import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../_guards/auth.guard';
import { ProductPackageComponent } from '../product/product-package/product-package.component';
import { FolderSummaryComponent } from './folder-summary/folder-summary.component';
import { FolderSummaryEditComponent } from './folder-summary-edit/folder-summary-edit.component';
import { DetailsComponent } from './details/details.component';
import { CustomerreceiptsComponent } from './customerreceipts/customerreceipts.component';
import { PaymentComponent } from './payment/payment.component';
import { FolderhistoryComponent } from './folderhistory/folderhistory.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { FolderNotesComponent } from './folder-notes/folder-notes.component';
import { PnrSourcefileComponent } from './pnr-sourcefile/pnr-sourcefile.component';
// import { RailsegDetailsEditComponent } from './railseg-details-edit/railseg-details-edit.component';
// import { RailsegDetailsComponent } from './railseg-details/railseg-details.component';
// import { AirsegDetailsComponent } from './airseg-details/airseg-details.component';
// import { AirsegDetailsEditComponent } from './airseg-details-edit/airseg-details-edit.component';

const routes: Routes = [
{
   path: "",
  component: DetailsComponent,
   data: { title: "Details" },
  children: [
    {
      path: "FolderSummary/:orderNo",
      component: FolderSummaryComponent,
      canActivate: [AuthGuard],
      data: { title: "Add Itinerary" },
    },
    {
      path: "FolderSummaryEdit/:orderNo",
      component: FolderSummaryEditComponent,
      canActivate: [AuthGuard],
      data: { title: "Add Itinerary" },
    },
    {
      path: "CustomerReceipts/:orderNo",
      component: CustomerreceiptsComponent,
      canActivate: [AuthGuard],
      data: { title: "Add Itinerary" },
    },
    {
      path: "Payment/:orderNo",
      component: PaymentComponent,
      canActivate: [AuthGuard],
      data: { title: "Payment" },
    },
    {
      path: "FolderHistory/:orderNo",
      component: FolderhistoryComponent,
      canActivate: [AuthGuard],
      data: { title: "Folder List" },
    },
    {
      path: "Invoice/:orderNo",
      component: InvoiceComponent,
      canActivate: [AuthGuard],
      data: { title: "Accounts" },
    },
    {
      path: "IssueCreditNote/:orderNo",
      component: InvoiceComponent,
      canActivate: [AuthGuard],
      data: { title: "Accounts" },
    },
    {
      path: "DeleteInvoice/:orderNo",
      component: InvoiceComponent,
      canActivate: [AuthGuard],
      data: { title: "Accounts" },
    },
    {
      path: "Reinvoice/:orderNo",
      component: InvoiceComponent,
      canActivate: [AuthGuard],
      data: { title: "Accounts" },
    },
    {
      path: "RefundReset/:orderNo",
      component: InvoiceComponent,
      canActivate: [AuthGuard],
      data: { title: "Accounts" },
    },
    {
      path: "PNRSourceFileDetails/:orderNo",
      component: PnrSourcefileComponent,
      canActivate: [AuthGuard],
    data: { title: "Folder Options" },
    },
    {
      path: "FolderNotes/:orderNo",
      component: FolderNotesComponent,
      canActivate: [AuthGuard],
      data: { title: "Notes" },
    },
     
      {
        path: "ProductPackage/:orderNo",
        component: ProductPackageComponent,
        canActivate: [AuthGuard],
        data: { title: "Product Package" },
      },

     
    ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FolderRoutingModule { }
