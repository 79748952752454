export class FolderQueuryReportDTO {
    CreationFromdate: any;
    CreationTodate: any;
    TravelFromdate: any;
    TravelTodate: any;
    InvFromdate: any;
    InvTodate: any;
    BookingFromdate: any;
    BookingTodate: any;
    ReturnFromdate: any;
    ReturnTodate: any;
    FolderNoFrom: any;
    OrderNoFrom: any;
    MarkettingCodeName: any;
    MarkettingCode: any;
    Branch: any;
    BranchName: any;
    BusinessArea: any;
    BArea: any;
    BookedBy: any;
    StaffGroup: any;
    StaffGroupName: any;
    Destination: any;
    SupplierCode: any;
    CustomerCode: any;
    ProductCode: any;
    Supplier_Group: any;
    SupplierGroup: any;
    Customer_Group: any;
    CustomerGroupName: any;
    ProfitPerFrom: any;
    ProfitPerTo: any;
    ProfitFrom: any;
    ProfitTo: any;
    YourReference: any;
    User: any;
    Status: any;
    GroupBy_varchar: any;
    TypeOfInvoice_nvarchar: any;
    QuotesOnly: any;
    CancelledCheck: any;
    UserId: any;
    VairlineAIRID: any;
    MonthOrDateWiseSummaryReport_tinyint: any;
    Country_varchar: any;
    OrderType_varchar: any;
    ExcludePDQCharge_int: any;
    ExcludeTOMSVat_int: any;
    Currency_varchar: any;
    PaymentStatus_tinyint: any;
    PassQuery: any;
    BookingStatus: any;
    detailTable: any;
    StatusName: any;
    IncludeQuoteswithPO: any;
    IncludeQuotesPO: any;
    ExcludeTOMSVat: any;
    ExcludePDQCharge: any;
    Quotes: any;
    Cancelled: any;
    ModuleName: any;
    SubGroup: any;
    CustUserCode: any;
    Currency: any;
    Country: any;
    PaymentStatus: any;
    UserName: any;
    PassQry: any;
    MarkettingCodeList: any;
    isIncluded: boolean = true;
    MCMultipleSelect: boolean = false;
    ReportGenarated: boolean = false;
    MarkettingCodeSelectedItem: any;
    BranchCodeSelectedItem: any;
    BranchCode: any;
    BranchList: any;
    DestinationCodeSelectedItem: any;
    DestinationCode: any;
    DestinationList: any;
    BusAreaSelectedItem: any;
    BusAreaCode: any;
    BusAreaList: any;
    CreatedUser: any;
    ExportPDFParam: any;
    CustomerName: any;
    Supplier: any;
    Product: any;
    CrystalRptQuery: any;
    RptHdrSel_ModuleName: any;
    RptHdrSel_UserId: any;
    RptHdrSel_AutoNo: any;
    RptHdrSel_TemplateName: any;
    OrderTypeName: any;
}
export class FolderQueuryReportResultDTO {
    FOLDERDATE: any;
    INVDATE: any;
    PROJNO: any;
    CREDITAMT: any;
    INVOICENO: any;
    CUSTNAME: any;
    LASTNAME: any;
    AIRID: any;
    NOOFPASS: any;
    BUYAMT: any;
    COMMAMT: any;
    SELLAMT: any;
    LineSell: any;
    Total: any;
    LineCOM: any;
    OrderNo: any;
    Branch: any;
    BusinessArea: any;
    Destination: any;
    MarketingCode: any;
    StaffGroup: any;
    Country: any;
    Name: any;
    NoOfFolderCreated: any;
    NoOfFolderInvoiced: any;
    StaffName: any;
}
export class FolderQueryReportFilterDTO {

    ReportType: string
    rptfilename: string;
    ReportName: String;
    LanId: String;
    rptFolderQueryReportDTO: FolderQueuryReportDTO;
    FormTitle: String;
    UserId: any;
    reportParmsc: any;
}