import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { PublicLayoutComponent } from 'src/app/public-layout/public-layout.component';
import { CommonserviceService } from '../../Services/commonservice.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ShareDataService } from '../../Services/share-data.service';
import { FileService } from '../../Services/file.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { debug } from 'util';
import { ModuleUsageService } from '../../Services/module-usage.service';

@Component({
  selector: 'app-rpt-folder-activity-report',
  templateUrl: './rpt-folder-activity-report.component.html',
  styleUrls: ['./rpt-folder-activity-report.component.scss']
})
export class RptFolderActivityReportComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  @ViewChild('autocompletecustomer') public autocompletecustomer: AutoCompleteComponent;
  @ViewChild('autocompletefrom') public autocompletefrom: AutoCompleteComponent;
  @ViewChild('autocompleteTo') public autocompleteTo: AutoCompleteComponent;


  public StatusSelectedItem: any;
  lookupname: any = 'CUSTOMER';
  lookuptype: string = '';
  openSearch = false;
  public UserSelectedItem: any;
  folderActivityReportForm: FormGroup;
  constructor(public translate: TranslateService, public moduleUsageService: ModuleUsageService, public common: CommonserviceService, private layout: PublicLayoutComponent, private route: ActivatedRoute, private lookupApi: LookUpDetailsService, private formBuilder: FormBuilder, private router: Router, private translateapi: TranslateService, private flashMessage: FlashMessagesService, private emailapi: FileService, private apishare: ShareDataService) { }
  branchList: Array<LookUpDetails["ResponseData"]> = [];
  businessAreaList: Array<LookUpDetails["ResponseData"]> = [];
  destinationList: Array<LookUpDetails["ResponseData"]> = [];
  public destinationSelectedItem: LookUpDetails["ResponseData"];
  public destinationToSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelectedItem: LookUpDetails["ResponseData"];
  destinationToList: Array<LookUpDetails["ResponseData"]> = [];
  CustomerList: Array<LookUpDetails["ResponseData"]> = [];
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];
  BOOKEBYList: Array<LookUpDetails["ResponseData"]> = [];
  AirlineList: Array<LookUpDetails["ResponseData"]> = [];
  public customerSelectedItem: LookUpDetails["ResponseData"];
  ProductList: Array<LookUpDetails["ResponseData"]> = [];
  public destinationSelected: String;
  public destinationToSelected: String;
  public supplierSelected: String;
  public customerSelected: String;
  FolderNostr: any;
  public ReportTypeList: Array<{ text: string, value: number }> = [{ text: "Summary", value: 0 }, { text: "Detailed", value: 1 }, { text: "Not Invoiced", value: 2 }];

  public tickettypeList: Array<string> = ["All", "TKT", "ADM", "ACM", "MCO", "MPD", "VMPD", "EMD", "RTKT", "RFN", "EMDRFN"];
  public groupByList: Array<{ text: string, value: number }> = [{ text: "Airline", value: 0 }, { text: "Booked by", value: 1 }, { text: "Ticketed by", value: 2 }, { text: "Destination", value: 3 }];
  public reportParms: any;
  public reportName: any = '';
  public reportFile: string = "FolderActivitySummary.trdp";
  public reportSubject: string = "Folder Activity";
  public PaymentMethod: any = "";
  public PaymentMethodName: any = "";
  public ReportType = 0;
  public GroupSelectedItem: any = { text: "Airline", value: 0 };
  public paymentSelectedItem = { text: "(All)", value: 0 }
  public rptTypeSelectedItem = { text: "(Summary)", value: 0 }
  public TravelDateFrom = null;
  public TravelDateTo = null;
  public TicketDateFrom = null;
  public TicketDateTo = null;
  public ReturnDateFrom = null;
  public ReturntDateTo = null;
  public disabled: any = false;
  public ATTravelDateFrom = "";
  public ATTravelDateTo = "";
  public ATTicketDateFrom: any = new Date();
  public ATTicketDateTo: any = new Date();
  public ATReturnDateFrom = "";
  public ATReturntDateTo = "";

  public DateTravelFrom = new Date();
  public DateTravelTo = new Date();
  public DateTicketFrom = new Date();
  public DateTicketTo = new Date();
  public isShown: any;
  public SupplierName: String = "";
  public Supplier: String = "0";
  public BookedBy: String = "";
  public CreatedUserName: string = "";
  public PassengerType = "";
  public TicketedBy = "";
  public GroupBy: any = "Airline";
  public GroupBy_tinyint = 0;
  public Customer = "";
  public PartyCode: any = 0;
  public ProductCode: any = 0;
  public Product: any = "";
  public Airline = "";
  public VairlineAIRID = "";
  public OriginFrom: String = "";
  public Origin = "";
  public DestinationFrom: String = "";
  public Destination = "";
  public Branch = "";
  public BusinessArea = "";
  public BranchCode = "";
  public BArea = "";
  public UnusedTickets = false;
  public TicketType = "All";
  public SQLPASSWHEREQUERY = "";

  public PassType = "All";
  public PassengerQuery = "";
  public enableChild = false;
  public enableAdult = false;
  public enableInfant = false;
  public enableYouth = false;
  public enableUnusedTicket = false;
  public Status = 0;
  public StatusName: any = "";
  public showMsg: any;
  public OrderNo: any = '';
  public FolderNo: any = '';
  fromId: string;
  bccId: string;
  userID: number;
  username: any = new BehaviorSubject('');
  public windowTop: number = -100;
  public windowLeft: number = -225;
  public offset = 10;
  public left = this.windowLeft;
  public top = this.windowTop;
  public width = window.innerWidth - 40;
  public height = 770;
  public Componentname: string;
  // public paymentMethodList:Array<{ text: string, value: number }>
  //    =[ {text:"All",value:0} , {text:"Cash",value:1}, 
  //    {text:"Bank",value:2} , {text:"Credit Card",value:3},
  //    {text:"Reward Points",value:4}, {text:"GL Transfer",value:5}, 
  //    {text:"Gift Card",value:6}, ,{text:"Customer Card",value:7} ];
  public paymentMethodList: Array<LookUpDetails["ResponseData"]> = [];

  public statusList: Array<{ text: string, value: number }> =
    [{ text: "All", value: 0 }, { text: "Saved", value: 1 }, { text: "Invoiced", value: 2 }, { text: "Refund", value: 3 }, { text: "Cancelled", value: 4 }, { text: "Partial Refund", value: 5 }, { text: "Refund Request", value: 6 }];

  ngOnInit() {
    this.StatusSelectedItem = { text: "All", value: 0 };
    this.Status = this.StatusSelectedItem.value;
    this.StatusName = this.StatusSelectedItem.text;
    this.layout.folderstatus = '';
    this.layout.headerPassenger = '';
    this.folderActivityReportForm = this.formBuilder.group({

      FolderNo: [""],
      OrderNo: [""],


    });
    this.userID = +(this.apishare.uid);
    this.username = this.apishare.uname;
    this.subscription.add(this.emailapi.GetReportEmail(this.userID).subscribe(data => {
      if (data != null && data != undefined) {
        this.fromId = data.ResponseData.FROMEMAIL,
          this.bccId = data.ResponseData.DEFAULTBCCMAIL
      }
    }));

    this.subscription.add(this.lookupApi.GetAllLookup('BRANCH').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.branchList = data["ResponseData"]
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('PRODUCTSForREPORT').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.ProductList = data["ResponseData"]
        }
        //  if(this.OthersEditForm.controls.CODE.value!="")
        //  {
        //  this.ProductSelectedItem =this.ProductList.find(item => item.Code ==this.OthersEditForm.controls.CODE.value);        
        // }


      }));
    this.subscription.add(this.lookupApi.GetAllLookup('BUSINESSAREA').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.businessAreaList = data["ResponseData"]
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('BUSINESSAREA').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.businessAreaList = data["ResponseData"]
        }
      }
    ));

    this.subscription.add(this.lookupApi.GetAllLookup('PAYMENT').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.paymentMethodList = data["ResponseData"]
        }
      }
    ));

    // this.lookupApi.GetAllLookup('DESTINATION').subscribe(
    //   (data) => {


    //      this.destinationList= data["ResponseData"]  



    //   }
    //   );

    // this.lookupApi.GetAllLookup('CUSTOMER').subscribe(
    //   (data) => {
    //      this.CustomerList= data["ResponseData"]  


    //   }
    //   );

    // this.lookupApi.GetAllLookup('SUPPLIERS').subscribe(
    //   (data) => {

    //      this.SupplierList= data["ResponseData"]      
    //      //alert("ddd"+this.airticketEditForm.controls.PRTYCODE.value)     ;

    //   }
    //   );
    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.BOOKEBYList = data["ResponseData"]
          // this.UserSelectedItem=this.BOOKEBYList.find(x=>x.Name=this.username)
          // this.BookedBy=this.UserSelectedItem.Code; 
          // this.CreatedUserName=this.UserSelectedItem.Name
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.AirlineList = data["ResponseData"]
        }
      }
    ));
    debugger;
    this.StatusSelectedItem = { text: "All", value: 0 };
    this.Status = this.StatusSelectedItem.value;
    this.StatusName = this.StatusSelectedItem.text;
    this.Componentname = "RptFolderActivityReport";
    //this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654
    sessionStorage.setItem('Componentname', this.Componentname);

  }
  get f() { return this.folderActivityReportForm.controls; }
  public BookedByChange(value: any): void {

    if (value != null && value != undefined) {
      this.BookedBy = value.Code;
      this.CreatedUserName = value.Name
    }
    else {
      this.BookedBy = "";
      this.CreatedUserName = "";
    }


  }
  public BookedByChang1e(value: any): void {

    this.customerSelectedItem = this.CustomerList.find(item => item.Name == value);

    if (this.customerSelectedItem != null) {
      this.Customer = this.customerSelectedItem.ActualName;
      this.PartyCode = this.customerSelectedItem.Code;


    }
    else {
      this.Customer = "";
      this.PartyCode = 0;
    }
    // this.carEditForm.get("suppUserCodetext").setValue( this.supplierSelectedItem.Name);
  }
  // public DestinationValueChange(value: any): void {

  //   this.destinationToSelectedItem =this.destinationToList.find(item => item.Name ==value);

  //   if(this.destinationToSelectedItem != null)
  //   {
  //     this.DestinationFrom=this.destinationToSelectedItem.ActualName;
  //     this.Destination=this.destinationToSelectedItem.UsrCode;
  //   }
  //   else{
  //     this.DestinationFrom=null;
  //     this.Destination=null;
  //   }
  // }

  public TicketTypeChange(value: any): void {

    if (value != null && value != undefined)
      this.TicketType = value.Name;
    else
      this.TicketType = null;

  }
  public TicketedByChange(value: any): void {
    console.log('selectionChange', value);
    if (value != null && value != undefined)
      this.TicketedBy = value.Name;
    else
      this.TicketedBy = "";

  }
  validateNumberLimit() {
    debugger;
    if (this.folderActivityReportForm.controls.FolderNo.value != null && this.folderActivityReportForm.controls.FolderNo.value != '' && this.folderActivityReportForm.controls.FolderNo.value != undefined) {
      var FolderNostr = this.folderActivityReportForm.controls.FolderNo.value.toString();
      if (FolderNostr.length > 9) {
        this.FolderNostr = FolderNostr.substring(0, 10);
        this.folderActivityReportForm.controls.FolderNo.setValue(+this.FolderNostr);

        //
      }
      else {
        this.folderActivityReportForm.controls.FolderNo.setValue(FolderNostr);
      }
      this.FolderNo = this.folderActivityReportForm.controls.FolderNo.value;
    }
    else {
      this.FolderNo = '';
    }
    if (this.folderActivityReportForm.controls.OrderNo.value != null && this.folderActivityReportForm.controls.OrderNo.value != '' && this.folderActivityReportForm.controls.OrderNo.value != undefined) {
      var OrderNostr = this.folderActivityReportForm.controls.OrderNo.value.toString();
      if (OrderNostr.length > 9) {
        OrderNostr = OrderNostr.substring(0, 10);
        this.folderActivityReportForm.controls.OrderNo.setValue(+OrderNostr);

      }
      else {
        this.folderActivityReportForm.controls.OrderNo.setValue(OrderNostr);
      }
      this.OrderNo = this.folderActivityReportForm.controls.OrderNo.value.toString();
    }
    else {
      this.OrderNo = '';
    }
  }
  public PaymentMethodValueChange(value: any): void {

    if (value != null && value != undefined) {
      this.PaymentMethod = value.Code;
      this.PaymentMethodName = value.Name;

    }
    else {
      this.PaymentMethod = "";
      this.PaymentMethodName = "";
    }
  }
  public ReportTypeValueChange(value: any): void {

    if (value != null && value != undefined) {
      this.ReportType = value.value;
      if (this.ReportType === 0) {
        this.disabled = false;
        this.reportFile = "FolderActivitySummary.trdp";
        this.subscription.add(this.translate.get(['Headings.FolderActivitySummaryReport'],).subscribe((translations) => {
          this.reportName = translations['Headings.FolderActivitySummaryReport'];

        }));

      }
      else if (this.ReportType === 1) {
        this.reportFile = "FolderActivityDetailed.trdp";
        this.reportName = "Folder Activity Detailed Report"
        this.disabled = false;
        this.subscription.add(this.translate.get(['Headings.FolderActivityDetailedReport'],).subscribe((translations) => {
          this.reportName = translations['Headings.FolderActivityDetailedReport'];

        }));
      }
      else if (this.ReportType === 2) {

        this.ATTicketDateFrom = null;
        this.ATTicketDateTo = null;
        this.disabled = true;
        this.reportFile = "FolderActivityNotInvoiced.trdp";
        this.reportName = "Folder Activity Not Invoiced Report"
        this.subscription.add(this.translate.get(['Headings.FolderActivityNotInvoiced'],).subscribe((translations) => {
          this.reportName = translations['Headings.FolderActivityNotInvoiced'];

        }));
      }

    }
    else {
      this.ReportType = 0;
      this.reportFile = "FolderActivitySummary.trdp";
      this.subscription.add(this.translate.get(['Headings.FolderActivityNotInvoiced'],).subscribe((translations) => {
        this.reportName = translations['Headings.FolderActivityNotInvoiced'];

      }));
    }

  }
  public StatusValueChange(value: any): void {

    if (value != null && value != undefined) {

      this.Status = value.value;
      this.StatusName = value.text;
    }
    else {
      this.Status = 0;
      this.StatusName = "";
    }
  }
  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'CUSTOMER') {
      this.lookupname = 'CUSTOMER';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.customerSelectedItem = value;
        this.CustomerList = [];
        this.CustomerValueChange(value.Name)
      },

      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860
  handleFilter(value) {

    // if(this.AirlineList.length==0){
    //   this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
    //     (data) => {

    //        this.AirlineList= data["ResponseData"]    

    //     }
    //     ));
    // }
    this.AirlineList = this.AirlineList.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public AirLineChange(value: any): void {

    if (value != null && value != undefined) {
      this.Airline = value.ActualName;
      this.VairlineAIRID = value.Code;
    }

    else {
      this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.AirlineList = data["ResponseData"]
          }
        }
      ));
      this.Airline = "";
      this.VairlineAIRID = "";
    }

  }
  // public OriginChange(value: any): void {
  //   console.log('selectionChange', value);
  //   if(value!=null && value!=undefined)
  //   {
  //     this.Origin=value.Code; 
  //     this.OriginFrom=value.ActualName; 
  //   }

  //   else

  //   {
  //     this.Airline=""; 
  //     this.VairlineAIRID=""; 
  //   }

  // } 

  public DestinationValueChange(value: any): void {

    this.destinationToSelectedItem = this.destinationToList.find(item => item.Name == value);

    if (this.destinationToSelectedItem != null) {
      this.DestinationFrom = this.destinationToSelectedItem.ActualName;
      this.Destination = this.destinationToSelectedItem.UsrCode;
    }
    else {
      this.DestinationFrom = null;
      this.Destination = null;
    }
  }
  // public DestinationValueChange(value: any): void {
  //  //console.log('selectionChange', value);
  //  this.Destination=value.ActualName;
  //  this.DestinationFrom=value.UsrCode;

  // }

  public OriginChange(value: any): void {

    this.destinationSelectedItem = this.destinationList.find(item => item.Name == value);

    if (this.destinationSelectedItem != null) {
      this.OriginFrom = this.destinationSelectedItem.ActualName;
      this.Origin = this.destinationSelectedItem.UsrCode;
    }
    else {
      this.OriginFrom = null;
      this.Origin = null;
    }


  }
  handleFilterToDestination(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {

          if (data != null && data != undefined) {
            this.destinationToList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompleteTo.toggle(false);
    }
  }
  handleFilterFromDestination(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.destinationList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletefrom.toggle(false);
    }
  }
  public BranchValueChange(value: any): void {
    //console.log('selectionChange', value);


    if (value != null && value != undefined) {
      this.BranchCode = value.UsrCode;
      this.Branch = value.Name;
    }
    // this.folderSummaryEditForm.controls.BRID.setValue(value.UsrCode);
    else {
      this.BranchCode = "";
      this.Branch = "";
    }
  }
  // public CustomerChange(value: any): void {
  //   console.log('selectionChange', value);
  //   if(value!=null && value!=undefined)
  //   {
  //     this.Customer=value.ActualName;  
  //     this.PartyCode=value.Code;  
  //   }

  //   else

  //   {
  //     this.Customer="";  
  //     this.PartyCode=0;  
  //   }
  // }

  public CustomerValueChange(value: any): void {
    if (this.CustomerList != null && this.CustomerList.length > 0) {//13864
      this.customerSelectedItem = this.CustomerList.find(item => item.Name == value);

    }

    if (this.customerSelectedItem != null) {
      this.customerSelected = this.customerSelectedItem.Name; //13864

      this.Customer = this.customerSelectedItem.ActualName;
      this.PartyCode = this.customerSelectedItem.Code;


    }
    else {
      this.Customer = "";
      this.PartyCode = 0;
    }
    // this.carEditForm.get("suppUserCodetext").setValue( this.supplierSelectedItem.Name);
  }

  public BusinessAreaValueChange(value: any): void {
    // console.log('selectionChange', value);

    if (value != null && value != undefined)
    //this.folderSummaryEditForm.controls.BAID.setValue(value.UsrCode);
    {
      this.BusinessArea = value.Name;
      this.BArea = value.UsrCode

    }
    else {
      this.BusinessArea = "";
      this.BArea = "";
    }
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };





  public SupplierValueChange(value: any): void {

    this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);
    if (this.supplierSelectedItem != null) {
      this.SupplierName = this.supplierSelectedItem.ActualName;
      this.Supplier = (this.supplierSelectedItem.Code);
    }
    else {
      this.SupplierName = "";
      this.Supplier = "0";
    }

  }

  public opened = false;

  public open() {
    window.scrollTo(0, 0);
    this.opened = true;
  }

  handleFiltercustomer(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchCustomer(value, this.userID).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.CustomerList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletecustomer.toggle(false);
    }
  }
  public close() {

    this.opened = false;
    this.openSearch = false;
  }
  onCancel() {
    var reporturl = sessionStorage.getItem('menuhighlight')

    if (reporturl != null) {
      this.router.navigate(['/Login']);
    }
    else {
      this.router.navigate(['PenAir/Home']);
    }
  }

  handleFiltersupplier(value) {
    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }

  public AccountGroupChange(value: any): void {

    if (value !== undefined) {
      this.GroupSelectedItem = this.groupByList.find(item => item.value == value.value);
      if (this.GroupSelectedItem != null) {
        this.GroupBy_tinyint = this.GroupSelectedItem.value;
        this.GroupBy = this.GroupSelectedItem.text;
        //this.trialBalanceReportForm.controls.AccountGroup.setValue( this.GroupSelectedItem.value);
      }
      else {
        this.GroupBy_tinyint = 0;
        this.GroupBy = "";
      }
    }

    else {
      this.GroupBy_tinyint = 0;
      this.GroupBy = "";
    }

  }


  public ProductChange(value: any): void {

    if (value != null && value != undefined) {
      this.Product = value.Name;
      this.ProductCode = value.Code;

    } else {
      this.Product = "";
      this.ProductCode = 0;
    }
  }
  onFormSubmit() {
    //this. GetPassengerQuery();

    this.UnusedTickets = this.enableUnusedTicket;
    debugger;
    if (this.ATTravelDateFrom == "" || this.ATTravelDateFrom == '' || this.ATTravelDateFrom == undefined) {
      this.ATTravelDateFrom = null
    }
    if (this.ATTicketDateFrom == "" || this.ATTicketDateFrom == '' || this.ATTicketDateFrom == undefined) {
      this.ATTicketDateFrom = null
    }
    if (this.ReportType === 0) {

      if (this.ATTicketDateFrom == null && this.ATTravelDateFrom == null) // For caseid-187
      {
        this.subscription.add(this.translateapi.get(['Messages.empty_fromdate']).subscribe((translations) => {

          this.showMsg = translations['Messages.empty_fromdate'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }))

        return;
      }
      this.subscription.add(this.translate.get(['Headings.FolderActivitySummaryReport'],).subscribe((translations) => {
        this.reportName = translations['Headings.FolderActivitySummaryReport'];

      }));

    }
    else if (this.ReportType === 1) {
      if (this.ATTicketDateFrom == null && this.ATTravelDateFrom == null) // For caseid-187
      {
        this.subscription.add(this.translateapi.get(['Messages.empty_fromdate']).subscribe((translations) => {

          this.showMsg = translations['Messages.empty_fromdate'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }))

        return;
      }
      this.subscription.add(this.translate.get(['Headings.FolderActivityDetailedReport'],).subscribe((translations) => {
        this.reportName = translations['Headings.FolderActivityDetailedReport'];

      }));
    }
    else if (this.ReportType === 2) {
      if ((this.ATTravelDateFrom == "" || this.ATTravelDateFrom == null)
      ) {

        this.common.showWARNINGtoastrmsg('Messages.empty_fromdate', 2000, true, false);

        return;
      }


      this.subscription.add(this.translate.get(['Headings.FolderActivityNotInvoiced'],).subscribe((translations) => {
        this.reportName = translations['Headings.FolderActivityNotInvoiced'];

      }));
    }
    this.reportSubject = this.reportName;
    if ((this.ATTravelDateTo == null || this.ATTravelDateTo == "") && (this.ATTravelDateFrom != null || this.ATTravelDateFrom != "")) {
      this.TravelDateTo = this.TravelDateFrom;
      this.ATTravelDateTo = this.ATTravelDateFrom;
    }
    if ((this.ATTicketDateTo == null || this.ATTicketDateTo == "") && (this.ATTicketDateFrom != null || this.ATTicketDateFrom != "")) {
      this.TicketDateTo = this.TicketDateFrom;
      this.ATTicketDateTo = this.ATTicketDateFrom;
    }

    var StartTravelDate = this.TravelDateFrom;
    var EndTravelDate = this.TravelDateTo;
    var StartTicketDate = this.TicketDateFrom;
    var EndTicketDate = this.TicketDateTo;
    var validdate = new Date('01/01/1900')
    var validmaxdate = new Date('01/01/2079')
    if (this.ATTicketDateFrom != null) // For caseid-187
    {
      this.ATTicketDateFrom = new Date(this.ATTicketDateFrom);
      this.ATTicketDateFrom.setSeconds(0);
      this.ATTicketDateFrom.setMinutes(0);
      this.ATTicketDateFrom.setHours(0);
      if (this.ATTicketDateFrom < validdate) {
        this.subscription.add(this.translateapi.get(['Messages.invalid_date_from']).subscribe((translations) => {

          this.showMsg = translations['Messages.invalid_date_from'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }))

        return;
      }
      if (this.ATTicketDateFrom > validmaxdate) {
        this.subscription.add(this.translateapi.get(['Messages.invalid_date_from']).subscribe((translations) => {

          this.showMsg = translations['Messages.invalid_date_from'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }))

        return;
      }
    }


    debugger;
    if (this.ATTicketDateTo != null) // For caseid-187
    {
      var ATTravelDateTo = new Date(this.ATTicketDateTo);
      ATTravelDateTo.setSeconds(0);
      ATTravelDateTo.setMinutes(0);
      ATTravelDateTo.setHours(0);
      if (ATTravelDateTo < validdate) {
        this.subscription.add(this.translateapi.get(['Messages.invalid_date_to']).subscribe((translations) => {

          this.showMsg = translations['Messages.invalid_date_to'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }))

        return;
      }
      if (ATTravelDateTo > validmaxdate) {
        this.subscription.add(this.translateapi.get(['Messages.invalid_date_to']).subscribe((translations) => {

          this.showMsg = translations['Messages.invalid_date_to'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }))

        return;
      }
    }
    // if(this.ATTicketDateTo==null) // For caseid-187
    // {
    //   this.subscription.add( this.translateapi.get(['Messages.invalid_date_to']).subscribe((translations ) => {

    //     this.showMsg= translations['Messages.invalid_date_to'];
    //     this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
    //   }))

    //   return;
    // }
    if (this.ATTicketDateFrom != null)
      if (this.ATTicketDateTo < this.ATTicketDateFrom) {

        this.common.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 2000, true, false);
        return;
      }
    if (this.ATTravelDateFrom != null) {
      if (new Date(this.ATTravelDateFrom) < validdate) {
        this.subscription.add(this.translateapi.get(['Messages.invalid_date_from']).subscribe((translations) => {

          this.showMsg = translations['Messages.invalid_date_from'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }))

        return;
      }
      if (new Date(this.ATTicketDateFrom) > validmaxdate) {
        this.subscription.add(this.translateapi.get(['Messages.invalid_date_from']).subscribe((translations) => {

          this.showMsg = translations['Messages.invalid_date_from'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }))

        return;
      }
      if (new Date(this.ATTravelDateTo) < validdate) {
        this.subscription.add(this.translateapi.get(['Messages.invalid_date_to']).subscribe((translations) => {

          this.showMsg = translations['Messages.invalid_date_to'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }))

        return;
      }
      if (new Date(this.ATTravelDateTo) > validmaxdate) {
        this.subscription.add(this.translateapi.get(['Messages.invalid_date_to']).subscribe((translations) => {

          this.showMsg = translations['Messages.invalid_date_to'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }))

        return;
      }
      if (this.ATTravelDateTo < this.ATTravelDateFrom) {
        this.common.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 2000, true, false);
        return;
      }
    }

    // this.TravelDateFrom= new Date(this.ATTravelDateFrom).toLocaleDateString('en-GB', {
    //   day: '2-digit', month: 'short', year: 'numeric'
    // }).replace(/ /g, '-');
    // this.TravelDateTo= new Date(this.ATTravelDateTo).toLocaleDateString('en-GB', {
    //   day: '2-digit', month: 'short', year: 'numeric'
    // }).replace(/ /g, '-');
    // this.TicketDateFrom= new Date(this.ATTicketDateFrom).toLocaleDateString('en-GB', {
    //   day: '2-digit', month: 'short', year: 'numeric'
    // }).replace(/ /g, '-');
    // this.TicketDateTo= new Date(this.ATTicketDateTo).toLocaleDateString('en-GB', {
    //   day: '2-digit', month: 'short', year: 'numeric'
    // }).replace(/ /g, '-');

    this.TravelDateFrom = this.common.ConvertToLocaleDate(new Date(this.ATTravelDateFrom));
    this.TravelDateTo = this.common.ConvertToLocaleDate(new Date(this.ATTravelDateTo));
    this.TicketDateFrom = this.common.ConvertToLocaleDate(new Date(this.ATTicketDateFrom));
    this.TicketDateTo = this.common.ConvertToLocaleDate(new Date(this.ATTicketDateTo));

    if (this.ATTravelDateFrom == "" || this.ATTravelDateFrom == null) {
      this.TravelDateFrom = "01/JAN/1900";
    }
    if (this.ATTravelDateTo == "" || this.ATTravelDateTo == null) {
      this.TravelDateTo = "01/JAN/1900";
    }
    if (this.ATTicketDateFrom == "" || this.ATTicketDateFrom == null) {
      this.TicketDateFrom = "01/JAN/1900";
    }
    if (this.ATTicketDateTo == "" || this.ATTicketDateTo == null) {
      this.TicketDateTo = "01/JAN/1900";
    }
    if (this.ReportType === 2) {
      if ((this.TravelDateFrom == "01/JAN/1900")
      ) {

        this.common.showWARNINGtoastrmsg('Messages.empty_fromdate', 2000, true, false);

        return;
      }
    }
    if (this.TicketType === undefined)
      this.TicketType = null;
    debugger;
    this.reportParms = ({
      reportName: this.reportName,
      TravelDateFrom: this.TravelDateFrom,
      TravelDateTo: this.TravelDateTo,
      InvoiceDateFrom: this.TicketDateFrom,
      InvoiceDateTo: this.TicketDateTo,
      Product: this.Product,
      ProductCode: this.ProductCode,
      FolderNo: this.FolderNo,
      OrderNo: this.OrderNo,
      CreatedUser: this.CreatedUserName,
      CreatedUserName: this.CreatedUserName,
      StatusName: this.StatusName,
      Status: this.Status,

      Customer: this.Customer,
      PartyCode: this.PartyCode,
      ReportType: this.ReportType,

      Branch: this.Branch,
      BusinessArea: this.BusinessArea,
      BranchCode: this.BranchCode,
      BArea: this.BArea,
      UnusedTickets: this.UnusedTickets,
      Includecancelled: this.enableChild,
      PaymentMethod: this.PaymentMethod,
      PaymentMethodName: this.PaymentMethodName,
      UserId: this.userID,

    }

    );
    this.open();
    //13856
    this.moduleUsageService.ModuleUsageDTO.reportname = "FolderActivityReport"
    this.moduleUsageService.List = this.reportParms;
    this.moduleUsageService.getModuleUsageReport().subscribe(res => {
      debugger;
    })
    //13856
  }

  public onEnd() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const positionTop = windowHeight - this.height - this.offset;
    const positionLeft = windowWidth - this.offset;


    if (this.top < this.windowTop) {
      this.top = this.windowTop;
    }

    if (this.top > positionTop) {
      this.top = this.windowTop;
    }
    if (this.left < (this.windowLeft - 200)) {
      this.left = this.windowLeft;
    }

    // if (this.left > positionLeft) {
    //   this.left = positionLeft;
    // }
  }
  folderno: any;
  orderno: any;
  ProductSelectedItem: any;
  BranchSelectedItem: any;
  BusinessAreaSelectedItem: any;
  CustSelectedItem: any;
  ClearAll() {
    this.ATTicketDateFrom = new Date();
    this.ATTicketDateTo = new Date();
    this.ATTravelDateFrom = null;
    this.ATTravelDateTo = null;
    this.rptTypeSelectedItem = { text: "(Summary)", value: 0 };
    this.folderno = null;
    this.orderno = null;
    this.ProductSelectedItem = null;
    this.customerSelectedItem = null;
    this.BranchSelectedItem = null;
    this.BusinessAreaSelectedItem = null;
    this.paymentSelectedItem = null;
    this.UserSelectedItem = null;
    this.StatusSelectedItem = { text: "(All)", value: 0 }
    this.CustSelectedItem = null;
    this.enableUnusedTicket = null;
    this.enableChild = null;
    this.customerSelected = null;
    this.customerSelected = '';
    this.autocompletecustomer.reset();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
