export class ProductList {
  IsSuccess: boolean;
  Message: string;
  ResponseData: {
    FOLDERNO: number;
    TYPE: string;
    PMCODE: number;
    ITEMNO: number;
    DESCRIPTION: string;
    SUPPNAME: string;
    SUPPPRTYCODE: string;
    PRV: string;
    PRP: string;
    INVOICED: string;
    PRI: string;
    TICKETAMT: number;
    OTHERTAX: number;
    BUYAMT: number;
    COMMAMT: number;
    SELLAMT: number;
    PAYABLEAMT: number;
    PAIDAMT: number;
    PRTYCODE: number;
    TAXNO: number;
    VAT: number;
    UBTAX: number;
    FCVAT: number;
    SupplierDeposit: any;
  };
  YQR: number;
  OTHCHARGE: number;
  DISAMT: number;
  GROSSAMT: number;
  COMONLY: string;
  PACKDES: string;
  PAYMODE: string;
  BUYTAXNO: number;
  PID: string;
  PName: string;
  BRID: string;
  CCNO: string;
  BAID: string;
  MCID: string;
  PTYPE: string;
  AIRID: string;
  ARRIVALPT: string;
  SLNO: number;
  PROJNO: number;
  PrintonInvoice: number;
  TYPEOFINV: number;
  GDS: string;
  PNR: string;
  AGTGROSSAMT: number;
  CancelledBooking: number;
  TTYPE: string;
  PDAMOUNT: number;
  PrintonCombined: number;
  PONO: number;
  PONOYEARID: string;
  PAYMENTID: number;
  PMTID: string;
  DEFDEPPER: number;
  DEFDEP: number;
  DepositDueDate: string;
  BalanceDueDate: string;
  BalanceDueAmount: number;
  FC_Comm: number;
  FC_Sell: number;
  FC_Payable: number;
  PO_FC_CID: string;
  PO_FC_CIDExRate: number;
  PO_FC_AMT: number;
  FNAME: string;
  GUIDItinerary: string;
  LOYALTYPOINTS: number;
  LOYALTYPOINTSMEMBNO: string;
  TOMSVATTAXNO: number;
  TOMSVATAMT: number;
  LOYALTYPOINTSAMT: number;
  LOYALTYPOINTSBAL: number;
  BSPPurchaseTax: number;
  BSPPurchaseTaxRate: number;
  TaxExcludingSalesTax: number;
  BaseFare: number;
  PONOCCDeferredPosted: number;
  BOOKEDBY: string;
  FPD_AutoNo: number;
  FC_Buy: number;
  PMName: string;
  FCCommTotal: number;
  FCSellTotal: number;
  TotalTax: number;
  FCBuyTotal: number;
  FCAlreadyPaid: number;
  FCOtherCredits: number;
  Balance: number;
  PDQCHARGE: number;
  TotalReceivable: number;
  AUTOPURCHASEORDER: number;
  OthersType: string;
}
export class RefundTotalProductList {
  IsSuccess: boolean;
  Message: string;
  ResponseData: {
    PO_FC_CIDExRate: number;
    PO_FC_CID: string;
    FOLDERNO: number;
    TYPE: string;
    PMCODE: number;
    ITEMNO: number;
    DESCRIPTION: string;
    SUPPNAME: string;
    SUPPPRTYCODE: string;
    PRV: string;
    PRP: string;
    INVOICED: string;
    PRI: string;
    PO_FC_AMT: any;
    TICKETAMT: number;
    OTHERTAX: number;
    BUYAMT: number;
    COMMAMT: number;
    SELLAMT: number;
    PAYABLEAMT: number;
    PAIDAMT: number;
    PRTYCODE: number;
    TAXNO: number;
    VAT: number;
    UBTAX: number;
    FC_Sell: number;
    FCVAT: number;
    PMName: string;
    TicketDate: any;
    BookingDate: any;
    PrtyUserCode: any;
    RefNo: any;
    TicketNo: any;
    GUIDItinerary: string;
  };
  YQR: number;
  OTHCHARGE: number;
  DISAMT: number;
  GROSSAMT: number;
  COMONLY: string;
  PACKDES: string;
  PAYMODE: string;
  BUYTAXNO: number;
  PID: string;
  PName: string;
  BRID: string;
  CCNO: string;
  BAID: string;
  MCID: string;
  PTYPE: string;
  AIRID: string;
  ARRIVALPT: string;
  SLNO: number;
  PROJNO: number;
  PrintonInvoice: number;
  TYPEOFINV: number;
  GDS: string;
  PNR: string;
  AGTGROSSAMT: number;
  CancelledBooking: number;
  TTYPE: string;
  PDAMOUNT: number;
  PrintonCombined: number;
  PONO: number;
  PONOYEARID: string;
  PAYMENTID: number;
  PMTID: string;
  DEFDEPPER: number;
  DEFDEP: number;
  DepositDueDate: string;
  BalanceDueDate: string;
  BalanceDueAmount: number;
  FC_Comm: number;
  FC_Sell: number;
  FC_Payable: number;
  PO_FC_CID: string;
  PO_FC_CIDExRate: number;
  PO_FC_AMT: number;
  FNAME: string;
  GUIDItinerary: string;
  LOYALTYPOINTS: number;
  LOYALTYPOINTSMEMBNO: string;
  TOMSVATTAXNO: number;
  TOMSVATAMT: number;
  LOYALTYPOINTSAMT: number;
  LOYALTYPOINTSBAL: number;
  BSPPurchaseTax: number;
  BSPPurchaseTaxRate: number;
  TaxExcludingSalesTax: number;
  BaseFare: number;
  PONOCCDeferredPosted: number;
  BOOKEDBY: string;
  FPD_AutoNo: number;
  FC_Buy: number;
  PMName: string;
  FCCommTotal: number;
  FCSellTotal: number;
  TotalTax: number;
  FCBuyTotal: number;
  FCAlreadyPaid: number;
  FCOtherCredits: number;
  Balance: number;
  PDQCHARGE: number;
  TotalReceivable: number;
  AUTOPURCHASEORDER: number;
  OthersType: string;
}
export class RefundProductList {
  PMName: string;
  FOLDERNO: number;
  TYPE: string;
  PMCODE: number;
  ITEMNO: number;
  DESCRIPTION: string;
  SUPPPRTYCODE: string;
  BUYAMT: number;
  COMMAMT: number;
  SELLAMT: number;
  OthersType: string;
  TicketDate: any;
  BookingDate: any;
  Supplier: any;
  PACKDES: any;
  RefNo: any;
  TicketNo: any;
  Invaliddate: any;
  FolderExh: any;
  ProductExh: any;
  HCBUYAMT: any;
  HCSellAMT: any;
  PO_FC_CID: string;
  FCID: string;
  GUIDItinerary: string;
  TTip: boolean;
  SupplierDeposit: any;
}
export class  DetailedProduct{
  PASSNAME:string;
   TICKETNO:string;
   TICKDATE:string;
   VairlineAIRID:string;
   AIRFROM:string; 
   AIRTO:string; 
   PAYABLE:string;
    PRTYCODE:string;
     SUPPNAME:string;
     SLNO:string;
      GUIDItinerary:string;

     SUPPRTYCODE:string;
      HOTELNAME:string; 
      ROOMS:string;
       TYPE:string;
        DATEIN:string;
         DATEOUT:string;
          NIGHTS:string;
          
      
          
           PICKUP:string;
            DROPOFF:string;
            DATE1:string;
             DATE2:string;

             TICKETDATE:string;
              FROMRAIL:string;
               TORAIL:string; 
                AMOUNT:string;   
              
                Ship:string;
                 CabinType:string;
                  DEPDATE:string;
                   ARRDATE:string;
                   PASSENGER:string;
                      DATEF:string;
                       DATET:string;
                       STATUS:string;    
                       
                       VEHITYPE:string;
                        FLIGHTNO:string;
                           FROMTRA:string;
                            TOTRA:string;
                            CODE:string;
                             DESCRIPTION:string;
                              QTY:string;
                               RATE:string;
                               ProductDescription:string;
}