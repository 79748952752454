import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HotelDetail } from '../Models/hotel-detail'
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';
import { FolderHotelActivityTblDTO, HotelDetailsDTO } from '../Models/hotel-master';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({

  providedIn: 'root'
})
export class HotelService {
  apiUrl: string;
  data5: any;
  public HD_AutoNo: any;
  public Hote101_Id: any;
  public guid: any;
  formDatasmain: any = {
    HotelCode: 0,
    FOLDERNO: 0,
    HDNO: 0,
    GUIDItinerary: ''
  };
  formdata: HotelDetailsDTO;
  public createdItems: FolderHotelActivityTblDTO[];
  public updatedItems: FolderHotelActivityTblDTO[];
  public deletedItems: FolderHotelActivityTblDTO[];
  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }



  //Supplierss:Object

  //getSuppliers (): Observable<HotelDetail[]> {   

  ////return this.http.get<HotelDetail[]>(apiUrl)
  // .pipe(
  //   tap(heroes => console.log('fetched Suppliers')),
  // catchError(this.commonservice.handleError('getSuppliers', []))
  //);
  //}

  private newMethod(data: Object) {
    alert(data);
  }

  getHotel(folderNo: number, GuidItinerary: string, isViewForm: boolean): Observable<HotelDetail> {
    const url = `${this.apiUrl}` + 'GetHotelDetailsById' + `/${folderNo}/${GuidItinerary}/${isViewForm}`;
    return this.http.get<HotelDetail>(url).pipe(
      tap(_ => console.log(`fetched Hotel id=${folderNo}`)),
      catchError(this.commonservice.handleError<HotelDetail>(`getHotel id=${folderNo}`))
    );
  }
  SaveHotelactivityForHotel(): Observable<any> {
    var body = {
      ...this.formDatasmain,

      createdItems: this.createdItems,
      updatedItems: this.updatedItems,
      deletedItems: this.deletedItems,

    };

    return this.http.post<any>(this.apiUrl + 'SaveHotelactivityForHotel', body);
  }
  GetHotelSelectedActvities(): Observable<any> {
    var body = {
      ...this.formDatasmain,



    };

    return this.http.post<any>(this.apiUrl + 'GetHotelSelectedActvities', body);

  }
  addHotel(hoteldetail: any): Observable<any> {
    if (hoteldetail.BookingDate === null || hoteldetail.BookingDate === '')
      hoteldetail.BookingDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      hoteldetail.BookingDate = this.commonservice.ConvertToLocaleDate(hoteldetail.BookingDate);
    if (hoteldetail.DATEIN === null || hoteldetail.DATEIN === '')
      hoteldetail.DATEIN = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      hoteldetail.DATEIN = this.commonservice.ConvertToLocaleDate(hoteldetail.DATEIN);
    if (hoteldetail.DATEOUT === null || hoteldetail.DATEOUT === '')
      hoteldetail.DATEOUT = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      hoteldetail.DATEOUT = this.commonservice.ConvertToLocaleDate(hoteldetail.DATEOUT);
    if (hoteldetail.DepositDueDate === null || hoteldetail.DepositDueDate === '')
      hoteldetail.DepositDueDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      hoteldetail.DepositDueDate = this.commonservice.ConvertToLocaleDate(hoteldetail.DepositDueDate);
    if (hoteldetail.BalanceDueDate === null || hoteldetail.BalanceDueDate === '')
      hoteldetail.BalanceDueDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      hoteldetail.BalanceDueDate = this.commonservice.ConvertToLocaleDate(hoteldetail.BalanceDueDate);
    // if(hoteldetail.BookingDate==null)
    // hoteldetail.BookingDate="01-Jan-1900";
    // if(hoteldetail.DATEIN==null)
    // hoteldetail.DATEIN="01-Jan-1900";
    // if(hoteldetail.DATEOUT==null)
    // hoteldetail.DATEOUT="01-Jan-1900";
    // if(hoteldetail.DepositDueDate==null)
    // hoteldetail.DepositDueDate="01-Jan-1900";
    // if(hoteldetail.BalanceDueDate==null)
    // hoteldetail.BalanceDueDate="01-Jan-1900";
    if (hoteldetail.LOYALTYPOINTSAMT == null)
      hoteldetail.LOYALTYPOINTSAMT = 0;
    if (hoteldetail.LOYALTYPOINTS == null)
      hoteldetail.LOYALTYPOINTS = 0;

    if (hoteldetail.InterInd == "International")
      hoteldetail.InterInd = "I"
    else if (hoteldetail.InterInd == "Domestic")
      hoteldetail.InterInd = "D"
    else if (hoteldetail.InterInd == "Transborder")
      hoteldetail.InterInd = "T"
    else
      hoteldetail.InterInd = ""

    return this.http.post<any>(this.apiUrl + 'AddHotelDetails', hoteldetail, httpOptions).pipe(
      tap((hotelRes: any) => console.log(`added Hotel `)),
      catchError(this.commonservice.handleError<any>('addHotel'))
    );
  }

  updateHotel(hoteldetail: any): Observable<any> {
    if (hoteldetail.BookingDate === null)
      hoteldetail.BookingDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      hoteldetail.BookingDate = this.commonservice.ConvertToLocaleDate(hoteldetail.BookingDate);
    if (hoteldetail.DATEIN === null)
      hoteldetail.DATEIN = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      hoteldetail.DATEIN = this.commonservice.ConvertToLocaleDate(hoteldetail.DATEIN);
    if (hoteldetail.DATEOUT === null)
      hoteldetail.DATEOUT = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      hoteldetail.DATEOUT = this.commonservice.ConvertToLocaleDate(hoteldetail.DATEOUT);
    if (hoteldetail.DepositDueDate === null)
      hoteldetail.DepositDueDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      hoteldetail.DepositDueDate = this.commonservice.ConvertToLocaleDate(hoteldetail.DepositDueDate);
    if (hoteldetail.BalanceDueDate === null)
      hoteldetail.BalanceDueDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      hoteldetail.BalanceDueDate = this.commonservice.ConvertToLocaleDate(hoteldetail.BalanceDueDate);

    if (hoteldetail.LOYALTYPOINTSAMT == null)
      hoteldetail.LOYALTYPOINTSAMT = 0;
    if (hoteldetail.LOYALTYPOINTS == null)
      hoteldetail.LOYALTYPOINTS = 0;

    if (hoteldetail.InterInd == "International")
      hoteldetail.InterInd = "I"
    if (hoteldetail.InterInd == "Domestic")
      hoteldetail.InterInd = "D"
    if (hoteldetail.InterInd == "Transborder")
      hoteldetail.InterInd = "T"

    const url = `${this.apiUrl}` + 'UpdateHotelDetails';///${id}
    return this.http.post(url, hoteldetail, httpOptions).pipe(
      tap(_ => console.log(`updated Hotel`)),
      catchError(this.commonservice.handleError<any>('updateHotel'))
    );
  }




  deleteHotel(folderNo: number, GuidItinerary: string): Observable<any> {

    const url = `${this.apiUrl}` + 'DeleteHotelDetails' + `/${folderNo}/${GuidItinerary}`;

    return this.http.post(url, httpOptions).pipe(
      tap(_ => console.log(`delete Hotel`)),
      catchError(this.commonservice.handleError<any>('deleteHotel'))
    );
  }
  UpdateHote101HotelDetails(): Observable<any> {
    var body = {
      ...this.formdata,


    };

    return this.http.post<any>(this.apiUrl + 'UpdateHote101HotelDetails', body);
  }
  getHotelContactDetails(HotelCode: any): Observable<any> {
    const url = `${this.apiUrl}` + 'GetHotelContactDetails' + `/${HotelCode}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched Hotel id=${HotelCode}`)),
      catchError(this.commonservice.handleError<any>(`getHotel id=${HotelCode}`))
    );
  }

}
