import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { AirticketDetail } from "../Models/airticket-detail";
import { Observable, of, throwError } from "rxjs";
import { catchError, tap, map } from "rxjs/operators";
import { EnvService } from "../../env.service";
import { CommonserviceService } from "./commonservice.service";
import { AddAirTicketVoidDetailsDTO } from "../Models/add-air-ticket-void-details-dto.model";
import { UpdateAirTicketDetailsDTO } from "../Models/update-air-ticket-details-dto.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable({
  providedIn: "root"
})
export class AirticketService {
  apiUrl: string;
  Isvalidfarecurrency:boolean=true
  IsValidTaxCurrency:boolean=true;
  IssameFareAndTaxCurrency:boolean=true;
  constructor(
    private http: HttpClient,
    private env: EnvService,
    public commonservice: CommonserviceService
  ) {
    this.apiUrl = env.baseUrl;
  }

  //Supplierss:Object

  //getSuppliers (): Observable<HotelDetail[]> {

  ////return this.http.get<HotelDetail[]>(apiUrl)
  // .pipe(
  //   tap(heroes => console.log('fetched Suppliers')),
  // catchError(this.commonservice.handleError('getSuppliers', []))
  //);
  //}

  private newMethod(data: Object) {
    alert(data);
  }

  getAirTicketDetails(
    folderNo: number,
    GuidItinerary: string,
    isViewForm: boolean
  ): Observable<AirticketDetail> {
    const url =
      `${this.apiUrl}` +
      "GetAirticketDetailsById" +
      `/${folderNo}/${GuidItinerary}/${isViewForm}`;
    return this.http.get<AirticketDetail>(url).pipe(
      tap(_ => console.log(`fetched Air ticket id=${folderNo}`)),
      catchError(
        this.commonservice.handleError<AirticketDetail>(
          `getAirticket id=${folderNo}`
        )
      )
    );
  }
  UpdateAirticketDetails(airticketdetail: any): Observable<any> {
    if (airticketdetail.ADATE == null)
      airticketdetail.ADATE = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      airticketdetail.ADATE = this.commonservice.ConvertToLocaleDate(
        airticketdetail.ADATE
      );

    if (airticketdetail.BDATE == null)
      airticketdetail.BDATE = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      airticketdetail.BDATE = this.commonservice.ConvertToLocaleDate(
        airticketdetail.BDATE
      );

    if (airticketdetail.ADocDate == null)
      airticketdetail.ADocDate = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      airticketdetail.ADocDate = this.commonservice.ConvertToLocaleDate(
        airticketdetail.ADocDate
      );

    if (airticketdetail.POSTINGDATE == null)
      airticketdetail.POSTINGDATE = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      airticketdetail.POSTINGDATE = this.commonservice.ConvertToLocaleDate(
        airticketdetail.POSTINGDATE
      );

    if (airticketdetail.TicketingDeadline == null)
      airticketdetail.TicketingDeadline = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      airticketdetail.TicketingDeadline = this.commonservice.ConvertToLocaleDate(
        airticketdetail.TicketingDeadline
      );

    if (airticketdetail.DepositDueDate == null)
      airticketdetail.DepositDueDate = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      airticketdetail.DepositDueDate = this.commonservice.ConvertToLocaleDate(
        airticketdetail.DepositDueDate
      );

    if (airticketdetail.BalanceDueDate == null)
      airticketdetail.BalanceDueDate = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      airticketdetail.BalanceDueDate = this.commonservice.ConvertToLocaleDate(
        airticketdetail.BalanceDueDate
      );

    if (airticketdetail.CARDValidity == null)
      airticketdetail.CARDValidity = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      airticketdetail.CARDValidity = this.commonservice.ConvertToLocaleDate(
        airticketdetail.CARDValidity
      );
    if (airticketdetail.TICKDATE == null)
      airticketdetail.TICKDATE = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      airticketdetail.TICKDATE = this.commonservice.ConvertToLocaleDate(
        airticketdetail.TICKDATE
      );

    if (airticketdetail.InterInd == "International")
      airticketdetail.InterInd = "I";
    if (airticketdetail.InterInd == "Domestic") airticketdetail.InterInd = "D";
    if (airticketdetail.InterInd == "Transborder")
      airticketdetail.InterInd = "T";

    if (airticketdetail.JOURNEY == "Return")
      //By case id-286
      airticketdetail.JOURNEY = 1;
    else if (airticketdetail.JOURNEY == "One way") airticketdetail.JOURNEY = 2;
    else airticketdetail.JOURNEY = 0;
    const url = `${this.apiUrl}` + "UpdateAirticketDetails"; ///${id}
    return this.http.post(url, airticketdetail, httpOptions).pipe(
      tap(_ => console.log(`updated Airticket`)),
      catchError(this.commonservice.handleError<any>("updateAirticket"))
    );
  }
  addAirticketDetails(AirticketDetail: any): Observable<any> {
    debugger;
    if (AirticketDetail.ADATE === null || AirticketDetail.ADATE === "")
      AirticketDetail.ADATE = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      AirticketDetail.ADATE = this.commonservice.ConvertToLocaleDate(
        AirticketDetail.ADATE
      );

    if (AirticketDetail.BDATE === null || AirticketDetail.BDATE === "")
      AirticketDetail.BDATE = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      AirticketDetail.BDATE = this.commonservice.ConvertToLocaleDate(
        AirticketDetail.BDATE
      );

    if (AirticketDetail.ADocDate === null || AirticketDetail.ADocDate === "")
      AirticketDetail.ADocDate = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      AirticketDetail.ADocDate = this.commonservice.ConvertToLocaleDate(
        AirticketDetail.ADocDate
      );

    if (
      AirticketDetail.POSTINGDATE === null ||
      AirticketDetail.POSTINGDATE === ""
    )
      AirticketDetail.POSTINGDATE = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      AirticketDetail.POSTINGDATE = this.commonservice.ConvertToLocaleDate(
        AirticketDetail.POSTINGDATE
      );

    if (
      AirticketDetail.TicketingDeadline === null ||
      AirticketDetail.TicketingDeadline === ""
    )
      AirticketDetail.TicketingDeadline = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      AirticketDetail.TicketingDeadline = this.commonservice.ConvertToLocaleDate(
        AirticketDetail.TicketingDeadline
      );

    if (
      AirticketDetail.DepositDueDate === null ||
      AirticketDetail.DepositDueDate === ""
    )
      AirticketDetail.DepositDueDate = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      AirticketDetail.DepositDueDate = this.commonservice.ConvertToLocaleDate(
        AirticketDetail.DepositDueDate
      );

    if (
      AirticketDetail.BalanceDueDate === null ||
      AirticketDetail.BalanceDueDate === ""
    )
      AirticketDetail.BalanceDueDate = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      AirticketDetail.BalanceDueDate = this.commonservice.ConvertToLocaleDate(
        AirticketDetail.BalanceDueDate
      );
    if (
      AirticketDetail.CARDValidity === null ||
      AirticketDetail.CARDValidity === ""
    )
      AirticketDetail.CARDValidity = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      AirticketDetail.CARDValidity = this.commonservice.ConvertToLocaleDate(
        AirticketDetail.CARDValidity
      );
    if (AirticketDetail.TICKDATE == null)
      AirticketDetail.TICKDATE = this.commonservice.ConvertToLocaleDate(
        new Date("01-Jan-1900")
      );
    else
      AirticketDetail.TICKDATE = this.commonservice.ConvertToLocaleDate(
        AirticketDetail.TICKDATE
      );

    if (AirticketDetail.InterInd == "International")
      AirticketDetail.InterInd = "I";
    else if (AirticketDetail.InterInd == "Domestic")
      AirticketDetail.InterInd = "D";
    else if (AirticketDetail.InterInd == "Transborder")
      AirticketDetail.InterInd = "T";
    else AirticketDetail.InterInd = "";
    if (AirticketDetail.JOURNEY == "Return")
      //By case id-286
      AirticketDetail.JOURNEY = 1;
    else if (AirticketDetail.JOURNEY == "One way") AirticketDetail.JOURNEY = 2;
    else AirticketDetail.JOURNEY = 0;

    return this.http
      .post<any>(
        this.apiUrl + "AddAirTicketDetails",
        AirticketDetail,
        httpOptions
      )
      .pipe(
        tap((AirticketRes: any) => console.log(`added Airticket w/ id`)),
        catchError(this.commonservice.handleError<any>("addAirticket"))
      );
  }
  DeleteAirTicketDetails(
    folderNo: number,
    GuidItinerary: string
  ): Observable<any> {
    const url =
      `${this.apiUrl}` +
      "DeleteAirTicketDetails" +
      `/${folderNo}/${GuidItinerary}`;

    return this.http.post(url, httpOptions).pipe(
      tap(_ => console.log(`delete AirTicket`)),
      catchError(this.commonservice.handleError<any>("deleteAirTicket"))
    );
  }

  UpdateAirTicketVoidDetails(
    airTicketVoidDetails: AddAirTicketVoidDetailsDTO
  ): Observable<any> {
    debugger;
    return this.http
      .post<any>(
        this.apiUrl + "UpdateAirTicketVoidDetails",
        airTicketVoidDetails,
        httpOptions
      )
      .pipe(
        tap(_ => console.log(`Update AirTicket Void Details`)),
        catchError(
          this.commonservice.handleError<any>("Update AirTicket Void Details")
        )
      );
  }
  UpdateAirTicketDetails(
    airTicketDetails: UpdateAirTicketDetailsDTO
  ): Observable<any> {
    debugger;
    return this.http
      .post<any>(
        this.apiUrl + "UpdateAirTicketEditDetails",
        airTicketDetails,
        httpOptions
      )
      .pipe(
        tap(_ => console.log(`Update AirTicketDetails`)),
        catchError(
          this.commonservice.handleError<any>("Update AirTicketDetails")
        )
      );
  }
}
