import { Component, Input, OnInit } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
@Component({
  selector: 'app-notes-to-be-printed-view',
  templateUrl: './notes-to-be-printed-view.component.html',
  styleUrls: ['./notes-to-be-printed-view.component.scss']
})
export class NotesToBePrintedViewComponent implements OnInit {
  debugger;
  @Input() itineraryDetails; 
  constructor() { }
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;

  ngOnInit(): void {
    debugger;
   
  }

}
