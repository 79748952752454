import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from 'src/env.service';
import { CommonserviceService } from './commonservice.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class PassengerMasterService {
  apiUrl: string;
  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }

  addPassenger(objPassenger: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'AddPassengerMaster', objPassenger, httpOptions).pipe(
      //tap((tourRes: any) => console.log(`added Supplier w/ id=${tourRes.ResponseData.SUPPNAME}`)),
      // catchError(this.commonservice.handleError<any>('addTour'))
    );
  }
  GetPassengerDefaults(userID): Observable<any> {
    const url = `${this.apiUrl}` + 'GetPassengerDefaults' + `/${userID}`;
    return this.http.get<any>(url);
  }
  GetDefaultBAIDAndMCID(custCo): Observable<any> {
    const url = `${this.apiUrl}` + 'GetDefaultBAIDAndMCID' + `/${custCo}`;
    return this.http.get<any>(url);
  }
}
