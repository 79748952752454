import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { MenusModule } from "@progress/kendo-angular-menu";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { GridModule, ExcelModule, PDFModule } from "@progress/kendo-angular-grid";
import { PopupModule } from "@progress/kendo-angular-popup";
import { WindowModule, DialogModule, DialogsModule } from "@progress/kendo-angular-dialog";
import { NgxSpinnerModule } from "ngx-spinner";
import { TranslateModule } from "@ngx-translate/core";
import { SearchModule } from '../search/search.module';
import { SharedCommonModule } from '../SharedCommonModule/shared-common.module';
import { EpayMailComponent } from './epay-mail/epay-mail.component';
import { RemindersComponent } from './reminders/reminders.component';
import { FolderSummaryComponent } from './folder-summary/folder-summary.component';
import { DeliveryaddressComponent } from './deliveryaddress/deliveryaddress.component';
import { PassengerDetailComponent } from './passenger-detail/passenger-detail.component';
import { CustomerspecificComponent } from './customerspecific/customerspecific.component';
import { FolderSummaryEditComponent } from './folder-summary-edit/folder-summary-edit.component';
import { CommissionCalculationComponent } from './commission-calculation/commission-calculation.component';
import { RetailOrPackagefolderComponent } from './retail-or-packagefolder/retail-or-packagefolder.component';
import { PassengerMasterAddComponent } from './passenger-master-add/passenger-master-add.component';
import { CustomerspecificEditComponent } from './customerspecific-edit/customerspecific-edit.component';
// import { BookingConfirmationComponent } from '../SharedCommonModule/booking-confirmation/booking-confirmation.component';
import { CommissionCalculationStepComponent } from './commission-calculation-step/commission-calculation-step.component';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { FolderActivityDetailedComponent } from './folder-activity-detailed/folder-activity-detailed.component';
import { FolderaddExpenseComponent } from './folderadd-expense/folderadd-expense.component';
import { FolderActivityDetailedReportComponent } from './folder-activity-detailed-report/folder-activity-detailed-report.component';
import { DeliveryaddresseditComponent } from './deliveryaddressedit/deliveryaddressedit.component';
import { PurgeAlertsComponent } from './purge-alerts/purge-alerts.component';
import { DelinkReceiptFromFolderComponent } from './delink-receipt-from-folder/delink-receipt-from-folder.component';
import { UnlockdocumentComponent } from './unlockdocument/unlockdocument.component';
import { AirlineDetailedReportComponent } from './airline-detailed-report/airline-detailed-report.component';
import { ProductModule } from '../product/product.module';
import { CustomerreceiptsComponent } from './customerreceipts/customerreceipts.component';
import { CustomerpdqreceiptComponent } from './customerpdqreceipt/customerpdqreceipt.component';
import { PDQMotoCreditCardDetailsComponent } from './pdqmoto-credit-card-details/pdqmoto-credit-card-details.component';
import { CustomerbankreceiptComponent } from './customerbankreceipt/customerbankreceipt.component';
import { PaymentstripComponent } from './paymentstrip/paymentstrip.component';
import { PaymentComponent } from './payment/payment.component';
import { FolderNotesComponent } from './folder-notes/folder-notes.component';
import { FolderoptionsComponent } from './folderoptions/folderoptions.component';
import { FolderhistoryComponent } from './folderhistory/folderhistory.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { EditorModule } from "@progress/kendo-angular-editor";
import { InternalNotesComponent } from './internal-notes/internal-notes.component';
import { RevenueRecognitionPolicyComponent } from './revenue-recognition-policy/revenue-recognition-policy.component';
import { AgentCommissionComponent } from './agent-commission/agent-commission.component';
import { DetailsComponent } from './details/details.component';
import { InstallmentPlanComponent } from './installment-plan/installment-plan.component';
import { PnrSourcefileComponent } from './pnr-sourcefile/pnr-sourcefile.component';
import { FolderRoutingModule } from './folder-routing.module';
// import { AirsegDetailsComponent } from './airseg-details/airseg-details.component';
// import { AirsegDetailsEditComponent } from './airseg-details-edit/airseg-details-edit.component';
// import { RailsegDetailsComponent } from './railseg-details/railseg-details.component';
// import { RailsegDetailsEditComponent } from './railseg-details-edit/railseg-details-edit.component';

@NgModule({
  declarations: [
    FolderSummaryComponent,
    FolderSummaryEditComponent,
    FolderActivityDetailedComponent,
    FolderaddExpenseComponent,
    FolderActivityDetailedReportComponent,
    RetailOrPackagefolderComponent,
    // BookingConfirmationComponent,
    EpayMailComponent,
    RemindersComponent,
    CommissionCalculationComponent,
    CommissionCalculationStepComponent,
    PassengerDetailComponent,
    CustomerspecificComponent,
    CustomerspecificEditComponent,
    DeliveryaddressComponent,
    PassengerMasterAddComponent,
    CustomerEditComponent,
    DeliveryaddresseditComponent,
    DelinkReceiptFromFolderComponent,
    UnlockdocumentComponent,
    PurgeAlertsComponent,
    AirlineDetailedReportComponent,
    CustomerreceiptsComponent,
    CustomerpdqreceiptComponent,
    PDQMotoCreditCardDetailsComponent,
    CustomerbankreceiptComponent,
    PaymentstripComponent,
    PaymentComponent,
    FolderNotesComponent,
    FolderoptionsComponent,
    FolderhistoryComponent,
    InvoiceComponent,
    InternalNotesComponent,
    RevenueRecognitionPolicyComponent,
    AgentCommissionComponent,
    DetailsComponent,
    InstallmentPlanComponent,
    PnrSourcefileComponent,
    // RailsegDetailsComponent,
    // RailsegDetailsEditComponent,
    // AirsegDetailsComponent,
    // AirsegDetailsEditComponent
  ],

  imports: [
    CommonModule,
    DialogModule,
    DialogsModule,
    ExcelModule,
    SharedCommonModule,
    SearchModule,
    InputsModule,
    FormsModule,
    LayoutModule,
    ReactiveFormsModule,
    WindowModule,
    ButtonsModule,
    NgxSpinnerModule,
    TranslateModule,
    RouterModule,
    TreeViewModule,
    MenusModule,
    DateInputsModule,
    DropDownsModule,
    GridModule,
    PDFModule,
    PopupModule,
    ProductModule,
    EditorModule,
    FolderRoutingModule

    
  ],
  exports: [
    // BookingConfirmationComponent,
    DetailsComponent,
    FolderSummaryComponent,
    FolderSummaryEditComponent,
    RemindersComponent,
    RetailOrPackagefolderComponent,   
  ],
  providers:[FolderSummaryComponent,FolderSummaryEditComponent,DetailsComponent],
})
export class FolderModule { }
