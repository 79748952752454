import { LookUpDetails } from "../Services/look-up-details";
import { AirticketDetail } from "../Models/airticket-detail";
import { DeletePO } from "../Models/delete-po";
import { FileDetails } from "../Models/file-details";
import { Deletepo } from "../Models/deletepo";
import { Pocreationresult } from "../Models/pocreationresult";
import { FolderSummary } from "../Models/folder-summary";
import { Subscription } from "rxjs";
import { POCreationDetail } from "../Models/pocreation-detail";
import { PendoclockService } from "../Services/pendoclock.service";
import { ShareDataService } from "../Services/share-data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { FileService } from "../Services/file.service";
import { LookUpDetailsService } from "../Services/look-up-details.service";

export abstract class AirticketDetailsView {
  //#region constructor
  constructor(
    public apiShared: ShareDataService,
    public spinner: NgxSpinnerService,
    public apiFile: FileService,
    public lookupApi: LookUpDetailsService
  ) { }
  //#endregion constructor
  //#region object declaration
  public rdCheckedWex=false;
  public isWexEnabled:boolean=false;
  MerchantLogUniqueId:any='';
  isShownWEX: boolean = false ;
  DisableFC_Tot_Buy:boolean=true;
  ShownCarddetails: boolean = false ;
  SupplierRef:any="";
  CardNo:any='';
  CID:any='';
  CardSecurityCode:any='';
  CardExpiryDate:any='';
  suppcode:any=0;
  PDQ:any=0;
  DateOfTravel:any;
  wexpocreated:boolean=false;

  hcCurrency: any;
  buyExRate: any;
  public rdCheckedCust = false;
  public rdCheckedCmp = false;
  public rdCheckedCr = false;
  isShownCust: any = false;
  isShowncm: any = false;
  isShownCmp: boolean = false;
  isBSP: boolean = false;
  public subscription: Subscription = new Subscription();
  public labelchange = false;
  lockStatus: any;
  foldersummaryDetails: FolderSummary;
  Pocreationresultobj: Pocreationresult;
  public formeditable = true;
  public result: any;
  public pocreated = true;
  public deletePO: DeletePO;
  fileDetail: FileDetails;
  public DeleteInvoiceResponse: Deletepo;
  public confirmMsg: any;
  public showMsg: any;
  public PONO: number;
  folderExrate: any;
  fldrCurrency: any;
  projNo: any;
  public exRate: any;
  folderEditMode: any;
  loaded = false;
  loadedfile = false;
  showOrder = true; //By case id-367
  public POCreation: POCreationDetail;
  public language = this.apiShared.LanguageSelected;
  BSPTAXEnable: any;
  Producttype: any = false;
  buycurrency: any;
  divValue: any;
  CostingVatType: any = "No VAT";
  hcExRate: any;
  public folderstatus: any = true;
  public folderStatus: any;
  isShown = false;
  divVat = false;

  enableUnusedTicket: any; //355
  public invoice = false;
  public createPoInvoice = false;
  public FolderQuoteCreatePO = false;
  public FolderQuoteDeletePO = false;
  public DisableAirTicketADMACM = true; //By Caseid-12641
  public voidNewTicketDate = false; //By Caseid-12650
  public voidNewTicketNumber = false; //By Caseid-12650
  public showMessage: any; //By Caseid-12650
  public DisableAirticketVoidSave = false; //By Caseid-12650
  public submitted = false; //By Caseid-12650
  public NewTicketDate: any; //By Caseid-12650
  public VoidTicketDate: any; //By Caseid-12650
  public DisableAirticketADMACMSave = false; //By Caseid-12650
  public voidPoNotCreated = false;
  public DisableAirTicketVoid = true; //By Caseid-12650
  public AirTicketVoidDetails: any = {
    //By Caseid-12650
    VoidTicketNumber: "",
    VoidTicketDate: "",
    FOLDERNO: "",
    UserId: "",
    PROJNO: "",
    GuId: "",
    PNR: "",
    NewTicketNumber: "",
    NewTicketDate: ""
  };

  public AirTicketEditDetails: any = {
    //By Caseid-12760
    EditTicketNumber: "",
    EditTicketDate: "",
    FOLDERNO: "",
    UserId: "",
    PROJNO: "",
    GuId: "",
    PNR: "",
    GDS: "",
    IATANo: "",
    NewEditTicketNumber: "",
    NewEditTicketDate: "",
    PRTYCODE: ""
  };
  public fghgh: any;
  public DisableAirTicketEdit = false; //By Caseid-12760
  IATANOList: Array<LookUpDetails["ResponseData"]> = []; //Case id-12760
  public IATANoSelectedItem: LookUpDetails["ResponseData"]; //Case id-12760
  GDSList: Array<LookUpDetails["ResponseData"]> = []; //Case id-12760
  public GDSselectedItem: LookUpDetails["ResponseData"]; //Case id-12760
  public DisableAirticketUpdate = false; //By Caseid-12760
  public NewTicketDateEdit: any; ///By Caseid-12760
  public TicketDateEdit: any; ///By Caseid-12760
  Tax: Array<LookUpDetails["ResponseData"]> = [];
  public TaxselectedItem: LookUpDetails["ResponseData"];

  public OutTaxselectedItem: LookUpDetails["ResponseData"];
  isShownMore = false; //337
  public ticketDate: any;
  public FolderHideStatus = false; //12780
  airticketDetail: AirticketDetail = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {
      FOLDERNO: 0,
      PASSNAME: "",
      TICKETNO: "",
      TYPE: "",
      AIRLINE: "",
      AIRFROM: "",
      AIRTO: "",
      PAYABLE: 0,
      PRTYCODE: 0,
      SUPPNAME: "",
      STATUS: "",
      VLOCATOR: "",
      FNAME: "",
      GDS: "",
      BSP: 0,
      JOURNEY: 0,
      TICKDATE: "01/Jan/1900",
      BOOKBY: "",
      IATANO: "",
      BDATE: "",
      ADATE: "",
      FREMARKS: "",
      CURR: "GBP",
      FARE: "",
      EXCHANGE: 1,
      FARENAME: "",
      FAREBASIS: "",
      TYPE1: "",
      DESC1: "",
      BUY1: 0,
      COMMP1: 0,
      COMMA1: 0,
      SELL1: 0,
      TYPE2: "",
      DESC2: "",
      BUY2: 0,
      COMMP2: 0,
      COMMA2: 0,
      SELL2: 0,
      TYPE3: "",
      DESC3: "",
      BUY3: 0,
      COMMP3: 0,
      COMMA3: 0,
      SELL3: 0,
      TYPE4: "",
      DESC4: "",
      BUY4: 0,
      COMMP4: 0,
      COMMA4: 0,
      SELL4: 0,
      BUYTOT: 0,
      COMMPTOT: 0,
      COMMATOT: 0,
      SELLTOT: 0,
      COMONLY: "",
      Remark: "",
      VairlineAIRWAYSCODE: 0,
      VairlineAIRNAME: "",
      ATYPE: "",
      AMODE: "",
      SLNO: 0,
      PROJNO: "",
      VairlineAIRID: "",
      TKTCODE: "",
      DISCPER: 0,
      DISCAMT: 0,
      NETSELL: 0,
      CommOn: "",
      ITYPE: "Ticket",
      TYPE5: "",
      DESC5: "",
      BUY5: 0,
      COMMP5: 0,
      COMMA5: 0,
      SELL5: 0,
      LowFB: "",
      LowFare: 0,
      HighFB: "",
      HighFare: 0,
      Fareoffered: 0,
      PotentialSaving: 0,
      ActualSaving: 0,
      CARDID: 0,
      CARDNUMBER: "",
      CARDValidity: "",
      CARDAUTHCODE: "",
      PNR: "",
      PDNO: 0,
      PDTYPENO: 0,
      ADocDate: "",
      VAIRNO: "",
      Forgone: 0,
      AddRemarks: "",
      SupplierNotes: "",
      POSTINGDATE: "",
      InterInd: "",
      CancelledBooking: 0,
      TicketingDeadline: "",
      PDAMOUNT: 0,
      TITLE: "",
      PONO: 0,
      PONOYEARID: "",
      PAYMENTID: 0,
      PMTID: "",
      DEFDEPPER: 0,
      DEFDEP: 0,
      DepositDueDate: "",
      BalanceDueDate: "",
      BalanceDueAmount: 0,
      PDFCURID: "",
      PDEXRATE: 0,
      PDFCAMT: 0,
      ITSplit1: "",
      ITSplit1AMT: 0,
      ITSplit2: "",
      ITSplit2AMT: 0,
      ITSplit3: "",
      ITSplit3AMT: 0,
      ITSplit4: "",
      ITSplit4AMT: 0,
      ITSplit5: "",
      ITSplit5AMT: 0,
      ITSplit6: "",
      ITSplit6AMT: 0,
      ITSplit7: "",
      ITSplit7AMT: 0,
      ITSplit8: "",
      ITSplit8AMT: 0,
      ITSplit9: "",
      ITSplit9AMT: 0,
      ITSplit10: "",
      ITSplit10AMT: 0,
      ITSplit11: "",
      ITSplit11AMT: 0,
      ITSplit12: "",
      ITSplit12AMT: 0,
      FC_Fare_Buy: 0,
      FC_Fare_CommAmt: 0,
      FC_Fare_Sell: 0,
      FC_Tax1_Buy: 0,
      FC_Tax1_CommAmt: 0,
      FC_Tax1_Sell: 0,
      FC_Tax1_CID: "GBP",
      FC_Tax1_CIDExRate: 0,
      FC_Tax2_Buy: 0,
      FC_Tax2_CommAmt: 0,
      FC_Tax2_Sell: 0,
      FC_Tax2_CID: "GBP",
      FC_Tax2_CIDExRate: 0,
      FC_Tax3_Buy: 0,
      FC_Tax3_CommAmt: 0,
      FC_Tax3_Sell: 0,
      FC_Tax3_CID: "GBP",
      FC_Tax3_CIDExRate: 0,
      FC_Tax4_Buy: 0,
      FC_Tax4_CommAmt: 0,
      FC_Tax4_Sell: 0,
      FC_Tax4_CID: "GBP",
      FC_Tax4_CIDExRate: 0,
      FC_Tot_Buy: 0,
      FC_Tot_CommAmt: 0,
      FC_Tot_Sell: 0,
      FC_DISCAMT: 0,
      FC_NETSELL: 0,
      UNUSEDTICKET: 0,
      GUIDItinerary: "",
      TICKETORDER: 0,
      LOYALTYPOINTS: 0,
      LOYALTYPOINTSMEMBNO: "",
      TOMSVATTAXNO: 0,
      TOMSVATAMT: 0,
      FCCommPer: 0,
      FCCommAmt: 0,
      FCSell: 0,
      HCCommPer: 0,
      HCCommAmt: 0,
      HCSell: 0,
      LOYALTYPOINTSAMT: 0,
      LOYALTYPOINTSBAL: 0,
      TOMSVATCID: "",
      PDUNIQUEREF: "",
      ATD_PCC: "",
      TYPE6_Tax5: "",
      DESC6_Tax5: "",
      BUY6_Tax5: 0,
      COMMP6_Tax5: 0,
      COMMA6_Tax5: 0,
      SELL6_Tax5: 0,
      FC_Tax5_Buy: 0,
      FC_Tax5_CommAmt: 0,
      FC_Tax5_Sell: 0,
      FC_Tax5_CID: "GBP",
      FC_Tax5_CIDExRate: 0,
      PONOCCDeferredPosted: 0,
      BOOKEDBY: "",
      BAGGALLOW: "",
      TaxExcludingSalesTax: 0,
      BaseFare: 0,
      Reasoncode: "",
      FC_Tax1_CommPer: 0,
      FC_Tax2_CommPer: 0,
      FC_Tax3_CommPer: 0,
      FC_Tax4_CommPer: 0,
      FC_Tax5_CommPer: 0,
      FC_Tax6_CommPer: 0,
      TotalTax: 0,
      PurchaseTax: 0,
      OtherTax: 0,
      PurchaseValue: 0,
      GSTHSTOnComm: 0,
      PDQDESC: "",
      CRDDESC: "",
      PMTDESC: "",
      PAYM: "",

      DepCityName: "",
      ArVCityName: "",
      Markup: 0,
      CostofSale: 0,
      CostingSupplierGrossValue: 0,
      CostingCustomerSalesValue: 0,
      CostingCustomerServiceFee: 0,
      CostingCustomerNetSellPrice: 0,
      CostingVATType: "",
      CostingSupplierServiceFee: 0,
      CostingTaxNo: 0,
      CostingTaxRate: 0,
      CostingSupplierTaxAmount: 0,
      CostingSupplierNetBuyPrice: 0,
      CostingCustomerTaxAmount: 0,
      CostingSuppComm: 0,
      FCBuy: 0,
      CardValidityStr: "",
      SEATS: "",
      FareType: "",
      SupplierDeposit: 0,
      SELLTAXNO: 0,
      SELLTAXRATE: 0,
      FC_SELLTAXAMT: 0,
      HC_SELLTAXAMT: 0,
      SELLVAT: 0,
    }
  };
  //#endregion object declaration
  ngOnInit() { }
  //#region get FileDetails
  getFileDetails() {
    this.spinner.show();
    this.subscription.add(
      this.apiFile.GetCompanySettings("Ticketview").subscribe(data => {
        if (data != null && data != undefined) {
          this.fileDetail = data;
          this.BSPTAXEnable = this.fileDetail.ResponseData.BSPTAXEnable;
          this.spinner.hide();
          this.loadedfile = true;
        }
      })
    );
  }
  //#endregion get FileDetails
  //#region LoadAirTicketGDS
  public LoadAirTicketGDS() {
    this.subscription.add(
      this.lookupApi.GetAllLookup("GDS").subscribe(data => {
        if (data != null && data != undefined && data["ResponseData"] != null) {
          this.GDSList = data["ResponseData"];
          if (this.airticketDetail.ResponseData.GDS != "") {
            this.GDSselectedItem = this.GDSList.find(
              item => item.Name == this.airticketDetail.ResponseData.GDS
            );
          }
        }
      })
    );
  }
  //#endregion LoadAirTicketGDS
  //#region LoadAirTicketIataNumber
  public LoadAirTicketIataNumber() {
    debugger;
    this.subscription.add(
      this.subscription.add(
        this.lookupApi.GetAllLookup("IATANO").subscribe(
          //By Eldhose for case id-12760
          data => {
            if (data != null && data != undefined) {
              debugger;
              this.IATANOList = data["ResponseData"];
              if (this.airticketDetail.ResponseData.IATANO != "") {
                debugger;
                this.IATANoSelectedItem = this.IATANOList.find(
                  item => item.Name == this.airticketDetail.ResponseData.IATANO
                );
              }
            }
          }
        )
      )
    );
  }
  //#endregion LoadAirTicketIataNumber

  //#region ShowPermissionMessageForm
  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true; //Caseid-339
    this.PermissionMessageId = permissionid; //Caseid-339
  }
  public permissionmessageformopened: any; //Caseid-339
  public PermissionMessageId: any; //Caseid-339
  //#endregion ShowPermissionMessageForm

  //#region PermissionFormClosing
  public PermissionFormClosing(
    status: any //339
  ) {
    if (status == "EmailSendSuccesfully") {
      //339
      this.permissionmessageformopened = false;
    }
  }
  //#endregion PermissionFormClosing
  //#region showmsg
  showmsg(permissionid: string) {
    this.permissionmessageformopened = true; //Caseid-339
    this.PermissionMessageId = permissionid; //Caseid-339
  }
  //#endregion showmsg

  toggleShowWexPay()
  {
    this.isShownCmp =false;
    this.isShown =false;
    this.isShownCust=false;
    this.isShownWEX=true;
  }
}
