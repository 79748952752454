import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { CruiseDetail } from '../../Models/cruise-detail';
import { CruiseService } from '../../Services/cruise.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { CurrencyExchangerateService } from '../../Services/currency-exchangerate.service';
import { CurrencyExchangerate } from '../../Models/currency-exchangerate';
import { ShareDataService } from '../../Services/share-data.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription, Subject } from 'rxjs';
import { FileService } from '../../Services/file.service';
import { SupplierMasterService } from '../../Services/supplier-master.service';
import { ToastrService } from 'ngx-toastr';
import { ProductUpdatePODTO } from '../../Models/pocreation-detail';
import { PocreationService } from '../../Services/pocreation.service';
import { FolderpriceService } from '../../Services/folderprice.service';
/* import { ShareDataService } from '../Services/share-data.service'; */

@Component({
  selector: 'app-cruise-details-edit',
  templateUrl: './cruise-details-edit.component.html',
  styleUrls: ['./cruise-details-edit.component.scss']
})


export class CruiseDetailsEditComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  @ViewChild("apppaymenttermsedit", { read: false, static: false }) public payterm: any;
  @ViewChild("appfchcdetailsedit", { read: false, static: false }) public fchcdetails: any;

  public opened = false;
  txtDisabled = true;
  folderEditMode: any;
  currencyExrate: CurrencyExchangerate;
  bsptaxenable: number;
  taxenabled = false;
  openSearch = false;

  ucode: string;
  erate: string;
  cruiseEditForm: FormGroup;
  submitted = false;
  loaded = false;
  Uname: any = "";
  folderExrate: any;
  prdtUpdatePODTO: ProductUpdatePODTO;
  fldrCurrency: any;
  private exRate: any;
  homeCurrency: any;
  savebutton = true;
  public hcExRate: any;
  public projNo: any;
  public showMsg: any;
  public statusList: Array<string> = ["Wait listed", "Confirmed", "Cancelled"];
  public commissinOnList: Array<string> = ["Purchase", "Sales", "Gross"];
  public cabinTypeList: Array<string> = ["Inside", "Outside", "Balcony", "Suite"];
  public pocreated = true;
  bookingDate: any //337
  embarkDate: any //337
  disEmbarkDate: any //337
  depositDueDate: any//337
  public PONO: number;
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  creditLimitMsg = false;
  creditLimiFlag = false;
  FC_Tot_BuyInitalvalue: any = 0;
  FCSell: any = 0;
  msg: any;
  constructor(private apiPO: PocreationService, public commonservice: CommonserviceService, private router: Router, private route: ActivatedRoute,
    private formBuilder: FormBuilder, private api: CruiseService, private fileservice: FileService, private toastr: ToastrService,
    private lookupApi: LookUpDetailsService, private apiExRate: CurrencyExchangerateService, private supplier: SupplierMasterService,
    private apiShared: ShareDataService, private flashMessage: FlashMessagesService, private translateapi: TranslateService, private spinner: NgxSpinnerService, private objFolderPrice: FolderpriceService) {
    this.subscription.add(this.lookupApi.GetAllLookup('CURRENCY').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.Currency = data["ResponseData"]
        }

      }
    ));
  }

  private guid: string = this.route.snapshot.params['guidItinerary'];//'Cruise.20190826.103628.{B9CB75FE-A3D6-46DE-926E-27990C46ECEC}/';
  private orderno = this.route.snapshot.params['orderNo']

  Currency: Array<LookUpDetails["ResponseData"]> = [];
  public CurrencyselectedItem: LookUpDetails["ResponseData"];

  public BookedByselectedItem: LookUpDetails["ResponseData"];
  BookedBy: Array<LookUpDetails["ResponseData"]> = [];
  lockStatus: any;
  folderStatus: any;
  formeditable: any;
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];
  public supplierSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelected: String;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  ngOnInit() {

    //case id : 13777 
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    } else {
      this.formeditable = false;

    }
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;

    }
    // case id : 13777


    // this.fileservice.getFileDetails().subscribe(x=>{
    this.fileservice.GetCompanySettings("Cruise").subscribe(x => {
      if (x != null && x != undefined) {
        this.bsptaxenable = x.ResponseData.BSPTAXEnable;
      }

      if (this.bsptaxenable === 1) {
        this.taxenabled = true;
      } else {
        this.taxenabled = false;
      }
    });
    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderno !== orderno) {
        return this.router.navigate(['PenAir/Home'], { skipLocationChange: true });
      }
    this.homeCurrency = this.apiShared.homeCurrency;
    this.hcExRate = this.apiShared.hcExRate;

    this.folderEditMode = "true";
    // this.apiShared.folderEditMode=this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.Uname = this.apiShared.uname;
    this.getFolderValues();

    this.cruiseEditForm = this.formBuilder.group({

      FOLDERNO: [this.orderno],
      GUIDItinerary: [''],
      SUPPCODE: ['', Validators.required],
      SUPPNAME: [''],
      TYPE: ['Cruise'],
      DATEF: [new Date()],
      DATET: [new Date()],
      BOOKEDBY: this.Uname,
      // BookingDate:  new Date('01/01/1900').toLocaleDateString('en-GB', {
      //   day: 'numeric', month: 'short', year: 'numeric'
      // }).replace(/ /g, '/'),
      BookingDate: [new Date()],
      DEPTIME: [0],
      CURCODE: [this.homeCurrency],
      Currency: [''],
      CURExRate: [this.hcExRate],
      AMTLC: [0],
      AMTHC: [0],
      PAYMODE: [''],
      LOYALTYPOINTSMEMBNO: [''],
      LOYALTYPOINTS: [''],
      LOYALTYPOINTSAMT: [''],
      TOCRUISE: [''],
      VOUCHER: [''],
      CONFIRMATIONNO: [''],
      STATUS: ['Confirmed'],
      FROMCRUISE: [''],
      Ship: [''],
      Category: [''],
      CabinType: [''],
      CommOn: [''],
      Provider: [''],
      StateroomNo: [''],
      ARRTIME: [0],
      DEPDATE: [new Date()],
      ARRDATE: [new Date()],
      AMOUNT: [0],
      VATCODE: [0],
      VATPER: [0],
      ITYPE: ['Cruise'],
      GDS: [''],
      PNR: [''],
      LowFB: [''],
      LowFare: [0],
      HighFB: [''],
      HighFare: [0],
      Fareoffered: [0],
      PotentialSaving: [0],
      ActualSaving: [0],
      PDNO: [0],
      PDTYPENO: [0],
      Forgone: [0],
      AddRemarks: [''],
      ItnryNotes: [''],
      FCBuy: [0],
      FCCommPer: [0],
      FCCommAmt: [0],
      FCSell: [0],
      HCCommPer: [0],
      HCCommAmt: [0],
      HCSell: [0],
      EmbarkationTime: [0],
      DisembarkationTime: [0],
      PDAMOUNT: [0],
      PONO: [0],
      PONOYEARID: [''],
      PAYMENTID: [0],
      PMTID: [''],
      DEFDEPPER: [0],
      DEFDEP: [0],
      DepositDueDate: [''],
      BalanceDueDate: [''],
      BalanceDueAmount: [0],
      EmbarkationDate: [''],
      DisembarkationDate: [''],
      TOMSVATTAXNO: [0],
      TOMSVATAMT: [0],
      PDUNIQUEREF: [''],
      PDFCURID: [this.homeCurrency],
      PDEXRATE: [this.hcExRate],
      PDFCAMT: [0],
      SELLTAXNO: [0],
      SELLTAXRATE: [0],
      FC_SELLTAXAMT: [0],
      HC_SELLTAXAMT: [0],
      PONOCCDeferredPosted: [0],

      TaxExcludingSalesTax: [0],
      BaseFare: [0],
      PROJNO: [this.projNo],
      GSTHSTOnComm: 0,
      CostingSupplierGrossValue: 0,
      CostingSupplierServiceFee: 0,
      CostingTaxNo: 0,
      CostingTaxRate: 0,
      CostingSupplierTaxAmount: 0,
      CostingSupplierNetBuyPrice: 0,
      CostingCustomerSalesValue: 0,
      CostingCustomerServiceFee: 0,
      CostingCustomerTaxAmount: 0,
      CostingCustomerNetSellPrice: 0,
      CostingVATType: 0,
      Markup: [0],
      CostofSale: [0],
      CostingSuppComm: [0],
      SupplierDeposit: [0],
      CountryID: ['']

    });

    if (this.guid != null && this.guid != '') {
      this.getCruiseDetails(this.orderno, this.guid);


    }
    else {
      this.loaded = true;

      this.FormLoyalityPointsChange();
      this.subscription.add(this.lookupApi.GetAllLookup('CURRENCY').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.Currency = data["ResponseData"]

            if (this.cruiseEditForm.controls.CURCODE.value != "") {

              this.CurrencyselectedItem = this.Currency.find(item => item.Code == this.cruiseEditForm.controls.CURCODE.value);
            }
          }
        }
      ));

      // if(this.homeCurrency!="")
      //       {    
      //      this.CurrencyselectedItem =this.Currency.find(item => item.Code ==this.homeCurrency);    
      // }

    }

    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.BookedBy = data["ResponseData"]
          if (this.cruiseEditForm.controls.BOOKEDBY.value != "") {
            this.BookedByselectedItem = this.BookedBy.find(item => item.Name == this.cruiseEditForm.controls.BOOKEDBY.value);

            //console.log("test:-"+this.cruiseEditForm.controls.BOOKEDBY.value+JSON.stringify(this.BookedByselectedItem , null, 4));
          }
        }
      }));




  }

  get f() { return this.cruiseEditForm.controls; }

  getCruiseDetails(id, guid) {
    this.spinner.show();
    this.subscription.add(this.api.getCruise(id, guid, false)
      .subscribe(data => {
        if (data != null && data != undefined) {
          this.cruiseEditForm.setValue({
            FOLDERNO: data.ResponseData.FOLDERNO,
            GUIDItinerary: data.ResponseData.GUIDItinerary,
            SUPPCODE: data.ResponseData.SUPPCODE,
            SUPPNAME: data.ResponseData.SUPPNAME,
            Provider: data.ResponseData.Provider,
            TYPE: data.ResponseData.TYPE,
            FROMCRUISE: data.ResponseData.FROMCRUISE,
            TOCRUISE: data.ResponseData.TOCRUISE,
            DATEF: data.ResponseData.DEFDEP != null ? (data.ResponseData.DEFDEP.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.DEFDEP)) : null,
            //DATEF:new Date(data.ResponseData.DEFDEP),
            DATET: data.ResponseData.ARRDATE != null ? (data.ResponseData.ARRDATE.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.ARRDATE)) : null,
            // DATET:new Date(data.ResponseData.ARRDATE),
            DEPTIME: data.ResponseData.DEPTIME,
            BOOKEDBY: data.ResponseData.BOOKEDBY,
            BookingDate: data.ResponseData.BookingDate != null ? (data.ResponseData.BookingDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.BookingDate)) : null,
            // BookingDate:new Date(data.ResponseData.BookingDate),
            CURCODE: data.ResponseData.CURCODE,
            Currency: data.ResponseData.CURCODE,
            CURExRate: data.ResponseData.CURExRate,
            AMTLC: data.ResponseData.AMTLC,
            AMTHC: data.ResponseData.AMTHC,
            PAYMODE: data.ResponseData.PAYMODE,
            LOYALTYPOINTSMEMBNO: data.ResponseData.LOYALTYPOINTSMEMBNO,
            LOYALTYPOINTS: data.ResponseData.LOYALTYPOINTS,
            LOYALTYPOINTSAMT: data.ResponseData.LOYALTYPOINTSAMT,
            VOUCHER: data.ResponseData.VOUCHER,
            CONFIRMATIONNO: data.ResponseData.CONFIRMATIONNO,
            CommOn: data.ResponseData.CommOn,
            Ship: data.ResponseData.Ship,
            CabinType: data.ResponseData.CabinType,
            Category: data.ResponseData.Category,
            StateroomNo: data.ResponseData.StateroomNo,
            STATUS: data.ResponseData.STATUS,
            ARRTIME: data.ResponseData.ARRTIME,
            DEPDATE: data.ResponseData.DEPDATE != null ? (data.ResponseData.DEPDATE.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.DEPDATE)) : null,
            ARRDATE: data.ResponseData.ARRDATE != null ? (data.ResponseData.ARRDATE.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.ARRDATE)) : null,
            //DEPDATE :new Date(data.ResponseData.DEPDATE),
            //ARRDATE :new Date(data.ResponseData.ARRDATE),
            AMOUNT: data.ResponseData.AMOUNT,
            VATCODE: data.ResponseData.VATCODE,
            VATPER: data.ResponseData.VATPER,
            ITYPE: 'Cruise',//data.ResponseData.ITYPE,
            GDS: data.ResponseData.GDS,
            PNR: data.ResponseData.PNR,
            LowFB: data.ResponseData.LowFB,
            LowFare: data.ResponseData.LowFare,
            HighFB: data.ResponseData.HighFB,
            HighFare: data.ResponseData.HighFare,
            Fareoffered: data.ResponseData.Fareoffered,
            PotentialSaving: data.ResponseData.PotentialSaving,
            ActualSaving: data.ResponseData.ActualSaving,
            PDNO: data.ResponseData.PDNO,
            PDTYPENO: data.ResponseData.PDTYPENO,
            Forgone: data.ResponseData.Forgone,
            AddRemarks: data.ResponseData.AddRemarks,
            ItnryNotes: data.ResponseData.ItnryNotes,
            FCBuy: data.ResponseData.FCBuy,
            FCCommPer: data.ResponseData.FCCommPer,
            FCCommAmt: data.ResponseData.FCCommAmt,
            FCSell: data.ResponseData.FCSell,
            HCCommPer: data.ResponseData.HCCommPer,
            HCCommAmt: data.ResponseData.HCCommAmt,
            HCSell: data.ResponseData.HCSell,
            EmbarkationTime: data.ResponseData.EmbarkationTime,
            DisembarkationTime: data.ResponseData.DisembarkationTime,
            PDAMOUNT: data.ResponseData.PDAMOUNT,
            PONO: data.ResponseData.PONO,
            PONOYEARID: data.ResponseData.PONOYEARID,
            PAYMENTID: data.ResponseData.PAYMENTID,
            PMTID: data.ResponseData.PMTID,
            DEFDEPPER: data.ResponseData.DEFDEPPER,
            DEFDEP: data.ResponseData.DEFDEP,
            DepositDueDate: new Date(data.ResponseData.DepositDueDate),
            BalanceDueDate: new Date(data.ResponseData.BalanceDueDate),
            BalanceDueAmount: data.ResponseData.BalanceDueAmount,
            // EmbarkationDate :new Date((data.ResponseData.EmbarkationDate)),
            //DisembarkationDate :new Date((data.ResponseData.DisembarkationDate)),
            EmbarkationDate: data.ResponseData.EmbarkationDate != null ? (data.ResponseData.EmbarkationDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.EmbarkationDate)) : null,
            DisembarkationDate: data.ResponseData.DisembarkationDate != null ? (data.ResponseData.DisembarkationDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.DisembarkationDate)) : null,
            TOMSVATTAXNO: data.ResponseData.TOMSVATTAXNO,
            TOMSVATAMT: data.ResponseData.TOMSVATAMT,
            PDUNIQUEREF: data.ResponseData.PDUNIQUEREF,
            PDFCURID: data.ResponseData.PDFCURID,
            PDEXRATE: data.ResponseData.PDEXRATE,
            PDFCAMT: data.ResponseData.PDFCAMT,
            SELLTAXNO: data.ResponseData.SELLTAXNO,
            SELLTAXRATE: data.ResponseData.SELLTAXRATE,
            FC_SELLTAXAMT: data.ResponseData.FC_SELLTAXAMT,
            HC_SELLTAXAMT: data.ResponseData.HC_SELLTAXAMT,
            PONOCCDeferredPosted: data.ResponseData.PONOCCDeferredPosted,
            TaxExcludingSalesTax: data.ResponseData.TaxExcludingSalesTax,
            BaseFare: data.ResponseData.BaseFare,
            PROJNO: data.ResponseData.PROJNO,
            GSTHSTOnComm: 0,
            //12698
            CostingSupplierGrossValue: data.ResponseData.CostingSupplierGrossValue,
            CostingSupplierServiceFee: data.ResponseData.CostingSupplierServiceFee,
            CostingTaxNo: data.ResponseData.CostingTaxNo,
            CostingTaxRate: data.ResponseData.CostingTaxRate,
            CostingSupplierTaxAmount: data.ResponseData.CostingSupplierTaxAmount,
            CostingSupplierNetBuyPrice: data.ResponseData.CostingSupplierNetBuyPrice,
            CostingCustomerSalesValue: data.ResponseData.CostingCustomerSalesValue,
            CostingCustomerServiceFee: data.ResponseData.CostingCustomerServiceFee,
            CostingCustomerTaxAmount: data.ResponseData.CostingCustomerTaxAmount,
            CostingCustomerNetSellPrice: data.ResponseData.CostingCustomerNetSellPrice,
            CostingVATType: data.ResponseData.CostingVATType,
            Markup: data.ResponseData.CostingCustomerServiceFee,
            CostofSale: data.ResponseData.CostingCustomerSalesValue,
            CostingSuppComm: data.ResponseData.CostingSuppComm,
            SupplierDeposit: data.ResponseData.SupplierDeposit,
            //12698
            CountryID: ['']
          });
          this.FC_Tot_BuyInitalvalue = this.cruiseEditForm.controls.FCBuy.value
          this.FCSell = this.cruiseEditForm.controls.FCSell.value;
        }
        else {
          this.spinner.hide();
        }
        this.PONO = this.cruiseEditForm.controls.PONO.value;

        if (this.PONO > 0) {

          this.pocreated = false;
        }
        else {
          this.pocreated = true;
        }
        this.spinner.hide();
        this.loaded = true;
        this.onDateoutblur();
        this.FormLoyalityPointsChange();
        this.loadsuppliermaster();
        this.CurrencyselectedItem = this.Currency.find(item => item.Code == this.cruiseEditForm.controls.CURCODE.value);
      }));
  }
  loadsuppliermaster() {
    if (this.cruiseEditForm.controls.SUPPCODE.value != '' && this.cruiseEditForm.controls.SUPPCODE.value != null) {
      this.subscription.add(this.lookupApi.findSupplier(this.cruiseEditForm.controls.SUPPCODE.value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
            if (this.cruiseEditForm.controls.SUPPCODE.value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.cruiseEditForm.controls.SUPPCODE.value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
                this.cruiseEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID);
              }

            }
          }
        }
      ));
    }
  }
  handleFiltersupplier(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  onFormSubmit() {
    debugger;
    this.spinner.show();
    if (this.cruiseEditForm.controls.PAYMODE.value == "Company Card")//By case id-355
    {
      if ((this.cruiseEditForm.controls.PDAMOUNT.value == 0) || (this.cruiseEditForm.controls.PDNO.value == "0") || (this.cruiseEditForm.controls.PDTYPENO.value == "0")) {
        this.subscription.add(this.translateapi.get(['Messages.PleaseSelectCompanyCardWithPaymentOption']).subscribe((translations) => {

          this.showMsg = translations['Messages.PleaseSelectCompanyCardWithPaymentOption'];;
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        this.spinner.hide();
        return;
      }
    }

    this.bookingDate = (this.cruiseEditForm.controls.BookingDate.value == null || this.cruiseEditForm.controls.BookingDate.value == "" ? "01-Jan-1900" : this.cruiseEditForm.controls.BookingDate.value);//Case id-337
    this.embarkDate = (this.cruiseEditForm.controls.DEPDATE.value == null || this.cruiseEditForm.controls.DEPDATE.value == "" ? "01-Jan-1900" : this.cruiseEditForm.controls.DEPDATE.value);//Case id-337
    this.disEmbarkDate = (this.cruiseEditForm.controls.ARRDATE.value == null || this.cruiseEditForm.controls.ARRDATE.value == "" ? "01-Jan-1900" : this.cruiseEditForm.controls.ARRDATE.value);//Case id-337
    this.depositDueDate = (this.cruiseEditForm.controls.DepositDueDate.value == null || this.cruiseEditForm.controls.DepositDueDate.value == "" ? "01-Jan-1900" : this.cruiseEditForm.controls.DepositDueDate.value);//Case id-337
    if (Boolean(this.commonservice.ValidDateRange(this.bookingDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidBookingDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidBookingDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.commonservice.ValidDateRange(this.embarkDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidEmbarkDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidEmbarkDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.commonservice.ValidDateRange(this.disEmbarkDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDisEmbarkDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDisEmbarkDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }

    if (Boolean(this.commonservice.ValidDateRange(this.depositDueDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDepositDueDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDepositDueDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (this.cruiseEditForm.value['CURExRate'] === '' || this.cruiseEditForm.value['CURExRate'] === 0 || this.cruiseEditForm.value['CURExRate'] === null) {
      this.subscription.add(this.translateapi.get(['Messages.ExchangeRateError']).subscribe((translations) => {
        this.showMsg = translations['Messages.ExchangeRateError'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.spinner.hide();
      return;
    }
    this.submitted = true;
    if (this.cruiseEditForm.invalid) {
      if (this.cruiseEditForm.controls.SUPPCODE.value == '' || this.cruiseEditForm.controls.SUPPCODE.value == undefined) {
        this.commonservice.submitted = true;
      }
      this.spinner.hide();
      return;
    }
    else {
      this.commonservice.submitted = false;
    }

    this.objFolderPrice
      .CreditLimitCheck(this.guid == null || undefined ? "0" : this.guid, 0, this.orderno, this.cruiseEditForm.get("HCSell").value)
      .subscribe(res => {
        this.spinner.hide();
        if (res != undefined || res["ResponseData"] != null) {
          if (
            res["ResponseData"].value != "0" &&
            res["ResponseData"].Status != "0" &&
            res["ResponseData"].ErrorMessage != ""
          ) {
            debugger;
            if (res["ResponseData"].value == 1) {
              this.msg = res["ResponseData"].ErrorMessage;
              this.creditLimiFlag = true;
              this.creditLimitMsg = true;
              this.spinner.hide();

            } else if (res["ResponseData"].value == 2) {
              this.msg = res["ResponseData"].ErrorMessage;
              this.creditLimiFlag = false;
              this.creditLimitMsg = true;
              this.spinner.hide();
              // this.AirTicketSaveAndUpdate();
            } else {
              this.cruiseDetailsSaveandUpdate();
            }
          } else {
            this.cruiseDetailsSaveandUpdate();
          }
        }
      });

  }

  cruiseDetailsSaveandUpdate() {
    this.cruiseEditForm.controls.BaseFare.setValue(this.cruiseEditForm.controls.FCSell.value);
    if (this.guid != null && this.PONO > 0) {
      this.prdtUpdatePODTO = new ProductUpdatePODTO();
      this.prdtUpdatePODTO.FOLDERNO = this.orderno;
      this.prdtUpdatePODTO.GUIDItinerary = this.cruiseEditForm.get('GUIDItinerary').value;
      this.prdtUpdatePODTO.LOYALTYPOINTS = this.cruiseEditForm.get('LOYALTYPOINTS').value;
      this.prdtUpdatePODTO.LOYALTYPOINTSAMT = this.cruiseEditForm.get('LOYALTYPOINTSAMT').value
      //  this.prdtUpdatePODTO.LOYALTYPOINTSBAL=this.cruiseEditForm.get('LOYALTYPOINTSBAL').value
      this.prdtUpdatePODTO.LOYALTYPOINTSMEMBNO = this.cruiseEditForm.get('LOYALTYPOINTSMEMBNO').value;
      this.prdtUpdatePODTO.BaseFare = this.cruiseEditForm.get('BaseFare').value;
      this.prdtUpdatePODTO.SELLAMT = this.cruiseEditForm.get('FCSell').value;
      this.prdtUpdatePODTO.SELLCOMM = this.cruiseEditForm.get('HCCommAmt').value;

      this.prdtUpdatePODTO.HCCommAmt = this.cruiseEditForm.get('HCCommAmt').value;

      // this.prdtUpdatePODTO.fCSellTax=this.hotelEditForm.get('RDESC').value;

      this.prdtUpdatePODTO.SELLTAXNO = this.cruiseEditForm.get('SELLTAXNO').value;
      this.prdtUpdatePODTO.SELLTAXRATE = this.cruiseEditForm.get('SELLTAXRATE').value;
      this.prdtUpdatePODTO.PAYABLE = this.cruiseEditForm.get('AMOUNT').value;
      this.prdtUpdatePODTO.FC_CIDExRate = this.cruiseEditForm.get('CURExRate').value;
      this.prdtUpdatePODTO.FC_SELLTAXAMT = this.cruiseEditForm.get('FC_SELLTAXAMT').value;
      this.prdtUpdatePODTO.CostingCustomerNetSellPrice = this.cruiseEditForm.get('CostingCustomerNetSellPrice').value;
      this.prdtUpdatePODTO.CostingCustomerServiceFee = this.cruiseEditForm.get('CostingCustomerServiceFee').value;
      this.prdtUpdatePODTO.CostingCustomerTaxAmount = this.cruiseEditForm.get('CostingCustomerTaxAmount').value;
      this.prdtUpdatePODTO.TYPE = "Cruise";
      //this.apiPO.formData=
      this.apiPO.formData = this.prdtUpdatePODTO;
      this.subscription.add(this.apiPO.SaveProductAfterPO()
        .subscribe(res => {
          if (res.IsSuccess === true && res.Message === 'Success') {
            this.spinner.hide();
            this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
              this.showMsg = translations['Messages.saved_success'];
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }));
            this.router.navigate(['/PenAir/Product/CruiseDetails', this.orderno, this.guid], { skipLocationChange: true });
          } else {
            this.spinner.hide();
            this.toastr.warning(res.ResponseData.ErrorMessage, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          }
        }, (err) => {
          this.spinner.hide();
          console.log(err);
          this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
            this.showMsg = translations['Messages.some_error_in_save'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
        }
        ));

    }


    else {

      if (this.guid != null) {
        this.spinner.show();
        if (this.CompanyCardValidation() == true) {
          this.subscription.add(this.api.updateCruise(this.cruiseEditForm.value)
            .subscribe(res => {
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  this.spinner.hide();
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));
                  this.router.navigate(['/PenAir/Product/CruiseDetails', this.orderno, this.guid + '/'], { skipLocationChange: true });
                }
                else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
              else {
                this.spinner.hide();
              }
            }
              , (err) => {
                this.spinner.hide();
                console.log(err);
                this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                  this.showMsg = translations['Messages.some_error_in_save'];
                  this.toastr.warning(this.showMsg, "", {
                    timeOut: 3000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }));
              }
            ));
        }
        else {
          this.spinner.hide();
        }
      }
      else {
        this.spinner.show();
        if (this.CompanyCardValidation() == true) {
          this.subscription.add(this.api.addCruise(this.cruiseEditForm.value)
            .subscribe(res => {
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  this.spinner.hide();
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));
                  //  this.router.navigate(['/PenAir/Folder/Products',this.orderno],{skipLocationChange:true});
                  this.commonservice.isfinalsaved = false;
                  sessionStorage.setItem('pagevalue', '0');
                  var folderQuotes = sessionStorage.getItem('folderQuotes');
                  if (folderQuotes) {
                    this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno], { skipLocationChange: true });
                  }
                  else {
                    this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno], { skipLocationChange: true });
                  }
                } else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
              else {
                this.spinner.hide();
              }
            }, (err) => {
              this.spinner.hide();
              console.log(err);
              this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                this.showMsg = translations['Messages.some_error_in_save'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));

            }
            ));
        } else {
          this.spinner.hide();
        }
      }

    }
  }
  onCancel() {
    if (this.guid != "" && this.guid != null)
      this.router.navigate(['/PenAir/Product/CruiseDetails', this.orderno, this.guid + '/']);
    else {
      this.commonservice.isfinalsaved = false;
      sessionStorage.setItem('pagevalue', '0')
      var folderQuotes = sessionStorage.getItem('folderQuotes');
      if (folderQuotes) {
        this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno], { skipLocationChange: true });
      }
      else {
        this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno], { skipLocationChange: true });
      }
    }
    // this.router.navigate(['/PenAir/Folder/Products',this.orderno],{skipLocationChange:true});
  }
  onReset() {
    this.submitted = false;
    this.cruiseEditForm.reset();
  }
  onChanges(): void {

  }
  public CurrencyChange(value: any): void {
    if (value != null && value != undefined)
      this.cruiseEditForm.controls.CURCODE.setValue(value.Code);
    else
      this.cruiseEditForm.controls.CURCODE.setValue("");
  }
  public CurrencyExRatechange(): void {
    this.CalculateForm();
  }
  SupplierDefaultvalue(suppcode) {
    this.subscription.add(this.supplier.GetDefaultValue(suppcode)
      .subscribe(data => {
        if (data !== '' && data !== null && data !== undefined) {
          // this.cruiseEditForm.controls.CURCODE.setValue(data.CURRENCYCODE);
          this.cruiseEditForm.controls.PAYMENTID.setValue(data.PAYTERMID);
          this.cruiseEditForm.controls.PMTID.setValue(data.PAYMTDID);
          this.resetChildForm();

          // if(this.cruiseEditForm.controls.CURCODE.value!=""&&this.cruiseEditForm.controls.CURCODE.value!=null)
          // {   
          //   this.CurrencyselectedItem =this.Currency.find(item => item.Code ===this.cruiseEditForm.controls.CURCODE.value );     
          //   this.getCurrencyExRate();
          // }
        }
      }));
  }
  resetChildForm() {
    this.resetFormSubject.next(true);
  }
  FormLoyalityPointsChange() {
    if (this.cruiseEditForm.controls.LOYALTYPOINTSMEMBNO.value != "" && this.cruiseEditForm.controls.LOYALTYPOINTSMEMBNO.value != null) {


      this.cruiseEditForm.get('LOYALTYPOINTS').enable();
      this.cruiseEditForm.get('LOYALTYPOINTSAMT').enable();
      // this.disableTextbox =  false;
    }
    else {
      this.cruiseEditForm.controls.LOYALTYPOINTS.setValue("0");
      this.cruiseEditForm.controls.LOYALTYPOINTSAMT.setValue(0);

      this.cruiseEditForm.get('LOYALTYPOINTS').disable();
      this.cruiseEditForm.get('LOYALTYPOINTSAMT').disable();

    }
  }
  public getCurrencyExRate() {

    this.bookingDate = (this.cruiseEditForm.controls.BookingDate.value == null || this.cruiseEditForm.controls.BookingDate.value == "" ? "01-Jan-1900" : this.cruiseEditForm.controls.BookingDate.value);//Case id-337

    if (Boolean(this.commonservice.ValidDateRange(this.bookingDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidBookingDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidBookingDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (this.cruiseEditForm.controls.CURCODE.value != '' && this.cruiseEditForm.controls.CURCODE.value != undefined)
      this.getExchangeRate(this.cruiseEditForm.controls.CURCODE.value, this.bookingDate)
    ///this.folderdata.changeData(this.cruiseEditForm.controls.CURCODE.value, (this.cruiseEditForm.controls.CURExRate.value))
  }

  getExchangeRate(currency, bookingDate) {
    this.savebutton = false;
    bookingDate = new Date(bookingDate).toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    bookingDate = bookingDate.replace('Sept', 'Sep')
    this.subscription.add(this.apiExRate.getExchangeRate(currency, bookingDate)
      .subscribe(data => {
        this.currencyExrate = data;
        this.cruiseEditForm.controls.CURExRate.setValue(this.currencyExrate.ResponseData.EXRATE);
        this.savebutton = true;
        // this.CalculateForm();
      }));
  }

  public BookedByChange(value: any): void {
    if (value != null && value != undefined)
      this.cruiseEditForm.controls.BOOKEDBY.setValue(value.Name);
    else
      this.cruiseEditForm.controls.BOOKEDBY.setValue("");
  }

  changefn() {
    // console.log(this.ucode);
    if (this.ucode == "")
      this.cruiseEditForm.controls.SUPPNAME.setValue("");
    let toArray = this.ucode.split("-");

    if (toArray.length > 1)
      if (toArray[1].trim() != '')
        this.cruiseEditForm.controls.SUPPNAME.setValue(toArray[1]);
  }


  private buyExRate: number;
  private fCBuy: any;
  private amount: number;
  private balanceDueAmount: number;
  private defaultDepositPer: number;
  private hcBuy: number;

  CalculateForm() {

    this.buyExRate = (this.cruiseEditForm.get('CURExRate').value == "" || this.cruiseEditForm.get('CURExRate').value == 0) ? 1 : this.cruiseEditForm.get('CURExRate').value;
    if ((this.cruiseEditForm.get('AMTLC') != null) && this.cruiseEditForm.get('AMTLC').value != "undefined") {

      this.fCBuy = this.cruiseEditForm.controls.AMTLC.value;


      this.cruiseEditForm.controls.FCBuy.setValue((this.fCBuy));
      this.cruiseEditForm.controls.AMOUNT.setValue(parseFloat((this.fCBuy * this.buyExRate).toFixed(2)));
      this.cruiseEditForm.controls.AMTHC.setValue(parseFloat((this.fCBuy * this.buyExRate).toFixed(2)));
    }
    else {
      this.cruiseEditForm.controls.AMOUNT.setValue(parseFloat((this.cruiseEditForm.get('FCBuy').value * this.buyExRate).toFixed(2)));
    }


    this.hcBuy = this.cruiseEditForm.get('AMOUNT').value;

    this.cruiseEditForm.controls.HCSell.setValue(parseFloat((this.cruiseEditForm.get('FCSell').value * this.exRate).toFixed(2)));
    this.cruiseEditForm.controls.HCCommAmt.setValue(parseFloat((this.cruiseEditForm.get('HCSell').value - this.cruiseEditForm.get('AMOUNT').value).toFixed(2)));

    //Payment Terms

    this.amount = (this.cruiseEditForm.get('DEFDEP').value == "" || this.cruiseEditForm.get('DEFDEP').value == 0) ? 0 : this.cruiseEditForm.get('DEFDEP').value;
    this.defaultDepositPer = (this.cruiseEditForm.get('DEFDEPPER').value == "" || this.cruiseEditForm.get('DEFDEPPER').value == 0) ? 0 : this.cruiseEditForm.get('DEFDEPPER').value;

    if (this.defaultDepositPer != 0 && this.defaultDepositPer != 0.00)
      this.amount = (this.hcBuy * this.defaultDepositPer) / 100;

    this.balanceDueAmount = this.hcBuy - this.amount
    this.cruiseEditForm.controls.DEFDEP.setValue(parseFloat((this.amount).toFixed(2)));
    this.cruiseEditForm.controls.BalanceDueAmount.setValue(parseFloat((this.balanceDueAmount).toFixed(2)));

    //Corperate Info

    this.cruiseEditForm.controls.ActualSaving.setValue(parseFloat((this.cruiseEditForm.get('AMOUNT').value * -1).toFixed(2)));

  }
  getFolderValues() {

    // this.apiShared.folderCurrency.subscribe(fldrCurrency => this.fldrCurrency = fldrCurrency)
    // this.apiShared.folderExchangeRate.subscribe(folderExrate => this.folderExrate = folderExrate)
    // this.exRate=new Number(this.folderExrate);
    // this.apiShared.projNo.subscribe(projNo => this.projNo = projNo)
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    this.exRate = new Number(this.folderExrate);
    this.projNo = this.apiShared.projNo;

  }

  public SupplierValueChange(value: any): void {
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);

    }
    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860

      this.SupplierDefaultvalue(this.supplierSelectedItem.Code);
      this.cruiseEditForm.controls.SUPPCODE.setValue(this.supplierSelectedItem.Code);
      this.cruiseEditForm.controls.SUPPNAME.setValue(this.supplierSelectedItem.ActualName);
      this.cruiseEditForm.controls.Provider.setValue(this.supplierSelectedItem.ActualName);
      this.apiShared.BALDUETO = this.supplierSelectedItem.BALDUETO;
      this.cruiseEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID);
      this.payterm.getBalanceDuedate();
      this.TaxLookup(this.supplierSelectedItem.CONTID);
    }
    else {
      this.supplierSelected = "";
      this.cruiseEditForm.controls.SUPPCODE.setValue('');
      this.cruiseEditForm.controls.SUPPNAME.setValue('');
      this.cruiseEditForm.controls.Provider.setValue('');
    }

  }

  TaxLookup(country) {
    this.fchcdetails.getInputTaxLookup(country)
  }
  public disabled: boolean = true;
  diff: number;
  indate: Date = new Date();
  outdate: Date = new Date();
  days: number;
  onDateoutblur() {
    this.embarkDate = (this.cruiseEditForm.controls.DEPDATE.value == null || this.cruiseEditForm.controls.DEPDATE.value == "" ? "01-Jan-1900" : this.cruiseEditForm.controls.DEPDATE.value);//Case id-337
    this.disEmbarkDate = (this.cruiseEditForm.controls.ARRDATE.value == null || this.cruiseEditForm.controls.ARRDATE.value == "" ? "01-Jan-1900" : this.cruiseEditForm.controls.ARRDATE.value);//Case id-337


    if (Boolean(this.commonservice.ValidDateRange(this.embarkDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidEmbarkDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidEmbarkDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (Boolean(this.commonservice.ValidDateRange(this.disEmbarkDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDisEmbarkDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDisEmbarkDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (this.cruiseEditForm.controls.ARRDATE.value != null && this.cruiseEditForm.controls.DEPDATE.value != null) {
      if (this.cruiseEditForm.controls.ARRDATE.value != '' && this.cruiseEditForm.controls.DEPDATE.value != '') {
        this.diff = this.cruiseEditForm.controls.ARRDATE.value.getTime() - this.cruiseEditForm.controls.DEPDATE.value.getTime();
        this.days = this.diff / (1000 * 60 * 60 * 24);
      }
    } else {
      this.days = 0;
    }
  }

  onNightsblur() {
    this.embarkDate = (this.cruiseEditForm.controls.DEPDATE.value == null || this.cruiseEditForm.controls.DEPDATE.value == "" ? "01-Jan-1900" : this.cruiseEditForm.controls.DEPDATE.value);//Case id-337
    this.disEmbarkDate = (this.cruiseEditForm.controls.ARRDATE.value == null || this.cruiseEditForm.controls.ARRDATE.value == "" ? "01-Jan-1900" : this.cruiseEditForm.controls.ARRDATE.value);//Case id-337


    if (Boolean(this.commonservice.ValidDateRange(this.embarkDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidEmbarkDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidEmbarkDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (Boolean(this.commonservice.ValidDateRange(this.disEmbarkDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDisEmbarkDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDisEmbarkDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }

    this.days = (this.days == null ? 0 : this.days)
    this.cruiseEditForm.controls.ARRDATE.setValue(new Date((new Date(this.indate)).getTime() + (this.days * 60 * 60 * 24 * 1000)));

  }

  public dattobechecked: any;
  public FormatDate(value: any): string {


    this.dattobechecked = new Date(value).toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    this.dattobechecked = this.dattobechecked.replace('Sept', 'Sep')

    if (this.dattobechecked == "1-Jan-1900")
      return "";
    else {
      return (this.dattobechecked);
    }
  }
  public open() {

    this.opened = true;
  }
  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
    this.openSearch = false;
  }

  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860


  CompanyCardValidation() {
    if (this.cruiseEditForm.get('PAYMODE').value == "Company Card") {
      if (this.cruiseEditForm.get('PDNO').value != "" && this.cruiseEditForm.get('PDNO').value != null && this.cruiseEditForm.get('PDFCURID').value != "" && this.cruiseEditForm.get('PDFCURID').value != null
        && this.cruiseEditForm.get('PDFCAMT').value != "" && this.cruiseEditForm.get('PDFCAMT').value != null && this.cruiseEditForm.get('PDFCAMT').value != 0 && this.cruiseEditForm.get('PDTYPENO').value != null
        && this.cruiseEditForm.get('PDTYPENO').value != "" && this.cruiseEditForm.get('PDAMOUNT').value != null && this.cruiseEditForm.get('PDAMOUNT').value != null && this.cruiseEditForm.get('PDAMOUNT').value != 0

        && this.cruiseEditForm.get('PDFCAMT').value != null && this.cruiseEditForm.get('PDFCAMT').value != null && this.cruiseEditForm.get('PDFCAMT').value != 0
      ) {
        return true;
      }
      else {
        this.commonservice.showFlash1('Messages.Companycard_validation', 'bg-danger text-white', 2000);

        return false;
      }
    }
    else {
      return true;
    }
  }
  isSticky: boolean = false;
  @HostListener('window:scroll')
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ConfirmMessage() {
    if (this.creditLimiFlag == false) {
      this.creditLimitMsg = false;
      this.cruiseDetailsSaveandUpdate();
    } else {
      this.creditLimitMsg = false;
    }
  }
}

