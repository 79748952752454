import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public baseUrl = '';
  public ViewTripURL = '';
  public ReportServiceURI = '';
  public RtftoHtmlUrl = '';
  public HotelWebSiteURL = '';
  public PenIBEURL: '';
  public PenIBENDCBAURL: '';
  public PenguinChatURL: '';
  public loc_jsonURL = '';
  public api_endpoint = '';
  public FolderO2cURL= '';
  public twilioapi_endpoint=''
  // Whether or not to enable debug mode
  public enableDebug = true;
  constructor() { }
}
