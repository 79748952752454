import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pnrsourcefile } from '../Models/pnrsourcefile';
import { Observable, of } from 'rxjs';
import { EnvService } from '../../env.service';
import { Folderoptions } from '../Models/folderoptions';
import { Foldernotes } from '../Models/foldernotes';
import { UpdateFolderNotesDetails, FolderOptionsDetails } from '../Models/folder-summary';
import { GetPNRSourceFileOptionDetails, PNRFileDTO } from '../Models/pnrsourcefile';
import { CommonserviceService } from './commonservice.service';
import { catchError, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class PnrsourcefileService {
  apiUrl: string;
  formData: PNRFileDTO;
  formPNRSourceFileData: Pnrsourcefile;
  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }

  GetPNRSourceFileBasedOnFolderNo(): Observable<Pnrsourcefile> {
    var body = {
      ...this.formPNRSourceFileData,
    };
    return this.http.post<any>(this.apiUrl + 'GetPNRSourceFileBasedOnFolderNo', body, httpOptions).pipe(
      tap(_ => console.log(`Get PNR Source File Details Based on FolderNo`)),
      catchError(this.commonservice.handleError<any>(`Get PNR Source File Details Based on FolderNo`))
    );
  }

  GetFolderOptions(folderNo: number): Observable<Folderoptions> {
    const url = `${this.apiUrl}` + 'GetFolderOptions' + `/${folderNo}`;
    return this.http.get<Folderoptions>(url).pipe(
      tap(_ => console.log(`Get Folder Options Details`)),
      catchError(this.commonservice.handleError<any>(`Get Folder Options Details`))
    );
  }

  GetFolderNotes(folderNo: number): Observable<Foldernotes> {
    const url = `${this.apiUrl}` + 'GetFolderNotes' + `/${folderNo}`;
    return this.http.get<Foldernotes>(url).pipe(
      tap(_ => console.log(`Get Folder Notes Details`)),
      catchError(this.commonservice.handleError<any>(`Get Folder Notes Details`))
    );
  }
  UpdateFolderNotesDetails(foldernotesDetails: UpdateFolderNotesDetails): Observable<any> { //358

    return this.http.post<any>(this.apiUrl + 'UpdateFolderNotesDetails', foldernotesDetails, httpOptions).pipe(
      tap(_ => console.log(`Update Folder Notes Detail`)),
      catchError(this.commonservice.handleError<any>(`Update Folder Notes Detail`))
    );
  }


  // 13504  canned message error by sreekanth
  GetMessageBasedOnMessageId(id: any): Observable<any> { // version 1.0.1 
    var obj = { messageId: id }

    return this.http.post<any>(this.apiUrl + 'GetMessageBasedOnMessageId', obj, httpOptions).pipe(
      tap(_ => console.log(`Get Message BasedOn MessageId`)),
      catchError(this.commonservice.handleError<any>(`Get MessageBasedOn MessageId`))
    );
  }

  // 13504 

  UpdatedFolderOptions(folderoptionsDetails: FolderOptionsDetails): Observable<any> {

    return this.http.post<any>(this.apiUrl + 'UpdatedFolderOptions', folderoptionsDetails, httpOptions).pipe(
      tap(_ => console.log(`Updated Folder Options`)),
      catchError(this.commonservice.handleError<any>(`Updated Folder Options`))
    );
  }
  GetPNRSourceFileDetails(folderNumber: any): Observable<GetPNRSourceFileOptionDetails[]> {
    const url = `${this.apiUrl}` + 'GetPNRSourceFileDetails' + `/${folderNumber}`;
    return this.http.get<GetPNRSourceFileOptionDetails[]>(url).pipe(
      tap(_ => console.log(`Get PNR Source File Details`)),
      catchError(this.commonservice.handleError<any>(`Get PNR Source File Details`))
    );
  }
  LoadPNRFile(): Observable<PNRFileDTO> {
    var body = {
      ...this.formData,
    };
    return this.http.post<any>(this.apiUrl + 'GetPNRFile', body, httpOptions).pipe(
      tap(_ => console.log(`Get PNR File Path Based on Interface`)),
      catchError(this.commonservice.handleError<any>(`Get PNR File Path Based on Interface`))
    );
  }

  LoadXMLData(filePathUrl: any): Observable<any> {
    //return this.http.get('http://www.google.com', httpOptions ).pipe(
    return this.http.post<any>(filePathUrl, { responseType: 'text' }).pipe(
      tap(_ => console.log(`Read data throw url`)),
      catchError(this.commonservice.handleError<any>(`Read data throw url`))
    );
  }

  DeleteFolderPNRDetails(): Observable<any> {
    var body = {
      ...this.formPNRSourceFileData,
    };
    const url = `${this.apiUrl}` + 'DeleteFolderPNRDetails';
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(_ => console.log(`Delete Folder PNR Details`)),
      catchError(this.commonservice.handleError<any>('Delete Folder PNR Details'))
    );
  }
  getFolderInstamentPlans(orderNo: any): Observable<any> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.get<any>(this.apiUrl + '/GetFolderInstallmentPlan/' + orderNo,
      { headers });
  }
  SaveFolderInstamentPlans(instalmentplan, grid): Observable<any> {
    var body = {
      ...instalmentplan,
      InstallmentPlanDetails: grid
    };
    return this.http.post<any>(this.apiUrl + 'InstallmentPlan', body);
  }
}
