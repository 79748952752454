import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicLayoutComponent } from '../public-layout/public-layout.component';
import { AuthGuard } from '../_guards/auth.guard';

const routes: Routes = [
  { path: '', component: PublicLayoutComponent ,canActivate: [AuthGuard]},
  // {
  //   path: '',
  //   component: PublicLayoutComponent ,
  //   children: [
  //    { path: '', component: PublicLayoutComponent ,canActivate: [AuthGuard]},
  //   ]
  // }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedMenuRoutingModule { }
