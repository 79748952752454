import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kendocontrols',
  templateUrl: './kendocontrols.component.html',
  styleUrls: ['./kendocontrols.component.scss']
})
export class KendocontrolsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  public listItems: Array<string> = [
    'Baseball', 'Basketball', 'Cricket', 'Field Hockey',
    'Football', 'Table Tennis', 'Tennis', 'Volleyball'
  ];

  public value = [ 'Basketball', 'Cricket' ]


  imageUrl = 'https://demos.telerik.com/kendo-ui/content/shared/icons/sports/snowboarding.png';

    splitButtonItems: Array<any> = [{
        text: 'Keep Text Only',
        icon: 'paste-plain-text',
        click: () => { console.log('Keep Text Only click handler'); }
    }, {
        text: 'Paste as HTML',
        icon: 'paste-as-html'
    }, {
        text: 'Paste Markdown',
        icon: 'paste-markdown'
    }, {
        text: 'Set Default Paste'
    }];

    dropDownButtonItems: Array<any> = [{
        text: 'My Profile'
    }, {
        text: 'Friend Requests'
    }, {
        text: 'Account Settings'
    }, {
        text: 'Support'
    }, {
        text: 'Log Out'
    }];

    public onSplitButtonClick(): void {
        console.log('Paste');
    }

    public onSplitButtonItemClick(dataItem: any): void {
        if (dataItem) {
            console.log(dataItem.text);
        }
    }

    onButtonClick() {
        console.log('click');
    }
    
}
