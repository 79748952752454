import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LookUpDetailsService } from 'src/app/Services/look-up-details.service';
import { LookUpDetails } from 'src/app/Services/look-up-details';
import {GridDataResult,PageChangeEvent, SelectAllCheckboxState, RowArgs, GreaterFilterOperatorComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { DelinkService } from 'src/app/Services/delink.service';
import { DelinkDto, DelinkReceiptGetDetailsDTO } from '../../Models/delink-dto';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CommonserviceService } from 'src/app/Services/commonservice.service';
import { timingSafeEqual } from 'crypto';
@Component({
  selector: 'app-delink-receipt-from-folder',
  templateUrl: './delink-receipt-from-folder.component.html',
  styleUrls: ['./delink-receipt-from-folder.component.scss']
})
export class DelinkReceiptFromFolderComponent implements OnInit {
  private subscription: Subscription = new Subscription();

  documentTypeList: Array<LookUpDetails["ResponseData"]> = [];
  public documentTypeSelectedItem: LookUpDetails["ResponseData"];
  public documentTypeSelected:String;
  public DocTypeName="";
  public mySelection: number[] = [];
  public items: any[];
  btnGenerate=false ;
  btnDelink=true;
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public selectAllState1: SelectAllCheckboxState = 'unchecked';
  public productGridData:any;
 public DocTypeID=0;
 public showMsg:any;
 public Yearid:any;
 public DocNo : any;
 public DocType :any;
 public DelinkParmDTO:DelinkDto;
 public DelinkGridData:any[];
 public GridData:any[];
 public loopVariable:number;
 public DelinkListdto:DelinkReceiptGetDetailsDTO[]= [];
 public DelinkCheckdto:DelinkReceiptGetDetailsDTO[]= [];
 public confirmMsg: any;
 public ConfirmWindow: any;
 public rowcount=0;
 cancel_msg:any="";
 public Componentname:string;
 OK_msg="";
  constructor( public common: CommonserviceService,private toastr: ToastrService,private route: ActivatedRoute,private lookupApi:LookUpDetailsService,private router: Router,private delinkSer: DelinkService,private spinner1: NgxSpinnerService, private translateapi: TranslateService) { }

  ngOnInit() {

    this.subscription.add( this.lookupApi.GetAllLookup('DOCTYPEDELINK').subscribe(
      (data) => {
  
          this.documentTypeList= data["ResponseData"];   
    }));
      
    this.Componentname="DelinkReceiptFromFolder";
    sessionStorage.setItem("Componentname", this.Componentname);
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState) {
  
    if (checkedState === 'checked') {
        this.mySelection = this.DelinkGridData.map((item) => item.slno);
        this.selectAllState = 'checked';
    } else {
        this.mySelection = [];
        this.selectAllState = 'unchecked';
    }
  }
  public rowsSelectedKeys(context: RowArgs): any {

 
    return context.dataItem.slno;
  }
  public onSelectedKeysChange(e) {
    debugger;
    const len = this.mySelection.length;
    // if(len>0)
    // {
    //   this.disableRefundbutton=true;
    // }
    if (len === 0) {
        this.selectAllState = 'unchecked';
        if(this.items==undefined)
        {
          this.items=[];
        }
      //  this.items.push(context.dataItem);
    } else if (len > 0 && len < this.DelinkGridData.length) {
        this.selectAllState = 'indeterminate';
    } else {
        this.selectAllState = 'checked';
    }

}

  public DocumentTypeValueChange (value: any): void {
   
    if(value!=null && value!=undefined)
    {
      this.DocType=value.Code; 
      this.DocTypeName=value.ActualName;
    }     
    else
    {
      this.DocType=0;       
    } 
  }
  async DelinkFolder()
  {
    debugger;
    if(this.mySelection.length<=0)
    {
      this.subscription.add(this.translateapi.get(['Messages.PleaseSelectAtleastOneRow']).subscribe((translations ) => {
             
        this.showMsg= translations['Messages.PleaseSelectAtleastOneRow']  ;;
        this.toastr.error(this.showMsg,"",{
          timeOut:3000,
          disableTimeOut:false,
          closeButton:true
        })
      
       })); 
    }
    else{
      this.btnDelink=true;
      this.btnGenerate=true;
     this.DelinkListdto=[];
     this.DelinkCheckdto=[];
      this.mySelection.forEach(obj => {
        this.DelinkListdto.push(this.DelinkGridData.find((slno) => slno.slno === obj && slno.StatusMessage===''));
      });
      if(this.DelinkListdto.length>0)
      {
      // this.delinkSer.gridData=this.DelinkListdto;
      debugger;
     // for(let data of this.DelinkListdto){
       //await this.DelinkFolderFromReceipt(this.DelinkListdto);
     // }
//alert(JSON.stringify(this.DelinkGridData))

     this.mySelection.forEach(obj => {
      this.DelinkCheckdto.push(this.DelinkGridData.find((slno) => slno.slno === obj && slno.StatusMessage==='' && (slno.commpaidbrid!==0 || slno.commpaid!==0 )));
    });


      // for(let data of this.DelinkCheckdto){
      //  if(data.commpaid!=0 || data.commpaidbrid!=0)
      //  {
        if(this.DelinkCheckdto[0]!=undefined&&this.DelinkCheckdto.length>0)
        {
        this.rowcount=1;
        
        this.subscription.add(this.translateapi.get(['Messages.DelinkConfirmation']).subscribe((translations) => {
          this.confirmMsg = translations['Messages.DelinkConfirmation'].replace("<<1>>",this.DelinkCheckdto[0].PROJNO);
          this.ConfirmWindow = true;
        
        }));

      }
    //     }
       
    //  }
     if(this.rowcount==0)
     {
       this.onDelinkFolder();
     }
     
}
else{
  this.spinner1.hide();
}
  }
}

onDelinkFolder()
{
  this.spinner1.show();
  // this.DelinkListdto=null;
  // this.mySelection.forEach(obj => {
  //   this.DelinkListdto.push(this.DelinkGridData.find((slno) => slno.slno === obj && slno.StatusMessage===''));
  // });
  this.delinkSer.gridData=this.DelinkListdto;
     this.subscription.add(this.delinkSer.DelinkReceiptData().
  subscribe( res=>{
  
  debugger;
 
  this.loopVariable=0;
    if(res["ResponseData"]!=undefined)
    {
      this.GridData= res["ResponseData"];
     
      for(let data of this.DelinkListdto){
        data.StatusMessage=this.GridData[this.loopVariable].StatusMessage;
        this.loopVariable=this.loopVariable+1;
      }
      
      this.spinner1.hide();
      this.common.showFlash1('Messages.updated_Sucess','bg-success text-white',2000);
      
    }
    else
    {
      this.common.showFlash1('Messages.Failed','bg-danger text-white',2000);
    }
    this.spinner1.hide();
  }));
      
      this.btnDelink=false;
      this.btnGenerate=false;
      }
     




  public action(status) {
    if(status=="yes")
    {
      this.btnDelink=true;
      this.btnGenerate=true;
      this.onDelinkFolder();
      this.ConfirmWindow = false;
     
     
    }
    if(status=="no")
    {
      this.btnDelink=false;
      this.btnGenerate=false;
      this.ConfirmWindow = false;
      return;
    }
  }

  async DelinkFolderFromReceipt(obj)
  {
    this.delinkSer.gridData=obj;
     this.subscription.add(this.delinkSer.DelinkReceiptData().
  subscribe( res=>{
  
  debugger;
  this.spinner1.hide();
    if(res["ResponseData"]!=undefined)
    {
       obj.ResponseData.StatusMessage=this.GridData[0].StatusMessage;
       this.GridData= res["ResponseData"];
      // this.DelinkGridData=this.GridData;
  
      alert(JSON.stringify(this.GridData[0].StatusMessage))
      this.spinner1.hide();
    }
  }));
  }
  generate()
  {
    this.DelinkParmDTO=new DelinkDto;
    this.delinkSer.formData=null;
    this.DelinkGridData=null;
   if(this.DocType==null||this.DocType=='')
  {
    this.common.showFlash1('Messages.DocTypemandatory','bg-danger text-white',2000);
      return;
  }
  else if(this.DocNo==null||this.DocNo=='')
  {
    this.common.showFlash1('Messages.DocNoMandatory','bg-danger text-white',2000);
    return;
  }
  else if(this.Yearid==null||this.Yearid=='')
  {
    this.common.showFlash1('Messages.YearidMandatory','bg-danger text-white',2000);
    return;
  }
  this.mySelection=[];
  this.btnGenerate=true;
  
    this.DelinkParmDTO=new DelinkDto;
this.DelinkParmDTO.VRNO=this.DocNo==null?0:this.DocNo;
this.DelinkParmDTO.VRTYPE=this.DocType=null?0:this.DocType;
this.DelinkParmDTO.YEARID=this.Yearid=null?0:this.Yearid;
this.delinkSer.formData=this.DelinkParmDTO;

this.subscription.add(this.delinkSer.GetDelinkGridData().
subscribe(res=>{
//  alert(JSON.stringify(res["ResponseData"]))
debugger;
  if(res["ResponseData"]!=undefined)
  {
    this.DelinkGridData=res["ResponseData"]
    this.btnDelink=false;
    this.btnGenerate=false;
    
    this.spinner1.hide();
  }
  else{
    this.spinner1.hide();
  }
}));


}

  onCancel()
  {
    var reporturl= sessionStorage.getItem('menuhighlight')
    if(reporturl!=null)
  {
    this.router.navigate(['/Login']);
  }
  else{
    this.router.navigate(['PenAir/Home']);
  }
  }
  close(msg)

  {
this.ConfirmWindow=false;
  }
}
