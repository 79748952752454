import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchParentComponent } from './search-parent/search-parent.component';
import { SearchbyFolderComponent } from './searchby-folder/searchby-folder.component';
import { AuthGuard } from '../_guards/auth.guard';
import { SearchbyHotelComponent } from './searchby-hotel/searchby-hotel.component';
import { SearchbyCarComponent } from './searchby-car/searchby-car.component';
import { SearchbyRailComponent } from './searchby-rail/searchby-rail.component';
import { SearchbyTransfersComponent } from './searchby-transfers/searchby-transfers.component';
import { SearchbyTourComponent } from './searchby-tour/searchby-tour.component';
import { SearchbyCruiseComponent } from './searchby-cruise/searchby-cruise.component';
import { SearchbyInsuranceComponent } from './searchby-insurance/searchby-insurance.component';
import { SearchbyOthersComponent } from './searchby-others/searchby-others.component';
import { FoldersearchlistComponent } from './foldersearchlist/foldersearchlist.component';


const routes: Routes = [
  {
    path: '',
    component: SearchParentComponent ,
    
    children: [
     {
      path: "Folder",
      component: SearchbyFolderComponent,
      canActivate: [AuthGuard],
      data: { title: "Folder" },
    },
    {
      path: "Hotel",
      component: SearchbyHotelComponent,
      canActivate: [AuthGuard],
      data: { title: "Hotel" },
    },
    {
      path: "Car",
      component: SearchbyCarComponent,
      canActivate: [AuthGuard],
      data: { title: "Car" },
    },
    {
      path: "Rail",
      component: SearchbyRailComponent,
      canActivate: [AuthGuard],
      data: { title: "Rail" },
    },
    {
      path: "Transfers",
      component: SearchbyTransfersComponent,
      canActivate: [AuthGuard],
      data: { title: "Transfers" },
    },
    {
      path: "Tour",
      component: SearchbyTourComponent,
      canActivate: [AuthGuard],
      data: { title: "Tour" },
    },
    {
      path: "Cruise",
      component: SearchbyCruiseComponent,
      canActivate: [AuthGuard],
      data: { title: "Cruise" },
    },
    {
      path: "Insurance",
      component: SearchbyInsuranceComponent,
      canActivate: [AuthGuard],
      data: { title: "Insurance" },
    },
    {
      path: "Others",
      component: SearchbyOthersComponent,
      canActivate: [AuthGuard],
      data: { title: "Others" },
    },
   
    ],
    
  },
  {
    path: "List",
    component: FoldersearchlistComponent,
    canActivate: [AuthGuard],
    data: { title: "Search" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchRoutingModule { }
