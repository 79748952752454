

import { Component, OnInit, Input } from '@angular/core';
import {  Alertdetails } from '../../Models/alertdetails';
import { ActivatedRoute, Router } from '@angular/router';
import { FolderHistoryService } from '../../Services/folder-history.service';
import { observable, Subscription } from 'rxjs';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { State, process,SortDescriptor} from '@progress/kendo-data-query';
@Component({
  selector: 'folderhistory',
  templateUrl: './folderhistory.component.html',
  styleUrls: ['./folderhistory.component.scss']
})
export class FolderhistoryComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public gridData: any ;
  public fullData: any ;
  public gridHeight=200;
  groups:any;
  ShowSaved=false;
  showFilter=false;
  openRemider = false;
  alertID={
    ID:'',
    Status:''
  };
  private orderNo:string=this.route.snapshot.params['orderNo'];
  public filter: CompositeFilterDescriptor;
  constructor(private FolderHistoryService:FolderHistoryService,private route: ActivatedRoute,private router: Router) { }

  ngOnInit() {
    var orderno= sessionStorage.getItem('orderNo');
    if(orderno!=null)
    if(this.orderNo !==orderno)
    {
      return  this.router.navigate(['PenAir/Home']);
    }
    this.getFolderList();
  
  }
  public getFolderList(){
    this.subscription.add(this.FolderHistoryService.GetFolderHistory(this.route.snapshot.params['orderNo']).subscribe(res=>{
      debugger;
      this.fullData=res.ResponseData;
      this.gridData=res.ResponseData;
      for(var obj of  this.gridData) {
        var time = obj.ACTIONTIME.split(' ');
        var timesplit=time[0].split(':');
        if(+timesplit[0]>12)
        {
          var dateObj = new Date('1900-01-01' + ' ' + time[0]);
        }
        else{
          var dateObj = new Date('1900-01-01' + ' ' + obj.ACTIONTIME);
        }
        
        obj.ACTIONTIME=dateObj;
      }
      this.SetGridHeight();
      this.onCheckboxShowSavedChange();
    }));
  }
  public onFilterClick()//12769
{
  this.showFilter = !this.showFilter;
  this.showFilter = this.showFilter? true :false; 
}
public sort: SortDescriptor[] = [  //12731
  {
      field: 'ACTIONDATE',
      dir: 'desc'
  }
];
onCellClick(e) {
  debugger;
  let clickedRowItem = e.dataItem;
  if(clickedRowItem!=undefined && clickedRowItem.STATUS!=='System' && clickedRowItem.STATUS!=='' )
  {
    //this.spinner.show();
    this.alertID.ID=clickedRowItem.ID;
    this.alertID.Status=clickedRowItem.STATUS;
    this.reminderOpen();
  }
}
public reminderOpen() {// Caseid-12739
  this.openRemider = true;
}
public reminderClose(e) { // Caseid-12739
  if(e=='reload'){
    this.getFolderList();
  }
  this.openRemider = false;   
}
  onCancel()
  {
    sessionStorage.setItem('pagevalue', '0')
    this.router.navigate(['PenAir/Folder/FolderSummary',this.orderNo]);
  }
  onCheckboxShowSavedChange()
  {
    if(this.ShowSaved==true)
    {
      this.switchChange(true);
    }
    else{
      this.switchChange(false);
    }
  }
  public switchChange(checked: boolean): void {debugger;
    if(!checked){
      this.gridData = filterBy(this.fullData,{
        logic: 'and',
        filters: [
          { field: "DESCRIPTION", operator: "neq", value: "SAVED", ignoreCase: true },
        ]});
    }
    else{
      this.gridData = filterBy(this.fullData, null);
    }
    this.SetGridHeight();
  }
  SetGridHeight(){debugger;
    this.gridHeight=200;
    if(this.gridData.length>1){
      var height= this.gridHeight+(this.gridData.length-1)*49;
      if(height>(window.innerHeight-180)){
        this.gridHeight=((window.innerHeight-180)<200)?200:(window.innerHeight-180);
      } else{
        this.gridHeight=height;
      }
     } 
  }
  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.fullData, filter);
    this.SetGridHeight();
}
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
