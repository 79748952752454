import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearhdtotransferService } from '../../Services/searhdtotransfer.service';
import { FolderSearchCriteriaDTO, FolderSearchDTO, HotelSearchDTO, CarSearchDTO, TourSearchDTO, RailSearchDTO, TransfersSearchDTO, CruiseSearchDTO, OthersSearchDTO, InsuranceSearchDTO } from '../../Models/folder-search-criteria-dto';
import { FoldersearchService } from '../../Services/foldersearch.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EnvService } from 'src/env.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-search-parent',
  templateUrl: './search-parent.component.html',
  styleUrls: ['./search-parent.component.scss']
})
export class SearchParentComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public submenuIndex: number = 0;
  //  document = { name: 'Angular 2', description: 'An amazing Angular 2 pdf', url: { url: 'http://localhost:29048/HotelImages/dummy.pdf', withCredentials: true,'Access-Control-Allow-Origin':'*' } }
  public enableQuotesonly = false;
  folderSearchCriteriaDto: FolderSearchCriteriaDTO;
  SearchForm: FormGroup;
  public StatusSelectedItem: any;
  public pdfFilePath = '';
  urlSafe: SafeResourceUrl;
  public PaystatusSelectedItem: any;

  public BookedByselectedItem: LookUpDetails["ResponseData"];
  BookedBy: Array<LookUpDetails["ResponseData"]> = [];

  public payStatusList: Array<{ text: string, value: number }> =
    [{ text: "All", value: 0 }, { text: "Not paid", value: 1 }, { text: "Partial", value: 2 }, { text: "Fully Paid", value: 3 }, { text: "Advance", value: 4 }];
  public statusList: Array<{ text: string, value: number }> =
    [{ text: "All", value: 0 }, { text: "Saved", value: 1 }, { text: "Invoiced", value: 2 }, { text: "Refund", value: 3 }, { text: "Cancelled", value: 4 }, { text: "Partial Refund", value: 5 }, { text: "Refund Request", value: 6 }];

  private readonly newProperty = this.searhTransferDTO;

  public showMsg: any;

  //public statusList: Array<string> = ["All", "All", "Invoiced","Refund","Cancelled","Partial Refund","Refund Request"];
  //public payStatusList: Array<string> = ["All", "Not paid", "Partial","Fully Paid","Advance"];
  //  public BookedByselectedItem: LookUpDetails["ResponseData"];
  //     BookedBy: Array<LookUpDetails["ResponseData"]> = []; 
  pdfurl: string;
  public windowTop: number = -100;
  public windowLeft: number = -225;
  public offset = 10;
  public left = this.windowLeft;
  public top = this.windowTop;
  public width = window.innerWidth - 40;
  public height = 770;
  public Componentname: string;
  openGDSTray = false;
  constructor(private spinner: NgxSpinnerService, private toastr: ToastrService, private env: EnvService, private formBuilder: FormBuilder, public sanitizer: DomSanitizer, public common: CommonserviceService, private router: Router, private searhTransferDTO: SearhdtotransferService, private api: FoldersearchService, private lookupApi: LookUpDetailsService, private translateapi: TranslateService) { this.pdfurl = env.baseUrl }

  ngOnInit() {
    debugger;
    this.submenuIndex = +(sessionStorage.getItem('searchmenuIndex'))
    sessionStorage.setItem('Componentname', "Search");
    //this.pdfFilePath='http://localhost:29048/HotelImages/dummy.pdf';
    // this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfFilePath);
    this.subscription.add(this.newProperty.SrchTranferDTO.subscribe(folderSearchCriteriaDto => {
      this.folderSearchCriteriaDto = folderSearchCriteriaDto;

debugger;
      this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.BookedBy = data["ResponseData"]
            if (this.folderSearchCriteriaDto.BookedBy != "") {
              this.BookedByselectedItem = this.BookedBy.find(item => item.Name == this.folderSearchCriteriaDto.BookedBy);
            }
          }
        }
      ));
      if (this.folderSearchCriteriaDto.FoderStatus != 0) {
        this.StatusSelectedItem = this.statusList.find(item => item.value == this.folderSearchCriteriaDto.FoderStatus);
      }
      if (this.folderSearchCriteriaDto.PayStatus != "") {
        this.PaystatusSelectedItem = this.payStatusList.find(item => item.text == this.folderSearchCriteriaDto.PayStatus);
      }
      if (this.folderSearchCriteriaDto.Quotesonly === 1) {
        this.enableQuotesonly = true;
      }
    }))
    if(this.submenuIndex!=undefined){
      this.MenuClick(this.submenuIndex,false);

    }
    var menuindex = sessionStorage.getItem('searchpagevalue')
    if (this.common.browserRefresh === true) {
      this.submenuIndex = +(sessionStorage.getItem('searchmenuIndex'))
      if (menuindex === undefined || menuindex === '') {
        menuindex = '0';
      }
      this.MenuClick(+menuindex,false);
    }

    this.SearchForm = this.formBuilder.group({//12687
      PNR: '',
      AirlinePNR: '',
      InetRef: '',
      FolderNo: '',
      LeadPassenger: '',
      PassengerPhone: '',
      PassengerEmail: '',
      passngerPostCode: '',
      PaxName: '',
      InvoiceNo: '',
      InvAmount1: '',
      InvAmount2: '',
      TicketNo: '',
      CustCode: [0],
      CustName: '',
      CustomerPostcode: '',
      DepDatefrom: '',
      DepDateTo: '',
      YourRef: '',
      Firstname: '',
      Lastname: '',
      DepDatefromAF: Date,
      DepDateToAF: Date,
      OrderNo: '',
      CreationDatefromAF: Date,
      CreationDateToAF: Date,
      CreateDatefrom: '',
      CreateDateTo: '',


      HotelName: '',
      Supplier: '',
      CheckInDatefrom: '',
      CheckInDateTo: '',
      CheckOutDatefrom: '',
      CheckOutDateTo: '',
      Status: '',
      RoomType: '',
      ConfirmationNo: '',
      VoucherNo: '',
      CheckInDatefromAF: Date,
      CheckInDateToAF: Date,
      CheckOutDatefromAF: Date,
      CheckOutDateToAF: Date,

      Type: '',
      DateFrom1: '',
      DateFrom2: '',
      DateTo1: '',
      DateTo2: '',

      DateFrom1AF: Date,
      DateFrom2AF: Date,
      DateTo1AF: Date,
      DateTo2AF: Date,
      CONFIRMATIONNO: '',
      PNUMBER: '',
      BookingDate: Date,


      PickUp: '',
      DropOff: '',

      hireFrom1: '',
      hireFrom2: '',
      hireTo1: '',
      hireTo2: '',
      hireFrom1AF: Date,
      hireFrom2AF: Date,
      hireTo1AF: Date,
      hireTo2AF: Date,


      EmbtDateFrom: '',
      EmbtDateTo: '',
      DisembDateFrom: '',
      DisembDateTo: '',

      EmbtDateFromDt: Date,
      EmbtDateToDt: Date,
      DisembDateFromDt: Date,
      DisembDateToDt: Date,


      From: '',
      To: '',

      VechicleType: '',

      DepDateFrom: '',

      BookingDateFrom: '',
      BookingDateTo: '',
      DepDateFromAF: Date,

      BookingDateFromAF: Date,
      BookingDateToAF: Date,



      StartDateFrom: '',
      StartDateTo: '',
      EndDateFrom: '',
      EndDateTo: '',

      StartDateFromAF: Date,
      StartDateToAF: Date,
      EndDateFromAF: Date,
      EndDateToAF: Date,

      Product: '',

      BookingDateFromDt: Date,
      BookingDateToDt: Date,

    });

    // this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654

  }

  public BookedByChange(value: any): void {
    if (value != null && value != undefined) {
      this.folderSearchCriteriaDto.BookedBy = value.Name;
      console.log('selectionChange', value);
    } else {
      this.folderSearchCriteriaDto.BookedBy = "";
    }
    //if(value!=null && value!=undefined)
    // this.OthersEditForm.controls.BOOKEDBY.setValue(value.Name);
    // else
    // this.OthersEditForm.controls.BOOKEDBY.setValue("");

  }
  public setmenuindexvalue(value: number) {
    this.submenuIndex = value;
    sessionStorage.setItem('searchmenuIndex', value.toString())
  }

  MenuClick(value: number,tabClik :any) {
    if(tabClik && (this.submenuIndex != value)){
      this.resetDTO();
    }
    

    // this.folderEditMode=this.apiShared.folderEditMode;
    // 
    // this.orderNo=this.apiShared.orderNo;
    this.submenuIndex = value;
    this.setmenuindexvalue(value);

    if (value == 0) {
      sessionStorage.setItem('searchpagevalue', value.toString())
      this.router.navigate(['PenAir/Search/Folder']);
    }
    else if (value == 1) {
      sessionStorage.setItem('searchpagevalue', value.toString())
      this.router.navigate(['PenAir/Search/Hotel']);

    }
    else if (value == 2) {
      sessionStorage.setItem('searchpagevalue', value.toString())
      this.router.navigate(['PenAir/Search/Car']);
    }
    else if (value == 3) {
      sessionStorage.setItem('searchpagevalue', value.toString())
      this.router.navigate(['PenAir/Search/Rail']);
    }
    else if (value == 4) {
      sessionStorage.setItem('searchpagevalue', value.toString())
      this.router.navigate(['PenAir/Search/Transfers']);
    }
    else if (value == 5) {
      sessionStorage.setItem('searchpagevalue', value.toString())
      this.router.navigate(['PenAir/SearchBy/Tour']);
    }
    else if (value == 6) {
      this.router.navigate(['PenAir/Search/Cruise']);
    }
    else if (value == 7) {
      sessionStorage.setItem('searchpagevalue', value.toString())
      this.router.navigate(['PenAir/Search/Insurance']);
    }
    else if (value == 8) {
      sessionStorage.setItem('searchpagevalue', value.toString())
      this.router.navigate(['PenAir/Search/Others']);
    }
    this.closeMenu();

  }
  QuotesOnlyChange() {
    if (this.enableQuotesonly === true) {
      this.folderSearchCriteriaDto.Quotesonly = 1;
    } else {
      this.folderSearchCriteriaDto.Quotesonly = 0;
    }
  }
  folderSearch() {
    debugger;
    if (this.validationChecking())
    {
      if (this.CheckDateRange())//337
      {
        debugger;
        this.folderSearchCriteriaDto.enableSearch = true;
        this.searhTransferDTO.FolderSearch(this.folderSearchCriteriaDto);
        this.router.navigate(['/PenAir/Search/List']);
      }
    }
  }

  validationChecking(){
    debugger;
    let res=false;

    if(this.folderSearchCriteriaDto.FolderSearchDTO == undefined && this.folderSearchCriteriaDto.HotelSearchDTO == undefined && 
      this.folderSearchCriteriaDto.CarSearchDTO == undefined && this.folderSearchCriteriaDto.RailSearchDTO == undefined &&
      this.folderSearchCriteriaDto.TransfersSearchDTO == undefined && this.folderSearchCriteriaDto.TourSearchDTO == undefined &&
      this.folderSearchCriteriaDto.CruiseSearchDTO == undefined && this.folderSearchCriteriaDto.InsuranceSearchDTO == undefined && 
      this.folderSearchCriteriaDto.OthersSearchDTO == undefined
      ){
        this.common.showWARNINGtoastrmsg('Messages.atleastonefield', 3000, true, false);
    }else{
      switch(this.submenuIndex) {
        case 0: case null:{
          if(
          (this.folderSearchCriteriaDto.FolderSearchDTO.PNR =="" || this.folderSearchCriteriaDto.FolderSearchDTO.PNR ==undefined) &&
          (this.folderSearchCriteriaDto.FolderSearchDTO.FolderNo =="" || this.folderSearchCriteriaDto.FolderSearchDTO.FolderNo == undefined) &&
          (this.folderSearchCriteriaDto.FolderSearchDTO.OrderNo == "" || this.folderSearchCriteriaDto.FolderSearchDTO.OrderNo == undefined) &&
          (this.folderSearchCriteriaDto.FolderSearchDTO.LeadPassenger== "" || this.folderSearchCriteriaDto.FolderSearchDTO.LeadPassenger== undefined)&&
          (this.folderSearchCriteriaDto.FolderSearchDTO.InetRef == "" ||this.folderSearchCriteriaDto.FolderSearchDTO.InetRef == undefined)&&
          (this.folderSearchCriteriaDto.FolderSearchDTO.InvoiceNo == "" || this.folderSearchCriteriaDto.FolderSearchDTO.InvoiceNo == undefined) &&
          (this.folderSearchCriteriaDto.FolderSearchDTO.YourRef == "" || this.folderSearchCriteriaDto.FolderSearchDTO.YourRef == undefined) &&
          (this.folderSearchCriteriaDto.FolderSearchDTO.AirlinePNR == "" || this.folderSearchCriteriaDto.FolderSearchDTO.AirlinePNR == undefined)&&
          (this.folderSearchCriteriaDto.FolderSearchDTO.PassengerPhone == "" || this.folderSearchCriteriaDto.FolderSearchDTO.PassengerPhone == undefined) &&
          (this.folderSearchCriteriaDto.FolderSearchDTO.PassengerEmail == "" || this.folderSearchCriteriaDto.FolderSearchDTO.PassengerEmail == undefined)&&
          (this.folderSearchCriteriaDto.FolderSearchDTO.passngerPostCode == ""|| this.folderSearchCriteriaDto.FolderSearchDTO.passngerPostCode == undefined) &&
          (this.folderSearchCriteriaDto.FolderSearchDTO.TicketNo == "" || this.folderSearchCriteriaDto.FolderSearchDTO.TicketNo == undefined)&&
          (this.folderSearchCriteriaDto.FolderSearchDTO.CustomerPostcode == "" || this.folderSearchCriteriaDto.FolderSearchDTO.CustomerPostcode == undefined)&&
          (this.folderSearchCriteriaDto.FolderSearchDTO.CustName == "" || this.folderSearchCriteriaDto.FolderSearchDTO.CustName == undefined)&&
          (this.folderSearchCriteriaDto.FolderSearchDTO.Firstname == "" || this.folderSearchCriteriaDto.FolderSearchDTO.Firstname == undefined) &&
          (this.folderSearchCriteriaDto.FolderSearchDTO.Lastname == "" || this.folderSearchCriteriaDto.FolderSearchDTO.Lastname== undefined)&&
          (this.folderSearchCriteriaDto.FolderSearchDTO.InvAmount1 == null || this.folderSearchCriteriaDto.FolderSearchDTO.InvAmount1 == undefined) &&
          (this.folderSearchCriteriaDto.FolderSearchDTO.InvAmount1 == null || this.folderSearchCriteriaDto.FolderSearchDTO.InvAmount1 == undefined) &&
          (this.folderSearchCriteriaDto.FolderSearchDTO.CreateDateTo == null || this.folderSearchCriteriaDto.FolderSearchDTO.CreateDateTo == undefined) &&
          (this.folderSearchCriteriaDto.FolderSearchDTO.CreateDatefrom == null || this.folderSearchCriteriaDto.FolderSearchDTO.CreateDatefrom == undefined)&&
          (this.folderSearchCriteriaDto.FolderSearchDTO.DepDateTo == null || this.folderSearchCriteriaDto.FolderSearchDTO.DepDateTo == undefined)&&
          (this.folderSearchCriteriaDto.FolderSearchDTO.DepDatefrom == null || this.folderSearchCriteriaDto.FolderSearchDTO.DepDatefrom == undefined) &&
          (this.folderSearchCriteriaDto.FolderSearchDTO.DepDatefromAF == null || this.folderSearchCriteriaDto.FolderSearchDTO.DepDatefromAF == undefined) )
          {
            this.common.showWARNINGtoastrmsg('Messages.atleastonefield', 3000, true, false);
            res=false;
          }else{
            debugger;
            if(this.folderSearchCriteriaDto.FolderSearchDTO.CreationDateToAF==null &&this.folderSearchCriteriaDto.FolderSearchDTO.CreationDatefromAF!=null)
            {
              this.folderSearchCriteriaDto.FolderSearchDTO.CreationDateToAF= this.folderSearchCriteriaDto.FolderSearchDTO.CreationDatefromAF;
              this.folderSearchCriteriaDto.FolderSearchDTO.CreateDateTo=this.common.ConvertToLocaleDate(this.folderSearchCriteriaDto.FolderSearchDTO.CreationDateToAF);
            }
            if(this.folderSearchCriteriaDto.FolderSearchDTO.DepDateToAF==null &&this.folderSearchCriteriaDto.FolderSearchDTO.DepDatefromAF!=null)
            {
              this.folderSearchCriteriaDto.FolderSearchDTO.DepDateToAF= this.folderSearchCriteriaDto.FolderSearchDTO.DepDatefromAF;
              this.folderSearchCriteriaDto.FolderSearchDTO.DepDateTo=this.common.ConvertToLocaleDate(this.folderSearchCriteriaDto.FolderSearchDTO.DepDateToAF);
            }
           // this.folderSearchCriteriaDto.FolderSearchDTO.DepDatefromAF
           // this.folderSearchCriteriaDto.FolderSearchDTO.DepDateToAF
            res=true;
          }
          break;
        }

        case 1:{

          if(this.folderSearchCriteriaDto.HotelSearchDTO.CheckInDateTo == null &&
            this.folderSearchCriteriaDto.HotelSearchDTO.CheckInDatefrom == null &&
            this.folderSearchCriteriaDto.HotelSearchDTO.CheckInDatefromAF == null &&
            this.folderSearchCriteriaDto.HotelSearchDTO.CheckOutDatefrom == null && 
            (this.folderSearchCriteriaDto.HotelSearchDTO.HotelName == "" || this.folderSearchCriteriaDto.HotelSearchDTO.HotelName == undefined)&&
            (this.folderSearchCriteriaDto.HotelSearchDTO.Supplier == "" || this.folderSearchCriteriaDto.HotelSearchDTO.Supplier == undefined)&&
            (this.folderSearchCriteriaDto.HotelSearchDTO.Status == "" || this.folderSearchCriteriaDto.HotelSearchDTO.Status == undefined)&&
            (this.folderSearchCriteriaDto.HotelSearchDTO.RoomType == "" || this.folderSearchCriteriaDto.HotelSearchDTO.RoomType == undefined)&&
            (this.folderSearchCriteriaDto.HotelSearchDTO.ConfirmationNo == "" || this.folderSearchCriteriaDto.HotelSearchDTO.ConfirmationNo == undefined)&&
            (this.folderSearchCriteriaDto.HotelSearchDTO.VoucherNo == "" || this.folderSearchCriteriaDto.HotelSearchDTO.VoucherNo == undefined))
          {
            this.common.showWARNINGtoastrmsg('Messages.atleastonefield', 3000, true, false);
            res=false;
          }else{
            res=true;
          }
          break;
        }

        case 2:{

          if(this.folderSearchCriteriaDto.CarSearchDTO.hireFrom1 == null &&
            this.folderSearchCriteriaDto.CarSearchDTO.hireFrom2 == null &&
            this.folderSearchCriteriaDto.CarSearchDTO.hireTo1 == null &&
            this.folderSearchCriteriaDto.CarSearchDTO.hireTo2 == null && 
            (this.folderSearchCriteriaDto.CarSearchDTO.Supplier == "" || this.folderSearchCriteriaDto.CarSearchDTO.Supplier == undefined)&&
            (this.folderSearchCriteriaDto.CarSearchDTO.Type == "" || this.folderSearchCriteriaDto.CarSearchDTO.Type == undefined)&&
            (this.folderSearchCriteriaDto.CarSearchDTO.PickUp == "" || this.folderSearchCriteriaDto.CarSearchDTO.PickUp == undefined)&&
            (this.folderSearchCriteriaDto.CarSearchDTO.DropOff == "" || this.folderSearchCriteriaDto.CarSearchDTO.DropOff == undefined)&&
            (this.folderSearchCriteriaDto.CarSearchDTO.Status == "" || this.folderSearchCriteriaDto.CarSearchDTO.Status == undefined)&&
            (this.folderSearchCriteriaDto.CarSearchDTO.VoucherNo == "" || this.folderSearchCriteriaDto.CarSearchDTO.VoucherNo == undefined))
          {
            this.common.showWARNINGtoastrmsg('Messages.atleastonefield', 3000, true, false);
            res=false;
          }else{
            res=true;
          }
          break;
        }


        case 3:{

          if((this.folderSearchCriteriaDto.RailSearchDTO.TicketDateFrom == null || this.folderSearchCriteriaDto.RailSearchDTO.TicketDateFrom == undefined) &&
            (this.folderSearchCriteriaDto.RailSearchDTO.TicketDateTo == null || this.folderSearchCriteriaDto.RailSearchDTO.TicketDateTo == undefined)&&
           
            (this.folderSearchCriteriaDto.RailSearchDTO.Supplier == "" || this.folderSearchCriteriaDto.RailSearchDTO.Supplier == undefined)&&
            (this.folderSearchCriteriaDto.RailSearchDTO.From == "" || this.folderSearchCriteriaDto.RailSearchDTO.From == undefined)&&
            (this.folderSearchCriteriaDto.RailSearchDTO.To == "" || this.folderSearchCriteriaDto.RailSearchDTO.To == undefined)&&
            (this.folderSearchCriteriaDto.RailSearchDTO.VoucherNo == "" || this.folderSearchCriteriaDto.RailSearchDTO.VoucherNo == undefined))
          {
            this.common.showWARNINGtoastrmsg('Messages.atleastonefield', 3000, true, false);
            res=false;
          }else{
            res=true;
          }
          break;
        }

        case 4:{
          if(
            (this.folderSearchCriteriaDto.TransfersSearchDTO.Supplier == "" || this.folderSearchCriteriaDto.TransfersSearchDTO.Supplier == undefined)&&
            (this.folderSearchCriteriaDto.TransfersSearchDTO.From == "" || this.folderSearchCriteriaDto.TransfersSearchDTO.From == undefined)&&
            (this.folderSearchCriteriaDto.TransfersSearchDTO.To == "" || this.folderSearchCriteriaDto.TransfersSearchDTO.To == undefined)&&
            (this.folderSearchCriteriaDto.TransfersSearchDTO.VechicleType == "" || this.folderSearchCriteriaDto.TransfersSearchDTO.VechicleType == undefined)&&
            (this.folderSearchCriteriaDto.TransfersSearchDTO.Status == "" || this.folderSearchCriteriaDto.TransfersSearchDTO.Status == undefined)&&
            (this.folderSearchCriteriaDto.TransfersSearchDTO.VoucherNo == "" || this.folderSearchCriteriaDto.TransfersSearchDTO.VoucherNo == undefined)&&
            (this.folderSearchCriteriaDto.TransfersSearchDTO.DepDateFrom == null || this.folderSearchCriteriaDto.TransfersSearchDTO.DepDateFrom == undefined)&&
            (this.folderSearchCriteriaDto.TransfersSearchDTO.DepDateTo == null || this.folderSearchCriteriaDto.TransfersSearchDTO.DepDateTo == undefined)&&
            (this.folderSearchCriteriaDto.TransfersSearchDTO.BookingDateFrom == null || this.folderSearchCriteriaDto.TransfersSearchDTO.BookingDateFrom == undefined)&&
            (this.folderSearchCriteriaDto.TransfersSearchDTO.BookingDateTo == null || this.folderSearchCriteriaDto.TransfersSearchDTO.BookingDateTo == undefined)
          )
          {
            this.common.showWARNINGtoastrmsg('Messages.atleastonefield', 3000, true, false);
            res=false;
          }else{
            res=true;
          }
          break;
        }

        case 5:{

          if(
            (this.folderSearchCriteriaDto.TourSearchDTO.Supplier == "" || this.folderSearchCriteriaDto.TourSearchDTO.Supplier == undefined)&&
            (this.folderSearchCriteriaDto.TourSearchDTO.Status == "" || this.folderSearchCriteriaDto.TourSearchDTO.Status == undefined)&&
            (this.folderSearchCriteriaDto.TourSearchDTO.VoucherNo == "" || this.folderSearchCriteriaDto.TourSearchDTO.VoucherNo == undefined)&&
           
            (this.folderSearchCriteriaDto.TourSearchDTO.StartDateFrom == null || this.folderSearchCriteriaDto.TourSearchDTO.StartDateFrom == undefined)&&
            (this.folderSearchCriteriaDto.TourSearchDTO.StartDateTo == null || this.folderSearchCriteriaDto.TourSearchDTO.StartDateTo == undefined)&&
            (this.folderSearchCriteriaDto.TourSearchDTO.EndDateFrom == null || this.folderSearchCriteriaDto.TourSearchDTO.EndDateFrom == undefined)&&
            (this.folderSearchCriteriaDto.TourSearchDTO.EndDateTo == null || this.folderSearchCriteriaDto.TourSearchDTO.EndDateTo == undefined)&&
            (this.folderSearchCriteriaDto.TourSearchDTO.BookingDateFrom == null || this.folderSearchCriteriaDto.TourSearchDTO.BookingDateFrom == undefined)&&
            (this.folderSearchCriteriaDto.TourSearchDTO.BookingDateTo == null || this.folderSearchCriteriaDto.TourSearchDTO.BookingDateTo == undefined)

          )
          {
            this.common.showWARNINGtoastrmsg('Messages.atleastonefield', 3000, true, false);
            res=false;
          }else{
            res=true;
          }
          break;
        }

        case 6:{
          if(
            (this.folderSearchCriteriaDto.CruiseSearchDTO.Supplier == "" || this.folderSearchCriteriaDto.CruiseSearchDTO.Supplier == undefined)&&
            (this.folderSearchCriteriaDto.CruiseSearchDTO.Type == "" || this.folderSearchCriteriaDto.CruiseSearchDTO.Type == undefined)&&
            (this.folderSearchCriteriaDto.CruiseSearchDTO.Status == "" || this.folderSearchCriteriaDto.CruiseSearchDTO.Status == undefined)&&
            (this.folderSearchCriteriaDto.CruiseSearchDTO.ConfirmationNo == "" || this.folderSearchCriteriaDto.CruiseSearchDTO.ConfirmationNo == undefined)&&
            (this.folderSearchCriteriaDto.CruiseSearchDTO.VoucherNo == "" || this.folderSearchCriteriaDto.CruiseSearchDTO.VoucherNo == undefined)&&
           
            (this.folderSearchCriteriaDto.CruiseSearchDTO.EmbtDateFrom == null || this.folderSearchCriteriaDto.CruiseSearchDTO.EmbtDateFrom == undefined)&&
            (this.folderSearchCriteriaDto.CruiseSearchDTO.EmbtDateTo == null || this.folderSearchCriteriaDto.CruiseSearchDTO.EmbtDateTo == undefined)&&
            (this.folderSearchCriteriaDto.CruiseSearchDTO.DisembDateFrom == null || this.folderSearchCriteriaDto.CruiseSearchDTO.DisembDateFrom == undefined)&&
            (this.folderSearchCriteriaDto.CruiseSearchDTO.DisembDateTo == null || this.folderSearchCriteriaDto.CruiseSearchDTO.DisembDateTo == undefined)
          )
          {
            this.common.showWARNINGtoastrmsg('Messages.atleastonefield', 3000, true, false);
            res=false;
          }else{
            res=true;
          }
          break;
        }

        case 7:{
          if(
            (this.folderSearchCriteriaDto.InsuranceSearchDTO.Supplier == "" || this.folderSearchCriteriaDto.InsuranceSearchDTO.Supplier == undefined)&&
            (this.folderSearchCriteriaDto.InsuranceSearchDTO.Type == "" || this.folderSearchCriteriaDto.InsuranceSearchDTO.Type == undefined)&&
            (this.folderSearchCriteriaDto.InsuranceSearchDTO.Status == "" || this.folderSearchCriteriaDto.InsuranceSearchDTO.Status == undefined)&&
            (this.folderSearchCriteriaDto.InsuranceSearchDTO.VoucherNo == "" || this.folderSearchCriteriaDto.InsuranceSearchDTO.VoucherNo == undefined)&&
            (this.folderSearchCriteriaDto.InsuranceSearchDTO.CONFIRMATIONNO == "" || this.folderSearchCriteriaDto.InsuranceSearchDTO.CONFIRMATIONNO == undefined)&&
            (this.folderSearchCriteriaDto.InsuranceSearchDTO.PNUMBER == "" || this.folderSearchCriteriaDto.InsuranceSearchDTO.PNUMBER == undefined)&&
           
            (this.folderSearchCriteriaDto.InsuranceSearchDTO.BookingDateFrom == null || this.folderSearchCriteriaDto.InsuranceSearchDTO.BookingDateFrom == undefined)&&
            (this.folderSearchCriteriaDto.InsuranceSearchDTO.BookingDateTo == null || this.folderSearchCriteriaDto.InsuranceSearchDTO.BookingDateTo == undefined)&&
            (this.folderSearchCriteriaDto.InsuranceSearchDTO.DateFrom1 == null || this.folderSearchCriteriaDto.InsuranceSearchDTO.DateFrom1 == undefined)&&
            (this.folderSearchCriteriaDto.InsuranceSearchDTO.DateFrom2 == null || this.folderSearchCriteriaDto.InsuranceSearchDTO.DateFrom2 == undefined)&&
            (this.folderSearchCriteriaDto.InsuranceSearchDTO.DateTo1 == null || this.folderSearchCriteriaDto.InsuranceSearchDTO.DateTo1 == undefined)&&
            (this.folderSearchCriteriaDto.InsuranceSearchDTO.DateTo2 == null || this.folderSearchCriteriaDto.InsuranceSearchDTO.DateTo2 == undefined)
          )
          {
            this.common.showWARNINGtoastrmsg('Messages.atleastonefield', 3000, true, false);
            res=false;
          }else{
            res=true;
          }
          break;
        }

        case 8:{
          if(
            (this.folderSearchCriteriaDto.OthersSearchDTO.Product == "" || this.folderSearchCriteriaDto.OthersSearchDTO.Product == undefined)&&
            (this.folderSearchCriteriaDto.OthersSearchDTO.Supplier == "" || this.folderSearchCriteriaDto.OthersSearchDTO.Supplier == undefined)&&
            (this.folderSearchCriteriaDto.OthersSearchDTO.Status == "" || this.folderSearchCriteriaDto.OthersSearchDTO.Status == undefined)&&
            (this.folderSearchCriteriaDto.OthersSearchDTO.ConfirmationNo == "" || this.folderSearchCriteriaDto.OthersSearchDTO.ConfirmationNo == undefined)&&
            (this.folderSearchCriteriaDto.OthersSearchDTO.BookingDateTo == null || this.folderSearchCriteriaDto.OthersSearchDTO.BookingDateTo == undefined)&&
            (this.folderSearchCriteriaDto.OthersSearchDTO.BookingDateFrom == null || this.folderSearchCriteriaDto.OthersSearchDTO.BookingDateFrom == undefined)
          )
          {
            this.common.showWARNINGtoastrmsg('Messages.atleastonefield', 3000, true, false);
            res=false;
          }else{
            res=true;
          }
          break;
        }

        default: { 
         

          break;
        }
      }

      // if(this.folderSearchCriteriaDto.FolderSearchDTO){
      //   if(this.folderSearchCriteriaDto.FolderSearchDTO.CreateDateTo == null &&
      //     this.folderSearchCriteriaDto.FolderSearchDTO.CreateDatefrom == null &&
      //     this.folderSearchCriteriaDto.FolderSearchDTO.DepDateTo == null &&
      //     this.folderSearchCriteriaDto.FolderSearchDTO.DepDatefrom == null &&
      //     this.folderSearchCriteriaDto.FolderSearchDTO.DepDatefromAF == null )
      //   {
      //     this.common.showWARNINGtoastrmsg('Messages.atleastonefield', 3000, true, false);
      //     res=false;
      //   }else{
      //     res=true;
      //   }
      // }
    }
   
    return res;
  }

  CheckDateRange(): Boolean {//337
    debugger;
    var ValidDate: boolean = false;//337
    if (this.folderSearchCriteriaDto.FolderSearchDTO != undefined)//337
    {

      if (this.DateRangeValidation(this.folderSearchCriteriaDto.FolderSearchDTO.DepDatefromAF, "Messages.InvalidDepartureDate"))
        if (this.DateRangeValidation(this.folderSearchCriteriaDto.FolderSearchDTO.DepDateToAF, "Messages.InvalidDepartureDate"))
          if (this.DateRangeValidation(this.folderSearchCriteriaDto.FolderSearchDTO.CreationDatefromAF, "Messages.InvalidCreationDate"))
            if (this.DateRangeValidation(this.folderSearchCriteriaDto.FolderSearchDTO.CreationDateToAF, "Messages.InvalidCreationDate"))
              ValidDate = true;
      
      if(this.folderSearchCriteriaDto.FolderSearchDTO.DepDateToAF != null){

        if (new Date(this.folderSearchCriteriaDto.FolderSearchDTO.DepDateToAF) < new Date(this.folderSearchCriteriaDto.FolderSearchDTO.DepDatefromAF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            } )
          }));
          this.spinner.hide();
          return;
        }

      }

      if (this.folderSearchCriteriaDto.FolderSearchDTO.CreationDateToAF != null){
        if (new Date(this.folderSearchCriteriaDto.FolderSearchDTO.CreationDateToAF) < new Date(this.folderSearchCriteriaDto.FolderSearchDTO.CreationDatefromAF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      } 

      
      

      if (ValidDate == false)
        return false;
    }

    if (this.folderSearchCriteriaDto.HotelSearchDTO != undefined) {
      if (this.DateRangeValidation(this.folderSearchCriteriaDto.HotelSearchDTO.CheckInDatefromAF, "Messages.InvalidCheckinDate"))
        if (this.DateRangeValidation(this.folderSearchCriteriaDto.HotelSearchDTO.CheckInDateToAF, "Messages.InvalidCheckinDate"))
          if (this.DateRangeValidation(this.folderSearchCriteriaDto.HotelSearchDTO.CheckOutDatefromAF, "Messages.InvalidCheckOutDate"))
            if (this.DateRangeValidation(this.folderSearchCriteriaDto.HotelSearchDTO.CheckOutDateToAF, "Messages.InvalidCheckOutDate"))
              ValidDate = true;
      if(this.folderSearchCriteriaDto.HotelSearchDTO.CheckInDateToAF != null) {
        if (new Date(this.folderSearchCriteriaDto.HotelSearchDTO.CheckInDateToAF) < new Date(this.folderSearchCriteriaDto.HotelSearchDTO.CheckInDatefromAF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }     
      
      if(this.folderSearchCriteriaDto.HotelSearchDTO.CheckOutDateToAF != null){
        if (new Date(this.folderSearchCriteriaDto.HotelSearchDTO.CheckOutDateToAF) < new Date(this.folderSearchCriteriaDto.HotelSearchDTO.CheckOutDatefromAF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }
      
      
      if (ValidDate == false)
        return false;
    }

    if (this.folderSearchCriteriaDto.CarSearchDTO != undefined) {
      if (this.DateRangeValidation(this.folderSearchCriteriaDto.CarSearchDTO.hireFrom1AF, "Messages.InvalidHiredFromDate"))
        if (this.DateRangeValidation(this.folderSearchCriteriaDto.CarSearchDTO.hireFrom2AF, "Messages.InvalidHiredFromDate"))
          if (this.DateRangeValidation(this.folderSearchCriteriaDto.CarSearchDTO.hireTo1AF, "Messages.InvalidHiredToDate"))
            if (this.DateRangeValidation(this.folderSearchCriteriaDto.CarSearchDTO.hireTo2AF, "Messages.InvalidHiredToDate"))
              ValidDate = true;
      if(this.folderSearchCriteriaDto.CarSearchDTO.hireFrom2AF != null){
        if (new Date(this.folderSearchCriteriaDto.CarSearchDTO.hireFrom2AF) < new Date(this.folderSearchCriteriaDto.CarSearchDTO.hireFrom1AF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }

      }
      if(this.folderSearchCriteriaDto.CarSearchDTO.hireTo2AF != null){
        if (new Date(this.folderSearchCriteriaDto.CarSearchDTO.hireTo2AF) < new Date(this.folderSearchCriteriaDto.CarSearchDTO.hireTo1AF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }
      
      if (ValidDate == false)
        return false;
      }

    if (this.folderSearchCriteriaDto.TransfersSearchDTO != undefined) {
      if (this.DateRangeValidation(this.folderSearchCriteriaDto.TransfersSearchDTO.DepDateFromAF, "Messages.InvalidDepartureDate"))
        if (this.DateRangeValidation(this.folderSearchCriteriaDto.TransfersSearchDTO.DepDateToAF, "Messages.InvalidDepartureDate"))
          if (this.DateRangeValidation(this.folderSearchCriteriaDto.TransfersSearchDTO.BookingDateFromAF, "Messages.InvalidBookingDate"))
            if (this.DateRangeValidation(this.folderSearchCriteriaDto.TransfersSearchDTO.BookingDateToAF, "Messages.InvalidBookingDate"))
              ValidDate = true;
      if(this.folderSearchCriteriaDto.TransfersSearchDTO.DepDateToAF != null){
        if (new Date(this.folderSearchCriteriaDto.TransfersSearchDTO.DepDateToAF) < new Date(this.folderSearchCriteriaDto.TransfersSearchDTO.DepDateFromAF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }

      }
      
      if(this.folderSearchCriteriaDto.TransfersSearchDTO.BookingDateToAF != null){
        if (new Date(this.folderSearchCriteriaDto.TransfersSearchDTO.BookingDateToAF) < new Date(this.folderSearchCriteriaDto.TransfersSearchDTO.BookingDateFromAF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }
      
      if (ValidDate == false)
        return false;
    }

    if (this.folderSearchCriteriaDto.TourSearchDTO != undefined) {
      if (this.DateRangeValidation(this.folderSearchCriteriaDto.TourSearchDTO.StartDateFromAF, "Messages.InvalidStartDate"))
        if (this.DateRangeValidation(this.folderSearchCriteriaDto.TourSearchDTO.StartDateToAF, "Messages.InvalidStartDate"))
          if (this.DateRangeValidation(this.folderSearchCriteriaDto.TourSearchDTO.EndDateFromAF, "Messages.InvalidEndDate"))
            if (this.DateRangeValidation(this.folderSearchCriteriaDto.TourSearchDTO.EndDateToAF, "Messages.InvalidEndDate"))
              if (this.DateRangeValidation(this.folderSearchCriteriaDto.TourSearchDTO.BookingDateFromAF, "Messages.InvalidBookingDate"))
                if (this.DateRangeValidation(this.folderSearchCriteriaDto.TourSearchDTO.BookingDateToAF, "Messages.InvalidBookingDate"))
                  ValidDate = true;


      if(this.folderSearchCriteriaDto.TourSearchDTO.StartDateToAF != null){
        if (new Date(this.folderSearchCriteriaDto.TourSearchDTO.StartDateToAF) < new Date(this.folderSearchCriteriaDto.TourSearchDTO.StartDateFromAF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }

      if(this.folderSearchCriteriaDto.TourSearchDTO.StartDateFromAF != null){
        if (new Date(this.folderSearchCriteriaDto.TourSearchDTO.StartDateFromAF) < new Date(this.folderSearchCriteriaDto.TourSearchDTO.StartDateFromAF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }



      if(this.folderSearchCriteriaDto.TourSearchDTO.EndDateToAF != null){
        if (new Date(this.folderSearchCriteriaDto.TourSearchDTO.EndDateToAF) < new Date(this.folderSearchCriteriaDto.TourSearchDTO.EndDateFromAF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }

      if(this.folderSearchCriteriaDto.TourSearchDTO.EndDateFromAF != null){
        if (new Date(this.folderSearchCriteriaDto.TourSearchDTO.EndDateFromAF) < new Date(this.folderSearchCriteriaDto.TourSearchDTO.EndDateFromAF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }



      if(this.folderSearchCriteriaDto.TourSearchDTO.BookingDateToAF != null){
        if (new Date(this.folderSearchCriteriaDto.TourSearchDTO.BookingDateToAF) < new Date(this.folderSearchCriteriaDto.TourSearchDTO.BookingDateToAF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }

      if(this.folderSearchCriteriaDto.TourSearchDTO.BookingDateFromAF != null){
        if (new Date(this.folderSearchCriteriaDto.TourSearchDTO.BookingDateFromAF) < new Date(this.folderSearchCriteriaDto.TourSearchDTO.BookingDateFromAF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }
      
      
      if (ValidDate == false)
        return false;
    }

    if (this.folderSearchCriteriaDto.CruiseSearchDTO != undefined) {
      if (this.DateRangeValidation(this.folderSearchCriteriaDto.CruiseSearchDTO.EmbtDateFromDt, "Messages.InvalidEmbarkDate"))
        if (this.DateRangeValidation(this.folderSearchCriteriaDto.CruiseSearchDTO.EmbtDateToDt, "Messages.InvalidEmbarkDate"))
          if (this.DateRangeValidation(this.folderSearchCriteriaDto.CruiseSearchDTO.DisembDateFromDt, "Messages.InvalidDisEmbarkDate"))
            if (this.DateRangeValidation(this.folderSearchCriteriaDto.CruiseSearchDTO.DisembDateToDt, "Messages.InvalidDisEmbarkDate"))
              ValidDate = true;

              if(this.folderSearchCriteriaDto.CruiseSearchDTO.EmbtDateFromDt != null){
                if (new Date(this.folderSearchCriteriaDto.CruiseSearchDTO.EmbtDateFromDt) < new Date(this.folderSearchCriteriaDto.CruiseSearchDTO.EmbtDateFromDt))//12687
                {
                  this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
                    this.showMsg = translations['Messages.fromdate_exceeds'];
                    this.toastr.warning(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));
                  this.spinner.hide();
                  return;
                }
              }
      
      if(this.folderSearchCriteriaDto.CruiseSearchDTO.EmbtDateToDt != null){
        if (new Date(this.folderSearchCriteriaDto.CruiseSearchDTO.EmbtDateToDt) < new Date(this.folderSearchCriteriaDto.CruiseSearchDTO.EmbtDateFromDt))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }

      if(this.folderSearchCriteriaDto.CruiseSearchDTO.DisembDateToDt != null){
        if (new Date(this.folderSearchCriteriaDto.CruiseSearchDTO.DisembDateToDt) < new Date(this.folderSearchCriteriaDto.CruiseSearchDTO.DisembDateFromDt))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
           })
          }));
          this.spinner.hide();
          return;
        }

      }
      
      
      if (ValidDate == false)
        return false;
    }

    if (this.folderSearchCriteriaDto.InsuranceSearchDTO != undefined) {
      if (this.DateRangeValidation(this.folderSearchCriteriaDto.InsuranceSearchDTO.DateFrom1AF, "Messages.InvalidFromDate"))
        if (this.DateRangeValidation(this.folderSearchCriteriaDto.InsuranceSearchDTO.DateFrom2AF, "Messages.InvalidToDate"))
          if (this.DateRangeValidation(this.folderSearchCriteriaDto.InsuranceSearchDTO.DateTo1AF, "Messages.InvalidFromDate"))
            if (this.DateRangeValidation(this.folderSearchCriteriaDto.InsuranceSearchDTO.DateTo2AF, "Messages.InvalidToDate"))
              if (this.DateRangeValidation(this.folderSearchCriteriaDto.InsuranceSearchDTO.BookingDateFrom1, "Messages.InvalidToDate"))
                if (this.DateRangeValidation(this.folderSearchCriteriaDto.InsuranceSearchDTO.BookingDateTo2, "Messages.InvalidToDate"))
                  ValidDate = true;
      if(this.folderSearchCriteriaDto.InsuranceSearchDTO.DateFrom2AF){
        if (new Date(this.folderSearchCriteriaDto.InsuranceSearchDTO.DateFrom2AF) < new Date(this.folderSearchCriteriaDto.InsuranceSearchDTO.DateFrom1AF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }

      if(this.folderSearchCriteriaDto.InsuranceSearchDTO.DateTo2AF != null){
        if (new Date(this.folderSearchCriteriaDto.InsuranceSearchDTO.DateTo2AF) < new Date(this.folderSearchCriteriaDto.InsuranceSearchDTO.DateTo1AF))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }
     
      if(this.folderSearchCriteriaDto.InsuranceSearchDTO.BookingDateTo2 != null){
        if (new Date(this.folderSearchCriteriaDto.InsuranceSearchDTO.BookingDateTo2) < new Date(this.folderSearchCriteriaDto.InsuranceSearchDTO.BookingDateFrom1))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }
      
      
      if (ValidDate == false)
        return false;
    }

    if (this.folderSearchCriteriaDto.OthersSearchDTO != undefined) {
      if (this.DateRangeValidation(this.folderSearchCriteriaDto.OthersSearchDTO.BookingDateFromDt, "Messages.InvalidBookingDate"))
        if (this.DateRangeValidation(this.folderSearchCriteriaDto.OthersSearchDTO.BookingDateToDt, "Messages.InvalidBookingDate"))
          ValidDate = true;
      if(this.folderSearchCriteriaDto.OthersSearchDTO.BookingDateToDt != null){
        if (new Date(this.folderSearchCriteriaDto.OthersSearchDTO.BookingDateToDt) < new Date(this.folderSearchCriteriaDto.OthersSearchDTO.BookingDateFromDt))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }
      }
      
      if (ValidDate == false)
        return false;
    }

    if (this.folderSearchCriteriaDto.RailSearchDTO != undefined) {
      if (this.DateRangeValidation(this.folderSearchCriteriaDto.RailSearchDTO.TicketDateFrom, "Messages.InvalidTicketDate"))
        if (this.DateRangeValidation(this.folderSearchCriteriaDto.RailSearchDTO.TicketDateTo, "Messages.InvalidTicketDate"))
          ValidDate = true;
      if(this.folderSearchCriteriaDto.RailSearchDTO.TicketDateTo != null){
        if (new Date(this.folderSearchCriteriaDto.RailSearchDTO.TicketDateTo) < new Date(this.folderSearchCriteriaDto.RailSearchDTO.TicketDateFrom))//12687
        {
          this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
            this.showMsg = translations['Messages.fromdate_exceeds'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
          this.spinner.hide();
          return;
        }

      }
      
      if (ValidDate == false)
        return false;
    }


    return true;
  }
  DateRangeValidation(Datevalue: any, MessageType: any): Boolean {
    debugger;
    if (Datevalue != null)
      if (Boolean(this.common.ValidDateRange(Datevalue)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get([MessageType]).subscribe((translations) => {
          this.showMsg = translations[MessageType];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        return false;
      }
    return true;
  }
  clearSearch() {
    debugger;
    if (this.folderSearchCriteriaDto.FolderSearchDTO != null) {
      this.folderSearchCriteriaDto.FolderSearchDTO = new FolderSearchDTO();
    }
    if (this.folderSearchCriteriaDto.HotelSearchDTO != null) {
      this.folderSearchCriteriaDto.HotelSearchDTO = new HotelSearchDTO();
    }
    if (this.folderSearchCriteriaDto.CarSearchDTO != null) {
      this.folderSearchCriteriaDto.CarSearchDTO = new CarSearchDTO();
    }
    if (this.folderSearchCriteriaDto.TourSearchDTO != null) {
      this.folderSearchCriteriaDto.TourSearchDTO = new TourSearchDTO();
    }
    if (this.folderSearchCriteriaDto.RailSearchDTO != null) {
      this.folderSearchCriteriaDto.RailSearchDTO = new RailSearchDTO();
    }
    if (this.folderSearchCriteriaDto.TransfersSearchDTO != null) {
      this.folderSearchCriteriaDto.TransfersSearchDTO = new TransfersSearchDTO();
    }
    if (this.folderSearchCriteriaDto.CruiseSearchDTO != null) {
      this.folderSearchCriteriaDto.CruiseSearchDTO = new CruiseSearchDTO();
    }
    if (this.folderSearchCriteriaDto.OthersSearchDTO != null) {
      this.folderSearchCriteriaDto.OthersSearchDTO = new OthersSearchDTO();
    }
    if (this.folderSearchCriteriaDto.InsuranceSearchDTO != null) {
      this.folderSearchCriteriaDto.InsuranceSearchDTO = new InsuranceSearchDTO();
    }

    this.folderSearchCriteriaDto = new FolderSearchCriteriaDTO();
    this.searhTransferDTO.FolderSearch(this.folderSearchCriteriaDto);

    this.router.navigated = false;
    this.router.navigateByUrl('/PenAir').then(() => {
     // this.router.navigate(['PenAir/Search/Folder'])

      this.MenuClick(this.submenuIndex,false);
    });
    
  }

  public StatusValueChange(value: any): void {

    if (value != null && value != undefined) {
      this.folderSearchCriteriaDto.FoderStatus = value.value;
    }
    // this.StatusSelectedItem =this.statusList.find(item => item.value ==value.value);
    // if(this.StatusSelectedItem != null)
    // {
    //   this.folderSearchCriteriaDto.FoderStatus=( this.StatusSelectedItem.value);
    // // this.SalesListFormGroup.controls.Type_Value.setValue( this.TypeSelectedItem.text);
    // }
    else {
      this.folderSearchCriteriaDto.FoderStatus = 0;
    }
  }
  openMenu() {
    let toggleMenuButtonElements = document.getElementsByClassName("__js_menu_button");

    if (toggleMenuButtonElements.length === 0)
      console.log("__js_menu_button classes not found");

    for (let i = 0; i < toggleMenuButtonElements.length; i++) {
      let menuElement = document.getElementById("menu_element");

      if (menuElement === null)
        console.log("menu_element id not found");
      else
        menuElement.classList.toggle("open");
    }
  }

  closeMenu() {
    let menuElement = document.getElementById("menu_element");
    menuElement.classList.remove("open");
  }

  public OnCancel() {
    this.router.navigate(['PenAir/Home']);
  }

  public PaystatusValueChange(value: any): void {
    if (value != null && value != undefined) {
      if (value.text !== 'All')
        this.folderSearchCriteriaDto.PayStatus = value.text;
      else
        this.folderSearchCriteriaDto.PayStatus = '';
    }
    // this.PaystatusSelectedItem =this.payStatusList.find(item => item.value ==value.value);
    // if(this.PaystatusSelectedItem != null)
    // {
    //   this.folderSearchCriteriaDto.PayStatus=( this.PaystatusSelectedItem.text);
    // // this.SalesListFormGroup.controls.Type_Value.setValue( this.TypeSelectedItem.text);
    // }
    else {
      this.folderSearchCriteriaDto.PayStatus = '';
    }

  }



  public opened = false;

  public open() {
    this.opened = true;
  }
  public close() {
    this.opened = false;

  }
  public onEnd() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const positionTop = windowHeight - this.height - this.offset;
    const positionLeft = windowWidth - this.offset;

    if (this.top < this.windowTop) {
      this.top = this.windowTop;
    }

    if (this.top > positionTop) {
      this.top = this.windowTop;
    }
    if (this.left < (this.windowLeft - 200)) {
      this.left = this.windowLeft;
    }
  }
  public gdsTrayOpen() {//By Caseid-12657
    this.openGDSTray = true;
  }
  public gdsTrayClose() { //By Caseid-12657
    this.openGDSTray = false;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public resetDTO(){
    this.folderSearchCriteriaDto.FolderSearchDTO = null;
    this.folderSearchCriteriaDto.HotelSearchDTO =null;
    this.folderSearchCriteriaDto.CarSearchDTO = null;
    this.folderSearchCriteriaDto.RailSearchDTO = null;
    this.folderSearchCriteriaDto.TransfersSearchDTO =  null;
    this.folderSearchCriteriaDto.TourSearchDTO =null;
    this.folderSearchCriteriaDto.CruiseSearchDTO = null;
    this.folderSearchCriteriaDto.InsuranceSearchDTO = null;
    this.folderSearchCriteriaDto.OthersSearchDTO = null;
    this.folderSearchCriteriaDto.BookedBy = null;
    this.folderSearchCriteriaDto.FoderStatus = null;
    this.folderSearchCriteriaDto.PayStatus = null;
  }

  onActivate(componentReference) {
    debugger;
    //// console.log(componentReference)
    //componentReference.anyFunction();
    //Below will subscribe to the searchItem emitter
    if (componentReference.menuIndex != undefined)
    this.submenuIndex = componentReference.menuIndex;
      
  }
}

