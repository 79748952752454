import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


import { ThrowStmt } from '@angular/compiler';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EnvService } from 'src/env.service';

import { catchError, tap } from 'rxjs/operators';
import { AtolReportDTO } from '../Models/crystal-report.model';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class CrystalReportService {
  pdfurl:string;
  url:string;
  formData:AtolReportDTO;
  constructor(private http: HttpClient,private env: EnvService) { this.pdfurl=env.baseUrl}



  
  downloadPDF(): Observable<any> {

    return this.http.get<any>( this.url+'/viewpdf1/',
    {  } );  
    // return this.http.get(this.url+'/viewpdf/', { responseType: 'blob', observe: 'response' }).pipe(
    //   map((result:HttpResponse<Blob>) => {
    //     console.log(result);
    //    // saveAs(result, "Quotation.pdf");
    //     return result;
     
    //   }))};
  
  }
  public  AtolReport(): Observable<AtolReportDTO> {
    var body = {
      ...this.formData,
      
     
    };
    return this.http.post<any>(this.pdfurl+'AtolReport', body, httpOptions).pipe(
      tap((railRes: any) => console.log(``)),
     
    );
  }
  public  DeleteAtolReportPath(): Observable<AtolReportDTO> {
    var body = {
      ...this.formData,
      
     
    };
    return this.http.post<any>(this.pdfurl+'DeleteAtolReportPath', body, httpOptions).pipe(
      tap((railRes: any) => console.log(``)),
     
    );
  }
  
  public  GetAtoltype(): Observable<AtolReportDTO> {
    var body = {
      ...this.formData,
     
    };
    return this.http.post<AtolReportDTO>(this.pdfurl+'GetAtoltype', body, httpOptions).pipe(
      tap((railRes: any) => console.log(`added Rail w/ id=`)),
     
    );
  }
  public  UpdateAtolType(): Observable<AtolReportDTO> {
    var body = {
      ...this.formData,
 
    };
    return this.http.post<any>(this.pdfurl+'UpdateAtolType', body, httpOptions).pipe(
      tap((railRes: any) => console.log(`added Rail w/ id=`)),
     
    );
  }
}
