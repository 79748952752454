import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { RowArgs, SelectAllCheckboxState, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LookUpDetails } from 'src/app/Services/look-up-details';
import { CommonserviceService } from '../../Services/commonservice.service';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { PurgeAlertsService } from '../../Services/purge-alerts.service';
import { ShareDataService } from '../../Services/share-data.service';

@Component({
  selector: 'app-purge-alerts',
  templateUrl: './purge-alerts.component.html',
  styleUrls: ['./purge-alerts.component.scss']
})
export class PurgeAlertsComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  @ViewChild('autocompletecustomer', { read: false, static: false }) public autocompletecustomer: AutoCompleteComponent;
  purgeAlertReportForm: FormGroup;
  public opened: any = false;
  showFilter = false;
  fileToUpload: File
  PCNO: any;
  lookupname: any = 'CUSTOMER';
  lookuptype: string = '';
  CustomerList: Array<LookUpDetails["ResponseData"]> = [];
  public customerSelectedItem: LookUpDetails["ResponseData"];
  public customerSelected: String;
  branchList: Array<LookUpDetails["ResponseData"]> = [];
  public BranchSelectedItem: LookUpDetails["ResponseData"];
  businessAreaList: Array<LookUpDetails["ResponseData"]> = [];
  public BusinessAreaSelectedItem: LookUpDetails["ResponseData"];
  ActionList: Array<LookUpDetails["ResponseData"]> = [];
  public ActionSelectedItem: LookUpDetails["ResponseData"];
  UserList: Array<LookUpDetails["ResponseData"]> = [];
  public UserListSelectedItem: LookUpDetails["ResponseData"];
  AlertStatusList: Array<string> = ["Open with Alert", "Open", "Closed"];
  openSearch = false;
  PurgeAlertsDTO: any = [];
  gridHeight = 175;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  // public state: State = {
  //   skip: 0,
  //   //take: 10,
  //   // Initial filter descriptor
  //   filter: {
  //     logic: 'and',
  //     filters: []
  //   }
  // };
  public stateUser: State = {
    skip: 0,
    //take: 10,
    // sort: [{
    //   dir: "asc",
    //   field: "userName"
    // }]
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public pageSize: number = 50;
  public gridData: GridDataResult;
  Messages_no_records: any = '';
  PurgeAlertsList: any = [];
  totRows: number = 0;
  userID: any;
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public mySelection: number[] = [];
  public items: any[];
  public Componentname: string;
  showMsg: any;
  constructor(private router: Router, private lookupApi: LookUpDetailsService, private route: ActivatedRoute, private toastr: ToastrService, private translateapi: TranslateService
    , private spinner: NgxSpinnerService, public common: CommonserviceService, private formBuilder: FormBuilder,
    private apishared: ShareDataService, private alerts: PurgeAlertsService) { }

  ngOnInit() {
    sessionStorage.setItem('Componentname', "PurgeAlerts");
    this.purgeAlertReportForm = this.formBuilder.group({
      AlertCrDateFrom: null,
      AlertCrDateTo: null,
      TravelFromDate: null,
      TravelToDate: null,
      FolderNo: '',
      AlertStatus: ["Closed", Validators.required],
      ActionId: 0,
      AssignedTo: ['', Validators.required],
      CustCode: 0,
      BrID: [""],
      BAID: [""]
      // }, {
      //   validators: (formGroup: FormGroup) => {
      //     return this.validateDate(formGroup);
      //   }
    });

    this.userID = +(this.apishared.uid);
    this.subscription.add(this.lookupApi.GetAllLookup('ACTION').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.ActionList = data["ResponseData"];
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.UserList = data["ResponseData"];
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('BRANCH').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.branchList = data["ResponseData"];
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('BUSINESSAREA').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.businessAreaList = data["ResponseData"];
        }
      }
    ));
    //this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654
  }
  validateDate(formgroup: FormGroup) {
    debugger;
    if ((formgroup.controls["AlertCrDateFrom"].value == null || formgroup.controls["AlertCrDateFrom"].value == undefined)
      && (formgroup.controls["TravelFromDate"].value == null || formgroup.controls["TravelFromDate"].value == undefined)) {
      return { validateDate: true };
    }
    else {
      return null;
    }
  }
  handleFiltercustomer(value) {
    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.SearchCustomer(value, this.userID).subscribe(
        (data) => {
          this.CustomerList = data["ResponseData"];
        }
      ));
    } else {
      this.autocompletecustomer.toggle(false);
    }
  }

  //13864
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'CUSTOMER') {
      this.lookupname = 'CUSTOMER';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.customerSelectedItem = value;
        this.CustomerList = [];
        this.CustomerValueChange(value.Name)
      },

      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  close(value) {
    this.openSearch = false;
  }
  //13864

  public CustomerValueChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {

      if (this.CustomerList != null && this.CustomerList.length > 0) {//13864
        this.customerSelectedItem = this.CustomerList.find(item => item.Name == value);

      }

      if (this.customerSelectedItem != null) {
        this.customerSelected = this.customerSelectedItem.Name; //13864

        this.customerSelected = this.customerSelectedItem.Name;
        this.purgeAlertReportForm.controls.CustCode.setValue(+this.customerSelectedItem.Code);
      } else {
        this.autocompletecustomer.text = null;
        this.customerSelected = '';
        this.purgeAlertReportForm.controls.CustCode.setValue(0);
      }
    } else {
      this.autocompletecustomer.text = null;
      this.customerSelected = '';
      this.purgeAlertReportForm.controls.CustCode.setValue(0);
    }
    // this.customerSelectedItem =this.CustomerList.find(item => item.Name ==value);
    // if(this.customerSelectedItem != null)
    // {
    //   this.purgeAlertReportForm.controls.CustCode.setValue( +this.customerSelectedItem.Code);
    //   // this.PartyCode=this.customerSelectedItem.Code;      
    // }
    // else
    // {
    //   this.purgeAlertReportForm.controls.CustCode.setValue(0); 
    // }
  }
  public UserValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.purgeAlertReportForm.controls.AssignedTo.setValue(value.UsrCode);
    }
    else {
      this.purgeAlertReportForm.controls.AssignedTo.setValue('');
    }
  }
  public ActionChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.ActionSelectedItem = this.ActionList.find(item => item.UsrCode == value.UsrCode);
      if (this.ActionSelectedItem != null) {
        this.purgeAlertReportForm.controls.ActionId.setValue(this.ActionSelectedItem.Code);
      }
    } else {
      this.purgeAlertReportForm.controls.ActionId.setValue(0);
    }
  }
  public BusinessAreaValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.purgeAlertReportForm.controls.BAID.setValue(value.UsrCode);
    }
    else {
      this.purgeAlertReportForm.controls.BAID.setValue('');
    }
  }
  public BranchValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.purgeAlertReportForm.controls.BrID.setValue(value.UsrCode);
    }
    else {
      this.purgeAlertReportForm.controls.BrID.setValue('');
    }
  }
  public onFilterClick() {
    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }
  public onSelectAllChange(checkedState: SelectAllCheckboxState) {

    if (checkedState === 'checked') {
      this.mySelection = this.gridData.data.map((item) => item.ID);
      this.selectAllState = 'checked';
      //   this.rptopen();

    } else {
      this.mySelection = [];
      this.selectAllState = 'unchecked';
    }
  }
  public rowsSelectedKeys(context: RowArgs): any {
    return context.dataItem.ID;
  }

  public onSelectedKeysChange(e) {
    const len = this.mySelection.length;
    if (len === 0) {
      this.selectAllState = 'unchecked';
      if (this.items == undefined) {
        this.items = [];
      }
    } else if (len > 0 && len < this.PurgeAlertsList.length) {
      this.selectAllState = 'indeterminate';
    } else {
      this.selectAllState = 'checked';
    }

  }
  get f() { return this.purgeAlertReportForm.controls; }
  submitted = false;
  async GenerateClick() {
    debugger;
    this.submitted = true;
    if (this.customerSelectedItem == null || this.customerSelectedItem == undefined) {
      this.autocompletecustomer.text = null;
      this.customerSelected = '';
      this.purgeAlertReportForm.controls.CustCode.setValue(0);
    }
    if ((this.purgeAlertReportForm.controls["AlertCrDateFrom"].value == null || this.purgeAlertReportForm.controls["AlertCrDateFrom"].value == undefined)
      && (this.purgeAlertReportForm.controls["TravelFromDate"].value == null || this.purgeAlertReportForm.controls["TravelFromDate"].value == undefined)) {
      //return { validateDate: true };
      //}
      //if (this.purgeAlertReportForm.invalid) {
      this.subscription.add(this.translateapi.get(['Messages.empty_fromdate']).subscribe((translations) => {
        this.showMsg = translations['Messages.empty_fromdate'];;
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      return;
    }
    if (this.purgeAlertReportForm.invalid) {
      return;
    }
    if (this.purgeAlertReportForm.controls.AlertCrDateTo.value == null || this.purgeAlertReportForm.controls.AlertCrDateTo.value == undefined) {
      this.purgeAlertReportForm.controls.AlertCrDateTo.setValue(this.purgeAlertReportForm.controls.AlertCrDateFrom.value);
    }
    if (this.purgeAlertReportForm.controls.TravelToDate.value == null || this.purgeAlertReportForm.controls.TravelToDate.value == undefined) {
      this.purgeAlertReportForm.controls.TravelToDate.setValue(this.purgeAlertReportForm.controls.TravelFromDate.value);
    }
    if (this.purgeAlertReportForm.controls.AlertCrDateFrom.value != null && this.purgeAlertReportForm.controls.AlertCrDateFrom.value != undefined) {
      var result = await this.dateRangeValidation(this.purgeAlertReportForm.controls.AlertCrDateFrom.value, this.purgeAlertReportForm.controls.AlertCrDateTo.value);
      if (result == false) {
        return;
      }
    }
    if (this.purgeAlertReportForm.controls.TravelFromDate.value != null && this.purgeAlertReportForm.controls.TravelFromDate.value != undefined) {
      var result = await this.dateRangeValidation(this.purgeAlertReportForm.controls.TravelFromDate.value, this.purgeAlertReportForm.controls.TravelToDate.value);
      if (result == false) {
        return;
      }
    }
    // if(new Date(this.purgeAlertReportForm.controls.AlertCrDateTo.value) < new Date(this.purgeAlertReportForm.controls.AlertCrDateFrom.value))
    // {
    //   this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations ) => {
    //     this.showMsg= translations['Messages.fromdate_exceeds'];
    //     this.toastr.warning(this.showMsg,"",{
    //     timeOut:3000,
    //     disableTimeOut:false,
    //     closeButton:true
    //     })
    //     }));
    //     this.spinner.hide();
    //   return;
    // }
    // if(new Date(this.purgeAlertReportForm.controls.TravelToDate.value) < new Date(this.purgeAlertReportForm.controls.TravelFromDate.value))
    // {
    //   this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations ) => {
    //     this.showMsg= translations['Messages.fromdate_exceeds'];
    //     this.toastr.warning(this.showMsg,"",{
    //     timeOut:3000,
    //     disableTimeOut:false,
    //     closeButton:true
    //     })
    //     }));
    //     this.spinner.hide();
    //   return;
    // }
    this.spinner.show();
    this.mySelection = [];
    debugger;
    this.purgeClick = false;
    this.stateUser.skip = 0;
    //var pageno = (this.stateUser.skip / this.pageSize) + 1;
    this.getAlertList();

  }
  loading = false;
  getAlertList() {

    var pageno = (this.stateUser.skip / this.pageSize) + 1;
    this.subscription.add(this.alerts.getClosedAlertsList(this.purgeAlertReportForm.value, this.pageSize, pageno).subscribe(
      (data) => {
        debugger;
        this.loading = false;
        this.PurgeAlertsList = data["ResponseData"];
        if (data.ResponseData != null && data.ResponseData.length > 0) {
          this.totRows = data.ResponseData[0] != undefined ? data.ResponseData[0].tot_rows : 0
          data.ResponseData.forEach(element => {
            element.ALERTDATE = new Date(element.ALERTDATE);
          });
          this.gridData = {
            // data: orderBy(res.ResponseData, this.sort),
            data: data.ResponseData,
            total: data.ResponseData[0] != undefined ? data.ResponseData[0].tot_rows : 0
          };
          if (data.ResponseData.length > 12)
            this.gridHeight = window.innerHeight * .85;
          else
            this.gridHeight = 130 + (44 * data.ResponseData.length - 1);
        }
        else {
          this.gridHeight = 175;
          this.gridData = {
            data: [],
            total: 0
          };
          this.totRows = 0;
          this.subscription.add(this.translateapi.get(['Messages.no_records']).subscribe((translations) => {
            this.Messages_no_records = translations['Messages.no_records'];
            // alert(this.Messages_no_records)
          }));
          this.spinner.hide();
        }
        // if (this.PurgeAlertsList && this.PurgeAlertsList.length > 0) {
        //   if (this.PurgeAlertsList.length > 12)
        //     this.gridHeight = window.innerHeight * .85;
        //   else
        //     this.gridHeight = 130 + (44 * this.PurgeAlertsList.length - 1);
        // } else {
        //   this.gridHeight = 175;
        // }
        this.spinner.hide();
      }
    ));
  }
  async dateRangeValidation(fromDate, toDate) {
    debugger;
    var DateFrom = this.common.ConvertToLocaleDate(fromDate);
    var DateTo = this.common.ConvertToLocaleDate(toDate);
    if (Boolean(this.common.ValidDateRange(fromDate)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidFromDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return false;
    }
    if (Boolean(this.common.ValidDateRange(toDate)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidToDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return false;
    }
    if (new Date(toDate) < new Date(fromDate)) {
      this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {
        this.showMsg = translations['Messages.fromdate_exceeds'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.spinner.hide();
      return false;
    }
  }
  purgeClick = false;
  async onPurgeClick() {
    debugger;
    this.spinner.show();
    if (this.mySelection.length > 0) {
      if (Boolean(await this.common.checkPermission1('13.09.02.03')) == true) {
        this.PurgeAlertsDTO = [];
        this.mySelection.forEach(obj => {
          if (this.PurgeAlertsList.find((test) => test.ID === obj)) {
            this.PurgeAlertsDTO.push(this.PurgeAlertsList.find((test) => test.ID === obj));
          }
        });
        if (this.PurgeAlertsDTO.length > 0) {
          this.purgeClick = true;
          this.spinner.show();
          var PugeAlertModel = {
            UniqueId: 0,
            UserId: this.userID,
            AlertStatus: this.purgeAlertReportForm.value.AlertStatus
          }
          for (let data of this.PurgeAlertsDTO) {
            if (data && data.purgeStatus == undefined) {
              data.purgeStatus = "In Progress"
              PugeAlertModel.UniqueId = data.ID;
              PugeAlertModel.AlertStatus = this.purgeAlertReportForm.value.AlertStatus;
              var result = await this.alerts.PurgeClosedAlert(PugeAlertModel).
                then(res => {
                  debugger;
                  this.spinner.hide();

                  if (res != undefined || (res["ResponseData"] != null)) {
                    data.purgeStatus = res["ResponseData"];//res["ResponseData"].oMessage;
                  }
                  // else
                  // {

                  // }
                });
            }
          }
          // this.alertMenu.alertReload = true;
        } else {
          this.spinner.hide();

          // this.btnGenerate=false;
          // this.btnInvoice=false;
        }
      }
      else {
        this.spinner.hide();
        this.ShowPermissionMessageForm('13.09.02.03');//339
      }
    }
    else {
      this.spinner.hide();
      this.subscription.add(this.translateapi.get(['Messages.PleaseSelectAtleastOneRow']).subscribe((translations) => {
        this.showMsg = translations['Messages.PleaseSelectAtleastOneRow'];;
        this.toastr.error(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })

        return;
      }));
    }
  }

  public permissionmessageformopened: any;
  public PermissionMessageId: any;
  public PermissionFormClosing(status: any) {
    if (status == "EmailSendSuccesfully") {
      this.permissionmessageformopened = false;
    }
  }
  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;
    this.PermissionMessageId = permissionid;
  }
  onCancel() {
    this.router.navigate(['PenAir/Home']);
  }
  onClear() {
    //formDirective.resetForm();
    this.purgeAlertReportForm.reset();
    this.customerSelectedItem = null;
    this.UserListSelectedItem = null;
    this.ActionSelectedItem = null;
    this.BranchSelectedItem = null;
    this.BusinessAreaSelectedItem = null;
    this.customerSelected = '';
    this.purgeAlertReportForm.controls.AlertStatus.setValue("Closed");
  }
  public pageChangeUser(event: PageChangeEvent): void {
    debugger;
    this.loading = true;
    if (this.purgeClick) {
      this.purgeClick = false;
      if (this.stateUser.skip < event.skip)
        this.stateUser.skip = event.skip - this.pageSize;
      else
        this.stateUser.skip = event.skip;
    }
    else {
      this.stateUser.skip = event.skip;
    }
    this.getAlertList();
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    debugger;
    //this.stateUser = state;
    this.gridData = {
      data: process(this.PurgeAlertsList, state).data,
      total: this.totRows
    };
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
