import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild, HostListener } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { GridDataResult, PageChangeEvent, DataStateChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";
import { aggregateBy, process, State } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  SelectableSettings,
  SelectableMode,
} from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-lookup-popup',
  templateUrl: './lookup-popup.component.html',
  styleUrls: ['./lookup-popup.component.scss']
})
export class LookupPopupComponent implements OnInit {
  @ViewChild('abcd') swiper: ElementRef;
  @ViewChild('myFile') myInputVariable: any;
  showFilter = false;
  public state: State = {
    skip: 0,
    take: 100
  };
  public stateremove: State = {
    skip: 0,
    take: 100
  };
  @Input() parentForm: FormGroup;
  @Input() lookuptype: any;
  @Input() _parentData !: any;
  public selectableSettings: SelectableSettings;
  gridHeight: any = 300;
  public gridView: GridDataResult;
  sort: any;
  virtual: any;
  public mySelection: number[] = [];
  SupplierLookupData: any = [];
  LookupData: any;
  public clickedRowItem;
  private subscription: Subscription = new Subscription();
  constructor(private lookupApi: LookUpDetailsService, private spinner: NgxSpinnerService) { }
  public pageSize = 10;
  public skip = 0;
  IDValue: string = "";
  NameValue: string = "";
  ngOnInit(): void {
    debugger;
    //  this.myInputVariable.nativeElement.focus();
    var dfg = this.parentForm;
    this.mySelection = [];
  }
  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    debugger;
    if (event.keyCode === 13) {
      this.SearchFullData();
    }
  }
  CallEnterkey(event: any) {
    if (event.keyCode == 13) {
      this.SearchFullData();
    } else {
    }
  }
  ngAfterViewInit(): void {
    this.myInputVariable.nativeElement.focus();

  }
  onFilterClick() {

    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }
  public pageChange(event: PageChangeEvent): void {
    debugger;
    this.skip = event.skip;
    this.loadLookup();
  }


  SearchFullData() {
    debugger;
    this.spinner.show();
    // if(this.state.filter!=undefined && this.state.filter['filters'].length>0 )
    // {
    //   if(this.state.filter.filters[0]['value']!=undefined && this.state.filter.filters[0]['field']== "ActualName")
    //   {
    //     var Namevalue=this.state.filter.filters[0]['value'];
    //   }
    //   else if(this.state.filter.filters[0]['value']!=undefined && this.state.filter.filters[0]['field']== "UsrCode"){
    //     var IDvalue=this.state.filter.filters[0]['value'];
    //   }
    //   if(this.state.filter.filters[1]!=undefined && this.state.filter.filters[1]['value']!=undefined  && this.state.filter.filters[1]['field']== "ActualName")
    //   {
    //     var IDvalue=this.state.filter.filters[1]['value'];
    //   }
    //   else if(this.state.filter.filters[1]!=undefined && this.state.filter.filters[1]['value']!=undefined && this.state.filter.filters[1]['field']== "UsrCode"){
    //     var IDvalue=this.state.filter.filters[1]['value'];
    //   }
    // }
    if (this.NameValue == "") {
      this.NameValue = null;
    }
    if (this.IDValue == "") {
      this.IDValue = null;
    }
    if (this.IDValue == null && this.NameValue == null) {
      this.subscription.add(this.lookupApi.GetAllLookup(this.lookuptype).subscribe(
        (data) => {
          this.spinner.hide();
          if (data != null && data != undefined) {

            this.LookupData = data["ResponseData"];
            this.loadLookup();
          }
        }
      ));
    }
    else {
      this.subscription.add(this.lookupApi.GetLookupByIdAndName(this.lookuptype, this.NameValue, this.IDValue).subscribe(
        (data) => {
          this.spinner.hide();
          if (data != null && data != undefined) {
            this.LookupData = data["ResponseData"];
            this.loadLookup();
          }
        }
      ));
    }


    //   else if(this.lookuptype=="CUSTOMER")

    //   {
    //     if(IDvalue==null && Namevalue==null)
    //     {
    //       this.subscription.add(this.lookupApi.GetLookupByIdAndName('CUSTOMER',Namevalue,IDvalue).subscribe(

    //       (data) => {
    //         this.spinner.hide();
    //         if (data != null && data != undefined) {

    //           this.LookupData = data["ResponseData"];
    //           this.loadLookup();
    //         }
    //       }
    //     ));
    //   }
    //   else
    //   {
    //     this.subscription.add(this.lookupApi.GetAllLookup('CUSTOMER').subscribe(
    //       (data) => {
    //         this.spinner.hide();
    //         if (data != null && data != undefined) {

    //           this.LookupData = data["ResponseData"];
    //           this.loadLookup();
    //         }
    //       }
    //     ));
    //   }
    // }
  }
  private loadLookup(): void {
    this.gridView = {
      data: this.LookupData.slice(this.skip, this.skip + this.pageSize),
      total: this.LookupData.length,
    };
    this.loadLookupGrid();
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    debugger;

    this.state = state;

    //  this.loadLookupGrid()

  }


  onCellClick(e) {
    debugger;
    this.clickedRowItem = e.dataItem;

  }
  onSelectClick() {
    debugger;
    this.dblClickEventCell(this.clickedRowItem);
  }
  async dblClickEvent(event) {
    let rowIndex;
    debugger;
    rowIndex = event.target.parentElement.rowIndex;
    var data = event;
    let clickedRowItem = this.gridView.data[rowIndex];
    this.dblClickEventCell(clickedRowItem);
  }
  dblClickEventCell(dataitem) {
    debugger;
    if (dataitem != null) {
      dataitem.Status = 1;
      this._parentData.callParentMethod(dataitem);
    }
  }
  private loadLookupGrid(): void {
    this.gridView = process(this.LookupData, this.state);
  }
  onCloseClick() {
    debugger;
    this._parentData.closemodalpopup();
  }
  onTextBoxChange(filterInput, id: any) {
    if (id == 1) {
      this.IDValue = filterInput;
      if (this.IDValue == "") {
        this.IDValue = null;
      }
    }
    else if (id == 0) {
      this.NameValue = filterInput;
      if (this.NameValue == "") {
        this.NameValue = null;
      }
    }
  }




}