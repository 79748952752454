export class DocumentEmailDTO {
    OrderNo: string
    ReportType: string
    rptfilename: string;
    ReportName: String;
    LanId: String;
    rptObj: SupplierRemittanceAdviceDTO;
    UserId: any;
    DailyJournal_rptObj: DailyJournalAuditReportDTO;
    FormTitle: String;
    // UserId: any;    
}
export class DailyJournalAuditReportDTO {
    DOCTYPE: string
    DOCNO: string
    ReportType: any;
    CreateDateFrom: any;
    CreateDateTo: any;
    DocDateFrom: any;
    DocDateTo: any;
    DelDateTo: any;
    DelDateFrom: any;
    CreatedUser: any;
    DeletedUser: any;
    ReferenceNo: any;
    GL_Account: any;
    DOCTYPE_name: string;
    GL_Account_name: string;
    CreatedUserName: any;
    DeletedUserName: any;
}
export class SupplierRemittanceAdviceDTO {
    DOCTYPE: string
    DOCNO: string
    Year: any;
    RemitAdvicewithFolderDetails: any;
}