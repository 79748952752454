import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { Subject, Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
import { ShareDataService } from '../../Services/share-data.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { PassengerDetailsn } from 'src/app/Models/passenger-details';
import { PnrsourcefileService } from '../../Services/pnrsourcefile.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { aggregateBy } from '@progress/kendo-data-query';
class installmentPlanDetailsDTO {
  SlNo: any = 1;
  InstallmentDate: any = new Date();
  InstallmentPc: number = 0.00;
  InstallmentAmount: number = 0.00;
}
@Component({
  selector: 'app-installment-plan',
  templateUrl: './installment-plan.component.html',
  styleUrls: ['./installment-plan.component.scss']
})
export class InstallmentPlanComponent implements OnInit {
  InstallmentPlanForm: FormGroup;
  Componentname: string;
  public formeditable = true;
  add = false;
  public submitted = false;
  private editedRowIndex: number;
  private orderNo: string = this.route.snapshot.params['orderNo'];
  private subscription: Subscription = new Subscription();
  installmentPlanGrid: any = [];
  installmentPlanGridCurr: any = [];
  orderTypeCurr: any;
  showMsg: any;
  orderTypeList: Array<LookUpDetails["ResponseData"]> = [];
  public orderTypeSelectedItem: LookUpDetails["ResponseData"];
  public orderTypeSelected: String;
  public total: any;
  public showtotal = false;
  public aggregates: any[] = [{ field: 'InstallmentAmount', aggregate: 'sum' }, { field: 'InstallmentPc', aggregate: 'sum' }];
  lockStatus: any;
  public folderStatus: any;
  public formEditable = true;
  public itemToRemove: any;
  public removeRowIndex: any;
  public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  constructor(public common: CommonserviceService, private apiShared: ShareDataService,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private translateapi: TranslateService, private formBuilder: FormBuilder,
    private route: ActivatedRoute, private instalmentapi: PnrsourcefileService, public intl: IntlService,
    private router: Router, public lookupApi: LookUpDetailsService) { }

  ngOnInit() {
    this.Componentname = "InstallmentPlans";
    sessionStorage.setItem('Componentname', this.Componentname);
    var orderno = sessionStorage.getItem('orderNo');

    if (orderno != null)
      if (this.orderNo !== orderno) {
        this.router.navigate(['PenAir/Home']);
      }
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formEditable = true;
    } else {
      this.formEditable = false;
    }
    if (this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund") {
      this.formEditable = false;
    }
    this.InstallmentPlanForm = this.formBuilder.group({
      OrderNo: [''],
      InvoiceAmount: [0, Validators.required],
      CancellationFee: [0, Validators.required],
      OrderType: ['', Validators.required],
      CancellationDate: [],
      UserName: this.apiShared.uname,
      Language: [''],
      isEdited: true
    })
    this.subscription.add(this.lookupApi.GetAllLookup('INSTALLMENTORDERTYPE').subscribe(
      (data) => {
        debugger;
        if (data["ResponseData"] != null) {
          this.orderTypeList = data["ResponseData"];
          if (this.InstallmentPlanForm.controls.OrderType.value != "") {
            debugger;
            this.orderTypeSelectedItem = this.orderTypeList.find(item => item.UsrCode == this.InstallmentPlanForm.controls.OrderType.value);
          }
          else {
            this.orderTypeSelectedItem = this.orderTypeList.find(item => item.Default == 1);
            this.OrderTypeChange(this.orderTypeSelectedItem);
          }
        }
      }
    ));
    this.getFolderInstallmentPlan();
  }
  public getFolderInstallmentPlan() {
    this.subscription.add(this.instalmentapi.getFolderInstamentPlans(this.orderNo)
      .subscribe(data => {
        debugger;
        if (data != null && data != undefined) {
          this.InstallmentPlanForm.setValue({
            OrderNo: data["ResponseData"].OrderNo,
            InvoiceAmount: data["ResponseData"].InvoiceAmount,
            CancellationFee: data["ResponseData"].CancellationFee,
            OrderType: data["ResponseData"].OrderType,
            CancellationDate: data["ResponseData"].CancellationDate != null ? (data["ResponseData"].CancellationDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data["ResponseData"].CancellationDate)) : null,
            UserName: this.apiShared.uname,
            Language: this.apiShared.LanguageSelected,
            isEdited: true
          });
          this.orderTypeCurr = data["ResponseData"].OrderType;
          if (this.orderTypeList.length > 0) {
            if (data["ResponseData"].OrderType != "") {
              debugger;
              this.orderTypeSelectedItem = this.orderTypeList.find(item => item.UsrCode == data["ResponseData"].OrderType);
            }
            else {
              this.orderTypeSelectedItem = this.orderTypeList.find(item => item.Default == 1);
              this.OrderTypeChange(this.orderTypeSelectedItem);
            }
          }
          if (data["ResponseData"].InstallmentPlanDetails != null && data["ResponseData"].InstallmentPlanDetails.length > 0) {
            this.installmentPlanGrid = data["ResponseData"].InstallmentPlanDetails;
            //this.installmentPlanGridCurr = data["ResponseData"].InstallmentPlanDetails;
            this.installmentPlanGrid.forEach(val => this.installmentPlanGridCurr.push(Object.assign({}, val)));
            // this.total = aggregateBy(this.installmentPlanGrid, this.aggregates);
            // this.showtotal = true;
          }
          // else
          //   this.showtotal = false;
          this.showTotal();

          // else {
          //   if ()
          // }
        }
        else {
          //this.api.data = [];
        }
        // this.view = this.editService.pipe(map(data1 => process(data1, this.gridState)));
        // this.editService.read();

      }));
  }
  get f() { return this.InstallmentPlanForm.controls; }

  public async onSave() {
    if (Boolean(await this.common.checkPermission1('InstallmentPlan')) == true) {
      this.spinner.show();
      if (this.InstallmentPlanForm.invalid) {
        this.spinner.hide();
        return;
      }
      if (this.total["InstallmentPc"].sum > 100) {
        this.subscription.add(this.translateapi.get(['Messages.percentexceed']).subscribe((translations) => {
          this.showMsg = translations['Messages.percentexceed'];
          this.toastr.warning(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          });
        }));
        this.spinner.hide();
        return;
      }
      else {
        this.InstallmentPlanForm.controls.Language.setValue(this.apiShared.LanguageSelected);
        this.subscription.add(this.instalmentapi.SaveFolderInstamentPlans(this.InstallmentPlanForm.value, this.installmentPlanGrid)
          .subscribe(res => {
            debugger;
            if (res.IsSuccess === true && res.ResponseData.status_bit == 'true') {
              this.spinner.hide();
              this.isGridEditable = false;
              this.toastr.success(res.ResponseData.message, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })

              //this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo], { skipLocationChange: true });
            }
            else {
              this.spinner.hide();
              this.toastr.warning(res.ResponseData.message, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }
          }, (err) => {
            this.spinner.hide();
            this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
              this.showMsg = translations['Messages.some_error_in_save'];
              this.toastr.warning(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }));
          }));
      }

    } else {
      this.spinner.hide();
      this.showmsg('InstallmentPlan');
    }

  }
  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339

  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

  showmsg(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }
  isGridEditable = false;
  onEdit() {
    this.isGridEditable = true;
  }
  onCancel() {
    this.isGridEditable = false;
  }
  public OrderTypeChange(value: any): void {
    debugger;
    if (value != undefined) {
      this.InstallmentPlanForm.controls.OrderType.setValue(value.UsrCode);
      if (this.orderTypeCurr == value.UsrCode) {
        this.installmentPlanGridCurr.forEach(val => this.installmentPlanGrid.push(Object.assign({}, val)));
      } else {
        this.addGridItems(value.Code);
      }
      this.showTotal();
    }
    else {
      this.InstallmentPlanForm.controls.OrderType.setValue("");
      this.installmentPlanGrid = [];
    }
  }
  addGridItems(rowCount) {
    this.installmentPlanGrid = [];
    for (var i = 1; i <= rowCount; i++) {
      var lineItm = new installmentPlanDetailsDTO();
      lineItm.SlNo = i;
      this.installmentPlanGrid.push(lineItm);
    }
  }
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 50;


  public onPanelChange(event: any): void { }
  public onChange(event: any): void {
    this.expandMode = parseInt(event.target.value, 10);
  }

  public addHandler({ sender, dataItem }) {
    debugger;
    this.add = true;
    var nextNo = Math.max(...sender.data.data.map(o => o.SlNo))
    var form = this.createFormGroup(new installmentPlanDetailsDTO());
    form.controls.SlNo.setValue(nextNo + 1);
    sender.addRow(form);
  }

  public removeHandler({ sender, dataItem, rowIndex }) {
    if (this.isGridEditable == true) {
      this.itemToRemove = dataItem;
      this.removeRowIndex = rowIndex;
      sender.cancelCell();
      return this.removeConfirmationSubject;
    }
  }
  public confirmRemove(shouldRemove: boolean): void {
    this.removeConfirmationSubject.next(shouldRemove);
    if (shouldRemove) {
      this.installmentPlanGrid.splice(this.removeRowIndex, 1);
    }
    this.reorderGrid();
    this.removeRowIndex = null;
    this.itemToRemove = null;
  }
  reorderGrid() {
    let i = 1;
    this.installmentPlanGrid.forEach(element => {
      element.i = i;
      i++;
    });
    this.showTotal();
  }
  //#region Inline Grid add/edit/delete handlers
  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (this.isGridEditable) {
      if (!isEdited && !this.add) {
        sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
        //this.TypeChange(dataItem.FSRCondition, dataItem)
      }
    }
  }
  public cellCloseHandler(args: any) {
    debugger;
    const { formGroup, dataItem } = args;
    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      Object.assign(dataItem, formGroup.value);
      //this.editService.update(dataItem);
    }
    this.showTotal();
  }
  public showTotal() {
    if (this.installmentPlanGrid != null && this.installmentPlanGrid.length > 0) {
      this.total = aggregateBy(this.installmentPlanGrid, this.aggregates);
      this.showtotal = true;
      if (this.total["InstallmentPc"].sum > 100) {
        this.subscription.add(this.translateapi.get(['Messages.percentexceed']).subscribe((translations) => {
          this.showMsg = translations['Messages.percentexceed'];
          this.toastr.warning(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          });
        }));
      }
    }
    else
      this.showtotal = false;
  }
  public onValueChange(value: number, formGroup, dataItem, type): void {
    debugger;
    if (type == 'percent')
      formGroup.controls.InstallmentAmount.setValue(+this.common.ConvertToFixed2((this.InstallmentPlanForm.controls.InvoiceAmount.value * value) / 100));
    else
      formGroup.controls.InstallmentPc.setValue(+this.common.ConvertToFixed2((value / this.InstallmentPlanForm.controls.InvoiceAmount.value) * 100));
    Object.assign(dataItem, formGroup.value);

  }
  public onInvAmtChange(value: number) {
    this.installmentPlanGrid.forEach(element => {
      element.InstallmentAmount = +this.common.ConvertToFixed2((value * element.InstallmentPc) / 100);
    });
    this.showTotal();
  }
  public saveHandler({ sender, formGroup, rowIndex }) {
    debugger;
    var validdate = new Date('01/01/1900')
    var InstallmentDate = formGroup.value['InstallmentDate'];

    if (InstallmentDate != null) // For caseid-187
    {
      InstallmentDate = new Date(InstallmentDate);
      InstallmentDate.setSeconds(0);
      InstallmentDate.setMinutes(0);
      InstallmentDate.setHours(0);
      if (InstallmentDate < validdate) {
        this.subscription.add(this.translateapi.get(['Messages.InvalidDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidDate'];
          this.toastr.warning(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          });
        }));
        return;
      }
    }
    if (formGroup.valid) {
      this.installmentPlanGrid.push(formGroup.value);
      sender.closeRow(rowIndex);
      this.add = false;
    }
    this.showTotal();
  }
  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
      'SlNo': dataItem.SlNo,
      'InstallmentDate': new Date(dataItem.InstallmentDate),
      'InstallmentPc': +dataItem.InstallmentPc,
      'InstallmentAmount': +dataItem.InstallmentAmount,
    });
  }
  public editHandler({ sender, rowIndex, dataItem }) {
    this.submitted = false;
    this.closeEditor(sender);
    this.InstallmentPlanForm = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.InstallmentPlanForm);
  }
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.InstallmentPlanForm = undefined;
  }
  public cancelHandler({ sender, rowIndex }) {

    sender.closeRow(rowIndex);
  }
}
