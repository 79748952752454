import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../../env.service';
import { State, toODataString } from '@progress/kendo-data-query';
import { map, tap, filter, catchError } from 'rxjs/operators';
import {  AlertListDTO } from '../Models/alertdetails';
import { SearhdtotransferService } from './searhdtotransfer.service';
import { FolderSearchCriteriaDTO } from '../Models/folder-search-criteria-dto';
import { FoldersearchService } from './foldersearch.service';
import { CommonserviceService } from './commonservice.service';
////import { FoldersearchService } from './foldersearch.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class PaginationServiceService extends BehaviorSubject<GridDataResult> {
  public loading: boolean = false;
 public value:any;
  apiUrl :string;
  url:string;
  
  constructor(private commonservice :CommonserviceService, private http: HttpClient,private env: EnvService,private searchservice:SearhdtotransferService) {
     super(null);    
      this.apiUrl=env.baseUrl+"/odata/AlerListPagination"; this.url = env.baseUrl; }

  public query(state: State): void {
    this.loading = true;
    
    this.runQuery(state).subscribe(r => super.next(r));
  }

  private runQuery(state: State) {
    
   
      if(state.sort===undefined)
      {
        
        state.sort  = [{
          field: 'CRDATE',
          dir: 'desc'
        }];
      }
      else if(state.sort.length>0 && state.sort[0].dir===undefined)
      {
        state.sort  = [{
          field: 'CRDATE',
          dir: 'desc'
        }];
      }
      else if(state.sort.length===0)
      {
        state.sort  = [{
          field: 'CRDATE',
          dir: 'desc'
        }];
      }
    
   
    
     var SrchCriteria = {
      ...this.searchservice.folderSearchCriteriaDto
      //...this.foldersearchService.formData
        
     };
   // var body=new AlertListDTO();
  //  body.SrchCriteria=SrchCriteria;
 //body.FOLDERNO=7686;
   // var body1 =new FolderSearchCriteriaDTOq();
   // body.SrchCriteria=body1;
   // body.SrchCriteria.BookedBy="dd";
  // alert(JSON.stringify(body) );
   //alert(this.apiUrl);
//alert(JSON.stringify(SrchCriteria));

  if(state.filter!=undefined && state.filter.filters.length>0)
  {
    var i=0;
    state.filter.filters.forEach(obj => {
      if(state.filter.filters[i]['field']==='CUSTOMERNAME' || state.filter.filters[i]['field']==='Pass'){
        var value=state.filter.filters[i]['value'].toUpperCase()
        state.filter.filters[i]['value']=value;
      }
      // if(state.filter.filters[i]['field']==='PROJNO' || state.filter.filters[i]['field']==='Pass'){
      //   var value=state.filter.filters[i]['value'].toUpperCase()
      //   state.filter.filters[i]['value']=value;
      // }
 
    
      if(state.filter.filters[i]!==null||state.filter.filters[i]!==undefined) {
        if(state.filter.filters[i]['field']=='STATUSNAME' && state.filter.filters[i]['value']=='All') {
          state.filter.filters.splice(i, 1);
        }
       
      }
      
      if(state.filter!==undefined && state.filter.filters.length>0) {
              if(state.filter.filters[i]['field']=='Receipt' && state.filter.filters[i]['value']=='All') {
          state.filter.filters.splice(i, 1);
        }
      }
      i++;
    })
    
  }
    return this.http.post<any>(`${this.apiUrl}?${toODataString(state)}&$count=true`,SrchCriteria)
      .pipe(
        map(r => (<GridDataResult>{
          
          data: r.value.map(r => <AlertListDTO>r),
          total: parseInt(r["@odata.count"], 10),
         
        })),
        tap(() => this.loading = false)
        ,

        );
        
  }

 
  GetStatusList(status: any): Observable<any[]> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.get<any[]>( this.url+'/GetFolderStatus/'+status,
    { headers } );
  }
  GetSearchResult(PageSize :any, PageNo:any):Observable<any>{
  
    const url = `${this.url}`+'GetFolderSearch';    
    var SrchCriteria = {
      ...this.searchservice.folderSearchCriteriaDto
     };
     SrchCriteria.PageNo=PageNo;
     SrchCriteria.PageSize=PageSize;
    
    return this.http.post<any>(url, SrchCriteria, httpOptions).pipe(
      tap(_ => console.log(`GetSearchResult`)),
      catchError(this.commonservice.handleError<any>('GetSearchResult'))
    );
    
  }
  upload(frmData)
  {
    const url = `${this.url}`+'UploadFilesorder';  
    this.http.post(url, frmData).subscribe(
      data => {
      });
  }
  GetInboxClosedResult(PageSize: any, PageNo: any, IsDetailed: any, Type: any, userid: any, actionid: any): Observable<any> {

    const url = `${this.url}` + 'GetInboxgriddata';
    var SrchCriteria = {
      ...this.searchservice.InboxClosedParameterDTO
    };
    SrchCriteria.IsDetailed = IsDetailed;
    SrchCriteria.Type = Type;
    SrchCriteria.UserID = userid;
    SrchCriteria.ACTIONID = actionid;
    SrchCriteria.PageNo = PageNo;
    SrchCriteria.PageSize = PageSize;
    return this.http.post<any>(url, SrchCriteria, httpOptions).pipe(
      tap(_ => console.log(`GetInboxClosedResult`)),
      catchError(this.commonservice.handleError<any>('GetInboxClosedResult'))
    );

  }
  CopyToFolder(folderDetails: any): Observable<any> {
    const url = `${this.url}` + 'CopyToFolder';
    return this.http.post<any>(url, folderDetails, httpOptions).pipe(
      catchError(this.commonservice.handleError<any>('CopyToFolderSave'))
    );
  }
}
