import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WindowModule, DialogModule, DialogsModule } from "@progress/kendo-angular-dialog";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { NgxSpinnerModule } from "ngx-spinner";
import {TranslateModule} from "@ngx-translate/core";
import { AccordianMainMenuComponent } from './accordian-main-menu/accordian-main-menu.component';
import { SharedMenuRoutingModule } from './sharedMenu-routing.module';
import { AccountsMainMenuComponent } from './accounts-main-menu/accounts-main-menu.component';
import { RouterModule } from '@angular/router';
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { MenusModule } from "@progress/kendo-angular-menu";
import { PublicLayoutComponent } from '../public-layout/public-layout.component';
import { MatDialogModule } from '@angular/material/dialog'
import { AlertModule } from '@full-fledged/alerts';
import { SharedCommonModule } from '../SharedCommonModule/shared-common.module';

@NgModule({
  declarations: [
    AccordianMainMenuComponent,PublicLayoutComponent,
    AccountsMainMenuComponent],
  imports: [
    CommonModule,
    SharedCommonModule,
    InputsModule,
    FormsModule,
    ReactiveFormsModule,
    WindowModule,
    DialogModule,
    DialogsModule,
    ButtonsModule,
    NgxSpinnerModule,
    TranslateModule,
    SharedMenuRoutingModule,
    RouterModule,
    TreeViewModule,
    MenusModule,
    MatDialogModule,
    AlertModule
  ],
  exports: [AccordianMainMenuComponent,AccountsMainMenuComponent,PublicLayoutComponent],
  entryComponents:[PublicLayoutComponent],

})
export class SharedMenuModule { }
