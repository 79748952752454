import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownfilterComponent } from './dropdownfilter/dropdownfilter.component';
// import { SharedCommonRoutingModule } from './shared-common-routing.module';
import { EmailComponent } from './email/email.component';
import { ReportformComponent } from './reportform/reportform.component';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { Calendar2Component } from './calendar2/calendar2.component';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { TranslateModule} from "@ngx-translate/core";
import { UploadsModule } from "@progress/kendo-angular-upload";
import { NgxSpinnerModule } from "ngx-spinner";
import { PopupModule } from "@progress/kendo-angular-popup";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { ComfirmationWindowComponent } from './comfirmation-window/comfirmation-window.component';
import { WindowModule, DialogModule, DialogsModule } from "@progress/kendo-angular-dialog";
import {GridModule,ExcelModule} from "@progress/kendo-angular-grid";
import { LookupPopupComponent } from './lookup-popup/lookup-popup.component';
import { ReportschedulersetupformComponent } from './reportschedulersetupform/reportschedulersetupform.component';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { PermissionMessageFormComponent } from './permission-message-form/permission-message-form.component';
import { LogformComponent } from './logform/logform.component';
import { GDSTrayComponent } from './gdstray/gdstray.component';
import { ExpenseAddComponent } from './expense-add/expense-add.component';
import { PopupAnchorDirective } from './expense-add/PopupAnchorDirective';
import { DefaultchargeLookupComponent } from './defaultcharge-lookup/defaultcharge-lookup.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { PenchannelListComponent } from './penchannel-list/penchannel-list.component';
import { PaymentTermsComponent } from './payment-terms/payment-terms.component';
import { PaymentTermsEditComponent } from './payment-terms-edit/payment-terms-edit.component';
import { BookingConfirmationComponent } from './booking-confirmation/booking-confirmation.component';
// import { TelerikReportingModule,TelerikReportViewerComponent} from "@progress/telerik-angular-report-viewer";


@NgModule({
  declarations: [DropdownfilterComponent,EmailComponent,ComfirmationWindowComponent,ReportformComponent,Calendar2Component,
    ExpenseAddComponent,
    PopupAnchorDirective,
    DefaultchargeLookupComponent,MultiselectComponent,
    PenchannelListComponent,PaymentTermsComponent,PaymentTermsEditComponent,BookingConfirmationComponent,
    LookupPopupComponent,ReportschedulersetupformComponent,PermissionMessageFormComponent,LogformComponent,GDSTrayComponent],
  imports: [
    CommonModule,

    // SharedCommonRoutingModule,
    DropDownsModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    TranslateModule,
    UploadsModule,
    NgxSpinnerModule,
    PopupModule,
    DateInputsModule,
    WindowModule,
    DialogModule,
    DialogsModule,
    GridModule,
    ExcelModule,
    LayoutModule,
    ButtonsModule
    // TelerikReportingModule,
    // TelerikReportViewerComponent
  ],
  exports: [DropdownfilterComponent,ComfirmationWindowComponent,EmailComponent,ReportformComponent,
    GDSTrayComponent,MultiselectComponent,ExpenseAddComponent,DefaultchargeLookupComponent,
    PenchannelListComponent,PaymentTermsComponent,PaymentTermsEditComponent,BookingConfirmationComponent,
    Calendar2Component,LookupPopupComponent,ReportschedulersetupformComponent,PermissionMessageFormComponent,PopupAnchorDirective]
})
export class SharedCommonModule { }
