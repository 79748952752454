import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PanelBarExpandMode } from "@progress/kendo-angular-layout";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { DeletePO } from "../Models/delete-po";
import { Deletepo } from "../Models/deletepo";
import { LookUpDetails } from "../Services/look-up-details";
import { POCreationDetail } from "../Models/pocreation-detail";
import { Pocreationresult } from "../Models/pocreationresult";
import { HeotelselectService } from "../Services/heotelselect.service";
import { HotelService } from "../Services/hotel.service";
import { HotelmasterService } from "../Services/hotelmaster.service";
import { LookUpDetailsService } from "../Services/look-up-details.service";
import { PocreationService } from "../Services/pocreation.service";
import { ShareDataService } from "../Services/share-data.service";
import { HotelDetailsDTO } from "../Models/hotel-master";
import { HotelDetail } from "../Models/hotel-detail";
export abstract class HotelDetailsView {
  constructor(
    public lookupApi: LookUpDetailsService,
    public hotelselectservice: HeotelselectService,
    public hmservice: HotelmasterService,
    public route: ActivatedRoute,
    public api: HotelService,
    public apiShared: ShareDataService,
    public spinner: NgxSpinnerService,
    public translateapi: TranslateService,
    public toastr: ToastrService,
    public apiPO: PocreationService
  ) { }

  //#region Varibales
  public guid: string = this.route.snapshot.params.guidItinerary;
  public orderno = this.route.snapshot.params.orderNo;
  public subscription: Subscription = new Subscription();
  folderEditMode: any;
  public Componentname: string;
  public HotelList: Array<LookUpDetails["ResponseData"]> = [];
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;
  lockStatus: any;
  public folderStatus: any;
  public confirmMsg: any;
  public showMsg: any;
  public PONO: number;
  public pocreated = true;
  public deletePO: DeletePO;
  public language = this.apiShared.LanguageSelected;
  public result: any;
  public heolteldata: any;
  public DeleteInvoiceResponse: Deletepo;
  Pocreationresultobj: Pocreationresult;
  public POCreation: POCreationDetail;
  projNo: any;
  passName: any;
  loaded = false;
  fromtime: string;
  totime: string;
  showOrder = true; //By Case id-367
  public formeditable = true;
  public invoice = false; //By Caseid-12621
  public createPoInvoice = false; //By Caseid-12621
  public bookingDate: any;
  public FolderHideStatus = false; //12780

  hotelDetail: HotelDetail = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {
      SUPPRTYUSERCODE: "",
      FOLDERNO: 0,
      SUPPNAME: "",
      SUPPRTYCODE: 0,
      HOTELNAME: "",
      ROOMS: 0,
      TYPE: "",
      DATEIN: "01/Jan/1900",
      DATEOUT: "01/Jan/1900",
      NIGHTS: 2,
      STATUS: "Confirmed",
      PAYABLE: 0,
      VOUCHER: "",
      HOTELLOC: "",
      CITY: "",
      CURCODE: "",
      AMTLC: 0,
      NOPAX: 0,
      PAYMODE: "Credit",
      RDESC: "",
      CONFNO: "",
      GENINFO: "",
      ADDRESS: "",
      PHONE: "",
      FAX: "",
      ROOMRDESC: "",
      CANPOLICY: "",
      ZOOMBOX: "",
      EXRATE: 0,
      PID: "",
      PName: "",
      Guest: "",
      NotesToBP: "",
      NotesNotToBP: "",
      CommOn: "Purchase",
      AMTHC: 0,
      SLNO: 0,
      PROJNO: "",
      SELLCOMM: 0,
      SELLAMT: 0,
      SELLVAT: 0,
      SELLTOTAL: 0,
      SELLTAXNO: 0,
      SELLTAXRATE: 0,
      ITYPE: "",
      GDS: "",
      PNR: "",
      CITYID: "",
      HCCommPer: 0,
      FCBuy: 0,
      FCCommPer: 0,
      FCCommAmt: 0,
      FCSell: 0,
      CHECKIN: 0,
      CHECKOUT: 0,
      EMAIL: "",
      LowFB: "",
      HighFB: "",
      HighFare: 0,
      Fareoffered: 0,
      PotentialSaving: 0,
      ActualSaving: 0,
      PDNO: 0,
      PDTYPENO: 0,
      Forgone: 0,
      AddRemarks: "",
      MealPlan: "",
      PDAMOUNT: 0,
      BookingDate: "01/01/1900",
      PONO: 0,
      PONOYEARID: "",
      PDFCURID: "",
      PDEXRATE: 0,
      PDFCAMT: 0,
      PAYMENTID: 0,
      PMTID: "",
      DEFDEPPER: 0,
      LowFare: 0,
      DEFDEP: 0,
      DepositDueDate: "01/Jan/1900",
      BalanceDueDate: "01/Jan/1900",
      BalanceDueAmount: 0,
      GUIDItinerary: "",
      LOYALTYPOINTS: 0,
      LOYALTYPOINTSMEMBNO: "",
      TOMSVATTAXNO: 0,
      TOMSVATAMT: 0,
      LOYALTYPOINTSAMT: 0,
      LOYALTYPOINTSBAL: 0,
      TOMSVATCID: "",
      PDUNIQUEREF: "",
      FC_SELLTAXAMT: 0,
      PONOCCDeferredPosted: 0,
      BOOKEDBY: "",
      MealPlan_MPM_Id: "",
      TaxExcludingSalesTax: 0,
      BaseFare: 0,

      PDQDESC: "",
      CRDDESC: "",
      PMTDESC: "",
      PAYM: "",
      GSTHSTOnComm: 0,
      InterInd: "",
      Markup: 0,
      CostofSale: 0,
      CostingSupplierGrossValue: 0,
      CostingCustomerSalesValue: 0,
      CostingCustomerServiceFee: 0,
      CostingCustomerNetSellPrice: 0,
      CostingVATType: "",
      CostingSupplierServiceFee: 0,
      CostingTaxNo: 0,
      CostingTaxRate: 0,
      CostingSupplierTaxAmount: 0,
      CostingSupplierNetBuyPrice: 0,
      CostingCustomerTaxAmount: 0,
      CostingSuppComm: 0,
      HD_AutoNo: 0,
      Hote101_Id: 0,
      SupplierDeposit: 0
    }
  };
  //#endregion Varibales

  getHotelDetails(id, guid) {
    this.spinner.show();
    debugger;
    this.subscription.add(
      this.api.getHotel(id, guid, true).subscribe(data => {
        if (data != null && data != undefined) {
          this.api.guid = data.ResponseData.GUIDItinerary;
          this.api.formDatasmain.FOLDERNO = data.ResponseData.FOLDERNO;
          this.api.formDatasmain.HDNO = data.ResponseData.HD_AutoNo;
          this.hotelDetail = data;
          this.PONO = this.hotelDetail.ResponseData.PONO;
        } else {
          this.PONO = 0;
        }
        if (this.PONO > 0) {
          this.pocreated = false;
          this.invoice = true; //By case id-12621
          if (this.folderStatus == "Invoiced") {
            //By case id-12621
            this.pocreated = true;
          }
        } else {
          this.pocreated = true;
        }
        this.spinner.hide();
        if (data != null && data != undefined) {
          this.hotelDetail.ResponseData.InterInd =
            data.ResponseData.InterInd == ""
              ? ""
              : data.ResponseData.InterInd == "I"
                ? "International"
                : data.ResponseData.InterInd == "D"
                  ? "Domestic"
                  : data.ResponseData.InterInd == "T"
                    ? "Transborder"
                    : "";
          this.fromtime = this.pad(data.ResponseData.CHECKIN, 4);
          this.totime = this.pad(data.ResponseData.CHECKOUT, 4);
        }
        this.loaded = true;
        debugger;
        this.api.HD_AutoNo = data.ResponseData.HD_AutoNo;
        this.api.Hote101_Id = data.ResponseData.Hote101_Id;
        debugger;

        if (data.ResponseData.Hote101_Id != null) {
          this.LisHotelActivity(data.ResponseData.Hote101_Id);
        } else {
          if (data.ResponseData.HOTELLOC != "") {
            this.api.HD_AutoNo = data.ResponseData.HOTELLOC;
            this.subscription.add(
              this.lookupApi
                .findHotel(data.ResponseData.HOTELLOC)
                .subscribe(datar => {
                  if (datar != null && datar != undefined) {
                    debugger;

                    this.HotelList = datar["ResponseData"];
                    this.heolteldata = this.HotelList.find(
                      item => item.Code == this.api.HD_AutoNo
                    );
                    this.api.HD_AutoNo = this.heolteldata.Code;
                    this.api.formdata = new HotelDetailsDTO();
                    this.api.formdata.HOTELNAME = this.api.HD_AutoNo;
                    this.api.formdata.FOLDERNO = this.orderno;
                    this.api.formdata.GUIDItinerary =
                      data.ResponseData.GUIDItinerary;
                    this.subscription.add(
                      this.api.UpdateHote101HotelDetails().subscribe(
                        res => {
                          if (res == null || res == undefined) {
                            res = 0;
                          }
                          this.LisHotelActivity(res);
                        },
                        err => {
                          console.log(err);
                        }
                      )
                    );
                  }
                })
            );
          } else {
            this.hotelselectservice.actvitylist = [];
          }
        }
      })
    );
  }

  pad(num, size) {
    let s = num + "";
    while (s.length < size) {
      s = "0" + s;
    }
    return s;
  }

  LisHotelActivity(id: any) {
    this.subscription.add(
      this.hmservice.LisHotelActivity(id).subscribe(data => {
        this.hotelselectservice.actvitylist = data["ResponseData"];
      })
    );
  }

  getMessageFromCreatePO() {
    this.subscription.add(
      this.translateapi
        .get(["Messages.InvalidBookingDate"])
        .subscribe(translations => {
          this.showMsg = translations["Messages.InvalidBookingDate"];
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          });
        })
    );
  }

  clickOnCreatePo() {
    this.subscription.add(
      this.apiPO.CreatePO(this.POCreation).subscribe(res => {
        if (res != null && res != undefined) {
          this.Pocreationresultobj = res;
          this.spinner.hide();
          if (this.Pocreationresultobj.ResponseData.success_bit == "True") {
            if (this.Pocreationresultobj.ResponseData.invoiceno > 0) {
              this.subscription.add(
                this.translateapi
                  .get(["Messages.POCreated"])
                  .subscribe(translations => {
                    this.showMsg =
                      translations["Messages.POCreated"] +
                      ":" +
                      this.Pocreationresultobj.ResponseData.invoiceno;
                    this.toastr.success(this.showMsg, "", {
                      //By case id-12630
                      timeOut: 5000, //By case id-12630
                      disableTimeOut: false, //By case id-12630
                      closeButton: true
                    });
                    this.spinner.hide();
                    this.getHotelDetails(
                      this.orderno,
                      this.hotelDetail.ResponseData.GUIDItinerary
                    );
                  })
              );
              this.pocreated = false;
            }
            if (
              this.Pocreationresultobj.ResponseData.message ==
              "PO Already Created"
            ) {
              this.spinner.hide();
              this.showMsg = "";
              this.subscription.add(
                this.translateapi
                  .get(["Messages.POAlreadyCreated"])
                  .subscribe(translations => {
                    this.showMsg = translations["Messages.POAlreadyCreated"];
                    this.toastr.warning(this.showMsg, "", {
                      timeOut: 0,
                      disableTimeOut: true,
                      closeButton: true
                    });
                  })
              );
            }
          } else {
            this.spinner.hide();
            this.showMsg = "";
            this.subscription.add(
              this.translateapi
                .get(["Messages.Failed"])
                .subscribe(translations => {
                  if (this.Pocreationresultobj.ResponseData.message != null && this.Pocreationresultobj.ResponseData.message != "")
                    this.showMsg = this.Pocreationresultobj.ResponseData.message;
                  else
                    this.showMsg = translations["Messages.Failed"];
                  this.toastr.error(this.showMsg, "", {
                    timeOut: 0,
                    disableTimeOut: true,
                    closeButton: true
                  });
                })
            );
          }
        } else {
          this.spinner.hide();
        }
      })
    );
  }

  getHotelWhenClickOnDeletePOButton() {
    this.subscription.add(
      this.api
        .getHotel(
          this.orderno,
          this.hotelDetail.ResponseData.GUIDItinerary,
          true
        )
        .subscribe(data => {
          this.hotelDetail = data;
        })
    );
  }

  clickOnDeletePo() {
    this.subscription.add(
      this.apiPO.DeletePO(this.deletePO).subscribe(res => {
        if (res != null && res != undefined) {
          this.DeleteInvoiceResponse = res;
          this.result = this.DeleteInvoiceResponse.ResponseData["message"];
          this.spinner.hide();
          if (this.result.includes("PO Deleted:")) {
            this.spinner.hide();
            this.pocreated = true;
            this.invoice = false; //By Caseid-12621
            this.toastr.success(this.result, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            });
          } else {
            this.spinner.hide();
            this.toastr.error(this.result, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            });
          }
        }
      })
    );
  }
}
