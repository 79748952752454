export class CashPaymentDTO {
  PVRDATE: any;
  OPENINGMONEY: any;
  CLOSINGMONEY: any;
  CurrentBalance: any;
  LastBalance: any;
  LIMITMONEY: any;
  PETTYCASH: any;

  GROSSTOTALMONEY: any;
  NETTAXMONEY: any;
  date: any;
  userId: any;
  pYearId: any;

  returnField: any;
  LANQUAGE: any;
  BRID: any;
  CashAccountNACCODE: any;
  RXRATE: any;
  FCID: any;
  HCID: any;
}
export class CashPaymentMasterDTO {
  ResponseData: {
    messageid: any;
    message: any;
    vono: any;
    sqnNo: any;
    success_bit: any;
    TAXCODE: any;
  };
}
export class CashPaymentBalceDTO {
  CashAccount: any;
  CurrentDate: Date;
  VrType: any;
  CurrentBalance: any;
  LastBalance: any;
  BRID: any;
  BankNacCode:any;
}
export class CashPaymentMasterGridDTO {
  index: any;
  NACCODE: any;
  NARRATION: any;
  NET: any;
  TAXNO: any;
  TAXRATE: any;
  TAXAMOUNT: any;
  GROSS: any;
  BRANCHID: any;
  COSTCENTER: any;
  PROFITCENTER: any;
  PBUSINESSAREA: any;
  TaxNACCODE: any;
  ACCOUNTNAME: any;
  NETHC: any;
  VATHC: any;
  TotalHC: any;
}
export class AtolReportDTO {
  parFolderNo: any;

  parNumPass: any;
  parNameofprotectingATOLholderandATOLnumber: any;
  paruniquereferencenumber: any;

  parDateofIssue: any;
  parATOLCertificateissuer: any;
  parALOLNo: any;
  ParNumPassCount1: any;
  parSRNO: any;
  parSumPrice: any;
  partravelDetailsArr: any;

  partravelDetails_return: any;
  index: any;
  parBookingreferencenumber: any;

  parAmt: any;
  ParNumPassCount: any;
  parItemno: any;
  parProtectingATOLHolder: any;
  parPackageDescription: any;

  parPack_Item_Count: any;
}
