import { LookUpDetails } from "../Services/look-up-details";
import { FileDetails } from "../Models/file-details";
import { FormGroup } from "@angular/forms";
import { CurrencyExchangerate } from "../Models/currency-exchangerate";
import { ProductUpdatePODTO } from "../Models/pocreation-detail";
import { Subject, Subscription } from "rxjs";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { LookUpDetailsService } from "../Services/look-up-details.service";
import { ShareDataService } from "../Services/share-data.service";
import { CommonserviceService } from "../Services/commonservice.service";
import { CurrencyExchangerateService } from "../Services/currency-exchangerate.service";
import { FolderpriceService } from "../Services/folderprice.service";
import { TaxMasterDetailsDTO } from '../Models/manual-sales-dto';
export abstract class AirticketDetailsEdit {
  //#region constructor
  constructor(
    public lookupApi: LookUpDetailsService,
    public apiShared: ShareDataService,
    public commonservice: CommonserviceService,
    public apiExRate: CurrencyExchangerateService,
    public objFolderPrice: FolderpriceService,
  ) { }
  //#endregion constructor
  //#region object declaration
  public folderStatus: any;
  lockStatus: any;
  public folderstatus: any = true;
  public subscription: Subscription = new Subscription();
  public buyExRate: number;
  public amount: number;
  public balanceDueAmount: number;
  public defaultDepositPer: number;
  public hcBuy: number;
  public disbaleCurr: boolean;
  public commOn: string;
  opened = false;
  folderExrate: any;
  fldrCurrency: any;
  buycurrency: any;
  public exRate: any;
  public labelchange = false;
  public formeditable = true;
  folderEditMode: any;
  editinputtax: boolean = false;
  prdtUpdatePODTO: ProductUpdatePODTO;
  isShown = false;
  savebutton = true;
  public divValue: any = 1;
  ShowVat: any;
  public fCSellTax: number;
  public TAXRATE: number;
  public FCSellTaxAmt: number;
  public HCSellTaxAmt: number;
  public NV: any = true;
  public VSF: any = false;
  public VP: any = false;
  public VSC: any = false;
  currencyExrate: CurrencyExchangerate;
  ucode: string;
  airticketEditForm: FormGroup;
  submitted = false;
  public rdCheckedCust = false;
  public rdCheckedCmp = false;
  public rdCheckedCr = false;
  isShownCust: any = false;
  isShowncm: any = false;
  isShownCmp: boolean = false;
  loaded = false;
  loadedfile = false;
  isBSP = false;
  public PONO: number;
  pocreated = true;
  fileDetail: FileDetails;
  ticketDate: any;
  ticketingDeadLine: any; //337
  validFrom: any; //337
  validTo: any; //337
  depositDueDate: any; //337
  validators: any;
  isShownMore = false; //337
  FCSell: any = 0.00;
  Tax: Array<LookUpDetails["ResponseData"]> = [];
  public chkVat: any;
  public TaxselectedItem: LookUpDetails["ResponseData"];
  public CommonList: Array<string> = ["Purchase", "Sales"];
  public passengerTypeList: Array<string> = [
    "Adult",
    "Youth",
    "Child",
    "Infant"
  ];
  public reporttypelist: Array<{ text: string; value: number }> = [
    { text: "Summary", value: 0 },
    { text: "Detailed", value: 1 }
  ];
  public ATYPESelected = "TKT";
  public SELFBILLING: string;
  public showMsg: any;
  creditLimiFlag = false;
  FC_Tot_BuyInitalvalue: any = 0.00;
  creditLimitMsg: any;
  msg: any;
  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];

  public supplierSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelected: String;

  public PassengerselectedItem: LookUpDetails["ResponseData"];
  Passenger: Array<LookUpDetails["ResponseData"]> = [];

  public CurrencyselectedItem: LookUpDetails["ResponseData"];
  CurrencyList: Array<LookUpDetails["ResponseData"]> = [];
  public InterIndList: Array<string> = [
    "International",
    "Domestic",
    "Transborder"
  ];
  public interIndListId: any = "";
  BOOKEBYList: Array<LookUpDetails["ResponseData"]> = [];
  public bookbyselectedItem: LookUpDetails["ResponseData"];
  CityList: Array<LookUpDetails["ResponseData"]> = [];
  ArCityList: Array<LookUpDetails["ResponseData"]> = [];
  ArCityFilter: Array<LookUpDetails["ResponseData"]> = [];
  CityListFilter: Array<LookUpDetails["ResponseData"]> = [];
  public cityselectedItem: LookUpDetails["ResponseData"];
  public cityselectedItemTo: LookUpDetails["ResponseData"];
  IATANOList: Array<LookUpDetails["ResponseData"]> = []; //Case id-286
  public IATANoSelectedItem: LookUpDetails["ResponseData"]; //Case id-286
  public cityselected: String;
  public cityselectedTo: String;
  public BSPTAXEnable: any;
  public typelist: Array<string> = [
    "TKT",
    "ADM",
    "MCO",
    "MPD",
    "VMPD",
    "EMD",
    "RTKT"
  ];
  public hcsell: any = false;
  public package = false;
  public manualCalculation = false;
  public disablenet: Boolean;
  public disabledbuy: Boolean;
  public disablecontrol: Boolean = true;
  public projNo: any;
  bsptaxNo: any;
  hcCurrency: any;
  folderexgrate: any = 0.0;
  Hccommn: any = 0.0;
  hcExRate: any;
  public HomeCurrency: string = "GBP";
  GDSList: Array<LookUpDetails["ResponseData"]> = [];
  public GDSselectedItem: LookUpDetails["ResponseData"];
  AIRWAYSList: Array<LookUpDetails["ResponseData"]> = [];
  public AIRWAYSselectedItem: LookUpDetails["ResponseData"];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains"
  };

  public removeVAT = false; //By eldhose for case id-286
  public formSubmitted = false; //By eldhose for case id-286
  public IATACheckingOnFolder: any; //By eldhose for case id-286
  public IATAChecking = false; //By eldhose for case id-286
  public JourneyList: Array<string> = ["Return", "One way"]; //By eldhose for case id-286
  ReasonCodeList: Array<LookUpDetails["ResponseData"]> = []; //By Case id-355
  public ReasonCodeSelectedItem: LookUpDetails["ResponseData"]; //By Case id-355
  currentExRate: any;
  public enableUnusedTicket = false; //By Case id-355

  public PurchaseTaxAmount: number;
  public OtherTaxAmount: number;
  public PurchaseValueAmount: number;
  public dattobechecked: any;
  inputTaxDetails: Array<TaxMasterDetailsDTO> = [];
  outputTaxDetails: Array<TaxMasterDetailsDTO> = [];
  //#endregion object declaration
  //#region load City
  loadarrivalcity() {
    if (
      this.airticketEditForm.controls.AIRTO.value != "" &&
      this.airticketEditForm.controls.AIRTO.value != null
    ) {
      this.subscription.add(
        this.lookupApi
          .SearchCity(this.airticketEditForm.controls.AIRTO.value, "0")
          .subscribe(data => {
            if (
              data != null &&
              data != undefined &&
              data["ResponseData"] != null
            ) {
              this.ArCityList = data["ResponseData"];
              if (this.airticketEditForm.controls.AIRTO.value != "") {
                this.cityselectedItemTo = this.ArCityList.find(
                  item =>
                    item.Code == this.airticketEditForm.controls.AIRTO.value
                );
                if (this.cityselectedItemTo != null)
                  this.cityselectedTo = this.cityselectedItemTo.Name;
              }
            }
          })
      );
    }
  }
  loaddeparturecity() {
    if (
      this.airticketEditForm.controls.AIRFROM.value != "" &&
      this.airticketEditForm.controls.AIRFROM.value != null
    ) {
      this.subscription.add(
        this.lookupApi
          .SearchCity(this.airticketEditForm.controls.AIRFROM.value, "0")
          .subscribe(data => {
            if (
              data != null &&
              data != undefined &&
              data["ResponseData"] != null
            ) {
              this.CityList = data["ResponseData"];

              if (this.airticketEditForm.controls.AIRFROM.value != "") {
                this.cityselectedItem = this.CityList.find(
                  item =>
                    item.Code == this.airticketEditForm.controls.AIRFROM.value
                );
                if (this.cityselectedItem != null)
                  this.cityselected = this.cityselectedItem.Name;
              }
            }
          })
      );
    }
  }
  //#endregion load City
  //#region for taxsplitCalculation
  taxsplitCalculation() {
    debugger;
    this.folderExrate = this.apiShared.folderExchangeRate;

    this.buyExRate =
      this.airticketEditForm.get("EXCHANGE").value == "" ||
        this.airticketEditForm.get("EXCHANGE").value == 0
        ? 1
        : this.airticketEditForm.get("EXCHANGE").value;

    if (
      this.airticketEditForm.controls.CostingVATType.value == "NV" ||
      this.airticketEditForm.controls.CostingVATType.value == "" ||
      this.airticketEditForm.controls.CostingVATType.value == null ||
      this.airticketEditForm.controls.CostingVATType.value == undefined
    ) {
      this.airticketEditForm.controls.CostingCustomerSalesValue.setValue(
        (this.airticketEditForm.controls.FCBuy.value * this.buyExRate) /
        this.folderExrate
      );

      this.airticketEditForm.controls.CostingCustomerSalesValue.setValue(
        parseFloat(
          this.airticketEditForm.controls.CostingCustomerSalesValue.value.toFixed(
            2
          )
        )
      );
      this.airticketEditForm.controls.CostofSale.setValue(
        this.airticketEditForm.controls.CostingCustomerSalesValue.value
      );
      this.airticketEditForm.controls.CostofSale.setValue(
        parseFloat(this.airticketEditForm.controls.CostofSale.value.toFixed(2))
      );

      this.airticketEditForm.controls.CostingCustomerServiceFee.setValue(
        this.airticketEditForm.controls.FCSell.value -
        this.airticketEditForm.controls.CostingCustomerSalesValue.value
      );
      this.airticketEditForm.controls.CostingCustomerServiceFee.setValue(
        parseFloat(
          this.airticketEditForm.controls.CostingCustomerServiceFee.value.toFixed(
            2
          )
        )
      );

      this.airticketEditForm.controls.Markup.setValue(
        this.airticketEditForm.controls.CostingCustomerServiceFee.value
      );
      this.airticketEditForm.controls.Markup.setValue(
        parseFloat(this.airticketEditForm.controls.Markup.value.toFixed(2))
      );

      this.airticketEditForm.controls.CostingSupplierGrossValue.setValue(
        this.airticketEditForm.controls.FCBuy.value
      );

      this.airticketEditForm.controls.CostingSupplierGrossValue.setValue(
        parseFloat(
          this.airticketEditForm.controls.CostingSupplierGrossValue.value.toFixed(
            2
          )
        )
      );
    }
    if (this.airticketEditForm.controls.CostingVATType.value == "VSF") {
      this.airticketEditForm.controls.CostingCustomerSalesValue.setValue(
        (this.airticketEditForm.controls.FCBuy.value * this.buyExRate) /
        this.folderExrate
      );

      this.airticketEditForm.controls.CostingCustomerSalesValue.setValue(
        this.airticketEditForm.controls.FCSell.value -
        this.airticketEditForm.controls.CostingCustomerSalesValue.value
      );
    }
    this.commonservice.buycurrency = this.airticketEditForm.controls.CURR.value;
  }
  //#endregion for taxsplitCalculation
  //#region for LoadSupplier
  public loadSupplier() {
    this.subscription.add(
      this.lookupApi.GetAllLookup("SUPPLIERS").subscribe(data => {
        if (data != null && data != undefined && data["ResponseData"] != null) {
          this.SupplierList = data["ResponseData"];
          //alert("ddd"+this.airticketEditForm.controls.PRTYCODE.value)     ;
          if (this.airticketEditForm.controls.PRTYCODE.value != "") {
            this.supplierSelectedItem = this.SupplierList.find(
              item =>
                item.Code == this.airticketEditForm.controls.PRTYCODE.value
            );
            this.supplierSelected = this.supplierSelectedItem.Name;
            this.SELFBILLING = this.supplierSelectedItem.SELFBILLING;
          }
        }
      })
    );
  }
  //#endregion for LoadSupplier
  //#region for checkcurrency
  checkcurrency() {
    this.fldrCurrency = this.apiShared.folderCurrency;
    if (this.airticketEditForm.controls.CURR.value == this.fldrCurrency) {
      this.commonservice.disablecurrency = false;
    } else {
      var value = this.airticketEditForm.controls.CostingVATType.value;
      this.ShowVat = 1;
      if (value == "NV") {
        this.airticketEditForm.controls.CostofSale.setValue(0);
        this.airticketEditForm.controls.Markup.setValue(0);
        this.airticketEditForm.controls.FCSell.setValue(0);

        //  this.onNoVatChange();
      } else if (value == "VSF") {
        this.airticketEditForm.controls.CostofSale.setValue(0);
        this.airticketEditForm.controls.Markup.setValue(0);
        this.airticketEditForm.controls.CostingCustomerTaxAmount.setValue(0);
        this.airticketEditForm.controls.CostingCustomerNetSellPrice.setValue(0);
      }
      if (value == "VP") {
        this.airticketEditForm.controls.CostofSale.setValue(0);
        this.airticketEditForm.controls.Markup.setValue(0);
        this.airticketEditForm.controls.CostingCustomerTaxAmount.setValue(0);
        this.airticketEditForm.controls.CostingCustomerNetSellPrice.setValue(0);
        this.divValue = 3;
      }
      if (value == "VSC") {
        this.airticketEditForm.controls.CostofSale.setValue(0);
        this.airticketEditForm.controls.Markup.setValue(0);

        this.airticketEditForm.controls.CostingCustomerNetSellPrice.setValue(0);
      }
      this.commonservice.disablecurrency = true;
    }
    this.buycurrency = this.airticketEditForm.controls.CURR.value;
    this.commonservice.buycurrency = this.airticketEditForm.controls.CURR.value;
  }
  checkcurrencyload() {
    this.fldrCurrency = this.apiShared.folderCurrency;
    if (this.airticketEditForm.controls.CURR.value == this.fldrCurrency) {
      this.commonservice.disablecurrency = false;
    } else {
      var value = this.airticketEditForm.controls.CostingVATType.value;
      if (value == "NV") {
        this.commonservice.issuvatonsupcomm = false;
      } else if (value == "VSF") {
        this.commonservice.issuvatonsupcomm = false;
      }
      if (value == "VP") {
        this.commonservice.issuvatonsupcomm = false;
        this.divValue = 3;
      }
      if (value == "VSC") {
        this.commonservice.issuvatonsupcomm = true;
      }
      this.commonservice.disablecurrency = true;
    }
    this.buycurrency = this.airticketEditForm.controls.CURR.value;
    this.commonservice.buycurrency = this.airticketEditForm.controls.CURR.value;
  }
  //#endregion for checkcurrency

  //#region for select div for Vat
  selectdiv(value) {
    this.airticketEditForm.controls.CostingVATType.setValue(value);
    if (value == "NV") {
      this.commonservice.issuvatonsupcomm = false;
      this.divValue = 1;
      this.calculateAirticketDetailsNOVAt(0);
    } else if (value == "VSF") {
      this.divValue = 2;
      this.calculateFormVotService(0,0);
      this.commonservice.issuvatonsupcomm = false;
    }
    if (value == "VP") {
      this.divValue = 3;
      this.calculateFormVotService(0,0);

      this.commonservice.issuvatonsupcomm = false;
    }
    if (value == "VSC") {
      this.commonservice.issuvatonsupcomm = true;
      this.divValue = 4;
      this.calculateAirticketDetailsSuppComm();
    }
  }
  //#endregion for select div for Vat
  //#region Vat Calculation
  calculateAirticketDetailsSuppComm() {
    debugger;
    this.buyExRate =
      this.airticketEditForm.get("EXCHANGE").value == "" ||
        this.airticketEditForm.get("EXCHANGE").value == 0
        ? 1
        : this.airticketEditForm.get("EXCHANGE").value;
    if (this.buyExRate == 0) {
      this.buyExRate = 1;
    }
    this.airticketEditForm.controls.FC_Tot_Buy.setValue(
      this.airticketEditForm.controls.FCBuy.value
    );
    this.airticketEditForm.controls.CostofSale.setValue(
      parseFloat(
        (
          ((this.airticketEditForm.controls.CostingSupplierGrossValue.value -
            this.airticketEditForm.controls.CostingSuppComm.value) *
            this.buyExRate) /
          this.folderExrate
        ).toFixed(2)
      )
    );
    this.airticketEditForm.controls.CostingCustomerSalesValue.setValue(
      this.airticketEditForm.controls.CostofSale.value
    );
    this.airticketEditForm.controls.CostingCustomerServiceFee.setValue(
      this.airticketEditForm.controls.Markup.value
    );
    this.airticketEditForm.controls.CostingCustomerNetSellPrice.setValue(
      this.airticketEditForm.controls.CostofSale.value +
      this.airticketEditForm.controls.Markup.value
    );
    this.airticketEditForm.controls.FCBuy.setValue(
      this.airticketEditForm.controls.CostofSale.value
    );
    this.airticketEditForm.controls.FCSell.setValue(
      this.airticketEditForm.controls.CostingCustomerNetSellPrice.value
    );
    this.calculatetxcommdiv4();
    this.calculateAirticket(0);
  }
  calculateFormVotService(value,newmarkup) {
    debugger;
    this.buyExRate =
      this.airticketEditForm.get("EXCHANGE").value == "" ||
        this.airticketEditForm.get("EXCHANGE").value == 0
        ? 1
        : this.airticketEditForm.get("EXCHANGE").value;
    if (this.buyExRate == 0) {
      this.buyExRate = 1;
    }

    if (this.divValue != 3 && this.divValue != 4)
      this.airticketEditForm.controls.CostofSale.setValue(
        parseFloat(
          (
            ((this.airticketEditForm.controls.CostingSupplierGrossValue.value +
              this.airticketEditForm.controls.CostingSupplierServiceFee.value) *
              this.buyExRate) /
            this.folderExrate
          ).toFixed(2)
        )
      );
    if (this.divValue == 3)
    
    this.airticketEditForm.controls.CostingSupplierGrossValue.setValue(
      parseFloat(
        (
          (this.airticketEditForm.controls.CostingSupplierNetBuyPrice.value -
            this.airticketEditForm.controls.CostingSupplierTaxAmount.value
        ).toFixed(2)
      ))
    );
      this.airticketEditForm.controls.CostofSale.setValue(
        parseFloat(
          (
            (this.airticketEditForm.controls.CostingSupplierGrossValue.value *
              this.buyExRate) /
            this.folderExrate
          ).toFixed(2)
        )
      );
    if (value != 101) {
      this.calculateAirticketdetailsVotserviceOupttax(0);
    } else {
      if (this.divValue != 3 && this.divValue != 4)
        this.outputTaxSplit(this.divValue);
      // this.airticketEditForm.controls.CostingCustomerTaxAmount.setValue(
      //   parseFloat(
      //     (
      //       (this.airticketEditForm.controls.Markup.value *
      //         this.airticketEditForm.controls.CostingTaxRate.value) /
      //       100
      //     ).toFixed(2)
      //   )
      // );
      else if (this.divValue == 3) {
        let selamt = (this.airticketEditForm.controls.Markup.value +
          this.airticketEditForm.controls.CostofSale.value)
          if(newmarkup==0 && (this.airticketEditForm.controls.GUIDItinerary.value == null ||this.airticketEditForm.controls.GUIDItinerary.value == '' ))
        this.outputTaxSplit(this.divValue);
      }
      // this.airticketEditForm.controls.CostingCustomerTaxAmount.setValue(
      //   parseFloat(
      //     (
      //       ((this.airticketEditForm.controls.Markup.value +
      //         this.airticketEditForm.controls.CostofSale.value) *
      //         this.airticketEditForm.controls.CostingTaxRate.value) /
      //       100
      //     ).toFixed(2)
      //   )
      // );
    }

    this.calculateinputTax(value,newmarkup);
    this.calculateAirticket(newmarkup);
    this.getCommonData();
  }
  calculateAirticketDetailsNOVAt(newmarkup:any) {
    if (this.PONO == null || this.PONO == undefined) {
      this.PONO = 0;
    }
    debugger;
    this.fCSellTax = +"0";
    this.TAXRATE = +"0";
    var diff = 0.00;
    if ((this.airticketEditForm.controls.GUIDItinerary.value != null && this.airticketEditForm.controls.GUIDItinerary.value != '') && this.commonservice.package) {
      diff = this.FC_Tot_BuyInitalvalue - this.airticketEditForm.controls.FCBuy.value;
      this.airticketEditForm.controls.FCCommAmt.setValue(parseFloat(diff.toFixed(2)));;

    }
    this.airticketEditForm.controls.FC_Tot_Buy.setValue(
      this.airticketEditForm.controls.FCBuy.value
    );
    if (this.TAXRATE == 0) {
      if (this.airticketEditForm.get("HC_SELLTAXAMT") != null)
        this.airticketEditForm.controls.HC_SELLTAXAMT.setValue(
          parseFloat((0).toFixed(2))
        );
      else
        this.airticketEditForm.controls.SELLVAT.setValue(
          parseFloat((0).toFixed(2))
        );
    }
    if (
      this.airticketEditForm.controls.FCBuy.value == undefined ||
      this.airticketEditForm.controls.FCBuy.value == null ||
      this.airticketEditForm.controls.FCBuy.value == ""
    ) {
      this.airticketEditForm.controls.FCBuy.setValue(0);
      this.airticketEditForm.controls.CostofSale.setValue(0);
    } else {
      this.airticketEditForm.controls.CostofSale.setValue(
        parseFloat(
          (
            (this.airticketEditForm.controls.FCBuy.value *
              this.airticketEditForm.controls.EXCHANGE.value) /
            this.folderExrate
          ).toFixed(2)
        )
      );
    }
debugger;
    if ((this.airticketEditForm.controls.GUIDItinerary.value ==''||this.airticketEditForm.controls.GUIDItinerary.value ==null) && this.commonservice.package && this.objFolderPrice.FCTotalCOMM != 0) {
      this.airticketEditForm.controls.FCSell.setValue(+0);
      this.airticketEditForm.controls.FCCommAmt.setValue(-this.airticketEditForm.controls.CostingSupplierGrossValue.value);
    }
    if (!this.commonservice.package && (this.hcsell == false || this.PONO > 0) &&(this.airticketEditForm.controls.GUIDItinerary.value ==''||this.airticketEditForm.controls.GUIDItinerary.value ==null)) {
      this.airticketEditForm.controls.FCSell.setValue(
        +this.airticketEditForm.controls.CostofSale.value +
        +this.airticketEditForm.controls.Markup.value
      );
    }
    else if ((this.airticketEditForm.controls.GUIDItinerary.value !=null && this.airticketEditForm.controls.GUIDItinerary.value !='')&& this.commonservice.package) {
      this.airticketEditForm.controls.FCSell.setValue(+this.FCSell);
    }
    else if (+this.objFolderPrice.FCTotalCOMM == 0 &&( this.commonservice.package &&(this.airticketEditForm.controls.GUIDItinerary.value ==''||this.airticketEditForm.controls.GUIDItinerary.value ==null) )) {
      this.airticketEditForm.controls.FCSell.setValue(
        +this.airticketEditForm.controls.CostofSale.value +
        +this.airticketEditForm.controls.Markup.value
      );
    }
     if (newmarkup==1 && (this.airticketEditForm.controls.GUIDItinerary.value !=null && this.airticketEditForm.controls.GUIDItinerary.value !=''))
      {
        this.airticketEditForm.controls.Markup.setValue(this.airticketEditForm.controls.FCSell.value - this.airticketEditForm.controls.CostofSale.value);
      if(this.airticketEditForm.controls.CostingCustomerServiceFee!=undefined)
        this.airticketEditForm.controls.CostingCustomerServiceFee.setValue (this.airticketEditForm.controls.Markup.value);
      }
  if(newmarkup==2)
  {
    this.airticketEditForm.controls.FCSell.setValue(
      +this.airticketEditForm.controls.CostofSale.value +
      +this.airticketEditForm.controls.Markup.value
    );
  }
    this.airticketEditForm.controls.CostingSupplierGrossValue.setValue(
      this.airticketEditForm.controls.FCBuy.value
    );
    this.airticketEditForm.controls.CostingCustomerSalesValue.setValue(
      this.airticketEditForm.controls.CostofSale.value
    );

    this.airticketEditForm.controls.CostingCustomerServiceFee.setValue(
      this.airticketEditForm.controls.Markup.value
    );
    this.airticketEditForm.controls.CostingCustomerNetSellPrice.setValue(
      this.airticketEditForm.controls.FCSell.value
    );

    this.calculateAirticket(newmarkup);
  }
  calculateAirticket(newmarkup:any) {
    debugger;
    this.buyExRate =
      this.airticketEditForm.get("EXCHANGE").value == "" ||
        this.airticketEditForm.get("EXCHANGE").value == 0
        ? 1
        : this.airticketEditForm.get("EXCHANGE").value;
    if (this.buyExRate == 0) {
      this.buyExRate = 1;
    }
    if (
      this.airticketEditForm.get("TaxExcludingSalesTax").value !== 0 &&
      this.airticketEditForm.get("TaxExcludingSalesTax").value !== null
    ) {
      this.airticketEditForm.controls.BaseFare.setValue(
        parseFloat(
          (
            this.airticketEditForm.get("FCSell").value -
            this.airticketEditForm.get("TaxExcludingSalesTax").value
          ).toFixed(2)
        )
      );
    } else {
      this.airticketEditForm.controls.BaseFare.setValue(
        parseFloat(this.airticketEditForm.get("FCSell").value).toFixed(2)
      );
    }
    if (this.airticketEditForm.controls.CostingVATType.value == "NV") {
      this.airticketEditForm.controls.PAYABLE.setValue(
        parseFloat(
          (
            this.airticketEditForm.controls.FCBuy.value * this.buyExRate
          ).toFixed(2)
        )
      );
    } else if (this.airticketEditForm.controls.CostingVATType.value == "VSF") {
      this.airticketEditForm.controls.PAYABLE.setValue(
        this.airticketEditForm.controls.CostingSupplierGrossValue.value +
        this.airticketEditForm.controls.CostingSupplierServiceFee.value
      );
      this.airticketEditForm.controls.PAYABLE.setValue(
        parseFloat(
          (
            this.airticketEditForm.controls.PAYABLE.value * this.buyExRate
          ).toFixed(2)
        )
      );
    } else if (this.airticketEditForm.controls.CostingVATType.value == "VP") {
      this.airticketEditForm.controls.PAYABLE.setValue(
        this.airticketEditForm.controls.CostingSupplierGrossValue.value
      );
      this.airticketEditForm.controls.PAYABLE.setValue(
        parseFloat(
          (
            this.airticketEditForm.controls.PAYABLE.value * this.buyExRate
          ).toFixed(2)
        )
      );
      this.airticketEditForm.controls.CostofSale.setValue(
        parseFloat(
          (
            (this.airticketEditForm.controls.CostingSupplierGrossValue.value *
              this.buyExRate) /
            this.folderExrate
          ).toFixed(2)
        )
      );
      this.airticketEditForm.controls.CostingCustomerSalesValue.setValue(
        this.airticketEditForm.controls.CostofSale.value
      );
    } else if (this.airticketEditForm.controls.CostingVATType.value == "VSC") {
      this.airticketEditForm.controls.PAYABLE.setValue(
        this.airticketEditForm.controls.CostofSale.value
      );
      this.airticketEditForm.controls.PAYABLE.setValue(
        parseFloat(
          (
            this.airticketEditForm.controls.PAYABLE.value * this.buyExRate
          ).toFixed(2)
        )
      );
    } else {
      this.airticketEditForm.controls.PAYABLE.setValue(
        this.airticketEditForm.get("FC_Tot_Buy").value * this.buyExRate
      );
    }

    this.airticketEditForm.controls.HCSell.setValue(
      this.airticketEditForm.get("FCSell").value * this.exRate
    );
    this.airticketEditForm.controls.HCCommAmt.setValue(
      this.airticketEditForm.get("HCSell").value -
      this.airticketEditForm.get("PAYABLE").value
    );

    //Payment Terms
    this.hcBuy = this.airticketEditForm.get("PAYABLE").value;
    this.amount =
      this.airticketEditForm.get("DEFDEP").value == "" ||
        this.airticketEditForm.get("DEFDEP").value == 0
        ? 0
        : this.airticketEditForm.get("DEFDEP").value;
    this.defaultDepositPer =
      this.airticketEditForm.get("DEFDEPPER").value == "" ||
        this.airticketEditForm.get("DEFDEPPER").value == 0
        ? 0
        : this.airticketEditForm.get("DEFDEPPER").value;

    if (this.defaultDepositPer != 0 && this.defaultDepositPer != 0.0)
      this.amount = (this.hcBuy * this.defaultDepositPer) / 100;

    this.balanceDueAmount = this.hcBuy - this.amount;
    this.airticketEditForm.controls.DEFDEP.setValue(
      parseFloat(this.amount.toFixed(2))
    );
    this.airticketEditForm.controls.BalanceDueAmount.setValue(
      parseFloat(this.balanceDueAmount.toFixed(2))
    );
    // obj.FC_Buy=obj.FC_Buy/this.folderexgrate;
    //Corperate Info

    this.airticketEditForm.controls.ActualSaving.setValue(
      parseFloat((this.airticketEditForm.get("PAYABLE").value * -1).toFixed(2))
    );
    this.airticketEditForm.controls.CostofSale.setValue(
      parseFloat(
        (
          (this.airticketEditForm.controls.CostingSupplierGrossValue.value *
            this.buyExRate) /
          this.folderExrate
        ).toFixed(2)
      )
    );
    if(newmarkup==0 ||(this.airticketEditForm.controls.GUIDItinerary.value == null ||this.airticketEditForm.controls.GUIDItinerary.value == '' ))
    this.airticketEditForm.controls.CostingCustomerNetSellPrice.setValue((this.airticketEditForm.controls.CostofSale.value +this.airticketEditForm.controls.Markup.value+ this.airticketEditForm.controls.CostingCustomerTaxAmount.value));

  }
  calculatetxcommdiv4() {
    this.outputTaxSplit(this.divValue);
    // this.airticketEditForm.controls.GSTHSTOnComm.setValue(
    //   (this.airticketEditForm.controls.CostingSuppComm.value *
    //     this.airticketEditForm.controls.CostingTaxRate.value) /
    //   100
    // );
  }
  calculateinputTax(value,newmarkup:number) {
    debugger;
    var ddd = +this.FCSell;
    if (value != 101) {
      if (this.divValue != 3 && this.divValue != 4) {
        let selamt = (this.airticketEditForm.controls.CostingSupplierServiceFee.value)
        this.TaxSplitCal(this.divValue, selamt);
      }
      // this.airticketEditForm.controls.CostingSupplierTaxAmount.setValue(
      //   parseFloat(
      //     (
      //       (this.airticketEditForm.controls.CostingSupplierServiceFee.value *
      //         this.airticketEditForm.controls.CostingTaxRate.value) /
      //       100
      //     ).toFixed(2)
      //   )
      // );
      else if (this.divValue == 3) {
        let selamt = (this.airticketEditForm.controls.CostingSupplierGrossValue.value)
        this.TaxSplitCal(this.divValue, selamt);
        this.outputTaxSplit(this.divValue);
      }
      // this.airticketEditForm.controls.CostingSupplierTaxAmount.setValue(
      //   parseFloat(
      //     (
      //       (this.airticketEditForm.controls.CostingSupplierGrossValue.value *
      //         this.airticketEditForm.controls.CostingTaxRate.value) /
      //       100
      //     ).toFixed(2)
      //   )
      // );
    }
    if (this.divValue != 3 && this.divValue != 4)
      this.airticketEditForm.controls.CostingSupplierNetBuyPrice.setValue(
        this.airticketEditForm.controls.CostingSupplierTaxAmount.value +
        this.airticketEditForm.controls.CostingSupplierServiceFee.value +
        this.airticketEditForm.controls.CostingSupplierGrossValue.value
      );
    else if (this.divValue == 3)
    this.airticketEditForm.controls.CostingSupplierGrossValue.setValue(
      parseFloat(
        (
          (this.airticketEditForm.controls.CostingSupplierNetBuyPrice.value -
            this.airticketEditForm.controls.CostingSupplierTaxAmount.value
        ).toFixed(2)
      ))
    );
    
    if (this.divValue != 3 && this.divValue != 4)
      this.airticketEditForm.controls.CostingCustomerNetSellPrice.setValue(
        this.airticketEditForm.controls.CostofSale.value +
        this.airticketEditForm.controls.Markup.value +
        this.airticketEditForm.controls.CostingCustomerTaxAmount.value
      );
    if (this.divValue == 3 &&(this.airticketEditForm.controls.GUIDItinerary.value == null ||this.airticketEditForm.controls.GUIDItinerary.value == '' ))
      this.airticketEditForm.controls.CostingCustomerNetSellPrice.setValue(
        this.airticketEditForm.controls.Markup.value +
        this.airticketEditForm.controls.CostingCustomerTaxAmount.value +
        this.airticketEditForm.controls.CostofSale.value
      );
    var diff = 0.00;
    if ((this.airticketEditForm.controls.GUIDItinerary.value != null &&this.airticketEditForm.controls.GUIDItinerary.value != '' ) && this.commonservice.package) {
      diff = this.FC_Tot_BuyInitalvalue - this.airticketEditForm.controls.CostingSupplierGrossValue.value;
      this.airticketEditForm.controls.FCCommAmt.setValue(parseFloat(diff.toFixed(2)));
    }
    else if ((+this.airticketEditForm.controls.GUIDItinerary.value == 0) && this.commonservice.package && this.objFolderPrice.FCTotalCOMM != 0) {
      this.airticketEditForm.controls.FCSell.setValue(+0);
      this.airticketEditForm.controls.FCCommAmt.setValue(-this.airticketEditForm.controls.CostingSupplierGrossValue.value);

    }
    if ((!this.commonservice.package || this.objFolderPrice.FCTotalCOMM == 0)&&(this.airticketEditForm.controls.GUIDItinerary.value == null ||this.airticketEditForm.controls.GUIDItinerary.value == '')) {
      this.airticketEditForm.controls.FCSell.setValue(
        this.airticketEditForm.controls.CostofSale.value +
        this.airticketEditForm.controls.Markup.value
      );
    }
    else if ((this.airticketEditForm.controls.GUIDItinerary.value != null &&this.airticketEditForm.controls.GUIDItinerary.value != '' ) && this.commonservice.package) {
      this.airticketEditForm.controls.FCSell.setValue(+this.FCSell);
    }
    else if ((+this.objFolderPrice.FCTotalCOMM == 0 && this.commonservice.package)&&(this.airticketEditForm.controls.GUIDItinerary.value == null ||this.airticketEditForm.controls.GUIDItinerary.value == '' )) {
      this.airticketEditForm.controls.FCSell.setValue(
        +this.airticketEditForm.controls.CostofSale.value +
        +this.airticketEditForm.controls.Markup.value
      );
    }
    if (this.divValue == 3){
    if (this.airticketEditForm.controls.GUIDItinerary.value == null ||this.airticketEditForm.controls.GUIDItinerary.value == '' ) {
      this.airticketEditForm.controls.FCSell.setValue(this.airticketEditForm.controls.CostofSale.value + this.airticketEditForm.controls.Markup.value);

    }
    else  if (newmarkup==2)
    {
      this.airticketEditForm.controls.FCSell.setValue(this.airticketEditForm.controls.CostofSale.value + this.airticketEditForm.controls.Markup.value);

    }
    else  if ( newmarkup==1)
    {
      this.airticketEditForm.controls.Markup.setValue(this.airticketEditForm.controls.FCSell.value - this.airticketEditForm.controls.CostofSale.value);
      if(this.airticketEditForm.controls.CostingCustomerServiceFee!=undefined)
      this.airticketEditForm.controls.CostingCustomerServiceFee.setValue (this.airticketEditForm.controls.Markup.value);

    }
  }
    //this.airticketEditForm.controls.Tax.setValue( this.airticketEditForm.controls.CostingTaxRate.value);
    //this.airticketEditForm.controls.Tax.setValue( this.airticketEditForm.controls.CostingCustomerTaxAmount.value);
    this.airticketEditForm.controls.CostingCustomerServiceFee.setValue(
      this.airticketEditForm.controls.Markup.value
    );
    debugger;
    this.airticketEditForm.controls.CostingCustomerSalesValue.setValue(
      this.airticketEditForm.controls.CostofSale.value
    );
    if (this.divValue == 2)
      this.airticketEditForm.controls.FCBuy.setValue(
        this.airticketEditForm.controls.CostingSupplierGrossValue.value +
        this.airticketEditForm.controls.CostingSupplierServiceFee.value
      );
    else if (this.divValue == 3)
      this.airticketEditForm.controls.FCBuy.setValue(
        this.airticketEditForm.controls.CostingSupplierGrossValue.value
      );
  }

  calculateAirticketdetailsVotserviceOupttax(value) {
    if (value != 101) {
      if (this.divValue != 3 && this.divValue != 4)
        // this.airticketEditForm.controls.CostingCustomerTaxAmount.setValue(
        //   parseFloat(
        //     (
        //       (this.airticketEditForm.controls.Markup.value *
        //         this.airticketEditForm.controls.CostingTaxRate.value) /
        //       100
        //     ).toFixed(2)
        //   )
        // );
        this.outputTaxSplit(this.divValue);
      else if (this.divValue == 3)
        // this.airticketEditForm.controls.CostingCustomerTaxAmount.setValue(
        //   parseFloat(
        //     (
        //       ((this.airticketEditForm.controls.Markup.value +
        //         this.airticketEditForm.controls.CostofSale.value) *
        //         this.airticketEditForm.controls.CostingTaxRate.value) /
        //       100
        //     ).toFixed(2)
        //   )
        // );
        this.outputTaxSplit(this.divValue);

    } else {
      if (this.divValue == 2)
        this.airticketEditForm.controls.CostingCustomerNetSellPrice.setValue(
          this.airticketEditForm.controls.Markup.value +
          this.airticketEditForm.controls.CostofSale.value +
          this.airticketEditForm.controls.CostingCustomerTaxAmount.value
        );
    }
    if (value != 101) {
      this.airticketEditForm.controls.CostingCustomerServiceFee.setValue(
        this.airticketEditForm.controls.Markup.value
      );

      this.airticketEditForm.controls.CostingCustomerSalesValue.setValue(
        this.airticketEditForm.controls.CostofSale.value
      );
    }
  }
  calculateAirticketDetailsNOVAtSell(hcsell: any,newmarkup:any) {
    debugger;//r
    if(newmarkup==3 && (this.airticketEditForm.controls.GUIDItinerary.value == null ||this.airticketEditForm.controls.GUIDItinerary.value == '' ))
    {
      this.airticketEditForm.controls.FCSell.setValue(
        parseFloat(
          (
            this.airticketEditForm.controls.FCBuy.value).toFixed(2)
        )
      );
    }
    if (hcsell == true) {

      this.airticketEditForm.controls.Markup.setValue(
        parseFloat(
          (
            this.airticketEditForm.controls.FCSell.value -
            this.airticketEditForm.controls.CostofSale.value
          ).toFixed(2)
        )
      );
    }
    this.hcsell = hcsell;
    if (this.airticketEditForm.controls.CostingSupplierGrossValue.value == 0) {
      this.airticketEditForm.controls.CostingSupplierGrossValue.setValue(this.airticketEditForm.controls.FCBuy.value);
    }
    this.calculateAirticketDetailsNOVAt(newmarkup);
  }
  calculateFormVotServiceManualcalculation(value:any,newmarkup:any) {
    debugger;
    this.editinputtax = true;
    this.buyExRate =
      this.airticketEditForm.get("EXCHANGE").value == "" ||
        this.airticketEditForm.get("EXCHANGE").value == 0
        ? 1
        : this.airticketEditForm.get("EXCHANGE").value;
    if (this.buyExRate == 0) {
      this.buyExRate = 1;
    }
    if (this.divValue != 3 && this.divValue != 4)
      this.airticketEditForm.controls.CostofSale.setValue(
        parseFloat(
          (
            ((this.airticketEditForm.controls.CostingSupplierGrossValue.value +
              this.airticketEditForm.controls.CostingSupplierServiceFee.value) *
              this.buyExRate) /
            this.folderExrate
          ).toFixed(2)
        )
      );
    if (this.divValue == 3)
    {
      this.airticketEditForm.controls.CostofSale.setValue(
        parseFloat(
          (
            (this.airticketEditForm.controls.CostingSupplierGrossValue.value *
              this.buyExRate) /
            this.folderExrate
          ).toFixed(2)
        )
      );

    }
     

    this.calculateAirticketdetailsVotserviceOupttax(value);

    this.calculateinputTax(value,newmarkup);
    this.calculateAirticket(newmarkup);
  }

  getCommonData() {
    debugger;
    if (this.airticketEditForm.controls.CostingVATType.value == "VSF") {
      // this.airticketEditForm.controls.SELLVAT.setValue(parseFloat(( this.airticketEditForm.controls.CostingCustomerTaxAmount.value));
      this.airticketEditForm.controls.SELLVAT.setValue(
        parseFloat(
          this.airticketEditForm.controls.CostingCustomerTaxAmount.value.toFixed(
            2
          )
        )
      );

      // this.airticketEditForm.controls.BSPPurchaseTax.setValue(parseFloat(( this.airticketEditForm.controls.CostingSupplierTaxAmount.value));
      this.airticketEditForm.controls.BSPPurchaseTax.setValue(
        parseFloat(
          this.airticketEditForm.controls.CostingSupplierTaxAmount.value.toFixed(
            2
          )
        )
      );

      this.airticketEditForm.controls.BSPPurchaseTaxRate.setValue(
        this.airticketEditForm.controls.CostingTaxRate.value
      );
      var diff = 0.00;
      if (+this.airticketEditForm.controls.GUIDItinerary.value != 0 && this.commonservice.package) {
        diff = this.FC_Tot_BuyInitalvalue - this.airticketEditForm.controls.CostingSupplierGrossValue.value;
        this.airticketEditForm.controls.FCCommAmt.setValue(parseFloat(diff.toFixed(2)));;

      }
      else if ((+this.airticketEditForm.controls.GUIDItinerary.value == 0) && this.commonservice.package && this.objFolderPrice.FCTotalCOMM != 0) {
        this.airticketEditForm.controls.FCSell.setValue(+0);
        this.airticketEditForm.controls.FCCommAmt.setValue(-this.airticketEditForm.controls.CostingSupplierGrossValue.value);
      }
      if (!this.commonservice.package || this.objFolderPrice.FCTotalCOMM == 0) {
        this.airticketEditForm.controls.FCSell.setValue(
          parseFloat(
            (
              this.airticketEditForm.controls.CostofSale.value +
              this.airticketEditForm.controls.Markup.value
            ).toFixed(2)
          )
        );
      }
      else if (+this.airticketEditForm.controls.GUIDItinerary.value != 0 && this.commonservice.package) {
        this.airticketEditForm.controls.FCSell.setValue(+this.FCSell);
      }
      else if (+this.objFolderPrice.FCTotalCOMM == 0 && this.commonservice.package) {
        this.airticketEditForm.controls.FCSell.setValue(
          +this.airticketEditForm.controls.CostofSale.value +
          +this.airticketEditForm.controls.Markup.value
        );
      }
      this.airticketEditForm.controls.FCBuy.setValue(
        parseFloat(
          (
            this.airticketEditForm.controls.CostingSupplierGrossValue.value +
            this.airticketEditForm.controls.CostingSupplierServiceFee.value
          ).toFixed(2)
        )
      );


      // this.airticketEditForm.controls.GSTHSTOnComm.setValue( 0);
      this.airticketEditForm.controls.CostingSuppComm.setValue(0);
      this.airticketEditForm.controls.FC_Tot_Buy.setValue(
        this.airticketEditForm.controls.FCBuy.value
      );
    } else if (this.airticketEditForm.controls.CostingVATType.value == "VP") {
      this.airticketEditForm.controls.SELLVAT.setValue(
        parseFloat(
          this.airticketEditForm.controls.CostingCustomerTaxAmount.value.toFixed(
            2
          )
        )
      );
      this.airticketEditForm.controls.BSPPurchaseTax.setValue(
        parseFloat(
          this.airticketEditForm.controls.CostingSupplierTaxAmount.value.toFixed(
            2
          )
        )
      );
      this.airticketEditForm.controls.BSPPurchaseTaxRate.setValue(
        this.airticketEditForm.controls.CostingTaxRate.value
      );
      this.airticketEditForm.controls.FCBuy.setValue(
        parseFloat(
          this.airticketEditForm.controls.CostingSupplierGrossValue.value.toFixed(
            2
          )
        )
      );
      var diff = 0.00;
      if (+this.airticketEditForm.controls.GUIDItinerary.value != 0 && this.commonservice.package) {
        diff = this.FC_Tot_BuyInitalvalue - this.airticketEditForm.controls.CostingSupplierGrossValue.value;
        this.airticketEditForm.controls.FCCommAmt.setValue(parseFloat(diff.toFixed(2)));;

      }
      else if ((+this.airticketEditForm.controls.GUIDItinerary.value == 0) && this.commonservice.package && this.objFolderPrice.FCTotalCOMM != 0) {
        this.airticketEditForm.controls.FCSell.setValue(+0);
        this.airticketEditForm.controls.FCCommAmt.setValue(-this.airticketEditForm.controls.CostingSupplierGrossValue.value);

      }
      if ((!this.commonservice.package || this.objFolderPrice.FCTotalCOMM == 0) &&(this.airticketEditForm.controls.GUIDItinerary.value == null||this.airticketEditForm.controls.GUIDItinerary.value == '' ) ) {
        this.airticketEditForm.controls.FCSell.setValue(
          parseFloat(
            (
              this.airticketEditForm.controls.CostofSale.value +
              this.airticketEditForm.controls.Markup.value
            ).toFixed(2)
          )
        );
      }
      else if ((this.airticketEditForm.controls.GUIDItinerary.value != null&&this.airticketEditForm.controls.GUIDItinerary.value != '' )  && this.commonservice.package) {
        this.airticketEditForm.controls.FCSell.setValue(+this.FCSell);
      }
      else if ((+this.objFolderPrice.FCTotalCOMM == 0 && this.commonservice.package)&&(this.airticketEditForm.controls.GUIDItinerary.value == null||this.airticketEditForm.controls.GUIDItinerary.value == '' ) ) {
        this.airticketEditForm.controls.FCSell.setValue(
          +this.airticketEditForm.controls.CostofSale.value +
          +this.airticketEditForm.controls.Markup.value
        );
      }
      //  this.airticketEditForm.controls.GSTHSTOnComm.setValue( 0);
      this.airticketEditForm.controls.CostingSuppComm.setValue(0);
      this.airticketEditForm.controls.CostingSupplierServiceFee.setValue(0);
      this.airticketEditForm.controls.FC_Tot_Buy.setValue(
        this.airticketEditForm.controls.FCBuy.value
      );
    } else if (this.airticketEditForm.controls.CostingVATType.value == "VSC") {
      this.airticketEditForm.controls.SELLVAT.setValue(0);
      this.airticketEditForm.controls.BSPPurchaseTax.setValue(0);
      this.airticketEditForm.controls.BSPPurchaseTaxRate.setValue(
        this.airticketEditForm.controls.CostingTaxRate.value
      );
      this.airticketEditForm.controls.FCBuy.setValue(
        parseFloat(this.airticketEditForm.controls.CostofSale.value.toFixed(2))
      );
      var diff = 0.00;
      if (+this.airticketEditForm.controls.GUIDItinerary.value != 0 && this.commonservice.package) {
        diff = this.FC_Tot_BuyInitalvalue - this.airticketEditForm.controls.CostingSupplierGrossValue.value;
        this.airticketEditForm.controls.FCCommAmt.setValue(parseFloat(diff.toFixed(2)));;

      }
      else if ((+this.airticketEditForm.controls.GUIDItinerary.value == 0) && this.commonservice.package && this.objFolderPrice.FCTotalCOMM != 0) {
        this.airticketEditForm.controls.FCSell.setValue(+0);
        this.airticketEditForm.controls.FCCommAmt.setValue(-this.airticketEditForm.controls.CostingSupplierGrossValue.value);

      }
      if (!this.commonservice.package || this.objFolderPrice.FCTotalCOMM == 0) {
        this.airticketEditForm.controls.FCSell.setValue(
          parseFloat(
            this.airticketEditForm.controls.CostingCustomerNetSellPrice.value.toFixed(
              2
            )
          )
        );
      }
      else if (+this.airticketEditForm.controls.GUIDItinerary.value != 0 && this.commonservice.package) {
        this.airticketEditForm.controls.FCSell.setValue(+this.FCSell);
      }
      else if (+this.objFolderPrice.FCTotalCOMM == 0 && this.commonservice.package) {
        this.airticketEditForm.controls.FCSell.setValue(
          +this.airticketEditForm.controls.CostofSale.value +
          +this.airticketEditForm.controls.Markup.value
        );
      }
      this.airticketEditForm.controls.CostingCustomerTaxAmount.setValue(0);

      this.airticketEditForm.controls.CostingSupplierNetBuyPrice.setValue(0);
      this.airticketEditForm.controls.CostingSupplierTaxAmount.setValue(0);
      this.airticketEditForm.controls.CostingSupplierServiceFee.setValue(0);
      this.airticketEditForm.controls.FC_Tot_Buy.setValue(
        this.airticketEditForm.controls.FCBuy.value
      );
    } else {
      this.airticketEditForm.controls.SELLVAT.setValue(0);
      this.airticketEditForm.controls.BSPPurchaseTax.setValue(0);
      this.airticketEditForm.controls.BSPPurchaseTaxRate.setValue(0);
      //  this.airticketEditForm.controls.GSTHSTOnComm.setValue( 0);
      this.airticketEditForm.controls.CostingSuppComm.setValue(0);
      this.airticketEditForm.controls.CostingCustomerTaxAmount.setValue(0);
      this.airticketEditForm.controls.CostingSupplierNetBuyPrice.setValue(0);
      this.airticketEditForm.controls.CostingSupplierTaxAmount.setValue(0);
      this.airticketEditForm.controls.CostingSupplierServiceFee.setValue(0);
      this.airticketEditForm.controls.FC_Tot_Buy.setValue(
        this.airticketEditForm.controls.FCBuy.value
      );
    }
  }
  //#endregion Vat Calculation

  //#region for disable/Enabe BspAndNonBSP
  disableEnabeBspAndNonBSP() {
    debugger;
    if (this.airticketEditForm.get("BSP").value == "1") {
      this.isBSP = true;
      this.disbaleCurr = false;
      //  this.ShowVat = 1;
      // this.airticketEditForm.get('EXCHANGE').disable();
      this.airticketEditForm.controls.CommOn.setValue("Sales");
      this.commOn = "Sales";
    } else {
      this.isBSP = false;
      this.disbaleCurr = false;
      // this.airticketEditForm.get('EXCHANGE').enable();
      this.airticketEditForm.controls.CommOn.setValue("Purchase");
      this.commOn = "Purchase";
    }
  }
  //#endregion for disable/Enabe BspAndNonBSP


  TaxSplitCal(baseval, selVat) {
    if (this.inputTaxDetails != undefined && this.inputTaxDetails != null && this.inputTaxDetails.length > 0) if (this.inputTaxDetails != undefined && this.inputTaxDetails != null && this.inputTaxDetails.length > 0) {

      let TaxCategoryval = this.inputTaxDetails.find(x => x.TaxCategory.toLowerCase() == "base");
      if (TaxCategoryval != undefined && TaxCategoryval != null) {
        TaxCategoryval.TaxAmount = selVat;
        if (this.airticketEditForm.controls.CostingSupplierServiceFee.value != undefined) {
          this.inputTaxDetails.forEach(element => {
            let level = element.Level;
            let TaxAmount = element.TaxAmount;
            this.inputTaxDetails.forEach(element1 => {
              if (element1.FromLevel == level) {
                element1.TaxAmount = (TaxAmount * element1.TaxRate) / 100;
                element1.TaxAmount = +element1.TaxAmount.toFixed(2);
              }
            });

          });

          let sum = this.inputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes').reduce((sum, current) => sum + current.TaxAmount, 0);
          if (baseval == 4)
            this.airticketEditForm.controls.GSTHSTOnComm.setValue(sum);
          //(parseFloat(((this.parentForm.controls.CostingSuppComm.value * this.parentForm.controls.CostingTaxRate.value) / 100).toFixed(2)));
          else
            this.airticketEditForm.controls.CostingSupplierTaxAmount.setValue(sum)
          var TaxPer = this.inputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes' && x.TaxCategory.toLowerCase() != 'cess').reduce((sum, current) => sum + current.TaxRate, 0);
          this.airticketEditForm.controls.SELLTAXRATE.setValue(TaxPer);
          this.airticketEditForm.controls.CostingTaxRate.setValue(TaxPer);
        }

      }
    }
  }

  outputTaxSplit(val) {
    debugger;
    if (this.outputTaxDetails != undefined && this.outputTaxDetails != null && this.outputTaxDetails.length > 0) {

      let TaxCategoryval = this.outputTaxDetails.find(x => x.TaxCategory.toLowerCase() == "base");
      if (TaxCategoryval != undefined && TaxCategoryval != null) {



        if (val == 3)
          TaxCategoryval.TaxAmount = this.airticketEditForm.controls.Markup.value + this.airticketEditForm.controls.CostofSale.value;
        else if (val == 4)
          TaxCategoryval.TaxAmount = this.airticketEditForm.controls.CostingSuppComm.value;

        else
          TaxCategoryval.TaxAmount = this.airticketEditForm.controls.Markup.value;



        if (TaxCategoryval.TaxAmount != undefined) {
          this.outputTaxDetails.forEach(element => {
            let level = element.Level;
            let TaxAmount = element.TaxAmount;
            this.outputTaxDetails.forEach(element1 => {
              if (element1.FromLevel == level) {
                element1.TaxAmount = (TaxAmount * element1.TaxRate) / 100;
                element1.TaxAmount = +element1.TaxAmount.toFixed(2);
              }
            });

          });
          if (val != 4)
            this.airticketEditForm.controls.CostingCustomerTaxAmount.setValue(

              this.outputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes').reduce((sum, current) => sum + current.TaxAmount, 0)
            )
          else
            this.airticketEditForm.controls.GSTHSTOnComm.setValue(

              this.outputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes').reduce((sum, current) => sum + current.TaxAmount, 0)
            )
          var TaxPer = this.outputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes' && x.TaxCategory.toLowerCase() != 'cess').reduce((sum, current) => sum + current.TaxRate, 0);
          this.airticketEditForm.controls.SELLTAXRATE.setValue(TaxPer);

        }
      }
    }
  }
}
