export class FlightBookingDetailsDTO {
    CreationFromdate: any;
    CreationTodate: any;
    TravelFromdate: any;
    TravelTodate: any;
    Airline: any;
    Supplier: any;
    SupplierName: any;
    Destination: any;
    passQry: any;
    isIncluded: boolean = true;
    MCMultipleSelect: boolean = false;
    DestinationList: any;
    DestinationCodeSelectedItem: any;
    DestinationCode: any;
    ReportGenarated: boolean = false;
    ModuleName: any;
    UserId: any;
    DestinationFrom: any;
    AirlineName: any;
    ExportPDFParam: any;
}
export class FlightBookingDetailsResultDTO {
    FolderNo: any;
    OrderNo: any;
    DateOfIssue: any;
    PaxName: any;
    Passport: any;
    DateofBirth: any;
    PassportIssueDate: any;
    PNR: any;
    OutboundTravelDate: any;
    FltNoOutbound: any;
    OutboundOrigin: any;
    OutboundDestination: any;
    OutboundDepTime: any;
    OutboundArrTime: any;
    InboundTravelDate: any;
    FltNoInbound: any;
    InboundOrigin: any;
    InboundDestination: any;
    InboundDepTime: any;
    InboundArrTime: any;
}