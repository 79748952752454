import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Users } from "../../Models/users";
import { Observable, of, throwError, BehaviorSubject } from "rxjs";
import { catchError, tap, map } from "rxjs/operators";
//import * as CryptoJS from 'crypto-js';
import { EnvService } from "../../env.service";
import { Permissionlist } from "../../Models/permissionlist";
import { Usercredetails } from "../Models/usercredetails";
import { Userlogininfo } from "../Models/userlogininfo";
import { Userlogininfodetails } from "../Models/userlogininfodetails";
import { User } from "../../user";
import { promise } from "protractor";
import { Pendoclock } from "../Models/pendoclock";
import { CommonserviceService } from "./commonservice.service";
import { UnlockdocumentService } from "./unlockdocument.service";
import { CRMSearchCriteriaDTO } from "../Models/crmsearch-criteria-dto";
import { CRMSearchService } from "./c-rmsearch.service";
import { SearhdtotransferService } from "./searhdtotransfer.service";
import { FolderSearchCriteriaDTO } from "../Models/folder-search-criteria-dto";
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable({
  providedIn: "root",
})
export class LoginService {
  url: string;
  gridData: any;
  public currentUserSubject: BehaviorSubject<Users>;
  public currentUser: Observable<Users>;
  private currentUserdata: Users;
  public userCredentails: Observable<Usercredetails>;
  public Userlogininfo: any;
  public canacc: any = false;
  constructor(
    private crmSerachService: CRMSearchService,
    private http: HttpClient,
    private env: EnvService,
    private unlockService: UnlockdocumentService,
    private SearhdtotrService: SearhdtotransferService,
    @Inject(LOCAL_STORAGE) private storageService: StorageService,
  ) {
    this.currentUserSubject = new BehaviorSubject<Users>(
      JSON.parse(localStorage.getItem("currentUser"))
    );

    this.currentUser = this.currentUserSubject.asObservable();
    this.url = env.baseUrl;
  }

  public get currentUserValue(): Users {
    return this.currentUserSubject.value;
  }
  GetLogin(userCredentails: any) {
    debugger;
    // var user= CryptoJS.AES.decrypt(Username,"Penguin");
    return this.http.post<Users>(this.url + "GetLogin", userCredentails).pipe(
      map((user) => {

        //alert(JSON.stringify(user));

        //this.GetPermission(user.ResponseData.UID);
        //this.storageService.set('loggedInUser', user);
        return user;
      })
    );
  }
  getLock(
    docType: string,
    docNo: string,
    userId: number,
    projno: string
  ): Observable<Pendoclock> {
    const url =
      `${this.url}` +
      "GetPenDocLockDetails" +
      `/${docType}/${docNo}/${userId}/${projno}/` +
      "PenAirWeb";
    return this.http
      .get<Pendoclock>(url)
      .pipe(tap((_) => console.log(`fetched lock id=${docNo}`)));
  }
  GetAutoLogin(userCredentails: any) {
    //
    // var user= CryptoJS.AES.decrypt(Username,"Penguin");

    return this.http
      .post<Users>(this.url + "GetAutoLogin", userCredentails)
      .pipe(
        map((user) => {
          //alert(JSON.stringify(user));
          console.log(user.ResponseData.Token);
          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);

          //  this.GetPermission(user.ResponseData.UID);
          return user;
        })
      );
  }
  GetLoginforreport(userCredentails: any, token: any) {
    //
    // var user= CryptoJS.AES.decrypt(Username,"Penguin");

    return this.http
      .post<Users>(this.url + "GetLoginforreport", userCredentails)
      .pipe(
        map((user) => {
          user.ResponseData.Token = token;
          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          //   this.GetPermission(userCredentails.UID);
          return user;
        })
      );
  }

  getloginforreport(userid: any, tocken: any) {
    this.currentUserdata = new Users();
    this.currentUserdata.ResponseData.UID = userid;
    this.currentUserdata.ResponseData.Token = tocken;

    localStorage.setItem("currentUser", JSON.stringify(this.currentUserdata));
    this.currentUserSubject.next(this.currentUserdata);
    // this.GetPermission(this.currentUserdata.ResponseData.UID);
    return this.currentUserdata;
  }
  GetpermissionInNewTab() {
    this.currentUserdata = JSON.parse(localStorage.getItem("currentUser"));

    if (this.currentUserdata) {
      //this.GetPermission(this.currentUserdata.ResponseData.UID);
    }
  }

  //  GetPermission(userid:string )
  //  {

  //    this.http.get<Permissionlist>(this.url+'GetPermissionList/'+userid).subscribe(permissionlist => {
  //     setTimeout(()=>{                           //<<<---using ()=> syntax
  //       sessionStorage.setItem('permissiondetailslist', JSON.stringify(permissionlist.ResponseData))
  //  }, 3000);

  //  //   this.createEmployee('27','00');
  //     return
  //   });
  //  }

  public async GetPermissionstatus1(
    userid: string,
    permission: string
  ): Promise<Observable<any>> {
    // var body = {
    //   AutoInvoiceResult: this.griddata
    // };
    var data = await this.http
      .get<any>(
        this.url + "GetPermissionstatus/" + userid + "/" + permission + "/"
      )
      .toPromise();
    return data.ResponseData;
  }
  GetLoginInfoBasedOnUID(UID: number): Observable<Userlogininfo> {
    const url = `${this.url}` + "GetUserLoginInfo" + `/${UID}`;
    return this.http
      .get<Userlogininfo>(url)
      .pipe(tap((_) => console.log(`fetched logininfo=${UID}`)));
  }

  GetLogout() {
    this.crmSerachService.cRMSearchCriteriaDTO = new CRMSearchCriteriaDTO();
    sessionStorage.removeItem("CRMSearchDTO");
    localStorage.removeItem('SrchTranferDTO');
    this.SearhdtotrService.folderSearchCriteriaDto = new FolderSearchCriteriaDTO();
    var messageSource = new BehaviorSubject(this.SearhdtotrService.folderSearchCriteriaDto);
    this.SearhdtotrService.SrchTranferDTO = messageSource.asObservable();
    localStorage.removeItem("currentUser");
    localStorage.removeItem("OrderType");
    sessionStorage.clear();
    this.currentUserSubject.next(null);
    localStorage.clear();
  }
  requestReset(body): Observable<any> {
    return this.http.post<any>(this.url + "ResetPasswordRequest", body).pipe(
      map((user) => {
        return user;
      })
    );
    //return this.http.post(`${this.url}ResetPasswordRequest`, body.emailid,httpOptions).pipe();
  }

  resetPassword(body): Observable<any> {
    let header = new HttpHeaders().set("Authorization", body.resettoken);
    //, {headers:header}
    return this.http.post<any>(this.url + "ResetPassword", body).pipe(
      map((user) => {
        return user;
      })
    );
    //return this.http.post(`${this.url}/ResetPassword`, body, httpOption1);
  }

  ValidPasswordToken(body): Observable<any> {
    // return this.http.post(`${this.url}/valid-password-token`, body);
    return this.http
      .post<any>(this.url + "TokenValidity", body)
      .pipe(tap((res: any) => console.log(`TokenValidity`)));
  }
  AddUserLoginInfo(Userlogininfodetails: any) {
    const url = `${this.url}` + "SaveUserLoginInfo";

    this.http.post(url, Userlogininfodetails, httpOptions).subscribe();
  }

  UpdateUserLoginInfo(Userlogininfodetails: any) {
    const url = `${this.url}` + "SaveUserLoginInfo";
    this.http.post(url, Userlogininfodetails, httpOptions).subscribe((res) => { sessionStorage.removeItem('NewLogin') });
    if (!this.currentUserValue.ResponseData.MultiUserAllowed) {
      this.gridData = [];
      this.unlockService.GetPenDocUnLockDetails().subscribe((res) => {
        if (res != null && res != undefined) {
          debugger;
          for (let result of res.ResponseData) {
            result.DATE = null;
          }
          this.gridData = res.ResponseData.filter(item => (item.USERNAME.toLowerCase() == this.currentUserValue.ResponseData.UNAME.toLowerCase()));
          //this.unlockService.List = this.gridData;
          if (this.gridData.length > 0) {
            this.unlockService.UnlockFolder(this.gridData).subscribe(res => {
              var dda = 1;
            });
          }
        }
      });
    }
  }
  DeleteUserLoginInfo(UID: any) {
    const url = `${this.url}` + 'DeleteUserLoginInfo' + `/${UID}`;
    return this.http.post(url, httpOptions).subscribe();
  }
  licenseRequest(body): Observable<any> {
    return this.http.post<any>(this.url + 'LicenseRequest', body, httpOptions).pipe(
      tap((res: any) => console.log(`LicenseRequest`))
    );
  }
  public async checkLicense(body: any): Promise<Observable<any>> {
    debugger;
    var result = await this.http.post<any>(this.url + 'CheckLicense', body, httpOptions).pipe(
      tap((checkLicense: any) => console.log(`CheckLicense`))
    ).toPromise();
    return result;
  }
  renewLicense(body): Observable<any> {
    return this.http.post<any>(this.url + 'RenewLicense', body).pipe(
      tap((res: any) => console.log(`RenewLicense`))
    );
  }
  CheckSingleUserLogin(body): Observable<any> {
    if (sessionStorage.getItem('NewLogin'))
      body.NewLogin = true;
    else
      body.NewLogin = false;
    return this.http
      .post<any>(this.url + "CheckSingleUserLogin", body)
      .pipe(map((data) => {
        return data.ResponseData;
      }), tap((res: any) => console.log(`TokenValidity`)));

  }
  updateLicenceTermsAgree(UID) {
    const url = `${this.url}` + 'LicenceTermsAgree' + `/${UID}`;
    return this.http.post(url, httpOptions).subscribe();
  }
}
