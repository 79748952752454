import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { PnrsourcefileService } from '../../Services/pnrsourcefile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
import { ShareDataService } from '../../Services/share-data.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
declare var kendo: any;
@Component({
  selector: 'app-folder-notes',
  templateUrl: './folder-notes.component.html',
  styleUrls: ['./folder-notes.component.scss']
})
export class FolderNotesComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  @ViewChild("questionEditor", null) questionEditor: ElementRef;
  data: any;
  NOTESNOTTOBEPRINTED: any;
  NOTES: any;
  NOTESTOPRINT: any;
  USERCODE24:any;
  public orderNo: string = this.route.snapshot.params['orderNo'];
  public content: any;
  foldernotes: FormGroup;//358
  public showMsg: any;//358
  public folderNotesEdit = false;//358
  public messageAddButton = true;//358
  public messageComboBox = true;//358
  public disableNotesToBePrinted = true;//358
  public readonlyvalue = true;
  public DisableFolderNotesSave = false;
  MessageList: Array<LookUpDetails["ResponseData"]> = []; //358
  public MessageSelectedItem: LookUpDetails["ResponseData"];//Case id-358
  lockStatus: any;
  public folderStatus: any;
  public formEditable = true;
  constructor(public common: CommonserviceService, private apiShared: ShareDataService,
    private PnrsourcefileService: PnrsourcefileService, private route: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder, private toastr: ToastrService,
    private datePipe: DatePipe, private lookupApi: LookUpDetailsService,
    private translateapi: TranslateService, private spinner: NgxSpinnerService) { }
  public Componentname: string;
  ngOnInit() {

    this.foldernotes = this.formBuilder.group({

      NOTESNOTTOBEPRINTED: [''],
      NOTES: [''],
      NOTESTOPRINT: [''],
      FOLDERNO: this.route.snapshot.params['orderNo'],
      USERCODE24: [''],
    })
    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderNo !== orderno) {
        return this.router.navigate(['PenAir/Home']);
      }
    this.loadFolderNotesData();//Case id-358
    this.LoadMessageData(); //Case id-358
    this.common.folderEditMode = "true" //Case id-358
    this.apiShared.folderEditMode = this.common.folderEditMode; //Case id-358
    this.apiShared.Setapishareddata(this.common.folderEditMode, 'folderEditMode'); //Case id-358

    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formEditable = true;
    } else {
      this.formEditable = false;
    }
    if (this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund")//By Caseid-12780
    {
      this.formEditable = false;
    }
    this.Componentname = " FolderNotes";
    sessionStorage.setItem("Componentname", this.Componentname);
  }

  onCancel() {
    this.common.folderEditMode = "false"
    this.apiShared.folderEditMode = this.common.folderEditMode;
    this.apiShared.Setapishareddata(this.common.folderEditMode, 'folderEditMode');
    this.common.submenuIndex = 0;
    sessionStorage.setItem('pagevalue', '0')
    this.apiShared.Setapishareddata('0', 'submenuIndex')
    this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo]);
  }
  ngAfterViewInit() {
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  public internalnotesformopened: any;//Caseid-358
  public newNotes: any;//358
  public Username: any;//358
  public currentDate: any;//358
  public currentTime: any;//358
  public messageId: any;//358
  public message: any;//358
  public newMessage: any;//358
  public messageToBePrint: any//358
  public clickAdd()//Caseid-358
  {
    this.internalnotesformopened = true;
  }

  public NotesNotToBePrinted(notes: any)//358
  {
    if (notes != "formClosed") {
      this.Username = this.apiShared.uname;
      var dateAndTime = this.datePipe.transform(new Date(), "dd-MM-yyyy h:mm:ss a");
      this.newNotes = "Notes by " + this.Username + " On " + dateAndTime + "\n" + notes + "\n" + this.foldernotes.controls.NOTESNOTTOBEPRINTED.value;
      this.foldernotes.controls.NOTESNOTTOBEPRINTED.setValue(this.newNotes);
      this.internalnotesformopened = false;
    }
    else {
      this.internalnotesformopened = false;
    }
  }

  public loadFolderNotesData()//358
  { 
    this.subscription.add(this.PnrsourcefileService.GetFolderNotes(this.route.snapshot.params['orderNo']).subscribe(
      // res=>this.data=res.ResponseData

      data => {
        if (data != null && data != undefined) { debugger;
          //  if(data.ResponseData.NOTESNOTTOBEPRINTED!=null && data.ResponseData.NOTESNOTTOBEPRINTED!='')
          //  {
          //   this.NOTESNOTTOBEPRINTED=data.ResponseData.NOTESNOTTOBEPRINTED;--//Commented By Case id-358
          //  }
          //  if(data.ResponseData.NOTES!=null && data.ResponseData.NOTES!='')
          //  {
          //   this.NOTES=data.ResponseData.NOTES;
          //  }
          //  if(data.ResponseData.NOTESTOPRINT!=null && data.ResponseData.NOTESTOPRINT!='')
          //  {
          //   this.NOTESTOPRINT=data.ResponseData.NOTESTOPRINT;
          //  }

          this.foldernotes.setValue({//358
            NOTESNOTTOBEPRINTED: data.ResponseData.NOTESNOTTOBEPRINTED,
            NOTES: data.ResponseData.NOTES,
            NOTESTOPRINT: data.ResponseData.NOTESTOPRINT,
            FOLDERNO: '',
            USERCODE24:data.ResponseData.USERCODE24,
          })
        }
      }
    ));
  }
   convertHtmlToRtf(html) {
    if (!(typeof html === "string" && html)) {
        return null;
    }

    var tmpRichText, hasHyperlinks;
    var richText = html;

    // Singleton tags
    richText = richText.replace(/<(?:hr)(?:\s+[^>]*)?\s*[\/]?>/ig, "{\\pard \\brdrb \\brdrs \\brdrw10 \\brsp20 \\par}\n{\\pard\\par}\n");
    richText = richText.replace(/<(?:br)(?:\s+[^>]*)?\s*[\/]?>/ig, "{\\pard\\par}\n");

    // Empty tags
    richText = richText.replace(/<(?:p|div|section|article)(?:\s+[^>]*)?\s*[\/]>/ig, "{\\pard\\par}\n");
    richText = richText.replace(/<(?:[^>]+)\/>/g, "");

    // Hyperlinks
    richText = richText.replace(
        /<a(?:\s+[^>]*)?(?:\s+href=(["'])(?:javascript:void\(0?\);?|#|return false;?|void\(0?\);?|)\1)(?:\s+[^>]*)?>/ig,
        "{{{\n");
    tmpRichText = richText;
    richText = richText.replace(
        /<a(?:\s+[^>]*)?(?:\s+href=(["'])(.+)\1)(?:\s+[^>]*)?>/ig,
        "{\\field{\\*\\fldinst{HYPERLINK\n \"$2\"\n}}{\\fldrslt{\\ul\\cf1\n");
    hasHyperlinks = richText !== tmpRichText;
    richText = richText.replace(/<a(?:\s+[^>]*)?>/ig, "{{{\n");
    richText = richText.replace(/<\/a(?:\s+[^>]*)?>/ig, "\n}}}");

    // Start tags
    richText = richText.replace(/<(?:b|strong)(?:\s+[^>]*)?>/ig, "{\\b\n");
    richText = richText.replace(/<(?:i|em)(?:\s+[^>]*)?>/ig, "{\\i\n");
    richText = richText.replace(/<(?:u|ins)(?:\s+[^>]*)?>/ig, "{\\ul\n");
    richText = richText.replace(/<(?:strike|del)(?:\s+[^>]*)?>/ig, "{\\strike\n");
    richText = richText.replace(/<sup(?:\s+[^>]*)?>/ig, "{\\super\n");
    richText = richText.replace(/<sub(?:\s+[^>]*)?>/ig, "{\\sub\n");
    richText = richText.replace(/<(?:p|div|section|article)(?:\s+[^>]*)?>/ig, "{\\pard\n");

    // End tags
    richText = richText.replace(/<\/(?:p|div|section|article)(?:\s+[^>]*)?>/ig, "\n\\par}\n");
    richText = richText.replace(/<\/(?:b|strong|i|em|u|ins|strike|del|sup|sub)(?:\s+[^>]*)?>/ig, "\n}");

    // Strip any other remaining HTML tags [but leave their contents]
    richText = richText.replace(/<(?:[^>]+)>/g, "");

    // Prefix and suffix the rich text with the necessary syntax
    richText =
        "{\\rtf1\\ansi\n" + (hasHyperlinks ? "{\\colortbl\n;\n\\red0\\green0\\blue255;\n}\n" : "") + richText +
        "\n}";

    return richText;
}

  public async onFormSubmit()//358
  {
    debugger;
    this.DisableFolderNotesSave = true;
    this.spinner.show();
    this.foldernotes.value.FOLDERNO = this.orderNo;
    
   // this.foldernotes.value.USERCODE24=this.convertHtmlToRtf(this.foldernotes.value.USERCODE24);
    this.PnrsourcefileService.UpdateFolderNotesDetails(this.foldernotes.value).subscribe(res => {
      if (res != null && res != undefined) {
        if (res["ResponseData"].Status == "Success") {
          this.common.folderEditMode = "false"
          this.apiShared.folderEditMode = this.common.folderEditMode;
          this.apiShared.Setapishareddata(this.common.folderEditMode, 'folderEditMode');
          this.showMsg = res["ResponseData"].StatusDetails;
          this.toastr.success(this.showMsg, "",
            {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          this.loadFolderNotesData();//Case id-358
          this.messageAddButton = true;//358
          this.messageComboBox = true;//358
          this.MessageSelectedItem = null;
          this.DisableFolderNotesSave = false;
          this.spinner.hide();
        }
        else {
          this.showMsg = res["ResponseData"].StatusDetails;
          this.toastr.warning(this.showMsg, "",
            {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          this.DisableFolderNotesSave = false;
          this.spinner.hide();
        }
      }
      this.DisableFolderNotesSave = false;
      this.spinner.hide();
    });
  }

  public onEdit() //caseid-358
  {
    this.common.folderEditMode = "true"
    this.apiShared.folderEditMode = this.common.folderEditMode;
    this.apiShared.Setapishareddata(this.common.folderEditMode, 'folderEditMode');
  }

  public LoadMessageData() {  //caseid-358
    this.subscription.add(this.subscription.add(this.lookupApi.GetAllLookup('MESSAGES').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.MessageList = data["ResponseData"];
        }
      }
    )));
  }

  public clickEdit() //caseid-358
  {
    this.messageAddButton = false;
    this.messageComboBox = false;
    this.disableNotesToBePrinted = false;//358
  }

  public clickAddMessage() //caseid-358
  {
    this.message = (this.message == null ? "" : this.message);
    if (this.message == "") {
      this.subscription.add(this.translateapi.get(['Messages.MessageRequired']).subscribe((translations) => {

        this.showMsg = translations['Messages.MessageRequired'];;
        this.toastr.error(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      return;
    }
    else {
      debugger;
      this.subscription.add(this.PnrsourcefileService.GetMessageBasedOnMessageId(this.message)
        .subscribe(data => {
          if (data != null && data != undefined) {
            this.messageToBePrint = data.ResponseData;
            if ((this.foldernotes.controls.USERCODE24.value) == null) {
              this.newMessage = this.messageToBePrint;
            }
            else {
              this.newMessage = this.foldernotes.controls.USERCODE24.value + "\n" + this.messageToBePrint;
            }
            this.foldernotes.controls.USERCODE24.setValue(this.newMessage);
          }
          else {
            this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {
              this.showMsg = translations['Messages.Failed'];
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }));
          }
        }));

    }
  }

  public MessageValueChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.messageId = value.Name;
      this.message = value.UsrCode;
    }

    else {
      this.message = "";
      this.messageId = ""
    }

  }
}
