import { Component, OnInit, Input } from '@angular/core';
import { CustomerspecificfieldService } from '../../Services/customerspecificfield.service';
import { ShareDataService } from '../../Services/share-data.service';
@Component({
  selector: 'app-customerspecific',
  templateUrl: './customerspecific.component.html',
  styleUrls: ['./customerspecific.component.scss']
})
export class CustomerspecificComponent implements OnInit {

  @Input() custcode: any;
  gridData = [];
  public pageSize = 10;
  public skip = 0;
  constructor(private api: CustomerspecificfieldService, private apiShared: ShareDataService) {
  }

  ngOnInit() {
    debugger;
    this.getCustomerSpecificField(this.apiShared.orderNo, this.custcode);
  }
  getCustomerSpecificField(folderno, custCo) {

    if (custCo == "")
      custCo = 0
    this.api.getCustomerSpecificField(folderno, custCo, 1)
      .subscribe(data => {
        this.gridData = data["ResponseData"];

      });

  }
}

