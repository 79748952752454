import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { HotelService } from "../../Services/hotel.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LookUpDetailsService } from "../../Services/look-up-details.service";
import { CurrencyExchangerateService } from "../../Services/currency-exchangerate.service";
import { ShareDataService } from "../../Services/share-data.service";
import { FlashMessagesService } from "angular2-flash-messages";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { CommonserviceService } from "../../Services/commonservice.service";
import { Subscription, Subject } from "rxjs";
import { FileService } from "../../Services/file.service";
import { SupplierMasterService } from "../../Services/supplier-master.service";
import { ToastrService } from "ngx-toastr";
import { FchcdetailseditComponent } from "../fchcdetailsedit/fchcdetailsedit.component";
import { PocreationService } from "../../Services/pocreation.service";
import { ProductUpdatePODTO } from "../../Models/pocreation-detail";
import { HotelDetailsEdit } from "src/app/BusinessLayer/hotel-details-edit";
import { AutoCompleteComponent } from "@progress/kendo-angular-dropdowns";
import { FolderpriceService } from "../../Services/folderprice.service";
import { PanelBarExpandMode } from "@progress/kendo-angular-layout";
@Component({
  selector: "app-hotel-details-edit",
  templateUrl: "./hotel-details-edit.component.html",
  styleUrls: ["./hotel-details-edit.component.scss"]
})
export class HotelDetailsEditComponent extends HotelDetailsEdit
  implements OnInit {
  public subscription: Subscription = new Subscription();
  public statusList: Array<string> = ["Wait listed", "Confirmed", "Cancelled"];
  resetFormSubject: Subject<boolean> = new Subject<boolean>();

  @ViewChild("apppaymenttermsedit", { read: false, static: false }) public payterm: any;
  @ViewChild("appfchcdetailsedit", { read: false, static: false }) public fchcdetails: any;

  @ViewChild("autocompletedeparture")
  public autocompletedeparture: AutoCompleteComponent;
  @ViewChild("autocompletesupplier")
  public autocompletearrival: AutoCompleteComponent;
  @ViewChild("autocompletehotel")
  public autocompletehotel: AutoCompleteComponent;
  lockStatus: any;
  formeditable: any;
  folderStatus: any;
  //#region constructor
  constructor(
    public apiPO: PocreationService,
    public fchdetail: FchcdetailseditComponent,
    public fileservice: FileService,
    public common: CommonserviceService,
    public router: Router,
    public route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public api: HotelService,
    public lookupApi: LookUpDetailsService,
    public apiExRate: CurrencyExchangerateService,
    public apiShared: ShareDataService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public supplier: SupplierMasterService,
    public flashMessage: FlashMessagesService,
    public translateapi: TranslateService,
    private objFolderPrice: FolderpriceService
  ) {
    super(
      fileservice,
      common,
      route,
      api,
      lookupApi,
      apiExRate,
      apiShared,
      spinner,
      supplier
    );
    this.fileservice.getFileDetails().subscribe(x => {
      this.checkin = x.ResponseData.HotelCheckInTime;
      this.checkout = x.ResponseData.HotelCheckOutTime;
    });
  }
  //#endregion constructor
  public expandMode: number = PanelBarExpandMode.Default;

  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;

  public onChange(event: any): void {
    this.expandMode = parseInt(event.target.value, 10);
  }
  public onPanelChange(event: any): void { console.log('stateChange: ', event); }

  // convenience getter for easy access to form fields
  get f() {
    return this.hotelEditForm.controls;
  }
  //#region LoadEvent
  ngOnInit() {
    //case id : 13777 
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    } else {
      this.formeditable = false;

    }
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;

    }
    // case id : 13777
    //#region orderNo difference

    var orderno = sessionStorage.getItem("orderNo");
    if (orderno != null)
      if (this.orderno !== orderno) {
        return this.router.navigate(["PenAir/Home"], { skipLocationChange: true });
      }

    //#endregion orderNo difference
    this.hcExRate = this.apiShared.hcExRate;
    this.homeCurrency = this.apiShared.homeCurrency;
    this.folderEditMode = "true";
    this.apiShared.Setapishareddata(this.folderEditMode, "folderEditMode");
    this.Uname = this.apiShared.uname;
    this.getFolderValues();

    if (this.guid != null) {
      this.getHotelDetails(this.orderno, this.guid);
    }

    this.getFormBuilder();
    if (this.guid == null || this.guid == "") {
      this.days = 0;
      this.FormLoyalityPointsChange();
      this.loaded = true;
      this.spinner.hide();
    }

    this.bindRoomTypeDetails();
    this.bindMealPlanDetails();
    this.bindUserDetails();
    this.onChanges();

    //#region Help File

    this.Componentname = "HotelDetailsEdit";
    sessionStorage.setItem("Componentname", this.Componentname);

    //#endregion Help File

  }


  //#endregion LoadEvent
  public open() {
    this.opened = true;
  }
  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
    this.openSearch = false;

  }
  //#region city filter value change

  handleFilterdeparture(value) {
    value = value.trim(); //By Caseid-12653
    if (value.length >= 3) {
      this.subscription.add(
        this.lookupApi.SearchDestination(value, "1").subscribe(data => {
          if (data != null && data != undefined) {
            this.CityList = data["ResponseData"];
          }
        })
      );
    } else {
      this.autocompletedeparture.toggle(false);
    }
  }

  //#endregion city filter value change

  //#region supplier filter value change

  handleFiltersupplier(value) {
    value = value.trim(); //By Caseid-12653
    if (value.length >= 3) {
      this.subscription.add(
        this.lookupApi.SearchSupplier(value).subscribe(data => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        })
      );
    } else {
      this.autocompletearrival.toggle(false);
    }
  }

  //#endregion supplier filter value change
  //#region hotel filter value change

  handleFilterHotel(value) {
    value = value.trim(); //By Caseid-12653
    if (value.length >= 3) {
      this.subscription.add(
        this.lookupApi.SearchHotel(value).subscribe(data => {
          if (data != null && data != undefined) {
            this.HotelList = data["ResponseData"];
          }
        })
      );
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletehotel.toggle(false);
    }
  }

  //#endregion hotel filter value change

  //#region form submission

  onFormSubmit() {
    debugger;
    this.spinner.show();
    if (this.hotelEditForm.controls.PAYMODE.value == "Company Card") {
      //By case id-355
      if (
        this.hotelEditForm.controls.PDAMOUNT.value == 0 ||
        this.hotelEditForm.controls.PDNO.value == "0" ||
        this.hotelEditForm.controls.PDTYPENO.value == "0"
      ) {
        this.subscription.add(
          this.translateapi
            .get(["Messages.PleaseSelectCompanyCardWithPaymentOption"])
            .subscribe(translations => {
              this.showMsg =
                translations[
                "Messages.PleaseSelectCompanyCardWithPaymentOption"
                ];
              this.toastr.error(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
            })
        );
        this.spinner.hide();
        return;
      }
    }

    this.bookingDate =
      this.hotelEditForm.controls.BookingDate.value == null ||
        this.hotelEditForm.controls.BookingDate.value == ""
        ? "01-Jan-1900"
        : this.hotelEditForm.controls.BookingDate.value; //Case id-337
    this.dateIn =
      this.hotelEditForm.controls.DATEIN.value == null ||
        this.hotelEditForm.controls.DATEIN.value == ""
        ? "01-Jan-1900"
        : this.hotelEditForm.controls.DATEIN.value; //Case id-337
    this.dateOut =
      this.hotelEditForm.controls.DATEOUT.value == null ||
        this.hotelEditForm.controls.DATEOUT.value == ""
        ? "01-Jan-1900"
        : this.hotelEditForm.controls.DATEOUT.value; //Case id-337
    this.depositDueDate =
      this.hotelEditForm.controls.DepositDueDate.value == null ||
        this.hotelEditForm.controls.DepositDueDate.value == ""
        ? "01-Jan-1900"
        : this.hotelEditForm.controls.DepositDueDate.value; //Case id-337
    if (Boolean(this.common.ValidDateRange(this.bookingDate)) == false) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidBookingDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidBookingDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.dateIn)) == false) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidDateIn"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidDateIn"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.dateOut)) == false) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidDateOut"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidDateOut"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }

    if (Boolean(this.common.ValidDateRange(this.depositDueDate)) == false) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidDepositDueDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidDepositDueDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }

    if (
      (this.hotelEditForm.controls.CURCODE.value == undefined &&
        this.hotelEditForm.controls.CURCODE.value == null) ||
      this.hotelEditForm.controls.CURCODE.value == ""
    ) {
      this.hotelEditForm.controls.CURCODE.setValue(this.homeCurrency);
    }
    if (this.hotelEditForm.controls.CostingVATType.value == "VSF") {
      this.hotelEditForm.controls.BSPPurchaseTax.setValue(
        parseFloat(
          this.hotelEditForm.controls.CostingSupplierTaxAmount.value.toFixed(2)
        )
      );
      this.hotelEditForm.controls.BSPPurchaseTaxRate.setValue(
        this.hotelEditForm.controls.CostingTaxRate.value
      );
      this.hotelEditForm.controls.FCBuy.setValue(
        parseFloat(
          (
            this.hotelEditForm.controls.CostingSupplierGrossValue.value +
            this.hotelEditForm.controls.CostingSupplierServiceFee.value
          ).toFixed(2)
        )
      );
      this.hotelEditForm.controls.FCSell.setValue(
        parseFloat(
          (
            this.hotelEditForm.controls.CostofSale.value +
            this.hotelEditForm.controls.Markup.value
          ).toFixed(2)
        )
      );
      this.hotelEditForm.controls.CostingSuppComm.setValue(0);
      this.hotelEditForm.controls.SELLTOTAL.setValue(
        this.hotelEditForm.controls.CostingCustomerNetSellPrice.value
      );
    } else if (this.hotelEditForm.controls.CostingVATType.value == "VP") {
      debugger;
      this.hotelEditForm.controls.BSPPurchaseTax.setValue(
        parseFloat(
          this.hotelEditForm.controls.CostingSupplierTaxAmount.value.toFixed(2)
        )
      );
      this.hotelEditForm.controls.BSPPurchaseTaxRate.setValue(
        this.hotelEditForm.controls.CostingTaxRate.value
      );
      this.hotelEditForm.controls.FCBuy.setValue(
        parseFloat(
          this.hotelEditForm.controls.CostingSupplierGrossValue.value.toFixed(2)
        )
      );
      debugger;
      if(this.hotelEditForm.controls.GUIDItinerary.value == null||this.hotelEditForm.controls.GUIDItinerary.value == '')
      {
      this.hotelEditForm.controls.FCSell.setValue(
        parseFloat(
          (
            this.hotelEditForm.controls.CostofSale.value +
            this.hotelEditForm.controls.Markup.value
          ).toFixed(2)
        )
      );
          }
          this.hotelEditForm.controls.BaseFare.setValue(this.hotelEditForm.controls.FCSell.value);  
      this.hotelEditForm.controls.CostingSuppComm.setValue(0);
      this.hotelEditForm.controls.CostingSupplierServiceFee.setValue(0);
      this.hotelEditForm.controls.SELLTOTAL.setValue(
        this.hotelEditForm.controls.CostingCustomerNetSellPrice.value
      );
    } else if (this.hotelEditForm.controls.CostingVATType.value == "VSC") {
      this.hotelEditForm.controls.SELLVAT.setValue(0);
      this.hotelEditForm.controls.BSPPurchaseTax.setValue(0);
      this.hotelEditForm.controls.BSPPurchaseTaxRate.setValue(
        this.hotelEditForm.controls.CostingTaxRate.value
      );
      this.hotelEditForm.controls.FCBuy.setValue(
        parseFloat(this.hotelEditForm.controls.CostofSale.value.toFixed(2))
      );
      this.hotelEditForm.controls.FCSell.setValue(
        parseFloat(
          this.hotelEditForm.controls.CostingCustomerNetSellPrice.value.toFixed(
            2
          )
        )
      );
      this.hotelEditForm.controls.CostingCustomerTaxAmount.setValue(0);

      this.hotelEditForm.controls.CostingSupplierNetBuyPrice.setValue(0);
      this.hotelEditForm.controls.CostingSupplierTaxAmount.setValue(0);
      this.hotelEditForm.controls.CostingSupplierServiceFee.setValue(0);
      this.hotelEditForm.controls.SELLTOTAL.setValue(
        this.hotelEditForm.controls.CostingCustomerNetSellPrice.value
      );
      this.hotelEditForm.controls.PAYABLE.setValue(this.hotelEditForm.controls.CostingSupplierGrossValue.value - this.hotelEditForm.controls.CostingSuppComm.value);
      this.hotelEditForm.controls.SELLCOMM.setValue(this.hotelEditForm.controls.Markup.value);
      this.hotelEditForm.controls.BalanceDueAmount.setValue(this.hotelEditForm.controls.CostingSupplierGrossValue.value - this.hotelEditForm.controls.CostingSuppComm.value);

    } else {
      this.hotelEditForm.controls.SELLVAT.setValue(0);
      this.hotelEditForm.controls.BSPPurchaseTax.setValue(0);
      this.hotelEditForm.controls.BSPPurchaseTaxRate.setValue(0);
      this.hotelEditForm.controls.CostingSuppComm.setValue(0);
      this.hotelEditForm.controls.CostingCustomerTaxAmount.setValue(0);
      this.hotelEditForm.controls.CostingSupplierNetBuyPrice.setValue(0);
      this.hotelEditForm.controls.CostingSupplierTaxAmount.setValue(0);
      this.hotelEditForm.controls.CostingSupplierServiceFee.setValue(0);
    }
    this.hotelEditForm.controls.FC_SELLTAXAMT.setValue(
      this.hotelEditForm.controls.FC_SELLTAXAMT.value
    );
    if (
      this.hotelEditForm.value["EXRATE"] === "" ||
      this.hotelEditForm.value["EXRATE"] === 0 ||
      this.hotelEditForm.value["EXRATE"] === null
    ) {
      this.subscription.add(
        this.translateapi
          .get(["Messages.ExchangeRateError"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.ExchangeRateError"];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            });
          })
      );
      this.spinner.hide();
      return;
    }
    this.submitted = true;
    if (this.hotelEditForm.invalid) {
      this.spinner.hide();
      return;
    }
    debugger;
    // this.hotelEditForm.controls.SELLTOTAL.setValue(
    //   this.hotelEditForm.controls.SELLAMT.value +
    //   +(this.hotelEditForm.controls.SELLVAT.value)
    // );
    this.objFolderPrice
      .CreditLimitCheck(
        this.guid == null || undefined ? "0" : this.guid,
        0,
        this.orderno,
        this.hotelEditForm.get("SELLAMT").value
      )
      .subscribe(res => {
        console.log(this.guid);
        console.log(this.orderno);
        console.log(this.hotelEditForm.get("SELLAMT").value);
        console.log("CreditLimitCheck");
        console.log(res);
        if (res != undefined || res["ResponseData"] != null) {
          if (
            res["ResponseData"].value != "0" &&
            res["ResponseData"].Status != "0" &&
            res["ResponseData"].ErrorMessage != ""
          ) {
            debugger;
            if (res["ResponseData"].value == 1) {
              this.spinner.hide();
              this.msg = res["ResponseData"].ErrorMessage;
              this.creditLimiFlag = true;
              this.creditLimitMsg = true;
            } else if (res["ResponseData"].value == 2) {
              this.spinner.hide();
              this.msg = res["ResponseData"].ErrorMessage;


              this.creditLimiFlag = false;
              this.creditLimitMsg = true;
              // this.AirTicketSaveAndUpdate();
            } else {
              this.hotelDetailsSaveandUpdate();
            }
          } else {
            this.hotelDetailsSaveandUpdate();
          }
        }
      });
  }

  hotelDetailsSaveandUpdate() {
    if (this.guid != null && this.PONO > 0) {
      this.prdtUpdatePODTO = new ProductUpdatePODTO();
      this.prdtUpdatePODTO.FOLDERNO = this.orderno;
      this.prdtUpdatePODTO.GUIDItinerary = this.hotelEditForm.get(
        "GUIDItinerary"
      ).value;
      this.prdtUpdatePODTO.LOYALTYPOINTS = this.hotelEditForm.get(
        "LOYALTYPOINTS"
      ).value;
      this.prdtUpdatePODTO.LOYALTYPOINTSAMT = this.hotelEditForm.get(
        "LOYALTYPOINTSAMT"
      ).value;
      this.prdtUpdatePODTO.LOYALTYPOINTSBAL = this.hotelEditForm.get(
        "LOYALTYPOINTSBAL"
      ).value;
      this.prdtUpdatePODTO.LOYALTYPOINTSMEMBNO = this.hotelEditForm.get(
        "LOYALTYPOINTSMEMBNO"
      ).value;
      this.prdtUpdatePODTO.ROOMRDESC = this.hotelEditForm.get("RDESC").value;
      this.prdtUpdatePODTO.MealPlan = this.hotelEditForm.get("MealPlan").value;
      this.prdtUpdatePODTO.RDESC = this.hotelEditForm.get("RDESC").value;

      this.prdtUpdatePODTO.CostingCustomerNetSellPrice = this.hotelEditForm.get(
        "CostingCustomerNetSellPrice"
      ).value;
      this.prdtUpdatePODTO.FCSell = this.hotelEditForm.get("FCSell").value;
      this.prdtUpdatePODTO.CostingCustomerServiceFee = this.hotelEditForm.get(
        "CostingCustomerServiceFee"
      ).value;
      this.prdtUpdatePODTO.CostingCustomerTaxAmount = this.hotelEditForm.get(
        "CostingCustomerTaxAmount"
      ).value;
      this.prdtUpdatePODTO.CostofSale = this.hotelEditForm.get(
        "CostofSale"
      ).value;

      this.prdtUpdatePODTO.BaseFare = this.hotelEditForm.get("BaseFare").value;
      this.prdtUpdatePODTO.SELLAMT = this.hotelEditForm.get("SELLAMT").value;
      this.prdtUpdatePODTO.SELLCOMM = this.hotelEditForm.get("SELLCOMM").value;
      this.prdtUpdatePODTO.FC_SELLTAXAMT = this.hotelEditForm.get(
        "FC_SELLTAXAMT"
      ).value;
      this.prdtUpdatePODTO.SELLVAT = this.hotelEditForm.get("SELLVAT").value;
      this.prdtUpdatePODTO.SELLTAXNO = this.hotelEditForm.get(
        "SELLTAXNO"
      ).value;
      this.prdtUpdatePODTO.SELLTAXRATE = this.hotelEditForm.get(
        "SELLTAXRATE"
      ).value;

      this.prdtUpdatePODTO.PAYABLE = this.hotelEditForm.get("PAYABLE").value;
      this.prdtUpdatePODTO.FC_CIDExRate = this.hotelEditForm.get(
        "EXRATE"
      ).value;
      this.prdtUpdatePODTO.TYPE = "Hotel";
      this.apiPO.formData = this.prdtUpdatePODTO;
      this.subscription.add(
        this.apiPO.SaveProductAfterPO().subscribe(
          res => {
            console.log("SaveProductAfterPO");
            console.log(this.apiPO.formData);
            console.log(res);
            this.spinner.hide();
            if (res.IsSuccess === true && res.Message === "Success") {
              this.spinner.hide();
              this.subscription.add(
                this.translateapi
                  .get(["Messages.saved_success"])
                  .subscribe(translations => {
                    this.showMsg = translations["Messages.saved_success"];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    });
                  })
              );
              this.router.navigate([
                "/PenAir/Product/HotelDetails",
                this.orderno,
                this.guid
              ], { skipLocationChange: true });
            } else {
              this.spinner.hide();
              this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              });
            }
          },
          err => {
            this.spinner.hide();
            console.log('SaveProductAfterPO hotel');
            console.log(err);
            this.subscription.add(
              this.translateapi
                .get(["Messages.some_error_in_save"])
                .subscribe(translations => {
                  this.showMsg = translations["Messages.some_error_in_save"];
                  this.toastr.warning(this.showMsg, "", {
                    timeOut: 3000,
                    disableTimeOut: false,
                    closeButton: true
                  });
                })
            );
          }
        )
      );
    } else {
      if (this.guid != null) {
        this.spinner.show();
        if (this.CompanyCardValidation() == true) {
          this.subscription.add(
            this.api.updateHotel(this.hotelEditForm.value).subscribe(
              res => {
                console.log(this.hotelEditForm.value);
                console.log("updateHotel");
                console.log(res);
                if (res != null && res != undefined) {
                  if (res.IsSuccess === true && res.Message === "SUCCESS") {
                    this.spinner.hide();
                    this.subscription.add(
                      this.translateapi
                        .get(["Messages.saved_success"])
                        .subscribe(translations => {
                          this.showMsg = translations["Messages.saved_success"];
                          this.toastr.success(this.showMsg, "", {
                            timeOut: 3000,
                            disableTimeOut: false,
                            closeButton: true
                          });
                        })
                    );
                    this.router.navigate([
                      "PenAir/Product/HotelDetails",
                      this.orderno,
                      this.guid + "/"
                    ], { skipLocationChange: true });
                  } else {
                    this.spinner.hide();
                    this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                      timeOut: 5000,
                      disableTimeOut: false,
                      closeButton: true
                    });
                  }
                } else {
                  this.spinner.hide();
                }
              },
              err => {
                this.spinner.hide();
                console.log('update hotel');
                console.log(err);
                this.subscription.add(
                  this.translateapi
                    .get(["Messages.some_error_in_save"])
                    .subscribe(translations => {
                      this.showMsg =
                        translations["Messages.some_error_in_save"];
                      this.toastr.warning(this.showMsg, "", {
                        timeOut: 3000,
                        disableTimeOut: false,
                        closeButton: true
                      });
                    })
                );
              }
            )
          );
        } else {
          this.spinner.hide();
        }
      } else {
        this.hotelEditForm.value["CHECKIN"] = this.checkin;
        this.hotelEditForm.value["CHECKOUT"] = this.checkout;
        this.spinner.show();
        if (this.CompanyCardValidation() == true) {
          this.subscription.add(
            this.api.addHotel(this.hotelEditForm.value).subscribe(
              res => {
                console.log("addHotel");
                console.log(this.hotelEditForm.value);
                console.log(res);
                if (res != null && res != undefined) {
                  if (res.IsSuccess === true && res.Message === "SUCCESS") {
                    this.spinner.hide();
                    this.subscription.add(
                      this.translateapi
                        .get(["Messages.saved_success"])
                        .subscribe(translations => {
                          this.showMsg = translations["Messages.saved_success"];
                          this.toastr.success(this.showMsg, "", {
                            timeOut: 3000,
                            disableTimeOut: false,
                            closeButton: true
                          });
                        })
                    );

                    this.common.isfinalsaved = false;
                    sessionStorage.setItem("pagevalue", "0");
                    var folderQuotes = sessionStorage.getItem("folderQuotes");
                    if (folderQuotes) {
                      this.router.navigate([
                        "/PenAir/CRM/Enquiry/View/",
                        this.orderno
                      ], { skipLocationChange: true });
                    } else {
                      this.router.navigate([
                        "PenAir/Folder/FolderSummary",
                        this.orderno
                      ], { skipLocationChange: true });
                    }
                  } else {
                    this.spinner.hide();
                    this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                      timeOut: 5000,
                      disableTimeOut: false,
                      closeButton: true
                    });
                  }
                } else {
                  this.spinner.hide();
                }
              },
              err => {
                this.spinner.hide();
                console.log('add hotel');
                console.log(err);
                this.subscription.add(
                  this.translateapi
                    .get(["Messages.some_error_in_save"])
                    .subscribe(translations => {
                      this.showMsg =
                        translations["Messages.some_error_in_save"];
                      this.toastr.warning(this.showMsg, "", {
                        timeOut: 3000,
                        disableTimeOut: false,
                        closeButton: true
                      });
                    })
                );
              }
            )
          );
        } else {
          this.spinner.hide();
        }
      }
    }
  }

  //#endregion Form Submission

  //#region Cancel Button Click Event

  onCancel() {
    if (this.guid != "" && this.guid != null) {
      this.router.navigate([
        "PenAir/Product/HotelDetails",
        this.orderno,
        this.guid
      ], { skipLocationChange: true }); //By Caseid-12752
    } else {
      this.common.isfinalsaved = false;
      sessionStorage.setItem("pagevalue", "0");
      var folderQuotes = sessionStorage.getItem("folderQuotes");
      if (folderQuotes) {
        this.router.navigate(["/PenAir/CRM/Enquiry/View/", this.orderno], { skipLocationChange: true });
      } else {
        this.router.navigate(["PenAir/Folder/FolderSummary", this.orderno], { skipLocationChange: true });
      }
    }
  }

  //#endregion Cancel Button Click Event

  onReset() {
    this.submitted = false;
    this.hotelEditForm.reset();
  }

  //#region feild value changed

  CalculateForm() {
    this.nights =
      this.hotelEditForm.get("NIGHTS") == null ||
        this.hotelEditForm.get("NIGHTS").value == 0 ||
        this.hotelEditForm.get("NIGHTS").value == ""
        ? 1
        : this.hotelEditForm.get("NIGHTS").value;
    if (this.nights == null) {
      this.nights = 1;
    }
    this.rooms =
      this.hotelEditForm.get("ROOMS").value == 0
        ? 1
        : this.hotelEditForm.get("ROOMS").value;
    if (this.rooms == null) {
      this.rooms = 1;
    }
    this.buyExRate =
      this.hotelEditForm.get("EXRATE").value == "" ||
        this.hotelEditForm.get("EXRATE").value == 0
        ? 1
        : this.hotelEditForm.get("EXRATE").value;
    if (
      this.hotelEditForm.get("AMTLC").value != null &&
      this.hotelEditForm.get("AMTLC").value != "undefined" &&
      this.hotelEditForm.get("AMTLC").value != 0
    ) {
      this.fCBuy =
        this.hotelEditForm.controls.AMTLC.value * this.nights * this.rooms;
      this.hotelEditForm.controls.FCBuy.setValue(
        parseFloat(this.fCBuy.toFixed(2))
      );
      this.hotelEditForm.controls.PAYABLE.setValue(
        parseFloat((this.fCBuy * this.buyExRate).toFixed(2))
      );
      this.hotelEditForm.controls.AMTHC.setValue(
        parseFloat(
          (this.hotelEditForm.controls.AMTLC.value * this.buyExRate).toFixed(2)
        )
      );
    } else {
      this.hotelEditForm.controls.AMTHC.setValue(0);
      this.hotelEditForm.controls.PAYABLE.setValue(
        parseFloat(
          (this.hotelEditForm.get("FCBuy").value * this.buyExRate).toFixed(2)
        )
      );
    }
    this.hcBuy = this.hotelEditForm.get("FCBuy").value * this.buyExRate;
    this.hotelEditForm.controls.SELLAMT.setValue(
      parseFloat(
        (this.hotelEditForm.get("FCSell").value * this.exRate).toFixed(2)
      )
    );
    this.hotelEditForm.controls.SELLCOMM.setValue(
      parseFloat(
        (
          this.hotelEditForm.get("SELLAMT").value -
          this.hotelEditForm.get("PAYABLE").value
        ).toFixed(2)
      )
    );
    /// Payment Terms

    this.amount =
      this.hotelEditForm.get("DEFDEP").value == "" ||
        this.hotelEditForm.get("DEFDEP").value == 0
        ? 0
        : this.hotelEditForm.get("DEFDEP").value;
    this.defaultDepositPer =
      this.hotelEditForm.get("DEFDEPPER").value == "" ||
        this.hotelEditForm.get("DEFDEPPER").value == 0
        ? 0
        : this.hotelEditForm.get("DEFDEPPER").value;

    if (this.defaultDepositPer != 0 && this.defaultDepositPer != 0.0) {
      this.amount = (this.hcBuy * this.defaultDepositPer) / 100;
    }

    this.balanceDueAmount = this.hcBuy - this.amount;
    this.hotelEditForm.controls.DEFDEP.setValue(
      parseFloat(this.amount.toFixed(2))
    );
    this.hotelEditForm.controls.BalanceDueAmount.setValue(
      parseFloat(this.balanceDueAmount.toFixed(2))
    );
    // Corperate Info

    this.hotelEditForm.controls.ActualSaving.setValue(
      parseFloat((this.hotelEditForm.get("PAYABLE").value * -1).toFixed(2))
    );
  }

  //#endregion feild value changed
  //#region booking date feild value changed

  public getCurrencyExRate() {
    this.bookingDate =
      this.hotelEditForm.controls.BookingDate.value == null ||
        this.hotelEditForm.controls.BookingDate.value == ""
        ? "01-Jan-1900"
        : this.hotelEditForm.controls.BookingDate.value; //Case id-337
    if (Boolean(this.common.ValidDateRange(this.bookingDate)) == false) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidBookingDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidBookingDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      return;
    }
    if (
      this.hotelEditForm.controls.CURCODE.value != "" &&
      this.hotelEditForm.controls.CURCODE.value != null
    ) {
      this.getExchangeRate(
        this.hotelEditForm.controls.CURCODE.value,
        this.bookingDate
      );
    }
  }

  //#endregion booking date feild value changed

  //#region inter ind feild value changed

  interChange(value: any) {
    if (value == "Domestic") {
      this.subscription.add(
        this.lookupApi
          .GetLookupById("ProductDomesticTax", "2", "1")
          .subscribe(data => {
            if (data != null && data != undefined) {
              var data = data["ResponseData"];
              this.common.TaxselectedItem.Code = data[0]["Code"];
              this.common.TaxselectedItem = this.common.Tax.find(
                item => item.Code == data[0]["UsrCode"]
              );
              this.hotelEditForm.controls.CostingTaxNo.setValue(
                this.common.TaxselectedItem.Code
              );
              this.hotelEditForm.controls.CostingTaxRate.setValue(
                this.common.TaxselectedItem.UsrCode
              );
            }
          })
      );
    } else if (value == "International") {
      this.subscription.add(
        this.lookupApi
          .GetLookupById("ProductInternationalTax", "2", "1")
          .subscribe(data => {
            if (data != null && data != undefined) {
              var data = data["ResponseData"];

              this.common.TaxselectedItem = this.common.Tax.find(
                item => item.Code == data[0]["UsrCode"]
              );
              this.hotelEditForm.controls.CostingTaxNo.setValue(
                this.common.TaxselectedItem.Code
              );
              this.hotelEditForm.controls.CostingTaxRate.setValue(
                this.common.TaxselectedItem.UsrCode
              );
            }
          })
      );
    }
  }

  //#endregion inter ind feild value changed

  //#region room type value changed

  public RoomTypeChange(value: any): void {
    if (value != null && value != undefined) {
      this.hotelEditForm.controls.TYPE.setValue(value.UsrCode);
    } else {
      this.hotelEditForm.controls.TYPE.setValue("");
    }
  }

  //#endregion room type value changed
  //#region city name value changed

  public CityChange(value: any): void {
    this.CityselectedItem = this.CityList.find(item => item.Name == value);

    if (this.CityselectedItem != null) {
      this.hotelEditForm.controls.CITYID.setValue(this.CityselectedItem.Code);
      this.hotelEditForm.controls.CITY.setValue(
        this.CityselectedItem.ActualName
      );
    } else {
      this.Cityselected = "";
      this.hotelEditForm.controls.CITYID.setValue("");
      this.hotelEditForm.controls.CITY.setValue("");
    }
  }

  //#endregion city name value changed
  //#region meal plan value changed

  public MealPlansChange(value: any): void {
    console.log("selectionChange", value);
    this.hotelEditForm.controls.MealPlan_MPM_Id.setValue(value.Code);
    this.hotelEditForm.controls.MealPlan.setValue(value.Name);
  }

  //#endregion meal plan value changed

  //#region bookedby value changed

  public BookedByChange(value: any): void {
    this.hotelEditForm.controls.BOOKEDBY.setValue(value.Name);
  }
  //#endregion bookedby value changed

  //#region supplier value changed

  SupplierDefaultvalue(suppcode) {
    this.subscription.add(
      this.supplier.GetDefaultValue(suppcode).subscribe(data => {
        if (data !== "" && data !== null && data !== undefined) {
          this.hotelEditForm.controls.PAYMENTID.setValue(data.PAYTERMID);
          this.hotelEditForm.controls.PMTID.setValue(data.PAYMTDID);
          this.resetChildForm();
        }
      })
    );
  }

  //#endregion supplier value changed

  //#region hotel value changed

  public HotelValueChange(value: any): void {
    this.HotelselectedItem = this.HotelList.find(item => item.Name == value);
    if (this.HotelselectedItem != null) {
      this.getHotelContactDetails(this.HotelselectedItem.ActualName);//By Caseid-13567
      this.hotelEditForm.controls.HOTELLOC.setValue(
        this.HotelselectedItem.Code
      );
      this.hotelEditForm.controls.HOTELNAME.setValue(
        this.HotelselectedItem.Name
      );
      this.hotelEditForm.controls.Hote101_Id.setValue(
        this.HotelselectedItem.ActualName
      );

    } else {
      //this.Hotelselected = '';//Commented By Caeid-12752
      this.hotelEditForm.controls.Hote101_Id.setValue(0); //By Caeid-12752
      this.hotelEditForm.controls.HOTELNAME.setValue(value); //By Caeid-12752
      this.hotelEditForm.controls.HOTELLOC.setValue(""); //By Caeid-12752
      this.hotelEditForm.controls.ADDRESS.setValue("");//By Caseid-13567
      this.hotelEditForm.controls.PHONE.setValue("");//By Caseid-13567
      this.hotelEditForm.controls.EMAIL.setValue("");//By Caseid-13567
    }
  }

  //#endregion hotel value changed
  public getHotelContactDetails(id) { //By Caseid-13567
    this.api.getHotelContactDetails(id).subscribe(data => {
      if (data && data.ResponseData) {
        this.hotelEditForm.controls.ADDRESS.setValue(data.ResponseData.Address);
        this.hotelEditForm.controls.PHONE.setValue(data.ResponseData.TelePhone);
        this.hotelEditForm.controls.EMAIL.setValue(data.ResponseData.AddressEmail);
      }
    });
  }
  resetChildForm() {
    this.resetFormSubject.next(true);
  }
  onChanges(): void { }

  changefn() { }

  //#region supplier value changed

  public SupplierValueChange(value: any): void {

    debugger;
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(
        item => item.Name == value
      );
    }

    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name;
      this.SupplierDefaultvalue(this.supplierSelectedItem.Code);
      this.hotelEditForm.controls.SUPPRTYCODE.setValue(
        this.supplierSelectedItem.Code
      );
      this.hotelEditForm.controls.SUPPNAME.setValue(
        this.supplierSelectedItem.ActualName
      );
      this.apiShared.BALDUETO = this.supplierSelectedItem.BALDUETO;
      this.payterm.getBalanceDuedate();
      this.hotelEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID)
      if (this.supplierSelectedItem.CONTID != null && this.supplierSelectedItem.CONTID != undefined && this.supplierSelectedItem.CONTID != "")
        // this.fchcdetails.getTaxLookup(this.supplierSelectedItem.CONTID)
        this.TaxLookup(this.supplierSelectedItem.CONTID)
    } else {
      this.supplierSelected = "";
      this.hotelEditForm.controls.SUPPRTYCODE.setValue("");
      this.hotelEditForm.controls.SUPPNAME.setValue("");
    }
  }

  TaxLookup(country) {
    this.fchcdetails.getInputTaxLookup(country)
  }
  //#endregion supplier value changed

  //#region Value of numeric text box changed

  onDateoutblur() {
    this.dateIn =
      this.hotelEditForm.controls.DATEIN.value == null ||
        this.hotelEditForm.controls.DATEIN.value == ""
        ? "01-Jan-1900"
        : this.hotelEditForm.controls.DATEIN.value; //Case id-337
    this.dateOut =
      this.hotelEditForm.controls.DATEOUT.value == null ||
        this.hotelEditForm.controls.DATEOUT.value == ""
        ? "01-Jan-1900"
        : this.hotelEditForm.controls.DATEOUT.value; //Case id-337

    if (Boolean(this.common.ValidDateRange(this.dateIn)) == false) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidDateIn"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidDateIn"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.dateOut)) == false) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidDateOut"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidDateOut"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      return;
    }

    if (
      this.hotelEditForm.controls.DATEOUT.value != null &&
      this.hotelEditForm.controls.DATEIN.value != null
    ) {
      if (
        this.hotelEditForm.controls.DATEOUT.value != "" &&
        this.hotelEditForm.controls.DATEIN.value != ""
      ) {
        this.diff =
          this.hotelEditForm.controls.DATEOUT.value.getTime() -
          this.hotelEditForm.controls.DATEIN.value.getTime();
        this.days = this.diff / (1000 * 60 * 60 * 24);
      }
    } else {
      this.days = 0;
    }
  }

  //#endregion Value of numeric text box changed

  //#region Value of numeric text box changed

  onNightsblur() {
    this.dateIn =
      this.hotelEditForm.controls.DATEIN.value == null ||
        this.hotelEditForm.controls.DATEIN.value == ""
        ? "01-Jan-1900"
        : this.hotelEditForm.controls.DATEIN.value; //Case id-337
    this.dateOut =
      this.hotelEditForm.controls.DATEOUT.value == null ||
        this.hotelEditForm.controls.DATEOUT.value == ""
        ? "01-Jan-1900"
        : this.hotelEditForm.controls.DATEOUT.value; //Case id-337

    if (Boolean(this.common.ValidDateRange(this.dateIn)) == false) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidDateIn"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidDateIn"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.dateOut)) == false) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidDateOut"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidDateOut"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      return;
    }
    if (
      this.hotelEditForm.controls.DATEIN.value != null &&
      this.hotelEditForm.controls.DATEIN.value != ""
    ) {
      this.hotelEditForm.controls.DATEOUT.setValue(
        new Date(
          new Date(this.hotelEditForm.controls.DATEIN.value).getTime() +
          this.days * 60 * 60 * 24 * 1000
        )
      );
    }
    //this.CalculateForm();
  }

  //#endregion Value of numeric text box changed

  isSticky: boolean = false;
  @HostListener("window:scroll")
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }
  //#region UnSubscribe Subscription
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  //#endregion UnSubscribe Subscription

  ConfirmMessage() {
    if (this.creditLimiFlag == false) {
      this.creditLimitMsg = false;
      this.hotelDetailsSaveandUpdate();
    } else {
      this.creditLimitMsg = false;
    }
  }
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }




  getParentMethod(): any { //13860 v1.0
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = []
        this.loadSupplierValue(value.Code)
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  loadSupplierValue(value: any) {//14068   v1.0 
    debugger;

    this.subscription.add(this.lookupApi.findSupplier(value).subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.SupplierList = data["ResponseData"];
          if (value != "") {

            this.supplierSelectedItem = this.SupplierList.find(item => item.Code == value);
            if (this.supplierSelectedItem != null) {
              this.supplierSelected = this.supplierSelectedItem.Name;
            }

          }
        }

      }
    ));

  }


}
