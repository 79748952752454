import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from 'src/env.service';
import { LocalizationModel } from '../Models/localization-model.model';

@Injectable({
  providedIn: 'root'
})
export class Localization_Service {
 
  
 public locl=new LocalizationModel;
  private _jsonURL = 'assets/Localization/AddressDetails/';
  constructor(private http: HttpClient,  private env: EnvService) { }
  public getJSONLanguage(value:any): any {
   

const path = this._jsonURL+value;
debugger;
var result = this.doesFileExist(this._jsonURL+value+'.json');
  // path exists
if(result==false)
{
  value='Default';
}
  this.http.get(this._jsonURL+value+'.json').subscribe(tl=>{

    this.locl=new LocalizationModel();
    Object.assign(this.locl, tl);
   });


    
  
  }
   doesFileExist(urlToFile) {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    xhr.send();
     
    if (xhr.status == 404) {
        return false;
    } else {
        return true;
    }
}

}
