import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from 'src/env.service';
import { BankStatementDTO } from '../Models/bankreconsilation.model';
import { CommonserviceService } from './commonservice.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class ModuleUsageService {
  ReportCriteria: any;
  public reportname: any;
  public ModuleUsageDTO = {
    reportname: "",
    ReportCriteria:""
  };
  public List: any;
  apiUrl: string;
  constructor(private http: HttpClient, private env: EnvService, public httpService: HttpClient, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }

  getModuleUsageReport(): Observable<any> {

    var body = {
      ...this.ModuleUsageDTO,
      objAirTicketDto: this.List,
      objFolderActivityReportDto: this.List,
      objPettycashReportDTO: this.List,
      objCustomerStatementDTO: this.List,
      objVoucherLiabilityDTO: this.List,
      objTrialBalanceDTO: this.List,
      objPLAnalysisDTO: this.List,
      objSupplierStatementDTO: this.List,
      objCorporateInvoiceDTO: this.List,
      objCustomerReceiptsDTO: this.List

    };
    return this.http.post<any>(this.apiUrl + 'GetModuleUsageReport', body);
  }

}
