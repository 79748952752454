import { Component, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FolderSearchCriteriaDTO, FolderSearchDTO } from '../../Models/folder-search-criteria-dto';
import { SearhdtotransferService } from '../../Services/searhdtotransfer.service';
import { ThrowStmt, IfStmt } from '@angular/compiler';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';


@Component({
  selector: 'app-searchby-folder',
  templateUrl: './searchby-folder.component.html',
  styleUrls: ['./searchby-folder.component.scss']
})
export class SearchbyFolderComponent implements OnInit, OnChanges {
  private subscription: Subscription = new Subscription();
  @ViewChild('autocompletecustomer') public autocompletecustomer: AutoCompleteComponent;
  isShown: boolean = false;
  constructor(public common: CommonserviceService, private searhTransferDTO: SearhdtotransferService, private lookupApi: LookUpDetailsService) { }
  flsrchDto: FolderSearchCriteriaDTO;
  folderSearchDTO: FolderSearchDTO;
  public DepDatefromAF = Date;
  public DepDateToAF = Date;
  public CreationDatefromAF = Date;
  public CreationDateToAF = Date;
  lookupname: any = 'CUSTOMER';
  lookuptype: string = '';
  SupplierList: Array<LookUpDetails['ResponseData']> = [];
  CustomerList: Array<LookUpDetails["ResponseData"]> = [];
  opened = false;
  openSearch = false;

  public customerSelectedItem: LookUpDetails["ResponseData"];
  public customerSelected: String;

  public supplierSelectedItem: LookUpDetails['ResponseData'];
  public supplierSelected: String;
  menuIndex = 0;
  ngOnInit() {

   

    this.subscription.add(this.searhTransferDTO.SrchTranferDTO.subscribe(flsrchDto => {
      this.flsrchDto = flsrchDto;

    }))
    if (this.flsrchDto.FolderSearchDTO != null)
      this.folderSearchDTO = this.flsrchDto.FolderSearchDTO;
    else
      this.folderSearchDTO = new FolderSearchDTO();

    
    if( this.folderSearchDTO.CustCode != 0 &&  this.folderSearchDTO.CustCode != undefined ){ debugger;
      this.loadCustomerValue(this.folderSearchDTO.CustCode);
    }


  }
  ngOnChanges(changes: SimpleChanges) {

    this.folderSearchDTO = this.flsrchDto.FolderSearchDTO;
  }
  callChange() {
    debugger;

    if (this.folderSearchDTO.FolderNo != "" || this.folderSearchDTO.OrderNo != "" || this.folderSearchDTO.PNR != "" || this.folderSearchDTO.AirlinePNR != '' || this.folderSearchDTO.LeadPassenger != ''
      || this.folderSearchDTO.LeadPassenger != '' || this.folderSearchDTO.InetRef != '' || this.folderSearchDTO.InvoiceNo.toString() != '' || this.folderSearchDTO.YourRef.toString() != ''
      || this.folderSearchDTO.DepDatefromAF.toString() != '' || this.folderSearchDTO.DepDateToAF.toString() != '' || this.folderSearchDTO.PassengerPhone.toString() != '' || this.folderSearchDTO.PassengerEmail.toString() != ''
      || this.folderSearchDTO.passngerPostCode.toString() != '' || this.folderSearchDTO.CustomerPostcode.toString() != '' || this.folderSearchDTO.TicketNo.toString() != '' || this.folderSearchDTO.CustCode.toString() != '0'
      || this.folderSearchDTO.Firstname != '' || this.folderSearchDTO.Lastname != '' || this.folderSearchDTO.InvAmount1.toString() != '' || this.folderSearchDTO.InvAmount2.toString() != ''
      || this.folderSearchDTO.CreationDatefromAF.toString() != '' || this.folderSearchDTO.CreationDateToAF.toString() != '') {

      if (this.folderSearchDTO.DepDatefromAF != null) {
        if (this.folderSearchDTO.DepDatefromAF.toString() != '')
          this.folderSearchDTO.DepDatefrom = this.common.transformDate(new Date(this.folderSearchDTO.DepDatefromAF));

        // this.folderSearchDTO.DepDatefrom= new Date(this.folderSearchDTO.DepDatefromAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.folderSearchDTO.DepDatefrom = null;

      }
      if (this.folderSearchDTO.DepDateToAF != null) {
        if (this.folderSearchDTO.DepDateToAF.toString() != '')
          this.folderSearchDTO.DepDateTo = this.common.transformDate(new Date(this.folderSearchDTO.DepDateToAF));

        // this.folderSearchDTO.DepDateTo=new Date(this.folderSearchDTO.DepDateToAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.folderSearchDTO.DepDateTo = null;

      }

      if (this.folderSearchDTO.CreationDatefromAF != null) {
        if (this.folderSearchDTO.CreationDatefromAF.toString() != '')
          this.folderSearchDTO.CreateDatefrom = this.common.transformDate(new Date(this.folderSearchDTO.CreationDatefromAF));
      } else {
        this.folderSearchDTO.CreateDatefrom = null;

      }
      if (this.folderSearchDTO.CreationDateToAF != null) {
        if (this.folderSearchDTO.CreationDateToAF.toString() != '')
          this.folderSearchDTO.CreateDateTo = this.common.transformDate(new Date(this.folderSearchDTO.CreationDateToAF));
      } else {
        this.folderSearchDTO.CreateDateTo = null;

      }

      if (this.folderSearchDTO.DepDatefromAF == null && this.folderSearchDTO.DepDateToAF != null) {
        this.folderSearchDTO.DepDatefrom = this.folderSearchDTO.DepDateTo;
      }
      if (this.folderSearchDTO.CreationDatefromAF == null && this.folderSearchDTO.CreationDateToAF != null) {
        this.folderSearchDTO.CreateDatefrom = this.folderSearchDTO.CreateDateTo;
      }



      this.flsrchDto.FolderSearchDTO = this.folderSearchDTO;
      // if( this.folderSearchDTO.DepDateTo.toString()!='')
      // {
      //   this.folderSearchDTO.DepDateTo=new Date( this.folderSearchDTO.DepDateTo).toLocaleDateString();
      // }
      // if( this.folderSearchDTO.DepDatefrom.toString()!='')
      // {
      //   this.folderSearchDTO.DepDatefrom=new Date( this.folderSearchDTO.DepDatefrom).toLocaleDateString();
      // }
    }
    else
      this.flsrchDto.FolderSearchDTO = null;

  }
  // get f() { return this.folderSearchForm.controls; }
  handleFiltercustomer(value) {

    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.GetLookupById("CUSTOMER", value, value).subscribe(

        (data) => {
          if (data != null && data != undefined) {
            this.CustomerList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletecustomer.toggle(false);
    }
  }

  public close(status) {

    this.opened = false;
    this.openSearch = false;
  }

  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'CUSTOMER') {
      this.lookupname = 'CUSTOMER';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {

        debugger;
        this.openSearch = false;
        this.customerSelectedItem = value;
        this.CustomerList = [];
        this.CustomerValueChange(value.Name)
      },

      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860

  public CustomerValueChange(value: any): void {
    debugger;
    if (this.CustomerList != null && this.CustomerList.length > 0) {//13860 
      this.customerSelectedItem = this.CustomerList.find(item => item.Name == value);

    }

    if (this.customerSelectedItem != null) {
      this.customerSelected = this.customerSelectedItem.Name; //13860
      this.folderSearchDTO.CustCode = parseInt(this.customerSelectedItem.Code);
      this.folderSearchDTO.CustName = (this.customerSelectedItem.ActualName);

    }
    else {
      this.customerSelected = "";
      this.folderSearchDTO.CustCode = 0;
      this.folderSearchDTO.CustName = "";
    }
    this.callChange();
  }

  // loadCustomerValue(value:any) {  //14068   v1.0 
  //   debugger; 

  //     this.subscription.add(this.lookupApi.findCustomer(value).subscribe(
  //       (data) => {
  //         if (data["ResponseData"] != null) {
  //           this.SupplierList = data["ResponseData"];
  //           if (value != "") {

  //             this.customerSelectedItem = this.CustomerList.find(item => item.Code == value);
  //             if (this.customerSelectedItem != null) {
  //               this.customerSelected = this.customerSelectedItem.Name;
  //             }

  //           }
  //         }

  //       }
  //     ));
    
  // }


  
  loadCustomerValue(value:any) {//14068   v1.0 
    debugger;

      this.subscription.add(this.lookupApi.findCustomer(value).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.CustomerList = data["ResponseData"];
            if (value != "") {

              this.customerSelectedItem = this.CustomerList.find(item => item.Code == value);
              if (this.customerSelectedItem != null) {
                this.customerSelected = this.customerSelectedItem.Name;
              }

            }
          }

        }
      ));
    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
