export class RailsegDetail {
    IsSuccess:boolean;
    Message:string;
    ResponseData:{
    
     FOLDERNO :number;
    ITEMNO :number;
    TRAINNAME :string;
    TRAINNO :string;
    CLASS :string;
    CLASSNAME :string;
    SEATS :string;
    STATUS :string;
    STATUSNAME :string;
    DEPDATE :Date;
    DEPCITY :string;
    DEPCITYNAME :string;
    ARRCITY :string;
    ARRCITYNAME :string;
    OPENSEG :string;
    DEPTIME :number;
    ARRTIME :number;
    S :string;
    PNRNO :string;
    FAREBASIS :string;
    DEPTERM :string;
    CHKIN :string;
    BOOKEDVIA :string;
    ARRDATE :Date;
    ARRTERM :string;
    TRAINTIME :number;
    BAGGALLOW :string;
    MEALSINFO :string;
    SEATINFO :string;
    SLNO :number;
     PROJNO :number;
    Stops :number;
    GDS :string;
    ItnryNotes :string;
    PCC :string;
    VLOCATOR :string;
    VOUCHER :string;
    CONFIRMATIONNO :string;
     RSD_AutoNo :number;

}
}
