import { Component, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { SearhdtotransferService } from '../../Services/searhdtotransfer.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { FolderSearchCriteriaDTO, HotelSearchDTO } from '../../Models/folder-search-criteria-dto';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
@Component({
  selector: 'app-searchby-hotel',
  templateUrl: './searchby-hotel.component.html',
  styleUrls: ['./searchby-hotel.component.scss']
})
export class SearchbyHotelComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;

  constructor(public common: CommonserviceService, private lookupApi: LookUpDetailsService, private formBuilder: FormBuilder, private searhTransferDTO: SearhdtotransferService,) { }
  // hotelSearchForm: FormGroup;
  SupplierList: Array<LookUpDetails['ResponseData']> = [];
  HotelList: Array<LookUpDetails['ResponseData']> = [];
  public statusList: Array<string> = ['Wait listed', 'Confirmed', 'Cancelled'];
  public supplierSelectedItem: LookUpDetails['ResponseData'];
  public supplierSelected: String;
  public HotelSelected: String;
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  opened = false;
  openSearch = false;
  public hotelSelectedItem: LookUpDetails['ResponseData'];

  RoomTypeList: Array<LookUpDetails['ResponseData']> = [];
  public RoomTypeselectedItem: LookUpDetails['ResponseData'];

  flsrchDto: FolderSearchCriteriaDTO;
  hotelSearchDTO: HotelSearchDTO;
  menuIndex = 1;
  ngOnChanges(changes: SimpleChanges) {
    this.hotelSearchDTO = this.flsrchDto.HotelSearchDTO;

  }


  ngOnInit() {


    this.subscription.add(this.searhTransferDTO.SrchTranferDTO.subscribe(flsrchDto => {
      this.flsrchDto = flsrchDto;

    }))
    if (this.flsrchDto.HotelSearchDTO != null)
      this.hotelSearchDTO = this.flsrchDto.HotelSearchDTO;
    else
      this.hotelSearchDTO = new HotelSearchDTO();
    // this.loadSupplier();
    this.loadHotel();
    this.loadRoomtype();
    if(this.hotelSearchDTO.Supplier!= "" && this.hotelSearchDTO.Supplier!= undefined){
      this.loadSupplierValue(this.hotelSearchDTO.Supplier);
    }
    
  }


  callChange() {

    if (this.hotelSearchDTO.HotelName != '' || this.hotelSearchDTO.Supplier != '' || this.hotelSearchDTO.CheckInDatefromAF.toString() != '' || this.hotelSearchDTO.CheckInDateToAF.toString() != ''
      || this.hotelSearchDTO.CheckOutDatefromAF.toString() != '' || this.hotelSearchDTO.CheckOutDateToAF.toString() != '' || this.hotelSearchDTO.Status.toString().trim() != '' || this.hotelSearchDTO.RoomType.toString().trim() != ''
      || this.hotelSearchDTO.ConfirmationNo.toString().trim() != '' || this.hotelSearchDTO.VoucherNo.toString().trim() != ''
    ) {
      if (this.hotelSearchDTO.CheckInDatefromAF != null) {
        if (this.hotelSearchDTO.CheckInDatefromAF.toString() != '')
          this.hotelSearchDTO.CheckInDatefrom = this.common.transformDate(new Date(this.hotelSearchDTO.CheckInDatefromAF));
        // this.hotelSearchDTO.CheckInDatefrom= new Date(this.hotelSearchDTO.CheckInDatefromAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.hotelSearchDTO.CheckInDatefrom = null;
      }

      if (this.hotelSearchDTO.CheckInDateToAF != null) {
        if (this.hotelSearchDTO.CheckInDateToAF.toString() != '')
          this.hotelSearchDTO.CheckInDateTo = this.common.transformDate(new Date(this.hotelSearchDTO.CheckInDateToAF));
        //  this.hotelSearchDTO.CheckInDateTo= new Date(this.hotelSearchDTO.CheckInDateToAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.hotelSearchDTO.CheckInDateTo = null;

      }

      if (this.hotelSearchDTO.CheckInDatefromAF == null && this.hotelSearchDTO.CheckInDateToAF != null) {
        this.hotelSearchDTO.CheckInDatefrom = this.hotelSearchDTO.CheckInDateTo;
      }


      if (this.hotelSearchDTO.CheckOutDatefromAF != null) {
        if (this.hotelSearchDTO.CheckOutDatefromAF.toString() != '')
          this.hotelSearchDTO.CheckOutDatefrom = this.common.transformDate(new Date(this.hotelSearchDTO.CheckOutDatefromAF));

        // this.hotelSearchDTO.CheckOutDatefrom= new Date(this.hotelSearchDTO.CheckOutDatefromAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.hotelSearchDTO.CheckOutDatefrom = null;

      }
      if (this.hotelSearchDTO.CheckOutDateToAF != null) {
        if (this.hotelSearchDTO.CheckOutDateToAF.toString() != '')
          this.hotelSearchDTO.CheckOutDateTo = this.common.transformDate(new Date(this.hotelSearchDTO.CheckOutDateToAF));

        //  this.hotelSearchDTO.CheckOutDateTo= new Date(this.hotelSearchDTO.CheckOutDateToAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      }
      if (this.hotelSearchDTO.CheckOutDatefromAF == null && this.hotelSearchDTO.CheckOutDateToAF != null) {
        this.hotelSearchDTO.CheckOutDatefrom = this.hotelSearchDTO.CheckOutDateTo;
      }
      this.flsrchDto.HotelSearchDTO = this.hotelSearchDTO;
    }
    else
      this.flsrchDto.HotelSearchDTO = null;
  }

  // public loadSupplier() {
  //   this.subscription.add(this.lookupApi.GetAllLookup('SUPPLIERS').subscribe(
  //     (data) => {
  //       if (data != null && data != undefined) {
  //         this.SupplierList = data["ResponseData"];
  //         if (this.hotelSearchDTO.Supplier != '' && this.hotelSearchDTO.Supplier != null) {

  //           this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.hotelSearchDTO.Supplier);
  //           this.supplierSelected = this.supplierSelectedItem.Name;
  //         }
  //       }
  //     }
  //   ));
  // }

  public close(status) {

    this.opened = false;
    this.openSearch = false;
  }

  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.hotelSearchDTO.Supplier = (this.supplierSelectedItem.Code);
        this.loadSupplierValue(value.Code);
        // this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  loadSupplierValue(value:any) {  //14068   v1.0 
    debugger; 

      this.subscription.add(this.lookupApi.findSupplier(value).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.SupplierList = data["ResponseData"];
            if (value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
              }

            }
          }

        }
      ));
    
  }
  //13860
  public loadHotel() {
    // alert(this.hotelSearchDTO.HotelName)
    this.subscription.add(this.lookupApi.GetAllLookup('HOTEL').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.HotelList = data["ResponseData"];
          if (this.hotelSearchDTO.HotelName != '' && this.hotelSearchDTO.HotelName != null) {

            this.hotelSelectedItem = this.HotelList.find(item => item.Name == this.hotelSearchDTO.HotelName);
            this.HotelSelected = this.hotelSelectedItem.Name;
          }
        }
      }
    ));
  }
  public loadRoomtype() {
    this.subscription.add(this.lookupApi.GetAllLookup('ROOMTYPEMASTER').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.RoomTypeList = data["ResponseData"];
          if (this.hotelSearchDTO.RoomType != '') {
            this.RoomTypeselectedItem = this.RoomTypeList.find(item => item.UsrCode == this.hotelSearchDTO.RoomType);
          }
        }
      }));
  }

  handleFilterhotel(value) {
    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.GetLookupById("HOTEL", value, value).subscribe(
        // this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.HotelList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }

  handleFiltersupplier(value) {

    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.GetLookupById("SUPPLIERS", value, value).subscribe(
        // this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }

  public RoomTypeChange(value: any): void {
    // console.log('selectionChange', value);
    if (value != null && value != undefined) {
      this.hotelSearchDTO.RoomType = (value.UsrCode);
    } else {
      this.hotelSearchDTO.RoomType = '';
    }
    this.callChange();
  }

  public HotelValueChange(value: any): void {
    //  alert(value);
    this.hotelSelectedItem = this.HotelList.find(item => item.Name == value);
    if (this.hotelSelectedItem != null) {

      this.hotelSearchDTO.HotelName = (this.hotelSelectedItem.Name);
    } else {
      this.HotelSelected = '';
      this.hotelSearchDTO.HotelName = "";
      // this.hotelSearchForm.controls.SUPPNAME.setValue('');
      this.callChange();
    }
    // this.carEditForm.get("suppUserCodetext").setValue( this.supplierSelectedItem.Name);
  }


  public SupplierValueChange(value: any): void {
    debugger;
    //  alert(value);
   
    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860

      //this.hotelSearchForm.controls.Supplier.setValue( this.supplierSelectedItem.Code);
      this.hotelSearchDTO.Supplier = (this.supplierSelectedItem.Code);

    } else {
      this.supplierSelected = '';
      this.hotelSearchDTO.Supplier = '';
      this.callChange();
    }
    // this.carEditForm.get("suppUserCodetext").setValue( this.supplierSelectedItem.Name);
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
