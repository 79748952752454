import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { CommonserviceService } from '../../Services/commonservice.service';
import { ShareDataService } from "../../Services/share-data.service";
@Component({
  selector: 'app-airticket-membership-loyalitypoints-edit',
  templateUrl: './airticket-membership-loyalitypoints-edit.component.html',
  styleUrls: ['./airticket-membership-loyalitypoints-edit.component.scss']
})
export class AirticketMembershipLoyalitypointsEditComponent implements OnInit {
  @Input() parentForm: FormGroup
  @Input() public pocreated;
  lockStatus: any;
  folderStatus: any;
  formeditable: any;
  constructor(
    public apiShared: ShareDataService,
    public common:CommonserviceService
  ) { }

  ngOnInit() {
    //case id : 13777 
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    } else {
      this.formeditable = false;

    }
    // case id : 13777
  }
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;

  public onChange(event: any): void {
    this.expandMode = parseInt(event.target.value, 10);
  }
  get fp() { return this.parentForm.controls; }
  public onPanelChange(event: any): void { console.log('stateChange: ', event); }
  FormLoyalityPointsChange() {
    debugger;
    if (this.parentForm.controls.LOYALTYPOINTSMEMBNO.value != "" && this.parentForm.controls.LOYALTYPOINTSMEMBNO.value != null) {


      this.parentForm.get('LOYALTYPOINTS').enable();
      this.parentForm.get('LOYALTYPOINTSAMT').enable();
      // this.disableTextbox =  false;
    }
    else {
      this.parentForm.controls.LOYALTYPOINTS.setValue(0);
      this.parentForm.controls.LOYALTYPOINTSAMT.setValue(0);
      this.parentForm.get('LOYALTYPOINTS').disable();
      this.parentForm.get('LOYALTYPOINTSAMT').disable();

    }
  }
}
