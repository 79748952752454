import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnlockdocumentService } from '../../Services/unlockdocument.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  GridDataResult, DataStateChangeEvent, RowArgs, SelectAllCheckboxState, PageChangeEvent,
  DataBindingDirective, PageSizeItem
} from '@progress/kendo-angular-grid';

import { UnlockDocumentListDTO } from '../../Models/unlock-document-list-dto.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { PublicLayoutComponent } from 'src/app/public-layout/public-layout.component';
import { CommonserviceService } from 'src/app/Services/commonservice.service';
import { stringify } from 'querystring';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-unlockdocument',
  templateUrl: './unlockdocument.component.html',
  styleUrls: ['./unlockdocument.component.scss']
})
export class UnlockdocumentComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  private subscription: Subscription = new Subscription();
  public offset = 50;
  public windowLeft = this.offset;
  public windowTop = this.offset;
  public width = 600;
  public height = 190;
  public date: string;
  equals: any;
  private rowsSelected: number[] = [];
  public itemToRemove: any;
  private view: Observable<GridDataResult>;
  //13656
  private pageSize: any;
  pagesizeSelectedItem: any;

  public pageSizes: (PageSizeItem | number)[] = [20, 50, 100, {
    text: 'All',
    value: 'all'
  }];
  //13656
  private skip = 0;
  showFilter = false;
  public showMsg: any;
  public gridData: any;
  public items: UnlockDocumentListDTO[] = [];
  public model: UnlockDocumentListDTO;
  modelobj: UnlockDocumentListDTO[];
  public mySelection: number[] = [];
  loaded = false;
  lockStatus: any;
  public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  folderStatus: any;
  public products: GridDataResult;
  public Componentname: string;
  constructor(public common: CommonserviceService, private toastr: ToastrService, private translateapi: TranslateService, public commonservice: CommonserviceService, private cd: ChangeDetectorRef, private route: ActivatedRoute, private router: Router,
    private service: UnlockdocumentService, private spinner: NgxSpinnerService, private layout: PublicLayoutComponent, private flashMessage: FlashMessagesService) { }
  ngOnInit() {
    //13656
    this.pagesizeSelectedItem = 20;
    this.pageSize = 20;
    //13656
    this.layout.folderstatus = '';
    this.layout.headerPassenger = '';

    this.load();
    this.Componentname = "Unlockdocument";
    //this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654
    sessionStorage.setItem('Componentname', this.Componentname);
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.subscription.add(this.service.GetPenDocUnLockDetails().subscribe(res => { this.gridData = res.ResponseData; }));
  }
  public rowsSelectedKeys(context: RowArgs): number {
    return context.dataItem.SLNO;
  }
  public onSelectedKeysChange(e) {
    const len = this.mySelection.length;
    if (len === 0) {
      this.selectAllState = 'unchecked';
      if (this.items === undefined) {
        this.items = [];
      }
      //  this.items.push(context.dataItem);
    } else if (len > 0 && len < this.gridData.length) {
      this.selectAllState = 'indeterminate';
    } else {
      this.selectAllState = 'checked';
    }
  }
  public sort: SortDescriptor[] = [ //12731
    {
      field: 'DATE',
      dir: 'desc'
    }
  ];
  //13656
  pageSizeValueChange(event) {
    debugger;
    this.pagesizeSelectedItem = event;
    this.pageSize = this.pagesizeSelectedItem;

  }
  //13656
  onFilterClick() {
    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }
  public load() {
    this.spinner.show();
    this.mySelection = [];
    this.selectAllState = 'unchecked';
    this.gridData = [];
    this.subscription.add(this.service.GetPenDocUnLockDetails().subscribe(res => {
      this.gridData = res.ResponseData;
      this.spinner.hide();
    }));
  }

  public refresh() {
    this.mySelection = [];
    this.selectAllState = 'unchecked';
    this.gridData = [];
    this.load();
    this.gridData = this.gridData.slice(1, 1);
    this.dataBinding.skip = 0;
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState) {
    debugger;
    if (checkedState === 'checked') {
      this.mySelection = this.gridData.map((item) => item.SLNO);
      this.selectAllState = 'checked';
    } else {
      this.mySelection = [];
      this.selectAllState = 'unchecked';
    }
  }


  public confirmRemove(Remove: boolean): void {
    this.removeConfirmationSubject.next(Remove);
    debugger;
    if (Remove) {
      // if (this.items === undefined) {
      //   this.items = [];
      // }
      this.items = [];
      this.mySelection.forEach(obj => {
        this.items.push(this.gridData.find((slno) => slno.SLNO === obj));
      });
      this.service.formData = new UnlockDocumentListDTO();
      this.service.List = [];
      for (let result of this.items) {
        result.DATE = null;
        // var dta=new Date(result.DATE);
        // if(dta.toString()=='Invalid Date')
        // {
        // result.DATE= (this.commonservice.ConvertToLocaleDate((new Date())))   // (this.commonservice.ConvertToLocaleDate((result.DATE)))
        //}
        // else
        // result.DATE=     (this.commonservice.ConvertToLocaleDate(new Date(result.DATE)))

      }
      this.service.List = this.items;

      if (this.service.List.length > 0) {
        this.subscription.add(this.service.UpdateFolderMaster().subscribe(res => {
          this.items = [];
          this.service.List = [];
          this.load();
          this.commonservice.showSUCCESStoastrmsg('Messages.DocumentUnlocked', 3000, true, false);
        }));

      } else {
        this.commonservice.showWARNINGtoastrmsg('Messages.PleaseSelectAtleastOneRow', 3000, true, true);

      }
    }
    this.itemToRemove = null;
  }
  public unlockdocument() {
    this.itemToRemove = 1;

  }

  public Back() {
    this.router.navigateByUrl('/PenAir/Home');
  }
  ngOnDestroy() {
    console.log('ngOnDestory');
    this.subscription.unsubscribe();
  }
}
