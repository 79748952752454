import { Component, OnInit, ViewChild, ChangeDetectorRef,Optional } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { Subscription } from 'rxjs';
import { CommonserviceService } from 'src/app/Services/commonservice.service';
import { ShareDataService } from 'src/app/Services/share-data.service';
import { TranslateService } from '@ngx-translate/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { HotelRoomService } from 'src/app/Services/hotel-room.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-hotel-menu-component',
  templateUrl: './hotel-menu-component.component.html',
  styleUrls: ['./hotel-menu-component.component.scss']
})
export class HotelMenuComponentComponent implements OnInit {
 
  public showMsg:any;
  submenuIndex:number=20;
  private subscription: Subscription = new Subscription();
  public orderNo:any;
  private folderEditMode:any;
  private Productsaved:any=false;
  private folderStatus:any;
  public reportParms:any;      
  public reportFile:string="";
  public Block:any='false';
  public windowTop: number = -100;
  public windowLeft: number = -225;
  public offset = 10;
  public left = this.windowLeft;
  public top = this.windowTop;
  public width = window.innerWidth-40;
  public height = 770;
  hid:any=0;
  public disablelink=true;
  public disablecreditnote=true;
  public disableinvoice=false;
  public disableRefund=true;
  id: number;
  hotelnames:any=null;
  private sub: any;
  constructor( public common:CommonserviceService,private toastr:ToastrService,public api:HotelRoomService,private router: Router, private route: ActivatedRoute,private apiShared: ShareDataService,private translateapi: TranslateService,private flashMessage: FlashMessagesService,@Optional() private cdref: ChangeDetectorRef) 
  {
   
  }
 
  ngOnInit() 
  {
    this.sub = this.route.params.subscribe(params => {
      debugger;
      this.id = +params['hodelcode']; // (+) converts string 'id' to a number
      if(this.id.toString()!=="NaN"){
      sessionStorage.setItem('hotelid',this.id.toString())



      
      }
      // In a real app: dispatch action to load the details here.
   });
   
    this.orderNo=this.apiShared.orderNo;
    this.submenuIndex=+(sessionStorage.getItem('submenuIndex'))
    // if(this.orderNo!="0")
    // this.router.navigate(['/PenAir/Folder/FolderSummary',this.orderNo]);
  }
  public GetHotelname(id:any)
  {
    debugger;
    if(id.toString()!=="NaN"){
      this.subscription.add( this.api.SearchHotelName(id.toString()).subscribe(
        (data) => {
         // console.log(JSON.stringify(data, null, 4));
          debugger;
         // console.log(JSON.stringify(data["ResponseData"], null, 4));
         // this.homecuuency= data["ResponseData"][0].ActualName    
       if(data!=null && data!=undefined)
       {
        this.hotelnames=data["ResponseData"][0].Code+'-'+data["ResponseData"][0].Name;
       }
      
        }));
      }
   
  }
async  MenuClick(value:number,refreshed:boolean)
  {
    debugger;
     this.hid=+(sessionStorage.getItem('hotelid'))
     this.Block=(sessionStorage.getItem('menuvalue'));
     if(this.Block=='true')
     {
      this.subscription.add(this.translateapi.get(['Messages.Leavehotelmenu']).subscribe((translations ) => {
        this.showMsg= translations['Messages.Leavehotelmenu'];
       this.toastr.warning(this.showMsg,"",{
          timeOut:3000,
          disableTimeOut:false,
          closeButton:true
        })
       }));
      return
     }
    
     debugger; 
    if(value==20) {
      sessionStorage.setItem('pagevalue', value.toString())
      if(!refreshed){
        if(this.hid>0){
          this.router.navigate(['/PenAir/Hotel/Details/View',this.hid]);
        }
        else{
          this.router.navigate(['/PenAir/Hotel/Details/Edit',0]);
        }
         this.setvalue(value); 
      }
      this.setvalue(value);
    } else  if(value==21) {
      if ( Boolean(await this.common.checkPermission1("01.11.32.02.03.07")) == true ) {
      sessionStorage.setItem('pagevalue', value.toString())
      if(!refreshed)
         this.router.navigate(['/PenAir/Hotel/Details/roomelist',this.hid])
         this.setvalue(value); 
      }
      else
      {
        this.ShowPermissionMessageForm('01.11.32.02.03.07');//339  
//this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.11.32.02.03.07');
      }
    } else  if(value==22) {
      if ( Boolean(await this.common.checkPermission1("01.14.01")) == true ) {
      sessionStorage.setItem('pagevalue', value.toString())
      if(!refreshed)
      {  
      this.router.navigate(['/PenAir/Hotel/Details/roomratesetup',this.hid])
           this.setvalue(value);
        }
      }
      else
      {
        this.ShowPermissionMessageForm('01.14.01');//339
//this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.14.01');
      }
    } else  if(value==23) {
      if ( Boolean(await this.common.checkPermission1("01.11.32.02.03.08")) == true ) {
      sessionStorage.setItem('pagevalue', value.toString())
      if(!refreshed)
       this.router.navigate(['/PenAir/Hotel/Details/Inventory',this.hid]);
        this.setvalue(value);
      }
      else
      {
        this.ShowPermissionMessageForm('01.11.32.02.03.08');//339
        //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.11.32.02.03.08');
      }
    }
 
   
  }
 public setvalue(value:number)
 {
  this.submenuIndex=value;
  this.common.submenuIndex=value;
  this.apiShared.Setapishareddata(value.toString(),'submenuIndex',)
 }

  onActivate(componentReference) {
     //componentReference.anyFunction();
     //Below will subscribe to the searchItem emitter
     if(componentReference.menuIndex != undefined)
     {
      
      this.subscription.add( componentReference.menuIndex.subscribe((data:number) => {
        if(data==0)
        {
          this.folderEditMode="false";
          var menuindex=sessionStorage.getItem('pagevalue')
          
         
            if(menuindex!=undefined)
            {
              this.MenuClick(+menuindex,this.common.browserRefresh);
            }
            else
            {
              this.MenuClick(0,this.common.browserRefresh)
            }
          
        
         
          this.cdref.detectChanges();
        }
        // Will receive the data from child here 
      }));
    }
    
  }

  public opened = false;

  public open() {
    this.opened = true;
  }
  
public close() {
  this.opened = false;
  
  
}
openMenu()
{
  let toggleMenuButtonElements = document.getElementsByClassName( "__js_menu_button" );

	if( toggleMenuButtonElements.length === 0 )
	console.log( "__js_menu_button classes not found" );

  for( let i = 0; i < toggleMenuButtonElements.length; i++ )
  {
		let menuElement = document.getElementById( "menu_element" );

		if( menuElement === null )
			console.log( "menu_element id not found" );
		else
			menuElement.classList.toggle( "open" );
	}
}
openMoreClickMobile()
{
  let  toggleParentLiElements = document.getElementsByClassName( "moreBtn" );
 
  for( let i = 0; i < toggleParentLiElements.length; i++ )
  { 
  toggleParentLiElements[i].parentElement.classList.toggle( "open" );
  }
}
openAccountsMobile()
{
  let  toggleParentLiElements = document.getElementsByClassName( "accounts_mob" );
  for( let i = 0; i < toggleParentLiElements.length; i++ )
  { 
    toggleParentLiElements[i].parentElement.classList.toggle( "open" );
  }
}
async openAccountsclick()
{
  if(Boolean(await this.common.checkPermission1('01.12.01.09'))==true )
  {
    let moreElement = document.getElementById( "more_element" );
    moreElement.classList.remove( "open" );
  
    let  toggleParentLiElements = document.getElementsByClassName( "accounts" );
    for( let i = 0; i < toggleParentLiElements.length; i++ )
    { 
      toggleParentLiElements[i].parentElement.classList.toggle( "open" );
    }
  }  else {
    this.ShowPermissionMessageForm('01.12.01.09');//339
    //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.09');
  }
}
openMoreclick()
{
  let accountElement = document.getElementById( "account_element" );
  accountElement.classList.remove( "open" );

  let  toggleParentLiElements = document.getElementsByClassName( "morelist" );
  for( let i = 0; i < toggleParentLiElements.length; i++ )
  { 
  toggleParentLiElements[i].parentElement.classList.toggle( "open" );
  }
}
closeMenu()
{
  let menuElement = document.getElementById( "menu_element" );
  menuElement.classList.remove( "open" );
  let accountElement = document.getElementById( "account_element" );
  accountElement.classList.remove( "open" );
  let accountMobElement = document.getElementById( "account_mob_element" );
  accountMobElement.classList.remove( "open" );
  let moreElement = document.getElementById( "more_element" );
  moreElement.classList.remove( "open" );
  let moreMobElement = document.getElementById( "more_mob_element" );
  moreMobElement.classList.remove( "open" );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  public permissionmessageformopened:any;//Caseid-339
public PermissionMessageId:any;//Caseid-339

public PermissionFormClosing(status:any)//339
  {
    if(status=="EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

ShowPermissionMessageForm(permissionid: string) {
  this.permissionmessageformopened=true;//Caseid-339
  this.PermissionMessageId =permissionid;//Caseid-339
}
}
