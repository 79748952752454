
import { Component, OnInit, Input } from '@angular/core';
import {  Alertdetails } from '../../Models/alertdetails';
import { ActivatedRoute, Router } from '@angular/router';
import {InvoiceService} from '../../Services/invoice.service'
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-revenue-recognition-policy',
  templateUrl: './revenue-recognition-policy.component.html',
  styleUrls: ['./revenue-recognition-policy.component.scss']
})
export class RevenueRecognitionPolicyComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  constructor(private api:InvoiceService,private route: ActivatedRoute,private router: Router ) { }
  public gridData: any ;
  ngOnInit() {
    this.subscription.add(this.api.GetInvoiceDetails().subscribe(res=>this.gridData=res.ResponseData));
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
