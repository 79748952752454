
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { EnvService } from "src/env.service";
import { CommonserviceService } from "./commonservice.service";
import { tap, catchError } from "rxjs/operators";
import { FolderActivityReportbyProductDTO, FolderActivityReportbyProductDetailedDTO } from "../Models/folder-activity-reportby-product-dto";

const httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" })
};
@Injectable({
    providedIn: "root"
})

export class FolderActivityReportbyProductService {
    public griddata: any;
    public SummaryGridData: any;
    apiUrl: string;
    public formData: FolderActivityReportbyProductDTO;
    formDataHeader: any = {
        RptHdrSel_AutoNo: 0,
        RptHdrSel_ModuleName: "",
        RptHdrSel_ColumnHeader: "",
        RptHdrSel_UserId: 0,
        RptHdrSel_TemplateName: "",
        RptHdrSel_Lupdate: new Date(),
        RptHdrSel_CustCo: ""
    };
    item: any;
    constructor(
        private http: HttpClient,
        private env: EnvService,
        public commonservice: CommonserviceService
    ) {
        this.apiUrl = env.baseUrl;
    }
    public GetFolderActivityReportbyProduct(): Observable<
        FolderActivityReportbyProductDTO
    > {
        var body = {
            ...this.formData
        };
        return this.http
            .post<FolderActivityReportbyProductDTO>(
                this.apiUrl + "GetFolderActivityReportByProduct",
                body,
                httpOptions
            )
            .pipe(
                tap((FolderActivityReportbyProductDTO: any) =>
                    console.log(`GetFolderActivityReportByProduct`)
                ),
                catchError(this.commonservice.handleError<any>("GetFolderActivityReportbyProduct"))
            );

    }
    public GetTemplate(): Observable<any> {
        var body = {
            ...this.formDataHeader
        };
        return this.http
            .post<FolderActivityReportbyProductDetailedDTO>(
                this.apiUrl + "GetTemplate",
                body,
                httpOptions
            )
            .pipe(
                tap((FolderActivityReportbyProductDetailedDTO: any) =>
                    console.log(`GetTemplate`)
                ),
                catchError(this.commonservice.handleError<any>("GetTemplate"))
            );
    }
    public GetGridColumn(): Observable<any> {
        var body = {
            ...this.formDataHeader
        };
        return this.http
            .post<FolderActivityReportbyProductDetailedDTO>(
                this.apiUrl + "getGridHeader",
                body,
                httpOptions
            )
            .pipe(
                tap((FolderQueuryReportResultDTO: any) =>
                    console.log(`getGridHeader`)
                ),
                catchError(this.commonservice.handleError<any>("getGridHeader"))
            );
    }
    public saveOrUpdateReportHeader(): Observable<any> {
        var body = {
            ...this.formDataHeader
        };
        return this.http
            .post<FolderActivityReportbyProductDetailedDTO>(
                this.apiUrl + "saveOrUpdateReportHeader",
                body,
                httpOptions
            )
            .pipe(
                tap((FolderActivityReportbyProductDetailedDTO: any) =>
                    console.log(`saveOrUpdateReportHeader`)
                ),
                catchError(
                    this.commonservice.handleError<any>("saveOrUpdateReportHeader")
                )
            );
    }
    GetVarientTemplateForEdit(Template: any, modulename: any): Observable<any> {
        const url = `${this.apiUrl}` + 'GetVarientTemplateForEdit' + `/${Template}/${modulename}`;
        return this.http.get<any>(url);
    }
    BranchAuthorisation(branch: any, userID: any): Observable<any> {
        const url = `${this.apiUrl}` + 'BranchAuthorisation' + `/${branch}/${userID}`;
        return this.http.get<any>(url);
    }
    GetReportHeaderFooter(uid: number, folderno: any): Observable<any> {
        const url = `${this.apiUrl}` + "GetCompanyLogo" + `/${uid}/`;
        return this.http.get<any>(url).pipe(
            tap(_ => console.log(`=${folderno}`)),
            catchError(this.commonservice.handleError<any>(` id=${folderno}`))
        );
    }
}
