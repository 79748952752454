import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { ManualSalesDTO, ManualSalesSaveXMLClass, ManualSalesgridDTO, ManualSalesSearchDTO } from '../Models/manual-sales-dto'
import { Observable, of, from } from 'rxjs';
import { HttpHeaders } from "@angular/common/http";
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})

export class ManualsalesService {

  public formvalue: ManualSalesSearchDTO;
  apiUrl: string;
  griddata: any;
  public formData: ManualSalesSaveXMLClass;
  public objManualsalesGrid: ManualSalesgridDTO[];
  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }

  // GetManualSalesList(yearid: any): Observable<ManualSalesDTO[]> {
  //   const url = `${this.apiUrl}` + 'GetManualSalesList' + `/${yearid}`;

  //   return this.http.get<ManualSalesDTO[]>(url).pipe(
  //     tap(_ => console.log(`fetched GetManualSalesList`)),
  //     catchError(this.commonservice.handleError<ManualSalesDTO[]>(`GetManualSalesList`))
  //   );
  // }
  //13436
  GetManualSalesList(ManualSalesSearchDTO: any): Observable<ManualSalesDTO[]> {
    // console.log(ManualSalesSearchDTO);
    // const url = `${this.apiUrl}` + 'GetManualSalesList' + `/${ManualSalesSearchDTO}`;
    // return this.http.get<ManualSalesDTO[]>(url).pipe(
    //   tap(_ => console.log(`fetched GetManualSalesList`)),
    //   catchError(this.commonservice.handleError<ManualSalesDTO[]>(`GetManualSalesList`))
    // );

    var body = ManualSalesSearchDTO;


    return this.http
      .post<ManualSalesSearchDTO>(
        this.apiUrl + "GetManualSalesList",
        body,
        httpOptions
      )
      .pipe(
        tap((ManualSalesDTO: any) =>
          console.log(`GetTemplate`)
        ),
        catchError(this.commonservice.handleError<any>("GetTemplate"))
      );
  }

  GetManualSalesReturnList(ManualSalesSearchDTO: any): Observable<ManualSalesDTO[]> {
    // const url = `${this.apiUrl}` + 'GetManualSalesReturnList' + `/${ManualSalesSearchDTO}`;
    // return this.http.get<ManualSalesDTO[]>(url).pipe(
    //   tap(_ => console.log(`fetched GetManualSalesReturnList`)),
    //   catchError(this.commonservice.handleError<ManualSalesDTO[]>(`GetManualSalesReturnList`))
    // );

    var body = ManualSalesSearchDTO;

    return this.http
      .post<ManualSalesSearchDTO>(
        this.apiUrl + "GetManualSalesReturnList",
        body,
        httpOptions
      )
      .pipe(
        tap((ManualSalesDTO: any) =>
          console.log(`GetTemplate`)
        ),
        catchError(this.commonservice.handleError<any>("GetTemplate"))
      );
  }
  //13436
  // GetManualSalesReturnList(yearid: any): Observable<ManualSalesDTO[]> {
  //   const url = `${this.apiUrl}` + 'GetManualSalesReturnList' + `/${yearid}`;

  //   return this.http.get<ManualSalesDTO[]>(url).pipe(
  //     tap(_ => console.log(`fetched GetManualSalesReturnList`)),
  //     catchError(this.commonservice.handleError<ManualSalesDTO[]>(`GetManualSalesReturnList`))
  //   );
  // }


  GetManualSalesListDetails(BNO: any, TRANSID: any, VRTYPE: any, yearid: any): Observable<ManualSalesgridDTO[]> {
    const url = `${this.apiUrl}` + 'GetManualSalesListDetails' + `/${BNO}` + `/${TRANSID}` + `/${VRTYPE}` + `/${yearid}`;
    return this.http.get<any>(url);
  }
  GetManualSalesReturnListDetails(BNO: any, TRANSID: any, VRTYPE: any, yearid: any): Observable<any> {
    const url = `${this.apiUrl}` + 'GetManualSalesReturnListDetails' + `/${BNO}` + `/${TRANSID}` + `/${VRTYPE}` + `/${yearid}`;
    return this.http.get<any>(url);
  }
  GetManualSalesListDetailsHeaderData(BNO: any, TRANSID: any, VRTYPE: any, yearid: any): Observable<any> {
    const url = `${this.apiUrl}` + 'GetManualSalesListDetailsHeaderData' + `/${BNO}` + `/${TRANSID}` + `/${VRTYPE}` + `/${yearid}`;
    return this.http.get<any>(url);
  }
  GetManualSalesReturnListDetailsHeaderData(BNO: any, TRANSID: any, VRTYPE: any, yearid: any): Observable<any> {
    const url = `${this.apiUrl}` + 'GetManualSalesReturnListDetailsHeaderData' + `/${BNO}` + `/${TRANSID}` + `/${VRTYPE}` + `/${yearid}`;
    return this.http.get<any>(url);
  }
  savemanualsales(): Observable<ManualSalesgridDTO> {
    var body = {
      ...this.formData,
      objManualsalesGrid: this.objManualsalesGrid
    };
    return this.http
      .post<ManualSalesgridDTO>(
        this.apiUrl + "ManualSalesSave",
        body,
        httpOptions
      )
      .pipe(
        tap((railRes: any) => console.log(`added Rail w/ id=`)),
        catchError(
          this.commonservice.handleError<any>("ManualSalesSave")
        )
      );
  }

  savemanualreturnsales(): Observable<ManualSalesgridDTO> {
    var body = {
      ...this.formData,
      objManualsalesGrid: this.objManualsalesGrid
    };
    return this.http
      .post<ManualSalesgridDTO>(
        this.apiUrl + "ManualSalesReturnSave",
        body,
        httpOptions
      )
      .pipe(
        tap((railRes: any) => console.log(`ManualSalesReturnSave`)),
        catchError(
          this.commonservice.handleError<any>("ManualSalesReturnSave")
        )
      );
  }
  GetTaxSplit(TaxType: any): Observable<any> {
    const url = `${this.apiUrl}` + 'GetTaxSplit' + `/${TaxType}`;
    return this.http.get<any>(url);
  }
}

