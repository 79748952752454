
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { InsuranceDetail } from '../../Models/insurance-detail';
import { InsuranceService } from '../../Services/insurance.service';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { ShareDataService } from '../../Services/share-data.service';
import { CurrencyExchangerateService } from '../../Services/currency-exchangerate.service';
import { CurrencyExchangerate } from '../../Models/currency-exchangerate';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription, Subject } from 'rxjs';
import { SupplierMasterService } from '../../Services/supplier-master.service';
import { ToastrService } from 'ngx-toastr';
import { ProductUpdatePODTO } from '../../Models/pocreation-detail';
import { PocreationService } from '../../Services/pocreation.service';
import { FolderpriceService } from '../../Services/folderprice.service';

@Component({
  selector: 'app-insurance-details-edit',
  templateUrl: './insurance-details-edit.component.html',
  styleUrls: ['./insurance-details-edit.component.scss']
})
export class InsuranceDetailsEditComponent implements OnInit {
  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  private subscription: Subscription = new Subscription();
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  @ViewChild("apppaymenttermsedit", { read: false, static: false }) public payterm: any;
  @ViewChild("appfchcdetailsedit", { read: false, static: false }) public fchcdetails: any;
  public opened = false;
  openSearch = false;
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  disableTextbox = true;
  currencyExrate: CurrencyExchangerate;
  ucode: string;
  public PONO;
  insuranceEditForm: FormGroup;
  prdtUpdatePODTO: ProductUpdatePODTO;
  submitted = false;
  loaded = false;
  folderEditMode: any;
  folderExrate: any;
  fldrCurrency: any;
  public showMsg: any;
  Uname: any = "";
  public pocreated = true;
  savebutton = true;
  bookingDate: any //337
  fromDate: any //337
  toDate: any //337
  depositDueDate: any//337 
  creditLimitMsg = false;
  creditLimiFlag = false;
  msg: any;
  FC_Tot_BuyInitalvalue: any = 0;
  FCSell: any = 0;
  public statusList: Array<string> = ["OK", "Pending", "Cancelled", "Confirmed"];
  public commissinOnList: Array<string> = ["Purchase", "Sales", "Gross"];
  public passengerTypeList: Array<string> = ["Adult", "Youth", "Child", "Infant"];
  constructor(private apiPO: PocreationService, public common: CommonserviceService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private api: InsuranceService, private lookupApi: LookUpDetailsService,
    private apiShared: ShareDataService, private apiExRate: CurrencyExchangerateService, private flashMessage: FlashMessagesService, private translateapi: TranslateService, private spinner: NgxSpinnerService,
    private supplier: SupplierMasterService, private toastr: ToastrService, private objFolderPrice: FolderpriceService) { }
  private guid: string = this.route.snapshot.params['guidItinerary'];//'Insurance.20190826.103628.{B9CB75FE-A3D6-46DE-926E-27990C46ECEC}/';
  private orderno = this.route.snapshot.params['orderNo']

  Insurance: Array<LookUpDetails["ResponseData"]> = [];
  public InsuranceselectedItem: LookUpDetails["ResponseData"];

  Currency: Array<LookUpDetails["ResponseData"]> = [];
  public CurrencyselectedItem: LookUpDetails["ResponseData"];

  public BookedByselectedItem: LookUpDetails["ResponseData"];
  BookedBy: Array<LookUpDetails["ResponseData"]> = [];

  public PassengerselectedItem: LookUpDetails["ResponseData"];
  Passenger: Array<LookUpDetails["ResponseData"]> = [];

  SupplierList: Array<LookUpDetails["ResponseData"]> = [];
  public supplierSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelected: String;
  public projNo: any;
  lockStatus: any;
  folderStatus: any;
  formeditable: boolean = true;
  hcExRate: any;
  homeCurrency: any;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  ngOnInit() {
    //case id : 13777 
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    } else {
      this.formeditable = false;

    }
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;

    }
    // case id : 13777

    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderno !== orderno) {
        return this.router.navigate(['PenAir/Home'], { skipLocationChange: true });
      }
    this.hcExRate = this.apiShared.hcExRate;
    this.homeCurrency = this.apiShared.homeCurrency;
    this.folderEditMode = "true";
    //this.apiShared.folderEditMode=this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.Uname = this.apiShared.uname;
    this.getFolderValues();
    this.insuranceEditForm = this.formBuilder.group({


      FOLDERNO: [this.orderno],
      GUIDItinerary: [''],
      SUPPRTYCODE: ['', Validators.required],
      SUPPLIER: [''],
      PASSENGER: [''],
      PassengerType: [''],
      TYPE: [''],
      // InsTypeName:data.ResponseData.InsTypeName, 
      REGION: [''],
      DATEF: [new Date()],
      DATET: [new Date()],
      DAYS: [0],
      BOOKEDBY: this.Uname,
      BookingDate: [new Date()],
      InsurVAT: [0],
      CURCODE: [this.homeCurrency],
      InsurCOMM: [0],
      Currency: [''],
      CURExRate: [this.hcExRate],
      AMTLC: [0],
      AMTHC: [0],
      PAYMODE: [''],
      LOYALTYPOINTSMEMBNO: [''],
      LOYALTYPOINTS: [0],
      LOYALTYPOINTSAMT: [0],
      PNUMBER: [''],
      PNR: [''],
      VOUCHER: [''],
      CONFIRMATIONNO: [''],
      CommOn: ['Purchase'],
      STATUS: ['OK'],
      FCBuy: [0],
      PAYABLE: [0],
      FCSell: [0],
      SELLTAXNO: [0],
      HC_SELLTAXAMT: [0],
      HCSell: [0],
      HCCommAmt: [0],
      SELLTAXRATE: [0],
      HCCommPer: [0],
      FC_SELLTAXAMT: [0],
      FCCommPer: [0],
      FCCommAmt: [0],
      ITYPE: ['Insurance'],
      ItnryNotes: [''],
      LowFB: [''],
      LowFare: [0],
      HighFB: [''],
      HighFare: [0],
      Fareoffered: [0],
      PotentialSaving: [0],
      ActualSaving: [0],
      PDNO: [0],
      PDTYPENO: [0],
      Forgone: [0],
      AddRemarks: [''],
      PDAMOUNT: [0],
      PONO: [0],
      PONOYEARID: [0],
      PAYMENTID: [0],
      PMTID: [''],
      DEFDEPPER: [0],
      DEFDEP: [0],
      DepositDueDate: [''],
      BalanceDueDate: [''],
      BalanceDueAmount: [0],
      GDS: [''],
      TOMSVATTAXNO: [0],
      TOMSVATAMT: [0],
      PDUNIQUEREF: [''],

      PDFCURID: [this.homeCurrency],
      PDEXRATE: [this.hcExRate],
      PDFCAMT: [0],
      INSDATE: [new Date()],
      PONOCCDeferredPosted: [0],
      GROSS: [0],
      TaxExcludingSalesTax: [0],
      BaseFare: [0],
      PROJNO: [this.projNo],
      GSTHSTOnComm: [0],
      //12698
      CostingSupplierGrossValue: 0,
      CostingSupplierServiceFee: 0,
      CostingTaxNo: 0,
      CostingTaxRate: 0,
      CostingSupplierTaxAmount: 0,
      CostingSupplierNetBuyPrice: 0,
      CostingCustomerSalesValue: 0,
      CostingCustomerServiceFee: 0,
      CostingCustomerTaxAmount: 0,
      CostingCustomerNetSellPrice: 0,
      CostingVATType: 0,
      Markup: [0],
      CostofSale: [0],
      CostingSuppComm: [0],
      SupplierDeposit: [0],
      CountryID: ['']
      //12698
    });


    if (this.guid != null)
      this.getInsuranceDetails(this.orderno, this.guid);
    if (this.guid == null) {
      this.FormLoyalityPointsChange();
      this.loaded = true;
    }

    this.subscription.add(this.lookupApi.GetAllLookup('INSURANCE').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.Insurance = data["ResponseData"]
          if (this.insuranceEditForm.controls.TYPE.value != "") {

            this.InsuranceselectedItem = this.Insurance.find(item => item.Code == this.insuranceEditForm.controls.TYPE.value);

            //console.log("test:-"+this.insuranceEditForm.controls.TYPE.value+JSON.stringify(this.InsuranceselectedItem , null, 4));
          }
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('CURRENCY').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.Currency = data["ResponseData"]
          if (this.insuranceEditForm.controls.CURCODE.value != "") {

            this.CurrencyselectedItem = this.Currency.find(item => item.Code == this.insuranceEditForm.controls.CURCODE.value);

            // console.log("test:-"+this.insuranceEditForm.controls.CURCODE.value+JSON.stringify(this.CurrencyselectedItem , null, 4));
          }
        }
      }
    ));

    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.BookedBy = data["ResponseData"]
          if (this.insuranceEditForm.controls.BOOKEDBY.value != "") {

            this.BookedByselectedItem = this.BookedBy.find(item => item.Name == this.insuranceEditForm.controls.BOOKEDBY.value);

            // console.log("test:-"+this.insuranceEditForm.controls.BOOKEDBY.value+JSON.stringify(this.InsuranceselectedItem , null, 4));
          }
        }
      }
    ));
    // this.lookupApi.GetAllLookup('PASSENGER').subscribe(
    this.subscription.add(this.lookupApi.GetLookupById('PASSENGERMASTER', this.orderno, "''").subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.Passenger = data["ResponseData"]
          if (this.insuranceEditForm.controls.PASSENGER.value != "") {

            this.PassengerselectedItem = this.Passenger.find(item => item.Name == this.insuranceEditForm.controls.PASSENGER.value);

            //console.log("test:-"+this.insuranceEditForm.controls.TYPE.value+JSON.stringify(this.InsuranceselectedItem , null, 4));
          }
        }
      }
    ));



    // this.lookupApi.GetLookupById('PASSENGERMASTER',this.orderno,'').subscribe(
    //   (data) => {

    //      this.SupplierList= data["ResponseData"]      
    //     //// alert("dd"+this.carEditForm.controls.SUPPRTYCODE.value)     ;
    //      if(this.insuranceEditForm.controls.SUPPRTYCODE.value!="")
    //      {

    //      this.supplierSelectedItem =this.SupplierList.find(item => item.Code ==this.insuranceEditForm.controls.SUPPRTYCODE.value);
    //      this.supplierSelected=this.supplierSelectedItem.Name;

    //     }
    //   }
    //   );

  }



  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860
  public CurrencyExRatechange(): void {
    this.CalculateForm();
  }
  loadsuppliermaster() {
    if (this.insuranceEditForm.controls.SUPPRTYCODE.value != '' && this.insuranceEditForm.controls.SUPPRTYCODE.value != null) {
      this.subscription.add(this.lookupApi.findSupplier(this.insuranceEditForm.controls.SUPPRTYCODE.value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
            if (this.insuranceEditForm.controls.SUPPRTYCODE.value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.insuranceEditForm.controls.SUPPRTYCODE.value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
                this.insuranceEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID);
              }

            }
          }
        }
      ));
    }
  }
  handleFiltersupplier(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  getFolderValues() {

    // this.apiShared.folderCurrency.subscribe(fldrCurrency => this.fldrCurrency = fldrCurrency)
    // this.apiShared.folderExchangeRate.subscribe(folderExrate => this.folderExrate = folderExrate)
    // this.exRate=new Number(this.folderExrate);
    // this.apiShared.projNo.subscribe(projNo => this.projNo = projNo)
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    this.exRate = new Number(this.folderExrate);
    this.projNo = this.apiShared.projNo;
  }

  // convenience getter for easy access to form fields
  get f() { return this.insuranceEditForm.controls; }

  getInsuranceDetails(id, guid) {
    this.spinner.show();
    this.subscription.add(this.api.getInsurance(id, guid, false)
      .subscribe(data => {
        if (data != null && data != undefined) {
          //this.insuranceEditForm = this.formBuilder.group(data);          
          this.insuranceEditForm.setValue({
            FOLDERNO: data.ResponseData.FOLDERNO,
            GUIDItinerary: data.ResponseData.GUIDItinerary,

            SUPPRTYCODE: data.ResponseData.SUPPRTYCODE,
            SUPPLIER: data.ResponseData.SUPPLIER,
            PASSENGER: data.ResponseData.PASSENGER,
            PassengerType: data.ResponseData.PassengerType,
            TYPE: data.ResponseData.TYPE,
            // InsTypeName:data.ResponseData.InsTypeName, 
            REGION: data.ResponseData.REGION,
            DATEF: data.ResponseData.DATEF != null ? (data.ResponseData.DATEF.toString() === '1900-01-01T00:00:00' ? null : new Date(data.ResponseData.DATEF)) : null,
            DATET: data.ResponseData.DATET != null ? (data.ResponseData.DATET.toString() === '1900-01-01T00:00:00' ? null : new Date(data.ResponseData.DATET)) : null,
            DAYS: data.ResponseData.DAYS,
            BOOKEDBY: data.ResponseData.BOOKEDBY,
            BookingDate: data.ResponseData.BookingDate != null ? (data.ResponseData.BookingDate.toString() === '1900-01-01T00:00:00' ? null : new Date(data.ResponseData.BookingDate)) : null,
            PAYABLE: data.ResponseData.PAYABLE,
            CURCODE: data.ResponseData.CURCODE,
            Currency: data.ResponseData.CURCODE,
            CURExRate: data.ResponseData.CURExRate,
            AMTLC: data.ResponseData.AMTLC,
            AMTHC: data.ResponseData.AMTHC,
            PAYMODE: data.ResponseData.PAYMODE,
            LOYALTYPOINTSMEMBNO: data.ResponseData.LOYALTYPOINTSMEMBNO,
            LOYALTYPOINTS: data.ResponseData.LOYALTYPOINTS,
            LOYALTYPOINTSAMT: data.ResponseData.LOYALTYPOINTSAMT,
            PNUMBER: data.ResponseData.PNUMBER,
            FCSell: data.ResponseData.FCSell,
            VOUCHER: data.ResponseData.VOUCHER,
            CONFIRMATIONNO: data.ResponseData.CONFIRMATIONNO,
            CommOn: data.ResponseData.CommOn,
            FCBuy: data.ResponseData.FCBuy,
            // FCSell:data.ResponseData.FCSell,
            //FCBuy:data.ResponseData.FCBuy,
            STATUS: data.ResponseData.STATUS,
            SELLTAXNO: data.ResponseData.SELLTAXNO,

            HC_SELLTAXAMT: data.ResponseData.HC_SELLTAXAMT,
            HCSell: data.ResponseData.HCSell,
            HCCommAmt: data.ResponseData.HCCommAmt,
            SELLTAXRATE: data.ResponseData.SELLTAXRATE,
            HCCommPer: data.ResponseData.HCCommPer,

            FC_SELLTAXAMT: data.ResponseData.FC_SELLTAXAMT,




            INSDATE: new Date(data.ResponseData.INSDATE),


            GROSS: data.ResponseData.GROSS,




            // SLNO:data.ResponseData.SLNO,
            PROJNO: data.ResponseData.PROJNO,
            ITYPE: "Insurance",
            GDS: data.ResponseData.GDS,
            PNR: data.ResponseData.PNR,
            InsurVAT: data.ResponseData.InsurVAT,
            InsurCOMM: data.ResponseData.InsurCOMM,



            FCCommPer: data.ResponseData.FCCommPer,
            FCCommAmt: data.ResponseData.FCCommAmt,

            ItnryNotes: data.ResponseData.ItnryNotes,
            LowFB: data.ResponseData.LowFB,
            LowFare: data.ResponseData.LowFare,
            HighFB: data.ResponseData.HighFB,
            HighFare: data.ResponseData.HighFare,
            Fareoffered: data.ResponseData.Fareoffered,
            PotentialSaving: data.ResponseData.PotentialSaving,
            ActualSaving: data.ResponseData.ActualSaving,
            PDNO: data.ResponseData.PDNO,
            PDTYPENO: data.ResponseData.PDTYPENO,
            Forgone: data.ResponseData.Forgone,
            AddRemarks: data.ResponseData.AddRemarks,
            PDAMOUNT: data.ResponseData.PDAMOUNT,
            PONO: data.ResponseData.PONO,
            PONOYEARID: data.ResponseData.PONOYEARID,
            PAYMENTID: data.ResponseData.PAYMENTID,
            PMTID: data.ResponseData.PMTID,
            DEFDEPPER: data.ResponseData.DEFDEPPER,
            DEFDEP: data.ResponseData.DEFDEP,
            DepositDueDate: data.ResponseData.DepositDueDate != null ? (data.ResponseData.DepositDueDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.DepositDueDate)) : null,
            //DepositDueDate :new Date(this.FormatDate(data.ResponseData.DepositDueDate)),
            //  BalanceDueDate:new Date(this.FormatDate(data.ResponseData.BalanceDueDate)),
            BalanceDueDate: data.ResponseData.BalanceDueDate != null ? (data.ResponseData.BalanceDueDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.BalanceDueDate)) : null,
            BalanceDueAmount: data.ResponseData.BalanceDueAmount,

            TOMSVATTAXNO: data.ResponseData.TOMSVATTAXNO,
            TOMSVATAMT: data.ResponseData.TOMSVATAMT,
            PDUNIQUEREF: data.ResponseData.PDUNIQUEREF,

            PDFCURID: data.ResponseData.PDFCURID,
            PDEXRATE: data.ResponseData.PDEXRATE,
            PDFCAMT: data.ResponseData.PDFCAMT,

            PONOCCDeferredPosted: data.ResponseData.PONOCCDeferredPosted,

            TaxExcludingSalesTax: data.ResponseData.TaxExcludingSalesTax,
            BaseFare: data.ResponseData.BaseFare,
            GSTHSTOnComm: 0,
            //12698
            CostingSupplierGrossValue: data.ResponseData.CostingSupplierGrossValue,
            CostingSupplierServiceFee: data.ResponseData.CostingSupplierServiceFee,
            CostingTaxNo: data.ResponseData.CostingTaxNo,
            CostingTaxRate: data.ResponseData.CostingTaxRate,
            CostingSupplierTaxAmount: data.ResponseData.CostingSupplierTaxAmount,
            CostingSupplierNetBuyPrice: data.ResponseData.CostingSupplierNetBuyPrice,
            CostingCustomerSalesValue: data.ResponseData.CostingCustomerSalesValue,
            CostingCustomerServiceFee: data.ResponseData.CostingCustomerServiceFee,
            CostingCustomerTaxAmount: data.ResponseData.CostingCustomerTaxAmount,
            CostingCustomerNetSellPrice: data.ResponseData.CostingCustomerNetSellPrice,
            CostingVATType: data.ResponseData.CostingVATType,
            Markup: data.ResponseData.CostingCustomerServiceFee,
            CostofSale: data.ResponseData.CostingCustomerSalesValue,
            CostingSuppComm: data.ResponseData.CostingSuppComm,
            SupplierDeposit: data.ResponseData.SupplierDeposit,
            CountryID: ['']

            //12698
          });
          this.PONO = this.insuranceEditForm.controls.PONO.value;
          this.FC_Tot_BuyInitalvalue = this.insuranceEditForm.controls.FCBuy.value
          this.FCSell = this.insuranceEditForm.controls.FCSell.value;
          if (this.PONO > 0) {

            this.pocreated = false;
          }
          else {
            this.pocreated = true;
          }

        }
        else {
          this.spinner.hide();
        }

        if (this.insuranceEditForm.controls.TYPE.value != "") {

          this.InsuranceselectedItem = this.Insurance.find(item => item.Code == this.insuranceEditForm.controls.TYPE.value);

          //console.log("test:-"+this.insuranceEditForm.controls.TYPE.value+JSON.stringify(this.InsuranceselectedItem , null, 4));
        }
        if (this.insuranceEditForm.controls.CURCODE.value != "") {

          this.CurrencyselectedItem = this.Currency.find(item => item.Code == this.insuranceEditForm.controls.CURCODE.value);

          // console.log("test:-"+this.insuranceEditForm.controls.CURCODE.value+JSON.stringify(this.CurrencyselectedItem , null, 4));
        }
        if (this.insuranceEditForm.controls.PASSENGER.value != "") {

          this.PassengerselectedItem = this.Passenger.find(item => item.Name == this.insuranceEditForm.controls.PASSENGER.value);

          //console.log("test:-"+this.insuranceEditForm.controls.TYPE.value+JSON.stringify(this.InsuranceselectedItem , null, 4));
        }
        if (this.insuranceEditForm.controls.SUPPRTYCODE.value != "") {

          this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.insuranceEditForm.controls.SUPPRTYCODE.value);
          if (this.supplierSelectedItem != null)
            this.supplierSelected = this.supplierSelectedItem.Name;


        }
        this.spinner.hide();
        this.loaded = true;
        this.FormLoyalityPointsChange();
        this.loadsuppliermaster();
      }));
  }

  async onFormSubmit() {
    this.spinner.show();
    if (this.insuranceEditForm.controls.PAYMODE.value == "Company Card")//By case id-355
    {
      if ((this.insuranceEditForm.controls.PDAMOUNT.value == 0) || (this.insuranceEditForm.controls.PDNO.value == "0") || (this.insuranceEditForm.controls.PDTYPENO.value == "0")) {
        this.subscription.add(this.translateapi.get(['Messages.PleaseSelectCompanyCardWithPaymentOption']).subscribe((translations) => {

          this.showMsg = translations['Messages.PleaseSelectCompanyCardWithPaymentOption'];;
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        this.spinner.hide();
        return;
      }
    }

    this.bookingDate = (this.insuranceEditForm.controls.BookingDate.value == null || this.insuranceEditForm.controls.BookingDate.value == "" ? "01-Jan-1900" : this.insuranceEditForm.controls.BookingDate.value);//Case id-337
    this.fromDate = (this.insuranceEditForm.controls.DATEF.value == null || this.insuranceEditForm.controls.DATEF.value == "" ? "01-Jan-1900" : this.insuranceEditForm.controls.DATEF.value);//Case id-337
    this.toDate = (this.insuranceEditForm.controls.DATET.value == null || this.insuranceEditForm.controls.DATET.value == "" ? "01-Jan-1900" : this.insuranceEditForm.controls.DATET.value);//Case id-337
    this.depositDueDate = (this.insuranceEditForm.controls.DepositDueDate.value == null || this.insuranceEditForm.controls.DepositDueDate.value == "" ? "01-Jan-1900" : this.insuranceEditForm.controls.DepositDueDate.value);//Case id-337
    if (Boolean(this.common.ValidDateRange(this.bookingDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidBookingDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidBookingDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.fromDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidFromDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.toDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidToDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }

    if (Boolean(this.common.ValidDateRange(this.depositDueDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDepositDueDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDepositDueDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(await this.common.checkPermission1('01.12.01.11')) == true) {
      if (this.insuranceEditForm.value['CURExRate'] === '' || this.insuranceEditForm.value['CURExRate'] === 0 || this.insuranceEditForm.value['CURExRate'] === null) {
        this.subscription.add(this.translateapi.get(['Messages.ExchangeRateError']).subscribe((translations) => {
          this.showMsg = translations['Messages.ExchangeRateError'];
          this.toastr.warning(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        this.spinner.hide();
        return;
      }
      this.submitted = true;

      //console.log(this.insuranceEditForm.get('FOLDERNO').value);
      // stop here if form is invalid
      if (this.insuranceEditForm.invalid) {
        this.spinner.hide();
        return;
      }
      this.objFolderPrice
        .CreditLimitCheck(this.guid == null || undefined ? "0" : this.guid, 0, this.orderno, this.insuranceEditForm.get("HCSell").value)
        .subscribe(res => {
          if (res != undefined || res["ResponseData"] != null) {
            if (
              res["ResponseData"].value != "0" &&
              res["ResponseData"].Status != "0" &&
              res["ResponseData"].ErrorMessage != ""
            ) {
              debugger;
              if (res["ResponseData"].value == 1) {
                this.spinner.hide();
                this.msg = res["ResponseData"].ErrorMessage;
                this.creditLimiFlag = true;
                this.creditLimitMsg = true;

                return false;
              } else if (res["ResponseData"].value == 2) {
                this.spinner.hide();
                this.msg = res["ResponseData"].ErrorMessage;
                this.creditLimiFlag = false;
                this.creditLimitMsg = true;
                // this.AirTicketSaveAndUpdate();
              } else {
                this.InsuranceDetailSaveandUpdate();
              }
            } else {
              this.InsuranceDetailSaveandUpdate();
            }
          }
        });

    } else {
      this.ShowPermissionMessageForm('01.12.01.11');//339
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.11');
    }

    //alert('SUCCESS2!! :-)\n\n' + JSON.stringify(this.InsuranceDetailModal, null, 4));
    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.insuranceEditForm.value, null, 4));
  }
  InsuranceDetailSaveandUpdate() {
    this.insuranceEditForm.controls.BaseFare.setValue(this.insuranceEditForm.controls.FCSell.value);

    if (this.guid != null && this.PONO > 0) {
      this.prdtUpdatePODTO = new ProductUpdatePODTO();
      this.prdtUpdatePODTO.FOLDERNO = this.orderno;
      this.prdtUpdatePODTO.GUIDItinerary = this.insuranceEditForm.get('GUIDItinerary').value;
      this.prdtUpdatePODTO.LOYALTYPOINTS = this.insuranceEditForm.get('LOYALTYPOINTS').value;
      this.prdtUpdatePODTO.LOYALTYPOINTSAMT = this.insuranceEditForm.get('LOYALTYPOINTSAMT').value
      //this.prdtUpdatePODTO.LOYALTYPOINTSBAL=this.insuranceEditForm.get('LOYALTYPOINTSBAL').value
      this.prdtUpdatePODTO.LOYALTYPOINTSMEMBNO = this.insuranceEditForm.get('LOYALTYPOINTSMEMBNO').value;
      this.prdtUpdatePODTO.TYPE = "Insurance";

      this.prdtUpdatePODTO.BaseFare = this.insuranceEditForm.get('BaseFare').value;
      this.prdtUpdatePODTO.SELLAMT = this.insuranceEditForm.get('FCSell').value;
      this.prdtUpdatePODTO.SELLCOMM = this.insuranceEditForm.get('HCCommAmt').value;

      this.prdtUpdatePODTO.HCCommAmt = this.insuranceEditForm.get('HCCommAmt').value;

      // this.prdtUpdatePODTO.fCSellTax=this.hotelEditForm.get('RDESC').value;

      this.prdtUpdatePODTO.SELLTAXNO = this.insuranceEditForm.get('SELLTAXNO').value;
      this.prdtUpdatePODTO.SELLTAXRATE = this.insuranceEditForm.get('SELLTAXRATE').value;

      this.prdtUpdatePODTO.PAYABLE = this.insuranceEditForm.get('PAYABLE').value;
      this.prdtUpdatePODTO.FC_CIDExRate = this.insuranceEditForm.get('CURExRate').value;
      this.prdtUpdatePODTO.FC_SELLTAXAMT = this.insuranceEditForm.get('FC_SELLTAXAMT').value;
      this.prdtUpdatePODTO.CostingCustomerNetSellPrice = this.insuranceEditForm.get('CostingCustomerNetSellPrice').value;
      this.prdtUpdatePODTO.CostingCustomerServiceFee = this.insuranceEditForm.get('CostingCustomerServiceFee').value;
      this.prdtUpdatePODTO.CostingCustomerTaxAmount = this.insuranceEditForm.get('CostingCustomerTaxAmount').value;
      //this.apiPO.formData=
     
      this.apiPO.formData = this.prdtUpdatePODTO;
      this.subscription.add(this.apiPO.SaveProductAfterPO()
        .subscribe(res => {
          this.spinner.hide();
          if (res.IsSuccess === true && res.Message === 'Success') {
            this.spinner.hide();
            this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
              this.showMsg = translations['Messages.saved_success'];
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }));
            this.router.navigate(['/PenAir/Product/InsuranceDetails', this.orderno, this.guid], { skipLocationChange: true });
          } else {
            this.spinner.hide();
            this.toastr.warning(res.ResponseData.ErrorMessage, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          }
        }, (err) => {
          this.spinner.hide();
          console.log(err);
          this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
            this.showMsg = translations['Messages.some_error_in_save'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
        }
        ));

    }


    else {

      //this.InsuranceDetailModal= Object.assign({}, this.insuranceEditForm.value);
      if (this.guid != null) {
        this.spinner.show();

        if (this.CompanyCardValidation() == true) {
          this.subscription.add(this.api.updateInsurance(this.insuranceEditForm.value)
            .subscribe(res => {
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  this.spinner.hide();
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));
                  this.router.navigate(['/PenAir/Product/InsuranceDetails', this.orderno, this.guid + '/'], { skipLocationChange: true });
                }
                else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
              else {
                this.spinner.hide();
              }
            }, (err) => {
              this.spinner.hide();
              console.log(err);
              this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                this.showMsg = translations['Messages.some_error_in_save'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
            }
            ));
        }
        else {
          this.spinner.hide();
        }
      }
      else {
        this.spinner.show();
        if (this.CompanyCardValidation() == true) {
          this.subscription.add(this.api.addInsurance(this.insuranceEditForm.value)
            .subscribe(res => {
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  this.spinner.hide();
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));
                  this.common.isfinalsaved = false;
                  sessionStorage.setItem('pagevalue', '0');
                  var folderQuotes = sessionStorage.getItem('folderQuotes');
                  if (folderQuotes) {
                    this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno], { skipLocationChange: true });
                  } else {
                    this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno], { skipLocationChange: true });
                  }
                }
                else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
              else {
                this.spinner.hide();
              }
            }, (err) => {
              this.spinner.hide();
              console.log(err);
              this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                this.showMsg = translations['Messages.some_error_in_save'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
            }
            ));
        }
        else {
          this.spinner.hide();
        }
      }
    }
  }
  public open() {

    this.opened = true;
  }
  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
    this.openSearch = false;
  }
  onCancel() {
    if (this.guid != "" && this.guid != null)
      this.router.navigate(['/PenAir/Product/InsuranceDetails', this.orderno, this.guid + '/'], { skipLocationChange: true });
    else {
      this.common.isfinalsaved = false;
      sessionStorage.setItem('pagevalue', '0');
      var folderQuotes = sessionStorage.getItem('folderQuotes');
      if (folderQuotes) {
        this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno], { skipLocationChange: true });
      } else {
        this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno], { skipLocationChange: true });
      }
    }
    //this.router.navigate(['/PenAir/Folder/Products',this.orderno],{skipLocationChange:true});

  }
  onReset() {
    this.submitted = false;
    this.insuranceEditForm.reset();
  }

  public InsuranceChange(value: any): void {


    if (value != null && value != undefined)
      this.insuranceEditForm.controls.TYPE.setValue(value.Code);
    else
      this.insuranceEditForm.controls.TYPE.setValue("");
  }
  public CurrencyChange(value: any): void {

    if (value != null && value != undefined)
      this.insuranceEditForm.controls.CURCODE.setValue(value.Code);
    else
      this.insuranceEditForm.controls.CURCODE.setValue("");
  }
  public BookedByChange(value: any): void {
    if (value != null && value != undefined)
      this.insuranceEditForm.controls.BOOKEDBY.setValue(value.Name);
    else
      this.insuranceEditForm.controls.BOOKEDBY.setValue("");
  }
  public PassengerChange(value: any): void {
    if (value != null && value != undefined) {
      this.insuranceEditForm.controls.PASSENGER.setValue(value.Name);
      this.insuranceEditForm.controls.PassengerType.setValue(value.UsrCode);
    }
    else {
      this.insuranceEditForm.controls.PASSENGER.setValue("");
      this.insuranceEditForm.controls.PassengerType.setValue("");
    }
  }
  SupplierDefaultvalue(suppcode) {
    this.subscription.add(this.supplier.GetDefaultValue(suppcode)
      .subscribe(data => {
        if (data !== '' && data !== null && data !== undefined) {
          // this.insuranceEditForm.controls.CURCODE.setValue(data.CURRENCYCODE);
          this.insuranceEditForm.controls.PAYMENTID.setValue(data.PAYTERMID);
          this.insuranceEditForm.controls.PMTID.setValue(data.PAYMTDID);
          this.resetChildForm();

          // if(this.insuranceEditForm.controls.CURCODE.value!=""&&this.insuranceEditForm.controls.CURCODE.value!=null)
          // {   
          //   this.CurrencyselectedItem =this.Currency.find(item => item.Code ===this.insuranceEditForm.controls.CURCODE.value );     
          //   this.getCurrencyExRate();
          // }
        }
      }));
  }
  resetChildForm() {
    this.resetFormSubject.next(true);
  }
  FormLoyalityPointsChange() {
    if (this.insuranceEditForm.controls.LOYALTYPOINTSMEMBNO.value != "" && this.insuranceEditForm.controls.LOYALTYPOINTSMEMBNO.value != null) {


      this.insuranceEditForm.get('LOYALTYPOINTS').enable();
      this.insuranceEditForm.get('LOYALTYPOINTSAMT').enable();
      // this.disableTextbox =  false;
    }
    else {
      this.insuranceEditForm.controls.LOYALTYPOINTS.setValue(0);
      this.insuranceEditForm.controls.LOYALTYPOINTSAMT.setValue(0);
      this.insuranceEditForm.get('LOYALTYPOINTS').disable();
      this.insuranceEditForm.get('LOYALTYPOINTSAMT').disable();

    }
  }
  onChanges(): void {

  }



  changefn() {



    if (this.ucode == "")
      this.insuranceEditForm.controls.SUPPNAME.setValue("");
    let toArray = this.ucode.split("-");
    //this.insuranceEditForm.controls.SUPPRTYUSERCODE.setValue(toArray[0]);
    if (toArray.length > 1)
      if (toArray[1].trim() != '')
        this.insuranceEditForm.controls.SUPPNAME.setValue(toArray[1]);
    // });
  }
  private buyExRate: number;
  private fCBuy: number;
  private amount: number;
  private balanceDueAmount: number;
  private defaultDepositPer: number;
  private exRate: any;
  private hcBuy: any;
  CalculateForm() {

    this.buyExRate = (this.insuranceEditForm.get('CURExRate').value == "" || this.insuranceEditForm.get('CURExRate').value == 0) ? 1 : this.insuranceEditForm.get('CURExRate').value;
    if ((this.insuranceEditForm.get('AMTLC') != null) && this.insuranceEditForm.get('AMTLC').value != 0 && this.insuranceEditForm
      .get('AMTLC').value != "undefined") {

      this.fCBuy = this.insuranceEditForm.controls.AMTLC.value;
      this.insuranceEditForm.controls.FCBuy.setValue(this.fCBuy);
      this.insuranceEditForm.controls.PAYABLE.setValue(this.fCBuy * this.buyExRate);
      this.insuranceEditForm.controls.AMTHC.setValue(this.fCBuy * this.buyExRate);
    }
    else {
      this.insuranceEditForm.controls.PAYABLE.setValue(this.insuranceEditForm.get('FCBuy').value * this.buyExRate);
    }
    if (this.insuranceEditForm.get('AMTLC').value == 0) {
      this.insuranceEditForm.controls.AMTHC.setValue(0);
    }
    this.hcBuy = this.insuranceEditForm.get('PAYABLE').value;

    this.insuranceEditForm.controls.HCSell.setValue(this.insuranceEditForm.get('FCSell').value * this.exRate);
    this.insuranceEditForm.controls.HCCommAmt.setValue(this.insuranceEditForm.get('HCSell').value - this.insuranceEditForm.get('PAYABLE').value);
    ///Payment Terms

    this.amount = (this.insuranceEditForm.get('DEFDEP').value == "" || this.insuranceEditForm.get('DEFDEP').value == 0) ? 0 : this.insuranceEditForm.get('DEFDEP').value;
    this.defaultDepositPer = (this.insuranceEditForm.get('DEFDEPPER').value == "" || this.insuranceEditForm.get('DEFDEPPER').value == 0) ? 0 : this.insuranceEditForm.get('DEFDEPPER').value;

    if (this.defaultDepositPer != 0 && this.defaultDepositPer != 0.00)
      this.amount = (this.hcBuy * this.defaultDepositPer) / 100;

    this.balanceDueAmount = this.hcBuy - this.amount
    this.insuranceEditForm.controls.DEFDEP.setValue(this.amount);
    this.insuranceEditForm.controls.BalanceDueAmount.setValue(this.balanceDueAmount);
    //Corperate Info


    this.insuranceEditForm.controls.ActualSaving.setValue(this.insuranceEditForm.get('PAYABLE').value * -1);
  }
  public getCurrencyExRate() {

    this.bookingDate = (this.insuranceEditForm.controls.BookingDate.value == null || this.insuranceEditForm.controls.BookingDate.value == "" ? "01-Jan-1900" : this.insuranceEditForm.controls.BookingDate.value);//Case id-337

    if (Boolean(this.common.ValidDateRange(this.bookingDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidBookingDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidBookingDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }

    if (this.insuranceEditForm.controls.CURCODE.value != '' && this.insuranceEditForm.controls.CURCODE.value != undefined)
      this.getExchangeRate(this.insuranceEditForm.controls.CURCODE.value, this.bookingDate)

  }
  getExchangeRate(currency, bookingDate) {
    this.savebutton = false;
    bookingDate = this.common.ConvertToLocaleDate(new Date());
    bookingDate = bookingDate.replace('Sept', 'Sep')
    this.subscription.add(this.apiExRate.getExchangeRate(currency, bookingDate)
      .subscribe(data => {
        if (data != null && data != undefined) {
          this.currencyExrate = data;

          this.insuranceEditForm.controls.CURExRate.setValue(this.currencyExrate.ResponseData.EXRATE);
        }
        this.savebutton = true;
        //this.CalculateForm();
      }));


  }


  public SupplierValueChange(value: any): void {
    //  alert(value);
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);

    }
    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860

      this.SupplierDefaultvalue(this.supplierSelectedItem.Code);
      this.insuranceEditForm.controls.SUPPRTYCODE.setValue(this.supplierSelectedItem.Code);
      this.insuranceEditForm.controls.SUPPLIER.setValue(this.supplierSelectedItem.ActualName);
      this.apiShared.BALDUETO = this.supplierSelectedItem.BALDUETO;
      this.insuranceEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID);
      this.payterm.getBalanceDuedate();
      this.TaxLookup(this.supplierSelectedItem.CONTID);
    }
    else {
      this.supplierSelected = "";
      this.insuranceEditForm.controls.SUPPRTYCODE.setValue("");
      this.insuranceEditForm.controls.SUPPLIER.setValue("");
    }

  }
  TaxLookup(country) {
    this.fchcdetails.getInputTaxLookup(country)
  }
  diff: number;
  indate: Date;
  outdate: Date;
  days: number = 0;
  onDateoutblur() {
    debugger;
    this.indate = this.insuranceEditForm.controls.DATEF.value
    this.outdate = this.insuranceEditForm.controls.DATET.value
    this.indate = (this.insuranceEditForm.controls.DATEF.value == null || this.insuranceEditForm.controls.DATEF.value == "" ? "01-Jan-1900" : this.insuranceEditForm.controls.DATEF.value);//Case id-337
    this.outdate = (this.insuranceEditForm.controls.DATET.value == null || this.insuranceEditForm.controls.DATET.value == "" ? "01-Jan-1900" : this.insuranceEditForm.controls.DATET.value);//Case id-337


    if (Boolean(this.common.ValidDateRange(this.indate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidFromDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.outdate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidToDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (this.outdate < this.indate) {
      this.common.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 2000, true, false);
      this.insuranceEditForm.controls.DATET.setValue(this.insuranceEditForm.controls.DATEF.value);
      this.outdate = this.indate;
      return;
    }

    if (this.outdate.toString() != '01-Jan-1900' && this.indate.toString() != '01-Jan-1900') {
      this.diff = this.outdate.getTime() - this.indate.getTime();
      this.days = this.diff / (1000 * 60 * 60 * 24);
    }

  }

  onNightsblur() {
    this.indate = this.insuranceEditForm.controls.DATEF.value
    this.days = this.insuranceEditForm.controls.DAYS.value

    this.indate = (this.insuranceEditForm.controls.DATEF.value == null || this.insuranceEditForm.controls.DATEF.value == "" ? "01-Jan-1900" : this.insuranceEditForm.controls.DATEF.value);//Case id-337
    this.outdate = (this.insuranceEditForm.controls.DATET.value == null || this.insuranceEditForm.controls.DATET.value == "" ? "01-Jan-1900" : this.insuranceEditForm.controls.DATET.value);//Case id-337


    if (Boolean(this.common.ValidDateRange(this.indate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidFromDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.outdate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidToDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }

    this.outdate = new Date((new Date(this.indate)).getTime() + (this.days * 60 * 60 * 24 * 1000));
    this.insuranceEditForm.controls.DATET.setValue(this.outdate);
  }

  public dattobechecked: any;
  public FormatDate(value: any): string {


    this.dattobechecked = new Date(value).toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    this.dattobechecked = this.dattobechecked.replace('Sept', 'Sep')
    if (this.dattobechecked == "1-Jan-1900")
      return "";
    else {
      return (this.dattobechecked);
    }
  }

  CompanyCardValidation() {
    if (this.insuranceEditForm.get('PAYMODE').value == "Company Card") {

      if (this.insuranceEditForm.get('PDNO').value != "" && this.insuranceEditForm.get('PDNO').value != null && this.insuranceEditForm.get('PDFCURID').value != "" && this.insuranceEditForm.get('PDFCURID').value != null
        && this.insuranceEditForm.get('PDFCAMT').value != "" && this.insuranceEditForm.get('PDFCAMT').value != null && this.insuranceEditForm.get('PDFCAMT').value != 0 && this.insuranceEditForm.get('PDTYPENO').value != null
        && this.insuranceEditForm.get('PDTYPENO').value != "" && this.insuranceEditForm.get('PDAMOUNT').value != null && this.insuranceEditForm.get('PDAMOUNT').value != null && this.insuranceEditForm.get('PDAMOUNT').value != 0

        && this.insuranceEditForm.get('PDFCAMT').value != null && this.insuranceEditForm.get('PDFCAMT').value != null && this.insuranceEditForm.get('PDFCAMT').value != 0
      ) {
        return true;
      }
      else {

        this.common.showFlash1('Messages.Companycard_validation', 'bg-danger text-white', 2000);
        return false;
      }
    }
    else {
      return true;
    }

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339

  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }
  isSticky: boolean = false;
  @HostListener('window:scroll')
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }
  ConfirmMessage() {
    if (this.creditLimiFlag == false) {
      this.creditLimitMsg = false;
      this.InsuranceDetailSaveandUpdate();
    } else {
      this.creditLimitMsg = false;
    }
  }
}
