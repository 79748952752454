import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {  RailDetail } from '../Models/rail-detail'
import { Observable, of,throwError  } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class RailService {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   }


  
  //Supplierss:Object
  
    //getSuppliers (): Observable<HotelDetail[]> {   
      
      ////return this.http.get<HotelDetail[]>(apiUrl)
       // .pipe(
      //   tap(heroes => console.log('fetched Suppliers')),
       // catchError(this.commonservice.handleError('getSuppliers', []))
        //);
    //}
    
    private newMethod(data: Object) {
      alert(data);
    }
  
    getRail(folderNo: number,GuidItinerary:string,isViewForm:boolean): Observable<RailDetail> {
      const url = `${this.apiUrl}`+'GetRailDetailsById'+`/${folderNo}/${GuidItinerary}/${isViewForm}`;
      return this.http.get<RailDetail>(url).pipe(
        tap(_ => console.log(`fetched Rail id=${folderNo}`)),
        catchError(this.commonservice.handleError<RailDetail>(`getRail id=${folderNo}`))
      );
    }
    AddRailDetails (raildetail: any): Observable<any> {
      if(raildetail.InterInd=="International")
      raildetail.InterInd="I"
      else if(raildetail.InterInd=="Domestic")
      raildetail.InterInd="D"
      else if(raildetail.InterInd=="Transborder")
      raildetail.InterInd="T"
      else
      raildetail.InterInd=""
      
      this.setraildetails(raildetail);
      return this.http.post<any>(this.apiUrl+'AddRailDetails', raildetail, httpOptions).pipe(
        tap((railRes: any) => console.log(`added Rail w/ id=${railRes.ResponseData.PASS}`)),
        catchError(this.commonservice.handleError<any>('AddRailDetails'))
      );
    }
    setraildetails(raildetail:any){
      if(raildetail.TICKETDATE===null || raildetail.TICKETDATE==='') {
        raildetail.TICKETDATE=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
      } else {
        raildetail.TICKETDATE=this.commonservice.ConvertToLocaleDate(raildetail.TICKETDATE);
      }
      if(raildetail.DepositDueDate===null || raildetail.DepositDueDate==='')
      raildetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
      else
      raildetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(raildetail.DepositDueDate);
  
      // if(raildetail.BalanceDueDate===null || raildetail.DepositDueDate==='')
      // raildetail.BalanceDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
      // else
      // raildetail.BalanceDueDate=(raildetail.BalanceDueDate);
  
      if(raildetail.DEPDATE===null || raildetail.DEPDATE==='')
      raildetail.DEPDATE=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
      else
      raildetail.DEPDATE=this.commonservice.ConvertToLocaleDate(raildetail.DEPDATE);
  
      if(raildetail.ARRDATE===null || raildetail.ARRDATE==='')
      raildetail.ARRDATE=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
      else
      raildetail.ARRDATE=this.commonservice.ConvertToLocaleDate(raildetail.ARRDATE);
  
      raildetail.BalanceDueDate=raildetail.TICKETDATE;
  
        if(raildetail.LOYALTYPOINTSAMT==null)
        raildetail.LOYALTYPOINTSAMT=0;
        if(raildetail.LOYALTYPOINTS==null)
        raildetail.LOYALTYPOINTS=0;
    }
    UpdateRailDetails ( raildetail: any): Observable<any> { 

      if(raildetail.InterInd=="International")
      raildetail.InterInd="I"
      if(raildetail.InterInd=="Domestic")
      raildetail.InterInd="D"
      if(raildetail.InterInd=="Transborder")
      raildetail.InterInd="T"
      
      this.setraildetails(raildetail);
      const url = `${this.apiUrl}`+'UpdateRailDetails';///${id}
      return this.http.post(url, raildetail, httpOptions).pipe(
        tap(_ => console.log(`updated Rail`)),
        catchError(this.commonservice.handleError<any>('UpdateCarDetails'))
      );
    }
    DeleteRailDetails( folderNo: number,GuidItinerary:string): Observable<any> {   

      const url = `${this.apiUrl}`+'DeleteRailDetails'+`/${folderNo}/${GuidItinerary}`;
     
      return this.http.post(url,  httpOptions).pipe(
        tap(_ => console.log(`delete Rail`)),
        catchError(this.commonservice.handleError<any>('deleteRail'))
      );
    }
}
