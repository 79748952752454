import { Component, Input, OnInit } from '@angular/core';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-notes-to-be-printed-edit',
  templateUrl: './notes-to-be-printed-edit.component.html',
  styleUrls: ['./notes-to-be-printed-edit.component.scss']
})
export class NotesToBePrintedEditComponent implements OnInit {

  constructor() { }
  @Input() parentForm:FormGroup;
  @Input() public pocreated;

  ngOnInit(): void {
  }
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;
  
  public onChange(event: any): void {
    this.expandMode = parseInt(event.target.value, 10);
}
get fp() { return this.parentForm.controls; }
public onPanelChange(event: any): void { console.log('stateChange: ', event); }

}
