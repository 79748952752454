import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {  POCreationDetail, ProductUpdatePODTO } from '../Models/pocreation-detail';
import { Observable, of,throwError  } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import{Pocreationresult} from '../Models/pocreationresult'
import { DeletePO } from '../Models/delete-po';
import { DeleteInvoiceResponse } from '../Models/delete-invoice-response';
import { CommonserviceService } from './commonservice.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({

  providedIn: 'root'
})
export class PocreationService {
  apiUrl :string;
  public formData:ProductUpdatePODTO;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) 
  { 
    this.apiUrl=env.baseUrl;
  }
  CreatePO(POCreationDetails:any):Observable<Pocreationresult>
  {
    POCreationDetails.postDate=this.commonservice.ConvertToLocaleDate(POCreationDetails.postDate);

    const url=this.apiUrl + 'PostPOCreation';
    return this.http.post<Pocreationresult>(url, POCreationDetails, httpOptions).pipe();
      
  }

  DeletePO(DeletePO:any):Observable<DeleteInvoiceResponse>
  {
    const url=this.apiUrl + 'DeletePOCreation';
    return this.http.post<DeleteInvoiceResponse>(url, DeletePO, httpOptions).pipe();
  }

  
  public  SaveProductAfterPO(): Observable<any> {
    var body = {
          ...this.formData,
     
     
    };
    return this.http.post<ProductUpdatePODTO>(this.apiUrl+'UpdateProductDetails', body, httpOptions).pipe(
      tap((ProductUpdateDTO: any) => console.log(`Update Product Details`)),
      catchError(this.commonservice.handleError<any>('Update Product Details'))
    );
  }
}
