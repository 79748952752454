
import { Injectable } from '@angular/core';  
import { HttpClient, HttpClientModule } from '@angular/common/http';  

import {  HotelPropertyTypes, HotelPropertyTypesRequestDTO } from '../Models/hotel-property-types';
import { Observable,of } from 'rxjs';
import { HttpHeaders } from "@angular/common/http";
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';
import { HotelPropertyTypesComponent } from '../hotel-property-types/hotel-property-types.component';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
@Injectable({
  providedIn: 'root'
})


export class Hotelpropertytypesservice {
    apiUrl :string;
 
   
    constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
      this.apiUrl=env.baseUrl;
     }
     AddPropertyTypesDetails(HotelPropertyTypesRequestDTO: any): Observable<any> {
    
        return this.http.post<any>(this.apiUrl + 'AddPropertyTypesDetails', HotelPropertyTypesRequestDTO, httpOptions).pipe(
         
        );
      }
      GetPropertyTypes():  Observable<HotelPropertyTypes[]>{
        const url = `${this.apiUrl}`+'GetAllPropertyTypesDetails';
        return this.http.get<HotelPropertyTypes[]>(url).pipe(
          tap(_ => console.log(`fetched AllHotelPropertyTypes`)),
          catchError(this.commonservice.handleError<HotelPropertyTypes[]>(`GetAllPropertyTypesDetails`))
        );
      }


      DeletePropertyTypes(HPtypeAutoNo:number):Observable <any>
      {
        const url = `${this.apiUrl}`+'DeletePropertyTypes'+`/${HPtypeAutoNo}`;
        return this.http.post(url,  httpOptions).pipe(
                      tap(_ => console.log(`DeletePropertyTypes`)),
        catchError(this.commonservice.handleError<any>('DeletePropertyTypes'))
          );
      }
      UpdatePropertyTypesDetails(HotelPropertyTypesRequestDTO: any): Observable<HotelPropertyTypes>
      {
 
   return this.http.post<HotelPropertyTypes>(this.apiUrl+'AddPropertyTypesDetails', HotelPropertyTypesRequestDTO, httpOptions).pipe();
   
      }
 
    }

    