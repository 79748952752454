import { Injectable } from '@angular/core';  
import { HttpClient, HttpClientModule } from '@angular/common/http';  

import {  Deliveryaddress } from '../Models/deliveryaddress';
import { Observable,of } from 'rxjs';
import { HttpHeaders } from "@angular/common/http";
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class DeliveryaddressService {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService) {
    this.apiUrl=env.baseUrl;
   }
  GetContactDetailsById(folderNo: string):Observable<Deliveryaddress> { 
    const url = `${this.apiUrl}`+'GetContactDetailsById'+`/${folderNo}`;
    
    return this.http.get<Deliveryaddress>(url);  

  }
  UpdateDeliveryAddress(Deliveryaddress: any): Observable<any>
{
  return this.http.post<any>(this.apiUrl+'UpdateContactDetails', Deliveryaddress, httpOptions).pipe();
  
}
  
}
