import { Component, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { FolderSearchCriteriaDTO, CarSearchDTO } from '../../Models/folder-search-criteria-dto';
import { SearhdtotransferService } from '../../Services/searhdtotransfer.service';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
@Component({
  selector: 'app-searchby-car',
  templateUrl: './searchby-car.component.html',
  styleUrls: ['./searchby-car.component.scss']
})
export class SearchbyCarComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  SupplierList: Array<LookUpDetails['ResponseData']> = [];
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  public supplierSelectedItem: LookUpDetails['ResponseData'];
  public supplierSelected: String;

  public statusList: Array<string> = ["OK", "Pending", "Cancelled", "Confirmed"];

  constructor(public common: CommonserviceService, private searhTransferDTO: SearhdtotransferService, private lookupApi: LookUpDetailsService, private formBuilder: FormBuilder) { }
  flsrchDto: FolderSearchCriteriaDTO;
  CarSearchDTO: CarSearchDTO;
  opened = false;
  openSearch = false;
  ngOnChanges(changes: SimpleChanges) {

    this.CarSearchDTO = this.flsrchDto.CarSearchDTO;
  }
  menuIndex = 2;
  ngOnInit() {
    // this.CarSearchForm = this.formBuilder.group({

    //   HotelName :[""],
    //   Supplier :[""],
    //   CheckInDatefrom :[""],
    //   CheckInDateTo :[""],
    //   CheckOutDatefrom :[""],
    //   CheckOutDateTo :[""],
    //   Status :[""],
    //   RoomType :[""],
    //   ConfirmationNo :[""],    
    //   VoucherNo :[""],

    // });


    this.subscription.add(this.searhTransferDTO.SrchTranferDTO.subscribe(flsrchDto => {
      this.flsrchDto = flsrchDto;

    }))
    if (this.flsrchDto.CarSearchDTO != null)
      this.CarSearchDTO = this.flsrchDto.CarSearchDTO;
    else
      this.CarSearchDTO = new CarSearchDTO();

    // this.loadSupplier();
    if(this.CarSearchDTO.Supplier!= "" && this.CarSearchDTO.Supplier!= undefined){
      this.loadSupplierValue(this.CarSearchDTO.Supplier);
    }
  }
  loadSupplierValue(value:any) {  //14068   v1.0 
    debugger; 

      this.subscription.add(this.lookupApi.findSupplier(value).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.SupplierList = data["ResponseData"];
            if (value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
              }

            }
          }

        }
      ));
    
  }


  callChange() {

    if (this.CarSearchDTO.Supplier != '' || this.CarSearchDTO.PickUp != '' || this.CarSearchDTO.DropOff != '' || this.CarSearchDTO.Status != ''
      || this.CarSearchDTO.VoucherNo != '' || this.CarSearchDTO.Type != '' || this.CarSearchDTO.hireFrom1AF.toString() != ''
      || this.CarSearchDTO.hireFrom2AF.toString() != '' || this.CarSearchDTO.hireTo1AF.toString() != '' || this.CarSearchDTO.hireTo2AF.toString() != '') {
      if (this.CarSearchDTO.hireFrom1AF != null) {
        if (this.CarSearchDTO.hireFrom1AF.toString() != '')
          this.CarSearchDTO.hireFrom1 = this.common.transformDate(new Date(this.CarSearchDTO.hireFrom1AF));
        // this.CarSearchDTO.hireFrom1= new Date(this.CarSearchDTO.hireFrom1AF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');

      } else {
        this.CarSearchDTO.hireFrom1 = null;

      }

      if (this.CarSearchDTO.hireFrom2AF != null) {
        if (this.CarSearchDTO.hireFrom2AF.toString() != '')
          this.CarSearchDTO.hireFrom2 = this.common.transformDate(new Date(this.CarSearchDTO.hireFrom2AF));
        // this.CarSearchDTO.hireFrom2= new Date(this.CarSearchDTO.hireFrom2AF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.CarSearchDTO.hireFrom2 = null;
      }


      if (this.CarSearchDTO.hireFrom1AF == null && this.CarSearchDTO.hireFrom2AF != null) {
        this.CarSearchDTO.hireFrom1 = this.CarSearchDTO.hireFrom2;
      }
      if (this.CarSearchDTO.hireTo1AF != null) {
        if (this.CarSearchDTO.hireTo1AF.toString() != '')
          this.CarSearchDTO.hireTo1 = this.common.transformDate(new Date(this.CarSearchDTO.hireTo1AF));

        // this.CarSearchDTO.hireTo1= new Date(this.CarSearchDTO.hireTo1AF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');

      } else {
        this.CarSearchDTO.hireTo1 = null;

      }
      if (this.CarSearchDTO.hireTo2AF != null) {
        if (this.CarSearchDTO.hireTo2AF.toString() != '')
          this.CarSearchDTO.hireTo2 = this.common.transformDate(new Date(this.CarSearchDTO.hireTo2AF));
        // this.CarSearchDTO.hireTo2= new Date(this.CarSearchDTO.hireTo2AF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.CarSearchDTO.hireTo2 = null;

      }
      if (this.CarSearchDTO.hireTo1AF == null && this.CarSearchDTO.hireTo2AF != null) {
        this.CarSearchDTO.hireTo1 = this.CarSearchDTO.hireTo2;
      }

      this.flsrchDto.CarSearchDTO = this.CarSearchDTO;
    }
    else
      this.flsrchDto.CarSearchDTO = null;
  }
  public loadSupplier() {
    this.subscription.add(this.lookupApi.GetAllLookup('SUPPLIERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.SupplierList = data["ResponseData"];
          if (this.CarSearchDTO.Supplier != '' && this.CarSearchDTO.Supplier != null) {

            this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.CarSearchDTO.Supplier);
            this.supplierSelected = this.supplierSelectedItem.Name;

          }
        }
      }
    ));
  }
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  handleFiltersupplier(value) { debugger;

    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.GetLookupById("SUPPLIERS", value, value).subscribe(

        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  public SupplierValueChange(value: any): void {
    debugger;
    if (this.SupplierList != null && this.SupplierList.length > 0) {//13860
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);
    }

    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860

      this.CarSearchDTO.Supplier = (this.supplierSelectedItem.Code);


    } else {
      this.supplierSelected = '';
      this.CarSearchDTO.Supplier = ('');


    }
    this.callChange();

  }


  
  public close(status) {

    this.opened = false;
    this.openSearch = false;
  }
  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }
  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
