import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { EnvService } from "src/env.service";
import { CommonserviceService } from "./commonservice.service";
import { tap, catchError } from "rxjs/operators";
import { FlightBookingDetailsDTO, FlightBookingDetailsResultDTO } from "../Models/flight-booking-details"
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};
@Injectable({
  providedIn: 'root'
})
export class FlightBookingDetailsService {
  public griddata: any;
  public GroupByGridData: any;
  apiUrl: string;
  //TemplateSelectedItem: string;
  public formData: FlightBookingDetailsDTO;
  formDataHeader: any = {
    RptHdrSel_AutoNo: 0,
    RptHdrSel_ModuleName: "",
    RptHdrSel_ColumnHeader: "",
    RptHdrSel_UserId: 0,
    RptHdrSel_TemplateName: "",
    RptHdrSel_Lupdate: new Date(),
    RptHdrSel_CustCo: "",
    TemplateSelectedItem: ""
  };
  constructor(
    private http: HttpClient,
    private env: EnvService,
    public commonservice: CommonserviceService
  ) {
    this.apiUrl = env.baseUrl;
  }
  public GetTemplate(): Observable<any> {
    var body = {
      ...this.formDataHeader
    };
    return this.http
      .post<FlightBookingDetailsResultDTO>(
        this.apiUrl + "GetTemplate",
        body,
        httpOptions
      )
      .pipe(
        tap((FlightBookingDetailsResultDTO: any) =>
          console.log(`GetTemplate`)
        ),
        catchError(this.commonservice.handleError<any>("GetTemplate"))
      );
  }
  public saveOrUpdateReportHeader(): Observable<any> {
    var body = {
      ...this.formDataHeader
    };
    return this.http
      .post<FlightBookingDetailsResultDTO>(
        this.apiUrl + "saveOrUpdateReportHeader",
        body,
        httpOptions
      )
      .pipe(
        tap((FlightBookingDetailsResultDTO: any) =>
          console.log(`saveOrUpdateReportHeader`)
        ),
        catchError(
          this.commonservice.handleError<any>("saveOrUpdateReportHeader")
        )
      );
  }
  public GetGridColumn(): Observable<any> {
    var body = {
      ...this.formDataHeader
    };
    return this.http
      .post<FlightBookingDetailsResultDTO>(
        this.apiUrl + "getGridHeader",
        body,
        httpOptions
      )
      .pipe(
        tap((FlightBookingDetailsResultDTO: any) =>
          console.log(`getGridHeader`)
        ),
        catchError(this.commonservice.handleError<any>("getGridHeader"))
      );
  }
  GetVarientTemplateForEdit(Template: any, modulename: any): Observable<any> {
    const url = `${this.apiUrl}` + 'GetVarientTemplateForEdit' + `/${Template}/${modulename}`;
    return this.http.get<any>(url);
  }
  public GetPassengerBookingDetailsReport(): Observable<
    FlightBookingDetailsDTO
  > {
    var body = {
      ...this.formData
    };
    return this.http
      .post<FlightBookingDetailsDTO>(
        this.apiUrl + "GetPassengerBookingDetailsReport",
        body,
        httpOptions
      )
      .pipe(
        tap((FlightBookingDetailsDTO: any) =>
          console.log(`GetPassengerBookingDetailsReport`)
        ),
        catchError(this.commonservice.handleError<any>("GetPassengerBookingDetailsReport"))
      );

  }
}
