import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  Optional
} from "@angular/core";
import { LookUpDetails } from "../../Services/look-up-details";
import { CommonserviceService } from "../../Services/commonservice.service";
import { LookUpDetailsService } from "../../Services/look-up-details.service";
import { Subscription } from "rxjs";
import { ShareDataService } from "../../Services/share-data.service";
import { PaymentgatewayListDetailsService } from "../../Services/paymentgateway-list-details.service";
import { EpayEmail } from "../../Models/epay-email";
import { ActivatedRoute } from "@angular/router";
import { EnvService } from "src/env.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@progress/kendo-angular-intl";
import { NgxSpinnerService } from "ngx-spinner";
import { anyChanged } from "@progress/kendo-angular-common";
import { FileService } from '../../Services/file.service';
@Component({
  selector: "app-epay-mail",
  templateUrl: "./epay-mail.component.html",
  styleUrls: ["./epay-mail.component.scss"]
})
export class EpayMailComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public ProviderList: Array<LookUpDetails["ResponseData"]> = [];
  PassengerEmail: Array<LookUpDetails["ResponseData"]> = [];
  public ProviderSelectedItem;
  @Output() emailStatus = new EventEmitter<string>();
  @Input() public PPID;
  @Input() public PassSurname;
  DefaultpaymentGateway: LookUpDetails["ResponseData"];
  public Provider: any;
  private datePipe: DatePipe;
  public PDQ: any;
  public OptionDate: any;
  objEpayEmail: EpayEmail;
  public OptionTime: any;
  public EpayEmailId: any;
  public BAID: any;
  public OrderNo: any;
  public ProjNo: any;
  public Currency: any;
  public ToEmail: any;
  public PassnegerName: any;
  public BRID: any;
  public PartyCode: any;
  public uid: any;
  public ViewTripurl: any;
  public showMsg: any;
  EmailSelectedItem: any;
  public Componentname: string;
  url: any;
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translateapi: TranslateService,
    private route: ActivatedRoute,
    private env: EnvService,
    private apipaymentgateway: PaymentgatewayListDetailsService,
    private apiShared: ShareDataService,
    public common: CommonserviceService,
    public lookupApi: LookUpDetailsService,
    private file: FileService
  ) { }

  ngOnInit() {
    this.BAID = this.apiShared.BAID;
    this.OrderNo = this.apiShared.orderNo;
    this.ProjNo = this.apiShared.projNo;
    this.Currency = this.apiShared.folderCurrency;
    this.BRID = this.apiShared.BRID;
    this.PartyCode = this.apiShared.CustomerCode;
    this.uid = this.apiShared.uid;

    this.subscription.add(
      this.lookupApi
        .GetPassengerEmailList(
          this.Currency,
          this.PPID,
          this.PartyCode,
          this.OrderNo
        )
        .subscribe(data => {
          if (data != null && data != undefined) {
            debugger;
            /// alert(JSON.stringify(data["ResponseData"]))
            if (
              data["ResponseData"][0].Name != null &&
              data["ResponseData"][0].Name != undefined
            ) {
              this.PassengerEmail = data["ResponseData"];
              this.EmailSelectedItem = this.PassengerEmail[0];
              this.ToEmail = this.PassengerEmail[0].Name;
            }
          }
        })
    );

    this.subscription.add(
      this.apipaymentgateway.GetOptionDate(this.OrderNo).subscribe(data => {
        if (data != null && data != undefined) {
          this.OptionDate = new Date(data["ResponseData"].OptionDate);
          this.OptionTime = data["ResponseData"].optionTime;
        } else {
          this.OptionDate = new Date();
          this.OptionTime = 0;
        }
      })
    );

    this.subscription.add(
      this.lookupApi.GetAllLookup("ActiveProviders").subscribe(data => {
        // console.log(JSON.stringify(data, null, 4));

        //console.log(JSON.stringify(data["ResponseData"], null, 4));
        debugger;
        if (data["ResponseData"] != null) {
          this.ProviderList = data["ResponseData"];
          if (
            this.BAID != "" &&
            this.BAID != null &&
            this.BAID != undefined &&
            this.Currency != null &&
            this.Currency != undefined &&
            this.Currency != ""
          ) {
            this.subscription.add(
              this.apipaymentgateway
                .GEtDefaultPGateWay(this.BAID, this.Currency)
                .subscribe(data => {
                  debugger;

                  if (data["ResponseData"] != null) {
                    this.DefaultpaymentGateway = data["ResponseData"];
                    if (this.DefaultpaymentGateway.Name != "") {
                      this.ProviderSelectedItem = this.ProviderList.find(
                        item =>
                          item.UsrCode.toLowerCase() ==
                          this.DefaultpaymentGateway.Name.toLowerCase()
                      );
                      this.PDQ = this.DefaultpaymentGateway.UsrCode;
                      // console.log("test:-"+this.folderSummaryEditForm.controls.BRID.value+JSON.stringify(this.branchSelectedItem , null, 4));

                      this.Provider = this.ProviderSelectedItem.UsrCode;
                    }
                  }
                })
            );
          }
        }
      })
    );
    this.Componentname = "EpayMail";
    // this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654
    sessionStorage.setItem("Componentname", this.Componentname);
  }

  public ProviderValueChange(value: any): void {
    //console.log('selectionChange', value);

    if (value != null && value != undefined && value != "") {
      this.Provider = value.UsrCode;
      this.subscription.add(
        this.apipaymentgateway
          .GEtDefaultPDQ(this.BAID, this.Currency, value.UsrCode)
          .subscribe(data => {
            this.PDQ = data["ResponseData"];
            if (this.PDQ == null || this.PDQ == undefined || this.PDQ == "")
              this.PDQ = value.Code;
            debugger;
          })
      );

      debugger;

      if (this.PDQ == null || this.PDQ == undefined || this.PDQ == "")
        this.PDQ = value.Code;
    } else {
      this.Provider = "";
      this.PDQ = 0;
    }
  }

  public FrommailValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.PassnegerName = value.UsrCode;
      this.ToEmail = value.Name;
    } else {
      this.PassnegerName = "";
      this.ToEmail = "";
    }
  }

  public close() {
    this.emailStatus.emit("close");
  }

  public async SendEpayEmail() {
    if (
      this.ToEmail == null ||
      this.ToEmail == "" ||
      this.ToEmail == undefined
    ) {
      this.subscription.add(
        this.translateapi
          .get(["Messages.EmailInvalid"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.EmailInvalid"];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            });
          })
      );
      // this.spinner.hide();
      return;
    } else if (
      this.Provider == null ||
      this.Provider == "" ||
      this.Provider == undefined
    ) {
      this.subscription.add(
        this.translateapi
          .get(["Messages.Providernvalid"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.Providernvalid"];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            });
          })
      );
      // this.spinner.hide();
      return;
    } else if (this.OptionDate == null || this.OptionDate == undefined) {
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidDate"];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            });
          })
      );
      // this.spinner.hide();
      return;
    } else if (
      this.PDQ == null ||
      this.PDQ == "" ||
      this.PDQ == undefined ||
      this.PDQ == 0
    ) {
      this.subscription.add(
        this.translateapi
          .get(["Messages.invalid_pdq"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.invalid_pdq"];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            });
          })
      );
      // this.spinner.hide();
      return;
    } else {
      if (this.PassSurname == null || this.PassSurname == undefined) {
        this.PassSurname = "";
      }

      await this.getUrlSettings();

    }

  }
  SendEpayEmailfn() {
    debugger;
    this.subscription.add(
      this.apipaymentgateway.SendEpayEmail().subscribe(data => {
        if (data != undefined && data != null) {
          if (data["ResponseData"].result == 1) {
            this.spinner.hide();
            this.emailStatus.emit("EmailSendSuccesfully");
          } else {
            this.spinner.hide();
            if (
              data["ResponseData"].ExceptionError != null &&
              data["ResponseData"].ExceptionError != "" &&
              data["ResponseData"].ExceptionError != undefined
            ) {
              this.toastr.error(data["ResponseData"].ExceptionError, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
            } else {
              this.subscription.add(
                this.translateapi
                  .get(["Messages.MailSendFailed"])
                  .subscribe(translations => {
                    this.showMsg = translations["Messages.MailSendFailed"];
                    this.toastr.error(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    });
                  })
              );
            }
          }
        } else {
          this.spinner.hide();
          this.subscription.add(
            this.translateapi
              .get(["Messages.MailSendFailed"])
              .subscribe(translations => {
                this.showMsg = translations["Messages.MailSendFailed"];
                this.toastr.success(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                });
              })
          );
        }
      })

    );
  }
  async getUrlSettings() {
    this.spinner.show();
    this.objEpayEmail = new EpayEmail();
    this.objEpayEmail.BAID = this.BAID;
    this.objEpayEmail.BRID = this.BRID;
    this.objEpayEmail.Email = this.ToEmail;
    this.objEpayEmail.FolderNo = this.OrderNo;
    this.objEpayEmail.ProjNo = this.ProjNo;
    this.objEpayEmail.OptionDate = this.common.ConvertToLocaleDate(
      this.OptionDate
    );

    this.objEpayEmail.PDQ = this.PDQ;
    this.objEpayEmail.PPID = this.PPID;
    this.objEpayEmail.PartyCode = this.PartyCode;
    this.objEpayEmail.PassFirstname = this.PassnegerName;
    this.objEpayEmail.Provider = this.Provider;
    this.objEpayEmail.ViewTripUrl = this.ViewTripurl;

    this.objEpayEmail.optionTime = this.OptionTime;

    this.objEpayEmail.uid = this.uid;
    this.apipaymentgateway.objEpayEmail = null;
    this.apipaymentgateway.objEpayEmail = this.objEpayEmail;
    debugger;
    if (this.Provider.toLowerCase() == "amexbanktransfer") {

      this.subscription.add(
        this.file.getUrlSettings("AmexBankTransfer").subscribe(data => {
          debugger
          if (data.ResponseData != null && data.ResponseData != "" && data.ResponseData != undefined) {
            this.url = data.ResponseData
            this.url = this.url + "/amexpay?Surname=" + this.PassSurname +
              "&Bookingref=" +
              this.apiShared.orderNo +
              "&PM=" +
              this.Provider +
              "&BRID=" +
              this.BRID +
              "&TransType=E&PDQNo=" +
              this.PDQ + "&ProjNo=" +
              this.ProjNo + "&UserId=" + this.apiShared.uid;
            console.log(this.url);
            this.objEpayEmail.ViewTripUrl = this.url;
            this.apipaymentgateway.objEpayEmail = this.objEpayEmail;
            this.SendEpayEmailfn();
          }
          else {
            this.spinner.hide();
            this.showMsg = " Url not Found";
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            });
            return
          }
        })
      );
    }
    else {
      this.subscription.add(
        this.file.getUrlSettings("ViewTrip").subscribe(data => {
          debugger
          if (data.ResponseData != null && data.ResponseData != "" && data.ResponseData != undefined) {
            this.url = data.ResponseData
            this.ViewTripurl =
              this.url + "?Surname=" +
              this.PassSurname +
              "&Bookingref=" +
              this.apiShared.orderNo +
              "&PM=" +
              this.Provider +

              "&BRID=" +
              this.BRID +
              "&TransType=E&PDQNo=" +
              this.PDQ;
            this.objEpayEmail.ViewTripUrl = this.ViewTripurl;
            this.apipaymentgateway.objEpayEmail = this.objEpayEmail;
            this.SendEpayEmailfn();
          }
          else {
            this.spinner.hide();
            this.showMsg = " Url not Found";
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            });
            return
          }
        })
      );
    }
  }
}
