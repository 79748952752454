import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Align, Collision } from '@progress/kendo-angular-popup';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-calendar2',
  templateUrl: './calendar2.component.html',
  styleUrls: ['./calendar2.component.scss']
})
export class Calendar2Component implements OnInit, OnChanges {
  public show = false;
  @ViewChild('anchor') public anchor: ElementRef;
  @ViewChild('popup', { read: ElementRef }) public popup: ElementRef;
  @Output() dateValue: EventEmitter<string> = new EventEmitter();
  months: Array<string> = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  @Input() action: any;
  @Input() dateInput: any;

  public anchorAlign: Align = { horizontal: "right", vertical: "bottom" };
  public popupAlign: Align = { horizontal: "right", vertical: "top" };
  public collision: Collision = { horizontal: 'flip', vertical: 'fit' };

  public showMsg: any;
  selectedDate: any = '';
  SchedulerDate: any;
  ShedulerVariance: any;
  public DateVariableList: Array<string> = [
    "Current Date",
    "Previous Date",
    "Tomorrow",
    "Week Start Date",
    "Week End Date",
    "Next Week Start Date",
    "Next Week End Date",
    "Previous Week Start Date",
    "Previous Week End Date",
    "Month Start Date",
    "Month End Date",
    "Previous Month Start Date",
    "Previous Month End Date",
    "Year Start Date",
    "Year End Date"
  ];
  dateformat = '';
  constructor(public common: CommonserviceService, public datepipe: DatePipe) {
    this.dateformat = this.common.cdateformat;
  }

  ngOnInit(): void {
    // debugger;
    if (this.dateInput) {
      this.selectedDate = this.datepipe.transform(this.dateInput, this.dateformat == '' ? this.common.cdateformat : this.dateformat);
    }
  }
  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.contains(event.target)) {
      this.toggle(false);
    }
  }

  public toggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
    //this.toggleText = this.show ? 'Hide' : 'Show';
  }

  private contains(target: any): boolean {

    var isvalid = (
      this.anchor.nativeElement.contains(target) || (this.popup ? this.popup.nativeElement.contains(target) : false)
    )

    if (this.months.includes(target.textContent)) {
      isvalid = true;
    }

    return isvalid;
  }

  DateSelectionChange() {
    var dateValidation = Date.parse(this.SchedulerDate);
    if (!isNaN(dateValidation)) {
      this.selectedDate = this.datepipe.transform(this.SchedulerDate, this.common.cdateformat);
      //this.selectedDate = this.common.ConvertToLocaleDate(this.SchedulerDate);
      var date = this.common.ConvertToLocaleDate(this.SchedulerDate);
      this.setValueInParentForm(date);

    }
  }
  setValueInParentForm(inputval) {
    // debugger;
    if (inputval != null)
      this.dateinvalid = false;
    this.dateValue.emit(inputval);
    this.toggle(false)

    // debugger;
  }
  dateinvalid = false;
  public onCalenderLeave(value: any): void {
    // debugger;
    if (this.selectedDate && this.selectedDate != '') {
      // var dateValidation = Date.parse(this.selectedDate);
      // var dateCheck=false;
      // if (!isNaN(dateValidation))
      // {
      //   this.selectedDate=this.common.ConvertToLocaleDate(this.selectedDate);
      //   dateCheck=true;
      // }
      var dateCheck = moment(this.selectedDate, this.dateformat.toUpperCase(), true).isValid()

      if (dateCheck) {
        var date = this.common.ConvertToLocaleDate(this.selectedDate);
        this.selectedDate = this.datepipe.transform(this.selectedDate, this.common.cdateformat);
        //this.selectedDate = this.common.ConvertToLocaleDate(this.selectedDate);
        this.setValueInParentForm(date);
      } else if (this.DateVariableList.includes(this.selectedDate)) {
        this.setValueInParentForm(this.selectedDate);
      }
      else {
        this.dateinvalid = true;
        this.setValueInParentForm(null);
      }
    } else {
      this.dateinvalid = false;
      this.setValueInParentForm(null);
    }

  }
  public SchdulerVarianceChange(value: any): void {
    if (value != null && value != undefined)
      this.selectedDate = value;
    else this.selectedDate = null;

    this.setValueInParentForm(this.selectedDate);
  }
  ngOnChanges(changes: SimpleChanges) {
    // debugger;

    if (changes["dateInput"] != undefined && changes["dateInput"].currentValue != '01-Jan-1900') {
      if (changes["dateInput"].currentValue) {
        // this.selectedDate = this.datepipe.transform(this.dateInput, this.common.cdateformat);
        if (!this.DateVariableList.includes(this.selectedDate)) {
          this.selectedDate = this.datepipe.transform(this.dateInput, this.common.cdateformat);
        }
      }
    }

    if (changes["action"] != undefined) {
      if (changes["action"].currentValue !== undefined && changes["action"].currentValue == 'ClearAll') {
        this.selectedDate = "";
        this.SchedulerDate = null;
        //this.setValueInParentForm(this.selectedDate);
      }
    }

    // alert(JSON.stringify(this.selectedDate))
    //this.loaddata();
  }
}
