import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { RailsegDetail } from '../Models/railseg-detail'
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({

  providedIn: 'root'
})
export class RailsegService {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   }

 
  //Supplierss:Object

  //getSuppliers (): Observable<HotelDetail[]> {   

  ////return this.http.get<HotelDetail[]>(apiUrl)
  // .pipe(
  //   tap(heroes => console.log('fetched Suppliers')),
  // catchError(this.commonservice.handleError('getSuppliers', []))
  //);
  //}

  private newMethod(data: Object) {
    alert(data);
  }


  getRailseg(folderNo: number, slno: number): Observable<RailsegDetail> {
    //const url = `${apiUrl}/${folderNo}/${SLNO}`;
    const url = `${this.apiUrl}` + 'GetRailSegDetailsById' + `/${folderNo}/${slno}`;
    return this.http.get<RailsegDetail>(url).pipe(
      tap(_ => console.log(`fetched Railseg id=${folderNo}`)),
      catchError(this.commonservice.handleError<RailsegDetail>(`getRailseg id=${folderNo}`))
    );
  }


  addrailSeg(railSegdetail: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'AddRailSegDetails', railSegdetail, httpOptions).pipe(
      tap((railSeg: any) => console.log(`added railSegw/ id=${railSeg.ResponseData.SLNO}`)),
      catchError(this.commonservice.handleError<any>('addrailSeg'))
    );
  }

  updaterailSeg(railSegdetail: any): Observable<any> {
    if(railSegdetail.DEPDATE===null || railSegdetail.DEPDATE==='')
    railSegdetail.DEPDATE=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
    railSegdetail.DEPDATE=this.commonservice.ConvertToLocaleDate(railSegdetail.DEPDATE);
    
    if(railSegdetail.ARRDATE===null || railSegdetail.ARRDATE==='')
    railSegdetail.ARRDATE=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
    railSegdetail.ARRDATE=this.commonservice.ConvertToLocaleDate(railSegdetail.ARRDATE);



    const url = `${this.apiUrl}` + 'UpdateRailSegDetails';///${id}
    return this.http.post(url, railSegdetail, httpOptions).pipe(
      tap(_ => console.log(`updated railSeg`)),
      catchError(this.commonservice.handleError<any>('updaterailSeg'))
    );
  }

  deleteRailseg (orderNo: number,slNo:number): Observable<any> {
    const url = `${this.apiUrl}`+'DeleteRailSegDetails'+`/${orderNo}/${slNo}`;
 
    return this.http.post(url,  httpOptions).pipe(
      tap(_ => console.log(`delete RailSegment`)),
      catchError(this.commonservice.handleError<any>('deleteRailseg'))
    );
  }

  GetAllRailSegDetails(folderNo: number): Observable<RailsegDetail[]> {
    const url = `${this.apiUrl}`+'GetAllRailSegDetails'+`/${folderNo}`;
   // const url = `${apiUrl}/${OrderNo}/${GuidItinerary}`;
    return this.http.get<RailsegDetail[]>(url).pipe(
      tap(_ => console.log(`fetched RailSegDetails id=${folderNo}`)),
      catchError(this.commonservice.handleError<RailsegDetail[]>(`GetAllRailSegDetails id=${folderNo}`))
    );
  }
}



