import { Injectable } from '@angular/core';
import { AlertListDTO } from '../Models/alertdetails';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from '../../env.service';
import { FolderSearchCriteriaDTO } from '../Models/folder-search-criteria-dto';
@Injectable({
  providedIn: 'root'
})
export class FoldersearchService {

  apiUrl :string;
  public formData:FolderSearchCriteriaDTO;
// public CustomerMaster:CustomerMaster;

  constructor(private http: HttpClient,private env: EnvService) {
    this.apiUrl=env.baseUrl;
   } 
  

   Searchfolderdetails (): any {
    var body = {
      ...this.formData
     
    
     
    };
    //return this.http.post<FolderSearchCriteriaDTO>(this.apiUrl+'AddCustomerProfile', body);
    
   
  
  
  }
  }