import { Component, OnInit } from '@angular/core';
import { RailDetail } from '../../Models/rail-detail';
import { ActivatedRoute, Router } from '@angular/router';
import { RailService } from '../../Services/rail.service';
import { ShareDataService } from '../../Services/share-data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { POCreationDetail } from '../../Models/pocreation-detail';
import { Pocreationresult } from '../../Models/pocreationresult';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PocreationService } from '../../Services/pocreation.service';
import { DeletePO } from '../../Models/delete-po';
import { Deletepo } from '../../Models/deletepo';
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription } from 'rxjs';
import { FolderpriceService } from '../../Services/folderprice.service';
@Component({
  selector: 'app-rail-details',
  templateUrl: './rail-details.component.html',
  styleUrls: ['./rail-details.component.scss']
})
export class RailDetailsComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  lockStatus: any;
  public folderStatus: any;
  public formeditable = true;
  public POCreation: POCreationDetail;
  public confirmMsg: any;
  public showMsg: any;
  public PONO: number;
  public pocreated = true;
  Pocreationresultobj: Pocreationresult;
  projNo: any;
  passName: any;
  public deletePO: DeletePO;
  public language = this.apiShared.LanguageSelected;
  public result: any;
  showOrder = true;  //By Case id-367
  public DeleteInvoiceResponse: Deletepo;
  loaded = false
  folderEditMode: any;
  public invoice = true;//By Caseid-12621
  public createPoInvoice = false;//By Caseid-12621
  public ticketDate: any;//By Caseid-12619
  public FolderHideStatus = false;//12780
  railDetail: RailDetail = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {
      FOLDERNO: 0,
      SUPPNAME: '',
      SUPPCODE: 0,
      TYPE: '',
      FROMRAIL: '',
      DEPDATE: '01/Jan/1900',
      DEPTIME: 0,
      TORAIL: '',
      ARRDATE: '',
      ARRTIME: 0,
      STATUS: '',
      AMOUNT: 0,
      VATCODE: 0,
      VATPER: 0,
      VOUCHER: '',
      PAYMODE: '',
      CURCODE: '',
      AMTLC: 0,
      AMTHC: 0,
      CommOn: '',
      PASS: '',
      SLNO: 0,
      PROJNO: 0,
      ITYPE: '',
      Provider: '',
      GDS: '',
      PNR: '',
      CURExRate: 0,
      FCBuy: 0,
      FCCommPer: 0,
      FCCommAmt: 0,
      FCSell: 0,
      HCCommPer: 0,
      HCCommAmt: 0,
      HCSell: 0,
      ItnryNotes: '',
      TICKETNO: '',
      TICKETDATE: '',
      FROMRAILNAME: '',
      TORAILNAME: '',
      LowFB: '',
      LowFare: 0,
      HighFB: '',
      HighFare: 0,
      Fareoffered: 0,
      PotentialSaving: 0,
      ActualSaving: 0,
      PDNO: 0,
      PDTYPENO: 0,
      Forgone: 0,
      AddRemarks: '',
      PDAMOUNT: 0,
      PONO: 0,
      PONOYEARID: '',
      PAYMENTID: 0,
      PMTID: '',
      DEFDEPPER: 0,
      DEFDEP: 0,
      DepositDueDate: '',
      BalanceDueDate: '',
      BalanceDueAmount: 0,
      GUIDItinerary: '',
      LOYALTYPOINTS: 0,
      LOYALTYPOINTSMEMBNO: '',
      TOMSVATTAXNO: 0,
      TOMSVATAMT: 0,
      PDUNIQUEREF: '',
      LOYALTYPOINTSAMT: 0,
      PDFCURID: '',
      PDFCAMT: 0,
      PDEXRATE: 0,
      SELLTAXNO: 0,
      SELLTAXRATE: 0,
      FC_SELLTAXAMT: 0,
      HC_SELLTAXAMT: 0,
      PONOCCDeferredPosted: 0,
      BOOKEDBY: '',
      TaxExcludingSalesTax: 0,
      BaseFare: 0,

      PDQDESC: '',
      CRDDESC: '',
      PMTDESC: '',
      PAYM: '',
      GSTHSTOnComm: 0,
      InterInd: '',
      //12698
      CostingSupplierGrossValue: 0,
      CostingSupplierServiceFee: 0,
      CostingTaxNo: 0,
      CostingTaxRate: 0,
      CostingSupplierTaxAmount: 0,
      CostingSupplierNetBuyPrice: 0,
      CostingCustomerSalesValue: 0,
      CostingCustomerServiceFee: 0,
      CostingCustomerTaxAmount: 0,
      CostingCustomerNetSellPrice: 0,
      CostingVATType: 0,
      CostingSuppComm: 0,
      SupplierDeposit: 0
      //12698
    }
  };
  constructor(public commonservice: CommonserviceService,  public objFolderPrice: FolderpriceService, private route: ActivatedRoute, private router: Router, private api: RailService, private apiShared: ShareDataService, private spinner: NgxSpinnerService, private apiPO: PocreationService, private translateapi: TranslateService, private toastr: ToastrService) { }

  //@Input() guid: string;
  //@Input() orderno: number;
  private guid: string = this.route.snapshot.params['guidItinerary'];//'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/';
  private orderno = this.route.snapshot.params['orderNo']
  public FolderQuoteCreatePO = false;
  public FolderQuoteDeletePO = false;
  ngOnInit() {
    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderno !== orderno) {
        return this.router.navigate(['PenAir/Home']);
      }
    window.scrollTo(0, 0);
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;
      this.invoice = false;//By Caseid-12621
    }
    else {
      this.formeditable = false;
      this.invoice = true;//By Caseid-12621
      this.createPoInvoice = true;//By Caseid-12621
    }
    if (this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund")//By Caseid-12780
    {
      this.formeditable = false;
      this.FolderHideStatus = true;//12780
    }
    this.folderEditMode = "false";
    // this.apiShared.folderEditMode=this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.getRailDetails(this.orderno, this.guid);
    //'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/
    var folderQuotes = sessionStorage.getItem('folderQuotes');//12663
    if (folderQuotes) {
      this.FolderQuoteCreatePO = true;
      this.FolderQuoteDeletePO = true;
    }
    else {
      this.FolderQuoteCreatePO = false;
      this.FolderQuoteDeletePO = false;
    }
  }
  getRailDetails(id, guid) {
    this.spinner.show();
    this.subscription.add(this.api.getRail(id, guid, true)
      .subscribe(data => {
        if (data != null && data != undefined) {
          this.railDetail = data;
          this.PONO = this.railDetail.ResponseData.PONO;

          if (this.PONO > 0) {
            this.pocreated = false;
            this.invoice = true;//By Caseid-12621
            if (this.folderStatus == "Invoiced")//By Caseid-12621
            {
              this.pocreated = true;
            }
          }
          else {
            this.pocreated = true;
          }

          this.spinner.hide();

          //this.isLoadingResults = false;
          this.loaded = true
          this.railDetail.ResponseData.InterInd = data.ResponseData.InterInd == "" ? "" : data.ResponseData.InterInd == "I" ? "International" : (data.ResponseData.InterInd == "D" ? "Domestic" : (data.ResponseData.InterInd == "T" ? "Transborder" : ""))
        } else {
          this.spinner.hide();
        }
      }));
  }
  onEdit() {
    this.router.navigate(['PenAir/Product/RailDetailsEdit', this.orderno, this.guid + '/']);
  }

  onCancel() {
    //this.router.navigate(['/PenAir/Folder/Products',this.orderno]);
    this.commonservice.isfinalsaved = false;
    sessionStorage.setItem('pagevalue', '0')
    var folderQuotes = sessionStorage.getItem('folderQuotes');
    if (folderQuotes) {
      this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno]);
    }
    else {
      this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno]);
    }

  }
  async onCreatePO() {
    this.spinner.show();
    this.ticketDate = (this.railDetail.ResponseData.TICKETDATE == null ? "01-Jan-1900" : this.railDetail.ResponseData.TICKETDATE);//By Caseid-12619
    if (Boolean(await this.commonservice.checkPermission1('01.12.01.26')) == true) {
      if (this.ticketDate == "1900-01-01T00:00:00")//By Case id-12619
      {
        this.spinner.hide();
        this.subscription.add(this.translateapi.get(['Messages.InvalidTicketDate']).subscribe((translations) => {

          this.showMsg = translations['Messages.InvalidTicketDate'];;
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        return;
      }
      else//By Caseid-12619
      {
        this.POCreation = new POCreationDetail();
        this.POCreation.folderNo = this.orderno;
        this.passName = this.apiShared.PassengerNames;
        this.projNo = this.apiShared.projNo;
        this.POCreation.projNo = this.projNo;
        this.POCreation.passName = this.passName;
        this.POCreation.postDate = new Date(this.railDetail.ResponseData.TICKETDATE);
        this.POCreation.POGUIDItinerary = this.railDetail.ResponseData.GUIDItinerary;
        this.POCreation.Status=this.folderStatus;//11614
        this.subscription.add(this.apiPO.CreatePO(this.POCreation).subscribe(res => {
          if (res != null && res != undefined) {
            this.Pocreationresultobj = res;
            this.spinner.hide();
            if (this.Pocreationresultobj.ResponseData.success_bit == "True") {
              if (this.Pocreationresultobj.ResponseData.invoiceno > 0) {
                this.subscription.add(this.translateapi.get(['Messages.POCreated']).subscribe((translations) => {

                  this.showMsg = translations['Messages.POCreated'] + ':' + this.Pocreationresultobj.ResponseData.invoiceno;
                  this.toastr.success(this.showMsg, "", {//By case id-12630
                    timeOut: 5000,//By case id-12630
                    disableTimeOut: false,//By case id-12630
                    closeButton: true
                  })

                }));
                this.getRailDetails(this.orderno, this.railDetail.ResponseData.GUIDItinerary); //By Caseid-12621
                this.pocreated = false;
              }
              if (this.Pocreationresultobj.ResponseData.message == "PO Already Created") {
                this.spinner.hide();
                this.showMsg = "";
                this.subscription.add(this.translateapi.get(['Messages.POAlreadyCreated']).subscribe((translations) => {

                  this.showMsg = translations['Messages.POAlreadyCreated'];
                  this.toastr.warning(this.showMsg, "", {
                    timeOut: 0,
                    disableTimeOut: true,
                    closeButton: true
                  })
                  this.getRailDetails(this.orderno, this.railDetail.ResponseData.GUIDItinerary);
                }));

              }
            }
            else {
              this.spinner.hide();
              this.showMsg = "";
              this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {

                this.showMsg = this.Pocreationresultobj.ResponseData.message;
                this.toastr.error(this.showMsg, "", {
                  timeOut: 0,
                  disableTimeOut: true,
                  closeButton: true
                })

              }));
            }

          }
          else {
            this.spinner.hide();
          }
        }
        ));
      }
    }
    else {
      this.spinner.hide();
      this.showmsg('01.12.01.26');
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.26');
    }

  }
  async onDeletePO() {
    this.spinner.show();
    if (Boolean(await this.commonservice.checkPermission1('01.12.01.27')) == true) {
      //this.getAirticketDetails(this.orderNo,this.route.snapshot.params['guidItinerary']); 
      this.subscription.add(this.api.getRail(this.orderno, this.railDetail.ResponseData.GUIDItinerary, true)
        .subscribe(data => {
          if (data != null && data != undefined) {
            this.railDetail = data;
          }
        }));

      this.deletePO = new DeletePO();
      this.deletePO.Language = this.language;
      this.deletePO.FolderNo = this.orderno;
      if (this.railDetail != null && this.railDetail != undefined) {
        this.deletePO.PONO = this.railDetail.ResponseData.PONO;
        this.deletePO.POYearId = this.railDetail.ResponseData.PONOYEARID;
        this.deletePO.Guid = this.railDetail.ResponseData.GUIDItinerary;
      }
      this.subscription.add(this.apiPO.DeletePO(this.deletePO).subscribe(res => {
        if (res != null && res != undefined) {
          this.spinner.hide();
          this.DeleteInvoiceResponse = res;
          this.result = this.DeleteInvoiceResponse.ResponseData["message"];

          if (this.result.includes("PO Deleted:")) {
            this.spinner.hide();
            this.pocreated = true;
            this.invoice = false;//By Caseid-12621
            this.toastr.success(this.result, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          }
          else {
            this.spinner.hide();
            this.toastr.error(this.result, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })

          }
        }
      }));

    }
    else {
      this.spinner.hide();
      this.showmsg('01.12.01.27');
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.27');
    }



  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339

  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

  showmsg(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }

  onCopy()
  {
    debugger;
this.spinner.show();
   this.objFolderPrice.CopyProductDetails(this.orderno,this.railDetail.ResponseData.GUIDItinerary,"Rail").subscribe(x=>{
    debugger;
    this.spinner.hide();
    if(x.ResponseData.value==1)
{
  this.router.navigate([
    "/PenAir/Product/RailDetailsEdit",
    this.orderno,
    x.ResponseData.GUIDItinerary
  ]);
}

   });
  }
}
