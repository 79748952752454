import * as $ from 'jquery';
import { Injectable } from '@angular/core';

@Injectable()
export class L10nInterceptor {
  public intercept(lang: () => string) {
    $.ajaxPrefilter((options, originalOptions) => {
      let str = originalOptions.url;
      let documentsSuffix = "documents";
      let isDocumentsUrl = (str.indexOf(documentsSuffix) == str.length - documentsSuffix.length);

      if (isDocumentsUrl) {
          var d = JSON.parse(originalOptions.data);
          d.deviceInfo["Culture"] = lang();
          options.data = JSON.stringify(d);
      }
    });
  }
}