import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { PanelBarExpandMode } from "@progress/kendo-angular-layout";
import { CommonserviceService } from "../../Services/commonservice.service";

@Component({
  selector: "app-airticket-salestaxdetails-edit",
  templateUrl: "./airticket-salestaxdetails-edit.component.html",
  styleUrls: ["./airticket-salestaxdetails-edit.component.scss"]
})
export class AirticketSalestaxdetailsEditComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() fileTax;
  @Input() public pocreated;
  public visible = false;
  public cruise = false;
  constructor(public commonservice: CommonserviceService) {}

  ngOnInit() {
    this.CalculateTax();
    this.ActivateItSplit();

    if (this.parentForm.value["ITYPE"] === "Ticket") {
      this.visible = true;
      this.cruise = false;
    } else if (this.parentForm.value["ITYPE"] === "Cruise") {
      this.visible = false;
      this.cruise = true;
    } else {
      this.visible = true;
      this.cruise = false;
    }
  }

  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;

  public onChange(event: any): void {
    this.expandMode = parseInt(event.target.value, 10);
  }
  get fp() {
    return this.parentForm.controls;
  }
  public onPanelChange(event: any): void {
    console.log("stateChange: ", event);
  }

  public PurchaseTaxAmount: number;
  public OtherTaxAmount: number;
  public PurchaseValueAmount: number;
  public TotalTax: number;
  public itsplit1disable: boolean;
  public itsplit2disable: boolean;
  public itsplit3disable: boolean;
  public itsplit4disable: boolean;
  public itsplit5disable: boolean;
  public CalculateTax() {
    this.TotalTax =
      this.parentForm.get("BUY2").value +
      this.parentForm.get("BUY3").value +
      this.parentForm.get("BUY4").value +
      this.parentForm.get("BUY5").value;

    this.parentForm.controls.TotalTax.setValue(
      parseFloat(this.TotalTax.toFixed(2))
    );

    this.PurchaseTaxAmount =
      this.parentForm.get("ITSplit1AMT").value +
      this.parentForm.get("ITSplit2AMT").value +
      this.parentForm.get("ITSplit3AMT").value +
      this.parentForm.get("ITSplit4AMT").value +
      this.parentForm.get("ITSplit5AMT").value;

    this.parentForm.controls.PurchaseTax.setValue(
      parseFloat(this.PurchaseTaxAmount.toFixed(2))
    );
    this.OtherTaxAmount = this.TotalTax - this.PurchaseTaxAmount;

    this.parentForm.controls.OtherTax.setValue(
      parseFloat(this.OtherTaxAmount.toFixed(2))
    );

    this.PurchaseValueAmount =
      this.parentForm.get("BUY1").value + this.OtherTaxAmount;

    this.parentForm.controls.PurchaseValue.setValue(
      parseFloat(this.PurchaseValueAmount.toFixed(2))
    );
    this.parentForm.controls.FC_Tax5_Buy.setValue(
      -1 * parseFloat(this.PurchaseTaxAmount.toFixed(2))
    );
    this.parentForm.controls.FC_Tax5_Sell.setValue(
      -1 * parseFloat(this.PurchaseTaxAmount.toFixed(2))
    );
    this.parentForm.controls.BUY6_Tax5.setValue(
      -1 * parseFloat(this.PurchaseTaxAmount.toFixed(2))
    );
    this.parentForm.controls.SELL6_Tax5.setValue(
      -1 * parseFloat(this.PurchaseTaxAmount.toFixed(2))
    );
    this.calculatetotal();
  }
  calculatetotal() {
    this.parentForm.controls.FC_Tot_Buy.setValue(
      this.parentForm.get("FC_Fare_Buy").value +
        this.parentForm.get("FC_Tax1_Buy").value +
        this.parentForm.get("FC_Tax2_Buy").value +
        this.parentForm.get("FC_Tax3_Buy").value +
        this.parentForm.get("FC_Tax4_Buy").value +
        this.parentForm.get("FC_Tax5_Buy").value
    );

    this.parentForm.controls.FC_Tot_CommAmt.setValue(
      this.parentForm.get("FC_Fare_CommAmt").value +
        this.parentForm.get("FC_Tax1_CommAmt").value +
        this.parentForm.get("FC_Tax2_CommAmt").value +
        this.parentForm.get("FC_Tax3_CommAmt").value +
        this.parentForm.get("FC_Tax4_CommAmt").value +
        this.parentForm.get("FC_Tax5_CommAmt").value
    );

    this.parentForm.controls.FC_Tot_Sell.setValue(
      this.parentForm.get("FC_Fare_Sell").value +
        this.parentForm.get("FC_Tax1_Sell").value +
        this.parentForm.get("FC_Tax2_Sell").value +
        this.parentForm.get("FC_Tax3_Sell").value +
        this.parentForm.get("FC_Tax4_Sell").value +
        this.parentForm.get("FC_Tax5_Sell").value
    );

    //----------------------HC
    this.parentForm.controls.BUYTOT.setValue(
      this.parentForm.get("BUY1").value +
        this.parentForm.get("BUY2").value +
        this.parentForm.get("BUY3").value +
        this.parentForm.get("BUY4").value +
        this.parentForm.get("BUY5").value +
        this.parentForm.get("BUY6_Tax5").value
    );

    this.parentForm.controls.COMMPTOT.setValue(
      this.parentForm.get("COMMP1").value +
        this.parentForm.get("COMMP2").value +
        this.parentForm.get("COMMP3").value +
        this.parentForm.get("COMMP4").value +
        this.parentForm.get("COMMP5").value +
        this.parentForm.get("COMMP6_Tax5").value
    );

    this.parentForm.controls.COMMATOT.setValue(
      this.parentForm.get("COMMA1").value +
        this.parentForm.get("COMMA2").value +
        this.parentForm.get("COMMA3").value +
        this.parentForm.get("COMMA4").value +
        this.parentForm.get("COMMA5").value +
        this.parentForm.get("COMMA6_Tax5").value
    );

    this.parentForm.controls.SELLTOT.setValue(
      this.parentForm.get("SELL1").value +
        this.parentForm.get("SELL2").value +
        this.parentForm.get("SELL3").value +
        this.parentForm.get("SELL4").value +
        this.parentForm.get("SELL5").value +
        this.parentForm.get("SELL6_Tax5").value
    );
  }
  ActivateItSplit() {
    if (this.parentForm.get("ITSplit1").value == "" || this.pocreated == false)
      this.itsplit1disable = true;
    else this.itsplit1disable = false;

    if (this.parentForm.get("ITSplit2").value == "" || this.pocreated == false)
      this.itsplit2disable = true;
    else this.itsplit2disable = false;

    if (this.parentForm.get("ITSplit3").value == "" || this.pocreated == false)
      this.itsplit3disable = true;
    else this.itsplit3disable = false;

    if (this.parentForm.get("ITSplit4").value == "" || this.pocreated == false)
      this.itsplit4disable = true;
    else this.itsplit4disable = false;

    if (this.parentForm.get("ITSplit5").value == "" || this.pocreated == false)
      this.itsplit5disable = true;
    else this.itsplit5disable = false;
  }
  Calculateform() {
    if (
      this.parentForm.get("TaxExcludingSalesTax").value !== 0 &&
      this.parentForm.get("TaxExcludingSalesTax").value !== null
    ) {
      this.parentForm.controls.BaseFare.setValue(
        parseFloat(
          (
            this.parentForm.get("FCSell").value -
            this.parentForm.get("TaxExcludingSalesTax").value
          ).toFixed(2)
        )
      );
    } else {
      this.parentForm.controls.BaseFare.setValue(
        parseFloat(this.parentForm.get("FCSell").value.toFixed(2))
      );
    }
  }
}
