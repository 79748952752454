import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FolderSearchCriteriaDTO, InboxClosedParameterDTO } from '../Models/folder-search-criteria-dto';
import { AlertListDTO } from '../Models/alertdetails';
import { ThrowStmt } from '@angular/compiler';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EnvService } from 'src/env.service';
import { CashPaymentDTO,AtolReportDTO } from '../Models/cash-payment-dto.model';
import { catchError, tap } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
@Injectable({
  providedIn: 'root'
})
export class SearhdtotransferService {
  pdfurl:string;
  url:string;
  formData:AtolReportDTO;
  constructor(private http: HttpClient,private env: EnvService) { this.pdfurl=env.baseUrl}
  InboxClosedParameterDTO:InboxClosedParameterDTO;

  folderSearchCriteriaDto: FolderSearchCriteriaDTO =localStorage.getItem('SrchTranferDTO')===null?new FolderSearchCriteriaDTO(): JSON.parse( localStorage.getItem('SrchTranferDTO'));
  private messageSource = new BehaviorSubject(this.folderSearchCriteriaDto);  
  SrchTranferDTO = this.messageSource.asObservable();


  FolderSearch(folderSearchCriteriaDto) {
   ////alert(JSON.stringify(folderSearchCriteriaDto))
    this.messageSource.next(folderSearchCriteriaDto)
    
    localStorage.setItem('SrchTranferDTO',JSON.stringify(folderSearchCriteriaDto));
  this.folderSearchCriteriaDto=folderSearchCriteriaDto;
  }
  downloadPDF(): Observable<any> {

    return this.http.get<any>( this.url+'/viewpdf1/',
    {  } );  
    // return this.http.get(this.url+'/viewpdf/', { responseType: 'blob', observe: 'response' }).pipe(
    //   map((result:HttpResponse<Blob>) => {
    //     console.log(result);
    //    // saveAs(result, "Quotation.pdf");
    //     return result;
     
    //   }))};
  
  }
  public  AtolReport(): Observable<AtolReportDTO> {
    var body = {
      ...this.formData,
      
     
    };
    return this.http.post<any>(this.pdfurl+'AtolReport', body, httpOptions).pipe(
      tap((railRes: any) => console.log(`added Rail w/ id=`)),
     
    );
  }
}
