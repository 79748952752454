import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EnvService } from 'src/env.service';
import { DefaultCharges } from '../Models/default-charges.model';
import { CommonserviceService } from './commonservice.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class DefaultChargesService {
  apiUrl: any;
  autoNo: any;
  AUTONO: any;
  data: any;
  type: any;
  edit: any;
  constructor(private env: EnvService, private http: HttpClient, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }
  GetdefaultChargesById(autono: any): Observable<any> {
    const url = `${this.apiUrl}` + 'GetdefaultChargesById' + `/${autono}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`Get default Charges ById`)),
      catchError(this.commonservice.handleError<any[]>(`Get defaultCharges ById`))
    );
  }
  GetDefaultChargesList(): Observable<any> {
    const url = `${this.apiUrl}` + 'GetDefaultChargesList';
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`Select All GetDefaultChargesList`)),
      catchError(this.commonservice.handleError<any>(`Select All GetDefaultChargesList`))
    );
  }
  AddDefaultChargesDetails(EmailSettingsDetailsAddDto): Observable<any> {
    debugger;
    return this.http.post<any>(this.apiUrl + 'AddDefaultChargesDetails', EmailSettingsDetailsAddDto, httpOptions).pipe(

      tap(_ => console.log(`Save DefaultCharges Details`)),
      catchError(this.commonservice.handleError<any[]>(`Save DefaultCharges Details`))
    );
  }
  AddDefaultChargeToProduct(DefaultChargeToProduct): Observable<any> {
    debugger;
    return this.http.post<any>(this.apiUrl + 'AddDefaultChargeToProduct', DefaultChargeToProduct, httpOptions).pipe(
      catchError(this.commonservice.handleError<any[]>(`AddDefaultChargeToProduct`))
    );
  }
  DeleteDefaultChargesDetails(comid: any): Observable<any> {
    const url = `${this.apiUrl}` + 'DeleteDefaultChargesDetails' + `/${comid}`;
    return this.http.post<any>(url, httpOptions).pipe(
      tap(_ => console.log(`DeleteDefaultChargesDetails`)),
      catchError(this.commonservice.handleError<any>('DeleteDefaultChargesDetails'))
    );
  }
}
