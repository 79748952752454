import { Injectable } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { LookUpDetails, LookUpDetailReport } from "./look-up-details";
import { HotelDetail } from "../Models/hotel-detail";
import { Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";

import { TrialBalace } from "../Models/folder-summary";
import { EnvService } from "../../env.service";
import { catchError, tap } from "rxjs/operators";
import { CommonserviceService } from "./commonservice.service";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class LookUpDetailsService {
  finacialdata: TrialBalace;
  url: string;
  OrderType: any;
  constructor(
    private http: HttpClient,
    private env: EnvService,
    public commonservice: CommonserviceService
  ) {
    this.url = env.baseUrl;
  }

  GetLookupById(
    provider: string,
    usrCode: string,
    name: string
  ): Observable<LookUpDetails[]> {
    let headers = new HttpHeaders();
    provider = provider.toString().trim();
    if (usrCode != null && usrCode != '')
      usrCode = usrCode.toString().trim();
    if (name != null && name != '')
      name = name.toString().trim();
    headers.set("Content-Type", "application/json");

    return this.GetLookupByIdAndName(provider, name, usrCode)
  }
  GetAllLookup(provider: string): Observable<LookUpDetails[]> {
    const obj = {
      provider: provider,

    };
    debugger;
    if (provider != null) {
      return this.http.post(this.url + `GetAllLokup`, obj).pipe(
        tap(),
        catchError(this.commonservice.handleError<any>("GetAllLokup"))
      );
    }
    else {
      var array: any;
      return array;
    }
  }


  GetMenuListforInbox(): Observable<any[]> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<any[]>(this.url + "GetMenuListforInbox/", {
      headers,
    });
  }
  FindDesignation(provider: string): Observable<LookUpDetails[]> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<LookUpDetails[]>(
      this.url + "/findDesignation/" + provider,
      { headers }
    );
  }

  SearchDestination(
    searchvalue: string,
    flag: string
  ): Observable<LookUpDetails[]> {
    let headers = new HttpHeaders();
    var provider = "DESTINATION"; //Caseid:12753
    searchvalue = searchvalue.trim();


    if (flag == "1") {

      return this.SearchCommonLookUps(provider, searchvalue, searchvalue, 0, "", flag);
    }
    else {
      return this.SearchCommonLookUps(provider, "", "", 0, searchvalue, flag);
    }

  }
  SearchSupplier(searchvalue: string): Observable<LookUpDetails[]> {
    let headers = new HttpHeaders();
    debugger;
    searchvalue = searchvalue.trim(); //Caseid:12753
    if (searchvalue.trim() != "") {

      return this.SearchCommonLookUps("SUPPLIERS", searchvalue, searchvalue, 0, "", 0);
    }
  }

  //13507
  SearchNonBspSupplier(provider: string): Observable<LookUpDetails[]> {
    let headers = new HttpHeaders();
    provider = provider.toString().trim(); //Caseid:12753
    if (provider.trim() != "") {

      return this.SearchCommonLookUps("NonBSPSUPPLIERS", provider, provider, 0, "", 0);
    }
  }


  findSupplier(provider: string): Observable<LookUpDetails[]> {
    debugger;
    if (provider != null && provider != '') {
      let headers = new HttpHeaders();
      provider = provider.toString().trim();



      return this.SearchCommonLookUps("SUPPLIERS", "", "", 0, provider, 0);
    }

  }
  SearchHotel(provider: string): Observable<LookUpDetails[]> {
    if (provider != null && provider != '') {
      provider = provider.toString().trim();

      return this.SearchCommonLookUps("HOTEL", provider, provider, 0, "", 0);

    }
  }
  findHotel(provider: string): Observable<LookUpDetails[]> {
    provider = provider.toString().trim();

    return this.SearchCommonLookUps("HOTEL", "", "", 0, provider, 0);
  }

  SearchCustomer(provider: string, uid: any): Observable<LookUpDetails[]> {
    debugger;
    let headers = new HttpHeaders();
    provider = provider.toString().trim();
    if (provider.trim() != "") {

      return this.SearchCommonLookUps("CUSTOMER", provider, provider, uid, "", 0);
    }
  }
  SearchPassenger(provider: string, uid: any): Observable<LookUpDetails[]> {
    let headers = new HttpHeaders();
    provider = provider.toString().trim();
    if (provider.trim() != "") {

      return this.SearchCommonLookUps("PASSENGER", provider, provider, uid, "", 0);
    }
  }
  findCustomer(provider: string): Observable<LookUpDetails[]> {
    if (provider != null && provider != '') {
      provider = provider.toString().trim();

      debugger;

      return this.SearchCommonLookUps("CUSTOMER", "", "", 0, provider, 0);
    }
  }

  findPassenger(provider: string): Observable<LookUpDetails[]> {
    if (provider != null && provider != '') {
      provider = provider.toString().trim();

      return this.SearchCommonLookUps("PASSENGER", "", "", 0, provider, 0);
    }
  }
  SearchCity(provider: string, flag: string): Observable<LookUpDetails[]> {
    let headers = new HttpHeaders();
    if (provider != null && provider != '') {
      provider = provider.toString().trim();
      if (provider.trim() != "") {

        if (flag == "1") {

          return this.SearchCommonLookUps("DESTINATION", provider, provider, 0, "", flag);
        }
        else {
          return this.SearchCommonLookUps("DESTINATION", "", "", 0, provider, flag);
        }
      }
    }
  }
  GetCity(provider: string, flag: string): Observable<LookUpDetails[]> {
    if (provider != null && provider != '') {
      provider = provider.toString().trim();

      if (flag == "1") {

        return this.SearchCommonLookUps("CITY", provider, provider, 0, "0", flag);
      }
      else {
        return this.SearchCommonLookUps("CITY", "", "", 0, provider, flag);
      }
    }
  }

  GetFinacialYear(id: any): Observable<LookUpDetails[]> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<LookUpDetails[]>(this.url + "/GetFinancialDate" + `/${id}`, {
      headers,
    });
  }



  GetFinancialDateforPANDLReport(YearID: any): Observable<LookUpDetails> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<LookUpDetails>(
      this.url + "GetFinancialDateforPANDLReport" + `/${YearID}`
    );
  }
  CheckdateintheFinancialYear(
    Fromdate: any,
    Todate: any
  ): Observable<LookUpDetails> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<LookUpDetails>(
      this.url +
      "PenAirWebFinancialDateChecking" +
      `/${Fromdate}` +
      `/${Todate}`
    );
  }
  CheckdateintheFinancialYeartrialbalance(
    Fromdate: any,
    Todate: any
  ): Observable<LookUpDetails> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<LookUpDetails>(
      this.url +
      "PenAirWebFinancialDateCheckingtrialbalance" +
      `/${Fromdate}` +
      `/${Todate}`
    );
  }
  CheckdateintheFinancialYearForReport(
    Fromdate: any,
    Todate: any
  ): Observable<LookUpDetailReport> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<LookUpDetailReport>(
      this.url +
      "CheckdateintheFinancialYearForReport" +
      `/${Fromdate}` +
      `/${Todate}`
    );
  }

  AddHotelDetails(FOLDERNO, SUPPNAME, HOTELNAME, TYPE) {
    const obj = {
      FOLDERNO: FOLDERNO,
      SUPPNAME: SUPPNAME,
      HOTELNAME: HOTELNAME,
      TYPE: TYPE,
    };

    this.http
      .post(`http://192.168.1.6/PenAirWebApiService/AddHotelDetails`, obj)
      .subscribe((res) => console.log("Done"));
  }

  GetHotelDetailsById(
    folderNo: string,
    guitinerary: string
  ): Observable<HotelDetail[]> {
    return this.http.get<HotelDetail[]>(
      "http://192.168.1.6/PenAirWebApiService/GetHotelDetailsById/" +
      folderNo +
      "/" +
      guitinerary
    );
  }
  GetPDQCard(cardno: any): Observable<any[]> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<any>(this.url + "GetCardType/" + cardno, { headers });
  }
  //12711
  GetCChargeByPaymentGateway(cardno: any): Observable<any[]> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<any>(
      this.url + "GetCChargeByPaymentGateway/" + cardno,
      { headers }
    );
  }
  GetPassengerEmailList(
    currency: any,
    ppid: any,
    partycode: any,
    orderno: any
  ): Observable<LookUpDetails[]> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<LookUpDetails[]>(
      this.url +
      "GetPassengerEmailList/" +
      currency +
      "/" +
      ppid +
      "/" +
      partycode +
      "/" +
      orderno,
      { headers }
    );
  }
  GetDefaultCrediCardPDQ(
    userid: any,
    FolderNo: any,
    CurrencyId: any
  ): Observable<any[]> {
    var RequestDto = {
      userid: userid,
      FolderNo: FolderNo,
      CurrencyId: CurrencyId,
    };
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    const url = `${this.url}` + "GetDefaultCrediCardPDQ"; /// ${id}
    return this.http.post(url, RequestDto, httpOptions).pipe(
      tap(),
      catchError(this.commonservice.handleError<any>("GetDefaultCrediCardPDQ"))
    );
  }
  GetLookupByUserCode(provider: string, usercode: any): Observable<LookUpDetails[]> {
    let headers = new HttpHeaders();
    var testDTOObject = {
      Filter: provider,
      Code: usercode,

    };
    headers.set("Content-Type", "application/json");
    const url = `${this.url}` + "GetLookupByUserId"; /// ${id}
    return this.http.post(url, testDTOObject, httpOptions).pipe(
      tap(),
      catchError(this.commonservice.handleError<any>("GetLookupByUserId"))
    );
  }
  GetLookupByIdAndName(provider: string, Name: any, Usercode): Observable<LookUpDetails[]> {
    debugger;
    let headers = new HttpHeaders();
    var testDTOObject = {
      Filter: provider,
      Code: Usercode,
      Name: Name
    };
    headers.set("Content-Type", "application/json");
    const url = `${this.url}` + "GetLookupByIdAndName"; /// ${id}
    return this.http.post(url, testDTOObject, httpOptions).pipe(
      tap(),
      catchError(this.commonservice.handleError<any>("GetLookupByIdAndName"))
    );
  }

  getCcChargeAndRate(cardno: any, cardid: any): Observable<any[]> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<any>(
      this.url + "GetCCCharge/" + cardno + "/" + cardid,
      { headers }
    );
  }

  //12728
  GetReportDetails(FolderNo: string): Observable<LookUpDetails[]> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<LookUpDetails[]>(
      this.url + "/GetReportDetails/" + FolderNo,
      { headers }
    );
  }
  //14077
  public async getReportSettings(FolderNo: string, userid: any): Promise<boolean> {
    let headers = new HttpHeaders();
    var data = await this.http.get<boolean>(this.url + "/GetReportSettings/" + FolderNo + '/' + userid,
      { headers }).toPromise();
    return data;
  }
  SearchCommonLookUps(provider, searchvalue, Name, UserID, InternalCode, isfind): Observable<any> {
    const obj = {
      provider: provider,
      Name: Name,
      UserID: UserID,
      searchvalue: searchvalue,
      InternalCode: InternalCode,
      isfind: isfind,
    };
    return this.http.post(this.url + `SearchCommonLookUps`, obj).pipe(
      tap(),
      catchError(this.commonservice.handleError<any>("SearchCommonLookUps"))
    );

  }

  GetDefaultCountry(): Observable<any> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<any[]>(
      this.url + "/GetDefaultCountry/",
      { headers }
    );
  }
  public async GetTaxSplit(TaxType: any): Promise<Observable<any>> {
    const url = `${this.url}` + 'GetTaxSplit' + `/${TaxType}`;
    var result = await this.http.get<any>(url).pipe(
      catchError(this.commonservice.handleError<any>('GetTaxSplit'))
    ).toPromise();
    return result;

  }


  GetSupplierorCustomerCountry(provider: string, code: any): Observable<any> {
    let headers = new HttpHeaders();
    var testDTOObject = {
      provider: provider,
      InternalCode: code,

    };
    headers.set("Content-Type", "application/json");
    const url = `${this.url}` + "getSupplierorCustomerCountry"; /// ${id}
    return this.http.post(url, testDTOObject, httpOptions).pipe(
      tap((_) => console.log("getSupplierorCustomerCountry")),
      catchError(this.commonservice.handleError<any>("getSupplierorCustomerCountry"))
    );
  }
}
