import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-internal-notes',
  templateUrl: './internal-notes.component.html',
  styleUrls: ['./internal-notes.component.scss']
})
export class InternalNotesComponent implements OnInit {
  interanlnotes: FormGroup;
  public internalNotes:any;
  @Output() notesNotToBePrinted = new EventEmitter<string>();
  constructor(private formBuilder: FormBuilder,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.interanlnotes=this.formBuilder.group({

      InternalNotes : [''],
    })
  }

  public onClickAdd()
  { debugger;
    this.spinner.show();
    this.internalNotes = (this.interanlnotes.controls.InternalNotes.value==null?"":this.interanlnotes.controls.InternalNotes.value);
    this.notesNotToBePrinted.emit(this.internalNotes);
    setTimeout(() => {
      /** spinner ends after 10 seconds */
      this.spinner.hide();
    }, 2000);
  }

  public onCancel()
  {
    this.notesNotToBePrinted.emit("formClosed");
  }
}
