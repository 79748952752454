export class Hote102DTO {
     IsSuccess: boolean;
    Message: string;
    ResponseData: {
         RRID :any;
         HID :any
         PRTYCODE :any
         CID :any
         HIDP :any
         Commission :any
         UID :any;
         LUPDATE :any
         PID :any
         Rep :any
         ExRate :any
         Hotelname:string
         CommionOnGross:any;
          PricingGroup :any;
          PricingGroupID :any;
    }
}
export class Hote103MasterDTO {
     RRID :any;
         HID :any
         PRTYCODE :any
         CID :any
         HIDP :any
         Commission :any
         UID :any;
         LUPDATE :any
         PID :any
         Rep :any
         ExRate :any
         Hotelname:string
}
export class Hote103DTO {
     RRID :any
     SLNO :any
    RFrom :any
    RTo :any
    TYPE :any
     Rate :any
    Gross :any
     Comm :any
     Buy :any
     Tax :any
     PFC :any
     PLC :any
     C1stMarkup :any
     Total :any
     LC :any
     OCharge :any
     Sell :any
    Plan :any
     AutoNo :any
     BuyHC:any
}

