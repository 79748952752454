import { Component, OnInit, ViewChild,Input } from '@angular/core';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FolderActivityReportbyProductService } from "../../Services/folder-activity-reportby-product-service";
import { FolderActivityReportbyProductDTO } from "../../Models/folder-activity-reportby-product-dto";
import { Subscription } from "rxjs";
import { CommonserviceService } from "../../Services/commonservice.service";
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { ShareDataService } from '../../Services/share-data.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LookUpDetails } from '../../Services/look-up-details';
import { DomSanitizer } from "@angular/platform-browser";
import { aggregateBy, State, process } from "@progress/kendo-data-query";
import { exportPDF, Group } from "@progress/kendo-drawing";
import { Workbook } from "@progress/kendo-angular-excel-export";
import { EmailService } from "../../Services/email.service";
import { saveAs } from "@progress/kendo-file-saver";
import { IntlService } from "@progress/kendo-angular-intl";
import { FileService } from 'src/app/Services/file.service';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { NgxSpinnerService } from "ngx-spinner";
import { FolderActivityReportbyProductSummaryComponent } from '../../folder-activity-reportby-product-summary/folder-activity-reportby-product-summary.component';
import { FolderActivityReportbyProductDetailedComponent } from '../../folder-activity-reportby-product-detailed/folder-activity-reportby-product-detailed.component';
@Component({
  selector: 'app-rpt-folder-activity-product',
  templateUrl: './rpt-folder-activity-product.component.html',
  styleUrls: ['./rpt-folder-activity-product.component.scss']
})
export class RptFolderActivityProductComponent implements OnInit {
  //#Caseid:13175 Folder Activity Report By Product

  private subscription: Subscription = new Subscription();
  @ViewChild('autocompletecustomer') public autocompletecustomer: AutoCompleteComponent;
  public FolderActivityReportbyProductDTO: FolderActivityReportbyProductDTO;
  constructor(private lookupApi: LookUpDetailsService,
    public intl: IntlService,
    public common: CommonserviceService,
    private apiShared: ShareDataService,
    public email: EmailService,
    private sanitizer: DomSanitizer,
    private translateapi: TranslateService,
    private toastr: ToastrService,
    private emailapi: FileService,
    private router: Router,
    private spinner: NgxSpinnerService,
    public FolderActivityReportbyProductCmp: FolderActivityReportbyProductSummaryComponent,
    public FolderActivityReportbyProductDetailedCmp: FolderActivityReportbyProductDetailedComponent,
    private FldrActivityReportbyProductService: FolderActivityReportbyProductService) { }
  public state: State = {
    skip: 0,
    take: 5,

  };
  public showMsg: any;
  image: any;
  opened = false;
  openSearch = false;
  RptHdrSel_TemplateName: any = "";
  RptHdrSel_AutoNo: any = '';
  submitted: any = false;
  public showtotal = false;
  FolderActivityDetailed: any = false;
  opentemplate: any = false;
  public FolderNo: any = '';
  public OrderNo: any = '';
  public dropdown: boolean = true;
  lookupname: any = 'CUSTOMER';
  lookuptype: string = '';
  @Input() drop: boolean = false;
  userID: any = 0;
  public Branch = "";
  public SummaryGridData: any;
  public BusinessArea = "";
  public BranchCode = "";
  public BArea = "";
  public Customer = "";
  public customerGroupCode = "";
  public customerGroupName = "";
  public PartyCode: any = 0;
  public TypesOfSales = 0;
  public TypesOfSalesName = "All";
  public ReportType = 0;
  public ReportTypeName = "Summary";
  public isShown: any;
  public rptTypeSelectedItem: any = { text: "Summary", value: 0 };
  public ProductCode = "";
  public ProductName = "";
  public TravelDateFrom = null;
  public TravelDateTo = null;
  public CreateDateFrom = null;
  public CreateDateTo = null;
  public CostIncurredDateFrom = null;
  public CostIncurredDateTo = null;
  public InvoiceDateFrom = null;
  public InvoiceDateTo = null;
  public FACreateDateFrom: any = "";
  public FACreateDateTo: any = "";
  public FACostIncurredDateFrom: any = "";
  public FACostIncurredDateTo: any = "";
  public FAInvoiceDateFrom: any = new Date();
  public FAInvoiceDateTo: any = new Date();
  public enableStatisticalPosting = false;
  public CustomerCreditGroupCode = "";
  public CustomerCreditGroupName = "";
  public TableandField: any;
  BRMultipleSelectedItems: any = '';
  TempList: Array<LookUpDetails["ResponseData"]> = [];
  public TemplateSelectedItem: LookUpDetails["ResponseData"];

  fromId: string;
  bccId: string;
  branchList: Array<LookUpDetails["ResponseData"]> = [];
  BranchSelectedItem: any;
  BusinessAreaSelectedItem: any;
  businessAreaList: Array<LookUpDetails["ResponseData"]> = [];
  CustomerList: Array<LookUpDetails["ResponseData"]> = [];
  customerGroupList: Array<LookUpDetails["ResponseData"]> = [];
  public customerSelectedItem: LookUpDetails["ResponseData"];
  public customerSelected: String;
  public customerGroupSelectedItem: LookUpDetails["ResponseData"];
  customerCreditGroupList: Array<LookUpDetails["ResponseData"]> = [];
  public customerCreditGroupSelectedItem: LookUpDetails["ResponseData"];
  ProductList: Array<LookUpDetails["ResponseData"]> = [];
  public ProductSelectedItem: LookUpDetails["ResponseData"];
  //public TypesOfSalesSelectedItem: LookUpDetails["ResponseData"];
  public TypesOfSalesSelectedItem: any = { text: "All", value: 0 };
  public TypesOfSalesList: Array<{ text: string, value: number }>
    = [{ text: "All", value: 0 }, { text: "Direct Sales only", value: 1 }, { text: "Exclude Direct Sales", value: 2 }];
  public ReportTypeList: Array<{ text: string, value: number }> = [{ text: "Summary", value: 0 }, { text: "Detailed", value: 1 }]
  public customerCreditGroupSelected: String;
  public mcodeInclude: boolean = true;
  public mcodeExclude: boolean = false;
  public type: string = "BRID";
  public mcodeMultiSelectPopUp: boolean = false;

  public BranchMultiSelectType: any = 0;
  public BRQuery: any;
  public PassQuery = "";
  MsInput: any = '';
  MCQuery: any = '';
  BRMultipleSelected = {
    SelectedItems: '',
    isIncluded: true
  }
  ngOnInit(): void {
    this.email.Isgridpdf = false;
    this.userID = +this.apiShared.uid;
    this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_UserId = this.userID;
    this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ModuleName =
      "FolderActivityReportbyProductSummary";
    sessionStorage.setItem('Componentname', "FolderActivityReportbyProduct");

    debugger;
    if (this.FldrActivityReportbyProductService.formData && this.FldrActivityReportbyProductService.formData.ReportGenarated == true) {
      if (this.FldrActivityReportbyProductService.formData.MCMultipleSelect == true) {
        this.BRMultipleSelected.SelectedItems = this.FldrActivityReportbyProductService.formData.BranchCodeList;

        if (this.FldrActivityReportbyProductService.formData.isIncluded == true) {
          this.BranchMultiSelectType = 1;
        } else {
          this.BranchMultiSelectType = 2;
        }
        this.mcodeInclude = this.FldrActivityReportbyProductService.formData.isIncluded;
        this.mcodeExclude = !this.FldrActivityReportbyProductService.formData.isIncluded;
      } else {
        // this.MarketCodeSelectedItem=this.Folderqueryreport.formData.MarkettingCodeSelectedItem
        // console.log("Marketing code selected item==",this.MarketCodeSelectedItem.Code);
      }
      // this.Folderqueryreport.formData.ReportGenarated =false;
    }
    this.FolderActivityReportbyProductCmp.LoadTemplate();
    this.subscription.add(this.lookupApi.GetAllLookup('BRANCH').subscribe(
      (data) => {
        debugger;
        this.branchList = data["ResponseData"]
        if (
          this.FldrActivityReportbyProductService.formData != undefined &&
          this.FldrActivityReportbyProductService.formData.Branch != null
        ) {
          this.BranchSelectedItem = this.branchList.find(item => item.Name == this.FldrActivityReportbyProductService.formData.BranchName);
          if (this.BranchSelectedItem != undefined) {
            this.BranchCode = this.BranchSelectedItem.Code;
            this.Branch = this.BranchSelectedItem.Name;
          }

        }


        else if (this.FldrActivityReportbyProductService.formData && this.FldrActivityReportbyProductService.formData.ReportGenarated == true) {
          if (this.FldrActivityReportbyProductService.formData.MCMultipleSelect == false) {
            this.BranchSelectedItem = this.branchList.find(item => item.Name == this.FldrActivityReportbyProductService.formData.BranchName);
          }
          this.FldrActivityReportbyProductService.formData.ReportGenarated = false;
        }

      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('BUSINESSAREA').subscribe(
      (data) => {
        this.businessAreaList = data["ResponseData"]
        if (
          this.FldrActivityReportbyProductService.formData != undefined &&
          this.FldrActivityReportbyProductService.formData.BArea != null
        ) {

          this.BusinessAreaSelectedItem = this.businessAreaList.find(item => item.Name == this.FldrActivityReportbyProductService.formData.BusinessArea);
          if (this.BusinessAreaSelectedItem != undefined) {
            this.BArea = this.BusinessAreaSelectedItem.Code;
            this.BusinessArea = this.BusinessAreaSelectedItem.Name;
          }
        }
      }
    ));
    debugger;
    this.subscription.add(this.lookupApi.GetAllLookup('CUSTOMERGROUP').subscribe(
      (data) => {

        this.customerGroupList = data["ResponseData"];

        if (
          this.FldrActivityReportbyProductService.formData != undefined &&
          this.FldrActivityReportbyProductService.formData.CustomerGroup != null
        ) {

          this.customerGroupSelectedItem = this.customerGroupList.find(item => item.UsrCode == this.FldrActivityReportbyProductService.formData.CustomerGroup);
          if (this.customerGroupSelectedItem != undefined) {
            this.customerGroupCode = this.customerGroupSelectedItem.Code;
            this.customerGroupName = this.customerGroupSelectedItem.ActualName;
          }

        }
      }));
    this.subscription.add(
      this.lookupApi.GetAllLookup("CUSTOMERCREDITGROUP").subscribe((data) => {
        debugger;
        if (data != null && data != undefined) {
          this.customerCreditGroupList = data["ResponseData"];
          if (
            this.FldrActivityReportbyProductService.formData != undefined &&
            this.FldrActivityReportbyProductService.formData.CustomerCrGroupName != null
          ) {

            this.customerCreditGroupSelectedItem = this.customerCreditGroupList.find(item => item.ActualName == this.FldrActivityReportbyProductService.formData.CustomerCrGroupName);
            if (this.customerCreditGroupSelectedItem != undefined) {
              this.CustomerCreditGroupCode = this.customerCreditGroupSelectedItem.Code;
              this.CustomerCreditGroupName = this.customerCreditGroupSelectedItem.ActualName;
            }

          }
        }
      }

      )
    );
    this.subscription.add(
      this.lookupApi.GetAllLookup("TYPE").subscribe((data) => {
        if (data != null && data != undefined) {
          this.ProductList = data["ResponseData"];
          if (
            this.FldrActivityReportbyProductService.formData != undefined &&
            this.FldrActivityReportbyProductService.formData.ProductName != null
          ) {

            this.ProductSelectedItem = this.ProductList.find(item => item.Name == this.FldrActivityReportbyProductService.formData.ProductName);
            if (this.ProductSelectedItem != undefined) {
              this.ProductCode = this.ProductSelectedItem.Code;
              this.ProductName = this.ProductSelectedItem.Name;
            }
          }
        }
      })
    );
    if (this.FldrActivityReportbyProductService.formData != undefined && this.FldrActivityReportbyProductService.formData != null) {
      debugger;
      if (this.FldrActivityReportbyProductService.formData.CreationFromdate != '01-Jan-1900') {
        this.FACreateDateFrom = new Date(this.FldrActivityReportbyProductService.formData.CreationFromdate);
        this.FACreateDateTo = new Date(this.FldrActivityReportbyProductService.formData.CreationTodate);
      }
      if (this.FldrActivityReportbyProductService.formData.CostIncurredFromDate != '01-Jan-1900') {
        this.FACostIncurredDateFrom = new Date(this.FldrActivityReportbyProductService.formData.CostIncurredFromDate);
        this.FACostIncurredDateTo = new Date(this.FldrActivityReportbyProductService.formData.CostIncurredToDate);
      }


      if (this.FldrActivityReportbyProductService.formData.InvFromdate != '01-Jan-1900') {
        this.FAInvoiceDateFrom = new Date(this.FldrActivityReportbyProductService.formData.InvFromdate);
        this.FAInvoiceDateTo = new Date(this.FldrActivityReportbyProductService.formData.InvTodate);
      }
      else {
        this.FAInvoiceDateFrom = null;
        this.FAInvoiceDateTo = null;
      }
      debugger;
      if (this.FldrActivityReportbyProductService.formData.TypeOfSales != undefined || this.FldrActivityReportbyProductService.formData.TypeOfSales != "") {
        this.TypesOfSalesSelectedItem = this.TypesOfSalesList.find(item => item.value == this.FldrActivityReportbyProductService.formData.TypeOfSales);
        if (this.TypesOfSalesSelectedItem != null) {
          this.TypesOfSales = this.TypesOfSalesSelectedItem.value;
          this.TypesOfSalesName = this.TypesOfSalesSelectedItem.text;

        }
        else {
          this.TypesOfSales = 0;
          this.TypesOfSalesName = '';
        }
      }
      if (this.FldrActivityReportbyProductService.formData.ReportType != undefined || this.FldrActivityReportbyProductService.formData.ReportType != "") {
        this.rptTypeSelectedItem = this.ReportTypeList.find(item => item.text == this.FldrActivityReportbyProductService.formData.ReportType);
        if (this.rptTypeSelectedItem != null) {
          this.ReportType = this.rptTypeSelectedItem.value;
          this.ReportTypeName = this.rptTypeSelectedItem.text;
          if (this.ReportTypeName == "Summary") {
            this.dropdown = true;
            this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_UserId = this.userID;
            this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ModuleName =
              "FolderActivityReportbyProductSummary";
            this.FolderActivityReportbyProductCmp.LoadTemplate();

          }
          else {

            this.dropdown = false;
            this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_UserId = this.userID;
            this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ModuleName =
              "FolderActivityReportbyProductDetailed";
            this.FolderActivityReportbyProductDetailedCmp.LoadTemplate();
          }
        }
        else {
          this.ReportType = 0;
          this.ReportTypeName = '';
        }
      }

      if (this.FldrActivityReportbyProductService.formData.CustomerGroup != undefined || this.FldrActivityReportbyProductService.formData.CustomerGroup != "") {
        debugger;
        this.customerGroupSelectedItem = this.customerGroupList.find(item => item.UsrCode == this.FldrActivityReportbyProductService.formData.CustomerGroup);
        if (this.customerGroupSelectedItem != undefined) {
          this.customerGroupCode = this.customerGroupSelectedItem.Code;
          this.customerGroupName = this.customerGroupSelectedItem.ActualName;
        }

      }
      else {

        this.customerGroupCode = "";
        this.customerGroupName = "";
      }

      if (this.FldrActivityReportbyProductService.formData.CustomerCrGroup != undefined || this.FldrActivityReportbyProductService.formData.CustomerCrGroup != "") {
        debugger;
        this.customerCreditGroupSelectedItem = this.customerCreditGroupList.find(item => item.ActualName == this.FldrActivityReportbyProductService.formData.CustomerCrGroupName);
        if (this.customerCreditGroupSelectedItem != undefined) {
          this.CustomerCreditGroupCode = this.customerCreditGroupSelectedItem.Code;
          this.CustomerCreditGroupName = this.customerCreditGroupSelectedItem.ActualName;
        }

      }
      else {

        this.CustomerCreditGroupCode = "";
        this.CustomerCreditGroupName = "";
      }


      if (this.FldrActivityReportbyProductService.formData.FolderNo != undefined) {
        this.FolderNo = this.FldrActivityReportbyProductService.formData.FolderNo;
      }
      this.OrderNo = this.FldrActivityReportbyProductService.formData.OrderNo;
      this.enableStatisticalPosting = this.FldrActivityReportbyProductService.formData.StatisticalPost;
      this.loadcustomerbyid();


    }

  }
  handleFiltercustomer(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchCustomer(value, this.userID).subscribe(
        (data) => {
          this.CustomerList = data["ResponseData"];
        }
      ));
    } else {
      this.autocompletecustomer.toggle(false);
    }
  }


  //13864
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'CUSTOMER') {
      this.lookupname = 'CUSTOMER';
    }
  }
  public close(value) {

    this.opened = false;
    this.openSearch = false;
  }
  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.customerSelectedItem = value;
        this.CustomerList = [];
        this.CustomerValueChange(value.Name)
      },

      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13864
  public BusinessAreaValueChange(value: any): void {

    this.BusinessAreaSelectedItem = this.businessAreaList.find(item => item.Name == value);
    if (value != null && value != undefined) {
      this.BArea = value.Code;
      this.BusinessArea = value.Name;
    }
    else {
      this.BArea = "";
      this.BusinessArea = "";
    }

  }
  // public BranchValueChange(value: any): void {

  //   if (value != null && value != undefined) {
  //     this.BranchCode = value.Code;
  //     this.Branch = value.Name;
  //   }
  //   else {
  //     this.BranchCode = "";
  //     this.Branch = "";
  //   }

  // }

  public BranchValueChange(value: any): void {

    this.BRMultipleSelected.SelectedItems = '';
    this.BranchMultiSelectType = 0;
    if (value != null && value != undefined) {
      this.BranchCode = value.Code;
      this.Branch = value.Name;
    }
    else {
      this.BranchCode = "";
      this.Branch = "";
    }

  }





  public CustomerValueChange(value: any): void {
    if (this.CustomerList != null && this.CustomerList.length > 0) { //13864
      this.customerSelectedItem = this.CustomerList.find(item => item.Name == value);

    }

    if (this.customerSelectedItem != null) {

      this.customerSelected = this.customerSelectedItem.Name; //13860

      this.Customer = this.customerSelectedItem.Name;
      this.PartyCode = this.customerSelectedItem.Code;

    }
    else {
      this.Customer = "";
      this.PartyCode = 0;
    }
  }
  public CustomerGroupValueChange(value: any): void {
    this.customerGroupSelectedItem = this.customerGroupList.find(item => item.UsrCode == value);
    if (value != null && value != undefined) {

      this.customerGroupCode = value.Code;
      this.customerGroupName = value.ActualName;
    }
    else {

      this.customerGroupCode = "";
      this.customerGroupName = "";
    }
  }

  public TypesOfSalesValueChange(value: any): void {

    if (value != null && value != undefined) {

      this.TypesOfSales = value.value;
      this.TypesOfSalesName = value.text;

    }
    else {
      this.TypesOfSales = 0;
      this.TypesOfSalesName = '';
    }
  }
  public ReportTypeValueChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {

      this.ReportType = value.value;
      this.ReportTypeName = value.text;
      if (this.ReportTypeName == "Summary") {
        this.dropdown = true;
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_UserId = this.userID;
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ModuleName =
          "FolderActivityReportbyProductSummary";
        this.FolderActivityReportbyProductCmp.LoadTemplate();

      }
      else {

        this.dropdown = false;
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_UserId = this.userID;
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ModuleName =
          "FolderActivityReportbyProductDetailed";
        this.FolderActivityReportbyProductDetailedCmp.LoadTemplate();
      }
    }
    else {
      this.ReportType = 0;
      this.ReportTypeName = '';
    }
  }

  loadcustomerbyid() {
    debugger;
    if (this.FldrActivityReportbyProductService.formData.CustomerCode != undefined || this.FldrActivityReportbyProductService.formData.CustomerCode != '' && this.FldrActivityReportbyProductService.formData.CustomerCode != null) {

      this.subscription.add(this.lookupApi.findCustomer(this.FldrActivityReportbyProductService.formData.CustomerCode).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.CustomerList = data["ResponseData"];
            if (this.FldrActivityReportbyProductService.formData.CustomerCode != "") {

              this.customerSelectedItem = this.CustomerList.find(item => item.Code == this.FldrActivityReportbyProductService.formData.CustomerCode);

              if (this.customerSelectedItem != null) {
                this.customerSelected = this.customerSelectedItem.Name;
              }

              this.CustomerValueChange(this.customerSelected);
            }
          }

        }
      ));
    }
  }

  public ProductChange(value: any): void {

    if (value != null && value != undefined) {

      this.ProductCode = value.Code;
      this.ProductName = value.Name;

    }
    else {

      this.ProductCode = "";
      this.ProductName = "";
    }
  }
  public CustomerCreditGroupValueChange(value: any): void {

    if (value != null && value != undefined) {

      this.CustomerCreditGroupCode = value.Code;
      this.CustomerCreditGroupName = value.ActualName;
    }
    else {

      this.CustomerCreditGroupCode = "";
      this.CustomerCreditGroupName = "";
    }
  }



  onFormSubmit() {

    this.spinner.show();
    debugger;
    this.CreateDateFrom = (this.FACreateDateFrom == null || this.FACreateDateFrom == undefined || this.FACreateDateFrom == "" ? "01-Jan-1900" : this.FACreateDateFrom);
    this.CreateDateTo = (this.FACreateDateTo == null || this.FACreateDateTo == undefined || this.FACreateDateTo == "" ? "01-Jan-1900" : this.FACreateDateTo);
    this.InvoiceDateFrom = (this.FAInvoiceDateFrom == null || this.FAInvoiceDateFrom == undefined || this.FAInvoiceDateFrom == "" ? "01-Jan-1900" : this.FAInvoiceDateFrom);
    this.InvoiceDateTo = (this.FAInvoiceDateTo == null || this.FAInvoiceDateTo == undefined || this.FAInvoiceDateTo == "" ? "01-Jan-1900" : this.FAInvoiceDateTo);
    this.CostIncurredDateFrom = (this.FACostIncurredDateFrom == null || this.FACostIncurredDateFrom == undefined || this.FACostIncurredDateFrom == "" ? "01-Jan-1900" : this.FACostIncurredDateFrom);
    this.CostIncurredDateTo = (this.FACostIncurredDateTo == null || this.FACostIncurredDateTo == undefined || this.FACostIncurredDateTo == "" ? "01-Jan-1900" : this.FACostIncurredDateTo);

    if ((this.CreateDateFrom == null || this.CreateDateFrom == "01-Jan-1900") && (this.CostIncurredDateFrom == null || this.CostIncurredDateFrom == "01-Jan-1900") && (this.InvoiceDateFrom == null || this.InvoiceDateFrom == "01-Jan-1900")) {
      this.common.showWARNINGtoastrmsg(
        "Messages.selectanyDate",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.CreateDateFrom)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidFromDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.CreateDateTo)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidToDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }

    if (Boolean(this.common.ValidDateRange(this.CostIncurredDateFrom)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidFromDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.CostIncurredDateTo)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidToDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }

    if (Boolean(this.common.ValidDateRange(this.InvoiceDateFrom)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidFromDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.InvoiceDateTo)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidToDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }



    if ((this.FACreateDateTo == null || this.FACreateDateTo == "") && (this.FACreateDateFrom != null || this.FACreateDateFrom != "")) {
      this.CreateDateTo = this.CreateDateFrom;
      this.FACreateDateTo = this.FACreateDateFrom;
    }
    if ((this.FACostIncurredDateTo == null || this.FACostIncurredDateTo == "") && (this.FACostIncurredDateFrom != null || this.FACostIncurredDateFrom != "")) {
      this.CostIncurredDateTo = this.CostIncurredDateFrom;
      this.FACostIncurredDateTo = this.FACostIncurredDateFrom;
    }
    if ((this.FAInvoiceDateTo == null || this.FAInvoiceDateTo == "") && (this.FAInvoiceDateFrom != null || this.FAInvoiceDateFrom != "")) {
      this.InvoiceDateTo = this.InvoiceDateFrom;
      this.FAInvoiceDateTo = this.FAInvoiceDateFrom;
    }

    if (this.FACreateDateTo < this.FACreateDateFrom) {
      this.common.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 2000, true, false);
      this.spinner.hide();
      return;
    }
    if (this.FACostIncurredDateTo < this.FACostIncurredDateFrom) {
      this.common.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 2000, true, false);
      this.spinner.hide();
      return;
    }
    if (this.FAInvoiceDateTo < this.FAInvoiceDateFrom) {
      this.common.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 2000, true, false);
      this.spinner.hide();
      return;
    }


    this.CreateDateFrom = this.common.ConvertToLocaleDate(this.CreateDateFrom);
    this.CreateDateTo = this.common.ConvertToLocaleDate(this.CreateDateTo);
    this.CostIncurredDateFrom = this.common.ConvertToLocaleDate(this.CostIncurredDateFrom);
    this.CostIncurredDateTo = this.common.ConvertToLocaleDate(this.CostIncurredDateTo);
    this.InvoiceDateFrom = this.common.ConvertToLocaleDate(this.InvoiceDateFrom);
    this.InvoiceDateTo = this.common.ConvertToLocaleDate(this.InvoiceDateTo);
    // if (this.BArea != "" || this.BArea != undefined || this.BArea != null) {
    //   debugger;

    //   if (Boolean(this.common.BAreaAuthorisation(this.BArea)) == false) {

    //     this.common.showWARNINGtoastrmsg(
    //       "Messages.busAreaAuthDenied",
    //       2000,
    //       true,
    //       false
    //     );

    //     this.spinner.hide();
    //     return false;
    //   }
    //   else {
    //     return true;
    //   }
    // }
    // if (this.Branch != "" || this.Branch != undefined) {
    //   debugger;
    //   if (Boolean(this.common.BranchAuthorisation(this.Branch)) == false) {

    //     this.common.showWARNINGtoastrmsg(
    //       "Messages.branchAuthDenied",
    //       2000,
    //       true,
    //       false
    //     );

    //     this.spinner.hide();
    //     return false;
    //   }


    // }
    this.FolderActivityReportbyProductDTO = new FolderActivityReportbyProductDTO();
    this.FolderActivityReportbyProductDTO.CreationFromdate = this.CreateDateFrom;
    this.FolderActivityReportbyProductDTO.CreationTodate = this.CreateDateTo;
    this.FolderActivityReportbyProductDTO.CostIncurredFromDate = this.CostIncurredDateFrom;
    this.FolderActivityReportbyProductDTO.CostIncurredToDate = this.CostIncurredDateTo;
    this.FolderActivityReportbyProductDTO.InvFromdate = this.InvoiceDateFrom;
    this.FolderActivityReportbyProductDTO.InvTodate = this.InvoiceDateTo;
    this.FolderActivityReportbyProductDTO.FolderNo = this.FolderNo;
    this.FolderActivityReportbyProductDTO.OrderNo = this.OrderNo;
    this.FolderActivityReportbyProductDTO.Branch = this.Branch;
    this.FolderActivityReportbyProductDTO.BranchName = this.Branch;
    this.FolderActivityReportbyProductDTO.BArea = this.BArea;
    this.FolderActivityReportbyProductDTO.BusinessArea = this.BusinessArea;
    this.FolderActivityReportbyProductDTO.CustUserCode = this.Customer;
    if (this.Customer != "" || this.Customer != undefined) {
      this.FolderActivityReportbyProductDTO.CustomerCode = this.PartyCode;
    }
    // this.FolderActivityReportbyProductDTO.CustomerCode = this.PartyCode;
    this.FolderActivityReportbyProductDTO.Customer = this.Customer;
    this.FolderActivityReportbyProductDTO.CustomerGroup = this.customerGroupCode;
    this.FolderActivityReportbyProductDTO.CustGroup = this.customerGroupName;
    this.FolderActivityReportbyProductDTO.CustomerCrGroup = this.CustomerCreditGroupCode;
    this.FolderActivityReportbyProductDTO.CustomerCrGroupName = this.CustomerCreditGroupName;
    this.FolderActivityReportbyProductDTO.ProductName = this.ProductName;
    this.FolderActivityReportbyProductDTO.Product = this.ProductCode;
    this.FolderActivityReportbyProductDTO.ReportType = this.ReportTypeName;
    this.FolderActivityReportbyProductDTO.TypeOfSales = this.TypesOfSales;
    this.FolderActivityReportbyProductDTO.TypesOfSalesName = this.TypesOfSalesName;
    this.FolderActivityReportbyProductDTO.StatisticalPost = this.enableStatisticalPosting == true ? 1 : 0;
    this.FolderActivityReportbyProductDTO.StatPost = this.enableStatisticalPosting == true ? "ON" : "OFF";

    debugger;
    // this.type = "BRID";
    // var BranchCodes = '';
    // this.TableandField = "fm.BRID";
    // this.FolderActivityReportbyProductDTO.isIncluded = true;
    // if (this.BranchSelectedItem != "" && this.BranchSelectedItem != undefined) {
    //   this.FolderActivityReportbyProductDTO.MCMultipleSelect = false;
    //   this.BRQuery = this.TableandField + " IN (" + "'" + this.BranchSelectedItem.Code + "'" + ")";
    //   this.FolderActivityReportbyProductDTO.BranchSelectedItem = this.BranchSelectedItem.Code;
    //   this.FolderActivityReportbyProductDTO.Branch = this.BranchSelectedItem.Code;
    //   this.PassQuery = "";
    // } else if (this.BRMultipleSelected.SelectedItems.length > 0) {
    //   this.FolderActivityReportbyProductDTO.MCMultipleSelect = true;
    //   BranchCodes = this.BRMultipleSelected.SelectedItems;
    //   if (this.mcodeInclude) {
    //     this.FolderActivityReportbyProductDTO.isIncluded = true;
    //     // this.BRQuery = this.TableandField + " IN (" + "'" + BranchCodes + "'" + ")";
    //     this.BRQuery = this.TableandField + " IN (" + BranchCodes + ")";
    //     this.PassQuery = this.BRQuery;
    //     var re = /'/gi;
    //     var newstr = BranchCodes.replace(re, "");
    //     this.FolderActivityReportbyProductDTO.BranchCodeList = newstr;

    //   } else {
    //     this.FolderActivityReportbyProductDTO.isIncluded = false;
    //     // this.BRQuery = this.TableandField + " NOT IN (" + "'" + BranchCodes + "'" + ")";
    //     this.BRQuery = this.TableandField + " NOT IN (" + BranchCodes + ")";
    //     this.PassQuery = this.BRQuery;
    //     var re = /'/gi;
    //     var newstr = BranchCodes.replace(re, "");
    //     this.FolderActivityReportbyProductDTO.BranchCodeList = newstr;
    //   }
    // }

    var BranchCodes = '';
    var BranchNames = '';
    this.TableandField = "fm.BRID";
    this.type = "BRID";

    this.BRQuery = "";

    if (this.BRMultipleSelected != null && this.BRMultipleSelected.SelectedItems != "") {
      debugger
      this.BRMultipleSelectedItems = this.BRMultipleSelected.SelectedItems;
      this.BRMultipleSelectedItems.forEach(element1 => {
        debugger;
        if (BranchCodes == "") {
          BranchCodes = "'" + element1.Code + "'";
          BranchNames = "'" + element1.Name + "'";
        } else {
          BranchCodes = BranchCodes + ',' + "'" + element1.Code + "'";
          BranchNames = BranchNames + ',' + "'" + element1.Name + "'";
        }
      })

      if (this.BRMultipleSelected.isIncluded) {

        this.MCQuery = this.TableandField + " IN (" + BranchCodes + ")";

        this.BRQuery = this.MCQuery;

      } else {

        this.MCQuery = this.TableandField + " NOT IN (" + BranchCodes + ")";

        this.BRQuery = this.MCQuery;

      }
      if (this.PassQuery != "" && this.BRQuery != "") {
        this.PassQuery = this.PassQuery + " and " + this.BRQuery;
      }
      else if (this.PassQuery == "" && this.BRQuery != "") {
        this.PassQuery = this.BRQuery;
      }
    }




    this.FolderActivityReportbyProductDTO.PassQuery = this.PassQuery;
    this.FldrActivityReportbyProductService.formData = this.FolderActivityReportbyProductDTO;


    if (this.ReportTypeName == "Summary") {
      this.subscription.add(this.FldrActivityReportbyProductService.GetFolderActivityReportbyProduct().subscribe(async res => {
        if (res["ResponseData"] != undefined) {
          this.spinner.hide();
          this.SummaryGridData = res["ResponseData"];

          if (this.SummaryGridData.length > 0) {
            this.FldrActivityReportbyProductService.SummaryGridData = this.SummaryGridData;
            this.FolderActivityReportbyProductDTO.ReportGenarated = true;
            this.router.navigate(["Reports/FolderActivityReportbyProductSummary"]);
            this.spinner.hide();


          }
          else {
            this.common.showWARNINGtoastrmsg(
              "Messages.no_data_found",
              2000,
              true,
              false
            );

            this.spinner.hide();
          }
        }
        else {
          this.spinner.hide();
        }
      }
      ))

    }
    else {
      this.subscription.add(this.FldrActivityReportbyProductService.GetFolderActivityReportbyProduct().subscribe(async res => {
        if (res["ResponseData"] != undefined) {
          this.spinner.hide();
          this.SummaryGridData = res["ResponseData"];

          if (this.SummaryGridData.length > 0) {
            this.FldrActivityReportbyProductService.SummaryGridData = this.SummaryGridData;
            this.FolderActivityReportbyProductDTO.ReportGenarated = true;
            this.router.navigate(["Reports/FolderActivityRptbyProductDetailed"]);
            this.spinner.hide();
          }
          else {
            this.common.showWARNINGtoastrmsg(
              "Messages.no_data_found",
              2000,
              true,
              false
            );

            this.spinner.hide();
          }
        }
        else {
          this.spinner.hide();
        }
      }
      ))
    }

  }
  onCancel() {
    var reporturl = localStorage.getItem('menuhighlight')

    if (reporturl != null) {
      localStorage.removeItem('menuhighlight');
      this.router.navigate(['/Login']);
    }
    else {
      this.router.navigate(['PenAir/Home']);
    }
  }
  public multiSelect(value) {
    debugger;
    this.drop = true;
    this.type = value;

    if (value == "BRID") {

      this.MsInput = this.BRMultipleSelected;
    }
    this.mcodeMultiSelectPopUp = true;
  }

  public closeMultiSelectPopUP(event: any) {
    this.mcodeMultiSelectPopUp = false;
  }


  public MultiCodeSelectedItems(event: any) {
    debugger;
    this.BranchSelectedItem = "";
    if (event.type == "BRID") {

      if (event.resultSelectedList != null && event.resultSelectedList != '') {
        this.BRMultipleSelected.SelectedItems = event.resultSelectedList;
        this.BRMultipleSelected.isIncluded = event.isIncluded

      } else {
        this.BRMultipleSelected.SelectedItems = "";

      }

    }
    this.mcodeMultiSelectPopUp = false;
    if (event.resultSelectedList != '') {
      if (event.isIncluded == true) {

        this.setMultiselectValue(event.type, 1);

      }
      else {

        this.setMultiselectValue(event.type, 2);
      }
    }
    else {
      this.setMultiselectValue(event.type, 0)
      this.MsInput = "";
    }
  }
  setMultiselectValue(type, value) {
    switch (type) {
      case 'BRID':
        this.BranchMultiSelectType = value;
        break;

    }
  }



  ClearAll() {
    this.FACreateDateFrom = null;
    this.FACreateDateTo = null;
    this.FACostIncurredDateFrom = null;
    this.FACostIncurredDateTo = null;
    this.FAInvoiceDateFrom = null;
    this.FAInvoiceDateTo = null;
    this.FolderNo = null;
    this.OrderNo = null;
    this.BranchSelectedItem = null;
    this.BusinessAreaSelectedItem = null;
    this.ProductSelectedItem = null;
    this.rptTypeSelectedItem = { text: "Summary", value: 0 };
    this.customerSelected = null;
    this.customerCreditGroupSelectedItem = null;
    this.customerGroupSelectedItem = null;
    this.TypesOfSalesSelectedItem = { text: "All", value: 0 };
    this.enableStatisticalPosting = null;
    this.BranchMultiSelectType = 0;
  }

}
