export class Pnrsourcefile {
    PROJNO: any;
    FOLDERNO: any;
    GDS: any;
    PNR: any;
    PNRFILENAME: any;
    INTERFACE: any;
    RECEIVEDTIME: any;
    RECEIVEDDATE: any;
    UID: any;
}

export class GetPNRSourceFileOptionDetails {
IsSuccess: boolean;
Message: string;
ResponseData:
{
    GDS: string;
    PNR: string;
}
}

export class PNRFileDTO {
    INTERFACE: string;
    PNRFILENAME: string;
    status: string;
    GDS: string;
}