import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LookUpDetails } from "../Services/look-up-details";
import { HotelService } from "../Services/hotel.service";
import { ActivatedRoute } from "@angular/router";
import { LookUpDetailsService } from "../Services/look-up-details.service";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { CurrencyExchangerateService } from "../Services/currency-exchangerate.service";
import { CurrencyExchangerate } from "../Models/currency-exchangerate";
import { ShareDataService } from "../Services/share-data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CommonserviceService } from "../Services/commonservice.service";
import { Subscription, Subject } from "rxjs";
import { FileService } from "../Services/file.service";
import { SupplierMasterService } from "../Services/supplier-master.service";
import { ProductUpdatePODTO } from "../Models/pocreation-detail";
import { Alert } from 'selenium-webdriver';

export abstract class HotelDetailsEdit {
  constructor(
    public fileservice: FileService,
    public common: CommonserviceService,
    public route: ActivatedRoute,
    public api: HotelService,
    public lookupApi: LookUpDetailsService,
    public apiExRate: CurrencyExchangerateService,
    public apiShared: ShareDataService,
    public spinner: NgxSpinnerService,
    public supplier: SupplierMasterService
  ) {
    this.fileservice.getFileDetails().subscribe(x => {
      this.checkin = x.ResponseData.HotelCheckInTime;
      this.checkout = x.ResponseData.HotelCheckOutTime;
    });
  }
  openSearch = false;
  lookupname: any = 'Supplier';
  lookuptype: string = '';
  public subscription: Subscription = new Subscription();
  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  public opened = false;
  txtDisabled = true;
  prdtUpdatePODTO: ProductUpdatePODTO;
  ucode: string;
  hotelEditForm: FormGroup;
  submitted = false;
  loaded = false;
  savebutton = true;
  currencyExrate: CurrencyExchangerate;
  folderEditMode: any;
  diff = 0;
  indate: Date = new Date();
  outdate: Date = new Date();
  days: number;
  Uname: any;
  public pocreated = true;
  public PONO;
  homeCurrency: any;
  folderExrate: any;
  fldrCurrency: any;
  public exRate: any;
  public showMsg: any;
  bookingDate: any; //337
  dateIn: any; //337
  dateOut: any; //337
  depositDueDate: any; //337
  public paymethodList: Array<string> = [
    "Credit",
    "Company Card",
    "Customer Card",
    "Customer Pay Locally"
  ];
  public CommonList: Array<string> = ["Purchase", "Gross", "Sales"];
  public CardList: Array<LookUpDetails> = [];
  public HotelList: Array<LookUpDetails["ResponseData"]> = [];

  CurrencyList: Array<LookUpDetails["ResponseData"]> = [];
  RoomTypeList: Array<LookUpDetails["ResponseData"]> = [];
  MealPlanTypeList: Array<LookUpDetails["ResponseData"]> = [];

  BOOKEBYList: Array<LookUpDetails["ResponseData"]> = [];
  public bookbyselectedItem: LookUpDetails["ResponseData"];

  public CurrencyselectedItem: LookUpDetails["ResponseData"];
  public RoomTypeselectedItem: LookUpDetails["ResponseData"];
  public MealPlanselectedItem: LookUpDetails["ResponseData"];

  CityList: Array<LookUpDetails["ResponseData"]> = [];
  public CityselectedItem: LookUpDetails["ResponseData"];
  public Cityselected: String;

  public guid: string = this.route.snapshot.params.guidItinerary;
  public orderno = this.route.snapshot.params.orderNo;
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];

  public supplierSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelected: String;

  public HotelselectedItem: LookUpDetails["ResponseData"];
  public Hotelselected: String;
  public projNo: any;
  hcExRate: any;

  creditLimitMsg = false;
  creditLimiFlag = false;
  msg: any;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains"
  };
  public checkin: any;
  public checkout: any;
  public disabled = true;
  public nights: number;
  public rooms: number;
  public fCBuy: number;
  public buyExRate: number;
  public amount: number;
  public balanceDueAmount: number;
  public defaultDepositPer: number;
  public hcBuy = 0;
  public Componentname: string;
  public InterIndList: Array<string> = [
    "International",
    "Domestic",
    "Transborder"
  ];
  FC_Tot_BuyInitalvalue: any = 0.00;
  FCSell: any = 0.00;
  public InterIndselectedItem = { text: "(International)", value: "I" };

  public interIndListId: any = "";
  CompanyCardValidation() {
    if (this.hotelEditForm.get("PAYMODE").value == "Company Card") {
      if (
        this.hotelEditForm.get("PDNO").value != "" &&
        this.hotelEditForm.get("PDNO").value != null &&
        this.hotelEditForm.get("PDFCURID").value != "" &&
        this.hotelEditForm.get("PDFCURID").value != null &&
        this.hotelEditForm.get("PDFCAMT").value != "" &&
        this.hotelEditForm.get("PDFCAMT").value != null &&
        this.hotelEditForm.get("PDFCAMT").value != 0 &&
        this.hotelEditForm.get("PDTYPENO").value != null &&
        this.hotelEditForm.get("PDTYPENO").value != "" &&
        this.hotelEditForm.get("PDAMOUNT").value != null &&
        this.hotelEditForm.get("PDAMOUNT").value != null &&
        this.hotelEditForm.get("PDAMOUNT").value != 0 &&
        this.hotelEditForm.get("PDFCAMT").value != null &&
        this.hotelEditForm.get("PDFCAMT").value != null &&
        this.hotelEditForm.get("PDFCAMT").value != 0
      ) {
        return true;
      } else {
        console.log('validation failed hotel');
        this.common.showFlash1(
          "Messages.Companycard_validation",
          "bg-danger text-white",
          2000
        );
        return false;
      }
    } else {
      return true;
    }
  }
  public formBuilder: FormBuilder;
  getFormBuilder() {
    this.hotelEditForm = this.formBuilder.group({
      FOLDERNO: [this.orderno],
      GUIDItinerary: [""],
      SUPPRTYCODE: ["", Validators.required],
      SUPPNAME: [""],
      BookingDate: [new Date()],
      PDFCAMT: [0],
      PDEXRATE: [this.hcExRate],
      ROOMS: [0],
      DATEIN: [new Date()],
      DATEOUT: [new Date()],
      CHECKIN: [0],
      MealPlan: [""],
      FCSell: [0],
      FCBuy: [0],
      PDTYPENO: [0],
      HOTELLOC: [""],
      CITYID: [""],
      // UID:[''],
      CURCODE: [this.homeCurrency],
      EXRATE: [this.hcExRate],
      AMTLC: [0],
      AMTHC: [0], // [{value: '', disabled: true}],
      HOTELNAME: [""],
      CITY: [""],
      BOOKEDBY: this.Uname,
      CHECKOUT: [0],
      NIGHTS: [0],
      NOPAX: [0],
      TYPE: [""],
      MealPlan_MPM_Id: [""],
      RDESC: [""],
      LOYALTYPOINTS: [0],
      LOYALTYPOINTSMEMBNO: [""],
      LOYALTYPOINTSAMT: [0],
      CommOn: ["Purchase"],
      CONFNO: [""],
      VOUCHER: [""],
      SELLVAT: [0],
      SELLTAXRATE: [0],
      SELLAMT: [0],
      SELLCOMM: [0],
      HCCommPer: [0],
      PAYABLE: [0],
      FC_SELLTAXAMT: [0],
      SELLTAXNO: [0],
      PDAMOUNT: [0], // [{value: '', disabled: true}],
      PDNO: [0],
      PDFCURID: [this.homeCurrency],
      ITYPE: ["Hotel"],
      PMTID: [""],
      DepositDueDate: [""],
      DEFDEP: [0],
      PAYMENTID: 0,
      DEFDEPPER: [0],
      BalanceDueAmount: [0],
      ActualSaving: [0],
      PotentialSaving: [0],
      LowFB: [""],
      HighFB: [""],
      AddRemarks: [""],
      HighFare: [0],
      ADDRESS: [""],
      GENINFO: [""],
      LowFare: [0],
      Fareoffered: [0],
      PAYMODE: [""],
      PROJNO: [this.projNo],
      STATUS: ["Confirmed"],
      PHONE: [""],
      FAX: [""],
      ROOMRDESC: [""],
      CANPOLICY: [""],
      ZOOMBOX: [""],
      PID: [""],
      PName: [""],
      Guest: [""],
      NotesToBP: [""],
      NotesNotToBP: [""],
      GDS: [""],
      PNR: [""],
      FCCommPer: [0],
      FCCommAmt: [0],

      EMAIL: ["", Validators.email],
      Forgone: [0],
      PONO: [0],
      BalanceDueDate: [new Date()],
      TOMSVATTAXNO: [0],
      TOMSVATAMT: [0],

      LOYALTYPOINTSBAL: [0],
      TOMSVATCID: [""],
      PDUNIQUEREF: [""],
      PONOCCDeferredPosted: [0],
      TaxExcludingSalesTax: [0],
      BaseFare: [0],
      PONOYEARID: [0],
      SELLTOTAL: [0],
      GSTHSTOnComm: 0,
      InterInd: [],

      Markup: [0],
      CostofSale: [0],
      CostingSupplierGrossValue: [0],
      CostingSupplierServiceFee: [0],
      CostingTaxNo: [0],
      CostingTaxRate: [0],
      CostingSupplierTaxAmount: [0],
      CostingSupplierNetBuyPrice: [0],
      CostingCustomerTaxAmount: [0],
      CostingCustomerNetSellPrice: [0],
      CostingCustomerSalesValue: [0],
      CostingCustomerServiceFee: [0],
      CostingVATType: ["NV"],
      CostingSuppComm: [0],
      BSPPurchaseTax: [0],
      BSPPurchaseTaxRate: [0],
      Hote101_Id: [0],
      SupplierDeposit: [0],
      CountryID: [""]
    });
  }
  FormLoyalityPointsChange() {
    if (
      this.hotelEditForm.controls.LOYALTYPOINTSMEMBNO.value != "" &&
      this.hotelEditForm.controls.LOYALTYPOINTSMEMBNO.value != null
    ) {
      this.hotelEditForm.get("LOYALTYPOINTS").enable();
      this.hotelEditForm.get("LOYALTYPOINTSAMT").enable();
    } else {
      this.hotelEditForm.controls.LOYALTYPOINTS.setValue(0);
      this.hotelEditForm.controls.LOYALTYPOINTSAMT.setValue(0);
      this.hotelEditForm.get("LOYALTYPOINTS").disable();
      this.hotelEditForm.get("LOYALTYPOINTSAMT").disable();
    }
  }

  getFolderValues() {
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    this.exRate = new Number(this.folderExrate);
    this.projNo = this.apiShared.projNo;
  }

  getExchangeRate(currency, bookingDate) {
    this.savebutton = false;
    bookingDate = new Date(bookingDate)
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric"
      })
      .replace(/ /g, "-");
    bookingDate = bookingDate.replace("Sept", "Sep");
    this.subscription.add(
      this.apiExRate.getExchangeRate(currency, bookingDate).subscribe(data => {
        if (data != null && data != undefined) {
          this.currencyExrate = data;

          this.hotelEditForm.controls.EXRATE.setValue(
            this.currencyExrate.ResponseData.EXRATE
          );
        }
        this.savebutton = true;
      })
    );
  }

  SupplierDefaultvalue(suppcode) {
    this.subscription.add(
      this.supplier.GetDefaultValue(suppcode).subscribe(data => {
        if (data !== "" && data !== null && data !== undefined) {
          //       if(data.CURRENCYCODE!="")
          //this.hotelEditForm.controls.CURCODE.setValue(data.CURRENCYCODE);
          this.hotelEditForm.controls.PAYMENTID.setValue(data.PAYTERMID);
          this.hotelEditForm.controls.PMTID.setValue(data.PAYMTDID);
          this.resetChildForm();
        }
      })
    );
  }
  resetChildForm() {
    this.resetFormSubject.next(true);
  }

  Checkcurrency() {
    this.fldrCurrency = this.apiShared.folderCurrency;
    if (this.hotelEditForm.controls.CURCODE.value == this.fldrCurrency) {
      this.common.disablecurrency = false;
    } else {
      this.hotelEditForm.controls.Markup.setValue(0);
      var value = this.hotelEditForm.controls.CostingVATType.value;
      if (value == "NV") {
        this.hotelEditForm.controls.CostofSale.setValue(0);
        this.hotelEditForm.controls.Markup.setValue(0);
        this.hotelEditForm.controls.FCSell.setValue(0);
      } else if (value == "VSF") {
        this.hotelEditForm.controls.CostofSale.setValue(0);
        this.hotelEditForm.controls.Markup.setValue(0);
        this.hotelEditForm.controls.CostingCustomerTaxAmount.setValue(0);
        this.hotelEditForm.controls.CostingCustomerNetSellPrice.setValue(0);
      }
      if (value == "VP") {
        this.hotelEditForm.controls.CostofSale.setValue(0);
        this.hotelEditForm.controls.Markup.setValue(0);
        this.hotelEditForm.controls.CostingCustomerTaxAmount.setValue(0);
        this.hotelEditForm.controls.CostingCustomerNetSellPrice.setValue(0);
      }
      if (value == "VSC") {
        this.hotelEditForm.controls.CostofSale.setValue(0);
        this.hotelEditForm.controls.Markup.setValue(0);

        this.hotelEditForm.controls.CostingCustomerNetSellPrice.setValue(0);
      }
      this.common.disablecurrency = true;
    }
  }

  getHotelDetails(id, guid) {
    this.spinner.show();
    this.subscription.add(
      this.api.getHotel(id, guid, false).subscribe(data => {
        if (

          data != null &&
          data != undefined &&
          data.ResponseData != undefined
        ) {
          this.spinner.hide();
          this.hotelEditForm.setValue({
            FOLDERNO: data.ResponseData.FOLDERNO,
            GUIDItinerary: data.ResponseData.GUIDItinerary,
            SUPPRTYCODE: data.ResponseData.SUPPRTYCODE,
            SUPPNAME: data.ResponseData.SUPPNAME,
            BookingDate:
              data.ResponseData.BookingDate != null
                ? data.ResponseData.BookingDate.toString() ==
                  "1900-01-01T00:00:00"
                  ? null
                  : new Date(data.ResponseData.BookingDate)
                : null,
            PDFCAMT: data.ResponseData.PDFCAMT,
            PDEXRATE: data.ResponseData.PDEXRATE,
            ROOMS: data.ResponseData.ROOMS,
            DATEIN:
              data.ResponseData.DATEIN != null
                ? data.ResponseData.DATEIN.toString() == "1900-01-01T00:00:00"
                  ? null
                  : new Date(data.ResponseData.DATEIN)
                : null,
            DATEOUT:
              data.ResponseData.DATEOUT != null
                ? data.ResponseData.DATEOUT.toString() == "1900-01-01T00:00:00"
                  ? null
                  : new Date(data.ResponseData.DATEOUT)
                : null,
            CHECKIN: data.ResponseData.CHECKIN,
            MealPlan: data.ResponseData.MealPlan,
            FCSell: data.ResponseData.FCSell,
            FCBuy: data.ResponseData.FCBuy,
            PDTYPENO: data.ResponseData.PDTYPENO,
            HOTELLOC: data.ResponseData.HOTELLOC,
            CITYID: data.ResponseData.CITYID,
            CURCODE: data.ResponseData.CURCODE,
            EXRATE: data.ResponseData.EXRATE,
            AMTLC: data.ResponseData.AMTLC,
            AMTHC: data.ResponseData.AMTHC,
            HOTELNAME: data.ResponseData.HOTELNAME,
            CITY: data.ResponseData.CITY,
            BOOKEDBY: data.ResponseData.BOOKEDBY,
            CHECKOUT: data.ResponseData.CHECKOUT,
            NIGHTS: data.ResponseData.NIGHTS,
            NOPAX: data.ResponseData.NOPAX,
            TYPE: data.ResponseData.TYPE,
            MealPlan_MPM_Id: data.ResponseData.MealPlan_MPM_Id,
            RDESC: data.ResponseData.RDESC,
            LOYALTYPOINTS: data.ResponseData.LOYALTYPOINTS,
            LOYALTYPOINTSMEMBNO: data.ResponseData.LOYALTYPOINTSMEMBNO,
            LOYALTYPOINTSAMT: data.ResponseData.LOYALTYPOINTSAMT,
            CommOn: data.ResponseData.CommOn,
            CONFNO: data.ResponseData.CONFNO,
            VOUCHER: data.ResponseData.VOUCHER,
            SELLVAT: data.ResponseData.SELLVAT,
            SELLTAXRATE: data.ResponseData.SELLTAXRATE,
            SELLAMT: data.ResponseData.SELLAMT,
            SELLCOMM: data.ResponseData.SELLCOMM,
            HCCommPer: data.ResponseData.HCCommPer,
            PAYABLE: data.ResponseData.PAYABLE,
            FC_SELLTAXAMT: data.ResponseData.FC_SELLTAXAMT,
            SELLTAXNO: data.ResponseData.SELLTAXNO,
            PDFCURID: data.ResponseData.PDFCURID,
            PDNO: data.ResponseData.PDNO,
            PDAMOUNT: data.ResponseData.PDAMOUNT,
            ITYPE: "Hotel",
            PMTID: data.ResponseData.PMTID,
            DepositDueDate:
              data.ResponseData.DepositDueDate != null
                ? data.ResponseData.DepositDueDate.toString() ==
                  "1900-01-01T00:00:00"
                  ? null
                  : new Date(data.ResponseData.DepositDueDate)
                : null,
            DEFDEP: data.ResponseData.DEFDEP,
            PAYMENTID: data.ResponseData.PAYMENTID,
            DEFDEPPER: data.ResponseData.DEFDEPPER,
            BalanceDueAmount: data.ResponseData.BalanceDueAmount,
            ActualSaving: data.ResponseData.ActualSaving,
            PotentialSaving: data.ResponseData.PotentialSaving,
            LowFB: data.ResponseData.LowFB,
            HighFB: data.ResponseData.HighFB,
            AddRemarks: data.ResponseData.AddRemarks,
            HighFare: data.ResponseData.HighFare,

            LowFare: data.ResponseData.LowFare,
            Fareoffered: data.ResponseData.Fareoffered,
            PAYMODE: data.ResponseData.PAYMODE,
            PROJNO: data.ResponseData.PROJNO,
            STATUS: data.ResponseData.STATUS,
            ADDRESS: data.ResponseData.ADDRESS,
            GENINFO: data.ResponseData.GENINFO,

            PHONE: data.ResponseData.PHONE,
            FAX: data.ResponseData.FAX,
            ROOMRDESC: data.ResponseData.ROOMRDESC,
            CANPOLICY: data.ResponseData.CANPOLICY,
            ZOOMBOX: data.ResponseData.ZOOMBOX,

            PID: data.ResponseData.PID,
            PName: data.ResponseData.PName,
            Guest: data.ResponseData.Guest,
            NotesToBP: data.ResponseData.NotesToBP,
            NotesNotToBP: data.ResponseData.NotesNotToBP,
            GDS: data.ResponseData.GDS,
            PNR: data.ResponseData.PNR,
            FCCommPer: data.ResponseData.FCCommPer,
            FCCommAmt: data.ResponseData.FCCommAmt,
            Forgone: data.ResponseData.Forgone,
            PONO: data.ResponseData.PONO,
            BalanceDueDate:
              data.ResponseData.BalanceDueDate != null ? data.ResponseData.BalanceDueDate.toString() ==
                "1900-01-01T00:00:00"
                ? null
                : new Date(data.ResponseData.BalanceDueDate)
                : null,
            TOMSVATTAXNO: data.ResponseData.TOMSVATTAXNO,
            TOMSVATAMT: data.ResponseData.TOMSVATAMT,

            LOYALTYPOINTSBAL: data.ResponseData.LOYALTYPOINTSBAL,
            TOMSVATCID: data.ResponseData.TOMSVATCID,
            PDUNIQUEREF: data.ResponseData.PDUNIQUEREF,

            PONOCCDeferredPosted: data.ResponseData.PONOCCDeferredPosted,

            TaxExcludingSalesTax: data.ResponseData.TaxExcludingSalesTax,
            BaseFare: data.ResponseData.BaseFare,
            PONOYEARID: data.ResponseData.PONOYEARID,
            EMAIL: data.ResponseData.EMAIL,
            SELLTOTAL: data.ResponseData.SELLTOTAL,
            GSTHSTOnComm: data.ResponseData.GSTHSTOnComm,
            InterInd:
              data.ResponseData.InterInd == ""
                ? ""
                : data.ResponseData.InterInd == "I"
                  ? "International"
                  : data.ResponseData.InterInd == "D"
                    ? "Domestic"
                    : data.ResponseData.InterInd == "T"
                      ? "Transborder"
                      : "",
            Markup: data.ResponseData.CostingCustomerServiceFee,
            CostofSale: data.ResponseData.CostingCustomerSalesValue,
            CostingSupplierGrossValue:
              data.ResponseData.CostingSupplierGrossValue,
            CostingCustomerSalesValue:
              data.ResponseData.CostingCustomerSalesValue,
            CostingCustomerServiceFee:
              data.ResponseData.CostingCustomerServiceFee,
            CostingCustomerNetSellPrice:
              data.ResponseData.CostingCustomerNetSellPrice,
            CostingVATType: data.ResponseData.CostingVATType,
            CostingSupplierServiceFee:
              data.ResponseData.CostingSupplierServiceFee,
            CostingTaxNo: data.ResponseData.CostingTaxNo,
            CostingTaxRate: data.ResponseData.CostingTaxRate,
            CostingSupplierTaxAmount:
              data.ResponseData.CostingSupplierTaxAmount,
            CostingSupplierNetBuyPrice:
              data.ResponseData.CostingSupplierNetBuyPrice,
            CostingCustomerTaxAmount:
              data.ResponseData.CostingCustomerTaxAmount,
            CostingSuppComm: data.ResponseData.CostingSuppComm,

            BSPPurchaseTax: [0],
            BSPPurchaseTaxRate: [0],
            Hote101_Id: data.ResponseData.Hote101_Id,
            SupplierDeposit: data.ResponseData.SupplierDeposit,
            CountryID: ['']
          });
          this.FC_Tot_BuyInitalvalue = data.ResponseData.FCBuy;
          this.FCSell = data.ResponseData.FCSell
          if (this.hotelEditForm.controls.MealPlan_MPM_Id.value != "") {
            this.MealPlanselectedItem = this.MealPlanTypeList.find(
              item =>
                item.UsrCode ==
                this.hotelEditForm.controls.MealPlan_MPM_Id.value
            );
          }
        } else {
          this.spinner.hide();
        }
        this.PONO = this.hotelEditForm.controls.PONO.value;

        if (this.PONO > 0) {
          this.pocreated = false;
        } else {
          this.pocreated = true;
        }

        this.loadsuppliermaster();
        this.spinner.hide();

        this.FormLoyalityPointsChange();
        this.loaddeparturecity();

        this.loadhotel();

        this.loaded = true;
      })
    );
  }

  loaddeparturecity() {
    if (
      this.hotelEditForm.controls.CITYID.value != "" &&
      this.hotelEditForm.controls.CITYID.value != null
    ) {
      this.subscription.add(
        this.lookupApi
          .SearchDestination(this.hotelEditForm.controls.CITYID.value, "0")
          .subscribe(data => {
            if (data != null && data != undefined) {
              this.CityList = data["ResponseData"];

              if (this.hotelEditForm.controls.CITYID.value != "") {
                this.CityselectedItem = this.CityList.find(
                  item => item.Code == this.hotelEditForm.controls.CITYID.value
                );
                if (this.CityselectedItem != null)
                  this.Cityselected = this.CityselectedItem.Name;
              }
            }
          })
      );
    }
  }

  loadsuppliermaster() {
    if (
      this.hotelEditForm.controls.SUPPRTYCODE.value != "" &&
      this.hotelEditForm.controls.SUPPRTYCODE.value != null
    ) {
      this.subscription.add(
        this.lookupApi
          .findSupplier(this.hotelEditForm.controls.SUPPRTYCODE.value)
          .subscribe(data => {
            if (data != null && data != undefined) {
              this.SupplierList = data["ResponseData"];
              if (this.hotelEditForm.controls.SUPPRTYCODE.value != "") {
                this.supplierSelectedItem = this.SupplierList.find(
                  item =>
                    item.Code == this.hotelEditForm.controls.SUPPRTYCODE.value
                );
                if (this.supplierSelectedItem != null) {

                  this.supplierSelected = this.supplierSelectedItem.Name;
                  this.hotelEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID);


                }
              }
            }
          })
      );
    }
  }

  loadhotel() {
    if (
      this.hotelEditForm.controls.HOTELLOC.value != "" &&
      this.hotelEditForm.controls.HOTELLOC.value != null
    ) {
      this.subscription.add(
        this.lookupApi
          .findHotel(this.hotelEditForm.controls.HOTELLOC.value)
          .subscribe(data => {
            if (data != null && data != undefined) {
              this.HotelList = data["ResponseData"];
              if (this.hotelEditForm.controls.HOTELLOC.value != "") {
                this.HotelselectedItem = this.HotelList.find(
                  item =>
                    item.Code == this.hotelEditForm.controls.HOTELLOC.value
                );
                this.Hotelselected = this.HotelselectedItem.Name;
              }
            }
          })
      );
    }
    if (this.hotelEditForm.controls.HOTELLOC.value == "") {
      //By Caseid-12752
      this.Hotelselected = this.hotelEditForm.controls.HOTELNAME.value;
    }
  }
  public bindRoomTypeDetails() {
    this.subscription.add(
      this.lookupApi.GetAllLookup("ROOMTYPEMASTER").subscribe(data => {
        if (data != null && data != undefined) {
          this.RoomTypeList = data["ResponseData"];

          if (this.hotelEditForm.controls.TYPE.value != "") {
            this.RoomTypeselectedItem = this.RoomTypeList.find(
              item => item.UsrCode == this.hotelEditForm.controls.TYPE.value
            );
          }
        }
      })
    );
  }
  public bindMealPlanDetails() {
    this.subscription.add(
      this.lookupApi.GetAllLookup("MEALPLAN").subscribe(data => {
        if (data != null && data != undefined) {
          this.MealPlanTypeList = data["ResponseData"];
          if (this.hotelEditForm.controls.MealPlan_MPM_Id.value != "") {
            this.MealPlanselectedItem = this.MealPlanTypeList.find(
              item =>
                item.Code == this.hotelEditForm.controls.MealPlan_MPM_Id.value
            );
          }
        }
      })
    );
  }
  public bindUserDetails() {
    this.subscription.add(
      this.lookupApi.GetAllLookup("USERS").subscribe(data => {
        if (data != null && data != undefined) {
          this.BOOKEBYList = data["ResponseData"];
          if (this.hotelEditForm.controls.BOOKEDBY.value != "") {
            this.bookbyselectedItem = this.BOOKEBYList.find(
              item => item.Name == this.hotelEditForm.controls.BOOKEDBY.value
            );
          }
        }
      })
    );
  }
}
