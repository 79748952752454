
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { Router, ActivatedRoute } from '@angular/router';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { FolderSummaryService } from '../../Services/folder-summary.service';
import { ShareDataService } from '../../Services/share-data.service';
import { EnvService } from 'src/env.service';
import { PassengerService } from '../../Services/passenger.service';
import { stringify } from 'querystring';
import { Observable, Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
@Component({
  selector: 'app-customerpdqreceipt',
  templateUrl: './customerpdqreceipt.component.html',
  styleUrls: ['./customerpdqreceipt.component.scss']
})
export class CustomerpdqreceiptComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  CustomerPdqReceiptForm: FormGroup;

  constructor(public common:CommonserviceService, private formBuilder: FormBuilder, private route: ActivatedRoute,private router: Router,private api: FolderSummaryService, private lookupApi:LookUpDetailsService,private apiShared: ShareDataService,private env: EnvService) { }
  private orderNo:string=this.route.snapshot.params['orderNo'];
  public PassengerName:Observable<string>;
     public Days:number;
     CustomerList: Array<LookUpDetails["ResponseData"]> = [];
     public customerSelectedItem: LookUpDetails["ResponseData"];
     public customerSelected:string;
     public gridData: any ;
     PassengerselectedItem:string ;
     public Componentname:string;
  ngOnInit() {
    var orderno= sessionStorage.getItem('orderNo');
    if(orderno!=null)
    if(this.orderNo !==orderno)
    {
     // return  this.router.navigate(['PenAir/Home']);
    }
    this.getFolderValues();
    this.GetFolderDetailsById(this.orderNo);
   
    this.CustomerPdqReceiptForm = this.formBuilder.group({
        BRID :[""],
    });


    this.subscription.add(  this.lookupApi.GetAllLookup('CUSTOMER').subscribe(
        (data) => {        
          if(data!=null && data!=undefined)
      {
           this.CustomerList= data["ResponseData"]  
                
           if(this.CustomerCode!="")
           {            
             this.customerSelectedItem =this.CustomerList.find(item => item.Code ==this.CustomerCode);                   
             
             this.customerSelected=this.customerSelectedItem.Name.toString();
          }
        } 
        }
        ));
        this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654
sessionStorage.setItem('Componentname',this.Componentname);
  }

   public BRID:string;
   public fldrCurrency:string;
   public CustomerCode:string;
   getFolderValues()
  {  
    
    this.subscription.add(this.apiShared.folderCurrency.subscribe(fldrCurrency => this.fldrCurrency = fldrCurrency))
    this.subscription.add(this.apiShared.BRID.subscribe(BRID => this.BRID = BRID))
    this.subscription.add(this.apiShared.CustomerCode.subscribe(CustomerCode => this.CustomerCode = CustomerCode))
      
  }

   public BranchValueChange(value: any): void {
    console.log('selectionChange', value);
    this.BRID=(value.UsrCode);
  }
  async onGoToPage2()
  {    
    if(Boolean(await this.common.checkPermission1('01.12.01.23'))==true)
    {
      this.PassengerName=this.apiShared.PassengerName;
   
   
      const url=this.env.ViewTripURL+this.apiShared.PassengerName+'&Bookingref='+this.apiShared.orderNo+'&Module=PenAIR&PM=SagePay&UserId='+this.apiShared.uid+'&BRID='+this.BRID+'&TransType=M';
     
       window.open(url, '_blank');
    } 
    else{
      this.ShowPermissionMessageForm('01.12.01.23');
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.23');
    }

  
      
  }
  
  public CustomerValueChange(value: any): void {
         
    this.customerSelectedItem =this.CustomerList.find(item => item.Name ==value);
   
    if(this.customerSelectedItem != null)
    {
      
        this.CustomerCode=this.customerSelectedItem.Code.toString();
    }
    else
    {
      this.customerSelected="";
      this.CustomerCode=("");
    }
   // this.carEditForm.get("suppUserCodetext").setValue( this.supplierSelectedItem.Name);
  }
  GetFolderDetailsById(id) {
    this.subscription.add( this.api.GetFolderDetailsById(id,1)
      .subscribe(data => {
        console.log('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
                 
        this.CustomerPdqReceiptForm.setValue({
            BRID :data.ResponseData.BRID,
          });
       
      }));
   }
   ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public permissionmessageformopened:any;//Caseid-339
  public PermissionMessageId:any;//Caseid-339

public PermissionFormClosing(status:any)//339
  {
    if(status=="EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

ShowPermissionMessageForm(permissionid: string) {
  this.permissionmessageformopened=true;//Caseid-339
  this.PermissionMessageId =permissionid;//Caseid-339
}
}
