import { Component, OnInit,Input } from '@angular/core';

import { Subscription, BehaviorSubject } from 'rxjs';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonserviceService } from '../../Services/commonservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicLayoutComponent } from '../../public-layout/public-layout.component';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { TranslateService } from '@ngx-translate/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { FileService } from '../../Services/file.service';
import { ShareDataService } from '../../Services/share-data.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Logviewformservice} from '../../Services/logviewformservice'


@Component({
  selector: 'app-logform',
  templateUrl: './logform.component.html',
  styleUrls: ['./logform.component.scss']
})
export class LogformComponent implements OnInit {
  @Input() LogViewForm;
  constructor(public translate: TranslateService,private httpClient: HttpClient,private logviewform: Logviewformservice,private toastr:ToastrService,private route:ActivatedRoute,public common: CommonserviceService,private apiShared: ShareDataService,private lookupApi:LookUpDetailsService,private formBuilder: FormBuilder ,private router: Router,private translateapi: TranslateService,private flashMessage: FlashMessagesService,private emailapi: FileService) { }
public Notes:any;

ViewlogForm: FormGroup;
  ngOnInit() {
    
  this.Notes=this.logviewform.notes;
//this.Notes=this.LogViewForm
 
  }

}
