import { Component, OnInit, ViewChild, Output, Inject, Input } from '@angular/core';
import { LookUpDetails } from '../../Services/look-up-details';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { FolderSummaryEditComponent } from '../../folder/folder-summary-edit/folder-summary-edit.component';
import { FolderSummaryService } from '../../Services/folder-summary.service';
import { CustomerMaster, Login } from '../../Models/customer-create.model';
import { SupplierMasterDetailsDTO } from '../../Models/supplier-master-details-dto.model';
import { SupplierMasterService } from '../../Services/supplier-master.service';
import { HotelDetailsEditComponent } from '../hotel-details-edit/hotel-details-edit.component';
import { AirticketDetailsEditComponent } from '../../product/airticket-details-edit/airticket-details-edit.component';
import {
  DialogService,
  DialogRef,
  DialogCloseResult
} from '@progress/kendo-angular-dialog';
import { CarDetailsEditComponent } from '../car-details-edit/car-details-edit.component';
import { TransfersDetailEditComponent } from '../transfers-detail-edit/transfers-detail-edit.component';
import { CruiseDetailsEditComponent } from '../cruise-details-edit/cruise-details-edit.component';
import { OthersDetailsEditComponent } from '../others-details-edit/others-details-edit.component';
import { InsuranceDetailsEditComponent } from '../insurance-details-edit/insurance-details-edit.component';
import { RailDetailsEditComponent } from '../rail-details-edit/rail-details-edit.component';
import { TourDetailsEditComponent } from '../tour-details-edit/tour-details-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { HotelRatesetupEditComponentComponent } from 'src/app/HotelInfo/hotel-ratesetup-edit-component/hotel-ratesetup-edit-component.component';
import { HotelPriceDetailsComponent } from '../hotel-price-details/hotel-price-details.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Localization_Service } from '../../Services/localization.service';
import { CommonserviceService } from '../../Services/commonservice.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';


@Component({
  selector: 'app-supplier-edit',
  templateUrl: './supplier-edit.component.html',
  styleUrls: ['./supplier-edit.component.scss']
})
export class SupplierEditComponent implements OnInit {
  
  private subscription: Subscription = new Subscription();
 // @ViewChild(CarDetailsEditComponent,{read:false,static:false}) private Pcomponent: CarDetailsEditComponent;
  @Input() parentForm:FormGroup
  public result:any;
  addressdetailsform:FormGroup;    public opened = false;
  private lookup:LookUpDetails;
  public allowCustom: boolean = true;
  public IsAutocode=false;
  public showMsg:any;
  public Salutation: string = "";
  public reponse:any;
  public submitted=false;
  public CustomerName:string;
  public listItems: Array<string> = ["", "MR", "MISS", "MRS", "MASTER", "M/S", "DR", "PROF", "MS" ];
  constructor(private translateapi: TranslateService, private spinner: NgxSpinnerService, private dialogService: DialogService,private formBuilder: FormBuilder,private lookupApi:LookUpDetailsService,private flashMessage: FlashMessagesService,private api:SupplierMasterService,public parentcar:CarDetailsEditComponent,public parenthotel:HotelDetailsEditComponent,public tour:TourDetailsEditComponent,public airticket:AirticketDetailsEditComponent,public tranfer:TransfersDetailEditComponent
    ,public Cruise: CruiseDetailsEditComponent,public other:OthersDetailsEditComponent,public insurance:InsuranceDetailsEditComponent,public rail:RailDetailsEditComponent,private foldersummary:FolderSummaryService,private ratesetup:HotelRatesetupEditComponentComponent, private toastr:ToastrService,private hotelpricede:HotelPriceDetailsComponent,public LocService : Localization_Service,public commonservice:CommonserviceService) {this.LocService.getJSONLanguage('Default');}
  LanguageList: Array<LookUpDetails["ResponseData"]> = [];
  public LanguageSelectedItem: LookUpDetails["ResponseData"];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  CountryList:Array<LookUpDetails["ResponseData"]> = [];
  public countrySelectedItem: LookUpDetails["ResponseData"];
  public countrySelected:String;

  CitizenList:Array<LookUpDetails["ResponseData"]> = [];
  public citizenSelectedItem: LookUpDetails["ResponseData"];
    
  currencyList: Array<LookUpDetails["ResponseData"]> = [];
  public currencySelectedItem: LookUpDetails["ResponseData"];
  public currencySelected:String;
  TerritoryList:Array<LookUpDetails["ResponseData"]> = [];
  public TerritotySelectedItem: LookUpDetails["ResponseData"];
 

  
  ngOnInit() {
    debugger;
    this.LoadBasicdat();
 
      //  this.getdefaultvalue(); 
      
  }
  
  get f() { return this.addressdetailsform.controls; }
  public onFormSubmit()
  {
    debugger;
this.submitted=true;
this.spinner.show();
    this.api.formData=new SupplierMasterDetailsDTO;
    // this.api.CustomerMaster=new CustomerMaster;
    this.api.Login=new Login;
    var TaxValidTodata = (this.addressdetailsform.controls.TaxValidTo.value == null || this.addressdetailsform.controls.TaxValidTo.value == "" ? "01-Jan-1900" : this.addressdetailsform.controls.TaxValidTo.value);//Case id-337

    if (TaxValidTodata !="01-Jan-1900" &&Boolean(this.commonservice.ValidDateRange(TaxValidTodata)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    else
    {
    //  TaxValidTo=new Date(TaxValidTo);
    TaxValidTodata=this.commonservice.ConvertToLocaleDate(TaxValidTodata);
    
    }
    if (this.addressdetailsform.valid) {
      this.CustomerName=this.addressdetailsform.value["PrtyName"];
      this.api.formData=this.addressdetailsform.value;
      this.api.formData.TaxValidTo=TaxValidTodata;
      this.lookup=new LookUpDetails;
      this.lookup.ResponseData
      this.subscription.add( this.api.SaveCustomerAddress().subscribe(res=>{
        this.spinner.hide();
if(res["ResponseData"].alreadyexistsupplier==null && res["ResponseData"].PrtyCode>0)
{
  debugger;
  if(this.hotelpricede.parentForm!=undefined)
  {
    if(this.hotelpricede.parentForm.get('ITYPE').value=="Hotel")
    {
      this.hotelpricede.parentForm.controls.SUPPRTYCODE.setValue(res["ResponseData"].PrtyCode);
      this.hotelpricede.parentForm.controls.SUPPNAME.setValue(this.CustomerName);
       this.hotelpricede.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
    }
    else if(this.hotelpricede.parentForm.get('ITYPE').value=='Transfers')
    {
      this.hotelpricede.parentForm.controls.SUPPNAME.setValue(this.CustomerName);
      this.hotelpricede.parentForm.controls.SUPPCODE.setValue(res["ResponseData"].PrtyCode);
      this.hotelpricede.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
      this.hotelpricede.parentForm.controls.PROVIDER.setValue( this.CustomerName);


    }
    else if(this.hotelpricede.parentForm.get('ITYPE').value=='Car')
    {
      this.hotelpricede.parentForm.controls.SUPPRTYCODE.setValue(res["ResponseData"].PrtyCode);
      this.hotelpricede.parentForm.controls.SupName.setValue(this.CustomerName);
      this.hotelpricede.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
      this.hotelpricede.parentForm.controls.COMPANY.setValue( this.CustomerName);

    }
    else if(this.hotelpricede.parentForm.get('ITYPE').value=='Tour')
    {
    
      this.hotelpricede.parentForm.controls.SUPPNAME.setValue(this.CustomerName);
      this.hotelpricede.parentForm.controls.SUPPCODE.setValue(res["ResponseData"].PrtyCode);
this.hotelpricede.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
this.hotelpricede.parentForm.controls.Provider.setValue( this.CustomerName);

    }
    else if(this.hotelpricede.parentForm.get('ITYPE').value=='Cruise')
    {
      this.hotelpricede.parentForm.controls.SUPPNAME.setValue(this.CustomerName);
      this.hotelpricede.parentForm.controls.SUPPCODE.setValue(res["ResponseData"].PrtyCode);
      this.hotelpricede.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
      this.hotelpricede.parentForm.controls.Provider.setValue( this.CustomerName);
    }
    else if(this.hotelpricede.parentForm.get('ITYPE').value=='Insurance')
    {
   
      this.hotelpricede.parentForm.controls.SUPPLIER.setValue(this.CustomerName);
      this.hotelpricede.parentForm.controls.SUPPRTYCODE.setValue(res["ResponseData"].PrtyCode);
this.hotelpricede.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
    }
    else if(this.hotelpricede.parentForm.get('ITYPE').value=='Rail')
    {
   
      this.hotelpricede.parentForm.controls.SUPPNAME.setValue(this.CustomerName);
      this.hotelpricede.parentForm.controls.SUPPCODE.setValue(res["ResponseData"].PrtyCode);
      this.hotelpricede.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
      this.hotelpricede.parentForm.controls.Provider.setValue( this.CustomerName);

    }
    else if(this.hotelpricede.parentForm.get('ITYPE').value=='Others')
    {
      this.hotelpricede.parentForm.controls.SUPPNAME.setValue(this.CustomerName);
      this.hotelpricede.parentForm.controls.SUPPCODE.setValue(res["ResponseData"].PrtyCode);
      this.hotelpricede.supplierSelected=res["ResponseData"].PrtySuppUsrCode;


    }
    this.hotelpricede.close(false);
  }
  if(this.airticket.airticketEditForm!=null){
    this.airticket.close(false);
 //this.airticket.loadSupplier();
 
 this.airticket.airticketEditForm.controls.PRTYCODE.setValue(res["ResponseData"].PrtyCode);
 this.airticket.airticketEditForm.controls.SUPPNAME.setValue(this.CustomerName);
  
  this.airticket.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
  this.airticket.supplierValueChange(res["ResponseData"].PrtySuppUsrCode);
}

if(this.parenthotel.hotelEditForm!=null){
  this.parenthotel.close(false);

this.parenthotel.hotelEditForm.controls.SUPPRTYCODE.setValue(res["ResponseData"].PrtyCode);
this.parenthotel.hotelEditForm.controls.SUPPNAME.setValue(this.CustomerName);
this.parenthotel.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
}
if(this.ratesetup.roomdetailform!=null){
  this.ratesetup.close(false);

this.ratesetup.roomdetailform.controls.PRTYCODE.setValue(res["ResponseData"].PrtyCode);

this.ratesetup.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
}
if(this.tranfer.transferEditForm!=null){
  this.tranfer.close(false);
this.tranfer.transferEditForm.controls.SUPPNAME.setValue(this.CustomerName);
this.tranfer.transferEditForm.controls.SUPPCODE.setValue(res["ResponseData"].PrtyCode);
this.tranfer.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
this.tranfer.transferEditForm.controls.PROVIDER.setValue( this.CustomerName);

}
if(this.parentcar.carEditForm!=null){
  this.parentcar.close(false);
this.parentcar.carEditForm.controls.SUPPRTYCODE.setValue(res["ResponseData"].PrtyCode);
this.parentcar.carEditForm.controls.SupName.setValue(this.CustomerName);
this.parentcar.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
this.parentcar.carEditForm.controls.COMPANY.setValue( this.CustomerName);
}

if(this.tour.tourEditForm!=null){
  this.tour.close(false);
this.tour.tourEditForm.controls.SUPPNAME.setValue(this.CustomerName);
this.tour.tourEditForm.controls.SUPPCODE.setValue(res["ResponseData"].PrtyCode);
this.tour.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
this.tour.tourEditForm.controls.Provider.setValue( this.CustomerName);
}
if(this.Cruise.cruiseEditForm!=null){
  this.Cruise.close(false);
this.Cruise.cruiseEditForm.controls.SUPPNAME.setValue(this.CustomerName);
this.Cruise.cruiseEditForm.controls.SUPPCODE.setValue(res["ResponseData"].PrtyCode);
this.Cruise.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
this.Cruise.cruiseEditForm.controls.Provider.setValue( this.CustomerName);
}
if(this.insurance.insuranceEditForm!=null){
  this.insurance.close(false);
this.insurance.insuranceEditForm.controls.SUPPLIER.setValue(this.CustomerName);
this.insurance.insuranceEditForm.controls.SUPPRTYCODE.setValue(res["ResponseData"].PrtyCode);
this.insurance.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
}
if(this.rail.railEditForm!=null){
  this.rail.close(false);
this.rail.railEditForm.controls.SUPPNAME.setValue(this.CustomerName);
this.rail.railEditForm.controls.SUPPCODE.setValue(res["ResponseData"].PrtyCode);
this.rail.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
this.rail.railEditForm.controls.Provider.setValue( this.CustomerName);

}
if(this.other.OthersEditForm!=null){
  this.other.close(false);
this.other.OthersEditForm.controls.SUPPNAME.setValue(this.CustomerName);
this.other.OthersEditForm.controls.SUPPCODE.setValue(res["ResponseData"].PrtyCode);
this.other.supplierSelected=res["ResponseData"].PrtySuppUsrCode;
}

 // this.Pcomponent.close(false);


  
}
else if(res["ResponseData"].alreadyexistsupplier!=null)
{
  this.subscription.add(this.translateapi.get(['Messages.Suppliercodealreadyexist']).subscribe((translations ) => {
            
    this.showMsg= translations['Messages.Suppliercodealreadyexist'] ;
    // this.flashMessage.show(this.showMsg, { cssClass: 'bg-danger text-white', timeout: 2000 });
    this.toastr.warning(this.showMsg,"",{
      timeOut:5000,
      disableTimeOut:false,
      positionClass: 'toast-top-center',
      closeButton:true
    })
   }));
}
else{
  if(res["ResponseData"].ErrorMessage!==null && res["ResponseData"].ErrorMessage!==''){
    this.toastr.warning(res["ResponseData"].ErrorMessage,"",{
      timeOut:5000,
      disableTimeOut:false,
      closeButton:true
    })
  }
  else {
    this.subscription.add( this.translateapi.get(['Messages.Failed']).subscribe((translations ) => {   
      this.showMsg= translations['Messages.Failed'] ;
      // this.flashMessage.show(this.showMsg, { cssClass: 'bg-danger text-white', timeout: 2000 });
      this.toastr.warning(this.showMsg,"",{
        timeOut:5000,
        disableTimeOut:false,
        positionClass: 'toast-top-center',
        closeButton:true
      })
     }));
  }
  
}
     
          }))
    this.submitted=false;


    } else {
      this.spinner.hide();
    }
  }
  public close() {
    if(this.hotelpricede.parentForm!=undefined)
  {
    this.hotelpricede.close(false);
  }
    if(this.ratesetup.roomdetailform!=null){
      this.ratesetup.close(false);
    
    }
    if(this.airticket.airticketEditForm!=null){
      this.airticket.close(false);
  }
  
  if(this.parenthotel.hotelEditForm!=null){
    this.parenthotel.close(false);

  }
  
  if(this.tranfer.transferEditForm!=null){
    this.tranfer.close(false);

  }
  if(this.parentcar.carEditForm!=null){
    this.parentcar.close(false);

  }
  
  if(this.tour.tourEditForm!=null){
    this.tour.close(false);
  
  }
  if(this.Cruise.cruiseEditForm!=null){
    this.Cruise.close(false);

  }
  if(this.insurance.insuranceEditForm!=null){
    this.insurance.close(false);

  }
  if(this.rail.railEditForm!=null){
    this.rail.close(false);

  }
  if(this.other.OthersEditForm!=null){
    this.other.close(false);
  }
  }
  public open() {

    this.opened = true;
  }
  public LoadBasicdat()
  {
    this.addressdetailsform=this.formBuilder.group({
      PrtySuppUsrCode: [''],
      Salutation: [''],
      PrtyName: ['',Validators.required],
      Add1: ['',],
      Add2: ['',],
      Add3: ['',],
      Add4: ['',],
      PrtyPostalP: ['',],
      PrtyVatno:['',],
      PrtyCountryP:['',Validators.required],
      CURRENCYCODE:['',],
      TerritoryName:['',],
      TaxValidTo:[]
    })

    this.subscription.add( this.foldersummary.GetAutoCodeFlagforCustomer()  .subscribe(data => {
    
      this.IsAutocode=data["ResponseData"];
    
      if(this.IsAutocode === false)
      {
        const CustomerUserCode = this.addressdetailsform.get('PrtySuppUsrCode');
        CustomerUserCode.setValidators([Validators.required]);
    
        
      }
    
      this.subscription.add( this.lookupApi.GetAllLookup('CURRENCY').subscribe(data => {
        this.currencyList= data["ResponseData"];
        this.subscription.add(  this.foldersummary.GetDefaultCurrency().subscribe(data => {
            this.currencySelectedItem=this.currencyList.find(item => item.UsrCode ==data.ResponseData.Name);
            
            this.addressdetailsform.controls.CURRENCYCODE.setValue(this.currencySelectedItem.UsrCode);
          }));
      }));
      this.subscription.add( this.lookupApi.GetAllLookup('COUNTRY').subscribe(
        (data) => {
          this.CountryList= data["ResponseData"] ;
          this.subscription.add(  this.foldersummary.GetDefaultCountry().subscribe(data => {
            if(data!=null && data.ResponseData!=null )
            this.LocService.getJSONLanguage(data.ResponseData.UsrCode);
            this.GetTerritoryByCountry(data.ResponseData.UsrCode);
          this.countrySelectedItem=this.CountryList.find(item => item.UsrCode ==data.ResponseData.UsrCode);
          this.addressdetailsform.controls.PrtyCountryP.setValue(this.countrySelectedItem.UsrCode);
          }));
        }));
        }));
   

      }
      TerrritoyValueChange(value: any)
      {
        debugger;
        if(value!=null && value!=undefined){
          this.addressdetailsform.controls.TerritoryName.setValue(value.Name);
        
      }
      else
      {
        this.addressdetailsform.controls.TerritoryName.setValue("");
        this.TerritotySelectedItem=null;
      
      }
      }


  public CurrencyValueChange(value: any): void {
    if(value!=null && value!=undefined)
    this.addressdetailsform.controls.CURRENCYCODE.setValue(value.UsrCode);
    else
    this.addressdetailsform.controls.CURRENCYCODE.setValue("");
  }
 
  public CountryValueChange(value: any): void {
    debugger;
    this.spinner.show();
    if(value!=null && value!=undefined)
    {
      this.TerritoryList=[]
      this.TerritotySelectedItem=null;
      this.addressdetailsform.controls.TerritoryName.setValue('');
      this.GetTerritoryByCountry(value.UsrCode);
      this.addressdetailsform.controls.PrtyCountryP.setValue(value.UsrCode);
      value.Code=value.Code.toUpperCase();
      this.LocService.getJSONLanguage(value.UsrCode);
    }
    else
{
  this.spinner.hide();
  this.LocService.getJSONLanguage('Default');
  this.TerritoryList=[]
  this.addressdetailsform.controls.TerritoryName.setValue('');
  this.addressdetailsform.controls.PrtyCountryP.setValue("");
  this.TerritotySelectedItem=null;
}
 
  }
  GetTerritoryByCountry(usrcode)
{
  this.subscription.add(
    this.lookupApi
      .GetLookupById("Territory", usrcode, "1")
      .subscribe(data => {
        if(data!=null && data!=undefined)
        {
          debugger;
          this.spinner.hide();
         this.TerritoryList= data["ResponseData"] 
         if( this.TerritoryList.length>0)
         {
           if(this.addressdetailsform.controls.TerritoryName.value!=""&& this.addressdetailsform.controls.TerritoryName.value!='')
          this.TerritotySelectedItem=   this.TerritoryList.find(x=>x.Name==this.addressdetailsform.controls.TerritoryName.value);
        else{
         // this.TerritotySelectedItem=   this.TerritoryList[0];
         // this.supplierdetailsform.controls.TerritoryName.setValue(this.TerritotySelectedItem.Name);
        }
        }
         
         else
         {
          this.spinner.hide();
          this.addressdetailsform.controls.TerritoryName.setValue('');
          this.TerritotySelectedItem=null;
         }
                     
      }
      else
      {
        this.spinner.hide();
      }
      }))
}
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

