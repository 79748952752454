import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateStore, TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
public ReceiptPayment :number=+(sessionStorage.getItem('ReceiptPayment')==null?1:sessionStorage.getItem('ReceiptPayment')==''?1:sessionStorage.getItem('ReceiptPayment'));  ;
  private currencySource = new BehaviorSubject('');
  folderCurrency = this.currencySource.asObservable();

  private ExchangeRateSource = new BehaviorSubject('');
  folderExchangeRate = this.ExchangeRateSource.asObservable();
  private orderNoSource = new BehaviorSubject('');
  orderNo = this.orderNoSource.asObservable();
  private PassengerNameSource = new BehaviorSubject('');
  PassengerName = this.PassengerNameSource.asObservable();
  private PassengerNameSources = new BehaviorSubject('');
  PassengerNames = this.PassengerNameSources.asObservable();
  private bridSource = new BehaviorSubject('');
  BRID = this.bridSource.asObservable();
  private customercodeSource = new BehaviorSubject('');
  CustomerCode = this.customercodeSource.asObservable();

  private projNoSource = new BehaviorSubject('');
  projNo = this.projNoSource.asObservable();
  private folderReadonlySource = new BehaviorSubject('');
  folderReadOnly = this.folderReadonlySource.asObservable();
  private UidSource = new BehaviorSubject('');
  uid = this.UidSource.asObservable();

  private UnameSource = new BehaviorSubject('');
  uname = this.UnameSource.asObservable();

  private FolderEditSource = new BehaviorSubject('');
  folderEditMode = this.FolderEditSource.asObservable();

  private HCSource = new BehaviorSubject('');
  homeCurrency = this.HCSource.asObservable();

  private HExRateSource = new BehaviorSubject('');
  hcExRate = this.HExRateSource.asObservable();

  private BSPTaxNoSource = new BehaviorSubject('');
  bspTaxNo = this.BSPTaxNoSource.asObservable();

  private FolderStatusSource = new BehaviorSubject('');
  FolderStatus = this.FolderStatusSource.asObservable();

  private LanguageSelectedSource = new BehaviorSubject('');
  LanguageSelected = this.LanguageSelectedSource.asObservable();
  private BankReadonlySource = new BehaviorSubject('');
  BankLock = this.BankReadonlySource.asObservable();
  private BankNoReadonlySource = new BehaviorSubject('');
  BankNoLock = this.BankNoReadonlySource.asObservable();

  private baidSource = new BehaviorSubject('');
  BAID = this.baidSource.asObservable();

  private cLogoSource = new BehaviorSubject('');
  cLogo = this.cLogoSource.asObservable();
  private invoicenoSource = new BehaviorSubject('');//12728
  invoiceno = this.invoicenoSource.asObservable();

  private isWebLinkSource = new BehaviorSubject('');
  isWebLink = this.isWebLinkSource.asObservable();


  constructor(public translate: TranslateService) { }
  public FolderDate: any = null;
  public BALDUETO: number = 0;
  // changeData(currency: string, exRate: string, projNo:string,brid :string) {
  //   this.currencySource.next(currency)
  //   this.ExchangeRateSource.next(exRate)
  //   this.bridSource.next(brid)
  //  // this.customercodeSource.next(CustomerCode)
  //   this.projNoSource.next(projNo)
  // }

  private TranslationLanguageSelected = new BehaviorSubject('');
  private TranslationLang$ = this.TranslationLanguageSelected.asObservable();
  getTranslateLang(): Observable<string> {
    return this.TranslationLang$;
  }
  setTranslateLang(lang: string) {
    return this.TranslationLanguageSelected.next(lang);
  }
  private CredinotAllsrcsel = new BehaviorSubject('');
  private CredinotAll$ = this.CredinotAllsrcsel.asObservable();
  getCredinotAll(): Observable<string> {
    return this.CredinotAll$;
  }
  setCredinotAll(value: string) {
    return this.CredinotAllsrcsel.next(value);
  }
  Setapishareddata(data: any, type: string) {

    if (type === 'language') {
      sessionStorage.setItem('LanguageSelected', data)
      this.LanguageSelected = data;
    }
    if (type === 'FolderStatus') {
      sessionStorage.setItem('FolderStatus', data)
      this.FolderStatus = data;
    }
    if (type === 'bspTaxNo') {
      sessionStorage.setItem('bspTaxNo', data)
      this.bspTaxNo = data;
    }
    if (type === 'hcExRate') {
      sessionStorage.setItem('hcExRate', data)
      this.hcExRate = data;
    }
    if (type === 'homeCurrency') {
      sessionStorage.setItem('homeCurrency', data)
      this.homeCurrency = data;
    }
    if (type === 'folderEditMode') {
      sessionStorage.setItem('folderEditMode', data)
      this.folderEditMode = data;
    }
    if (type === 'uname') {

      sessionStorage.setItem('uname', data)
      this.uname = data;
    }
    if (type === 'uid') {
      sessionStorage.setItem('uid', data)
      this.uid = data;
    }
    if (type === 'folderReadOnly') {
      sessionStorage.setItem('folderReadOnly', data)
      this.folderReadOnly = data;
    }
    if (type === 'projNo') {
      sessionStorage.setItem('projNo', data)
      this.projNo = data;
    }
    if (type === 'CustomerCode') {
      sessionStorage.setItem('CustomerCode', data)
      this.CustomerCode = data;
    }
    if (type === 'BRID') {
      sessionStorage.setItem('BRID', data)
      this.BRID = data;
    }

    if (type === 'BAID') {
      sessionStorage.setItem('BAID', data)
      this.BAID = data;
    }
    if (type === 'PassengerNames') {
      sessionStorage.setItem('PassengerNames', data)
      this.PassengerNames = data;
    }
    if (type === 'PassengerName') {
      sessionStorage.setItem('PassengerName', data)
      this.PassengerName = data;
    }
    if (type === 'orderNo') {
      sessionStorage.setItem('orderNo', data)
      this.orderNo = data;
    }
    if (type === 'folderExchangeRate') {
      sessionStorage.setItem('folderExchangeRate', data)
      this.folderExchangeRate = data;
    }
    if (type === 'folderCurrency') {
      sessionStorage.setItem('folderCurrency', data)
      this.folderCurrency = data;
    }
    if (type === 'submenuIndex') {
      sessionStorage.setItem('submenuIndex', data)
    }
    if (type === 'banklock') {
      sessionStorage.setItem('banklock', data)
      this.BankLock = data;
    }
    if (type === 'BankNoLock') {
      sessionStorage.setItem('BankNoLock', data)
      this.BankNoLock = data;
    }
    //12728
    if (type === 'invoiceno') {
      sessionStorage.setItem('invoiceno', data)
      this.invoiceno = data;
    }
    if (type === 'ReceiptPayment') {
      sessionStorage.setItem('ReceiptPayment', data)
      this.ReceiptPayment = data;
    }
    
  }
}
