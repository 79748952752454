export class CrystalReport {
}
export class AtolReportDTO {
  parFolderNo: any

  parNumPass: any
  parNameofprotectingATOLholderandATOLnumber: any
  paruniquereferencenumber: any

  parDateofIssue: any
  parATOLCertificateissuer: any
  parALOLNo: any
  ParNumPassCount1: any
  parSRNO: any
  parSumPrice: any
  partravelDetailsArr: any

  partravelDetails_return: any
  index: any
  parBookingreferencenumber: any

  parAmt: any
  ParNumPassCount: any
  parItemno: any
  parProtectingATOLHolder: any
  parPackageDescription: any
  Reportfolder: string;
  Reportpath: string;
  EmailReportpath: any;
  emailsubject: any;
  reportfilename: any;
  emailbody: any;
  parPack_Item_Count: any
  AtolType: any
  issucess: any
  TYPEOFINV: number;
  USERID: number;
}