export class EpayEmail {

     FolderNo: any;
     ProjNo: any;
     BAID: any;
     BRID: any;
     Provider: any;
     Email: any;

     PDQ: any;
     OptionDate: any;
     optionTime: any;
     ViewTripUrl: any;

     PartyCode: any;
     PPID: any;
     PassFirstname: any;
     uid: any;
}
