import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FlashMessagesService } from 'angular2-flash-messages';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { Subscription } from 'rxjs';
import { ExportPDFParam } from '../../Models/common-class.model';
import { FeesReportDTO } from '../../Models/fees-report-dto.model';
import { LookUpDetails } from '../../Services/look-up-details';
import { PublicLayoutComponent } from '../../public-layout/public-layout.component';
import { CommonserviceService } from '../../Services/commonservice.service';
import { FileService } from '../../Services/file.service';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { ShareDataService } from '../../Services/share-data.service';
import { FolderActivityDetailedDTO } from '../../Models/folder-activity-detailed.model';
import { FolderActivityDetailedService } from '../../Services/folder-activity-detailed.service';
import { FolderActivityDetailedReportComponent } from '../folder-activity-detailed-report/folder-activity-detailed-report.component';
import { ModuleUsageService } from '../../Services/module-usage.service';


@Component({
  selector: 'app-folder-activity-detailed',
  templateUrl: './folder-activity-detailed.component.html',
  styleUrls: ['./folder-activity-detailed.component.scss']
})
export class FolderActivityDetailedComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  @ViewChild('typeInvoice')
  myInputVariable: ElementRef;
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  @ViewChild('autocompletecustomer') public autocompletecustomer: AutoCompleteComponent;
  @ViewChild('autocompletefrom') public autocompletefrom: AutoCompleteComponent;
  @ViewChild('autocompleteTo') public autocompleteTo: AutoCompleteComponent;

  FolderActivityDetailedDTO = new FolderActivityDetailedDTO();
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  lierSelectedItem: LookUpDetails["ResponseData"];
  branchList: Array<LookUpDetails['ResponseData']> = [];
  destinationList: Array<LookUpDetails["ResponseData"]> = [];
  destinationSelected: any;
  destinationToSelectedItem: any;
  DestinationFrom: any;
  Destination: any;
  PermissionMessageId: any;
  PermissionFormClosing: any;
  emailopened: any;
  permissionmessageformopened: any;
  action: any;
  csChecked: boolean = false;
  SupplierList: any;
  ordertypeSelecItem: any;
  SupplierSelectedUsrCode: any;
  public supplierSelected: String;
  suppCode: any;
  tempAutono: any;
  public SupplierName: String = "";
  public Supplier: any = 0;
  public opened = false;
  FolderActivityReportRequest: any;
  supplierSelectedItem: any;
  productSelectedItem: any;
  reportSelecItem: any;
  CreationFromDate: any;
  CreationToDate: any;
  InvoiceFromDate: any;
  InvoiceToDate: any;
  TravelFromDate: any;
  TravelToDate: any;
  BookingDateFrom: any;
  BookingDateTo: any;
  QConDateFrom: any;
  QConDateTo: any;
  lookupname: any = 'Supplier';
  ReceiptStatusSelectedItem: any;
  CreateDateFrom: any;
  CreateDateTo: any;
  InvoiceDateFrom: any;
  InvoiceDateTo: any;
  TravelDateFrom: any;
  TravelDateTo: any;
  QconDateFrom: any;
  ProfitFrom: any;
  folderNo: any;
  ProfitTo: any;
  UserSelectedItem: any;
  OrderNo: any;
  suppSelectedItem: any;
  suppSelected: any;
  salesOrganization: any;
  ordertypeList: any[];
  DivisionSelectedItem: any;
  public allData = { Name: "(ALL)" }
  DistributionList: any;
  CCGroupValue: any;
  ReceiptStatusValue: any;
  BusinessAreaSelectedItem: any;
  destinationToList: any;
  ReceiptStatus: any;
  customerSelected: any;
  branchSelectedItem: any;
  DistributionSelectedItem: any;
  customerGroupSelectedItem: any;
  CCGroup: any;
  lineItems: any;
  DestinationList: any;
  customerSelectedItem: any;
  customerSelectedCode: any;
  salesOrganizationValue: any;
  DivisionList: any;
  crUrlSafe: any;
  customerSelectedUsrCode: any;
  customerGroupList: any;
  CCGroupSelectedItem: any;
  CustomerList: any;
  custusrcode: any;
  DestinationSelectedItem: any;
  openSearch = false;
  lineItemsSelectedItem: any;
  lookuptype: string = '';
  UsersList: any;
  UserCode: any;
  UName: any;
  public productList: any;
  salesOrganizationList: any;
  salesOrganizationSelectedItem: any;
  BranchValue: any;
  Branch: any;
  lineItemsValue: any;
  CCGroupList: any;
  isShowncase = false;

  public StatusSelectedItem: any;
  public GroupItem: any;
  public subGroupItem: any;
  public typeofinvoiceItem: any;
  public paymentStatusSelectedItem: any;
  public ExportPDFParamList: ExportPDFParam[];
  public ModuleName: String = "Folder Activity Detailed Report";
  public EmailSubject: String = "Folder Activity Detailed Report";
  public bookingStatusSelectedItem: LookUpDetails["ResponseData"];
  businessAreaList: Array<LookUpDetails["ResponseData"]> = [];
  Bookingstauslist: Array<LookUpDetails["ResponseData"]> = [];

  public paymentList: Array<{ text: string, value: number }> =
    [{ text: "All", value: 1 }, { text: "Credit", value: 2 }, { text: "Company Card", value: 3 }, { text: "Customer Card", value: 4 }, { text: "Customer Pay Locally", value: 5 }];

  public productstauslist: Array<{ text: string, value: number }> =
    [{ text: "All", value: 4 }, { text: "OK", value: 5 }, { text: "Pending", value: 1 }, { text: "Cancelled", value: 2 }, { text: "Wait Listed", value: 6 }, { text: "Confirmed", value: 3 }];

  public receiptstauslist: Array<{ text: string, value: number }> =
    [{ text: "All", value: 3 }, { text: "Fully Paid", value: 5 }, { text: "Not Paid", value: 4 }, { text: "Partial", value: 1 }, { text: "Advance", value: 2 }];



  folderActivityReportForm: FormGroup;
  addressdetailsform: FormGroup;
  folderSummaryEditForm: FormGroup;
  public FeesReportRequest: any;
  public Customer = "";
  public rptloaded: any = false;
  submitted: any = false;
  public enableUnusedTicket = false;
  userID: number;
  RptHdrSel_TemplateName: any = "";
  RptHdrSel_AutoNo: any = '';
  ATInvoiceDateFrom: any;
  ATInvoiceDateTo: any;
  stateChange: any;
  FRCreateDateFrom: any;
  FRCreateDateTo: any;
  FRBookingDateFrom: any;
  FRBookingDateTo: any;
  FRTravelDateFrom: any;
  FRTravelDateTo: any;
  FRATInvoiceDateTo: any;
  FRATInvoiceDateFrom: any;
  gridData: any;
  disabledate: any;
  disableTraveldate: any;
  disableinvoicedate: any
  public column: any =
    "AirlineCodeCol,ArrivalCityCol,ATOLPAX,BAID,BalanceDueAmount,BalDueDateCol,BaseFareCol,QCONDATE,BookingStatusCol,bookingRef,BRNAME,BRID,BANAME,BuyAmtCol,BuyAmtHCCol,DAYS,CARDNUMBER,CARDValidity,CheckinCol,CheckOutCol,CityCol,HotelCityNameCol,CommAmtHCCol,COMMAMT,ConfirmationNo,CostDiscType,CRDATE,CruiseFrom,CruiseTo,FC_CID,CustNameColumn,CustCodeCol,CntryCodeCol,ClientCountryName,ClientCountry,DateinCol,DateOutCol,DepDueDateCol,CITYNAME,ARRDATE,DCM_NAME,FM_DM_UID,DEPDATE,EntitlementAmount,fldrBkdbyCol,PROJNO,GDS,HtlCol,NIGHTS,INETREF,Inputvatcol,InvDateCol,InvCol,ItineraryNotes,DiscVal,LidCol,Pass,BookedBy,LOYALTYPOINTS,MCIdColumn,MemberShipNoCol,NoOfPax,FOLDERNO,OrderTypeCol,OriginCol,PassCodeCol,PmCol,PnrCol,TYPE,ProductStatus,ProdctType,Provider,QCDATE,Receiptstatus,Remarks,ReturnDateCol,SalesTaxCol,SellAmtCol,SELLAMT,Status,SUPPUSERCODE,SUPPNAME,TaxesCol,TktNoCol,Tkt_Type,TicketingDeadline,TOMSVATAMT,TotalFareCol,TotalAmount,Folderdate,Description,VoucherNo,YourRefCol";
  BANAMEHidden: any = false;
  BRNAMEHidden: any = false;
  DCM_NAMEHidden: any = false;
  PROJNOHidden: any = false;
  FOLDERNOHidden: any = false;
  TYPEHidden: any = false;
  DescriptionHidden: any = false;
  SUPPUSERCODEHidden: any = false;
  SUPPNAMEHidden: any = false;
  FOLDERDATEHidden: any = false;
  BookingDateHidden: any = false;
  CRDATEHidden: any = false;
  TicketNoHidden: any = false;
  PassHidden: any = false;
  FC_CIDHidden: any = false;
  SELLAMTHidden: any = false;
  COMMAMTHidden: any = false;
  BuyAmtHidden: any = false;
  TOMSVATAMTHidden: any = false;
  CITYNAMEHidden: any = false;
  LOYALTYPOINTSAMTHidden: any = false;
  CostDiscTypeHidden: any = false;
  LOYALTYPOINTSHidden: any = false;
  LOYALTYPOINTSMEMBNOHidden: any = false;
  FM_DM_UIDHidden: any = false;
  BOOKEDBYHidden: any = false;
  bookingRefHidden: any = false;
  StatusHidden: any = false;
  DAYSHidden: any = false;
  NIGHTSHidden: any = false;
  CustLidHidden: any = false;
  CustCountryHidden: any = false;
  ClientHidden: any = false;
  CountryNameHidden: any = false;
  ATOLPAXHidden: any = false;
  INETREFHidden: any = false;
  UPLOADRCLHidden: any = false;
  VairlineAIRIDHidden: any = false;
  PONOHidden: any = false;
  OrderTypeHidden: any = false;
  HOTELNAMEHidden: any = false;
  BSPPurchaseTaxHidden: any = false;
  PaymentMethodHidden: any = false;
  FM_LIDHidden: any = false;
  NAMEHidden: any = false;
  MCIDHidden: any = false;
  DATEINHidden: any = false;
  DATEOUTHidden: any = false;
  CHECKINHidden: any = false;
  CHECKOUTHidden: any = false;
  CITYIDHidden: any = false;
  CITYHidden: any = false;
  BALDUEDATEHidden: any = false;
  DEPDUEDATEHidden: any = false;
  BaseFareHidden: any = false;
  ProdctTypeHidden: any = false;
  ProviderHidden: any = false;
  ItineraryNotesHidden: any = false;
  EntitlementAmountHidden: any = false;
  RemarksHidden: any = false;
  TotalHidden: any = false;
  FareHidden: any = false;
  DIVISIONHidden: any = false;
  SalesTaxHidden: any = false;
  TotalAmountHidden: any = false;
  OriginHidden: any = false;
  CustomerCodeHidden: any = false;
  PassengerCodeHidden: any = false;
  InvoiceDateHidden: any = false;
  InvoiceNoHidden: any = false;
  ArrivalCityHidden: any = false;
  ReturnDateHidden: any = false;
  SellAmountHCHidden: any = false;
  CommAmountHCHidden: any = false;
  BuyAmountHCHidden: any = false;
  NoOfPaxHidden: any = false;
  FolderBookedByHidden: any = false;
  BookingStatusHidden: any = false;
  Tkt_TypeHidden: any = false;
  TicketingDeadlineHidden: any = false;
  QCDATEHidden: any = false;
  GDSHidden: any = false;
  ReceiptstatusHidden: any = false;
  QCONDATEHidden: any = false;
  ProductStatusHidden: any = false;
  ConfirmationNoHidden: any = false;
  VoucherNoHidden: any = false;
  BRIDHidden: any = false;
  BAIDHidden: any = false;
  BalanceDueAmountHidden: any = false;
  CARDNUMBERHidden: any = false;
  CARDValidityHidden: any = false;
  CruiseFromHidden: any = false;
  CruiseToHidden: any = false;
  DEPDATEHidden: any = false;
  ARRDATEHidden: any = false;
  TaxesHidden: any = false;
  selectAll: any = false;
  BANAMEChk: any = false;
  BRNAMEChk: any = false;
  DCM_NAMEChk: any = false;
  PROJNOChk: any = false;
  FOLDERNOChk: any = false;
  TYPEChk: any = false;
  DescriptionChk: any = false;
  SUPPUSERCODEChk: any = false;
  SUPPNAMEChk: any = false;
  FOLDERDATEChk: any = false;
  BookingDateChk: any = false;
  CRDATEChk: any = false;
  TicketNoChk: any = false;
  PassChk: any = false;
  FC_CIDChk: any = false;
  SELLAMTChk: any = false;
  COMMAMTChk: any = false;
  BuyAmtChk: any = false;
  TOMSVATAMTChk: any = false;
  CITYNAMEChk: any = false;
  LOYALTYPOINTSAMTChk: any = false;
  CostDiscTypeChk: any = false;
  LOYALTYPOINTSChk: any = false;
  LOYALTYPOINTSMEMBNOChk: any = false;
  FM_DM_UIDChk: any = false;
  BOOKEDBYChk: any = false;
  bookingRefChk: any = false;
  StatusChk: any = false;
  DAYSChk: any = false;
  NIGHTSChk: any = false;
  CustLidChk: any = false;
  CustCountryChk: any = false;
  ClientChk: any = false;
  CountryNameChk: any = false;
  ATOLPAXChk: any = false;
  INETREFChk: any = false;
  UPLOADRCLChk: any = false;
  VairlineAIRIDChk: any = false;
  PONOChk: any = false;
  OrderTypeChk: any = false;
  HOTELNAMEChk: any = false;
  BSPPurchaseTaxChk: any = false;
  PaymentMethodChk: any = false;
  FM_LIDChk: any = false;
  NAMEChk: any = false;
  MCIDChk: any = false;
  DATEINChk: any = false;
  DATEOUTChk: any = false;
  CHECKINChk: any = false;
  CHECKOUTChk: any = false;
  CITYIDChk: any = false;
  CITYChk: any = false;
  BALDUEDATEChk: any = false;
  DEPDUEDATEChk: any = false;
  BaseFareChk: any = false;
  ProdctTypeChk: any = false;
  ProviderChk: any = false;
  ItineraryNotesChk: any = false;
  EntitlementAmountChk: any = false;
  RemarksChk: any = false;
  TotalChk: any = false;
  FareChk: any = false;
  SalesTaxChk: any = false;
  TotalAmountChk: any = false;
  OriginChk: any = false;
  CustomerCodeChk: any = false;
  QCDATEChk: any = false;
  DIVISIONChk: any = false;
  PassengerCodeChk: any = false;
  InvoiceDateChk: any = false;
  InvoiceNoChk: any = false;
  ArrivalCityChk: any = false;
  ReturnDateChk: any = false;
  SellAmountHCChk: any = false;
  CommAmountHCChk: any = false;
  BuyAmountHCChk: any = false;
  NoOfPaxChk: any = false;
  FolderBookedByChk: any = false;
  BookingStatusChk: any = false;
  Tkt_TypeChk: any = false;
  TicketingDeadlineChk: any = false;
  GDSChk: any = false;
  ReceiptstatusChk: any = false;
  QCONDATEChk: any = false;
  ProductStatusChk: any = false;
  ConfirmationNoChk: any = false;
  VoucherNoChk: any = false;
  BRIDChk: any = false;
  BAIDChk: any = false;
  BalanceDueAmountChk: any = false;
  CARDNUMBERChk: any = false;
  CARDValidityChk: any = false;
  CruiseFromChk: any = false;
  CruiseToChk: any = false;
  DEPDATEChk: any = false;
  ARRDATEChk: any = false;
  TaxesChk: any = false;
  PmSelectedItem: any;
  defaultItem: any;
  duplicatetemp: any = false;
  actionsLayout: any;
  public AirlineDetailedList: any;
  public total: any;

  constructor(private spinner: NgxSpinnerService,
    private cd: ChangeDetectorRef,
    public moduleUsageService: ModuleUsageService,
    public apiShared: ShareDataService,
    public FolderActivityService: FolderActivityDetailedService,
    public translate: TranslateService,
    public common: CommonserviceService,
    private route: ActivatedRoute,
    private layout: PublicLayoutComponent,
    private lookupApi: LookUpDetailsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private translateapi: TranslateService,
    private flashMessage: FlashMessagesService,
    private emailapi: FileService,
    private apishare: ShareDataService,
    public folderactivityObj: FolderActivityDetailedReportComponent,
    private toastr: ToastrService) { }

  TempList: Array<LookUpDetails["ResponseData"]> = [];
  public TemplateSelectedItem: LookUpDetails["ResponseData"];
  opentemplate: any = false;
  custocode: any;
  public Folderstatus = "";
  public FStatus: any;
  public Businessarea = "";
  public BArea = "";
  public bookingstatus = "";
  public showMsg: any;
  productStatusSelectedItem: any;
  fromId: string;
  bccId: string;
  ngOnInit(): void {
    this.LoadTemplate();

    this.PmSelectedItem = this.paymentList.find(x => x.text = "All")
    this.ReceiptStatusSelectedItem = this.receiptstauslist.find(x => x.text = "All")
    this.productStatusSelectedItem = this.productstauslist.find(x => x.text = "All")
    this.ordertypeSelecItem = "(ALL)";
    this.subscription.add(
      this.lookupApi.GetAllLookup("OrderType").subscribe((data) => {
        if (data != null && data != undefined) {
          this.ordertypeList = data["ResponseData"];
          this.ordertypeList.push(this.allData)
          if (this.FolderActivityService.formData != undefined && this.FolderActivityService.formData != null) {
            if (this.FolderActivityService.formData.pOrderType_varchar != "" && this.FolderActivityService.formData.pOrderType_varchar != "(ALL)") {
              this.ordertypeSelecItem = this.ordertypeList.find(item => item.UsrCode == this.FolderActivityService.formData.pOrderType_varchar)
              this.FolderActivityDetailedDTO.pOrderType_varchar = this.FolderActivityService.formData.pOrderType_varchar;
            } else {
              this.ordertypeSelecItem = this.ordertypeList.find(item => item.Name == "(ALL)")
              this.FolderActivityDetailedDTO.pOrderType_varchar = "(ALL)";
            }
          }
          else {
            this.ordertypeSelecItem = this.ordertypeList.find(item => item.Name == "(ALL)")
            this.FolderActivityDetailedDTO.pOrderType_varchar = "(ALL)";
          }
        }
      })
    );

    this.subscription.add(
      this.lookupApi.GetAllLookup("CUSTOMERGROUP").subscribe((data) => {
        debugger;
        if (data != null && data != undefined) {
          this.customerGroupList = data["ResponseData"];
          if (this.FolderActivityService.formData != undefined && this.FolderActivityService.formData != null) {
            if (this.FolderActivityService.formData.CustGrp_varchar != "") {
              this.customerGroupSelectedItem = this.customerGroupList.find
                (item => item.Code == this.FolderActivityService.formData.CustGrp_varchar);
            }
          }
        }
      })
    );

    this.subscription.add(
      this.lookupApi.GetAllLookup("CUSTOMERCREDITGROUP").subscribe((data) => {
        debugger;
        if (data != null && data != undefined) {
          this.CCGroupList = data["ResponseData"];
          if (this.FolderActivityService.formData != undefined && this.FolderActivityService.formData != null) {
            if (this.FolderActivityService.formData.CCGINTCODE_bigint != "") {
              this.CCGroupSelectedItem = this.CCGroupList.find
                (item => item.Code == this.FolderActivityService.formData.CCGINTCODE_bigint);
            }
          }
        }
      })
    );

    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        debugger;
        if (data != null && data != undefined) {
          this.UsersList = data["ResponseData"];
          if (this.FolderActivityService.formData != undefined && this.FolderActivityService.formData != null) {
            if (this.FolderActivityService.formData.pBookedBy_varchar != "") {
              this.lineItemsSelectedItem = this.UsersList.find
                (item => item.Name == this.FolderActivityService.formData.pBookedBy_varchar);
            }
            if (this.FolderActivityService.formData.FolderBookedBy != "") {
              this.UserSelectedItem = this.UsersList.find
                (item => item.Name == this.FolderActivityService.formData.FolderBookedBy);
            }
          }
        }

      }
    ));

    this.subscription.add(
      this.lookupApi.GetAllLookup("TYPE").subscribe(data => {
        if (data != null && data != undefined) {
          this.productList = data["ResponseData"];
          if (this.FolderActivityService.formData != undefined && this.FolderActivityService.formData != null) {
            if (this.FolderActivityService.formData.pProductCode_int != "") {
              this.productSelectedItem = this.productList.find
                (item => item.Code == this.FolderActivityService.formData.pProductCode_int);
            }
          }

        }
      })
    );

    this.subscription.add(this.lookupApi.GetAllLookup('BRANCH').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.branchList = data["ResponseData"];
          if (this.FolderActivityService.formData != undefined && this.FolderActivityService.formData != null) {
            if (this.FolderActivityService.formData.Branch_varchar != "") {
              this.branchSelectedItem = this.branchList.find
                (item => item.Code == this.FolderActivityService.formData.Branch_varchar);
            }
          }
        }
      }
    ));

    this.subscription.add(this.lookupApi.GetAllLookup('DIVISION').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.DivisionList = data["ResponseData"];
          if (this.FolderActivityService.formData != undefined && this.FolderActivityService.formData != null) {
            if (this.FolderActivityService.formData.SalesOrgCode != "") {
              this.DivisionSelectedItem = this.DivisionList.find
                (item => item.Code == this.FolderActivityService.formData.DM_UID);
            }
          }
        }
      }
    ));

    this.subscription.add(this.lookupApi.GetAllLookup('SALESORGANISATION').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.salesOrganizationList = data["ResponseData"];
          if (this.FolderActivityService.formData != undefined && this.FolderActivityService.formData != null) {
            if (this.FolderActivityService.formData.SalesOrgCode != "") {
              this.salesOrganizationValue = this.salesOrganizationList.find
                (item => item.Code == this.FolderActivityService.formData.SalesOrgCode);
            }
          }
        }
      }
    ));

    this.subscription.add(this.lookupApi.GetAllLookup('Distribution').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.DistributionList = data["ResponseData"];
          if (this.FolderActivityService.formData != undefined && this.FolderActivityService.formData != null) {
            if (this.FolderActivityService.formData.DCM_UID != "") {
              this.DistributionSelectedItem = this.DistributionList.find
                (item => item.Code == this.FolderActivityService.formData.DCM_UID);
            }
          }
        }
      }
    ));



    this.subscription.add(this.lookupApi.GetAllLookup('BUSINESSAREA').subscribe(
      (data) => {
        debugger;
        this.businessAreaList = data["ResponseData"];
        if (this.FolderActivityService.formData != undefined && this.FolderActivityService.formData != null) {

          if (this.FolderActivityService.formData.BArea_varchar != undefined) {
            this.BusinessAreaSelectedItem = this.businessAreaList.find
              (item => item.Code == this.FolderActivityService.formData.BArea_varchar);
          }
        }

      }
    ));

    if (this.FolderActivityService.formData != undefined && this.FolderActivityService.formData != null) {
      if (this.FolderActivityService.formData.ProdStatus != "") {
        this.productStatusSelectedItem = this.productstauslist.find
          (item => item.text == this.FolderActivityService.formData.ProdStatus);
        this.FolderActivityDetailedDTO.ProdStatus = this.FolderActivityService.formData.ProdStatus

      }
      if (this.FolderActivityService.formData.template != "") {

        this.FolderActivityDetailedDTO.template = this.FolderActivityService.formData.template

      }
      if (this.FolderActivityService.formData.ReciptStatus != "") {
        this.ReceiptStatusSelectedItem = this.receiptstauslist.find
          (item => item.text == this.FolderActivityService.formData.ReciptStatus);
        this.FolderActivityDetailedDTO.ReciptStatus = this.FolderActivityService.formData.ReciptStatus;
      }
      if (this.FolderActivityService.formData.Paymentmethod != "") {
        this.PmSelectedItem = this.paymentList.find
          (item => item.text == this.FolderActivityService.formData.Paymentmethod);
        this.FolderActivityDetailedDTO.Paymentmethod = this.FolderActivityService.formData.Paymentmethod;
      }
      if (this.FolderActivityService.formData.ProfitFrom != "") {
        this.ProfitFrom = this.FolderActivityService.formData.ProfitFrom;
      }
      if (this.FolderActivityService.formData.ProfitTo != "") {
        this.ProfitTo = this.FolderActivityService.formData.ProfitTo;
      }
      if (this.FolderActivityService.formData.vFolderNo != "") {
        this.folderNo = this.FolderActivityService.formData.vFolderNo;
      }
      if (this.FolderActivityService.formData.OrderNo != "") {
        this.OrderNo = this.FolderActivityService.formData.OrderNo;
      }
      if (this.FolderActivityService.formData.CreationFromDate != '01-Jan-1900') {
        this.CreateDateFrom = new Date(this.FolderActivityService.formData.CreationFromDate);
        this.CreateDateTo = new Date(this.FolderActivityService.formData.CreationToDate);
      }
      if (this.FolderActivityService.formData.InvoiceFromDate != '01-Jan-1900') {
        this.InvoiceDateFrom = new Date(this.FolderActivityService.formData.InvoiceFromDate);
        this.InvoiceDateTo = new Date(this.FolderActivityService.formData.InvoiceToDate);
      }
      if (this.FolderActivityService.formData.TravelFromDate != '01-Jan-1900') {
        this.TravelDateFrom = new Date(this.FolderActivityService.formData.TravelFromDate);
        this.TravelDateTo = new Date(this.FolderActivityService.formData.TravelToDate);
      }
      if (this.FolderActivityService.formData.BookingDateFrom != '01-Jan-1900') {
        this.BookingDateFrom = new Date(this.FolderActivityService.formData.BookingDateFrom);
        this.BookingDateTo = new Date(this.FolderActivityService.formData.BookingDateTo);
      }
      if (this.FolderActivityService.formData.QConDateFrom != '01-Jan-1900') {
        this.QConDateFrom = new Date(this.FolderActivityService.formData.QConDateFrom);
        this.QConDateTo = new Date(this.FolderActivityService.formData.QConDateTo);
      }
      if (this.FolderActivityService.formData.Destination != undefined && this.FolderActivityService.formData.Destination != '' && this.FolderActivityService.formData.Destination != null) {
        this.subscription.add(this.lookupApi.SearchDestination(this.FolderActivityService.destName, "1").subscribe(
          (data) => {
            if (data["ResponseData"] != null) {
              this.destinationToList = data["ResponseData"];
              if (this.FolderActivityService.formData.Destination != "") {
                this.destinationToSelectedItem = this.destinationToList.find(item => item.Code == this.FolderActivityService.formData.Destination);
                if (this.destinationToSelectedItem != null) {
                  this.destinationSelected = this.destinationToSelectedItem.Name;
                }
                this.DestinationValueChange(this.destinationSelected);
              }
            }

          }
        ));
      }
    }

    sessionStorage.setItem('Componentname', "folderactivitydeatiledRpt");
    this.userID = +(this.apishare.uid);
    this.FolderActivityService.formDataHeader.RptHdrSel_UserId = this.userID;
    this.subscription.add(this.lookupApi.GetAllLookup('CUSTOMER').subscribe(
      (data) => {
        debugger;
        this.CustomerList = data["ResponseData"];
        if (this.FolderActivityService.formData != undefined && this.FolderActivityService.formData != null) {

          if (this.FolderActivityService.formData.CustCode_int != "") {
            debugger;
            if (this.CustomerList != undefined) {
              this.customerSelectedItem = this.CustomerList.find(item => item.Code == this.FolderActivityService.formData.CustCode_int);
              if (this.customerSelectedItem != undefined) {
                this.CustomerValueChange(this.customerSelectedItem.Name);
              }
            }
          }
        }

      }
    ));

    this.subscription.add(this.lookupApi.GetAllLookup('SUPPLIER').subscribe(
      (data) => {
        debugger;
        this.SupplierList = data["ResponseData"];
        if (this.FolderActivityService.formData != undefined && this.FolderActivityService.formData != null) {

          if (this.FolderActivityService.formData.PrtyCode != "") {
            if (this.SupplierList != undefined) {
              this.suppSelectedItem = this.SupplierList.find(item => item.Code == this.FolderActivityService.formData.PrtyCode);
              if (this.suppSelectedItem != undefined) {
                this.SupplierValueChange(this.suppSelectedItem.Name);
              }
            }

          }
        }

      }
    ));

    this.LoadTemplate();
    this.loadDestinationbyid();
  }

  validateInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode);
    const pattern = /^[0-9]*$/;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public BusinessAreaValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.Businessarea = value.Name;
      this.BArea = value.Code
      this.FolderActivityDetailedDTO.BArea_varchar = this.BArea;
      this.FolderActivityService.BAreaname = value.Name;
    }
    else {
      this.Businessarea = "";
      this.BArea = "";
      this.FolderActivityDetailedDTO.BArea_varchar = "";
    }
  }

  public BranchValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.BranchValue = value.Name;
      this.Branch = value.UsrCode
      this.FolderActivityDetailedDTO.Branch_varchar = value.Code;
      this.FolderActivityService.branchname = value.Name;
    }
    else {
      this.BranchValue = "";
      this.Branch = "";
      this.FolderActivityDetailedDTO.Branch_varchar = "";
    }
  }

  public salesOrganizationValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.salesOrganizationValue = value.Name;
      this.salesOrganization = value.UsrCode;
      this.FolderActivityDetailedDTO.SalesOrgCode = value.Code;
    }
    else {
      this.salesOrganizationValue = "";
      this.salesOrganization = "";
      this.FolderActivityDetailedDTO.SalesOrgCode = "";
    }
  }

  public lineItemsValueChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.lineItemsValue = value.Name;
      this.lineItems = value.UsrCode;
      this.FolderActivityDetailedDTO.pBookedBy_varchar = value.Name;
    }
    else {
      this.lineItemsValue = "";
      this.lineItems = "";
      this.FolderActivityDetailedDTO.pBookedBy_varchar = "";
    }
  }

  loadDestinationbyid() {
    debugger;
    // if (this.FolderActivityService.formData.Destination != undefined && this.FolderActivityService.formData.Destination != '' && this.FolderActivityService.formData.Destination != null) {
    //   this.subscription.add(this.lookupApi.SearchDestination(this.FolderActivityService.destName, "1").subscribe(
    //     (data) => {
    //       if (data["ResponseData"] != null) {
    //         this.destinationToList = data["ResponseData"];
    //         if (this.FolderActivityService.formData.Destination != "") {
    //           this.destinationToSelectedItem = this.destinationToList.find(item => item.Code == this.FolderActivityService.formData.Destination);
    //           if (this.destinationToSelectedItem != null) {
    //             this.destinationSelected = this.destinationToSelectedItem.Name;
    //           }
    //           this.DestinationValueChange(this.destinationSelected);
    //         }
    //       }

    //     }
    //   ));
    // }
  }


  public DestinationValueChange(value: any): void {
    this.destinationToSelectedItem = this.destinationToList.find(item => item.Name == value);
    if (this.destinationToSelectedItem != null) {
      this.DestinationFrom = this.destinationToSelectedItem.ActualName;
      this.FolderActivityService.destName = this.destinationToSelectedItem.ActualName;
      this.Destination = this.destinationToSelectedItem.UsrCode;
      this.FolderActivityDetailedDTO.Destination = this.destinationToSelectedItem.UsrCode;
    }
    else {
      this.DestinationFrom = "";
      this.Destination = "";
      this.FolderActivityDetailedDTO.Destination = "";
    }
  }

  CCGroupValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.CCGroupValue = value.Name;
      this.CCGroup = value.UsrCode;
      this.FolderActivityDetailedDTO.CCGINTCODE_bigint = value.Code;
      this.FolderActivityService.creditName = value.Name;
    }
    else {
      this.CCGroupValue = "";
      this.CCGroup = "";
      this.FolderActivityDetailedDTO.CCGINTCODE_bigint = "";
    }
  }

  ReceiptStatusValueChange(value: any): void {
    if (value != null && value != undefined) {
      debugger;
      this.FolderActivityDetailedDTO.ReciptStatus = value.text;
    }
    else {
      this.FolderActivityDetailedDTO.ReciptStatus = "All";
      this.ReceiptStatusSelectedItem = this.receiptstauslist.find(x => x.text = "All")
    }
  }

  productValueChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.FolderActivityDetailedDTO.pProductCode_int = value.Code;
      this.FolderActivityService.ProductCodeName = value.Name;
    }
    else {
      this.FolderActivityDetailedDTO.pProductCode_int = "";
    }
  }

  productStatusValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.FolderActivityDetailedDTO.ProdStatus = value.text;
    }
    else {
      this.FolderActivityDetailedDTO.ProdStatus = "All";
      this.productStatusSelectedItem = this.productstauslist.find(x => x.text = "All")
      this.FolderActivityService.prodtstatus = "All"
    }
  }

  DivisionValueChange(value: any): void {
    if (value != null && value != undefined) {
      debugger;
      this.FolderActivityDetailedDTO.DM_UID = value.Code;
    }
    else {
      this.FolderActivityDetailedDTO.DM_UID = "";
    }
  }

  orderTypeValueChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.FolderActivityDetailedDTO.pOrderType_varchar = value.UsrCode;
      this.FolderActivityService.ordertypename = value.Name;
    }
    else {
      this.FolderActivityDetailedDTO.pOrderType_varchar = "(ALL)";
      this.ordertypeSelecItem = "(ALL)";
      this.FolderActivityService.ordertypename = "(ALL)";
    }
  }

  paymentMethodChange(value: any): void {
    if (value != null && value != undefined) {
      this.FolderActivityDetailedDTO.Paymentmethod = value.text;
    }
    else {
      this.FolderActivityDetailedDTO.Paymentmethod = "All";
      this.PmSelectedItem = this.paymentList.find(x => x.text = "All")
    }
  }

  DistributionValueChange(value: any): void {
    if (value != null && value != undefined) {
      debugger;
      this.FolderActivityDetailedDTO.DCM_UID = value.Code;
    }
    else {

      this.FolderActivityDetailedDTO.DCM_UID = "";
    }
  }

  CustomerGroupValueChange(value: any): void {
    if (value != null && value != undefined) {
      debugger;
      this.FolderActivityDetailedDTO.CustGrp_varchar = value.Code;
    }
    else {

      this.FolderActivityDetailedDTO.CustGrp_varchar = "";
    }
  }

  handleFiltercustomer(value) {
    if (value.length >= 3) {
      this.subscription.add(
        this.lookupApi.SearchCustomer(value, this.userID).subscribe((data) => {
          if (data != null && data != undefined) {
            this.CustomerList = data["ResponseData"];
          }
        })
      );
    } else {
      this.autocompletecustomer.toggle(false);
    }
  }

  handleFiltersupplier(value) {
    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
    } else {
      this.autocompletearrival.toggle(false);
    }
  }

  public FormClosing(status: any) {
    this.rptloaded = false;
  }

  public ReportSchedulerFormClose() {
    this.rptloaded = false;
  }

  public schedulerClose() {
    this.rptloaded = false;
  }

  CreateSheduler() {
    this.rptloaded = true;

    this.CreateDateTo =
      this.CreateDateTo == undefined || this.CreateDateTo == null || this.CreateDateTo == ""
        ? "01-Jan-1900"
        : this.CreateDateTo;

    this.InvoiceDateFrom =
      this.InvoiceDateFrom == undefined || this.InvoiceDateFrom == null || this.InvoiceDateFrom == ""
        ? "01-Jan-1900"
        : this.InvoiceDateFrom;
    this.InvoiceDateTo =
      this.InvoiceDateTo == undefined || this.InvoiceDateTo == null || this.InvoiceDateTo == ""
        ? "01-Jan-1900"
        : this.InvoiceDateTo;
    this.TravelDateFrom =
      this.TravelDateFrom == undefined || this.TravelDateFrom == null || this.TravelDateFrom == ""
        ? "01-Jan-1900"
        : this.TravelDateFrom;
    this.TravelDateTo =
      this.TravelDateTo == undefined || this.TravelDateTo == null || this.TravelDateTo == ""
        ? "01-Jan-1900"
        : this.TravelDateTo;
    this.QConDateFrom =
      this.QConDateFrom == undefined || this.QConDateFrom == null || this.QConDateFrom == ""
        ? "01-Jan-1900"
        : this.QConDateFrom;
    this.QConDateTo =
      this.QConDateTo == undefined || this.QConDateTo == null || this.QConDateTo == ""
        ? "01-Jan-1900"
        : this.QConDateTo;
    this.BookingDateFrom =
      this.BookingDateFrom == undefined || this.BookingDateFrom == null || this.BookingDateFrom == ""
        ? "01-Jan-1900"
        : this.BookingDateFrom;
    this.BookingDateTo =
      this.BookingDateTo == undefined || this.BookingDateTo == null || this.BookingDateTo == ""
        ? "01-Jan-1900"
        : this.BookingDateTo;
    var dateValidation = Date.parse(this.TravelFromDate);
    if (!isNaN(dateValidation)) {
      this.TravelFromDate = this.common.ConvertToLocaleDate(
        this.TravelFromDate
      );

    } else if (this.TravelFromDate != null && this.TravelFromDate != "") {
      this.TravelFromDate = "{{" + this.TravelFromDate + "}}";
    }
    dateValidation = Date.parse(this.TravelToDate);
    if (!isNaN(dateValidation)) {
      this.TravelToDate = this.common.ConvertToLocaleDate(
        this.TravelToDate
      );

    } else if (this.TravelToDate != null && this.TravelToDate != "") {
      this.TravelToDate = "{{" + this.TravelToDate + "}}";
    }
    dateValidation = Date.parse(this.BookingDateFrom);
    if (!isNaN(dateValidation)) {
      this.BookingDateFrom = this.common.ConvertToLocaleDate(
        this.BookingDateFrom
      );

    } else if (this.BookingDateFrom != null && this.BookingDateFrom != "") {
      this.BookingDateFrom = "{{" + this.BookingDateFrom + "}}";
    }
    dateValidation = Date.parse(this.BookingDateTo);
    if (!isNaN(dateValidation)) {
      this.BookingDateTo = this.common.ConvertToLocaleDate(
        this.BookingDateTo
      );

    } else if (this.BookingDateTo != null && this.BookingDateTo != "") {
      this.BookingDateTo = "{{" + this.BookingDateTo + "}}";
    }
    dateValidation = Date.parse(this.CreationFromDate);
    if (!isNaN(dateValidation)) {
      this.CreationFromDate = this.common.ConvertToLocaleDate(
        this.CreationFromDate
      );

    } else if (this.CreationFromDate != null && this.CreationFromDate != "") {
      this.CreationFromDate = "{{" + this.CreationFromDate + "}}";
    }
    dateValidation = Date.parse(this.CreationToDate);
    if (!isNaN(dateValidation)) {
      this.CreationToDate = this.common.ConvertToLocaleDate(
        this.CreationToDate
      );

    } else if (this.CreationToDate != null && this.CreationToDate != "") {
      this.CreationToDate = "{{" + this.CreationToDate + "}}";
    }
    dateValidation = Date.parse(this.InvoiceFromDate);
    if (!isNaN(dateValidation)) {
      this.InvoiceFromDate = this.common.ConvertToLocaleDate(
        this.InvoiceFromDate
      );

    } else if (this.InvoiceFromDate != null && this.InvoiceFromDate != "") {
      this.InvoiceFromDate = "{{" + this.InvoiceFromDate + "}}";
    }
    dateValidation = Date.parse(this.InvoiceToDate);
    if (!isNaN(dateValidation)) {
      this.InvoiceToDate = this.common.ConvertToLocaleDate(
        this.InvoiceToDate
      );

    } else if (this.InvoiceToDate != null && this.InvoiceToDate != "") {
      this.InvoiceToDate = "{{" + this.InvoiceToDate + "}}";
    }
    dateValidation = Date.parse(this.QConDateFrom);
    if (!isNaN(dateValidation)) {
      this.QConDateFrom = this.common.ConvertToLocaleDate(
        this.QConDateFrom
      );

    } else if (this.QConDateFrom != null && this.QConDateFrom != "") {
      this.QConDateFrom = "{{" + this.QConDateFrom + "}}";
    }
    dateValidation = Date.parse(this.QConDateTo);
    if (!isNaN(dateValidation)) {
      this.QConDateTo = this.common.ConvertToLocaleDate(
        this.QConDateTo
      );

    } else if (this.QConDateTo != null && this.QConDateTo != "") {
      this.QConDateTo = "{{" + this.QConDateTo + "}}";
    }

    this.FolderActivityDetailedDTO.TravelFromDate = this.TravelDateFrom;
    this.FolderActivityDetailedDTO.TravelToDate = this.TravelDateTo;
    this.FolderActivityDetailedDTO.BookingDateFrom = this.BookingDateFrom;
    this.FolderActivityDetailedDTO.BookingDateTo = this.BookingDateTo;
    this.FolderActivityDetailedDTO.CreationFromDate = this.CreateDateFrom;
    this.FolderActivityDetailedDTO.CreationToDate = this.CreateDateTo;
    this.FolderActivityDetailedDTO.InvoiceFromDate = this.InvoiceDateFrom;
    this.FolderActivityDetailedDTO.InvoiceToDate = this.InvoiceDateTo;
    this.FolderActivityDetailedDTO.QConDateFrom = this.QConDateFrom;
    this.FolderActivityDetailedDTO.QConDateTo = this.QConDateTo;
    this.FolderActivityDetailedDTO.vFolderNo = this.folderNo;
    this.FolderActivityDetailedDTO.OrderNo = this.OrderNo;
    this.FolderActivityDetailedDTO.ProfitFrom = this.ProfitFrom;
    this.FolderActivityDetailedDTO.ProfitTo = this.ProfitTo;
    this.FolderActivityDetailedDTO.csChecked = this.csChecked;
    this.FolderActivityDetailedDTO.RptHdrSel_UserId = this.userID;
    this.FolderActivityDetailedDTO.RptHdrSel_ModuleName = "Folder Activity Detailed Report";
    this.FolderActivityDetailedDTO.RptHdrSel_AutoNo = this.tempAutono == "" ? 0 : this.tempAutono;
    if ((this.FolderActivityDetailedDTO.template == undefined) || (this.FolderActivityDetailedDTO.template == "") || (this.FolderActivityDetailedDTO.template == null)) {
      this.FolderActivityDetailedDTO.template = "DefaultTemplate"
    }
    this.FolderActivityReportRequest = JSON.stringify(this.FolderActivityDetailedDTO);
  }

  onCancel() {
    var reporturl = sessionStorage.getItem("menuhighlight");
    if (reporturl != null) {
      this.router.navigate(["/Login"]);
    } else {
      this.router.navigate(["PenAir/Home"]);
    }
  }

  public LoadTemplate() {
    this.FolderActivityService.GetTemplate().subscribe(data => {
      if (data != null && data != undefined) {
        this.TempList = data["ResponseData"];
        if (this.FolderActivityService.formDataHeader.RptHdrSel_AutoNo > 0) {
          this.TemplateSelectedItem = this.TempList.find(
            x =>
              x.UsrCode ==
              this.FolderActivityService.formDataHeader.RptHdrSel_AutoNo
          );
        }
        else {
          debugger;
          this.TemplateSelectedItem = this.TempList[0];
        }
      }
    }
    );
  }

  EditTemplate() {
    debugger;
    if (this.TemplateSelectedItem == null || this.TemplateSelectedItem == undefined) {
      this.subscription.add(this.translateapi.get(['Messages.template_required']).subscribe((translations) => {
        this.showMsg = translations['Messages.template_required'];;
        this.toastr.error(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      return;
    }
    this.GetVarientTemplateForEdit();
  }

  GetVarientTemplateForEdit() {
    this.FolderActivityService.GetVarientTemplateForEdit(this.TemplateSelectedItem.Code, "Folder Activity Detailed Report").subscribe(data => {
      this.opentemplate = true;
      this.RptHdrSel_TemplateName = data["ResponseData"].RptHdrSel_TemplateName;
      this.RptHdrSel_AutoNo = data["ResponseData"].RptHdrSel_AutoNo;
      this.FolderActivityService.formDataHeader.RptHdrSel_AutoNo = this.RptHdrSel_AutoNo;
      if (data != null && data != undefined) {
        if (data["ResponseData"].RptHdrSel_ColumnHeader != null) {
          var datas = data["ResponseData"].RptHdrSel_ColumnHeader.split(',');
          //this.FolderActivityDetailedDTO.template = datas;
          this.FolderActivityService.templatedata = datas;
          "AirlineCodeCol,ArrivalCityCol,ATOLPAX,BAID,BalanceDueAmount,BalDueDateCol,BaseFareCol,QCONDATE,BookingStatusCol,bookingRef,BRNAME,BRID,BANAME,BuyAmtCol,BuyAmtHCCol,DAYS,CARDNUMBER,CARDValidity,CheckinCol,CheckOutCol,CityCol,HotelCityNameCol,CommAmtHCCol,COMMAMT,ConfirmationNo,CostDiscType,CRDATE,CruiseFrom,CruiseTo,FC_CID,CustNameColumn,CustCodeCol,CntryCodeCol,ClientCountryName,ClientCountry,DateinCol,DateOutCol,DepDueDateCol,CITYNAME,ARRDATE,DCM_NAME,FM_DM_UID,DEPDATE,EntitlementAmount,fldrBkdbyCol,PROJNO,GDS,HtlCol,NIGHTS,INETREF,Inputvatcol,InvDateCol,InvCol,ItineraryNotes,DiscVal,LidCol,Pass,BookedBy,LOYALTYPOINTS,MCIdColumn,MemberShipNoCol,NoOfPax,FOLDERNO,OrderTypeCol,OriginCol,PassCodeCol,PmCol,PnrCol,TYPE,ProductStatus,ProdctType,Provider,QCDATE,Receiptstatus,Remarks,ReturnDateCol,SalesTaxCol,SellAmtCol,SELLAMT,Status,SUPPUSERCODE,SUPPNAME,TaxesCol,TktNoCol,Tkt_Type,TicketingDeadline,TOMSVATAMT,TotalFareCol,TotalAmount,Folderdate,Description,VoucherNo,YourRefCol";
          if (datas.includes('Description')) {
            this.DescriptionChk = true;
          }
          else {
            this.DescriptionChk = false;
          }
          if (datas.includes('ProdctType')) {
            this.ProdctTypeChk = true;
          }
          else {
            this.ProdctTypeChk = false;
          }
          if (datas.includes('TotalAmount')) {
            this.TotalAmountChk = true;
          }
          else {
            this.TotalAmountChk = false;
          }
          if (datas.includes('QCDATE')) {
            this.QCDATEChk = true;
          }
          else {
            this.QCDATEChk = false;
          }
          if (datas.includes('QCONDATE')) {
            this.BookingDateChk = true;
          }
          else {
            this.BookingDateChk = false;
          }
          if (datas.includes('CustCodeCol')) {
            this.CustomerCodeChk = true;
          }
          else {
            this.CustomerCodeChk = false;
          }

          if (datas.includes('VoucherNo')) {
            this.VoucherNoChk = true;
          }
          else {
            this.VoucherNoChk = false;

          }
          if (datas.includes('YourRefCol')) {
            this.PONOChk = true;
          }
          else {
            this.PONOChk = false;
          }

          if (datas.includes('Folderdate')) {
            this.FOLDERDATEChk = true;
          }
          else {
            this.FOLDERDATEChk = false;
          }
          if (datas.includes('SalesTaxCol')) {
            this.SalesTaxChk = true;
          }
          else {
            this.SalesTaxChk = false;

          }
          if (datas.includes('TotalFareCol')) {
            this.TotalChk = true;
          }
          else {
            this.TotalChk = false;

          }
          if (datas.includes('TOMSVATAMT')) {
            this.TOMSVATAMTChk = true;
          }
          else {
            this.TOMSVATAMTChk = false;
          }

          if (datas.includes('TicketingDeadline')) {
            this.TicketingDeadlineChk = true;
          }
          else {
            this.TicketingDeadlineChk = false;

          }

          if (datas.includes('Tkt_Type')) {
            this.Tkt_TypeChk = true;
          }
          else {
            this.Tkt_TypeChk = false;

          }

          if (datas.includes('TktNoCol')) {
            this.TicketNoChk = true;
          }
          else {
            this.TicketNoChk = false;
          }



          if (datas.includes('TaxesCol')) {
            this.TaxesChk = true;
          }
          else {
            this.TaxesChk = false;

          }

          if (datas.includes('SUPPUSERCODE')) {
            this.SUPPUSERCODEChk = true;
          }
          else {
            this.SUPPUSERCODEChk = false;
          }
          if (datas.includes('SUPPNAME')) {
            this.SUPPNAMEChk = true;
          }
          else {
            this.SUPPNAMEChk = false;
          }

          if (datas.includes('Status')) {
            this.StatusChk = true;
          }
          else {
            this.StatusChk = false;
          }

          if (datas.includes('SELLAMT')) {
            this.SELLAMTChk = true;
          }
          else {
            this.SELLAMTChk = false;
          }


          if (datas.includes('SellAmtCol')) {
            this.SellAmountHCChk = true;
          }
          else {
            this.SellAmountHCChk = false;

          }

          if (datas.includes('SalesTaxCol')) {
            this.FareChk = true;
          }
          else {
            this.FareChk = false;

          }
          if (datas.includes('ReturnDateCol')) {
            this.ReturnDateChk = true;
          }
          else {
            this.ReturnDateChk = false;

          }

          if (datas.includes('Remarks')) {
            this.RemarksChk = true;
          }
          else {
            this.RemarksChk = false;

          }
          if (datas.includes('Receiptstatus')) {
            this.ReceiptstatusChk = true;
          }
          else {
            this.ReceiptstatusChk = false;

          }
          if (datas.includes('MemberShipNoCol')) {
            this.LOYALTYPOINTSMEMBNOChk = true;
          }
          else {
            this.LOYALTYPOINTSMEMBNOChk = false;

          }
          if (datas.includes('Provider')) {
            this.ProviderChk = true;
          }
          else {
            this.ProviderChk = false;
          }

          if (datas.includes('ProdctType')) {
            this.ProdctTypeChk = true;
          }
          else {
            this.ProdctTypeChk = false;

          }
          if (datas.includes('ProductStatus')) {
            this.ProductStatusChk = true;
          }
          else {
            this.ProductStatusChk = false;

          }

          if (datas.includes('TYPE')) {
            this.TYPEChk = true;
          }
          else {
            this.TYPEChk = false;
          }
          if (datas.includes('PnrCol')) {
            this.UPLOADRCLChk = true;
          }
          else {
            this.UPLOADRCLChk = false;
          }





          if (datas.includes('FM_DM_UID')) {
            this.DIVISIONChk = true;
          }
          else {
            this.DIVISIONChk = false;
          }

          if (datas.includes('PmCol')) {
            this.PaymentMethodChk = true;
          }
          else {
            this.PaymentMethodChk = false;
          }

          if (datas.includes('PassCodeCol')) {
            this.PassengerCodeChk = true;
          }
          else {
            this.PassengerCodeChk = false;

          }
          if (datas.includes('OriginCol')) {
            this.OriginChk = true;
          }
          else {
            this.OriginChk = false;

          }
          if (datas.includes('OrderTypeCol')) {
            this.OrderTypeChk = true;
          }
          else {
            this.OrderTypeChk = false;
          }

          if (datas.includes('FOLDERNO')) {
            this.FOLDERNOChk = true;
          }
          else {
            this.FOLDERNOChk = false;
          }

          if (datas.includes('NoOfPax')) {
            this.NoOfPaxChk = true;
          }
          else {
            this.NoOfPaxChk = false;

          }



          if (datas.includes('MCIdColumn')) {
            this.MCIDChk = true;
          }
          else {
            this.MCIDChk = false;
          }

          if (datas.includes('LOYALTYPOINTS')) {
            this.LOYALTYPOINTSChk = true;
          }
          else {
            this.LOYALTYPOINTSChk = false;
          }
          if (datas.includes('BookedBy')) {
            this.BOOKEDBYChk = true;
          }
          else {
            this.BOOKEDBYChk = false;
          }

          if (datas.includes('Pass')) {
            this.PassChk = true;
          }
          else {
            this.PassChk = false;
          }

          if (datas.includes('DiscVal')) {
            this.LOYALTYPOINTSAMTChk = true;
          }
          else {
            this.LOYALTYPOINTSAMTChk = false;
          }

          if (datas.includes('LidCol')) {
            this.FM_LIDChk = true;
          }
          else {
            this.FM_LIDChk = false;
          }
          if (datas.includes('ItineraryNotes')) {
            this.ItineraryNotesChk = true;
          }
          else {
            this.ItineraryNotesChk = false;

          }
          if (datas.includes('InvDateCol')) {
            this.InvoiceDateChk = true;
          }
          else {
            this.InvoiceDateChk = false;

          }

          if (datas.includes('InvCol')) {
            this.InvoiceNoChk = true;
          }
          else {
            this.InvoiceNoChk = false;

          }
          if (datas.includes('Inputvatcol')) {
            this.BSPPurchaseTaxChk = true;
          }
          else {
            this.BSPPurchaseTaxChk = false;
          }

          if (datas.includes('INETREF')) {
            this.INETREFChk = true;
          }
          else {
            this.INETREFChk = false;
          }

          if (datas.includes('NIGHTS')) {
            this.NIGHTSChk = true;
          }
          else {
            this.NIGHTSChk = false;
          }

          if (datas.includes('HtlCol')) {
            this.HOTELNAMEChk = true;
          }
          else {
            this.HOTELNAMEChk = false;
          }
          if (datas.includes('GDS')) {
            this.GDSChk = true;
          }
          else {
            this.GDSChk = false;

          }
          if (datas.includes('PROJNO')) {
            this.PROJNOChk = true;
          }
          else {
            this.PROJNOChk = false;
          }

          if (datas.includes('fldrBkdbyCol')) {
            this.FolderBookedByChk = true;
          }
          else {
            this.FolderBookedByChk = false;

          }
          if (datas.includes('EntitlementAmount')) {
            this.EntitlementAmountChk = true;
          }
          else {
            this.EntitlementAmountChk = false;

          }
          if (datas.includes('DepDueDateCol')) {
            this.DEPDUEDATEChk = true;
          }
          else {
            this.DEPDUEDATEChk = false;
          }
          if (datas.includes('CITYNAME')) {
            this.CITYNAMEChk = true;
          }
          else {
            this.CITYNAMEChk = false;
          }
          if (datas.includes('DEPDATE')) {
            this.DEPDATEChk = true;
          }
          else {
            this.DEPDATEChk = false;

          }


          if (datas.includes('DateinCol')) {
            this.DATEINChk = true;
          }
          else {
            this.DATEINChk = false;
          }
          if (datas.includes('DateOutCol')) {
            this.DATEOUTChk = true;
          }
          else {
            this.DATEOUTChk = false;
          }
          if (datas.includes('ARRDATE')) {
            this.ARRDATEChk = true;
          }
          else {
            this.ARRDATEChk = false;
          }

          if (datas.includes('ClientCountry')) {
            this.CustLidChk = true;
          }
          else {
            this.CustLidChk = false;
          }
          if (datas.includes('CntryCodeCol')) {
            this.CustCountryChk = true;
          }
          else {
            this.CustCountryChk = false;
          }
          if (datas.includes('ClientCountryName')) {
            this.ClientChk = true;
          }
          else {
            this.ClientChk = false;
          }

          if (datas.includes('CustNameColumn')) {
            this.NAMEChk = true;
          }
          else {
            this.NAMEChk = false;
          }
          if (datas.includes('DCM_NAME')) {
            this.DCM_NAMEChk = true;
          }
          else {
            this.DCM_NAMEChk = false;
          }




          if (datas.includes('FC_CID')) {
            this.FC_CIDChk = true;
          }
          else {
            this.FC_CIDChk = false;
          }
          if (datas.includes('CruiseFrom')) {
            this.CruiseFromChk = true;
          }
          else {
            this.CruiseFromChk = false;

          }
          if (datas.includes('Provider')) {
            this.ProviderChk = true;
          }
          else {
            this.ProviderChk = false;

          }
          if (datas.includes('CruiseTo')) {
            this.CruiseToChk = true;
          }
          else {
            this.CruiseToChk = false;

          }
          if (datas.includes('CRDATE')) {
            this.CRDATEChk = true;
          }
          else {
            this.CRDATEChk = false;
          }
          if (datas.includes('CostDiscType')) {
            this.CostDiscTypeChk = true;
          }
          else {
            this.CostDiscTypeChk = false;
          }
          if (datas.includes('ConfirmationNo')) {
            this.ConfirmationNoChk = true;
          }
          else {
            this.ConfirmationNoChk = false;

          }
          if (datas.includes('COMMAMT')) {
            this.COMMAMTChk = true;
          }
          else {
            this.COMMAMTChk = false;
          }

          if (datas.includes('CommAmtHCCol')) {
            this.CommAmountHCChk = true;
          }
          else {
            this.CommAmountHCChk = false;

          }
          if (datas.includes('CityCol')) {
            this.CITYIDChk = true;
          }
          else {
            this.CITYIDChk = false;
          }
          if (datas.includes('HotelCityNameCol')) {
            this.CITYChk = true;
          }
          else {
            this.CITYChk = false;
          }


          if (datas.includes('CheckinCol')) {
            this.CHECKINChk = true;
          }
          else {
            this.CHECKINChk = false;
          }
          if (datas.includes('CheckOutCol')) {
            this.CHECKOUTChk = true;
          }
          else {
            this.CHECKOUTChk = false;
          }
          if (datas.includes('CARDValidity')) {
            this.CARDValidityChk = true;
          }
          else {
            this.CARDValidityChk = false;

          }

          if (datas.includes('CARDNUMBER')) {
            this.CARDNUMBERChk = true;
          }
          else {
            this.CARDNUMBERChk = false;

          }
          if (datas.includes('DAYS')) {
            this.DAYSChk = true;
          }
          else {
            this.DAYSChk = false;
          }
          if (datas.includes('BuyAmtHCCol')) {
            this.BuyAmountHCChk = true;
          }
          else {
            this.BuyAmountHCChk = false;

          }
          if (datas.includes('BuyAmtCol')) {
            this.BuyAmtChk = true;
          }
          else {
            this.BuyAmtChk = false;
          }

          if (datas.includes('BANAME')) {
            this.BANAMEChk = true;
          }
          else {
            this.BANAMEChk = false;
          }


          if (datas.includes('BRID')) {
            this.BRIDChk = true;
          }
          else {
            this.BRIDChk = false;

          }
          if (datas.includes('BRNAME')) {
            this.BRNAMEChk = true;
          }
          else {
            this.BRNAMEChk = false;
          }
          if (datas.includes('bookingRef')) {
            this.bookingRefChk = true;
          }
          else {
            this.bookingRefChk = false;
          }
          if (datas.includes('BookingStatusCol')) {
            this.BookingStatusChk = true;
          }
          else {
            this.BookingStatusChk = false;

          }
          if (datas.includes('QCONDATE')) {
            this.BookingDateChk = true;
          }
          else {
            this.BookingDateChk = false;

          }
          if (datas.includes('BalDueDateCol')) {
            this.BALDUEDATEChk = true;
          }
          else {
            this.BALDUEDATEChk = false;
          }
          if (datas.includes('DepDueDateCol')) {
            this.DEPDUEDATEChk = true;
          }
          else {
            this.DEPDUEDATEChk = false;
          }
          if (datas.includes('BaseFareCol')) {
            this.BaseFareChk = true;
          }
          else {
            this.BaseFareChk = false;
          }
          if (datas.includes('BalanceDueAmount')) {
            this.BalanceDueAmountChk = true;
          }
          else {
            this.BalanceDueAmountChk = false;

          }
          if (datas.includes('BAID')) {
            this.BAIDChk = true;
          }
          else {
            this.BAIDChk = false;

          }
          if (datas.includes('ATOLPAX')) {
            this.ATOLPAXChk = true;
          }
          else {
            this.ATOLPAXChk = false;
          }
          if (datas.includes('ArrivalCityCol')) {
            this.ArrivalCityChk = true;
          }
          else {
            this.ArrivalCityChk = false;

          }
          if (datas.includes('AirlineCodeCol')) {
            this.VairlineAIRIDChk = true;
          }
          else {
            this.VairlineAIRIDChk = false;
          }
        }
      }
    })
  }

  public selectAllColumn() {
    if (this.selectAll == true) {
      this.BANAMEChk = true;
      this.BRNAMEChk = true;
      this.DCM_NAMEChk = true;
      this.PROJNOChk = true;
      this.FOLDERNOChk = true;
      this.TYPEChk = true;
      this.DescriptionChk = true;
      this.SUPPUSERCODEChk = true;
      this.SUPPNAMEChk = true;
      this.FOLDERDATEChk = true;
      this.BookingDateChk = true;
      this.CRDATEChk = true;
      this.TicketNoChk = true;
      this.PassChk = true;
      this.FC_CIDChk = true;
      this.SELLAMTChk = true;
      this.COMMAMTChk = true;
      this.BuyAmtChk = true;
      this.TOMSVATAMTChk = true;
      this.CITYNAMEChk = true;
      this.LOYALTYPOINTSAMTChk = true;
      this.CostDiscTypeChk = true;
      this.LOYALTYPOINTSChk = true;
      this.LOYALTYPOINTSMEMBNOChk = true;
      this.FM_DM_UIDChk = true;
      this.BOOKEDBYChk = true;
      this.bookingRefChk = true;
      this.StatusChk = true;
      this.DAYSChk = true;
      this.NIGHTSChk = true;
      this.CustLidChk = true;
      this.CustCountryChk = true;
      this.ClientChk = true;
      this.CountryNameChk = true;
      this.ATOLPAXChk = true;
      this.INETREFChk = true;
      this.UPLOADRCLChk = true;
      this.VairlineAIRIDChk = true;
      this.PONOChk = true;
      this.OrderTypeChk = true;
      this.HOTELNAMEChk = true;
      this.BSPPurchaseTaxChk = true;
      this.PaymentMethodChk = true;
      this.FM_LIDChk = true;
      this.NAMEChk = true;
      this.MCIDChk = true;
      this.QCDATEChk = true;
      this.DATEINChk = true;
      this.DATEOUTChk = true;
      this.CHECKINChk = true;
      this.CHECKOUTChk = true;
      this.CITYIDChk = true;
      this.CITYChk = true;
      this.BALDUEDATEChk = true;
      this.DEPDUEDATEChk = true;
      this.BaseFareChk = true;
      this.ProdctTypeChk = true;
      this.ProviderChk = true;
      this.ItineraryNotesChk = true;
      this.EntitlementAmountChk = true;
      this.DIVISIONChk = true;
      this.RemarksChk = true;
      this.TotalChk = true;
      this.FareChk = true;
      this.SalesTaxChk = true;
      this.TotalAmountChk = true;
      this.OriginChk = true;
      this.CustomerCodeChk = true;
      this.PassengerCodeChk = true;
      this.InvoiceDateChk = true;
      this.InvoiceNoChk = true;
      this.ArrivalCityChk = true;
      this.ReturnDateChk = true;
      this.SellAmountHCChk = true;
      this.CommAmountHCChk = true;
      this.BuyAmountHCChk = true;
      this.NoOfPaxChk = true;
      this.FolderBookedByChk = true;
      this.BookingStatusChk = true;
      this.Tkt_TypeChk = true;
      this.TicketingDeadlineChk = true;
      this.GDSChk = true;
      this.ReceiptstatusChk = true;
      this.QCONDATEChk = true;
      this.ProductStatusChk = true;
      this.ConfirmationNoChk = true;
      this.VoucherNoChk = true;
      this.BRIDChk = true;
      this.BAIDChk = true;
      this.BalanceDueAmountChk = true;
      this.CARDNUMBERChk = true;
      this.CARDValidityChk = true;
      this.CruiseFromChk = true;
      this.CruiseToChk = true;
      this.DEPDATEChk = true;
      this.ARRDATEChk = true;
      this.TaxesChk = true;     //

    } else {
      this.BANAMEChk = false;
      this.BRNAMEChk = false;
      this.DCM_NAMEChk = false;
      this.PROJNOChk = false;
      this.FOLDERNOChk = false;
      this.TYPEChk = false;
      this.DescriptionChk = false;
      this.SUPPUSERCODEChk = false;
      this.SUPPNAMEChk = false;
      this.FOLDERDATEChk = false;
      this.BookingDateChk = false;
      this.CRDATEChk = false;
      this.TicketNoChk = false;
      this.PassChk = false;
      this.FC_CIDChk = false;
      this.DIVISIONChk = false;
      this.SELLAMTChk = false;
      this.COMMAMTChk = false;
      this.BuyAmtChk = false;
      this.TOMSVATAMTChk = false;
      this.QCDATEChk = false;
      this.CITYNAMEChk = false;
      this.LOYALTYPOINTSAMTChk = false;
      this.CostDiscTypeChk = false;
      this.LOYALTYPOINTSChk = false;
      this.LOYALTYPOINTSMEMBNOChk = false;
      this.FM_DM_UIDChk = false;
      this.BOOKEDBYChk = false;
      this.bookingRefChk = false;
      this.StatusChk = false;
      this.DAYSChk = false;
      this.NIGHTSChk = false;
      this.CustLidChk = false;
      this.CustCountryChk = false;
      this.ClientChk = false;
      this.CountryNameChk = false;
      this.ATOLPAXChk = false;
      this.INETREFChk = false;
      this.UPLOADRCLChk = false;
      this.VairlineAIRIDChk = false;
      this.PONOChk = false;
      this.OrderTypeChk = false;
      this.HOTELNAMEChk = false;
      this.BSPPurchaseTaxChk = false;
      this.PaymentMethodChk = false;
      this.FM_LIDChk = false;
      this.NAMEChk = false;
      this.MCIDChk = false;
      this.DATEINChk = false;
      this.DATEOUTChk = false;
      this.CHECKINChk = false;
      this.CHECKOUTChk = false;
      this.CITYIDChk = false;
      this.CITYChk = false;
      this.BALDUEDATEChk = false;
      this.DEPDUEDATEChk = false;
      this.BaseFareChk = false;
      this.ProdctTypeChk = false;
      this.ProviderChk = false;
      this.ItineraryNotesChk = false;
      this.EntitlementAmountChk = false;
      this.RemarksChk = false;
      this.TotalChk = false;
      this.FareChk = false;
      this.SalesTaxChk = false;
      this.TotalAmountChk = false;
      this.OriginChk = false;
      this.CustomerCodeChk = false;
      this.PassengerCodeChk = false;
      this.InvoiceDateChk = false;
      this.InvoiceNoChk = false;
      this.ArrivalCityChk = false;
      this.ReturnDateChk = false;
      this.SellAmountHCChk = false;
      this.CommAmountHCChk = false;
      this.BuyAmountHCChk = false;
      this.NoOfPaxChk = false;
      this.FolderBookedByChk = false;
      this.BookingStatusChk = false;
      this.Tkt_TypeChk = false;
      this.TicketingDeadlineChk = false;
      this.GDSChk = false;
      this.ReceiptstatusChk = false;
      this.QCONDATEChk = false;
      this.ProductStatusChk = false;
      this.ConfirmationNoChk = false;
      this.VoucherNoChk = false;
      this.BRIDChk = false;
      this.BAIDChk = false;
      this.BalanceDueAmountChk = false;
      this.CARDNUMBERChk = false;
      this.CARDValidityChk = false;
      this.CruiseFromChk = false;
      this.CruiseToChk = false;
      this.DEPDATEChk = false;
      this.ARRDATEChk = false;
      this.TaxesChk = false;

    }
  }
  selectAlldisable() {
    if (
      (this.BANAMEChk == true) &&
      (this.BRNAMEChk == true) &&
      (this.DCM_NAMEChk == true) &&
      (this.PROJNOChk == true) &&
      (this.FOLDERNOChk == true) &&
      (this.TYPEChk == true) &&
      (this.DescriptionChk == true) &&
      (this.SUPPUSERCODEChk == true) &&
      (this.SUPPNAMEChk == true) &&
      (this.FOLDERDATEChk == true) &&
      (this.BookingDateChk == true) &&
      (this.CRDATEChk == true) &&
      (this.TicketNoChk == true) &&
      (this.PassChk == true) &&
      (this.FC_CIDChk == true) &&
      (this.SELLAMTChk == true) &&
      (this.COMMAMTChk == true) &&
      (this.BuyAmtChk == true) &&
      (this.TOMSVATAMTChk == true) &&
      (this.CITYNAMEChk == true) &&
      (this.LOYALTYPOINTSAMTChk == true) &&
      (this.CostDiscTypeChk == true) &&
      (this.LOYALTYPOINTSChk == true) &&
      (this.LOYALTYPOINTSMEMBNOChk == true) &&
      (this.FM_DM_UIDChk == true) &&
      (this.BOOKEDBYChk == true) &&
      (this.bookingRefChk == true) &&
      (this.StatusChk == true) &&
      (this.DAYSChk == true) &&
      (this.NIGHTSChk == true) &&
      (this.CustLidChk == true) &&
      (this.CustCountryChk == true) &&
      (this.QCDATEChk == true) &&

      (this.ClientChk == true) &&
      (this.CountryNameChk == true) &&
      (this.ATOLPAXChk == true) &&
      (this.INETREFChk == true) &&
      (this.UPLOADRCLChk == true) &&
      (this.VairlineAIRIDChk == true) &&
      (this.PONOChk == true) &&
      (this.OrderTypeChk == true) &&
      (this.HOTELNAMEChk == true) &&
      (this.BSPPurchaseTaxChk == true) &&
      (this.PaymentMethodChk == true) &&
      (this.FM_LIDChk == true) &&
      (this.NAMEChk == true) &&
      (this.MCIDChk == true) &&
      (this.DATEINChk == true) &&
      (this.DATEOUTChk == true) &&
      (this.CHECKINChk == true) &&
      (this.CHECKOUTChk == true) &&
      (this.CITYIDChk == true) &&
      (this.CITYChk == true) &&

      (this.DIVISIONChk == true) &&
      (this.BALDUEDATEChk == true) &&
      (this.DEPDUEDATEChk == true) &&
      (this.BaseFareChk == true) &&
      (this.ProdctTypeChk == true) &&
      (this.ProviderChk == true) &&
      (this.ItineraryNotesChk == true) &&
      (this.EntitlementAmountChk == true) &&
      (this.RemarksChk == true) &&
      (this.TotalChk == true) &&
      (this.FareChk == true) &&
      (this.SalesTaxChk == true) &&
      (this.TotalAmountChk == true) &&
      (this.OriginChk == true) &&
      (this.CustomerCodeChk == true) &&
      (this.PassengerCodeChk == true) &&
      (this.InvoiceDateChk == true) &&
      (this.InvoiceNoChk == true) &&
      (this.ArrivalCityChk == true) &&
      (this.ReturnDateChk == true) &&
      (this.SellAmountHCChk == true) &&
      (this.CommAmountHCChk == true) &&
      (this.BuyAmountHCChk == true) &&
      (this.NoOfPaxChk == true) &&
      (this.FolderBookedByChk == true) &&
      (this.BookingStatusChk == true) &&
      (this.Tkt_TypeChk == true) &&
      (this.TicketingDeadlineChk == true) &&
      (this.GDSChk == true) &&
      (this.ReceiptstatusChk == true) &&
      (this.QCONDATEChk == true) &&
      (this.ProductStatusChk == true) &&
      (this.ConfirmationNoChk == true) &&
      (this.VoucherNoChk == true) &&
      (this.BRIDChk == true) &&
      (this.BAIDChk == true) &&
      (this.BalanceDueAmountChk == true) &&
      (this.CARDNUMBERChk == true) &&
      (this.CARDValidityChk == true) &&
      (this.CruiseFromChk == true) &&
      (this.CruiseToChk == true) &&
      (this.DEPDATEChk == true) &&
      (this.ARRDATEChk == true) &&
      (this.TaxesChk == true)) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }

  }
  public onTabSelect(e) {

  }

  public enableAllColumn() {
    this.BANAMEHidden = false;
    this.BRNAMEHidden = false;
    this.DCM_NAMEHidden = false;
    this.PROJNOHidden = false;
    this.FOLDERNOHidden = false;
    this.TYPEHidden = false;
    this.DescriptionHidden = false;
    this.SUPPUSERCODEHidden = false;
    this.DIVISIONHidden = false;
    this.SUPPNAMEHidden = false;
    this.FOLDERDATEHidden = false;
    this.BookingDateHidden = false;
    this.CRDATEHidden = false;
    this.TicketNoHidden = false;
    this.PassHidden = false;
    this.FC_CIDHidden = false;
    this.SELLAMTHidden = false;
    this.COMMAMTHidden = false;
    this.BuyAmtHidden = false;
    this.TOMSVATAMTHidden = false;
    this.CITYNAMEHidden = false;
    this.LOYALTYPOINTSAMTHidden = false;
    this.CostDiscTypeHidden = false;
    this.LOYALTYPOINTSHidden = false;
    this.LOYALTYPOINTSMEMBNOHidden = false;
    this.FM_DM_UIDHidden = false;
    this.BOOKEDBYHidden = false;
    this.bookingRefHidden = false;
    this.StatusHidden = false;
    this.DAYSHidden = false;
    this.NIGHTSHidden = false;
    this.CustLidHidden = false;
    this.CustCountryHidden = false;
    this.ClientHidden = false;
    this.CountryNameHidden = false;
    this.ATOLPAXHidden = false;
    this.INETREFHidden = false;
    this.UPLOADRCLHidden = false;
    this.VairlineAIRIDHidden = false;
    this.PONOHidden = false;
    this.OrderTypeHidden = false;
    this.HOTELNAMEHidden = false;
    this.BSPPurchaseTaxHidden = false;
    this.PaymentMethodHidden = false;
    this.FM_LIDHidden = false;
    this.NAMEHidden = false;
    this.MCIDHidden = false;
    this.DATEINHidden = false;
    this.DATEOUTHidden = false;
    this.CHECKINHidden = false;
    this.CHECKOUTHidden = false;
    this.CITYIDHidden = false;
    this.CITYHidden = false;
    this.BALDUEDATEHidden = false;
    this.DEPDUEDATEHidden = false;
    this.BaseFareHidden = false;
    this.ProdctTypeHidden = false;
    this.ProviderHidden = false;
    this.ItineraryNotesHidden = false;
    this.EntitlementAmountHidden = false;
    this.RemarksHidden = false;
    this.TotalHidden = false;
    this.FareHidden = false;
    this.SalesTaxHidden = false;
    this.TotalAmountHidden = false;
    this.OriginHidden = false;
    this.CustomerCodeHidden = false;
    this.PassengerCodeHidden = false;
    this.InvoiceDateHidden = false;
    this.InvoiceNoHidden = false;
    this.ArrivalCityHidden = false;
    this.QCDATEHidden = false;
    this.ReturnDateHidden = false;
    this.SellAmountHCHidden = false;
    this.CommAmountHCHidden = false;
    this.BuyAmountHCHidden = false;
    this.NoOfPaxHidden = false;
    this.FolderBookedByHidden = false;
    this.BookingStatusHidden = false;
    this.Tkt_TypeHidden = false;
    this.TicketingDeadlineHidden = false;
    this.GDSHidden = false;
    this.ReceiptstatusHidden = false;
    this.QCONDATEHidden = false;
    this.ProductStatusHidden = false;
    this.ConfirmationNoHidden = false;
    this.VoucherNoHidden = false;
    this.BRIDHidden = false;
    this.BAIDHidden = false;
    this.BalanceDueAmountHidden = false;
    this.CARDNUMBERHidden = false;
    this.CARDValidityHidden = false;
    this.CruiseFromHidden = false;
    this.CruiseToHidden = false;
    this.DEPDATEHidden = false;
    this.ARRDATEHidden = false;
    this.TaxesHidden = false;

  }

  CreateTemplate() {
    this.DIVISIONChk = false;
    this.BANAMEChk = false;
    this.BRNAMEChk = false;
    this.DCM_NAMEChk = false;
    this.PROJNOChk = false;
    this.FOLDERNOChk = false;
    this.TYPEChk = false;
    this.DescriptionChk = false;
    this.SUPPUSERCODEChk = false;
    this.SUPPNAMEChk = false;
    this.FOLDERDATEChk = false;
    this.BookingDateChk = false;
    this.CRDATEChk = false;
    this.TicketNoChk = false;
    this.PassChk = false;
    this.FC_CIDChk = false;
    this.SELLAMTChk = false;
    this.COMMAMTChk = false;
    this.BuyAmtChk = false;
    this.TOMSVATAMTChk = false;
    this.CITYNAMEChk = false;
    this.LOYALTYPOINTSAMTChk = false;
    this.CostDiscTypeChk = false;
    this.LOYALTYPOINTSChk = false;
    this.LOYALTYPOINTSMEMBNOChk = false;
    this.FM_DM_UIDChk = false;
    this.BOOKEDBYChk = false;
    this.bookingRefChk = false;
    this.StatusChk = false;
    this.DAYSChk = false;
    this.NIGHTSChk = false;
    this.CustLidChk = false;
    this.CustCountryChk = false;
    this.QCDATEChk = false;
    this.ClientChk = false;
    this.CountryNameChk = false;
    this.ATOLPAXChk = false;
    this.INETREFChk = false;
    this.UPLOADRCLChk = false;
    this.VairlineAIRIDChk = false;
    this.PONOChk = false;
    this.OrderTypeChk = false;
    this.HOTELNAMEChk = false;
    this.BSPPurchaseTaxChk = false;
    this.PaymentMethodChk = false;
    this.FM_LIDChk = false;
    this.NAMEChk = false;
    this.MCIDChk = false;
    this.DATEINChk = false;
    this.DATEOUTChk = false;
    this.CHECKINChk = false;
    this.CHECKOUTChk = false;
    this.CITYIDChk = false;
    this.CITYChk = false;
    this.BALDUEDATEChk = false;
    this.DEPDUEDATEChk = false;
    this.BaseFareChk = false;
    this.ProdctTypeChk = false;
    this.ProviderChk = false;
    this.ItineraryNotesChk = false;
    this.EntitlementAmountChk = false;
    this.RemarksChk = false;
    this.TotalChk = false;
    this.FareChk = false;
    this.SalesTaxChk = false;
    this.TotalAmountChk = false;
    this.OriginChk = false;
    this.CustomerCodeChk = false;
    this.PassengerCodeChk = false;
    this.InvoiceDateChk = false;
    this.InvoiceNoChk = false;
    this.ArrivalCityChk = false;
    this.ReturnDateChk = false;
    this.SellAmountHCChk = false;
    this.CommAmountHCChk = false;
    this.BuyAmountHCChk = false;
    this.NoOfPaxChk = false;
    this.FolderBookedByChk = false;
    this.BookingStatusChk = false;
    this.Tkt_TypeChk = false;
    this.TicketingDeadlineChk = false;
    this.GDSChk = false;
    this.ReceiptstatusChk = false;
    this.QCONDATEChk = false;
    this.ProductStatusChk = false;
    this.ConfirmationNoChk = false;
    this.VoucherNoChk = false;
    this.BRIDChk = false;
    this.BAIDChk = false;
    this.BalanceDueAmountChk = false;
    this.CARDNUMBERChk = false;
    this.CARDValidityChk = false;
    this.CruiseFromChk = false;
    this.CruiseToChk = false;
    this.DEPDATEChk = false;
    this.ARRDATEChk = false;
    this.TaxesChk = false;


    this.selectAll = false;
    this.RptHdrSel_TemplateName = null;
    this.submitted = false;
    this.opentemplate = true;
    this.FolderActivityService.formDataHeader.RptHdrSel_AutoNo = 0;
  }

  close_temp() {
    this.opentemplate = false;
  }

  onSave() {
    debugger;
    this.submitted = true;
    if (this.FolderActivityService.formDataHeader.RptHdrSel_AutoNo == 0) {

      if (
        this.RptHdrSel_TemplateName == null ||
        this.RptHdrSel_TemplateName == undefined ||
        this.RptHdrSel_TemplateName == ""
      ) {
        return;
      }
      else {
        if (this.selectAll == true) {
          this.column = "AirlineCodeCol,ArrivalCityCol,ATOLPAX,BAID,BalanceDueAmount,BalDueDateCol,BaseFareCol,QCONDATE,BookingStatusCol,bookingRef,BRNAME,BRID,BANAME,BuyAmtCol,BuyAmtHCCol,DAYS,CARDNUMBER,CARDValidity,CheckinCol,CheckOutCol,CityCol,HotelCityNameCol,CommAmtHCCol,COMMAMT,ConfirmationNo,CostDiscType,CRDATE,CruiseFrom,CruiseTo,FC_CID,CustNameColumn,CustCodeCol,CntryCodeCol,ClientCountryName,ClientCountry,DateinCol,DateOutCol,DepDueDateCol,CITYNAME,ARRDATE,DCM_NAME,FM_DM_UID,DEPDATE,EntitlementAmount,fldrBkdbyCol,PROJNO,GDS,HtlCol,NIGHTS,INETREF,Inputvatcol,InvDateCol,InvCol,ItineraryNotes,DiscVal,LidCol,Pass,BookedBy,LOYALTYPOINTS,MCIdColumn,MemberShipNoCol,NoOfPax,FOLDERNO,OrderTypeCol,OriginCol,PassCodeCol,PmCol,PnrCol,TYPE,ProductStatus,ProdctType,Provider,QCDATE,Receiptstatus,Remarks,ReturnDateCol,SalesTaxCol,SellAmtCol,SELLAMT,Status,SUPPUSERCODE,SUPPNAME,TaxesCol,TktNoCol,Tkt_Type,TicketingDeadline,TOMSVATAMT,TotalFareCol,TotalAmount,Folderdate,Description,VoucherNo,YourRefCol";
        }
        else {
          this.column = "";
          if (this.BANAMEChk == true) {
            this.column = "BANAME";
          }
          if (this.BRNAMEChk == true) {
            this.column = this.column + ",BRNAME";
          }
          if (this.DCM_NAMEChk == true) {
            this.column = this.column + ",DCM_NAME";
          }
          if (this.PROJNOChk == true) {
            this.column = this.column + ",PROJNO";
          }

          if (this.DIVISIONChk == true) {
            this.column = this.column + ",FM_DM_UID";
          }

          if (this.FM_LIDChk == true) {
            this.column = this.column + ",LidCol";
          }
          if (this.FOLDERNOChk == true) {
            this.column = this.column + ",FOLDERNO";
          }
          if (this.TYPEChk == true) {
            this.column = this.column + ",TYPE";
          }
          if (this.TotalAmountChk == true) {
            this.column = this.column + ",TotalAmount";
          }

          if (this.CustCountryChk == true) {
            this.column = this.column + ",CntryCodeCol";
          }

          if (this.SUPPUSERCODEChk == true) {
            this.column = this.column + ",SUPPUSERCODE";
          }
          if (this.SUPPNAMEChk == true) {
            this.column = this.column + ",SUPPNAME";
          }
          if (this.FOLDERDATEChk == true) {
            this.column = this.column + ",Folderdate";
          }



          if (this.BOOKEDBYChk == true) {
            this.column = this.column + ",BookedBy";
          }
          if (this.CRDATEChk == true) {
            this.column = this.column + ",CRDATE";
          }
          if (this.TicketNoChk == true) {
            this.column = this.column + ",TktNoCol";
          }
          if (this.PassChk == true) {
            this.column = this.column + ",Pass";
          }
          if (this.FC_CIDChk == true) {
            this.column = this.column + ",FC_CID";
          }
          if (this.SELLAMTChk == true) {
            this.column = this.column + ",SELLAMT";
          }

          if (this.COMMAMTChk == true) {
            this.column = this.column + ",COMMAMT";
          }
          if (this.BuyAmtChk == true) {
            this.column = this.column + ",BuyAmtCol";
          }
          if (this.TOMSVATAMTChk == true) {
            this.column = this.column + ",TOMSVATAMT";
          }
          if (this.CITYNAMEChk == true) {
            this.column = this.column + ",CITYNAME";
          }

          if (this.LOYALTYPOINTSAMTChk == true) {
            this.column = this.column + ",DiscVal";
          }
          if (this.CostDiscTypeChk == true) {
            this.column = this.column + ",CostDiscType";
          }
          if (this.LOYALTYPOINTSChk == true) {
            this.column = this.column + ",LOYALTYPOINTS";
          }
          if (this.LOYALTYPOINTSMEMBNOChk == true) {
            this.column = this.column + ",MemberShipNoCol";
          }

          // if (this.FM_DM_UIDChk == true) {
          //   this.column = this.column + ",FM_DM_UID";
          // }
          if (this.BOOKEDBYChk == true) {
            this.column = this.column + ",LineItemBookedBy";
          }
          if (this.bookingRefChk == true) {
            this.column = this.column + ",bookingRef";
          }
          if (this.StatusChk == true) {
            this.column = this.column + ",Status";
          }

          if (this.DAYSChk == true) {
            this.column = this.column + ",DAYS";
          }
          if (this.NIGHTSChk == true) {
            this.column = this.column + ",NIGHTS";
          }
          if (this.CustLidChk == true) {
            this.column = this.column + ",ClientCountry";////doub
          }
          if (this.CustCountryChk == true) {
            this.column = this.column + ",CntryCodeCol";
          }

          // if (this.CustCountryChk == true) {
          //   this.column = this.column + ",CntryCodeCol";
          // }
          if (this.ATOLPAXChk == true) {
            this.column = this.column + ",ATOLPAX";
          }

          if (this.INETREFChk == true) {
            this.column = this.column + ",INETREF";
          }
          if (this.UPLOADRCLChk == true) {
            this.column = this.column + ",PnrCol";
          }
          if (this.VairlineAIRIDChk == true) {
            this.column = this.column + ",AirlineCodeCol";
          }

          if (this.PONOChk == true) {
            this.column = this.column + ",YourRefCol";
          }
          if (this.OrderTypeChk == true) {
            this.column = this.column + ",OrderTypeCol";
          }


          if (this.HOTELNAMEChk == true) {
            this.column = this.column + ",HtlCol";
          }
          if (this.BSPPurchaseTaxChk == true) {
            this.column = this.column + ",Inputvatcol";
          }
          if (this.PaymentMethodChk == true) {
            this.column = this.column + ",PmCol";
          }

          if (this.FM_LIDChk == true) {
            this.column = this.column + ",LidCol";
          }
          if (this.NAMEChk == true) {
            this.column = this.column + ",CustNameColumn";
          }

          if (this.MCIDChk == true) {
            this.column = this.column + ",MCIdColumn";
          }
          if (this.DATEINChk == true) {
            this.column = this.column + ",DateinCol";
          }
          if (this.DATEOUTChk == true) {
            this.column = this.column + ",DateOutCol";
          }

          if (this.CHECKINChk == true) {
            this.column = this.column + ",CheckinCol";
          }
          if (this.CHECKOUTChk == true) {
            this.column = this.column + ",CheckOutCol";
          }

          if (this.CITYIDChk == true) {
            this.column = this.column + ",CityCol";
          }

          //need to add
          if (this.CITYChk == true) {
            this.column = this.column + ",HotelCityNameCol";
          }
          if (this.BALDUEDATEChk == true) {
            this.column = this.column + ",BalDueDateCol";
          }

          if (this.DEPDUEDATEChk == true) {
            this.column = this.column + ",DepDueDateCol";
          }
          if (this.BaseFareChk == true) {
            this.column = this.column + ",BaseFareCol";
          }
          if (this.ProdctTypeChk == true) {
            this.column = this.column + ",ProdctType";
          }
          if (this.ProviderChk == true) {
            this.column = this.column + ",Provider";
          }
          if (this.ItineraryNotesChk == true) {
            this.column = this.column + ",ItineraryNotes";
          }

          if (this.EntitlementAmountChk == true) {
            this.column = this.column + ",EntitlementAmount";
          }
          if (this.RemarksChk == true) {
            this.column = this.column + ",Remarks";
          }

          if (this.TotalChk == true) {
            this.column = this.column + ",TotalFareCol";
          }

          if (this.SalesTaxChk == true) {
            this.column = this.column + ",SalesTaxCol";
          }

          if (this.OriginChk == true) {
            this.column = this.column + ",OriginCol";
          }

          ///
          if (this.CustomerCodeChk == true) {
            this.column = this.column + ",CustCodeCol";
          }
          //


          if (this.PassengerCodeChk == true) {
            this.column = this.column + ",PassCodeCol";
          }
          if (this.InvoiceDateChk == true) {
            this.column = this.column + ",InvDateCol";
          }
          if (this.InvoiceNoChk == true) {
            this.column = this.column + ",InvCol";
          }

          if (this.ArrivalCityChk == true) {
            this.column = this.column + ",ArrivalCityCol";
          }
          if (this.ReturnDateChk == true) {
            this.column = this.column + ",ReturnDateCol";
          }

          if (this.SellAmountHCChk == true) {
            this.column = this.column + ",SellAmtCol";
          }
          if (this.CommAmountHCChk == true) {
            this.column = this.column + ",CommAmtHCCol";
          }

          if (this.QCDATEChk == true) {
            this.column = this.column + ",QCDATE";
          }

          //
          if (this.BuyAmountHCChk == true) {
            this.column = this.column + ",BuyAmtHCCol";
          }

          if (this.NoOfPaxChk == true) {
            this.column = this.column + ",NoOfPax";
          }
          if (this.FolderBookedByChk == true) {
            this.column = this.column + ",fldrBkdbyCol";
          }
          if (this.BookingStatusChk == true) {
            this.column = this.column + ",BookingStatusCol";
          }
          if (this.ClientChk == true) {
            this.column = this.column + ",ClientCountryName";
          }
          if (this.Tkt_TypeChk == true) {
            this.column = this.column + ",Tkt_Type";
          }
          if (this.TicketingDeadlineChk == true) {
            this.column = this.column + ",TicketingDeadline";
          }
          if (this.GDSChk == true) {
            this.column = this.column + ",GDS";
          }
          if (this.ReceiptstatusChk == true) {
            this.column = this.column + ",Receiptstatus";
          }

          if (this.BookingDateChk == true) {
            this.column = this.column + ",QCONDATE";
          }
          if (this.ProductStatusChk == true) {
            this.column = this.column + ",ProductStatus";
          }
          if (this.ConfirmationNoChk == true) {
            this.column = this.column + ",ConfirmationNo";
          }
          if (this.VoucherNoChk == true) {
            this.column = this.column + ",VoucherNo";
          }
          if (this.BRIDChk == true) {
            this.column = this.column + ",BRID";
          }
          if (this.BAIDChk == true) {
            this.column = this.column + ",BAID";
          }
          if (this.BalanceDueAmountChk == true) {
            this.column = this.column + ",BalanceDueAmount";
          }
          if (this.CARDNUMBERChk == true) {
            this.column = this.column + ",CARDNUMBER";
          }
          if (this.CARDValidityChk == true) {
            this.column = this.column + ",CARDValidity";
          }
          if (this.CruiseFromChk == true) {
            this.column = this.column + ",CruiseFrom";
          }
          if (this.CruiseToChk == true) {
            this.column = this.column + ",CruiseTo";
          }
          if (this.DEPDATEChk == true) {
            this.column = this.column + ",DEPDATE";
          }
          if (this.ARRDATEChk == true) {
            this.column = this.column + ",ARRDATE";
          }
          if (this.TaxesChk == true) {
            this.column = this.column + ",TaxesCol";
          }
          this.column = this.column.replace(/,\s*$/, "");
          this.column = this.column.replace(/^,/, "");
        }
        this.FolderActivityService.formDataHeader.RptHdrSel_UserId = this.userID;
        this.FolderActivityService.formDataHeader.RptHdrSel_ModuleName =
          "Folder Activity Detailed Report";
        this.FolderActivityService.formDataHeader.RptHdrSel_ColumnHeader = this.column;
        this.FolderActivityService.formDataHeader.RptHdrSel_TemplateName = this.RptHdrSel_TemplateName;
        this.FolderActivityService.formDataHeader.RptHdrSel_CustCo = 0;
        this.FolderActivityService.formDataHeader.RptHdrSel_AutoNo = 0;
        this.FolderActivityService.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
          new Date()
        );
        this.subscription.add(
          this.FolderActivityService.saveOrUpdateReportHeader().subscribe(data => {
            debugger;
            this.LoadTemplate();
            if (data != undefined && data != null) {
              if (data["ResponseData"]["RptHdrSel_AutoNo"] > 0) {
                this.FolderActivityService.formDataHeader.RptHdrSel_AutoNo =
                  data["ResponseData"]["RptHdrSel_AutoNo"];

                // this.LoadReportColumn();
                this.opentemplate = false;
                this.duplicatetemp = false;
              } else {
                this.duplicatetemp = true;
              }
            }
          })
        );
      }
    }
    else {
      if (this.RptHdrSel_TemplateName == null || this.RptHdrSel_TemplateName == undefined || this.RptHdrSel_TemplateName == "") {
        return
      }
      else {
        if (this.selectAll == true) {
          this.column = "AirlineCodeCol,ArrivalCityCol,ATOLPAX,BAID,BalanceDueAmount,BalDueDateCol,BaseFareCol,QCONDATE,BookingStatusCol,bookingRef,BRNAME,BRID,BANAME,BuyAmtCol,BuyAmtHCCol,DAYS,CARDNUMBER,CARDValidity,CheckinCol,CheckOutCol,CityCol,HotelCityNameCol,CommAmtHCCol,COMMAMT,ConfirmationNo,CostDiscType,CRDATE,CruiseFrom,CruiseTo,FC_CID,CustNameColumn,CustCodeCol,CntryCodeCol,ClientCountryName,ClientCountry,DateinCol,DateOutCol,DepDueDateCol,CITYNAME,ARRDATE,DCM_NAME,FM_DM_UID,DEPDATE,EntitlementAmount,fldrBkdbyCol,PROJNO,GDS,HtlCol,NIGHTS,INETREF,Inputvatcol,InvDateCol,InvCol,ItineraryNotes,DiscVal,LidCol,Pass,BookedBy,LOYALTYPOINTS,MCIdColumn,MemberShipNoCol,NoOfPax,FOLDERNO,OrderTypeCol,OriginCol,PassCodeCol,PmCol,PnrCol,TYPE,ProductStatus,ProdctType,Provider,QCDATE,Receiptstatus,Remarks,ReturnDateCol,SalesTaxCol,SellAmtCol,SELLAMT,Status,SUPPUSERCODE,SUPPNAME,TaxesCol,TktNoCol,Tkt_Type,TicketingDeadline,TOMSVATAMT,TotalFareCol,TotalAmount,Folderdate,Description,VoucherNo,YourRefCol";
        }
        else {
          this.column = "";
          if (this.BANAMEChk == true) {
            this.column = this.column + "BANAME";
          }
          if (this.QCDATEChk == true) {
            this.column = this.column + ",QCDATE";
          }

          if (this.ClientChk == true) {
            this.column = this.column + ",ClientCountryName";
          }

          if (this.LOYALTYPOINTSMEMBNOChk == true) {
            this.column = this.column + ",MemberShipNoCol";
          }


          if (this.TotalAmountChk == true) {
            this.column = this.column + ",TotalAmount";
          }
          if (this.BRNAMEChk == true) {
            this.column = this.column + ",BRNAME";
          }
          if (this.DCM_NAMEChk == true) {
            this.column = this.column + ",DCM_NAME";
          }

          if (this.PROJNOChk == true) {
            this.column = this.column + ",PROJNO";
          }
          if (this.FOLDERNOChk == true) {
            this.column = this.column + ",FOLDERNO";
          }
          if (this.TYPEChk == true) {
            this.column = this.column + ",TYPE";
          }
          if (this.SUPPUSERCODEChk == true) {
            this.column = this.column + ",SUPPUSERCODE";
          }
          if (this.SUPPNAMEChk == true) {
            this.column = this.column + ",SUPPNAME";
          }
          if (this.FOLDERDATEChk == true) {
            this.column = this.column + ",Folderdate";
          }
          if (this.BOOKEDBYChk == true) {
            this.column = this.column + ",BookedBy";
          }
          if (this.CRDATEChk == true) {
            this.column = this.column + ",CRDATE";
          }
          if (this.TicketNoChk == true) {
            this.column = this.column + ",TktNoCol";
          }
          if (this.DescriptionChk == true) {
            this.column = this.column + ",Description";
          }
          if (this.PassChk == true) {
            this.column = this.column + ",Pass";
          }
          if (this.FC_CIDChk == true) {
            this.column = this.column + ",FC_CID";
          }
          if (this.SELLAMTChk == true) {
            this.column = this.column + ",SELLAMT";
          }

          if (this.COMMAMTChk == true) {
            this.column = this.column + ",COMMAMT";
          }
          if (this.BuyAmtChk == true) {
            this.column = this.column + ",BuyAmtCol";
          }
          if (this.TOMSVATAMTChk == true) {
            this.column = this.column + ",TOMSVATAMT";
          }

          if (this.CITYNAMEChk == true) {
            this.column = this.column + ",CITYNAME";
          }
          if (this.DIVISIONChk == true) {
            this.column = this.column + ",FM_DM_UID";
          }
          if (this.LOYALTYPOINTSAMTChk == true) {
            this.column = this.column + ",DiscVal";
          }
          if (this.CostDiscTypeChk == true) {
            this.column = this.column + ",CostDiscType";
          }
          if (this.LOYALTYPOINTSChk == true) {
            this.column = this.column + ",LOYALTYPOINTS";
          }
          if (this.BOOKEDBYChk == true) {
            this.column = this.column + ",LineItemBookedBy";
          }
          if (this.bookingRefChk == true) {
            this.column = this.column + ",bookingRef";
          }
          if (this.StatusChk == true) {
            this.column = this.column + ",Status";
          }

          if (this.DAYSChk == true) {
            this.column = this.column + ",DAYS";
          }
          if (this.NIGHTSChk == true) {
            this.column = this.column + ",NIGHTS";
          }
          if (this.CustLidChk == true) {
            this.column = this.column + ",ClientCountry";////doub
          }
          if (this.CustCountryChk == true) {
            this.column = this.column + ",CntryCodeCol";
          }

          // if (this.CustCountryChk == true) {
          //   this.column = this.column + ",CntryCodeCol";
          // }
          if (this.ATOLPAXChk == true) {
            this.column = this.column + ",ATOLPAX";
          }
          if (this.ReceiptstatusChk == true) {
            this.column = this.column + ",Receiptstatus";
          }
          if (this.INETREFChk == true) {
            this.column = this.column + ",INETREF";
          }
          if (this.UPLOADRCLChk == true) {
            this.column = this.column + ",PnrCol";
          }
          if (this.VairlineAIRIDChk == true) {
            this.column = this.column + ",AirlineCodeCol";
          }

          if (this.PONOChk == true) {
            this.column = this.column + ",YourRefCol";
          }
          if (this.OrderTypeChk == true) {
            this.column = this.column + ",OrderTypeCol";
          }


          if (this.HOTELNAMEChk == true) {
            this.column = this.column + ",HtlCol";
          }
          if (this.BSPPurchaseTaxChk == true) {
            this.column = this.column + ",Inputvatcol";
          }
          if (this.PaymentMethodChk == true) {
            this.column = this.column + ",PmCol";
          }

          if (this.FM_LIDChk == true) {
            this.column = this.column + ",LidCol";
          }
          if (this.NAMEChk == true) {
            this.column = this.column + ",CustNameColumn";
          }

          if (this.MCIDChk == true) {
            this.column = this.column + ",MCIdColumn";
          }
          if (this.DATEINChk == true) {
            this.column = this.column + ",DateinCol";
          }
          if (this.DATEOUTChk == true) {
            this.column = this.column + ",DateOutCol";
          }

          if (this.CHECKINChk == true) {
            this.column = this.column + ",CheckinCol";
          }
          if (this.CHECKOUTChk == true) {
            this.column = this.column + ",CheckOutCol";
          }

          if (this.CITYIDChk == true) {
            this.column = this.column + ",CityCol";
          }

          //need to add
          if (this.CITYChk == true) {
            this.column = this.column + ",HotelCityNameCol";
          }
          if (this.BALDUEDATEChk == true) {
            this.column = this.column + ",BalDueDateCol";
          }

          if (this.DEPDUEDATEChk == true) {
            this.column = this.column + ",DepDueDateCol";
          }
          if (this.BaseFareChk == true) {
            this.column = this.column + ",BaseFareCol";
          }

          if (this.ProdctTypeChk == true) {
            this.column = this.column + ",ProdctType";
          }
          if (this.ProviderChk == true) {
            this.column = this.column + ",Provider";
          }
          if (this.ItineraryNotesChk == true) {
            this.column = this.column + ",ItineraryNotes";
          }

          if (this.EntitlementAmountChk == true) {
            this.column = this.column + ",EntitlementAmount";
          }
          if (this.RemarksChk == true) {
            this.column = this.column + ",Remarks";
          }

          if (this.TotalChk == true) {
            this.column = this.column + ",TotalFareCol";
          }

          if (this.SalesTaxChk == true) {
            this.column = this.column + ",SalesTaxCol";
          }
          if (this.OriginChk == true) {
            this.column = this.column + ",OriginCol";
          }

          if (this.CustomerCodeChk == true) {
            this.column = this.column + ",CustCodeCol";
          }
          if (this.PassengerCodeChk == true) {
            this.column = this.column + ",PassCodeCol";
          }
          if (this.InvoiceDateChk == true) {
            this.column = this.column + ",InvDateCol";
          }
          if (this.InvoiceNoChk == true) {
            this.column = this.column + ",InvCol";
          }

          if (this.ArrivalCityChk == true) {
            this.column = this.column + ",ArrivalCityCol";
          }
          if (this.ReturnDateChk == true) {
            this.column = this.column + ",ReturnDateCol";
          }

          if (this.SellAmountHCChk == true) {
            this.column = this.column + ",SellAmtCol";
          }
          if (this.CommAmountHCChk == true) {
            this.column = this.column + ",CommAmtHCCol";
          }

          if (this.BuyAmountHCChk == true) {
            this.column = this.column + ",BuyAmtHCCol";
          }

          if (this.NoOfPaxChk == true) {
            this.column = this.column + ",NoOfPax";
          }
          if (this.FolderBookedByChk == true) {
            this.column = this.column + ",fldrBkdbyCol";
          }
          if (this.BookingStatusChk == true) {
            this.column = this.column + ",BookingStatusCol";
          }
          if (this.Tkt_TypeChk == true) {
            this.column = this.column + ",Tkt_Type";
          }
          if (this.TicketingDeadlineChk == true) {
            this.column = this.column + ",TicketingDeadline";
          }
          if (this.GDSChk == true) {
            this.column = this.column + ",GDS";
          }

          if (this.QCONDATEChk == true) {
            this.column = this.column + ",QCONDATE";
          }
          if (this.ProductStatusChk == true) {
            this.column = this.column + ",ProductStatus";
          }

          if (this.ConfirmationNoChk == true) {
            this.column = this.column + ",ConfirmationNo";
          }

          if (this.VoucherNoChk == true) {
            this.column = this.column + ",VoucherNo";
          }

          if (this.BRIDChk == true) {
            this.column = this.column + ",BRID";
          }
          if (this.BAIDChk == true) {
            this.column = this.column + ",BAID";
          }
          if (this.BalanceDueAmountChk == true) {
            this.column = this.column + ",BalanceDueAmount";
          }
          if (this.CARDNUMBERChk == true) {
            this.column = this.column + ",CARDNUMBER";
          }
          if (this.CARDValidityChk == true) {
            this.column = this.column + ",CARDValidity";
          }
          if (this.CruiseFromChk == true) {
            this.column = this.column + ",CruiseFrom";
          }
          if (this.BookingDateChk == true) {
            this.column = this.column + ",QCONDATE";
          }
          if (this.CruiseToChk == true) {
            this.column = this.column + ",CruiseTo";
          }
          if (this.DEPDATEChk == true) {
            this.column = this.column + ",DEPDATE";
          }
          if (this.ARRDATEChk == true) {
            this.column = this.column + ",ARRDATE";
          }
          if (this.TaxesChk == true) {
            this.column = this.column + ",TaxesCol";
          }
          if (this.BSPPurchaseTaxChk == true) {
            this.column = this.column + ",Inputvatcol";
          }

          this.column = this.column.replace(/,\s*$/, "");
          this.column = this.column.replace(/^,/, "");
        }
        this.FolderActivityService.formDataHeader.RptHdrSel_UserId = this.userID;
        this.FolderActivityService.formDataHeader.RptHdrSel_ModuleName =
          "Folder Activity Detailed Report";
        this.FolderActivityService.formDataHeader.RptHdrSel_ColumnHeader = this.column;
        this.FolderActivityService.formDataHeader.RptHdrSel_TemplateName = this.RptHdrSel_TemplateName;
        this.FolderActivityService.formDataHeader.RptHdrSel_CustCo = 0;
        this.FolderActivityService.formDataHeader.RptHdrSel_AutoNo = this.RptHdrSel_AutoNo;
        this.FolderActivityService.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
          new Date()
        );
        this.subscription.add(
          this.FolderActivityService.saveOrUpdateReportHeader().subscribe(data => {
            debugger;
            if (data != undefined && data != null) {
              if (data["ResponseData"]["RptHdrSel_AutoNo"] > 0) {
                this.FolderActivityService.formDataHeader.RptHdrSel_AutoNo =
                  data["ResponseData"]["RptHdrSel_AutoNo"];
                this.LoadTemplate();
                // this.LoadReportColumn();
                this.opentemplate = false;
                this.duplicatetemp = false;
              } else {
                this.duplicatetemp = true;
              }
            }
          })
        );
      }
    }
  }

  templateChange(value) {
    debugger; //UsrCode
    if (value != undefined) {
      this.FolderActivityService.formDataHeader.RptHdrSel_AutoNo = value.UsrCode;
      this.tempAutono = value.UsrCode;
      this.FolderActivityDetailedDTO.template = value.Code;
      this.FolderActivityService.templatevalue = value.Code;
      this.FolderActivityService.template = value.Code;
      //     this.FolderActivityService.templatevalue = value.Code;
      this.LoadReportColumn();
    }
  }
  public LoadReportColumn() {
    this.FolderActivityService.formDataHeader.RptHdrSel_UserId = this.userID;
    this.FolderActivityService.formDataHeader.RptHdrSel_ModuleName =
      "Folder Activity Detailed Report";

    this.subscription.add(
      this.FolderActivityService.GetGridColumn().subscribe(data => {
        debugger;
        if (
          data != undefined &&
          data != null &&
          data["ResponseData"] != undefined
        ) {
          this.TemplateSelectedItem = this.TempList.find(
            x => x.UsrCode == data["ResponseData"].RptHdrSel_AutoNo
          );

          if (data["ResponseData"].RptHdrSel_ColumnHeader != null) {
            var datas = data["ResponseData"].RptHdrSel_ColumnHeader.split(",");

            if (datas.includes("BANAME")) {
              this.BANAMEHidden = false;
            } else {
              this.BANAMEHidden = true;
            }
            if (datas.includes("BRNAME")) {
              this.BRNAMEHidden = false;
            } else {
              this.BRNAMEHidden = true;
            }
            if (datas.includes("DCM_NAME")) {
              this.DCM_NAMEHidden = false;
            } else {
              this.DCM_NAMEHidden = true;
            }
            if (datas.includes("FolderNumber")) {
              this.PROJNOHidden = false;
            } else {
              this.PROJNOHidden = true;
            }
            if (datas.includes("COMMAMT")) {
              this.COMMAMTChk = false;
            } else {
              this.COMMAMTChk = true;
            }
            if (datas.includes("TotalAmount")) {
              this.TotalAmountChk = false;
            } else {
              this.TotalAmountChk = true;
            }
            if (datas.includes("OrderNumber")) {
              this.FOLDERNOHidden = false;
            } else {
              this.FOLDERNOHidden = true;
            }
            if (datas.includes("FM_DM_UID")) {
              this.DIVISIONHidden = false;
            } else {
              this.DIVISIONHidden = true;
            }
            if (datas.includes("TYPE")) {
              this.TYPEHidden = false;
            } else {
              this.TYPEHidden = true;
            }
            // DescriptionHidden: any = false;
            if (datas.includes("SUPPUSERCODE")) {
              this.SUPPUSERCODEHidden = false;
            } else {
              this.SUPPUSERCODEHidden = true;
            }
            if (datas.includes("Description")) {
              this.DescriptionHidden = false;
            } else {
              this.DescriptionHidden = true;
            }
            if (datas.includes("SUPPNAME")) {
              this.SUPPNAMEHidden = false;
            } else {
              this.SUPPNAMEHidden = true;
            }
            if (datas.includes("Folderdate")) {
              this.FOLDERDATEHidden = false;
            } else {
              this.FOLDERDATEHidden = true;
            }
            if (datas.includes("QCONDATE")) {
              this.BookingDateHidden = false;
            } else {
              this.BookingDateHidden = true;
            }
            if (datas.includes("CreationDate")) {
              this.CRDATEHidden = false;
            } else {
              this.CRDATEHidden = true;
            }
            if (datas.includes("Pass")) {
              this.PassHidden = false;
            } else {
              this.PassHidden = true;
            }
            if (datas.includes("FC_CID")) {
              this.FC_CIDHidden = false;
            } else {
              this.FC_CIDHidden = true;
            }
            if (datas.includes("SELLAMT")) {
              this.SELLAMTHidden = false;
            } else {
              this.SELLAMTHidden = true;
            }
            if (datas.includes("COMMAMT")) {
              this.COMMAMTHidden = false;
            } else {
              this.COMMAMTHidden = true;
            }
            if (datas.includes("BuyAmtCol")) {
              this.BuyAmtHidden = false;
            } else {
              this.BuyAmtHidden = true;
            }
            if (datas.includes("TOMSVATAMT")) {
              this.TOMSVATAMTHidden = false;
            } else {
              this.TOMSVATAMTHidden = true;
            }

            if (datas.includes("Destination")) {
              this.CITYNAMEHidden = false;
            } else {
              this.CITYNAMEHidden = true;
            }
            if (datas.includes("DiscVal")) {
              this.LOYALTYPOINTSAMTHidden = false;
            } else {
              this.LOYALTYPOINTSAMTHidden = true;
            }
            //
            if (datas.includes("CostDiscType")) {
              this.CostDiscTypeHidden = false;
            } else {
              this.CostDiscTypeHidden = true;
            }
            if (datas.includes("LoyaltyPoints")) {
              this.LOYALTYPOINTSHidden = false;
            } else {
              this.LOYALTYPOINTSHidden = true;
            }
            if (datas.includes("MemberShipNoCol")) {
              this.LOYALTYPOINTSMEMBNOHidden = false;
            } else {
              this.LOYALTYPOINTSMEMBNOHidden = true;
            }
            if (datas.includes("CntryCodeCol")) {
              this.CustCountryHidden = false;
            } else {
              this.CustCountryHidden = true;
            }
            if (datas.includes("ClientCountryName")) {
              this.CountryNameHidden = false;
            } else {
              this.CountryNameHidden = true;
            }
            if (datas.includes("LineItemBookedBy")) {
              this.BOOKEDBYHidden = false;
            } else {
              this.BOOKEDBYHidden = true;
            }
            if (datas.includes("Destination")) {
              this.BOOKEDBYHidden = false;
            } else {
              this.BOOKEDBYHidden = true;
            }
            if (datas.includes("ArrivalCityCol")) {
              this.ArrivalCityHidden = false;
            } else {
              this.ArrivalCityHidden = true;
            }
            if (datas.includes("bookingRef")) {
              this.bookingRefHidden = false;
            } else {
              this.bookingRefHidden = true;
            }
            if (datas.includes("Status")) {
              this.StatusHidden = false;
            } else {
              this.StatusHidden = true;
            }
            if (datas.includes("QCDATE")) {
              this.QCDATEHidden = false;
            } else {
              this.QCDATEHidden = true;
            }
            if (datas.includes("DAYS")) {
              this.DAYSHidden = false;
            } else {
              this.DAYSHidden = true;
            }
            if (datas.includes("HotelNumberOfNights")) {
              this.NIGHTSHidden = false;
            } else {
              this.NIGHTSHidden = true;
            }
            if (datas.includes("CustomerLanguage")) {
              this.CustLidHidden = false;
            } else {
              this.CustLidHidden = true;
            }
            if (datas.includes("CustomerCountryName")) {
              this.CountryNameHidden = false;
            } else {
              this.CountryNameHidden = true;
            }
            if (datas.includes("AtolNo.OfPax")) {
              this.ATOLPAXHidden = false;
            } else {
              this.ATOLPAXHidden = true;
            }
            if (datas.includes("InetRef")) {
              this.INETREFHidden = false;
            } else {
              this.INETREFHidden = true;
            }
            if (datas.includes("PnrCol")) {
              this.UPLOADRCLHidden = false;
            } else {
              this.UPLOADRCLHidden = true;
            }
            if (datas.includes("AirlineCode")) {
              this.VairlineAIRIDHidden = false;
            } else {
              this.VairlineAIRIDHidden = true;
            }
            if (datas.includes("YourRefCol")) {
              this.PONOHidden = false;
            } else {
              this.PONOHidden = true;
            }
            if (datas.includes("OrderType")) {
              this.OrderTypeHidden = false;
            } else {
              this.OrderTypeHidden = true;
            }
            if (datas.includes("HotelName")) {
              this.HOTELNAMEHidden = false;
            } else {
              this.HOTELNAMEHidden = true;
            }
            if (datas.includes("Inputvatcol")) {
              this.BSPPurchaseTaxHidden = false;
            } else {
              this.BSPPurchaseTaxHidden = true;
            }
            if (datas.includes("PmCol")) {
              this.PaymentMethodHidden = false;
            } else {
              this.PaymentMethodHidden = true;
            }
            if (datas.includes("LidCol")) {
              this.FM_LIDHidden = false;
            } else {
              this.FM_LIDHidden = true;
            }
            if (datas.includes("Customer")) {
              this.NAMEHidden = false;
            } else {
              this.NAMEHidden = true;
            }
            if (datas.includes("MarketingCode")) {
              this.MCIDHidden = false;
            } else {
              this.MCIDHidden = true;
            }
            if (datas.includes("DateinCol")) {
              this.DATEINHidden = false;
            } else {
              this.DATEINHidden = true;
            }
            if (datas.includes("DateOut")) {
              this.DATEOUTHidden = false;
            } else {
              this.DATEOUTHidden = true;
            }
            if (datas.includes("CheckinCol")) {
              this.CHECKINHidden = false;
            } else {
              this.CHECKINHidden = true;
            }
            if (datas.includes("CheckOutCol")) {
              this.CHECKOUTHidden = false;
            } else {
              this.CHECKOUTHidden = true;
            }
            if (datas.includes("CityCol")) {
              this.CITYIDHidden = false;
            } else {
              this.CITYIDHidden = true;
            }
            if (datas.includes("HotelCityNameCol")) {
              this.CITYHidden = false;
            } else {
              this.CITYHidden = true;
            }
            if (datas.includes("BalanceDueDate")) {
              this.BALDUEDATEHidden = false;
            } else {
              this.BALDUEDATEHidden = true;
            }
            if (datas.includes("DepositDueDate")) {
              this.DEPDUEDATEHidden = false;
            } else {
              this.DEPDUEDATEHidden = true;
            }
            if (datas.includes("BaseFare")) {
              this.BaseFareHidden = false;
            } else {
              this.BaseFareHidden = true;
            }
            if (datas.includes("ProdctType")) {
              this.ProdctTypeHidden = false;
            } else {
              this.ProdctTypeHidden = true;
            }
            if (datas.includes("Provider")) {
              this.ProviderHidden = false;
            } else {
              this.ProviderHidden = true;
            }
            if (datas.includes("ItineraryNotes")) {
              this.ItineraryNotesHidden = false;
            } else {
              this.ItineraryNotesHidden = true;
            }
            if (datas.includes("EntitlementAmount")) {
              this.EntitlementAmountHidden = false;
            } else {
              this.EntitlementAmountHidden = true;
            }
            if (datas.includes("Remarks")) {
              this.RemarksHidden = false;
            } else {
              this.RemarksHidden = true;
            }
            if (datas.includes("TotalFareCol")) {
              this.TotalHidden = false;
            } else {
              this.TotalHidden = true;
            }
            if (datas.includes("SalesTaxCol")) {
              this.SalesTaxHidden = false;
            } else {
              this.SalesTaxHidden = true;
            }
            if (datas.includes("TotalAmount")) {
              this.TotalAmountHidden = false;
            } else {
              this.TotalAmountHidden = true;
            }
            if (datas.includes("Origin")) {
              this.OriginHidden = false;
            } else {
              this.OriginHidden = true;
            }
            if (datas.includes("CustomerCode")) {
              this.CustomerCodeHidden = false;
            } else {
              this.CustomerCodeHidden = true;
            }
            if (datas.includes("PassengerCode")) {
              this.PassengerCodeHidden = false;
            } else {
              this.PassengerCodeHidden = true;
            }
            if (datas.includes("InvoiceDate")) {
              this.InvoiceDateHidden = false;
            } else {
              this.InvoiceDateHidden = true;
            }
            if (datas.includes("InvoiceNo")) {
              this.InvoiceNoHidden = false;
            } else {
              this.InvoiceNoHidden = true;
            }
            if (datas.includes("ReturnDateCol")) {
              this.ReturnDateHidden = false;
            } else {
              this.ReturnDateHidden = true;
            }
            if (datas.includes("SellAmtCol")) {
              this.SellAmountHCHidden = false;
            } else {
              this.SellAmountHCHidden = true;
            }
            if (datas.includes("CommAmtHCCol")) {
              this.CommAmountHCHidden = false;
            } else {
              this.CommAmountHCHidden = true;
            }
            if (datas.includes("BuyAmtHCCol")) {
              this.BuyAmountHCHidden = false;
            } else {
              this.BuyAmountHCHidden = true;
            }
            if (datas.includes("NoOfPax")) {
              this.NoOfPaxHidden = false;
            } else {
              this.NoOfPaxHidden = true;
            }
            if (datas.includes("FolderBookedBy")) {
              this.FolderBookedByHidden = false;
            } else {
              this.FolderBookedByHidden = true;
            }
            if (datas.includes("BookingStatus")) {
              this.BookingStatusHidden = false;
            } else {
              this.BookingStatusHidden = true;
            }
            if (datas.includes("Tkt_Type")) {
              this.Tkt_TypeHidden = false;
            } else {
              this.Tkt_TypeHidden = true;
            }
            if (datas.includes("TicketingDeadline")) {
              this.TicketingDeadlineHidden = false;
            } else {
              this.TicketingDeadlineHidden = true;
            }
            if (datas.includes("bookingRef")) {
              this.bookingRefHidden = false;
            } else {
              this.bookingRefHidden = true;
            }
            if (datas.includes("GDS")) {
              this.GDSHidden = false;
            } else {
              this.GDSHidden = true;
            }
            if (datas.includes("Receiptstatus")) {
              this.ReceiptstatusHidden = false;
            } else {
              this.ReceiptstatusHidden = true;
            }
            if (datas.includes("QConDate")) {
              this.QCONDATEHidden = false;
            } else {
              this.QCONDATEHidden = true;
            }
            if (datas.includes("ProductStatus")) {
              this.ProductStatusHidden = false;
            } else {
              this.ProductStatusHidden = true;
            }
            if (datas.includes("ConfirmationNo")) {
              this.ConfirmationNoHidden = false;
            } else {
              this.ConfirmationNoHidden = true;
            }
            if (datas.includes("VoucherNo")) {
              this.VoucherNoHidden = false;
            } else {
              this.VoucherNoHidden = true;
            }
            if (datas.includes("BRID")) {
              this.BRIDHidden = false;
            } else {
              this.BRIDHidden = true;
            }
            if (datas.includes("BAID")) {
              this.BAIDHidden = false;
            } else {
              this.BAIDHidden = true;
            }
            if (datas.includes("BalanceDueAmount")) {
              this.BalanceDueAmountHidden = false;
            } else {
              this.BalanceDueAmountHidden = true;
            }
            if (datas.includes("CARDNUMBER")) {
              this.CARDNUMBERHidden = false;
            } else {
              this.CARDNUMBERHidden = true;
            }
            if (datas.includes("CARDValidity")) {
              this.CARDValidityHidden = false;
            } else {
              this.CARDValidityHidden = true;
            }
            if (datas.includes("CruiseFrom")) {
              this.CruiseFromHidden = false;
            } else {
              this.CruiseFromHidden = true;
            }
            if (datas.includes("CruiseTo")) {
              this.CruiseToHidden = false;
            } else {
              this.CruiseToHidden = true;
            }
            if (datas.includes("DEPDATE")) {
              this.DEPDATEHidden = false;
            } else {
              this.DEPDATEHidden = true;
            }
            if (datas.includes("ARRDATE")) {
              this.ARRDATEHidden = false;
            } else {
              this.ARRDATEHidden = true;
            }
            if (datas.includes("TaxesCol")) {
              this.TaxesHidden = false;
            } else {
              this.TaxesHidden = true;
            }
          }
        } else {
          this.enableAllColumn();
        }
      })
    );
  }


  SetDate(date, controlName) {
    debugger;
    switch (controlName) {
      case 'CreateDateFrom':
        this.CreateDateFrom = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'CreateDateTo':
        this.CreateDateTo = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'InvoiceDateFrom':
        this.InvoiceDateFrom = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'InvoiceDateTo':
        this.InvoiceDateTo = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'TravelDateFrom':
        this.TravelDateFrom = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'TravelDateTo':
        this.TravelDateTo = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'BookingDateFrom':
        this.BookingDateFrom = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'BookingDateTo':
        this.BookingDateTo = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'QConDateFrom':
        this.QConDateFrom = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'QConDateTo':
        this.QConDateTo = (date == null || date == "" ? "01-Jan-1900" : date);
        break;

    }

  }
  async GetReport() {
    debugger;
    this.spinner.show();

    if ((this.OrderNo == "" || this.OrderNo == undefined || this.OrderNo == null) && (this.folderNo == "" || this.folderNo == undefined || this.folderNo == null) && (this.CreateDateFrom == undefined || this.CreateDateTo == undefined) && (this.InvoiceDateFrom == undefined || this.InvoiceDateTo == undefined) && (this.TravelDateFrom == undefined ||
      this.TravelDateTo == undefined) && (this.BookingDateFrom == undefined || this.BookingDateTo == undefined) && (this.QConDateTo == undefined || this.QConDateFrom == undefined)) {
      this.common.showWARNINGtoastrmsg(
        "Messages.selectanyDate",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }
    if (this.BArea != null && this.BArea != "" && this.BArea != undefined) {
      if (Boolean(await this.common.BAreaAuthorisation(this.BArea)) == false) {

        this.common.showWARNINGtoastrmsg(
          "Messages.busAreaAuthDenied",
          2000,
          true,
          false
        );

        this.spinner.hide();
        return true;
      }
    }

    if (this.Branch != null && this.Branch != "" && this.Branch != undefined) {
      debugger;
      if (Boolean(await this.common.BranchAuthorisation(this.Branch)) == false) {

        this.common.showWARNINGtoastrmsg(
          "Messages.branchAuthDenied",
          2000,
          true,
          false
        );
        this.spinner.hide();
        return true;
      }
    }

    if ((this.FolderActivityDetailedDTO.ProdStatus == "All") || (this.FolderActivityDetailedDTO.ProdStatus == null)) {
      this.FolderActivityDetailedDTO.ProdStatus = "";
    }
    if ((this.FolderActivityDetailedDTO.ReciptStatus == "All") || (this.FolderActivityDetailedDTO.ReciptStatus == null)) {
      this.FolderActivityDetailedDTO.ReciptStatus = "";
    }
    if ((this.FolderActivityDetailedDTO.Paymentmethod == undefined) || (this.FolderActivityDetailedDTO.Paymentmethod == null)) {
      this.FolderActivityDetailedDTO.Paymentmethod = "All";
    }
    if (new Date(this.CreateDateTo) < new Date(this.CreateDateFrom)) {
      this.common.showWARNINGtoastrmsg(
        "Messages.fromdate_exceeds",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }

    if (new Date(this.BookingDateTo) < new Date(this.BookingDateFrom)) {
      this.common.showWARNINGtoastrmsg(
        "Messages.fromdate_exceeds",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }
    if (new Date(this.QConDateTo) < new Date(this.QConDateFrom)) {
      this.common.showWARNINGtoastrmsg(
        "Messages.fromdate_exceeds",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }
    if (new Date(this.TravelDateTo) < new Date(this.TravelDateFrom)) {
      this.common.showWARNINGtoastrmsg(
        "Messages.fromdate_exceeds",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }
    if (new Date(this.InvoiceDateTo) < new Date(this.InvoiceDateFrom)) {
      this.common.showWARNINGtoastrmsg(
        "Messages.fromdate_exceeds",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }


    var noofdays = 90;
    if (Boolean(this.common.DateRangeDiff(this.InvoiceDateFrom, this.InvoiceDateTo, noofdays)) == false) {
      debugger;
      this.subscription.add(
        this.translateapi
          .get(["Messages.daterangeismorethan90days"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.daterangeismorethan90days"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.DateRangeDiff(this.TravelDateFrom, this.TravelDateTo, noofdays)) == false) {
      debugger;
      this.subscription.add(
        this.translateapi
          .get(["Messages.daterangeismorethan90days"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.daterangeismorethan90days"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.DateRangeDiff(this.QConDateFrom, this.QConDateTo, noofdays)) == false) {
      debugger;
      this.subscription.add(
        this.translateapi
          .get(["Messages.daterangeismorethan90days"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.daterangeismorethan90days"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.DateRangeDiff(this.BookingDateFrom, this.BookingDateTo, noofdays)) == false) {
      debugger;
      this.subscription.add(
        this.translateapi
          .get(["Messages.daterangeismorethan90days"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.daterangeismorethan90days"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }

    if (Boolean(this.common.DateRangeDiff(this.CreateDateFrom, this.CreateDateTo, noofdays)) == false) {
      debugger;
      this.subscription.add(
        this.translateapi
          .get(["Messages.daterangeismorethan90days"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.daterangeismorethan90days"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }



    //alert(JSON.stringify(this.CreateDateFrom))
    // //13376
    this.CreateDateFrom =
      this.CreateDateFrom == undefined || this.CreateDateFrom == null || this.CreateDateFrom == ""
        ? "01-Jan-1900"
        : this.CreateDateFrom;
    this.CreateDateTo =
      this.CreateDateTo == undefined || this.CreateDateTo == null || this.CreateDateTo == ""
        ? "01-Jan-1900"
        : this.CreateDateTo;

    this.InvoiceDateFrom =
      this.InvoiceDateFrom == undefined || this.InvoiceDateFrom == null || this.InvoiceDateFrom == ""
        ? "01-Jan-1900"
        : this.InvoiceDateFrom;
    this.InvoiceDateTo =
      this.InvoiceDateTo == undefined || this.InvoiceDateTo == null || this.InvoiceDateTo == ""
        ? "01-Jan-1900"
        : this.InvoiceDateTo;
    this.TravelDateFrom =
      this.TravelDateFrom == undefined || this.TravelDateFrom == null || this.TravelDateFrom == ""
        ? "01-Jan-1900"
        : this.TravelDateFrom;
    this.TravelDateTo =
      this.TravelDateTo == undefined || this.TravelDateTo == null || this.TravelDateTo == ""
        ? "01-Jan-1900"
        : this.TravelDateTo;
    this.QConDateFrom =
      this.QConDateFrom == undefined || this.QConDateFrom == null || this.QConDateFrom == ""
        ? "01-Jan-1900"
        : this.QConDateFrom;
    this.QConDateTo =
      this.QConDateTo == undefined || this.QConDateTo == null || this.QConDateTo == ""
        ? "01-Jan-1900"
        : this.QConDateTo;
    this.BookingDateFrom =
      this.BookingDateFrom == undefined || this.BookingDateFrom == null || this.BookingDateFrom == ""
        ? "01-Jan-1900"
        : this.BookingDateFrom;
    this.BookingDateTo =
      this.BookingDateTo == undefined || this.BookingDateTo == null || this.BookingDateTo == ""
        ? "01-Jan-1900"
        : this.BookingDateTo;

    //13376
    this.QConDateFrom = this.common.ConvertToLocaleDate(new Date(this.QConDateFrom));
    this.QConDateTo = this.common.ConvertToLocaleDate(new Date(this.QConDateTo));
    this.CreateDateFrom = this.common.ConvertToLocaleDate(new Date(this.CreateDateFrom));
    this.CreateDateTo = this.common.ConvertToLocaleDate(new Date(this.CreateDateTo));
    this.BookingDateFrom = this.common.ConvertToLocaleDate(new Date(this.BookingDateFrom));
    this.BookingDateTo = this.common.ConvertToLocaleDate(new Date(this.BookingDateTo));
    this.TravelDateFrom = this.common.ConvertToLocaleDate(new Date(this.TravelDateFrom));
    this.TravelDateTo = this.common.ConvertToLocaleDate(new Date(this.TravelDateTo));
    this.InvoiceDateTo = this.common.ConvertToLocaleDate(new Date(this.InvoiceDateTo));
    this.InvoiceDateFrom = this.common.ConvertToLocaleDate(new Date(this.InvoiceDateFrom));

    //this.FolderActivityDetailedDTO = new FolderActivityDetailedDTO();
    this.FolderActivityDetailedDTO.TravelFromDate = this.TravelDateFrom
    this.FolderActivityDetailedDTO.TravelToDate = this.TravelDateTo
    this.FolderActivityDetailedDTO.BookingDateFrom = this.BookingDateFrom
    this.FolderActivityDetailedDTO.BookingDateTo = this.BookingDateTo;
    this.FolderActivityDetailedDTO.CreationFromDate = this.CreateDateFrom;
    this.FolderActivityDetailedDTO.CreationToDate = this.CreateDateTo;
    this.FolderActivityDetailedDTO.InvoiceFromDate = this.InvoiceDateFrom;
    this.FolderActivityDetailedDTO.InvoiceToDate = this.InvoiceDateTo;
    this.FolderActivityDetailedDTO.QConDateFrom = this.QConDateFrom;
    this.FolderActivityDetailedDTO.QConDateTo = this.QConDateTo;
    this.FolderActivityDetailedDTO.vFolderNo = this.folderNo;
    this.FolderActivityDetailedDTO.OrderNo = this.OrderNo;
    this.FolderActivityDetailedDTO.ProfitFrom = this.ProfitFrom;
    this.FolderActivityDetailedDTO.ProfitTo = this.ProfitTo;
    this.FolderActivityDetailedDTO.csChecked = this.csChecked;
    this.FolderActivityDetailedDTO.RptHdrSel_UserId = this.userID;
    this.FolderActivityDetailedDTO.template = this.FolderActivityService.template;
    this.FolderActivityDetailedDTO.RptHdrSel_ModuleName =
      "Folder Activity Detailed Report";

    this.FolderActivityDetailedDTO.RptHdrSel_AutoNo = this.RptHdrSel_AutoNo;
    this.FolderActivityService.formData = this.FolderActivityDetailedDTO;
    if ((this.FolderActivityDetailedDTO.template == undefined) || (this.FolderActivityDetailedDTO.template == "") || (this.FolderActivityDetailedDTO.template == null)) {
      this.FolderActivityDetailedDTO.template = "DefaultTemplate"
    }
    this.gridData = [];
    this.FolderActivityService.formData = this.FolderActivityDetailedDTO;
    if (this.FolderActivityDetailedDTO.csChecked == false) {
      this.subscription.add(this.FolderActivityService.GetFolderActivityDetailedReportList(this.FolderActivityDetailedDTO).subscribe(data => {
        this.spinner.show();
        debugger;
        if (data != null && data != undefined) {
          if (data["ResponseData"] != undefined) {
            this.spinner.hide();
            this.gridData = data["ResponseData"];
            console.log(this.gridData)
            if (this.gridData.length > 1) {
              var masterData = [];
              this.gridData.forEach(val => masterData.push(Object.assign({}, val)));
              masterData.forEach(element => {

                if ((element.ARRDATE != null) && (element.ARRDATE != "")) {

                  element.ARRDATE = this.common.ConvertToLocaleDate(element.ARRDATE)
                }
                else {
                  element.ARRDATE = "";
                }
                if ((element.DEPDATE != null) && (element.DEPDATE != "")) {

                  element.DEPDATE = this.common.ConvertToLocaleDate(element.DEPDATE)
                }
                else {
                  element.DEPDATE = "";
                }
                if ((element.QCONDATE != null) && (element.QCONDATE != "")) {

                  element.QCONDATE = this.common.ConvertToLocaleDate(element.QCONDATE)
                }
                else {
                  element.QCONDATE = "";
                }
                if ((element.TicketingDeadline != null) && (element.TicketingDeadline != "")) {

                  element.TicketingDeadline = this.common.ConvertToLocaleDate(element.TicketingDeadline)
                }
                else {
                  element.TicketingDeadline = "";
                }
                if ((element.ReturnDate != null) && (element.ReturnDate != "")) {

                  element.ReturnDate = this.common.ConvertToLocaleDate(element.ReturnDate)
                }
                else {
                  element.ReturnDate = "";
                }
                if ((element.InvoiceDate != null) && (element.InvoiceDate != "")) {

                  element.InvoiceDate = this.common.ConvertToLocaleDate(element.InvoiceDate)
                }
                else {
                  element.InvoiceDate = "";
                }
                if ((element.DEPDUEDATE != null) && (element.DEPDUEDATE != "")) {

                  element.DEPDUEDATE = this.common.ConvertToLocaleDate(element.DEPDUEDATE)
                }
                else {
                  element.DEPDUEDATE = "";
                }
                if ((element.DATEOUT != null) && (element.DATEOUT != "")) {

                  element.DATEOUT = this.common.ConvertToLocaleDate(element.DATEOUT)
                }
                else {
                  element.DATEOUT = "";
                }
                if ((element.DATEIN != null) && (element.DATEIN != "")) {

                  element.DATEIN = this.common.ConvertToLocaleDate(element.DATEIN)
                }
                else {
                  element.DATEIN = "";
                }
                if ((element.CRDATE != null) && (element.CRDATE != "")) {

                  element.CRDATE = this.common.ConvertToLocaleDate(element.CRDATE)
                }
                else {
                  element.CRDATE = "";
                }
                if ((element.BookingDate != null) && (element.BookingDate != "")) {

                  element.BookingDate = this.common.ConvertToLocaleDate(element.BookingDate)
                }
                else {
                  element.BookingDate = "";
                }
                if ((element.FOLDERDATE != null) && (element.FOLDERDATE != "")) {

                  element.FOLDERDATE = this.common.ConvertToLocaleDate(element.FOLDERDATE)
                }
                else {
                  element.FOLDERDATE = "";
                }
                if ((element.BALDUEDATE != null) && (element.BALDUEDATE != "")) {

                  element.BALDUEDATE = this.common.ConvertToLocaleDate(element.BALDUEDATE)
                }
                else {
                  element.BALDUEDATE = "";
                }
              })
              this.common.masterData = masterData;
              this.FolderActivityService.griddata = this.gridData;
              this.router.navigate(["Reports/FolderactivitityReportgridDetailed"], { skipLocationChange: true });
              this.spinner.hide();
              this.moduleUsageService.ModuleUsageDTO.reportname = "PWR_FolderActivityDetailedReport"
              this.moduleUsageService.ModuleUsageDTO.ReportCriteria = JSON.stringify(this.FolderActivityDetailedDTO);
              this.moduleUsageService.getModuleUsageReport().subscribe(res => {
                debugger;
              })

            }
            else {
              this.common.showWARNINGtoastrmsg(
                "Messages.no_data_found",
                2000,
                true,
                false
              );
              this.spinner.hide();
            }
          }
          else {
            this.spinner.hide();
          }
        }

      })
      )
    }

  }

  public CustomerValueChange(value: any): void {
    debugger;
    if (value != null && value != undefined && value != "") {
      if (this.CustomerList != null && this.CustomerList.length > 0) {
        this.customerSelectedItem = this.CustomerList.find(item => item.Name == value);
      }
      if (
        this.customerSelectedItem != null &&
        this.customerSelectedItem != undefined
      ) {
        this.customerSelectedCode = this.customerSelectedItem.Code;
        this.customerSelectedUsrCode = this.customerSelectedItem.UsrCode;
        this.FolderActivityService.custUsrCode = this.customerSelectedItem.Name;
        this.customerSelected = this.customerSelectedItem.Name;
        this.FolderActivityService.custname = this.customerSelectedItem.ActualName;
        this.customerSelected = this.customerSelectedItem.Name;
        this.Customer = this.customerSelectedItem.ActualName;
        this.custusrcode = this.customerSelectedItem.Name;
        this.FolderActivityDetailedDTO.CustCode_int = this.customerSelectedItem.Code;
      } else {
        this.customerSelected = "";
        this.Customer = "";
        this.autocompletecustomer.value = "";
        this.autocompletecustomer.text = null;
        this.FolderActivityDetailedDTO.CustCode_int = "";

      }
    } else {
      this.customerSelected = "";
      this.customerSelectedItem = "";
      this.Customer = "";
      this.FolderActivityDetailedDTO.CustCode_int = "";
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        if (this.lookuptype == 'SUPPLIERS') {
          this.supplierSelectedItem = value;
          this.SupplierList = [];
          this.SupplierValueChange(value.ActualName)
        }
        else if (this.lookuptype == 'CUSTOMER') {
          debugger;
          this.customerSelectedItem = value;
          this.CustomerList = [];
          this.CustomerValueChange(value.Name)
        }

      },
      closemodalpopup: () => {
        this.openSearch = false;
      }
    }
  }

  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'CUSTOMER') {
      this.lookupname = 'CUSTOMER';
    }
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }
  openBox1(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
  }

  public SupplierValueChange(value: any): void {
    debugger;
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);
    }
    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name;
      this.SupplierName = this.supplierSelectedItem.ActualName;
      this.FolderActivityService.supliername = this.supplierSelectedItem.ActualName;
      this.suppCode = this.supplierSelectedItem.Code;
      this.FolderActivityService.supusrcode = this.supplierSelectedItem.Name;
      this.FolderActivityDetailedDTO.PrtyCode = this.supplierSelectedItem.Code;
      this.SupplierSelectedUsrCode = (this.supplierSelectedItem.UsrCode);
    }
    else {

      this.SupplierName = "";
      this.Supplier = 0;
      this.FolderActivityDetailedDTO.PrtyCode = "";
    }
  }

  public UserValueChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.UserCode = value.UsrCode;
      this.FolderActivityDetailedDTO.FolderBookedBy = value.Name;
      this.UName = value.Name;
    }
    else {
      this.UserCode = "";
      this.UName = "";
    }

  }

  onClear() {
    debugger;
    this.action = "ClearAll";
    this.ordertypeSelecItem = "(ALL)";
    this.PmSelectedItem = this.paymentList.find(x => x.text = "All")
    this.ReceiptStatusSelectedItem = this.receiptstauslist.find(x => x.text = "All")
    this.productStatusSelectedItem = this.productstauslist.find(x => x.text = "All")

    this.salesOrganizationValue = "";
    this.supplierSelected = "";
    this.CCGroupSelectedItem = "";
    this.BusinessAreaSelectedItem = "";
    this.customerGroupSelectedItem = "";
    this.CreateDateFrom = null;
    this.CreateDateTo = null;
    this.InvoiceDateFrom = null;
    this.InvoiceDateTo = null;
    this.TravelDateFrom = null;
    this.TravelDateTo = null;
    this.BookingDateFrom = null;
    this.BookingDateTo = null;
    this.QConDateTo = null;
    this.QConDateFrom = null;
    this.ProfitFrom = "";
    this.destinationSelected = "";
    this.ProfitTo = "";
    this.customerSelected = "";
    this.UserSelectedItem = "";
    this.productSelectedItem = "";
    this.branchSelectedItem = "";
    this.BusinessAreaSelectedItem = "";
    this.BusinessAreaSelectedItem = "";
    this.DivisionSelectedItem = "";
    this.OrderNo = "";
    this.lineItemsSelectedItem = "";
    this.DestinationSelectedItem = "";
    this.FolderActivityDetailedDTO.Paymentmethod = "All";
    this.FolderActivityDetailedDTO.ReciptStatus = "All";
    this.FolderActivityDetailedDTO.ProdStatus = "All";
    this.FolderActivityDetailedDTO.pOrderType_varchar = "(ALL)";
    this.FolderActivityService.ordertypename = "(ALL)";
    this.folderNo = "";
    this.DistributionSelectedItem = "";
    this.cd.detectChanges();
    this.action = "";
    this.FolderActivityDetailedDTO = new FolderActivityDetailedDTO();
  }
  emailclose(close) {

  }
  close() {

  }
  EmailFormClosingatol(event) {

  }
  handleFilterToDestination(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          this.destinationToList = data["ResponseData"];
        }
      ));
    } else {
      this.autocompleteTo.toggle(false);
    }
  }
  handleFilterFromDestination(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          this.destinationList = data["ResponseData"];
        }
      ));

    } else {
      this.autocompletefrom.toggle(false);
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
