export class WeTuTransferDTO {
    public BookingRef:any;
    public OrderNo:any;
    public UserId:any;
    public Provider:any;
}

export class WETUDTO{
        public WETU_AutoNo:any;
        public WETU_ApiKey :any;
        public WETU_Url :any;
        public WETU_CrDate :any;
        public WETU_CrTime :any;
        public WETU_CrUID :any;
        public WETU_LupDate :any;
        public WETU_LupTime :any;
        public WETU_LupDateUID :any;
        public WETU_EmailId :any;
        public WETU_Uname :any;
        public WETU_BookingListURL :any;
    }
