
export class CustomerReceiptRequest {
        Id:number;
        UserId :number;
        FolderNo :string;
        YearId:string;    
        CurrencyId :string;  
        PartyCode:number;
	ShowSavedFolders:number;
	GLSelected:number;
        IncludeReceipt:number;
        DateFrom:Date;
        DateTo:Date;
        HoldInvNo:string;
 }