import { Component, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { FolderSearchCriteriaDTO, OthersSearchDTO } from '../../Models/folder-search-criteria-dto';
import { SearhdtotransferService } from '../../Services/searhdtotransfer.service';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
@Component({
  selector: 'app-searchby-others',
  templateUrl: './searchby-others.component.html',
  styleUrls: ['./searchby-others.component.scss']
})
export class SearchbyOthersComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public CheckOutDateTo: "";

  opened = false;
  openSearch = false;
  constructor(public common: CommonserviceService, private searhTransferDTO: SearhdtotransferService, private lookupApi: LookUpDetailsService) { }
  //othersSearchForm: FormGroup;
  public submitted: boolean = false;
  SupplierList: Array<LookUpDetails['ResponseData']> = [];
  public statusList: Array<string> = ["Wait listed", "Confirmed", "Cancelled"];
  public supplierSelectedItem: LookUpDetails['ResponseData'];
  public supplierSelected: String;
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  public otherSearchForm: FormGroup;
  flsrchDto: FolderSearchCriteriaDTO;
  OthersSearchDTO: OthersSearchDTO;
  public Status: any;
  ProductList: Array<LookUpDetails["ResponseData"]> = [];
  public ProductSelectedItem: LookUpDetails["ResponseData"];
  ngOnChanges(changes: SimpleChanges) {

    this.OthersSearchDTO = this.flsrchDto.OthersSearchDTO;
  }
  menuIndex = 8;
  ngOnInit() {

    this.subscription.add(this.searhTransferDTO.SrchTranferDTO.subscribe(flsrchDto => {
      this.flsrchDto = flsrchDto;

    }))
    if (this.flsrchDto.OthersSearchDTO != null)
      this.OthersSearchDTO = this.flsrchDto.OthersSearchDTO;
    else
      this.OthersSearchDTO = new OthersSearchDTO();
   
    // this.loadSupplier();
    this.loadProduct();
    if(this.OthersSearchDTO.Supplier!="" && this.OthersSearchDTO.Supplier !=undefined){
     this.loadSupplierValue(this.OthersSearchDTO.Supplier);
    }
  }


  loadSupplierValue(value:any) {  //14068   v1.0 
    debugger; 

      this.subscription.add(this.lookupApi.findSupplier(value).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.SupplierList = data["ResponseData"];
            if (value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
              }

            }
          }

        }
      ));
    
  }
  get f() { return this.otherSearchForm.controls; }
  public loadProduct() {
    this.subscription.add(this.lookupApi.GetAllLookup('PRODUCTS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.ProductList = data["ResponseData"]
          //  if(this.OthersEditForm.controls.CODE.value!="")
          //  {
          //  this.ProductSelectedItem =this.ProductList.find(item => item.Code ==this.OthersEditForm.controls.CODE.value);        
          // }

          if (this.OthersSearchDTO.Product != '' && this.OthersSearchDTO.Product != null) {

            this.ProductSelectedItem = this.ProductList.find(item => item.Code == this.OthersSearchDTO.Product);


          }
        }
        if (this.OthersSearchDTO.Status != '' && this.OthersSearchDTO.Status != null) {

          this.Status = this.OthersSearchDTO.Status;


        }
      }));
  }


  public close(status) {

    this.opened = false;
    this.openSearch = false;
  }
  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860


  public StatusChange(value: any): void {

    if (value != null && value != undefined) {
      this.OthersSearchDTO.Status = value;

    } else {
      this.OthersSearchDTO.Status = "";
    }
    this.callChange();
  }
  public ProductChange(value: any): void {

    if (value != null && value != undefined) {
      this.OthersSearchDTO.Product = value.Code;

    } else {
      this.OthersSearchDTO.Product = "";
    }
    this.callChange();
    //if(value!=null && value!=undefined)
    // this.OthersEditForm.controls.BOOKEDBY.setValue(value.Name);
    // else
    // this.OthersEditForm.controls.BOOKEDBY.setValue("");

  }



  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  handleFiltersupplier(value) {

    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.GetLookupById("SUPPLIERS", value, value).subscribe(
        // this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  public SupplierValueChange(value: any): void { debugger;
    //  alert(value);
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);

    }
    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860

      this.OthersSearchDTO.Supplier = (this.supplierSelectedItem.Code);


    } else {
      this.supplierSelected = '';
      this.OthersSearchDTO.Supplier = ('');


    }
    this.callChange();

  }


  callChange() {

    //(OthersDetail.BookingDate).toLocaleDateString();
    if (this.OthersSearchDTO.Product || this.OthersSearchDTO.Supplier || this.OthersSearchDTO.BookingDateFrom || this.OthersSearchDTO.BookingDateTo
      || this.OthersSearchDTO.Status || this.OthersSearchDTO.ConfirmationNo || this.OthersSearchDTO.BookingDateFromDt.toString() != '' || this.OthersSearchDTO.BookingDateFromDt.toString() !== undefined || this.OthersSearchDTO.BookingDateToDt.toString() != '' || this.OthersSearchDTO.BookingDateToDt.toString() !== undefined) {
      if (this.OthersSearchDTO.BookingDateFromDt !== undefined) {
        if (this.OthersSearchDTO.BookingDateFromDt !== null && this.OthersSearchDTO.BookingDateFromDt.toString() !== '') {
          this.OthersSearchDTO.BookingDateFrom = this.common.transformDate(new Date(this.OthersSearchDTO.BookingDateFromDt));

          //  this.OthersSearchDTO.BookingDateFrom= new Date(this.OthersSearchDTO.BookingDateFromDt).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
        } else {
          this.OthersSearchDTO.BookingDateFrom = null;

        }
      }
      if (this.OthersSearchDTO.BookingDateToDt !== undefined) {
        if (this.OthersSearchDTO.BookingDateToDt !== null && this.OthersSearchDTO.BookingDateToDt.toString() !== '') {
          this.OthersSearchDTO.BookingDateTo = this.common.transformDate(new Date(this.OthersSearchDTO.BookingDateToDt));
          // this.OthersSearchDTO.BookingDateTo= new Date(this.OthersSearchDTO.BookingDateToDt).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
        } else {
          this.OthersSearchDTO.BookingDateTo = null;
        }
      }
      if (this.OthersSearchDTO.BookingDateFromDt == null && this.OthersSearchDTO.BookingDateToDt != null) {
        this.OthersSearchDTO.BookingDateFrom = this.OthersSearchDTO.BookingDateTo;
      }


      this.flsrchDto.OthersSearchDTO = this.OthersSearchDTO;
    }
    else {
      this.flsrchDto.OthersSearchDTO = null;
    }

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
