export class CRMSearchCriteriaDTO {
  CRMSearchDTO: CRMSearchDTO;
  PageNo: string;
  PageSize: string;
  SearcType: string;
  ExportPDFParam: any;
}
export class CRMSearchDTO {
  //13427
  // DatefromAF = new Date();
  // DateToAF = new Date();
  //13427

  DatefromAF : Date;
  DateToAF :Date;
  DepDatefrom: Date;
  DepDateTo: Date;
  TravelDatefromAF: Date;
  TravelDateToAF: Date;
  TravelDatefrom: Date;
  TravelDateTo: Date;
  QueryNo: string;
  Destination: string;
  BAID: any;
  BRID: any;
  Source: any;
  EmailID: any;
  Name: string;
  Status: any;
  MobileNo: any;
  LastUpdateFromDate: Date;
  LastUpdateToDate: Date;
  LastUpdateFromDateAF: Date;
  LastUpdateToDateAF: Date;
  Type: any;
  Owner: any;
  Ordertype: any;
  DealId:any;
  Origin:any;
  OriginSelected:any;
  ContactMethod:any;
  referredBy:any;
  requestType:any;
}
