import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginService } from '../Services/login.service';
import { Usercredetails } from '../Models/usercredetails';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: LoginService,
        private toastr: ToastrService,
        private translateapi: TranslateService
    ) { }
    isAuthenticated: boolean;
    public userCredentails: Usercredetails;
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const currentUser = this.authenticationService.currentUserValue;
        const helper = new JwtHelperService();
        var token_id = currentUser.ResponseData.Token;
        // const decodedToken = helper.decodeToken(token_id);
        //  const expirationDate = helper.getTokenExpirationDate(token_id);
        const isExpired = helper.isTokenExpired(token_id);
        ///// this.isAuthenticated = helper.isTokenExpired(token_id);
        if (isExpired == false) {
            if (!currentUser.ResponseData.MultiUserAllowed) {
                if (!sessionStorage.getItem('NewLogin')) {
                    return this.authenticationService
                        .CheckSingleUserLogin({ UserId: currentUser.ResponseData.UID, JWTToken: currentUser.ResponseData.Token })
                        .pipe(map(e => {
                            if (e) {
                                return true;
                            } else {
                                this.authenticationService.GetLogout();
                                this.translateapi.get(["Messages.singleUserLogoutmsg"])
                                    .subscribe((translations) => {
                                        var showMsg = translations["Messages.singleUserLogoutmsg"];
                                        this.toastr.error(showMsg, "", {
                                            timeOut: 3000,
                                            disableTimeOut: true,
                                            closeButton: true,
                                        });
                                    });
                                this.router.navigate(['/Login']);
                            }
                        }),
                            catchError((err) => {
                                this.authenticationService.GetLogout();
                                this.router.navigate(['/Login']);
                                return of(false);
                            })
                        );
                } else {
                    return of(true);
                }
            }
            else {
                return of(true);
            }
        }
        else {
            // this.userCredentails.userName = currentUser.ResponseData.UNAME.toString();
            // this.userCredentails.passWord = currentUser.ResponseData.UPWD.toString();
            // this.authenticationService.GetLogin(this.userCredentails).subscribe(data => { });
        }
    }

}
