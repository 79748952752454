import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ShareDataService } from "../../Services/share-data.service";
import { LookUpDetailsService } from "../../Services/look-up-details.service";
import { LookUpDetails } from "../../Services/look-up-details";
import { CurrencyExchangerateService } from "../../Services/currency-exchangerate.service";
import { CurrencyExchangerate } from "../../Models/currency-exchangerate";
import { Subscription } from "rxjs";
import { AirticketDetailsEditComponent } from "../airticket-details-edit/airticket-details-edit.component";
import { CommonserviceService } from "../../Services/commonservice.service";
import { TranslateService } from "@ngx-translate/core";
import { AirticketService } from "../../Services/airticket.service";

@Component({
  selector: "app-airticket-bsp-edit",
  templateUrl: "./airticket-bsp-edit.component.html",
  styleUrls: ["./airticket-bsp-edit.component.scss"],

})
export class AirticketBspEditComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  IsSametax: boolean = true;
  showMsg: any = "";
  @Input() BSPTAXEnableVal:number;
  FareValue: string = "Fare";
  Tax1: string = "Tax1";
  Tax2: string = "Tax2";
  Tax3: string = "Tax3";
  Tax4: string = "Tax4";
  Tax5: string = "Tax5";
  currencyExrate: CurrencyExchangerate;
  CuexRate: number;
  lockStatus: any;
  folderStatus: any;
  formeditable: any;
  currencyList: Array<LookUpDetails["ResponseData"]> = [];
  public currencySelectedItem: LookUpDetails["ResponseData"];
  public currencySelectedItemTax1: LookUpDetails["ResponseData"];
  public currencySelectedItemTax2: LookUpDetails["ResponseData"];
  public currencySelectedItemTax3: LookUpDetails["ResponseData"];
  public currencySelectedItemTax4: LookUpDetails["ResponseData"];
  public FarecurrencySelectedItem: LookUpDetails["ResponseData"];
  public currencySelected: String;
  @Input() parentForm: FormGroup;

  @Input() common: "Sales";
  @Input() public pocreated;
  public hcExRate: any;
  public hcCurr: any;
  constructor(

    public commons: CommonserviceService,
    private apiShared: ShareDataService,
    private lookupApi: LookUpDetailsService,
    private apiExRate: CurrencyExchangerateService,
    private airticket: AirticketDetailsEditComponent,
    private translateapi: TranslateService,
    public api: AirticketService,
  ) { }

  ngOnInit() {
    //case id : 13777 
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    } else {
      this.formeditable = false;

    }
    // case id : 13777
    // alert(this.pocreated)
    debugger;
    this.disableEnabebuyell();
    this.getFolderValues();
    this.subscription.add(
      this.translateapi
        .get(["Messages.select_currency"])
        .subscribe((translations) => {
          this.showMsg = translations["Messages.select_currency"];

        })
    );
    if (this.parentForm.get("BSP").value == "1") {
      if (
        this.parentForm.controls.CURR1.value == this.parentForm.controls.FC_Tax1_CID.value
      ) {
        this.api.IssameFareAndTaxCurrency = true;

      }
      else {
        this.api.IssameFareAndTaxCurrency = false;
      }


    }
    this.subscription.add(
      this.lookupApi.GetAllLookup("CURRENCY").subscribe(data => {
        // console.log(JSON.stringify(data, null, 4));

        // console.log(JSON.stringify(data["ResponseData"], null, 4));
        if (data != null && data != undefined) {
          this.currencyList = data["ResponseData"];
          //  alert("Currency="+this.carEditForm.controls.CURCODE.value);

          if (this.parentForm.get("CURR1").value != this.parentForm.get("FC_Tax1_CID").value) {
            this.IsSametax = false;
          }
          else {
            this.IsSametax = true;
          }

          if (this.parentForm.get("FC_Tax1_CID").value != "") {
            this.currencySelectedItem = this.currencyList.find(
              item => item.Code == this.parentForm.get("FC_Tax1_CID").value
            );
          }
          if (this.parentForm.get("FC_Tax2_CID").value != "") {
            this.currencySelectedItemTax1 = this.currencyList.find(
              item => item.Code == this.parentForm.get("FC_Tax2_CID").value
            );
            this.getExchangeRate(
              "FC_Tax2_CID",
              "TICKDATE",
              "FC_Tax2_CIDExRate",0
            );
          }
          if (this.parentForm.get("FC_Tax3_CID").value != "") {
            this.currencySelectedItemTax2 = this.currencyList.find(
              item => item.Code == this.parentForm.get("FC_Tax3_CID").value
            );
            this.getExchangeRate(
              "FC_Tax3_CID",
              "TICKDATE",
              "FC_Tax3_CIDExRate",0
            );
          }
          if (this.parentForm.get("FC_Tax4_CID").value != "") {
            this.currencySelectedItemTax3 = this.currencyList.find(
              item => item.Code == this.parentForm.get("FC_Tax4_CID").value
            );
            this.getExchangeRate(
              "FC_Tax4_CID",
              "TICKDATE",
              "FC_Tax4_CIDExRate",0
            );
          }
          if (this.parentForm.get("FC_Tax5_CID").value != "") {
            this.currencySelectedItemTax4 = this.currencyList.find(
              item => item.Code == this.parentForm.get("FC_Tax5_CID").value
            );
            this.getExchangeRate(
              "FC_Tax5_CID",
              "TICKDATE",
              "FC_Tax5_CIDExRate",0
            );
          }
          if (this.parentForm.get("CURR1").value != "") {
            this.FarecurrencySelectedItem = this.currencyList.find(
              item => item.Code == this.parentForm.get("CURR1").value
            );
            this.getExchangeRate(
              "CURR1",
              "TICKDATE",
              "EXCHANGE1",0
            );
          }

        }
      })
    );
    // if(this.BSPTAXEnableVal==1)
    // {
    //   this.Calculateform();
    // }
 
  }
  public getCurrencyExRate(
    objcurrency: string,
    objbookingDate: string,
    ObjCommPer: string
  ) {
    debugger;
    if (
      this.parentForm.controls.CURR1.value == this.parentForm.controls.FC_Tax1_CID.value
    ) {
      this.api.IssameFareAndTaxCurrency = true;

    }
    else {
      this.api.IssameFareAndTaxCurrency = false;
    }
    this.getExchangeRate(objcurrency, objbookingDate, ObjCommPer,1);
  }
  private currency: any;
  private bookingDate: any;

  getExchangeRate(
    objcurrency: string,
    objbookingDate: string,
    ObjExRte: string,initialvalu:number
  ) {
    this.currency = this.parentForm.get(objcurrency).value;
    this.bookingDate = this.parentForm.get(objbookingDate).value;
    if (this.bookingDate == "" || this.bookingDate == null || this.bookingDate == undefined) {
      this.bookingDate = new Date;
    }
    if (this.bookingDate != "" && this.bookingDate != null && this.bookingDate != undefined) {
      this.bookingDate = this.commons.ConvertToLocaleDate(this.bookingDate);
      this.bookingDate = this.bookingDate.replace("Sept", "Sep");
    }
    if (this.currency != undefined && this.currency != "") {
      this.airticket.savebutton = false;
      this.subscription.add(
        this.apiExRate
          .getExchangeRate(this.currency, this.bookingDate)
          .subscribe(data => {
            if (data != null && data != undefined) {
              this.currencyExrate = data;
              if (this.currencyExrate.ResponseData.EXRATE == null) {
                this.currencyExrate.ResponseData.EXRATE = 0;
              }
              if (
                this.currencyExrate.ResponseData.EXRATE !== null &&
                this.currencyExrate.ResponseData.EXRATE !== undefined
              ) {
                this.CuexRate = this.currencyExrate.ResponseData.EXRATE;
                this.parentForm
                  .get(ObjExRte)
                  .setValue(parseFloat(this.CuexRate.toFixed(6)));
              }
            }

            if (this.parentForm.controls.CURR1.value == this.parentForm.controls.FC_Tax1_CID.value) {
              this.parentForm.controls.EXCHANGE.setValue(this.CuexRate.toFixed(6));
            }
            this.airticket.savebutton = true;

            this.CalculateHomeCurrency();
            this.calculatetotal(initialvalu);
          })
      );
    }
    else {
      var exhrte = 0;
      this.parentForm
        .get(ObjExRte)
        .setValue(parseFloat(exhrte.toFixed(6)));

      this.CalculateHomeCurrency();
      this.calculatetotal(initialvalu);
    }
  }

  ngOnChanges() {
    this.disableEnabebuyell();
  }
  public selldisable: boolean = true;
  public buydisable: boolean = true;
  public disablecolumn: boolean = true;
  disableEnabebuyell() {
    this.buydisable = false;
    this.selldisable = false;

    if (this.common == "Sales" || this.common == "undefined") {
      this.selldisable = false;
      this.buydisable = true;
    } else {
      this.selldisable = true;
      this.buydisable = false;
    }

    if (this.pocreated == false) {
      this.selldisable = true;
      this.buydisable = true;
    }
  }
  folderExrate: any;
  fldrCurrency: any;
  public exRate: any;

  getFolderValues() {
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    this.exRate = new Number(this.folderExrate);
    this.hcExRate = this.apiShared.hcExRate;
    this.hcCurr = this.apiShared.homeCurrency;
  }
  // private show: boolean = false;

  // public onToggle(): void {
  //     this.show = !this.show;

  // }
  public opened = false;

  public close(status) {
    //console.log(`Dialog result: ${status}`);
    this.opened = false;
  }
  public onPanelChange(event: any): void {
    console.log("stateChange: ", event);
  }
  private pCommamt: number;
  private sum: number;
  public CalculateAmount(
    objBuy: string,
    ObjCommPer: string,
    ObjComm: string,
    objSell: string
  ) {
    debugger;

    if (this.parentForm.get("CommOn").value == "Sales") {
      if (this.parentForm.get(ObjCommPer).value == null) {
        this.parentForm.get(ObjCommPer).setValue(0);
      }

      if (this.parentForm.get(ObjComm).value == 0)
        if (this.parentForm.get(ObjCommPer).value != 0)
          this.parentForm
            .get(ObjComm)
            .setValue(
              (this.parentForm.get(objSell).value *
                this.parentForm.get(ObjCommPer).value) /
              100
            );
      this.parentForm
        .get(objBuy)
        .setValue(
          this.parentForm.get(objSell).value -
          this.parentForm.get(ObjComm).value
        );
    } else {
      if (this.parentForm.get(ObjCommPer).value != 0)
        this.parentForm
          .get(ObjComm)
          .setValue(
            (this.parentForm.get(objBuy).value *
              this.parentForm.get(ObjCommPer).value) /
            100
          );
      this.parentForm
        .get(objSell)
        .setValue(
          this.parentForm.get(objBuy).value + this.parentForm.get(ObjComm).value
        );
    }


    this.CalculateHomeCurrency();
    this.calculatetotal(1);
  }
  CalculateHomeCurrency() {
    debugger;
    this.parentForm.controls.COMMP1.setValue(
      this.parentForm.get("FC_Tax1_CommPer").value
    );
    this.parentForm.controls.COMMA1.setValue(
      this.parentForm.get("FC_Fare_CommAmt").value *
      (this.parentForm.get("EXCHANGE1").value
      )
    );
    this.parentForm.controls.SELL1.setValue(
      parseFloat(
        (
          this.parentForm.get("FC_Fare_Sell").value *
          this.parentForm.get("EXCHANGE1").value
        ).toFixed(2)
      )
    );

    this.parentForm.controls.BUY1.setValue(
      parseFloat(
        (
          this.parentForm.get("SELL1").value -
          this.parentForm.get("COMMA1").value
        ).toFixed(2)
      )
    );
    this.parentForm.controls.COMMP2.setValue(
      this.parentForm.get("FC_Tax2_CommPer").value

    );
    this.parentForm.controls.COMMA2.setValue(
      this.parentForm.get("FC_Tax1_CommAmt").value *
      (this.parentForm.get("FC_Tax1_CIDExRate").value
      )
    );
    this.parentForm.controls.SELL2.setValue(
      parseFloat(
        (
          this.parentForm.get("FC_Tax1_Sell").value *
          this.parentForm.get("FC_Tax1_CIDExRate").value
        ).toFixed(2)
      )
    );

    this.parentForm.controls.BUY2.setValue(
      parseFloat(
        (
          this.parentForm.get("SELL2").value -
          this.parentForm.get("COMMA2").value
        ).toFixed(2)
      )
    );
    this.parentForm.controls.COMMP3.setValue(
      this.parentForm.get("FC_Tax3_CommPer").value
    );
    this.parentForm.controls.COMMA3.setValue(
      this.parentForm.get("FC_Tax2_CommAmt").value *
      (this.parentForm.get("FC_Tax1_CIDExRate").value
      )
    );
    this.parentForm.controls.SELL3.setValue(
      parseFloat(
        (
          this.parentForm.get("FC_Tax2_Sell").value *
          this.parentForm.get("FC_Tax2_CIDExRate").value
        ).toFixed(2)
      )
    );

    this.parentForm.controls.BUY3.setValue(
      parseFloat(
        (
          this.parentForm.get("SELL3").value -
          this.parentForm.get("COMMA3").value
        ).toFixed(2)
      )
    );
    this.parentForm.controls.COMMP4.setValue(
      this.parentForm.get("FC_Tax4_CommPer").value
    );
    this.parentForm.controls.COMMA4.setValue(
      this.parentForm.get("FC_Tax3_CommAmt").value *
      (this.parentForm.get("FC_Tax1_CIDExRate").value
      )
    );
    this.parentForm.controls.SELL4.setValue(
      parseFloat(
        (
          this.parentForm.get("FC_Tax3_Sell").value *
          this.parentForm.get("FC_Tax3_CIDExRate").value
        ).toFixed(2)
      )
    );

    this.parentForm.controls.BUY4.setValue(
      parseFloat(
        (
          this.parentForm.get("SELL4").value -
          this.parentForm.get("COMMA4").value
        ).toFixed(2)
      )
    );
    this.parentForm.controls.COMMP5.setValue(
      this.parentForm.get("FC_Tax5_CommPer").value
    );
    this.parentForm.controls.COMMA5.setValue(
      this.parentForm.get("FC_Tax4_CommAmt").value *
      (this.parentForm.get("FC_Tax1_CIDExRate").value
      )
    );
    this.parentForm.controls.SELL5.setValue(
      parseFloat(
        (
          this.parentForm.get("FC_Tax4_Sell").value *
          this.parentForm.get("FC_Tax4_CIDExRate").value
        ).toFixed(2)
      )
    );

    this.parentForm.controls.BUY5.setValue(
      parseFloat(
        (
          this.parentForm.get("SELL5").value -
          this.parentForm.get("COMMA5").value
        ).toFixed(2)
      )
    );
    this.parentForm.controls.COMMP6_Tax5.setValue(
      this.parentForm.get("FC_Tax6_CommPer").value
    );
    this.parentForm.controls.COMMA6_Tax5.setValue(
      this.parentForm.get("FC_Tax5_CommAmt").value
    );
    this.parentForm.controls.SELL6_Tax5.setValue(
      parseFloat(
        (
          this.parentForm.get("FC_Tax5_Sell").value *
          this.parentForm.get("FC_Tax5_CIDExRate").value
        ).toFixed(2)
      )
    );
    this.parentForm.controls.BUY6_Tax5.setValue(
      parseFloat(
        (
          this.parentForm.get("FC_Tax5_Buy").value *
          this.parentForm.get("FC_Tax5_CIDExRate").value
        ).toFixed(2)
      )
    );
  }
  public buyExRate: number;
  calculatetotal(initialvalu) {
    debugger;
    this.parentForm.controls.FC_Tot_Buy.setValue(
      this.parentForm.get("FC_Fare_Buy").value +
      this.parentForm.get("FC_Tax1_Buy").value +
      this.parentForm.get("FC_Tax2_Buy").value +
      this.parentForm.get("FC_Tax3_Buy").value +
      this.parentForm.get("FC_Tax4_Buy").value +
      this.parentForm.get("FC_Tax5_Buy").value
    );

    this.parentForm.controls.FC_Tot_CommAmt.setValue(
      this.parentForm.get("FC_Fare_CommAmt").value +
      this.parentForm.get("FC_Tax1_CommAmt").value +
      this.parentForm.get("FC_Tax2_CommAmt").value +
      this.parentForm.get("FC_Tax3_CommAmt").value +
      this.parentForm.get("FC_Tax4_CommAmt").value +
      this.parentForm.get("FC_Tax5_CommAmt").value
    );
    this.parentForm.controls.TaxExcludingSalesTax.setValue(
     
      this.parentForm.get("FC_Tax1_Sell").value +
      this.parentForm.get("FC_Tax2_Sell").value +
      this.parentForm.get("FC_Tax3_Sell").value +
      this.parentForm.get("FC_Tax4_Sell").value +
      this.parentForm.get("FC_Tax5_Sell").value
    );
    
    this.parentForm.controls.FC_Tot_Sell.setValue(
      this.parentForm.get("FC_Fare_Sell").value +
      this.parentForm.get("FC_Tax1_Sell").value +
      this.parentForm.get("FC_Tax2_Sell").value +
      this.parentForm.get("FC_Tax3_Sell").value +
      this.parentForm.get("FC_Tax4_Sell").value +
      this.parentForm.get("FC_Tax5_Sell").value
    );
if(initialvalu!=0){
    this.parentForm.controls.FCSell.setValue(
      this.parentForm.get("FC_Fare_Sell").value +
      this.parentForm.get("FC_Tax1_Sell").value +
      this.parentForm.get("FC_Tax2_Sell").value +
      this.parentForm.get("FC_Tax3_Sell").value +
      this.parentForm.get("FC_Tax4_Sell").value +
      this.parentForm.get("FC_Tax5_Sell").value
    );
    }
    //----------------HC------------------
    this.parentForm.controls.BUYTOT.setValue(
      this.parentForm.get("BUY1").value +
      this.parentForm.get("BUY2").value +
      this.parentForm.get("BUY3").value +
      this.parentForm.get("BUY4").value +
      this.parentForm.get("BUY5").value +
      this.parentForm.get("BUY6_Tax5").value
    );

    this.parentForm.controls.COMMPTOT.setValue(
      this.parentForm.get("COMMP1").value +
      this.parentForm.get("COMMP2").value +
      this.parentForm.get("COMMP3").value +
      this.parentForm.get("COMMP4").value +
      this.parentForm.get("COMMP5").value +
      this.parentForm.get("COMMP6_Tax5").value
    );

    this.parentForm.controls.COMMATOT.setValue(
      this.parentForm.get("COMMA1").value +
      this.parentForm.get("COMMA2").value +
      this.parentForm.get("COMMA3").value +
      this.parentForm.get("COMMA4").value +
      this.parentForm.get("COMMA5").value +
      this.parentForm.get("COMMA6_Tax5").value
    );

    this.parentForm.controls.SELLTOT.setValue(
      this.parentForm.get("SELL1").value +
      this.parentForm.get("SELL2").value +
      this.parentForm.get("SELL3").value +
      this.parentForm.get("SELL4").value +
      this.parentForm.get("SELL5").value +
      this.parentForm.get("SELL6_Tax5").value
    );

    this.buyExRate =
      this.parentForm.get("EXCHANGE").value == "" ||
        this.parentForm.get("EXCHANGE").value == 0
        ? 1
        : this.parentForm.get("EXCHANGE").value;
    this.parentForm.controls.PAYABLE.setValue(
      this.parentForm.get("FC_Tot_Buy").value * this.buyExRate
    );
    this.parentForm.controls.HCSell.setValue(
      this.parentForm.get("FCSell").value * this.exRate
    );
    this.parentForm.controls.HCCommAmt.setValue(
      this.parentForm.get("HCSell").value - this.parentForm.get("PAYABLE").value
    );

    this.parentForm.controls.Markup.setValue(
      this.parentForm.controls.COMMATOT.value
    );
    debugger;
    if (this.parentForm.controls.CURR1.value == this.parentForm.controls.FC_Tax1_CID.value) {
      this.parentForm.controls.FCBuy.setValue(
        this.parentForm.controls.FC_Tot_Buy.value
      );
      // this.parentForm.controls.CostofSale.setValue(
      //   this.parentForm.controls.FCBuy.value
      // );
    }
    else {
      this.parentForm.controls.FCBuy.setValue(
        this.parentForm.controls.BUYTOT.value
      );
      // this.parentForm.controls.CostofSale.setValue(
      //   this.parentForm.controls.FCBuy.value
      // );
    }
    // this.parentForm.controls.FCSell.setValue(
    //     parseFloat(
    //       (
    //         (this.parentForm.controls.SELLTOT.value 
    //           ) /
    //         this.folderExrate
    //       ).toFixed(2)
    //     )
    //   );
    // SELLTOT
    this.parentForm.controls.CostofSale.setValue(
      parseFloat(
        (
          (this.parentForm.controls.FCBuy.value
            *
            this.parentForm.controls.EXCHANGE.value) /
          this.folderExrate
        ).toFixed(2)
      )
    );
    debugger;
    if(initialvalu!=0){
    this.parentForm.controls.FCSell.setValue(this.parentForm.controls.SELLTOT.value / this.folderExrate)
    }
    this.parentForm.controls.FCSell.setValue(parseFloat(this.parentForm.controls.FCSell.value).toFixed(2));
    this.parentForm.controls.Markup.setValue(this.parentForm.controls.FCSell.value - this.parentForm.controls.CostofSale.value);
    this.parentForm.controls.Markup.setValue(parseFloat(this.parentForm.controls.Markup.value).toFixed(2));

    this.parentForm.controls.HCSell.setValue(
      this.parentForm.get("FCSell").value * this.exRate
    );
    if(this.BSPTAXEnableVal==1)
    {
      this.Calculateform();
    }
 
  }
  Calculateform() {
    debugger;
    
    if (
      this.parentForm.get("TaxExcludingSalesTax").value !== 0 &&
      this.parentForm.get("TaxExcludingSalesTax").value !== null
    ) {
      this.parentForm.controls.BaseFare.setValue(
        parseFloat(
          (
            this.parentForm.get("FCSell").value -
            this.parentForm.get("TaxExcludingSalesTax").value
          ).toFixed(2)
        )
      );
    } else {
      if(this.parentForm.get("FCSell").value==null ||this.parentForm.get("FCSell").value=='')
      {
        this.parentForm.get("FCSell").setValue(0);
      }
      else{
        this.parentForm.get("FCSell").setValue(+this.parentForm.get("FCSell").value);
      }
      this.parentForm.controls.BaseFare.setValue(
        parseFloat(this.parentForm.get("FCSell").value.toFixed(2))
      );
    }
  }
  public open() {
    this.CalculateHomeCurrency();
    this.opened = true;
  }

  public getFareCurrencyChange(value: any, CURR1: any, TICKDATE: any, EXCHANGE1: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.parentForm.controls.CURR1.setValue(value.UsrCode);
      if (this.parentForm.controls.CURR1.value == this.parentForm.controls.FC_Tax1_CID.value) {
        this.parentForm.controls.CURR.setValue(value.UsrCode);
      }
      this.api.Isvalidfarecurrency = true;
    }
    else {
      this.parentForm.controls.CURR1.setValue(null);
      this.api.Isvalidfarecurrency = false;
    }

    this.getCurrencyExRate(CURR1, TICKDATE, EXCHANGE1);
  }

  public getTax1CurrencyChange(value: any, FC_Tax1_CID, TICKDATE, FC_Tax1_CIDExRate): void {
    debugger;
    if (value != null && value != undefined) {
      this.parentForm.controls.FC_Tax1_CID.setValue(value.UsrCode);
      this.parentForm.controls.FC_Tax2_CID.setValue(value.UsrCode);
      this.parentForm.controls.FC_Tax5_CID.setValue(value.UsrCode);
      this.parentForm.controls.FC_Tax3_CID.setValue(value.UsrCode);
      this.parentForm.controls.FC_Tax4_CID.setValue(value.UsrCode);

      if (this.parentForm.get("FC_Tax1_CID").value != "") {
        this.currencySelectedItem = this.currencyList.find(
          item => item.Code == this.parentForm.get("FC_Tax1_CID").value
        );
      }
      if (this.parentForm.get("FC_Tax2_CID").value != "") {
        this.currencySelectedItemTax1 = this.currencyList.find(
          item => item.Code == this.parentForm.get("FC_Tax2_CID").value
        );
        this.getExchangeRate(
          "FC_Tax2_CID",
          "TICKDATE",
          "FC_Tax2_CIDExRate",1
        );
      }
      if (this.parentForm.get("FC_Tax3_CID").value != "") {
        this.currencySelectedItemTax2 = this.currencyList.find(
          item => item.Code == this.parentForm.get("FC_Tax3_CID").value
        );
        this.getExchangeRate(
          "FC_Tax3_CID",
          "TICKDATE",
          "FC_Tax3_CIDExRate",1
        );
      }
      if (this.parentForm.get("FC_Tax4_CID").value != "") {
        this.currencySelectedItemTax3 = this.currencyList.find(
          item => item.Code == this.parentForm.get("FC_Tax4_CID").value
        );
        this.getExchangeRate(
          "FC_Tax4_CID",
          "TICKDATE",
          "FC_Tax4_CIDExRate",1
        );
      }
      if (this.parentForm.get("FC_Tax5_CID").value != "") {
        this.currencySelectedItemTax4 = this.currencyList.find(
          item => item.Code == this.parentForm.get("FC_Tax5_CID").value
        );
        this.getExchangeRate(
          "FC_Tax5_CID",
          "TICKDATE",
          "FC_Tax5_CIDExRate",1
        );
      }

      this.api.IsValidTaxCurrency = true;
      //this.getExchangeRate(this.parentForm.get('FC_Tax1_CID').value,this.parentForm.get('TICKDATE').value,'FC_Tax2_CIDExRate')
    } else {
      this.api.IsValidTaxCurrency = false;
      this.parentForm.controls.FC_Tax1_CID.setValue("");
    }
    this.getCurrencyExRate(FC_Tax1_CID, TICKDATE, FC_Tax1_CIDExRate);

  }
  public getTax2CurrencyChange(value: any): void {
    console.log("selectionChange", value);
    if (value != null && value != undefined) {
      this.parentForm.controls.FC_Tax2_CID.setValue(value.UsrCode);
      //this.getExchangeRate(this.parentForm.get('FC_Tax2_CID').value,this.parentForm.get('TICKDATE').value,'')
    } else this.parentForm.controls.FC_Tax2_CID.setValue("");
  }
  public getTax3CurrencyChange(value: any): void {
    console.log("selectionChange", value);
    if (value != null && value != undefined) {
      this.parentForm.controls.FC_Tax3_CID.setValue(value.UsrCode);
      //this.getExchangeRate(this.parentForm.get('FC_Tax3_CID').value,this.parentForm.get('TICKDATE').value,3)
    } else this.parentForm.controls.FC_Tax3_CID.setValue("");
  }
  public getTax4CurrencyChange(value: any): void {
    console.log("selectionChange", value);
    if (value != null && value != undefined) {
      this.parentForm.controls.FC_Tax4_CID.setValue(value.UsrCode);
    } else this.parentForm.controls.FC_Tax4_CID.setValue("");
  }
  public getTax5CurrencyChange(value: any): void {
    console.log("selectionChange", value);
    if (value != null && value != undefined) {
      this.parentForm.controls.FC_Tax5_CID.setValue(value.UsrCode);
    } else this.parentForm.controls.FC_Tax5_CID.setValue("");
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
