export class Hote201DTO {
    IsSuccess: boolean;
    Message: string;
    ResponseData: {
     HID :any
     SLNO :number
     TYPE :string
     Rooms :number
     TYPEDesc :string
     IMAGE :string
     HtmlRoomID :any
     Sleeps :number
     Extrabed :number
     Noofrooms :number
     Adult :number
     Child :number
     Facility :string
    }
}
