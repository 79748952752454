import { Component, OnInit, Input } from '@angular/core';
import {  RailsegDetail } from '../../Models/railseg-detail';
import { ActivatedRoute, Router } from '@angular/router';
import { RailsegService } from '../../Services/railseg.service';
import { ShareDataService } from '../../Services/share-data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';

@Component({
  selector: 'app-railseg-details',
  templateUrl: './railseg-details.component.html',
  styleUrls: ['./railseg-details.component.scss']
})


export class RailsegDetailsComponent implements OnInit {
  public ExcludeOnetimeCust_int=0;
  public ExcludeOnetimeCust=false;
  private subscription: Subscription = new Subscription();
  lockStatus:any;
  folderEditMode:any;
  folderStatus:any;
  DEPTIME:string=null;
  TRAINTIME:string=null;
  ARRTIME:string=null;
  public formeditable=true;
     railsegDetail: RailsegDetail  = {
    IsSuccess:true,
    Message:"succcess",
    ResponseData:{     
       

      FOLDERNO :0,
      ITEMNO :0,
      TRAINNAME :"",
      TRAINNO :"",
      CLASS :"",
      CLASSNAME :"",
      SEATS :"",
      STATUS :"",
      STATUSNAME :"",
      DEPDATE :new Date('01-01-1900'),
      DEPCITY :"",
      DEPCITYNAME :"",
      ARRCITY :"",
      ARRCITYNAME :"",
      OPENSEG :"",
      DEPTIME :0,
      ARRTIME :0,
      S :"",
      PNRNO :"",
      FAREBASIS :"",
      DEPTERM :"",
      CHKIN :"",
      BOOKEDVIA :"",
      ARRDATE :new Date('01-01-1900'),
      ARRTERM :"",
      TRAINTIME :0,
      BAGGALLOW :"",
      MEALSINFO :"",
      SEATINFO :"",
      SLNO :0,
       PROJNO :0,
      Stops :0,
      GDS :"",
      ItnryNotes :"",
      PCC :"",
      VLOCATOR :"",
      VOUCHER :"",
      CONFIRMATIONNO :"",
       RSD_AutoNo :0,
       
    }
  };

  constructor( public commonservice :CommonserviceService, private route: ActivatedRoute,private router: Router,private api: RailsegService,private apiShared: ShareDataService, private spinner:NgxSpinnerService) { }
  //@Input() guid: "",
  //@Input() orderno: 0,
  private slno:string=this.route.snapshot.params['slno'];//'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/';
  private orderno=this.route.snapshot.params['orderNo']
  ngOnInit() {
    var orderno= sessionStorage.getItem('orderNo');
    if(orderno!=null)
    if(this.orderno !==orderno)
    {
      return  this.router.navigate(['PenAir/Home']);
    }
    this.lockStatus= this.apiShared.folderReadOnly;
    this.folderStatus=this.apiShared.FolderStatus;
    if(this.lockStatus=="0" && this.folderStatus!="Invoiced")
    {
      this.formeditable=true;
    }
    else{
      this.formeditable=false;
    }
    if(this.folderStatus=="Cancelled" ||this.folderStatus=="Refund" || this.folderStatus=="Partial Refund")//By Caseid-12780
    {
      this.formeditable=false;
    }

    this.folderEditMode="false";
    //this.apiShared.folderEditMode=this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode,'folderEditMode');
    //alert(this.orderno)
   this.getRailseggDetails(this.orderno,this.slno);
    //'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/
  }
  getRailseggDetails(id,slno) {
    this.spinner.show();
    this.subscription.add( this.api.getRailseg(id,slno)
      .subscribe(data => {
        if(data!=null &&data!=undefined){
        this.railsegDetail = data;
        this.DEPTIME= this.commonservice.ConvertTo4digit( this.railsegDetail.ResponseData.DEPTIME,4);
        this.TRAINTIME= this.commonservice.ConvertTo4digit( this.railsegDetail.ResponseData.TRAINTIME,4);
        this.ARRTIME= this.commonservice.ConvertTo4digit( this.railsegDetail.ResponseData.ARRTIME,4);
        
        if(this.railsegDetail.ResponseData.OPENSEG=='1'){
          this. ExcludeOnetimeCust_int=1;
          this. ExcludeOnetimeCust=true;
      
        }
        else{
          this. ExcludeOnetimeCust_int=0;
          this. ExcludeOnetimeCust=false;
         
        };
        this.spinner.hide();
      }
      else{
        this.spinner.hide();
      }
      //  console.log(this.railsegDetail);
        //this.isLoadingResults = false;
      }));
  }
  onEdit()
  {
    
    this.router.navigate(['PenAir/Folder/RailsegDetailsEdit',this.orderno,this.slno]);
  }
  onCancel()
  {
    this.commonservice.isfinalsaved=false;
    sessionStorage.setItem('pagevalue', '0');
    var folderQuotes=sessionStorage.getItem('folderQuotes');
     if(folderQuotes){
       this.router.navigate(['/PenAir/CRM/Enquiry/View/',this.orderno]);
     }else{
      this.router.navigate(['PenAir/Folder/FolderSummary',this.orderno]);
     }
    
  }
  onCheckboxChange()
  {
    
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
