import { Component, OnInit, Input  } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { CommonserviceService } from '../../Services/commonservice.service';

@Component({
  selector: 'app-airticket-salestaxdetails',
  templateUrl: './airticket-salestaxdetails.component.html',
  styleUrls: ['./airticket-salestaxdetails.component.scss']
})
export class AirticketSalestaxdetailsComponent implements OnInit {
  @Input() airticketSalesTax;
  @Input() fileTax;
  constructor(public intl: IntlService,public common:CommonserviceService
    ) { }

  ngOnInit() {
    if(this.airticketSalesTax.ResponseData.ITYPE ==='Ticket') {
      this.visible=true;
      this.cruise=false;
    } else if(this.airticketSalesTax.ResponseData.ITYPE ==='Cruise') {
      this.visible=true;
      this.cruise=true;
    } else {
      this.visible=false;
      this.cruise=false;
    }
    
  }
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;

  visible=false;
  cruise=false;

  public onChange(event: any): void {
      this.expandMode = parseInt(event.target.value, 10);
  }
  
  public onPanelChange(event: any): void { }

  
  
}
