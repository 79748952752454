export class HotelPropertyTypes {
    IsSuccess:boolean;
    Message:string;
    ResponseData:{
        HPtypecode :string;
        HPtypename :string;
        HPtypeAutoNo:number;
        StatusDetails:string;
        Status:string;
    }
}

export class HotelPropertyTypesRequestDTO {
    HPtypecode :string;
    HPtypename :string;
    CrUid:number;
     CrDate:any;
    LupId:number;
    LupDate:any;
    HPtypeAutoNo:number;
    StatusDetails:string;
    // Status:string;
}
