
import { Component, OnInit, Input } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { CommonserviceService } from 'src/app/Services/commonservice.service';
import { ShareDataService } from 'src/app/Services/share-data.service';


@Component({
  selector: 'app-airticket-bsp',
  templateUrl: './airticket-bsp.component.html',
  styleUrls: ['./airticket-bsp.component.scss']
})
export class AirticketBspComponent implements OnInit {
  FareValue:string="Fare";
  IsSametax:boolean=true;
  Tax1:string="Tax1";
  Tax2:string="Tax2";
  Tax3:string="Tax3";
  Tax4:string="Tax4";
  Tax5:string="Tax5";
  FC_Tax1_CommPer:number;
  FC_Tax2_CommPer:number;
  FC_Tax3_CommPer:number;
  FC_Tax4_CommPer:number;
  FC_Tax5_CommPer:number;
  FC_Tax6_CommPer:number;
  hcExRate:any;
  hcCurr:any;
  @Input() itineraryDetails:any;
  constructor(public common:CommonserviceService,public intl: IntlService, private apiShared: ShareDataService,) { }

  ngOnInit() {
    // debugger;
    this.hcExRate = this.apiShared.hcExRate;
    this.hcCurr = this.apiShared.homeCurrency;
    this.FC_Tax1_CommPer=this.GetPercentage(this.itineraryDetails.ResponseData.FC_Fare_CommAmt,this.itineraryDetails.ResponseData.FC_Fare_Sell),
    this.FC_Tax2_CommPer=this.GetPercentage(this.itineraryDetails.ResponseData.FC_Tax1_CommAmt,this.itineraryDetails.ResponseData.FC_Tax1_Sell),
    this.FC_Tax3_CommPer=this.GetPercentage(this.itineraryDetails.ResponseData.FC_Tax2_CommAmt,this.itineraryDetails.ResponseData.FC_Tax2_Sell),
    this.FC_Tax4_CommPer=this.GetPercentage(this.itineraryDetails.ResponseData.FC_Tax3_CommAmt,this.itineraryDetails.ResponseData.FC_Tax3_Sell),
    this.FC_Tax5_CommPer=this.GetPercentage(this.itineraryDetails.ResponseData.FC_Tax4_CommAmt,this.itineraryDetails.ResponseData.FC_Tax4_Sell),
    this.FC_Tax6_CommPer=this.GetPercentage(this.itineraryDetails.ResponseData.FC_Tax5_CommAmt,this.itineraryDetails.ResponseData.FC_Tax5_Sell)
 if(this.itineraryDetails.ResponseData.CURR!=this.itineraryDetails.ResponseData.FC_Tax1_CID)
  {
this.IsSametax=false;
  }
  else
  {
    this.IsSametax=true;
  }
}
  public GetPercentage(comm: number,sell:number): number 
      {
       
        if(sell!=0)
          return (comm/sell)*100;
        else
          return 0;
      }
  public opened = false;

  public close(status) {
    //console.log(`Dialog result: ${status}`);
    this.opened = false;
  }
  public onPanelChange(event: any): void { console.log('stateChange: ', event); }
public open() {
  this.opened = true;
}
}
