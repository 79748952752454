import { Component, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { FolderSearchCriteriaDTO, TransfersSearchDTO } from '../../Models/folder-search-criteria-dto';
import { SearhdtotransferService } from '../../Services/searhdtotransfer.service';
import { Alert } from 'selenium-webdriver';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
@Component({
  selector: 'app-searchby-transfers',
  templateUrl: './searchby-transfers.component.html',
  styleUrls: ['./searchby-transfers.component.scss']
})
export class SearchbyTransfersComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  //transfersSearchForm: FormGroup;
  constructor(public common: CommonserviceService, private searhTransferDTO: SearhdtotransferService, private lookupApi: LookUpDetailsService) { }
  SupplierList: Array<LookUpDetails['ResponseData']> = [];
  opened = false;
  openSearch = false;
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  public supplierSelectedItem: LookUpDetails['ResponseData'];
  public supplierSelected: String;
  public statusList: Array<string> = ['OK', 'Pending', 'Cancelled', 'Confirmed'];
  flsrchDto: FolderSearchCriteriaDTO;
  TransfersSearchDTO: TransfersSearchDTO;
  ngOnChanges(changes: SimpleChanges) {

    this.TransfersSearchDTO = this.flsrchDto.TransfersSearchDTO;
  }
  menuIndex = 4;
  ngOnInit() {

    this.subscription.add(this.searhTransferDTO.SrchTranferDTO.subscribe(flsrchDto => {
      this.flsrchDto = flsrchDto;

    }))
    if (this.flsrchDto.TransfersSearchDTO != null)
      this.TransfersSearchDTO = this.flsrchDto.TransfersSearchDTO;
    else
      this.TransfersSearchDTO = new TransfersSearchDTO();
    this.loadSupplier();
     if( this.TransfersSearchDTO.Supplier !="" && this.TransfersSearchDTO.Supplier !=undefined){
      this.loadSupplierValue(this.TransfersSearchDTO.Supplier);
     }
  }

  loadSupplierValue(value:any) {  //14068   v1.0 
    debugger; 

      this.subscription.add(this.lookupApi.findSupplier(value).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.SupplierList = data["ResponseData"];
            if (value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
              }

            }
          }

        }
      ));
    
  }

  public loadSupplier() {
    this.subscription.add(this.lookupApi.GetAllLookup('SUPPLIERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.SupplierList = data["ResponseData"];
          if (this.TransfersSearchDTO.Supplier != '' && this.TransfersSearchDTO.Supplier != null) {

            this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.TransfersSearchDTO.Supplier);
            this.supplierSelected = this.supplierSelectedItem.Name;
          }
        }
      }
    ));
  }

  public close(status) {

    this.opened = false;
    this.openSearch = false;
  }

  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }

  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  handleFiltersupplier(value) {

    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.GetLookupById("SUPPLIERS", value, value).subscribe(
        // this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  public SupplierValueChange(value: any): void {
    if (this.SupplierList != null && this.SupplierList.length > 0) {//13860
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);

    }

    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860

      this.TransfersSearchDTO.Supplier = (this.supplierSelectedItem.Code);


    } else {

      this.supplierSelected = '';
      this.TransfersSearchDTO.Supplier = ('');


    }
    this.callChange();
  }


  callChange() {

    if (this.TransfersSearchDTO.Supplier != '' || this.TransfersSearchDTO.VechicleType != '' || this.TransfersSearchDTO.VoucherNo != ''
      || this.TransfersSearchDTO.From != '' || this.TransfersSearchDTO.To != '' || this.TransfersSearchDTO.Status.toString() != ''

    ) {
      if (this.TransfersSearchDTO.DepDateFromAF != null) {
        if (this.TransfersSearchDTO.DepDateFromAF.toString() != '')
          this.TransfersSearchDTO.DepDateFrom = this.common.transformDate(new Date(this.TransfersSearchDTO.DepDateFromAF));

        // this.TransfersSearchDTO.DepDateFrom= new Date(this.TransfersSearchDTO.DepDateFromAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.TransfersSearchDTO.DepDateFrom = null;
      }

      if (this.TransfersSearchDTO.DepDateToAF != null) {
        if (this.TransfersSearchDTO.DepDateToAF.toString() != '')
          this.TransfersSearchDTO.DepDateTo = this.common.transformDate(new Date(this.TransfersSearchDTO.DepDateToAF));

        // this.TransfersSearchDTO.DepDateTo= new Date(this.TransfersSearchDTO.DepDateToAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.TransfersSearchDTO.DepDateTo = null;
      }

      if (this.TransfersSearchDTO.BookingDateFromAF != null) {
        if (this.TransfersSearchDTO.BookingDateFromAF.toString() != '')
          this.TransfersSearchDTO.BookingDateFrom = this.common.transformDate(new Date(this.TransfersSearchDTO.BookingDateFromAF));

        //this.TransfersSearchDTO.BookingDateFrom= new Date(this.TransfersSearchDTO.BookingDateFromAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.TransfersSearchDTO.BookingDateFrom = null;

      }
      if (this.TransfersSearchDTO.BookingDateToAF != null) {
        if (this.TransfersSearchDTO.BookingDateToAF.toString() != '')
          this.TransfersSearchDTO.BookingDateTo = this.common.transformDate(new Date(this.TransfersSearchDTO.BookingDateToAF));

        //  this.TransfersSearchDTO.BookingDateTo= new Date(this.TransfersSearchDTO.BookingDateToAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.TransfersSearchDTO.BookingDateTo = null;
      }

      if (this.TransfersSearchDTO.DepDateFromAF == null && this.TransfersSearchDTO.DepDateToAF != null) {
        this.TransfersSearchDTO.DepDateFrom = this.TransfersSearchDTO.DepDateTo;
      }
      if (this.TransfersSearchDTO.BookingDateFromAF == null && this.TransfersSearchDTO.BookingDateToAF != null) {
        this.TransfersSearchDTO.BookingDateFrom = this.TransfersSearchDTO.BookingDateTo;
      }

      this.flsrchDto.TransfersSearchDTO = this.TransfersSearchDTO;
    }


    else
      this.flsrchDto.TransfersSearchDTO = null;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
