import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FolderHotelActivityTblDTO } from 'src/app/Models/hotel-master';
import { CommonserviceService } from 'src/app/Services/commonservice.service';
import { HeotelselectService } from 'src/app/Services/heotelselect.service';
import { LookUpDetailsService } from 'src/app/Services/look-up-details.service';
import { State, process } from '@progress/kendo-data-query';
import { HotelService } from 'src/app/Services/hotel.service';
@Component({
  selector: 'app-select-hotel-activity',
  templateUrl: './select-hotel-activity.component.html',
  styleUrls: ['./select-hotel-activity.component.scss']
})
export class SelectHotelActivityComponent implements OnInit {
  add=false;
  public view: Observable<any>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
};
private subscription: Subscription = new Subscription();
public changes: any = {};
  constructor(public comm:CommonserviceService, public editService:HeotelselectService,public router:Router ,private toastr:ToastrService,private translateapi: TranslateService,public api:HotelService, public route:ActivatedRoute,private formBuilder: FormBuilder,private lookupApi:LookUpDetailsService) { }

  ngOnInit() {
    debugger;
    this.editService.updatedItems=[];
        this.editService.createdItems=[];
        this.editService.deletedItems=[];
        this.editService.originalData=[];
        this.editService.data=[];
        this.editService.reset();
       this.editService.dataactvity=[];
  this.loaddata();
        // this.view = this.editService.pipe(map(data1 => process(data1, this.gridState)));
        //   this.editService.read();
         
  }
  public loaddata(){
    this.api.formDatasmain["GUIDItinerary"]=this.api.guid;
    this.subscription.add( this.api.GetHotelSelectedActvities()
    .subscribe(data => {
      this.editService.updatedItems=[];
      this.editService.createdItems=[];
      this.editService.deletedItems=[];
      this.editService.originalData=[];
      this.editService.data=[];
     this.editService.dataactvity=[];
     if(data !=null && data !=undefined && data.ResponseData!=undefined){
      this.editService.data=data.ResponseData ;
     }
     else{
      this.editService.data=[];
     } 
     
      this.view = this.editService.pipe(map(data1 => process(data1, this.gridState)));
      this.editService.read();
      // this.api.data5=data;
    }));
  }
  public onChange(value: Date,dataItem): void {
  
   
    
    
  }
  public onChangeRTo(value: Date,dataItem): void {
    
    
  }
  public onStateChange(state: State) {
    
    this.gridState = state;
  
    this.editService.read();
  }
  
  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    
  
  
    if (!isEdited) {
        sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }
  
  public cellCloseHandler(args: any) {
    debugger;
    const { formGroup, dataItem } = args;
    if(formGroup.value['ActivityID']!=null && formGroup.value['ActivityID']!=''&& formGroup.value['ActivityID']!=undefined)
    {
      if(this.editService.actvitylist!=null && this.editService.actvitylist!=undefined){
      var data=this.editService.actvitylist.find(x=>x.ActivityId==formGroup.value['ActivityID']);
      formGroup.value['Description']=data.ActivityName;
      }

    }
   formGroup.value['Hote101_Id']=this.api.Hote101_Id;
   formGroup.value['HD_AutoNo']=this.api.HD_AutoNo;
         if (!formGroup.valid) {
              // prevent closing the edited cell if there are invalid values.
             args.preventDefault();
         } 
         else if (formGroup.dirty ||!formGroup.dirty) {
             this.editService.assignValues(dataItem, formGroup.value);
             this.editService.update(dataItem);
         }
      
    
  
  
  
  
  }
  
  public addHandler({ sender }) {
    debugger;
    this.add=false;
    sender.addRow(this.createFormGroup(new FolderHotelActivityTblDTO()));
  }
  
  public cancelHandler({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
  }
  
  public saveHandler({ sender, formGroup, rowIndex }) {
    debugger;
    this.add=true;
   
      for(let result of this.editService.data)
      {
        if(formGroup.value['ActivityID']==result['ActivityID'])
        {
          this.subscription.add(this.translateapi.get(['Messages.SameActivity']).subscribe((translations ) => {
            var dataw= translations['Messages.SameActivity'];
           this.toastr.warning(dataw,"",{
              timeOut:3000,
              disableTimeOut:false,
              closeButton:true
            })
           }));
           return;
           
        }
      }
    if(formGroup.value['ActivityID']!=null && formGroup.value['ActivityID']!=''&& formGroup.value['ActivityID']!=undefined)
    {
      if(this.editService.actvitylist!=null && this.editService.actvitylist!=undefined){
      var data=this.editService.actvitylist.find(x=>x.ActivityId==formGroup.value['ActivityID']);
      formGroup.value['Description']=data.ActivityName;
      }

    }
   formGroup.value['Hote101_Id']=this.api.Hote101_Id;
   formGroup.value['HD_AutoNo']=this.api.HD_AutoNo;
    if (formGroup.valid) {
        this.editService.create(formGroup.value);
        sender.closeRow(rowIndex);
    }
  }
  
  public removeHandler({ sender, dataItem }) {
    
    this.editService.remove(dataItem);
  
    sender.cancelCell();
  }
  
  public saveChanges(grid: any): void {
    debugger;
    grid.closeCell();
    grid.cancelCell();
  
    
    this.api.createdItems=this.editService.createdItems;
    this.api.updatedItems=this.editService.updatedItems;
    this.api.deletedItems=this.editService.deletedItems;
    this.subscription.add( this.api.SaveHotelactivityForHotel().subscribe(res=>{
      if(res !=null && res !=undefined ){
      if(res.ResponseData==1)
      {
        this.editService.updatedItems=[];
        this.editService.createdItems=[];
        this.editService.deletedItems=[];
        this.editService.originalData=[];
        this.editService.data=[];
       this.editService.dataactvity=[];
       this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations ) => {
        var dataw= translations['Messages.saved_success'];
       this.toastr.success(dataw,"",{
          timeOut:3000,
          disableTimeOut:false,
          closeButton:true
        })
       }));
      //  this.editService.saveChanges(); saved_success
        this.loaddata();
      }
      else{
        this.subscription.add(this.translateapi.get(['Messages.FailedPosting']).subscribe((translations ) => {
          var dataw= translations['Messages.FailedPosting'];
         this.toastr.warning(dataw,"",{
            timeOut:3000,
            disableTimeOut:false,
            closeButton:true
          })
         }));
      }
    }
  }));
  }
  
  public cancelChanges(grid: any): void {
    debugger;
    grid.cancelCell();
  
    this.editService.cancelChanges();
    this.loaddata();
  }
  
  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
        'AutoNo': dataItem.AutoNo,
        'HD_AutoNo': dataItem.HD_AutoNo,
        'ActivityID': [dataItem.ActivityID,Validators.required],
        'Hote101_Id': dataItem.Hote101_Id,
        'Description': dataItem.Description,
        'AdditionalNotes': dataItem.AdditionalNotes,
        'PassengerNames': dataItem.PassengerNames,
        'LupDate':[dataItem.LupDate!=null?(dataItem.LupDate.toString()=="1900-01-01T00:00:00"?null:new Date(dataItem.RFrom)):null],
        'CrDate':[dataItem.CrDate!=null?(dataItem.CrDate.toString()=="1900-01-01T00:00:00"?null:new Date(dataItem.CrDate)):null],

        
    });
  }
  public calculateamountrate(value:any,dataItem,grid): void {
    
    if(value!=undefined)
    {
      dataItem.Rate=value;
      this.calculateamount(dataItem,grid);
    }
  
  }
  public calculateamountcomm(value:any,dataItem): void {
    
    if(value!=undefined)
    {
      dataItem.Comm=value;
    }
  
  }
  public calculateamount(dataItem,grid:any): void {
    
    if(dataItem.Rate==null||dataItem.Rate==undefined)
    {
      dataItem.Rate=0;
    }
   // var d=this.itemIndex(dataItem,grid)
    dataItem.Buy=dataItem.Rate+dataItem.Rate*2/100;
    //this.editService.createdItems.push(dataItem)
  
  }
  public itemIndex = (item: any, data: any[]): number => {
    for (let idx = 0; idx < data.length; idx++) {
        if (data[idx].AutoNo === item.AutoNo) {
            return idx;
        }
    }
  
    return -1;
  };
}
