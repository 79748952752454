import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { EnvService } from 'src/env.service';
import { FeesReportDTO, FeesReportResultDTO } from '../Models/fees-report-dto.model';
import { FolderActivityDetailedDTO } from '../Models/folder-activity-detailed.model';
import { CommonserviceService } from './commonservice.service';
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};
@Injectable({
  providedIn: 'root'
})
export class FolderActivityDetailedService {
  ProductCodeName: any;
  custname: any;
  ordertypename: any;
  supliername: any;
  public griddata: any;
  prodtstatus: any;
  destName: any;
  branchname: any;
  creditName: any;
  BAreaname: any;
  UserID: any;
  templatedata: any;
  supusrcode: any;
  custUsrCode: any;
  BussinessArea: any;
  apiUrl: string;
  furl: string;
  templatevalue: any;
  template: any;
  public formData: FolderActivityDetailedDTO;
  formDataHeader: any = {
    RptHdrSel_AutoNo: 0,
    RptHdrSel_ModuleName: "Folder Activity Detailed Report",
    RptHdrSel_ColumnHeader: "",
    RptHdrSel_UserId: 0,
    RptHdrSel_TemplateName: "",
    RptHdrSel_Lupdate: new Date(),
    RptHdrSel_CustCo: ""
  };
  item: any;
  constructor(
    private http: HttpClient,
    private env: EnvService,
    public commonservice: CommonserviceService
  ) {
    this.apiUrl = env.baseUrl;
  }

  public GetFolderActivityDetailedCSReportList(FolderActivityDetailedDTO): Observable<
    FolderActivityDetailedDTO
  > {
    var body = FolderActivityDetailedDTO;
    console.log(body);
    return this.http
      .post<FolderActivityDetailedDTO>(
        this.apiUrl + "GetFolderActivityDetailedCSRptList",
        body,
        httpOptions
      )
      .pipe(
        tap((FolderActivityDetailedDTO: any) =>
          console.log(`GetFolderActivityDetailedCSRptList`)
        ),
        catchError(this.commonservice.handleError<any>("GetFolderActivityDetailedCSRptList"))
      );

  }
  public GetFolderActivityDetailedReportList(FolderActivityDetailedDTO): Observable<
    FolderActivityDetailedDTO
  > {
    var body = FolderActivityDetailedDTO;
    console.log(body);
    return this.http
      .post<FolderActivityDetailedDTO>(
        this.apiUrl + "GetFolderActiovityDetailedRptList",
        body,
        httpOptions
      )
      .pipe(
        tap((FolderActivityDetailedDTO: any) =>
          console.log(`GetFolderActiovityDetailedRptList`)
        ),
        catchError(this.commonservice.handleError<any>("GetFolderActiovityDetailedRptList"))
      );
  }

  public GetTemplate(): Observable<any> {
    if (this.formDataHeader.RptHdrSel_TemplateName == "") {
      this.formDataHeader.RptHdrSel_TemplateName = "DefaultTemplate";
    } else {
      this.formDataHeader.RptHdrSel_TemplateName = this.template;
    }
    var body = {
      ...this.formDataHeader
    };
    return this.http
      .post<any>(
        this.apiUrl + "GetTemplate",
        body,
        httpOptions
      )
      .pipe(
        tap(() =>
          console.log(`GetTemplate`)
        ),
        catchError(this.commonservice.handleError<any>("GetTemplate"))
      );
  }
  public GetGridColumn(): Observable<any> {
    var body = {
      ...this.formDataHeader
    };
    return this.http
      .post<FeesReportResultDTO>(
        this.apiUrl + "getGridHeader",
        body,
        httpOptions
      )
      .pipe(
        tap((FeesReportResultDTO: any) =>
          console.log(`getGridHeader`)
        ),
        catchError(this.commonservice.handleError<any>("getGridHeader"))
      );
  }
  public saveOrUpdateReportHeader(): Observable<any> {
    var body = {
      ...this.formDataHeader
    };
    return this.http
      .post<FeesReportResultDTO>(
        this.apiUrl + "saveOrUpdateReportHeader",
        body,
        httpOptions
      )
      .pipe(
        tap((FeesReportResultDTO: any) =>
          console.log(`saveOrUpdateReportHeader`)
        ),
        catchError(
          this.commonservice.handleError<any>("saveOrUpdateReportHeader")
        )
      );
  }
  GetVarientTemplateForEdit(Template: any, modulename: any): Observable<any> {
    const url = `${this.apiUrl}` + 'GetVarientTemplateForEdit' + `/${Template}/${modulename}`;
    return this.http.get<any>(url);
  }
  BranchAuthorisation(branch: any, userID: any): Observable<any> {
    const url = `${this.apiUrl}` + 'BrAuthorisation' + `/${branch}/${userID}`;
    return this.http.get<any>(url);
  }
  GetReportHeaderFooter(uid: number, folderno: any): Observable<any> {
    const url = `${this.apiUrl}` + "GetCompanyLogo" + `/${uid}/`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`=${folderno}`)),
      catchError(this.commonservice.handleError<any>(` id=${folderno}`))
    );
  }
}
