import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EnvService } from 'src/env.service';
import { FileService } from '../../Services/file.service';
import { FolderSummaryService } from '../../Services/folder-summary.service';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { ShareDataService } from '../../Services/share-data.service';
import { DocumentEmailDTO } from '../../Models/document-email-dto';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EmailService } from '../../Services/email.service';
import { CommonserviceService } from '../../Services/commonservice.service';
import { CommonVariencereportService } from '../../Services/common-variencereport.service';
import { WindowState } from '@progress/kendo-angular-dialog';
import { ReportParameterDTO } from '../../Models/common-class.model';
type rptFolderDetails = {
  orderNo: string,
  projNo: string,
  brName: string,
  folderstatus: string,
  atolattachement: boolean
};
@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss']

})
export class BookingConfirmationComponent implements OnInit, OnChanges {
  private subscription: Subscription = new Subscription();
  public opened = false;

  @Input() FolderDetails: rptFolderDetails;//=this.route.snapshot.params['orderNo']
  @Input() invoiceFormatItems: Array<string>;
  @Input() ReportSelected: string;
  @Input() defaultReportSelected: string;
  @Output() closeBookingRpt: EventEmitter<string> = new EventEmitter();
  @Input() OrderType: string;
  public offset = 50;
  public windowLeft = this.offset;
  public windowTop = this.offset;
  public widthCr = window.innerWidth - 40;

  public left = -220;
  public top = -100;
  public heightCr = 950;
  public width = 600;
  public height = 190;
  enablepayment: any;
  rptopened = false;
  reportHeader: any;
  public reportSubject: string = "";
  PaymentUrl: any;
  PassSurname: any;
  public count: any;
  showMsg: any;
  fromId: string;
  bccId: string;
  crRptName: any = "";
  public crPdfFilePath = '';
  pdfurl: string;
  crFilePath: any = '';
  crEmailPath: any = '';
  emailbody: any = '';
  crUrlSafe: SafeResourceUrl;
  crOpened = false;
  emailopened: any = false;
  public reportViewerHeader = "";
  public reportViewertemp = "";
  public windowState: WindowState = "maximized";
  rptType: any = "Booking Confirmation";
  langSelected: any;
  crFileName: any = '';
  crDeletePath: any;
  canndMesgAttachment: any = '';
  //BrName:any;
  //public invoiceFormatItems: Array<string> = ["PackageItinerary7",
  //"InvoicewithItinerary 02","Car Voucher","Accommodation Voucher","E-Ticket Bes"];

  //public invoiceFormatItems: Array<string> = ["InvoicewithItinerary 02"];
  //   ["Accommodation Voucher" ,"AtoReceipt", 
  // "Booking Itinerary", "Cancellation Document", "Car Voucher", "CombinedItinerary", 
  // "CombinedItinerary Clientcopy noCost EN","CombinedItinerary Points Clientcopy KG EN",
  //  "CreditCard Authorisation","Cruise Voucher","Customer Refund Request","E-Ticket","E-Ticket-A4","E-Ticket Bes",
  // "E-Ticket Hardcopy","E-Ticket without Notes","Hotel Voucher","Invoice Only","Invoice Only Client Copy",
  // ,"InvoicewithItinerary 02","InvoicewithItinerary 02 A4","CorporateInvoice02",InvoicewithItinerary 02_Signature];
  constructor(private rptAPI: CommonVariencereportService, public common: CommonserviceService, public email: EmailService, private router: Router, public sanitizer: DomSanitizer, private route: ActivatedRoute, public lookupApi: LookUpDetailsService,
    private env: EnvService, private apiShared: ShareDataService, private api: FolderSummaryService, private emailapi: FileService,
    private translateapi: TranslateService, private toastr: ToastrService, private spinner: NgxSpinnerService) {
    this.pdfurl = env.baseUrl
  }
  showrptlist = false;
  public reportParms: any;
  public reportFile: string = "";
  public selectedReport: string = "";
  ngOnInit() {
    // debugger;
    if (this.FolderDetails.orderNo != null && this.FolderDetails.orderNo != "0" && this.FolderDetails.orderNo != "") {

      this.apiShared.Setapishareddata(this.FolderDetails.orderNo, 'orderNo');
      this.subscription.add(
        this.lookupApi.GetReportDetails(this.FolderDetails.orderNo).subscribe((data) => {
          if (data != null && data != undefined) {
            this.reportViewertemp = data["ResponseData"].ActualName;

            ///this.reportViewerHeader=this.reportViewerHeader.replace("<<Email>>",this.orderNo).replace("<<ORDERNO>>",this.orderNo)
          }
        })
      );
    }
    this.loadReportModule();
    // if (this.selectedReport == null || this.selectedReport == '') {
    //   this.selectedReport = "InvoicewithItinerary 02";
    // }

    this.invoiceFormatItems;
    // var orderno= sessionStorage.getItem('orderNo');
    // if(orderno!=null)
    // if(this.orderNo !==orderno)
    // {
    //   return  this.router.navigate(['PenAir/Home'],{skipLocationChange:true});
    // }
    // this.reportParms=({FolderNo: [this.orderNo]});
    this.subscription.add(
      this.emailapi
        .getFolderFromEmail(this.FolderDetails.orderNo, +this.apiShared.uid)
        .subscribe(data => {
          if (data != null && data != undefined) {
            this.fromId = data["ResponseData"];
          }
        })
    );
    this.subscription.add(this.emailapi.GetReportEmail(+this.apiShared.uid).subscribe(data => {
      if (data != null && data != undefined) {
        if (data.ResponseData != null && data.ResponseData != undefined) {
          //this.fromId = data.ResponseData.FROMEMAIL,
          this.bccId = data.ResponseData.DEFAULTBCCMAIL
        }
      }
    }));
    this.subscription.add(this.lookupApi.GetLookupById('PaymentGatewayPassenger', "''", this.FolderDetails.orderNo.toString()).subscribe(
      (data) => {
        if (data != null && data != undefined && data["ResponseData"] != null) {
          if (data["ResponseData"].length > 0)
            this.PassSurname = data["ResponseData"][0].UsrCode.replace(" ", "%20")
        }
      }
    ));
  }
  loadReportModule() {
    window.scrollTo(0, 0);
    if (this.ReportSelected != null && this.ReportSelected != '') {
      this.selectedReport = this.ReportSelected;
      this.rptopen();
    } else if (this.defaultReportSelected != null && this.defaultReportSelected != '') {
      this.showrptlist = true;
      this.invoiceFormatItems.forEach(element => {
        if (element == this.defaultReportSelected) {
          this.selectedReport = this.defaultReportSelected;
        }
      });
    } else {
      this.showrptlist = true;
    }
  }
  InvoiceFormatChange(value: any) {
    // debugger;
    if (this.selectedReport == "InvoicewithItinerary 02") {
      this.subscription.add(this.lookupApi.GetAllLookup('ViewtripSettings').subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            if (data["ResponseData"][0].Code == 1) {
              this.enablepayment = 1;
            } else {
              this.enablepayment = 0;
            }
          }
        }));
    }
  }
  public async rptopen() {
    // debugger;

    this.rptopened = false;
    // this.atolopened = false;
    this.crOpened = false;

    this.windowState = "maximized";
    if (this.selectedReport != "") {
      if (
        this.reportViewertemp != null &&
        this.reportViewertemp != "" &&
        this.reportViewertemp != undefined
      )
        this.reportViewerHeader = this.reportViewertemp.replace(
          "<<MAILFORMAT>>",
          this.selectedReport
        );
      else this.reportViewerHeader = this.selectedReport;

      switch (this.selectedReport) {
        case 'PackageItinerary7': {
          this.reportParms = ({ Id: 1, FolderNo: [this.FolderDetails.orderNo], RtftoHtmlUrl: [this.env.RtftoHtmlUrl], envpath: [this.env.baseUrl.toString()], UserId: this.apiShared.uid });
          this.reportFile = "PackageItenaryreport.trdp";
          this.rptopened = true;
          this.reportHeader = 'Package Invoice Report-7'
          this.reportSubject = 'Package Invoice Report-7'
          this.opened = false;
          this.rptType = "Invoice";
          break;
        }
        case 'PackageItinerary8': {
          this.reportParms = ({ Id: 1, FolderNo: [this.FolderDetails.orderNo], RtftoHtmlUrl: [this.env.RtftoHtmlUrl], envpath: [this.env.baseUrl.toString()], UserId: this.apiShared.uid });
          this.reportFile = "PackageItenaryreport8.trdp";
          this.rptopened = true;
          this.reportHeader = 'Package Invoice Report-8'
          this.reportSubject = 'Package Invoice Report-8'
          this.opened = false;
          this.rptType = "Invoice";
          break;
        }
        // by chithra for case id:13814
        case 'PackageItinerary5': {
          this.spinner.show();
          this.api.CrRptformData = new DocumentEmailDTO;
          this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
          this.api.CrRptformData.ReportType = "PackageItinerary5";
          this.api.CrRptformData.rptfilename = "PackageItinerary";
          this.api.CrRptformData.ReportName = "PackageItinerary5";
          this.showCrystalReport("Booking confirmation");
          break;
        } // by chithra for case id:13814
        // by chithra for case id:14426
        case 'Itineraryonly': {
          this.spinner.show();
          this.api.CrRptformData = new DocumentEmailDTO;
          this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
          this.api.CrRptformData.ReportType = "Itineraryonly";
          this.api.CrRptformData.rptfilename = "Itineraryonly";
          this.api.CrRptformData.ReportName = "Itineraryonly";
          this.showCrystalReport("Booking confirmation");
          break;
        } // by chithra for case id:14426
        //13771 Tour Voucher
        case 'Tour Voucher': {
          this.spinner.show();

          // this.api.CrRptformData = new DocumentEmailDTO;
          // this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
          // this.api.CrRptformData.ReportType = "TourVoucher";
          // this.api.CrRptformData.rptfilename = "TourVoucher";
          // this.api.CrRptformData.ReportName = "TourVoucher";
          // this.showCrystalReport("Tour Voucher");
          var ObjParam = {
            orderNo: this.FolderDetails.orderNo,
            type: 'Tour',
            ReportType: 'TourVoucher',
            rptfilename: 'TourVoucher',
            ReportName: "TourVoucher",
            crystalReportName: "Tour Voucher"
          }
          this.getProductCount(ObjParam);
          break;
        } //13771
        //13998 CustomerRefundRequest
        case 'Customer Refund Request': {
          this.spinner.show();
          this.api.CrRptformData = new DocumentEmailDTO;
          this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
          this.api.CrRptformData.ReportType = "CustomerRefundRequest";
          this.api.CrRptformData.rptfilename = "Customer Refund Request";
          this.api.CrRptformData.ReportName = "CustomerRefundRequest";
          this.showCrystalReport("Customer Refund Request");
          break;
        } //13998

        // // by chithra for case id:14120 InvoicewithItinerary1
        // case 'InvoicewithItinerary1': {
        //   debugger;
        //   // if (this.FolderStatus == "Invoiced") {
        //   if (this.OrderType == "Package") {
        //     debugger;
        //     this.api.CrRptformData = new DocumentEmailDTO;
        //     this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
        //     this.api.CrRptformData.ReportType = "InvoicewithItinerary1_Invoiced";
        //     this.api.CrRptformData.rptfilename = "Packagebookingconfirmation";
        //     this.api.CrRptformData.ReportName = "InvoicewithItinerary1_Invoiced";
        //   }
        //   else {
        //     this.api.CrRptformData = new DocumentEmailDTO;
        //     this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
        //     this.api.CrRptformData.ReportType = "InvoicewithItinerary1";
        //     this.api.CrRptformData.rptfilename = "flightbookingconfirmation";
        //     this.api.CrRptformData.ReportName = "InvoicewithItinerary1";
        //   }
        //   this.spinner.show();         
        //   this.showCrystalReport("InvoicewithItinerary1");
        //   break;

        // } // by chithra for case id:14120
        // by chithra for case id:14440
        case 'InvoicewithItinerary1': {
          // debugger;
          if (this.OrderType == "Package") {
            // debugger;
            if (this.FolderDetails.folderstatus == "Saved" || this.FolderDetails.folderstatus == "Refund Request") {
              this.api.CrRptformData = new DocumentEmailDTO;
              this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
              this.api.CrRptformData.ReportType = "InvoicewithItinerary1";
              this.api.CrRptformData.rptfilename = "Packagebookingconfirmation";
              this.api.CrRptformData.ReportName = "InvoicewithItinerary1_Package";
            }
            else {
              this.api.CrRptformData = new DocumentEmailDTO;
              this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
              this.api.CrRptformData.ReportType = "InvoicewithItinerary1";
              this.api.CrRptformData.rptfilename = "Invoice5";
              this.api.CrRptformData.ReportName = "InvoicewithItinerary1_Package_Invoiced";
            }
          }
          else {
            if (this.FolderDetails.folderstatus == "Saved" || this.FolderDetails.folderstatus == "Refund Request") {
              this.api.CrRptformData = new DocumentEmailDTO;
              this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
              this.api.CrRptformData.ReportType = "InvoicewithItinerary1";
              this.api.CrRptformData.rptfilename = "flightbookingconfirmation";
              this.api.CrRptformData.ReportName = "InvoicewithItinerary1";
            }
            else {
              this.api.CrRptformData = new DocumentEmailDTO;
              this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
              this.api.CrRptformData.ReportType = "InvoicewithItinerary1";
              this.api.CrRptformData.rptfilename = "Invoice1";
              this.api.CrRptformData.ReportName = "InvoicewithItinerary1_Invoiced";
            }
          }
          this.spinner.show();
          this.showCrystalReport("InvoicewithItinerary1");
          break;

        }

        // by chithra for case id:14294 InvoicewithItinerary 02 Canada
        case 'InvoicewithItinerary 02 Canada': {
          // debugger;
          if (this.OrderType == "Package") {
            // debugger;
            if (this.FolderDetails.folderstatus == "Saved" || this.FolderDetails.folderstatus == "Refund Request") {
              this.api.CrRptformData = new DocumentEmailDTO;
              this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
              this.api.CrRptformData.ReportType = "InvoicewithItinerary 02 Canada EN";
              this.api.CrRptformData.rptfilename = "Packagebookingconfirmation";
              this.api.CrRptformData.ReportName = "InvoicewithItinerary 02 Canada EN_Package";
            }
            else {
              this.api.CrRptformData = new DocumentEmailDTO;
              this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
              this.api.CrRptformData.ReportType = "InvoicewithItinerary 02 Canada EN";
              this.api.CrRptformData.rptfilename = "Invoice5";
              this.api.CrRptformData.ReportName = "InvoicewithItinerary 02 Canada EN_Package_Invoiced";
            }
          }
          else {
            if (this.FolderDetails.folderstatus == "Saved" || this.FolderDetails.folderstatus == "Refund Request") {
              this.api.CrRptformData = new DocumentEmailDTO;
              this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
              this.api.CrRptformData.ReportType = "InvoicewithItinerary 02 Canada EN";
              this.api.CrRptformData.rptfilename = "flightbookingconfirmation";
              this.api.CrRptformData.ReportName = "InvoicewithItinerary 02 Canada EN";
            }
            else {
              this.api.CrRptformData = new DocumentEmailDTO;
              this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
              this.api.CrRptformData.ReportType = "InvoicewithItinerary 02 Canada EN";
              this.api.CrRptformData.rptfilename = "Invoice1";
              this.api.CrRptformData.ReportName = "InvoicewithItinerary 02 Canada EN_Invoiced";
            }
          }
          this.spinner.show();
          this.showCrystalReport("InvoicewithItinerary 02 Canada");
          break;
        } // by chithra for case id:14294 InvoicewithItinerary 02 Canada
        //by chithra for case id:14322 Cancellation Document 
        case 'Cancellation Document':
          {
            if (this.OrderType == "Package") {
              if (this.FolderDetails.folderstatus == "Saved" || this.FolderDetails.folderstatus == "Refund Request") {
                this.api.CrRptformData = new DocumentEmailDTO;
                this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
                this.api.CrRptformData.ReportType = "InvoicewithItinerary 02 Canada EN";
                this.api.CrRptformData.rptfilename = "Packagebookingconfirmation";
                this.api.CrRptformData.ReportName = "Cancellation Document_Package";
              }
              else {
                this.api.CrRptformData = new DocumentEmailDTO;
                this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
                this.api.CrRptformData.ReportType = "InvoicewithItinerary 02 Canada EN";
                this.api.CrRptformData.rptfilename = "Invoice5";
                this.api.CrRptformData.ReportName = "Cancellation Document_Package_Invoiced";
              }
            }
            else {
              if (this.FolderDetails.folderstatus == "Saved" || this.FolderDetails.folderstatus == "Refund Request") {
                this.api.CrRptformData = new DocumentEmailDTO;
                this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
                this.api.CrRptformData.ReportType = "InvoicewithItinerary 02 Canada EN";
                this.api.CrRptformData.rptfilename = "flightbookingconfirmation";
                this.api.CrRptformData.ReportName = "Cancellation Document";
              }
              else {
                this.api.CrRptformData = new DocumentEmailDTO;
                this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
                this.api.CrRptformData.ReportType = "InvoicewithItinerary 02 Canada EN";
                this.api.CrRptformData.rptfilename = "Invoice1";
                this.api.CrRptformData.ReportName = "Cancellation Document_Invoiced";
              }
            }
            this.spinner.show();
            this.showCrystalReport("Cancellation Document");
            break;
          }
        // //by chithra for case id:14322 Cancellation Document 

        // by chithra for case id:13786 Transfer Voucher
        case 'Transfer Voucher': {
          this.spinner.show();
          // this.api.CrRptformData = new DocumentEmailDTO;
          // this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
          // this.api.CrRptformData.ReportType = "TransferVoucher";
          // this.api.CrRptformData.rptfilename = "TransferVoucher";
          // this.api.CrRptformData.ReportName = "TransferVoucher";
          // this.showCrystalReport("Transfer Voucher");
          var ObjParam = {
            orderNo: this.FolderDetails.orderNo,
            type: 'Transfer',
            ReportType: 'TransferVoucher',
            rptfilename: 'TransferVoucher',
            ReportName: "TransferVoucher",
            crystalReportName: "Transfer Voucher"
          }
          this.getProductCount(ObjParam);
          break;
        } // by chithra for case id:13786
        // by chithra for case id:13798 Car Voucher
        case 'Car Voucher': {
          // debugger;
          this.spinner.show();
          //   this.GetCarCount(this.FolderDetails.orderNo);
          var ObjParam = {
            orderNo: this.FolderDetails.orderNo,
            type: 'Car',
            ReportType: 'CarVoucher',
            rptfilename: 'CarVoucher',
            ReportName: "CarVoucher",
            crystalReportName: "Car Voucher"
          }
          this.getProductCount(ObjParam);
          break;
        } // by chithra for case id:13798
        // by chithra for case id:13797
        case 'Hotel Voucher': {
          this.spinner.show();
          // this.api.CrRptformData = new DocumentEmailDTO;
          // this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
          // this.api.CrRptformData.ReportType = "HotelVoucher";
          // this.api.CrRptformData.rptfilename = "HotelVoucher";
          // this.api.CrRptformData.ReportName = "HotelVoucher";
          // this.showCrystalReport("Hotel Voucher");
          var ObjParam = {
            orderNo: this.FolderDetails.orderNo,
            type: 'Hotel',
            ReportType: 'HotelVoucher',
            rptfilename: 'HotelVoucher',
            ReportName: "HotelVoucher",
            crystalReportName: "Hotel Voucher"
          }
          this.getProductCount(ObjParam);
          break;
        } // by chithra for case id:13797
        // by chithra for case id:13891
        case 'Invoiceonly': {
          this.spinner.show();
          this.api.CrRptformData = new DocumentEmailDTO;
          this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
          this.api.CrRptformData.ReportType = "Invoiceonly";
          this.api.CrRptformData.rptfilename = "flightbookingconfirmation";
          this.api.CrRptformData.ReportName = "Invoiceonly";
          this.showCrystalReport("Invoice Only");
          break;
        } // by chithra for case id:13891
        // case 'Accommodation Voucher': {
        //   this.reportSubject = this.selectedReport;
        //   this.count = 0;
        //   this.rptType = "Invoice";
        //   this.GetHotelCount(this.FolderDetails.orderNo);
        //   break;
        // }
        //caseid:14461
        case 'Accommodation Voucher': {
          // debugger;
          this.spinner.show();
          var ObjParam = {
            orderNo: this.FolderDetails.orderNo,
            type: 'Accomodation',
            ReportType: 'AccommodationVoucher',
            rptfilename: 'AccommodationVoucher',
            ReportName: "AccommodationVoucher",
            crystalReportName: "AccommodationVoucher"
          }
          this.getProductCount(ObjParam);
          break;
        }
        case 'E-Ticket': {
          this.spinner.show();
          this.api.CrRptformData = new DocumentEmailDTO;
          this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
          this.api.CrRptformData.ReportType = "E-Ticket";
          this.api.CrRptformData.rptfilename = "E-Ticket";
          this.api.CrRptformData.ReportName = "E-Ticket";
          this.showCrystalReport("E-Ticket");
          break;
        }
        //by chithra for case id- 14093 InsuranceReclaimDocument
        case 'Insurance Reclaim Document': {
          this.spinner.show();
          this.api.CrRptformData = new DocumentEmailDTO;
          this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
          this.api.CrRptformData.ReportType = "InsuranceReclaimDocument";
          this.api.CrRptformData.rptfilename = "flightbookingconfirmation";
          this.api.CrRptformData.ReportName = "InsuranceReclaimDocument";
          this.showCrystalReport("Insurance Reclaim Document");
          break;
        } //by chithra for case id- 14093 InsuranceReclaimDocument
        case 'Trans Summary': {
          this.reportSubject = this.selectedReport;
          this.reportParms = ({
            FolderNo: this.FolderDetails.orderNo
            , UserId: this.apiShared.uid
            , BranchName: this.FolderDetails.brName
          });
          this.reportHeader = 'Folder Transaction Summary Report';
          this.reportFile = "FolderTransactionSummaryReport.trdp";
          this.rptopened = true;
          this.opened = false;
          break;
        }
        case 'ReceiptPrinting': {
          this.reportSubject = this.selectedReport;
          this.reportParms = ({
            FolderNo: this.FolderDetails.orderNo
            , UserId: this.apiShared.uid
            , Brid: this.apiShared.BRID,
            Currency: this.apiShared.homeCurrency
          });
          this.reportHeader = 'Reciept Print';
          this.rptType = "Invoice";
          this.reportFile = "RecieptPrinting.trdp";
          this.reportViewerHeader = "Reciept Print Folder:" + this.FolderDetails.projNo + " " + "Order:" + this.FolderDetails.orderNo;
          this.rptopened = true;
          break;
        }
        case 'Booking Confirmation': {
          this.spinner.show();
          this.api.CrRptformData = new DocumentEmailDTO;
          this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
          this.api.CrRptformData.ReportType = "BookingItinerary";
          this.api.CrRptformData.rptfilename = "BookingItinerary";
          this.api.CrRptformData.ReportName = "BookingItinerary";
          this.showCrystalReport("Booking confirmation");
          break;
        }
        case 'Service Invoice':
          {
            this.spinner.show();
            this.api.CrRptformData = new DocumentEmailDTO;
            this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
            this.api.CrRptformData.ReportType = "ServiceInvoice";
            this.api.CrRptformData.rptfilename = "flightbookingconfirmation";
            this.api.CrRptformData.ReportName = "ServiceInvoice";
            this.langSelected = this.apiShared.LanguageSelected;
            if (this.langSelected == "English") {
              this.api.CrRptformData.LanId = "EN";

            } else if (this.langSelected == "Chinese") {
              this.api.CrRptformData.LanId = "ZH";
            } else if (this.langSelected == "French") {
              this.api.CrRptformData.LanId = "FR";
            }
            this.showCrystalReport("Service Invoice");
            break;
          }
        case 'InvoicewithItinerary 02': {
          this.spinner.show();
          this.api.CrRptformData = new DocumentEmailDTO;
          this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
          this.api.CrRptformData.ReportType = "InvoicewithItinerary02";
          this.api.CrRptformData.rptfilename = "flightbookingconfirmation";
          this.api.CrRptformData.ReportName = "InvoicewithItinerary02";
          this.langSelected = this.apiShared.LanguageSelected;
          if (this.langSelected == "English") {
            this.api.CrRptformData.LanId = "EN";

          } else if (this.langSelected == "Chinese") {
            this.api.CrRptformData.LanId = "ZH";
          } else if (this.langSelected == "French") {
            this.api.CrRptformData.LanId = "FR";
          }
          this.showCrystalReport("Booking confirmation");
          break;
        }
        //Caseid 14077
        case 'CorporateInvoice02': {
          var result = <any>await this.getReportSettings();
          this.reportParms = ({
            Id: 1, FolderNo: [this.FolderDetails.orderNo], RtftoHtmlUrl: [this.env.RtftoHtmlUrl],
            UserId: this.apiShared.uid, PaymentUrl: result.parPayNow,
            ShowAGTCOMONINV: result.ShowAGTCOMONINV, INVwAirTax: result.INVwAirTax
          });
          this.reportFile = "CorporateInvoice02.trdp";
          this.rptopened = true;
          this.reportHeader = 'CorporateInvoice-2'
          this.reportSubject = 'CorporateInvoice-2'
          this.opened = false;
          this.rptType = "Invoice";
          break;
        }
        //Caseid 14077
        //Caseid 14115
        case 'InvoicewithItinerary 02_Signature': {
          this.spinner.show();
          this.api.CrRptformData = new DocumentEmailDTO;
          this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
          this.api.CrRptformData.ReportType = "InvoicewithItinerary02_Signature";
          this.api.CrRptformData.rptfilename = "InvoicewithItinerary02_Signature";
          this.api.CrRptformData.ReportName = "InvoicewithItinerary02_Signature";
          this.langSelected = this.apiShared.LanguageSelected;
          // if (this.langSelected == "English") {
          //   this.api.CrRptformData.LanId = "EN";
          // } else if (this.langSelected == "Chinese") {
          //   this.api.CrRptformData.LanId = "ZH";
          // } else if (this.langSelected == "French") {
          //   this.api.CrRptformData.LanId = "FR";
          // }
          this.showCrystalReport("InvoicewithItinerary02_Signature");
          break;
        }
        default: {
          this.spinner.hide();
          break;
        }
      }
    }
    this.reportSubject = this.reportViewerHeader;
    this.email.filename = this.reportFile.replace("trdp", "pdf");
    this.email.emailsubject = this.reportViewerHeader;

    //  this.GetEmailBofyByFolderno();

  }
  showCrystalReport(RptName) {
    this.subscription.add(this.api.GetCrystalReport()
      .subscribe(res => {
        this.spinner.hide();
        this.crRptName = RptName;
        this.crFileName = res["ResponseData"].rptfilename;
        this.crPdfFilePath = this.pdfurl + res["ResponseData"].Reportpath;
        this.crFilePath = res["ResponseData"].Reportpath;
        this.crDeletePath = res["ResponseData"].Reportfolder
        this.crEmailPath = res["ResponseData"].EmailReportpath;
        this.canndMesgAttachment = res["ResponseData"].cannedAttachment;
        this.crUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.crPdfFilePath);
        this.crOpened = true;
        this.opened = false;
        window.scroll(0, 0);
      }));
  }
  // GetHotelCount(folderno) {
  //   this.subscription.add(this.api.GetHotelCount(folderno)
  //     .subscribe(data => {
  //       //count:data;
  //       if (data == 0) {
  //         //this.common.showFlash1('Messages.no_data_found','bg-danger text-white',2000);
  //         this.subscription.add(this.translateapi.get(['Messages.no_data_found']).subscribe((translations) => { //By Case id-12619
  //           this.showMsg = translations['Messages.no_data_found'];;
  //           this.toastr.error(this.showMsg, "", {
  //             timeOut: 3000,
  //             disableTimeOut: false,
  //             closeButton: true
  //           })
  //         }));
  //         this.count = false;
  //       }
  //       else {
  //         this.reportParms = ({ FolderNo: [this.FolderDetails.orderNo], ReportHeader: "Accommodation Voucher # ", UserId: this.apiShared.uid });
  //         this.reportFile = "AccomoationVoucher.trdp";
  //         this.rptopened = true;
  //         this.opened = false;
  //         this.reportHeader = 'Accommodation Voucher';
  //       }
  //       console.log(this.count)
  //     }));
  // }
  getProductCount(obj) {
    // debugger;
    this.subscription.add(this.api.GetProductCount(obj.orderNo, obj.crystalReportName)
      .subscribe(data => {
        //count:data;
        if (data == 0) {
          this.count = false;
          this.spinner.hide();
          this.subscription.add(this.translateapi.get(['Messages.no_data_found']).subscribe((translations) => {//By Case id-12619     
            this.showMsg = translations['Messages.no_data_found'];;
            this.toastr.error(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
        }
        else {
          this.api.CrRptformData = new DocumentEmailDTO;
          this.api.CrRptformData.OrderNo = obj.orderNo;
          this.api.CrRptformData.ReportType = obj.ReportType;
          this.api.CrRptformData.rptfilename = obj.rptfilename;
          this.api.CrRptformData.ReportName = obj.ReportName;
          this.showCrystalReport(obj.crystalReportName);
        }
        // console.log(this.count)
      }));
  }
  GetCarCount(folderno) {
    this.subscription.add(this.api.GetCarCount(folderno)
      .subscribe(data => {
        //count:data;
        if (data == 0) {
          this.count = false;
          this.spinner.hide();
          this.subscription.add(this.translateapi.get(['Messages.no_data_found']).subscribe((translations) => {//By Case id-12619     
            this.showMsg = translations['Messages.no_data_found'];;
            this.toastr.error(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
        }
        else {
          this.api.CrRptformData = new DocumentEmailDTO;
          this.api.CrRptformData.OrderNo = this.FolderDetails.orderNo;
          this.api.CrRptformData.ReportType = "CarVoucher";
          this.api.CrRptformData.rptfilename = "CarVoucher";
          this.api.CrRptformData.ReportName = "CarVoucher";
          this.showCrystalReport("Car Voucher");
        }
        // console.log(this.count)
      }));
  }
  public rptclose() {
    // debugger;
    this.rptopened = false;
    if (this.selectedReport != "ReceiptPrinting")
      this.opened = true;
    if (this.selectedReport == "ReceiptPrinting")
      this.selectedReport = '';
  }
  public offset1 = 10;
  public rptLeft = this.offset1;
  public rptTop = this.offset1;
  public rptWidth = window.innerWidth - 200;
  public rptHeight = window.innerHeight - 100;
  onrptEnd() {
    // debugger;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const positionTop = windowHeight - this.rptHeight - this.offset1;
    const positionRight = windowWidth - this.rptWidth - this.offset1;

    if (this.rptTop < this.offset1) {
      this.rptTop = this.offset1;
    }

    if (this.rptTop > positionTop) {
      this.rptTop = positionTop;
    }

    if (this.rptLeft < this.offset1) {
      this.rptLeft = this.offset1;
    }

    if (this.rptLeft > positionRight) {
      this.rptLeft = positionRight;
    }
  }
  public onEnd() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const positionTop = windowHeight - this.height - this.offset;
    const positionLeft = windowWidth - this.width - this.offset;

    if (this.windowTop < this.offset) {
      this.windowTop = this.offset;
    }

    if (this.windowTop > positionTop) {
      this.windowTop = positionTop;
    }

    if (this.windowLeft < this.offset) {
      this.windowLeft = this.offset;
    }

    if (this.windowLeft > positionLeft) {
      this.windowLeft = positionLeft;
    }
  }
  public close() {
    // debugger;
    this.closeBookingRpt.emit('true');
  }
  onCancel() {
    this.router.navigate(['PenAir/Folder/FolderSummary', this.FolderDetails.orderNo], { skipLocationChange: true });
  }
  public async getReportSettings(): Promise<any> {
    var result = await this.lookupApi.getReportSettings(this.FolderDetails.orderNo, this.apiShared.uid);
    if (result != undefined && result != null)
      return result["ResponseData"];
    else
      return '';
  }
  public async SendTelerikMail() {
    this.spinner.show();
    await this.email.updateReportParameterDTO(this.selectedReport);
    await this.GetEmailBofyByFolderno();
    this.email.Atolre = true;
  }
  public GetEmailBofyByFolderno() {
    // debugger;
    this.spinner.show();
    if (this.common.formData == undefined)
      this.common.formData = new ReportParameterDTO();
    if (this.selectedReport == "ReceiptPrinting") {
      this.common.formData.p_emailtype = "Receipt";
    }
    else {
      this.common.formData.p_emailtype = "Receipt";
    }
    this.common.formData.attachmentindex = 0;
    this.common.formData.Branch = String(this.apiShared.BRID);
    this.common.formData.RtftoHtmlUrl = this.env.RtftoHtmlUrl;

    var ordernos = this.apiShared.orderNo;
    this.common.formData.trdpfilename = this.selectedReport;
    this.common.formData.FolderNo = String(ordernos);
    this.common.formData.Userid = +this.apiShared.uid;
    this.email.filename = this.selectedReport;
    this.email.emailatolpath = '';
    this.email.emailpath = '';
    if (this.rptAPI.webLink != '') {
      this.common.formData.isWebLink = true;
    }
    this.subscription.add(
      this.common
        .GetReportBodyOrSubject()
        .subscribe((res) => {
          // debugger;
          this.spinner.hide();
          this.email.Fromlist = res["ResponseData"]["Fromdata"]
          this.email.CCEmail = res["ResponseData"]["CCEmail"];
          this.emailopened = true;
          // this.atolopened = false;
          this.common.formData.FolderNo = this.FolderDetails.orderNo;
          this.common.formData.projno = this.FolderDetails.projNo;
          this.common.formData.BCCEmail = res["ResponseData"]["BCCEmail"];
          this.common.formData.BCCEnable = res["ResponseData"]["BCCEnable"];
          this.common.formData.ToEmail = res["ResponseData"]["ToEmail"];
          this.common.formData.EmailBody = res["ResponseData"]["EmailBody"].replace("<<WEBLINK>>", this.rptAPI.webLink);

          if (this.rptAPI.webLink == '') {
            this.common.formData.RecieptEmailBody = res["ResponseData"]["RecieptEmailBody"];
            if (this.FolderDetails.atolattachement == false)
              //this.email.atolfilepath = res["ResponseData"]["Attachementpath"];
              this.email.folderreportfilepath = res["ResponseData"]["Attachementpath"];
          }
          if (res["ResponseData"]["cannedMsgAttachment"] && res["ResponseData"]["cannedMsgAttachment"] != '') {
            this.email.cannedMsgAttachment = res["ResponseData"]["cannedMsgAttachment"];
          } else {
            this.email.cannedMsgAttachment = '';
          }
        }))

  }
  public async crSendMail1() {
    // debugger;
    this.spinner.show();
    if (this.reportViewertemp != null && this.reportViewertemp != "" && this.reportViewertemp != undefined) {
      this.reportViewerHeader = this.reportViewertemp.replace(
        "<<MAILFORMAT>>",
        this.selectedReport
      );
    }
    else {
      this.reportViewerHeader = this.selectedReport;


    }
    await this.email.ComposeMail(this.crRptName, this.crFilePath, this.crEmailPath, this.reportViewerHeader, this.emailbody, true);
    await this.email.updateReportParameterDTO(this.selectedReport);
    await this.email.GetEmailBodyByFolderno();
    await this.subscription.add(
      this.common
        .GetReportBodyOrSubject()
        .subscribe((res) => {
          this.spinner.hide();
          this.email.Fromlist = res["ResponseData"]["Fromdata"]
          this.email.CCEmail = res["ResponseData"]["CCEmail"];
          this.emailopened = true;
          this.common.formData.FolderNo = this.FolderDetails.orderNo;
          this.common.formData.projno = this.FolderDetails.projNo;
          this.common.formData.BCCEmail = res["ResponseData"]["BCCEmail"];
          this.common.formData.BCCEnable = res["ResponseData"]["BCCEnable"];
          this.common.formData.ToEmail = res["ResponseData"]["ToEmail"];
          this.common.formData.EmailBody = res["ResponseData"]["EmailBody"].replace("<<WEBLINK>>", this.rptAPI.webLink);
          if (this.rptAPI.webLink == '') {
            this.common.formData.RecieptEmailBody = res["ResponseData"]["RecieptEmailBody"];

            this.email.folderreportfilepath = res["ResponseData"]["Attachementpath"];
          }
          this.emailopened = true;
        }
        )
    )


  }
  public async crSendMail() {
    this.spinner.show();
    if (this.reportViewertemp != null && this.reportViewertemp != "" && this.reportViewertemp != undefined) {
      this.reportViewerHeader = this.reportViewertemp.replace(
        "<<MAILFORMAT>>", this.selectedReport);
    }
    else {
      this.reportViewerHeader = this.selectedReport;
    }
    await this.email.ComposeMail(this.crRptName, this.crFilePath, this.crEmailPath, this.reportViewerHeader, this.emailbody, true, this.canndMesgAttachment);
    await this.email.updateReportParameterDTO(this.selectedReport);
    await this.email.GetEmailBodyByFolderno();
    await this.subscription.add(
      this.common
        .GetReportBodyOrSubject()
        .subscribe((res) => {
          this.spinner.hide();
          this.email.Fromlist = res["ResponseData"]["Fromdata"]
          this.email.CCEmail = res["ResponseData"]["CCEmail"];
          this.emailopened = true;
          this.common.formData.FolderNo = this.FolderDetails.orderNo;
          this.common.formData.projno = this.FolderDetails.projNo;
          this.common.formData.BCCEmail = res["ResponseData"]["BCCEmail"];
          this.common.formData.BCCEnable = res["ResponseData"]["BCCEnable"];
          this.common.formData.ToEmail = res["ResponseData"]["ToEmail"];
          this.common.formData.EmailBody = res["ResponseData"]["EmailBody"].replace("<<WEBLINK>>", this.rptAPI.webLink);
          if (this.rptAPI.webLink == '') {
            this.common.formData.RecieptEmailBody = res["ResponseData"]["RecieptEmailBody"];
            if (this.FolderDetails.atolattachement == false)
              this.email.folderreportfilepath = res["ResponseData"]["Attachementpath"];
          }
          this.emailopened = true;
        }
        )
    )


  }

  public crClose() {
    this.crOpened = false;
    this.email.emailbody = '';
  }

  public EmailFormClosingatol(status: any)//Email status change by case id-12434
  {
    this.email.Atolre = false;
    this.email.filename = '';
    this.email.atolfilepath = '';
    this.email.emailsubject = '';
    this.email.emailbody = '';
    if (status == "EmailSendSuccesfully" || status == "Closed")//Email status change by case id-12434
    {
      this.emailopened = false;
    }
  }

  public emailclose(status) {
    this.emailopened = false;
    this.email.Atolre = false;
    this.email.filename = '';
    this.email.atolfilepath = '';
    this.email.emailsubject = '';
    this.email.emailbody = '';
    this.email.filename = '';

  }
  ngOnChanges() {
    this.loadReportModule();
  }
}
