
import { Injectable } from '@angular/core';  
import { HttpClient, HttpClientModule } from '@angular/common/http';  

import {  FolderHistory } from '../Models/folder-history';
import { Observable,of } from 'rxjs';
import { HttpHeaders } from "@angular/common/http";
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class FolderHistoryService {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService) { 
    this.apiUrl=env.baseUrl;
  }

  GetFolderHistory(FolderNo:string):Observable<FolderHistory> { 
    const url =  `${this.apiUrl}`+'GetFolderHistoryDetails'+`/${FolderNo}`;// `http://localhost:29048/GetFolderHistoryDetails/10001`;
    
    return this.http.get<FolderHistory>(url);  

  }
}
