import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';

import { Alertdetails } from '../Models/alertdetails';
import { Observable, of } from 'rxjs';
import { HttpHeaders } from "@angular/common/http";
import { catchError, tap, map } from 'rxjs/operators';

import { DatePipe } from '@angular/common';

import { environment } from '../../environments/environment';
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
  translateAggregateResults,
  DataResult,
  DataSourceRequestState,
  orderBy,
  CompositeFilterDescriptor,
  filterBy,
  toDataSourceRequest
} from '@progress/kendo-data-query';


import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { UnlockDocumentListDTO } from '../Models/unlock-document-list-dto.model';
import { EnvService } from '../../env.service';



@Injectable({
  providedIn: 'root'
})
export class UnlockdocumentService {
  public formData: UnlockDocumentListDTO;
  public List: UnlockDocumentListDTO[];
  apiUrl: string;
  constructor(private http: HttpClient, private env: EnvService) {
    this.apiUrl = env.baseUrl;
  }


  GetPenDocUnLockDetails(): Observable<any> {

    const url = `${this.apiUrl}` + 'GetPenDocUnLockDetails';
    return this.http.get<any>(url);

  }


  UpdateFolderMaster(): Observable<UnlockDocumentListDTO> {
    var body = {
      ...this.formData,
      List: this.List,

    };
    return this.http.post<UnlockDocumentListDTO>(this.apiUrl + 'Unlockdocument', body);
    //alert(JSON.stringify(FolderSummary));



  }
  UnlockFolder(List: any): Observable<UnlockDocumentListDTO> {
    var body = {
      ...this.formData,
      List: List,

    };
    return this.http.post<UnlockDocumentListDTO>(this.apiUrl + 'Unlockdocument', body);

  }

}
