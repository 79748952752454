import { Component, OnInit, ViewChild } from '@angular/core';
import { Folderqueryreport } from "../Services/folderqueryreport";
import { FolderQueuryReportDTO } from "../Models/folder-queury-report-dto";
import { Subscription } from "rxjs";
import { CommonserviceService } from "../Services/commonservice.service";
import { LookUpDetailsService } from '../Services/look-up-details.service';
import { ShareDataService } from '../Services/share-data.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LookUpDetails } from '../Services/look-up-details';
import { DomSanitizer } from "@angular/platform-browser";
import { aggregateBy, State, process } from "@progress/kendo-data-query";
import { exportPDF, Group } from "@progress/kendo-drawing";
import { Workbook, ExcelExportData } from "@progress/kendo-angular-excel-export";
import { EmailService } from "../Services/email.service";
import { saveAs } from "@progress/kendo-file-saver";
import { IntlService } from "@progress/kendo-angular-intl";
import { FileService } from "../Services/file.service";
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { PageSizeItem } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-folder-query-report',
  templateUrl: './folder-query-report.component.html',
  styleUrls: ['./folder-query-report.component.scss']
})
// Caseid:13177 PWR_FolderQueryReport
export class FolderQueryReportComponent implements OnInit {
  @ViewChild("pdf", { read: false, static: false }) pdf;
  constructor(
    private lookupApi: LookUpDetailsService,
    public intl: IntlService,
    public common: CommonserviceService,
    public apiShared: ShareDataService,
    public email: EmailService,
    private sanitizer: DomSanitizer,
    public Folderqueryreport: Folderqueryreport,
    private translateapi: TranslateService,
    private toastr: ToastrService,
    private emailapi: FileService,
    private router: Router
  ) { this.allData = this.allData.bind(this); }
  public aggregates: any[] = [

    { field: "BUYAMT", aggregate: "sum" },
    { field: "COMMAMT", aggregate: "sum" },
    { field: "SELLAMT", aggregate: "sum" },
    { field: "SalesWithCC", aggregate: "sum" },
    { field: "NOOFPASS", aggregate: "sum" },

  ];

  public equals: 'equals';
  formData: FolderQueuryReportDTO;
  public opened: any = false;
  private subscription: Subscription = new Subscription();
  // public gridData: any = [];
  virtual: any;
  public showMsg: any;
  userID: any = 0;
  image: any;
  GpPercent = '';
  public state: State = {
    skip: 0,
    take: 20,
    // group: [{ field: "GroupBy" }]
    filter: {
      logic: 'and',
      filters: []
    },
    group: []
  };
  public gridData: GridDataResult = process([], this.state);
  public pageSizes: (PageSizeItem | number)[] = [20, 50, 100, {
    text: 'All',
    value: 'all'
  }];
  public GroupCheckBoolStaff: boolean = false;
  public GroupCheckBoolBranch: boolean = false;
  public GroupCheckBoolBA: boolean = false;
  public GroupCheckBoolInvProduct: boolean = false;
  public GroupCheckBoolProductSold: boolean = false;
  public GroupCheckBoolSupplierGroup: boolean = false;
  public GroupCheckBoolCustomerGroup: boolean = false;
  public GroupCheckBoolDestination: boolean = false;
  public GroupCheckBoolCustomer: boolean = false;
  public GroupCheckBoolMarketingCode: boolean = false;
  public GroupCheckBoolStaffGroup: boolean = false;
  public GroupCheckBoolSupplier: boolean = false;
  public GroupCheckBoolCountry: boolean = false;
  public GroupCheckBoolOrderType: boolean = false;
  public strdest: any;
  public strBa: any;
  public strBr: any;
  public strMc: any;
  public strCustomer: any;
  public strSupplier: any;
  public strAirline: any;
  public strCountry: any;


  public pdftop: any;
  RptHdrSel_TemplateName: any = "";
  RptHdrSel_AutoNo: any = '';
  submitted: any = false;
  public showtotal = false;
  opentemplate: any = false;
  showFilter = false;
  TempList: Array<LookUpDetails["ResponseData"]> = [];
  public TemplateSelectedItem: LookUpDetails["ResponseData"];
  public hidegridbutton: any = false;
  public column: any = "TravelDate,InvDate,DocNo,FolderNo,OrderNo,Customer,Passenger,AIRID,NOOFPASS,BUYAMT,COMMAMT,SELLAMT,SalesWithCC,GPPer,BOOKEDBY,BRID,BAID,Destination,CustGroup,MarketingCode,StaffGroup,Country,OrderType";
  FOLDERNOHidden: any = false;
  FOLDERDATEHidden: any = false;
  CREDITAMTHidden: any = false;
  BUYAMTHidden: any = false;
  COMMAMTHidden: any = false;
  SELLAMTHidden: any = false;
  BOOKEDBYHidden: any = false;
  BAIDHidden: any = false;
  BRIDHidden: any = false;
  INVDATEHidden: any = false;
  CRDATEHidden: any = false;
  NOOFPASSHidden: any = false;

  BRNAMEHidden: any = false;
  BANAMEHidden: any = false;
  PMNAMEHidden: any = false;
  GRPIDHidden: any = false;
  GRPNAMEHidden: any = false;
  CUSTUSRCODEHidden: any = false;
  CUSTNAMEHidden: any = false;

  LASTNAMEHidden: any = false;
  INVOICENOHidden: any = false;
  AIRIDHidden: any = false;

  CountryNameHidden: any = false;

  RETURNDATEHidden: any = false;
  OrderTypeHidden: any = false;
  OrderNoHidden: any = false;
  BranchHidden: any = false;
  BusinessAreaHidden: any = false;
  DestinationHidden: any = false;
  MarketingCodeHidden: any = false;
  StaffGroupHidden: any = false;
  CountryHidden: any = false;
  GPPercentHidden: any = false;
  SalesWithCCHidden: any = false;
  CustGroupHidden: any = false;
  duplicatetemp: any = false;

  selectAll: any = false;
  FOLDERNOChk: any = false;
  FOLDERDATEChk: any = false;
  CREDITAMTChk: any = false;
  BUYAMTChk: any = false;
  COMMAMTChk: any = false;
  SELLAMTChk: any = false;
  PROJNOChk: any = false;
  STATUSChk: any = false;
  BOOKEDBYChk: any = false;
  BAIDChk: any = false;
  BRIDChk: any = false;
  INVDATEChk: any = false;
  CRDATEChk: any = false;
  NOOFPASSChk: any = false;
  TotalCOMMChk: any = false;
  CUSTNAMEChk: any = false;
  LASTNAMEChk: any = false;
  INVOICENOChk: any = false;
  AIRIDChk: any = false;
  CITYNAMEChk: any = false;
  SUPPUSRCODEChk: any = false;
  SUPPNAMEChk: any = false;
  CountryNameChk: any = false;
  CIDChk: any = false;
  QCONDATEChk: any = false;
  RETURNDATEChk: any = false;
  OrderTypeChk: any = false;
  OrderNoChk: any = false;
  BranchChk: any = false;
  BusinessAreaChk: any = false;
  DestinationChk: any = false;
  MarketingCodeChk: any = false;
  StaffGroupChk: any = false;
  CountryChk: any = false;
  CustGroupChk: any = false;
  DocNoChk: any = false;
  SalesWithCCChk: any = false;
  GPPerChk: any = false;
  public FolderQueryList: any;
  public total: any;
  defaultItem: any;
  fromId: string;
  bccId: string;
  actionsLayout: any;

  GP: number = 0;
  ProfitPerFromEnbleflag: boolean = false;
  ProfitFromEnbleflag: boolean = false;
  scale = 1;
  ngOnInit(): void {
    debugger;
    this.email.Isgridpdf = false;
    this.userID = +this.apiShared.uid;
    this.formData = this.Folderqueryreport.formData;

    if (this.formData.ProfitPerFrom == "" || this.formData.ProfitPerFrom == null || this.formData.ProfitPerFrom == undefined) {

      if (this.formData.ProfitPerFrom == "") {
        this.ProfitPerFromEnbleflag = false;
      }
      else {
        this.ProfitPerFromEnbleflag = true;
      }
    }

    else {
      this.ProfitPerFromEnbleflag = true;
    }
    if (this.formData.ProfitFrom == '' || this.formData.ProfitFrom == null || this.formData.ProfitFrom == undefined) {
      if (this.formData.ProfitFrom == '0') {
        this.ProfitFromEnbleflag = true;
      }
      else {
        this.ProfitFromEnbleflag = false;
      }
    }

    else {
      this.ProfitFromEnbleflag = true;
    }

    this.subscription.add(
      this.emailapi.GetReportEmail(this.userID).subscribe(data => {
        (this.fromId = data.ResponseData.FROMEMAIL),
          (this.bccId = data.ResponseData.DEFAULTBCCMAIL);
      })
    );

    this.Folderqueryreport.formDataHeader.RptHdrSel_UserId = this.userID;
    this.Folderqueryreport.formDataHeader.RptHdrSel_ModuleName =
      "FolderQueryReport";
    // this.LoadDefaultTemplate();
    this.LoadTemplate();


    if (this.Folderqueryreport.griddata.length > 0)
      this.showtotal = true;
    else this.showtotal = false;


    this.gridData = this.Folderqueryreport.griddata;
    console.log(JSON.stringify(this.gridData))

    this.FolderQueryList = this.gridData;
    debugger;
    this.Folderqueryreport.griddata.forEach(item => {
      item.TravelDate = item.TravelDate != '' ? new Date(item.TravelDate) : '';
      item.InvDate = item.InvDate != '' ? new Date(item.InvDate) : '';

    });
    this.state.group = [{ field: "BOOKEDBY", aggregates: this.aggregates }];

    this.gridData = process(this.FolderQueryList, this.state);

    if (this.FolderQueryList != null && this.FolderQueryList.length > 0) {
      debugger;
      this.total = aggregateBy(this.FolderQueryList, this.aggregates);
    }

    if (this.Folderqueryreport.formData.ExportPDFParam.length != undefined) {
      debugger;
      if (this.Folderqueryreport.formData.ExportPDFParam.length > 0) {
        if (this.Folderqueryreport.formData.ExportPDFParam.length <= 5) {
          this.pdftop = "3cm";
        }
        else {
          this.pdftop = (this.Folderqueryreport.formData.ExportPDFParam.length / 5) + "cm";
        }
      }
    }

    if (this.formData.Destination != "" && this.formData.Destination != "{MULTISELECT}") {
      this.strdest = this.Folderqueryreport.formData.Destination;
    }
    else if (this.formData.BusinessArea != "" && this.formData.BusinessArea != "{MULTISELECT}") {
      this.strBa = this.Folderqueryreport.formData.BusinessArea;
    }
    else if (this.formData.BranchName != "" && this.formData.BranchName != "{MULTISELECT}") {
      this.strBr = this.Folderqueryreport.formData.BranchName;
    }
    else if (this.formData.MarkettingCode != "" && this.formData.MarkettingCode != "{MULTISELECT}") {
      this.strMc = this.Folderqueryreport.formData.MarkettingCode
    }
    else if (this.formData.CustomerName != "" && this.formData.CustomerName != "{MULTISELECT}") {
      this.strCustomer = this.Folderqueryreport.formData.CustomerName;
    }
    else if (this.formData.Supplier != "" && this.formData.Supplier != "{MULTISELECT}") {
      this.strSupplier = this.Folderqueryreport.formData.Supplier;
    }
    else if (this.formData.VairlineAIRID != "" && this.formData.VairlineAIRID != "{MULTISELECT}") {
      this.strAirline = this.Folderqueryreport.formData.VairlineAIRID;
    }

    else if (this.formData.Country_varchar != "" && this.formData.Country_varchar != "{MULTISELECT}") {
      this.strCountry = this.Folderqueryreport.formData.Country_varchar;
    }

    var splitted = this.Folderqueryreport.formData.CrystalRptQuery.split("&&");
    splitted.forEach((obj) => {
      if (obj != "") {
        // if (this.Folderqueryreport.formData.CrystalRptQuery != null) {
        debugger;

        if (obj.includes("Fld.ARRIVALPT IN") || obj.includes("Fld.ARRIVALPT NOT IN")) {
          this.strdest = (this.Folderqueryreport.formData.Destination == "{MULTISELECT}") ? obj.replace("Fld.ARRIVALPT IN (", "").replaceAll("'", "").replace(")", "").replace("Fld.ARRIVALPT NOT IN (", "not in ") : this.Folderqueryreport.formData.Destination;

        }


        else if (obj.includes("Fld.BAID IN") || obj.includes("Fld.BAID NOT IN")) {
          this.strBa = (this.Folderqueryreport.formData.BusinessArea == "{MULTISELECT}") ? obj.replace("Fld.BAID IN (", "").replaceAll("'", "").replace(")", "").replace("Fld.BAID NOT IN (", "not in ") : this.Folderqueryreport.formData.BusinessArea;

        }

        else if (obj.includes("Fld.BRID IN") || obj.includes("Fld.BRID NOT IN")) {
          this.strBr = (this.Folderqueryreport.formData.Branch == "{MULTISELECT}") ? obj.replace("Fld.BRID IN (", "").replaceAll("'", "").replace(")", "").replace("Fld.BRID NOT IN (", "not in ") : this.Folderqueryreport.formData.Branch;

        }

        else if (this.Folderqueryreport.formData.CrystalRptQuery.includes("Fld.MCID IN") || this.Folderqueryreport.formData.CrystalRptQuery.includes("Fld.MCID NOT IN")) {
          this.strMc = (this.Folderqueryreport.formData.CrystalRptQuery.MarkettingCode == "{MULTISELECT}") ? this.Folderqueryreport.formData.CrystalRptQuery.replace("Fld.MCID IN (", "").replaceAll("'", "").replace(")", "").replace("Fld.MCID NOT IN (", "not in ") : this.Folderqueryreport.formData.CrystalRptQuery.MarkettingCode;

        }


        else if (this.Folderqueryreport.formData.CrystalRptQuery.includes("Fld.PRTYCODE IN") || this.Folderqueryreport.formData.CrystalRptQuery.includes("Fld.PRTYCODE NOT IN")) {
          this.strCustomer = (this.Folderqueryreport.formData.CrystalRptQuery.CustomerName == "{MULTISELECT}") ? this.Folderqueryreport.formData.CrystalRptQuery.replace("Fld.PRTYCODE IN ", "").replaceAll("'", "").replace("Fld.PRTYCODE NOT IN (", "not in ") : this.Folderqueryreport.formData.CrystalRptQuery.CustomerName;


        }

        else if (this.Folderqueryreport.formData.CrystalRptQuery.includes("FldPrice.SUPPPRTYCODE IN") || this.Folderqueryreport.formData.CrystalRptQuery.includes("FldPrice.SUPPPRTYCODE NOT IN")) {
          this.strSupplier = (this.Folderqueryreport.formData.CrystalRptQuery.Supplier == "{MULTISELECT}") ? this.Folderqueryreport.formData.CrystalRptQuery.replace("Fld.PRTYCODE IN (", "").replaceAll("'", "").replace(")", "").replace("Fld.PRTYCODE NOT IN (", "not in ") : this.Folderqueryreport.formData.CrystalRptQuery.Supplier;

        }

        else if (this.Folderqueryreport.formData.CrystalRptQuery.includes("Fld.VairlineAIRID IN") || this.Folderqueryreport.formData.CrystalRptQuery.includes("Fld.VairlineAIRID NOT IN")) {
          this.strAirline = (this.Folderqueryreport.formData.CrystalRptQuery.VairlineAIRID == "{MULTISELECT}") ? this.Folderqueryreport.formData.CrystalRptQuery.replace("Fld.VairlineAIRID IN (", "").replaceAll("'", "").replace(")", "").replace("Fld.VairlineAIRID NOT IN (", "not in ") : this.Folderqueryreport.formData.CrystalRptQuery.VairlineAIRID;

        }

        else if (this.Folderqueryreport.formData.CrystalRptQuery.includes("CITYCODES.CID IN") || this.Folderqueryreport.formData.CrystalRptQuery.includes("city.CID NOT IN")) {
          this.strCountry = (this.Folderqueryreport.formData.CrystalRptQuery.Country_varchar == "{MULTISELECT}") ? this.Folderqueryreport.formData.CrystalRptQuery.replace("CITYCODES.CID IN (", "").replaceAll("'", "").replace(")", "").replace("CITYCODES.CID NOT IN (", "not in ") : this.Folderqueryreport.formData.CrystalRptQuery.Country_varchar;

        }

      }


    });


    sessionStorage.setItem('Componentname', "FolderQueryReport");
  }


  public LoadTemplate() {
    debugger;
    this.userID = +this.apiShared.uid;
    this.Folderqueryreport.formDataHeader.RptHdrSel_UserId = this.userID;
    this.subscription.add(
      this.Folderqueryreport.GetTemplate().subscribe(data => {
        if (data != null && data != undefined) {
          this.TempList = data["ResponseData"];
          if (this.Folderqueryreport.formDataHeader.RptHdrSel_AutoNo > 0) {
            this.TemplateSelectedItem = this.TempList.find(
              x =>
                x.UsrCode ==
                this.Folderqueryreport.formDataHeader.RptHdrSel_AutoNo
            );

          }
          else {
            debugger;
            if (this.TempList != null) {
              var length = this.TempList.length;

              this.TemplateSelectedItem = this.TempList[length - 1];
            }

          }
          this.templateChange(this.TemplateSelectedItem);
        }
        // else {
        //   this.LoadDefaultTemplate();
        // }

      })
    );
  }

  public GetReportHeaderFooter() {
    this.subscription.add(
      this.Folderqueryreport
        .GetReportHeaderFooter(this.userID, 0)
        .subscribe(data => {
          debugger;
          let objectURL = "data:image/png;base64," + data["ResponseData"];

          this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.LoadReportColumn();
        })
    );
  }
  public LoadDefaultTemplate() {
    debugger;
    this.userID = +this.apiShared.uid;
    this.Folderqueryreport.formDataHeader.RptHdrSel_UserId = this.userID;
    this.Folderqueryreport.formDataHeader.RptHdrSel_ModuleName =
      "FolderQueryReport";
    this.Folderqueryreport.formDataHeader.RptHdrSel_TemplateName = "DefaultTemplate";
    this.Folderqueryreport.formDataHeader.RptHdrSel_ColumnHeader = this.column;
    this.selectAll = true;
    this.subscription.add(
      this.Folderqueryreport.saveOrUpdateReportHeader().subscribe(data => {
        debugger;
        if (data != undefined && data != null) {
          if (data["ResponseData"]["RptHdrSel_AutoNo"] > 0) {
            this.Folderqueryreport.formDataHeader.RptHdrSel_AutoNo =
              data["ResponseData"]["RptHdrSel_AutoNo"];
            this.LoadTemplate();
            this.LoadReportColumn();
            this.opentemplate = false;
            this.duplicatetemp = false;
          }
          //  else {
          //   this.duplicatetemp = true;
          // }
        }
      })
    );
  }
  public LoadReportColumn() {
    debugger;
    this.Folderqueryreport.formDataHeader.RptHdrSel_UserId = this.userID;
    this.Folderqueryreport.formDataHeader.RptHdrSel_ModuleName =
      "FolderQueryReport";
    this.subscription.add(
      this.Folderqueryreport.GetGridColumn().subscribe(data => {
        debugger;
        if (
          data != undefined &&
          data != null &&
          data["ResponseData"] != undefined
        ) {
          this.TemplateSelectedItem = this.TempList.find(
            x => x.UsrCode == data["ResponseData"].RptHdrSel_AutoNo
          );

          if (data["ResponseData"].RptHdrSel_ColumnHeader != null) {
            var datas = data["ResponseData"].RptHdrSel_ColumnHeader.split(",");

            if (datas.length > 12) {
              this.scale = .25;
            }
            else {
              this.scale = .6;
            }
            if (datas.includes("TravelDate")) {
              this.FOLDERDATEHidden = false;
            } else {
              this.FOLDERDATEHidden = true;
            }
            if (datas.includes("InvDate")) {
              this.INVDATEHidden = false;
            } else {
              this.INVDATEHidden = true;
            }
            if (datas.includes("DocNo")) {
              this.INVOICENOHidden = false;
            } else {
              this.INVOICENOHidden = true;
            }
            if (datas.includes("OrderNo")) {
              this.OrderNoHidden = false;
            } else {
              this.OrderNoHidden = true;
            }
            if (datas.includes("FolderNo")) {
              this.FOLDERNOHidden = false;
            } else {
              this.FOLDERNOHidden = true;
            }

            if (datas.includes("BUYAMT")) {
              this.BUYAMTHidden = false;
            } else {
              this.BUYAMTHidden = true;
            }
            if (datas.includes("COMMAMT")) {
              this.COMMAMTHidden = false;
            } else {
              this.COMMAMTHidden = true;
            }
            if (datas.includes("SELLAMT")) {
              this.SELLAMTHidden = false;
            } else {
              this.SELLAMTHidden = true;
            }
            if (datas.includes("Customer")) {
              this.CUSTNAMEHidden = false;
            } else {
              this.CUSTNAMEHidden = true;
            }

            if (datas.includes("Passenger")) {
              this.LASTNAMEHidden = false;
            } else {
              this.LASTNAMEHidden = true;
            }

            if (datas.includes("AIRID")) {
              this.AIRIDHidden = false;
            } else {
              this.AIRIDHidden = true;
            }



            if (datas.includes("NOOFPASS")) {
              this.NOOFPASSHidden = false;
            } else {
              this.NOOFPASSHidden = true;
            }


            if (datas.includes("BOOKEDBY")) {
              this.BOOKEDBYHidden = false;
            } else {
              this.BOOKEDBYHidden = true;
            }
            if (datas.includes("SalesWithCC")) {
              this.SalesWithCCHidden = false;
            } else {
              this.SalesWithCCHidden = true;
            }

            if (datas.includes("GPPer")) {
              this.GPPercentHidden = false;
            } else {
              this.GPPercentHidden = true;
            }

            if (datas.includes("BRID")) {
              this.BranchHidden = false;
            } else {
              this.BranchHidden = true;
            }
            if (datas.includes("BAID")) {
              this.BusinessAreaHidden = false;
            } else {
              this.BusinessAreaHidden = true;
            }
            if (datas.includes("Destination")) {
              this.DestinationHidden = false;
            } else {
              this.DestinationHidden = true;
            }
            if (datas.includes("MarketingCode")) {
              this.MarketingCodeHidden = false;
            } else {
              this.MarketingCodeHidden = true;
            }
            if (datas.includes("StaffGroup")) {
              this.StaffGroupHidden = false;
            } else {
              this.StaffGroupHidden = true;
            }
            if (datas.includes("Country")) {
              this.CountryHidden = false;
            } else {
              this.CountryHidden = true;
            }
            if (datas.includes("CustGroup")) {
              this.CustGroupHidden = false;
            } else {
              this.CustGroupHidden = true;
            }
            if (datas.includes("OrderType")) {
              this.OrderTypeHidden = false;
            } else {
              this.OrderTypeHidden = true;
            }
          }
        } else {
          this.enableAllColumn();
        }
      })
    );
  }
  public enableAllColumn() {
    this.FOLDERDATEHidden = false;
    this.FOLDERNOHidden = false;
    this.INVDATEHidden = false;
    this.BUYAMTHidden = false;
    this.COMMAMTHidden = false;
    this.BOOKEDBYHidden = false;
    this.SELLAMTHidden = false;
    this.NOOFPASSHidden = false;
    this.CUSTNAMEHidden = false;
    this.LASTNAMEHidden = false;
    this.INVOICENOHidden = false;
    this.AIRIDHidden = false;
    this.OrderNoHidden = false;
    this.BranchHidden = false;
    this.BusinessAreaHidden = false;
    this.DestinationHidden = false;
    this.MarketingCodeHidden = false;
    this.StaffGroupHidden = false;
    this.CountryHidden = false;
    this.GPPercentHidden = false;
    this.SalesWithCCHidden = false;
    this.CustGroupHidden = false;
    this.OrderTypeHidden = false;
  }
  CreateTemplate() {
    debugger;
    this.FOLDERDATEChk = false;
    this.INVDATEChk = false;
    this.DocNoChk = false;
    this.FOLDERNOChk = false;
    this.BOOKEDBYChk = false;
    this.BUYAMTChk = false;
    this.COMMAMTChk = false;
    this.SELLAMTChk = false;
    this.SalesWithCCChk = false;
    this.CUSTNAMEChk = false;
    this.LASTNAMEChk = false;
    this.AIRIDChk = false;
    this.NOOFPASSChk = false;
    this.OrderNoChk = false;
    this.BRIDChk = false;
    this.BAIDChk = false;
    this.DestinationChk = false;
    this.MarketingCodeChk = false;
    this.StaffGroupChk = false;
    this.CountryChk = false;
    this.GPPerChk = false;
    this.CustGroupChk = false;
    this.OrderTypeChk = false;
    this.selectAll = false;
    this.RptHdrSel_TemplateName = null;
    this.submitted = false;

    this.opentemplate = true;
    this.Folderqueryreport.formDataHeader.RptHdrSel_AutoNo = 0;
    this.duplicatetemp = false;
  }

  public selectAllColumn() {
    debugger;
    if (this.selectAll == true) {
      this.FOLDERDATEChk = true;
      this.INVDATEChk = true;
      this.DocNoChk = true;
      this.FOLDERNOChk = true;
      this.BOOKEDBYChk = true;
      this.BUYAMTChk = true;
      this.COMMAMTChk = true;
      this.SELLAMTChk = true;
      this.SalesWithCCChk = true;
      this.CUSTNAMEChk = true;
      this.LASTNAMEChk = true;
      this.AIRIDChk = true;
      this.NOOFPASSChk = true;
      this.OrderNoChk = true;
      this.BRIDChk = true;
      this.BAIDChk = true;
      this.DestinationChk = true;
      this.MarketingCodeChk = true;
      this.StaffGroupChk = true;
      this.CountryChk = true;
      this.GPPerChk = true;
      this.CustGroupChk = true;
      this.OrderTypeChk = true;
    } else {

      this.FOLDERDATEChk = false;
      this.INVDATEChk = false;
      this.DocNoChk = false;
      this.FOLDERNOChk = false;
      this.BOOKEDBYChk = false;
      this.BUYAMTChk = false;
      this.COMMAMTChk = false;
      this.SELLAMTChk = false;
      this.SalesWithCCChk = false;
      this.CUSTNAMEChk = false;
      this.LASTNAMEChk = false;
      this.AIRIDChk = false;
      this.NOOFPASSChk = false;
      this.OrderNoChk = false;
      this.BRIDChk = false;
      this.BAIDChk = false;
      this.DestinationChk = false;
      this.MarketingCodeChk = false;
      this.StaffGroupChk = false;
      this.CountryChk = false;
      this.GPPerChk = false;
      this.CustGroupChk = false;
      this.OrderTypeChk = false;
    }
  }
  selectAlldisable() {

    if (
      (this.FOLDERDATEChk == true) &&
      (this.FOLDERNOChk == true) &&
      (this.INVDATEChk == true) &&
      (this.DocNoChk == true) &&
      (this.CUSTNAMEChk == true) &&
      (this.LASTNAMEChk == true) &&
      (this.OrderNoChk == true) &&
      (this.BOOKEDBYChk == true) &&
      (this.BUYAMTChk == true) &&
      (this.COMMAMTChk == true) &&
      (this.SELLAMTChk == true) &&
      (this.SalesWithCCChk == true) &&
      (this.NOOFPASSChk == true) &&
      (this.AIRIDChk == true) &&
      (this.BRIDChk == true) &&
      (this.BAIDChk == true) &&
      (this.DestinationChk == true) &&
      (this.MarketingCodeChk == true) &&
      (this.StaffGroupChk == true) &&
      (this.CountryChk == true) &&
      (this.GPPerChk == true) &&
      (this.CustGroupChk == true) &&
      (this.OrderTypeChk == true)) {
      this.selectAll = true;
    } else {
      this.selectAll = false;

    }

  }
  EditTemplate() {
    debugger;
    this.duplicatetemp = false;
    if (this.TemplateSelectedItem == null || this.TemplateSelectedItem == undefined) {
      this.subscription.add(this.translateapi.get(['Messages.template_required']).subscribe((translations) => {

        this.showMsg = translations['Messages.template_required'];;
        this.toastr.error(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })

      }));

      return;
    }
    this.GetVarientTemplateForEdit();

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    debugger;

    if (state && state.group) {
      state.group.map(group => group.aggregates = this.aggregates);
    }
    this.state = state;


    this.gridData = process(this.Folderqueryreport.griddata, this.state);

  }

  public allData(): ExcelExportData {
    var ExcelData = this.FolderQueryList;
    ExcelData.forEach(item => {
      item.TravelDate = item.TravelDate != '' ? this.common.ConvertToLocaleDate(new Date(item.TravelDate)) : '';
      item.InvDate = item.InvDate != '' ? this.common.ConvertToLocaleDate(new Date(item.InvDate)) : '';

    });
    const result: ExcelExportData = {
      data: ExcelData
    };

    return result;
  }

  GetVarientTemplateForEdit() {
    debugger;

    this.Folderqueryreport.GetVarientTemplateForEdit(this.TemplateSelectedItem.Code, this.Folderqueryreport.formDataHeader.RptHdrSel_ModuleName).subscribe(data => {
      this.opentemplate = true;
      this.RptHdrSel_TemplateName = data["ResponseData"].RptHdrSel_TemplateName;
      this.RptHdrSel_AutoNo = data["ResponseData"].RptHdrSel_AutoNo;
      this.Folderqueryreport.formDataHeader.RptHdrSel_AutoNo = this.RptHdrSel_AutoNo;
      if (data != null && data != undefined) {

        if (data["ResponseData"].RptHdrSel_ColumnHeader != null) {
          var datas = data["ResponseData"].RptHdrSel_ColumnHeader.split(',');
          "TravelDate,InvDate,DocNo,FolderNo,OrderNo,Customer,Passenger,AIRID,NOOFPASS,BUYAMT,COMMAMT,SELLAMT,SalesWithCC,GPPer,BOOKEDBY,BRID,BAID,Destination,CustGroup,MarketingCode,StaffGroup,Country,OrderType";
          if (datas.includes('FolderNo')) {
            this.FOLDERNOChk = true;
          }
          else {
            this.FOLDERNOChk = false;
          }
          if (datas.includes('TravelDate')) {
            this.FOLDERDATEChk = true;
          }
          else {
            this.FOLDERDATEChk = false;
          }
          if (datas.includes('InvDate')) {
            this.INVDATEChk = true;
          }
          else {
            this.INVDATEChk = false;
          }
          if (datas.includes('DocNo')) {
            this.DocNoChk = true;
          }
          else {
            this.DocNoChk = false;
          }
          if (datas.includes('OrderNo')) {
            this.OrderNoChk = true;
          }
          else {
            this.OrderNoChk = false;
          }
          if (datas.includes('Customer')) {
            this.CUSTNAMEChk = true;
          }
          else {
            this.CUSTNAMEChk = false;
          }
          if (datas.includes('Passenger')) {
            this.LASTNAMEChk = true;
          }
          else {
            this.LASTNAMEChk = false;
          }

          if (datas.includes('AIRID')) {
            this.AIRIDChk = true;
          }
          else {
            this.AIRIDChk = false;
          }
          if (datas.includes('NOOFPASS')) {
            this.NOOFPASSChk = true;
          }
          else {
            this.NOOFPASSChk = false;
          }
          if (datas.includes('BUYAMT')) {
            this.BUYAMTChk = true;
          }
          else {
            this.BUYAMTChk = false;
          }
          if (datas.includes('COMMAMT')) {
            this.COMMAMTChk = true;
          }
          else {
            this.COMMAMTChk = false;
          }
          if (datas.includes('SELLAMT')) {
            this.SELLAMTChk = true;
          }
          else {
            this.SELLAMTChk = false;
          }
          if (datas.includes('SalesWithCC')) {
            this.SalesWithCCChk = true;
          }
          else {
            this.SalesWithCCChk = false;
          }
          if (datas.includes('GPPer')) {
            this.GPPerChk = true;
          }
          else {
            this.GPPerChk = false;
          }
          if (datas.includes('BOOKEDBY')) {
            this.BOOKEDBYChk = true;
          }
          else {
            this.BOOKEDBYChk = false;
          }
          if (datas.includes('BRID')) {
            this.BRIDChk = true;
          }
          else {
            this.BRIDChk = false;
          }
          if (datas.includes('BAID')) {
            this.BAIDChk = true;
          }
          else {
            this.BAIDChk = false;
          }
          if (datas.includes('Destination')) {
            this.DestinationChk = true;
          }
          else {
            this.DestinationChk = false;
          }
          if (datas.includes('CustGroup')) {
            this.CustGroupChk = true;
          }
          else {
            this.CustGroupChk = false;
          }
          if (datas.includes('MarketingCode')) {
            this.MarketingCodeChk = true;
          }
          else {
            this.MarketingCodeChk = false;
          }
          if (datas.includes('StaffGroup')) {
            this.StaffGroupChk = true;
          }
          else {
            this.StaffGroupChk = false;
          }
          if (datas.includes('Country')) {
            this.CountryChk = true;
          }
          else {
            this.CountryChk = false;
          }
          if (datas.includes('OrderType')) {
            this.OrderTypeChk = true;
          }
          else {
            this.OrderTypeChk = false;
          }
        }
        this.selectAlldisable();
      }
    })
  }

  onSave() {
    debugger
    this.submitted = true;
    this.userID = +this.apiShared.uid;
    if (this.Folderqueryreport.formDataHeader.RptHdrSel_AutoNo == 0) {

      if (
        this.RptHdrSel_TemplateName == null ||
        this.RptHdrSel_TemplateName == undefined ||
        this.RptHdrSel_TemplateName == ""
      ) {
        return;
      } else {
        if (this.selectAll == true) {
          this.column = "TravelDate,InvDate,DocNo,FolderNo,OrderNo,Customer,Passenger,AIRID,NOOFPASS,BUYAMT,COMMAMT,SELLAMT,SalesWithCC,GPPer,BOOKEDBY,BRID,BAID,Destination,CustGroup,MarketingCode,StaffGroup,Country,OrderType";
        } else {
          this.column = "";

          if (this.FOLDERDATEChk == true) {
            this.column = this.column + ",TravelDate";
          }
          if (this.INVDATEChk == true) {
            this.column = this.column + ",InvDate";
          }
          if (this.DocNoChk == true) {
            this.column = this.column + ",DocNo";
          }
          if (this.FOLDERNOChk == true) {
            this.column = this.column + ",FolderNo";
          }
          if (this.OrderNoChk == true) {
            this.column = this.column + ",OrderNo";
          }
          if (this.CUSTNAMEChk == true) {
            this.column = this.column + ",Customer";
          }
          if (this.LASTNAMEChk == true) {
            this.column = this.column + ",Passenger";
          }
          if (this.AIRIDChk == true) {
            this.column = this.column + ",AIRID";
          }
          if (this.NOOFPASSChk == true) {
            this.column = this.column + ",NOOFPASS";
          }
          if (this.BUYAMTChk == true) {
            this.column = this.column + ",BUYAMT";
          }
          if (this.COMMAMTChk == true) {
            this.column = this.column + ",COMMAMT";
          }
          if (this.SELLAMTChk == true) {
            this.column = this.column + ",SELLAMT";
          }
          if (this.SalesWithCCChk == true) {
            this.column = this.column + ",SalesWithCC";
          }
          if (this.GPPerChk == true) {
            this.column = this.column + ",GPPer";
          }


          if (this.BOOKEDBYChk == true) {
            this.column = this.column + ",BOOKEDBY";
          }
          if (this.BRIDChk == true) {
            this.column = this.column + ",BRID";
          }
          if (this.BAIDChk == true) {
            this.column = this.column + ",BAID";
          }
          if (this.DestinationChk == true) {
            this.column = this.column + ",Destination";
          }
          if (this.CustGroupChk == true) {
            this.column = this.column + ",CustGroup";
          }
          if (this.MarketingCodeChk == true) {
            this.column = this.column + ",MarketingCode";
          }
          if (this.StaffGroupChk == true) {
            this.column = this.column + ",StaffGroup";
          }

          if (this.CountryChk == true) {
            this.column = this.column + ",Country";
          }
          if (this.OrderTypeChk == true) {
            this.column = this.column + ",OrderType";
          }

          this.column = this.column.replace(/,\s*$/, "");
          this.column = this.column.replace(/^,/, "");
        }
        this.Folderqueryreport.formDataHeader.RptHdrSel_UserId = this.userID;
        this.Folderqueryreport.formDataHeader.RptHdrSel_ModuleName =
          "FolderQueryReport";
        this.Folderqueryreport.formDataHeader.RptHdrSel_ColumnHeader = this.column;
        this.Folderqueryreport.formDataHeader.RptHdrSel_TemplateName = this.RptHdrSel_TemplateName;
        this.Folderqueryreport.formDataHeader.RptHdrSel_CustCo = 0;
        this.Folderqueryreport.formDataHeader.RptHdrSel_AutoNo = 0;
        this.Folderqueryreport.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
          new Date()
        );
        this.subscription.add(
          this.Folderqueryreport.saveOrUpdateReportHeader().subscribe(data => {
            debugger;
            if (data != undefined && data != null) {
              if (data["ResponseData"]["RptHdrSel_AutoNo"] > 0) {
                this.Folderqueryreport.formDataHeader.RptHdrSel_AutoNo =
                  data["ResponseData"]["RptHdrSel_AutoNo"];
                this.LoadTemplate();
                this.LoadReportColumn();
                this.opentemplate = false;
                this.duplicatetemp = false;
              } else {
                this.duplicatetemp = true;
              }
            }
          })
        );
      }
    }

    else {
      if (this.RptHdrSel_TemplateName == null || this.RptHdrSel_TemplateName == undefined || this.RptHdrSel_TemplateName == "") {
        return
      }
      else if (this.selectAll == true) {
        this.column = "TravelDate,InvDate,DocNo,FolderNo,OrderNo,Customer,Passenger,AIRID,NOOFPASS,BUYAMT,COMMAMT,SELLAMT,SalesWithCC,GPPer,BOOKEDBY,BRID,BAID,Destination,CustGroup,MarketingCode,StaffGroup,Country,OrderType";
      }
      else {
        this.column = "";

        if (this.FOLDERDATEChk == true) {
          this.column = this.column + ",TravelDate";
        }
        if (this.INVDATEChk == true) {
          this.column = this.column + ",InvDate";
        }
        if (this.DocNoChk == true) {
          this.column = this.column + ",DocNo";
        }
        if (this.FOLDERNOChk == true) {
          this.column = this.column + ",FolderNo";
        }
        if (this.OrderNoChk == true) {
          this.column = this.column + ",OrderNo";
        }
        if (this.CUSTNAMEChk == true) {
          this.column = this.column + ",Customer";
        }
        if (this.LASTNAMEChk == true) {
          this.column = this.column + ",Passenger";
        }
        if (this.AIRIDChk == true) {
          this.column = this.column + ",AIRID";
        }
        if (this.NOOFPASSChk == true) {
          this.column = this.column + ",NOOFPASS";
        }
        if (this.BUYAMTChk == true) {
          this.column = this.column + ",BUYAMT";
        }
        if (this.COMMAMTChk == true) {
          this.column = this.column + ",COMMAMT";
        }
        if (this.SELLAMTChk == true) {
          this.column = this.column + ",SELLAMT";
        }
        if (this.SalesWithCCChk == true) {
          this.column = this.column + ",SalesWithCC";
        }
        if (this.GPPerChk == true) {
          this.column = this.column + ",GPPer";
        }

        if (this.BOOKEDBYChk == true) {
          this.column = this.column + ",BOOKEDBY";
        }
        if (this.BRIDChk == true) {
          this.column = this.column + ",BRID";
        }
        if (this.BAIDChk == true) {
          this.column = this.column + ",BAID";
        }
        if (this.DestinationChk == true) {
          this.column = this.column + ",Destination";
        }
        if (this.CustGroupChk == true) {
          this.column = this.column + ",CustGroup";
        }
        if (this.MarketingCodeChk == true) {
          this.column = this.column + ",MarketingCode";
        }
        if (this.StaffGroupChk == true) {
          this.column = this.column + ",StaffGroup";
        }

        if (this.CountryChk == true) {
          this.column = this.column + ",Country";
        }
        if (this.OrderTypeChk == true) {
          this.column = this.column + ",OrderType";
        }
        this.column = this.column.replace(/,\s*$/, "");
        this.column = this.column.replace(/^,/, "");
      }
      this.Folderqueryreport.formDataHeader.RptHdrSel_UserId = this.userID;
      this.Folderqueryreport.formDataHeader.RptHdrSel_ModuleName =
        "FolderQueryReport";
      this.Folderqueryreport.formDataHeader.RptHdrSel_ColumnHeader = this.column;
      this.Folderqueryreport.formDataHeader.RptHdrSel_TemplateName = this.RptHdrSel_TemplateName;
      this.Folderqueryreport.formDataHeader.RptHdrSel_CustCo = 0;
      this.Folderqueryreport.formDataHeader.RptHdrSel_AutoNo = this.RptHdrSel_AutoNo;
      this.Folderqueryreport.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
        new Date()
      );
      this.subscription.add(
        this.Folderqueryreport.saveOrUpdateReportHeader().subscribe(data => {
          debugger;
          if (data != undefined && data != null) {
            if (data["ResponseData"]["RptHdrSel_AutoNo"] > 0) {
              this.Folderqueryreport.formDataHeader.RptHdrSel_AutoNo =
                data["ResponseData"]["RptHdrSel_AutoNo"];
              this.LoadTemplate();
              this.LoadReportColumn();
              this.opentemplate = false;
              this.duplicatetemp = false;
            }
            // else {
            //   this.duplicatetemp = true;
            // }
          }
        })
      );

    }

  }
  closeFolderQueryReport() {
    this.opentemplate = false;
  }
  async dataStateChangeColumnVisible(value: any) {
    debugger;
    this.column = "TravelDate,InvDate,DocNo,FolderNo,OrderNo,Customer,Passenger,AIRID,NOOFPASS,BUYAMT,COMMAMT,SELLAMT,SalesWithCC,GPPer,BOOKEDBY,BRID,BAID,Destination,CustGroup,MarketingCode,StaffGroup,Country,OrderType";
    if (value["columns"] != null && value["columns"].length > 0) {
      value["columns"].forEach(obj => {
        if (obj["hidden"] == true) {
          this.column = this.column.replace(obj["field"], "");
          this.column = this.column.replace(/,\s*$/, "");
          this.column = this.column.replace(/^,/, "");
        } else {
        }
      });
      if (this.TemplateSelectedItem != undefined) {
        this.Folderqueryreport.formDataHeader.RptHdrSel_AutoNo = this.TemplateSelectedItem.UsrCode;
        this.Folderqueryreport.formDataHeader.RptHdrSel_UserId = this.userID;
        this.Folderqueryreport.formDataHeader.RptHdrSel_ModuleName =
          "FolderQueryReport";
        this.Folderqueryreport.formDataHeader.RptHdrSel_ColumnHeader = this.column;
        this.Folderqueryreport.formDataHeader.RptHdrSel_TemplateName =
          this.RptHdrSel_TemplateName;
        this.Folderqueryreport.formDataHeader.RptHdrSel_CustCo = 0;
        this.Folderqueryreport.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
          new Date()
        );
        this.subscription.add(
          this.Folderqueryreport
            .saveOrUpdateReportHeader()
            .subscribe(data => { })
        );
      } else {

      }
    }
  }
  templateChange(value) {

    debugger; //UsrCode
    if (value != undefined) {
      this.Folderqueryreport.formDataHeader.RptHdrSel_AutoNo = value.UsrCode;
      this.Folderqueryreport.formDataHeader.RptHdrSel_TemplateName = value.Name;
      this.LoadReportColumn();
    }
  }



  async export(pdfComponent: any) {
    let processedUri: any;
    let baseprocess = await pdfComponent
      .export()
      .then((group: Group) => exportPDF(group))
      .then(dataUri => {
        const base64 = dataUri.replace("data:application/pdf;base64,", "");
        processedUri = dataUri;
      });
    const fileObject = await this.dataURLtoFile(
      processedUri,
      "Folder Query Report.pdf"
    );
    return fileObject;
  }
  async dataURLtoFile(dataurl, filename) {
    let file: any;
    let dataURL = new Promise(resolve => {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let newFile = new File([u8arr], filename, { type: mime });
      file = newFile;
      resolve(newFile);
    });
    this.email.fileurl = file;
    this.email.filename = "Folder Query Report.pdf";
    this.email.reportname = "Folder Query  Report";
    this.open();
    return file;
  }
  public open() {

    this.opened = true;
  }
  public close(status) {
    this.opened = false;
    this.email.fileurl = null;
    this.email.filename = null;
    this.email.reportname = null;
  }
  EmailPDF() {
    debugger;
    this.email.Isgridpdf = true;
    this.export(this.pdf);
  }
  excel() {

    this.email.filename = "Folder Query Report.xlsx";
    this.email.reportname = "Folder Query  Report";

    this.open();
    //args.preventDefault();
  }
  public async onExcelExport2(args: any) {
    debugger;

    // this.email.filename = "Folder Query  Report.xlsx";
    // this.email.reportname = "Folder Query  Report";

    // Prevent automatically saving the file. We will save it manually after we fetch and add the details
    args.preventDefault();

    const observables = [];
    let workbook = args.workbook;

    let rows = workbook.sheets[0].rows;

    let headerOptions = rows[0].cells[0];

    var reader = new FileReader();
    var sheet = workbook.sheets[0];

    if ((this.Folderqueryreport.formData.FolderNoFrom != undefined && this.Folderqueryreport.formData.FolderNoFrom != null && this.Folderqueryreport.formData.FolderNoFrom != "")) {
      this.common.generateExcelFilterheader(sheet, 'Folder No', this.Folderqueryreport.formData.FolderNoFrom.toString(), '');

    }
    if ((this.Folderqueryreport.formData.OrderNoFrom != undefined && this.Folderqueryreport.formData.OrderNoFrom != null && this.Folderqueryreport.formData.OrderNoFrom != "")) {
      this.common.generateExcelFilterheader(sheet, 'Order No', this.Folderqueryreport.formData.OrderNoFrom.toString(), '');

    }
    if ((this.Folderqueryreport.formData.MarkettingCode != undefined && this.Folderqueryreport.formData.MarkettingCode != null && this.Folderqueryreport.formData.MarkettingCode != "")) {
      this.common.generateExcelFilterheader(sheet, 'Marketting Code', this.strMc.toString(), '');

    }
    if ((this.Folderqueryreport.formData.BranchName != undefined && this.Folderqueryreport.formData.BranchName != null && this.Folderqueryreport.formData.BranchName != "")) {
      this.common.generateExcelFilterheader(sheet, 'Branch Name', this.strBr.toString(), '');

    }
    if ((this.Folderqueryreport.formData.BusinessArea != undefined && this.Folderqueryreport.formData.BusinessArea != null && this.Folderqueryreport.formData.BusinessArea != "")) {
      this.common.generateExcelFilterheader(sheet, 'Business Area', this.strBa.toString(), '');

    }
    if ((this.Folderqueryreport.formData.BookedBy != undefined && this.Folderqueryreport.formData.BookedBy != null && this.Folderqueryreport.formData.BookedBy != "")) {
      this.common.generateExcelFilterheader(sheet, 'Booked By', this.Folderqueryreport.formData.BookedBy.toString(), '');

    }
    if ((this.Folderqueryreport.formData.StaffGroup != undefined && this.Folderqueryreport.formData.StaffGroup != null && this.Folderqueryreport.formData.StaffGroup != "")) {
      this.common.generateExcelFilterheader(sheet, 'Staff Group', this.Folderqueryreport.formData.StaffGroup.toString(), '');

    }
    if ((this.Folderqueryreport.formData.Destination != undefined && this.Folderqueryreport.formData.Destination != null && this.Folderqueryreport.formData.Destination != "")) {
      this.common.generateExcelFilterheader(sheet, 'Destination', this.strdest.toString(), '');

    }
    if ((this.Folderqueryreport.formData.SupplierCode != undefined && this.Folderqueryreport.formData.SupplierCode != null && this.Folderqueryreport.formData.SupplierCode != "")) {
      this.common.generateExcelFilterheader(sheet, 'Supplier Code', this.Folderqueryreport.formData.SupplierCode.toString(), '');

    }
    if ((this.Folderqueryreport.formData.CustomerCode != undefined && this.Folderqueryreport.formData.CustomerCode != null && this.Folderqueryreport.formData.CustomerCode != "")) {
      this.common.generateExcelFilterheader(sheet, 'Customer Code', this.Folderqueryreport.formData.CustomerCode.toString(), '');

    }
    if ((this.Folderqueryreport.formData.ProductCode != undefined && this.Folderqueryreport.formData.ProductCode != null && this.Folderqueryreport.formData.ProductCode != "")) {
      this.common.generateExcelFilterheader(sheet, 'Product Code', this.Folderqueryreport.formData.ProductCode.toString(), '');

    }
    if ((this.Folderqueryreport.formData.Supplier_Group != undefined && this.Folderqueryreport.formData.Supplier_Group != null && this.Folderqueryreport.formData.Supplier_Group != "")) {
      this.common.generateExcelFilterheader(sheet, 'Supplier Group', this.Folderqueryreport.formData.Supplier_Group.toString(), '');

    }
    if ((this.Folderqueryreport.formData.Customer_Group != undefined && this.Folderqueryreport.formData.Customer_Group != null && this.Folderqueryreport.formData.Customer_Group != "")) {
      this.common.generateExcelFilterheader(sheet, 'Customer Group', this.Folderqueryreport.formData.Customer_Group.toString(), '');

    }
    if ((this.Folderqueryreport.formData.ProfitPerFrom != undefined && this.Folderqueryreport.formData.ProfitPerFrom != null && this.Folderqueryreport.formData.ProfitPerFrom != "")) {
      this.common.generateExcelFilterheader(sheet, 'Profit Per ', this.Folderqueryreport.formData.ProfitPerFrom.toString(), '');

    }
    if ((this.Folderqueryreport.formData.ProfitFrom != undefined && this.Folderqueryreport.formData.ProfitFrom != null && this.Folderqueryreport.formData.ProfitFrom != "")) {
      this.common.generateExcelFilterheader(sheet, 'Profit', this.Folderqueryreport.formData.ProfitFrom.toString(), '');

    }
    if ((this.Folderqueryreport.formData.YourReference != undefined && this.Folderqueryreport.formData.YourReference != null && this.Folderqueryreport.formData.YourReference != "")) {
      this.common.generateExcelFilterheader(sheet, 'Your Reference', this.Folderqueryreport.formData.YourReference.toString(), '');

    }
    if ((this.Folderqueryreport.formData.User != undefined && this.Folderqueryreport.formData.User != null && this.Folderqueryreport.formData.User != "")) {
      this.common.generateExcelFilterheader(sheet, 'User', this.Folderqueryreport.formData.User.toString(), '');

    }
    if ((this.Folderqueryreport.formData.Status != undefined)) {
      this.common.generateExcelFilterheader(sheet, 'Status', this.Folderqueryreport.formData.StatusName.toString(), '');

    }
    if ((this.Folderqueryreport.formData.GroupBy_varchar != undefined && this.Folderqueryreport.formData.GroupBy_varchar != null && this.Folderqueryreport.formData.GroupBy_varchar != "")) {
      this.common.generateExcelFilterheader(sheet, 'Group By', this.Folderqueryreport.formData.GroupBy_varchar.toString(), '');

    }
    if ((this.Folderqueryreport.formData.TypeOfInvoice_nvarchar != undefined && this.Folderqueryreport.formData.TypeOfInvoice_nvarchar != null && this.Folderqueryreport.formData.TypeOfInvoice_nvarchar != "")) {
      this.common.generateExcelFilterheader(sheet, 'Type Of Invoice', this.Folderqueryreport.formData.TypeOfInvoice_nvarchar.toString(), '');

    }
    if ((this.Folderqueryreport.formData.QuotesOnly != undefined)) {
      this.common.generateExcelFilterheader(sheet, 'Quotes Only', this.Folderqueryreport.formData.Quotes, '');

    }
    if ((this.Folderqueryreport.formData.CancelledCheck != undefined)) {
      this.common.generateExcelFilterheader(sheet, 'Include Canceled', this.Folderqueryreport.formData.Cancelled, '');

    }
    if ((this.Folderqueryreport.formData.VairlineAIRID != undefined && this.Folderqueryreport.formData.VairlineAIRID != null && this.Folderqueryreport.formData.VairlineAIRID != "")) {
      this.common.generateExcelFilterheader(sheet, 'Airline', this.Folderqueryreport.formData.VairlineAIRID.toString(), '');

    }
    if ((this.Folderqueryreport.formData.Country_varchar != undefined && this.Folderqueryreport.formData.Country_varchar != null && this.Folderqueryreport.formData.Country_varchar != "")) {
      this.common.generateExcelFilterheader(sheet, 'Country', this.Folderqueryreport.formData.Country_varchar.toString(), '');

    }
    if ((this.Folderqueryreport.formData.OrderType_varchar != undefined && this.Folderqueryreport.formData.OrderType_varchar != null && this.Folderqueryreport.formData.OrderType_varchar != "")) {
      this.common.generateExcelFilterheader(sheet, 'Order Type', this.Folderqueryreport.formData.OrderType_varchar.toString(), '');

    }
    if ((this.Folderqueryreport.formData.ExcludePDQCharge_int != undefined)) {
      this.common.generateExcelFilterheader(sheet, 'Exclude PDQ Charge', this.Folderqueryreport.formData.ExcludePDQCharge.toString(), '');

    }
    if ((this.Folderqueryreport.formData.ExcludeTOMSVat_int != undefined)) {
      this.common.generateExcelFilterheader(sheet, 'Exclude TOMS Vat', this.Folderqueryreport.formData.ExcludeTOMSVat.toString(), '');

    }
    if ((this.Folderqueryreport.formData.Currency_varchar != undefined && this.Folderqueryreport.formData.Currency_varchar != null && this.Folderqueryreport.formData.Currency_varchar != "")) {
      this.common.generateExcelFilterheader(sheet, 'Currency', this.Folderqueryreport.formData.Currency_varchar.toString(), '');

    }
    if ((this.Folderqueryreport.formData.PaymentStatus_tinyint != undefined)) {
      this.common.generateExcelFilterheader(sheet, 'Payment Status', this.Folderqueryreport.formData.PaymentStatus.toString(), '');

    }
    if ((this.Folderqueryreport.formData.BookingStatus != undefined && this.Folderqueryreport.formData.BookingStatus != null && this.Folderqueryreport.formData.BookingStatus != "")) {
      this.common.generateExcelFilterheader(sheet, 'Booking Status', this.Folderqueryreport.formData.BookingStatus, '');

    }
    if ((this.Folderqueryreport.formData.IncludeQuoteswithPO != undefined)) {
      this.common.generateExcelFilterheader(sheet, 'Include Quotes with PO', this.Folderqueryreport.formData.IncludeQuotesPO, '');

    }
    if ((this.Folderqueryreport.formData.SubGroup != undefined && this.Folderqueryreport.formData.SubGroup != null && this.Folderqueryreport.formData.SubGroup != "")) {
      this.common.generateExcelFilterheader(sheet, 'SubGroup', this.Folderqueryreport.formData.SubGroup.toString(), '');

    }
    if ((this.Folderqueryreport.formData.CreatedUser != undefined && this.Folderqueryreport.formData.CreatedUser != null && this.Folderqueryreport.formData.CreatedUser != "")) {
      this.common.generateExcelFilterheader(sheet, 'Created User', this.Folderqueryreport.formData.CreatedUser.toString(), '');

    }
    if ((this.Folderqueryreport.formData.CustomerName != undefined && this.Folderqueryreport.formData.CustomerName != null && this.Folderqueryreport.formData.CustomerName != "")) {
      this.common.generateExcelFilterheader(sheet, 'Customer', this.Folderqueryreport.formData.CustomerName.toString(), '');

    }
    if ((this.Folderqueryreport.formData.CreationFromdate != undefined && this.Folderqueryreport.formData.CreationFromdate != null && this.Folderqueryreport.formData.CreationFromdate != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'Creation Date', this.common.ConvertToLocaleDate(this.Folderqueryreport.formData.CreationFromdate), this.common.ConvertToLocaleDate(this.Folderqueryreport.formData.CreationTodate));

    }
    if ((this.Folderqueryreport.formData.TravelFromdate != undefined && this.Folderqueryreport.formData.TravelFromdate != null && this.Folderqueryreport.formData.TravelFromdate != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'Travel Date', this.common.ConvertToLocaleDate(this.Folderqueryreport.formData.TravelFromdate), this.common.ConvertToLocaleDate(this.Folderqueryreport.formData.TravelTodate));

    }
    if ((this.Folderqueryreport.formData.InvFromdate != undefined && this.Folderqueryreport.formData.InvFromdate != null && this.Folderqueryreport.formData.InvFromdate != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'Invoice Date', this.common.ConvertToLocaleDate(this.Folderqueryreport.formData.InvFromdate), this.common.ConvertToLocaleDate(this.Folderqueryreport.formData.InvTodate));

    }

    if ((this.Folderqueryreport.formData.BookingFromdate != undefined && this.Folderqueryreport.formData.BookingFromdate != null && this.Folderqueryreport.formData.BookingFromdate != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'Booking Date', this.common.ConvertToLocaleDate(this.Folderqueryreport.formData.BookingFromdate), this.common.ConvertToLocaleDate(this.Folderqueryreport.formData.BookingTodate));

    }
    if ((this.Folderqueryreport.formData.ReturnFromdate != undefined && this.Folderqueryreport.formData.ReturnFromdate != null && this.Folderqueryreport.formData.ReturnFromdate != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'Return Date', this.common.ConvertToLocaleDate(this.Folderqueryreport.formData.ReturnFromdate), this.common.ConvertToLocaleDate(this.Folderqueryreport.formData.ReturnTodate));

    }
    if ((this.email.reportname != undefined)) {
      this.common.generateExcelMainheader(sheet, this.email.reportname);
    }
    await this.common.SetCompanyheaderExcelheader(sheet)
    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      saveAs(dataUrl, "Folder Query  Report.xlsx");
      this.email.fileurl = this.dataURItoBlob(dataUrl);
    });
  }


  dataURItoBlob(dataURI) {

    var byteString = atob(dataURI.split(",")[1]);


    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];


    var ab = new ArrayBuffer(byteString.length);



    var ia = new Uint8Array(ab);


    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }


    var blob = new Blob([ab], { type: mimeString });

    return blob;
  }
  public EmailFormClosing(
    status: any
  ) {
    if (status == "EmailSendSuccesfully" || status == 'Closed') {

      this.opened = false;
    }
  }
  public onTabSelect(e) {

  }
  onCancel() {
    var reporturl = sessionStorage.getItem("menuhighlight");
    this.Folderqueryreport.griddata = "";
    if (reporturl != null) {
      this.router.navigate(["/Login"]);
    } else {
      this.router.navigate(["PenAir/Reports/rptfolderquery"]);
    }

  }
  onFilterClick() {
    debugger
    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
