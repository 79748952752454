export class CustomerReceiptDetailXMLClass {
    ReceiptAmount :any; 
    FC_BALANCE:any;
    FCReceiptAmount :any; 
    HCBalance:any;
   AVRTYPE:number;
   AYEARID :string;  
   ASSIGN:string; 
   PROJNO:string;
   Select:number;
   AVRNO:any;
   HCAMT:any;
   ExchangeGainFlag:number
}
