import { Component, OnInit, Input, ViewChild, SimpleChanges, OnChanges, OnDestroy, SimpleChange, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { ShareDataService } from '../../Services/share-data.service';
import { Alertdetails } from '../../Models/alertdetails';
import { Alert } from 'selenium-webdriver';
import { Subscription, Subject } from 'rxjs';
import { HotelDetailsEditComponent } from '../hotel-details-edit/hotel-details-edit.component';
import { CommonserviceService } from '../../Services/commonservice.service';
import { CurrencyExchangerateService } from '../../Services/currency-exchangerate.service';
import { CurrencyExchangerate } from '../../Models/currency-exchangerate';
import { SupplierMasterService } from '../../Services/supplier-master.service';
import { toUnicode } from 'punycode';
import { FileService } from '../../Services/file.service';
import { CashPaymentService } from '../../Services/cash-payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CashPaymentDTO } from '../../Models/cash-payment-dto.model';
import { TaxMasterDetailsDTO } from '../../Models/manual-sales-dto';
import { ManualsalesService } from '../../Services/manualsales.service';

@Component({
  selector: 'app-fchcdetailedit-new',
  templateUrl: './fchcdetailedit-new.component.html',
  styleUrls: ['./fchcdetailedit-new.component.scss']
})
export class FchcdetaileditNewComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  folderExrate: any;
  fldrCurrency: any;
  buycurrency: any;
  editinputtax: boolean = false;
  folderStatus: any;
  public labelchange = false;
  exRate: number;
  txtDisabled = true;
  hcCurrency: any;
  isShown = false;
  isShowntax = false;
  Vtype: any;
  public divValue: any = 1;
  public Markup: any = 0;
  public CostofSale: any = 0;
  public package = false;
  public manualCalculation = false;
  public Producttype: any = false;
  isother: any = false;
  public disablecurrency: any = false;
  public NV: any = false;
  public VSF: any = false;
  public VP: any = false;
  public VSC: any = false;
  public chkVat: any;
  currencyExrate: CurrencyExchangerate;
  public CurrencyselectedItem: LookUpDetails['ResponseData'];
  CurrencyList: Array<LookUpDetails['ResponseData']> = [];
  ShowVat: any;
  @Input() public parentForm: FormGroup
  @Input() public pocreated;
  @Input() resetFormSubject: Subject<boolean> = new Subject<boolean>();
  opentaxbaseForm: boolean = false;

  InputTax: Array<LookUpDetails["ResponseData"]> = [];
  OutputTax: Array<LookUpDetails["ResponseData"]> = [];
  lockStatus: any;
  formeditable: boolean = true;
  public InputTaxselectedItem: LookUpDetails["ResponseData"];
  public OutputTaxselectedItem: LookUpDetails["ResponseData"];
  inputTaxDetails: Array<TaxMasterDetailsDTO> = [];
  outputTaxDetails: Array<TaxMasterDetailsDTO> = [];
  custcountry: any;
  public iseditinput = true;
  public iseditoutput = true;

  checked1: any = false;
  public model = {
    vtype: 'NOVAT'
  };
  public showcurrexgerate:boolean=false;
  constructor(private supplier: SupplierMasterService, private fileser: FileService, private apiExRate: CurrencyExchangerateService, private lookupApi: LookUpDetailsService, private apiShared: ShareDataService, public commonser: CommonserviceService,
    private cashservice: CashPaymentService, private spinner: NgxSpinnerService, private Manualsalesservice: ManualsalesService, ) { }

  ngOnInit(): void {
    this.loadfiledetails();
    debugger;
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    this.commonser.InputTaxselectedItem = null;
    this.commonser.OutTaxselectedItem = null;
    this.custcountry = localStorage.getItem('CustomerCountry')
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    }
   else {
      this.formeditable = false;

    }
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;

    }
     
    // case id : 13777
    debugger;
    if (this.parentForm.controls.CostingVATType != undefined)//By -12771
      if (this.parentForm.controls.CostingVATType.value == "0" || this.parentForm.controls.CostingVATType.value == null) {
        this.parentForm.controls.CostingVATType.setValue('NV');
      }
    if (this.parentForm.controls.GUIDItinerary.value != null || this.parentForm.controls.GUIDItinerary.value != '') {
      this.iseditinput = false;
      this.iseditoutput = false;
      if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'NV') {
        this.divValue = 1;
        this.VSF = false;
        this.VP = false;
        this.VSC = false;
        this.NV = true;
        this.commonser.issuvatonsupcomm = false;
        this.ShowVat = 0;
      }
      else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
        this.divValue = 2;
        this.VSF = true;
        this.VP = false;
        this.VSC = false;
        this.NV = false;
        this.commonser.issuvatonsupcomm = false;
        this.ShowVat = 1;
      }
      else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {
        this.divValue = 3;
        this.VP = true;
        this.VSC = false;
        this.VSF = false;
        this.commonser.issuvatonsupcomm = false;
        this.NV = false;
        this.ShowVat = 1;
      }
      else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSC') {
        this.commonser.issuvatonsupcomm = true;
        this.divValue = 4;
        this.VSC = true;
        this.VSF = false;
        this.VP = false;
        this.NV = false;
        this.ShowVat = 1;
      }
      else {
        this.commonser.issuvatonsupcomm = false;
        this.divValue = 1;
        this.VSF = false;
        this.VP = false;
        this.VSC = false;
        this.NV = true;
        this.ShowVat = 0;

      }

    }
    if (this.parentForm.controls.ITYPE.value == "Others") {

      this.isother = true;
    }
    else {
      this.isother = false;
    }
    // if(this.parentForm.controls.ITYPE.value=="Hotel" ||this.parentForm.controls.ITYPE.value=="Others"||this.parentForm.controls.ITYPE.value=="Cruise" ||this.parentForm.controls.ITYPE.value=="Car"|| this.parentForm.controls.ITYPE.value=="Rail" )
    // {
    this.Producttype = true;
    // }
    this.hcCurrency = this.apiShared.homeCurrency;
    if (localStorage.getItem('OrderType') === 'Package') {
      this.package = true;
      if (localStorage.getItem('ManualCalc') === '1') {
        this.manualCalculation = true;
      }
    }
    this.getFolderValues();
    this.Checkcurrencyload();
    // alert(this.parentForm.controls.CountryID.value)

    this.subscription.add(this.lookupApi.GetAllLookup('CURRENCY').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.CurrencyList = data["ResponseData"];
          if (this.parentForm.get('ITYPE').value == "Cruise" || this.parentForm.get('ITYPE').value == 'Car' || this.parentForm.get('ITYPE').value == 'Tour' || this.parentForm.get('ITYPE').value == 'Rail' || this.parentForm.get('ITYPE').value == 'Insurance' || this.parentForm.get('ITYPE').value == 'Transfers' || this.parentForm.get('ITYPE').value == 'Hotel') {
            if (this.parentForm.controls.CURCODE.value != '') {

              this.CurrencyselectedItem = this.CurrencyList.find(item => item.Code == this.parentForm.controls.CURCODE.value);

              // console.log("test:-"+this.hotelEditForm.controls.CURCODE.value+JSON.stringify(this.CurrencyselectedItem , null, 4));
            }
          }
          else {
            if (this.parentForm.controls.CURID.value != '') {

              this.CurrencyselectedItem = this.CurrencyList.find(item => item.Code == this.parentForm.controls.CURID.value);

              // console.log("test:-"+this.hotelEditForm.controls.CURCODE.value+JSON.stringify(this.CurrencyselectedItem , null, 4));
            }

          }
        }
      }
    ));
    if (this.parentForm.controls.CountryID == undefined || this.parentForm.controls.CountryID == null || this.parentForm.controls.CountryID.value == undefined || this.parentForm.controls.CountryID.value == null || this.parentForm.controls.CountryID.value == "") {
      this.subscription.add(
        this.lookupApi.GetDefaultCountry().subscribe(data => {
          if (data != null && data != undefined) {
            var countryID = data["ResponseData"].UsrCode;
            if (countryID != undefined && countryID != null) {
              this.getInputTaxLookup(countryID);
              if (this.custcountry == undefined && this.custcountry == null)
                this.getOutTaxLookup(countryID);
            }
            // debugger;
            // this.iseditinput = true;
            // this.iseditoutput = true;
          }
        }
        )
      );
    }



    // else {
    //   this.getTaxLookup(this.parentForm.controls.CountryID.value);
    // }




    this.resetFormSubject.subscribe(response => {
      if (response) {
        this.CurrencyValueFromSupplier();
      }
    })
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.folderStatus == "Refund Request") {
      this.labelchange = true;
    }

    // this.onValueChanges();
    this.TaxsplitCalculation();
    if (this.custcountry != undefined && this.custcountry != null) {
      this.getOutTaxLookup(this.custcountry);
    }
  }
  loadfiledetails() {
    this.subscription.add(
      this.fileser.GetCompanySettings("Ticket").subscribe(data => {
        if (data != null && data != undefined) {

          debugger;
          this.commonser.BSPTAXEnable = data.ResponseData.BSPTAXEnable;
        }
      }))
  }
  public ProductChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.parentForm.controls.CODE.setValue(value.Code);
      this.parentForm.controls.DESCRIPTION.setValue(value.Name);//12618 by jubina
    }
    else {
      this.parentForm.controls.CODE.setValue("");
      this.parentForm.controls.DESCRIPTION.setValue("");
    }
  }

  /*newCaculation*/
  TaxsplitCalculation() {
    debugger;
    this.folderExrate = this.apiShared.folderExchangeRate;
    // if((this.parentForm.get('ITYPE').value ) =='Hotel'||(this.parentForm.get('ITYPE').value) =='Others')
    // {
    if (this.parentForm.get('ITYPE').value == "Hotel") {
      this.buyExRate = (this.parentForm.get('EXRATE').value == "" || this.parentForm.get('EXRATE').value == 0) ? 1 : this.parentForm.get('EXRATE').value;
    }
    else {
      this.buyExRate = (this.parentForm.get('CURExRate').value == "" || this.parentForm.get('CURExRate').value == 0) ? 1 : this.parentForm.get('CURExRate').value;
    }

    if (this.parentForm.controls.CostingVATType.value == 'NV' || this.parentForm.controls.CostingVATType.value == '' || this.parentForm.controls.CostingVATType.value == null || this.parentForm.controls.CostingVATType.value == undefined) {
      this.parentForm.controls.CostingCustomerSalesValue.setValue(((this.parentForm.controls.FCBuy.value * this.buyExRate) / this.folderExrate));

      this.parentForm.controls.CostingCustomerSalesValue.setValue(parseFloat((this.parentForm.controls.CostingCustomerSalesValue.value).toFixed(2)));
      this.parentForm.controls.CostofSale.setValue(this.parentForm.controls.CostingCustomerSalesValue.value);
      this.parentForm.controls.CostofSale.setValue(parseFloat((this.parentForm.controls.CostofSale.value).toFixed(2)));


      this.parentForm.controls.CostingCustomerServiceFee.setValue(this.parentForm.controls.FCSell.value - this.parentForm.controls.CostingCustomerSalesValue.value);
      this.parentForm.controls.CostingCustomerServiceFee.setValue(parseFloat((this.parentForm.controls.CostingCustomerServiceFee.value).toFixed(2)));

      this.parentForm.controls.Markup.setValue(this.parentForm.controls.CostingCustomerServiceFee.value);
      this.parentForm.controls.Markup.setValue(parseFloat((this.parentForm.controls.Markup.value).toFixed(2)));

      this.parentForm.controls.CostingSupplierGrossValue.setValue(this.parentForm.controls.FCBuy.value);

      this.parentForm.controls.CostingSupplierGrossValue.setValue(parseFloat((this.parentForm.controls.CostingSupplierGrossValue.value).toFixed(2)));



    }
    if (this.parentForm.controls.CostingVATType.value == 'VSF') {
      this.parentForm.controls.CostingCustomerSalesValue.setValue(((this.parentForm.controls.FCBuy.value * this.buyExRate) / this.folderExrate));


      this.parentForm.controls.CostingCustomerSalesValue.setValue(this.parentForm.controls.FCSell.value - this.parentForm.controls.CostingCustomerSalesValue.value);
    }

  }

  NoVatSellAmountChange() {
    if (this.parentForm.get('ITYPE').value == "Hotel") {
      this.buyExRate = (this.parentForm.get('EXRATE').value == "" || this.parentForm.get('EXRATE').value == 0) ? 1 : this.parentForm.get('EXRATE').value;
    }
    else {
      this.buyExRate = (this.parentForm.get('CURExRate').value == "" || this.parentForm.get('CURExRate').value == 0) ? 1 : this.parentForm.get('CURExRate').value;
    }
    debugger;
    this.parentForm.controls.Markup.setValue(parseFloat((this.parentForm.controls.FCSell.value - this.parentForm.controls.CostofSale.value).toFixed(2)));

    // this.parentForm.controls.CostofSale.setValue( (this.parentForm.controls.FCBuy.value* this.buyExRate)/this.folderExrate);
    // this.parentForm.controls.FCSell.setValue( this.parentForm.controls.CostofSale.value +this.parentForm.controls.Markup.value);
    // this.parentForm.controls.CostingSupplierGrossValue.setValue( this.parentForm.controls.FCBuy.value);
    // this.parentForm.controls.CostingCustomerSalesValue.setValue( this.parentForm.controls.CostofSale.value);

    // this.parentForm.controls.CostingCustomerServiceFee.setValue( this.parentForm.controls.Markup.value);
    // this.parentForm.controls.CostingCustomerNetSellPrice.setValue( this.parentForm.controls.FCSell.value);
    // //this.parentForm.controls.SELLAMT.setValue(parseFloat((this.parentForm.get('FCSell').value*this.exRate).toFixed(2)));
    // if( this.parentForm.get('HCSell'))
    // {

    // this.parentForm.controls.HCSell.setValue(this.parentForm.get('FCSell').value*this.folderExrate)
    // }
    // if( this.parentForm.get('SELLAMT'))
    // {

    //   this.parentForm.controls.SELLAMT.setValue(this.parentForm.get('FCSell').value*this.folderExrate)
    // }
    this.parentForm.controls.CostingCustomerServiceFee.setValue(this.parentForm.controls.Markup.value);
    this.HCDetailsCalculation(1);

  }
  onOpenTaxbase() {
    this.opentaxbaseForm = true;
  }
  close() {
    this.opentaxbaseForm = false;
  }
  closeTaxBaseForm(): any { //13860 v1.0
    return {
      callParentMethod: (value: any) => {
        debugger;


      },
      closemodalpopup: () => {

        this.close()

      }
    }
  }
  OutputVatcalculation(selvat) {
debugger;
    if (this.parentForm.controls.CostingCustomerTaxAmount.value == null || this.parentForm.controls.CostingCustomerTaxAmount.value == undefined) {
      this.parentForm.controls.CostingCustomerTaxAmount.setValue(0);
    }
    if (this.parentForm.controls.CostingCustomerTaxAmount.value != null && this.parentForm.controls.CostingCustomerTaxAmount.value != undefined) {
      if (selvat == 2)//vat on service
      {

        this.parentForm.controls.CostingCustomerNetSellPrice.setValue((this.parentForm.controls.FCSell.value + this.parentForm.controls.CostingCustomerTaxAmount.value));
      }
      else if (selvat == 3) {

        this.parentForm.controls.CostingCustomerNetSellPrice.setValue((this.parentForm.controls.FCSell.value + this.parentForm.controls.CostingCustomerTaxAmount.value));
      }
      if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
        this.parentForm.controls.FC_SELLTAXAMT.setValue(this.parentForm.controls.CostingCustomerTaxAmount.value);
      }
      else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {
        this.parentForm.controls.FC_SELLTAXAMT.setValue(this.parentForm.controls.CostingCustomerTaxAmount.value);
      }
      if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
        if (this.parentForm.get('HC_SELLTAXAMT') != null)
          this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2)));
        else
          this.parentForm.controls.SELLVAT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

      }
      else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {
        if (this.parentForm.get('HC_SELLTAXAMT') != null)
          this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2)));
        else
          this.parentForm.controls.SELLVAT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

      }

      this.TaxAmountChange()

    }
  }
  InputvatCalculation(selvat,newmarkup) {
    debugger;
    if(newmarkup)
    {
      this.CalculationBuy(3,0,1);
    }
    if (this.parentForm.controls.CostingSupplierTaxAmount.value == null || this.parentForm.controls.CostingSupplierTaxAmount.value == undefined) {
      this.parentForm.controls.CostingSupplierTaxAmount.setValue(0);
    }
    if (this.parentForm.controls.CostingSupplierTaxAmount.value != null && this.parentForm.controls.CostingSupplierTaxAmount.value != undefined) {
      if (selvat == 2)//vat on service
      {
        
        this.parentForm.controls.CostingSupplierNetBuyPrice.setValue(this.parentForm.controls.FCBuy.value + this.parentForm.controls.CostingSupplierTaxAmount.value);
      }
      else if (selvat == 3) {
        this.parentForm.controls.CostingSupplierGrossValue.setValue(this.parentForm.controls.CostingSupplierNetBuyPrice.value - this.parentForm.controls.CostingSupplierTaxAmount.value);
        this.parentForm.controls.CostofSale.setValue(parseFloat(((this.parentForm.controls.CostingSupplierGrossValue.value * this.buyExRate) / this.folderExrate).toFixed(2)));
        this.parentForm.controls.CostingCustomerNetSellPrice.setValue((this.parentForm.controls.CostofSale.value +this.parentForm.controls.Markup.value+ this.parentForm.controls.CostingCustomerTaxAmount.value));
        this.parentForm.controls.CostingCustomerSalesValue.setValue(this.parentForm.controls.CostofSale.value);
        this.parentForm.controls.PAYABLE.setValue(
          parseFloat(
            (
              this.parentForm.controls.CostingSupplierGrossValue.value
            ).toFixed(2)
          )
        );
        this.parentForm.controls.FCBuy.setValue(
          parseFloat(
            this.parentForm.controls.CostingSupplierGrossValue.value.toFixed(
              2
            )
          )
        );
        if (this.parentForm.controls.GUIDItinerary.value == null ||this.parentForm.controls.GUIDItinerary.value == '' ) {
          this.parentForm.controls.FCSell.setValue(this.parentForm.controls.CostofSale.value + this.parentForm.controls.Markup.value);
  
        }
        else  if ( newmarkup==1)
        {
          this.parentForm.controls.Markup.setValue(this.parentForm.controls.FCSell.value - this.parentForm.controls.CostofSale.value);
          if(this.parentForm.controls.CostingCustomerServiceFee!=undefined)
          this.parentForm.controls.CostingCustomerServiceFee.setValue (this.parentForm.controls.Markup.value);

        }
        this.paymentterms();
        //this.parentForm.controls.CostingSupplierNetBuyPrice.setValue(this.parentForm.controls.FCBuy.value + this.parentForm.controls.CostingSupplierTaxAmount.value);
      }
    }

  }
  CalculateNOvat() {


    if (this.parentForm.get('ITYPE').value == "Hotel") {
      this.buyExRate = (this.parentForm.get('EXRATE').value == "" || this.parentForm.get('EXRATE').value == 0) ? 1 : this.parentForm.get('EXRATE').value;
    }
    else {
      this.buyExRate = (this.parentForm.get('CURExRate').value == "" || this.parentForm.get('CURExRate').value == 0) ? 1 : this.parentForm.get('CURExRate').value;
    }

    this.parentForm.controls.CostofSale.setValue(((this.parentForm.controls.FCBuy.value * this.buyExRate) / this.folderExrate));
    this.parentForm.controls.FCSell.setValue(this.parentForm.controls.CostofSale.value + this.parentForm.controls.Markup.value);
    this.parentForm.controls.CostingSupplierGrossValue.setValue(this.parentForm.controls.FCBuy.value);
    this.parentForm.controls.CostingCustomerSalesValue.setValue(this.parentForm.controls.CostofSale.value);

    this.parentForm.controls.CostingCustomerServiceFee.setValue(this.parentForm.controls.Markup.value);
    this.parentForm.controls.CostingCustomerNetSellPrice.setValue(this.parentForm.controls.FCSell.value);
    //this.parentForm.controls.SELLAMT.setValue(parseFloat((this.parentForm.get('FCSell').value*this.exRate).toFixed(2)));



    if (this.parentForm.get('HCSell')) {

      this.parentForm.controls.HCSell.setValue(this.parentForm.get('FCSell').value * this.folderExrate)
    }
    if (this.parentForm.get('SELLAMT')) {

      this.parentForm.controls.SELLAMT.setValue(this.parentForm.get('FCSell').value * this.folderExrate)
    }
    this.parentForm.controls.PAYABLE.setValue(this.parentForm.get('FCBuy').value * this.buyExRate);
  }

  CalculationBuy(selvat, buyorsell,newmarkup) {
debugger;
    if (this.parentForm.get('ITYPE').value == "Hotel") {
      this.buyExRate = (this.parentForm.get('EXRATE').value == "" || this.parentForm.get('EXRATE').value == 0) ? 1 : this.parentForm.get('EXRATE').value;
    }
    else {
      this.buyExRate = (this.parentForm.get('CURExRate').value == "" || this.parentForm.get('CURExRate').value == 0) ? 1 : this.parentForm.get('CURExRate').value;
    }
    debugger;
    if (selvat == 1)//novat
    {


      this.parentForm.controls.CostofSale.setValue(parseFloat(((this.parentForm.controls.FCBuy.value * this.buyExRate) / this.folderExrate).toFixed(2)));
      this.parentForm.controls.FCSell.setValue(this.parentForm.controls.CostofSale.value + this.parentForm.controls.Markup.value);

    }
    if (selvat == 2)//vat on service
    {
      debugger;
      this.parentForm.controls.FCBuy.setValue(this.parentForm.controls.CostingSupplierGrossValue.value + this.parentForm.controls.CostingSupplierServiceFee.value)
      if (buyorsell == 1)
        //this.parentForm.controls.CostingSupplierTaxAmount.setValue(parseFloat(((this.parentForm.controls.CostingSupplierServiceFee.value * this.parentForm.controls.CostingTaxRate.value) / 100).toFixed(2)));
        this.TaxSplitCal(this.parentForm.controls.CostingSupplierServiceFee.value, 2);//to get the tax split rate


      this.parentForm.controls.CostingSupplierNetBuyPrice.setValue(this.parentForm.controls.FCBuy.value + this.parentForm.controls.CostingSupplierTaxAmount.value);

      this.parentForm.controls.CostofSale.setValue(parseFloat(((this.parentForm.controls.FCBuy.value * this.buyExRate) / this.folderExrate).toFixed(2)));
      if (buyorsell == 1 || buyorsell == 2)
        this.outputTaxSplit(selvat);
      //88888 this.parentForm.controls.CostingCustomerTaxAmount.setValue(parseFloat(((this.parentForm.controls.Markup.value * this.parentForm.controls.CostingTaxRate.value) / 100).toFixed(2)));
      this.parentForm.controls.FCSell.setValue(this.parentForm.controls.CostofSale.value + this.parentForm.controls.Markup.value);


      this.parentForm.controls.CostingCustomerNetSellPrice.setValue((this.parentForm.controls.FCSell.value + this.parentForm.controls.CostingCustomerTaxAmount.value));




    }

    if (selvat == 3)//vat on purchase
    {
      this.parentForm.controls.CostingSupplierGrossValue.setValue(this.parentForm.controls.CostingSupplierNetBuyPrice.value - this.parentForm.controls.CostingSupplierTaxAmount.value);

      this.parentForm.controls.FCBuy.setValue(this.parentForm.controls.CostingSupplierGrossValue.value);
      if (buyorsell == 1)
        this.TaxSplitCal(this.parentForm.controls.CostingSupplierGrossValue.value, 3);//to get the tax split rate


      //  this.parentForm.controls.CostingSupplierTaxAmount.setValue(parseFloat(((this.parentForm.controls.CostingSupplierGrossValue.value * this.parentForm.controls.CostingTaxRate.value) / 100).toFixed(2)));
    //  this.parentForm.controls.CostingSupplierNetBuyPrice.setValue(this.parentForm.controls.FCBuy.value + this.parentForm.controls.CostingSupplierTaxAmount.value);


      this.parentForm.controls.CostofSale.setValue(parseFloat(((this.parentForm.controls.CostingSupplierGrossValue.value * this.buyExRate) / this.folderExrate).toFixed(2)));
      if (buyorsell == 1 || buyorsell == 2) {
        let inputcalval = (this.parentForm.controls.CostofSale.value + this.parentForm.controls.Markup.value);
      if(newmarkup==0 ||(this.parentForm.controls.GUIDItinerary.value == null ||this.parentForm.controls.GUIDItinerary.value == '' ))
        this.outputTaxSplit(this.divValue);
      }
      // 88888        this.parentForm.controls.CostingCustomerTaxAmount.setValue(parseFloat((((this.parentForm.controls.CostofSale.value + this.parentForm.controls.Markup.value) * this.parentForm.controls.CostingTaxRate.value) / 100).toFixed(2)));
      if (this.parentForm.controls.GUIDItinerary.value == null ||this.parentForm.controls.GUIDItinerary.value == '' ) {
        this.parentForm.controls.FCSell.setValue(this.parentForm.controls.CostofSale.value + this.parentForm.controls.Markup.value);

      }
      else  if (newmarkup==1)
      {
        this.parentForm.controls.Markup.setValue(this.parentForm.controls.FCSell.value - this.parentForm.controls.CostofSale.value);
      if(this.parentForm.controls.CostingCustomerServiceFee!=undefined)
        this.parentForm.controls.CostingCustomerServiceFee.setValue (this.parentForm.controls.Markup.value);
      }
      else  if (newmarkup==2)
      {
        this.parentForm.controls.FCSell.setValue(this.parentForm.controls.CostofSale.value + this.parentForm.controls.Markup.value);

      }

     // this.parentForm.controls.CostingCustomerNetSellPrice.setValue((this.parentForm.controls.FCSell.value + this.parentForm.controls.CostingCustomerTaxAmount.value));
      this.parentForm.controls.CostingCustomerNetSellPrice.setValue((this.parentForm.controls.CostofSale.value +this.parentForm.controls.Markup.value+ this.parentForm.controls.CostingCustomerTaxAmount.value));

      
    }

    if (selvat == 4)//Vat on Supp.Commission
    {
      this.parentForm.controls.FCBuy.setValue(this.parentForm.controls.CostingSupplierGrossValue.value);
      // this.parentForm.controls.CostingSupplierTaxAmount.setValue((this.parentForm.controls.CostingSupplierGrossValue.value*this.parentForm.controls.CostingTaxRate.value)/100);
      // this.parentForm.controls.CostingSupplierNetBuyPrice.setValue(this.parentForm.controls.FCBuy.value+this.parentForm.controls.CostingSupplierTaxAmount.value);

      this.outputTaxSplit(selvat);
      // this.parentForm.controls.CostofSale.setValue((this.parentForm.controls.CostingSupplierGrossValue.value*this.buyExRate)/this.folderExrate);

      // this.parentForm.controls.CostingCustomerTaxAmount.setValue(((this.parentForm.controls.CostofSale.value+this.parentForm.controls.Markup.value)*this.parentForm.controls.CostingTaxRate.value)/100);
      // this.parentForm.controls.FCSell.setValue( this.parentForm.controls.CostofSale.value +this.parentForm.controls.Markup.value);

      // this.parentForm.controls.CostingCustomerNetSellPrice.setValue((this.parentForm.controls.FCSell.value+this.parentForm.controls.CostingCustomerTaxAmount.value));
      if (buyorsell == 1)
        this.TaxSplitCal(this.parentForm.controls.CostingSuppComm.value, 4);


      // this.parentForm.controls.GSTHSTOnComm.setValue(parseFloat(((this.parentForm.controls.CostingSuppComm.value * this.parentForm.controls.CostingTaxRate.value) / 100).toFixed(2)));
      this.parentForm.controls.CostofSale.setValue(parseFloat((((this.parentForm.controls.CostingSupplierGrossValue.value - this.parentForm.controls.CostingSuppComm.value) * this.buyExRate) / this.folderExrate).toFixed(2)));
      this.parentForm.controls.CostingCustomerNetSellPrice.setValue((this.parentForm.controls.CostofSale.value + this.parentForm.controls.Markup.value));



      ///this.parentForm.controls.FCSell.setValue( this.parentForm.controls.CostingCustomerNetSellPrice.value );

      this.parentForm.controls.CostingCustomerNetSellPrice.setValue(parseFloat((this.parentForm.controls.CostingCustomerNetSellPrice.value).toFixed(2)));


      this.parentForm.controls.FCSell.setValue(parseFloat((this.parentForm.controls.CostingCustomerNetSellPrice.value).toFixed(2)));

    }
    this.HCDetailsCalculation(selvat);
    this.TableFieldUpdation(selvat);

    if (this.parentForm.get('ITYPE').value == "Hotel") {
      this.TAXRATE = this.parentForm.get('SELLTAXRATE').value;
      if (this.TAXRATE == 0) {
        this.setvatvaluezero();
      }
      else {
        //var FolderExRate=1;        
        this.setTaxamount();

        if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
          this.parentForm.controls.FC_SELLTAXAMT.setValue(this.parentForm.controls.CostingCustomerTaxAmount.value);
        }
        else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {
          this.parentForm.controls.FC_SELLTAXAMT.setValue(this.parentForm.controls.CostingCustomerTaxAmount.value);
        }
        else {
          this.parentForm.controls.FC_SELLTAXAMT.setValue(parseFloat((this.FCSellTaxAmt).toFixed(2)));
        }
        // this.parentForm.controls.FC_SELLTAXAMT.setValue(parseFloat((this.FCSellTaxAmt ).toFixed(2)));
        if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'NV') {
          if (this.parentForm.get('HC_SELLTAXAMT') != null)
            this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.parentForm.controls.FCBuy.value).toFixed(2)));
          else
            this.parentForm.controls.SELLVAT.setValue(this.parentForm.controls.FCBuy.value);
        } else
          if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
            if (this.parentForm.get('HC_SELLTAXAMT') != null)
              this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2)));
            else
              this.parentForm.controls.SELLVAT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

          }
          else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {
            if (this.parentForm.get('HC_SELLTAXAMT') != null)
              this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2)));
            else
              this.parentForm.controls.SELLVAT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

          } else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSC') {
            if (this.parentForm.get('HC_SELLTAXAMT') != null)
              this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((0).toFixed(2)));
            else
              this.parentForm.controls.SELLVAT.setValue(parseFloat('0').toFixed(2));
          }
          else {
            if (this.parentForm.get('HC_SELLTAXAMT') != null)
              this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.HCSellTaxAmt).toFixed(2)));
            else
              this.parentForm.controls.SELLVAT.setValue(parseFloat((this.HCSellTaxAmt).toFixed(2)));
          }


      }


    }
    else {
      this.setTaxrate();




      if (this.TAXRATE == 0) {

        this.fillTaxrate();
      }
      else {
        this.setTaxamount();
        this.setTaxAmtandValue();
      }



    }
    this.paymentterms();
    //  if(this.parentForm.controls.ITYPE.value=="Hotel" ||this.parentForm.controls.ITYPE.value=="Others"||this.parentForm.controls.ITYPE.value=="Cruise"||this.parentForm.controls.ITYPE.value=="Car"|| this.parentForm.controls.ITYPE.value=="Rail"  )
    //     {

    if (this.parentForm.get('HC_SELLTAXAMT') != null)
      this.parentForm.controls.HC_SELLTAXAMT.setValue((this.parentForm.controls.HC_SELLTAXAMT.value * this.exRate).toFixed(2));
    else
      this.parentForm.controls.SELLVAT.setValue((this.parentForm.controls.SELLVAT.value * this.exRate).toFixed(2));
    //}
    this.spinner.hide();
  }
  calculateonExchangeRate() {
    if (this.parentForm.controls.CostingVATType != undefined) {
      var value = this.parentForm.controls.CostingVATType.value;
      if (value == 'NV') {
        this.CalculationBuy(1, 0,0)
      } else if (value == 'VSF') {

        this.CalculationBuy(2, 1,0)

      } if (value == 'VP') {


        this.CalculationBuy(3, 1,0)


      } if (value == 'VSC') {

        this.CalculationBuy(4, 1,0)


      }
    }
  }

  TableFieldUpdation(selvat) {
    if (selvat == 1) {
      this.parentForm.controls.CostingSupplierGrossValue.setValue(this.parentForm.controls.FCBuy.value);
      this.parentForm.controls.CostingCustomerSalesValue.setValue(this.parentForm.controls.CostofSale.value);
      this.parentForm.controls.CostingCustomerServiceFee.setValue(this.parentForm.controls.Markup.value);
      this.parentForm.controls.CostingCustomerNetSellPrice.setValue(this.parentForm.controls.FCSell.value);

    }
    else {
      this.parentForm.controls.CostingCustomerSalesValue.setValue(this.parentForm.controls.CostofSale.value);
      // this.parentForm.controls.CostingSupplierNetBuyPrice.setValue( this.parentForm.controls.CostofSale.value);
      this.parentForm.controls.CostingCustomerServiceFee.setValue(this.parentForm.controls.Markup.value);
    }


  }

  HCDetailsCalculation(selvat) {
    var payable_amount = 0;
    //   if(selvat==1)
    // {
    // if(this.parentForm.get('ITYPE').value=="Others") 

    if (this.parentForm.get('SELLAMT')) {

      this.parentForm.controls.SELLAMT.setValue((this.parentForm.get('FCSell').value) * this.folderExrate);
      this.parentForm.controls.SELLAMT.setValue(parseFloat((this.parentForm.controls.SELLAMT.value).toFixed(2)));

    }
    if (this.parentForm.get('HCSell')) {

      this.parentForm.controls.HCSell.setValue((this.parentForm.get('FCSell').value) * this.folderExrate);
      this.parentForm.controls.HCSell.setValue(parseFloat((this.parentForm.controls.HCSell.value).toFixed(2)));
    }
   
    if (this.parentForm.get('PAYABLE')) {
      this.parentForm.controls.PAYABLE.setValue(this.parentForm.get('FCBuy').value * this.buyExRate);
      this.parentForm.controls.PAYABLE.setValue(parseFloat((this.parentForm.controls.PAYABLE.value).toFixed(2)));
      payable_amount = this.parentForm.controls.PAYABLE.value;

    }
    if (this.parentForm.get('AMOUNT')) {
      this.parentForm.controls.AMOUNT.setValue(this.parentForm.get('FCBuy').value * this.buyExRate);
      this.parentForm.controls.AMOUNT.setValue(parseFloat((this.parentForm.controls.AMOUNT.value).toFixed(2)));

      // this.parentForm.controls.HCCommAmt.setValue(this.parentForm.get('HCSell').value-this.parentForm.get('AMOUNT').value);

      // this.parentForm.controls.HCCommAmt.setValue(parseFloat((this.parentForm.controls.HCCommAmt.value).toFixed(2)));
      payable_amount = this.parentForm.controls.AMOUNT.value;
    }
    if (this.parentForm.get('SELLCOMM')) {
      this.parentForm.controls.SELLCOMM.setValue(this.parentForm.get('SELLAMT').value - payable_amount);
      this.parentForm.controls.SELLCOMM.setValue(parseFloat((this.parentForm.controls.SELLCOMM.value).toFixed(2)));
    }


    // }

    if (this.parentForm.get('HCCommAmt')) {
      this.parentForm.controls.HCCommAmt.setValue(this.parentForm.get('HCSell').value - payable_amount);

      this.parentForm.controls.HCCommAmt.setValue(parseFloat((this.parentForm.controls.HCCommAmt.value).toFixed(2)));
    }
 

  }



  /*newCaculation*/
  private fCSellTax: number;
  private TAXRATE: number;
  private FCSellTaxAmt: number
  private HCSellTaxAmt: number;
  selectdiv(value) {
    debugger;
    var d = this.CostofSale;

    this.parentForm.controls.CostingVATType.setValue(value)
    if (value == 'NV') {
      this.commonser.issuvatonsupcomm = false;
      this.divValue = 1;
      this.CalculateHoteldetailsNOVAt();

    } else if (value == 'VSF') {
      this.commonser.issuvatonsupcomm = false;
      this.divValue = 2;
      this.CalculateFormVotService(0);


    } if (value == 'VP') {
      this.commonser.issuvatonsupcomm = false;
      this.divValue = 3;
      this.chkVat = true;
      this.CalculateFormVotService(0);


    } if (value == 'VSC') {
      this.commonser.issuvatonsupcomm = true;
      this.divValue = 4;
      this.chkVat = true;
      this.CalculateHoteldetailsSuppComm();

    }
  }
  InputTaxInitialLoad(value: any) {
    if (value != undefined && value != null && value.Code != undefined && value.Code != null) {
      this.subscription.add(
        this.Manualsalesservice.GetTaxSplit(value.Code).subscribe(x => {
          this.inputTaxDetails = x["ResponseData"]
          // if (this.divValue == 3 || this.divValue == 2 || this.divValue == 4) {

          //   this.CalculationBuy(this.divValue, 1);
          // }
        }))
        ;
    }
  }
  public InputTaxChange(value: any,newmarkup): void {
    debugger;
   
    if (value != undefined && value != null && value.Code != undefined && value.Code != null) {
      this.subscription.add(
        this.Manualsalesservice.GetTaxSplit(value.Code).subscribe(x => {
          this.inputTaxDetails = x["ResponseData"]
          if (this.divValue == 3 || this.divValue == 2 || this.divValue == 4) {
if(newmarkup==1)
{
  this.CalculationBuy(this.divValue, 1,1);
}
else
this.CalculationBuy(this.divValue, 1,0);
            
          }
        }))
        ;




      this.editinputtax = false;
      if (value != undefined) {

        this.fCSellTax = this.parentForm.get('FCSell').value;//this.parentForm.controls.FCSell.value;
        this.TAXRATE = this.parentForm.get('SELLTAXRATE').value;//this.parentForm.controls.SELLTAXRATE.value;


        this.parentForm.controls.CostingTaxNo.setValue(value.Code);



      }

      if (this.TAXRATE == 0) {
        this.parentForm.controls.FC_SELLTAXAMT.setValue(parseFloat((0).toFixed(2)));
        if (this.parentForm.get('HC_SELLTAXAMT') != null)
          this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((0).toFixed(2)));
        else
          this.parentForm.controls.SELLVAT.setValue(parseFloat((0).toFixed(2)));

      }
      else {
        var FolderExRate = this.exRate;
        this.FCSellTaxAmt = (this.TAXRATE * this.fCSellTax) / 100;//parseFloat(FCSell) * parseFloat(SellVat)/100;
        this.HCSellTaxAmt = (this.FCSellTaxAmt) * (FolderExRate);
        if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
          this.parentForm.controls.FC_SELLTAXAMT.setValue(this.parentForm.controls.CostingCustomerTaxAmount.value);
        }
        else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {
          this.parentForm.controls.FC_SELLTAXAMT.setValue(this.parentForm.controls.CostingCustomerTaxAmount.value);
        }
        else {
          this.parentForm.controls.FC_SELLTAXAMT.setValue(parseFloat((this.FCSellTaxAmt).toFixed(2)));
        }

        //  this.parentForm.controls.FC_SELLTAXAMT.setValue(parseFloat((this.FCSellTaxAmt ).toFixed(2)));  
        if (this.parentForm.get('HC_SELLTAXAMT') != null) {
          if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
            this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

          }
          else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {
            this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

          } else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSC') {
            this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat('0').toFixed(2));
          }
          else {
            this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.HCSellTaxAmt).toFixed(2)));
          }
        }
        // this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.HCSellTaxAmt).toFixed(2)));  
        else {
          if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
            if (this.parentForm.get('HC_SELLTAXAMT') != null)
              this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));
            else
              this.parentForm.controls.SELLVAT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));
          }
          else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {

            if (this.parentForm.get('HC_SELLTAXAMT') != null)
              this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));
            else
              this.parentForm.controls.SELLVAT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));
          }
          else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSC') {
            if (this.parentForm.get('HC_SELLTAXAMT') != null)
              this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat('0').toFixed(2));
            else
              this.parentForm.controls.SELLVAT.setValue(parseFloat('0').toFixed(2));
          }
          else {
            this.parentForm.controls.SELLVAT.setValue(parseFloat((this.HCSellTaxAmt).toFixed(2)));
          }

        }


      }



      if (this.parentForm.get('HC_SELLTAXAMT') != null)
        this.parentForm.controls.HC_SELLTAXAMT.setValue((this.parentForm.controls.HC_SELLTAXAMT.value * this.exRate).toFixed(2));
      else
        this.parentForm.controls.SELLVAT.setValue((this.parentForm.controls.SELLVAT.value * this.exRate).toFixed(2));

    }
    else {

      if (this.divValue == 4)
        this.parentForm.controls.GSTHSTOnComm.setValue(0);
      else
        this.parentForm.controls.CostingSupplierTaxAmount.setValue(0)
      this.inputTaxDetails = null;

      if (this.divValue == 3 || this.divValue == 2 || this.divValue == 4) {

        this.CalculationBuy(this.divValue, 1,0);
      }
    }
  }
  TaxSplitCal(baseval, selVat) {
    if (this.inputTaxDetails != undefined && this.inputTaxDetails != null && this.inputTaxDetails.length > 0) if (this.inputTaxDetails != undefined && this.inputTaxDetails != null && this.inputTaxDetails.length > 0) {

      let TaxCategoryval = this.inputTaxDetails.find(x => x.TaxCategory.toLowerCase() == "base");
      if (TaxCategoryval != undefined && TaxCategoryval != null) {
        TaxCategoryval.TaxAmount = baseval;
        if (this.parentForm.controls.CostingSupplierServiceFee.value != undefined) {
          this.inputTaxDetails.forEach(element => {
            let level = element.Level;
            let TaxAmount = element.TaxAmount;
            this.inputTaxDetails.forEach(element1 => {
              if (element1.FromLevel == level) {
                element1.TaxAmount = (TaxAmount * element1.TaxRate) / 100;
                element1.TaxAmount = +element1.TaxAmount.toFixed(2);
              }
            });

          });

          let sum = this.inputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes').reduce((sum, current) => sum + current.TaxAmount, 0);
          if (selVat == 4)
            this.parentForm.controls.GSTHSTOnComm.setValue(sum);
          //(parseFloat(((this.parentForm.controls.CostingSuppComm.value * this.parentForm.controls.CostingTaxRate.value) / 100).toFixed(2)));
          else
            this.parentForm.controls.CostingSupplierTaxAmount.setValue(sum)
        if(selVat==3)
        {
          this.parentForm.controls.CostingSupplierGrossValue.setValue(this.parentForm.controls.CostingSupplierNetBuyPrice.value - this.parentForm.controls.CostingSupplierTaxAmount.value);
          
        }    
          var TaxPer = this.inputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes' && x.TaxCategory.toLowerCase() != 'cess').reduce((sum, current) => sum + current.TaxRate, 0);
          this.parentForm.controls.SELLTAXRATE.setValue(TaxPer);
          this.parentForm.controls.CostingTaxRate.setValue(TaxPer);
        }
      }
    }
  }



  outputTaxChangeonLoad(value: any) {
    if (value != undefined && value != null) {
      this.parentForm.controls.SELLTAXNO.setValue(value.Code);
      //  this.parentForm.controls.CostingTaxNo.setValue(value.Code);
      this.subscription.add(
        this.Manualsalesservice.GetTaxSplit(value.Code).subscribe(x => {
          this.outputTaxDetails = x["ResponseData"]

        }))
        ;

    }
    else {
      this.outputTaxDetails = null;
      this.parentForm.controls.CostingCustomerTaxAmount.setValue(0);
      this.parentForm.controls.SELLTAXRATE.setValue(0);
    }
   
  }

  outputTaxChange(value: any) {
    debugger;
    if (value != undefined && value != null) {
      this.parentForm.controls.SELLTAXNO.setValue(value.Code);
      // this.parentForm.controls.CostingTaxNo.setValue(value.Code);
      this.subscription.add(
        this.Manualsalesservice.GetTaxSplit(value.Code).subscribe(x => {
          this.outputTaxDetails = x["ResponseData"]
          this.outputTaxSplit(this.divValue);
        }))
        ;
       
       
    }
    else {
      this.outputTaxDetails = null;
      this.parentForm.controls.CostingCustomerTaxAmount.setValue(0);
      this.parentForm.controls.SELLTAXRATE.setValue(0);
      this.parentForm.controls.SELLTAXNO.setValue(0);
      if (this.parentForm.controls.CostingVATType.value == 'VSC') {

        this.parentForm.controls.GSTHSTOnComm.setValue(0);


      }
    }
    
  }

  outputTaxSplit(val) {
    debugger;

    if (this.outputTaxDetails != undefined && this.outputTaxDetails != null && this.outputTaxDetails.length > 0) {

      let TaxCategoryval = this.outputTaxDetails.find(x => x.TaxCategory.toLowerCase() == "base");
      if (TaxCategoryval != undefined && TaxCategoryval != null) {
        if (val == 3)
          TaxCategoryval.TaxAmount = this.parentForm.controls.Markup.value + this.parentForm.controls.CostofSale.value;
        else if (val == 2)
          TaxCategoryval.TaxAmount = this.parentForm.controls.Markup.value;
        else
          TaxCategoryval.TaxAmount = this.parentForm.controls.CostingSuppComm.value;
        if (TaxCategoryval.TaxAmount != undefined) {
          this.outputTaxDetails.forEach(element => {
            let level = element.Level;
            let TaxAmount = element.TaxAmount;
            this.outputTaxDetails.forEach(element1 => {
              if (element1.FromLevel == level) {
                element1.TaxAmount = (TaxAmount * element1.TaxRate) / 100;
                element1.TaxAmount = +element1.TaxAmount.toFixed(2);
              }
            });

          });
          if (val == 4) {
            this.parentForm.controls.GSTHSTOnComm.setValue(
              this.outputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes').reduce((sum, current) => sum + current.TaxAmount, 0)
            )

          }
          else {
            this.parentForm.controls.CostingCustomerTaxAmount.setValue(
              this.outputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes').reduce((sum, current) => sum + current.TaxAmount, 0)
            )
          }
          var TaxPer = this.outputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes' && x.TaxCategory.toLowerCase() != 'cess').reduce((sum, current) => sum + current.TaxRate, 0);
          this.parentForm.controls.SELLTAXRATE.setValue(TaxPer);
          this.parentForm.controls.CostingTaxRate.setValue(TaxPer);
          if(val==3)
          this.OutputVatcalculation(2); 
          this.TaxAmountChange();
        }
      }
    }
    else {
      this.parentForm.controls.CostingCustomerTaxAmount.setValue(0);
      this.parentForm.controls.SELLTAXRATE.setValue(0);
      this.TaxAmountChange();
    }
  }


  calculatetxcommdiv4() {
    this.parentForm.controls.GSTHSTOnComm.setValue(this.parentForm.controls.CostingSuppComm.value * this.parentForm.controls.CostingTaxRate.value / 100);

  }
  CalculateFormVotService(value) {
    debugger;
    if (this.divValue != 3 && this.divValue != 4)
      this.parentForm.controls.CostofSale.setValue(this.parentForm.controls.CostingSupplierGrossValue.value + this.parentForm.controls.CostingSupplierServiceFee.value);

    if (value != 101)
      this.CalculateHoteldetailsVotserviceOupttax(0);

    this.calculateinputTax(value);
    this.CalculateFormsub();
  }
  CalculateFormVotServiceManualcalculation(value) {
    debugger;
    this.editinputtax = true;
    if (this.divValue != 3 && this.divValue != 4)
      this.parentForm.controls.CostofSale.setValue(this.parentForm.controls.CostingSupplierGrossValue.value + this.parentForm.controls.CostingSupplierServiceFee.value);


    this.CalculateHoteldetailsVotserviceOupttax(value);

    this.calculateinputTax(value);
    this.CalculateFormsub();
  }
  calculateinputTax(value) {
    debugger;
    if (value != 101) {
      if (this.divValue != 3 && this.divValue != 4)
        this.TaxSplitCal(this.parentForm.controls.CostingSupplierServiceFee.value, this.divValue);
      // this.parentForm.controls.CostingSupplierTaxAmount.setValue(this.parentForm.controls.CostingSupplierServiceFee.value * this.parentForm.controls.CostingTaxRate.value / 100);
      else if (this.divValue == 3)
        this.TaxSplitCal(this.parentForm.controls.CostingSupplierGrossValue.value, this.divValue);

      //this.parentForm.controls.CostingSupplierTaxAmount.setValue(this.parentForm.controls.CostingSupplierGrossValue.value * this.parentForm.controls.CostingTaxRate.value / 100);
    }
    if (this.divValue != 3 && this.divValue != 4)
      this.parentForm.controls.CostingSupplierNetBuyPrice.setValue(this.parentForm.controls.CostingSupplierTaxAmount.value + this.parentForm.controls.CostingSupplierServiceFee.value + this.parentForm.controls.CostingSupplierGrossValue.value);
    else if (this.divValue == 3)
    {
      this.parentForm.controls.CostingSupplierGrossValue.setValue(this.parentForm.controls.CostingSupplierNetBuyPrice.value - this.parentForm.controls.CostingSupplierTaxAmount.value);

    }
    if (this.divValue != 3 && this.divValue != 4)
      this.parentForm.controls.CostingCustomerNetSellPrice.setValue(this.parentForm.controls.CostofSale.value + this.parentForm.controls.Markup.value + this.parentForm.controls.CostingCustomerTaxAmount.value);
    if (this.divValue == 3)
      this.parentForm.controls.CostingCustomerNetSellPrice.setValue(this.parentForm.controls.Markup.value + this.parentForm.controls.CostingCustomerTaxAmount.value + this.parentForm.controls.CostofSale.value);


      if (+this.parentForm.controls.GUIDItinerary.value == 0 ) {
        this.parentForm.controls.FCSell.setValue(this.parentForm.controls.CostofSale.value + this.parentForm.controls.Markup.value);

      }
  
    //this.parentForm.controls.Tax.setValue( this.parentForm.controls.CostingTaxRate.value);
    //this.parentForm.controls.Tax.setValue( this.parentForm.controls.CostingCustomerTaxAmount.value);
    this.parentForm.controls.CostingCustomerServiceFee.setValue(this.parentForm.controls.Markup.value);
    this.parentForm.controls.CostingCustomerSalesValue.setValue(this.parentForm.controls.CostofSale.value);
    if (this.divValue == 2) {
      this.outputTaxSplit(this.divValue);
      // this.parentForm.controls.CostingCustomerTaxAmount.setValue(parseFloat((this.parentForm.controls.Markup.value * this.parentForm.controls.CostingTaxRate.value / 100).toFixed(2)));
      this.parentForm.controls.FCBuy.setValue(this.parentForm.controls.CostingSupplierGrossValue.value + this.parentForm.controls.CostingSupplierServiceFee.value);
    }
    else if (this.divValue == 3)
      this.parentForm.controls.FCBuy.setValue(this.parentForm.controls.CostingSupplierGrossValue.value);

  }
  CalculateHoteldetailsVotserviceOupttax(value) {
    debugger;
    if (value != 101) {
      if (this.divValue != 3 && this.divValue != 4)
        this.outputTaxSplit(this.divValue);
      //this.parentForm.controls.CostingCustomerTaxAmount.setValue(parseFloat((this.parentForm.controls.Markup.value * this.parentForm.controls.CostingTaxRate.value / 100).toFixed(2)));
      else if (this.divValue == 3)
        this.outputTaxSplit(this.divValue);
      //this.parentForm.controls.CostingCustomerTaxAmount.setValue(parseFloat(((this.parentForm.controls.Markup.value + this.parentForm.controls.CostofSale.value) * this.parentForm.controls.CostingTaxRate.value / 100).toFixed(2)));
    }
    else {
      if (this.divValue == 2) {
        this.parentForm.controls.CostingCustomerNetSellPrice.setValue((this.parentForm.controls.Markup.value + this.parentForm.controls.CostofSale.value + this.parentForm.controls.CostingCustomerTaxAmount.value));

      }
    }


    this.parentForm.controls.CostingCustomerServiceFee.setValue(this.parentForm.controls.Markup.value);

    this.parentForm.controls.CostingCustomerSalesValue.setValue(this.parentForm.controls.CostofSale.value);

  }

  CalculateHoteldetailsNOVAt() {

    this.parentForm.controls.SELLTAXNO.setValue('1');
    this.parentForm.controls.SELLTAXRATE.setValue('0');
    this.fCSellTax = +'0';
    this.TAXRATE = +'0';

    debugger;
    if (this.TAXRATE == 0) {
      this.parentForm.controls.FC_SELLTAXAMT.setValue(parseFloat((0).toFixed(2)));
      if (this.parentForm.get('HC_SELLTAXAMT') != null)
        this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((0).toFixed(2)));
      else
        this.parentForm.controls.SELLVAT.setValue(parseFloat((0).toFixed(2)));

    }
    if (this.parentForm.controls.FCBuy.value == undefined || this.parentForm.controls.FCBuy.value == null || this.parentForm.controls.FCBuy.value == '') {
      this.parentForm.controls.FCBuy.setValue(0);
    }
    else {
      if (this.parentForm.controls.AMTLC != undefined) {
        if (this.parentForm.controls.AMTLC.value != 0 && this.parentForm.controls.AMTLC.value != undefined && this.parentForm.controls.AMTLC.value != null) {
          this.parentForm.controls.CostofSale.setValue(this.parentForm.controls.AMTLC.value);
        }
        else {
          this.parentForm.controls.CostofSale.setValue(this.parentForm.controls.FCBuy.value);
        }
      }
      else if (this.parentForm.controls.AMTFC != undefined) {
        if (this.parentForm.controls.AMTFC.value != 0 && this.parentForm.controls.AMTFC.value != undefined && this.parentForm.controls.AMTFC.value != null) {
          this.parentForm.controls.CostofSale.setValue(this.parentForm.controls.AMTFC.value);
        }
        else {
          this.parentForm.controls.CostofSale.setValue(this.parentForm.controls.FCBuy.value);
        }
      }
      else {
        this.parentForm.controls.CostofSale.setValue(this.parentForm.controls.FCBuy.value);
      }


    }
    this.parentForm.controls.FCSell.setValue(this.parentForm.controls.CostofSale.value + this.parentForm.controls.Markup.value);
    this.parentForm.controls.CostingSupplierGrossValue.setValue(this.parentForm.controls.FCBuy.value);
    this.parentForm.controls.CostingCustomerSalesValue.setValue(this.parentForm.controls.CostofSale.value);

    this.parentForm.controls.CostingCustomerServiceFee.setValue(this.parentForm.controls.Markup.value);
    this.parentForm.controls.CostingCustomerNetSellPrice.setValue(this.parentForm.controls.FCSell.value);

    //  this.Checkcurrency();
    this.CalculateFormsub();
  }
  CalculateHoteldetailsSuppComm() {


    this.parentForm.controls.CostofSale.setValue(this.parentForm.controls.CostingSupplierGrossValue.value - this.parentForm.controls.CostingSuppComm.value);
    this.parentForm.controls.CostingCustomerSalesValue.setValue(this.parentForm.controls.CostofSale.value);
    this.parentForm.controls.CostingCustomerServiceFee.setValue(this.parentForm.controls.Markup.value);
    this.parentForm.controls.CostingCustomerNetSellPrice.setValue(this.parentForm.controls.CostofSale.value + this.parentForm.controls.Markup.value)
    this.parentForm.controls.FCBuy.setValue(this.parentForm.controls.CostofSale.value);
    this.parentForm.controls.FCSell.setValue(this.parentForm.controls.CostingCustomerNetSellPrice.value);
    this.calculatetxcommdiv4();
    this.CalculateFormsub();
  }
  getFolderValues() {
    // this.apiShared.folderCurrency.subscribe(fldrCurrency => this.fldrCurrency = fldrCurrency)
    // this.apiShared.folderExchangeRate.subscribe(folderExrate => this.folderExrate = folderExrate) CURID
    // this.exRate=parseFloat(this.folderExrate);
    this.fldrCurrency = (this.apiShared.folderCurrency == null || undefined || "" ? "" : this.apiShared.folderCurrency);
    this.folderExrate = (this.apiShared.folderExchangeRate == null || undefined || "" ? 0 : this.apiShared.folderExchangeRate);
    this.exRate = parseFloat(this.folderExrate);


  }
  Checkcurrencyload() {
    debugger;
    // if(this.parentForm.controls.ITYPE.value=="Others"||(this.parentForm.controls.ITYPE.value=="Hotel")||(this.parentForm.controls.ITYPE.value=="Ticket")||this.parentForm.controls.ITYPE.value=="Cruise")

    // {
    this.fldrCurrency = this.apiShared.folderCurrency;
    if (this.parentForm.controls.ITYPE.value == "Others") {
      //   if(this.parentForm.controls.CURID.value==this.fldrCurrency)
      //  {
      //    this.commonser.disablecurrency=false;
      //  }
      //  else{
      if (this.parentForm.controls.CostingVATType != undefined) {
        var value = this.parentForm.controls.CostingVATType.value;
        if (value == 'NV') {
          this.chkVat = false;
        } else if (value == 'VSF') {
          this.chkVat = true;
          this.divValue = 2;

        } if (value == 'VP') {
          this.chkVat = true;
          this.divValue = 3;



        } if (value == 'VSC') {
          this.chkVat = true;
          this.divValue = 4;


        }
      }

      // this.commonser.disablecurrency=true;
      //  }
      this.buycurrency = this.parentForm.controls.CURID.value;
      this.commonser.buycurrency = this.parentForm.controls.CURID.value;
    }
    else {
      if (this.parentForm.controls.CURCODE.value == this.fldrCurrency) {
        this.commonser.disablecurrency = false;
      }
      else {
        if (this.parentForm.controls.CostingVATType != undefined) {
          var value = this.parentForm.controls.CostingVATType.value;
          if (value == 'NV') {


            this.chkVat = false;
          } else if (value == 'VSF') {
            this.chkVat = true;
            this.divValue = 2;


          } if (value == 'VP') {
            this.chkVat = true;
            this.divValue = 3;



          } if (value == 'VSC') {
            this.chkVat = true;
            this.divValue = 4;

          }
        }
        this.commonser.disablecurrency = true;
      }
      this.buycurrency = this.parentForm.controls.CURCODE.value;
      this.commonser.buycurrency = this.parentForm.controls.CURCODE.value;
    }

    // }
  }
  Checkcurrency() {
    debugger;
    this.fldrCurrency = this.apiShared.folderCurrency;
    if (this.parentForm.controls.ITYPE.value == "Others") {
      if (this.parentForm.controls.CURID.value == this.fldrCurrency) {
        this.commonser.disablecurrency = false;
      }
      else {
        if (this.parentForm.controls.CostingVATType != undefined) {
          var value = this.parentForm.controls.CostingVATType.value;
          this.ShowVat = 1;
          if (value == 'NV') {

            this.parentForm.controls.CostofSale.setValue(0);
            this.parentForm.controls.Markup.setValue(0);
            this.parentForm.controls.FCSell.setValue(0);
            this.CalculationBuy(1, 0,0);


          } else if (value == 'VSF') {
            this.parentForm.controls.CostofSale.setValue(0);
            this.parentForm.controls.Markup.setValue(0);
            this.parentForm.controls.CostingCustomerTaxAmount.setValue(0);
            this.parentForm.controls.CostingCustomerNetSellPrice.setValue(0);
            this.CalculationBuy(2, 0,0);

          } if (value == 'VP') {
            this.parentForm.controls.CostofSale.setValue(0);
            this.parentForm.controls.Markup.setValue(0);
            this.parentForm.controls.CostingCustomerTaxAmount.setValue(0);
            this.parentForm.controls.CostingCustomerNetSellPrice.setValue(0);
            this.divValue = 3;
            this.CalculationBuy(3, 0,0);


          } if (value == 'VSC') {
            this.parentForm.controls.CostofSale.setValue(0);
            this.parentForm.controls.Markup.setValue(0);

            this.parentForm.controls.CostingCustomerNetSellPrice.setValue(0);
            this.CalculationBuy(4, 0,0);
          }
        }
        this.commonser.disablecurrency = true;
      }
      this.buycurrency = this.parentForm.controls.CURID.value;
      this.commonser.buycurrency = this.parentForm.controls.CURID.value;
    }
    else {
      if (this.parentForm.controls.CURCODE.value == this.fldrCurrency) {
        this.commonser.disablecurrency = false;
      }
      else {
        if (this.parentForm.controls.CostingVATType != undefined) {
          var value = this.parentForm.controls.CostingVATType.value;
          if (value == 'NV') {

            this.parentForm.controls.CostofSale.setValue(0);
            this.parentForm.controls.Markup.setValue(0);
            this.parentForm.controls.FCSell.setValue(0);
            this.CalculationBuy(1, 0,0);

          } else if (value == 'VSF') {
            this.parentForm.controls.CostofSale.setValue(0);
            this.parentForm.controls.Markup.setValue(0);
            this.parentForm.controls.CostingCustomerTaxAmount.setValue(0);
            this.parentForm.controls.CostingCustomerNetSellPrice.setValue(0);
            this.CalculationBuy(2, 0,0);

          } if (value == 'VP') {
            this.parentForm.controls.CostofSale.setValue(0);
            this.parentForm.controls.Markup.setValue(0);
            this.parentForm.controls.CostingCustomerTaxAmount.setValue(0);
            this.parentForm.controls.CostingCustomerNetSellPrice.setValue(0);
            this.divValue = 3;
            this.CalculationBuy(3, 0,0);


          } if (value == 'VSC') {
            this.parentForm.controls.CostofSale.setValue(0);
            this.parentForm.controls.Markup.setValue(0);

            this.parentForm.controls.CostingCustomerNetSellPrice.setValue(0);
            this.CalculationBuy(4, 0,0);
          }
        }
        this.commonser.disablecurrency = true;
      }
      this.buycurrency = this.parentForm.controls.CURCODE.value;
      this.commonser.buycurrency = this.parentForm.controls.CURCODE.value;
    }


  }
  onValueChanges(): void {
    this.parentForm.get('CURCODE').valueChanges.subscribe(val => {
      debugger;
      if (this.parentForm.controls.CURCODE.value == this.fldrCurrency) {
        this.commonser.disablecurrency = false;
      }
      else {
        this.commonser.disablecurrency = true;
      }
    });
  }
  Checkcurrencyparrnt(parentForm: any) {
    debugger;
    this.parentForm = parentForm;
    this.fldrCurrency = this.apiShared.folderCurrency;
    if (this.parentForm.controls.CURCODE.value == this.fldrCurrency) {
      this.disablecurrency = false;
    }
    else {
      this.disablecurrency = true;
    }
    this.Checkcurrency();

  }
  public TaxAmountChange() {

    // var FolderExRate=1;
    this.HCSellTaxAmt = (this.parentForm.get('FC_SELLTAXAMT').value) * (this.exRate);
    if (this.parentForm.get('HC_SELLTAXAMT') != null) {
      if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
        this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

      }
      else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {
        this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

      }
      else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSC') {
        this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat('0').toFixed(2));

      }
      else {
        this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.HCSellTaxAmt).toFixed(2)));
      }
      // this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.HCSellTaxAmt).toFixed(2))); 
    }

    else {
      if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
        this.parentForm.controls.SELLVAT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

      }
      else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {
        this.parentForm.controls.SELLVAT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

      } else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSC') {
        this.parentForm.controls.SELLVAT.setValue(parseFloat('0').toFixed(2));
      }
      else {
        this.parentForm.controls.SELLVAT.setValue(parseFloat((this.HCSellTaxAmt).toFixed(2)));
      }
      //   else if(this.parentForm.get('CostingVATType')!=null && this.parentForm.controls.CostingVATType.value=='VSC') {
      //    this.parentForm.controls.SELLVAT.setValue(this.parentForm.controls.CostofSale.value);
      //  }
      // this.parentForm.controls.SELLVAT.setValue(parseFloat((this.HCSellTaxAmt).toFixed(2))); 
    }


    //if (this.parentForm.controls.ITYPE.value == "Hotel" || this.parentForm.controls.ITYPE.value == "Others") {

      if (this.parentForm.get('HC_SELLTAXAMT') != null)
        this.parentForm.controls.HC_SELLTAXAMT.setValue((this.parentForm.controls.HC_SELLTAXAMT.value * this.exRate).toFixed(2));
      else
        this.parentForm.controls.SELLVAT.setValue((this.parentForm.controls.SELLVAT.value * this.exRate).toFixed(2));
    //}
  }
  get fp() { return this.parentForm.controls; }


  private nights: number;
  private rooms: number;
  private fCBuy: number;
  private buyExRate: number;
  private units: number;
  private amount: number;
  private balanceDueAmount: number;
  private defaultDepositPer: number;
  private hcBuy: number;
  CalculateFormsub() {
    debugger;
    var value = this.parentForm.controls.CostingVATType.value;
    if (value == 'NV') {


      this.CalculationBuy(1, 0,0);

    } else if (value == 'VSF') {

      this.CalculationBuy(2, 0,0);

    } if (value == 'VP') {

      this.CalculationBuy(3, 0,0);


    } if (value == 'VSC') {

      this.CalculationBuy(4, 0,0);
    }
    // }
  }

  paymentterms() {
    debugger;
    if (this.parentForm.get('ITYPE').value == "Hotel" || this.parentForm.get('ITYPE').value == "Insurance" || this.parentForm.get('ITYPE').value == "Car" || this.parentForm.get('ITYPE').value == "Tour" || this.parentForm.get('ITYPE').value == "Transfers")  //Remove rail from -Caseid-12619
    {
      this.hcBuy = parseFloat((this.parentForm.get('PAYABLE').value).toFixed(2));
    }
    else
      this.hcBuy = parseFloat((this.parentForm.get('AMOUNT').value).toFixed(2));

    this.amount = (this.parentForm.get('DEFDEP').value == "" || this.parentForm.get('DEFDEP').value == 0) ? 0 : this.parentForm.get('DEFDEP').value;
    this.defaultDepositPer = (this.parentForm.get('DEFDEPPER').value == "" || this.parentForm.get('DEFDEPPER').value == 0) ? 0 : this.parentForm.get('DEFDEPPER').value;

    if (this.defaultDepositPer != 0 && this.defaultDepositPer != 0.00) {
      this.amount = (this.hcBuy * this.defaultDepositPer) / 100;
    }
    this.balanceDueAmount = this.hcBuy - this.amount
    this.parentForm.controls.DEFDEP.setValue(parseFloat((this.amount).toFixed(2)));
    this.parentForm.controls.BalanceDueAmount.setValue(parseFloat((this.balanceDueAmount).toFixed(2)));
  }
  setTaxAmtandValue() {
    if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
      this.parentForm.controls.FC_SELLTAXAMT.setValue(this.parentForm.controls.CostingCustomerTaxAmount.value);
    }
    else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {
      this.parentForm.controls.FC_SELLTAXAMT.setValue(this.parentForm.controls.CostingCustomerTaxAmount.value);
    } else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSC') {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat('0').toFixed(2));
    }
    else {
      this.parentForm.controls.FC_SELLTAXAMT.setValue(parseFloat((this.FCSellTaxAmt).toFixed(2)));
    }
    // this.parentForm.controls.FC_SELLTAXAMT.setValue(parseFloat((this.FCSellTaxAmt ).toFixed(2))); 
    //this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.HCSellTaxAmt).toFixed(2)));
    if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

    }
    else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

    } else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSC') {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat('0').toFixed(2));
    }
    else {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.HCSellTaxAmt).toFixed(2)));
    }
  }
  sefcbuyonly() {
    if (this.parentForm.get('ITYPE').value === "Others") {
      this.units = (this.parentForm.get('QTY') == null || this.parentForm.get('QTY').value == 0) ? 1 : this.parentForm.get('QTY').value;
      this.fCBuy = this.parentForm.controls.AMTFC.value * this.units;
    }
    else {
      this.fCBuy = this.parentForm.controls.AMTLC.value;
    }
    this.parentForm.controls.FCBuy.setValue(parseFloat((this.fCBuy).toFixed(2)));
  }
  setTaxamount() {
    this.FCSellTaxAmt = (this.TAXRATE * this.parentForm.get('FCSell').value) / 100;//parseFloat(FCSell) * parseFloat(SellVat)/100;
    this.HCSellTaxAmt = (this.FCSellTaxAmt) * (this.exRate);
  }
  setFCBuyAndAMHC() {
    if (this.parentForm.get('ITYPE').value !== "Cruise" || this.parentForm.get('ITYPE').value !== "Rail") {
      if (this.parentForm.controls.PAYABLE !== undefined) {
        this.parentForm.controls.PAYABLE.setValue(parseFloat((this.fCBuy * this.buyExRate).toFixed(2)));
      }
    }

    this.parentForm.controls.AMTHC.setValue(parseFloat((this.fCBuy * this.buyExRate).toFixed(2)));
  }
  setvatvaluezero() {
    this.parentForm.controls.FC_SELLTAXAMT.setValue(0);
    if (this.parentForm.get('ITYPE').value !== "Cruise" && this.parentForm.get('ITYPE').value !== "Car" && this.parentForm.get('ITYPE').value !== "Tour" && this.parentForm.get('ITYPE').value !== "Insurance" && this.parentForm.get('ITYPE').value !== "Transfers" && this.parentForm.get('ITYPE').value !== "Rail") {
      this.parentForm.controls.SELLVAT.setValue(0);
    }
    else {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(0);
    }
  }
  fillTaxrate() {
    this.parentForm.controls.FC_SELLTAXAMT.setValue(parseFloat((0).toFixed(2)));
    this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((0).toFixed(2)));
  }
  setTaxrate() {
    if (this.parentForm.get('AMOUNT'))
      this.hcBuy = this.parentForm.get('AMOUNT').value;
    else
      this.hcBuy = this.parentForm.get('PAYABLE').value;
    this.parentForm.controls.HCSell.setValue(parseFloat((this.parentForm.get('FCSell').value * this.exRate).toFixed(2)));
    this.parentForm.controls.HCCommAmt.setValue(parseFloat((this.parentForm.get('HCSell').value - this.hcBuy).toFixed(2)));
    //this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.parentForm.get('FC_SELLTAXAMT').value*this.exRate).toFixed(2)));  
    if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

    }
    else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

    } else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSC') {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat('0').toFixed(2));
    }
    else {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.parentForm.get('FC_SELLTAXAMT').value * this.exRate).toFixed(2)));
    }

    this.TAXRATE = this.parentForm.get('SELLTAXRATE').value;
  }
  fillformdata() {
    this.hcBuy = this.parentForm.get('PAYABLE').value;
    this.parentForm.controls.HCSell.setValue(parseFloat((this.parentForm.get('FCSell').value * this.exRate).toFixed(2)));
    this.parentForm.controls.HCCommAmt.setValue(parseFloat((this.parentForm.get('HCSell').value - this.parentForm.get('PAYABLE').value).toFixed(2)));
    //this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.parentForm.get('FC_SELLTAXAMT').value*this.exRate).toFixed(2))); 
    if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSF') {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

    }
    else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VP') {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.parentForm.controls.CostingCustomerTaxAmount.value).toFixed(2));

    } else if (this.parentForm.get('CostingVATType') != null && this.parentForm.controls.CostingVATType.value == 'VSC') {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat('0').toFixed(2));
    }
    else {
      this.parentForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.parentForm.get('FC_SELLTAXAMT').value * this.exRate).toFixed(2)));
    }
    this.TAXRATE = this.parentForm.get('SELLTAXRATE').value;
  }
  onChanges(): void {

  }

  // ngOnChanges(changes: SimpleChanges) {
  //   debugger;
  //   if (changes.parentForm != undefined)
  //   // && this.parentForm.controls.CountryID.value != null && this.parentForm.controls.CountryID.value != undefined && this.parentForm.controls.CountryID.value != "") {
  //   {

  //     const MyFormChanges: SimpleChange = changes.parentForm;
  //     // To Check current values
  //     console.log(MyFormChanges.currentValue)

  //     // To Check previous values
  //     console.log(MyFormChanges.previousValue)

  //   } else {

  //   }
  // }

  getInputTaxLookup(country: any) {
    this.subscription.add(this.lookupApi.GetLookupById('InputTaxMaster', country, "''").subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.InputTax = data["ResponseData"];
          this.commonser.InputTax = data["ResponseData"];
          // alert(this.parentForm.controls.ITYPE.value);   
          if (this.parentForm.controls.ITYPE.value != "Ticket") {

            if (this.parentForm.controls.CostingTaxNo.value != "" && this.parentForm.controls.CostingTaxNo.value != undefined && this.parentForm.controls.CostingTaxNo.value != null) {
              debugger;

              this.InputTaxselectedItem = this.InputTax.find(item => item.Code == this.parentForm.controls.CostingTaxNo.value);
              this.commonser.InputTaxselectedItem = this.commonser.InputTax.find(item => item.Code == this.parentForm.controls.CostingTaxNo.value);
              // if (this.iseditinput == true)
              this.InputTaxInitialLoad(this.commonser.InputTaxselectedItem);
              // else
              //   this.iseditinput = true;
            }
          }
        }
      }
    ));
  }
  getOutTaxLookup(country: any) {
    this.subscription.add(this.lookupApi.GetLookupById('OutputTaxMaster', country, "''").subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.OutputTax = data["ResponseData"];
          this.commonser.OutputTax = data["ResponseData"];
          // alert(this.parentForm.controls.ITYPE.value);   
          if (this.parentForm.controls.ITYPE.value != "Ticket") {
            if (this.parentForm.controls.SELLTAXNO.value != "") {

              this.OutputTaxselectedItem = this.OutputTax.find(item => item.Code == this.parentForm.controls.SELLTAXNO.value);
              this.commonser.OutTaxselectedItem = this.commonser.OutputTax.find(item => item.Code == this.parentForm.controls.SELLTAXNO.value);
              // if (this.iseditoutput == true)
              this.outputTaxChangeonLoad(this.commonser.OutTaxselectedItem);
              // else
              //   this.iseditoutput = true;
            }
          }
        }
      }
    ));
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.commonser.OutTaxselectedItem = null;
    this.commonser.InputTaxselectedItem = null
  }
  public CurrencyChange(value: any): void {
    // console.log('selectionChange', value);
    debugger;
    if (value !== undefined && value !== null) {
      if (this.parentForm.get('ITYPE').value == "Cruise" || this.parentForm.get('ITYPE').value == 'Car' || this.parentForm.get('ITYPE').value == 'Tour' || this.parentForm.get('ITYPE').value == 'Rail' || this.parentForm.get('ITYPE').value == 'Insurance' || this.parentForm.get('ITYPE').value == 'Transfers' || this.parentForm.get('ITYPE').value == 'Hotel') {
        this.parentForm.controls.CURCODE.setValue(value.Code);
      }
      else {
        this.parentForm.controls.CURID.setValue(value.Code);
      }
    }
    else {
      if (this.parentForm.get('ITYPE').value == "Cruise" || this.parentForm.get('ITYPE').value == 'Car' || this.parentForm.get('ITYPE').value == 'Tour' || this.parentForm.get('ITYPE').value == 'Rail' || this.parentForm.get('ITYPE').value == 'Insurance' || this.parentForm.get('ITYPE').value == 'Transfers' || this.parentForm.get('ITYPE').value == 'Hotel') {
        this.parentForm.controls.CURCODE.setValue('');
      }
      else {
        this.parentForm.controls.CURID.setValue('');
      }
    }
    if (this.parentForm.get('ITYPE').value == "Cruise" || this.parentForm.get('ITYPE').value == 'Car' || this.parentForm.get('ITYPE').value == 'Tour' || this.parentForm.get('ITYPE').value == 'Rail' || this.parentForm.get('ITYPE').value == 'Insurance' || this.parentForm.get('ITYPE').value == 'Transfers' || this.parentForm.get('ITYPE').value == 'Hotel') {
      this.commonser.buycurrency = this.parentForm.controls.CURCODE.value;
    }
    else {
      this.commonser.buycurrency = this.parentForm.controls.CURID.value;
    }
    if (value == undefined && value == null) {
      this.Checkcurrency();
    }
    else
    {
      if (this.parentForm.controls.CURID!=undefined&& this.parentForm.controls.CURID.value == this.fldrCurrency) {
        this.commonser.disablecurrency = false;
      }
    }
  }
  onNoVatChange() {

    if (this.chkVat == false) {
      // this.parentForm.get('CostingVATType')!=null && this.parentForm.controls.CostingVATType.value=='NV'
      this.parentForm.controls.CostingVATType.setValue('NV')
      this.selectdiv('NV');
      this.ShowVat = 0;
      this.VSF = false;
      this.VP = false;
      this.VSC = false;
      this.NV = true;
    }
    else {
      this.selectdiv('VP');
      this.VP = true;
      this.ShowVat = 1;
      this.divValue = 3;
    }
  }
  public getCurrencyExRate() {
    if (this.parentForm.get('ITYPE').value == "Cruise" || this.parentForm.get('ITYPE').value == 'Car' || this.parentForm.get('ITYPE').value == 'Tour' || this.parentForm.get('ITYPE').value == 'Rail' || this.parentForm.get('ITYPE').value == 'Insurance' || this.parentForm.get('ITYPE').value == 'Transfers' || this.parentForm.get('ITYPE').value == 'Hotel') {
      if (this.parentForm.controls.CURCODE.value != '' && this.parentForm.controls.CURCODE.value != null) {

        if (this.parentForm.get('ITYPE').value == 'Rail') {
          this.getExchangeRate(this.parentForm.controls.CURCODE.value, this.parentForm.controls.TICKETDATE.value);
        }
        // (this.railEditForm.controls.TICKETDATE.value==null||this.railEditForm.controls.TICKETDATE.value==""?"01-Jan-1900":this.railEditForm.controls.TICKETDATE.value);
        else
          this.getExchangeRate(this.parentForm.controls.CURCODE.value, this.parentForm.controls.BookingDate.value);
      }
    }
    else {
      if (this.parentForm.controls.CURID.value != null) {
        this.getExchangeRate(this.parentForm.controls.CURID.value, this.parentForm.controls.BookingDate.value);
      }
    }
  }
  public suppartycode: any;
  CurrencyValueFromSupplier() {
    if (this.parentForm.get('ITYPE').value == "Cruise" || this.parentForm.get('ITYPE').value == 'Tour' || this.parentForm.get('ITYPE').value == 'Rail' || this.parentForm.get('ITYPE').value == 'Transfers' || this.parentForm.get('ITYPE').value == 'Others') {
      this.suppartycode = this.parentForm.controls.SUPPCODE.value;
    }
    else
      this.suppartycode = this.parentForm.controls.SUPPRTYCODE.value;
    debugger;
    if (this.suppartycode != undefined) {
      this.subscription.add(this.supplier.GetDefaultValue(this.suppartycode)
        .subscribe(data => {

          if (data !== '' && data !== null && data !== undefined) {

            if (data.CURRENCYCODE != "") {
              if (this.parentForm.get('ITYPE').value == "Cruise" || this.parentForm.get('ITYPE').value == 'Car' || this.parentForm.get('ITYPE').value == 'Tour' || this.parentForm.get('ITYPE').value == 'Rail' || this.parentForm.get('ITYPE').value == 'Insurance' || this.parentForm.get('ITYPE').value == 'Transfers' || this.parentForm.get('ITYPE').value == 'Hotel') {

                this.parentForm.controls.CURCODE.setValue(data.CURRENCYCODE);
                this.commonser.buycurrency = this.parentForm.controls.CURCODE.value;
                this.CurrencyselectedItem = this.CurrencyList.find(item => item.Code === this.parentForm.controls.CURCODE.value);

              }
              else {

                this.parentForm.controls.CURID.setValue(data.CURRENCYCODE);
                this.commonser.buycurrency = this.parentForm.controls.CURID.value;
                this.CurrencyselectedItem = this.CurrencyList.find(item => item.Code === this.parentForm.controls.CURID.value);


              }



              this.getCurrencyExRate();
            }
            //  this.parentForm.controls.PAYMENTID.setValue(data.PAYTERMID);
            // this.parentForm.controls.PMTID.setValue(data.PAYMTDID);


            //  if(this.parentForm.controls.CURCODE.value!=""&&this.parentForm.controls.CURCODE.value!=null)
            //    {   

            //     }
            //this.resetChildForm();
          }
        }));

    }

  }
  getExchangeRate(currency, bookingDate) {
    debugger;
    if (bookingDate == null || bookingDate == undefined || bookingDate == "") {
      bookingDate = new Date;
    }
    // this.savebutton=false;
    bookingDate = this.commonser.ConvertToLocaleDate(bookingDate);
    //  new Date(bookingDate).toLocaleDateString('en-GB', {
    //   day: 'numeric', month: 'short', year: 'numeric'
    // }).replace(/ /g, '-');
    bookingDate = bookingDate.replace('Sept', 'Sep')
    this.subscription.add(this.apiExRate.getExchangeRate(currency, bookingDate)
      .subscribe(data => {
        this.currencyExrate = data;
        if (this.parentForm.get('ITYPE').value == "Hotel")
          this.parentForm.controls.EXRATE.setValue(this.currencyExrate.ResponseData.EXRATE);
        if (this.parentForm.get('ITYPE').value == "Cruise" || this.parentForm.get('ITYPE').value == 'Car' || this.parentForm.get('ITYPE').value == 'Tour' || this.parentForm.get('ITYPE').value == 'Rail' || this.parentForm.get('ITYPE').value == 'Insurance' || this.parentForm.get('ITYPE').value == 'Transfers' || this.parentForm.get('ITYPE').value == 'Others') {
          this.parentForm.controls.CURExRate.setValue(this.currencyExrate.ResponseData.EXRATE);
          // this.savebutton=true;
        }
        //  if(this.parentForm.get('ITYPE').value!=="Hotel"&&this.parentForm.get('ITYPE').value!=="Others")
        // {

        //   this.CalculateFormsub();
        // }
        // else{
        this.calculateonExchangeRate();
        // }
      }));


  }
  public GetCurrencyExchangeRateTolerance() {
    debugger;
    var bookingDate = new Date();
    if (this.parentForm.get('ITYPE').value == "Cruise" || this.parentForm.get('ITYPE').value == 'Car' || this.parentForm.get('ITYPE').value == 'Tour' || this.parentForm.get('ITYPE').value == 'Rail' || this.parentForm.get('ITYPE').value == 'Insurance' || this.parentForm.get('ITYPE').value == 'Transfers' || this.parentForm.get('ITYPE').value == 'Hotel') {
      if (this.parentForm.get('ITYPE').value == 'Rail') {
        bookingDate = this.parentForm.controls.TICKETDATE.value;
      }
      else
        bookingDate = this.parentForm.controls.BookingDate.value;
    }
    else {
      if (this.parentForm.controls.CURID.value != null) {
        bookingDate = this.parentForm.controls.BookingDate.value;
      }
    }
    bookingDate = this.commonser.ConvertToLocaleDate(bookingDate != null ? bookingDate : new Date());
    if (this.CurrencyselectedItem != undefined) {
      this.spinner.show();
      this.cashservice.formData = new CashPaymentDTO();
      this.cashservice.formData.PVRDATE = this.commonser.ConvertToLocaleDate(new Date(bookingDate));
      this.cashservice.formData.date = this.commonser.ConvertToLocaleDate(new Date())
      this.cashservice.formData.HCID = this.CurrencyselectedItem.UsrCode;
      this.cashservice.formData.FCID = this.CurrencyselectedItem.UsrCode;
      this.cashservice.formData.RXRATE = this.parentForm.get('ITYPE').value == 'Hotel' ? this.parentForm.controls.EXRATE.value : this.parentForm.controls.CURExRate.value;
      this.cashservice.formData.LANQUAGE = this.apiShared.LanguageSelected;
      this.subscription.add(this.cashservice.GetCurrencyExchangeRateTolerance().subscribe(x => {
        debugger;
        if (x != null && x != undefined) {
          if (x["ResponseData"].messageid == 0) {
            if (this.parentForm.get('ITYPE').value == 'Hotel')
              this.parentForm.controls.EXRATE.setValue(x["ResponseData"].RXRATE);
            else
              this.parentForm.controls.CURExRate.setValue(x["ResponseData"].RXRATE);
          }
          else if (x["ResponseData"].messageid > 0) {
            var msg = 'Messages.msg' + x["ResponseData"].messageid;
            this.commonser.showWARNINGtoastrmsg(x["ResponseData"].message, 3000, true, false);
            if (this.parentForm.get('ITYPE').value == 'Hotel')
              this.parentForm.controls.EXRATE.setValue(x["ResponseData"].RXRATE);
            else
              this.parentForm.controls.CURExRate.setValue(x["ResponseData"].RXRATE);
            //this.getCurrencyExRate();
          }
          this.CalculateFormsub();
          this.spinner.hide();
        }
      }));
    } else {
      this.commonser.showWARNINGtoastrmsg('Messages.invalid_currency', 3000, true, false);
      this.CurrencyChange(null);
    }



  }

  CalculateForm() {
    // hotel

    this.nights = (this.parentForm.get('NIGHTS') == null || this.parentForm.get('NIGHTS').value == 0 || this.parentForm.get('NIGHTS').value == '') ? 1 : this.parentForm.get('NIGHTS').value;
    if (this.nights == null) {
      this.nights = 1;
    }
    this.rooms = this.parentForm.get('ROOMS').value == 0 ? 1 : this.parentForm.get('ROOMS').value;
    if (this.rooms == null) {
      this.rooms = 1;
    }
    this.buyExRate = (this.parentForm.get('EXRATE').value == '' || this.parentForm.get('EXRATE').value == 0) ? 1 : this.parentForm.get('EXRATE').value;
    if ((this.parentForm.get('AMTLC').value != null) && this.parentForm.get('AMTLC').value != 'undefined' && this.parentForm.get('AMTLC').value != 0) {

      this.fCBuy = this.parentForm.controls.AMTLC.value * this.nights * this.rooms;
      this.parentForm.controls.FCBuy.setValue(parseFloat((this.fCBuy).toFixed(2)));
      this.parentForm.controls.PAYABLE.setValue(parseFloat((this.fCBuy * this.buyExRate).toFixed(2)));
      this.parentForm.controls.AMTHC.setValue(parseFloat((this.parentForm.controls.AMTLC.value * this.buyExRate).toFixed(2)));

    } else {
      this.parentForm.controls.AMTHC.setValue(0);
      this.parentForm.controls.PAYABLE.setValue(parseFloat((this.parentForm.get('FCBuy').value * this.buyExRate).toFixed(2)));
    }
    this.hcBuy = this.parentForm.get('FCBuy').value * this.buyExRate;
    this.parentForm.controls.SELLAMT.setValue(parseFloat((this.parentForm.get('FCSell').value * this.exRate).toFixed(2)));
    this.parentForm.controls.SELLCOMM.setValue(parseFloat((this.parentForm.get('SELLAMT').value - this.parentForm.get('PAYABLE').value).toFixed(2)));
    /// Payment Terms

    this.amount = (this.parentForm.get('DEFDEP').value == '' || this.parentForm.get('DEFDEP').value == 0) ? 0 : this.parentForm.get('DEFDEP').value;
    this.defaultDepositPer = (this.parentForm.get('DEFDEPPER').value == '' || this.parentForm.get('DEFDEPPER').value == 0) ? 0 : this.parentForm.get('DEFDEPPER').value;

    if (this.defaultDepositPer != 0 && this.defaultDepositPer != 0.00) {
      this.amount = (this.hcBuy * this.defaultDepositPer) / 100;
    }

    this.balanceDueAmount = this.hcBuy - this.amount;
    this.parentForm.controls.DEFDEP.setValue(parseFloat((this.amount).toFixed(2)));
    this.parentForm.controls.BalanceDueAmount.setValue(parseFloat((this.balanceDueAmount).toFixed(2)));
    // Corperate Info


    this.parentForm.controls.ActualSaving.setValue(parseFloat((this.parentForm.get('PAYABLE').value * -1).toFixed(2)));
    // hotel
  }

  HCTaxvalue(input) {

    if (this.parentForm.controls.ITYPE.value == "Hotel" || this.parentForm.controls.ITYPE.value == "Others") {

      if (input == 1)
        this.parentForm.controls.SELLVAT.setValue((this.parentForm.controls.SELLVAT.value * this.exRate).toFixed(2));
      else
        this.parentForm.controls.HC_SELLTAXAMT.setValue((this.parentForm.controls.HC_SELLTAXAMT.value * this.exRate).toFixed(2));

    }
  }
  // ngAfterViewChecked() {
  //   alert(this.parentForm.controls.CostingSupplierTaxAmount.value)
  // }
  ngAfterViewInit() {
    debugger;


    this.subscription.add(this.parentForm.get('CountryID').valueChanges.subscribe(val => {
      if (val != null && val != undefined && val != "") {

        this.getInputTaxLookup(val);
      }



    })
    );


  }
  oncurrencychange()
  {
  this.showcurrexgerate=!this.showcurrexgerate;
  }

}

