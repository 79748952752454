import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../../env.service';
import { Invoicedefault } from '../Models/invoicedefault';
import { Observable, of } from 'rxjs';
import { Invoicedetails } from '../Models/invoicedetails';
import { tap, catchError } from 'rxjs/operators';
import { FolderInvoiceResponse } from '../Models/folder-invoice-response';
import { CommonserviceService } from './commonservice.service';
import { FolderReInvoiceResponse } from '../Models/folder-re-invoice-response';
import { FolderRefundResult } from '../Models/folder-refund-result';
import { DeleteInvoiceResponse } from '../Models/delete-invoice-response';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  apiUrl: string;
  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }


  GetDefaultInvoice(FolderNo: string): Observable<Invoicedefault> {
    const url = `${this.apiUrl}` + 'GetInvoiceValues' + `/${FolderNo}`;// `http://localhost:29048/GetFolderHistoryDetails/10001`;
    return this.http.get<Invoicedefault>(url);

  }

  GetInvoiceDetails(): Observable<Invoicedetails> {
    const url = `${this.apiUrl}` + 'GetOrderTypeDetails';
    return this.http.get<Invoicedetails>(url);
  }

  AddFolderInvoice(folderinvoice: any): Observable<FolderInvoiceResponse> {
    if (folderinvoice.DepDueDate == null)
      folderinvoice.DepDueDate = new Date("01-Jan-1900").toLocaleString();

    const url = `${this.apiUrl}` + 'FolderInvoice';///${id}
    return this.http.post<FolderInvoiceResponse>(url, folderinvoice, httpOptions).pipe(
      tap(_ => console.log(`Invoice Created`)),
      catchError(this.commonservice.handleError<FolderInvoiceResponse>('createInvoice'))
    );
  }
  FolderReinvoice(folderinvoice: any): Observable<FolderReInvoiceResponse> {
    const url = `${this.apiUrl}` + 'FolderReInvoice';
    return this.http.post<FolderReInvoiceResponse>(url, folderinvoice, httpOptions).pipe(
      tap(_ => console.log(`Reinvoiced`)),
      catchError(this.commonservice.handleError<FolderReInvoiceResponse>('ReInvoice'))
    );
  }
  FolderRefund(FolderRefund: any): Observable<FolderRefundResult> {
    const url = `${this.apiUrl}` + 'FolderRefund';
    return this.http.post<FolderRefundResult>(url, FolderRefund, httpOptions).pipe(
      tap(_ => console.log(`FolderRefund`)),
      catchError(this.commonservice.handleError<FolderRefundResult>('FolderRefund'))
    );
  }
  DeleteInvoice(folderinvoice: any): Observable<DeleteInvoiceResponse> {

    const url = `${this.apiUrl}` + 'FolderInvoiceDelete';
    return this.http.post<DeleteInvoiceResponse>(url, folderinvoice, httpOptions).pipe(
      tap(_ => console.log(`Invoice Deleted`)),
      catchError(this.commonservice.handleError<DeleteInvoiceResponse>('Deleted Invoice'))
    );
  }
  RefundReset(folderinvoice: any): Observable<FolderRefundResult> {
    const url = `${this.apiUrl}` + 'FolderRefundReset';
    return this.http.post<FolderRefundResult>(url, folderinvoice, httpOptions).pipe(
      tap(_ => console.log(`Refund Reset`)),
      catchError(this.commonservice.handleError<FolderRefundResult>('Refund Reset'))
    );
  }

  CancelledFolderReset(folderNO: any, projNo: any): Observable<any> {
    debugger;
    const url = `${this.apiUrl}` + 'CancelledFolderReset' + `/${folderNO}/${projNo}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`Cancelled folder reset`))
    );
  }


}
