import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Pendoclock } from '../Models/pendoclock'
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';
// 'http://localhost:29048/';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class PendoclockService {
  apiUrl: string;
  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }


  //Supplierss:Object

  //getSuppliers (): Observable<HotelDetail[]> {   

  ////return this.http.get<HotelDetail[]>(apiUrl)
  // .pipe(
  //   tap(heroes => console.log('fetched Suppliers')),
  // catchError(this.commonservice.handleError('getSuppliers', []))
  //);
  //}

  private newMethod(data: Object) {
    alert(data);
  }

  getLock(docType: string, docNo: string, userId: number, projno: string): Observable<Pendoclock> {
    const url = `${this.apiUrl}` + 'GetPenDocLockDetails' + `/${docType}/${docNo}/${userId}/${projno}/` + 'PenAirWeb';
    return this.http.get<Pendoclock>(url).pipe(
      tap(_ => console.log(`fetched lock id=${docNo}`)),
      catchError(this.commonservice.handleError<Pendoclock>(`getRail id=${docNo}`))
    );
  }
  public async getLockStatus(docType: string, docNo: string, userId: number, projno: string): Promise<Observable<Pendoclock>> {
    const url = `${this.apiUrl}` + 'GetPenDocLockDetails' + `/${docType}/${docNo}/${userId}/${projno}/` + 'PenAirWeb';
    var result = await this.http.get<Pendoclock>(url).pipe(
      tap(_ => console.log(`fetched lock id=${docNo}`)),
      catchError(this.commonservice.handleError<any>(`getRail id=${docNo}`))
    ).toPromise();
    return result;
  }
  // AddLock (penlock: any): Observable<Pendoclock> {
  //   return this.http.post<Pendoclock>(apiUrl+'AddRailDetails', penlock, httpOptions).pipe(
  //     tap((lockRes: Pendoclock) => console.log(`added Rail w/ id=${lockRes.ResponseData.UID}`)),
  //     catchError(this.commonservice.handleError<Pendoclock>('AddLock'))
  //   );
  // }

  DeleteLock(docType: string, docNo: string, updateAlert = 0): Observable<any> {

    const url = `${this.apiUrl}` + 'DeletePenDocLock' + `/${docType}/${docNo}/` + 'PenAirWeb/' + updateAlert;

    return this.http.post(url, httpOptions).pipe(
      tap(_ => console.log(`delete penDockLock`)),
      catchError(this.commonservice.handleError<any>('DeleteLock'))
    );
  }
  public async DeleteLockAsync(docType: string, docNo: string, updateAlert = 1): Promise<Observable<any>> {
    const url = `${this.apiUrl}` + 'DeletePenDocLock' + `/${docType}/${docNo}/` + 'PenAirWeb/' + updateAlert;
    var result = await this.http.post(url, httpOptions).pipe(
      tap(_ => console.log(`delete penDockLock`)),
      catchError(this.commonservice.handleError<any>('DeleteLock'))
    ).toPromise();
    return result;
  }
}