import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { OthersDetail } from '../Models/others-detail'
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';



const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({

  providedIn: 'root'
})
export class OthersService {
  apiUrl: string;
  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }



  //Supplierss:Object

  //getSuppliers (): Observable<HotelDetail[]> {   

  ////return this.http.get<HotelDetail[]>(apiUrl)
  // .pipe(
  //   tap(heroes => console.log('fetched Suppliers')),
  // catchError(this.commonservice.handleError('getSuppliers', []))
  //);
  //}

  private newMethod(data: Object) {
    // alert(data);
  }

  getOthers(folderNo: number, GuidItinerary: string, isViewForm: boolean): Observable<OthersDetail> {
    const url = `${this.apiUrl}` + 'GetOthersDetailsById' + `/${folderNo}/${GuidItinerary}/${isViewForm}`;

    // const url = `${apiUrl}/${folderNo}/${GuidItinerary}`;
    return this.http.get<OthersDetail>(url).pipe(
      tap(_ => console.log(`fetched Others id=${folderNo}`)),
      catchError(this.commonservice.handleError<OthersDetail>(`getOthers id=${folderNo}`))
    );
  }
  addOthers(OthersDetail: any): Observable<any> {

    if (OthersDetail.LOYALTYPOINTSAMT == null)
      OthersDetail.LOYALTYPOINTSAMT = 0;
    if (OthersDetail.LOYALTYPOINTS == null)
      OthersDetail.LOYALTYPOINTS = 0;

    if (OthersDetail.BookingDate === null || OthersDetail.BookingDate === '')
      OthersDetail.BookingDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      OthersDetail.BookingDate = this.commonservice.ConvertToLocaleDate(OthersDetail.BookingDate);
    if (OthersDetail.DepositDueDate === null || OthersDetail.DepositDueDate === '')
      OthersDetail.DepositDueDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      OthersDetail.DepositDueDate = this.commonservice.ConvertToLocaleDate(OthersDetail.DepositDueDate);
    // if(OthersDetail.BalanceDueDate===null || OthersDetail.BalanceDueDate==='')
    // OthersDetail.BalanceDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    // else
    // OthersDetail.BalanceDueDate=(OthersDetail.BalanceDueDate).toLocaleDateString();
    OthersDetail.BalanceDueDate = OthersDetail.BookingDate;

    if (OthersDetail.DefaultDepositPer == null || OthersDetail.DefaultDepositPer == "")
      OthersDetail.DefaultDepositPer = 0;
    if (OthersDetail.DefaultDepositAmt == null || OthersDetail.DefaultDepositAmt == "")
      OthersDetail.DefaultDepositAmt = 0;
    if (OthersDetail.OthersType == null || OthersDetail.OthersType == "")
      OthersDetail.OthersType = "";
    // if(OthersDetail.BookingDate==null)
    // OthersDetail.BookingDate="01-Jan-1900";

    return this.http.post<any>(this.apiUrl + 'AddOthersDetails', OthersDetail, httpOptions).pipe(
      tap((OthersRes: any) => console.log(`added Others w/ id=${OthersRes['ITYPE']}`)),
      catchError(this.commonservice.handleError<any>('addOthers'))
    );
  }

  updateOthers(OthersDetail: any): Observable<any> {
    console.log(OthersDetail);
    // if(OthersDetail.BookingDate==null)
    // OthersDetail.BookingDate="01-Jan-1900";

    if (OthersDetail.BookingDate === null || OthersDetail.BookingDate === '')
      OthersDetail.BookingDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      OthersDetail.BookingDate = this.commonservice.ConvertToLocaleDate(OthersDetail.BookingDate);
    if (OthersDetail.DepositDueDate === null || OthersDetail.DepositDueDate === '')
      OthersDetail.DepositDueDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      OthersDetail.DepositDueDate = this.commonservice.ConvertToLocaleDate(OthersDetail.DepositDueDate);
    if (OthersDetail.BalanceDueDate === null || OthersDetail.BalanceDueDate === '')
      OthersDetail.BalanceDueDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      OthersDetail.BalanceDueDate = this.commonservice.ConvertToLocaleDate(OthersDetail.BalanceDueDate);

    if (OthersDetail.LOYALTYPOINTSAMT == null)
      OthersDetail.LOYALTYPOINTSAMT = 0;
    if (OthersDetail.LOYALTYPOINTS == null)
      OthersDetail.LOYALTYPOINTS = 0;

    if (OthersDetail.DefaultDepositPer == null || OthersDetail.DefaultDepositPer == "")
      OthersDetail.DefaultDepositPer = 0;
    if (OthersDetail.DefaultDepositAmt == null || OthersDetail.DefaultDepositAmt == "")
      OthersDetail.DefaultDepositAmt = 0;
    if (OthersDetail.OthersType == null || OthersDetail.OthersType == "")
      OthersDetail.OthersType = "";
    const url = `${this.apiUrl}` + 'UpdateOthersDetails';///${id}

    return this.http.post(url, OthersDetail, httpOptions).pipe(
      tap(_ => console.log(`updated Others`)),
      catchError(this.commonservice.handleError<any>('updateOthers'))
    );
  }

  deleteOthers(folderNo: number, GuidItinerary: string): Observable<any> {

    const url = `${this.apiUrl}` + 'DeleteOthersDetails' + `/${folderNo}/${GuidItinerary}`;

    return this.http.post(url, httpOptions).pipe(
      tap(_ => console.log(`delete Tour`)),
      catchError(this.commonservice.handleError<any>('deleteTour'))
    );
  }

  addExpense(ExpenseDetail: any): Observable<any> {
    const url = `${this.apiUrl}` + 'AddExpense';///${id}
    return this.http.post<any>(url, ExpenseDetail, httpOptions).pipe(
      tap(_ => console.log(`Add Expense`)),
      catchError(this.commonservice.handleError<any>('AddExpense'))
    );
  }

}
