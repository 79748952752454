import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {  FolderProductTotal, HCFolderProductTotal } from '../Models/folder-product-total';
import { Observable, of,throwError  } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';


@Injectable({
  providedIn: 'root'
})
export class FolderproducttotalService {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   }


   getHCFolderProductTotal(folderNo: number): Observable<HCFolderProductTotal> {
    const url = `${this.apiUrl}`+'GetHCFolderPriceTotal'+`/${folderNo}`;
  
    return this.http.get<HCFolderProductTotal>(url).pipe(
      tap(_ => console.log(`fetched Product Total=${folderNo}`)),
      catchError(this.commonservice.handleError<HCFolderProductTotal>(`getProductTotal id=${folderNo}`))
    );
  }

  getProductTotal(folderNo: number): Observable<FolderProductTotal> {
    const url = `${this.apiUrl}`+'GetFolderPriceTotal'+`/${folderNo}`;
  
    return this.http.get<FolderProductTotal>(url).pipe(
      tap(_ => console.log(`fetched Product Total=${folderNo}`)),
      catchError(this.commonservice.handleError<FolderProductTotal>(`getProductTotal id=${folderNo}`))
    );
  }
  GetPackageTotal(folderNo: number): Observable<any> {
    const url = `${this.apiUrl}`+'GetPackageTotal'+`/${folderNo}`;
    return this.http.get<FolderProductTotal>(url).pipe(
      tap(_ => console.log(`fetched Product Vat=${folderNo}`)),
      catchError(this.commonservice.handleError<FolderProductTotal>(`GetPackageTotal id=${folderNo}`))
    );
  }
  
}
