import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { FolderSummaryService } from '../../Services/folder-summary.service';
import { TranslateService } from '@ngx-translate/core';
import { ShareDataService } from '../../Services/share-data.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CommonserviceService } from '../../Services/commonservice.service';
import { PassengerMasterService } from '../../Services/passenger-master.service';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { NgxSpinnerService } from 'ngx-spinner';
import { Localization_Service } from '../../Services/localization.service';

@Component({
  selector: 'app-passenger-master-add',
  templateUrl: './passenger-master-add.component.html',
  styleUrls: ['./passenger-master-add.component.scss']
})
export class PassengerMasterAddComponent implements OnInit {
  @ViewChild('autocompletecity') public autocompletecity: AutoCompleteComponent;
  @ViewChild('autocompletecustomer') public autocompletecustomer: AutoCompleteComponent;
  @Output() ppid = new EventEmitter<string>();
  @Output() closeStatus = new EventEmitter<string>();
  private subscription: Subscription = new Subscription();
  passengermasterform: FormGroup;
  public opened = false;
  openSearch = false;
  lookupname: any = 'CUSTOMER';
  lookuptype: string = '';
  private lookup: LookUpDetails;
  public IsAutocode = false;
  public reponse: any;
  public showMsg: any;
  public submitted = false;
  public bkdID: any;
  public listItems: Array<string> = ["", "Mr", "Miss", "Mrs", "Master", "Dr", "Prof", "Ms"];
  constructor(public commonservice: CommonserviceService, private passengerApi: PassengerMasterService, private apiShared: ShareDataService,
    private translateapi: TranslateService, private formBuilder: FormBuilder, private lookupApi: LookUpDetailsService,
    private api: FolderSummaryService, private toastr: ToastrService, private spinner: NgxSpinnerService,public LocService : Localization_Service) {
    this.bkdID = +(this.apiShared.uid);
    this.LocService.getJSONLanguage("Default")
    this.LoadBasicdat();
  }
  CountryList: Array<LookUpDetails["ResponseData"]> = [];
  public countrySelectedItem: LookUpDetails["ResponseData"];
  public countrySelected: String;

  branchList: Array<LookUpDetails["ResponseData"]> = [];
  public branchSelectedItem: LookUpDetails["ResponseData"];
  public branchSelected: String;

  businessAreaList: Array<LookUpDetails["ResponseData"]> = [];
  public businessAreaSelectedItem: LookUpDetails["ResponseData"];
  public businessAreaSelected: String;

  CityList: Array<LookUpDetails["ResponseData"]> = [];
  public cityselectedItem: LookUpDetails["ResponseData"];
  public cityselected: String;

  CustomerList: Array<LookUpDetails["ResponseData"]> = [];
  public customerSelectedItem: LookUpDetails["ResponseData"];
  public customerSelected: String;
  TerritoryList:Array<LookUpDetails["ResponseData"]> = [];
  public TerritotySelectedItem: LookUpDetails["ResponseData"];
  ngOnInit() {
    this.spinner.show();
    debugger;
    this.passengermasterform = this.formBuilder.group({
      ProfileID: [''],
      Title: [''],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Address1: ['',],
      Address2: ['',],
      Address3: ['',],
      CityId: [''],
      City: ['',],
      CountryID: ['',],
      Country: [''],
      PostCode: [''],
      CUSTCO: ['', Validators.required],
      CustUsrCode: [''],
      STATUS: 'Active',
      Branch: [''],
      BusinessArea: [''],
      MarketingCode: [''],
      Telephone: ['', Validators.pattern('[(+\\d)]{1,6}[\\d -]{7,12}\\d')],
      Email: ['', Validators.email],
      returnPPID: true,
      TerritoryName:[]
    })
   
    this.subscription.add(this.api.GetAutoCodeFlagforPassenger().subscribe(data => {
      if (data != null && data != undefined) {
        this.IsAutocode = data["ResponseData"];

        if (this.IsAutocode == false) {
          this.passengermasterform.controls.ProfileID.setValidators(Validators.required);
          this.passengermasterform.controls.ProfileID.updateValueAndValidity();
        }

      }
    }));
    this.getdefaultvalue();
  }
  get f() { return this.passengermasterform.controls; }
  public onFormSubmit() {
    this.submitted = true;
    if (this.passengermasterform.valid) {
      this.subscription.add(this.passengerApi.addPassenger(this.passengermasterform.value).subscribe(res => {
        debugger;
        if (res.ResponseData.success_bit == true && res.ResponseData.PPID > 0) {
          this.ppid.emit(res.ResponseData.PPID);
        }
        else {
          if (res.ResponseData.message == 'ProfileIDalreadyexists')
            this.commonservice.showWARNINGtoastrmsg('Messages.ProfileIDalreadyexists', 5000, true, false);
          else if (res.ResponseData.message.includes('EmailorTelephoneExists')) {
            this.subscription.add(
              this.translateapi.get(["Messages.EmailorTelephoneExists"]).subscribe((translations) => {
                this.showMsg = translations["Messages.EmailorTelephoneExists"].replace("<<ppuid>>", res.ResponseData.profileID)
                this.commonservice.showWARNINGtoastrmsg(this.showMsg, 5000, true, false);
              })
            );

          }

          else
            this.commonservice.showWARNINGtoastrmsg(res.ResponseData.message, 5000, true, false);
        }

      }))
      this.submitted = false;
    } else {
      this.commonservice.showWARNINGtoastrmsg('Messages.PleasefillMandatory', 5000, true, false);
    }
  }

  async customerlist() {


  }
  handleFiltercustomer(value) {
    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.SearchCustomer(value, this.bkdID).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.CustomerList = data["ResponseData"];
          }
        }
      ));
    } else {
      this.autocompletecustomer.toggle(false);
    }
  }
  public CustomerValueChange(value: any): void {
    debugger;

    if (value != undefined && (this.customerSelectedItem == undefined || this.customerSelectedItem.Status != 1)) {
      this.customerSelectedItem = this.CustomerList.find(item => item.Name == value);
    }
    if (value == undefined) {
      this.customerSelectedItem = null;
    }

    if (this.customerSelectedItem != null) {
      this.passengermasterform.controls.CUSTCO.setValue(this.customerSelectedItem.Code);
      this.passengermasterform.controls.CustUsrCode.setValue(this.customerSelectedItem.UsrCode);
      this.customerSelected = this.customerSelectedItem.Name;
      this.GetDefaultBAIDAndMCID();
    }
    else {
      this.customerSelected = "";
      this.passengermasterform.controls.CUSTCO.setValue("");
      this.passengermasterform.controls.CustUsrCode.setValue("");
    }
    // this.carEditForm.get("suppUserCodetext").setValue( this.supplierSelectedItem.Name);
  }
  handleFilterCity(value) {
    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.SearchCity(value, '1').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.CityList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletecity.toggle(false);
    }
  }
  TerrritoyValueChange(value: any)
{
  if(value!=null && value!=undefined){
    this.passengermasterform.controls.TerritoryName.setValue(value.Name);
    
}
else
{
  this.passengermasterform.controls.TerritoryName.setValue("");
  this.TerritotySelectedItem=null;
 
}
}
  public CityChange(value: any): void {
    this.cityselectedItem = this.CityList.find(item => item.Name == value);
    if (this.cityselectedItem != null) {
      this.passengermasterform.controls.City.setValue(this.cityselectedItem.ActualName);
      this.passengermasterform.controls.CityId.setValue(this.cityselectedItem.Code);
    }
    else {
      this.cityselected = "";
      this.passengermasterform.controls.City.setValue("");
      this.passengermasterform.controls.CityId.setValue("");
    }
  }
  public BranchValueChange(value: any): void {
    if (value != null && value != undefined)
      this.passengermasterform.controls.Branch.setValue(value.UsrCode);
    else
      this.passengermasterform.controls.Branch.setValue("");
  }
  public BusinessAreaValueChange(value: any): void {
    if (value != null && value != undefined)
      this.passengermasterform.controls.BusinessArea.setValue(value.UsrCode);
    else
      this.passengermasterform.controls.BusinessArea.setValue("");
  }
  public open() {

    this.opened = true;
  }
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'CUSTOMER') {
      this.lookupname = 'CUSTOMER';
    }
  }
  public close() {
    this.closeStatus.emit('close');
  }
  public closeLookup(status) {
    this.openSearch = false;
  }
  public LoadBasicdat() {

    this.subscription.add(this.lookupApi.GetAllLookup('COUNTRY').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.CountryList = data["ResponseData"];
         
          
            this.LocService.getJSONLanguage("Default")
          
        }
        this.subscription.add(this.api.GetDefaultCountry().subscribe(data => {
          if (data != null && data != undefined) {
            this.LocService.getJSONLanguage(data.ResponseData.UsrCode);
            this.GetTerritoryByCountry(data.ResponseData.UsrCode);
            this.countrySelectedItem = this.CountryList.find(item => item.UsrCode == data.ResponseData.UsrCode);
            this.passengermasterform.controls.CountryID.setValue(this.countrySelectedItem.UsrCode);
            this.passengermasterform.controls.Country.setValue(this.countrySelectedItem.Name);
          }
         
        }));
      }));
    this.subscription.add(this.lookupApi.GetAllLookup('BRANCH').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.branchList = data["ResponseData"]

          if (this.passengermasterform.controls.Branch.value != "") {
            this.branchSelectedItem = this.branchList.find(item => item.UsrCode == this.passengermasterform.controls.Branch.value);

            // console.log("test:-"+this.folderSummaryEditForm.controls.BRID.value+JSON.stringify(this.branchSelectedItem , null, 4));
          }
        }
      }));

    this.subscription.add(this.lookupApi.GetAllLookup('BUSINESSAREA').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.businessAreaList = data["ResponseData"]
          if (this.passengermasterform.controls.BusinessArea.value != "") {
            this.businessAreaSelectedItem = this.businessAreaList.find(item => item.Code == this.passengermasterform.controls.BusinessArea.value);
            //console.log("test:-"+this.folderSummaryEditForm.controls.BAID.value+JSON.stringify(this.businessAreaSelectedItem , null, 4));
          }
        }
      }));

  }

  public getdefaultvalue() {
    this.subscription.add(this.passengerApi.GetPassengerDefaults(this.bkdID).subscribe(data => {
      if (data != null && data != undefined) {
        if (this.branchList.length > 0) {
          this.branchSelectedItem = this.branchList.find(item => item.UsrCode == data.ResponseData.BRID);
          this.passengermasterform.controls.Branch.setValue(data.ResponseData.BRID);
        }
        if (this.businessAreaList.length > 0) {
          this.businessAreaSelectedItem = this.businessAreaList.find(item => item.UsrCode == data.ResponseData.BAID);
          this.passengermasterform.controls.BusinessArea.setValue(data.ResponseData.BAID);
        }
        this.passengermasterform.controls.CUSTCO.setValue(data.ResponseData.CUSTCO);
        this.getCustomerById();
      }
    }));

  }
  public GetDefaultBAIDAndMCID() {
    this.subscription.add(this.passengerApi.GetDefaultBAIDAndMCID(this.passengermasterform.controls.CUSTCO.value).subscribe(data => {
      if (data != null && data != undefined) {
        if (this.businessAreaList.length > 0) {
          this.businessAreaSelectedItem = this.businessAreaList.find(item => item.UsrCode == data.ResponseData.BAID);
          this.passengermasterform.controls.BusinessArea.setValue(data.ResponseData.BAID);
        }
        this.passengermasterform.controls.MarketingCode.setValue(data.ResponseData.MCID);
      }
    }));

  }
  getCustomerById() {
    if (this.passengermasterform.controls.CUSTCO.value != '' && this.passengermasterform.controls.CUSTCO.value != null) {

      this.subscription.add(this.lookupApi.findCustomer(this.passengermasterform.controls.CUSTCO.value).subscribe(
        (data) => {
          this.spinner.hide();
          if (data["ResponseData"] != null) {
            this.CustomerList = data["ResponseData"];
            if (this.passengermasterform.controls.CUSTCO.value != "") {

              this.customerSelectedItem = this.CustomerList.find(item => item.Code == this.passengermasterform.controls.CUSTCO.value);
              if (this.customerSelectedItem != null) {
                this.customerSelected = this.customerSelectedItem.Name;
                this.passengermasterform.controls.CustUsrCode.setValue(this.customerSelectedItem.UsrCode);
              }

            }
          }

        }
      ));
    }
  }
  public CountryValueChange(value: any): void {
    this.spinner.show();
    if (value != null && value != undefined) {
      value.Code=value.Code.toUpperCase();
      this.TerritoryList=[]
      this.TerritotySelectedItem=null;
      this.passengermasterform.controls.TerritoryName.setValue('');
      this.GetTerritoryByCountry(value.UsrCode);
      this.LocService.getJSONLanguage(value.UsrCode);
      this.passengermasterform.controls.CountryID.setValue(this.countrySelectedItem.UsrCode);
      this.passengermasterform.controls.Country.setValue(this.countrySelectedItem.Name);
    }
    else {
      this.spinner.hide();
      this.TerritoryList=[]
      this.TerritotySelectedItem=null;
      this.passengermasterform.controls.TerritoryName.setValue('');
      this.LocService.getJSONLanguage("Default")
      this.passengermasterform.controls.CountryID.setValue('');
      this.passengermasterform.controls.Country.setValue('');
    }
  }
  // }
GetTerritoryByCountry(usrcode)
{
  this.subscription.add(
    this.lookupApi
      .GetLookupById("Territory", usrcode, "1")
      .subscribe(data => {
        if(data!=null && data!=undefined)
        {
          debugger;
          this.spinner.hide();
         this.TerritoryList= data["ResponseData"] 
         if( this.TerritoryList.length>0)
         {
           if(this.passengermasterform.controls.TerritoryName.value!=""&& this.passengermasterform.controls.TerritoryName.value!='')
          this.TerritotySelectedItem=   this.TerritoryList.find(x=>x.Name==this.passengermasterform.controls.TerritoryName.value);
        else{
         // this.TerritotySelectedItem=   this.TerritoryList[0];
         // this.supplierdetailsform.controls.TerritoryName.setValue(this.TerritotySelectedItem.Name);
        }
        }
         
         else
         {
          this.passengermasterform.controls.TerritoryName.setValue('');
          this.TerritotySelectedItem=null;
         }
                     
      }
      else
      {
        this.spinner.hide();
      }
      }))
}
  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.customerSelectedItem = value;
        this.CustomerValueChange(value.Name);
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  ngOnDestroy() {
    console.log('ngOnDestorycust');
    this.subscription.unsubscribe();
  }
}

