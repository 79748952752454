import { Component, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { FolderSearchCriteriaDTO, CruiseSearchDTO } from '../../Models/folder-search-criteria-dto';
import { SearhdtotransferService } from '../../Services/searhdtotransfer.service';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
@Component({
  selector: 'app-searchby-cruise',
  templateUrl: './searchby-cruise.component.html',
  styleUrls: ['./searchby-cruise.component.scss']
})
export class SearchbyCruiseComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  constructor(public common: CommonserviceService, private searhTransferDTO: SearhdtotransferService, private lookupApi: LookUpDetailsService) { }
  SupplierList: Array<LookUpDetails['ResponseData']> = [];
  public statusList: Array<string> = ["Wait listed", "Confirmed", "Cancelled"];
  public cabinTypeList: Array<string> = ["Inside", "Outside", "Balcony", "Suite"];///12687
  public supplierSelectedItem: LookUpDetails['ResponseData'];
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  opened = false;
  openSearch = false;
  public supplierSelected: String;
  // cruiseSearchForm: FormGroup;

  flsrchDto: FolderSearchCriteriaDTO;
  CruiseSearchDTO: CruiseSearchDTO;
  ngOnChanges(changes: SimpleChanges) {

    this.CruiseSearchDTO = this.flsrchDto.CruiseSearchDTO;
  }
  menuIndex = 6;
  ngOnInit() {

    this.subscription.add(this.searhTransferDTO.SrchTranferDTO.subscribe(flsrchDto => {
      this.flsrchDto = flsrchDto;

    }))
    if (this.flsrchDto.CruiseSearchDTO != null)
      this.CruiseSearchDTO = this.flsrchDto.CruiseSearchDTO;
    else
      this.CruiseSearchDTO = new CruiseSearchDTO();
    // this.loadSupplier();
    if(this.CruiseSearchDTO.Supplier!="" && this.CruiseSearchDTO.Supplier !=undefined){
      this.loadSupplierValue(this.CruiseSearchDTO.Supplier);
    }
  }



  loadSupplierValue(value:any) {  //14068   v1.0 
    debugger; 

      this.subscription.add(this.lookupApi.findSupplier(value).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.SupplierList = data["ResponseData"];
            if (value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
              }

            }
          }

        }
      ));
    
  }

  public close(status) {

    this.opened = false;
    this.openSearch = false;
  }
  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860

  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  handleFiltersupplier(value) {

    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.GetLookupById("SUPPLIERS", value, value).subscribe(
        // this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  public SupplierValueChange(value: any): void {
    //  alert(value);
    if (this.SupplierList != null && this.SupplierList.length > 0) {//13860
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);

    }
    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860

      this.CruiseSearchDTO.Supplier = (this.supplierSelectedItem.Code);


    } else {
      this.supplierSelected = '';
      this.CruiseSearchDTO.Supplier = ('');


    }
    this.callChange()
  }


  callChange() {

    if (this.CruiseSearchDTO.Supplier != '' || this.CruiseSearchDTO.EmbtDateFromDt.toString() != '' || this.CruiseSearchDTO.EmbtDateToDt.toString() != ''
      || this.CruiseSearchDTO.DisembDateFromDt.toString() != '' || this.CruiseSearchDTO.DisembDateToDt.toString() != '' || this.CruiseSearchDTO.Status != '' || this.CruiseSearchDTO.Type != ''
      || this.CruiseSearchDTO.ConfirmationNo != '' || this.CruiseSearchDTO.VoucherNo != ''
    ) {

      if (this.CruiseSearchDTO.DisembDateToDt !== undefined) {
        if (this.CruiseSearchDTO.DisembDateToDt !== null && this.CruiseSearchDTO.DisembDateToDt.toString() !== '') {
          this.CruiseSearchDTO.DisembDateTo = this.common.transformDate(new Date(this.CruiseSearchDTO.DisembDateToDt));

          //  this.CruiseSearchDTO.DisembDateTo= new Date(this.CruiseSearchDTO.DisembDateToDt).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
        }
      }
      if (this.CruiseSearchDTO.EmbtDateToDt !== undefined) {
        if (this.CruiseSearchDTO.EmbtDateToDt !== null && this.CruiseSearchDTO.EmbtDateToDt.toString() !== '') {
          this.CruiseSearchDTO.EmbtDateTo = this.common.transformDate(new Date(this.CruiseSearchDTO.EmbtDateToDt));

          //  this.CruiseSearchDTO.EmbtDateTo= new Date(this.CruiseSearchDTO.EmbtDateToDt).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
        } else {
          this.CruiseSearchDTO.EmbtDateTo = null;

        }
      }
      // if(this.CruiseSearchDTO.DisembDateFromDt==null&&this.CruiseSearchDTO.DisembDateToDt!=null)
      // {
      //   this.CruiseSearchDTO.DisembDateFrom= this.CruiseSearchDTO.DisembDateTo;
      // }



      if (this.CruiseSearchDTO.DisembDateFromDt !== undefined) {
        if (this.CruiseSearchDTO.DisembDateFromDt !== null && this.CruiseSearchDTO.DisembDateFromDt.toString() !== '') {
          this.CruiseSearchDTO.DisembDateFrom = this.common.transformDate(new Date(this.CruiseSearchDTO.DisembDateFromDt));
          //  this.CruiseSearchDTO.DisembDateFrom= new Date(this.CruiseSearchDTO.DisembDateFromDt).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
        } else {
          this.CruiseSearchDTO.DisembDateFrom = null;

        }
      }
      if (this.CruiseSearchDTO.EmbtDateFromDt !== undefined) {
        if (this.CruiseSearchDTO.EmbtDateFromDt !== null && this.CruiseSearchDTO.EmbtDateFromDt.toString() !== '') {
          this.CruiseSearchDTO.EmbtDateFrom = this.common.transformDate(new Date(this.CruiseSearchDTO.EmbtDateFromDt));

          //  this.CruiseSearchDTO.EmbtDateFrom= new Date(this.CruiseSearchDTO.EmbtDateFromDt).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
        } else {
          this.CruiseSearchDTO.EmbtDateFrom = null;

        }
      }


      // if(this.CruiseSearchDTO.EmbtDateFromDt==null&&this.CruiseSearchDTO.EmbtDateToDt!=null)
      // {
      //   this.CruiseSearchDTO.EmbtDateFrom= this.CruiseSearchDTO.EmbtDateTo;
      // }



      this.flsrchDto.CruiseSearchDTO = this.CruiseSearchDTO;
    }
    else
      this.flsrchDto.CruiseSearchDTO = null;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
