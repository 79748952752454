import { Component, OnInit } from '@angular/core';

import { Mealplanmaster, MealplanmasterRequestDTO } from '../Models/mealplanmaster';
import { ActivatedRoute, Router } from '@angular/router';
import { Mealplanmasterservice } from '../Services/mealplanmasterservice';
import { NgxSpinnerService } from "ngx-spinner";
import { ResponseData } from '../Models/response-data';
import { observable, Subscription, Observable } from 'rxjs';
import { ShareDataService } from '../Services/share-data.service';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { CommonserviceService } from '../Services/commonservice.service';
import { DynamicpassengerdetailsService } from '../Services/dynamicpassengerdetails.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AlertserviceService } from '../Services/alertservice.service';
import { EmailService } from '../Services/email.service';
import { saveAs } from '@progress/kendo-file-saver';
import { Workbook, ExcelExportData } from '@progress/kendo-angular-excel-export';
import { FileService } from '../Services/file.service';
import { PageChangeEvent, PageSizeItem } from '@progress/kendo-angular-grid';
@Component({
  selector: 'app-mealplanmaster',
  templateUrl: './mealplanmaster.component.html',
  styleUrls: ['./mealplanmaster.component.scss']
})
export class MealplanmasterComponent implements OnInit {

  private subscription: Subscription = new Subscription();
  public view: any[];
  public gridData: any;
  public itemToRemove: any;
  public showMsg: any;
  public submitted = false;
  public opened: any = false;
  public formGroup: FormGroup;
  public formeditable = true;
  folderEditMode: any;
  private editedRowIndex: number;
  showFilter = false;
  public windowTop: number = -100;
  public windowLeft: number = -225;
  public left = this.windowLeft;
  public top = this.windowTop;
  public width = window.innerWidth - 40;
  public height = 770;
  public openMealPlanMasterReport: any = false;
  public reportParms: any;
  public Componentname: string;
  public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  public pageSizes: (PageSizeItem | number)[] = [20, 50, 100, {
    text: 'All',
    value: 'all',
}];
  fromId: string;
  bccId: string;
  userID: number;

  constructor(private toastr: ToastrService, public common: CommonserviceService, private emailapi: FileService, public email: EmailService, private formBuilder1: FormBuilder, private Mealplanmasterservice: Mealplanmasterservice, private route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private apiShared: ShareDataService, private flashMessage: FlashMessagesService, private translateapi: TranslateService) {

    this.removeConfirmation = this.removeConfirmation.bind(this);
    this.allData = this.allData.bind(this);
  }
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  public sort: SortDescriptor[] = [  //12731
    {
      field: 'MPM_Id',
      dir: 'asc'
    }
  ];
  ngOnInit() {
    this.loadGridData();
    this.userID = +(this.apiShared.uid);
    this.subscription.add(this.emailapi.GetReportEmail(this.userID).subscribe(data => {
      if (data != null && data != undefined) {
        this.fromId = data.ResponseData.FROMEMAIL,
          this.bccId = data.ResponseData.DEFAULTBCCMAIL
      }
    }));


    this.Componentname = "Mealplanmaster";
    //this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654
    sessionStorage.setItem('Componentname', this.Componentname);
  }
  public allData(): ExcelExportData { //By Caseid-12617
    const result: ExcelExportData = {
      data: this.gridData
    };
    return result;
  }
  public loadGridData() {

    this.subscription.add(this.Mealplanmasterservice.GetMealPlanDetails().subscribe(data => {
      if (data != null && data != undefined) {
        this.gridData = data["ResponseData"];
        this.common.masterData=this.gridData;
      }
      else {
        this.gridData = [];
        this.common.masterData= [];
      }


    }));
  }
  public createFormGroup(dataItem: any): FormGroup {


    return this.formBuilder.group({
      'MPM_Id': [dataItem.MPM_Id, Validators.required],
      'MPM_Description': [dataItem.MPM_Description, Validators.required],
      'MPM_CrUID': this.apiShared.uid,
      'MPM_CrDate': this.common.ConvertToLocaleDate(new Date()),
      "MPM_CrTime": new Date().toLocaleString(),
      'MPM_LupUID': this.apiShared.uid,
      'MPM_LupDate': this.common.ConvertToLocaleDate(new Date()),
      "MPM_LupTime": new Date().toLocaleString(),
      'MPM_AutoNo': dataItem.MPM_AutoNo
    });

  }
  onCancel() {
    this.router.navigate(['PenAir/Home']);
  }

  closeEmail(status : any){
if( status == "EmailSendSuccesfully" || status == 'Closed' ){ //13552 email modification
  this.opened = false;
}
  }


  public onExcelExport2(args: any): void {
    this.email.filename = 'MealPlanMaster.xlsx';
    this.email.reportname = 'Meal Plan Master';
    // Prevent automatically saving the file. We will save it manually after we fetch and add the details
    args.preventDefault();
    // this.open();
    const observables = [];
    const workbook = args.workbook;
    const rows = workbook.sheets[0].rows;
    // Get the default header styles.
    // Aternatively set custom styles for the details
    // https://www.telerik.com/kendo-angular-ui/components/excelexport/api/WorkbookSheetRowCell/
    const headerOptions = rows[0].cells[0];
    // create a Workbook and save the generated data URL
    // https://www.telerik.com/kendo-angular-ui/components/excelexport/api/Workbook/
    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      // https://www.telerik.com/kendo-angular-ui/components/filesaver/
      saveAs(dataUrl, 'MealPlanMaster.xlsx');
      this.email.fileurl = this.dataURItoBlob(dataUrl);
    });
  }
  public open() {
    this.opened = true;
  }
  public close(status : any) {
    if(status=="EmailSendSuccesfully" || status == 'Closed')
    {
      this.opened = false;
    }
  }
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // // create a view into the buffer
    var ia = new Uint8Array(ab);

    // // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }
  public async addHandler({ sender }) {
    if (Boolean(await this.common.checkPermission1('01.11.32.02.03.05.01')) == true) {
      this.submitted = false;

      sender.addRow(this.createFormGroup(new MealplanmasterRequestDTO()));
    }
    else {
      this.ShowPermissionMessageForm('01.11.32.02.03.05.01');//339
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.11.32.02.03.05.01');
    }

  }
  public cancelHandler({ sender, rowIndex }) {

    sender.closeRow(rowIndex);
  }
  public async editHandler({ sender, rowIndex, dataItem }) {
    debugger;

    if (Boolean(await this.common.checkPermission1('01.11.32.02.03.05.01')) == true) {
      this.submitted = false;
      this.closeEditor(sender);

      this.formGroup = this.createFormGroup(dataItem);
      this.editedRowIndex = rowIndex;
      sender.editRow(rowIndex, this.formGroup);
    }
    else {
      this.ShowPermissionMessageForm('01.11.32.02.03.05.01');//339
      // this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.11.32.02.03.05.01');
    }


  }
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;


  }


  public async saveHandler({ sender, rowIndex, formGroup, isNew }) {
    debugger;
    if (Boolean(await this.common.checkPermission1('01.11.32.02.03.05.01')) == true) {
      if (formGroup.valid) {
        this.submitted = true;
        if (isNew === true) {
          //   formGroup.value['id']=-1;


          this.subscription.add(this.Mealplanmasterservice.AddMealPlanDetails(formGroup.value).subscribe(res => {
            this.spinner.hide();
            if (res != null && res != undefined) {

              if (res["ResponseData"].Status == "Success") {

                //this.prtycode=res.ResponseData.ID;
                this.showMsg = res["ResponseData"].StatusDetails;
                this.toastr.success(this.showMsg, "",
                  {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })

              }
              else if (res["ResponseData"].Status == "Failed") {
                debugger;
                this.showMsg = res["ResponseData"].StatusDetails;
                this.toastr.warning(this.showMsg, "",
                  {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
              }
              else {
                debugger;
                this.showMsg = res["ResponseData"].StatusDetails;
                this.toastr.warning(this.showMsg, "",
                  {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
              }
              this.subscription.add(this.Mealplanmasterservice.GetMealPlanDetails().subscribe(data => {
                this.gridData = data["ResponseData"];
                this.spinner.hide();
                //   alert(JSON.stringify(res.ResponseData));
                sender.closeRow(rowIndex);

              }));
            }
          }, (err) => {
            this.spinner.hide();
            // console.log(err);
            this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {

              this.showMsg = translations['Messages.some_error_in_save'];
              this.toastr.warning(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,

                closeButton: true
              });
            }));

          }));
        }
        else {


          this.subscription.add(this.Mealplanmasterservice.UpdateMealPlanDetails(formGroup.value).subscribe(res => {
            this.spinner.hide();


            if (res != null && res != undefined) {
              if (res["ResponseData"].Status == "Success") {
                debugger;
                //this.prtycode=res.ResponseData.ID;
                this.showMsg = res["ResponseData"].StatusDetails;
                this.subscription.add(this.Mealplanmasterservice.GetMealPlanDetails().subscribe(data => {
                  this.gridData = data["ResponseData"];
                  this.spinner.hide();

                  sender.closeRow(rowIndex);

                }));
                this.toastr.success(this.showMsg, "",
                  {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })

              }
              else if (res["ResponseData"].Status == "Failed") {
                debugger;
                this.showMsg = res["ResponseData"].StatusDetails;
                sender.closeRow(rowIndex);
                this.toastr.warning(this.showMsg, "",
                  {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
              }
              else {
                debugger;
                this.showMsg = res["ResponseData"].StatusDetails;
                this.toastr.warning(this.showMsg, "",
                  {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
              }
            }
          }));

        }
      }
    }

    else {
      this.ShowPermissionMessageForm('01.11.32.02.03.05.01');//339
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.11.32.02.03.05.01');
    }

  }



  public confirmRemove(shouldRemove: boolean): void {
    this.removeConfirmationSubject.next(shouldRemove);
    if (shouldRemove) {

      this.subscription.add(this.Mealplanmasterservice.DeleteMealPlanDetails(this.itemToRemove.MPM_AutoNo).subscribe(res => {
        this.loadGridData();
        this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {
          this.showMsg = translations['Messages.deleted_success'];
          this.toastr.success(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          });
        }));
      }, (err) => {
        console.log(err);
        this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {
          this.showMsg = translations['Messages.Failed'];
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          });
        }));

      }));
    }
    this.itemToRemove = null;

  }

  public removeConfirmation(dataItem): Subject<boolean> {
    this.itemToRemove = dataItem;

    return this.removeConfirmationSubject;
  }

  public async removeHandler({ sender, dataItem }) {
    if (Boolean(await this.common.checkPermission1('01.11.32.02.03.05.02')) == true) {
      this.itemToRemove = dataItem;
      return this.removeConfirmationSubject;
      sender.cancelCell();
    }

    else {
      this.ShowPermissionMessageForm('01.11.32.02.03.05.02');//339
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.11.32.02.03.05.02');
    }

  }


  public onFilterClick()//Caseid 12643
  {
    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.common.masterData= [];

  }
  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339

  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }

  public reportFile: string = "MealPlanMaster.trdp";//12617
  public reportHeader = "Meal Plan Master";
  public reportSubject: string = "Meal Plan Master";
  public UserId = 1;
  public MealPlanId: any;
  public MealPlanDesc: any;
  public filterChange(colName: any)//12617
  {
    debugger;
    this.MealPlanId = "";
    this.MealPlanDesc = "";

    if (colName.filters[0] != undefined) {

      if (colName.filters[0].field == "MPM_Id")
        this.MealPlanId = colName.filters[0].value;
      if (colName.filters[0].field == "MPM_Description")
        this.MealPlanDesc = colName.filters[0].value;

    }
    if (colName.filters[1] != undefined) {

      if (colName.filters[1].field == "MPM_Id")
        this.MealPlanId = colName.filters[1].value;
      if (colName.filters[1].field == "MPM_Description")
        this.MealPlanDesc = colName.filters[1].value;

    }

  }
  public OpenMealPlanMasterReport()//12617
  {
    debugger;
    this.reportFile = "MealPlanMaster.trdp";
    this.reportParms = ({
      MealPlanId: this.MealPlanId,
      MealPlanDesc: this.MealPlanDesc,
      UserId: this.UserId,
      reportHeader: this.reportHeader
    }

    );
    this.openReport();
  }

  public openReport() {
    window.scrollTo(0, 0);
    this.openMealPlanMasterReport = true;
  }
  public closeMealPlanMasterReport() {
    this.openMealPlanMasterReport = false;
  }






}
