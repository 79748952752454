import { Component, OnInit,ViewChild } from '@angular/core';
import { LookUpDetails } from "../../Services/look-up-details";
import { ActivatedRoute, Router } from "@angular/router";
import { LookUpDetailsService } from "../../Services/look-up-details.service";
import { ShareDataService } from "../../Services/share-data.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from "@ngx-translate/core";
import { FileService } from "../../Services/file.service";
import { CommonserviceService } from "../../Services/commonservice.service";
import { Subscription } from "rxjs";
import { PublicLayoutComponent } from '../../public-layout/public-layout.component';
import { NgxSpinnerService } from "ngx-spinner";
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import * as XLSX from "xlsx";
import * as moment from 'moment';
import { FolderAddExpenseService } from '../../Services/folder-add-expense.service';
import { FolderAddExpense } from '../../Models/folder-add-expense.model';
import { OthersService } from '../../Services/others.service';
import { PageSizeItem } from '@progress/kendo-angular-grid';
OthersService
type AOA = any[][];
@Component({
  selector: 'app-folderadd-expense',
  templateUrl: './folderadd-expense.component.html',
  styleUrls: ['./folderadd-expense.component.scss']
})
export class FolderaddExpenseComponent implements OnInit {
  @ViewChild("autocompletecustomer")
  public autocompletecustomer: AutoCompleteComponent;
  reactiveForm: FormGroup;
  private subscription: Subscription = new Subscription();
  Componentname: string;
  isShowncase=false;
  isPost=true;
  chatIsToggled = true;
  showcaseid=true;
  showFilter = false;
  filedata: any;
  folderExpenseUploadData: AOA = [[1, 2], [3, 4]];
  folderAddExpenseModel: FolderAddExpense;
  public showMessage: any;
  templateIdList: Array<LookUpDetails["ResponseData"]> = [];
  public TemplateSelectedItem:any;
  public mappingList:any="";
  public FolderAddExpenceList:any=[];
  folderAddExpenceToRemove: any;
  folderAddExpenceRemoveIndex: any;
  public selectAll:any;
  public editedRowIndex:any;
  public editchkbox:any;
  public Filter:any;
  public pageSize:any;
  public sort:any;
  public gridHeight:any;
  public pageSizes: (PageSizeItem | number)[] = [20, 50, 100, {
    text: 'All',
    value: 'all'
  }];
  public isSubmited:boolean=false;
  constructor(
    private spinner: NgxSpinnerService,
    public common: CommonserviceService,
    private layout: PublicLayoutComponent,
    private lookupApi: LookUpDetailsService,
    private apiShared: ShareDataService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private translateapi: TranslateService,
    private emailapi: FileService,
    private FolderAddExpense : FolderAddExpenseService,
    private OthersService : OthersService,
  ) { }

  ngOnInit(): void {
    this.reactiveForm = this.formBuilder.group({
      fileUpload: [null, Validators.required]
     
    });

    this.Componentname="FolderaddExpense";
    sessionStorage.setItem('Componentname',this.Componentname);

    this.subscription.add(this.lookupApi.GetAllLookup('ADDEXPENSEPMTID').subscribe(
      (data) => {
        debugger;
        if (data != null && data != undefined) {
          this.templateIdList = data["ResponseData"];
        
          
        }
      }
    ));

  }
  public onFilterClick()//Caseid 13385
  {
    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }

  downloadMyFile(){
    debugger;
    var result = this.common.fileExists("assets//Excel_Download//FolderAddExpense.xlsx");
    if (result == true) {

      let link = document.createElement('a');
      link.setAttribute('type', 'hidden');
      link.href = 'assets//Excel_Download//FolderAddExpense.xlsx';

      document.body.appendChild(link);
      link.click();
      link.remove();

    }
    else {
      this.common.showWARNINGtoastrmsg(
        "Messages.File Not Found",
        2000,
        true,
        false
      );
    }

  }

  public getFileData(evt: any) //v1.0  case id : 13709 excel upload step1
  {
    debugger;
    this.filedata = evt;
  }

  public UploadExcelData() //v1.0 excel upload step2
  {
    debugger;
    if(this.TemplateSelectedItem != undefined){
      if(this.mappingList == ""){
      
      }else{
        this.UploadExcelDataToDataBase(this.filedata);
  
      }

    }else{
      this.FolderAddExpenceList=[];
    }
  
    
  }


  async UploadExcelDataToDataBase(filedata: any) {
    this.isSubmited =false;
    debugger;
    this.spinner.show();
    if (filedata !== undefined && filedata !== null) {

      const target: DataTransfer = <DataTransfer>(filedata.target);
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        debugger;
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
          type: 'binary', cellDates: true,
          cellNF: false,
          cellText: true
        });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        this.folderExpenseUploadData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, defval: "", raw: false, dateNF: "DD/MM/YYYY" }));
        //this.data.splice(0, 1); 
        /* save data */
        var firstHeader = this.folderExpenseUploadData[0];

          var offset = moment().utcOffset();

          const workbook: any = XLSX.read(bstr, {
            type: 'binary',
            cellDates: true,
            cellNF: false,
            cellText: true, dateNF: "dd/MMM/yyyy"
          });
          this.FolderAddExpense.List = [];
          debugger;
          this.folderExpenseUploadData.splice(0, 1);
          debugger;
        
          this.folderExpenseUploadData.forEach(obj => {
            debugger;

            this.folderAddExpenseModel = new FolderAddExpense;
            this.folderAddExpenseModel.OrderNo =obj[this.mappingList.FAW_OrderNo - 1];
            this.folderAddExpenseModel.INETREF =obj[ this.mappingList.FAW_Inetref - 1];
            this.folderAddExpenseModel.ProductCode =obj[ this.mappingList.FAW_ProductCode - 1];
            this.folderAddExpenseModel.CurrencyId = obj[ this.mappingList.FAW_Currency - 1];
            this.folderAddExpenseModel.ExchangeRate =obj[  this.mappingList.FAW_ExRate - 1];
            this.folderAddExpenseModel.TCAmount = obj[ this.mappingList.FAW_TCAmount - 1];
            this.folderAddExpenseModel.SupplierCode = obj[ this.mappingList.FAW_SupplierCode - 1];
            this.folderAddExpenseModel.BookingDate = obj[ this.mappingList.FAW_BookingDate - 1];
            this.folderAddExpenseModel.Service ="folderAddExpense";
            this.folderAddExpenseModel.Status =""
            if(
              (this.folderAddExpenseModel.OrderNo ==""||this.folderAddExpenseModel.OrderNo ==null)&&
              (this.folderAddExpenseModel.INETREF ==""||this.folderAddExpenseModel.INETREF ==null)&&
              (this.folderAddExpenseModel.ProductCode ==""||this.folderAddExpenseModel.ProductCode ==null)&&
              (this.folderAddExpenseModel.CurrencyId ==""||this.folderAddExpenseModel.CurrencyId ==null)&&
              (this.folderAddExpenseModel.ExchangeRate ==""||this.folderAddExpenseModel.ExchangeRate ==null)&&
              (this.folderAddExpenseModel.TCAmount ==""||this.folderAddExpenseModel.TCAmount ==null)&&
              (this.folderAddExpenseModel.SupplierCode ==""||this.folderAddExpenseModel.SupplierCode ==null)&&
              (this.folderAddExpenseModel.BookingDate ==""||this.folderAddExpenseModel.BookingDate ==null)

            ){

            }else{
              this.FolderAddExpense.List.push(this.folderAddExpenseModel);

            }
            

          });
          this.spinner.hide();;
          this.FolderAddExpenceList=this.FolderAddExpense.List;
          console.log("jhb==",this.FolderAddExpenceList);
 
      }
      reader.readAsBinaryString(target.files[0]);
    }
    else {
      this.spinner.hide();
      this.subscription.add(this.translateapi.get(['Messages.Selectfile']).subscribe((translations) => {
        this.showMessage = translations['Messages.Selectfile'];
        this.toastr.error(this.showMessage, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        });
      }));
      return;
    }
    
  }


  UploadFolderAddExpenseExcelFileDetails() {
    debugger;
    this.spinner.show();
    //if (this.FolderAddExpense.List.length > 0) {
      this.FolderAddExpense.UploadFolderAddExpenseFileDetails(this.TemplateSelectedItem).subscribe(res => {
        this.spinner.hide();
        if (res != null && res != undefined) {
          if (res["ResponseData"].Status == "Success") {
            debugger;
            this.showMessage = res["ResponseData"].StatusDetails;
            this.toastr.success(this.showMessage, "",
              {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              })
          }
          else {
            this.showMessage = res["ResponseData"].StatusDetails;
            this.toastr.warning(this.showMessage, "",
              {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              })
          }
        }
      });
    //}
  }

  public TemplateSelectionChange(event:any){
    debugger
    this.spinner.show();
    if(event != undefined){
      this.FolderAddExpense.GetFolderAddExpenseMappingDetails(event.UsrCode).subscribe(res => {
        debugger;
        if(res.ResponseData.length > 0){
          this.mappingList =res.ResponseData[0];
        }else{
          this.mappingList ="";
        }
        this.spinner.hide();
      });

    }else{
      this.spinner.hide();

    }
    
  }

  public RemoveHandler(event:any){
    this.folderAddExpenceRemoveIndex=event.rowIndex
    this.folderAddExpenceToRemove = event.dataItem;
  }


  public cellClickHandler(e:any){
    if(e.columnIndex != 10){
      // if (e.dataItem.AutoNo != null) {
      //   if (e.dataItem.TEMPLATEID !== null) {
          
      //      this.router.navigate(['/PenAir/Commonsetting/TemplateConditionsView/',e.dataItem.AutoNo]);
      //   }
      // }
  
    }
    
  }

  public confirmFolderAddExpenceRemove(shouldRemove: boolean): void
  {
    debugger;
   this.FolderAddExpenceList.splice(this.folderAddExpenceRemoveIndex,1);
    this.folderAddExpenceToRemove="";
    console.log("Folder List",this.FolderAddExpenceList);
  }

  public AddExpenseClick(){
    debugger;
    if(this.FolderAddExpenceList.length != 0){
      this.spinner.show();
      this.OthersService.addExpense(this.FolderAddExpenceList).subscribe(ResponseData => {
        let i=0;
        ResponseData.ResponseData.forEach(element => {
          this.FolderAddExpenceList[i].Status=element.Status;
          i=i+1;
        });
        this.isSubmited=true;
        this.spinner.hide();
      });

    }
  }
  ConfirmChange(Select:any,dataItem:any){

  }
  cancel(){
    this.router.navigate(["PenAir/Home"]);

  }
  ClearAll(){
    this.TemplateSelectedItem="";
    this.FolderAddExpenceList="";
   

  }
  SelectAll(selectall:any){

  }

  


  
}
