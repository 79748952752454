import { Component, OnInit,Input, Output, EventEmitter, HostListener } from '@angular/core';
import { FolderCommCalculation, FolderSummary } from '../../Models/folder-summary';
import { ActivatedRoute, Router } from '@angular/router';
import { FolderSummaryService } from '../../Services/folder-summary.service';
import { ShareDataService } from '../../Services/share-data.service';
import { CustomerSpecific } from '../../Models/customer-specific';
import { CustomerspecificfieldService } from '../../Services/customerspecificfield.service';
import { PendoclockService } from '../../Services/pendoclock.service';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { CommonserviceService } from '../../Services/commonservice.service';
import { EnvService } from 'src/env.service';
import { FileService } from '../../Services/file.service';
import { Subscription, Observable } from 'rxjs';
import { PublicLayoutComponent } from '../../public-layout/public-layout.component';
import { FolderpriceService } from '../../Services/folderprice.service';
import { ProductList } from '../../Models/product-list';
import { RailsegService } from '../../Services/railseg.service';
import { AirsegService } from '../../Services/airseg.service';
import { RailsegDetail } from '../../Models/railseg-detail';
import { AirsegDetail } from '../../Models/airseg-detail';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { PassengerService } from '../../Services/passenger.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { CrystalReportService } from '../../Services/crystal-report.service';
import { AtolReportDTO } from '../../Models/crystal-report.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EmailService } from '../../Services/email.service';
import { ToastrService } from 'ngx-toastr';

import { PaymentgatewayListDetailsService } from '../../Services/paymentgateway-list-details.service';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { ReportParameterDTO } from '../../Models/common-class.model';
import { CommonVariencereportService } from '../../Services/common-variencereport.service';
import { DocumentEmailDTO } from '../../Models/document-email-dto';
import { WindowState } from '@progress/kendo-angular-dialog';
import { CRMSettingsServiceService } from '../../Services/crm-settings-service.service';
import { HttpClient } from '@angular/common/http';
import { WeTuTransferService } from '../../we-tu-transfer.service';
import { WeTuTransferDTO } from '../../we-tu-transfer-dto.model';
import { ProductsComponent } from 'src/app/product/products/products.component';

@Component({
  selector: 'app-folder-summary',
  templateUrl: './folder-summary.component.html',
  styleUrls: ['./folder-summary.component.scss']
})
export class FolderSummaryComponent implements OnInit {
  Atolcertificate: any = "Atol Certificate";
  public reportViewerHeader = "";
  public reportViewertemp = "";
  private subscription: Subscription = new Subscription();
  Ptype = "folder";
  fromId: string;
  packagefolderno: number = 0;
  bccId: string;
  userID: number;
  langSelected: any;
  atolattachement: any = false;
  public offset = 50;
  public windowLeft = this.offset;
  public windowTop = this.offset;
  public widthCr = window.innerWidth - 40;

  public left = -220;
  public top = -100;
  public heightCr = 950;
  public width = 600;
  public height = 190;
  isShown = false;
  showOrder = false;
  showPass = false;
  showProduct = false;
  showCS = false;
  showOther = false;
  showStatus = false;
  openEpayMail = false;
  public PassengerName: Observable<string>;
  showTravel = false;
  labelchange = false;
  Products = '';
  enablepayment: any;
  public rptwindowTop: number = -100;
  public rptwindowLeft: number = -225;;
  hcCurrency: any;
  public rptwindowWidth = window.innerWidth;
  public rptwindowHeight = window.innerHeight;
  public selectedReport: string = "";
  public defaultReportSelected: string = "";
  public productGridData: Array<ProductList["ResponseData"]> = [];
  public airSegGridData: Array<AirsegDetail["ResponseData"]> = [];
  public railSegGridData: Array<RailsegDetail["ResponseData"]> = [];
  public PassengerList: any;
  public pageSize = 10;
  public skip = 0;
  public ProductList: any;
  public buy: number = 0.00;
  public comm: number = 0.00;
  public manualCalculation = false;
  package:boolean = false;
  folderexgrate: any = 0.00;
  public reportSubject: string = "";
  public Componentname: string;
  // Sheffy for case id 14121-
  public CreditNoteAll: number = 0;
  // by chithra for case id:13786 -Transfer Voucher
  // by chithra for case id:-Hotel Voucher
  //by chithra for case id:13891-Invoiceonly

  // public invoiceFormatItems: Array<string> = ["PackageItinerary8", "PackageItinerary7", "PackageItinerary5", "InvoicewithItinerary 02", "InvoicewithItinerary 02_Signature", "CorporateInvoice02", "Car Voucher", "Accommodation Voucher", "E-Ticket", "Booking Confirmation", "Tour Voucher", "Transfer Voucher", "Hotel Voucher", "Insurance Reclaim Document", "Customer Refund Request", "InvoicewithItinerary1", "InvoicewithItinerary 02 Canada", "Cancellation Document", "Service Invoice"];
  public invoiceFormatItems: Array<string> = ["Accommodation Voucher", "Booking Confirmation", "Car Voucher",
    "Cancellation Document", "CorporateInvoice02", "Customer Refund Request", "E-Ticket", "Insurance Reclaim Document", "Hotel Voucher",
    "InvoicewithItinerary1", "InvoicewithItinerary 02", "InvoicewithItinerary 02 Canada", "InvoicewithItinerary 02_Signature", "Itineraryonly",
    "PackageItinerary5", "PackageItinerary7", "PackageItinerary8", "Service Invoice", "Tour Voucher", "Transfer Voucher"];


  public EditValue: any;
  invoiceno: any;//12728
  barclay = false;
  relaex = false;
  worldpay = false;
  sagePay = false;
  Netbanx = false;
  FirstData = false
  Stripe = false;
  Ingenico = false;
  GTPay = false;
  TrustPay = false;
  BarclaycardFuse = false;
  Eway = false;
  MPesa = false;
  swedpay = false;
  WeTu=false;
  PaymentUrl: any;
  @Output() menuIndex: EventEmitter<number> = new EventEmitter();
  loaded = false;
  lockStatus: any;
  prtycode: any;
  foldercurrency: any;
  folderexrate: any;
  folderbrid: any;
  projno: any;
  ppid: any;
  public formeditable = true;
  folderEditMode: any;
  public gridData: [];
  sell: number;
  TotCOMM: number = 0.00;
  TotBUY: number = 0.00;
  HcTotCOMM: number = 0.00;
  HcTotBUY: number = 0.00;
  HcSell: number = 0.00;
  fcTotVat: number = 0.00;
  totalReceivable: number;
  HCtotalReceivable: number = 0.00;
  balance: number;
  HCbalance: number = 0.00;
  folderStatus: any;
  BrName: any;
  homeCurrency: any;
  reportHeader: any;
  AgntTotCOMM: number = 0.00;
  HCAgntTotCOMM: number = 0.00;
  ProviderList: any;

  PassSurname: any;
  AGTCOM: number;
  ordertype: any;
  folderSummary: FolderSummary = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {
      FOLDERNO: "",
      PROJNO: "",
      FCTotalBUY: 0,
      FCTotalCOMM: 0,
      TotVAT: 0,
      FCCREDITAMT: 0,
      AGTCOMSUM: 0,
      AGTCOMVAT: 0,
      FCAGTCOMSUM: 0,
      FCAGTCOMVAT: 0,
      AGTCOMVATNO: 0,
      TotRECEIVABLE: 0,
      FCALREADYPAID: 0,
      FCOtherCredits: 0,
      PRTYCODE: "",
      BRID: "",
      BAID: "",
      OrderType: "",
      FC_CID: "",
      ARRIVALPT: "",
      DESTINAION: "",
      TotalBUY: 0,
      TotalCOMM: 0,
      FOLDERDATE: null,
      BOOKEDBY: "",
      NOOFPASS: 0,
      INETREF: "",
      PONO: "",
      STATUS: "",
      DEPDUEDATE: null,
      DEPAMT: 0,
      BALDUEDATE: null,
      BALAMT: 0,
      QCONDATE: new Date("01-Jan-1900"),
      FC_CIDExRate: 0,
      FCTotRECEIVABLE: 0,
      Receipt: '',
      CRDATE: new Date('01/Jan/1900'),
      PPID: 0,
      StatusName: '',
      CUSTOMERNAME: '',
      FM_LID: '',
      BAName: '',
      BrName: '',
      PassengerName: '',
      Pass: '',
      CustomerSpecific:
      {
        LabelName: '',
        labelData: '',
        CustCo: ''
      },
      ErrorMessage: '',
      CREDITAMT: 0,
      PDQCHARGE: 0,
      FM_MnlPkgCalc: 0,
      PACKAGED: 0,
      ALREADYPAID: 0,
      OtherCredits: 0,
      MCID: '',
      INVOICENO: 0,
      MarkupPer: 0,
      Defaultinvoiceformat: '',
      CustCountry: ''

    }
  };
  rptType: any = "Booking Confirmation"
  crRptName: any = "";
  public AtolEnabled: any = false;
  public AtolType: any = ""
  public showPrint: any = false;
  public pdfFilePath = '';
  public crpdfFilePath = '';
  public crPdfFilePath = '';
  public ViewTripurl: any;
  urlSafe: SafeResourceUrl;
  crUrlSafe: SafeResourceUrl;
  pdfurl: string;
  TYPEOFINV: any = 0;
  Projno: any = 0;
  emailopened: any = false;
  rptfilename: any = '';
  crFileName: any = '';
  filepathatol: any = '';
  crFilePath: any = '';
  attolreportdeletepath: any = '';
  emailatolpath: any = '';
  crEmailPath: any = '';
  emailsubject: any = '';
  emailbody: any = '';
  reportpathname: any = '';
  activeGDS: any;
  PNR: '';
  invalidPNR = false;
  objPNRRetrieval: any = {
    url: '',
    queryParams: ''
  }
  openGDSTray = false;
  objCommCalculation: FolderCommCalculation = {
    FCTotalBUY: 0,
    PDQCHARGE: 0,
    FC_CIDExRate: 0,
    FCTotalCOMM: 0,
    FCCREDITAMT: 0,
    FCAGTCOMSUM: 0,
    FCALREADYPAID: 0,
    FCOtherCredits: 0,
    TotalBUY: 0,
    TotalCOMM: 0,
    CREDITAMT: 0,
    AGTCOMSUM: 0,
    ALREADYPAID: 0,
    OtherCredits: 0,
  }
  commType = 'both';
  openRemider = false;
  crOpened = false;
  crDeletePath: any;
  public windowState: WindowState = "maximized";
  
  
  public WetuCRMApiKey:any;
  WeTuUrl:string;
  
  constructor(private toastr: ToastrService, private apipaymentgateway: PaymentgatewayListDetailsService, public email: EmailService, public sanitizer: DomSanitizer, private reportser: CrystalReportService,
    public lookupApi: LookUpDetailsService, public intl: IntlService, private emailapi: FileService, private Productapi: FolderpriceService, private layout: PublicLayoutComponent, private env: EnvService,
    public common: CommonserviceService, private route: ActivatedRoute, private router: Router, private api: FolderSummaryService, private apiShared: ShareDataService, private apiCS: CustomerspecificfieldService,
    private apipenLock: PendoclockService, private spinner: NgxSpinnerService, private translateapi: TranslateService, private rptAPI: CommonVariencereportService
    , private flashMessage: FlashMessagesService, private apiFile: FileService, private airSegAPI: AirsegService, private PassengerService: PassengerService, private railSegAPI: RailsegService,
    private CRMservice: CRMSettingsServiceService, public WeTuService:WeTuTransferService) {
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    this.pdfurl = env.baseUrl
    
  }
  public orderNo = this.route.snapshot.params['orderNo']
  ngOnInit() {   
debugger;
    
    this.subscription.add(
      this.CRMservice.getCRMDetails().subscribe((data) => {
        debugger;
        this.WetuCRMApiKey = data['ResponseData'].objWETUDTO.WETU_ApiKey; 
        if(this.WetuCRMApiKey!="" && this.WetuCRMApiKey!=null){
          this.WeTu=true;
        }      
      })
    );

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.loadfiledetails();

    sessionStorage.removeItem('folderQuotes');
    var a = this.common.DecimalCount;
    this.subscription.add(
      this.emailapi
        .getFolderFromEmail(this.orderNo, +this.apiShared.uid)
        .subscribe(data => {
          if (data != null && data != undefined) {
            this.fromId = data["ResponseData"];
          }
        })
    );
    this.subscription.add(this.emailapi.GetReportEmail(+this.apiShared.uid).subscribe(data => {
      if (data != null && data != undefined) {
        //this.fromId = data.ResponseData.FROMEMAIL,
        this.bccId = data.ResponseData.DEFAULTBCCMAIL

      }
    }));
    this.subscription.add(this.api.GetActiveGDS()
      .subscribe(data => {
        debugger;
        this.activeGDS = data.ResponseData;
      }));
    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderNo !== orderno) {
        return this.router.navigate(['PenAir/Home'], { skipLocationChange: true });
      }
    window.scrollTo(0, 0);
    this.spinner.show();
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    this.layout.folderstatus = this.folderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;
    }
    else {

      this.formeditable = false;
    }

    // if (this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund")//By Caseid-12780
    // {
    //   this.formeditable = false;
    // }
    if (this.lockStatus == "0" &&(this.folderStatus == "Invoiced" ||this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund"))//By Caseid-12780
    {
      this.formeditable = false;
    }
    if (this.lockStatus == "0" &&(this.folderStatus != "Invoiced" && this.folderStatus != "Cancelled" && this.folderStatus != "Refund" &&  this.folderStatus != "Partial Refund"))
    {
      this.formeditable = true;
    }
    else if (this.lockStatus != "0" &&(this.folderStatus == "Invoiced" ||this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund"))//By Caseid-12780
    {
      this.formeditable = false;
    }
    this.folderEditMode = "false";
    this.common.folderEditMode = 'false'
    this.subscription.add(this.apiFile.getFileDetails()
      .subscribe(data => {

        this.hcCurrency = data.ResponseData.COMCID;
        this.apiShared.Setapishareddata(this.hcCurrency, 'homeCurrency');
      }));
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.GetFolderDetailsById(this.orderNo);
    this.loaded = true;
    this.menuIndex.emit(0);


    this.subscription.add(this.lookupApi.GetAllLookup('Atol').subscribe(
      (data) => {

        if (data["ResponseData"] != null) {
          if (data["ResponseData"][0].Code == 1) {
            this.email.Atolre = true;
            this.AtolEnabled = true;
            this.getAtolType();
          } else {
            this.AtolEnabled = false;
          }

        }
      }));

    this.subscription.add(this.lookupApi.GetLookupById('PaymentGatewayPassenger', "''", this.orderNo).subscribe(
      (data) => {
        if (data != null && data != undefined && data["ResponseData"] != null) {
          if (data["ResponseData"].length > 0)
            this.PassSurname = data["ResponseData"][0].UsrCode.replace(" ", "%20")

        }
      }
    ));
    this.subscription.add(this.apipaymentgateway.GetActiveProviderList().subscribe(
      (data) => {

        this.ProviderList = ((data["ResponseData"]))
        console.log(this.ProviderList)

        for (let data of this.ProviderList) {
          if (data.VTPM_PaymentMethodID.toLowerCase() == "SagePay".toLowerCase()
          ) {
            this.sagePay = true;
          }
          if (data.VTPM_PaymentMethodID.toLowerCase() == "barclaycard".toLowerCase()) {
            this.barclay = true;
          }
          if (data.VTPM_PaymentMethodID.toLowerCase() == "realexpayments".toLowerCase()) {
            this.relaex = true;
          }
          if (data.VTPM_PaymentMethodID.toLowerCase() == "FirstData".toLowerCase()) {
            this.FirstData = true;
          }
          if (data.VTPM_PaymentMethodID.toLowerCase() == "NETBANX".toLowerCase()) {
            this.Netbanx = true;
          }
          if (data.VTPM_PaymentMethodID.toLowerCase() == "Stripe".toLowerCase()) {
            this.Stripe = true;
          }

          if (data.VTPM_PaymentMethodID.toLowerCase() == "Ingenico".toLowerCase()) {
            this.Ingenico = true;
          }
          if (data.VTPM_PaymentMethodID.toLowerCase() == "GTPay".toLowerCase()) {
            this.GTPay = true;
          }
          if (data.VTPM_PaymentMethodID.toLowerCase() == "TrustPay".toLowerCase()) {
            this.TrustPay = true;
          }
          if (data.VTPM_PaymentMethodID.toLowerCase() == "barclaycardSmartPayFuse".toLowerCase()) {
            this.BarclaycardFuse = true;
          }
          if (data.VTPM_PaymentMethodID.toLowerCase() == "Eway".toLowerCase()) {
            this.Eway = true;
          }
          if (data.VTPM_PaymentMethodID.toLowerCase() == "MPesa".toLowerCase()) {
            this.MPesa = true;
          }

          if (data.VTPM_PaymentMethodID.toLowerCase() == "swedpay".toLowerCase()) {
            this.swedpay = true;
          }         

        }

      }
    ));
   
    this.openEpay = true;
    this.Componentname = "FolderSummary";
    sessionStorage.setItem("Componentname", this.Componentname);
    this.subscription.add(
      this.apiFile.getUrlSettings("ViewTrip").subscribe(data => {
        debugger
        if (data.ResponseData != null && data.ResponseData != "" && data.ResponseData != undefined) {
          this.ViewTripurl = data.ResponseData
        }
      })
    );

    
  }

  public emailclose(status) {
    this.emailopened = false;
    this.email.Atolre = false;
    this.showPrint = false;
    // this.email.filename = '';
    //  this.email.atolfilepath = '';
    // this.email.emailsubject = '';
    // this.email.emailbody = '';
    // this.email.filename = '';
    this.atolopened = false;

  }
  public EmailFormClosingatol(status: any)//Email status change by case id-12434
  {
    this.email.emailbody = '';
    if (status == "EmailSendSuccesfully" || status == 'Closed')//Email status change by case id-12434
    {
      this.emailclose(false);
    }
  }
  public async SendTelerikMail() {
    this.spinner.show();
    await this.email.updateReportParameterDTO(this.selectedReport);
    await this.GetEmailBofyByFolderno();
    this.email.Atolre = true;
  }
  public GetEmailBofyByFolderno() {
    debugger;
    this.spinner.show();
    if (this.common.formData == undefined)
      this.common.formData = new ReportParameterDTO();
    if (this.selectedReport == "ReceiptPrinting") {
      this.common.formData.p_emailtype = "Receipt";
    }
    else {
      this.common.formData.p_emailtype = "Receipt";
    }
    this.common.formData.attachmentindex = 0;
    this.common.formData.Branch = String(this.apiShared.BRID);
    this.common.formData.RtftoHtmlUrl = this.env.RtftoHtmlUrl;

    var ordernos = this.apiShared.orderNo;
    this.common.formData.trdpfilename = this.selectedReport;
    this.common.formData.FolderNo = String(ordernos);
    this.common.formData.Userid = +this.apiShared.uid;

    if (this.rptAPI.webLink != '') {
      this.common.formData.isWebLink = true;
    }
    this.subscription.add(
      this.common
        .GetReportBodyOrSubject()
        .subscribe((res) => {
          debugger;
          this.spinner.hide();
          this.email.Fromlist = res["ResponseData"]["Fromdata"]
          this.email.CCEmail = res["ResponseData"]["CCEmail"];
          this.emailopened = true;
          // this.atolopened = false;
          this.common.formData.FolderNo = this.orderNo;
          this.common.formData.projno = this.projno;
          this.common.formData.BCCEmail = res["ResponseData"]["BCCEmail"];
          this.common.formData.BCCEnable = res["ResponseData"]["BCCEnable"];
          this.common.formData.ToEmail = res["ResponseData"]["ToEmail"];
          this.common.formData.EmailBody = res["ResponseData"]["EmailBody"].replace("<<WEBLINK>>", this.rptAPI.webLink);

          if (this.rptAPI.webLink == '') {
            this.common.formData.RecieptEmailBody = res["ResponseData"]["RecieptEmailBody"];
            if (this.atolattachement == false)
              //this.email.atolfilepath = res["ResponseData"]["Attachementpath"];
              this.email.folderreportfilepath = res["ResponseData"]["Attachementpath"];
          }
        }))

  }
  public SendMail() {
    this.atolattachement = true;
    this.email.filename = '';
    this.email.atolfilepath = '';
    this.email.emailsubject = '';
    this.email.emailbody = '';
    this.email.filename = '';
    this.atolopened = true;
    this.email.Atolre = true
    // this.emailopened = true;
    this.email.filename = this.emailsubject;
    this.email.atolfilepath = this.filepathatol;
    this.email.emailatolpath = this.emailatolpath;
    this.email.emailsubject = this.emailsubject;
    this.email.emailbody = this.emailbody;
    this.SendTelerikMail();
  }
  sendFoldersummarytrdpReport() {
    this.atolattachement = false;
    this.SendTelerikMail();
  }
  loadfiledetails() {
    this.subscription.add(
      this.apiFile.GetCompanySettings("Ticket").subscribe(data => {
        if (data != null && data != undefined) {

          debugger;
          this.common.BSPTAXEnable = data.ResponseData.BSPTAXEnable;
          this.api.defaultrpt = data.ResponseData.Defaultinvoiceformat;
        }
      }))
  }
  GetFolderDetailsById(id) {

    //this.hcCurrency=this.apiShared.homeCurrency;
    this.subscription.add(this.api.GetFolderDetailsById(id, 1)
      .subscribe(data => {
        debugger;
        this.spinner.hide();
        this.common.PrtyCodeFolder = data.ResponseData.PRTYCODE;
        this.CreditNoteAll = data['ResponseData']['CreditAlloc'];
        if (data['ResponseData']['STATUS'] == '3' || data['ResponseData']['STATUS'] == '4' || data['ResponseData']['STATUS'] == '5' || data['ResponseData']['STATUS'] == '6')
          this.apiShared.ReceiptPayment = this.CreditNoteAll == 1 ? 0 : 1;
        else
          this.apiShared.ReceiptPayment = 1;
        debugger;
        this.apiShared.Setapishareddata(this.apiShared.ReceiptPayment, 'ReceiptPayment');
        //  this.CredinotAll
        this.folderSummary = data;
        this.objCommCalculation = Object.assign({}, this.folderSummary.ResponseData);
        if (this.folderSummary != null && this.folderSummary != undefined) {
          if (this.folderSummary.ResponseData.Defaultinvoiceformat != null && this.folderSummary.ResponseData.Defaultinvoiceformat != '') {
            this.invoiceFormatItems.forEach(element => {
              if (element == this.folderSummary.ResponseData.Defaultinvoiceformat) {
                this.defaultReportSelected = this.folderSummary.ResponseData.Defaultinvoiceformat;
              }
            });

          }
          if (this.defaultReportSelected == null || this.defaultReportSelected == '') {
            this.invoiceFormatItems.forEach(element => {
              if (element == this.api.defaultrpt) {
                this.defaultReportSelected = this.api.defaultrpt;
              }
            });

          }
          if (this.defaultReportSelected == null || this.defaultReportSelected == '') {
            this.defaultReportSelected = "InvoicewithItinerary 02";

          }
          debugger;
          this.ordertype = this.folderSummary.ResponseData.OrderType;
          this.packagefolderno = +this.folderSummary.ResponseData.FOLDERNO;
          this.prtycode = this.folderSummary.ResponseData.PRTYCODE;
          this.ppid = this.folderSummary.ResponseData.PPID == null || undefined ? 0 : this.folderSummary.ResponseData.PPID;
          localStorage.setItem('OrderType', this.folderSummary.ResponseData.OrderType);
          if (this.folderSummary.ResponseData.OrderType === 'Package' && this.folderSummary.ResponseData.PACKAGED == 1) {


            this.package = true;
            localStorage.setItem('package', 'true');
            this.Productapi.package = true;
            this.common.package = true;
            if (this.folderSummary.ResponseData.FM_MnlPkgCalc === 1) {
              this.manualCalculation = true;
              localStorage.setItem('ManualCalc', '1');
            }
            else {
              this.manualCalculation = false;
              localStorage.setItem('ManualCalc', '0');
            }
          }
          else {
            this.common.package = false;
            this.Productapi.package = false;
            localStorage.setItem('package', 'false');
          }

          this.apiShared.Setapishareddata(this.prtycode, 'CustomerCode');
          this.foldercurrency = this.folderSummary.ResponseData.FC_CID;
          this.apiShared.Setapishareddata(this.foldercurrency, 'folderCurrency');
          this.folderbrid = this.folderSummary.ResponseData.BRID;
          this.apiShared.Setapishareddata(this.folderbrid, 'BRID');
          this.apiShared.Setapishareddata(this.folderSummary.ResponseData.BAID, 'BAID');
          this.apiShared.FolderDate = this.folderSummary.ResponseData.FOLDERDATE != null ? (this.folderSummary.ResponseData.FOLDERDATE.toString() == "1900-01-01T00:00:00" ? null : new Date(this.folderSummary.ResponseData.FOLDERDATE)) : null;

          this.folderexrate = this.folderSummary.ResponseData.FC_CIDExRate;
          this.apiShared.Setapishareddata(this.folderexrate, 'folderExchangeRate');
          this.projno = this.folderSummary.ResponseData.PROJNO;
          this.apiShared.Setapishareddata(this.projno, 'projNo');
          // this.layout.headerPassenger = this.PassSurname +'- Folder No:'  + this.apiShared.projNo+ " Order No:" + this.apiShared.orderNo;
          this.sell = this.folderSummary.ResponseData.FCTotalBUY + this.folderSummary.ResponseData.FCTotalCOMM;
          this.fcTotVat = this.folderSummary.ResponseData.TotVAT / this.folderexrate;
          this.totalReceivable = (this.folderSummary.ResponseData.FCTotRECEIVABLE + this.fcTotVat + this.folderSummary.ResponseData.FCCREDITAMT) - (this.folderSummary.ResponseData.AGTCOMSUM + this.folderSummary.ResponseData.AGTCOMVAT);
          this.HCtotalReceivable = this.totalReceivable * this.folderexrate;
          this.balance = this.totalReceivable - this.folderSummary.ResponseData.FCALREADYPAID - this.folderSummary.ResponseData.FCOtherCredits;
          this.HCbalance = this.balance * this.folderexrate;
          this.BrName = this.folderSummary.ResponseData.BrName;
          this.TotBUY = this.folderSummary.ResponseData.FCTotalBUY;
          this.TotCOMM = this.folderSummary.ResponseData.FCTotalCOMM;
          this.invoiceno = this.folderSummary.ResponseData.INVOICENO;//12728
          if (this.folderSummary.ResponseData.CREDITAMT === 0) {
            this.HcTotBUY = this.folderSummary.ResponseData.TotalBUY + this.folderSummary.ResponseData.PDQCHARGE;
            this.HcTotCOMM = this.folderSummary.ResponseData.TotalCOMM - this.folderSummary.ResponseData.PDQCHARGE;
          } else {
            this.HcTotBUY = this.folderSummary.ResponseData.TotalBUY;
            this.HcTotCOMM = this.folderSummary.ResponseData.TotalCOMM;
          }

          this.AgntTotCOMM = this.TotCOMM + this.folderSummary.ResponseData.AGTCOMVAT - this.folderSummary.ResponseData.AGTCOMSUM;
          this.HCAgntTotCOMM = this.AgntTotCOMM * this.folderexrate;
          this.HcSell = this.folderSummary.ResponseData.TotalBUY + this.folderSummary.ResponseData.TotalCOMM;
          this.AGTCOM = this.folderSummary.ResponseData.AGTCOMSUM + this.folderSummary.ResponseData.AGTCOMVAT;
          this.balance = (this.folderSummary.ResponseData.FCTotRECEIVABLE + this.folderSummary.ResponseData.FCCREDITAMT
            + this.folderSummary.ResponseData.TotVAT) - (this.folderSummary.ResponseData.FCOtherCredits +
              this.folderSummary.ResponseData.FCAGTCOMSUM + this.folderSummary.ResponseData.FCAGTCOMVAT + this.folderSummary.ResponseData.FCALREADYPAID)
          this.folderSummary.ResponseData.BALAMT = this.balance;


          // this.apiShared.changeData((this.folderSummary.ResponseData.FC_CIDExRate).toString(), (this.folderSummary.ResponseData.PROJNO).toString(),
          // (this.folderSummary.ResponseData.BRID).toString())
          //this.isLoadingResults = false;
          this.spinner.hide();
          this.apiShared.Setapishareddata(this.invoiceno, 'invoiceno');
          localStorage.setItem('CustomerCountry', this.folderSummary.ResponseData.CustCountry);

        } else {
          this.spinner.hide();
        }
        this.loaded = true;
      }));
    this.loaded = true;


  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
  }

  async onEditButtonClick() {
    this.EditValue = false;
    if (Boolean(await this.common.checkPermission1("01.12.01.11")) == true) {
      if (this.common.package)
        this.router.navigate(['/PenAir/Folder/PackageSummaryEdit', this.orderNo], { skipLocationChange: true });
      else
        this.router.navigate(['PenAir/Folder/FolderSummaryEdit', this.orderNo], { skipLocationChange: true });

    }
    else {
      this.showmsg('01.12.01.11');
    }
  }

  // async onSagePayment() {
  //   if (Boolean(await this.common.checkPermission1('01.12.01.23')) == true) {
  //     this.PassengerName = this.PassSurname;
  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }
  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=SagePay&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {

  //     this.showmsg('01.12.01.23');

  //   }
  // }

  // async onFirstDataPayment() {
  //   if (Boolean(await this.common.checkPermission1('01.12.01.36')) == true) {
  //     this.PassengerName = this.PassSurname;

  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }
  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=FirstData&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {

  //     this.showmsg('01.12.01.36');

  //   }

  // }
  // async onIngenicoPayment() {
  //   if (Boolean(await this.common.checkPermission1('01.12.01.31')) == true) {
  //     this.PassengerName = this.PassSurname;
  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }

  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=Ingenico&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {

  //     this.showmsg('01.12.01.31');

  //   }

  // }

  // async onTrustPayment() {
  //   if (Boolean(await this.common.checkPermission1('01.12.01.38')) == true) {
  //     this.PassengerName = this.PassSurname;
  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }

  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=trustpay&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {

  //     this.showmsg('01.12.01.38');

  //   }

  // }

  // async barclaycardSmartPayFuse() {
  //   if (Boolean(await this.common.checkPermission1('01.12.01.44')) == true) {
  //     this.PassengerName = this.PassSurname;
  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }

  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=barclaycardSmartPayFuse&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {

  //     this.showmsg('01.12.01.44');

  //   }
  // }

  // async onGTPayPayment() {
  //   if (Boolean(await this.common.checkPermission1('01.12.01.39')) == true) {
  //     this.PassengerName = this.PassSurname;
  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }

  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=GTPay&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {

  //     this.showmsg('01.12.01.39');

  //   }

  // }
  // async onStripePayment() {
  //   if (Boolean(await this.common.checkPermission1('01.12.01.37')) == true) {
  //     this.PassengerName = this.PassSurname;
  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }

  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=Stripe&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {
  //     this.showmsg('01.12.01.37');
  //   }



  // }
  // async onNetBanksPayment() {
  //   if (Boolean(await this.common.checkPermission1('01.12.01.21')) == true) {
  //     this.PassengerName = this.PassSurname;
  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }

  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=NETBANX&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {

  //     this.showmsg('01.12.01.21');
  //   }



  // }
  // async onWorldPay() {
  //   if (Boolean(await this.common.checkPermission1('01.12.01.24')) == true) {
  //     this.PassengerName = this.PassSurname;
  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }

  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=worldpay&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {

  //     this.showmsg('01.12.01.24');
  //     //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.23');
  //   }



  // }
  // async onRealexPayment() {
  //   if (Boolean(await this.common.checkPermission1('01.12.01.28')) == true) {
  //     this.PassengerName = this.PassSurname;
  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }

  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=realexpayments&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {

  //     this.showmsg('01.12.01.28');
  //     //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.23');
  //   }



  // }

  // async onBarclayPayment() {
  //   if (Boolean(await this.common.checkPermission1('01.12.01.25')) == true) {
  //     this.PassengerName = this.PassSurname;
  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }

  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=barclaycardSmartPay&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {
  //     // this.ShowPermissionMessageForm('01.12.01.23');
  //     this.showmsg('01.12.01.25');
  //     //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.23');
  //   }



  // }
  // async onEwayPayment() {
  //   if (Boolean(await this.common.checkPermission1('Eway')) == true) {
  //     this.PassengerName = this.PassSurname;
  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }
  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=Eway&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {

  //     this.showmsg('Eway');

  //   }




  // }
  // async onMPesaPayment() {
  //   if (Boolean(await this.common.checkPermission1('MPesa')) == true) {
  //     this.PassengerName = this.PassSurname;
  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }
  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=MPesa&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {

  //     this.showmsg('MPesa');

  //   }
  // }

  // async onSwedPayPayment() {
  //   if (Boolean(await this.common.checkPermission1('swedpay')) == true) {
  //     this.PassengerName = this.PassSurname;
  //     if (this.PassSurname == null || this.PassSurname == undefined) {
  //       this.PassSurname = "";
  //     }
  //     const url = this.env.ViewTripURL + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=swedpay&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

  //     window.open(url, '_blank');
  //   }
  //   else {

  //     this.showmsg('swedpay');

  //   }

  // }
  onMotoButtonClick(value) {
    debugger;
    let permission = "";
    switch (value) {
      case 'Realex':
        permission = "01.12.01.28";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      case 'barclaycardSmartPay':
        permission = "01.12.01.25";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      case 'SagePay':
        permission = "01.12.01.23";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      case 'worldpay':
        permission = "01.12.01.24";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      case 'FirstData':
        permission = "01.12.01.36";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      case 'Ingenico':
        permission = "01.12.01.31";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      case 'NETBANX':
        permission = "01.12.01.21";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      case 'Stripe':
        permission = "01.12.01.37";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      case 'GTPay':
        permission = "01.12.01.39";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      case 'trustpay':
        permission = "01.12.01.38";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      case 'barclaycardSmartPayFuse':
        permission = "01.12.01.44";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      case 'Eway':
        permission = "Eway";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      case 'MPesa':
        permission = "MPesa";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      case 'swedpay':
        permission = "swedpay";
        this.MotoButtonPermissionChecking(permission, value);
        break;
      
    }

  }

  async MotoButtonPermissionChecking(permission, Provider) {
    debugger;
    if (Boolean(await this.common.checkPermission1(permission)) == true) {
      this.PassengerName = this.PassSurname;
      if (this.PassSurname == null || this.PassSurname == undefined) {
        this.PassSurname = "";
      }
      const url = this.ViewTripurl + "?Surname=" + this.PassSurname + '&Bookingref=' + this.apiShared.orderNo + '&Module=PenAIR&PM=' + Provider + '&UserId=' + this.apiShared.uid + '&BRID=' + this.folderbrid + '&TransType=M';

      window.open(url, '_blank');
    }
    else {

      this.showmsg(permission);

    }
  }

  onCancelButtonClick() {
    if (this.attolreportdeletepath != '' && this.attolreportdeletepath != undefined && this.attolreportdeletepath != null)
      this.DeleteAtolReportPath();
    this.common.isfinalsaved = false;
    sessionStorage.setItem('pagevalue', '0')
    if (this.orderNo != 0 || this.orderNo != "")
      if (this.lockStatus == "0") {
        this.deletePenLock("FolderCancel", this.orderNo);
      }

    this.orderNo = "";
    //this.apiShared.orderNo=this.orderNo;

    this.apiShared.Setapishareddata('', 'PassengerNames');
    this.apiShared.Setapishareddata('', 'projNo');
    this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
    // this.router.navigate([localStorage.getItem('alerturl')],{skipLoction?Change:true});
    this.router.navigateByUrl(localStorage.getItem('alerturl'), { skipLocationChange: true });
    localStorage.removeItem('alerturl');
    this.common.folderEditMode = 'false';
    this.apiShared.folderEditMode = this.common.folderEditMode;//by case id-355
    this.apiShared.Setapishareddata(this.common.folderEditMode, 'folderEditMode');//by case id-355
  }

  deletePenLock(docType, docno) {

    this.apipenLock.DeleteLock(docType, docno + "/").subscribe(res => {
      this.apiShared.Setapishareddata("0", 'folderReadOnly');
    }, (err) => {
      console.log(err);

    }
    );
  }

  public opened = false;
  public atolopened: any = false
  public rptopened = false;
  public reportParms: any;
  public reportFile: string = "";
  public count: any;
  public openEpay = false;

  public close() {
    this.opened = false;
  }

  public open() {
    this.opened = true;
  }
  public atolclose() {
    debugger;
    this.atolopened = false;
    this.email.Atolre = false;
    this.showPrint = false;
    //  this.email.filename = '';
    //  this.email.atolfilepath = '';
    //  this.email.emailsubject = '';
    this.email.emailbody = '';
    if (this.attolreportdeletepath != '' && this.attolreportdeletepath != undefined && this.attolreportdeletepath != null)
      this.DeleteAtolReportPath();
  }

  public crClose() {
    debugger;
    this.crOpened = false;
    this.showPrint = false;
    //  this.email.filename = '';
    //  this.email.atolfilepath = '';
    //  this.email.emailsubject = '';
    this.email.emailbody = '';
    // if (this.crDeletePath != '' && this.crDeletePath != undefined && this.crDeletePath != null)
    // this.DeleteCrReportPath();
  }

  public atolopen() {
    this.atolopened = true;
  }


  public closeEpay(status) {
    this.openEpayMail = false;
  }
  public offset1 = -100;
  public rptLeft = this.offset;
  public rptTop = this.offset;
  //public width1 = 400;
  //public height1 = 350;
  public rptWidth = window.innerWidth - 200;
  public rptHeight = window.innerHeight - 100;
  onrptEnd() {
    debugger;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const positionTop = windowHeight - this.rptHeight - this.offset1;
    const positionRight = windowWidth - this.rptWidth - this.offset1;

    if (this.rptTop < this.offset1) {
      this.rptTop = this.offset1;
    }

    if (this.rptTop > positionTop) {
      this.rptTop = positionTop;
    }

    if (this.rptLeft < this.offset1) {
      this.rptLeft = this.offset1;
    }

    if (this.rptLeft > positionRight) {
      this.rptLeft = positionRight;
    }
  }
  public onEnd() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const positionTop = windowHeight - this.height - this.offset;
    const positionLeft = windowWidth - this.width - this.offset;

    if (this.windowTop < this.offset) {
      this.windowTop = this.offset;
    }

    if (this.windowTop > positionTop) {
      this.windowTop = positionTop;
    }

    if (this.windowLeft < this.offset) {
      this.windowLeft = this.offset;
    }

    if (this.windowLeft > positionLeft) {
      this.windowLeft = positionLeft;
    }
  }
  public atolonEnd() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const positionTop = windowHeight - this.heightCr - this.offset;
    const positionLeft = windowWidth - this.widthCr - this.offset;

    if (this.windowTop < this.offset) {
      this.windowTop = this.offset;
    }

    if (this.windowTop > positionTop) {
      this.windowTop = positionTop;
    }

    if (this.windowLeft < this.offset) {
      this.windowLeft = this.offset;
    }

    if (this.windowLeft > positionLeft) {
      this.windowLeft = positionLeft;
    }
  }

  public duration: number = 250;
  public type: string = 'zoom';
  public direction: string = 'down';
  public get animate(): any {
    if (this.opened) {
      return {
        type: this.type,
        direction: this.direction,
        duration: this.duration
      };
    }

    return false;
  }
  public showMsg: any;

  opencsfield() {
    let sectionInnerButton = document.getElementById("section-inner__button");

    if (sectionInnerButton == null) {
      console.log("sectionInnerButton is null")
    };

    sectionInnerButton.onclick = function (event) {
      var sectionInner = document.getElementById("pa-section-inner");

      sectionInner.classList.toggle("open");
    };
  }
  async Whatsapp() {
    if (Boolean(await this.common.checkPermission1('01.12.01.40')) == true) {
      this.router.navigate(['WhatsappMesssage/', this.orderNo]);
    } else {
      this.showmsg('01.12.01.40');
    }
  }
  getAtolType() {

    this.spinner.show();
    this.reportser.formData = new AtolReportDTO;
    this.reportser.formData.parFolderNo = this.orderNo
    this.reportser.GetAtoltype().subscribe(
      (res) => {

        if (res != null) {
          this.spinner.hide();
          this.AtolType = res["ResponseData"].AtolType;

          this.Projno = this.orderNo;//res["ResponseData"].
          if (res["ResponseData"].TYPEOFINV == 1 || res["ResponseData"].TYPEOFINV == 2 || res["ResponseData"].TYPEOFINV == 3 || res["ResponseData"].TYPEOFINV == 4) {
            this.showPrint = false;
          }
          else {
            this.showPrint = false;
          }
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
        }
      });
  }
  calculateAtol() {
    this.spinner.show();
    this.reportser.formData = new AtolReportDTO;
    this.reportser.formData.parFolderNo = this.orderNo
    this.reportser.UpdateAtolType().subscribe(
      (res) => {

        if (res != null) {
          this.spinner.hide();
          this.AtolType = res["ResponseData"].AtolType;
          this.TYPEOFINV = res["ResponseData"].TYPEOFINV;
          this.emailsubject = res["ResponseData"].emailsubject;
          this.emailbody = res["ResponseData"].emailBody;
          this.Projno = this.orderNo;//res["ResponseData"].Projno
          this.reportpathname = res["ResponseData"].reportfilename;
          if (res["ResponseData"].TYPEOFINV == 1 || res["ResponseData"].TYPEOFINV == 2 || res["ResponseData"].TYPEOFINV == 3 || res["ResponseData"].TYPEOFINV == 4) {
            this.showPrint = true;
          }
          else {
            this.showPrint = false;
          }
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
        }
      });

  }
  DeleteAtolReportPath() {
    this.spinner.show();
    this.urlSafe = null;
    this.reportser.formData = new AtolReportDTO;
    this.reportser.formData.Reportfolder = this.attolreportdeletepath
    this.reportser.formData.Reportpath = this.filepathatol;
    this.reportser.DeleteAtolReportPath().subscribe(
      (res) => {

        this.spinner.hide();

      });

  }

  DeleteCrReportPath() {
    this.spinner.show();
    this.urlSafe = null;
    this.reportser.formData = new AtolReportDTO;
    this.reportser.formData.Reportfolder = this.crDeletePath
    this.reportser.formData.Reportpath = this.crFilePath;
    this.reportser.DeleteAtolReportPath().subscribe(
      (res) => {
        this.spinner.hide();
      });

  }
  onPrintAtolreport() {
    this.email.atolfilepath = '';
    this.email.folderreportfilepath = '';
    this.subscription.add(this.translateapi.get(['Headings.Atolcertificate']).subscribe((translations) => {
      this.Atolcertificate = translations['Headings.Atolcertificate']

      // this.apiShared.uid=userId;
    }));

    window.scrollTo(0, 0);

    if (this.TYPEOFINV == 1 || this.TYPEOFINV == 2 || this.TYPEOFINV == 3 || this.TYPEOFINV == 4) {
      //this.urlSafe=this.sanitizer.bypassSecurityTrustResourceUrl('');
      this.atolopened = true;
      this.spinner.show();
      this.reportser.formData = new AtolReportDTO;
      this.reportser.formData.parFolderNo = this.orderNo;
      this.reportser.formData.emailsubject = this.emailsubject;
      this.reportser.formData.reportfilename = this.reportpathname;
      this.reportser.formData.TYPEOFINV = this.TYPEOFINV;
      this.reportser.AtolReport().subscribe(
        (res) => {

          if (res != null) {
            this.spinner.hide();
            this.rptfilename = res["ResponseData"].rptfilename;
            this.pdfFilePath = this.pdfurl + res["ResponseData"].Reportpath;
            this.filepathatol = res["ResponseData"].Reportpath;
            this.attolreportdeletepath = res["ResponseData"].Reportfolder

            this.emailatolpath = res["ResponseData"].EmailReportpath;
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfFilePath);
            this.spinner.hide();
          }
          else {
            this.spinner.hide();
          }
        });
    }
  }
  bookingRptClose(evnt) {
    this.loadBookingRpt = false;
  }
  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339

  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

  showmsg(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }
  onEpayEmailClick() {
    this.openEpayMail = true;
  }

  public EmailFormClosing(status: any) {
    if (status == "EmailSendSuccesfully" || status == "close") {


      if (status == "EmailSendSuccesfully") {
        this.subscription.add(this.translateapi.get(['Messages.Mailsent']).subscribe((translations) => {
          this.showMsg = translations['Messages.Mailsent'];
          this.toastr.success(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
      }
      this.openEpayMail = false;
    }

  }
  //Caseid:12616- PNR retrieval by Ganesh
  public retrievePNR(GDS) {
    debugger;
    if (this.PNR == '' || this.PNR == undefined) {
      this.subscription.add(this.translateapi.get(['Messages.pnr_required']).subscribe((translations) => {
        this.showMsg = translations['Messages.pnr_required'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      return;
    }

    if (GDS = "NDCBA") {
      this.loaded = false;
      this.spinner.show();
      this.objPNRRetrieval = {
        url: this.env.PenIBENDCBAURL + 'NDC/OrderRetrival',
        queryParams: '/' + GDS + '/' + this.PNR + '/' + this.orderNo + '/' + 'Admin'
      }
      this.subscription.add(this.api.RetrievePNR(this.objPNRRetrieval).subscribe(
        (data) => {
          debugger;
          if (data["ResponseData"] == 'True') {
            this.GetFolderDetailsById(this.orderNo);
            this.loaded = true;
            this.subscription.add(this.translateapi.get(['Messages.pnr_success']).subscribe((translations) => {
              this.showMsg = translations['Messages.pnr_success'];
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }));
          }
          else {
            this.spinner.hide();
            this.loaded = true;
            this.showMsg = data["ResponseData"];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }
        }));
    }
    else {
      if (this.env.PenIBEURL == '' || this.env.PenIBEURL == undefined) {
        this.showMsg = 'No IBE Settings Found';
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
        return;
      }
      if (GDS != null) {
        this.loaded = false;
        this.spinner.show();
        this.objPNRRetrieval = {
          url: this.env.PenIBEURL + 'AgentArea/PenAirFolderCreation.aspx?',
          queryParams: 'GDS=' + GDS.PROVIDERNAME + '&PNR=' + this.PNR + '&PenAirBookingRef=' + this.orderNo
        }
        this.subscription.add(this.api.RetrievePNR(this.objPNRRetrieval).subscribe(
          (data) => {
            debugger;
            if (data["ResponseData"] == 'True') {
              this.GetFolderDetailsById(this.orderNo);
              this.loaded = true;
              this.subscription.add(this.translateapi.get(['Messages.pnr_success']).subscribe((translations) => {
                this.showMsg = translations['Messages.pnr_success'];
                this.toastr.success(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
            }
            else {
              this.spinner.hide();
              this.loaded = true;
              this.showMsg = data["ResponseData"];
              this.toastr.warning(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }
          }));
      }
    }
  }
  public reminderOpen() {// Caseid-12739
    this.openRemider = true;
  }
  public reminderClose(e) { // Caseid-12739
    this.openRemider = false;
  }
  public folderReload(e) {
    this.GetFolderDetailsById(this.orderNo);
  }
  public gdsTrayOpen() {//By Caseid-12657
    this.openGDSTray = true;
  }
  public gdsTrayClose(e) { //By Caseid-12657
    if (!this.loaded) {
      this.GetFolderDetailsById(this.orderNo);
      this.loaded = true;
    }
    this.openGDSTray = false;
  }
  public productReload(e) {
    this.loaded = false;
  }
  async openPenChat() {
    if (Boolean(await this.common.checkPermission1("WhatsApp_Dashboard")) == true) {
      window.open(this.env.PenguinChatURL, "_blank", "noopener");
      return false;
    } else {
      this.showmsg("WhatsApp_Dashboard");
    }
  }
  //webLink: string = '';
  isWebLink = false;
  SendWebLinkClick() {
    var reportDetails = {
      OrderNo: this.orderNo,
      ReportName: 'PackageItinerary8',
      userID: this.apiShared.uid
    };
    this.isWebLink = true;
    this.selectedReport = 'PackageItinerary8';
    this.email.filename = '';
    this.email.filepath = '';
    if (this.orderNo != null && this.orderNo != "0" && this.orderNo != "") {
      this.subscription.add(
        this.lookupApi.GetReportDetails(this.orderNo).subscribe((data) => {
          debugger;
          if (data != null && data != undefined) {
            this.reportViewertemp = data["ResponseData"].ActualName;
            this.email.emailsubject = this.reportViewertemp.replace(
              "<<MAILFORMAT>>",
              this.selectedReport
            );
          }
        })
      );
    }
    this.subscription.add(this.rptAPI.GetWebLink(reportDetails)
      .subscribe(data => {
        if (data != null) {
          debugger;
          this.rptAPI.webLink = data['ResponseData'];
          //this.emailopened = true;
          this.email.Atolre = true;
          this.GetEmailBofyByFolderno();
        }
      }));
  }

  isSticky: boolean = false;
  @HostListener('window:scroll')
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }
  ngOnDestroy() {
    this.spinner.hide();
    this.email.filename = '';
    this.email.atolfilepath = '';
    this.email.emailsubject = '';
    this.email.emailbody = '';
    this.email.filename = '';
    this.email.Fromlist = undefined;
    this.subscription.unsubscribe();
  }

  public async crSendMail() {
    this.spinner.show();
    await this.email.ComposeMail(this.crRptName, this.crFilePath, this.crEmailPath, this.reportViewerHeader, this.emailbody, true);
    await this.email.updateReportParameterDTO(this.selectedReport);
    await this.email.GetEmailBodyByFolderno();
    await this.subscription.add(
      this.common
        .GetReportBodyOrSubject()
        .subscribe((res) => {
          this.spinner.hide();
          this.email.Fromlist = res["ResponseData"]["Fromdata"]
          this.email.CCEmail = res["ResponseData"]["CCEmail"];
          this.emailopened = true;
          this.common.formData.FolderNo = this.orderNo;
          this.common.formData.projno = this.projno;
          this.common.formData.BCCEmail = res["ResponseData"]["BCCEmail"];
          this.common.formData.BCCEnable = res["ResponseData"]["BCCEnable"];
          this.common.formData.ToEmail = res["ResponseData"]["ToEmail"];
          this.common.formData.EmailBody = res["ResponseData"]["EmailBody"].replace("<<WEBLINK>>", this.rptAPI.webLink);
          if (this.rptAPI.webLink == '') {
            this.common.formData.RecieptEmailBody = res["ResponseData"]["RecieptEmailBody"];
            if (this.atolattachement == false)
              this.email.folderreportfilepath = res["ResponseData"]["Attachementpath"];
          }
          this.emailopened = true;
        }
        )
    )


  }
  public rptFolderDetails = {
    orderNo: '',
    projNo: '',
    brName: '',
    folderstatus: '',
    atolattachement: false
  };
  loadBookingRpt = false;
  async onBookingConfirmationClick() {
    //this.rptclose();
    debugger;
    if(this.common.productexist==false)
{
  this.common.showWARNINGtoastrmsg("Messages.Itinerarynotfound", 3000, true, false);
return;
}
    this.selectedReport = '';
    this.subscription.add(this.translateapi.get(['Headings.booking_confirmation']).subscribe((translations) => {
      this.reportHeader = translations['Headings.booking_confirmation'];
    }));
    if (Boolean(await this.common.checkPermission1('01.12.01.09.02')) == true) {
      window.scrollTo(0, 0);
      this.windowTop = document.documentElement.scrollTop;
      this.windowLeft = document.documentElement.scrollLeft;
      if (this.width > document.documentElement.scrollWidth)
        this.width = document.documentElement.scrollWidth - 1;

      this.rptFolderDetails = {
        orderNo: this.orderNo,
        projNo: this.projno,
        brName: this.BrName,
        folderstatus: this.folderStatus,
        atolattachement: false
      };
      this.loadBookingRpt = true;

      // this.router.navigate(['PenAir/Folder/BooingConfirmation',this.orderNo],{skipLocationChange:true});

    }
    else {
      this.showmsg('01.12.01.09.02');
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.09.02');
    }
  }
  async onPrintReceiptClick() {
    debugger;
    if(this.common.productexist==false)
    {
      this.common.showWARNINGtoastrmsg("Messages.Itinerarynotfound", 3000, true, false);
    return;
    }
    this.rptopened = false;
    this.subscription.add(this.translateapi.get(['Headings.folder_receipt_printing']).subscribe((translations) => {
      this.reportHeader = translations['Headings.folder_receipt_printing']

      // this.apiShared.uid=userId;'Headings.booking_confirmation'
    }));

    if (Boolean(await this.common.checkPermission1('01.12.01.45')) == true) {
      this.rptFolderDetails = {
        orderNo: this.orderNo,
        projNo: this.projno,
        brName: this.BrName,
        folderstatus: this.folderStatus,
        atolattachement: false
      };
      this.selectedReport = "ReceiptPrinting";
      this.loadBookingRpt = true;
    }
    else {
      this.showmsg('01.12.01.45');
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.09.02');
    }
  }

  WetuTransferClick(value,status){
    debugger;
    this.spinner.show();
    let permission = "";
    switch (value) {
      case 'Wetu':
        permission = "01.12.01.34";
        if(status=='View'){
          this.ViewTransferClick(permission, value);
        }
        else{
          this.WetuTransferPermissionChecking(permission, value);
        }
        
        break;
    }
  }

  async WetuTransferPermissionChecking(permission, Provider) {
    debugger;
    if (Boolean(await this.common.checkPermission1(permission)) == true)
    {
      this.WeTuService.WeTuparam=new WeTuTransferDTO;
      this.WeTuService.WeTuparam.Provider=Provider;
      this.WeTuService.WeTuparam.BookingRef=this.apiShared.orderNo;
      this.WeTuService.WeTuparam.UserId=this.apiShared.uid;

      this.subscription.add(this.WeTuService.GetWeTuTransferResponse().subscribe(x=>{
        this.spinner.hide();
        debugger;
        var response=x["ResponseData"];
        if(response=="True"){
          this.common.showSUCCESStoastrmsg('Messages.WeTuSuccess', 3000, true, false);
        }
        else{
          this.common.showWARNINGtoastrmsg(response, 2000, true, false);
        }

      }));
    }
    else {
      this.spinner.hide();
      this.showmsg(permission);
    }
  }

  async ViewTransferClick(permission, Provider)
  {
    debugger;
    if (Boolean(await this.common.checkPermission1(permission)) == true)
    {
      this.subscription.add(this.WeTuService.GetWETU_BookingListURL().subscribe(x=>{
        this.spinner.hide();
        debugger;
        var response=x["ResponseData"].WETU_BookingListURL;
        window.open(response, '_blank');
      }));
    }
    else {
      this.spinner.hide();
      this.showmsg(permission);
    }
  }
}
