//import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'; // First, import Input
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { PermissionMessageDTO } from '../../Models/permission-message-dto.model';
import { UserAuthorisationDTO } from '../../Models/user-authorisation-dto.model';
import { CommonserviceService } from '../../Services/commonservice.service';
import { PermissionMessageService } from '../../Services/permission-message.service';
import { ShareDataService } from '../../Services/share-data.service';

@Component({
  selector: 'app-permission-message-form',
  templateUrl: './permission-message-form.component.html',
  styleUrls: ['./permission-message-form.component.scss']
})
export class PermissionMessageFormComponent implements OnInit {

  @Input() permissionId: string; // decorate the property with @Input()
  @Output() permissionStatus = new EventEmitter<string>();
  public PermissionMessageDTOParameter:UserAuthorisationDTO;
  btnRequestForAuthorization=false ;
  constructor(private apiShared: ShareDataService,private permissionMessageService:PermissionMessageService,
    private translateapi: TranslateService,public commonservice:CommonserviceService,
    private spinner: NgxSpinnerService) { }

  private subscription: Subscription = new Subscription();
  public showMsg:any;
  ngOnInit() {
  }

  public close() {
    this.permissionStatus.emit("EmailSendSuccesfully");
  }
  
  public onFormSubmit()
  {
    this.btnRequestForAuthorization = true;
    this.spinner.show();
    this.PermissionMessageDTOParameter =new UserAuthorisationDTO;
    this.PermissionMessageDTOParameter.type="Request";
    this.PermissionMessageDTOParameter.loggedUid= this.apiShared.uid;
    this.PermissionMessageDTOParameter.mId=this.permissionId;
    this.permissionMessageService.formData=this.PermissionMessageDTOParameter;
    this.subscription.add(this.permissionMessageService.SendModuleAuthorizationMail().
  subscribe(res=>{
  //alert(JSON.stringify(res["ResponseData"]))
  if(res!=null && res!=undefined && res["ResponseData"]!=undefined)
      {
        if(res!=null && res!=undefined && res["ResponseData"].result==1)
        {
          this.spinner.hide();
          this.btnRequestForAuthorization = false;
          this.subscription.add(this.translateapi.get(['Messages.MailSendSuccessfully']).subscribe((translations ) => {
            this.showMsg= translations['Messages.MailSendSuccessfully'].replace("<<1>>",res["ResponseData"].ToMail);
            this.commonservice.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.permissionStatus.emit("EmailSendSuccesfully");
        }

        else if(res!=null && res!=undefined && res["ResponseData"].result==0 && res["ResponseData"].ExceptionError != ""){
          this.commonservice.showWARNINGtoastrmsg(
            res["ResponseData"].ExceptionError,
            2000,
            true,
            false
          );
          this.spinner.hide();
          this.permissionStatus.emit("false");
        }
        else
        { debugger;
          this.spinner.hide();
          this.btnRequestForAuthorization = false;
          this.subscription.add(this.translateapi.get(['Messages.PleaseCheckEmailSettings']).subscribe((translations ) => {
            this.showMsg= translations['Messages.PleaseCheckEmailSettings'];
            this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.permissionStatus.emit("false");
        }
    }
      else{
        this.spinner.hide();
        this.btnRequestForAuthorization = false;
        this.permissionStatus.emit("false");
      } 
  }));
  }

}
