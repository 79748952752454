import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageSizeItem, RowArgs } from '@progress/kendo-angular-grid';
import { IntlService } from '@progress/kendo-angular-intl';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
import { DefaultChargesService } from '../../Services/default-charges.service';
import { ShareDataService } from '../../Services/share-data.service';

@Component({
  selector: 'app-defaultcharge-lookup',
  templateUrl: './defaultcharge-lookup.component.html',
  styleUrls: ['./defaultcharge-lookup.component.scss']
})
export class DefaultchargeLookupComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  DefaultChargesList: any;
  @Input() orderNo: string;
  @Input() homeCurrency: string;
  @Output() closeDefaultCharge: EventEmitter<string> = new EventEmitter();
  constructor(private defaultChargeService: DefaultChargesService, private translateapi: TranslateService,
    public common: CommonserviceService, public intl: IntlService, private spinner: NgxSpinnerService,
    private toastr: ToastrService, private apiShared: ShareDataService) { }

  gridHeight = window.innerHeight * .4;
  pageSize = 50;
  showFilter = false;
  showMsg = '';
  public mySelection: any[] = [];
  private contextItem: any;
  ngOnInit(): void {
    // this.Componentname = "DefaultChargesList";
    // sessionStorage.setItem('Componentname', this.Componentname);
    this.DefaultChargesListData();

  }


  //data load function
  public async DefaultChargesListData() {
    this.spinner.show();
    // if (Boolean(await this.common.checkPermission1('01.11.32.01.20.01')) == true) {
    this.subscription.add(
      this.defaultChargeService.GetDefaultChargesList().subscribe((data) => {
        debugger;
        this.spinner.hide();
        if (data != null && data != undefined) {
          this.DefaultChargesList = data["ResponseData"].filter(x => x.DEFAULTCHARGE == 0);

          //this.common.masterData = this.DefaultChargesList;
        } else {
          this.DefaultChargesList = [];
          //this.common.masterData = [];
        }
      })
    );

    //}
    // else {
    //   //this.spinner.hide();
    //   this.ShowPermissionMessageForm('01.11.32.01.20.01');
    // }
    this.mySelection = [];
  }
  onSelect(value) {
    debugger;
    this.contextItem = null;
    const len = this.mySelection.length;
    // if(value.selectedRows[0])
    // this.AuthDetails.rId=value.selectedRows[0].dataItem.RID;
    // else
    // this.AuthDetails.rId='';
  }
  public rowsSelectedKeys(context: RowArgs): any {
    return context.dataItem.AUTONO;
  }
  public onFilterClick() {
    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }
  uploadClick() {
    debugger;
    if (this.mySelection.length > 0) {
      let filteredX = this.DefaultChargesList.filter(item => this.mySelection.includes(item.AUTONO));
      var DefaultChargeUploadData = {
        DefaultChargesList: filteredX,
        FolderNo: this.orderNo,
        HomeCurrency: this.homeCurrency,
        BookedBy: this.apiShared.uname
      };
      this.spinner.show();
      this.DefaultChargeUpload(DefaultChargeUploadData);
    } else {
      this.subscription.add(this.translateapi.get(['Messages.SelectAtleastoneRow']).subscribe((translations) => {
        this.showMsg = translations['Messages.SelectAtleastoneRow'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 4000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
    }
  }
  async DefaultChargeUpload(gdsUploadData) {
    // if (Boolean(await this.common.checkPermission1('01.12.01.22')) == true) {
    //this.reload.emit('reload');
    this.subscription.add(this.defaultChargeService.AddDefaultChargeToProduct(gdsUploadData)
      .subscribe(res => {
        this.spinner.hide();
        if (res.ResponseData.RowAffected == 1) {
          this.contextItem = null;
          this.mySelection = [];
          this.common.showSUCCESStoastrmsg('Messages.saved_success', 3000, true, false)
          this.onDefChargeClose('saved');
        }
        else {
          //this.showMsg = res.ResponseData.Status;
          this.common.showWARNINGtoastrmsg('Messages.Failed', 3000, true, false)
          this.onDefChargeClose('closed');
        }
      }, (err) => {
        this.onDefChargeClose('closed');
      }));
    //}
    // else {
    //   this.spinner.hide();
    //   this.ShowPermissionMessageForm('01.12.01.22');//339
    // }
  }
  onDefChargeClose(status) {
    // if(this.reload){
    //   this.reload.emit('reload');
    // }else{
    this.closeDefaultCharge.emit(status);
    //}
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
