import { Component, OnInit, Input } from '@angular/core';
import { ShareDataService } from '../../Services/share-data.service';
import { Subscription } from 'rxjs';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { CommonserviceService } from '../../Services/commonservice.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-fchcdetails-new',
  templateUrl: './fchcdetails-new.component.html',
  styleUrls: ['./fchcdetails-new.component.scss']
})
export class FchcdetailsNewComponent implements OnInit {

  public labelchange = false;
  folderExrate: any;
  opentaxbaseForm: any = false;
  fldrCurrency: any;
  homeCurrency:any;
  private buyExRate: number;
  folderStatus: any;
  isShown = false;
  Producttype: any = false;
  buycurrency: any;
  divValue: any;
  supplier: any;
  custcountry: any;
  CostingVatType: any = 'No VAT'
  @Input() itineraryDetails;
  Tax: Array<LookUpDetails["ResponseData"]> = [];

  public TaxselectedItem: LookUpDetails["ResponseData"];
  public OutTaxselectedItem: LookUpDetails["ResponseData"];
  private subscription: Subscription = new Subscription();
  constructor(private apiShared: ShareDataService, private lookupApi: LookUpDetailsService, public intl: IntlService,
    public common: CommonserviceService) {
    debugger;

    // this.subscription.add(this.lookupApi.GetAllLookup('InputTaxMaster').subscribe(

  }


  taxLookup(country) {
    this.subscription.add(this.lookupApi.GetLookupById('InputTaxMaster', country, "''").subscribe(

      (data) => {
        if (data != null && data != undefined) {
          this.Tax = data["ResponseData"];


          var tax = this.itineraryDetails.ResponseData.SELLTAXNO;
          if (tax != "") {

            this.TaxselectedItem = this.Tax.find(item => item.Code == tax);

          }

        }

      }
    ));
  }
  ngOnInit() {
    debugger;
    this.custcountry = localStorage.getItem('CustomerCountry');
    this.homeCurrency=this.apiShared.homeCurrency;
    var d = this.itineraryDetails;
    if ((this.itineraryDetails.ResponseData.ITYPE) == 'Hotel' || (this.itineraryDetails.ResponseData.ITYPE) == 'Cruise' || this.itineraryDetails.ResponseData.ITYPE == 'Car' || this.itineraryDetails.ResponseData.ITYPE == 'Tour' || this.itineraryDetails.ResponseData.ITYPE == 'Rail' || this.itineraryDetails.ResponseData.ITYPE == 'Insurance' || this.itineraryDetails.ResponseData.ITYPE == 'Transfers') {
      this.buycurrency = this.itineraryDetails.ResponseData.CURCODE;
      if ((this.itineraryDetails.ResponseData.ITYPE) == 'Hotel')
        this.supplier = this.itineraryDetails.ResponseData.SUPPRTYCODE;
      else {
        this.supplier = this.itineraryDetails.ResponseData.SUPPCODE;
      }


    }
    if ((this.itineraryDetails.ResponseData.ITYPE) == 'Others') {
      this.buycurrency = this.itineraryDetails.ResponseData.CURID;
      this.supplier = this.itineraryDetails.ResponseData.SUPPCODE;
    }
    // if(this.itineraryDetails.ResponseData.ITYPE=="Hotel" ||this.itineraryDetails.ResponseData.ITYPE=="Others"||this.itineraryDetails.ResponseData.ITYPE=="Cruise"||this.itineraryDetails.ResponseData.ITYPE=="Car"||this.itineraryDetails.ResponseData.ITYPE=="Rail")
    // {
    this.Producttype = true;
    // }
    if (this.itineraryDetails.ResponseData.CostingVATType == 'NV') {
      this.divValue = 1;
      this.CostingVatType = 'No VAT'
    }
    else if (this.itineraryDetails.ResponseData.CostingVATType == 'VSF') {
      this.divValue = 2;
      this.CostingVatType = 'VAT on Service Fee'
    }
    else if (this.itineraryDetails.ResponseData.CostingVATType == 'VP') {
      this.divValue = 3;
      this.CostingVatType = 'VAT on Purchase'
    }
    else if (this.itineraryDetails.ResponseData.CostingVATType == 'VSC') {
      this.CostingVatType = 'VAT on Supp.Commission'
      this.divValue = 4;
    }
    else {
      this.divValue = 1;
      this.CostingVatType = 'No VAT'
    }
    this.lookupApi.GetSupplierorCustomerCountry('SUPPLIERS', this.supplier).subscribe(data => {
      var countryID;
      debugger;
      if (data != null && data != undefined && data["ResponseData"] != undefined && data["ResponseData"] != null) {
        countryID = data["ResponseData"].CONTID;
        // this.custcountry = countryID;
      }
      debugger;
      if (countryID == undefined || countryID == null || countryID == '') {
        this.lookupApi.GetDefaultCountry().subscribe(data1 => {
          if (data1 != null && data1 != undefined) {
            countryID = data1["ResponseData"].UsrCode;
            if (this.custcountry == null || this.custcountry == undefined) {
              this.custcountry = countryID;
            }

          }
        });
      }
      debugger;
      if (countryID != undefined && countryID != null && countryID != '') {
        this.getInputTaxLookup(countryID);
      }
      if (this.custcountry != undefined && this.custcountry != null)
        this.getOutTaxLookup(this.custcountry);

    }
    );

    this.getFolderValues();

    this.folderStatus = this.apiShared.FolderStatus;
    if (this.folderStatus == "Refund Request") {
      this.labelchange = true;
    }
    this.TaxsplitCalculation();
  }
  onOpenTaxbase() {
    this.opentaxbaseForm = true;
  }
  close() {
    this.opentaxbaseForm = false;
  }
  closeTaxBaseForm(): any { //13860 v1.0
    return {
      callParentMethod: (value: any) => {
        debugger;


      },
      closemodalpopup: () => {

        this.close()

      }
    }
  }
  TaxsplitCalculation() {
    this.folderExrate = this.apiShared.folderExchangeRate;
    // if((this.itineraryDetails.ResponseData.ITYPE ) =='Hotel'||(this.itineraryDetails.ResponseData.ITYPE ) =='Others')
    // {
    if (this.itineraryDetails.ResponseData.ITYPE == "Hotel") {
      this.buyExRate = this.itineraryDetails.ResponseData.EXRATE == "" || this.itineraryDetails.ResponseData.EXRATE == 0 ? 1 : this.itineraryDetails.ResponseData.EXRATE;


    }
    else {
      this.buyExRate = (this.itineraryDetails.ResponseData.CURExRate == "" || this.itineraryDetails.ResponseData.CURExRate == 0) ? 1 : this.itineraryDetails.ResponseData.CURExRate;
    }
    if (this.itineraryDetails.ResponseData.CostingVATType == 'NV' || this.itineraryDetails.ResponseData.CostingVATType == '' || this.itineraryDetails.ResponseData.CostingVATType == null || this.itineraryDetails.ResponseData.CostingVATType == undefined) {
      this.itineraryDetails.ResponseData.CostingCustomerSalesValue = (((this.itineraryDetails.ResponseData.FCBuy * this.buyExRate) / this.folderExrate));


      this.itineraryDetails.ResponseData.CostingCustomerServiceFee = this.itineraryDetails.ResponseData.FCSell - this.itineraryDetails.ResponseData.CostingCustomerSalesValue
    }
    if (this.itineraryDetails.ResponseData.CostingVATType == 'VSF') {
      this.itineraryDetails.ResponseData.CostingCustomerSalesValue = (((this.itineraryDetails.ResponseData.FCBuy * this.buyExRate) / this.folderExrate));


      this.itineraryDetails.ResponseData.CostingCustomerServiceFee = this.itineraryDetails.ResponseData.FCSell - this.itineraryDetails.ResponseData.CostingCustomerSalesValue
    }
    // }
  }

  getFolderValues() {
    // this.apiShared.folderCurrency.subscribe(fldrCurrency => this.fldrCurrency = fldrCurrency)
    // this.apiShared.folderExchangeRate.subscribe(folderExrate => this.folderExrate = folderExrate)
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    //this.folderExrate=new Number(this.folderExrate);


  }

  getInputTaxLookup(country: any) {
    this.subscription.add(this.lookupApi.GetLookupById('InputTaxMaster', country, "''").subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.Tax = data["ResponseData"];

          // alert(this.parentForm.controls.ITYPE.value);   
          var tax = this.itineraryDetails.ResponseData.CostingTaxNo;
          if (tax != "") {

            this.TaxselectedItem = this.Tax.find(item => item.Code == tax);

          }
        }
      }
    ));
  }
  getOutTaxLookup(country: any) {
    this.subscription.add(this.lookupApi.GetLookupById('OutputTaxMaster', country, "''").subscribe(
      (data) => {
        if (data != null && data != undefined) {

          this.Tax = data["ResponseData"];

          // alert(this.parentForm.controls.ITYPE.value);   
          var tax = this.itineraryDetails.ResponseData.SELLTAXNO;
          if (tax != "") {

            this.OutTaxselectedItem = this.Tax.find(item => item.Code == tax);

          }

        }
      }
    ));
  }


}
