import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { LookUpDetails } from "../../Services/look-up-details";
import { ActivatedRoute, Router } from "@angular/router";
import { LookUpDetailsService } from "../../Services/look-up-details.service";
import { ShareDataService } from "../../Services/share-data.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { FlashMessagesService } from "angular2-flash-messages";
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { TranslateService } from "@ngx-translate/core";
import { FileService } from "../../Services/file.service";
import { CommonserviceService } from "../../Services/commonservice.service";
import { Subscription } from "rxjs";
import { PublicLayoutComponent } from 'src/app/public-layout/public-layout.component';
import { AirlineDetailedReportDTO } from "../../Models/airline-detailed-report-dto";
import { AirlineDetailedReport } from "../../Services/airline-detailed-report";
import { NgxSpinnerService } from "ngx-spinner";
import { ItemComponent } from '@progress/kendo-angular-menu';
import { RptAirlineDetailedReportComponent } from 'src/app/rpt-airline-detailed-report/rpt-airline-detailed-report.component';
import { ExportPDFParam } from '../../Models/common-class.model';
import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';


@Component({
  selector: 'app-airline-detailed-report',
  templateUrl: './airline-detailed-report.component.html',
  styleUrls: ['./airline-detailed-report.component.scss']
})
export class AirlineDetailedReportComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  @ViewChild('autocompletecustomer') public autocompletecustomer: AutoCompleteComponent;
  @ViewChild('autocompleteTo') public autocompleteTo: AutoCompleteComponent;
  AirLineList: Array<LookUpDetails["ResponseData"]> = [];
  public AirLineSelectedItem: LookUpDetails["ResponseData"];
  public agentSelected: String;
  BOOKEBYList: Array<LookUpDetails["ResponseData"]> = [];
  public bookbyselectedItem: LookUpDetails["ResponseData"];
  public bookedbySelected: String;
  branchList: Array<LookUpDetails["ResponseData"]> = [];
  public branchSelectedItem: LookUpDetails["ResponseData"];
  public branchSelected: String;
  ClassTypeList: Array<LookUpDetails["ResponseData"]> = [];
  public ClassTypeSelectedItem: LookUpDetails["ResponseData"];
  public ClassTypeSelected: String;
  destinationToList: Array<LookUpDetails["ResponseData"]> = [];
  customerList: Array<LookUpDetails["ResponseData"]> = [];
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];
  CustPaymentMethodByList: Array<LookUpDetails["ResponseData"]> = [];
  DestinationCountryList: Array<LookUpDetails["ResponseData"]> = [];
  OriginCountryList: Array<LookUpDetails["ResponseData"]> = [];

  public destinationToSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelectedItem: LookUpDetails["ResponseData"];
  public customerSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelected: String;
  public customerSelected: String;
  public destinationSelected: String;
  public paymentMethodList: Array<string> = ["All", "Customer Card", "Company Card", "Credit"];
  public CustPaymentMethodSelectedItem: LookUpDetails["ResponseData"];
  public DestinationCountrySelectedItem: LookUpDetails["ResponseData"];
  public OriginCountrySelectedItem: LookUpDetails["ResponseData"];

  constructor(private spinner: NgxSpinnerService, public rptairticketcom: RptAirlineDetailedReportComponent,
    public common: CommonserviceService, private layout: PublicLayoutComponent, private route: ActivatedRoute,
    private lookupApi: LookUpDetailsService, private router: Router, private translateapi: TranslateService,
    private flashMessage: FlashMessagesService, private emailapi: FileService
    , private AirlineDetailedReportservice: AirlineDetailedReport, private cd: ChangeDetectorRef, private apishare: ShareDataService) { }

  public AirlineDetailedReportParamDTO: AirlineDetailedReportDTO;
  public gridData: any[];
  public opened: any = false;
  public CreateDateFrom: any = null;
  public CreateDateTo: any = null;
  public TicketDateFrom: any = null;
  public TicketDateTo: any = null;
  public TravelDateFrom: any = null;
  public TravelDateTo: any = null;
  public ADCreateDateFrom: any = "";
  public ADCreateDateTo: any = "";
  public ADTicketDateFrom: any = "";
  public ADTicketDateTo: any = "";
  public ADTravelDateFrom: any = "";
  public ADTravelDateTo: any = "";
  public ReturnDateFrom: any = null;
  public ReturnDateTo: any = null;
  public TicketDeadlineFrom: any = null;
  public TicketDeadlineTo: any = null;
  public ADReturnDateFrom: any = "";
  public ADReturnDateTo: any = "";
  public ADTicketDeadlineFrom: any = "";
  public ADTicketDeadlineTo: any = "";
  public ADFolderChangeDateFrom: any = "01/Jan/1900";
  public ADFolderChangeDateTo: any = "01/Jan/1900";
  public ExportPDFParamList: ExportPDFParam[];
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  public paymentSelectedItem: any = "All";

  public Airline = "";
  public VairlineAIRID = "";
  public DestinationFrom: String = "";
  public Destination = "";
  public CustPaymentMethod = "";
  public CustPayMethod = "";
  public Branch = "";
  public BusinessArea = "";
  public BranchCode = "";
  public BArea = "";
  public SupplierName: String = "";
  public Supplier: String = "";
  public BookedBy = "";
  public Customer = "";
  public PartyCode: any = "";
  userID: number;
  public showMsg: any;
  public ClassType = "";
  public PaymentMethod: any = "";
  public PaymentMethodName: any = "";
  public FareCommFrom: any = "";
  public FareCommTo: any = "";
  public PCC: any;
  public ModuleName: String = "Airline Detailed Report";
  public DestinationCntry = "";
  public DestinationCntryCode = "";
  public OriginCountry = "";
  public OriginCountryCode = "";
  public AirlineDetailedRequest: any;
  openSearch = false;
  action: any;
  public EmailSubject: String = "Airline Detailed Report";
  public rptloaded: any = false;
  public RptHdrSel_AutoNo: any;
  public RptHdrSel_UserId: any;
  public RptHdrSel_ModuleName: any;
  ngOnInit(): void {
    sessionStorage.setItem('Componentname', "AirlineDetailedRpt");

    this.userID = +(this.apishare.uid);
    this.AirlineDetailedReportservice.formDataHeader.RptHdrSel_UserId = this.userID;
    this.AirlineDetailedReportservice.formDataHeader.RptHdrSel_ModuleName =
      "AirlineDetailedReport";
    this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.AirLineList = data["ResponseData"];
          if (
            this.AirlineDetailedReportservice.formData != undefined &&
            this.AirlineDetailedReportservice.formData.Airline != null
          ) {
            this.AirLineSelectedItem = this.AirLineList.find(item => item.UsrCode == this.AirlineDetailedReportservice.formData.Airline);
            this.AirLineChange(this.AirLineSelectedItem);
          }
        }
      }));
    this.rptairticketcom.LoadTemplate();
    this.subscription.add(this.lookupApi.GetAllLookup('CLASSTYPE').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.ClassTypeList = data["ResponseData"]

          if (
            this.AirlineDetailedReportservice.formData != undefined &&
            this.AirlineDetailedReportservice.formData.ClassType != null
          ) {
            this.ClassTypeSelectedItem = this.ClassTypeList.find(item => item.Name == this.AirlineDetailedReportservice.formData.ClassType)
            this.ClassTypeValueChange(this.ClassTypeSelectedItem);
          }
        }

      }));
    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.BOOKEBYList = data["ResponseData"]
          if (
            this.AirlineDetailedReportservice.formData != undefined &&
            this.AirlineDetailedReportservice.formData.BookedBy != null
          ) {
            this.bookbyselectedItem = this.BOOKEBYList.find(item => item.Name == this.AirlineDetailedReportservice.formData.BookedBy);
            this.BookedByChange(this.bookbyselectedItem);
          }

        }
      }));
    this.subscription.add(this.lookupApi.GetAllLookup('BRANCH').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.branchList = data["ResponseData"]

          if (
            this.AirlineDetailedReportservice.formData != undefined &&
            this.AirlineDetailedReportservice.formData.Branch != null
          ) {

            this.branchSelectedItem = this.branchList.find(item => item.Name == this.AirlineDetailedReportservice.formData.Branch);
            this.BranchValueChange(this.branchSelectedItem);
          }
        }

      }));
    this.subscription.add(this.lookupApi.GetAllLookup('PAYMENT').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.CustPaymentMethodByList = data["ResponseData"]

          if (
            this.AirlineDetailedReportservice.formData != undefined &&
            this.AirlineDetailedReportservice.formData.CustPaymentMethod != null
          ) {
            this.CustPaymentMethodSelectedItem = this.CustPaymentMethodByList.find(item => item.Name == this.AirlineDetailedReportservice.formData.CustPaymentMethod);
            this.CustPaymentMethodValueChange(this.CustPaymentMethodSelectedItem);
          }
        }

      }));

    this.subscription.add(this.lookupApi.GetAllLookup('COUNTRY').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.DestinationCountryList = data["ResponseData"]

          if (
            this.AirlineDetailedReportservice.formData != undefined &&
            this.AirlineDetailedReportservice.formData.Destinationcountry != null
          ) {
            this.DestinationCountrySelectedItem = this.DestinationCountryList.find(item => item.Name == this.AirlineDetailedReportservice.formData.Destinationcountry);
            this.DestinationCountryValueChange(this.DestinationCountrySelectedItem);
          }

          if (this.AirlineDetailedReportservice.formData != undefined &&
            this.AirlineDetailedReportservice.formData.origin != null) {
            this.OriginCountrySelectedItem = this.DestinationCountryList.find(item => item.Name == this.AirlineDetailedReportservice.formData.origin);

            this.OriginCountryValueChange(this.OriginCountrySelectedItem);
          }
        }

      }));
    if (this.AirlineDetailedReportservice.formData != undefined && this.AirlineDetailedReportservice.formData != null) {
      if (this.AirlineDetailedReportservice.formData.CreateDateFrom != '01-Jan-1900') {
        this.CreateDateFrom = new Date(this.AirlineDetailedReportservice.formData.CreateDateFrom);
        this.CreateDateTo = new Date(this.AirlineDetailedReportservice.formData.CreateDateTo);
      }
      if (this.AirlineDetailedReportservice.formData.TicketDateFrom != '01-Jan-1900') {
        this.TicketDateFrom = new Date(this.AirlineDetailedReportservice.formData.TicketDateFrom);
        this.TicketDateTo = new Date(this.AirlineDetailedReportservice.formData.TicketDateTo);
      }
      if (this.AirlineDetailedReportservice.formData.TravelDateFrom != '01-Jan-1900') {
        this.TravelDateFrom = new Date(this.AirlineDetailedReportservice.formData.TravelDateFrom);
        this.TravelDateTo = new Date(this.AirlineDetailedReportservice.formData.TravelDateTo);
      }



      this.loadcustomerbyid();
      this.loadSupplierbyid();
      this.loadDestinationbyid();
      this.FareCommFrom = this.AirlineDetailedReportservice.formData.FareCommFrom;
      this.FareCommTo = this.AirlineDetailedReportservice.formData.FareCommTo;
      this.paymentSelectedItem = this.AirlineDetailedReportservice.formData.PaymentMethodName;
      this.supplierSelectedItem = this.AirlineDetailedReportservice.formData.SupplierName;
      this.PCC = this.AirlineDetailedReportservice.formData.PCC;



    }


  }



  handleFiltercustomer(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchCustomer(value, this.userID).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.customerList = data["ResponseData"];

          }

        }
      ));

    } else {
      this.autocompletecustomer.toggle(false);
    }
  }

  //13864
  openBox(lookuptype) {
    debugger;
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
    else if (this.lookuptype == 'CUSTOMER') {
      this.lookupname = 'CUSTOMER';
    }
  }

  getParentMethod(): any {
    debugger;
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        if (this.lookuptype == 'SUPPLIERS') {
          this.supplierSelectedItem = value;
          this.SupplierList = [];
          this.SupplierValueChange(value.ActualName)
        }
        else if (this.lookuptype == 'CUSTOMER') {
          debugger;
          this.customerSelectedItem = value;
          this.customerList = [];
          this.CustomerValueChange(value.Name)
        }

      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13864
  loadcustomerbyid() {
    if (this.AirlineDetailedReportservice.formData.Customer != '' && this.AirlineDetailedReportservice.formData.Customer != null) {

      this.subscription.add(this.lookupApi.findCustomer(this.AirlineDetailedReportservice.formData.Customer).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.customerList = data["ResponseData"];
            if (this.AirlineDetailedReportservice.formData.Customer != "") {

              this.customerSelectedItem = this.customerList.find(item => item.Code == this.AirlineDetailedReportservice.formData.Customer);

              if (this.customerSelectedItem != null) {
                this.customerSelected = this.customerSelectedItem.Name;
              }

              this.CustomerValueChange(this.customerSelected);
            }
          }

        }
      ));
    }
  }
  loadSupplierbyid() {
    if (this.AirlineDetailedReportservice.formData.SupplierName != '' && this.AirlineDetailedReportservice.formData.SupplierName != null) {

      this.subscription.add(this.lookupApi.findSupplier(this.AirlineDetailedReportservice.formData.SupplierName).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.SupplierList = data["ResponseData"];
            if (this.AirlineDetailedReportservice.formData.SupplierName != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.AirlineDetailedReportservice.formData.SupplierName);

              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
              }

              this.SupplierValueChange(this.supplierSelected);
            }
          }

        }
      ));
    }
  }
  loadDestinationbyid() {
    if (this.AirlineDetailedReportservice.formData.DestinationFrom != '' && this.AirlineDetailedReportservice.formData.DestinationFrom != null) {

      this.subscription.add(this.lookupApi.SearchDestination(this.AirlineDetailedReportservice.formData.DestinationFrom, "1").subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.destinationToList = data["ResponseData"];
            if (this.AirlineDetailedReportservice.formData.DestinationFrom != "") {

              this.destinationToSelectedItem = this.destinationToList.find(item => item.Code == this.AirlineDetailedReportservice.formData.DestinationFrom);

              if (this.destinationToSelectedItem != null) {
                this.destinationSelected = this.destinationToSelectedItem.Name;
              }
              this.DestinationValueChange(this.destinationSelected);
            }
          }

        }
      ));
    }
  }




  handleFiltersupplier(value) {
    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));

    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  handleFilterToDestination(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.destinationToList = data["ResponseData"];
          }
        }
      ));

    } else {
      this.autocompleteTo.toggle(false);
    }
  }
  public AirLineChange(value: any): void {

    if (value != null && value != undefined) {
      this.Airline = value.ActualName;
      this.VairlineAIRID = value.UsrCode;
    }
    else {
      this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.AirLineList = data["ResponseData"];
          }
        }
      ));
      this.Airline = "";
      this.VairlineAIRID = "";
    }

  }
  public BookedByChange(value: any): void {
    console.log('selectionChange', value);
    if (value != null && value != undefined)
      this.BookedBy = value.Name;
    else
      this.BookedBy = "";

  }
  public ClassTypeValueChange(value: any): void {
    if (value != null && value != undefined)
      this.ClassType = value.Name;
    else
      this.ClassType = "";


  }
  public CustPaymentMethodValueChange(value: any): void {

    if (value != null && value != undefined) {
      this.CustPaymentMethod = value.Name;
      this.CustPayMethod = value.UsrCode;
    }

    else {
      this.CustPaymentMethod = "";
      this.CustPayMethod = "";
    }


  }
  public PaymentMethodValueChange(value: any): void {

    if (value != null && value != undefined) {

      this.paymentSelectedItem = value.Name;

    }
    else {
      this.paymentSelectedItem = "All";

    }
  }
  public DestinationValueChange(value: any): void {

    this.destinationToSelectedItem = this.destinationToList.find(item => item.Name == value);

    if (this.destinationToSelectedItem != null) {
      this.DestinationFrom = this.destinationToSelectedItem.ActualName;
      this.Destination = this.destinationToSelectedItem.UsrCode;

    }
    else {
      this.DestinationFrom = "";
      this.Destination = "";
    }
  }

  public SupplierValueChange(value: any): void {
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);//13864

    }

    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13864

      this.SupplierName = this.supplierSelectedItem.ActualName;
      this.Supplier = (this.supplierSelectedItem.Code);
    }
    else {
      this.SupplierName = "";
      this.Supplier = "";
    }

  }

  public CustomerValueChange(value: any): void {
    if (this.customerList != null && this.customerList.length > 0) {//13864
      this.customerSelectedItem = this.customerList.find(item => item.Name == value);

    }


    if (this.customerSelectedItem != null) {
      this.customerSelected = this.customerSelectedItem.Name; //13864

      this.Customer = this.customerSelectedItem.ActualName;
      this.PartyCode = this.customerSelectedItem.Code;


    }
    else {
      this.Customer = "";
      this.PartyCode = "";
    }

  }
  public BranchValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.BranchCode = value.UsrCode;
      this.Branch = value.Name;

    }

    else {
      this.BranchCode = "";
      this.Branch = "";
    }
  }

  public DestinationCountryValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.DestinationCntryCode = value.UsrCode;
      this.DestinationCntry = value.Name;

    }

    else {
      this.DestinationCntryCode = "";
      this.DestinationCntry = "";
    }
  }

  public OriginCountryValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.OriginCountryCode = value.UsrCode;
      this.OriginCountry = value.Name;

    }

    else {
      this.OriginCountryCode = "";
      this.OriginCountry = "";
    }
  }


  CreateSheduler() {
    debugger;
    this.rptloaded = true;
    this.CreateDateFrom =
      this.CreateDateFrom == null || this.CreateDateFrom == ""
        ? "01-Jan-1900"
        : this.CreateDateFrom;

    this.CreateDateTo =
      this.CreateDateTo == null || this.CreateDateTo == ""
        ? "01-Jan-1900"
        : this.CreateDateTo;

    this.TicketDateFrom =
      this.TicketDateFrom == null || this.TicketDateFrom == ""
        ? "01-Jan-1900"
        : this.TicketDateFrom;

    this.TicketDateTo =
      this.TicketDateTo == null || this.TicketDateTo == ""
        ? "01-Jan-1900"
        : this.TicketDateTo;

    this.TravelDateFrom =
      this.TravelDateFrom == null || this.TravelDateFrom == ""
        ? "01-Jan-1900"
        : this.TravelDateFrom;

    this.TravelDateTo =
      this.TravelDateTo == null || this.TravelDateTo == ""
        ? "01-Jan-1900"
        : this.TravelDateTo;

    if ((this.CreateDateFrom == null || this.CreateDateFrom == "") && (this.TicketDateFrom == null || this.TicketDateFrom == "") && (this.TravelDateFrom == null || this.TravelDateFrom == "")) {
      this.common.showWARNINGtoastrmsg(
        "Messages.selectanyDate",
        2000,
        true,
        false
      );

      return;
    }
    // this.ADCreateDateFrom = this.common.ConvertToLocaleDate(this.CreateDateFrom);
    // this.ADCreateDateTo = this.common.ConvertToLocaleDate(this.CreateDateTo);
    // this.ADTicketDateFrom = this.common.ConvertToLocaleDate(this.TicketDateFrom);
    // this.ADTicketDateTo = this.common.ConvertToLocaleDate(this.TicketDateTo);
    // this.ADTravelDateFrom = this.common.ConvertToLocaleDate(this.TravelDateFrom);
    // this.ADTravelDateTo = this.common.ConvertToLocaleDate(this.TravelDateTo);


    var dateValidation = Date.parse(this.CreateDateFrom);
    if (!isNaN(dateValidation)) {
      this.CreateDateFrom = this.common.ConvertToLocaleDate(
        this.CreateDateFrom
      );

    } else if (this.CreateDateFrom != null && this.CreateDateFrom != "") {
      this.CreateDateFrom = "{{" + this.CreateDateFrom + "}}";
    }
    dateValidation = Date.parse(this.CreateDateTo);
    if (!isNaN(dateValidation)) {
      this.CreateDateTo = this.common.ConvertToLocaleDate(this.CreateDateTo);

    } else if (this.CreateDateTo != null && this.CreateDateTo != "") {
      this.CreateDateTo = "{{" + this.CreateDateTo + "}}";
    }
    dateValidation = Date.parse(this.TicketDateFrom);
    if (!isNaN(dateValidation)) {
      this.TicketDateFrom = this.common.ConvertToLocaleDate(this.TicketDateFrom);

    } else if (this.TicketDateFrom != null && this.TicketDateFrom != "") {
      this.TicketDateFrom = "{{" + this.TicketDateFrom + "}}";
    }

    dateValidation = Date.parse(this.TicketDateTo);
    if (!isNaN(dateValidation)) {
      this.TicketDateTo = this.common.ConvertToLocaleDate(this.TicketDateTo);

    } else if (this.TicketDateTo != null && this.TicketDateTo != "") {
      this.TicketDateTo = "{{" + this.TicketDateTo + "}}";
    }
    dateValidation = Date.parse(this.TravelDateFrom);
    if (!isNaN(dateValidation)) {
      this.TravelDateFrom = this.common.ConvertToLocaleDate(this.TravelDateFrom);

    } else if (this.TravelDateFrom != null && this.TravelDateFrom != "") {
      this.TravelDateFrom = "{{" + this.TravelDateFrom + "}}";
    }
    dateValidation = Date.parse(this.TravelDateFrom);
    if (!isNaN(dateValidation)) {
      this.TravelDateTo = this.common.ConvertToLocaleDate(this.TravelDateTo);

    } else if (this.TravelDateTo != null && this.TravelDateTo != "") {
      this.TravelDateTo = "{{" + this.TravelDateTo + "}}";
    }


    if (this.AirlineDetailedReportservice.formDataHeader != undefined) {
      this.RptHdrSel_AutoNo = this.AirlineDetailedReportservice.formDataHeader.RptHdrSel_AutoNo;
      this.RptHdrSel_UserId = this.AirlineDetailedReportservice.formDataHeader.RptHdrSel_UserId;
      this.RptHdrSel_ModuleName = this.AirlineDetailedReportservice.formDataHeader.RptHdrSel_ModuleName;

    }

    this.Airline = this.Airline == null || this.Airline == undefined ? "" : this.Airline;
    this.ClassType = this.ClassType == null || this.ClassType == undefined ? "" : this.ClassType;
    this.CustPaymentMethod = this.CustPaymentMethod == null || this.CustPaymentMethod == undefined ? "" : this.CustPaymentMethod;
    this.Branch = this.Branch == null || this.Branch == undefined ? "" : this.Branch;
    this.BookedBy = this.BookedBy == null || this.BookedBy == undefined ? "" : this.BookedBy;
    this.paymentSelectedItem = this.paymentSelectedItem == "All" ? "" : this.paymentSelectedItem;
    this.Supplier = this.Supplier == null || this.Supplier == undefined ? "" : this.Supplier;
    this.PCC = this.PCC == null || this.PCC == undefined ? "" : this.PCC;
    this.BranchCode = this.BranchCode == null || this.BranchCode == undefined ? "" : this.BranchCode;
    this.FareCommFrom = this.FareCommFrom == null || this.FareCommFrom == undefined ? "" : this.FareCommFrom;
    this.FareCommTo = this.FareCommTo == null || this.FareCommTo == undefined ? "" : this.FareCommTo;
    this.DestinationCntry = this.DestinationCntry == null || this.DestinationCntry == undefined ? "" : this.DestinationCntry;
    this.OriginCountry = this.OriginCountry == null || this.OriginCountry == undefined ? "" : this.OriginCountry;
    this.AirlineDetailedReportParamDTO = new AirlineDetailedReportDTO();
    this.AirlineDetailedReportParamDTO.CreateDateFrom = this.CreateDateFrom;
    this.AirlineDetailedReportParamDTO.CreateDateTo = this.CreateDateTo;
    this.AirlineDetailedReportParamDTO.TicketDateFrom = this.TicketDateFrom;
    this.AirlineDetailedReportParamDTO.TicketDateTo = this.TicketDateTo;
    this.AirlineDetailedReportParamDTO.TravelDateFrom = this.TravelDateFrom;
    this.AirlineDetailedReportParamDTO.TravelDateTo = this.TravelDateTo;
    this.AirlineDetailedReportParamDTO.FareCommFrom = this.FareCommFrom;
    this.AirlineDetailedReportParamDTO.FareCommTo = this.FareCommTo;
    this.AirlineDetailedReportParamDTO.Airline = this.VairlineAIRID;
    this.AirlineDetailedReportParamDTO.AirlineName = this.Airline;
    this.AirlineDetailedReportParamDTO.ClassType = this.ClassType;
    this.AirlineDetailedReportParamDTO.CustPaymentMethod = this.CustPaymentMethod;
    this.AirlineDetailedReportParamDTO.Customer = this.PartyCode;
    this.AirlineDetailedReportParamDTO.SupplierName = this.Supplier;
    this.AirlineDetailedReportParamDTO.DestinationFrom = this.Destination;
    this.AirlineDetailedReportParamDTO.Branch = this.Branch;
    this.AirlineDetailedReportParamDTO.BranchCode = this.BranchCode;
    this.AirlineDetailedReportParamDTO.BookedBy = this.BookedBy;
    this.AirlineDetailedReportParamDTO.PaymentMethodName = this.paymentSelectedItem;
    this.AirlineDetailedReportParamDTO.PCC = this.PCC;
    this.AirlineDetailedReportParamDTO.UserId = this.userID;
    this.AirlineDetailedReportParamDTO.CustomerName = this.Customer;
    this.AirlineDetailedReportParamDTO.ModuleName = this.ModuleName;
    this.AirlineDetailedReportParamDTO.SuppName = this.SupplierName;
    this.AirlineDetailedReportParamDTO.Destination = this.DestinationFrom;
    this.AirlineDetailedReportParamDTO.DestinationCntry = this.DestinationCntryCode;
    this.AirlineDetailedReportParamDTO.OriginCountry = this.OriginCountryCode;
    this.AirlineDetailedReportParamDTO.Destinationcountry = this.DestinationCntry;
    this.AirlineDetailedReportParamDTO.origin = this.OriginCountry;
    this.AirlineDetailedReportParamDTO.FolderChangeDateFrom = this.ADFolderChangeDateFrom;
    this.AirlineDetailedReportParamDTO.FolderChangeDateTo = this.ADFolderChangeDateTo;
    this.AirlineDetailedReportParamDTO.showDeleted = 0;
    this.AirlineDetailedReportParamDTO.RptHdrSel_ModuleName = this.RptHdrSel_ModuleName;
    this.AirlineDetailedReportParamDTO.RptHdrSel_UserId = this.RptHdrSel_UserId;
    this.AirlineDetailedReportParamDTO.RptHdrSel_AutoNo = this.RptHdrSel_AutoNo;
    this.AirlineDetailedReportservice.formData = this.AirlineDetailedReportParamDTO;

    this.AirlineDetailedRequest = JSON.stringify(this.AirlineDetailedReportParamDTO);
  }

  onReportGeneration() {
    debugger;
    this.spinner.show();
    if ((this.CreateDateFrom == null || this.CreateDateFrom == "") && (this.TicketDateFrom == null || this.TicketDateFrom == "") && (this.TravelDateFrom == null || this.TravelDateFrom == "")) {
      this.common.showWARNINGtoastrmsg(
        "Messages.selectanyDate",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }
    if ((this.CreateDateFrom == "Invalid-Date" || this.CreateDateTo == "Invalid-Date") || (this.TicketDateFrom == "Invalid-Date" || this.TicketDateTo == "Invalid-Date") || (this.TravelDateFrom == "Invalid-Date" || this.TravelDateTo == "Invalid-Date")) {
      this.common.showWARNINGtoastrmsg(
        "Messages.InvalidDate",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.CreateDateFrom)) == false) {

      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidFromDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidFromDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.CreateDateTo)) == false) {

      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidToDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidToDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (this.CreateDateTo == null || this.CreateDateTo == "") {
      this.CreateDateTo = this.CreateDateFrom;
    }
    if (Date.parse(this.CreateDateTo) < Date.parse(this.CreateDateFrom)) {
      this.common.showWARNINGtoastrmsg(
        "Messages.fromdate_exceeds",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }



    if (Boolean(this.common.ValidDateRange(this.TicketDateFrom)) == false) {

      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidFromDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidFromDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.TicketDateTo)) == false) {

      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidToDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidToDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (this.TicketDateTo == null || this.TicketDateTo == "") {
      this.TicketDateTo = this.TicketDateFrom;
    }
   
    if ( Date.parse(this.TicketDateTo) < Date.parse(this.TicketDateFrom)) {
      this.common.showWARNINGtoastrmsg(
        "Messages.fromdate_exceeds",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }


    if (Boolean(this.common.ValidDateRange(this.TravelDateFrom)) == false) {

      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidFromDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidFromDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.TravelDateTo)) == false) {

      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidToDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidToDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (this.TravelDateTo == null || this.TravelDateTo == "") {
      this.TravelDateTo = this.TravelDateFrom;
    }
    if (Date.parse(this.TravelDateTo) < Date.parse(this.TravelDateFrom)) {
      this.common.showWARNINGtoastrmsg(
        "Messages.fromdate_exceeds",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }

    if (Boolean(this.common.ValidDateRange(this.ReturnDateFrom)) == false) {

      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidFromDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidFromDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }


    if (Boolean(this.common.ValidDateRange(this.ReturnDateTo)) == false) {

      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidFromDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidFromDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.TicketDeadlineFrom)) == false) {

      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidFromDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidFromDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.TicketDeadlineTo)) == false) {

      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidFromDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidFromDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    var noofdays = 30;
    if (Boolean(this.common.DateRangeDiff(this.CreateDateFrom, this.CreateDateTo, noofdays)) == false) {
      debugger;
      this.subscription.add(
        this.translateapi
          .get(["Messages.daterangeismorethan30days"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.daterangeismorethan30days"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }

    if (Boolean(this.common.DateRangeDiff(this.CreateDateFrom, this.CreateDateTo, noofdays)) == false) {
      debugger;
      this.subscription.add(
        this.translateapi
          .get(["Messages.daterangeismorethan30days"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.daterangeismorethan30days"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.DateRangeDiff(this.TicketDateFrom, this.TicketDateTo, noofdays)) == false) {
      debugger;
      this.subscription.add(
        this.translateapi
          .get(["Messages.daterangeismorethan30days"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.daterangeismorethan30days"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.DateRangeDiff(this.TravelDateFrom, this.TravelDateTo, noofdays)) == false) {
      debugger;
      this.subscription.add(
        this.translateapi
          .get(["Messages.daterangeismorethan30days"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.daterangeismorethan30days"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (this.Branch != null && this.Branch != "" && this.Branch != undefined) {

      this.subscription.add(this.AirlineDetailedReportservice.BranchAuthorisation(this.Branch, this.userID).subscribe(async res => {
        if (res["ResponseData"].Status == "False") {
          this.common.showWARNINGtoastrmsg(
            "Messages.branchnoauthorized",
            2000,
            true,
            false
          );
          this.spinner.hide();
          return;
        }
      }));

    }

    this.CreateDateFrom =
      this.CreateDateFrom == null || this.CreateDateFrom == ""
        ? "01-Jan-1900"
        : this.CreateDateFrom;

    this.CreateDateTo =
      this.CreateDateTo == null || this.CreateDateTo == ""
        ? "01-Jan-1900"
        : this.CreateDateTo;

    this.TicketDateFrom =
      this.TicketDateFrom == null || this.TicketDateFrom == ""
        ? "01-Jan-1900"
        : this.TicketDateFrom;

    this.TicketDateTo =
      this.TicketDateTo == null || this.TicketDateTo == ""
        ? "01-Jan-1900"
        : this.TicketDateTo;

    this.TravelDateFrom =
      this.TravelDateFrom == null || this.TravelDateFrom == ""
        ? "01-Jan-1900"
        : this.TravelDateFrom;

    this.TravelDateTo =
      this.TravelDateTo == null || this.TravelDateTo == ""
        ? "01-Jan-1900"
        : this.TravelDateTo;


    this.ADCreateDateFrom = this.common.ConvertToLocaleDate(this.CreateDateFrom);
    this.ADCreateDateTo = this.common.ConvertToLocaleDate(this.CreateDateTo);
    this.ADTicketDateFrom = this.common.ConvertToLocaleDate(this.TicketDateFrom);
    this.ADTicketDateTo = this.common.ConvertToLocaleDate(this.TicketDateTo);
    this.ADTravelDateFrom = this.common.ConvertToLocaleDate(this.TravelDateFrom);
    this.ADTravelDateTo = this.common.ConvertToLocaleDate(this.TravelDateTo);


    this.Airline = this.Airline == null || this.Airline == undefined ? "" : this.Airline;
    this.ClassType = this.ClassType == null || this.ClassType == undefined ? "" : this.ClassType;
    this.CustPaymentMethod = this.CustPaymentMethod == null || this.CustPaymentMethod == undefined ? "" : this.CustPaymentMethod;
    this.Branch = this.Branch == null || this.Branch == undefined ? "" : this.Branch;
    this.BookedBy = this.BookedBy == null || this.BookedBy == undefined ? "" : this.BookedBy;
    this.paymentSelectedItem = this.paymentSelectedItem == "All" ? "" : this.paymentSelectedItem;
    this.Supplier = this.Supplier == null || this.Supplier == undefined ? "" : this.Supplier;
    this.PCC = this.PCC == null || this.PCC == undefined ? "" : this.PCC;
    this.BranchCode = this.BranchCode == null || this.BranchCode == undefined ? "" : this.BranchCode;
    this.FareCommFrom = this.FareCommFrom == null || this.FareCommFrom == undefined ? "" : this.FareCommFrom;
    this.FareCommTo = this.FareCommTo == null || this.FareCommTo == undefined ? "" : this.FareCommTo;
    this.DestinationCntry = this.DestinationCntry == null || this.DestinationCntry == undefined ? "" : this.DestinationCntry;
    this.OriginCountry = this.OriginCountry == null || this.OriginCountry == undefined ? "" : this.OriginCountry;

    this.ExportPDFParamList = [];
    var filter = new ExportPDFParam();
    if ((this.CreateDateTo == undefined || this.CreateDateTo == null)) {

      this.CreateDateTo = null;
    }
    else {
      this.CreateDateTo = this.common.ConvertToLocaleDate(this.CreateDateTo);
      this.CreateDateFrom = this.common.ConvertToLocaleDate(this.CreateDateFrom);
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.creation_date');
      filter.FirstValue = this.CreateDateFrom + ' - ' + this.CreateDateTo;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.TravelDateTo == undefined || this.TravelDateTo == null)) {

      this.TravelDateTo = null;
    }
    else {
      this.TravelDateTo = this.common.ConvertToLocaleDate(this.TravelDateTo);
      this.TravelDateFrom = this.common.ConvertToLocaleDate(this.TravelDateFrom);
      if (this.TravelDateFrom != "01-Jan-1900") {
        var filter = new ExportPDFParam();
        filter.Filter = this.common.GetTransilationMessage('Labels.travel_date');
        filter.FirstValue = this.TravelDateFrom + ' - ' + this.TravelDateTo;
        this.ExportPDFParamList.push(filter);
      }

    }
    if ((this.TicketDateTo == undefined || this.TicketDateTo == null)) {

      this.TicketDateTo = null;
    }
    else {
      this.TicketDateTo = this.common.ConvertToLocaleDate(this.TicketDateTo);
      this.TicketDateFrom = this.common.ConvertToLocaleDate(this.TicketDateFrom);
      if (this.TicketDateFrom != "01-Jan-1900") {
        var filter = new ExportPDFParam();
        filter.Filter = this.common.GetTransilationMessage('Labels.ticket_date');
        filter.FirstValue = this.TicketDateFrom + ' - ' + this.TicketDateTo;
        this.ExportPDFParamList.push(filter);
      }

    }


    if ((this.Airline == undefined || this.Airline == '')) {
      this.Airline = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.AirLine');
      filter.FirstValue = this.Airline;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.DestinationFrom == undefined || this.DestinationFrom == '')) {
      this.DestinationFrom = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.destination');
      filter.FirstValue = this.DestinationFrom;
      this.ExportPDFParamList.push(filter);
    }

    if ((this.FareCommFrom == undefined || this.FareCommFrom == '')) {
      this.FareCommFrom = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.FareCommAmt');
      filter.FirstValue = this.FareCommFrom + '-' + this.FareCommTo;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.DestinationCntry == undefined || this.DestinationCntry == '')) {
      this.DestinationCntry = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.DestinationCountry');
      filter.FirstValue = this.DestinationCntry;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.ClassType == undefined || this.ClassType == '')) {
      this.ClassType = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.ClassType');
      filter.FirstValue = this.ClassType;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.Branch == undefined || this.Branch == '')) {
      this.Branch = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.branch');
      filter.FirstValue = this.Branch;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.OriginCountry == undefined || this.OriginCountry == '')) {
      this.OriginCountry = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.OriginCountry');
      filter.FirstValue = this.OriginCountry;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.CustPaymentMethod == undefined || this.CustPaymentMethod == '')) {
      this.CustPaymentMethod = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.CustPaymentMethod');
      filter.FirstValue = this.CustPaymentMethod;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.BookedBy == undefined || this.BookedBy == '')) {
      this.BookedBy = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.booked_by');
      filter.FirstValue = this.BookedBy;
      this.ExportPDFParamList.push(filter);
    }


    if ((this.Customer == undefined || this.Customer == '')) {
      this.Customer = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.customer');
      filter.FirstValue = this.Customer;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.SupplierName == undefined || this.SupplierName == '')) {
      this.SupplierName = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.supplier');
      filter.FirstValue = this.SupplierName;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.PCC == undefined || this.PCC == '')) {
      this.PCC = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.Pcc');
      filter.FirstValue = this.PCC;
      this.ExportPDFParamList.push(filter);
    }

    if ((this.paymentSelectedItem == undefined || this.paymentSelectedItem == '')) {
      this.paymentSelectedItem = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.supplierpayment_method');
      filter.FirstValue = this.paymentSelectedItem;
      this.ExportPDFParamList.push(filter);
    }



    this.AirlineDetailedReportParamDTO = new AirlineDetailedReportDTO();
    this.AirlineDetailedReportParamDTO.CreateDateFrom = this.ADCreateDateFrom;
    this.AirlineDetailedReportParamDTO.CreateDateTo = this.ADCreateDateTo;
    this.AirlineDetailedReportParamDTO.TicketDateFrom = this.ADTicketDateFrom;
    this.AirlineDetailedReportParamDTO.TicketDateTo = this.ADTicketDateTo;
    this.AirlineDetailedReportParamDTO.TravelDateFrom = this.ADTravelDateFrom;
    this.AirlineDetailedReportParamDTO.TravelDateTo = this.ADTravelDateTo;
    this.AirlineDetailedReportParamDTO.FareCommFrom = this.FareCommFrom;
    this.AirlineDetailedReportParamDTO.FareCommTo = this.FareCommTo;
    this.AirlineDetailedReportParamDTO.Airline = this.VairlineAIRID;
    this.AirlineDetailedReportParamDTO.AirlineName = this.Airline;
    this.AirlineDetailedReportParamDTO.ClassType = this.ClassType;
    this.AirlineDetailedReportParamDTO.CustPaymentMethod = this.CustPaymentMethod;
    this.AirlineDetailedReportParamDTO.Customer = this.PartyCode;
    this.AirlineDetailedReportParamDTO.SupplierName = this.Supplier;
    this.AirlineDetailedReportParamDTO.DestinationFrom = this.Destination;
    this.AirlineDetailedReportParamDTO.Branch = this.Branch;
    this.AirlineDetailedReportParamDTO.BranchCode = this.BranchCode;
    this.AirlineDetailedReportParamDTO.BookedBy = this.BookedBy;
    this.AirlineDetailedReportParamDTO.PaymentMethodName = this.paymentSelectedItem;
    this.AirlineDetailedReportParamDTO.PCC = this.PCC;
    this.AirlineDetailedReportParamDTO.UserId = this.userID;
    this.AirlineDetailedReportParamDTO.CustomerName = this.Customer;
    this.AirlineDetailedReportParamDTO.ModuleName = this.ModuleName;
    this.AirlineDetailedReportParamDTO.SuppName = this.SupplierName;
    this.AirlineDetailedReportParamDTO.Destination = this.DestinationFrom;
    this.AirlineDetailedReportParamDTO.DestinationCntry = this.DestinationCntryCode;
    this.AirlineDetailedReportParamDTO.OriginCountry = this.OriginCountryCode;
    this.AirlineDetailedReportParamDTO.Destinationcountry = this.DestinationCntry;
    this.AirlineDetailedReportParamDTO.origin = this.OriginCountry;
    this.AirlineDetailedReportParamDTO.FolderChangeDateFrom = this.ADFolderChangeDateFrom;
    this.AirlineDetailedReportParamDTO.FolderChangeDateTo = this.ADFolderChangeDateTo;
    this.AirlineDetailedReportParamDTO.showDeleted = 0;

    //this.AirlineDetailedReportservice.formData = this.AirlineDetailedReportParamDTO;
    this.AirlineDetailedReportParamDTO.ExportPDFParam = this.ExportPDFParamList;
    this.AirlineDetailedReportservice.formData = this.AirlineDetailedReportParamDTO;
    this.AirlineDetailedRequest = JSON.stringify(this.AirlineDetailedReportParamDTO);
    this.subscription.add(this.AirlineDetailedReportservice.GetAirLineDetailedReport().subscribe(async res => {
      if (res["ResponseData"] != undefined) {
        this.spinner.hide();
        this.gridData = res["ResponseData"];

        if (this.gridData.length > 0) {
          this.AirlineDetailedReportservice.griddata = this.gridData;

          this.router.navigate(["Reports/AirLineDetailedReportView"], { skipLocationChange: true });
          this.spinner.hide();


        }
        else {
          this.common.showWARNINGtoastrmsg(
            "Messages.no_data_found",
            2000,
            true,
            false
          );

          this.spinner.hide();
        }
      }
      else {
        this.spinner.hide();
      }
    }
    ))

  }

  SetDate(date, controlName) {
    debugger;
    switch (controlName) {
      case 'CreateDateFrom':
        this.CreateDateFrom = (date == null || date == "" ? "01-Jan-1900" : date);
        break;

      case 'CreateDateTo':
        this.CreateDateTo = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'TravelDateFrom':
        this.TravelDateFrom = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'TravelDateTo':
        this.TravelDateTo = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'TicketDateFrom':
        this.TicketDateFrom = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'TicketDateTo':
        this.TicketDateTo = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
    }
  }

  public FormClosing(status: any) {
    this.rptloaded = false;
  }
  public ReportSchedulerFormClose() {
    this.rptloaded = false;
  }
  public schedulerClose() {
    this.rptloaded = false;
  }



  public close(status) {
    this.opened = false;
  }
  onCancel() {
    var reporturl = sessionStorage.getItem("menuhighlight");

    if (reporturl != null) {
      this.router.navigate(["/Login"], { skipLocationChange: true });
    } else {
      this.router.navigate(["PenAir/Home"], { skipLocationChange: true });
    }
  }

  onClearButtonClick() {
    this.AirlineDetailedReportservice.formData = undefined;
    this.action = "ClearAll";
    this.CreateDateFrom = null;
    this.CreateDateTo = null;
    this.TravelDateFrom = null;
    this.TravelDateTo = null;
    this.TicketDateFrom = null;
    this.TicketDateTo = null;
    this.FareCommFrom = "";
    this.FareCommTo = "";
    this.AirLineSelectedItem = null;
    this.supplierSelected = null;
    this.customerSelected = null;
    this.customerSelected = '';
    this.autocompletecustomer.reset();
    this.autocompletearrival.reset();
    this.autocompleteTo.reset();
    this.destinationToSelectedItem = null;
    this.bookbyselectedItem = null;
    this.paymentSelectedItem = "All";
    this.branchSelectedItem = null;
    this.CustPaymentMethodSelectedItem = null;
    this.ClassTypeSelectedItem = null;
    this.PCC = "";
    this.DestinationCountrySelectedItem = null;
    this.OriginCountrySelectedItem = null;
    this.cd.detectChanges();
    this.action = "";
  }
}
