export class CustomerCreate {
    Login: Login;
    CustomerMaster: CustomerMaster;
}

export class CustomerMaster {
    CustomerUserCode: string;
    Name: number;
    Address1: string;
    Address2: string;
    Address3: string;
    Address4: string;
    PostCode: string;
    VATNum: string;
    Country: string;
    Language: number;
    Currency: number;
    Salutation: number;
    Citizenship: number;
    // TelePhone :string;
    // EMail :string;
    // Area :string;
    // City :string;

}
export class CustomerAddDTO {
    GLCode: string;
    NOTES: string;
    SOM_UID: string;
    CustomerUserCode: string;

    Name: string;

    CCEMAIL: string;

    Address1: string;

    Address2: string;
    Address3: string;

    Address4: string;

    Salutation: string;
    TelePhone: string;

    EMail: string;

    Area: string;

    City: string;

    PostCode: string;
    VATNum: string;
    Country: string;
    Language: string;
    Currency: string;
    Citizenship: string;
    UID: string;
    CustomerType: string;

    ContactName: string;


    TradeContact: string;


    PhoneB: string;


    PhoneP: string;


    PhoneH: string;


    PhoneM: string;


    Fax: string;


    MailB: string;


    MailP: string;


    MailO: string;


    WebB: string;


    WebP: string;


    WebO: string;


    IsEdit: boolean;

    PayTerm: string;

    PayMethod: string;

    PAYMENTBASEDON: string;

    OneTimeCustomer: any;

    CorporateCustomer: any;

    GRPID: string;
    SCUT100_BRID: string;
    BAID: string;
    MCID: string;
    PCID: string;
    AFFILIATEID: any;


    CustomerCode: any;
    CustomerName: string;
    Status: string;
    VATNumber: string;
    EMailB: string;
    WebsiteB: string;
    TaxValidTo:any
    // TelePhone :string;
    // EMail :string;
    // Area :string;
    // City :string;

}
export class CustomerAddViewDTO {

    GLCode: string;
    NOTES: string;
    SOM_UID: string;
    CustomerUserCode: string;

    Name: string;

    Address1: string;

    Address2: string;
    Address3: string;

    Address4: string;

    Salutation: string;
    TelePhone: string;

    Area: string;
    EMail: string;



    City: string;

    PostCode: string;
    VATNum: string;
    Country: string;
    Language: string;
    Currency: string;
    Citizenship: string;
    UID: string;
    CustomerType: string;

    ContactName: string;


    TradeContact: string;


    PhoneB: string;

    WhatsAppNo: string;



    PhoneP: string;


    PhoneH: string;


    PhoneM: string;


    Fax: string;


    MailB: string;


    MailP: string;


    MailO: string;


    WebB: string;


    WebP: string;


    WebO: string;


    IsEdit: boolean;

    PayTerm: string;

    PayMethod: string;

    PAYMENTBASEDON: string;

    OneTimeCustomer: any;

    CorporateCustomer: any;

    GRPID: string;
    SCUT100_BRID: string;
    BAID: string;
    MCID: string;
    PCID: string;
    AFFILIATEID: any;


    CustomerCode: any;
    CustomerName: string;
    Status: string;
    VATNumber: string;
    EMailB: string;
    WebsiteB: string;
    CCEMAIL: string;
    TerritoryName:string;
    CustomerBranchList: {
        BRID: string
    }
    TaxValidTo:string;
    // TelePhone :string;
    // EMail :string;
    // Area :string;
    // City :string;

}
export class Login {
    Username: string;
    Password: number;
    URL: string;

}
