import { Injectable } from '@angular/core';
import { PaymentGatewayListDTO } from '../Models/payment-gateway-list-dto.model';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { CommonserviceService } from './commonservice.service';
import { EnvService } from 'src/env.service';
import { EpayEmail } from '../Models/epay-email';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})

export class PaymentgatewayListDetailsService {
  apiUrl: string;
  objEpayEmail: EpayEmail;
  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }
  GetPaymentGatewayList(): Observable<any> {
    const url = `${this.apiUrl}` + 'GetPaymentGateWayDetails';
    return this.http.get<any>(url)
  }
  GetProviderList(): Observable<any> {
    const url = `${this.apiUrl}` + 'GetPaymentGateListDetails';
    return this.http.get<any>(url)
  }



  checkWexPaymentAlreadyExists(): Observable<any> {
    const url = `${this.apiUrl}` + 'checkWexPaymentAlreadyExists';
    return this.http.get<any>(url);
  }

  GetActiveProviderList(): Observable<any> {
    const url = `${this.apiUrl}` + 'GetActivePaymentGateways';
    return this.http.get<any>(url)
  }
  GEtDefaultPDQ(Baid: any, currency: any, Provider: any): Observable<any> {
    if (Baid == "") Baid = 0;
    const url = `${this.apiUrl}` + 'GEtDefaultPDQ' + `/${Baid}/${currency}/${Provider}`;
    return this.http.get<any>(url)
  }



  GetOptionDate(FolderNo: any): Observable<any> {
    const url = `${this.apiUrl}` + 'GetOptionDate' + `/${FolderNo}`;
    return this.http.get<any>(url)
  }
  GEtDefaultPGateWay(Baid: any, currency: any): Observable<any> {
    const url = `${this.apiUrl}` + 'GEtDefaultPGateWay' + `/${Baid}/${currency}`;
    return this.http.get<any>(url)
  }

  public SendEpayEmail(): Observable<any> {
    var body = {
      ...this.objEpayEmail,


    };

    return this.http.post<any>(this.apiUrl + 'SendEpayEmail', body, httpOptions).pipe(
      tap((EmailInvoiceGetDetailsDTO: any) => console.log(`SendEpayEmail`)),
      catchError(this.commonservice.handleError<any>('SendEpayEmail'))

    );

  }

}
