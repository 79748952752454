import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../Services/login.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { PublicLayoutComponent } from '../public-layout/public-layout.component';
import { CommonserviceService } from '../Services/commonservice.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private subscription: Subscription = new Subscription();
    public showMsg: any;
    constructor(public logout: PublicLayoutComponent, public spinner: NgxSpinnerService, private commn: CommonserviceService, private authenticationService: LoginService, private toastr: ToastrService, private router: Router, private translateapi: TranslateService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401 || err.status === 403 || err.status === 404 || err.status === 503) {
                // auto logout if 401 response returned from api
                this.spinner.hide();
                // this.router.navigate(['/PenAir/Folder/Index']);
                //alert("Unauthorized");
                this.authenticationService.GetLogout();
                this.router.navigate(['/Login']);
                location.reload();
            }

            else if (err.status === 500) {
                //this.logout.onLogoutClick500Errror();
                this.spinner.hide();
                this.commn.showWARNINGtoastrmsg("Messages.Failed", 5000, true, false);
            }
            else if (err.status === 0) {
                //this.logout.onLogoutClick500Errror();
                this.spinner.hide();

            }


            if (err.error !== undefined) {
                this.spinner.hide();
                const error = err.error.message || err.statusText;
                return throwError(error);
            }


        }))
    }
}
