import { FolderPaymentPostGridParameter } from './folder-payment-post-grid-parameter';


export class Paymentpost {
      Id:number;
      CustCode:number;
      FolderNo:string;  
      Assign :string;  
      HCAmount:number;
      ForeignCurrencAmt :number;
      ReceiptAmt :number;
      AllocatedAmount :number;
      Reference:string;  
      TransactionType:string;  
      PostDate:string;  
      BRID :string;  
      UID :number;
      FCURID :string;  
      ExRate :number;
      ExchangeGainLossFlag:number;
      BankPayMethod:number;  
      BankCode:number;
      ChequeDate:Date;  
      ChequeNo :string; 

      DrawnbankName:string;  
      BacsDate :Date;  
     VoucherNo:number;
      AVRNO :number;
      AVRTYPE :number;
      AYearId :string; 
      HCBalance :number;
      HCPayBalance:number;
      FolderPaymentPostGridParameter:FolderPaymentPostGridParameter[];
}
