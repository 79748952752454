import { Component, OnInit, Input } from '@angular/core';
import { PassengerDetails } from '../../Models/passenger-details';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryaddressService } from '../../Services/deliveryaddress.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { ShareDataService } from '../../Services/share-data.service';
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Localization_Service } from '../../Services/localization.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-deliveryaddressedit',
  templateUrl: './deliveryaddressedit.component.html',
  styleUrls: ['./deliveryaddressedit.component.scss']
})
export class DeliveryaddresseditComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public showMsg: any;
  DeliveryEditForm: FormGroup;
  folderEditMode: any;
  CountryList:Array<LookUpDetails["ResponseData"]> = [];
  public countrySelectedItem: LookUpDetails["ResponseData"];
  public countrySelected:String;
  TerritoryList:Array<LookUpDetails["ResponseData"]> = [];
  public TerritotySelectedItem: LookUpDetails["ResponseData"];
  constructor(public common: CommonserviceService, private DeliveryaddressService: DeliveryaddressService, private formBuilder: FormBuilder, private route: ActivatedRoute
    , private router: Router, private spinner: NgxSpinnerService, private flashMessage: FlashMessagesService, private translateapi: TranslateService
    , private apiShared: ShareDataService, private toastr: ToastrService,public LocService : Localization_Service, private lookupApi: LookUpDetailsService
  ) {this.LocService.getJSONLanguage('Default'); }
  private orderNo: string = this.route.snapshot.params['orderNo'];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  submitted = false;
  ngOnInit() {
    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderNo !== orderno) {
        return this.router.navigate(['PenAir/Home']);
      }
    this.folderEditMode = "true";
    debugger;
    // this.apiShared.folderEditMode=this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.DeliveryEditForm = new FormGroup({
      DELIVERYADD0: new FormControl(),
      DELIVERYADD1: new FormControl(),
      DELIVERYADD2: new FormControl(),
      DELIVERYADD3: new FormControl(),
      DELIVERYADD4: new FormControl(),
      DELIVERYADD5: new FormControl(),
      DELIVERYADD6: new FormControl(),
      DELIVERYADD7: new FormControl(),
      USERCODE20: new FormControl(),
      FOLDERNO: new FormControl(),
    });
    this.DeliveryEditForm = this.formBuilder.group({
      DELIVERYADD0: ['', Validators.required],
      DELIVERYADD1: ['', Validators.required],
      DELIVERYADD2: [''],
      DELIVERYADD3: [''],
      DELIVERYADD4: ['', Validators.required],
      DELIVERYADD5: [''],
      DELIVERYADD6: [''],
      DELIVERYADD7: [''],
      USERCODE20: [''],
      FOLDERNO: [],
    });
    this.spinner.show();
    this.subscription.add(this.DeliveryaddressService.GetContactDetailsById(this.orderNo).subscribe(res => {
      // alert(JSON.stringify(res["ResponseData"].DELIVERYADD0));
debugger;
      if (res["ResponseData"] != null)
        this.DeliveryEditForm.setValue({
          DELIVERYADD0: res["ResponseData"].DELIVERYADD0 == '' ? res["ResponseData"].CUSTOMERNAME : res["ResponseData"].DELIVERYADD0,
          DELIVERYADD1: res["ResponseData"].DELIVERYADD1,
          DELIVERYADD2: res["ResponseData"].DELIVERYADD2
          , DELIVERYADD3: res["ResponseData"].DELIVERYADD3,
          DELIVERYADD4: res["ResponseData"].DELIVERYADD4,
          DELIVERYADD5: res["ResponseData"].DELIVERYADD5,
          DELIVERYADD6: res["ResponseData"].DELIVERYADD6
          , DELIVERYADD7: res["ResponseData"].DELIVERYADD7,
          FOLDERNO: res["ResponseData"].FOLDERNO,
          USERCODE20: res["ResponseData"].USERCODE20,
        });
      //   if(this.DeliveryEditForm.controls.DELIVERYADD5.value!="")
      //   {
         
      //   this.TerritotySelectedItem=this.TerritoryList.find(item => item.Name ==this.DeliveryEditForm.controls.DELIVERYADD5.value);
    
      
      //  }
      this.subscription.add( this.lookupApi.GetAllLookup('COUNTRY').subscribe(
        (data) => {
          if(data!=null && data!=undefined)
          {
          this.CountryList= data["ResponseData"] ;
          }
          if(res["ResponseData"].USERCODE20==null){
            res["ResponseData"].USERCODE20=res["ResponseData"].Country;
          }
          if(res["ResponseData"].USERCODE20!=null){
      this.countrySelectedItem=this.CountryList.find(item => item.UsrCode ==res["ResponseData"].USERCODE20);
       this.GetTerritoryByCountry(res["ResponseData"].USERCODE20)
        this.LocService.getJSONLanguage(res["ResponseData"].USERCODE20);
        }
        }))
      this.spinner.hide();
    }));
  }
  public CountryValueChange(value: any): void {
    debugger;
    this.spinner.show();
    if(value!=null && value!=undefined)
    {
      this.TerritoryList=[]
      this.TerritotySelectedItem=null;
      this.DeliveryEditForm.controls.DELIVERYADD5.setValue('');
      this.GetTerritoryByCountry(value.UsrCode);
      this.DeliveryEditForm.controls.USERCODE20.setValue(value.UsrCode);
      this.DeliveryEditForm.controls.DELIVERYADD7.setValue(value.Name);
      value.Code=value.Code.toUpperCase();
      this.LocService.getJSONLanguage(value.Code);
     
    } 
    else
    {
      debugger;
      this.spinner.hide();
      this.TerritotySelectedItem=null;
      this.TerritoryList=[]
      this.DeliveryEditForm.controls.DELIVERYADD5.setValue('');
      this.DeliveryEditForm.controls.DELIVERYADD7.setValue('');
      this.countrySelectedItem=null;
      this.DeliveryEditForm.controls.USERCODE20.setValue("");
      this.LocService.getJSONLanguage('Default');
    }
   
  }
  onFormSubmit() {
    this.submitted = true;
    if (this.DeliveryEditForm.invalid) {
      return;
    }
    // alert(JSON.stringify(this.DeliveryEditForm.value));
    this.spinner.show();
    this.subscription.add(this.DeliveryaddressService.UpdateDeliveryAddress(this.DeliveryEditForm.value).subscribe
      (res => {
        if (res != null && res != undefined) {
          if (res.IsSuccess === true && res.Message === 'SUCCESS') {
            // alert('SUCCESS3!! :-)\n\n' + JSON.stringify(this.railEditForm.value, null, 4));
            this.spinner.hide();
            this.common.showSUCCESStoastrmsg('Messages.saved_success', 2000, true, false);//13042

            this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo]);
          }
          else if (res.IsSuccess === false && res.Message === 'Fail') {
            this.spinner.hide();
            this.toastr.warning(res.ResponseData.ErrorMessage, "", {
              timeOut: 3000,
              disableTimeOut: true,
              closeButton: true
            })
          }
          else {
            this.spinner.hide();
            this.toastr.warning(res.Message, "", {
              timeOut: 3000,
              disableTimeOut: true,
              closeButton: true
            })
          }
        }
        else {
          this.spinner.hide();
        }
      }, (err) => {
        // console.log(err);
        // alert('Failed!! :-)\n\n' +err+'\n\n'+ JSON.stringify(this.railEditForm.value, null, 4));
        this.spinner.hide();
        this.common.showWARNINGtoastrmsg('Messages.some_error_in_save', 2000, true, false);//13042
      }));
    //();

  }
  GetTerritoryByCountry(usrcode)
{
  this.subscription.add(
    this.lookupApi
      .GetLookupById("Territory", usrcode, "1")
      .subscribe(data => {
        if(data!=null && data!=undefined)
        {
          debugger;
          this.spinner.hide();
         this.TerritoryList= data["ResponseData"] 
         if( this.TerritoryList.length>0)
         {
           if(this.DeliveryEditForm.controls.DELIVERYADD5.value!=""&& this.DeliveryEditForm.controls.DELIVERYADD5.value!='')
          this.TerritotySelectedItem=   this.TerritoryList.find(x=>x.Name==this.DeliveryEditForm.controls.DELIVERYADD5.value);
        else{
         // this.TerritotySelectedItem=   this.TerritoryList[0];
         // this.supplierdetailsform.controls.TerritoryName.setValue(this.TerritotySelectedItem.Name);
        }
        }
         
         else
         {
          this.DeliveryEditForm.controls.DELIVERYADD5.setValue('');
          this.TerritotySelectedItem=null;
         }
                     
      }
      else
      {
        this.spinner.hide();
      }
      }))
}
  TerrritoyValueChange(value: any)
  {
    if(value!=null && value!=undefined){
      this.DeliveryEditForm.controls.DELIVERYADD5.setValue(value.Name);
     
  }
  else
  {
    this.DeliveryEditForm.controls.DELIVERYADD5.setValue("");
   
  }
  }
  onCancel() {
    this.router.navigate(['/PenAir/Folder/FolderSummary', this.orderNo]);
  }

  get f() { return this.DeliveryEditForm.controls; }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
