import { NgAnalyzeModulesHost } from "@angular/compiler"

export class FolderActivityDetailedDTO {
    ID: any;

    CreationFromDate: any;
    CreationToDate: any;
    InvoiceFromDate: any;
    InvoiceToDate: any;
    TravelFromDate: any;
    TravelToDate: any;
    BookingDateFrom: any;
    BookingDateTo: any;
    QConDateFrom: any;
    QConDateTo: any;
    CustCode_int: any;
    Branch_varchar: any;
    FolderBookedBy: any;
    BArea_varchar: any;
    DCM_UID: any;
    DM_UID: any;
    SalesOrgCode: any;
    UserId: any;
    CustGrp_varchar: any;
    CCGINTCODE_bigint: any;
    Paymentmethod: any;
    pOrderType_varchar: any;
    pBookedBy_varchar: any;
    pProductCode_int: any;
    PrtyCode: any;
    ProfitFrom: any;
    ProfitTo: any;
    Destination: any;
    vFolderNo: any;
    OrderNo: any;
    ReciptStatus: any;
    ProdStatus: any;
    template: any;
    csChecked: any;
    RptHdrSel_AutoNo: any;
    RptHdrSel_ModuleName: any;
    RptHdrSel_UserId: any;
}
