import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EnvService } from '../../env.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Login } from '../Models/customer-create.model';
import { SupplierMasterDetailsDTO } from '../Models/supplier-master-details-dto.model';
import { LookUpDetails } from './look-up-details';
import { CommonserviceService } from './commonservice.service';
import { SupplierList } from '../Models/supplier-list';
import { tap, catchError } from 'rxjs/operators';
import { SupplierMaster } from '../Models/supplier-master';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class SupplierMasterService {

  apiUrl :string;
  public formData:SupplierMasterDetailsDTO;
// public CustomerMaster:CustomerMaster;
UsrCode:String;
Name:String ;
public Login:Login;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   } 
  SaveCustomerAddress (): Observable<SupplierMasterDetailsDTO> {
    var body = {
      ...this.formData

    };
    return this.http.post<SupplierMasterDetailsDTO>(this.apiUrl+'SupplierAdd', body);
    //alert(JSON.stringify(FolderSummary));
  }
  // AddSupplier (): Observable<any> {
  //   var body = {
  //     ...this.formData

  //   };
  //   return this.http.post<any>(this.apiUrl+'SupplierDetailsAdd', body);
  //   //alert(JSON.stringify(FolderSummary));
  // }
  AddSupplier(supplierdetail: SupplierMaster): Observable<any> {
    var TaxValidTodata = (supplierdetail.TaxValidTo == null || supplierdetail.TaxValidTo == "" ? "01-Jan-1900" : supplierdetail.TaxValidTo);//Case id-337

    //  TaxValidTo=new Date(TaxValidTo);
    if(TaxValidTodata =="01-Jan-1900"){
      TaxValidTodata=this.commonservice.ConvertToLocaleDate(new Date(TaxValidTodata))
    }
    else
    TaxValidTodata=this.commonservice.ConvertToLocaleDate(supplierdetail.TaxValidTo)
    supplierdetail.TaxValidTo=(TaxValidTodata);
     return this.http.post<any>(this.apiUrl + 'SupplierDetailsAdd', supplierdetail, httpOptions).pipe(
       //tap((tourRes: any) => console.log(`added Supplier w/ id=${tourRes.ResponseData.SUPPNAME}`)),
      // catchError(this.commonservice.handleError<any>('addTour'))
     );
   }
   UpdateSupplier(supplierdetail: SupplierMaster): Observable<any>{
   
      debugger;
      var TaxValidTodata = (supplierdetail.TaxValidTo == null || supplierdetail.TaxValidTo == "" ? "01-Jan-1900" : supplierdetail.TaxValidTo);//Case id-337

    //  TaxValidTo=new Date(TaxValidTo);
    if(TaxValidTodata =="01-Jan-1900"){
      TaxValidTodata=this.commonservice.ConvertToLocaleDate(new Date(TaxValidTodata))
    }
    else
    TaxValidTodata=this.commonservice.ConvertToLocaleDate(supplierdetail.TaxValidTo)
    supplierdetail.TaxValidTo=(TaxValidTodata);
    
     return this.http.post<any>(this.apiUrl + 'UpdateSupplierDetails', supplierdetail, httpOptions).pipe(
       //tap((tourRes: any) => console.log(`added Supplier w/ id=${tourRes.ResponseData.SUPPNAME}`)),
      // catchError(this.commonservice.handleError<any>('addTour'))
     );
   }
   deleteSupplier(prtycode: any,language:any):  Observable<any> {
    
    const url = `${this.apiUrl}`+'DeleteSupplierDetails'+`/${prtycode}/${language}`;
     return this.http.post<any>(url, httpOptions).pipe(
      tap(_ => console.log(`deleted Supplier`)),
      catchError(this.commonservice.handleError<any>('deleteSupplier'))
     );
   }
  getSupplierList():  Observable<SupplierList[]>{
    const url = `${this.apiUrl}`+'GetAllSupplierDetails';
    // const url = `${apiUrl}/${OrderNo}/${GuidItinerary}`;
    return this.http.get<SupplierList[]>(url).pipe(
      tap(_ => console.log(`fetched AllSupplier`)),
      catchError(this.commonservice.handleError<SupplierList[]>(`GetAllSupplier`))
    );
  }
  GetStatusList(): Observable<any[]> {
    const url = `${this.apiUrl}`+'GetSupplierStatus';
    // const url = `${apiUrl}/${OrderNo}/${GuidItinerary}`;
    return this.http.get<any[]>(url).pipe(
      catchError(this.commonservice.handleError<any[]>(`GetSupplierStatus`))
    );
  }
  GetSupplierDetailsbyId(prtycode:any):Observable<any> { 
    const url =  `${this.apiUrl}`+'GetSupplierDetailByID'+`/${prtycode}`;
    return this.http.get<any>(url);  
  }

  GetDefaultValue(prtycode:any): Observable<any> {
    const url = `${this.apiUrl}`+'GetSupplierDefault'+`/${prtycode}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched SupplierDefault id=${prtycode}`)),
      catchError(this.commonservice.handleError<any>(`SupplierDefault id=${prtycode}`))
    );
  }
}
