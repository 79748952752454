import { Component, Input, OnInit } from '@angular/core';
import { EditorComponent } from '@progress/kendo-angular-editor';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ImageInfo } from 'src/app/upload-image/upload-image.component';
import { of } from 'rxjs';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements HttpInterceptor {
  @Input() public editor: EditorComponent;

    public opened = false;
    public src: string;
    public height: number;
    public width: number;

    public get canInsert(): boolean {
        return !this.src;
    }

    public uploadImage(): void {
        // Invoking the insertImage command of the Editor.
        this.editor.exec('insertImage', this.imageInfo);

        // Closing the Dialog.
        this.close();
    }

    public get imageInfo(): ImageInfo {
        return {
            src: this.src,
            height: this.height,
            width: this.width
        };
    }

    public setImageInfo(value: ImageInfo) {
      debugger;
        if (value) {
            this.src = value.src;
            this.height = value.height;
            this.width = value.width;
        } else {
            this.resetData();
        }
    }

    public open(): void {
        this.opened = true;
    }

    public close(): void {
        this.opened = false;
        this.resetData();
    }

    public resetData(): void {
        this.src = null;
        this.width = null;
        this.height = null;
    }

    /*
        Mocked backend service.
        For further details, check
        https://angular.io/guide/http#writing-an-interceptor
   */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url === 'saveUrl' || req.url === 'removeUrl') {
            return of(new HttpResponse({ status: 200 }));
        }

        return next.handle(req);
    }



  

  


}
