import { Component, OnInit,Input } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { CommonserviceService } from 'src/app/Services/commonservice.service';
@Component({
  selector: 'app-corporate-info-details',
  templateUrl: './corporate-info-details.component.html',
  styleUrls: ['./corporate-info-details.component.scss']
})
export class CorporateInfoDetailsComponent implements OnInit {

  @Input() itineraryDetails;
  constructor(public intl: IntlService,public common:CommonserviceService
    ) { }

  ngOnInit() {
  }
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;

  public onChange(event: any): void {
      this.expandMode = parseInt(event.target.value, 10);
  }
  public onPanelChange(event: any): void { console.log('stateChange: ', event); }
}
