export class FolderRefund {
    pFolderNo:string ;
    pACKAGED:number;
    invoiceDeleteFlag:number;
    language:any ;
    invDate:any;
    pPmtTermId:number;
    pPmtMeth:string;
    pDepDueDate :any;
    pProfitCentre :string;
}
