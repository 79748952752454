import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { OthersDetailsEditComponent } from '../others-details-edit/others-details-edit.component';
import { ProductService } from '../../Services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
import { FolderSummaryService } from '../../Services/folder-summary.service';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  Productdetailsform: FormGroup;
  public submitted = false;
  public showMsg: any;

  constructor(public commonservice: CommonserviceService, private formBuilder: FormBuilder, private lookupApi: LookUpDetailsService, private othersdetail: OthersDetailsEditComponent,
    private product: ProductService, private translateapi: TranslateService, private toastr: ToastrService, private foldersummary: FolderSummaryService) { }

  salesAccountList: Array<LookUpDetails["ResponseData"]> = [];
  public salesAccountSelectedItem: LookUpDetails["ResponseData"];
  public salesAccountSelected: String;

  purchaseAccountList: Array<LookUpDetails["ResponseData"]> = [];
  public purchaseAccountSelectedItem: LookUpDetails["ResponseData"];
  public purchaseAccountSelected: String;

  commissionAccountList: Array<LookUpDetails["ResponseData"]> = [];
  public commissionAccountSelectedItem: LookUpDetails["ResponseData"];
  public commissionAccountSelected: String;

  groupNameList: Array<LookUpDetails["ResponseData"]> = [];
  public groupNameSelectedItem: LookUpDetails["ResponseData"];
  public groupNameSelected: String;

  taxList: Array<LookUpDetails["ResponseData"]> = [];
  public taxSelectedItem: LookUpDetails["ResponseData"];
  public taxSelected: String;
  IsAutocode = false;
  isReadOnly = false;
  ngOnInit() {


    this.Productdetailsform = this.formBuilder.group({
      Name: ['', Validators.required],
      UserCode: [''],
      Alias: [''],
      GrpCode: [0],
      SALES: ["", Validators.required],
      PURCHASE: ["", Validators.required],
      COMMISSION: ["", Validators.required],
      TaxNo: ["", Validators.required],
      TaxRate: [0]
    });
    this.subscription.add(this.foldersummary.GetAutoCodeFlagforCustomer().subscribe(data => {
      if (data != null && data != undefined) {
        this.IsAutocode = data["ResponseData"];
      }
      else {
        this.IsAutocode = false;
      }
      if (this.IsAutocode === false) {
        this.Productdetailsform.get('UserCode').setValidators([Validators.required]);
        this.Productdetailsform.get('UserCode').updateValueAndValidity();
        //this.supplierdetailsform.get('PRTYUSRCODE').disable();
      }
      else {
        this.Productdetailsform.get('UserCode').clearValidators();
        this.Productdetailsform.get('UserCode').updateValueAndValidity();
        this.isReadOnly = true;
      }
    }));
    this.subscription.add(this.lookupApi.GetAllLookup('SALESACCOUNT').subscribe(
      (data) => {

        this.salesAccountList = data["ResponseData"];

        // this.api.GetDefaultLanguage().subscribe(data => {
        //   this.salesAccountSelectedItem=this.salesAccountList.find(item => item.UsrCode ==data.ResponseData.Code);
        //   this.Productdetailsform.controls.SALES.setValue(this.salesAccountSelectedItem.UsrCode);
        //   });
      }));

    this.subscription.add(this.lookupApi.GetAllLookup('PURCHASEORCOMMISSION').subscribe(
      (data) => {

        this.purchaseAccountList = data["ResponseData"];
      }));
    this.subscription.add(this.lookupApi.GetAllLookup('GROUPNAME').subscribe(
      (data) => {

        this.groupNameList = data["ResponseData"];
      }));
    this.subscription.add(this.lookupApi.GetAllLookup('TAX').subscribe(
      (data) => {

        this.taxList = data["ResponseData"];
      }));

  }

  TaxChange(value) {
    if (value != null && value != undefined) {
      this.Productdetailsform.controls.TaxNo.setValue(value.Code);
      this.Productdetailsform.controls.TaxRate.setValue(value.UsrCode);
    } else {
      this.Productdetailsform.controls.TaxNo.setValue("");
      this.Productdetailsform.controls.TaxRate.setValue(0);
    }
  }
  groupNameValueChange(value) {
    if (value != null && value != undefined) {
      this.Productdetailsform.controls.GrpCode.setValue(value.Code);
    } else {
      this.Productdetailsform.controls.GrpCode.setValue(0);
    }
  }
  salesAccountValueChange(value) {
    if (value != null && value != undefined) {
      this.Productdetailsform.controls.SALES.setValue(value.Code);
    } else {
      this.Productdetailsform.controls.SALES.setValue("");
    }
  }
  purchaseAccountValueChange(value) {
    if (value != null && value != undefined) {
      this.Productdetailsform.controls.PURCHASE.setValue(value.Code);
    } else {
      this.Productdetailsform.controls.PURCHASE.setValue("");
    }
  }
  commissionAccountValueChange(value) {
    if (value != null && value != undefined) {
      this.Productdetailsform.controls.COMMISSION.setValue(value.Code);
    } else {
      this.Productdetailsform.controls.COMMISSION.setValue("");
    }
  }

  get f() { return this.Productdetailsform.controls; }

  onFormSubmit() {
    this.submitted = true;
    if (this.Productdetailsform.invalid) {
      return;
    }
    // if(this.IsAutocode){

    // }
    this.subscription.add(this.product.addProduct(this.Productdetailsform.value).subscribe(res => {
      var data = res['ResponseData'];
      if (data != null && data != undefined) {
        if (data > 0) {
          this.othersdetail.closeProduct(false);
          this.othersdetail.OthersEditForm.controls.CODE.setValue(res['ResponseData']);
          this.othersdetail.loadProduct();
        } else {
          this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {

            this.showMsg = translations['Messages.Failed'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              positionClass: 'toast-top-center',
              closeButton: true
            });
          }));
        }
      }
    }));
    this.submitted = false;
  }

  close() {
    this.othersdetail.closeProduct(false);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
