import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { LookUpDetails } from "../../Services/look-up-details";
import { Router, ActivatedRoute } from "@angular/router";
import { LookUpDetailsService } from "../../Services/look-up-details.service";
import { FolderSummaryService } from "../../Services/folder-summary.service";
import { ShareDataService } from "../../Services/share-data.service";
import { CurrencyExchangerateService } from "../../Services/currency-exchangerate.service";
import { CurrencyExchangerate } from "../../Models/currency-exchangerate";
import { PaymentService } from "../../Services/payment.service";
import { CustomerPostResult } from "../../Models/customer-post-result";
import {
  AddEvent,
  EditEvent,
  GridComponent
} from "@progress/kendo-angular-grid";
import { groupBy, GroupDescriptor } from "@progress/kendo-data-query";
import { Component, OnInit, ViewChild, Renderer2, Input } from "@angular/core";
import { Paymentpost } from "../../Models/paymentpost";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { CustomerPaymentResponse } from "../../Models/customer-payment-response";
import { FolderPaymentPostGridParameter } from "../../Models/folder-payment-post-grid-parameter";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { CommonserviceService } from "../../Services/commonservice.service";
import { TranslateService } from "@ngx-translate/core";
import { IntlService } from "@progress/kendo-angular-intl";
//import { FlashMessagesService } from 'angular2-flash-messages';
import { BankPaymentDTO } from '../../Models/bank-payment-dto.model';
import { FileService } from "../../Services/file.service";
const createFormGroup = dataItem =>
  new FormGroup({
    SUPINVNO: new FormControl(dataItem.SUPINVNO),
    VRDATE: new FormControl(dataItem.VRDATE),
    PROJNO: new FormControl(dataItem.PROJNO),
    ASSIGN: new FormControl(dataItem.ASSIGN),
    FC_AMOUNT: new FormControl(dataItem.FC_AMOUNT),
    FC_BALANCE: new FormControl(dataItem.FC_BALANCE),
    FCPayment: new FormControl(dataItem.FCPayment),
    Reference: new FormControl(dataItem.Reference),
    Payments: new FormControl(dataItem.Payments),
    AMOUNT: new FormControl(dataItem.AMOUNT),
    HCBALANCE: new FormControl(dataItem.HCBALANCE),
    VRTYPE: new FormControl(dataItem.VRTYPE),
    YEARID: new FormControl(dataItem.YEARID),
    CUSTOMER: new FormControl(dataItem.CUSTOMER),
    CUSTOMERBALANCE: new FormControl(dataItem.CUSTOMERBALANCE)
  });
const matches = (el, selector) =>
  (el.matches || el.msMatchesSelector).call(el, selector);
const hasClass = (el, className) => new RegExp(className).test(el.className);

const isChildOf = (el, className) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};
@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"]
})
export class PaymentComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  CustomerPaymentForm: FormGroup;
  currencyExrate: CurrencyExchangerate;
  @Input() folderSummary:any;
  //CustomerReceipt:CustomerReceiptResponse;
  public createFormGroup: FormGroup;
  public groups: GroupDescriptor[] = [];
  public view: any[];
  public IsSameCurrency:any=false;
  stringifiedData: any[];
  @ViewChild(GridComponent) private grid: GridComponent;
  private editedRowIndex: number;
  private editedcolIndex: number;
  FCAmalaneSum:any=0;
  FCBalanceSum:any=0;
  HCBalanceSum:any=0;
  public TotBalance: 0;
  private isNew = false;
  public gridData: any;
  public TotalRows: number = 0;
  folderEditMode: any;
  Paymentpost: Paymentpost = new Paymentpost();
  CustomerPostResult: CustomerPostResult;
  paymentpostgrid: FolderPaymentPostGridParameter[];
  paymentPostGridParameter: FolderPaymentPostGridParameter = new FolderPaymentPostGridParameter();
  private docClickSubscription: any;
  submitted = false;
  ExRate:any=0;
  Language:any='EN'
  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public groupChange(groups: GroupDescriptor[]): void {
    this.groups = groups;
    this.view = groupBy(this.view, this.groups);
    // alert(this.view);
  }
  public statusList: Array<string> = [
    "Cheque",
    "Bacs",
    "Bank Transfer",
    "Others",
    "Direct Debit",
    "Debit Card"
  ];
  public showMsg: any; //333
  date: any;
  public Componentname: string;
  constructor(
    public intl: IntlService,
    public common: CommonserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private api: FolderSummaryService,
    private lookupApi: LookUpDetailsService,
    private apiShared: ShareDataService,
    private apiExRate: CurrencyExchangerateService,
    private apiPayment: PaymentService,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private translateapi: TranslateService,
    public file:FileService
  ) {}
  private orderno = this.route.snapshot.params["orderNo"];
  public type = this.route.snapshot.params["type"];
  branchList: Array<LookUpDetails["ResponseData"]> = [];
  public branchSelectedItem: LookUpDetails["ResponseData"];
  public branchSelected: String;
  public Days: number;
  CustomerList: Array<LookUpDetails["ResponseData"]> = [];
  public customerSelectedItem: LookUpDetails["ResponseData"];
  public customerSelected: string;
  Currency: Array<LookUpDetails["ResponseData"]> = [];
  public CurrencyselectedItem: LookUpDetails["ResponseData"];
  balanceAmount:any=0.00;
//{{intl.formatNumber(balance, common.DecimalCount)}}
  ngOnInit() {
    this.folderEditMode = "false";
    this.file.ValidChequeDate=false;
    this.file.chequeNovaild=false;
    this.file.PaymentReference=false;
    //this.apiShared.folderEditMode=this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, "folderEditMode");
    this.CustomerPaymentForm = this.formBuilder.group({
      PRTYCODE: ["", Validators.required],
      DocCurrencyAmount: [0],
      HCAmount: [0],
      CURExRate: [""],
      Reference: [""],
      FCAllocated: [0],
      FCUnAllocated: [0],
      HCAllocated: [0],
      HCUnAllocated: [0],
      FOLDERNO: [this.orderno],
      PostDate: [new Date()],
      BankPayMethod: ["Bank Transfer"],
      BankCode: [""],
      DrawnbankName: ["test"],
      ChequeNo: [""],
      ChequeDate: [new Date()],
      PaymentReference: [""]
    });

    this.getFolderValues();
    this.getPaymentDetails(1, 1, this.orderno, "2019", this.fldrCurrency);
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        
        this.saveClick();
      }
    });
    this.subscription.add(
      this.lookupApi.GetAllLookup("BRANCH").subscribe(data => {
        if (data != null && data != undefined) {
          this.branchList = data["ResponseData"];
          if (this.BRID != "") {
            this.branchSelectedItem = this.branchList.find(
              item => item.Code == this.BRID
            );
          }
        }
      })
    );
    this.subscription.add(this.lookupApi.GetAllLookup('LANGUAGES').subscribe(
      (data) => {
        
        if (data != null && data != undefined) {
          if (data["ResponseData"] != null && data["ResponseData"] != undefined) {
            if (data["ResponseData"].length > 0) {
              this.Language=data["ResponseData"][0].Code;
            }}}

      }))
    this.subscription.add(
      this.lookupApi.GetAllLookup("FilePriceDetails").subscribe(data => {
        
        if (data != null && data != undefined) {
          var ComapnyCurrencydata= data["ResponseData"][0];
          if (ComapnyCurrencydata !=undefined) {
            if(ComapnyCurrencydata.UsrCode==this.fldrCurrency)
            {
              this.balanceAmount=this.common.balance;
              if(Number.isNaN(this.balanceAmount)||this.balanceAmount==undefined ||this.balanceAmount==null)
              {
                this.balanceAmount=null;
                this.IsSameCurrency=false;
              }
              else
              {
              this.IsSameCurrency=true;
              }
            }
          
          }
        }
      })
    );
    this.subscription.add(
      this.lookupApi.GetAllLookup("CUSTOMER").subscribe(data => {
        if (data != null && data != undefined) {
          this.CustomerList = data["ResponseData"];

          if (this.CustomerPaymentForm.controls.PRTYCODE.value != "") {
            this.customerSelectedItem = this.CustomerList.find(
              item =>
                item.Code == this.CustomerPaymentForm.controls.PRTYCODE.value
            );
            this.customerSelected = this.customerSelectedItem.Name.toString();
          }
        }
        this.getFolderDefaultCustomer();
      })
    );

    this.subscription.add(
      this.lookupApi.GetAllLookup("CURRENCY").subscribe(data => {
        if (data != null && data != undefined) {
          this.Currency = data["ResponseData"];
          if (this.fldrCurrency != "") {
            this.CurrencyselectedItem = this.Currency.find(
              item => item.Code == this.fldrCurrency
            );
            this.getCurrencyExRate();
          }
        }
      })
    );
    this.Componentname = "Payment";
    // this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654
    sessionStorage.setItem("Componentname", this.Componentname);
    this.banktoggleShow();
  }

  getFolderDefaultCustomer() {
    //this.apiShared.CustomerCode.subscribe(CustomerCode => this.sharedCustCode=CustomerCode)
    //this.sharedCustCode=this.apiShared.CustomerCode;
    this.sharedCustCode = this.apiShared.CustomerCode;
    if (this.sharedCustCode != "") {
      this.customerSelectedItem = this.CustomerList.find(
        item => item.Code == this.sharedCustCode
      );
      if (this.customerSelectedItem != null) {
        this.CustomerPaymentForm.controls.PRTYCODE.setValue(
          this.customerSelectedItem.Code.toString()
        );
        this.customerSelected = this.customerSelectedItem.Name.toString();
      } else {
        this.customerSelected = "";
        this.CustomerPaymentForm.controls.PRTYCODE.setValue("");
      }
    }
  }
  public BRID: any;
  public UID: string;
  public fldrCurrency: any;
  public sharedCustCode: any;
  //public PostDate:Date=new Date();
  //public CURExRate:number;
  public Reference: string = "";
  public disabled: boolean = true;
  getFolderValues() {
    // alert(this.PostDate);
    //this.apiShared.folderCurrency.subscribe(fldrCurrency => this.fldrCurrency = fldrCurrency)
    this.fldrCurrency = this.apiShared.folderCurrency;
   // this.BRID = this.apiShared.BRID;
    this.BRID = sessionStorage.getItem('LUBRID')

    //this.apiShared.BRID.subscribe(BRID => this.BRID = BRID)
    this.getExchangeRate(this.fldrCurrency, new Date());
    //this.apiShared.uid.subscribe(UID => this.UID = UID)
  }

  getPaymentDetails(
    id: number,
    userid: number,
    folderno: string,
    yearid: string,
    FldCurr: string
  ) {
    this.subscription.add(
      this.apiPayment
        .GetCustomerPaymentDetails(id, userid, folderno, yearid, FldCurr, 0)
        .subscribe(res => {
          this.gridData = res["ResponseData"];
          if (res != null && res != undefined) {
            if (
              JSON.stringify(res["ResponseData"]) == "null" ||
              JSON.stringify(res["ResponseData"]) == "[]"
            ) {
              this.TotalRows = 0;
            } else {
              this.view = this.gridData;
              this.TotalRows = this.view.length;
              //this.paymentpostgrid=new PaymentPostGridParameter[this.TotalRows];
              this.paymentpostgrid = new Array<FolderPaymentPostGridParameter>(
                this.TotalRows
              );
              //alert(JSON.stringify(this.paymentpostgrid));
            }
          }
        })
    );
  }

  public BranchValueChange(value: any): void {
    //console.log('selectionChange', value);
    if(value!=undefined)
    {
      this.BRID = value.Code;
    }
    else
    {
      this.BRID='';
    }
  }

  public tabtitle: string = "Cash Payment";
  isShown: boolean = false;
  bankloaded: boolean = false;
  isBankShown: boolean = false;
  public cashtoggleShow() {
    this.isShown = false;
    this.isBankShown = false;
    this.bankloaded = false;
    this.tabtitle = "Cash Payment";
  }
  public banktoggleShow() {
    this.isBankShown = true;
    this.isShown = false;
    this.bankloaded = true;
    this.CustomerPaymentForm.controls.BankPayMethod.setValue('Bank Transfer');
    this.tabtitle = "Bank Payment";
  }
  public pdqtoggleShow() {
    this.isBankShown = false;
    this.isShown = true;
    this.bankloaded = false;
    this.tabtitle = "PDQ/MOTO Payment";
  }
  public CustomerValueChange(value: any): void {
    this.customerSelectedItem = this.CustomerList.find(
      item => item.Name == value
    );
    if (this.customerSelectedItem != null) {
      this.CustomerPaymentForm.controls.PRTYCODE.setValue(
        this.customerSelectedItem.Code.toString()
      );
    } else {
      this.customerSelected = "";
      this.CustomerPaymentForm.controls.PRTYCODE.setValue("");
    }
  }
  public CurrencyChange(value: any): void {
    this.fldrCurrency = value.Code;
    this.getCurrencyExRate();
  }
  public getCurrencyExRate() {
    this.getExchangeRate(this.fldrCurrency, new Date());
  }

  getExchangeRate(currency, postingdate) {
    // postingdate= new Date(postingdate).toLocaleDateString('en-GB', {
    //   day: 'numeric', month: 'short', year: 'numeric'
    // }).replace(/ /g, '-');
    postingdate = this.common.transformDate(new Date(postingdate));
    this.subscription.add(
      this.apiExRate.getExchangeRate(currency, postingdate).subscribe(data => {
        this.currencyExrate = data;
        this.CustomerPaymentForm.controls.CURExRate.setValue(
          this.currencyExrate.ResponseData.EXRATE
        );
        this.ExRate= this.CustomerPaymentForm.controls.CURExRate.value;
      })
    );
  }

  public addHandler({ sender }: AddEvent): void {
    
    this.closeEditor(sender);

    this.createFormGroup = createFormGroup({
      SUPINVNO: 0,
      VRDATE: "",
      ORDNO: "",
      ASSIGN: "",
      AMOUNT: 0,
      HCBALANCE: 0,
      FC_AMOUNT: 0,
      FC_BALANCE: 0,
      VRTYPE: 0,
      YEARID: "",
      AGAINST: 0,
      PROJNO: "",
      BRID: "",
      Reference: "",
      PB_NO: "",
      CUSTOMERBALANCE: 0,
      CUSTOMER: "",
      Payments: 0,
      FCPayment: 0
    });
    this.isNew = true;

    this.grid.addRow(this.createFormGroup);
  }
  public cancelHandler(sender): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  public editClick({ dataItem, rowIndex, columnIndex }: any): void {
    
    // this.editHandler({
    //   dataItem: dataItem,
    //   rowIndex: rowIndex,
    //   colIndex: columnIndex,
    //   sender: this.grid
    // });
    this.editHandler(
      dataItem,
      rowIndex,
      columnIndex,
       this.grid
    );
  }

  public saveClick(): void {
    
    if (this.createFormGroup && !this.createFormGroup.valid) {
      return;
    }

    this.saveRow(0);
  }
  public editHandler( sender,rowIndex, colIndex,  sendn ): void {
    
    if (this.createFormGroup && !this.createFormGroup.valid) {
      return;
    }
    if((colIndex== 5 )&& (sender.FC_BALANCE!=null &&sender.FC_BALANCE!=undefined))
    {
      sender.FCPayment=sender.FC_BALANCE;
    }
    if(rowIndex!=undefined)
    {
    this.view.forEach((obj) => {
      
      
      var selectedRowIndex = this.view.indexOf(obj);
      if(selectedRowIndex!=rowIndex)
      sendn.closeRow(selectedRowIndex);
    });
  }
    this.createFormGroup = createFormGroup(sender);
    this.editedRowIndex = rowIndex;
    this.saveRow(this.editedRowIndex);
   // this.createFormGroup = createFormGroup(dataItem);

   
    this.editedcolIndex = colIndex;
    this.createFormGroup = createFormGroup(sender);
    sendn.editRow(rowIndex, this.createFormGroup);
    // setTimeout(() => {
    //   (document.querySelector(`.k-grid-edit-row > td:nth-child(${colIndex + 1}) input`) as HTMLElement)
    //     .focus();
    // });
  }
  public editHandler1( sender, colIndex, rowIndex, sendn : any): void {
    if (this.createFormGroup && !this.createFormGroup.valid) {
      return;
    }
    if((colIndex== 5 )&& (sender.FC_BALANCE!=null &&sender.FC_BALANCE!=undefined))
    {
      sender.FCPayment=sender.FC_BALANCE;
    }
   
    this.createFormGroup = createFormGroup(sender);
    this.editedRowIndex = rowIndex;
    this.editedcolIndex = colIndex;
    this.saveRow(this.editedcolIndex);

   this.createFormGroup = createFormGroup(sender);

   
    // alert(this.createFormGroup);
    sendn.editRow(rowIndex, this.createFormGroup);
    setTimeout(() => {
      (<HTMLElement>(
        document.querySelector(
          `.k-grid-edit-row > td:nth-child(${colIndex + 1}) input`
        )
      )).focus();
    });
  }
  gridReceiptSum: number = 0;
  private closeEditor1(grid: GridComponent, rowIndex: number = this.editedRowIndex): void {
    this.gridReceiptSum = 0;
    if (this.editedRowIndex !== undefined) {
      if (grid.data[rowIndex] != null) {
        this.view[0].FCReceipt = parseFloat(this.view[0].FCReceipt.toString()).toFixed(2)

        //this.intl.formatNumber(  this.view[0].FCReceipt, this.commonservice.DecimalCount)
        this.CalculateForm();



      }
    }
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.createFormGroup = undefined;
  }
  private closeEditor(
    grid: any,
    rowIndex: number = this.editedRowIndex
  ): void {
    this.gridReceiptSum = 0;
    if (this.editedRowIndex !== undefined) {
      if (grid.data[rowIndex] != null) {
        this.CalculateForm();

        //   // grid.data[rowIndex].UnitPrice=grid.data[rowIndex].UnitPrice*grid.data[rowIndex].UnitsInStock;

        //  // alert(JSON.stringify(grid.data));

        // this.stringifiedData=grid.data as any[];
        // //alert(JSON.stringify(this.stringifiedData[rowIndex].Receipt));
        //    //let sum = 0;
        //    for (var i = 0; i < this.stringifiedData.length; i++) {
        //     this.gridReceiptSum= (this.gridReceiptSum + Number(this.stringifiedData[i].FCReceipt));
        //    alert( this.stringifiedData[i].FCReceipt);
        //    alert(this.gridReceiptSum + Number(this.stringifiedData[i].FCReceipt));
        //}
      }
    }
    else
    {this.CalculateForm();}
    
    this.isNew = false;
    if(rowIndex==undefined && this.view!=undefined )
    {
      
      this.view.forEach((obj) => {
        
        
        var selectedRowIndex = this.view.indexOf(obj);
       
        grid.closeRow(selectedRowIndex);
      });
    }
    else if(grid!=undefined)
    {

      grid.closeRow(rowIndex);
    }
   
    
    this.editedRowIndex = undefined;
    this.createFormGroup = undefined;
  }

  private saveRow(rowinedex:any): void {
    
    
   // if (this.isInEditingMode) {
     if(this.createFormGroup!=undefined&&this.createFormGroup.value!=undefined)
     {
      this.save(this.createFormGroup.value, false);
     }
      
    //}
    
    // this.grid.data[rowinedex].FCPayment=+this.createFormGroup.value.FCPayment;
    // this.grid.data= this.grid.data;
    this.closeEditor(this.grid,rowinedex);
  }
  public GetCurrencyExchangeRateTolerance()
  {
    
    this.spinner.show();
    this.common.excformData = new BankPaymentDTO();
    
   
    this.common.excformData.PVRDATE = this.common.ConvertToLocaleDate(this.CustomerPaymentForm.controls.PostDate.value);
    this.common.excformData.date = this.common.ConvertToLocaleDate(new Date())
   
    this.common.excformData.HCID = this.fldrCurrency;
    this.common.excformData.FCID = this.fldrCurrency;
    this.common.excformData.RXRATE = this.CustomerPaymentForm.controls.CURExRate.value;

    this.common.excformData.LANQUAGE = this.Language;
    this.subscription.add( this.common.GetCurrencyExchangeRateTolerance().subscribe(x => {
      this.spinner.hide();
      if (x != null && x != undefined) {
        this.spinner.hide();
        
        if (x["ResponseData"].messageid== 0) {
        //  this.ExRate =this.ExRate ;
         // this.CalculateAll();
         this.CalculateForm();
        }
        else if (x["ResponseData"].messageid> 0 ) {
          
          var msg = 'Messages.msg' + x["ResponseData"].messageid;
          this.common.showWARNINGtoastrmsg(x["ResponseData"].message, 3000, true, false);
         this.CustomerPaymentForm.controls.CURExRate.setValue(this.ExRate);
         this.CalculateForm();
        }
      }
    })
    )
  }
  public save(formreceipt: any, isNew: boolean): void {
    // alert(JSON.stringify(formreceipt));
    //  alert(this.TotalRows);
    if (this.TotalRows > 0) {
      {
        Object.assign(
          this.view.find(({ SUPINVNO }) => SUPINVNO === formreceipt.SUPINVNO),
          formreceipt
        );
      }
    }
  }

  public TC_Allocated: number = 0;
  public TC_UnAllocated: number = 0;
  public HC_Allocated: number = 0;
  public HC_UnAllocated: number = 0;
  public gridfcreceiptsum: number = 0;
  public gridhcreceiptsum: number = 0;
  public confirmMsg: string = "";
  isVisible:any;
  AdvancePay: boolean = false;
  public CalculateForm() {
    debugger;
    this.CustomerPaymentForm.controls.HCAmount.setValue(
      this.CustomerPaymentForm.get("DocCurrencyAmount").value *
        this.CustomerPaymentForm.get("CURExRate").value
    );
    if (this.TotalRows >0) {
      // if (
      //   this.view[0].FCPayment == "" ||
      //   this.view[0].FCPayment == 0 ||
      //   this.view[0].FCPayment == 0.0
      // )
        this.view[0].FCPayment = this.CustomerPaymentForm.get(
          "DocCurrencyAmount"
        ).value;
      this.view[0].Payments =
        this.view[0].FCPayment *
        this.CustomerPaymentForm.get("CURExRate").value;
    }
    if (this.TotalRows > 0) {
      this.stringifiedData = this.grid.data as any[];
      let fcsum = 0;
      let hcsum = 0;
      for (var i = 0; i < this.stringifiedData.length; i++) {
        fcsum += +this.stringifiedData[i].FCPayment;
        hcsum += +this.stringifiedData[i].Payments;
      }
      this.gridfcreceiptsum = fcsum;
      this.gridhcreceiptsum = hcsum;
    } else {
      this.gridfcreceiptsum = 0;
      this.gridhcreceiptsum = 0;
    }

    this.TC_UnAllocated =
      this.CustomerPaymentForm.get("DocCurrencyAmount").value -
      this.gridfcreceiptsum;
    //alert(this.gridhcreceiptsum);
    this.TC_Allocated = this.gridfcreceiptsum;
    this.HC_UnAllocated =
      this.CustomerPaymentForm.get("HCAmount").value - this.gridhcreceiptsum;
    this.HC_Allocated = this.gridhcreceiptsum;
    this.CustomerPaymentForm.controls.FCAllocated.setValue(this.TC_Allocated);
    this.CustomerPaymentForm.controls.FCUnAllocated.setValue(
      this.TC_UnAllocated
    );
    this.CustomerPaymentForm.controls.HCAllocated.setValue(this.HC_Allocated);
    this.CustomerPaymentForm.controls.HCUnAllocated.setValue(
      this.HC_UnAllocated
    );
  }
  public Transaction: string;
  public ExchangeGainLossFlag: number = 0;
  public BankPayMethod: number = 0;
  public getBankpaymentmethod() {
    if (this.CustomerPaymentForm.get("BankPayMethod").value == "Cheque")
      this.BankPayMethod = 0;
    else if (this.CustomerPaymentForm.get("BankPayMethod").value == "Bacs")
      this.BankPayMethod = 1;
    else if (
      this.CustomerPaymentForm.get("BankPayMethod").value == "Bank Transfer"
    )
      this.BankPayMethod = 2;
    else if (this.CustomerPaymentForm.get("BankPayMethod").value == "Others")
      this.BankPayMethod = 3;
    else if (
      this.CustomerPaymentForm.get("BankPayMethod").value == "Direct Debit"
    )
      this.BankPayMethod = 4;
    else if (
      this.CustomerPaymentForm.get("BankPayMethod").value == "Debit Card"
    )
      this.BankPayMethod = 5;
    else this.BankPayMethod = 2;
  }
public   ValidateCheque(): boolean 
{
  
  this.file.chequeNovaild=false;
  this.file.PaymentReference=false;
 var paymntMethod= this.CustomerPaymentForm.controls.BankPayMethod.value;
  if(paymntMethod=='Cheque')
  {
    if(this.CustomerPaymentForm.controls.ChequeNo.value==undefined||this.CustomerPaymentForm.controls.ChequeNo.value==null||this.CustomerPaymentForm.controls.ChequeNo.value=='')
    {
     this.file.chequeNovaild=true;
    }

    if(this.CustomerPaymentForm.controls.PaymentReference.value==undefined||this.CustomerPaymentForm.controls.PaymentReference.value==null||this.CustomerPaymentForm.controls.PaymentReference.value=='')
    {
     this.file.PaymentReference=true;
    }
    var chequedate = (this.CustomerPaymentForm.controls.ChequeDate.value == null || this.CustomerPaymentForm.controls.ChequeDate.value == "" ? "01-Jan-1900" : this.CustomerPaymentForm.controls.ChequeDate.value);//Case id-337

   
    if (Boolean(this.common.ValidDateRange(chequedate)) == false ||chequedate=='01-Jan-1900')//Case id-337
    {
     this.isShown = false;
    // this.postButtonDisable = false;
      this.file.ValidChequeDate=true;
   
      
    }
    else{
      this.file.ValidChequeDate=false;
    }
 if(this.file.ValidChequeDate==true|| this.file.chequeNovaild==true||this.file.PaymentReference==true)
 {
   this.isShown = false;
 return false;
 }
 else
{
  this.file.ValidChequeDate=false;
  this.file.chequeNovaild=false;
  this.file.PaymentReference=false;
  return true;;
}
}
else if(paymntMethod=='Bacs')
{
  var chequedate = (this.CustomerPaymentForm.controls.ChequeDate.value == null || this.CustomerPaymentForm.controls.ChequeDate.value == "" ? "01-Jan-1900" : this.CustomerPaymentForm.controls.ChequeDate.value);//Case id-337

   
  if (Boolean(this.common.ValidDateRange(chequedate)) == false ||chequedate=='01-Jan-1900')//Case id-337
  {
   this.isShown = false;
  // this.postButtonDisable = false;
    this.file.ValidChequeDate=true;
 
    
  }
  else{
    this.file.ValidChequeDate=false;
  }
if(this.file.ValidChequeDate==true)
{
 this.isShown = false;
return false;
}
else
{
this.file.ValidChequeDate=false;

return true;;
}
}
}
 public SaveOrCheckUnallocatedOrNot()
 {
   
   
   this.file.chequeNovaild=false;
    this.file.PaymentReference=false;
    if(this.tabtitle=='Bank Payment')
    {
   var paymntMethod= this.CustomerPaymentForm.controls.BankPayMethod.value;
   if(this.CustomerPaymentForm.controls.BankCode.value==''||this.CustomerPaymentForm.controls.BankCode.value==undefined||this.CustomerPaymentForm.controls.BankCode.value==null)
  {
    this.file.ValidBankcode=true;
    return false;
  }
  else
  {
    this.file.ValidBankcode=false;
  }
     if(this.ValidateCheque()==false)
     { 
      return false;
    
  }
}
  if (this.CustomerPaymentForm.get("FCUnAllocated").value >0)  {
    this.AdvancePay = true;
   // this.postButtonDisable = false;

    return false;
  }
  else
  {
    this.onPost();
  }
 }
  public async onPost() {
    if (Boolean(await this.common.checkPermission1('02.07.05')) == true) {
        this.spinner.show();
    this.submitted = true;
    this.date =
      this.CustomerPaymentForm.controls.PostDate.value == null ||
      this.CustomerPaymentForm.controls.PostDate.value == ""
        ? "01-Jan-1900"
        : this.CustomerPaymentForm.controls.PostDate.value; //Case id-337

    if (Boolean(this.common.ValidDateRange(this.date)) == false) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidDate"];
            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
      this.spinner.hide();
      return;
    }
    if (this.CustomerPaymentForm.get('DocCurrencyAmount').value==0) {
      this.subscription.add(this.translateapi.get(['Messages.customerReceiptPostValidationMsg1']).subscribe((translations) => {
        this.showMsg = translations['Messages.customerReceiptPostValidationMsg1'];
        
        this.common.showWARNINGtoastrmsg(this.showMsg, 3000, true, false);
      }));
      this.spinner.hide();
      
      return;
  
    }
    if (this.CustomerPaymentForm.invalid) {
      this.spinner.hide();
      return;
    }
   

    this.Paymentpost.Id = 1;
    this.Paymentpost.CustCode = this.CustomerPaymentForm.get("PRTYCODE").value;
    this.Paymentpost.FolderNo = this.orderno;

    this.Paymentpost.HCAmount = this.common.ConvertToFixed2(this.CustomerPaymentForm.get("HCAmount").value); 
    this.Paymentpost.ForeignCurrencAmt =this.common.ConvertToFixed2( this.CustomerPaymentForm.get(
      "DocCurrencyAmount"
    ).value);

    this.Paymentpost.AllocatedAmount =this.common.ConvertToFixed2( this.CustomerPaymentForm.get(
      "HCAllocated"
    ).value);

    this.Paymentpost.Reference = this.CustomerPaymentForm.get(
      "Reference"
    ).value;

    if (this.tabtitle == "Cash Payment")
      this.Paymentpost.TransactionType = "Cash";
    else this.Paymentpost.TransactionType = "Bank";

    this.Paymentpost.PostDate = this.common.ConvertToLocaleDate(
      this.CustomerPaymentForm.get("PostDate").value
    );
    //  new Date(this.CustomerPaymentForm.get('PostDate').value).toLocaleDateString('en-GB', {
    //   day: 'numeric', month: 'short', year: 'numeric'
    // }).replace(/ /g, '-');
    // this.CustomerPaymentForm.get('PostDate').value;
    this.Paymentpost.BRID = this.BRID;
    this.Paymentpost.UID = 1;
    this.Paymentpost.FCURID = this.fldrCurrency;
    this.Paymentpost.ExRate = this.CustomerPaymentForm.get("CURExRate").value;
    this.Paymentpost.ExchangeGainLossFlag = 0;
    if (this.bankloaded == true) {
      this.getBankpaymentmethod();

      this.Paymentpost.BankPayMethod = this.BankPayMethod;
      this.Paymentpost.BankCode =
        this.CustomerPaymentForm.get("BankCode").value == ""
          ? 0
          : this.CustomerPaymentForm.get("BankCode").value;
      this.Paymentpost.ChequeDate = this.common.ConvertToLocaleDate(
        this.CustomerPaymentForm.get("ChequeDate").value
      );
      // this.CustomerPaymentForm.get("ChequeDate")
      //   .value.toLocaleDateString("en-GB", {
      //     day: "numeric",
      //     month: "short",
      //     year: "numeric"
      //   })
      //   .replace(/ /g, "-");
      this.Paymentpost.ChequeNo = this.CustomerPaymentForm.get(
        "ChequeNo"
      ).value;
      this.Paymentpost.DrawnbankName = this.CustomerPaymentForm.get(
        "PaymentReference"
      ).value;
     
    }
    // alert(this.TotalRows);
    if (this.TotalRows > 0) {
      for (var i = 0; i < this.TotalRows; i++) {
        this.FCAmalaneSum=0;
        this.FCBalanceSum=0;
        this.HCBalanceSum=0;
          this.FCAmalaneSum=this.FCAmalaneSum+this.view[i].FCPayment;
         this.FCBalanceSum=this.FCBalanceSum+this.view[i].FC_BALANCE;
         this.HCBalanceSum=this.HCBalanceSum+this.view[i].HCBALANCE;
         if (this.view[i].FCPayment != 0) {
          if (+this.view[i].FCPayment != this.view[i].FC_BALANCE)
            this.TotBalance =  this.TotBalance+this.view[i].FCPayment;
          else
            this.TotBalance = this.TotBalance+ this.view[i].HCBALANCE
        }
        this.paymentpostgrid[i] = this.paymentPostGridParameter;
        this.paymentpostgrid[i].Assign = this.view[i].ASSIGN;
        this.paymentpostgrid[i].PaymentAmount = this.view[i].FCPayment;
        this.paymentpostgrid[i].AVRNO = this.view[i].AGAINST;
        this.paymentpostgrid[i].AYearId = this.view[i].YEARID;
        this.paymentpostgrid[i].AVRTYPE = this.view[i].VRTYPE;
        this.paymentpostgrid[i].HCBalance = this.view[i].HCBALANCE;
      }
      // console.log(JSON.stringify( this.paymentpostgrid));
      this.Paymentpost.FolderPaymentPostGridParameter = this.paymentpostgrid;
      this.Paymentpost.HCPayBalance=this.HCBalanceSum;
      this.Paymentpost.HCBalance=this.HCBalanceSum;
    }
    if(this.FCAmalaneSum==this.FCBalanceSum)
    {
      this.ExchangeGainLossFlag = 1;
     // this.Paymentpost.HCAmount=this.HCBalanceSum;
    }
    else
    {
     this.ExchangeGainLossFlag = 0;  
    }
  
    this.Paymentpost.ExchangeGainLossFlag=this.ExchangeGainLossFlag;
    // this.Paymentpost.ExchangeGainLossFlag=0;
    // console.log(JSON.stringify( this.Paymentpost));

    this.subscription.add(
      this.apiPayment.PostCustomerPaymentDetails(this.Paymentpost).subscribe(
        res => {
          this.spinner.hide();
          this.CustomerPostResult = res;
         
          //  alert('SUCCESS3!! :-)\n\n' + JSON.stringify(this.CustomerPostResult["ResponseData"], null, 4));
          // this.flashMessage.show(this.CustomerPostResult["ResponseData"].Message, { cssClass: 'text-white bg-dark', timeout: 2000 });
          if (this.CustomerPostResult["ResponseData"].VRNO > 0) {
          this.confirmMsg = this.CustomerPostResult["ResponseData"].Message;
          this.ShowConfMsg = true;
         
            this.gridData = null;
            this.view = this.gridData;
          }
          else
          {
       
            this.common.showWARNINGtoastrmsg(this.CustomerPostResult["ResponseData"].Message, 5000, true, false);

          }
        },
        err => {
          this.spinner.hide();
          console.log(err);
          alert(
            "Failed!! :-)\n\n" +
              err +
              "\n\n" +
              JSON.stringify(this.CustomerPostResult.Message, null, 4)
          );
        }
      )
    );
      }
      else
      {
        this.ShowPermissionMessageForm('02.07.05');//339
      }
   
  }
  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339
  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }
  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }
  onReset() {
    this.submitted = false;
    this.CustomerPaymentForm.reset();
  }
  AdvancePayCheck(AdvancePay: boolean): void {
    if (AdvancePay == true) {
   
      this.onPost();
      this.AdvancePay = null;
    } else {
      this.AdvancePay = null;
    }
  }
  onCancel() {
    sessionStorage.setItem("pagevalue", "0");
    this.router.navigate(["PenAir/Folder/FolderSummary", this.orderno]);
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains"
  };
  public ShowConfMsg: boolean = false;
  public ConfirmMessage(status) {
    sessionStorage.setItem("pagevalue", "0");
    this.ShowConfMsg = false;
    this.router.navigate(["PenAir/Folder/FolderSummary", this.orderno]);
  }
  get f() {
    return this.CustomerPaymentForm.controls;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
