import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from "@angular/core";
import { Alertdetails } from "../Models/alertdetails";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertserviceService } from "../Services/alertservice.service";
import { NgxSpinnerService } from "ngx-spinner";
import { observable, Observable, Subscription } from "rxjs";
import { ShareDataService } from "../Services/share-data.service";
import { PendoclockService } from "../Services/pendoclock.service";
import { Pendoclock } from "../Models/pendoclock";
import { TranslateService } from "@ngx-translate/core";
import {
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  GridComponent
} from "@progress/kendo-angular-grid";
import {
  DataSourceRequestState,
  DataResult,
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
  State,
  SortDescriptor,
  orderBy
} from "@progress/kendo-data-query";
import { CommonserviceService } from "../Services/commonservice.service";
import { ToastrService } from "ngx-toastr";
import { PaginationServiceService } from "../Services/pagination-service.service";
import { PublicLayoutComponent } from "../public-layout/public-layout.component";
import { FolderSearchCriteriaDTO } from "../Models/folder-search-criteria-dto";
import * as XLSX from "xlsx";

import * as FileSaver from "file-saver";
import { areAllEquivalent } from "@angular/compiler/src/output/output_ast";
import * as moment from "moment";
// import { AlertlistService, CategoriesService1 } from 'src/Services/alertlist.service';
// const flatten = filter => {
//   const filters = filter.filters;
//   if (filters) {
//     return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
//   }
//   return [];
// };
@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"]
})
export class AlertComponent implements OnInit {
  @ViewChild("fileInput") fileInput;
  @ViewChild("kendoexcel") kendoexcel;
  @ViewChild("myFile") myFile;
  private subscription: Subscription = new Subscription();
  @Input() passengerNames: String;
  @Output() selectedPassenger: EventEmitter<string> = new EventEmitter();
  public passenger: any = "";
  public myFiles: string[] = [];
  public showMsg: any;
  public ConfirmWindow: any;
  public confirmMsg: any;
  public gridData: GridDataResult;
  public alertms: string;
  public passengerName: any;
  actionid: any = 0;
  public folderStatus: any;
  showID: boolean = false;
  public AlreadyPaid_int = 0;
  public AlreadyPaid = false;
  lockStatus: any;
  public formeditable = true;
  public opened: any = false;
  public folderList: any[];
  totRows: number;
  fileToUpload: File;
  Messages_no_records: any = "";
  public filter: CompositeFilterDescriptor;
  orderNo: any;
  public products: GridDataResult;
  public stateUser: State = {
    skip: 0
    //take: 10,
    // sort: [{
    //   dir: "asc",
    //   field: "userName"
    // }]
  };
  public sort: SortDescriptor[] = [
    {
      field: "ALERT_CR_DATE",
      dir: "asc"
    }
  ];
  public StatusList: any[];
  Type: any = 0;
  public ActionList: any[];
  userId: any = 0;

  private view: Observable<GridDataResult>;
  public pageSize: number = 10;
  private categoryFilter: any[] = [];
  public equals: "equals";
  public contains: "contain";
  public STATUSNAME: any = "STATUSNAME";
  public folderEditMode: any;
  public Componentname: string;
  files: File;
  //alertmsg:string;
  penDockLock: Pendoclock = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {
      LDocType: "",
      LDocNo: "",
      UID: 0,
      LDATE: "",
      LTIME: "",
      LPROJNO: "",
      LYearID: "",
      LAutoNo: "",
      Uname: "",
      Source: ""
    }
  };
  openRemider = false;
  alertID = {
    ID: "",
    Status: ""
  };
  constructor(
    public commonservice: CommonserviceService,
    private layout: PublicLayoutComponent,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    public common: CommonserviceService,
    private AlertserviceService: AlertserviceService,
    private route: ActivatedRoute,
    private router: Router,
    private apiShared: ShareDataService,
    private paginationservice: PaginationServiceService,
    private spinner: NgxSpinnerService,
    private penLock: PendoclockService,
    private translateapi: TranslateService,
    private publiclayout: PublicLayoutComponent
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.Type = params["type"] ? params["type"] : 0;
      this.userId = params["userId"];
      this.actionid = params["actionid"];
      this.Messages_no_records = "";
      this.getFolderList();
    });
    //this.service.query(this.state);
  }

  ngOnInit() {
    sessionStorage.setItem("PassengerNames", "");
    sessionStorage.setItem("FolderStatus", "");
    localStorage.removeItem("OrderType");

    this.layout.headerlanguage = "Mainmenu.Inbox";
    sessionStorage.setItem("headerlanguage", "Mainmenu.Inbox");
    // this.layout.mainmenutransilate=sessionStorage.setItem('mainmenutransilate','Alert');
    this.layout.gettransilateheadertest();
    this.orderNo = "";
    this.orderNo = this.apiShared.orderNo;
    //this.publiclayout.headerText = "Inbox";
    //this.alertlist.query(this.state);
    // this.AlertserviceService.fetch(this.state).subscribe(r => this.products = r);
    this.selectedPassenger.emit("");
    this.layout.folderstatus = "";
    //this.spinner.show();

    /** spinner ends after 5 seconds */

    this.lockStatus = this.apiShared.folderReadOnly;
    if (this.lockStatus == "0" && this.orderNo != "") {
      this.deletePenLock("Folder", this.orderNo);
    }
    this.Componentname = "Alert";
    //this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654
    sessionStorage.setItem('Componentname', this.Componentname);
  }
  public async onNew() {
    localStorage.setItem("alerturl", this.router.url);
    if (
      Boolean(await this.commonservice.checkPermission1("01.12.01.11")) == true
    ) {
      this.orderNo = 0;
      //this.apiShared.orderNo = this.orderNo;
      this.apiShared.Setapishareddata(this.orderNo, "orderNo");
      this.router.navigate(["/PenAir/Folder/FolderSummaryEdit", "0"]);
    } else {
      this.showmsg("01.12.01.11");
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied", 5000, true, false,"01.12.01.11")
    }
  }
  closeEmail(status:any){ debugger;
    if(status=="EmailSendSuccesfully" ||status=="Closed"){
      this.opened = false; //case id 13552 email modification
    }
  }
  
  onCellClick(e) {
    debugger;
    this.spinner.show();
    let clickedRowItem = e.dataItem;
    //alert((new Date()).getFullYear());
    var year = new Date(clickedRowItem.ALERT_DATE).getFullYear();
    if (year <= 2018) {
      this.subscription.add(
        this.translateapi
          .get(["Messages.Invalidproductguid1st"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.Invalidproductguid1st"];
          })
      );
      this.subscription.add(
        this.translateapi
          .get(["Messages.Invalidproductguid2nd"])
          .subscribe(translations => {
            this.showMsg =
              this.showMsg +
              " " +
              2018 +
              " ." +
              translations["Messages.Invalidproductguid2nd"];
          })
      );
      this.toastr.warning(this.showMsg, "", {
        timeOut: 5000,
        disableTimeOut: false,
        closeButton: true
      });
      this.spinner.hide();
    } else {
      if (clickedRowItem.ORDERNO == "" || clickedRowItem.ORDERNO == undefined) {
        this.alertID.ID = clickedRowItem.ID;
        this.alertID.Status = clickedRowItem.STATUS;
        this.reminderOpen();
      } else {
        this.getFolderDetails(clickedRowItem);
      }
    }
  }
  getFolderDetails(clickedRowItem: any) {
    //  this.apiShared.orderNo = clickedRowItem.FOLDERNO;
    this.apiShared.Setapishareddata(clickedRowItem.ORDERNO, "orderNo");
    this.apiShared.Setapishareddata(clickedRowItem.PROJNO, "projNo");
    this.passengerName =
      clickedRowItem.LEAD_PAX == null ? "" : clickedRowItem.LEAD_PAX;
    let arry = this.passengerName.split(" ");
    if (arry.length > 0) this.passengerName = arry[arry.length - 1].toString();
    //this.apiShared.PassengerName = this.passengerName;
    this.apiShared.Setapishareddata(this.passengerName, "PassengerName");
    // this.apiShared.PassengerNames = clickedRowItem.Pass;
    this.apiShared.Setapishareddata(clickedRowItem.LEAD_PAX, "PassengerNames");
    //alert(JSON.stringify(this.apiShared.PassengerNames));
    if (clickedRowItem.LEAD_PAX !== "" && clickedRowItem.LEAD_PAX !== null) {
      this.passenger =
        this.apiShared.PassengerNames + " - " + this.apiShared.orderNo;
    } else {
      this.passenger = this.apiShared.orderNo;
    }
    this.folderStatus = clickedRowItem.STATUSNAME;
    //this.apiShared.FolderStatus = this.folderStatus;
    this.apiShared.Setapishareddata(this.folderStatus, "FolderStatus");
    //12751
    if (
      clickedRowItem.ORDERNO != "0" &&
      clickedRowItem.ORDERNO != "" &&
      clickedRowItem.ORDERNO != null &&
      clickedRowItem.ORDERNO != undefined
    )
      this.getLockDetails(
        "Folder",
        clickedRowItem.ORDERNO,
        this.apiShared.uid,
        clickedRowItem.FOLDERNO
      );
    else this.spinner.hide();
    // this.getLockDetails("Folder", clickedRowItem.ORDERNO, this.apiShared.uid, clickedRowItem.FOLDERNO);

    this.folderStatus = this.apiShared.FolderStatus;
    this.layout.folderstatus = this.folderStatus;
    if (clickedRowItem.STATUSNAME == "Cancelled") {
      this.formeditable = false;
      this.lockStatus = "1";
      this.apiShared.folderReadOnly = this.lockStatus;
    }

    this.folderEditMode = "false";
    this.common.folderEditMode = "false";

    //this.apiShared.folderEditMode=this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, "folderEditMode");
  }
  deletePenLock(docType, docno) {
    this.penLock.DeleteLock(docType, docno + "/").subscribe(
      res => { },
      err => {
        console.log(err);
      }
    );
  }
  getLockDetails(docType, docNo, userId, projno) {
    localStorage.setItem("alerturl", this.router.url);
    this.subscription.add(
      this.penLock.getLock(docType, docNo, userId, projno).subscribe(data => {
        this.penDockLock = data;
        if (this.penDockLock.ResponseData != null) {
          if (this.penDockLock.ResponseData.Uname != "") {
            this.spinner.hide();
            this.folderReadOnly = "1";
            this.lockStatus = this.folderReadOnly;
            // this.apiShared.folderReadOnly = this.folderReadOnly;
            this.apiShared.Setapishareddata(
              this.folderReadOnly,
              "folderReadOnly"
            );
            this.subscription.add(
              this.translateapi
                .get(["Labels.lock_msg"])
                .subscribe(translations => {
                  this.confirmMsg = translations["Labels.lock_msg"].replace(
                    "<<1>>",
                    this.penDockLock.ResponseData.Uname
                  );

                  // this.apiShared.uid=userId;
                })
            );

            this.ConfirmWindow = true;
          } else {
            this.spinner.hide();
            this.folderReadOnly = "0";
            // this.apiShared.folderReadOnly = this.folderReadOnly;
            this.lockStatus = this.folderReadOnly;
            this.apiShared.Setapishareddata(
              this.folderReadOnly,
              "folderReadOnly"
            );
            this.orderNo = this.apiShared.orderNo;
            if (this.orderNo != "0") {
              this.router.navigate([
                "/PenAir/Folder/FolderSummary",
                this.apiShared.orderNo
              ]);
            } else
              this.router.navigate([
                "PenAir/Folder/FolderSummaryEdit",
                "0"
              ]);
          }
        } else {
          this.spinner.hide();
          this.folderReadOnly = "0";
          this.lockStatus = this.folderReadOnly;
          //this.apiShared.folderReadOnly = this.folderReadOnly;
          this.apiShared.Setapishareddata(
            this.folderReadOnly,
            "folderReadOnly"
          );
          this.orderNo = this.apiShared.orderNo;
          this.selectedPassenger.emit(this.passenger);
          if (this.orderNo != "0") {
            // localStorage.setItem('alerturl', 'PenAir/Folder/Index')

            this.router.navigate([
              "/PenAir/Folder/FolderSummary",
              this.apiShared.orderNo
            ]);
          } else
            this.router.navigate([
              "PenAir/Folder/FolderSummaryEdit",
              "0"
            ]);
        }
      })
    );
  }
  onConfirm(action: string) {
    this.ConfirmWindow = false;

    if (action == "yes") {
      this.selectedPassenger.emit(this.passenger);
      this.orderNo = this.apiShared.orderNo;
      if (this.orderNo != "0") {
        this.router.navigate([
          "/PenAir/Folder/FolderSummary",
          this.apiShared.orderNo
        ]);
      } else
        this.router.navigate(["PenAir/Folder/FolderSummaryEdit", "0"]);
    }
  }
  public pageChangeUser(event: PageChangeEvent): void {
    this.stateUser.skip = event.skip;
    this.getFolderList();
  }
  public dataStateChange(state: DataStateChangeEvent): void { }
  public onSelect({ dataItem, item }): void {
    //const index = this.gridData.indexOf(dataItem);
  }
  getFolderList() {
    this.spinner.show();
    var pageno = this.stateUser.skip / this.pageSize + 1;
    this.paginationservice
      .GetInboxClosedResult(
        this.pageSize,
        pageno,
        this.AlreadyPaid,
        this.Type,
        this.userId,
        this.actionid
      )
      .subscribe(res => {
        debugger;
        this.spinner.hide();
        if (
          res != null &&
          res.ResponseData != null &&
          res.ResponseData.length > 0
        ) {
          this.totRows =
            res.ResponseData[0] != undefined ? res.ResponseData[0].tot_rows : 0;
          this.folderList = res.ResponseData;
          for (var obj of res.ResponseData) {
            obj.ALERT_CR_TIME = moment(obj.ALERT_CR_TIME, "h:mm:ss A").format(
              "HH:mm:ss"
            );
          }

          this.gridData = {
            data: res.ResponseData,
            total: res.ResponseData[0].tot_rows
          };
          this.spinner.hide();
        } else {
          this.gridData = {
            data: [],
            total: 0
          };

          this.subscription.add(
            this.translateapi
              .get(["Messages.no_records"])
              .subscribe(translations => {
                this.Messages_no_records = translations["Messages.no_records"];
                // alert(this.Messages_no_records)
              })
          );
          this.spinner.hide();
        }
      });
  }
  onCheckboxAlreadyPaidChange() {
    if (this.AlreadyPaid == true) {
      this.AlreadyPaid_int = 1;
    } else {
      this.AlreadyPaid_int = 0;
    }
    this.getFolderList();
  }
  // total: res.ResponseData[0].tot_rows
  folderReadOnly: any;

  public onExcelExport(e: any): void {
    debugger;
    const rows = e.workbook.sheets[0].rows;

    // align multi header
    rows[0].cells[2].hAlign = "center";
    ///   this.kendoexcel.fileName=this.kendoexcel.fileName;
    // this.myFile['name']=this.kendoexcel.fileName;

    //   this.myFile['type']="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    // this.files= this.myFile;
    // this.myFile['size']=this.files;
    // const data4: Blob = new Blob([this.files], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    //  const formData: FormData = new FormData();

    // formData.append('file',data4,this.kendoexcel.fileName);
    // this.paginationservice.upload(formData);
    this.open(); //caseid :13552 email modification by sreekanth
  }
  public close(status) {
    this.opened = false;
  }
  public open() {
    this.opened = true;
  }
  public exportAsExcelFile(json: any, excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: json },
      SheetNames: json
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array"
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    const formData = new FormData();
    this.myFiles.push(JSON.stringify(data));
    formData.append("file", this.myFiles[this.myFiles.length - 1]);
    //  this.paginationservice.upload(formData);
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + ".xlsx"
    );
  }
  getfiledat(evt: any) {
    debugger;
    console.log(evt.target.files[0]);
    this.myFiles.push(evt.target.files[0]);
    const frmData = new FormData();
    debugger;

    frmData.append("dsf", this.myFiles[this.myFiles.length - 1]);
    this.paginationservice.upload(frmData);
  }
  public uploadFile(): void {
    debugger;
    if (this.fileInput.files.length === 0) {
      return; // maybe needs more checking
    }

    const formData = new FormData();
    formData.append("file", this.fileInput.files[0]);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public permissionmessageformopened: any; //Caseid-339
  public PermissionMessageId: any; //Caseid-339

  public PermissionFormClosing(
    status: any //339
  ) {
    if (status == "EmailSendSuccesfully") {
      //339
      this.permissionmessageformopened = false;
    }
  }

  showmsg(permissionid: string) {
    this.permissionmessageformopened = true; //Caseid-339
    this.PermissionMessageId = permissionid; //Caseid-339
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.gridData = {
      data: orderBy(this.folderList, this.sort),
      total: this.totRows
    };
  }
  public reminderOpen() {
    // Caseid-12739
    this.openRemider = true;
  }
  public reminderClose(e) {
    // Caseid-12739
    debugger;
    if (e == "reload") {
      this.getFolderList();
    }
    this.openRemider = false;
  }
}
