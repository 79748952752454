import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EnvService } from 'src/env.service';
import { PublicLayoutComponent } from '../public-layout/public-layout.component';
import { CommonserviceService } from '../Services/commonservice.service';
import { FileService } from '../Services/file.service';
import { ShareDataService } from '../Services/share-data.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  image: any;
  UID: any;
  comLogo: any
  objURL: string;
  orderNo: any;
  hcCurrency: any;
  url: any;
  private subscription: Subscription = new Subscription();

  public permissionmessageformopened: any;
  public PermissionMessageId: any;
  showMsg: any;
  constructor(private env: EnvService, private toastr: ToastrService, public common: CommonserviceService, private router: Router, private sanitizer: DomSanitizer, private apiShared: ShareDataService, private file: FileService,
    private layout: PublicLayoutComponent) {

    this.UID = this.apiShared.uid;
    this.comLogo = this.apiShared.cLogo;
    this.loadData();
    this.layout.headervalue = '';
  }

  ngOnInit() {
    this.orderNo = "";
    this.orderNo = this.apiShared.orderNo;
    this.apiShared.ReceiptPayment = 1;
    this.subscription.add(this.file.getFileDetails()
      .subscribe(data => {
        this.hcCurrency = data.ResponseData.COMCID;

        this.apiShared.Setapishareddata(this.hcCurrency, 'homeCurrency');
      }));
  }



  loadData() {
    if (this.comLogo.source === 'undefined' || this.comLogo.source === undefined) {
      this.image = this.sanitizer.bypassSecurityTrustUrl(this.comLogo);
    } else {
      this.subscription.add(this.file.GetCompanyLogo(this.UID)
        .subscribe(data => {
          if (data != null) {
            this.objURL = 'data:image/png;base64,' + data["ResponseData"];
            this.comLogo = this.objURL;
            this.apiShared.cLogo = this.comLogo;
            this.image = this.sanitizer.bypassSecurityTrustUrl(this.comLogo);
          }
        }));
    }
  }
  //# region manual folder
  async onNew() {

    localStorage.setItem('alerturl', this.router.url);
    if (Boolean(await this.common.checkPermission1("01.12.01.11")) == true) {
      this.orderNo = 0;
      //this.apiShared.orderNo = this.orderNo;
      // this.confirmRetailOrPackageFolder=true;

      // this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
      // this.router.navigate(['/PenAir/Folder/FolderSummaryEdit', "0"], { skipLocationChange: true });
      this.router.navigate(['/PenAir/RetailPackageFolder']);

    }
    else {

      this.showmsg('01.12.01.11');
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied", 5000, true, false,"01.12.01.11")
    }
  }
  showmsg(permissionid: string) {
    this.permissionmessageformopened = true;
    this.PermissionMessageId = permissionid;
  }

  //#end region manual folder

  //# region search
  onSearch() {
    this.router.navigateByUrl('/PenAir/SearchBy');
  }
  //# end  region search

  //# region Book hotel(PenHotel) nad flight(PenIBEFlight)
  async onBooking(type: any) {
    if (type == 'PenHotel') {//Hotel
      if (Boolean(await this.common.checkPermission1("13.10.01")) == true) {
        this.subscription.add(
          this.file.getUrlSettings(type).subscribe(data => {
            if (data.responsedata != null && data.responsedata != undefined && data.responsedata != "") {
              this.url = data["ResponseData"];
              window.open(this.url)
            }
            else {
              this.showMsg = " Url Not Found";
              this.toastr.warning(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });

            }

          })
        );


      } else {
        this.ShowPermissionMessageForm("13.10.01");
      }
    } else {//Logic for flight



      if (Boolean(await this.common.checkPermission1("QuickMenu_Flight_Search")) == true) {

        this.subscription.add(
          this.file.getUrlSettings(type).subscribe(data => {
            if (data.ResponseData != null && data.ResponseData != "" && data.ResponseData != undefined) {
              this.url = data.ResponseData
              window.open(this.url)
            }
            else {
              this.showMsg = " Url not Found";
              this.toastr.warning(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
            }
          })
        );

      }





    }


  }
  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;
    this.PermissionMessageId = permissionid;
  }
  //# end book hotel
  onClick() { }
}
