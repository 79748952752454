import { Component, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { FolderSearchCriteriaDTO, TourSearchDTO } from '../../Models/folder-search-criteria-dto';
import { SearhdtotransferService } from '../../Services/searhdtotransfer.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
@Component({
  selector: 'app-searchby-tour',
  templateUrl: './searchby-tour.component.html',
  styleUrls: ['./searchby-tour.component.scss']
})
export class SearchbyTourComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  //tourSearchForm: FormGroup;
  control: number;
  public statusList: Array<string> = ["OK", "Pending", "Cancelled", "Confirmed"];
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  SupplierList: Array<LookUpDetails['ResponseData']> = [];
  opened = false;
  openSearch = false;
  public supplierSelectedItem: LookUpDetails['ResponseData'];
  public supplierSelected: String;
  constructor(public common: CommonserviceService, private searhTransferDTO: SearhdtotransferService, private lookupApi: LookUpDetailsService) { }
  flsrchDto: FolderSearchCriteriaDTO;
  TourSearchDTO: TourSearchDTO;




  ngOnChanges(changes: SimpleChanges) {

    this.TourSearchDTO = this.flsrchDto.TourSearchDTO;
  }
  menuIndex = 5;
  ngOnInit() {

    this.subscription.add(this.searhTransferDTO.SrchTranferDTO.subscribe(flsrchDto => {
      this.flsrchDto = flsrchDto;

    }))
    if (this.flsrchDto.TourSearchDTO != null)
      this.TourSearchDTO = this.flsrchDto.TourSearchDTO;
    else
      this.TourSearchDTO = new TourSearchDTO();
    // this.loadSupplier();
    if(this.TourSearchDTO.Supplier!="" &&  this.TourSearchDTO.Supplier!= undefined){
       this.loadSupplierValue(this.TourSearchDTO.Supplier);
    }
  }
  loadSupplierValue(value:any) {  //14068   v1.0 
    debugger; 

      this.subscription.add(this.lookupApi.findSupplier(value).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.SupplierList = data["ResponseData"];
            if (value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
              }

            }
          }

        }
      ));
    
  }



  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  handleFiltersupplier(value) {

    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.GetLookupById("SUPPLIERS", value, value).subscribe(
        // this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }


  public SupplierValueChange(value: any): void {
    //  alert(value);
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);
    }

    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860

      this.TourSearchDTO.Supplier = (this.supplierSelectedItem.Code);


    } else {
      this.supplierSelected = '';
      this.TourSearchDTO.Supplier = ('');


    }
    this.callChange();
  }


  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860
  public close(status) {

    this.opened = false;
    this.openSearch = false;
  }

  callChange() {
    debugger;
    if (this.TourSearchDTO.Supplier != '' || this.TourSearchDTO.StartDateFromAF.toString() != ''
      || this.TourSearchDTO.StartDateToAF.toString() != '' || this.TourSearchDTO.EndDateFromAF.toString() != ''
      || this.TourSearchDTO.EndDateToAF.toString() != '' || this.TourSearchDTO.Status != '') {
      if (this.TourSearchDTO.StartDateFromAF != null) {
        if (this.TourSearchDTO.StartDateFromAF.toString() != '')
          this.TourSearchDTO.StartDateFrom = this.common.transformDate(new Date(this.TourSearchDTO.StartDateFromAF));

        // this.TourSearchDTO.StartDateFrom= new Date(this.TourSearchDTO.StartDateFromAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.TourSearchDTO.StartDateFrom = null;
      }
      if (this.TourSearchDTO.StartDateToAF != null) {
        if (this.TourSearchDTO.StartDateToAF.toString() != '')
          this.TourSearchDTO.StartDateTo = this.common.transformDate(new Date(this.TourSearchDTO.StartDateToAF));
        //this.TourSearchDTO.StartDateTo= new Date(this.TourSearchDTO.StartDateToAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.TourSearchDTO.StartDateTo = null;
      }

      if (this.TourSearchDTO.EndDateFromAF != null) {
        if (this.TourSearchDTO.EndDateFromAF.toString() != '')
          this.TourSearchDTO.EndDateFrom = this.common.transformDate(new Date(this.TourSearchDTO.EndDateFromAF));
        // this.TourSearchDTO.EndDateFrom= new Date(this.TourSearchDTO.EndDateFromAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.TourSearchDTO.EndDateFrom = null;

      }
      if (this.TourSearchDTO.EndDateToAF != null) {
        if (this.TourSearchDTO.EndDateToAF.toString() != '')
          this.TourSearchDTO.EndDateTo = this.common.transformDate(new Date(this.TourSearchDTO.EndDateToAF));
        // this.TourSearchDTO.EndDateTo= new Date(this.TourSearchDTO.EndDateToAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.TourSearchDTO.EndDateTo = null;

      }

      if (this.TourSearchDTO.BookingDateFromAF != null) {
        if (this.TourSearchDTO.BookingDateFromAF.toString() != '')
          this.TourSearchDTO.BookingDateFrom = this.common.transformDate(new Date(this.TourSearchDTO.BookingDateFromAF));
        // this.TourSearchDTO.BookingDateFrom= new Date(this.TourSearchDTO.BookingDateFromAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.TourSearchDTO.BookingDateFrom = null;
      }
      if (this.TourSearchDTO.BookingDateToAF != null) {
        if (this.TourSearchDTO.BookingDateToAF.toString() != '')
          this.TourSearchDTO.BookingDateTo = this.common.transformDate(new Date(this.TourSearchDTO.BookingDateToAF));
        //  this.TourSearchDTO.BookingDateTo= new Date(this.TourSearchDTO.BookingDateToAF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.TourSearchDTO.BookingDateTo = null;
      }

      if (this.TourSearchDTO.StartDateFromAF == null && this.TourSearchDTO.StartDateToAF != null) {
        this.TourSearchDTO.StartDateFrom = this.TourSearchDTO.StartDateTo;
      }
      if (this.TourSearchDTO.EndDateFromAF == null && this.TourSearchDTO.EndDateToAF != null) {
        this.TourSearchDTO.EndDateFrom = this.TourSearchDTO.EndDateTo;
      }

      if (this.TourSearchDTO.BookingDateFromAF == null && this.TourSearchDTO.BookingDateToAF != null) {
        this.TourSearchDTO.BookingDateFrom = this.TourSearchDTO.BookingDateTo;
      }


      this.flsrchDto.TourSearchDTO = this.TourSearchDTO;
    }


    else
      this.flsrchDto.TourSearchDTO = null;
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
