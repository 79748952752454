export class SupplierMasterDetailsDTO {
    PrtySuppUsrCode  :string;
    PrtyName  :number;
    Add1 :string;
    Add2 :string;
    Add3 :string;
    Add4 :string;
    PrtyPostalP :string;
    PrtyVatno:string;
    PrtyCountryP:string;
    PrtyCode:string;
    CURRENCYCODE:number;
    Salutation:number;
    TaxValidTo:any;
}

