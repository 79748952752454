
 import { Injectable } from '@angular/core';
 import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
 import {CustomerReceiptResponse} from '../Models/customer-receipt-response';
 import { Observable, of, throwError } from 'rxjs';
 import { catchError, tap, map } from 'rxjs/operators';
 import { EnvService } from '../../env.service';
 import { ResponseData } from '../Models/response-data';
 import {CustomerReceiptPost} from '../Models/customer-receipt-post';
 import {CustomerPostResult} from '../Models/customer-post-result';
import { CommonserviceService } from './commonservice.service';
import { CustomerReceiptRequest } from '../Models/customer-receipt-request';
import { CustomerReceiptHeldHeaderData } from '../Models/customer-receipt-held-header-data';

 const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

 @Injectable({

  providedIn: 'root'
})
export class CustomerreceiptresponseService {
  public formData:CustomerReceiptRequest;
  public List:any;
  public formDQData:any;
  apiUrl: string;
  constructor(private http: HttpClient, private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl = env.baseUrl;
   }
 

  GetCustomerReceiptDetails(Id: number, UserId: number, folderNo: string, YearId: string, CurrencyId: string): Observable<CustomerReceiptResponse[]> {
    const url = `${this.apiUrl}` + 'GetCustomerReceiptDetails' + `/${Id}/${UserId}/${folderNo}/${YearId}/${CurrencyId}`;
    // return this.data;
    // alert(url);
    return this.http.get<CustomerReceiptResponse[]>(url).pipe(
       tap(_ => console.log(`getFolder No=${folderNo}`)),
      catchError(this.commonservice.handleError<CustomerReceiptResponse[]>(`getFolder No=${folderNo}`))
     );
  }

  public  GetOutsideCustomerReceiptDetails(): Observable<CustomerReceiptResponse>
   {
   
    var body = {
          ...this.formData, 
    };
    return this.http.post<CustomerReceiptResponse[]>(this.apiUrl+'GetOutsideCustomerReceiptDetails', body, httpOptions).pipe(
      tap((CustomerReceiptResponse: any) => console.log(`GetOutsideCustomerReceiptDetails`)),
      catchError(this.commonservice.handleError<any>('GetOutsideCustomerReceiptDetails'))
    );
  
  }
  PostCustomerReceiptDetails(CustomerReceiptPost: any): Observable<any> {
   var  method='ReceiptPost';   
   if( CustomerReceiptPost.TransactionType == 'PdqMoto')
   {
    method='CustomerReceiptCreditCardPost';
   }  
   const url = `${this.apiUrl}` + method; /// ${id}
   return this.http.post(url, CustomerReceiptPost, httpOptions).pipe(
      tap(_ => console.log(method)),
      catchError(this.commonservice.handleError<any>(method))
    );
  }
  HeldReceipt(CustomerReceiptHold: any): Observable<any> 
  {   
    const url = `${this.apiUrl}` + 'HeldReceipt'; /// ${id}
    return this.http.post(url, CustomerReceiptHold, httpOptions).pipe(
       tap(_ => console.log(`HeldReceipt`)),
       catchError(this.commonservice.handleError<any>('HeldReceipt'))
     );
   }
   HeldDeleteReceipt(HoldInvNo: string): Observable<any> {
    //const url = `${this.apiUrl}` + 'HeldDeleteReceipt'; /// ${id}
    const url = `${this.apiUrl}` + 'HeldDeleteReceipt' + `/${HoldInvNo}`;
    return this.http.post(url, HoldInvNo, httpOptions).pipe(
       tap(_ => console.log(`HeldDeleteReceipt`)),
       catchError(this.commonservice.handleError<any>('HeldDeleteReceipt'))
     );
   }
   public  RetriveHeldData(HoldInvNo: string): Observable<CustomerReceiptHeldHeaderData>
   {
  
    const url = `${this.apiUrl}` + 'GetHeldData' + `/${HoldInvNo}`;
    return this.http.post(url, HoldInvNo, httpOptions).pipe(
       tap(_ => console.log(`GetHeldData`)),
       catchError(this.commonservice.handleError<any>('GetHeldData'))
     );
  
  }
  
  // async CustomerReceiptSave(CustomerReceiptPost: any):Promise< Observable<CustomerPostResult>>  
  // {
  //  const url = `${this.apiUrl}` + 'CustOutsideReceiptPost'; /// ${id}
  //  console.log(JSON.stringify(CustomerReceiptPost));
  //  var result =await  this.http.post(url, CustomerReceiptPost, httpOptions).pipe(
  //     tap(_ => console.log(`CustOutsideReceiptPost`)),
  //     catchError(this.commonservice.handleError<any>('CustOutsideReceiptPost'))
  //   ).toPromise();
  //   return  result;
  // }

  CustomerReceiptSave(CustomerReceiptPost: any): Observable<any> {
    const url = `${this.apiUrl}` + 'CustOutsideReceiptPost'; /// ${id}
    return this.http.post(url, CustomerReceiptPost, httpOptions).pipe(
       tap(_ => console.log(`CustOutsideReceiptPost`)),
       catchError(this.commonservice.handleError<any>('CustOutsideReceiptPost'))
     );
   }

   CustomerReceiptOutsideFolderPDQMOTOPost (): Observable<any> {
    var body = {
      ...this.formDQData,
      CustomerReceiptDetailXMLClass: this.List
     
    };
    
    return this.http.post<any>(this.apiUrl+'CustomerReceiptOutsideFolderPDQMOTOPost', body).pipe(
           tap(_ => console.log(`CustomerReceiptOutsideFolderPDQMOTOPost`)),
           catchError(this.commonservice.handleError<any>('CustomerReceiptOutsideFolderPDQMOTOPost'))
         );
    //alert(JSON.stringify(FolderSummary));
   
  
  
  }
   
   
  // GetOutsideCustomerReceiptDetails()
  // {
  //   const url = `${this.apiUrl}` + 'ReceiptPost'; /// ${id}
  //  return this.http.post(url, CustomerReceiptPost, httpOptions).pipe(
  //     tap(_ => console.log(`ReceiptPost`)),
  //     catchError(this.commonservice.handleError<any>('ReceiptPost'))
  //   );
  // }
  public  GetCarholderMembershipInfo(data:any): Observable<any>
  {
  
   var body = {
         ...data, 
   };
   return this.http.post<any>(this.apiUrl+'GetCarholderMembershipInfo', body, httpOptions).pipe(
     tap((CustomerReceiptResponse: any) => console.log(`GetCarholderMembershipInfo`)),
     catchError(this.commonservice.handleError<any>('GetCarholderMembershipInfo'))
   );
 
 }
}
