
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';

import { RailsegService } from '../../Services/railseg.service';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ShareDataService } from '../../Services/share-data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-railseg-details-edit',
  templateUrl: './railseg-details-edit.component.html',
  styleUrls: ['./railseg-details-edit.component.scss']
})



export class RailsegDetailsEditComponent implements OnInit {
  private subscription: Subscription = new Subscription();

  disableTextbox =  false;
    ucode:string;
    RailsegEditForm: FormGroup;
    submitted = false;
    loaded=false;
    depcity:string
    arrcity:string;
    folderEditMode:any;
    projNo:any;
    public showMsg:any;
    public ExcludeOnetimeCust_int=0;
    public ExcludeOnetimeCust=false;
    public statusList: Array<string> = ["Wait listed", "Confirmed", "Cancelled"];
    public commissinOnList: Array<string> = ["Purchase","Sales","Gross"];
    public passengerTypeList: Array<string> = ["Adult","Youth","Child","Infant"];
    constructor(public common :CommonserviceService,private router: Router, private route: ActivatedRoute,private formBuilder: FormBuilder, private api: RailsegService,private lookupApi:LookUpDetailsService
      ,private apiShared: ShareDataService  ,private flashMessage: FlashMessagesService ,private translateapi: TranslateService,private toastr:ToastrService,private spinner:NgxSpinnerService) { }
    private slno:string=this.route.snapshot.params['slno'];//'Railseg.20190826.103628.{B9CB75FE-A3D6-46DE-926E-27990C46ECEC}/';
    private orderno=this.route.snapshot.params['orderNo']
   
    Railseg: Array<LookUpDetails["ResponseData"]> = [];  
    public RailsegselectedItem: LookUpDetails["ResponseData"];

    Currency: Array<LookUpDetails["ResponseData"]> = [];  
    public CurrencyselectedItem: LookUpDetails["ResponseData"];

    GDSL: Array<LookUpDetails["ResponseData"]> = [];  
    public GDSselectedItem: LookUpDetails["ResponseData"];
    departureDate:any;//337
    arrivalDate:any //337
    depositDueDate:any//337

  ngOnInit() {
    var orderno= sessionStorage.getItem('orderNo');
    if(orderno!=null)
    if(this.orderno !==orderno)
    {
      return  this.router.navigate(['PenAir/Home']);
    }
    this.folderEditMode="true";
   // this.apiShared.folderEditMode=this.folderEditMode;
   this.apiShared.Setapishareddata(this.folderEditMode,'folderEditMode');
    this.projNo=this.apiShared.projNo;
    this.RailsegEditForm = this.formBuilder.group({
  

      FOLDERNO: [this.orderno],
    //  GUIDItinerary: [''],
    
   
          SLNO: [0],
          ITEMNO :  [0],
          TRAINNAME :  [''],
          TRAINNO :  [''],
          CLASS :  [''],
          CLASSNAME :  [''],
          SEATS : [''],
          STATUS :  [''],
          STATUSNAME :  [''],
          DEPDATE :  [''],
          DEPCITY :  ['', Validators.required],
          DEPCITYNAME :  ['', Validators.required],
          ARRCITY :  ['', Validators.required],
          ARRCITYNAME :  ['', Validators.required],
          OPENSEG :  [''],
          DEPTIME :  [0],
          ARRTIME : [0],
          S :  [''],
          PNRNO :  [''],
          FAREBASIS :  [''],
          DEPTERM :  [''],
          CHKIN : [''],
          BOOKEDVIA : [''],
          ARRDATE :   [''],
          ARRTERM : [''],
          TRAINTIME :  [0],
          BAGGALLOW :  [''],
          MEALSINFO :  [''],
          SEATINFO :  [''],
         PROJNO : [this.projNo],
          Stops :  [0],
          GDS : [''],
          ItnryNotes : [''],
          
          PCC :  [''],
          VLOCATOR :  [''],
          VOUCHER : [''],
          CONFIRMATIONNO : [''],  
         
    
      
     
  });
  
  
 if(this.slno!=null)
 this.getRailsegDetails(this.orderno,this.slno);
 
 
 
this.subscription.add(this.lookupApi.GetAllLookup('GDS').subscribe(
  (data) => {
    if(data!=null &&data!=undefined){
     this.GDSL= data["ResponseData"]           
     if(this.RailsegEditForm.controls.GDS.value!="")
     {
     this.GDSselectedItem =this.GDSL.find(item => item.Code ==this.RailsegEditForm.controls.GDS.value);
    }
  }
  }
  ));  
  
  




}

// convenience getter for easy access to form fields
get f() { return this.RailsegEditForm.controls; }

getRailsegDetails(id,guid) {
  this.spinner.show();
  
this.subscription.add(this.api.getRailseg(id,guid)
    .subscribe(data => {
      if(data!=null &&data!=undefined){
      this.RailsegEditForm.setValue({
          FOLDERNO: data.ResponseData.FOLDERNO,
          SLNO: data.ResponseData.SLNO,
         
         
          ITEMNO : data.ResponseData.ITEMNO,
          TRAINNAME : data.ResponseData.TRAINNAME,
          TRAINNO : data.ResponseData.TRAINNO,
          CLASS : data.ResponseData.CLASS,
          CLASSNAME : data.ResponseData.CLASSNAME,
          SEATS : data.ResponseData.SEATS,
          STATUS : data.ResponseData.STATUS,
          STATUSNAME : data.ResponseData.STATUSNAME,
          DEPDATE :data.ResponseData.DEPDATE!=null?(data.ResponseData.DEPDATE.toString()==='1900-01-01T00:00:00'?null: new Date(data.ResponseData.DEPDATE)):null,
          DEPCITY : data.ResponseData.DEPCITY,
          DEPCITYNAME : data.ResponseData.DEPCITYNAME,
          ARRCITY : data.ResponseData.ARRCITY,
          ARRCITYNAME : data.ResponseData.ARRCITYNAME,
          OPENSEG : data.ResponseData.OPENSEG,
          DEPTIME : data.ResponseData.DEPTIME,
          ARRTIME : data.ResponseData.ARRTIME,
          S : data.ResponseData.S,
          PNRNO : data.ResponseData.PNRNO,
          FAREBASIS : data.ResponseData.FAREBASIS,
          DEPTERM : data.ResponseData.DEPTERM,
          CHKIN : data.ResponseData.CHKIN,
          BOOKEDVIA : data.ResponseData.BOOKEDVIA,
          ARRDATE :data.ResponseData.ARRDATE!=null?(data.ResponseData.ARRDATE.toString()==='1900-01-01T00:00:00'?null:new Date(data.ResponseData.ARRDATE)):null,
          ARRTERM : data.ResponseData.ARRTERM,
          TRAINTIME : data.ResponseData.TRAINTIME,
          BAGGALLOW : data.ResponseData.BAGGALLOW,
          MEALSINFO : data.ResponseData.MEALSINFO,
          SEATINFO : data.ResponseData.SEATINFO,
        
          PROJNO: data.ResponseData.PROJNO,
          Stops : data.ResponseData.Stops,
          GDS : data.ResponseData.GDS,
          ItnryNotes : data.ResponseData.ItnryNotes,
          PCC : data.ResponseData.PCC,
          VLOCATOR : data.ResponseData.VLOCATOR,
          VOUCHER : data.ResponseData.VOUCHER,
          CONFIRMATIONNO : data.ResponseData.CONFIRMATIONNO,


        });
        if(data.ResponseData.GDS!="")
        {
          this.GDSselectedItem =this.GDSL.find(item => item.Code ==data.ResponseData.GDS);
        }
        if(data.ResponseData.OPENSEG=='1'){
          this. ExcludeOnetimeCust_int=1;
          this. ExcludeOnetimeCust=true;
         this.RailsegEditForm.controls.OPENSEG.setValue(1);
        }
        else{
          this. ExcludeOnetimeCust_int=0;
          this. ExcludeOnetimeCust=false;
         this.RailsegEditForm.controls.OPENSEG.setValue(0);
        };
        this.spinner.hide();
      //this.isLoadingResults = false;
      this.loaded=true;
      }
      else{
        this.spinner.hide();
      }
    //  this.FormChange();
    }));
}
coverttouppercase(value)
{
  if(value!=undefined)//Caseid-358
  {
    this.depcity=value.toUpperCase();//Caseid-358
  }
}
coverttouppercase1(value)
{
  if(value!=undefined)//Caseid-358
  {
    this.arrcity=value.toUpperCase();
  }
   
}

onFormSubmit(){

  this.submitted = true;

        this.departureDate=(this.RailsegEditForm.controls.DEPDATE.value==null||this.RailsegEditForm.controls.DEPDATE.value==""?"01-Jan-1900":this.RailsegEditForm.controls.DEPDATE.value);//Case id-337
        this.arrivalDate=(this.RailsegEditForm.controls.ARRDATE.value==null||this.RailsegEditForm.controls.ARRDATE.value==""?"01-Jan-1900":this.RailsegEditForm.controls.ARRDATE.value);//Case id-337
        if(Boolean(this.common.ValidDateRange(this.departureDate))==false)//Case id-337
        {
          this.subscription.add(this.translateapi.get(['Messages.InvalidDepartureDate']).subscribe((translations ) => {
          this.showMsg= translations['Messages.InvalidDepartureDate'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
          return;
        }
        if(Boolean(this.common.ValidDateRange(this.arrivalDate))==false)//Case id-337
        {
          this.subscription.add(this.translateapi.get(['Messages.invalid_arrival_date']).subscribe((translations ) => {
          this.showMsg= translations['Messages.invalid_arrival_date'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
          return;
        }
  //console.log(this.RailsegEditForm.get('FOLDERNO').value);
  // stop here if form is invalid
  if (this.RailsegEditForm.invalid) {
      return;
  }


 //this.RailsegDetailModal= Object.assign({}, this.RailsegEditForm.value);
 if(this.slno!=null)
 {

  this.spinner.show();
  this.subscription.add( this.api.updaterailSeg(this.RailsegEditForm.value)
.subscribe(res => {
  if(res!=null && res!=undefined){
  if(res.IsSuccess===true && res.Message==='SUCCESS')
  {
 this.spinner.hide();
 this.common.showFlash1('Messages.saved_success','bg-success text-white',2000);
  //alert('SUCCESS3!! :-)\n\n' + JSON.stringify(this.RailsegEditForm.value, null, 4));
  this.router.navigate(['PenAir/Folder/RailsegDetails',this.orderno,this.slno+'/']);
} else {
  this.spinner.hide();
  this.toastr.warning(res.ResponseData.ErrorMessage,"",{
    timeOut:5000,
    disableTimeOut:false,
    closeButton:true
    })
  } 

} 
else
{
  this.spinner.hide();

}
}, (err) => {
    this.spinner.hide();
    console.log(err);
    this.common.showFlash1('Messages.some_error_in_save','bg-danger text-white',2000);
  //  alert('Failed!! :-)\n\n' +err+'\n\n'+ JSON.stringify(this.RailsegEditForm.value, null, 4));
  }
  ));
  }

else
{
  this.spinner.show();
  this.subscription.add(this.api.addrailSeg(this.RailsegEditForm.value)
.subscribe(res => {
  if(res!=null && res!=undefined){
  if(res.IsSuccess===true && res.Message==='SUCCESS')
  {
    this.spinner.hide();
    this.common.showFlash1('Messages.saved_success','bg-success text-white',2000);
  //alert('SUCCESS223!! :-)\n\n' + JSON.stringify(this.RailsegEditForm.value, null, 4));
  //this.router.navigate(['/PenAir/Folder/Products',this.orderno]);
  this.common.isfinalsaved=false;
  sessionStorage.setItem('pagevalue', '0');
  var folderQuotes=sessionStorage.getItem('folderQuotes');
  if(folderQuotes){
    this.router.navigate(['/PenAir/CRM/Enquiry/View/',this.orderno]);
  }else{
   this.router.navigate(['PenAir/Folder/FolderSummary',this.orderno]);
  }
} else {
  this.spinner.hide();
  this.toastr.warning(res.ResponseData.ErrorMessage,"",{
    timeOut:5000,
    disableTimeOut:false,
    closeButton:true
    })
  }  
}
else{
  this.spinner.hide();
  
}
}, (err) => {
    this.spinner.hide();
    console.log(err);
    this.common.showFlash1('Messages.some_error_in_save','bg-danger text-white',2000);
   // alert('Failed!! :-)\n\n' +err+'\n\n'+ JSON.stringify(this.RailsegEditForm.value, null, 4));
  }
  ));
  }
 //alert('SUCCESS2!! :-)\n\n' + JSON.stringify(this.RailsegDetailModal, null, 4));
  // display form values on success
 // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.RailsegEditForm.value, null, 4));
}
onCancel()
{
  if(this.slno!=null&&this.slno!=""){
    this.router.navigate(['PenAir/Folder/RailsegDetails',this.orderno,this.slno+'/']);
  }
  else{
  this.common.isfinalsaved=false;
  sessionStorage.setItem('pagevalue', '0')
  var folderQuotes=sessionStorage.getItem('folderQuotes');
  if(folderQuotes){
    this.router.navigate(['/PenAir/CRM/Enquiry/View/',this.orderno]);
  }
  else{
    this.router.navigate(['PenAir/Folder/FolderSummary',this.orderno]);
  }
}
// this.router.navigate(['/PenAir/Folder/Products',this.orderno]);
}
onReset() {
  this.submitted = false;
  this.RailsegEditForm.reset();
}
public GDSChange(value: any): void {
  debugger;
  if(value!=null && value!=undefined)
  this.RailsegEditForm.controls.GDS.setValue( value.Code);
  else
  this.RailsegEditForm.controls.GDS.setValue("");
}

onCheckboxChange()
{

  if(this.ExcludeOnetimeCust==true)
  {
this. ExcludeOnetimeCust_int=1;
this.RailsegEditForm.controls.OPENSEG.setValue(1);
  }
  else{
    this. ExcludeOnetimeCust_int=0;
    this.RailsegEditForm.controls.OPENSEG.setValue(0);
  }
  
 
 

}
public RailsegChange(value: any): void {
  console.log('selectionChange', value);
  if(value!=null && value!=undefined)
  this.RailsegEditForm.controls.TYPE.setValue( value.Code);
  else
  this.RailsegEditForm.controls.TYPE.setValue( "");
}
public CurrencyChange(value: any): void {
  console.log('selectionChange', value);
  if(value!=null && value!=undefined)
  this.RailsegEditForm.controls.CURCODE.setValue( value.Code);
  else
  this.RailsegEditForm.controls.CURCODE.setValue( "");
}
// FormChange()
// {
// if( this.RailsegEditForm.controls.LOYALTYPOINTSMEMBNO.value!="" &&this.RailsegEditForm.controls.LOYALTYPOINTSMEMBNO.value!=null)
// {
//  alert(1)
//   // this.RailsegEditForm.controls.LOYALTYPOINTS.enabled;
//   // this.RailsegEditForm.controls.LOYALTYPOINTSAMT.enabled;
//   this.disableTextbox =  false;
// }
// else{
//   alert(2)
//  // this.RailsegEditForm.controls.LOYALTYPOINTS.disabled;
//   //this.RailsegEditForm.controls.LOYALTYPOINTSAMT.disabled;
//   this.disableTextbox =  true;
// }
// }
onChanges(): void {
  //this.RailsegEditForm.get('SUPPRTYUSERCODE').valueChanges.subscribe(val => {
 //  this.formattedMessage = `My name is ${val}.`;
   //console.log({val}.val);
   
   //let toArray = {val}.val.split(" - ");
   
   //this.RailsegEditForm.controls.SUPPNAME.setValue(toArray[1]);
  //});
}

changefn()
{

   // this.ucode= this.RailsegEditForm.controls.SUPPRTYUSERCODE.value;
//   this.RailsegEditForm.get('RailsegUserCodeText').valueChanges.subscribe(val => {
      //  this.formattedMessage = `My name is ${val}.`;
        console.log(this.ucode);
        if( this.ucode=="")
        this.RailsegEditForm.controls.SUPPNAME.setValue("");
        let toArray =this.ucode.split("-");
        //this.RailsegEditForm.controls.SUPPRTYUSERCODE.setValue(toArray[0]);
        if(toArray.length>1)
         if(toArray[1].trim()!='')
        this.RailsegEditForm.controls.SUPPNAME.setValue(toArray[1]);
      // });
}
ngOnDestroy() {
  this.subscription.unsubscribe();
}
}