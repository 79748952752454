import { Injectable } from '@angular/core';  
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams } from '@angular/common/http';  

import {  Alertdetails } from '../Models/alertdetails';
import { Observable,of } from 'rxjs';
// import { HttpHeaders } from "@angular/common/http";
// import { catchError, tap ,map} from 'rxjs/operators';

// import { DatePipe } from '@angular/common';

import { environment } from '../../environments/environment';
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
  translateAggregateResults,
  DataResult,
  DataSourceRequestState,
  orderBy,
  CompositeFilterDescriptor,
  filterBy,
  toDataSourceRequest
} from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid'
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
@Injectable({
  providedIn: 'root'
}) 
export class AlertserviceService {
  public filter: CompositeFilterDescriptor;
  public myFrmData = new HttpParams(); 


  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService,private commonservice:CommonserviceService) { 
    this.apiUrl=env.baseUrl;
  }
  GetFolderDetailsDetails():Observable<Alertdetails> { 
    const url = `${this.apiUrl}`+'GetAllFolderDetails';    
    return this.http.get<Alertdetails>(url);  

  }
  setReminder(reminder: any): Observable<any>
  {
    reminder.AlertDate=this.commonservice.ConvertToLocaleDate(reminder.AlertDate);
    const url = `${this.apiUrl}`+'SaveReminder';///${id}
    return this.http.post(url, reminder, httpOptions).pipe(
    );
  }
  GetReminderByID(alertID):Observable<any> { 
    const url = `${this.apiUrl}`+'GetReminderByID'+ `/${alertID.ID}`+ `/${alertID.Status}`;    
    return this.http.get<any>(url);  
  }

}


