import { Component, OnInit, Input, OnChanges, ViewChild, Renderer2, Output, EventEmitter } from '@angular/core';
import { FolderSummary } from '../../Models/folder-summary';
import { CustomerspecificfieldService } from '../../Services/customerspecificfield.service';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AddEvent, EditEvent, GridComponent } from '@progress/kendo-angular-grid';
import { ShareDataService } from '../../Services/share-data.service';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { Subscription } from 'rxjs';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

const formGroup = dataItem => new FormGroup({
  'LabelName': new FormControl(dataItem.LabelName),
  'labelData': new FormControl(dataItem.labelData),

});

const hasClass = (el, className) => new RegExp(className).test(el.className);

const isChildOf = (el, className) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

@Component({
  selector: 'app-customerspecific-edit',
  templateUrl: './customerspecific-edit.component.html',
  styleUrls: ['./customerspecific-edit.component.scss']
})
export class CustomerspecificEditComponent implements OnInit, OnChanges {
  @ViewChild('combo') combo: ComboBoxComponent
  private subscription: Subscription = new Subscription();
  gridData: [];
  @Input() custcode: any;
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  public formGroup: FormGroup;
  @ViewChild(GridComponent) private grid: GridComponent;
  public view: any[];
  private editedRowIndex: number;
  private isNew = false;
  public TotalRows: number = 0;
  public source: any;
  public data: any;
  public LabelDataSelectedItem: any;
  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private api: CustomerspecificfieldService, private formBuilder: FormBuilder, private renderer: Renderer2, private apiShared: ShareDataService) { }

  ngOnInit() {
    this.renderer.listen(
      "document",
      "click",
      ({ target }) => {
        if (!isChildOf(target, "k-grid")) {
          this.saveClick();
        }
      });
  }

  ngOnChanges() {
    this.getCustomerSpecificField(this.apiShared.orderNo, this.custcode);
  }
  getCustomerSpecificField(folderno, custCo) {
    if (custCo == "")
      custCo = 0
    this.subscription.add(this.api.getCustomerSpecificField(folderno, custCo, 1)
      .subscribe(data => {
        if (data != null && data != undefined) {
          this.gridData = data["ResponseData"];
          this.view = this.gridData;
          this.TotalRows = this.view.length;
        }
        else {
          this.gridData = [];
          this.view = this.gridData;
          this.TotalRows = 0;
        }
      }));
  }


  handleFilter(value) {
    this.data = this.source.filter((s) => s.LabelData.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    if (this.data.length == 0 && this.combo.isOpen) {
      this.combo.toggle(false);
    }
  }
  public selectedItemChange(value: any, dataItem): void {
    debugger
    if (value && value != '') {
      if (value.LabelData && value.LabelData != '') {
        this.LabelDataSelectedItem = this.source.find(item => item.LabelData == value.LabelData);
        dataItem.labelData = this.LabelDataSelectedItem.LabelData;
      }
      else {
        this.LabelDataSelectedItem = { LabelData: value, OrderId: 0 }
        dataItem.labelData = value;
      }
    }
    else {
      dataItem.labelData = '';
    }
    this.formGroup.controls["labelData"].setValue(dataItem.labelData)
  }
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      'LabelName': "",
      'labelData': "",
    });

    this.isNew = true;
    //  sender.addRow(this.formGroup);
    this.grid.addRow(this.formGroup);
  }

  public editHandler({ sender, colIndex, rowIndex, dataItem }: any): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.source = dataItem.LabelNameList;
    this.data = this.source.slice();
    if (dataItem.labelData && dataItem.labelData != "") {
      this.LabelDataSelectedItem = this.source.find(item => item.LabelData == dataItem.labelData);
      if (!this.LabelDataSelectedItem) {
        this.LabelDataSelectedItem = { LabelData: dataItem.labelData, OrderId: 0 }
      }
    } else {
      this.LabelDataSelectedItem = null;
    }
    this.saveRow();
    this.formGroup = formGroup(dataItem);

    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
    if (colIndex) {
      setTimeout(() => {
        (<HTMLElement>document.querySelector(`.k-grid-edit-row > td:nth-child(${colIndex + 1}) input`))
          .focus();
      });
    }

  }

  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  public editClick({ dataItem, rowIndex, columnIndex }: any): void {
    this.editHandler({
      dataItem: dataItem,
      rowIndex: rowIndex,
      colIndex: columnIndex,
      sender: this.grid
    });
  }

  public saveClick(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.saveRow();
  }

  private closeEditor(grid: GridComponent, rowIndex: number = this.editedRowIndex): void {

    this.isNew = false;
    if (grid != undefined)
      grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;

    this.notifyParent.emit(this.view);

  }

  private saveRow(): void {
    if (this.isInEditingMode) {
      this.save(this.formGroup.value, false);
    }

    this.closeEditor(this.grid);
  }

  public save(formreceipt: any, isNew: boolean): void {
    // alert(JSON.stringify(formreceipt));
    //  alert(this.TotalRows);
    if (this.TotalRows > 0) {

      {
        Object.assign(
          this.view.find(({ LabelName }) => LabelName === formreceipt.LabelName),
          formreceipt
        );
      }

    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
