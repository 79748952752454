export class BankPaymentDTO {
    PVRDATE: any;
    OPENINGMONEY: any;
    CLOSINGMONEY: any;
    CurrentBalance: any;
    LastBalance: any;
    LIMITMONEY: any;
    PETTYCASH: any;
    PaymentMethod:any;
    ChequeDate:any;
    ChequeNo:any;
    Drawnbank:any;
    GROSSTOTALMONEY: any;
    NETTAXMONEY: any;
    date: any;
    userId: any;
    pYearId: any;
    BankNACCODE:any;
    PDQCode:any;
    CardID:any;
    CardName:any;
    Uniquereference:any;
    returnField: any;
    LANQUAGE: any;
    BRID: any;
    CashAccountNACCODE: any;
    RXRATE: any;
    FCID: any;
    HCID: any;
    LUPDATE:any;
  }
  export class BankPaymentMasterDTO {
    ResponseData: {
      messageid: any;
      message: any;
      vono: any;
      sqnNo: any;
      success_bit: any;
      TAXCODE: any;
    };
  }
  export class CashPaymentBalceDTO {
    CashAccount: any;
    CurrentDate: Date;
    VrType: any;
    CurrentBalance: any;
    LastBalance: any;
    BRID: any;
  }
  export class BankReceiptOrPaymentPostXMLDetailClass{
     HCDebitAmount: any;
     HCCreditAmount: any;
     CostCentreId: any;
     NacCode: any;
     BranchId: any;
     BaId: any;
     ProfitId: any;
     Narration: any;
     Reference: any;
     CreditAmount: any;
     DebitAmount: any;
     HCurency: any;
     SLNO: any;
     UserId: any;
     VoucherNo: any;
     FCurrency: any;
     FCurrencyExRate: any;
     TaxAmt: any;
     taxNacCode: any;
  }
  export class BankPaymentMasterGridDTO {
      CDate:any;
    index: any;
    NACCODE: any;
    NARRATION: any;
    NET: any;
    TAXNO: any;
    TAXRATE: any;
    TAXAMOUNT: any;
    GROSS: any;
    BRANCHID: any;
    COSTCENTER: any;
    PROFITCENTER: any;
    PBUSINESSAREA: any;
    TaxNACCODE: any;
    ACCOUNTNAME: any;
    NETHC: any;
    VATHC: any;
    TotalHC: any;
    TotalTax:any;
  }

  