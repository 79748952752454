import { CustomerReceiptDetailXMLClass } from './customer-receipt-detail-xmlclass';
export class CustomerReceiptPost {

        Id: number;
           CustCode: number;
          TotalAmount: number;
          BRID: string;
           UID: number;
          TransactionType: string;
          Reference: string;
          FolderNo: string;
          PostDate: string;
          FCURID: string;
           ForeignCurrencAmt: number;
          ExRate: number;
           VRTYPE: number;
           INVNO: number;
          AVRTYPE: number;
          AYEARID: string;
          PROJNO: string;
	      BankPayMethod: number;
          BankCode: number;
          AllocatedAmt: number;
          UnAllocatedAmt: number;
           FCAllocatedAmt: number;
          FCUnAllocatedAmt: number;        
         ChequeDate: Date;
          ChequeNo: string;
          DrawnbankName: string;
          HCBalance: number;
          PaymentReference: string;
        ExchangeGainLossFlag: number;
         BacsDate: Date;
         CurrentDate: Date;
         HCAmount:number;
         AllocatedAmount:number;
         Assign:any;
         ReceiptAmt:any;
         FCReceiptAmt:any;
         HoldInvNo:any;
         CardName :string;   
         CardType:string;

          cardno : number;
          cardid : number;
          cardNumber : string;
          ValidFrom : string ;
          validTo : string;
          IssueNo : string;
          SecurityCode : string;
          CommissionAmount : string;        
          CreditCardAmount : number;
          CreditPer : number;
          creditTotalAmount : number;
          invoiceAmount :number;
          AuthorizationCode:string; 
            UniqueReference:string;
            holdDocNo:any;
            RewardPoint:any=0;
            Rate:any=0;
            Cardholder:any;
            Membershipno:any;
         CustomerReceiptDetailXMLClass:CustomerReceiptDetailXMLClass[];
}
