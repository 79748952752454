export class AirlineDetailedReportDTO {
    CreateDateFrom: any;
    CreateDateTo: any;
    TicketDateFrom: any;
    TicketDateTo: any;
    TravelDateFrom: any;
    TravelDateTo: any;
    FareCommFrom: any;
    FareCommTo: any;
    Airline: any;
    AirlineName: any;
    ClassType: any;
    CustPaymentMethod: any;
    Customer: any;
    CustomerName: any;
    SupplierName: any;
    SuppName: any;
    DestinationFrom: any;
    Destination: any;
    Branch: any;
    BranchCode: any;
    BookedBy: any;
    PaymentMethodName: any;
    PCC: any;
    UserId: any;
    ModuleName: any;
    DestinationCntry: any;
    OriginCountry: any;
    Destinationcountry: any;
    origin: any;
    FolderChangeDateFrom: any;
    FolderChangeDateTo: any;
    showDeleted: any;
    ExportPDFParam: any;
    RptHdrSel_ModuleName: any;
    RptHdrSel_AutoNo: any;
    RptHdrSel_UserId: any;
}
export class AirlineDetailedReportResultDTO {
    FOLDER_NO: any;
    ORDER_NO: any;
    DATE_OF_ISSUE: any;
    PAX_NAME: any;
    TKT_NO: any;
    PAX_TYPE: any;
    PNR: any;
    TKT_TYPE: any;
    ORG: any;
    DEST: any;
    BASE_FARE: any;
    TAX1_TYPE: any;
    TAX1_AMT: any;
    TAX2_TYPE: any;
    TAX2_AMT: any;
    TAX3_TYPE: any;
    TAX3_AMT: any;
    TAX4_TYPE: any;
    TAX4_AMT: any;
    TOTAL: any;
    TRAVEL_DATE: any;
    Returndate: any;
    CustomerCode: any;
    CUSTOMER: any;
    ISSUED_BY: any;
    BOOKED_BY: any;
    JOURNEY_TYPE: any;
    AIRID: any;
    AIRNAME: any;
    AIRLINE_CLASS: any;
    AIRLINE_CODE: any;
    AIRLINE_PNR: any;
    Branch_ID: any;
    AIRLINE_PCC: any;
    FLTNO_OUTBOUND: any;
    FLTNO_INBOUND: any;
    PRTYUSRCODE: any;
    CLASSNAME: any;
    FareCommAmt: any;
    FareCommPer: any;
    PaymentMethod: any;
    FAREBASIS: any;
    CommAmountHC: any;
    AirlineAcomm: any;
    SellAmountHC: any;
    MileageInfo: any;
    Receipt: any;
    Status: any;
    MarkUP: any;

}