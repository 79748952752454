import { Component, OnInit, ViewChild } from '@angular/core';
import { FolderActivityReportbyProductService } from "../Services/folder-activity-reportby-product-service";
import { FolderActivityReportbyProductDTO } from "../Models/folder-activity-reportby-product-dto";
import { Subscription } from "rxjs";
import { CommonserviceService } from "../Services/commonservice.service";
import { LookUpDetailsService } from '../Services/look-up-details.service';
import { ShareDataService } from '../Services/share-data.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LookUpDetails } from '../Services/look-up-details';
import { DomSanitizer } from "@angular/platform-browser";
import { aggregateBy, State, process } from "@progress/kendo-data-query";
import { exportPDF, Group } from "@progress/kendo-drawing";
import { Workbook } from "@progress/kendo-angular-excel-export";
import { EmailService } from "../Services/email.service";
import { saveAs } from "@progress/kendo-file-saver";
import { IntlService } from "@progress/kendo-angular-intl";
import { FileService } from "../Services/file.service";
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
@Component({
  selector: 'app-folder-activity-reportby-product-summary',
  templateUrl: './folder-activity-reportby-product-summary.component.html',
  styleUrls: ['./folder-activity-reportby-product-summary.component.scss']
})
//#Caseid:13175 Folder Activity Report By Product
export class FolderActivityReportbyProductSummaryComponent implements OnInit {

  @ViewChild("pdf", { read: false, static: false }) pdf;
  constructor(
    private lookupApi: LookUpDetailsService,
    public intl: IntlService,
    public common: CommonserviceService,
    private apiShared: ShareDataService,
    public email: EmailService,
    private sanitizer: DomSanitizer,
    public FldrActivityReportbyProductService: FolderActivityReportbyProductService,
    private translateapi: TranslateService,
    private toastr: ToastrService,
    private emailapi: FileService,
    private router: Router
  ) { }
  public aggregates: any[] = [

    { field: "BUYAMT", aggregate: "sum" },
    { field: "COMMAMT", aggregate: "sum" },
    { field: "SELLAMT", aggregate: "sum" },
    { field: "CREDITAMT", aggregate: "sum" },
    { field: "AGTCOMSUM", aggregate: "sum" },
    { field: "AGTCOMVAT", aggregate: "sum" },
    { field: "VATAMT", aggregate: "sum" },
    { field: "Total", aggregate: "sum" },
  ];
  formData: FolderActivityReportbyProductDTO;
  public opened: any = false;
  private subscription: Subscription = new Subscription();
  public gridData: any = [];
  //public state: State;
  public showMsg: any;
  userID: any = 0;
  duplicatetemp: any = false;
  RptHdrSel_TemplateName: any = "";
  RptHdrSel_AutoNo: any = '';
  submitted: any = false;
  public showtotal = false;
  opentemplate: any = false;
  TempList: Array<LookUpDetails["ResponseData"]> = [];
  public TemplateSelectedItem: LookUpDetails["ResponseData"];
  public hidegridbutton: any = false;
  public column: any = "PMUSERCODE,PMNAME,BUYAMT,COMMAMT,SELLAMT,VATAMT,CREDITAMT,AGTCOMSUM,AGTCOMVAT,Total";
  PMUSERCODEHidden: any = false;
  PMNAMEHidden: any = false;
  CUSTUSRCODEHidden: any = false;
  BUYAMTHidden: any = false;
  COMMAMTHidden: any = false;
  SELLAMTHidden: any = false;
  VATAMTHidden: any = false;
  CREDITAMTHidden: any = false;
  AGTCOMSUMHidden: any = false;
  AGTCOMVATHidden: any = false;
  TotalHidden: any = false;

  selectAll: any = false;
  PMUSERCODEChk: any = false;
  PMNAMEChk: any = false;
  CUSTUSRCODEChk: any = false;
  CREDITAMTChk: any = false;
  BUYAMTChk: any = false;
  COMMAMTChk: any = false;
  SELLAMTChk: any = false;
  VATAMTChk: any = false;
  AGTCOMSUMChk: any = false;
  AGTCOMVATChk: any = false;
  TotalChk: any = false;

  public FolderQueryList: any;
  public total: any;
  defaultItem: any;
  fromId: string;
  bccId: string;
  actionsLayout: any
  public state: State = {
    skip: 0,
    take: 5,
    // group: [{ field: "PMNAME" }]
  };
  ngOnInit(): void {
    this.email.Isgridpdf = false;
    this.userID = +this.apiShared.uid;
    this.formData = this.FldrActivityReportbyProductService.formData;
    this.subscription.add(
      this.emailapi.GetReportEmail(this.userID).subscribe(data => {
        (this.fromId = data.ResponseData.FROMEMAIL),
          (this.bccId = data.ResponseData.DEFAULTBCCMAIL);
      })
    );

    this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_UserId = this.userID;
    this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ModuleName =
      "FolderActivityReportbyProductSummary";
    this.LoadTemplate();
    this.GetReportHeaderFooter();
    if (this.FldrActivityReportbyProductService.SummaryGridData.length > 0)
      this.showtotal = true;
    else this.showtotal = false;
    this.gridData = this.FldrActivityReportbyProductService.SummaryGridData;
    this.FolderQueryList = this.gridData;

    this.gridData = process(this.FolderQueryList, this.state);

    if (this.FolderQueryList != null && this.FolderQueryList.length > 0) {
      debugger;
      this.total = aggregateBy(this.FolderQueryList, this.aggregates);
    }
    sessionStorage.setItem('Componentname', "FolderActivityReportbyProduct");
  }
  public LoadTemplate() {
    debugger;

    this.subscription.add(
      this.FldrActivityReportbyProductService.GetTemplate().subscribe(data => {
        if (data != null && data != undefined) {
          this.TempList = data["ResponseData"];
          if (this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_AutoNo > 0) {
            this.TemplateSelectedItem = this.TempList.find(
              x =>
                x.UsrCode ==
                this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_AutoNo
            );
          }
          else {
            debugger;
            this.TemplateSelectedItem = this.TempList[0];
          }
        }
      })
    );
  }

  public GetReportHeaderFooter() {
    this.subscription.add(
      this.FldrActivityReportbyProductService
        .GetReportHeaderFooter(this.userID, 0)
        .subscribe(data => {
          debugger;
          let objectURL = "data:image/png;base64," + data["ResponseData"];

          //  this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.LoadReportColumn();
        })
    );
  }
  public LoadReportColumn() {

    this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_UserId = this.userID;
    this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ModuleName =
      "FolderActivityReportbyProductSummary";
    this.subscription.add(
      this.FldrActivityReportbyProductService.GetGridColumn().subscribe(data => {
        debugger;
        if (
          data != undefined &&
          data != null &&
          data["ResponseData"] != undefined
        ) {
          this.TemplateSelectedItem = this.TempList.find(
            x => x.UsrCode == data["ResponseData"].RptHdrSel_AutoNo
          );

          if (data["ResponseData"].RptHdrSel_ColumnHeader != null) {
            var datas = data["ResponseData"].RptHdrSel_ColumnHeader.split(",");


            if (datas.includes("PMUSERCODE")) {
              this.PMUSERCODEHidden = false;
            } else {
              this.PMUSERCODEHidden = true;
            }
            if (datas.includes("PMNAME")) {
              this.PMNAMEHidden = false;
            } else {
              this.PMNAMEHidden = true;
            }

            if (datas.includes("BUYAMT")) {
              this.BUYAMTHidden = false;
            } else {
              this.BUYAMTHidden = true;
            }

            if (datas.includes("COMMAMT")) {
              this.COMMAMTHidden = false;
            } else {
              this.COMMAMTHidden = true;
            }
            if (datas.includes("SELLAMT")) {
              this.SELLAMTHidden = false;
            } else {
              this.SELLAMTHidden = true;
            }
            if (datas.includes("VATAMTHidden")) {
              this.VATAMTHidden = false;
            } else {
              this.VATAMTHidden = true;
            }

            if (datas.includes("CREDITAMT")) {
              this.CREDITAMTHidden = false;
            } else {
              this.CREDITAMTHidden = true;
            }

            if (datas.includes("AGTCOMSUM")) {
              this.AGTCOMSUMHidden = false;
            } else {
              this.AGTCOMSUMHidden = true;
            }


            if (datas.includes("AGTCOMVAT")) {
              this.AGTCOMVATHidden = false;
            } else {
              this.AGTCOMVATHidden = true;
            }
            if (datas.includes("Total")) {
              this.TotalHidden = false;
            } else {
              this.TotalHidden = true;
            }

          }
        } else {
          this.enableAllColumn();
        }
      })
    );
  }
  public enableAllColumn() {
    this.PMUSERCODEHidden = false;
    this.PMNAMEHidden = false;

    this.BUYAMTHidden = false;
    this.COMMAMTHidden = false;
    this.SELLAMTHidden = false;
    this.VATAMTHidden = false;
    this.CREDITAMTHidden = false;
    this.AGTCOMSUMHidden = false;
    this.AGTCOMVATHidden = false;
    this.TotalHidden = false;

  }
  CreateTemplate() {
    debugger;
    this.PMUSERCODEChk = false;
    this.PMNAMEChk = false;

    this.CREDITAMTChk = false;
    this.BUYAMTChk = false;
    this.COMMAMTChk = false;
    this.SELLAMTChk = false;
    this.VATAMTChk = false;
    this.AGTCOMSUMChk = false;
    this.AGTCOMVATChk = false;
    this.TotalChk = false;


    this.selectAll = false;
    this.RptHdrSel_TemplateName = null;
    this.submitted = false;
    this.opentemplate = true;
    this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_AutoNo = 0;
  }

  public selectAllColumn() {
    if (this.selectAll == true) {
      this.PMUSERCODEChk = true;
      this.PMNAMEChk = true;
      this.CUSTUSRCODEChk = true;
      this.CREDITAMTChk = true;
      this.BUYAMTChk = true;
      this.COMMAMTChk = true;
      this.SELLAMTChk = true;
      this.VATAMTChk = true;
      this.AGTCOMSUMChk = true;
      this.AGTCOMVATChk = true;
      this.TotalChk = true;


    } else {

      this.PMUSERCODEChk = false;
      this.PMNAMEChk = false;
      this.CUSTUSRCODEChk = false;
      this.CREDITAMTChk = false;
      this.BUYAMTChk = false;
      this.COMMAMTChk = false;
      this.SELLAMTChk = false;
      this.VATAMTChk = false;
      this.AGTCOMSUMChk = false;
      this.AGTCOMVATChk = false;
      this.TotalChk = false;
    }
  }
  selectAlldisable() {

    if (
      (this.PMUSERCODEChk == true) &&
      (this.PMNAMEChk == true) &&
      (this.CUSTUSRCODEChk == true) &&
      (this.CREDITAMTChk == true) &&
      (this.BUYAMTChk == true) &&
      (this.COMMAMTChk == true) &&
      (this.SELLAMTChk == true) &&
      (this.VATAMTChk == true) &&
      (this.AGTCOMSUMChk == true) &&
      (this.AGTCOMVATChk == true) &&
      (this.TotalChk == true)) {
      this.selectAll = true;
    } else {
      this.selectAll = false;

    }

  }
  EditTemplate() {
    debugger;

    if (this.TemplateSelectedItem == null || this.TemplateSelectedItem == undefined) {
      this.subscription.add(this.translateapi.get(['Messages.template_required']).subscribe((translations) => {

        this.showMsg = translations['Messages.template_required'];;
        this.toastr.error(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })

      }));

      return;
    }
    this.GetVarientTemplateForEdit();

  }
  public dataStateChange(state: DataStateChangeEvent): void {
    debugger;

    if (state && state.group) {
      state.group.map(group => group.aggregates = this.aggregates);
    }
    this.state = state;
    this.gridData = process(this.FldrActivityReportbyProductService.SummaryGridData, this.state);
    // this.dataStateChangeColumnVisible(state);
  }



  GetVarientTemplateForEdit() {
    debugger;
    this.FldrActivityReportbyProductService.GetVarientTemplateForEdit(this.TemplateSelectedItem.Code, this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ModuleName).subscribe(data => {
      this.opentemplate = true;
      this.RptHdrSel_TemplateName = data["ResponseData"].RptHdrSel_TemplateName;
      this.RptHdrSel_AutoNo = data["ResponseData"].RptHdrSel_AutoNo;
      this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_AutoNo = this.RptHdrSel_AutoNo;
      if (data != null && data != undefined) {

        if (data["ResponseData"].RptHdrSel_ColumnHeader != null) {
          var datas = data["ResponseData"].RptHdrSel_ColumnHeader.split(',');
          "PMUSERCODE,PMNAME,BUYAMT,COMMAMT,SELLAMT,VATAMT,CREDITAMT,AGTCOMSUM,AGTCOMVAT,Total";
          if (datas.includes("PMUSERCODE")) {
            this.PMUSERCODEChk = true;
          } else {
            this.PMUSERCODEChk = false;
          }
          if (datas.includes("PMNAME")) {
            this.PMNAMEChk = true;
          } else {
            this.PMNAMEChk = false;
          }

          if (datas.includes("BUYAMT")) {
            this.BUYAMTChk = true;
          } else {
            this.BUYAMTChk = false;
          }

          if (datas.includes("COMMAMT")) {
            this.COMMAMTChk = true;
          } else {
            this.COMMAMTChk = false;
          }
          if (datas.includes("SELLAMT")) {
            this.SELLAMTChk = true;
          } else {
            this.SELLAMTChk = false;
          }
          if (datas.includes("VATAMT")) {
            this.VATAMTChk = true;
          } else {
            this.VATAMTChk = false;
          }

          if (datas.includes("CREDITAMT")) {
            this.CREDITAMTChk = true;
          } else {
            this.CREDITAMTChk = false;
          }

          if (datas.includes("AGTCOMSUM")) {
            this.AGTCOMSUMChk = true;
          } else {
            this.AGTCOMSUMChk = false;
          }


          if (datas.includes("AGTCOMVAT")) {
            this.AGTCOMVATChk = true;
          } else {
            this.AGTCOMVATChk = false;
          }
          if (datas.includes("Total")) {
            this.TotalChk = true;
          } else {
            this.TotalChk = false;
          }

        }

      }


    })
  }
  onSave() {

    this.submitted = true;
    this.userID = +this.apiShared.uid;
    if (this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_AutoNo == 0) {

      if (
        this.RptHdrSel_TemplateName == null ||
        this.RptHdrSel_TemplateName == undefined ||
        this.RptHdrSel_TemplateName == ""
      ) {
        return;
      } else {
        if (this.selectAll == true) {
          this.column = "PMUSERCODE,PMNAME,BUYAMT,COMMAMT,SELLAMT,VATAMT,CREDITAMT,AGTCOMSUM,AGTCOMVAT,Total";
        } else {
          this.column = "";

          if (this.PMUSERCODEChk == true) {
            this.column = this.column + ",PMUSERCODE";
          }
          if (this.PMNAMEChk == true) {
            this.column = this.column + ",PMNAME";
          }


          if (this.BUYAMTChk == true) {
            this.column = this.column + ",BUYAMT";
          }
          if (this.COMMAMTChk == true) {
            this.column = this.column + ",COMMAMT";
          }
          if (this.SELLAMTChk == true) {
            this.column = this.column + ",SELLAMT";
          }
          if (this.VATAMTChk == true) {
            this.column = this.column + ",VATAMT";
          }

          if (this.CREDITAMTChk == true) {
            this.column = this.column + ",CREDITAMT";
          }
          if (this.AGTCOMSUMChk == true) {
            this.column = this.column + ",AGTCOMSUM";
          }
          if (this.AGTCOMVATChk == true) {
            this.column = this.column + ",AGTCOMVAT";
          }
          if (this.TotalChk == true) {
            this.column = this.column + ",Total";
          }


          this.column = this.column.replace(/,\s*$/, "");
          this.column = this.column.replace(/^,/, "");
        }
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_UserId = this.userID;
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ModuleName =
          "FolderActivityReportbyProductSummary";
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ColumnHeader = this.column;
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_TemplateName = this.RptHdrSel_TemplateName;
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_CustCo = 0;
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_AutoNo = 0;
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
          new Date()
        );
        this.subscription.add(
          this.FldrActivityReportbyProductService.saveOrUpdateReportHeader().subscribe(data => {
            debugger;
            if (data != undefined && data != null) {
              if (data["ResponseData"]["RptHdrSel_AutoNo"] > 0) {
                this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_AutoNo =
                  data["ResponseData"]["RptHdrSel_AutoNo"];
                this.LoadTemplate();
                this.LoadReportColumn();
                this.opentemplate = false;
                this.duplicatetemp = false;
              } else {
                this.duplicatetemp = true;
              }
            }
          })
        );
      }
    }

    else {
      if (this.RptHdrSel_TemplateName == null || this.RptHdrSel_TemplateName == undefined || this.RptHdrSel_TemplateName == "") {
        return
      }
      else if (this.selectAll == true) {
        this.column = "PMUSERCODE,PMNAME,BUYAMT,COMMAMT,SELLAMT,VATAMT,CREDITAMT,AGTCOMSUM,AGTCOMVAT,Total";
      } else {
        this.column = "";

        if (this.PMUSERCODEChk == true) {
          this.column = this.column + ",PMUSERCODE";
        }
        if (this.PMNAMEChk == true) {
          this.column = this.column + ",PMNAME";
        }

        if (this.BUYAMTChk == true) {
          this.column = this.column + ",BUYAMT";
        }
        if (this.COMMAMTChk == true) {
          this.column = this.column + ",COMMAMT";
        }
        if (this.SELLAMTChk == true) {
          this.column = this.column + ",SELLAMT";
        }
        if (this.VATAMTChk == true) {
          this.column = this.column + ",VATAMT";
        }

        if (this.CREDITAMTChk == true) {
          this.column = this.column + ",CREDITAMT";
        }
        if (this.AGTCOMSUMChk == true) {
          this.column = this.column + ",AGTCOMSUM";
        }
        if (this.AGTCOMVATChk == true) {
          this.column = this.column + ",AGTCOMVAT";
        }
        if (this.TotalChk == true) {
          this.column = this.column + ",Total";
        }
        this.column = this.column.replace(/,\s*$/, "");
        this.column = this.column.replace(/^,/, "");
      }
      this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_UserId = this.userID;
      this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ModuleName =
        "FolderActivityReportbyProductSummary";
      this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ColumnHeader = this.column;
      this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_TemplateName = this.RptHdrSel_TemplateName;
      this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_CustCo = 0;
      this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_AutoNo = this.RptHdrSel_AutoNo;
      this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
        new Date()
      );
      this.subscription.add(
        this.FldrActivityReportbyProductService.saveOrUpdateReportHeader().subscribe(data => {
          debugger;
          if (data != undefined && data != null) {
            if (data["ResponseData"]["RptHdrSel_AutoNo"] > 0) {
              this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_AutoNo =
                data["ResponseData"]["RptHdrSel_AutoNo"];
              this.LoadTemplate();
              this.LoadReportColumn();
              this.opentemplate = false;
              this.duplicatetemp = false;
            } else {
              this.duplicatetemp = true;
            }
          }
        })
      );

    }

  }
  closeFolderActivityByProductReport() {
    this.opentemplate = false;
  }
  async dataStateChangeColumnVisible(value: any) {
    debugger;
    this.column = "PMUSERCODE,PMNAME,BUYAMT,COMMAMT,SELLAMT,VATAMT,CREDITAMT,AGTCOMSUM,AGTCOMVAT,Total";
    if (value["columns"] != null && value["columns"].length > 0) {
      value["columns"].forEach(obj => {
        if (obj["hidden"] == true) {
          this.column = this.column.replace(obj["field"], "");
          this.column = this.column.replace(/,\s*$/, "");
          this.column = this.column.replace(/^,/, "");
        } else {
        }
      });
      if (this.TemplateSelectedItem != undefined) {
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_AutoNo = this.TemplateSelectedItem.UsrCode;
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_UserId = this.userID;
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ModuleName =
          "FolderActivityReportbyProductSummary";
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_ColumnHeader = this.column;
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_TemplateName =
          "FolderActivityReportbyProductSummary";
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_CustCo = 0;
        this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
          new Date()
        );
        this.subscription.add(
          this.FldrActivityReportbyProductService
            .saveOrUpdateReportHeader()
            .subscribe(data => { })
        );
      } else {

      }
    }
  }
  templateChange(value) {

    debugger; //UsrCode
    if (value != undefined) {
      this.FldrActivityReportbyProductService.formDataHeader.RptHdrSel_AutoNo = value.UsrCode;

      this.LoadReportColumn();
    }
  }

  async export(pdfComponent: any) {
    let processedUri: any;
    let baseprocess = await pdfComponent
      .export()
      .then((group: Group) => exportPDF(group))
      .then(dataUri => {
        const base64 = dataUri.replace("data:application/pdf;base64,", "");
        processedUri = dataUri;
      });
    const fileObject = await this.dataURLtoFile(
      processedUri,
      "Folder Activity Report by Product Summary.pdf"
    );
    return fileObject;
  }
  async dataURLtoFile(dataurl, filename) {
    let file: any;
    let dataURL = new Promise(resolve => {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let newFile = new File([u8arr], filename, { type: mime });
      file = newFile;
      resolve(newFile);
    });
    this.email.fileurl = file;
    this.email.filename = "Folder Activity Report by Product Summary.pdf";
    this.email.reportname = "Folder Activity Report by Product Summary";
    this.open();
    return file;
  }
  public open() {

    this.opened = true;
  }
  public close(status) {
    this.opened = false;
    this.email.fileurl = null;
    this.email.filename = null;
    this.email.reportname = null;
  }
  EmailPDF() {
    debugger;
    this.email.Isgridpdf = true;
    this.export(this.pdf);
  }
  excel() {

    this.email.filename = "Folder Activity Report by Product Summary.xlsx";
    this.email.reportname = "Folder Activity Report by Product Summary";

    this.open();
    //args.preventDefault();
  }
  public async onExcelExport2(args: any) {
    debugger;

    this.email.filename = "Folder Activity Report by Product Summary.xlsx";
    this.email.reportname = "Folder Activity Report by Product Summary";

    // Prevent automatically saving the file. We will save it manually after we fetch and add the details
    args.preventDefault();

    const observables = [];
    let workbook = args.workbook;

    let rows = workbook.sheets[0].rows;

    let headerOptions = rows[0].cells[0];

    var reader = new FileReader();

    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      debugger;
      saveAs(dataUrl, "Folder Activity Report by Product Summary.xlsx");

      //  this.email.fileurl=this.dataURItoBlob( reader.result);
      this.email.fileurl = this.dataURItoBlob(dataUrl);


    });
  }
  dataURItoBlob(dataURI) {

    var byteString = atob(dataURI.split(",")[1]);


    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];


    var ab = new ArrayBuffer(byteString.length);



    var ia = new Uint8Array(ab);


    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }


    var blob = new Blob([ab], { type: mimeString });

    return blob;
  }
  public EmailFormClosing(
    status: any
  ) {
    if (status == "EmailSendSuccesfully" || status == "Closed") {

      this.opened = false;
    }
  }
  public onTabSelect(e) {

  }
  onCancel() {
    var reporturl = sessionStorage.getItem("menuhighlight");
    this.FldrActivityReportbyProductService.SummaryGridData = "";
    if (reporturl != null) {
      this.router.navigate(["/Login"]);
    } else {
      this.router.navigate(["PenAir/Reports/rptfolderactivityproduct"]);
    }

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
