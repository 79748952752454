import { Component, OnInit ,Input} from '@angular/core';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { FormGroup,Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '../../Services/file.service';
import { ShareDataService } from '../../Services/share-data.service';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
@Component({
  selector: 'app-customerbankreceipt',
  templateUrl: './customerbankreceipt.component.html',
  styleUrls: ['./customerbankreceipt.component.scss']
})
 export  class CustomerbankreceiptComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public divCheque:boolean;
  public divChequeBacs:boolean;
  public divOthers:boolean=true;
  public  submitted=false;
  public divBacs:boolean;
  paymntMethod:string;
  public drwanbackerrormsg:string;
  public checkNoerrormsg:string;
  public bankerrormsg:string;
  public referenceerrormsg:string;
  public userID:number;
  
  @Input() parentForm:FormGroup
  bankList: Array<LookUpDetails["ResponseData"]> = [];  
  public bankselectedItem: LookUpDetails["ResponseData"];
  
  public paymentMethodList: Array<string> = ["Cheque", "Bacs", "Bank Transfer", "Others", "Direct Debit", "Debit Card"];
  constructor(public commonservice:CommonserviceService, private translateapi: TranslateService,private lookupApi:LookUpDetailsService,private formBuilder: FormBuilder,private apiShared: ShareDataService, public file:FileService) { }

  ngOnInit() {
    debugger;
    this.userID =+(this.apiShared.uid);
    this.subscription.add(this.translateapi.get(['Messages.Drawnbankisrequired']).subscribe((translations ) => {
            
      this.drwanbackerrormsg= translations['Messages.Drawnbankisrequired'];
   
     }));
     this.subscription.add( this.translateapi.get(['Messages.Checknoisrequired']).subscribe((translations ) => {
            
      this.checkNoerrormsg= translations['Messages.Checknoisrequired'];
   
     }));
     this.parentForm.controls.PaymentReference.setValue("Bank Transfer");

    //  this.translateapi.get(['Messages.Bankisrequired']).subscribe((translations ) => {
            
    //   this.bankerrormsg= translations['Messages.Bankisrequired'];
   
    //  });
    //  this.translateapi.get(['Messages.Referenceisrequired']).subscribe((translations ) => {
            
    //   this.referenceerrormsg= translations['Messages.Referenceisrequired'];
   
    //  });

     
  //   this.parentForm = this.formBuilder.group({
       
  //     BankCode:[],
  //     BankPayMethod:[""],
  //     // DrawnbankName:[""],
  //     ChequeNo:[""],
  //     ChequeDate:[new Date],
  //     PaymentReference:[""]
     
  // }); 

  this.subscription.add(this.lookupApi.GetAllLookup('BANK').subscribe(
      (data) => {
         this.bankList= data["ResponseData"]  

         this.subscription.add(this.file.GetDefaultBankCode(this.userID).subscribe(
          (data)=>{
           
            this.parentForm.controls.BankCode.setValue(data["ResponseData"]);
            this.bankselectedItem =this.bankList.find(item => item.Code ==this.parentForm.controls.BankCode.value);    
          }));

      }
      )); 


     
  }
 onBlur(a:any)
 {
   debugger;
   var datedata=this.parentForm.controls.ChequeDate.value
   if(datedata==null|| datedata==undefined||datedata=='')
   {
    this.file.ValidChequeDate=true;
   }
   else{
    this.file.ValidChequeDate=false;
   }
 }
  
  public bankChange(value: any): void {
    if(value!=null && value!=undefined)
    {
      
    this.parentForm.controls.BankCode.setValue( value.Code);
    this.file.ValidBankcode=false;
    }
    else
    {
    this.parentForm.controls.BankCode.setValue("");
    this.file.ValidBankcode=true;
    }
  }
  get f() { return this.parentForm.controls; }
public paymentMethodChange(value: any): void {
  
  const phoneControl = this.parentForm.get('ChequeNo');
  const DrawnbankNameControl = this.parentForm.get('PaymentReference');
 this.paymntMethod= this.parentForm.controls.BankPayMethod.value;
//  this.parentForm.controls.PaymentReferencevalue.setValue(this.paymntMethod);

 if(value=="Cheque")
 {
  this.divCheque=true;
  this.divChequeBacs=true;
  this.divOthers=false;
  this.divBacs=false;

  this.parentForm.controls.PaymentReference.setValue("");
 
  this.subscription.add(this.parentForm.get('BankPayMethod').valueChanges.subscribe(
      (mode: string) => {
         
          if (mode === 'Cheque') {
              phoneControl.setValidators([Validators.required]);
              DrawnbankNameControl.setValidators([Validators.required]);
          }
          else  {
            DrawnbankNameControl.clearValidators();
              phoneControl.clearValidators();
          }
          DrawnbankNameControl.updateValueAndValidity();
          phoneControl.updateValueAndValidity();
      }));
 }
 else if(value=="Bacs")
 {
  this.parentForm.controls.PaymentReference.setValue(value);
  phoneControl.clearValidators();
  DrawnbankNameControl.clearValidators();
   this.divChequeBacs=true;
   this.divOthers=true;
   this.divCheque=false;
   this.divBacs=true;

 }
 else{
  this.parentForm.controls.PaymentReference.setValue(value);
  phoneControl.clearValidators();
  DrawnbankNameControl.clearValidators();
  this.divOthers=true;
  this.divChequeBacs=false;
  this.divCheque=false;
  this.divBacs=false;
 }
}
public   ValidateCheque(value:any): boolean 
{
  debugger;
  this.file.chequeNovaild=false;
  this.file.PaymentReference=false;
 var paymntMethod= this.parentForm.controls.BankPayMethod.value;
  if(paymntMethod=='Cheque')
  {
    if(value==1)
    {
    if(this.parentForm.controls.ChequeNo.value==undefined||this.parentForm.controls.ChequeNo.value==null||this.parentForm.controls.ChequeNo.value=='')
    {
     this.file.chequeNovaild=true;
    }
  }
  if(value==2)
  {
    if(this.parentForm.controls.PaymentReference.value==undefined||this.parentForm.controls.PaymentReference.value==null||this.parentForm.controls.PaymentReference.value=='')
    {
     this.file.PaymentReference=true;
    }
    var chequedate = (this.parentForm.controls.ChequeDate.value == null || this.parentForm.controls.ChequeDate.value == "" ? "01-Jan-1900" : this.parentForm.controls.ChequeDate.value);//Case id-337
  }
   
    if (Boolean(this.commonservice.ValidDateRange(chequedate)) == false ||chequedate=='01-Jan-1900')//Case id-337
    {
   
    // this.postButtonDisable = false;
      this.file.ValidChequeDate=true;
   
      
    }
    else{
      this.file.ValidChequeDate=false;
    }
 if(this.file.ValidChequeDate==true|| this.file.chequeNovaild==true||this.file.PaymentReference==true)
 {

 return false;
 }
 else
{
  this.file.ValidChequeDate=false;
  this.file.chequeNovaild=false;
  this.file.PaymentReference=false;
  return true;;
}
}
}
ngOnDestroy() {
  console.log('ngOnDestory');
  this.subscription.unsubscribe();
} 
}
