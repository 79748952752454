import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FolderSummary } from '../Models/folder-summary';
import { CustomerspecificGrid } from '../Models/customerspecific-grid';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CustomerCreate, CustomerMaster, Login } from '../Models/customer-create.model';
import { LookUpDetails } from './look-up-details';
import { DefaultComboboxDTO } from '../Models/default-combobox-dto.model';
import { CommonserviceService } from './commonservice.service';
import { DocumentEmailDTO } from '../Models/document-email-dto';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class FolderSummaryService {
  apiUrl: string;
  public formData: CustomerMaster;
  // public CustomerMaster:CustomerMaster;
  public markcomm: any = 0;
  public defaultrpt: any;
  public SelectedDefaultinvoicefmt: any;
  UsrCode: String;
  Name: String;
  public CrRptformData: DocumentEmailDTO;

  public Login: Login;
  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }

  private newMethod(data: Object) {
    alert(data);
  }

  GetFolderDetailsById(folderNo: string, usrID: number): Observable<FolderSummary> {
    const url = `${this.apiUrl}` + 'GetFolderDetailsById' + `/${folderNo}/${usrID}`;
    return this.http.get<FolderSummary>(url).pipe(
      tap(_ => console.log(`fetched FolderSummary id=${folderNo}`)),
      catchError(this.commonservice.handleError<FolderSummary>(`GetFolderDetailsById id=${folderNo}`))
    );
  }
  AddFolderDetails(FolderSummary: any, CustomerspecificGrid: any): Observable<FolderSummary> {
    debugger;
    FolderSummary.CustomerSpecific = CustomerspecificGrid;
    //alert(JSON.stringify(FolderSummary));

    // if(FolderSummary.QCONDATE==null)
    // FolderSummary.QCONDATE="01-Jan-1900";
    // FolderSummary.INVDATE="01-Jan-1900";
    // FolderSummary.Optdate="01-Jan-1900";
    // FolderSummary.LUPDATE=new Date();
    // FolderSummary.UPLOADDTE=new Date();
    // FolderSummary.TPFTDate="01-Jan-1900";
    // FolderSummary.ReturnDate="01-Jan-1900";
    // FolderSummary.OptinDate=new Date();
    // FolderSummary.CRDATE=new Date();

    if (FolderSummary.QCONDATE == null)
      FolderSummary.QCONDATE = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
      FolderSummary.QCONDATE = this.commonservice.ConvertToLocaleDate(FolderSummary.QCONDATE);
    if (FolderSummary.BALDUEDATE == null)
      FolderSummary.BALDUEDATE = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
      FolderSummary.BALDUEDATE = this.commonservice.ConvertToLocaleDate(FolderSummary.BALDUEDATE);
    if (FolderSummary.DEPDUEDATE == null)
      FolderSummary.DEPDUEDATE = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
      FolderSummary.DEPDUEDATE = this.commonservice.ConvertToLocaleDate(FolderSummary.DEPDUEDATE);


    FolderSummary.INVDATE = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    FolderSummary.Optdate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    FolderSummary.LUPDATE = this.commonservice.ConvertToLocaleDate(new Date());
    FolderSummary.UPLOADDTE = this.commonservice.ConvertToLocaleDate(new Date());
    FolderSummary.TPFTDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    FolderSummary.ReturnDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    FolderSummary.OptinDate = this.commonservice.ConvertToLocaleDate(new Date());
    FolderSummary.CRDATE = this.commonservice.ConvertToLocaleDate(new Date());
    //FolderSummary.QCONDATE=this.commonservice.ConvertToLocaleDate(new Date());
    // FolderSummary.FOLDERDATE=new Date().toLocaleDateString(); 

    // FolderSummary.DEPDUEDATE=new Date().toLocaleDateString();
    if (FolderSummary.FOLDERDATE == null)
      FolderSummary.FOLDERDATE = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
      FolderSummary.FOLDERDATE = this.commonservice.ConvertToLocaleDate(FolderSummary.FOLDERDATE)

    return this.http.post<FolderSummary>(this.apiUrl + 'AddFolderDetails', FolderSummary, httpOptions).pipe(
      tap((folderSumRes: FolderSummary) => console.log(`added FolderSummary w/ id=${folderSumRes.ResponseData.FOLDERNO}`)),
      catchError(this.commonservice.handleError<FolderSummary>('AddFolderDetails'))
    );
  }
  UpdateFolderMaster(FolderSummary: any, CustomerspecificGrid: any): Observable<FolderSummary> {
    FolderSummary.CustomerSpecific = CustomerspecificGrid;
    //alert(JSON.stringify(FolderSummary));
    // if(FolderSummary.QCONDATE==null)
    // FolderSummary.QCONDATE="01-Jan-1900";
    if (FolderSummary.FOLDERDATE == null)
      FolderSummary.FOLDERDATE = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
      FolderSummary.FOLDERDATE = this.commonservice.ConvertToLocaleDate(FolderSummary.FOLDERDATE);
    if (FolderSummary.QCONDATE == null)
      FolderSummary.QCONDATE = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
      FolderSummary.QCONDATE = this.commonservice.ConvertToLocaleDate(FolderSummary.QCONDATE);
    if (FolderSummary.BALDUEDATE == null)
      FolderSummary.BALDUEDATE = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
      FolderSummary.BALDUEDATE = this.commonservice.ConvertToLocaleDate(FolderSummary.BALDUEDATE);
    if (FolderSummary.DEPDUEDATE == null)
      FolderSummary.DEPDUEDATE = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
      FolderSummary.DEPDUEDATE = this.commonservice.ConvertToLocaleDate(FolderSummary.DEPDUEDATE);

    return this.http.post<FolderSummary>(this.apiUrl + 'UpdateFolderMaster', FolderSummary, httpOptions).pipe(
      tap((folderSumRes: FolderSummary) => console.log(`added FolderSummary w/ id=${FolderSummary.FOLDERNO}`)),
      catchError(this.commonservice.handleError<FolderSummary>('UpdateFolderMaster'))
    );
  }

  BusinessAreaId(custco: number, bookedBy: string): Observable<any> {
    const url = `${this.apiUrl}` + 'BusinessAreaId' + `/${custco}/${bookedBy}`;
    return this.http.get<FolderSummary>(url).pipe(
      tap(_ => console.log(`fetched FolderSummary id=${custco}`)),
      catchError(this.commonservice.handleError<FolderSummary>(`BusinessAreaId id=${custco}`))
    );
  }
  GetBranch(custco: number, bookedBy: string): Observable<any> {
    const url = `${this.apiUrl}` + 'GetBranch' + `/${custco}/${bookedBy}`;
    return this.http.get<FolderSummary>(url).pipe(
      tap(_ => console.log(`fetched FolderSummary id=${custco}`)),
      catchError(this.commonservice.handleError<FolderSummary>(`GetBranch id=${custco}`))
    );


  }

  GetCarCount(FolderNo: string): Observable<any> {
    const url = `${this.apiUrl}` + 'GetCarDetailsCount' + `/${FolderNo}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched FolderSummary id=${FolderNo}`)),
      catchError(this.commonservice.handleError<FolderSummary>(`GetCarCount id=${FolderNo}`))
    );
  }
  GetProductCount(FolderNo: string, Type: string): Observable<any> {
    const url = `${this.apiUrl}` + 'GetProductDetailsCount' + `/${FolderNo}/${Type}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched FolderSummary id=${FolderNo}`)),
      catchError(this.commonservice.handleError<FolderSummary>(`GetProductCount id=${FolderNo}`))
    );
  }
  GetHotelCount(FolderNo: string): Observable<any> {
    const url = `${this.apiUrl}` + 'GetHotelDetailsCount' + `/${FolderNo}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched FolderSummary id=${FolderNo}`)),
      catchError(this.commonservice.handleError<FolderSummary>(`GetCarCount id=${FolderNo}`))
    );
  }

  GetAutoCodeFlagforCustomer(): Observable<any> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    // var body = {
    //   ...data,

    // };
    return this.http.get<any>(this.apiUrl + 'GetAutoCodeFlagforCustomer');
  }
  GetAutoCodeFlagforPassenger(): Observable<any> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    // var body = {
    //   ...data,

    // };
    return this.http.get<any>(this.apiUrl + 'GetAutoCodeFlagforPassenger');
  }

  GetDefaultCountry(): Observable<LookUpDetails> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    // var body = {
    //   ...data,

    // };
    return this.http.get<LookUpDetails>(this.apiUrl + 'GetDefaultCountry');
  }
  GetDefaultLanguage(): Observable<LookUpDetails> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    // var body = {
    //   ...data,

    // };
    return this.http.get<LookUpDetails>(this.apiUrl + 'GetDefaultLanguage');
  }

  GetDefaultCurrency(): Observable<LookUpDetails> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    // var body = {
    //   ...data,

    // };
    return this.http.get<LookUpDetails>(this.apiUrl + 'GetDefaultCurrency');
  }

  SaveCustomerAddress(): Observable<any> {
    var body = {
      ...this.formData



    };
    return this.http.post<any>(this.apiUrl + 'AddCustomerProfile', body);
    //alert(JSON.stringify(FolderSummary));



  }
  GetActiveGDS(): Observable<any> {
    const url = `${this.apiUrl}` + 'GetActiveProviders';
    return this.http.get<FolderSummary>(url).pipe(
      catchError(this.commonservice.handleError<any>(`GetActiveProviders`))
    );
  }
  RetrievePNR(objPNRRetrieval: any): Observable<any> {
    const url = `${this.apiUrl}` + 'RetrievePNR';
    return this.http.post<FolderSummary>(url, objPNRRetrieval, httpOptions).pipe(
      catchError(this.commonservice.handleError<any>('RetrievePNR'))
    );
    //   let header = new HttpHeaders().set("Access-Control-Allow-Origin", "*");
    //   header.set('Content-Type', 'application/json');
    //   return this.http.post<any>('https://penserverdev2.pensupport.co.uk/PenIBE/PenGuinIBEGC/AgentArea/PenAirFolderCreation.aspx?GDS=Sabre&PNR=741074', {headers:header}).pipe(
    //   catchError(this.commonservice.handleError<any>('RetrievePNR'))
    // );
  }

  GetBookingConfirmationCReport(FolderNo: any): Observable<any> {

    const url = `${this.apiUrl}` + 'BookingConfirmationCReport' + `/${FolderNo}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`Get booking confirmation report`)),
      catchError(this.commonservice.handleError<any[]>(`Get booking confirmation report`))
    );
  }

  GetCrystalReport(): Observable<any> {
    var body = {
      ...this.CrRptformData
    };
    return this.http.post<any>(this.apiUrl + 'GetCrystalReport', body);
    //alert(JSON.stringify(FolderSummary));

  }
}
