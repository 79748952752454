import { Component, OnInit, ViewChild } from '@angular/core';
import { AirlineDetailedReport } from "../Services/airline-detailed-report";
import { AirlineDetailedReportDTO } from "../Models/airline-detailed-report-dto";
import { DataStateChangeEvent, SelectAllCheckboxState, GridDataResult, PageChangeEvent, RowArgs } from '@progress/kendo-angular-grid';
import { Subscription } from "rxjs";
import { CommonserviceService } from "../Services/commonservice.service";
import { LookUpDetailsService } from '../Services/look-up-details.service';
import { ShareDataService } from '../Services/share-data.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LookUpDetails } from '../Services/look-up-details';
import { DomSanitizer } from "@angular/platform-browser";
import { aggregateBy, State, process } from "@progress/kendo-data-query";
import { exportPDF, Group } from "@progress/kendo-drawing";
import { Workbook } from "@progress/kendo-angular-excel-export";
import { EmailService } from "../Services/email.service";
import { saveAs } from "@progress/kendo-file-saver";
import { IntlService } from "@progress/kendo-angular-intl";
import { FileService } from "../Services/file.service";
@Component({
  selector: 'app-rpt-airline-detailed-report',
  templateUrl: './rpt-airline-detailed-report.component.html',
  styleUrls: ['./rpt-airline-detailed-report.component.scss']
})
export class RptAirlineDetailedReportComponent implements OnInit {
  @ViewChild("pdf", { read: false, static: false }) pdf;


  public aggregates: any[] = [
    { field: "BASE_FARE", aggregate: "sum" },
    { field: "TAX1_AMT", aggregate: "sum" },
    { field: "TAX2_AMT", aggregate: "sum" },
    { field: "TAX3_AMT", aggregate: "sum" },
    { field: "TAX4_AMT", aggregate: "sum" },
    { field: "TOTAL", aggregate: "sum" },
    { field: "FareCommAmt", aggregate: "sum" },
    { field: "FareCommPer", aggregate: "sum" },

  ];
  public pageSize = 100;
  formData: AirlineDetailedReportDTO;
  public opened: any = false;
  private subscription: Subscription = new Subscription();
  public AirlineReportGridData: any;
  public showMsg: any;
  userID: any = 0;
  public pdftop: any;
  public gridHeight: any;
  image: any;
  RptHdrSel_TemplateName: any = "";
  RptHdrSel_AutoNo: any = '';
  submitted: any = false;
  public showtotal = false;
  opentemplate: any = false;
  TempList: Array<LookUpDetails["ResponseData"]> = [];
  public TemplateSelectedItem: LookUpDetails["ResponseData"];
  public hidegridbutton: any = false;
  public column: any = "FOLDER_NO,ORDER_NO,DATE_OF_ISSUE,PAX_NAME,TKT_NO,PAX_TYPE,PNR,TKT_TYPE,ORG,DEST,BASE_FARE,TAX1_TYPE,TAX1_AMT,TAX2_TYPE,TAX2_AMT,TAX3_TYPE,TAX3_AMT,TAX4_TYPE,TAX4_AMT,TOTAL,TRAVEL_DATE,CustomerCode,CUSTOMER,ISSUED_BY,BOOKED_BY,JOURNEY_TYPE,AIRID,AIRNAME,AIRLINE_CLASS,AIRLINE_CODE,AIRLINE_PNR,Branch_ID,AIRLINE_PCC,FLTNO_OUTBOUND,FLTNO_INBOUND,PRTYUSRCODE,CLASSNAME,FareCommAmt,FareCommPer,PaymentMethod,FAREBASIS,CommAmountHC,AirlineAcomm,SellAmountHC,MileageInfo,Receipt,Status,MarkUp,Returndate,TicketingDeadline,InterInd,GDS";
  FOLDERNOHidden: any = false;
  ORDERNOHidden: any = false;
  DATE_OF_ISSUEHidden: any = false;
  PAX_NAMEHidden: any = false;
  TKT_NOHidden: any = false;
  PAX_TYPEHidden: any = false;
  PNRHidden: any = false;
  TKT_TYPEHidden: any = false;
  ORGHidden: any = false;
  DESTHidden: any = false;
  BASE_FAREHidden: any = false;
  TAX1_TYPEHidden: any = false;
  TAX1_AMTHidden: any = false;
  TAX2_TYPEHidden: any = false;
  TAX2_AMTHidden: any = false;
  TAX3_TYPEHidden: any = false;
  TAX3_AMTHidden: any = false;
  TAX4_TYPEHidden: any = false;
  TAX4_AMTHidden: any = false;
  TOTALHidden: any = false;
  TRAVEL_DATEHidden: any = false;
  CustomerCodeHidden: any = false;
  CUSTOMERHidden: any = false;
  ISSUED_BYHidden: any = false;
  BOOKED_BYHidden: any = false;
  JOURNEY_TYPEHidden: any = false;
  AIRIDHidden: any = false;
  AIRNAMEHidden: any = false;
  AIRLINE_CLASSHidden: any = false;
  AIRLINE_CODEHidden: any = false;
  AIRLINE_PNRHidden: any = false;
  Branch_IDHidden: any = false;
  AIRLINE_PCCHidden: any = false;
  FLT_NO_OUTBOUNDHidden: any = false;
  FLT_NO_INBOUNDHidden: any = false;
  PRTYUSRCODEHidden: any = false;
  CLASSNAMEHidden: any = false;
  FareCommAmtHidden: any = false;
  FareCommPerHidden: any = false;
  PaymentMethodHidden: any = false;
  FAREBASISHidden: any = false;
  CommAmountHidden: any = false;
  AirlineAcommHidden: any = false;
  SellAmountHidden: any = false;
  MileageInfoHidden: any = false;
  ReceiptHidden: any = false;
  StatusHidden: any = false;
  MarkUPHidden: any = false;
  ReturnDateHidden: any = false;
  TicketingDeadlineHidden: any = false;
  InterIndHidden: any = false;
  GDSHidden: any = false;

  selectAll: any = false;
  FOLDERNOChk: any = false;
  ORDERNOChk: any = false;
  DATEOFISSUEChk: any = false;
  PAXNAMEChk: any = false;
  TKTNOChk: any = false;
  PAXTYPEChk: any = false;
  PNRChk: any = false;
  TKTTYPEChk: any = false;
  ORGChk: any = false;
  DESTChk: any = false;
  BASEFAREChk: any = false;
  TAX1TYPEChk: any = false;
  TAX1AMTChk: any = false;
  TAX2TYPEChk: any = false;
  TAX2AMTChk: any = false;
  TAX3TYPEChk: any = false;
  TAX3AMTChk: any = false;
  TAX4TYPEChk: any = false;
  TAX4AMTChk: any = false;
  TOTALChk: any = false;
  TRAVELDATEChk: any = false;
  CustomerCodeChk: any = false;
  CUSTOMERChk: any = false;
  ISSUEDBYChk: any = false;
  BOOKEDBYChk: any = false;
  JOURNEYTYPEChk: any = false;
  AIRIDChk: any = false;
  AIRNAMEChk: any = false;
  AIRLINECLASSChk: any = false;
  AIRLINECODEChk: any = false;
  AIRLINEPNRChk: any = false;
  BranchIDChk: any = false;
  AIRLINEPCCChk: any = false;
  FLTNOOUTBOUNDChk: any = false;
  FLTNOINBOUNDChk: any = false;
  PRTYUSRCODEChk: any = false;
  CLASSNAMEChk: any = false;
  FareCommAmtChk: any = false;
  FareCommPerChk: any = false;
  PaymentMethodChk: any = false;
  FAREBASISChk: any = false;
  CommAmountChk: any = false;
  AirlineAcommChk: any = false;
  SellAmountChk: any = false;
  MileageInfoChk: any = false;
  ReceiptChk: any = false;
  StatusChk: any = false;
  MarkUPChk: any = false;
  ReturnDateChk: any = false;
  TicketingDeadlineChk: any = false;
  InterIndChk: any = false;
  GDSChk: any = false;
  defaultItem: any;
  duplicatetemp: any = false;
  actionsLayout: any;
  public AirlineDetailedList: any;
  public total: any;

  fromId: string;
  bccId: string;


  constructor(
    private lookupApi: LookUpDetailsService,
    public intl: IntlService,
    public common: CommonserviceService,
    public apiShared: ShareDataService,
    public email: EmailService,
    private sanitizer: DomSanitizer,
    public AirlineDetailedReportService: AirlineDetailedReport,
    private translateapi: TranslateService,
    private toastr: ToastrService,
    private emailapi: FileService,
    private router: Router
  ) { }
  public state: State = {
    skip: 0,
    take: 100,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  ngOnInit(): void {
    debugger;
    this.email.Isgridpdf = false;
    this.userID = +this.apiShared.uid;
    this.formData = this.AirlineDetailedReportService.formData;
    this.subscription.add(
      this.emailapi.GetReportEmail(this.userID).subscribe(data => {
        (this.fromId = data.ResponseData.FROMEMAIL),
          (this.bccId = data.ResponseData.DEFAULTBCCMAIL);
      })
    );

    this.AirlineDetailedReportService.formDataHeader.RptHdrSel_UserId = this.userID;
    this.AirlineDetailedReportService.formDataHeader.RptHdrSel_ModuleName =
      "AirlineDetailedReport";
    this.LoadTemplate();
    this.GetReportHeaderFooter();
    this.AirlineReportGridData = this.AirlineDetailedReportService.griddata;
    // if (this.AirlineReportGridData != null && this.AirlineReportGridData.length > 0) {
    //   if (this.AirlineReportGridData.length > 12) {
    //     this.gridHeight = window.innerHeight * .85;
    //   }

    //   else {
    //     this.gridHeight = 130 + (33 * this.AirlineReportGridData.length - 1);
    //   }
    // }
    // else {
    //   this.gridHeight = 130;
    // }
    this.loadAirLineGrid();


    if (this.AirlineReportGridData != null && this.AirlineReportGridData.length > 0)
      this.total = aggregateBy(this.AirlineReportGridData, this.aggregates);
    if (this.AirlineReportGridData.length > 0) this.showtotal = true;
    else this.showtotal = false;
    if (this.AirlineDetailedReportService.formData.ExportPDFParam.length != undefined) {
      if (this.AirlineDetailedReportService.formData.ExportPDFParam.length > 0) {
        if (this.AirlineDetailedReportService.formData.ExportPDFParam.length <= 5) {
          this.pdftop = "3cm";
        }
        else if (this.AirlineDetailedReportService.formData.ExportPDFParam.length <= 13) {
          this.pdftop = "8cm";
        }
      }
    }
  }
  public LoadTemplate() {
    debugger;
    this.subscription.add(
      this.AirlineDetailedReportService.GetTemplate().subscribe(data => {
        if (data != null && data != undefined) {
          this.TempList = data["ResponseData"];
          if (this.AirlineDetailedReportService.formDataHeader.RptHdrSel_AutoNo > 0) {
            this.TemplateSelectedItem = this.TempList.find(
              x =>
                x.UsrCode ==
                this.AirlineDetailedReportService.formDataHeader.RptHdrSel_AutoNo
            );
          }
          else {
            debugger;
            this.TemplateSelectedItem = this.TempList[0];

          }
          this.templateChange(this.TemplateSelectedItem);
        }
      })
    );
  }
  selectAlldisable() {
    if (
      (this.FOLDERNOChk == true) &&
      (this.ORDERNOChk == true) &&
      (this.DATEOFISSUEChk == true) &&
      (this.PAXNAMEChk == true) &&
      (this.TKTNOChk == true) &&
      (this.PAXTYPEChk == true) &&
      (this.PNRChk == true) &&
      (this.TKTTYPEChk == true) &&
      (this.ORGChk == true) &&
      (this.DESTChk == true) &&
      (this.BASEFAREChk == true) &&
      (this.TAX1_TYPEHidden == true) &&
      (this.TAX1AMTChk == true) &&
      (this.TAX2TYPEChk == true) &&
      (this.TAX2AMTChk == true) &&
      (this.TAX3TYPEChk == true) &&
      (this.TAX3AMTChk == true) &&
      (this.TAX3AMTChk == true) &&
      (this.TAX4TYPEChk == true) &&
      (this.TAX4AMTChk == true) &&
      (this.TOTALChk == true) &&
      (this.TRAVELDATEChk == true) &&
      (this.CustomerCodeChk == true) &&
      (this.CUSTOMERChk == true) &&
      (this.ISSUEDBYChk == true) &&
      (this.BOOKEDBYChk == true) &&
      (this.JOURNEYTYPEChk == true) &&
      (this.AIRIDChk == true) &&
      (this.AIRNAMEChk == true) &&
      (this.AIRLINECLASSChk == true) &&
      (this.AIRLINECODEChk == true) &&
      (this.AIRLINEPNRChk == true) &&
      (this.BranchIDChk == true) &&
      (this.AIRLINEPCCChk == true) &&
      (this.FLTNOOUTBOUNDChk == true) &&
      (this.FLTNOINBOUNDChk == true) &&
      (this.PRTYUSRCODEChk == true) &&
      (this.CLASSNAMEChk == true) &&
      (this.FareCommAmtChk == true) &&
      (this.FareCommPerChk == true) &&
      (this.PaymentMethodChk == true) &&
      (this.FAREBASISChk == true) &&
      (this.FAREBASISChk == true) &&

      (this.AirlineAcommChk == true) &&
      (this.SellAmountChk == true) &&

      (this.MileageInfoChk == true) &&

      (this.ReceiptChk == true) &&

      (this.StatusChk == true) &&
      (this.MarkUPChk == true) && (this.ReturnDateChk == true) && (this.TicketingDeadlineChk == true) && (this.InterIndChk == true) && (this.GDSChk == true)) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }

  }
  public onTabSelect(e) {

  }

  public enableAllColumn() {
    this.FOLDERNOHidden = false;
    this.ORDERNOHidden = false;
    this.DATE_OF_ISSUEHidden = false;
    this.PAX_NAMEHidden = false;
    this.TKT_NOHidden = false;
    this.PAX_TYPEHidden = false;
    this.PNRHidden = false;
    this.TKT_TYPEHidden = false;
    this.ORGHidden = false;
    this.DESTHidden = false;
    this.BASE_FAREHidden = false;
    this.TAX1_TYPEHidden = false;
    this.TAX1_AMTHidden = false;
    this.TAX2_TYPEHidden = false;
    this.TAX2_AMTHidden = false;
    this.TAX3_AMTHidden = false;
    this.TAX3_TYPEHidden = false;
    this.TAX4_AMTHidden = false;
    this.TAX4_TYPEHidden = false;
    this.TOTALHidden = false;
    this.TRAVEL_DATEHidden = false;
    this.CustomerCodeHidden = false;
    this.CUSTOMERHidden = false;
    this.ISSUED_BYHidden = false;
    this.BOOKED_BYHidden = false;
    this.JOURNEY_TYPEHidden = false;
    this.AIRIDHidden = false;
    this.AIRNAMEHidden = false;
    this.AIRLINE_CLASSHidden = false;
    this.AIRLINE_CODEHidden = false;
    this.AIRLINE_PNRHidden = false;
    this.Branch_IDHidden = false;
    this.AIRLINE_PCCHidden = false;
    this.FLT_NO_INBOUNDHidden = false;
    this.FLT_NO_OUTBOUNDHidden = false;
    this.PRTYUSRCODEHidden = false;
    this.CLASSNAMEHidden = false;
    this.FareCommAmtHidden = false;
    this.FareCommPerHidden = false;
    this.PaymentMethodHidden = false;
    this.FAREBASISHidden = false;
    this.CommAmountHidden = false;
    this.AirlineAcommHidden = false;
    this.SellAmountHidden = false;
    this.MileageInfoHidden = false;
    this.ReceiptHidden = false;
    this.StatusHidden = false;
    this.MarkUPHidden = false;
    this.ReturnDateHidden = false;
    this.TicketingDeadlineHidden = false;
    this.GDSHidden = false;
    this.InterIndHidden = false;

  }
  CreateTemplate() {

    this.FOLDERNOChk = false;
    this.ORDERNOChk = false;
    this.DATEOFISSUEChk = false;
    this.PAXNAMEChk = false;
    this.TKTNOChk = false;
    this.PAXTYPEChk = false;
    this.PNRChk = false;
    this.TKTTYPEChk = false;
    this.ORGChk = false;
    this.DESTChk = false;
    this.BASEFAREChk = false;
    this.TAX1TYPEChk = false;
    this.TAX1AMTChk = false;
    this.TAX2TYPEChk = false;
    this.TAX2AMTChk = false;
    this.TAX3TYPEChk = false;
    this.TAX3AMTChk = false;
    this.TAX4TYPEChk = false;
    this.TAX4AMTChk = false;
    this.TOTALChk = false;
    this.TRAVELDATEChk = false;
    this.CustomerCodeChk = false;
    this.CUSTOMERChk = false;
    this.ISSUEDBYChk = false;
    this.BOOKEDBYChk = false;
    this.JOURNEYTYPEChk = false;
    this.AIRIDChk = false;
    this.AIRNAMEChk = false;
    this.AIRLINECLASSChk = false;
    this.AIRLINECODEChk = false;
    this.AIRLINEPNRChk = false;
    this.BranchIDChk = false;
    this.AIRLINEPCCChk = false;
    this.FLTNOOUTBOUNDChk = false;
    this.FLTNOINBOUNDChk = false;
    this.PRTYUSRCODEChk = false;
    this.CLASSNAMEChk = false;
    this.FareCommAmtChk = false;
    this.FareCommPerChk = false;
    this.PaymentMethodChk = false;
    this.FAREBASISChk = false;
    this.CommAmountChk = false;
    this.AirlineAcommChk = false;
    this.SellAmountChk = false;
    this.MileageInfoChk = false;
    this.ReceiptChk = false;
    this.StatusChk = false;
    this.MarkUPChk = false;
    this.ReturnDateChk = false;
    this.TicketingDeadlineChk = false;
    this.InterIndChk = false;
    this.GDSChk = false;
    this.selectAll = false;
    this.RptHdrSel_TemplateName = null;
    this.submitted = false;
    this.opentemplate = true;
    this.AirlineDetailedReportService.formDataHeader.RptHdrSel_AutoNo = 0;
  }
  closeAirlineDetailedReport() {
    this.opentemplate = false;
  }
  public selectAllColumn() {
    if (this.selectAll == true) {
      this.FOLDERNOChk = true;
      this.ORDERNOChk = true;
      this.DATEOFISSUEChk = true;
      this.PAXNAMEChk = true;
      this.TKTNOChk = true;
      this.PAXTYPEChk = true;
      this.PNRChk = true;
      this.TKTTYPEChk = true;
      this.ORGChk = true;
      this.DESTChk = true;
      this.BASEFAREChk = true;
      this.TAX1TYPEChk = true;
      this.TAX1AMTChk = true;
      this.TAX2TYPEChk = true;
      this.TAX2AMTChk = true;
      this.TAX3TYPEChk = true;
      this.TAX3AMTChk = true;
      this.TAX4TYPEChk = true;
      this.TAX4AMTChk = true;
      this.TOTALChk = true;
      this.TRAVELDATEChk = true;
      this.CustomerCodeChk = true;
      this.CUSTOMERChk = true;
      this.ISSUEDBYChk = true;
      this.BOOKEDBYChk = true;
      this.JOURNEYTYPEChk = true;
      this.AIRIDChk = true;
      this.AIRNAMEChk = true;
      this.AIRLINECLASSChk = true;
      this.AIRLINECODEChk = true;
      this.AIRLINEPNRChk = true;
      this.BranchIDChk = true;
      this.AIRLINEPCCChk = true;
      this.FLTNOOUTBOUNDChk = true;
      this.FLTNOINBOUNDChk = true;
      this.PRTYUSRCODEChk = true;
      this.CLASSNAMEChk = true;
      this.FareCommAmtChk = true;
      this.FareCommPerChk = true;
      this.PaymentMethodChk = true;
      this.FAREBASISChk = true;
      this.CommAmountChk = true;
      this.AirlineAcommChk = true;
      this.SellAmountChk = true;
      this.MileageInfoChk = true;
      this.ReceiptChk = true;
      this.StatusChk = true;
      this.MarkUPChk = true;
      this.ReturnDateChk = true;
      this.TicketingDeadlineChk = true;
      this.InterIndChk = true;
      this.GDSChk = true;
    } else {
      this.FOLDERNOChk = false;
      this.ORDERNOChk = false;
      this.DATEOFISSUEChk = false;
      this.PAXNAMEChk = false;
      this.TKTNOChk = false;
      this.PAXTYPEChk = false;
      this.PNRChk = false;
      this.TKTTYPEChk = false;
      this.ORGChk = false;
      this.DESTChk = false;
      this.BASEFAREChk = false;
      this.TAX1TYPEChk = false;
      this.TAX1AMTChk = false;
      this.TAX2TYPEChk = false;
      this.TAX2AMTChk = false;
      this.TAX3TYPEChk = false;
      this.TAX3AMTChk = false;
      this.TAX4TYPEChk = false;
      this.TAX4AMTChk = false;
      this.TOTALChk = false;
      this.TRAVELDATEChk = false;
      this.CustomerCodeChk = false;
      this.CUSTOMERChk = false;
      this.ISSUEDBYChk = false;
      this.BOOKEDBYChk = false;
      this.JOURNEYTYPEChk = false;
      this.AIRIDChk = false;
      this.AIRNAMEChk = false;
      this.AIRLINECLASSChk = false;
      this.AIRLINECODEChk = false;
      this.AIRLINEPNRChk = false;
      this.BranchIDChk = false;
      this.AIRLINEPCCChk = false;
      this.FLTNOOUTBOUNDChk = false;
      this.FLTNOINBOUNDChk = false;
      this.PRTYUSRCODEChk = false;
      this.CLASSNAMEChk = false;
      this.FareCommAmtChk = false;
      this.FareCommPerChk = false;
      this.PaymentMethodChk = false;
      this.FAREBASISChk = false;
      this.CommAmountChk = false;
      this.AirlineAcommChk = false;
      this.SellAmountChk = false;
      this.MileageInfoChk = false;
      this.ReceiptChk = false;
      this.StatusChk = false;
      this.MarkUPChk = false;
      this.ReturnDateChk = false;
      this.TicketingDeadlineChk = false;
      this.InterIndChk = false;
      this.GDSChk = false;
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    debugger;
    this.state = state;

    this.loadAirLineGrid();

  }
  loadAirLineGrid() {
    this.AirlineDetailedList = process(this.AirlineReportGridData, this.state);
  }
  async columnVisibilityChange(value: any) {
    debugger;
    this.column = "FOLDER_NO,ORDER_NO,DATE_OF_ISSUE,PAX_NAME,TKT_NO,PAX_TYPE,PNR,TKT_TYPE,ORG,DEST,BASE_FARE,TAX1_TYPE,TAX1_AMT,TAX2_TYPE,TAX2_AMT,TAX3_TYPE,TAX3_AMT,TAX4_TYPE,TAX4_AMT,TOTAL,TRAVEL_DATE,CustomerCode,CUSTOMER,ISSUED_BY,BOOKED_BY,JOURNEY_TYPE,AIRID,AIRNAME,AIRLINE_CLASS,AIRLINE_CODE,AIRLINE_PNR,Branch_ID,AIRLINE_PCC,FLTNO_OUTBOUND,FLTNO_INBOUND,PRTYUSRCODE,CLASSNAME,FareCommAmt,FareCommPer,PaymentMethod,FAREBASIS,CommAmountHC,AirlineAcomm,SellAmountHC,MileageInfo,Receipt,Status,MarkUp,Returndate,TicketingDeadline,InterInd,GDS";
    if (value["columns"].length > 0) {
      value["columns"].forEach(obj => {
        if (obj["hidden"] == true) {
          this.column = this.column.replace(obj["field"], "");
          this.column = this.column.replace(/,\s*$/, "");
          this.column = this.column.replace(/^,/, "");
        } else {
        }
      });
      if (this.TemplateSelectedItem != undefined) {
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_AutoNo = this.TemplateSelectedItem.UsrCode;
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_UserId = this.userID;
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_ModuleName =
          "AirlineDetailedReport";
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_ColumnHeader = this.column;
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_TemplateName =
          "AirlineDetailedReport";
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_CustCo = 0;
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
          new Date()
        );
        this.subscription.add(
          this.AirlineDetailedReportService
            .saveOrUpdateReportHeader()
            .subscribe(data => { })
        );
      } else {
      }
    }

  }

  templateChange(value) {

    debugger; //UsrCode
    if (value != undefined) {
      this.AirlineDetailedReportService.formDataHeader.RptHdrSel_AutoNo = value.UsrCode;

      this.LoadReportColumn();
    }
  }
  public LoadReportColumn() {
    this.AirlineDetailedReportService.formDataHeader.RptHdrSel_UserId = this.userID;
    this.AirlineDetailedReportService.formDataHeader.RptHdrSel_ModuleName =
      "AirlineDetailedReport";
    this.subscription.add(
      this.AirlineDetailedReportService.GetGridColumn().subscribe(data => {
        debugger;
        if (
          data != undefined &&
          data != null &&
          data["ResponseData"] != undefined
        ) {
          this.TemplateSelectedItem = this.TempList.find(
            x => x.UsrCode == data["ResponseData"].RptHdrSel_AutoNo
          );

          if (data["ResponseData"].RptHdrSel_ColumnHeader != null) {
            var datas = data["ResponseData"].RptHdrSel_ColumnHeader.split(",");

            if (datas.includes("FOLDER_NO")) {
              this.FOLDERNOHidden = false;
            } else {
              this.FOLDERNOHidden = true;
            }
            if (datas.includes("ORDER_NO")) {
              this.ORDERNOHidden = false;
            } else {
              this.ORDERNOHidden = true;
            }
            if (datas.includes("DATE_OF_ISSUE")) {
              this.DATE_OF_ISSUEHidden = false;
            } else {
              this.DATE_OF_ISSUEHidden = true;
            }
            if (datas.includes("PAX_NAME")) {
              this.PAX_NAMEHidden = false;
            } else {
              this.PAX_NAMEHidden = true;
            }
            if (datas.includes("TKT_NO")) {
              this.TKT_NOHidden = false;
            } else {
              this.TKT_NOHidden = true;
            }
            if (datas.includes("PAX_TYPE")) {
              this.PAX_TYPEHidden = false;
            } else {
              this.PAX_TYPEHidden = true;
            }

            if (datas.includes("PNR")) {
              this.PNRHidden = false;
            } else {
              this.PNRHidden = true;
            }
            if (datas.includes("TKT_TYPE")) {
              this.TKT_TYPEHidden = false;
            } else {
              this.TKT_TYPEHidden = true;
            }
            if (datas.includes("ORG")) {
              this.ORGHidden = false;
            } else {
              this.ORGHidden = true;
            }
            if (datas.includes("DEST")) {
              this.DESTHidden = false;
            } else {
              this.DESTHidden = true;
            }
            if (datas.includes("BASE_FARE")) {
              this.BASE_FAREHidden = false;
            } else {
              this.BASE_FAREHidden = true;
            }
            if (datas.includes("TAX1_TYPE")) {
              this.TAX1_TYPEHidden = false;
            } else {
              this.TAX1_TYPEHidden = true;
            }
            if (datas.includes("TAX1_AMT")) {
              this.TAX1_AMTHidden = false;
            } else {
              this.TAX1_AMTHidden = true;
            }

            if (datas.includes("TAX2_TYPE")) {
              this.TAX2_TYPEHidden = false;
            } else {
              this.TAX2_TYPEHidden = true;
            }
            if (datas.includes("TAX2_AMT")) {
              this.TAX2_AMTHidden = false;
            } else {
              this.TAX2_AMTHidden = true;
            }
            if (datas.includes("TAX3_TYPE")) {
              this.TAX3_TYPEHidden = false;
            } else {
              this.TAX3_TYPEHidden = true;
            }
            if (datas.includes("TAX3_AMT")) {
              this.TAX3_AMTHidden = false;
            } else {
              this.TAX3_AMTHidden = true;
            }

            if (datas.includes("TAX4_TYPE")) {
              this.TAX4_TYPEHidden = false;
            } else {
              this.TAX4_TYPEHidden = true;
            }
            if (datas.includes("TAX4_AMT")) {
              this.TAX4_AMTHidden = false;
            } else {
              this.TAX4_AMTHidden = true;
            }

            if (datas.includes("TOTAL")) {
              this.TOTALHidden = false;
            } else {
              this.TOTALHidden = true;
            }
            if (datas.includes("TRAVEL_DATE")) {
              this.TRAVEL_DATEHidden = false;
            } else {
              this.TRAVEL_DATEHidden = true;
            }
            if (datas.includes("CustomerCode")) {
              this.CustomerCodeHidden = false;
            } else {
              this.CustomerCodeHidden = true;
            }
            if (datas.includes("CUSTOMER")) {
              this.CUSTOMERHidden = false;
            } else {
              this.CUSTOMERHidden = true;
            }

            if (datas.includes("ISSUED_BY")) {
              this.ISSUED_BYHidden = false;
            } else {
              this.ISSUED_BYHidden = true;
            }
            if (datas.includes("BOOKED_BY")) {
              this.BOOKED_BYHidden = false;
            } else {
              this.BOOKED_BYHidden = true;
            }
            if (datas.includes("JOURNEY_TYPE")) {
              this.JOURNEY_TYPEHidden = false;
            } else {
              this.JOURNEY_TYPEHidden = true;
            }
            if (datas.includes("AIRID")) {
              this.AIRIDHidden = false;
            } else {
              this.AIRIDHidden = true;
            }
            if (datas.includes("AIRNAME")) {
              this.AIRNAMEHidden = false;
            } else {
              this.AIRNAMEHidden = true;
            }
            if (datas.includes("AIRLINE_CLASS")) {
              this.AIRLINE_CLASSHidden = false;
            } else {
              this.AIRLINE_CLASSHidden = true;
            }
            if (datas.includes("AIRLINE_CODE")) {
              this.AIRLINE_CODEHidden = false;
            } else {
              this.AIRLINE_CODEHidden = true;
            }
            if (datas.includes("AIRLINE_PNR")) {
              this.AIRLINE_PNRHidden = false;
            } else {
              this.AIRLINE_PNRHidden = true;
            }
            if (datas.includes("Branch_ID")) {
              this.Branch_IDHidden = false;
            } else {
              this.Branch_IDHidden = true;
            }
            if (datas.includes("AIRLINE_PCC")) {
              this.AIRLINE_PCCHidden = false;
            } else {
              this.AIRLINE_PCCHidden = true;
            }
            if (datas.includes("FLTNO_OUTBOUND")) {
              this.FLT_NO_OUTBOUNDHidden = false;
            } else {
              this.FLT_NO_OUTBOUNDHidden = true;
            }
            if (datas.includes("FLTNO_INBOUND")) {
              this.FLT_NO_INBOUNDHidden = false;
            } else {
              this.FLT_NO_INBOUNDHidden = true;
            }
            if (datas.includes("PRTYUSRCODE")) {
              this.PRTYUSRCODEHidden = false;
            } else {
              this.PRTYUSRCODEHidden = true;
            }
            if (datas.includes("CLASSNAME")) {
              this.CLASSNAMEHidden = false;
            } else {
              this.CLASSNAMEHidden = true;
            }
            if (datas.includes("FareCommAmt")) {
              this.FareCommAmtHidden = false;
            } else {
              this.FareCommAmtHidden = true;
            }
            if (datas.includes("FareCommPer")) {
              this.FareCommPerHidden = false;
            } else {
              this.FareCommPerHidden = true;
            }
            if (datas.includes("PaymentMethod")) {
              this.PaymentMethodHidden = false;
            } else {
              this.PaymentMethodHidden = true;
            }
            if (datas.includes("FAREBASIS")) {
              this.FAREBASISHidden = false;
            } else {
              this.FAREBASISHidden = true;
            }
            if (datas.includes("CommAmountHC")) {
              this.CommAmountHidden = false;
            } else {
              this.CommAmountHidden = true;
            }

            if (datas.includes("AirlineAcomm")) {
              this.AirlineAcommHidden = false;
            } else {
              this.AirlineAcommHidden = true;
            }

            if (datas.includes("SellAmountHC")) {
              this.SellAmountHidden = false;
            } else {
              this.SellAmountHidden = true;
            }
            if (datas.includes("MileageInfo")) {
              this.MileageInfoHidden = false;
            } else {
              this.MileageInfoHidden = true;
            }
            if (datas.includes("Receipt")) {
              this.ReceiptHidden = false;
            } else {
              this.ReceiptHidden = true;
            }
            if (datas.includes("Status")) {
              this.StatusHidden = false;
            } else {
              this.StatusHidden = true;
            }
            if (datas.includes("MarkUp")) {
              this.MarkUPHidden = false;
            } else {
              this.MarkUPHidden = true;
            }
            if (datas.includes("Returndate")) {
              this.ReturnDateHidden = false;
            } else {
              this.ReturnDateHidden = true;
            }
            if (datas.includes("TicketingDeadline")) {
              this.TicketingDeadlineHidden = false;
            } else {
              this.TicketingDeadlineHidden = true;
            }
            if (datas.includes("InterInd")) {
              this.InterIndHidden = false;
            } else {
              this.InterIndHidden = true;
            }
            if (datas.includes("GDS")) {
              this.GDSHidden = false;
            } else {
              this.GDSHidden = true;
            }
          }
        } else {
          this.enableAllColumn();
        }
      })
    );
  }
  public GetReportHeaderFooter() {
    this.subscription.add(
      this.AirlineDetailedReportService
        .GetReportHeaderFooter(this.userID, 0)
        .subscribe(data => {
          debugger;
          let objectURL = "data:image/png;base64," + data["ResponseData"];

          this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.LoadReportColumn();
        })
    );
  }
  EditTemplate() {
    debugger;

    if (this.TemplateSelectedItem == null || this.TemplateSelectedItem == undefined) {
      this.subscription.add(this.translateapi.get(['Messages.template_required']).subscribe((translations) => {

        this.showMsg = translations['Messages.template_required'];;
        this.toastr.error(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })

      }));

      return;
    }
    this.GetVarientTemplateForEdit();

  }
  GetVarientTemplateForEdit() {

    this.AirlineDetailedReportService.GetVarientTemplateForEdit(this.TemplateSelectedItem.Code, this.AirlineDetailedReportService.formDataHeader.RptHdrSel_ModuleName).subscribe(data => {
      this.opentemplate = true;
      this.RptHdrSel_TemplateName = data["ResponseData"].RptHdrSel_TemplateName;
      this.RptHdrSel_AutoNo = data["ResponseData"].RptHdrSel_AutoNo;
      this.AirlineDetailedReportService.formDataHeader.RptHdrSel_AutoNo = this.RptHdrSel_AutoNo;
      if (data != null && data != undefined) {

        if (data["ResponseData"].RptHdrSel_ColumnHeader != null) {
          var datas = data["ResponseData"].RptHdrSel_ColumnHeader.split(',');
          if (datas.includes('FOLDER_NO')) {
            this.FOLDERNOChk = true;
          }
          else {
            this.FOLDERNOChk = false;
          }
          if (datas.includes('ORDER_NO')) {
            this.ORDERNOChk = true;
          }
          else {
            this.ORDERNOChk = false;
          }
          if (datas.includes('DATE_OF_ISSUE')) {
            this.DATEOFISSUEChk = true;
          }
          else {
            this.DATEOFISSUEChk = false;
          }
          if (datas.includes('PAX_NAME')) {
            this.PAXNAMEChk = true;
          }
          else {
            this.PAXNAMEChk = false;
          }
          if (datas.includes('TKT_NO')) {
            this.TKTNOChk = true;
          }
          else {
            this.TKTNOChk = false;
          }
          if (datas.includes('PAX_TYPE')) {
            this.PAXTYPEChk = true;
          }
          else {
            this.PAXTYPEChk = false;
          }

          if (datas.includes('PNR')) {
            this.PNRChk = true;
          }
          else {
            this.PNRChk = false;
          }
          if (datas.includes('TKT_TYPE')) {
            this.TKTTYPEChk = true;
          }
          else {
            this.TKTTYPEChk = false;
          }
          if (datas.includes('ORG')) {
            this.ORGChk = true;
          }
          else {
            this.ORGChk = false;
          }
          if (datas.includes('DEST')) {
            this.DESTChk = true;
          }
          else {
            this.DESTChk = false;
          }
          if (datas.includes('BASE_FARE')) {
            this.BASEFAREChk = true;
          }
          else {
            this.BASEFAREChk = false;
          }
          if (datas.includes('TAX1_TYPE')) {
            this.TAX1TYPEChk = true;
          }
          else {
            this.TAX1TYPEChk = false;
          }
          if (datas.includes('TAX1_AMT')) {
            this.TAX1AMTChk = true;
          }
          else {
            this.TAX1AMTChk = false;
          }
          if (datas.includes('TAX2_TYPE')) {
            this.TAX2TYPEChk = true;
          }
          else {
            this.TAX2TYPEChk = false;
          }
          if (datas.includes('TAX2_AMT')) {
            this.TAX2AMTChk = true;
          }
          else {
            this.TAX2AMTChk = false;
          }
          if (datas.includes('TAX3_TYPE')) {
            this.TAX3TYPEChk = true;
          }
          else {
            this.TAX3TYPEChk = false;
          }
          if (datas.includes('TAX3_AMT')) {
            this.TAX3AMTChk = true;
          }
          else {
            this.TAX3AMTChk = false;
          }
          if (datas.includes('TAX4_TYPE')) {
            this.TAX4TYPEChk = true;
          }
          else {
            this.TAX4TYPEChk = false;
          }
          if (datas.includes('TAX4_AMT')) {
            this.TAX4AMTChk = true;
          }
          else {
            this.TAX4AMTChk = false;
          }
          if (datas.includes('TOTAL')) {
            this.TOTALChk = true;
          }
          else {
            this.TOTALChk = false;
          }
          if (datas.includes('TRAVEL_DATE')) {
            this.TRAVELDATEChk = true;
          }
          else {
            this.TRAVELDATEChk = false;
          }
          if (datas.includes('CustomerCode')) {
            this.CustomerCodeChk = true;
          }
          else {
            this.CustomerCodeChk = false;
          }
          if (datas.includes('CUSTOMER')) {
            this.CUSTOMERChk = true;
          }
          else {
            this.CUSTOMERChk = false;
          }
          if (datas.includes('ISSUED_BY')) {
            this.ISSUEDBYChk = true;
          }
          else {
            this.ISSUEDBYChk = false;
          }
          if (datas.includes('BOOKED_BY')) {
            this.BOOKEDBYChk = true;
          }
          else {
            this.BOOKEDBYChk = false;
          }
          if (datas.includes('JOURNEY_TYPE')) {
            this.JOURNEYTYPEChk = true;
          }
          else {
            this.JOURNEYTYPEChk = false;
          }
          if (datas.includes('AIRID')) {
            this.AIRIDChk = true;
          }
          else {
            this.AIRIDChk = false;
          }
          if (datas.includes('AIRNAME')) {
            this.AIRNAMEChk = true;
          }
          else {
            this.AIRNAMEChk = false;
          }
          if (datas.includes('AIRLINE_CLASS')) {
            this.AIRLINECLASSChk = true;
          }
          else {
            this.AIRLINECLASSChk = false;
          }
          if (datas.includes('AIRLINE_CODE')) {
            this.AIRLINECODEChk = true;
          }
          else {
            this.AIRLINECODEChk = false;
          }
          if (datas.includes('AIRLINE_PNR')) {
            this.AIRLINEPNRChk = true;
          }
          else {
            this.AIRLINEPNRChk = false;
          }
          if (datas.includes('Branch_ID')) {
            this.BranchIDChk = true;
          }
          else {
            this.BranchIDChk = false;
          }
          if (datas.includes('AIRLINE_PCC')) {
            this.AIRLINEPCCChk = true;
          }
          else {
            this.AIRLINEPCCChk = false;
          }
          if (datas.includes('FLTNO_OUTBOUND')) {
            this.FLTNOOUTBOUNDChk = true;
          }
          else {
            this.FLTNOOUTBOUNDChk = false;
          }
          if (datas.includes('FLTNO_INBOUND')) {
            this.FLTNOINBOUNDChk = true;
          }
          else {
            this.FLTNOINBOUNDChk = false;
          }
          if (datas.includes('PRTYUSRCODE')) {
            this.PRTYUSRCODEChk = true;
          }
          else {
            this.PRTYUSRCODEChk = false;
          }
          if (datas.includes('CLASSNAME')) {
            this.CLASSNAMEChk = true;
          }
          else {
            this.CLASSNAMEChk = false;
          }
          if (datas.includes('FareCommAmt')) {
            this.FareCommAmtChk = true;
          }
          else {
            this.FareCommAmtChk = false;
          }
          if (datas.includes('FareCommPer')) {
            this.FareCommPerChk = true;
          }
          else {
            this.FareCommPerChk = false;
          }
          if (datas.includes('PaymentMethod')) {
            this.PaymentMethodChk = true;
          }
          else {
            this.PaymentMethodChk = false;
          }
          if (datas.includes('FAREBASIS')) {
            this.FAREBASISChk = true;
          }
          else {
            this.FAREBASISChk = false;
          }
          if (datas.includes('CommAmountHC')) {
            this.CommAmountChk = true;
          }
          else {
            this.CommAmountChk = false;
          }
          if (datas.includes('AirlineAcomm')) {
            this.AirlineAcommChk = true;
          }
          else {
            this.AirlineAcommChk = false;
          }
          if (datas.includes('SellAmountHC')) {
            this.SellAmountChk = true;
          }
          else {
            this.SellAmountChk = false;
          }
          if (datas.includes('MileageInfo')) {
            this.MileageInfoChk = true;
          }
          else {
            this.MileageInfoChk = false;
          }
          if (datas.includes('Receipt')) {
            this.ReceiptChk = true;
          }
          else {
            this.ReceiptChk = false;
          }
          if (datas.includes('Status')) {
            this.StatusChk = true;
          }
          else {
            this.StatusChk = false;
          }
          if (datas.includes('MarkUp')) {
            this.MarkUPChk = true;
          }
          else {
            this.MarkUPChk = false;
          }
          if (datas.includes('Returndate')) {
            this.ReturnDateChk = true;
          }
          else {
            this.ReturnDateChk = false;
          }
          if (datas.includes('GDS')) {
            this.GDSChk = true;
          }
          else {
            this.GDSChk = false;
          }
          if (datas.includes('InterInd')) {
            this.InterIndChk = true;
          }
          else {
            this.InterIndChk = false;
          }
          if (datas.includes('TicketingDeadline')) {
            this.TicketingDeadlineChk = true;
          }
          else {
            this.TicketingDeadlineChk = false;
          }
        }

      }
    })
  }
  onSave() {

    this.submitted = true;
    this.userID = +this.apiShared.uid;
    if (this.AirlineDetailedReportService.formDataHeader.RptHdrSel_AutoNo == 0) {

      if (
        this.RptHdrSel_TemplateName == null ||
        this.RptHdrSel_TemplateName == undefined ||
        this.RptHdrSel_TemplateName == ""
      ) {
        return;
      } else {
        if (this.selectAll == true) {
          this.column = "FOLDER_NO,ORDER_NO,DATE_OF_ISSUE,PAX_NAME,TKT_NO,PAX_TYPE,PNR,TKT_TYPE,ORG,DEST,BASE_FARE,TAX1_TYPE,TAX1_AMT,TAX2_TYPE,TAX2_AMT,TAX3_TYPE,TAX3_AMT,TAX4_TYPE,TAX4_AMT,TOTAL,TRAVEL_DATE,CustomerCode,CUSTOMER,ISSUED_BY,BOOKED_BY,JOURNEY_TYPE,AIRID,AIRNAME,AIRLINE_CLASS,AIRLINE_CODE,AIRLINE_PNR,Branch_ID,AIRLINE_PCC,FLTNO_OUTBOUND,FLTNO_INBOUND,PRTYUSRCODE,CLASSNAME,FareCommAmt,FareCommPer,PaymentMethod,FAREBASIS,CommAmountHC,AirlineAcomm,SellAmountHC,MileageInfo,Receipt,Status,MarkUp,Returndate,TicketingDeadline,InterInd,GDS";
        } else {
          this.column = "";
          if (this.FOLDERNOChk == true) {
            this.column = "FOLDER_NO";
          }
          if (this.ORDERNOChk == true) {
            this.column = this.column + ",ORDER_NO";
          }
          if (this.DATEOFISSUEChk == true) {
            this.column = this.column + ",DATE_OF_ISSUE";
          }
          if (this.PAXNAMEChk == true) {
            this.column = this.column + ",PAX_NAME";
          }
          if (this.TKTNOChk == true) {
            this.column = this.column + ",TKT_NO";
          }
          if (this.PAXTYPEChk == true) {
            this.column = this.column + ",PAX_TYPE";
          }
          if (this.PNRChk == true) {
            this.column = this.column + ",PNR";
          }
          if (this.TKTTYPEChk == true) {
            this.column = this.column + ",TKT_TYPE";
          }
          if (this.ORGChk == true) {
            this.column = this.column + ",ORG";
          }
          if (this.DESTChk == true) {
            this.column = this.column + ",DEST";

          }
          if (this.BASEFAREChk == true) {
            this.column = this.column + ",BASE_FARE";
          }
          if (this.TAX1_TYPEHidden == true) {
            this.column = this.column + ",TAX1_TYPE";
          }
          if (this.TAX1AMTChk == true) {
            this.column = this.column + ",TAX1_AMT";
          }
          if (this.TAX2TYPEChk == true) {
            this.column = this.column + ",TAX2_TYPE";
          }
          if (this.TAX2AMTChk == true) {
            this.column = this.column + ",TAX2_AMT";
          }
          if (this.TAX3TYPEChk == true) {
            this.column = this.column + ",TAX3_TYPE";
          }
          if (this.TAX3AMTChk == true) {
            this.column = this.column + ",TAX3_AMT";
          }
          if (this.TAX3AMTChk == true) {
            this.column = this.column + ",TAX3_AMT";
          }
          if (this.TAX4TYPEChk == true) {
            this.column = this.column + ",TAX4_TYPE";
          }
          if (this.TAX4AMTChk == true) {
            this.column = this.column + ",TAX4_AMT";
          }
          if (this.TOTALChk == true) {
            this.column = this.column + ",TOTAL";
          }
          if (this.TRAVELDATEChk == true) {
            this.column = this.column + ",TRAVEL_DATE";
          }
          if (this.CustomerCodeChk == true) {
            this.column = this.column + ",CustomerCode";
          }
          if (this.CUSTOMERChk == true) {
            this.column = this.column + ",CUSTOMER";
          }
          if (this.ISSUEDBYChk == true) {
            this.column = this.column + ",ISSUED_BY";
          }
          if (this.BOOKEDBYChk == true) {
            this.column = this.column + ",BOOKED_BY";
          }
          if (this.JOURNEYTYPEChk == true) {
            this.column = this.column + ",JOURNEY_TYPE";
          }
          if (this.AIRIDChk == true) {
            this.column = this.column + ",AIRID";
          }
          if (this.AIRNAMEChk == true) {
            this.column = this.column + ",AIRNAME";
          }
          if (this.AIRLINECLASSChk == true) {
            this.column = this.column + ",AIRLINE_CLASS";
          }
          if (this.AIRLINECODEChk == true) {
            this.column = this.column + ",AIRLINE_CODE";
          }
          if (this.AIRLINEPNRChk == true) {
            this.column = this.column + ",AIRLINE_PNR";
          }
          if (this.BranchIDChk == true) {
            this.column = this.column + ",Branch_ID";
          }
          if (this.AIRLINEPCCChk == true) {
            this.column = this.column + ",AIRLINE_PCC";
          }
          if (this.FLTNOOUTBOUNDChk == true) {
            this.column = this.column + ",FLTNO_OUTBOUND";
          }
          if (this.FLTNOINBOUNDChk == true) {
            this.column = this.column + ",FLTNO_INBOUND";
          }
          if (this.PRTYUSRCODEChk == true) {
            this.column = this.column + ",PRTYUSRCODE";
          }
          if (this.CLASSNAMEChk == true) {
            this.column = this.column + ",CLASSNAME";
          }
          if (this.FareCommAmtChk == true) {
            this.column = this.column + ",FareCommAmt";
          }
          if (this.FareCommPerChk == true) {
            this.column = this.column + ",FareCommPer";
          }
          if (this.PaymentMethodChk == true) {
            this.column = this.column + ",PaymentMethod";
          }
          if (this.FAREBASISChk == true) {
            this.column = this.column + ",FAREBASIS";
          }
          if (this.CommAmountChk == true) {
            this.column = this.column + ",CommAmountHC";
          }
          if (this.AirlineAcommChk == true) {
            this.column = this.column + ",AirlineAcomm";
          }
          if (this.SellAmountChk == true) {
            this.column = this.column + ",SellAmountHC";
          }
          if (this.MileageInfoChk == true) {
            this.column = this.column + ",MileageInfo";
          }
          if (this.ReceiptChk == true) {
            this.column = this.column + ",Receipt";
          }
          if (this.StatusChk == true) {
            this.column = this.column + ",Status";
          }
          if (this.MarkUPChk == true) {
            this.column = this.column + ",MarkUp";
          }
          this.column = this.column.replace(/,\s*$/, "");
          this.column = this.column.replace(/^,/, "");
        }
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_UserId = this.userID;
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_ModuleName =
          "AirlineDetailedReport";
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_ColumnHeader = this.column;
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_TemplateName = this.RptHdrSel_TemplateName;
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_CustCo = 0;
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_AutoNo = 0;
        this.AirlineDetailedReportService.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
          new Date()
        );
        this.subscription.add(
          this.AirlineDetailedReportService.saveOrUpdateReportHeader().subscribe(data => {
            debugger;
            if (data != undefined && data != null) {
              if (data["ResponseData"]["RptHdrSel_AutoNo"] > 0) {
                this.AirlineDetailedReportService.formDataHeader.RptHdrSel_AutoNo =
                  data["ResponseData"]["RptHdrSel_AutoNo"];
                this.LoadTemplate();
                this.LoadReportColumn();
                this.opentemplate = false;
                this.duplicatetemp = false;
              } else {
                this.duplicatetemp = true;
              }
            }
          })
        );
      }
    }

    else {
      if (this.RptHdrSel_TemplateName == null || this.RptHdrSel_TemplateName == undefined || this.RptHdrSel_TemplateName == "") {
        return
      }
      else if (this.selectAll == true) {
        this.column = "FOLDER_NO,ORDER_NO,DATE_OF_ISSUE,PAX_NAME,TKT_NO,PAX_TYPE,PNR,TKT_TYPE,ORG,DEST,BASE_FARE,TAX1_TYPE,TAX1_AMT,TAX2_TYPE,TAX2_AMT,TAX3_TYPE,TAX3_AMT,TAX4_TYPE,TAX4_AMT,TOTAL,TRAVEL_DATE,CustomerCode,CUSTOMER,ISSUED_BY,BOOKED_BY,JOURNEY_TYPE,AIRID,AIRNAME,AIRLINE_CLASS,AIRLINE_CODE,AIRLINE_PNR,Branch_ID,AIRLINE_PCC,FLTNO_OUTBOUND,FLTNO_INBOUND,PRTYUSRCODE,CLASSNAME,FareCommAmt,FareCommPer,PaymentMethod,FAREBASIS,CommAmountHC,AirlineAcomm,SellAmountHC,MileageInfo,Receipt,Status,MarkUp,Returndate,TicketingDeadline,InterInd,GDS";
      }
      else {
        this.column = "";
        if (this.FOLDERNOChk == true) {
          this.column = "FOLDER_NO";
        }
        if (this.ORDERNOChk == true) {
          this.column = this.column + ",ORDER_NO";
        }
        if (this.DATEOFISSUEChk == true) {
          this.column = this.column + ",DATE_OF_ISSUE";
        }
        if (this.PAXNAMEChk == true) {
          this.column = this.column + ",PAX_NAME";
        }
        if (this.TKTNOChk == true) {
          this.column = this.column + ",TKT_NO";
        }
        if (this.PAXTYPEChk == true) {
          this.column = this.column + ",PAX_TYPE";
        }
        if (this.PNRChk == true) {
          this.column = this.column + ",PNR";
        }
        if (this.TKTTYPEChk == true) {
          this.column = this.column + ",TKT_TYPE";
        }
        if (this.ORGChk == true) {
          this.column = this.column + ",ORG";
        }
        if (this.DESTChk == true) {
          this.column = this.column + ",DEST";

        }
        if (this.BASEFAREChk == true) {
          this.column = this.column + ",BASE_FARE";
        }
        if (this.TAX1_TYPEHidden == true) {
          this.column = this.column + ",TAX1_TYPE";
        }
        if (this.TAX1AMTChk == true) {
          this.column = this.column + ",TAX1_AMT";
        }
        if (this.TAX2TYPEChk == true) {
          this.column = this.column + ",TAX2_TYPE";
        }
        if (this.TAX2AMTChk == true) {
          this.column = this.column + ",TAX2_AMT";
        }
        if (this.TAX3TYPEChk == true) {
          this.column = this.column + ",TAX3_TYPE";
        }
        if (this.TAX3AMTChk == true) {
          this.column = this.column + ",TAX3_AMT";
        }
        if (this.TAX3AMTChk == true) {
          this.column = this.column + ",TAX3_AMT";
        }
        if (this.TAX4TYPEChk == true) {
          this.column = this.column + ",TAX4_TYPE";
        }
        if (this.TAX4AMTChk == true) {
          this.column = this.column + ",TAX4_AMT";
        }
        if (this.TOTALChk == true) {
          this.column = this.column + ",TOTAL";
        }
        if (this.TRAVELDATEChk == true) {
          this.column = this.column + ",TRAVEL_DATE";
        }
        if (this.CustomerCodeChk == true) {
          this.column = this.column + ",CustomerCode";
        }
        if (this.CUSTOMERChk == true) {
          this.column = this.column + ",CUSTOMER";
        }
        if (this.ISSUEDBYChk == true) {
          this.column = this.column + ",ISSUED_BY";
        }
        if (this.BOOKEDBYChk == true) {
          this.column = this.column + ",BOOKED_BY";
        }
        if (this.JOURNEYTYPEChk == true) {
          this.column = this.column + ",JOURNEY_TYPE";
        }
        if (this.AIRIDChk == true) {
          this.column = this.column + ",AIRID";
        }
        if (this.AIRNAMEChk == true) {
          this.column = this.column + ",AIRNAME";
        }
        if (this.AIRLINECLASSChk == true) {
          this.column = this.column + ",AIRLINE_CLASS";
        }
        if (this.AIRLINECODEChk == true) {
          this.column = this.column + ",AIRLINE_CODE";
        }
        if (this.AIRLINEPNRChk == true) {
          this.column = this.column + ",AIRLINE_PNR";
        }
        if (this.BranchIDChk == true) {
          this.column = this.column + ",Branch_ID";
        }
        if (this.AIRLINEPCCChk == true) {
          this.column = this.column + ",AIRLINE_PCC";
        }
        if (this.FLTNOOUTBOUNDChk == true) {
          this.column = this.column + ",FLTNO_OUTBOUND";
        }
        if (this.FLTNOINBOUNDChk == true) {
          this.column = this.column + ",FLTNO_INBOUND";
        }
        if (this.PRTYUSRCODEChk == true) {
          this.column = this.column + ",PRTYUSRCODE";
        }
        if (this.CLASSNAMEChk == true) {
          this.column = this.column + ",CLASSNAME";
        }
        if (this.FareCommAmtChk == true) {
          this.column = this.column + ",FareCommAmt";
        }
        if (this.FareCommPerChk == true) {
          this.column = this.column + ",FareCommPer";
        }
        if (this.PaymentMethodChk == true) {
          this.column = this.column + ",PaymentMethod";
        }
        if (this.FAREBASISChk == true) {
          this.column = this.column + ",FAREBASIS";
        }
        if (this.CommAmountChk == true) {
          this.column = this.column + ",CommAmountHC";
        }
        if (this.AirlineAcommChk == true) {
          this.column = this.column + ",AirlineAcomm";
        }
        if (this.SellAmountChk == true) {
          this.column = this.column + ",SellAmountHC";
        }
        if (this.MileageInfoChk == true) {
          this.column = this.column + ",MileageInfo";
        }
        if (this.ReceiptChk == true) {
          this.column = this.column + ",Receipt";
        }
        if (this.StatusChk == true) {
          this.column = this.column + ",Status";
        }
        if (this.MarkUPChk == true) {
          this.column = this.column + ",MarkUp";
        }
        if (this.ReturnDateChk == true) {
          this.column = this.column + ",Returndate";
        }
        if (this.TicketingDeadlineChk == true) {
          this.column = this.column + ",TicketingDeadline";
        }
        if (this.InterIndChk == true) {
          this.column = this.column + ",InterInd";
        }
        if (this.GDSChk == true) {
          this.column = this.column + ",GDS";
        }
        this.column = this.column.replace(/,\s*$/, "");
        this.column = this.column.replace(/^,/, "");
      }
      this.AirlineDetailedReportService.formDataHeader.RptHdrSel_UserId = this.userID;
      this.AirlineDetailedReportService.formDataHeader.RptHdrSel_ModuleName =
        "AirlineDetailedReport";
      this.AirlineDetailedReportService.formDataHeader.RptHdrSel_ColumnHeader = this.column;
      this.AirlineDetailedReportService.formDataHeader.RptHdrSel_TemplateName = this.RptHdrSel_TemplateName;
      this.AirlineDetailedReportService.formDataHeader.RptHdrSel_CustCo = 0;
      this.AirlineDetailedReportService.formDataHeader.RptHdrSel_AutoNo = this.RptHdrSel_AutoNo;
      this.AirlineDetailedReportService.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
        new Date()
      );
      this.subscription.add(
        this.AirlineDetailedReportService.saveOrUpdateReportHeader().subscribe(data => {
          debugger;
          if (data != undefined && data != null) {
            if (data["ResponseData"]["RptHdrSel_AutoNo"] > 0) {
              this.AirlineDetailedReportService.formDataHeader.RptHdrSel_AutoNo =
                data["ResponseData"]["RptHdrSel_AutoNo"];
              this.LoadTemplate();
              this.LoadReportColumn();
              this.opentemplate = false;
              this.duplicatetemp = false;
            } else {
              this.duplicatetemp = true;
            }
          }
        })
      );

    }

  }

  async export(pdfComponent: any) {
    let processedUri: any;
    let baseprocess = await pdfComponent
      .export()
      .then((group: Group) => exportPDF(group))
      .then(dataUri => {
        const base64 = dataUri.replace("data:application/pdf;base64,", "");
        processedUri = dataUri;
      });
    const fileObject = await this.dataURLtoFile(
      processedUri,
      "Airline Detailed Report.pdf"
    );
    return fileObject;
  }
  async dataURLtoFile(dataurl, filename) {
    let file: any;
    let dataURL = new Promise(resolve => {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let newFile = new File([u8arr], filename, { type: mime });
      file = newFile;
      resolve(newFile);
    });
    this.email.fileurl = file;
    this.email.filename = "Airline Detailed Report.pdf";
    this.email.reportname = "Airline Detailed Report";
    this.open();
    return file;
  }
  public open() {

    this.opened = true;
  }
  public close(status) {
    this.opened = false;
    this.email.fileurl = null;
    this.email.filename = null;
    this.email.reportname = null;
  }
  EmailPDF() {
    debugger;
    this.email.Isgridpdf = true;
    this.export(this.pdf);
  }
  excel() {

    this.email.filename = "Airline Detailed Report.xlsx";
    this.email.reportname = "Airline Detailed Report";

    this.open();
    //args.preventDefault();
  }
  public async onExcelExport2(args: any) {
    debugger;
    this.email.filename = "Airline Detailed Report.xlsx";
    this.email.reportname = "Airline Detailed Report";

    // Prevent automatically saving the file. We will save it manually after we fetch and add the details
    args.preventDefault();

    const observables = [];
    let workbook = args.workbook;

    let rows = workbook.sheets[0].rows;

    let headerOptions = rows[0].cells[0];

    var reader = new FileReader();
    var sheet = workbook.sheets[0];
    if ((this.AirlineDetailedReportService.formData.CreateDateFrom != undefined && this.AirlineDetailedReportService.formData.CreateDateFrom != null && this.AirlineDetailedReportService.formData.CreateDateFrom != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'Creation Date', this.common.ConvertToLocaleDate(this.AirlineDetailedReportService.formData.CreateDateFrom), this.common.ConvertToLocaleDate(this.AirlineDetailedReportService.formData.CreateDateTo));

    }
    if ((this.AirlineDetailedReportService.formData.TicketDateFrom != undefined && this.AirlineDetailedReportService.formData.TicketDateFrom != null && this.AirlineDetailedReportService.formData.TicketDateFrom != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'Ticket Date', this.common.ConvertToLocaleDate(this.AirlineDetailedReportService.formData.TicketDateFrom), this.common.ConvertToLocaleDate(this.AirlineDetailedReportService.formData.TicketDateTo));

    }
    if ((this.AirlineDetailedReportService.formData.TravelDateFrom != undefined && this.AirlineDetailedReportService.formData.TravelDateFrom != null && this.AirlineDetailedReportService.formData.TravelDateFrom != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'travel Date', this.common.ConvertToLocaleDate(this.AirlineDetailedReportService.formData.TravelDateFrom), this.common.ConvertToLocaleDate(this.AirlineDetailedReportService.formData.TravelDateTo));

    }
    if ((this.AirlineDetailedReportService.formData.FareCommFrom != undefined && this.AirlineDetailedReportService.formData.FareCommFrom != null && this.AirlineDetailedReportService.formData.FareCommFrom != "")) {
      this.common.generateExcelFilterheader(sheet, 'Fare Comm Amt', this.AirlineDetailedReportService.formData.FareCommFrom.toString(), this.AirlineDetailedReportService.formData.FareCommTo.toString());

    }
    if ((this.AirlineDetailedReportService.formData.Airline != undefined && this.AirlineDetailedReportService.formData.Airline != null && this.AirlineDetailedReportService.formData.Airline != "")) {
      this.common.generateExcelFilterheader(sheet, 'Airline', this.AirlineDetailedReportService.formData.Airline.toString(), '');

    }
    if ((this.AirlineDetailedReportService.formData.ClassType != undefined && this.AirlineDetailedReportService.formData.ClassType != null && this.AirlineDetailedReportService.formData.ClassType != "")) {
      this.common.generateExcelFilterheader(sheet, 'Class Type', this.AirlineDetailedReportService.formData.ClassType.toString(), '');

    }
    if ((this.AirlineDetailedReportService.formData.CustPaymentMethod != undefined && this.AirlineDetailedReportService.formData.CustPaymentMethod != null && this.AirlineDetailedReportService.formData.CustPaymentMethod != "")) {
      this.common.generateExcelFilterheader(sheet, 'Cust Payment Method', this.AirlineDetailedReportService.formData.CustPaymentMethod.toString(), '');

    }
    if ((this.AirlineDetailedReportService.formData.CustomerName != undefined && this.AirlineDetailedReportService.formData.CustomerName != null && this.AirlineDetailedReportService.formData.CustomerName != "")) {
      this.common.generateExcelFilterheader(sheet, 'Customer', this.AirlineDetailedReportService.formData.CustomerName.toString(), '');

    }
    if ((this.AirlineDetailedReportService.formData.SuppName != undefined && this.AirlineDetailedReportService.formData.SuppName != null && this.AirlineDetailedReportService.formData.SuppName != "")) {
      this.common.generateExcelFilterheader(sheet, 'Supplier', this.AirlineDetailedReportService.formData.SuppName.toString(), '');

    }
    if ((this.AirlineDetailedReportService.formData.Destination != undefined && this.AirlineDetailedReportService.formData.Destination != null && this.AirlineDetailedReportService.formData.Destination != "")) {
      this.common.generateExcelFilterheader(sheet, 'Destination', this.AirlineDetailedReportService.formData.Destination.toString(), '');

    }
    if ((this.AirlineDetailedReportService.formData.Branch != undefined && this.AirlineDetailedReportService.formData.Branch != null && this.AirlineDetailedReportService.formData.Branch != "")) {
      this.common.generateExcelFilterheader(sheet, 'Branch', this.AirlineDetailedReportService.formData.Branch.toString(), '');

    }
    if ((this.AirlineDetailedReportService.formData.BookedBy != undefined && this.AirlineDetailedReportService.formData.BookedBy != null && this.AirlineDetailedReportService.formData.BookedBy != "")) {
      this.common.generateExcelFilterheader(sheet, 'BookedBy', this.AirlineDetailedReportService.formData.BookedBy.toString(), '');

    }
    if ((this.AirlineDetailedReportService.formData.PaymentMethodName != undefined && this.AirlineDetailedReportService.formData.PaymentMethodName != null && this.AirlineDetailedReportService.formData.PaymentMethodName != "")) {
      this.common.generateExcelFilterheader(sheet, 'Supp PaymentMethodName', this.AirlineDetailedReportService.formData.PaymentMethodName.toString(), '');

    }
    if ((this.AirlineDetailedReportService.formData.PCC != undefined && this.AirlineDetailedReportService.formData.PCC != null && this.AirlineDetailedReportService.formData.PCC != "")) {
      this.common.generateExcelFilterheader(sheet, 'PCC', this.AirlineDetailedReportService.formData.PCC.toString(), '');

    }
    if ((this.AirlineDetailedReportService.formData.Destinationcountry != undefined && this.AirlineDetailedReportService.formData.Destinationcountry != null && this.AirlineDetailedReportService.formData.Destinationcountry != "")) {
      this.common.generateExcelFilterheader(sheet, 'Destination Country', this.AirlineDetailedReportService.formData.Destinationcountry.toString(), '');

    }
    if ((this.AirlineDetailedReportService.formData.origin != undefined && this.AirlineDetailedReportService.formData.origin != null && this.AirlineDetailedReportService.formData.origin != "")) {
      this.common.generateExcelFilterheader(sheet, 'Origin Country', this.AirlineDetailedReportService.formData.origin.toString(), '');

    }
    if ((this.email.reportname != undefined)) {
      this.common.generateExcelMainheader(sheet, this.email.reportname);
    }
    await this.common.SetCompanyheaderExcelheader(sheet)
    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      saveAs(dataUrl, "Airline Detailed Report.xlsx");
      this.email.fileurl = this.dataURItoBlob(dataUrl);
    });
    // new Workbook(workbook).toDataURL().then((dataUrl: string) => {
    //   debugger;
    //   saveAs(dataUrl, "Airline Detailed Report.xlsx");

    //   //  this.email.fileurl=this.dataURItoBlob( reader.result);
    //   this.email.fileurl = this.dataURItoBlob(dataUrl);

    // this.open();
    // });
  }
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // // separate out the mime component
    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];

    // // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // // create a view into the buffer
    var ia = new Uint8Array(ab);

    // // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });

    return blob;
  }
  public rightAlign: object = {
    "text-align": "right"
  };
  onCancel() {
    var reporturl = sessionStorage.getItem("menuhighlight");
    this.AirlineDetailedReportService.griddata = "";
    if (reporturl != null) {
      this.router.navigate(["/Login"]);
    } else {

      this.router.navigate(["/PenAir/Reports/AirlineDetailedReport"]);
    }

  }
  public EmailFormClosing(
    status: any //Email status change by case id-12434
  ) {
    if (status == "EmailSendSuccesfully" || status == "Closed") {
      //Email status change by case id-12434
      this.opened = false;
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
