import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EnvService } from 'src/env.service';
import { FolderAddExpense } from '../Models/folder-add-expense.model';
import { CommonserviceService } from './commonservice.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class FolderAddExpenseService {
  apiUrl: string;
  public List: FolderAddExpense[];

  constructor(private env: EnvService, private http: HttpClient,
    public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
   }

  

  UploadFolderAddExpenseFileDetails(FolderExpenseList:any): Observable<FolderAddExpense> {
    debugger;
    var body = {
      List: FolderExpenseList
    };
    return this.http.post<FolderAddExpense>(this.apiUrl + 'UploadFolderAddExpenseFileDetails', body).pipe(
      tap(_ => console.log(`Upload Excel file Details`)),
      catchError(this.commonservice.handleError<any>(`Upload Excel file Details`))
    );;
  }


  GetFolderAddExpenseMappingDetails(PMID:any): Observable<any> {
    debugger;
    const url = `${this.apiUrl}` + 'GetFolderAddExpenseMappingDetails'+`/${PMID}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`Folder add expense mapping details`)),
      catchError(this.commonservice.handleError<any>(`Folder add expense mapping details`))
    );
  }
}
