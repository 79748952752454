export class LocalizationModel {
    CustomerForm: CustomerForm;
    FolderForm: FolderForm;
    SupplierForm: SupplierForm;
}
export class CustomerForm{
    Labels:{
        PostCode: "Post Code",
        Addr1: "Address 1",
        Addr2: "Address 2",
        Addr3: "Address 3",
        Addr4: "Address 4",
        VATNumber: "VAT Number",
         Citizenship: "Citizenship",
         language: "Language",                          
          currency: "Currency",
          country: "Country"
               }
}
export class FolderForm{
    Labels:{
                        
        city: "City",
       county_province_state: "County/Province/State",
       post_code: "Post Code",
       country: "Country",                       
       address1: "Address 1",
       address2: "Address 2",
       address3: "Address 3"
        }
}
export class SupplierForm{
    Labels:{
        PostCode: "Post Code",
        Addr1: "Address 1",
        Addr2: "Address 2",
        Addr3: "Address 3",
        Addr4: "Address 4",
        VATNumber: "VAT Number",
         Citizenship: "Citizenship",
         language: "Language",                          
          currency: "Currency",
          Country: "Country",
          email: "Email",
          website: "Website"                        
              }
}
