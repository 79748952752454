import { Component, OnInit, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { RailDetail } from '../../Models/rail-detail';
import { RailService } from '../../Services/rail.service';
import { Router, ActivatedRoute } from '@angular/router';
import { dateFieldName } from '@telerik/kendo-intl';
import { ShareDataService } from '../../Services/share-data.service';

import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { CurrencyExchangerateService } from '../../Services/currency-exchangerate.service';
import { CurrencyExchangerate } from '../../Models/currency-exchangerate';
import { FlashMessagesService } from 'angular2-flash-messages';
import { NgxSpinnerService } from "ngx-spinner";

import { TranslateService } from '@ngx-translate/core';
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription, Subject } from 'rxjs';
import { SupplierMasterService } from '../../Services/supplier-master.service';
import { ToastrService } from 'ngx-toastr';
import { PocreationService } from '../../Services/pocreation.service';
import { ProductUpdatePODTO } from '../../Models/pocreation-detail';
import { FolderpriceService } from '../../Services/folderprice.service';
@Component({
  selector: 'app-rail-details-edit',
  templateUrl: './rail-details-edit.component.html',
  styleUrls: ['./rail-details-edit.component.scss']
})
export class RailDetailsEditComponent implements OnInit {
  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  private subscription: Subscription = new Subscription();
  @ViewChild('fromcity') public fromcity: AutoCompleteComponent;
  @ViewChild('tocity') public tocity: AutoCompleteComponent;
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  @ViewChild("apppaymenttermsedit", { read: false, static: false }) public payterm: any;
  @ViewChild("appfchcdetailsedit", { read: false, static: false }) public fchcdetails: any;
  openSearch = false;
  public opened = false;
  folderEditMode: any;
  folderExrate: any;
  fldrCurrency: any;
  private exRate: any;
  prdtUpdatePODTO: ProductUpdatePODTO;
  Uname: any = "";
  msg: string;
  @Output() AlertMessage = new EventEmitter<string>();
  currencyExrate: CurrencyExchangerate;
  ucode: string;
  railEditForm: FormGroup;
  submitted = false;
  loaded = false;
  savebutton = true;
  public pocreated = true;
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  FC_Tot_BuyInitalvalue: any = 0;
  FCSell: any = 0;
  //hotelDetailModal=HotelDetail;
  public statusList: Array<string> = ["Wait listed", "Confirmed", "Cancelled"];
  public paymethodList: Array<string> = ["Credit", "Company Card", "Customer Card", "Customer Pay Locally"];
  public CommonList: Array<string> = ["Purchase", "Gross", "Sales"];
  public CardList: Array<LookUpDetails> = [];
  ticketDate: any //337
  constructor(private apiPO: PocreationService, public common: CommonserviceService, private lookupApi: LookUpDetailsService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private api: RailService, private apiExRate: CurrencyExchangerateService, private apiShared: ShareDataService
    , private flashMessage: FlashMessagesService, private spinner: NgxSpinnerService, private translateapi: TranslateService, private supplier: SupplierMasterService, private toastr: ToastrService, private objFolderPrice: FolderpriceService
  ) { }
  private guid: string = this.route.snapshot.params['guidItinerary'];//'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/';
  private orderno = this.route.snapshot.params['orderNo']
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];

  public supplierSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelected: String;
  BOOKEBYList: Array<LookUpDetails["ResponseData"]> = [];
  public bookbyselectedItem: LookUpDetails["ResponseData"];

  currencyList: Array<LookUpDetails["ResponseData"]> = [];
  public currencySelectedItem: LookUpDetails["ResponseData"];
  public currencySelected: String;

  destinationList: Array<LookUpDetails["ResponseData"]> = [];
  destinationfromList: Array<LookUpDetails["ResponseData"]> = [];
  public destinationSelectedItem: LookUpDetails["ResponseData"];
  public destinationToSelectedItem: LookUpDetails["ResponseData"];

  public destinationSelected: String;
  public destinationToSelected: String;

  public projNo: any;
  public showMsg: any;
  disabled = true;
  hcExRate: any;
  homeCurrency: any;
  public PONO;
  creditLimitMsg = false;
  creditLimiFlag = false;
  lockStatus: any;
  folderStatus: any;
  formeditable: boolean = true;
  public InterIndList: Array<string> = ["International", "Domestic", "Transborder"];
  public interIndListId: any = '';
  ngOnInit() {

    //case id : 13777 
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    } else {
      this.formeditable = false;

    }
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;

    }
    // case id : 13777
    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderno !== orderno) {
        return this.router.navigate(['PenAir/Home']);
      }
    this.hcExRate = this.apiShared.hcExRate;
    this.homeCurrency = this.apiShared.homeCurrency;
    this.folderEditMode = "true";
    // this.apiShared.folderEditMode=this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.Uname = this.apiShared.uname;
    this.getFolderValues();
    this.railEditForm = this.formBuilder.group({
      FOLDERNO: [this.orderno],
      SUPPNAME: ['', Validators.required],
      SUPPCODE: [''],
      TYPE: [''],
      FROMRAIL: [''],
      DEPDATE: [''],
      DEPTIME: [0],
      TORAIL: [''],
      ARRDATE: [''],
      ARRTIME: [0],
      STATUS: [''],
      AMOUNT: [0],
      VATCODE: [0],
      VATPER: [0],
      VOUCHER: [''],
      PAYMODE: [''],
      CURCODE: [this.homeCurrency],
      AMTLC: [0],
      AMTHC: [0],
      CommOn: ['Purchase'],
      PASS: [''],
      SLNO: [0],
      PROJNO: [this.projNo],
      ITYPE: ['Rail'],
      Provider: [''],
      GDS: [''],
      PNR: [''],
      CURExRate: [this.hcExRate],
      FCBuy: [0],
      FCCommPer: [0],
      FCCommAmt: [0],
      FCSell: [0],
      HCCommPer: [0],
      HCCommAmt: [0],
      HCSell: [0],
      ItnryNotes: [''],
      TICKETNO: [''],
      TICKETDATE: [''],
      FROMRAILNAME: [''],
      TORAILNAME: [''],
      LowFB: [''],
      LowFare: [0],
      HighFB: [''],
      HighFare: [0],
      Fareoffered: [0],
      PotentialSaving: [0],
      ActualSaving: [0],
      PDNO: [0],
      PDTYPENO: [0],
      Forgone: [0],
      AddRemarks: [''],
      PDAMOUNT: [0],
      PONO: [0],
      PONOYEARID: [''],
      PAYMENTID: [0],
      PMTID: [''],
      DEFDEPPER: [0],
      DEFDEP: [0],
      DepositDueDate: [''],
      BalanceDueDate: [''],
      BalanceDueAmount: [0],
      GUIDItinerary: [''],
      LOYALTYPOINTS: [0],
      LOYALTYPOINTSMEMBNO: [''],
      TOMSVATTAXNO: [0],
      TOMSVATAMT: [0],
      PDUNIQUEREF: [''],
      LOYALTYPOINTSAMT: [0],
      PDFCURID: [this.homeCurrency],
      PDFCAMT: [0],
      PDEXRATE: [this.hcExRate],
      SELLTAXNO: [0],
      SELLTAXRATE: [0],
      FC_SELLTAXAMT: [0],
      HC_SELLTAXAMT: [0],
      PONOCCDeferredPosted: [0],
      BOOKEDBY: this.Uname,
      TaxExcludingSalesTax: [0],
      BaseFare: [0],
      GSTHSTOnComm: [0],
      InterInd: [''],
      //12698
      CostingSupplierGrossValue: 0,
      CostingSupplierServiceFee: 0,
      CostingTaxNo: 0,
      CostingTaxRate: 0,
      CostingSupplierTaxAmount: 0,
      CostingSupplierNetBuyPrice: 0,
      CostingCustomerSalesValue: 0,
      CostingCustomerServiceFee: 0,
      CostingCustomerTaxAmount: 0,
      CostingCustomerNetSellPrice: 0,
      CostingVATType: 0,
      Markup: [0],
      CostofSale: [0],
      CostingSuppComm: [0],
      SupplierDeposit: 0,
      CountryID: ['']

      //12698

    }
    );
    if (this.guid != null)
      this.getRailDetails(this.orderno, this.guid);
    if (this.guid == null) {
      this.FormLoyalityPointsChange();
      this.loaded = true;
    }
    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.BOOKEBYList = data["ResponseData"]
          // alert(this.railEditForm.controls.BOOKEDBY.value); 
          if (this.railEditForm.controls.BOOKEDBY.value != "") {
            this.bookbyselectedItem = this.BOOKEBYList.find(item => item.Name == this.railEditForm.controls.BOOKEDBY.value);

          }
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('CURRENCY').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.currencyList = data["ResponseData"]
          //  alert("Currency="+this.railEditForm.controls.CURCODE.value);

          if (this.railEditForm.controls.CURCODE.value != "") {

            this.currencySelectedItem = this.currencyList.find(item => item.Code == this.railEditForm.controls.CURCODE.value);


          }
        }
      }
    ));
    // this.lookupApi.GetAllLookup('DESTINATION').subscribe(
    //   (data) => {


    //      this.destinationList= data["ResponseData"] ; 
    //      if(this.railEditForm.controls.FROMRAIL.value!="")
    //      {            
    //        this.destinationSelectedItem =this.destinationList.find(item => item.UsrCode ==this.railEditForm.controls.FROMRAIL.value);
    //        if(this.destinationSelectedItem!=null)
    //        this.destinationSelected=this.destinationSelectedItem.Name;  
    //     }
    //     if(this.railEditForm.controls.TORAIL.value!="")
    //      {            
    //        this.destinationToSelectedItem =this.destinationList.find(item => item.UsrCode ==this.railEditForm.controls.TORAIL.value); 
    //        if(this.destinationToSelectedItem!=null)
    //        this.destinationToSelected=this.destinationToSelectedItem.Name;
    //     }    

    //   }
    //   );

    // this.loaded=true;


    this.onChanges();


  }
  get f() { return this.railEditForm.controls; }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  getRailDetails(id, guid) {
    this.spinner.show();
    this.subscription.add(this.api.getRail(id, guid, false)
      .subscribe(data => {
        if (data != null && data != undefined) {
          this.railEditForm.setValue({

            FOLDERNO: data.ResponseData.FOLDERNO,
            SUPPNAME: data.ResponseData.SUPPNAME,
            SUPPCODE: data.ResponseData.SUPPCODE,
            TYPE: data.ResponseData.TYPE,
            FROMRAIL: data.ResponseData.FROMRAIL,
            DEPDATE: data.ResponseData.DEPDATE != null ? (data.ResponseData.DEPDATE.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.DEPDATE)) : null,
            //DEPDATE:new Date(data.ResponseData.DEPDATE) ,
            DEPTIME: data.ResponseData.DEPTIME,
            TORAIL: data.ResponseData.TORAIL,
            ARRDATE: data.ResponseData.ARRDATE != null ? (data.ResponseData.ARRDATE.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.ARRDATE)) : null,
            //ARRDATE:new Date(data.ResponseData.ARRDATE),
            ARRTIME: data.ResponseData.ARRTIME,
            STATUS: data.ResponseData.STATUS,
            AMOUNT: data.ResponseData.AMOUNT,
            VATCODE: data.ResponseData.VATCODE,
            VATPER: data.ResponseData.VATPER,
            VOUCHER: data.ResponseData.VOUCHER,
            PAYMODE: data.ResponseData.PAYMODE,
            CURCODE: data.ResponseData.CURCODE,
            AMTLC: data.ResponseData.AMTLC,
            AMTHC: data.ResponseData.AMTHC,
            CommOn: data.ResponseData.CommOn,
            PASS: data.ResponseData.PASS,
            SLNO: data.ResponseData.SLNO,
            PROJNO: data.ResponseData.PROJNO,
            ITYPE: "Rail",
            Provider: data.ResponseData.Provider,
            GDS: data.ResponseData.GDS,
            PNR: data.ResponseData.PNR,
            CURExRate: data.ResponseData.CURExRate,
            FCBuy: data.ResponseData.FCBuy,
            FCCommPer: data.ResponseData.FCCommPer,
            FCCommAmt: data.ResponseData.FCCommAmt,
            FCSell: data.ResponseData.FCSell,
            HCCommPer: data.ResponseData.HCCommPer,
            HCCommAmt: data.ResponseData.HCCommAmt,
            HCSell: data.ResponseData.HCSell,
            ItnryNotes: data.ResponseData.ItnryNotes,
            TICKETNO: data.ResponseData.TICKETNO,
            TICKETDATE: data.ResponseData.TICKETDATE != null ? (data.ResponseData.TICKETDATE.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.TICKETDATE)) : null,
            //TICKETDATE:new Date(data.ResponseData.TICKETDATE),
            FROMRAILNAME: data.ResponseData.FROMRAILNAME,
            TORAILNAME: data.ResponseData.TORAILNAME,
            LowFB: data.ResponseData.LowFB,
            LowFare: data.ResponseData.LowFare,
            HighFB: data.ResponseData.HighFB,
            HighFare: data.ResponseData.HighFare,
            Fareoffered: data.ResponseData.Fareoffered,
            PotentialSaving: data.ResponseData.PotentialSaving,
            ActualSaving: data.ResponseData.ActualSaving,
            PDNO: data.ResponseData.PDNO,
            PDTYPENO: data.ResponseData.PDTYPENO,
            Forgone: data.ResponseData.Forgone,
            AddRemarks: data.ResponseData.AddRemarks,
            PDAMOUNT: data.ResponseData.PDAMOUNT,
            PONO: data.ResponseData.PONO,
            PONOYEARID: data.ResponseData.PONOYEARID,
            PAYMENTID: data.ResponseData.PAYMENTID,
            PMTID: data.ResponseData.PMTID,
            DEFDEPPER: data.ResponseData.DEFDEPPER,
            DEFDEP: data.ResponseData.DEFDEP,
            DepositDueDate: data.ResponseData.DepositDueDate != null ? (data.ResponseData.DepositDueDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.DepositDueDate)) : null,
            //DepositDueDate:new Date(data.ResponseData.DepositDueDate),
            BalanceDueDate: data.ResponseData.BalanceDueDate != null ? (data.ResponseData.BalanceDueDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.BalanceDueDate)) : null,
            //BalanceDueDate:new Date(data.ResponseData.BalanceDueDate) ,
            BalanceDueAmount: data.ResponseData.BalanceDueAmount,
            GUIDItinerary: data.ResponseData.GUIDItinerary,
            LOYALTYPOINTS: data.ResponseData.LOYALTYPOINTS,
            LOYALTYPOINTSMEMBNO: data.ResponseData.LOYALTYPOINTSMEMBNO,
            TOMSVATTAXNO: data.ResponseData.TOMSVATTAXNO,
            TOMSVATAMT: data.ResponseData.TOMSVATAMT,
            PDUNIQUEREF: data.ResponseData.PDUNIQUEREF,
            LOYALTYPOINTSAMT: data.ResponseData.LOYALTYPOINTSAMT,
            PDFCURID: data.ResponseData.PDFCURID,
            PDFCAMT: data.ResponseData.PDFCAMT,
            PDEXRATE: data.ResponseData.PDEXRATE,
            SELLTAXNO: data.ResponseData.SELLTAXNO,
            SELLTAXRATE: data.ResponseData.SELLTAXRATE,
            FC_SELLTAXAMT: data.ResponseData.FC_SELLTAXAMT,
            HC_SELLTAXAMT: data.ResponseData.HC_SELLTAXAMT,
            PONOCCDeferredPosted: data.ResponseData.PONOCCDeferredPosted,
            BOOKEDBY: data.ResponseData.BOOKEDBY,
            TaxExcludingSalesTax: data.ResponseData.TaxExcludingSalesTax,
            BaseFare: data.ResponseData.BaseFare,
            GSTHSTOnComm: 0,

            CostingSupplierGrossValue: data.ResponseData.CostingSupplierGrossValue,
            CostingSupplierServiceFee: data.ResponseData.CostingSupplierServiceFee,
            CostingTaxNo: data.ResponseData.CostingTaxNo,
            CostingTaxRate: data.ResponseData.CostingTaxRate,
            CostingSupplierTaxAmount: data.ResponseData.CostingSupplierTaxAmount,
            CostingSupplierNetBuyPrice: data.ResponseData.CostingSupplierNetBuyPrice,
            CostingCustomerSalesValue: data.ResponseData.CostingCustomerSalesValue,
            CostingCustomerServiceFee: data.ResponseData.CostingCustomerServiceFee,
            CostingCustomerTaxAmount: data.ResponseData.CostingCustomerTaxAmount,
            CostingCustomerNetSellPrice: data.ResponseData.CostingCustomerNetSellPrice,
            CostingVATType: data.ResponseData.CostingVATType,
            Markup: data.ResponseData.CostingCustomerServiceFee,
            CostofSale: data.ResponseData.CostingCustomerSalesValue,
            CostingSuppComm: data.ResponseData.CostingSuppComm,
            InterInd: data.ResponseData.InterInd == "" ? "" : data.ResponseData.InterInd == "I" ? "International" : (data.ResponseData.InterInd == "D" ? "Domestic" : (data.ResponseData.InterInd == "T" ? "Transborder" : "")),
            SupplierDeposit: data.ResponseData.SupplierDeposit,
            CountryID: ['']


          });
          this.FC_Tot_BuyInitalvalue = this.railEditForm.controls.FCBuy.value
          this.FCSell = this.railEditForm.controls.FCSell.value;
          this.spinner.hide();
          //this.isLoadingResults = false;
          this.FormLoyalityPointsChange();
          this.PONO = this.railEditForm.controls.PONO.value;

          if (this.PONO > 0) {

            this.pocreated = false;
          }
          else {
            this.pocreated = true;
          }
          this.loaded = true;
          this.loadtocity();
          this.loadfromcity();
          this.loadsuppliermaster();
          if (data.ResponseData.BOOKEDBY != "")//By Case id-355
          {
            this.bookbyselectedItem = this.BOOKEBYList.find(item => item.Name == data.ResponseData.BOOKEDBY);
          }
        } else {
          this.spinner.hide();
        }
      }));
  }

  public loadtocity() {

    if (this.railEditForm.controls.TORAIL.value != '' && this.railEditForm.controls.TORAIL.value != null) {
      this.subscription.add(this.lookupApi.SearchDestination(this.railEditForm.controls.TORAIL.value, '0').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            if (data["ResponseData"].length > 0) {
              this.destinationList = data["ResponseData"];
              if (this.railEditForm.controls.TORAIL.value != "") {
                this.destinationToSelectedItem = this.destinationList.find(item => item.UsrCode == this.railEditForm.controls.TORAIL.value);
                if (this.destinationToSelectedItem != null) {
                  this.destinationToSelected = this.destinationToSelectedItem.Name;

                }
              }
            }
            else {
              this.destinationToSelected = this.railEditForm.controls.TORAIL.value;
            }

          }

        }
      ));
    }
  }

  public loadfromcity() {

    if (this.railEditForm.controls.FROMRAIL.value != '' && this.railEditForm.controls.FROMRAIL.value != null) {
      this.subscription.add(this.lookupApi.SearchDestination(this.railEditForm.controls.FROMRAIL.value, '0').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            if (data["ResponseData"].length > 0) {
              this.destinationfromList = data["ResponseData"];
              if (this.railEditForm.controls.FROMRAIL.value != "") {
                this.destinationSelectedItem = this.destinationfromList.find(item => item.UsrCode == this.railEditForm.controls.FROMRAIL.value);
                if (this.destinationSelectedItem != null) {
                  this.destinationSelected = this.destinationSelectedItem.Name;
                }
              }
            }
            else {
              this.destinationSelected = this.railEditForm.controls.FROMRAIL.value;
            }
          }
        }
      ));
    }
  }
  public CurrencyExRatechange(): void {
    this.CalculateForm();
  }
  public DestinationValueChange(value: any): void {

    this.destinationSelectedItem = this.destinationfromList.find(item => item.Name == value);

    if (this.destinationSelectedItem != null) {
      this.railEditForm.controls.FROMRAIL.setValue(this.destinationSelectedItem.Code);
      this.railEditForm.controls.FROMRAILNAME.setValue(this.destinationSelectedItem.ActualName);
    }
    else if (value == "" || value == null) {
      this.destinationSelected = "";
      this.railEditForm.controls.FROMRAIL.setValue("");
      this.railEditForm.controls.FROMRAILNAME.setValue("");
    }
    else {
      this.destinationSelected = value;
      this.railEditForm.controls.FROMRAIL.setValue(value);
      this.railEditForm.controls.FROMRAILNAME.setValue(value);
    }
  }

  loadsuppliermaster() {

    if (this.railEditForm.controls.SUPPCODE.value != '' && this.railEditForm.controls.SUPPCODE.value != null) {
      this.subscription.add(this.lookupApi.findSupplier(this.railEditForm.controls.SUPPCODE.value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
            if (this.railEditForm.controls.SUPPCODE.value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.railEditForm.controls.SUPPCODE.value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
              }
            }
          }
        }
      ));
    }
  }
  handleFiltersupplier(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  public DestinationToValueChange(value: any): void {
    debugger;
    this.destinationToSelectedItem = this.destinationList.find(item => item.Name == value);
    if (this.destinationToSelectedItem != null) {
      this.railEditForm.controls.TORAIL.setValue(this.destinationToSelectedItem.Code);
      this.railEditForm.controls.TORAILNAME.setValue(this.destinationToSelectedItem.ActualName);
    }
    else if (value == "" || value == null) {
      this.destinationToSelected = "";
      this.railEditForm.controls.TORAIL.setValue("");
      this.railEditForm.controls.TORAILNAME.setValue("");
    }
    else {
      this.destinationToSelected = value;
      this.railEditForm.controls.TORAIL.setValue(value);
      this.railEditForm.controls.TORAILNAME.setValue(value);
    }
  }
  handletocity(value) {
    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            if (data["ResponseData"].length > 0)
              this.destinationList = data["ResponseData"];
            else {
              this.railEditForm.controls.TORAIL.setValue(value);
              this.railEditForm.controls.TORAILNAME.setValue(value);
            }
          }

        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.railEditForm.controls.TORAIL.setValue(value);
      this.railEditForm.controls.TORAILNAME.setValue(value);
      this.tocity.toggle(false);
    }
  }
  handlefromcity(value) {
    if (value.length >= 3) {
      debugger;
      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            if (data["ResponseData"].length > 0)
              this.destinationfromList = data["ResponseData"];
            else {
              this.railEditForm.controls.FROMRAIL.setValue(value);
              this.railEditForm.controls.FROMRAILNAME.setValue(value);
            }
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.railEditForm.controls.FROMRAIL.setValue(value);
      this.railEditForm.controls.FROMRAILNAME.setValue(value);
      this.fromcity.toggle(false);
    }
  }
  onFormSubmit() {
    this.spinner.show();
    if (this.railEditForm.controls.PAYMODE.value == "Company Card")//By case id-355
    {
      if ((this.railEditForm.controls.PDAMOUNT.value == 0) || (this.railEditForm.controls.PDNO.value == "0") || (this.railEditForm.controls.PDTYPENO.value == "0")) {
        this.subscription.add(this.translateapi.get(['Messages.PleaseSelectCompanyCardWithPaymentOption']).subscribe((translations) => {

          this.showMsg = translations['Messages.PleaseSelectCompanyCardWithPaymentOption'];;
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        this.spinner.hide();
        return;
      }
    }

    this.ticketDate = (this.railEditForm.controls.TICKETDATE.value == null || this.railEditForm.controls.TICKETDATE.value == "" ? "01-Jan-1900" : this.railEditForm.controls.TICKETDATE.value);//Case id-337

    if (Boolean(this.common.ValidDateRange(this.ticketDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidTicketDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidTicketDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (this.railEditForm.value['CURExRate'] === '' || this.railEditForm.value['CURExRate'] === 0 || this.railEditForm.value['CURExRate'] === null) {
      this.subscription.add(this.translateapi.get(['Messages.ExchangeRateError']).subscribe((translations) => {
        this.showMsg = translations['Messages.ExchangeRateError'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.spinner.hide();
      return;
    }
    this.submitted = true;

    //console.log(this.railEditForm.get('FOLDERNO').value);
    // stop here if form is invalid
    if (this.railEditForm.invalid) {
      this.spinner.hide();
      return;
    }

    this.objFolderPrice
      .CreditLimitCheck(this.guid == null || undefined ? "0" : this.guid, 0, this.orderno, this.railEditForm.get("HCSell").value)
      .subscribe(res => {
        if (res != undefined || res["ResponseData"] != null) {
          if (
            res["ResponseData"].value != "0" &&
            res["ResponseData"].Status != "0" &&
            res["ResponseData"].ErrorMessage != ""
          ) {
            debugger;
            if (res["ResponseData"].value == 1) {
              this.spinner.hide();
              this.msg = res["ResponseData"].ErrorMessage;
              this.creditLimiFlag = true;
              this.creditLimitMsg = true;

              return false;
            } else if (res["ResponseData"].value == 2) {
              this.spinner.hide();
              this.msg = res["ResponseData"].ErrorMessage;
              this.creditLimiFlag = false;
              this.creditLimitMsg = true;
              // this.AirTicketSaveAndUpdate();
            } else {
              this.RailDetailsSaveandUpdate();
            }
          } else {
            this.RailDetailsSaveandUpdate();
          }
        }
      });




  }
  RailDetailsSaveandUpdate() {
    this.railEditForm.controls.BaseFare.setValue(this.railEditForm.controls.FCSell.value);  

    if (this.guid != null && this.PONO > 0) {
      this.prdtUpdatePODTO = new ProductUpdatePODTO();
      this.prdtUpdatePODTO.FOLDERNO = this.orderno;
      this.prdtUpdatePODTO.GUIDItinerary = this.railEditForm.get('GUIDItinerary').value;
      this.prdtUpdatePODTO.LOYALTYPOINTS = this.railEditForm.get('LOYALTYPOINTS').value;
      this.prdtUpdatePODTO.LOYALTYPOINTSAMT = this.railEditForm.get('LOYALTYPOINTSAMT').value
      // this.prdtUpdatePODTO.LOYALTYPOINTSBAL=this.railEditForm.get('LOYALTYPOINTSBAL').value
      this.prdtUpdatePODTO.LOYALTYPOINTSMEMBNO = this.railEditForm.get('LOYALTYPOINTSMEMBNO').value;
      this.prdtUpdatePODTO.TYPE = "Rail";
      this.prdtUpdatePODTO.BaseFare = this.railEditForm.get('BaseFare').value;
      this.prdtUpdatePODTO.SELLAMT = this.railEditForm.get('FCSell').value;
      this.prdtUpdatePODTO.SELLCOMM = this.railEditForm.get('HCCommAmt').value;

      this.prdtUpdatePODTO.HCCommAmt = this.railEditForm.get('HCCommAmt').value;

      // this.prdtUpdatePODTO.fCSellTax=this.hotelEditForm.get('RDESC').value;

      this.prdtUpdatePODTO.SELLTAXNO = this.railEditForm.get('SELLTAXNO').value;
      this.prdtUpdatePODTO.SELLTAXRATE = this.railEditForm.get('SELLTAXRATE').value;

      this.prdtUpdatePODTO.PAYABLE = this.railEditForm.get('AMOUNT').value;
      this.prdtUpdatePODTO.FC_CIDExRate = this.railEditForm.get('CURExRate').value;
      this.prdtUpdatePODTO.CostingCustomerNetSellPrice = this.railEditForm.get('CostingCustomerNetSellPrice').value;
      this.prdtUpdatePODTO.CostingCustomerServiceFee = this.railEditForm.get('CostingCustomerServiceFee').value;
      this.prdtUpdatePODTO.CostingCustomerTaxAmount = this.railEditForm.get('CostingCustomerTaxAmount').value;
      //this.apiPO.formData=
      this.apiPO.formData = this.prdtUpdatePODTO;
      this.subscription.add(this.apiPO.SaveProductAfterPO()
        .subscribe(res => {
          if (res.IsSuccess === true && res.Message === 'Success') {
            this.spinner.hide();
            this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
              this.showMsg = translations['Messages.saved_success'];
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }));
            this.router.navigate(['/PenAir/Product/RailDetails', this.orderno, this.guid]);
          } else {
            this.spinner.hide();
            this.toastr.warning(res.ResponseData.ErrorMessage, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          }
        }, (err) => {
          this.spinner.hide();
          console.log(err);
          this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
            this.showMsg = translations['Messages.some_error_in_save'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
        }
        ));

    }


    else {
      //this.hotelDetailModal= Object.assign({}, this.railEditForm.value);
      if (this.guid != null) {
        this.spinner.show();
        if (this.CompanyCardValidation() == true) {
          this.subscription.add(this.api.UpdateRailDetails(this.railEditForm.value)
            .subscribe(res => {
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));
                  this.spinner.hide();
                  this.router.navigate(['/PenAir/Product/RailDetails', this.orderno, this.guid + '/']);
                } else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
              else {
                this.spinner.hide();
              }
            }, (err) => {
              this.spinner.hide();
              console.log(err);
              this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                this.showMsg = translations['Messages.some_error_in_save'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
            }
            ));
        }
        else {
          this.spinner.hide();
        }
      }

      else {
        this.spinner.show();
        if (this.CompanyCardValidation() == true) {
          this.subscription.add(this.api.AddRailDetails(this.railEditForm.value)
            .subscribe(res => {
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  // alert('SUCCESS223!! :-)\n\n' + JSON.stringify(this.railEditForm.value, null, 4));
                  this.spinner.hide();
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));
                  //this.router.navigate(['/PenAir/Folder/Products',this.orderno]);
                  this.common.isfinalsaved = false;
                  sessionStorage.setItem('pagevalue', '0');
                  var folderQuotes = sessionStorage.getItem('folderQuotes');
                  if (folderQuotes) {
                    this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno]);
                  } else {
                    this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno]);
                  }
                } else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
              else {
                this.spinner.hide();
              }
            }, (err) => {
              this.spinner.hide();
              console.log(err);
              this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                this.showMsg = translations['Messages.some_error_in_save'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
            }
            ));
        }
        else {
          this.spinner.hide();
        }
      }
    }
  }
  //alert('SUCCESS2!! :-)\n\n' + JSON.stringify(this.hotelDetailModal, null, 4));
  // display form values on success
  // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.railEditForm.value, null, 4));

  onCancel() {
    if (this.guid != null)
      this.router.navigate(['/PenAir/Product/RailDetails', this.orderno, this.guid + '/']);
    else {
      this.common.isfinalsaved = false;
      sessionStorage.setItem('pagevalue', '0')
      var folderQuotes = sessionStorage.getItem('folderQuotes');
      if (folderQuotes) {
        this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno]);
      }
      else {
        this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno]);
      }
    }
    // this.router.navigate(['/PenAir/Folder/Products',this.orderno]);
  }
  onReset() {
    this.submitted = false;
    this.railEditForm.reset();
  }
  getFolderValues() {

    // this.apiShared.folderCurrency.subscribe(fldrCurrency => this.fldrCurrency = fldrCurrency)
    // this.apiShared.folderExchangeRate.subscribe(folderExrate => this.folderExrate = folderExrate)
    // this.exRate=new Number(this.folderExrate);
    // this.apiShared.projNo.subscribe(projNo => this.projNo = projNo)
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    this.exRate = new Number(this.folderExrate);
    this.projNo = this.apiShared.projNo;
  }
  public open() {
    this.opened = true;
  }
  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
    this.openSearch = false;
  }

  onChanges(): void {

    //   this.railEditForm.get('SUPPRTYUSERCODE').valueChanges.subscribe(val => {
    //  //  this.formattedMessage = `My name is ${val}.`;
    //    console.log({val}.val);

    //    let toArray = {val}.val.split(" - ");

    //    this.railEditForm.controls.SUPPNAME.setValue(toArray[1]);
    //   });
  }
  public CurrencyChange(value: any): void {
    if (value != null && value != undefined)
      this.railEditForm.controls.CURCODE.setValue(value.UsrCode);
    else
      this.railEditForm.controls.CURCODE.setValue("");
  }
  public getCurrencyExRate() {

    this.ticketDate = (this.railEditForm.controls.TICKETDATE.value == null || this.railEditForm.controls.TICKETDATE.value == "" ? "01-Jan-1900" : this.railEditForm.controls.TICKETDATE.value);//Case id-337

    if (Boolean(this.common.ValidDateRange(this.ticketDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidTicketDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidTicketDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (this.railEditForm.controls.CURCODE.value != '' && this.railEditForm.controls.CURCODE.value != undefined)
      this.getExchangeRate(this.railEditForm.controls.CURCODE.value, this.ticketDate)


    ///this.folderdata.changeData(this.cruiseEditForm.controls.CURCODE.value, (this.cruiseEditForm.controls.CURExRate.value))
  }
  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860

  getExchangeRate(currency, bookingDate) {

    if (bookingDate == null || bookingDate == '') {
      bookingDate = new Date();
    }
    if (bookingDate !== null && bookingDate !== '') {
      this.savebutton = false;
      // bookingDate= new Date(bookingDate).toLocaleDateString('en-GB', {
      //   day: 'numeric', month: 'short', year: 'numeric'
      // }).replace(/ /g, '-');
      bookingDate = this.common.transformDate(new Date(bookingDate));

      this.subscription.add(this.apiExRate.getExchangeRate(currency, bookingDate)
        .subscribe(data => {
          this.currencyExrate = data;
          this.railEditForm.controls.CURExRate.setValue(this.currencyExrate.ResponseData.EXRATE);
          this.savebutton = true;
        }));
    }

  }
  private buyExRate: number;
  private fCBuy: number;
  private unit: number;
  private rate: number;
  private hcBuy: number = 0;
  private amount: number;
  private balanceDueAmount: number;
  private defaultDepositPer: number;

  CalculateForm() {
    this.buyExRate = (this.railEditForm.get('CURExRate').value == "" || this.railEditForm.get('CURExRate').value == 0) ? 1 : this.railEditForm.get('CURExRate').value;
    if ((this.railEditForm.get('AMTLC') != null) && this.railEditForm.get('AMTLC').value != 0 && this.railEditForm
      .get('AMTLC').value != "undefined") {

      this.fCBuy = this.railEditForm.controls.AMTLC.value;
      this.railEditForm.controls.FCBuy.setValue(parseFloat((this.fCBuy).toFixed(2)));
      this.railEditForm.controls.AMOUNT.setValue(parseFloat((this.fCBuy * this.buyExRate).toFixed(2)));
      this.railEditForm.controls.AMTHC.setValue(parseFloat((this.fCBuy * this.buyExRate).toFixed(2)));
    }
    // else
    // {
    //   this.railEditForm.controls.PAYABLE.setValue(parseFloat((this.railEditForm.get('FCBuy').value*this.buyExRate ).toFixed(2)));
    // }
    if (this.railEditForm.get('AMTLC').value == 0) {
      this.railEditForm.controls.AMTHC.setValue(parseFloat((0).toFixed(2)));
    }
    this.hcBuy = this.railEditForm.get('FCBuy').value * this.buyExRate;
    this.railEditForm.controls.HCSell.setValue(parseFloat((this.railEditForm.get('FCSell').value * 1).toFixed(2)));
    this.railEditForm.controls.HCCommAmt.setValue(parseFloat((this.railEditForm.get('HCSell').value - this.railEditForm.get('AMOUNT').value).toFixed(2)));


    this.amount = (this.railEditForm.get('DEFDEP').value == "" || this.railEditForm.get('DEFDEP').value == 0) ? 0 : this.railEditForm.get('DEFDEP').value;
    this.defaultDepositPer = (this.railEditForm.get('DEFDEPPER').value == "" || this.railEditForm.get('DEFDEPPER').value == 0) ? 0 : this.railEditForm.get('DEFDEPPER').value;

    if (this.defaultDepositPer != 0 && this.defaultDepositPer != 0.00)
      this.amount = (this.hcBuy * this.defaultDepositPer) / 100;

    this.balanceDueAmount = this.hcBuy - this.amount
    this.railEditForm.controls.DEFDEP.setValue(parseFloat((this.amount).toFixed(2)));
    this.railEditForm.controls.BalanceDueAmount.setValue(parseFloat((this.balanceDueAmount).toFixed(2)));
    //Corperate Info


    // this.railEditForm.controls.ActualSaving.setValue(parseFloat((this.railEditForm.get('PAYABLE').value*-1).toFixed(2)));    


  }
  ChangeMempNo() {
    if (this.railEditForm.controls.LOYALTYPOINTSMEMBNO.value != "") {

    }
  }
  FormLoyalityPointsChange() {
    if (this.railEditForm.controls.LOYALTYPOINTSMEMBNO.value != "" && this.railEditForm.controls.LOYALTYPOINTSMEMBNO.value != null) {


      this.railEditForm.get('LOYALTYPOINTS').enable();
      this.railEditForm.get('LOYALTYPOINTSAMT').enable();
      // this.disableTextbox =  false;
    }
    else {
      this.railEditForm.controls.LOYALTYPOINTS.setValue(0);
      this.railEditForm.controls.LOYALTYPOINTSAMT.setValue(0);
      this.railEditForm.get('LOYALTYPOINTS').disable();
      this.railEditForm.get('LOYALTYPOINTSAMT').disable();

    }
  }

  // public DestinationValueChange(value: any): void {

  //   this.destinationSelectedItem =this.destinationList.find(item => item.Name ==value);

  //   if(this.destinationSelectedItem != null)
  //   {
  //   this.folderSummaryEditForm.controls.ARRIVALPT.setValue( this.destinationSelectedItem.Code);
  //   this.folderSummaryEditForm.controls.DESTINAION.setValue( this.destinationSelectedItem.Name);
  //   }
  //   else
  //   {
  //     this.destinationSelected="";
  //     this.folderSummaryEditForm.controls.ARRIVALPT.setValue("");
  //     this.folderSummaryEditForm.controls.DESTINAION.setValue("");
  //   }
  // }


  SupplierDefaultvalue(suppcode) {
    this.subscription.add(this.supplier.GetDefaultValue(suppcode)
      .subscribe(data => {
        if (data !== '' && data !== null && data !== undefined) {
          //  this.railEditForm.controls.CURCODE.setValue(data.CURRENCYCODE);
          this.railEditForm.controls.PAYMENTID.setValue(data.PAYTERMID);
          this.railEditForm.controls.PMTID.setValue(data.PAYMTDID);
          this.resetChildForm();

          // if(this.railEditForm.controls.CURCODE.value!=""&&this.railEditForm.controls.CURCODE.value!=null)
          // {   
          //   this.currencySelectedItem =this.currencyList.find(item => item.Code ===this.railEditForm.controls.CURCODE.value );     
          //   this.getCurrencyExRate();
          // }
        }
      }));
  }
  resetChildForm() {
    this.resetFormSubject.next(true);
  }

  public SupplierValueChange(value: any): void {
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);

    }
    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860

      this.SupplierDefaultvalue(this.supplierSelectedItem.Code);
      this.railEditForm.controls.SUPPCODE.setValue(this.supplierSelectedItem.Code);
      this.railEditForm.controls.SUPPNAME.setValue(this.supplierSelectedItem.ActualName);
      this.railEditForm.controls.Provider.setValue(this.supplierSelectedItem.ActualName);
      this.apiShared.BALDUETO = this.supplierSelectedItem.BALDUETO;
      this.railEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID);
      this.payterm.getBalanceDuedate();
      this.TaxLookup(this.supplierSelectedItem.CONTID);
    }
    else {
      this.supplierSelected = "";
      this.railEditForm.controls.SUPPCODE.setValue("");
      this.railEditForm.controls.SUPPNAME.setValue("");
      this.railEditForm.controls.Provider.setValue("");
    }

  }
  public BookedByChange(value: any): void {//By Case id-355
    if (value != undefined) {
      this.railEditForm.controls.BOOKEDBY.setValue(value.Name);
    }
    else {
      this.railEditForm.controls.BOOKEDBY.setValue('');
    }
  }

  CompanyCardValidation() {
    if (this.railEditForm.get('PAYMODE').value == "Company Card") {
      if (this.railEditForm.get('PDNO').value != "" && this.railEditForm.get('PDNO').value != null && this.railEditForm.get('PDFCURID').value != "" && this.railEditForm.get('PDFCURID').value != null
        && this.railEditForm.get('PDFCAMT').value != "" && this.railEditForm.get('PDFCAMT').value != null && this.railEditForm.get('PDFCAMT').value != 0 && this.railEditForm.get('PDTYPENO').value != null
        && this.railEditForm.get('PDTYPENO').value != "" && this.railEditForm.get('PDAMOUNT').value != null && this.railEditForm.get('PDAMOUNT').value != null && this.railEditForm.get('PDAMOUNT').value != 0

        && this.railEditForm.get('PDFCAMT').value != null && this.railEditForm.get('PDFCAMT').value != null && this.railEditForm.get('PDFCAMT').value != 0
      ) {

        return true;

      }
      else {

        this.common.showFlash1('Messages.Companycard_validation', 'bg-danger text-white', 2000);


        return false;
      }
    }
    else {
      return true;
    }

  }
  isSticky: boolean = false;
  @HostListener('window:scroll')
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ConfirmMessage() {
    if (this.creditLimiFlag == false) {
      this.creditLimitMsg = false;
      this.RailDetailsSaveandUpdate();
    } else {
      this.creditLimitMsg = false;
    }
  }

  TaxLookup(country) {
    this.fchcdetails.getInputTaxLookup(country)
  }
}
