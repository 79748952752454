import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { CustomerPaymentResponse } from "../Models/customer-payment-response";
import { Observable, of, throwError } from "rxjs";
import { catchError, tap, map } from "rxjs/operators";
import { EnvService } from "../../env.service";
import { ResponseData } from "../Models/response-data";
import { CustomerReceiptPost } from "../Models/customer-receipt-post";
import { CustomerPostResult } from "../Models/customer-post-result";
import { SupplierPaymentResponse } from "../Models/supplier-payment-response";
import { CommonserviceService } from "./commonservice.service";
import { SupplierPaymentRequest } from "../Models/supplier-payment-request";
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  public formData: SupplierPaymentRequest;
  apiUrl: string;
  constructor(
    private http: HttpClient,
    private env: EnvService,
    public commonservice: CommonserviceService
  ) {
    this.apiUrl = env.baseUrl;
  }

  GetCustomerPaymentDetails(
    Id: number,
    UserId: number,
    folderNo: string,
    YearId: string,
    CurrencyId: string,
    PartyCode: number
  ): Observable<CustomerPaymentResponse[]> {
    const url =
      `${this.apiUrl}` +
      "GetCustomerPaymentDetails" +
      `/${Id}/${UserId}/${folderNo}/${YearId}/${CurrencyId}/${PartyCode}`;
    //return this.data;
    //alert(url);
    return this.http.get<CustomerPaymentResponse[]>(url).pipe(
      tap((_) => console.log(`fetched GetCustomerPaymentDetails=${folderNo}`)),
      catchError(
        this.commonservice.handleError<CustomerPaymentResponse[]>(
          `GetCustomerPaymentDetails=${folderNo}`
        )
      )
    );
  }
  PostCustomerPaymentDetails(CustomerPaymentPost: any): Observable<any> {
    const url = `${this.apiUrl}` + "PaymentPost"; ///${id}

    return this.http.post(url, CustomerPaymentPost, httpOptions).pipe(
      tap((_) => console.log(`PaymentPost`)),
      catchError(this.commonservice.handleError<any>("PaymentPost"))
    );
  }

  public GetsupplierPaymentDetails(): Observable<SupplierPaymentResponse> {
    var body = {
      ...this.formData,
    };

    return this.http
      .post<SupplierPaymentResponse[]>(
        this.apiUrl + "GetSupplierPaymentDetails",
        body,
        httpOptions
      )
      .pipe(
        tap((SupplierPaymentResponse: any) =>
          console.log(`GetSupplierPaymentDetails`)
        ),
        catchError(
          this.commonservice.handleError<any>("GetSupplierPaymentDetails")
        )
      );
  }
  PostSupplierPaymentDetails(SupplierPaymentPost: any): Observable<any> {
    // const url = `${this.apiUrl}` + 'SupplierPaymentPost';///${id}
    var method = "SupplierPaymentPost";
    if (SupplierPaymentPost.VoucherType == 51) {
      method = "SupplierPaymentCreditPost";
    }
    const url = `${this.apiUrl}` + method; /// ${id}
    return this.http.post(url, SupplierPaymentPost, httpOptions).pipe(
      tap((_) => console.log(`SupplierPaymentPost`)),
      catchError(this.commonservice.handleError<any>("SupplierPaymentPost"))
    );
  }
}
