export class HotelMaster {
    HID: string;
    HName : string;
    CITYCODE : number;
    STAR : number;
    URL : string;
    EMAIL: string;
    IMAGE : string;
    UID : number;
    LUPDATE:Date;
    CPerson: string;
    CNumber : string;
    HDes : string;
    Directions : string;
    Address1 : string;
    Address2 : string;
    Address3: string;
    Address4 : string;
    COUNTRY : string;
    POSTALCODE: string;
    TELEPHONE: string;
    ADDRESSEMAIL: string;
    HNO : number;
    Hote_AutoNo : number;
    PropertyType : string;
    Status : string;
    DistanceFromCity: number;
    Region : string;
    Latitude : number;
    Longitude : number;
    LocallyKnownAs : string;
    CheckinTime:Date;
    CheckoutTime:Date;
    WeekendStart : string;
    Facilities : string;
    CityName:string;
    PropertyTypeName:string;
    CITYID :string;
    TandC :string;
    LocationDetails:string;
    MoreImages: {
        HIAutoNo:string;
        HotelID:string;
        Image:string;
      
    }
   
}
export class HotelDetailsDTO {
   
    FOLDERNO: number;
    
    HOTELNAME: string;
    
    GUIDItinerary: string;
   
        
}
export class FolderHotelActivityTblDTO{
    AutonNo :any;
 HD_AutoNo :any;
 Hote101_Id :any;
 ActiityID :any;
 PassengerNames :any;
 AdditionalNotes :any;
 Description :any;
 LupDate :any;
 CrDate :any;
}

