import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiConfig } from 'src/api.config';
import { GoogleAuthCommonService } from './google-auth.common.service';
import { EnvService } from 'src/env.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';


@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService extends GoogleAuthCommonService {
  API_ENDPOINT: string;
  constructor( 
    private env: EnvService, 
    private httpClient: HttpClient,
    @Inject(LOCAL_STORAGE) private storageService: StorageService
    ) {
    super();
    this.API_ENDPOINT = env.api_endpoint;
  }
  enableTFA(Uname:string) {
    //const headers = { 'Authorization': `Bearer ${accessToken}` };
    const body = { uname:Uname };
    return this.httpClient
      .post(`${this.API_ENDPOINT}${apiConfig.auth.enableTFA}`,body)
      .pipe(map((response) => response),
        catchError((err) => throwError(err))
      );
  }

  disableTFA(payload:any) {
    let accessToken  = JSON.parse(localStorage.getItem("currentUser"));
    let access=accessToken.ResponseData.accessToken;
    const headers = { 'Authorization': `Bearer ${access}` };
    return this.httpClient
      .post(`${this.API_ENDPOINT}${apiConfig.auth.disableTFA}`, payload,{headers})
      .pipe(
        map((response) => response),
        catchError((err) => throwError(err))
      );
  }
  validateTFAOTP(payload: any) {
    let accessToken  = JSON.parse(localStorage.getItem("currentUser"));
    let access='';
    if(accessToken.ResponseData)
    {
       access=accessToken.ResponseData.accessToken;
    }else{
       access=accessToken.accessToken;
    }
    
    const headers = { 'Authorization': `Bearer ${access}` };
    return this.httpClient
      .post(`${this.API_ENDPOINT}${apiConfig.auth.validateTFAOTP}`, payload, {headers})
      .pipe(
        map((response) => response),
        catchError((err) => throwError(err))
      );
  }

  checkIfTFAEnabled(payload: any) {
    return this.httpClient
      .post(`${this.API_ENDPOINT}${apiConfig.auth.checkIfTFAEnabled}`, payload)
      .pipe(
        map((response) => response),
        catchError((err) => throwError(err))
      );
  }

  otpVerify(otpPayload: any) {
    return this.httpClient
      .post(`${this.API_ENDPOINT}${apiConfig.auth.otpverify}`, otpPayload, )
      .pipe(
        map((response) => response),
        catchError((err) => throwError(err))
      );
  }


  ipaddress() {
    return this.httpClient
      .get('https://api.ipify.org?format=json')
      .pipe(
        map((response) => response),
        catchError((err) => throwError(err))
      );
  }

  ipExist(ip:any) {
    return this.httpClient
      .get(`${this.API_ENDPOINT}${apiConfig.auth.ipexist}?ipaddress=`+ip)
      .pipe(
        map((response) => response),
        catchError((err) => throwError(err))
      );
  }
 
}


