import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Workbook } from '@progress/kendo-angular-excel-export';
import { DataStateChangeEvent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { IntlService } from '@progress/kendo-angular-intl';
import { DatePipe } from '@angular/common';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { State, aggregateBy, CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { Group, exportPDF } from '@progress/kendo-drawing';
import { process } from "@progress/kendo-data-query";
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FolderActivityDetailedDTO } from '../../Models/folder-activity-detailed.model';
import { LookUpDetails } from '../../Services/look-up-details';
import { AirlineDetailedReport } from '../../Services/airline-detailed-report';
import { CommonserviceService } from '../../Services/commonservice.service';
import { EmailService } from '../../Services/email.service';
import { FileService } from '../../Services/file.service';
import { FolderActivityDetailedService } from '../../Services/folder-activity-detailed.service';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { ShareDataService } from '../../Services/share-data.service';
import { saveAs } from "@progress/kendo-file-saver";

@Component({
  selector: 'app-folder-activity-detailed-report',
  templateUrl: './folder-activity-detailed-report.component.html',
  styleUrls: ['./folder-activity-detailed-report.component.scss']
})
export class FolderActivityDetailedReportComponent implements OnInit {
  @ViewChild("pdf", { read: false, static: false }) pdf;
  custUsrCode: any;
  ordertypename: any;
  prodtstatus: any;
  public state: State = {
    skip: 0,
    take: 100,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public columnFormat: any[] = [
    { field: 'BALDUEDATE', format: 'dd/MMM/yyyy' },
    { field: 'FOLDERDATE', format: 'dd/MMM/yyyy' },
    { field: 'BookingDate', format: 'dd/MMM/yyyy' },
    { field: 'CRDATE', format: 'dd/MMM/yyyy' },
    { field: 'DATEIN', format: 'dd/MMM/yyyy' },
    { field: 'DATEOUT', format: 'dd/MMM/yyyy' },
    { field: 'DEPDUEDATE', format: 'dd/MMM/yyyy' },
    { field: 'InvoiceDate', format: 'dd/MMM/yyyy' },
    { field: 'ReturnDate', format: 'dd/MMM/yyyy' },
    { field: 'TicketingDeadline', format: 'dd/MMM/yyyy' },
    { field: 'QCONDATE', format: 'dd/MMM/yyyy' },
    { field: 'DEPDATE', format: 'dd/MMM/yyyy' },
    { field: 'ARRDATE', format: 'dd/MMM/yyyy' },

  ];
  equals: any;
  public sort: SortDescriptor[] = [

    {
      field: "ARRDATE",
      dir: "desc",
    },
    {
      field: "DEPDATE",
      dir: "desc",
    },
    {
      field: "QCONDATE",
      dir: "desc",
    },
    {
      field: "TicketingDeadline",
      dir: "desc",
    },
    {
      field: "ReturnDate",
      dir: "desc",
    },
    {
      field: "InvoiceDate",
      dir: "desc",
    },
    {
      field: "DEPDUEDATE",
      dir: "desc",
    },
    {
      field: "DATEOUT",
      dir: "desc",
    },
    {
      field: "DATEIN",
      dir: "desc",
    },
    {
      field: "CRDATE",
      dir: "desc",
    },
    {
      field: "BookingDate",
      dir: "desc",
    },
    {
      field: "BALDUEDATE",
      dir: "desc",
    },
    {
      field: "FOLDERDATE",
      dir: "desc",
    },


  ];
  showFilterSub = false;
  supusrcode: any;
  pdftop: any;
  formData: any;
  Primarygriddata: any = [];

  public gridData: GridDataResult = process([], this.state);
  destName: any;
  public gridView: any;
  showtotal: boolean = false;
  public aggregates: any[] = [
    { field: "SELLAMT", aggregate: "sum" },
    { field: "COMMAMT", aggregate: "sum" },
    { field: "BuyAmt", aggregate: "sum" },
    { field: "TOMSVATAMT", aggregate: "sum" },
    { field: "BaseFare", aggregate: "sum" },
    { field: "Taxes", aggregate: "sum" },
    { field: "TotalFare", aggregate: "sum" },
    { field: "SalesTax", aggregate: "sum" },
    { field: "TotaLAmount", aggregate: "sum" },
    { field: "LOYALTYPOINTS", aggregate: "sum" },
    { field: "SellAmount_HC", aggregate: "sum" },
    { field: "Buy_Amount_HC", aggregate: "sum" },
    { field: "Comm_Amount_HC", aggregate: "sum" },
    { field: "EntitlementAmount", aggregate: "sum" },
    { field: "LOYALTYPOINTSAMT", aggregate: "sum" },


  ];
  showFilter = false;
  ProductCodeName: any;
  branchname: any;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  FolderActivityDetailedDTO = new FolderActivityDetailedDTO();

  public opened: any = false;
  private subscription: Subscription = new Subscription();
  public AirlineReportGridData: any[];
  public showMsg: any;
  creditName: any;
  userID: any = 0;

  image: any;
  RptHdrSel_TemplateName: any = "";
  RptHdrSel_AutoNo: any = '';
  submitted: any = false;

  opentemplate: any = false;


  TempList: Array<LookUpDetails["ResponseData"]> = [];
  public TemplateSelectedItem: LookUpDetails["ResponseData"];
  public hidegridbutton: any = false;

  public column = "AirlineCodeCol,ArrivalCityCol,ATOLPAX,BAID,BalanceDueAmount,BalDueDateCol,BaseFareCol,QCONDATE,BookingStatusCol,bookingRef,BRNAME,BRID,BANAME,BuyAmtCol,BuyAmtHCCol,DAYS,CARDNUMBER,CARDValidity,CheckinCol,CheckOutCol,CityCol,HotelCityNameCol,CommAmtHCCol,COMMAMT,ConfirmationNo,CostDiscType,CRDATE,CruiseFrom,CruiseTo,FC_CID,CustNameColumn,CustCodeCol,CntryCodeCol,ClientCountryName,ClientCountry,DateinCol,DateOutCol,DepDueDateCol,CITYNAME,ARRDATE,DCM_NAME,FM_DM_UID,DEPDATE,EntitlementAmount,fldrBkdbyCol,PROJNO,GDS,HtlCol,NIGHTS,INETREF,Inputvatcol,InvDateCol,InvCol,ItineraryNotes,DiscVal,LidCol,Pass,BookedBy,LOYALTYPOINTS,MCIdColumn,MemberShipNoCol,NoOfPax,FOLDERNO,OrderTypeCol,OriginCol,PassCodeCol,PmCol,PnrCol,TYPE,ProductStatus,ProdctType,Provider,QCDATE,Receiptstatus,Remarks,ReturnDateCol,SalesTaxCol,SellAmtCol,SELLAMT,Status,SUPPUSERCODE,SUPPNAME,TaxesCol,TktNoCol,Tkt_Type,TicketingDeadline,TOMSVATAMT,TotalFareCol,TotalAmount,Folderdate,Description,VoucherNo,YourRefCol";
  //14290
  BANAMEHidden: any = false;
  BRNAMEHidden: any = false;
  DCM_NAMEHidden: any = false;
  PROJNOHidden: any = false;
  FOLDERNOHidden: any = false;
  TYPEHidden: any = false;
  DescriptionHidden: any = false;
  SUPPUSERCODEHidden: any = false;
  SUPPNAMEHidden: any = false;
  FOLDERDATEHidden: any = false;
  BookingDateHidden: any = false;
  CRDATEHidden: any = false;
  TicketNoHidden: any = false;
  PassHidden: any = false;
  FC_CIDHidden: any = false;
  SELLAMTHidden: any = false;
  COMMAMTHidden: any = false;
  BuyAmtHidden: any = false;
  TOMSVATAMTHidden: any = false;
  CITYNAMEHidden: any = false;
  LOYALTYPOINTSAMTHidden: any = false;
  CostDiscTypeHidden: any = false;
  LOYALTYPOINTSHidden: any = false;
  LOYALTYPOINTSMEMBNOHidden: any = false;
  FM_DM_UIDHidden: any = false;
  BOOKEDBYHidden: any = false;
  bookingRefHidden: any = false;
  StatusHidden: any = false;
  DAYSHidden: any = false;
  NIGHTSHidden: any = false;
  CustLidHidden: any = false;
  CustCountryHidden: any = false;
  ClientHidden: any = false;
  CountryNameHidden: any = false;
  ATOLPAXHidden: any = false;
  INETREFHidden: any = false;
  UPLOADRCLHidden: any = false;
  VairlineAIRIDHidden: any = false;
  PONOHidden: any = false;
  OrderTypeHidden: any = false;
  HOTELNAMEHidden: any = false;
  BSPPurchaseTaxHidden: any = false;
  PaymentMethodHidden: any = false;
  FM_LIDHidden: any = false;
  NAMEHidden: any = false;
  MCIDHidden: any = false;
  DATEINHidden: any = false;
  DATEOUTHidden: any = false;
  CHECKINHidden: any = false;
  CHECKOUTHidden: any = false;
  CITYIDHidden: any = false;
  CITYHidden: any = false;
  BALDUEDATEHidden: any = false;
  DEPDUEDATEHidden: any = false;
  BaseFareHidden: any = false;
  ProdctTypeHidden: any = false;
  ProviderHidden: any = false;
  ItineraryNotesHidden: any = false;
  EntitlementAmountHidden: any = false;
  RemarksHidden: any = false;
  TotalHidden: any = false;
  FareHidden: any = false;
  SalesTaxHidden: any = false;
  TotalAmountHidden: any = false;
  OriginHidden: any = false;
  CustomerCodeHidden: any = false;
  PassengerCodeHidden: any = false;
  InvoiceDateHidden: any = false;
  InvoiceNoHidden: any = false;
  ArrivalCityHidden: any = false;
  ReturnDateHidden: any = false;
  SellAmountHCHidden: any = false;
  CommAmountHCHidden: any = false;
  BuyAmountHCHidden: any = false;
  NoOfPaxHidden: any = false;
  FolderBookedByHidden: any = false;
  BookingStatusHidden: any = false;
  Tkt_TypeHidden: any = false;
  TicketingDeadlineHidden: any = false;
  GDSHidden: any = false;
  ReceiptstatusHidden: any = false;
  TAX1_TYPEHidden: any = false;
  QCONDATEHidden: any = false;
  ProductStatusHidden: any = false;
  DIVISIONHidden: any = false;
  ConfirmationNoHidden: any = false;
  VoucherNoHidden: any = false;
  BRIDHidden: any = false;
  BAIDHidden: any = false;
  BalanceDueAmountHidden: any = false;
  CARDNUMBERHidden: any = false;
  QCDATEHidden: any = false;
  CARDValidityHidden: any = false;
  CruiseFromHidden: any = false;
  CruiseToHidden: any = false;
  DEPDATEHidden: any = false;
  ARRDATEHidden: any = false;
  TaxesHidden: any = false;
  DateOutColHidden: any = false;
  DatInColHidden: any = false;
  //14290


  selectAll: any = false;
  FOLDERNOChk: any = false;
  ORDERNOChk: any = false;
  DATEOFISSUEChk: any = false;
  PAXNAMEChk: any = false;
  TKTNOChk: any = false;
  PAXTYPEChk: any = false;
  PNRChk: any = false;
  TKTTYPEChk: any = false;
  ORGChk: any = false;
  DESTChk: any = false;
  BASEFAREChk: any = false;
  TAX1TYPEChk: any = false;
  TAX1AMTChk: any = false;
  TAX2TYPEChk: any = false;
  TAX2AMTChk: any = false;
  TAX3TYPEChk: any = false;
  TAX3AMTChk: any = false;
  TAX4TYPEChk: any = false;
  TAX4AMTChk: any = false;
  TOTALChk: any = false;
  TRAVELDATEChk: any = false;
  CustomerCodeChk: any = false;
  CUSTOMERChk: any = false;
  ISSUEDBYChk: any = false;
  BOOKEDBYChk: any = false;
  JOURNEYTYPEChk: any = false;
  AIRIDChk: any = false;
  AIRNAMEChk: any = false;
  AIRLINECLASSChk: any = false;
  AIRLINECODEChk: any = false;
  AIRLINEPNRChk: any = false;
  FM_DM_UIDChk: any;
  BranchIDChk: any = false;
  AIRLINEPCCChk: any = false;
  FLTNOOUTBOUNDChk: any = false;
  FLTNOINBOUNDChk: any = false;
  PRTYUSRCODEChk: any = false;
  CLASSNAMEChk: any = false;
  FareCommAmtChk: any = false;
  FareCommPerChk: any = false;
  PaymentMethodChk: any = false;
  FAREBASISChk: any = false;
  CommAmountChk: any = false;
  AirlineAcommChk: any = false;
  SellAmountChk: any = false;
  MileageInfoChk: any = false;
  ReceiptChk: any = false;
  StatusChk: any = false;
  BAreaname: any;
  MarkUPChk: any = false;
  ReturnDateChk: any = false;
  TicketingDeadlineChk: any = false;
  InterIndChk: any = false;
  GDSChk: any = false;
  defaultItem: any;
  duplicatetemp: any = false;
  actionsLayout: any;
  public AirlineDetailedList: any;
  public total: any;

  fromId: string;
  bccId: string;
  public griddata: any;

  constructor(
    private lookupApi: LookUpDetailsService,
    public intl: IntlService,
    public common: CommonserviceService,
    public apiShared: ShareDataService,
    public email: EmailService,
    private sanitizer: DomSanitizer,

    private translateapi: TranslateService,
    private toastr: ToastrService,
    private emailapi: FileService,
    private router: Router,
    private datePipe: DatePipe,
    public FolderActivityService: FolderActivityDetailedService,
  ) { }

  ngOnInit(): void {
    debugger;
    this.getFormData();
    this.email.Isgridpdf = false;
    this.userID = +this.apiShared.uid;

    this.griddata = this.FolderActivityService.griddata;

    this.gridView = this.FolderActivityService.griddata;
    this.griddata.forEach(element => {

      if ((element.ARRDATE != undefined) && (element.ARRDATE != "")) {
        element.ARRDATE = new Date(this.common.ConvertToLocaleDate(element.ARRDATE));
      }
      else {
        element.ARRDATE = "";
      }
      if ((element.DEPDATE != undefined) && (element.DEPDATE != "")) {
        element.DEPDATE = new Date(this.common.ConvertToLocaleDate(element.DEPDATE));
      }
      else {
        element.DEPDATE = "";
      }
      if ((element.QCONDATE != undefined) && (element.QCONDATE != "")) {
        element.QCONDATE = new Date(this.common.ConvertToLocaleDate(element.QCONDATE));
      }
      else {
        element.QCONDATE = "";
      }
      if ((element.TicketingDeadline != undefined) && (element.TicketingDeadline != "")) {
        element.TicketingDeadline = new Date(this.common.ConvertToLocaleDate(element.TicketingDeadline));
      }
      else {
        element.TicketingDeadline = "";
      }
      if ((element.ReturnDate != undefined) && (element.ReturnDate != "")) {
        element.ReturnDate = new Date(this.common.ConvertToLocaleDate(element.ReturnDate));
      }
      else {
        element.ReturnDate = "";
      }

      if ((element.InvoiceDate != undefined) && (element.InvoiceDate != "")) {
        element.InvoiceDate = new Date(this.common.ConvertToLocaleDate(element.InvoiceDate));
      }
      else {
        element.InvoiceDate = "";
      }

      if ((element.DEPDUEDATE != undefined) && (element.DEPDUEDATE != "")) {
        element.DEPDUEDATE = new Date(this.common.ConvertToLocaleDate(element.DEPDUEDATE));
      }
      else {
        element.DEPDUEDATE = "";
      }

      if ((element.DATEOUT != undefined) && (element.DATEOUT != "")) {
        element.DATEOUT = new Date(this.common.ConvertToLocaleDate(element.DATEOUT));
      }
      else {
        element.DATEOUT = "";
      }


      if ((element.DATEIN != undefined) && (element.DATEIN != "")) {
        element.DATEIN = new Date(this.common.ConvertToLocaleDate(element.DATEIN));
      }
      else {
        element.DATEIN = "";
      }


      if ((element.CRDATE != undefined) && (element.CRDATE != "")) {
        element.CRDATE = new Date(this.common.ConvertToLocaleDate(element.CRDATE));
      }
      else {
        element.CRDATE = "";
      }

      if ((element.BookingDate != undefined) && (element.BookingDate != "")) {
        element.BookingDate = new Date(this.common.ConvertToLocaleDate(element.BookingDate));
      }
      else {
        element.BookingDate = "";
      }

      if ((element.FOLDERDATE != undefined) && (element.FOLDERDATE != "")) {
        element.FOLDERDATE = new Date(this.common.ConvertToLocaleDate(element.FOLDERDATE));
      }
      else {
        element.FOLDERDATE = "";
      }
      if ((element.BALDUEDATE != undefined) && (element.BALDUEDATE != "")) {
        element.BALDUEDATE = new Date(this.common.ConvertToLocaleDate(element.BALDUEDATE));
      }
      else {
        element.BALDUEDATE = "";
      }

    })

    // if (this.FolderActivityService.griddata.length > 0)
    //   this.showtotal = true;
    // else this.showtotal = false;

    this.Primarygriddata = this.FolderActivityService.griddata;
    //this.total = aggregateBy(this.griddata, this.aggregates);
    this.subscription.add(
      this.emailapi.GetReportEmail(this.userID).subscribe(data => {
        (this.fromId = data.ResponseData.FROMEMAIL),
          (this.bccId = data.ResponseData.DEFAULTBCCMAIL);
      })
    );

    this.LoadTemplate();
    this.GetReportHeaderFooter();


  }
  getFormData() {
    this.formData = this.FolderActivityService.formData;
    if (this.formData.ProdStatus == "") {
      this.formData.ProdStatus = "All"
    }
    if (this.formData.ReciptStatus == "") {
      this.formData.ReciptStatus = "All"
    }
    if (this.formData.Paymentmethod == undefined) {
      this.formData.Paymentmethod = "All"
    }
    if (this.formData.pOrderType_varchar == undefined) {
      this.formData.pOrderType_varchar = "(ALL)"
    }
    this.ordertypename = this.FolderActivityService.ordertypename;
    this.prodtstatus = this.FolderActivityService.prodtstatus;
    this.custUsrCode = this.FolderActivityService.custUsrCode;
    this.creditName = this.FolderActivityService.creditName;
    this.destName = this.FolderActivityService.destName;
    this.supusrcode = this.FolderActivityService.supusrcode;
    this.ProductCodeName = this.FolderActivityService.ProductCodeName;
    this.branchname = this.FolderActivityService.branchname;
    this.BAreaname = this.FolderActivityService.BAreaname;
  }
  //total
  public calculateSum(data: any[], columnsToSum: string[]): number {
    let sum = 0;
    for (const item of data) {
      for (const column of columnsToSum) {
        sum += item[column];
      }
    }
    return sum;
  }

  //total
  public LoadTemplate() {
    debugger;
    this.subscription.add(
      this.FolderActivityService.GetTemplate().subscribe(data => {
        if (data != null && data != undefined) {
          this.TempList = data["ResponseData"];
          if (this.FolderActivityService.formDataHeader.RptHdrSel_AutoNo > 0) {
            this.TemplateSelectedItem = this.TempList.find(
              x =>
                x.UsrCode ==
                this.FolderActivityService.formDataHeader.RptHdrSel_AutoNo
            );
          }
          else {
            debugger;
            this.TemplateSelectedItem = this.TempList[0];
          }
        }
      })
    );
  }
  selectAlldisable() {
    if (
      (this.FOLDERNOChk == true) &&
      (this.ORDERNOChk == true) &&
      (this.DATEOFISSUEChk == true) &&
      (this.PAXNAMEChk == true) &&
      (this.TKTNOChk == true) &&
      (this.PAXTYPEChk == true) &&
      (this.PNRChk == true) &&
      (this.TKTTYPEChk == true) &&
      (this.ORGChk == true) &&
      (this.DESTChk == true) &&
      (this.BASEFAREChk == true) &&
      (this.TAX1_TYPEHidden == true) &&
      (this.TAX1AMTChk == true) &&
      (this.TAX2TYPEChk == true) &&
      (this.TAX2AMTChk == true) &&
      (this.TAX3TYPEChk == true) &&
      (this.TAX3AMTChk == true) &&
      (this.TAX3AMTChk == true) &&
      (this.TAX4TYPEChk == true) &&
      (this.TAX4AMTChk == true) &&
      (this.TOTALChk == true) &&
      (this.TRAVELDATEChk == true) &&
      (this.CustomerCodeChk == true) &&
      (this.CUSTOMERChk == true) &&
      (this.ISSUEDBYChk == true) &&
      (this.BOOKEDBYChk == true) &&
      (this.JOURNEYTYPEChk == true) &&
      (this.AIRIDChk == true) &&
      (this.AIRNAMEChk == true) &&
      (this.AIRLINECLASSChk == true) &&
      (this.AIRLINECODEChk == true) &&
      (this.AIRLINEPNRChk == true) &&
      (this.BranchIDChk == true) &&
      (this.AIRLINEPCCChk == true) &&
      (this.FLTNOOUTBOUNDChk == true) &&
      (this.FLTNOINBOUNDChk == true) &&
      (this.PRTYUSRCODEChk == true) &&
      (this.CLASSNAMEChk == true) &&
      (this.FareCommAmtChk == true) &&
      (this.FareCommPerChk == true) &&
      (this.PaymentMethodChk == true) &&
      (this.FAREBASISChk == true) &&
      (this.FAREBASISChk == true) &&

      (this.AirlineAcommChk == true) &&
      (this.SellAmountChk == true) &&

      (this.MileageInfoChk == true) &&

      (this.ReceiptChk == true) &&

      (this.StatusChk == true) &&
      (this.MarkUPChk == true) && (this.ReturnDateChk == true) && (this.TicketingDeadlineChk == true) && (this.InterIndChk == true) && (this.GDSChk == true)) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }

  }
  public onTabSelect(e) {

  }

  public enableAllColumn() {
    this.BANAMEHidden = false;
    this.BRNAMEHidden = false;
    this.DCM_NAMEHidden = false;
    this.PROJNOHidden = false;
    this.FOLDERNOHidden = false;
    this.TYPEHidden = false;
    this.DescriptionHidden = false;
    this.SUPPUSERCODEHidden = false;
    this.SUPPNAMEHidden = false;
    this.FOLDERDATEHidden = false;
    this.BookingDateHidden = false;
    this.CRDATEHidden = false;
    this.TicketNoHidden = false;
    this.PassHidden = false;
    this.FC_CIDHidden = false;
    this.SELLAMTHidden = false;
    this.COMMAMTHidden = false;
    this.BuyAmtHidden = false;
    this.TOMSVATAMTHidden = false;
    this.CITYNAMEHidden = false;
    this.LOYALTYPOINTSAMTHidden = false;
    this.CostDiscTypeHidden = false;
    this.LOYALTYPOINTSHidden = false;
    this.LOYALTYPOINTSMEMBNOHidden = false;
    this.FM_DM_UIDHidden = false;
    this.BOOKEDBYHidden = false;
    this.bookingRefHidden = false;
    this.StatusHidden = false;
    this.DAYSHidden = false;
    this.NIGHTSHidden = false;
    this.CustLidHidden = false;
    this.CustCountryHidden = false;
    this.ClientHidden = false;
    this.CountryNameHidden = false;
    this.ATOLPAXHidden = false;
    this.INETREFHidden = false;
    this.UPLOADRCLHidden = false;
    this.VairlineAIRIDHidden = false;
    this.PONOHidden = false;
    this.OrderTypeHidden = false;
    this.HOTELNAMEHidden = false;
    this.BSPPurchaseTaxHidden = false;
    this.PaymentMethodHidden = false;
    this.FM_LIDHidden = false;
    this.NAMEHidden = false;
    this.MCIDHidden = false;
    this.DATEINHidden = false;
    this.DATEOUTHidden = false;
    this.CHECKINHidden = false;
    this.CHECKOUTHidden = false;
    this.CITYIDHidden = false;
    this.CITYHidden = false;
    this.BALDUEDATEHidden = false;
    this.DEPDUEDATEHidden = false;
    this.BaseFareHidden = false;
    this.ProdctTypeHidden = false;
    this.ProviderHidden = false;
    this.ItineraryNotesHidden = false;
    this.EntitlementAmountHidden = false;
    this.RemarksHidden = false;
    this.TotalHidden = false;
    this.FareHidden = false;
    this.SalesTaxHidden = false;
    this.TotalAmountHidden = false;
    this.OriginHidden = false;
    this.CustomerCodeHidden = false;
    this.PassengerCodeHidden = false;
    this.InvoiceDateHidden = false;
    this.InvoiceNoHidden = false;
    this.ArrivalCityHidden = false;
    this.ReturnDateHidden = false;
    this.SellAmountHCHidden = false;
    this.CommAmountHCHidden = false;
    this.BuyAmountHCHidden = false;
    this.NoOfPaxHidden = false;
    this.FolderBookedByHidden = false;
    this.BookingStatusHidden = false;
    this.Tkt_TypeHidden = false;
    this.TicketingDeadlineHidden = false;
    this.GDSHidden = false;
    this.ReceiptstatusHidden = false;
    this.QCONDATEHidden = false;
    this.ProductStatusHidden = false;
    this.ConfirmationNoHidden = false;
    this.VoucherNoHidden = false;
    this.BRIDHidden = false;
    this.BAIDHidden = false;
    this.BalanceDueAmountHidden = false;
    this.CARDNUMBERHidden = false;
    this.CARDValidityHidden = false;
    this.CruiseFromHidden = false;
    this.CruiseToHidden = false;
    this.DEPDATEHidden = false;
    this.ARRDATEHidden = false;
    this.TaxesHidden = false;

  }


  closeAirlineDetailedReport() {
    this.opentemplate = false;
  }


  templateChange(value) {
    debugger; //UsrCode
    if (value != undefined) {
      this.FolderActivityService.formDataHeader.RptHdrSel_AutoNo = value.UsrCode;
      this.FolderActivityService.template = value.Code;
      this.FolderActivityService.templatevalue = value.Code;
      this.LoadReportColumn();
    }
  }

  public LoadReportColumn() {
    this.FolderActivityService.formDataHeader.RptHdrSel_UserId = this.userID;
    this.FolderActivityService.formDataHeader.RptHdrSel_ModuleName =
      "Folder Activity Detailed Report";

    this.subscription.add(
      this.FolderActivityService.GetGridColumn().subscribe(data => {
        debugger;
        if (
          data != undefined &&
          data != null &&
          data["ResponseData"] != undefined
        ) {
          this.TemplateSelectedItem = this.TempList.find(
            x => x.UsrCode == data["ResponseData"].RptHdrSel_AutoNo
          );

          if (data["ResponseData"].RptHdrSel_ColumnHeader != null) {
            var datas = data["ResponseData"].RptHdrSel_ColumnHeader.split(",");

            if (datas.includes("CruiseFrom")) {
              this.CruiseFromHidden = false;
            } else {
              this.CruiseFromHidden = true;
            }
            if (datas.includes("CruiseFrom")) {
              this.CruiseFromHidden = false;
            } else {
              this.CruiseFromHidden = true;
            }
            if (datas.includes("CruiseFrom")) {
              this.CruiseFromHidden = false;
            } else {
              this.CruiseFromHidden = true;
            }
            if (datas.includes("CruiseTo")) {
              this.CruiseToHidden = false;
            } else {
              this.CruiseToHidden = true;
            }
            if (datas.includes("TktNoCol")) {
              this.TicketNoHidden = false;
            } else {
              this.TicketNoHidden = true;
            }
            if (datas.includes("CRDATE")) {
              this.CRDATEHidden = false;
            } else {
              this.CRDATEHidden = true;
            }
            if (datas.includes("LidCol")) {
              this.FM_LIDHidden = false;
            } else {
              this.FM_LIDHidden = true;
            }
            if (datas.includes("CostDiscType")) {
              this.CostDiscTypeHidden = false;
            } else {
              this.CostDiscTypeHidden = true;
            }
            if (datas.includes("ConfirmationNo")) {
              this.ConfirmationNoHidden = false;
            } else {
              this.ConfirmationNoHidden = true;
            }
            if (datas.includes("COMMAMT")) {
              this.COMMAMTHidden = false;
            } else {
              this.COMMAMTHidden = true;
            }
            if (datas.includes("CommAmtHCCol")) {
              this.CommAmountHCHidden = false;
            } else {
              this.CommAmountHCHidden = true;
            }

            if (datas.includes("CityCol")) {
              this.CITYIDHidden = false;
            } else {
              this.CITYIDHidden = true;
            }
            if (datas.includes("HotelCityNameCol")) {
              this.CITYHidden = false;
            } else {
              this.CITYHidden = true;
            }
            if (datas.includes("CheckinCol")) {
              this.CHECKINHidden = false;
            } else {
              this.CHECKINHidden = true;
            }
            if (datas.includes("CheckOutCol")) {
              this.CHECKOUTHidden = false;
            } else {
              this.CHECKOUTHidden = true;
            }
            if (datas.includes("CARDValidity")) {
              this.CARDValidityHidden = false;
            } else {
              this.CARDValidityHidden = true;
            }
            if (datas.includes("CARDNUMBER")) {
              this.CARDNUMBERHidden = false;
            } else {
              this.CARDNUMBERHidden = true;
            }
            if (datas.includes("DAYS")) {
              this.DAYSHidden = false;
            } else {
              this.DAYSHidden = true;
            }
            if (datas.includes("BuyAmtHCCol")) {
              this.BuyAmountHCHidden = false;
            } else {
              this.BuyAmountHCHidden = true;
            }
            if (datas.includes("FM_DM_UID")) {
              this.DIVISIONHidden = false;
            } else {
              this.DIVISIONHidden = true;
            }
            if (datas.includes("DiscVal")) {
              this.LOYALTYPOINTSAMTHidden = false;
            } else {
              this.LOYALTYPOINTSAMTHidden = true;
            }
            if (datas.includes("BookingStatusCol")) {
              this.BookingStatusHidden = false;
            } else {
              this.BookingStatusHidden = true;
            }
            if (datas.includes("SalesTax")) {
              this.FareHidden = false;
            } else {
              this.FareHidden = true;
            }
            if (datas.includes("BaseFareCol")) {
              this.BaseFareHidden = false;
            } else {
              this.BaseFareHidden = true;
            }
            if (datas.includes("BalDueDateCol")) {
              this.BALDUEDATEHidden = false;
            } else {
              this.BALDUEDATEHidden = true;
            }
            if (datas.includes("AirlineCodeCol")) {
              this.VairlineAIRIDHidden = false;
            } else {
              this.VairlineAIRIDHidden = true;
            }
            if (datas.includes("ArrivalCityCol")) {
              this.ArrivalCityHidden = false;
            } else {
              this.ArrivalCityHidden = true;
            }

            if (datas.includes("BANAME")) {
              this.BANAMEHidden = false;
            } else {
              this.BANAMEHidden = true;
            }
            if (datas.includes("BRNAME")) {
              this.BRNAMEHidden = false;
            } else {
              this.BRNAMEHidden = true;
            }
            if (datas.includes("BRID")) {
              this.BRIDHidden = false;
            } else {
              this.BRIDHidden = true;
            }

            if (datas.includes("BAID")) {
              this.BAIDHidden = false;
            } else {
              this.BAIDHidden = true;
            }

            if (datas.includes("QCONDATE")) {
              this.BookingDateHidden = false;
            } else {
              this.BookingDateHidden = true;
            }

            if (datas.includes("FC_CID")) {
              this.FC_CIDHidden = false;
            } else {
              this.FC_CIDHidden = true;
            }





            if (datas.includes("DCM_NAME")) {
              this.DCM_NAMEHidden = false;
            } else {
              this.DCM_NAMEHidden = true;
            }
            if (datas.includes("PROJNO")) {
              this.PROJNOHidden = false;
            } else {
              this.PROJNOHidden = true;
            }
            if (datas.includes("FOLDERNO")) {
              this.FOLDERNOHidden = false;
            } else {
              this.FOLDERNOHidden = true;
            }
            if (datas.includes("TYPE")) {
              this.TYPEHidden = false;
            } else {
              this.TYPEHidden = true;
            }
            if (datas.includes("Description")) {
              this.DescriptionHidden = false;
            } else {
              this.DescriptionHidden = true;
            }
            // DescriptionHidden: any = false;
            if (datas.includes("SUPPUSERCODE")) {
              this.SUPPUSERCODEHidden = false;
            } else {
              this.SUPPUSERCODEHidden = true;
            }

            if (datas.includes("SUPPNAME")) {
              this.SUPPNAMEHidden = false;
            } else {
              this.SUPPNAMEHidden = true;
            }
            if (datas.includes("Folderdate")) {
              this.FOLDERDATEHidden = false;
            } else {
              this.FOLDERDATEHidden = true;
            }
            if (datas.includes("BuyAmtCol")) {
              this.BuyAmtHidden = false;
            } else {
              this.BuyAmtHidden = true;
            }
            if (datas.includes("Pass")) {
              this.PassHidden = false;
            } else {
              this.PassHidden = true;
            }

            if (datas.includes("SELLAMT")) {
              this.SELLAMTHidden = false;
            } else {
              this.SELLAMTHidden = true;
            }


            if (datas.includes("TOMSVATAMT")) {
              this.TOMSVATAMTHidden = false;
            } else {
              this.TOMSVATAMTHidden = true;
            }

            if (datas.includes("CITYNAME")) {
              this.CITYNAMEHidden = false;
            } else {
              this.CITYNAMEHidden = true;
            }
            if (datas.includes("DiscVal")) {
              this.LOYALTYPOINTSAMTHidden = false;
            } else {
              this.LOYALTYPOINTSAMTHidden = true;
            }
            //

            if (datas.includes("LOYALTYPOINTS")) {
              this.LOYALTYPOINTSHidden = false;
            } else {
              this.LOYALTYPOINTSHidden = true;
            }
            if (datas.includes("MemberShipNoCol")) {
              this.LOYALTYPOINTSMEMBNOHidden = false;
            } else {
              this.LOYALTYPOINTSMEMBNOHidden = true;
            }
            if (datas.includes("QCDATE")) {
              this.QCDATEHidden = false;
            } else {
              this.QCDATEHidden = true;
            }
            if (datas.includes("FM_DM_UID")) {
              this.FM_DM_UIDHidden = false;
            } else {
              this.FM_DM_UIDHidden = true;
            }
            if (datas.includes("BookedBy")) {
              this.BOOKEDBYHidden = false;
            } else {
              this.BOOKEDBYHidden = true;
            }
            if (datas.includes("bookingRef")) {
              this.bookingRefHidden = false;
            } else {
              this.bookingRefHidden = true;
            }
            if (datas.includes("Status")) {
              this.StatusHidden = false;
            } else {
              this.StatusHidden = true;
            }

            if (datas.includes("NIGHTS")) {
              this.NIGHTSHidden = false;
            } else {
              this.NIGHTSHidden = true;
            }
            if (datas.includes("ClientCountry")) {
              this.CustLidHidden = false;
            } else {
              this.CustLidHidden = true;
            }
            if (datas.includes("CntryCodeCol")) {
              this.CustCountryHidden = false;
            } else {
              this.CustCountryHidden = true;
            }
            if (datas.includes("ClientCountryName")) {
              this.CountryNameHidden = false;
            } else {
              this.CountryNameHidden = true;
            }
            if (datas.includes("ATOLPAX")) {
              this.ATOLPAXHidden = false;
            } else {
              this.ATOLPAXHidden = true;
            }
            if (datas.includes("INETREF")) {
              this.INETREFHidden = false;
            } else {
              this.INETREFHidden = true;
            }
            if (datas.includes("PnrCol")) {
              this.UPLOADRCLHidden = false;
            } else {
              this.UPLOADRCLHidden = true;
            }

            if (datas.includes("YourRefCol")) {
              this.PONOHidden = false;
            } else {
              this.PONOHidden = true;
            }
            if (datas.includes("OrderTypeCol")) {
              this.OrderTypeHidden = false;
            } else {
              this.OrderTypeHidden = true;
            }
            if (datas.includes("HtlCol")) {
              this.HOTELNAMEHidden = false;
            } else {
              this.HOTELNAMEHidden = true;
            }
            if (datas.includes("Inputvatcol")) {
              this.BSPPurchaseTaxHidden = false;
            } else {
              this.BSPPurchaseTaxHidden = true;
            }
            if (datas.includes("PmCol")) {
              this.PaymentMethodHidden = false;
            } else {
              this.PaymentMethodHidden = true;
            }

            if (datas.includes("CustNameColumn")) {
              this.NAMEHidden = false;
            } else {
              this.NAMEHidden = true;
            }
            if (datas.includes("MCIdColumn")) {
              this.MCIDHidden = false;
            } else {
              this.MCIDHidden = true;
            }
            if (datas.includes("DateinCol")) {
              this.DATEINHidden = false;
            } else {
              this.DATEINHidden = true;
            }
            if (datas.includes("DateOutCol")) {
              this.DATEOUTHidden = false;
            } else {
              this.DATEOUTHidden = true;
            }


            if (datas.includes("DepDueDateCol")) {
              this.DEPDUEDATEHidden = false;
            } else {
              this.DEPDUEDATEHidden = true;
            }

            if (datas.includes("ProdctType")) {
              this.ProdctTypeHidden = false;
            } else {
              this.ProdctTypeHidden = true;
            }

            if (datas.includes("Provider")) {
              this.ProviderHidden = false;
            } else {
              this.ProviderHidden = true;
            }

            if (datas.includes("ItineraryNotes")) {
              this.ItineraryNotesHidden = false;
            } else {
              this.ItineraryNotesHidden = true;
            }

            if (datas.includes("EntitlementAmount")) {
              this.EntitlementAmountHidden = false;
            } else {
              this.EntitlementAmountHidden = true;
            }
            if (datas.includes("Remarks")) {
              this.RemarksHidden = false;
            } else {
              this.RemarksHidden = true;
            }


            if (datas.includes("TotalFareCol")) {
              this.TotalHidden = false;
            } else {
              this.TotalHidden = true;
            }


            if (datas.includes("SalesTaxCol")) {
              this.SalesTaxHidden = false;
            } else {
              this.SalesTaxHidden = true;
            }

            if (datas.includes("TotalAmount")) {
              this.TotalAmountHidden = false;
            } else {
              this.TotalAmountHidden = true;
            }
            if (datas.includes("OriginCol")) {
              this.OriginHidden = false;
            } else {
              this.OriginHidden = true;
            }
            if (datas.includes("CustCodeCol")) {
              this.CustomerCodeHidden = false;
            } else {
              this.CustomerCodeHidden = true;
            }
            if (datas.includes("PassCodeCol")) {
              this.PassengerCodeHidden = false;
            } else {
              this.PassengerCodeHidden = true;
            }
            if (datas.includes("InvDateCol")) {
              this.InvoiceDateHidden = false;
            } else {
              this.InvoiceDateHidden = true;
            }

            if (datas.includes("InvCol")) {
              this.InvoiceNoHidden = false;
            } else {
              this.InvoiceNoHidden = true;
            }


            if (datas.includes("ReturnDateCol")) {
              this.ReturnDateHidden = false;
            } else {
              this.ReturnDateHidden = true;
            }
            if (datas.includes("SellAmtCol")) {
              this.SellAmountHCHidden = false;
            } else {
              this.SellAmountHCHidden = true;
            }


            if (datas.includes("NoOfPax")) {
              this.NoOfPaxHidden = false;
            } else {
              this.NoOfPaxHidden = true;
            }
            if (datas.includes("fldrBkdbyCol")) {
              this.FolderBookedByHidden = false;
            } else {
              this.FolderBookedByHidden = true;
            }


            if (datas.includes("Tkt_Type")) {
              this.Tkt_TypeHidden = false;
            } else {
              this.Tkt_TypeHidden = true;
            }
            if (datas.includes("TicketingDeadline")) {
              this.TicketingDeadlineHidden = false;
            } else {
              this.TicketingDeadlineHidden = true;
            }
            if (datas.includes("GDS")) {
              this.GDSHidden = false;
            } else {
              this.GDSHidden = true;
            }
            if (datas.includes("Receiptstatus")) {
              this.ReceiptstatusHidden = false;
            } else {
              this.ReceiptstatusHidden = true;
            }

            if (datas.includes("ProductStatus")) {
              this.ProductStatusHidden = false;
            } else {
              this.ProductStatusHidden = true;
            }

            if (datas.includes("VoucherNo")) {
              this.VoucherNoHidden = false;
            } else {
              this.VoucherNoHidden = true;
            }


            if (datas.includes("BalanceDueAmount")) {
              this.BalanceDueAmountHidden = false;
            } else {
              this.BalanceDueAmountHidden = true;
            }


            if (datas.includes("DEPDATE")) {
              this.DEPDATEHidden = false;
            } else {
              this.DEPDATEHidden = true;
            }
            if (datas.includes("ARRDATE")) {
              this.ARRDATEHidden = false;
            } else {
              this.ARRDATEHidden = true;
            }
            if (datas.includes("TaxesCol")) {
              this.TaxesHidden = false;
            } else {
              this.TaxesHidden = true;
            }
          }
        } else {
          this.enableAllColumn();
        }
      })
    );
  }

  public GetReportHeaderFooter() {
    this.subscription.add(
      this.FolderActivityService
        .GetReportHeaderFooter(this.userID, 0)
        .subscribe(data => {
          debugger;
          let objectURL = "data:image/png;base64," + data["ResponseData"];

          this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.LoadReportColumn();
        })
    );
  }



  async export(pdfComponent: any) {
    let processedUri: any;
    let baseprocess = await pdfComponent
      .export()
      .then((group: Group) => exportPDF(group))
      .then(dataUri => {
        const base64 = dataUri.replace("data:application/pdf;base64,", "");
        processedUri = dataUri;
      });
    const fileObject = await this.dataURLtoFile(
      processedUri,
      "Folder Activity Detailed Report.pdf"
    );
    return fileObject;
  }
  async dataURLtoFile(dataurl, filename) {
    let file: any;
    let dataURL = new Promise(resolve => {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let newFile = new File([u8arr], filename, { type: mime });
      file = newFile;
      resolve(newFile);
    });
    this.email.fileurl = file;
    this.email.filename = "Folder Activity Detailed Report.pdf";
    this.email.reportname = "Folder Activity Detailed Report";
    this.open();
    return file;
  }

  EmailPDF() {
    debugger;
    this.email.Isgridpdf = true;
    this.export(this.pdf);
  }



  public rightAlign: object = {
    "text-align": "right"
  };
  onCancel() {
    var reporturl = sessionStorage.getItem("menuhighlight");
    this.FolderActivityService.griddata = "";
    if (reporturl != null) {
      this.router.navigate(["/Login"]);
    } else {

      this.router.navigate(["/PenAir/Reports/FolderActivityDetailedReport"]);
    }

  }

  public EmailFormClosing(
    status: any //Email status change by case id-12434
  ) {
    if (status == "EmailSendSuccesfully" || status == "Closed") {
      //Email status change by case id-12434
      this.opened = false;
    }
  }
  onFilterClick() {
    debugger
    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }
  public open() {
    this.opened = true;
  }

  public close(status) {
    this.opened = false;
    this.email.fileurl = null;
    this.email.filename = null;
    this.email.reportname = null;
  }


  excel() {
    this.email.filename = "Folder Activity Detailed Report.xlsx";
    this.email.reportname = "Folder Activity Detailed  Report";
    this.open();
  }

  public async onExcelExport2(args: any) {
    debugger;
    this.email.filename = "Folder Activity Detailed Report.xlsx";
    this.email.reportname = "Folder Activity Detailed Report";
    args.preventDefault();
    const observables = [];
    let workbook = args.workbook;
    let rows = workbook.sheets[0].rows;
    let headerOptions = rows[0].cells[0];
    var reader = new FileReader();
    var sheet = workbook.sheets[0];

    if ((this.formData.CreationFromDate != undefined && this.formData.CreationFromDate != null && this.formData.CreationFromDate != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'Creation Date', this.common.ConvertToLocaleDate(this.formData.CreationFromDate), this.common.ConvertToLocaleDate(this.formData.CreationToDate));

    }
    if ((this.formData.InvoiceFromDate != undefined && this.formData.InvoiceFromDate != null && this.formData.InvoiceFromDate != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'Invoice Date', this.common.ConvertToLocaleDate(this.formData.InvoiceFromDate), this.common.ConvertToLocaleDate(this.formData.InvoiceToDate));

    }
    if ((this.formData.BookingDateFrom != undefined && this.formData.BookingDateFrom != null && this.formData.BookingDateFrom != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'Booking Date', this.common.ConvertToLocaleDate(this.formData.BookingDateFrom), this.common.ConvertToLocaleDate(this.formData.BookingDateTo));

    }
    if ((this.formData.QConDateFrom != undefined && this.formData.QConDateFrom != null && this.formData.QConDateFrom != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'QCon Date', this.common.ConvertToLocaleDate(this.formData.QConDateFrom), this.common.ConvertToLocaleDate(this.formData.QConDateTo));
    }

    if ((this.formData.ProfitFrom != undefined && this.formData.ProfitFrom != null && this.formData.ProfitFrom != "")) {
      this.common.generateExcelFilterheader(sheet, 'Profit', this.formData.ProfitFrom.toString(), this.formData.ProfitTo.toString());

    }
    if ((this.formData.CustCode_int != undefined && this.formData.CustCode_int != null && this.formData.CustCode_int != "")) {
      this.common.generateExcelFilterheader(sheet, 'Customer', this.FolderActivityService.custname.toString(), "");
    }
    if ((this.formData.PrtyCode != undefined && this.formData.PrtyCode != null && this.formData.PrtyCode != "")) {
      this.common.generateExcelFilterheader(sheet, 'Supplier', this.FolderActivityService.supliername.toString(), "");
    }
    if ((this.formData.FolderBookedBy != undefined && this.formData.FolderBookedBy != null && this.formData.FolderBookedBy != "")) {
      this.common.generateExcelFilterheader(sheet, 'Folder Booked By', this.formData.FolderBookedBy.toString(), "");
    }
    if ((this.formData.pProductCode_int != undefined && this.formData.pProductCode_int != null && this.formData.pProductCode_int != "")) {
      this.common.generateExcelFilterheader(sheet, 'Product', this.ProductCodeName.toString(), "");
    }
    if ((this.formData.Branch_varchar != undefined && this.formData.Branch_varchar != null && this.formData.Branch_varchar != "")) {
      this.common.generateExcelFilterheader(sheet, 'Branch', this.branchname.toString(), "");

    }
    if ((this.formData.BArea_varchar != undefined && this.formData.BArea_varchar != null && this.formData.BArea_varchar != "")) {
      this.common.generateExcelFilterheader(sheet, 'BussinessArea', this.BAreaname.toString(), "");

    }
    if ((this.formData.SalesOrgCode != undefined && this.formData.SalesOrgCode != null && this.formData.SalesOrgCode != "")) {
      this.common.generateExcelFilterheader(sheet, 'Sales Organisation', this.formData.SalesOrgCode.toString(), "");

    }
    if ((this.formData.CCGINTCODE_bigint != undefined && this.formData.CCGINTCODE_bigint != null && this.formData.CCGINTCODE_bigint != "")) {
      this.common.generateExcelFilterheader(sheet, 'Credit Control', this.creditName.toString(), "");

    }
    if ((this.formData.CCGINTCODE_bigint != undefined && this.formData.CCGINTCODE_bigint != null && this.formData.CCGINTCODE_bigint != "")) {
      this.common.generateExcelFilterheader(sheet, 'Credit Control', this.creditName.toString(), "");

    }

    if ((this.formData.CustGrp_varchar != undefined && this.formData.CustGrp_varchar != null && this.formData.CustGrp_varchar != "")) {
      this.common.generateExcelFilterheader(sheet, 'Customer Group', this.formData.CustGrp_varchar.toString(), "");

    }
    if ((this.formData.DCM_UID != undefined && this.formData.DCM_UID != null && this.formData.DCM_UID != "")) {
      this.common.generateExcelFilterheader(sheet, 'Distribution', this.formData.DCM_UID.toString(), "");

    }
    if ((this.formData.pOrderType_varchar != undefined && this.formData.pOrderType_varchar != null && this.formData.pOrderType_varchar != "")) {
      this.common.generateExcelFilterheader(sheet, 'Order Type', this.formData.pOrderType_varchar.toString(), "");

    }
    if ((this.formData.ProdStatus != undefined && this.formData.ProdStatus != null && this.formData.ProdStatus != "")) {
      this.common.generateExcelFilterheader(sheet, 'Product Status', this.formData.ProdStatus.toString(), "");

    }
    if ((this.formData.ReciptStatus != undefined && this.formData.ReciptStatus != null && this.formData.ReciptStatus != "")) {
      this.common.generateExcelFilterheader(sheet, 'Receipt Status', this.formData.ReciptStatus.toString(), "");

    }
    if ((this.formData.Destination != undefined && this.formData.Destination != null && this.formData.Destination != "")) {
      this.common.generateExcelFilterheader(sheet, 'Destination', this.destName.toString(), "");

    }
    if ((this.formData.payment_method != undefined && this.formData.payment_method != null && this.formData.payment_method != "")) {
      this.common.generateExcelFilterheader(sheet, 'Payment method', this.formData.payment_method.toString(), "");

    }

    if ((this.formData.pBookedBy_varchar != undefined && this.formData.pBookedBy_varchar != null && this.formData.pBookedBy_varchar != "")) {
      this.common.generateExcelFilterheader(sheet, 'Line Item Booked By', this.formData.pBookedBy_varchar.toString(), "");

    }
    if ((this.formData.OrderNo != undefined && this.formData.OrderNo != null && this.formData.OrderNo != "")) {
      this.common.generateExcelFilterheader(sheet, 'OrderNo', this.formData.OrderNo.toString(), "");

    }
    if ((this.formData.vFolderNo != undefined && this.formData.vFolderNo != null && this.formData.vFolderNo != "")) {
      this.common.generateExcelFilterheader(sheet, 'FolderNo', this.formData.vFolderNo.toString(), "");

    }
    if ((this.formData.DM_UID != undefined && this.formData.DM_UID != null && this.formData.DM_UID != "")) {
      this.common.generateExcelFilterheader(sheet, 'Division', this.formData.DM_UID.toString(), "");

    }







    if ((this.email.reportname != undefined)) {
      this.common.generateExcelMainheader(sheet, this.email.reportname);
    }
    await this.common.SetCompanyheaderExcelheader(sheet)
    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      saveAs(dataUrl, "Folder Activity Detailed Report.xlsx");
      this.email.fileurl = this.dataURItoBlob(dataUrl);
    });
  }

  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  public rowCallback(context: RowClassArgs) {
    const isActive = context.dataItem.BANAME === 'Total';
    return {
      active: isActive
    };
    // }
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    debugger;
    var data = process(this.Primarygriddata, this.state);
    this.gridView = data.data;
  }

  public onFilterClicksub() {
    this.showFilterSub = !this.showFilterSub;
    this.showFilterSub = this.showFilterSub ? true : false;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    var arrays = []
    if (state && state.group) {
      state.group.map(group => group.aggregates = this.aggregates);
    }
    var data = process(this.Primarygriddata, this.state);
    this.gridView = data.data;
  }

  ngOnDestroy() {
    this.common.masterData = [];
    this.subscription.unsubscribe();
  }
}
