import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { LookUpDetails } from "../../Services/look-up-details";
import { LookUpDetailsService } from "../../Services/look-up-details.service";
import { FolderSummaryService } from "../../Services/folder-summary.service";
import {
  CustomerCreate,
  CustomerMaster,
  Login,
} from "../../Models/customer-create.model";
import { FolderSummaryEditComponent } from "../../folder/folder-summary-edit/folder-summary-edit.component";
import { FlashMessagesService } from "angular2-flash-messages";
import { stringify } from "querystring";
import { TranslateService } from "@ngx-translate/core";
import { ShareDataService } from "../../Services/share-data.service";
import { JsonPipe } from "@angular/common";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { AlertComponent } from "../../alert/alert.component";
import { FileSelectModule } from "@progress/kendo-angular-upload";
import { FileInfo } from "@progress/kendo-angular-upload";
import {
  SelectEvent,
  RemoveEvent,
  FileRestrictions,
} from "@progress/kendo-angular-upload";
import { EmailService } from "../../Services/email.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { EnvService } from "src/env.service";
import { RptAuditReportFolderTransDetailsComponent } from "../../rpt-audit-report-folder-trans-details/rpt-audit-report-folder-trans-details.component";
import { RptAuditReportComponent } from "../../rpt-audit-report/rpt-audit-report.component";
import { RoomtypesmasterComponent } from "../../roomtypesmaster/roomtypesmaster.component";
import { MealplanmasterComponent } from "../../mealplanmaster/mealplanmaster.component";
import { HotelPropertyTypesComponent } from "../../hotel-property-types/hotel-property-types.component";
import { ProjectmasterComponent } from "../../projectmaster/projectmaster.component";
import { PettycashmasterListComponent } from "../../pettycashmaster-list/pettycashmaster-list.component";
import { ProductgroupmasterListComponent } from "../../productgroupmaster-list/productgroupmaster-list.component";
import { PenchannelListComponent } from "../penchannel-list/penchannel-list.component";
import { FolderSummaryComponent } from "../../folder/folder-summary/folder-summary.component";
import { RptLedgerReportgridComponent } from "../../rpt-ledger-reportgrid/rpt-ledger-reportgrid.component";
import { FolderQuotesListComponent } from "../../folder-quotes-list/folder-quotes-list.component";
import { RptAirlineDetailedReportComponent } from '../../rpt-airline-detailed-report/rpt-airline-detailed-report.component';
import { CustomerOutstandingSummaryReportViewComponent } from "../../customer-outstanding-summary-report-view/customer-outstanding-summary-report-view.component";
import { GeneralledgerReportComponent } from '../../generalledger-report/generalledger-report.component';
import { InvoiceComponent } from "src/app/folder/invoice/invoice.component";
import { CrystalReportService } from "../../Services/crystal-report.service";
import { AtolReportDTO } from "../../Models/crystal-report.model";
import { VouchermanagementComponent } from "../../VoucherManagement/vouchermanagement/vouchermanagement.component";
import { FolderQueryReportComponent } from '../../folder-query-report/folder-query-report.component';
import { CommonVariencereportService } from "../../Services/common-variencereport.service";



@Component({
  selector: "app-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.scss"],
})
export class EmailComponent implements OnInit {
  @Output() emailStatus = new EventEmitter<string>(); //Email status change by case id-12434
  @Input() isWebLink: boolean;
  @ViewChild("myFileSelect", { read: true }) myFile;
  private subscription: Subscription = new Subscription();
  addressdetailsform: FormGroup;
  public opened = false;
  private lookup: LookUpDetails;
  public myselectedfiles: any;
  public myselectedfilesfinal: any;
  ccvalid: any = true;
  public allowCustom: boolean = true;
  public IsAutocode = false;
  public Salutation: string = "";
  public reponse: any;
  TYPEOFINV: any = 0;
  emailsubject: any = '';
  showfilebtn: any = false;
  reportpathname: any = '';
  public myFiles: Array<File>;
  public showMsg: any;
  formdats: any;
  formdatstest: any;
  morefiles: any = false;
  isfolderNo: any = false;
  public submitted = false;
  DocusignMail: any = false;
  public CustomerName: string;
  url: string;
  constructor(
    private reportser: CrystalReportService,
    private env: EnvService,
    private http: HttpClient,
    public emailser: EmailService,
    private spinner: NgxSpinnerService,
    private apiShared: ShareDataService,
    private translateapi: TranslateService,
    private formBuilder: FormBuilder,
    private lookupApi: LookUpDetailsService,
    private flashMessage: FlashMessagesService,
    private api: FolderSummaryService,
    private toastr: ToastrService,
    private rptAPI: CommonVariencereportService
  ) {

    this.url = env.baseUrl;

  }
  LanguageList: Array<LookUpDetails["ResponseData"]> = [];
  Fromlist: any;
  BccList: any;
  public LanguageSelectedItem: LookUpDetails["ResponseData"];
  public languageSelected: String;
  CountryList: Array<LookUpDetails["ResponseData"]> = [];
  public countrySelectedItem: LookUpDetails["ResponseData"];
  public countrySelected: String;
  public FromSelectedItem: any;
  public BCCSelectedItem: any;
  Fromvalue: string = "";
  ToValue: string = "";
  CCValue: string = "";
  EmailTemplateList: Array<LookUpDetails["ResponseData"]> = [];
  public TemplateSelectedItem: LookUpDetails["ResponseData"];

  public pdfFilePath = '';
  public Atolcertificate: any = null;
  showemailattch: any = false;
  pdfurl: string;

  Projno: any = 0;
  emailopened: any = false;
  rptfilename: any = '';
  docusignurl: any = '';
  objDocusign: any;
  filepathatol: any = '';
  attolreportdeletepath: any = '';
  emailatolpath: any = '';
  uploadSaveUrl = "saveUrl"; // should represent an actual API endpoint
  uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint
  public myFiles1: string[] = [];
  public defaulturl: any;

  myFileSelect: Array<any> = [
    { name: "First.txt", size: 500, File: "" },
    { name: "Second.jpg", size: 100 },
  ];
  filename: any = [];
  fileToUpload: File;
  Filedat: any;
  changeTempl: any = false;
  public loading: boolean = false;
  public listItems: Array<string> = [
    "Baseball",
    "Basketball",
    "Cricket",
    "Field Hockey",
    "Football",
    "Table Tennis",
    "Tennis",
    "Volleyball",
  ];
  public value: any = ["Baseball"];
  ngOnInit() {
    // this.LoadBasicdat();
    debugger;
    if (this.emailser.filename && this.emailser.filename != '')
      this.filename.push(this.emailser.filename);
    if (this.emailser.cannedMsgAttachment && this.emailser.cannedMsgAttachment != '') {
      var cmattchmnts = this.emailser.cannedMsgAttachment.split(',');
      if (cmattchmnts.length > 0)
        cmattchmnts.forEach((file) => {
          if (file) {
            this.filename.push(file.split('\\').pop());
          }
        });
      else
        this.filename.push(cmattchmnts);

    }
    this.myselectedfiles = [];
    this.myselectedfilesfinal = [];
    this.addressdetailsform = this.formBuilder.group({
      From: ["", Validators.required],
      To: [""],
      CC: [""],
      Subject: [""],
      Body: [""],
      BCC: [""],
    }); //  this.getdefaultvalue();
    debugger;
    this.subscription.add(this.lookupApi.GetAllLookup('EmailTemplate').subscribe(
      (data) => {
        this.EmailTemplateList = data["ResponseData"]

      }
    ));
    if (this.emailser.AtolEnabled) {
      this.calculateAtolForFolderemail();
    }
    this.subscription.add(
      this.lookupApi.GetAllLookup("DocuSignURL").subscribe((data) => {
        if (data != null && data != undefined) {
          debugger;
          if (data["ResponseData"][0] != undefined) {
            this.docusignurl = data["ResponseData"][0].Name;
            if (this.emailser.commonservice.formData != undefined && this.emailser.commonservice.formData.FolderNo != '' && this.emailser.commonservice.formData.FolderNo != null && this.emailser.commonservice.formData.FolderNo != undefined && this.docusignurl != '' && this.docusignurl != null) {
              this.isfolderNo = true;
            }
            else {
              this.isfolderNo = false;
            }
          }
        }
      })
    );
    this.LoadBasicdat();
  }

  get f() {
    return this.addressdetailsform.controls;
  }

  downloadFile() {
    let link = document.createElement("a");
    link.download = "filename";
    link.href = "assets/Book1.xlsx";

    const frmData2 = new FormData();

    frmData2.append("myfiles1", link.href);

    this.formdatstest = link.href;
    link.click();
  }
  public changeTemplte() {
    if (this.changeTempl == false) {
      this.changeTempl = true
    }
    else {
      this.changeTempl = false
    }
  }
  public onFormSubmit() {
    // debugger;
    const frmData5 = new FormData();
    if (this.myselectedfiles != undefined) {
      this.myselectedfiles.forEach((file) => {
        // console.log(`File selected: ${file.name}`);

        if (!file.validationErrors) {
          const reader = new FileReader();
          frmData5.append("myfiles", file.rawFile);
          reader.readAsDataURL(file.rawFile);
        }
      });
      this.formdats = frmData5;
    }

    //     let link = document.createElement('a');
    // link.setAttribute('type', 'hidden');
    // link.href = 'assets/Book1.xlsx';

    this.spinner.show();
    this.submitted = true;

    if (this.addressdetailsform.value["From"] == undefined) {
      this.addressdetailsform.value["From"] = null;
    }
    if (this.addressdetailsform.value["To"] == undefined) {
      this.addressdetailsform.value["To"] = null;
    }
    if (this.addressdetailsform.value["From"] == null) {
      this.spinner.hide();
      return;
    }
    if (
      !this.addressdetailsform.controls["BCC"].valid ||
      !this.addressdetailsform.controls["To"].valid ||
      !this.addressdetailsform.controls["CC"].valid
    ) {
      this.spinner.hide();
      return;
    }
    if (this.addressdetailsform.controls["CC"].value == undefined || this.addressdetailsform.controls["CC"].value == null) {
      this.addressdetailsform.controls["CC"].setValue(
        ''
      );
    }
    if (this.addressdetailsform.controls["BCC"].value == undefined || this.addressdetailsform.controls["BCC"].value == null) {
      this.addressdetailsform.controls["BCC"].setValue(
        ''
      );
    }
    if (this.addressdetailsform.controls["To"].value == undefined || this.addressdetailsform.controls["To"].value == null) {
      this.addressdetailsform.controls["To"].setValue(
        ''
      );
    }
    if (
      this.addressdetailsform.controls["BCC"].value == '' && this.addressdetailsform.controls["To"].value == '' &&
      this.addressdetailsform.controls["CC"].value == ''
    ) {
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidTomail"])
          .subscribe((translations) => {
            this.showMsg = translations["Messages.InvalidTomail"];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true,
            });
          })
      );
      this.spinner.hide();
      return;
    }
    var frmData = new FormData();

    const frmData1 = new FormData();
    // this.formdats=frmData;
    //formdats.append('Excelfile', this.emailser.fileurl);
    frmData = this.formdats;
    if (frmData == undefined) {
      frmData = new FormData();
    }
    //  frmData.append("Myfiless",this.formdatstest.rawFile)
    if (
      this.addressdetailsform.value["To"] == "" ||
      this.addressdetailsform.value["To"] == undefined
    ) {
      this.addressdetailsform.controls["To"].setValue(null);
    }
    if (this.emailser.fileurl != null && this.emailser.fileurl != undefined)
      frmData.append("myfiles3", this.emailser.fileurl);
    frmData.append("filename", this.emailser.filename);
    frmData.append("To", this.addressdetailsform.value["To"]);
    frmData.append("BCC", this.addressdetailsform.value["BCC"]);

    frmData.append("CC", this.addressdetailsform.value["CC"]);
    //frmData.append('From', this.addressdetailsform.value["From"]);
    frmData.append("From", this.Fromvalue); //Email status change by case id-12434
    frmData.append("Isgridpdf", this.emailser.Isgridpdf);
    frmData.append("Body", this.addressdetailsform.value["Body"]);
    if (this.emailser.Atolre == true || this.emailser.isCrystalre == true) {
      frmData.append("Subject", this.emailser.emailsubject);
      frmData.append("Atolpath", this.emailser.filename);
      if (this.emailser.atolfilepath != "") {
        frmData.append("atolfilepath", this.emailser.atolfilepath);
      } else {
        frmData.append("atolfilepath", this.emailser.filepath);
      }
      frmData.append("folderreportfilepath", this.emailser.folderreportfilepath);


      if (this.emailser.emailatolpath != "") {
        frmData.append("emailatolpath", this.emailser.emailatolpath);
      } else {
        frmData.append("emailatolpath", this.emailser.emailpath);
      }
      if (this.emailser.commonservice.formData != undefined) {


        frmData.append("projno", this.emailser.commonservice.formData.projno);
        frmData.append("folderno", this.emailser.commonservice.formData.FolderNo);
        if (this.emailser.commonservice.formData.FolderNo != '' && this.emailser.commonservice.formData.FolderNo != null && this.emailser.commonservice.formData.FolderNo != undefined && this.docusignurl != '' && this.docusignurl != null) {
          this.isfolderNo = true;
        }
        else {
          this.isfolderNo = false;
        }
      }

    } else {
      frmData.append("Subject", this.addressdetailsform.value["Subject"]);
    }
    frmData.append("DocusignMail", this.DocusignMail);
    frmData.append("attachments", this.emailser.cannedMsgAttachment);
    //var data= this.emailser.upload(frmData,this.addressdetailsform.value);
    this.loading = true;
    const url = `${this.url}` + "UploadFilesorder";
    this.http.post(url, frmData).subscribe((data) => {
      debugger;
      if (data != undefined && data != null) {
        if (data[0].toLowerCase() == "true") {
          this.subscription.add(
            this.translateapi
              .get(["Messages.Mailsent"])
              .subscribe((translations) => {
                this.showMsg = translations["Messages.Mailsent"];
                this.toastr.success(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true,
                });
              })
          );
          this.loading = false;
          this.spinner.hide();
          // this.Pcomponent.close(false);
          this.close();
          this.emailStatus.emit("EmailSendSuccesfully"); //Email status change by case id-12434
        } else if (data[0] == "False") {
          //By Caseid-333
          this.spinner.hide();
          this.subscription.add(
            this.translateapi
              .get(["Messages.MailSendFailed"])
              .subscribe((translations) => {
                this.showMsg = translations["Messages.MailSendFailed"];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true,
                });
              })
          );
        }
        else if (data[1] == "True") {
          this.spinner.hide();
          this.DocusignMail = false;

          this.showMsg = data[0]; //Email status change by case id-12434
          this.toastr.success(this.showMsg, "", {
            timeOut: 5000,
            disableTimeOut: false,
            closeButton: true,
          });
          this.loading = false;
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.DocusignMail = false;

          this.showMsg = data[0]; //Email status change by case id-12434
          this.toastr.warning(this.showMsg, "", {
            timeOut: 5000,
            disableTimeOut: false,
            closeButton: true,
          });
          this.close();
          this.loading = false;
          this.spinner.hide();
          this.emailStatus.emit("false"); //Email status change by case id-12434
        }
      }
    });
    this.submitted = false;
  }

  handleFilter(value) {
    this.listItems.push(value);
    this.value.push(value);
  }
  public valueChange(value: any): void {
    this.listItems.push(value);
    this.value.push(value);
  }
  public removeEventHandler(e: RemoveEvent): void {
    var index1 = 0;
    // console.log(`Removing ${e.files[0].name}`);
    // if(index1==0)
    // this.myselectedfiles.forEach((file) => {
    // if(file.uid!=e.files[0].uid){
    //   this.myselectedfilesfinal.push(file);
    // }
    const index = this.myselectedfiles.findIndex(
      (item) => item.uid === e.files[0].uid
    );

    if (index >= 0) {
      index1 = 1;

      this.myselectedfiles.splice(index, 1);
    }
    if (this.myselectedfiles == null || this.myselectedfiles == undefined || this.myselectedfiles.length == 0) {
      this.morefiles = false;
      this.showfilebtn = false;
    }
    // })
    // this.myselectedfiles= this.myselectedfilesfinal;
  }
  public selectEventHandler(e: SelectEvent): void {
    const frmData = new FormData();

    e.files.forEach((file) => {
      // console.log(`File selected: ${file.name}`);

      if (!file.validationErrors) {
        const reader = new FileReader();

        frmData.append("myfiles", file.rawFile);

        this.myselectedfiles.push(file);

        reader.readAsDataURL(file.rawFile);
      }
    });
    if (e.files != null && e.files != undefined && e.files.length > 0) {
      this.showfilebtn = true;
    }
    this.formdats = frmData;
  }

  public close() {
    debugger;
    this.emailser.emailatolpath = '';
    this.opened = false;
    this.emailStatus.emit('Closed');

  }

  public open() {
    this.opened = true;
  }
  public LoadBasicdat() {
    debugger;
    // if(this.emailser.commonservice.formData.FolderNo!=''&&  this.emailser.commonservice.formData.FolderNo!=null&& this.emailser.commonservice.formData.FolderNo!=undefined &&this.docusignurl!='' &&this.docusignurl!=null)
    //   {
    //     this.isfolderNo=true;
    //   }
    //   else{
    //     this.isfolderNo=false;
    //   }
    debugger;
    this.Fromlist = this.emailser.Fromlist;
    if (this.Fromlist != undefined && this.Fromlist.length > 0) {


      this.FromSelectedItem = this.Fromlist.find(x => x.No == 1);
      this.addressdetailsform.value["From"] =
        this.FromSelectedItem.FROMEMAIL.toString();

      this.addressdetailsform.controls["CC"].setValue(
        this.emailser.CCEmail
      );
      // this.BccList = data["ResponseData"]["BCCMailList"];
      // this.BCCSelectedItem = this.BccList[0];
      // this.addressdetailsform.value["BCC"] =
      //   this.BCCSelectedItem.PENEMAILDefaultBCCEmail.toString();
      // this.addressdetailsform.controls["BCC"].setValue(
      //   this.BCCSelectedItem.PENEMAILDefaultBCCEmail.toString()
      // );
      debugger;
      if (this.emailser.Atolre == true || this.emailser.isCrystalre == true) {
        this.addressdetailsform.controls["Subject"].setValue(
          this.emailser.emailsubject
        );
        this.addressdetailsform.controls["Body"].setValue(
          this.emailser.emailbody
        );
        if (this.emailser.filename == "RecieptPrinting.pdf") {
          this.addressdetailsform.controls["Body"].setValue(
            this.emailser.commonservice.formData.RecieptEmailBody
          );
        }
        // else if((this.emailser.emailbody==''|| this.emailser.emailbody==null)&& this.emailser.commonservice.formData.EmailBody!="" &&  this.emailser.commonservice.formData.EmailBody!=undefined){
        //   this.addressdetailsform.controls["Body"].setValue(
        //     this.emailser.commonservice.formData.EmailBody
        //   );
        // }
        else if (this.emailser.commonservice.formData.EmailBody != "" && this.emailser.commonservice.formData.EmailBody != undefined) {
          this.addressdetailsform.controls["Body"].setValue(
            this.emailser.commonservice.formData.EmailBody
          );
        }
      } else {
        this.addressdetailsform.controls["Subject"].setValue(
          this.emailser.emailsubject
        );


      }
      if (this.emailser.commonservice.formData.ToEmail != '' && this.emailser.commonservice.formData.ToEmail != undefined) {
        this.addressdetailsform.controls["To"].setValue(
          this.emailser.commonservice.formData.ToEmail
        );
      }
      if (this.emailser.emailsubject == null || this.emailser.emailsubject == '') {
        this.emailser.emailsubject = this.emailser.reportname;
      }

      this.Fromvalue = this.FromSelectedItem.FROMEMAIL.toString();

      var str = this.Fromvalue;
      var splitted = str.split("<");

      if (splitted.length > 1) {
        var splitted1 = splitted[1].split(">");
        this.Fromvalue = splitted1[0].toString();
      }
      this.CCValue = this.addressdetailsform.value["CC"];

      var str3 = this.CCValue;
      if (str3 != undefined && str3 != null) {
        var splitted3 = str3.split("<");

        if (splitted3.length > 1) {
          var splitted4 = splitted3[1].split(">");
          this.CCValue = splitted4[0].toString();
        }
      }
      this.addressdetailsform.controls["From"].setValue(this.Fromvalue);
      this.addressdetailsform.controls["CC"].setValue(this.CCValue);
      if (this.emailser.commonservice.formData.BCCEnable == true) {
        this.ToValue = this.emailser.commonservice.formData.BCCEmail.toString();
        var str1 = this.ToValue;
        if (str1 != undefined && str1 != null) {
          var splitte = str1.split("<");

          if (splitte.length > 1) {
            var splitt = splitte[1].split(">");
            this.ToValue = splitt[0].toString();
          }
        }
        this.addressdetailsform.controls["BCC"].setValue(
          this.ToValue
        );
      }
      else {
        this.addressdetailsform.controls["BCC"].setValue(
          this.Fromvalue.toString()
        );

      }
    }
    else {
      this.spinner.show();
      this.subscription.add(
        this.emailser.GetEmailBCCAndCC().subscribe((data) => {
          if (data != null && data != undefined) {
            this.Fromlist = data["ResponseData"]["FromMailList"];
            this.FromSelectedItem = this.Fromlist[0];
            this.addressdetailsform.value["From"] =
              this.FromSelectedItem.FROMEMAIL.toString();
            // this.addressdetailsform.value["CC"] =
            //   this.FromSelectedItem.FROMEMAIL.toString();
            this.BccList = data["ResponseData"]["BCCMailList"];
            this.BCCSelectedItem = this.BccList[0];
            this.addressdetailsform.value["BCC"] =
              this.BCCSelectedItem.PENEMAILDefaultBCCEmail.toString();
            this.addressdetailsform.controls["BCC"].setValue(
              this.BCCSelectedItem.PENEMAILDefaultBCCEmail.toString()
            );
            debugger;
            if (this.emailser.Atolre == true) {
              this.addressdetailsform.controls["Subject"].setValue(
                this.emailser.emailsubject
              );
              this.addressdetailsform.controls["Body"].setValue(
                this.emailser.emailbody
              );
            } else {
              this.addressdetailsform.controls["Subject"].setValue(
                this.emailser.reportname
              );
            }
            this.Fromvalue = this.FromSelectedItem.FROMEMAIL.toString();
            this.ToValue = this.Fromvalue;

            var str = this.Fromvalue;
            if (str != undefined && str != null) {
              var splitted = str.split("<");

              if (splitted.length > 1) {
                var splitted1 = splitted[1].split(">");
                this.Fromvalue = splitted1[0].toString();
              }
            }

            var str1 = this.ToValue;
            if (str1 != undefined && str1 != null) {
              var splitte = str1.split("<");

              if (splitte.length > 1) {
                var splitt = splitte[1].split(">");
                this.ToValue = splitt[0].toString();
              }
            }
            this.addressdetailsform.controls["From"].setValue(this.Fromvalue);
            if (this.emailser.CCEmail != "") {
              this.addressdetailsform.controls["CC"].setValue(this.emailser.CCEmail);
            }
            this.addressdetailsform.controls["BCC"].setValue(this.ToValue);
            if (this.emailser.ToEmail != "") {
              this.addressdetailsform.controls["To"].setValue(this.emailser.ToEmail);
            }
            this.spinner.hide();
          }
          this.spinner.hide();
        })
      );
    }
    this.onchangeBCC();
    this.onchangeCC();
    this.onchangefrom();



  }
  public SendDocusign() {
    this.DocusignMail = true;
    this.onFormSubmit();

  }
  // public getdefaultvalue()
  // {
  //   this.api.GetDefaultCountry().subscribe(data => {
  //
  //    this.countrySelectedItem.Name="df"
  //   });

  // }
  ONBccCHANGE() {
    this.ccvalid = true;
    var str = this.addressdetailsform.value["BCC"];
    var splitted = str.split(",");
    var splitted1 = str.split(";");
    if (splitted.length > 1) {
      splitted.forEach((obj) => {
        if (obj != "") {
          this.ccvalid = this.validateEmail(obj);
          if (this.ccvalid == false) {
            // this.addressdetailsform.controls["CC"].setErrors({'incorrect': true});
            return;
          }
        }
      });
    } else if (splitted1.length > 1) {
      var str1 = this.addressdetailsform.value["BCC"];
      var splitted = str1.split(";");
      splitted.forEach((obj) => {
        if (obj != "") {
          this.ccvalid = this.validateEmail(obj);
          if (this.ccvalid == false) {
            return;
            //  this.addressdetailsform.controls["CC"].setErrors({'incorrect': true});
          }
        }
      });
    } else {
      var str1 = this.addressdetailsform.value["BCC"];
      if (str1 != "") this.ccvalid = this.validateEmail(str1);
      else this.ccvalid = true;
    }

    if (this.ccvalid == false) {
      this.addressdetailsform.controls["BCC"].setErrors({ incorrect: true });
      //email_invalid
    }
  }

  ONFromCHANGE() {
    this.ccvalid = true;
    var str = this.addressdetailsform.value["From"];
    var splitted = str.split(",");
    var splitted1 = str.split(";");
    if (splitted.length > 1) {
      splitted.forEach((obj) => {
        if (obj != "") {
          this.ccvalid = this.validateEmail(obj);
          if (this.ccvalid == false) {
            // this.addressdetailsform.controls["CC"].setErrors({'incorrect': true});
            return;
          }
        }
      });
    } else if (splitted1.length > 1) {
      var str1 = this.addressdetailsform.value["From"];
      var splitted = str1.split(";");
      splitted.forEach((obj) => {
        if (obj != "") {
          this.ccvalid = this.validateEmail(obj);
          if (this.ccvalid == false) {
            return;
            //  this.addressdetailsform.controls["CC"].setErrors({'incorrect': true});
          }
        }
      });
    } else {
      var str1 = this.addressdetailsform.value["From"];
      if (str1 != "") this.ccvalid = this.validateEmail(str1);
      else this.ccvalid = true;
    }

    if (this.ccvalid == false) {
      this.addressdetailsform.controls["From"].setErrors({ incorrect: true });
      //
    }
  }
  onchangefrom() {
    var string3 = this.Fromvalue;

    var result = string3.includes("@");
    if (!string3.includes("@") && !string3.includes(".")) {
      this.addressdetailsform.controls["From"].setErrors({ incorrect: true });
    }
  }
  onchangeTo() {
    var string3 = this.ToValue;

    var result = string3.includes("@");
    if (!string3.includes("@") && !string3.includes(".")) {
      this.addressdetailsform.controls["To"].setErrors({ incorrect: true });
    }
  }
  onchangeBCC() {
    var string3 = this.ToValue;
    if (string3 != "" && string3 != undefined) {
      var result = string3.includes("@");
      if (!string3.includes("@") && !string3.includes(".")) {
        this.addressdetailsform.controls["BCC"].setErrors({ incorrect: true });
      }
    }
  }
  onchangeCC() {
    var string3 = this.addressdetailsform.controls["CC"].value;
    if (string3 != "" && string3 != undefined) {
      var result = string3.includes("@");
      if (!string3.includes("@") && !string3.includes(".")) {
        this.addressdetailsform.controls["CC"].setErrors({ incorrect: true });
      }
    }
  }
  ONtOcHANGE() {
    debugger;
    this.ccvalid = true;
    var str = this.addressdetailsform.value["To"];
    var splitted = str.split(",");
    var splitted1 = str.split(";");
    if (splitted.length > 1) {
      splitted.forEach((obj) => {
        if (obj != "") {
          this.ccvalid = this.validateEmail(obj);
          if (this.ccvalid == false) {
            // this.addressdetailsform.controls["CC"].setErrors({'incorrect': true});
            return;
          }
        }
      });
    } else if (splitted1.length > 1) {
      var str1 = this.addressdetailsform.value["To"];
      var splitted = str1.split(";");
      splitted.forEach((obj) => {
        if (obj != "") {
          this.ccvalid = this.validateEmail(obj);
          if (this.ccvalid == false) {
            return;
            //  this.addressdetailsform.controls["CC"].setErrors({'incorrect': true});
          }
        }
      });
    } else {
      var str1 = this.addressdetailsform.value["To"];
      if (str1 != "") this.ccvalid = this.validateEmail(str1);
      else this.ccvalid = true;
    }

    if (this.ccvalid == false) {
      this.addressdetailsform.controls["To"].setErrors({ incorrect: true });
      //email_invalid
    }
  }
  onCCChange() {
    this.ccvalid = true;
    var str = this.addressdetailsform.value["CC"];
    var splitted = str.split(",");
    var splitted1 = str.split(";");
    if (splitted.length > 1) {
      splitted.forEach((obj) => {
        if (obj != "") {
          this.ccvalid = this.validateEmail(obj);
          if (this.ccvalid == false) {
            // this.addressdetailsform.controls["CC"].setErrors({'incorrect': true});
            return;
          }
        }
      });
    } else if (splitted1.length > 1) {
      var str1 = this.addressdetailsform.value["CC"];
      var splitted = str1.split(";");
      splitted.forEach((obj) => {
        if (obj != "") {
          this.ccvalid = this.validateEmail(obj);
          if (this.ccvalid == false) {
            return;
            //  this.addressdetailsform.controls["CC"].setErrors({'incorrect': true});
          }
        }
      });
    } else {
      var str1 = this.addressdetailsform.value["CC"];
      if (str1 != "") this.ccvalid = this.validateEmail(str1);
      else this.ccvalid = true;
    }

    if (this.ccvalid == false) {
      this.addressdetailsform.controls["CC"].setErrors({ incorrect: true });
      //email_invalid
    }
  }

  validateEmail(email) {
    const regularExpression =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }
  public TemplateChangeevent(value: any) {
    debugger;
    if (value != undefined) {
      this.TemplateSelectedItem = this.EmailTemplateList.find(x => x.Code == value.Code)
      if (this.TemplateSelectedItem != undefined && this.TemplateSelectedItem.UsrCode) {
        this.addressdetailsform.controls["Body"].setValue(
          this.TemplateSelectedItem.UsrCode.replace("<<WEBLINK>>", this.rptAPI.webLink)
        );
      }
      else {
        this.addressdetailsform.controls["Body"].setValue(
          ''
        );
      }

    }
  }
  public FrommailValueChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.FromSelectedItem = value.FROMEMAIL;
      this.addressdetailsform.value["From"] = this.FromSelectedItem.toString();
      this.Fromvalue = this.FromSelectedItem.toString();
      this.Fromvalue = this.Fromvalue.substring(
        this.Fromvalue.indexOf("<") + 1,
        this.Fromvalue.indexOf(">")
      ); //Email status change by case id-12434
    } else {
      this.FromSelectedItem = this.Fromlist[0];
      this.addressdetailsform.value["From"] =
        this.FromSelectedItem.FROMEMAIL.toString();
      this.Fromvalue = this.FromSelectedItem.FROMEMAIL.toString();
    }
    if (this.emailser.commonservice.formData != undefined && this.emailser.commonservice.formData.BCCEnable == true) {
      this.ToValue = this.emailser.commonservice.formData.BCCEmail.toString();
      var str1 = this.ToValue;
      var splitte = str1.split("<");

      if (splitte.length > 1) {
        var splitt = splitte[1].split(">");
        this.ToValue = splitt[0].toString();
      }
      this.addressdetailsform.controls["BCC"].setValue(
        this.ToValue
      );
    }
    else {
      this.addressdetailsform.controls["BCC"].setValue(
        this.Fromvalue.toString()
      );

    }
    this.onchangefrom();
  }
  public ToMailValueChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.BCCSelectedItem = value.PENEMAILDefaultBCCEmail;
      this.addressdetailsform.value["BCC"] = this.BCCSelectedItem.toString();
      this.ToValue = this.BCCSelectedItem.toString();
    } else {
      this.BCCSelectedItem = this.BccList[0];
      this.addressdetailsform.value["BCC"] =
        this.BCCSelectedItem.PENEMAILDefaultBCCEmail.toString();
      this.ToValue = this.BCCSelectedItem.PENEMAILDefaultBCCEmail.toString();
    }
    this.onchangeTo();
  }
  calculateAtolForFolderemail() {
    debugger;
    this.spinner.show();
    this.reportser.formData = new AtolReportDTO;
    this.reportser.formData.parFolderNo = this.emailser.commonservice.formData.FolderNo;
    this.reportser.UpdateAtolType().subscribe(
      (res) => {

        if (res != null) {
          ///   this.AtolType = res["ResponseData"].AtolType;
          this.TYPEOFINV = res["ResponseData"].TYPEOFINV;
          this.emailsubject = res["ResponseData"].emailsubject;
          this.reportpathname = res["ResponseData"].reportfilename;
          // this.Projno = this.orderNo;//res["ResponseData"].Projno
          if (res["ResponseData"].TYPEOFINV == 1 || res["ResponseData"].TYPEOFINV == 2 || res["ResponseData"].TYPEOFINV == 3 || res["ResponseData"].TYPEOFINV == 4) {
            this.showemailattch = true;
            this.spinner.hide();
            // this.onPrintAtolreportForemail();
          }
          else {
            this.showemailattch = false;
          }
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
        }
      });

  }
  onPrintAtolreportForemail() {
    debugger;
    this.emailser.atolfilepath = '';




    this.spinner.show();
    this.reportser.formData = new AtolReportDTO;
    this.reportser.formData.parFolderNo = this.emailser.commonservice.formData.FolderNo
    this.reportser.formData.emailsubject = this.emailsubject;
    this.reportser.formData.reportfilename = this.reportpathname;
    this.reportser.formData.TYPEOFINV = this.TYPEOFINV;
    this.reportser.AtolReport().subscribe(
      (res) => {

        if (res != null) {
          this.subscription.add(this.translateapi.get(['Headings.Atolcertificate']).subscribe((translations) => {
            this.Atolcertificate = translations['Headings.Atolcertificate']
            this.filename.push(this.Atolcertificate);
          }));
          this.pdfFilePath = this.pdfurl + res["ResponseData"].Reportpath;
          this.filepathatol = res["ResponseData"].Reportpath;
          this.attolreportdeletepath = res["ResponseData"].Reportfolder

          this.emailatolpath = res["ResponseData"].EmailReportpath;
          this.emailser.emailatolpath = this.emailatolpath;
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
        }
      });

  }
  public Addmorefiles() {
    this.morefiles = true;
  }
  ngOnDestroy() {
    this.emailser.emailatolpath = '';
    // console.log("ngOnDestorycust");
    this.subscription.unsubscribe();
  }
}
