import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HotelService } from "../../Services/hotel.service";
import { ShareDataService } from "../../Services/share-data.service";
import { POCreationDetail } from "../../Models/pocreation-detail";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { PocreationService } from "../../Services/pocreation.service";
import { NgxSpinnerService } from "ngx-spinner";
import { DeletePO } from "../../Models/delete-po";
import { CommonserviceService } from "../../Services/commonservice.service";
import { HeotelselectService } from "../../Services/heotelselect.service";
import { HotelmasterService } from "../../Services/hotelmaster.service";
import { LookUpDetailsService } from "../../Services/look-up-details.service";
import { HotelDetailsView } from "src/app/BusinessLayer/hotel-details-view";
import { PanelBarExpandMode } from "@progress/kendo-angular-layout";
import { FolderpriceService } from '../../Services/folderprice.service';
@Component({
  selector: "app-hotel-details",
  templateUrl: "./hotel-details.component.html",
  styleUrls: ["./hotel-details.component.scss"],
})
export class HotelDetailsComponent extends HotelDetailsView implements OnInit {
  //#region constructor
  constructor(
    public lookupApi: LookUpDetailsService,
    public commonservice: CommonserviceService,
    public hotelselectservice: HeotelselectService,
    public hmservice: HotelmasterService,
    public route: ActivatedRoute,
    public router: Router,
    public api: HotelService,
    public apiShared: ShareDataService,
    public spinner: NgxSpinnerService,
    public apiPO: PocreationService,
    public translateapi: TranslateService,
    public toastr: ToastrService,
    public objFolderPrice: FolderpriceService
  ) {
    super(
      lookupApi,
      hotelselectservice,
      hmservice,
      route,
      api,
      apiShared,
      spinner,
      translateapi,
      toastr,
      apiPO
    );
  }
  //#endregion constructor

  // @Input() guid: string;
  // @Input() orderno: number;
  public guid: string = this.route.snapshot.params.guidItinerary; // 'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/';
  public orderno = this.route.snapshot.params.orderNo;
  public Componentname: string;
  public FolderQuoteCreatePO: any;
  public FolderQuoteDeletePO: any;

  //#region LoadEvent
  ngOnInit() {
    //#region orderNo difference
    debugger;
    var orderno = sessionStorage.getItem("orderNo");
    if (orderno != null)
      if (this.orderno !== orderno) {
        return this.router.navigate(["PenAir/Home"]);
      }
    //#endregion orderNo difference
    window.scrollTo(0, 0);

    //#region FormEditableOrNot

    this.folderEditMode = "false";
    this.apiShared.Setapishareddata(this.folderEditMode, "folderEditMode");
    this.folderStatus = this.apiShared.FolderStatus;
    this.lockStatus = this.apiShared.folderReadOnly;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;
      this.invoice = false; //By Caseid-12621
    } else {
      this.formeditable = false;
      this.invoice = true; //By Caseid-12621
      this.createPoInvoice = true; //By Caseid-12621
    }
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;
      this.FolderHideStatus = true;
    }

    //#endregion FormEditableOrNot

    this.getHotelDetails(this.orderno, this.guid);

    //#region Help File

    this.Componentname = "HotelDetails";
    sessionStorage.setItem("Componentname", this.Componentname);

    //#endregion Help File

    var folderQuotes = sessionStorage.getItem('folderQuotes');//12663
    if (folderQuotes) {
      this.FolderQuoteCreatePO = true;
      this.FolderQuoteDeletePO = true;
    }
    else {
      this.FolderQuoteCreatePO = false;
      this.FolderQuoteDeletePO = false;
    }
  }

  //#endregion LoadEvent

  //#region Edit Button Click Event

  onEdit() {
    if (!this.commonservice.package) {
      this.router.navigate([
        "PenAir/Product/HotelDetailsEdit",
        this.orderno,
        this.guid,
      ]); //By Caseid-12752
    }
    else {
      this.router.navigate([
        "PenAir/Product/HotelPackageDetailsEdit",
        this.orderno,
        this.guid,
      ]); //By Caseid-13764
    }
  }

  //#endregion Edit Button Click Event

  //#region Cancel Button Click Event

  onCancel() {
    this.commonservice.isfinalsaved = false;
    sessionStorage.setItem("pagevalue", "0");
    var folderQuotes = sessionStorage.getItem("folderQuotes");
    if (folderQuotes) {
      this.router.navigate(["/PenAir/CRM/Enquiry/View/", this.orderno]);
    } else {
      this.router.navigate(["PenAir/Folder/FolderSummary", this.orderno]);
    }
  }

  //#endregion Cancel Button Click Event

  //#region Panel Change Click Event
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;

  public onChange(event: any): void {
    this.expandMode = parseInt(event.target.value, 10);
  }
  public onPanelChange(event: any): void { console.log('stateChange: ', event); }

  //#endregion Panel Change Click Event

  //#region Create PO button click event

  async onCreatePO() {
    this.spinner.show();
    this.bookingDate =
      this.hotelDetail.ResponseData.BookingDate == null
        ? "01-Jan-1900"
        : this.hotelDetail.ResponseData.BookingDate; //By Caseid-12619
    if (
      Boolean(await this.commonservice.checkPermission1("01.12.01.26")) == true
    ) {
      if (this.bookingDate == "1900-01-01T00:00:00") {
        //By Case id-12619
        this.spinner.hide();
        this.getMessageFromCreatePO();
        return;
      } //By Case id-12619
      else {
        this.POCreation = new POCreationDetail();
        this.POCreation.folderNo = this.orderno;
        this.passName = this.apiShared.PassengerNames;
        this.projNo = this.apiShared.projNo;
        this.POCreation.projNo = this.projNo;
        this.POCreation.passName = this.passName;
        this.POCreation.buyTaxNo = this.hotelDetail.ResponseData.CostingTaxNo.value;
        this.POCreation.postDate = new Date(
          this.hotelDetail.ResponseData.BookingDate
        );
        this.POCreation.POGUIDItinerary =
          this.hotelDetail.ResponseData.GUIDItinerary;
        this.POCreation.Status = this.folderStatus;//11614
        this.clickOnCreatePo();
      }
    } else {
      this.spinner.hide();
      this.showPermissionMessageForm("01.12.01.26");
    }
  }

  //#endregion Create PO button click event

  //#region Delete PO button click event

  async onDeletePO() {
    this.spinner.show();
    if (
      Boolean(await this.commonservice.checkPermission1("01.12.01.27")) == true
    ) {
      this.getHotelWhenClickOnDeletePOButton();
      if (this.hotelDetail != null && this.hotelDetail != undefined) {
        this.deletePO = new DeletePO();
        this.deletePO.Language = this.language;
        this.deletePO.FolderNo = this.orderno;
        this.deletePO.PONO = this.hotelDetail.ResponseData.PONO;
        this.deletePO.POYearId = this.hotelDetail.ResponseData.PONOYEARID;
        this.deletePO.Guid = this.hotelDetail.ResponseData.GUIDItinerary;
      }
      this.clickOnDeletePo();
    } else {
      this.spinner.hide();
      this.showPermissionMessageForm("01.12.01.27");
    }
  }

  //#endregion Delete PO button click event

  //#region Form Permission

  public permissionmessageformopened: any; //Caseid-339
  public PermissionMessageId: any; //Caseid-339

  public PermissionFormClosing(
    status: any //339
  ) {
    if (status == "EmailSendSuccesfully") {
      //339
      this.permissionmessageformopened = false;
    }
  }

  showPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true; //Caseid-339
    this.PermissionMessageId = permissionid; //Caseid-339
  }

  //#endregion Form Permission

  //#region UnSubscribe Subscription
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  //#endregion UnSubscribe Subscription

  onCopy() {
    debugger;
    this.spinner.show();
    this.objFolderPrice.CopyProductDetails(this.orderno, this.hotelDetail.ResponseData.GUIDItinerary, "Hotel").subscribe(x => {
      debugger;
      this.spinner.hide();
      if (x.ResponseData.value == 1) {
        this.router.navigate([
          "/PenAir/Product/HotelDetailsEdit",
          this.orderno,
          x.ResponseData.GUIDItinerary
        ]);
      }

    });
  }

}
