import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of,throwError,BehaviorSubject  } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { ReportschedulerRequestDTO,Reportscheduler,LogviewformDTO } from '../Models/reportscheduler';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})


export class Logviewformservice {
    apiUrl :string;
    notes:any;
   
    constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
      this.apiUrl=env.baseUrl;
     }
   
      ViewLog(LogviewformDTO: any): Observable<any> {
      
        return this.http.post<any>(this.apiUrl + 'ViewLog', LogviewformDTO, httpOptions).pipe(
         
        );
      }
  
    
}
