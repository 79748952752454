import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { CurrencyExchangerate } from "../Models/currency-exchangerate";
import { Observable, of, throwError } from "rxjs";
import { catchError, tap, map } from "rxjs/operators";
import { EnvService } from "../../env.service";
import { CommonserviceService } from "./commonservice.service";
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: "root"
})
export class CurrencyExchangerateService {
  apiUrl: string;
  constructor(
    private http: HttpClient,
    private env: EnvService,
    public commonservice: CommonserviceService
  ) {
    this.apiUrl = env.baseUrl;
  }

  getExchangeRate(
    currencyCode: string,
    Date: string
  ): Observable<CurrencyExchangerate> {
    if (currencyCode == "" || Date == "") return null;
    Date = Date.replace("Sept", "Sep");
    const url =
      `${this.apiUrl}` +
      "GetExchangeRateByCurrencyId" +
      `/${currencyCode}/${Date}`;

    return this.http.get<CurrencyExchangerate>(url).pipe(
      tap(_ =>
        console.log(`fetched CurrencyExchangerate Currency=${currencyCode}`)
      ),
      catchError(
        this.commonservice.handleError<CurrencyExchangerate>(
          `getCruise id=${currencyCode}`
        )
      )
    );
  }
  public GetExchangeRateTolerance(objExRate: any): Observable<any> {

    return this.http.post<any>(this.apiUrl + 'GetExchangeRateTolerance', objExRate, httpOptions).pipe(
      tap((PdqbyHeldNo: any) => console.log(`GetExchangeRateTolerance`)),
      catchError(this.commonservice.handleError<any>('GetExchangeRateTolerance'))
    );
  }

}
