import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { Subscription } from 'rxjs';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { ShareDataService } from '../../Services/share-data.service';
@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit {
  @ViewChild('autocompleteTo') public autocompleteTo: AutoCompleteComponent;
  @ViewChild('autocompletesupplier') public autocompletesupplier: AutoCompleteComponent;

  lookUpList: Array<LookUpDetails["ResponseData"]> = [];
  AutocompleteList: Array<LookUpDetails["ResponseData"]> = [];
  public SupplierSelectedItem: LookUpDetails["ResponseData"];
  public selectArray: Array<any> = [];
  private subscription: Subscription = new Subscription();
  mscodeInclude: boolean = true;
  RadmscodeInclude: any = "1";
  mscodeExclude: boolean = false;
  codeAdded: boolean = false;
  userID: number;
  @Input() drop: boolean = true;
  public supplierSelected: string = "";
  public supplierSelectedCode: string = "";

  lookuptype: string = '';
  lookupname: any = 'Supplier';
  selectedItem: any = [];
  opened: boolean = false;
  GroupByName = '';
  public SupplierName: String = "";
  public Supplier: any = 0;
  @Input() type: string;
  @Input() MSInputItems: any;
  @Input() parentForm: string;
  @Output() SelectedItems: EventEmitter<any> = new EventEmitter();
  @Output() closeBtnFn: EventEmitter<string> = new EventEmitter();
  checked: any = false;
  mcodechecked: any = true;
  openSearch = false;

  public dropdown: boolean = false;
  public searchLookup: boolean = false;
  public Autocomplet: boolean = false;
  public LookUpData = [];


  constructor(private lookupApi: LookUpDetailsService, private apishare: ShareDataService) { }
  ngOnInit(): void {


    debugger;

    this.selectedItem = this.MSInputItems.SelectedItems;

    this.mscodeInclude = this.MSInputItems.isIncluded;
    this.mscodeExclude = !this.MSInputItems.isIncluded;
    // this.RadmscodeInclude = this.mscodeIncludeInput ? "0" : "1";
    this.RadmscodeInclude = this.MSInputItems.isIncluded ? "0" : "1";
    this.userID = +(this.apishare.uid);

    if (this.type == "MCCode") {
      this.GroupByName = 'Labels.markettingCode';

      this.subscription.add(this.lookupApi.GetAllLookup('MarketingCode').subscribe(

        (data) => {
          if (data["ResponseData"] != null && data["ResponseData"] != undefined) {
            this.lookUpList = data["ResponseData"]
          }
        }
      ));
    }
    else if (this.type == "BRID") {
      this.GroupByName = 'Labels.branch';
      this.subscription.add(this.lookupApi.GetAllLookup('BRANCH').subscribe(

        (data) => {
          if (data["ResponseData"] != null && data["ResponseData"] != undefined) {
            this.lookUpList = data["ResponseData"]
          }
        }
      ));
    }
    else if (this.type == "BUSINESSAREA") {
      this.GroupByName = 'Labels.businessArea';
      this.subscription.add(this.lookupApi.GetAllLookup('BUSINESSAREA').subscribe(

        (data) => {
          if (data["ResponseData"] != null && data["ResponseData"] != undefined) {
            this.lookUpList = data["ResponseData"]

          }
        }
      ));
    }
    else if (this.type == "COUNTRY") {

      this.GroupByName = 'Labels.OriginCountry';
      this.subscription.add(this.lookupApi.GetAllLookup('COUNTRY').subscribe(

        (data) => {
          if (data["ResponseData"] != null && data["ResponseData"] != undefined) {
            this.lookUpList = data["ResponseData"]

          }
        }
      ));
    }
    else if (this.type == "COUNTRY") {
      this.GroupByName = 'Labels.OriginCountry';
      this.subscription.add(this.lookupApi.GetAllLookup('COUNTRY').subscribe(

        (data) => {
          if (data["ResponseData"] != null && data["ResponseData"] != undefined) {
            this.lookUpList = data["ResponseData"]
          }

        }
      ));
    }
    else if (this.type == "DestinationCountry") {
      this.GroupByName = 'Labels.DestinationCountry';
      this.subscription.add(this.lookupApi.GetAllLookup('COUNTRY').subscribe(

        (data) => {
          if (data["ResponseData"] != null && data["ResponseData"] != undefined) {
            this.lookUpList = data["ResponseData"]

          }
        }
      ));
    }
    else if (this.type == "AIRWAYS") {


      debugger;
      this.GroupByName = 'Labels.airline';
      this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(

        (data) => {
          if (data["ResponseData"] != null && data["ResponseData"] != undefined) {
            this.lookUpList = data["ResponseData"]
          }

        }
      ));
    }

    if (this.type == "Supplier") {
      this.dropdown = false;
      this.Autocomplet = false;
      this.searchLookup = true;
    } else if (this.type == "ARRIVALPT") {
      this.dropdown = false;
      this.Autocomplet = true;
      this.searchLookup = false;

    }
    else if (this.type == "Customer") {
      this.dropdown = false;
      this.Autocomplet = false;
      this.searchLookup = true;

    } else {
      this.dropdown = true;
      this.Autocomplet = false;
      this.searchLookup = false;

    }

  }

  handleFilter(value) {
    debugger;
    if (this.type == "ARRIVALPT") {

      if (value.length >= 3) {


        this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
          (data) => {
            if (data["ResponseData"] != null && data["ResponseData"] != undefined) {
              this.AutocompleteList = data["ResponseData"];
            }
          }
        ));


      } else {
        this.autocompleteTo.toggle(false);
      }
    }
    else if (this.type == "Customer") {
      if (value.length >= 3) {

        this.subscription.add(this.lookupApi.SearchCustomer(value, this.userID).subscribe(
          (data) => {
            if (data["ResponseData"] != null && data["ResponseData"] != undefined) {
              this.LookUpData = data["ResponseData"];
            }

          }
        ));

      } else {
        this.autocompleteTo.toggle(false);
      }
    }
    else if (this.type == "Supplier") {

      if (value.length >= 3) {

        this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
          (data) => {
            if (data["ResponseData"] != null && data["ResponseData"] != undefined) {
              this.LookUpData = data["ResponseData"];
            }
          }
        ));
      } else {
        this.autocompletesupplier.toggle(false);
      }
    }
  }
  public mcodeIncludeChangeHandler(event: any) {
    debugger;
    if (event == 1) {
      this.mscodeExclude = true;
      this.mscodeInclude = false;
    }
    else {

      this.mscodeInclude = true;
      this.mscodeExclude = false;
    }

  }

  public mcodeExcludeChangeHandler(event) {
    debugger;
    if (event == 0) {
      this.mscodeInclude = true;
    }

  }

  public closeMarketingCodeModel() {

    this.closeBtnFn.emit('close');

  }


  MultiSelectOK() {
    debugger;
    if (this.selectedItem != undefined && this.selectedItem != null && this.selectedItem != "") {
      if (this.selectedItem.length > 0) {


        debugger;

        var ResultCodes = this.selectedItem;

        let data = {
          "isIncluded": this.mscodeInclude,
          "resultSelectedList": ResultCodes,
          "ResultCode": this.supplierSelectedCode,
          "type": this.type
        };
        this.SelectedItems.emit(data);


      } else {
        this.closeMarketingCodeModel();
      }

    } else {
      ResultCodes = "";

      let data = {
        "isIncluded": false,
        "resultSelectedList": ResultCodes,
        "type": this.type
      };
      this.SelectedItems.emit(data);
    }
  }
  getParentMethod(): any {

    return {
      callParentMethod: (value: any) => {
        this.LookUpData.push(value)
        debugger;
        this.openSearch = false;
        this.selectedItem = this.LookUpData;
        //  this.LookUpData = [];
        this.SupplierValueChange(value)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  public close() {

    this.opened = false;
    this.openSearch = false;
  }
  public SupplierValueChange(value: any): void {

    // debugger;
    // console.log("supplierSelected", this.supplierSelected);

    if (this.supplierSelected == "") {
      this.supplierSelected = value.Name;
    }
    else {
      this.supplierSelected = this.supplierSelected + "," + value.Name;
    }

    if (this.supplierSelectedCode == "") {
      this.supplierSelectedCode = "'" + value.Code + "'";

    }
    else {
      this.supplierSelectedCode = this.supplierSelectedCode + "," + "'" + value.Code + "'";
    }
    // this.selectedItem = this.supplierSelected;
  }
  openBox() {
    debugger;
    this.openSearch = true;
    if (this.type == "Supplier") {
      this.lookuptype = 'SUPPLIERS';
      this.lookupname = 'Supplier';

    }
    else if (this.type == "Customer") {

      this.lookuptype = 'CUSTOMER'
      this.lookupname = 'Customer';
    }

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}