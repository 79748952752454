import { CustomerHeldDetails } from './customer-held-details';

export class CustomerReceiptHold {

    HoldInvNo: string;
    Custco : number;
    CURID: string;
    ExchRate:any;
    TotalAmtTC:any;
    Status: string;
    FolderCrDateFrom : Date;
    FolderCrDateTo: Date;
    Reference: string;
    PostingDate: Date;  
    CrDate : Date;
    CrTime : Date;
    CrUID : number;
    Lupdate: Date;
    LupTime: Date;
    LupID : number;  
    CustomerHeldDetails:CustomerHeldDetails[];
}
