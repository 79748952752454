import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductsComponent } from './products/products.component';
import { AuthGuard } from '../_guards/auth.guard';
import { AirsegDetailsComponent } from './airseg-details/airseg-details.component';
import { AirsegDetailsEditComponent } from './airseg-details-edit/airseg-details-edit.component';
import { RailsegDetailsEditComponent } from './railseg-details-edit/railseg-details-edit.component';
import { RailsegDetailsComponent } from './railseg-details/railseg-details.component';
import { AirticketDetailsComponent } from './airticket-details/airticket-details.component';
import { AirticketDetailsEditComponent } from './airticket-details-edit/airticket-details-edit.component';
import { HotelDetailsComponent } from './hotel-details/hotel-details.component';
import { HotelDetailsEditComponent } from './hotel-details-edit/hotel-details-edit.component';
import { TransfersDetailsComponent } from './transfers-details/transfers-details.component';
import { OthersDetailsEditComponent } from './others-details-edit/others-details-edit.component';
import { TransfersDetailEditComponent } from './transfers-detail-edit/transfers-detail-edit.component';
import { OthersDetailsComponent } from './others-details/others-details.component';
import { RailDetailsEditComponent } from './rail-details-edit/rail-details-edit.component';
import { RailDetailsComponent } from './rail-details/rail-details.component';
import { CarDetailsEditComponent } from './car-details-edit/car-details-edit.component';
import { CardetailsComponent } from './cardetails/cardetails.component';
import { TourDetailsEditComponent } from './tour-details-edit/tour-details-edit.component';
import { TourDetailsComponent } from './tour-details/tour-details.component';
import { CruiseDetailsEditComponent } from './cruise-details-edit/cruise-details-edit.component';
import { InsuranceDetailsEditComponent } from './insurance-details-edit/insurance-details-edit.component';
import { CruiseDetailsComponent } from './cruise-details/cruise-details.component';
import { InsuranceDetailsComponent } from './insurance-details/insurance-details.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: "AirsegDetails/:orderNo/:slno",
        component: AirsegDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "AirsegDetailsEdit/:orderNo/:slno",
        component: AirsegDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "AirSegDetailsAdd/:orderNo",
        component: AirsegDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add AirSegment" },
      },
      {
        path: "RailSegDetailsAdd/:orderNo",
        component: RailsegDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "RailsegDetailsEdit/:orderNo/:slno",
        component: RailsegDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "RailsegDetails/:orderNo/:slno",
        component: RailsegDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "AirticketDetails/:orderNo/:guidItinerary",
        component: AirticketDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "AirticketDetailsAdd/:orderNo",
        component: AirticketDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "AirTicketDetailsEdit/:orderNo/:guidItinerary",
        component: AirticketDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },

      {
        path: "HotelDetails/:orderNo/:guidItinerary",
        component: HotelDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "HotelDetailsEdit/:orderNo/:guidItinerary",
        component: HotelDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "HotelDetailsAdd/:orderNo",
        component: HotelDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      //  {
      //   path: "HotelPackageDetailsAdd/:orderNo",
      //   component: HotelPackageDetailsEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Hotel Package Details Edit" },
      // },
      // {
      //   path: "HotelPackageDetailsEdit/:orderNo/:guidItinerary",
      //   component: HotelPackageDetailsEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Add Itinerary" },
      // },
      {
        path: "InsuranceDetails/:orderNo/:guidItinerary",
        component: InsuranceDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "InsuranceDetailsEdit/:orderNo/:guidItinerary",
        component: InsuranceDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "InsuranceDetailsAdd/:orderNo",
        component: InsuranceDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "CruiseDetails/:orderNo/:guidItinerary",
        component: CruiseDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "CruiseDetailsAdd/:orderNo",
        component: CruiseDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },

      {
        path: "CruiseDetailsEdit/:orderNo/:guidItinerary",
        component: CruiseDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "TourDetails/:orderNo/:guidItinerary",
        component: TourDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "TourDetailsEdit/:orderNo/:guidItinerary",
        component: TourDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "TourDetailsAdd/:orderNo",
        component: TourDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "CarDetails/:orderNo/:guidItinerary",
        component: CardetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "CarDetailsEdit/:orderNo/:guidItinerary",
        component: CarDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "AddCarDetails/:orderNo",
        component: CarDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "RailDetails/:orderNo/:guidItinerary",
        component: RailDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "RailDetailsEdit/:orderNo/:guidItinerary",
        component: RailDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "AddRailDetails/:orderNo",
        component: RailDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "OthersDetails/:orderNo/:guidItinerary",
        component: OthersDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },

      {
        path: "OthersDetailsAdd/:orderNo",
        component: OthersDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "OthersDetailsEdit/:orderNo/:guidItinerary",
        component: OthersDetailsEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },

      {
        path: "TransfersDetail/:orderNo/:guidItinerary",
        component: TransfersDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
      {
        path: "TransfersDetailEdit/:orderNo/:guidItinerary",
        component: TransfersDetailEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },

      {
        path: "AddTransferDetails/:orderNo",
        component: TransfersDetailEditComponent,
        canActivate: [AuthGuard],
        data: { title: "Add Itinerary" },
      },
]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductRoutingModule { }
