import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  Router,
  NavigationStart,
  ActivatedRoute,
  NavigationEnd,
  Params,
} from "@angular/router";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { Users } from "src/Models/users";
import { Location } from "@angular/common";
import { ShareDataService } from "./Services/share-data.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { PublicLayoutComponent } from "./public-layout/public-layout.component";
import { CommonserviceService } from "./Services/commonservice.service";
import { LoginService } from "./Services/login.service";
import {
  InsuranceSearchDTO,
  OthersSearchDTO,
  CruiseSearchDTO,
  TransfersSearchDTO,
  RailSearchDTO,
  TourSearchDTO,
  CarSearchDTO,
  HotelSearchDTO,
  FolderSearchDTO,
  FolderSearchCriteriaDTO,
} from "./Models/folder-search-criteria-dto";
import { SearhdtotransferService } from "./Services/searhdtotransfer.service";
import { parseNumber } from "@telerik/kendo-intl";
import { LoginComponent } from "./login/login.component";
import { JwtInterceptor } from "./_helpers/jwt-interceptor";
import { Usercredetails } from "./Models/usercredetails";
import { ProjectmasterComponent } from "./projectmaster/projectmaster.component";
import { MealplanmasterComponent } from "./mealplanmaster/mealplanmaster.component";
import { HotelPropertyTypesComponent } from "./hotel-property-types/hotel-property-types.component";
import { AutoLoginParams } from "./Models/auto-login-params";

export let browserRefresh = false;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  //subscription :Subscription;
  title = "PenAir";
  folderSearchCriteriaDto: FolderSearchCriteriaDTO;
  lang: any = "";
  headerlanguage: any = "";
  langSelected: any = "English";
  folderCurrency: any;
  folderExchangeRate: any;
  PassengerName: any;
  FolderStatus: any;
  PassengerNames: any;
  BRID: any;
  CustomerCode: any;
  projNo: any;
  folderReadOnly: any;
  uid: any;
  orderNo: any;
  uname: any;
  folderEditMode: any;
  homeCurrency: any;
  hcExRate: any;
  bspTaxNo: any;
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  private currentUser: Users;
  public usercred: any;
  urlfull: any;
  autologin: AutoLoginParams = {
    ModulePath: "",
    UserName: "",
    Password: "",
    UserID: "",
    Params: "",
  };
  constructor(
    private activatedRoute: ActivatedRoute,
    // private loginService: LoginComponent,
    private loginService1: LoginService,
    private route: ActivatedRoute,
    private searhTransferDTO: SearhdtotransferService,
    private common: CommonserviceService,
    private apiShared: ShareDataService,
    public publayout: PublicLayoutComponent,
    public translate: TranslateService,
    private router: Router,
    public idle: Idle,
    private keepalive: Keepalive,
    private location: Location,
    private ProjectComponent: ProjectmasterComponent,
    private htlpropertytypecomponent: HotelPropertyTypesComponent,
    private mealplanmastercomponent: MealplanmasterComponent,
    private cdref: ChangeDetectorRef
  ) {

    this.publayout.getDateandCurrencyFormat();
    this.urlfull = location.path();
    // alert(this.urlfull)
    var ordertype = localStorage.getItem('OrderType');
    if(ordertype=== 'Package')
    {
      this.common.package=true
    }
    else{
      this.common.package=false
    }
    if (this.urlfull.includes("AutoWebLogin")) {
      //sessionStorage.setItem('reporturl',  this.urlfull);
      var new_str = this.urlfull.substring(
        this.urlfull.indexOf("AutoWebLogin")
      );
      //var new_str = str.split("Example")[1];

      let x = new_str.split("/");
      this.autologin.ModulePath = x[1];
      this.autologin.UserName = x[2];
      this.autologin.Password = x[3];
      this.autologin.UserID = x[4];
      if (x.length > 5) {
        for (let i = 5; i < x.length; i++) {
          this.autologin.Params = x[i];
          if (i != x.length - 1)
            this.autologin.Params = this.autologin.Params + "/";
        }
      }
      sessionStorage.setItem("autoLogin", JSON.stringify(this.autologin));
    } else {
      sessionStorage.removeItem("autoLogin");
    }

    this.subscription = router.events.subscribe((event) => {
      var rtpurll = sessionStorage.getItem("reporturl");
      if (event instanceof NavigationStart && rtpurll == null) {
        browserRefresh = !router.navigated;
        this.common.browserRefresh = browserRefresh;

        if (browserRefresh) {
          this.clearSearch();
        }
      }
    });
    if (!this.urlfull.includes("AutoWebLogin")) {
      var pathString = this.urlfull;
      if (
        pathString.includes("ConnectionName") &&
        pathString.includes("LoginId")
      ) {
        this.router.navigate([
          "/ConfigurationSettings",
          { Configurl: pathString },
        ]);
        return;
      }
      if (pathString.includes("Reset")) {
        this.router.navigate([pathString]);
        return;
      }
      if (
        pathString.includes("ApproveRequest") ||
        pathString.includes("RejectRequest")
      ) {
        //this.router.navigate([pathString]);
        localStorage.setItem("ApproveRequest", pathString);
        // localStorage.getItem('ApproveRequest')
        this.router.navigate(["/Login"]);
        return;
      }
      if (pathString.includes("WebReportView")) {
        sessionStorage.setItem('isWebLink', 'true');
      }
    } else {
      this.router.navigate(["/Login"]);
      return;
    }



    idle.setIdle(600);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(600);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out!";
      this.timedOut = true;
      console.log(this.idleState);
      this.publayout.onLogoutClick500Errror();
     // this.loginService1.GetLogout();
      this.router.navigate(["/Login"]);
     
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      console.log(this.idleState);
      // this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = "You will time out in " + countdown + " seconds!";
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
    translate.addLangs(["en", "fr", "zh", "es","th"]);
    translate.setDefaultLang("en");
    const browserLang = translate.getBrowserLang();
    this.lang = new BehaviorSubject(sessionStorage.getItem("LanguageSelected"));
    if (this.lang._value !== null && this.lang === "Chinese") {
      translate.use(browserLang.match(/en|zh/) ? browserLang : "zh");
    } else {
      translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
      translate.use(browserLang.match(/en|fr/) ? browserLang : "fr");
    }

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    var permission = sessionStorage.getItem("permissiondetailslist");
    if (!pathString.includes("WebReportView"))
      if (this.currentUser == null || this.currentUser.ResponseData == null) {
        this.router.navigate(["/Login"]);
        return;
      }
    if (this.currentUser && permission !== null) {
      this.getlanguageAndsessiondetails();
      return;
    } else if (this.currentUser && permission === null) {
      this.getlanguageAndsessiondetails();
      //commented on 03/06/2021
      // if(sessionStorage.getItem('reporturl')!=null)
      //  {
      //   var reporturl= sessionStorage.getItem('reporturl')

      //     this.router.navigate([reporturl,'0','0']);
      //   // this.router.navigateByUrl(reporturl);
      //  }
      //  else if(this.urlfull!=null && this.urlfull!='' && !pathString.includes('Login'))
      //  this.router.navigate([this.urlfull]);
      //  else{
      //    this.router.navigate(['/PenAir/Folder/Index']);
      //  }
    } else if (history.length == 1) {
      this.getlanguageAndsessiondetails();
      this.router.navigate(["/PenAir/Home"]);
    } else {
      if (!pathString.includes("WebReportView"))
        this.router.navigate(["/Login"]);
    }
  }

  selectLanguage(i: number) {
    this.translate.addLangs(["en", "fr", "zh", "es","th"]);
    this.translate.setDefaultLang("en");
    const browserLang = this.translate.getBrowserLang();
    this.lang = new BehaviorSubject(sessionStorage.getItem("LanguageSelected"));

    this.translate.use("en");
    localStorage.setItem("language", "en");
    var reporturl = sessionStorage.getItem("menuhighlight");
  }
  GetParam(name) {
    const results = new RegExp("[\\?&]" + name + "=([^&#]*)").exec(
      window.location.href
    );
    if (!results) {
      return 0;
    }
    return results[1] || 0;
  }
  GetParam1(name, url) {
    const results = new RegExp("[\\?&]" + name + "=([^&#]*)").exec(
      window.location.href
    );
    if (!results) {
      return 0;
    }
    return results[1] || 0;
  }
  clearSearch() {
    this.folderSearchCriteriaDto = new FolderSearchCriteriaDTO();
    if (
      this.folderSearchCriteriaDto.FolderSearchDTO != null ||
      this.folderSearchCriteriaDto.FolderSearchDTO == undefined
    ) {
      // alert(this.folderSearchCriteriaDto.FolderSearchDTO)
      this.folderSearchCriteriaDto.FolderSearchDTO = new FolderSearchDTO();
    }
    if (
      this.folderSearchCriteriaDto.HotelSearchDTO != null ||
      this.folderSearchCriteriaDto.HotelSearchDTO == undefined
    ) {
      // alert(this.folderSearchCriteriaDto.HotelSearchDTO)
      this.folderSearchCriteriaDto.HotelSearchDTO = new HotelSearchDTO();
    }
    if (
      this.folderSearchCriteriaDto.CarSearchDTO != null ||
      this.folderSearchCriteriaDto.CarSearchDTO == undefined
    ) {
      // alert(this.folderSearchCriteriaDto.HotelSearchDTO)
      this.folderSearchCriteriaDto.CarSearchDTO = new CarSearchDTO();
    }
    if (
      this.folderSearchCriteriaDto.TourSearchDTO != null ||
      this.folderSearchCriteriaDto.TourSearchDTO == undefined
    ) {
      // alert(this.folderSearchCriteriaDto.TourSearchDTO)
      this.folderSearchCriteriaDto.TourSearchDTO = new TourSearchDTO();
    }
    if (
      this.folderSearchCriteriaDto.RailSearchDTO != null ||
      this.folderSearchCriteriaDto.RailSearchDTO == undefined
    ) {
      // alert(this.folderSearchCriteriaDto.RailSearchDTO)
      this.folderSearchCriteriaDto.RailSearchDTO = new RailSearchDTO();
    }
    if (
      this.folderSearchCriteriaDto.TransfersSearchDTO != null ||
      this.folderSearchCriteriaDto.TransfersSearchDTO == undefined
    ) {
      // alert(this.folderSearchCriteriaDto.TransfersSearchDTO)
      this.folderSearchCriteriaDto.TransfersSearchDTO =
        new TransfersSearchDTO();
    }
    if (
      this.folderSearchCriteriaDto.CruiseSearchDTO != null ||
      this.folderSearchCriteriaDto.CruiseSearchDTO == undefined
    ) {
      // alert(this.folderSearchCriteriaDto.CruiseSearchDTO)
      this.folderSearchCriteriaDto.CruiseSearchDTO = new CruiseSearchDTO();
    }
    if (
      this.folderSearchCriteriaDto.OthersSearchDTO != null ||
      this.folderSearchCriteriaDto.OthersSearchDTO == undefined
    ) {
      // alert(this.folderSearchCriteriaDto.OthersSearchDTO)
      this.folderSearchCriteriaDto.OthersSearchDTO = new OthersSearchDTO();
    }
    if (
      this.folderSearchCriteriaDto.InsuranceSearchDTO != null ||
      this.folderSearchCriteriaDto.InsuranceSearchDTO == undefined
    ) {
      // alert(this.folderSearchCriteriaDto.InsuranceSearchDTO)
      this.folderSearchCriteriaDto.InsuranceSearchDTO =
        new InsuranceSearchDTO();
    }
    this.folderSearchCriteriaDto = new FolderSearchCriteriaDTO();
    this.searhTransferDTO.FolderSearch(this.folderSearchCriteriaDto);
  }
  getlanguageAndsessiondetails() {
    this.lang = new BehaviorSubject(sessionStorage.getItem("LanguageSelected"));
    if (this.lang._value === "null" || this.lang._value === null) {
      this.apiShared.LanguageSelected = this.langSelected;
      this.publayout.langSelected = this.langSelected;
      this.publayout.translateMenutext(this.langSelected);
    } else {
      this.apiShared.LanguageSelected = this.lang._value;
      this.publayout.langSelected = this.lang._value;
      // this.publayout. translateMenutext(this.langSelected);
    }
    if (this.publayout.langSelected == "English") {
      this.translate.use("en");
      //   this.publayout. translateMenutext(this.publayout.langSelected);
    }
    if (this.publayout.langSelected == "Chinese") {
      this.translate.use("zh");
      // this.publayout. translateMenutext(this.publayout.langSelected);
    }
    if (this.publayout.langSelected == "French") {
      this.translate.use("fr");
      //  this.publayout. translateMenutext(this.publayout.langSelected);
    }
    if (this.publayout.langSelected == "Spanish") {
      this.translate.use("es");
      //  this.publayout. translateMenutext(this.publayout.langSelected);
    }
    if (this.publayout.langSelected == "Thai") {
      this.translate.use("th");
      //  this.publayout. translateMenutext(this.publayout.langSelected);
    }
    //  this.getCurrentLanguage(); headerlanguage

    this.publayout.headerlanguage = sessionStorage.getItem("headerlanguage");
    this.publayout.mainmenutransilate =
      sessionStorage.getItem("mainmenutransilate");

    this.FolderStatus = new BehaviorSubject(
      sessionStorage.getItem("FolderStatus")
    );
    if (
      this.FolderStatus._value === "null" ||
      this.FolderStatus._value === null
    ) {
      this.FolderStatus = "";
      this.apiShared.FolderStatus = this.FolderStatus;
    } else {
      this.apiShared.FolderStatus = this.FolderStatus._value;
    }

    this.bspTaxNo = new BehaviorSubject(sessionStorage.getItem("bspTaxNo"));
    if (this.bspTaxNo._value === "null" || this.bspTaxNo._value === null) {
      this.bspTaxNo = "";
      this.apiShared.bspTaxNo = this.bspTaxNo;
    } else {
      this.apiShared.bspTaxNo = this.bspTaxNo._value;
    }

    this.hcExRate = new BehaviorSubject(sessionStorage.getItem("hcExRate"));
    if (this.hcExRate._value === "null" || this.hcExRate._value === null) {
      this.hcExRate = "";
      this.apiShared.hcExRate = this.hcExRate;
    } else {
      this.apiShared.hcExRate = this.hcExRate._value;
    }

    this.homeCurrency = new BehaviorSubject(
      sessionStorage.getItem("homeCurrency")
    );
    if (
      this.homeCurrency._value === "null" ||
      this.homeCurrency._value === null
    ) {
      this.homeCurrency = "";
      this.apiShared.homeCurrency = this.homeCurrency;
    } else {
      this.apiShared.homeCurrency = this.homeCurrency._value;
    }

    this.folderEditMode = new BehaviorSubject(
      sessionStorage.getItem("folderEditMode")
    );
    if (
      this.folderEditMode._value === "null" ||
      this.folderEditMode._value === null
    ) {
      this.folderEditMode = "";
      this.apiShared.folderEditMode = this.folderEditMode;
    } else {
      this.apiShared.folderEditMode = this.folderEditMode._value;
    }

    this.uname = new BehaviorSubject(sessionStorage.getItem("uname"));
    if (this.uname._value === "null" || this.uname._value === null) {
      this.uname = this.currentUser.ResponseData.UNAME;
      this.apiShared.uname = this.uname;
    } else {
      this.apiShared.uname = this.uname._value;
    }

    this.uid = new BehaviorSubject(sessionStorage.getItem("uid"));
    if (this.uid._value === "null" || this.uid._value === null) {
      this.uid = this.currentUser.ResponseData.UID;
      this.apiShared.uid = this.uid;
    } else {
      this.apiShared.uid = this.uid._value;
    }

    this.folderReadOnly = new BehaviorSubject(
      sessionStorage.getItem("folderReadOnly")
    );
    if (
      this.folderReadOnly._value === "null" ||
      this.folderReadOnly._value === null
    ) {
      this.folderReadOnly = "";
      this.apiShared.folderReadOnly = this.folderReadOnly;
    } else {
      this.apiShared.folderReadOnly = this.folderReadOnly._value;
    }

    this.projNo = new BehaviorSubject(sessionStorage.getItem("projNo"));
    if (this.projNo._value === "null" || this.projNo._value === null) {
      this.projNo = "";
      this.apiShared.projNo = this.projNo;
    } else {
      this.apiShared.projNo = this.projNo._value;
    }

    this.CustomerCode = new BehaviorSubject(
      sessionStorage.getItem("CustomerCode")
    );
    if (
      this.CustomerCode._value === "null" ||
      this.CustomerCode._value === null
    ) {
      this.CustomerCode = "";
      this.apiShared.CustomerCode = this.CustomerCode;
    } else {
      this.apiShared.CustomerCode = this.CustomerCode._value;
    }

    this.BRID = new BehaviorSubject(sessionStorage.getItem("BRID"));
    if (this.BRID._value === "null" || this.BRID._value === null) {
      this.BRID = "";
      this.apiShared.BRID = this.BRID;
    } else {
      this.BRID = this.BRID._value;
    }

    this.PassengerNames = new BehaviorSubject(
      sessionStorage.getItem("PassengerNames")
    );
    if (
      this.PassengerNames._value === "null" ||
      this.PassengerNames._value === null
    ) {
      this.PassengerNames = this.currentUser.ResponseData.UNAME;
      this.apiShared.PassengerNames = this.PassengerNames;
      this.publayout.headerPassenger = this.PassengerNames;
    } else {
      this.apiShared.PassengerNames = this.PassengerNames._value;
      this.publayout.headerPassenger = this.PassengerNames._value;
    }

    this.PassengerName = new BehaviorSubject(
      sessionStorage.getItem("PassengerName")
    );
    if (
      this.PassengerName._value === "null" ||
      this.PassengerName._value === null
    ) {
      this.PassengerName = "";
      this.apiShared.PassengerName = this.PassengerName;
    } else {
      this.apiShared.PassengerName = this.PassengerName._value;
    }

    this.orderNo = new BehaviorSubject(sessionStorage.getItem("orderNo"));
    if (this.orderNo._value === "null" || this.orderNo._value === null) {
      this.orderNo = "";
      //this.apiShared.orderNo=this.orderNo;
      this.apiShared.Setapishareddata(this.orderNo, "orderNo");
    } else {
      // this.apiShared.orderNo= this.orderNo._value;

      this.apiShared.orderNo = this.orderNo._value;
    }
    this.folderExchangeRate = new BehaviorSubject(
      sessionStorage.getItem("folderExchangeRate")
    );
    if (
      this.folderExchangeRate._value === "null" ||
      this.folderExchangeRate._value === null
    ) {
      this.folderExchangeRate = "";
      this.apiShared.folderExchangeRate = this.folderExchangeRate;
      this.apiShared.Setapishareddata(
        this.folderExchangeRate,
        "folderExchangeRate"
      );
    } else {
      this.apiShared.folderExchangeRate = this.folderExchangeRate._value;
      this.apiShared.Setapishareddata(
        this.folderExchangeRate._value,
        "folderExchangeRate"
      );
    }

    this.folderCurrency = new BehaviorSubject(
      sessionStorage.getItem("folderCurrency")
    );
    if (
      this.folderCurrency._value === "null" ||
      this.folderCurrency._value === null
    ) {
      this.folderCurrency = "";
      this.apiShared.folderCurrency = this.folderCurrency;
      this.apiShared.Setapishareddata(this.folderCurrency, "folderCurrency");
    } else {
      this.apiShared.folderCurrency = this.folderCurrency._value;
      this.apiShared.Setapishareddata(
        this.folderCurrency._value,
        "folderCurrency"
      );
    }
  }
  getCurrentLanguage() {
    this.publayout.Alerts = sessionStorage.getItem("Alerts");
    this.publayout.Search = sessionStorage.getItem("Search");
    this.publayout.Inbox = sessionStorage.getItem("Inbox");

    this.publayout.Closed = sessionStorage.getItem("Closed");

    this.publayout.Shared = sessionStorage.getItem("Shared");

    this.publayout.Reports = sessionStorage.getItem("Reports");

    this.publayout.AirTktrptPNR = sessionStorage.getItem("AirTktrptPNR");

    this.publayout.SalesListing = sessionStorage.getItem("SalesListing");

    this.publayout.CustomerReceiptsAndPaymentRpt = sessionStorage.getItem(
      "CustomerReceiptsAndPaymentRpt"
    );

    this.publayout.CustomerStatement =
      sessionStorage.getItem("CustomerStatement");

    this.publayout.BankStatement = sessionStorage.getItem("BankStatement");

    this.publayout.TrialBalanceReport =
      sessionStorage.getItem("TrialBalanceReport");
    //this.publayout.VoucherLiabilityReport=sessionStorage.getItem('VoucherLiabilityReport')
    this.publayout.Supplier = sessionStorage.getItem("Supplier");

    this.publayout.Payment = sessionStorage.getItem("Payment");

    this.publayout.Utilities = sessionStorage.getItem("Utilities");

    this.publayout.UnlockDocument = sessionStorage.getItem("UnlockDocument");

    this.publayout.Reconcilation = sessionStorage.getItem("Reconcilation");

    this.publayout.BankReconcilation =
      sessionStorage.getItem("BankReconcilation");

    this.publayout.Help = sessionStorage.getItem("Help");

    this.publayout.Guidlines = sessionStorage.getItem("Guidlines");

    this.publayout.BeginnerNotes = sessionStorage.getItem("BeginnerNotes");

    this.publayout.Shortcuts = sessionStorage.getItem("Shortcuts");
  }

  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

  ngOnInit() { }
  // ngOnInit1() {
  //     this.common.browserRefresh=browserRefresh;
  //     var permission=sessionStorage.getItem('permissiondetailslist');
  //     this.currentUser=JSON.parse(localStorage.getItem('currentUser'));
  //      if(permission===null && this.common.browserRefresh)
  //     {

  //      // this.loginService1.GetPermission(this.currentUser.ResponseData.UID);
  //      return this.router.navigate(['PenAir/Folder/Index']);
  //     }
  //     else  if (history.length == 1) {  // Um, needs to be 0 for IE, 1 for Firefox
  //       // This is a new window or a new tab.
  //       return  this.router.navigate(['PenAir/Folder/Index']);
  //     }
  // }
  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }
}
