import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ShareDataService } from "../../Services/share-data.service";

import { LookUpDetails } from "../../Services/look-up-details";
import { Router, ActivatedRoute } from "@angular/router";
import { LookUpDetailsService } from "../../Services/look-up-details.service";
import { PanelBarExpandMode } from "@progress/kendo-angular-layout";
import { CurrencyExchangerateService } from "../../Services/currency-exchangerate.service";
import { CurrencyExchangerate } from "../../Models/currency-exchangerate";
import { Subscription, Subject } from "rxjs";
import { CommonserviceService } from "../../Services/commonservice.service";
import { CashPaymentDTO } from "../../Models/cash-payment-dto.model";
import { CashPaymentService } from "../../Services/cash-payment.service";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-payment-terms-edit",
  templateUrl: "./payment-terms-edit.component.html",
  styleUrls: ["./payment-terms-edit.component.scss"]
})
export class PaymentTermsEditComponent implements OnInit, OnChanges {
  private subscription: Subscription = new Subscription();
  currencyExrate: CurrencyExchangerate;
  @Input() parentForm: FormGroup;
  @Input() resetFormSubject: Subject<boolean> = new Subject<boolean>();
  @Input() public pocreated;

  visible = false;
  submitted = false;
  SellComm = 0;

  PmntTrm: Array<LookUpDetails["ResponseData"]> = [];
  PmntMethods: Array<LookUpDetails["ResponseData"]> = [];
  cardType: any;
  LookupRes: Array<LookUpDetails["ResponseData"]> = [];
  PDQ: Array<LookUpDetails["ResponseData"]> = [];
  public PDQselectedItem: LookUpDetails["ResponseData"];
  Currency: Array<LookUpDetails["ResponseData"]> = [];
  public CurrencyselectedItem: LookUpDetails["ResponseData"];
  lockStatus: any;
  folderStatus: any;
  formeditable: any;
  public PmntTrmselectedItem: LookUpDetails["ResponseData"];

  public PmntMethodsSelectedItem: LookUpDetails["ResponseData"];

  public PDTYPENOSelectedItem: LookUpDetails["ResponseData"];

  public paymode: string;
  public Componentname: string;
  constructor(public apiShared: ShareDataService,
    private lookupApi: LookUpDetailsService,
    private apiExRate: CurrencyExchangerateService,
    private route: ActivatedRoute,
    public commonser: CommonserviceService, private cashservice: CashPaymentService,
    private spinner: NgxSpinnerService
  ) { }
  ngOnInit() {
    debugger;
    //case id : 13777 
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    } else {
      this.formeditable = false;

    }
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;

    }
    // case id : 13777
    this.subscription.add(
      this.lookupApi.GetAllLookup("PAYTERM").subscribe(data => {
        if (data != null && data != undefined) {
          this.PmntTrm = data["ResponseData"];
          if (this.parentForm.controls.PAYMENTID.value != "") {
            this.PmntTrmselectedItem = this.PmntTrm.find(
              item => item.Code == this.parentForm.controls.PAYMENTID.value
            );

            // console.log("test:-"+this.parentForm.controls.PAYMENTID.value+JSON.stringify(this.PmntTrmselectedItem , null, 4));
          }
        }
      })
    );

    this.subscription.add(
      this.lookupApi.GetAllLookup("PAYMENT").subscribe(data => {
        if (data != null && data != undefined) {
          this.PmntMethods = data["ResponseData"];
          if (this.parentForm.controls.PMTID.value != "") {
            this.PmntMethodsSelectedItem = this.PmntMethods.find(
              item => item.Code == this.parentForm.controls.PMTID.value
            );
          }
        }
      })
    );
    // this.lookupApi.GetAllLookup('CREDITCARD').subscribe(
    //   (data) => {

    //      this.cardType= data["ResponseData"]
    //      if(this.parentForm.controls.PDTYPENO.value!="")
    //      {

    //      this.PDTYPENOSelectedItem =this.cardType.find(item => item.Code ==this.parentForm.controls.PDTYPENO.value);

    //     }
    //   }
    //   );

    this.subscription.add(
      this.lookupApi.GetAllLookup("CURRENCY").subscribe(data => {
        if (data != null && data != undefined) {
          this.Currency = data["ResponseData"];
          if (this.parentForm.controls.PDFCURID.value != "") {
            this.CurrencyselectedItem = this.Currency.find(
              item => item.Code == this.parentForm.controls.PDFCURID.value
            );
          }
        }
      })
    );

    this.subscription.add(
      this.lookupApi.GetAllLookup("COMPANYCARD").subscribe(data => {
        if (data != null && data != undefined) {
          this.PDQ = data["ResponseData"];
          if (this.parentForm.controls.PDNO.value != "") {
            this.PDQselectedItem = this.PDQ.find(
              item => item.Code == this.parentForm.controls.PDNO.value
            );
            this.subscription.add(
              this.lookupApi
                .GetPDQCard(this.parentForm.controls.PDNO.value)
                .subscribe(data => {
                  if (data != null && data != undefined) {
                    this.cardType = data["ResponseData"];
                    if (this.parentForm.controls.PDTYPENO.value != "") {
                      this.PDTYPENOSelectedItem = this.cardType.find(
                        item =>
                          item.CARDID == this.parentForm.controls.PDTYPENO.value
                      );
                    }
                  } // else {

                  // }
                })
            );
          }
        }
      })
    );
    this.loadPaymethod();

    this.resetFormSubject.subscribe(response => {
      if (response) {
        this.getValueChange();
      }
    });
    debugger;
    if (
      this.parentForm.value["ITYPE"] === "Ticket" ||
      this.parentForm.value["ITYPE"] === "Others" ||
      this.parentForm.value["ITYPE"] === "Hotel"
    ) {
      this.visible = true;
    } else {
      this.visible = false;
    }
    if (this.parentForm.value["ITYPE"] === "Hotel") {
      this.SellComm = this.parentForm.get("SELLCOMM").value;
    } else {
      this.SellComm = this.parentForm.get("HCCommAmt").value;
    }
    this.getValueChange();
    //this.onChanges();
    this.Componentname = "PaymentTerms";
    //this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654
    sessionStorage.setItem("Componentname", this.Componentname);
  }
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;
  public onChange(event: any): void {
    this.expandMode = parseInt(event.target.value, 10);
  }
  getPayment() {
    return this.PmntTrm;
  }
  isShown: boolean = false; // hidden by default
  isShownCmp: boolean = false;
  isShownCust: boolean = false;

  public toggleShow() {
    this.isShown = true;
    this.isShownCmp = false;
    this.isShownCust = false;

    if (this.parentForm.controls.ITYPE.value != "Ticket") {
      this.paymode = "Credit";
      this.parentForm.controls.PAYMODE.setValue(this.paymode);
      //alert(this.parentForm.controls.PAYMODE.value)
    } else {
      this.paymode = "N";
      this.parentForm.controls.COMONLY.setValue(this.paymode);
      //alert(this.parentForm.controls.PAYMODE.value)
    }
  }
  public toggleShowCmp() {
    debugger;
    this.isShownCmp = true;
    this.isShown = false;
    this.isShownCust = false;
    if (this.parentForm.controls.ITYPE.value != "Ticket") {
      this.paymode = "Company Card";
      this.parentForm.controls.PAYMODE.setValue(this.paymode);
      //alert(this.parentForm.controls.PAYMODE.value)
    } else {
      this.paymode = "W";
      this.parentForm.controls.COMONLY.setValue(this.paymode);
    }
  }
  public toggleShowCust() {
    this.isShownCust = true;
    this.isShownCmp = false;
    this.isShown = false;
    if (this.parentForm.controls.ITYPE.value != "Ticket") {
      this.paymode = "Customer Card";

      this.parentForm.controls.PAYMODE.setValue(this.paymode);
      //alert(this.parentForm.controls.PAYMODE.value)
    } else {
      this.paymode = "C";
      this.parentForm.controls.COMONLY.setValue(this.paymode);
    }
  }
  get f() {
    return this.parentForm.controls;
  }
  LookupAll(provider: string, lookupObj: Array<LookUpDetails["ResponseData"]>) {
    this.subscription.add(
      this.lookupApi.GetAllLookup(provider).subscribe(data => {
        //  console.log(JSON.stringify(data, null, 4));
        if (data != null && data != undefined) {
          lookupObj = data["ResponseData"];
        }
        // this.PmntTrm=data["ResponseData"];
      })
    );
  }

  get fp() {
    return this.parentForm.controls;
  }
  // public paymodeCredit  = false;
  // public paymodeCust = false;
  // public paymodeCmp=true;
  public rdCheckedCr = false;
  public rdCheckedCust = false;
  public rdCheckedCmp = false;
  public loadPaymethod() {
    //alert(this.parentForm.controls.PAYMODE.value)
    if (this.parentForm.controls.ITYPE.value != "Ticket") {
      if (this.parentForm.get("PAYMODE") != null) {
        if (this.parentForm.get("PAYMODE").value == "Company Card") {
          this.rdCheckedCmp = true;
          this.toggleShowCmp();
        } else if (this.parentForm.get("PAYMODE").value == "Customer Card") {
          this.rdCheckedCust = true;
          this.toggleShowCust();
        } else {
          this.rdCheckedCr = true;
          this.toggleShow();
        }
      } else {
        this.rdCheckedCr = true;
        this.toggleShow();
      }
    } else {
      if (this.parentForm.get("COMONLY") != null) {
        if (this.parentForm.get("COMONLY").value == "W") {
          this.rdCheckedCmp = true;
          this.toggleShowCmp();
        } else if (this.parentForm.get("COMONLY").value == "C") {
          this.rdCheckedCust = true;
          this.toggleShowCust();
        } else {
          this.rdCheckedCr = true;
          this.toggleShow();
        }
      } else {
        this.rdCheckedCr = true;
        this.toggleShow();
      }
    }
  }
  getBalanceDuedateByPaymentTerm(type, term) {
    debugger;
    if (this.apiShared.BALDUETO == 0 || this.apiShared.BALDUETO == null) {
      if (this.parentForm.get('ITYPE').value == 'Rail' && this.parentForm.get('TICKETDATE').value != null) {
        if (type == "Days") {
          var date = new Date(this.parentForm.get('TICKETDATE').value); // Now 
          date.setDate(date.getDate() + (+term));
          this.parentForm.controls.BalanceDueDate.setValue(date);
        }
        else if (type == "Month") {
          var date = new Date(this.parentForm.get('TICKETDATE').value); // Now 
          var paytermmoth = +term * 30;
          date.setDate(date.getDate() + paytermmoth);
          this.parentForm.controls.BalanceDueDate.setValue(date);
        }
        else {
          this.parentForm.controls.BalanceDueDate.setValue(null);
        }
        return
      } else if (this.parentForm.get('ITYPE').value == 'Ticket' && this.parentForm.get('TICKDATE').value != null) {
        if (type == "Days") {
          var date = new Date(this.parentForm.get('TICKDATE').value); // Now 
          date.setDate(date.getDate() + (+term));
          this.parentForm.controls.BalanceDueDate.setValue(date);
        }
        else if (type == "Month") {
          var date = new Date(this.parentForm.get('TICKDATE').value); // Now 
          var paytermmoth = +term * 30;
          date.setDate(date.getDate() + paytermmoth);
          this.parentForm.controls.BalanceDueDate.setValue(date);
        }
        else {
          this.parentForm.controls.BalanceDueDate.setValue(null);
        }
        return
      }
      else if (this.parentForm.get('ITYPE').value != 'Rail' && this.parentForm.get('ITYPE').value != 'Ticket' && this.parentForm.get('BookingDate').value != null) {
        if (type == "Days") {
          var date = new Date(this.parentForm.get('BookingDate').value); // Now TICKDATE
          date.setDate(date.getDate() + (+term));
          this.parentForm.controls.BalanceDueDate.setValue(date);
        }
        else if (type == "Month") {
          var date = new Date(this.parentForm.get('BookingDate').value); // Now 
          var paytermmoth = +term * 30;
          date.setDate(date.getDate() + paytermmoth);
          this.parentForm.controls.BalanceDueDate.setValue(date);
        }
        else {
          this.parentForm.controls.BalanceDueDate.setValue(null);
        }
      }
    }
    else {
      this.getBalanceDuedate();
    }

  }
  getBalanceDuedate() {
    if (this.apiShared.BALDUETO == 0 || this.apiShared.BALDUETO == null) {
      this.getValueChange();
    }
    else {
      if (this.parentForm.get('ITYPE').value == 'Ticket' || this.parentForm.get('ITYPE').value == 'Others') {
        var date = new Date(this.apiShared.FolderDate); // Now
        date.setDate(date.getDate() + this.apiShared.BALDUETO);
        this.parentForm.controls.BalanceDueDate.setValue(date);
      }
      else if (this.parentForm.get('ITYPE').value == 'Hotel' && this.parentForm.get('DATEIN').value != null) {
        var date = new Date(this.parentForm.get('DATEIN').value); // Now
        date.setDate(date.getDate() + this.apiShared.BALDUETO);
        this.parentForm.controls.BalanceDueDate.setValue(date);
      }
      else if ((this.parentForm.get('ITYPE').value == 'Car' || this.parentForm.get('ITYPE').value == 'Tour') && this.parentForm.get('DATE1').value != null) {
        var date = new Date(this.parentForm.get('DATE1').value); // Now
        date.setDate(date.getDate() + this.apiShared.BALDUETO);
        this.parentForm.controls.BalanceDueDate.setValue(date);
      }
      else if (this.parentForm.get('ITYPE').value == 'Rail' && this.parentForm.get('TICKETDATE').value != null) {
        var date = new Date(this.parentForm.get('TICKETDATE').value); // Now
        date.setDate(date.getDate() + this.apiShared.BALDUETO);
        this.parentForm.controls.BalanceDueDate.setValue(date);
      }
      else if (this.parentForm.get('ITYPE').value == 'Rail' && this.parentForm.get('DATEF').value != null) {
        var date = new Date(this.parentForm.get('DATEF').value); // Now
        date.setDate(date.getDate() + this.apiShared.BALDUETO);
        this.parentForm.controls.BalanceDueDate.setValue(date);
      }
      else if (this.parentForm.get('ITYPE').value == 'Transfers' || this.parentForm.get('ITYPE').value == 'Cruise' && this.parentForm.get('DEPDATE').value != null) {
        var date = new Date(this.parentForm.get('DEPDATE').value); // Now
        date.setDate(date.getDate() + this.apiShared.BALDUETO);
        this.parentForm.controls.BalanceDueDate.setValue(date);
      }
      else {
        this.parentForm.controls.BalanceDueDate.setValue(null);
      }

    }
    // FolderDate
  }
  public CurrencyChange(value: any): void {
    // console.log("selectionChange", value);

    this.parentForm.controls.PDFCURID.setValue(value.Code);
  }

  public getCurrencyExRate() {
    if (this.parentForm.controls.ITYPE.value != "Ticket") {
      this.getExchangeRate(
        this.parentForm.controls.PDFCURID.value,
        this.parentForm.controls.BookingDate.value
      );
    } else {
      this.getExchangeRate(
        this.parentForm.controls.PDFCURID.value,
        this.parentForm.controls.TICKDATE.value
      );
    }
    ///this.folderdata.changeData(this.parentForm.controls.CURCODE.value, (this.parentForm.controls.CURExRate.value))
  }

  public getValueChange() {
    this.subscription.add(
      this.lookupApi.GetAllLookup("PAYTERM").subscribe(data => {
        this.PmntTrm = data["ResponseData"];
        if (this.parentForm.controls.PAYMENTID.value != "") {
          this.PmntTrmselectedItem = this.PmntTrm.find(
            item => item.Code == this.parentForm.controls.PAYMENTID.value
          );
          if (this.PmntTrmselectedItem != undefined) {

            var type = this.PmntTrmselectedItem.ActualName;
            var term = this.PmntTrmselectedItem.PAYTERM;
            this.getBalanceDuedateByPaymentTerm(type, term);
          }
          else {

            this.getBalanceDuedateByPaymentTerm('', '');
          }
        }
      })
    );
    this.subscription.add(
      this.lookupApi.GetAllLookup("PAYMENT").subscribe(data => {
        this.PmntMethods = data["ResponseData"];
        if (this.parentForm.controls.PMTID.value != "") {
          this.PmntMethodsSelectedItem = this.PmntMethods.find(
            item => item.Code == this.parentForm.controls.PMTID.value
          );
        }
      })
    );
  }
  getExchangeRate(currency, bookingDate) {
    bookingDate = this.commonser.ConvertToLocaleDate(bookingDate);

    this.subscription.add(
      this.apiExRate.getExchangeRate(currency, bookingDate).subscribe(data => {
        this.currencyExrate = data;
        this.parentForm.controls.PDEXRATE.setValue(
          this.currencyExrate.ResponseData.EXRATE
        );
        this.CalculateCorporateForm();
      })
    );
  }
  private exRate: number;
  private tcAmount: number;
  public CalculateCorporateForm() {
    this.exRate =
      this.parentForm.get("PDEXRATE").value == "" ||
        this.parentForm.get("PDEXRATE").value == 0
        ? 1
        : this.parentForm.get("PDEXRATE").value;
    // if((this.parentForm.get('AMTLC')!=null) && this.parentForm.get('AMTLC').value!=0&&this.parentForm.get('AMTLC').value!="undefined")
    // {

    this.tcAmount = this.parentForm.controls.PDFCAMT.value;
    this.parentForm.controls.PDAMOUNT.setValue(
      parseFloat((this.tcAmount * this.exRate).toFixed(2))
    );
    //this.parentForm.controls.PAYABLE.setValue(this.fCBuy*this.exRate );
    // }
  }

  changefn() { }
  public PmntMethodsChange(value: any): void {
    this.parentForm.controls.PMTID.setValue(value.Code);
  }
  private amount: number;
  private defaultDepositPer: number;
  private balanceDueAmount: number;
  // private fcBuy:number;
  private hcBuy: number = 0;
  CalculatePaymentForm() {
    if (this.parentForm.get("PAYABLE") != null) {
      this.hcBuy = this.parentForm.get("PAYABLE").value;
    } else if (this.parentForm.get("AMOUNT") != null) {
      this.hcBuy = this.parentForm.get("AMOUNT").value;
    }
    // this.fcBuy=(this.parentForm.get('FCBuy').value==""||this.parentForm.get('FCBuy').value==0)?0:this.parentForm.get('FCBuy').value;
    this.amount =
      this.parentForm.get("DEFDEP").value == "" ||
        this.parentForm.get("DEFDEP").value == 0
        ? 0
        : this.parentForm.get("DEFDEP").value;
    this.defaultDepositPer =
      this.parentForm.get("DEFDEPPER").value == "" ||
        this.parentForm.get("DEFDEPPER").value == 0
        ? 0
        : this.parentForm.get("DEFDEPPER").value;
    if (this.defaultDepositPer != 0 && this.defaultDepositPer != 0.0)
      this.amount = (this.hcBuy * this.defaultDepositPer) / 100;

    this.balanceDueAmount = this.hcBuy - this.amount;
    this.parentForm.controls.DEFDEP.setValue(
      parseFloat(this.amount.toFixed(2))
    );
    this.parentForm.controls.BalanceDueAmount.setValue(
      parseFloat(this.balanceDueAmount.toFixed(2))
    );
  }
  CalculatePaymentFormByAmount() {
    if (this.parentForm.get("PAYABLE") != null) {
      this.hcBuy = this.parentForm.get("PAYABLE").value;
    } else if (this.parentForm.get("AMOUNT") != null) {
      this.hcBuy = this.parentForm.get("AMOUNT").value;
    }
    //this.fcBuy=(this.parentForm.get('FCBuy').value==""||this.parentForm.get('FCBuy').value==0)?1:this.parentForm.get('FCBuy').value;
    this.amount =
      this.parentForm.get("DEFDEP").value == "" ||
        this.parentForm.get("DEFDEP").value == 0
        ? 0
        : this.parentForm.get("DEFDEP").value;
    this.defaultDepositPer =
      this.parentForm.get("DEFDEPPER").value == "" ||
        this.parentForm.get("DEFDEPPER").value == 0
        ? 0
        : this.parentForm.get("DEFDEPPER").value;
    // if (this.amount != 0 && this.amount!=0.00)
    //      this.amount = (this.fcBuy * this.defaultDepositPer) / 100;

    this.balanceDueAmount = this.hcBuy - this.amount;
    this.parentForm.controls.DEFDEP.setValue(
      parseFloat(this.amount.toFixed(2))
    );
    this.parentForm.controls.BalanceDueAmount.setValue(
      parseFloat(this.balanceDueAmount.toFixed(2))
    );
  }
  public cardTypecombochage(value: any): void {
    if (value !== undefined && value !== null) {
      this.parentForm.controls.PDTYPENO.setValue(value.CARDID);
    } else {
      this.parentForm.controls.PDTYPENO.setValue(0);
    }
  }

  public PmntTrmChange(value: any): void {
    debugger;
    if (value != undefined) {
      this.parentForm.controls.PAYMENTID.setValue(value.Code);

      var type = value.ActualName;
      var term = value.PAYTERM;
      this.getBalanceDuedateByPaymentTerm(type, term);
    }
    else {
      this.parentForm.controls.PAYMENTID.setValue(0);
      this.getBalanceDuedateByPaymentTerm('', '');
    }

  }
  public PDQChange(value: any): void {
    debugger;
    if (value !== undefined && value !== null) {
      this.parentForm.controls.PDNO.setValue(value.Code);
      this.parentForm.controls.PDTYPENO.setValue(0);
      this.PDTYPENOSelectedItem = null;
      this.parentForm.controls.PDTYPENO.setValue

      this.subscription.add(
        this.lookupApi.GetPDQCard(value.Code).subscribe(data => {
          this.cardType = data["ResponseData"];
          if (value.ActualName != "") {
            this.PDTYPENOSelectedItem = this.cardType.find(
              item => item.CARDID == value.ActualName
            );
            this.parentForm.controls.PDTYPENO.setValue(value.ActualName);
            this.parentForm.controls.PDNO.setValue(value.Code);
          }
        })
      );

      if (value.CurID != "") {
        this.parentForm.controls.PDFCURID.setValue(value.CurID);
        this.CurrencyselectedItem = this.Currency.find(
          item => item.Code == value.CurID
        );
      }
    } else {
      this.parentForm.controls.PDNO.setValue(0); //355
    }
  }

  public valueChange(value: any): void {
    this.PmntTrmselectedItem = this.PmntTrm.find(item => item.UsrCode == value);
    //alert(this.PmntTrmselectedItem.Name+'-'+this.PmntTrmselectedItem.UsrCode+'-'+this.PmntTrmselectedItem.Code);
  }

  public onPanelChange(event: any): void {
    console.log("stateChange: ", event);
  }
  public GetCurrencyExchangeRateTolerance() {
    debugger;
    var bookingDate = new Date();
    if (this.parentForm.controls.ITYPE.value != "Ticket") {
      bookingDate = this.parentForm.controls.BookingDate.value != null ? this.parentForm.controls.BookingDate.value : bookingDate;
    } else {
      bookingDate = this.parentForm.controls.TICKDATE.value != null ? this.parentForm.controls.TICKDATE.value : bookingDate;
    }
    if (this.CurrencyselectedItem != undefined) {
      this.spinner.show();
      this.cashservice.formData = new CashPaymentDTO();
      this.cashservice.formData.PVRDATE = this.commonser.ConvertToLocaleDate(new Date(bookingDate));
      this.cashservice.formData.date = this.commonser.ConvertToLocaleDate(new Date())
      this.cashservice.formData.HCID = this.CurrencyselectedItem.UsrCode;
      this.cashservice.formData.FCID = this.CurrencyselectedItem.UsrCode;
      this.cashservice.formData.RXRATE = this.parentForm.controls.PDEXRATE.value;
      this.cashservice.formData.LANQUAGE = this.apiShared.LanguageSelected;
      this.subscription.add(this.cashservice.GetCurrencyExchangeRateTolerance().subscribe(x => {
        debugger;
        if (x != null && x != undefined) {
          if (x["ResponseData"].messageid == 0) {
            this.parentForm.controls.PDEXRATE.setValue(x["ResponseData"].RXRATE);
          }
          else if (x["ResponseData"].messageid > 0) {
            var msg = 'Messages.msg' + x["ResponseData"].messageid;
            this.commonser.showWARNINGtoastrmsg(x["ResponseData"].message, 3000, true, false);
            this.parentForm.controls.PDEXRATE.setValue(x["ResponseData"].RXRATE);
          }
          this.CalculateCorporateForm();
          this.spinner.hide();
        }
      }));
    } else {
      this.commonser.showWARNINGtoastrmsg('Messages.invalid_currency', 3000, true, false);
      this.CurrencyChange(null);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.parentForm.value["ITYPE"] === "Hotel") {
      this.SellComm = this.parentForm.get("SELLCOMM").value;
    } else {
      this.SellComm = this.parentForm.get("HCCommAmt").value;
    }
    // console.log(this.dummymenu);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
