import { Component, OnInit, Input } from '@angular/core';
import { PassengerDetailsn, titleList1, typeList1 } from '../../Models/passenger-details';
import { ActivatedRoute, Router } from '@angular/router';
import { PassengerService } from '../../Services/passenger.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import { ShareDataService } from '../../Services/share-data.service';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { CommonserviceService } from '../../Services/commonservice.service';
import { State } from '@progress/kendo-data-query';
import { ToastrService } from 'ngx-toastr';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';


@Component({
  selector: 'app-passenger-detail',
  templateUrl: './passenger-detail.component.html',
  styleUrls: ['./passenger-detail.component.scss'],
  styles: [`
      html, body, app-passenger-detail {
        height: 100%;
      }
    `]
})

export class PassengerDetailComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  editForm: FormGroup;
  enableaddinfo: boolean = false;
  public clickedRowItem;
  PassCitizenShip: Array<LookUpDetails["ResponseData"]> = [];
  public PassCitizenShipselectedItem: LookUpDetails["ResponseData"];
  PassReqdDoc: Array<LookUpDetails["ResponseData"]> = [];
  public PassReqdDocselectedItem: LookUpDetails["ResponseData"];
  Insurance: Array<LookUpDetails["ResponseData"]> = [];
  public InsuranceselectedItem: LookUpDetails["ResponseData"];
  VisaReqd: Array<LookUpDetails["ResponseData"]> = [];
  public VisaReqdselectedItem: LookUpDetails["ResponseData"];
  IsEdit: boolean = false;
  lockStatus: any;
  projNo: any;
  public equals: 'equals';
  public value: Date = new Date('1900-01-01T00:00:00');
  private editedRowIndex: number;
  public view: any[];
  public typeList1: any[] = typeList1;
  public titleList1: any[] = titleList1;
  products: PassengerDetailsn[];
  public formGroup: FormGroup;
  public formeditable = true;
  public gridData: any;
  public gridData1: any;
  folderStatus: any;
  folderEditMode: any;
  showFilter = false;
  passdata: PassengerDetailsn;
  public showMsg: any;
  public typeList: Array<string> = ["Adult", "Youth", "Junior", "Child", "Infant"];
  public titleList: Array<string> = ["MR", "MRS", "MS", "PROF", "DOCTOR", "MSTR", "MISS", "LORD", "LADY", "MISSCHD", "REV", "HON", "BSS"];
  public itemToRemove: any;
  public submitted = false;
  public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  @Input() editId: boolean; // decorate the property with @Input()

  constructor(private toastr: ToastrService, public common: CommonserviceService, private formBuilder1: FormBuilder, private PassengerService: PassengerService, private route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private apiShared: ShareDataService, private flashMessage: FlashMessagesService, private translateapi: TranslateService, private lookupApi: LookUpDetailsService) {

    this.removeConfirmation = this.removeConfirmation.bind(this);
  }
  private orderNo: string = this.route.snapshot.params['orderNo'];//'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/';
  private slno = this.route.snapshot.params['slno'];
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  gridHeight = 134;

  public disableDates = (date: Date): boolean => {   //By caseid -187

    return (date && date.getTime() > (new Date()).getTime());
  }
  ngOnInit() {

    var orderno = sessionStorage.getItem('orderNo');
    this.projNo = this.apiShared.projNo;
    this.spinner.show();
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;
    }
    else {
      this.formeditable = false;
    }

    this.folderEditMode = "false";
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');

    this.BindPassengerDetails(this.orderNo);
    if (this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund")//By Caseid-12780
    {
      this.formeditable = false;
    }
    this.editForm = this.formBuilder.group({
      CITIZENSHIP: [''],
      PASSREQUIREDDOCS: ['',],
      INSURANCENAME: ['',],
      VISAREQUIRED: [''],
      PROJNO: this.projNo,
      FOLDERNO: this.orderNo,
      SLNO: 0,
      DOB: [''],
      FIRSTNAME: [''],
      LASTNAME: [''],
      TITLE: [''],
      TYPE: [''],
      EMail: [''],
      TELEPHONE: [''],
      SaveType: 1,
      PM_AutoNo: 0
    });
  }
  get f() { return this.formGroup.controls; }
  public BindPassengerDetails(folderNo: string) {
    this.spinner.show();
    this.subscription.add(this.PassengerService.GetPassengerDetails(folderNo).subscribe(res => {
      debugger;
      this.gridData = res.ResponseData
      if (this.gridData.length > 0) {
        this.enableaddinfo = false;
      }
      else {
        this.enableaddinfo = true;
      }
      this.spinner.hide();
      this.setGridSize();

    }));

    this.LoadDefaultdat();

  }
  LoadDefaultdat() {
    debugger;
    this.spinner.show();
    this.subscription.add(this.lookupApi.GetAllLookup('PASSENGERREQUIREDDOCS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.PassReqdDoc = data["ResponseData"];
          if (data != null && data != undefined && data["ResponseData"].length > 0) {
            this.PassReqdDocselectedItem = this.PassReqdDoc.find(x => x.Default == 1);

            if (this.editForm.controls.PASSREQUIREDDOCS.value != null && this.editForm.controls.PASSREQUIREDDOCS.value != '') {
              this.PassReqdDocselectedItem = this.PassReqdDoc.find(x => x.Name == this.editForm.controls.PASSREQUIREDDOCS.value);
              this.editForm.controls.PASSREQUIREDDOCS.setValue(this.PassReqdDocselectedItem.Name);
            }
            else {
              this.PassReqdDocselectedItem = this.PassReqdDoc.find(x => x.Default == 1);
              if (this.PassReqdDocselectedItem != null)
                this.editForm.controls.PASSREQUIREDDOCS.setValue(this.PassReqdDocselectedItem.Name);
            }


          }


        }

      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('CITIZENSHIP').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.PassCitizenShip = data["ResponseData"];



          if (this.editForm.controls.CITIZENSHIP.value != null && this.editForm.controls.CITIZENSHIP.value != '') {
            this.PassCitizenShipselectedItem = this.PassCitizenShip.find(x => x.Name == this.editForm.controls.CITIZENSHIP.value);
            if (this.PassCitizenShipselectedItem != null)
              this.editForm.controls.CITIZENSHIP.setValue(this.PassCitizenShipselectedItem.Name);
          }
          else {
            this.subscription.add(this.lookupApi.GetLookupById('FINDCUSTOMER', this.common.PrtyCodeFolder, '').subscribe(
              (data) => {
                debugger;
                if (data != null && data != undefined && data["ResponseData"].length > 0) {

                  this.PassCitizenShipselectedItem = this.PassCitizenShip.find(x => x.Name == data["ResponseData"][0].SELFBILLING);
                  if (this.PassCitizenShipselectedItem != null)
                    this.editForm.controls.CITIZENSHIP.setValue(this.PassCitizenShipselectedItem.Name);
                }
              }))
          }

        }



      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('PassINSURANCE').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.Insurance = data["ResponseData"];
          if (data != null && data != undefined && data["ResponseData"].length > 0) {
            ;

            if (this.editForm.controls.INSURANCENAME.value != null && this.editForm.controls.INSURANCENAME.value != '') {
              this.InsuranceselectedItem = this.Insurance.find(x => x.Name == this.editForm.controls.INSURANCENAME.value)
              if (this.InsuranceselectedItem != null)
                this.editForm.controls.INSURANCENAME.setValue(this.InsuranceselectedItem.Name);
            }
            else {
              this.InsuranceselectedItem = this.Insurance.find(x => x.Default == 1)
              if (this.InsuranceselectedItem != null)
                this.editForm.controls.INSURANCENAME.setValue(this.InsuranceselectedItem.Name);
            }
          }
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('VISAREQUIRED').subscribe(
      (data) => {
        this.spinner.hide();
        if (data != null && data != undefined) {
          this.VisaReqd = data["ResponseData"];
          if (data != null && data != undefined && data["ResponseData"].length > 0) {


            if (this.editForm.controls.VISAREQUIRED.value != null && this.editForm.controls.VISAREQUIRED.value != '') {
              this.VisaReqdselectedItem = this.VisaReqd.find(x => x.Name == this.editForm.controls.VISAREQUIRED.value);
              if (this.VisaReqdselectedItem != null)
                this.editForm.controls.VISAREQUIRED.setValue(this.VisaReqdselectedItem.Name);
            }
            else {
              this.VisaReqdselectedItem = this.VisaReqd.find(x => x.Default == 1);
              if (this.VisaReqdselectedItem != null)
                this.editForm.controls.VISAREQUIRED.setValue(this.VisaReqdselectedItem.Name);
            }


          }
        }
      }
    ));
  }
  setGridSize() {
    this.gridHeight = 134;
    if (this.gridData.length > 5) {
      this.gridHeight = this.gridHeight + (46.5 * 4);
    } else {
      if (this.gridData.length > 1)
        this.gridHeight = this.gridHeight + (47 * (this.gridData.length - 1));
    }
  }
  public onStateChange(state: State) {
    this.gridState = state;
  }
  onAddClick() {
    debugger;


    this.router.navigate(['PenAir/Folder/PassengerDetailEdit', this.orderNo, -1]);
  }

  public confirmRemove(shouldRemove: boolean): void {
    debugger;
    this.removeConfirmationSubject.next(shouldRemove);
    if (shouldRemove) {

      this.subscription.add(this.PassengerService.DeletePassengerDetails(this.itemToRemove.FOLDERNO, this.itemToRemove.PM_AutoNo).subscribe(res => {


        this.BindPassengerDetails(this.orderNo);
        this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {

          this.showMsg = translations['Messages.deleted_success'];
          this.toastr.success(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,

            closeButton: true
          });
        }));
      }, (err) => {
        console.log(err);

        this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {

          this.showMsg = translations['Messages.Failed'];
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,

            closeButton: true
          });
        }));

      }));
    }
    this.itemToRemove = null;
  }

  public removeConfirmation(dataItem): Subject<boolean> {
    this.itemToRemove = dataItem;

    return this.removeConfirmationSubject;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }









  private serializeModels(data?: any): string {
    return data ? `&models=${JSON.stringify(data)}` : '';
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {

    if (!isEdited) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup1(dataItem));
    }
  }

  public addHandler({ sender }) {
    this.submitted = false;
    if (this.orderNo == null || this.orderNo == "" || this.orderNo == undefined || this.orderNo == "0")
      this.orderNo = this.route.snapshot.params['orderNo'];
    if (this.projNo == null || this.projNo == "" || this.projNo == undefined)
      this.projNo = 0;

    sender.addRow(this.createFormGroup1(new PassengerDetailsn()));
  }

  public cancelHandler({ sender, rowIndex }) {

    sender.closeRow(rowIndex);
  }
  public editHandler({ sender, rowIndex, dataItem }) {


    this.submitted = false;
    this.closeEditor(sender);

    this.formGroup = this.createFormGroup1(dataItem);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);


  }
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    debugger;
    var validdate = new Date('01/01/1900')
    formGroup.value['DOB'] = this.value;
    var currentdate = new Date();
    currentdate.setSeconds(0);
    currentdate.setMinutes(0);
    currentdate.setHours(0);
    var date = formGroup.value['DOB'];
    var type = formGroup.value['TYPE'];
    if (date != null) // For caseid-187
    {
      date.setSeconds(0);
      date.setMinutes(0);
      date.setHours(0);
      if (date > currentdate) {
        this.subscription.add(this.translateapi.get(['Messages.FutureDate']).subscribe((translations) => {

          this.showMsg = translations['Messages.FutureDate'];
          this.toastr.warning(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,

            closeButton: true
          });
        }));
        return;
      }
      if (date < validdate) {
        this.subscription.add(this.translateapi.get(['Messages.InvalidDate']).subscribe((translations) => {

          this.showMsg = translations['Messages.InvalidDate'];
          this.toastr.warning(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,

            closeButton: true
          });
        }));
        return;
      }
    }
    if (formGroup.value['TYPE'] == '' || formGroup.value['TYPE'] == null || formGroup.value['TYPE'] == undefined) {
      formGroup.value['TYPE'] = 'Adult';
    }
    if (formGroup.valid) {
      this.submitted = true;
      if (isNew === true) {
        formGroup.value['SLNO'] = -1;
        this.subscription.add(this.PassengerService.AddPassengerDetails(formGroup.value).subscribe(res => {
          this.spinner.hide();

          this.subscription.add(this.PassengerService.GetPassengerDetails(this.orderNo).subscribe(res => {
            this.gridData = res.ResponseData
            this.spinner.hide();
            if (this.gridData.length > 0) {
              this.enableaddinfo = false;
            }
            else {
              this.enableaddinfo = true;
            }
            this.setGridSize();
            sender.closeRow(rowIndex);
            this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {

              this.showMsg = translations['Messages.saved_success'];
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,

                closeButton: true
              });
            }));
          }));
        }, (err) => {
          this.spinner.hide();
          this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {

            this.showMsg = translations['Messages.some_error_in_save'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,

              closeButton: true
            });
          }));

        }));
      }
      else {
        debugger;
        this.subscription.add(this.PassengerService.UpdatePassengerDetails(this.formGroup.value).subscribe(res => {
          this.spinner.hide();
          this.subscription.add(this.PassengerService.GetPassengerDetails(this.orderNo).subscribe(res => {
            this.gridData = res.ResponseData
            this.spinner.hide();
            if (this.gridData.length > 0) {
              this.enableaddinfo = false;
            }
            else {
              this.enableaddinfo = true;
            }
            this.setGridSize();
            sender.closeRow(rowIndex);
            this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {

              this.showMsg = translations['Messages.saved_success'];
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,

                closeButton: true
              });
            }));
          }));
        }, (err) => {
          this.spinner.hide();
          this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {

            this.showMsg = translations['Messages.some_error_in_save'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,

              closeButton: true
            });
          }));
        }));

      }


    }
  }
  public onFilterClick() {
    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }

  public removeHandler({ sender, dataItem }) {
    this.itemToRemove = dataItem;

    return this.removeConfirmationSubject;
    sender.cancelCell();
  }
  public createFormGroup1(dataItem: any): FormGroup {
    debugger;
    this.value = dataItem.DOB != null ? (dataItem.DOB.toString() == "1900-01-01T00:00:00" ? null : new Date(dataItem.DOB)) : null;
    return this.formBuilder.group({
      'FIRSTNAME': [dataItem.FIRSTNAME, Validators.required],
      'LASTNAME': [dataItem.LASTNAME, Validators.required],
      'TITLE': dataItem.TITLE,
      'TYPE': [dataItem.TYPE],
      'EMail': [dataItem.EMail, Validators.email],
      'TELEPHONE': [dataItem.TELEPHONE, Validators.compose([Validators.maxLength(12), Validators.pattern('[0-9]+')])],
      'DOB': dataItem.DOB != null ? (dataItem.DOB.toString() == "1900-01-01T00:00:00" ? null : new Date(dataItem.DOB)) : null,
      'PROJNO': this.projNo,
      'FOLDERNO': this.orderNo,
      'SLNO': dataItem.SLNO,
      'PM_AutoNo': dataItem.PM_AutoNo
    });
  }
  onUpdateButtonClick() {
    if (this.gridData.length > 0) {
      this.onDblClick('');

    }
    else {
      this.common.showWARNINGtoastrmsg("Messages.AddAtleastOnePax", 2000, true, false);
    }
  }
  close() {
    this.IsEdit = false;
  }
  ClearData() {
    this.PassReqdDocselectedItem = null;
    this.VisaReqdselectedItem = null;
    this.InsuranceselectedItem = null;
    this.PassCitizenShipselectedItem = null;
    this.editForm = this.formBuilder.group({
      CITIZENSHIP: [''],
      PASSREQUIREDDOCS: ['',],
      INSURANCENAME: ['',],
      VISAREQUIRED: [''],
      PROJNO: this.projNo,
      FOLDERNO: this.orderNo,
      SLNO: 0,
      DOB: [''],
      FIRSTNAME: [''],
      LASTNAME: [''],
      TITLE: [''],
      TYPE: [''],
      EMail: [''],
      TELEPHONE: [''],
      SaveType: 1,
      PM_AutoNo: 0
    });
  }
  public VisaReqdValueChange(value: any): void {
    debugger;
    if (value != undefined) {
      this.VisaReqdselectedItem = this.VisaReqd.find(item => item.Name == value.Name);
    }
    if (value == undefined) {
      this.VisaReqdselectedItem = null;
    }

    if (this.VisaReqdselectedItem != null) {

      this.editForm.controls.VISAREQUIRED.setValue(this.VisaReqdselectedItem.Name);
    }
    else {


      this.editForm.controls.VISAREQUIRED.setValue("");
    }

  }

  public InsuranceValueChange(value: any): void {
    debugger;
    if (value != undefined) {
      this.InsuranceselectedItem = this.Insurance.find(item => item.Name == value.Name);
    }
    if (value == undefined) {
      this.InsuranceselectedItem = null;
    }

    if (this.InsuranceselectedItem != null) {

      this.editForm.controls.INSURANCENAME.setValue(this.InsuranceselectedItem.Name);
    }
    else {


      this.editForm.controls.INSURANCENAME.setValue("");
    }

  }
  public PassReqdDocValueChange(value: any): void {
    debugger;
    if (value != undefined) {
      this.PassReqdDocselectedItem = this.PassReqdDoc.find(item => item.Name == value.Name);
    }
    if (value == undefined) {
      this.PassReqdDocselectedItem = null;
    }

    if (this.PassReqdDocselectedItem != null) {

      this.editForm.controls.PASSREQUIREDDOCS.setValue(this.PassReqdDocselectedItem.Name);
    }
    else {


      this.editForm.controls.PASSREQUIREDDOCS.setValue("");
    }

  }

  public PassCitizenValueChange(value: any): void {
    debugger;
    if (value != undefined) {
      this.PassCitizenShipselectedItem = this.PassCitizenShip.find(item => item.Name == value.Name);
    }
    if (value == undefined) {
      this.PassCitizenShipselectedItem = null;
    }

    if (this.PassCitizenShipselectedItem != null) {

      this.editForm.controls.CITIZENSHIP.setValue(this.PassCitizenShipselectedItem.Name);
    }
    else {


      this.editForm.controls.CITIZENSHIP.setValue("");
    }

  }
  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }
  onDblClick(e) {
    debugger;
    this.spinner.show();
    this.ClearData();
    if (this.clickedRowItem != null && this.clickedRowItem.PM_AutoNo != null && this.clickedRowItem.PM_AutoNo > 0) {
      this.editForm.controls.DOB.setValue(this.clickedRowItem.DOB != null ? (this.clickedRowItem.DOB.toString() == "1900-01-01T00:00:00" ? null : new Date(this.clickedRowItem.DOB)) : null);
      this.editForm.controls.PM_AutoNo.setValue(this.clickedRowItem.PM_AutoNo);

      this.editForm.controls.TELEPHONE.setValue(this.clickedRowItem.TELEPHONE);
      this.editForm.controls.EMail.setValue(this.clickedRowItem.EMail);

      this.editForm.controls.TYPE.setValue(this.clickedRowItem.TYPE);
      this.editForm.controls.TITLE.setValue(this.clickedRowItem.TITLE);

      this.editForm.controls.LASTNAME.setValue(this.clickedRowItem.LASTNAME);
      this.editForm.controls.FIRSTNAME.setValue(this.clickedRowItem.FIRSTNAME);

      this.editForm.controls.INSURANCENAME.setValue(this.clickedRowItem.INSURANCENAME);
      this.editForm.controls.VISAREQUIRED.setValue(this.clickedRowItem.VISAREQUIRED);

      this.editForm.controls.PASSREQUIREDDOCS.setValue(this.clickedRowItem.PASSREQUIREDDOCS);
      this.editForm.controls.CITIZENSHIP.setValue(this.clickedRowItem.CITIZENSHIP);

      this.IsEdit = true;
      this.LoadDefaultdat();
    }
    else {
      this.spinner.hide();
      this.common.showWARNINGtoastrmsg("Messages.PleaseSelectAtleastOneRow", 2000, true, false);
    }

  }
  onSaveButtonClick(id: any) {
    debugger;
    this.editForm.controls.SaveType.setValue(id);
    this.spinner.show();
    this.subscription.add(this.PassengerService.UpdatePassengerDetails(this.editForm.value).subscribe(res => {

      this.subscription.add(this.PassengerService.GetPassengerDetails(this.orderNo).subscribe(res => {
        this.gridData = res.ResponseData

        if (this.gridData.length > 0) {
          this.enableaddinfo = false;
        }
        else {
          this.enableaddinfo = true;
        }
        this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
          this.close()
          this.ClearData();
          this.spinner.hide();
          this.showMsg = translations['Messages.saved_success'];
          this.toastr.success(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,

            closeButton: true
          });
        }))
      }));

    }, (err) => {
      this.spinner.hide();
      this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {

        this.showMsg = translations['Messages.some_error_in_save'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,

          closeButton: true
        });
      }));
    }));

  }

}
