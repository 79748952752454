import { Component, OnInit, Input, HostListener } from '@angular/core';
import {  AirsegDetail } from '../../Models/airseg-detail';
import { ActivatedRoute, Router } from '@angular/router';
import { AirsegService } from '../../Services/airseg.service';
import { ShareDataService } from '../../Services/share-data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';

@Component({
  selector: 'app-airseg-details',
  templateUrl: './airseg-details.component.html',
  styleUrls: ['./airseg-details.component.scss']
})


export class AirsegDetailsComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  lockStatus:any;
  public formeditable=true;
  folderEditMode:any;
  folderStatus:any;
  public ExcludeOnetimeCust_int=0;
  public ExcludeOnetimeCust=false;
  DEPTIME:string=null;
  ARRTIME:string=null;
     airsegDetail: AirsegDetail  = {
    IsSuccess:true,
    Message:"succcess",
    ResponseData:{   
         FOLDERNO:0,
          ITEMNO:0,
          AIRNAME:'',
          AIRREALNAME:'',
          FLIGHT:'',
          CLASS:'',
          CLASSNAME:'',
          SEATS:'',
          STATUS:'',
          STATUSNAME:'',
          AIRDATE:new Date('01/01/1900').toLocaleDateString('en-GB', {
            day: 'numeric', month: 'short', year: 'numeric'
          }).replace(/ /g, '/'),
          DEPCITY:'',
          DEPCITYNAME:'',
          ARRCITY:'',
          ARRCITYNAME:'',
          OPENSEG:'',
          DEPTIME:0,
          ARRTIME:0,
          S:'',
          PNRNO:'',
          FAREBASIS:'',
          DEPTERM:'',
          CHKIN:'',
          BOOKEDVIA:'',
          ARRDATE:new Date('01/01/1900').toLocaleDateString('en-GB', {
            day: 'numeric', month: 'short', year: 'numeric'
          }).replace(/ /g, '/'),
          ARRTERM:'',
          FLTTIME:0,
          BAGGALLOW:'',
          MEALSINFO:'',
          SEATINFO:'',
          SLNO:0,
          PROJNO:0,
          GDS:'',
          Stops:0,
          ItnryNotes:'',
          PCC:'',
          VLOCATOR:'',
          SUPPCODE:0,
          OperatingAIRID:'',
          OperatingAIRNAME:'',
          OperatingFlightNo:'',
          TICKETORDER:0,
          Stopover:''  ,
          PNR:''        

       
    }
  };

  constructor(public common :CommonserviceService, private route: ActivatedRoute,private router: Router,private api: AirsegService,private apiShared: ShareDataService, private spinner:NgxSpinnerService) { }
  
  //@Input() guid: "",
  //@Input() orderno: 0,
  private slno:string=this.route.snapshot.params['slno'];//'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/';
  private orderNo=this.route.snapshot.params['orderNo']
  ngOnInit() {
    var orderno= sessionStorage.getItem('orderNo');
    if(orderno!=null)
    if(this.orderNo !==orderno)
    {
      return  this.router.navigate(['PenAir/Home'],{skipLocationChange:true});
    }
    this.folderStatus=this.apiShared.FolderStatus;
    this.lockStatus= this.apiShared.folderReadOnly;
    if(this.lockStatus=="0" && this.folderStatus!="Invoiced")
    {
      this.formeditable=true;
    }
    else{
      this.formeditable=false;
    }
    if(this.folderStatus=="Cancelled" ||this.folderStatus=="Refund" || this.folderStatus=="Partial Refund")//By Caseid-12780
    {
      this.formeditable=false;
    }
    this.folderEditMode="false";
    //this.apiShared.folderEditMode=this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode,'folderEditMode');

   this.getAirseggDetails(this.orderNo,this.slno);
    //'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/
  }
  getAirseggDetails(id,slno) {
    this.spinner.show();
    this.subscription.add(  this.api.getAirseg(id,slno)
      .subscribe(data => {
        if(data!=null && data !==undefined)
        {
        this.airsegDetail = data;
        debugger;
     //   this.airsegDetail.ResponseData.DEPTIME=+this.airsegDetail.ResponseData.DEPTIME.toFixed(4);
        this.DEPTIME= this.common.ConvertTo4digit( this.airsegDetail.ResponseData.DEPTIME,4);
        this.ARRTIME= this.common.ConvertTo4digit( this.airsegDetail.ResponseData.ARRTIME,4);
        

          if(this.airsegDetail.ResponseData.OPENSEG=='1'){
                this. ExcludeOnetimeCust_int=1;
                this. ExcludeOnetimeCust=true;
            
              }
              else{
                this. ExcludeOnetimeCust_int=0;
                this. ExcludeOnetimeCust=false;
               
              };
        this.spinner.hide();
            }
            else
            {
              this.spinner.hide(); 
            }
        //this.isLoadingResults = false;
      }));
  }
 

  onEdit()
  {
    
    this.router.navigate(['PenAir/Product/AirsegDetailsEdit',this.orderNo,this.slno],{skipLocationChange:true});
  }
  onCancel()
  {
    var folderQuotes=sessionStorage.getItem('folderQuotes');
    if(folderQuotes){
      this.router.navigate(['/PenAir/CRM/Enquiry/View/',this.orderNo],{skipLocationChange:true});
    }
    else{
    this.router.navigate(['PenAir/Folder/FolderSummary',this.orderNo],{skipLocationChange:true});
    }
    //this.router.navigate(['/PenAir/Folder/Products',this.orderNo],{skipLocationChange:true});
    
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  isSticky: boolean = false;
@HostListener('window:scroll')
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
}

  onCheckboxChange(){
    
  }
}
