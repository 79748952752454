import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EnvService } from 'src/env.service';
import { CommonserviceService } from './commonservice.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class MenuserviceService {
  apiUrl: string;
  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }

  public getFavoriteMenuList(): Observable<any> {
    const url = `${this.apiUrl}` + 'GetFavourites';
    return this.http.get<any>(url).pipe(
      tap(),
      catchError(this.commonservice.handleError<any>(`FavoriteMenu`))
    );
  }
  addFavoriteMenu(FavoriteMenu: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'AddMenuFavorites', FavoriteMenu, httpOptions).pipe(
      tap((InsuranceRes: any) => console.log(`AddMenuFavorites`)),
      catchError(this.commonservice.handleError<any>('AddMenuFavorites'))
    );
  }
  //13097
  async deleteFavoriteMenu(FavoriteMenu: any): Promise<Observable<any>> {
    const url = `${this.apiUrl}` + 'DeleteMenuFavorites';
    var result = await this.http.post(url, FavoriteMenu, httpOptions).pipe(
      tap(_ => console.log(`DeleteMenuFavorites`)),
      catchError(this.commonservice.handleError<any>('DeleteMenuFavorites'))
    ).toPromise();
    return result;;
  }
  //13097
}
