// import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { Router, ActivatedRoute } from '@angular/router';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { FolderSummaryService } from '../../Services/folder-summary.service';
import { ShareDataService } from '../../Services/share-data.service';
import { CurrencyExchangerateService } from '../../Services/currency-exchangerate.service';
import { CurrencyExchangerate } from '../../Models/currency-exchangerate';
import { CustomerreceiptresponseService } from '../../Services/customerreceiptresponse.service';
import { CustomerReceiptResponse } from '../../Models/customer-receipt-response';
import { AddEvent, EditEvent, GridComponent } from '@progress/kendo-angular-grid';
import { groupBy, GroupDescriptor } from '@progress/kendo-data-query';
import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { CustomerReceiptPost } from '../../Models/customer-receipt-post';
import { CustomerPostResult } from '../../Models/customer-post-result';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription, Observable } from 'rxjs';
import { CustomerReceiptRequest } from '../../Models/customer-receipt-request';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
//import { CustomerReceiptPostGridParameter } from '../Models/customer-receipt-post-grid-parameter';
import { CustomerReceiptDetailXMLClass } from '../../Models/customer-receipt-detail-xmlclass';
import { TranslateService } from '@ngx-translate/core';
import { CustomerReceiptHold } from '../../Models/customer-receipt-hold';
import { CustomerHeldDetails } from '../../Models/customer-held-details';
import { map } from 'rxjs/operators';
import { CustomerReceiptHeldHeaderData } from '../../Models/customer-receipt-held-header-data';
import {
  DialogService, DialogRef,
  DialogCloseResult
} from '@progress/kendo-angular-dialog';
import { CreditcardDetailsWindowComponent } from 'src/app/creditcard-details-window/creditcard-details-window.component';
import { IntlService } from '@progress/kendo-angular-intl';
import { BankPaymentDTO } from '../../Models/bank-payment-dto.model';
import { FileService } from '../../Services/file.service';

// import { FlashMessagesService } from 'angular2-flash-messages';


const createFormGroup = dataItem => new FormGroup({
  // AGAINST: new FormControl(dataItem.AGAINST),
  // VRDATE: new FormControl(dataItem.VRDATE),
  // PROJNO: new FormControl(dataItem.PROJNO),
  // ORDNO: new FormControl(dataItem.ORDNO),
  // BRID: new FormControl(dataItem.BRID),
  // VRREF: new FormControl(dataItem.VRREF),
  // ASSIGN: new FormControl(dataItem.ASSIGN),
  // FC_AMOUNT: new FormControl(dataItem.FC_AMOUNT),
  // FC_BALANCE: new FormControl(dataItem.FC_BALANCE),
  // FCReceipt: new FormControl(dataItem.FCReceipt),
  // Reference: new FormControl(dataItem.Reference),
  // Receipt: new FormControl(dataItem.Receipt),
  // AMOUNT: new FormControl(dataItem.AMOUNT),
  // BALANCE: new FormControl(dataItem.BALANCE),
  // VRTYPE: new FormControl(dataItem.VRTYPE),
  // YEARID: new FormControl(dataItem.YEARID),
  // CUSTOMER: new FormControl(dataItem.CUSTOMER),
  // CUSTOMERBALANCE: new FormControl(dataItem.CUSTOMERBALANCE),
  // TempHCBALANCE: new FormControl(dataItem.TempHCBALANCE),
  // Select: new FormControl(dataItem.Select)
 
  AGAINST: new FormControl(dataItem.AGAINST),
  VRDATE: new FormControl(dataItem.VRDATE),
  ORDNO: new FormControl(dataItem.ORDNO),
  VRREF: new FormControl(dataItem.VRREF),
  FC_AMOUNT: new FormControl(dataItem.FC_AMOUNT),
  FC_BALANCE: new FormControl(dataItem.FC_BALANCE),
  VRTYPE: new FormControl(dataItem.VRTYPE),
  YEARID: new FormControl(dataItem.YEARID),
  PROJNO: new FormControl(dataItem.PROJNO),
  ASSIGN: new FormControl(dataItem.ASSIGN),
  BRID: new FormControl(dataItem.BRID),
  Reference: new FormControl(dataItem.Reference),
  AMOUNT: new FormControl(dataItem.AMOUNT),
  BALANCE: new FormControl(dataItem.BALANCE),
  TempHCBALANCE: new FormControl(dataItem.TempHCBALANCE),
  CUSTOMER: new FormControl(dataItem.CUSTOMER),
  CUSTOMERBALANCE: new FormControl(dataItem.CUSTOMERBALANCE),
  Receipt: new FormControl(dataItem.Receipt),
  FCReceipt: new FormControl(dataItem.FCReceipt),
  Select: new FormControl(dataItem.Select)
});

const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);
const hasClass = (el, className) => new RegExp(className).test(el.className);

const isChildOf = (el, className) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};
@Component({
  selector: 'app-customerreceipts',
  templateUrl: './customerreceipts.component.html',
  styleUrls: ['./customerreceipts.component.scss']
})
export class CustomerreceiptsComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }
public Membership_No:any=null;
public CardHolder:any=null;
public ConversionRate:any=null;
  constructor(private spinner: NgxSpinnerService, public commonservice: CommonserviceService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private api: FolderSummaryService, private lookupApi: LookUpDetailsService,
    private apiShared: ShareDataService, private apiExRate: CurrencyExchangerateService, private apiReceipt: CustomerreceiptresponseService,public file:FileService,
    private renderer: Renderer2, private translateapi: TranslateService, private toastr: ToastrService, private dialogService: DialogService
    , public intl: IntlService) { }
  get f() { return this.CustomerReceiptForm.controls; }
  
  Lannguage:any;
  ispdq:any=false;
  crncyCode:any='';
  balanceamt:any=0;
  custCo:any=0;
  public IsSameCurrency:any=false;
  balanceAmount:any=0.00;
  public CURID = "";
  public showMsg: any;
  CustomerReceiptForm: FormGroup;
  currencyExrate: CurrencyExchangerate;
  ExRate:any=0;
  CustomerReceipt: CustomerReceiptResponse;
  public createFormGroup: FormGroup;
  public groups: GroupDescriptor[] = [];
  public view: any[];
  public CustParmDTO: CustomerReceiptRequest;
  stringifiedData: any[];
  @ViewChild(GridComponent) private grid: GridComponent;
  @ViewChild('pdqmotoform')private userInfo: CreditcardDetailsWindowComponent;
  private editedRowIndex: number;
  private editedcolIndex: number;
  private isNew = false;
  public gridData: any;
  disablepostbutton: any = false;
  public TotalRows = 0;
  folderEditMode: any;
  public chkShowSavedFolders: any;
  public chkIncludeReceiptPayment: any;
  public GLSelected: any;
  public ShowSavedFolders: any;
  public IncludeReceipt: any;
  CustomerReceiptPost: CustomerReceiptPost = new CustomerReceiptPost();
  CustomerReceiptHold: CustomerReceiptHold = new CustomerReceiptHold();
  CustomerReceiptHeldHeaderData: CustomerReceiptHeldHeaderData = new CustomerReceiptHeldHeaderData();
  CustomerPostResult: CustomerPostResult;
  Receiptpostgrid: CustomerReceiptDetailXMLClass[];
  CustomerHeldgrid: CustomerHeldDetails[];
  CustomerHeldgridParameter: CustomerHeldDetails = new CustomerHeldDetails();
  ReceiptPostGridParameter: CustomerReceiptDetailXMLClass = new CustomerReceiptDetailXMLClass();
  submitted = false;
  FCAmalaneSum:any=0;
  AdvancePay: boolean = false;
  date: any;//337
  // submittedBank=false;
  private docClickSubscription: any;
  public statusList: Array<string> = ['Cheque',
    'Bacs',
    'Bank Transfer',
    'Others',
    'Direct Debit',
    'Debit Card'];
  private orderno = this.route.snapshot.params.orderNo;
  public type = this.route.snapshot.params.type;
  branchList: Array<LookUpDetails['ResponseData']> = [];
  public branchSelectedItem: LookUpDetails['ResponseData'];
  public branchSelected: String;
  HeldInvoiceNoList: Array<LookUpDetails['ResponseData']> = [];
  public HeldInvoiceNoSelectedItem: LookUpDetails['ResponseData'];
  public HeldInvoiceNoSelected: String;
  public Days: number;
  CustomerList: Array<LookUpDetails['ResponseData']> = [];
  public customerSelectedItem: LookUpDetails['ResponseData'];
  public customerSelected: string;
  Currency: Array<LookUpDetails['ResponseData']> = [];
  public CurrencyselectedItem: LookUpDetails['ResponseData'];
  GLList: Array<LookUpDetails["ResponseData"]> = [];
  public GLSelectedItem: LookUpDetails["ResponseData"];
  public Glcodeerror: string;
  public Yearid: any;
  public BRID: any;
  public HoldInvNo: any;
  public UID: any;
  public fldrCurrency: any;
  public sharedCustCode: any;
  public PostDate: Date = new Date();
  public CreateDateFrom = null;
  public CreateDateTo = null;
  public ATCreateDateFrom = "";
  public ATCreateDateTo = "";
  // public CURExRate:number;
  public GLCode: any;
  public Reference = '';
  public disabled = true;
  public curdisabled = true;
  public tabtitle = 'Cash Receipt';
  isShown = false;
  isVisible: any;
  public allowCustom = true;
  isShownGenerateBtn = false;
  isShownHoldBtn = false;
  isShownFilter: boolean = false;
  docCrncyAmntReadOnly: boolean = false;
  bankloaded = false;
  isBankShown = false;
  isGLShown = true;
  gridReceiptSum = 0;
  postButtonDisable: boolean = false;
  public TC_Allocated = 0;
  public TC_UnAllocated = 0;
  public HC_Allocated = 0;
  public HC_UnAllocated = 0;
  public gridfcreceiptsum = 0;
  public gridhcreceiptsum = 0;
  public confirmMsg = '';
  public HCamount: number;
  public Transaction: string;
  public ExchangeGainLossFlag = 0;
  public BankPayMethod = 0;
  public Balance: number;
  public Receipt: number;
  public TotBalance: 0;
  public fromMenu = false;
  public isreward:any=false;
  public Componentname: string;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  public ShowConfMsg = false;
  formGroup:any;
  public groupChange(groups: GroupDescriptor[]): void {
    this.groups = groups;
    this.view = groupBy(this.view, this.groups);

  }
  public Iscardvalid: boolean = false;
  public IsAuthvalid: boolean = false;
  public Isamtvalid: boolean = false;
  ngOnInit() {
    
    this.file.ValidChequeDate=false;
    this.file.chequeNovaild=false;
    this.file.PaymentReference=false;
    this.BRID = sessionStorage.getItem('LUBRID')
    var orderno = sessionStorage.getItem('orderNo');
    
    if (orderno != null)
      if (this.orderno !== orderno) {
        this.fromMenu = true;
        this.curdisabled = false;
        this.isShownGenerateBtn = true;
        this.isShownHoldBtn = false
      }
    this.isGLShown = false;
    this.folderEditMode = 'false';
    //this.apiShared.folderEditMode = this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.CustomerReceiptForm = this.formBuilder.group({

      PRTYCODE: ['', Validators.required],
      DocCurrencyAmount: [0],
      HCAmount: [0],
      CURExRate: [''],
      Reference: [''],
      FCAllocated: [0],
      FCUnAllocated: [0],
      HCAllocated: [0],
      HCUnAllocated: [0],
      FOLDERNO: [this.orderno],
      PostDate: [new Date],
      BankPayMethod: ['Bank Transfer'],
      BankCode: [''],
      DrawnbankName: ['test'],
      ChequeNo: [''],
      ChequeDate: [new Date],
      PaymentReference: [''],
      RewardPoint: [0],
    });


    this.renderer.listen(
      'document',
      'click',
      ({ target }) => {
        if (!isChildOf(target, 'k-grid')) {
          this.saveClick();
        }
      });
    this.subscription.add(this.lookupApi.GetAllLookup('BRANCHPETTYCASH').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.branchList = data["ResponseData"];
          if (this.BRID != '') {
            this.branchSelectedItem = this.branchList.find(item => item.Code == this.BRID);
          }
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('LANGUAGES').subscribe(
      (data) => {
        
        if (data != null && data != undefined) {
          if (data["ResponseData"] != null && data["ResponseData"] != undefined) {
            if (data["ResponseData"].length > 0) {
              this.Lannguage=data["ResponseData"][0].Code;
            }}}

      }))
    this.subscription.add(this.lookupApi.GetAllLookup('RECHELDINVOICENO').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.HeldInvoiceNoList = data["ResponseData"];
          if (this.HoldInvNo != '') {
            this.HeldInvoiceNoSelectedItem = this.HeldInvoiceNoList.find(item => item.UsrCode == this.HoldInvNo);
          }
        }
      }
    ));
    this.subscription.add(
      this.lookupApi.GetAllLookup("FilePriceDetails").subscribe(data => {
        debugger;
        if (data != null && data != undefined) {
          var ComapnyCurrencydata= data["ResponseData"][0];
          if (ComapnyCurrencydata !=undefined) {
            if(ComapnyCurrencydata.UsrCode==this.fldrCurrency)
            {
              this.balanceAmount=this.commonservice.balance;
             
              if(Number.isNaN(this.balanceAmount)||this.balanceAmount==undefined ||this.balanceAmount==null)
              {
                this.IsSameCurrency=false;
                this.balanceAmount=null;
              }
              else
              {
                this.IsSameCurrency=true;
              }
            
            }
          
          }
        }
      })
    );
    this.subscription.add(this.subscription.add(this.lookupApi.GetAllLookup('DirectPostingNominal').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.GLList = data["ResponseData"];
          if (this.GLCode != "") {
            this.GLSelectedItem = this.GLList.find(item => item.UsrCode == this.GLCode);
          }
        }
      }
    )));
    this.subscription.add(this.lookupApi.GetAllLookup('CUSTOMER').subscribe(
      (data) => {
        if (data != null && data != undefined) {

          this.CustomerList = data["ResponseData"];
          this.CustomerReceiptForm.controls.PRTYCODE.setValue(this.apiShared.CustomerCode);
          if (this.CustomerReceiptForm.controls.PRTYCODE.value != '') {
            this.customerSelectedItem = this.CustomerList.find(item => item.Code == this.CustomerReceiptForm.controls.PRTYCODE.value);
            this.customerSelected = this.customerSelectedItem.Name.toString();
          }
        }


        this.getFolderDefaultCustomer();
      }
    ));

    this.subscription.add(this.lookupApi.GetAllLookup('CURRENCY').subscribe(
      (data) => {

        if (data != null && data != undefined) {
          this.Currency = data["ResponseData"];
          if (this.fldrCurrency != '') {
            this.CurrencyselectedItem = this.Currency.find(item => item.Code == this.fldrCurrency);
          }
          this.getCurrencyExRate();
        }
      }
    ));
    if (this.orderno != "" && this.orderno != null && this.orderno != undefined) {


      this.getFolderDefaultCustomer();
      this.getFolderValues();
      this.getReceiptDetails(4, 1, this.orderno, '2019', this.fldrCurrency, this.sharedCustCode);

    }
    this.Componentname = "CustomerReceipts";
    // this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654
    sessionStorage.setItem('Componentname', this.Componentname);
    this.banktoggleShow();
  }


  getFolderValues() {

    this.date = (this.CustomerReceiptForm.controls.PostDate.value == null || this.CustomerReceiptForm.controls.PostDate.value == "" ? "01-Jan-1900" : this.CustomerReceiptForm.controls.PostDate.value);//Case id-337

    if (Boolean(this.commonservice.ValidDateRange(this.date)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    // this.fldrCurrency = this.apiShared.folderCurrency;
    //  this.BRID = this.apiShared.BRID;
    this.fldrCurrency = (sessionStorage.getItem('folderCurrency'));
    this.BRID = (sessionStorage.getItem('LUBRID'));
    this.CURID = this.fldrCurrency;
    this.getExchangeRate(this.fldrCurrency, this.PostDate);
    this.UID = (sessionStorage.getItem('uid'));
    //this.apiShared.Setapishareddata(this.UID,'uid');

  }

  getReceiptDetails(id: number, userid: number, folderno: string, yearid: string, FldCurr: string, FolderPartycode: number) {

    //     this.subscription.add(this.apiReceipt.GetCustomerReceiptDetails(id, userid, folderno, yearid, FldCurr).subscribe(res => {this.gridData = res["ResponseData"];

    //  if ( JSON.stringify(res["ResponseData"]) == 'null') {
    //           this.TotalRows = 0;
    //           } else {
    //           this.TotalRows = 1;
    //           this.view = (Array.of(this.gridData));
    //           }
    //     }));


    this.CustParmDTO = new CustomerReceiptRequest;
    this.CustParmDTO.Id = id;
    this.CustParmDTO.FolderNo = folderno;
    this.CustParmDTO.UserId = userid;
    this.CustParmDTO.CurrencyId = FldCurr;
    this.CustParmDTO.PartyCode = FolderPartycode;

    this.apiReceipt.formData = this.CustParmDTO;

    this.subscription.add(this.apiReceipt.GetOutsideCustomerReceiptDetails().
      subscribe(res => {

        if (res != null && res != undefined) {
          this.gridData = res["ResponseData"]

          if (JSON.stringify(res["ResponseData"]) == "null" || JSON.stringify(res["ResponseData"]) == "[]") {
            this.TotalRows = 0;
          }
          else {

            this.view = this.gridData;
            this.TotalRows = this.view.length;
            this.Receiptpostgrid = new Array<CustomerReceiptDetailXMLClass>(this.TotalRows);
          }
        }
        else {
          this.view = [];
          this.TotalRows = 0;

        }
      }));

  }
  public GLChange(value: any): void {
    //console.log('selectionChange', value);
    const BankCode = this.CustomerReceiptForm.get('BankCode');
    BankCode.setValidators([Validators.required]);
    BankCode.setValue(value.Code);
    BankCode.updateValueAndValidity();
    this.GLCode = (value.Code);
  }
  getFolderDefaultCustomer() {

    //  this.apiShared.CustomerCode.subscribe(CustomerCode => this.sharedCustCode=CustomerCode)   
    //this.sharedCustCode = this.apiShared.CustomerCode;
    this.sharedCustCode = (sessionStorage.getItem('CustomerCode'));

    if (this.sharedCustCode != '') {
      this.customerSelectedItem = this.CustomerList.find(item => item.Code == this.sharedCustCode);
      if (this.customerSelectedItem != null) {
        this.CustomerReceiptForm.controls.PRTYCODE.setValue(this.customerSelectedItem.Code.toString());
        this.customerSelected = this.customerSelectedItem.Name.toString();
      } else {
        this.customerSelected = '';
        this.CustomerReceiptForm.controls.PRTYCODE.setValue('');
      }
    }

  }

  public BranchValueChange(value: any): void {

if(value!=undefined)
{
  this.BRID = (value.Code);
}
else
{
  this.BRID ="";
}
  
  }
  public onHeldNoChange(value) {

    if (value.UsrCode != null) {
      this.HoldInvNo = value.UsrCode;
      this.RetriveHeldData();
    }
    else {
      this.HoldInvNo = value;

    }
  }
  public GLtoggleShow() {
    debugger;
    this.docCrncyAmntReadOnly = false;
    // const BankCode = this.SuppliePaymentForm.get('BankCode');
    // BankCode.setValidators([Validators.required]);

    // BankCode.updateValueAndValidity();
    this.isShown = false;
    this.isGLShown = true;
    this.isBankShown = false;
    this.bankloaded = false;
    this.tabtitle = "GL Receipt"
    this.ispdq=false;
    this.isreward=false;
     this.CalculateForm();
  }
  public cashtoggleShow() {
    debugger;
    this.docCrncyAmntReadOnly = false;
    this.isShown = false;
    this.isGLShown = false;
    this.isBankShown = false;
    this.bankloaded = false;
    this.tabtitle = 'Cash Receipt';
    this.ispdq=false;
    this.isreward=false;
    this.CalculateForm();
  }
  public banktoggleShow() {
    

    this.docCrncyAmntReadOnly = false;
    this.isBankShown = true;
    this.isShown = false;
    this.bankloaded = true;
    this.CustomerReceiptForm.controls.BankPayMethod.setValue('Bank Transfer');
    this.tabtitle = 'Bank Receipt';
    this.isGLShown = false;
    this.ispdq=false;
    this.isreward=false;
    this.CalculateForm();
  }
  public pdqtoggleShow() {
    this.isBankShown = false;
    this.isGLShown = false;
    this.isShown = false;
    this.bankloaded = false;
    this.docCrncyAmntReadOnly = true;
    this.tabtitle = 'PDQ/MOTO Receipt';
    this.isShownHoldBtn = false;
    this.isreward=false;
    this.openDialogFormModification();

  }
public RewardtoggleShow()
{
  this.docCrncyAmntReadOnly = false;
this.isreward=true;
    
    this.isShown = false;
    this.isGLShown = false;
    this.isBankShown = false;
    this.bankloaded = false;
    this.tabtitle = "Reward Points"
    this.ispdq=false;
    this.GetCarholderMembershipInfo();
    this.CalculateForm();
}
  creditCardfrmGrp: FormGroup;
  public openDialogForm() {
    const dialogRef = this.dialogService.open({
      content: CreditcardDetailsWindowComponent,
      title: 'Credit Card Details',
      // actions: [{ text: 'No' ,primary: false}, { text: 'Yes', primary: true }],
      width: 500,
      height: 650,

    });

    const userInfo = dialogRef.content.instance;

    if (this.creditCardfrmGrp != undefined) {
      //this.frm.controls.customerCode.setValue(this.customerSelectedItem.Code.toString());
      this.creditCardfrmGrp.controls.customerCode.setValue(this.apiShared.CustomerCode.toString());
      userInfo.formGroup = this.creditCardfrmGrp;

    }
    else {

      userInfo.custCo = this.apiShared.CustomerCode.toString();//this.customerSelectedItem.Code.toString()
      userInfo.crncyCode = this.apiShared.folderCurrency;
    }
    userInfo.balanceamt=this.balanceAmount;
    dialogRef.result.subscribe(r => {


      if (r["text"] == "OK") {
        this.creditCardfrmGrp = userInfo.formGroup as FormGroup;
        this.CustomerReceiptForm.controls.DocCurrencyAmount.setValue(this.creditCardfrmGrp.value.totalAmount);
        this.CustomerReceiptForm.controls.CURExRate.setValue(this.creditCardfrmGrp.value.exRate);
        this.CustomerReceiptForm.controls.PostDate.setValue(this.creditCardfrmGrp.value.dateC);

        this.DocumentCrncyAmntChange();//CalculateForm() ;
        //console.log(`Form: ${JSON.stringify(userInfo.formGroup.value)}`);
      }
    });
  }
  public openDialogFormModification() {
    

   // const userInfo = dialogRef.content.instance;

    if (this.creditCardfrmGrp != undefined) {
      //this.frm.controls.customerCode.setValue(this.customerSelectedItem.Code.toString());
      this.creditCardfrmGrp.controls.customerCode.setValue(this.apiShared.CustomerCode.toString());
      this.formGroup = this.creditCardfrmGrp;

    }
    else {
     
      this.custCo = +this.apiShared.CustomerCode;//this.customerSelectedItem.Code.toString()
      this.crncyCode = this.apiShared.folderCurrency.toString();
    }
    this.balanceamt=this.balanceAmount;
 


     
     
        this.ispdq=true;
    
  }
  setPDQMoTOformchange()
  {
  
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.formGroup = value;
        this.creditCardfrmGrp = this.formGroup as FormGroup;
        this.CustomerReceiptForm.controls.DocCurrencyAmount.setValue(this.creditCardfrmGrp.value.totalAmount);
        this.CustomerReceiptForm.controls.CURExRate.setValue(this.creditCardfrmGrp.value.exRate);
        this.CustomerReceiptForm.controls.PostDate.setValue(this.creditCardfrmGrp.value.dateC);

        this.DocumentCrncyAmntChange();//CalculateForm() ;
      }
    }
  }
  public CustomerValueChange(value: any): void {

    this.customerSelectedItem = this.CustomerList.find(item => item.Name == value);
    if (this.customerSelectedItem != null) {
      this.CustomerReceiptForm.controls.PRTYCODE.setValue(this.customerSelectedItem.Code.toString());
    } else {
      this.customerSelected = '';
      this.CustomerReceiptForm.controls.PRTYCODE.setValue('');
    }

  }
  public CurrencyChange(value: any): void {
    this.fldrCurrency = value.Code;
    this.CURID = value.Code;
    this.getCurrencyExRate();
  }
  public getCurrencyExRate() {

    this.date = (this.CustomerReceiptForm.controls.PostDate.value == null || this.CustomerReceiptForm.controls.PostDate.value == "" ? "01-Jan-1900" : this.CustomerReceiptForm.controls.PostDate.value);//Case id-337

    if (Boolean(this.commonservice.ValidDateRange(this.date)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }

    this.getExchangeRate(this.fldrCurrency, this.date);

  }

  getExchangeRate(currency, postingdate) {

    postingdate = new Date(postingdate).toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    postingdate = postingdate.replace('Sept', 'Sep')
    this.subscription.add(this.apiExRate.getExchangeRate(currency, postingdate)
      .subscribe(data => {
        if (data != null && data != undefined) {
          this.currencyExrate = data;
          this.ExRate= this.CustomerReceiptForm.controls.CURExRate.value;
          this.CustomerReceiptForm.controls.CURExRate.setValue(this.currencyExrate.ResponseData.EXRATE);
        }
      }));

  }





  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.createFormGroup = createFormGroup({

      //   AGAINST  : 0,
      //   VRDATE: '',
      //   ORDNO : '',
      //   VRREF : '',
      //   FC_AMOUNT : 0,
      //   FC_BALANCE : 0,
      //   VRTYPE : 0,
      //   YEARID : '',
      //   PROJNO : '',
      //   ASSIGN  : '',
      //   BRID  : '',
      //   Reference : '',
      //   AMOUNT: 0,
      //   BALANCE : 0,
      //  CUSTOMER : '',
      //  CUSTOMERBALANCE : 0,
      //  Receipt : 0,
      //  FCReceipt : 0
      AGAINST: 0,
      VRDATE: '',
      ORDNO: '',
      VRREF: '',
      FC_AMOUNT: 0,
      FC_BALANCE: 0,
      VRTYPE: 0,
      YEARID: '',
      PROJNO: '',
      ASSIGN: '',
      BRID: '',
      Reference: '',
      AMOUNT: 0,
      BALANCE: 0,
      TempHCBALANCE: 0,
      CUSTOMER: '',
      CUSTOMERBALANCE: '',
      Receipt: 0,
      FCReceipt: 0,
      Select: 0
    });
    this.isNew = true;

    this.grid.addRow(this.createFormGroup);
  }
  public cancelHandler(sender): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  public editClick({ dataItem, rowIndex, columnIndex }: any): void {
    
    //this.createFormGroup.=dataItem
    this.editHandler(
      dataItem,
      rowIndex,
       columnIndex,
       this.grid
    );
  }

  public saveClick(): void {
    if (this.createFormGroup && !this.createFormGroup.valid) {
      return;
    }

    this.saveRow();
  }
  public editHandler( sender,rowIndex, colIndex,  sendn ): void {
    if (this.createFormGroup && !this.createFormGroup.valid) {
      return;
    }
    if((colIndex== 5 )&& (sender.FC_BALANCE!=null &&sender.FC_BALANCE!=undefined))
    {
      sender.FCReceipt=sender.FC_BALANCE;
    }
    this.createFormGroup = createFormGroup(sender);
   
    this.saveRow();
   // this.createFormGroup = createFormGroup(dataItem);

    this.editedRowIndex = rowIndex;
    this.editedcolIndex = colIndex;
    this.createFormGroup = createFormGroup(sender);
    sendn.editRow(rowIndex, this.createFormGroup);
    setTimeout(() => {
      (document.querySelector(`.k-grid-edit-row > td:nth-child(${colIndex + 1}) input`) as HTMLElement)
        .focus();
    });
  }
  private closeEditor(grid: GridComponent, rowIndex: number = this.editedRowIndex): void {
    this.gridReceiptSum = 0;
    if (this.editedRowIndex !== undefined) {
      if (grid.data[rowIndex] != null) {
        this.view[0].FCReceipt = parseFloat(this.view[0].FCReceipt.toString()).toFixed(2)

        //this.intl.formatNumber(  this.view[0].FCReceipt, this.commonservice.DecimalCount)
        this.CalculateForm();



      }
    }
    this.isNew = false;
    if(grid!=undefined && grid.closeRow!=undefined)
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.createFormGroup = undefined;
  }
  onDblClick(e) {
  
    console.log('Double click, data item: ');
  }

  private saveRow(): void {
  
    if (this.isInEditingMode) {
      this.save(this.createFormGroup.value, false);

    }

    this.closeEditor(this.grid);
  }


  public save(formreceipt: any, isNew: boolean): void {

    if (this.TotalRows > 0) {

      {
        Object.assign(
          this.view.find(({ AGAINST }) => AGAINST === formreceipt.AGAINST),
          formreceipt
        );
      }
    }
  }
  public GetCurrencyExchangeRateTolerance()
  {
    
    this.spinner.show();
    this.commonservice.excformData = new BankPaymentDTO();
    
    this.commonservice.excformData.PVRDATE = this.commonservice.ConvertToLocaleDate(this.CustomerReceiptForm.controls.PostDate.value);
    this.commonservice.excformData.date = this.commonservice.ConvertToLocaleDate(new Date())
   
    this.commonservice.excformData.HCID = this.fldrCurrency;
    this.commonservice.excformData.FCID = this.fldrCurrency;
    this.commonservice.excformData.RXRATE = this.CustomerReceiptForm.controls.CURExRate.value;

    this.commonservice.excformData.LANQUAGE = this.Lannguage;
    this.subscription.add( this.commonservice.GetCurrencyExchangeRateTolerance().subscribe(x => {
      this.spinner.hide();
      if (x != null && x != undefined) {
        this.spinner.hide();
        
        if (x["ResponseData"].messageid== 0) {
        //  this.ExRate =this.ExRate ;
         // this.CalculateAll();
         this.DocumentCrncyAmntChange();
        }
        else if (x["ResponseData"].messageid> 0 ) {
          
          var msg = 'Messages.msg' + x["ResponseData"].messageid;
          this.commonservice.showWARNINGtoastrmsg(x["ResponseData"].message, 3000, true, false);
         this.CustomerReceiptForm.controls.CURExRate.setValue(this.ExRate);
         this.DocumentCrncyAmntChange();
        }
      }
    })
    )
  }
  public DocumentCrncyAmntChange() {
   
    if (this.TotalRows > 0) {

      
        if (this.CustomerReceiptForm.get('DocCurrencyAmount').value != "" && this.CustomerReceiptForm.get('DocCurrencyAmount').value != undefined && this.CustomerReceiptForm.get('DocCurrencyAmount').value != null) {
          if (this.tabtitle == "PDQ/MOTO Receipt") {

            if (this.creditCardfrmGrp != undefined)
            {
             if(this.creditCardfrmGrp.controls.invoiceAmount.value==0||this.creditCardfrmGrp.controls.invoiceAmount.value==null)
             this.creditCardfrmGrp.controls.invoiceAmount.setValue(this.CustomerReceiptForm.get('DocCurrencyAmount').value);
              this.view[0].FCReceipt = this.creditCardfrmGrp.value.invoiceAmount;
            }
            

          }
          else
            this.view[0].FCReceipt = this.CustomerReceiptForm.get('DocCurrencyAmount').value;

        }
      
    }

    this.CalculateForm();
  }
  ccCharge: number;
  homeCcCharge: number;
  public CalculateForm() {

    this.HCamount = parseFloat((this.CustomerReceiptForm.get('DocCurrencyAmount').value * this.CustomerReceiptForm.get('CURExRate').value).toFixed(2));
    this.CustomerReceiptForm.controls.HCAmount.setValue(this.HCamount);
debugger;
    if (this.TotalRows > 0) {

      if (this.TotalRows >0) {



        // if(this.CustomerReceiptForm.get('DocCurrencyAmount').value!="" && this.CustomerReceiptForm.get('DocCurrencyAmount').value!=undefined && this.CustomerReceiptForm.get('DocCurrencyAmount').value!=null)  
        //  {

        //   if(this.tabtitle=="PDQ/MOTO Receipt")
        //   {
        //     if(this.creditCardfrmGrp!=undefined)
        //     this.view[0].FCReceipt =  this.creditCardfrmGrp.value.invoiceAmount
        //   }
        //   else
        //   this.view[0].FCReceipt = this.CustomerReceiptForm.get('DocCurrencyAmount').value;
        // }    
        this.view[0].Receipt = this.view[0].FCReceipt * this.CustomerReceiptForm.get('CURExRate').value;

      }
      this.stringifiedData = this.grid.data as any[];
      let fcsum = 0;
      let hcsum = 0;
      for (let i = 0; i < this.stringifiedData.length; i++) {

        fcsum += +this.stringifiedData[i].FCReceipt;
        hcsum += +this.stringifiedData[i].Receipt;
        this.view[i].Receipt = this.view[i].FCReceipt * this.CustomerReceiptForm.get('CURExRate').value;

      }

      this.gridfcreceiptsum = fcsum;
      this.gridhcreceiptsum = hcsum;
    } else {
      this.gridfcreceiptsum = 0;
      this.gridhcreceiptsum = 0;
    }
    this.ccCharge = 0.00;
    this.homeCcCharge = 0.00;
    if (this.tabtitle == "PDQ/MOTO Receipt") {
      if (this.creditCardfrmGrp != undefined) {
        if (this.creditCardfrmGrp.value != undefined)
          this.ccCharge = this.creditCardfrmGrp.value.ccCharge;
        this.homeCcCharge = parseFloat((this.ccCharge * this.CustomerReceiptForm.get('CURExRate').value).toFixed(2));
      }
    }

    this.TC_UnAllocated = this.CustomerReceiptForm.get('DocCurrencyAmount').value - this.gridfcreceiptsum - this.ccCharge;

    this.TC_Allocated = this.gridfcreceiptsum;
    this.HC_UnAllocated = this.CustomerReceiptForm.get('HCAmount').value - this.gridhcreceiptsum - this.homeCcCharge;
    this.HC_Allocated = this.gridhcreceiptsum;
  
    this.CustomerReceiptForm.controls.HCAllocated.setValue(this.HC_Allocated);
    this.CustomerReceiptForm.controls.HCUnAllocated.setValue(this.HC_UnAllocated);
    if (this.tabtitle == "PDQ/MOTO Receipt") {
  this.CustomerReceiptForm.controls.FCAllocated.setValue(this.TC_UnAllocated);
  this.CustomerReceiptForm.controls.FCUnAllocated.setValue(this.TC_Allocated);
    }
    else
    {
      this.CustomerReceiptForm.controls.FCAllocated.setValue(this.TC_Allocated);
      this.CustomerReceiptForm.controls.FCUnAllocated.setValue(this.TC_UnAllocated);
    }
  }
  public getBankpaymentmethod() {
    if (this.CustomerReceiptForm.get('BankPayMethod').value == 'Cheque') {
      this.BankPayMethod = 0;
    } else if (this.CustomerReceiptForm.get('BankPayMethod').value == 'Bacs') {
      this.BankPayMethod = 1;
    } else if (this.CustomerReceiptForm.get('BankPayMethod').value == 'Bank Transfer') {
      this.BankPayMethod = 2;
    } else if (this.CustomerReceiptForm.get('BankPayMethod').value == 'Others') {
      this.BankPayMethod = 3;
    } else if (this.CustomerReceiptForm.get('BankPayMethod').value == 'Direct Debit') {
      this.BankPayMethod = 4;
    } else if (this.CustomerReceiptForm.get('BankPayMethod').value == 'Debit Card') {
      this.BankPayMethod = 5;
    } else {
      this.BankPayMethod = 2;
    }


  }
  public   ValidateCheque(): boolean 
{
  this.file.chequeNovaild=false;
  this.file.PaymentReference=false;
 var paymntMethod= this.CustomerReceiptForm.controls.BankPayMethod.value;
  if(paymntMethod=='Cheque')
  {
    if(this.CustomerReceiptForm.controls.ChequeNo.value==undefined||this.CustomerReceiptForm.controls.ChequeNo.value==null||this.CustomerReceiptForm.controls.ChequeNo.value=='')
    {
     this.file.chequeNovaild=true;
    }

    if(this.CustomerReceiptForm.controls.PaymentReference.value==undefined||this.CustomerReceiptForm.controls.PaymentReference.value==null||this.CustomerReceiptForm.controls.PaymentReference.value=='')
    {
     this.file.PaymentReference=true;
    }
    var chequedate = (this.CustomerReceiptForm.controls.ChequeDate.value == null || this.CustomerReceiptForm.controls.ChequeDate.value == "" ? "01-Jan-1900" : this.CustomerReceiptForm.controls.ChequeDate.value);//Case id-337

   
    if (Boolean(this.commonservice.ValidDateRange(chequedate)) == false ||chequedate=='01-Jan-1900')//Case id-337
    {
     this.isShown = false;
    // this.postButtonDisable = false;
      this.file.ValidChequeDate=true;
   
      
    }
    else{
      this.file.ValidChequeDate=false;
    }
 if(this.file.ValidChequeDate==true|| this.file.chequeNovaild==true||this.file.PaymentReference==true)
 {
   this.isShown = false;
 return false;
 }
 else
{
  this.file.ValidChequeDate=false;
  this.file.chequeNovaild=false;
  this.file.PaymentReference=false;
  return true;;
}
}
else if(paymntMethod=='Bacs')
{
  var chequedate = (this.CustomerReceiptForm.controls.ChequeDate.value == null || this.CustomerReceiptForm.controls.ChequeDate.value == "" ? "01-Jan-1900" : this.CustomerReceiptForm.controls.ChequeDate.value);//Case id-337

   
  if (Boolean(this.commonservice.ValidDateRange(chequedate)) == false ||chequedate=='01-Jan-1900')//Case id-337
  {
   this.isShown = false;
  // this.postButtonDisable = false;
    this.file.ValidChequeDate=true;
 
    
  }
  else{
    this.file.ValidChequeDate=false;
  }
if(this.file.ValidChequeDate==true)
{
 this.isShown = false;
return false;
}
else
{
this.file.ValidChequeDate=false;

return true;;
}
}
}
  public SaveOrCheckUnallocatedOrNot()
 {
   

    this.file.chequeNovaild=false;
    this.file.PaymentReference=false;
    if (this.tabtitle == "Bank Receipt") {
    var paymntMethod= this.CustomerReceiptForm.controls.BankPayMethod.value;
    if(paymntMethod=='Cheque'||paymntMethod=='Bacs')
    {
     if(this.ValidateCheque()==false)
     { 
      return false;
     }
  }
  if(this.CustomerReceiptForm.controls.BankCode.value==''||this.CustomerReceiptForm.controls.BankCode.value==undefined||this.CustomerReceiptForm.controls.BankCode.value==null)
  {
    this.file.ValidBankcode=true;
    return false;
  }
  else
  {
    this.file.ValidBankcode=false;
  }
}
if (this.tabtitle == "PDQ/MOTO Receipt" )
{
 
  if( this.formGroup.controls.invalidpdqNo.value==true|| this.formGroup.controls.Iscardvalid.value==true||this.formGroup.controls.IsAuthvalid.value==true|| this.formGroup.controls.Isamtvalid.value==true )
  {
    return;
  }
}
 if (this.tabtitle == "PDQ/MOTO Receipt" && this.CustomerReceiptForm.get("FCAllocated").value >0) {
  this.AdvancePay = true;
}else  if (this.tabtitle != "PDQ/MOTO Receipt" && this.CustomerReceiptForm.get("FCUnAllocated").value >0)  {
    this.AdvancePay = true;
   // this.postButtonDisable = false;
    return false;
  }
  else
  {
    this.onPost();
  }
 }
 AdvancePayCheck(AdvancePay: boolean): void {
  if (AdvancePay == true) {
 
    this.onPost();
    this.AdvancePay = null;
  } else {
    this.AdvancePay = null;
  }
}
  public async onPost() {
    
    debugger;
    this.spinner.show();
    if(this.isreward){
      if(this.Membership_No==undefined ||this.Membership_No==null ||this.Membership_No=='')
      {
        this.commonservice.showWARNINGtoastrmsg("Messages.Membershipnomandatorty", 2000, true, false);
        this.spinner.hide();
        return;
      }
      
     
    
    }
  
    this.postButtonDisable = true;
    this.date = (this.CustomerReceiptForm.controls.PostDate.value == null || this.CustomerReceiptForm.controls.PostDate.value == "" ? "01-Jan-1900" : this.CustomerReceiptForm.controls.PostDate.value);//Case id-337

    
    if (Boolean(await this.commonservice.checkPermission1('02.07.04')) == true) {
      if (Boolean(this.commonservice.ValidDateRange(this.date)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidDate'];
          this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        this.postButtonDisable = false;
        this.spinner.hide();
        return;
      }
      if (this.fldrCurrency == "" || this.fldrCurrency == null || this.fldrCurrency == undefined) {
        this.subscription.add(this.translateapi.get(['Messages.CurrencyIsMandatory']).subscribe((translations) => {
          this.showMsg = translations['Messages.CurrencyIsMandatory'];
          this.toastr.warning(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        this.spinner.hide();
        this.postButtonDisable = false;
        // this.common.showFlash1('Messages.fromdate_exceeds','bg-danger text-white',2000);
        return;
      }
      else if (this.CustomerReceiptForm.get('CURExRate').value == null || this.CustomerReceiptForm.get('CURExRate').value == "" || this.CustomerReceiptForm.get('CURExRate').value == undefined) {
        this.subscription.add(this.translateapi.get(['Messages.InvalidExchangeRate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidExchangeRate'];
          this.toastr.warning(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        this.spinner.hide();
        this.postButtonDisable = false;
        return;
      }
      
      else if (this.view!=null && this.view.length>0&&this.TC_Allocated > this.CustomerReceiptForm.get('DocCurrencyAmount').value) {
        this.subscription.add(this.translateapi.get(['Messages.customerReceiptPostValidationMsg1']).subscribe((translations) => {
          this.showMsg = translations['Messages.customerReceiptPostValidationMsg1'];
          this.toastr.warning(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        this.spinner.hide();
        this.postButtonDisable = false;
        return;

      }
      debugger;
      if (this.tabtitle == "GL Receipt")
{
  if(this.GLCode==undefined || this.GLCode==null||this.GLCode=='')
  {
    this.commonservice.showWARNINGtoastrmsg('Messages.SelectGLCode', 3000, true, false);
    
    this.spinner.hide();
    this.postButtonDisable = false;
    return;
  } 
 
}
      if (this.tabtitle == 'GL Receipt') {
        this.CustomerReceiptPost.BankCode = (this.CustomerReceiptForm.get('BankCode').value) == '' ? 0 : (this.CustomerReceiptForm.get('BankCode').value);

        if (this.CustomerReceiptPost.BankCode == 0 || this.CustomerReceiptPost.BankCode == undefined) {
          this.commonservice.showWARNINGtoastrmsg('Messages.Invalid_GLCode', 2000, true, false);
          this.isShown = false;
          this.spinner.hide();
          this.postButtonDisable = false;

          return;
        }

      }
      else if (this.tabtitle == "Bank Receipt") {
        this.CustomerReceiptPost.BankCode = (this.CustomerReceiptForm.get('BankCode').value) == '' ? 0 : (this.CustomerReceiptForm.get('BankCode').value);
        if (this.CustomerReceiptPost.BankCode == 0 || this.CustomerReceiptPost.BankCode == undefined) {
          this.commonservice.showWARNINGtoastrmsg('Messages.Invalid_BankCode', 2000, true, false);
          this.isShown = false;
          this.spinner.hide();
          this.postButtonDisable = false;
          return;
        }
      }
      else if (this.tabtitle == "PDQ/MOTO Receipt") {
    // this.CustomerReceiptForm.controls.FCAllocated.setValue(this.TC_Allocated);
    //this.CustomerReceiptForm.controls.FCUnAllocated.setValue(this.TC_UnAllocated);
        if (this.creditCardfrmGrp == undefined) {

          this.commonservice.showWARNINGtoastrmsg('Messages.Invalid_CardDetails', 2000, true, false);
          this.isShown = false;
          this.spinner.hide();
          this.postButtonDisable = false;
          return;
        }
      }
    
 
      if (this.CustomerReceiptForm.invalid) {

        this.isShown = false;
        this.spinner.hide();
        this.postButtonDisable = false;
        return;
      }





      this.CustomerReceiptPost = new CustomerReceiptPost();
      if (this.orderno != "" && this.orderno != undefined) {

        this.submitted = true;
        //  if( this.tabtitle=="Bank Receipt")
        //  this.submittedBank=true;
        this.isShown = false;

        this.CustomerReceiptPost.Id = 1;
        if (this.tabtitle == "PDQ/MOTO Receipt") {
          this.CustomerReceiptPost.TotalAmount = this.creditCardfrmGrp.value.totalAmount;
        }
        else
          this.CustomerReceiptPost.TotalAmount = this.CustomerReceiptForm.get('HCAmount').value;
        this.CustomerReceiptPost.BRID = this.BRID;
        this.CustomerReceiptPost.UID = this.UID;

        if (this.tabtitle == 'Cash Receipt') {
          this.Transaction = 'Cash';
        }
        else if (this.tabtitle == 'GL Receipt') {
          this.Transaction = 'GL';
        }
        else if (this.tabtitle == "PDQ/MOTO Receipt") {
          this.Transaction = 'PdqMoto';
        }
         else if (this.tabtitle == "Reward Points") {
          this.Transaction = 'Reward';
        }
        else {
          this.Transaction = 'Bank';
        }


        if (this.Transaction == 'PdqMoto' && this.creditCardfrmGrp != undefined) {
          if(this.creditCardfrmGrp.controls.dateC.value == null || this.creditCardfrmGrp.controls.dateC.value == ""){
            this.commonservice.showWARNINGtoastrmsg("Messages.msg155",5000, true, false)
            this.spinner.hide();
            return;
          }
          this.CustomerReceiptPost.cardno = this.creditCardfrmGrp.value.pdqNo;
          this.CustomerReceiptPost.cardid = this.creditCardfrmGrp.value.cardType;
          this.CustomerReceiptPost.cardNumber = this.creditCardfrmGrp.value.cardnumber;
          this.CustomerReceiptPost.ValidFrom = this.commonservice.ConvertToLocaleDate(this.creditCardfrmGrp.value.dateC);
          this.CustomerReceiptPost.validTo = this.commonservice.ConvertToLocaleDate(this.creditCardfrmGrp.value.dateC);
          this.CustomerReceiptPost.IssueNo = "";
          this.CustomerReceiptPost.SecurityCode = "";
          this.CustomerReceiptPost.CommissionAmount = this.creditCardfrmGrp.value.pdQCharge;
          this.CustomerReceiptPost.CreditCardAmount = this.creditCardfrmGrp.value.ccCharge;
          this.CustomerReceiptPost.CreditPer = this.creditCardfrmGrp.value.ccRate;
          this.CustomerReceiptPost.creditTotalAmount = this.creditCardfrmGrp.value.totalAmount;
          this.CustomerReceiptPost.invoiceAmount = this.creditCardfrmGrp.value.invoiceAmount;
          this.CustomerReceiptPost.AuthorizationCode = this.creditCardfrmGrp.value.authCode;
          this.CustomerReceiptPost.UniqueReference = this.creditCardfrmGrp.value.uniquRef;


        }
        this.CustomerReceiptPost.TransactionType = this.Transaction;
        this.CustomerReceiptPost.CustCode = this.CustomerReceiptForm.get('PRTYCODE').value;
        this.CustomerReceiptPost.Reference = this.CustomerReceiptForm.get('Reference').value;
        this.CustomerReceiptPost.FolderNo = this.orderno;
        // this.CustomerReceiptPost.PostDate =  new Date(this.CustomerReceiptForm.get('PostDate').value).toLocaleDateString('en-GB', {
        //   day: 'numeric', month: 'short', year: 'numeric'
        // }).replace(/ /g, '-');
        this.CustomerReceiptPost.PostDate = this.commonservice.transformDate(new Date(this.CustomerReceiptForm.get('PostDate').value))

        // this.CustomerReceiptForm.get('PostDate').value;

        //  new Date (new Date(this.CustomerReceiptForm.get('PostDate').value).toLocaleDateString('en-GB', {
        //   day: 'numeric', month: 'short', year: 'numeric'
        // }).replace(/ /g, '-'));

        this.CustomerReceiptPost.FCURID == this.fldrCurrency;
        this.CustomerReceiptPost.ForeignCurrencAmt = this.CustomerReceiptForm.get('DocCurrencyAmount').value;
        this.CustomerReceiptPost.ExRate = this.CustomerReceiptForm.get('CURExRate').value;
        this.CustomerReceiptPost.VRTYPE = 0;
        this.ExchangeGainLossFlag = 0;
        if (this.TotalRows > 0) {

          this.FCAmalaneSum=0;
          for (var i = 0; i < this.TotalRows; i++) {
            this.FCAmalaneSum=this.FCAmalaneSum+this.view[i].FC_BALANCE;
            this.CustomerReceiptPost.HCBalance = this.view[i].BALANCE;
            this.CustomerReceiptPost.ReceiptAmt = this.view[i].Receipt;
          }
          this.CustomerReceiptPost.INVNO = this.view[0].AGAINST;
          this.CustomerReceiptPost.AVRTYPE = this.view[0].VRTYPE;
          this.CustomerReceiptPost.AYEARID = this.view[0].YEARID;
          // this.CustomerReceiptPost.HCBalance = this.view[0].BALANCE;
          // this.CustomerReceiptPost.ReceiptAmt = this.view[0].Receipt;
          // if (this.view[0].FC_BALANCE == this.CustomerReceiptForm.get('FCAllocated').value)
          // this.ExchangeGainLossFlag = 1;
          // else
          // this.ExchangeGainLossFlag = 0;
        }
        
        this.CustomerReceiptPost.AllocatedAmt = this.CustomerReceiptForm.get('HCAllocated').value;
        this.CustomerReceiptPost.UnAllocatedAmt = this.CustomerReceiptForm.get('HCUnAllocated').value;
        this.CustomerReceiptPost.FCAllocatedAmt = this.CustomerReceiptForm.get('FCAllocated').value;
        this.CustomerReceiptPost.FCUnAllocatedAmt = this.CustomerReceiptForm.get('FCUnAllocated').value;
       debugger;
        if (this.tabtitle == "PDQ/MOTO Receipt") {
          if(this.FCAmalaneSum==this.CustomerReceiptPost.FCUnAllocatedAmt)
          {
            this.ExchangeGainLossFlag = 1;
          }
        }
       else if(this.FCAmalaneSum==this.CustomerReceiptPost.FCAllocatedAmt)
        {
          this.ExchangeGainLossFlag = 1;
        }
        else
        {
         this.ExchangeGainLossFlag = 0;  
        }
        this.getBankpaymentmethod();

        this.CustomerReceiptPost.BankPayMethod = this.BankPayMethod;
        this.CustomerReceiptPost.BankCode = (this.CustomerReceiptForm.get('BankCode').value) == '' ? 0 : (this.CustomerReceiptForm.get('BankCode').value);
        // // this.CustomerReceiptPost.ChequeDate = this.CustomerReceiptForm.get('ChequeDate').value.toLocaleDateString('en-GB', {
        // //   day: 'numeric', month: 'short', year: 'numeric'
        // // }).replace(/ /g, '-');;
        this.CustomerReceiptPost.ChequeDate = this.commonservice.transformDate(this.CustomerReceiptForm.get('ChequeDate').value)
        this.CustomerReceiptPost.ChequeNo = this.CustomerReceiptForm.get('ChequeNo').value;
        this.CustomerReceiptPost.DrawnbankName = this.CustomerReceiptForm.get('PaymentReference').value;



        this.CustomerReceiptPost.ExchangeGainLossFlag = this.ExchangeGainLossFlag;
        this.CustomerReceiptPost.FCURID = this.fldrCurrency;
        if (this.tabtitle == "Reward Points") {
        this.CustomerReceiptPost.RewardPoint = this.CustomerReceiptForm.get('RewardPoint').value;
        this.CustomerReceiptPost.Rate=this.ConversionRate;
        this.CustomerReceiptPost.Membershipno=this.Membership_No;
        this.CustomerReceiptPost.Cardholder=this.CardHolder;
      }
        else
        {
          this.CustomerReceiptPost.RewardPoint =0;
        }
        this.subscription.add(this.apiReceipt.PostCustomerReceiptDetails(this.CustomerReceiptPost)
          .subscribe(res => {
            this.postButtonDisable=false;
            this.spinner.hide();
          
            if (res != null && res != undefined) {
             
              this.CustomerPostResult = res;
              this.isShown = false;

              if (this.CustomerPostResult["ResponseData"].VRNO > 0) {

                this.confirmMsg = this.CustomerPostResult["ResponseData"].Message;
                this.ShowConfMsg = true;


              }
              else {
                this.postButtonDisable=false;
                this.commonservice.showWARNINGtoastrmsg(this.CustomerPostResult["ResponseData"].Message, 5000, true, false);
              }
            }
          }, (err) => {
            this.spinner.hide();
            this.postButtonDisable=false;
            console.log(err);
            alert('Failed!! :-)\n\n' + err + '\n\n' + JSON.stringify(this.CustomerPostResult.Message, null, 4));
          }
          ));

      }
      else {
        this.submitted = true;

        this.isShown = false;

        this.date = (this.CustomerReceiptForm.controls.PostDate.value == null || this.CustomerReceiptForm.controls.PostDate.value == "" ? "01-Jan-1900" : this.CustomerReceiptForm.controls.PostDate.value);//Case id-337
        this.date = this.commonservice.ConvertToLocaleDate(new Date(this.date));
        var todaysDate = this.commonservice.ConvertToLocaleDate(new Date());
        var postDate = this.commonservice.ConvertToLocaleDate(new Date(this.date));

        
        if (postDate < todaysDate) {

          if (Boolean(await this.commonservice.checkPermission1("02.07.03")) == true) {

            this.date = this.commonservice.ConvertToLocaleDate(new Date(this.date));
          }
          else {
            this.spinner.hide();
            this.ShowPermissionMessageForm("02.07.03");
            this.postButtonDisable = false;
            return;

          }

        }
        
        if (this.TotalRows > 0) {
          for (var i = 0; i < this.TotalRows; i++) {
            if (this.view[i].FCReceipt != 0) {
              if (this.view[i].FCReceipt != this.view[i].FC_BALANCE)
                this.TotBalance += this.view[i].Receipt;
              else
                this.TotBalance += this.view[i].TempHCBALANCE
            }
          }
        }
        for (var i = 0; i < this.TotalRows; i++) {
          this.Balance = this.view[i].FC_BALANCE;
          this.Receipt = this.view[i].FCReceipt;
          if (this.Balance == this.Receipt) {
            this.ExchangeGainLossFlag = 1;
            i = this.TotalRows;
          }
        }

        this.CustomerReceiptPost.Id = 1;
        this.CustomerReceiptPost.CustCode = this.CustomerReceiptForm.get('PRTYCODE').value;
        this.CustomerReceiptPost.FolderNo = "";//this.orderno;    "" 
        //this.CustomerReceiptPost.Assign=this.CustomerReceiptForm.get('ASSIGN').value;
        //this.CustomerReceiptPost.HCAmount=this.CustomerReceiptForm.get('DocCurrencyAmount').value*this.CustomerReceiptForm.get('CURExRate').value;
        this.CustomerReceiptPost.ForeignCurrencAmt = this.CustomerReceiptForm.get('DocCurrencyAmount').value;
        //this.CustomerReceiptPost.AllocatedAmount=this.CustomerReceiptForm.get('HCAllocated').value;  
        this.CustomerReceiptPost.Reference = this.CustomerReceiptForm.get('Reference').value;
        this.CustomerReceiptPost.ReceiptAmt = 0;
        // this.CustomerReceiptPost.FCReceiptAmt=0;
        if (this.tabtitle == 'Cash Receipt') {
          this.Transaction = 'Cash';
        }
        else if (this.tabtitle == 'GL Receipt') {
          this.Transaction = 'GL';
          this.CustomerReceiptPost.BankPayMethod = 0;
          this.CustomerReceiptPost.BankCode = this.GLCode;
        }
        else {
          this.Transaction = 'Bank';
        }
        this.CustomerReceiptPost.TransactionType = this.Transaction;
        //  this.CustomerReceiptPost.PostDate=  new Date(this.CustomerReceiptForm.get('PostDate').value).toLocaleDateString('en-GB', {
        //   day: 'numeric', month: 'short', year: 'numeric'
        // }).replace(/ /g, '-');
        this.CustomerReceiptPost.PostDate = this.date;

        // this.CustomerPaymentForm.get('PostDate').value; 
        this.CustomerReceiptPost.BRID = this.BRID;
        this.CustomerReceiptPost.UID = 1;
        this.CustomerReceiptPost.FCURID = this.fldrCurrency;
        this.CustomerReceiptPost.ExRate = this.CustomerReceiptForm.get('CURExRate').value;;
        this.CustomerReceiptPost.HCBalance = this.TotBalance;
        this.CustomerReceiptPost.ExchangeGainLossFlag = this.ExchangeGainLossFlag;
        if (this.bankloaded == true) {
          this.getBankpaymentmethod();

          this.CustomerReceiptPost.BankPayMethod = this.BankPayMethod;
          this.CustomerReceiptPost.BankCode = (this.CustomerReceiptForm.get('BankCode').value) == "" ? 0 : (this.CustomerReceiptForm.get('BankCode').value);
          //  this.CustomerReceiptPost.ChequeDate=this.CustomerReceiptForm.get('ChequeDate').value.toLocaleDateString('en-GB', {
          //   day: 'numeric', month: 'short', year: 'numeric'
          // }).replace(/ /g, '-');
          this.CustomerReceiptPost.ChequeDate = this.commonservice.transformDate(this.CustomerReceiptForm.get('ChequeDate').value);

          this.CustomerReceiptPost.ChequeNo = this.CustomerReceiptForm.get('ChequeNo').value;
          this.CustomerReceiptPost.DrawnbankName = this.CustomerReceiptForm.get('PaymentReference').value;

        }

        if (this.TotalRows > 0) {


          for (var i = 0; i < this.TotalRows; i++) {
            this.FCAmalaneSum=this.FCAmalaneSum+this.view[i].FC_BALANCE;
            if (this.view[i].FC_BALANCE == this.view[i].FCReceipt)
              this.ExchangeGainLossFlag = 1;
            else
              this.ExchangeGainLossFlag = 0;
            this.ReceiptPostGridParameter = new CustomerReceiptDetailXMLClass();
            this.ReceiptPostGridParameter.ReceiptAmount = this.view[i].Receipt;
            this.ReceiptPostGridParameter.FCReceiptAmount = this.view[i].FCReceipt;
            if (this.view[i].FCReceipt != 0) {
              if (this.view[i].FCReceipt != this.view[i].FC_BALANCE)
                this.ReceiptPostGridParameter.HCBalance = this.view[i].Receipt;
              else
                this.ReceiptPostGridParameter.HCBalance = this.view[i].TempHCBALANCE
            }

            this.ReceiptPostGridParameter.AVRTYPE = this.view[i].VRTYPE;
            this.ReceiptPostGridParameter.AYEARID = this.view[i].YEARID;
            this.ReceiptPostGridParameter.ASSIGN = this.view[i].ASSIGN;
            this.ReceiptPostGridParameter.AVRNO = this.view[i].AGAINST;
            this.ReceiptPostGridParameter.PROJNO = this.view[i].PROJNO;
            this.ReceiptPostGridParameter.ExchangeGainFlag = this.ExchangeGainLossFlag;
            this.Receiptpostgrid[i] = this.ReceiptPostGridParameter;
          }

          this.CustomerReceiptPost.CustomerReceiptDetailXMLClass = this.Receiptpostgrid;
        }

        this.isShown = false;
        this.isBankShown = false;
        this.bankloaded = false;

        this.subscription.add(this.apiReceipt.CustomerReceiptSave(this.CustomerReceiptPost)
          .subscribe(res => {
            this.postButtonDisable=false;
            this.spinner.hide();
            if (res != null && res != undefined) {
              this.postButtonDisable = false;
              this.CustomerPostResult = res;
              this.isShown = false;

              if (this.CustomerPostResult["ResponseData"].VRNO > 0) {

                this.confirmMsg = this.CustomerPostResult["ResponseData"].Message;
                this.ShowConfMsg = true;
                this.gridData = null;
                this.view = this.gridData;
                this.CustomerReceiptForm.controls.DocCurrencyAmount.setValue(0);
                this.CustomerReceiptForm.controls.HCAmount.setValue(0);
                this.CustomerReceiptForm.controls.HCAllocated.setValue(0);
                this.CustomerReceiptForm.controls.HCUnAllocated.setValue(0);
                this.CustomerReceiptForm.controls.FCAllocated.setValue(0);
                this.CustomerReceiptForm.controls.FCUnAllocated.setValue(0);

              }
              else {
                this.postButtonDisable=false;
                this.spinner.hide();
                this.commonservice.showWARNINGtoastrmsg(this.CustomerPostResult["ResponseData"].Message, 5000, true, false);
              }
            }
          }, (err) => {
            this.spinner.hide();
            this.postButtonDisable=false;
            console.log(err);
            alert('Failed!! :-)\n\n' + err + '\n\n' + JSON.stringify(this.CustomerPostResult.Message, null, 4));
          }
          ));



        //this.spinner.hide();


      }

    }
    else {
      this.ShowPermissionMessageForm('02.07.04');//339

      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'02.07.02');
    }
    this.postButtonDisable = false;
  }

  onReset() {
    this.submitted = false;
    // this.submittedBank=false;
    this.CustomerReceiptForm.reset();
  }
  onCancel() {

    if (this.orderno != "" && this.orderno != undefined) {
      sessionStorage.setItem('pagevalue', '0')
      this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno],{skipLocationChange:true});
    }
    else {
      var reporturl = sessionStorage.getItem('menuhighlight')

      if (reporturl != null) {
        this.router.navigate(['/Login'],{skipLocationChange:true});
      }
      else {
        this.router.navigate(['PenAir/Home'],{skipLocationChange:true});
      }
    }
  }
  onDeleteHeld() {

    if (this.HoldInvNo == "") {
      this.subscription.add(this.translateapi.get(['Messages.HolderInvNoIsMandatory']).subscribe((translations) => {
        this.showMsg = translations['Messages.HolderInvNoIsMandatory'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.spinner.hide();

      // this.common.showFlash1('Messages.fromdate_exceeds','bg-danger text-white',2000);
      return;
    }
    this.subscription.add(this.apiReceipt.HeldDeleteReceipt(this.HoldInvNo)
      .subscribe(res => {
        if (res != null && res != undefined) {
          this.CustomerPostResult = res;


          if (this.CustomerPostResult["ResponseData"].VRNO > 0) {

            this.gridData = null;
            this.view = this.gridData;
            this.confirmMsg = this.CustomerPostResult["ResponseData"].Message;
            this.ShowConfMsg = true;
            this.CustomerReceiptForm.controls.DocCurrencyAmount.setValue(0);
            this.CustomerReceiptForm.controls.HCAmount.setValue(0);
            this.HoldInvNo.setValue("");
          }
        }
      }, (err) => {
        console.log(err);
        alert('Failed!! :-)\n\n' + err + '\n\n' + JSON.stringify(this.CustomerPostResult.Message, null, 4));
      }
      ));
  }
  RetriveHeldData() {
    if (this.HoldInvNo == "" || this.HoldInvNo == null || this.HoldInvNo == undefined) {
      this.subscription.add(this.translateapi.get(['Messages.HolderInvNoIsMandatory']).subscribe((translations) => {
        this.showMsg = translations['Messages.HolderInvNoIsMandatory'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.spinner.hide();

      // this.common.showFlash1('Messages.fromdate_exceeds','bg-danger text-white',2000);
      return;
    }
    this.subscription.add(this.apiReceipt.RetriveHeldData(this.HoldInvNo)
      .subscribe(res => {
        if (res != null && res != undefined) {

          this.CustomerReceiptHeldHeaderData = res;
          this.CustomerReceiptForm.controls.PRTYCODE.setValue(this.CustomerReceiptHeldHeaderData["ResponseData"][0].Custco);

          if (this.CustomerReceiptForm.controls.PRTYCODE.value != '') {
            this.customerSelectedItem = this.CustomerList.find(item => item.Code == this.CustomerReceiptForm.controls.PRTYCODE.value);
            this.customerSelected = this.customerSelectedItem.Name.toString();
          }
          this.fldrCurrency = (this.CustomerReceiptHeldHeaderData["ResponseData"][0].CURID);
          if (this.fldrCurrency != '') {
            this.CurrencyselectedItem = this.Currency.find(item => item.Code == this.fldrCurrency);
          }

          this.CustomerReceiptForm.controls.CURExRate.setValue(this.CustomerReceiptHeldHeaderData["ResponseData"][0].ExchRate);
          this.CustomerReceiptForm.controls.Reference.setValue(this.CustomerReceiptHeldHeaderData["ResponseData"][0].Reference);
          this.CustomerReceiptForm.controls.HCAmount.setValue(this.CustomerReceiptHeldHeaderData["ResponseData"][0].TotalAmountHC);
          this.CustomerReceiptForm.controls.DocCurrencyAmount.setValue(this.CustomerReceiptHeldHeaderData["ResponseData"][0].TotalAmtTC);
        }
      }, (err) => {
        console.log(err);
        alert('Failed!! :-)\n\n' + err + '\n\n' + JSON.stringify(this.CustomerPostResult.Message, null, 4));
      }
      ));
  }
  onHold() {

    if (this.CustomerReceiptForm.get('PRTYCODE').value == "" || this.CustomerReceiptForm.get('PRTYCODE').value == null || this.CustomerReceiptForm.get('PRTYCODE').value == undefined) {
      this.subscription.add(this.translateapi.get(['Messages.CustomerIsMandatory']).subscribe((translations) => {
        this.showMsg = translations['Messages.CustomerIsMandatory'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.spinner.hide();

      // this.common.showFlash1('Messages.fromdate_exceeds','bg-danger text-white',2000);
      return;
    }
    if (this.HoldInvNo == "" || this.HoldInvNo == null || this.HoldInvNo == undefined) {
      this.subscription.add(this.translateapi.get(['Messages.HolderInvNoIsMandatory']).subscribe((translations) => {
        this.showMsg = translations['Messages.HolderInvNoIsMandatory'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.spinner.hide();

      // this.common.showFlash1('Messages.fromdate_exceeds','bg-danger text-white',2000);
      return;
    }
    this.CustomerHeldgrid = [];

    this.UID = (sessionStorage.getItem('uid'));
    this.date = this.commonservice.ConvertToLocaleDate(new Date(this.date));


    this.CustomerReceiptHold.HoldInvNo = this.HoldInvNo;
    this.CustomerReceiptHold.Custco = this.CustomerReceiptForm.get('PRTYCODE').value;
    this.CustomerReceiptHold.CURID = this.fldrCurrency;
    this.CustomerReceiptHold.ExchRate = this.CustomerReceiptForm.get('CURExRate').value;;
    this.CustomerReceiptHold.TotalAmtTC = this.CustomerReceiptForm.get('DocCurrencyAmount').value;;
    this.CustomerReceiptHold.Status = 'H';
    this.CustomerReceiptHold.FolderCrDateFrom = this.CreateDateFrom;
    this.CustomerReceiptHold.FolderCrDateTo = this.CreateDateTo;
    this.CustomerReceiptHold.Reference = this.CustomerReceiptForm.get('Reference').value;
    this.CustomerReceiptHold.PostingDate = this.date;
    this.CustomerReceiptHold.CrUID = this.UID;
    this.CustomerReceiptHold.LupID = this.UID;
    for (var i = 0; i < this.TotalRows; i++) {
      this.CustomerHeldgridParameter = new CustomerHeldDetails();
      this.CustomerHeldgridParameter.Amount = this.view[i].FCReceipt;
      this.CustomerHeldgridParameter.TACRAutoNo = this.view[i].TACRAutoNo;

      this.CustomerHeldgrid[i] = this.CustomerHeldgridParameter;

    }

    this.CustomerReceiptHold.CustomerHeldDetails = this.CustomerHeldgrid;

    this.subscription.add(this.apiReceipt.HeldReceipt(this.CustomerReceiptHold)
      .subscribe(res => {
        if (res != null && res != undefined) {
          this.CustomerPostResult = res;
          this.confirmMsg = this.CustomerPostResult["ResponseData"].Message;
          this.ShowConfMsg = true;
          if (this.CustomerPostResult["ResponseData"].MSGID = 232) {

            this.gridData = null;
            this.gridData = "";
            this.view = this.gridData;
            this.CustomerReceiptForm.controls.DocCurrencyAmount.setValue(0);
            this.CustomerReceiptForm.controls.HCAmount.setValue(0);
            // this.HoldInvNo.setValue("");
            this.HoldInvNo = '';
            this.HeldInvoiceNoSelectedItem = undefined;
            this.CustomerReceiptForm.controls.HCAllocated.setValue(0);
            this.CustomerReceiptForm.controls.HCUnAllocated.setValue(0);
            this.CustomerReceiptForm.controls.FCAllocated.setValue(0);
            this.CustomerReceiptForm.controls.FCUnAllocated.setValue(0);
          }
        }
      }, (err) => {
        console.log(err);
        alert('Failed!! :-)\n\n' + err + '\n\n' + JSON.stringify(this.CustomerPostResult.Message, null, 4));
      }
      ));
  }
  public valueNormalizer = (text: Observable<string>) => text.pipe(map((content: string) => {
    return {

      value: this.HeldInvoiceNoList[this.HeldInvoiceNoList.length - 1].Name + 1,
      text: content
    };
  }))
  public onGenerate() {


    this.submitted = true;

    if (this.CustomerReceiptForm.invalid) {
      return;
    }

    if (this.CustomerReceiptForm.get('PRTYCODE').value == "" || this.CustomerReceiptForm.get('PRTYCODE').value == null || this.CustomerReceiptForm.get('PRTYCODE').value == undefined) {
      this.subscription.add(this.translateapi.get(['Messages.CustomerIsMandatory']).subscribe((translations) => {
        this.showMsg = translations['Messages.CustomerIsMandatory'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.spinner.hide();

      // this.common.showFlash1('Messages.fromdate_exceeds','bg-danger text-white',2000);
      return;
    }
    else if (this.fldrCurrency == "" || this.fldrCurrency == null) {

      this.subscription.add(this.translateapi.get(['Messages.CurrencyIsMandatory']).subscribe((translations) => {
        this.showMsg = translations['Messages.CurrencyIsMandatory'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.spinner.hide();

      // this.common.showFlash1('Messages.fromdate_exceeds','bg-danger text-white',2000);
      return;
    }
    else
      this.getCustCustRecDetails(4, "", this.fldrCurrency, this.CustomerReceiptForm.get('PRTYCODE').value, "01-Jan-1900", "01-Jan-1900", "0", "0")

  }

  getCustCustRecDetails(Id: number, FolderNo: string, Currency: string, PartyCode: number, DepDateFrom: string, DepDateTo: string, BranchId: string, BusinesAreaId: string) {


    this.CreateDateFrom = (this.ATCreateDateFrom == null || this.ATCreateDateFrom == "" ? "01-Jan-1900" : this.ATCreateDateFrom);
    this.CreateDateTo = (this.ATCreateDateTo == null || this.ATCreateDateTo == "" ? "01-Jan-1900" : this.ATCreateDateTo);

    if ((this.CreateDateFrom == "01-Jan-1900" && this.CreateDateTo != "01-Jan-1900") ||
      (this.CreateDateFrom == "01-Jan-1900" && this.CreateDateTo != "01-Jan-1900") ||
      (this.CreateDateTo == "01-Jan-1900" && this.CreateDateTo != "01-Jan-1900")) {
      this.commonservice.showFlash1('Messages.empty_fromdate', 'bg-danger text-white', 2000);
      return;
    }
    if (this.CreateDateFrom != "01-Jan-1900") //Case id-337
    {
      if (Boolean(this.commonservice.ValidDateRange(this.CreateDateFrom)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidFromDate'];
          this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        return;
      }
    }
    if (this.CreateDateTo != "01-Jan-1900") //Case id-337
    {
      if (Boolean(this.commonservice.ValidDateRange(this.CreateDateTo)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidToDate'];
          this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        return;
      }
    }

    this.CustParmDTO = new CustomerReceiptRequest;
    this.CustParmDTO.Id = Id;
    this.CustParmDTO.PartyCode = this.CustomerReceiptForm.get('PRTYCODE').value;
    this.CustParmDTO.YearId = "";
    this.CustParmDTO.CurrencyId = Currency;
    this.CustParmDTO.GLSelected = this.GLSelected;
    this.CustParmDTO.ShowSavedFolders = (this.ShowSavedFolders == true) ? 1 : 0;
    this.CustParmDTO.IncludeReceipt = (this.IncludeReceipt == true) ? 1 : 0;
    this.CustParmDTO.DateFrom = this.CreateDateFrom;
    this.CustParmDTO.DateTo = this.CreateDateTo;
    this.CustParmDTO.FolderNo = FolderNo;
    this.CustParmDTO.HoldInvNo = (this.HoldInvNo);
    //this.CustParmDTO.HoldInvNo="";
    this.apiReceipt.formData = this.CustParmDTO;
    this.subscription.add(this.apiReceipt.GetOutsideCustomerReceiptDetails().
      subscribe(res => {
        if (res != null && res != undefined) {
          this.gridData = res["ResponseData"]

          if (JSON.stringify(res["ResponseData"]) == "null" || JSON.stringify(res["ResponseData"]) == "[]") {
            this.TotalRows = 0;
          }
          else {

            this.view = this.gridData;
            this.TotalRows = this.view.length;
            this.Receiptpostgrid = new Array<CustomerReceiptDetailXMLClass>(this.TotalRows);
          }
        }
        else {
          this.view = [];
          this.TotalRows = 0;
        }
      }));

  }

  public ConfirmMessage(status) {

    sessionStorage.setItem('pagevalue', '0')
    this.ShowConfMsg = false;
    if (this.orderno != undefined) {
      this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno],{skipLocationChange:true});

    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  onIncludeRecpay() {
    this.ShowSavedFolders = 0;
    this.IncludeReceipt = this.chkIncludeReceiptPayment;
  }
  onShowsaved() {
    this.GLSelected = 0;
    this.ShowSavedFolders = this.chkShowSavedFolders;
  }
  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339

  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }
public GetCarholderMembershipInfo()
{
  var data=new Object;
  data["FolderNo"]=this.orderno;
  data["CUSTCODE"]=this.apiShared.CustomerCode;
  this.subscription.add(this.apiReceipt.GetCarholderMembershipInfo(data)
          .subscribe(res => {
debugger;
if(res.ResponseData!=null)
{
  this.CardHolder=res.ResponseData["Membershipno"];
  this.Membership_No=res.ResponseData["Cardholder"];
  this.ConversionRate=res.ResponseData["Rate"];
}
          
          }))
}
CalculateAmt()
{
  debugger;
  if( this.CustomerReceiptForm.controls.RewardPoint.value!=undefined &&this.CustomerReceiptForm.controls.RewardPoint.value!=null && this.CustomerReceiptForm.controls.RewardPoint.value!=0)
  {
    if(this.ConversionRate!=null &&this.ConversionRate!=0)
    {
      this.CustomerReceiptForm.controls.DocCurrencyAmount.setValue(this.CustomerReceiptForm.controls.RewardPoint.value*this.ConversionRate)
      this.DocumentCrncyAmntChange();
    }
    else
    {
      this.CustomerReceiptForm.controls.DocCurrencyAmount.setValue(this.CustomerReceiptForm.controls.RewardPoint.value*1)
      this.DocumentCrncyAmntChange();
    }
    
    
  }
else
{
  if(this.ConversionRate!=null &&this.ConversionRate!=0)
    {
      this.CustomerReceiptForm.controls.DocCurrencyAmount.setValue(1*this.ConversionRate)
      this.DocumentCrncyAmntChange();
    }
    else
    {
      this.CustomerReceiptForm.controls.DocCurrencyAmount.setValue(0)
      this.DocumentCrncyAmntChange();
    }
}

}
}
