import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EnvService } from "../../env.service";
import { State, toODataString } from "@progress/kendo-data-query";
import { map, tap, filter, catchError } from "rxjs/operators";
import { AlertListDTO } from "../Models/alertdetails";
import { SearhdtotransferService } from "./searhdtotransfer.service";
import { FolderSearchCriteriaDTO } from "../Models/folder-search-criteria-dto";
import { FoldersearchService } from "./foldersearch.service";
import { CommonserviceService } from "./commonservice.service";
import { CompanyEmailDTO } from "../Models/email-dto.model";
////import { FoldersearchService } from './foldersearch.service';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ReportParameterDTO } from '../Models/common-class.model';
import { ShareDataService } from '../Services/share-data.service';
import { CommonVariencereportService } from '../Services/common-variencereport.service';
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable({
  providedIn: "root"
})
export class EmailService {
  private subscription: Subscription = new Subscription();
  public loading: boolean = false;
  public value: any;
  apiUrl: string;
  url: string;
  fileurl: any;
  Isgridpdf: any = false;
  filename: any;
  atolfilepath: any = "";
  filepath: any = "";
  emailpath: any = "";
  folderreportfilepath: any = "";
  AtolEnabled: any = false;
  Fromlist: any;
  CCEmail: string = '';
  ToEmail: string = '';
  emailatolpath: any = "";
  reportname: any;
  public showMsg: any;
  public Atolre: any = false;
  public isCrystalre = false;
  emailsubject: any = "";
  emailbody: any = "";
  RecieptEmailBody: any = "";
  cannedMsgAttachment: any = '';
  constructor(
    private translateapi: TranslateService,
    public commonservice: CommonserviceService,
    private toastr: ToastrService,
    private http: HttpClient,
    private env: EnvService,
    private searchservice: SearhdtotransferService,
    private spinner: NgxSpinnerService,
    public common: CommonserviceService,
    private apiShared: ShareDataService,
    private rptAPI: CommonVariencereportService
  ) {
    this.url = env.baseUrl;
  }

  GetEmailBCCAndCC(): Observable<CompanyEmailDTO[]> {
    let headers = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    return this.http.get<CompanyEmailDTO[]>(this.url + "/GetEmailBCCAndCC/", {
      headers
    });
  }

  upload(frmData, body: any) {
    this.loading = true;
    const url = `${this.url}` + "UploadFilesorder";
    this.http.post(url, frmData, body).subscribe(data => {
      if (data != undefined && data != null) {
        debugger;
        if (data[0] == "0") {
          this.subscription.add(
            this.translateapi
              .get(["Messages.Mailsent"])
              .subscribe(translations => {
                this.showMsg = translations["Messages.Mailsent"];
                this.toastr.success(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                });
              })
          );
          this.loading = false;
        } else {
          this.subscription.add(
            this.translateapi
              .get(["Messages.FailedSent"])
              .subscribe(translations => {
                this.showMsg = translations["Messages.FailedSent"];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                });
              })
          );
          this.loading = false;
        }
      }
    });
  }

  ComposeMail(rptName: any, filePath: any, emailPath: any, reportViewerHeader: any, emailbody: any, isCrystal: any, canMsgAttachment: string = '') {
    debugger;
    this.emailatolpath = '';
    this.atolfilepath = '';
    this.filepath = '';
    this.emailpath = '';
    this.filename = '';
    this.emailsubject = '';
    this.emailbody = '';
    this.cannedMsgAttachment = '';
    this.isCrystalre = isCrystal;
    this.filepath = filePath;
    this.emailpath = emailPath;
    this.filename = rptName;
    this.emailsubject = reportViewerHeader;
    this.emailbody = emailbody;
    this.reportname = rptName;
    this.cannedMsgAttachment = canMsgAttachment;
  }

  updateReportParameterDTO(selectedReport: any) {
    debugger;
    if (this.common.formData == undefined)
      this.common.formData = new ReportParameterDTO();
    this.common.formData.attachmentindex = 0;
    var ordernos = this.apiShared.orderNo;
    this.common.formData.trdpfilename = selectedReport;
    this.common.formData.FolderNo = String(ordernos);
    this.common.formData.Userid = +this.apiShared.uid;
    this.common.formData.Branch = String(this.apiShared.BRID);
    this.common.formData.RtftoHtmlUrl = this.env.RtftoHtmlUrl;
  }

  GetEmailBodyByFolderno() {
    if (this.common.formData == undefined)
      this.common.formData = new ReportParameterDTO();
    this.common.formData.p_emailtype = "Receipt";
    this.common.formData.attachmentindex = 0;
    this.common.formData.Branch = String(this.apiShared.BRID);
    this.common.formData.RtftoHtmlUrl = this.env.RtftoHtmlUrl;

    var ordernos = this.apiShared.orderNo;
    this.common.formData.trdpfilename = "";
    this.common.formData.FolderNo = String(ordernos);
    this.common.formData.Userid = +this.apiShared.uid;

    if (this.rptAPI.webLink != '') {
      this.common.formData.isWebLink = true;
    }
  }




}
