import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { CustomerSpecific } from '../Models/customer-specific'
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';


@Injectable({
  providedIn: 'root'
})
export class CustomerspecificfieldService {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   }


  getCustomerSpecificField(folderno: number,custco:number,id:number): Observable<CustomerSpecific[]> {
    const url = `${this.apiUrl}`+'GetCustomerSpecificField'+`/${folderno}/${custco}/${id}`;
    return this.http.get<CustomerSpecific[]>(url).pipe(
      tap(_ => console.log(`fetched CustomerSpecificField folderno=${folderno}`)),
      catchError(this.commonservice.handleError<CustomerSpecific[]>(`getCustomerSpecificField folderno=${folderno}`))
    );
  }


  
}
