import { Component, OnInit, Input } from '@angular/core';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { FormGroup } from '@angular/forms';
import { CommonserviceService } from '../../Services/commonservice.service';
import { IntlService } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-taxbase',
  templateUrl: './taxbase.component.html',
  styleUrls: ['./taxbase.component.scss']
})
export class TaxbaseComponent implements OnInit {
  @Input() itineraryDetails;
  @Input()  _closeTaxBaseForm;
  constructor(public intl: IntlService,public common: CommonserviceService) { }
  visible=false;
  cruise=false;

  ngOnInit() { 
    if(this.itineraryDetails.ResponseData.ITYPE ==='Ticket') {
      this.visible=true;
      this.cruise=false;
    } else if(this.itineraryDetails.ResponseData.ITYPE ==='Cruise') {
      this.visible=true;
      this.cruise=true;
    } else {
      this.visible=false;
      this.cruise=false;
     }
  }
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;

  public onChange(event: any): void {
      this.expandMode = parseInt(event.target.value, 10);
  }
  public onPanelChange(event: any): void { console.log('stateChange: ', event); }
}
