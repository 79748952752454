import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { CarDetail } from '../Models/car-detail'
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({

  providedIn: 'root'
})
export class CarService {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl; }
  

  //Supplierss:Object

  //getSuppliers (): Observable<HotelDetail[]> {   

  ////return this.http.get<HotelDetail[]>(apiUrl)
  // .pipe(
  //   tap(heroes => console.log('fetched Suppliers')),
  // catchError(this.commonservice.handleError('getSuppliers', []))
  //);
  //}

  private newMethod(data: Object) {
    alert(data);
  }

  getCar(folderNo: number, GuidItinerary: string ,isViewForm:boolean): Observable<CarDetail> {
    const url = `${this.apiUrl}` + 'GetCarDetailsById' + `/${folderNo}/${GuidItinerary}/${isViewForm}`;
    return this.http.get<CarDetail>(url).pipe(
      tap(_ => console.log(`fetched Car id=${folderNo}`)),
      catchError(this.commonservice.handleError<CarDetail>(`getCar id=${folderNo}`))
    );
  }
  setcardatedetails(cardetail: any) {
    
    if(cardetail.DATE1===null || cardetail.DATE1==='')
    cardetail.DATE1=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
    cardetail.DATE1=this.commonservice.ConvertToLocaleDate(cardetail.DATE1);

    if(cardetail.DATE2===null || cardetail.DATE2==='')
    cardetail.DATE2=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
    cardetail.DATE2=this.commonservice.ConvertToLocaleDate(cardetail.DATE2);

    if(cardetail.BookingDate===null || cardetail.BookingDate==='')
    cardetail.BookingDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
    cardetail.BookingDate=this.commonservice.ConvertToLocaleDate(cardetail.BookingDate);

    if(cardetail.DepositDueDate===null || cardetail.DepositDueDate==='')
    cardetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
    cardetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(cardetail.DepositDueDate);

    if(cardetail.BalanceDueDate===null || cardetail.BalanceDueDate==='')
    cardetail.BalanceDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
    cardetail.BalanceDueDate=this.commonservice.ConvertToLocaleDate(cardetail.BalanceDueDate);
    console.log(cardetail.DepositDueDate);
    console.log(cardetail.BalanceDueDate);
    console.log(cardetail.BookingDate);
    console.log(cardetail.DATE2);
    console.log(cardetail.DATE1);

    // if(cardetail.DATE1==null)
    // cardetail.DATE1=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    // else
    // cardetail.DATE1=this.commonservice.ConvertToLocaleDate(cardetail.DATE1);

    // if(cardetail.DATE2==null)
    // cardetail.DATE2=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    // else
    // cardetail.DATE2=this.commonservice.ConvertToLocaleDate(cardetail.DATE2);

    // if(cardetail.BookingDate==null)
    // cardetail.BookingDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    // else
    // cardetail.BookingDate=this.commonservice.ConvertToLocaleDate(cardetail.BookingDate);

    // if(cardetail.BalanceDueDate==null)
    // cardetail.BalanceDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    // else
    // cardetail.BalanceDueDate=this.commonservice.ConvertToLocaleDate(cardetail.BalanceDueDate);

    // if(cardetail.DepositDueDate==null)
    // cardetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    // else
    // cardetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(cardetail.DepositDueDate);



    // if(cardetail.DATE1==null)
    // cardetail.DATE1="01-Jan-1900";

    // if(cardetail.DATE2==null)
    // cardetail.DATE2="01-Jan-1900";

    // if(cardetail.BookingDate==null)
    // cardetail.BookingDate="01-Jan-1900";
 
    // if(cardetail.DepositDueDate==null)
    // cardetail.DepositDueDate="01-Jan-1900";

    // if(cardetail.BalanceDueDate==null)
    // cardetail.BalanceDueDate="01-Jan-1900";
  
    if(cardetail.LOYALTYPOINTSAMT==null)
    cardetail.LOYALTYPOINTSAMT=0;
    if(cardetail.LOYALTYPOINTS==null)
    cardetail.LOYALTYPOINTS=0;
  }
  AddCarDetails(cardetail: any): Observable<any> {
   
    

   this.setcardatedetails(cardetail);
    return this.http.post<any>(this.apiUrl + 'AddCarDetails', cardetail, httpOptions).pipe(
      tap((carRes: any) => console.log(`added Car w/ id=${carRes.ResponseData.SUPPRTYCODE}`)),
      catchError(this.commonservice.handleError<any>('AddCarDetails'))
    );
  }

  UpdateCarDetails(cardetail: any): Observable<any> {
   
    this.setcardatedetails(cardetail);
    const url = `${this.apiUrl}` + 'UpdateCarDetails';///${id}
    return this.http.post(url, cardetail, httpOptions).pipe(
      tap(_ => console.log(`updated Car`)),
      catchError(this.commonservice.handleError<any>('updateCar'))
    );
  }
  DeleteCarDetails ( folderNo: number,GuidItinerary:string): Observable<any> {   

    const url = `${this.apiUrl}`+'DeleteCarDetails'+`/${folderNo}/${GuidItinerary}`;
   
    return this.http.post(url,  httpOptions).pipe(
      tap(_ => console.log(`delete Car`)),
      catchError(this.commonservice.handleError<any>('deleteCar'))
    );
  }
}
