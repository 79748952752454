import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { FileDetails } from "../Models/file-details";
import { ReportEmail, ReportCCEmail } from "../Models/report-email";
import { Observable, of, throwError } from "rxjs";
import { catchError, tap, map } from "rxjs/operators";
import { EnvService } from "../../env.service";
import { UserDetails } from "../Models/user-details";
import { CommonserviceService } from "./commonservice.service";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable({
  providedIn: "root"
})
export class FileService {
  apiUrl: string;
  chequeNovaild: boolean = false;
  PaymentReference: boolean = false;
  ValidBankcode: boolean = false;
  ValidChequeDate: boolean = false;
  constructor(
    private http: HttpClient,
    private env: EnvService,
    public commonservice: CommonserviceService
  ) {
    this.apiUrl = env.baseUrl;
  }

  getFileDetails(): Observable<FileDetails> {
    const url = `${this.apiUrl}` + "GetFileDetails";

    return this.http.get<FileDetails>(url).pipe(
      tap(_ => console.log(`fetched file`)),
      catchError(this.commonservice.handleError<FileDetails>(`getFileDetails`))
    );
  }

  GetReportEmail(usrID: number): Observable<ReportEmail> {
    if (usrID == null || usrID == NaN) usrID = 1;
    const url = `${this.apiUrl}` + "GetReportEmail" + `/${usrID}`;
    return this.http.get<ReportEmail>(url).pipe(
      tap(_ => console.log(`fetched ReportEmail id=${usrID}`)),
      catchError(
        this.commonservice.handleError<ReportEmail>(
          `GetReportEmail id=${usrID}`
        )
      )
    );
  }
  GetReportCCEmail(CustCo: number): Observable<ReportCCEmail> {

    const url = `${this.apiUrl}` + "GetReportCCEmail" + `/${CustCo}`;
    return this.http.get<ReportCCEmail>(url).pipe(
      tap(_ => console.log(`fetched ReportEmail id=${CustCo}`)),
      catchError(
        this.commonservice.handleError<ReportCCEmail>(
          `GetReportEmail id=${CustCo}`
        )
      )
    );
  }
  GetCCEmailInvoice(): Observable<any> {
    const url = `${this.apiUrl}` + "GetCCEmailInvoice";
    return this.http
      .get<any>(url)
      .pipe(
        catchError(this.commonservice.handleError<any>(`GetCCEmailInvoice`))
      );
  }
  GetDefaultValuesOfLoggeduser(usrID: number): Observable<UserDetails> {
    const url = `${this.apiUrl}` + "GetDefaultValuesOfLoggeduser" + `/${usrID}`;

    return this.http.get<UserDetails>(url).pipe(
      tap(_ => console.log(`fetched file`)),
      catchError(
        this.commonservice.handleError<UserDetails>(
          `GetDefaultValuesOfLoggeduser`
        )
      )
    );
  }

  GetDefaultBankCode(usrID: number): Observable<any> {
    const url = `${this.apiUrl}` + "GetDefaultBankCode" + `/${usrID}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched DefaultBankCode id=${usrID}`)),
      catchError(
        this.commonservice.handleError<any>(`GetDefaultBankCode id=${usrID}`)
      )
    );
  }
  GetFooterDetails(): Observable<any> {
    const url = `${this.apiUrl}` + "GetFooterDetails";
    return this.http
      .get<any>(url)
      .pipe(
        catchError(this.commonservice.handleError<any>(`GetFooterDetails`))
      );
  }
  //caseid 12640
  GetCompanyLogo(usrID: any): Observable<any> {
    const url = `${this.apiUrl}` + "GetCompanyLogo" + `/${usrID}`;
    return this.http
      .get<any>(url)
      .pipe(catchError(this.commonservice.handleError<any>(`GetCompanyLogo`)));
  }
  GetHelpFile(component: string): Observable<any> {
    const url = `${this.apiUrl}` + "GetHelpFile" + `/${component}`;

    return this.http
      .get<any>(url)
      .pipe(catchError(this.commonservice.handleError<any>(`GetHelpFile`)));
  }
  private newMethod(data: Object) {
    alert(data);
  }
  //12590
  GetCompanySettings(productname: any): Observable<FileDetails> {
    const url = `${this.apiUrl}` + "GetCompanySettings" + `/${productname}`;

    return this.http.get<FileDetails>(url).pipe(
      tap(_ => console.log(`fetched file`)),
      catchError(this.commonservice.handleError<FileDetails>(`getFileDetails`))
    );
  }
  //12590
  getFolderFromEmail(FolderNo: any, uid: any): Observable<any> {
    const url = `${this.apiUrl}` + "GetFolderFromEmail" + `/${FolderNo}/${uid}`;

    return this.http
      .get<any>(url)
      .pipe(
        catchError(this.commonservice.handleError<any>(`GetFolderFromEmail`))
      );
  }



  getUrlSettings(type: string): Observable<any> {
    debugger;
    const url = `${this.apiUrl}` + "GetWebServiceUrlSettings" + `/${type}`;

    return this.http
      .get<any>(url)
      .pipe(catchError(this.commonservice.handleError<any>(`GetHelpFile`)));
  }

  GetURL(): Observable<any> {
    const url = `${this.apiUrl}` + "GetURL";

    return this.http
      .get<any>(url)
      .pipe(catchError(this.commonservice.handleError<any>(`GetURL`)));
  }
}
