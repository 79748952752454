import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CommonserviceService } from '../../Services/commonservice.service';
import { PublicLayoutComponent } from '../../public-layout/public-layout.component';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { FileService } from '../../Services/file.service';
import { ShareDataService } from '../../Services/share-data.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { NgxSpinnerService } from "ngx-spinner";
import { FolderQueuryReportDTO, FolderQueryReportFilterDTO } from "../../Models/folder-queury-report-dto";
import { Folderqueryreport } from "../../Services/folderqueryreport";
import { FolderQueryReportComponent } from '../../folder-query-report/folder-query-report.component';
import { ExportPDFParam } from "../../Models/common-class.model";
import { EnvService } from 'src/env.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WindowState } from '@progress/kendo-angular-dialog';
import { EmailService } from '../../Services/email.service';
import { CommonVariencereportService } from '../../Services/common-variencereport.service';
import { ModuleUsageService } from '../../Services/module-usage.service';
@Component({
  selector: 'app-rpt-folder-query',
  templateUrl: './rpt-folder-query.component.html',
  styleUrls: ['./rpt-folder-query.component.scss']
})
// Caseid:13177 PWR_FolderQueryReport
export class RptFolderQueryComponent implements OnInit {
  public listItems: Array<string> = ['Small', 'Medium', 'Large'];
  public selectedValue = 'Medium';
  private subscription: Subscription = new Subscription();
  @ViewChild('typeInvoice')
  myInputVariable: ElementRef;
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  @ViewChild('autocompletecustomer') public autocompletecustomer: AutoCompleteComponent;
  @ViewChild('autocompletefrom') public autocompletefrom: AutoCompleteComponent;
  @ViewChild('autocompleteTo') public autocompleteTo: AutoCompleteComponent;

  public FolderQueuryReportDTO: FolderQueuryReportDTO;
  public StatusSelectedItem: any;
  public GroupItem: any;

  openSearch = false;
  public typeofinvoiceItem: any;
  public paymentStatusSelectedItem: any;
  public bookingStatusSelectedItem: LookUpDetails["ResponseData"];
  folderActivityReportForm: FormGroup;
  addressdetailsform: FormGroup;
  folderSummaryEditForm: FormGroup;
  public reportname1: any = '';
  public reportname2: any = '';
  public reportname3: any = '';
  public isShowncase: any;
  HomeCurrency: any;
  constructor(public translate: TranslateService, public FolderQueryReportCmp: FolderQueryReportComponent,
    public common: CommonserviceService, private spinner: NgxSpinnerService,
    private layout: PublicLayoutComponent, private lookupApi: LookUpDetailsService, private env: EnvService,
    private router: Router, public email: EmailService,
    private translateapi: TranslateService,
    public moduleUsageService: ModuleUsageService, public sanitizer: DomSanitizer, private emailapi: FileService,
    private apishare: ShareDataService, public Folderqueryreport: Folderqueryreport, private cd: ChangeDetectorRef, ) {
    this.pdfurl = env.baseUrl
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  public ModuleName: String = "Folder Query Report";
  public EmailSubject: String = "Folder Query Report";
  Bookingstauslist: Array<LookUpDetails["ResponseData"]> = [];
  ProductList: Array<LookUpDetails["ResponseData"]> = [];
  public supplierSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelected: String;
  currencyList: Array<LookUpDetails["ResponseData"]> = [];
  branchList: Array<LookUpDetails["ResponseData"]> = [];
  supplierGroupList: Array<LookUpDetails["ResponseData"]> = [];
  marketCodeList: Array<LookUpDetails["ResponseData"]> = [];
  selectedindexinvoicetype: number = 0;
  typeOfInvoiceList: Array<LookUpDetails["ResponseData"]> = [];
  orderTypeList: Array<LookUpDetails["ResponseData"]> = [];
  staffGroupList: Array<LookUpDetails["ResponseData"]> = [];
  businessAreaList: Array<LookUpDetails["ResponseData"]> = [];
  destinationList: Array<LookUpDetails["ResponseData"]> = [];
  public destinationSelectedItem: LookUpDetails["ResponseData"];
  public destinationToSelectedItem: LookUpDetails["ResponseData"];
  public ReportName: string = '';
  destinationToList: Array<LookUpDetails["ResponseData"]> = [];
  customerGroupList: Array<LookUpDetails["ResponseData"]> = [];
  public customerGroupSelectedItem: LookUpDetails["ResponseData"];
  CountryList: Array<LookUpDetails["ResponseData"]> = [];
  CustomerList: Array<LookUpDetails["ResponseData"]> = [];
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];
  BOOKEBYList: Array<LookUpDetails["ResponseData"]> = [];
  CreatedUsersList: Array<LookUpDetails["ResponseData"]> = [];
  username: any = new BehaviorSubject('');
  AirlineList: Array<LookUpDetails["ResponseData"]> = [];
  public customerSelectedItem: LookUpDetails["ResponseData"];
  public OrderselectedItem = LookUpDetails["ResponseData"];
  public submitted: any;
  public isShown: any;
  countrySelectedItem: any;
  getCurrencyExRate: any;
  public currencySelectedItem: LookUpDetails["ResponseData"];
  public destinationSelected: String;
  public destinationToSelected: String;
  public gridData: any;
  public GroupByGridData: any;
  public customerSelected: String;
  public FolderQueryRequest: any;
  public PaymentMethod = 0;
  public GroupSelectedItem: any = { text: "None", value: 0 };
  public subGroupItem: any;
  public MonthOrDateWiseSummaryReport_int: any = 0;
  action: any;
  CurrentDate: any = new Date();
  opened: any = false;
  //public GroupSelectedItem: any;
  public paymentSelectedItem = { text: "(All)", value: 0 }

  isWebLink = false;
  public TravelDateFrom = null;
  public TravelDateTo = null;
  public CreateDateFrom = null;
  public CreateDateTo = null;
  public ReturnDateFrom = null;
  public ReturnDateTo = null;
  public InvoiceDateFrom = null;
  public InvoiceDateTo = null;
  public BookingDateFrom = null;
  public BookingDateTo = null;
  public disabled: any = true;
  public disableinvoicedate: any = false;
  public disableTraveldate: any = false;
  public disableCreatedate: any = false;
  public disabledate: any = false;
  public disableTemplate: any = true;
  public DateTravelFrom = new Date();
  public DateTravelTo = new Date();
  public DateTicketFrom = new Date();
  public DateTicketTo = new Date();
  public SupplierName: String = "";
  public Supplier: any = 0;
  public BookedBy = "";
  public BookedByName = "";
  public CreatedUser: any;
  public CreatedUserName = "";
  public PassengerType = "";
  public TicketedBy = "";
  public GroupBy: any = "None";
  public GroupBy_tinyint = 0;
  public Customer = "";
  public PartyCode: any = 0;
  public Airline = "";
  public VairlineAIRID = "";
  public OriginFrom: String = "";
  public Origin = "";
  public DestinationFrom: String = "";
  public Destination = "";
  public Branch = "";
  public BusinessArea = "";
  public BranchCode = "";
  public BArea = "";
  public UnusedTickets = false;
  public TicketType = "All";
  public SQLPASSWHEREQUERY = "";
  public MarketCodeMultSelect: any;
  public BranchCodeMultSelect: any;
  public BusinessAreaMultSelect: any;
  public DestinationMultiSelect: any;
  public PassType = "All";
  public CrystalRptQuery = "";
  public PassQuery = "";
  public disableTravelDateFrom: boolean = false;
  public disableTravelDateTo: boolean = false;
  public disableInvoiceDateFrom: boolean = false;
  public disableInvoiceDateTo: boolean = false;
  public disableCreateDateFrom: boolean = false;
  public disableCreateDateTo: boolean = false;
  public disableBookingDateFrom: boolean = false;
  public disableBookingDateTo: boolean = false;
  public disableReturnDateFrom: boolean = false;
  public disableReturnDateTo: boolean = false;
  crRptName: any = "";
  public crPdfFilePath = '';
  pdfurl: string;
  crFilePath: any = '';
  crEmailPath: any = '';
  emailbody: any = '';
  crUrlSafe: SafeResourceUrl;
  crFileName: any = '';
  crDeletePath: any;
  crOpened = false;
  ID: number = 0;
  public enableIncludeQuotes = false;
  public IncludeQuoteswithPO = false;
  public drop: boolean = false;
  SupplierQuery: any;
  public ExcludePDQCharge: any;
  public ExcludeTOMS = false;
  public ExcludeTOMS1: any = 0;
  public ExcludePDQ1: any = 0;
  public IncludeCancelled: any;
  public Status = 0;
  StatusName: any = '(ALL)'
  selectAll: any = false;
  public group = 0;
  public subGroup = 0;
  public subGroupName = '';
  public paymentStatus = 0;
  public paymentStatusName = "(ALL)";
  public showMsg: any;
  public supplierGroupCode = "";
  public supplierGroupName = "";
  public markettingCode = "";
  public markettingCodeName = "";
  public typeOfInvoiceCode = "(ALL)";
  public typeOfInvoiceName = "(ALL)";
  public orderTypeCode = "(ALL)";
  public orderTypeName: any = '(ALL)'
  public staffGroupCode = "";
  public staffGroupName = "";
  public Product = "";
  public CustomerName = "";
  public ProductCode: number = 0;
  public currencyCode = "";
  public currencyName = "";
  public customerGroupCode = "";
  public customerGroupName = "";
  public countryCode = "";
  public countryName = "";
  public UserSelectedItem: any;
  public bookSelectedItem: any;
  fromId: string;
  bccId: string;
  userID: number;
  Airlineflag: any = false;
  public SupplierCode = 0;
  public CustCode = 0;
  public YourRef = 0;
  public BookdBy = 0;
  public detailTable: number;
  public subquery1 = "";
  lookupname: any = 'CUSTOMER';
  lookuptype: string = '';
  public marketingcodeArray: Array<any> = [];
  public BranchcodeArray: Array<any> = [];
  public BusinessAreaArray: Array<any> = [];
  public DestinationArray: Array<any> = [];
  public strMarketingCode: any;
  public strBranchCode: any;
  public strBusinessArea: any;
  public strDestination: any;
  public OrderNo: any = '';
  public FolderNo: any = '';
  public YourReference: any = '';
  public profitFrom: any = '';
  public profitPerFrom: any = '';
  public profitPerTo: any = '';
  public profitpercentageFrom: any = '';
  public profitTo: any = '';
  public Componentname: string;
  public rptloaded: any = false;
  public strBa: any;
  public strBr: any;
  public strMc: any;
  public strdest: any;
  public TableandField: any;
  public MCQuery: any;
  public BRQuery: any;
  public BAQuery: any;
  public DesQuery: any;
  public CountryQuery: any;
  public CountryQryCrystalRpt: any;
  public commoncrystalqry: any;
  public AirlineCrystalQuery: any;
  public MarketingSelectCrystalQry: any;
  public DestinationSelectCrystalQry: any;
  public BranchSelectCrystalQry: any;
  public BusAreaSelectCrystalQry: any;
  public CustomerSelectCrystalQry: any;
  public SupplierSelectCrystalQry: any;
  public ReportSubject: any;


  public CustomerQuery: any;
  public AirlineQuery: any;
  public Username: any;
  disabledBookedBy = false;
  disabledTypeOfInvoice: any;
  disabledSupplier: boolean;
  disabledProduct: any;
  disabledSupplierGroup: any;
  disabledSubgroup = true;
  public directPermission: any;
  public selectedvalueGroupBy: any;
  public mcodeInclude: boolean = true;
  public mcodeExclude: boolean = false;
  public mcodeMultiSelectPopUp: boolean = false;
  MCodeMultipleSelectedItems: any = '';
  MCMultipleSelectedItems: any = '';
  BRMultipleSelectedItems: any = '';
  BAMultipleSelectedItems: any = '';
  MsInput: any = '';

  DestinationMultipleSelected = {
    SelectedItems: '',
    isIncluded: true
  }
  BRMultipleSelected = {
    SelectedItems: '',
    isIncluded: true
  }
  BAMultipleSelected = {
    SelectedItems: '',
    isIncluded: true
  }
  MCodeMultipleSelected = {
    SelectedItems: '',
    isIncluded: true
  }
  CustomerMultipleSelected = {
    SelectedItems: '',
    isIncluded: true
  }
  SupplierMultipleSelected = {
    SelectedItems: '',
    isIncluded: true
  }
  AirlineMultipleSelected = {
    SelectedItems: '',
    isIncluded: true
  }
  CountryMultipleSelected = {
    SelectedItems: '',
    isIncluded: true
  }
  DestinationMultipleSelectedItems: any = '';
  CustomerMultipleSelectedItems: any = '';
  SupplierMultipleSelectedItems: any = '';
  AirlineMultipleSelectedItems: any = '';
  CountryMultipleSelectedItems: any = '';
  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339
  public type: string = "";
  public reportViewertemp = "";
  public windowState: WindowState = "maximized";
  public reportViewerHeader = "";
  public selectedReport: string = "";
  emailopened: any = false;
  public offset = 50;
  public windowLeft = this.offset;
  public windowTop = this.offset;
  public widthCr = window.innerWidth - 40;
  rptopened = false;
  public reportSubject: string = "Folder Query Report";
  public reportFile: string = "";
  public SubGroupEnabled_bool: any;


  bookingstatus: any = '(ALL)'
  public groupList: Array<{ text: string, value: number }>
    = [{ text: "None", value: 0 }, { text: "Staff", value: 1 }, { text: "Branch", value: 2 }, { text: "Business Area", value: 3 }, { text: "Individual Product", value: 4 }, { text: "Products Sold", value: 5 },
    { text: "Supplier Group", value: 6 }, { text: "Customer Group", value: 7 },
    { text: "Destination", value: 8 }, { text: "Customer", value: 9 },
    { text: "Marketing Code", value: 10 }, { text: "Staff Group", value: 11 },
    { text: "Supplier", value: 12 }, { text: "Country", value: 13 }, { text: "Order Type", value: 14 }
    ];


  public paymentMethodList: Array<{ text: string, value: number }>
    = [{ text: "(ALL)", value: 0 }, { text: "Cash", value: 1 },
    { text: "Bank", value: 2 }, { text: "Credit Card", value: 3 },
    { text: "Reward Points", value: 4 }, { text: "GL Transfer", value: 5 },
    { text: "Gift Card", value: 6 }, , { text: "Customer Card", value: 7 }];


  public statusList: Array<{ text: string, value: number }> =
    [{ text: "All", value: 0 }, { text: "Saved", value: 1 }, { text: "Invoiced", value: 2 }, { text: "Refund", value: 3 }, { text: "Cancelled", value: 4 }, { text: "Partial Refund", value: 5 }, { text: "Refund Request", value: 6 }];


  public defaultItem: { Name: string; UsrCode: string; Code: number } = {
    Name: "(ALL)", UsrCode: '', Code: 0
  };
  //public subGroupList: Array<{ text: string, value: number }>
  // = [{ text: "Staff", value: 0 }];
  public subGroupList: Array<{ text: string, value: number, disable: boolean }>
    = [{ text: "Staff", value: 1, disable: true }, { text: "Monthly", value: 2, disable: false }, { text: "Daily", value: 3, disable: false }];
  public paymentStatusList: Array<{ text: string, value: number }>
    = [{ text: "(ALL)", value: 0 }, { text: "Not Paid", value: 1 },
    { text: "Partial", value: 2 }, { text: "Fully Paid", value: 3 }, { text: "Advance", value: 4 }];

  public MCodeMuiltiSelectType: any = 0;
  public BranchMultiSelectType: any = 0;
  public BusinessAreaMultiSelectType: any = 0;
  public DestinationMultiSelectType: any = 0;
  public CustomerMultiSelectType: any = 0;
  public AirlineMultiSelectType: any = 0;
  public CountryMultiSelectType: any = 0;
  public SupplierMultiSelectType: any = 0;
  public ExportPDFParamList: ExportPDFParam[];
  public RptHdrSel_AutoNo: any;
  public RptHdrSel_UserId: any;
  public RptHdrSel_ModuleName: any;
  public RptHdrSel_TemplateName: any = "";
  ngOnInit() {
    this.HomeCurrency = this.apishare.homeCurrency;

    sessionStorage.setItem('Componentname', "FolderQueryRpt");
    this.StatusSelectedItem = { text: "All", value: 0 };

    //this.subGroupItem = { text: "None", value: 2 };

    this.paymentStatusSelectedItem = { text: "(ALL)", value: 0 };
    this.GroupItem = { text: "None", value: 0 };
    this.layout.folderstatus = '';
    this.layout.headerPassenger = '';
    this.username = this.apishare.uname;
    this.userID = +(this.apishare.uid);

    // this.InvoiceDateFrom = new Date();
    // this.InvoiceDateTo = new Date();
    this.Folderqueryreport.formDataHeader.RptHdrSel_UserId = this.userID;
    this.Folderqueryreport.formDataHeader.RptHdrSel_ModuleName =
      "FolderQueryReport";

    if (this.Folderqueryreport.formDataHeader.RptHdrSel_TemplateName == null || this.Folderqueryreport.formDataHeader.RptHdrSel_TemplateName == "") {
      this.FolderQueryReportCmp.LoadDefaultTemplate();
    }

    this.FolderQueryReportCmp.LoadTemplate();
    this.subscription.add(this.emailapi.GetReportEmail(this.userID).subscribe(data => {

      this.fromId = data.ResponseData.FROMEMAIL,
        this.bccId = data.ResponseData.DEFAULTBCCMAIL
    }));
    this.subscription.add(this.lookupApi.GetAllLookup('FOLDERBOOKINGSTATUS').subscribe(
      (data) => {

        this.Bookingstauslist = data["ResponseData"]

        this.bookingStatusSelectedItem = this.Bookingstauslist[this.Bookingstauslist.length - 1];
        if (
          this.Folderqueryreport.formData != undefined &&
          this.Folderqueryreport.formData.BookingStatus != null
        ) {

          this.bookingStatusSelectedItem = this.Bookingstauslist.find(item => item.Name == this.Folderqueryreport.formData.BookingStatus);


        }
      }));
    this.subscription.add(this.lookupApi.GetAllLookup('TYPE').subscribe(
      (data) => {

        this.ProductList = data["ResponseData"]
      }));

    this.subscription.add(this.lookupApi.GetAllLookup('BRANCH').subscribe(
      (data) => {
        ;
        this.branchList = data["ResponseData"]
        if (
          this.Folderqueryreport.formData != undefined &&
          this.Folderqueryreport.formData.Branch != null
        ) {

          this.BranchSelectedItem = this.branchList.find(item => item.Name == this.Folderqueryreport.formData.BranchName);

        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('SupplierGroup').subscribe(
      (data) => {

        this.supplierGroupList = data["ResponseData"]

      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('MarketingCode').subscribe(
      (data) => {

        this.marketCodeList = data["ResponseData"]
        if (this.Folderqueryreport.formData && this.Folderqueryreport.formData.ReportGenarated == true) {
          if (this.Folderqueryreport.formData.MCMultipleSelect == false) {
            this.MarketCodeSelectedItem = this.marketCodeList.find(item => item.Code == this.Folderqueryreport.formData.MarkettingCodeSelectedItem);
          }
          this.Folderqueryreport.formData.ReportGenarated = false;
        }

      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('InvoiceType').subscribe(
      (data) => {

        this.typeOfInvoiceList = data["ResponseData"]

      }
    ));

    this.subscription.add(this.lookupApi.GetAllLookup('OrderType').subscribe(
      (data) => {
        this.orderTypeList = data["ResponseData"]
        ;
        if (this.Folderqueryreport.formData != undefined) {
          if (
            this.Folderqueryreport.formData.OrderType_varchar != undefined &&
            this.Folderqueryreport.formData.OrderType_varchar != null
          ) {
            ;
            this.OrderselectedItem = this.orderTypeList.find(item => item.Name == this.Folderqueryreport.formData.OrderTypeName);
            this.OrderTypeValueChange(this.OrderselectedItem);

          }
        }



      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('StaffGroup').subscribe(
      (data) => {

        this.staffGroupList = data["ResponseData"]
        if (
          this.Folderqueryreport.formData != undefined &&
          this.Folderqueryreport.formData.StaffGroupName != null) {

          this.StaffGroupSelectedItem = this.staffGroupList.find(item => item.Name == this.Folderqueryreport.formData.StaffGroupName);
          this.StaffGroupValueChange(this.StaffGroupSelectedItem);
        }
      }
    ));

    this.subscription.add(this.lookupApi.GetAllLookup('CUSTOMERGROUP').subscribe(
      (data) => {

        this.customerGroupList = data["ResponseData"];
      }));
    this.subscription.add(this.lookupApi.GetAllLookup('CURRENCY').subscribe(
      (data) => {

        this.currencyList = data["ResponseData"]
        if (
          this.Folderqueryreport.formData != undefined &&
          this.Folderqueryreport.formData.Currency_varchar != null && this.Folderqueryreport.formData.Currency_varchar != "" && this.Folderqueryreport.formData.Currency_varchar != undefined
        ) {

          this.currencySelectedItem = this.currencyList.find(item => item.Code == this.Folderqueryreport.formData.Currency_varchar);
          this.CurrencyValueChange(this.currencySelectedItem);
        }

      }));

    this.subscription.add(this.lookupApi.GetAllLookup('BUSINESSAREA').subscribe(
      (data) => {
        ;
        this.businessAreaList = data["ResponseData"]

        if (
          this.Folderqueryreport.formData != undefined &&
          this.Folderqueryreport.formData.BArea != null
        ) {

          this.BusinessAreaSelectedItem = this.businessAreaList.find(item => item.Name == this.Folderqueryreport.formData.BArea);
          this.BusinessAreaValueChange(this.BusinessAreaSelectedItem);
        }



      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('COUNTRY').subscribe(
      (data) => {
        this.CountryList = data["ResponseData"];
        if (
          this.Folderqueryreport.formData != undefined &&
          this.Folderqueryreport.formData.Country_varchar != null
        ) {

          this.countrySelectedItem = this.CountryList.find(item => item.Name == this.Folderqueryreport.formData.Country);
          this.CountryValueChange(this.countrySelectedItem);
        }
      }
    ));

    this.Username = this.apishare.uname;

    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        ;

        this.BOOKEBYList = data["ResponseData"]
        this.DirectStaffPermission();
        if (
          this.Folderqueryreport.formData != undefined &&
          this.Folderqueryreport.formData.BookedBy != null
        ) {

          this.BookedBySelectedItem = this.BOOKEBYList.find(item => item.Name == this.Folderqueryreport.formData.BookedBy);
          this.BookedByChange(this.BookedBySelectedItem);
        }

      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {


        this.CreatedUsersList = data["ResponseData"]
        if (
          this.Folderqueryreport.formData != undefined &&
          this.Folderqueryreport.formData.CreatedUser != null
        ) {

          this.CreatedUserSelectedItem = this.CreatedUsersList.find(item => item.Name == this.Folderqueryreport.formData.CreatedUser);
          this.CreatedUserChange(this.CreatedUserSelectedItem);
        }

      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
      (data) => {

        this.AirlineList = data["ResponseData"]

      }
    ));
    this.subscription.add(this.emailapi.GetReportEmail(this.userID).subscribe(data => {
      if (data != null && data != undefined) {
        this.fromId = data.ResponseData.FROMEMAIL,
          this.bccId = data.ResponseData.DEFAULTBCCMAIL
      }
    }));
    if (this.Folderqueryreport.formData != undefined && this.Folderqueryreport.formData != null) {
      ;

      if (this.Folderqueryreport.formData.CreationFromdate != '01-Jan-1900') {
        this.CreateDateFrom = new Date(this.Folderqueryreport.formData.CreationFromdate);
        this.CreateDateTo = new Date(this.Folderqueryreport.formData.CreationTodate);
      }
      if (this.Folderqueryreport.formData.BookingFromdate != '01-Jan-1900') {
        this.BookingDateFrom = new Date(this.Folderqueryreport.formData.BookingFromdate);
        this.BookingDateTo = new Date(this.Folderqueryreport.formData.BookingTodate);
      }
      if (this.Folderqueryreport.formData.TravelFromdate != '01-Jan-1900') {
        this.TravelDateFrom = new Date(this.Folderqueryreport.formData.TravelFromdate);
        this.TravelDateTo = new Date(this.Folderqueryreport.formData.TravelTodate);
      }

      if (this.Folderqueryreport.formData.InvFromdate != '01-Jan-1900') {
        this.InvoiceDateFrom = new Date(this.Folderqueryreport.formData.InvFromdate);
        this.InvoiceDateTo = new Date(this.Folderqueryreport.formData.InvTodate);
      }

      if (this.Folderqueryreport.formData.ReturnFromdate != '01-Jan-1900') {
        this.ReturnDateFrom = new Date(this.Folderqueryreport.formData.ReturnFromdate);
        this.ReturnDateTo = new Date(this.Folderqueryreport.formData.ReturnTodate);
      }
      this.profitFrom = this.Folderqueryreport.formData.ProfitFrom;
      this.profitTo = this.Folderqueryreport.formData.ProfitTo;
      this.profitPerFrom = this.Folderqueryreport.formData.ProfitPerFrom;
      this.profitPerTo = this.Folderqueryreport.formData.ProfitPerTo;
      //  this.GroupSelectedItem = this.Folderqueryreport.formData.GroupBy_varchar;
      ;
      this.GroupSelectedItem = this.groupList.find(item => item.text == this.Folderqueryreport.formData.GroupBy_varchar);
      if (this.GroupSelectedItem != null) {
        this.GroupBy_tinyint = this.GroupSelectedItem.value;
        this.GroupBy = this.GroupSelectedItem.text;
      }
      else {
        this.GroupBy_tinyint = 0;
        this.GroupBy = "";
      }


      ;
      this.StatusSelectedItem = this.statusList.find(item => item.text == this.Folderqueryreport.formData.StatusName);
      if (this.StatusSelectedItem != null) {
        this.Status = this.StatusSelectedItem.value;
        this.StatusName = this.StatusSelectedItem.text;
      }
      else {
        this.Status = 0;
        this.StatusName = 'All';
      }

      ;
      this.paymentStatusSelectedItem = this.paymentStatusList.find(item => item.text == this.Folderqueryreport.formData.PaymentStatus);
      if (this.paymentStatusSelectedItem != null) {
        this.paymentStatus = this.paymentStatusSelectedItem.value;
        this.paymentStatusName = this.paymentStatusSelectedItem.text;
      }
      else {
        this.paymentStatus = 0;
        this.paymentStatusName = 'ALL';
      }
      this.loadSupplierbyid();
      this.loadcustomerbyid();
      this.loadDestinationbyid();

      this.FolderNo = this.Folderqueryreport.formData.FolderNoFrom == null ? "" : this.Folderqueryreport.formData.FolderNoFrom;
      this.OrderNo = this.Folderqueryreport.formData.OrderNoFrom == null ? "" : this.Folderqueryreport.formData.OrderNoFrom;
      this.enableIncludeQuotes = this.Folderqueryreport.formData.QuotesOnly;
      this.IncludeQuoteswithPO = this.Folderqueryreport.formData.IncludeQuoteswithPO;
      this.ExcludePDQ1 = this.Folderqueryreport.formData.ExcludePDQCharge_int;
      this.ExcludeTOMS = this.Folderqueryreport.formData.ExcludeTOMSVat_int;
      this.IncludeCancelled = this.Folderqueryreport.formData.CancelledCheck;
    }



  }

  public ProductChange(value: any): void {
    ;
    if (value != null && value != undefined) {
      this.Product = value.Name;
      this.ProductCode = value.Code;

    } else {
      this.Product = "";
      this.ProductCode = 0;
    }
  }


  public SupplierValueChange(value: any): void {
    ;
    this.SupplierMultipleSelected.SelectedItems = "";
    this.setMultiselectValue('Supplier', 0)
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);//13864

    }
    if (this.supplierSelectedItem != null) {
      ;
      this.supplierSelected = this.supplierSelectedItem.Name; //13864     
      this.SupplierName = this.supplierSelectedItem.ActualName;
      this.Supplier = (this.supplierSelectedItem.Code);
    }
    else {
      this.SupplierName = "";
      this.supplierSelected = "";
      this.Supplier = "0";
    }

  }





  handleFiltersupplier(value) {
    ;
    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          this.SupplierList = data["ResponseData"];
        }
      ));
    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  public CreatedUserChange(value: any): void {

    if (value != null && value != undefined) {
      this.CreatedUser = value.Code;
      this.CreatedUserName = value.Name;
    }

    else {
      this.CreatedUser = 0;
      this.CreatedUserName = "";
    }


  }

  public BookedByChange(value: any): void {


    if (value != null && value != undefined) {
      this.BookedBy = value.Code;
      this.BookedByName = value.Name;
      this.DirectStaffPermission();
    }

    else {
      this.BookedBy = "";
      this.BookedByName = "";
    }


  }
  public CountryValueChange(value: any): void {
    this.CountryMultipleSelected.SelectedItems = "";
    this.setMultiselectValue('COUNTRY', 0)
    if (value != null && value != undefined) {
      this.countryCode = value.Code;
      this.countryName = value.Name;
    }
    else {
      this.countryCode = "";
      this.countryName = "";
    }
  }
  public CurrencyValueChange(value: any): void {
    ;

    if (value.UsrCode != null && value.UsrCode != undefined) {
      this.currencyCode = value.UsrCode;
      this.currencyName = value.Name;
    }
    else {
      this.currencyCode = "";
      this.currencyName = "";
    }
  }
  public CustomerGroupValueChange(value: any): void {
    ;
    if (value != null && value != undefined) {
      this.customerGroupCode = value.Code;
      this.customerGroupName = value.Name;
    }
    else {
      this.customerGroupCode = "";
      this.customerGroupName = "";
    }
  }


  public TicketTypeChange(value: any): void {
    if (value != null && value != undefined)
      this.TicketType = value.Name;
    else
      this.TicketType = null;
  }
  public TicketedByChange(value: any): void {

    if (value != null && value != undefined)
      this.TicketedBy = value.Name;
    else
      this.TicketedBy = "";

  }
  public PaymentMethodValueChange(value: any): void {

    if (value != null && value != undefined) {
      this.PaymentMethod = value.value;


    }
    else {
      this.PaymentMethod = 0;

    }
  }

  public StatusValueChange(value: any): void {
    ;
    if (value != null && value != undefined) {

      this.Status = value.value;
      this.StatusName = value.text;


    }
    else {
      this.Status = 0;
      this.StatusName = 'All';
    }
  }
  public GroupValueChange(value: any): void {
    if (value != null && value != undefined) {

      this.group = value.value;

    }
    else {
      this.group = 0;
    }

  }
  EnableControls(SubGroupEnabled_bool: boolean): void {
    if (SubGroupEnabled_bool == true) {
      if (this.MonthOrDateWiseSummaryReport_int == 0) {

        if (this.InvoiceDateFrom != "" && this.InvoiceDateFrom != "01/jan/1900" && this.InvoiceDateFrom != null) {
          this.disableTravelDateFrom = true;
          this.disableTravelDateTo = true;
          this.disableCreateDateFrom = true;
          this.disableCreateDateTo = true;
          this.disableBookingDateFrom = true;
          this.disableBookingDateTo = true;
          this.disableReturnDateFrom = true;
          this.disableReturnDateTo = true;

        }
        if (this.TravelDateFrom != "" && this.TravelDateFrom != "01/jan/1900" && this.TravelDateFrom != null) {
          this.disableInvoiceDateFrom = true;
          this.disableInvoiceDateTo = true;
          this.disableCreateDateFrom = true;
          this.disableCreateDateTo = true;
          this.disableBookingDateFrom = true;
          this.disableBookingDateTo = true;
          this.disableReturnDateFrom = true;
          this.disableReturnDateTo = true;

        }
        if (this.CreateDateFrom != "" && this.CreateDateFrom != "01/jan/1900" && this.CreateDateFrom != null) {
          this.disableInvoiceDateFrom = true;
          this.disableInvoiceDateTo = true;
          this.disableTravelDateFrom = true;
          this.disableTravelDateTo = true;
          this.disableBookingDateFrom = true;
          this.disableBookingDateTo = true;
          this.disableReturnDateFrom = true;
          this.disableReturnDateTo = true;

        }
        if (this.BookingDateFrom != "" && this.BookingDateFrom != "01/jan/1900" && this.BookingDateFrom != null) {
          this.disableInvoiceDateFrom = true;
          this.disableInvoiceDateTo = true;
          this.disableTravelDateFrom = true;
          this.disableTravelDateTo = true;
          this.disableCreateDateFrom = true;
          this.disableCreateDateTo = true;
          this.disableReturnDateFrom = true;
          this.disableReturnDateTo = true;

        }
        if (this.ReturnDateFrom != "" && this.ReturnDateFrom != "01/jan/1900" && this.ReturnDateFrom != null) {
          this.disableInvoiceDateFrom = true;
          this.disableInvoiceDateTo = true;
          this.disableTravelDateFrom = true;
          this.disableTravelDateTo = true;
          this.disableCreateDateFrom = true;
          this.disableCreateDateTo = true;
          this.disableBookingDateFrom = true;
          this.disableBookingDateTo = true;

        }
        this.SupplierSelectedItem = null;
        this.supplierSelected = null;
        this.SupplierGroupSelectedItem = null;
        this.ProductSelectedItem = null;
        this.ProductSelectedItem = ""
        this.Product = "";
        this.Supplier = "";
        this.supplierGroupName = "";
        this.typeofinvoiceItem = { Name: "(ALL)", Code: 0 };
        this.disabledTypeOfInvoice = true;
        this.disabledProduct = true;
        this.disabledSupplier = true;
        this.disabledSupplierGroup = true;

      }
      this.MonthOrDateWiseSummaryReport_int = 1;
    }
    else {
      this.MonthOrDateWiseSummaryReport_int = 0;
      this.disableTravelDateFrom = false;
      this.disableTravelDateTo = false;
      this.disableCreateDateFrom = false;
      this.disableCreateDateTo = false;
      this.disableBookingDateFrom = false;
      this.disableBookingDateTo = false;
      this.disableReturnDateFrom = false;
      this.disableReturnDateTo = false;
      this.disableInvoiceDateFrom = false;
      this.disableInvoiceDateTo = false;
      this.disabledTypeOfInvoice = false;
      this.disabledProduct = false;
      this.disabledSupplier = false;
      this.disabledSupplierGroup = false;
    }



  }
  public SubGroupValueChange(value: any): void {
    if (value != null && value != undefined) {

      this.subGroup = value.value;
      this.subGroupName = value.text;
      if (this.subGroupName == "Monthly" || this.subGroupName == "Daily") {
        this.EnableControls(true);
      }
      else {
        this.EnableControls(false);
      }
    }
    else {
      this.subGroup = 0;
      this.subGroupName = '';
    }
  }
  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;
    this.PermissionMessageId = permissionid;
  }
  public PermissionFormClosing(status: any) {
    if (status == "EmailSendSuccesfully") {
      this.permissionmessageformopened = false;
    }
  }
  public async DirectStaffPermission() {
    this.common.directpermission = true;
    if (Boolean(await this.common.checkPermission1('14.02.01')) == true) {
      this.BookedBySelectedItem = this.BOOKEBYList.find(
        item => item.Name == this.Username
      );
      this.BookedByChange(this.BookedBySelectedItem);
      this.disabledBookedBy = true;
    }
    else {
      this.disabledBookedBy = false;
    }
    this.common.directpermission = false;
  }

  public PaymentStatusValueChange(value: any): void {

    if (value != null && value != undefined) {

      this.paymentStatus = value.value;
      this.paymentStatusName = value.text;
    }
    else {
      this.paymentStatus = 0;
      this.paymentStatusName = 'ALL';
    }
  }
  public bookingStatusValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.bookingstatus = value.Name;

    }
    else {

      this.bookingstatus = 'All';
    }
  }
  handleFilter(value) {


    this.AirlineList = this.AirlineList.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public AirLineChange(value: any): void {
    this.AirlineMultipleSelected.SelectedItems = "";
    this.setMultiselectValue('AIRWAYS', 0)
    if (value != null && value != undefined) {
      this.Airline = value.ActualName;
      this.VairlineAIRID = value.UsrCode;
    }

    else {
      this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
        (data) => {

          this.AirlineList = data["ResponseData"]

        }
      ));
      this.Airline = "";
      this.VairlineAIRID = "";
    }

  }
  loadDestinationbyid() {
    if (this.Folderqueryreport.formData.Destination != '' && this.Folderqueryreport.formData.Destination != null) {

      this.subscription.add(this.lookupApi.SearchDestination(this.Folderqueryreport.formData.Destination, "1").subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.destinationToList = data["ResponseData"];
            if (this.Folderqueryreport.formData.Destination != "") {

              this.destinationToSelectedItem = this.destinationToList.find(item => item.Code == this.Folderqueryreport.formData.Destination);

              if (this.destinationToSelectedItem != null) {
                this.destinationSelected = this.destinationToSelectedItem.Name;
              }
              this.DestinationValueChange(this.destinationSelected);
            }
          }

        }
      ));
    }
  }
  public DestinationValueChange(value: any): void {

    this.destinationToSelectedItem = this.destinationToList.find(item => item.Name == value);
    this.DestinationMultipleSelected.SelectedItems = "";
    this.setMultiselectValue('ARRIVALPT', 0)
    if (this.destinationToSelectedItem != null) {
      this.DestinationFrom = this.destinationToSelectedItem.ActualName;
      this.Destination = this.destinationToSelectedItem.UsrCode;

    }
    else {
      this.DestinationFrom = "";
      this.Destination = "";
    }
  }

  public OriginChange(value: any): void {
    this.destinationSelectedItem = this.destinationList.find(item => item.Name == value);
    if (this.destinationSelectedItem != null) {
      this.OriginFrom = this.destinationSelectedItem.ActualName;
      this.Origin = this.destinationSelectedItem.UsrCode;
    }
    else {
      this.OriginFrom = null;
      this.Origin = null;
    }


  }
  handleFilterToDestination(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          this.destinationToList = data["ResponseData"];
        }
      ));
    } else {
      this.autocompleteTo.toggle(false);
    }
  }
  handleFilterFromDestination(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          this.destinationList = data["ResponseData"];
        }
      ));

    } else {
      this.autocompletefrom.toggle(false);
    }
  }

  public BranchValueChange(value: any): void {
    this.BRMultipleSelected.SelectedItems = "";
    this.setMultiselectValue('BRID', 0)
    if (value != null && value != undefined) {
      this.BranchCode = value.Code;
      this.Branch = value.Name;
    }
    else {
      this.BranchCode = "";
      this.Branch = "";
    }

  }
  public SupplierGroupValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.supplierGroupCode = value.Code;
      this.supplierGroupName = value.Name;
    }
    else {
      this.supplierGroupCode = "";
      this.supplierGroupName = "";
    }

  }
  DisableorEnableFCControls(pFCControls: boolean): void {
    this.supplierGroupName = "";
    this.supplierGroupCode = "";
    this.ProductCode = 0;
    this.Product = "";
    this.Supplier.ReadOnly = (pFCControls) ? true : false;
    this.ProductSelectedItem.ReadOnly = (pFCControls) ? true : false;
    this.disabledProduct.enabled = (pFCControls) ? false : true;
    //this.disabledSupplier.enabled = (pFCControls) ? false : true;
    this.disabledSupplierGroup.enabled = (pFCControls) ? false : true;


    if (pFCControls == true) {
      this.GroupBy.SelectedIndex = 1;
    }
    else {
      this.GroupBy.SelectedIndex = 0;

    }
    this.GroupBy.Enabled = (pFCControls) ? false : true;

  }
  public MarketCodeValueChange(value: any): void {
    this.MCodeMultipleSelected.SelectedItems = "";
    this.setMultiselectValue('MCCode', 0)
    if (value != null && value != undefined) {
      this.markettingCode = value.Code;
      this.markettingCodeName = value.Name;
    }
    else {
      this.markettingCode = "";
      this.markettingCodeName = "";
    }
  }

  public TypeOfInvoiceValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.typeOfInvoiceCode = value.Code;
      this.typeOfInvoiceName = value.Name;
    }
    else {
      this.typeOfInvoiceCode = "(ALL)";
      this.typeOfInvoiceName = "(ALL)";
    }
  }

  public OrderTypeValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.orderTypeCode = value.UsrCode;
      this.orderTypeName = value.Name;
    }
    else {
      this.orderTypeCode = "(ALL)";
      this.orderTypeName = "(ALL)";
    }
  }

  public StaffGroupValueChange(value: any): void {
    if (value != null && value != undefined) {
      this.staffGroupCode = value.Code;
      this.staffGroupName = value.Name;
    }
    else {
      this.staffGroupCode = "";
      this.staffGroupName = "";
    }
  }

  //13864
  openBox(lookuptype) { //v1.0
    if (this.disabledSupplier && this.lookuptype == 'SUPPLIERS') {
      this.openSearch = false;
    } else {
      this.openSearch = true;
    }
    // this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'CUSTOMER') {
      this.lookupname = 'Customer';
    }
    else if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Suppliers';
    }
  }

  getParentMethod(): any { //v1.0

    return {
      callParentMethod: (value: any) => {
        this.openSearch = false;
        if (this.lookuptype == 'SUPPLIERS') {
          this.supplierSelectedItem = value;
          this.SupplierList = [];
          this.SupplierValueChange(value.ActualName)
        }
        else if (this.lookuptype == 'CUSTOMER') {
          this.customerSelectedItem = value;
          this.CustomerList = [];
          this.CustomerValueChange(value.Name)
        }
      },

      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13864

  public CustomerValueChange(value: any): void {
    this.CustomerMultipleSelected.SelectedItems = "";
    this.setMultiselectValue('Customer', 0)
    if (this.CustomerList != null && this.CustomerList.length > 0) {//13864 
      this.customerSelectedItem = this.CustomerList.find(item => item.Name == value);

    }



    if (this.customerSelectedItem != null) {
      this.customerSelected = this.customerSelectedItem.Name; //13864
      this.Customer = this.customerSelectedItem.UsrCode;
      this.PartyCode = this.customerSelectedItem.Code;


    }
    else {
      this.Customer = "";
      this.PartyCode = 0;
      this.customerSelected = "";
    }
  }
  loadcustomerbyid() {
    if (this.Folderqueryreport.formData.CustomerCode != '' && this.Folderqueryreport.formData.CustomerCode != null) {

      this.subscription.add(this.lookupApi.findCustomer(this.Folderqueryreport.formData.CustomerCode).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.CustomerList = data["ResponseData"];
            if (this.Folderqueryreport.formData.CustomerCode != "") {

              this.customerSelectedItem = this.CustomerList.find(item => item.Code == this.Folderqueryreport.formData.CustomerCode);

              if (this.customerSelectedItem != null) {
                this.customerSelected = this.customerSelectedItem.Name;
              }

              this.CustomerValueChange(this.customerSelected);
            }
          }

        }
      ));
    }
  }
  loadSupplierbyid() {
    if (this.Folderqueryreport.formData.Supplier != '' && this.Folderqueryreport.formData.Supplier != null) {

      this.subscription.add(this.lookupApi.findSupplier(this.Folderqueryreport.formData.SupplierCode).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.SupplierList = data["ResponseData"];
            if (this.Folderqueryreport.formData.SupplierCode != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.Folderqueryreport.formData.SupplierCode);

              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
              }

              this.SupplierValueChange(this.supplierSelected);
            }
          }

        }
      ));
    }
  }
  public BusinessAreaValueChange(value: any): void {
    this.BusinessAreaSelectedItem = this.businessAreaList.find(item => item.Name == value);

    this.BAMultipleSelected.SelectedItems = "";
    this.setMultiselectValue('BUSINESSAREA', 0)
    if (value != null && value != undefined) {
      this.BArea = value.Code;
      this.BusinessArea = value.Name;
    }
    else {
      this.BArea = "";
      this.BusinessArea = "";
    }
  }

  handleFiltercustomer(value) {
    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchCustomer(value, this.userID).subscribe(
        (data) => {
          this.CustomerList = data["ResponseData"];
        }
      ));
    } else {
      this.autocompletecustomer.toggle(false);
    }
  }
  public close() {

    this.opened = false;
    this.openSearch = false;
  }
  public FormClosing(status: any) {
    this.rptloaded = false;
  }
  public ReportSchedulerFormClose() {
    this.rptloaded = false;
  }
  public schedulerClose() {
    this.rptloaded = false;
  }
  onCancel() {
    var reporturl = localStorage.getItem('menuhighlight')
    this.btnClearAll();
    if (reporturl != null) {
      localStorage.removeItem('menuhighlight');
      this.router.navigate(['/Login']);
    }
    else {
      this.router.navigate(['PenAir/Home']);
    }
  }

  public GroupByChange(value: any): void {
    if (value !== undefined) {
      this.EnableControls(false);
      this.GroupSelectedItem = this.groupList.find(item => item.value == value.value);
      if (this.GroupSelectedItem != null) {
        this.GroupBy_tinyint = this.GroupSelectedItem.value;
        this.GroupBy = this.GroupSelectedItem.text;

        if (this.GroupBy == "None") {
          this.disabledSubgroup = true;
          this.disableTemplate = true;

        }
        else {
          this.disabledSubgroup = false;
          this.disableTemplate = false;

        }
        if (this.GroupBy == "Branch") {
          this.subGroupList.find(item => item.text == "Staff").disable = false;

          this.subGroupItem = this.subGroupList.find(item => item.text == "Staff");
          this.SubGroupValueChange(this.subGroupItem);

        }
        else {
          this.subGroupList.find(item => item.text == "Staff").disable = true;
          this.subGroupItem = null;
          this.SubGroupValueChange(null);
        }
      }
    }
    else {
      //  this.subGroupList.find(item => item.text == "Staff").enabled = true;
      this.GroupBy_tinyint = 0;
      this.GroupBy = "";
    }
  }
  public itemDisabled(itemArgs: { dataItem: any; index: number }) {

    return itemArgs.dataItem.disable;// disable the 3rd item



  }
  CreateSheduler() {
    this.rptloaded = true;
    this.CreateDateFrom = (this.CreateDateFrom == null || this.CreateDateFrom == "" ? "01-Jan-1900" : this.CreateDateFrom);
    this.CreateDateTo = (this.CreateDateTo == null || this.CreateDateTo == "" ? "01-Jan-1900" : this.CreateDateTo);
    this.TravelDateFrom = (this.TravelDateFrom == null || this.TravelDateFrom == "" ? "01-Jan-1900" : this.TravelDateFrom);
    this.TravelDateTo = (this.TravelDateTo == null || this.TravelDateTo == "" ? "01-Jan-1900" : this.TravelDateTo);
    this.InvoiceDateFrom = (this.InvoiceDateFrom == null || this.InvoiceDateFrom == "" ? "01-Jan-1900" : this.InvoiceDateFrom);
    this.InvoiceDateTo = (this.InvoiceDateTo == null || this.InvoiceDateTo == "" ? "01-Jan-1900" : this.InvoiceDateTo);
    this.BookingDateFrom = (this.BookingDateFrom == null || this.BookingDateFrom == "" ? "01-Jan-1900" : this.BookingDateFrom);
    this.BookingDateTo = (this.BookingDateTo == null || this.BookingDateTo == "" ? "01-Jan-1900" : this.BookingDateTo);
    this.ReturnDateFrom = (this.ReturnDateFrom == null || this.ReturnDateFrom == "" ? "01-Jan-1900" : this.ReturnDateFrom);
    this.ReturnDateTo = (this.ReturnDateTo == null || this.ReturnDateTo == "" ? "01-Jan-1900" : this.ReturnDateTo);

    var dateValidation = Date.parse(this.CreateDateFrom);
    if (!isNaN(dateValidation)) {
      this.CreateDateFrom = this.common.ConvertToLocaleDate(
        this.CreateDateFrom
      );

    } else if (this.CreateDateFrom != null && this.CreateDateFrom != "") {
      this.CreateDateFrom = "{{" + this.CreateDateFrom + "}}";
    }
    dateValidation = Date.parse(this.CreateDateTo);
    if (!isNaN(dateValidation)) {
      this.CreateDateTo = this.common.ConvertToLocaleDate(this.CreateDateTo);

    } else if (this.CreateDateTo != null && this.CreateDateTo != "") {
      this.CreateDateTo = "{{" + this.CreateDateTo + "}}";
    }
    dateValidation = Date.parse(this.TravelDateFrom);
    if (!isNaN(dateValidation)) {
      this.TravelDateFrom = this.common.ConvertToLocaleDate(
        this.TravelDateFrom
      );

    } else if (this.TravelDateFrom != null && this.TravelDateFrom != "") {
      this.TravelDateFrom = "{{" + this.TravelDateFrom + "}}";
    }
    dateValidation = Date.parse(this.TravelDateTo);
    if (!isNaN(dateValidation)) {
      this.TravelDateTo = this.common.ConvertToLocaleDate(
        this.TravelDateTo
      );

    } else if (this.TravelDateTo != null && this.TravelDateTo != "") {
      this.TravelDateTo = "{{" + this.TravelDateTo + "}}";
    }
    dateValidation = Date.parse(this.ReturnDateFrom);
    if (!isNaN(dateValidation)) {
      this.ReturnDateFrom = this.common.ConvertToLocaleDate(
        this.ReturnDateFrom
      );

    } else if (this.ReturnDateFrom != null && this.ReturnDateFrom != "") {
      this.ReturnDateFrom = "{{" + this.ReturnDateFrom + "}}";
    }
    dateValidation = Date.parse(this.ReturnDateTo);
    if (!isNaN(dateValidation)) {
      this.ReturnDateTo = this.common.ConvertToLocaleDate(this.ReturnDateTo);

    } else if (this.ReturnDateTo != null && this.ReturnDateTo != "") {
      this.ReturnDateTo = "{{" + this.ReturnDateTo + "}}";
    }
    dateValidation = Date.parse(this.BookingDateFrom);
    if (!isNaN(dateValidation)) {
      this.BookingDateFrom = this.common.ConvertToLocaleDate(this.BookingDateFrom);

    } else if (this.BookingDateFrom != null && this.BookingDateFrom != "") {
      this.BookingDateFrom = "{{" + this.BookingDateFrom + "}}";
    }
    dateValidation = Date.parse(this.BookingDateTo);
    if (!isNaN(dateValidation)) {
      this.BookingDateTo = this.common.ConvertToLocaleDate(this.BookingDateTo);

    } else if (this.BookingDateTo != null && this.BookingDateTo != "") {
      this.BookingDateTo = "{{" + this.BookingDateTo + "}}";
    }
    dateValidation = Date.parse(this.InvoiceDateFrom);
    if (!isNaN(dateValidation)) {
      this.InvoiceDateFrom = this.common.ConvertToLocaleDate(this.InvoiceDateFrom);

    } else if (this.InvoiceDateFrom != null && this.InvoiceDateFrom != "") {
      this.InvoiceDateFrom = "{{" + this.InvoiceDateFrom + "}}";
    }
    dateValidation = Date.parse(this.InvoiceDateTo);
    if (!isNaN(dateValidation)) {
      this.InvoiceDateTo = this.common.ConvertToLocaleDate(this.InvoiceDateTo);

    } else if (this.InvoiceDateTo != null && this.InvoiceDateTo != "") {
      this.InvoiceDateTo = "{{" + this.InvoiceDateTo + "}}";
    }

    if ((this.TravelDateTo == null || this.TravelDateTo == "") && (this.TravelDateFrom != null || this.TravelDateFrom != "")) {
      this.TravelDateTo = this.TravelDateFrom;

    }
    if ((this.CreateDateTo == null || this.CreateDateTo == "") && (this.CreateDateFrom != null || this.CreateDateFrom != "")) {
      this.CreateDateTo = this.CreateDateFrom;

    }
    if ((this.InvoiceDateTo == null || this.InvoiceDateTo == "") && (this.InvoiceDateFrom != null || this.InvoiceDateFrom != "")) {
      this.InvoiceDateTo = this.InvoiceDateFrom;

    }
    if ((this.BookingDateTo == null || this.BookingDateTo == "") && (this.BookingDateFrom != null || this.BookingDateFrom != "")) {
      this.BookingDateTo = this.BookingDateFrom;

    }
    if ((this.ReturnDateTo == null || this.ReturnDateTo == "") && (this.ReturnDateFrom != null || this.ReturnDateFrom != "")) {
      this.ReturnDateTo = this.ReturnDateFrom;

    }



    if (
      this.ReturnDateTo == "" &&
      this.ReturnDateFrom == "" &&
      this.TravelDateTo == "" &&
      this.TravelDateFrom == "" &&
      this.CreateDateTo == "" &&
      this.CreateDateFrom == "" &&
      this.BookingDateFrom == "" &&
      this.BookingDateTo == ""
    ) {
      this.common.showFlash1(
        "Messages.empty_fromdate",
        "bg-danger text-white",
        2000
      );
      return;
    }

    if (this.Folderqueryreport.formDataHeader != undefined) {
      this.RptHdrSel_AutoNo = this.Folderqueryreport.formDataHeader.RptHdrSel_AutoNo;
      this.RptHdrSel_UserId = this.Folderqueryreport.formDataHeader.RptHdrSel_UserId;
      this.RptHdrSel_ModuleName = this.Folderqueryreport.formDataHeader.RptHdrSel_ModuleName;
      this.RptHdrSel_TemplateName = this.Folderqueryreport.formDataHeader.RptHdrSel_TemplateName;

    }
    this.CommonFunctions();
    this.FolderQueuryReportDTO.RptHdrSel_ModuleName = this.RptHdrSel_ModuleName;
    this.FolderQueuryReportDTO.RptHdrSel_UserId = this.RptHdrSel_UserId;
    this.FolderQueuryReportDTO.RptHdrSel_AutoNo = this.RptHdrSel_AutoNo;
    this.FolderQueryRequest = JSON.stringify(this.FolderQueuryReportDTO);

  }

  SetDate(date, controlName) {
    //13557 changes in folder query 
    switch (controlName) {
      case 'CreateDateFrom':
        this.CreateDateFrom = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      //  this.CreateDateFrom = this.CreateDateFrom.setValue(date);
      case 'CreateDateTo':
        this.CreateDateTo = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'TravelDateFrom':
        this.TravelDateFrom = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'TravelDateTo':
        this.TravelDateTo = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'InvoiceDateFrom':
        this.InvoiceDateFrom = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'InvoiceDateTo':
        this.InvoiceDateTo = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'BookingDateFrom':
        this.BookingDateFrom = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'BookingDateTo':
        this.BookingDateTo = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'ReturnDateFrom':
        this.ReturnDateFrom = (date == null || date == "" ? "01-Jan-1900" : date);
        break;
      case 'ReturnDateTo':
        this.ReturnDateTo = (date == null || date == "" ? "01-Jan-1900" : date);
    }
  }
  onCheckboxChange() {
    if (this.enableIncludeQuotes == true) {
      this.IncludeQuoteswithPO = false;
    }

  }
  onCheckboxIncludeQuoteswithPOChange() {
    if (this.IncludeQuoteswithPO == true) {
      this.enableIncludeQuotes = false;
    }
  }
  async onFormSubmit() {
    this.spinner.show();
    this.CreateDateFrom = (this.CreateDateFrom == null || this.CreateDateFrom == "" ? "01-Jan-1900" : this.CreateDateFrom);
    this.CreateDateTo = (this.CreateDateTo == null || this.CreateDateTo == "" ? "01-Jan-1900" : this.CreateDateTo);
    this.TravelDateFrom = (this.TravelDateFrom == null || this.TravelDateFrom == "" ? "01-Jan-1900" : this.TravelDateFrom);
    this.TravelDateTo = (this.TravelDateTo == null || this.TravelDateTo == "" ? "01-Jan-1900" : this.TravelDateTo);
    this.InvoiceDateFrom = (this.InvoiceDateFrom == null || this.InvoiceDateFrom == "" ? "01-Jan-1900" : this.InvoiceDateFrom);
    this.InvoiceDateTo = (this.InvoiceDateTo == null || this.InvoiceDateTo == "" ? "01-Jan-1900" : this.InvoiceDateTo);
    this.BookingDateFrom = (this.BookingDateFrom == null || this.BookingDateFrom == "" ? "01-Jan-1900" : this.BookingDateFrom);
    this.BookingDateTo = (this.BookingDateTo == null || this.BookingDateTo == "" ? "01-Jan-1900" : this.BookingDateTo);
    this.ReturnDateFrom = (this.ReturnDateFrom == null || this.ReturnDateFrom == "" ? "01-Jan-1900" : this.ReturnDateFrom);
    this.ReturnDateTo = (this.ReturnDateTo == null || this.ReturnDateTo == "" ? "01-Jan-1900" : this.ReturnDateTo);


    this.CreateDateFrom = this.common.ConvertToLocaleDate(this.CreateDateFrom);
    this.CreateDateTo = this.common.ConvertToLocaleDate(this.CreateDateTo);
    this.TravelDateFrom = this.common.ConvertToLocaleDate(this.TravelDateFrom);
    this.TravelDateTo = this.common.ConvertToLocaleDate(this.TravelDateTo);
    this.InvoiceDateFrom = this.common.ConvertToLocaleDate(this.InvoiceDateFrom);
    this.InvoiceDateTo = this.common.ConvertToLocaleDate(this.InvoiceDateTo);
    this.ReturnDateFrom = this.common.ConvertToLocaleDate(this.ReturnDateFrom);
    this.ReturnDateTo = this.common.ConvertToLocaleDate(this.ReturnDateTo);
    this.BookingDateFrom = this.common.ConvertToLocaleDate(this.BookingDateFrom);
    this.BookingDateTo = this.common.ConvertToLocaleDate(this.BookingDateTo);



    if ((this.CreateDateFrom == null || this.CreateDateFrom == "") && (this.TravelDateFrom == null || this.TravelDateFrom == "") && (this.InvoiceDateFrom == null || this.InvoiceDateFrom == "") && (this.BookingDateFrom == null || this.BookingDateFrom == "") && (this.ReturnDateFrom == null || this.ReturnDateFrom == "")) {
      this.common.showWARNINGtoastrmsg(
        "Messages.selectanyDate",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }
    if ((this.CreateDateFrom == "Invalid-Date" || this.CreateDateFrom == "Invalid-Date") || (this.TravelDateFrom == "Invalid-Date" || this.TravelDateFrom == "Invalid-Date") || (this.InvoiceDateFrom == "Invalid-Date" || this.InvoiceDateFrom == "Invalid-Date") || (this.BookingDateFrom == "Invalid-Date" || this.BookingDateFrom == "Invalid-Date") || (this.ReturnDateFrom == "Invalid-Date" || this.ReturnDateFrom == "Invalid-Date")) {
      this.common.showWARNINGtoastrmsg(
        "Messages.InvalidDate",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.CreateDateFrom)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidFromDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.CreateDateTo)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidToDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }

    if (Boolean(this.common.ValidDateRange(this.TravelDateFrom)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidFromDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.TravelDateTo)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidToDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }

    if (Boolean(this.common.ValidDateRange(this.InvoiceDateFrom)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidFromDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.InvoiceDateTo)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidToDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.BookingDateFrom)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidFromDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.BookingDateTo)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidToDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.ReturnDateFrom)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidFromDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.ReturnDateTo)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidToDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }

    if ((this.TravelDateTo == null || this.TravelDateTo == "01-Jan-1900") && (this.TravelDateFrom != null || this.TravelDateFrom != "")) {
      this.TravelDateTo = this.TravelDateFrom;
      // this.ATTravelDateTo = this.ATTravelDateFrom;
    }
    if ((this.CreateDateTo == null || this.CreateDateTo == "01-Jan-1900") && (this.CreateDateFrom != null || this.CreateDateFrom != "")) {
      this.CreateDateTo = this.CreateDateFrom;
      //this.ATCreateDateTo = this.ATCreateDateFrom;
    }
    if ((this.InvoiceDateTo == null || this.InvoiceDateTo == "01-Jan-1900") && (this.InvoiceDateFrom != null || this.InvoiceDateFrom != "")) {
      this.InvoiceDateTo = this.InvoiceDateFrom;
      // this.ATInvoiceDateTo = this.ATInvoiceDateFrom;
    }
    if ((this.BookingDateTo == null || this.BookingDateTo == "01-Jan-1900") && (this.BookingDateFrom != null || this.BookingDateFrom != "")) {
      this.BookingDateTo = this.BookingDateFrom;
      //this.ATBookingDateTo = this.ATBookingDateFrom;
    }
    if ((this.ReturnDateTo == null || this.ReturnDateTo == "01-Jan-1900") && (this.ReturnDateFrom != null || this.ReturnDateFrom != "")) {
      this.ReturnDateTo = this.ReturnDateFrom;
      // this.ATReturnDateTo = this.ATReturnDateFrom;
    }


    if (new Date(this.TravelDateTo) < new Date(this.TravelDateFrom)) {
      this.common.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 2000, true, false);
      this.spinner.hide();
      return;
    }
    if (new Date(this.CreateDateTo) < new Date(this.CreateDateFrom)) {
      this.common.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 2000, true, false);
      this.spinner.hide();
      return;
    }
    if (new Date(this.BookingDateTo) < new Date(this.BookingDateFrom)) {
      this.common.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 2000, true, false);
      this.spinner.hide();
      return;
    }
    if (new Date(this.InvoiceDateTo) < new Date(this.InvoiceDateFrom)) {
      this.common.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 2000, true, false);
      this.spinner.hide();
      return;
    }
    if (new Date(this.ReturnDateTo) < new Date(this.ReturnDateFrom)) {
      this.common.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 2000, true, false);
      this.spinner.hide();
      return;
    }
    if (this.BranchCode != null && this.BranchCode != "" && this.BranchCode != undefined) {
      if (Boolean(await this.common.BranchAuthorisation(this.BranchCode)) == false) {
        this.common.showWARNINGtoastrmsg(
          "Messages.branchAuthDenied",
          2000,
          true,
          false
        );
        this.spinner.hide();
        return;
      }

    }

    if (this.BArea != null && this.BArea != "" && this.BArea != undefined) {
      if (Boolean(await this.common.BAreaAuthorisation(this.BArea)) == false) {
        this.common.showWARNINGtoastrmsg(
          "Messages.busAreaAuthDenied",
          2000,
          true,
          false
        );
        this.spinner.hide();
        return;
      }

    }

    var date = (this.CreateDateFrom == "01-Jan-1900" ? this.TravelDateFrom : this.CreateDateFrom);
    date = (date == "01-Jan-1900" ? this.ReturnDateFrom : date);
    date = (date == "01-Jan-1900" ? this.InvoiceDateFrom : date);
    date = (date == "01-Jan-1900" ? this.BookingDateFrom : date);
    if (date == "01-Jan-1900") {
      this.common.showWARNINGtoastrmsg('Messages.empty_fromdate', 2000, true, false);
      this.spinner.hide();
      return;
    }
    this.CommonFunctions();

    if (this.SupplierName == "" && this.supplierGroupName == "" && this.Product == "" && this.GroupBy != "None" && this.GroupBy != "Individual Product"
      && this.GroupBy != "Products Sold" && this.GroupBy != "Supplier Group" && this.GroupBy != "Supplier" && (this.subGroupName == "" || this.subGroupName == "Staff")) {


      this.PrintHeaderSummaryReports();
    }
    else {
      ;

      if (this.currencyName != null && this.currencyName != undefined && this.currencyName != "") {
        this.FolderQueuryReportDTO.Currency = (this.currencyName).replace("'", "''");

      }

      if (this.currencyCode == "") {

        this.FolderQueuryReportDTO.Currency_varchar = "";
      }

      if (this.FolderQueuryReportDTO.Currency_varchar == "") {
        ;
        if ((this.supplierGroupName != "" || this.Supplier != 0 || this.typeOfInvoiceName != "(ALL)"
          || this.ProductCode != 0 || this.GroupBy == "Individual Product" || this.GroupBy == "Products Sold" || this.GroupBy == "Supplier Group" || this.GroupBy == "Supplier") && (this.subGroupName != "Monthly" && this.subGroupName != "Daily") && (this.GroupBy != "None")) {

          this.detailTable = 1;
          this.PrintProductsAndSupplierSummaryReports();
        }

        else if ((this.supplierGroupName != "" || this.Supplier != 0 || this.typeOfInvoiceName != "(ALL)"
          || this.ProductCode != 0 || this.GroupBy == "None")) {
          this.detailTable = 0;

        }

      }
      else {
        this.detailTable = 0;
      }


      if (this.detailTable == 0) {

        if (this.GroupBy != "None" && this.subGroupName == "Monthly") {
          this.FolderQueryMonthwiseReport();
        }
        else if (this.GroupBy != "None" && this.subGroupName == "Daily") {

          this.FolderQueryDatewiseReport();
        }
        else if (this.GroupBy == "None" && ((this.supplierSelected == "" || this.supplierSelected == undefined || this.supplierSelected == null) && (this.supplierGroupName == "" || this.supplierGroupName == null)
          && (this.ProductCode == 0 || this.ProductCode == null))) {
          ;
          this.GenerateData();

        }
        else if (this.GroupBy == "None" && ((this.supplierSelected != "" && this.supplierSelected != undefined) || (this.supplierGroupName != "" && this.supplierGroupName != undefined && this.supplierGroupName != null) || (this.Supplier != 0 && this.Supplier != undefined && this.Supplier != null)
          || (this.ProductCode != 0 && this.ProductCode != undefined && this.ProductCode != null))) {
          //  this.GenerateReportForProduct();
          this.GenerateData();
        }

      }

    }

    this.moduleUsageService.ModuleUsageDTO.reportname = "PWR_Folder Query Report";
    // this.FolderQueuryReportDTO.ExportPDFParam = "";
    this.FolderQueuryReportDTO.ExportPDFParam = this.ExportPDFParamList;
    this.moduleUsageService.ModuleUsageDTO.ReportCriteria = JSON.stringify(this.FolderQueuryReportDTO);
    this.moduleUsageService.getModuleUsageReport().subscribe(res => {
      ;
    })

  }

  GenerateReportForProduct() {
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO = new FolderQueryReportFilterDTO;
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.ReportName = "CrptFolderQueryPRGroupByStaff";
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.rptfilename = "CrptFolderQueryPRGroupByStaff";
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.ReportType = "CrptFolderQueryPRGroupByStaff";
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.FormTitle = "Staff Performance Report";
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.UserId = this.userID;
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.rptFolderQueryReportDTO = this.FolderQueuryReportDTO;
    this.reportFile = "CrptFolderQueryPRGroupByStaff";
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.reportParmsc = JSON.stringify(this.FolderQueuryReportDTO);
    this.selectedReport = "CrptFolderQueryPRGroupByStaff";
    this.ReportSubject = "Folder Query " + this.Folderqueryreport.ObjFolderQueryReportFilterDTO.FormTitle;
    this.showCrystalReport(this.selectedReport);
  }
  FolderQueryDatewiseReport() {
    if (this.GroupBy != "None" && this.subGroupName == "Daily") {
      ;
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO = new FolderQueryReportFilterDTO;
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.ReportName = "CrptFolderQueryPRGrpByDatewiseSummary";
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.rptfilename = "CrptFolderQueryPRGrpByDatewiseSummary";
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.ReportType = "FolderQueryPRGrpByDatewiseSummary";
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.FormTitle = "Date wise " + this.GroupBy + " Summary";
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.UserId = this.userID;
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.rptFolderQueryReportDTO = this.FolderQueuryReportDTO;
      this.reportFile = "CrptFolderQueryPRGrpByDatewiseSummary";
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.reportParmsc = JSON.stringify(this.FolderQueuryReportDTO);
      this.selectedReport = "CrptFolderQueryPRGrpByDatewiseSummary";
      this.ReportSubject = "Folder Query " + this.Folderqueryreport.ObjFolderQueryReportFilterDTO.FormTitle;
      this.showCrystalReport(this.selectedReport);
    }
  }
  FolderQueryMonthwiseReport() {
    if (this.GroupBy != "None" && this.subGroupName == "Monthly") {
      ;
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO = new FolderQueryReportFilterDTO;
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.ReportName = "CrptFolderQueryPRGrpByMonthwiseSummary";
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.rptfilename = "CrptFolderQueryPRGrpByMonthwiseSummary";
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.ReportType = "FolderQueryPRGrpByMonthwiseSummary";
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.FormTitle = "Month wise " + this.GroupBy + " Summary";
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.UserId = this.userID;
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.rptFolderQueryReportDTO = this.FolderQueuryReportDTO;
      this.reportFile = "CrptFolderQueryPRGrpByMonthwiseSummary";
      this.ReportSubject = "Folder Query " + this.Folderqueryreport.ObjFolderQueryReportFilterDTO.FormTitle;
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.reportParmsc = JSON.stringify(this.FolderQueuryReportDTO);
      this.selectedReport = "CrptFolderQueryPRGrpByMonthwiseSummary";

      this.showCrystalReport(this.selectedReport);
    }

  }
  PrintHeaderSummaryReports() {

    ;
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO = new FolderQueryReportFilterDTO;
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.ReportName = "FolderQueryHeaderSummaryReport";
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.rptfilename = "FolderQueryHeaderSummaryReport";
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.ReportType = "FolderQueryHeaderSummaryReport";
    if (this.GroupBy == "Individual Product" || this.GroupBy == "Products Sold") {
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.FormTitle = "Product Performance Report";

    }
    else {
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.FormTitle = this.GroupBy + " Performance Report";
    }
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.UserId = this.userID;
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.rptFolderQueryReportDTO = this.FolderQueuryReportDTO;
    this.reportFile = "FolderQueryHeaderSummaryReport";
    this.ReportSubject = "Folder Query " + this.Folderqueryreport.ObjFolderQueryReportFilterDTO.FormTitle;
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.reportParmsc = JSON.stringify(this.FolderQueuryReportDTO);
    this.selectedReport = "FolderQueryHeaderSummaryReport";
    this.showCrystalReport(this.selectedReport);

  }
  PrintProductsAndSupplierSummaryReports() {
    ;
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO = new FolderQueryReportFilterDTO;
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.ReportName = "CrptFolderQueryPRGrpBySummary";
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.rptfilename = "CrptFolderQueryPRGrpBySummary";
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.ReportType = "FolderQueryPRGrpBySummary";
    if (this.GroupBy == "Individual Product" || this.GroupBy == "Products Sold") {
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.FormTitle = "Product Performance Report";

    }
    else {
      this.Folderqueryreport.ObjFolderQueryReportFilterDTO.FormTitle = this.GroupBy + " Performance Report";
    }
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.UserId = this.userID;
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.rptFolderQueryReportDTO = this.FolderQueuryReportDTO;
    this.reportFile = "CrptFolderQueryPRGrpBySummary";
    this.ReportSubject = "Folder Query " + this.Folderqueryreport.ObjFolderQueryReportFilterDTO.FormTitle;
    this.Folderqueryreport.ObjFolderQueryReportFilterDTO.reportParmsc = JSON.stringify(this.FolderQueuryReportDTO);
    this.selectedReport = "CrptFolderQueryPRGrpBySummary";
    this.showCrystalReport(this.selectedReport);
  }



  CommonFunctions() {
    ;
    this.FolderQueuryReportDTO = new FolderQueuryReportDTO();
    if (this.profitFrom != "") {
      this.FolderQueuryReportDTO.ProfitFrom = this.profitFrom;
    }
    if (this.profitTo != "") {
      this.FolderQueuryReportDTO.ProfitTo = this.profitTo;
    }
    if (this.profitPerFrom != "") {
      this.FolderQueuryReportDTO.ProfitPerFrom = this.profitPerFrom;
    }
    if (this.profitPerTo != "") {
      this.FolderQueuryReportDTO.ProfitPerTo = this.profitPerTo;
    }
    if (this.CreatedUserName != "") {
      this.FolderQueuryReportDTO.User = this.CreatedUser;
    }



    this.FolderQueuryReportDTO.CreationFromdate = this.CreateDateFrom;
    this.FolderQueuryReportDTO.CreationTodate = this.CreateDateTo;
    this.FolderQueuryReportDTO.BookingFromdate = this.BookingDateFrom;
    this.FolderQueuryReportDTO.BookingTodate = this.BookingDateTo;
    this.FolderQueuryReportDTO.ReturnFromdate = this.ReturnDateFrom;
    this.FolderQueuryReportDTO.ReturnTodate = this.ReturnDateTo;
    this.FolderQueuryReportDTO.TravelFromdate = this.TravelDateFrom;
    this.FolderQueuryReportDTO.TravelTodate = this.TravelDateTo;
    this.FolderQueuryReportDTO.InvFromdate = this.InvoiceDateFrom;
    this.FolderQueuryReportDTO.InvTodate = this.InvoiceDateTo;
    this.FolderQueuryReportDTO.FolderNoFrom = this.FolderNo == null ? "" : this.FolderNo;
    this.FolderQueuryReportDTO.OrderNoFrom = this.OrderNo == null ? "" : this.OrderNo;
    this.FolderQueuryReportDTO.BookedBy = this.BookedByName;
    this.FolderQueuryReportDTO.ProfitPerFrom = this.profitPerFrom == null ? "" : this.profitPerFrom;
    this.FolderQueuryReportDTO.ProfitPerTo = this.profitPerTo == null ? "" : this.profitPerTo;
    this.FolderQueuryReportDTO.ProfitTo = this.profitTo == null ? "" : this.profitTo;
    this.FolderQueuryReportDTO.ProfitFrom = this.profitFrom == null ? "" : this.profitFrom;

    this.FolderQueuryReportDTO.VairlineAIRID = this.VairlineAIRID == null ? "" : this.VairlineAIRID;
    //this.FolderQueuryReportDTO.OrderType_varchar = this.orderTypeCode;
    this.FolderQueuryReportDTO.ExcludeTOMSVat = this.ExcludeTOMS == true ? "ON" : "OFF";
    this.FolderQueuryReportDTO.ExcludePDQCharge = this.ExcludePDQ1 == true ? "ON" : "OFF";
    this.FolderQueuryReportDTO.Quotes = this.enableIncludeQuotes == true ? "ON" : "OFF";
    this.FolderQueuryReportDTO.IncludeQuotesPO = this.IncludeQuoteswithPO == true ? "ON" : "OFF";
    this.FolderQueuryReportDTO.Cancelled = this.IncludeCancelled == true ? "ON" : "OFF";
    this.FolderQueuryReportDTO.ModuleName = this.ModuleName;
    this.FolderQueuryReportDTO.SubGroup = this.subGroupName == null ? "" : this.subGroupName;
    this.FolderQueuryReportDTO.UserId = this.userID;
    this.FolderQueuryReportDTO.CustUserCode = this.Customer == null ? "" : this.Customer;
    this.FolderQueuryReportDTO.UserName = this.CreatedUserName;
    this.FolderQueuryReportDTO.Currency_varchar = this.currencyCode;

    if (this.currencyName != null && this.currencyName != undefined) {
      this.FolderQueuryReportDTO.Currency = (this.currencyName).replace("'", "''");
    }
    if (this.currencyCode == null && this.currencyCode == undefined) {

      this.FolderQueuryReportDTO.Currency_varchar = "";
    }

    this.FolderQueuryReportDTO.QuotesOnly = this.enableIncludeQuotes == true ? 1 : 0;
    this.FolderQueuryReportDTO.IncludeQuoteswithPO = this.IncludeQuoteswithPO == true ? 1 : 0;
    this.FolderQueuryReportDTO.ExcludePDQCharge_int = this.ExcludePDQ1 == true ? 1 : 0;
    this.FolderQueuryReportDTO.ExcludeTOMSVat_int = this.ExcludeTOMS == true ? 1 : 0;
    this.FolderQueuryReportDTO.CancelledCheck = this.IncludeCancelled == true ? 1 : 0;

    this.PassQuery = "";
    this.CrystalRptQuery = "";
    this.type = "MCCode";
    var MarketingCodes = '';
    var MarketingNames = '';
    this.TableandField = "Fld.MCID";

    if (this.MCodeMultipleSelected != null && this.MCodeMultipleSelected.SelectedItems != "") {

      this.MCodeMultipleSelectedItems = this.MCodeMultipleSelected.SelectedItems;
      this.MCodeMultipleSelectedItems.forEach(element1 => {
        ;
        if (MarketingCodes == "") {
          MarketingCodes = "'" + element1.Code + "'";
          MarketingNames = "'" + element1.Name + "'";
        } else {
          MarketingCodes = MarketingCodes + ',' + "'" + element1.Code + "'";
          MarketingNames = MarketingNames + ',' + "'" + element1.Name + "'";
        }
      })

      if (this.MCodeMultipleSelected.isIncluded) {

        this.MCQuery = this.TableandField + " IN (" + MarketingCodes + ")";
        this.commoncrystalqry = this.TableandField + " IN (" + MarketingNames + ")";
        this.MarketingSelectCrystalQry = this.commoncrystalqry;


        if (this.PassQuery != "") {
          this.PassQuery = this.PassQuery + " and " + this.MCQuery;
        }
        else {
          this.PassQuery = this.MCQuery;
        }

      } else if (!this.MCodeMultipleSelected.isIncluded) {

        this.MCQuery = this.TableandField + " NOT IN (" + MarketingCodes + ")";
        this.commoncrystalqry = this.TableandField + " NOT IN (" + MarketingNames + ")";
        this.MarketingSelectCrystalQry = this.commoncrystalqry;

        if (this.PassQuery != "") {
          this.PassQuery = this.PassQuery + " and " + this.MCQuery;
        }
        else {
          this.PassQuery = this.MCQuery;
        }
      }

      if (this.CrystalRptQuery != "" && this.MarketingSelectCrystalQry != "") {
        this.CrystalRptQuery = this.CrystalRptQuery + " && " + this.MarketingSelectCrystalQry;
      }
      else if (this.CrystalRptQuery == "" && this.MarketingSelectCrystalQry != "") {
        this.CrystalRptQuery = this.MarketingSelectCrystalQry;
      }


      this.markettingCode = "{MULTISELECT}"
    }


    var BranchCodes = '';
    var BranchNames = '';
    this.TableandField = "Fld.BRID";
    this.type = "BRID";

    this.BRQuery = "";

    if (this.BRMultipleSelected != null && this.BRMultipleSelected.SelectedItems != "") {
      
      this.BRMultipleSelectedItems = this.BRMultipleSelected.SelectedItems;
      this.BRMultipleSelectedItems.forEach(element1 => {
        ;
        if (BranchCodes == "") {
          BranchCodes = "'" + element1.Code + "'";
          BranchNames = "'" + element1.Name + "'";
        } else {
          BranchCodes = BranchCodes + ',' + "'" + element1.Code + "'";
          BranchNames = BranchNames + ',' + "'" + element1.Name + "'";
        }
      })

      if (this.BRMultipleSelected.isIncluded) {

        this.MCQuery = this.TableandField + " IN (" + BranchCodes + ")";
        this.commoncrystalqry = this.TableandField + " IN (" + BranchNames + ")";
        this.BranchSelectCrystalQry = this.commoncrystalqry;
        this.BRQuery = this.MCQuery;

      } else {

        this.MCQuery = this.TableandField + " NOT IN (" + BranchCodes + ")";
        this.commoncrystalqry = this.TableandField + " NOT IN (" + BranchNames + ")";
        this.BranchSelectCrystalQry = this.commoncrystalqry;
        this.BRQuery = this.MCQuery;

      }
      if (this.PassQuery != "" && this.BRQuery != "") {
        this.PassQuery = this.PassQuery + " and " + this.BRQuery;
      }
      else if (this.PassQuery == "" && this.BRQuery != "") {
        this.PassQuery = this.BRQuery;
      }



      if (this.CrystalRptQuery != "" && this.BranchSelectCrystalQry != "") {
        this.CrystalRptQuery = this.CrystalRptQuery + " && " + this.BranchSelectCrystalQry;
      }
      else if (this.CrystalRptQuery == "" && this.BranchSelectCrystalQry != "") {
        this.CrystalRptQuery = this.BranchSelectCrystalQry;
      }
      this.BranchCode = "{MULTISELECT}"
    }



    var BACodes = '';
    var BANames = '';
    this.TableandField = "Fld.BAID";
    this.type = "BAID";
    this.BAQuery = "";
    if (this.BAMultipleSelected != null && this.BAMultipleSelected.SelectedItems != "") {

      this.BAMultipleSelectedItems = this.BAMultipleSelected.SelectedItems;
      this.BAMultipleSelectedItems.forEach(element1 => {
        ;
        if (BACodes == "") {
          BACodes = "'" + element1.Code + "'";
          BANames = "'" + element1.Name + "'";
        } else {
          BACodes = BACodes + ',' + "'" + element1.Code + "'";
          BANames = BANames + ',' + "'" + element1.Name + "'";
        }
      })

      if (this.BAMultipleSelected.isIncluded) {

        this.MCQuery = this.TableandField + " IN (" + BACodes + ")";
        this.commoncrystalqry = this.TableandField + " IN (" + BANames + ")";
        this.BusAreaSelectCrystalQry = this.commoncrystalqry;
        this.BAQuery = this.MCQuery;


      } else {

        this.MCQuery = this.TableandField + " NOT IN (" + BACodes + ")";
        this.commoncrystalqry = this.TableandField + " NOT IN (" + BANames + ")";
        this.BusAreaSelectCrystalQry = this.commoncrystalqry;
        this.BAQuery = this.MCQuery;

      }
      if (this.PassQuery != "" && this.BAQuery != "") {
        this.PassQuery = this.PassQuery + " and " + this.BAQuery;
      }
      else if (this.PassQuery == "" && this.BAQuery != "") {
        this.PassQuery = this.BAQuery;
      }


      if (this.CrystalRptQuery != "" && this.BusAreaSelectCrystalQry != "") {
        this.CrystalRptQuery = this.CrystalRptQuery + " && " + this.BusAreaSelectCrystalQry;
      }
      else if (this.CrystalRptQuery == "" && this.BusAreaSelectCrystalQry != "") {
        this.CrystalRptQuery = this.BusAreaSelectCrystalQry;
      }
      this.BArea = "{MULTISELECT}";
    }


    var DestinationCodes = '';
    var DestinationNames = '';
    this.TableandField = "Fld.ARRIVALPT";
    this.type = "ARRIVALPT";
    this.DesQuery = "";

    this.DestinationFrom == "";

    if (this.DestinationMultipleSelected != null && this.DestinationMultipleSelected.SelectedItems != "") {

      this.DestinationMultipleSelectedItems = this.DestinationMultipleSelected.SelectedItems;
      this.DestinationMultipleSelectedItems.forEach(element1 => {
        ;
        if (DestinationCodes == "") {
          DestinationCodes = "'" + element1.Code + "'";
          DestinationNames = "'" + element1.Name + "'";
        } else {
          DestinationCodes = DestinationCodes + ',' + "'" + element1.Code + "'";
          DestinationNames = DestinationNames + ',' + "'" + element1.Name + "'";
        }
      })

      if (this.DestinationMultipleSelected.isIncluded) {

        this.MCQuery = this.TableandField + " IN (" + DestinationCodes + ")";
        this.commoncrystalqry = this.TableandField + " IN (" + DestinationNames + ")";
        this.DestinationSelectCrystalQry = this.commoncrystalqry;
        this.DesQuery = this.MCQuery;


      } else {

        this.MCQuery = this.TableandField + " NOT IN (" + DestinationCodes + ")";
        this.commoncrystalqry = this.TableandField + " NOT IN (" + DestinationNames + ")";
        this.DestinationSelectCrystalQry = this.commoncrystalqry;
        this.DesQuery = this.MCQuery;

      }
      if (this.PassQuery != "" && this.DesQuery != "") {
        this.PassQuery = this.PassQuery + " and " + this.DesQuery;
      }
      else if (this.PassQuery == "" && this.DesQuery != "") {
        this.PassQuery = this.DesQuery;
      }

      if (this.CrystalRptQuery != "" && this.DestinationSelectCrystalQry != "") {
        this.CrystalRptQuery = this.CrystalRptQuery + " && " + this.DestinationSelectCrystalQry;
      }
      else if (this.CrystalRptQuery == "" && this.DestinationSelectCrystalQry != "") {
        this.CrystalRptQuery = this.DestinationSelectCrystalQry;
      }
      this.Destination = "{MULTISELECT}";
    }

    var CustCodes = '';
    var CustNames = '';
    this.TableandField = "Fld.PRTYCODE";
    this.type = "Customer";
    this.CustomerQuery = "";
    if (this.CustomerMultipleSelected != null && this.CustomerMultipleSelected.SelectedItems != "") {
      ;
      this.CustomerMultipleSelectedItems = this.CustomerMultipleSelected.SelectedItems;
      this.CustomerMultipleSelectedItems.forEach(element1 => {
        ;
        if (CustCodes == "") {
          CustCodes = "'" + element1.Code + "'";
          CustNames = "'" + element1.Name + "'";

        } else {
          CustCodes = CustCodes + ',' + "'" + element1.Code + "'";
          CustNames = CustNames + ',' + "'" + element1.Name + "'";
        }
      })

      if (this.CustomerMultipleSelected.isIncluded) {

        this.MCQuery = this.TableandField + " IN (" + CustCodes + ")";
        this.commoncrystalqry = this.TableandField + " IN " + CustNames + "";
        this.CustomerSelectCrystalQry = this.commoncrystalqry;
        this.CustomerQuery = this.MCQuery;


      } else {

        this.MCQuery = this.TableandField + " NOT IN (" + CustCodes + ")";
        this.commoncrystalqry = this.TableandField + " NOT IN " + CustNames + "";
        this.CustomerSelectCrystalQry = this.commoncrystalqry;
        this.CustomerQuery = this.MCQuery;

      }
      if (this.PassQuery != "" && this.CustomerQuery != "") {
        this.PassQuery = this.PassQuery + " and " + this.CustomerQuery;
      }
      else if (this.PassQuery == "" && this.CustomerQuery != "") {
        this.PassQuery = this.CustomerQuery;
      }


      if (this.CrystalRptQuery != "" && this.CustomerSelectCrystalQry != "") {
        this.CrystalRptQuery = this.CrystalRptQuery + " && " + this.CustomerSelectCrystalQry;
      }
      else if (this.CrystalRptQuery == "" && this.CustomerSelectCrystalQry != "") {
        this.CrystalRptQuery = this.CustomerSelectCrystalQry;
      }
      this.customerSelected = "{MULTISELECT}";
    }

    var SupplierCodes = '';
    var SuppNames = '';
    this.TableandField = "FldPrice.SUPPPRTYCODE";
    this.type = "Supplier";
    this.SupplierQuery = "";

    if (this.SupplierMultipleSelected != null && this.SupplierMultipleSelected.SelectedItems != "") {

      this.SupplierMultipleSelectedItems = this.SupplierMultipleSelected.SelectedItems;
      this.SupplierMultipleSelectedItems.forEach(element1 => {
        ;
        if (SupplierCodes == "") {
          SupplierCodes = "'" + element1.Code + "'";
          SuppNames = "'" + element1.Name + "'";

        } else {
          SupplierCodes = SupplierCodes + ',' + "'" + element1.Code + "'";
          SuppNames = SuppNames + ',' + "'" + element1.Name + "'";
        }
      })

      if (this.SupplierMultipleSelected.isIncluded) {

        this.MCQuery = this.TableandField + " IN (" + SupplierCodes + ")";
        this.commoncrystalqry = this.TableandField + " IN (" + SuppNames + ")";
        this.SupplierSelectCrystalQry = this.commoncrystalqry;
        this.SupplierQuery = this.MCQuery;


      } else {

        this.MCQuery = this.TableandField + " NOT IN (" + SupplierCodes + ")";
        this.commoncrystalqry = this.TableandField + " NOT IN (" + SuppNames + ")";
        this.SupplierSelectCrystalQry = this.commoncrystalqry;
        this.SupplierQuery = this.MCQuery;

      }
      if (this.PassQuery != "" && this.SupplierQuery != "") {
        this.PassQuery = this.PassQuery + " and " + this.SupplierQuery;
      }
      else if (this.PassQuery == "" && this.SupplierQuery != "") {
        this.PassQuery = this.SupplierQuery;
      }


      if (this.CrystalRptQuery != "" && this.SupplierSelectCrystalQry != "") {
        this.CrystalRptQuery = this.CrystalRptQuery + " && " + this.SupplierSelectCrystalQry;
      }
      else if (this.CrystalRptQuery == "" && this.SupplierSelectCrystalQry != "") {
        this.CrystalRptQuery = this.SupplierSelectCrystalQry;
      }
      this.Supplier = "{MULTISELECT}";
    }


    var AirwaysCodes = '';
    var AirwaysNames = '';
    this.TableandField = "Fld.VairlineAIRID";
    this.type = "AIRWAYS";
    this.AirlineQuery = "";
    if (this.AirlineMultipleSelected != null && this.AirlineMultipleSelected.SelectedItems != "") {

      this.AirlineMultipleSelectedItems = this.AirlineMultipleSelected.SelectedItems;
      this.AirlineMultipleSelectedItems.forEach(element1 => {
        ;
        if (AirwaysCodes == "") {
          AirwaysCodes = "'" + element1.Code + "'";
          AirwaysNames = "'" + element1.Name + "'";

        } else {
          AirwaysCodes = AirwaysCodes + ',' + "'" + element1.Code + "'";
          AirwaysNames = AirwaysNames + ',' + "'" + element1.Name + "'";
        }
      })

      if (this.AirlineMultipleSelected.isIncluded) {

        this.MCQuery = this.TableandField + " IN (" + AirwaysCodes + ")";
        this.commoncrystalqry = this.TableandField + " IN (" + AirwaysNames + ")";
        this.AirlineCrystalQuery = this.commoncrystalqry;
        this.AirlineQuery = this.MCQuery;


      } else {

        this.MCQuery = this.TableandField + " NOT IN (" + AirwaysCodes + ")";
        this.commoncrystalqry = this.TableandField + " NOT IN (" + AirwaysNames + ")";
        this.AirlineCrystalQuery = this.commoncrystalqry;
        this.AirlineQuery = this.MCQuery;

      }
      if (this.PassQuery != "" && this.AirlineQuery != "") {
        this.PassQuery = this.PassQuery + " and " + this.AirlineQuery;
      }
      else if (this.PassQuery == "" && this.AirlineQuery != "") {
        this.PassQuery = this.AirlineQuery;
      }
      if (this.CrystalRptQuery != "" && this.AirlineCrystalQuery != "") {
        this.CrystalRptQuery = this.CrystalRptQuery + " && " + this.AirlineCrystalQuery;
      }
      else if (this.CrystalRptQuery == "" && this.AirlineCrystalQuery != "") {
        this.CrystalRptQuery = this.AirlineCrystalQuery;
      }
      this.Airline = "{MULTISELECT}";
    }



    var CountryCodes = '';
    var CountryNames = '';
    this.TableandField = "CITYCODES.CID";
    this.type = "COUNTRY";
    this.CountryQuery = "";

    if (this.CountryMultipleSelected != null && this.CountryMultipleSelected.SelectedItems != "") {

      ;
      this.CountryMultipleSelectedItems = this.CountryMultipleSelected.SelectedItems;
      this.CountryMultipleSelectedItems.forEach(element1 => {
        ;
        if (CountryCodes == "") {
          CountryCodes = "'" + element1.Code + "'";
          CountryNames = "'" + element1.Name + "'";

        } else {
          CountryCodes = CountryCodes + ',' + "'" + element1.Code + "'";
          CountryNames = CountryNames + ',' + "'" + element1.Name + "'";

        }
      })

      if (this.CountryMultipleSelected.isIncluded) {

        this.MCQuery = this.TableandField + " IN (" + CountryCodes + ")";
        this.commoncrystalqry = this.TableandField + " IN (" + CountryNames + ")";
        this.CountryQryCrystalRpt = this.commoncrystalqry;
        this.CountryQuery = this.MCQuery;


      } else {

        this.MCQuery = this.TableandField + " NOT IN (" + CountryCodes + ")";
        this.commoncrystalqry = this.TableandField + " NOT IN (" + CountryNames + ")";
        this.CountryQryCrystalRpt = this.commoncrystalqry;
        this.CountryQuery = this.MCQuery;

      }
      if (this.PassQuery != "" && this.CountryQuery != "") {
        this.PassQuery = this.PassQuery + " and " + this.CountryQuery;
      }
      else if (this.PassQuery == "" && this.CountryQuery != "") {
        this.PassQuery = this.CountryQuery;
      }
      if (this.CrystalRptQuery != "" && this.CountryQryCrystalRpt != "") {
        this.CrystalRptQuery = this.CrystalRptQuery + " && " + this.CountryQryCrystalRpt;
      }
      else if (this.CrystalRptQuery == "" && this.CountryQryCrystalRpt != "") {
        this.CrystalRptQuery = this.CountryQryCrystalRpt;
      }
      this.countryCode = "{MULTISELECT}";
    }
    if (this.Airline != "") {
      this.FolderQueuryReportDTO.VairlineAIRID = this.Airline;
    }

    if (this.Customer != "") {
      this.FolderQueuryReportDTO.CustomerCode = this.PartyCode;
    }





    if (this.currencyName != null && this.currencyName != undefined) {
      this.FolderQueuryReportDTO.Currency = (this.currencyName).replace("'", "''");
    }
    if (this.currencyCode == null && this.currencyCode == undefined) {

      this.FolderQueuryReportDTO.Currency_varchar = "";
    }

    this.FolderQueuryReportDTO.VairlineAIRID = this.VairlineAIRID == null ? "" : this.VairlineAIRID;
    this.FolderQueuryReportDTO.Currency_varchar = this.currencyCode;
    this.FolderQueuryReportDTO.YourReference = this.YourReference == null ? "" : this.YourReference;
    this.FolderQueuryReportDTO.Customer_Group = this.customerGroupCode == null ? "" : this.customerGroupCode;
    this.FolderQueuryReportDTO.CustomerGroupName = this.customerGroupName == null ? "" : this.customerGroupName;
    this.FolderQueuryReportDTO.Country_varchar = this.countryCode;
    this.FolderQueuryReportDTO.Country = this.countryName == null ? "" : this.countryName;
    this.FolderQueuryReportDTO.TypeOfInvoice_nvarchar = this.typeOfInvoiceName;
    this.FolderQueuryReportDTO.GroupBy_varchar = this.GroupBy;
    this.FolderQueuryReportDTO.OrderType_varchar = this.orderTypeCode;
    this.FolderQueuryReportDTO.OrderTypeName = this.orderTypeName;
    this.FolderQueuryReportDTO.PaymentStatus = this.paymentStatusName;
    this.FolderQueuryReportDTO.PaymentStatus_tinyint = this.paymentStatus;
    this.FolderQueuryReportDTO.BookingStatus = this.bookingstatus;
    this.FolderQueuryReportDTO.Status = this.Status;
    this.FolderQueuryReportDTO.SupplierCode = this.Supplier == null ? "" : this.Supplier;
    this.FolderQueuryReportDTO.Supplier = this.SupplierName;
    this.FolderQueuryReportDTO.ProductCode = this.ProductCode;
    this.FolderQueuryReportDTO.Product = this.Product == null ? "" : this.Product;
    this.FolderQueuryReportDTO.Supplier_Group = this.supplierGroupCode == null ? "" : this.supplierGroupCode;
    this.FolderQueuryReportDTO.SupplierGroup = this.supplierGroupName == null ? "" : this.supplierGroupName;
    this.FolderQueuryReportDTO.MarkettingCode = this.markettingCode == null ? "" : this.markettingCode;
    this.FolderQueuryReportDTO.BusinessArea = this.BArea == null ? "" : this.BArea;
    this.FolderQueuryReportDTO.Branch = this.BranchCode == null ? "" : this.BranchCode;
    this.FolderQueuryReportDTO.BranchName = this.Branch == undefined ? "" : this.Branch;
    this.FolderQueuryReportDTO.Destination = this.Destination == null ? "" : this.Destination;
    this.FolderQueuryReportDTO.MarkettingCodeName = this.markettingCodeName == null ? "" : this.markettingCodeName;
    this.FolderQueuryReportDTO.StaffGroup = this.staffGroupCode == null ? "" : this.staffGroupCode;
    this.FolderQueuryReportDTO.StaffGroupName = this.staffGroupName == null ? "" : this.staffGroupName;
    this.FolderQueuryReportDTO.StatusName = this.StatusName == null ? "" : this.StatusName;
    this.FolderQueuryReportDTO.BArea = this.BusinessArea == undefined ? "" : this.BusinessArea;
    this.FolderQueuryReportDTO.PassQuery = this.PassQuery;
    this.FolderQueuryReportDTO.CustomerName = this.customerSelected == null ? "" : this.customerSelected;
    this.FolderQueuryReportDTO.CrystalRptQuery = this.CrystalRptQuery;


    // this.BAreaAuthorisation();
    // this.BranchAuthorisation();
    ;


    if ((this.supplierGroupName != "" || this.SupplierCode != 0
      || this.ProductCode != 0 || this.GroupBy == "Individual Product" || this.GroupBy == "Products Sold" || this.GroupBy == "Supplier Group" || this.GroupBy == "Supplier") && (this.GroupBy != "None")) {

      this.detailTable = 1;
    }
    else {
      this.detailTable = 0;

    }
    this.FolderQueuryReportDTO.detailTable = this.detailTable;
    this.FolderQueuryReportDTO.ExportPDFParam = this.ExportPDFParamList;
    this.Folderqueryreport.formData = this.FolderQueuryReportDTO;
  }
  GenerateData() {
    ;
    this.ExportPDFParamList = [];
    var filter = new ExportPDFParam();
    if ((this.CreateDateTo == undefined || this.CreateDateTo == null)) {

      this.CreateDateTo = null;
    }
    else {
      this.CreateDateTo = this.common.ConvertToLocaleDate(this.CreateDateTo);
      this.CreateDateFrom = this.common.ConvertToLocaleDate(this.CreateDateFrom);
      if (this.CreateDateFrom != "01-Jan-1900") {
        var filter = new ExportPDFParam();
        filter.Filter = this.common.GetTransilationMessage('Labels.creation_date');
        filter.FirstValue = this.CreateDateFrom + '-' + this.CreateDateTo;
        this.ExportPDFParamList.push(filter);
      }
    }
    if ((this.TravelDateTo == undefined || this.TravelDateTo == null)) {

      this.TravelDateTo = null;
    }
    else {
      this.TravelDateTo = this.common.ConvertToLocaleDate(this.TravelDateTo);
      this.TravelDateFrom = this.common.ConvertToLocaleDate(this.TravelDateFrom);
      if (this.TravelDateFrom != "01-Jan-1900") {
        var filter = new ExportPDFParam();
        filter.Filter = this.common.GetTransilationMessage('Labels.travel_date');
        filter.FirstValue = this.TravelDateFrom + '-' + this.TravelDateTo;
        this.ExportPDFParamList.push(filter);
      }

    }
    if ((this.InvoiceDateTo == undefined || this.InvoiceDateTo == null)) {

      this.InvoiceDateTo = null;
    }
    else {
      this.InvoiceDateTo = this.common.ConvertToLocaleDate(this.InvoiceDateTo);
      this.InvoiceDateFrom = this.common.ConvertToLocaleDate(this.InvoiceDateFrom);
      if (this.InvoiceDateFrom != "01-Jan-1900") {
        var filter = new ExportPDFParam();
        filter.Filter = this.common.GetTransilationMessage('Labels.travel_date');
        filter.FirstValue = this.InvoiceDateFrom + '-' + this.InvoiceDateTo;
        this.ExportPDFParamList.push(filter);
      }

    }
    if ((this.InvoiceDateTo == undefined || this.InvoiceDateTo == null)) {

      this.InvoiceDateTo = null;
    }
    else {
      this.InvoiceDateTo = this.common.ConvertToLocaleDate(this.InvoiceDateTo);
      this.InvoiceDateFrom = this.common.ConvertToLocaleDate(this.InvoiceDateFrom);
      if (this.InvoiceDateFrom != "01-Jan-1900") {
        var filter = new ExportPDFParam();
        filter.Filter = this.common.GetTransilationMessage('Labels.invoice_date');
        filter.FirstValue = this.InvoiceDateFrom + '-' + this.InvoiceDateTo;
        this.ExportPDFParamList.push(filter);
      }

    }
    if ((this.ReturnDateTo == undefined || this.ReturnDateTo == null)) {

      this.ReturnDateTo = null;
    }
    else {
      this.ReturnDateTo = this.common.ConvertToLocaleDate(this.ReturnDateTo);
      this.ReturnDateFrom = this.common.ConvertToLocaleDate(this.ReturnDateFrom);
      if (this.ReturnDateFrom != "01-Jan-1900") {
        var filter = new ExportPDFParam();
        filter.Filter = this.common.GetTransilationMessage('Labels.return_date');
        filter.FirstValue = this.ReturnDateFrom + '-' + this.ReturnDateTo;
        this.ExportPDFParamList.push(filter);
      }

    }
    if ((this.BookingDateTo == undefined || this.BookingDateTo == null)) {

      this.BookingDateTo = null;
    }
    else {
      this.BookingDateTo = this.common.ConvertToLocaleDate(this.BookingDateTo);
      this.BookingDateFrom = this.common.ConvertToLocaleDate(this.BookingDateFrom);
      if (this.BookingDateFrom != "01-Jan-1900") {
        var filter = new ExportPDFParam();
        filter.Filter = this.common.GetTransilationMessage('Labels.booking_date');
        filter.FirstValue = this.BookingDateFrom + '-' + this.BookingDateTo;
        this.ExportPDFParamList.push(filter);
      }

    }
    if ((this.FolderNo == undefined || this.FolderNo == '')) {
      this.FolderNo = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.folderNo');
      filter.FirstValue = this.FolderNo;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.OrderNo == undefined || this.OrderNo == '')) {
      this.OrderNo = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.orderNo');
      filter.FirstValue = this.OrderNo;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.BranchCode == undefined || this.BranchCode == '')) {
      this.BranchCode = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.branch');
      filter.FirstValue = this.BranchCode;
      this.ExportPDFParamList.push(filter);
    }

    if ((this.BArea == undefined || this.BArea == '')) {
      this.BArea = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.businessArea');
      filter.FirstValue = this.BArea;
      this.ExportPDFParamList.push(filter);
    }

    if ((this.Destination == undefined || this.Destination == '')) {
      this.Destination = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.destination');
      filter.FirstValue = this.Destination;
      this.ExportPDFParamList.push(filter);
    }

    if ((this.GroupBy == undefined || this.GroupBy == '')) {
      this.GroupBy = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.groupby');
      filter.FirstValue = this.GroupBy;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.orderTypeName == undefined || this.orderTypeName == '')) {
      this.orderTypeName = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.orderType');
      filter.FirstValue = this.orderTypeName;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.staffGroupName == undefined || this.staffGroupName == '')) {
      this.staffGroupName = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.staffGroup');
      filter.FirstValue = this.staffGroupName;
      this.ExportPDFParamList.push(filter);
    }

    if ((this.BookedBy == undefined || this.BookedBy == '')) {
      this.BookedBy = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.booked_by');
      filter.FirstValue = this.BookedBy;
      this.ExportPDFParamList.push(filter);
    }

    if ((this.YourReference == undefined || this.YourReference == '')) {
      this.YourReference = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.yourReference');
      filter.FirstValue = this.YourReference;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.customerGroupCode == undefined || this.customerGroupCode == '')) {
      this.customerGroupCode = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.customer_group');
      filter.FirstValue = this.customerGroupCode;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.paymentStatusName == undefined || this.paymentStatusName == '')) {
      this.paymentStatusName = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.payment_Status');
      filter.FirstValue = this.paymentStatusName;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.countryName == undefined || this.countryName == '')) {
      this.countryName = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.country');
      filter.FirstValue = this.countryName;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.Airline == undefined || this.Airline == '')) {
      this.Airline = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.airline');
      filter.FirstValue = this.Airline;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.bookingstatus == undefined || this.bookingstatus == '')) {
      this.bookingstatus = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.Booking_Status');
      filter.FirstValue = this.bookingstatus;
      this.ExportPDFParamList.push(filter);
    }

    if ((this.currencyName == undefined || this.currencyName == '')) {
      this.currencyName = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.currency');
      filter.FirstValue = this.currencyName;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.typeOfInvoiceName == undefined || this.typeOfInvoiceName == '')) {
      this.typeOfInvoiceName = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.typeOfInvoice');
      filter.FirstValue = this.typeOfInvoiceName;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.StatusName == undefined || this.StatusName == '')) {
      this.StatusName = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.status');
      filter.FirstValue = this.StatusName;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.CreatedUser == undefined || this.CreatedUser == '')) {
      this.CreatedUser = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.created_user');
      filter.FirstValue = this.CreatedUser;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.markettingCodeName == undefined || this.markettingCodeName == '')) {
      this.markettingCodeName = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.markettingCode');
      filter.FirstValue = this.markettingCodeName;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.Customer == undefined || this.Customer == '')) {
      this.Customer = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.customer');
      filter.FirstValue = this.Customer;
      this.ExportPDFParamList.push(filter);
    }

    if ((this.profitFrom == undefined || this.profitFrom == '')) {
      this.profitFrom = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.profit');
      filter.FirstValue = this.profitFrom + '-' + this.profitTo;;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.profitPerFrom == undefined || this.profitPerFrom == '')) {
      this.profitPerFrom = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.ProfitPer');
      filter.FirstValue = this.profitPerFrom + '-' + this.profitPerTo;;
      this.ExportPDFParamList.push(filter);
    }

    if ((this.profitPerFrom == undefined || this.profitPerFrom == '')) {
      this.profitPerFrom = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.ProfitPer');
      filter.FirstValue = this.profitPerFrom + '-' + this.profitPerTo;;
      this.ExportPDFParamList.push(filter);
    }

    var filter = new ExportPDFParam();
    filter.Filter = this.common.GetTransilationMessage('Labels.quotes_only');
    filter.FirstValue = this.enableIncludeQuotes == true ? "ON" : "OFF";
    this.ExportPDFParamList.push(filter);



    var filter = new ExportPDFParam();
    filter.Filter = this.common.GetTransilationMessage('Labels.quotes_only');
    filter.FirstValue = this.enableIncludeQuotes == true ? "ON" : "OFF";
    this.ExportPDFParamList.push(filter);


    var filter = new ExportPDFParam();
    filter.Filter = this.common.GetTransilationMessage('Labels.IncludeQuoteswithPO');
    filter.FirstValue = this.IncludeQuoteswithPO == true ? "ON" : "OFF";
    this.ExportPDFParamList.push(filter);

    var filter = new ExportPDFParam();
    filter.Filter = this.common.GetTransilationMessage('Labels.ExcludePDQCharge');
    filter.FirstValue = this.ExcludePDQ1 == true ? "ON" : "OFF";
    this.ExportPDFParamList.push(filter);

    var filter = new ExportPDFParam();
    filter.Filter = this.common.GetTransilationMessage('Labels.ExcludeTOMSVat');
    filter.FirstValue = this.ExcludeTOMS == true ? "ON" : "OFF";
    this.ExportPDFParamList.push(filter);

    var filter = new ExportPDFParam();
    filter.Filter = this.common.GetTransilationMessage('Labels.IncludeCanceled');
    filter.FirstValue = this.IncludeCancelled == true ? "ON" : "OFF";
    this.ExportPDFParamList.push(filter);

    if (this.subGroupName == undefined || this.subGroupName == '') {
      this.subGroupName = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.subGroup');
      filter.FirstValue = this.subGroupName;
      this.ExportPDFParamList.push(filter);
    }

    if (this.subGroupName == undefined || this.subGroupName == '') {
      this.subGroupName = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.subGroup');
      filter.FirstValue = this.subGroupName;
      this.ExportPDFParamList.push(filter);
    }

    if (this.Product == undefined || this.Product == '') {
      this.Product = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.product');
      filter.FirstValue = this.Product;
      this.ExportPDFParamList.push(filter);
    }

    if (this.Supplier == undefined || this.Supplier == '') {
      this.Supplier = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.supplier');
      filter.FirstValue = this.Supplier;
      this.ExportPDFParamList.push(filter);
    }

    if (this.supplierGroupName == undefined || this.supplierGroupName == '') {
      this.supplierGroupName = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.Supplier_Group');
      filter.FirstValue = this.supplierGroupName;
      this.ExportPDFParamList.push(filter);
    }

    ;
    //this.CommonFunctions();



    ;
    this.subscription.add(this.Folderqueryreport.GetFolderQueryReport().subscribe(async res => {
      if (res["ResponseData"] != undefined) {
        this.spinner.hide();

        this.gridData = res["ResponseData"];
        console.log(this.gridData)
        if (this.gridData.length > 0) {
          this.Folderqueryreport.griddata = this.gridData;

          this.router.navigate(["Reports/FolderQueryReport"]);
          this.spinner.hide();
        }
        else {
          this.common.showWARNINGtoastrmsg(
            "Messages.no_data_found",
            2000,
            true,
            false
          );

          this.spinner.hide();
        }
      }
      else {
        this.spinner.hide();
      }
    }
    ))


  }

  showCrystalReport(RptName) {
    ;
    this.subscription.add(this.Folderqueryreport.GetCrystalReport()
      .subscribe(res => {
        this.spinner.hide();
        this.crRptName = this.ReportSubject;
        this.crFileName = res["ResponseData"].rptfilename;
        this.crPdfFilePath = this.pdfurl + res["ResponseData"].Reportpath;
        this.crFilePath = res["ResponseData"].Reportpath;
        this.crDeletePath = res["ResponseData"].Reportfolder
        this.crEmailPath = res["ResponseData"].EmailReportpath;
        this.crUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.crPdfFilePath);
        this.crOpened = true;
        this.opened = false;
        window.scroll(0, 0);
      }));
  }



  public async BAreaAuthorisation() {
    if (this.BArea != null && this.BArea != "" && this.BArea != undefined) {
      if (Boolean(await this.common.BAreaAuthorisation(this.BArea)) == false) {
        this.common.showWARNINGtoastrmsg(
          "Messages.busAreaAuthDenied",
          2000,
          true,
          false
        );
        this.spinner.hide();
        return;
      }

    }

  }
  public async BranchAuthorisation() {
    if (this.BranchCode != null && this.BranchCode != "" && this.BranchCode != undefined) {
      if (Boolean(await this.common.BranchAuthorisation(this.BranchCode)) == false) {
        this.common.showWARNINGtoastrmsg(
          "Messages.branchAuthDenied",
          2000,
          true,
          false
        );
        this.spinner.hide();
        return;
      }

    }

  }


  MarketCodeSelectedItem: any;
  MarketCodeSelectedItemExclude: any;
  BranchSelectedItem: any;
  BusinessAreaSelectedItem: any;
  BookedBySelectedItem: any;
  StaffGroupSelectedItem: any;
  AirLineSelectedItem: any;
  CreatedUserSelectedItem: any;
  SupplierSelectedItem: any;
  SupplierGroupSelectedItem: any;
  ProductSelectedItem: any;
  btnClearAll() {
    ;

    this.action = "ClearAll";
    this.TravelDateFrom = null;
    this.TravelDateTo = null;
    this.CreateDateFrom = null;
    this.CreateDateTo = null;

    this.InvoiceDateFrom = null;
    this.InvoiceDateTo = null;
    this.BookingDateFrom = null;
    this.BookingDateTo = null;
    this.ReturnDateFrom = null;
    this.ReturnDateTo = null;
    this.profitFrom = null;
    this.profitpercentageFrom = null;
    this.profitTo = null;
    this.profitPerFrom = null;
    this.profitPerTo = null;
    this.FolderNo = null;
    this.OrderNo = null;
    this.BranchSelectedItem = null;
    this.MarketCodeSelectedItem = null;
    this.MarketCodeSelectedItemExclude = null;
    this.BusinessAreaSelectedItem = null;
    this.ProductCode = 0;
    this.Product = null;

    this.StaffGroupSelectedItem = null;
    this.currencySelectedItem = null;
    this.YourReference = null;
    this.customerGroupSelectedItem = null;
    this.StatusSelectedItem = { text: "(ALL)", value: 0 };
    this.AirLineSelectedItem = null;
    this.countrySelectedItem = null;
    this.destinationSelected = null;
    this.customerSelected = null;
    this.paymentSelectedItem = { text: "(ALL)", value: 0 }

    this.OrderselectedItem = null;
    this.paymentStatusSelectedItem = { text: "(ALL)", value: 0 };
    this.subGroupItem = null;
    this.typeofinvoiceItem = { Name: "(ALL)", Code: 0 };
    this.GroupItem = { text: "None", value: 0 };
    this.GroupByChange(this.GroupItem);
    this.CreatedUserSelectedItem = null;
    this.SupplierSelectedItem = null;
    this.supplierSelected = null;
    this.SupplierGroupSelectedItem = null;
    this.ProductSelectedItem = null;
    this.enableIncludeQuotes = null;
    this.IncludeQuoteswithPO = null;
    this.ExcludePDQ1 = null;
    this.IncludeCancelled = null;
    this.ExcludeTOMS = null;
    this.PassQuery = "";
    this.GroupSelectedItem = { text: "None", value: 0 };
    this.MCodeMuiltiSelectType = 0;
    this.BranchMultiSelectType = 0;
    this.BusinessAreaMultiSelectType = 0;
    this.DestinationMultiSelectType = 0;
    this.CustomerMultiSelectType = 0;
    this.SupplierMultiSelectType = 0;
    this.AirlineMultiSelectType = 0;
    this.CountryMultiSelectType = 0;
    this.MCMultipleSelectedItems = null;
    this.DestinationMultipleSelectedItems = null;
    this.CustomerMultipleSelectedItems = null;
    this.SupplierMultipleSelectedItems = null;
    this.BRMultipleSelectedItems = null;
    this.BAMultipleSelectedItems = null;
    this.AirlineMultipleSelectedItems = null;
    this.CountryMultipleSelectedItems = null;

    this.disableInvoiceDateFrom = false;
    this.disableInvoiceDateTo = false;
    this.disableTravelDateFrom = false;
    this.disableTravelDateTo = false;
    this.disableCreateDateFrom = false;
    this.disableCreateDateTo = false;
    this.disableBookingDateFrom = false;
    this.disableBookingDateTo = false;
    this.disableReturnDateFrom = false;
    this.disableReturnDateTo = false;
    this.disabledTypeOfInvoice = false;
    this.disabledProduct = false;
    this.disabledSupplier = false;
    this.disabledSupplierGroup = false;
    this.cd.detectChanges();
    this.action = "";

  }
  public mcodeIncludeChangeHandler(event: any) {
    ;
    this.mcodeExclude = event;
  }

  public mcodeExcludeChangeHandler(event) {
    ;
    this.mcodeInclude = event;
  }

  public onValueChange(event: any) {
    this.mcodeExclude = !event;
  }

  public multiSelect(value) {
    ;
    if (value == "ARRIVALPT" || value == "Customer" || value == "Supplier") {
      this.drop = false;
    }
    else {
      this.drop = true;
    }
    this.type = value;
    if (value == "ARRIVALPT") {
      this.MsInput = this.DestinationMultipleSelected;
    }
    else if (value == "Customer") {

      this.MsInput = this.CustomerMultipleSelected;
    }
    else if (value == "Supplier") {

      this.MsInput = this.SupplierMultipleSelected;
    }
    else if (value == "MCCode") {

      this.MsInput = this.MCodeMultipleSelected;
    }
    else if (value == "BRID") {

      this.MsInput = this.BRMultipleSelected;
    }
    else if (value == "BUSINESSAREA") {

      this.MsInput = this.BAMultipleSelected;
    }
    else if (value == "AIRWAYS") {

      this.MsInput = this.AirlineMultipleSelected;
    }
    else if (value == "COUNTRY") {

      this.MsInput = this.CountryMultipleSelected;
    }

    this.mcodeMultiSelectPopUp = true;

  }
  public EmailFormClosingatol(status: any) {

    if (status == "EmailSendSuccesfully" || status == "Closed") {
      this.emailopened = false;
    }
  }
  public closeMultiSelectPopUP(event: any) {

    this.mcodeMultiSelectPopUp = false;
  }
  setMultiselectValue(type, value) {
    switch (type) {
      case 'MCCode':
        this.MCodeMuiltiSelectType = value;
        break;
      case 'BRID':
        this.BranchMultiSelectType = value;
        break;
      case 'BUSINESSAREA':
        this.BusinessAreaMultiSelectType = value;
        break;
      case 'ARRIVALPT':
        this.DestinationMultiSelectType = value;
        break;
      case 'Customer':
        this.CustomerMultiSelectType = value;
        break;
      case 'Supplier':
        this.SupplierMultiSelectType = value;
        break;
      case 'AIRWAYS':
        this.AirlineMultiSelectType = value;
        break;
      case 'COUNTRY':
        this.CountryMultiSelectType = value;
        break;

    }
  }
  public MultiCodeSelectedItems(event: any) {

    ;

    this.MarketCodeSelectedItem = "";
    if (event.type == "MCCode") {
      if (event.resultSelectedList != null && event.resultSelectedList != '') {
        this.MCodeMultipleSelected.SelectedItems = event.resultSelectedList
        this.MCodeMultipleSelected.isIncluded = event.isIncluded
      }
      else {
        this.MCodeMultipleSelected.SelectedItems = '';
      }
    }

    this.BranchCode = "";
    this.Branch = "";
    this.BranchSelectedItem = "";
    if (event.type == "BRID") {

      if (event.resultSelectedList != null && event.resultSelectedList != '') {
        this.BRMultipleSelected.SelectedItems = event.resultSelectedList;
        this.BRMultipleSelected.isIncluded = event.isIncluded

      } else {
        this.BRMultipleSelected.SelectedItems = "";

      }

      // this.BRMultipleSelected.SelectedItems = event.resultSelectedList

    }
    this.BArea = "";
    this.BusinessArea = "";
    this.BusinessAreaSelectedItem = "";
    if (event.type == "BUSINESSAREA") {

      if (event.resultSelectedList != null && event.resultSelectedList != '') {
        this.BAMultipleSelected.SelectedItems = event.resultSelectedList
        this.BAMultipleSelected.isIncluded = event.isIncluded
      }
      else {
        this.BAMultipleSelected.SelectedItems = '';
      }
    }

    this.destinationSelected = "";
    if (event.type == "ARRIVALPT") {
      if (event.resultSelectedList != null && event.resultSelectedList != '') {
        this.DestinationMultipleSelected.SelectedItems = event.resultSelectedList
        this.DestinationMultipleSelected.isIncluded = event.isIncluded
      }
      else {
        this.DestinationMultipleSelected.SelectedItems = '';
      }
    }

    this.customerSelected = "";
    if (event.type == "Customer") {
      if (event.resultSelectedList != null && event.resultSelectedList != '') {
        this.CustomerMultipleSelected.SelectedItems = event.resultSelectedList
        this.CustomerMultipleSelected.isIncluded = event.isIncluded
      }
      else {
        this.CustomerMultipleSelected.SelectedItems = '';
      }

    }

    this.SupplierSelectedItem = "";
    if (event.type == "Supplier") {
      if (event.resultSelectedList != null && event.resultSelectedList != '') {
        this.SupplierMultipleSelected.SelectedItems = event.resultSelectedList
        this.SupplierMultipleSelected.isIncluded = event.isIncluded
      }
      else {
        this.SupplierMultipleSelected.SelectedItems = '';
      }
    }

    this.AirLineSelectedItem = "";
    if (event.type == "AIRWAYS") {
      if (event.resultSelectedList != null && event.resultSelectedList != '') {
        this.AirlineMultipleSelected.SelectedItems = event.resultSelectedList
        this.AirlineMultipleSelected.isIncluded = event.isIncluded
      }
      else {
        this.AirlineMultipleSelected.SelectedItems = '';
      }
    }

    this.countrySelectedItem = "";
    if (event.type == "COUNTRY") {
      if (event.resultSelectedList != null && event.resultSelectedList != '') {
        this.CountryMultipleSelected.SelectedItems = event.resultSelectedList
        this.CountryMultipleSelected.isIncluded = event.isIncluded
      }
      else {
        this.CountryMultipleSelected.SelectedItems = '';
      }
    }

    this.mcodeMultiSelectPopUp = false;
    if (event.resultSelectedList != '') {
      if (event.isIncluded == true) {

        this.setMultiselectValue(event.type, 1);

      }
      else {

        this.setMultiselectValue(event.type, 2);
      }
    }
    else {
      this.setMultiselectValue(event.type, 0)
      this.MsInput = "";
    }

  }
  public offset1 = 10;
  public rptLeft = this.offset1;
  public rptTop = this.offset1;
  public rptWidth = window.innerWidth - 200;
  public rptHeight = window.innerHeight - 100;
  onrptEnd() {
    ;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const positionTop = windowHeight - this.rptHeight - this.offset1;
    const positionRight = windowWidth - this.rptWidth - this.offset1;

    if (this.rptTop < this.offset1) {
      this.rptTop = this.offset1;
    }

    if (this.rptTop > positionTop) {
      this.rptTop = positionTop;
    }

    if (this.rptLeft < this.offset1) {
      this.rptLeft = this.offset1;
    }

    if (this.rptLeft > positionRight) {
      this.rptLeft = positionRight;
    }
  }

  public rptclose() {
    ;
    this.rptopened = false;

  }
  public crClose() {
    this.crOpened = false;

  }


  public async crSendMail() {
    ;
    this.spinner.show();
    await this.email.ComposeMail(this.crRptName, this.crFilePath, this.crEmailPath, this.ReportSubject, this.emailbody, true);
    await this.email.updateReportParameterDTO(this.selectedReport);

    this.emailopened = true;
    this.common.formData.BCCEmail = this.bccId;

    this.common.formData.ToEmail = this.fromId;
    this.email.folderreportfilepath = this.crEmailPath;
  }




  public emailclose(status) {
    this.emailopened = false;

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
