export class CustomerReceiptHeldHeaderData 
{
    IsSuccess: boolean;
    Message: string;
    ResponseData: {
        
        Custco:number;
        CURID:string;
        ExchRate:number;
        FolderCrDateFrom:string;
        FolderCrDateTo:string;
        Reference:string;
        TotalAmtTC :number;
        TotalAmountHC :number;
};
}


