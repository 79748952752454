import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './products/products.component';
import { ProductPackageComponent } from './product-package/product-package.component';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { TranslateModule } from "@ngx-translate/core";
import { GridModule, ExcelModule, PDFModule } from "@progress/kendo-angular-grid";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { WindowModule, DialogModule, DialogsModule } from "@progress/kendo-angular-dialog";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetailedProductGridComponent } from './detailed-product-grid/detailed-product-grid.component';
import { SharedCommonModule } from '../SharedCommonModule/shared-common.module';
import { PopupModule } from "@progress/kendo-angular-popup";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { AirticketBspComponent } from './airticket-bsp/airticket-bsp.component';
import { AirticketBspEditComponent } from './airticket-bsp-edit/airticket-bsp-edit.component';
import { CorporateInfoDetailsComponent } from './corporate-info-details/corporate-info-details.component';
import { CorporateInfoDetailsEditComponent } from './corporate-info-details-edit/corporate-info-details-edit.component';
import { AirticketMembershipLoyalitypointsComponent } from './airticket-membership-loyalitypoints/airticket-membership-loyalitypoints.component';
import { AirticketMembershipLoyalitypointsEditComponent } from './airticket-membership-loyalitypoints-edit/airticket-membership-loyalitypoints-edit.component';
import { AirticketSalestaxdetailsComponent } from './airticket-salestaxdetails/airticket-salestaxdetails.component';
import { AirticketSalestaxdetailsEditComponent } from './airticket-salestaxdetails-edit/airticket-salestaxdetails-edit.component';
import { NotesToBePrintedViewComponent } from './notes-to-be-printed/notes-to-be-printed-view/notes-to-be-printed-view.component';
import { NotesToBePrintedEditComponent } from './notes-to-be-printed/notes-to-be-printed-edit/notes-to-be-printed-edit.component';
import { AirticketDetailsComponent } from './airticket-details/airticket-details.component';
import { AirticketDetailsEditComponent } from './airticket-details-edit/airticket-details-edit.component';
import { SupplierEditComponent } from './supplier-edit/supplier-edit.component';
import { HotelSupplierPaymentComponent } from './hotel-supplier-payment/hotel-supplier-payment.component';
import { TourDetailsComponent } from './tour-details/tour-details.component';
import { TourDetailsEditComponent } from './tour-details-edit/tour-details-edit.component';
import { PriceDetailsViewComponent } from './price-details-view/price-details-view.component';
import { MembershipLoyalitypointsComponent } from './membership-loyalitypoints/membership-loyalitypoints.component';
import { MembershipLoyalitypointsEditComponent } from './membership-loyalitypoints-edit/membership-loyalitypoints-edit.component';
import { FchcdetailsNewComponent } from './fchcdetails-new/fchcdetails-new.component';
import { FCHCDetailsComponent } from './fchcdetails/fchcdetails.component';
import { FchcdetaileditNewComponent } from './fchcdetailedit-new/fchcdetailedit-new.component';
import { HotelPriceDetailsComponent } from './hotel-price-details/hotel-price-details.component';
import { TaxbaseComponent } from './taxbase/taxbase.component';
import { TaxbaseEditComponent } from './taxbase-edit/taxbase-edit.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { HotelDetailsComponent } from './hotel-details/hotel-details.component';
import { HotelDetailsEditComponent } from './hotel-details-edit/hotel-details-edit.component';
import { SelectHotelActivityComponent } from './select-hotel-activity/select-hotel-activity.component';
import { CardetailsComponent } from './cardetails/cardetails.component';
import { CarDetailsEditComponent } from './car-details-edit/car-details-edit.component';
import { RailDetailsComponent } from './rail-details/rail-details.component';
import { RailDetailsEditComponent } from './rail-details-edit/rail-details-edit.component';
import { CruiseDetailsComponent } from './cruise-details/cruise-details.component';
import { CruiseDetailsEditComponent } from './cruise-details-edit/cruise-details-edit.component';
import { OthersDetailsComponent } from './others-details/others-details.component';
import { OthersDetailsEditComponent } from './others-details-edit/others-details-edit.component';
import { InsuranceDetailsComponent } from './insurance-details/insurance-details.component';
import { InsuranceDetailsEditComponent } from './insurance-details-edit/insurance-details-edit.component';
import { TransfersDetailsComponent } from './transfers-details/transfers-details.component';
import { TransfersDetailEditComponent } from './transfers-detail-edit/transfers-detail-edit.component';
import { FchcdetailseditComponent } from './fchcdetailsedit/fchcdetailsedit.component';
import { RailsegDetailsComponent } from './railseg-details/railseg-details.component';
import { RailsegDetailsEditComponent } from './railseg-details-edit/railseg-details-edit.component';
import { AirsegDetailsComponent } from './airseg-details/airseg-details.component';
import { AirsegDetailsEditComponent } from './airseg-details-edit/airseg-details-edit.component';
import { ProductRoutingModule } from './product-routing.module';

@NgModule({
  declarations: [
    ProductsComponent,
    ProductPackageComponent,
    AirticketBspEditComponent,
    AirticketBspComponent,
    DetailedProductGridComponent,
    AirticketMembershipLoyalitypointsComponent,
    AirticketMembershipLoyalitypointsEditComponent,
    CorporateInfoDetailsComponent,
    AirticketSalestaxdetailsComponent,
    AirticketSalestaxdetailsEditComponent,
    NotesToBePrintedViewComponent,
    NotesToBePrintedEditComponent,
    AirticketDetailsComponent,
    AirticketDetailsEditComponent,
    SupplierEditComponent,
    CorporateInfoDetailsEditComponent,
    HotelSupplierPaymentComponent,
    TourDetailsComponent,
    TourDetailsEditComponent,
    PriceDetailsViewComponent,
    MembershipLoyalitypointsComponent,
    MembershipLoyalitypointsEditComponent,
    FchcdetailsNewComponent,
    FCHCDetailsComponent,
    FchcdetaileditNewComponent,
    HotelPriceDetailsComponent,
    TaxbaseComponent,
    TaxbaseEditComponent,
    ProductEditComponent,
    HotelDetailsComponent,
    HotelDetailsEditComponent,
    SelectHotelActivityComponent,
    CardetailsComponent,
    CarDetailsEditComponent,
    RailDetailsComponent,
    RailDetailsEditComponent,
    CruiseDetailsComponent,
    CruiseDetailsEditComponent,
    OthersDetailsComponent,
    OthersDetailsEditComponent,
    InsuranceDetailsComponent,
    InsuranceDetailsEditComponent,
    TransfersDetailsComponent,
    TransfersDetailEditComponent,
    FchcdetailseditComponent,
    RailsegDetailsComponent,
    RailsegDetailsEditComponent,
    AirsegDetailsComponent,
    AirsegDetailsEditComponent,

  ],
  imports: [
    CommonModule,
    LayoutModule,
    TranslateModule,
    GridModule,
    ExcelModule,
    PDFModule,
    InputsModule,
    ButtonsModule,
    DropDownsModule,
    WindowModule,
    DialogModule,
    DialogsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedCommonModule,
    PopupModule,
    DateInputsModule,
    ProductRoutingModule

  ],
  exports: [
    ProductsComponent,
    ProductPackageComponent,
    DetailedProductGridComponent,
    NotesToBePrintedViewComponent,
    AirticketDetailsEditComponent,
    TourDetailsEditComponent,
    HotelPriceDetailsComponent,
    HotelDetailsEditComponent,
    CarDetailsEditComponent,
    RailDetailsEditComponent,
    CruiseDetailsEditComponent,
    InsuranceDetailsEditComponent,
    TransfersDetailEditComponent,
    OthersDetailsEditComponent,
    FchcdetailseditComponent
  ],
  entryComponents:[
    SupplierEditComponent,
    HotelPriceDetailsComponent
  ],
  providers: [ProductsComponent,
    RailsegDetailsEditComponent,
    FchcdetailseditComponent,
    AirsegDetailsEditComponent]
})
export class ProductModule { }
