import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { CommonserviceService } from '../../Services/commonservice.service';

@Component({
  selector: 'app-taxbase-edit',
  templateUrl: './taxbase-edit.component.html',
  styleUrls: ['./taxbase-edit.component.scss']
})
export class TaxbaseEditComponent implements OnInit {

  @Input() parentForm:FormGroup
  @Input() _closeTaxBaseForm !: any;
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320; 
  public visible=false;
  public cruise=false;
  baseFare:any=0;
  constructor(public commonservice:CommonserviceService) { }

  ngOnInit() {
    debugger;
    if(this.parentForm.value['ITYPE'] ==='Ticket') {
      this.visible=false;
      this.cruise=false;
    } else if(this.parentForm.value['ITYPE'] ==='Cruise') {
      this.visible=false;
      this.cruise=true;
    } else {
      this.visible=true;
      this.cruise=false;
    }
    this.baseFare=this.parentForm.get('TaxExcludingSalesTax').value;
    this.Calculateform();
  }
  public onChange(event: any): void {
    this.expandMode = parseInt(event.target.value, 10);
}
onCloseClick (){
  this.parentForm.controls.TaxExcludingSalesTax.setValue(this.baseFare)
  this._closeTaxBaseForm.closemodalpopup();
  }
  onSelectClick(){
    this._closeTaxBaseForm.closemodalpopup();
  }
Calculateform(){
  debugger;
  if(this.parentForm.get('TaxExcludingSalesTax').value!==0 && this.parentForm.get('TaxExcludingSalesTax').value!==null)
    {
      this.parentForm.controls.BaseFare.setValue(parseFloat((this.parentForm.get('FCSell').value-this.parentForm.get('TaxExcludingSalesTax').value).toFixed(2))); 
    } else {
      this.parentForm.controls.BaseFare.setValue(parseFloat((this.parentForm.get('FCSell').value).toFixed(2)));
    } 
    return;
}
public onHeightChange(event: any): void {
    this.height = parseInt(event.target.value, 10);
}
public onPanelChange(event: any): void { 
  this.visible=!this.visible;
  console.log('stateChange: ', event); }

}


