import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from 'src/env.service';
import { CommonserviceService } from './commonservice.service';
import { Observable } from 'rxjs';
import { tap, catchError, retry } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class CommonVariencereportService {
  webLink: string = '';
  public griddata: any;
  public FromDate: any;
  public ToDate: any;
  public CustomerSelected: boolean = false;
  public defaultCheck = true;
  public CustomerName: any = "";
  public CustomerCode: any = "";
  public GroupCheck: any = "None";

  apiUrl: string;

  formDataHeader: any = {
    RptHdrSel_AutoNo: 0,
    RptHdrSel_ModuleName: "",
    RptHdrSel_ColumnHeader: "",
    RptHdrSel_UserId: 0,
    RptHdrSel_TemplateName: "",
    RptHdrSel_Lupdate: new Date(),
    RptHdrSel_CustCo: ""
  };

  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }





  public saveOrUpdateReportHeader(): Observable<any> {
    var body = {
      ...this.formDataHeader
    };
    return this.http
      .post<any>(
        this.apiUrl + "saveOrUpdateReportHeader",
        body,
        httpOptions
      )
      .pipe(
        tap(_ =>
          console.log(`saveOrUpdateReportHeader`)
        ),
        catchError(
          this.commonservice.handleError<any>("saveOrUpdateReportHeader")
        )
      );
  }

  public GetTemplate(): Observable<any> {
    var body = {
      ...this.formDataHeader
    };
    return this.http
      .post<any>(
        this.apiUrl + "GetTemplate",
        body,
        httpOptions
      )
      .pipe(
        tap(_ =>
          console.log(`GetTemplate`)
        ),
        catchError(this.commonservice.handleError<any>("GetTemplate"))
      );
  }

  public GetGridColumn(): Observable<any> {
    var body = {
      ...this.formDataHeader
    };
    return this.http
      .post<any>(
        this.apiUrl + "getGridHeader",
        body,
        httpOptions
      )
      .pipe(
        tap(_ =>
          console.log(`getGridHeader`)
        ),
        catchError(this.commonservice.handleError<any>("getGridHeader"))
      );
  }

  GetReportHeaderFooter(uid: number, folderno: any): Observable<any> {
    const url = `${this.apiUrl}` + "GetCompanyLogo" + `/${uid}/`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`=${folderno}`)),
      catchError(this.commonservice.handleError<any>(` id=${folderno}`))
    );
  }

  GetWebLink(reportDetails: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'GetWebLink', reportDetails, httpOptions).pipe(
      catchError(this.commonservice.handleError<any>('GetWebLink'))
    );
  }
  DecryptWebLink(queryString: any): Observable<any> {
    var objParam = {
      queryString: queryString
    };
    return this.http.post<any>(this.apiUrl + 'DecryptWebLink', objParam, httpOptions).pipe(
      catchError(this.commonservice.handleError<any>('DecryptWebLink'))
    );
  }
}
