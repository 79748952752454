import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { AirsegDetail } from '../../Models/airseg-detail';
import { AirsegService } from '../../Services/airseg.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { ShareDataService } from '../../Services/share-data.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-airseg-details-edit',
  templateUrl: './airseg-details-edit.component.html',
  styleUrls: ['./airseg-details-edit.component.scss']
})
export class AirsegDetailsEditComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  @ViewChild('autocompletearrival') public autocompletearrival: AutoCompleteComponent;
  @ViewChild('autocompletedeparture') public autocompletedeparture: AutoCompleteComponent;
  @ViewChild('autocompletesupplier') public autocompletesupplier: AutoCompleteComponent;

  public ExcludeOnetimeCust_int = 0;
  public ExcludeOnetimeCust = false;
  ucode: string;
  airsegEditForm: FormGroup;
  submitted = false;
  loaded = false;
  folderEditMode: any;
  //hotelDetailModal=HotelDetail;
  public statusList: Array<string> = ["Wait listed", "Confirmed", "Cancelled"];
  public paymethodList: Array<string> = ["Credit", "Company Card", "Customer Card", "Customer Pay Locally"];
  public CommonList: Array<string> = ["Purchase", "Gross", "Sales"];
  public CardList: Array<LookUpDetails> = [];
  GDSList: Array<LookUpDetails["ResponseData"]> = [];
  public GDSselectedItem: LookUpDetails["ResponseData"];
  AirwaysList: Array<LookUpDetails["ResponseData"]> = [];
  public airwaysSelectedItem: LookUpDetails["ResponseData"];
  constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private api: AirsegService, private lookupApi: LookUpDetailsService, private toastr: ToastrService,
    private apiShared: ShareDataService, private flashMessage: FlashMessagesService, private translateapi: TranslateService, private spinner: NgxSpinnerService, public common: CommonserviceService) { }
  private slno: number = this.route.snapshot.params['slno']//'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/';
  private orderNo = this.route.snapshot.params['orderNo'];
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];
  AirlineList: Array<LookUpDetails["ResponseData"]> = [];

  destinationList: Array<LookUpDetails["ResponseData"]> = [];
  destinationfromList: Array<LookUpDetails["ResponseData"]> = [];

  public destinationSelectedItem: LookUpDetails["ResponseData"];
  public destinationToSelectedItem: LookUpDetails["ResponseData"];
  public destinationSelected: String;
  public destinationToSelected: String;

  public supplierSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelected: String;
  public citySelectedItem: LookUpDetails["ResponseData"];
  OperatedByList: Array<LookUpDetails["ResponseData"]> = [];
  public OperatedByselectedItem: LookUpDetails["ResponseData"];
  public showMsg: any;
  public projNo: any;
  departureDate: any;//337
  arrivalDate: any //337
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  ngOnInit() {
    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderNo !== orderno) {
        return this.router.navigate(['PenAir/Home']);
      }
    // this.apiShared.projNo.subscribe(projNo => this.projNo = projNo);
    this.projNo = this.apiShared.projNo;
    this.folderEditMode = "true";

    // this.apiShared.folderEditMode=this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.airsegEditForm = this.formBuilder.group({
      FOLDERNO: [this.orderNo],
      SLNO: [this.slno],
      ITEMNO: [0],
      AIRNAME: ['', Validators.required],
      AIRREALNAME: [''],
      FLIGHT: [''],
      CLASS: [''],
      CLASSNAME: [''],
      SEATS: [''],
      STATUS: [''],
      STATUSNAME: [''],
      AIRDATE: [new Date],
      DEPCITY: ['', Validators.required],
      DEPCITYNAME: ['', Validators.required],
      ARRCITY: ['', Validators.required],
      ARRCITYNAME: ['', Validators.required],
      OPENSEG: [''],
      DEPTIME: [0],
      ARRTIME: [0],
      S: [''],
      PNRNO: [''],
      FAREBASIS: [''],
      DEPTERM: [''],
      CHKIN: [''],
      BOOKEDVIA: [''],
      ARRDATE: [new Date()],
      ARRTERM: [''],
      FLTTIME: [0],
      BAGGALLOW: [''],
      MEALSINFO: [''],
      SEATINFO: [''],
      PROJNO: this.projNo,
      GDS: [''],
      Stops: [0],
      ItnryNotes: [''],
      PCC: [''],
      VLOCATOR: [''],
      SUPPCODE: ['',],
      OperatingAIRID: [''],
      OperatingAIRNAME: [''],
      OperatingFlightNo: [''],
      TICKETORDER: [0],
      Stopover: ['']



    });

    if (this.slno != null)
      this.getAirsegDetails(this.orderNo, this.slno);
    this.subscription.add(this.lookupApi.GetAllLookup('GDS').subscribe(
      (data) => {
        if (data != null && data !== undefined) {
          this.GDSList = data["ResponseData"]
          if (this.airsegEditForm.controls.GDS.value != "") {
            // alert(this.airsegEditForm.controls.GDS.value)
            this.GDSselectedItem = this.GDSList.find(item => item.Name == this.airsegEditForm.controls.GDS.value);

            //console.log("test:-"+this.airsegEditForm.controls.GDS.value+JSON.stringify(this.GDSselectedItem , null, 4));
          }
        }
      }
    ));
    // this.lookupApi.GetAllLookup('SUPPLIERS').subscribe(
    //   (data) => {
    //  //   console.log(JSON.stringify(data, null, 4));

    //     //console.log(JSON.stringify(data["ResponseData"], null, 4));
    //      this.SupplierList= data["ResponseData"]      
    //     //// alert("dd"+this.carEditForm.controls.SUPPRTYCODE.value)     ;
    //      if(this.airsegEditForm.controls.SUPPCODE.value!="")
    //      {

    //      this.supplierSelectedItem =this.SupplierList.find(item => item.Code ==this.airsegEditForm.controls.SUPPCODE.value);
    //      this.supplierSelected=this.supplierSelectedItem.Name;

    //     }
    //   }
    //   );

    // this.lookupApi.GetAllLookup('DESTINATION').subscribe(
    //   (data) => {


    //      this.destinationList= data["ResponseData"] ; 



    //     if(this.airsegEditForm.controls.DEPCITY.value!="")  
    //  {            
    //    this.destinationSelectedItem =this.destinationList.find(item => item.UsrCode ==this.airsegEditForm.controls.DEPCITY.value);
    //    if(this.destinationSelectedItem!=null)
    //    this.destinationSelected=this.destinationSelectedItem.Name;  
    // }
    // if(this.airsegEditForm.controls.ARRCITY.value!="")
    //  {            
    //    this.destinationToSelectedItem =this.destinationList.find(item => item.UsrCode ==this.airsegEditForm.controls.ARRCITY.value); 
    //    if(this.destinationToSelectedItem!=null)
    //    this.destinationToSelected=this.destinationToSelectedItem.Name;
    // } 
    //   }
    //   );
    this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
      (data) => {
        if (data != null && data !== undefined) {
          this.OperatedByList = data["ResponseData"]
          if (this.airsegEditForm.controls.OperatingAIRID.value != "") {

            this.OperatedByselectedItem = this.OperatedByList.find(item => item.UsrCode == this.airsegEditForm.controls.OperatingAIRID.value);


          }
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
      (data) => {
        if (data != null && data !== undefined) {
          this.AirwaysList = data["ResponseData"]
          //alert(this.airsegEditForm.controls.AIRNAME.value);
          if (this.airsegEditForm.controls.AIRNAME.value != "") {
            this.airwaysSelectedItem = this.AirwaysList.find(item => item.UsrCode == this.airsegEditForm.controls.AIRNAME.value);
          }
          // if(this.airsegEditForm.controls.AIRREALNAME.value!="")
          //  {                        
          //   this.airwaysSelectedItem =this.AirwaysList.find(item => item.Name ==this.airsegEditForm.controls.AIRREALNAME.value);                                             
          // }
        }
      }
    ));


    this.onChanges();
  }

  // convenience getter for easy access to form fields
  get f() { return this.airsegEditForm.controls; }

  getAirsegDetails(orderno, slno) {
    debugger;
    this.spinner.show();
    this.subscription.add(this.api.getAirseg(orderno, slno)
      .subscribe(data => {
        //console.log('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
        this.spinner.hide();
        debugger;
        if (data != null && data !== undefined) {
          debugger;
          //this.hotelEditForm = this.formBuilder.group(data);          
          this.airsegEditForm.setValue({
            FOLDERNO: data.ResponseData.FOLDERNO,
            SLNO: data.ResponseData.SLNO,
            // SUPPRTYUSERCODE:  data.ResponseData.SUPPRTYUSERCODE,             
            ITEMNO: data.ResponseData.ITEMNO,
            AIRNAME: data.ResponseData.AIRNAME,
            AIRREALNAME: data.ResponseData.AIRREALNAME,
            FLIGHT: data.ResponseData.FLIGHT,
            CLASS: data.ResponseData.CLASS,
            CLASSNAME: data.ResponseData.CLASSNAME,
            SEATS: data.ResponseData.SEATS,
            STATUS: data.ResponseData.STATUS,
            STATUSNAME: data.ResponseData.STATUSNAME,
            AIRDATE: data.ResponseData.AIRDATE != null ? (data.ResponseData.AIRDATE.toString() === '1900-01-01T00:00:00' ? null : new Date(data.ResponseData.AIRDATE)) : null,
            DEPCITY: data.ResponseData.DEPCITY,
            DEPCITYNAME: data.ResponseData.DEPCITYNAME,
            ARRCITY: data.ResponseData.ARRCITY,
            ARRCITYNAME: data.ResponseData.ARRCITYNAME,
            OPENSEG: data.ResponseData.OPENSEG,
            DEPTIME: data.ResponseData.DEPTIME,
            ARRTIME: data.ResponseData.ARRTIME,
            S: data.ResponseData.S,
            PNRNO: data.ResponseData.PNRNO,
            FAREBASIS: data.ResponseData.FAREBASIS,
            DEPTERM: data.ResponseData.DEPTERM,
            CHKIN: data.ResponseData.CHKIN,
            BOOKEDVIA: data.ResponseData.BOOKEDVIA,
            ARRDATE: data.ResponseData.ARRDATE != null ? (data.ResponseData.ARRDATE.toString() === '1900-01-01T00:00:00' ? null : new Date(data.ResponseData.ARRDATE)) : null,
            ARRTERM: data.ResponseData.ARRTERM,
            FLTTIME: data.ResponseData.FLTTIME,
            BAGGALLOW: data.ResponseData.BAGGALLOW,
            MEALSINFO: data.ResponseData.MEALSINFO,
            SEATINFO: data.ResponseData.SEATINFO,
            PROJNO: data.ResponseData.PROJNO,
            GDS: data.ResponseData.GDS,
            Stops: data.ResponseData.Stops,
            ItnryNotes: data.ResponseData.ItnryNotes,
            PCC: data.ResponseData.PCC,
            VLOCATOR: data.ResponseData.VLOCATOR,
            SUPPCODE: data.ResponseData.SUPPCODE,
            OperatingAIRID: data.ResponseData.OperatingAIRID,
            OperatingAIRNAME: data.ResponseData.OperatingAIRNAME,
            OperatingFlightNo: data.ResponseData.OperatingFlightNo,
            TICKETORDER: data.ResponseData.TICKETORDER,
            Stopover: data.ResponseData.Stopover

          });

          if (data.ResponseData.AIRNAME != "") {
            this.airwaysSelectedItem = this.AirwaysList.find(item => item.UsrCode == data.ResponseData.AIRNAME)
          }
          if (data.ResponseData.OperatingAIRID != "") {
            this.OperatedByselectedItem = this.OperatedByList.find(item => item.UsrCode == data.ResponseData.OperatingAIRID);
          }
          if (data.ResponseData.GDS != "") {
            this.GDSselectedItem = this.GDSList.find(item => item.Name == data.ResponseData.GDS);
          }
          if (data.ResponseData.OPENSEG == '1') {
            this.ExcludeOnetimeCust_int = 1;
            this.ExcludeOnetimeCust = true;
            this.airsegEditForm.controls.OPENSEG.setValue(1);
          }
          else {
            this.ExcludeOnetimeCust_int = 0;
            this.ExcludeOnetimeCust = false;
            this.airsegEditForm.controls.OPENSEG.setValue(0);
          };
        }
        //this.isLoadingResults = false;
        this.loaded = true;
        this.loadarrivalcity();
        this.loaddeparturecity();
        this.loadsuppliermaster();
      }));


  }
  loadsuppliermaster() {
    if (this.airsegEditForm.controls.SUPPCODE.value != '' && this.airsegEditForm.controls.SUPPCODE.value != null) {
      this.subscription.add(this.lookupApi.findSupplier(this.airsegEditForm.controls.SUPPCODE.value).subscribe(
        (data) => {
          if (data != null && data !== undefined) {
            this.SupplierList = data["ResponseData"];
            if (this.airsegEditForm.controls.SUPPCODE.value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.airsegEditForm.controls.SUPPCODE.value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
              }
            }
          }
        }
      ));
    }
  }
  handleFiltersupplier(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data !== undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletesupplier.toggle(false);
    }
  }
  loadarrivalcity() {
    debugger;
    if (this.airsegEditForm.controls.ARRCITY.value != '' && this.airsegEditForm.controls.ARRCITY.value != null) {
      debugger;
      this.subscription.add(this.lookupApi.SearchDestination(this.airsegEditForm.controls.ARRCITY.value, '0').subscribe(
        (data) => {
          if (data != null && data !== undefined) {
            debugger;
            this.destinationList = data["ResponseData"];
            if (this.airsegEditForm.controls.ARRCITY.value != "") {
              debugger;
              this.destinationToSelectedItem = this.destinationList.find(item => item.UsrCode == this.airsegEditForm.controls.ARRCITY.value);
              if (this.destinationToSelectedItem != null)
                debugger;
              this.destinationToSelected = this.destinationToSelectedItem.Name;
            }
          }

        }
      ));
    }
  }
  onCheckboxChange() {

    if (this.ExcludeOnetimeCust == true) {
      this.ExcludeOnetimeCust_int = 1;
      this.airsegEditForm.controls.OPENSEG.setValue(1);
    }
    else {
      this.ExcludeOnetimeCust_int = 0;
      this.airsegEditForm.controls.OPENSEG.setValue(0);
    }
  }
  public DestinationValueChange(value: any): void {
    this.destinationSelectedItem = this.destinationfromList.find(item => item.Name == value);

    if (this.destinationSelectedItem != null) {
      this.airsegEditForm.controls.DEPCITY.setValue(this.destinationSelectedItem.UsrCode);
      this.airsegEditForm.controls.DEPCITYNAME.setValue(this.destinationSelectedItem.ActualName);
    }
    else {
      this.destinationSelected = "";
      this.airsegEditForm.controls.DEPCITY.setValue("");
      this.airsegEditForm.controls.DEPCITYNAME.setValue("");
    }
  }

  loaddeparturecity() {
    debugger;
    if (this.airsegEditForm.controls.DEPCITY.value != '' && this.airsegEditForm.controls.DEPCITY.value != null) {
      debugger;
      this.subscription.add(this.lookupApi.SearchDestination(this.airsegEditForm.controls.DEPCITY.value, '0').subscribe(
        (data) => {
          debugger;
          if (data != null && data !== undefined) {
            debugger;
            this.destinationfromList = data["ResponseData"];

            if (this.airsegEditForm.controls.DEPCITY.value != "") {
              debugger;
              this.destinationSelectedItem = this.destinationfromList.find(item => item.UsrCode == this.airsegEditForm.controls.DEPCITY.value);
              if (this.destinationSelectedItem != null)
                this.destinationSelected = this.destinationSelectedItem.Name;
            }
          }
        }
      ));
    }

  }

  public DestinationToValueChange(value: any): void {
    this.destinationToSelectedItem = this.destinationList.find(item => item.Name == value);
    if (this.destinationToSelectedItem != null) {
      this.airsegEditForm.controls.ARRCITY.setValue(this.destinationToSelectedItem.UsrCode);
      this.airsegEditForm.controls.ARRCITYNAME.setValue(this.destinationToSelectedItem.ActualName);
    }
    else {
      this.destinationToSelected = "";
      this.airsegEditForm.controls.ARRCITY.setValue("");
      this.airsegEditForm.controls.ARRCITYNAME.setValue("");
    }
  }
  handleFilterdeparture(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          if (data != null && data !== undefined) {
            this.destinationfromList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletedeparture.toggle(false);
    }
  }
  handleFilterarrival(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          if (data != null && data !== undefined) {
            this.destinationList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }



  onFormSubmit() {
    if (navigator.onLine) {

      this.submitted = true;

      this.departureDate = (this.airsegEditForm.controls.AIRDATE.value == null || this.airsegEditForm.controls.AIRDATE.value == "" ? "01-Jan-1900" : this.airsegEditForm.controls.AIRDATE.value);//Case id-337
      this.arrivalDate = (this.airsegEditForm.controls.ARRDATE.value == null || this.airsegEditForm.controls.ARRDATE.value == "" ? "01-Jan-1900" : this.airsegEditForm.controls.ARRDATE.value);//Case id-337

      if (Boolean(this.common.ValidDateRange(this.departureDate)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidDepartureDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidDepartureDate'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        return;
      }
      if (Boolean(this.common.ValidDateRange(this.arrivalDate)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.invalid_arrival_date']).subscribe((translations) => {
          this.showMsg = translations['Messages.invalid_arrival_date'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        return;
      }

      if (this.airsegEditForm.invalid) {
        return;
      }


      //this.hotelDetailModal= Object.assign({}, this.hotelEditForm.value);
      if (this.slno != null) {
        this.spinner.show();
        this.subscription.add(this.api.updateAirseg(this.airsegEditForm.value)
          .subscribe(res => {
            if (res.IsSuccess === true && res.Message === 'SUCCESS') {
              this.spinner.hide();
              this.common.showSUCCESStoastrmsg('Messages.saved_success', 2000, true, false);//13042
              this.router.navigate(['/PenAir/Product/AirsegDetails', this.orderNo, this.slno]);
            }
            else {
              debugger;
              this.spinner.hide();
              this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              })
            }
          }, (err) => {
            this.spinner.hide();
            console.log(err);
            this.common.showWARNINGtoastrmsg('Messages.some_error_in_save', 2000, true, false);//13042
          }
          ));
      }

      else {
        this.spinner.show();
        this.subscription.add(this.api.addAirseg(this.airsegEditForm.value)
          .subscribe(res => {
            debugger;
            if (res.IsSuccess === true && res.Message === 'SUCCESS') {
              this.common.showFlash1('Messages.saved_success', 'bg-success text-white', 2000);
              //alert('SUCCESS223!! :-)\n\n' + JSON.stringify(this.airsegEditForm.value, null, 4));
              //this.router.navigate(['/PenAir/Folder/Products',this.orderNo],{skipLocationChange:true});
              var folderQuotes = sessionStorage.getItem('folderQuotes');
              if (folderQuotes) {
                this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderNo]);
              }
              else {
                this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo]);
              }
            } else {
              this.spinner.hide();
              this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              })
            }
          }, (err) => {
            this.spinner.hide();
            console.log(err);
            this.common.showFlash1('Messages.some_error_in_save', 'bg-danger text-white', 2000);
            // alert('Failed!! :-)\n\n' +err+'\n\n'+ JSON.stringify(this.airsegEditForm.value, null, 4));
          }
          ));
      }

    }
    else {

      this.spinner.hide();
      this.toastr.warning("Please check your internet connection", "",
        {
          timeOut: 5000, disableTimeOut: false, closeButton: true
        }
      )
      return;

    }
  }

  onReset() {
    this.submitted = false;
    this.airsegEditForm.reset();
  }


  onCancel() {
    if (this.slno != null)
      this.router.navigate(['/PenAir/Product/AirsegDetails', this.orderNo, this.slno]);
    else {
      var folderQuotes = sessionStorage.getItem('folderQuotes');
      if (folderQuotes) {
        this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderNo]);
      }
      else {
        this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo]);
      }
    }
    //this.router.navigate(['/PenAir/Folder/Products',this.orderNo],{skipLocationChange:true});
  }
  onChanges(): void {

  }

  changefn() {


  }
  public SupplierValueChange(value: any): void {
    //  alert(value);
    this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);
    if (this.supplierSelectedItem != null) {


      this.airsegEditForm.controls.SUPPCODE.setValue(this.supplierSelectedItem.Code);
    }
    else {
      this.supplierSelected = "";
      this.airsegEditForm.controls.SUPPCODE.setValue("");
    }
    // this.carEditForm.get("suppUserCodetext").setValue( this.supplierSelectedItem.Name);
  }


  public airwaysValueChange(value: any): void {
debugger;
    if (value != null && value != undefined) {
      this.airsegEditForm.controls.AIRNAME.setValue(value.UsrCode);
      this.airsegEditForm.controls.AIRREALNAME.setValue(value.SELFBILLING);
    }
    else {
      this.airsegEditForm.controls.AIRNAME.setValue("");
      this.airsegEditForm.controls.AIRREALNAME.setValue("");
    }
  }
  public OperatedByValueChange(value: any): void {

    if (value != null && value != undefined) {
      this.airsegEditForm.controls.OperatingAIRID.setValue(value.UsrCode);
      this.airsegEditForm.controls.OperatingAIRNAME.setValue(value.SELFBILLING);
    }
    else {
      this.airsegEditForm.controls.OperatingAIRID.setValue("");
      this.airsegEditForm.controls.OperatingAIRNAME.setValue("");
    }
  }
  public GDSValueChange(value: any): void {
    console.log('selectionChange', value);

    if (value != null && value != undefined)

      this.airsegEditForm.controls.GDS.setValue(value.Name);
    else
      this.airsegEditForm.controls.GDS.setValue("");

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  isSticky: boolean = false;
  @HostListener('window:scroll')
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }


}


