import { Component, OnInit, Input } from '@angular/core';
import {  Deliveryaddress } from '../../Models/deliveryaddress';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryaddressService } from '../../Services/deliveryaddress.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ShareDataService } from '../../Services/share-data.service';
import { observable, Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
import { Localization_Service } from '../../Services/localization.service';
@Component({
  selector: 'app-deliveryaddress',
  templateUrl: './deliveryaddress.component.html',
  styleUrls: ['./deliveryaddress.component.scss']
})
export class DeliveryaddressComponent implements OnInit {
  @Input() Folder:boolean
  private subscription: Subscription = new Subscription();
  folderEditMode:any;
  isfolder=false;
  showAddress=false;
  lockStatus:any;
  public formeditable=true;
  Deliveryaddress:Deliveryaddress=
  {
    IsSuccess:true,
    Message:"succcess",
    ResponseData:{
      FOLDERNO :0,
      CUSTOMERNAME:"",
      DELIVERYADD0:"",
      DELIVERYADD1:"",
      DELIVERYADD2:"",
      DELIVERYADD3:"",
      DELIVERYADD4:"",
      DELIVERYADD5:"",
      DELIVERYADD6:"",
      DELIVERYADD7:"",
      USERCODE20:"",
      Country:""
    } 
  };

  constructor(public common:CommonserviceService, private DeliveryaddressService:DeliveryaddressService,private route: ActivatedRoute,private router: Router, private spinner:NgxSpinnerService,private apiShared: ShareDataService,public LocService : Localization_Service) { this.LocService.getJSONLanguage('Default'); }
  private orderNo:string=this.route.snapshot.params['orderNo'];
  ngOnInit() {
    debugger;
    if (this.Folder==true)
    {
      this.isfolder=true;
    }
    var orderno= sessionStorage.getItem('orderNo');
    if(orderno!=null)
    if(this.orderNo !==orderno)
    {
      return  this.router.navigate(['PenAir/Home']);
    }
    this.spinner.show();
    this.lockStatus= this.apiShared.folderReadOnly;
if(this.lockStatus=="0")
{
  this.formeditable=true;
}
else{
  this.formeditable=false;
}


this.folderEditMode="false";
//this.apiShared.folderEditMode=this.folderEditMode;
this.apiShared.Setapishareddata(this.folderEditMode,'folderEditMode');
  
this.subscription.add( this.DeliveryaddressService.GetContactDetailsById(this.orderNo).subscribe
    (res=>{
      if(res!==null && res!==undefined) {
  
      if(res["ResponseData"] != null){ 
        res["ResponseData"].DELIVERYADD0= res["ResponseData"].DELIVERYADD0==''?res["ResponseData"].CUSTOMERNAME:res["ResponseData"].DELIVERYADD0
      this.Deliveryaddress=res
      this.LocService.getJSONLanguage(res["ResponseData"].Country);
      this.spinner.hide();
    }
  }
  else
  {
    this.spinner.hide();
  }
    
  }));
  }

  onEditButtonClick()
  {
    this.router.navigate(['PenAir/Folder/DeliveryAddressEdit',this.orderNo]);
  }
  onCancel()
  {
    
    this.common.submenuIndex=0;
    sessionStorage.setItem('pagevalue','0')
    this.apiShared.Setapishareddata('0','submenuIndex')
    this.router.navigate(['PenAir/Folder/FolderSummary',this.orderNo]);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
