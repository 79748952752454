import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, GridDataResult, RowArgs, RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PublicLayoutComponent } from '../../public-layout/public-layout.component';
import { CommonserviceService } from '../../Services/commonservice.service';
import { GdsTrayService } from '../../Services/gds-tray.service';
import { ShareDataService } from '../../Services/share-data.service';

@Component({
  selector: 'app-gdstray',
  templateUrl: './gdstray.component.html',
  styleUrls: ['./gdstray.component.scss']
})
export class GDSTrayComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  @Input() orderNo: string;
  @Output() closeGDS: EventEmitter<string> = new EventEmitter();
  @Output() reload: EventEmitter<string> = new EventEmitter();
  public showMsg: any;
  public gdsTrayData: any;
  gridHeight = window.innerHeight * .7;
  pageSize = 50;
  showFilter = false;
  public mySelection: any[] = [];
  public sort: SortDescriptor[] = [
    {
      field: 'GIT_UPLOADNO',
      dir: 'desc'
    }
  ];
  private contextItem: any;
  itemToRemove = false;
  constructor(private gdsapi: GdsTrayService, private ngZone: NgZone, public common: CommonserviceService, private toastr: ToastrService
    , private translateapi: TranslateService, private router: Router, private apiShared: ShareDataService, private layout: PublicLayoutComponent,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.loadData();
  }
  // public ngAfterViewInit(): void {
  //   //this.fitColumns();
  // }

  public onDataStateChange(e): void {
    // this.fitColumns();
  }

  // private fitColumns(): void {
  //   this.ngZone.onStable
  //     .asObservable()
  //     .pipe(take(1))
  //     .subscribe(() => {
  //       this.grid.autoFitColumns();
  //     });
  // }
  loadData() {
    debugger;
    this.spinner.show();
    this.subscription.add(this.gdsapi.getGDSTray()
      .subscribe(data => {
        this.gdsTrayData = data.ResponseData;
        this.pageSize = data.ResponseData.length;
        this.setGridSize();
        this.spinner.hide();
      }));
    this.mySelection = [];
  }
  onSelect(value) {
    debugger;
    this.contextItem = null;
    const len = this.mySelection.length;
    // if(value.selectedRows[0])
    // this.AuthDetails.rId=value.selectedRows[0].dataItem.RID;
    // else
    // this.AuthDetails.rId='';
  }
  public rowsSelectedKeys(context: RowArgs): any {
    return context.dataItem.GIT_UPLOADNO;
  }
  public onFilterClick() {
    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }
  public rowCallback = (context: RowClassArgs) => {
    if (this.contextItem) {
      const isActive = context.dataItem.GIT_PNR == this.contextItem.GIT_PNR;
      return {
        selected: isActive
      };
    }
  }
  onCellClick(e) {
    this.contextItem = e.dataItem;
  }
  uploadClick() {
    debugger;
    if (this.mySelection.length > 0) {
      let filteredX = this.gdsTrayData.filter(item => this.mySelection.includes(item.GIT_UPLOADNO));
      const PNRSelected = filteredX.filter(
        (thing, i, arr) => arr.findIndex(t => t.GIT_PNR === thing.GIT_PNR) === i
      );
      if (PNRSelected.length > 1) {
        // this.subscription.add(  this.translateapi.get(['Messages.SelectAtleastoneRow']).subscribe((translations ) => {    
        //   this.showMsg= translations['Messages.SelectAtleastoneRow'] ;
        //   this.toastr.warning(this.showMsg)
        //  }));
        this.showMsg = 'Please select same PNR';
        this.toastr.warning(this.showMsg, "", {
          timeOut: 4000,
          disableTimeOut: false,
          closeButton: true
        })
      }
      else {
        const PNRSelected = filteredX.filter((thing, i, arr) =>
          arr.findIndex(t => t.GIT_PNR === thing.GIT_PNR && t.GIT_UPLOADNO === thing.GIT_UPLOADNO) === i);
        let gdsUploadData = [];
        PNRSelected.forEach(item => {
          gdsUploadData.push({
            OrderNo: this.orderNo,
            UploadNo: item.GIT_UPLOADNO,
            PNR: item.GIT_PNR,
            GDS: item.GIT_GDS
          })
        });
        this.spinner.show();
        this.gdsUpload(gdsUploadData);
      }
    } else if (this.contextItem != null) {
      let gdsUploadData = [];
      let gdsTrayfiltered = this.gdsTrayData.filter(item => item.GIT_PNR == this.contextItem.GIT_PNR);
      gdsTrayfiltered.forEach(item => {
        gdsUploadData.push({
          OrderNo: this.orderNo,
          UploadNo: item.GIT_UPLOADNO,
          PNR: item.GIT_PNR,
          GDS: item.GIT_GDS
        })
      });

      this.spinner.show();
      this.gdsUpload(gdsUploadData);
    } else {
      this.subscription.add(this.translateapi.get(['Messages.SelectAtleastoneRow']).subscribe((translations) => {
        this.showMsg = translations['Messages.SelectAtleastoneRow'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 4000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
    }
  }
  onDblClick(e) {
    debugger;
    if (this.contextItem.GIT_PNR && this.contextItem.GIT_PNR.trim() != '') {
      let gdsUploadData = [];
      let gdsTrayfiltered = this.gdsTrayData.filter(item => item.GIT_PNR == this.contextItem.GIT_PNR);
      gdsTrayfiltered.forEach(item => {
        gdsUploadData.push({
          OrderNo: this.orderNo,
          UploadNo: item.GIT_UPLOADNO,
          PNR: item.GIT_PNR,
          GDS: item.GIT_GDS
        })
      });
      this.spinner.show();
      this.gdsUpload(gdsUploadData);
    } else {
      this.subscription.add(this.translateapi.get(['Messages.pnr_required']).subscribe((translations) => {
        this.showMsg = translations['Messages.pnr_required'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        });
      }));
    }
  }
  async gdsUpload(gdsUploadData) {
    if (gdsUploadData[0].PNR && gdsUploadData[0].PNR.trim() != '') {
      if (Boolean(await this.common.checkPermission1('01.12.01.22')) == true) {
        this.reload.emit('reload');

        const distinctUploadData = gdsUploadData.filter((thing, i, arr) => {
          return arr.indexOf(arr.find(t => t.UploadNo === thing.UploadNo)) === i;
        });

        this.subscription.add(this.gdsapi.uploadGDSTray(distinctUploadData)
          .subscribe(res => {
            this.spinner.hide();
            if (res.ResponseData.StatusCode === 190 || res.ResponseData.StatusCode === 184) {
              // this.showMsg='Uploading completed against Folder No:'+res.ResponseData.ProjNo+' and Order No: '+res.ResponseData.OrderNo;
              // this.toastr.success(this.showMsg,"",{
              //   timeOut:4000,
              //   disableTimeOut:false,
              //   closeButton:true
              // })
              this.contextItem = null;
              this.mySelection = [];
              if (res.ResponseData.StatusCode === 190) {

                this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                  this.showMsg = translations['Messages.saved_success'];
                  this.toastr.success(this.showMsg, "", {
                    timeOut: 3000,
                    disableTimeOut: false,
                    closeButton: true
                  });
                }));
              }
              this.orderNo = res.ResponseData.OrderNo;
              this.apiShared.Setapishareddata(0, 'folderReadOnly');
              this.apiShared.Setapishareddata(res.ResponseData.OrderNo, 'orderNo');
              this.apiShared.Setapishareddata("Saved", 'FolderStatus');
              this.apiShared.Setapishareddata("", 'PassengerName');
              this.layout.headerPassenger = res.ResponseData.OrderNo;
              //this.getLockDetails("Folder",res.ResponseData.OrderNo,this.apiShared.uid,res.ResponseData.ProjNo);
              this.router.navigate(['PenAir/Folder/FolderSummary', res.ResponseData.OrderNo]);
              this.gdsClose();
            }
            else {
              this.showMsg = res.ResponseData.Status;
              this.toastr.error(this.showMsg, "", {
                timeOut: 4000,
                disableTimeOut: false,
                closeButton: true
              })
              this.gdsClose();
            }
          }, (err) => {
            this.gdsClose();
          }));
      }
      else {
        this.spinner.hide();
        this.ShowPermissionMessageForm('01.12.01.22');//339
      }
    } else {
      this.spinner.hide();
      this.subscription.add(this.translateapi.get(['Messages.pnr_required']).subscribe((translations) => {
        this.showMsg = translations['Messages.pnr_required'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        });
      }));
    }
  }
  async gdsReset() {
    if (Boolean(await this.common.checkPermission1('01.12.01.22.01')) == true) {
      this.spinner.show();
      this.subscription.add(this.gdsapi.resetGDSTray()
        .subscribe(data => {
          this.spinner.hide();
          if (data.ResponseData == 1) {
            this.loadData();
          }
          else {
            this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {
              this.showMsg = translations['Messages.Failed'];
              this.toastr.error(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
            }));
          }
        }));
    }
    else {
      this.ShowPermissionMessageForm('01.12.01.22.01');//339
    }
  }

  async gdsDelete() {
    debugger;
    if (this.mySelection != undefined && this.mySelection.length != 0) {
      if (Boolean(await this.common.checkPermission1('01.12.01.22.02')) == true) {
        this.itemToRemove = true;
        return this.removeConfirmationSubject;
      }
      else {
        this.ShowPermissionMessageForm('01.12.01.22.02');//339
      }
    } else {
      this.subscription.add(this.translateapi.get(['Messages.SelectAtleastoneRow']).subscribe((translations) => {
        this.showMsg = translations['Messages.SelectAtleastoneRow'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 4000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
    }

    // if(this.mySelection!=undefined && this.mySelection.length!=0){
    //   let filteredX = this.gdsTrayData.filter(item => this.mySelection.includes(item.GIT_UPLOADNO));
    //   var gdsDeleteData = filteredX.map((item) => ({UploadNo: item.GIT_UPLOADNO, GDS: item.GIT_GDS}));
    //   this.subscription.add(this.gdsapi.deleteGDSTray(gdsDeleteData)
    //   .subscribe(data => {
    //     // this.gdsTrayData=data.ResponseData;
    //     // this.pageSize=data.ResponseData.length;
    //     // this.setGridSize();
    //   }));
    // }
  }
  public confirmRemove(shouldRemove: boolean): void {
    this.removeConfirmationSubject.next(shouldRemove);
    if (shouldRemove) {
      if (this.mySelection != undefined && this.mySelection.length != 0) {
        let filteredX = this.gdsTrayData.filter(item => this.mySelection.includes(item.GIT_UPLOADNO));
        const PNRSelected = filteredX.filter((thing, i, arr) =>
          arr.findIndex(t => t.GIT_PNR === thing.GIT_PNR && t.GIT_UPLOADNO === thing.GIT_UPLOADNO) === i);

        var gdsDeleteData = PNRSelected.map((item) => ({ UploadNo: item.GIT_UPLOADNO, GDS: item.GIT_GDS }));
        this.subscription.add(this.gdsapi.deleteGDSTray(gdsDeleteData)
          .subscribe(data => {
            if (data.ResponseData == 1) {
              this.loadData();
              this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {
                this.showMsg = translations['Messages.deleted_success'];
                this.toastr.success(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                });
              }));
            } else {
              this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {
                this.showMsg = translations['Messages.Failed'];
                this.toastr.error(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                });
              }));
            }

          }, (err) => {
            this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {
              this.showMsg = translations['Messages.Failed'];
              this.toastr.error(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
            }));
          }));
      }
    }
    this.itemToRemove = false;

  }

  gdsClose() {
    // if(this.reload){
    //   this.reload.emit('reload');
    // }else{
    this.closeGDS.emit('true');
    //}
  }

  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339

  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }
  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }
  setGridSize() {
    debugger;
    // this.gridHeight=134;
    // if(this.pageSize>5){
    //   this.gridHeight=this.gridHeight+(46.5*4);
    // }else{
    //   if(this.pageSize>1)
    //     this.gridHeight=this.gridHeight+(47*(this.pageSize-1));
    // }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
