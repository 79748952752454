import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LookUpDetailsService } from 'src/app/Services/look-up-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UsermasterService } from 'src/app/Services/usermaster.service';
import { CommonserviceService } from 'src/app/Services/commonservice.service';
import { Subscription } from 'rxjs';
import { FileService } from 'src/app/Services/file.service';


@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  @Output() FormClose = new EventEmitter<string>();
  @Input() userDetails;
  private subscription: Subscription = new Subscription();
  passwordEditForm: FormGroup;
  passmatch = true;
  submitted = false;
  validators: any;
  noOfPwd = 0;
  alphanumPwd = 0;
  public DisabledSaveButton: boolean = false;
  // NoOfPwdChar:number;
  //AlphaNumPwdChar:number;

  constructor(private formBuilder: FormBuilder, public lookupApi: LookUpDetailsService, private UserApi: UsermasterService
    , private toastr: ToastrService, private router: Router, public commonservice: CommonserviceService
    , private spinner: NgxSpinnerService, private fileservice: FileService) { }

  ngOnInit() {
    this.passwordEditForm = this.formBuilder.group({
      UID: this.userDetails.UID,
      UNAME: this.userDetails.UNAME,
      //NAME :[''],
      OLDPWD: ['', Validators.required],
      NEWPWD: ['', Validators.required],//,[ Validators.pattern("^(?=^.{0,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$")]],
      ConfirmPWD: ['', Validators.required]
    });
    this.fileservice.getFileDetails().subscribe(x => {
      if (x != null && x != undefined) {
        this.noOfPwd = x.ResponseData.NoOfPwdChar;
        this.alphanumPwd = x.ResponseData.AlphaNumPwdChar;
        if (this.alphanumPwd != 0 && this.noOfPwd != 0) {
          this.validators = [Validators.required, Validators.minLength(this.noOfPwd), Validators.pattern("(?=^.{0,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s)[0-9a-zA-Z!@#$%^&*()]*$")]
        } else if (this.alphanumPwd != 0) {
          this.validators = [Validators.required, Validators.pattern("(?=^.{0,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s)[0-9a-zA-Z!@#$%^&*()]*$")]
        } else if (this.noOfPwd != 0) {
          this.validators = [Validators.required, Validators.minLength(this.noOfPwd)]
        } else {
          this.validators = [Validators.required];
        }
        this.passwordEditForm.controls.NEWPWD.setValidators(this.validators);
        this.passwordEditForm.controls.NEWPWD.updateValueAndValidity();
      }
    });
  }
  changefn() {
    if (this.passwordEditForm.controls.NEWPWD.value !== '' && this.passwordEditForm.controls.ConfirmPWD.value !== '') {
      if (this.passwordEditForm.controls.NEWPWD.value !== this.passwordEditForm.controls.ConfirmPWD.value) {
        this.passmatch = false;

      } else {
        this.passmatch = true;
      }
    }
  }
  get f() { return this.passwordEditForm.controls; }
  onFormSubmit() {
    this.DisabledSaveButton = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordEditForm.invalid || this.passmatch === false) {
      this.DisabledSaveButton = false;
      return;

    }
    this.subscription.add(this.UserApi.updatePassword(this.passwordEditForm.value)
      .subscribe(res => {
        if (res != null && res != undefined) {

          if (res.IsSuccess === true && res.Message === 'SUCCESS') {

            this.commonservice.showSUCCESStoastrmsg('Messages.saved_success', 3000, true, false);
            // this.commonservice.showFlash1('Messages.saved_success','bg-success text-white',2000);

            this.FormClose.emit("success");
          }
          else {
            this.spinner.hide();
            if (res.ResponseData.ErrorMessage === 'PasswordIncorrect') {
              this.commonservice.showWARNINGtoastrmsg("Messages.pwd_incorrect", 3000, true, false);
              this.DisabledSaveButton = false;
            }
            if (res.ResponseData.ErrorMessage === "LastPasswordError") {
              this.commonservice.showWARNINGtoastrmsg("Messages.Password_lasterr", 3000, true, false);
              this.DisabledSaveButton = false;
            }

          }
        }
        else {
          this.spinner.hide();
        }
      }, (err) => {
        this.commonservice.showFlash1('Messages.some_error_in_save', 'bg-danger text-white', 2000);
        this.DisabledSaveButton = false;
      }));
    this.DisabledSaveButton = false;
  }
  close() {

    this.FormClose.emit("formClosed");
  }

}
