export const apiConfig = {
  auth: {
    checkIfTFAEnabled: '/tfa/enabled',
    enableTFA: '/tfa/enable',
    disableTFA: '/tfa/disable',
    validateTFAOTP: '/tfa/validate',
    otpverify: '/tfa/otpverify',
    ipexist: '/tfa/iplist',
  },
};
