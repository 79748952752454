import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FolderSummary } from '../Models/folder-summary';
import{CustomerspecificGrid} from '../Models/customerspecific-grid';
import { Observable, of,throwError  } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CustomerCreate, CustomerMaster, Login } from '../Models/customer-create.model';
import { LookUpDetails } from './look-up-details';
import { DefaultComboboxDTO } from '../Models/default-combobox-dto.model';
import { CommonserviceService } from './commonservice.service';
import {  Hote201DTO } from '../Models/hote201.model';
import { Hote102DTO, Hote103DTO, Hote103MasterDTO } from '../Models/hote102-dto.model';
import { ResponseData } from '../Models/response-data';
import { LookUpDetailsService } from './look-up-details.service';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
@Injectable({
  providedIn: 'root'
})
export class HotelRoomService {
  apiUrl :string;
  public formData:any;

  public formDatas:Hote102DTO;
  formDatasmain:Hote103MasterDTO;
  public List:Hote103DTO[];
  public createdItems: Hote103DTO[];
  public updatedItems: Hote103DTO[];
  public  deletedItems: Hote103DTO[];
  data:any;
// public CustomerMaster:CustomerMaster;
UsrCode:String;
Name:String ;
public Login:Login;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService,private lookup:LookUpDetailsService) {
    this.apiUrl=env.baseUrl;
   } 
   SaveroomDetails (model: any,CustomerspecificGrid:any): Observable<Hote201DTO> {
   
      
    return this.http.post<Hote201DTO>(this.apiUrl+'SaveRoom', model, httpOptions).pipe(
      
      catchError(this.commonservice.handleError<Hote201DTO>('SaveRoom'))
    );
  }
   Saveratesetp (): Observable<Hote103MasterDTO> {
    var body = {
      ...this.formDatasmain,
      List: this.List,
       createdItems:this.createdItems,
       updatedItems: this.updatedItems,
       deletedItems: this.deletedItems,
     
    };
    
    return this.http.post<Hote103MasterDTO>(this.apiUrl+'SavePricedetails', body);
    //alert(JSON.stringify(FolderSummary));
   
  
  //  this.api.formDatasmain=this.roomdetailform.value;
  //  this.api.List=[];
  //  this.api.List=this.editService.api.data;
  }
  ListRoom(roomid: any,hid:any): Observable<Hote201DTO[]> {  
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.get<Hote201DTO[]>( this.apiUrl+'/GetListroom/'+roomid+'/'+hid,
    { headers } );  
  }
  SearchHotelName(provider: string): Observable<LookUpDetails[]> {  
    // let headers = new HttpHeaders();
    // headers.set('Content-Type', 'application/json');
    // return this.http.get<LookUpDetails[]>( this.apiUrl+'/SearchHotelName/'+provider,
    // { headers } );  
    return  this.lookup.SearchCommonLookUps("HOTELPriceDetails","","",0,provider,0);
  }
  GetHotelRoom(provider: string): Observable<LookUpDetails[]> {  
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.get<LookUpDetails[]>( this.apiUrl+'/GetHotelRoom/'+provider,
    { headers } );  
  }
  LisPriceMaster(rrid: any,hotelid:any): Observable<Hote102DTO[]> {  
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.get<Hote102DTO[]>( this.apiUrl+'/LisPriceMaster/'+rrid+'/'+hotelid,
    { headers } );  
  }
  LisPriceMasterForGir(rrid: any,hotelid:any): Observable<Hote102DTO[]> {  
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.get<Hote102DTO[]>( this.apiUrl+'/LisPriceMasterForGir/'+rrid+'/'+hotelid,
    { headers } );  
  }
  LisPriceDetails(rrid: any,hotelid:any): Observable<Hote102DTO[]> {  
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.get<Hote102DTO[]>( this.apiUrl+'/LisPriceDetails/'+rrid+'/'+hotelid,
    { headers } );  
  }
  RemoveRoom(id:any,roomid: any,imgur): Observable<any> {  
    this.formData=new Hote201DTO;
    this.formData.HtmlRoomID=roomid;
    this.formData.IMAGE=imgur
    this.formData.HID=id;
    var body = {
      ...this.formData,
     
     
    };
   
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.post<any>( this.apiUrl+'/RemoveRoom/',body,
    { headers } );  
  }
  Removepricedetails(id:any,roomid: any): Observable<any> {  
    this.formData=new Hote103MasterDTO;
    this.formData.RRID=roomid;
    
    this.formData.HID=id;
    var body = {
      ...this.formData,
     
     
    };
   
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.post<any>( this.apiUrl+'/RemovePriceMaster/',body,
    { headers } );  
  }
  RemoveImg(id:any,roomid: any,imgur): Observable<any> {  
    this.formData=new Hote201DTO;
    this.formData.HtmlRoomID=roomid;
    this.formData.IMAGE=imgur
    this.formData.HID=id
    var body = {
      ...this.formData,
     
     
    };
   
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.post<any>( this.apiUrl+'/RemoveImg/',body,
    { headers } );  
  }
}
