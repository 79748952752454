
import { Injectable } from '@angular/core';  
import { HttpClient, HttpClientModule } from '@angular/common/http';  

import {  Mealplanmaster, MealplanmasterRequestDTO } from '../Models/mealplanmaster';
import { Observable,of } from 'rxjs';
import { HttpHeaders } from "@angular/common/http";
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';
import { MealplanmasterComponent } from '../mealplanmaster/mealplanmaster.component';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
@Injectable({
  providedIn: 'root'
})



export class Mealplanmasterservice {
    apiUrl :string;
 
   
    constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
      this.apiUrl=env.baseUrl;
     }

     AddMealPlanDetails(MealplanmasterRequestDTO: any): Observable<any> {
    
        return this.http.post<any>(this.apiUrl + 'AddMealPlanDetails', MealplanmasterRequestDTO, httpOptions).pipe(
         
        );
      }

     GetMealPlanDetails():  Observable<Mealplanmaster[]>{
        const url = `${this.apiUrl}`+'GetAllMealPlanDetails';
        return this.http.get<Mealplanmaster[]>(url).pipe(
          tap(_ => console.log(`fetched AllMealPlanDetails`)),
          catchError(this.commonservice.handleError<Mealplanmaster[]>(`GetAllMealPlanDetails`))
        );
      }
      UpdateMealPlanDetails(MealplanmasterRequestDTO: any): Observable<Mealplanmaster>
      {
 
   return this.http.post<Mealplanmaster>(this.apiUrl+'AddMealPlanDetails', MealplanmasterRequestDTO, httpOptions).pipe();
   
      }

      DeleteMealPlanDetails(MPM_AutoNo:number):Observable <any>
      {
        const url = `${this.apiUrl}`+'DeleteMealPlanDetails'+`/${MPM_AutoNo}`;
        return this.http.post(url,  httpOptions).pipe(
                      tap(_ => console.log(`DeleteMealPlanDetails`)),
        catchError(this.commonservice.handleError<any>('DeleteMealPlanDetails'))
          );
      }
}

