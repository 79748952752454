
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EnvService } from '../../env.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Login } from '../Models/customer-create.model';
import { SupplierMasterDetailsDTO } from '../Models/supplier-master-details-dto.model';
import { LookUpDetails } from './look-up-details';
import { CommonserviceService } from './commonservice.service';
import { Projectlist } from '../Models/projectlist';
import { tap, catchError } from 'rxjs/operators';
import { Projectmaster } from '../Models/projectmaster';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class Projectmasterservice {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   } 
   AddProjectDetails(projectdetails: Projectmaster): Observable<any> {
    
    if(projectdetails.SDate==null ||projectdetails.SDate=='')
    {
      projectdetails.SDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    }
    
    else
    {
      projectdetails.SDate=this.commonservice.ConvertToLocaleDate(projectdetails.SDate);
    }
    
    if(projectdetails.EDate==null||projectdetails.EDate=='')
    {
      projectdetails.EDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    }
  
    else
    {
      projectdetails.EDate=this.commonservice.ConvertToLocaleDate(projectdetails.EDate);
    }
    return this.http.post<any>(this.apiUrl + 'AddProjectDetails', projectdetails, httpOptions).pipe(
    
    );
  }
  UpdateProjectDetails(projectdetails: Projectmaster): Observable<any>{
   


    if(projectdetails.SDate==null ||projectdetails.SDate=='')
    {
      projectdetails.SDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    }
    
    else
    {
      projectdetails.SDate=this.commonservice.ConvertToLocaleDate(projectdetails.SDate);
    }
    
    if(projectdetails.EDate==null||projectdetails.EDate=='')
    {
      projectdetails.EDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    }
  
    else
    {
      projectdetails.EDate=this.commonservice.ConvertToLocaleDate(projectdetails.EDate);
    }
    
    return this.http.post<any>(this.apiUrl + 'AddProjectDetails', projectdetails, httpOptions).pipe(
   
    );
  }
   GetProjectMasterDetails():  Observable<Projectlist[]>{
    const url = `${this.apiUrl}`+'GetProjectMasterDetails';
  
    return this.http.get<Projectlist[]>(url).pipe(
      tap(_ => console.log(`fetched ProjectMasterDetails`)),
      catchError(this.commonservice.handleError<Projectlist[]>(`GetProjectMasterDetails`))
    );
  }
  GetProjectDetailsbyId(AutoNo:any):Observable<any> { 
    const url =  `${this.apiUrl}`+'GetProjectDetailsbyId'+`/${AutoNo}`;
    return this.http.get<any>(url);  
  }

  DeleteProjectDetails(AutoNo: any):  Observable<any> {
    
    const url = `${this.apiUrl}`+'DeleteProjectDetails'+`/${AutoNo}`;
     return this.http.post<any>(url, httpOptions).pipe(
      tap(_ => console.log(`deleted Project`)),
      catchError(this.commonservice.handleError<any>('deleteProject'))
     );
   }
}
