import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from 'src/env.service';
import { CommonserviceService } from './commonservice.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UsermasterService {
  apiUrl: string;
  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }

  GetUserDetailByID(usrID: any): Observable<any> {
    const url = `${this.apiUrl}` + 'GetUserDetailByID' + `/${usrID}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched User id=${usrID}`)),
      catchError(this.commonservice.handleError<any>(`GetUserDetailByID id=${usrID}`))
    );
  }
  updateUser(userdetail: any): Observable<any> {
    const url = `${this.apiUrl}` + 'UpdateUserMaster';///${id}
    return this.http.post(url, userdetail, httpOptions).pipe(
      tap(_ => console.log(`updated User`)),
      catchError(this.commonservice.handleError<any>('updateUser'))
    );
  }
  updatePassword(userdetail: any): Observable<any> {
    debugger;
    const url = `${this.apiUrl}` + 'ChangePassword';///${id}
    return this.http.post(url, userdetail, httpOptions).pipe(
      tap(_ => console.log(`updated Password`)),
      catchError(this.commonservice.handleError<any>('Change Password'))
    );
  }
  GetStatusList(): Observable<any[]> {
    const url = `${this.apiUrl}` + 'GetUserStatus';
    return this.http.get<any[]>(url).pipe(
      catchError(this.commonservice.handleError<any[]>(`GetUserStatus`))
    );
  }
}
