import { NgModule, Component } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HotelDetailsComponent } from "./product/hotel-details/hotel-details.component";
import { InsuranceDetailsComponent } from "./product/insurance-details/insurance-details.component";
// import { CustomerreceiptsComponent } from "./folder/customerreceipts/customerreceipts.component";
import { HotelDetailsEditComponent } from "./product/hotel-details-edit/hotel-details-edit.component";
import { UserRegistrationComponent } from "./user-registration/user-registration.component";
import { CruiseDetailsComponent } from "./product/cruise-details/cruise-details.component";
import { OthersDetailsComponent } from "./product/others-details/others-details.component";
// import { RailsegDetailsComponent } from "./folder/railseg-details/railseg-details.component";
import { TourDetailsEditComponent } from "./product/tour-details-edit/tour-details-edit.component";
// import { AirsegDetailsComponent } from "./airseg-details/airseg-details.component";
import { TourDetailsComponent } from "./product/tour-details/tour-details.component";
import { InsuranceDetailsEditComponent } from "./product/insurance-details-edit/insurance-details-edit.component";
import { CardetailsComponent } from "./product/cardetails/cardetails.component";
import { RailDetailsComponent } from "./product/rail-details/rail-details.component";
import { CarDetailsEditComponent } from "./product/car-details-edit/car-details-edit.component";
import { CruiseDetailsEditComponent } from "./product/cruise-details-edit/cruise-details-edit.component";
import { OthersDetailsEditComponent } from "./product/others-details-edit/others-details-edit.component";
// import { AirsegDetailsEditComponent } from "./airseg-details-edit/airseg-details-edit.component";
// import { RailsegDetailsEditComponent } from "./folder/railseg-details-edit/railseg-details-edit.component";
import { LoginComponent } from "./login/login.component";
import { AirticketBspEditComponent } from "./product/airticket-bsp-edit/airticket-bsp-edit.component";
import { AirticketDetailsComponent } from "./product/airticket-details/airticket-details.component";
import { AirticketDetailsEditComponent } from "./product/airticket-details-edit/airticket-details-edit.component";
import { ProductsComponent } from "./product/products/products.component";
import { AlertComponent } from "./alert/alert.component";
// import { FolderhistoryComponent } from "./folder/folderhistory/folderhistory.component";
import { DeliveryaddresseditComponent } from "./folder/deliveryaddressedit/deliveryaddressedit.component";
import { DeliveryaddressComponent } from "./folder/deliveryaddress/deliveryaddress.component";
import { PassengerdetailsviewComponent } from "./passengerdetailsview/passengerdetailsview.component";
import { PassengerDetailComponent } from "./folder/passenger-detail/passenger-detail.component";
import { PassengerdetailseditComponent } from "./passengerdetailsedit/passengerdetailsedit.component";
import { PnrSourcefileComponent } from "./folder/pnr-sourcefile/pnr-sourcefile.component";
// import { FolderSummaryComponent } from "./folder/folder-summary/folder-summary.component";
// import { FolderSummaryEditComponent } from "./folder/folder-summary-edit/folder-summary-edit.component";
// import { FolderNotesComponent } from "./folder/folder-notes/folder-notes.component";
import { DetailsComponent } from "./folder/details/details.component";
import { RailDetailsEditComponent } from "./product/rail-details-edit/rail-details-edit.component";
import { TransfersDetailsComponent } from "./product/transfers-details/transfers-details.component";
import { TransfersDetailEditComponent } from "./product/transfers-detail-edit/transfers-detail-edit.component";
import { PaymentComponent } from "./folder/payment/payment.component";
import { BookingConfirmationComponent } from "./SharedCommonModule/booking-confirmation/booking-confirmation.component";
import { BlankpageComponent } from "./blankpage/blankpage.component";
import { RptCorporateInvoiceListingComponent } from "./rpt-corporate-invoice-listing/rpt-corporate-invoice-listing.component";
import { RptTrialBalanceComponent } from "./rpt-trial-balance/rpt-trial-balance.component";
import { RptAirticketReportWithPNRComponent } from "./report/rpt-airticket-report-with-pnr/rpt-airticket-report-with-pnr.component";
import { RptBankLedgerComponent } from "./rpt-bank-ledger/rpt-bank-ledger.component";
import { RptCustomerStatementComponent } from "./rpt-customer-statement/rpt-customer-statement.component";
import { RptCustomerReceiptsComponent } from "./rpt-customer-receipts/rpt-customer-receipts.component";
import { ReportSampleDesignComponent } from "./report-sample-design/report-sample-design.component";

// import { DesigntestComponent } from './designtest/designtest.component';
import { UnlockdocumentComponent } from "./folder/unlockdocument/unlockdocument.component";
import { SupplierPaymentComponent } from "./supplier-payment/supplier-payment.component";
import { SearchBarComponent } from "@progress/kendo-angular-dropdowns";
import { FoldersearchComponent } from "./folder/foldersearch/foldersearch.component";
// import { InvoiceComponent } from "./folder/invoice/invoice.component";
// import { SearchParentComponent } from "./search/search-parent/search-parent.component";
// import { SearchbyHotelComponent } from "./search/searchby-hotel/searchby-hotel.component";
// import { SearchbyCarComponent } from "./search/searchby-car/searchby-car.component";
// import { SearchbyRailComponent } from "./search/searchby-rail/searchby-rail.component";
// import { SearchbyTransfersComponent } from "./search/searchby-transfers/searchby-transfers.component";
// import { SearchbyTourComponent } from "./search/searchby-tour/searchby-tour.component";
// import { SearchbyCruiseComponent } from "./search/searchby-cruise/searchby-cruise.component";
// import { SearchbyInsuranceComponent } from "./search/searchby-insurance/searchby-insurance.component";
// import { SearchbyOthersComponent } from "./search/searchby-others/searchby-others.component";
// import { SearchbyFolderComponent } from "./search/searchby-folder/searchby-folder.component";
import { ConfigurationSettingsComponent } from "./configuration-settings/configuration-settings.component";
import { SupplierListComponent } from "./supplier-list/supplier-list.component";
import { SupplierdetailsViewComponent } from "./supplierdetails-view/supplierdetails-view.component";
import { SupplierdetailsEditComponent } from "./supplierdetails-edit/supplierdetails-edit.component";
import { BankReconcilationComponent } from "./bank-reconcilation/bank-reconcilation.component";
import { BankreconciliationdatareadingComponent } from "./bankreconciliationdatareading/bankreconciliationdatareading.component";
import { RptFolderActivityReportComponent } from "./report/rpt-folder-activity-report/rpt-folder-activity-report.component";
import { RptFolderQueryComponent } from "./report/rpt-folder-query/rpt-folder-query.component";
import { RptSupplierStatementComponent } from "./rpt-supplier-statement/rpt-supplier-statement.component";
import { UserdetailsViewComponent } from "./userdetails-view/userdetails-view.component";
import { UserdetailsEditComponent } from "./userdetails-edit/userdetails-edit.component";
import { RptPandLReportsComponent } from "./rpt-pand-lreports/rpt-pand-lreports.component";
// import { FoldersearchlistComponent } from "./search/foldersearchlist/foldersearchlist.component";
import { AuthorizeUserComponent } from "./authorize-user/authorize-user.component";

import { CustomermasterComponent } from "./customermaster/customermaster.component";
import { CustomerDetailsComponent } from "./customer-details/customer-details.component";
import { CustomerAddeditComponent } from "./customer-addedit/customer-addedit.component";
import { CashDetailsComponent } from "./cash-details/cash-details.component";
import { RptAuditReportFolderTransDetailsComponent } from "./rpt-audit-report-folder-trans-details/rpt-audit-report-folder-trans-details.component";
import { RptAuditReportComponent } from "./rpt-audit-report/rpt-audit-report.component";
import { DelinkReceiptFromFolderComponent } from "./folder/delink-receipt-from-folder/delink-receipt-from-folder.component";
import { NominalMasterListComponent } from "./nominal-master-list/nominal-master-list.component";
import { NominalMasterViewComponent } from "./nominal-master-view/nominal-master-view.component";
import { NominalMasterEditComponent } from "./nominal-master-edit/nominal-master-edit.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { WhatsappMessagingComponent } from "./whatsapp-messaging/whatsapp-messaging.component";
import { PenchannelListComponent } from "./SharedCommonModule/penchannel-list/penchannel-list.component";
import { RptPettycashReportComponent } from "./rpt-pettycash-report/rpt-pettycash-report.component";
import { HotelPropertyTypesComponent } from "./hotel-property-types/hotel-property-types.component";
import { MealplanmasterComponent } from "./mealplanmaster/mealplanmaster.component";
import { HotelListComponentComponent } from "./HotelInfo/hotel-list-component/hotel-list-component.component";
import { HotelMenuComponentComponent } from "./HotelInfo/hotel-menu-component/hotel-menu-component.component";
import { HotelDetailsEditComponentComponent } from "./HotelInfo/hotel-details-edit-component/hotel-details-edit-component.component";
import { HotelRoomEditComponentComponent } from "./HotelInfo/hotel-room-edit-component/hotel-room-edit-component.component";
import { HotelRoomComponentComponent } from "./HotelInfo/hotel-room-component/hotel-room-component.component";
import { HotelRoomViewComponentComponent } from "./HotelInfo/hotel-room-view-component/hotel-room-view-component.component";
import { HotelRatesetupComponentComponent } from "./HotelInfo/hotel-ratesetup-component/hotel-ratesetup-component.component";
import { HotelRatesetupEditComponentComponent } from "./HotelInfo/hotel-ratesetup-edit-component/hotel-ratesetup-edit-component.component";
import { HotelDetailsComponentComponent } from "./HotelInfo/hotel-details-component/hotel-details-component.component";
import { HotelRatesetupViewComponentComponent } from "./HotelInfo/hotel-ratesetup-view-component/hotel-ratesetup-view-component.component";
import { ConsolidatedInvoiceListComponent } from "./consolidated-invoice-list/consolidated-invoice-list.component";
import { HotelInventoryComponentComponent } from "./HotelInfo/hotel-inventory-component/hotel-inventory-component.component";
// import { BankPayInSlipComponent } from './bank-pay-in-slip/bank-pay-in-slip.component';
import { EmailInvoiceComponent } from "./email-invoice/email-invoice.component";

import { RoomtypesmasterComponent } from "./roomtypesmaster/roomtypesmaster.component";

import { ProjectmasterComponent } from "./projectmaster/projectmaster.component";
import { ProjectdetailsviewComponent } from "./projectdetailsview/projectdetailsview.component";

import { ProjectdetailseditComponent } from "./projectdetailsedit/projectdetailsedit.component";
import { ProductgroupmasterListComponent } from "./productgroupmaster-list/productgroupmaster-list.component";
import { ProductgroupmasterViewComponent } from "./productgroupmaster-view/productgroupmaster-view.component";
import { ProductgroupmasterEditComponent } from "./productgroupmaster-edit/productgroupmaster-edit.component";
import { PaymentgatewayListComponent } from "./paymentgateway-list/paymentgateway-list.component";
import { PettycashmasterListComponent } from "./pettycashmaster-list/pettycashmaster-list.component";
import { PettycashmasterViewComponent } from "./pettycashmaster-view/pettycashmaster-view.component";
import { PettycashmasterEditComponent } from "./pettycashmaster-edit/pettycashmaster-edit.component";
import { HotelpricinggroupComponent } from "./hotelpricinggroup/hotelpricinggroup.component";
import { HotelpricinggroupviewComponent } from "./hotelpricinggroupview/hotelpricinggroupview.component";
import { HotelpricinggroupeditComponent } from "./hotelpricinggroupedit/hotelpricinggroupedit.component";
import { ReportschedulersetupformComponent } from "./SharedCommonModule/reportschedulersetupform/reportschedulersetupform.component";
import { ReportschedulerlistComponent } from "./reportschedulerlist/reportschedulerlist.component";
import { SagepaysettingsformeditComponent } from "./sagepaysettingsformedit/sagepaysettingsformedit.component";
import { SagepaysettingsformviewComponent } from "./sagepaysettingsformview/sagepaysettingsformview.component";
import { BarclaycardsettingsEditComponent } from "./barclaycardsettings-edit/barclaycardsettings-edit.component";
import { BarclaycardsettingsViewComponent } from "./barclaycardsettings-view/barclaycardsettings-view.component";
import { BarclaycardsmartpaysettingsEditComponent } from "./barclaycardsmartpaysettings-edit/barclaycardsmartpaysettings-edit.component";
import { BarclaycardsmartpaysettingsViewComponent } from "./barclaycardsmartpaysettings-view/barclaycardsmartpaysettings-view.component";
import { RealexpaymentssettingseditComponent } from "./realexpaymentssettingsedit/realexpaymentssettingsedit.component";
import { RealexpaymentsettingsviewComponent } from "./realexpaymentsettingsview/realexpaymentsettingsview.component";
import { NetbanxpaymentgatewaysettingseditComponent } from "./netbanxpaymentgatewaysettingsedit/netbanxpaymentgatewaysettingsedit.component";
import { NetbanxpaymentgatewaysettingsviewComponent } from "./netbanxpaymentgatewaysettingsview/netbanxpaymentgatewaysettingsview.component";
import { IngenicosettingsEditComponent } from "./ingenicosettings-edit/ingenicosettings-edit.component";
import { IngenicosettingsViewComponent } from "./ingenicosettings-view/ingenicosettings-view.component";
import { WorldpaycorporateViewComponent } from "./worldpaycorporate-view/worldpaycorporate-view.component";
import { WorldpaycorporateEditComponent } from "./worldpaycorporate-edit/worldpaycorporate-edit.component";
import { FirstdatasettingsViewComponent } from "./firstdatasettings-view/firstdatasettings-view.component";
import { FirstdatasettingsEditComponent } from "./firstdatasettings-edit/firstdatasettings-edit.component";

import { FlynowpaylaterpaymentgatewaysettingseditComponent } from "./flynowpaylaterpaymentgatewaysettingsedit/flynowpaylaterpaymentgatewaysettingsedit.component";
import { FlynowpaylaterpaymentgatewaysettingsviewComponent } from "./flynowpaylaterpaymentgatewaysettingsview/flynowpaylaterpaymentgatewaysettingsview.component";
import { WorldpaysettingseditComponent } from "./worldpaysettingsedit/worldpaysettingsedit.component";
import { WorldpaysettingsviewComponent } from "./worldpaysettingsview/worldpaysettingsview.component";
import { StripepaymentsettingsEditComponent } from "./stripepaymentsettings-edit/stripepaymentsettings-edit.component";
import { StripepaymentsettingsViewComponent } from "./stripepaymentsettings-view/stripepaymentsettings-view.component";
import { BankPayInSlipComponent } from "./bank-pay-in-slip/bank-pay-in-slip.component";
import { FolderQuotesListComponent } from "./folder-quotes-list/folder-quotes-list.component";
import { FolderQuotesViewComponent } from "./folder-quotes-view/folder-quotes-view.component";
import { FolderQuotesEditComponent } from "./folder-quotes-edit/folder-quotes-edit.component";
import { PermissionMessageFormComponent } from "./SharedCommonModule/permission-message-form/permission-message-form.component";

import { TrustpaysettingsEditComponent } from "./trustpaysettings-edit/trustpaysettings-edit.component";
import { TrustpaysettingsViewComponent } from "./trustpaysettings-view/trustpaysettings-view.component";
import { RptVoucherLiabilityComponent } from "./rpt-voucher-liability/rpt-voucher-liability.component";
import { WexpaymentssettingsEditComponent } from "./wexpaymentssettings-edit/wexpaymentssettings-edit.component";
import { WexpaymentssettingsViewComponent } from "./wexpaymentssettings-view/wexpaymentssettings-view.component";
import { InternalNotesComponent } from "./folder/internal-notes/internal-notes.component";
import { CashPaymentComponent } from "./cash-payment/cash-payment.component";
import { HomePageComponent } from "./home-page/home-page.component";
import { MyAccountsComponent } from "./my-accounts/my-accounts.component";
import { AirticketAdmAcmEditComponent } from "./airticket-adm-acm-edit/airticket-adm-acm-edit.component";
import { PurgeAlertsComponent } from "./folder/purge-alerts/purge-alerts.component";
import { RptLedgerReportgridComponent } from "./rpt-ledger-reportgrid/rpt-ledger-reportgrid.component";
import { RptTrialBalancewithCostCenterComponent } from "./rpt-trial-balancewith-cost-center/rpt-trial-balancewith-cost-center.component";
import { CurrencyMenuComponent } from "./CurrencyMaster/currency-menu/currency-menu.component";
import { CurrencyMasterListComponent } from "./CurrencyMaster/currency-master-list/currency-master-list.component";
import { CurrencyExchangerateListComponent } from "./CurrencyMaster/currency-exchangerate-list/currency-exchangerate-list.component";
import { CurrencyDataUploadComponent } from "./CurrencyMaster/currency-data-upload/currency-data-upload.component";
import { CurrencyMasterEditComponent } from "./CurrencyMaster/currency-master-edit/currency-master-edit.component";
import { CurrencyMasterViewComponent } from "./CurrencyMaster/currency-master-view/currency-master-view.component";
import { CRMSearchParentComponent } from "./crm-search-parent/crm-search-parent.component";
import { AuthGuard } from "./_guards/auth.guard";
import { CRMScreenVariantComponent } from "./crmscreen-variant/crmscreen-variant.component";
import { CashRecieptComponent } from "./cash-reciept/cash-reciept.component";
import { ManualPDQReconcilationComponent } from "./manual-pdqreconcilation/manual-pdqreconcilation.component";
import { AirlineDetailedReportComponent } from "./folder/airline-detailed-report/airline-detailed-report.component";
import { RptAirlineDetailedReportComponent } from './rpt-airline-detailed-report/rpt-airline-detailed-report.component';
import { ManualsaleslistComponent } from './manualsaleslist/manualsaleslist.component';
import { CustomerOutstandingSummaryComponent } from "./customer-outstanding-summary/customer-outstanding-summary.component";
import { CustomerOutstandingSummaryReportViewComponent } from "./customer-outstanding-summary-report-view/customer-outstanding-summary-report-view.component";
import { RptgeneralledgerComponent } from './rptgeneralledger/rptgeneralledger.component';
import { GeneralledgerReportComponent } from './generalledger-report/generalledger-report.component';
import { OrdertypemasterListComponent } from "./ordertypemaster-list/ordertypemaster-list.component";
import { OrdertypemasterViewComponent } from "./ordertypemaster-view/ordertypemaster-view.component";
import { OrdertypemasterEditComponent } from "./ordertypemaster-edit/ordertypemaster-edit.component";
import { BankPaymentComponent } from "./bank-payment/bank-payment.component";
import { ManualSalesComponent } from "./manual-sales/manual-sales.component";
import { ManualsalesreturnlistComponent } from './manualsalesreturnlist/manualsalesreturnlist.component';
import { ManualSalesReturnComponent } from './manual-sales-return/manual-sales-return.component';
import { ManualBspreconciliationComponent } from "./manual-bspreconciliation/manual-bspreconciliation.component";
import { VouchermanagementComponent } from "./VoucherManagement/vouchermanagement/vouchermanagement.component";
import { FolderQueryReportComponent } from './folder-query-report/folder-query-report.component';
import { FolderQuerySummaryReportComponent } from './folder-query-summary-report/folder-query-summary-report.component';
import { DataDictionaryComponent } from "./data-dictionary/data-dictionary.component";
import { WebLinkReportViewComponent } from "./web-link-report-view/web-link-report-view.component";
import { ManualsalesSearchComponent } from "./manualsales-search/manualsales-search.component";
import { ManulasalesCreditsnoteSearchComponent } from "./manulasales-creditsnote-search/manulasales-creditsnote-search.component";
import { AutoReceiptComponent } from "./auto-receipt/auto-receipt.component";
import { SupplierReconciliationComponent } from './supplier-reconciliation/supplier-reconciliation.component';
import { RptFolderActivityProductComponent } from "./report/rpt-folder-activity-product/rpt-folder-activity-product.component";
import { FolderActivityReportbyProductSummaryComponent } from './folder-activity-reportby-product-summary/folder-activity-reportby-product-summary.component';
import { FolderActivityReportbyProductDetailedComponent } from './folder-activity-reportby-product-detailed/folder-activity-reportby-product-detailed.component';
import { FeesReportComponent } from "./fees-report/fees-report.component";
import { RptFeesReportgridComponent } from "./rpt-fees-reportgrid/rpt-fees-reportgrid.component";
import { AutomaticCreditCardReconComponent } from './automatic-credit-card-recon/automatic-credit-card-recon.component';
import { CustomerStatementbylineitemComponent } from './customer-statementbylineitem/customer-statementbylineitem.component';
import { MarketingCodeMasterListComponent } from "./marketing-code-master-list/marketing-code-master-list.component";
import { MarketingCodeMasterEditComponent } from "./marketing-code-master-edit/marketing-code-master-edit.component";
import { MarketingCodeMasterViewComponent } from "./marketing-code-master-view/marketing-code-master-view.component";
import { ProfitCenterAddComponent } from "./ProfitCentreMaster/profit-center-add/profit-center-add.component";
import { ProfitCenterMasterViewComponent } from "./ProfitCentreMaster/profit-center-master-view/profit-center-master-view.component";
import { ProfitCenterListComponent } from "./ProfitCentreMaster/profit-center-list/profit-center-list.component";
import { CountryMasterListComponent } from './create-country-master/country-master-list/country-master-list.component';
import { CountryMasterEditComponent } from "./create-country-master/country-master-edit/country-master-edit.component";
import { CountryMasterViewComponent } from './create-country-master/country-master-view/country-master-view.component';

import { ActionMasterAddComponent } from "./action-master-add/action-master-add.component";
import { ActionMasterViewComponent } from "./action-master-view/action-master-view.component";
import { ActionMasterListComponent } from "./action-master-list/action-master-list.component";
import { BankReceiptComponent } from './bank-receipt/bank-receipt.component';
import { FraudScoreSettingslistComponent } from './Fraud-score/fraud-score-settingslist/fraud-score-settingslist.component';
import { FraudScoreSettingseditComponent } from "./Fraud-score/fraud-score-settingsedit/fraud-score-settingsedit.component";
import { FraudScoreSettingsviewComponent } from "./Fraud-score/fraud-score-settingsview/fraud-score-settingsview.component";
import { BspDirectExpenseComponent } from './bsp-direct-expense/bsp-direct-expense.component';
import { CityMasterListComponent } from './city-master/city-master-list/city-master-list.component';
import { CityMasterEditComponent } from './city-master/city-master-edit/city-master-edit.component';
import { CityMasterViewComponent } from "./city-master/city-master-view/city-master-view.component";
import { MarketingCodeGroupMasterListComponent } from "./marketing-code-group-master-list/marketing-code-group-master-list.component";
import { MarketingCodeGroupMasterAddeditComponent } from "./marketing-code-group-master-addedit/marketing-code-group-master-addedit.component";
import { MarketingCodeGroupMasterViewComponent } from "./marketing-code-group-master-view/marketing-code-group-master-view.component";
import { DropdowmListMasterAddeditComponent } from "./dropdowm-list-master-addedit/dropdowm-list-master-addedit.component";
import { DropdowmListMasterViewComponent } from "./dropdowm-list-master-view/dropdowm-list-master-view.component";
import { DropdowmListMasterComponent } from "./dropdowm-list-master/dropdowm-list-master.component";
import { CustomerGroupMasterListComponent } from './Customer-Group-Master/customer-group-master-list/customer-group-master-list.component';
import { CustomerGroupMasterViewComponent } from './customer-group-master-view/customer-group-master-view.component';
import { CustomerGroupMasterEditComponent } from './customer-group-master-edit/customer-group-master-edit.component';
import { CustomerCreditGroupListComponent } from './customer-credit-group-list/customer-credit-group-list.component';


import { ProfitCentreGroupMasterListComponent } from './ProfitCentreGroupMaster/profit-centre-group-master-list/profit-centre-group-master-list.component';
import { ProfitCentreGroupMasterViewComponent } from "./ProfitCentreGroupMaster/profit-centre-group-master-view/profit-centre-group-master-view.component";
import { ProfitCentreGroupMasterAddEditComponent } from "./ProfitCentreGroupMaster/profit-centre-group-master-add-edit/profit-centre-group-master-add-edit.component";
import { GdsUsermappingListComponent } from './GDS/gds-usermapping-list/gds-usermapping-list.component';
import { GdsUsermappingAddeditComponent } from './GDS/gds-usermapping-addedit/gds-usermapping-addedit.component';
import { GdsUsermappingViewComponent } from './GDS/gds-usermapping-view/gds-usermapping-view.component';
import { CostCentreMasterListComponent } from "./Cost-Centre-Master/cost-centre-master-list/cost-centre-master-list.component";
import { CostCentreMasterViewComponent } from "./Cost-Centre-Master/cost-centre-master-view/cost-centre-master-view.component";
import { CostCentreMasterAddEditComponent } from "./Cost-Centre-Master/cost-centre-master-add-edit/cost-centre-master-add-edit.component";
import { FolderInstallmentRptComponent } from "./folder-installment-rpt/folder-installment-rpt.component";
import { FolderInstallmentGridRprtComponent } from "./folder-installment-grid-rprt/folder-installment-grid-rprt.component";
import { EmailSettingsListComponent } from "./Email-settings/email-settings-list/email-settings-list.component";
import { EmailSettingsEditComponent } from "./Email-settings/email-settings-edit/email-settings-edit.component";
import { EmailSettingsViewComponent } from "./Email-settings/email-settings-view/email-settings-view.component";

import { EmailTemplatesGridComponent } from './email-templates/email-templates-grid/email-templates-grid.component';
import { EmailTemplateAddComponent } from './email-templates/detailed-form/email-template-add/email-template-add.component';
import { TemplateConditionsAddComponent } from './email-templates/detailed-form/template-conditions-add/template-conditions-add.component';
import { ConditionsTypeAddComponent } from './email-templates/detailed-form/conditions-type-add/conditions-type-add.component';
import { EmailTemplateViewComponent } from './email-templates/email-templates-view/email-template-view/email-template-view.component';
import { TemplateConditionsViewComponent } from './email-templates/email-templates-view/template-conditions-view/template-conditions-view.component';
import { ConditionsTypeViewComponent } from './email-templates/email-templates-view/conditions-type-view/conditions-type-view.component';

import { CustomerCreditGroupViewComponent } from "./customer-credit-group-view/customer-credit-group-view.component";
import { CustomerCreditGroupEditComponent } from "./customer-credit-group-edit/customer-credit-group-edit.component";

import { PackageSummaryEditComponent } from './package-summary-edit/package-summary-edit.component';
import { ProductPackageComponent } from "./product/product-package/product-package.component";
import { HotelPackageDetailsEditComponent } from './hotel-package-details-edit/hotel-package-details-edit.component';
import { RetailOrPackagefolderComponent } from "./folder/retail-or-packagefolder/retail-or-packagefolder.component";
import { FolderaddExpenseComponent } from './folder/folderadd-expense/folderadd-expense.component';
import { FlightBookingDetailsReportComponent } from './flight-booking-details-report/flight-booking-details-report.component';
import { RptFlightBookingDetailsComponent } from "./report/rpt-flight-booking-details/rpt-flight-booking-details.component";
import { SupplierGroupMasterListComponent } from "./supplier-group-master-list/supplier-group-master-list.component";
import { SupplierGroupMasterViewComponent } from "./supplier-group-master-view/supplier-group-master-view.component";
import { SupplierGroupMasterEditComponent } from "./supplier-group-master-edit/supplier-group-master-edit.component";
import { StaffGroupMasterListComponent } from "./staff-group-master-list/staff-group-master-list.component";
import { StaffGroupMasterViewComponent } from "./staff-group-master-view/staff-group-master-view.component";
import { StaffGroupMasterEditComponent } from "./staff-group-master-edit/staff-group-master-edit.component";
import { TaxCalculationComponent } from './tax-calculation/tax-calculation.component';
import { JournalTransactionComponent } from './journal-transaction/journal-transaction.component';
import { BanktoBankTransferComponent } from "./bankto-bank-transfer/bankto-bank-transfer.component";
import { DefaultChargesEditComponent } from "./default-charges-edit/default-charges-edit.component";
import { DefaultChargesViewComponent } from "./default-charges-view/default-charges-view.component";
import { DefaultChargesListComponent } from "./default-charges-list/default-charges-list.component";
import { TaxRateMasterListComponent } from "./Tax-Rate-Master/tax-rate-master-list/tax-rate-master-list.component";
import { TaxRateMasterViewComponent } from "./Tax-Rate-Master/tax-rate-master-view/tax-rate-master-view.component";
import { TaxRateMasterAddEditComponent } from "./Tax-Rate-Master/tax-rate-master-add-edit/tax-rate-master-add-edit.component";
import { MarkettingRegionListComponent } from "./marketting-region-list/marketting-region-list.component";
import { MarkettingRegionEditComponent } from "./marketting-region-edit/marketting-region-edit.component";
import { MarkettingRegionViewComponent } from "./marketting-region-view/marketting-region-view.component";
import { AirwaysMasterAddEditComponent } from "./airways-master/airways-master-add-edit/airways-master-add-edit.component";
import { AirwaysMasterViewComponent } from "./airways-master/airways-master-view/airways-master-view.component";
import { AirwaysMasterListComponent } from "./airways-master/airways-master-list/airways-master-list.component";
import { PaymentBlockMasterListComponent } from "./payment-block-master-list/payment-block-master-list.component";
import { PaymentBlockMasterEditComponent } from "./payment-block-master-edit/payment-block-master-edit.component";
import { PaymentBlockMasterViewComponent } from "./payment-block-master-view/payment-block-master-view.component";
import { ThirdPartyExcelMappingListComponent } from "./Third-Party-Excel-Mapping/third-party-excel-mapping-list/third-party-excel-mapping-list.component";

import { CompanycardpaymentComponent } from "./compmanycard/companycardpayment/companycardpayment.component";
import { BankMasterAddeditComponent } from "./bank-master/bank-master-addedit/bank-master-addedit.component";
import { BankMasterViewComponent } from "./bank-master/bank-master-view/bank-master-view.component";
import { BankMasterListComponent } from "./bank-master/bank-master-list/bank-master-list.component";
import { PaymentMethodMasterListComponent } from "./payment-method-master-list/payment-method-master-list.component";
import { PaymentMethodMasterEditComponent } from "./payment-method-master-edit/payment-method-master-edit.component";
import { PaymentMethodMasterViewComponent } from "./payment-method-master-view/payment-method-master-view.component";
import { GDSAutoFolderSettingsListComponent } from "./GDS-Auto-folder-settings/gds-auto-folder-settings-list/gds-auto-folder-settings-list.component";
import { GDSAutoFolderSettingsBusinessComponent } from "./GDS-auto-folder-settings-BusinessArea/gds-auto-folder-settings-business/gds-auto-folder-settings-business.component";

import { CardMasterListComponent } from "./card-master-list/card-master-list.component";
import { CardMasterViewComponent } from "./card-master-view/card-master-view.component";
import { CardMasterEditComponent } from "./card-master-edit/card-master-edit.component";
import { PaymentTermsMasterListComponent } from "./payment-terms-master-list/payment-terms-master-list.component";
import { PaymentTermsMasterViewComponent } from "./payment-terms-master-view/payment-terms-master-view.component";
import { PaymentTermsMasterEditComponent } from "./payment-terms-master-edit/payment-terms-master-edit.component";
import { IATAMasterListComponent } from "./iata-master-list/iata-master-list.component";
import { CustomerMappingComponent } from "./ThirdPartyMapping/customer-mapping/customer-mapping.component";
import { ThirdPartyMappingComponent } from "./third-party-mapping/third-party-mapping.component";
import { BusinessAreaMappingComponent } from "./ThirdPartyMapping/business-area-mapping/business-area-mapping.component";
import { ProductMappingComponent } from "./ThirdPartyMapping/product-mapping/product-mapping.component";
import { AgentCommissionAddComponent } from "./AgentCommissiononInvoice/agent-commission-add/agent-commission-add.component";
import { AgentCommissionListComponent } from "./AgentCommissiononInvoice/agent-commission-list/agent-commission-list.component";
import { AgentCommissionViewComponent } from "./AgentCommissiononInvoice/agent-commission-view/agent-commission-view.component";
import { ThirdPartyExcelMappingAddEDitComponent } from "./Third-Party-Excel-Mapping/third-party-excel-mapping-add-edit/third-party-excel-mapping-add-edit.component";
import { VAT201VATReturnsComponent } from "./vat201-vat-returns/vat201-vat-returns.component";
import { AgingScheduleMasterListComponent } from "./aging-schedule-master-list/aging-schedule-master-list.component";
import { AgingScheduleMasterViewComponent } from "./aging-schedule-master-view/aging-schedule-master-view.component";
import { AgingScheduleMasterEditComponent } from "./aging-schedule-master-edit/aging-schedule-master-edit.component";
import { WorkflowEditComponent } from "./Workflow/workflow-edit/workflow-edit.component";
import { WorkflowViewComponent } from "./Workflow/workflow-view/workflow-view.component";
import { WorkflowListComponent } from "./Workflow/workflow-list/workflow-list.component";
import { AutomaticCompanyCardReconciliationComponent } from "./automatic-company-card-reconciliation/automatic-company-card-reconciliation.component";
import { TaxMasterDetailedFormComponent } from './tax-master-detailed-form/tax-master-detailed-form.component';
import { TaxMasterGridFormComponent } from './tax-master-grid-form/tax-master-grid-form.component';
import { TaxMasterViewComponent } from './tax-master-view/tax-master-view.component';
import { InstallmentPlanComponent } from "./folder/installment-plan/installment-plan.component";
import { CustomerAllocationReportComponent } from "./customer-allocation-report/customer-allocation-report.component";
import { SupplierRemittanceAdviceComponent } from './supplier-remittance-advice/supplier-remittance-advice.component';
import { SupplierAllocationReportComponent } from "./Supplier-Allocation-Report/supplier-allocation-report/supplier-allocation-report.component";
import { DailyJournalAuditReportComponent } from "./daily-journal-audit-report/daily-journal-audit-report.component";
import { ForeignCurrencyCustomerstatementComponent } from "./foreign-currency-customer-statment/foreign-currency-customerstatement/foreign-currency-customerstatement.component";
import { DepositAsondateReportComponent } from "./deposit-asondate-report/deposit-asondate-report.component";
import { ManualPurchaseDetailedComponent } from './manual-purchase-detailed/manual-purchase-detailed.component';
import { ExpenseInvoiceSearchComponent } from "./expenseinvoice/expense-invoice-search/expense-invoice-search.component";
import { ExpenseInvoiceListComponent } from "./expenseinvoice/expense-invoice-list/expense-invoice-list.component";

import { CannedMessageListComponent } from "./canned-messages/canned-message-list/canned-message-list.component";
import { CannedMessageViewComponent } from "./canned-messages/canned-message-view/canned-message-view.component";
import { CannedMessageAddComponent } from "./canned-messages/canned-message-add/canned-message-add.component";
import { CustomerOutstandingDetailedReportComponent } from "./customer-outstanding-detailed-report/customer-outstanding-detailed-report.component";
import { SupplierOutstandingDetailedAsondateReportComponent } from "./supplier-outstanding-detailed-asondate-report/supplier-outstanding-detailed-asondate-report.component";
import { ForeignCurrencySupplierStatementComponent } from "./foreign-currency-supplier-statement/foreign-currency-supplier-statement.component";
import { SupplierOutstandingSummaryComponent } from "./supplier-outstanding-summary/supplier-outstanding-summary.component";
import { RewardPointsReconciliationComponent } from "./reward-points-reconciliation/reward-points-reconciliation.component";
import { EwaySettingsAddComponent } from "./EwaySettings/eway-settings-add/eway-settings-add.component";
import { EwaySettingsViewComponent } from "./EwaySettings/eway-settings-view/eway-settings-view.component";
import { DepositCollectedForFutureTravelReportComponent } from "./deposit-collected-for-future-travel-report/deposit-collected-for-future-travel-report.component";
import { CalllogsComponent } from "./calllogs/calllogs.component";
// import { ReplenishmentReportComponent } from "./replenishment-report/replenishment-report.component";
import { MessageAnalysisReportComponent } from "./report/message-analysis-report/message-analysis-report.component";
import { GiftCardMasterListComponent } from './Gift-card-master/gift-card-master-list/gift-card-master-list.component';
import { GiftCardMasterEditComponent } from './Gift-card-master/gift-card-master-edit/gift-card-master-edit.component';
import { GiftCardMasterViewComponent } from "./Gift-card-master/gift-card-master-view/gift-card-master-view.component";
import { DeferredRevenueAndExpenseReportComponent } from "./deferred-revenue-and-expense-report/deferred-revenue-and-expense-report.component";
import { MessageSettingsViewEditComponent } from "./messageSettings/message-settings-view-edit/message-settings-view-edit.component";
import { MessageSettingsListComponent } from "./messageSettings/message-settings-list/message-settings-list.component";
import { ReasonCodeMasterComponent } from './reason-code-master/reason-code-master.component';
import { MainDocumentListComponent } from "./main-document-list/main-document-list.component";

import { StoreFrontMasterListComponent } from "./store-front-master-list/store-front-master-list.component";
import { SFTPSettingsListComponent } from "./sftp-settings-list/sftp-settings-list.component";
import { SFTPSettingsEditViewComponent } from "./sftp-settings-edit-view/sftp-settings-edit-view.component";
import { Crmsettings } from "./Models/crmsettings";
import { CRMSettingsComponent } from "./crm-settings/crm-settings.component";
import { PostCodeSearchSettingsComponent } from "./post-code-search-settings/post-code-search-settings.component";
import { PostCodeSearchListComponent } from "./post-code-search-list/post-code-search-list.component";
import { GDSMasterComponent } from "./gdsmaster/gdsmaster.component";
import { DealIDMasterComponent } from "./deal-idmaster/deal-idmaster.component";
import { DealIDMasterDetailsComponent } from "./deal-idmaster-details/deal-idmaster-details.component";
import { AutoChargingInstallmentComponent } from "./auto-charging-installment/auto-charging-installment.component";
import { BacsReconciliationComponent } from './bacs-reconciliation/bacs-reconciliation.component';
import { PurchaseListingComponent } from "./purchase-listing/purchase-listing.component";
import { SupplierLederReportComponent } from "./Supplier-Ledger-Report/supplier-leder-report/supplier-leder-report.component";
import { SupplierLedgerReportGridComponent } from "./Supplier-Ledger-Report/supplier-ledger-report-grid/supplier-ledger-report-grid.component";
import { CustomerreceiptoutsidefolderComponent } from "./customerreceiptoutsidefolder/customerreceiptoutsidefolder.component";
import { SupplierOutstandingAsOnDateAgedReportComponent } from "./supplier-outstanding-as-on-date-aged-report/supplier-outstanding-as-on-date-aged-report.component";
import { BusareawiseemailandphoneComponent } from "./busareawiseemailandphone/busareawiseemailandphone.component";
import { CustomerLedgerReportComponent } from "./customer-ledger-report/customer-ledger-report.component";
import { CustomerLedgerReportGridComponent } from "./customer-ledger-report-grid/customer-ledger-report-grid.component";
import { CustomerOutstandingAsOnDateAgedSummaryReportComponent } from "./customer-outstanding-as-on-date-aged-summary-report/customer-outstanding-as-on-date-aged-summary-report.component";
import { CustomerListComponent } from "./customer-list/customer-list.component";
import { FolderActivityDetailedComponent } from "./folder/folder-activity-detailed/folder-activity-detailed.component";
import { FolderActivityDetailedReportComponent } from "./folder/folder-activity-detailed-report/folder-activity-detailed-report.component";
import { CustomerledgerwithitineraryComponent } from './customerledgerwithitinerary/customerledgerwithitinerary.component';
import { CustomerAgeingWithCreditLimitComponent } from "./customer-ageing-with-credit-limit/customer-ageing-with-credit-limit.component";
import { UserListComponent } from "./user-list/user-list.component";
import { PublicLayoutComponent } from "./public-layout/public-layout.component";

const routes: Routes = [

  {
    path: "",
    redirectTo: "/PenAir/Home",
    pathMatch: "full",
    canActivate: [AuthGuard],
    data: { title: "Home" },
  },
  
  {
    path: "PenAir",
    component: PublicLayoutComponent,
    data: { title: "Alert" },
    children: [
      // {
      //   path: "",
      //   loadChildren: () => import('./SharedMenu/sharedMenu.module').then(m => m.SharedMenuModule),
      //   canActivate: [AuthGuard],
      //   data: { title: "alert" },
      // },
         {
        path: "Folder",
        loadChildren: () => import('./folder/Folder.module').then(m => m.FolderModule),
        canActivate: [AuthGuard],
      },
      {
        path: "Product",
        loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
        canActivate: [AuthGuard],
      },
      {
        path: "Home",
        component: HomePageComponent,
        canActivate: [AuthGuard],
        data: { title: "Home" },
      },
      // {
      //   path: "Settings/Commonsettings/MessageSettings",
      //   component: MessageSettingsListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "message settings details" },

      // },
      // PenAir/Settings/Commonsettings/list
      // {
      //   path: "Settings/Commonsettings/MessageSettings/messagedetails/:ID/:LID",
      //   component: MessageSettingsViewEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "message settings details" },
      // },
      // {
      //   path: "RetailPackageFolder",
      //   component: RetailOrPackagefolderComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Retail Or Package Folder" },
      // },

      // {
      //   path: "Searchdummy",
      //   component: FoldersearchComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Index" },
      // },
      // {
      //   path: "Search/List",
      //   component: FoldersearchlistComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Search" },
      // },
      // {
      //   path: "Search/List/InstallmentPlan",
      //   component: InstallmentPlanComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Installment Plan" },
      // },
      {
        path: "PassengerBookingDetailsReport",
        component: RptFlightBookingDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: "Flight Booking Details" },
      },
      // {
      //   path: "Folder/Index",
      //   component: AlertComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Index" },
      // },
      // {
      //   path: "Reports/QuebecOPCFICAVFeesReport",
      //   component: FeesReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Quebec OPC FICAV FeesReport" },
      // },
      // {
      //   path: "Reports/FolderInstallmentReport",
      //   component: FolderInstallmentRptComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Folder Installment Report" },
      // },

      // {
      //   path: "Reports/ForeignCurrencyCustomerStatment",
      //   component: ForeignCurrencyCustomerstatementComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Foreign Currency Customer statement" },
      // },
      //14524 
      // {
      //   path: "Reports/CustomerAgeingWithCreditLimit",
      //   component: CustomerAgeingWithCreditLimitComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Ageing With Credit Limit" },
      // },
      // {
      //   path: "BSP/BSPDirectExpense",
      //   component: BspDirectExpenseComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "BSP Direct Expense" },
      // },
      // {
      //   path: "journal/DailyJournalAuditReport",
      //   component: DailyJournalAuditReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Daily Journal Audit Report" },
      // },
      // {
      //   path: "journal/JournalTransaction",
      //   component: JournalTransactionComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Journal Transaction" },
      // },

      // {
      //   path: "Reports/ReplenishmentReport",
      //   component: ReplenishmentReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Replenishment Report" },
      // },

      // {
      //   path: "CRM/Calllogs/:orderno",
      //   component: CalllogsComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Call logs" },
      // },




      // {
      //   path:'Folder/backreconciliationdatareading',
      //   component:BankreconciliationdatareadingComponent,
      //   data:{title:'bankreconcilation'}
      // },
      // { app-configuration-settings
      //   path: 'designtest',
      //   component: DesigntestComponent,
      //   canActivate: [AuthGuard],
      //data: { title: 'Index' }
      // },
      // {
      //   path: "Hotel/Details",
      //   component: HotelMenuComponentComponent,
      //   data: { title: "Details" },
      //   children: [
      //     {
      //       path: "roomelist/:hodelcode",
      //       component: HotelRoomComponentComponent,
      //       canActivate: [AuthGuard],
      //       data: { title: "Room List" },
      //     },
      //     {
      //       path: "roomedit/:hodelcode/:roomid",
      //       component: HotelRoomEditComponentComponent,
      //       canActivate: [AuthGuard],
      //       data: { title: "Room Details" },
      //     },

      //     {
      //       path: "roomview/:hodelcode/:roomid",
      //       component: HotelRoomViewComponentComponent,
      //       canActivate: [AuthGuard],
      //       data: { title: "Room View" },
      //     },
      //     {
      //       path: "roomratesetup/:hodelcode",
      //       component: HotelRatesetupComponentComponent,
      //       canActivate: [AuthGuard],
      //       data: { title: "Rate setup" },
      //     },
      //     {
      //       path: "roomratesetupedit/:hodelcode/:roomid",
      //       component: HotelRatesetupEditComponentComponent,
      //       canActivate: [AuthGuard],
      //       data: { title: "Rate setup Details" },
      //     },
      //     {
      //       path: "roomratesetupview/:hodelcode/:roomid",
      //       component: HotelRatesetupViewComponentComponent,
      //       canActivate: [AuthGuard],
      //       data: { title: "Rate setup View" },
      //     },
      //     {
      //       path: "Edit/:hodelcode",
      //       component: HotelDetailsEditComponentComponent,
      //       canActivate: [AuthGuard],
      //       data: { title: "Hotel" },
      //     },
      //     {
      //       path: "View/:hodelcode",
      //       component: HotelDetailsComponentComponent,
      //       canActivate: [AuthGuard],
      //       data: { title: "Hotel" },
      //     },
      //     {
      //       path: "Inventory/:hodelcode",
      //       component: HotelInventoryComponentComponent,
      //       canActivate: [AuthGuard],
      //       data: { title: "Hotel" },
      //     },
      //   ],
      // },
      // {
      //   path: "Currency",
      //   component: CurrencyMenuComponent,
      //   data: { title: "Details" },
      //   children: [
      //     {
      //       path: "Details",
      //       component: CurrencyMasterListComponent,
      //       canActivate: [AuthGuard],
      //       data: { title: "Currency List" },
      //     },
      //     {
      //       path: "CurrencyExchangeList",
      //       component: CurrencyExchangerateListComponent,
      //       canActivate: [AuthGuard],
      //       data: { title: "Currency Exchange Rate List" },
      //     },
      //     {
      //       path: "CurrencyExcelUpload",
      //       component: CurrencyDataUploadComponent,
      //       canActivate: [AuthGuard],
      //       data: { title: "Currency Excel Upload" },
      //     },
      //   ],
      // },
      {
        path: "Folder",
        // component: DetailsComponent,
        data: { title: "Details" },
        children: [
          // {
          //   path: 'CRM/List',
          //   component: FolderQuotesListComponent,
          //   canActivate: [AuthGuard],
          //data: { title: 'Folder Quotes List' }
          // },
          // {
          //   path: 'CRM/Enquiry/Edit/:folderno',
          //   component: FolderQuotesEditComponent,
          //   canActivate: [AuthGuard],
          //data: { title: 'Folder Quotes Edit' }
          // },
          // {
          //   path: 'CRM/Enquiry/View/:folderno',
          //   component: FolderQuotesViewComponent,
          //   canActivate: [AuthGuard],
          //data: { title: 'Folder Quotes View' }
          // },

          // {
          //   path: "InsuranceDetails/:orderNo/:guidItinerary",
          //   component: InsuranceDetailsComponent,
          //   canActivate: [AuthGuard],
          //data: { title: "Add Itinerary" }
          // },

          // {
          //   path: "UesrReg/:orderNo/:guidItinerary",
          //   component: UserRegistrationComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "UesrReg" },
          // },
          // {
          //   path: "CarDetails/:orderNo/:guidItinerary",
          //   component: CardetailsComponent,
          //   canActivate: [AuthGuard],
          //data: { title: "Add Itinerary" }
          // },
 
          // {
          //   path: "RailsegDetails/:orderNo/:slno",
          //   component: RailsegDetailsComponent,
          //   canActivate: [AuthGuard],
          // data: { title: "Add Itinerary" }
          // },
          
         
          // {
          //   path: "AirsegDetails/:orderNo/:slno",
          //   component: AirsegDetailsComponent,
          //   canActivate: [AuthGuard],
          // data: { title: "Add Itinerary" }
          // },
          {
            path: "CarDetailsEdit/:orderNo/:guidItinerary",
            component: CarDetailsEditComponent,
            canActivate: [AuthGuard],
          data: { title: "Add Itinerary" }
          },
          {
            path: "CruiseDetailsAdd/:orderNo",
            component: CruiseDetailsEditComponent,
            canActivate: [AuthGuard],
          data: { title: "Add Itinerary" }
          },
          {
            path: "CruiseDetailsEdit/:orderNo/:guidItinerary",
            component: CruiseDetailsEditComponent,
            canActivate: [AuthGuard],
          data: { title: "Add Itinerary" }
          },
          {
            path: "OthersDetailsEdit/:orderNo/:guidItinerary",
            component: OthersDetailsEditComponent,
            canActivate: [AuthGuard],
          data: { title: "Add Itinerary" }
          },
          // {
          //   path: "AirsegDetailsEdit/:orderNo/:slno",
          //   component: AirsegDetailsEditComponent,
          //   canActivate: [AuthGuard],
          // data: { title: "Add Itinerary" }
          // },
          // {
          //   path: "AirSegDetailsAdd/:orderNo",
          //   component: AirsegDetailsEditComponent,
          //   canActivate: [AuthGuard],
          // data: { title: "Add AirSegment" }
          // },
          {
            path: "testbspedit",
            component: AirticketBspEditComponent,
            canActivate: [AuthGuard],
            data: { title: "Add Itinerary" },
          },
          {
            path: "AirticketDetailsAdd/:orderNo",
            component: AirticketDetailsEditComponent,
            canActivate: [AuthGuard],
          data: { title: "Add Itinerary" }
          },
          {
            path: "AirTicketDetailsEdit/:orderNo/:guidItinerary",
            component: AirticketDetailsEditComponent,
            canActivate: [AuthGuard],
          data: { title: "Add Itinerary" }
          },
          {
            path: "OthersDetailsAdd/:orderNo",
            component: OthersDetailsEditComponent,
            canActivate: [AuthGuard],
          data: { title: "Add Itinerary" }
          },
          // {
          //   path: "RailSegDetailsAdd/:orderNo",
          //   component: RailsegDetailsEditComponent,
          //   canActivate: [AuthGuard],
          // data: { title: "Add Itinerary" }
          // },
          // {
          //   path: "RailsegDetailsEdit/:orderNo/:slno",
          //   component: RailsegDetailsEditComponent,
          //   canActivate: [AuthGuard],
          //data: { title: "Add Itinerary" }
          // },
          {
            path: "PassengerDetails/:orderNo",
            component: PassengerDetailComponent,
            canActivate: [AuthGuard],
            data: { title: "Add Itinerary" },
          },
          {
            path: "Products/:orderNo",
            component: ProductsComponent,
            canActivate: [AuthGuard],
            data: { title: "Add Itinerary" },
          },
          // {
          //   path: "PassengerDetailView/:orderNo/:slno",
          //   component: PassengerdetailsviewComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Passenger Details View" },
          // },
          // {
          //   path: "PassengerDetailEdit/:orderNo/:slno",
          //   component: PassengerdetailseditComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Passenger Details Edit" },
          // },
          // {
          //   path: "FolderHistory/:orderNo",
          //   component: FolderhistoryComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Folder List" },
          // },
          {
            path: "DeliveryAddressEdit/:orderNo",
            component: DeliveryaddresseditComponent,
            canActivate: [AuthGuard],
            data: { title: "Delivery Address edit " },
          },
          {
            path: "DeliveryAddressview/:orderNo",
            component: DeliveryaddressComponent,
            canActivate: [AuthGuard],
            data: { title: "Delivery Address view" },
          },
          // {
          //   path: "PNRSourceFileDetails/:orderNo",
          //   component: PnrSourcefileComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Folder Options" },
          // },
          // {
          //   path: "FolderNotes/:orderNo",
          //   component: FolderNotesComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Notes" },
          // },
  
          // {
          //   path: "PackageSummaryEdit/:orderNo",
          //   component: PackageSummaryEditComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Package summary edit" },
          // },
          // {
          //   path: "RailDetailsEdit/:orderNo/:guidItinerary",
          //   component: RailDetailsEditComponent,
          //   canActivate: [AuthGuard],
          //data: { title: "Add Itinerary" }
          // },
          // {
          //   path: "TransfersDetail/:orderNo/:guidItinerary",
          //   component: TransfersDetailsComponent,
          //   canActivate: [AuthGuard],
          //data: { title: "Add Itinerary" }
          // },
          // {
          //   path: "TransfersDetailEdit/:orderNo/:guidItinerary",
          //   component: TransfersDetailEditComponent,
          //   canActivate: [AuthGuard],
          //data: { title: "Add Itinerary" }
          // },
          // {
          //   path: "CustomerReceipts/:orderNo",
          //   component: CustomerreceiptsComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Add Itinerary" },
          // },
          // {
          //   path: "Payment/:orderNo",
          //   component: PaymentComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Payment" },
          // },
          // {
          //   path: "AddCarDetails/:orderNo",
          //   component: CarDetailsEditComponent,
          //   canActivate: [AuthGuard],
          //data: { title: "Add Itinerary" }
          // },
          // {
          //   path: "AddRailDetails/:orderNo",
          //   component: RailDetailsEditComponent,
          //   canActivate: [AuthGuard],
          //data: { title: "Add Itinerary" }
          // },
          // {
          //   path: "AddTransferDetails/:orderNo",
          //   component: TransfersDetailEditComponent,
          //   canActivate: [AuthGuard],
          //data: { title: "Add Itinerary" }
          // },
          // {
          //   path: "BooingConfirmation/:orderNo",
          //   component: BookingConfirmationComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Booking Confirmation" },
          // },
          // {
          //   path: "Invoice/:orderNo",
          //   component: InvoiceComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Accounts" },
          // },
          // {
          //   path: "IssueCreditNote/:orderNo",
          //   component: InvoiceComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Accounts" },
          // },
          // {
          //   path: "DeleteInvoice/:orderNo",
          //   component: InvoiceComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Accounts" },
          // },
          // {
          //   path: "Reinvoice/:orderNo",
          //   component: InvoiceComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Accounts" },
          // },
          // {
          //   path: "RefundReset/:orderNo",
          //   component: InvoiceComponent,
          //   canActivate: [AuthGuard],
          //   data: { title: "Accounts" },
          // },
        ],
      },

   
      // {
      //   path: "Reports/CorporateInvoiceList",
      //   component: RptCorporateInvoiceListingComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Corporate Invoice Listing" },
      // },
      // {
      //   // path: "Reports/PurchaseListing",
      //   path: "Reports/PurchaseListingCommitmentReport",
      //   component: PurchaseListingComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Purchase Listing" },
      // },
      // {
      //   path: "Reports/TrialBalance",
      //   component: RptTrialBalanceComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Trial Balance" },
      // },
      // {
      //   path: "Reports/TrialBalancewithCostCenter",
      //   component: RptTrialBalancewithCostCenterComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Trial Balance with Cost Centere" },
      // },
      // {
      //   path: "UAE/VAT201VATReturns",
      //   component: VAT201VATReturnsComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "VAT201-VAT Returns" },
      // },
      // {
      //   path: "Reports/TaxCalculation",
      //   component: TaxCalculationComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Tax Calculation" },
      // },
      // {
      //   path: "CashDetails/PettyCash",
      //   component: CashDetailsComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Petty Cash" },
      // },
      // {
      //   path: "CashDetails/CashPayment",
      //   component: CashPaymentComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Petty Cash" },
      // },
      // {
      //   path: "CashDetails/CashReceipt",
      //   component: CashRecieptComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Cash Receipt" },
      // },

      // {
      //   path: "Reports/GeneralLedger",
      //   component: RptgeneralledgerComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "General Ledger" },
      // },
      // {
      //   path: "Reports/VoucherLiability",
      //   component: RptVoucherLiabilityComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Voucher Liability" },
      // },
      // {
      //   path: "Reports/AirticketReportWithPNRReport",
      //   component: RptAirticketReportWithPNRComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Air Ticket With PNR" },
      // },
      // {
      //   path: "Reports/AirlineDetailedReport",
      //   component: AirlineDetailedReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Airline Detailed Report" },
      // },
      // {
      //   path: "Reports/BanktoBankTransfer",
      //   component: BanktoBankTransferComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Bank to Bank Transfer" },
      // },
      // {
      //   path: "Reports/BankLedger",
      //   component: RptBankLedgerComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Bank Ledger" },
      // },
      // {
      //   path: "Bank/BankPayment",
      //   component: BankPaymentComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Bank Payment" },
      // },
      // {
      //   path: "Bank/CompanycardPayment",
      //   component: CompanycardpaymentComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Company Card Payment" },
      // },
      // {
      //   path: "Bank/BankReceipt",
      //   component: BankReceiptComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Bank Receipt" },
      // },
      // //13968
      // {
      //   path: "Bank/BankMaster",
      //   component: BankMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Bank Master" },
      // },
      // //14240
      // {
      //   path: "Bank/BacsReconciliation",
      //   component: BacsReconciliationComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Bacs Reconciliation" },
      // },
      // {
      //   path: "Bank/BankMasterAddedit/:autoNumber",
      //   component: BankMasterAddeditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Bank Master Add Edit" },
      // },
      // {
      //   path: "Bank/BankMasterAddedit/0",
      //   component: BankMasterAddeditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Bank Master Add Edit" },
      // },
      // {
      //   path: "Bank/BankMasterView/:autoNumber",
      //   component: BankMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Bank Master View" },
      // },
      // //13968
      // {
      //   path: "Bank/AutomaticCreditCardRecon",
      //   component: AutomaticCreditCardReconComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Automatic Credit Card Reconciliation" },
      // },
      // {
      //   path: "Bank/AutomaticCompanyCardRecon",
      //   component: AutomaticCompanyCardReconciliationComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Automatic Company Card Reconciliation" },
      // },
      // //14231
      // {
      //   path: "Bank/RewardPointsReconciliation",
      //   component: RewardPointsReconciliationComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Reward Points Reconciliation" },
      // },
      // {
      //   path: "Reports/CustomerStatement",
      //   component: RptCustomerStatementComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer statement" },
      // },

      // {
      //   path: "Reports/CustomerStatementbylineitem",
      //   component: CustomerStatementbylineitemComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Statement by line item" },
      // },
      // {//14143
      //   path: "Reports/CustomerAllocation",
      //   component: CustomerAllocationReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Allocation Report Component" },
      // },

      // {
      //   path: "Reports/CustomerReceipt",
      //   component: RptCustomerReceiptsComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Receipt" },
      // },
      // {
      //   path: "Reports/rptfolderactivity",
      //   component: RptFolderActivityReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Folder Activity" },
      // },
      // {
      //   path: "Reports/rptfolderactivityproduct",
      //   component: RptFolderActivityProductComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Folder Activity Product" },
      // },
      // //14290
      // {
      //   path: "Reports/FolderActivityDetailedReport",
      //   component: FolderActivityDetailedComponent,
      //   data: { title: "Folder Activity Detailed Report" },
      // },
      // //14290
      // {
      //   path: "Reports/rptfolderquery",
      //   component: RptFolderQueryComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Folder Query" },
      // },
      // {
      //   path: "Reports/rptsupplierstatement",
      //   component: RptSupplierStatementComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Supplier Statement" },
      // },
      // {
      //   path: "Reports/rptpandl",
      //   component: RptPandLReportsComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "P&L Analysis And Month wise" },
      // },
      // {
      //   path: "Supplier/ExpenseInvoiceSearch",
      //   component: ExpenseInvoiceSearchComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Expense Invoice" },
      // },
      // {
      //   path: "Supplier/ExpenseInvoiceList/:source",
      //   component: ExpenseInvoiceListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Expense Invoice List " },
      // },
      // {
      //   path: "Supplier/ExpenseInvoiceForm/:docNo/:tranID/:vrType/:yearID",
      //   component: ManualPurchaseDetailedComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Expense Invoice Form " },
      // },
      // {
      //   path: "Supplier/Payment",
      //   component: SupplierPaymentComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Supplier Receipts" },
      // },
      // {
      //   path: "Supplier/ViewSupplier",
      //   component: SupplierListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Supplier List" },
      // },
      // //14154
      // {
      //   path: "Reports/SuppierAllocationReport",
      //   component: SupplierAllocationReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "SupplierAllocationReportComponent" },
      // },
      // //14154
      // //14234
      // {
      //   path: "Reports/ForeignCurrencySupplierStatement",
      //   component: ForeignCurrencySupplierStatementComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "ForeignCurrencySupplierStatementComponent" },
      // },
      //14234
      //14227
      // {
      //   path: "Reports/SupplierOutstandingDetailedAsondateReport",
      //   component: SupplierOutstandingDetailedAsondateReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "SupplierOutstandingDetailedAsondateReport" },
      // },
      //14227
      //14495
      // {
      //   path: "Reports/SupplierOutstandingAsOnDateAgedReport",
      //   component: SupplierOutstandingAsOnDateAgedReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "SupplierOutstandingAsOnDateAgedReport" },
      // },
      //14495
      //14177
      // {
      //   path: "Reports/CustomerOutstandingDetailedAsondateReport",
      //   component: CustomerOutstandingDetailedReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "CustomerOutstandingDetailedAsondateReport" },
      // },
      //14177
      // //14494
      // {
      //   path: "Reports/CustomerOutstandingAsOnDateAgedSummaryReport",
      //   component: CustomerOutstandingAsOnDateAgedSummaryReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "CustomerOutstandingAsOnDateAgedSummaryReport" },
      // }, //14494
      // {
      //   path: "Supplier/supplierReconciliation",
      //   component: SupplierReconciliationComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Supplier Reconciliation" },
      // },
      //14214
      // {
      //   path: "Reports/SupplierOutStandingSummary",
      //   component: SupplierOutstandingSummaryComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Supplier OutStanding Summary" },
      // },
      //14214
      // {
      //   path: "Supplier/SupplierRemittanceAdvice",
      //   component: SupplierRemittanceAdviceComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Supplier Remittance Advice" },
      // },
      // {
      //   path: "Nominal/ViewNominal",
      //   component: NominalMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Nominal Master List" },
      // },
      // {
      //   path: "Nominal/NominalDetails/:nomcode",
      //   component: NominalMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Nominal Details" },
      // },
      // {
      //   path: "Nominal/NominalEdit/:nomcode",
      //   component: NominalMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Nominal Edit" },
      // },
      // {
      //   path: "ProductGroupMaster/ViewProductGroupMaster",
      //   component: ProductgroupmasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Product Group  Master List" },
      // },
      // {
      //   path: "ProductGroupMaster/ProductGroupMasterDetails/:productgroupcode",
      //   component: ProductgroupmasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Product Group  Master Details" },
      // },
      // {
      //   path: "ProductGroupMaster/ProductGroupMasterEdit/:productgroupcode",
      //   component: ProductgroupmasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Product Group  Master Edit" },
      // },
      // {
      //   path: "ConsolidatedInvoice/ViewConsolidatedInvoice",
      //   component: ConsolidatedInvoiceListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Consolidated Invoice List" },
      // },
      // {
      //   path: "CustomerList",
      //   component: CustomerListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer List" },
      // },
      // {
      //   path: "Customer/ViewCustomer",
      //   component: CustomermasterComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Master" },
      // },
      // {
      //   path: "Customer/AutoReceipt",
      //   component: AutoReceiptComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Auto Receipt by Line Item" },
      // },
      // {
      //   path: "Customer/AutoChargingInstallment",
      //   component: AutoChargingInstallmentComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Auto Charging Installment" },
      // },
      // {
      //   path: "Customer/CustomerDetails/:custcode",
      //   component: CustomerDetailsComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Details" },
      // },
      // {
      //   path: "Customer/Customeraddedit/:custcode",
      //   component: CustomerAddeditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Edit" },
      // },

      // {
      //   path: "Customer/Receipt",
      //   component: CustomerreceiptsComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Receipt" },
      // },
      // {
      //   path: "Customer/OutsideReceipt",
      //   component: CustomerreceiptoutsidefolderComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Receipt" },
      // },
      // {
      //   path: "Customer/ExportData",
      //   component: DataDictionaryComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Export Data" },
      // },
      // {
      //   path: "Supplier/SupplierDetails/:supcode",
      //   component: SupplierdetailsViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Supplier Details" },
      // },
      // {
      //   path: "Supplier/SupplierEdit/:supcode",
      //   component: SupplierdetailsEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Supplier Edit" },
      // },
      // {
      //   path: "Reports/rptpettycashreport",
      //   component: RptPettycashReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Petty Cash Report" },
      // },
      {
        path: "Settings/PropertyTypes",
        component: HotelPropertyTypesComponent,
        canActivate: [AuthGuard],
        data: { title: "Property Types" },
      },
      {
        path: "Settings/MealPlanMaster",
        component: MealplanmasterComponent,
        canActivate: [AuthGuard],
        data: { title: "Meal Plan Master" },
       },
      // {
      //   path: "Settings/RoomTypesMaster",
      //   component: RoomtypesmasterComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Room Types Master" },
      // },
      {
        path: "Settings/ProjectMaster",
        component: ProjectmasterComponent,
        canActivate: [AuthGuard],
        data: { title: "Project Master" },
      },
      // {
      //   path: "Projects/ProjectDetails/:autono",
      //   component: ProjectdetailsviewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Project Details" },
      // },
      // {
      //   path: "Projects/ProjectDetailsEdit/:autono",
      //   component: ProjectdetailseditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Project Edit" },
      // },
      // {
      //   path: "Settings/HotelPricingGroup",
      //   component: HotelpricinggroupComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "HotelPricingGroup" },
      // },
      // {
      //   path: "HotelPricingGroup/HotelPricingGroupDetails/:autono",
      //   component: HotelpricinggroupviewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Hotel PricingGroup View" },
      // },
      // {
      //   path: "HotelPricingGroup/HotelPricingGroupEdit/:autono",
      //   component: HotelpricinggroupeditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Hotel PricingGroup Edit" },
      // },
      // {
      //   path: "Commonsetting/ReportSchedulerList",
      //   component: ReportschedulerlistComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "ReportScheduler List" },
      // },
      //13613
      // {
      //   path: "Financesetting/AgentCommissionList",
      //   component: AgentCommissionListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Agent Commission List" },
      // },
      // {
      //   path: "Financesetting/AgentCommissionAdd/:autoNumber",
      //   component: AgentCommissionAddComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Agent Commission Detailed" },
      // },
      // {
      //   path: "Financesetting/AgentCommissionAdd/:autoNumber",
      //   component: AgentCommissionAddComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Agent Commission Detailed" },
      // },
      // {
      //   path: "Financesetting/AgentCommissionView/:autoNumber",
      //   component: AgentCommissionViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Agent Commission View" },
      // },
      //13613
      //13588
      // {
      //   path: "Settings/Commonsettings/MarketingCodeMaster",
      //   component: MarketingCodeMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Marketing Code Master List" },
      // },
      // {
      //   path: "Settings/Commonsettings/MarketingCodeMasterEdit/0",
      //   component: MarketingCodeMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Marketing Code Master Edit" },
      // },
      // {
      //   path: "Settings/Commonsettings/MarketingCodeMasterEdit/:autono",
      //   component: MarketingCodeMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Marketing Code Master Edit" },
      // },
      // {
      //   path: "Settings/Commonsettings/MarketingCodeMasterView/:autono",
      //   component: MarketingCodeMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Marketing Code Master View" },
      // },
      //13588

      // 13802
      // {
      //   path: "Settings/Commonsettings/ProfitCentreGroupMaster",
      //   component: ProfitCentreGroupMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Profit Center Group Master" },
      // },
      // {
      //   path: "Settings/Commonsettings/PostCodeSearchSettings",
      //   component: PostCodeSearchListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Post Code Search Settings" },
      // },

      // {
      //   path: "Settings/Commonsettings/PostCodeSearchSettings/postcodeEdit/:ID",
      //   component: PostCodeSearchSettingsComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Post Code Edit View" },
      // },
      //13861
      // {
      //   path: "Settings/Financesettings/CustomerGroupMaster",
      //   component: CustomerGroupMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Group Master List" },
      // },
      // {
      //   path: "Financesettings/CustomerGroupMasterView/:code",
      //   component: CustomerGroupMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Group Master View" },
      // },
      // {
      //   path: "Financesettings/CustomerGroupMasterEdit/:code",
      //   component: CustomerGroupMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Group Master Edit" },
      // },
      // //13921 
      // {
      //   path: "Settings/Financesettings/CustomerCreditGroup",
      //   component: CustomerCreditGroupListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Credit Group List" },
      // },
      // {
      //   path: "Financesettings/CustomerCreditGroupView/:code",
      //   component: CustomerCreditGroupViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Credit Group  View" },
      // },
      // {
      //   path: "Financesettings/CustomerCreditGroupEdit/:code",
      //   component: CustomerCreditGroupEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Credit Group  Edit" },
      // },
      //13953
      // {
      //   path: "Settings/Financesettings/SupplierGroupMaster",
      //   component: SupplierGroupMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Supplier Group Master List" },
      // },
      // {
      //   path: "Financesettings/SupplierGroupMasterView/:code",
      //   component: SupplierGroupMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Supplier Group Master  View" },
      // },
      // {
      //   path: "Financesettings/SupplierGroupMasterEdit/:code",
      //   component: SupplierGroupMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Supplier Group Master  Edit" },
      // },
      //13953
      // {
      //   path: "Settings/Commonsettings/ProfitCentreGroupMasterView/:autono",
      //   component: ProfitCentreGroupMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Profit Centre Group Master View" },
      // },
      // {
      //   path: "Settings/Commonsettings/ProfitCentreGroupMasterAddEdit/:autono",
      //   component: ProfitCentreGroupMasterAddEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Profit Centre Group Master Add" },
      // },

      //14106
      // {
      //   path: "Settings/Financesettings/AgeingScheduleMaster",
      //   component: AgingScheduleMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Aging Schedule Master List" },
      // },
      // {
      //   path: "Settings/Financesettings/AgeingScheduleMasterView/:code",
      //   component: AgingScheduleMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Aging Schedule Master View" },
      // },
      // {
      //   path: "Settings/Financesettings/AgeingScheduleMasterEdit/:code",
      //   component: AgingScheduleMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Aging Schedule Master Edit" },
      // },

      // 13802


      //13799
      // {
      //   path: "Settings/Financesettings/CostCentreMaster",
      //   component: CostCentreMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Cost Centre Master" },
      // },

      // {
      //   path: "Settings/Financesettings/CostCentreMasterView/:cscode",
      //   component: CostCentreMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Cost Centre Master View" },
      // },
      // {
      //   path: "Settings/Financesettings/CostCentreMasterAddEdit/:cscode",
      //   component: CostCentreMasterAddEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Cost Centre Master AddEdit" },
      // },
      // {
      //   path: "Settings/Financesettings/CardMaster",
      //   component: CardMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Card Master List" },
      // },
      // {
      //   path: "Settings/Financesettings/CardMasterView/:code",
      //   component: CardMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Card Master View" },
      // },
      // {
      //   path: "Settings/Financesettings/CardMasterEdit/:code",
      //   component: CardMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Card Master Edit" },
      // },

      ///14138
      // {
      //   path: "Settings/Commonsettings/workflowEdit/0",
      //   component: WorkflowEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Default Charges Add" },
      // },
      // {
      //   path: "Settings/Commonsettings/workflowView",
      //   component: WorkflowViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "WorkflowView" },
      // },
      // {
      //   path: "Settings/Commonsettings/workflowEdit/:autoNumber",
      //   component: WorkflowEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Workflow Add" },
      // },
      // {
      //   path: "Settings/Commonsettings/workflowView/:autoNumber",
      //   component: WorkflowViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Workflow View" },
      // },
      // {
      //   path: "Settings/Commonsettings/Workflow",
      //   component: WorkflowListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Wokflow" },
      // },

      //14138
      //13902
      // {
      //   path: "Settings/Financesettings/DefaultChargesEdit/0",
      //   component: DefaultChargesEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Default Charges Add" },
      // },
      // {
      //   path: "Settings/Financesettings/DefaultChargesEdit/:autoNumber",
      //   component: DefaultChargesEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Default Charges Add" },
      // },
      // {
      //   path: "Settings/Financesettings/DefaultChargesView/:autoNumber",
      //   component: DefaultChargesViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Default Charges View" },
      // },
      // {
      //   path: "Settings/Financesettings/DefaultCharges",
      //   component: DefaultChargesListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Default Charges List" },
      // },
      //13902

      //13989 by chithra
      // {
      //   path: "Settings/Financesettings/PaymentBlockMaster",
      //   component: PaymentBlockMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Payment Block Master List" },
      // },
      // {
      //   path: "Financesettings/PaymentBlockMasterEdit/:code",
      //   component: PaymentBlockMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Payment Block Master Add" },
      // },
      // {
      //   path: "Financesettings/PaymentBlockMasterView/:code",
      //   component: PaymentBlockMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Payment Block Master View" },
      // },
      //13989 by chithra
      //13970 by chithra
      // {
      //   path: "Settings/Financesettings/PaymentMethodMaster",
      //   component: PaymentMethodMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Payment Method Master List" },
      // },
      // {
      //   path: "Financesettings/PaymentMethodMasterEdit/:code",
      //   component: PaymentMethodMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Payment Method Master Add" },
      // },
      // {
      //   path: "Financesettings/PaymentMethodMasterView/:code",
      //   component: PaymentMethodMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Payment Method Master View" },
      // },
      //13970 by chithra
      //14057 by chithra
      // {
      //   path: "Settings/Financesettings/PaymentTermsMaster",
      //   component: PaymentTermsMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Payment Terms Master List" },
      // },
      // {
      //   path: "Settings/Financesettings/PaymentTermsMasterView/:code",
      //   component: PaymentTermsMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Payment Terms Master View" },
      // },
      // {
      //   path: "Settings/Financesettings/PaymentTermsMasterEdit/:code",
      //   component: PaymentTermsMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Payment Terms Master Edit" },
      // },
      //13799
      // {
      //   path: "Commonsetting/EmailSettingsEdit/:emailcode/:autoNumber",
      //   component: EmailSettingsEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Email Settings Edit" },
      // },
      // {
      //   path: "Commonsetting/EmailSettingsView/:autono",
      //   component: EmailSettingsViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Email Settings View" },
      // },


      // {
      //   path: "Commonsetting/EmailTemplateView/:autono",
      //   component: EmailTemplateViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Email Template View" },
      // },
      // {
      //   path: "Commonsetting/TemplateConditionsView/:autono",
      //   component: TemplateConditionsViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Template Conditions View" },
      // },

      // {
      //   path: "Commonsetting/ConditionsTypeView/:condid",
      //   component: ConditionsTypeViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Conditions Type View" },
      // },
      // {
      //   path: "Commonsetting/EmailTemplatesGrid",
      //   component: EmailTemplatesGridComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Email Templates Grid" },
      // },

      // {
      //   path: "Settings/Commonsettings/CannedMessage",
      //   component: CannedMessageListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Canned Message" },
      // },
      // {
      //   path: "Settings/Commonsettings/CannedMessageView/:msgid",
      //   component: CannedMessageViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Canned Message View" },
      // },
      // {
      //   path: "Settings/Commonsettings/CannedMessageEdit/:msgid",
      //   component: CannedMessageAddComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Canned Message Add/Edit" },
      // },

      //13638
      // {
      //   path: "Settings/Commonsetting/CountryMasterList",
      //   component: CountryMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Country Master List" },
      // },
      // {
      //   path: "Settings/Commonsetting/CRMSettings",
      //   component: CRMSettingsComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "CRM Settings View" },
      // },
      // {
      //   path: "Settings/Commonsetting/CountryMasterEdit/0",
      //   component: CountryMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Country Master Edit" },
      // },
      // {
      //   path: "Settings/Commonsetting/CountryMasterEdit/:autono",
      //   component: CountryMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Country Master Edit" },
      // },
      // {
      //   path: "Settings/Commonsetting/CountryMasterView/:autono",
      //   component: CountryMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Country Master View" },
      // },

      // //14005
      // {
      //   path: "Settings/Commonsetting/thirdPartyExcelMappingList",
      //   component: ThirdPartyExcelMappingListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Third Party Excel Mapping List" },

      // },
      // {
      //   path: "Settings/Commonsetting/thirdPartyExcelMappingAddEdit/:PMID/:PMNO",
      //   component: ThirdPartyExcelMappingAddEDitComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Third Party Excel Mapping Edit" },
      // },
      // 14005

      // {
      //   path: "Commonsetting/EmailSettingsList",
      //   component: EmailSettingsListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Email Settings List" },
      // },
      // {
      //   path: "Commonsetting/EmailSettingsEdit/:emailcode/:autoNumber",
      //   component: EmailSettingsEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Email Settings Edit" },
      // },
      // {
      //   path: "Commonsetting/EmailSettingsEdit/:autono",
      //   component: EmailSettingsEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "EmailSettingsEditComponent" },
      // },
      // {
      //   path: "Commonsetting/EmailSettingsView/:autono",
      //   component: EmailSettingsViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Email Settings View" },
      // },
      // {
      //   path: "Commonsetting/EmailTemplateView",
      //   component: EmailTemplateViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Email Template View" },
      // },
      // {
      //   path: "Commonsetting/TemplateConditionsView",
      //   component: TemplateConditionsViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Template Conditions View" },
      // },


      // //13638
      // {
      //   path: "Settings/Commonsetting/DropdownListMaster",
      //   component: DropdowmListMasterComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Dropdowm List Master" },
      // },
      // {
      //   path: "Settings/Commonsetting/DropdowmListMasterAddedit/:autoNumber",
      //   component: DropdowmListMasterAddeditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "DropdowmList Master Add/edit" },
      // },
      // {
      //   path: "Settings/Commonsetting/DropdowmListMasterAddedit/0",
      //   component: DropdowmListMasterAddeditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "DropdowmList Master Add/edit" },
      // },
      // {
      //   path: "Settings/Commonsetting/DropdowmListMasterView/:autono",
      //   component: DropdowmListMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Dropdowm List Master View" },
      // },
      //13654
      //13614
      // {
      //   path: "Settings/Commonsettings/ProfitCentreMaster", // 
      //   component: ProfitCenterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Profit Center List" },
      // },
      // {
      //   path: "Settings/Commonsettings/ProfitCentreAdd/:autoNumber",
      //   component: ProfitCenterAddComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Profit Center Add" },
      // },
      // {
      //   path: "Settings/Commonsettings/ProfitCentreAdd/0",
      //   component: ProfitCenterAddComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Profit Center Add" },
      // },
      // {
      //   path: "Settings/Commonsettings/ProfitCentreView/:autoNumber",
      //   component: ProfitCenterMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Profit Center View" },
      // },
      // {
      //   path: "Settings/Commonsettings/MainDocumentList",
      //   component: MainDocumentListComponent
      // },


      //14318
      // {
      //   path: "Settings/Commonsettings/SftpSettings",
      //   component: SFTPSettingsListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "SftpSettings" },
      // },
      // {
      //   path: "Settings/Commonsettings/SftpSettings/SftpSettingsDetails/:autono",
      //   component: SFTPSettingsEditViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "SftpSettings" },
      // },
      //13614
      //13637
      // {
      //   path: "Settings/Commonsettings/ActionMaster",
      //   component: ActionMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "ActionMaster List" },
      // },
      // {
      //   path: "Settings/Commonsettings/ActionMasteradd/0",
      //   component: ActionMasterAddComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "ActionMaster Edit" },
      // },
      // {
      //   path: "Settings/Commonsettings/ActionMasteradd/:autono",
      //   component: ActionMasterAddComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "ActionMaster Edit" },
      // },
      // {
      //   path: "Settings/Commonsettings/ActionMasterView/:autono",
      //   component: ActionMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "City Master View" },
      // },

      //13791

      //13879
      // {
      //   path: "travelsettings/AirwaysMaster",
      //   component: AirwaysMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Airways Master List" },
      // },
      // {
      //   path: "travelsettings/AirwaysMasterView/:AIRWAYSCODE",
      //   component: AirwaysMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Airways Master View" },
      // },
      // {
      //   path: "travelsettings/AirwaysMasterEdit/:AIRWAYSCODE",
      //   component: AirwaysMasterAddEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "City Master Edit" },
      // },
      //13879


      // {
      //   path: "travelsettings/GdsUsermappingList",
      //   component: GdsUsermappingListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "GDS User Mapping Set up" },
      // },
      // {
      //   path: "travelsettings/GdsUsermappingAddedit/:AutoNo",
      //   component: GdsUsermappingAddeditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "GDS User Mapping Set up Details" },
      // },

      // {
      //   path: "travelsettings/GdsUsermappingView/:AutoNo",
      //   component: GdsUsermappingViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "GDS User Mapping Set up View" },
      // },
      // //14103
      // {
      //   path: "Settings/travelsettings/IATAMaster",
      //   component: IATAMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "IATA Master List" },
      // },
      //13969 by chithra
      // {
      //   path: "travelsettings/StaffGroupMaster",
      //   component: StaffGroupMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Staff Group Master List" },
      // },
      //14277
      // {
      //   path: "Travelsettings/StoreFrontMaster",
      //   component: StoreFrontMasterListComponent,
      //   canActivate: [AuthGuard],
      // },
      // //14107
      // {
      //   path: "settings/Travelsettings/ThirdPartyMapping",
      //   component: ThirdPartyMappingComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Third Party Mapping" },
      // },
      // {
      //   path: "settings/Travelsettings/ThirdPartyProductMapping",
      //   component: ProductMappingComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "ProductMappingComponent" },
      // },
      // {
      //   path: "settings/Travelsettings/ThirdPartyBusinessAreaMapping",
      //   component: BusinessAreaMappingComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "BusinessAreaMappingComponent" },
      // },
      // {
      //   path: "settings/Travelsettings/ThirdPartyCustomerMapping",
      //   component: CustomerMappingComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "CustomerMappingComponent" },
      // },
      // //14055
      // {
      //   path: "settings/Travelsettings/ReasonCodeMaster",
      //   component: ReasonCodeMasterComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "ReasonCodeMaster" },
      // },
      //14333
      // {
      //   path: "settings/Travelsettings/GDSMaster",
      //   component: GDSMasterComponent,
      //   canActivate: [AuthGuard],
      // },
      //14107
      // {
      //   path: "travelsettings/StaffGroupMasterView/:code",
      //   component: StaffGroupMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Staff Group Master  View" },
      // },
      // {
      //   path: "travelsettings/StaffGroupMasterEdit/:code",
      //   component: StaffGroupMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Staff Group Master  Edit" },
      // },
      // 13969 by chithra

      // {
      //   path: "travelsettings/MarketingCodeGroupMaster",
      //   component: MarketingCodeGroupMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Marketing Code Group Master List" },
      // },
      //13943

      // {
      //   path: "Settings/Financesetting/TaxRateMaster",
      //   component: TaxRateMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Tax Rate Master List" },
      // },

      // {
      //   path: "Settings/Financesetting/TaxRateMasterAddEdit/:Taxno",
      //   component: TaxRateMasterAddEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Tax Rate Master Addedit" },
      // },
      // {
      //   path: "Settings/Financesetting/TaxRateMasterView/:Taxno",
      //   component: TaxRateMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "TaxRate Master View" },
      // },

      //13943
      // {
      //   path: "Settings/Financesetting/TaxMaster",
      //   component: TaxMasterGridFormComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Tax Master" },
      // },
      // {
      //   path: "Settings/Financesetting/TaxMasterDetails/:AutoNo",
      //   component: TaxMasterDetailedFormComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Tax Master Details" },
      // },
      // {
      //   path: "Settings/Financesetting/TaxMasterView/:AutoNo",
      //   component: TaxMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Tax Master Details" },
      // },
      // {
      //   path: "Settings/Commonsettings/MarketingCodeGroupMaster",
      //   component: MarketingCodeGroupMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Marketing Code Group Master List" },
      // },
      // {
      //   path: "Settings/Commonsettings/MarketingCodeGroupMasterAddEdit/:autono",
      //   component: MarketingCodeGroupMasterAddeditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Marketing Code Group Master Addedit" },
      // },
      // {
      //   path: "Settings/Commonsettings/MarketingCodeGroupMasterView/:autono",
      //   component: MarketingCodeGroupMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Marketing Code Group Master View" },
      // },
      //13791
      //14009
      // {
      //   path: "Settings/Commonsettings/MarketingRegion",
      //   component: MarkettingRegionListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Marketing Region List" },
      // },
      // {
      //   path: "Settings/Commonsettings/MarketingRegionEdit/:code",
      //   component: MarkettingRegionEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Marketing Region Addedit" },
      // },
      // {
      //   path: "Settings/Commonsettings/MarketingRegionView/:code",
      //   component: MarkettingRegionViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Marketing Region View" },
      // },
      //14009
      // {
      //   path: "Commonsetting/ConditionsTypeView",
      //   component: ConditionsTypeViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Conditions Type View" },
      // },
      // {
      //   path: "Commonsetting/EmailTemplatesGrid",
      //   component: EmailTemplatesGridComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Email Templates Grid" },
      // },
      // {
      //   path: "Settings/Commonsettings/Usersettings/Busareawiseemailandphone",
      //   component: BusareawiseemailandphoneComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Business area wise " },
      // },//14344
      // {
      //   path: "Commonsetting/EmailTemplateAdd/:tempId",
      //   component: EmailTemplateAddComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Email Template Add" },
      // },

      // {
      //   path: "Commonsetting/TemplateConditionsAdd/:autoNumber",
      //   component: TemplateConditionsAddComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: " Template Conditions Add" },
      // },
      // {
      //   path: "Commonsetting/ConditionsTypeAdd/:condid",
      //   component: ConditionsTypeAddComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Conditions Type Add" },
      // },
      {
        path: "Utilities/UnlockDocuments",
        component: UnlockdocumentComponent,
        canActivate: [AuthGuard],
        data: { title: "Unlock Documents" },
      },
      {
        path: "Utilities/Delink",
        component: DelinkReceiptFromFolderComponent,
        canActivate: [AuthGuard],
        data: { title: "Delink Receipt  from Folder" },
      },
      {
        path: "Utilities/PenChannel",
        component: PenchannelListComponent,
        canActivate: [AuthGuard],
        data: { title: "PenChannel" },
      },
      {
        path: "Utilities/PurgeAlerts",
        component: PurgeAlertsComponent,
        canActivate: [AuthGuard],
        data: { title: "PurgeAlerts" },
      },
      {
        path: "Utilities/MessageAnalysis",
        component: MessageAnalysisReportComponent,
        canActivate: [AuthGuard],
        data: { title: "Message Analysis Report " },
      },
      {
        path: "Utilities/FolderAddExpense",
        component: FolderaddExpenseComponent,
        canActivate: [AuthGuard],
        data: { title: "Folder Add Expense" },
      },

  ///// user DEtails
      // {
      //   path: 'Administration/UserDetails',
      //   data: { title: "User Details" },
      //   loadChildren: () => import('./userdetails-view/userdetails.module').then(m => m.userModule)
      // },
      // {
      //   path: "Administration/UserDetails",
      //   component: UserdetailsViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "User Details" },
      // },
      // {
      //   path: "Administration/UserDetailsEdit",
      //   component: UserdetailsEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "User Details" },
      // },
      // {
      //   path: "Adminstration/UserList",
      //   component: UserListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "User List" },
      // },
      // {
      //   path: "Reports/AuditReport",
      //   component: RptAuditReportFolderTransDetailsComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Audit Report" },
      // },
      // {
      //   path: "Hotel/List",
      //   component: HotelListComponentComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Hotel" },
      // },
      // {
      //   path: "Hotel/details/:hodelcode",
      //   component: HotelMenuComponentComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Hotel" },
      // },
      // {
      //   path: "Hotel/Edit/:hodelcode",
      //   component: HotelDetailsEditComponentComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Hotel" },
      // },
      // {
      //   path: "PaymentGatewayList/PaymentGatewayListDetails",
      //   component: PaymentgatewayListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Payment Gateway List" },
      // },

      // {
      //   path: "PettyCashMaster/ViewPettyCashMaster",
      //   component: PettycashmasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Petty Cash Master List" },
      // },

      // {
      //   path: "PettyCashMaster/PettyCashMasterDetails/:pettycashmastercode",
      //   component: PettycashmasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Petty Cash Master View" },
      // },
      // {
      //   path: "PettyCashMaster/PettyCashMasterEdit/:pettycashmastercode",
      //   component: PettycashmasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Petty Cash Master Edit" },
      // },
      // {
      //   path: 'SagePaySettings/SagePayGridLoadForm',
      //   component: SagepaysettingsgridloadformComponent,
      //   canActivate: [AuthGuard],
      //data: { title: 'SagePayGridLoadForm' }
      // },
    //   {
    //     path: "SagePaySettings/SagePaySettingsFormEdit/:provider/:pdqno/:transtype/:code",
    //     component: SagepaysettingsformeditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Sage Pay Settings Edit" },
    //   },

    //   {
    //     path: "SagePaySettings/SagePaySettingsFormView/:provider/:pdqno/:transtype",
    //     component: SagepaysettingsformviewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Sage Pay Settings Details" },
    //   },
    //   {
    //     path: "SagePaySettings/AddSagePaySettingsFormEdit/:provider/:code",
    //     component: SagepaysettingsformeditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Sage Pay Settings Edit" },
    //   },
    //   //14252
    //   {
    //     path: "EwaySettings/EwaySettingsFormEdit/:Provider/:PDQNumber/:TransType",
    //     component: EwaySettingsAddComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Eway Settings Edit" },
    //   },
    //   {
    //     path: "EwaySettings/AddEwaySettingsFormEdit/:Provider/:code",
    //     component: EwaySettingsAddComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Eway Settings Edit" },
    //   },

    //   {
    //     path: "EwaySettings/EwaySettingsFormView/:Provider/:PDQNumber/:TransType",
    //     component: EwaySettingsViewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Eway Settings Details" },
    //   },
    //   //14252
    //   {
    //     path: "RealexPaymentGatewaySettings/AddRealexPaymentGatewaySettingsEdit/:provider/:code",
    //     component: RealexpaymentssettingseditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Realex Payment Gateway Settings" },
    //   },
    //   {
    //     path: "RealexPaymentGatewaySettings/RealexPaymentGatewaySettingsView/:provider/:pdqno/:transtype",
    //     component: RealexpaymentsettingsviewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Realex Payment Gateway Settings View" },
    //   },
    //   {
    //     path: "RealexPaymentGatewaySettings/RealexPaymentGatewaySettingsEdit/:provider/:pdqno/:transtype/:code",
    //     component: RealexpaymentssettingseditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Realex Payment Gateway Settings Edit" },
    //   },
    //   {
    //     path: "BarClayCardSettings/BarClayCardSettingsDetails/:provider/:PDQNumber/:transType",
    //     component: BarclaycardsettingsViewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Barclay Card Settings View" },
    //   },
    //   {
    //     path: "BarClayCardSettings/BarClayCardSettingsDetailsEdit/:provider/:PDQNumber/:transType/:code",
    //     component: BarclaycardsettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Barclay Card Settings Edit" },
    //   },
    //   {
    //     path: "BarClayCardSettings/AddBarClayCardSettings/:provider/:code",
    //     component: BarclaycardsettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Barclay Card Settings Add" },
    //   },

    //   {
    //     path: "BarClayCardSmartPaySettings/BarClayCardSmartPaySettingsView/:provider/:PDQNumber/:transType",
    //     component: BarclaycardsmartpaysettingsViewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "BarClayCard SmartPay Settings View" },
    //   },
    //   {
    //     path: "BarClayCardSmartPaySettings/BarClayCardSmartPaySettingsEdit/:provider/:PDQNumber/:transType/:code",
    //     component: BarclaycardsmartpaysettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "BarClayCard SmartPay Settings Edit" },
    //   },
    //   {
    //     path: "BarClayCardSmartPaySettings/AddBarClayCardSmartPaySettings/:provider/:code",
    //     component: BarclaycardsmartpaysettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "BarClayCard SmartPay Settings Add" },
    //   },
    //   {
    //     path: "NetbanxPaymentGatewaySettings/NetbanxPaymentGatewaySettingsEdit/:provider/:pdqno/:transtype/:code",
    //     component: NetbanxpaymentgatewaysettingseditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Netbanx PaymentGateway Settings Edit" },
    //   },
    //   {
    //     path: "NetbanxPaymentGatewaySettings/AddNetbanxPaymentGatewaySettings/:provider/:code",
    //     component: NetbanxpaymentgatewaysettingseditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Netbanx PaymentGateway Settings Edit" },
    //   },
    //   {
    //     path: "NetbanxPaymentGatewaySettings/NetbanxPaymentGatewaySettingsView/:provider/:pdqno/:transtype",
    //     component: NetbanxpaymentgatewaysettingsviewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Netbanx PaymentGateway Settings View" },
    //   },

    //   {
    //     path: "BankPayInSlip",
    //     component: BankPayInSlipComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Bank Pay In Slip" },
    //   },
    //   {
    //     path: "IngenicoSettings/IngenicoSettingsView/:provider/:PDQNumber/:transType",
    //     component: IngenicosettingsViewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Ingenico Settings View" },
    //   },
    //   {
    //     path: "IngenicoSettings/IngenicoSettingsEdit/:provider/:PDQNumber/:transType/:code",
    //     component: IngenicosettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Ingenico Settings Edit" },
    //   },
    //   {
    //     path: "IngenicoSettings/AddIngenicoSettings/:provider/:code",
    //     component: IngenicosettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Ingenico Settings Add" },
    //   },

    //   {
    //     path: "FlyNowPayLaterPaymentGatewaySettings/FlyNowPayLaterPaymentGatewaySettingsEdit/:provider/:pdqno/:transtype/:code",
    //     component: FlynowpaylaterpaymentgatewaysettingseditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "FlyNowPayLater PaymentGateway Settings Edit" },
    //   },
    //   {
    //     path: "FlyNowPayLaterPaymentGatewaySettings/AddFlyNowPayLaterPaymentGatewaySettings/:provider/:code",
    //     component: FlynowpaylaterpaymentgatewaysettingseditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "FlyNowPayLater PaymentGateway Settings Edit" },
    //   },
    //   {
    //     path: "FlyNowPayLaterPaymentGatewaySettings/FlyNowPayLaterPaymentGatewaySettingsView/:provider/:pdqno/:transtype",
    //     component: FlynowpaylaterpaymentgatewaysettingsviewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "FlyNowPayLater PaymentGateway Settings View" },
    //   },

    //   {
    //     path: "WorldPayCorporateSettings/WorldPayCorporateSettingsView/:provider/:PDQNumber/:transType",
    //     component: WorldpaycorporateViewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "WorldPay Corporate Settings View" },
    //   },
    //   {
    //     path: "WorldPayCorporateSettings/WorldPayCorporateSettingsEdit/:provider/:PDQNumber/:transType/:code",
    //     component: WorldpaycorporateEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "WorldPay Corporate Settings Edit" },
    //   },
    //   {
    //     path: "WorldPayCorporateSettings/AddWorldPayCorporateSettings/:provider/:code",
    //     component: WorldpaycorporateEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "WorldPay Corporate Settings Add" },
    //   },
    //   {
    //     path: "FirstdataSettings/View/:provider/:PDQNumber/:transType",
    //     component: FirstdatasettingsViewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "FirstData Settings View" },
    //   },
    //   {
    //     path: "FirstdataSettings/Add/:provider/:code",
    //     component: FirstdatasettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "FirstData Settings Add" },
    //   },
    //   {
    //     path: "FirstdataSettings/Edit/:provider/:PDQNumber/:transType/:code",
    //     component: FirstdatasettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "FirstData Settings Add" },
    //   },

    //   {
    //     path: "WorldPaySettings/WorldPaySettingsEdit/:provider/:pdqno/:transtype/:code",
    //     component: WorldpaysettingseditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "WorldPay Settings Edit" },
    //   },
    //   {
    //     path: "WorldPaySettings/AddWorldPaySettings/:provider/:code",
    //     component: WorldpaysettingseditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "WorldPay Settings Edit" },
    //   },
    //   {
    //     path: "WorldPaySettings/WorldPaySettingsView/:provider/:pdqno/:transtype",
    //     component: WorldpaysettingsviewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "WorldPay Settings View" },
    //   },

    //   {
    //     path: "StripePaymentSettings/StripePaymentSettingsView/:provider/:PDQNumber/:transType",
    //     component: StripepaymentsettingsViewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Stripe Payment Settings View" },
    //   },
    //   {
    //     path: "StripePaymentSettings/StripePaymentSettingsEdit/:provider/:PDQNumber/:transType/:code",
    //     component: StripepaymentsettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Stripe Payment Settings Edit" },
    //   },
    //   {
    //     path: "StripePaymentSettings/AddStripePaymentSettings/:provider/:code",
    //     component: StripepaymentsettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Stripe Payment Settings Add" },
    //   },
    //   {
    //     path: "CRM/Search",
    //     component: CRMSearchParentComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Folder Quotes Search" },
    //   },
    //   {
    //     path: "CRM/List",
    //     component: FolderQuotesListComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Folder Quotes List" },
    //   },
    //   {
    //     path: "CRM/Enquiry/Edit/:orderNo",
    //     component: FolderQuotesEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Folder Quotes Edit" },
    //   },
    //   {
    //     path: "CRM/Enquiry/View/:orderNo",
    //     component: FolderQuotesViewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Folder Quotes View" },
    //   },
    //   {
    //     path: "TrustPaySettings/TrustPaySettingsEdit/:provider/:pdqno/:transtype/:code",
    //     component: TrustpaysettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Trustpay Settings Edit" },
    //   },
    //   {
    //     path: "TrustPaySettings/AddTrustPaySettings/:provider/:code",
    //     component: TrustpaysettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Trustpay Settings Edit" },
    //   },
    //   {
    //     path: "TrustPaySettings/TrustpaySettingsView/:provider/:pdqno/:transtype",
    //     component: TrustpaysettingsViewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Trustpay Settings View" },
    //   },
    //   {
    //     path: "WexPaymentSettings/AddWexPaymentSettings",
    //     component: WexpaymentssettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Wex Payment Settings Edit" },
    //   },
    //   {
    //     path: "WexPaymentSettings/WexPaymentSettingsView/:AutoNo",
    //     component: WexpaymentssettingsViewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Wex Payment Settings View" },
    //   },
    //   {
    //     path: "WexPaymentSettings/WexPaymentSettingsEdit/:AutoNo",
    //     component: WexpaymentssettingsEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Wex Payment Settings Edit" },
    //   },
    //   {
    //     path: "PermissionMessage/PermissionMessageForm",
    //     component: InternalNotesComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Permission Message Form" },
    //   },
    //   {
    //     path: "Account/MyAccounts",
    //     component: MyAccountsComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "My Accounts" },
    //   },
    //   {
    //     path: "AirTicketDetails/AirTicketDetailsADMACM/:folderNo/:guidItinerary/:AutoNo/:OrderNo",
    //     component: AirticketAdmAcmEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "AirTicketDetails ADM ACM" },
    //   },
    //   {
    //     path: "CurrencyMaster/CurrencyMasterEdit/:currencycode/:autoNumber",
    //     component: CurrencyMasterEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Currency Master Edit" },
    //   },
    //   {
    //     path: "CurrencyMaster/CurrencyMasterView/:currencycode/:autoNumber",
    //     component: CurrencyMasterViewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Currency Master View" },
    //   },
    //   {
    //     path: "CurrencyMaster/CurrencyDataUpload",
    //     component: CurrencyDataUploadComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Currency Data Upload" },
    //   },
    //   {
    //     path: "CRMSettings/ScreenVariant",
    //     component: CRMScreenVariantComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "CRM Settings" },
    //   },
    //   {
    //     path: "CRMSettings/DealIdMaster",
    //     component: DealIDMasterComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Deal ID Master" },
    //   },
    //   {
    //     path: "travelsettings/DealIDMasterDetails/:AutoNo",
    //     component: DealIDMasterDetailsComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Deal ID MasterDetails" },
    //   },
    //   {
    //     path: "travelsettings/OrderTypeMaster",
    //     component: OrdertypemasterListComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "OrderTypeMaster List" },
    //   },
    //   {
    //     path: "travelsettings/FraudScoreSettings",
    //     component: FraudScoreSettingslistComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Fraud Score Settings List" },
    //   },
    //   {
    //     path: "travelsettings/FraudScoreSettingsedit/:autono",
    //     component: FraudScoreSettingseditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Fraud Score Settings Edit" },
    //   },
    //   {
    //     path: "travelsettings/FraudScoreSettingsview/:autono",
    //     component: FraudScoreSettingsviewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Fraud Score Settings View" },
    //   },
    //   {
    //     path: "travelsettings/CityMaster",
    //     component: CityMasterListComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "City Master List" },
    //   },
    //   {
    //     path: "travelsettings/CityMasterEdit/:code",
    //     component: CityMasterEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "City Master Edit" },
    //   },
    //   {
    //     path: "travelsettings/CityMasterView/:code",
    //     component: CityMasterViewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "City Master View" },
    //   },
    //   {
    //     path: "Settings/travelsettings/GDSAutoFolderSettings",
    //     component: GDSAutoFolderSettingsListComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "GDS Auto Folder Settings List" },
    //   },
    //   {
    //     path: "Settings/travelsettings/GDSAutoFolderSettingsBusiness",
    //     component: GDSAutoFolderSettingsBusinessComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "GDS Auto Folder Settings Business List" },
    //   },

    //   {
    //     path: "OrderTypeMaster/OrderTypeMasterDetails/:autono/:system",
    //     component: OrdertypemasterViewComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Order Type Master Details" },
    //   },
    //   {
    //     path: "OrderTypeMaster/OrderTypeMasterEdit/:autono",
    //     component: OrdertypemasterEditComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Order Type Master Edit" },
    //   },

    //   {
    //     path: "Customer/ManualSales/:BNO/:TRANID/:VRTYPE/:yearid",
    //     component: ManualSalesComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Manual Sales" }
    //   },
    //   {
    //     path: "Customer/ManualSalesList",
    //     component: ManualsaleslistComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Manual Sales List" },
    //   },
    //   {
    //     path: "Customer/ManualSalesSearch",
    //     component: ManualsalesSearchComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Manual Sales Search" },
    //   },
    //   {
    //     path: "Customer/ManualSalesCreditnoteSearch",
    //     component: ManulasalesCreditsnoteSearchComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Manual Sales Credit Note Search" },
    //   },
    //   {
    //     path: "Customer/ManualCreditNotes",
    //     component: ManualsalesreturnlistComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Manual Sales Return List" },
    //   },
    //   {
    //     path: "Reports/FolderactivitityReportgridDetailed",
    //     component: FolderActivityDetailedReportComponent,
    //     //canActivate: [AuthGuard],
    //     data: { title: "FolderActivityDetailedReportComponent" },
    //   },
    //   {
    //     path: "Customer/ManualSalesReturn/:BNO/:TRANID/:VRTYPE/:yearid",
    //     component: ManualSalesReturnComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Manual Sales Return" }
    //   },
      {
        path: 'Search',
        data: { title: "Search" },
        loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
          // children: [
          //   { path: '',  loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
          //   },
          // ]
    },
    // {
      
    //     // path: "SearchBy",
    //     // component: SearchParentComponent,
    //     // data: { title: "Search" },
    //     // children: [
    //     //   {
    //     //     path: "Folder",
    //     //     component: SearchbyFolderComponent,
    //     //     canActivate: [AuthGuard],
    //     //     data: { title: "Folder" },
    //     //   },
    //     //   {
    //     //     path: "Hotel",
    //     //     component: SearchbyHotelComponent,
    //     //     canActivate: [AuthGuard],
    //     //     data: { title: "Hotel" },
    //     //   },
    //     //   {
    //     //     path: "Car",
    //     //     component: SearchbyCarComponent,
    //     //     canActivate: [AuthGuard],
    //     //     data: { title: "Car" },
    //     //   },
    //     //   {
    //     //     path: "Rail",
    //     //     component: SearchbyRailComponent,
    //     //     canActivate: [AuthGuard],
    //     //     data: { title: "Rail" },
    //     //   },
    //     //   {
    //     //     path: "Transfers",
    //     //     component: SearchbyTransfersComponent,
    //     //     canActivate: [AuthGuard],
    //     //     data: { title: "Transfers" },
    //     //   },
    //     //   {
    //     //     path: "Tour",
    //     //     component: SearchbyTourComponent,
    //     //     canActivate: [AuthGuard],
    //     //     data: { title: "Tour" },
    //     //   },
    //     //   {
    //     //     path: "Cruise",
    //     //     component: SearchbyCruiseComponent,
    //     //     canActivate: [AuthGuard],
    //     //     data: { title: "Cruise" },
    //     //   },
    //     //   {
    //     //     path: "Insurance",
    //     //     component: SearchbyInsuranceComponent,
    //     //     canActivate: [AuthGuard],
    //     //     data: { title: "Insurance" },
    //     //   },
    //     //   {
    //     //     path: "Others",
    //     //     component: SearchbyOthersComponent,
    //     //     canActivate: [AuthGuard],
    //     //     data: { title: "Others" },
    //     //   },
    //     // ],
    //   },
    //   {
    //     path: "Reports/CustomerLedgerwithItinerary",
    //     component: CustomerledgerwithitineraryComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Customer Ledger with Itinerary" },
    //   },
    //   {
    //     path: "Reports/CustomerOutstandingSummary",
    //     component: CustomerOutstandingSummaryComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Customer Outstanding Summary" },
    //   },
    //   //14197
    //   {
    //     path: "Reports/DepositAsondateReport",
    //     component: DepositAsondateReportComponent,
    //     canActivate: [AuthGuard],
    //     data: { title: "Deposit Asondate Report" },
    //   },
      //14197
      //14350
      // {
      //   path: "Reports/CustomerLedgerReport",
      //   component: CustomerLedgerReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Ledger Report" },
      // },
      // {
      //   path: "Reports/CustomerLedgerReportgrid",
      //   component: CustomerLedgerReportGridComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Customer Ledger Report grid" },
      // },
      //    //14360
      // {
      //   path: "Reports/SupplierLedgerReport",
      //   component: SupplierLederReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Supplier Ledger Report" },
      // },

      // {
      //   path: "Reports/SupplierLedgerReportGrid",
      //   component: SupplierLedgerReportGridComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "SupplierLedgerReportGrid" },
      // },
      //14360
      //14254

      // {
      //   path: "Reports/DeferredRevenueAndExpenseReport",
      //   component: DeferredRevenueAndExpenseReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "DeferredRevenueAndExpenseReport" },
      // },
      // //14254
      // //14255
      // {
      //   path: "Reports/DepositCollectedforFutureTravel",
      //   component: DepositCollectedForFutureTravelReportComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Deposit Collected for Future Travel" },
      // },
      // {
      //   path: "GiftCard/Voucher",
      //   component: VouchermanagementComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Voucher" },
      // },
      // {
      //   path: "GiftCard/GiftCardMaster",
      //   component: GiftCardMasterListComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Gift Card Master" },
      // },
      // {
      //   path: "GiftCard/GiftCardMasterEdit/:giftcode",
      //   component: GiftCardMasterEditComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Gift Card Master Edit" },
      // },
      // {
      //   path: "GiftCard/GiftCardMasterview/:giftcode/:vrcode",
      //   component: GiftCardMasterViewComponent,
      //   canActivate: [AuthGuard],
      //   data: { title: "Gift Card Master View" },
      // },

    ],
  },

  // {
  //   path: "Login",
  //   //component: PublicLayoutComponent,
  //   component: LoginComponent,
  //   data: { title: "Login" },
  // },
  {
  path: '',
  data: { title: "Login" },
  loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
},


  // {
  //   path: "ConfigurationSettings",
  //   component: ConfigurationSettingsComponent,
  //   data: { title: "Configuration Settings" },
  // },
  // {
  //   path: "Reconcilation/BankReconcilation",
  //   component: BankReconcilationComponent,
  //   //canActivate: [AuthGuard],
  //   data: { title: "Bank Reconcilation" },
  // },

  // {
  //   path: "EmailInvoice/EmailInvoice",
  //   component: EmailInvoiceComponent,
  //   canActivate: [AuthGuard],
  //   data: { title: "Email Invoice" },
  // },
  //14606
  // {
  //   // path: "FolderSalesReceiptReport/FolderSalesReceiptReport",
  //   path: "Reports/FolderSalesReceiptReport",
  //   component: FolderSalesReceiptReportComponent,
  //   canActivate: [AuthGuard],
  //   data: { title: "Folder Sales Receipt Report" },
  // },
  // {
  //   path: "ManualPDQReconciliation",
  //   component: ManualPDQReconcilationComponent,
  //   canActivate: [AuthGuard],
  //   data: { title: "Manual PDQ Reconciliation" },
  // },

  // {
  //   path: "BSPManualReconciliation",
  //   component: ManualBspreconciliationComponent,
  //   canActivate: [AuthGuard],
  //   data: { title: "Manual BSP Reconciliation" },
  // },




  // {
  //   path: "CashDetails/PettyCash",
  //     component: CashDetailsComponent,
  //     canActivate: [AuthGuard],
  //   data: { title: "Petty Cash" },
  // },
  // {
  //   path: "CashDetails/CashPayment",
  //     component: CashPaymentComponent,
  //     canActivate: [AuthGuard],
  //   data: { title: "Petty Cash" },
  // },
  // {
  //   path: "Reports/AuditReportView",
  //   component: RptAuditReportComponent,
  //   //canActivate: [AuthGuard],
  //   data: { title: "Audit Report" },
  // },
  // {
  //   path: "Reports/LedgerReportView",
  //   component: RptLedgerReportgridComponent,
  //   //canActivate: [AuthGuard],
  //   data: { title: "Ledger Report" },
  // },

  // {
  //   path: "Reports/CustomerOutstandingReportView",
  //   component: CustomerOutstandingSummaryReportViewComponent,
  //   //canActivate: [AuthGuard],
  //   data: { title: "Outstanding Customer Report" },
  // },

  // {
  //   path: "Reports/AirLineDetailedReportView",
  //   component: RptAirlineDetailedReportComponent,
  //   //canActivate: [AuthGuard],
  //   data: { title: "AirLineDetailed Report" },
  // },
  // {
  //   path: "Reports/FolderQueryReport",
  //   component: FolderQueryReportComponent,
  //   data: { title: "Folder Query Report" },
  // },

  // {
  //   path: "Reports/FolderQuerySummaryReport",
  //   component: FolderQuerySummaryReportComponent,
  //   data: { title: "Folder Query Summary Report" },
  // },
  // {
  //   path: "Reports/PassengerBookingDetails",
  //   component: FlightBookingDetailsReportComponent,
  //   data: { title: "Passenger Booking Details" },
  // },
  // {
  //   path: "Reports/FolderActivityReportbyProductSummary",
  //   component: FolderActivityReportbyProductSummaryComponent,
  //   data: { title: "Folder Activity Report by Product Summary" },
  // },
  // {
  //   path: "Reports/FeesReportgridDetailed",
  //   component: RptFeesReportgridComponent,
  //   //canActivate: [AuthGuard],
  //   data: { title: "Fees Report Detailed" },
  // },
  // {
  //   path: "Reports/FolderactivitityReportgridDetailed",
  //   component: FolderActivityDetailedReportComponent,
  //   //canActivate: [AuthGuard],
  //   data: { title: "Folder Activity DetailedReport" },
  // },
  // {
  //   path: "Reports/FolderInstallmentGridRprt",
  //   component: FolderInstallmentGridRprtComponent,
  //   //canActivate: [AuthGuard],
  //   data: { title: "FolderInstallment Detailed" },
  // },
  // {
  //   path: "Reports/FolderActivityRptbyProductDetailed",
  //   component: FolderActivityReportbyProductDetailedComponent,
  //   data: { title: "Folder Activity Report by Product Detailed" },
  // },
  // {
  //   path: "Reports/GeneralledgerReport",
  //   component: GeneralledgerReportComponent,
  //   //canActivate: [AuthGuard],
  //   data: { title: "General Ledger Report" },
  // },
  // {
  //   path: "ApproveRequest/:usrId/:modId", //
  //   component: AuthorizeUserComponent,
  //   canActivate: [AuthGuard],
  //   data: { title: "Authorization" },
  // },
  // {
  //   path: "RejectRequest/:usrId/:modId", //
  //   component: AuthorizeUserComponent,
  //   canActivate: [AuthGuard],
  //   data: { title: "Authorization" },
  // },
  // {
  //   path: "ResetPassword/:token", //
  //   component: ResetPasswordComponent,
  //   data: { title: "Reset Password" },
  // },
  // {
  //   path: "WhatsappMesssage/:folderNo", //
  //   component: WhatsappMessagingComponent,
  //   data: { title: "Messaging" },
  // },
  // {
  //   path: "PaymentGatewayList/PaymentGatewayListDetails",
  //   component: PaymentgatewayListComponent,
  //   canActivate: [AuthGuard],
  //   data: { title: "Payment Gateway List" },
  // },
  // {
  //   path: "WebReportView/:queryString", //
  //   component: WebLinkReportViewComponent,
  //   data: { title: "Authorization" },
  // },
  
  // {
  //   path: "Search",
  //   component: PublicLayoutComponent,
  //   data: { title: "Search" },
  //   children: [
  //     {
  //       path: "Searchby",
  //       component: SearchParentComponent,
  //       canActivate: [AuthGuard],
  //       data: { title: "Searchby" },
  //       children: [
  //         {
  //           path: "FolderSearch",
  //           component: SearchbyFolderComponent,
  //           canActivate: [AuthGuard],
  //           data: { title: "FolderSearch" },
  //         },
  //         {
  //           path: "Hotel",
  //           component: SearchbyHotelComponent,
  //           canActivate: [AuthGuard],
  //           data: { title: "Hotel" },
  //         },
  //         {
  //           path: "Car",
  //           component: SearchbyCarComponent,
  //           canActivate: [AuthGuard],
  //           data: { title: "Car" },
  //         },
  //         {
  //           path: "Rail",
  //           component: SearchbyRailComponent,
  //           canActivate: [AuthGuard],
  //           data: { title: "Rail" },
  //         },
  //         {
  //           path: "Transfers",
  //           component: SearchbyTransfersComponent,
  //           canActivate: [AuthGuard],
  //           data: { title: "Transfers" },
  //         },
  //         {
  //           path: "Tour",
  //           component: SearchbyTourComponent,
  //           canActivate: [AuthGuard],
  //           data: { title: "Tour" },
  //         },
  //         {
  //           path: "Cruise",
  //           component: SearchbyCruiseComponent,
  //           canActivate: [AuthGuard],
  //           data: { title: "Cruise" },
  //         },
  //         {
  //           path: "Insurance",
  //           component: SearchbyInsuranceComponent,
  //           canActivate: [AuthGuard],
  //           data: { title: "Insurance" },
  //         },
  //         {
  //           path: "Others",
  //           component: SearchbyOthersComponent,
  //           canActivate: [AuthGuard],
  //           data: { title: "Others" },
  //         },
  //       ],
  //     },
  //   ],
  // },
  { path: '**', redirectTo: 'Login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
