import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { OthersDetail } from '../../Models/others-detail';
import { OthersService } from '../../Services/others.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LookUpDetailsService } from 'src/app/Services/look-up-details.service';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { CurrencyExchangerateService } from '../../Services/currency-exchangerate.service';
import { CurrencyExchangerate } from '../../Models/currency-exchangerate';
import { ShareDataService } from '../../Services/share-data.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';

import { NgxSpinnerService } from "ngx-spinner";
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription, Subject } from 'rxjs';
import { SupplierMasterService } from '../../Services/supplier-master.service';
import { ToastrService } from 'ngx-toastr';
import { PocreationService } from '../../Services/pocreation.service';
import { ProductUpdatePODTO } from '../../Models/pocreation-detail';
import { FolderpriceService } from '../../Services/folderprice.service';

@Component({
  selector: 'app-others-details-edit',
  templateUrl: './others-details-edit.component.html',
  styleUrls: ['./others-details-edit.component.scss']
})


export class OthersDetailsEditComponent implements OnInit {
  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  private subscription: Subscription = new Subscription();
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  @ViewChild("apppaymenttermsedit", { read: false, static: false }) public payterm: any;
  @ViewChild("appfchcdetailsedit", { read: false, static: false }) public fchcdetails: any;
  public opened = false;
  public openproduct = false;
  disableTextbox = true;
  folderExrate: any;
  public PONO;
  prdtUpdatePODTO: ProductUpdatePODTO;
  fldrCurrency: any;
  private exRate: any;
  folderEditMode: any;
  currencyExrate: CurrencyExchangerate;
  ucode: string;
  OthersEditForm: FormGroup;
  submitted = false;
  loaded = false;
  public enablePackage = false;
  isDefault: any = false;
  public enableCheck = true;
  public showMsg: any;
  savebutton = true;
  Uname: any = "";
  openSearch = false;
  public pocreated = true;
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  FC_Tot_BuyInitalvalue: any = 0;
  FCSell: any = 0;
  //OthersDetailModal=OthersDetail;
  public statusList: Array<string> = ["Wait listed", "Confirmed", "Cancelled"];
  public commissinOnList: Array<string> = ["Purchase", "Sales", "Gross"];
  public passengerTypeList: Array<string> = ["Adult", "Youth", "Child", "Infant"];

  constructor(private apiPO: PocreationService, public common: CommonserviceService, private router: Router, private route: ActivatedRoute,
    private formBuilder: FormBuilder, private api: OthersService, private supplier: SupplierMasterService,
    private lookupApi: LookUpDetailsService, private apiExRate: CurrencyExchangerateService, private toastr: ToastrService,
    private apiShared: ShareDataService, private flashMessage: FlashMessagesService, private translateapi: TranslateService, private spinner: NgxSpinnerService, private objFolderPrice: FolderpriceService) { }

  private guid: string = this.route.snapshot.params['guidItinerary'];//'Others.20190826.103628.{B9CB75FE-A3D6-46DE-926E-27990C46ECEC}/';
  private orderno = this.route.snapshot.params['orderNo']
  Currency: Array<LookUpDetails["ResponseData"]> = [];
  public CurrencyselectedItem: LookUpDetails["ResponseData"];

  public BookedByselectedItem: LookUpDetails["ResponseData"];
  BookedBy: Array<LookUpDetails["ResponseData"]> = [];
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];
  public supplierSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelected: String;

  ProductList: Array<LookUpDetails["ResponseData"]> = [];
  public ProductSelectedItem: LookUpDetails["ResponseData"];
  public ProductSelected: String;
  public projNo: any;
  hcExRate: any;
  homeCurrency: any;
  bookingDate: any //337
  depositDueDate: any//337
  creditLimitMsg = false;
  creditLimiFlag = false;
  msg: any;
  lockStatus: any;
  folderStatus: any;
  formeditable: boolean = true;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  ngOnInit() {
    //case id : 13777 
    debugger;
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    } else {
      this.formeditable = false;

    }
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;

    }
    // case id : 13777
    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderno !== orderno) {
        return this.router.navigate(['PenAir/Home'], { skipLocationChange: true });
      }
    this.hcExRate = this.apiShared.hcExRate;
    this.homeCurrency = this.apiShared.homeCurrency;

    this.getFolderValues();

    this.folderEditMode = "true";
    // this.apiShared.folderEditMode=this.folderEditMode; ||this.parentForm.controls.ITYPE.value=="Others"
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.Uname = this.apiShared.uname;
    this.OthersEditForm = this.formBuilder.group({




      FOLDERNO: [this.orderno],
      GUIDItinerary: [''],

      SUPPNAME: [''],
      CODE: [Validators.required],
      OthersType: [''],

      ProductDescription: [''],
      BOOKEDBY: this.Uname,
      BookingDate: [new Date()],
      CURID: [this.homeCurrency],
      CURExRate: [this.hcExRate],
      AMTFC: [0],

      RATE: [0],
      QTY: [1],
      DefaultDepositPer: [0],
      DefaultDepositAmt: [0],
      STATUS: ['Confirmed'],
      CONFIRMATIONNO: [''],

      LOYALTYPOINTSMEMBNO: [''],
      LOYALTYPOINTS: [0],
      LOYALTYPOINTSAMT: [0],
      CommOn: ['Purchase'],
      PrintonInvoice: [1],


      DESCRIPTION: [''],
      UNIT: [''],


      DISCOUNT: [0],
      AMOUNT: [0],
      VATCODE: [0],
      TOTAL: [0],
      SUPPCODE: ['', Validators.required],

      VATPER: [0],

      PROJNO: [this.projNo],
      ITYPE: ['Others'],
      TYPE: ['Others'],
      GDS: [''],
      PNR: [''],

      PAYMODE: [''],

      FCBuy: [0],
      FCCommPer: [0],
      FCCommAmt: [0],
      FCSell: [0],
      HCCommPer: [0],
      HCCommAmt: [0],
      HCSell: [0],
      ItnryNotes: [''],
      LowFB: [''],
      LowFare: [0],
      HighFB: [''],
      HighFare: [0],
      Fareoffered: [0],
      PotentialSaving: [0],
      ActualSaving: [0],
      PDNO: [0],
      PDTYPENO: [0],
      Forgone: [0],
      AddRemarks: [''],
      PDAMOUNT: [0],

      PONO: [0],
      PONOYEARID: [''],
      PAYMENTID: [0],
      PMTID: [''],
      DEFDEPPER: [0],
      DEFDEP: [0],
      DepositDueDate: [''],
      BalanceDueDate: [''],
      BalanceDueAmount: [0],

      TOMSVATTAXNO: [0],
      TOMSVATAMT: [0],
      PDUNIQUEREF: [''],

      PDFCURID: [this.homeCurrency],
      PDEXRATE: [this.hcExRate],
      PDFCAMT: [0],
      SELLTAXNO: [0],
      SELLTAXRATE: [0],
      FC_SELLTAXAMT: [0],
      HC_SELLTAXAMT: [0],

      TaxExcludingSalesTax: [0],
      BaseFare: [0],
      PONOCCDeferredPosted: [0],
      GSTHSTOnComm: 0,

      Markup: [0],
      CostofSale: [0],
      CostingSupplierGrossValue: [0],
      CostingSupplierServiceFee: [0],
      CostingTaxNo: [0],
      CostingTaxRate: [0],
      CostingSupplierTaxAmount: [0],
      CostingSupplierNetBuyPrice: [0],
      CostingCustomerTaxAmount: [0],
      CostingCustomerNetSellPrice: [0],
      CostingCustomerSalesValue: [0],
      CostingCustomerServiceFee: [0],
      CostingVATType: ['NV'],
      CostingSuppComm: [0],
      BSPPurchaseTax: [0],
      BSPPurchaseTaxRate: [0],

      SELLVAT: [0],

      SupplierDeposit: [0],
      CountryID: [""]

    });

    if (this.guid != null)
      this.getOthersDetails(this.orderno, this.guid);
    else {
      this.FormLoyalityPointsChange();
      this.loaded = true;
    }
    this.subscription.add(this.lookupApi.GetAllLookup('CURRENCY').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.Currency = data["ResponseData"]
          if (this.OthersEditForm.controls.CURID.value != "") {
            this.CurrencyselectedItem = this.Currency.find(item => item.Code == this.OthersEditForm.controls.CURID.value);
          }
        }
      }
    ));
    this.loadProduct();
    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.BookedBy = data["ResponseData"]
          if (this.OthersEditForm.controls.BOOKEDBY.value != "") {
            this.BookedByselectedItem = this.BookedBy.find(item => item.Name == this.OthersEditForm.controls.BOOKEDBY.value);
          }
        }
      }
    ));




  }

  // convenience getter for easy access to form fields
  get f() { return this.OthersEditForm.controls; }

  public loadProduct() {
    this.subscription.add(this.lookupApi.GetAllLookup('PRODUCTS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.ProductList = data["ResponseData"]
          if (this.OthersEditForm.controls.CODE.value != "") {
            this.ProductSelectedItem = this.ProductList.find(item => item.Code == this.OthersEditForm.controls.CODE.value);
          }
        }
      }));
  }

  public open() {

    this.opened = true;
  }
  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
    this.openSearch = false;
  }
  public CurrencyExRatechange(): void {
    debugger;
    this.CalculateForm();
  }
  getOthersDetails(id, guid) {

    this.spinner.show();
    this.subscription.add(this.api.getOthers(id, guid, false)
      .subscribe(data => {
        debugger;
        if (data != null && data != undefined) {
          if (data.ResponseData.PrintonInvoice == 1) {
            this.enableCheck = true;

          }
          else {
            this.enableCheck = false;
          }
          if (data.ResponseData.OthersType == "Package") {
            this.isDefault = false;
            this.enablePackage = true;
          }
          else if (data.ResponseData.OthersType == "Default") {
            this.isDefault = true;
          }
          else {
            this.isDefault = false;
            this.enablePackage = false;
          }



          //console.log('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));

          this.OthersEditForm.setValue({

            FOLDERNO: data.ResponseData.FOLDERNO,
            GUIDItinerary: data.ResponseData.GUIDItinerary,

            SUPPNAME: data.ResponseData.SUPPNAME,
            CODE: data.ResponseData.CODE,
            OthersType: data.ResponseData.OthersType,
            ProductDescription: data.ResponseData.ProductDescription,
            BOOKEDBY: data.ResponseData.BOOKEDBY,
            BookingDate: data.ResponseData.BookingDate != null ? (data.ResponseData.BookingDate.toString() === '1900-01-01T00:00:00' ? null : new Date(data.ResponseData.BookingDate)) : null,
            CURID: data.ResponseData.CURID,
            CURExRate: data.ResponseData.CURExRate,
            AMTFC: data.ResponseData.AMTFC,
            RATE: data.ResponseData.RATE,
            QTY: data.ResponseData.QTY,
            DefaultDepositPer: data.ResponseData.DefaultDepositPer,
            DefaultDepositAmt: data.ResponseData.DefaultDepositAmt,
            STATUS: data.ResponseData.STATUS,
            CONFIRMATIONNO: data.ResponseData.CONFIRMATIONNO,
            LOYALTYPOINTSMEMBNO: data.ResponseData.LOYALTYPOINTSMEMBNO,
            LOYALTYPOINTS: data.ResponseData.LOYALTYPOINTS,
            LOYALTYPOINTSAMT: data.ResponseData.LOYALTYPOINTSAMT,
            CommOn: data.ResponseData.CommOn,
            PrintonInvoice: data.ResponseData.PrintonInvoice,
            DESCRIPTION: data.ResponseData.DESCRIPTION,
            UNIT: data.ResponseData.UNIT,
            DISCOUNT: data.ResponseData.DISCOUNT,
            AMOUNT: data.ResponseData.AMOUNT,
            VATCODE: data.ResponseData.VATCODE,
            TOTAL: data.ResponseData.TOTAL,
            SUPPCODE: data.ResponseData.SUPPCODE,
            VATPER: data.ResponseData.VATPER,
            PROJNO: data.ResponseData.PROJNO,
            ITYPE: "Others",
            GDS: data.ResponseData.GDS,
            PNR: data.ResponseData.PNR,
            PAYMODE: data.ResponseData.PAYMODE,
            FCBuy: data.ResponseData.FCBuy,
            FCCommPer: data.ResponseData.FCCommPer,
            FCCommAmt: data.ResponseData.FCCommAmt,
            FCSell: data.ResponseData.FCSell,
            HCCommPer: data.ResponseData.HCCommPer,
            HCCommAmt: data.ResponseData.HCCommAmt,
            HCSell: data.ResponseData.HCSell,
            ItnryNotes: data.ResponseData.ItnryNotes,
            LowFB: data.ResponseData.LowFB,
            LowFare: data.ResponseData.LowFare,
            HighFB: data.ResponseData.HighFB,
            HighFare: data.ResponseData.HighFare,
            Fareoffered: data.ResponseData.Fareoffered,
            PotentialSaving: data.ResponseData.PotentialSaving,
            ActualSaving: data.ResponseData.ActualSaving,
            PDNO: data.ResponseData.PDNO,
            PDTYPENO: data.ResponseData.PDTYPENO,
            Forgone: data.ResponseData.Forgone,
            AddRemarks: data.ResponseData.AddRemarks,
            PDAMOUNT: data.ResponseData.PDAMOUNT,
            PONO: data.ResponseData.PONO,
            PONOYEARID: data.ResponseData.PONOYEARID,
            PAYMENTID: data.ResponseData.PAYMENTID,
            PMTID: data.ResponseData.PMTID,
            DEFDEPPER: data.ResponseData.DEFDEPPER,
            DEFDEP: data.ResponseData.DEFDEP,
            DepositDueDate: data.ResponseData.DepositDueDate != null ? (data.ResponseData.DepositDueDate.toString() === '1900-01-01T00:00:00' ? null : new Date(data.ResponseData.DepositDueDate)) : null,
            BalanceDueDate: data.ResponseData.BalanceDueDate != null ? (data.ResponseData.BalanceDueDate.toString() === '1900-01-01T00:00:00' ? null : new Date(data.ResponseData.BalanceDueDate)) : null,
            BalanceDueAmount: data.ResponseData.BalanceDueAmount,
            TOMSVATTAXNO: data.ResponseData.TOMSVATTAXNO,
            TOMSVATAMT: data.ResponseData.TOMSVATAMT,
            PDUNIQUEREF: data.ResponseData.PDUNIQUEREF,
            PDFCURID: data.ResponseData.PDFCURID,
            PDEXRATE: data.ResponseData.PDEXRATE,
            PDFCAMT: data.ResponseData.PDFCAMT,
            SELLTAXNO: data.ResponseData.SELLTAXNO,
            SELLTAXRATE: data.ResponseData.SELLTAXRATE,
            FC_SELLTAXAMT: data.ResponseData.FC_SELLTAXAMT,
            HC_SELLTAXAMT: data.ResponseData.HC_SELLTAXAMT,
            TaxExcludingSalesTax: data.ResponseData.TaxExcludingSalesTax,
            BaseFare: data.ResponseData.BaseFare,
            PONOCCDeferredPosted: data.ResponseData.PONOCCDeferredPosted,
            TYPE: 'Others',
            GSTHSTOnComm: data.ResponseData.GSTHSTOnComm,
            Markup: data.ResponseData.CostingCustomerServiceFee,
            CostofSale: data.ResponseData.CostingCustomerSalesValue,
            CostingSupplierGrossValue: data.ResponseData.CostingSupplierGrossValue,
            CostingCustomerSalesValue: data.ResponseData.CostingCustomerSalesValue,
            CostingCustomerServiceFee: data.ResponseData.CostingCustomerServiceFee,
            CostingCustomerNetSellPrice: data.ResponseData.CostingCustomerNetSellPrice,
            CostingVATType: data.ResponseData.CostingVATType,
            CostingSupplierServiceFee: data.ResponseData.CostingSupplierServiceFee,
            CostingTaxNo: data.ResponseData.CostingTaxNo,
            CostingTaxRate: data.ResponseData.CostingTaxRate,
            CostingSupplierTaxAmount: data.ResponseData.CostingSupplierTaxAmount,
            CostingSupplierNetBuyPrice: data.ResponseData.CostingSupplierNetBuyPrice,
            CostingCustomerTaxAmount: data.ResponseData.CostingCustomerTaxAmount,
            CostingSuppComm: data.ResponseData.CostingSuppComm,

            BSPPurchaseTax: [0],
            BSPPurchaseTaxRate: [0],
            SELLVAT: [0],
            SupplierDeposit: data.ResponseData.SupplierDeposit,
            CountryID: ['']
          }); debugger;
          this.FC_Tot_BuyInitalvalue = this.OthersEditForm.controls.FCBuy.value
          this.FCSell = this.OthersEditForm.controls.FCSell.value;
          this.PONO = this.OthersEditForm.controls.PONO.value;

          if (this.PONO > 0) {

            this.pocreated = false;
          }
          else {
            this.pocreated = true;
          }
          this.spinner.hide();
          this.loaded = true;
          this.FormLoyalityPointsChange();
          this.loadsuppliermaster();
          this.loadProduct();
        }
        else {
          this.spinner.hide();
        }
      }));
  }

  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }
  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860

  onFormSubmit() {
    debugger;
    this.spinner.show();
    if (this.OthersEditForm.controls.PAYMODE.value == "Company Card")//By case id-355
    {
      if ((this.OthersEditForm.controls.PDAMOUNT.value == 0) || (this.OthersEditForm.controls.PDNO.value == "0") || (this.OthersEditForm.controls.PDTYPENO.value == "0")) {
        this.subscription.add(this.translateapi.get(['Messages.PleaseSelectCompanyCardWithPaymentOption']).subscribe((translations) => {

          this.showMsg = translations['Messages.PleaseSelectCompanyCardWithPaymentOption'];;
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        this.spinner.hide();
        return;
      }
    }
    this.bookingDate = (this.OthersEditForm.controls.BookingDate.value == null || this.OthersEditForm.controls.BookingDate.value == "" ? "01-Jan-1900" : this.OthersEditForm.controls.BookingDate.value);//Case id-337
    this.depositDueDate = (this.OthersEditForm.controls.DepositDueDate.value == null || this.OthersEditForm.controls.DepositDueDate.value == "" ? "01-Jan-1900" : this.OthersEditForm.controls.DepositDueDate.value);//Case id-337

    if (Boolean(this.common.ValidDateRange(this.bookingDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidBookingDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidBookingDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }

    if (Boolean(this.common.ValidDateRange(this.depositDueDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDepositDueDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDepositDueDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }


    if (this.OthersEditForm.controls.CURID.value == undefined && this.OthersEditForm.controls.CURID.value == null || this.OthersEditForm.controls.CURID.value == '') {
      // this.OthersEditForm.controls.CURCODE.setValue( this.homeCurrency);
      this.OthersEditForm.controls.CURID.setValue(this.homeCurrency)
    }
    if (this.OthersEditForm.controls.CostingVATType.value == 'VSF') {
      this.OthersEditForm.controls.SELLVAT.setValue((this.OthersEditForm.controls.CostingCustomerTaxAmount.value));
      this.OthersEditForm.controls.BSPPurchaseTax.setValue(parseFloat((this.OthersEditForm.controls.CostingSupplierTaxAmount.value).toFixed(2)));
      this.OthersEditForm.controls.BSPPurchaseTaxRate.setValue(this.OthersEditForm.controls.CostingTaxRate.value);
      this.OthersEditForm.controls.FCBuy.setValue(parseFloat((this.OthersEditForm.controls.CostingSupplierGrossValue.value + this.OthersEditForm.controls.CostingSupplierServiceFee.value).toFixed(2)));
      this.OthersEditForm.controls.FCSell.setValue(parseFloat((this.OthersEditForm.controls.CostofSale.value + this.OthersEditForm.controls.Markup.value).toFixed(2)));
      // this.OthersEditForm.controls.GSTHSTOnComm.setValue( 0);
      this.OthersEditForm.controls.CostingSuppComm.setValue(0);
      //   this.OthersEditForm.controls.SELLTOTAL.setValue(this.OthersEditForm.controls.CostingCustomerNetSellPrice.value);
    }

    else if (this.OthersEditForm.controls.CostingVATType.value == 'VP') {
      this.OthersEditForm.controls.SELLVAT.setValue((this.OthersEditForm.controls.CostingCustomerTaxAmount.value));
      this.OthersEditForm.controls.BSPPurchaseTax.setValue(parseFloat((this.OthersEditForm.controls.CostingSupplierTaxAmount.value).toFixed(2)));
      this.OthersEditForm.controls.BSPPurchaseTaxRate.setValue((this.OthersEditForm.controls.CostingTaxRate.value));
      this.OthersEditForm.controls.FCBuy.setValue(parseFloat((this.OthersEditForm.controls.CostingSupplierGrossValue.value).toFixed(2)));
      this.OthersEditForm.controls.FCSell.setValue(parseFloat((this.OthersEditForm.controls.CostofSale.value + this.OthersEditForm.controls.Markup.value).toFixed(2)));
      // this.OthersEditForm.controls.GSTHSTOnComm.setValue( 0);
      this.OthersEditForm.controls.CostingSuppComm.setValue(0);
      this.OthersEditForm.controls.CostingSupplierServiceFee.setValue(0);
      // this.OthersEditForm.controls.SELLTOTAL.setValue(this.OthersEditForm.controls.CostingCustomerNetSellPrice.value);
    }
    else if (this.OthersEditForm.controls.CostingVATType.value == 'VSC') {
      this.OthersEditForm.controls.SELLVAT.setValue(0);
      this.OthersEditForm.controls.BSPPurchaseTax.setValue(0);
      this.OthersEditForm.controls.BSPPurchaseTaxRate.setValue(this.OthersEditForm.controls.CostingTaxRate.value);
      this.OthersEditForm.controls.FCBuy.setValue(parseFloat((this.OthersEditForm.controls.CostofSale.value).toFixed(2)));
      this.OthersEditForm.controls.FCSell.setValue(parseFloat((this.OthersEditForm.controls.CostingCustomerNetSellPrice.value).toFixed(2)));
      this.OthersEditForm.controls.CostingCustomerTaxAmount.setValue(0);

      this.OthersEditForm.controls.CostingSupplierNetBuyPrice.setValue(0);
      this.OthersEditForm.controls.CostingSupplierTaxAmount.setValue(0);
      this.OthersEditForm.controls.CostingSupplierServiceFee.setValue(0);
      //14150
      // this.OthersEditForm.controls.PAYABLE.setValue(this.OthersEditForm.controls.CostingSupplierGrossValue.value - this.OthersEditForm.controls.CostingSuppComm.value);
      this.OthersEditForm.controls.HCCommAmt.setValue(this.OthersEditForm.controls.Markup.value);
      this.OthersEditForm.controls.BalanceDueAmount.setValue(this.OthersEditForm.controls.CostingSupplierGrossValue.value - this.OthersEditForm.controls.CostingSuppComm.value);
      //14150



    }
    else {
      this.OthersEditForm.controls.SELLVAT.setValue(0);
      this.OthersEditForm.controls.BSPPurchaseTax.setValue(0);
      this.OthersEditForm.controls.BSPPurchaseTaxRate.setValue(0);
      //   this.OthersEditForm.controls.GSTHSTOnComm.setValue( 0);
      this.OthersEditForm.controls.CostingSuppComm.setValue(0);
      this.OthersEditForm.controls.CostingCustomerTaxAmount.setValue(0);
      this.OthersEditForm.controls.CostingSupplierNetBuyPrice.setValue(0);
      this.OthersEditForm.controls.CostingSupplierTaxAmount.setValue(0);
      this.OthersEditForm.controls.CostingSupplierServiceFee.setValue(0);
    }
    this.OthersEditForm.controls.FC_SELLTAXAMT.setValue(this.OthersEditForm.controls.FC_SELLTAXAMT.value);
    this.buyExRate = (this.OthersEditForm.get('CURExRate').value == "" || this.OthersEditForm.get('CURExRate').value == 0) ? 1 : this.OthersEditForm.get('CURExRate').value;
    if (this.OthersEditForm.controls.AMTFC.value != null || this.OthersEditForm.controls.AMTFC.value != undefined)
      this.OthersEditForm.controls.RATE.setValue((this.OthersEditForm.controls.AMTFC.value * this.buyExRate));
    if (this.OthersEditForm.controls.BSPPurchaseTax.value.length > 0) {
      this.OthersEditForm.controls.BSPPurchaseTax.setValue(0);
    }
    if (this.OthersEditForm.controls.BSPPurchaseTaxRate.value.length > 0) {
      this.OthersEditForm.controls.BSPPurchaseTaxRate.setValue(0);
    }
    if (this.OthersEditForm.controls.SELLVAT.value.length > 0) {
      this.OthersEditForm.controls.SELLVAT.setValue(0);
    }
    if (this.OthersEditForm.value['CURExRate'] === '' || this.OthersEditForm.value['CURExRate'] === 0 || this.OthersEditForm.value['CURExRate'] === null) {
      this.subscription.add(this.translateapi.get(['Messages.ExchangeRateError']).subscribe((translations) => {
        this.showMsg = translations['Messages.ExchangeRateError'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.spinner.hide();
      return;
    }
    this.submitted = true;

    this.OthersEditForm.controls.DESCRIPTION.setValue(this.OthersEditForm.controls.DESCRIPTION.value);

    if (this.OthersEditForm.invalid) {
      this.common.submitted = true;
      this.spinner.hide();
      return;
    }
    else {
      this.common.submitted = false;
    }
    this.objFolderPrice
      .CreditLimitCheck(this.guid == null || undefined ? "0" : this.guid, 0, this.orderno, this.OthersEditForm.get("HCSell").value)
      .subscribe(res => {
        if (res != undefined || res["ResponseData"] != null) {
          if (
            res["ResponseData"].value != "0" &&
            res["ResponseData"].Status != "0" &&
            res["ResponseData"].ErrorMessage != ""
          ) {
            debugger;
            if (res["ResponseData"].value == 1) {
              this.msg = res["ResponseData"].ErrorMessage;
              this.spinner.hide();
              this.creditLimiFlag = true;
              this.creditLimitMsg = true;
              this.spinner.hide();
              return false;
            } else if (res["ResponseData"].value == 2) {
              this.spinner.hide();
              this.msg = res["ResponseData"].ErrorMessage;
              this.creditLimiFlag = false;
              this.creditLimitMsg = true;
              // this.AirTicketSaveAndUpdate();
            } else {
              this.OthersDetailsSaveandUpdate();
            }
          } else {
            this.OthersDetailsSaveandUpdate();
          }
        }
      });





  }
  OthersDetailsSaveandUpdate() {

    this.OthersEditForm.controls.RATE.setValue((this.OthersEditForm.controls.AMOUNT.value / this.OthersEditForm.controls.QTY.value));//13157

    if (this.guid != null && this.PONO > 0) {
      this.prdtUpdatePODTO = new ProductUpdatePODTO();
      this.prdtUpdatePODTO.FOLDERNO = this.orderno;
      this.prdtUpdatePODTO.GUIDItinerary = this.OthersEditForm.get('GUIDItinerary').value;
      this.prdtUpdatePODTO.LOYALTYPOINTS = this.OthersEditForm.get('LOYALTYPOINTS').value;
      this.prdtUpdatePODTO.LOYALTYPOINTSAMT = this.OthersEditForm.get('LOYALTYPOINTSAMT').value
      //this.prdtUpdatePODTO.LOYALTYPOINTSBAL=this.OthersEditForm.get('LOYALTYPOINTSBAL').value
      this.prdtUpdatePODTO.LOYALTYPOINTSMEMBNO = this.OthersEditForm.get('LOYALTYPOINTSMEMBNO').value;
      this.prdtUpdatePODTO.TYPE = "Others";
      this.prdtUpdatePODTO.BaseFare = this.OthersEditForm.get('FCSell').value;
      this.prdtUpdatePODTO.SELLAMT = this.OthersEditForm.get('HCSell').value;
      this.prdtUpdatePODTO.SELLCOMM = this.OthersEditForm.get('HCCommAmt').value;
      this.prdtUpdatePODTO.FC_SELLTAXAMT = this.OthersEditForm.get('FC_SELLTAXAMT').value;
      this.prdtUpdatePODTO.HC_SELLTAXAMT = this.OthersEditForm.get('HC_SELLTAXAMT').value;
      //this.prdtUpdatePODTO.HCSellTaxAmt=this.OthersEditForm.get('HCSellTaxAmt').value;
      this.prdtUpdatePODTO.HCSell = this.OthersEditForm.get('HCSell').value;
      this.prdtUpdatePODTO.HCCommAmt = this.OthersEditForm.get('HCCommAmt').value;
      this.prdtUpdatePODTO.CostingCustomerTaxAmount = this.OthersEditForm.get('CostingCustomerTaxAmount').value;
      this.prdtUpdatePODTO.CostingCustomerNetSellPrice = this.OthersEditForm.get('CostingCustomerNetSellPrice').value;
      this.prdtUpdatePODTO.CostingCustomerServiceFee = this.OthersEditForm.get('CostingCustomerServiceFee').value;
      this.prdtUpdatePODTO.SELLVAT = this.OthersEditForm.get('SELLVAT').value;
      this.prdtUpdatePODTO.SELLTAXNO = this.OthersEditForm.get('SELLTAXNO').value;
      this.prdtUpdatePODTO.SELLTAXRATE = this.OthersEditForm.get('SELLTAXRATE').value;

      this.prdtUpdatePODTO.PAYABLE = this.OthersEditForm.get('AMOUNT').value;
      this.prdtUpdatePODTO.FC_CIDExRate = this.OthersEditForm.get('CURExRate').value;
      //this.apiPO.formData=
      if (this.OthersEditForm.controls.CostingVATType.value == "VSC") {
        //this.OthersEditForm.controls.PAYABLE.setValue(this.OthersEditForm.controls.CostingSupplierGrossValue.value - this.OthersEditForm.controls.CostingSuppComm.value);
        this.OthersEditForm.controls.HCCommAmt.setValue(this.OthersEditForm.controls.Markup.value);
        this.OthersEditForm.controls.BalanceDueAmount.setValue(this.OthersEditForm.controls.CostingSupplierGrossValue.value - this.OthersEditForm.controls.CostingSuppComm.value);
      }
      this.apiPO.formData = this.prdtUpdatePODTO;
      this.subscription.add(this.apiPO.SaveProductAfterPO()
        .subscribe(res => {
          this.spinner.hide();
          if (res.IsSuccess === true && res.Message === 'Success') {
            this.spinner.hide();
            this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
              this.showMsg = translations['Messages.saved_success'];
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }));
            this.router.navigate(['/PenAir/Product/OthersDetails', this.orderno, this.guid], { skipLocationChange: true });
          } else {
            this.spinner.hide();
            this.toastr.warning(res.ResponseData.ErrorMessage, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          }
        }, (err) => {
          this.spinner.hide();
          console.log(err);
          this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
            this.showMsg = translations['Messages.some_error_in_save'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
        }
        ));

    }


    else {
      if (this.OthersEditForm.controls.CostingVATType.value == "VSC") {
        //this.OthersEditForm.controls.PAYABLE.setValue(this.OthersEditForm.controls.CostingSupplierGrossValue.value - this.OthersEditForm.controls.CostingSuppComm.value);
        this.OthersEditForm.controls.HCCommAmt.setValue(this.OthersEditForm.controls.Markup.value);
        this.OthersEditForm.controls.BalanceDueAmount.setValue(this.OthersEditForm.controls.CostingSupplierGrossValue.value - this.OthersEditForm.controls.CostingSuppComm.value);
      }
      this.OthersEditForm.controls.BaseFare.setValue( this.OthersEditForm.get('FCSell').value);
      if (this.guid != null) {
        this.spinner.show();
        if (this.CompanyCardValidation() == true) {
          this.subscription.add(this.api.updateOthers(this.OthersEditForm.value)
            .subscribe(res => {
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  this.spinner.hide();
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));
                  this.router.navigate(['PenAir/Product/OthersDetails/', this.orderno, this.guid + '/'], { skipLocationChange: true });
                  //this.router.navigate(['/OthersDetails','12977','Others.20190826.103628.{B9CB75FE-A3D6-46DE-926E-27990C46ECEC}/'],{skipLocationChange:true});
                } else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
            }, (err) => {
              this.spinner.hide();
              console.log(err);
              this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                this.showMsg = translations['Messages.some_error_in_save'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
            }
            ));
        }
        else {
          this.spinner.hide();
        }
      }
      else {
        this.spinner.show();
        if (this.CompanyCardValidation() == true) {
          //alert(this.OthersEditForm.PrintonInvoice);
          this.subscription.add(this.api.addOthers(this.OthersEditForm.value)
            .subscribe(res => {
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  this.spinner.hide();
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));
                  //this.router.navigate(['/PenAir/Folder/Products',this.orderno],{skipLocationChange:true});
                  this.common.isfinalsaved = false;
                  sessionStorage.setItem('pagevalue', '0');
                  var folderQuotes = sessionStorage.getItem('folderQuotes');
                  if (folderQuotes) {
                    this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno], { skipLocationChange: true });
                  } else {
                    this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno], { skipLocationChange: true });
                  }
                } else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
            }, (err) => {
              this.spinner.hide();
              console.log(err);
              this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                this.showMsg = translations['Messages.some_error_in_save'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
            }
            ));
        }
        else {
          this.spinner.hide();
        }
      }
    }
  }
  onCancel() {
    if (this.guid != "" && this.guid != null)
      this.router.navigate(['PenAir/Product/OthersDetails/', this.orderno, this.guid + '/'], { skipLocationChange: true });

    else {
      this.common.isfinalsaved = false;
      sessionStorage.setItem('pagevalue', '0');
      var folderQuotes = sessionStorage.getItem('folderQuotes');
      if (folderQuotes) {
        this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno], { skipLocationChange: true });
      } else {
        this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno], { skipLocationChange: true });
      }
    }
    //this.router.navigate(['/PenAir/Folder/Products',this.orderno],{skipLocationChange:true});
  }
  onReset() {
    this.submitted = false;
    this.OthersEditForm.reset();
  }
  loadsuppliermaster() {
    if (this.OthersEditForm.controls.SUPPCODE.value != '' && this.OthersEditForm.controls.SUPPCODE.value != null) {
      this.subscription.add(this.lookupApi.findSupplier(this.OthersEditForm.controls.SUPPCODE.value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
            if (this.OthersEditForm.controls.SUPPCODE.value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.OthersEditForm.controls.SUPPCODE.value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
                this.OthersEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID);
              }
            }
          }
        }
      ));
    }
  }
  handleFiltersupplier(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  getFolderValues() {

    // this.apiShared.folderCurrency.subscribe(fldrCurrency => this.fldrCurrency = fldrCurrency)
    // this.apiShared.folderExchangeRate.subscribe(folderExrate => this.folderExrate = folderExrate)
    // this.exRate=new Number(this.folderExrate);
    // this.apiShared.projNo.subscribe(projNo => this.projNo = projNo)
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    this.exRate = new Number(this.folderExrate);
    this.projNo = this.apiShared.projNo;
  }
  private buyExRate: number;
  private fCBuy: number;
  private units: number;
  private amount: number;
  private balanceDueAmount: number;
  private defaultDepositPer: number;
  CalculateForm() {

    this.units = (this.OthersEditForm.get('QTY') == null || this.OthersEditForm.get('QTY').value == 0) ? 1 : this.OthersEditForm.get('QTY').value;

    this.buyExRate = (this.OthersEditForm.get('CURExRate').value == "" || this.OthersEditForm.get('CURExRate').value == 0) ? 1 : this.OthersEditForm.get('CURExRate').value;
    if ((this.OthersEditForm.get('AMTFC') != null) && this.OthersEditForm.get('AMTFC').value != 0 && this.OthersEditForm.get('AMTFC').value != "undefined") {

      // this.fCBuy = this.OthersEditForm.controls.AMTFC.value * this.units;
      this.fCBuy = this.OthersEditForm.controls.FCBuy.value;
      // this.OthersEditForm.controls.FCBuy.setValue(parseFloat(this.fCBuy.toFixed(2)));
      this.OthersEditForm.controls.AMOUNT.setValue((this.fCBuy * this.buyExRate));
      this.OthersEditForm.controls.RATE.setValue((this.OthersEditForm.controls.AMTFC.value * this.buyExRate));
    }
    else {
      this.OthersEditForm.controls.AMOUNT.setValue(this.OthersEditForm.get('FCBuy').value * this.buyExRate);
      this.OthersEditForm.controls.RATE.setValue(0);
    }
    this.OthersEditForm.controls.HCSell.setValue(this.OthersEditForm.get('FCSell').value * this.exRate);
    this.OthersEditForm.controls.HCCommAmt.setValue(this.OthersEditForm.get('HCSell').value - this.OthersEditForm.get('AMOUNT').value);

    ///Payment Terms    
    this.amount = (this.OthersEditForm.get('DEFDEP').value == "" || this.OthersEditForm.get('DEFDEP').value == 0) ? 1 : this.OthersEditForm.get('DEFDEP').value;
    this.defaultDepositPer = (this.OthersEditForm.get('DEFDEPPER').value == "" || this.OthersEditForm.get('DEFDEPPER').value == 0) ? 1 : this.OthersEditForm.get('DEFDEPPER').value;

    if (this.amount != 0 && this.amount != 0.00)
      this.amount = (this.fCBuy * this.defaultDepositPer) / 100;
    this.balanceDueAmount = this.fCBuy - this.amount
    this.OthersEditForm.controls.DEFDEP.setValue(this.amount);
    this.OthersEditForm.controls.BalanceDueAmount.setValue(this.balanceDueAmount);
    //Corperate Info
    this.OthersEditForm.controls.ActualSaving.setValue(this.OthersEditForm.get('AMOUNT').value * -1);

  }
  onChanges(): void {

  }
  checkboxChange() {
    this.enableCheck == true;
    if (this.enableCheck == true) {
      this.OthersEditForm.controls.PrintonInvoice.setValue(1);

    }
    else {
      this.OthersEditForm.controls.PrintonInvoice.setValue(0);
    }
    if (this.enablePackage == true) {
      this.OthersEditForm.controls.OthersType.setValue("Package");

    }
    else {
      this.OthersEditForm.controls.OthersType.setValue("");
    }
  }
  public getCurrencyExRate() {

    this.bookingDate = (this.OthersEditForm.controls.BookingDate.value == null || this.OthersEditForm.controls.BookingDate.value == "" ? "01-Jan-1900" : this.OthersEditForm.controls.BookingDate.value);//Case id-337


    if (Boolean(this.common.ValidDateRange(this.bookingDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidBookingDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidBookingDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (this.OthersEditForm.controls.CURID.value != '' && this.OthersEditForm.controls.CURID.value != undefined)
      this.getExchangeRate(this.OthersEditForm.controls.CURID.value, this.bookingDate)

  }
  getExchangeRate(currency, bookingDate) {
    this.savebutton = false;
    // bookingDate= new Date(bookingDate).toLocaleDateString('en-GB', {
    //   day: 'numeric', month: 'short', year: 'numeric'
    // }).replace(/ /g, '-');

    bookingDate = this.common.ConvertToLocaleDate(new Date(bookingDate));

    this.subscription.add(this.apiExRate.getExchangeRate(currency, bookingDate)
      .subscribe(data => {
        this.currencyExrate = data;

        this.OthersEditForm.controls.CURExRate.setValue(this.currencyExrate.ResponseData.EXRATE);
        this.savebutton = true;
        //  this.CalculateForm();
      }));


  }

  public CurrencyChange(value: any): void {
    console.log('selectionChange', value);
    if (value != null && value != undefined)
      this.OthersEditForm.controls.CURID.setValue(value.Code);
    else
      this.OthersEditForm.controls.CURID.setValue("");

    this.common.buycurrency = this.OthersEditForm.controls.CURID.value;
    this.Checkcurrency();
  }
  Checkcurrency() {
    this.fldrCurrency = this.apiShared.folderCurrency;
    if (this.OthersEditForm.controls.CURID.value == this.fldrCurrency) {
      this.common.disablecurrency = false;
    }
    else {
      this.OthersEditForm.controls.Markup.setValue(0);
      this.common.disablecurrency = true;
      var value = this.OthersEditForm.controls.CostingVATType.value;
      if (value == 'NV') {

        this.OthersEditForm.controls.CostofSale.setValue(0);
        this.OthersEditForm.controls.Markup.setValue(0);
        this.OthersEditForm.controls.FCSell.setValue(0);


      } else if (value == 'VSF') {
        this.OthersEditForm.controls.CostofSale.setValue(0);
        this.OthersEditForm.controls.Markup.setValue(0);
        this.OthersEditForm.controls.CostingCustomerTaxAmount.setValue(0);
        this.OthersEditForm.controls.CostingCustomerNetSellPrice.setValue(0);


      } if (value == 'VP') {
        this.OthersEditForm.controls.CostofSale.setValue(0);
        this.OthersEditForm.controls.Markup.setValue(0);
        this.OthersEditForm.controls.CostingCustomerTaxAmount.setValue(0);
        this.OthersEditForm.controls.CostingCustomerNetSellPrice.setValue(0);




      } if (value == 'VSC') {
        this.OthersEditForm.controls.CostofSale.setValue(0);
        this.OthersEditForm.controls.Markup.setValue(0);

        this.OthersEditForm.controls.CostingCustomerNetSellPrice.setValue(0);

      }
    }
    //  if(this.OthersEditForm.controls.FCBuy.value==undefined ||this.OthersEditForm.controls.FCBuy.value==null||this.OthersEditForm.controls.FCBuy.value=='')
    //   {
    //     this.OthersEditForm.controls.FCBuy.setValue(0);
    //   }
    //   else{
    //     this.OthersEditForm.controls.CostofSale.setValue( this.OthersEditForm.controls.FCBuy.value);
    //   }
    //   this.OthersEditForm.controls.FCSell.setValue( this.OthersEditForm.controls.CostofSale.value +this.OthersEditForm.controls.Markup.value);

  }
  public BookedByChange(value: any): void {
    console.log('selectionChange', value);
    if (value != null && value != undefined)
      this.OthersEditForm.controls.BOOKEDBY.setValue(value.Name);
    else
      this.OthersEditForm.controls.BOOKEDBY.setValue("");
  }

  public ProductChange(value: any): void {
    if (value != null && value != undefined) {
      this.OthersEditForm.controls.CODE.setValue(value.Code);
      this.OthersEditForm.controls.DESCRIPTION.setValue(value.Name);//12618 by jubina
    }
    else {
      this.OthersEditForm.controls.CODE.setValue("");
      this.OthersEditForm.controls.DESCRIPTION.setValue("");
    }
  }
  SupplierDefaultvalue(suppcode) {
    this.subscription.add(this.supplier.GetDefaultValue(suppcode)
      .subscribe(data => {
        if (data !== '' && data !== null && data !== undefined && data.CURRENCYCODE != '') {
          // this.OthersEditForm.controls.CURID.setValue(data.CURRENCYCODE);
          this.OthersEditForm.controls.PAYMENTID.setValue(data.PAYTERMID);
          this.OthersEditForm.controls.PMTID.setValue(data.PAYMTDID);
          this.resetChildForm();

          // if(this.OthersEditForm.controls.CURID.value!=""&&this.OthersEditForm.controls.CURID.value!=null)
          // {   
          //   this.CurrencyselectedItem =this.Currency.find(item => item.Code ===this.OthersEditForm.controls.CURID.value );     
          //   this.getCurrencyExRate();
          // }
        }
      }));
  }
  resetChildForm() {
    this.resetFormSubject.next(true);
  }
  changefn() {
    console.log(this.ucode);
    if (this.ucode == "")
      this.OthersEditForm.controls.SUPPNAME.setValue("");
    let toArray = this.ucode.split("-");

    if (toArray.length > 1)
      if (toArray[1].trim() != '')
        this.OthersEditForm.controls.SUPPNAME.setValue(toArray[1]);

  }

  public SupplierValueChange(value: any): void {
    //  alert(value);
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);

    }
    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860

      this.SupplierDefaultvalue(this.supplierSelectedItem.Code);
      this.OthersEditForm.controls.SUPPCODE.setValue(this.supplierSelectedItem.Code);
      this.OthersEditForm.controls.SUPPNAME.setValue(this.supplierSelectedItem.ActualName);
      this.apiShared.BALDUETO = this.supplierSelectedItem.BALDUETO;
      this.TaxLookup(this.supplierSelectedItem.CONTID)
      this.payterm.getBalanceDuedate();
    }
    else {
      this.supplierSelected = "";
      this.OthersEditForm.controls.SUPPCODE.setValue("");
      this.OthersEditForm.controls.SUPPNAME.setValue("");
    }

  }

  FormLoyalityPointsChange() {
    if (this.OthersEditForm.controls.LOYALTYPOINTSMEMBNO.value != "" && this.OthersEditForm.controls.LOYALTYPOINTSMEMBNO.value != null) {


      this.OthersEditForm.get('LOYALTYPOINTS').enable();
      this.OthersEditForm.get('LOYALTYPOINTSAMT').enable();
      // this.disableTextbox =  false;
    }
    else {
      this.OthersEditForm.controls.LOYALTYPOINTS.setValue(0);
      this.OthersEditForm.controls.LOYALTYPOINTSAMT.setValue(0);
      this.OthersEditForm.get('LOYALTYPOINTS').disable();
      this.OthersEditForm.get('LOYALTYPOINTSAMT').disable();

    }
  }

  public dattobechecked: any;
  public FormatDate(value: any): string {


    // this.dattobechecked=new Date(value).toLocaleDateString('en-GB', {
    //   day: 'numeric', month: 'short', year: 'numeric'
    // }).replace(/ /g, '-');
    this.dattobechecked = this.common.transformDate(new Date(value));

    if (this.dattobechecked == "1-Jan-1900")
      return "";
    else {
      return (this.dattobechecked);
    }
  }

  CompanyCardValidation() {
    if (this.OthersEditForm.get('PAYMODE').value == "Company Card") {
      if (this.OthersEditForm.get('PDNO').value != "" && this.OthersEditForm.get('PDNO').value != null && this.OthersEditForm.get('PDFCURID').value != "" && this.OthersEditForm.get('PDFCURID').value != null
        && this.OthersEditForm.get('PDFCAMT').value != "" && this.OthersEditForm.get('PDFCAMT').value != null && this.OthersEditForm.get('PDFCAMT').value != 0 && this.OthersEditForm.get('PDTYPENO').value != null
        && this.OthersEditForm.get('PDTYPENO').value != "" && this.OthersEditForm.get('PDAMOUNT').value != null && this.OthersEditForm.get('PDAMOUNT').value != null && this.OthersEditForm.get('PDAMOUNT').value != 0

        && this.OthersEditForm.get('PDFCAMT').value != null && this.OthersEditForm.get('PDFCAMT').value != null && this.OthersEditForm.get('PDFCAMT').value != 0
      ) {
        return true;
      }
      else {
        this.common.showFlash1('Messages.Companycard_validation', 'bg-danger text-white', 2000);

        return false;
      }
    }

    else {
      return true;
    }
  }

  public openProduct() {
    this.openproduct = true;
  }

  public closeProduct(status) {
    console.log(`Dialog result: ${status}`);
    this.openproduct = false;
  }
  isSticky: boolean = false;
  @HostListener('window:scroll')
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ConfirmMessage() {
    if (this.creditLimiFlag == false) {
      this.creditLimitMsg = false;
      this.OthersDetailsSaveandUpdate();
    } else {
      this.creditLimitMsg = false;
    }
  }

  TaxLookup(country) {
    this.fchcdetails.getInputTaxLookup(country)
  }
}