import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { LoginService } from '../../Services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-licence-renewal',
  templateUrl: './licence-renewal.component.html',
  styleUrls: ['./licence-renewal.component.scss']
})
export class LicenceRenewalComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  openLicensenKey = false;
  @Input() LicenseStatus: any;
  @Input() user: any;
  @Input() licenseUpdateOnly: boolean;
  @Output() licenseOut: EventEmitter<string> = new EventEmitter();
  LicenseDetails: any;
  expiryMsgHead = '';
  expiryMsg = '';
  LicenseKeyForm: FormGroup;
  loading = false;
  IsvalidForm = true;
  showMsg = '';
  showLicenseStatus = false;
  showError = false;
  renewSuccess = false;
  constructor(private login: LoginService, private translate: TranslateService, private spinner: NgxSpinnerService,
    private toastr: ToastrService) { }

  ngOnInit() {
    // debugger;
    if (!this.licenseUpdateOnly) {
      if (this.LicenseStatus.Message != '') {
        this.subscription.add(this.translate.get(['Messages.NoValidLicense']).subscribe((translations) => {
          this.expiryMsgHead = translations['Messages.NoValidLicense'];
        }));
      } else {
        if (this.LicenseStatus.LicenseExpDays < 0) {
          this.expiryMsgHead = 'Your Penguin license expired on ' + moment(this.LicenseStatus.LicenseExpDate).format("dddd, DD MMM YYYY") +
            '. Please renew the license.';
        } else {
          this.expiryMsgHead = 'Your license will expire on ' + moment(this.LicenseStatus.LicenseExpDate).format("dddd, DD MMM YYYY") +
            '. ' + this.LicenseStatus.LicenseExpDays + ' day(s) remaining.'
        }
      }
    } else {
      this.openLicensenKey = true;
    }
    this.LicenseKeyForm = new FormGroup({
      'LicenseKey': new FormControl(null, [Validators.required]),
      'LicenseExpDate': new FormControl('')
    });
  }

  async checkLicense(form) {
    if (form.valid && !this.loading) {
      this.loading = true;
      this.IsvalidForm = true;
      this.showError = false;
      this.showLicenseStatus = false;
      var result = await this.login.checkLicense(this.LicenseKeyForm.value)
        .then((res: any) => {
          // debugger;
          this.loading = false;
          if (res["ResponseData"]) {
            this.LicenseDetails = res["ResponseData"];
            if (this.LicenseDetails.Message != '') {
              if (this.LicenseDetails.Message.includes('KeyNotFor')) {
                this.showError = true;
                this.subscription.add(this.translate.get(['Messages.keynotfor']).subscribe((translations) => {
                  this.showMsg = translations['Messages.keynotfor'];
                  this.showMsg = this.showMsg.replace('<CName>', this.LicenseDetails.Message.split("KeyNotFor:")[1]);
                }));
              } else {
                this.showError = true;
                this.subscription.add(this.translate.get(['Messages.' + this.LicenseDetails.Message]).subscribe((translations) => {
                  this.showMsg = translations['Messages.' + this.LicenseDetails.Message];
                }));
              }
            } else {
              // if (this.LicenseDetails.LicenseExpDays >= 0) {
              this.showError = false;
              this.showLicenseStatus = true;
              this.subscription.add(this.translate.get(['Messages.confirmLicenseRenew']).subscribe((translations) => {
                this.showMsg = translations['Messages.confirmLicenseRenew'].replace('<Date>', moment(this.LicenseDetails.LicenseExpDate).format("DD/MMM/YYYY"));
              }));
              // }
              // else {
              //   this.showLicenseStatus = false;
              //   this.showError = true;
              //   this.showMsg = 'Entered license expired on ' + moment(this.LicenseDetails.LicenseExpDate).format("DD/MMM/YYYY") +
              //     '. Please enter a valid license.';
              // }
            }

          }
        });
    } else {
      this.IsvalidForm = false;
    }
  }
  renewLicense() {
    this.LicenseKeyForm.controls.LicenseExpDate.setValue(moment(this.LicenseDetails.LicenseExpDate).format("DD/MMM/YYYY"));
    this.subscription.add(this.login.renewLicense(this.LicenseKeyForm.value).subscribe(
      data => {
        // debugger;
        if (data) {
          if (data.IsSuccess === true && data.ResponseData == 1) {
            // this.subscription.add(this.translate.get(['Messages.saved_success']).subscribe((translations) => {
            //   this.successMessage = translations['Messages.saved_success'];
            // }));
            this.renewSuccess = true;
            this.showMsg = 'Thank you for renewing the license. Please remember to renew license on ' + moment(this.LicenseDetails.LicenseExpDate).format("DD/MMM/YYYY");
            //this.LicenseKeyForm.reset();
          }
          this.loading = false;
        }
        else {
          this.loading = false;
          this.showMsg = "Please try again later";
        }
      },
      err => {
        this.loading = false;
        this.showMsg = "Please try again later";

      }
    ));
  }
  version = '';
  sendingMail = false;
  onLicenseRequest() {
    this.version = require("../../../config").config.appVersion;
    this.spinner.show();
    this.subscription.add(this.login.licenseRequest({ User: this.user, Version: this.version }).subscribe(
      data => {
        this.spinner.hide();
        if (data && data.ResponseData == 1) {
          this.toastr.success('Mail sent successfully!', "", {
            timeOut: 5000,
            disableTimeOut: false,
            closeButton: true
          })
        }
        else {
          this.toastr.warning('Mail not sent. Please try later!', "", {
            timeOut: 5000,
            disableTimeOut: false,
            closeButton: true
          })
        }
      }, err => {
        this.spinner.hide();
        this.toastr.warning('Mail not sent. Please try later!', "", {
          timeOut: 5000,
          disableTimeOut: false,
          closeButton: true
        })
        // if (err.error.message) {
        //   this.errorMessage = err.error.message;

        // }
      }
    ));
  }
  closeLicense() {
    this.openLicensenKey = false;
  }
  okClick() {
    if (this.LicenseDetails.LicenseExpDays >= 0) {
      this.licenseOut.emit('ok');
    } else {
      this.licenseOut.emit('cancel');
    }

  }
  askLaterClick() {
    if (this.LicenseStatus.LicenseExpDays >= 0) {
      this.licenseOut.emit('ok');
    } else {
      this.licenseOut.emit('cancel');
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
