import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { EnvService } from "src/env.service";
import { CommonserviceService } from "./commonservice.service";
import { tap, catchError } from "rxjs/operators";
import { FolderQueuryReportDTO, FolderQueuryReportResultDTO, FolderQueryReportFilterDTO } from "../Models/folder-queury-report-dto";

const httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" })
};
@Injectable({
    providedIn: "root"
})

export class Folderqueryreport {
    public griddata: any;
    public GroupByGridData: any;
    apiUrl: string;
    public formData: FolderQueuryReportDTO;
    ObjFolderQueryReportFilterDTO: FolderQueryReportFilterDTO;
    formDataHeader: any = {
        RptHdrSel_AutoNo: 0,
        RptHdrSel_ModuleName: "",
        RptHdrSel_ColumnHeader: "",
        RptHdrSel_UserId: 0,
        RptHdrSel_TemplateName: "",
        RptHdrSel_Lupdate: new Date(),
        RptHdrSel_CustCo: ""
    };
    AirLineSelectedItem: { Code: string; UsrCode: string; Name: string; SELFBILLING: string; ActualName: string; IATACode: string; IATACheckingOnFolder: string; LID: any; Status: number; CurID: any; };
    AirLineList: any;
    item: any;
    constructor(
        private http: HttpClient,
        private env: EnvService,
        public commonservice: CommonserviceService
    ) {
        this.apiUrl = env.baseUrl;
    }


    public GetFolderQueryReport(): Observable<
        FolderQueuryReportDTO
    > {
        var body = {
            ...this.formData
        };
        return this.http
            .post<FolderQueuryReportDTO>(
                this.apiUrl + "GetFolderQueryReport",
                body,
                httpOptions
            )
            .pipe(
                tap((FolderQueuryReportDTO: any) =>
                    console.log(`GetFolderQueryReport`)
                ),
                catchError(this.commonservice.handleError<any>("GetFolderQueryReport"))
            );

    }


    public GetTemplate(): Observable<any> {
        var body = {
            ...this.formDataHeader
        };
        return this.http
            .post<FolderQueuryReportResultDTO>(
                this.apiUrl + "GetTemplate",
                body,
                httpOptions
            )
            .pipe(
                tap((FolderQueuryReportResultDTO: any) =>
                    console.log(`GetTemplate`)
                ),
                catchError(this.commonservice.handleError<any>("GetTemplate"))
            );
    }
    public GetGridColumn(): Observable<any> {
        var body = {
            ...this.formDataHeader
        };
        return this.http
            .post<FolderQueuryReportResultDTO>(
                this.apiUrl + "getGridHeader",
                body,
                httpOptions
            )
            .pipe(
                tap((FolderQueuryReportResultDTO: any) =>
                    console.log(`getGridHeader`)
                ),
                catchError(this.commonservice.handleError<any>("getGridHeader"))
            );
    }
    public saveOrUpdateReportHeader(): Observable<any> {
        var body = {
            ...this.formDataHeader
        };
        return this.http
            .post<FolderQueuryReportResultDTO>(
                this.apiUrl + "saveOrUpdateReportHeader",
                body,
                httpOptions
            )
            .pipe(
                tap((FolderQueuryReportResultDTO: any) =>
                    console.log(`saveOrUpdateReportHeader`)
                ),
                catchError(
                    this.commonservice.handleError<any>("saveOrUpdateReportHeader")
                )
            );
    }
    GetVarientTemplateForEdit(Template: any, modulename: any): Observable<any> {
        const url = `${this.apiUrl}` + 'GetVarientTemplateForEdit' + `/${Template}/${modulename}`;
        return this.http.get<any>(url);
    }
    BranchAuthorisation(branch: any, userID: any): Observable<any> {
        const url = `${this.apiUrl}` + 'BranchAuthorisation' + `/${branch}/${userID}`;
        return this.http.get<any>(url);
    }
    GetReportHeaderFooter(uid: number, folderno: any): Observable<any> {
        const url = `${this.apiUrl}` + "GetCompanyLogo" + `/${uid}/`;
        return this.http.get<any>(url).pipe(
            tap(_ => console.log(`=${folderno}`)),
            catchError(this.commonservice.handleError<any>(` id=${folderno}`))
        );
    }
    GetCrystalReport(): Observable<any> {
        debugger;
        var body = {
            ...this.ObjFolderQueryReportFilterDTO
        };
        return this.http.post<any>(this.apiUrl + 'GetCrystalReport', body);
        //alert(JSON.stringify(FolderSummary));

    }
}
