import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { FolderSummaryService } from '../../Services/folder-summary.service';
import { CustomerCreate, CustomerMaster, Login } from '../../Models/customer-create.model';
import { FolderSummaryEditComponent } from '../folder-summary-edit/folder-summary-edit.component';
import { FlashMessagesService } from 'angular2-flash-messages';
import { stringify } from 'querystring';
import { TranslateService } from '@ngx-translate/core';
import { ShareDataService } from '../../Services/share-data.service';
import { JsonPipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CommonserviceService } from '../../Services/commonservice.service';
import { CustomermasterserviceService } from '../../Services/customermasterservice.service';
import { PackageSummaryEditComponent } from '../../package-summary-edit/package-summary-edit.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Localization_Service } from '../../Services/localization.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  addressdetailsform:FormGroup;    public opened = false;
  private lookup:LookUpDetails;
  public allowCustom: boolean = true;
  public IsAutocode=false;
  public Salutation: string = "";
  public reponse:any;
  public showMsg:any;
  public submitted=false;
  PostCodelbl:any;
  public CustomerName:string;
  public listItems: Array<string> = ["", "MR", "MISS", "MRS", "MASTER", "M/S", "DR", "PROF", "MS" ];
  constructor(private spinner: NgxSpinnerService,public LocService : Localization_Service,public commonservice: CommonserviceService,private apiShared: ShareDataService,private translateapi: TranslateService, private formBuilder: FormBuilder,private lookupApi:LookUpDetailsService,private flashMessage: FlashMessagesService,private api:FolderSummaryService,private Pcomponent:FolderSummaryEditComponent, private customerApi: CustomermasterserviceService,private Ppkgcomponent1:PackageSummaryEditComponent, private toastr:ToastrService,) {debugger; this.LocService.getJSONLanguage('Default');  this.LoadBasicdat();}
  LanguageList: Array<LookUpDetails["ResponseData"]> = [];
  public LanguageSelectedItem: LookUpDetails["ResponseData"];
public languageSelected:String;
  CountryList:Array<LookUpDetails["ResponseData"]> = [];
  public countrySelectedItem: LookUpDetails["ResponseData"];
  public countrySelected:String;

  CitizenList:Array<LookUpDetails["ResponseData"]> = [];
  public citizenSelectedItem: LookUpDetails["ResponseData"];
    
  currencyList: Array<LookUpDetails["ResponseData"]> = [];
  public currencySelectedItem: LookUpDetails["ResponseData"];
  public currencySelected:String;
    
  TerritoryList:Array<LookUpDetails["ResponseData"]> = [];
  public TerritotySelectedItem: LookUpDetails["ResponseData"];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  ngOnInit() {
 debugger;
  

    this.addressdetailsform=this.formBuilder.group({
      CustomerUserCode:[''],
      Salutation:[''],
      Name:['',Validators.required],
      Address1:['',],
      Address2:['',],
      Address3:['',],
      Address4:['',],
      PostCode:['',],
      VATNum:['',],
      Country:['',Validators.required],
      Language:['',],
      Currency:['',],
      Citizenship:['',],
      MasterPage:false,
      Status:'Active',
      TerritoryName:''
    
    })
    this.subscription.add( this.api.GetAutoCodeFlagforCustomer()  .subscribe(data => {
      if(data!=null && data!=undefined)
      {
  this.IsAutocode=data["ResponseData"];

  this.addressdetailsform=this.formBuilder.group({
    CustomerUserCode:[''],
    Salutation:[''],
    Name:['',Validators.required],
    Address1:['',],
    Address2:['',],
    Address3:['',],
    Address4:['',],
    PostCode:['',],
    VATNum:['',],
    Country:['',Validators.required],
    Language:['',],
    Currency:['',],
    MasterPage:false,
    Status:'Active',
    Citizenship:['',],
    TerritoryName:''
  })
  if(this.IsAutocode==false)
  {
    const CustomerUserCode = this.addressdetailsform.get('CustomerUserCode');
    CustomerUserCode.setValidators([Validators.required]);
  }

}
    }));
  
   
    
    

      //  this.getdefaultvalue(); 
  }
  get f() { return this.addressdetailsform.controls; }
  public onFormSubmit()
  {
    this.spinner.show();
this.submitted=true;
    // public formData:CustomerCreate;
    // public CustomerMaster:CustomerMaster;
    // public Login:Login;
    this.api.formData=new CustomerMaster;
    // this.api.CustomerMaster=new CustomerMaster;
    this.api.Login=new Login;
    
    if (this.addressdetailsform.valid) {
      this.CustomerName=this.addressdetailsform.value["Name"];
     // this.addressdetailsform.value["Name"]= this.addressdetailsform.value["Salutation"] +" "+this.addressdetailsform.value["Name"];
      
      this.api.formData=this.addressdetailsform.value;
      this.lookup=new LookUpDetails;
      this.lookup.ResponseData
      if(this.Pcomponent.Componentname==undefined )
      {
        this.reponse=this.Ppkgcomponent1.currencyList[0];
      }
      else
      {
        this.reponse=this.Pcomponent.currencyList[0];
      }
  


     
     this.subscription.add( this.customerApi.UpdateSupplier(this.addressdetailsform.value).subscribe(res=>{
    // this.subscription.add( this.api.SaveCustomerAddress().subscribe(res=>{
      this.spinner.hide();
if(res.IsSuccess==true)
{
  if(this.Pcomponent.Componentname==undefined)
  {
  this.subscription.add(this.Ppkgcomponent1.lookupApi.GetAllLookup('CUSTOMER').subscribe(data=>{
    if(data!=null && data!=undefined)
    {
  if(data["ResponseData"].length>0){
   
      this.Ppkgcomponent1.customerSelectedItem =data["ResponseData"].find(item => item.UsrCode ==res.ResponseData);  
      this.Ppkgcomponent1.folderSummaryEditForm.controls.PRTYCODE.setValue(this.Ppkgcomponent1.customerSelectedItem.Code);
      this.Ppkgcomponent1.folderSummaryEditForm.controls.CUSTOMERNAME.setValue(this.CustomerName);
       this.Ppkgcomponent1.customerSelected=res.ResponseData+"-"+this.CustomerName;
       this.Ppkgcomponent1.prtycode=this.Ppkgcomponent1.customerSelectedItem.Code;
   
      this.apiShared.Setapishareddata(this.Ppkgcomponent1.prtycode,'CustomerCode');
      this.Ppkgcomponent1.loadCustomer();
    
  
  
  }
  
  this.Ppkgcomponent1.close(false);
  
  
}
}))
  }
 else
  {
  this.subscription.add(this.Pcomponent.lookupApi.GetAllLookup('CUSTOMER').subscribe(data=>{
    if(data!=null && data!=undefined)
    {
  if(data["ResponseData"].length>0){
   
      this.Pcomponent.customerSelectedItem =data["ResponseData"].find(item => item.UsrCode ==res.ResponseData);  
      this.Pcomponent.folderSummaryEditForm.controls.PRTYCODE.setValue(this.Pcomponent.customerSelectedItem.Code);
      this.Pcomponent.folderSummaryEditForm.controls.CUSTOMERNAME.setValue(this.CustomerName);
       this.Pcomponent.customerSelected=res.ResponseData+"-"+this.CustomerName;
       this.Pcomponent.prtycode=this.Pcomponent.customerSelectedItem.Code;
     
      this.apiShared.Setapishareddata(this.Pcomponent.prtycode,'CustomerCode');
      this.Pcomponent.loadCustomer();
    
  
  
  }
  this.spinner.hide();
  this.Pcomponent.close(false);
  
  
}
}))
  }
}
else
{
  this.spinner.hide();
  if(res.Message==='Error'){
    this.commonservice.showWARNINGtoastrmsg('res.ResponseData', 5000, true, false);
    
  }

  this.commonservice.showWARNINGtoastrmsg('Messages.Customercodealreadyexist', 5000, true, false);  
  if(this.Pcomponent.Componentname==undefined)
  {
   this.Ppkgcomponent1.customerSelected="";
   this.Ppkgcomponent1.folderSummaryEditForm.controls.PRTYCODE.setValue("");
  }
  else{
    this.Pcomponent.customerSelected="";
   this.Pcomponent.folderSummaryEditForm.controls.PRTYCODE.setValue("");
  }
}
     
          }))
    this.submitted=false;


    } else {
      this.spinner.hide();
      // validate all form fields
    }
  }
 
    async customerlist()
{
 
  

  
}

  public close() {
    if(this.Pcomponent.Componentname==undefined)
  {
    this.Ppkgcomponent1.close(false)
  }
  else
    this.Pcomponent.close(false);
  }

  public open() {

    this.opened = true;
  }
  public LoadBasicdat()
  {
    this.subscription.add(  this.lookupApi.GetAllLookup('LANGUAGES').subscribe(
    (data) => {
      if(data!=null && data!=undefined)
      {
       this.LanguageList= data["ResponseData"]  
     
       this.subscription.add(  this.api.GetDefaultLanguage().subscribe(data => {
        this.LanguageSelectedItem=this.LanguageList.find(item => item.UsrCode ==data.ResponseData.Code);
        this.addressdetailsform.controls.Language.setValue(this.LanguageSelectedItem.UsrCode);
  
        }));
      }
    }
    ));
    this.subscription.add( this.lookupApi.GetAllLookup('CURRENCY').subscribe(data => {
      if(data!=null && data!=undefined)
      {
        this.currencyList= data["ResponseData"];
      }
        this.subscription.add( this.api.GetDefaultCurrency().subscribe(data => {
          if(data!=null && data!=undefined)
          {
            this.currencySelectedItem=this.currencyList.find(item => item.UsrCode ==data.ResponseData.Name);
            this.addressdetailsform.controls.Currency.setValue(this.currencySelectedItem.UsrCode);
          }
          }));
      }));
      this.subscription.add( this.lookupApi.GetAllLookup('COUNTRY').subscribe(
        (data) => {
          if(data!=null && data!=undefined)
          {
          this.CountryList= data["ResponseData"] ;
          }
          this.subscription.add(  this.api.GetDefaultCountry().subscribe(data => {
            if(data!=null && data!=undefined)
          {
            debugger;
            this.GetTerritoryByCountry(data.ResponseData.UsrCode);
          this.LocService.getJSONLanguage(data.ResponseData.UsrCode);
          this.countrySelectedItem=this.CountryList.find(item => item.UsrCode ==data.ResponseData.UsrCode);
          this.addressdetailsform.controls.Country.setValue(this.countrySelectedItem.UsrCode);
          }
          else
          {
            debugger;
            this.LocService.getJSONLanguage("EN")
          }
          }));
        }));

      
        this.subscription.add(this.lookupApi.GetAllLookup('CITIZENSHIP').subscribe(
          (data) => {
            if(data!=null && data!=undefined)
            {
             this.CitizenList= data["ResponseData"] 
             if(this.addressdetailsform.controls.Citizenship.value!="")
             {
              
             this.citizenSelectedItem=this.CitizenList.find(item => item.Code ==this.addressdetailsform.controls.Citizenship.value);
         
           
            }                   
          }
          }
          ));}

// public getdefaultvalue()
// {
//   this.api.GetDefaultCountry().subscribe(data => {
//     
//    this.countrySelectedItem.Name="df"
//   });

// }
GetTerritoryByCountry(usrcode)
{
  this.subscription.add(
    this.lookupApi
      .GetLookupById("Territory", usrcode, "1")
      .subscribe(data => {
        if(data!=null && data!=undefined)
        {
          debugger;
          this.spinner.hide();
         this.TerritoryList= data["ResponseData"] 
         if( this.TerritoryList.length>0)
         {
           if(this.addressdetailsform.controls.TerritoryName.value!=""&& this.addressdetailsform.controls.TerritoryName.value!='')
          this.TerritotySelectedItem=   this.TerritoryList.find(x=>x.Name==this.addressdetailsform.controls.TerritoryName.value);
        else{
         // this.TerritotySelectedItem=   this.TerritoryList[0];
         // this.supplierdetailsform.controls.TerritoryName.setValue(this.TerritotySelectedItem.Name);
        }
        }
         
         else
         {
          this.addressdetailsform.controls.TerritoryName.setValue('');
          this.TerritotySelectedItem=null;
         }
                     
      }
      else
      {
        this.spinner.hide();
      }
      }))
}
TerrritoyValueChange(value: any)
{
  debugger;
  if(value!=null && value!=undefined){
    this.addressdetailsform.controls.TerritoryName.setValue(value.Name);
 }
else
{
  this.addressdetailsform.controls.TerritoryName.setValue("");
  this.TerritotySelectedItem=null;
}
}
  public CitizenshipValueChange(value: any): void {

    if(value!=null && value!=undefined)
    this.addressdetailsform.controls.Citizenship.setValue(value.UsrCode);
    else
    this.addressdetailsform.controls.Citizenship.setValue("");
  }
  public CurrencyValueChange(value: any): void {
    if(value!=null && value!=undefined)
    this.addressdetailsform.controls.Currency.setValue(value.UsrCode);
    else
    this.addressdetailsform.controls.Currency.setValue("");
  }
  public LanguageValueChange(value: any): void {
    if(value!=null && value!=undefined)
    this.addressdetailsform.controls.Language.setValue(value.UsrCode);
    else
    this.addressdetailsform.controls.Language.setValue("");
  }
  public CountryValueChange(value: any): void {
    debugger;
    this.spinner.show();
    if(value!=null && value!=undefined)
    {
      this.TerritoryList=[]
      this.TerritotySelectedItem=null;
      this.addressdetailsform.controls.TerritoryName.setValue('');
      this.GetTerritoryByCountry(value.UsrCode);
      this.addressdetailsform.controls.Country.setValue(value.UsrCode);
      value.Code=value.Code.toUpperCase();
      this.LocService.getJSONLanguage(value.Code);
     
    } 
    else
    {
      debugger;
      this.spinner.hide();
      this.TerritotySelectedItem=null;
      this.TerritoryList=[]
      this.addressdetailsform.controls.TerritoryName.setValue('');
      this.countrySelectedItem=null;
      this.addressdetailsform.controls.Country.setValue("");
      this.LocService.getJSONLanguage('Default');
    }
   
  }
  ngOnDestroy() {
    console.log('ngOnDestorycust');
    this.subscription.unsubscribe();
  } 
}

