import { NgModule } from '@angular/core';
import { ChangepasswordComponent } from './change-password/changepassword.component';
import { LicenceRenewalComponent } from './licence-renewal/licence-renewal.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WindowModule, DialogModule, DialogsModule } from "@progress/kendo-angular-dialog";
// import { EditorModule } from "@progress/kendo-angular-editor";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { NgxSpinnerModule } from "ngx-spinner";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [ChangepasswordComponent,LicenceRenewalComponent],
  imports: [
    ButtonsModule,
    WindowModule,
    DialogModule,
    DialogsModule,
    CommonModule,
    InputsModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
    // You can import other modules here if needed
  ],
  exports: [ChangepasswordComponent,LicenceRenewalComponent]
})
export class SharedLoginModule { }
