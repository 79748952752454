import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AirsegDetail } from '../Models/airseg-detail';
import { Observable, of,throwError  } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
 //'http://localhost:29048/';
@Injectable({

  providedIn: 'root'
})
export class AirsegService {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   }



//Supplierss:Object

  //getSuppliers (): Observable<HotelDetail[]> {   
    
    ////return this.http.get<HotelDetail[]>(apiUrl)
     // .pipe(
    //   tap(heroes => console.log('fetched Suppliers')),
     // catchError(this.handleError.handleError('getSuppliers', []))
      //);
  //}
  
  private newMethod(data: Object) {
    alert(data);
  }

  getAirseg(folderNo: number,SLNO:number): Observable<AirsegDetail> 
  {   
    
    const url = `${this.apiUrl}`+'GetAirsegDetailsById'+`/${folderNo}/${SLNO}`;
    return this.http.get<AirsegDetail>(url).pipe(
      tap(_ => console.log(`fetched getAirseg id=${folderNo}`)),
      catchError(this.commonservice.handleError<AirsegDetail>(`getAirseg id=${folderNo}`))
    );   
  }
  addAirseg (airsegdetail: any): Observable<any> 
    {
      if(airsegdetail.AIRDATE===null || airsegdetail.AIRDATE==='')
      airsegdetail.AIRDATE=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
    airsegdetail.AIRDATE=this.commonservice.ConvertToLocaleDate(airsegdetail.AIRDATE);
    if(airsegdetail.ARRDATE===null || airsegdetail.ARRDATE==='')
    airsegdetail.ARRDATE=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
    airsegdetail.ARRDATE=this.commonservice.ConvertToLocaleDate(airsegdetail.ARRDATE);
    
      return this.http.post<any>(this.apiUrl+'AddAirsegDetails', airsegdetail, httpOptions).pipe(
        tap((aisegRes: any) => console.log(`added Airseg w/ id=${airsegdetail.AIRNAME}`)),
        // tap((aisegRes: AirsegDetail) => console.log(`added Airseg w/ id=${aisegRes.ResponseData.AIRNAME}`)),
        catchError(this.commonservice.handleError<any>('addAirseg'))
      );
    }
    updateAirseg ( airsegdetail: any): Observable<any>
     {
      if(airsegdetail.AIRDATE===null || airsegdetail.AIRDATE==='')
      airsegdetail.AIRDATE=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
    airsegdetail.AIRDATE=this.commonservice.ConvertToLocaleDate(airsegdetail.AIRDATE);
    if(airsegdetail.ARRDATE===null || airsegdetail.ARRDATE==='')
    airsegdetail.ARRDATE=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
    airsegdetail.ARRDATE=this.commonservice.ConvertToLocaleDate(airsegdetail.ARRDATE);

      const url = `${this.apiUrl}`+'UpdateAirSegDetails';///${id}
      return this.http.post(url, airsegdetail, httpOptions).pipe(
        tap(_ => console.log(`updated Airseg`)),
        catchError(this.commonservice.handleError<any>('updateAirseg'))
      );
    }
    
    deleteAirseg (orderNo: number,slNo:number): Observable<any> {
      const url = `${this.apiUrl}`+'DeleteAirSegDetails'+`/${orderNo}/${slNo}`;
   
      return this.http.post(url,  httpOptions).pipe(
        tap(_ => console.log(`delete Airsegment`)),
        catchError(this.commonservice.handleError<any>('deleteAirseg'))
      );
    }



    GetAllAirSegDetails(folderNo: number): Observable<AirsegDetail[]> {
      const url = `${this.apiUrl}`+'GetAllAirSegDetails'+`/${folderNo}`;
     // const url = `${apiUrl}/${OrderNo}/${GuidItinerary}`;
      return this.http.get<AirsegDetail[]>(url).pipe(
        tap(_ => console.log(`fetched AirSegDetails id=${folderNo}`)),
        catchError(this.commonservice.handleError<AirsegDetail[]>(`GetAllAirSegDetails id=${folderNo}`))
      );
    }

}
