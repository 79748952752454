import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpDetails } from '../Services/look-up-details';
import { FolderCommCalculation, FolderSummary } from '../Models/folder-summary';
import { FolderSummaryService } from '../Services/folder-summary.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LookUpDetailsService } from '../Services/look-up-details.service';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { CalendarCustomMessagesComponent } from '@progress/kendo-angular-dateinputs';
import { CurrencyExchangerateService } from '../Services/currency-exchangerate.service';
import { CurrencyExchangerate } from '../Models/currency-exchangerate';
import { NgxSpinnerService } from "ngx-spinner";
import { ShareDataService } from '../Services/share-data.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { PendoclockService } from '../Services/pendoclock.service';
import { Pendoclock } from '../Models/pendoclock';
import { CustomerspecificfieldService } from '../Services/customerspecificfield.service';
import { CustomerspecificEditComponent } from '../folder/customerspecific-edit/customerspecific-edit.component'
import { JsonPipe } from '@angular/common';
import { CommonserviceService } from '../Services/commonservice.service';
import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';
import { Subscription } from 'rxjs';
import { PublicLayoutComponent } from '../public-layout/public-layout.component';
import { FileService } from '../Services/file.service';
import { ToastrService } from 'ngx-toastr';
import { IntlService } from '@progress/kendo-angular-intl';
import { ProductsComponent } from '../product/products/products.component';
import { ProductPackageComponent } from '../product/product-package/product-package.component';
import { FolderpriceService } from '../Services/folderprice.service';
import { CashPaymentService } from '../Services/cash-payment.service';
import { CashPaymentDTO } from '../Models/cash-payment-dto.model';
@Component({
  selector: 'app-package-summary-edit',
  templateUrl: './package-summary-edit.component.html',
  styleUrls: ['./package-summary-edit.component.scss']
})
export class PackageSummaryEditComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  @ViewChild('autocomplete') public autocomplete: AutoCompleteComponent;
  @ViewChild('autocompletecustomer') public autocompletecustomer: AutoCompleteComponent;
  @ViewChild('autocompletepassenger') public autocompletepassenger: AutoCompleteComponent;
  openSearch = false;
  lookupname: any = 'CUSTOMER';
  lookuptype: string = '';
  currencyExrate: CurrencyExchangerate;
  ucode: string;
  isShowncase: any;
  public customerradioselection = false;
  public retailradioselection = false;
  prtycode: any = '';
  savebutton = true;
  public UID: any;
  public csview: any[];
  private salutation: string
  addressdetailsform: FormGroup;
  public disabled: any = false;
  TotCOMM: number;
  TotBUY: number;
  folderSummaryEditForm: FormGroup;
  submitted = false;
  loaded = false;
  sell: number;
  totalReceivable: number;
  balance: number;
  folderEditMode: any;
  Users_DisableCustomer: boolean = true;
  Users_DisableBranch: any
  Users_DisableBA: any
  public showMsg: any;
  folderedit: any;
  folderStatus: any;
  folderdate = false;
  folderdateform: Date;
  QuoteConvDate: any //337
  depositeDueDate: any //337
  balanceDueDate: any //337
  creationDate: any //337
  currentdateval: string = ''
  passengerName: any;
  public comm: number = 0.00;
  currentdate = new Date();
  AgtTotCOMM: any = 0.00;
  public Componentname: string;
  public totalRecievable: number = 0.00;
  penDockLock: Pendoclock = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {

      LDocType: "",
      LDocNo: "",
      UID: 0,
      LDATE: "",
      LTIME: "",
      LPROJNO: "",
      LYearID: "",
      LAutoNo: "",
      Uname: "",
      Source: ""

    }
  };
  public package = false;
  public manualCalculation = false;
  public bkdID: any;
  showPass = false;//By Case id-367
  folderType = "folderSummary";//By Case id-367
  addProduct = false;//By Case id-367
  addPass = false;//By Case id-367
  public projNo: any;//Caseid-367
  creditLimiFlag = false;
  msg: any;
  creditlimit = false;
  showCS = false;
  constructor(public intl: IntlService, public commonservice: CommonserviceService, private layout: PublicLayoutComponent, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private api: FolderSummaryService
    , public lookupApi: LookUpDetailsService, private apiExRate: CurrencyExchangerateService, private spinner: NgxSpinnerService
    , private apiShared: ShareDataService, private apiCS: CustomerspecificfieldService, public fileservice: FileService, private toastr: ToastrService
    , private flashMessage: FlashMessagesService, private translateapi: TranslateService,
    private penLock: PendoclockService, public product: ProductPackageComponent, public objFolderPrice: FolderpriceService,
    private cashservice: CashPaymentService) {
    this.UID = this.apiShared.uid;
    this.bkdID = +(this.apiShared.uid);
    this.GetDefaultValuesOfLoggeduser();
  }

  public orderNo = this.route.snapshot.params['orderNo']
  CustomerList: Array<LookUpDetails["ResponseData"]> = [];

  public customerSelectedItem: LookUpDetails["ResponseData"];
  public customerSelected: String;

  orderTypeList: Array<LookUpDetails["ResponseData"]> = [];
  public orderTypeSelectedItem: LookUpDetails["ResponseData"];
  public orderTypeSelected: String;

  branchList: Array<LookUpDetails["ResponseData"]> = [];
  public branchSelectedItem: LookUpDetails["ResponseData"];
  public branchSelected: String;
  businessAreaList: Array<LookUpDetails["ResponseData"]> = [];
  public businessAreaSelectedItem: LookUpDetails["ResponseData"];
  public businessAreaSelected: String;
  public alreadyPaid: number = 0.00;
  currencyList: Array<LookUpDetails["ResponseData"]> = [];
  public currencySelectedItem: LookUpDetails["ResponseData"];
  public currencySelected: String;


  BOOKEBYList: Array<LookUpDetails["ResponseData"]> = [];
  public bookbyselectedItem: LookUpDetails["ResponseData"];
  public bookedbySelected: String;

  destinationList: Array<LookUpDetails["ResponseData"]> = [];
  public destinationSelectedItem: LookUpDetails["ResponseData"];
  public destinationSelected: String;
  public source: Array<LookUpDetails["ResponseData"]> = [];

  passengerList: Array<LookUpDetails["ResponseData"]> = [];
  public passengerSelectedItem: LookUpDetails["ResponseData"];
  public passengerSelected: String;

  LanguageList: Array<LookUpDetails["ResponseData"]> = [];
  public LanguageSelectedItem: LookUpDetails["ResponseData"];
  public AGTaxselectedItem: LookUpDetails["ResponseData"];
  Tax: Array<LookUpDetails["ResponseData"]> = [];
  public TaxselectedItem: LookUpDetails["ResponseData"];


  MarketingCodeList: Array<LookUpDetails["ResponseData"]> = [];
  public MarketingCodeListSelectedItem: LookUpDetails["ResponseData"];
  public MarketingCodeListSelected: String;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };


  public windowTop: number = 5;
  public windowLeft: number = 40;
  public otherCredits: number = 0.00;
  public windowWidth = 1000;
  public windowHeight = 600;
  public hasbookedbypermission: boolean = true;
  public edit = false;
  public projNum: any;
  packagefolderno: number = 0;
  public agentComm = false;
  public FCCREDITAMT: number = 0.00;
  public AgentCommission: any = {
    FCAGTCOM: 0,
    FCAGTCOMSUM: 0,
    FCAGTCOMVAT: 0,
    AGTCOMVATNO: 0,
    AGTCOMVATRate: 0,
  }
  public TotalValue: any = {
    TotRECEIVABLE: 0,
    VATNO: 0,
    VATRate: 0,
    VATAmt: 0,
    VatTotal: 0,
    FCAGTCOM: 0,
    FCAGTCOMSUM: 0,
    FCAGTCOMVAT: 0,
    AGTCOMVATNO: 0,
    Balance: 0,
    TotalCOMM: 0,
    NetComm: 0,
    Description: '',
    FCTotalCOMM: 0,
    MarkupPer: 0
  }
  showCalc = false;
  commDetail = {
    type: '',
    currency: ''
  };
  objCommCalculation: FolderCommCalculation = {
    FCTotalBUY: 0,
    PDQCHARGE: 0,
    FC_CIDExRate: 0,
    FCTotalCOMM: 0,
    FCCREDITAMT: 0,
    FCAGTCOMSUM: 0,
    FCALREADYPAID: 0,
    FCOtherCredits: 0,
    TotalBUY: 0,
    TotalCOMM: 0,
    CREDITAMT: 0,
    AGTCOMSUM: 0,
    ALREADYPAID: 0,
    OtherCredits: 0
  }

  public addCustOpened = false;
  public addPassOpened = false;
  async ngOnInit() {
    this.spinner.show();
    sessionStorage.removeItem('folderQuotes');
   
    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderNo !== orderno) {
        this.commonservice.folderEditMode = 'false';//by case id-355
        this.apiShared.folderEditMode = this.commonservice.folderEditMode;//by case id-355
        this.apiShared.Setapishareddata(this.commonservice.folderEditMode, 'folderEditMode');//by case id-355
        return this.router.navigate(['PenAir/Home'], { skipLocationChange: true });
      }
    if (this.orderNo != '0') {
      this.edit = true;
    }
    this.UID = this.apiShared.uid;

    this.folderEditMode = "true";
    this.commonservice.folderEditMode = "true"
    this.apiShared.folderEditMode = this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.customerradioselection = true;
    //if(this.orderNo!=null)
    this.folderSummaryEditForm = this.formBuilder.group({
      FOLDERNO: this.orderNo,
      PROJNO: [""],
      FCTotalBUY: [0],
      FCTotalCOMM: [0],
      TotVAT: [0],
      FCCREDITAMT: [0],
      AGTCOMSUM: [0],
      AGTCOMVAT: [0],
      FCAGTCOMSUM: [0],
      FCAGTCOMVAT: [0],
      AGTCOMVATNO: [0],
      TotRECEIVABLE: [0],
      FCALREADYPAID: [0],
      FCOtherCredits: [0],
      PRTYCODE: [''],
      BRID: ["", Validators.required],
      BAID: ["", Validators.required],
      OrderType: ["Package", Validators.required],
      FC_CID: ["", Validators.required],
      ARRIVALPT: ["", Validators.required],
      DESTINAION: [""],
      FOLDERDATE: [new Date()],
      BOOKEDBY: [""],
      NOOFPASS: [0],
      INETREF: [""],
      PONO: [""],
      STATUS: [""],
      DEPDUEDATE: [new Date()],
      DEPAMT: [0],
      BALDUEDATE: [new Date()],
      BALAMT: [0],
      QCONDATE: [],
      FC_CIDExRate: [0],
      FCTotRECEIVABLE: [0],
      Receipt: [''],
      CRDATE: [new Date()],
      PPID: [0],
      StatusName: [""],
      CUSTOMERNAME: [""],
      FM_LID: ["", Validators.required],
      BAName: [""],
      BrName: [""],
      CREDITAMT: [0],
      PDQCHARGE: [0],
      FM_MnlPkgCalc: [0],
      PACKAGED: [0],
      MCID: [""],
      MarkupPer: [0],
      IsDirectRefund:0
    });

    this.GetFolderDetailsById(this.orderNo);



    this.subscription.add(this.lookupApi.GetAllLookup('ORDERTYPEMASTER').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.orderTypeList = data["ResponseData"]
          if (this.folderSummaryEditForm.controls.OrderType.value != "") {

            this.orderTypeSelectedItem = this.orderTypeList.find(item => item.Name == this.folderSummaryEditForm.controls.OrderType.value);
          }
        }



      }
    ));

    this.subscription.add(this.lookupApi.GetAllLookup('BRANCH').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.branchList = data["ResponseData"]

          if (this.folderSummaryEditForm.controls.BRID.value != "") {
            this.branchSelectedItem = this.branchList.find(item => item.UsrCode == this.folderSummaryEditForm.controls.BRID.value);

            // console.log("test:-"+this.folderSummaryEditForm.controls.BRID.value+JSON.stringify(this.branchSelectedItem , null, 4));
          }
        }

      }));

    this.subscription.add(this.lookupApi.GetAllLookup('BUSINESSAREA').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.businessAreaList = data["ResponseData"]
          if (this.folderSummaryEditForm.controls.BAID.value != "") {
            this.businessAreaSelectedItem = this.businessAreaList.find(item => item.Code == this.folderSummaryEditForm.controls.BAID.value);
            //console.log("test:-"+this.folderSummaryEditForm.controls.BAID.value+JSON.stringify(this.businessAreaSelectedItem , null, 4));
          }
        }
      }));

    this.subscription.add(this.lookupApi.GetAllLookup('CURRENCY').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.currencyList = data["ResponseData"]
          if (this.folderSummaryEditForm.controls.FC_CID.value != "") {
            this.currencySelectedItem = this.currencyList.find(item => item.Code == this.folderSummaryEditForm.controls.FC_CID.value);
            this.getCurrencyExRate();
          }
        }
      }));

    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.BOOKEBYList = data["ResponseData"]
          if (this.folderSummaryEditForm.controls.BOOKEDBY.value != "") {
            this.bookbyselectedItem = this.BOOKEBYList.find(item => item.Name == this.folderSummaryEditForm.controls.BOOKEDBY.value);

          }
        }
      }));

     
      if (this.folderSummaryEditForm.controls.PPID.value > 0) {
        this.Loadpassenger();
      }
    // this.subscription.add(this.lookupApi.GetAllLookup('PASSENGER').subscribe(
    //   (data) => {
    //     if (data["ResponseData"] != null) {
    //       this.passengerList = data["ResponseData"];

    //       if (this.folderSummaryEditForm.controls.PPID.value != 0) {
    //         this.passengerSelectedItem = this.passengerList.find(item => item.Code == this.folderSummaryEditForm.controls.PPID.value);
    //         if (this.passengerSelectedItem != null){
    //           this.passengerSelected = this.passengerSelectedItem.Name;
    //           this.autocompletepassenger.text= this.passengerSelectedItem.Name;
    //           this.folderSummaryEditForm.controls.PRTYCODE.setValue(this.passengerSelectedItem.ActualName);//Passenger custco taken in Actual Name field
  
    //         }
    //         else
    //         {
    //           this.autocompletepassenger.text='';
    //           this.passengerSelected = "";
    //   this.folderSummaryEditForm.controls.PPID.setValue(0);
    //   this.folderSummaryEditForm.controls.PRTYCODE.setValue("");
    //         }

    //       }
    //     }
    //   }));

    this.subscription.add(this.lookupApi.GetAllLookup('LANGUAGES').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.LanguageList = data["ResponseData"]
          //console.log(JSON.stringify(data["ResponseData"], null, 4));
          if (this.folderSummaryEditForm.controls.FM_LID.value != "") {
            this.LanguageSelectedItem = this.LanguageList.find(item => item.UsrCode == this.folderSummaryEditForm.controls.FM_LID.value);
          }
        }
      }));
    this.subscription.add(this.lookupApi.GetAllLookup('TAX').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.Tax = data["ResponseData"];

          if (this.TotalValue.VATNO !== null) {
            this.TaxselectedItem = this.Tax.find(item => item.Code == this.TotalValue.VATNO);

          }
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('MarketingCode').subscribe((data) => {
      if (data["ResponseData"] != null) {
        this.MarketingCodeList = data["ResponseData"]
        if (this.folderSummaryEditForm.controls.MCID.value != "") {
          this.MarketingCodeListSelectedItem = this.MarketingCodeList.find(item => item.Code == this.folderSummaryEditForm.controls.MCID.value);
        }
      }
    }));
    if (Boolean(await this.commonservice.checkPermission1('01.12.01.14')) == true) {
      this.hasbookedbypermission = true;

    } else {
      this.hasbookedbypermission = false;
    }
    this.loaded = true;//Added by case id-367
    this.Componentname = "FolderSummaryEdit";
    sessionStorage.setItem("Componentname", this.Componentname);
  }
  public loadDestination() {

    if (this.folderSummaryEditForm.controls.ARRIVALPT.value != '' && this.folderSummaryEditForm.controls.ARRIVALPT.value != null) {

      this.subscription.add(this.lookupApi.SearchDestination(this.folderSummaryEditForm.controls.ARRIVALPT.value, '0').subscribe(
        (data) => {

          if (data["ResponseData"] != null) {

            this.destinationList = data["ResponseData"];
            //console.log(JSON.stringify(data["ResponseData"], null, 4));
            if (this.folderSummaryEditForm.controls.ARRIVALPT.value != "") {
              this.destinationSelectedItem = this.destinationList.find(item => item.Code == this.folderSummaryEditForm.controls.ARRIVALPT.value);
              if (this.destinationSelectedItem != null)
                this.destinationSelected = this.destinationSelectedItem.Name;
            }
          }

        }
      ));
    }

  }
  GetDefaultValuesOfLoggeduser() {

    if (this.UID !== undefined) {

      this.subscription.add(this.fileservice.GetDefaultValuesOfLoggeduser(this.UID)
        .subscribe(data => {
          if (data.ResponseData != null) {
            this.Users_DisableCustomer = data.ResponseData.Users_DisableCustlookup;
            this.Users_DisableBranch = data.ResponseData.Users_DisableBRlookup;
            this.Users_DisableBA = data.ResponseData.Users_DisableBAlookup;
            this.disabled = this.Users_DisableCustomer;
          }

        }));
    }

  }
  handleFilterPassenger(value) {
    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.SearchPassenger(value, this.bkdID).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.passengerList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletepassenger.toggle(false);
    }
  }
  public AGTaxChange(value: any): void {
    this.folderdate = false;
    if (value != undefined) {
      this.AgentCommission.AGTCOMVATNO = value.Code;
      this.AgentCommission.AGTCOMVATRate = value.UsrCode;
    }
    else {
      this.AgentCommission.AGTCOMVATNO = 0;
      this.AgentCommission.AGTCOMVATRate = 0;
    }
    this.CalculateAGTax();
  }
  public CalculateAGTax() {
    this.folderdate = false;
    this.AgentCommission.FCAGTCOMVAT = this.AgentCommission.FCAGTCOMSUM * this.AgentCommission.AGTCOMVATRate / 100;
    this.AgentCommission.FCAGTCOM = this.AgentCommission.FCAGTCOMSUM + this.AgentCommission.FCAGTCOMVAT;
  }
  public Loadpassenger()
  {
    if (this.folderSummaryEditForm.controls.PPID.value != '' && this.folderSummaryEditForm.controls.PPID.value != null) {

      this.subscription.add(this.lookupApi.findPassenger(this.folderSummaryEditForm.controls.PPID.value).subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.passengerList = data["ResponseData"];
  debugger;
          if (this.folderSummaryEditForm.controls.PPID.value != 0) {
            this.passengerSelectedItem = this.passengerList.find(item => item.Code == this.folderSummaryEditForm.controls.PPID.value);
            if (this.passengerSelectedItem != null){
              this.passengerSelected = this.passengerSelectedItem.Name;
              this.autocompletepassenger.text=this.passengerSelectedItem.Name;
              this.folderSummaryEditForm.controls.PRTYCODE.setValue(this.passengerSelectedItem.ActualName);//Passenger custco taken in Actual Name field

            }
            else
            {
              this.autocompletepassenger.text='';
              this.passengerSelected = "";
      this.folderSummaryEditForm.controls.PPID.setValue(0);
      this.folderSummaryEditForm.controls.PRTYCODE.setValue("");
            }
          }
        }
      }));
     
    }
  }
  confirmAgentComm(save: boolean) {
    this.folderdate = false;
    if (save) {
      debugger;
      this.TotalValue.FCAGTCOM = this.AgentCommission.FCAGTCOM;
      this.TotalValue.FCAGTCOMSUM = this.AgentCommission.FCAGTCOMSUM,
        this.TotalValue.FCAGTCOMVAT = this.AgentCommission.FCAGTCOMVAT;
      this.TotalValue.AGTCOMVATNO = this.AgentCommission.AGTCOMVATNO;
      this.folderSummaryEditForm.controls.FCAGTCOMSUM.setValue(this.AgentCommission.FCAGTCOMSUM);
      this.folderSummaryEditForm.controls.FCAGTCOMVAT.setValue(this.AgentCommission.FCAGTCOMVAT);
      this.folderSummaryEditForm.controls.AGTCOMSUM.setValue(this.AgentCommission.FCAGTCOMSUM * this.folderSummaryEditForm.controls.FC_CIDExRate.value);
      this.folderSummaryEditForm.controls.AGTCOMVAT.setValue(this.AgentCommission.FCAGTCOMVAT * this.folderSummaryEditForm.controls.FC_CIDExRate.value);
      this.folderSummaryEditForm.controls.AGTCOMVATNO.setValue(this.AgentCommission.AGTCOMVATNO);
      this.CalculateTax();
    }

    this.agentComm = false;
  }

  CalculateTax() {
    debugger;
    this.alreadyPaid = this.folderSummaryEditForm.controls.FCALREADYPAID.value;
    this.otherCredits = this.folderSummaryEditForm.controls.FCOtherCredits.value;
    this.FCCREDITAMT = this.folderSummaryEditForm.controls.FCCREDITAMT.value;
    //this.totalRecievable= this.sell;
    if (this.folderSummaryEditForm.value['CREDITAMT'] === 0)
      this.TotCOMM = this.folderSummaryEditForm.controls.FCTotalCOMM.value - (this.folderSummaryEditForm.value['PDQCHARGE'] / this.folderSummaryEditForm.controls.FC_CIDExRate.value);
    else
      this.TotCOMM = this.folderSummaryEditForm.controls.FCTotalCOMM.value;

    this.TotalValue.NetComm = this.TotCOMM - this.folderSummaryEditForm.controls.FCAGTCOMSUM.value
    //this.TotalValue.NetComm=this.comm-this.TotalValue.FCAGTCOMSUM;
    this.TotalValue.VATAmt = this.sell * this.folderSummaryEditForm.controls.TotVAT.value / 100;
    this.TotalValue.VatTotal = this.sell + this.TotalValue.VATAmt;
    this.TotalValue.TotRECEIVABLE = this.TotalValue.VatTotal - this.TotalValue.FCAGTCOM + this.FCCREDITAMT;
    this.TotalValue.Balance = this.TotalValue.TotRECEIVABLE - this.alreadyPaid - this.otherCredits;
    this.totalReceivable = this.TotalValue.TotRECEIVABLE;
    this.balance = this.TotalValue.Balance;
    this.AgtTotCOMM = this.TotalValue.NetComm == null ? 0 : this.TotalValue.NetComm;

    //   if(this.folderSummaryEditForm.value['CREDITAMT']===0)
    //   this.folderSummaryEditForm.controls.FCTotalCOMM.setValue(this.TotCOMM+this.folderSummaryEditForm.value['PDQCHARGE']);
    // else
    //   this.folderSummaryEditForm.controls.FCTotalCOMM.setValue(this.TotCOMM);

  }
  public MarketingCodeChange(value: any): void {
    if (value != undefined && value != null) {
      this.folderSummaryEditForm.controls.MCID.setValue(value.Code);
    }
    else {
      this.folderSummaryEditForm.controls.MCID.setValue('');
    }
  }
  public loadCustomer() {
    this.lookupApi.GetAllLookup('CUSTOMER').toPromise();
  }
  notifygrid(event) {
    this.csview = event;
  }
  handleFilter(value) {

    if (value.length >= 3) {


      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.destinationList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocomplete.toggle(false);
    }
  }


  async onFormSubmit() {
    this.spinner.show();
    if (Boolean(await this.commonservice.checkPermission1('01.12.01.11')) == true) {
      this.currentdate.setSeconds(0);
      this.currentdate.setMinutes(0);
      this.currentdate.setHours(0);

      this.folderSummaryEditForm.controls.FOLDERDATE.value.setSeconds(0)
      this.folderSummaryEditForm.controls.FOLDERDATE.value.setMinutes(0)
      this.folderSummaryEditForm.controls.FOLDERDATE.value.setHours(0)
      this.folderdateform = (this.folderSummaryEditForm.controls.FOLDERDATE.value == null || this.folderSummaryEditForm.controls.FOLDERDATE.value == "" ? "01-Jan-1900" : this.folderSummaryEditForm.controls.FOLDERDATE.value);//Case id-337
      this.QuoteConvDate = (this.folderSummaryEditForm.controls.QCONDATE.value == null || this.folderSummaryEditForm.controls.QCONDATE.value == "" ? "01-Jan-1900" : this.folderSummaryEditForm.controls.QCONDATE.value);//Case id-337
      this.depositeDueDate = (this.folderSummaryEditForm.controls.DEPDUEDATE.value == null || this.folderSummaryEditForm.controls.DEPDUEDATE.value == "" ? "01-Jan-1900" : this.folderSummaryEditForm.controls.DEPDUEDATE.value);//Case id-337
      this.balanceDueDate = (this.folderSummaryEditForm.controls.BALDUEDATE.value == null || this.folderSummaryEditForm.controls.BALDUEDATE.value == "" ? "01-Jan-1900" : this.folderSummaryEditForm.controls.BALDUEDATE.value);//Case id-337
      this.creationDate = (this.folderSummaryEditForm.controls.CRDATE.value == null || this.folderSummaryEditForm.controls.FOLDERDATE.value == "" ? "01-Jan-1900" : this.folderSummaryEditForm.controls.CRDATE.value);//Case id-337

      if (Boolean(this.commonservice.ValidDateRange(this.folderdateform)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidDepartureDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidDepartureDate'];
          this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        this.spinner.hide();
        return;
      }
      if (Boolean(this.commonservice.ValidDateRange(this.QuoteConvDate)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidQuoteConvDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidQuoteConvDate'];
          this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        this.spinner.hide();
        return;
      }
      if (Boolean(this.commonservice.ValidDateRange(this.depositeDueDate)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidDepositDueDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidDepositDueDate'];
          this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        this.spinner.hide();
        return;
      }
      if (Boolean(this.commonservice.ValidDateRange(this.balanceDueDate)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidBalanceDueDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidBalanceDueDate'];
          this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        this.spinner.hide();
        return;
      }
      if (Boolean(this.commonservice.ValidDateRange(this.creationDate)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidCreationDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidCreationDate'];
          this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        this.spinner.hide();
        return;
      }
      var EndDate = new Date(this.folderSummaryEditForm.controls.FOLDERDATE.value.toLocaleDateString());
      var StartDate = new Date(this.currentdate.toLocaleDateString());
      if (EndDate < StartDate) {
        this.spinner.hide();
        this.folderdate = true;
      }
      else {
        this.FolderSave();
      }
    }
    else {
      this.spinner.hide();
      this.showmsg('01.12.01.11');
    }

  }
  onFolderDate(shouldRemove: boolean): void {
    if (shouldRemove) {
      this.folderdate = false;
      this.FolderSave();
    }
    else {
      this.folderdate = false;
      return;
    }
  }

  FolderSave() {
    debugger;
    if (this.orderNo != null && this.orderNo != "0") {
      //no action need
    }
    else {
      this.folderSummaryEditForm.controls.OrderType.setValue("Package");
    }
    if (this.folderSummaryEditForm.value['FC_CIDExRate'] === '' || this.folderSummaryEditForm.value['FC_CIDExRate'] === 0 || this.folderSummaryEditForm.value['FC_CIDExRate'] === null) {
      this.subscription.add(this.translateapi.get(['Messages.ExchangeRateError']).subscribe((translations) => {
        this.showMsg = translations['Messages.ExchangeRateError'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      var element = document.getElementById("Advancesearch-one");
      element.classList.remove("collapse");
      this.spinner.hide();
      return;
    }
    if (this.folderSummaryEditForm.controls.PRTYCODE.value == null || this.folderSummaryEditForm.controls.PRTYCODE.value == undefined || this.folderSummaryEditForm.controls.PRTYCODE.value == 0 || this.folderSummaryEditForm.controls.PRTYCODE.value == "") {
      this.folderSummaryEditForm.controls.PRTYCODE.setValue("");
    }
    if (this.folderSummaryEditForm.controls.PRTYCODE.value == "") {
      this.commonservice.showWARNINGtoastrmsg("Messages.specifyCustomer", 3000, true, false);
      this.spinner.hide();
      return;
    }
    this.submitted = true;

    if (!this.folderSummaryEditForm.controls.BAID.valid) {
      var element = document.getElementById("Advancesearch-one");
      element.classList.remove("collapse");
      this.commonservice.showWARNINGtoastrmsg("Messages.SetBusinessArea", 3000, true, false);
      this.spinner.hide();
      return;
    }
    if (!this.folderSummaryEditForm.controls.BRID.valid) {
      var element = document.getElementById("Advancesearch-one");
      element.classList.remove("collapse");
      this.commonservice.showWARNINGtoastrmsg("Messages.SetBranch", 3000, true, false);
      this.spinner.hide();
      return;
    }
    if (this.folderSummaryEditForm.invalid) {
      this.spinner.hide();
      return;
    }



    this.objFolderPrice
      .CreditLimitCheck("0",
        this.folderSummaryEditForm.controls.PRTYCODE.value,
        this.orderNo,
        0
      )
      .subscribe(res => {
        if (res != undefined || res["ResponseData"] != null) {
          if (
            res["ResponseData"].value != "0" &&
            res["ResponseData"].Status != "0" &&
            res["ResponseData"].ErrorMessage != ""
          ) {
            debugger;
            if (res["ResponseData"].value == 1) {
              this.msg = res["ResponseData"].ErrorMessage;
              this.spinner.hide();
              this.creditLimiFlag = true;
              this.creditlimit = true;
              this.spinner.hide();
              return false;
            } else if (res["ResponseData"].value == 2) {
              this.spinner.hide();
              this.msg = res["ResponseData"].ErrorMessage;
              this.creditLimiFlag = false;
              this.creditlimit = true;

              // this.AirTicketSaveAndUpdate();
            } else {
              this.updateFolderMaster();
            }
          } else {
            this.updateFolderMaster();
          }
        }
      });



    // alert(this.orderNo);


  }
  updateFolderMaster() {
    if (this.orderNo != null && this.orderNo != "0") {
      if (this.isShownrtl === false) {
        this.folderSummaryEditForm.controls.PPID.setValue(0);
      }

      this.spinner.show();
      debugger;

      this.subscription.add(this.api.UpdateFolderMaster(this.folderSummaryEditForm.value, this.csview)
        .subscribe(res => {
          this.spinner.hide();
          if (res != null) {
            if (res.IsSuccess === true && res.Message === 'SUCCESS') {
              this.spinner.hide();
              this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                this.showMsg = translations['Messages.saved_success'];
                this.toastr.success(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
              this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo], { skipLocationChange: true });
            }
            else {
              this.spinner.hide();
              this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                timeOut: 0,
                disableTimeOut: true,
                closeButton: true
              })
            }
          }
          else {
            this.spinner.hide();
          }
        }, (err) => {
          this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
            this.showMsg = translations['Messages.some_error_in_save'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
        }));
    }

    else {
      this.spinner.show();

      this.subscription.add(this.api.AddFolderDetails(this.folderSummaryEditForm.value, this.csview)
        .subscribe(res => {
          if (res != null) {

            if (res.IsSuccess === true && res.Message === 'SUCCESS') {
              this.orderNo = res.ResponseData.FOLDERNO
              this.projNo = res.ResponseData.PROJNO;
              this.spinner.hide();
              this.folderedit = 0;
              this.folderSummaryEditForm.controls.FOLDERNO.setValue(this.orderNo);
              this.folderSummaryEditForm.controls.PROJNO.setValue(this.projNo);
              this.apiShared.Setapishareddata(this.folderedit, 'folderReadOnly');
              this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
              this.apiShared.Setapishareddata(this.projNo, 'projNo');
              this.folderSummaryEditForm.controls.BALDUEDATE.setValue(new Date(res.ResponseData.BALDUEDATE));

              this.passengerName = "";
              this.folderStatus = "Saved";
              this.apiShared.Setapishareddata(this.folderStatus, 'FolderStatus');
              this.apiShared.Setapishareddata(this.passengerName, 'PassengerName');
              this.apiShared.Setapishareddata(this.passengerName, 'PassengerNames');
              this.layout.headerPassenger = this.orderNo;
              this.getLockDetails("Folder", this.orderNo, this.apiShared.uid, res.ResponseData.PROJNO);
              if (!this.addProduct && !this.addPass) { //Case id-367
                this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                  this.showMsg = translations['Messages.saved_success'];
                  this.toastr.success(this.showMsg, "", {
                    timeOut: 3000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }));
                this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo], { skipLocationChange: true });
              }
              else {

                this.folderSummaryEditForm.controls.STATUS.setValue(1);
                this.folderSummaryEditForm.controls.StatusName.setValue("Saved");
                this.spinner.hide();
                this.commonservice.newfolder = true;
                this.product.orderNo = this.orderNo;
                this.router.navigate(['PenAir/Folder/PackageSummaryEdit', this.orderNo], { skipLocationChange: true });


                if (this.addProduct) {
                  //setTimeout(() => {
                  this.product.showProduct = true;
                  // }, 1000);
                }
                if (this.addPass) {
                  this.ShowPassengerDetails();
                }
              }
            } else {
              this.spinner.hide();
              this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                timeOut: 0,
                disableTimeOut: true,
                closeButton: true
              })
            }
          }
          else {
            this.spinner.hide();
          }
        }, (err) => {
          console.log(err);
          this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
            this.showMsg = translations['Messages.some_error_in_save'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
        }));
    }
  }
  onReset() {
    this.submitted = false;
    this.folderSummaryEditForm.reset();
  }
  onCancel() {

    if (this.orderNo != "0") {
      this.commonservice.folderEditMode = 'false';
      this.apiShared.folderEditMode = this.commonservice.folderEditMode;
      this.apiShared.Setapishareddata(this.commonservice.folderEditMode, 'folderEditMode');
      this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo], { skipLocationChange: true });
    }

    else {

      this.orderNo = "";
      this.commonservice.folderEditMode = 'false';
      this.apiShared.folderEditMode = this.commonservice.folderEditMode;//by case id-355
      this.apiShared.Setapishareddata(this.commonservice.folderEditMode, 'folderEditMode');//by case id-355
      //this.apiShared.orderNo=this.orderNo;
      this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
      //this.router.navigate(['PenAir/Folder/Index'],{skipLocationChange:true});
      this.router.navigateByUrl(localStorage.getItem('alerturl'), { skipLocationChange: true });
      localStorage.removeItem('alerturl');
    }


  }
  getLockDetails(docType, docNo, userId, projno) {

    this.subscription.add(this.penLock.getLock(docType, docNo, userId, projno)
      .subscribe(data => {
        if (data != null)
          this.penDockLock = data;
      }));
  }
  get f() { return this.folderSummaryEditForm.controls; }


  BusinessAreaId(custco, bookedby) {
    this.subscription.add(this.api.BusinessAreaId(custco, bookedby)
      .subscribe(data => {
        if (data !== '' && data !== null && data !== undefined) {
          this.folderSummaryEditForm.controls.BAID.setValue(data);
          if (this.folderSummaryEditForm.controls.BAID.value != "" && this.folderSummaryEditForm.controls.BAID.value != null) {
            this.businessAreaSelectedItem = this.businessAreaList.find(item => item.Code === this.folderSummaryEditForm.controls.BAID.value);
          }
        }
        this.savebutton = true;
      }));
  }

  GetBranch(custco, bookedby) {
    this.subscription.add(this.api.GetBranch(custco, bookedby)
      .subscribe(data => {
        if (data !== '' && data !== null && data !== undefined) {
          this.folderSummaryEditForm.controls.BRID.setValue(data);
          if (this.folderSummaryEditForm.controls.BRID.value != "" && this.folderSummaryEditForm.controls.BRID.value != null) {
            this.branchSelectedItem = this.branchList.find(item => item.Code === this.folderSummaryEditForm.controls.BRID.value);
          }
        }
        this.savebutton = true;
      }));
  }

  GetFolderDetailsById(id) {
    this.spinner.show();

    this.subscription.add(this.api.GetFolderDetailsById(id, this.UID)
      .subscribe(data => {
        debugger;
        this.spinner.hide();
        if (data.ResponseData != null) {
          this.commonservice.PrtyCodeFolder=data.ResponseData.PRTYCODE;
          this.packagefolderno = +data.ResponseData.FOLDERNO;
          this.objFolderPrice.packagefolderno = +data.ResponseData.FOLDERNO;
          this.projNum = data.ResponseData.PROJNO;
          this.prtycode = data.ResponseData.PRTYCODE;
          this.apiShared.CustomerCode = this.prtycode;
          // console.log('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
          this.apiShared.Setapishareddata(this.prtycode, 'CustomerCode');
          this.folderSummaryEditForm.setValue({
            FOLDERNO: data.ResponseData.FOLDERNO,
            PROJNO: data.ResponseData.PROJNO,
            FCTotalBUY: data.ResponseData.FCTotalBUY,
            FCTotalCOMM: data.ResponseData.FCTotalCOMM,
            TotVAT: data.ResponseData.TotVAT,
            FCCREDITAMT: data.ResponseData.FCCREDITAMT,
            FCAGTCOMSUM: data.ResponseData.FCAGTCOMSUM,
            FCAGTCOMVAT: data.ResponseData.FCAGTCOMVAT,
            AGTCOMSUM: data.ResponseData.AGTCOMSUM,
            AGTCOMVAT: data.ResponseData.AGTCOMVAT,
            AGTCOMVATNO: data.ResponseData.AGTCOMVATNO,
            TotRECEIVABLE: data.ResponseData.TotRECEIVABLE,
            FCALREADYPAID: data.ResponseData.FCALREADYPAID,
            FCOtherCredits: data.ResponseData.FCOtherCredits,
            PRTYCODE: data.ResponseData.PRTYCODE == '0' ? '' : data.ResponseData.PRTYCODE,
            BRID: data.ResponseData.BRID,
            BAID: data.ResponseData.BAID,
            OrderType: data.ResponseData.OrderType,
            FC_CID: data.ResponseData.FC_CID,
            ARRIVALPT: data.ResponseData.ARRIVALPT,
            DESTINAION: data.ResponseData.DESTINAION,
            FOLDERDATE: data.ResponseData["FOLDERDATE"] != null ? (data.ResponseData["FOLDERDATE"].toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData["FOLDERDATE"])) : null,
            BOOKEDBY: data.ResponseData.BOOKEDBY,
            NOOFPASS: data.ResponseData.NOOFPASS,
            INETREF: data.ResponseData.INETREF,
            PONO: data.ResponseData.PONO,
            STATUS: data.ResponseData.STATUS,
            DEPDUEDATE: data.ResponseData["DEPDUEDATE"] != null ? (data.ResponseData["DEPDUEDATE"].toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData["DEPDUEDATE"])) : null,
            DEPAMT: data.ResponseData.DEPAMT,
            BALDUEDATE: data.ResponseData["BALDUEDATE"] != null ? (data.ResponseData["BALDUEDATE"].toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData["BALDUEDATE"])) : null,
            BALAMT: data.ResponseData.BALAMT,
            QCONDATE: data.ResponseData["QCONDATE"] != null ? (data.ResponseData["QCONDATE"].toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData["QCONDATE"])) : null,
            FC_CIDExRate: data.ResponseData.FC_CIDExRate,
            FCTotRECEIVABLE: data.ResponseData.FCTotRECEIVABLE,
            Receipt: data.ResponseData.Receipt,
            CRDATE: data.ResponseData["CRDATE"] != null ? (data.ResponseData["CRDATE"].toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData["CRDATE"])) : null,
            PPID: data.ResponseData.PPID,
            StatusName: data.ResponseData.StatusName,
            CUSTOMERNAME: data.ResponseData.CUSTOMERNAME,
            FM_LID: data.ResponseData.FM_LID,
            BAName: data.ResponseData.BAName,
            BrName: data.ResponseData.BrName,
            CREDITAMT: data.ResponseData.CREDITAMT,
            PDQCHARGE: data.ResponseData.PDQCHARGE,
            FM_MnlPkgCalc: data.ResponseData.FM_MnlPkgCalc,
            PACKAGED: data.ResponseData.PACKAGED,
            MCID: data.ResponseData.MCID,
            MarkupPer: data.ResponseData.MarkupPer,
            IsDirectRefund:0
          });
        }
        this.TotBUY = this.folderSummaryEditForm.controls.FCTotalBUY.value + this.folderSummaryEditForm.value['PDQCHARGE'];


        if (this.folderSummaryEditForm.controls.MCID.value != "") {
          this.MarketingCodeListSelectedItem = this.MarketingCodeList.find(item => item.Code == this.folderSummaryEditForm.controls.MCID.value);
        }

        debugger;

        if (this.folderSummaryEditForm.value['CREDITAMT'] === 0)
          this.TotCOMM = this.folderSummaryEditForm.controls.FCTotalCOMM.value - (this.folderSummaryEditForm.value['PDQCHARGE'] / this.folderSummaryEditForm.controls.FC_CIDExRate.value);
        else
          this.TotCOMM = this.folderSummaryEditForm.controls.FCTotalCOMM.value;
        if (this.TotCOMM == null || this.TotCOMM == undefined || isNaN(this.TotCOMM))
          this.TotCOMM = 0;
        if (this.folderSummaryEditForm.controls.FCAGTCOMSUM.value != null && this.folderSummaryEditForm.controls.FCAGTCOMSUM.value != undefined)
          this.AgtTotCOMM = this.TotCOMM - parseFloat(this.folderSummaryEditForm.controls.FCAGTCOMSUM.value);


        if (this.folderSummaryEditForm.controls.PPID.value > 0) {
          this.Loadpassenger();
          this.customerradioselection = false;
          this.retailradioselection = true;
          this.toggleShowRtl();
        }
        else {
          this.customerradioselection = true;
          this.retailradioselection = false;
          this.toggleShow();
        }
      

        if (this.folderSummaryEditForm.controls.BAID.value != "") {

          this.businessAreaSelectedItem = this.businessAreaList.find(item => item.Code == this.folderSummaryEditForm.controls.BAID.value);

          //console.log("test:-"+this.folderSummaryEditForm.controls.BAID.value+JSON.stringify(this.businessAreaSelectedItem , null, 4));
        }
        if (this.folderSummaryEditForm.controls.BRID.value != "") {

          this.branchSelectedItem = this.branchList.find(item => item.UsrCode == this.folderSummaryEditForm.controls.BRID.value);

          // console.log("test:-"+this.folderSummaryEditForm.controls.BRID.value+JSON.stringify(this.branchSelectedItem , null, 4));
        }
        if (this.folderSummaryEditForm.controls.OrderType.value != "") {

          this.orderTypeSelectedItem = this.orderTypeList.find(item => item.UsrCode == this.folderSummaryEditForm.controls.OrderType.value);
          if (this.folderSummaryEditForm.controls.OrderType.value === 'Package') {
            this.package = true;
            this.folderSummaryEditForm.controls.PACKAGED.setValue(1);
          } else {
            this.package = false;
            this.folderSummaryEditForm.controls.PACKAGED.setValue(0);
          }
          // console.log("test:-"+this.folderSummaryEditForm.controls.OrderType.value+JSON.stringify(this.orderTypeSelectedItem , null, 4));
        }
        if (this.folderSummaryEditForm.controls.FC_CID.value != "") {

          this.currencySelectedItem = this.currencyList.find(item => item.Code == this.folderSummaryEditForm.controls.FC_CID.value);
          this.getCurrencyExRate();

        }

        if (this.folderSummaryEditForm.controls.BOOKEDBY.value != "") {
          this.bookbyselectedItem = this.BOOKEBYList.find(item => item.Name == this.folderSummaryEditForm.controls.BOOKEDBY.value);
        }
        if (this.folderSummaryEditForm.controls.PRTYCODE.value != "") {
          this.customerSelectedItem = this.CustomerList.find(item => item.Code == this.folderSummaryEditForm.controls.PRTYCODE.value);
          if (this.customerSelectedItem != null)
            this.customerSelected = this.customerSelectedItem.Name;
          else
            this.customerSelected = data.ResponseData.CUSTOMERNAME;
        }
        if (this.folderSummaryEditForm.controls.ARRIVALPT.value != "") {
          this.destinationSelectedItem = this.destinationList.find(item => item.Code == this.folderSummaryEditForm.controls.ARRIVALPT.value);
          if (this.destinationSelectedItem != null)
            this.destinationSelected = this.destinationSelectedItem.Name;
        }
        if (this.folderSummaryEditForm.controls.PPID.value != 0) {
          this.passengerSelectedItem = this.passengerList.find(item => item.Code == this.folderSummaryEditForm.controls.PPID.value);
          if (this.passengerSelectedItem != null)
            this.passengerSelected = this.passengerSelectedItem.Name;
        }
        if (this.folderSummaryEditForm.controls.FM_LID.value != "") {
          this.LanguageSelectedItem = this.LanguageList.find(item => item.UsrCode == this.folderSummaryEditForm.controls.FM_LID.value);
        }
        if (this.folderSummaryEditForm.controls.FM_MnlPkgCalc.value === 1) {
          this.manualCalculation = true;
        }

        this.sell = this.folderSummaryEditForm.controls.FCTotalBUY.value + this.folderSummaryEditForm.controls.FCTotalCOMM.value;
        this.totalReceivable = (this.folderSummaryEditForm.controls.FCTotRECEIVABLE.value + this.folderSummaryEditForm.controls.TotVAT.value) - (this.folderSummaryEditForm.controls.AGTCOMSUM.value + this.folderSummaryEditForm.controls.AGTCOMVAT.value);
        this.balance = this.totalReceivable - this.folderSummaryEditForm.controls.FCALREADYPAID.value - this.folderSummaryEditForm.controls.FCOtherCredits.value;
        this.folderSummaryEditForm.controls.BALAMT.setValue(this.balance);

        debugger;
        this.TotalValue.FCAGTCOMSUM = this.folderSummaryEditForm.controls.FCAGTCOMSUM.value;
        this.TotalValue.FCAGTCOMVAT = this.folderSummaryEditForm.controls.FCAGTCOMVAT.value;
        this.TotalValue.AGTCOMVATNO = this.folderSummaryEditForm.controls.AGTCOMVATNO.value;
        this.TotalValue.FCAGTCOM = this.TotalValue.FCAGTCOMSUM + this.TotalValue.FCAGTCOMVAT;
        this.spinner.hide();
        this.loadDestination();
        this.loadsuppliermaster();
      }));
  }
  loadsuppliermaster() {
    if (this.folderSummaryEditForm.controls.PRTYCODE.value != '' && this.folderSummaryEditForm.controls.PRTYCODE.value != null) {

      this.subscription.add(this.lookupApi.findCustomer(this.folderSummaryEditForm.controls.PRTYCODE.value).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.CustomerList = data["ResponseData"];
            if (this.folderSummaryEditForm.controls.PRTYCODE.value != "") {

              this.customerSelectedItem = this.CustomerList.find(item => item.Code == this.folderSummaryEditForm.controls.PRTYCODE.value);
              if (this.customerSelectedItem != null) {
                this.customerSelected = this.customerSelectedItem.Name;
              }

            }
          }

        }
      ));
    }
  }
  handleFiltercustomer(value) {
    debugger;
    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.SearchCustomer(value, this.bkdID).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.CustomerList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletecustomer.toggle(false);
    }
  }
  public CustomerValueChange(value: any): void {
    debugger;
    if (this.customerSelectedItem != undefined && this.customerSelectedItem.Status != 1) {
      this.customerSelectedItem = this.CustomerList.find(item => item.Name == value);
    }
    if (this.customerSelectedItem == null) {
      this.customerSelectedItem = this.CustomerList.find(item => item.Name == value);
    }


    if (this.customerSelectedItem != null) {
      this.savebutton = false;
      this.prtycode = this.customerSelectedItem.Code;
      // this.apiShared.CustomerCode=this.prtycode;
      this.apiShared.Setapishareddata(this.prtycode, 'CustomerCode');
      this.folderSummaryEditForm.controls.PRTYCODE.setValue(this.customerSelectedItem.Code);
      this.customerSelected = this.customerSelectedItem.Name;
      this.folderSummaryEditForm.controls.CUSTOMERNAME.setValue(this.customerSelectedItem.Name);
      this.BusinessAreaId(this.customerSelectedItem.Code, this.folderSummaryEditForm.controls.BOOKEDBY.value);
      this.GetBranch(this.customerSelectedItem.Code, this.folderSummaryEditForm.controls.BOOKEDBY.value);
      if (this.customerSelectedItem.LID != null && this.customerSelectedItem.LID != undefined) {
        this.LanguageSelectedItem = this.LanguageList.find(item => item.UsrCode == this.customerSelectedItem.LID);
        this.LanguageValueChange(this.LanguageSelectedItem);
      }
    }
    else {
      this.customerSelected = "";
      this.folderSummaryEditForm.controls.PRTYCODE.setValue("");
    }
    // this.carEditForm.get("suppUserCodetext").setValue( this.supplierSelectedItem.Name);
  }
  public passengerValueChange(value: any): void {
    debugger;
    this.passengerSelectedItem = this.passengerList.find(item => item.Name == value);

    if (this.passengerSelectedItem != null) {

      this.folderSummaryEditForm.controls.PPID.setValue(Number(this.passengerSelectedItem.Code));
      this.folderSummaryEditForm.controls.PRTYCODE.setValue(this.passengerSelectedItem.ActualName);//Passenger custco taken in Actual Name field
    }
    else {
      this.passengerSelected = "";
      this.folderSummaryEditForm.controls.PPID.setValue(0);
      this.folderSummaryEditForm.controls.PRTYCODE.setValue("");
    }
    // this.carEditForm.get("suppUserCodetext").setValue( this.supplierSelectedItem.Name);
  }

  public BranchValueChange(value: any): void {
    //console.log('selectionChange', value);


    if (value != null && value != undefined)
      this.folderSummaryEditForm.controls.BRID.setValue(value.UsrCode);
    else
      this.folderSummaryEditForm.controls.BRID.setValue("");
  }
  public BusinessAreaValueChange(value: any): void {
    // console.log('selectionChange', value);

    if (value != null && value != undefined)
      this.folderSummaryEditForm.controls.BAID.setValue(value.UsrCode);
    else
      this.folderSummaryEditForm.controls.BAID.setValue("");

  }
  public LanguageValueChange(value: any): void {
    // console.log('selectionChange', value);

    if (value != null && value != undefined)
      this.folderSummaryEditForm.controls.FM_LID.setValue(value.UsrCode);
    else
      this.folderSummaryEditForm.controls.FM_LID.setValue("");

  }
  public CurrencyValueChange(value: any): void {
    //  console.log('selectionChange', value);

    if (value != null && value != undefined) {
      this.folderSummaryEditForm.controls.FC_CID.setValue(value.UsrCode);
      this.apiShared.Setapishareddata(value.UsrCode, 'folderCurrency');
      this.getCurrencyExRate()
    }
    else {
      this.folderSummaryEditForm.controls.FC_CID.setValue("");
    }
  }
  public BookedByValueChange(value: any): void {
    //console.log('selectionChange', value);


    if (value != null && value != undefined) {
      this.bkdID = +(value.Code);
      this.folderSummaryEditForm.controls.BOOKEDBY.setValue(value.Name);
      this.BusinessAreaId(0, this.folderSummaryEditForm.controls.BOOKEDBY.value);
      this.GetBranch(0, this.folderSummaryEditForm.controls.BOOKEDBY.value);
    }
    else
      this.folderSummaryEditForm.controls.BOOKEDBY.setValue("");
  }

  public DestinationValueChange(value: any): void {

    this.destinationSelectedItem = this.destinationList.find(item => item.Name == value);

    if (this.destinationSelectedItem != null) {
      this.folderSummaryEditForm.controls.ARRIVALPT.setValue(this.destinationSelectedItem.Code);
      this.folderSummaryEditForm.controls.DESTINAION.setValue(this.destinationSelectedItem.ActualName);
    }
    else {
      this.destinationSelected = "";
      this.folderSummaryEditForm.controls.ARRIVALPT.setValue("");
      this.folderSummaryEditForm.controls.DESTINAION.setValue("");
    }

  }

  public OrderTypeChange(value: any): void {
    console.log('selectionChange', value);

    if (value != undefined) {
      this.folderSummaryEditForm.controls.OrderType.setValue(value.UsrCode);
      if (value.UsrCode == 'Package') {
        this.folderSummaryEditForm.controls.PACKAGED.setValue(1);
        this.package = true;
      }
      else {
        this.folderSummaryEditForm.controls.PACKAGED.setValue(0);
        this.package = false;
      }

    }
    else {
      this.folderSummaryEditForm.controls.PACKAGED.setValue(0);
      this.package = false;
    }
  }

  public getCurrencyExRate() {

    this.creationDate = (this.folderSummaryEditForm.controls.CRDATE.value == null || this.folderSummaryEditForm.controls.FOLDERDATE.value == "" ? "01-Jan-1900" : this.folderSummaryEditForm.controls.CRDATE.value);//Case id-337
    if (Boolean(this.commonservice.ValidDateRange(this.creationDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidCreationDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidCreationDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }

    this.getExchangeRate(this.folderSummaryEditForm.controls.FC_CID.value, this.creationDate)

  }
  getExchangeRate(currency, CRDATE) {
    if (currency != undefined && currency != "") {
      this.savebutton = false;
      CRDATE = this.commonservice.ConvertToLocaleDate(CRDATE);

      // new Date(CRDATE).toLocaleDateString('en-GB', {
      //   day: 'numeric', month: 'short', year: 'numeric'
      // }).replace(/ /g, '-');


      // console.log("Crdate="+CRDATE);

      this.subscription.add(this.apiExRate.getExchangeRate(currency, CRDATE)
        .subscribe(data => {
          this.currencyExrate = data;
          // console.log(this.currencyExrate.ResponseData.EXRATE);
          this.folderSummaryEditForm.controls.FC_CIDExRate.setValue(this.currencyExrate.ResponseData.EXRATE);
          this.apiShared.Setapishareddata(this.currencyExrate.ResponseData.EXRATE, 'folderExchangeRate');
          this.savebutton = true;
        }));
    }
  }
  isShown: boolean = true; // hidden by default
  isShownrtl: boolean = false;

  public toggleShow() {

    this.isShownrtl = false;
    this.isShown = true;
  }
  public toggleShowRtl() {

    this.isShown = false;
    this.isShownrtl = true;
  }
  public close(status) {
    this.addCustOpened = false;
    this.openSearch = false;
    this.addPassOpened = false;
  }

  public open(type) {
    if (type == 'CUSTOMER')
      this.addCustOpened = true;
    else if (type == 'PASSENGER')
      this.addPassOpened = true;
  }
  public dattobechecked: any;
  opencsfield() {

    let sectionInnerButton = document.getElementById("section-inner__button");

    if (sectionInnerButton == null) {
      console.log("sectionInnerButton is null")
    };

    sectionInnerButton.onclick = function (event) {
      var sectionInner = document.getElementById("pa-section-inner");

      sectionInner.classList.toggle("open");
    };
  }
  onCheckboxChange() {
    if (this.manualCalculation === true) {
      this.folderSummaryEditForm.controls.FM_MnlPkgCalc.setValue(1);
    }
    else {
      this.folderSummaryEditForm.controls.FM_MnlPkgCalc.setValue(0);
    }
  }
  ngOnDestroy() {
    this.commonservice.newfolder = false;
    this.spinner.hide();
    this.subscription.unsubscribe();
  }
  public CalculateAgentComm() {
    debugger;
    this.submitted = false;
    this.folderdate = false;
    if (this.TotalValue.AGTCOMVATNO !== 0 && this.TotalValue.AGTCOMVATNO !== null) {
      this.AGTaxselectedItem = this.Tax.find(item => item.Code == this.TotalValue.AGTCOMVATNO);
      if (this.AGTaxselectedItem != null && this.AGTaxselectedItem != undefined)
        this.AgentCommission.AGTCOMVATRate = this.AGTaxselectedItem.UsrCode;
    }

    this.AgentCommission.FCAGTCOM = this.TotalValue.FCAGTCOM;
    this.AgentCommission.FCAGTCOMSUM = this.TotalValue.FCAGTCOMSUM,
      this.AgentCommission.FCAGTCOMVAT = this.TotalValue.FCAGTCOMVAT;
    this.agentComm = true;

  }

  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339

  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

  showmsg(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }

  ShowPassengerDetails() {
    if (this.orderNo != "0") {
      this.addPass = false;
      //this.showPass=true;
      this.showPass = !this.showPass;
    }
    else {
      this.addPass = true;
      this.onFormSubmit();
    }
  }

  SavePackageSummary(e) {

    if (e == 'true') {
      this.addProduct = true;
      this.onFormSubmit();
    }
  }

  public showCalculation(type) {
    debugger;
    this.commDetail.type = type;
    if (type == 'FC') {
      this.commDetail.currency = String(this.folderSummaryEditForm.controls.FC_CID.value);
    }
    this.objCommCalculation = Object.assign({}, this.folderSummaryEditForm.value);
    this.showCalc = true;
  }
  isSticky: boolean = false;
  @HostListener('window:scroll')
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }
  ConfirmMessage() {
    if (this.creditLimiFlag == false) {
      this.creditlimit = false;
      this.updateFolderMaster();

    } else {
      this.creditlimit = false;
    }
  }
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }
  getPassengerMaster(ppid) {
    this.folderSummaryEditForm.controls.PPID.setValue(ppid);
    this.addPassOpened = false;
    this.getpassengerById();
  }
  getpassengerById() {
    if (this.folderSummaryEditForm.controls.PPID.value != '' && this.folderSummaryEditForm.controls.PPID.value != null) {

      this.subscription.add(this.lookupApi.findPassenger(this.folderSummaryEditForm.controls.PPID.value).subscribe(
        (data) => {
          this.spinner.hide();
          if (data["ResponseData"] != null) {
            this.passengerList = data["ResponseData"];
            if (this.folderSummaryEditForm.controls.PPID.value != "") {

              this.passengerSelectedItem = this.passengerList.find(item => item.Code == this.folderSummaryEditForm.controls.PPID.value);
              if (this.passengerSelectedItem != null) {
                this.passengerSelected = this.passengerSelectedItem.Name;
                this.folderSummaryEditForm.controls.PRTYCODE.setValue(this.passengerSelectedItem.ActualName);//Passenger custco taken in Actual Name field
              }
              else
              {
                this.passengerSelected = "";
      this.folderSummaryEditForm.controls.PPID.setValue(0);
      this.folderSummaryEditForm.controls.PRTYCODE.setValue("");
              }

            }
          }

        }
      ));
    }
  }
  public GetCurrencyExchangeRateTolerance() {
    debugger;
    this.creationDate = (this.folderSummaryEditForm.controls.CRDATE.value == null || this.folderSummaryEditForm.controls.FOLDERDATE.value == "" ? "01-Jan-1900" : this.folderSummaryEditForm.controls.CRDATE.value);//Case id-337
    this.creationDate = this.commonservice.ConvertToLocaleDate(this.creationDate);
    if (this.currencySelectedItem != undefined) {
      this.spinner.show();
      this.cashservice.formData = new CashPaymentDTO();

      this.cashservice.formData.PVRDATE = this.commonservice.ConvertToLocaleDate(new Date(this.creationDate));
      this.cashservice.formData.date = this.commonservice.ConvertToLocaleDate(new Date())
      this.cashservice.formData.HCID = this.currencySelectedItem.UsrCode;
      this.cashservice.formData.FCID = this.currencySelectedItem.UsrCode;
      this.cashservice.formData.RXRATE = this.folderSummaryEditForm.controls.FC_CIDExRate.value;
      this.cashservice.formData.LANQUAGE = this.apiShared.LanguageSelected;
      this.subscription.add(this.cashservice.GetCurrencyExchangeRateTolerance().subscribe(x => {
        debugger;
        this.spinner.hide();
        if (x != null && x != undefined) {
          this.spinner.hide();
          if (x["ResponseData"].messageid == 0) {
            this.folderSummaryEditForm.controls.FC_CIDExRate.setValue(x["ResponseData"].RXRATE);
          }
          else if (x["ResponseData"].messageid > 0) {
            var msg = 'Messages.msg' + x["ResponseData"].messageid;
            this.commonservice.showWARNINGtoastrmsg(x["ResponseData"].message, 3000, true, false);
            this.getCurrencyExRate();
          }
        }
      }));
    } else {
      this.commonservice.showWARNINGtoastrmsg('Messages.invalid_currency', 3000, true, false);
      this.CurrencyValueChange(null);
    }



  }
  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.close('close')
        this.customerSelectedItem = value;
        this.CustomerValueChange(value.Name)
      },
      closemodalpopup: () => {

        this.close('close')

      }
    }
  }
}
