import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PopupAnchorDirective } from './PopupAnchorDirective';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { GridDataResult, PageChangeEvent, SelectAllCheckboxState, RowArgs, DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AddExpenseDTO } from '../../Models/add-expense-dto';
import { LookUpDetails } from '../../Services/look-up-details';
import { CommonserviceService } from '../../Services/commonservice.service';
import { CurrencyExchangerateService } from '../../Services/currency-exchangerate.service';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { OthersService } from '../../Services/others.service';
import { ShareDataService } from '../../Services/share-data.service';
@Component({
  selector: 'app-expense-add',
  templateUrl: './expense-add.component.html',
  styleUrls: ['./expense-add.component.scss']
})

export class ExpenseAddComponent implements OnInit {
  @ViewChild('autocompletesupplier', { read: false, static: false }) public autocompletesupp: AutoCompleteComponent;
  private subscription: Subscription = new Subscription();
  @Input() orderNo: string;
  @Output() closeExpense: EventEmitter<string> = new EventEmitter();
  addExpenseForm: FormGroup;
  public gridData: any = [];
  public categories: any[]
  public formGroup: FormGroup;
  private editedRowIndex: number;
  TaxList: Array<LookUpDetails["ResponseData"]> = [];
  public TaxselectedItem: LookUpDetails["ResponseData"];
  ProductList: Array<LookUpDetails["ResponseData"]> = [];
  public productSelectedItem: LookUpDetails["ResponseData"];
  currencyList: Array<LookUpDetails["ResponseData"]> = [];
  public currencySelectedItem: LookUpDetails["ResponseData"];
  public currencySelected: string;

  SupplierList: Array<LookUpDetails['ResponseData']> = [];
  public supplierSelectedItem: LookUpDetails['ResponseData'];
  public supplierSelected: string;
  enableCheck = false;
  exRate: number;
  public formGroupOther: FormGroup;
  showHC = false;
  homeCurrency: any;
  submitted = false;
  showMsg: any;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  constructor(private formBuilder: FormBuilder, private lookupApi: LookUpDetailsService, private apiExRate: CurrencyExchangerateService,
    public commonservice: CommonserviceService, private others: OthersService, private apiShared: ShareDataService, private spinner: NgxSpinnerService
    , private translateapi: TranslateService, private toastr: ToastrService) { }

  public ngOnInit(): void {
    this.addExpenseForm = this.formBuilder.group({
      OrderNo: this.orderNo,
      ProductUserCode: ['', Validators.required],
      CurrencyId: 0,
      ExchangeRate: 0,
      TCAmount: 0,
      SupplierUserCode: ['', Validators.required],
      VATCode: 0,
      BookingDate: new Date,
      PrintonInvoice: 0,
      TAXAmount: 0
    });
    this.exRate = this.apiShared.hcExRate ? +this.apiShared.hcExRate : 1;
    this.homeCurrency = this.apiShared.homeCurrency;
    this.subscription.add(this.lookupApi.GetAllLookup('TAX').subscribe(
      (data) => {
        if (data !== null && data !== undefined) {
          this.TaxList = data["ResponseData"];
        }
      }));
    this.subscription.add(this.lookupApi.GetAllLookup('PRODUCTS').subscribe(
      (data) => {
        if (data !== null && data !== undefined) {
          this.ProductList = data["ResponseData"];
        }
      }));
    this.subscription.add(this.lookupApi.GetAllLookup('CURRENCY').subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.currencyList = data["ResponseData"]
          if (this.homeCurrency != undefined && this.homeCurrency != "") {
            this.currencySelectedItem = this.currencyList.find(item => item.Code == this.homeCurrency);
            this.addExpenseForm.controls.CurrencyId.setValue(this.currencySelectedItem.Code);
            this.addExpenseForm.controls.ExchangeRate.setValue(this.exRate);
          }
        }
      }));
    this.loaddata();
  }
  public addExpense = new AddExpenseDTO;
  loaddata() {
    this.addExpense = new AddExpenseDTO;
    this.addExpense.ProductCode = null;
    this.addExpense.TCAmount = 0;
    this.addExpense.TaxCode = 0;
    this.addExpense.TaxAmount = 0;
    this.addExpense.Total = 0;
    this.addExpense.HCAmount = 0;
    this.addExpense.HCTaxAmount = 0;

    this.gridData.push(this.addExpense);
  }
  public productChange(value: any, dataItem): void {
    debugger;
    if (value != null && value != undefined) {
      this.productSelectedItem = this.ProductList.find(item => item.Code == value.Code);
      dataItem.ProductCode = this.productSelectedItem.UsrCode;
      this.addExpenseForm.controls.ProductUserCode.setValue(dataItem.ProductCode);
    }
    else {
      dataItem.ProductCode = null;
      this.addExpenseForm.controls.ProductUserCode.setValue(dataItem.ProductCode);
    }
  }
  handleFiltersupplier(value) {
    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          this.SupplierList = data["ResponseData"];
        }
      ));
    } else {
      this.autocompletesupp.toggle(false);
    }
  }
  checkboxChange() {
    this.enableCheck == true;
    if (this.enableCheck == true) {
      this.addExpenseForm.controls.PrintonInvoice.setValue(1);
    }
    else {
      this.addExpenseForm.controls.PrintonInvoice.setValue(0);
    }
  }
  public CurrencyValueChange(value: any): void {
    //  console.log('selectionChange', value);
    if (value != null && value != undefined) {
      this.addExpenseForm.controls.CurrencyId.setValue(value.UsrCode);
      this.getExchangeRate(value.UsrCode, new Date());
    }
    else {
      this.addExpenseForm.controls.CurrencyId.setValue("");
    }
  }
  getCurrencyExRate() {
    this.getExchangeRate(this.addExpenseForm.controls.CurrencyId.value, new Date());
  }
  getExchangeRate(currency, CRDATE) {
    if (currency != undefined && currency != "") {
      //this.savebutton=false;
      CRDATE = new Date(CRDATE).toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, '-');
      this.subscription.add(this.apiExRate.getExchangeRate(currency, CRDATE)
        .subscribe(data => {
          this.exRate = +data.ResponseData.EXRATE;
          this.addExpenseForm.controls.ExchangeRate.setValue(data.ResponseData.EXRATE);
          this.calculateTotal(this.gridData[0]);
          //this.savebutton=true;
        }))
    }
  }
  public TaxChange(value: any, dataItem): void {
    debugger;
    if (value == '') {
      dataItem.TaxCode = 0;
      dataItem.TaxAmount = null;
    }
    else {
      this.TaxselectedItem = this.TaxList.find(item => item.Code == value.Code);
      dataItem.TaxCode = this.TaxselectedItem.Code;
      dataItem.TaxAmount = Math.round(((this.TaxselectedItem ? +this.TaxselectedItem.UsrCode : 1) / 100 * (+dataItem.TCAmount > 0 ? +dataItem.TCAmount : 0) + Number.EPSILON) * 100) / 100;
      //dataItem.TaxAmount = (this.TaxselectedItem ? +this.TaxselectedItem.UsrCode : 0 * (+dataItem.TCAmount > 0 ? +dataItem.TCAmount : 0)) / 100;
      this.calculateTotal(dataItem);
    }
  }

  TCAMTp: any;
  TaxAMTp: any;
  valuechange(TCAMTp: any, TaxAMTp, dataItem): void {
    debugger;
    if (TCAMTp != undefined) {
      dataItem.TCAmount = TCAMTp.value;
      dataItem.TaxAmount = Math.round(((this.TaxselectedItem ? +this.TaxselectedItem.UsrCode : 0) / 100 * (+dataItem.TCAmount > 0 ? +dataItem.TCAmount : 0) + Number.EPSILON) * 100) / 100;

      //dataItem.TaxAmount = (+this.TaxselectedItem.UsrCode * (+dataItem.TCAmount > 0 ? +dataItem.TCAmount : 0)) / 100;
    }
    if (TaxAMTp != undefined) {
      dataItem.TaxAmount = TaxAMTp.value;
    }
    this.calculateTotal(dataItem);
  }
  calculateTotal(dataItem) {
    dataItem.Total = dataItem.TCAmount + dataItem.TaxAmount;
    dataItem.HCAmount = Math.round((dataItem.TCAmount * this.exRate + Number.EPSILON) * 100) / 100;
    //dataItem.HCTaxAmount = Math.fround(dataItem.TaxAmount * this.exRate);
    dataItem.HCTaxAmount = Math.round((dataItem.TaxAmount * this.exRate + Number.EPSILON) * 100) / 100;
  }
  public SupplierValueChange(value: any): void {
    this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);
    if (this.supplierSelectedItem != null) {
      this.addExpenseForm.controls.SupplierUserCode.setValue(this.supplierSelectedItem.UsrCode);
    } else {
      this.supplierSelected = '';
      this.addExpenseForm.controls.SupplierUserCode.setValue('');
    }
  }
  get f() { return this.addExpenseForm.controls; }
  onSaveClick() {
    if (this.addExpenseForm.value['ExchangeRate'] === '' || this.addExpenseForm.value['ExchangeRate'] === 0 || this.addExpenseForm.value['ExchangeRate'] === null) {
      this.subscription.add(this.translateapi.get(['Messages.ExchangeRateError']).subscribe((translations) => {
        this.showMsg = translations['Messages.ExchangeRateError'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      return;
    }
    if (this.gridData.length > 0) {
      this.submitted = true;
      this.addExpenseForm.controls.ProductUserCode.setValue(this.gridData[0].ProductCode);
      this.addExpenseForm.controls.TCAmount.setValue(this.gridData[0].TCAmount);
      this.addExpenseForm.controls.VATCode.setValue(this.gridData[0].TaxCode);
      this.addExpenseForm.controls.TAXAmount.setValue(this.gridData[0].TaxAmount);
      if (this.addExpenseForm.invalid) {
        return;
      }
      this.spinner.show();
      let arr=[];
      arr.push(this.addExpenseForm.value);
      this.subscription.add(this.others.addExpense(arr)
        .subscribe(res => {
          this.spinner.hide();
          if (res.ResponseData[0].StatusCode === 432) {
            this.closeExpense.emit('reload');
            this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
              this.showMsg = translations['Messages.saved_success'];
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
            }));
          }
          else {
            this.showMsg = res.ResponseData[0].Status;
            this.toastr.error(this.showMsg, "", {
              timeOut: 4000,
              disableTimeOut: false,
              closeButton: true
            })
          }
        }, (err) => {
          this.closeExpense.emit('true');
        }));
    } else {
      return;
    }
  }
  cancelClick() {
    this.closeExpense.emit('true');
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
