import { Component, OnInit, Input, Output, ViewChild, EventEmitter, Renderer2, NgZone, AfterViewInit, OnDestroy, RendererFactory2 } from '@angular/core';

import { FolderpriceService } from '../../Services/folderprice.service';
import { ProductList, RefundProductList, RefundTotalProductList } from '../../Models/product-list';
import { ActivatedRoute, Router } from '@angular/router';
import { PageChangeEvent, SelectAllCheckboxState, RowArgs, DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { HotelService } from '../../Services/hotel.service';
import { AirsegDetail } from '../../Models/airseg-detail';
import { RailsegDetail } from '../../Models/railseg-detail';
import { AirsegService } from '../../Services/airseg.service';
import { RailsegService } from '../../Services/railseg.service';
import { FolderProductTotal, HCFolderProductTotal } from '../../Models/folder-product-total';
import { FolderproducttotalService } from '../../Services/folderproducttotal.service';
import { CarService } from '../../Services/car.service';
import { AirticketService } from '../../Services/airticket.service';
import { RailService } from '../../Services/rail.service';
import { CruiseService } from '../../Services/cruise.service';
import { TourService } from '../../Services/tour.service';
import { OthersService } from '../../Services/others.service';
import { InsuranceService } from '../../Services/insurance.service';
import { TransfersService } from '../../Services/transfers.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ShareDataService } from '../../Services/share-data.service';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '../../Services/file.service';
import { ToastrService } from 'ngx-toastr';
import { FolderRefundItineraryDTO, FolderRefundDTO } from '../../Models/folder-refund-itinerary-dto.model';
import { DetailsComponent } from 'src/app/folder/details/details.component';
import { PendoclockService } from '../../Services/pendoclock.service';
import { Pendoclock } from '../../Models/pendoclock';
import { PublicLayoutComponent } from '../../public-layout/public-layout.component';
import { CommonserviceService } from '../../Services/commonservice.service';
import { SavedFolderDelete } from '../../Models/saved-folder-delete';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { aggregateBy, State, process } from '@progress/kendo-data-query';
import { IntlService } from '@progress/kendo-angular-intl';
import { AutoCompleteComponent, ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { FolderSummaryComponent } from '../../folder/folder-summary/folder-summary.component';
import { take, tap } from 'rxjs/operators';
import { DetailedProductGridComponent } from '../detailed-product-grid/detailed-product-grid.component';
import { TaxMasterDetailsDTO } from '../../Models/manual-sales-dto';





const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};
const tableRow = node => node.tagName.toLowerCase() === "tr";
const createFormGroupOther = (dataItem) =>
  new FormGroup({

    TYPE: new FormControl(dataItem.TYPE),
    PMName: new FormControl(dataItem.PMName),
    TITLE: new FormControl(dataItem.TITLE),
    BUYAMT: new FormControl(dataItem.BUYAMT),
    COMMAMT: new FormControl(dataItem.COMMAMT),
    SELLAMT: new FormControl(dataItem.SELLAMT),
    TicketDate: new FormControl(dataItem.TicketDate),
    PACKDES: new FormControl(dataItem.PACKDES),
    BookingDate: new FormControl(dataItem.BookingDate != null ? (dataItem.BookingDate.toString() == "1900-01-01T00:00:00" ? null : new Date(dataItem.BookingDate)) : null),
    FOLDERNO: new FormControl(dataItem.FOLDERNO),
    Invaliddate: new FormControl(),
    SLNO: new FormControl(dataItem.SLNO),
    Supplier: new FormControl(dataItem.Supplier),
    RefNo: new FormControl(dataItem.RefNo),
    TicketNo: new FormControl(dataItem.TicketNo),
    SUPPPRTYCODE: new FormControl(dataItem.SUPPPRTYCODE),
    ITEMNO: new FormControl(dataItem.ITEMNO),
    HCBUYAMT: new FormControl(dataItem.HCBUYAMT),
    HCSellAMT: new FormControl(dataItem.HCSellAMT),
    PO_FC_CID: new FormControl(dataItem.PO_FC_CID),
    FCID: new FormControl(dataItem.FCID),
    TTip: new FormControl(false)
  });
@Component({
  selector: 'app-product-package',
  templateUrl: './product-package.component.html',
  styleUrls: ['./product-package.component.scss']
})
export class ProductPackageComponent implements OnInit {
  isAll: any = true;
  @ViewChild(DetailedProductGridComponent) child !: any;
  private currentSubscription: Subscription;
  private currentProducrSubscription: Subscription;
  private currentrailsegscription: Subscription;
  @Input() Folder: string;
  @Input() folderStatussavepage: any;
  @Input() pdtOrderNo: string;
  @Input() MarkupPer: any;
  @Input() FCTotalCOMM: any;
  @Output() newFolder: EventEmitter<string> = new EventEmitter();
  @Output() folderReload: EventEmitter<string> = new EventEmitter();
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild('autocompletesupplier1') public autocompletesupplier1: AutoCompleteComponent;
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;

  @Input() notifier: Subject<boolean>;
  @ViewChild('RefNos', { read: true }) refs;
  @ViewChild('productsGrid')
  private grid: GridComponent;
  @ViewChild('productsGrid1') public grid1: GridComponent;
  @ViewChild('productsList') private productsList: ComboBoxComponent;
  public maxlength = 3;

  public aggregates: any[] = [{ field: 'FC_Sell', aggregate: 'sum' }, { field: 'SELLAMT', aggregate: 'sum' },
  { field: 'FC_Buy', aggregate: 'sum' }, { field: 'BUYAMT', aggregate: 'sum' },
  { field: 'FC_Comm', aggregate: 'sum' }, { field: 'COMMAMT', aggregate: 'sum' },
  { field: 'FCVAT', aggregate: 'sum' }, { field: 'VAT', aggregate: 'sum' },
  { field: 'FC_Payable', aggregate: 'sum' }, { field: 'PAYABLEAMT', aggregate: 'sum' }]

  public supplierSelectedItem: LookUpDetails["ResponseData"];
  private subscription: Subscription = new Subscription();
  public SupplierSelectedItem: LookUpDetails["ResponseData"];
  public SupplierSelected: String;
  selecttextmarkup: any = 0
  isfolder = false;
  isfinalsaved: any = false;
  lockStatus: any;
  folderStatus: any;
  modifyproductorder: any = false;
  saveRefundbutton = false;
  public formeditable = true;
  public FolderHideStatus = true;
  public FolderFinalSaveHideStatus = true;
  public foldersaved = false;
  public showcheckbox = false;
  Markupdata: any = 0;
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public disableRefundbutton = false;
  public items: FolderRefundItineraryDTO[] = [];
  public mySelection: number[] = [];
  folderproductotal: FolderProductTotal;
  hcfolderproductotal: HCFolderProductTotal;
  butDisabled: boolean = false;
  public productToRemove: any;
  public labelchange = false;
  public removeProductConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  public airToRemove: any;
  public removeAirConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  public railToRemove: any;
  public removeRailConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  public confirmMsg: any;
  public deletefolder = false;
  public agentComm = false;
  public showMsg: any;
  folderReadOnly: any;
  public buy: number = 0.00;
  public comm: number = 0.00;
  public sell: number = 0.00;
  public totalTax: number = 0.00;


  public HCbuy: number = 0.00;
  public HCcomm: number = 0.00;
  public HCsell: number = 0.00;


  public HCalreadyPaid: number = 0.00;
  public HCotherCredits: number = 0.00;

  public HCCREDITAMT: number = 0.00;



  folderexgrate: any = 0.00;
  public totalRecievable: number = 0.00;
  public alreadyPaid: number = 0.00;
  public otherCredits: number = 0.00;
  public balance: number = 0.00;
  public fcsellStat: number = 0.00;
  public fcsellCustCard: number = 0.00;
  public fcselldefault: number = 0.00;
  public fcbuyStat: number = 0.00;
  public fcbuyCustCard: number = 0.00;
  public fcbuydefault: number = 0.00;
  public packageSell: number = 0.00;
  public FCCREDITAMT: number = 0.00;
  Products = '';
  showProduct = false;//By case id-12621
  public productItems: any[] = [
    { Name: '', Code: 'Air' },
    { Name: '', Code: 'Hotel' },
    { Name: '', Code: 'Car' },
    { Name: '', Code: 'Tour' },
    { Name: '', Code: 'Cruise' },
    { Name: '', Code: 'Insurance' },
    { Name: '', Code: 'Rail' },
    { Name: '', Code: 'Transfers' },
    { Name: '', Code: 'Others' },

  ];
  public productSelectedValue: any;

  public productGridData: any;
  public productGridDataArray: any;
  public productGridAirDataForRefund: Array<RefundProductList> = [];
  public refunddata = new RefundProductList;
  public productGridDataForRefund: Array<RefundProductList> = [];
  public RefundTotalProductList: Array<RefundTotalProductList["ResponseData"]> = [];
  public fcid: any = 'GBP';
  actionsLayout: any;
  public validrefund: any;
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];
  SUPPPRTYCODEfalse: any = false;
  public BUYAMTp: any;
  RefNop: any;
  public SELLAMTF: any;
  public BUYAMTF: any;
  public SELLAMTp: any;
  private isNew: boolean;
  private editedRowIndex: number;
  private editedRowOtherIndex: number;
  public formGroup: FormGroup;
  public formGroupOther: FormGroup;



  public ProductList: any;
  public ProductListfinalsave: any;
  //public airSegGridData: Array<AirsegDetail["ResponseData"]> = [];
  public airSegGridData: any;
  public railSegGridData: any;
  public homeCurrency: any;
  private fcCurrency = this.apiShared.folderCurrency;
  private hcExRate: any;
  private bsptaxNo: any;
  public pageSize = 50;
  public skip = 0;
  prodgridHeight = 140;
  railgridHeight = 133;
  airgridHeight = 140;
  private usrId: number;
  public total: any;
  public showtotal = false;
  OutputTax: Array<LookUpDetails["ResponseData"]> = [];
  public OutputTaxselectedItem: LookUpDetails["ResponseData"];
  outputTaxDetails: Array<TaxMasterDetailsDTO> = [];
  penDockLock: Pendoclock = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {

      LDocType: "",
      LDocNo: "",
      UID: 0,
      LDATE: "",
      LTIME: "",
      LPROJNO: "",
      LYearID: "",
      LAutoNo: "",
      Uname: "",
      Source: ""

    }
  };
  public TotalValue: any = {
    TotRECEIVABLE: 0,
    VATNO: 0,
    VATRate: 0,
    VATAmt: 0,
    VatTotal: 0,
    AGTCOM: 0,
    AGTCOMSUM: 0,
    AGTCOMVAT: 0,
    AGTCOMVATNO: 0,
    Balance: 0,
    TotalCOMM: 0,
    NetComm: 0,
    Description: '',
    FCTotalCOMM: 0,
    MarkupPer: 0
  }
  public AgentCommission: any = {
    AGTCOM: 0,
    AGTCOMSUM: 0,
    AGTCOMVAT: 0,
    AGTCOMVATNO: 0,
    AGTCOMVATRate: 0,
  }
  Tax: Array<LookUpDetails["ResponseData"]> = [];
  public TaxselectedItem: LookUpDetails["ResponseData"];
  public AGTaxselectedItem: LookUpDetails["ResponseData"];
  public newfolder: boolean = false;
  public orderNo = this.route.snapshot.params['orderNo'];
  public package = false;
  public Markup: any = 0.00;
  public showVat = false;
  folderQuotes = false;
  ispackage = false;
  refundfrmFolderlist = false;
  //private folder:FolderSummaryComponent
  public folderDelete: SavedFolderDelete = {
    orderNo: this.orderNo
  };
  public showPdtHeader = true;
  public openRefundPro: any = false;
  public formvalid: any = true;
  showExpense = false;
  addExpense = false;
  public state: State = {
    skip: 0
  };
  airSegGrid: any;
  public airReOrder: boolean = false;
  productGrid: any;
  railSegGrid: any;
  //public gridData:  any;
  private renderer: Renderer2;
  issuumaypage: boolean = true;
  public ProjNO: any = 0;
  constructor(private zone: NgZone, private rendererFactory: RendererFactory2, private folder: FolderSummaryComponent, public intl: IntlService, public common: CommonserviceService, private publiclayout: PublicLayoutComponent, private penLock: PendoclockService, private route: ActivatedRoute, private router: Router, public Productapi: FolderpriceService, private airSegAPI: AirsegService, private railSegAPI: RailsegService,
    private hotelapi: HotelService, private apiTotal: FolderproducttotalService, private carapi: CarService
    , private detailcomponent: DetailsComponent
    , private tktapi: AirticketService, private railapi: RailService, private cruiseapi: CruiseService, private tourapi: TourService, private lookupApi: LookUpDetailsService
    , private othersapi: OthersService, private insuranceapi: InsuranceService, private transfersapi: TransfersService
    , private spinner: NgxSpinnerService, private apiShared: ShareDataService, private formBuilder: FormBuilder
    , private flashMessage: FlashMessagesService, private apipenLock: PendoclockService, private translateapi: TranslateService, private apiFile: FileService, private toastr: ToastrService) {
    debugger;
    this.lockStatus = this.apiShared.folderReadOnly;
    if (this.folderStatussavepage != undefined) {
      this.folderStatus = this.folderStatussavepage;
    }
    else
      this.folderStatus = this.apiShared.FolderStatus;
    if (this.router.url.toString().includes("FolderSummary/")) {
      this.issuumaypage = true;
    }
    else {
      this.issuumaypage = false;
    }
    if (this.lockStatus == "0" && this.folderStatus == "Saved") {
      this.formeditable = true;
      this.foldersaved = true;
    }

    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;
    }
    else {
      this.formeditable = false;
    }
    if (this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund")//By Caseid-12780
    {
      this.formeditable = false;
    }
    if (this.folderStatus == "Saved" || this.folderStatus == "Invoiced")//By Caseid-12780
    {
      this.FolderHideStatus = false;
    }
    if (this.folderStatus == "Saved" || this.folderStatus == "Refund Request")//By Caseid-12780
    {
      this.FolderFinalSaveHideStatus = false;
      this.foldersaved = true;
    }
    this.renderer = rendererFactory.createRenderer(null, null);

    if (this.pdtOrderNo) {
      this.orderNo = this.pdtOrderNo;
    }
    if (this.orderNo != undefined && this.orderNo != 0) {
      this.showProduct = true;
      // this.spinner.show();
      // this.getProductDetails(this.orderNo);
      // this.getAirSegDetails(this.orderNo);
      // this.getRailSegDetails(this.orderNo);
    }
    this.loadpages();
    this.getFolderStatus();
  }
  folderEditMode: any;
  productSelected: any;
  CustCountry: any;
  ngOnInit() {
    debugger;
    this.CustCountry = localStorage.getItem('CustomerCountry');
    this.subscription.add(this.apiShared.getTranslateLang().subscribe((lang) => {
      this.tanslateDropDownValue();
    }));
    this.lockStatus = this.apiShared.folderReadOnly;
    if (this.folderStatussavepage != undefined) {
      this.folderStatus = this.folderStatussavepage;
    }
    else
      this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus == "Saved") {
      this.formeditable = true;
      this.foldersaved = true;
    }

    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;
    }
    else {
      this.formeditable = false;
    }
    if (this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund")//By Caseid-12780
    {
      this.formeditable = false;
    }
    if (this.folderStatus == "Saved" || this.folderStatus == "Invoiced")//By Caseid-12780
    {
      this.FolderHideStatus = false;
    }
    if (this.folderStatus == "Saved" || this.folderStatus == "Refund Request")//By Caseid-12780
    {
      this.FolderFinalSaveHideStatus = false;
      this.foldersaved = true;
    }
    this.TotalValue.MarkupPer = this.MarkupPer == null ? 0 : this.MarkupPer;
    this.TotalValue.FCTotalCOMM = this.FCTotalCOMM == null ? 0 : this.FCTotalCOMM;
    if (this.TotalValue.MarkupPer != null && this.TotalValue.MarkupPer > 0) {
      this.selecttextmarkup = 0;
    }
    else {
      this.selecttextmarkup = 1;
    }
    this.getProductDetails(this.orderNo);
    this.getAirSegDetails(this.orderNo);
    this.getRailSegDetails(this.orderNo);
    debugger;
    this.getFolderStatus();
    this.fcid = sessionStorage.getItem('folderCurrency');
    debugger;
    if (this.Folder == 'folder') {
      this.folderQuotes = false;
      this.ispackage = false;
      this.isfolder = true;
      sessionStorage.setItem('pagevalue', '0');
    } else if (this.Folder == 'folderQuotes') {
      this.folderQuotes = true;
      this.ispackage = false;
      this.isfolder = true;
    }
    else if (this.Folder == 'folderSummary') {
      this.folderQuotes = true;
      this.ispackage = true;
      this.isfolder = true;
    } else if (this.Folder == 'RefundRequest') {
      this.refundfrmFolderlist = true;
      this.folderQuotes = false;
      this.ispackage = false;
      this.isfolder = true;
      if (this.pdtOrderNo) {
        this.orderNo = this.pdtOrderNo;
      }
      this.showPdtHeader = false;
      this.showProduct = true;//By case id-12641
      this.spinner.show();
      this.getProductDetails(this.orderNo);
      // this.getAirSegDetails(this.orderNo);
      // this.getRailSegDetails(this.orderNo);
      this.onRefundClick();

    }
    else {
      this.common.isfinalsaved = true;
    }
    debugger;

    //var orderno= sessionStorage.getItem('orderNo');
    // if(orderno!=null)
    // if(this.orderNo !==orderno)
    // {
    //   return  this.router.navigate(['PenAir/Home']);
    // } 
    window.scrollTo(0, 0);

    this.mySelection = [];
    this.selectAllState = 'unchecked';
    if (localStorage.getItem('OrderType') === 'Package' && localStorage.getItem('ManualCalc') !== '1') {
      this.package = true;
      if (this.apiShared.folderCurrency == this.apiShared.homeCurrency) {
        this.showVat = true;
      }
    }
    if (localStorage.getItem('ProductSelection') == null) {
      this.subscription.add(this.translateapi.get(['Labels.Ticket']).subscribe((translations) => {
        this.productSelectedValue = { Name: translations['Labels.Ticket'], Code: 'Air' }//"Air Ticket";
      }));
    }
    else {
      var Code = localStorage.getItem('ProductSelection');
      localStorage.removeItem('ProductSelection');
      this.productSelectedValue = this.productItems.find(e => e.Code == Code);
    }
    this.getFileDetails();

    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    this.subscription.add(this.lookupApi.GetAllLookup('TAX').subscribe(
      (data) => {
        if (data !== null && data !== undefined) {
          this.Tax = data["ResponseData"];

          if (this.TotalValue.VATNO !== null) {
            this.TaxselectedItem = this.Tax.find(item => item.Code == this.TotalValue.VATNO);
            if (this.TaxselectedItem != undefined && this.TaxselectedItem != null)
              this.TotalValue.VATRate = this.TaxselectedItem.UsrCode;
            if (this.folderproductotal != undefined && this.folderproductotal.ResponseData != undefined)
              this.GetPackageSell(0);
          }
        }
      }
    ));
    debugger;


    if (this.folderStatus == "Invoiced") {
      this.productItems.push('Expense');
    }
    this.folderEditMode = "false";
    // this.apiShared.folderEditMode=this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    if (this.folderStatus == "Refund Request") {
      this.saveRefundbutton = true;
    }
    debugger;
    this.getTaxLookup(this.CustCountry);

    //this.FolderFinalSave();
  }
  tanslateDropDownValue() {
    debugger;
    this.subscription.add(this.translateapi.get(['Labels.Ticket', 'Labels.hotels', 'Labels.cars', 'Labels.Tour', 'Labels.Cruise',
      'Labels.Insurance', 'Labels.Rail', 'Labels.Transfers', 'Labels.Others']).subscribe((translations) => {
        this.productItems[0].Name = translations['Labels.Ticket'];
        this.productItems[1].Name = translations['Labels.hotels'];
        this.productItems[2].Name = translations['Labels.cars'];
        this.productItems[3].Name = translations['Labels.Tour'];
        this.productItems[4].Name = translations['Labels.Cruise'];
        this.productItems[5].Name = translations['Labels.Insurance'];
        this.productItems[6].Name = translations['Labels.Rail'];
        this.productItems[7].Name = translations['Labels.Transfers'];
        this.productItems[8].Name = translations['Labels.Others'];
        if (this.productSelectedValue != null) {
          this.productSelectedValue = this.productItems.find(e => e.Code == this.productSelectedValue.Code);
          this.productsList.text = this.productSelectedValue.Name;
        }
      }));
  }
  public ngAfterViewInit(): void {
    this.currentSubscription = this.handleDragAndDrop();
    this.currentProducrSubscription = this.handleProductDragAndDrop();
    this.currentrailsegscription = this.handleRailGridDragAndDrop();



  }



  private handleDragAndDrop(): Subscription {
    debugger;
    const sub = new Subscription(() => { });
    let draggedItemIndex;

    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    tableRows.forEach((item) => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(
        dragStart
          .pipe(
            tap(({ dataTransfer }) => {
              try {
                const dragImgEl = document.createElement('span');
                dragImgEl.setAttribute(
                  'style',
                  'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;'
                );
                document.body.appendChild(dragImgEl);
                dataTransfer.setDragImage(dragImgEl, 0, 0);
              } catch (err) {
                // IE doesn't support setDragImage
              }
              try {
                // Firefox won't drag without setting data
                dataTransfer.setData('application/json', "");
              } catch (err) {
                // IE doesn't support MIME types in setData
              }
            })
          )
          .subscribe(({ target }) => {
            const row: HTMLTableRowElement = <HTMLTableRowElement>target;
            draggedItemIndex = row.rowIndex;
            const dataItem = this.airSegGridData[draggedItemIndex];

          })
      );

      sub.add(
        dragOver.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.airSegGridData.splice(draggedItemIndex, 1)[0];
          const dropIndex = closest(e.target, tableRow).rowIndex;
          const dropItem = this.airSegGridData[dropIndex];

          draggedItemIndex = dropIndex;
          this.zone.run(() =>
            this.airSegGridData.splice(dropIndex, 0, dataItem)
          );
        })
      );

      sub.add(
        dragEnd.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.airSegGridData[draggedItemIndex];

        })
      );
    });

    return sub;
  }

  public dataStateChange(state: State): void {
    this.state = state;
    this.airSegGridData = process(this.airSegGrid, this.state).data;
    this.currentSubscription.unsubscribe();
    this.zone.onStable
      .pipe(take(1))
      .subscribe(() => (this.currentSubscription = this.handleDragAndDrop()));
  }

  public createProductFormGroup(args: any): any {
    const item = args.isNew ? new ProductList() : args.dataItem;

    const formGroup = this.formBuilder.group({
      'TYPE': item.TYPE,
      'PMName': item.PMName,
      'FC_Buy': item.FC_Buy,
      'FC_Comm': item.FC_Comm
    });

    return formGroup;
  }

  public createAirFormGroup(args: any): any {
    const item = args.isNew ? new AirsegDetail() : args.dataItem;

    const formGroup = this.formBuilder.group({
      'AIRNAME': item.AIRNAME,
      'FLIGHT': item.FLIGHT,
      'AIRDATE': item.AIRDATE,
      'DEPCITY': item.DEPCITY
    });

    return formGroup;
  }

  public createRailFormGroup(args: any): any {
    const item = args.isNew ? new RailsegDetail() : args.dataItem;

    const formGroup = this.formBuilder.group({
      'TRAINNAME': item.TRAINNAME,
      'TRAINNO': item.TRAINNO,
      'DEPDATE': item.DEPDATE,
      'DEPCITY': item.DEPCITY
    });
    return formGroup;
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    // this.loadProduct();
  }

  public showProductFn() {
    this.orderNo = this.route.snapshot.params['orderNo'];
    if (this.orderNo != "0") {
      this.showProduct = !this.showProduct;
    }
    else {
      this.newFolder.emit('true');
    }
  }

  public async onRefundClick() {
    debugger;
    if (Boolean(await this.common.checkPermission1('01.12.01.06.02')) == true) {
      this.foldersaved = false;
      this.disableRefundbutton = true;
      this.showcheckbox = true;
      this.subscription.add(this.translateapi.get(['Messages.Selectproductsrefund']).subscribe((translations) => {

        this.showMsg = translations['Messages.Selectproductsrefund'];
        this.toastr.info(this.showMsg, "", {
          timeOut: 2000,
          disableTimeOut: false,
          closeButton: true
        })

      }));
    }
    else {
      this.spinner.hide();
      this.ShowPermissionMessageForm('01.12.01.06.02');
    }
  }

  public rowsSelectedKeys(context: RowArgs): number {

    return context.dataItem.ITEMNO;
  }

  public onSelectedKeysChange(e) {
    debugger;
    this.toastr.clear();
    const len = this.mySelection.length;
    if (len === 0) {
      this.selectAllState = 'unchecked';
      if (this.items == undefined) {
        this.items = [];
      }
    } else if (len > 0 && len < this.productGridData.length) {
      this.selectAllState = 'indeterminate';
    } else {
      this.selectAllState = 'checked';
    }

  }

  public onCancelClick() {
    this.toastr.clear();
    this.disableRefundbutton = false;
    this.showcheckbox = false;
    this.mySelection = [];
    this.foldersaved = true;
    this.selectAllState = 'unchecked';
  }

  onCancelButtonClick() {
    if (this.orderNo != 0 || this.orderNo != "")
      if (this.lockStatus == "0") {
        this.deletePenLock("Folder", this.orderNo);
      }
  }

  getLockDetails(docType, docNo, userId, projno) {
    this.subscription.add(this.penLock.getLock(docType, docNo, userId, projno)
      .subscribe(data => {
        if (data !== null && data !== undefined) {
          this.penDockLock = data;
          if (this.penDockLock.ResponseData != null) {

            if (this.penDockLock.ResponseData.Uname != "") {

              this.folderReadOnly = "1";
              this.apiShared.Setapishareddata(this.folderReadOnly, 'folderReadOnly');
              this.subscription.add(this.translateapi.get(['Labels.lock_msg']).subscribe((translations) => {
                this.confirmMsg = translations['Labels.lock_msg'].replace("<<1>>", this.penDockLock.ResponseData.Uname)
              }));


            }
            else {

              this.folderReadOnly = "0";
              this.apiShared.Setapishareddata(this.folderReadOnly, 'folderReadOnly');

            }
          }
          else {

            this.folderReadOnly = "0";
            this.apiShared.Setapishareddata(this.folderReadOnly, 'folderReadOnly');


          }
        }

      }));

  }

  moveUp(value) {
    var olddata = this.productGridData;
    const index = this.productGridData.indexOf(value);
    const currentndex1 = this.productGridData[index].ITEMNO;
    const itemindex1 = this.productGridData[index - 1].ITEMNO;
    var datavald = this.productGridData[itemindex1];
    // if(datavald.TYPE!='Others')
    // {
    if (index > 0) {
      const tmp = this.productGridData[index - 1];
      if (tmp != undefined) {
        this.productGridData[index - 1] = this.productGridData[index];
        this.productGridData[index - 1].ITEMNO = itemindex1;
        this.productGridData[index] = tmp;
        this.productGridData[index].ITEMNO = currentndex1;
        var data = this.productGridData;
        this.productGridData = [];
        this.productGridData = data;
        this.reload();
      }
    }
  }

  reload() {
    this.productGridData = this.productGridData.slice(0, 10);
    this.dataBinding.skip = 0;
  }

  moveDown1(value) {
    const index = this.productGridData.indexOf(value);
    const currentndex1 = this.productGridData[index].ITEMNO;
    const itemindex1 = this.productGridData[index + 1].ITEMNO;
    var datavald = this.productGridData[itemindex1];

    if (index < this.productGridData.length) {
      const tmp = this.productGridData[index + 1];
      if (tmp != undefined) {
        this.productGridData[index + 1] = this.productGridData[index];
        this.productGridData[index + 1].ITEMNO = itemindex1;
        this.productGridData[index] = tmp;
        this.productGridData[index].ITEMNO = currentndex1;
        var data = this.productGridData;
        this.productGridData = [];
        this.productGridData = data;
        this.reload();
      }

    }
  }

  deletePenLock(docType, docno) {
    this.subscription.add(this.apipenLock.DeleteLock(docType, docno + "/").subscribe(res => {

    }, (err) => {
      console.log(err);

    }
    ));
  }

  public updateproductsortAfterfinalSave() {
    this.subscription.add(this.Productapi.FolderFinalSave(this.orderNo, 1).subscribe(res => {
      this.subscription.add(this.translateapi.get(['Messages.ProductOrderUpdatedSucess']).subscribe((translations) => {
        this.spinner.hide();
        this.showMsg = translations['Messages.ProductOrderUpdatedSucess'];
        this.toastr.success(this.showMsg, "", {
          timeOut: 5000,
          disableTimeOut: false,
          closeButton: true
        })

      }));
      this.getProductDetails(this.orderNo);

    }, (err) => {
      this.spinner.hide();
      console.log(err);

    }
    ));


  }



  public updateproductsort() {
    this.spinner.show();
    this.ProductList = this.productGridData;
    this.ProductListfinalsave = this.productGridData;
    this.Productapi.formData = new FolderRefundDTO;
    this.Productapi.formData.FolderNo = this.ProductList[0].FOLDERNO;
    this.Productapi.formData.ProjNo = this.ProductList[0].PROJNO;
    this.Productapi.productList = [];
    this.Productapi.productList = this.ProductList;
    this.subscription.add(this.Productapi.UpdateProductOrder().subscribe(res => {
      if (res !== null && res !== undefined) {
        if (res["ResponseData"] == "Pass") {
          this.modifyproductorder = false;

          this.updateproductsortAfterfinalSave();

        }
        else {
          this.spinner.hide();
          this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {

            this.showMsg = translations['Messages.Failed'];
            this.toastr.warning(this.showMsg)
          }));
        }

      }
      else {
        this.spinner.hide();
      }
    }));

  }

  public async onContinueClick() {
    debugger;
    this.productGridDataForRefund = [];
    this.productGridAirDataForRefund = [];
    this.RefundTotalProductList = [];
    if (this.mySelection.length > 0) {
      this.toastr.clear();
      if (this.items == undefined) {
        this.items = [];
      }
      this.ProductList = this.productGridData;
      this.mySelection.forEach(obj => {
        this.items.push(this.ProductList.find((slno) => slno.ITEMNO == obj));
        this.RefundTotalProductList.push(this.ProductList.find((slno) => slno.ITEMNO == obj));
      })
      this.RefundTotalProductList.forEach(obj => {
        debugger;
        this.refunddata = new RefundProductList;
        this.refunddata.FolderExh = sessionStorage.getItem('folderExchangeRate');
        this.refunddata.FCID = sessionStorage.getItem('folderCurrency');
        this.refunddata.FOLDERNO = obj.FOLDERNO
        this.refunddata.ProductExh = obj.PO_FC_CIDExRate;
        this.refunddata.ITEMNO = obj.ITEMNO;
        this.refunddata.BUYAMT = obj.PO_FC_AMT;
        this.refunddata.TYPE = obj.TYPE;
        this.refunddata.PMName = obj.PMName;
        this.refunddata.COMMAMT = obj.COMMAMT;
        this.refunddata.SELLAMT = obj.FC_Sell;
        this.refunddata.TicketDate = obj.BookingDate;
        this.refunddata.TicketNo = obj.TicketNo;
        this.refunddata.BookingDate = obj.BookingDate;
        this.refunddata.RefNo = obj.TicketNo,
          this.refunddata.Supplier = obj.PrtyUserCode;
        this.refunddata.PO_FC_CID = obj.PO_FC_CID;
        this.refunddata.SUPPPRTYCODE = obj.SUPPPRTYCODE;
        this.refunddata.GUIDItinerary = obj.GUIDItinerary;
        debugger;
        // this.customerSelectedItem = this.customerList.find(
        //   (item) => item.Name == value
        // );
        //   this.supplierSelectedItem.
        //this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);
        // [value]="dataItem.Supplier" 
        this.SupplierSelected = this.refunddata.Supplier;
        // dataItem.SUPPPRTYCODE = this.supplierSelectedItem.Code;
        this.refunddata.Supplier = obj.PrtyUserCode;
        this.refunddata.HCBUYAMT = this.refunddata.BUYAMT * this.refunddata.ProductExh / this.refunddata.FolderExh;
        this.refunddata.HCSellAMT = this.refunddata.SELLAMT * this.refunddata.FolderExh;
        this.refunddata.COMMAMT = this.refunddata.HCSellAMT - this.refunddata.HCBUYAMT;

        this.productGridDataForRefund.push(this.refunddata);


      });

      this.Productapi.formData = new FolderRefundDTO;
      this.Productapi.formData.FolderNo = this.ProductList[0].FOLDERNO;
      this.Productapi.formData.ProjNo = this.ProductList[0].PROJNO;
      this.ProjNO = this.Productapi.formData.ProjNo;
      this.Productapi.List = [];
      this.Productapi.List = this.items;
      if (this.productGridDataForRefund.length > 0) {
        debugger;
        this.spinner.show();
        this.Productapi.OtherList = this.productGridDataForRefund;
        await this.subscription.add(this.Productapi.GetProductTKTNo().subscribe(res => {
          debugger;
          if (res != null && res != undefined) {
            var i = 0;
            this.productGridDataForRefund.forEach(obj1 => {
              this.OneditRow(obj1, i);
              i = i + 1;
            })



            res["ResponseData"].forEach(obj => {
              var data = this.productGridDataForRefund.find(x => x.GUIDItinerary == obj.GUIDItinerary)
              data.RefNo = obj["RefNo"];
              data.PACKDES = obj["PACKDES"];
            });
            this.spinner.hide();
          }
          else {
            this.spinner.hide();
          }
        }));

      }
      this.formeditable = true;
      this.saveRefundbutton = true;
      this.disableRefundbutton = false;
      this.openRefundPro = true;

    }
    else {
      this.subscription.add(this.translateapi.get(['Messages.SelectAtleastoneRow']).subscribe((translations) => {

        this.showMsg = translations['Messages.SelectAtleastoneRow'];
        this.toastr.warning(this.showMsg)
      }));
    }

  }

  public loadpages() {
    this.createProductFormGroup = this.createProductFormGroup.bind(this);
    this.removeProductConfirmation = this.removeProductConfirmation.bind(this);
    this.createAirFormGroup = this.createAirFormGroup.bind(this);
    this.removeAirConfirmation = this.removeAirConfirmation.bind(this);
    this.createRailFormGroup = this.createRailFormGroup.bind(this);
    this.removeRailConfirmation = this.removeRailConfirmation.bind(this);
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState) {

    if (checkedState === 'checked') {
      this.mySelection = this.productGridData.map((item) => item.ITEMNO);
      this.selectAllState = 'checked';
    } else {
      this.mySelection = [];
      this.selectAllState = 'unchecked';
    }
  }

  public opened = false;

  public close(status) {
    this.pageSize = 50;
    this.skip = 0;
    this.opened = false;
    this.getProductDetails(this.orderNo);
  }

  closeRefundpRod(value) {
    this.foldersaved = true;
    this.selectAllState = 'unchecked';
    this.disableRefundbutton = false;
    this.showcheckbox = false;
    this.mySelection = [];
    this.openRefundPro = false;
  }

  public open() {
    this.pageSize = 50;
    this.skip = 0;
    this.opened = true;
    this.getProductDetails(this.orderNo);
    this.getHCFolderProductTotal(this.orderNo);
  }

  getProductDetails(ordeNo) {
    debugger;
    this.Products = '';
    this.buy = 0.00;
    this.comm = 0.00;
    this.spinner.show();
    this.subscription.add(this.Productapi.getAllProducts(ordeNo)
      .subscribe(data => {
        debugger;

        this.folderexgrate = this.apiShared.folderExchangeRate;
        if (this.folderexgrate == null || this.folderexgrate == undefined || this.folderexgrate == "")
          this.folderexgrate = 1;
        if (data !== null && data !== undefined && data["ResponseData"].length > 0) {
          this.spinner.hide();
          //this.productGridData = data["ResponseData"];
          this.productGrid = data["ResponseData"];
          this.productGridData = process(this.productGrid, this.state).data;
          this.productDataStateChange(this.state);
          if (this.refundfrmFolderlist)
            this.prodgridHeight = 100;
          else
            this.prodgridHeight = 140;
          if (this.productGridData.length > 5) {
            this.prodgridHeight = this.prodgridHeight + (43 * 5);
          } else {
            this.prodgridHeight = this.prodgridHeight + (43 * this.productGridData.length);
          }
          this.productGridData.forEach(element => {
            if (!this.Products.includes(element.TYPE)) {
              if (this.Products === '')
                this.Products = element.TYPE;
              else
                this.Products = this.Products + ',' + element.TYPE;
            }
            element.FCVAT = element.VAT / this.folderexgrate;
          });
          if(  this.Products!=''&&   this.Products!=null)
          {
            this.common.productexist=true;
          }
          else
          {
            this.common.productexist=false;
          }
          this.ProductList = this.productGridData;
          this.ProductList.forEach(obj => {
            if (obj.AUTOPURCHASEORDER === 0) {
              this.fcsellStat += obj.FC_Sell;
              this.fcbuyStat += obj.FC_Buy;
            }
            if (obj.OthersType === 'Default') {
              this.fcselldefault += obj.FC_Sell;
              this.fcbuydefault += obj.FC_Buy;

            }
            if (obj.COMONLY === 'C' || obj.COMONLY === 'L') {
              this.fcsellCustCard += obj.FC_Sell;
              this.fcbuyCustCard += obj.FC_Buy;
            }
          })
          this.productGridData = this.ProductList;
          this.getProductTotal(ordeNo, 0);
          debugger;
          if (this.ProductList != null && this.ProductList.length > 0)
            this.total = aggregateBy(this.ProductList, this.aggregates);
          if (this.productGridData.length > 0)
            this.showtotal = true;
          else
            this.showtotal = false;



        }
        else {
          this.common.productexist=false;
          this.ProductList = [];
          this.productGridData = [];
          this.showtotal = false;
          this.total = null;
          this.spinner.hide();

        }
      }));
  }

  public productDataStateChange(state: State): void {
    this.state = state;
    this.productGridData = process(this.productGrid, this.state).data;
    this.currentProducrSubscription.unsubscribe();
    this.zone.onStable
      .pipe(take(1))
      .subscribe(() => (this.currentProducrSubscription = this.handleProductDragAndDrop()));
  }

  public railDataStateChange(state: State): void {
    this.state = state;
    this.railSegGridData = process(this.railSegGrid, this.state).data;
    this.currentrailsegscription.unsubscribe();
    this.zone.onStable
      .pipe(take(1))
      .subscribe(() => (this.currentrailsegscription = this.handleRailGridDragAndDrop()));
  }

  private handleRailGridDragAndDrop(): Subscription {

    const sub = new Subscription(() => { });
    let draggedItemIndexRail;

    const RailTableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    RailTableRows.forEach((item) => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(
        dragStart
          .pipe(
            tap(({ dataTransfer }) => {
              try {
                const dragImgEl = document.createElement('span');
                dragImgEl.setAttribute(
                  'style',
                  'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;'
                );
                document.body.appendChild(dragImgEl);
                dataTransfer.setDragImage(dragImgEl, 0, 0);
              } catch (err) {
                // IE doesn't support setDragImage
              }
              try {
                // Firefox won't drag without setting data
                dataTransfer.setData('application/json', "");
              } catch (err) {
                // IE doesn't support MIME types in setData
              }
            })
          )
          .subscribe(({ target }) => {
            const row: HTMLTableRowElement = <HTMLTableRowElement>target;
            draggedItemIndexRail = row.rowIndex;
            const dataItem = this.railSegGridData[draggedItemIndexRail];
            dataItem.dragging = true;
          })
      );

      sub.add(
        dragOver.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.railSegGridData.splice(draggedItemIndexRail, 1)[0];
          const dropIndex = closest(e.target, tableRow).rowIndex;
          const dropItem = this.railSegGridData[dropIndex];

          draggedItemIndexRail = dropIndex;
          this.zone.run(() =>
            this.railSegGridData.splice(dropIndex, 0, dataItem)
          );
        })
      );

      sub.add(
        dragEnd.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.railSegGridData[draggedItemIndexRail];
          dataItem.dragging = false;
        })
      );
    });

    return sub;
  }

  private handleProductDragAndDrop(): Subscription {

    const sub = new Subscription(() => { });
    let draggedItemIndex;

    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    tableRows.forEach((item) => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(
        dragStart
          .pipe(
            tap(({ dataTransfer }) => {
              try {
                const dragImgEl = document.createElement('span');
                dragImgEl.setAttribute(
                  'style',
                  'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;'
                );
                document.body.appendChild(dragImgEl);
                dataTransfer.setDragImage(dragImgEl, 0, 0);
              } catch (err) {
                // IE doesn't support setDragImage
              }
              try {
                // Firefox won't drag without setting data
                dataTransfer.setData('application/json', "");
              } catch (err) {
                // IE doesn't support MIME types in setData
              }
            })
          )
          .subscribe(({ target }) => {
            const row: HTMLTableRowElement = <HTMLTableRowElement>target;
            draggedItemIndex = row.rowIndex;
            const dataItem = this.productGridData[draggedItemIndex];

          })
      );

      sub.add(
        dragOver.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.productGridData.splice(draggedItemIndex, 1)[0];
          const dropIndex = closest(e.target, tableRow).rowIndex;
          const dropItem = this.productGridData[dropIndex];

          draggedItemIndex = dropIndex;
          this.zone.run(() =>
            this.productGridData.splice(dropIndex, 0, dataItem)
          );
        })
      );

      sub.add(
        dragEnd.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.productGridData[draggedItemIndex];

        })
      );
    });

    return sub;
  }

  public getProductTotal(orderNo, initialval) {
    debugger;
    this.subscription.add(this.apiTotal.getProductTotal(orderNo)
      .subscribe(data => {
        if (data !== null && data !== undefined) {
          this.folderproductotal = data;
          this.spinner.hide();
          this.buy = this.folderproductotal.ResponseData.FCBuyTotal;
          this.comm = this.folderproductotal.ResponseData.FCCommTotal;
          this.sell = this.folderproductotal.ResponseData.FCSellTotal;
          this.totalTax = this.folderproductotal.ResponseData.TotalTax;
          this.totalRecievable = this.folderproductotal.ResponseData.Total_Receivable;
          this.alreadyPaid = this.folderproductotal.ResponseData.FCAlreadyPaid;
          this.otherCredits = this.folderproductotal.ResponseData.FCOtherCredits;
          this.balance = this.folderproductotal.ResponseData.Balance;
          this.FCCREDITAMT = this.folderproductotal.ResponseData.FCCREDITAMT

          // if(this.folderproductotal!=undefined &&this.folderproductotal.ResponseData!=undefined)
          // this.GetPackageSell(0);


          if (this.folderStatus == "Refund Request") {
            this.labelchange = true;
          }
          if (this.package) {

            //if(this.showVat){
            debugger;
            if (this.folderproductotal.ResponseData != undefined)
              this.GetPackageTotal(orderNo, initialval);

            //}
          }
        }
        else {

          this.spinner.hide();
        }

      }));
  }

  public getHCFolderProductTotal(orderNo) {
    this.subscription.add(this.apiTotal.getHCFolderProductTotal(orderNo)
      .subscribe(data => {
        if (data !== null && data !== undefined) {
          this.hcfolderproductotal = data;
          this.spinner.hide();
          this.HCbuy = this.hcfolderproductotal.ResponseData.HCBuyTotal;
          this.HCcomm = this.hcfolderproductotal.ResponseData.HCCommTotal;
          this.HCsell = this.hcfolderproductotal.ResponseData.HCSellTotal;
          this.totalTax = this.hcfolderproductotal.ResponseData.TotalTax;
          this.totalRecievable = this.hcfolderproductotal.ResponseData.Total_Receivable;
          this.HCalreadyPaid = this.hcfolderproductotal.ResponseData.HCAlreadyPaid;
          this.HCotherCredits = this.hcfolderproductotal.ResponseData.HCOtherCredits;
          this.balance = this.hcfolderproductotal.ResponseData.Balance;
          this.HCCREDITAMT = this.hcfolderproductotal.ResponseData.HCCREDITAMT


        }
        else {
          this.spinner.hide();
        }



      }));
  }
  markuponBlur() {
    if (this.TotalValue.MarkupPer > 100) {
      this.TotalValue.MarkupPer = 100;
    }
    if (this.TotalValue.MarkupPer < 0) {
      this.TotalValue.MarkupPer = 0;
    }
  }
  CaluclateMarkup(selectdata: any) {
    debugger;
    if (selectdata == 1) {
      this.TotalValue.MarkupPer = 0;
    }

    this.TotalValue.MarkupPer = +this.TotalValue.MarkupPer;
    if (this.TotalValue.MarkupPer > 100) {
      this.TotalValue.MarkupPer = 100;
    }
    if (this.TotalValue.MarkupPer < 0) {
      this.TotalValue.MarkupPer = 0;
    }
    if (this.TotalValue.FCTotalCOMM > 999999) {
      this.TotalValue.FCTotalCOMM = 999999;
    }
    //document.getElementById("markupdata").setAttribute("text", this.TotalValue.MarkupPer);
    this.Markupdata = +this.TotalValue.FCTotalCOMM;
    this.selecttextmarkup = selectdata;
    //this.packageSell=this.folderproductotal.ResponseData.FCSellTotal
    this.GetPackageSell(1);
  }
  public GetPackageTotal(orderNo, initialval) {
    this.subscription.add(this.apiTotal.GetPackageTotal(orderNo)
      .subscribe(data => {
        if (data !== null && data !== undefined) {
          this.TotalValue.Description = data.ResponseData.Description;
          this.packageSell = this.sell;
          if (this.showVat) {
            debugger;
            this.TotalValue.VATNO = data.ResponseData.VATNO;

            if (this.OutputTax != null && this.OutputTax != undefined && this.OutputTax.length > 0) {
              this.OutputTaxselectedItem = this.OutputTax.find(item => item.Code == this.TotalValue.VATNO);
              this.common.OutTaxselectedItem = this.common.OutputTax.find(item => item.Code == this.TotalValue.VATNO);
            }
            this.TotalValue.VATAmt = data.ResponseData.VatTotal;
            this.outputTaxSplit();
            this.TotalValue.AGTCOM = data.ResponseData.AGTCOM;
            this.TotalValue.AGTCOMSUM = data.ResponseData.AGTCOMSUM;
            this.TotalValue.AGTCOMVATNO = data.ResponseData.AGTCOMVATNO;
            this.TotalValue.AGTCOMVAT = data.ResponseData.AGTCOMVAT;
            this.TotalValue.VatTotal = this.sell + this.TotalValue.VATAmt;
            this.TotalValue.VatTotal = this.common.ConvertToFixed2(+this.TotalValue.VatTotal);
            this.TotalValue.TotRECEIVABLE = this.TotalValue.VatTotal - this.TotalValue.AGTCOM + this.FCCREDITAMT;
            this.TotalValue.NetComm = this.comm - data.ResponseData.AGTCOMSUM;

            this.totalRecievable = this.TotalValue.TotRECEIVABLE;
            this.balance = data.ResponseData.Balance;

            if (data.ResponseData.VATNO !== null) {
              this.TaxselectedItem = this.Tax.find(item => item.Code == data.ResponseData.VATNO);
              if (this.TaxselectedItem != undefined && this.TaxselectedItem != null)
                this.TotalValue.VATRate = this.TaxselectedItem.UsrCode;
            }
          }
          debugger;
          if (this.folderproductotal != undefined && this.folderproductotal.ResponseData != undefined)
            this.GetPackageSell(initialval);
        }
        else {
          this.spinner.hide();
        }
      }));
  }

  GetPackageSell(value: any) {
    debugger;
    this.sell = 0.00;
    this.comm = 0.00;
    if (this.selecttextmarkup == 0) {

      if (value == 0) {
        // this.TotalValue.FCTotalCOMM=(+this.total.FC_Buy.sum*+this.TotalValue.MarkupPer)/100;
        this.packageSell = +this.folderproductotal.ResponseData.FCSellTotal;
        this.TotalValue.FCTotalCOMM = this.common.ConvertToFixed2(+this.TotalValue.FCTotalCOMM);
      }

      else {
        this.TotalValue.FCTotalCOMM = (+this.total.FC_Buy.sum * +this.TotalValue.MarkupPer) / 100;
        this.packageSell = (+this.total.FC_Buy.sum * +this.TotalValue.MarkupPer) / 100 + (+this.total.FC_Buy.sum);
        this.TotalValue.FCTotalCOMM = this.common.ConvertToFixed2(+this.TotalValue.FCTotalCOMM);
      }
    }
    else {

      if (value == 0)
        this.packageSell = +this.folderproductotal.ResponseData.FCSellTotal;
      else {
        if (this.total != null)
          this.packageSell = +this.total.FC_Buy.sum + this.TotalValue.FCTotalCOMM;
      }
    }
    this.packageSell = this.common.ConvertToFixed2(+this.packageSell);
    if (this.productGridData != null) {
      this.ProductList = this.productGridData;
      if (this.fcsellCustCard == null) {
        this.fcsellCustCard = 0;
      }
      if (this.fcsellStat == null) {
        this.fcsellStat = 0;
      }
      if (this.fcselldefault == null) {
        this.fcselldefault = 0;
      }
      if (this.fcbuyCustCard == null) {
        this.fcbuyCustCard = 0;
      }
      if (this.fcbuydefault == null) {
        this.fcbuydefault = 0;
      }
      if (this.fcbuyStat == null) {
        this.fcbuyStat = 0;
      }

      this.ProductList.forEach(obj => {
        if (obj !== null && obj !== undefined) {
          if (this.buy !== 0) {
            debugger;
            if (this.buy == undefined) {
              this.buy = 0;
            }
            if (obj.FC_Buy == undefined) {
              obj.FC_Buy = 0;
            }
            if (obj.COMONLY !== 'C' && obj.COMONLY !== 'L' && obj.AUTOPURCHASEORDER !== 0 && obj.OthersType !== 'Default') {


              obj.FC_Sell = (((this.packageSell - (this.fcsellCustCard + this.fcsellStat + this.fcselldefault)) * obj.FC_Buy) / (this.buy - (this.fcbuyCustCard + this.fcbuyStat + this.fcbuydefault)));
              obj.FC_Comm = obj.FC_Sell - obj.FC_Buy;
            }
            if (!isNaN(obj.FC_Sell))
              this.sell += obj.FC_Sell;
            else {
              this.sell += 0;
              obj.FC_Sell = 0;
            }

            if (!isNaN(obj.FC_Comm))
              this.comm += obj.FC_Comm;
            else {
              obj.FC_Comm = 0;
              this.comm += 0;
            }

          }
        }

      })
      debugger;
     
      this.Productapi.FCTotalCOMM = this.TotalValue.FCTotalCOMM;
      this.Productapi.MarkupPer = this.TotalValue.MarkupPer;
      this.productGridData = this.ProductList;
      this.CalculateTax();
      if (this.ProductList != null && this.ProductList.length > 0) {
        this.total = aggregateBy(this.ProductList, this.aggregates);
      }
    }
  }

  VatAmtChange() {
    this.outputTaxSplit();

    // this.TotalValue.VatTotal = this.sell + this.TotalValue.VATAmt;
    // this.TotalValue.VatTotal = this.common.ConvertToFixed2(+this.TotalValue.VatTotal);
  }

  public confirmProductRemove(shouldRemove: boolean): void {
    this.removeProductConfirmationSubject.next(shouldRemove);
    if (shouldRemove) {

      let res = this.productToRemove;

      if (res.TYPE == "Hotel") {
        this.subscription.add(this.hotelapi.deleteHotel(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {  //By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails(this.orderNo);
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (res.TYPE == "Car") {
        this.subscription.add(this.carapi.DeleteCarDetails(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails(this.orderNo);
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (res.TYPE == "Ticket") {
        this.subscription.add(this.tktapi.DeleteAirTicketDetails(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails(this.orderNo);
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (res.TYPE == "Rail") {
        this.subscription.add(this.railapi.DeleteRailDetails(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails(this.orderNo);
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (res.TYPE == "Cruise") {
        this.subscription.add(this.cruiseapi.deleteCruise(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails(this.orderNo);
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (res.TYPE == "Tour") {
        this.subscription.add(this.tourapi.deleteTour(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails(this.orderNo);
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (res.TYPE == "Others") {
        this.subscription.add(this.othersapi.deleteOthers(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails(this.orderNo);
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (res.TYPE == "Insurance") {
        this.subscription.add(this.insuranceapi.deleteInsurance(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails(this.orderNo);
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (res.TYPE == "Transfers") {
        this.subscription.add(this.transfersapi.deleteTransfers(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          // this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails(this.orderNo);
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
    }
    this.productToRemove = null;

  }

  public removeProductConfirmation(dataItem): Subject<boolean> {
    debugger;
    if (dataItem.SupplierDeposit != null && dataItem.SupplierDeposit != 0) {
      this.productToRemove = null;

      this.subscription.add(
        this.translateapi
          .get(["Messages.SupplierDepositAlreadyMade"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.SupplierDepositAlreadyMade"];

            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
    } else if (dataItem.PONO != undefined && dataItem.PONO != 0) {
      this.common.showWARNINGtoastrmsg("Messages.POAlready250", 2000, true, false);
      this.productToRemove = null;
      //this.reloadPrd();
      return
    } else {
      this.productToRemove = dataItem;
      return this.removeProductConfirmationSubject;
    }
  }

  onProductCellClick(e) {
    if (!this.refundfrmFolderlist) {
      if (e.column.field != null) {
        let clickedRowItem = e.dataItem;
        // alert(JSON.stringify( clickedRowItem));
        //alert(JSON.stringify(e.column));
        if (clickedRowItem.GUIDItinerary === null || clickedRowItem.GUIDItinerary === '') {
          this.subscription.add(this.translateapi.get(['Messages.Invalidproductguid1st']).subscribe((translations) => {

            this.showMsg = translations['Messages.Invalidproductguid1st'];


          }));
          this.subscription.add(this.translateapi.get(['Messages.Invalidproductguid2nd']).subscribe((translations) => {

            this.showMsg = this.showMsg + " " + 2018 + " ." + translations['Messages.Invalidproductguid2nd'];


          }));
          this.toastr.warning(this.showMsg, "", {
            timeOut: 5000,
            disableTimeOut: false,
            closeButton: true
          })
        }
        else {
          if (clickedRowItem.TYPE == "Ticket")//By Caseid-12641
          {
            if (
              (clickedRowItem.TTYPE == "ADM" ||
                clickedRowItem.TTYPE == "ACM" ||
                clickedRowItem.TTYPE == "MCO" ||
                clickedRowItem.TTYPE == "MPD" ||
                clickedRowItem.TTYPE == "EMD" ||
                clickedRowItem.TTYPE == "VMPD") &&
              clickedRowItem.GRNINO != 0 &&
              clickedRowItem.GRNINO != null)//By Caseid-12641
              this.router.navigate(['PenAir/AirTicketDetails/AirTicketDetailsADMACM/', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/', clickedRowItem.FPD_AutoNo, clickedRowItem.PROJNO]);
            else
              this.router.navigate(['/PenAir/Product/AirticketDetails/', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          }
          else if (clickedRowItem.TYPE == "Hotel")
            this.router.navigate(['/PenAir/Product/HotelDetails/', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (clickedRowItem.TYPE == "Insurance")
            this.router.navigate(['PenAir/Product/InsuranceDetails', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (clickedRowItem.TYPE == "Tour")
            this.router.navigate(['PenAir/Product/TourDetails', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (clickedRowItem.TYPE == "Cruise")
            this.router.navigate(['PenAir/Product/CruiseDetails', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (clickedRowItem.TYPE == "Others")
            this.router.navigate(['PenAir/Product/OthersDetails', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (clickedRowItem.TYPE == "Car")
            this.router.navigate(['PenAir/Product/CarDetails/', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (clickedRowItem.TYPE == "Rail")
            this.router.navigate(['PenAir/Product/RailDetails/', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (clickedRowItem.TYPE == "Transfers")
            this.router.navigate(['PenAir/Product/TransfersDetail/', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);

        }


      }
    }

  }

  async addProductHandler() {

    if (Boolean(await this.common.checkPermission1('01.12.01.11')) == true) {// this. productSelected=this.productSelectedValue;
      //this.apiShared.ProductName=this.productSelected;

      if (this.productSelectedValue.Code != "Expense")
        localStorage.setItem('ProductSelection', this.productSelectedValue.Code);


      // alert(JSON.stringify(this.productSelectedValue));
      if (this.productSelectedValue.Code == "Hotel")
        this.router.navigate(['PenAir/Product/HotelPackageDetailsAdd', this.orderNo]);
      else if (this.productSelectedValue.Code == "Insurance")
        this.router.navigate(['PenAir/Product/InsuranceDetailsAdd', this.orderNo]);
      else if (this.productSelectedValue.Code == "Tour")
        this.router.navigate(['PenAir/Product/TourDetailsAdd', this.orderNo]);

      else if (this.productSelectedValue.Code == "Others")
        this.router.navigate(['PenAir/Product/OthersDetailsAdd', this.orderNo]);


      else if (this.productSelectedValue.Code == "Car")
        this.router.navigate(['PenAir/Product/AddCarDetails', this.orderNo]);
      else if (this.productSelectedValue.Code == "Cruise")
        this.router.navigate(['PenAir/Product/CruiseDetailsAdd', this.orderNo]);
      else if (this.productSelectedValue.Code == "Air")
        this.router.navigate(['PenAir/Product/AirticketDetailsAdd', this.orderNo]);
      else if (this.productSelectedValue.Code == "Rail")
        this.router.navigate(['PenAir/Product/AddRailDetails', this.orderNo]);
      else if (this.productSelectedValue.Code == "Transfers")
        this.router.navigate(['PenAir/Product/AddTransferDetails', this.orderNo]);
      else if (this.productSelectedValue.Code == "Expense")
        this.showExpense = true;
    } else {
      this.ShowPermissionMessageForm('01.12.01.11');//339
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.11');
    }
  }
  productValueChange(value) {
    debugger;
    if (value != null) {
      this.productSelectedValue = value;
      if (this.lockStatus == "0" && this.folderStatus == "Invoiced") {
        if (value.Code == 'Expense') {
          this.addExpense = true;
        }
        else {
          this.addExpense = false;
        }
      }
    }
  }

  getAirSegDetails(ordeNo) {
    //this.gridData = process(products, this.state);
    this.subscription.add(this.airSegAPI.GetAllAirSegDetails(ordeNo)
      .subscribe(data => {
        if (data !== null && data !== undefined && data["ResponseData"].length > 0) {
          if (data["ResponseData"] != undefined)
            this.airSegGrid = data["ResponseData"];
          this.airSegGridData = process(this.airSegGrid, this.state).data;
          this.dataStateChange(this.state);
          this.airgridHeight = 133;
          if (this.airSegGridData.length > 5) {
            this.airgridHeight = this.airgridHeight + (46 * 4);
          } else {
            if (this.airSegGridData.length > 1)
              this.airgridHeight = this.airgridHeight + (46.5 * (this.airSegGridData.length - 1));
          }
        }
      }));
  }

  public onTabSelect(e) {

    //  alert(JSON.stringify(e));
  }

  public confirmAirRemove(shouldRemove: boolean): void {
    this.removeAirConfirmationSubject.next(shouldRemove);
    if (shouldRemove) {
      let res = this.airToRemove;
      this.subscription.add(this.airSegAPI.deleteAirseg(res.FOLDERNO, res.SLNO).subscribe(res => {
        this.common.showFlash1('Messages.deleted_success', 'bg-success text-white', 2000);
        this.getAirSegDetails(this.orderNo);
      }, (err) => {
        console.log(err);
        this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
      }));
    }
    this.airToRemove = null;
  }

  public removeAirConfirmation(dataItem): Subject<boolean> {
    this.airToRemove = dataItem;
    return this.removeAirConfirmationSubject;
  }

  public addAirSegHandler({ sender }) {
    this.router.navigate(['PenAir/Product/AirSegDetailsAdd', this.orderNo]);
  }


  onAirSegCellClick(e) {
    //alert(e.column.field);
    if (e.column.field != null) {
      let clickedRowItem = e.dataItem;
      //alert(JSON.stringify( clickedRowItem));
      //alert(JSON.stringify(e.column));


      this.router.navigate(['/PenAir/Product/AirsegDetails/', clickedRowItem.FOLDERNO, clickedRowItem.SLNO + '/']);
    }
  }

  getRailSegDetails(ordeNo) {
    this.subscription.add(this.railSegAPI.GetAllRailSegDetails(ordeNo)
      .subscribe(data => {
        if (data !== null && data !== undefined && data["ResponseData"].length > 0) {
          if (data["ResponseData"] != '')
            this.railSegGrid = data["ResponseData"];
          this.railSegGridData = process(this.railSegGrid, this.state).data;
          this.railDataStateChange(this.state);
          this.railgridHeight = 133;
          if (this.railSegGridData.length > 5) {
            this.railgridHeight = this.railgridHeight + (46 * 4);
          } else {
            if (this.railSegGridData.length > 1)
              this.railgridHeight = this.railgridHeight + (46.5 * (this.railSegGridData.length - 1));
          }

        }
      }));
  }

  public confirmRailRemove(shouldRemove: boolean): void {

    this.removeRailConfirmationSubject.next(shouldRemove);
    if (shouldRemove) {
      let res = this.railToRemove;
      this.subscription.add(this.railSegAPI.deleteRailseg(res.FOLDERNO, res.SLNO).subscribe(res => {
        this.common.showFlash1('Messages.deleted_success', 'bg-success text-white', 2000);
        this.getRailSegDetails(this.orderNo);
      }, (err) => {
        console.log(err);
        this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
      }));
    }
    this.railToRemove = null;
  }

  public removeRailConfirmation(dataItem): Subject<boolean> {
    this.railToRemove = dataItem;
    return this.removeRailConfirmationSubject;
  }

  public addRailSegHandler({ sender }) {


    this.router.navigate(['PenAir/Folder/RailSegDetailsAdd', this.orderNo]);
  }

  onRailSegCellClick(e) {
    if (e.column.field != null) {
      let clickedRowItem = e.dataItem;
      this.router.navigate(['/PenAir/Folder/RailsegDetails/', clickedRowItem.FOLDERNO, clickedRowItem.SLNO + '/']);
    }
  }

  public TaxChange(value: any): void {
    if (value != undefined) {
      this.TotalValue.VATNO = value.Code;
      this.TotalValue.VATRate = value.UsrCode;
    }
    else {
      this.TotalValue.VATNO = 0;
      this.TotalValue.VATRate = 0;
    }
    this.CalculateTax();
  }

  CalculateTax() {
    //this.totalRecievable= this.sell;
    debugger;
    this.TotalValue.NetComm = +this.comm - +this.TotalValue.AGTCOMSUM;
    // this.TotalValue.VATAmt = +this.sell * +this.TotalValue.VATRate / 100;
    // this.TotalValue.VatTotal = +this.sell + (+ this.TotalValue.VATAmt);
    this.outputTaxSplit();
    this.TotalValue.TotRECEIVABLE = +this.TotalValue.VatTotal - +this.TotalValue.AGTCOM + (+this.FCCREDITAMT);
    this.TotalValue.Balance = +this.TotalValue.TotRECEIVABLE - +this.alreadyPaid - +this.otherCredits;
    this.totalRecievable = +this.TotalValue.TotRECEIVABLE;
    this.balance = + this.TotalValue.Balance;
    this.TotalValue.VatTotal = this.TotalValue.VATAmt + this.packageSell;
    this.TotalValue.VatTotal = this.common.ConvertToFixed2(+this.TotalValue.VatTotal);

  }

  public AGTaxChange(value: any): void {
    if (value != undefined) {
      this.AgentCommission.AGTCOMVATNO = value.Code;
      this.AgentCommission.AGTCOMVATRate = value.UsrCode;
    }
    else {
      this.AgentCommission.AGTCOMVATNO = 0;
      this.AgentCommission.AGTCOMVATRate = 0;
    }
    this.CalculateAGTax();
  }

  public CalculateAGTax() {
    this.AgentCommission.AGTCOMVAT = this.AgentCommission.AGTCOMSUM * this.AgentCommission.AGTCOMVATRate / 100;
    this.AgentCommission.AGTCOM = this.AgentCommission.AGTCOMSUM + this.AgentCommission.AGTCOMVAT;
  }

  onCancel() {
    this.common.isfinalsaved = false;
    sessionStorage.setItem('pagevalue', '0')
    this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo]);
  }

  onSave() {
    this.spinner.show();
    this.common.isfinalsaved = false;
    sessionStorage.setItem('pagevalue', '0');
    if (this.package) {
      this.subscription.add(this.Productapi.FolderPackageSave(this.productGridData, this.TotalValue).subscribe(res => {
        this.spinner.hide();
        this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
          this.showMsg = translations['Messages.saved_success'];
          this.toastr.success(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          });
          this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo], { skipLocationChange: true });
        }));
        // this.getProductDetails(this.orderNo);
        this.folderReload.emit('true');

      }, (err) => {
        console.log(err);
        this.spinner.hide();
        this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {
          this.showMsg = translations['Messages.Failed'];
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          });
        }));

      }
      ));
    } else {

      this.subscription.add(this.Productapi.FolderFinalSave(this.orderNo, 1).subscribe(res => {
        this.spinner.hide();
        this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
          this.showMsg = translations['Messages.saved_success'];
          this.toastr.success(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          });
          //  this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo],{skipLocationChange:true});
        }));

        this.getProductDetails(this.orderNo);

      }, (err) => {
        console.log(err);
        this.spinner.hide();
        this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {
          this.showMsg = translations['Messages.Failed'];
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          });
        }));
      }
      ));
    }

  }

  getFileDetails() {
    this.subscription.add(this.apiFile.getFileDetails()
      .subscribe(data => {

        this.homeCurrency = data.ResponseData.COMCID;
        this.hcExRate = data.ResponseData.COMExRate;
        this.bsptaxNo = data.ResponseData.BSPTAXTAXNO;
        this.apiShared.Setapishareddata(this.homeCurrency, 'homeCurrency');
        this.apiShared.Setapishareddata(this.hcExRate, 'hcExRate');
        this.apiShared.Setapishareddata(this.bsptaxNo, 'bspTaxNo');
      }));
  }
  // public confirmFolderDelete() {

  //   this.deletefolder = true;
  // }

  onDeleteFolder(shouldRemove: boolean) {
    if (shouldRemove) {
      this.subscription.add(this.Productapi.FolderDelete(this.folderDelete).subscribe(res => {
        if (res !== null && res !== undefined) {
          if (res.ResponseData.messageid === 42) {
            this.toastr.success(res.ResponseData.message, "", {
              timeOut: 3000,
              disableTimeOut: false,
              positionClass: 'toast-top-center',
              closeButton: true
            });
          } else {
            this.toastr.warning(res.ResponseData.message, "", {
              timeOut: 3000,
              disableTimeOut: false,
              positionClass: 'toast-top-center',
              closeButton: true
            })
          }
        }
      }));
    }
    this.deletefolder = false;
  }

  public CalculateAgentComm() {
    if (this.TotalValue.AGTCOMVATNO !== 0 && this.TotalValue.AGTCOMVATNO !== null) {
      this.AGTaxselectedItem = this.Tax.find(item => item.Code == this.TotalValue.AGTCOMVATNO);
      this.AgentCommission.AGTCOMVATRate = this.AGTaxselectedItem.UsrCode;
    }

    this.AgentCommission.AGTCOM = this.TotalValue.AGTCOM;
    this.AgentCommission.AGTCOMSUM = this.TotalValue.AGTCOMSUM,
      this.AgentCommission.AGTCOMVAT = this.TotalValue.AGTCOMVAT;
    this.agentComm = true;
  }

  confirmAgentComm(save: boolean) {
    if (save) {
      this.TotalValue.AGTCOM = this.AgentCommission.AGTCOM;
      this.TotalValue.AGTCOMSUM = this.AgentCommission.AGTCOMSUM,
        this.TotalValue.AGTCOMVAT = this.AgentCommission.AGTCOMVAT;
      this.TotalValue.AGTCOMVATNO = this.AgentCommission.AGTCOMVATNO;
      this.CalculateTax();
    }

    this.agentComm = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339

  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }

  private saveCurrent(): void {
    if (this.formGroup) {
      this.save(this.formGroup.value, this.isNew);
      this.closeEditor();
    }
  }

  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public save(product: any, isNew: boolean): void {

    Object.assign(
      this.productGridAirDataForRefund.find(({ ITEMNO }) => ITEMNO === product.ITEMNO),
      product
    );

  }

  handleFiltersupplier(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
    } else {
      this.autocompletearrival.toggle(false);
    }
  }

  handleFiltersupplier1(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
    } else {
      this.autocompletesupplier1.toggle(false);
    }
  }

  valuechange(value: any, valueb, dataItem): void {

    debugger;
    if (value != undefined) {


      dataItem.BUYAMT = value.value;
      dataItem.HCBUYAMT = value.value * dataItem.ProductExh / dataItem.FolderExh;
      dataItem.HCSellAMT = dataItem.SELLAMT * dataItem.FolderExh;
      dataItem.COMMAMT = dataItem.HCSellAMT - dataItem.HCBUYAMT;



    }
    if (valueb != undefined) {
      dataItem.SELLAMT = valueb.value;
      dataItem.HCBUYAMT = dataItem.BUYAMT * dataItem.ProductExh / dataItem.FolderExh;
      dataItem.HCSellAMT = dataItem.SELLAMT * dataItem.FolderExh;
      dataItem.COMMAMT = dataItem.HCSellAMT - dataItem.HCBUYAMT;

    }


  }

  onChangeOtherrefenrece(value: any, dataItem): void {
    debugger;
    if (value != undefined) {
      dataItem.RefNo = value;
    }
  }

  onSelect(value: any) {

  }

  valuechangeAir(value: any, valueb, dataItem): void {

    debugger;
    if (value != undefined) {
      dataItem.BUYAMT = value.value;
      dataItem.HCBUYAMT = value.value * dataItem.ProductExh / dataItem.FolderExh;;
      dataItem.HCSellAMT = dataItem.SELLAMT * dataItem.ProductExh;
      dataItem.COMMAMT = dataItem.HCSellAMT - dataItem.HCBUYAMT;

    }
    if (valueb != undefined) {
      dataItem.SELLAMT = valueb.value;
      dataItem.HCBUYAMT = dataItem.BUYAMT * dataItem.ProductExh / dataItem.FolderExh;;
      dataItem.HCSellAMT = dataItem.SELLAMT * dataItem.ProductExh;
      dataItem.COMMAMT = dataItem.HCSellAMT - dataItem.HCBUYAMT;

    }
  }

  public SupplierValueChange(value: any, dataItem): void {
    debugger;
    if (value == '') {
      dataItem.SUPPPRTYCODE = null;
      dataItem.Supplier = null;
    }
    else {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);
      dataItem.SUPPPRTYCODE = this.supplierSelectedItem.Code;
      dataItem.Supplier = this.supplierSelectedItem.Name;
    }

  }

  public SupplierValueChange1(value: any, dataItem): void {
    debugger;
    if (value == '') {
      dataItem.SUPPPRTYCODE = null;
      dataItem.Supplier = null;
    }
    else {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);

      dataItem.SUPPPRTYCODE = this.supplierSelectedItem.Code;
      dataItem.Supplier = this.supplierSelectedItem.Name;
    }

  }

  public cellCloseHandler1(args: any) {
    debugger;
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {

    }
  }

  public cellCloseHandler(args: any) {
    debugger;
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {

    }
  }

  public cellClickHandlerOther({ isEdited, dataItem, rowIndex, columnIndex }, gridid: any): void {
    debugger;
    if (columnIndex == 6) {


    }





  }


  public OneditRow(dataItem, rowIndex): void {
    debugger;

    this.formGroupOther = createFormGroupOther(dataItem);

    this.editedRowIndex = rowIndex;
    if (this.grid1 != undefined)
      this.grid1.editRow(rowIndex, this.formGroupOther);

  }
  private saveCurrentOther(): void {
    if (this.formGroupOther && this.formGroupOther.value["ITEMNO"] != null) {
      this.saveOther(this.formGroupOther.value, this.isNew);
      this.closeEditorOther();
    }
  }
  onChangeOther(event: any, dataitem: any) {
    var datedata = event.value//this.common.transformDate((event.value));
    if (Boolean(this.common.ValidDateRange(datedata)) == false)//Case id-12675
    {

      dataitem.Invaliddate = true;
      dataitem.BookingDate = datedata;

    }
    else {
      dataitem.Invaliddate = false;
      dataitem.BookingDate = datedata;
    }


    debugger;
  }

  private closeEditorOther(): void {
    if (this.grid1 != undefined)
      this.grid1.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowOtherIndex = undefined;
    this.formGroupOther = undefined;
  }
  public saveOther(product: any, isNew: boolean): void {

    Object.assign(
      this.productGridDataForRefund.find(({ ITEMNO }) => ITEMNO === product.ITEMNO),
      product
    );

  }
  public getFolderStatus() {


    this.folderStatus = this.apiShared.FolderStatus;
    if (this.folderStatus == "Refund Request") {
      this.labelchange = true;
    }
  }

  public isANumber(str) {
    return !/\D/.test(str);
  }
  public onSaveRefund() {
    debugger;
    this.spinner.show();
    this.formvalid = true;
    this.validrefund = true;
    var airticketData = this.productGridAirDataForRefund;
    var OtherProductData = this.productGridDataForRefund;
    this.Productapi.AirList = [];
    this.Productapi.AirList = this.productGridAirDataForRefund;
    this.Productapi.OtherList = [];
    this.Productapi.OtherList = this.productGridDataForRefund;

    this.productGridDataForRefund.forEach(obj => {

      // if ((obj.BookingDate == null || obj.BookingDate == undefined && this.formvalid == true) && obj.TYPE == "Ticket") {
      //   this.subscription.add(this.translateapi.get(['Messages.TKTBKGDate']).subscribe((translations) => {
      //     this.showMsg = translations['Messages.TKTBKGDate'];
      //     this.common.showWARNINGtoastrmsg(this.showMsg, 3000, true, false);
      //   }));
      //   this.spinner.hide();
      //   this.validrefund = false;
      //   this.formvalid = false;
      //   return
      // }
      var dte = Boolean(this.common.ValidDateRange(obj.BookingDate));
      if (obj.BookingDate != null && dte == false && this.formvalid == true) {
        this.subscription.add(this.translateapi.get(['Messages.InvalidTKTBKGDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidTKTBKGDate'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 3000, true, false);
        }));
        this.spinner.hide();
        this.validrefund = false;
        this.formvalid = false;
        return
      }
      else if (obj.BookingDate != null && dte == true) {
        obj.BookingDate = this.common.transformDate(obj.BookingDate);
      }
      if (obj.SUPPPRTYCODE == null || obj.SUPPPRTYCODE == undefined && this.formvalid == true) {
        this.subscription.add(this.translateapi.get(['Messages.Supplierreqd']).subscribe((translations) => {
          this.showMsg = translations['Messages.Supplierreqd'];
          this.toastr.warning(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        this.spinner.hide();
        this.validrefund = false;
        this.formvalid = false;
        return
      }
      // if ((obj.RefNo == null || obj.RefNo == "") && this.formvalid == true && obj.TYPE == "Ticket") {
      //   this.subscription.add(this.translateapi.get(['Messages.TKTBKGReqired']).subscribe((translations) => {
      //     this.showMsg = translations['Messages.TKTBKGReqired'];
      //     this.toastr.warning(this.showMsg, "", {
      //       timeOut: 3000,
      //       disableTimeOut: false,
      //       closeButton: true
      //     })
      //   }));
      //   this.spinner.hide();
      //   this.validrefund = false;
      //   this.formvalid = false;
      //   return
      // }

    })

    if (this.validrefund == true) {


      this.subscription.add(this.Productapi.RefundFolder().subscribe(res => {

        if (res["ResponseData"] != null && res["ResponseData"] != "") {


          if (this.isANumber(res["ResponseData"]) == true) {
            debugger;
            if (this.lockStatus == "0") {
              this.deletePenLock("Folder", this.orderNo);
            }

            var dataorderno = res["ResponseData"];
            this.orderNo = dataorderno;
            this.publiclayout.headerPassenger = res["ResponseData"];
            this.getLockDetails("Folder", res["ResponseData"], this.apiShared.uid, this.Productapi.formData.ProjNo);

            this.items = [];
            this.Productapi.List = [];
            this.onCancelClick();

            this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
            this.detailcomponent.orderNo = res["ResponseData"];

            this.folder.orderNo = this.orderNo;
            this.folderStatus = "Refund Request";


            this.labelchange = true;
            this.getProductDetails(res["ResponseData"]);
            this.getAirSegDetails(res["ResponseData"]);
            this.getRailSegDetails(res["ResponseData"]);

            this.loadpages();
            this.folderStatus = "Refund Request";
            this.publiclayout.folderstatus = this.folderStatus;
            this.closeRefundpRod('close');




            this.labelchange = true;

            this.apiShared.Setapishareddata(this.folderStatus, 'FolderStatus');
            this.formeditable = true;
            this.saveRefundbutton = true;
            this.disableRefundbutton = false;
            this.saveRefundbutton = true;
            this.detailcomponent.disablecreditnote = false;
            this.detailcomponent.disablelink = true;
            this.detailcomponent.disableinvoice = true;

            this.detailcomponent.disablelinkReinvoice = true;//12728
            this.detailcomponent.disableRefund = true;//12728
            this.foldersaved = true;

            this.showtotal = true;

            this.folder.GetFolderDetailsById(this.orderNo);
            this.subscription.add(this.translateapi.get(['Messages.RefundCompleted']).subscribe((translations) => {

              this.showMsg = translations['Messages.RefundCompleted'] + " " + res["ResponseData"];
              this.toastr.success(this.showMsg, "", {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              })

            }));
            this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo]);

          }
          else {
            this.spinner.hide();
            this.common.showWARNINGtoastrmsg(res["ResponseData"], 3000, true, false);
          }


        }
        else {
          this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
            this.spinner.hide();
            this.showMsg = translations['Messages.some_error_in_save'];
            this.toastr.warning(this.showMsg)
          }));
        }

      }))

    }

  }
  public showTooltip(e: MouseEvent, dataItem: any): void {
    debugger;

    dataItem.TTip = true;

  }
  public hideTooltip(e: MouseEvent, dataItem: any) {

    dataItem.TTip = false;
  }
  expenseClose(event) {
    if (event == 'reload') {
      this.getProductDetails(this.orderNo);
    }
    this.showExpense = false;
  }
  public confirmFolderDelete() {
    var flag = 0;

    for (let data of this.ProductList) {
      if (
        data.SupplierDeposit != 0 &&
        data.SupplierDeposit != null &&
        data.SupplierDeposit != undefined
      ) {
        flag = 1;
        this.subscription.add(
          this.translateapi
            .get(["Messages.SupplierDepositAlreadyMade"])
            .subscribe(translations => {
              this.showMsg =
                translations["Messages.SupplierDepositAlreadyMade"];

              this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
            })
        );
        return false;
      }
    }
    if (flag == 0) this.deletefolder = true;
  }

  public updateAirSegsort() {
    this.spinner.show()
    // console.log("airlist==",this.airSegGridData);
    let i = 1;
    this.airSegGridData.forEach(obj => {
      obj.SLNO = i;
      i = i + 1;

    })

    this.subscription.add(this.Productapi.UpdateAirlineSegOrder(this.airSegGridData).subscribe(res => {
      if (res !== null && res !== undefined) {
        if (res["ResponseData"] == "Pass") {
          this.spinner.hide();
          this.showMsg = "successfully updated";
          this.toastr.info(this.showMsg, "", {
            timeOut: 2000,
            disableTimeOut: false,
            closeButton: true
          })
        }
        else {
          this.spinner.hide();
          this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {

            this.showMsg = translations['Messages.Failed'];
            this.toastr.warning(this.showMsg)
          }));
        }
      }
      else {
        this.spinner.hide();
      }
    }));

  }

  public updateproductsortorder() {

    this.spinner.show()
    //console.log("airlist==",this.productGridData);
    let i = 1;
    this.productGridData.forEach(obj => {
      obj.ITEMNO = i;
      i = i + 1;
    })


    this.subscription.add(this.Productapi.UpdateProductSortOrder(this.productGridData).subscribe(res => {
      if (res !== null && res !== undefined) {
        if (res["ResponseData"] == "Pass") {
          this.spinner.hide();
          this.showMsg = "successfully updated";
          this.toastr.info(this.showMsg, "", {
            timeOut: 2000,
            disableTimeOut: false,
            closeButton: true
          })
        }
        else {
          this.spinner.hide();
          this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {

            this.showMsg = translations['Messages.Failed'];
            this.toastr.warning(this.showMsg)
          }));
        }
      }
      else {
        this.spinner.hide();
      }
    }));

  }

  public updateRailSegsort() {
    this.spinner.show()
    // console.log("airlist==",this.airSegGridData);
    let i = 1;
    this.railSegGridData.forEach(obj => {
      obj.SLNO = i;
      i = i + 1;

    })

    this.subscription.add(this.Productapi.UpdateRailSegOrder(this.railSegGridData).subscribe(res => {
      if (res !== null && res !== undefined) {
        if (res["ResponseData"] == "Pass") {
          this.spinner.hide();
          this.showMsg = "successfully updated";
          this.toastr.info(this.showMsg, "", {
            timeOut: 2000,
            disableTimeOut: false,
            closeButton: true
          })
        }
        else {
          this.spinner.hide();
          this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {

            this.showMsg = translations['Messages.Failed'];
            this.toastr.warning(this.showMsg)
          }));
        }
      }
      else {
        this.spinner.hide();
      }
    }));

  }
  showDefaultCharge = false;
  public openDefaultCharges() {
    this.showDefaultCharge = true;
  }
  defaultChargeClose(status) {
    this.showDefaultCharge = false;
    if (status == 'saved') {
      this.getProductDetails(this.orderNo);
      this.folderReload.emit('true');
    }

  }
  public IsProductExist(Type): boolean {

    if (this.Products.includes(Type)) {
      return true;
    }
    else {
      return false;
    }
  }
  onTabSelectForDetail(event) {
    debugger;
    this.isAll = true;
    this.getProductDetails(this.orderNo);
  }
  GetDetailedproduct(item: any, Type: any) {
    debugger;
    if (item != 0) {

      this.isAll = false;
      this.Productapi.formparmData = new Object;
      this.Productapi.formparmData.OrderNo = this.orderNo;
      this.Productapi.formparmData.TYPE = Type;
      this.Productapi.formparmData.formeditable = this.formeditable;
      if (this.child == undefined) {
        this.child = new DetailedProductGridComponent(this.spinner, this.Productapi, this.translateapi, this.common, this.router, this.hotelapi, this.carapi

          , this.tktapi, this.railapi, this.cruiseapi, this.tourapi
          , this.othersapi, this.insuranceapi, this.transfersapi)
      }
      this.child.getProductDetails();
    }
    else {
      this.isAll = true;
      this.getProductDetails(this.orderNo);
    }



  }

  getTaxLookup(country: any) {


    this.subscription.add(this.lookupApi.GetLookupById('OutputTaxMaster', country, "''").subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.OutputTax = data["ResponseData"];
          this.common.OutputTax = data["ResponseData"];
          // alert(this.parentForm.controls.ITYPE.value);   

          if (this.TotalValue.VATNO != "") {

            this.OutputTaxselectedItem = this.OutputTax.find(item => item.Code == this.TotalValue.VATNO);
            this.common.OutTaxselectedItem = this.common.OutputTax.find(item => item.Code == this.TotalValue.VATNO);
            this.outputTaxChange(this.OutputTaxselectedItem);

          }

        }
      }
    ));
  }

  outputTaxChange(value: any) {
    if (value != undefined && value != null) {
      this.TotalValue.VATNO = (value.Code);
      this.subscription.add(
        this.common.GetTaxSplit(value.Code).subscribe(x => {
          this.outputTaxDetails = x["ResponseData"]
          this.CalculateTax();
        }))
        ;

    }
    else {
      this.TotalValue.VATAmt = 0;
    }

  }

  outputTaxSplit() {
    if (this.outputTaxDetails != undefined && this.outputTaxDetails != null && this.outputTaxDetails.length > 0) {

      let TaxCategoryval = this.outputTaxDetails.find(x => x.TaxCategory.toLowerCase() == "base");
      if (TaxCategoryval != undefined && TaxCategoryval != null) {

        TaxCategoryval.TaxAmount = this.sell;
        if (this.sell != undefined) {
          this.outputTaxDetails.forEach(element => {
            let level = element.Level;
            let TaxAmount = element.TaxAmount;
            this.outputTaxDetails.forEach(element1 => {
              if (element1.FromLevel == level) {
                element1.TaxAmount = (TaxAmount * element1.TaxRate) / 100;
                element1.TaxAmount = +element1.TaxAmount.toFixed(2);
              }
            });

          });

          this.TotalValue.VATAmt = (

            this.outputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes').reduce((sum, current) => sum + current.TaxAmount, 0)
          )
          var TaxPer = this.outputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes' && x.TaxCategory.toLowerCase() != 'cess').reduce((sum, current) => sum + current.TaxRate, 0);
          this.TotalValue.VATRate = (TaxPer);


        }
      }
    }
  }


  // getTaxLookup(country: any) {


  //   this.subscription.add(this.lookupApi.GetLookupById('OutputTaxMaster', country, "''").subscribe(
  //     (data) => {
  //       if (data != null && data != undefined) {
  //         this.OutputTax = data["ResponseData"];
  //         this.common.OutputTax = data["ResponseData"];
  //         // alert(this.parentForm.controls.ITYPE.value);   

  //         if (this.TotalValue.VATNO != "") {

  //           this.OutputTaxselectedItem = this.OutputTax.find(item => item.Code == this.TotalValue.VATNO);
  //           this.common.OutTaxselectedItem = this.common.OutputTax.find(item => item.Code == this.TotalValue.VATNO);
  //           this.outputTaxChange(this.OutputTaxselectedItem);

  //         }

  //       }
  //     }
  //   ));
  // }

  // outputTaxChange(value: any) {
  //   if (value != undefined && value != null) {
  //     this.TotalValue.VATNO = (value.Code);
  //     this.subscription.add(
  //       this.common.GetTaxSplit(value.Code).subscribe(x => {
  //         this.outputTaxDetails = x["ResponseData"]
  //         this.CalculateTax();
  //       }))
  //       ;

  //   }
  //   else {
  //     this.TotalValue.VATAmt = 0;
  //   }

  // }

  // outputTaxSplit() {
  //   if (this.outputTaxDetails != undefined && this.outputTaxDetails != null && this.outputTaxDetails.length > 0) {

  //     let TaxCategoryval = this.outputTaxDetails.find(x => x.TaxCategory.toLowerCase() == "base");
  //     if (TaxCategoryval != undefined && TaxCategoryval != null) {

  //       TaxCategoryval.TaxAmount = this.sell;
  //       if (this.sell != undefined) {
  //         this.outputTaxDetails.forEach(element => {
  //           let level = element.Level;
  //           let TaxAmount = element.TaxAmount;
  //           this.outputTaxDetails.forEach(element1 => {
  //             if (element1.FromLevel == level) {
  //               element1.TaxAmount = (TaxAmount * element1.TaxRate) / 100;
  //               element1.TaxAmount = +element1.TaxAmount.toFixed(2);
  //             }
  //           });

  //         });

  //         this.TotalValue.VATAmt = (

  //           this.outputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes').reduce((sum, current) => sum + current.TaxAmount, 0)
  //         )
  //         var TaxPer = this.outputTaxDetails.filter(x => x.Fieldtype.toLowerCase() != 'notes' && x.TaxCategory.toLowerCase() != 'cess').reduce((sum, current) => sum + current.TaxRate, 0);
  //         this.TotalValue.VATRate = (TaxPer);


  //       }
  //     }
  //   }
  // }


}
