import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { TourDetails } from '../../Models/tour-details';
import { TourService } from '../../Services/tour.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { CurrencyExchangerateService } from '../../Services/currency-exchangerate.service';
import { CurrencyExchangerate } from '../../Models/currency-exchangerate';
import { ShareDataService } from '../../Services/share-data.service';
import { NgxSpinnerService } from "ngx-spinner";

import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '../../Services/file.service';
import { FileDetails } from '../../Models/file-details';
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription, Subject } from 'rxjs';
import { SupplierMasterService } from '../../Services/supplier-master.service';
import { ToastrService } from 'ngx-toastr';
import { PocreationService } from '../../Services/pocreation.service';
import { ProductUpdatePODTO } from '../../Models/pocreation-detail';
import { FolderpriceService } from '../../Services/folderprice.service';
@Component({
  selector: 'app-tour-details-edit',
  templateUrl: './tour-details-edit.component.html',
  styleUrls: ['./tour-details-edit.component.scss']
})
export class TourDetailsEditComponent implements OnInit {
  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  private subscription: Subscription = new Subscription();
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  @ViewChild("apppaymenttermsedit", { read: false, static: false }) public payterm: any;
  @ViewChild("appfchcdetailsedit", { read: false, static: false }) public fchcdetails: any;

  public opened = false;
  public PONO;
  folderExrate: any;
  openSearch = false;
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  fldrCurrency: any;
  private exRate: any;
  folderEditMode: any;
  ucode: string;
  currencyExrate: CurrencyExchangerate;
  tourEditForm: FormGroup;
  submitted = false;
  prdtUpdatePODTO: ProductUpdatePODTO;
  loaded = false;
  Uname: any = "";
  savebutton = true;
  public showMsg: any;
  public pocreated = true;
  //hotelDetailModal=HotelDetail;
  public statusList: Array<string> = ["OK", "Pending", "Cancelled", "Confirmed"];
  public paymethodList: Array<string> = ["Credit", "Company Card", "Customer Card", "Customer Pay Locally"];
  public CommonList: Array<string> = ["Purchase", "Gross", "Sales"];
  public CardList: Array<LookUpDetails> = [];
  CurrencyList: Array<LookUpDetails["ResponseData"]> = [];
  BookedByList: Array<LookUpDetails["ResponseData"]> = [];
  public CurrencyselectedItem: LookUpDetails["ResponseData"];
  public BookedByselectedItem: LookUpDetails["ResponseData"];
  constructor(private apiPO: PocreationService, public common: CommonserviceService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private api: TourService, private lookupApi: LookUpDetailsService, private apiExRate: CurrencyExchangerateService,
    private apiShared: ShareDataService, private flashMessage: FlashMessagesService, private translateapi: TranslateService, private spinner: NgxSpinnerService, private apiFile: FileService, private supplier: SupplierMasterService, private toastr: ToastrService, private objFolderPrice: FolderpriceService) { }
  private guid: string = this.route.snapshot.params['guidItinerary'];//'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/';
  private orderno = this.route.snapshot.params['orderNo']
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];
  creditLimitMsg = false;
  creditLimiFlag = false;
  msg: any;
  public supplierSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelected: String;
  public projNo: any;
  homeCurrency: any;
  hcExRate: any;
  bookingDate: any //337
  startFromDate: any //337
  endDate: any //337
  lockStatus: any;
  folderStatus: any;
  formeditable: any = true;
  depositDueDate: any//337
  FC_Tot_BuyInitalvalue: any = 0;
  FCSell: any = 0;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  ngOnInit() {
    //case id : 13777 
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    } else {
      this.formeditable = false;

    }
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;

    }
    // case id : 13777

    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderno !== orderno) {
        return this.router.navigate(['PenAir/Home']);
      }
    this.hcExRate = this.apiShared.hcExRate;

    this.homeCurrency = this.apiShared.homeCurrency;
    this.folderEditMode = "true";
    //this.apiShared.folderEditMode=this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.Uname = this.apiShared.uname;
    //   this.folderEditMode="false";
    // this.apiShared.folderEditMode=this.folderEditMode;
    this.getFolderValues();

    this.tourEditForm = this.formBuilder.group({


      FOLDERNO: [this.orderno],
      GUIDItinerary: [''],
      // SUPPRTYUSERCODE: ['', Validators.required],
      SUPPNAME: [''],
      SUPPCODE: ['', Validators.required],
      TYPE: ['Tour'],
      PICKUP: [''],
      DROPOFF: [''],
      DATE1: [new Date()],
      DATE2: [new Date()],
      DAYS: [0],
      STATUS: ['OK'],
      PAYABLE: [0],
      VATCODE: [0],
      VATPER: [0],
      VOUCHER: [''],
      PAYMODE: ['Credit'],
      CURCODE: [this.homeCurrency],
      AMTLC: [0],
      AMTHC: [0],
      CommOn: ['Purchase'],
      PROJNO: [this.projNo],
      ITYPE: ['Tour'],
      Provider: [''],
      GDS: [''],
      PNR: [''],
      CURExRate: [this.hcExRate],
      FCBuy: [0],
      FCCommPer: [0],
      FCCommAmt: [0],
      FCSell: [0],
      HCCommPer: [0],
      HCCommAmt: [0],
      HCSell: [0],
      ItnryNotes: [''],
      StartTIME: [0],
      EndTIME: [0],
      LowFB: [''],
      LowFare: [0],
      HighFB: [''],
      HighFare: [0],
      Fareoffered: [0],
      PotentialSaving: [0],
      ActualSaving: [0],
      PDNO: [0],
      PDTYPENO: [0],
      Forgone: [0],
      AddRemarks: [''],
      PDAMOUNT: [0],
      BookingDate: [new Date()],
      PONO: [0],
      PONOYEARID: [''],
      PAYMENTID: [0],
      PMTID: [''],
      DEFDEPPER: [0],
      DEFDEP: [0],
      DepositDueDate: [''],
      BalanceDueDate: [''],
      BalanceDueAmount: [0],
      LOYALTYPOINTS: [0],
      LOYALTYPOINTSMEMBNO: [''],
      TOMSVATTAXNO: [0],
      TOMSVATAMT: [0],
      TOMSVATCID: [''],
      PDUNIQUEREF: [''],
      LOYALTYPOINTSAMT: [0],
      PDFCURID: [this.homeCurrency],
      PDEXRATE: [this.hcExRate],
      PDFCAMT: [0],
      SELLTAXNO: [0],
      SELLTAXRATE: [0],
      FC_SELLTAXAMT: [0],
      HC_SELLTAXAMT: [0],
      PONOCCDeferredPosted: [0],
      BOOKEDBY: this.Uname,
      CONFIRMATIONNO: [''],
      TaxExcludingSalesTax: [0],
      BaseFare: [0],
      GSTHSTOnComm: 0,

      //12698
      CostingSupplierGrossValue: 0,
      CostingSupplierServiceFee: 0,
      CostingTaxNo: 0,
      CostingTaxRate: 0,
      CostingSupplierTaxAmount: 0,
      CostingSupplierNetBuyPrice: 0,
      CostingCustomerSalesValue: 0,
      CostingCustomerServiceFee: 0,
      CostingCustomerTaxAmount: 0,
      CostingCustomerNetSellPrice: 0,
      CostingVATType: 0,
      Markup: [0],
      CostofSale: [0],
      CostingSuppComm: [0],
      SupplierDeposit: [0],
      CountryID: ['']
      //12698
    });
    // alert(this.tourEditForm.controls.SUPPCODE.value)
    if (this.guid != null)
      this.getTourDetails(this.orderno, this.guid);


    if (this.guid == null) {
      this.FormLoyalityPointsChange();

      this.loaded = true;

    }
    // this.getFileDetails();
    this.subscription.add(this.lookupApi.GetAllLookup('CURRENCY').subscribe(
      (data) => {
        // if(this.tourEditForm.controls.CURCODE.value==null&&this.guid==null)
        // {
        //   this.tourEditForm.controls.CURCODE.setValue(this.homeCurrency); 
        // }
        if (data != null && data != undefined) {
          this.CurrencyList = data["ResponseData"]
          if (this.tourEditForm.controls.CURCODE.value != "") {


            this.CurrencyselectedItem = this.CurrencyList.find(item => item.Code == this.tourEditForm.controls.CURCODE.value);
          }
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.BookedByList = data["ResponseData"]
          if (this.tourEditForm.controls.BOOKEDBY.value != "") {

            this.BookedByselectedItem = this.BookedByList.find(item => item.Name == this.tourEditForm.controls.BOOKEDBY.value);
          }
        }
      }
    ));

    this.onChanges();

  }

  // convenience getter for easy access to form fields
  get f() { return this.tourEditForm.controls; }
  public loadSupplier() {
    this.subscription.add(this.lookupApi.GetAllLookup('SUPPLIERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.SupplierList = data["ResponseData"]
          // alert(this.tourEditForm.controls.SUPPCODE.value)     ;
          if (this.tourEditForm.controls.SUPPCODE.value != "") {

            this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.tourEditForm.controls.SUPPCODE.value);
            this.supplierSelected = this.supplierSelectedItem.Name;
            this.tourEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID);

          }
        }
      }
    ));
  }
  public CurrencyExRatechange(): void {
    this.CalculateForm();
  }
  loadsuppliermaster() {
    if (this.tourEditForm.controls.SUPPCODE.value != '' && this.tourEditForm.controls.SUPPCODE.value != null) {
      this.subscription.add(this.lookupApi.findSupplier(this.tourEditForm.controls.SUPPCODE.value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
            if (this.tourEditForm.controls.SUPPCODE.value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.tourEditForm.controls.SUPPCODE.value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
              }
            }
          }
        }
      ));
    }
  }
  handleFiltersupplier(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  getTourDetails(id, guid) {
    this.spinner.show();
    this.subscription.add(this.api.getTour(id, guid, false)
      .subscribe(data => {
        if (data != null && data != undefined) {
          // console.log('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
          //this.hotelEditForm = this.formBuilder.group(data);          
          this.tourEditForm.setValue({
            FOLDERNO: data.ResponseData.FOLDERNO,
            GUIDItinerary: data.ResponseData.GUIDItinerary,
            // SUPPRTYUSERCODE:  data.ResponseData.SUPPRTYUSERCODE,              
            SUPPNAME: data.ResponseData.SUPPNAME,
            SUPPCODE: data.ResponseData.SUPPCODE,
            TYPE: "Tour",
            PICKUP: data.ResponseData.PICKUP,
            DROPOFF: data.ResponseData.DROPOFF,
            DATE1: data.ResponseData.DATE1 != null ? (data.ResponseData.DATE1.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.DATE1)) : null,
            // DATE1 :new Date(data.ResponseData.DATE1),
            DATE2: data.ResponseData.DATE2 != null ? (data.ResponseData.DATE2.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.DATE2)) : null,
            //DATE2 :new Date(data.ResponseData.DATE2),
            DAYS: data.ResponseData.DAYS,
            STATUS: data.ResponseData.STATUS,
            PAYABLE: data.ResponseData.PAYABLE,
            VATCODE: data.ResponseData.VATCODE,
            VATPER: data.ResponseData.VATPER,
            VOUCHER: data.ResponseData.VOUCHER,
            PAYMODE: data.ResponseData.PAYMODE,
            CURCODE: data.ResponseData.CURCODE,
            AMTLC: data.ResponseData.AMTLC,
            AMTHC: data.ResponseData.AMTHC,
            CommOn: data.ResponseData.CommOn,
            PROJNO: data.ResponseData.PROJNO,
            ITYPE: "Tour",
            Provider: data.ResponseData.Provider,
            GDS: data.ResponseData.GDS,
            PNR: data.ResponseData.PNR,
            CURExRate: data.ResponseData.CURExRate,
            FCBuy: data.ResponseData.FCBuy,
            FCCommPer: data.ResponseData.FCCommPer,
            FCCommAmt: data.ResponseData.FCCommAmt,
            FCSell: data.ResponseData.FCSell,
            HCCommPer: data.ResponseData.HCCommPer,
            HCCommAmt: data.ResponseData.HCCommAmt,
            HCSell: data.ResponseData.HCSell,
            ItnryNotes: data.ResponseData.ItnryNotes,
            StartTIME: data.ResponseData.StartTIME,
            EndTIME: data.ResponseData.EndTIME,
            LowFB: data.ResponseData.LowFB,
            LowFare: data.ResponseData.LowFare,
            HighFB: data.ResponseData.HighFB,
            HighFare: data.ResponseData.HighFare,
            Fareoffered: data.ResponseData.Fareoffered,
            PotentialSaving: data.ResponseData.PotentialSaving,
            ActualSaving: data.ResponseData.ActualSaving,
            PDNO: data.ResponseData.PDNO,
            PDTYPENO: data.ResponseData.PDTYPENO,
            Forgone: data.ResponseData.Forgone,
            AddRemarks: data.ResponseData.AddRemarks,
            PDAMOUNT: data.ResponseData.PDAMOUNT,
            BookingDate: data.ResponseData.BookingDate != null ? (data.ResponseData.BookingDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.BookingDate)) : null,
            //BookingDate :new Date(data.ResponseData.BookingDate),
            PONO: data.ResponseData.PONO,
            PONOYEARID: data.ResponseData.PONOYEARID,
            PAYMENTID: data.ResponseData.PAYMENTID,
            PMTID: data.ResponseData.PMTID,
            DEFDEPPER: data.ResponseData.DEFDEPPER,
            DEFDEP: data.ResponseData.DEFDEP,
            DepositDueDate: data.ResponseData.DepositDueDate != null ? (data.ResponseData.DepositDueDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.DepositDueDate)) : null,
            //DepositDueDate :new Date(data.ResponseData.DepositDueDate),
            BalanceDueDate: data.ResponseData.BalanceDueDate != null ? (data.ResponseData.BalanceDueDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.BalanceDueDate)) : null,
            ///BalanceDueDate :new Date(data.ResponseData.BalanceDueDate),
            BalanceDueAmount: data.ResponseData.BalanceDueAmount,
            LOYALTYPOINTS: data.ResponseData.LOYALTYPOINTS,
            LOYALTYPOINTSMEMBNO: data.ResponseData.LOYALTYPOINTSMEMBNO,
            TOMSVATTAXNO: data.ResponseData.TOMSVATTAXNO,
            TOMSVATAMT: data.ResponseData.TOMSVATAMT,
            TOMSVATCID: data.ResponseData.TOMSVATCID,
            PDUNIQUEREF: data.ResponseData.PDUNIQUEREF,
            LOYALTYPOINTSAMT: data.ResponseData.LOYALTYPOINTSAMT,
            PDFCURID: data.ResponseData.PDFCURID,
            PDEXRATE: data.ResponseData.PDEXRATE,
            PDFCAMT: data.ResponseData.PDFCAMT,
            SELLTAXNO: data.ResponseData.SELLTAXNO,
            SELLTAXRATE: data.ResponseData.SELLTAXRATE,
            FC_SELLTAXAMT: data.ResponseData.FC_SELLTAXAMT,
            HC_SELLTAXAMT: data.ResponseData.HC_SELLTAXAMT,
            PONOCCDeferredPosted: data.ResponseData.PONOCCDeferredPosted,
            BOOKEDBY: data.ResponseData.BOOKEDBY,
            CONFIRMATIONNO: data.ResponseData.CONFIRMATIONNO,
            TaxExcludingSalesTax: data.ResponseData.TaxExcludingSalesTax,
            BaseFare: data.ResponseData.BaseFare,
            GSTHSTOnComm: 0,
            //12698
            CostingSupplierGrossValue: data.ResponseData.CostingSupplierGrossValue,
            CostingSupplierServiceFee: data.ResponseData.CostingSupplierServiceFee,
            CostingTaxNo: data.ResponseData.CostingTaxNo,
            CostingTaxRate: data.ResponseData.CostingTaxRate,
            CostingSupplierTaxAmount: data.ResponseData.CostingSupplierTaxAmount,
            CostingSupplierNetBuyPrice: data.ResponseData.CostingSupplierNetBuyPrice,
            CostingCustomerSalesValue: data.ResponseData.CostingCustomerSalesValue,
            CostingCustomerServiceFee: data.ResponseData.CostingCustomerServiceFee,
            CostingCustomerTaxAmount: data.ResponseData.CostingCustomerTaxAmount,
            CostingCustomerNetSellPrice: data.ResponseData.CostingCustomerNetSellPrice,
            CostingVATType: data.ResponseData.CostingVATType,
            Markup: data.ResponseData.CostingCustomerServiceFee,
            CostofSale: data.ResponseData.CostingCustomerSalesValue,
            CostingSuppComm: data.ResponseData.CostingSuppComm,
            //12698
            SupplierDeposit: data.ResponseData.SupplierDeposit,
            CountryID: ['']
          });
          this.FC_Tot_BuyInitalvalue = this.tourEditForm.controls.FCBuy.value
          this.FCSell = this.tourEditForm.controls.FCSell.value;

          this.spinner.hide();
          this.FormLoyalityPointsChange();
          this.loadsuppliermaster();

          this.PONO = this.tourEditForm.controls.PONO.value;

          if (this.PONO > 0) {

            this.pocreated = false;
          }
          else {
            this.pocreated = true;
          }
          this.loaded = true;
        }
        else {
          this.spinner.hide();
          this.loaded = true;
        }
      }));
  }

  onFormSubmit() {
    this.spinner.show();
    if (this.tourEditForm.controls.PAYMODE.value == "Company Card")//By case id-355
    {
      if ((this.tourEditForm.controls.PDAMOUNT.value == 0) || (this.tourEditForm.controls.PDNO.value == "0") || (this.tourEditForm.controls.PDTYPENO.value == "0")) {
        this.subscription.add(this.translateapi.get(['Messages.PleaseSelectCompanyCardWithPaymentOption']).subscribe((translations) => {

          this.showMsg = translations['Messages.PleaseSelectCompanyCardWithPaymentOption'];;
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        this.spinner.hide();
        return;
      }
    }

    this.bookingDate = (this.tourEditForm.controls.BookingDate.value == null || this.tourEditForm.controls.BookingDate.value == "" ? "01-Jan-1900" : this.tourEditForm.controls.BookingDate.value);//Case id-337
    this.startFromDate = (this.tourEditForm.controls.DATE1.value == null || this.tourEditForm.controls.DATE1.value == "" ? "01-Jan-1900" : this.tourEditForm.controls.DATE1.value);//Case id-337
    this.endDate = (this.tourEditForm.controls.DATE2.value == null || this.tourEditForm.controls.DATE2.value == "" ? "01-Jan-1900" : this.tourEditForm.controls.DATE2.value);//Case id-337
    this.depositDueDate = (this.tourEditForm.controls.DepositDueDate.value == null || this.tourEditForm.controls.DepositDueDate.value == "" ? "01-Jan-1900" : this.tourEditForm.controls.DepositDueDate.value);//Case id-337
    if (Boolean(this.common.ValidDateRange(this.bookingDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidBookingDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidBookingDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.startFromDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidStartDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidStartDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.endDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidEndDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidEndDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.depositDueDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDepositDueDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDepositDueDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (this.tourEditForm.value['CURExRate'] === '' || this.tourEditForm.value['CURExRate'] === 0 || this.tourEditForm.value['CURExRate'] === null) {
      this.subscription.add(this.translateapi.get(['Messages.ExchangeRateError']).subscribe((translations) => {
        this.showMsg = translations['Messages.ExchangeRateError'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.spinner.hide();
      return;
    }
    this.submitted = true;
    if (this.tourEditForm.invalid) {
      this.spinner.hide();
      return;
    }

    this.objFolderPrice
      .CreditLimitCheck(this.guid == null || undefined ? "0" : this.guid, 0, this.orderno, this.tourEditForm.get("HCSell").value)
      .subscribe(res => {
        if (res != undefined || res["ResponseData"] != null) {
          if (
            res["ResponseData"].value != "0" &&
            res["ResponseData"].Status != "0" &&
            res["ResponseData"].ErrorMessage != ""
          ) {
            debugger;
            if (res["ResponseData"].value == 1) {
              this.spinner.hide();
              this.msg = res["ResponseData"].ErrorMessage;
              this.creditLimiFlag = true;
              this.creditLimitMsg = true;
              return false;
            } else if (res["ResponseData"].value == 2) {
              this.spinner.hide();
              this.msg = res["ResponseData"].ErrorMessage;
              this.creditLimiFlag = false;
              this.creditLimitMsg = true;
              // this.AirTicketSaveAndUpdate();
            } else {
              this.TourDetailsSaveandUpdate();
            }
          } else {
            this.TourDetailsSaveandUpdate();
          }
        }
      });


  }

  TourDetailsSaveandUpdate() {
    this.tourEditForm.controls.BaseFare.setValue(this.tourEditForm.controls.FCSell.value);

    if (this.guid != null && this.PONO > 0) {
      this.prdtUpdatePODTO = new ProductUpdatePODTO();
      this.prdtUpdatePODTO.FOLDERNO = this.orderno;
      this.prdtUpdatePODTO.GUIDItinerary = this.tourEditForm.get('GUIDItinerary').value;
      this.prdtUpdatePODTO.LOYALTYPOINTS = this.tourEditForm.get('LOYALTYPOINTS').value;
      this.prdtUpdatePODTO.LOYALTYPOINTSAMT = this.tourEditForm.get('LOYALTYPOINTSAMT').value
      // this.prdtUpdatePODTO.LOYALTYPOINTSBAL=this.tourEditForm.get('LOYALTYPOINTSBAL').value
      this.prdtUpdatePODTO.LOYALTYPOINTSMEMBNO = this.tourEditForm.get('LOYALTYPOINTSMEMBNO').value;
      this.prdtUpdatePODTO.TYPE = "Tour";
      this.prdtUpdatePODTO.BaseFare = this.tourEditForm.get('BaseFare').value;
      this.prdtUpdatePODTO.SELLAMT = this.tourEditForm.get('FCSell').value;
      this.prdtUpdatePODTO.SELLCOMM = this.tourEditForm.get('HCCommAmt').value;

      this.prdtUpdatePODTO.HCCommAmt = this.tourEditForm.get('HCCommAmt').value;

      // this.prdtUpdatePODTO.fCSellTax=this.hotelEditForm.get('RDESC').value;

      this.prdtUpdatePODTO.SELLTAXNO = this.tourEditForm.get('SELLTAXNO').value;
      this.prdtUpdatePODTO.SELLTAXRATE = this.tourEditForm.get('SELLTAXRATE').value;
      this.prdtUpdatePODTO.PAYABLE = this.tourEditForm.get('PAYABLE').value;
      this.prdtUpdatePODTO.FC_CIDExRate = this.tourEditForm.get('CURExRate').value;
      this.prdtUpdatePODTO.CostingCustomerNetSellPrice = this.tourEditForm.get('CostingCustomerNetSellPrice').value;
      this.prdtUpdatePODTO.CostingCustomerServiceFee = this.tourEditForm.get('CostingCustomerServiceFee').value;
      this.prdtUpdatePODTO.CostingCustomerTaxAmount = this.tourEditForm.get('CostingCustomerTaxAmount').value;
      //this.apiPO.formData=
      this.apiPO.formData = this.prdtUpdatePODTO;
      this.subscription.add(this.apiPO.SaveProductAfterPO()
        .subscribe(res => {
          if (res.IsSuccess === true && res.Message === 'Success') {
            this.spinner.hide();
            this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
              this.showMsg = translations['Messages.saved_success'];
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }));
            this.router.navigate(['/PenAir/Product/TourDetails', this.orderno, this.guid]);
          } else {
            this.spinner.hide();
            this.toastr.warning(res.ResponseData.ErrorMessage, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          }
        }, (err) => {
          this.spinner.hide();
          console.log(err);
          console.log(err);
          this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
            this.showMsg = translations['Messages.some_error_in_save'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
        }
        ));

    }


    else {

      if (this.guid != null) {
        this.spinner.show();
        if (this.CompanyCardValidation() == true) {
          this.subscription.add(this.api.updateTour(this.tourEditForm.value)
            .subscribe(res => {
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  this.spinner.hide();
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));
                  this.router.navigate(['/PenAir/Product/TourDetails', this.orderno, this.guid + '/']);
                } else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
              else {
                this.spinner.hide();
              }
            }, (err) => {
              this.spinner.hide();
              console.log(err);
              this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                this.showMsg = translations['Messages.some_error_in_save'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
            }
            ));
        }
        else {
          this.spinner.hide();
        }
      }

      else {
        this.spinner.show();
        if (this.CompanyCardValidation() == true) {
          this.subscription.add(this.api.addTour(this.tourEditForm.value)
            .subscribe(res => {
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  this.spinner.hide();
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));
                  this.common.isfinalsaved = false;
                  sessionStorage.setItem('pagevalue', '0');
                  var folderQuotes = sessionStorage.getItem('folderQuotes');
                  if (folderQuotes) {
                    this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno]);
                  } else {
                    this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno]);
                  }
                } else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
              else {
                this.spinner.hide();
              }
            }, (err) => {
              this.spinner.hide();
              console.log(err);
              this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                this.showMsg = translations['Messages.some_error_in_save'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
            }
            ));
        }
        else {
          this.spinner.hide();
        }
        //alert('SUCCESS2!! :-)\n\n' + JSON.stringify(this.hotelDetailModal, null, 4));
        // display form values on success
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.hotelEditForm.value, null, 4));
      }
    }
  }
  onCancel() {
    if (this.guid != "" && this.guid != null)
      this.router.navigate(['/PenAir/Product/TourDetails', this.orderno, this.guid + '/']);

    else {
      this.common.isfinalsaved = false;
      sessionStorage.setItem('pagevalue', '0');
      var folderQuotes = sessionStorage.getItem('folderQuotes');
      if (folderQuotes) {
        this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno]);
      } else {
        this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno]);
      }
    }
    // this.router.navigate(['/PenAir/Folder/Products',this.orderno]);
  }
  onReset() {
    this.submitted = false;
    this.tourEditForm.reset();
  }

  getFolderValues() {

    // this.apiShared.folderCurrency.subscribe(fldrCurrency => this.fldrCurrency = fldrCurrency)
    // this.apiShared.folderExchangeRate.subscribe(folderExrate => this.folderExrate = folderExrate)
    // this.exRate=new Number(this.folderExrate);
    // this.apiShared.projNo.subscribe(projNo => this.projNo = projNo)
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    this.exRate = new Number(this.folderExrate);
    this.projNo = this.apiShared.projNo;
  }
  onChanges(): void {
    //   this.hotelEditForm.get('SUPPRTYUSERCODE').valueChanges.subscribe(val => {
    //  //  this.formattedMessage = `My name is ${val}.`;
    //    console.log({val}.val);

    //    let toArray = {val}.val.split(" - ");

    //    this.hotelEditForm.controls.SUPPNAME.setValue(toArray[1]);
    //   });
  }
  private buyExRate: number;
  private fCBuy: number;
  private amount: number;
  private balanceDueAmount: number;
  private defaultDepositPer: number;
  private hcBuy: number = 0;
  public open() {
    this.opened = true;
  }
  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
    this.openSearch = false;
  }

  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }
  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.loadSupplierValue(value.Code);
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  loadSupplierValue(value: any) {//14068   v1.0 
    debugger;

    this.subscription.add(this.lookupApi.findSupplier(value).subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.SupplierList = data["ResponseData"];
          if (value != "") {

            this.supplierSelectedItem = this.SupplierList.find(item => item.Code == value);
            if (this.supplierSelectedItem != null) {
              this.supplierSelected = this.supplierSelectedItem.Name;
            }

          }
        }

      }
    ));

  }


  CalculateForm() {
    if (this.tourEditForm.get('AMTLC').value == null) {
      this.tourEditForm.controls.AMTLC.setValue(0);
      return

    }
    this.buyExRate = (this.tourEditForm.get('CURExRate').value == "" || this.tourEditForm.get('CURExRate').value == 0) ? 1 : this.tourEditForm.get('CURExRate').value;
    if ((this.tourEditForm.get('AMTLC').value != null) && this.tourEditForm.get('AMTLC').value != "undefined") {

      this.fCBuy = this.tourEditForm.controls.AMTLC.value;
      this.tourEditForm.controls.FCBuy.setValue(parseFloat((this.fCBuy).toFixed(2)));
      this.tourEditForm.controls.PAYABLE.setValue(parseFloat((this.fCBuy * this.buyExRate).toFixed(2)));
      this.tourEditForm.controls.AMTHC.setValue(parseFloat((this.fCBuy * this.buyExRate).toFixed(2)));
    }
    else {
      this.tourEditForm.controls.PAYABLE.setValue(parseFloat((this.tourEditForm.get('FCBuy').value * this.buyExRate).toFixed(2)));
    }
    if (this.tourEditForm.get('AMTLC').value == 0) {
      this.tourEditForm.controls.AMTHC.setValue(0);
    }
    this.tourEditForm.controls.HCSell.setValue(parseFloat((this.tourEditForm.get('FCSell').value * this.exRate).toFixed(2)));
    this.tourEditForm.controls.HCCommAmt.setValue(parseFloat((this.tourEditForm.get('HCSell').value - this.tourEditForm.get('PAYABLE').value).toFixed(2)));

    this.hcBuy = this.tourEditForm.get('FCBuy').value * this.buyExRate;
    this.amount = (this.tourEditForm.get('DEFDEP').value == "" || this.tourEditForm.get('DEFDEP').value == 0) ? 0 : this.tourEditForm.get('DEFDEP').value;
    this.defaultDepositPer = (this.tourEditForm.get('DEFDEPPER').value == "" || this.tourEditForm.get('DEFDEPPER').value == 0) ? 0 : this.tourEditForm.get('DEFDEPPER').value;

    if (this.defaultDepositPer != 0 && this.defaultDepositPer != 0.00)
      this.amount = (this.hcBuy * this.defaultDepositPer) / 100;

    this.balanceDueAmount = this.hcBuy - this.amount
    this.tourEditForm.controls.DEFDEP.setValue(parseFloat((this.amount).toFixed(2)));
    this.tourEditForm.controls.BalanceDueAmount.setValue(parseFloat((this.balanceDueAmount).toFixed(2)));
    //Corperate Info


    this.tourEditForm.controls.ActualSaving.setValue(this.tourEditForm.get('PAYABLE').value * -1);

    // this.buyExRate=(this.parentForm.get('CURExRate').value==""||this.parentForm.get('CURExRate').value==0)?1:this.parentForm.get('CURExRate').value;

    // this.cruiseEditForm.controls.HCSell.setValue(this.cruiseEditForm.get('FCSell').value*1);
    // this.cruiseEditForm.controls.HCCommAmt.setValue(this.cruiseEditForm.get('HCSell').value-this.cruiseEditForm.get('AMOUNT').value);

    // this.cruiseEditForm.controls.HC_SELLTAXAMT.setValue(this.cruiseEditForm.get('FC_SELLTAXAMT').value*1);
  }


  public getCurrencyExRate() {

    this.bookingDate = (this.tourEditForm.controls.BookingDate.value == null || this.tourEditForm.controls.BookingDate.value == "" ? "01-Jan-1900" : this.tourEditForm.controls.BookingDate.value);//Case id-337

    if (Boolean(this.common.ValidDateRange(this.bookingDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidBookingDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidBookingDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }

    if (this.tourEditForm.controls.CURCODE.value != '' && this.tourEditForm.controls.CURCODE.value != undefined)

      this.getExchangeRate(this.tourEditForm.controls.CURCODE.value, this.bookingDate)

  }
  getExchangeRate(currency, bookingDate) {
    this.savebutton = false;
    // bookingDate= new Date(bookingDate).toLocaleDateString('en-GB', {
    //   day: 'numeric', month: 'short', year: 'numeric'
    // }).replace(/ /g, '-');
    bookingDate = this.common.transformDate(new Date(bookingDate));

    this.subscription.add(this.apiExRate.getExchangeRate(currency, bookingDate)
      .subscribe(data => {
        if (data != null && data != undefined) {
          this.currencyExrate = data;

          this.tourEditForm.controls.CURExRate.setValue(this.currencyExrate.ResponseData.EXRATE);
          this.savebutton = true;
          this.CalculateForm();
        }
      }));


  }
  changefn() {


  }
  SupplierDefaultvalue(suppcode) {
    this.subscription.add(this.supplier.GetDefaultValue(suppcode)
      .subscribe(data => {
        if (data !== '' && data !== null && data !== undefined) {
          // this.tourEditForm.controls.CURCODE.setValue(data.CURRENCYCODE);
          this.tourEditForm.controls.PAYMENTID.setValue(data.PAYTERMID);
          this.tourEditForm.controls.PMTID.setValue(data.PAYMTDID);
          this.resetChildForm();

          // if(this.tourEditForm.controls.CURCODE.value!=""&&this.tourEditForm.controls.CURCODE.value!=null)
          // {   
          //   this.CurrencyselectedItem =this.CurrencyList.find(item => item.Code ===this.tourEditForm.controls.CURCODE.value );     
          //   this.getCurrencyExRate();
          // }
        }
      }));
  }
  resetChildForm() {
    this.resetFormSubject.next(true);
  }
  public SupplierValueChange(value: any): void {
    //  alert(value);

    debugger;
    if (this.supplierSelectedItem == undefined && (value != null && value != '')) {
      this.supplierSelectedItem = this.SupplierList.find(x => x.Name == value);
    }

    if ((value == null || value == '')) {
      this.supplierSelectedItem = null;
    }
    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13680
      this.SupplierDefaultvalue(this.supplierSelectedItem.Code);
      this.tourEditForm.controls.SUPPCODE.setValue(this.supplierSelectedItem.Code);
      this.tourEditForm.controls.SUPPNAME.setValue(this.supplierSelectedItem.ActualName);
      this.tourEditForm.controls.Provider.setValue(this.supplierSelectedItem.ActualName);
      this.apiShared.BALDUETO = this.supplierSelectedItem.BALDUETO;
      this.tourEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID);

      this.payterm.getBalanceDuedate();
      this.TaxLookup(this.supplierSelectedItem.CONTID);

    }
    else {
      this.supplierSelected = "";
      this.tourEditForm.controls.SUPPCODE.setValue("");
      this.tourEditForm.controls.SUPPNAME.setValue("");
      this.tourEditForm.controls.Provider.setValue("");
    }
    // this.tourEditForm.get("suppUserCodetext").setValue( this.supplierSelectedItem.Name);
  }

  TaxLookup(country) {
    this.fchcdetails.getInputTaxLookup(country)
  }
  public CurrencyChange(value: any): void {

    if (value != null && value != undefined)
      this.tourEditForm.controls.CURCODE.setValue(value.Code);
    else
      this.tourEditForm.controls.CURCODE.setValue("");

  }
  public BookedByChange(value: any): void {

    if (value != null && value != undefined)
      this.tourEditForm.controls.BOOKEDBY.setValue(value.Name);
    else
      this.tourEditForm.controls.BOOKEDBY.setValue("");
  }
  public disabled: boolean = true;
  diff: number;
  indate: Date = new Date();
  outdate: Date = new Date();
  days: number = 0;
  onDateoutblur() {

    this.startFromDate = (this.tourEditForm.controls.DATE1.value == null || this.tourEditForm.controls.DATE1.value == "" ? "01-Jan-1900" : this.tourEditForm.controls.DATE1.value);//Case id-337
    this.endDate = (this.tourEditForm.controls.DATE2.value == null || this.tourEditForm.controls.DATE2.value == "" ? "01-Jan-1900" : this.tourEditForm.controls.DATE2.value);//Case id-337


    if (Boolean(this.common.ValidDateRange(this.startFromDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidStartDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidStartDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.endDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidEndDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidEndDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (this.tourEditForm.controls.DATE2.value != null && this.tourEditForm.controls.DATE1.value != null) {
      if (this.tourEditForm.controls.DATE2.value != '' && this.tourEditForm.controls.DATE1.value != '') {
        this.diff = this.tourEditForm.controls.DATE2.value.getTime() - this.tourEditForm.controls.DATE1.value.getTime();
        this.days = Math.round(this.diff / (1000 * 60 * 60 * 24));
      }
    } else {
      this.days = 0;
    }

  }

  onNightsblur() {
    this.startFromDate = (this.tourEditForm.controls.DATE1.value == null || this.tourEditForm.controls.DATE1.value == "" ? "01-Jan-1900" : this.tourEditForm.controls.DATE1.value);//Case id-337
    this.endDate = (this.tourEditForm.controls.DATE2.value == null || this.tourEditForm.controls.DATE2.value == "" ? "01-Jan-1900" : this.tourEditForm.controls.DATE2.value);//Case id-337


    if (Boolean(this.common.ValidDateRange(this.startFromDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidStartDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidStartDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.endDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidEndDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidEndDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }

    if (this.tourEditForm.controls.DAYS.value != null) {
      if (this.tourEditForm.controls.DATE1.value != null && this.tourEditForm.controls.DATE1.value != '') {
        //this.outdate=new Date((new Date(this.indate)).getTime() + (this.days*60*60*24*1000));
        this.tourEditForm.controls.DATE2.setValue(new Date((new Date(this.tourEditForm.controls.DATE1.value)).getTime() + (this.days * 60 * 60 * 24 * 1000)));
      }
    }
    else {
      this.onDateoutblur()
    }
  }


  FormLoyalityPointsChange() {
    if (this.tourEditForm.controls.LOYALTYPOINTSMEMBNO.value != "" && this.tourEditForm.controls.LOYALTYPOINTSMEMBNO.value != null) {


      this.tourEditForm.get('LOYALTYPOINTS').enable();
      this.tourEditForm.get('LOYALTYPOINTSAMT').enable();
      // this.disableTextbox =  false;
    }
    else {
      this.tourEditForm.controls.LOYALTYPOINTS.setValue(0);
      this.tourEditForm.controls.LOYALTYPOINTSAMT.setValue(0);
      this.tourEditForm.get('LOYALTYPOINTS').disable();
      this.tourEditForm.get('LOYALTYPOINTSAMT').disable();

    }
  }

  getFileDetails() {

    this.subscription.add(this.apiFile.getFileDetails()
      .subscribe(data => {

        this.homeCurrency = data.ResponseData.COMCID
        this.tourEditForm.controls.CURCODE.setValue(this.homeCurrency);

        // this.tourEditForm.controls.CURCODE.setValue( data.ResponseData.COMCID);   
        // alert( this.tourEditForm.controls.CURCODE.value)      

      }));
  }

  CompanyCardValidation() {
    if (this.tourEditForm.get('PAYMODE').value == "Company Card") {
      if (this.tourEditForm.get('PDNO').value != "" && this.tourEditForm.get('PDNO').value != null && this.tourEditForm.get('PDFCURID').value != "" && this.tourEditForm.get('PDFCURID').value != null
        && this.tourEditForm.get('PDFCAMT').value != "" && this.tourEditForm.get('PDFCAMT').value != null && this.tourEditForm.get('PDFCAMT').value != 0 && this.tourEditForm.get('PDTYPENO').value != null
        && this.tourEditForm.get('PDTYPENO').value != "" && this.tourEditForm.get('PDAMOUNT').value != null && this.tourEditForm.get('PDAMOUNT').value != null && this.tourEditForm.get('PDAMOUNT').value != 0

        && this.tourEditForm.get('PDFCAMT').value != null && this.tourEditForm.get('PDFCAMT').value != null && this.tourEditForm.get('PDFCAMT').value != 0
      ) {

        return true;

      }
      else {

        this.common.showFlash1('Messages.Companycard_validation', 'bg-danger text-white', 2000);


        return false;
      }
    }
    else {
      return true;
    }
  }
  isSticky: boolean = false;
  @HostListener('window:scroll')
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ConfirmMessage() {
    if (this.creditLimiFlag == false) {
      this.creditLimitMsg = false;
      this.TourDetailsSaveandUpdate();
    } else {
      this.creditLimitMsg = false;
    }
  }

}
