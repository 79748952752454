import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RptAirticketReportWithPNRComponent } from './rpt-airticket-report-with-pnr/rpt-airticket-report-with-pnr.component';
import { RptFlightBookingDetailsComponent } from './rpt-flight-booking-details/rpt-flight-booking-details.component';
import { RptFolderActivityProductComponent } from './rpt-folder-activity-product/rpt-folder-activity-product.component';
import { RptFolderActivityReportComponent } from './rpt-folder-activity-report/rpt-folder-activity-report.component';
import { RptFolderQueryComponent } from './rpt-folder-query/rpt-folder-query.component';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { MenusModule } from "@progress/kendo-angular-menu";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { NgxSpinnerModule } from "ngx-spinner";
import { TranslateModule } from "@ngx-translate/core";
import { SearchModule } from '../search/search.module';
import { SharedCommonModule } from '../SharedCommonModule/shared-common.module';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { GridModule, ExcelModule, PDFModule } from "@progress/kendo-angular-grid";
import { PopupModule } from "@progress/kendo-angular-popup";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { WindowModule, DialogModule, DialogsModule } from "@progress/kendo-angular-dialog";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageAnalysisReportComponent } from './message-analysis-report/message-analysis-report.component';

@NgModule({
  declarations: [
    RptAirticketReportWithPNRComponent,
    RptFlightBookingDetailsComponent,
    RptFolderActivityProductComponent,
    RptFolderActivityReportComponent,
    RptFolderQueryComponent,
    MessageAnalysisReportComponent
  
  ],
  imports: [
    CommonModule,
    InputsModule,
    LayoutModule,
    TranslateModule,
    DropDownsModule,
    ButtonsModule,
    DateInputsModule,
    PopupModule,
    FormsModule,
    ReactiveFormsModule,
    DialogsModule,
    SharedCommonModule,
    WindowModule,
    DialogModule,
    PDFModule,
    GridModule,
    ExcelModule
  ],
  exports:[RptFolderQueryComponent,RptFlightBookingDetailsComponent]
})
export class ReportModule { }
