import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomerList } from '../Models/customer-list';
import { Observable, of } from 'rxjs';
import { HttpHeaders } from "@angular/common/http";
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CustomerAddDTO } from '../Models/customer-create.model';
import { CommonserviceService } from './commonservice.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CustomermasterserviceService {
  apiUrl: string;
  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;

  }
  GetCustomerList(): Observable<CustomerList> {
    const url = `${this.apiUrl}` + 'GetCustomerList';// `http://localhost:29048/GetCustomerList`;

    return this.http.get<CustomerList>(url);



  }
  UpdateSupplier(objCustomer: CustomerAddDTO): Observable<any> {
    var TaxValidTodata = (objCustomer.TaxValidTo == null || objCustomer.TaxValidTo == "" ? "01-Jan-1900" : objCustomer.TaxValidTo);//Case id-337

    //  TaxValidTo=new Date(TaxValidTo);
    if (TaxValidTodata == "01-Jan-1900") {
      TaxValidTodata = this.commonservice.ConvertToLocaleDate(new Date(TaxValidTodata))
    }
    else
      TaxValidTodata = this.commonservice.ConvertToLocaleDate(objCustomer.TaxValidTo)
    objCustomer.TaxValidTo = (TaxValidTodata);
    return this.http.post<any>(this.apiUrl + 'AddCustomerDetails', objCustomer, httpOptions).pipe(
      //tap((tourRes: any) => console.log(`added Supplier w/ id=${tourRes.ResponseData.SUPPNAME}`)),
      // catchError(this.commonservice.handleError<any>('addTour'))
    );
  }
  deleteCustomer(customerUsercode: any): Observable<any> {

    const url = `${this.apiUrl}` + 'DeleteCustomerDetails' + `/${customerUsercode}`;
    return this.http.post<any>(url, httpOptions).pipe(
      tap(_ => console.log(`deleted Customer`)),
      catchError(this.commonservice.handleError<any>('delete customer'))
    );
  }
  GetCustomerDetailsbyId(prtycode: any): Observable<any> {
    const url = `${this.apiUrl}` + 'GetCustomerDetailsByIdforedit' + `/${prtycode}`;
    return this.http.get<any>(url);
  }
}
