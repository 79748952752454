import { Injectable } from "@angular/core";

import { EnvService } from "../../env.service";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { ProductList, RefundProductList } from "../Models/product-list";
import { tap, catchError } from "rxjs/operators";
import {
  FolderRefundDTO,
  FolderRefundItineraryDTO,
  FolderPriceDetailsDTO
} from "../Models/folder-refund-itinerary-dto.model";
import { CommonserviceService } from "./commonservice.service";
import { SavedFolderDeleteResponse } from "../Models/saved-folder-delete-response";
import { SavedFolderDelete } from "../Models/saved-folder-delete";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable({
  providedIn: "root"
})
export class FolderpriceService {
  public folderqoutestatus: any = false;
  public formData: FolderRefundDTO;
  public formparmData: any;
  public deleteformdata: SavedFolderDelete;
  public List: FolderRefundItineraryDTO[];
  public productList: FolderPriceDetailsDTO[];
  public AirList: RefundProductList[];
  public package: boolean = this.commonservice.package;
  public FCTotalCOMM: any = 0;
  public MarkupPer: any = 0;
  packagefolderno: any = 0;
  public IsCopy: boolean = false;
  body:any;
  public OtherList: RefundProductList[];
  apiUrl: string;
  constructor(
    private http: HttpClient,
    private env: EnvService,
    public commonservice: CommonserviceService
  ) {
    this.apiUrl = env.baseUrl;
  }

  getAllProducts(folderNo: number): Observable<ProductList[]> {
    const url = `${this.apiUrl}` + "GetAllProductDetails" + `/${folderNo}`;
    // const url = `${apiUrl}/${OrderNo}/${GuidItinerary}`;
    return this.http.get<ProductList[]>(url).pipe(
      tap(_ => { }),
      catchError(
        this.commonservice.handleError<ProductList[]>(
          `GetAllProductDetails id=${folderNo}`
        )
      )
    );
  }
  FolderFinalSave(folderNo: string, UsrId: number): Observable<any> {
    const url = `${this.apiUrl}` + "FolderFinalSave" + `/${folderNo}/${UsrId}`;

    return this.http.post(url, httpOptions).pipe(
      tap(_ => console.log(`final save`)),
      catchError(this.commonservice.handleError<any>("final save"))
    );
  }
  FolderPackageSave(productList: any, TotalValue: any): Observable<any> {
    const url = `${this.apiUrl}` + "PackageFolderSave";
    return this.http
      .post<any>(url, { productList, TotalValue }, httpOptions)
      .pipe(
        tap(_ => console.log(`final save`)),
        catchError(this.commonservice.handleError<any>("final save"))
      );
  }

  RefundFolder(): Observable<FolderRefundDTO> {
    var body = {
      ...this.formData,
      FolderRefundItinerary: this.List,
      FolderRefundAirItinerary: this.AirList,
      FolderRefundOtherItinerary: this.OtherList
    };
    return this.http.post<FolderRefundDTO>(
      this.apiUrl + "CreateRefundRequestFolder",
      body
    );
    //alert(JSON.stringify(FolderSummary));
  }
  GetProductTKTNo(): Observable<any> {
    var body = {
      ...this.formData,

      FolderRefundOtherItinerary: this.OtherList
    };
    return this.http.post<any>(this.apiUrl + "GetProductTKTNo", body);
    //alert(JSON.stringify(FolderSummary));
  }
  UpdateProductOrder(): Observable<FolderRefundDTO> {
    var body = {
      ...this.formData,
      productList: this.productList
    };
    return this.http.post<FolderRefundDTO>(
      this.apiUrl + "UpdateProductOrder",
      body
    );
  }
  FolderDelete(
    folderDelete: SavedFolderDelete
  ): Observable<SavedFolderDeleteResponse> {
    const url = `${this.apiUrl}` + "SaveFolderDelete";
    return this.http.post(url, folderDelete, httpOptions).pipe(
      tap(_ => console.log(`final save`)),
      catchError(this.commonservice.handleError<any>("delete folder"))
    );
  }
  CreditLimitCheck(
    Guid: any,
    custco: any,
    FolderNo: any,
    SellAmt: any
  ): Observable<any> {
    debugger;

    return this.http.get<any>(
      this.apiUrl +
      "CreditLimitCheck/" +
      Guid +
      "/" +
      custco +
      "/" +
      FolderNo +
      "/" +
      SellAmt +
      "/"
    );
  }


  UpdateAirlineSegOrder(airlineOrderArray: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'UpdateAirSegmentOrder', airlineOrderArray, httpOptions).pipe(
      tap(_ => console.log(`Air seg order details`)),
      catchError(this.commonservice.handleError<any[]>(`get air segment order  details`))
    );
  }

  // UpdateProductSortOrder(productOrderArray: any): Observable<any> {
  //   return this.http.post<any>(this.apiUrl + 'UpdateProductOrder', productOrderArray, httpOptions).pipe(
  //     tap(_ => console.log(`Air seg order details`)),
  //     catchError(this.commonservice.handleError<any[]>(`get air segment order  details`))
  //   );
  // }


  UpdateProductSortOrder(productOrderArray: any): Observable<any> {
    var body = {
      productList: productOrderArray
    };
    return this.http.post<any>(
      this.apiUrl + "UpdateProductOrder",
      body
    );
  }

  UpdateRailSegOrder(railSegOrderArray: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'UpdateRailSegmentOrder', railSegOrderArray, httpOptions).pipe(
      tap(_ => console.log(`Air rail order details`)),
      catchError(this.commonservice.handleError<any[]>(`get rail segment order  details`))
    );
  }

  CopyProductDetails(folderno, guid, PType) {
    let objprod = {
      FOLDERNO: folderno,
      GUIDItinerary: guid,
      PType: PType,
      UID: 0
    }
    var body = {
      ...objprod
    };
    return this.http.post<any>(
      this.apiUrl + "CopyProductDetails",
      body
    );

  }
  GetWexPaymentSettingsDataByID(auto:any) {
    let objprod = {
      FOLDERNO: 0,
      GUIDItinerary: 0,
      PType: 0,
      UID: 0,
      AutoNo:auto
    }
    var body = {
      ...objprod
    };
    return this.http.post<any>(
      this.apiUrl + "GetWexPaymentSettingsDataByID",
      body
    );

  }
  GetItineraryDetailsForWexPayment(folderno, guid, PType,SLNO) {
    let objprod = {
      FolderNo: folderno,
      GUID: guid,
      Type: PType,
      SLNO: SLNO
    }
    var body = {
      ...objprod
    };
    return this.http.post<any>(
      this.apiUrl + "GetItineraryDetailsForWexPayment",
      body
    );

  }
  AutomaticPOCreationAndSave() {
  
    var body = {
      ...this.body
    };
    return this.http.post<any>(
      this.apiUrl + "AutomaticPOCreationAndSave",
      body
    );

  }
  GetProductDetailedlist(): Observable<any> {
    var body = {
      ...this.formparmData,

     
    };
    return this.http.post<any>(this.apiUrl + "GetProductByType", body);
    //alert(JSON.stringify(FolderSummary));
  }
}
