import { Component, OnInit, ViewChild } from '@angular/core';
import { LookUpDetails } from '../../Services/look-up-details';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '../../Services/file.service';
import { ShareDataService } from '../../Services/share-data.service';
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription } from 'rxjs';
import { PublicLayoutComponent } from 'src/app/public-layout/public-layout.component';
import { EmailService } from '../../Services/email.service';
import { ReportParameterDTO } from '../../Models/common-class.model';
import { EnvService } from 'src/env.service';
import { ModuleUsageService } from '../../Services/module-usage.service';
@Component({
  selector: 'app-rpt-airticket-report-with-pnr',
  templateUrl: './rpt-airticket-report-with-pnr.component.html',
  styleUrls: ['./rpt-airticket-report-with-pnr.component.scss']
})
export class RptAirticketReportWithPNRComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  @ViewChild('autocompletecustomer') public autocompletecustomer: AutoCompleteComponent;
  @ViewChild('autocompletefrom') public autocompletefrom: AutoCompleteComponent;
  @ViewChild('autocompleteTo') public autocompleteTo: AutoCompleteComponent;

  trialBalanceReportForm: FormGroup;
  constructor(public common: CommonserviceService, public moduleUsageService: ModuleUsageService, private layout: PublicLayoutComponent, private route: ActivatedRoute, private lookupApi: LookUpDetailsService, private formBuilder: FormBuilder, private router: Router, private translateapi: TranslateService, private flashMessage: FlashMessagesService, public email: EmailService, private env: EnvService, private emailapi: FileService, private apishare: ShareDataService) { }
  branchList: Array<LookUpDetails["ResponseData"]> = [];
  businessAreaList: Array<LookUpDetails["ResponseData"]> = [];
  destinationList: Array<LookUpDetails["ResponseData"]> = [];
  public destinationSelectedItem: LookUpDetails["ResponseData"];
  public destinationToSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelectedItem: LookUpDetails["ResponseData"];
  destinationToList: Array<LookUpDetails["ResponseData"]> = [];
  CustomerList: Array<LookUpDetails["ResponseData"]> = [];
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];
  BOOKEBYList: Array<LookUpDetails["ResponseData"]> = [];
  AirlineList: Array<LookUpDetails["ResponseData"]> = [];
  public customerSelectedItem: LookUpDetails["ResponseData"];
  public destinationSelected: String;
  public destinationToSelected: String;
  public supplierSelected: String;
  public customerSelected: String;
  public tickettypeList: Array<string> = ["All", "TKT", "ADM", "ACM", "MCO", "MPD", "VMPD", "EMD", "RTKT", "RFN", "EMDRFN"];
  public groupByList: Array<{ text: string, value: number }> =
    [{ text: "Airline", value: 0 }, { text: "Booked by", value: 1 }, { text: "Ticketed by", value: 2 }, { text: "Destination", value: 3 }];
  public reportParms: any;
  public reportFile: string = "AirticketreportwithPNR.trdp";
  public reportSubject: string = "Air Ticket Report With PNR";
  public Componentname: string;
  public GroupSelectedItem: any = { text: "Airline", value: 0 };
  public TravelDateFrom = null;
  public TravelDateTo = null;
  public TicketDateFrom = null;
  public TicketDateTo = null;
  public ReturnDateFrom = null;
  public ReturntDateTo = null;

  public ATTravelDateFrom = "";
  public ATTravelDateTo = "";
  public ATTicketDateFrom = "";
  public ATTicketDateTo = "";
  public ATReturnDateFrom = "";
  public ATReturntDateTo = "";

  public SupplierName: String = "";
  public Supplier: String = "0";
  public BookedBy = "";
  public PassengerType = "";
  public TicketedBy = "";
  public GroupBy: any = "Airline";
  public GroupBy_tinyint = 0;
  public Customer = "";
  public PartyCode: any = 0;
  public Airline = "";
  public VairlineAIRID = "";

  public OriginFrom: String = "";
  public Origin = "";
  public DestinationFrom: String = "";
  public Destination = "";
  public Branch = "";
  public BusinessArea = "";
  public BranchCode = "";
  public BArea = "";
  public UnusedTickets = false;
  public TicketType = "All";
  public SQLPASSWHEREQUERY = "";
  public UserId = 1;
  public PassType = "All";
  public PassengerQuery = "";
  public enableChild = false;
  public enableAdult = false;
  public enableInfant = false;
  public enableYouth = false;
  public enableUnusedTicket = false;
  public showMsg: any;
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  fromId: string;
  bccId: string;
  userID: number;
  openSearch = false;

  public windowTop: number = -100;
  public windowLeft: number = -225;
  public offset = 10;
  public left = this.windowLeft;
  public top = this.windowTop;
  public width = window.innerWidth - 40;
  public height = 770;
  public selectedReport: string = "";
  emailopened: any = false;
  isWebLink = false;
  ngOnInit() {

    this.layout.folderstatus = '';
    this.layout.headerPassenger = '';

    this.userID = +(this.apishare.uid);
    this.subscription.add(this.emailapi.GetReportEmail(this.userID).subscribe(data => {

      this.fromId = data.ResponseData.FROMEMAIL,
        this.bccId = data.ResponseData.DEFAULTBCCMAIL
    }));

    this.subscription.add(this.lookupApi.GetAllLookup('BRANCH').subscribe(
      (data) => {
        if (data != null && data != undefined) {

          this.branchList = data["ResponseData"]

        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('BUSINESSAREA').subscribe(
      (data) => {
        if (data != null && data != undefined) {

          this.businessAreaList = data["ResponseData"]
        }
      }
    ));

    //  this.subscription.add(this.lookupApi.GetAllLookup('PAYMENTMETHODS').subscribe(
    //   (data) => {

    //      this.businessAreaList= data["ResponseData"]           

    //   }
    //  ));
    //  this.subscription.add(this.lookupApi.GetAllLookup('PRODUCT').subscribe(
    //   (data) => {

    //      this.businessAreaList= data["ResponseData"]           

    //   }
    //  ));
    // this.lookupApi.GetAllLookup('DESTINATION').subscribe(
    //   (data) => {


    //      this.destinationList= data["ResponseData"]  



    //   }
    //   );

    // this.lookupApi.GetAllLookup('CUSTOMER').subscribe(
    //   (data) => {
    //      this.CustomerList= data["ResponseData"]  


    //   }
    //   );

    // this.lookupApi.GetAllLookup('SUPPLIERS').subscribe(
    //   (data) => {

    //      this.SupplierList= data["ResponseData"]      
    //      //alert("ddd"+this.airticketEditForm.controls.PRTYCODE.value)     ;

    //   }
    //   );
    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.BOOKEBYList = data["ResponseData"]
        }

      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.AirlineList = data["ResponseData"]
        }
      }
    ));
    this.Componentname = "RptAirTicketReportWithPnr";
    //this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654
    sessionStorage.setItem('Componentname', this.Componentname);
  }

  public BookedByChange(value: any): void {
    console.log('selectionChange', value);
    if (value != null && value != undefined)
      this.BookedBy = value.Name;
    else
      this.BookedBy = "";

  }

  public TicketTypeChange(value: any): void {

    if (value != null && value != undefined)
      this.TicketType = value.Name;
    else
      this.TicketType = null;

  }
  public TicketedByChange(value: any): void {
    console.log('selectionChange', value);
    if (value != null && value != undefined)
      this.TicketedBy = value.Name;
    else
      this.TicketedBy = "";

  }
  handleFilter(value) {

    if (value === "" || value === null) {
      this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.AirlineList = data["ResponseData"]
          }
        }
      ));
    }
    else {
      this.AirlineList = this.AirlineList.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

  }

  public AirLineChange(value: any): void {

    if (value != null && value != undefined) {
      this.Airline = value.ActualName;
      this.VairlineAIRID = value.UsrCode;
    }
    else {
      this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.AirlineList = data["ResponseData"]
          }
        }
      ));
      this.Airline = "";
      this.VairlineAIRID = "";
    }

  }
  // public OriginChange(value: any): void {
  //   console.log('selectionChange', value);
  //   if(value!=null && value!=undefined)
  //   {
  //     this.Origin=value.Code; 
  //     this.OriginFrom=value.ActualName; 
  //   }

  //   else

  //   {
  //     this.Airline=""; 
  //     this.VairlineAIRID=""; 
  //   }

  // } 

  public DestinationValueChange(value: any): void {

    this.destinationToSelectedItem = this.destinationToList.find(item => item.Name == value);

    if (this.destinationToSelectedItem != null) {
      this.DestinationFrom = this.destinationToSelectedItem.ActualName;
      this.Destination = this.destinationToSelectedItem.UsrCode;
    }
    else {
      this.DestinationFrom = null;
      this.Destination = null;
    }
  }
  // public DestinationValueChange(value: any): void {
  //  //console.log('selectionChange', value);
  //  this.Destination=value.ActualName;
  //  this.DestinationFrom=value.UsrCode;

  // }

  public OriginChange(value: any): void {

    this.destinationSelectedItem = this.destinationList.find(item => item.Name == value);

    if (this.destinationSelectedItem != null) {
      this.OriginFrom = this.destinationSelectedItem.ActualName;
      this.Origin = this.destinationSelectedItem.UsrCode;
    }
    else {
      this.OriginFrom = null;
      this.Origin = null;
    }


  }

  //13864
  openBox(lookuptype) {
    debugger;
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
    else if (this.lookuptype == 'CUSTOMER') {
      this.lookupname = 'CUSTOMER';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        if (this.lookuptype == 'SUPPLIERS') {
          this.supplierSelectedItem = value;
          this.SupplierList = [];
          this.SupplierValueChange(value.ActualName)
        }
        else if (this.lookuptype == 'CUSTOMER') {
          this.customerSelectedItem = value;
          this.CustomerList = [];
          this.CustomerValueChange(value.Name)
        }

      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13864
  handleFilterToDestination(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.destinationToList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompleteTo.toggle(false);
    }
  }
  handleFilterFromDestination(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.destinationList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletefrom.toggle(false);
    }
  }
  public BranchValueChange(value: any): void {
    //console.log('selectionChange', value);


    if (value != null && value != undefined) {
      this.BranchCode = value.UsrCode;
      this.Branch = value.Name;
    }
    // this.folderSummaryEditForm.controls.BRID.setValue(value.UsrCode);
    else {
      this.BranchCode = "";
      this.Branch = "";
    }
  }
  // public CustomerChange(value: any): void {
  //   console.log('selectionChange', value);
  //   if(value!=null && value!=undefined)
  //   {
  //     this.Customer=value.ActualName;  
  //     this.PartyCode=value.Code;  
  //   }

  //   else

  //   {
  //     this.Customer="";  
  //     this.PartyCode=0;  
  //   }
  // }

  public CustomerValueChange(value: any): void {
    debugger;
    if (this.CustomerList != null && this.CustomerList.length > 0) {//13864
      this.customerSelectedItem = this.CustomerList.find(item => item.Name == value);

    }


    if (this.customerSelectedItem != null) {
      debugger;
      this.customerSelected = this.customerSelectedItem.Name; //13864

      this.Customer = this.customerSelectedItem.ActualName;
      this.PartyCode = this.customerSelectedItem.Code;


    }
    else {
      this.Customer = "";
      this.PartyCode = 0;
    }
    // this.carEditForm.get("suppUserCodetext").setValue( this.supplierSelectedItem.Name);
  }
  public BusinessAreaValueChange(value: any): void {
    // console.log('selectionChange', value);

    if (value != null && value != undefined)
    //this.folderSummaryEditForm.controls.BAID.setValue(value.UsrCode);
    {
      this.BusinessArea = value.Name;
      this.BArea = value.UsrCode

    }
    else {
      this.BusinessArea = "";
      this.BArea = "";
    }
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  GetPassengerQuery() {
    this.PassengerQuery = "";
    //  this.PassType = "All";
    //  public enableChild=false;
    //  public enableAdult=false;
    //  public enableInfant=false;
    //  public enableYouth=false;
    this.PassType = "All";
    if (this.enableAdult == true) {
      this.PassType = "Adult";
      if (this.enableChild == false) {
        if (this.PassengerQuery != "")
          this.PassengerQuery = this.PassengerQuery + ",";
        this.PassengerQuery = this.PassengerQuery + "'Child'";
      }
      else
        this.PassType = this.PassType + " ,Child";
      if (this.enableInfant == false) {
        if (this.PassengerQuery != "")
          this.PassengerQuery = this.PassengerQuery + ",";
        this.PassengerQuery = this.PassengerQuery + "'Infant'";
      }
      else
        this.PassType = this.PassType + " ,Infant";
      if (this.enableYouth == false) {
        if (this.PassengerQuery != "")
          this.PassengerQuery = this.PassengerQuery + ",";
        this.PassengerQuery = this.PassengerQuery + "'Youth'";
      }
      else
        this.PassType = this.PassType + " ,Youth";

      if (this.PassengerQuery != "") {
        this.PassengerQuery = " NOT IN(" + this.PassengerQuery + ")";
      }

    }

    else {
      this.PassType = "";

      if (this.enableChild == true) {
        if (this.PassengerQuery != "")
          this.PassengerQuery = this.PassengerQuery + ",";
        this.PassengerQuery = this.PassengerQuery + "'Child'";
        if (this.PassType != "")
          this.PassType = this.PassType + ",";
        this.PassType = this.PassType + "Child";
      }
      if (this.enableInfant == true) {
        if (this.PassengerQuery != "")
          this.PassengerQuery = this.PassengerQuery + ",";
        this.PassengerQuery = this.PassengerQuery + "'Infant'";
        if (this.PassType != "")
          this.PassType = this.PassType + ",";
        this.PassType = this.PassType + "Infant";
      }
      if (this.enableYouth == true) {
        if (this.PassengerQuery != "")
          this.PassengerQuery = this.PassengerQuery + ",";
        this.PassengerQuery = this.PassengerQuery + "'Youth'";
        if (this.PassType != "")
          this.PassType = this.PassType + ",";
        this.PassType = this.PassType + " Youth";
      }

      if (this.PassengerQuery != "") {
        this.PassengerQuery = " IN(" + this.PassengerQuery + ")";
      }
      if (this.PassType == "")
        this.PassType = "All";
    }




  }



  public SupplierValueChange(value: any): void {
    debugger;
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);//13864

    }
    if (this.supplierSelectedItem != null) {
      debugger;
      this.supplierSelected = this.supplierSelectedItem.Name; //13864

      this.SupplierName = this.supplierSelectedItem.ActualName;
      this.Supplier = (this.supplierSelectedItem.Code);
    }
    else {
      this.SupplierName = "";
      this.Supplier = "0";
    }

  }
  public opened = false;

  public open() {
    window.scrollTo(0, 0);
    this.opened = true;
  }

  handleFiltercustomer(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchCustomer(value, this.userID).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.CustomerList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletecustomer.toggle(false);
    }
  }
  public close() {
    this.opened = false;
    this.openSearch = false;

  }
  onCancel() {
    var reporturl = sessionStorage.getItem('menuhighlight')

    if (reporturl != null) {
      this.router.navigate(['/Login']);
    }
    else {
      this.router.navigate(['PenAir/Home']);
    }
  }

  handleFiltersupplier(value) {
    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }

  public AccountGroupChange(value: any): void {

    if (value !== undefined) {
      this.GroupSelectedItem = this.groupByList.find(item => item.value == value.value);
      if (this.GroupSelectedItem != null) {
        this.GroupBy_tinyint = this.GroupSelectedItem.value;
        this.GroupBy = this.GroupSelectedItem.text;
        //this.trialBalanceReportForm.controls.AccountGroup.setValue( this.GroupSelectedItem.value);
      }
      else {
        this.GroupBy_tinyint = 0;
        this.GroupBy = "";
      }
    }

    else {
      this.GroupBy_tinyint = 0;
      this.GroupBy = "";
    }

  }



  onFormSubmit() {
    //this. GetPassengerQuery();
    this.UnusedTickets = this.enableUnusedTicket;

    this.TravelDateFrom = (this.ATTravelDateFrom == null || this.ATTravelDateFrom == "" ? "01-Jan-1900" : this.ATTravelDateFrom);
    this.TravelDateTo = (this.ATTravelDateTo == null || this.ATTravelDateTo == "" ? "01-Jan-1900" : this.ATTravelDateTo)
    this.TicketDateFrom = (this.ATTicketDateFrom == null || this.ATTicketDateFrom == "" ? "01-Jan-1900" : this.ATTicketDateFrom)
    this.TicketDateTo = (this.ATTicketDateTo == null || this.ATTicketDateTo == "" ? "01-Jan-1900" : this.ATTicketDateTo)
    this.ReturnDateFrom = (this.ATReturnDateFrom == null || this.ATReturnDateFrom == "" ? "01-Jan-1900" : this.ATReturnDateFrom)
    this.ReturntDateTo = (this.ATReturntDateTo == null || this.ATReturntDateTo == "" ? "01-Jan-1900" : this.ATReturntDateTo)
    if ((this.TravelDateFrom == "01-Jan-1900" && this.TravelDateTo != "01-Jan-1900") ||
      (this.TicketDateFrom == "01-Jan-1900" && this.TicketDateTo != "01-Jan-1900") ||
      (this.ReturnDateFrom == "01-Jan-1900" && this.ReturntDateTo != "01-Jan-1900")) {
      this.common.showWARNINGtoastrmsg('Messages.empty_fromdate', 2000, true, false);

      return;
    }

    if (this.TravelDateFrom != "01-Jan-1900") //Case id-337
    {
      if (Boolean(this.common.ValidDateRange(this.TravelDateFrom)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidFromDate'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        return;
      }
    }
    if (this.TravelDateTo != "01-Jan-1900") //Case id-337
    {
      if (Boolean(this.common.ValidDateRange(this.TravelDateTo)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidToDate'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        return;
      }
    }
    if (this.TicketDateFrom != "01-Jan-1900") //Case id-337
    {
      if (Boolean(this.common.ValidDateRange(this.TicketDateFrom)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidFromDate'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        return;
      }
    }
    if (this.TicketDateTo != "01-Jan-1900") //Case id-337
    {
      if (Boolean(this.common.ValidDateRange(this.TicketDateTo)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidToDate'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        return;
      }
    }
    if (this.ReturnDateFrom != "01-Jan-1900") //Case id-337
    {
      if (Boolean(this.common.ValidDateRange(this.ReturnDateFrom)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidFromDate'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        return;
      }
    }
    if (this.ReturntDateTo != "01-Jan-1900") //Case id-337
    {
      if (Boolean(this.common.ValidDateRange(this.ReturntDateTo)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidToDate'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        return;
      }
    }


    if ((this.ATTravelDateTo == null || this.ATTravelDateTo == "") && (this.ATTravelDateFrom != null || this.ATTravelDateFrom != "")) {
      this.TravelDateTo = this.TravelDateFrom;
      this.ATTravelDateTo = this.ATTravelDateFrom;
    }
    if ((this.ATTicketDateTo == null || this.ATTicketDateTo == "") && (this.ATTicketDateFrom != null || this.ATTicketDateFrom != "")) {
      this.TicketDateTo = this.TicketDateFrom;
      this.ATTicketDateTo = this.ATTicketDateFrom;
    }
    if ((this.ATReturntDateTo == null || this.ATReturntDateTo == "") && (this.ATReturnDateFrom != null || this.ATReturnDateFrom == "")) {
      this.ReturntDateTo = this.ReturnDateFrom;
      this.ATReturntDateTo = this.ATReturnDateFrom;
    }
    if (this.ATTravelDateTo < this.ATTravelDateFrom || this.ATTicketDateTo < this.ATTicketDateFrom || this.ATReturntDateTo < this.ATReturnDateFrom) {
      this.common.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 2000, true, false);

      return;
    }
    this.TravelDateFrom = this.common.ConvertToLocaleDate(new Date(this.TravelDateFrom));

    this.TravelDateTo = this.common.ConvertToLocaleDate(new Date(this.TravelDateTo));
    this.TicketDateFrom = this.common.ConvertToLocaleDate(new Date(this.TicketDateFrom));
    this.TicketDateTo = this.common.ConvertToLocaleDate(new Date(this.TicketDateTo));
    this.ReturnDateFrom = this.common.ConvertToLocaleDate(new Date(this.ReturnDateFrom));
    this.ReturntDateTo = this.common.ConvertToLocaleDate(new Date(this.ReturntDateTo));
    if (this.TicketType === undefined)
      this.TicketType = null;

    // if((this.TicketDateFrom=="01-Jan-1")||(this.TicketDateTo=="01-Jan-1")||(this.ReturnDateFrom=="01-Jan-1")
    // ||(this.ReturntDateTo=="01-Jan-1")||(this.TravelDateFrom=="01-Jan-1")||(this.TravelDateTo=="01-Jan-1"))
    // {
    //   this.common.showFlash1('Messages.InvalidDate','bg-danger text-white',2000);
    //   return;
    // }
    // if((Date.parse(this.TicketDateFrom)<Date.parse("01-Jan-1900")||
    // (Date.parse(this.TicketDateFrom)>Date.parse("31-Dec-9999")))) 
    // {
    //   this.common.showFlash1('Messages.InvalidDate','bg-danger text-white',2000);
    //   return;
    // }
    // if((Date.parse(this.TicketDateTo)<Date.parse("01-Jan-1900")||
    // (Date.parse(this.TicketDateTo)>Date.parse("31-Dec-9999")))) 
    // {
    //   this.common.showFlash1('Messages.InvalidDate','bg-danger text-white',2000);
    //   return;
    // }
    // if((Date.parse(this.TicketDateTo)<Date.parse("01-Jan-1900")||
    // (Date.parse(this.TicketDateTo)>Date.parse("31-Dec-9999")))) 
    // {
    //   this.common.showFlash1('Messages.InvalidDate','bg-danger text-white',2000);
    //   return;
    // }
    // if((Date.parse(this.ReturnDateFrom)<Date.parse("01-Jan-1900")||
    // (Date.parse(this.ReturnDateFrom)>Date.parse("31-Dec-9999")))) 
    // {
    //   this.common.showFlash1('Messages.InvalidDate','bg-danger text-white',2000);
    //   return;
    // }
    // if((Date.parse(this.ReturntDateTo)<Date.parse("01-Jan-1900")||
    // (Date.parse(this.ReturntDateTo)>Date.parse("31-Dec-9999")))) 
    // {
    //   this.common.showFlash1('Messages.InvalidDate','bg-danger text-white',2000);
    //   return;
    // }
    // if((Date.parse(this.TravelDateFrom)<Date.parse("01-Jan-1900")||
    // (Date.parse(this.TravelDateFrom)>Date.parse("31-Dec-9999")))) 
    // {
    //   this.common.showFlash1('Messages.InvalidDate','bg-danger text-white',2000);
    //   return;
    // }
    // if((Date.parse(this.TravelDateTo)<Date.parse("01-Jan-1900")||
    // (Date.parse(this.TravelDateTo)>Date.parse("31-Dec-9999")))) 
    // {
    //   this.common.showFlash1('Messages.InvalidDate','bg-danger text-white',2000);
    //   return;
    // }

    this.reportParms = ({
      TravelDateFrom: this.TravelDateFrom,
      TravelDateTo: this.TravelDateTo,
      TicketDateFrom: this.TicketDateFrom,
      TicketDateTo: this.TicketDateTo,
      ReturnDateFrom: this.ReturnDateFrom,
      ReturntDateTo: this.ReturntDateTo,
      SupplierName: this.SupplierName,
      Supplier: this.Supplier,
      BookedBy: this.BookedBy,
      PassengerType: this.PassType,
      TicketedBy: this.TicketedBy,
      GroupBy: this.GroupBy,
      GroupBy_tinyint: this.GroupBy_tinyint,
      Customer: this.Customer,
      PartyCode: this.PartyCode,
      Airline: this.Airline,
      VairlineAIRID: this.VairlineAIRID,
      OriginFrom: this.OriginFrom,
      Origin: this.Origin,
      DestinationFrom: this.DestinationFrom,
      Destination: this.Destination,
      Branch: this.Branch,
      BusinessArea: this.BusinessArea,
      BranchCode: this.BranchCode,
      BArea: this.BArea,
      UnusedTickets: this.UnusedTickets,
      TicketType: this.TicketType,
      SQLPASSWHEREQUERY: this.PassengerQuery,
      UserId: this.UserId,
      PassType: this.PassType
    }

    );
    this.open();
    debugger;
    this.moduleUsageService.ModuleUsageDTO.reportname = "airTicketReport"
    this.moduleUsageService.List = this.reportParms;
    this.moduleUsageService.getModuleUsageReport().subscribe(res => {
      debugger;
    })

  }
  public async SendTelerikMail() {//13846

    await this.GetEmailDetails();


  }
  public GetEmailDetails() {

    if (this.common.formData == undefined)
      this.common.formData = new ReportParameterDTO();
    this.common.formData.attachmentindex = 0;
    this.selectedReport = this.reportFile;
    this.common.formData.trdpfilename = this.selectedReport;


    this.common.formData.Userid = +this.apishare.uid;
    this.common.formData.p_emailtype = this.reportSubject;
    this.common.formData.RtftoHtmlUrl = this.env.RtftoHtmlUrl;
    this.common.reportParms = JSON.stringify(this.reportParms);
    this.common.reportParmsc = this.reportParms;


    this.subscription.add(
      this.common
        .GetReportBodyOrSubject()
        .subscribe((res) => {
          debugger;

          this.email.folderreportfilepath = res["ResponseData"]["Attachementpath"];

          this.email.isCrystalre = true;
          this.email.filename = this.reportSubject;
          this.email.emailsubject = this.reportSubject;
          this.email.reportname = this.reportSubject;
          this.opened = true;
          this.emailopened = true;
        }))
  }
  public emailclose(status) {
    this.emailopened = false;
    this.email.Atolre = false;
  }
  public EmailFormClosingatol(status: any) {
    this.email.emailbody = '';
    if (status == "EmailSendSuccesfully" || status == 'Closed') {
      this.emailclose(false);
    }
  }
  public onEnd() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const positionTop = windowHeight - this.height - this.offset;
    const positionLeft = windowWidth - this.offset;


    if (this.top < this.windowTop) {
      this.top = this.windowTop;
    }

    if (this.top > positionTop) {
      this.top = this.windowTop;
    }
    if (this.left < (this.windowLeft - 200)) {
      this.left = this.windowLeft;
    }

    // if (this.left > positionLeft) {
    //   this.left = positionLeft;
    // }
  }
  BookedBySelectedItem: any;
  TicketedBySelectedItem: any;
  BranchSelectedItem: any;
  BusinessSelectedItem: any;
  AirLineSelectedItem: any;
  ClearAll() {
    this.ATTicketDateFrom = null;
    this.ATTicketDateTo = null;
    this.ATTravelDateFrom = null;
    this.ATTravelDateTo = null;
    this.ATReturnDateFrom = null;
    this.ATReturntDateTo = null;
    this.BookedBySelectedItem = null;
    this.TicketedBySelectedItem = null;
    this.BranchSelectedItem = null;
    this.BusinessSelectedItem = null;
    this.TicketType = "All";
    this.AirLineSelectedItem = null;
    this.GroupSelectedItem = { text: "Airline", value: 0 };
    this.enableUnusedTicket = null;
    this.enableChild = null;
    this.enableAdult = null;
    this.enableInfant = null;
    this.enableYouth = null;
    this.supplierSelected = null;
    this.customerSelected = null;
    this.destinationSelected = null;
    this.destinationToSelected = null;
    this.customerSelected = '';
    this.autocompletecustomer.reset();
    this.autocompletearrival.reset();
    this.autocompletefrom.reset();
    this.autocompleteTo.reset();

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
