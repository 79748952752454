import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { GoogleAuthService } from '../Services/google-auth.service';
import { AlertService } from '@full-fledged/alerts';
import { CommonserviceService } from '../Services/commonservice.service';

@Component({
  selector: 'app-tfa-dialog',
  templateUrl: './tfa-dialog.component.html',
  styleUrls: ['./tfa-dialog.component.scss'],
})
export class TfaDialogComponent implements OnInit {
  public dataForm: FormGroup;
  constructor(
    public common: CommonserviceService,
    public dialogRef: MatDialogRef<TfaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private alertService: AlertService,
    private authService: GoogleAuthService,
   @Inject(LOCAL_STORAGE) private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.dataForm = this.fb.group({
      otp: ['', [Validators.required]],
      UID: ['', [Validators.required]],
    });
  }
  onSubmitClick() {
    
    let userdata  = JSON.parse(localStorage.getItem("currentUser"));
    let UID=userdata.UID;
    this.dataForm.controls.UID.setValue(UID);
    if (!this.dataForm.valid) {
      return;
    }
    
    if (this.data.isDisable) {
      this.authService.disableTFA(this.dataForm.value).subscribe(
        (res: any) => {
          //const loggedInUser = this.storageService.get('secretTOken');
          localStorage.setItem("secretToken",null);
          //this.storageService.set('loggedInUser', false);
          this.common.showSUCCESStoastrmsg("Successfully Disabled 2FA", 5000, true, false);
          this.dialogRef.close();
        },
        (error) => {
          console.error(error);
          this.common.showWARNINGtoastrmsg(error, 5000, true, false);
          //this.alertService.danger(error.message);
        }
      );
    } else {
      this.authService.validateTFAOTP(this.dataForm.value).subscribe(
        (res: any) => {
          debugger;
          let secretToken  = JSON.parse(localStorage.getItem("currentUser"));
          if(secretToken.ResponseData!=null){
          let secret=secretToken.ResponseData.secretToken;
          localStorage.setItem("secretToken",secret);
          }
          this.common.showSUCCESStoastrmsg("Successfully Enabled 2FA", 5000, true, false);
          this.dialogRef.close();
        },
        (error) => {
          console.log("error==>",error);
          this.common.showWARNINGtoastrmsg(error, 5000, true, false); 
        }
      );
    }
  }
}
