import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { InsuranceDetail } from '../Models/insurance-detail'
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class InsuranceService {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   }


 

  private newMethod(data: Object) {
    alert(data);
  }

  getInsurance(folderNo: number, GuidItinerary: string,isViewForm:boolean): Observable<InsuranceDetail> {
    const url = `${this.apiUrl}` + 'GetInsuranceDetailsById' + `/${folderNo}/${GuidItinerary}/${isViewForm}`;
    // const url = `${apiUrl}/${OrderNo}/${GuidItinerary}`;
    return this.http.get<InsuranceDetail>(url).pipe(
      tap(_ => console.log(`fetched Insurance id=${folderNo}`)),
      catchError(this.commonservice.handleError<InsuranceDetail>(`getInsurance id=${folderNo}`))
    );
  }
  addInsurance(InsuranceDetail: any): Observable<any> {
    this.setinsurancedate(InsuranceDetail);

    return this.http.post<any>(this.apiUrl + 'AddInsuranceDetails', InsuranceDetail, httpOptions).pipe(
      tap((InsuranceRes: any) => console.log(`added Insurance w/ id=${InsuranceDetail.TYPE}`)),
      catchError(this.commonservice.handleError<any>('addInsurance'))
    );
  }
setinsurancedate(InsuranceDetail: any) {
  if(InsuranceDetail.LOYALTYPOINTSAMT==null)
  InsuranceDetail.LOYALTYPOINTSAMT=0;
  if(InsuranceDetail.LOYALTYPOINTS==null)
  InsuranceDetail.LOYALTYPOINTS=0;

  if(InsuranceDetail.DepositDueDate===null || InsuranceDetail.DepositDueDate==='')
  InsuranceDetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
  else
  InsuranceDetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(InsuranceDetail.DepositDueDate);

  if(InsuranceDetail.BalanceDueDate===null || InsuranceDetail.BalanceDueDate==='')
  InsuranceDetail.BalanceDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
  else
  InsuranceDetail.BalanceDueDate=this.commonservice.ConvertToLocaleDate(InsuranceDetail.BalanceDueDate);

  if(InsuranceDetail.DATEF===null || InsuranceDetail.DATEF==='') {
    InsuranceDetail.DATEF=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    InsuranceDetail.INSDATE=this.commonservice.ConvertToLocaleDate(InsuranceDetail.INSDATE);
  } else {
    InsuranceDetail.DATEF=this.commonservice.ConvertToLocaleDate(InsuranceDetail.DATEF);
    InsuranceDetail.INSDATE=InsuranceDetail.DATEF;
   }

  if(InsuranceDetail.DATET===null || InsuranceDetail.DATET==='')
  InsuranceDetail.DATET=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
  else
  InsuranceDetail.DATET=this.commonservice.ConvertToLocaleDate(InsuranceDetail.DATET);

  if(InsuranceDetail.BookingDate===null || InsuranceDetail.BookingDate==='')
  InsuranceDetail.BookingDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
  else
  InsuranceDetail.BookingDate=this.commonservice.ConvertToLocaleDate(InsuranceDetail.BookingDate);

  
  // InsuranceDetail.DATEF= InsuranceDetail.DATEF.toLocaleDateString();
  // InsuranceDetail.DATET= InsuranceDetail.DATET.toLocaleDateString();
  // InsuranceDetail.BookingDate= InsuranceDetail.BookingDate.toLocaleDateString();
  // InsuranceDetail.INSDATE= (InsuranceDetail.INSDATE).toLocaleDateString();
  
}
  updateInsurance(InsuranceDetail: any): Observable<any> {

this.setinsurancedate(InsuranceDetail);
    

    const url = `${this.apiUrl}` + 'UpdateInsuranceDetails';///${id}
    return this.http.post(url, InsuranceDetail, httpOptions).pipe(
      tap(_ => console.log(`updated Insurance`)),
      catchError(this.commonservice.handleError<any>('updateInsurance'))
    );
  }
  deleteInsurance ( folderNo: number,GuidItinerary:string): Observable<any> {   

    const url = `${this.apiUrl}`+'DeleteInsuranceDetails'+`/${folderNo}/${GuidItinerary}`;
   
    return this.http.post(url,  httpOptions).pipe(
      tap(_ => console.log(`delete Tour`)),
      catchError(this.commonservice.handleError<any>('deleteTour'))
    );
  }
  
}
