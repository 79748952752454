import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PnrsourcefileService } from '../../Services/pnrsourcefile.service';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
import { ShareDataService } from '../../Services/share-data.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-folderoptions',
  templateUrl: './folderoptions.component.html',
  styleUrls: ['./folderoptions.component.scss']
})
export class FolderoptionsComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  folderoptions: FormGroup;
  folderoption: any;
  public EpayOptiondate: any;

  public showMsg: any;

  public isDisabled: boolean = true;
  constructor(public common: CommonserviceService, private apiShared: ShareDataService, private spinner: NgxSpinnerService, private toastr: ToastrService, private translateapi: TranslateService, private formBuilder: FormBuilder, private PnrsourcefileService: PnrsourcefileService, private route: ActivatedRoute, private router: Router) { }
  private orderNo: string = this.route.snapshot.params['orderNo'];
  public PaymentStatusList: Array<string> = ["Not Paid", "Paid", "Pending Approval", "Blocked"];
  public CustomerApproval = false;
  public Quotesonly = false;
  public TicketDelivered = false;
  public MANDATORYDEFAULTCHARGES = false;
  public DefaultBalanceDueDate = false;
  public AutoInvoice = false;
  public GDPRConsent = false;
  public DisabledSaveButton: boolean = false;
  lockStatus: any;
  public folderStatus: any;//By Caseid-12780
  public formEditable = true;//By Caseid-12780
  ngOnInit() {

    var orderno = sessionStorage.getItem('orderNo');

    if (orderno != null)
      if (this.orderNo !== orderno) {
        return this.router.navigate(['PenAir/Home'], { skipLocationChange: true });
      }


    this.folderoptions = this.formBuilder.group({
      CustomerNetPayment: ['Not Paid'],
      CustomerApproval: [''],
      OptDate: [''],
      INETREF_XMLFI_AUTONUM: [''],
      Quotesonly: [''],
      TicketDelivered: [''],
      MANDATORYDEFAULTCHARGES: [''],
      DefaultBalanceDueDate: [''],
      AutoInvoice: [''],
      GDPRConsent: [''],
      FOLDERNO: [''],
      Opttime: ['']
    })


    if (this.route.snapshot.params['orderNo'] > 0) {
      this.GetFolderOptionsById();
    }

    this.lockStatus = this.apiShared.folderReadOnly;//By Caseid-12780
    this.folderStatus = this.apiShared.FolderStatus;//By Caseid-12780
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced")//By Caseid-12780
    {
      this.formEditable = true;
    } else {
      this.formEditable = false;
    }
    if (this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund")//By Caseid-12780
    {
      this.formEditable = false;
    }

  }
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 50;

  public onChange(event: any): void {
    this.expandMode = parseInt(event.target.value, 10);
  }
  public onPanelChange(event: any): void { }

  public onEdit() {
    this.isDisabled = false
    // this.saveFolderoptions=true;

  }


  ValidateFieldlength() {
    if (this.folderoptions.controls.INETREF_XMLFI_AUTONUM.value != null) {
      var INETREF_XMLFI_AUTONUM = this.folderoptions.controls.INETREF_XMLFI_AUTONUM.value.toString();
      if (INETREF_XMLFI_AUTONUM != null && INETREF_XMLFI_AUTONUM.length > 9) {
        INETREF_XMLFI_AUTONUM = INETREF_XMLFI_AUTONUM.substring(0, 9);
        this.folderoptions.controls.INETREF_XMLFI_AUTONUM.setValue(INETREF_XMLFI_AUTONUM);
      }
    }
  }

  ValidateOpttimeFieldLength() {
    if (this.folderoptions.controls.Opttime.value != null) {
      var Opttime = this.folderoptions.controls.Opttime.value.toString();
      if (Opttime != null && Opttime.length > 4) {
        Opttime = Opttime.substring(0, 4);
        this.folderoptions.controls.Opttime.setValue(Opttime);
      }
    }
  }
  public GetFolderOptionsById() {
    this.subscription.add(this.PnrsourcefileService.GetFolderOptions(this.route.snapshot.params['orderNo'])
      .subscribe(data => {
        if (data != null && data != undefined) {


          debugger;
          this.folderoptions.setValue({
            CustomerNetPayment: data.ResponseData.CustomerNetPayment,
            CustomerApproval: data.ResponseData.CustomerApproval,
            OptDate: data.ResponseData.OptDate != null ? (data.ResponseData.OptDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.OptDate)) : null,
            //  Opttime:data.ResponseData.Opttime !=null?(data.ResponseData.Opttime.toString()=="1900-01-01T00:00:00"?null:new Date(data.ResponseData.Opttime)):null,
            INETREF_XMLFI_AUTONUM: data.ResponseData.INETREF_XMLFI_AUTONUM,
            Quotesonly: data.ResponseData.Quotesonly,
            TicketDelivered: data.ResponseData.TicketDelivered,
            MANDATORYDEFAULTCHARGES: data.ResponseData.MANDATORYDEFAULTCHARGES,
            DefaultBalanceDueDate: data.ResponseData.DefaultBalanceDueDate,
            AutoInvoice: data.ResponseData.AutoInvoice,
            GDPRConsent: data.ResponseData.GDPRConsent,
            FOLDERNO: data.ResponseData.FOLDERNO,
            Opttime: data.ResponseData.Opttime




          })

          if (data.ResponseData.CustomerNetPayment == "0") {
            this.folderoptions.controls.CustomerNetPayment.setValue("Not Paid");
          }
          if (data.ResponseData.CustomerNetPayment == 1) {
            this.folderoptions.controls.CustomerNetPayment.setValue("Paid");
          }
          if (data.ResponseData.CustomerNetPayment == 2) {
            this.folderoptions.controls.CustomerNetPayment.setValue("Pending Approval");
          }
          if (data.ResponseData.CustomerNetPayment == 3) {
            this.folderoptions.controls.CustomerNetPayment.setValue("Blocked");
          }
          if (data.ResponseData.CustomerApproval == 1) {
            this.CustomerApproval = true;
          }
          else {
            this.CustomerApproval = false;
          }
          if (data.ResponseData.Quotesonly == 1) {
            this.Quotesonly = true;
          }
          else {
            this.Quotesonly = false;
          }

          if (data.ResponseData.TicketDelivered == 1) {
            this.TicketDelivered = true;
          }
          else {
            this.TicketDelivered = false;
          }
          if (data.ResponseData.MANDATORYDEFAULTCHARGES == 1) {
            this.MANDATORYDEFAULTCHARGES = true;
          }
          else {
            this.MANDATORYDEFAULTCHARGES = false;
          }
          if (data.ResponseData.DefaultBalanceDueDate == 1) {
            this.DefaultBalanceDueDate = true;
          }
          else {
            this.DefaultBalanceDueDate = false;
          }
          if (data.ResponseData.AutoInvoice == 1) {
            this.AutoInvoice = true;
          }
          else {
            this.AutoInvoice = false;
          }
          if (data.ResponseData.GDPRConsent == 1) {
            this.GDPRConsent = true;
          }
          else {
            this.GDPRConsent = false;
          }


        }
      }));
  }
  OnlinecustomerconfirmationChange() {
    // this.CustomerApproval==true;

    if (this.CustomerApproval == true) {
      this.folderoptions.controls.CustomerApproval.setValue(1);

    }
    else {
      this.folderoptions.controls.CustomerApproval.setValue(0);
    }
  }
  QuotesonlyChange() {

    if (this.Quotesonly == true) {
      this.folderoptions.controls.Quotesonly.setValue(1);

    }
    else {
      this.folderoptions.controls.Quotesonly.setValue(0);
    }
  }

  TicketDeliveredChange() {
    if (this.TicketDelivered == true) {
      this.folderoptions.controls.TicketDelivered.setValue(1);

    }
    else {
      this.folderoptions.controls.TicketDelivered.setValue(0);
    }
  }
  MANDATORYDEFAULTCHARGESChange() {
    if (this.MANDATORYDEFAULTCHARGES == true) {
      this.folderoptions.controls.MANDATORYDEFAULTCHARGES.setValue(1);

    }
    else {
      this.folderoptions.controls.MANDATORYDEFAULTCHARGES.setValue(0);
    }
  }
  DefaultBalanceDueDateChange() {

    if (this.DefaultBalanceDueDate == true) {
      this.folderoptions.controls.DefaultBalanceDueDate.setValue(1);

    }
    else {
      this.folderoptions.controls.DefaultBalanceDueDate.setValue(0);
    }
  }
  AutoInvoiceChange() {
    if (this.AutoInvoice == true) {
      this.folderoptions.controls.AutoInvoice.setValue(1);

    }
    else {
      this.folderoptions.controls.AutoInvoice.setValue(0);
    }
  }

  GDPRConsentChange() {

    if (this.GDPRConsent == true) {
      this.folderoptions.controls.GDPRConsent.setValue(1);

    }
    else {
      this.folderoptions.controls.GDPRConsent.setValue(0);
    }
  }
  public onSave() {
    debugger;
    this.DisabledSaveButton = true;
    if (this.folderoptions.controls.CustomerNetPayment.value == "Not Paid") {
      this.folderoptions.controls.CustomerNetPayment.setValue("0");
    }

    if (this.folderoptions.controls.CustomerNetPayment.value == "Paid") {
      this.folderoptions.controls.CustomerNetPayment.setValue(1);
    }
    if (this.folderoptions.controls.CustomerNetPayment.value == "Pending Approval") {
      this.folderoptions.controls.CustomerNetPayment.setValue(2);
    }
    if (this.folderoptions.controls.CustomerNetPayment.value == "Blocked") {
      this.folderoptions.controls.CustomerNetPayment.setValue(3);
    }

    this.EpayOptiondate = (this.folderoptions.controls.OptDate.value == null || this.folderoptions.controls.OptDate.value == "" ? "01-Jan-1900" : this.folderoptions.controls.OptDate.value);
    this.EpayOptiondate = this.common.ConvertToLocaleDate(this.EpayOptiondate);

    if (Boolean(this.common.ValidDateRange(this.EpayOptiondate)) == false) {
      this.subscription.add(this.translateapi.get(['Messages.InvalidStartDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidStartDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.DisabledSaveButton = false;
      return;
    }

    this.folderoptions.value.OptDate = this.EpayOptiondate;

    this.folderoptions.value.FOLDERNO = this.orderNo;
    this.spinner.show();
    this.PnrsourcefileService.UpdatedFolderOptions(this.folderoptions.value).subscribe(res => {
      if (res != null && res != undefined) {
        if (res["ResponseData"].Status == "Success") {
          debugger;
          this.spinner.hide();
          this.showMsg = res["ResponseData"].StatusDetails;
          this.toastr.success(this.showMsg, "",
            {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true,

            })
          this.DisabledSaveButton = false;
          this.isDisabled = true;
        }
        else if (res["ResponseData"].Status == "Failed") {
          debugger;
          this.showMsg = res["ResponseData"].StatusDetails;
          this.toastr.warning(this.showMsg, "",
            {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          this.DisabledSaveButton = false;
        }
        else {
          debugger;
          this.showMsg = res["ResponseData"].StatusDetails;
          this.toastr.warning(this.showMsg, "",
            {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          this.DisabledSaveButton = false;
        }


      }
    }, (err) => {

      this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {

        this.showMsg = translations['Messages.some_error_in_save'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,

          closeButton: true
        });
        this.DisabledSaveButton = false;
      }));
      this.DisabledSaveButton = false;
    });

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}


