export class FolderActivityReportbyProductDTO {
    CreationFromdate: any;
    CreationTodate: any;
    CostIncurredFromDate: any;
    CostIncurredToDate: any;
    InvFromdate: any;
    InvTodate: any;
    ReportType: any;
    FolderNo: any;
    OrderNo: any;
    Product: any;
    Customer: any;
    CustomerCode: any;
    CustGroup: any;
    CustomerGroup: any;
    CustomerCrGroup: any;
    CustomerCrGroupName: any;
    ProductName: any;
    Branch: any;
    BArea: any;
    BusinessArea: any;
    TypeOfSales: any;
    TypesOfSalesName: any;
    BranchName: any;
    StatisticalPost: any;
    StatPost: any;
    isIncluded: boolean = true;
    MCMultipleSelect: boolean = false;
    ReportGenarated: boolean = false;
    BranchSelectedItem: any;
    BranchCodeList: any;
    PassQuery: any;
    CustUserCode: any;
}
export class FolderActivityReportbyProductDetailedDTO {
    PMUSERCODE: any;
    PMNAME: any;
    INVDATE: any;
    INVOICENO: any;
    PROJNO: any;
    CUSTUSRCODE: any;
    BUYAMT: any;
    COMMAMT: any;
    SELLAMT: any;
    VATAMT: any;
    CREDITAMT: any;
    AGTCOMSUM: any;
    AGTCOMVAT: any;
    Total: any;

}
