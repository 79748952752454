import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from 'src/env.service';
import { CommonserviceService } from './commonservice.service';
import { catchError, tap } from 'rxjs/operators';
import { PenChannelParameterDTO } from '../Models/pen-channel-parameter-dto.model';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class PenChannelService {
  apiUrl :string;
  public formData:PenChannelParameterDTO;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   }
   public getIPAddress()  
   {  
     return this.http.get("https://api.ipify.org/?format=json");  
   } 
  // getPenchannelDetails(): Observable<any> {
  //   const url = `${this.apiUrl}`+'GetAllPenChannel';
  //   return this.http.get<any>(url).pipe(
  //     catchError(this.commonservice.handleError<any>(`getPenChannel`))
  //   );
  // }

  public  getPenchannelDetails(): Observable<PenChannelParameterDTO> {//Caseid-12434
    var body = {
          ...this.formData,
    };
    return this.http.post<PenChannelParameterDTO>(this.apiUrl+'GetAllPenChannel', body, httpOptions).pipe(
      tap((PenChannelParameterDTO: any) => console.log(`GetAllPenChannel`)),
      catchError(this.commonservice.handleError<any>('GetAllPenChannel'))
    );
  }
  addPenchannel(PCDetails: any) {
     this.http.post<any>(this.apiUrl + 'AddPenChannel', PCDetails, httpOptions)
    .subscribe(data => {  
      if(data!=null&& data!=undefined){debugger;
        sessionStorage.setItem('PCNO', data.ResponseData);
      }  
    });
  }
  SaveLogoutTime(PCDetails: any): Observable<any> {
    debugger;
    PCDetails.LogoutTime=new Date().toLocaleString();

    return this.http.post<any>(this.apiUrl + 'SaveLogoutTime', PCDetails, httpOptions).pipe(
      catchError(this.commonservice.handleError<any>('SaveLogoutTime'))
    );
  }
}
