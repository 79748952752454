export class MessageAnalysisReport {
    ActionDateFrom: any;
    ActionDateTo: any;
    FolderNo: string;
    OrderNo: string;

    AlertStatus: string;
    Action: string;
    ActionCode: any;
    User: string;
    usercode: any;
    ExportPDFParam: any;

}
export class MessageAnalysisReportDTOList {
    UserName: any;
    DATE: any;
    TIME: any;
    FolderNo: string;
    OrderNo: string;
    Action: string;
    DESCRIPTION: string;
}