import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { TransfersDetail } from '../../Models/transfers-detail';
import { TransfersService } from '../../Services/transfers.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { ShareDataService } from '../../Services/share-data.service';
import { CurrencyExchangerateService } from '../../Services/currency-exchangerate.service';
import { CurrencyExchangerate } from '../../Models/currency-exchangerate';
import { NgxSpinnerService } from 'ngx-spinner';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { Alert } from 'selenium-webdriver';
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription, Subject } from 'rxjs';
import { SupplierMasterService } from '../../Services/supplier-master.service';
import { ToastrService } from 'ngx-toastr';
import { PocreationService } from '../../Services/pocreation.service';
import { ProductUpdatePODTO } from '../../Models/pocreation-detail';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { FolderpriceService } from '../../Services/folderprice.service';
@Component({
  selector: 'app-transfers-detail-edit',
  templateUrl: './transfers-detail-edit.component.html',
  styleUrls: ['./transfers-detail-edit.component.scss']
})
export class TransfersDetailEditComponent implements OnInit {
  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  private subscription: Subscription = new Subscription();
  @ViewChild('fromcity') public fromcity: AutoCompleteComponent;
  @ViewChild('tocity') public tocity: AutoCompleteComponent;
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  @ViewChild("apppaymenttermsedit", { read: false, static: false }) public payterm: any;
  @ViewChild('Notes') printnote;
  @ViewChild("appfchcdetailsedit", { read: false, static: false }) public fchcdetails: any;
  isShown = false;
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;



  ItnryNotes: any;
  public opened = false;
  openSearch = false;
  folderEditMode: any;
  public PONO;
  folderExrate: any;
  fldrCurrency: any;
  currencyExrate: CurrencyExchangerate;
  private exRate: any;
  ucode: string;
  disableTextbox = false;
  transferEditForm: FormGroup;
  submitted = false;
  loaded = false;
  public showMsg: any;
  Uname: any = '';
  savebutton = true;
  public pocreated = true;
  creditLimitMsg = false;
  creditLimiFlag = false;
  msg: any;
  FC_Tot_BuyInitalvalue: any = 0;
  FCSell: any = 0;
  prdtUpdatePODTO: ProductUpdatePODTO;
  // hotelDetailModal=HotelDetail;
  public statusList: Array<string> = ['OK', 'Pending', 'Cancelled', 'Confirmed'];
  public paymethodList: Array<string> = ['Credit', 'Company Card', 'Customer Card', 'Customer Pay Locally'];
  public CommonList: Array<string> = ['Purchase', 'Gross', 'Sales'];
  public CardList: Array<LookUpDetails> = [];
  constructor(private apiPO: PocreationService, public common: CommonserviceService, private lookupApi: LookUpDetailsService, private router: Router, private route: ActivatedRoute,
    private formBuilder: FormBuilder, private api: TransfersService, private apiShared: ShareDataService, private toastr: ToastrService,
    private apiExRate: CurrencyExchangerateService, private flashMessage: FlashMessagesService,
    private translateapi: TranslateService, private spinner: NgxSpinnerService, private supplier: SupplierMasterService, private objFolderPrice: FolderpriceService) { }
  private guid: string = this.route.snapshot.params['guidItinerary'];
  // 'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/';
  private orderno = this.route.snapshot.params['orderNo'];
  Currency: Array<LookUpDetails['ResponseData']> = [];
  public CurrencyselectedItem: LookUpDetails['ResponseData'];
  SupplierList: Array<LookUpDetails['ResponseData']> = [];
  public supplierSelectedItem: LookUpDetails['ResponseData'];
  public supplierSelected: any;
  destinationList: Array<LookUpDetails['ResponseData']> = [];
  destinationfromList: Array<LookUpDetails["ResponseData"]> = [];
  public destinationSelectedItem: LookUpDetails['ResponseData'];
  public destinationToSelectedItem: LookUpDetails['ResponseData'];
  public destinationSelected: any;
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  public destinationToSelected: any;
  public projNo: any;
  homeCurrency: any;
  hcExRate: any;
  BookedByList: Array<LookUpDetails['ResponseData']> = [];
  public BookedByselectedItem: LookUpDetails['ResponseData'];
  departureDate: any //337
  bookingDate: any //337
  depositDueDate: any//337
  lockStatus: any;
  folderStatus: any;
  formeditable: boolean = true;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  ngOnInit() {


    //case id : 13777 
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    } else {
      this.formeditable = false;

    }
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;

    }
    // case id : 13777
    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderno !== orderno) {
        return this.router.navigate(['PenAir/Home']);
      }
    this.hcExRate = this.apiShared.hcExRate;
    this.homeCurrency = this.apiShared.homeCurrency;
    this.folderEditMode = 'true';
    // this.apiShared.folderEditMode = this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.Uname = this.apiShared.uname;
    this.getFolderValues();
    this.transferEditForm = this.formBuilder.group({
      FOLDERNO: [this.orderno],
      SUPPNAME: [''],
      SUPPCODE: ['', Validators.required],
      PROVIDER: [''],
      FROMTRA: [''],
      TOTRA: [''],
      DEPDATE: [new Date()],
      DEPTIME: [0],
      ReturnDate: [new Date()],
      ReturnTime: [0],
      VEHITYPE: [''],
      FLIGHTNO: [''],
      STATUS: ['OK'],
      PAYABLE: [0],
      VATCODE: [0],
      VATPER: [0],
      PAYMODE: [''],
      SUPPREF: [''],
      COMMENTS: [''],
      SLNO: [0],
      PROJNO: [this.projNo],
      ITYPE: ['Transfers'],
      GDS: [''],
      PNR: [''],
      CURCODE: [this.homeCurrency],
      CURExRate: [this.hcExRate],
      AMTLC: [0],
      AMTHC: [0],
      CommOn: ['Purchase'],
      FCBuy: [0],
      FCCommPer: [0],
      FCCommAmt: [0],
      FCSell: [0],
      HCCommPer: [0],
      HCCommAmt: [0],
      HCSell: [0],
      ItnryNotes: [''],
      LowFB: [''],
      LowFare: [0],
      HighFB: [''],
      HighFare: [0],
      Fareoffered: [0],
      PotentialSaving: [0],
      ActualSaving: [0],
      PDNO: [0],
      PDTYPENO: [0],
      Forgone: [0],
      AddRemarks: [''],
      PDAMOUNT: [0],
      BookingDate: [new Date()],
      PONO: [0],
      PONOYEARID: [''],
      PAYMENTID: [0],
      PMTID: [''],
      DEFDEPPER: [0],
      DEFDEP: [0],
      DepositDueDate: [''],
      BalanceDueDate: [''],
      BalanceDueAmount: [0],
      GUIDItinerary: [''],
      LOYALTYPOINTS: [0],
      LOYALTYPOINTSMEMBNO: [''],
      TOMSVATTAXNO: [0],
      TOMSVATAMT: [0],
      PDUNIQUEREF: [''],
      LOYALTYPOINTSAMT: [0],
      PDFCURID: [this.homeCurrency],
      PDFCAMT: [0],
      PDEXRATE: [this.hcExRate],
      SELLTAXNO: [0],
      SELLTAXRATE: [0],
      FC_SELLTAXAMT: [0],
      HC_SELLTAXAMT: [0],
      PONOCCDeferredPosted: [0],
      BOOKEDBY: this.Uname,
      CONFIRMATIONNO: [''],
      TaxExcludingSalesTax: [0],
      BaseFare: [0],
      GSTHSTOnComm: 0,
      //12698
      CostingSupplierGrossValue: 0,//12698
      CostingSupplierServiceFee: 0,
      CostingTaxNo: 0,
      CostingTaxRate: 0,
      CostingSupplierTaxAmount: 0,
      CostingSupplierNetBuyPrice: 0,
      CostingCustomerSalesValue: 0,
      CostingCustomerServiceFee: 0,
      CostingCustomerTaxAmount: 0,
      CostingCustomerNetSellPrice: 0,
      CostingVATType: 0,
      Markup: [0],
      CostofSale: [0],
      CostingSuppComm: [0],
      SupplierDeposit: [0],
      CountryID: ['']

      //12698
    });
    if (this.guid != null) {
      this.getTransfers(this.orderno, this.guid);
    } if (this.guid == null) {
      this.FormLoyalityPointsChange();
      this.loaded = true;
    }
    // this.loadSupplier();
    this.subscription.add(this.lookupApi.GetAllLookup('CURRENCY').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.Currency = data['ResponseData'];
          if (this.transferEditForm.controls.CURCODE.value !== '') {
            this.CurrencyselectedItem = this.Currency.find(item => item.Code === this.transferEditForm.controls.CURCODE.value);
          }
        }
      }));
    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.BookedByList = data['ResponseData'];
          if (this.transferEditForm.controls.BOOKEDBY.value !== '') {
            this.BookedByselectedItem = this.BookedByList.find(item => item.Name === this.transferEditForm.controls.BOOKEDBY.value);
          }
        }
      }
    ));
    this.onChanges();


  }
  get f() { return this.transferEditForm.controls; }

  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }
  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860

  getTransfers(id, guid) {
    this.spinner.show();
    this.subscription.add(this.api.getTransfers(id, guid, false)
      .subscribe(data => {
        if (data != null && data != undefined) {
          this.transferEditForm.setValue({
            FOLDERNO: data.ResponseData.FOLDERNO,
            SUPPNAME: data.ResponseData.SUPPNAME,
            SUPPCODE: data.ResponseData.SUPPCODE,
            PROVIDER: data.ResponseData.PROVIDER,
            FROMTRA: data.ResponseData.FROMTRA,
            TOTRA: data.ResponseData.TOTRA,
            DEPDATE: data.ResponseData.DEPDATE != null ? (data.ResponseData.DEPDATE.toString() === '1900-01-01T00:00:00' ? null : new Date(data.ResponseData.DEPDATE)) : null,
            // DEPDATE:new Date(data.ResponseData.DEPDATE),
            DEPTIME: data.ResponseData.DEPTIME,
            VEHITYPE: data.ResponseData.VEHITYPE,
            FLIGHTNO: data.ResponseData.FLIGHTNO,
            STATUS: data.ResponseData.STATUS,
            PAYABLE: data.ResponseData.PAYABLE,
            VATCODE: data.ResponseData.VATCODE,
            VATPER: data.ResponseData.VATPER,
            PAYMODE: data.ResponseData.PAYMODE,
            SUPPREF: data.ResponseData.SUPPREF,
            COMMENTS: data.ResponseData.COMMENTS,
            SLNO: data.ResponseData.SLNO,
            PROJNO: data.ResponseData.PROJNO,
            ITYPE: 'Transfers', // data.ResponseData.ITYPE,
            GDS: data.ResponseData.GDS,
            PNR: data.ResponseData.PNR,
            CURCODE: data.ResponseData.CURCODE,
            CURExRate: data.ResponseData.CURExRate,
            AMTLC: data.ResponseData.AMTLC,
            AMTHC: data.ResponseData.AMTHC,
            CommOn: data.ResponseData.CommOn,
            FCBuy: data.ResponseData.FCBuy,
            FCCommPer: data.ResponseData.FCCommPer,
            FCCommAmt: data.ResponseData.FCCommAmt,
            FCSell: data.ResponseData.FCSell,
            HCCommPer: data.ResponseData.HCCommPer,
            HCCommAmt: data.ResponseData.HCCommAmt,
            HCSell: data.ResponseData.HCSell,
            ItnryNotes: data.ResponseData.ItnryNotes,
            LowFB: data.ResponseData.LowFB,
            LowFare: data.ResponseData.LowFare,
            HighFB: data.ResponseData.HighFB,
            HighFare: data.ResponseData.HighFare,
            Fareoffered: data.ResponseData.Fareoffered,
            PotentialSaving: data.ResponseData.PotentialSaving,
            ActualSaving: data.ResponseData.ActualSaving,
            PDNO: data.ResponseData.PDNO,
            PDTYPENO: data.ResponseData.PDTYPENO,
            Forgone: data.ResponseData.Forgone,
            AddRemarks: data.ResponseData.AddRemarks,
            PDAMOUNT: data.ResponseData.PDAMOUNT,
            BookingDate: data.ResponseData.BookingDate != null ? (data.ResponseData.BookingDate.toString() === '1900-01-01T00:00:00' ? null : new Date(data.ResponseData.BookingDate)) : null,
            // BookingDate:new Date(data.ResponseData.BookingDate),
            PONO: data.ResponseData.PONO,
            PONOYEARID: data.ResponseData.PONOYEARID,
            PAYMENTID: data.ResponseData.PAYMENTID,
            PMTID: data.ResponseData.PMTID,
            DEFDEPPER: data.ResponseData.DEFDEPPER,
            DEFDEP: data.ResponseData.DEFDEP,
            DepositDueDate: data.ResponseData.DepositDueDate != null ? (data.ResponseData.DepositDueDate.toString() === '1900-01-01T00:00:00' ? null : new Date(data.ResponseData.DepositDueDate)) : null,
            // DepositDueDate:new Date(data.ResponseData.DepositDueDate),
            BalanceDueDate: data.ResponseData.BalanceDueDate != null ? (data.ResponseData.BalanceDueDate.toString() === '1900-01-01T00:00:00' ? null : new Date(data.ResponseData.BalanceDueDate)) : null,
            //   BalanceDueDate:new Date(data.ResponseData.BalanceDueDate),
            BalanceDueAmount: data.ResponseData.BalanceDueAmount,
            GUIDItinerary: data.ResponseData.GUIDItinerary,
            LOYALTYPOINTS: data.ResponseData.LOYALTYPOINTS,
            LOYALTYPOINTSMEMBNO: data.ResponseData.LOYALTYPOINTSMEMBNO,
            TOMSVATTAXNO: data.ResponseData.TOMSVATTAXNO,
            TOMSVATAMT: data.ResponseData.TOMSVATAMT,
            PDUNIQUEREF: data.ResponseData.PDUNIQUEREF,
            LOYALTYPOINTSAMT: data.ResponseData.LOYALTYPOINTSAMT,
            PDFCURID: data.ResponseData.PDFCURID,
            PDFCAMT: data.ResponseData.PDFCAMT,
            PDEXRATE: data.ResponseData.PDEXRATE,
            SELLTAXNO: data.ResponseData.SELLTAXNO,
            SELLTAXRATE: data.ResponseData.SELLTAXRATE,
            FC_SELLTAXAMT: data.ResponseData.FC_SELLTAXAMT,
            HC_SELLTAXAMT: data.ResponseData.HC_SELLTAXAMT,
            PONOCCDeferredPosted: data.ResponseData.PONOCCDeferredPosted,
            BOOKEDBY: data.ResponseData.BOOKEDBY,
            CONFIRMATIONNO: data.ResponseData.CONFIRMATIONNO,
            TaxExcludingSalesTax: data.ResponseData.TaxExcludingSalesTax,
            BaseFare: data.ResponseData.BaseFare,
            GSTHSTOnComm: 0,
            ReturnDate: data.ResponseData.ReturnDate != null ? (data.ResponseData.ReturnDate.toString() === '1900-01-01T00:00:00' ? null : new Date(data.ResponseData.ReturnDate)) : null,
            ReturnTime: data.ResponseData.ReturnTime,
            //12698
            CostingSupplierGrossValue: data.ResponseData.CostingSupplierGrossValue,
            CostingSupplierServiceFee: data.ResponseData.CostingSupplierServiceFee,
            CostingTaxNo: data.ResponseData.CostingTaxNo,
            CostingTaxRate: data.ResponseData.CostingTaxRate,
            CostingSupplierTaxAmount: data.ResponseData.CostingSupplierTaxAmount,
            CostingSupplierNetBuyPrice: data.ResponseData.CostingSupplierNetBuyPrice,
            CostingCustomerSalesValue: data.ResponseData.CostingCustomerSalesValue,
            CostingCustomerServiceFee: data.ResponseData.CostingCustomerServiceFee,
            CostingCustomerTaxAmount: data.ResponseData.CostingCustomerTaxAmount,
            CostingCustomerNetSellPrice: data.ResponseData.CostingCustomerNetSellPrice,
            CostingVATType: data.ResponseData.CostingVATType,
            Markup: data.ResponseData.CostingCustomerServiceFee,
            CostofSale: data.ResponseData.CostingCustomerSalesValue,
            CostingSuppComm: data.ResponseData.CostingSuppComm,
            SupplierDeposit: data.ResponseData.SupplierDeposit,
            CountryID: ['']
            //12698
          });
          this.FC_Tot_BuyInitalvalue = this.transferEditForm.controls.FCBuy.value
          this.FCSell = this.transferEditForm.controls.FCSell.value;
          this.spinner.hide();
          // this.isLoadingResults = false;
          this.FormLoyalityPointsChange();
          this.loadtocity();
          this.loadfromcity();
          this.loadsuppliermaster();

          this.PONO = this.transferEditForm.controls.PONO.value;

          if (this.PONO > 0) {

            this.pocreated = false;
          }
          else {
            this.pocreated = true;
          }
          this.loaded = true;
        }
        else {
          this.spinner.hide();
        }
      }));
  }

  public loadtocity() {
    if (this.transferEditForm.controls.TOTRA.value != '' && this.transferEditForm.controls.TOTRA.value != null) {
      this.subscription.add(this.lookupApi.SearchDestination(this.transferEditForm.controls.TOTRA.value, '0').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.destinationList = data["ResponseData"];
            if (this.transferEditForm.controls.TOTRA.value != "") {
              this.destinationToSelectedItem = this.destinationList.find(item => item.UsrCode == this.transferEditForm.controls.TOTRA.value);
              if (this.destinationToSelectedItem != null || this.destinationToSelectedItem != undefined) {//By Caseid-12753
                this.destinationToSelected = this.destinationToSelectedItem.Name;

              }
              else {//By Caseid-12753
                this.destinationToSelected = this.transferEditForm.controls.TOTRA.value;
              }
            }
          }

        }
      ));
    }
  }
  public CurrencyExRatechange(): void {
    this.CalculateForm();
  }
  public loadfromcity() {
    if (this.transferEditForm.controls.FROMTRA.value != '' && this.transferEditForm.controls.FROMTRA.value != null) {
      this.subscription.add(this.lookupApi.SearchDestination(this.transferEditForm.controls.FROMTRA.value, '0').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.destinationfromList = data["ResponseData"];
            if (this.transferEditForm.controls.FROMTRA.value != "") {
              this.destinationSelectedItem = this.destinationfromList.find(item => item.UsrCode == this.transferEditForm.controls.FROMTRA.value);
              if (this.destinationSelectedItem != null || this.destinationSelectedItem != undefined) {//By Caseid-12753
                this.destinationSelected = this.destinationSelectedItem.Name;
              }
              else {//By Caseid-12753
                debugger;
                this.destinationSelected = this.transferEditForm.controls.FROMTRA.value;
              }
            }
          }
        }
      ));
    }
  }
  loadsuppliermaster() {

    if (this.transferEditForm.controls.SUPPCODE.value != '' && this.transferEditForm.controls.SUPPCODE.value != null) {
      this.subscription.add(this.lookupApi.findSupplier(this.transferEditForm.controls.SUPPCODE.value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
            if (this.transferEditForm.controls.SUPPCODE.value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.transferEditForm.controls.SUPPCODE.value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
                this.transferEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID);
              }
            }
          }
        }
      ));
    }
  }
  handleFiltersupplier(value) {
    value = value.trim();//By Caseid-12753
    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  public DestinationValueChange(value: any): void {

    this.destinationSelectedItem = this.destinationfromList.find(item => item.Name === value);
    if (this.destinationSelectedItem != null) {
      this.transferEditForm.controls.FROMTRA.setValue(this.destinationSelectedItem.Code);
    } else {
      // this.destinationSelected = '';//Commented By Caeid-12753
      this.transferEditForm.controls.FROMTRA.setValue(value);//By Caeid-12753
    }
  }
  public DestinationToValueChange(value: any): void {
    this.destinationToSelectedItem = this.destinationList.find(item => item.Name === value);
    if (this.destinationToSelectedItem != null) {
      this.destinationToSelected = '';
      this.transferEditForm.controls.TOTRA.setValue(this.destinationToSelectedItem.Code);
    } else {
      //this.destinationToSelected = '';//Commented By Caeid-12753
      this.transferEditForm.controls.TOTRA.setValue(value);//By Caeid-12753
    }
  }
  handletocity(value) {
    value = value.trim();//By Caseid-12753
    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.destinationList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.tocity.toggle(false);
    }
  }
  handlefromcity(value) {
    value = value.trim();//By Caseid-12753
    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.destinationfromList = data["ResponseData"];
          }
        }
      ));
    } else {
      this.fromcity.toggle(false);
    }
  }
  public onChange(event: any): void {
    this.expandMode = parseInt(event.target.value, 10);
  }
  public onPanelChange(event: any): void { console.log('stateChange: ', event); }

  onFormSubmit() {
    debugger;
    this.spinner.show();
    if (this.transferEditForm.controls.PAYMODE.value == "Company Card")//By case id-355
    {
      if ((this.transferEditForm.controls.PDAMOUNT.value == 0) || (this.transferEditForm.controls.PDNO.value == "0") || (this.transferEditForm.controls.PDTYPENO.value == "0")) {
        this.subscription.add(this.translateapi.get(['Messages.PleaseSelectCompanyCardWithPaymentOption']).subscribe((translations) => {

          this.showMsg = translations['Messages.PleaseSelectCompanyCardWithPaymentOption'];;
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        this.spinner.hide();
        return;
      }
    }

    this.departureDate = (this.transferEditForm.controls.DEPDATE.value == null || this.transferEditForm.controls.DEPDATE.value == "" ? "01-Jan-1900" : this.transferEditForm.controls.DEPDATE.value);//Case id-337
    this.bookingDate = (this.transferEditForm.controls.BookingDate.value == null || this.transferEditForm.controls.BookingDate.value == "" ? "01-Jan-1900" : this.transferEditForm.controls.BookingDate.value);//Case id-337
    this.depositDueDate = (this.transferEditForm.controls.DepositDueDate.value == null || this.transferEditForm.controls.DepositDueDate.value == "" ? "01-Jan-1900" : this.transferEditForm.controls.DepositDueDate.value);//Case id-337
    if (Boolean(this.common.ValidDateRange(this.departureDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDepartureDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDepartureDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.common.ValidDateRange(this.bookingDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidBookingDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidBookingDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }

    if (Boolean(this.common.ValidDateRange(this.depositDueDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDepositDueDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDepositDueDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (this.transferEditForm.value['CURExRate'] === '' || this.transferEditForm.value['CURExRate'] === 0 || this.transferEditForm.value['CURExRate'] === null) {
      this.subscription.add(this.translateapi.get(['Messages.ExchangeRateError']).subscribe((translations) => {
        this.showMsg = translations['Messages.ExchangeRateError'];
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.spinner.hide();
      return;
    }
    this.submitted = true;
    if (this.transferEditForm.controls.ItnryNotes.value.length > 4000) //case id 
    {
      this.subscription.add(this.translateapi.get(['Messages.invalidNotes']).subscribe((translations) => {
        this.showMsg = translations['Messages.invalidNotes'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (this.transferEditForm.invalid) {
      this.spinner.hide();
      return;
    }
    this.objFolderPrice
      .CreditLimitCheck(this.guid == null || undefined ? "0" : this.guid, 0, this.orderno, this.transferEditForm.get("HCSell").value)
      .subscribe(res => {
        if (res != undefined || res["ResponseData"] != null) {
          if (
            res["ResponseData"].value != "0" &&
            res["ResponseData"].Status != "0" &&
            res["ResponseData"].ErrorMessage != ""
          ) {
            debugger;
            if (res["ResponseData"].value == 1) {
              this.spinner.hide();
              this.msg = res["ResponseData"].ErrorMessage;
              this.creditLimiFlag = true;
              this.creditLimitMsg = true;
              return false;
            } else if (res["ResponseData"].value == 2) {
              this.spinner.hide();
              this.msg = res["ResponseData"].ErrorMessage;
              this.creditLimiFlag = false;
              this.creditLimitMsg = true;
              // this.AirTicketSaveAndUpdate();
            } else {
              this.TransfersSaveandUpdate();
            }
          } else {
            this.TransfersSaveandUpdate();
          }
        }
      });

  }
  TransfersSaveandUpdate() {
    this.transferEditForm.controls.BaseFare.setValue(this.transferEditForm.controls.FCSell.value);

    if (this.guid != null && this.PONO > 0) {
      this.prdtUpdatePODTO = new ProductUpdatePODTO();
      this.prdtUpdatePODTO.FOLDERNO = this.orderno;
      this.prdtUpdatePODTO.GUIDItinerary = this.transferEditForm.get('GUIDItinerary').value;
      this.prdtUpdatePODTO.LOYALTYPOINTS = this.transferEditForm.get('LOYALTYPOINTS').value;
      this.prdtUpdatePODTO.LOYALTYPOINTSAMT = this.transferEditForm.get('LOYALTYPOINTSAMT').value
      // this.prdtUpdatePODTO.LOYALTYPOINTSBAL=this.transferEditForm.get('LOYALTYPOINTSBAL').value
      this.prdtUpdatePODTO.LOYALTYPOINTSMEMBNO = this.transferEditForm.get('LOYALTYPOINTSMEMBNO').value;
      this.prdtUpdatePODTO.TYPE = "Transfers";
      this.prdtUpdatePODTO.BaseFare = this.transferEditForm.get('BaseFare').value;
      this.prdtUpdatePODTO.SELLAMT = this.transferEditForm.get('FCSell').value;
      this.prdtUpdatePODTO.SELLCOMM = this.transferEditForm.get('HCCommAmt').value;

      this.prdtUpdatePODTO.HCCommAmt = this.transferEditForm.get('HCCommAmt').value;

      // this.prdtUpdatePODTO.fCSellTax=this.hotelEditForm.get('RDESC').value;

      this.prdtUpdatePODTO.SELLTAXNO = this.transferEditForm.get('SELLTAXNO').value;
      this.prdtUpdatePODTO.SELLTAXRATE = this.transferEditForm.get('SELLTAXRATE').value;

      this.prdtUpdatePODTO.PAYABLE = this.transferEditForm.get('PAYABLE').value;
      this.prdtUpdatePODTO.FC_CIDExRate = this.transferEditForm.get('CURExRate').value;
      this.prdtUpdatePODTO.FC_SELLTAXAMT = this.transferEditForm.get('FC_SELLTAXAMT').value;
      this.prdtUpdatePODTO.CostingCustomerNetSellPrice = this.transferEditForm.get('CostingCustomerNetSellPrice').value;
      this.prdtUpdatePODTO.CostingCustomerServiceFee = this.transferEditForm.get('CostingCustomerServiceFee').value;
      this.prdtUpdatePODTO.CostingCustomerTaxAmount = this.transferEditForm.get('CostingCustomerTaxAmount').value;
      //this.apiPO.formData=
      this.apiPO.formData = this.prdtUpdatePODTO;
      this.subscription.add(this.apiPO.SaveProductAfterPO()
        .subscribe(res => {
          if (res.IsSuccess === true && res.Message === 'Success') {
            this.spinner.hide();
            this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
              this.showMsg = translations['Messages.saved_success'];
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }));

            this.router.navigate(['/PenAir/Product/TransfersDetail', this.orderno, this.guid]);
          } else {
            this.spinner.hide();
            this.toastr.warning(res.ResponseData.ErrorMessage, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          }
        }, (err) => {
          this.spinner.hide();
          console.log(err);
          this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
            this.showMsg = translations['Messages.some_error_in_save'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
        }
        ));

    }


    else {



      if (this.guid != null) {
        this.spinner.show();
        if (this.CompanyCardValidation() === true) {
          this.subscription.add(this.api.UpdateTransfersDetails(this.transferEditForm.value)
            .subscribe(res => {
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  this.spinner.hide();
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));

                  this.router.navigate(['/PenAir/Product/TransfersDetail', this.orderno, this.guid + '/']);
                } else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
              else {
                this.spinner.hide();
              }
            }, (err) => {
              this.spinner.hide();
              console.log(err);
              this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                this.showMsg = translations['Messages.some_error_in_save'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
            }
            ));
        } else {
          this.spinner.hide();
        }
      } else {
        this.spinner.show();
        if (this.CompanyCardValidation() === true) {
          this.subscription.add(this.api.AddTransferDetails(this.transferEditForm.value)
            .subscribe(res => {
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  this.spinner.hide();
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));
                  this.common.isfinalsaved = false;
                  sessionStorage.setItem('pagevalue', '0');
                  var folderQuotes = sessionStorage.getItem('folderQuotes');
                  if (folderQuotes) {
                    this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno]);
                  } else {
                    this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno]);
                  }
                } else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
              else {
                this.spinner.hide();
              }
            }, (err) => {
              this.spinner.hide();
              console.log(err);
              this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                this.showMsg = translations['Messages.some_error_in_save'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
            }
            ));
        } else {
          this.spinner.hide();
        }
      }
    }
  }

  onCancel() {
    if (this.guid !== '' && this.guid != null) {
      this.router.navigate(['/PenAir/Product/TransfersDetail', this.orderno, this.guid]);//By Caseid-12753
    } else {
      var folderQuotes = sessionStorage.getItem('folderQuotes');
      if (folderQuotes) {
        this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno]);
      } else {
        this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno]);
      }
    }
  }
  onReset() {
    this.submitted = false;
    this.transferEditForm.reset();
  }
  private buyExRate: number;
  private fCBuy: number;
  private TAXRATE: number;
  private FCSellTaxAmt: number;
  private HCSellTaxAmt: number;
  private hcBuy: number = 0;
  private amount: number;
  private balanceDueAmount: number;
  private defaultDepositPer: number;
  CalculateForm() {
    if (this.transferEditForm.get('AMTLC').value == null) {
      this.transferEditForm.controls.AMTLC.setValue(0);
      return

    }
    this.buyExRate = (this.transferEditForm.get('CURExRate').value === '' || this.transferEditForm.get('CURExRate').value === 0) ? 1 : this.transferEditForm.get('CURExRate').value;
    if ((this.transferEditForm.get('AMTLC').value != null) && this.transferEditForm.get('AMTLC').value !== 'undefined') {
      this.fCBuy = this.transferEditForm.controls.AMTLC.value;
      this.transferEditForm.controls.FCBuy.setValue(parseFloat((this.fCBuy).toFixed(2)));
      this.transferEditForm.controls.PAYABLE.setValue(parseFloat((this.fCBuy * this.buyExRate).toFixed(2)));
      this.transferEditForm.controls.AMTHC.setValue(parseFloat((this.fCBuy * this.buyExRate).toFixed(2)));
    } else {
      this.transferEditForm.controls.PAYABLE.setValue(parseFloat((this.transferEditForm.get('FCBuy').value * this.buyExRate).toFixed(2)));
    }
    this.hcBuy = this.transferEditForm.get('FCBuy').value * this.buyExRate;
    this.transferEditForm.controls.HCSell.setValue(parseFloat((this.transferEditForm.get('FCSell').value * this.exRate).toFixed(2)));
    this.transferEditForm.controls.HCCommAmt.setValue(parseFloat((this.transferEditForm.get('HCSell').value - this.transferEditForm.get('PAYABLE').value).toFixed(2)));
    this.transferEditForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.transferEditForm.get('FC_SELLTAXAMT').value * this.exRate).toFixed(2)));
    this.TAXRATE = this.transferEditForm.get('SELLTAXRATE').value;
    if (this.TAXRATE === 0) {
      this.transferEditForm.controls.FC_SELLTAXAMT.setValue(parseFloat((0).toFixed(2)));
      this.transferEditForm.controls.HC_SELLTAXAMT.setValue(parseFloat((0).toFixed(2)));
    } else {
      this.FCSellTaxAmt = (this.TAXRATE * this.transferEditForm.get('FCSell').value) / 100; // parseFloat(FCSell) * parseFloat(SellVat)/100;
      this.HCSellTaxAmt = (this.FCSellTaxAmt) * (this.exRate);
      this.transferEditForm.controls.FC_SELLTAXAMT.setValue(parseFloat((this.FCSellTaxAmt).toFixed(2)));
      this.transferEditForm.controls.HC_SELLTAXAMT.setValue(parseFloat((this.HCSellTaxAmt).toFixed(2)));
    }
    this.amount = (this.transferEditForm.get('DEFDEP').value === '' || this.transferEditForm.get('DEFDEP').value === 0) ? 0 : this.transferEditForm.get('DEFDEP').value;
    this.defaultDepositPer = (this.transferEditForm.get('DEFDEPPER').value === '' || this.transferEditForm.get('DEFDEPPER').value === 0) ? 0 : this.transferEditForm.get('DEFDEPPER').value;
    if (this.defaultDepositPer !== 0 && this.defaultDepositPer !== 0.00) {
      this.amount = (this.hcBuy * this.defaultDepositPer) / 100;
    }
    this.balanceDueAmount = this.hcBuy - this.amount;
    this.transferEditForm.controls.DEFDEP.setValue(parseFloat((this.amount).toFixed(2)));
    this.transferEditForm.controls.BalanceDueAmount.setValue(parseFloat((this.balanceDueAmount).toFixed(2)));
    // Corperate Info
    this.transferEditForm.controls.ActualSaving.setValue(parseFloat((this.transferEditForm.get('PAYABLE').value * -1).toFixed(2)));
  }
  getFolderValues() {
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    this.exRate = new Number(this.folderExrate);
    this.projNo = this.apiShared.projNo;
  }
  onChanges(): void {
    if (this.transferEditForm.controls.SUPPCODE.value !== '') {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Code === this.transferEditForm.controls.SUPPCODE.value);
      this.supplierSelected = this.supplierSelectedItem.Name;
    }
  }
  changefn() {
  }

  FormChange() {
    if (this.transferEditForm.controls.LOYALTYPOINTSMEMBNO.value !== '' && this.transferEditForm.controls.LOYALTYPOINTSMEMBNO.value != null) {

      this.disableTextbox = false;
    } else {
      this.disableTextbox = true;
    }
  }
  public SupplierValueChange(value: any): void {
    //  alert(value);
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name === value);

    }
    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860

      this.SupplierDefaultvalue(this.supplierSelectedItem.Code);
      this.transferEditForm.controls.SUPPCODE.setValue(this.supplierSelectedItem.Code);
      this.transferEditForm.controls.SUPPNAME.setValue(this.supplierSelectedItem.ActualName);
      this.transferEditForm.controls.PROVIDER.setValue(this.supplierSelectedItem.ActualName);
      this.apiShared.BALDUETO = this.supplierSelectedItem.BALDUETO;
      this.transferEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID);
      this.payterm.getBalanceDuedate();
      this.TaxLookup(this.supplierSelectedItem.CONTID);
    } else {
      this.supplierSelected = '';
      this.transferEditForm.controls.SUPPCODE.setValue('');
      this.transferEditForm.controls.SUPPNAME.setValue('');
      this.transferEditForm.controls.PROVIDER.setValue('');
    }
  }
  TaxLookup(country) {
    this.fchcdetails.getInputTaxLookup(country)
  }
  SupplierDefaultvalue(suppcode) {
    this.subscription.add(this.supplier.GetDefaultValue(suppcode)
      .subscribe(data => {
        if (data !== '' && data !== null && data !== undefined) {
          this.transferEditForm.controls.PAYMENTID.setValue(data.PAYTERMID);
          this.transferEditForm.controls.PMTID.setValue(data.PAYMTDID);
          this.resetChildForm();
        }
      }));
  }
  resetChildForm() {
    this.resetFormSubject.next(true);
  }
  public open() {
    this.opened = true;
  }
  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
    this.openSearch = false;
  }


  public BookedByChange(value: any): void {
    console.log('selectionChange', value);
    if (value != null && value !== undefined) {
      this.transferEditForm.controls.BOOKEDBY.setValue(value.Name);
    } else {
      this.transferEditForm.controls.BOOKEDBY.setValue('');
    }
  }
  public getCurrencyExRate() {
    this.bookingDate = (this.transferEditForm.controls.BookingDate.value == null || this.transferEditForm.controls.BookingDate.value == "" ? "01-Jan-1900" : this.transferEditForm.controls.BookingDate.value);//Case id-337


    if (Boolean(this.common.ValidDateRange(this.bookingDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidBookingDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidBookingDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (this.transferEditForm.controls.CURCODE.value != '' && this.transferEditForm.controls.CURCODE.value != undefined)

      this.getExchangeRate(this.transferEditForm.controls.CURCODE.value, this.bookingDate);
  }
  getExchangeRate(currency, BookingDate) {
    this.savebutton = false;
    BookingDate = this.common.transformDate(new Date(BookingDate));

    console.log('Crdate=' + BookingDate);
    this.subscription.add(this.apiExRate.getExchangeRate(currency, BookingDate)
      .subscribe(data => {
        this.currencyExrate = data;
        this.transferEditForm.controls.CURExRate.setValue(this.currencyExrate.ResponseData.EXRATE);
        this.savebutton = true;
      }));
  }
  public CurrencyValueChange(value: any): void {
    console.log('selectionChange', value);
    if (value != null && value !== undefined) {
      this.transferEditForm.controls.CURCODE.setValue(value.UsrCode);
    } else {
      this.transferEditForm.controls.CURCODE.setValue('');
    }
  }
  FormLoyalityPointsChange() {
    if (this.transferEditForm.controls.LOYALTYPOINTSMEMBNO.value !== ' ' && this.transferEditForm.controls.LOYALTYPOINTSMEMBNO.value != null) {
      this.transferEditForm.get('LOYALTYPOINTS').enable();
      this.transferEditForm.get('LOYALTYPOINTSAMT').enable();
      // this.disableTextbox =  false;
    } else {
      this.transferEditForm.controls.LOYALTYPOINTS.setValue(0);
      this.transferEditForm.controls.LOYALTYPOINTSAMT.setValue(0);
      this.transferEditForm.get('LOYALTYPOINTS').disable();
      this.transferEditForm.get('LOYALTYPOINTSAMT').disable();
    }
  }


  CompanyCardValidation() {
    if (this.transferEditForm.get('PAYMODE').value === 'Company card') {
      if (this.transferEditForm.get('PDNO').value !== ' '
        && this.transferEditForm.get('PDNO').value != null
        && this.transferEditForm.get('PDFCURID').value !== ' '
        && this.transferEditForm.get('PDFCURID').value != null
        && this.transferEditForm.get('PDFCAMT').value !== ' '
        && this.transferEditForm.get('PDFCAMT').value != null
        && this.transferEditForm.get('PDFCAMT').value !== 0
        && this.transferEditForm.get('PDTYPENO').value != null
        && this.transferEditForm.get('PDTYPENO').value !== ' '
        && this.transferEditForm.get('PDAMOUNT').value != null
        && this.transferEditForm.get('PDAMOUNT').value != null
        && this.transferEditForm.get('PDAMOUNT').value !== 0
        && this.transferEditForm.get('PDFCAMT').value != null
        && this.transferEditForm.get('PDFCAMT').value != null
        && this.transferEditForm.get('PDFCAMT').value !== 0) {
        return true;
      } else {
        this.common.showFlash1('Messages.Companycard_validation', 'bg-danger text-white', 2000);
        return false;
      }
    } else {
      return true;
    }
  }
  isSticky: boolean = false;
  @HostListener('window:scroll')
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ConfirmMessage() {
    if (this.creditLimiFlag == false) {
      this.creditLimitMsg = false;
      this.TransfersSaveandUpdate();
    } else {
      this.creditLimitMsg = false;
    }
  }
}
