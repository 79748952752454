import { Injectable } from '@angular/core';
import { WETUDTO,  WeTuTransferDTO } from './we-tu-transfer-dto.model';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/env.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WeTuTransferService {
  WeTuparam:WeTuTransferDTO;

  apiUrl:string;
  constructor(private http: HttpClient,private env:EnvService) { 
    this.apiUrl = env.baseUrl;
  }

  GetWeTuTransferResponse(): Observable<any> {
    var body = {
      ...this.WeTuparam,       
    };    
    return this.http.post<WeTuTransferDTO>(this.apiUrl+'GetWeTuTransferResponse', body); 
  }

  GetWETU_BookingListURL(): Observable<any> {
    var body = {
             
    };    
    return this.http.post<WETUDTO>(this.apiUrl+'GetWETU_BookingListURL', body); 
  }
}
