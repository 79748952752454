import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { TransfersDetail } from '../Models/transfers-detail'
import { Observable, of,throwError  } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class TransfersService {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) { 
    this.apiUrl=env.baseUrl;
  }


  //Supplierss:Object
  
    //getSuppliers (): Observable<HotelDetail[]> {   
      
      ////return this.http.get<HotelDetail[]>(apiUrl)
       // .pipe(
      //   tap(heroes => console.log('fetched Suppliers')),
       // catchError(this.commonservice.handleError('getSuppliers', []))
        //);
    //}
    
    private newMethod(data: Object) {
      alert(data);
    }
  
    getTransfers(folderNo: number,GuidItinerary:string ,isViewForm:boolean): Observable<TransfersDetail> {
      const url = `${this.apiUrl}`+'GetTransferDetailsById'+`/${folderNo}/${GuidItinerary}/${isViewForm}`;
      return this.http.get<TransfersDetail>(url).pipe(
        tap(_ => console.log(`fetched Transfers id=${folderNo}`)),
        catchError(this.commonservice.handleError<TransfersDetail>(`getTransfers id=${folderNo}`))
      );
    }
    settranferdetaildate(transfersdetail: any) {
      if(transfersdetail.DEPDATE===null || transfersdetail.DEPDATE==='')
      transfersdetail.DEPDATE=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
    transfersdetail.DEPDATE=this.commonservice.ConvertToLocaleDate(transfersdetail.DEPDATE);

    if(transfersdetail.BookingDate===null || transfersdetail.BookingDate==='')
    transfersdetail.BookingDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
    transfersdetail.BookingDate=this.commonservice.ConvertToLocaleDate(transfersdetail.BookingDate);

    if(transfersdetail.DepositDueDate===null || transfersdetail.DepositDueDate==='')
    transfersdetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
    transfersdetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(transfersdetail.DepositDueDate);

    // if(transfersdetail.BalanceDueDate===null || transfersdetail.BalanceDueDate==='')
    // transfersdetail.BalanceDueDate=this.commonservice.ConvertToLocaleDatenew Date("01-Jan-1900"));
    // else
    // transfersdetail.BalanceDueDate=this.commonservice.ConvertToLocaleDate(transfersdetail.BalanceDueDate);
    transfersdetail.BalanceDueDate=transfersdetail.DEPDATE;

      if(transfersdetail.LOYALTYPOINTSAMT==null)
      transfersdetail.LOYALTYPOINTSAMT=0;
      if(transfersdetail.LOYALTYPOINTS==null)
      transfersdetail.LOYALTYPOINTS=0;
    }
    AddTransferDetails (transfersdetail: any): Observable<any> {
      
     this.settranferdetaildate(transfersdetail);
      return this.http.post<any>(this.apiUrl+'AddTransferDetails', transfersdetail, httpOptions).pipe(
        tap((TransfersRes: any) => console.log(`added Transfers`)),
        catchError(this.commonservice.handleError<any>('AddTransferDetails'))
      );
    }
    
    UpdateTransfersDetails ( transfersdetail: any): Observable<any> {
      this.settranferdetaildate(transfersdetail);
      // if(transfersdetail.DEPDATE==null)
      // transfersdetail.DEPDATE="01-Jan-1900";
      // if(transfersdetail.BookingDate==null)
      // transfersdetail.BookingDate="01-Jan-1900";
      // if(transfersdetail.DepositDueDate==null)
      // transfersdetail.DepositDueDate="01-Jan-1900";
      // if(transfersdetail.BalanceDueDate==null)
      // transfersdetail.BalanceDueDate="01-Jan-1900";
      const url = `${this.apiUrl}`+'UpdateTransfersDetails';///${id}
      return this.http.post(url, transfersdetail, httpOptions).pipe(
        tap(_ => console.log(`updated Transfers`)),
        catchError(this.commonservice.handleError<any>('UpdateTransfersDetails'))
      );
    }
    deleteTransfers ( folderNo: number,GuidItinerary:string): Observable<any> {   

      const url = `${this.apiUrl}`+'DeleteTransferDetails'+`/${folderNo}/${GuidItinerary}`;
     
      return this.http.post(url,  httpOptions).pipe(
        tap(_ => console.log(`delete Transfers`)),
        catchError(this.commonservice.handleError<any>('deleteTransfers'))
      );
    }
}
