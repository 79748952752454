import { Component, OnInit,Input } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { CommonserviceService } from '../../Services/commonservice.service';
import { FolderpriceService } from '../../Services/folderprice.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { ShareDataService } from '../../Services/share-data.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-payment-terms',
  templateUrl: './payment-terms.component.html',
  styleUrls: ['./payment-terms.component.scss']
})
export class PaymentTermsComponent implements OnInit {
  @Input() itineraryDetails;
  SupplierRef:any="";
  CardNo:any='';
  CID:any='';
  CardSecurityCode:any='';
  MerchantLogUniqueId:any='';
  CardExpiryDate:any='';
  suppcode:any=0;
  PDQ:any=0;
  private subscription: Subscription = new Subscription();
  constructor(private router: Router,public intl: IntlService,public lookupApi: LookUpDetailsService,public common:CommonserviceService, private spinner: NgxSpinnerService, public objFolderPrice: FolderpriceService,private apiShared: ShareDataService) {}
  visible=false;
  isShowncase: any;
  DateOfTravel:any;
  DisableFC_Tot_Buy:boolean=true;
  isWexEnabled:boolean=false;
  public pocreated:boolean=false;
  folderStatus:any;
  ngOnInit() {
    debugger;
    this.folderStatus=this.apiShared.FolderStatus;
    this.subscription.add(this.lookupApi.GetAllLookup('ISWEX').subscribe(
      (data) => {
 
         debugger;
if(data["ResponseData"] != null&& data["ResponseData"].length>0)
{
  this.isWexEnabled=false;
}
else
{
  this.isWexEnabled=true;
}
     }
    ));
    this.loadPaymethod();
    debugger;
    if(this.itineraryDetails.ResponseData.FC_Tot_Buy==undefined)
    {
      this.itineraryDetails.ResponseData.FC_Tot_Buy=this.itineraryDetails.ResponseData.FCBuy;
    }
    if((this.itineraryDetails.ResponseData.PONO!=null &&this.itineraryDetails.ResponseData.PONO>0)&&  this.folderStatus == "Invoiced")
    {
      this.pocreated=true;
    }
    else{
      this.pocreated=false;
    }
   
    if (this.itineraryDetails.ResponseData.OthersType == "Default" ||this.folderStatus == "Invoiced" ||this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund")//By Caseid-12780
    {
      this.pocreated=true;
    }
    if(this.itineraryDetails.ResponseData.ITYPE ==='Ticket' || this.itineraryDetails.ResponseData.ITYPE ==='Others'
    ||this.itineraryDetails.ResponseData.ITYPE ==='Hotel') {
      this.visible=true;
    }
    else{
      this.visible=false;
    }
  }
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;

  public onChange(event: any): void {
      this.expandMode = parseInt(event.target.value, 10);
  }

  public onHeightChange(event: any): void {
      this.height = parseInt(event.target.value, 10);
  }
  isShown: boolean = true ; // hidden by default
  isShownCmp: boolean = false ;
  isShownCust: boolean = false ;
  isShownWEX: boolean = false ;
  ShownCarddetails: boolean = false ;
public toggleShow() {

this.isShown = true;
this.isShownCmp=false;
this.isShownCust =false;
this.isShownWEX=false;

}
public toggleShowCmp() {

  this.isShownCmp =true;
  this.isShown =false;
  this.isShownCust=false;
  this.isShownWEX=false;
  }
  public toggleShowCust() {

    this.isShownCust =true;
    this.isShownCmp=false;
    this.isShown =false;
    this.isShownWEX=false;
    
    }

    toggleShowWexPay()
    {
      this.isShownCmp =false;
      this.isShown =false;
      this.isShownCust=false;
      this.isShownWEX=true;
    }
    ShowCardDetails()
    {
      debugger;
      this.spinner.show();
this.ShownCarddetails=true;
this.subscription.add(this.objFolderPrice.GetItineraryDetailsForWexPayment(this.itineraryDetails.ResponseData.FOLDERNO,this.itineraryDetails.ResponseData.GUIDItinerary,this.itineraryDetails.ResponseData.ITYPE,this.itineraryDetails.ResponseData.SLNO).subscribe(
  (data) => {
    if(data.ResponseData!=null && data.ResponseData.datampdel!=null&& data.ResponseData.datampdel.ResponseMessage!=null&& data.ResponseData.datampdel.ResponseMessage!='')
    {
      this.ShownCarddetails=false;
      this.spinner.hide();
      // this.CID=data.ResponseData.CID;
      // this.suppcode=data.ResponseData.SUPPPRTYCODE;
      // this.PDQ=data.ResponseData.PDQ;
      // this.CardNo=data.ResponseData.datampdel.CardNumber
      // this.CardSecurityCode=data.ResponseData.datampdel.CardSecurityCode;
      // this.MerchantLogUniqueId=data.ResponseData.datampdel.MerchantLogUniqueId;
      // if(data.ResponseData.datampdel.ExpirationDate!=null)
      // this.CardExpiryDate=this.common.ConvertToLocaleDate(data.ResponseData.datampdel.ExpirationDate);
    
      // this.DateOfTravel= data.ResponseData.datampdel.DateOfTravel;
      this.common.showWARNINGtoastrmsg(data.ResponseData.datampdel.ResponseMessage, 2000, true, false);
    }else  if(data.ResponseData!=null && data.ResponseData.datampdel!=null&& data.ResponseData.datampdel.ResponseMessage!=null&& data.ResponseData.datampdel.ResponseMessage=='')
    {
      this.spinner.hide();
      this.CID=data.ResponseData.CID;
      this.suppcode=data.ResponseData.SUPPPRTYCODE;
      this.PDQ=data.ResponseData.PDQ;
      this.CardNo=data.ResponseData.datampdel.CardNumber
      this.CardSecurityCode=data.ResponseData.datampdel.CardSecurityCode;
      this.MerchantLogUniqueId=data.ResponseData.datampdel.MerchantLogUniqueId;
      if(data.ResponseData.datampdel.ExpirationDate!=null)
      this.CardExpiryDate=this.common.ConvertToLocaleDate(data.ResponseData.datampdel.ExpirationDate);
    
      this.DateOfTravel= data.ResponseData.datampdel.DateOfTravel;
    }
    else{
      this.CID=data.ResponseData.CID;
    }
    this.spinner.hide();
    //this.r=data.ResponseData.

  //  this.subscription.add(this.objFolderPrice.GetWexPaymentSettingsDataByID(1).subscribe(
  //   (data) => {
  //    // this.CardNo=data.ResponseData.
  //   }
  // ))
  }
))

    }
    onCreatePO(id:any)
    {
      this.spinner.show();
      if(this.SupplierRef==null||this.SupplierRef=='')
      {
        this.spinner.hide();
        this.common.showWARNINGtoastrmsg("Messages.SupplierRefMandatory", 2000, true, false); 
        return;
      }
      debugger;
     
      
        this.objFolderPrice.body=new Object;
        this.objFolderPrice.body.GUIDItinerary=this.itineraryDetails.ResponseData.GUIDItinerary;
        this.objFolderPrice.body.TYPE=this.itineraryDetails.ResponseData.ITYPE;
        this.objFolderPrice.body.SLNO=this.itineraryDetails.ResponseData.SLNO;
        this.objFolderPrice.body.FOLDERNO=this.itineraryDetails.ResponseData.FOLDERNO;
        //this.objFolderPrice.body.PDQNO=this.itineraryDetails.ResponseData.PDQNO;
        this.objFolderPrice.body.Amount=this.itineraryDetails.ResponseData.FC_Tot_Buy;
        this.objFolderPrice.body.MerchantLogUniqueId=this.MerchantLogUniqueId;
        this.objFolderPrice.body.SupplierRef=this.SupplierRef;
        this.objFolderPrice.body.supplierCode_bigint= this.suppcode;
        this.objFolderPrice.body.pTravelDateFrom_smalldatetime=this.common.ConvertToLocaleDate(this.DateOfTravel);
        this.objFolderPrice.body.CID=this.CID;
        if(id==1)
        {
          this.objFolderPrice.body.PostType = "Save";
        }
        this.DisableFC_Tot_Buy=true;
        this.objFolderPrice.body.CuurentDate=this.common.ConvertToLocaleDate(new Date());
        this.subscription.add(this.objFolderPrice.AutomaticPOCreationAndSave().subscribe(
          (data) => {
            if(data.ResponseData!=null && data.ResponseData!=null&& data.ResponseData.ResponseMessage!=null&& data.ResponseData.ResponseMessage!='' &&data.ResponseData.MSGID==4 )
            {
              this.spinner.hide();
              this.common.showWARNINGtoastrmsg(data.ResponseData.ResponseMessage, 5000, true, false);
            }
            else if(data.ResponseData!=null && data.ResponseData!=null&& data.ResponseData.ResponseMessage!=null&& data.ResponseData.ResponseMessage!='' &&(data.ResponseData.MSGID==1||data.ResponseData.MSGID==2||data.ResponseData.MSGID==3) )
            {
              this.spinner.hide();
              if(data.ResponseData.MSGID==1)
              {
                this.common.showSUCCESStoastrmsg("Messages.saved_success", 5000, true, false);

              }
              else
              {
                this.common.showSUCCESStoastrmsg(data.ResponseData.ResponseMessage, 5000, true, false);
                var orderno = sessionStorage.getItem("orderNo");
            this.router.navigate(["PenAir/Folder/FolderSummary", orderno]);
              }
            }
            
            this.spinner.hide();
            //this.r=data.ResponseData.
        
      
          }
        ))
      
    
    }
    onCancelPO()
    {
      this.ShownCarddetails=false;
    }
    EnableFC_Tot_Buy()
    {
      debugger;
      this.DisableFC_Tot_Buy=false;
     
    }
    public rdCheckedCr=false;
    public rdCheckedCust=false;
    public rdCheckedCmp=false;
    public rdCheckedWex=false;
    public loadPaymethod()
{
  if(this.itineraryDetails.ResponseData.ITYPE!="Ticket")
  {
   
  if(this.itineraryDetails.ResponseData.PAYMODE!=null)
  {
    
   
 if(this.itineraryDetails.ResponseData.PAYMODE=="Company Card")
 {
  this.rdCheckedCmp=true;
  this.toggleShowCmp();
 }
 else if(this.itineraryDetails.ResponseData.PAYMODE=="Customer Card")
 {
  this.rdCheckedCust=true;
this.toggleShowCust();
 }
 else{
  this.rdCheckedCr=true;
  this.toggleShow();
 }
}
else{
  this.rdCheckedCr=true;
  this.toggleShow();
}
  }
  else
  {
    if(this.itineraryDetails.ResponseData.COMONLY!=null)
{
 
 if(this.itineraryDetails.ResponseData.COMONLY=="W")
 {
   this.rdCheckedCmp=true;
  this.toggleShowCmp();
 }
 else if(this.itineraryDetails.ResponseData.COMONLY=="C")
 {
  this.rdCheckedCust=true;
this.toggleShowCust();
 }
 else{
  this.rdCheckedCr=true;
  this.toggleShow();
 }
}
else{
  this.rdCheckedCr=true;
  this.toggleShow();
}

}
}

  public onPanelChange(event: any): void { console.log('stateChange: ', event); }
}
