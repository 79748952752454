export class DelinkDto {
    
     VRTYPE:any;
    VRNO:any;
    YEARID:any;
}
export class DelinkReceiptGetDetailsDTO {

    PROJNO :any;
ASSIGN :any;
    Status :any;
    AGAINST :any;
     AVRTYPE :any;
    AYEARID :any;
     PRTYCODE :any;
    AMOUNT :any;
   VRNO :any;
   VRTYPE :any;
  YEARID :any;
    FC_AMOUNT :any;
    USERID :any;
CURRENTDATE :any;
    StatusMessage :any;
    STATUSNAME :any;
    VRNAME:any;
     Customer :any;
     slno:any;

      commpaid:any;
     commpaidbrid:any;
}