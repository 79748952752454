export class PassengerDetails {
  IsSuccess: boolean;
  Message: string;
  ResponseData: {
    FIRSTNAME: string;
    LASTNAME: string;
    TITLE: string;
    TYPE: string;
    EMail: string;
    TELEPHONE: string;
    DOB: Date;
    PROJNO: number;
  }


}
export class PassengerDetailsn {
  FIRSTNAME: string;
  LASTNAME: string;
  TITLE: string = '';
  TYPE: string = "Adult";
  EMail: string;
  TELEPHONE: string;
  DOB: Date;
  PROJNO: number;
}

export const typeList1 = [
  {
    'TypeID': 1,
    'TypeName': 'Adult',

  },
  {
    'TypeID': 2,
    'TypeName': 'Youth',

  },
  {
    'TypeID': 3,
    'TypeName': 'Junior',

  },
  {
    'TypeID': 4,
    'TypeName': 'Child',

  },
  {
    'TypeID': 5,
    'TypeName': 'Infant',

  },

];

export const titleList1 = [
  {
    'TitleID': 1,
    'TitleName': 'MR',

  },
  {
    'TitleID': 2,
    'TitleName': 'MRS',

  },
  {
    'TitleID': 3,
    'TitleName': 'MS',

  },
  {
    'TitleID': 4,
    'TitleName': 'PROF',

  },
  {
    'TitleID': 5,
    'TitleName': 'DOCTOR',

  },
  {
    'TitleID': 6,
    'TitleName': 'MSTR',

  },
  {
    'TitleID': 7,
    'TitleName': 'MISS',

  },
  {
    'TitleID': 8,
    'TitleName': 'LORD',

  },
  {
    'TitleID': 9,
    'TitleName': 'LADY',

  },
  {
    'TitleID': 10,
    'TitleName': 'MISSCHD',

  }, {
    'TitleID': 11,
    'TitleName': 'REV',

  }, {
    'TitleID': 12,
    'TitleName': 'HON',

  }, {
    'TitleID': 13,
    'TitleName': 'BSS',

  },


];