export class UnlockDocumentListDTO {
     SLNO :number
   DOCTYPE :string
     DOCNO :string
     FOLDERNO :string
     USERNAME :string
     DATE :string
     TIME :any
    DURATION :string
   YEARID :string
   EntrySource:string

   list:UnlockDocumentListDTO[]
}
