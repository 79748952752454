import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from 'src/env.service';
import { MessageAnalysisReport } from '../Models/message-analysis-report.model';

@Injectable({
  providedIn: 'root'
})
export class MessageAnalysisReportService {
  public MessageAnalysisReportDTO: MessageAnalysisReport;
  apiUrl: string;
  constructor(private env: EnvService, private http: HttpClient) {
    this.apiUrl = env.baseUrl;
  }
  GetMessageAnalysisReport(MessageAnalysisRptDTO: any): Observable<any> {
    debugger;

    return this.http.post<any>(this.apiUrl + 'GetMessageAnalysisReport', MessageAnalysisRptDTO);

  }
}
