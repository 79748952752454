import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LookUpDetails } from '../../Services/look-up-details';
import { PublicLayoutComponent } from '../../public-layout/public-layout.component';
import { AlertserviceService } from '../../Services/alertservice.service';
import { CommonserviceService } from '../../Services/commonservice.service';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { ShareDataService } from '../../Services/share-data.service';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss']
})
export class RemindersComponent implements OnInit {
  @Input() alertID: any;
  @Output() closeReminder: EventEmitter<string> = new EventEmitter();
  private subscription: Subscription = new Subscription();
  reminderEditForm: FormGroup;
  private orderno = this.route.snapshot.params['orderNo'];
  ActionList: Array<LookUpDetails["ResponseData"]> = [];
  public ActionSelectedItem: LookUpDetails["ResponseData"];
  UserList: Array<LookUpDetails["ResponseData"]> = [];
  public AssignedToSelectedItem: LookUpDetails["ResponseData"];
  public StatusList: Array<string> = ["Open with Alert", "Open", "Closed"];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  submitted = false;
  showMsg = '';
  constructor(private formBuilder: FormBuilder, private lookupApi: LookUpDetailsService, private toastr: ToastrService, private route: ActivatedRoute,
    private apiShared: ShareDataService, private router: Router, private translateapi: TranslateService, private alertApi: AlertserviceService,
    public common: CommonserviceService, private spinner: NgxSpinnerService, private layout: PublicLayoutComponent) { }

  ngOnInit() {
    var Time = moment().format("HHmm");
    this.reminderEditForm = this.formBuilder.group({
      AlertID: 0,
      AlertDate: [new Date(), Validators.required],
      AlertTime: [Time, [Validators.required, Validators.pattern('^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$')]],
      ActionId: ['', Validators.required],
      FolderNo: this.orderno != undefined ? this.orderno : '',//['',Validators.required],
      ActionDesc: '',//['',Validators.required],
      AssignedTo: ['', Validators.required],
      Status: ['Open with Alert'],
    })

    this.subscription.add(this.lookupApi.GetAllLookup('ACTION').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.ActionList = data["ResponseData"];
          if (this.reminderEditForm.controls.ActionId.value != "") {
            this.ActionSelectedItem = this.ActionList.find(item => item.UsrCode == this.reminderEditForm.controls.ActionId.value);
          }
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.UserList = data["ResponseData"];
          if (this.reminderEditForm.controls.AssignedTo.value != "") {
            this.AssignedToSelectedItem = this.UserList.find(item => item.UsrCode == this.reminderEditForm.controls.AssignedTo.value);
          }
        }
      }
    ));
    if (this.alertID != undefined && this.alertID.ID > 0) {
      this.GetReminderByID(this.alertID);
    }
  }
  GetReminderByID(alertID) {
    this.subscription.add(this.alertApi.GetReminderByID(alertID)
      .subscribe(data => {
        if (data != null && data != undefined) {
          this.reminderEditForm.setValue({
            AlertID: data.ResponseData.AlertID,
            AlertDate: data.ResponseData.AlertDate != null ? (data.ResponseData.AlertDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.AlertDate)) : null,
            AlertTime: data.ResponseData.AlertTime,
            ActionId: data.ResponseData.ActionId,
            FolderNo: data.ResponseData.FolderNo,
            ActionDesc: data.ResponseData.ActionDesc,
            AssignedTo: data.ResponseData.AssignedTo,
            Status: data.ResponseData.Status,
          });
          if (data.ResponseData.ActionId != "") {
            this.ActionSelectedItem = this.ActionList.find(item => item.UsrCode == data.ResponseData.ActionId);
          }
          if (data.ResponseData.AssignedTo != "") {
            this.AssignedToSelectedItem = this.UserList.find(item => item.UsrCode == data.ResponseData.AssignedTo);
          }
          this.spinner.hide();
          //this.isLoadingResults = false;
        }
      }));
  }
  public ActionChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.ActionSelectedItem = this.ActionList.find(item => item.UsrCode == value.UsrCode);
      if (this.ActionSelectedItem != null) {
        this.reminderEditForm.controls.ActionId.setValue(this.ActionSelectedItem.Code);
      }
    } else {
      this.reminderEditForm.controls.ActionId.setValue("");
    }
  }
  public AssignedToChange(value: any): void {
    if (value != null && value != undefined) {
      this.AssignedToSelectedItem = this.UserList.find(item => item.UsrCode == value.UsrCode);
      if (this.AssignedToSelectedItem != null) {
        this.reminderEditForm.controls.AssignedTo.setValue(this.AssignedToSelectedItem.Code);
        //this.reminderEditForm.controls.AssignedTo.setValue( this.AssignedToSelectedItem.Name);
      }
    }
    else {
      this.reminderEditForm.controls.AssignedTo.setValue("");
    }
  }
  get f() { return this.reminderEditForm.controls; }
  reminderClose() {
    this.closeReminder.emit('true');
  }
  onFormSubmit() {
    this.spinner.show();
    this.submitted = true;
    // stop here if form is invalid
    if (this.reminderEditForm.invalid) {
      this.spinner.hide();
      return;
    }
    // if(this.orderno!=undefined && this.orderno!=null)
    // {
    //   this.reminderEditForm.controls.AssignedTo.setValue(this.orderno);
    // }
    this.subscription.add(this.alertApi.setReminder(this.reminderEditForm.value)
      .subscribe(res => {
        if (res != null && res != undefined) {
          debugger;
          if (res.IsSuccess === true && res.Message === 'SUCCESS') {
            if (res.ResponseData.includes('Created') || res.ResponseData.includes('Updated')) {
              this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {

                this.spinner.hide();
                this.showMsg = translations['Messages.saved_success'];
                this.toastr.success(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
              this.spinner.hide();
              // this.alertMenu.alertReload = true;
              this.layout.translateMenutext('');
              this.closeReminder.emit('reload');
            } else {
              this.spinner.hide();

              this.showMsg = res.ResponseData
              this.toastr.warning(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
              this.spinner.hide();
            }
          }
          else {
            this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
              this.spinner.hide();
              this.showMsg = translations['Messages.some_error_in_save'];
              this.toastr.warning(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }));


          }
        }
      }, (err) => {
        this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
          this.spinner.hide();

          this.showMsg = translations['Messages.some_error_in_save'];
          this.toastr.warning(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
      }));
  }

}
