import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { CruiseDetail } from '../Models/cruise-detail'
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})


export class CruiseService {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=this.env.baseUrl;//'http://192.168.1.6/PenAirWebApiService/';// 'http://localhost:29048/';
  }
  



  private newMethod(data: Object) {
   
  }

  getCruise(folderNo: number, GuidItinerary: string ,isViewForm:boolean): Observable<CruiseDetail> {
    const url = `${this.apiUrl}` + 'GetCruiseDetailsById' + `/${folderNo}/${GuidItinerary}/${isViewForm}`;

    return this.http.get<CruiseDetail>(url).pipe(
      tap(_ => console.log(`fetched Cruise id=${folderNo}`)),
      catchError(this.commonservice.handleError<CruiseDetail>(`getCruise id=${folderNo}`))
    );
  }
  setcruisedate(CruiseDetail: any) {
// alert(CruiseDetail.DATEF)
// alert(CruiseDetail.DATET)
// alert(new Date(CruiseDetail.BookingDate))

 
if(CruiseDetail.DATEF===null || CruiseDetail.DATEF==='')
CruiseDetail.DATEF=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
else
CruiseDetail.DATEF=this.commonservice.ConvertToLocaleDate(CruiseDetail.DATEF);

// if(CruiseDetail.DATEF==null)
// CruiseDetail.DATEF=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));

if(CruiseDetail.DATET===null|| CruiseDetail.DATET==='')
CruiseDetail.DATET=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
else
CruiseDetail.DATET=this.commonservice.ConvertToLocaleDate(CruiseDetail.DATET);

if(CruiseDetail.BookingDate===null|| CruiseDetail.BookingDate==='')
CruiseDetail.BookingDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
else
CruiseDetail.BookingDate=this.commonservice.ConvertToLocaleDate(CruiseDetail.BookingDate);

if(CruiseDetail.DEPDATE===null|| CruiseDetail.DEPDATE==='')
CruiseDetail.DEPDATE=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
else
CruiseDetail.DEPDATE=this.commonservice.ConvertToLocaleDate(CruiseDetail.DEPDATE);

if(CruiseDetail.ARRDATE===null|| CruiseDetail.ARRDATE==='')
CruiseDetail.ARRDATE=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
else
CruiseDetail.ARRDATE=this.commonservice.ConvertToLocaleDate(CruiseDetail.ARRDATE);

if(CruiseDetail.DepositDueDate===null|| CruiseDetail.DepositDueDate==='')
CruiseDetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
else
CruiseDetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(CruiseDetail.DepositDueDate);

// if(CruiseDetail.BalanceDueDate===null|| CruiseDetail.BalanceDueDate==='')
// CruiseDetail.BalanceDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
// else
// CruiseDetail.BalanceDueDate=(CruiseDetail.BalanceDueDate).toLocaleDateString();

if(CruiseDetail.EmbarkationDate===null|| CruiseDetail.EmbarkationDate==='')
CruiseDetail.EmbarkationDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
else
CruiseDetail.EmbarkationDate=this.commonservice.ConvertToLocaleDate(CruiseDetail.EmbarkationDate);

if(CruiseDetail.DisembarkationDate===null|| CruiseDetail.DisembarkationDate==='')
CruiseDetail.DisembarkationDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
else
CruiseDetail.DisembarkationDate=this.commonservice.ConvertToLocaleDate(CruiseDetail.DisembarkationDate);

CruiseDetail.BalanceDueDate=CruiseDetail.DEPDATE;
    
    if(CruiseDetail.LOYALTYPOINTSAMT==null)
    CruiseDetail.LOYALTYPOINTSAMT=0;
    if(CruiseDetail.LOYALTYPOINTS==null)
    CruiseDetail.LOYALTYPOINTS=0;

  }
  addCruise(CruiseDetail: any): Observable<any> {
    this.setcruisedate(CruiseDetail);
    return this.http.post<any>(this.apiUrl + 'AddCruiseDetails', CruiseDetail, httpOptions).pipe(
      tap((CruiseRes: any) => console.log(`added Cruise w/ id=${CruiseRes.ResponseData.SUPPCODE}`)),
      catchError(this.commonservice.handleError<any>('addCruise'))
    );
  }

  updateCruise(CruiseDetail: any): Observable<any> {
    this.setcruisedate(CruiseDetail);
    const url = `${this.apiUrl}` + 'UpdateCruiseDetails';///${id}
    return this.http.post(url, CruiseDetail, httpOptions).pipe(
      tap(_ => console.log(`updated Cruise`)),
      catchError(this.commonservice.handleError<any>('updateCruise'))
    );
  }

  
  deleteCruise ( folderNo: number,GuidItinerary:string): Observable<any> {   

    const url = `${this.apiUrl}`+'DeleteCruiseDetails'+`/${folderNo}/${GuidItinerary}`;
   
    return this.http.post(url,  httpOptions).pipe(
      tap(_ => console.log(`delete Cruise`)),
      catchError(this.commonservice.handleError<any>('deleteCruise'))
    );
  }
}
