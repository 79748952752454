import { Component, OnInit, OnChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductList } from '../../Models/product-list';
import { FolderpriceService } from '../../Services/folderprice.service';
import { FolderproducttotalService } from '../../Services/folderproducttotal.service';
import { FolderProductTotal } from '../../Models/folder-product-total';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ShareDataService } from '../../Services/share-data.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { InvoiceService } from '../../Services/invoice.service';
import { Invoicedefault } from '../../Models/invoicedefault'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CommonserviceService } from '../../Services/commonservice.service';
import { FolderSummaryService } from '../../Services/folder-summary.service';
import { FolderReinvoice } from '../../Models/folder-reinvoice';
import { FolderRefund } from '../../Models/folder-refund';
import { DetailsComponent } from '../details/details.component';
import { PendoclockService } from '../../Services/pendoclock.service';
import { Pendoclock } from '../../Models/pendoclock';
import { FolderRefundreset } from '../../Models/folder-refundreset';
import { EnvService } from 'src/env.service';
import { Subscription } from 'rxjs';
import { PublicLayoutComponent } from '../../public-layout/public-layout.component';
import { FileService } from '../../Services/file.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FolderCommCalculation } from '../../Models/folder-summary';
import { IntlService } from '@progress/kendo-angular-intl';
import { EmailService } from '../../Services/email.service';
import { ReportParameterDTO } from '../../Models/common-class.model';
import { DocumentEmailDTO } from '../../Models/document-email-dto';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonVariencereportService } from '../../Services/common-variencereport.service';
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  reportHeader: any;
  fromId: string;
  bccId: string;
  public reportSubject: string = "";
  public reportViewertemp = "";
  folderInvoiceForm: FormGroup;
  submitted = false;
  deletebutton = true;
  invoicebutton = false;
  printbutton = true;
  delete_invoice: any;
  deleteinvoicebutton = false;
  public confirmMsg: any;
  public deletefolder = false;
  lockStatus: any;
  BrName: any;
  ordertype: any;
  isShownMore = false;
  public agentComm = false;
  issusecreditnote = true;
  public productGridData: Array<ProductList["ResponseData"]> = [];
  folderproductotal: FolderProductTotal;
  private orderNo = this.route.snapshot.params['orderNo'];
  public cusco = this.apiShared.CustomerCode;
  public brid = this.apiShared.BRID;
  public language = this.apiShared.LanguageSelected;
  public userid = this.apiShared.uid;
  public InvoiceDate: any;
  public skip = 0;
  public buy: number = 0.00;
  public comm: number = 0.00;
  public sell: number = 0.00;
  public totalTax: number = 0.00;
  public totalRecievable: number = 0.00;
  public alreadyPaid: number = 0.00;
  public otherCredits: number = 0.00;
  public balance: number = 0.00;
  public projno: any;
  public Total: any;
  opened = false;
  public rptopened = false;
  public invoiceopened = false;
  loaded = false;
  public offset = 50;
  public windowLeft = this.offset;
  public windowTop = this.offset;
  public width = 600;
  public height = 190;
  public reportParms: any;
  public reportFile: string = "";
  public count: any;

  public defaultReportSelected: string = "";
  public reportViewerHeader = "";
  PCIDList: Array<LookUpDetails["ResponseData"]> = [];
  public PCIDSelectedItem: LookUpDetails["ResponseData"];
  public PCIDSelected: string;

  public paymentMethodSelectedItem: LookUpDetails["ResponseData"];
  public paymentMethodList: Array<LookUpDetails["ResponseData"]> = [];
  public paymentMethodSelected: string;

  paymentTermList: Array<LookUpDetails["ResponseData"]> = [];
  public paymentTermSelectedItem: LookUpDetails["ResponseData"];
  public paymentTermSelected: string;
  refundFolderNo: any;
  folderReadOnly: any;
  folderStatus: any;
  InvDate: any;
  public showMsg: any;
  public ResponseURL: string;
  public reinvoice: FolderReinvoice;
  public refund: FolderRefund;
  public deleteinvoice: FolderReinvoice;
  public refundReset: FolderRefundreset;
  public HeaderLabel = "Create Invoice";
  ONETIMECUSTOMER = 0;
  ShowAGTCOMONINV = 0;
  refundreset = false;
  disableButton = false;
  invoiceDate: any//Case id-337
  depositDueDate: any//Case id-337
  commType = 'FConly'
  OrderTyperpt: any;
  PrintEmail: any;
  creditLimitMsg = false;
  creditLimiFlag = false;
  msg: any;
  defaultInvoice: Invoicedefault = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {
      CustomerCode: "",
      InvoiceDate: "",
      DepDueDate: "",
      OrderType: "",
      PaymentTerms: "",
      PaymentMethod: "",
      BalanceDueDate: "",
      TravelDate: "",
      ProfitCentre: "",
      Branch: "",
    }
  }
  page: any;
  // public invoiceFormatItems: Array<string> = ["PackageItinerary7", "PackageItinerary5", "InvoicewithItinerary 02", "InvoicewithItinerary 02_Signature", "CorporateInvoice02", "InvoicewithItinerary 02 Canada", "Cancellation Document", "Service Invoice"];
  // public invoiceFormatItems: Array<string> = ["Cancellation Document", "CorporateInvoice02", "InvoicewithItinerary1", "InvoicewithItinerary 02",
  //   "InvoicewithItinerary 02 Canada", "InvoicewithItinerary 02_Signature", "Itineraryonly", "PackageItinerary5",
  //   "PackageItinerary7", "Service Invoice"];


  public invoiceFormatItems: Array<string> = ["Accommodation Voucher", "Booking Confirmation", "Car Voucher",
    "Cancellation Document", "CorporateInvoice02", "Customer Refund Request", "E-Ticket", "Insurance Reclaim Document", "Hotel Voucher",
    "InvoicewithItinerary1", "InvoicewithItinerary 02", "InvoicewithItinerary 02 Canada", "InvoicewithItinerary 02_Signature", "Itineraryonly",
    "PackageItinerary5", "PackageItinerary7", "PackageItinerary8", "Service Invoice", "Tour Voucher", "Transfer Voucher"];



  pageid = 0;
  public AgentCommission: any = {
    FCAGTCOM: 0,
    FCAGTCOMSUM: 0,
    FCAGTCOMVAT: 0,
    AGTCOMVATNO: 0,
    AGTCOMVATRate: 0,
  }
  objCommCalculation: FolderCommCalculation = {
    FCTotalBUY: 0,
    PDQCHARGE: 0,
    FC_CIDExRate: 0,
    FCTotalCOMM: 0,
    FCCREDITAMT: 0,
    FCAGTCOMSUM: 0,
    FCALREADYPAID: 0,
    FCOtherCredits: 0,
    TotalBUY: 0,
    TotalCOMM: 0,
    CREDITAMT: 0,
    AGTCOMSUM: 0,
    ALREADYPAID: 0,
    OtherCredits: 0,

  }
  penDockLock: Pendoclock = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {

      LDocType: "",
      LDocNo: "",
      UID: 0,
      LDATE: "",
      LTIME: "",
      LPROJNO: "",
      LYearID: "",
      LAutoNo: "",
      Uname: "",
      Source: ""

    }
  };
  disable = false;
  emailopened: any = false;
  crRptName: any = "";
  crOpened = false;
  pdfurl: string;
  public crPdfFilePath = '';
  crUrlSafe: SafeResourceUrl;
  crFilePath: any = '';
  crEmailPath: any = '';
  emailbody: any = '';
  public rptFolderDetails = {
    orderNo: '',
    projNo: '',
    brName: '',
    folderstatus: '',
    atolattachement: false
  };
  loadBookingRpt = false;
  constructor(public common: CommonserviceService, private rptAPI: CommonVariencereportService, public sanitizer: DomSanitizer, public intl: IntlService, public email: EmailService, private spinner: NgxSpinnerService, private emailapi: FileService, private env: EnvService, private layout: PublicLayoutComponent, private menulist: DetailsComponent, private api: FolderSummaryService, public commonservice: CommonserviceService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService
    , private Productapi: FolderpriceService, private translateapi: TranslateService, private formBuilder: FormBuilder, private lookupApi: LookUpDetailsService, private apiTotal: FolderproducttotalService, private apiShared: ShareDataService
    , private flashMessage: FlashMessagesService, private invoice: InvoiceService, private apipenLock: PendoclockService, private penLock: PendoclockService) {
    this.getProductDetails(this.orderNo);
    this.pdfurl = env.baseUrl
  }

  ngOnInit() {
    debugger;
    this.OrderTyperpt = localStorage.getItem('OrderType')
    if (this.OrderTyperpt === 'Package') {
      this.defaultReportSelected = "PackageItinerary7"
    }
    this.emailapi.getFileDetails().subscribe(x => {
      if (x != null && x != undefined) {
        this.ShowAGTCOMONINV = x.ResponseData.ShowAGTCOMONINV;
      }

    });
    this.subscription.add(
      this.emailapi
        .getFolderFromEmail(this.orderNo, +this.apiShared.uid)
        .subscribe(data => {
          if (data != null && data != undefined) {
            this.fromId = data["ResponseData"];
          }
        })
    );
    this.subscription.add(this.emailapi.GetReportEmail(+this.apiShared.uid).subscribe(data => {
      if (data != null && data != undefined) {
        if (data.ResponseData != null) {
         
          this.bccId = data.ResponseData.DEFAULTBCCMAIL
        }

      }
    }));
    var orderno = sessionStorage.getItem('orderNo');

    if (orderno != null)
      if (this.orderNo !== orderno) {
        this.router.navigate(['PenAir/Home']);
      }
    this.ResponseURL = this.router.url;;
    var domain = this.ResponseURL.split('/');
    this.page = domain[domain.length - 2];
    if (this.page === 'Invoice') {
      this.pageid = 0;
    } else if (this.page === 'IssueCreditNote') {
      this.pageid = 1;
    } else if (this.page === 'DeleteInvoice') {
      this.pageid = 2;
    } else if (this.page === 'Reinvoice') {//12873
      this.router.navigate([
        "PenAir/Folder/FolderSummary",
        this.orderNo
      ]);
      //this.pageid=3;
    } else if (this.page === 'RefundReset') {
      this.pageid = 4;
    }

    this.folderReadOnly = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;

    if (this.folderStatus == "Saved") {

      this.HeaderLabel = "Create Invoice";
    }
    else if (this.folderStatus == "Invoiced") {

      var invoiceno = sessionStorage.getItem('invoiceno');
      this.HeaderLabel = "Invoice No : " + invoiceno;

      this.subscription.add(this.translateapi.get(['Labels.PrintEmail']).subscribe((translations) => {

        this.PrintEmail = translations['Labels.PrintEmail'];;

      }));

      this.subscription.add(this.translateapi.get(['Labels.delete_invoice']).subscribe((translations) => {

        this.delete_invoice = translations['Labels.delete_invoice'];;

      }));
    }
    else if (this.folderStatus == "Refund" || this.folderStatus == "Partial Refund") {

      var invoiceno = sessionStorage.getItem('invoiceno');
      this.HeaderLabel = "Credit Note No : " + invoiceno;

      this.subscription.add(this.translateapi.get(['Labels.PrintCreditNote']).subscribe((translations) => {

        this.PrintEmail = translations['Labels.PrintCreditNote'];;

      }));

      this.subscription.add(this.translateapi.get(['Labels.delete_creditnote']).subscribe((translations) => {

        this.delete_invoice = translations['Labels.delete_creditnote'];;

      }));




    }
    else if (this.folderStatus == "Refund Request") {

      var invoiceno = sessionStorage.getItem('invoiceno');
      this.HeaderLabel = " Issue Credit Note ";
    }
    else if (this.folderStatus == "Cancelled") {
      var invoiceno = sessionStorage.getItem('invoiceno');
      this.HeaderLabel = " Cancellation No " + invoiceno;
    }

    this.changestatus();
    if (this.folderStatus == "Invoiced") {
      // this.disable=true;
    }
    this.folderInvoiceForm = this.formBuilder.group({
      FolderNo: this.orderNo,
      InvDate: ["", Validators.required],
      PmtTerm: [0],
      PmtMeth: [""],
      DepDueDate: [""],
      ProfitCentre: [""],
      Language: this.language,
      InvoiceDate: [""],
      OrderType_InvoiceType: [""],
      IsPackage: [false],
      PackageInvAmt: [0]
    });

    this.subscription.add(this.invoice.GetDefaultInvoice(this.orderNo).subscribe((data) => {
      if (data != null && data != undefined) {

        this.defaultInvoice = data;

        if ((data.ResponseData.ProfitCentre != "") && (data.ResponseData.ProfitCentre != null)) {
          this.PCIDSelectedItem = this.PCIDList.find(item => item.Name == data.ResponseData.ProfitCentre);
          if (this.PCIDSelectedItem != null && this.PCIDSelectedItem != undefined)
            this.folderInvoiceForm.controls.ProfitCentre.setValue(this.PCIDSelectedItem.Code);
        }
        if ((data.ResponseData.InvoiceDate != "") && (data.ResponseData.InvoiceDate != null)) {

          this.folderInvoiceForm.controls.InvDate.setValue(new Date(data.ResponseData.InvoiceDate));
        }
        if ((data.ResponseData.DepDueDate != "") && (data.ResponseData.DepDueDate != null)) {
          this.folderInvoiceForm.controls.DepDueDate.setValue(new Date(data.ResponseData.DepDueDate));
        }
        if ((data.ResponseData.PaymentMethod != "") && (data.ResponseData.PaymentMethod != null)) {
          this.paymentMethodSelectedItem = this.paymentMethodList.find(item => item.Name == data.ResponseData.PaymentMethod);
          if (this.paymentMethodSelectedItem != null && this.paymentMethodSelectedItem != undefined)
            this.folderInvoiceForm.controls.PmtMeth.setValue(this.paymentMethodSelectedItem.Code);
        }
        if ((data.ResponseData.PaymentTerms != "") && (data.ResponseData.PaymentTerms != null)) {
          this.paymentTermSelectedItem = this.paymentTermList.find(item => item.UsrCode == data.ResponseData.PaymentTerms);
          if (this.paymentTermSelectedItem != null && this.paymentTermSelectedItem != undefined)
            this.folderInvoiceForm.controls.PmtTerm.setValue(this.paymentTermSelectedItem.Code);
        }
      }

    }));

    this.subscription.add(this.lookupApi.GetAllLookup('PROFITCENTRE').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.PCIDList = data["ResponseData"];
          if (this.defaultInvoice.ResponseData.ProfitCentre != "") {
            this.PCIDSelectedItem = this.PCIDList.find(item => item.Name == this.PCIDSelected);
            if (this.PCIDSelectedItem != null && this.PCIDSelectedItem != undefined)
              this.folderInvoiceForm.controls.ProfitCentre.setValue(this.PCIDSelectedItem.Code);
          }
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('PAYMENT').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.paymentMethodList = data["ResponseData"];
          this.paymentMethodSelected = this.defaultInvoice.ResponseData.PaymentMethod;
          if (this.defaultInvoice.ResponseData.PaymentMethod != "") {
            this.paymentMethodSelectedItem = this.paymentMethodList.find(item => item.Name == this.defaultInvoice.ResponseData.PaymentMethod);
            if (this.paymentMethodSelectedItem != null && this.paymentMethodSelectedItem != undefined)
              this.folderInvoiceForm.controls.PmtMeth.setValue(this.paymentMethodSelectedItem.Code);
          }
        }
      }
    ));

    this.subscription.add(this.lookupApi.GetAllLookup('PAYTERM').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.paymentTermList = data["ResponseData"];
          if (this.defaultInvoice.ResponseData.PaymentTerms != "") {
            this.paymentTermSelectedItem = this.paymentTermList.find(item => item.UsrCode == this.defaultInvoice.ResponseData.PaymentTerms);
            if (this.paymentTermSelectedItem != null && this.paymentTermSelectedItem != undefined)
              this.folderInvoiceForm.controls.PmtTerm.setValue(this.paymentTermSelectedItem.Code);
          }
        }
        //  if(this.parentForm.controls.PAYMENTID.value!="")
        //  {

        //  this.PmntTrmselectedItem =this.PmntTrm.find(item => item.Code ==this.parentForm.controls.PAYMENTID.value);

        // console.log("test:-"+this.parentForm.controls.PAYMENTID.value+JSON.stringify(this.PmntTrmselectedItem , null, 4));
        // }
      }
    ));

  }
  public EmailFormClosingatol(status: any)//Email status change by case id-12434
  {
    this.email.Atolre = false;
    this.email.filename = '';
    this.email.atolfilepath = '';
    this.email.emailsubject = '';
    this.email.emailbody = '';
    if (status == "EmailSendSuccesfully" || status == "Closed")//Email status change by case id-12434
    {
      this.emailopened = false;
    }
  }

  getProductDetails(ordeNo) {
    this.subscription.add(this.Productapi.getAllProducts(ordeNo)
      .subscribe(data => {
        this.productGridData = data["ResponseData"];
        // console.log(this.gridData);
        //this.isLoadingResults = false;
        if (this.productGridData.length > 0)
          if (this.productGridData != null && this.productGridData != undefined)
            this.projno = this.productGridData[0]["PROJNO"]
        this.getProductTotal(ordeNo);
      }));

  }

  public PCIDChange(value: any): void {
    if (value != undefined && value != null) {
      this.PCIDSelectedItem = this.PCIDList.find(item => item.Code == value.Code);
      if (this.PCIDSelectedItem != null && this.PCIDSelectedItem != undefined) {
        this.folderInvoiceForm.controls.ProfitCentre.setValue(this.PCIDSelectedItem.Code);
      }
      else {
        this.PCIDSelected = "";
        this.folderInvoiceForm.controls.ProfitCentre.setValue("");
      }
    }
    else {
      this.PCIDSelected = "";
      this.folderInvoiceForm.controls.ProfitCentre.setValue("");
    }
  }
  public paymentTermChange(value: any): void {
    if (value != undefined && value != null) {
      this.paymentTermSelectedItem = this.paymentTermList.find(item => item.Code == value.Code);
      if (this.paymentTermSelectedItem != null && this.paymentTermSelectedItem != undefined) {
        this.folderInvoiceForm.controls.PmtTerm.setValue(this.paymentTermSelectedItem.Code);
      }
      else {
        this.paymentTermSelected = "";
        this.folderInvoiceForm.controls.PmtTerm.setValue("");
      }
    }
    else {
      this.folderInvoiceForm.controls.PmtTerm.setValue("");
    }
   
  }
  public paymentMethodChange(value: any): void {
    if (value != undefined && value != null) {
      this.paymentMethodSelectedItem = this.paymentMethodList.find(item => item.Code == value.Code);
      if (this.paymentMethodSelectedItem != null && this.paymentMethodSelectedItem != undefined) {
        this.folderInvoiceForm.controls.PmtMeth.setValue(this.paymentMethodSelectedItem.Code);
      }
      else {
        this.paymentMethodSelected = "";
        this.folderInvoiceForm.controls.PmtMeth.setValue("");
      }
    }
    else {
      this.folderInvoiceForm.controls.PmtMeth.setValue("");
    }
    // this.parentForm.controls.PMTID.setValue( value.Code);
  }
  public getProductTotal(orderNo) {
    this.subscription.add(this.apiTotal.getProductTotal(orderNo)
      .subscribe(data => {
        if (data != null && data != undefined) {
          this.folderproductotal = data;

         
          this.buy = this.folderproductotal.ResponseData.FCBuyTotal;
          this.comm = this.folderproductotal.ResponseData.FCCommTotal;
          this.sell = this.folderproductotal.ResponseData.FCSellTotal - (+this.folderproductotal.ResponseData.FCAGTCOMSUM);
        
          this.totalTax = this.folderproductotal.ResponseData.TotVAT;
          this.totalRecievable = this.folderproductotal.ResponseData.Total_Receivable;
          this.alreadyPaid = this.folderproductotal.ResponseData.FCAlreadyPaid;
          this.otherCredits = this.folderproductotal.ResponseData.FCOtherCredits;
          this.balance = this.folderproductotal.ResponseData.Balance;
          this.Total = this.sell + this.totalTax;
          this.AgentCommission.FCAGTCOM = this.folderproductotal.ResponseData.AGTCOM;
          this.AgentCommission.FCAGTCOMSUM = this.folderproductotal.ResponseData.FCAGTCOMSUM;
          this.AgentCommission.FCAGTCOMVAT = this.folderproductotal.ResponseData.FCAGTCOMVAT;
          this.AgentCommission.AGTCOMVATNO = this.folderproductotal.ResponseData.AGTCOMVATNO;
          this.AgentCommission.AGTCOMVATRate = this.folderproductotal.ResponseData.AGTCOMVATRate;
          this.ONETIMECUSTOMER = this.folderproductotal.ResponseData.ONETIMECUSTOMER;

          this.objCommCalculation = Object.assign({}, this.folderproductotal.ResponseData);
          this.loaded = true;
        }
      }));
  }
  get f() { return this.folderInvoiceForm.controls; }


  async onFormSubmit() {
    debugger;
    this.spinner.show();
    this.invoiceDate = (this.folderInvoiceForm.controls.InvDate.value == null || this.folderInvoiceForm.controls.InvDate.value == "" ? "01-Jan-1900" : this.folderInvoiceForm.controls.InvDate.value);//Case id-337
    this.depositDueDate = (this.folderInvoiceForm.controls.DepDueDate.value == null || this.folderInvoiceForm.controls.DepDueDate.value == "" ? "01-Jan-1900" : this.folderInvoiceForm.controls.DepDueDate.value);//Case id-337
    if (Boolean(this.commonservice.ValidDateRange(this.invoiceDate)) == false)//Case id-337
    {
      this.spinner.hide();
      this.subscription.add(this.translateapi.get(['Messages.InvalidInvoiceDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidInvoiceDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (Boolean(this.commonservice.ValidDateRange(this.depositDueDate)) == false)//Case id-337
    {
      this.spinner.hide();
      this.subscription.add(this.translateapi.get(['Messages.InvalidDepositDueDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDepositDueDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }

    if (Boolean(await this.commonservice.checkPermission1('01.12.01.09')) == true) {
      //this.spinner.hide();
      this.deletebutton = true;
      this.submitted = true;

      if (this.folderInvoiceForm.invalid) {
        this.spinner.hide();
        return;
      }

      this.Productapi
        .CreditLimitCheck("0", 0, this.orderNo, 0)
        .subscribe(res => {
          if (res != undefined || res["ResponseData"] != null) {
            if (
              res["ResponseData"].value != "0" &&
              res["ResponseData"].Status != "0" &&
              res["ResponseData"].ErrorMessage != ""
            ) {
              debugger;
              if (res["ResponseData"].value == 1) {
                this.msg = res["ResponseData"].ErrorMessage;
                this.creditLimiFlag = true;
                this.creditLimitMsg = true;
                return false;
              } else if (res["ResponseData"].value == 2) {

                this.msg = res["ResponseData"].ErrorMessage;
                this.creditLimitMsg = true;
                this.creditLimiFlag = false;

                // this.AirTicketSaveAndUpdate();
              } else {
                this.FolderInvoice();
              }
            } else {
              this.FolderInvoice();
            }
          }
        });



      this.folderStatus = this.apiShared.FolderStatus;


    } else {
      this.spinner.hide();
      this.ShowPermissionMessageForm('01.12.01.09');//339
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.09');
    }
  }
  FolderInvoice() {

    if (this.folderStatus != "Invoiced") {
      this.disableButton = true;
      // this.InvDate= (this.folderInvoiceForm.controls.InvDate.value).toLocaleDateString('en-GB', {
      //   day: '2-digit', month: 'short', year: 'numeric'
      // }).replace(/ /g, '-');
      this.InvDate = this.commonservice.transformDate(this.folderInvoiceForm.controls.InvDate.value);

      this.folderInvoiceForm.controls.InvoiceDate.setValue(this.InvDate);
      if (this.defaultInvoice.ResponseData.OrderType == "Package") {
        this.folderInvoiceForm.controls.PackageInvAmt.setValue(0);
        this.folderInvoiceForm.controls.IsPackage.setValue(true);
      }
      else {
        this.folderInvoiceForm.controls.IsPackage.setValue(false);
      }
      this.subscription.add(this.invoice.AddFolderInvoice(this.folderInvoiceForm.value).subscribe(res => {
        if (res != null && res != undefined) {
          this.spinner.hide();
          if (res.ResponseData.Messag != "") {
            this.showMsg = res.ResponseData.ReturnStatus + ':- ' + res.ResponseData.Messag;

            if (res.ResponseData.ReturnStatus == 81) {
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
              this.menulist.disablelink = false;
              this.menulist.disableinvoice = false;
              this.menulist.disablelinkReinvoice = false;
              this.subscription.add(this.translateapi.get(['Labels.delete_invoice']).subscribe((translations) => {

                this.menulist.DeleteLabel = translations['Labels.delete_invoice'];
              }));
              debugger;
              this.folderStatus = "Invoiced";
              this.layout.folderstatus = this.folderStatus;
              //this.apiShared.FolderStatus=this.folderStatus;
              this.apiShared.Setapishareddata(this.folderStatus, 'FolderStatus');
              this.HeaderLabel = "Invoice No : " + res.ResponseData.INVOICENO;
              this.apiShared.Setapishareddata(res.ResponseData.INVOICENO, 'invoiceno');
              this.subscription.add(this.translateapi.get(['Labels.PrintEmail']).subscribe((translations) => {


                this.menulist.InvoiceLabel = translations['Labels.PrintEmail'];
                this.PrintEmail = translations['Labels.PrintEmail'];

              }));
              this.changestatus();
              this.disable = true;
            } else {
              this.toastr.warning(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }
            this.disableButton = false;
          } else {
            this.spinner.hide();
            this.showFlash("Failed");
            this.disableButton = false;
          }
        }
      }));
    }
    else {
      this.spinner.hide();
      this.showFlash("InvoicedAlready");
    }
  }


  public bookingRptClose(evnt) {
    this.loadBookingRpt = false;
  }



  async onBookingConfirmationClick() {

    this.subscription.add(this.translateapi.get(['Headings.booking_confirmation']).subscribe((translations) => {
      this.reportHeader = translations['Headings.booking_confirmation']
    }));

    if (Boolean(await this.commonservice.checkPermission1('01.12.01.09.02')) == true) {
      this.rptFolderDetails = {
        orderNo: this.orderNo,
        projNo: this.projno,
        brName: this.BrName,
        folderstatus: this.folderStatus,
        atolattachement: false
      };
      this.loadBookingRpt = true;

    }
    else {
      this.ShowPermissionMessageForm('01.12.01.09.02');//339
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.09.02');
    }
  }
  changestatus() {
    this.deleteinvoicebutton = false;
    if (this.folderReadOnly == "1" && this.folderStatus == "Invoiced") {

      this.invoicebutton = true;
      this.printbutton = false;
      this.deletebutton = true;
      this.issusecreditnote = true;
      this.deleteinvoicebutton = true;

    }
    else if (this.folderReadOnly == "1" && this.folderStatus != "Invoiced") {
      this.invoicebutton = true;
      this.printbutton = true;
      this.deletebutton = true;
      this.issusecreditnote = false;
    }
    else if (this.folderStatus == "Invoiced") {
      this.invoicebutton = false;
      this.printbutton = true;
      this.deletebutton = true;
      this.issusecreditnote = true;
      this.deleteinvoicebutton = true;
    }
    else if (this.folderStatus == 'Refund' || this.folderStatus == 'Partial Refund') {
      this.printbutton = true;
      this.deletebutton = true;
      this.issusecreditnote = true;
      this.invoicebutton = false;
      this.deleteinvoicebutton = false;

    }
    else if (this.folderStatus == "Refund Request") {
      this.issusecreditnote = true;
    }
    else {
      this.invoicebutton = true;
      this.printbutton = false;
      this.deletebutton = true;
      this.issusecreditnote = false;
      this.deleteinvoicebutton = false;
    }
  }
  onCancel() {
    if (this.refundreset === true) {
      if (this.orderNo != 0 || this.orderNo != "")
        if (this.lockStatus == "0") {
          this.deletePenLock("Folder", this.orderNo);
        }
      this.orderNo = "";
      //  this.apiShared.orderNo=this.orderNo;
      this.apiShared.Setapishareddata(this.orderNo, 'orderNo');

      this.router.navigate(['PenAir/Home']);
    } else {
      sessionStorage.setItem('pagevalue', '0')
      this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo]);
    }

  }
  onview() {
    this.opened = true;
  }
  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
    this.emailopened = false;
  }
  showFlash(flshmessage: string) {

    if (flshmessage == "InvoicedAlready") {
      this.subscription.add(this.translateapi.get(['Messages.invoiced_already']).subscribe((translations) => {

        this.showMsg = translations['Messages.invoiced_already'];
        //  this.flashMessage.show(this.showMsg, { cssClass: 'bg-success text-white', timeout: 2000 });
        this.toastr.info(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })

      }));
    }
    else if (flshmessage == "Failed") {
      this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {

        this.showMsg = translations['Messages.Failed'];
        // this.flashMessage.show(this.showMsg, { cssClass: 'bg-success text-white', timeout: 2000 });
        this.toastr.warning(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
    }

  }
  async onReinvoice() {
    this.spinner.show();
    if (Boolean(await this.commonservice.checkPermission1('01.12.01.06.04')) === true) {
      for (let data of this.productGridData) {
        if (
          data.SupplierDeposit != 0 &&
          data.SupplierDeposit != null &&
          data.SupplierDeposit != undefined
        ) {
          this.spinner.hide();
          this.subscription.add(
            this.translateapi
              .get(["Messages.SupplierDepositAlreadyMade"])
              .subscribe(translations => {
                this.showMsg =
                  translations["Messages.SupplierDepositAlreadyMade"];

                this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
              })
          );
          return false;
        }
      }
      this.invoiceDate = (this.folderInvoiceForm.controls.InvDate.value == null || this.folderInvoiceForm.controls.InvDate.value == "" ? "01-Jan-1900" : this.folderInvoiceForm.controls.InvDate.value);


      this.reinvoice = new FolderReinvoice();
      this.reinvoice.pFolderNo = this.orderNo;
      this.reinvoice.language = this.language;
      this.reinvoice.invoicedate = this.commonservice.ConvertToLocaleDate(this.invoiceDate);
      this.reinvoice.packaged = 0;
      this.subscription.add(this.invoice.FolderReinvoice(this.reinvoice).subscribe(res => {
        if (res != null && res != undefined) {
          if (res.ResponseData.orderno != null && res.ResponseData.orderno != 0) {
            if (this.orderNo != 0 || this.orderNo != "")
              if (this.lockStatus == "0") {
                this.deletePenLock("Folder", this.orderNo);
              }
            this.getLockDetails("Folder", res.ResponseData.orderno, this.apiShared.uid, this.projno);
            this.orderNo = res.ResponseData.orderno;
            //this.apiShared.orderNo=this.orderNo;
            this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
            this.router.navigate(['/PenAir/Folder/Invoice/', this.orderNo]);
            this.subscription.add(this.translateapi.get(['Labels.create_invoice']).subscribe((translations) => {

              this.menulist.InvoiceLabel = translations['Labels.create_invoice'];
            }));
            this.menulist.disableinvoice = false;
            this.menulist.disablelink = true;
            this.menulist.disablelinkReinvoice = true;
            this.folderStatus = "Saved";
            this.layout.folderstatus = this.folderStatus;
            this.layout.headerPassenger = sessionStorage.getItem('PassengerNames') + '-' + res.ResponseData.orderno;
            this.spinner.hide();
            //this.apiShared.FolderStatus=this.folderStatus;
            this.apiShared.Setapishareddata(this.folderStatus, 'FolderStatus');
            this.toastr.success(res.ResponseData.message["messageid"] + ':- ' + res.ResponseData.message["message"].replace("FolderOrder2Cash -", "") + 'New order no is:' + this.orderNo, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          } else {
            this.toastr.warning(res.ResponseData.message["messageid"] + ':- ' + res.ResponseData.message["message"].replace("FolderOrder2Cash -", ""), "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
            this.spinner.hide();
          }
        }
      }));
    } else {
      this.ShowPermissionMessageForm('01.12.01.06.04');//339
      this.spinner.hide();
      // this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.06.04');
    }
  }
  async onDeleteinvoice() {
    this.spinner.show();
    if (Boolean(await this.commonservice.checkPermission1('01.12.01.06.03')) === true) {
      this.deleteinvoice = new FolderReinvoice();
      this.invoiceDate = (this.folderInvoiceForm.controls.InvDate.value == null || this.folderInvoiceForm.controls.InvDate.value == "" ? "01-Jan-1900" : this.folderInvoiceForm.controls.InvDate.value);//Case id-337
      this.deleteinvoice.pFolderNo = this.orderNo;
      this.deleteinvoice.language = this.language;
      this.deleteinvoice.packaged = 0;

      this.deleteinvoice.invoicedate = this.commonservice.ConvertToLocaleDate(this.invoiceDate);
      this.subscription.add(this.invoice.DeleteInvoice(this.deleteinvoice).subscribe(res => {
        if (res != null && res != undefined) {
          if (res.ResponseData.refundFolderNo != null && res.ResponseData.refundFolderNo != 0) {
            if (this.orderNo != 0 || this.orderNo != "")
              if (this.lockStatus == "0") {
                this.deletePenLock("Folder", this.orderNo);
              }
            this.getLockDetails("Folder", res.ResponseData.refundFolderNo, this.apiShared.uid, this.projno);
            this.orderNo = res.ResponseData.refundFolderNo;
            // this.apiShared.orderNo=this.orderNo;
            this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
            this.folderStatus = "Cancelled";
            this.layout.folderstatus = this.folderStatus;
            //this.apiShared.FolderStatus=this.folderStatus;
            this.apiShared.Setapishareddata(this.folderStatus, 'FolderStatus');
            this.router.navigate(['/PenAir/Folder/DeleteInvoice/', this.orderNo]);
            var invoiceno = sessionStorage.getItem('invoiceno');
            this.HeaderLabel = " Cancellation No " + invoiceno;

            this.menulist.disablecreditnote = true;
            this.menulist.disablelink = true;
            this.menulist.disableinvoice = true;
            this.menulist.disablelinkReinvoice = true;
            if (res.ResponseData.message["messageid"] == 42)
              this.toastr.success(res.ResponseData.message["messageid"] + ':- ' + res.ResponseData.message["message"], "", {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              })
            else
              this.toastr.warning(res.ResponseData.message["messageid"] + ':- ' + res.ResponseData.message["message"], "", {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              })
            this.spinner.hide();
            // let currentUrl = this.router.url;
            // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            // this.router.onSameUrlNavigation = 'reload';
            // this.router.navigate([currentUrl]);
          } else {
            this.toastr.warning(res.ResponseData.message["messageid"] + ':- ' + res.ResponseData.message["message"], "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
            this.spinner.hide();
          }
        }
      }));
    } else {
      this.ShowPermissionMessageForm('01.12.01.06.03');//339
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.06.03');
      this.spinner.hide();
    }
  }

  getLockDetails(docType, docNo, userId, projno) {
    this.subscription.add(this.penLock.getLock(docType, docNo, userId, projno)
      .subscribe(data => {
        if (data != null && data != undefined) {
          this.penDockLock = data;
          if (this.penDockLock.ResponseData != null) {
            if (this.penDockLock.ResponseData.Uname != "") {
              this.folderReadOnly = "1";
              // this.apiShared.folderReadOnly=this.folderReadOnly;
              this.apiShared.Setapishareddata(this.folderReadOnly, 'folderReadOnly');
              this.subscription.add(this.translateapi.get(['Labels.lock_msg']).subscribe((translations) => {
                this.confirmMsg = translations['Labels.lock_msg'].replace("<<1>>", this.penDockLock.ResponseData.Uname)
              }));
            }
            else {
              this.folderReadOnly = "0";
              // this.apiShared.folderReadOnly=this.folderReadOnly;
              this.apiShared.Setapishareddata(this.folderReadOnly, 'folderReadOnly');
            }
          }
          else {
            this.folderReadOnly = "0";
            //this.apiShared.folderReadOnly=this.folderReadOnly;
            this.apiShared.Setapishareddata(this.folderReadOnly, 'folderReadOnly');
          }
        }

      }));

  }
  public open() {


    this.opened = true;
    // alert(JSON.stringify(this.opened));
  }

  deletePenLock(docType, docno) {
    this.subscription.add(this.apipenLock.DeleteLock(docType, docno + "/").subscribe(res => {

    }, (err) => {
      console.log(err);

    }
    ));
  }

  async onIssueCreditNotesClick() {
    this.spinner.show();
    debugger;
    this.invoiceDate = (this.folderInvoiceForm.controls.InvDate.value == null || this.folderInvoiceForm.controls.InvDate.value == "" ? "01-Jan-1900" : this.folderInvoiceForm.controls.InvDate.value);//Case id-337
    this.depositDueDate = (this.folderInvoiceForm.controls.DepDueDate.value == null || this.folderInvoiceForm.controls.DepDueDate.value == "" ? "01-Jan-1900" : this.folderInvoiceForm.controls.DepDueDate.value);//Case id-337
    if (Boolean(this.commonservice.ValidDateRange(this.invoiceDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidInvoiceDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidInvoiceDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.commonservice.ValidDateRange(this.depositDueDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDepositDueDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDepositDueDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }

    if (Boolean(await this.commonservice.checkPermission1('01.12.01.06.01')) === true) {
      this.refund = new FolderRefund();
      this.refund.pFolderNo = this.orderNo;
      this.refund.pACKAGED = 0;
      this.refund.language = this.language;
      this.refund.invoiceDeleteFlag = 0;
      this.refund.pDepDueDate = this.folderInvoiceForm.controls.DepDueDate.value;
      this.InvDate = this.commonservice.transformDate(this.invoiceDate);
      this.refund.invDate = this.InvDate;
      this.refund.pPmtMeth = this.folderInvoiceForm.controls.PmtMeth.value;
      this.refund.pPmtTermId = this.folderInvoiceForm.controls.PmtTerm.value;
      this.refund.pProfitCentre = this.folderInvoiceForm.controls.ProfitCentre.value;
      this.subscription.add(this.invoice.FolderRefund(this.refund).subscribe(res => {
        if (res != null && res != undefined) {
          if (res.ResponseData.refundFolderNo > 0 && (res.ResponseData.message["messageid"].toString() == "477" || res.ResponseData.message["message"] === "Folder Refunded Successfully")) {
            this.toastr.success(res.ResponseData.message["messageid"] + ':- ' + res.ResponseData.message["message"], "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            });
            this.pageid = 0;
            this.folderStatus = "Refund";
            this.subscription.add(this.translateapi.get(['Labels.PrintCreditNote']).subscribe((translations) => {

              this.PrintEmail = translations['Labels.PrintCreditNote'];;

            }));
            var invoiceno = res.ResponseData.InvoiceNo;
            this.HeaderLabel = "Credit Note No : " + invoiceno;
            this.printbutton = true;
            this.apiShared.FolderStatus = this.folderStatus;
            this.layout.folderstatus = this.folderStatus;
            this.menulist.disableRefund = false;
            this.issusecreditnote = false;
            this.menulist.InvoiceLabel = "Print Credit note"
            this.menulist.disablecreditnote = true;
            this.menulist.disableinvoice = false;

            //this.apiShared.FolderStatus=this.folderStatus;
            this.apiShared.Setapishareddata(this.folderStatus, 'FolderStatus');
            this.changestatus();
            this.spinner.hide();
          }
          else if (res.ResponseData.message["messageid"].toString() != "0" && res.ResponseData.message["messageid"].toString() != null) {

            this.commonservice.showWARNINGtoastrmsg(res.ResponseData.message["messageid"] + ':- ' + res.ResponseData.message["message"], 3000, true, false);


            this.toastr.warning(res.ResponseData.message["messageid"] + ':- ' + res.ResponseData.message["message"], "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            });
            this.spinner.hide();
          }
          else {
            this.toastr.error(res.ResponseData.message["messageid"] + ':- ' + res.ResponseData.message["message"], "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            });
            this.spinner.hide();
          }
        }
      }));
    } else {
      this.ShowPermissionMessageForm('01.12.01.06.01');//339
      this.spinner.hide();
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.06.01');
    }
  }

  async onRefundReset() {
    this.spinner.show();
    if (Boolean(await this.commonservice.checkPermission1('01.12.01.06.05')) == true) {
      this.refundReset = new FolderRefundreset();
      this.refundReset.pFolderNo = this.orderNo;
      this.refundReset.language = this.language;
      this.refundReset.packaged = 0;
      this.refundReset.userId = this.userid;
      this.subscription.add(this.invoice.RefundReset(this.refundReset).subscribe(res => {
        if (res != null && res != undefined) {
          if (res.ResponseData.message.messageid === 422) {
            this.toastr.success(res.ResponseData.message["messageid"] + ':- ' + res.ResponseData.message["message"], "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            });
            this.pageid = 6;
            this.menulist.disablecreditnote = true;
            this.menulist.disablelink = true;
            this.menulist.disableinvoice = true;
            this.refundreset = true;
            var invoiceno = sessionStorage.getItem('invoiceno');
            this.HeaderLabel = "Credit Note No : " + invoiceno;
            this.spinner.hide();
            this.orderNo = "";

            this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
            this.router.navigateByUrl(localStorage.getItem('alerturl'));
            localStorage.removeItem('alerturl');
            this.commonservice.folderEditMode = 'false';
            this.apiShared.folderEditMode = this.commonservice.folderEditMode;
            this.apiShared.Setapishareddata(this.commonservice.folderEditMode, 'folderEditMode');


          } else {
            this.toastr.error(res.ResponseData.message["messageid"] + ':- ' + res.ResponseData.message["message"], "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            });
            this.spinner.hide();
          }
        }
      }));
    } else {
      this.ShowPermissionMessageForm('01.12.01.06.05');//339
      this.spinner.hide();
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.06.05');
    }
  }

  ngOnDestroy() {
    this.email.filename = '';
    this.email.atolfilepath = '';
    this.email.emailsubject = '';
    this.email.emailbody = '';
    this.email.filename = '';
    this.email.AtolEnabled = false;
    debugger;
    if (this.commonservice.formData != undefined) {
      this.commonservice.formData.projno = ''
      this.commonservice.formData.FolderNo = '';

    }
    this.email.Fromlist = undefined;
    this.subscription.unsubscribe();
  }
  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339

  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }

  public CalculateAgentComm() {
    this.agentComm = true;
  }
  CloseAgentComm() {
    this.agentComm = false;
  }

  public AgntCommFormClosing(status: any) {
    if (status == "close") {
      this.CloseAgentComm()
    }
  }
  public confirmFolderDelete() {

    this.deletefolder = true;
  }

  onDeleteFolder(shouldRemove: boolean) {
    if (shouldRemove) {
      this.onDeleteinvoice();
    }
    this.deletefolder = false;
  }
  ConfirmMessage() {
    if (this.creditLimiFlag == false) {
      this.creditLimitMsg = false;
      this.FolderInvoice();
    } else {
      this.creditLimitMsg = false;
    }
  }

  // public crClose() {
  //   this.crOpened = false;
  //   this.email.emailbody = '';
  // }

  // public async crSendMail() {
  //   this.spinner.show();
  //   await this.email.ComposeMail(this.crRptName,this.crFilePath,this.crEmailPath,this.reportViewerHeader,this.emailbody,true);
  //   await this.email.updateReportParameterDTO(this.selectedReport);
  //   await this.email.GetEmailBodyByFolderno();
  //   await this.subscription.add(
  //     this.common
  //       .GetReportBodyOrSubject()
  //       .subscribe((res) => {
  //         this.spinner.hide();
  //         this.email.Fromlist = res["ResponseData"]["Fromdata"]
  //         this.email.CCEmail = res["ResponseData"]["CCEmail"];
  //         this.emailopened = true;
  //         this.common.formData.FolderNo = this.orderNo;
  //         this.common.formData.projno = this.projno;
  //         this.common.formData.BCCEmail = res["ResponseData"]["BCCEmail"];
  //         this.common.formData.BCCEnable = res["ResponseData"]["BCCEnable"];
  //         this.common.formData.ToEmail = res["ResponseData"]["ToEmail"];
  //         this.common.formData.EmailBody = res["ResponseData"]["EmailBody"].replace("<<WEBLINK>>", this.rptAPI.webLink);
  //         if (this.rptAPI.webLink == '') {
  //           this.common.formData.RecieptEmailBody = res["ResponseData"]["RecieptEmailBody"];

  //           this.email.folderreportfilepath = res["ResponseData"]["Attachementpath"];
  //         }
  //         this.emailopened=true;
  //       }
  //     )
  //   )


  // }
  // public SendTelerikMail() {
  //   debugger;
  //   this.spinner.show();
  //   this.email.Atolre=true;


  //      this.email.reportname= this.reportSubject;
  //     //  this.email.atolfilepath = this.filepathatol;
  //     //  this.email.emailatolpath = this.emailatolpath;
  //      if(this.commonservice.formData==undefined)
  //       this.commonservice.formData = new ReportParameterDTO();
  //       this.commonservice.formData.attachmentindex=0;
  //       var ordernos= this.apiShared.orderNo;
  //       this.commonservice.formData.trdpfilename=this.selectedReport;
  //       this.commonservice.formData.FolderNo=String (ordernos);
  //       this.commonservice.formData.Userid=+this.apiShared.uid;
  //       this.commonservice.formData.Branch=String (this.apiShared.BRID);
  //       this.commonservice.formData.RtftoHtmlUrl=this.env.RtftoHtmlUrl;
  //       this.subscription.add(
  //         this.commonservice
  //           .GetReportBodyOrSubject()
  //           .subscribe((res) => {
  //             debugger;
  //             this.spinner.hide();
  //             this.emailopened = true;
  //             this.opened=true;
  //             this.commonservice.formData.FolderNo=this.orderNo;
  //             this.commonservice.formData.projno=this.projno;
  //             this.email.Fromlist=res["ResponseData"]["Fromdata"]
  //             this.email.CCEmail=res["ResponseData"]["CCEmail"];
  //             this.commonservice.formData.BCCEmail= res["ResponseData"]["BCCEmail"];
  //             this.commonservice.formData.BCCEnable= res["ResponseData"]["BCCEnable"];
  //             this.commonservice.formData.ToEmail=res["ResponseData"]["ToEmail"];
  //             this.commonservice.formData.EmailBody=res["ResponseData"]["EmailBody"];
  // //  this.email.atolfilepath = res["ResponseData"]["Attachementpath"];
  //   this.email.folderreportfilepath=res["ResponseData"]["Attachementpath"];

  //   this.rptopened=false;

  //   // this.email.filename=this.email.atolfilepath;
  //           }))

  //     } 

  // public rptopen() {
  //   debugger;
  //   if(this.selectedReport!="")
  //   {

  //     if (
  //       this.reportViewertemp != null &&
  //       this.reportViewertemp != "" &&
  //       this.reportViewertemp != undefined
  //     )
  //       this.reportViewerHeader = this.reportViewertemp.replace(
  //         "<<MAILFORMAT>>",
  //         this.selectedReport
  //       );
  //     else this.reportViewerHeader = this.selectedReport;
  //     if(this.selectedReport=="PackageItinerary7")
  //     {
  //       this.reportParms=({Id:1,FolderNo: [this.orderNo],RtftoHtmlUrl:[this.env.RtftoHtmlUrl],envpath:[this.env.baseUrl.toString()],UserId:this.apiShared.uid});
  //       this.reportFile="PackageItenaryreport.trdp";

  //       this.reportHeader='Package Invoice Report-7'
  //       this.reportSubject=this.reportViewerHeader;
  //       this.rptmainopened = true;
  //       this.invoiceopened = false;
  //     }
  //     if(this.selectedReport=="InvoicewithItinerary 02")
  //     {
  //       this.spinner.show();
  //       this.api.CrRptformData =  new DocumentEmailDTO;
  //       this.api.CrRptformData.OrderNo=this.orderNo;
  //       this.api.CrRptformData.ReportType="InvoicewithItinerary02";
  //       this.api.CrRptformData.rptfilename="flightbookingconfirmation";
  //       this.api.CrRptformData.ReportName="InvoicewithItinerary02";

  //       this.subscription.add(this.api.GetCrystalReport()
  //         .subscribe(res => {
  //           this.spinner.hide();


  //           this.crRptName="Booking confirmation";
  //           // this.crFileName = res["ResponseData"].rptfilename;
  //            this.crPdfFilePath = this.pdfurl + res["ResponseData"].Reportpath;
  //           this.crFilePath = res["ResponseData"].Reportpath;
  //           // this.crDeletePath = res["ResponseData"].Reportfolder
  //           this.crEmailPath = res["ResponseData"].EmailReportpath;
  //           this.crUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.crPdfFilePath);
  //           this.crOpened=true;


  //       }));


  //       // this.reportParms=({FolderNo: [this.orderNo],RtftoHtmlUrl:this.env.RtftoHtmlUrl,UserId:this.apiShared.uid,PaymentUrl:0});
  //       // this.reportFile="flightbookingconfirmation.trdp";
  //       // this.reportHeader='InvoicewithItinerary 02'
  //       // this.reportSubject=this.reportViewerHeader;
  //       // this.rptmainopened = true;
  //       // this.invoiceopened = false;
  //     } 
  //     this.email.filename = this.reportFile.replace("trdp", "pdf");
  //     this.email.emailsubject=this.reportViewerHeader;

  //     }

  //   // this.rptopened = true;
  //   // this.opened = false;
  //   }
}

