import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Workbook } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import { Subscription } from 'rxjs';
import { PenChannel } from 'src/app/Models/pen-channel';
import { EmailService } from 'src/app/Services/email.service';
import { PenChannelService } from 'src/app/Services/pen-channel.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process, State, SortDescriptor } from '@progress/kendo-data-query';
import { RowClassArgs, DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import * as moment from 'moment'
import { LookUpDetails } from 'src/app/Services/look-up-details';
import { LookUpDetailsService } from 'src/app/Services/look-up-details.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonserviceService } from 'src/app/Services/commonservice.service';
import { PenChannelParameterDTO } from 'src/app/Models/pen-channel-parameter-dto.model';

@Component({
  selector: 'app-penchannel-list',
  templateUrl: './penchannel-list.component.html',
  // styleUrls: ['./penchannel-list.component.scss'],
  styles: [`
       .k-grid tr.even { background-color: #f45c42; }
       .k-grid tr.odd { background-color: #41f4df; }
   `],
})
export class PenchannelListComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public PenChannelList: Array<PenChannel["ResponseData"]> = [];
  public opened: any = false;
  showFilter = false;
  fileToUpload: File
  PCNO: any;

  public Componentname: string;
  public state: State = {
    skip: 0,
    take: 10,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public PenChannelDetailsList: GridDataResult = process(this.PenChannelList, this.state);//Caseid-12434
  public equals: 'equals';
  constructor(public email: EmailService, private penchannelApi: PenChannelService, private router: Router, private lookupApi: LookUpDetailsService, private toastr: ToastrService, private translateapi: TranslateService
    , private route: ActivatedRoute, private spinner1: NgxSpinnerService, public common: CommonserviceService) {
    this.allData = this.allData.bind(this);
    // this.PCNO=sessionStorage.getItem('PCNO');
  }
  public UserID: any;//Caseid-12434
  loginFromDate: any = null;
  loginToDate: any = null;
  public showMsg: any;
  public penChannelLoginFromDate: any;
  public penChannelLoginToDate: any;
  public btnGenerate = false;
  public PenChannelParamDto: PenChannelParameterDTO;
  PenChannelUserList: Array<LookUpDetails["ResponseData"]> = [];//Caseid-12434
  public PenChannelUserSelectedItem: LookUpDetails["ResponseData"];//Caseid-12434

  ngOnInit() {
    //localStorage.setItem('PCNO')=sessionStorage.getItem('PCNO');
    sessionStorage.setItem('Componentname', "PenchannelList");
    this.PCNO = sessionStorage.getItem('PCNO');
    //this.loaddata();//Caseid-12434
    this.subscription.add(this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe( //Caseid-12434
      (data) => {
        if (data != null && data != undefined) {
          this.PenChannelUserList = data["ResponseData"];
        }
      }
    )));


    //this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654


  }


  public PenChannelUserChange(value: any): void //Caseid-12434
  {
    if (value != null && value != undefined) {
      this.UserID = (value.Name);
    }
    else {
      this.UserID = "";
    }
  }

  public EmailFormClosing(status: any)//Email status change by case id-13552
  {
    if (status == "EmailSendSuccesfully" || status=="Closed")  //Email status change by case id-13552
    {
      this.opened = false;
    }
  }
  onFilterClick()///12731
  {

    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }
  public ClearFilter() {//Caseid-12434
    this.state.filter = {
      logic: 'and',
      filters: []
    };
    this.PenChannelDetailsList = process(this.PenChannelList, this.state);//Caseid-12434
  }
  public isFiltered(colName: string) {//Caseid-12434
    const operators = ['isnull', 'isnotnull', 'isempty', 'isnotempty'];
    return this.state.filter.filters.some((f: { field: string; operator: string; }) => (f.field === colName && operators.indexOf(f.operator) > -1));
  }

  public dataStateChange(state: DataStateChangeEvent): void {//Caseid-12434
    this.state = state;
    this.PenChannelDetailsList = process(this.PenChannelList, this.state);
  }

  GetPenChannelData() {//Caseid-12434
    this.loaddata();
    this.ClearFilter();
  }
  public async loaddata() {//Caseid-12434
    this.loginFromDate = (this.penChannelLoginFromDate == null ? "01-Jan-1900" : this.penChannelLoginFromDate);
    this.loginToDate = (this.penChannelLoginToDate == null ? "01-Jan-1900" : this.penChannelLoginToDate);
    this.UserID = (this.UserID == null ? "" : this.UserID);

    if (this.loginFromDate == "01-Jan-1900") {
      this.subscription.add(this.translateapi.get(['Messages.empty_fromdate']).subscribe((translations) => {

        this.showMsg = translations['Messages.empty_fromdate'];
        this.toastr.error(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.PenChannelList = null;
      return;
    }
    else if ((this.loginFromDate == "01-Jan-1900" && this.loginToDate != "01-Jan-1900")
    ) {
      this.subscription.add(this.translateapi.get(['Messages.empty_fromdate']).subscribe((translations) => {

        this.showMsg = translations['Messages.empty_fromdate'];;
        this.toastr.error(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.PenChannelList = null;
      return this.PenChannelList;
    }

    else if (this.loginFromDate > this.loginToDate) {
      this.subscription.add(this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations) => {

        this.showMsg = translations['Messages.fromdate_exceeds'];;
        this.toastr.error(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })
      }));
      this.PenChannelList = null;
      return;
    }
    else if (Boolean(this.common.ValidDateRange(this.loginFromDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidFromDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.PenChannelList = null;
      return;
    }
    else if (Boolean(this.common.ValidDateRange(this.loginToDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidToDate'];
        this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.PenChannelList = null;
      return;
    }
    else {
      this.btnGenerate = true;
      if (this.loginFromDate != "01-Jan-1900" && this.loginToDate == "01-Jan-1900") {
        this.loginToDate = this.loginFromDate;
        this.penChannelLoginToDate = this.penChannelLoginFromDate;
      }
      this.spinner1.show();
      this.loginFromDate = this.common.transformDate(new Date(this.loginFromDate));
      this.loginToDate = this.common.transformDate(new Date(this.loginToDate));

      this.PenChannelParamDto = new PenChannelParameterDTO;
      this.PenChannelParamDto.LoginFromDate = this.loginFromDate;
      this.PenChannelParamDto.LoginToDate = this.loginToDate;
      this.PenChannelParamDto.UserId = this.UserID;
      this.penchannelApi.formData = this.PenChannelParamDto;

      this.subscription.add(this.penchannelApi.getPenchannelDetails()
        .subscribe(data => {
          if (data != null && data != undefined) {
            this.PenChannelList = data["ResponseData"];
            for (var obj of this.PenChannelList) {
              obj.LOGINDATE = new Date(obj.PCULOGINDATE);
              obj.LOGINTIME = moment(obj.PCULOGINTIME).format("hh:mm:ss A");
              if (obj.LogoutTime != null) {
                var logindate = new Date(obj.PCULOGINTIME);
                var timeString = logindate.getHours() + ':' + logindate.getMinutes() + ':' + logindate.getSeconds();
                obj.PCULOGINDATE = obj.PCULOGINDATE.replace('T00:00:00', ' ' + timeString);
                obj.LogoutTime = obj.LogoutTime.replace('T', ' ');
                var ms = moment(obj.LogoutTime, "YYYY-MM-DD HH:mm:ss").diff(moment(obj.PCULOGINDATE, "YYYY-MM-DD HH:mm:ss"));
                var diff = moment.duration(ms);
                var duration = Math.floor(diff.asHours()) + moment.utc(ms).format(":mm:ss");
                obj.Duration = duration;
              }
            }
            if (this.PenChannelList.length > 0) {
              this.btnGenerate = false;
              this.spinner1.hide();
            }
            else {
              this.btnGenerate = false;
              this.subscription.add(this.translateapi.get(['Messages.no_data_found']).subscribe((translations) => {

                this.showMsg = translations['Messages.no_data_found'];;
                this.toastr.error(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
              this.spinner1.hide();
            }
          }
          else {
            this.spinner1.hide();
          }
        }));

    }
  }
  public sort: SortDescriptor[] = [  //12731
    {
      field: 'LOGINDATE',
      dir: 'desc'
    }
  ];
  onClear()//Caseid-12434
  {
    this.penChannelLoginFromDate = null;
    this.penChannelLoginToDate = null;
    this.PenChannelUserSelectedItem = null;
    this.PenChannelList = null;
  }
  public rowCallback(context: RowClassArgs) {
    if (+sessionStorage.getItem('PCNO') > -1) {
      const isActive = context.dataItem.PCNO === +sessionStorage.getItem('PCNO');
      return {
        active: isActive
      };
    }
  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.PenChannelList, { sort: [{ field: 'LOGINDATE', dir: 'desc' }] }).data
    };
    return result;
  }
  public onExcelExport2(args: any): void {
    this.email.filename = 'PenChannel Report.xlsx';
    this.email.reportname = 'PenChannel Report';
    // Prevent automatically saving the file. We will save it manually after we fetch and add the details
    args.preventDefault();
    this.open();


    const observables = [];
    const workbook = args.workbook;
    const rows = workbook.sheets[0].rows;

    // Get the default header styles.
    // Aternatively set custom styles for the details
    // https://www.telerik.com/kendo-angular-ui/components/excelexport/api/WorkbookSheetRowCell/
    const headerOptions = rows[0].cells[0];




    // create a Workbook and save the generated data URL
    // https://www.telerik.com/kendo-angular-ui/components/excelexport/api/Workbook/
    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      // https://www.telerik.com/kendo-angular-ui/components/filesaver/=

      saveAs(dataUrl, 'PenChannel Report.xlsx');


      this.email.fileurl = this.dataURItoBlob(dataUrl);





    });
  }
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // // create a view into the buffer
    var ia = new Uint8Array(ab);

    // // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });

    return blob;



  }
  public close(status) {
    this.opened = false;
  }
  public open() {
    this.opened = true;
  }
  onCancel() {
    this.router.navigate(['PenAir/Home']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
