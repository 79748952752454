import { Component, OnInit ,Input} from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { CommonserviceService } from '../../Services/commonservice.service';

@Component({
  selector: 'app-membership-loyalitypoints',
  templateUrl: './membership-loyalitypoints.component.html',
  styleUrls: ['./membership-loyalitypoints.component.scss']
})
export class MembershipLoyalitypointsComponent implements OnInit {

  

  constructor(public intl: IntlService,
    public common: CommonserviceService) { }
  @Input() itineraryDetails;
  ngOnInit() {

    
  }
  
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;

  public onChange(event: any): void {
      this.expandMode = parseInt(event.target.value, 10);
  }
  
  public onPanelChange(event: any): void { console.log('stateChange: ', event); }


  
}

