export class FolderAddExpense {
    OrderNo:any;
    INETREF:any;
    ProductUserCode:any;
    CurrencyId:any;
    ExchangeRate:any;
    TCAmount:any;
    SupplierUserCode:any;
    BookingDate:any;
    ProductCode:any;
    SupplierCode:any;
    Service:any;
    Status:any;
}
