import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import {
  FolderSearchCriteriaDTO,
  InboxClosedParameterDTO,
} from "../Models/folder-search-criteria-dto";
import { AlertListDTO } from "../Models/alertdetails";
import { ThrowStmt } from "@angular/compiler";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { EnvService } from "src/env.service";
import {
  CashPaymentDTO,
  AtolReportDTO,
} from "../Models/cash-payment-dto.model";
import { catchError, tap } from "rxjs/operators";
import { CRMSearchCriteriaDTO } from "../Models/crmsearch-criteria-dto";
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable({
  providedIn: "root",
})
export class CRMSearchService {
  pdfurl: string;
  url: string;
  navigateRoot:string="search";

  constructor(private http: HttpClient, private env: EnvService) {
    this.pdfurl = env.baseUrl;
  }

  cRMSearchCriteriaDTO: CRMSearchCriteriaDTO =
    sessionStorage.getItem("CRMSearchDTO") === null
      ? new CRMSearchCriteriaDTO()
      : JSON.parse(sessionStorage.getItem("CRMSearchDTO"));
  private crmSearchSource = new BehaviorSubject(this.cRMSearchCriteriaDTO);

  SrchCRMDTO = this.crmSearchSource.asObservable();

  CRMSearch(CRMSearchCriteriaDto) {
    ////alert(JSON.stringify(folderSearchCriteriaDto))
    this.crmSearchSource.next(CRMSearchCriteriaDto);

    sessionStorage.setItem(
      "CRMSearchDTO",
      JSON.stringify(CRMSearchCriteriaDto)
    );
    this.cRMSearchCriteriaDTO = CRMSearchCriteriaDto;
  }
}
