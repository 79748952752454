import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';
import { HotelActivityTblDTO, RoomInventory } from '../Models/room-inventory';
import { HotelInventoryService } from './hotel-inventory.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class HotelmasterService {
  apiUrl :string;
  public formData:any;
  public hotelid:any;
  public formDataactivity:HotelActivityTblDTO;
  // public formDatas:Hote102DTO;
  formDatasmain:any={
    HotelCode:0
  };
  public createdItems: RoomInventory[];
  public updatedItems: RoomInventory[];
  public  deletedItems: RoomInventory[];
  data:any;
  edithotelactvity:any=false  ;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   }
  
   getAllHotel(): Observable<any> {
    const url = `${this.apiUrl}` + 'GetAllHotels' ;
    return this.http.get<any>(url).pipe(
      catchError(this.commonservice.handleError<any>(`getAllHotels`))
    );
  }
  AddHotelMaster(hotelDetail: any): Observable<any> {
    if(hotelDetail.CheckinTime!=null && hotelDetail.CheckinTime!='' && hotelDetail.CheckinTime!=undefined)
    {
      
      var timee=this.setTime(hotelDetail.CheckinTime);
      hotelDetail.CheckinTime=timee;
    }
    else{
      hotelDetail.CheckinTime=''
    }
    if(hotelDetail.CheckoutTime!=null && hotelDetail.CheckoutTime!='' && hotelDetail.CheckoutTime!=undefined)
    {
      var timee=this.setTime(hotelDetail.CheckoutTime);
      hotelDetail.CheckoutTime=timee; 
    }
    else{
      hotelDetail.CheckoutTime=''; 
    }
    return this.http.post<any>(this.apiUrl + 'AddHotelMaster', hotelDetail, httpOptions).pipe(
      //tap((tourRes: any) => console.log(`added Supplier w/ id=${tourRes.ResponseData.SUPPNAME}`)),
     // catchError(this.commonservice.handleError<any>('addTour'))
    );
  }
  setTime(time:any){
  
    if(time===null || time==='')
    time=new Date("01-Jan-1900").toLocaleString();
    else
    {
  
  
      
      debugger;
      if(time.setDate==undefined)
       time= new Date(time); 
      
  
      time.setDate(1);
      time.setMonth(0);
      time.setFullYear(1900);
      time.setSeconds(0);
      time=time.toLocaleString();
      return time;
    
    }
  }
  UpdateHotelMaster(hotelDetail: any): Observable<any>{
    if(hotelDetail.CheckinTime!=null && hotelDetail.CheckinTime!='' && hotelDetail.CheckinTime!=undefined)
{
  
  var timee=this.setTime(hotelDetail.CheckinTime);
  hotelDetail.CheckinTime=timee;
}
else{
  hotelDetail.CheckinTime=''
}
if(hotelDetail.CheckoutTime!=null && hotelDetail.CheckoutTime!='' && hotelDetail.CheckoutTime!=undefined)
{
  var timee=this.setTime(hotelDetail.CheckoutTime);
  hotelDetail.CheckoutTime=timee; 
}
else{
  hotelDetail.CheckoutTime=''; 
}
    
    return this.http.post<any>(this.apiUrl + 'UpdateHotelMaster', hotelDetail, httpOptions).pipe(
      catchError(this.commonservice.handleError<any>('UpdatehotelDetails'))
    );
  }
  DeleteHotelMaster(HID:any, HotelCode: any):  Observable<any> {
   const url = `${this.apiUrl}`+'DeleteHotelMaster'+`/${HID}`+`/${HotelCode}`;
    return this.http.post<any>(url, httpOptions).pipe(
     catchError(this.commonservice.handleError<any>('DeleteHotelDetails'))
    );
  }
  DeleteHotelImage(RemoveImage: any):  Observable<any> {
    return this.http.post<any>(this.apiUrl + 'RemoveHotelImg', RemoveImage, httpOptions).pipe(
      catchError(this.commonservice.handleError<any>('UpdatehotelDetails'))
    );
    // const url = `${this.apiUrl}`+'RemoveHotelImg'+`/${RemoveImage}`;
    //  return this.http.post<any>(url, httpOptions).pipe(
    //   catchError(this.commonservice.handleError<any>('DeleteHotelDetails'))
    //  );
   }
  
  GetHotelDetailsbyId(HotelCode:any):Observable<any> { 
   const url =  `${this.apiUrl}`+'GetHotelMasterById'+`/${HotelCode}`;
   return this.http.get<any>(url);  
 }
 LisHotelActivity(AutoNo:any):Observable<any> { 
  const url =  `${this.apiUrl}`+'LisHotelActivity'+`/${AutoNo}`;
  return this.http.get<any>(url);  
}
Removehotelactivity(AutoNo: any):  Observable<any> {
  const url = `${this.apiUrl}`+'Removehotelactivity'+`/${AutoNo}`;
   return this.http.get<any>(url, httpOptions).pipe(
    catchError(this.commonservice.handleError<any>('Removehotelactivity'))
   );
 }
LisHotelActivityByID(AutoNo:any):Observable<any> { 
  const url =  `${this.apiUrl}`+'LisHotelActivityByID'+`/${AutoNo}`;
  return this.http.get<any>(url);  
}
 GetRoomInventory(HotelCode:any): Observable<any> {
  const url = `${this.apiUrl}` + 'GetInventoryList'+`/${HotelCode}` ;
  return this.http.get<any>(url)
}
SaveInventory (): Observable<any> {
  var body = {
    ...this.formDatasmain,

     createdItems:this.createdItems,
     updatedItems: this.updatedItems,
     deletedItems: this.deletedItems,
   
  };
  
  return this.http.post<any>(this.apiUrl+'SaveInventoryDetails', body);
  //alert(JSON.stringify(FolderSummary));
 

//  this.api.formDatasmain=this.roomdetailform.value;
//  this.api.List=[];
//  this.api.List=this.editService.api.data;
}
SaveHotelactivitydetails (): Observable<any> {
  if(this.formDataactivity.ValidFrom!=null) // For caseid-187
{
  
  
  {
    this.formDataactivity.ValidFrom=(this.commonservice.transformDate(this.formDataactivity.ValidFrom));
  }
}
if(this.formDataactivity.ValidTo!=null ) 
{

    this.formDataactivity.ValidTo=(this.commonservice.transformDate(this.formDataactivity.ValidTo));

  
}
  var body = {
    ...this.formDataactivity
   
  
   
  };
  return this.http.post<any>(this.apiUrl+'SaveHotelactivitydetails', body);
  //alert(JSON.stringify(FolderSummary));
 


}


}