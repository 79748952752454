import { Injectable } from '@angular/core';  
import { HttpClient, HttpClientModule } from '@angular/common/http';  

import {  PassengerDetails, PassengerDetailsn } from '../Models/passenger-details';
import { Observable,of } from 'rxjs';
import { HttpHeaders } from "@angular/common/http";
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
@Injectable({
  providedIn: 'root'
})
export class PassengerService {
  apiUrl :string;
  private data: any[] ;
  private counter: number = PassengerDetails.length;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   }


  GetPassengerDetails(folderNo: string):Observable<PassengerDetails> { 
    const url = `${this.apiUrl}`+'GetAllPassengerDetails'+`/${folderNo}`;
    
    return this.http.get<PassengerDetails>(url);  

  }
  GetPassengerDetails1(folderNo: string):Observable<PassengerDetailsn> { 
    const url = `${this.apiUrl}`+'GetAllPassengerDetails'+`/${folderNo}`;
    
    return this.http.get<PassengerDetailsn>(url);  

  }

  GetPassengerDetailsById(folderNo: string ,slno:number): Observable<PassengerDetails>
   {
    const url = `${this.apiUrl}`+'GetPassengerDetailsById'+`/${folderNo}/${slno}`;
    //alert(JSON.stringify(url));
    return this.http.get<PassengerDetails>(url).pipe(
      tap(_ => console.log(`fetched Hotel id=${folderNo}`)),
      catchError(this.commonservice.handleError<PassengerDetails>(`getHotel id=${folderNo}`))
    );    
  }

DeletePassengerDetails(folderNo: string ,Autono:any):Observable <any>
{
  const url = `${this.apiUrl}`+'DeletePassengerDetails'+`/${folderNo}/${Autono}`;
  
   
    return this.http.post(url,  httpOptions).pipe(
      tap(_ => console.log(`delete passenger`)),
      catchError(this.commonservice.handleError<any>('deletepassenger'))
    );
}
AddPassengerDetails(passengerDetails: any): Observable<PassengerDetails>
{
 
  // if(passengerDetails.DOB==null)
  // passengerDetails.DOB="01-Jan-1900";
    if(passengerDetails.DOB==null)
    passengerDetails.DOB=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
    passengerDetails.DOB=this.commonservice.ConvertToLocaleDate(passengerDetails.DOB);

  return this.http.post<PassengerDetails>(this.apiUrl+'AddPassengerDetails', passengerDetails, httpOptions).pipe();
  
}
UpdatePassengerDetails(passengerDetails: any): Observable<PassengerDetails>
{

  if(passengerDetails.DOB==null)
    passengerDetails.DOB=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
    passengerDetails.DOB=this.commonservice.ConvertToLocaleDate(passengerDetails.DOB);
  return this.http.post<PassengerDetails>(this.apiUrl+'UpdatePassengerDetails', passengerDetails, httpOptions).pipe();
  
}
}
