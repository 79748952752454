import { JsonPipe } from "@angular/common";
import { ThrowStmt } from "@angular/compiler";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DialogContentBase, DialogRef } from "@progress/kendo-angular-dialog";
import { AutoCompleteComponent, DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { IntlService } from "@progress/kendo-angular-intl";
import { ToolBarDropDownButtonComponent } from "@progress/kendo-angular-toolbar";
import { Subscription } from "rxjs";
import { LookUpDetails } from "../Services/look-up-details";
import { CommonserviceService } from "../Services/commonservice.service";
import { CurrencyExchangerateService } from "../Services/currency-exchangerate.service";
import { LookUpDetailsService } from "../Services/look-up-details.service";
import { ShareDataService } from "../Services/share-data.service";
import { FileService } from "../Services/file.service";
import { NgxSpinnerService } from "ngx-spinner";
import { BankPaymentDTO } from "../Models/bank-payment-dto.model";

@Component({
  selector: "app-creditcard-details-window",
  templateUrl: "./creditcard-details-window.component.html",
  styleUrls: ["./creditcard-details-window.component.scss"],
})
export class CreditcardDetailsWindowComponent extends DialogContentBase {
  @ViewChild("autocompletecustomer") public autocompletecustomer: AutoCompleteComponent;
  private _custname: string;
 
  balanceamtv:any=0;
  @Input() public set custCo(value: number) {
    this.formGroup.controls.customerCode.setValue(value);
    this._custCo = value.toString();
  }
  @Input() public set balanceamt(value: number) {
    
    this.balanceamtv = value.toString();
  }
  
  @Input() public set orderNo(value: string) {
    this.formGroup.controls.orderNo.setValue(value);
    this._orderNo = value;
  }
  @Input() public set crncyCode(value: string) {
    this.formGroup.controls.currency.setValue(value);

    this._crncyCode = value;
  }
  @Input() public set ParenForm(value: string) {
    this.formGroup.controls.parentForm.setValue(value);
    this._FromForm = value;
  }
  public get getCustomerCode(): string {
    return this._custCo;
  }
  public get getCurrencyCode(): string {
    return this._crncyCode;
  }
  public get getParentFrom(): string {
    return this._FromForm;
  }

  public get getOrderNo(): string {
    return this._orderNo;
  }
  public get getcustname(): string {
    return this._custname;
  }
  invalidpdqNo = false;
  private _custCo: string;
  public _crncyCode: string;
  private _orderNo: string;
  private _FromForm: string;
  lookuptype: string = 'SUPPLIERS';
  lookupname: any = 'Supplier';
  opened = false;
  openSearch = false;
  isSuppShown = false;
  ExRate:any=0;
  Language:any='EN';
  CCChargePaymentGateway: any = false;
  isCustShown = false;
  TCPDQAMT: any = 0;
  custBorderColor: any = "red";
  public formGroup: FormGroup = new FormGroup({
    pdqNo: new FormControl("0"),
    cardType: new FormControl("0"),
    customerCode: new FormControl(this.getCustomerCode),
    dateC: new FormControl(new Date()),
    currency: new FormControl(this.getCurrencyCode),
    exRate: new FormControl(0.0),
    invoiceAmount: new FormControl(0.0),
    ccRate: new FormControl(""),
    ccCharge: new FormControl(""),
    custname: new FormControl(""),
    totalAmount: new FormControl(""),
    cardnumber: new FormControl(""),
    authCode: new FormControl(""),
    uniquRef: new FormControl(""),
    pdQCharge: new FormControl("0"),
    orderNo: new FormControl(this.getOrderNo),
    CardName: new FormControl(''),
    parentForm: new FormControl(this.getParentFrom),
    CARDDESP: new FormControl(''),
    CARDID:new  FormControl(''),
  });

  constructor(
    public dialog: DialogRef,
    private apiFile: FileService,
    private lookupApi: LookUpDetailsService,
    public commonservice: CommonserviceService,
    private apiShared: ShareDataService,
    private apiExRate: CurrencyExchangerateService,
    public intl: IntlService,
    private spinner: NgxSpinnerService
  ) {
    super(dialog);
  }
  public onCancelAction(): void {
    this.formGroup = undefined;
    this.dialog.close({ text: "Cancel" });
    window.onscroll = function () {};
  }
  public submitted: boolean;
  public FromFolder: boolean;
  public Invalid: boolean = false;
  public Iscustvalid: boolean = false;
  public Iscardvalid: boolean = false;
  public IsAuthvalid: boolean = false;
  public Isamtvalid: boolean = false;
  public onConfirmAction(): void {
    debugger;
    this.formGroup.controls.custname.setValue(this.autocompletecustomer.text);
    this.Invalid = false;
    this.Iscustvalid = false;
    this.Iscardvalid = false;
    this.Isamtvalid = false;
    if (
      this.formGroup.value["pdqNo"] == "0" ||
      this.formGroup.value["pdqNo"] == "" ||
      this.formGroup.value["pdqNo"] == undefined
    ) {
      this.invalidpdqNo = true;
    } else {
      this.invalidpdqNo = false;
    }
    if (this.formGroup.value.customerCode == 0) {
      this.Iscustvalid = true;
    }
    if (this.cardTypeSelectedItem == undefined) this.Iscardvalid = true;
    else if (
      this.cardTypeSelectedItem.CARDID == "0" ||
      this.cardTypeSelectedItem.CARDID == ""
    )
      this.Iscardvalid = true;
    if (this.formGroup && !this.formGroup.valid) {
      if (this.cardTypeSelectedItem == undefined) this.Iscardvalid = true;
      else if (
        this.cardTypeSelectedItem.CARDID == "0" ||
        this.cardTypeSelectedItem.CARDID == ""
      )
        this.Iscardvalid = true;
      if (
        this.formGroup.value.authCode == "" ||
        this.formGroup.value.authCode == undefined
      )
        this.IsAuthvalid = true;
      if (
        this.formGroup.value.invoiceAmount == "0" ||
        this.formGroup.value.invoiceAmount == undefined ||
        this.formGroup.value.invoiceAmount == ""
      )
        this.Isamtvalid = true;
      return;
    }
    if (
      this.invalidpdqNo == false &&
      this.Iscustvalid == false &&
      this.Iscardvalid == false &&
      this.IsAuthvalid == false &&
      this.Isamtvalid == false
    ) {
      window.onscroll = function () {};
      this.dialog.close({ text: "OK", primary: true });
    }
  }

  private subscription: Subscription = new Subscription();
  PDQ: Array<LookUpDetails["ResponseData"]> = [];
  public PDQselectedItem: LookUpDetails["ResponseData"];
  cardType: Array<any> = [];
  public cardTypeSelectedItem: any;
  CustomerList: Array<LookUpDetails["ResponseData"]> = [];
  public customerSelectedItem: LookUpDetails["ResponseData"];
  public customerSelectedValue: string;
  currencyList: Array<LookUpDetails["ResponseData"]> = [];
  public currencySelectedItem: LookUpDetails["ResponseData"];

  folderExchangeRate: any;
  tcPDQCardRate: number;
  iscreditordebit:any='';
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  getFolderDefaultCustomer() {
    this._custCo = sessionStorage.getItem("CustomerCode");
    this.formGroup.controls.customerCode.setValue(this._custCo);
  }
  getFolderValues() {
    this._crncyCode = sessionStorage.getItem("folderCurrency");
    this.formGroup.controls.currency.setValue(this._crncyCode);
  }

  ngOnInit() {
    this.Invalid = false;
debugger;
    if (
      this._orderNo != null &&
      this._orderNo !== "" &&
      this._orderNo != undefined
    ) {
      if (
        this._custCo == null ||
        this._custCo == undefined ||
        this._custCo == "0" ||
        this._custCo == ""
      )
        this.getFolderDefaultCustomer();
      if (
        this._crncyCode == null ||
        this._crncyCode == undefined ||
        this._crncyCode == ""
      )
        this.getFolderValues();

      this.FromFolder = true;
    } else {
      this.FromFolder = false;
      if(this.formGroup.controls.currency==null||this.formGroup.controls.currency.value==''){
        this._crncyCode = (sessionStorage.getItem('homeCurrency'));
        this.formGroup.controls.currency.setValue(this._crncyCode);
      }
    
    }
    this.subscription.add(this.lookupApi.GetAllLookup('LANGUAGES').subscribe(
      (data) => {
        debugger;
        if (data != null && data != undefined) {
          if (data["ResponseData"] != null && data["ResponseData"] != undefined) {
            if (data["ResponseData"].length > 0) {
              this.Language=data["ResponseData"][0].Code;
            }}}

      }))
    if (this.formGroup.value.exRate == "0.000000") {
      if(this._crncyCode==null|| this._crncyCode ==undefined)
      {
        this.subscription.add(
          this.apiFile.getFileDetails().subscribe((data) => {
            this._crncyCode = data.ResponseData.COMCID.valueOf();
            this.getExchangeRate(this._crncyCode, this.formGroup.value.dateC);
            this.GetDefaultPDQ();
          })
        );
        
      }
      else
      {
        this.getExchangeRate(this._crncyCode, this.formGroup.value.dateC);
        this.GetDefaultPDQ();
      }
 
     // this.getExchangeRate(this._crncyCode, this.formGroup.value.dateC);
    }
    else{
      this.GetDefaultPDQ();
    }
debugger;
    if (this.formGroup.value.parentForm == "Supplier") {
      this.subscription.add(
        this.lookupApi.GetAllLookup("SUPPLIERS").subscribe((data) => {
          if (data != null && data != undefined) {
            this.CustomerList = data["ResponseData"];
            if (this.formGroup.value.customerCode != 0) {
              this.customerSelectedItem = this.CustomerList.find(
                (item) => item.Code == this.formGroup.value.customerCode
              );
              this.customerSelectedValue = this.customerSelectedItem.Name;
            }
          }
        })
      );
    } else {
      this.subscription.add(
        this.lookupApi.GetAllLookup("CUSTOMER").subscribe((data) => {
          if (data != null && data != undefined) {
            this.CustomerList = data["ResponseData"];
            if (this.formGroup.value.customerCode != 0 && this.formGroup.value.customerCode!=null) {
              this.customerSelectedItem = this.CustomerList.find(
                (item) => item.Code == this.formGroup.value.customerCode
              );
              this.customerSelectedValue = this.customerSelectedItem.Name;
            }
          }
        })
      );
    }
    this.subscription.add(
      this.lookupApi.GetAllLookup("CURRENCY").subscribe((data) => {
        if (data["ResponseData"] != null) {
          this.currencyList = data["ResponseData"];
          if (this.formGroup.value.currency == null || this.formGroup.value.currency == undefined||this.formGroup.value.currency == '' ||this.formGroup.value.currency == '0'||this.formGroup.value.currency == 0)
          {
            this.formGroup.value.currency=(sessionStorage.getItem('homeCurrency'));
          }
          if (this.formGroup.value.currency != 0)
            this.currencySelectedItem = this.currencyList.find(
              (item) => item.Code == this.formGroup.value.currency
            );
        }
      })
    );

    if (this.formGroup.value.parentForm == "Supplier") {
      this.isSuppShown = true;
      this.isCustShown = false;
    } else {
      this.isSuppShown = false;
      this.isCustShown = true;
    }
    this.CalculateCcCharge();
    }
  // setDefaultExrate() {
  //   if (
  //     this.formGroup.value.exRate == "" ||
  //     this.formGroup.value.exRate == 0 ||
  //     this.formGroup.value.exRate == undefined
  //   )
  //     this.formGroup.controls.exRate.setValue("1.000000");
  // }
  getExchangeRate(currency, postingdate) {
    postingdate = new Date(postingdate)
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    postingdate = postingdate.replace("Sept", "Sep");

    this.subscription.add(
      this.apiExRate
        .getExchangeRate(currency, postingdate)
        .subscribe((data) => {
          if (data != null && data != undefined) {
            if (data.ResponseData.EXRATE != undefined)
              this.formGroup.controls.exRate.setValue(data.ResponseData.EXRATE);
            else this.formGroup.controls.exRate.setValue("1.000000");

            this.ExRate= this.formGroup.value.exRate;
          }
        })
    );
  }
  onDateblur() {
    debugger;
    this.getExchangeRate(this.formGroup.value.currency, this.formGroup.value.dateC);
  }
  public GetCurrencyExchangeRateTolerance()
  {
    debugger;
    this.spinner.show();
    this.commonservice.excformData = new BankPaymentDTO();
    
    this.commonservice.excformData.PVRDATE = this.commonservice.ConvertToLocaleDate(this.formGroup.value.dateC);
    this.commonservice.excformData.date = this.commonservice.ConvertToLocaleDate(new Date())
   
    this.commonservice.excformData.HCID = this.formGroup.value.currency;
    this.commonservice.excformData.FCID =this.formGroup.value.currency;
    this.commonservice.excformData.RXRATE =this.formGroup.value.exRate;

    this.commonservice.excformData.LANQUAGE = this.Language;
    this.subscription.add( this.commonservice.GetCurrencyExchangeRateTolerance().subscribe(x => {
      this.spinner.hide();
      if (x != null && x != undefined) {
        this.spinner.hide();
        debugger;
        if (x["ResponseData"].messageid== 0) {
        //  this.ExRate =this.ExRate ;
         // this.CalculateAll();
        }
        else if (x["ResponseData"].messageid> 0 ) {
          
          var msg = 'Messages.msg' + x["ResponseData"].messageid;
          this.commonservice.showWARNINGtoastrmsg(x["ResponseData"].message, 3000, true, false);
         this.formGroup.controls.exRate.setValue(this.ExRate);
        
        }
      }
    })
    )
  }
  public async PDQChange(value: any) {
    debugger;
    if (value !== undefined && value !== null && value != "") {
      this.invalidpdqNo = false;
      this.formGroup.controls.pdqNo.setValue(value.Code);
      await this.bindCardType(value.Code);
      this.formGroup.controls.CardName.setValue(value.SELFBILLING)
      // this.subscription.add(this.lookupApi.GetPDQCard(value.Code).subscribe(
      //   (data) => {

      //      if(data!=null&& data!=undefined){
      //       this.cardType= data["ResponseData"] ;
      //       if(this.formGroup.value.cardType.trim()!='')
      //       this.cardTypeSelectedItem=  this.cardType.find(item => item.CARDID == this.formGroup.value.cardType);

      //        }
      //   }));
    } else {
      
       this.formGroup.controls.CardName.setValue('');
     
      this.invalidpdqNo = true;
    }
  }
  GetDefaultPDQ()
  {
    var provider="PDQ";
    if (this.formGroup.value.parentForm == "Supplier") {
      provider="PDQCMNYCARD";
    }
    else
    {
      provider="PDQ";
    }
    this.subscription.add(
      this.lookupApi.GetAllLookup(provider).subscribe((data) => {
        if (data != null && data != undefined) {
          this.PDQ = data["ResponseData"];
          debugger;
          if (this.formGroup.value.pdqNo != 0) {
            this.PDQselectedItem = this.PDQ.find(
              (item) => item.Code == this.formGroup.value.pdqNo
            );
            
             this.formGroup.controls.CardName.setValue(this.PDQselectedItem.SELFBILLING)
             
            
            this.bindCardType(this.formGroup.value.pdqNo);
          } else {
            this.subscription.add(
              this.lookupApi
                .GetDefaultCrediCardPDQ(
                  this.apiShared.uid,
                  this._orderNo,
                  this._crncyCode
                )
                .subscribe((data) => {
                  if (data != null && data != undefined) {
                    this.PDQselectedItem = this.PDQ.find(
                      (item) => item.Code == data["ResponseData"]["CARDNO"]
                    );
                    if (this.PDQselectedItem != undefined) {
                      this.formGroup.controls.pdqNo.setValue(
                        this.PDQselectedItem.Code
                      );
                      
                       this.formGroup.controls.CardName.setValue(this.PDQselectedItem.SELFBILLING)
                       
                      
                      this.bindCardType(data["ResponseData"]["CARDNO"]);
                    }
                  }
                })
            );
          }
          //formGroup.get('name')"
        }
      })
    );
  }
  public async bindCardType(pdqNo: number) {
    await this.subscription.add(
      await this.lookupApi.GetPDQCard(pdqNo).subscribe((data) => {
        if (data != null && data != undefined) {
          this.cardType = data["ResponseData"];

          if (
            this.formGroup.value.cardType != "" &&
            this.formGroup.value.cardType != 0
          ) {
            this.cardTypeSelectedItem = this.cardType.find(
              (item) => item.CARDID == this.formGroup.value.cardType
            );

            this.getCcChargeAndRate();
          }
        }
      })
    );
    await this.subscription.add(
      this.lookupApi.GetCChargeByPaymentGateway(pdqNo).subscribe((data) => {
        if (data["ResponseData"] != null) {
          if (data["ResponseData"] == 1) {
            this.CCChargePaymentGateway = false;
          } else {
            this.CCChargePaymentGateway = true;
          }
        }
      })
    );
  }
  public CardTypeChange(value: any): void {
    if (value !== undefined && value !== null && value != "") {
      this.custBorderColor = "#d7dcd7";
      this.Iscardvalid = false;
      this.formGroup.controls.cardType.setValue(value.CARDID);
      this.cardTypeSelectedItem = this.cardType.find(
        (item) => item.CARDID == value.CARDID
      );
      if(this.cardTypeSelectedItem!=null){
        this.formGroup.controls.CARDDESP.setValue(this.cardTypeSelectedItem.CARDDESP);
        this.formGroup.controls.CARDID.setValue( this.cardTypeSelectedItem.CARDID);
        }
      // if (this.formGroup.value.parentForm == "Customer")
      this.getCcChargeAndRate();
    } else {
      this.formGroup.value.CARDDESP='';
      this.custBorderColor = "red";
      this.Iscardvalid = true;
    }
  }

  public getCcChargeAndRate() {
    debugger;
    if (
      this.cardTypeSelectedItem != undefined &&
      this.PDQselectedItem != undefined
    ) {
      this.subscription.add(
        this.lookupApi
          .getCcChargeAndRate(
            this.PDQselectedItem.Code,
            this.cardTypeSelectedItem.CARDID
          )
          .subscribe((data) => {
            if (data != null && data != undefined) {
              this.formGroup.controls.ccRate.setValue(
                data["ResponseData"]["CCCHARGEPERC"]
              );
              this.formGroup.controls.ccCharge.setValue(
                data["ResponseData"]["CCCHARGEAMT"]
              );
              debugger;
              this.ccRate = data["ResponseData"]["CCCHARGEPERC"];
              this.ccCharge = data["ResponseData"]["CCCHARGEAMT"];
              this.iscreditordebit=data["ResponseData"]["CardType"];
              this.tcPDQCardRate = data["ResponseData"]["RATE"];
              this.TCPDQAMT = data["ResponseData"]["AMOUNT"];
              this.CalculateTotalAndPDQCharge();
            }
          })
      );
    }
  }

  public CustomerChange(value: any): void {
    debugger;
    if (value !== undefined && value !== null && value != "") {
      this.customerSelectedItem = this.CustomerList.find(
        (item) => item.Name == value
      );
      this.formGroup.controls.customerCode.setValue(
        this.customerSelectedItem.Code
      );
    } else {
      this.formGroup.controls.customerCode.setValue(0);
    }
  }

  public CurrencyChange(value: any): void {
    debugger;
    if (value !== undefined && value !== null) {
      this._crncyCode = value.Code;
      this.formGroup.controls.currency.setValue(value.Code);
      this.getExchangeRate(this._crncyCode, this.formGroup.value.dateC);
    }
  }
  public reset() {
    if (
      this.formGroup.value.CARDID != "" &&
      this.formGroup.value.CARDID != undefined
    )
      this.Iscardvalid = false;
    else this.Iscardvalid = true;
    if (
      this.formGroup.value.authCode != "" &&
      this.formGroup.value.authCode != undefined
    )
      this.IsAuthvalid = false;
    else this.IsAuthvalid = true;
    if (this.formGroup.value.customerCode != 0) this.Iscustvalid = false;
    else this.Iscustvalid = true;
  }

  private invAmt: number = 0.0;
  private ccRate: number = 0.0;
  private ccCharge: number = 0.0;
  public CalculateCcCharge() {
    debugger;
    this.ccCharge=0.00;
    this.invAmt = this.formGroup.value.invoiceAmount;
    this.ccRate = this.formGroup.value.ccRate;
    if (this.ccRate==null){
      this.ccRate=0;
    }
    if (this.invAmt != 0 && this.invAmt != undefined) {
      if (this.ccRate!=null &&this.ccRate != 0 ) {
        this.ccCharge = parseFloat(
          ((this.invAmt * this.ccRate) / 100).toFixed(2)
        );
        this.formGroup.controls.ccCharge.setValue(this.ccCharge);
      }
      else
      {
        this.formGroup.controls.ccCharge.setValue(0);
      }
      this.CalculateTotalAndPDQCharge();
    } else {
      this.formGroup.controls.totalAmount.setValue(
        parseFloat(this.ccCharge.toFixed(2))
      );
    }
    if (
      this.formGroup.value.invoiceAmount != "" ||
      this.formGroup.value.invoiceAmount != "0" ||
      this.formGroup.value.invoiceAmount != "0.00" ||
      this.formGroup.value.invoiceAmount != undefined
    )
      this.Isamtvalid = false;
  }

  public CalculateCcRate() {
    debugger;
    this.invAmt = this.formGroup.value.invoiceAmount;
    this.ccCharge = this.formGroup.value.ccCharge;
    if (this.invAmt != 0 && this.invAmt != undefined) {
      //  this.Inamtvalid=false;
      //   if(this.ccCharge!=0)
      //   {
      // this.ccRate=parseFloat((this.ccCharge * 100 /(this.invAmt)).toFixed(2));
      // this.formGroup.controls.ccRate.setValue(this.ccRate);
      //   }
      this.CalculateTotalAndPDQCharge();
    } else {
      this.formGroup.controls.totalAmount.setValue(
        parseFloat(this.ccCharge.toFixed(2))
      );
    }
    //(this.invAmt)

    //(Total Amount*TCPDQCARD.RATE)/100
  }
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
    if (this.lookuptype == 'CUSTOMER') {
      this.lookupname = 'CUSTOMER';
    }
  }
  public close(status) {

    this.opened = false;
    this.openSearch = false;
  }
  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.CustomerList = [];
        this.customerSelectedItem = value;
        this.CustomerList[0] =this.customerSelectedItem;
        this.autocompletecustomer.text=value.Name;
        this.customerSelectedValue=value.Name;
        this.CustomerChange(value.Name)
        // if (this.lookuptype == 'SUPPLIERS') {
        //   this.CustomerChange(value.ActualName)
        // }
        // if (this.lookuptype == 'CUSTOMER') {
        //   this.CustomerChange(value.ActualName)
        // }
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  private CalculateTotalAndPDQCharge() {
    debugger;
    if (this.invAmt != undefined && this.ccCharge != undefined)
      this.formGroup.controls.totalAmount.setValue(
        parseFloat((this.invAmt + this.ccCharge).toFixed(2))
      );
    else {
      this.formGroup.controls.totalAmount.setValue(
        parseFloat(this.ccCharge.toFixed(2))
      );
    }
    if (this.tcPDQCardRate != undefined)
      if (this.tcPDQCardRate == 0) {
        this.formGroup.controls.pdQCharge.setValue(
          parseFloat(this.TCPDQAMT.toFixed(2)).toString()
        );
      } else if(this.iscreditordebit=="Company Credit Card") {
        this.formGroup.controls.pdQCharge.setValue(
          parseFloat(
            (
              (this.formGroup.value.totalAmount * this.tcPDQCardRate) /
              100
            ).toFixed(2)
          ).toString()
        );
      }
      else if(this.formGroup.value.parentForm=="CUSTOMER") {
        this.formGroup.controls.pdQCharge.setValue(
          parseFloat(
            (
              (this.formGroup.value.totalAmount * this.tcPDQCardRate) /
              100
            ).toFixed(2)
          ).toString()
        );
      }
      else{
        this.formGroup.controls.pdQCharge.setValue(0.00);
      }
  }
}
