import { Component, OnInit ,Input} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin, fromEvent, Subject, Subscription } from 'rxjs';
import { AirticketService } from '../../Services/airticket.service';
import { CarService } from '../../Services/car.service';
import { CommonserviceService } from '../../Services/commonservice.service';
import { CruiseService } from '../../Services/cruise.service';
import { FolderpriceService } from '../../Services/folderprice.service';
import { HotelService } from '../../Services/hotel.service';
import { InsuranceService } from '../../Services/insurance.service';
import { OthersService } from '../../Services/others.service';
import { RailService } from '../../Services/rail.service';
import { TourService } from '../../Services/tour.service';
import { TransfersService } from '../../Services/transfers.service';
@Component({
  selector: 'app-detailed-product-grid',
  templateUrl: './detailed-product-grid.component.html',
  styleUrls: ['./detailed-product-grid.component.scss']
})
export class DetailedProductGridComponent implements OnInit {
  TYPE:string='Air';
  equals:any;
  public formeditable = true;
  showMsg :string ='';
  productGrid: any;
  isAll:boolean=false;
  prdservice:any;
  private subscription: Subscription = new Subscription();
  public removeProductConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  public productToRemove: any;
  orderNo:any=0;
  public productGridData1: any;
  prodgridHeight = 140;
  public pageSize = 50;
  public skip = 0;
  public state: State = {
    skip: 0
  };
  constructor(private spinner: NgxSpinnerService,private Productapi: FolderpriceService, private translateapi: TranslateService,public common: CommonserviceService,private router: Router,private hotelapi: HotelService,private carapi: CarService
    
    , private tktapi: AirticketService, private railapi: RailService, private cruiseapi: CruiseService, private tourapi: TourService
    , private othersapi: OthersService, private insuranceapi: InsuranceService, private transfersapi: TransfersService) { }

  ngOnInit(): void {

debugger;


  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    // this.loadProduct();
  }
  public confirmProductRemove(shouldRemove: boolean): void {
    debugger;
    this.removeProductConfirmationSubject.next(shouldRemove);
    if (shouldRemove) {

      let res = this.productToRemove;
let TYPE=this.TYPE;
      if (TYPE == "Hotel") {
        this.subscription.add(this.hotelapi.deleteHotel(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {  //By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
         this.getProductDetails();
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (this.TYPE == "Car") {
        this.subscription.add(this.carapi.DeleteCarDetails(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails();
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (this.TYPE == "Air") {
        this.subscription.add(this.tktapi.DeleteAirTicketDetails(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails();
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (this.TYPE == "Rail") {
        this.subscription.add(this.railapi.DeleteRailDetails(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails();
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (this.TYPE == "Cruise") {
        this.subscription.add(this.cruiseapi.deleteCruise(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails();
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (this.TYPE == "Tour") {
        this.subscription.add(this.tourapi.deleteTour(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails();
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (this.TYPE == "Others") {
        this.subscription.add(this.othersapi.deleteOthers(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails();
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (this.TYPE == "Insurance") {
        this.subscription.add(this.insuranceapi.deleteInsurance(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          //this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails();
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
      else if (this.TYPE == "Transfers") {
        this.subscription.add(this.transfersapi.deleteTransfers(res.FOLDERNO, res.GUIDItinerary + "/").subscribe(res => {
          // this.common.showFlash1('Messages.deleted_success','bg-success text-white',2000);
          this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {//By case id-355
            this.showMsg = translations['Messages.deleted_success'];
            this.common.showSUCCESStoastrmsg(this.showMsg, 2000, true, false);
          }));
          this.getProductDetails();
        }, (err) => {
          console.log(err);
          this.common.showFlash1('Messages.Failed', 'bg-danger text-white', 2000);
        }
        ));
      }
    }
    this.productToRemove = null;

  }

  public removeProductdetailsConfirmation(data): Subject<boolean> {
    debugger;
    let dataItem=data.dataItem;
    if (dataItem.SupplierDeposit != 0) {
      this.productToRemove = null;

      this.subscription.add(
        this.translateapi
          .get(["Messages.SupplierDepositAlreadyMade"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.SupplierDepositAlreadyMade"];

            this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
          })
      );
    }else if (dataItem.PONO != 0) 
    { 
      this.common.showWARNINGtoastrmsg("Messages.POAlready250", 2000, true, false);
    } else {
      this.productToRemove = dataItem;
      return this.removeProductConfirmationSubject;
    }
  }
  onProductCellClick(e) {
debugger;
      if (e.column.field != null) {
        let clickedRowItem = e.dataItem;
        // alert(JSON.stringify( clickedRowItem));
        //alert(JSON.stringify(e.column));
        if (clickedRowItem.GUIDItinerary === null || clickedRowItem.GUIDItinerary === '') {
          this.subscription.add(this.translateapi.get(['Messages.Invalidproductguid1st']).subscribe((translations) => {

            this.showMsg = translations['Messages.Invalidproductguid1st'];


          }));
          this.subscription.add(this.translateapi.get(['Messages.Invalidproductguid2nd']).subscribe((translations) => {

            this.showMsg = this.showMsg + " " + 2018 + " ." + translations['Messages.Invalidproductguid2nd'];


          }));
          this.common.showWARNINGtoastrmsg(this.showMsg,5000,false,true)
         
        }
        else {
          if (this.TYPE == "Air")//By Caseid-12641
          {
            if (
              (clickedRowItem.TTYPE == "ADM" ||
                clickedRowItem.TTYPE == "ACM" ||
                clickedRowItem.TTYPE == "MCO" ||
                clickedRowItem.TTYPE == "MPD" ||
                clickedRowItem.TTYPE == "EMD" ||
                clickedRowItem.TTYPE == "VMPD") &&
              clickedRowItem.GRNINO != 0 &&
              clickedRowItem.GRNINO != null)//By Caseid-12641
              this.router.navigate(['PenAir/AirTicketDetails/pAirTicketDetailsADMACM/', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/', clickedRowItem.FPD_AutoNo, clickedRowItem.PROJNO]);
            else
              this.router.navigate(['/PenAir/Product/AirticketDetails/', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          }
          else if (this.TYPE == "Hotel")
            this.router.navigate(['/PenAir/Product/HotelDetails/', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (this.TYPE == "Insurance")
            this.router.navigate(['PenAir/Product/InsuranceDetails', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (this.TYPE == "Tour")
            this.router.navigate(['PenAir/Product/TourDetails', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (this.TYPE == "Cruise")
            this.router.navigate(['PenAir/Product/CruiseDetails', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (this.TYPE == "Others")
            this.router.navigate(['PenAir/Product/OthersDetails', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (this.TYPE == "Car")
            this.router.navigate(['PenAir/Product/CarDetails/', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (this.TYPE == "Rail")
            this.router.navigate(['PenAir/Product/RailDetails/', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);
          else if (this.TYPE == "Transfers")
            this.router.navigate(['PenAir/Product/TransfersDetail/', clickedRowItem.FOLDERNO, clickedRowItem.GUIDItinerary + '/']);

        }


      }
    

  }
 getProductDetails() {
this.isAll=false;
debugger
    // this.Productapi.formparmData= new  Object;
    // this.Productapi.formparmData.OrderNo=ordeNo;
    // this.Productapi.formparmData.TYPE=Type;
    this.formeditable=this.Productapi.formparmData.formeditable;
    this.TYPE=this.Productapi.formparmData.TYPE;
    // this.TYPE=this.Productapi.formparmData.TYPE;
    this.productGridData1=[];
    this.productGrid=[];
    this.spinner.show();
    this.subscription.add(this.Productapi.GetProductDetailedlist()
      .subscribe(data => {
        debugger;
        this.spinner.hide();
        this.removeProductdetailsConfirmation = this.removeProductdetailsConfirmation.bind(this);
        if (data !== null && data !== undefined && data["ResponseData"].length > 0) {
          this.spinner.hide();
          //this.productGridData = data["ResponseData"];
          this.productGrid = data["ResponseData"];
          this.productGridData1 = process(this.productGrid, this.state).data;
        //  this.productDataStateChange(this.state);
         
            this.prodgridHeight = 140;
          if (this.productGridData1.length > 5) {
            this.prodgridHeight = this.prodgridHeight + (43 * 5);
          } else {
            this.prodgridHeight = this.prodgridHeight + (43 * this.productGridData1.length);
          }
          
  
        
         


        }
        else {
          this.productGridData1=[];
          this.productGrid=[];
          this.spinner.hide();

        }
      }));
  }
  public productDataStateChange(state: State): void {
    this.state = state;
    this.productGridData1 = process(this.productGrid, this.state);
  //  this.subscription.unsubscribe();
    
  }
}
