export class FolderRefundItineraryDTO {
    public  Type :string
    public  GUIDItinerary :string

}
export class FolderRefundDTO
{
    public  FolderNo :string
    public  ProjNo :string
}
export class FolderPriceDetailsDTO {
    
    FOLDERNO:number;
   TYPE:string;
    PMCODE:number;
    ITEMNO:number;
   DESCRIPTION :string;
   SUPPNAME :string;
   SUPPPRTYCODE :string;
   PRV :string;
   PRP :string;
   INVOICED :string;
   PRI :string;
    TICKETAMT:number;
    OTHERTAX:number;
    BUYAMT:number;
    COMMAMT:number;
    SELLAMT:number;
    PAYABLEAMT:number;
    PAIDAMT:number;
    PRTYCODE:number;
    TAXNO:number;
    VAT:number;
    UBTAX:number;
    
}
