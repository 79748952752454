import { Component, Inject, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { Config, Menu, Menu1, Favorites } from './types';
import { PublicLayoutComponent } from '../../public-layout/public-layout.component';
import { TranslateService, TranslateStore } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/Services/login.service';
import { PendoclockService } from 'src/app/Services/pendoclock.service';
import { ShareDataService } from 'src/app/Services/share-data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonserviceService } from 'src/app/Services/commonservice.service';
import { L10nInterceptor } from 'src/app/_helpers/l10n.interceptor';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { DetailsComponent } from 'src/app/folder/details/details.component';
import { LookUpDetailsService } from 'src/app/Services/look-up-details.service';
import { Subscription } from 'rxjs';
import { AlertComponent } from 'src/app/alert/alert.component';
import { TreeItemDropEvent, DropPosition, TreeItemLookup, DropAction } from '@progress/kendo-angular-treeview';
import { ContextMenuComponent } from '@progress/kendo-angular-menu';
import { MenuserviceService } from 'src/app/Services/menuservice.service';
import { ToastrService } from 'ngx-toastr';
import { EnvService } from 'src/env.service';
import { PenchannelListComponent } from 'src/app/SharedCommonModule/penchannel-list/penchannel-list.component';
import { PenChannelService } from 'src/app/Services/pen-channel.service';
import { Users } from 'src/Models/users';
import { Pendoclock } from 'src/app/Models/pendoclock';
import { FileService } from 'src/app/Services/file.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { Location } from "@angular/common";
import { AlertService } from '@full-fledged/alerts';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAuthService } from 'src/app/Services/google-auth.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
const isOfType = (fileName: string, ext: string) => new RegExp(`.${ext}\$`).test(fileName);
const isFile = (name: string) => name.length > 1;//name.split('.')

@Component({
  selector: 'app-accordian-main-menu',
  templateUrl: './accordian-main-menu.component.html',
  styleUrls: ['./accordian-main-menu.component.scss']
})
export class AccordianMainMenuComponent implements OnInit, OnChanges {
  @Input() options;
  @Input() menus: Menu[];
  @Input() collpaseClick: boolean;
  @Output() menuView: EventEmitter<boolean> = new EventEmitter();
  showfullmenu: boolean = false;
  dummymenu: any;
  @Input() menus1: Menu1[];
  @ViewChild('treemenu') public treeContextMenu: ContextMenuComponent;
  @ViewChild('TreeView') public stepsTree: ElementRef;
  favList: Favorites[] = [];
  favList1: Favorites[] = [];
  removeitem = false;
  canacess: boolean = false;
  public currentPermissionId: any;//By Case id-339
  // {PMF_UserID: 1, PMF_SubMenuId: 'Payment', PMF_MainMenuId: 'Supplier'},
  // {PMF_UserID: 1, PMF_SubMenuId: 'AirTktrptPNR', PMF_MainMenuId: 'Reports'},
  // {PMF_UserID: 1, PMF_SubMenuId: 'SalesListing', PMF_MainMenuId: 'Reports'},
  // {PMF_UserID: 1, PMF_SubMenuId: '', PMF_MainMenuId: 'Utilities'}];
  favorite: Favorites = {
    PMF_UserID: 1,
    PMF_SubMenuId: '',
    PMF_MainMenuId: '',
    addmenu: false
  };
  private currentUser: Users;
  menuList: Menu[];
  result: Menu[];
  tempmenu: Menu;
  tempsub: Menu;//13097
  tempsubmenu: any = [];
  addmenu = false;
  public UID: any;
  config: Config;
  public elseBlock: any;
  @Output() messageToEmit = new EventEmitter<string>();
  @Output() pageHeader = new EventEmitter<string>();
  publicLayoutComponent: PublicLayoutComponent;
  translate: TranslateService;
  router: Router;
  a: number = 1;
  private subscription: Subscription = new Subscription();
  commonservice: CommonserviceService;
  logoutService: LoginService;
  spinner: NgxSpinnerService;
  route: ActivatedRoute;
  inbox: number = 0;
  Reports: any = 'Reports'
  header: any = 'Reports'
  Searchplaceholder: any = 'Search'
  Shared: ShareDataService;
  penLock: PendoclockService;
  penLock1: PendoclockService;
  interceptor: L10nInterceptor;
  DetailsComponent: DetailsComponent;
  trasilatesettings: TranslateStore; private http: HttpClient;
  penchannelApi: PenChannelService
  confirmMsg: any;
  public inboxdata: any[] = [];
  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339
  public showMsg: any;//case id-355
  penDockLock: Pendoclock = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {

      LDocType: "",
      LDocNo: "",
      UID: 0,
      LDATE: "",
      LTIME: "",
      LPROJNO: "",
      LYearID: "",
      LAutoNo: "",
      Uname: "",
      Source: ""

    }
  };
  public items: any[] = [{ text: 'Remove', icon: 'close', id: 'Remove' }];
  private contextItem: any;
  favmenu: any[] = [];

  submenuList: any[] = [];
  kendo: any = false;
  public alertReload = false;
  public menuSearch = false;
  constructor(private intl: IntlService, private menu: MenuserviceService, private tl: TranslateService, private spinner1: NgxSpinnerService, private apiShared: ShareDataService,
    public common: CommonserviceService, private lookupApi: LookUpDetailsService,
    private _router: Router, private toastr: ToastrService,
    public login: LoginService, public file: FileService, private env: EnvService, private location: Location, private googleauthservice: GoogleAuthService,
    private dialog: MatDialog,
    private alertService: AlertService) {
    this.publicLayoutComponent = new PublicLayoutComponent(this.intl, this.http, this.trasilatesettings, this.commonservice, this.router, this.logoutService, this.route, this.translate, this.spinner, this.Shared, this.penchannelApi, this.penLock, this.penLock1, this.toastr, this.file, this.env, this.googleauthservice, this.dialog, this.alertService);
    this.UID = this.apiShared.uid;
  }

  ngOnInit() {

    this.config = this.mergeConfig(this.options);
    this.subscription.add(this.tl.get(['Labels.search'],).subscribe((translations) => {
      this.Searchplaceholder = translations['Labels.search'] + '..';
    }));
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.menuList = this.menus;
    // this.getinboxlist();

    this.getmenuStatus();
    //this.getFavorites();
  }
  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }
  loading = false;
  getinboxlist(node) {
    this.handleCollapse(node);
    this.loading = true;
    this.subscription.add(this.lookupApi.GetMenuListforInbox().subscribe(
      (data) => {
        this.inboxdata = data["ResponseData"]
        for (var obj of this.menus) {
          if (obj.id == 'Alerts') {
            obj.submenu = data["ResponseData"];
            this.loading = false;
          }
        }
        this.handleExpand(node);
        if (window.location.href.includes('/PenAir/Folder/Index')) {
          this.menus[0].active = false;
          this.menus[1].active = true;
          this.inbox = 1;
        }

      }
    ));

  }
  getFavorites() {

    this.favmenu = [];
    this.favList = [];
    this.subscription.add(this.menu.getFavoriteMenuList().subscribe(
      (data) => {
        if (data !== null && data !== undefined) {
          this.favList = data["ResponseData"];
          //{userId: 1, submenuid: "Payment", mainmenuid: ""},
          const myClonedArray = [];
          this.menuList.forEach(val => myClonedArray.push(Object.assign({}, val)));
          for (var obj of myClonedArray) {
            this.tempmenu = null;
            this.tempsub = null;//13097
            this.tempsubmenu = [];
            for (var fav of this.favList) {
              if (fav.PMF_MainMenuId != '' && fav.PMF_SubMenuId == '') {

                if (obj.id == fav.PMF_MainMenuId) {
                  obj.isFavorite = true;
                  this.favmenu.push(obj);
                }
              }
              else {
                if (obj.id !== 'Favorites') {
                  if (obj.submenuexists)
                    this.tempmenu = obj;
                  for (var obj1 of obj.submenu) {
                    if (obj1.id == fav.PMF_SubMenuId) {
                      obj1.isFavorite = true;
                      this.tempsubmenu.push(obj1);
                    }
                    // 13097
                    else if (obj1.submenuexists) {
                      this.tempsub = obj1
                      for (var obj3 of obj1.submenu) {
                        if (obj3.id == fav.PMF_SubMenuId) {
                          obj3.isFavorite = true;
                          this.tempsubmenu.push(obj3)
                        }
                        else if (obj3.submenuexists) {
                          for (var obj4 of obj3.submenu) {
                            if (obj4.id == fav.PMF_SubMenuId) {
                              obj4.isFavorite = true;
                              this.tempsubmenu.push(obj4)
                            }
                          }
                        }
                      }
                    }
                    // 13097
                  }
                }
              }

            }
            if (this.tempsubmenu.length > 0) {
              this.tempmenu.submenu = this.tempsubmenu;
              this.favmenu.push(this.tempmenu);
            }
          }
          for (var obj2 of this.menus) {
            if (obj2.id == 'Favorites') {
              obj2.submenu = this.favmenu;
              if (this.removeitem == true) {
                this.reload('0');
              }
            }
          }

          //this.reload();
        }
      }))


  }
  getmenuStatus() {

    if (this.router == undefined && this.menus != undefined) {
      this.menus[0].active = false;
      // this.menus[1].active = true; // commented for hide menus
      this.inbox = 1;
    }
  }
  appInitializerFactory(translateService: TranslateService) {
    return () => {
      this.translate.setDefaultLang('en-US');
      this.translate.use('English')
      return translateService.use('en-US').toPromise();
    };
  }
  mergeConfig(options: Config) {
    // 기본 옵션
    const config = {
      // selector: '#accordion',
      multi: true
    };

    return { ...config, ...options };
  }
  public async onNodeClick(e: any) {
    this.apiShared.Setapishareddata(null, 'CustomerCode');
    sessionStorage.removeItem('IsDirectRefund');
    this.apiShared.Setapishareddata('', 'PassengerNames');
    this.apiShared.Setapishareddata('', 'projNo');
    this.apiShared.Setapishareddata('', 'orderNo');
    if (this.menuSearch) {
      this.menus = this.menuList;
      this.menuSearch = false;
    }
    if (e.item.dataItem.id == "Search" && this.location.path().includes("/PenAir/SearchBy/")) {
      var submenuIndex = +(sessionStorage.getItem('searchmenuIndex'));
      if (submenuIndex != undefined) {
        return;
      }
    }
    if (e.item.dataItem.id == "Alerts") {
      this.getinboxlist(e.item);
    }
    if (!this.showfullmenu) {
      this.showfullmenu = true;
      this.menuView.emit(this.showfullmenu);

    }


    this.apiShared.Setapishareddata(null, 'CustomerCode');
    //let getmenuStatus=  this.login.GetPermission1(this.currentUser.ResponseData.UID);
    if (e.type === 'contextmenu') {
      const originalEvent = e.originalEvent;
      originalEvent.preventDefault()
      this.contextItem = e.item.dataItem;
      if (e.item.index.includes('_')) {
        const Parentindex: string = e.item.index.split('_', 1);
        if (this.menus[Parentindex].id === 'Favorites') {
          this.treeContextMenu.show({ left: originalEvent.pageX, top: originalEvent.pageY });
        }
      }
    }
    else {
      if (e.item.dataItem.submenuexists !== undefined && e.item.dataItem.submenuexists !== false) {
        this.handleExpand(e.item);
      }
      else {
        // this.showfullmenu = false;
        // this.menuView.emit(this.showfullmenu);
        let menuElement = document.getElementById("collapsibleNavbar");
        // menuElement.classList.remove("show");
      }
      if (e.item.dataItem.submenuexists == undefined || e.item.dataItem.submenuexists == false) {
        const Parentindex: string = e.item.index.split('_', 1);
        this.myMethod(this.menus[Parentindex].name, e.item.dataItem.name);
      }
      if (this.common.isfinalsaved === true || this.common.folderEditMode === 'true') {

        ///commented by case id-355
        //this.common.showFlash1('Messages.FolderOpen', 'bg-danger text-white', 2000);
        this.subscription.add(this.tl.get(['Messages.FolderOpen']).subscribe((translations) => {

          this.showMsg = translations['Messages.FolderOpen'];;
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        if (this.router != undefined)
          if (this.router.url != undefined && this.router.url != '')
            return this.router.navigateByUrl(this.router.url);
      }

      if (e.item.dataItem.ACTIONID !== undefined && e.item.dataItem.ACTIONID != null) {

        if (this.common.isfinalsaved === true || this.common.folderEditMode === 'true') {

        }
        else {
          this._router.navigate(['/PenAir/Folder/Index'], { queryParams: { p: this.a, actionid: e.item.dataItem.ACTIONID, userId: e.item.dataItem.SharedUserID, type: e.item.dataItem.Type } });
          this.a = this.a + 1;
        }
      }
      else {
        if (e.item.dataItem.submenuexists == undefined || e.item.dataItem.submenuexists == false) {
          if (e.item.dataItem.menuid != undefined && e.item.dataItem.menuid != null && e.item.dataItem.menuid != '') {
            if (Boolean(await this.common.checkPermission1(e.item.dataItem.menuid)) == true) {
              if (e.item.dataItem.id == "EmailInvoice") {
                this.getLockDetails("Email Invoice", "Email Invoice", this.currentUser.ResponseData.UID, "Email Invoice");
              }
              else {

                if (e.item.dataItem.routerLink != '' && e.item.dataItem.routerLink != undefined && e.item.dataItem.routerLink != '#') {
                  if (this.common.isfinalsaved === true || this.common.folderEditMode === 'true') {

                  }
                  else {
                    if (e.item.dataItem.id == 'DirectRefundFolder') {
                      sessionStorage.setItem('IsDirectRefund', '1')
                    }
                    this._router.navigate([e.item.dataItem.routerLink]);
                  }
                }
              }

            }
            else {
              this.showmsg(e.item.dataItem.menuid);
              // this.showfullmenu = true;
              // this.menuView.emit(this.showfullmenu);
            }
          }
          else {

            if (e.item.dataItem.id == "EmailInvoice") {
              this.getLockDetails("Email Invoice", "Email Invoice", this.currentUser.ResponseData.UID, "Email Invoice");
            }
            else {

              if (e.item.dataItem.routerLink != '' && e.item.dataItem.routerLink != undefined && e.item.dataItem.routerLink != '#') {
                if (this.common.isfinalsaved === true || this.common.folderEditMode === 'true') {

                }
                else {
                  if (e.item.dataItem.id == 'DirectRefundFolder') {
                    sessionStorage.setItem('IsDirectRefund', '1')
                  }
                  this._router.navigate([e.item.dataItem.routerLink]);
                }
              }
            }
          }

        }
        else {

          if (e.item.dataItem.id == "EmailInvoice") {
            this.getLockDetails("Email Invoice", "Email Invoice", this.currentUser.ResponseData.UID, "Email Invoice");
          }
          else {

            //if (e.item.dataItem.routerLink != '' && e.item.dataItem.routerLink != undefined)
            if (e.item.dataItem.submenuexists !== true) {
              if (e.item.dataItem.id == 'DirectRefundFolder') {
                sessionStorage.setItem('IsDirectRefund', '1')
              }
              this._router.navigate([e.item.dataItem.routerLink]);
            }
          }
        }
      }
    }
    this.getDateandCurrencyFormat();


    if (e.item.dataItem.mainmenuid != undefined) {
      this.subscription.add(this.tl.get([e.item.dataItem.mainmenuid],).subscribe((translations) => {
        this.publicLayoutComponent.headerText = translations[e.item.dataItem.mainmenuid];
        if (e.item.dataItem.submenuid != undefined) {
          this.subscription.add(this.tl.get([e.item.dataItem.submenuid],).subscribe((translations) => {
            this.publicLayoutComponent.headervalue = translations[e.item.dataItem.submenuid];
            this.publicLayoutComponent.headervalue = this.publicLayoutComponent.headerText + '/' + this.publicLayoutComponent.headervalue;

            this.publicLayoutComponent.headerlanguage = e.item.dataItem.submenuid;
            this.publicLayoutComponent.mainmenutransilate = e.item.dataItem.mainmenuid;
            sessionStorage.setItem('mainmenutransilate', this.publicLayoutComponent.mainmenutransilate)
            sessionStorage.setItem('headerlanguage', this.publicLayoutComponent.headerlanguage)
          }));

        }
        else {
          this.publicLayoutComponent.headervalue = "";
          this.publicLayoutComponent.headervalue = this.publicLayoutComponent.headerText;
          this.publicLayoutComponent.headerlanguage = ""
          this.publicLayoutComponent.mainmenutransilate = e.item.dataItem.mainmenuid;
          sessionStorage.setItem('mainmenutransilate', this.publicLayoutComponent.mainmenutransilate)
          sessionStorage.setItem('headerlanguage', this.publicLayoutComponent.headerlanguage)
        }

      }));
    }
  }
  getDateandCurrencyFormat() {
    this.subscription.add(this.common.GetDefaultCurrencyAndDate().subscribe(
      (data) => {
        if (data != null && data != undefined) {
          if (data["ResponseData"] != null && data["ResponseData"] != undefined) {

            this.common.cdateformat = data["ResponseData"]["DateFormat"];
            if (this.common.cdateformat == null || this.common.cdateformat == '' || this.common.cdateformat == undefined) {
              this.common.cdateformat = 'dd/MMM/yyyy';
            }
            this.common.DecimalCount = data["ResponseData"]["DecimalCount"];
            if (this.common.DecimalCount == null || this.common.DecimalCount == '' || this.common.DecimalCount == undefined) {
              this.common.DecimalCount = 'n2';
            }
            else {
              this.common.DecimalCount = 'n'.concat(this.common.DecimalCount);
            }

            this.common.Culture = data["ResponseData"]["Culture"];

            if (this.common.Culture == null || this.common.Culture == '' || this.common.Culture == undefined) {
              this.common.Culture = 'en-GB';
              this.intl["localeId"] = 'en-GB';
            }
            else {
              this.intl["localeId"] = data["ResponseData"]["Culture"];
            }

          }
          else {
            this.intl["localeId"] = 'en-GB';
            this.common.Culture = 'en-GB';
            this.common.DecimalCount = 'n2';
            this.common.cdateformat = 'dd/MMM/yyyy';
          }
        }



      }
    ));
    //}
  }
  getLockDetails(docType, docNo, userId, projno) {
    this.subscription.add(this.login.getLock(docType, docNo, userId, projno)
      .subscribe(data => {
        this.penDockLock = data;
        if (this.penDockLock.ResponseData != null) {

          if (this.penDockLock.ResponseData.LAutoNo != null) {

            this.apiShared.Setapishareddata("1", 'EmailInvoice');
            this.publicLayoutComponent.lockStatus = "1";
            this.subscription.add(this.tl.get(['Messages.cannot_continue']).subscribe((translations) => {
              this.confirmMsg = translations['Messages.cannot_continue'].replace("<<1>>", "Email Invoice").replace("<<2>>", this.penDockLock.ResponseData.Source).replace("<<3>>", this.penDockLock.ResponseData.Uname)
              this.toastr.warning(this.confirmMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,

                closeButton: true
              });

            }));


            this._router.navigate(['PenAir/Home']);

          }
          else {

            this.apiShared.Setapishareddata("0", 'EmailInvoice');
            this.publicLayoutComponent.lockStatus = "0";

            this._router.navigate(['/EmailInvoice/EmailInvoice']);
          }
        }
        else {
          this.spinner1.hide();

          this.publicLayoutComponent.lockStatus = "0";

          this.apiShared.Setapishareddata("0", 'EmailInvoice');

          this._router.navigate(['/EmailInvoice/EmailInvoice']);
        }

      }));
    return this.publicLayoutComponent.lockStatus;
  }
  showmsg(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
    //this.common.showPermissiontoastrmsg("Messages.Permissiondenied", 5000, true, false, permissionid);
  }

  toggle(index: number, e, id: number) {

    if (id == 1) { index = 1; }
    //
    var na = (e.target as Element).className
    this.kendo = na.includes('fa fa-chevron-down ng-star-inserted')
    const mainmenu = na.includes('menu')
    const kendoi = na.includes('k-icon')
    // if(this.kendo==false)
    // {
    //   this. kendo=mainmenu;
    // }
    if (index == 1) {
      if ((kendoi && this.inbox == 1) || (this.kendo && this.inbox == 1) || (mainmenu && this.inbox == 1)) {
        this.inbox = 0;
      }
      else if ((kendoi && this.inbox == 0) || (this.kendo && this.inbox == 0) || (mainmenu && this.inbox == 0)) {
        this.inbox = 1;
      }
      if (kendoi) {
        this.inbox = 1;
      }
    }
    else {
      this.inbox = 0;
    }
    if (!this.config.multi) {
      this.menus.filter(
        (menu, i) => i !== index && menu.active
      ).forEach(menu => menu.active = !menu.active);
    }

    // Menu의 active를 반전
    this.menus[index].active = !this.menus[index].active;
    // this.publicLayoutComponent.headerText=this.menus[index].name;
    //this.messageToEmit.emit(this.menus[index].name);
  }

  myMethod(menu: string, obj: string) {

    this.messageToEmit.emit(obj);
    if (menu == obj)
      this.pageHeader.emit(menu);
    else
      this.pageHeader.emit(menu + '/' + obj);
    //this.publicLayoutComponent.changeheader(obj);
    //this.publicLayoutComponent.headerText=obj;
  }
  MenuFilerSearch(menuObj, value) {
    if (menuObj.submenu != null && menuObj.submenu.length > 0) {
      for (var obj1 of menuObj.submenu) {
        this.MenuFilerSearch(obj1, value);
      }
    } else {
      if (menuObj.name.toLowerCase().includes(value.toLowerCase())) {
        //objadded = 1;
        this.result.push(menuObj);
      }
    }

  }
  changefn(value) {
    if (value != undefined && value != null && value != '') {
      if (this.menuList === undefined) {
        this.menuList = this.menus;
      }
      this.result = [];
      for (var obj of this.menuList) {
        var objadded = 0;
        if (obj.id != 'Favorites') {
          this.MenuFilerSearch(obj, value);
        }
      }
      this.menus = this.result;
      if (this.menus.length > 0)
        this.menus[0].active = true;
      this.menuSearch = true;
    }
    else {
      this.menuSearch = false;
      this.menus = this.menuList;
    }

  }
  public getDragStatus(action: DropAction, destinationItem: TreeItemLookup): string {
    if ((destinationItem && (action === DropAction.InsertTop || action === DropAction.InsertBottom || action === DropAction.InsertMiddle)
      && isFile(destinationItem.item.dataItem.name))) {
      return 'k-i-cancel';
    }

    switch (action) {
      case DropAction.Add: return 'k-i-plus';
      case DropAction.InsertTop: return 'k-i-insert-up';
      case DropAction.InsertBottom: return 'k-i-insert-down';
      case DropAction.InsertMiddle: return 'k-i-insert-middle';
      case DropAction.Invalid:
      default: return 'k-i-cancel';
    }
  }
  public log(event: string, args?: any): void {
    // console.log(event, args);
  }

  public handleDrop(event: TreeItemDropEvent): void {

    this.log('nodeDrop', event);

    // prevent drop if attempting to add to file
    if (event.destinationItem.parent != null) {
      if (event.destinationItem.parent.item.dataItem.id !== 'Favorites' || (isFile(event.destinationItem.item.dataItem.id) && event.dropPosition !== DropPosition.Over)) {
        event.setValid(false);
      } else {
        this.addFavItem(event);
        //this.favList.push(event.destinationItem.parent.item.dataItem);
      }
    } else {
      if (event.destinationItem.item.dataItem.id !== 'Favorites' || (isFile(event.destinationItem.item.dataItem.id) && event.dropPosition !== DropPosition.Over)) {
        event.setValid(false);
      }
      else {
        this.addFavItem(event);
        //this.favList.push(event.destinationItem.item.dataItem);
      }
    }
  }
  addFavItem(event: any) {
    //this.dummymenu=this.menu;
    this.favorite.PMF_UserID = this.UID;
    if (event.sourceItem.item.dataItem.submenuexists != undefined) {
      this.favorite.PMF_MainMenuId = event.sourceItem.item.dataItem.id;
      this.favorite.PMF_SubMenuId = '';
    } else {
      this.favorite.PMF_MainMenuId = event.sourceItem.item.dataItem.pid;
      this.favorite.PMF_SubMenuId = event.sourceItem.item.dataItem.id;
    }
    if (this.favList.filter(obj => (obj.PMF_SubMenuId === this.favorite.PMF_SubMenuId && obj.PMF_MainMenuId === this.favorite.PMF_MainMenuId)
      || (obj.PMF_SubMenuId === '' && obj.PMF_MainMenuId === this.favorite.PMF_MainMenuId)).length > 0) {
      event.setValid(false);
    }
    else {
      if (this.favorite.PMF_SubMenuId == '' && this.favorite.PMF_MainMenuId !== '') {
        this.favList1 = [];
        const myClonedArray = [];
        this.menuList.forEach(val => myClonedArray.push(Object.assign({}, val)));
        for (var objArr of myClonedArray) {
          this.getDataFav(objArr);
        }

        if (this.addmenu === true) {
          this.subscription.add(this.menu.addFavoriteMenu(this.favList1).subscribe(
            (data) => {
            }));
          this.addmenu = false;
        }
        else {
          event.setValid(false);
        }
      }
      else {
        const submenuList = [];
        //this.submenuList=[];
        for (var objmL of this.menuList) {
          if (objmL.id == this.favorite.PMF_MainMenuId) {
            //this.submenuList=objm.submenu;
            objmL.submenu.forEach(val => submenuList.push(Object.assign({}, val)));
          }
        }
        this.favList1 = [];
        this.favList1.push(this.favorite);
        this.favList.push(this.favorite);
        this.subscription.add(this.menu.addFavoriteMenu(this.favList1).subscribe(
          (data) => {
            for (var objm of this.menuList) {
              if (objm.id == this.favList1[0].PMF_MainMenuId) {
                //objm.submenu= this.submenuList;
                objm.submenu = submenuList;
              }
            }
            for (var objdm of this.dummymenu) {
              if (objdm.id == this.favList1[0].PMF_MainMenuId) {
                //objm.submenu= this.submenuList;
                objdm.submenu = submenuList;
              }
            }

            //this.getFavorites();
          }));
      }
    }
    this.favorite = {
      PMF_UserID: this.UID,
      PMF_SubMenuId: '',
      PMF_MainMenuId: '',
      addmenu: false
    };
  }
  getDataFav(menuObj) {
    if (menuObj.id === this.favorite.PMF_MainMenuId) {
      if (menuObj.submenu.length > 0) {
        for (var obj1 of menuObj.submenu) {
          if (this.favList.filter(obj => (obj.PMF_SubMenuId === obj1.id
            && obj.PMF_MainMenuId === obj1.pid)).length == 0) {
            this.addmenu = true;
            //13097
            if (!menuObj.pid) {
              this.favorite.PMF_MainMenuId = menuObj.id;
              this.favorite.PMF_SubMenuId = '';
            } else {
              this.favorite.PMF_MainMenuId = menuObj.pid;
              this.favorite.PMF_SubMenuId = menuObj.id;
            }
            //13097
            this.favorite.PMF_MainMenuId = obj1.pid;
            this.favorite.PMF_SubMenuId = obj1.id;
            this.favList1.push(this.favorite);
            this.favList.push(this.favorite);
            this.favorite = {
              PMF_UserID: this.UID,
              PMF_SubMenuId: '',
              PMF_MainMenuId: '',
              addmenu: false
            };
          }
        }
      } else {

        if (this.favList.filter(obj => (obj.PMF_SubMenuId === menuObj.id
          && obj.PMF_MainMenuId === menuObj.pid)).length == 0) {
          if (!menuObj.pid) {
            this.addmenu = true;
            this.favorite.PMF_MainMenuId = menuObj.id;
            this.favorite.PMF_SubMenuId = "";
            this.favList1.push(this.favorite);
            this.favList.push(this.favorite);
            this.favorite = {
              PMF_UserID: this.UID,
              PMF_SubMenuId: '',
              PMF_MainMenuId: '',
              addmenu: false
            };
          } else {
            this.addmenu = true;
            this.favorite.PMF_MainMenuId = menuObj.pid;
            this.favorite.PMF_SubMenuId = menuObj.id;
            this.favList1.push(this.favorite);
            this.favList.push(this.favorite);
            this.favorite = {
              PMF_UserID: this.UID,
              PMF_SubMenuId: '',
              PMF_MainMenuId: '',
              addmenu: false
            };
          }

        }
      }

    }
    else {

      if (menuObj.submenuexists && menuObj.submenu.length > 0) {
        const subMenuArray = [];
        menuObj.submenu.forEach(val => subMenuArray.push(Object.assign({}, val)));
        for (var subArr of subMenuArray) {
          this.getDataFav(subArr);
        }
      }
    }
  }

  public keys: string[] = [];
  public isExpanded = (dataItem: any, index: string) => {
    return this.keys.indexOf(index) > -1;
  }

  /**
  * A `collapse` event handler that will remove the node hierarchical index
  * from the collection, collapsing its children.
  */
  public handleCollapse(node) {
    this.keys = this.keys.filter(k => k !== node.index);
  }

  /**
  * An `expand` event handler that will add the node hierarchical index
  * to the collection, expanding the its children.
  */
  public handleExpand(node) {
    if (!(node.dataItem.id == 'Alerts' && this.loading)) {
      this.keys = this.keys.concat(node.index);
    }
  }

  public async onSelect({ item }): Promise<void> {

    if (item.text === 'Remove') {
      var result = await this.removeItem(this.contextItem, this.favmenu);
      this.getFavorites();
    }
  }
  public reload(index): void {

    const confectionsIndex = index;
    this.keys = this.keys.filter(k => k !== confectionsIndex);
    setTimeout(() => {
      //this.keys = [...this.keys, confectionsIndex];
      this.keys = [confectionsIndex];
    });
    this.removeitem = false;
  }
  private async removeItem(dataItem: any, items: any[]) {

    this.removeitem = true;
    this.favList1 = [];
    this.favorite = {
      PMF_UserID: this.UID,
      PMF_SubMenuId: '',
      PMF_MainMenuId: '',
      addmenu: false
    };

    if (dataItem.submenuexists != undefined && dataItem.submenuexists != false) {

      if (!dataItem.pid) {
        var removeFavList: any = [];
        if (this.favList.find(x => x.PMF_MainMenuId == dataItem.id)) {
          this.favorite.PMF_MainMenuId = dataItem.id;
          removeFavList.push(this.favorite);
          this.favorite = {
            PMF_UserID: this.UID,
            PMF_SubMenuId: '',
            PMF_MainMenuId: '',
            addmenu: false
          };
        }
        else {
          var submenu = dataItem.submenu.map(item => item.pid)
            .filter((value, index, self) => self.indexOf(value) === index)
          if (submenu.length > 0) {
            submenu.forEach(element => {
              this.favorite.PMF_MainMenuId = element;
              removeFavList.push(this.favorite);
              this.favorite = {
                PMF_UserID: this.UID,
                PMF_SubMenuId: '',
                PMF_MainMenuId: '',
                addmenu: false
              };
            });
          }
        }
        var result = await this.removeFav(removeFavList)
      } else {
        for (var fav of this.favList) {
          var removeFavList: any = [];
          if (fav.PMF_MainMenuId == dataItem.id || fav.PMF_SubMenuId == dataItem.id) {
            removeFavList.push(fav);
            break;
          }

        }
        var result = await this.removeFav(removeFavList)
      }

    } else {
      if (!dataItem.pid) {
        var removeFavList: any = [];
        this.favorite.PMF_MainMenuId = dataItem.id;
        this.favorite.PMF_SubMenuId = "";
        removeFavList.push(this.favorite);
        this.favorite = {
          PMF_UserID: this.UID,
          PMF_SubMenuId: '',
          PMF_MainMenuId: '',
          addmenu: false
        };
        var result = await this.removeFav(removeFavList);
      }
      else {
        var removeFavList: any = [];
        this.favorite.PMF_MainMenuId = dataItem.pid;
        this.favorite.PMF_SubMenuId = dataItem.id;
        removeFavList.push(this.favorite);
        this.favorite = {
          PMF_UserID: this.UID,
          PMF_SubMenuId: '',
          PMF_MainMenuId: '',
          addmenu: false
        };
        var result = await this.removeFav(removeFavList);
      }

    }
  }

  async getRemov(event: any, fav) {
    var count = event.length;
    var removeFavList: any = [];
    for (var i = 0; i <= count; i++) {
      if (fav.id == event[i].PMF_MainMenuId) {
        this.favorite.PMF_MainMenuId = event[i].pid;
        removeFavList.push(this.favorite);
      }
    }
    var result = await this.removeFav(removeFavList);
    if (event.submenuexists) {
      var submenu = event.submenu
      this.getRemov(submenu, fav);
    }
  }

  async removeFav(event) {

    var result = await this.menu.deleteFavoriteMenu(event).then(
      (data) => {

        this.keys = [];
        for (var obj2 of this.menus) {
          if (obj2.id == 'Favorites') {
            var favsubmenu = obj2.submenu;
          }
        }
        this.menuList = [];
        this.dummymenu.forEach(val => this.menuList.push(Object.assign({}, val)));
        for (var obj of this.menuList) {
          if (obj.id == 'Alerts') {
            obj.submenu = this.inboxdata;
          }
          if (obj.id == 'Favorites') {
            obj.submenu = favsubmenu;

          }
        }
        this.menus = this.menuList;
        //this.getFavorites();
        //this.reload();
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes["collpaseClick"] != undefined) {
      this.showfullmenu = changes["collpaseClick"].currentValue;
      //this.getFavorites();

    }
    if (changes["menus"] != undefined) {
      if (changes["menus"].currentValue !== undefined) {
        this.menuList = changes["menus"].currentValue;
        const dummy = [];
        changes["menus"].currentValue.forEach(val => dummy.push(Object.assign({}, val)));
        this.dummymenu = dummy;
        //this.getinboxlist();
        this.getFavorites();
        this.subscription.add(this.tl.get(['Labels.search'],).subscribe((translations) => {
          this.Searchplaceholder = translations['Labels.search'] + '..';
        }));
      }
    }
    // console.log(this.dummymenu);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}


