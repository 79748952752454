import {
  Component,
  ViewEncapsulation,
  OnInit,
  Input,
  ViewChild,
} from "@angular/core";
import { map } from "rxjs/operators";
import { LookUpDetailsService } from "../../Services/look-up-details.service";

import { ActivatedRoute, Router } from "@angular/router";

import { Users } from '../../../Models/users';

import { EnvService } from "../../../env.service";
import { TelerikReportViewerComponent } from "@progress/telerik-angular-report-viewer";
import { L10nInterceptor } from "../../_helpers/l10n.interceptor";
import { ShareDataService } from "../../Services/share-data.service";
import { JsonpInterceptor } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-reportform',
  templateUrl: './reportform.component.html',
  styleUrls: ['./reportform.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportformComponent implements OnInit {

  @ViewChild("reportViewer", { static: true }) reportViewer: TelerikReportViewerComponent;

  public windowWidth = 100;
  public windowHeight = 600;
  viewerContainerStyle = {
    position: 'relative',
    width: window.innerWidth,
    // width: (100)+"%",
    // height: this.windowHeight+"px",//(document.documentElement.scrollHeight-150)+"px",
    // width: this.windowWidth+"px",//(document.documentElement.scrollWidth-50)+"px",
    height: 100 + "%",
    padding: 150,
    ["font-family"]: "tahoma",
  };

  @Input() reportFileName: string;
  @Input() reportParmeters: any;
  @Input() reportSubjectName: string;
  @Input() fromId: string;
  @Input() bccId: string;
  @Input() emaildisbled: boolean;
  @Input() toemail: string;
  @Input() ccemail: string;
  finacialdata: Users;
  public selectedLang: any = "de-DE"; // initial value = english
  token: string;

  public langSelected: any;
  reportUrl: string;
  constructor(
    private spinner: NgxSpinnerService,
    private reportViewer1: TelerikReportViewerComponent,
    private apiShared: ShareDataService,
    private interceptor: L10nInterceptor,
    private env: EnvService,
    private lookupApi: LookUpDetailsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.reportUrl = this.env.ReportServiceURI;
    if (sessionStorage.getItem('isWebLink') == 'true') {
      this.token = '';
      this.emaildisbled = false;
    }
    else {
      this.finacialdata = JSON.parse(localStorage.getItem("currentUser"));
      this.token = this.finacialdata.ResponseData.Token;
    }

  }



  ngOnInit() {
    debugger;
    if (this.emaildisbled == undefined) {
      this.emaildisbled = true;
    }
    this.langSelected = this.apiShared.LanguageSelected;

    if (this.langSelected == 'English') {
      this.selectedLang = 'en'
      this.interceptor.intercept(() => this.selectedLang);
    }
    if (this.langSelected == 'Chinese') {
      this.selectedLang = 'zh'
      this.interceptor.intercept(() => this.selectedLang);
    }

    if (this.langSelected == 'French') {
      this.selectedLang = 'fr'
      this.interceptor.intercept(() => this.selectedLang);
    }


  }
  onModelChange() {
    this.reportViewer.refreshReport();
  }
}
