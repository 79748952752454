import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EnvService } from 'src/env.service';
import { PermissionMessageDTO } from '../Models/permission-message-dto.model';
import { UserAuthorisationDTO } from '../Models/user-authorisation-dto.model';
import { CommonserviceService } from './commonservice.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};


@Injectable({
  providedIn: 'root'
})
export class PermissionMessageService {
  apiUrl :string;
  public formData:UserAuthorisationDTO;

  constructor(private http: HttpClient,private env: EnvService,private commonservice:CommonserviceService) { 
    this.apiUrl=env.baseUrl;
  }

  public SendModuleAuthorizationMail(): Observable<UserAuthorisationDTO> {
    var body = {
          ...this.formData,
    };
    return this.http.post<UserAuthorisationDTO>(this.apiUrl+'RequestForAuthorization', body, httpOptions).pipe(
      tap((UserAuthorisationDTO: any) => console.log(`Request For Authorization`)),
      catchError(this.commonservice.handleError<any>('Request For Authorization'))
    );
  }
}
