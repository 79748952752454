import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LookUpDetailsService } from 'src/app/Services/look-up-details.service';
import { Subscription, Observable } from 'rxjs';
import { LookUpDetails } from 'src/app/Services/look-up-details';
import { HotelRoomService } from 'src/app/Services/hotel-room.service';
import { EnvService } from 'src/env.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { CurrencyExchangerateService } from 'src/app/Services/currency-exchangerate.service';


import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';



import { map } from 'rxjs/operators';
import { PriceDetailsService } from 'src/app/Services/price-details.service';
import { Hote103DTO, Hote102DTO } from 'src/app/Models/hote102-dto.model';
import { CommonserviceService } from 'src/app/Services/commonservice.service';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import { HotelMenuComponentComponent } from '../hotel-menu-component/hotel-menu-component.component';

import { debug } from 'util';
class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'app-hotel-ratesetup-edit-component',
  templateUrl: './hotel-ratesetup-edit-component.component.html',
  styleUrls: ['./hotel-ratesetup-edit-component.component.scss']
})
export class HotelRatesetupEditComponentComponent implements OnInit {
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  public value1: any=null;
  public value0: any=null;
  public showMsg:any;
  selectedFile: ImageSnippet;
  public imagePath;
  filedata:any;
  public submitted=false;
  imgURL: any;
  public message: string;
  id: number;
  imagename:any;
  imgdelete:any=false;
  private sub: any;
 

    opened:any= false;
  
  haveimgurl:any;
  allowCustom:any;
  private subscription: Subscription = new Subscription();
  roomdetailform:FormGroup;
  roomtypeList:Array<LookUpDetails["ResponseData"]> = [];
  public roomSelectedItem: LookUpDetails["ResponseData"];
  public roomSelected:String;

  public projctList:Array<LookUpDetails["ResponseData"]> = [];
  public projectSelectedItem: LookUpDetails["ResponseData"];
  public projectSelected:String;

  public pricegroupmasterlist:Array<LookUpDetails["ResponseData"]> = [];
  public pricegroupmasterselectedItem: LookUpDetails["ResponseData"];
  public pricegroupmasterSelected:String;
  url:string;
  public RoomList: any[];
  public listItems: Array<any> = [0, 1, 2, 3, 4, 5, 6, 7, 8,9,10 ];
  value=0;
  public dtpto:DatePickerComponent;
  public dtp:DatePickerComponent;
  public ddlm:DatePickerComponent;
  showFilter=false;
  roomid:any=0;
  displayimg:any;
  foldercurr:any;
  element:any
  SupplierList: Array<LookUpDetails['ResponseData']> = [];
  add=false;
  BOOKEBYList: Array<LookUpDetails["ResponseData"]> = [];
    public bookbyselectedItem: LookUpDetails["ResponseData"];
    public bookedbySelected:String;

    currencyList: Array<LookUpDetails["ResponseData"]> = [];
  public currencySelectedItem: LookUpDetails["ResponseData"];
  public currencySelected:String;

  public supplierSelectedItem: LookUpDetails['ResponseData'];
  public supplierSelected: String;
  public autoCorrect = true;
  hotelnames:any;
  chkcomm:any=false;
  public view: Observable<any>;
    public gridState: State = {
        sort: [],
        skip: 0,
        take: 10
    };

    public changes: any = {};
    homecuuency:any;
  constructor(private menu:HotelMenuComponentComponent, public comm:CommonserviceService, public editService:PriceDetailsService,public router:Router ,private apiExRate:CurrencyExchangerateService,private sanitizer: DomSanitizer,private spinner:NgxSpinnerService,private toastr:ToastrService,private translateapi: TranslateService,private env: EnvService,private http: HttpClient,public api:HotelRoomService, public route:ActivatedRoute,private formBuilder: FormBuilder,private lookupApi:LookUpDetailsService) { 
    this.url = env.baseUrl;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      
      this.id = +params['hodelcode']; 
this.roomid=+params['roomid']; 
this.menu.hid=this.id;
sessionStorage.setItem('menuvalue', 'true')
this.menu.GetHotelname(this.id);   
   });
 
   this.roomdetailform=this.formBuilder.group({
    RRID :[],
    HID :[],
    PRTYCODE :['',Validators.required],
    CID :['',Validators.required],
    HIDP :[],
    Commission :[],
    CRDATE:[new Date()],
    UID :[],
    LUPDATE :[],
    PID :[],
    Rep :[],
    ExRate :[],
    Hotelname:[],
    CommionOnGross:[false],
    PricingGroupID:[]
  })
  if(this.roomid>0)
{
  this.OnLoad();
  //this.OnLoadGrid();
 
}
else{
  this.subscription.add( this.api.GetHotelRoom(this.id.toString()).subscribe(
    (data) => {
      if(data !=null && data !=undefined ){
      this.roomtypeList= data["ResponseData"]    
      }
   
    }));
  this.loadmasterdata();
  this.OnLoadGridNOid()
}

    this.subscription.add( this.api.SearchHotelName(this.id.toString()).subscribe(
      (data) => {
        if(data !=null && data !=undefined ){ 
        this.roomdetailform.controls.Hotelname.setValue(data["ResponseData"][0].Name)
     this.hotelnames=this.roomdetailform.controls.Hotelname.value;
        }
      }));
    this.subscription.add( this.lookupApi.GetAllLookup('FilePriceDetails').subscribe(
      (data) => {
        if(data !=null && data !=undefined ){
        this.homecuuency= "("+data["ResponseData"][0].UsrCode +")"  
        }
     
      }));
  
      

  }
  onFilterClick()
  {
    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter? true :false; 
  }
  
  public open() {

    this.opened = true;
  }
  public close(status) {
    debugger;
      this.opened = false;
    }
  loadmasterdata()
  {
    this.subscription.add( this.lookupApi.GetAllLookup('CURRENCY').subscribe(data => {
      if(data !=null && data !=undefined ){
      this.currencyList= data["ResponseData"];
      
      if(this.roomdetailform.controls.CID.value!="" && this.roomdetailform.controls.CID.value!=null)
      {
          this.currencySelectedItem=this.currencyList.find(item => item.UsrCode ==this.roomdetailform.controls.CID.value);
          this.foldercurr="("+this.currencySelectedItem.UsrCode+")";
      }
    } 
     
    }));
    this.subscription.add( this.lookupApi.GetAllLookup('PROJECT').subscribe(
      (data) => {
        if(data !=null && data !=undefined ){
        this.projctList= data["ResponseData"]    
        
        if(this.roomdetailform.controls.PID.value!="")
        {
        this.projectSelectedItem =this.projctList.find(item => item.UsrCode ==this.roomdetailform.controls.PID.value);
        }
      }
      }));
      this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
        (data) => {
          if(data !=null && data !=undefined ){
        this.BOOKEBYList= data["ResponseData"]           
        if(this.roomdetailform.controls.Rep.value!="")
        {            
          this.bookbyselectedItem =this.BOOKEBYList.find(item => item.Name ==this.roomdetailform.controls.Rep.value);                   
                   
        }
      }
      }));
      this.subscription.add(this.lookupApi.GetAllLookup('PriceGroupMaster').subscribe(
        (data) => {
          if(data !=null && data !=undefined ){
        this.pricegroupmasterlist= data["ResponseData"]           
        if(this.roomdetailform.controls.PricingGroupID.value!="")
        {            
          this.pricegroupmasterselectedItem =this.pricegroupmasterlist.find(item => item.Code ==this.roomdetailform.controls.PricingGroupID.value);                   
                   
        }
      }
      }));

  }
  public loadSupplier() {
    this.subscription.add( this.lookupApi.GetAllLookup('SUPPLIERS').subscribe(
      (data) => {
        if(data !=null && data !=undefined ){
         this.SupplierList = data["ResponseData"];
        //// alert("dd"+this.carEditForm.controls.SUPPRTYCODE.value)     ;
         if (this.roomdetailform.controls.PRTYCODE.value != '') {

         this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.roomdetailform.controls.PRTYCODE.value);
         this.supplierSelected = this.supplierSelectedItem.Name;
         }
        }
      }
      ));
  }
  public BookedByValueChange(value: any): void {
    //console.log('selectionChange', value);
   
    
    if(value!=null && value!=undefined)
    {
     
      this.roomdetailform.controls.Rep.setValue(value.Name);
    
    }
    else
    this.roomdetailform.controls.Rep.setValue("");
  }
  ProjectGroupValueChange(value: any): void 
  {
    if(value!=null && value!=undefined)
    {
     
      this.roomdetailform.controls.PricingGroupID.setValue(value.Code);
    
    }
    else
    this.roomdetailform.controls.PricingGroupID.setValue("");
  }
  handleFiltersupplier(value) {
    
    if (value.length >= 3) {
  
      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
           this.SupplierList = data["ResponseData"]; 
        }
        ));
        // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
  }
  }
  public SupplierValueChange(value: any): void {
    
    this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);
    if (this.supplierSelectedItem != null) {
    
    this.roomdetailform.controls.PRTYCODE.setValue( this.supplierSelectedItem.Code);
  //  this.roomdetailform.controls.SUPPNAME.setValue( this.supplierSelectedItem.ActualName);

    } else {
      this.supplierSelected = '';
      this.roomdetailform.controls.PRTYCODE.setValue('');
   //   this.roomdetailform.controls.SUPPNAME.setValue('');

    }
   // this.carEditForm.get("suppUserCodetext").setValue( this.supplierSelectedItem.Name);
  }
  
  public CurrencyValueChange(value: any): void {
    debugger;
    if(value!=null && value!=undefined)
    {
      this.roomdetailform.controls.CID.setValue(value.UsrCode);
      this.foldercurr="("+value.UsrCode+")";
      this.getCurrencyExRate()
      
    }
       else
       {
        this.roomdetailform.controls.CID.setValue("");
        this.foldercurr="";
        this.roomdetailform.controls.ExRate.setValue(0);
        this.calculategridamt();
       }
    
  }
  removeimagedata(){
this.imgdelete=true;
  }
  confirmRoomRemove(shouldRemove: boolean) :void
  {
    if(shouldRemove==true)
    {
     //this.removeimg();
    }
    else{
      this.imgdelete=false;
    }
  }
public roomtypeValueChange(value: any): void {
    
  if(value!=null && value!=undefined)
  {
    this.roomdetailform.controls.TYPE.setValue(value.UsrCode);
    this.roomSelectedItem =this.roomtypeList.find(item => item.UsrCode ==this.roomdetailform.controls.TYPE.value);
    this.roomdetailform.controls.TYPEDesc.setValue(this.roomSelectedItem.Name);
  }

  else
  {
    this.roomSelectedItem=null;
    this.roomdetailform.controls.TYPE.setValue("");
    this.roomdetailform.controls.TYPEDesc.setValue("");
  }
  
}
public getCurrencyExRate() {

  this.getExchangeRate(this.roomdetailform.controls.CID.value,this.roomdetailform.controls.CRDATE.value)
  
}
getExchangeRate(currency,CRDATE)
{
  debugger;
  if(currency!=undefined&& currency!=""){
    
   // CRDATE= this.comm.ConvertToLocaleDate(CRDATE);
    // new Date(CRDATE).toLocaleDateString('en-GB', {
    //   day: 'numeric', month: 'short', year: 'numeric'
    // }).replace(/ /g, '-');
   // console.log("Crdate="+CRDATE);
   CRDATE= new Date(CRDATE);
  CRDATE=this.comm.ConvertToLocaleDate(CRDATE);


  
this.subscription.add(this.apiExRate.getExchangeRate(currency,CRDATE)
   .subscribe(data => {
    if(data !=null && data !=undefined ){  
   // console.log(this.currencyExrate.ResponseData.EXRATE);
    this.roomdetailform.controls.ExRate.setValue(data.ResponseData.EXRATE);  
    }
    this.calculategridamt();
    
  }));  
  }
}

public ProjectValueChange(value:any):void{
  if(value!=null && value!=undefined)
  {
    this.roomdetailform.controls.PID.setValue(value.UsrCode);
    this.projectSelectedItem =this.projctList.find(item => item.UsrCode ==this.roomdetailform.controls.PID.value);
  
  }

  else
  {
    this.roomSelectedItem=null;
    this.roomdetailform.controls.PID.setValue("");
    
  }
}
public OnLoadGrid()
{
  this.subscription.add(  this.api.LisPriceDetails(this.roomdetailform.controls.RRID.value,this.id)
  .subscribe(data => {
    this.editService.updatedItems=[];
    this.editService.createdItems=[];
    this.editService.deletedItems=[];
   this.editService.data=[];
   this.api.data=[];
   if(data !=null && data !=undefined ){
    this.api.data=data["ResponseData"] ;
    for(let result of this.api.data)
    {
     if(result.Comm==null ||result.Comm==''||result.Comm==undefined)
 {
   result.Comm=0;
 }
 //(Rate*100)/(100+comm%)
 debugger;
 if(this.chkcomm==true)
 {
   result.Buy=+result.Rate+result.Rate*result.Comm/100;
 }
 else{
   result.Buy=+result.Rate*100;
 
   result.Buy=result.Buy/(100+(+result.Comm));
 }
       if(this.roomdetailform.controls.ExRate.value==null || this.roomdetailform.controls.ExRate.value==undefined )
       {
        this.roomdetailform.controls.ExRate.setValue(0);
       }
       
       result.BuyHC=result.Buy*this.roomdetailform.controls.ExRate.value
       if(result.BuyHC==null||result.BuyHC==undefined)
       {
        result.BuyHC=0;
       }
       if(result.C1stMarkup==null||result.C1stMarkup==undefined)
       {
        result.C1stMarkup=0;
       }
       result.Sell= result.BuyHC+(+result.C1stMarkup);
       if(result.Tax==null||result.Tax==undefined)
       {
        result.Tax=0;
       }
       result.Sell=result.Sell+(+result.Tax)
    }
   }
   else{
    this.api.data=[];
   } 
    this.view = this.editService.pipe(map(data1 => process(data1, this.gridState)));
    this.editService.read();
   // this.RoomList = data["ResponseData"] ;           
  }));
}
public OnLoadGridNOid()
{
  this.editService.updatedItems=[];
  this.editService.createdItems=[];
  this.editService.deletedItems=[];
 this.editService.data=[];
 this.api.data=[];
    this.view = this.editService.pipe(map(data1 => process(data1, this.gridState)));
    
    this.editService.read();
  
}
public OnLoad()
{
  this.subscription.add(  this.api.LisPriceMaster(this.roomid,this.id)
  .subscribe(data => {
    
    if(this.hotelnames==null ||this.hotelnames==undefined)
    {
      this.hotelnames='';
    }
    if(data !=null && data !=undefined ){
    this.RoomList = data["ResponseData"] ;
    var dataf=this.RoomList[0]; 
    this.roomdetailform.setValue({
      RRID :dataf.RRID,
    HID :dataf.HID,
    PRTYCODE :dataf.PRTYCODE,
    CID :dataf.CID,
    HIDP :dataf.HID,
    Commission :dataf.Commission,
    CRDATE:[new Date()],
    UID :dataf.UID,
    LUPDATE :dataf.LUPDATE,
    PID :dataf.PID,
    Rep :dataf.Rep,
    ExRate :dataf.ExRate,
    Hotelname:this.hotelnames,
    CommionOnGross:dataf.CommionOnGross,
    PricingGroupID:dataf.PricingGroupID
    })
this.chkcomm=dataf.CommionOnGross;
  }
    this.subscription.add( this.api.GetHotelRoom(this.id.toString()).subscribe(
      (data) => {
        if(data !=null && data !=undefined ){
        this.roomtypeList= data["ResponseData"]    
        }
     
      }));
    this.loadmasterdata();
    this.loadSupplier();
 this.OnLoadGrid();
  }));
  
}
oncommiOnGrossChange(){
this.roomdetailform.controls.CommionOnGross.setValue(this.chkcomm)

this.calculategridamt();

}
onNightsblur(){
  debugger;
this.calculategridamt();
}
calculategridamt(){
  for(let result of this.editService.updatedItems)
   {
    if(result.Comm==null ||result.Comm==''||result.Comm==undefined)
{
  result.Comm=0;
}
//(Rate*100)/(100+comm%)
debugger;
if(this.chkcomm==true)
{
  result.Buy=+result.Rate+result.Rate*result.Comm/100;
}
else{
  result.Buy=+result.Rate*100;

  result.Buy=result.Buy/(100+(+result.Comm));
}
      if(this.roomdetailform.controls.ExRate.value==null || this.roomdetailform.controls.ExRate.value==undefined )
      {
       this.roomdetailform.controls.ExRate.setValue(0);
      }
      
      result.BuyHC=result.Buy*this.roomdetailform.controls.ExRate.value
      if(result.BuyHC==null||result.BuyHC==undefined)
      {
       result.BuyHC=0;
      }
      if(result.C1stMarkup==null||result.C1stMarkup==undefined)
      {
       result.C1stMarkup=0;
      }
      result.Sell= result.BuyHC+(+result.C1stMarkup);
      if(result.Tax==null||result.Tax==undefined)
      {
       result.Tax=0;
      }
      result.Sell=result.Sell+(+result.Tax)
   }
   this.view.source["data"].forEach(result => {
   
    if(result.Comm==null ||result.Comm==''||result.Comm==undefined)
{
  result.Comm=0;
}
//(Rate*100)/(100+comm%)
debugger;
if(this.chkcomm==true)
{
  result.Buy=+result.Rate+result.Rate*result.Comm/100;
}
else{
  result.Buy=+result.Rate*100;

  result.Buy=result.Buy/(100+(+result.Comm));
}
      if(this.roomdetailform.controls.ExRate.value==null || this.roomdetailform.controls.ExRate.value==undefined )
      {
       this.roomdetailform.controls.ExRate.setValue(0);
      }
      
      result.BuyHC=result.Buy*this.roomdetailform.controls.ExRate.value
      if(result.BuyHC==null||result.BuyHC==undefined)
      {
       result.BuyHC=0;
      }
      if(result.C1stMarkup==null||result.C1stMarkup==undefined)
      {
       result.C1stMarkup=0;
      }
      result.Sell= result.BuyHC+(+result.C1stMarkup);
      if(result.Tax==null||result.Tax==undefined)
      {
       result.Tax=0;
      }
      result.Sell=result.Sell+(+result.Tax)
   }); 
for(let result of this.editService.createdItems)
   {
    if(result.Comm==null ||result.Comm==''||result.Comm==undefined)
{
  result.Comm=0;
}
//(Rate*100)/(100+comm%)
debugger;
if(this.chkcomm==true)
{
  result.Buy=+result.Rate+result.Rate*result.Comm/100;
}
else{
  result.Buy=+result.Rate*100;

  result.Buy=result.Buy/(100+(+result.Comm));
}
      if(this.roomdetailform.controls.ExRate.value==null || this.roomdetailform.controls.ExRate.value==undefined )
      {
       this.roomdetailform.controls.ExRate.setValue(0);
      }
      
      result.BuyHC=result.Buy*this.roomdetailform.controls.ExRate.value
      if(result.BuyHC==null||result.BuyHC==undefined)
      {
       result.BuyHC=0;
      }
      if(result.C1stMarkup==null||result.C1stMarkup==undefined)
      {
       result.C1stMarkup=0;
      }
      result.Sell= result.BuyHC+(+result.C1stMarkup);
      if(result.Tax==null||result.Tax==undefined)
      {
       result.Tax=0;
      }
      result.Sell=result.Sell+(+result.Tax)
   }
}
onCancel()
{
  sessionStorage.setItem('menuvalue', 'false')
  if(this.roomid>0)
  this.router.navigate(['/PenAir/Hotel/Details/roomratesetupview',this.id,this.roomid])
  else
  this.router.navigate(['/PenAir/Hotel/Details/roomratesetup',this.id])
}
public async onFormSubmit()
{
  if ( Boolean(await this.comm.checkPermission1("01.14.01.03")) == true ) {
  this.roomdetailform.controls.HIDP.setValue(this.id);
  this.roomdetailform.controls.HID.setValue(this.id);
  if(this.roomdetailform.controls.PRTYCODE.value==null ||this.roomdetailform.controls.PRTYCODE.value==''||this.roomdetailform.controls.PRTYCODE.value==undefined)
  {
    this.subscription.add(this.translateapi.get(['Messages.Supplierreqd']).subscribe((translations ) => {
      this.showMsg= translations['Messages.Supplierreqd'];
     this.toastr.warning(this.showMsg,"",{
        timeOut:3000,
        disableTimeOut:false,
        closeButton:true
      })
     }));
     this.spinner.hide();
     return;
  }
  if(this.roomdetailform.controls.PID.value==null ||this.roomdetailform.controls.PID.value==''||this.roomdetailform.controls.PID.value==undefined)
  {
    this.subscription.add(this.translateapi.get(['Messages.Projectreqd']).subscribe((translations ) => {
      this.showMsg= translations['Messages.Projectreqd'];
     this.toastr.warning(this.showMsg,"",{
        timeOut:3000,
        disableTimeOut:false,
        closeButton:true
      })
     }));
     this.spinner.hide();
     return;
  }
  if(this.roomdetailform.controls.CID.value==null ||this.roomdetailform.controls.CID.value==''||this.roomdetailform.controls.CID.value==undefined)
  {
    this.subscription.add(this.translateapi.get(['Messages.select_currency']).subscribe((translations ) => {
      this.showMsg= translations['Messages.select_currency'];
     this.toastr.warning(this.showMsg,"",{
        timeOut:3000,
        disableTimeOut:false,
        closeButton:true
      })
     }));
     this.spinner.hide();
     return;
  }
  this.spinner.show();
this.submitted=true;


  // this.api.CustomerMaster=new CustomerMaster;
 
  if (this.roomdetailform.valid) {
   this.api.formDatasmain=this.roomdetailform.value;
   this.api.List=[];
   debugger;
   var udate=this.comm.transformDate(new Date());
   this.roomdetailform.controls.LUPDATE.setValue(udate);
   
   

   this.api.createdItems=this.editService.createdItems;
   for(let result of this.api.createdItems)
   {
    result.RFrom=this.comm.transformDate(new Date(result.RFrom));
    result.RTo=this.comm.transformDate(new Date(result.RTo));
   }
   this.api.deletedItems=this.editService.deletedItems;
   for(let result of this.api.deletedItems)
   {
    result.RFrom=this.comm.transformDate(new Date(result.RFrom));
    result.RTo=this.comm.transformDate(new Date(result.RTo));
   }
   this.api.updatedItems=this.editService.updatedItems;
   for(let result of this.api.updatedItems)
   {
    result.RFrom=this.comm.transformDate(new Date(result.RFrom));
    result.RTo=this.comm.transformDate(new Date(result.RTo));
   }
    this.subscription.add( this.api.Saveratesetp().subscribe(res=>{
      if(res !=null && res !=undefined ){
if(res["ResponseData"].RRID>0)
{
  
  this.roomdetailform.controls.RRID.setValue(res["ResponseData"].RRID);
 
  sessionStorage.setItem('menuvalue', 'false')
    this.spinner.hide();

    this.router.navigate(['PenAir/Hotel/Details/roomratesetupview',this.id ,res["ResponseData"].RRID])
    this.subscription.add(this.translateapi.get(['Messages.Ratesetupsaved']).subscribe((translations ) => {
      this.showMsg= translations['Messages.Ratesetupsaved'];
     this.toastr.success(this.showMsg,"",{
        timeOut:3000,
        disableTimeOut:false,
        closeButton:true
      })
     }));
  
  
}
else if(res["ResponseData"].RRID==-1)
{
  this.spinner.hide();
  this.subscription.add(this.translateapi.get(['Messages.Ratesetupalreadyexsit']).subscribe((translations ) => {
    this.showMsg= translations['Messages.Ratesetupalreadyexsit'];
   this.toastr.error(this.showMsg,"",{
      timeOut:3000,
      disableTimeOut:false,
      closeButton:true
    })
   }));
}
else
{
  this.spinner.hide();
  this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations ) => {
    this.showMsg= translations['Messages.some_error_in_save'];
   this.toastr.error(this.showMsg,"",{
      timeOut:3000,
      disableTimeOut:false,
      closeButton:true
    })
   }));
}
     
      }
      else
      {
        this.spinner.hide();
      }

    }))
}
else{
  this.spinner.hide();
}
}
else
{
  this.ShowPermissionMessageForm('01.14.01.03');//339
  //this.comm.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.14.01.03');
}
}
public onChange(value: Date,dataItem): void {
  
  dataItem.RFrom=value;
  this.value0=value;
  
  
}
public onChangeRTo(value: Date,dataItem): void {
  
  dataItem.RTo=value;
  this.value1=value;
  
}
public onStateChange(state: State) {
  
  this.gridState = state;

  this.editService.read();
}

public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
  
  if(dataItem.RFrom!=null && dataItem.RFrom!=undefined)
  {
    dataItem.RFrom=new Date(dataItem.RFrom);
  }
  if(dataItem.RTo!=null && dataItem.RTo!=undefined)
  {
    dataItem.RTo=new Date(dataItem.RTo);
  }
  

  if (!isEdited) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
  }
}

public cellCloseHandler(args: any) {
  
  const { formGroup, dataItem } = args;
  if(formGroup.value.Rate==null||formGroup.value.Rate==undefined)
  {
    formGroup.value.Rate=0;
  }
  var validdate=new Date('01/01/1900')
  var date = formGroup.value['RFrom'];
  var RTo = formGroup.value['RTo'];
var type = formGroup.value['TYPE'];
if(date!=null) // For caseid-187
{
  date=new Date(date);
  date.setSeconds(0);
  date.setMinutes(0);
  date.setHours(0);
  if (date<validdate){
    this.subscription.add( this.translateapi.get(['Messages.InvalidDate']).subscribe((translations ) => {
        
      this.showMsg= translations['Messages.InvalidDate'];
      this.toastr.warning(this.showMsg,"",{
        timeOut:3000,
        disableTimeOut:false,
      
        closeButton:true
      });
    }));
    return;
  }
}
 if(RTo!=null) // For caseid-187
{
  RTo=new Date(RTo);
  RTo.setSeconds(0);
  RTo.setMinutes(0);
  RTo.setHours(0);
  if (RTo<validdate){
    this.subscription.add( this.translateapi.get(['Messages.InvalidDate']).subscribe((translations ) => {
        
      this.showMsg= translations['Messages.InvalidDate'];
      this.toastr.warning(this.showMsg,"",{
        timeOut:3000,
        disableTimeOut:false,
      
        closeButton:true
      });
    }));
    return;
  }
}
if(formGroup.value.Comm==null ||formGroup.value.Comm==''||formGroup.value.Comm==undefined)
{
  formGroup.value.Comm=0;
}
//(Rate*100)/(100+comm%)
debugger;
if(this.chkcomm==true)
{
  formGroup.value.Buy=+formGroup.value.Rate+formGroup.value.Rate*formGroup.value.Comm/100;
}
else{
  formGroup.value.Buy=+formGroup.value.Rate*100;
 
  formGroup.value.Buy=formGroup.value.Buy/(100+(+formGroup.value.Comm));
}
     
      if(this.roomdetailform.controls.ExRate.value==null || this.roomdetailform.controls.ExRate.value==undefined )
      {
       this.roomdetailform.controls.ExRate.setValue(0);
      }
      
      formGroup.value.BuyHC=formGroup.value.Buy*this.roomdetailform.controls.ExRate.value
      if(formGroup.value.BuyHC==null||formGroup.value.BuyHC==undefined)
      {
       formGroup.value.BuyHC=0;
      }
      if(formGroup.value.C1stMarkup==null||formGroup.value.C1stMarkup==undefined)
      {
       formGroup.value.C1stMarkup=0;
      }
      formGroup.value.Sell= formGroup.value.BuyHC+(+formGroup.value.C1stMarkup);
      if(formGroup.value.Tax==null||formGroup.value.Tax==undefined)
      {
       formGroup.value.Tax=0;
      }
      formGroup.value.Sell=formGroup.value.Sell+(+formGroup.value.Tax)
       if (!formGroup.valid) {
            // prevent closing the edited cell if there are invalid values.
           args.preventDefault();
       } 
       else if (formGroup.dirty ||!formGroup.dirty) {
           this.editService.assignValues(dataItem, formGroup.value);
           this.editService.update(dataItem);
       }
    
  




}

public addHandler({ sender }) {
  
  this.add=false;
  sender.addRow(this.createFormGroup(new Hote103DTO()));
}

public cancelHandler({ sender, rowIndex }) {
  sender.closeRow(rowIndex);
}

public saveHandler({ sender, formGroup, rowIndex }) {
  
  this.add=true;
  if(formGroup.value.Rate==null||formGroup.value.Rate==undefined)
  {
    formGroup.value.Rate=0;
  }
  var validdate=new Date('01/01/1900')
  var date = formGroup.value['RFrom'];
  var RTo = formGroup.value['RTo'];
var type = formGroup.value['TYPE'];
if(date!=null) // For caseid-187
{
  date=new Date(date);
  date.setSeconds(0);
  date.setMinutes(0);
  date.setHours(0);
  if (date<validdate){
    this.subscription.add( this.translateapi.get(['Messages.InvalidDate']).subscribe((translations ) => {
        
      this.showMsg= translations['Messages.InvalidDate'];
      this.toastr.warning(this.showMsg,"",{
        timeOut:3000,
        disableTimeOut:false,
      
        closeButton:true
      });
    }));
    return;
  }
}
 if(RTo!=null) // For caseid-187
{
  RTo=new Date(RTo);
  RTo.setSeconds(0);
  RTo.setMinutes(0);
  RTo.setHours(0);
  if (RTo<validdate){
    this.subscription.add( this.translateapi.get(['Messages.InvalidDate']).subscribe((translations ) => {
        
      this.showMsg= translations['Messages.InvalidDate'];
      this.toastr.warning(this.showMsg,"",{
        timeOut:3000,
        disableTimeOut:false,
      
        closeButton:true
      });
    }));
    return;
  }
}
// else{
//   this.subscription.add( this.translateapi.get(['Messages.InvalidDate']).subscribe((translations ) => {
        
//     this.showMsg= translations['Messages.InvalidDate'];
//     this.toastr.warning(this.showMsg,"",{
//       timeOut:3000,
//       disableTimeOut:false,
    
//       closeButton:true
//     });
//   }));
//   return;
// }
//  if(RTo!=null) // For caseid-187
// {
//   RTo=new Date(RTo);
//   RTo.setSeconds(0);
//   RTo.setMinutes(0);
//   RTo.setHours(0);
//   if (RTo<validdate){
//     this.subscription.add( this.translateapi.get(['Messages.InvalidDate']).subscribe((translations ) => {
        
//       this.showMsg= translations['Messages.InvalidDate'];
//       this.toastr.warning(this.showMsg,"",{
//         timeOut:3000,
//         disableTimeOut:false,
      
//         closeButton:true
//       });
//     }));
//     return;
//   }
// } 
// else
// {
 
//     this.subscription.add( this.translateapi.get(['Messages.InvalidDate']).subscribe((translations ) => {
          
//       this.showMsg= translations['Messages.InvalidDate'];
//       this.toastr.warning(this.showMsg,"",{
//         timeOut:3000,
//         disableTimeOut:false,
      
//         closeButton:true
//       });
//     }));
//     return;
  
// }
if(RTo!=null && date!=null)
{
  if (RTo<date){

    this.subscription.add( this.translateapi.get(['Messages.fromdate_exceeds']).subscribe((translations ) => {
          
      this.showMsg= translations['Messages.fromdate_exceeds'];
      this.toastr.warning(this.showMsg,"",{
        timeOut:3000,
        disableTimeOut:false,
      
        closeButton:true
      });
    }));
    return;
    }

} 
// if(formGroup.value.TYPE==null ||formGroup.value.TYPE==''||formGroup.value.TYPE==undefined)
// {
//   this.subscription.add(this.translateapi.get(['Messages.RoomTypeRequired']).subscribe((translations ) => {
//     this.showMsg= translations['Messages.RoomTypeRequired'];
//    this.toastr.warning(this.showMsg,"",{
//       timeOut:3000,
//       disableTimeOut:false,
//       closeButton:true
//     })
//    }));
//    this.spinner.hide();
//    return;
// }
if(formGroup.value.Comm==null ||formGroup.value.Comm==''||formGroup.value.Comm==undefined)
{
  formGroup.value.Comm=0;
}
//(Rate*100)/(100+comm%)
debugger;
if(this.chkcomm==true)
{
  formGroup.value.Buy=+formGroup.value.Rate+formGroup.value.Rate*formGroup.value.Comm/100;
}
else{
  formGroup.value.Buy=+formGroup.value.Rate*100;

  formGroup.value.Buy=formGroup.value.Buy/(100+(+formGroup.value.Comm));
}
      if(this.roomdetailform.controls.ExRate.value==null || this.roomdetailform.controls.ExRate.value==undefined )
      {
       this.roomdetailform.controls.ExRate.setValue(0);
      }
      
      formGroup.value.BuyHC=formGroup.value.Buy*this.roomdetailform.controls.ExRate.value
      if(formGroup.value.BuyHC==null||formGroup.value.BuyHC==undefined)
      {
       formGroup.value.BuyHC=0;
      }
      if(formGroup.value.C1stMarkup==null||formGroup.value.C1stMarkup==undefined)
      {
       formGroup.value.C1stMarkup=0;
      }
      formGroup.value.Sell= formGroup.value.BuyHC+(+formGroup.value.C1stMarkup);
      if(formGroup.value.Tax==null||formGroup.value.Tax==undefined)
      {
       formGroup.value.Tax=0;
      }
      formGroup.value.Sell=formGroup.value.Sell+(+formGroup.value.Tax)
  if (formGroup.valid) {
      this.editService.create(formGroup.value);
      sender.closeRow(rowIndex);
  }
}

public removeHandler({ sender, dataItem }) {
  
  this.editService.remove(dataItem);

  sender.cancelCell();
}

public saveChanges(grid: any): void {
  
  grid.closeCell();
  grid.cancelCell();

  this.editService.saveChanges();
}

public cancelChanges(grid: any): void {
  
  grid.cancelCell();

  this.editService.cancelChanges();
}

public createFormGroup(dataItem: any): FormGroup {
  return this.formBuilder.group({
      'AutoNo': dataItem.AutoNo,
      'SLNO': dataItem.SLNO,
      'RFrom':[dataItem.RFrom!=null?(dataItem.RFrom.toString()=="1900-01-01T00:00:00"?null:new Date(dataItem.RFrom)):null, Validators.required],
      'RTo':[dataItem.RTo!=null?(dataItem.RTo.toString()=="1900-01-01T00:00:00"?null:new Date(dataItem.RTo)):null, Validators.required],
      'TYPE':[dataItem.TYPE, Validators.required],
      'Rate': dataItem.Rate,
      'Gross': dataItem.Gross,
      'Comm': dataItem.Comm,
      'Buy': dataItem.Buy,
      'BuyHC': dataItem.BuyHC,
      'Tax': dataItem.Tax,

      'PFC': dataItem.PFC,
      'PLC': dataItem.PLC,
      'C1stMarkup': dataItem.C1stMarkup,
      'Total': dataItem.Total,
      'LC': dataItem.LC,
      'OCharge': dataItem.OCharge,
      'Sell': dataItem.Sell,
      'Plan': dataItem.Plan,
     
      
  });
}
public calculateamountrate(value:any,dataItem,grid): void {
  
  if(value!=undefined)
  {
    dataItem.Rate=value;
    this.calculateamount(dataItem,grid);
  }

}
public calculateamountcomm(value:any,dataItem): void {
  
  if(value!=undefined)
  {
    dataItem.Comm=value;
  }

}
public calculateamount(dataItem,grid:any): void {
  
  if(dataItem.Rate==null||dataItem.Rate==undefined)
  {
    dataItem.Rate=0;
  }
 // var d=this.itemIndex(dataItem,grid)
  dataItem.Buy=dataItem.Rate+dataItem.Rate*2/100;
  //this.editService.createdItems.push(dataItem)

}

public permissionmessageformopened:any;//Caseid-339
public PermissionMessageId:any;//Caseid-339

public PermissionFormClosing(status:any)//339
  {
    if(status=="EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

ShowPermissionMessageForm(permissionid: string) {
  this.permissionmessageformopened=true;//Caseid-339
  this.PermissionMessageId =permissionid;//Caseid-339
}
public itemIndex = (item: any, data: any[]): number => {
  for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].AutoNo === item.AutoNo) {
          return idx;
      }
  }

  return -1;
};
  }