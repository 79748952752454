import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { TourDetails } from '../Models/tour-details'
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class TourService {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   }

  
  //Supplierss:Object

  //getSuppliers (): Observable<HotelDetail[]> {   

  ////return this.http.get<HotelDetail[]>(apiUrl)
  // .pipe(
  //   tap(heroes => console.log('fetched Suppliers')),
  // catchError(this.commonservice.handleError('getSuppliers', []))
  //);
  //}

  private newMethod(data: Object) {
    alert(data);
  }

  getTour(folderNo: number, GuidItinerary: string,isViewForm:boolean): Observable<TourDetails> {
    const url = `${this.apiUrl}` + 'GetTourDetailsById' + `/${folderNo}/${GuidItinerary}/${isViewForm}`;
    return this.http.get<TourDetails>(url).pipe(
      tap(_ => console.log(`fetched Tour id=${folderNo}`)),
      catchError(this.commonservice.handleError<TourDetails>(`getTour id=${folderNo}`))
    );
  }
  settourdate(tourdetail: any)
  {
    if(tourdetail.LOYALTYPOINTSAMT==null)
    tourdetail.LOYALTYPOINTSAMT=0;
    if(tourdetail.LOYALTYPOINTS==null)
    tourdetail.LOYALTYPOINTS=0;

    // if(tourdetail.DATE1==null)
    // tourdetail.DATE1="01-Jan-1900";

    // if(tourdetail.DATE2==null)
    // tourdetail.DATE2="01-Jan-1900";

    // if(tourdetail.BookingDate==null)
    // tourdetail.BookingDate="01-Jan-1900";
 
    // if(tourdetail.DepositDueDate==null)
    // tourdetail.DepositDueDate="01-Jan-1900";

    // if(tourdetail.BalanceDueDate==null)
    // tourdetail.BalanceDueDate="01-Jan-1900";

    if(tourdetail.DATE1===null || tourdetail.DATE1==='')
    tourdetail.DATE1=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"))
    else
    tourdetail.DATE1=this.commonservice.ConvertToLocaleDate(tourdetail.DATE1);

    if(tourdetail.DATE2===null || tourdetail.DATE2==='')
    tourdetail.DATE2=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
    tourdetail.DATE2=this.commonservice.ConvertToLocaleDate(tourdetail.DATE2);

    if(tourdetail.BookingDate===null || tourdetail.BookingDate==='')
    tourdetail.BookingDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
    tourdetail.BookingDate=this.commonservice.ConvertToLocaleDate(tourdetail.BookingDate);

    if(tourdetail.DepositDueDate===null || tourdetail.DepositDueDate==='')
    tourdetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
    tourdetail.DepositDueDate=this.commonservice.ConvertToLocaleDate(tourdetail.DepositDueDate);

    if(tourdetail.BalanceDueDate===null || tourdetail.BalanceDueDate==='')
    tourdetail.BalanceDueDate=this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
    tourdetail.BalanceDueDate=this.commonservice.ConvertToLocaleDate(tourdetail.BalanceDueDate);

    


  


    
  }
  addTour(tourdetail: any): Observable<any> {
   this.settourdate(tourdetail);
    return this.http.post<any>(this.apiUrl + 'AddTourDetails', tourdetail, httpOptions).pipe(
      tap((tourRes: any) => console.log(`added Tour w/ id=${tourRes.ResponseData.SUPPNAME}`)),
      catchError(this.commonservice.handleError<any>('addTour'))
    );
  }

  updateTour(tourdetail: any): Observable<any> {
    this.settourdate(tourdetail);
    const url = `${this.apiUrl}` + 'UpdateTourDetails';///${id}
    return this.http.post(url, tourdetail, httpOptions).pipe(
      tap(_ => console.log(`updated Tour`)),
      catchError(this.commonservice.handleError<any>('updateTour'))
    );
  }
  deleteTour ( folderNo: number,GuidItinerary:string): Observable<any> {   

    const url = `${this.apiUrl}`+'DeleteTourDetails'+`/${folderNo}/${GuidItinerary}`;
   
    return this.http.post(url,  httpOptions).pipe(
      tap(_ => console.log(`delete Tour`)),
      catchError(this.commonservice.handleError<any>('deleteTour'))
    );
  }
  
}
