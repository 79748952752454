import { Component, OnInit, Input } from '@angular/core';
import { ShareDataService } from '../../Services/share-data.service';
import { Subscription } from 'rxjs';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { CommonserviceService } from '../../Services/commonservice.service';
import { IntlService } from '@progress/kendo-angular-intl';


@Component({
  selector: 'app-fchcdetails',
  templateUrl: './fchcdetails.component.html',
  styleUrls: ['./fchcdetails.component.scss']
})
export class FCHCDetailsComponent implements OnInit {
  public labelchange = false;
  folderExrate: any;
  opentaxbaseForm: any = false;
  fldrCurrency: any;
  private buyExRate: number;
  folderStatus: any;
  isShown = false;
  Producttype: any = false;
  buycurrency: any;
  divValue: any;
  CostingVatType: any = 'No VAT'
  @Input() itineraryDetails;
  Tax: Array<LookUpDetails["ResponseData"]> = [];

  public TaxselectedItem: LookUpDetails["ResponseData"];
  private subscription: Subscription = new Subscription();
  constructor(private apiShared: ShareDataService, private lookupApi: LookUpDetailsService, public intl: IntlService,
    public common: CommonserviceService) {
    debugger;

    this.subscription.add(this.lookupApi.GetAllLookup('TAX').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.Tax = data["ResponseData"];

          // alert(this.parentForm.controls.ITYPE.value);   
          var tax = this.itineraryDetails.ResponseData.SELLTAXNO;
          if (tax != "") {

            this.TaxselectedItem = this.Tax.find(item => item.Code == tax);

          }

        }

      }
    ));
  }

  ngOnInit() {
    debugger;
    var d = this.itineraryDetails;
    if ((this.itineraryDetails.ResponseData.ITYPE) == 'Hotel' || (this.itineraryDetails.ResponseData.ITYPE) == 'Cruise' || this.itineraryDetails.ResponseData.ITYPE == 'Car' || this.itineraryDetails.ResponseData.ITYPE == 'Tour' || this.itineraryDetails.ResponseData.ITYPE == 'Rail' || this.itineraryDetails.ResponseData.ITYPE == 'Insurance' || this.itineraryDetails.ResponseData.ITYPE == 'Transfers') {
      this.buycurrency = this.itineraryDetails.ResponseData.CURCODE;
    }
    if ((this.itineraryDetails.ResponseData.ITYPE) == 'Others') {
      this.buycurrency = this.itineraryDetails.ResponseData.CURID;
    }
    // if(this.itineraryDetails.ResponseData.ITYPE=="Hotel" ||this.itineraryDetails.ResponseData.ITYPE=="Others"||this.itineraryDetails.ResponseData.ITYPE=="Cruise"||this.itineraryDetails.ResponseData.ITYPE=="Car"||this.itineraryDetails.ResponseData.ITYPE=="Rail")
    // {
    this.Producttype = true;
    // }
    if (this.itineraryDetails.ResponseData.CostingVATType == 'NV') {
      this.divValue = 1;
      this.CostingVatType = 'No VAT'
    }
    else if (this.itineraryDetails.ResponseData.CostingVATType == 'VSF') {
      this.divValue = 2;
      this.CostingVatType = 'VAT on Service Fee'
    }
    else if (this.itineraryDetails.ResponseData.CostingVATType == 'VP') {
      this.divValue = 3;
      this.CostingVatType = 'VAT on Purchase'
    }
    else if (this.itineraryDetails.ResponseData.CostingVATType == 'VSC') {
      this.CostingVatType = 'VAT on Supp.Commission'
      this.divValue = 4;
    }
    else {
      this.divValue = 1;
      this.CostingVatType = 'No VAT'
    }


    this.getFolderValues();

    this.folderStatus = this.apiShared.FolderStatus;
    if (this.folderStatus == "Refund Request") {
      this.labelchange = true;
    }
    this.TaxsplitCalculation();
  }
  onOpenTaxbase() {
    this.opentaxbaseForm = true;
  }
  close() {
    this.opentaxbaseForm = false;
  }
  closeTaxBaseForm(): any { //13860 v1.0
    return {
      callParentMethod: (value: any) => {
        debugger;


      },
      closemodalpopup: () => {

        this.close()

      }
    }
  }
  TaxsplitCalculation() {
    this.folderExrate = this.apiShared.folderExchangeRate;
    // if((this.itineraryDetails.ResponseData.ITYPE ) =='Hotel'||(this.itineraryDetails.ResponseData.ITYPE ) =='Others')
    // {
    if (this.itineraryDetails.ResponseData.ITYPE == "Hotel") {
      this.buyExRate = this.itineraryDetails.ResponseData.EXRATE == "" || this.itineraryDetails.ResponseData.EXRATE == 0 ? 1 : this.itineraryDetails.ResponseData.EXRATE;


    }
    else {
      this.buyExRate = (this.itineraryDetails.ResponseData.CURExRate == "" || this.itineraryDetails.ResponseData.CURExRate == 0) ? 1 : this.itineraryDetails.ResponseData.CURExRate;
    }
    if (this.itineraryDetails.ResponseData.CostingVATType == 'NV' || this.itineraryDetails.ResponseData.CostingVATType == '' || this.itineraryDetails.ResponseData.CostingVATType == null || this.itineraryDetails.ResponseData.CostingVATType == undefined) {
      this.itineraryDetails.ResponseData.CostingCustomerSalesValue = (((this.itineraryDetails.ResponseData.FCBuy * this.buyExRate) / this.folderExrate));


      this.itineraryDetails.ResponseData.CostingCustomerServiceFee = this.itineraryDetails.ResponseData.FCSell - this.itineraryDetails.ResponseData.CostingCustomerSalesValue
    }
    if (this.itineraryDetails.ResponseData.CostingVATType == 'VSF') {
      this.itineraryDetails.ResponseData.CostingCustomerSalesValue = (((this.itineraryDetails.ResponseData.FCBuy * this.buyExRate) / this.folderExrate));


      this.itineraryDetails.ResponseData.CostingCustomerServiceFee = this.itineraryDetails.ResponseData.FCSell - this.itineraryDetails.ResponseData.CostingCustomerSalesValue
    }
    // }
  }

  getFolderValues() {
    // this.apiShared.folderCurrency.subscribe(fldrCurrency => this.fldrCurrency = fldrCurrency)
    // this.apiShared.folderExchangeRate.subscribe(folderExrate => this.folderExrate = folderExrate)
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    //this.folderExrate=new Number(this.folderExrate);


  }


}
