import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EnvService } from 'src/env.service';
import { CommonserviceService } from './commonservice.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class PurgeAlertsService {
  apiUrl: string;
  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }
  getClosedAlertsList(getClosedAlert: any, pageSize, pageno) {
    if (getClosedAlert.AlertCrDateFrom === null || getClosedAlert.AlertCrDateFrom === '')
      getClosedAlert.AlertCrDateFrom = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      getClosedAlert.AlertCrDateFrom = this.commonservice.ConvertToLocaleDate(getClosedAlert.AlertCrDateFrom);

    if (getClosedAlert.AlertCrDateTo === null || getClosedAlert.AlertCrDateTo === '')
      getClosedAlert.AlertCrDateTo = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      getClosedAlert.AlertCrDateTo = this.commonservice.ConvertToLocaleDate(getClosedAlert.AlertCrDateTo);


    if (getClosedAlert.TravelFromDate === null || getClosedAlert.TravelFromDate === '')
      getClosedAlert.TravelFromDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      getClosedAlert.TravelFromDate = this.commonservice.ConvertToLocaleDate(getClosedAlert.TravelFromDate);

    if (getClosedAlert.TravelToDate === null || getClosedAlert.TravelToDate === '')
      getClosedAlert.TravelToDate = this.commonservice.ConvertToLocaleDate(new Date("01-Jan-1900"));
    else
      getClosedAlert.TravelToDate = this.commonservice.ConvertToLocaleDate(getClosedAlert.TravelToDate);

    getClosedAlert.PageSize = pageSize;
    getClosedAlert.PageNo = pageno;

    return this.http.post<any>(this.apiUrl + 'GetPurgeAlertsList', getClosedAlert, httpOptions).pipe(
      tap((PenChannelParameterDTO: any) => console.log(`getClosedAlertsList`)),
      catchError(this.commonservice.handleError<any>('getClosedAlertsList'))
    );
  }
  public async PurgeClosedAlert(deleteAlert: any): Promise<Observable<any>> {
    var result = await this.http.post<any>(this.apiUrl + 'PurgeAlerts', deleteAlert, httpOptions).pipe(
      tap((GetAutoInvoiceDetailsDTO: any) => console.log(`Purge Alert`)),
      catchError(this.commonservice.handleError<any>('Purge Alert'))
    ).toPromise();
    return result;

  }
}
