import { Component, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { FolderSearchCriteriaDTO, InsuranceSearchDTO } from '../../Models/folder-search-criteria-dto';
import { SearhdtotransferService } from '../../Services/searhdtotransfer.service';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
@Component({
  selector: 'app-searchby-insurance',
  templateUrl: './searchby-insurance.component.html',
  styleUrls: ['./searchby-insurance.component.scss']
})
export class SearchbyInsuranceComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  constructor(public common: CommonserviceService, private searhTransferDTO: SearhdtotransferService, private lookupApi: LookUpDetailsService) { }

  Insurance: Array<LookUpDetails["ResponseData"]> = [];
  public InsuranceselectedItem: LookUpDetails["ResponseData"];
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  public statusList: Array<string> = ["OK", "Pending", "Cancelled", "Confirmed"];
  SupplierList: Array<LookUpDetails['ResponseData']> = [];
  public supplierSelectedItem: LookUpDetails['ResponseData'];
  public supplierSelected: String;
  flsrchDto: FolderSearchCriteriaDTO;
  InsuranceSearchDTO: InsuranceSearchDTO;
  opened = false;
  openSearch = false;

  ngOnChanges(changes: SimpleChanges) {

    this.InsuranceSearchDTO = this.flsrchDto.InsuranceSearchDTO;
  }
  menuIndex = 7;
  ngOnInit() {

    this.subscription.add(this.searhTransferDTO.SrchTranferDTO.subscribe(flsrchDto => {
      this.flsrchDto = flsrchDto;

    }))
    if (this.flsrchDto.InsuranceSearchDTO != null)
      this.InsuranceSearchDTO = this.flsrchDto.InsuranceSearchDTO;
    else
      this.InsuranceSearchDTO = new InsuranceSearchDTO();
    this.loadSupplier();
    this.loadInsuranceType();
    if(this.InsuranceSearchDTO.Supplier!=""&&   this.InsuranceSearchDTO.Supplier != undefined){

    }
  }

  
  loadSupplierValue(value:any) {  //14068   v1.0 
    debugger; 

      this.subscription.add(this.lookupApi.findSupplier(value).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.SupplierList = data["ResponseData"];
            if (value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
              }

            }
          }

        }
      ));
    
  }
  public loadInsuranceType() {
    this.subscription.add(this.lookupApi.GetAllLookup('INSURANCE').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.Insurance = data["ResponseData"];
          if (this.InsuranceSearchDTO.Type != '') {
            this.InsuranceselectedItem = this.Insurance.find(item => item.Code == this.InsuranceSearchDTO.Type);
            //console.log("test:-"+this.insuranceEditForm.controls.TYPE.value+JSON.stringify(this.InsuranceselectedItem , null, 4));
          }
        }
      }));
  }



  public close(status) {

    this.opened = false;
    this.openSearch = false;
  }

  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860


  public loadSupplier() {
    this.subscription.add(this.lookupApi.GetAllLookup('SUPPLIERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.SupplierList = data["ResponseData"];
          if (this.InsuranceSearchDTO.Supplier != '' && this.InsuranceSearchDTO.Supplier != null) {

            this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.InsuranceSearchDTO.Supplier);
            this.supplierSelected = this.supplierSelectedItem.Name;

          }
        }
      }
    ));
  }

  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  handleFiltersupplier(value) {

    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.GetLookupById("SUPPLIERS", value, value).subscribe(
        // this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }

  public InsuranceChange(value: any): void {
    if (value != null && value != undefined) {
      this.InsuranceSearchDTO.Type = value.Code;
    } else {
      this.InsuranceSearchDTO.Type = '';
    }
    this.callChange();
  }


  public SupplierValueChange(value: any): void {
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);

    }
    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860

      this.InsuranceSearchDTO.Supplier = (this.supplierSelectedItem.Code);
    } else {
      this.supplierSelected = '';
      this.InsuranceSearchDTO.Supplier = ('');


    }
    this.callChange();
  }

  callChange() {

    if (this.InsuranceSearchDTO.Supplier != '' || this.InsuranceSearchDTO.Type.trim() != ''
      || this.InsuranceSearchDTO.DateFrom1AF.toString() != '' || this.InsuranceSearchDTO.DateFrom2AF.toString() != ''
      || this.InsuranceSearchDTO.DateTo1AF.toString() != '' || this.InsuranceSearchDTO.DateTo2AF.toString() != ''
      || this.InsuranceSearchDTO.Status != '' || this.InsuranceSearchDTO.VoucherNo != '' || this.InsuranceSearchDTO.CONFIRMATIONNO != '' || this.InsuranceSearchDTO.PNUMBER != '' || this.InsuranceSearchDTO.BookingDateFrom.toString() != '' || this.InsuranceSearchDTO.BookingDateTo.toString() != '') {
      if (this.InsuranceSearchDTO.DateFrom1AF != null) {
        if (this.InsuranceSearchDTO.DateFrom1AF.toString() != '')
          this.InsuranceSearchDTO.DateFrom1 = this.common.transformDate(new Date(this.InsuranceSearchDTO.DateFrom1AF));
        //  this.InsuranceSearchDTO.DateFrom1= new Date(this.InsuranceSearchDTO.DateFrom1AF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.InsuranceSearchDTO.DateFrom1 = null;

      }

      if (this.InsuranceSearchDTO.DateFrom2AF != null) {
        if (this.InsuranceSearchDTO.DateFrom2AF.toString() != '')
          this.InsuranceSearchDTO.DateFrom2 = this.common.transformDate(new Date(this.InsuranceSearchDTO.DateFrom2AF));

        // this.InsuranceSearchDTO.DateFrom2= new Date(this.InsuranceSearchDTO.DateFrom2AF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.InsuranceSearchDTO.DateFrom2 = null;

      }
      if (this.InsuranceSearchDTO.DateFrom1AF == null && this.InsuranceSearchDTO.DateFrom2AF != null) {
        this.InsuranceSearchDTO.DateFrom1 = this.InsuranceSearchDTO.DateFrom2;
      }
      if (this.InsuranceSearchDTO.DateTo1AF != null) {
        if (this.InsuranceSearchDTO.DateTo1AF.toString() != '')
          this.InsuranceSearchDTO.DateTo1 = this.common.transformDate(new Date(this.InsuranceSearchDTO.DateTo1AF));

        // this.InsuranceSearchDTO.DateTo1= new Date(this.InsuranceSearchDTO.DateTo1AF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.InsuranceSearchDTO.DateTo1 = null;
      }
      if (this.InsuranceSearchDTO.DateTo2AF != null) {
        if (this.InsuranceSearchDTO.DateTo2AF.toString() != '')
          this.InsuranceSearchDTO.DateTo2 = this.common.transformDate(new Date(this.InsuranceSearchDTO.DateTo2AF));

        // this.InsuranceSearchDTO.DateTo2= new Date(this.InsuranceSearchDTO.DateTo2AF).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
      } else {
        this.InsuranceSearchDTO.DateTo2 = null;

      }
      if (this.InsuranceSearchDTO.DateTo1AF == null && this.InsuranceSearchDTO.DateTo2AF != null) {
        this.InsuranceSearchDTO.DateTo1 = this.InsuranceSearchDTO.DateTo2;
      }
      if (this.InsuranceSearchDTO.BookingDateFrom != null)//12687
      {
        if (this.InsuranceSearchDTO.BookingDateFrom != null)
          this.InsuranceSearchDTO.BookingDateFrom1 = this.common.transformDate(new Date(this.InsuranceSearchDTO.BookingDateFrom));

      } else {
        this.InsuranceSearchDTO.BookingDateFrom1 = null;
      }
      if (this.InsuranceSearchDTO.BookingDateTo != null)//12687
      {
        if (this.InsuranceSearchDTO.BookingDateTo != null)
          this.InsuranceSearchDTO.BookingDateTo2 = this.common.transformDate(new Date(this.InsuranceSearchDTO.BookingDateTo));
      } else {
        this.InsuranceSearchDTO.BookingDateTo2 = null;
      }
      if (this.InsuranceSearchDTO.BookingDateFrom == null && this.InsuranceSearchDTO.BookingDateTo != null)//12687
      {
        this.InsuranceSearchDTO.BookingDateFrom1 = this.InsuranceSearchDTO.BookingDateTo2;
      }
      this.flsrchDto.InsuranceSearchDTO = this.InsuranceSearchDTO;
    }

    else
      this.flsrchDto.InsuranceSearchDTO = null;
    //alert(JSON.stringify(this.flsrchDto.InsuranceSearchDTO))
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
