export class ReportParameterDTO {
  isWebLink: boolean;
  FolderNo: string;
  RtftoHtmlUrl: string;

  ToEmail: string;
  Userid: number;
  FromEmail: string;

  body: string;
  Attachementpath: string;
  FileName: string;
  CCEmail: string;
  subject: string;
  trdpfilename: string;
  attachmentindex: number;
  enableAtol: number;
  Branch: string;
  TYPEOFINV: number;
  p_emailtype: string;
  pTemplateField: string;
  EmailBody: string;
  RecieptEmailBody: string;
  BCCEnable: any;
  BCCEmail: string;

  projno: string;
  hasExportandEmail: any;
}
export class voucherRpt {
  UserId: any
  GCTFolderNO: any
  GCTVhrNote: any
  GCTVhrNo: any
  GCTcurrency: any
  GCTProjNo: any
  ArraivalPt: any
  RedeemValidFrom: any
  RedeemValidTo: any
  GCTLeadPx: any
  FolderNo: any
  GCTValue: any
  RedeemAmt: any
  BalanceAmtTC: any
  Inetf: any
  RtfToHTMLUrl: any
  VhrCode: any
}
export class ExportPDFParam {
  Filter: any;
  FirstValue: any;
  SecondValue: any;
}
export class Cellparam {
  value: any;

}