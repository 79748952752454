import { Component, HostListener, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LookUpDetails } from "../../Services/look-up-details";

import { AirticketService } from "../../Services/airticket.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LookUpDetailsService } from "../../Services/look-up-details.service";
import {
  DropDownFilterSettings,
  AutoCompleteComponent
} from "@progress/kendo-angular-dropdowns";
import { FileService } from "../../Services/file.service";
import { FileDetails } from "../../Models/file-details";
import { CurrencyExchangerateService } from "../../Services/currency-exchangerate.service";
import { CurrencyExchangerate } from "../../Models/currency-exchangerate";
import { ShareDataService } from "../../Services/share-data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { FlashMessagesService } from "angular2-flash-messages";
import { TranslateService } from "@ngx-translate/core";
import { CommonserviceService } from "../../Services/commonservice.service";
import { Subscription } from "rxjs";
import { SupplierMasterService } from "../../Services/supplier-master.service";
import { ToastrService } from "ngx-toastr";
import { PaymentInformationEditComponent } from "../../payment-information-edit/payment-information-edit.component";
import { Subject } from "rxjs";
import { PocreationService } from "../../Services/pocreation.service";
import { ProductUpdatePODTO } from "../../Models/pocreation-detail";
import { AirticketDetailsEdit } from "../../BusinessLayer/airticket-details-edit";
import { FolderpriceService } from "../../Services/folderprice.service";
import { CashPaymentService } from "../../Services/cash-payment.service";
import { CashPaymentDTO } from "../../Models/cash-payment-dto.model";
import { TaxMasterDetailsDTO } from '../../Models/manual-sales-dto';


@Component({
  selector: "app-airticket-details-edit",
  templateUrl: "./airticket-details-edit.component.html",
  styleUrls: ["./airticket-details-edit.component.scss"]
})
export class AirticketDetailsEditComponent extends AirticketDetailsEdit
  implements OnInit {
  //#region Autocomplete object
  @ViewChild("autocompletesupplier", { read: false, static: false })
  public autocompletearrival: AutoCompleteComponent;
  @ViewChild("apppaymenttermsedit", { read: false, static: false }) public payterm: any;
  openSearch = false;
  lookupname: any = 'Supplier';
  lookuptype: string = '';
  @ViewChild("autocompletedeparture", { read: false, static: false })
  public autocompletedeparture: AutoCompleteComponent;
  Currency: Array<LookUpDetails["ResponseData"]> = [];
  public CurrencypyselectedItem: LookUpDetails["ResponseData"];
  PDQ: Array<LookUpDetails["ResponseData"]> = [];
  public PmntTrmselectedItem: LookUpDetails["ResponseData"];
  cardType: any;
  public PmntMethodsSelectedItem: LookUpDetails["ResponseData"];
  public PDQselectedItem: LookUpDetails["ResponseData"];
  public PDTYPENOSelectedItem: LookUpDetails["ResponseData"];

  InputTax: Array<LookUpDetails["ResponseData"]> = [];
  OutputTax: Array<LookUpDetails["ResponseData"]> = [];

  public InputTaxselectedItem: LookUpDetails["ResponseData"];
  public OutputTaxselectedItem: LookUpDetails["ResponseData"];

  custcountry: any;
 showcurrexgerate:boolean=false;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  //#endregion Autocomplete object
  //#region constructor
  constructor(
    private apiPO: PocreationService,
    public commonservice: CommonserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private api: AirticketService,
    public lookupApi: LookUpDetailsService,
    private apiFile: FileService,
    private toastr: ToastrService,
    public apiExRate: CurrencyExchangerateService,
    public apiShared: ShareDataService,
    private spinner: NgxSpinnerService,
    private translateapi: TranslateService,
    private supplier: SupplierMasterService,
    public objFolderPrice: FolderpriceService,
    private cashservice: CashPaymentService,

  ) {
    super(lookupApi, apiShared, commonservice, apiExRate, objFolderPrice);
  }
  //#endregion constructor

  //#region for Getting guid and Orderno
  private guid: string = this.route.snapshot.params["guidItinerary"]; //'Airticket.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/';
  private orderNo = this.route.snapshot.params["orderNo"];
  lockStatus: any;
  formeditable: any;
  //#endregion for Getting guid and Orderno
  ngOnInit() {

    //case id : 13777 
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    this.custcountry = localStorage.getItem('CustomerCountry')
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    } else {
      this.formeditable = false;

    }
    // case id : 13777
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;

    }
    //#region for Getting folderexgrate
    this.folderexgrate = this.apiShared.folderExchangeRate;
    if (localStorage.getItem("OrderType") === "Package") {
      this.package = true;
      if (localStorage.getItem("ManualCalc") === "1") {
        this.manualCalculation = true;
      }
    }
    //#endregion for Getting folderexgrate

    //#region checking orderNo difference
    var orderno = sessionStorage.getItem("orderNo");
    if (orderno != null)
      if (this.orderNo !== orderno) {
        return this.router.navigate(["PenAir/Home"], { skipLocationChange: true });
      }
    //#endregion checking orderNo difference
    //#region for Getting folderStatus
    this.folderStatus = this.apiShared.FolderStatus;
    //11614
    if (this.folderStatus == "Refund Request") {
      this.labelchange = true;
      this.typelist.push("RFN")
    }
    //11614
    //#endregion for Getting folderStatus

    //#region for Getting hcCurrency,hcExRate,folderEditMode,bsptaxNo
    this.hcCurrency = this.apiShared.homeCurrency;
    this.hcExRate = this.apiShared.hcExRate;
    this.folderEditMode = "true";

    this.apiShared.Setapishareddata(this.folderEditMode, "folderEditMode");
    this.bsptaxNo = this.apiShared.bspTaxNo;

    //#end region for Getting hcCurrency,hcExRate,folderEditMode,bsptaxNo

    //#region for calling FolderValues
    this.getFolderValues();
    //#endregion for calling FolderValues

    //#region for airticketEditForm
    this.airticketEditForm = this.formBuilder.group({
      FOLDERNO: [this.orderNo],
      PASSNAME: ["", Validators.required],
      TICKETNO: [""],
      TYPE: [""],
      AIRLINE: [""],
      AIRFROM: [""],
      AIRTO: [""],
      PAYABLE: [0],
      PRTYCODE: ["", Validators.required],
      SUPPNAME: [""],
      STATUS: [""],
      VLOCATOR: [""],
      FNAME: [""],
      GDS: [""],
      BSP: [0],
      JOURNEY: [""],
      TICKDATE: [],
      BOOKBY: [""],
      IATANO: [""],
      BDATE: [],
      ADATE: [],
      FREMARKS: [""],
      CURR: [this.hcCurrency],
      FARE: [""],
      EXCHANGE: [this.hcExRate],
      FARENAME: [""],
      FAREBASIS: [""],
      TYPE1: ["Fare"],
      DESC1: ["AIR"],
      BUY1: [0],
      COMMP1: [0],
      COMMA1: [0],
      SELL1: [0],
      TYPE2: ["Tax1"],
      DESC2: [""],
      BUY2: [0],
      COMMP2: [0],
      COMMA2: [0],
      SELL2: [0],
      TYPE3: ["Tax2"],
      DESC3: [""],
      BUY3: [0],
      COMMP3: [0],
      COMMA3: [0],
      SELL3: [0],
      TYPE4: ["Tax3"],
      DESC4: [""],
      BUY4: [0],
      COMMP4: [0],
      COMMA4: [0],
      SELL4: [0],
      BUYTOT: [0],
      COMMPTOT: [0],
      COMMATOT: [0],
      SELLTOT: [0],
      COMONLY: [""],
      Remark: [""],
      VairlineAIRWAYSCODE: [0],
      VairlineAIRNAME: [""],
      ATYPE: [],
      AMODE: [""],
      SLNO: [0],
      PROJNO: [this.projNo],
      VairlineAIRID: [""],
      TKTCODE: [""],
      DISCPER: [0],
      DISCAMT: [0],
      NETSELL: [0],
      CommOn: [""],
      ITYPE: ["Ticket"],
      TYPE5: ["Tax4"],
      DESC5: [""],
      BUY5: [0],
      COMMP5: [0],
      COMMA5: [0],
      SELL5: [0],
      LowFB: [""],
      LowFare: [0],
      HighFB: [""],
      HighFare: [0],
      Fareoffered: [0],
      PotentialSaving: [0],
      ActualSaving: [0],
      CARDID: [0],
      CARDNUMBER: [""],
      CARDValidity: [""],
      CARDAUTHCODE: [""],
      PNR: [""],
      PDNO: [0],
      PDTYPENO: [0],
      ADocDate: [""],
      VAIRNO: [""],
      Forgone: [0],
      AddRemarks: [""],
      SupplierNotes: [""],
      POSTINGDATE: [""],
      CancelledBooking: [0],
      TicketingDeadline: [""],
      PDAMOUNT: [0],
      TITLE: [""],
      PONO: [0],
      PONOYEARID: [""],
      PAYMENTID: [0],
      PMTID: [""],
      DEFDEPPER: [0],
      DEFDEP: [0],
      DepositDueDate: [""],
      BalanceDueDate: [new Date()],
      BalanceDueAmount: [0],
      PDFCURID: [this.hcCurrency],
      PDEXRATE: [this.hcExRate],
      PDFCAMT: [0],
      ITSplit1: [""],
      ITSplit1AMT: [0],
      ITSplit2: [""],
      ITSplit2AMT: [0],
      ITSplit3: [""],
      ITSplit3AMT: [0],
      ITSplit4: [""],
      ITSplit4AMT: [0],
      ITSplit5: [""],
      ITSplit5AMT: [0],
      ITSplit6: [""],
      ITSplit6AMT: [0],
      ITSplit7: [""],
      ITSplit7AMT: [0],
      ITSplit8: [""],
      ITSplit8AMT: [0],
      ITSplit9: [""],
      ITSplit9AMT: [0],
      ITSplit10: [""],
      ITSplit10AMT: [0],
      ITSplit11: [""],
      ITSplit11AMT: [0],
      ITSplit12: [""],
      ITSplit12AMT: [0],
      FC_Fare_Buy: [0],
      FC_Fare_CommAmt: [0],
      FC_Fare_Sell: [0],
      FC_Tax1_Buy: [0],
      FC_Tax1_CommAmt: [0],
      FC_Tax1_Sell: [0],
      FC_Tax1_CID: [this.hcCurrency],
      FC_Tax1_CIDExRate: [this.hcExRate],
      FC_Tax2_Buy: [0],
      FC_Tax2_CommAmt: [0],
      FC_Tax2_Sell: [0],
      FC_Tax2_CID: [this.hcCurrency],
      FC_Tax2_CIDExRate: [this.hcExRate],
      FC_Tax3_Buy: [0],
      FC_Tax3_CommAmt: [0],
      FC_Tax3_Sell: [0],
      FC_Tax3_CID: [this.hcCurrency],
      FC_Tax3_CIDExRate: [this.hcExRate],
      FC_Tax4_Buy: [0],
      FC_Tax4_CommAmt: [0],
      FC_Tax4_Sell: [0],
      FC_Tax4_CID: [this.hcCurrency],
      FC_Tax4_CIDExRate: [this.hcExRate],
      FC_Tot_Buy: [0],
      FC_Tot_CommAmt: [0],
      FC_Tot_Sell: [0],
      FC_DISCAMT: [0],
      FC_NETSELL: [0],
      UNUSEDTICKET: [0],
      GUIDItinerary: [0],
      TICKETORDER: [0],
      LOYALTYPOINTS: [0],
      LOYALTYPOINTSMEMBNO: [""],
      TOMSVATTAXNO: [0],
      TOMSVATAMT: [0],
      FCCommPer: [0],
      FCCommAmt: [0],
      FCSell: [0],
      HCCommPer: [0],
      HCCommAmt: [0],
      HCSell: [0],
      LOYALTYPOINTSAMT: [0],
      LOYALTYPOINTSBAL: [0],
      TOMSVATCID: [""],
      PDUNIQUEREF: [""],
      ATD_PCC: [""],
      TYPE6_Tax5: [""],
      DESC6_Tax5: [""],
      BUY6_Tax5: [0],
      COMMP6_Tax5: [0],
      COMMA6_Tax5: [0],
      SELL6_Tax5: [0],
      FC_Tax5_Buy: [0],
      FC_Tax5_CommAmt: [0],
      FC_Tax5_Sell: [0],
      FC_Tax5_CID: [this.hcCurrency],
      FC_Tax5_CIDExRate: [this.hcExRate],
      PONOCCDeferredPosted: [0],
      BOOKEDBY: [sessionStorage.getItem("uname")],
      BAGGALLOW: [""],
      TaxExcludingSalesTax: [0],
      BaseFare: [0],
      BSPTAXTAXNO: [0],

      Reasoncode: [""],
      FC_Tax1_CommPer: [0],
      FC_Tax2_CommPer: [0],
      FC_Tax3_CommPer: [0],
      FC_Tax4_CommPer: [0],
      FC_Tax5_CommPer: [0],
      FC_Tax6_CommPer: [0],

      TotalTax: [0],
      PurchaseTax: [0],
      OtherTax: [0],
      PurchaseValue: [0],
      GSTHSTOnComm: [0],
      InterInd: [],

      Markup: [0],
      CostofSale: [0],
      CostingSupplierGrossValue: [0],
      CostingSupplierServiceFee: [0],
      CostingTaxNo: [1],
      CostingTaxRate: [0],
      CostingSupplierTaxAmount: [0],
      CostingSupplierNetBuyPrice: [0],
      CostingCustomerTaxAmount: [0],
      CostingCustomerNetSellPrice: [0],
      CostingCustomerSalesValue: [0],
      CostingCustomerServiceFee: [0],
      CostingVATType: ["NV"],
      CostingSuppComm: [0],
      BSPPurchaseTax: [0],
      BSPPurchaseTaxRate: [0],
      FCBuy: [0],
      SELLVAT: [0],
      CardValidityStr: [""],
      SEATS: [""],
      FareType: [""],
      SupplierDeposit: [0],
      SELLTAXNO: [0],
      SELLTAXRATE: [0],
      FC_SELLTAXAMT: [0],
      HC_SELLTAXAMT: [0],
      EXCHANGE1: [this.hcExRate],
      CURR1: [this.hcCurrency],


    });
    //#endregion for airticketEditForm
    //#region for calling file details,ticket details,exchange rate

    if (this.guid != null) {
      //this.getFileDetails();
      this.getAirTicket(this.orderNo, this.guid);
    } else {
      this.airticketEditForm.controls.CURR.setValue(this.hcCurrency);
      this.airticketEditForm.controls.ATYPE.setValue("TKT");
      // this.airticketEditForm.controls.TICKDATE.setValue("01/Jan/1900");
      this.getExchangeRate(this.hcCurrency, new Date());
      this.getFileDetails();
      this.chkVat = false;
      this.onNoVatChange();
      this.loaded = true;
    }
    //#endregion for calling file details,ticket details,exchange rate
    //#region for binding currency
    this.checkcurrencyload();
    //#endregion for binding currency

    //#region for loading currency,PASSENGERMASTER,USERS,GDS,AIRWAYS,supplier,IATANOand Tax
    this.subscription.add(
      this.lookupApi.GetAllLookup("CURRENCY").subscribe(data => {
        if (data != null && data != undefined && data["ResponseData"] != null) {
          this.CurrencyList = data["ResponseData"];
          this.Currency = data["ResponseData"];
          if (this.airticketEditForm.controls.PDFCURID.value != "") {
            this.CurrencypyselectedItem = this.Currency.find(
              item => item.Code == this.airticketEditForm.controls.PDFCURID.value
            );
          }
          if (this.airticketEditForm.controls.CURR.value != "") {

            this.CurrencyselectedItem = this.CurrencyList.find(
              item => item.Code == this.airticketEditForm.controls.CURR.value

            );
          }
        }
      })
    );
    this.subscription.add(
      this.lookupApi
        .GetLookupById("PASSENGERMASTER", this.orderNo, "''")
        .subscribe(data => {
          if (
            data != null &&
            data != undefined &&
            data["ResponseData"] != null
          ) {
            this.Passenger = data["ResponseData"];
            if (this.airticketEditForm.controls.PASSNAME.value != "") {
              this.PassengerselectedItem = this.Passenger.find(
                item =>
                  item.Name == this.airticketEditForm.controls.PASSNAME.value
              );
            }
          }
        })
    );
    this.subscription.add(
      this.lookupApi.GetAllLookup("USERS").subscribe(data => {
        if (data != null && data != undefined && data["ResponseData"] != null) {
          this.BOOKEBYList = data["ResponseData"];
          if (this.airticketEditForm.controls.BOOKEDBY.value != "") {
            this.bookbyselectedItem = this.BOOKEBYList.find(
              item =>
                item.Name == this.airticketEditForm.controls.BOOKEDBY.value
            );
          }
        }
      })
    );
    this.subscription.add(
      this.lookupApi.GetAllLookup("GDS").subscribe(data => {
        if (data != null && data != undefined && data["ResponseData"] != null) {
          this.GDSList = data["ResponseData"];
          if (this.airticketEditForm.controls.GDS.value != "") {
            this.GDSselectedItem = this.GDSList.find(
              item => item.Name == this.airticketEditForm.controls.GDS.value
            );
          }
        }
      })
    );
    this.subscription.add(
      this.lookupApi.GetAllLookup("AIRWAYS").subscribe(data => {
        if (data != null && data != undefined && data["ResponseData"] != null) {

          this.AIRWAYSList = data["ResponseData"];
          if (this.airticketEditForm.controls.VairlineAIRID.value != "") {
            this.AIRWAYSselectedItem = this.AIRWAYSList.find(
              item =>
                item.UsrCode ==
                this.airticketEditForm.controls.VairlineAIRID.value
            );
          }
        }
      })
    );
    // this.loadSupplier();
    this.subscription.add(
      this.subscription.add(
        this.lookupApi.GetAllLookup("IATANO").subscribe(
          //By Eldhose for case id-286
          data => {
            if (data != null && data != undefined) {
              this.IATANOList = data["ResponseData"];
              if (this.airticketEditForm.controls.IATANO.value != "") {
                this.IATANoSelectedItem = this.IATANOList.find(
                  item =>
                    item.Name == this.airticketEditForm.controls.IATANO.value
                );
              }
            }
          }
        )
      )
    );
    // this.subscription.add(
    //   this.lookupApi.GetAllLookup("TAX").subscribe(data => {
    //     if (data != null && data != undefined && data["ResponseData"] != null) {
    //       this.Tax = data["ResponseData"];
    //       this.commonservice.Tax = data["ResponseData"];
    //       // alert(this.airticketEditForm.controls.ITYPE.value);

    //       if (this.airticketEditForm.controls.CostingTaxNo.value != "") {
    //         this.TaxselectedItem = this.Tax.find(
    //           item =>
    //             item.Code == this.airticketEditForm.controls.CostingTaxNo.value
    //         );
    //         this.commonservice.TaxselectedItem = this.commonservice.Tax.find(
    //           item =>
    //             item.Code == this.airticketEditForm.controls.CostingTaxNo.value
    //         );
    //         // this.InputTaxChange(this.commonservice.TaxselectedItem);
    //       }
    //     }
    //   })
    // );
    //#endregion for loading currency,PASSENGERMASTER,USERS,GDS,AIRWAYS,supplier,IATANOand Tax

    this.onChanges();
    //#region for currency

    this.checkcurrency();
    //#endregion for currency
    if (this.commonservice.package) {

      this.subscription.add(
        this.lookupApi.GetAllLookup("COMPANYCARD").subscribe(data => {
          if (data != null && data != undefined) {
            this.PDQ = data["ResponseData"];

            if (this.airticketEditForm.controls.PDNO.value != "") {
              this.PDQselectedItem = this.PDQ.find(
                item => item.Code == this.airticketEditForm.controls.PDNO.value
              );
              this.subscription.add(
                this.lookupApi
                  .GetPDQCard(this.airticketEditForm.controls.PDNO.value)
                  .subscribe(data => {
                    if (data != null && data != undefined) {
                      this.cardType = data["ResponseData"];
                      if (this.airticketEditForm.controls.PDTYPENO.value != "") {
                        this.PDTYPENOSelectedItem = this.cardType.find(
                          item =>
                            item.CARDID == this.airticketEditForm.controls.PDTYPENO.value
                        );
                      }
                    } // else {

                    // }
                  })
              );
            }
          }
        })
      );
    }
    debugger;
    if (this.custcountry != undefined && this.custcountry != null) {
      this.getOutTaxLookup(this.custcountry);
    }

    if (this.supplierSelectedItem == undefined || this.supplierSelectedItem == null || this.supplierSelectedItem.CONTID == null || this.supplierSelectedItem.CONTID == undefined || this.supplierSelectedItem.CONTID == "") {
      this.subscription.add(
        this.lookupApi.GetDefaultCountry().subscribe(data => {
          if (data != null && data != undefined) {
            var countryID = data["ResponseData"].UsrCode;
            if (countryID != undefined && countryID != null) {
              this.getInputTaxLookup(countryID);
              if (this.custcountry == undefined || this.custcountry == null)
                this.getOutTaxLookup(countryID);
            }
          }
        }
        )
      );
    }
  }
  //#region for Change Event

  //#region for BookedByChange
  public bookedByChange(value: any): void {
    if (value != undefined) {
      this.airticketEditForm.controls.BOOKEDBY.setValue(value.Name);
    } else {
      this.airticketEditForm.controls.BOOKEDBY.setValue("");
    }
  }
  //#endregion for BookedByChange

  //#region for arCityChange

  public arCityChange(value: any): void {
    this.cityselectedItemTo = this.ArCityList.find(item => item.Name == value);

    if (this.cityselectedItemTo != null) {
      this.airticketEditForm.controls.AIRTO.setValue(
        this.cityselectedItemTo.Code
      );
    } else {
      this.cityselectedTo = "";
      this.airticketEditForm.controls.AIRTO.setValue("");
    }
  }
  //#endregion for arCityChange
  //#region for depCityChange
  public depCityChange(value: any): void {
    this.cityselectedItem = this.CityList.find(item => item.Name == value);

    if (this.cityselectedItem != null) {
      this.airticketEditForm.controls.AIRFROM.setValue(
        this.cityselectedItem.Code
      );
    } else {
      this.cityselected = "";
      this.airticketEditForm.controls.AIRFROM.setValue("");
    }
  }
  //#endregion for depCityChange

  //#region for VAT Change
  onNoVatChange() {
    if (this.chkVat == false) {
      this.airticketEditForm.controls.CostingVATType.setValue("NV");
      this.selectdiv("NV");
      this.ShowVat = 0;
      this.VSF = false;
      this.VP = false;
      this.VSC = false;
      this.NV = true;
    } else {
      this.selectdiv('VP');
      this.VP = true;
      this.ShowVat = 1;
      this.divValue = 3;
    }
  }
  //#endregion for VAT Change


  //#region for TAX Change

  public InputTaxChange(value: any,newmarkup:number): void {
    debugger;
    if (value != undefined && value != null) {
      this.commonservice.GetTaxSplit(value.Code).subscribe(x => {
        this.inputTaxDetails = x["ResponseData"]
        this.editinputtax = false;

        if (value != undefined) {
          this.airticketEditForm.controls.CostingTaxNo.setValue(value.Code);
          // this.airticketEditForm.controls.CostingTaxRate.setValue(value.UsrCode);
          if (this.divValue == 3 || this.divValue == 2) {
            this.calculateFormVotService(0,newmarkup);
            this.calculateAirticketdetailsVotserviceOupttax(0);
          } else this.divValue == 4;
          {
            this.calculatetxcommdiv4();
          }
        }
        if (this.TAXRATE == 0) {
          if (this.airticketEditForm.get("HC_SELLTAXAMT") != null)
            this.airticketEditForm.controls.HC_SELLTAXAMT.setValue(
              parseFloat((0).toFixed(2))
            );
          else
            this.airticketEditForm.controls.SELLVAT.setValue(
              parseFloat((0).toFixed(2))
            );
        } else {
          var FolderExRate = this.exRate;
          this.FCSellTaxAmt = (this.TAXRATE * this.fCSellTax) / 100; //parseFloat(FCSell) * parseFloat(SellVat)/100;
          this.HCSellTaxAmt = this.FCSellTaxAmt * FolderExRate;

          if (this.airticketEditForm.get("HC_SELLTAXAMT") != null)
            this.airticketEditForm.controls.HC_SELLTAXAMT.setValue(
              parseFloat(this.HCSellTaxAmt.toFixed(2))
            );
          else
            this.airticketEditForm.controls.SELLVAT.setValue(
              parseFloat(this.HCSellTaxAmt.toFixed(2))
            );
        }
      });
    }
    else {
      if (this.divValue == 4)
        this.airticketEditForm.controls.GSTHSTOnComm.setValue(0);

      else
        this.airticketEditForm.controls.CostingSupplierTaxAmount.setValue(0);
      this.inputTaxDetails = null;
      if (this.divValue == 3 || this.divValue == 2) {
        this.calculateFormVotService(0,newmarkup);
        this.calculateAirticketdetailsVotserviceOupttax(0);
      } else this.divValue == 4;
      {
        this.calculatetxcommdiv4();
      }


    }
  }

  public taxChange(value: any): void {
    this.editinputtax = false;

    if (value != undefined) {
      this.airticketEditForm.controls.CostingTaxNo.setValue(value.Code);
      this.airticketEditForm.controls.CostingTaxRate.setValue(value.UsrCode);
      if (this.divValue == 3 || this.divValue == 2) {
        this.calculateFormVotService(0,0);
        this.calculateAirticketdetailsVotserviceOupttax(0);
      } else this.divValue == 4;
      {
        this.calculatetxcommdiv4();
      }
    } else {
      this.airticketEditForm.controls.CostingTaxNo.setValue("1");
      this.airticketEditForm.controls.CostingTaxRate.setValue("0");
      if (this.divValue == 3 || this.divValue == 2) {
        this.calculateFormVotService(0,0);
        this.calculateAirticketdetailsVotserviceOupttax(0);
      } else this.divValue == 4;
      {
        this.calculatetxcommdiv4();
      }
    }
    if (this.TAXRATE == 0) {
      if (this.airticketEditForm.get("HC_SELLTAXAMT") != null)
        this.airticketEditForm.controls.HC_SELLTAXAMT.setValue(
          parseFloat((0).toFixed(2))
        );
      else
        this.airticketEditForm.controls.SELLVAT.setValue(
          parseFloat((0).toFixed(2))
        );
    } else {
      var FolderExRate = this.exRate;
      this.FCSellTaxAmt = (this.TAXRATE * this.fCSellTax) / 100; //parseFloat(FCSell) * parseFloat(SellVat)/100;
      this.HCSellTaxAmt = this.FCSellTaxAmt * FolderExRate;

      if (this.airticketEditForm.get("HC_SELLTAXAMT") != null)
        this.airticketEditForm.controls.HC_SELLTAXAMT.setValue(
          parseFloat(this.HCSellTaxAmt.toFixed(2))
        );
      else
        this.airticketEditForm.controls.SELLVAT.setValue(
          parseFloat(this.HCSellTaxAmt.toFixed(2))
        );
    }

  }
  //#endregion for TAX Change

  //#region for Currency Change
  public currencyChange(value: any): void {
    if (value !== undefined && value !== null) {
      // console.log('selectionChange', value);
      this.airticketEditForm.controls.CURR.setValue(value.Code);
    } else {
      this.airticketEditForm.controls.CURR.setValue("");
    }
    this.commonservice.buycurrency = this.airticketEditForm.controls.CURR.value;
    this.checkcurrency();
  }
  //#endregion for Currency Change
  //#region for passenger Change
  public passengerChange(value: any): void {

    if (value != null && value != undefined) {
      this.airticketEditForm.controls.PASSNAME.setValue(value.Name);
    } else this.airticketEditForm.controls.PASSNAME.setValue("");
  }
  //#endregion for passenger Change
  //#region for checkbox Unused Ticket Change
  checkboxUnusedTicketChange() {
    //355
    if (this.enableUnusedTicket == true) {
      this.airticketEditForm.controls.UNUSEDTICKET.setValue(1);
    } else {
      this.airticketEditForm.controls.UNUSEDTICKET.setValue(0);
    }
  }
  //#endregion for checkbox Unused Ticket Change

  //#region for gDSChange
  public gDSChange(value: any): void {
    //By Eldhose for case id-12760

    if (value != null && value != undefined) {
      this.airticketEditForm.controls.GDS.setValue(value.Name);
    } else {
      this.airticketEditForm.controls.GDS.setValue("");
    }
  }
  //#endregion for gDSChange
  //#region for airwaysChange
  public airwaysChange(value: any): void {

    if (value != null && value != undefined) {
      this.airticketEditForm.controls.VairlineAIRWAYSCODE.setValue(value.Code);
      this.airticketEditForm.controls.VairlineAIRNAME.setValue(value.SELFBILLING);
      this.airticketEditForm.controls.VairlineAIRID.setValue(value.UsrCode);
      this.airticketEditForm.controls.VAIRNO.setValue(value.ActualName);
    }
    else {
      this.airticketEditForm.controls.VairlineAIRWAYSCODE.setValue("");
      this.airticketEditForm.controls.VairlineAIRNAME.setValue("");
      this.airticketEditForm.controls.VairlineAIRID.setValue("");
      this.airticketEditForm.controls.VAIRNO.setValue("");
    }
  }
  //#endregion for airwaysChange
  //#region for IATANo Change
  public iATANoChange(value: any): void {
    //By Eldhose for case id-286
    if (value != null && value != undefined) {
      this.airticketEditForm.controls.IATANO.setValue(value.Name);
    } else this.airticketEditForm.controls.IATANO.setValue("");
  }
  //#endregion for IATANo Change
  //#endregion for Change Event

  //#region for Filterdeparture
  handleFilterdeparture(value) {
    if (value.length >= 3) {
      this.subscription.add(
        this.lookupApi.SearchCity(value, "1").subscribe(data => {
          if (
            data != null &&
            data != undefined &&
            data["ResponseData"] != null
          ) {
            this.CityList = data["ResponseData"];
          }
        })
      );
    } else {
      this.autocompletedeparture.toggle(false);
    }
  }
  //#end region for Filterdeparture

  //#region for handleFilterarrival
  handleFilterarrival(value) {
    if (value.length >= 3) {
      this.subscription.add(
        this.lookupApi.SearchCity(value, "1").subscribe(data => {
          if (
            data != null &&
            data != undefined &&
            data["ResponseData"] != null
          ) {
            this.ArCityList = data["ResponseData"];
          }
        })
      );
    } else {
      this.autocompletearrival.toggle(false);
    }
  }

  //#endregion for handleFilterarrival

  // convenience getter for easy access to form fields
  get f() {
    return this.airticketEditForm.controls;
  }

  //#region for GetFileDetails

  getFileDetails() {
    this.subscription.add(
      this.apiFile.getFileDetails().subscribe(data => {
        if (data != null && data != undefined) {
          //  console.log(JSON.stringify(data, null, 4));

          //    console.log(JSON.stringify(data["ResponseData"], null, 4));
          this.fileDetail = data;

          this.airticketEditForm.controls.CURR.setValue(
            this.fileDetail.ResponseData.COMCID
          );
          this.airticketEditForm.controls.FC_Tax1_CID.setValue(
            this.fileDetail.ResponseData.COMCID
          );
          this.airticketEditForm.controls.FC_Tax2_CID.setValue(
            this.fileDetail.ResponseData.COMCID
          );
          this.airticketEditForm.controls.FC_Tax3_CID.setValue(
            this.fileDetail.ResponseData.COMCID
          );
          this.airticketEditForm.controls.FC_Tax4_CID.setValue(
            this.fileDetail.ResponseData.COMCID
          );
          this.airticketEditForm.controls.FC_Tax5_CID.setValue(
            this.fileDetail.ResponseData.COMCID
          );
          /// this.BSPTAXEnable=this.fileDetail.ResponseData.BSPTAXEnable;
          //  this.airticketEditForm.controls.FC_Tax6_CID.setValue( this.fileDetail.ResponseData.COMCID);
          this.airticketEditForm.controls.BSPTAXTAXNO.setValue(
            this.fileDetail.ResponseData.BSPTAXTAXNO
          );
          this.getExchangeRate(this.fileDetail.ResponseData.COMCID, new Date());
        }
      })
    );
    this.subscription.add(
      this.apiFile.GetCompanySettings("Ticket").subscribe(data => {
        if (data != null && data != undefined) {
          this.fileDetail = data;
          debugger;
          this.BSPTAXEnable = this.fileDetail.ResponseData.BSPTAXEnable;

          if (this.airticketEditForm.controls.ITSplit1.value == "") {
            this.airticketEditForm.controls.ITSplit1.setValue(
              this.fileDetail.ResponseData.BSPTAXCountryTax1
            );
          }
          if (this.airticketEditForm.controls.ITSplit2.value == "") {
            this.airticketEditForm.controls.ITSplit2.setValue(
              this.fileDetail.ResponseData.BSPTAXCountryTax2
            );
          }
          if (this.airticketEditForm.controls.ITSplit3.value == "") {
            this.airticketEditForm.controls.ITSplit3.setValue(
              this.fileDetail.ResponseData.BSPTAXCountryTax3
            );
          }
          if (this.airticketEditForm.controls.ITSplit4.value == "") {
            this.airticketEditForm.controls.ITSplit4.setValue(
              this.fileDetail.ResponseData.BSPTAXCountryTax4
            );
          }
          if (this.airticketEditForm.controls.ITSplit5.value == "") {
            this.airticketEditForm.controls.ITSplit5.setValue(
              this.fileDetail.ResponseData.BSPTAXCountryTax5
            );
          }

          this.loadedfile = true;
        }
      })
    );
  }
  //#endregion for GetFileDetails
  //#region for getAirTicket
  getAirTicket(id, guid) {

    this.spinner.show();
    this.subscription.add(
      this.api.getAirTicketDetails(id, guid, false).subscribe(data => {

        this.spinner.hide();
        //  alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
        //this.AirticketEditForm = this.formBuilder.group(data);
        if (data != null && data["ResponseData"] != null) {

          this.airticketEditForm.setValue({
            GUIDItinerary: data.ResponseData.GUIDItinerary,
            SUPPNAME: data.ResponseData.SUPPNAME,
            FOLDERNO: data.ResponseData.FOLDERNO,
            PASSNAME: data.ResponseData.PASSNAME,
            TICKETNO: data.ResponseData.TICKETNO,
            TYPE: data.ResponseData.TYPE,
            AIRLINE: data.ResponseData.AIRLINE,
            AIRFROM: data.ResponseData.AIRFROM,
            AIRTO: data.ResponseData.AIRTO,
            PAYABLE: data.ResponseData.PAYABLE,
            PRTYCODE: data.ResponseData.PRTYCODE,
            STATUS: data.ResponseData.STATUS,
            VLOCATOR: data.ResponseData.VLOCATOR,
            FNAME: data.ResponseData.FNAME,
            GDS: data.ResponseData.GDS,
            BSP: data.ResponseData.BSP,
            JOURNEY:
              data.ResponseData.JOURNEY == 1
                ? "Return"
                : data.ResponseData.JOURNEY == 2
                  ? "One way"
                  : "",
            TICKDATE:
              data.ResponseData.TICKDATE != null
                ? data.ResponseData.TICKDATE.toString() == "1900-01-01T00:00:00"
                  ? null
                  : new Date(data.ResponseData.TICKDATE)
                : null,
            BOOKBY: data.ResponseData.BOOKBY,
            IATANO: data.ResponseData.IATANO,
            BDATE:
              data.ResponseData.BDATE != null
                ? data.ResponseData.BDATE.toString() == "1900-01-01T00:00:00"
                  ? null
                  : new Date(data.ResponseData.BDATE)
                : null,
            ADATE:
              data.ResponseData.ADATE != null
                ? data.ResponseData.ADATE.toString() == "1900-01-01T00:00:00"
                  ? null
                  : new Date(data.ResponseData.ADATE)
                : null,
            FREMARKS: data.ResponseData.FREMARKS,
            CURR: data.ResponseData.CURR,
            FARE: data.ResponseData.FARE == null ? 0 : data.ResponseData.FARE,
            FARENAME: data.ResponseData.FARENAME,
            FAREBASIS: data.ResponseData.FAREBASIS,
            TYPE1: data.ResponseData.TYPE1,
            DESC1: data.ResponseData.DESC1,
            BUY1: data.ResponseData.BUY1,
            COMMP1: data.ResponseData.COMMP1,
            COMMA1: data.ResponseData.COMMA1,
            SELL1: data.ResponseData.SELL1,
            TYPE2: data.ResponseData.TYPE2,
            DESC2: data.ResponseData.DESC2,
            BUY2: data.ResponseData.BUY2,
            COMMP2: data.ResponseData.COMMP2,
            COMMA2: data.ResponseData.COMMA2,
            SELL2: data.ResponseData.SELL2,
            TYPE3: data.ResponseData.TYPE3,
            DESC3: data.ResponseData.DESC3,
            BUY3: data.ResponseData.BUY3,
            COMMP3: data.ResponseData.COMMP3,
            COMMA3: data.ResponseData.COMMA3,
            SELL3: data.ResponseData.SELL3,
            TYPE4: data.ResponseData.TYPE4,
            DESC4: data.ResponseData.DESC4,
            BUY4: data.ResponseData.BUY4,
            COMMP4: data.ResponseData.COMMP4,
            COMMA4: data.ResponseData.COMMA4,
            SELL4: data.ResponseData.SELL4,
            BUYTOT: data.ResponseData.BUYTOT,
            COMMPTOT: data.ResponseData.COMMPTOT,
            COMMATOT: data.ResponseData.COMMATOT,
            SELLTOT: data.ResponseData.SELLTOT,
            COMONLY: data.ResponseData.COMONLY,
            Remark: data.ResponseData.Remark,
            VairlineAIRWAYSCODE: data.ResponseData.VairlineAIRWAYSCODE,
            VairlineAIRNAME: data.ResponseData.VairlineAIRNAME,
            ATYPE: data.ResponseData.ATYPE,
            AMODE: data.ResponseData.AMODE,
            SLNO: data.ResponseData.SLNO,
            PROJNO: data.ResponseData.PROJNO,
            VairlineAIRID: data.ResponseData.VairlineAIRID,
            TKTCODE: data.ResponseData.TKTCODE,
            DISCPER: data.ResponseData.DISCPER,
            DISCAMT: data.ResponseData.DISCAMT,
            NETSELL:
              data.ResponseData.NETSELL == null ? 0 : data.ResponseData.NETSELL,
            CommOn: data.ResponseData.CommOn,
            ITYPE: data.ResponseData.ITYPE,
            TYPE5: data.ResponseData.TYPE5,
            DESC5: data.ResponseData.DESC5,
            BUY5: data.ResponseData.BUY5,
            COMMP5: data.ResponseData.COMMP5,
            COMMA5: data.ResponseData.COMMA5,
            SELL5:
              data.ResponseData.SELL5 == null ? 0 : data.ResponseData.SELL5,
            LowFB: data.ResponseData.LowFB,
            LowFare: data.ResponseData.LowFare,
            HighFB: data.ResponseData.HighFB,
            HighFare: data.ResponseData.HighFare,
            Fareoffered: data.ResponseData.Fareoffered,
            PotentialSaving: data.ResponseData.PotentialSaving,
            ActualSaving: data.ResponseData.ActualSaving,
            CARDID: data.ResponseData.CARDID,
            CARDNUMBER: data.ResponseData.CARDNUMBER,
            CARDValidity:
              data.ResponseData.CARDValidity != null
                ? data.ResponseData.CARDValidity.toString() ==
                  "1900-01-01T00:00:00"
                  ? null
                  : new Date(data.ResponseData.CARDValidity)
                : null,
            CARDAUTHCODE: data.ResponseData.CARDAUTHCODE,
            PNR: data.ResponseData.PNR,
            PDNO: data.ResponseData.PDNO,
            PDTYPENO: data.ResponseData.PDTYPENO,
            ADocDate:
              data.ResponseData.ADocDate != null
                ? data.ResponseData.ADocDate.toString() == "1900-01-01T00:00:00"
                  ? null
                  : new Date(data.ResponseData.ADocDate)
                : null,
            //ADocDate : new Date(data.ResponseData.ADATE),
            VAIRNO: data.ResponseData.VAIRNO,
            Forgone: data.ResponseData.Forgone,
            AddRemarks: data.ResponseData.AddRemarks,
            SupplierNotes: data.ResponseData.SupplierNotes,
            POSTINGDATE:
              data.ResponseData.POSTINGDATE != null
                ? data.ResponseData.POSTINGDATE.toString() ==
                  "1900-01-01T00:00:00"
                  ? null
                  : new Date(data.ResponseData.POSTINGDATE)
                : null,
            // POSTINGDATE : new Date(data.ResponseData.POSTINGDATE),
            CancelledBooking: data.ResponseData.CancelledBooking,
            TicketingDeadline:
              data.ResponseData.TicketingDeadline != null
                ? data.ResponseData.TicketingDeadline.toString() ==
                  "1900-01-01T00:00:00"
                  ? null
                  : new Date(data.ResponseData.TicketingDeadline)
                : null,
            // TicketingDeadline :this.FormatDate( new Date(data.ResponseData.TicketingDeadline)),
            PDAMOUNT: data.ResponseData.PDAMOUNT,
            TITLE: data.ResponseData.TITLE,
            PONO: data.ResponseData.PONO,
            PONOYEARID: data.ResponseData.PONOYEARID,
            PAYMENTID: data.ResponseData.PAYMENTID,
            PMTID: data.ResponseData.PMTID,
            DEFDEPPER: data.ResponseData.DEFDEPPER,
            DEFDEP: data.ResponseData.DEFDEP,
            DepositDueDate:
              data.ResponseData.DepositDueDate != null
                ? data.ResponseData.DepositDueDate.toString() ==
                  "1900-01-01T00:00:00"
                  ? null
                  : new Date(data.ResponseData.DepositDueDate)
                : null,
            //DepositDueDate : new Date(data.ResponseData.DepositDueDate),
            BalanceDueDate:
              data.ResponseData.BalanceDueDate != null
                ? data.ResponseData.BalanceDueDate.toString() ==
                  "1900-01-01T00:00:00"
                  ? null
                  : new Date(data.ResponseData.BalanceDueDate)
                : null,
            // BalanceDueDate : new Date(data.ResponseData.BalanceDueDate),
            BalanceDueAmount: data.ResponseData.BalanceDueAmount,
            PDFCURID: data.ResponseData.PDFCURID,
            PDEXRATE: data.ResponseData.PDEXRATE,
            PDFCAMT: data.ResponseData.PDFCAMT,
            ITSplit1: data.ResponseData.ITSplit1,
            ITSplit1AMT: data.ResponseData.ITSplit1AMT,
            ITSplit2: data.ResponseData.ITSplit2,
            ITSplit2AMT: data.ResponseData.ITSplit2AMT,
            ITSplit3: data.ResponseData.ITSplit3,
            ITSplit3AMT: data.ResponseData.ITSplit3AMT,
            ITSplit4: data.ResponseData.ITSplit4,
            ITSplit4AMT: data.ResponseData.ITSplit4AMT,
            ITSplit5: data.ResponseData.ITSplit5,
            ITSplit5AMT: data.ResponseData.ITSplit5AMT,
            ITSplit6: data.ResponseData.ITSplit6,
            ITSplit6AMT: data.ResponseData.ITSplit6AMT,
            ITSplit7: data.ResponseData.ITSplit7,
            ITSplit7AMT: data.ResponseData.ITSplit7AMT,
            ITSplit8: data.ResponseData.ITSplit8,
            ITSplit8AMT: data.ResponseData.ITSplit8AMT,
            ITSplit9: data.ResponseData.ITSplit9,
            ITSplit9AMT: data.ResponseData.ITSplit9AMT,
            ITSplit10: data.ResponseData.ITSplit10,
            ITSplit10AMT: data.ResponseData.ITSplit10AMT,
            ITSplit11: data.ResponseData.ITSplit11,
            ITSplit11AMT: data.ResponseData.ITSplit11AMT,
            ITSplit12: data.ResponseData.ITSplit12,
            ITSplit12AMT: data.ResponseData.ITSplit12AMT,
            FC_Fare_Buy: data.ResponseData.FC_Fare_Buy,
            FC_Fare_CommAmt: data.ResponseData.FC_Fare_CommAmt,
            FC_Fare_Sell: data.ResponseData.FC_Fare_Sell,
            FC_Tax1_Buy: data.ResponseData.FC_Tax1_Buy,
            FC_Tax1_CommAmt: data.ResponseData.FC_Tax1_CommAmt,
            FC_Tax1_Sell: data.ResponseData.FC_Tax1_Sell,
            FC_Tax1_CID: data.ResponseData.FC_Tax1_CID,
            FC_Tax1_CIDExRate: data.ResponseData.FC_Tax1_CIDExRate,
            FC_Tax2_Buy: data.ResponseData.FC_Tax2_Buy,
            FC_Tax2_CommAmt: data.ResponseData.FC_Tax2_CommAmt,
            FC_Tax2_Sell: data.ResponseData.FC_Tax2_Sell,
            FC_Tax2_CID: data.ResponseData.FC_Tax2_CID,
            FC_Tax2_CIDExRate: data.ResponseData.FC_Tax2_CIDExRate,
            FC_Tax3_Buy: data.ResponseData.FC_Tax3_Buy,
            FC_Tax3_CommAmt: data.ResponseData.FC_Tax3_CommAmt,
            FC_Tax3_Sell: data.ResponseData.FC_Tax3_Sell,
            FC_Tax3_CID: data.ResponseData.FC_Tax3_CID,
            FC_Tax3_CIDExRate: data.ResponseData.FC_Tax3_CIDExRate,
            FC_Tax4_Buy: data.ResponseData.FC_Tax4_Buy,
            FC_Tax4_CommAmt: data.ResponseData.FC_Tax4_CommAmt,
            FC_Tax4_Sell: data.ResponseData.FC_Tax4_Sell,
            FC_Tax4_CID: data.ResponseData.FC_Tax4_CID,
            FC_Tax4_CIDExRate: data.ResponseData.FC_Tax4_CIDExRate,
            FC_Tot_Buy: data.ResponseData.FC_Tot_Buy,
            FC_Tot_CommAmt: data.ResponseData.FC_Tot_CommAmt,
            FC_Tot_Sell: data.ResponseData.FC_Tot_Sell,
            FC_DISCAMT: data.ResponseData.FC_DISCAMT,
            FC_NETSELL: data.ResponseData.FC_NETSELL,
            UNUSEDTICKET: data.ResponseData.UNUSEDTICKET,
            TICKETORDER: data.ResponseData.TICKETORDER,
            LOYALTYPOINTS: data.ResponseData.LOYALTYPOINTS,
            LOYALTYPOINTSMEMBNO: data.ResponseData.LOYALTYPOINTSMEMBNO,
            TOMSVATTAXNO: data.ResponseData.TOMSVATTAXNO,
            TOMSVATAMT: data.ResponseData.TOMSVATAMT,
            FCCommPer: data.ResponseData.FCCommPer,
            FCCommAmt: data.ResponseData.FCCommAmt,
            FCSell: data.ResponseData.FCSell,
            HCCommPer: data.ResponseData.HCCommPer,
            HCCommAmt: data.ResponseData.HCCommAmt,
            HCSell: data.ResponseData.HCSell,
            LOYALTYPOINTSAMT: data.ResponseData.LOYALTYPOINTSAMT,
            LOYALTYPOINTSBAL: data.ResponseData.LOYALTYPOINTSBAL,
            TOMSVATCID: data.ResponseData.TOMSVATCID,
            PDUNIQUEREF: data.ResponseData.PDUNIQUEREF,
            ATD_PCC: data.ResponseData.ATD_PCC,
            TYPE6_Tax5: data.ResponseData.TYPE6_Tax5,
            DESC6_Tax5: data.ResponseData.DESC6_Tax5,
            BUY6_Tax5: data.ResponseData.BUY6_Tax5,
            COMMP6_Tax5: data.ResponseData.COMMP6_Tax5,
            COMMA6_Tax5: data.ResponseData.COMMA6_Tax5,
            SELL6_Tax5: data.ResponseData.SELL6_Tax5,
            FC_Tax5_Buy: data.ResponseData.FC_Tax5_Buy,
            FC_Tax5_CommAmt: data.ResponseData.FC_Tax5_CommAmt,
            FC_Tax5_Sell: data.ResponseData.FC_Tax5_Sell,
            FC_Tax5_CID: data.ResponseData.FC_Tax5_CID,
            FC_Tax5_CIDExRate: data.ResponseData.FC_Tax5_CIDExRate,
            PONOCCDeferredPosted: data.ResponseData.PONOCCDeferredPosted,
            BOOKEDBY: data.ResponseData.BOOKEDBY,
            BAGGALLOW: data.ResponseData.BAGGALLOW,
            TaxExcludingSalesTax: data.ResponseData.TaxExcludingSalesTax,
            BaseFare: data.ResponseData.BaseFare,
            Reasoncode: data.ResponseData.Reasoncode,
            BSPTAXTAXNO: this.bsptaxNo,
            EXCHANGE: data.ResponseData.EXCHANGE,
            GSTHSTOnComm: data.ResponseData.GSTHSTOnComm,
            FC_Tax1_CommPer: this.getPercentage(
              data.ResponseData.FC_Fare_CommAmt,
              data.ResponseData.FC_Fare_Sell
            ),
            FC_Tax2_CommPer: this.getPercentage(
              data.ResponseData.FC_Tax1_CommAmt,
              data.ResponseData.FC_Tax1_Sell
            ),
            FC_Tax3_CommPer: this.getPercentage(
              data.ResponseData.FC_Tax2_CommAmt,
              data.ResponseData.FC_Tax2_Sell
            ),
            FC_Tax4_CommPer: this.getPercentage(
              data.ResponseData.FC_Tax3_CommAmt,
              data.ResponseData.FC_Tax3_Sell
            ),
            FC_Tax5_CommPer: this.getPercentage(
              data.ResponseData.FC_Tax4_CommAmt,
              data.ResponseData.FC_Tax4_Sell
            ),
            FC_Tax6_CommPer: this.getPercentage(
              data.ResponseData.FC_Tax5_CommAmt,
              data.ResponseData.FC_Tax5_Sell
            ),
            TotalTax:
              data.ResponseData.BUY2 +
              data.ResponseData.BUY3 +
              data.ResponseData.BUY4 +
              data.ResponseData.BUY5,
            PurchaseTax:
              data.ResponseData.ITSplit1AMT +
              data.ResponseData.ITSplit2AMT +
              data.ResponseData.ITSplit3AMT +
              data.ResponseData.ITSplit4AMT +
              data.ResponseData.ITSplit5AMT,
            OtherTax:
              data.ResponseData.BUY2 +
              data.ResponseData.BUY3 +
              data.ResponseData.BUY4 +
              data.ResponseData.BUY5 -
              (data.ResponseData.ITSplit1AMT +
                data.ResponseData.ITSplit2AMT +
                data.ResponseData.ITSplit3AMT +
                data.ResponseData.ITSplit4AMT +
                data.ResponseData.ITSplit5AMT),
            PurchaseValue:
              data.ResponseData.BUY1 +
              (data.ResponseData.BUY2 +
                data.ResponseData.BUY3 +
                data.ResponseData.BUY4 +
                data.ResponseData.BUY5 -
                (data.ResponseData.ITSplit1AMT +
                  data.ResponseData.ITSplit2AMT +
                  data.ResponseData.ITSplit3AMT +
                  data.ResponseData.ITSplit4AMT +
                  data.ResponseData.ITSplit5AMT)),
            InterInd:
              data.ResponseData.InterInd == ""
                ? ""
                : data.ResponseData.InterInd == "I"
                  ? "International"
                  : data.ResponseData.InterInd == "D"
                    ? "Domestic"
                    : data.ResponseData.InterInd == "T"
                      ? "Transborder"
                      : "",
            Markup: data.ResponseData.CostingCustomerServiceFee,
            CostofSale: data.ResponseData.CostingCustomerSalesValue,
            CostingSupplierGrossValue:
              data.ResponseData.CostingSupplierGrossValue,
            CostingCustomerSalesValue:
              data.ResponseData.CostingCustomerSalesValue,
            CostingCustomerServiceFee:
              data.ResponseData.CostingCustomerServiceFee,
            CostingCustomerNetSellPrice:
              data.ResponseData.CostingCustomerNetSellPrice,
            CostingVATType: data.ResponseData.CostingVATType,
            CostingSupplierServiceFee:
              data.ResponseData.CostingSupplierServiceFee,
            CostingTaxNo: data.ResponseData.CostingTaxNo,
            CostingTaxRate: data.ResponseData.CostingTaxRate,
            CostingSupplierTaxAmount:
              data.ResponseData.CostingSupplierTaxAmount,
            CostingSupplierNetBuyPrice:
              data.ResponseData.CostingSupplierNetBuyPrice,
            CostingCustomerTaxAmount:
              data.ResponseData.CostingCustomerTaxAmount,
            CostingSuppComm: data.ResponseData.CostingSuppComm,
            FCBuy: data.ResponseData.FC_Tot_Buy,
            BSPPurchaseTax: [0],
            BSPPurchaseTaxRate: [0],
            SELLVAT: [0],
            CardValidityStr: data.ResponseData.CardValidityStr,
            SEATS: data.ResponseData.SEATS,
            FareType: data.ResponseData.FareType,
            SupplierDeposit: data.ResponseData.SupplierDeposit,
            SELLTAXNO: data.ResponseData.SELLTAXNO,
            SELLTAXRATE: data.ResponseData.SELLTAXRATE,
            FC_SELLTAXAMT: data.ResponseData.FC_SELLTAXAMT,
            HC_SELLTAXAMT: data.ResponseData.HC_SELLTAXAMT,
            CURR1: data.ResponseData.CURR,
            EXCHANGE1: data.ResponseData.EXCHANGE,
          });
          this.FC_Tot_BuyInitalvalue = data.ResponseData.FC_Tot_Buy;
          this.FCSell = data.ResponseData.FCSell
          this.loadPaymethod();
          this.ATYPESelected = this.airticketEditForm.controls.ATYPE.value;
          if (data.ResponseData.GUIDItinerary != null) {
            this.taxsplitCalculation();
          }

          this.PONO = this.airticketEditForm.controls.PONO.value;
          if (this.PONO > 0) {
            this.pocreated = false;
          } else {
            this.pocreated = true;
          }

          if (data.ResponseData.IATANO != "") {
            //By Case id-286
            this.IATANoSelectedItem = this.IATANOList.find(
              item => item.Name == data.ResponseData.IATANO
            );
          }
          if (data.ResponseData.GDS != "") {
            //By Case id-355
            this.GDSselectedItem = this.GDSList.find(
              item => item.Name == data.ResponseData.GDS
            );
          }
          if (data.ResponseData.VairlineAIRID != "") {
            //By Case id-355
            this.AIRWAYSselectedItem = this.AIRWAYSList.find(
              item => item.UsrCode == data.ResponseData.VairlineAIRID
            );
          }

          if (data.ResponseData.CostingTaxNo != "") {
            //By Case id-286
            this.TaxselectedItem = this.Tax.find(
              item => item.Code == data.ResponseData.CostingTaxNo
            );
            this.commonservice.TaxselectedItem = this.commonservice.Tax.find(
              item => item.Code == data.ResponseData.CostingTaxNo
            );
          }
          if (data.ResponseData.UNUSEDTICKET == 1) {
            this.enableUnusedTicket = true;
          } else {
            this.enableUnusedTicket = false;
          }
        } else {
          this.spinner.hide();
        }

        if (
          this.airticketEditForm.controls.PASSNAME.value != "" &&
          this.Passenger.length > 0
        ) {
          this.PassengerselectedItem = this.Passenger.find(
            item => item.Name == this.airticketEditForm.controls.PASSNAME.value
          );
        }
        if (
          this.airticketEditForm.controls.PRTYCODE.value != "" &&
          this.SupplierList.length > 0
        ) {
          this.supplierSelectedItem = this.SupplierList.find(
            item => item.Code == this.airticketEditForm.controls.PRTYCODE.value
          );
          this.supplierSelected = this.supplierSelectedItem.Name;
          this.SELFBILLING = this.supplierSelectedItem.SELFBILLING;
        }
        if (
          this.airticketEditForm.controls.CURR.value != "" &&
          this.CurrencyList.length > 0
        ) {
          this.CurrencyselectedItem = this.CurrencyList.find(
            item => item.Code == this.airticketEditForm.controls.CURR.value
          );
        }

        // this.airticketEditForm.controls.FC_Tax1_CID.setValue(this.hcCurrency);
        // this.airticketEditForm.controls.FC_Tax2_CID.setValue(this.hcCurrency);
        // this.airticketEditForm.controls.FC_Tax3_CID.setValue(this.hcCurrency);
        // this.airticketEditForm.controls.FC_Tax4_CID.setValue(this.hcCurrency);
        // this.airticketEditForm.controls.FC_Tax5_CID.setValue(this.hcCurrency);
        if (
          this.airticketEditForm.controls.GUIDItinerary.value != null ||
          this.airticketEditForm.controls.GUIDItinerary.value != ""
        ) {
          if (this.airticketEditForm.controls.CostingVATType.value == "NV") {
            this.divValue = 1;
            this.VSF = false;
            this.VP = false;
            this.VSC = false;
            this.NV = true;
            this.chkVat = false;
            this.ShowVat = 0;
          } else if (
            this.airticketEditForm.controls.CostingVATType.value == "VSF"
          ) {
            this.divValue = 2;
            this.VSF = true;
            this.VP = false;
            this.VSC = false;
            this.NV = false;
            this.chkVat = true;
            this.ShowVat = 1;
          } else if (
            this.airticketEditForm.controls.CostingVATType.value == "VP"
          ) {
            this.divValue = 3;
            this.VP = true;
            this.VSC = false;
            this.VSF = false;

            this.NV = false;
            this.chkVat = true;
            this.ShowVat = 1;
          } else if (
            this.airticketEditForm.controls.CostingVATType.value == "VSC"
          ) {
            this.divValue = 4;
            this.VSC = true;
            this.VSF = false;
            this.VP = false;
            this.NV = false;
            this.chkVat = true;
            this.ShowVat = 1;
          } else {
            this.divValue = 1;
            this.VSF = false;
            this.VP = false;
            this.VSC = false;
            this.NV = true;
            this.chkVat = true;
            this.ShowVat = 1;
          }
        }

        this.spinner.hide();
        this.loaded = true;

        this.loadsuppliermaster();
        this.loaddeparturecity(); //By case id-355
        this.loadarrivalcity(); //By case id-355
        if (this.airticketEditForm.get("BSP").value == "1") {
          this.chkVat = false;
          this.removeVAT = true;
          this.onNoVatChange();
        }
        if (this.airticketEditForm.get("BSP").value == "1") {
          if (this.airticketEditForm.controls.CURR1.value != this.airticketEditForm.controls.FC_Tax1_CID.value) {
            this.airticketEditForm.controls.CURR.setValue(this.hcCurrency);
            this.CurrencyselectedItem = this.CurrencyList.find(
              item => item.Code == this.airticketEditForm.controls.CURR.value
            );
            this.commonservice.buycurrency = this.airticketEditForm.controls.CURR.value;
            this.airticketEditForm.controls.EXCHANGE.setValue(this.hcExRate);
          }
          this.isBSP = true;
          this.removeVAT = true;

        } else this.isBSP = false;

        if (this.airticketEditForm.controls.CommOn.value == "Sales") {
          this.commOn = "Sales";
        } else {
          this.commOn = "Purchase";
        }

        if (this.airticketEditForm.controls.CostingTaxNo.value != "" && this.InputTax != null && this.InputTax != undefined && this.InputTax.length > 0) {
          debugger;

          this.InputTaxselectedItem = this.InputTax.find(item => item.Code == this.airticketEditForm.controls.CostingTaxNo.value);
          this.commonservice.InputTaxselectedItem = this.InputTax.find(item => item.Code == this.airticketEditForm.controls.CostingTaxNo.value);
        }

        debugger;
        if (this.airticketEditForm.controls.SELLTAXNO.value != "" && this.OutputTax != null && this.OutputTax != undefined) {

          this.OutputTaxselectedItem = this.OutputTax.find(item => item.Code == this.airticketEditForm.controls.SELLTAXNO.value);
          this.commonservice.OutTaxselectedItem = this.OutputTax.find(item => item.Code == this.airticketEditForm.controls.SELLTAXNO.value);
        }

      })
    );
    this.currencyExRatechange();
  }
  //#endregion for getAirTicket
  ngAfterViewInit(): void {

    //this.supplierSelected = this.supplierSelectedItem.Name;
  }
  onTabSelect(event: any) {

    if (event.index == 0) {
      this.supplierSelected = this.supplierSelectedItem.Name;
      // if (this.supplierSelectedItem != null) {
      //   this.supplierSelected = this.supplierSelectedItem.Name;
      //   this.SELFBILLING = this.supplierSelectedItem.SELFBILLING;
      //   this.autocompletearrival.text=this.supplierSelected.toString();
      //   this.supplierSelected = this.supplierSelectedItem.Name;
      // }
      //   this.supplierSelected=this.supplierSelected.toString();
      //  this.autocompletearrival.text=this.supplierSelected.toString();
      //  this.SupplierList
      //  if(this.autocompletearrival!=undefined)
      //  {
      //   this.loadsuppliermaster();
      //   this.supplierSelected=this.supplierSelected.toString();

      //  }

    }
  }
  //#region for Load supplier And search supplier
  loadsuppliermaster() {
    if (
      this.airticketEditForm.controls.PRTYCODE.value != "" &&
      this.airticketEditForm.controls.PRTYCODE.value != null
    ) {

      this.subscription.add(
        this.lookupApi
          .findSupplier(this.airticketEditForm.controls.PRTYCODE.value)
          .subscribe(data => {
            if (data != null && data != undefined) {
              this.SupplierList = data["ResponseData"];
              if (this.airticketEditForm.controls.PRTYCODE.value != "") {
                this.supplierSelectedItem = this.SupplierList.find(
                  item =>
                    item.Code == this.airticketEditForm.controls.PRTYCODE.value
                );
                if (this.supplierSelectedItem != null) {
                  this.supplierSelected = this.supplierSelectedItem.Name;
                  this.SELFBILLING = this.supplierSelectedItem.SELFBILLING;
                  this.autocompletearrival.text = this.supplierSelected.toString();
                  this.supplierSelected = this.supplierSelectedItem.Name;
                }
              }

            }
          })
      );
    }
  }
  handleFiltersupplier(value) {

    if (value.length >= 3) {
      this.subscription.add(
        this.lookupApi.SearchSupplier(value).subscribe(data => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        })
      );
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {

      if (this.autocompletearrival != undefined)
        this.autocompletearrival.toggle(false);
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        if (value.Code != undefined) {
          this.openSearch = false;
          this.supplierSelectedItem = value;
          this.loadSupplierValue(value.Code)
          this.setsupplierChagedValues()

        }
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }

  loadSupplierValue(value: any) {//14068   v1.0 
    debugger;

    this.subscription.add(this.lookupApi.findSupplier(value).subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.SupplierList = data["ResponseData"];
          if (value != "") {

            this.supplierSelectedItem = this.SupplierList.find(item => item.Code == value);
            if (this.supplierSelectedItem != null) {
              this.supplierSelected = this.supplierSelectedItem.Name;
            }

          }
        }

      }
    ));

  }



  public supplierValueChange(value: any): void {

    if (value != undefined) {
      if (value.Name != undefined) {
        this.supplierSelectedItem = this.SupplierList.find(
          item => item.Name == value.Name
        );
        this.setsupplierChagedValues();
      }
      else if (value != undefined) {
        this.supplierSelectedItem = this.SupplierList.find(
          item => item.Name == value
        );
        this.setsupplierChagedValues();
      }
      if (this.supplierSelectedItem == null && value != undefined) {
        var searchvalue = value.slice(0, 3);
        this.subscription.add(

          this.lookupApi.SearchSupplier(searchvalue).subscribe(data => {
            if (data != null && data != undefined) {
              this.SupplierList = data["ResponseData"];
              this.supplierSelectedItem = this.SupplierList.find(
                item => item.Name == value
              );
              this.setsupplierChagedValues();
            }
          })
        );
      }


    }

  }
  setsupplierChagedValues() {

    if (this.supplierSelectedItem != null) {
      this.supplierDefaultvalue(this.supplierSelectedItem.Code);
      this.airticketEditForm.controls.PRTYCODE.setValue(
        this.supplierSelectedItem.Code
      );
      this.airticketEditForm.controls.SUPPNAME.setValue(
        this.supplierSelectedItem.ActualName
      );
      this.SELFBILLING = this.supplierSelectedItem.SELFBILLING;
      this.IATANoSelectedItem = this.IATANOList.find(
        item => item.Name == this.supplierSelectedItem.IATACode
      ); //Case id-286-eldhose
      this.IATACheckingOnFolder = this.supplierSelectedItem.IATACheckingOnFolder; //Case id-286-eldhose
      if (
        this.SELFBILLING !== null &&
        this.SELFBILLING.toUpperCase() == "YES"
      ) {
        this.airticketEditForm.controls.BSP.setValue(1);
        this.airticketEditForm.controls.CostingVATType.setValue("NV");
        this.chkVat = false;
        this.removeVAT = true; //Case id-286-eldhose
        this.onNoVatChange();
      } else {
        this.airticketEditForm.controls.BSP.setValue(0);
        this.removeVAT = false; //Case id-286-eldhose
      }
      if (this.IATANoSelectedItem != null) {
        //Case id-286-eldhose
        this.airticketEditForm.controls.IATANO.setValue(
          this.supplierSelectedItem.IATACode
        );
      } else this.airticketEditForm.controls.IATANO.setValue("");
      this.disableEnabeBspAndNonBSP();
      this.supplierSelected = this.supplierSelectedItem.Name;
      debugger;
      this.apiShared.BALDUETO = this.supplierSelectedItem.BALDUETO;
      this.getInputTaxLookup(this.supplierSelectedItem.CONTID)
      this.payterm.getBalanceDuedate();

    } else {
      this.supplierSelected = "";
      this.airticketEditForm.controls.PRTYCODE.setValue("");
      this.airticketEditForm.controls.SUPPNAME.setValue("");
      this.SELFBILLING = "N";
      this.airticketEditForm.controls.BSP.setValue(0);
      this.disableEnabeBspAndNonBSP();
      this.removeVAT = false; //Case id-286-eldhose
      this.IATANoSelectedItem = null;
    }

    if (this.IATACheckingOnFolder == "True") {
      //By Case id-286
      this.IATAChecking = true;
    } else if (this.IATACheckingOnFolder == "False") {
      this.IATAChecking = false;
    } else {
      this.IATAChecking = false;
    }
    if (this.IATAChecking === true) {
      //Case id-286-eldhose
      this.validators = [Validators.required];
      this.airticketEditForm.controls.IATANO.setValidators(this.validators);
      this.airticketEditForm.controls.IATANO.updateValueAndValidity();
    } else {
      this.airticketEditForm.controls.IATANO.clearValidators();
      this.airticketEditForm.controls.IATANO.updateValueAndValidity();
    }
  }
  supplierDefaultvalue(suppcode) {

    this.subscription.add(
      this.supplier.GetDefaultValue(suppcode).subscribe(data => {
        if (data !== "" && data !== null && data !== undefined) {
          if (data.CURRENCYCODE !== "") {
            this.airticketEditForm.controls.CURR.setValue(data.CURRENCYCODE);
          }
          this.airticketEditForm.controls.PAYMENTID.setValue(data.PAYTERMID);
          this.airticketEditForm.controls.PMTID.setValue(data.PAYMTDID);
          this.resetChildForm();
          if (
            this.airticketEditForm.controls.CURR.value != "" &&
            this.airticketEditForm.controls.CURR.value != null
          ) {
            this.CurrencyselectedItem = this.CurrencyList.find(
              item => item.Code === this.airticketEditForm.controls.CURR.value
            );

            this.commonservice.buycurrency = this.airticketEditForm.controls.CURR.value;
            if (data.CURRENCYCODE !== "") {
              this.currencyExRate1();
            }
          }
        }
      })
    );
  }
  //#endregion for Load supplier And search supplier

  //#region for Submit
  onFormSubmit() {

    this.spinner.show();
    this.submitted = true;
    debugger;
    if (this.airticketEditForm.controls.COMONLY.value == "W") {
      //By case id-355
      if (
        this.airticketEditForm.controls.PDAMOUNT.value == 0 ||
        this.airticketEditForm.controls.PDNO.value == "0" ||
        this.airticketEditForm.controls.PDTYPENO.value == "0"
      ) {
        this.subscription.add(
          this.translateapi
            .get(["Messages.PleaseSelectCompanyCardWithPaymentOption"])
            .subscribe(translations => {
              this.showMsg =
                translations[
                "Messages.PleaseSelectCompanyCardWithPaymentOption"
                ];
              this.toastr.error(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
            })
        );
        this.spinner.hide();
        return;
      }
    }
    if (this.airticketEditForm.invalid) {
      this.spinner.hide();
      return;
    }
    this.ticketDate =
      this.airticketEditForm.controls.TICKDATE.value == null ||
        this.airticketEditForm.controls.TICKDATE.value == ""
        ? "01-Jan-1900"
        : this.airticketEditForm.controls.TICKDATE.value; //Case id-337
    this.ticketingDeadLine =
      this.airticketEditForm.controls.TicketingDeadline.value == null ||
        this.airticketEditForm.controls.TicketingDeadline.value == ""
        ? "01-Jan-1900"
        : this.airticketEditForm.controls.TicketingDeadline.value; //Case id-337
    this.validFrom =
      this.airticketEditForm.controls.BDATE.value == null ||
        this.airticketEditForm.controls.BDATE.value == ""
        ? "01-Jan-1900"
        : this.airticketEditForm.controls.BDATE.value; //Case id-337
    this.validTo =
      this.airticketEditForm.controls.ADATE.value == null ||
        this.airticketEditForm.controls.ADATE.value == ""
        ? "01-Jan-1900"
        : this.airticketEditForm.controls.ADATE.value; //Case id-337
    this.depositDueDate =
      this.airticketEditForm.controls.DepositDueDate.value == null ||
        this.airticketEditForm.controls.DepositDueDate.value == ""
        ? "01-Jan-1900"
        : this.airticketEditForm.controls.DepositDueDate.value; //Case id-337

    ///this.airticketEditForm.controls.TICKDATE.setValue(new Date("01-Jan-1900"));
    // this.airticketEditForm.controls.TICKDATE.setValue("");

    if (Boolean(this.commonservice.ValidDateRange(this.ticketDate)) == false) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidTicketDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidTicketDate"];
            this.commonservice.showWARNINGtoastrmsg(
              this.showMsg,
              2000,
              true,
              false
            );
          })
      );
      this.spinner.hide();
      return;
    }
    if (
      Boolean(this.commonservice.ValidDateRange(this.ticketingDeadLine)) ==
      false
    ) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidTicketingDeadLine"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidTicketingDeadLine"];
            this.commonservice.showWARNINGtoastrmsg(
              this.showMsg,
              2000,
              true,
              false
            );
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.commonservice.ValidDateRange(this.validFrom)) == false) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidValidFromDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidValidFromDate"];
            this.commonservice.showWARNINGtoastrmsg(
              this.showMsg,
              2000,
              true,
              false
            );
          })
      );
      this.spinner.hide();
      return;
    }
    if (Boolean(this.commonservice.ValidDateRange(this.validTo)) == false) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidValidToDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidValidToDate"];
            this.commonservice.showWARNINGtoastrmsg(
              this.showMsg,
              2000,
              true,
              false
            );
          })
      );
      this.spinner.hide();
      return;
    }
    if (
      Boolean(this.commonservice.ValidDateRange(this.depositDueDate)) == false
    ) {
      //Case id-337
      this.subscription.add(
        this.translateapi
          .get(["Messages.InvalidDepositDueDate"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.InvalidDepositDueDate"];
            this.commonservice.showWARNINGtoastrmsg(
              this.showMsg,
              2000,
              true,
              false
            );
          })
      );
      this.spinner.hide();
      return;
    }
    if (this.airticketEditForm.get("BSP").value == "1") {
      if (
        this.airticketEditForm.controls.CURR1.value === null ||
        this.airticketEditForm.controls.CURR1.value === ""
      ) {
        this.api.Isvalidfarecurrency = false;
        this.spinner.hide();
        return;
      }
      else {
        this.api.Isvalidfarecurrency = true;
      }
      if (
        this.airticketEditForm.controls.FC_Tax1_CID.value === null ||
        this.airticketEditForm.controls.FC_Tax1_CID.value === ""
      ) {
        this.api.IsValidTaxCurrency = false;
        this.spinner.hide();
        return;
      }
      else {
        this.api.IsValidTaxCurrency = true;
      }

    }
    if (this.airticketEditForm.controls.CostingVATType.value == "VSF") {
      this.airticketEditForm.controls.SELLVAT.setValue(
        this.airticketEditForm.controls.CostingCustomerTaxAmount.value
      );
      this.airticketEditForm.controls.BSPPurchaseTax.setValue(
        parseFloat(
          this.airticketEditForm.controls.CostingSupplierTaxAmount.value.toFixed(
            2
          )
        )
      );
      this.airticketEditForm.controls.BSPPurchaseTaxRate.setValue(
        this.airticketEditForm.controls.CostingTaxRate.value
      );
      this.airticketEditForm.controls.FCBuy.setValue(
        parseFloat(
          (
            this.airticketEditForm.controls.CostingSupplierGrossValue.value +
            this.airticketEditForm.controls.CostingSupplierServiceFee.value
          ).toFixed(2)
        )
      );
      var diff = 0.00;
      if (+this.airticketEditForm.controls.GUIDItinerary.value != 0 && this.commonservice.package) {

        diff = this.FC_Tot_BuyInitalvalue - this.airticketEditForm.controls.CostingSupplierGrossValue.value;
        this.airticketEditForm.controls.FCCommAmt.setValue(parseFloat(this.airticketEditForm.controls.Markup.value.toFixed(2)) + diff);

      }
      else if ((+this.airticketEditForm.controls.GUIDItinerary.value == 0) && this.commonservice.package && this.objFolderPrice.FCTotalCOMM != 0) {
       // this.airticketEditForm.controls.FCSell.setValue(+0);
        this.airticketEditForm.controls.FCCommAmt.setValue(-this.airticketEditForm.controls.CostingSupplierGrossValue.value);
      }
      if (!this.commonservice.package || this.objFolderPrice.FCTotalCOMM == 0) {
        this.airticketEditForm.controls.FCSell.setValue(
          parseFloat(
            this.airticketEditForm.controls.CostingCustomerNetSellPrice.value.toFixed(
              2
            )
          )
        );
      }
      else if (+this.airticketEditForm.controls.GUIDItinerary.value != 0 && this.commonservice.package) {
        this.airticketEditForm.controls.FCSell.setValue(+this.FCSell);
      }
      else if (+this.objFolderPrice.FCTotalCOMM == 0 && this.commonservice.package) {
        this.airticketEditForm.controls.FCSell.setValue(
          +this.airticketEditForm.controls.CostofSale.value +
          +this.airticketEditForm.controls.Markup.value
        );
      }
      // this.airticketEditForm.controls.GSTHSTOnComm.setValue( 0);
      this.HCSellTaxAmt = this.exRate * this.airticketEditForm.controls.CostingCustomerTaxAmount.value;

      this.airticketEditForm.controls.CostingSuppComm.setValue(0);
      this.airticketEditForm.controls.FCCommAmt.setValue(parseFloat(this.airticketEditForm.controls.Markup.value.toFixed(2)));
      // this.airticketEditForm.controls.SELLTAXNO.setValue(this.airticketEditForm.controls.CostingTaxNo.value);
      this.airticketEditForm.controls.SELLTAXRATE.setValue(this.airticketEditForm.controls.CostingTaxRate.value);
      this.airticketEditForm.controls.FC_SELLTAXAMT.setValue(parseFloat(this.airticketEditForm.controls.CostingCustomerTaxAmount.value.toFixed(2)));
      this.airticketEditForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.HCSellTaxAmt.toFixed(2)));

    } else if (this.airticketEditForm.controls.CostingVATType.value == "VP") {
      this.airticketEditForm.controls.BaseFare.setValue(this.airticketEditForm.controls.FCSell.value);  
      this.airticketEditForm.controls.SELLVAT.setValue(
        this.airticketEditForm.controls.CostingCustomerTaxAmount.value
      );
      this.airticketEditForm.controls.BSPPurchaseTax.setValue(
        parseFloat(
          this.airticketEditForm.controls.CostingSupplierTaxAmount.value.toFixed(
            2
          )
        )
      );
      this.airticketEditForm.controls.BSPPurchaseTaxRate.setValue(
        this.airticketEditForm.controls.CostingTaxRate.value
      );
      this.airticketEditForm.controls.FCBuy.setValue(
        parseFloat(
          this.airticketEditForm.controls.CostingSupplierGrossValue.value.toFixed(
            2
          )
        )
      );
      this.HCSellTaxAmt = this.exRate * this.airticketEditForm.controls.CostingCustomerTaxAmount.value;
      this.airticketEditForm.controls.FCCommAmt.setValue(parseFloat(this.airticketEditForm.controls.Markup.value.toFixed(2)));
      // this.airticketEditForm.controls.SELLTAXNO.setValue(this.airticketEditForm.controls.CostingTaxNo.value);
      this.airticketEditForm.controls.SELLTAXRATE.setValue(this.airticketEditForm.controls.CostingTaxRate.value);
      this.airticketEditForm.controls.FC_SELLTAXAMT.setValue(parseFloat(this.airticketEditForm.controls.CostingCustomerTaxAmount.value.toFixed(2)));
      this.airticketEditForm.controls.HC_SELLTAXAMT.setValue(parseFloat(this.HCSellTaxAmt.toFixed(2)));
      var diff = 0.00;
      if (+this.airticketEditForm.controls.GUIDItinerary.value != 0 && this.commonservice.package) {
        diff = this.FC_Tot_BuyInitalvalue - this.airticketEditForm.controls.CostingSupplierGrossValue.value;
        this.airticketEditForm.controls.FCCommAmt.setValue(parseFloat(this.airticketEditForm.controls.Markup.value.toFixed(2)) + diff);

      }
      else if ((+this.airticketEditForm.controls.GUIDItinerary.value == 0) && this.commonservice.package && this.objFolderPrice.FCTotalCOMM != 0) {
       // this.airticketEditForm.controls.FCSell.setValue(+0);
        this.airticketEditForm.controls.FCCommAmt.setValue(-this.airticketEditForm.controls.CostingSupplierGrossValue.value);
      }

      if ((!this.commonservice.package || this.objFolderPrice.FCTotalCOMM == 0 )&&(this.airticketEditForm.controls.GUIDItinerary.value == null ||this.airticketEditForm.controls.GUIDItinerary.value == '' )) {
        this.airticketEditForm.controls.FCSell.setValue(

          parseFloat(
            (
              this.airticketEditForm.controls.CostofSale.value +
              this.airticketEditForm.controls.Markup.value
            ).toFixed(2)
          )
        );
        this.airticketEditForm.controls.HCSell.setValue(
          this.airticketEditForm.get("FCSell").value * this.exRate
        );
        this.airticketEditForm.controls.HCCommAmt.setValue(
          this.airticketEditForm.get("HCSell").value -
          this.airticketEditForm.get("PAYABLE").value
        );
      }
      else if ((this.airticketEditForm.controls.GUIDItinerary.value != null&&this.airticketEditForm.controls.GUIDItinerary.value != '' ) && this.commonservice.package) {
        this.airticketEditForm.controls.FCSell.setValue(+this.FCSell);
      }
      else if ((+this.objFolderPrice.FCTotalCOMM == 0 && this.commonservice.package)&&(this.airticketEditForm.controls.GUIDItinerary.value != null&&this.airticketEditForm.controls.GUIDItinerary.value != '' ) ) {
        this.airticketEditForm.controls.FCSell.setValue(
          +this.airticketEditForm.controls.CostofSale.value +
          +this.airticketEditForm.controls.Markup.value
        );
      }
      // this.airticketEditForm.controls.GSTHSTOnComm.setValue( 0);
      this.airticketEditForm.controls.CostingSuppComm.setValue(0);
      this.airticketEditForm.controls.CostingSupplierServiceFee.setValue(0);
      // this.airticketEditForm.controls.SELLTOTAL.setValue(this.airticketEditForm.controls.CostingCustomerNetSellPrice.value);
    } else if (this.airticketEditForm.controls.CostingVATType.value == "VSC") {
      this.airticketEditForm.controls.SELLVAT.setValue(0);
      this.airticketEditForm.controls.BSPPurchaseTax.setValue(0);
      this.airticketEditForm.controls.BSPPurchaseTaxRate.setValue(
        this.airticketEditForm.controls.CostingTaxRate.value
      );
      this.airticketEditForm.controls.FCBuy.setValue(
        parseFloat(this.airticketEditForm.controls.CostofSale.value.toFixed(2))
      );
      var diff = 0.00;
      if ((this.airticketEditForm.controls.GUIDItinerary.value != null  &&this.airticketEditForm.controls.GUIDItinerary.value != '') && this.commonservice.package) {
        diff = this.FC_Tot_BuyInitalvalue - this.airticketEditForm.controls.CostingSupplierGrossValue.value;
        this.airticketEditForm.controls.FCCommAmt.setValue(parseFloat(this.airticketEditForm.controls.Markup.value.toFixed(2)) + diff);

      }

      else if ((this.airticketEditForm.controls.GUIDItinerary.value == null  ||this.airticketEditForm.controls.GUIDItinerary.value == '') && this.commonservice.package && this.objFolderPrice.FCTotalCOMM != 0) {
      //  this.airticketEditForm.controls.FCSell.setValue(+0);
        this.airticketEditForm.controls.FCCommAmt.setValue(-(this.airticketEditForm.controls.CostingSupplierGrossValue.value));
      }

      if (!this.commonservice.package || this.objFolderPrice.FCTotalCOMM == 0) {
        this.airticketEditForm.controls.FCSell.setValue(
          parseFloat(
            this.airticketEditForm.controls.CostingCustomerNetSellPrice.value.toFixed(
              2
            )
          )
        );
      }
      else if ((this.airticketEditForm.controls.GUIDItinerary.value != null &&this.airticketEditForm.controls.GUIDItinerary.value != '' )&& this.commonservice.package) {
        this.airticketEditForm.controls.FCSell.setValue(+this.FCSell);
      }
      else if (+this.objFolderPrice.FCTotalCOMM == 0 && this.commonservice.package) {
        this.airticketEditForm.controls.FCSell.setValue(
          +this.airticketEditForm.controls.CostofSale.value +
          +this.airticketEditForm.controls.Markup.value
        );
      }

      this.airticketEditForm.controls.CostingCustomerTaxAmount.setValue(0);

      this.airticketEditForm.controls.CostingSupplierNetBuyPrice.setValue(0);
      this.airticketEditForm.controls.CostingSupplierTaxAmount.setValue(0);
      this.airticketEditForm.controls.CostingSupplierServiceFee.setValue(0);
      this.airticketEditForm.controls.FCCommAmt.setValue(parseFloat(this.airticketEditForm.controls.Markup.value.toFixed(2)));

    } else {
      this.airticketEditForm.controls.SELLVAT.setValue(0);
      this.airticketEditForm.controls.BSPPurchaseTax.setValue(0);
      this.airticketEditForm.controls.BSPPurchaseTaxRate.setValue(0);
      //   this.airticketEditForm.controls.GSTHSTOnComm.setValue( 0);
      this.airticketEditForm.controls.CostingSuppComm.setValue(0);
      this.airticketEditForm.controls.CostingCustomerTaxAmount.setValue(0);
      this.airticketEditForm.controls.CostingSupplierNetBuyPrice.setValue(0);
      this.airticketEditForm.controls.CostingSupplierTaxAmount.setValue(0);
      this.airticketEditForm.controls.CostingSupplierServiceFee.setValue(0);
    }

    if (
      this.airticketEditForm.value["EXCHANGE"] === "" ||
      this.airticketEditForm.value["EXCHANGE"] === 0 ||
      this.airticketEditForm.value["EXCHANGE"] === null
    ) {
      this.subscription.add(
        this.translateapi
          .get(["Messages.ExchangeRateError"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.ExchangeRateError"];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            });
          })
      );
      this.spinner.hide();
      return;
    }
    if (this.airticketEditForm.value["EXCHANGE"].toFixed != undefined)
      this.airticketEditForm.value["EXCHANGE"] = this.airticketEditForm.value[
        "EXCHANGE"
      ].toFixed(6);

    if (
      (this.airticketEditForm.controls.CURR.value == undefined &&
        this.airticketEditForm.controls.CURR.value == null) ||
      this.airticketEditForm.controls.CURR.value == ""
    ) {
      // this.airticketEditForm.controls.CURCODE.setValue( this.homeCurrency);
      this.airticketEditForm.controls.CURR.setValue(this.HomeCurrency);
    }

    if (this.airticketEditForm.controls.BSP.value == 1) {
      this.calculateAirticketDetailsNOVAt(0);

      // if(this.airticketEditForm.controls.CURR1.value==this.airticketEditForm.controls.FC_Tax1_CID.value)
      // {
      //   this.airticketEditForm.controls.CURR.setValue(this.airticketEditForm.controls.CURR1.value);
      //   this.airticketEditForm.controls.EXCHANGE.setValue(this.airticketEditForm.controls.EXCHANGE1.value);
      // }
      this.airticketEditForm.controls.CURR.setValue(this.airticketEditForm.controls.CURR1.value);
      this.airticketEditForm.controls.EXCHANGE.setValue(this.airticketEditForm.controls.EXCHANGE1.value);
    }
    else {
      this.getCommonData();
    }
    /*13089*/
    this.airticketEditForm.controls.AIRLINE.setValue(
      this.airticketEditForm.controls.VairlineAIRID.value
    );
    /*13089*/

    this.objFolderPrice
      .CreditLimitCheck(
        this.guid == null || undefined ? "0" : this.guid,
        0,
        this.orderNo,
        this.airticketEditForm.get("HCSell").value
      )
      .subscribe(res => {
        if (res != undefined || res["ResponseData"] != null) {
          if (
            res["ResponseData"].value != "0" &&
            res["ResponseData"].Status != "0" &&
            res["ResponseData"].ErrorMessage != ""
          ) {

            if (res["ResponseData"].value == 1) {
              this.msg = res["ResponseData"].ErrorMessage;
              this.creditLimiFlag = true;
              this.spinner.hide();
              this.creditLimitMsg = true;
              this.spinner.hide();
              return false;
            } else if (res["ResponseData"].value == 2) {
              this.msg = res["ResponseData"].ErrorMessage;
              this.creditLimiFlag = false;
              this.spinner.hide();
              this.creditLimitMsg = true;

              // this.AirTicketSaveAndUpdate();
            } else {
              this.AirTicketSaveAndUpdate();
            }
          } else {
            this.AirTicketSaveAndUpdate();
          }
        }
      });
  }

  AirTicketSaveAndUpdate() {
    if (this.guid != null && this.PONO > 0) {
      this.prdtUpdatePODTO = new ProductUpdatePODTO();
      this.prdtUpdatePODTO.FOLDERNO = this.orderNo;
      this.prdtUpdatePODTO.GUIDItinerary = this.airticketEditForm.get(
        "GUIDItinerary"
      ).value;
      this.prdtUpdatePODTO.LOYALTYPOINTS = this.airticketEditForm.get(
        "LOYALTYPOINTS"
      ).value;
      this.prdtUpdatePODTO.LOYALTYPOINTSAMT = this.airticketEditForm.get(
        "LOYALTYPOINTSAMT"
      ).value;
      //this.prdtUpdatePODTO.LOYALTYPOINTSBAL=this.airticketEditForm.get('LOYALTYPOINTSBAL').value
      this.prdtUpdatePODTO.LOYALTYPOINTSMEMBNO = this.airticketEditForm.get(
        "LOYALTYPOINTSMEMBNO"
      ).value;

      this.prdtUpdatePODTO.BaseFare = this.airticketEditForm.get(
        "BaseFare"
      ).value;
      this.prdtUpdatePODTO.SELLAMT = this.airticketEditForm.get("HCSell").value;
      this.prdtUpdatePODTO.SELLCOMM = this.airticketEditForm.get(
        "HCCommAmt"
      ).value;
      this.prdtUpdatePODTO.FC_SELLTAXAMT = this.FCSellTaxAmt;
      this.prdtUpdatePODTO.HC_SELLTAXAMT = this.HCSellTaxAmt;
      this.prdtUpdatePODTO.HCSellTaxAmt = this.HCSellTaxAmt;
      this.prdtUpdatePODTO.HCSell = this.airticketEditForm.get("HCSell").value;
      this.prdtUpdatePODTO.HCCommAmt = this.airticketEditForm.get(
        "HCCommAmt"
      ).value;
      this.prdtUpdatePODTO.CostingCustomerServiceFee = this.airticketEditForm.get(
        "CostingCustomerServiceFee"
      ).value;
      this.prdtUpdatePODTO.CostingCustomerTaxAmount = this.airticketEditForm.get(
        "CostingCustomerTaxAmount"
      ).value;
      // this.prdtUpdatePODTO.fCSellTax=this.AirticketEditForm.get('RDESC').value;
      this.prdtUpdatePODTO.SELLVAT = this.airticketEditForm.get(
        "SELLVAT"
      ).value;
      this.prdtUpdatePODTO.SELLTAXNO = this.airticketEditForm.get(
        "CostingTaxNo"
      ).value;
      this.prdtUpdatePODTO.SELLTAXRATE = this.airticketEditForm.get(
        "CostingTaxRate"
      ).value;
      this.prdtUpdatePODTO.CostingCustomerNetSellPrice = this.airticketEditForm.get(
        "CostingCustomerNetSellPrice"
      ).value;
      this.prdtUpdatePODTO.FCSell = this.airticketEditForm.get("FCSell").value;
      this.prdtUpdatePODTO.PAYABLE = this.airticketEditForm.get(
        "PAYABLE"
      ).value;
      this.prdtUpdatePODTO.FC_CIDExRate = this.airticketEditForm.get(
        "EXCHANGE"
      ).value;
      // this.prdtUpdatePODTO.LOYALTYPOINTSMEMBNO=this.AirticketEditForm.get('LOYALTYPOINTSMEMBNO').value;
      this.prdtUpdatePODTO.TYPE = "Ticket";
      //this.apiPO.formData=
      this.apiPO.formData = this.prdtUpdatePODTO;
      this.subscription.add(
        this.apiPO.SaveProductAfterPO().subscribe(

          res => {
            this.spinner.hide();
            if (res.IsSuccess === true && res.Message === "Success") {
              this.spinner.hide();
              this.subscription.add(
                this.translateapi
                  .get(["Messages.saved_success"])
                  .subscribe(translations => {
                    this.showMsg = translations["Messages.saved_success"];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    });
                  })
              );
              this.router.navigate([
                "/PenAir/Product/AirticketDetails",
                this.orderNo,
                this.guid
              ], { skipLocationChange: true });
            } else {
              this.spinner.hide();
              this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              });
            }
          },
          err => {
            this.spinner.hide();
            console.log(err);
            this.subscription.add(
              this.translateapi
                .get(["Messages.some_error_in_save"])
                .subscribe(translations => {
                  this.showMsg = translations["Messages.some_error_in_save"];
                  this.toastr.warning(this.showMsg, "", {
                    timeOut: 3000,
                    disableTimeOut: false,
                    closeButton: true
                  });
                })
            );
          }
        )
      );
    } else {
      if (this.guid != null) {
        this.spinner.show();
        this.subscription.add(
          this.api
            .UpdateAirticketDetails(this.airticketEditForm.value)
            .subscribe(
              res => {
                if (res != null && res != undefined) {
                  if (res.IsSuccess === true && res.Message === "SUCCESS") {
                    this.spinner.hide();

                    this.subscription.add(
                      this.translateapi
                        .get(["Messages.saved_success"])
                        .subscribe(translations => {
                          this.showMsg = translations["Messages.saved_success"];
                          this.toastr.success(this.showMsg, "", {
                            timeOut: 3000,
                            disableTimeOut: false,
                            closeButton: true
                          });
                        })
                    );
                    this.router.navigate([
                      "PenAir/Product/AirticketDetails",
                      this.orderNo,
                      this.guid + "/"
                    ], { skipLocationChange: true });
                  } else {
                    this.spinner.hide();
                    this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                      timeOut: 5000,
                      disableTimeOut: false,
                      closeButton: true
                    });
                  }
                } else {
                  this.spinner.hide();
                }
              },
              err => {
                this.spinner.hide();
                console.log(err);
                this.subscription.add(
                  this.translateapi
                    .get(["Messages.some_error_in_save"])
                    .subscribe(translations => {
                      this.showMsg =
                        translations["Messages.some_error_in_save"];
                      this.toastr.warning(this.showMsg, "", {
                        timeOut: 3000,
                        disableTimeOut: false,
                        closeButton: true
                      });
                    })
                );
              }
            )
        );
      } else {
        this.spinner.show();
        this.subscription.add(
          this.api
            .addAirticketDetails(this.airticketEditForm.value)

            .subscribe(
              res => {
                if (res != null && res != undefined) {
                  if (res.IsSuccess === true && res.Message === "SUCCESS") {
                    this.spinner.hide();
                    this.subscription.add(
                      this.translateapi
                        .get(["Messages.saved_success"])
                        .subscribe(translations => {
                          this.showMsg = translations["Messages.saved_success"];
                          this.toastr.success(this.showMsg, "", {
                            timeOut: 3000,
                            disableTimeOut: false,
                            closeButton: true
                          });
                        })
                    );
                    this.commonservice.isfinalsaved = false;
                    sessionStorage.setItem("pagevalue", "0");
                    var folderQuotes = sessionStorage.getItem("folderQuotes");
                    if (folderQuotes) {
                      this.router.navigate([
                        "/PenAir/CRM/Enquiry/View/",
                        this.orderNo
                      ], { skipLocationChange: true });
                    } else {
                      this.router.navigate([
                        "PenAir/Folder/FolderSummary",
                        this.orderNo
                      ], { skipLocationChange: true });
                    }
                  } else {
                    this.spinner.hide();
                    //this.commonservice.showWARNINGtoastrmsg(res.Message,2000);
                    this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                      timeOut: 5000,
                      disableTimeOut: false,
                      closeButton: true
                    });
                  }
                } else {
                  this.spinner.hide();
                }
              },
              err => {
                console.log(err);
                this.subscription.add(
                  this.translateapi
                    .get(["Messages.some_error_in_save"])
                    .subscribe(translations => {
                      this.showMsg =
                        translations["Messages.some_error_in_save"];
                      this.toastr.warning(this.showMsg, "", {
                        timeOut: 3000,
                        disableTimeOut: false,
                        closeButton: true
                      });
                    })
                );
                this.spinner.hide();
              }
            )
        );
      }
    }
  }
  //#endregion for Submit

  //#region for Reset
  onReset() {
    this.submitted = false;
    this.airticketEditForm.reset();
  }
  //#endregion for Reset
  onChanges(): void { }

  //#region for Cancel

  onCancel() {
    if (this.guid != null)
      this.router.navigate([
        "/PenAir/Product/AirticketDetails/",
        this.orderNo,
        this.guid + "/"
      ], { skipLocationChange: true });
    else {
      //this.router.navigate(['/PenAir/Folder/Products',this.orderNo]);
      this.commonservice.isfinalsaved = false;
      sessionStorage.setItem("pagevalue", "0");
      var folderQuotes = sessionStorage.getItem("folderQuotes");
      if (folderQuotes) {
        this.router.navigate(["/PenAir/CRM/Enquiry/View/", this.orderNo], { skipLocationChange: true });
      } else {
        this.router.navigate(["PenAir/Folder/FolderSummary", this.orderNo], { skipLocationChange: true });
      }
    }
  }
  //#endregion for Cancel

  //#region for get Percentage
  public getPercentage(comm: number, sell: number): number {
    if (sell != 0) return (comm / sell) * 100;
    else return 0;
  }
  //#endregion for get Percentage

  //#region for open or close div
  public open() {

    this.opened = true;
  }
  public close(status) {
    this.opened = false;
    this.openSearch = false;
  }
  //#region for open or close div
  //#region for Format Date conversion
  public FormatDate(value: any): string {
    this.dattobechecked = new Date(value)
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric"
      })
      .replace(/ /g, "-");
    if (this.dattobechecked == "1-Jan-1900") return "";
    else {
      this.dattobechecked = this.dattobechecked.replace("Sept", "Sep");
      return this.dattobechecked;
    }
  }
  //#endregion for Format Date conversion
  //#region for calculate Sales Tax
  calculateSalesTax() {
    this.PurchaseTaxAmount =
      this.airticketEditForm.get("ITSplit1AMT").value +
      this.airticketEditForm.get("ITSplit2AMT").value +
      this.airticketEditForm.get("ITSplit3AMT").value +
      this.airticketEditForm.get("ITSplit4AMT").value +
      this.airticketEditForm.get("ITSplit5AMT").value;

    this.PurchaseTaxAmount =
      this.airticketEditForm.get("ITSplit1AMT").value +
      this.airticketEditForm.get("ITSplit2AMT").value +
      this.airticketEditForm.get("ITSplit3AMT").value +
      this.airticketEditForm.get("ITSplit4AMT").value +
      this.airticketEditForm.get("ITSplit5AMT").value;
    this.airticketEditForm.controls.PurchaseTax.setValue(
      this.PurchaseTaxAmount
    );

    this.OtherTaxAmount =
      this.airticketEditForm.get("TotalTax").value -
      this.airticketEditForm.get("PurchaseTax").value;

    this.PurchaseValueAmount =
      this.airticketEditForm.get("BUY1").value + this.OtherTaxAmount;
    this.airticketEditForm.controls.OtherTax.setValue(this.OtherTaxAmount);
    this.airticketEditForm.controls.PurchaseValue.setValue(
      this.PurchaseValueAmount
    );
  }
  //#endregion for calculate Sales Tax
  //#region for  set CommOn
  setCommOn() {
    this.commOn = this.airticketEditForm.get("CommOn").value;
  }
  //#endregion for  set CommOn
  //#region for calculate currencyExRate
  public currencyExRate1(): void {
    this.getExchangeRate(
      this.airticketEditForm.controls.CURR.value,
      this.airticketEditForm.controls.TICKDATE.value
    );
  }

  public currencyExRatechange(): void {
    if (this.airticketEditForm.controls.CostingVATType.value == "NV") {
      this.calculateAirticketDetailsNOVAtSell(false,0);
    } else if (this.airticketEditForm.controls.CostingVATType.value == "VSC") {
      this.calculateAirticketDetailsSuppComm();
    } else {
      this.calculateFormVotService(0,0);
    }
    this.calculateAirticket(0);
  }
  //#endregion for calculate currencyExRate

  //#region for getFolderValues
  getFolderValues() {
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    this.exRate = new Number(this.folderExrate);
    this.projNo = this.apiShared.projNo;
  }
  //#endregion for getFolderValues

  ngOnChanges() { }
  resetChildForm() {
    this.resetFormSubject.next(true);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.commonservice.OutTaxselectedItem = null;
    this.commonservice.InputTaxselectedItem = null;
  }
  //#region get exchange rate
  getExchangeRate(currency, bookingDate) {
    if (bookingDate == null || bookingDate == undefined) {
      bookingDate = new Date();
    }
    this.savebutton = false;
    bookingDate = new Date(bookingDate)
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric"
      })
      .replace(/ /g, "-");

    bookingDate = bookingDate.replace("Sept", "Sep");
    this.subscription.add(
      this.apiExRate.getExchangeRate(currency, bookingDate).subscribe(data => {
        this.currencyExrate = data;
        // alert(this.currencyExrate.ResponseData.EXRATE);
        //         if(this.airticketEditForm.value['EXCHANGE']==null||this.airticketEditForm.value['EXCHANGE']==0||this.airticketEditForm.value['EXCHANGE']==''||this.airticketEditForm.value['EXCHANGE']==undefined)
        this.currentExRate = this.airticketEditForm.controls.EXCHANGE.value;
        if (
          this.currencyExrate != null &&
          this.currencyExrate.ResponseData.EXRATE !== null
        ) {
          this.airticketEditForm.controls.EXCHANGE.setValue(
            this.currencyExrate.ResponseData.EXRATE
          );
        } else {
          this.airticketEditForm.controls.EXCHANGE.setValue(0);
        }

        // if(this.loaded=true)

        if (this.airticketEditForm.controls.CostingVATType.value == "NV") {
          this.calculateAirticketDetailsNOVAtSell(false,0);
        } else if (
          this.airticketEditForm.controls.CostingVATType.value == "VSC"
        ) {
          this.calculateAirticketDetailsSuppComm();
        } else {
          if (
            this.currentExRate != this.airticketEditForm.controls.EXCHANGE.value
          ) {
            this.calculateFormVotService(0,0);
          }
        }

        this.calculateAirticket(0);
        this.savebutton = true;
        this.spinner.hide();
      })
    );
  }
  //#endregion get exchange rate
  //#region for show/hide header label
  isSticky: boolean = false;
  @HostListener("window:scroll")
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }
  //#endregion for show/hide header label

  ATYPEChange() {
    this.airticketEditForm.controls.ATYPE.setValue(this.ATYPESelected);
  }
  ConfirmMessage() {
    if (this.creditLimiFlag == false) {
      this.creditLimitMsg = false;

      this.AirTicketSaveAndUpdate();
    } else {
      this.creditLimitMsg = false;
    }
  }
  isShowncm: boolean = false; // hidden by default
  isShownCmp: boolean = false;
  isShownCust: boolean = false;
  public paymode: string;
  toggleShow() {
    this.isShowncm = true;
    this.isShownCmp = false;
    this.isShownCust = false;

    if (this.airticketEditForm.controls.ITYPE.value != "Ticket") {
      this.paymode = "Credit";
      this.airticketEditForm.controls.PAYMODE.setValue(this.paymode);
      //alert(this.airticketEditForm.controls.PAYMODE.value)
    } else {
      this.paymode = "N";
      this.airticketEditForm.controls.COMONLY.setValue(this.paymode);
      //alert(this.airticketEditForm.controls.PAYMODE.value)
    }
  }
  public toggleShowCmp() {

    this.isShownCmp = true;
    this.isShown = false;
    this.isShownCust = false;
    if (this.airticketEditForm.controls.ITYPE.value != "Ticket") {
      this.paymode = "Company Card";
      this.airticketEditForm.controls.PAYMODE.setValue(this.paymode);
      //alert(this.airticketEditForm.controls.PAYMODE.value)
    } else {
      this.paymode = "W";
      this.airticketEditForm.controls.COMONLY.setValue(this.paymode);
    }
  }
  public toggleShowCust() {
    this.isShownCust = true;
    this.isShownCmp = false;
    this.isShown = false;
    if (this.airticketEditForm.controls.ITYPE.value != "Ticket") {
      this.paymode = "Customer Card";

      this.airticketEditForm.controls.PAYMODE.setValue(this.paymode);
      //alert(this.airticketEditForm.controls.PAYMODE.value)
    } else {
      this.paymode = "C";
      this.airticketEditForm.controls.COMONLY.setValue(this.paymode);
    }
  }
  public PDQChange(value: any): void {

    if (value !== undefined && value !== null) {
      this.airticketEditForm.controls.PDNO.setValue(value.Code);
      this.airticketEditForm.controls.PDTYPENO.setValue(0);
      this.PDTYPENOSelectedItem = null;
      this.airticketEditForm.controls.PDTYPENO.setValue

      this.subscription.add(
        this.lookupApi.GetPDQCard(value.Code).subscribe(data => {
          this.cardType = data["ResponseData"];
          if (value.ActualName != "") {
            this.PDTYPENOSelectedItem = this.cardType.find(
              item => item.CARDID == value.ActualName
            );
            this.airticketEditForm.controls.PDTYPENO.setValue(value.ActualName);
            this.airticketEditForm.controls.PDNO.setValue(value.Code);
          }
        })
      );

      if (value.CurID != "") {
        this.airticketEditForm.controls.PDFCURID.setValue(value.CurID);
        this.CurrencypyselectedItem = this.CurrencyList.find(
          item => item.Code == value.CurID
        );
      }
    } else {
      this.airticketEditForm.controls.PDNO.setValue(0); //355
    }
  }

  public PayemtTermCurrencyChange(value: any): void {
    console.log("selectionChange", value);

    this.airticketEditForm.controls.PDFCURID.setValue(value.Code);
  }
  public cardTypecombochage(value: any): void {
    if (value !== undefined && value !== null) {
      this.airticketEditForm.controls.PDTYPENO.setValue(value.CARDID);
    } else {
      this.airticketEditForm.controls.PDTYPENO.setValue(0);
    }
  }
  getCorporateFormExchangeRate(currency, bookingDate) {
    bookingDate = this.commonservice.ConvertToLocaleDate(bookingDate);

    this.subscription.add(
      this.apiExRate.getExchangeRate(currency, bookingDate).subscribe(data => {
        this.currencyExrate = data;
        this.airticketEditForm.controls.PDEXRATE.setValue(
          this.currencyExrate.ResponseData.EXRATE
        );
        this.CalculateCorporateForm();
      })
    );
  }
  public getPayemtTermCurrencyExRate() {

    var Ticketdate = null;
    if (this.airticketEditForm.controls.TICKDATE.value == null || this.airticketEditForm.controls.TICKDATE.value == undefined) {
      Ticketdate = new Date();
    }
    else {
      Ticketdate = this.airticketEditForm.controls.TICKDATE.value;
    }
    this.getCorporateFormExchangeRate(
      this.airticketEditForm.controls.PDFCURID.value,
      Ticketdate
    );

  }
  private exRatept: number;
  private tcAmount: number;
  public CalculateCorporateForm() {
    this.exRatept =
      this.airticketEditForm.get("PDEXRATE").value == "" ||
        this.airticketEditForm.get("PDEXRATE").value == 0
        ? 1
        : this.airticketEditForm.get("PDEXRATE").value;
    // if((this.airticketEditForm.get('AMTLC')!=null) && this.airticketEditForm.get('AMTLC').value!=0&&this.airticketEditForm.get('AMTLC').value!="undefined")
    // {

    this.tcAmount = this.airticketEditForm.controls.PDFCAMT.value;
    this.airticketEditForm.controls.PDAMOUNT.setValue(
      parseFloat((this.tcAmount * this.exRatept).toFixed(2))
    );
    //this.airticketEditForm.controls.PAYABLE.setValue(this.fCBuy*this.exRate );
    // }
    this.spinner.hide();
  }
  public loadPaymethod() {
    //alert(this.parentForm.controls.PAYMODE.value)


    if (this.airticketEditForm.get("COMONLY") != null) {
      if (this.airticketEditForm.get("COMONLY").value == "W") {
        this.rdCheckedCmp = true;
        this.toggleShowCmp();
      } else if (this.airticketEditForm.get("COMONLY").value == "C") {
        this.rdCheckedCust = true;
        this.toggleShowCust();
      } else {
        this.rdCheckedCr = true;
        this.toggleShow();
      }
    } else {
      this.rdCheckedCr = true;
      this.toggleShow();
    }
    this.subscription.add(
      this.lookupApi.GetAllLookup("COMPANYCARD").subscribe(data => {
        if (data != null && data != undefined) {
          this.PDQ = data["ResponseData"];

          if (this.airticketEditForm.controls.PDNO.value != "") {
            this.PDQselectedItem = this.PDQ.find(
              item => item.Code == this.airticketEditForm.controls.PDNO.value
            );
            this.subscription.add(
              this.lookupApi
                .GetPDQCard(this.airticketEditForm.controls.PDNO.value)
                .subscribe(data => {
                  if (data != null && data != undefined) {
                    this.cardType = data["ResponseData"];
                    if (this.airticketEditForm.controls.PDTYPENO.value != "") {
                      this.PDTYPENOSelectedItem = this.cardType.find(
                        item =>
                          item.CARDID == this.airticketEditForm.controls.PDTYPENO.value
                      );
                    }
                  } // else {

                  // }
                })
            );
          }
        }
      })
    );
  }
  public GetCurrencyExchangeRateTolerance(exRateType: string) {
    debugger;
    var bookingDate = new Date();
    if (exRateType == 'EXRATE') {
      bookingDate = this.commonservice.ConvertToLocaleDate(this.airticketEditForm.controls.TICKDATE.value != null ? this.airticketEditForm.controls.TICKDATE.value : bookingDate);
      if (this.CurrencyselectedItem != undefined) {
        this.spinner.show();
        this.cashservice.formData = new CashPaymentDTO();
        this.cashservice.formData.PVRDATE = this.commonservice.ConvertToLocaleDate(new Date(bookingDate));
        this.cashservice.formData.date = this.commonservice.ConvertToLocaleDate(new Date())
        this.cashservice.formData.HCID = this.CurrencyselectedItem.UsrCode;
        this.cashservice.formData.FCID = this.CurrencyselectedItem.UsrCode;
        this.cashservice.formData.RXRATE = this.airticketEditForm.controls.EXCHANGE.value;
        this.cashservice.formData.LANQUAGE = this.apiShared.LanguageSelected;
        this.subscription.add(this.cashservice.GetCurrencyExchangeRateTolerance().subscribe(x => {
          debugger;
          if (x != null && x != undefined) {
            if (x["ResponseData"].messageid == 0) {
              this.airticketEditForm.controls.EXCHANGE.setValue(x["ResponseData"].RXRATE);
            }
            else if (x["ResponseData"].messageid > 0) {
              var msg = 'Messages.msg' + x["ResponseData"].messageid;
              this.commonservice.showWARNINGtoastrmsg(x["ResponseData"].message, 3000, true, false);
              this.airticketEditForm.controls.EXCHANGE.setValue(x["ResponseData"].RXRATE);
            }
            this.currencyExRatechange();
            this.spinner.hide();
          }
        }));
      } else {
        this.spinner.hide();
        this.commonservice.showWARNINGtoastrmsg('Messages.invalid_currency', 3000, true, false);
        this.currencyChange(null);
      }
    } else {
      bookingDate = this.commonservice.ConvertToLocaleDate(bookingDate);
      if (this.CurrencypyselectedItem != undefined) {
        this.spinner.show();
        this.cashservice.formData = new CashPaymentDTO();
        this.cashservice.formData.PVRDATE = this.commonservice.ConvertToLocaleDate(new Date(bookingDate));
        this.cashservice.formData.date = this.commonservice.ConvertToLocaleDate(new Date())
        this.cashservice.formData.HCID = this.CurrencypyselectedItem.UsrCode;
        this.cashservice.formData.FCID = this.CurrencypyselectedItem.UsrCode;
        this.cashservice.formData.RXRATE = this.airticketEditForm.controls.PDEXRATE.value;
        this.cashservice.formData.LANQUAGE = this.apiShared.LanguageSelected;
        this.subscription.add(this.cashservice.GetCurrencyExchangeRateTolerance().subscribe(x => {
          debugger;
          if (x != null && x != undefined) {
            if (x["ResponseData"].messageid == 0) {
              this.airticketEditForm.controls.PDEXRATE.setValue(x["ResponseData"].RXRATE);
            }
            else if (x["ResponseData"].messageid > 0) {
              var msg = 'Messages.msg' + x["ResponseData"].messageid;
              this.commonservice.showWARNINGtoastrmsg(x["ResponseData"].message, 3000, true, false);
              this.airticketEditForm.controls.PDEXRATE.setValue(x["ResponseData"].RXRATE);
            }
            this.CalculateCorporateForm();
            this.spinner.hide();
          }
        }));
      } else {
        this.commonservice.showWARNINGtoastrmsg('Messages.invalid_currency', 3000, true, false);
        this.currencyChange(null);
      }
    }
  }
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }
  alert() {

    if (this.formeditable == false) {
      alert("only not can be edit")
    }
  }

  getInputTaxLookup(country: any) {
    this.subscription.add(this.lookupApi.GetLookupById('InputTaxMaster', country, "''").subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.InputTax = data["ResponseData"];
          this.commonservice.InputTax = data["ResponseData"];
          // alert(this.parentForm.controls.ITYPE.value);   


          if (this.airticketEditForm.controls.CostingTaxNo.value != "") {
            debugger;

            this.InputTaxselectedItem = this.InputTax.find(item => item.Code == this.airticketEditForm.controls.CostingTaxNo.value);
            this.commonservice.InputTaxselectedItem = this.commonservice.InputTax.find(item => item.Code == this.airticketEditForm.controls.CostingTaxNo.value);
          }

        }
      }
    ));
  }
  OutputTaxAmtChange(val) {
    // if (this.divValue == 2)
    if(val==3)
    this.calculateFormVotService(101,0)
    this.airticketEditForm.controls.CostingCustomerNetSellPrice.setValue(
      this.airticketEditForm.controls.CostofSale.value +
      this.airticketEditForm.controls.Markup.value +
      this.airticketEditForm.controls.CostingCustomerTaxAmount.value
    );
   
    // if (this.divValue == 3)
    //   this.airticketEditForm.controls.CostingCustomerNetSellPrice.setValue(
    //     this.airticketEditForm.controls.Markup.value +
    //     this.airticketEditForm.controls.CostingCustomerTaxAmount.value +
    //     this.airticketEditForm.controls.CostofSale.value
    //   );
  }
  getOutTaxLookup(country: any) {
    this.subscription.add(this.lookupApi.GetLookupById('OutputTaxMaster', country, "''").subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.OutputTax = data["ResponseData"];
          this.commonservice.OutputTax = data["ResponseData"];
          // alert(this.parentForm.controls.ITYPE.value);   

          if (this.airticketEditForm.controls.SELLTAXNO.value != "") {
            debugger;
            this.OutputTaxselectedItem = this.OutputTax.find(item => item.Code == this.airticketEditForm.controls.SELLTAXNO.value);
            this.commonservice.OutTaxselectedItem = this.OutputTax.find(item => item.Code == this.airticketEditForm.controls.SELLTAXNO.value);
          }
        }

      }
    ));
  }
  outputTaxChange(value: any) {
    debugger;
    if (value != undefined && value != null) {
      this.airticketEditForm.controls.SELLTAXNO.setValue(value.Code);
      this.subscription.add(
        this.commonservice.GetTaxSplit(value.Code).subscribe(x => {
          this.outputTaxDetails = x["ResponseData"]
          this.outputTaxSplit(this.divValue);
          if(this.divValue==3)
          this.OutputTaxAmtChange(101)
        }))
        ;



    }
    else {
      this.outputTaxDetails = null;
      this.airticketEditForm.controls.CostingCustomerTaxAmount.setValue(0);
    }

  }

  oncurrencychange()
  {
  this.showcurrexgerate=!this.showcurrexgerate;
  }

}
