import { Component,Inject, OnInit,Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropPosition, TreeItemDropEvent } from '@progress/kendo-angular-treeview';
import { ShareDataService } from '../../Services/share-data.service';
import {AccountMenu} from './types';
import { Subscription } from 'rxjs';
import { PublicLayoutComponent } from 'src/app/public-layout/public-layout.component';
import { TranslateService, TranslateStore } from '@ngx-translate/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { HttpClient } from '@angular/common/http';
import { CommonserviceService } from '../../Services/commonservice.service';
import { LoginService } from '../../Services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FileService } from '../../Services/file.service';
import { EnvService } from 'src/env.service';
import { PendoclockService } from '../../Services/pendoclock.service';
import { L10nInterceptor } from '../../_helpers/l10n.interceptor';
import { PenChannelService } from '../../Services/pen-channel.service';
const isFile = (name: string) => name.length > 1;//name.split('.')
import { AlertService } from '@full-fledged/alerts';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAuthService } from '../../Services/google-auth.service';
import { LOCAL_STORAGE,StorageService } from 'ngx-webstorage-service';
@Component({
  selector: 'app-accounts-main-menu',
  templateUrl: './accounts-main-menu.component.html',
  styleUrls: ['./accounts-main-menu.component.scss']
})
export class AccountsMainMenuComponent implements OnInit {

  @Input() OptionsAccounts;
  @Input() MenuAccounts: AccountMenu[];
  @Output() messageToEmit = new EventEmitter<string>();
  @Output() pageHeader = new EventEmitter<string>();
  @Input() collpaseClick:boolean;
  @Output() menuView: EventEmitter<boolean> = new EventEmitter();
  public UID:any;
  dummymenu: any;
  test:boolean=false;
  public MyAccounts:any;
  trasilatesettings:TranslateStore;
  private http: HttpClient;
  commonservice:CommonserviceService;
  logoutService: LoginService;
  spinner: NgxSpinnerService;
  route: ActivatedRoute;
  ranslate:TranslateService;
  Shared: ShareDataService;
  penLock:PendoclockService;
  penLock1:PendoclockService;
  penchannelApi:PenChannelService;
  public datas:any;
  public selectedKeys: any[] = ['1'];
  constructor(private apiShared: ShareDataService,private router: Router,
    private translate:TranslateService,private intl: IntlService,
    private toastr: ToastrService,private tl: TranslateService,
    public login:LoginService,public file:FileService,private env:EnvService,private googleauthservice: GoogleAuthService,
    private dialog: MatDialog,
    private alertService: AlertService) 
  {
    this.UID=this.apiShared.uid;
    this.publicLayoutComponent=new PublicLayoutComponent(this.intl,this.http,this.trasilatesettings,this.commonservice,this.router,this.logoutService,this.route, this.translate,this.spinner,this.Shared,this.penchannelApi,this.penLock,this.penLock1,this.toastr,this.file,this.env,this.googleauthservice,this.dialog,this.alertService);
  }
  @Output() hideStatus = new EventEmitter<string>();
  private subscription: Subscription = new Subscription();
  public publicLayoutComponent:PublicLayoutComponent;
  Searchplaceholder:any='Search';
  menuList: AccountMenu[];
  result:AccountMenu[];
  ngOnInit() {
    this.subscription.add(this.translate.get(['Labels.search'],).subscribe((translations ) => {
      this.Searchplaceholder= translations['Labels.search']+'..';
     }));
  }


  public keys: string[] = [];
public isExpanded = (dataItem: any, index: string) => {
  return this.keys.indexOf(index) > -1;
}
public handleCollapse(node) {
  this.keys = this.keys.filter(k => k !== node.index);
}
  public log(event: string, args?: any): void {
    // console.log(event, args);
  }
  public handleExpand(node) {
  
    this.keys = this.keys.concat(node.index);
  }
  public async onNodeClick(e: any) {
    sessionStorage.removeItem('IsDirectRefund');
    this.apiShared.Setapishareddata('', 'PassengerNames');
    this.apiShared.Setapishareddata('', 'projNo');
    this.apiShared.Setapishareddata('', 'orderNo');
    if(!this.test){
      this.test=true;
      this.menuView.emit(this.test);

    }
    if(e.item.dataItem.submenuexists==false)
    {
      this.router.navigate([e.item.dataItem.routerLink]);
    }
    if(e.item.dataItem.mainmenuid=='Mainmenu.Home')
    {
      this.selectedKeys= ['1'];
      this.hideStatus.emit("HideAccountsMenu");
    }
    if(e.item.dataItem.mainmenuid!='Mainmenu.Home')
    {
      this.test=false;
      this.menuView.emit(this.test);
      let menuElement = document.getElementById( "collapsibleNavbar" );
      menuElement.classList.remove( "show" );
    }
    if(e.item.dataItem.submenuexists==false)
    {
      this.subscription.add(this.translate.get(['Mainmenu.MyAccount'],).subscribe((translations ) => {
        this.MyAccounts= translations['Mainmenu.MyAccount'];
    
       }));
      this.myMethod(this.MyAccounts,e.item.dataItem.name);
    }
     this.subscription.add(this.translate.get([e.item.dataItem.mainmenuid],).subscribe((translations ) => {
     
      this.publicLayoutComponent.headerText= translations[e.item.dataItem.mainmenuid];
      this.subscription.add( this.translate.get(['Mainmenu.MyAccount']).subscribe((translations ) => {
       
        this.publicLayoutComponent.headervalue= translations['Mainmenu.MyAccount'];
            this.publicLayoutComponent.headervalue=this.publicLayoutComponent.headervalue+'/'+this.publicLayoutComponent.headerText;
           
    this.publicLayoutComponent.headerlanguage= e.item.dataItem.mainmenuid;
    this.publicLayoutComponent.mainmenutransilate="Mainmenu.MyAccount";
    sessionStorage.setItem('mainmenutransilate', this.publicLayoutComponent.mainmenutransilate)
    sessionStorage.setItem('headerlanguage', this.publicLayoutComponent.headerlanguage)
       }));
     }));
}

myMethod(menu:string, obj:string) {
    
  this.messageToEmit.emit(obj);
  this.pageHeader.emit(menu+'/'+obj);
}
ngOnChanges(changes: SimpleChanges) {
  if (changes["collpaseClick"] != undefined) {
     this.test =changes["collpaseClick"].currentValue;
     
  }else if (changes["menus"]!== undefined) {
    this.menuList = changes["menus"].currentValue;
    const dummy = [];
    changes["menus"].currentValue.forEach(val => dummy.push(Object.assign({}, val)));
    this.dummymenu = dummy;
   
    this.subscription.add(this.tl.get(['Labels.search'],).subscribe((translations) => {
      this.Searchplaceholder = translations['Labels.search'] + '..';
    }));
  }
  // console.log(this.dummymenu);
}
changefn(value){

  if(this.menuList===undefined)
  {
    this.menuList= this.MenuAccounts ;
  }
  this.result=[];
    for(var obj of this.menuList)  {
      var objadded=0;
      if(obj.name.toLowerCase().includes(value.toLowerCase()))
      {
        objadded=1;
        this.result.push(obj);
      }
    }
    this.MenuAccounts=this.result;
    this.MenuAccounts[0].active=true; 
}
}
