import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { IntlService } from '@progress/kendo-angular-intl';
import { CommonserviceService } from '../../Services/commonservice.service';
const sampleCustomers:any = [
  {
    Steps: "A",
    Formula: "",
    Description: "Buy",
    Amount: 0,
  },
  {
    Steps: "B",
    Formula: "",
    Description: "PDQ Charge",
    Amount: 0,
  },
  {
    Steps: "C",
    Formula: "A+B",
    Description: "Total Expense",
    Amount: 0,
  },
  {
    Steps: "D",
    Formula: "",
    Description: "Sales",
    Amount: 0,
  },
  {
    Steps: "E",
    Formula: "",
    Description: "Credit Card Charge",
    Amount: 0,
  },
  {
    Steps: "F",
    Formula: "D+E",
    Description: "Total Sales",
    Amount: 0,
  },
  {
    Steps: "G",
    Formula: "",
    Description: "Agent Commission",
    Amount: 0,
  },
  {
    Steps: "H",
    Formula: "F-C-G",
    Description: "Net Profit",
    Amount: 0,
  }]
@Component({
  selector: 'app-commission-calculation-step',
  templateUrl: './commission-calculation-step.component.html',
  styleUrls: ['./commission-calculation-step.component.scss']
})
export class CommissionCalculationStepComponent implements OnInit {
  public gridData: any[] = sampleCustomers;
  @Input() folderSummary:any;
  @Input() commDetail:any;
  @Output() closeCommCalc: EventEmitter<string> = new EventEmitter();
  public currency='GBP';
  constructor(public intl: IntlService,public common: CommonserviceService) { }

  ngOnInit() {
    debugger;
    this.currency=this.commDetail.currency;
    if(this.commDetail.type=='FC'){
      this.gridData[0].Amount=this.folderSummary.FCTotalBUY;
      this.gridData[1].Amount=this.folderSummary.PDQCHARGE/this.folderSummary.FC_CIDExRate;
      this.gridData[2].Amount=this.gridData[0].Amount+this.gridData[1].Amount;
      this.gridData[3].Amount=this.folderSummary.FCTotalBUY+this.folderSummary.FCTotalCOMM;
      this.gridData[4].Amount=this.folderSummary.FCCREDITAMT;
      this.gridData[5].Amount=this.gridData[3].Amount+this.gridData[4].Amount;
      this.gridData[6].Amount=this.folderSummary.FCAGTCOMSUM;
      this.gridData[7].Amount=this.gridData[5].Amount-this.gridData[2].Amount-this.gridData[6].Amount;
    }else if(this.commDetail.type=='HC')
    {
      this.gridData[0].Amount=this.folderSummary.TotalBUY;
      this.gridData[1].Amount=this.folderSummary.PDQCHARGE;
      this.gridData[2].Amount=this.gridData[0].Amount+this.gridData[1].Amount;
      this.gridData[3].Amount=this.folderSummary.TotalBUY+this.folderSummary.TotalCOMM;
      this.gridData[4].Amount=this.folderSummary.CREDITAMT;
      this.gridData[5].Amount=this.gridData[3].Amount+this.gridData[4].Amount;
      this.gridData[6].Amount=this.folderSummary.AGTCOMSUM;
      this.gridData[7].Amount=this.gridData[5].Amount-this.gridData[2].Amount-this.gridData[6].Amount;
    }
  }
public rowCallback(context: RowClassArgs) {
  const isActive = context.dataItem.Steps==='C'||context.dataItem.Steps==='F'||context.dataItem.Steps==='H';
    return {
      active: isActive
    };
}
public closeCalc(){
  this.closeCommCalc.emit('true');
}

}
