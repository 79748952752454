import { Component, OnInit } from "@angular/core";
import { AirticketDetail } from "../../Models/airticket-detail";
import { ActivatedRoute, Router } from "@angular/router";
import { AirticketService } from "../../Services/airticket.service";
import { FileService } from "../../Services/file.service";
import { FileDetails } from "../../Models/file-details";
import { ShareDataService } from "../../Services/share-data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { POCreationDetail } from "../../Models/pocreation-detail";
import { FolderSummary } from "../../Models/folder-summary";
import { Subscription } from "rxjs";
import { PocreationService } from "../../Services/pocreation.service";
import { Pocreationresult } from "../../Models/pocreationresult";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { DeletePO } from "../../Models/delete-po";
import { Deletepo } from "../../Models/deletepo";
import { CommonserviceService } from "../../Services/commonservice.service";
import { LookUpDetails } from "../../Services/look-up-details";
import { LookUpDetailsService } from "../../Services/look-up-details.service";
import { IntlService } from "@progress/kendo-angular-intl";
import { AirticketDetailsView } from "../../BusinessLayer/airticket-details-view";
import { FolderpriceService } from "../../Services/folderprice.service";

@Component({
  selector: "app-airticket-details",
  templateUrl: "./airticket-details.component.html",
  styleUrls: ["./airticket-details.component.scss"]
})
export class AirticketDetailsComponent extends AirticketDetailsView
  implements OnInit {

  //#region constructor
  constructor(
    public intl: IntlService,
    public commonservice: CommonserviceService,
    public lookupApi: LookUpDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    private api: AirticketService,
    public apiFile: FileService,
    public apiShared: ShareDataService,
    public spinner: NgxSpinnerService,
    private apiPO: PocreationService,
    private translateapi: TranslateService,
    private toastr: ToastrService,
    public objFolderPrice: FolderpriceService,
  ) {
    super(apiShared, spinner, apiFile, lookupApi);
  }
  //#endregion constructor

  //#region for Getting Router Parameter
  public guid: string = this.route.snapshot.params["guidItinerary"];
  public orderNo = this.route.snapshot.params["orderNo"];
  public FolderQuoteCreatePO = false;
  public FolderQuoteDeletePO = false;
  custcountry: any;
  //#endregion for Getting Router Parameter

  //#region for ngOnIt
  ngOnInit() {
    //#region checking orderNo difference
    this.custcountry = localStorage.getItem('CustomerCountry')
    var orderno = sessionStorage.getItem("orderNo");
    if (orderno != null)
      if (this.orderNo !== orderno) {
        return this.router.navigate(["PenAir/Home"]);
      }

    //#end region checking orderNo difference

    //#region For scroll page on top automatically

    window.scrollTo(0, 0);

    //#endregion For scroll page on top automatically

    //#region Getting folderstatus
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.folderStatus == "Invoiced") {
      this.folderstatus = false;
      this.DisableAirTicketADMACM = false; //By Caseid-12641
      this.DisableAirTicketVoid = false; //By Caseid-12650
    }
    if (this.folderStatus == "Refund Request") {
      this.labelchange = true;
      this.DisableAirTicketEdit = true; //By-12760
    }
    this.lockStatus = this.apiShared.folderReadOnly;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;
      this.invoice = false;
    } else {
      this.formeditable = false;
      this.invoice = true;
      this.createPoInvoice = true;
    }
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;
      this.FolderHideStatus = true; //12780
    }
    this.folderEditMode = "false";
    if (this.folderStatus == "Refund") {
      //By Caseid-12641
      this.DisableAirTicketADMACM = false;
    }

    this.subscription.add(
      this.lookupApi.GetAllLookup("TAX").subscribe(data => {
        this.Tax = data["ResponseData"];
      })
    );
    this.subscription.add(this.lookupApi.GetAllLookup('ISWEX').subscribe(
      (data) => {

        debugger;
        if (data["ResponseData"] != null && data["ResponseData"].length > 0) {
          this.isWexEnabled = false;
        }
        else {
          this.isWexEnabled = true;
        }
      }
    ));
    // this.subscription.add(
    //   this.lookupApi.GetAllLookup("TAX").subscribe(data => {
    //     this.Tax = data["ResponseData"];
    //   })
    // );


    //#end region Getting folderstatus
    //#region GetFileDetails

    this.getFileDetails();

    //#endregion GetFileDetails

    //#region for taking Homecurrency and Exchange rate
    this.hcCurrency = this.apiShared.homeCurrency;
    this.hcExRate = this.apiShared.hcExRate;
    //#region for taking Homecurrency and Exchange rate

    //#region for Set FolderEditmode
    this.apiShared.Setapishareddata(this.folderEditMode, "folderEditMode");
    //#endregion for Set FolderEditmode

    //#region for GetAirtickect details and file details
    debugger;
    this.getAirticketDetails(this.orderNo, this.guid);
    this.getFolderValues();
    //#endregion for GetAirtickect details and file details

    //#region for getting folderQoutes value and show/hide Po Create/Delete Button
    var folderQuotes = sessionStorage.getItem("folderQuotes"); //12663
    if (folderQuotes) {
      this.FolderQuoteCreatePO = true;
      this.FolderQuoteDeletePO = true;
    } else {
      this.FolderQuoteCreatePO = false;
      this.FolderQuoteDeletePO = false;
    }
    if (this.folderStatus == "Saved") {
      //By Caseid-12650
      this.voidNewTicketDate = true;
      this.voidNewTicketNumber = true;
      this.DisableAirTicketVoid = false; //By Caseid-12650
      this.DisableAirTicketEdit = true; //By-12760
    }

    //#end region for getting folderQoutes value and show/hide Po Create/Delete Button

    this.BindTax();

  }
  //#endregion for ngOnIt

  //#endregion for ngOnIt
  //#endregion for ngOnIt
  public bsptabvisible: boolean;
  public bsptabhead: string;
  public CURR1: any;
  public EXCHANGE1: any;
  //#region for get Airticket Details
  getAirticketDetails(id, guid) {
    debugger;
    this.folderStatus = this.apiShared.FolderStatus; //By Caseid-12650
    this.spinner.show();
    this.subscription.add(
      this.api.getAirTicketDetails(id, guid, true).subscribe(data => {
        this.spinner.hide();
        if (data != null && data != undefined) {
          this.airticketDetail = data;
          this.PONO = this.airticketDetail.ResponseData.PONO;
          this.airticketDetail.ResponseData.InterInd =
            data.ResponseData.InterInd == ""
              ? ""
              : data.ResponseData.InterInd == "I"
                ? "International"
                : data.ResponseData.InterInd == "D"
                  ? "Domestic"
                  : "Transborder";
          this.airticketDetail.ResponseData.JOURNEY =
            data.ResponseData.JOURNEY == 1
              ? "Return"
              : data.ResponseData.JOURNEY == 2
                ? "One way"
                : "";
          this.CURR1 = this.airticketDetail.ResponseData.CURR;
          this.EXCHANGE1 = this.airticketDetail.ResponseData.EXCHANGE;
          if (this.PONO > 0) {
            this.pocreated = false;
            this.invoice = true;
            if (this.folderStatus == "Invoiced") {
              this.pocreated = true;
              this.DisableAirTicketADMACM = false; //ByCaseid-12641
              this.DisableAirTicketVoid = false; //By Caseid-12650
            }
            this.voidNewTicketDate = false; //By Caseid-12650
            this.voidNewTicketNumber = false; //By Caseid-12650
            if (this.folderStatus == "Saved") {
              //By-12760
              this.DisableAirTicketEdit = false;
            }
            if (this.folderStatus == "Refund Request") {
              //By-12760
              this.DisableAirTicketEdit = false;
            }
          } else {
            this.pocreated = true;
          }

          if (this.airticketDetail.ResponseData.BSP == 1) {
            this.isBSP = true;
          }
          else {
            this.isBSP = false;
          }
          //console.log(JSON.stringify(this.airticketDetail, null, 4));
          // this.airticketDetail.ResponseData.FC_Tax1_CID = this.hcCurrency;
          // this.airticketDetail.ResponseData.FC_Tax2_CID = this.hcCurrency;
          // this.airticketDetail.ResponseData.FC_Tax3_CID = this.hcCurrency;
          // this.airticketDetail.ResponseData.FC_Tax4_CID = this.hcCurrency;
          // this.airticketDetail.ResponseData.FC_Tax5_CID = this.hcCurrency;
          if (
            this.airticketDetail.ResponseData.EXCHANGE === null ||
            this.airticketDetail.ResponseData.EXCHANGE === 0
          ) {
            this.airticketDetail.ResponseData.EXCHANGE = this.hcExRate;
          }

          if (this.airticketDetail.ResponseData.ITSplit1 == "") {
            this.airticketDetail.ResponseData.ITSplit1 = this.fileDetail.ResponseData.BSPTAXCountryTax1;
          }
          if (this.airticketDetail.ResponseData.ITSplit2 == "") {
            this.airticketDetail.ResponseData.ITSplit2 = this.fileDetail.ResponseData.BSPTAXCountryTax2;
          }
          if (this.airticketDetail.ResponseData.ITSplit3 == "") {
            this.airticketDetail.ResponseData.ITSplit3 = this.fileDetail.ResponseData.BSPTAXCountryTax3;
          }
          if (this.airticketDetail.ResponseData.ITSplit4 == "") {
            this.airticketDetail.ResponseData.ITSplit4 = this.fileDetail.ResponseData.BSPTAXCountryTax4;
          }
          if (this.airticketDetail.ResponseData.ITSplit5 == "") {
            this.airticketDetail.ResponseData.ITSplit5 = this.fileDetail.ResponseData.BSPTAXCountryTax5;
          }
          if ((this.airticketDetail.ResponseData.PONO != null && this.airticketDetail.ResponseData.PONO > 0) && this.folderStatus == "Invoiced") {
            this.wexpocreated = true;
          }
          else {
            this.wexpocreated = false;
          }
          if (this.folderStatus == "Invoiced" || this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund")//By Caseid-12780
          {
            this.wexpocreated = true;
          }
          if (this.airticketDetail.ResponseData.BSP == 1) {

            if (this.airticketDetail.ResponseData.CURR != this.airticketDetail.ResponseData.FC_Tax1_CID) {
              this.CURR1 = (this.hcCurrency);

              this.commonservice.buycurrency = this.hcCurrency;
              this.EXCHANGE1 = (this.hcExRate);
            }

            this.bsptabvisible = false;
            this.bsptabhead = "BSP Tax Breakdown";
          } else {
            this.bsptabvisible = true;
            this.bsptabhead = "";
          }
          this.airticketDetail.ResponseData.TotalTax =
            this.airticketDetail.ResponseData.BUY2 +
            this.airticketDetail.ResponseData.BUY3 +
            this.airticketDetail.ResponseData.BUY4 +
            this.airticketDetail.ResponseData.BUY5;

          this.airticketDetail.ResponseData.PurchaseTax =
            this.airticketDetail.ResponseData.ITSplit1AMT +
            this.airticketDetail.ResponseData.ITSplit2AMT +
            this.airticketDetail.ResponseData.ITSplit3AMT +
            this.airticketDetail.ResponseData.ITSplit4AMT +
            this.airticketDetail.ResponseData.ITSplit5AMT;

          this.airticketDetail.ResponseData.OtherTax =
            this.airticketDetail.ResponseData.TotalTax -
            this.airticketDetail.ResponseData.PurchaseTax;

          this.airticketDetail.ResponseData.PurchaseValue =
            this.airticketDetail.ResponseData.BUY1 +
            this.airticketDetail.ResponseData.OtherTax;

          this.hcCurrency = this.apiShared.homeCurrency;
          this.buycurrency = this.airticketDetail.ResponseData.CURR;

          this.Producttype = true;
          this.TaxsplitCalculation();
          if (this.airticketDetail.ResponseData.CostingVATType == "NV") {
            this.divVat = false;
            this.divValue = 1;
            this.CostingVatType = "No VAT";
          } else if (
            this.airticketDetail.ResponseData.CostingVATType == "VSF"
          ) {
            this.divValue = 2;
            this.CostingVatType = "VAT on Service Fee";
            this.divVat = true;
          } else if (this.airticketDetail.ResponseData.CostingVATType == "VP") {
            this.divValue = 3;
            this.CostingVatType = "VAT on Purchase";
            this.divVat = true;
          } else if (
            this.airticketDetail.ResponseData.CostingVATType == "VSC"
          ) {
            this.CostingVatType = "VAT on Supp.Commission";
            this.divValue = 4;
            this.divVat = true;
          } else {
            this.divValue = 1;
            this.CostingVatType = "No VAT";
            this.divVat = false;
          }
          // if (this.airticketDetail.ResponseData.CostingTaxNo == 0) {
          //   this.airticketDetail.ResponseData.CostingTaxNo = 1;
          // }

          // if (
          //   this.airticketDetail.ResponseData.CostingTaxNo != 0 &&
          //   this.airticketDetail.ResponseData.CostingTaxNo != "" &&
          //   this.airticketDetail.ResponseData.CostingTaxNo != null &&
          //   this.airticketDetail.ResponseData.CostingTaxNo != undefined
          // ) {
          //   this.TaxselectedItem = this.Tax.find(
          //     item =>
          //       item.Code == this.airticketDetail.ResponseData.CostingTaxNo
          //   );
          // } else {
          //   if (this.TaxselectedItem != undefined)
          //     this.TaxselectedItem.Name = "";
          // }
          this.BindTax();
          this.loaded = true;
          this.spinner.hide();

          if (data.ResponseData.UNUSEDTICKET == 1) {
            this.enableUnusedTicket = true;
          } else {
            this.enableUnusedTicket = false;
          }
          this.VoidTicketDate =
            this.airticketDetail.ResponseData.TICKDATE != null
              ? this.airticketDetail.ResponseData.TICKDATE.toString() ==
                "1900-01-01T00:00:00"
                ? null
                : new Date(this.airticketDetail.ResponseData.TICKDATE)
              : null; //By Caseid-12650
          this.AirTicketVoidDetails.VoidTicketNumber = this.airticketDetail.ResponseData.TICKETNO; //By Caseid-12650
          this.TicketDateEdit =
            this.airticketDetail.ResponseData.TICKDATE != null
              ? this.airticketDetail.ResponseData.TICKDATE.toString() ==
                "1900-01-01T00:00:00"
                ? null
                : new Date(this.airticketDetail.ResponseData.TICKDATE)
              : null; //By Caseid-12760
          this.AirTicketEditDetails.EditTicketNumber = this.airticketDetail.ResponseData.TICKETNO; //By Caseid-12760
          this.LoadAirTicketGDS();
          this.LoadAirTicketIataNumber();
          this.loadPaymethod();
        } else {
          this.spinner.hide();
        }
      })
    );
    this.hcCurrency = this.apiShared.homeCurrency;
  }
  //#endregion for get Airticket Details
  //#region for Tax split Calculation
  TaxsplitCalculation() {
    debugger;
    this.folderExrate = this.apiShared.folderExchangeRate;

    this.buyExRate =
      this.airticketDetail.ResponseData.EXCHANGE == null ||
        this.airticketDetail.ResponseData.EXCHANGE == 0
        ? 1
        : this.airticketDetail.ResponseData.EXCHANGE;

    if (
      this.airticketDetail.ResponseData.CostingVATType == "NV" ||
      this.airticketDetail.ResponseData.CostingVATType == "" ||
      this.airticketDetail.ResponseData.CostingVATType == null ||
      this.airticketDetail.ResponseData.CostingVATType == undefined
    ) {
      this.airticketDetail.ResponseData.CostingCustomerSalesValue =
        (this.airticketDetail.ResponseData.FC_Tot_Buy * this.buyExRate) /
        this.folderExrate;

      this.airticketDetail.ResponseData.CostingCustomerServiceFee =
        this.airticketDetail.ResponseData.FCSell -
        this.airticketDetail.ResponseData.CostingCustomerSalesValue;
    }
    if (this.airticketDetail.ResponseData.CostingVATType == "VSF") {
      this.airticketDetail.ResponseData.CostingCustomerSalesValue =
        (this.airticketDetail.ResponseData.FC_Tot_Buy * this.buyExRate) /
        this.folderExrate;

      // this.airticketDetail.ResponseData.CostingCustomerServiceFee =
      //   this.airticketDetail.ResponseData.FCSell -
      //   this.airticketDetail.ResponseData.CostingCustomerSalesValue;
    }
  }
  //#endregion for Tax split Calculation
  //#region for onEdit
  //#region for change events
  //#region for GDSChange
  public GDSChange(value: any): void {
    //By Eldhose for case id-12760
    debugger;
    if (value != null && value != undefined) {
      this.AirTicketEditDetails.GDS = value.Name;
      this.GDSselectedItem = value.Name;
    } else {
      this.AirTicketEditDetails.GDS = "";
      this.GDSselectedItem = null;
    }
  }
  //#endregion for GDSChange
  //#region for IATANoChange
  public IATANoChange(value: any): void {
    //By Eldhose for case id-12760
    if (value != null && value != undefined) {
      this.AirTicketEditDetails.IATANo = value.Name;
      this.IATANoSelectedItem = value.Name;
    } else this.AirTicketEditDetails.IATANo = "";
    this.IATANoSelectedItem = null;
  }
  //#endregion for IATANoChange
  //#endregion for change events

  //#region for onEdit
  onEdit() {
    this.router.navigate([
      "/PenAir/Product/AirTicketDetailsEdit",
      this.orderNo,
      this.guid
    ]);
  }
  //#endregion for onEdit
  //#region for onCancel

  onCancel() {
    // this.router.navigate(['/PenAir/Folder/Products',this.orderNo],{skipLocationChange:true});
    this.commonservice.isfinalsaved = false;
    sessionStorage.setItem("pagevalue", "0");
    var folderQuotes = sessionStorage.getItem("folderQuotes");
    if (folderQuotes) {
      this.router.navigate(["/PenAir/CRM/Enquiry/View/", this.orderNo]);
    } else {
      this.router.navigate(["PenAir/Folder/FolderSummary", this.orderNo]);
    }
  }

  //#endregion for onCancel
  //#region for get Folder Values
  getFolderValues() {
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    this.exRate = new Number(this.folderExrate);
    this.projNo = this.apiShared.projNo;
  }
  //#endregion for get Folder Values
  //#region for onCreatePO
  async onCreatePO() {
    this.spinner.show();
    this.ticketDate =
      this.airticketDetail.ResponseData.TICKDATE == null
        ? "01-Jan-1900"
        : this.airticketDetail.ResponseData.TICKDATE; //By Caseid-12619

    if (
      Boolean(await this.commonservice.checkPermission1("01.12.01.26")) == true
    ) {
      this.POCreation = new POCreationDetail();
      if (
        this.airticketDetail.ResponseData.SUPPNAME == "" ||
        this.airticketDetail.ResponseData.PROJNO == null
      ) {
        this.showMsg = "";
        this.subscription.add(
          this.translateapi
            .get(["Messages.Supplierismandatory"])
            .subscribe(translations => {
              this.spinner.hide();
              this.showMsg = translations["Messages.Supplierismandatory"];
              this.toastr.error(this.showMsg, "", {
                timeOut: 0,
                disableTimeOut: true,
                closeButton: true
              });
            })
        );
      }
      if (this.ticketDate == "1900-01-01T00:00:00") {
        //By Case id-12619
        this.spinner.hide();
        this.subscription.add(
          this.translateapi
            .get(["Messages.InvalidTicketDate"])
            .subscribe(translations => {
              this.showMsg = translations["Messages.InvalidTicketDate"];
              this.toastr.error(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
            })
        );
        return;
      } else {
        this.POCreation.folderNo = this.orderNo;
        this.POCreation.projNo = this.airticketDetail.ResponseData.PROJNO;

        this.POCreation.passName = this.airticketDetail.ResponseData.PASSNAME;
        this.POCreation.postDate = new Date(
          this.airticketDetail.ResponseData.TICKDATE
        );
        this.POCreation.POGUIDItinerary = this.airticketDetail.ResponseData.GUIDItinerary;
        this.POCreation.Status = this.folderStatus;
        this.subscription.add(
          this.apiPO.CreatePO(this.POCreation).subscribe(res => {
            if (res != null && res != undefined) {
              this.Pocreationresultobj = res;
              this.spinner.hide();

              if (this.Pocreationresultobj.ResponseData.success_bit == "True") {
                if (this.Pocreationresultobj.ResponseData.invoiceno > 0) {
                  this.subscription.add(
                    this.translateapi
                      .get(["Messages.POCreated"])
                      .subscribe(translations => {
                        this.showMsg =
                          translations["Messages.POCreated"] +
                          ":" +
                          this.Pocreationresultobj.ResponseData.invoiceno;
                        this.toastr.success(this.showMsg, "", {
                          //By case id-12630
                          timeOut: 5000, //By case id-12630
                          disableTimeOut: false, //By case id-12630
                          closeButton: true
                        });
                      })
                  );
                  this.getAirticketDetails(
                    this.orderNo,
                    this.airticketDetail.ResponseData.GUIDItinerary
                  );
                  this.pocreated = false;
                }
                if (
                  this.Pocreationresultobj.ResponseData.message ==
                  "PO Already Created"
                ) {
                  this.spinner.hide();
                  this.showMsg = "";
                  this.subscription.add(
                    this.translateapi
                      .get(["Messages.POAlreadyCreated"])
                      .subscribe(translations => {
                        this.showMsg =
                          translations["Messages.POAlreadyCreated"];
                        this.toastr.warning(this.showMsg, "", {
                          timeOut: 0,
                          disableTimeOut: true,
                          closeButton: true
                        });
                      })
                  );
                }
              } else {
                this.spinner.hide();
                this.showMsg = "";
                if (
                  this.Pocreationresultobj.ResponseData.message ==
                  "PO Already Created"
                ) {
                  this.showMsg = "";
                  this.subscription.add(
                    this.translateapi
                      .get(["Messages.POAlreadyCreated"])
                      .subscribe(translations => {
                        this.showMsg =
                          translations["Messages.POAlreadyCreated"];
                        this.toastr.warning(this.showMsg, "", {
                          timeOut: 0,
                          disableTimeOut: true,
                          closeButton: true
                        });
                      })
                  );
                } else {
                  this.spinner.hide();
                  this.subscription.add(
                    this.translateapi
                      .get(["Messages.Failed"])
                      .subscribe(translations => {
                        this.showMsg = this.Pocreationresultobj.ResponseData.message;
                        this.toastr.error(this.showMsg, "", {
                          timeOut: 0,
                          disableTimeOut: true,
                          closeButton: true
                        });
                      })
                  );
                }
              }
            } else {
              this.spinner.hide();
            }
          })
        );
      }
    } else {
      this.spinner.hide();
      this.showmsg("01.12.01.26");
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.26');
    }
  }
  //#endregion for onCreatePO
  //#region for onDeletePO
  async onDeletePO() {
    this.folderStatus = this.apiShared.FolderStatus; //-12760
    this.spinner.show();
    if (
      Boolean(await this.commonservice.checkPermission1("01.12.01.27")) == true
    ) {
      this.subscription.add(
        this.api
          .getAirTicketDetails(
            this.orderNo,
            this.airticketDetail.ResponseData.GUIDItinerary,
            true
          )
          .subscribe(data => {
            this.airticketDetail = data;
          })
      );

      this.deletePO = new DeletePO();
      this.deletePO.Language = this.language;
      this.deletePO.FolderNo = this.orderNo;
      this.deletePO.PONO = this.airticketDetail.ResponseData.PONO;
      this.deletePO.POYearId = this.airticketDetail.ResponseData.PONOYEARID;
      this.deletePO.Guid = this.airticketDetail.ResponseData.GUIDItinerary;
      this.subscription.add(
        this.apiPO.DeletePO(this.deletePO).subscribe(res => {
          this.spinner.hide();
          if (res != null && res != undefined) {
            this.DeleteInvoiceResponse = res;
            this.result = this.DeleteInvoiceResponse.ResponseData["message"];

            if (this.result.includes("PO Deleted:")) {
              this.spinner.hide();
              this.pocreated = true;
              this.invoice = false;
              this.toastr.success(this.result, "", {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              });
              if (this.folderStatus == "Refund Request") {
                //By Caseid-12760
                this.DisableAirTicketEdit = true;
              }

              if (this.folderStatus == "Saved") {
                //By Caseid-12760
                this.DisableAirTicketEdit = true;
              }
            } else {
              this.spinner.hide();
              this.toastr.error(this.result, "", {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              });
            }
          } else {
            this.spinner.hide();
          }
        })
      );
      this.voidNewTicketDate = true; //By Caseid-12650
      this.voidNewTicketNumber = true; //By Caseid-12650
    } else {
      this.spinner.hide();
      this.showmsg("01.12.01.27");
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.27');
    }
  }
  //#endregion for onDeletePO
  //#region for ngOnDestroy
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  //#endregion for ngOnDestroy
  //#region for onClickAirTicketADMACM
  async onClickAirTicketADMACM() {
    //By Caseid-12641
    if (
      Boolean(await this.commonservice.checkPermission1("01.12.01.41")) == true
    ) {
      this.router.navigate([
        "PenAir/AirTicketDetails/AirTicketDetailsADMACM/",
        this.orderNo,
        this.guid,
        0,
        0
      ]);
    } else {
      this.ShowPermissionMessageForm("01.12.01.41"); //339
    }
  }
  //# endregion for onClick AirTicket ADMACM

  //#region for onClickAirTicketADMACM
  public openAirTicketVoidForm: any = false; //By Caseid-12650
  async onClickAirTicketVoid() {
    //By Caseid-12650
    if (
      Boolean(await this.commonservice.checkPermission1("01.12.01.42")) == true
    ) {
      this.openAirTicketVoidForm = true;
      this.submitted = false;
      this.AirTicketVoidDetails.NewTicketNumber = "";
      this.NewTicketDate = "";
      this.voidPoNotCreated = false;
    } else {
      this.ShowPermissionMessageForm("01.12.01.42");
    }
  }
  //#endregion for onClickAirTicketADMACM
  //#region for closeAirTicketVoidForm
  closeAirTicketVoidForm(
    save: boolean //By Caseid-12650
  ) {
    debugger;
    if (!save) {
      this.openAirTicketVoidForm = false;
    }
  }
  //#endregion for closeAirTicketVoidForm
  //#region for SaveAirTicketVoidDetails
  public async SaveAirTicketVoidDetails() {
    //By Caseid-12650
    debugger;
    this.submitted = true;
    this.DisableAirticketVoidSave = true;
    if (
      Boolean(await this.commonservice.checkPermission1("01.12.01.42")) == true
    ) {
      if (this.voidNewTicketNumber == false) {
        if (this.AirTicketVoidDetails.NewTicketNumber == "") {
          this.DisableAirticketVoidSave = false;
          this.voidPoNotCreated = true;
          return;
        }
      }

      if (this.voidNewTicketDate == false) {
        if (
          this.NewTicketDate == undefined ||
          this.NewTicketDate == "" ||
          this.NewTicketDate == null
        ) {
          this.DisableAirticketVoidSave = false;
          this.voidPoNotCreated = true;
          return;
        } else {
          this.AirTicketVoidDetails.NewTicketDate =
            this.NewTicketDate == null ||
              this.NewTicketDate == "" ||
              this.NewTicketDate == undefined
              ? new Date("01/Jan/1900")
              : this.NewTicketDate;
          this.AirTicketVoidDetails.VoidTicketDate =
            this.VoidTicketDate == null ||
              this.VoidTicketDate == "" ||
              this.VoidTicketDate == undefined
              ? new Date("01/Jan/1900")
              : this.VoidTicketDate;
        }
      } else {
        this.AirTicketVoidDetails.NewTicketDate =
          this.NewTicketDate == null ||
            this.NewTicketDate == "" ||
            this.NewTicketDate == undefined
            ? new Date("01/Jan/1900")
            : this.NewTicketDate;
        this.AirTicketVoidDetails.VoidTicketDate =
          this.VoidTicketDate == null ||
            this.VoidTicketDate == "" ||
            this.VoidTicketDate == undefined
            ? new Date("01/Jan/1900")
            : this.VoidTicketDate;
      }
      this.spinner.show();
      this.AirTicketVoidDetails.NewTicketDate =
        this.AirTicketVoidDetails.NewTicketDate == null ||
          this.AirTicketVoidDetails.NewTicketDate == "" ||
          this.AirTicketVoidDetails.NewTicketDate == undefined
          ? new Date()
          : this.AirTicketVoidDetails.NewTicketDate;
      this.AirTicketVoidDetails.NewTicketDate = this.commonservice.ConvertToLocaleDate(
        this.AirTicketVoidDetails.NewTicketDate
      );
      this.AirTicketVoidDetails.VoidTicketDate =
        this.AirTicketVoidDetails.VoidTicketDate == null ||
          this.AirTicketVoidDetails.VoidTicketDate == "" ||
          this.AirTicketVoidDetails.VoidTicketDate == undefined
          ? new Date()
          : this.AirTicketVoidDetails.VoidTicketDate;
      this.AirTicketVoidDetails.VoidTicketDate = this.commonservice.ConvertToLocaleDate(
        this.AirTicketVoidDetails.VoidTicketDate
      );
      this.AirTicketVoidDetails.FOLDERNO = this.orderNo;
      this.AirTicketVoidDetails.UserId = this.apiShared.uid;
      this.AirTicketVoidDetails.PROJNO = this.apiShared.projNo;
      this.AirTicketVoidDetails.GuId = this.guid;
      this.AirTicketVoidDetails.NewTicketNumber = this.AirTicketVoidDetails.NewTicketNumber;

      this.AirTicketVoidDetails.PNR = "";

      if (
        Boolean(this.commonservice.ValidDateRange(this.NewTicketDate)) == false
      ) {
        this.subscription.add(
          this.translateapi
            .get(["Messages.InvalidNewTicketDate"])
            .subscribe(translations => {
              this.showMessage = translations["Messages.InvalidNewTicketDate"];
              this.toastr.error(this.showMessage, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
            })
        );
        this.DisableAirticketVoidSave = false;
        this.spinner.hide();
        return;
      }
      this.api
        .UpdateAirTicketVoidDetails(this.AirTicketVoidDetails)
        .subscribe(res => {
          debugger;
          if (res != null && res != undefined) {
            debugger;
            if (res["ResponseData"].Status == "Success") {
              debugger;
              if (res["ResponseData"].MessageId == "439") {
                debugger;
                this.showMessage =
                  res["ResponseData"].MessageId +
                  ":- " +
                  res["ResponseData"].Message;
                this.toastr.success(this.showMessage, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                });
                this.closeAirTicketVoidForm(false);
                this.spinner.hide();
                if (this.voidNewTicketDate == false) {
                  this.router.navigate([
                    "/PenAir/Product/AirticketDetails/",
                    this.orderNo,
                    this.guid
                  ]);
                } else {
                  this.spinner.hide();
                  if (this.AirTicketVoidDetails.NewTicketNumber != "")
                    this.router.navigate([
                      "/PenAir/Product/AirticketDetails/",
                      this.orderNo,
                      this.guid
                    ]);
                  else
                    this.router.navigate([
                      "/PenAir/Folder/FolderSummary",
                      this.apiShared.orderNo
                    ]);
                }
                this.getAirticketDetails(this.orderNo, this.guid);
              } else {
                this.showMessage =
                  res["ResponseData"].MessageId +
                  ":- " +
                  res["ResponseData"].Message;
                this.toastr.warning(this.showMessage, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                });
                this.closeAirTicketVoidForm(false);
                this.spinner.hide();
                this.getAirticketDetails(this.orderNo, this.guid);
                this.router.navigate([
                  "/PenAir/Product/AirticketDetails/",
                  this.orderNo,
                  this.guid
                ]);
              }
              this.DisableAirticketVoidSave = false;
            } else if (res["ResponseData"].Status == "Failed") {
              this.showMessage = res["ResponseData"].StatusDetails;
              this.toastr.warning(this.showMessage, "", {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              });
              this.DisableAirticketVoidSave = false;
              this.spinner.hide();
              this.getAirticketDetails(this.orderNo, this.guid);
            } else {
              this.subscription.add(
                this.translateapi
                  .get(["Messages.Failed"])
                  .subscribe(translations => {
                    this.showMessage = translations["Messages.Failed"];
                    this.commonservice.showWARNINGtoastrmsg(
                      this.showMessage,
                      2000,
                      true,
                      false
                    );
                  })
              );
              this.DisableAirticketVoidSave = false;
              this.spinner.hide();
            }
          }
        });
    } else {
      this.ShowPermissionMessageForm("01.12.01.42");
      this.spinner.hide();
    }
  }

  //#endregion for SaveAirTicketVoidDetails
  //#region for onClickAirTicketEdit
  public openAirTicketEditForm: any = false; //By Caseid-12760
  async onClickAirTicketEdit() {
    ///By Caseid-12760
    if (
      Boolean(await this.commonservice.checkPermission1("01.12.01.43")) == true
    ) {
      this.openAirTicketEditForm = true;
      this.voidPoNotCreated = false;
      this.NewTicketDateEdit = ""; //12760
      this.AirTicketEditDetails.NewEditTicketNumber = "";
    } else {
      this.ShowPermissionMessageForm("01.12.01.43");
    }
  }
  //#endregion for onClickAirTicketEdit

  //#region for closeAirTicketEditForm
  closeAirTicketEditForm(
    save: boolean ///By Caseid-12760
  ) {
    debugger;
    if (!save) {
      this.openAirTicketEditForm = false;
    }
  }
  //#endregion for closeAirTicketEditForm

  //#region for UpdateAirTicketDetails
  public async UpdateAirTicketDetails() {
    //By Caseid-12760
    debugger;
    this.submitted = true;
    this.DisableAirticketUpdate = true;
    if (
      Boolean(await this.commonservice.checkPermission1("01.12.01.43")) == true
    ) {
      if (this.AirTicketEditDetails.NewEditTicketNumber == "") {
        this.DisableAirticketUpdate = false;
        this.voidPoNotCreated = true;
        return;
      }
      if (
        this.NewTicketDateEdit == undefined ||
        this.NewTicketDateEdit == "" ||
        this.NewTicketDateEdit == null
      ) {
        this.DisableAirticketUpdate = false;
        this.voidPoNotCreated = true;
        return;
      } else {
        this.AirTicketEditDetails.NewEditTicketDate =
          this.NewTicketDateEdit == null ||
            this.NewTicketDateEdit == "" ||
            this.NewTicketDateEdit == undefined
            ? new Date("01/Jan/1900")
            : this.NewTicketDateEdit;
        this.AirTicketEditDetails.EditTicketDate =
          this.TicketDateEdit == null ||
            this.TicketDateEdit == "" ||
            this.TicketDateEdit == undefined
            ? new Date("01/Jan/1900")
            : this.TicketDateEdit;
      }
      this.spinner.show();
      this.AirTicketEditDetails.NewEditTicketDate =
        this.AirTicketEditDetails.NewEditTicketDate == null ||
          this.AirTicketEditDetails.NewEditTicketDate == "" ||
          this.AirTicketEditDetails.NewEditTicketDate == undefined
          ? new Date()
          : this.AirTicketEditDetails.NewEditTicketDate;
      this.AirTicketEditDetails.NewEditTicketDate = this.commonservice.ConvertToLocaleDate(
        this.AirTicketEditDetails.NewEditTicketDate
      );
      this.AirTicketEditDetails.EditTicketDate =
        this.AirTicketEditDetails.EditTicketDate == null ||
          this.AirTicketEditDetails.EditTicketDate == "" ||
          this.AirTicketEditDetails.EditTicketDate == undefined
          ? new Date()
          : this.AirTicketEditDetails.EditTicketDate;
      this.AirTicketEditDetails.EditTicketDate = this.commonservice.ConvertToLocaleDate(
        this.AirTicketEditDetails.EditTicketDate
      );
      this.AirTicketEditDetails.FOLDERNO = this.orderNo;
      this.AirTicketEditDetails.UserId = this.apiShared.uid;
      this.AirTicketEditDetails.PROJNO = this.apiShared.projNo;
      this.AirTicketEditDetails.GuId = this.guid;
      this.AirTicketEditDetails.PNR = "";
      this.AirTicketEditDetails.PRTYCODE = this.airticketDetail.ResponseData.PRTYCODE;
      if (this.GDSselectedItem != undefined && this.GDSselectedItem == null)
        this.AirTicketEditDetails.GDS = this.GDSselectedItem.Name;
      else this.AirTicketEditDetails.GDS = "";
      if (
        this.IATANoSelectedItem != undefined &&
        this.IATANoSelectedItem == null
      )
        this.AirTicketEditDetails.IATANo = this.IATANoSelectedItem.Name;
      else this.AirTicketEditDetails.IATANo = "";
      if (
        Boolean(this.commonservice.ValidDateRange(this.NewTicketDateEdit)) ==
        false
      ) {
        this.subscription.add(
          this.translateapi
            .get(["Messages.InvalidNewTicketDate"])
            .subscribe(translations => {
              this.showMessage = translations["Messages.InvalidNewTicketDate"];
              this.toastr.error(this.showMessage, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
            })
        );
        this.DisableAirticketUpdate = false;
        this.spinner.hide();
        return;
      }
      this.api
        .UpdateAirTicketDetails(this.AirTicketEditDetails)
        .subscribe(res => {
          debugger;
          if (res != null && res != undefined) {
            debugger;
            if (res["ResponseData"].Status == "Success") {
              debugger;
              if (res["ResponseData"].MessageId == "512") {
                debugger;
                this.showMessage =
                  res["ResponseData"].MessageId +
                  ":- " +
                  res["ResponseData"].Message;
                this.toastr.success(this.showMessage, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                });
                this.closeAirTicketEditForm(false);
                this.spinner.hide();
                this.router.navigate([
                  "/PenAir/Product/AirticketDetails/",
                  this.orderNo,
                  this.guid
                ]);
                this.getAirticketDetails(this.orderNo, this.guid);
              } else {
                this.showMessage =
                  res["ResponseData"].MessageId +
                  ":- " +
                  res["ResponseData"].Message;
                this.toastr.warning(this.showMessage, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                });
                this.closeAirTicketEditForm(false);
                this.spinner.hide();
                this.router.navigate([
                  "/PenAir/Product/AirticketDetails/",
                  this.orderNo,
                  this.guid
                ]);
              }
              this.DisableAirticketUpdate = false;
            } else if (res["ResponseData"].Status == "Failed") {
              this.showMessage = res["ResponseData"].StatusDetails;
              this.toastr.warning(this.showMessage, "", {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              });
              this.DisableAirticketUpdate = false;
              this.spinner.hide();
            } else {
              this.subscription.add(
                this.translateapi
                  .get(["Messages.Failed"])
                  .subscribe(translations => {
                    this.showMessage = translations["Messages.Failed"];
                    this.commonservice.showWARNINGtoastrmsg(
                      this.showMessage,
                      2000,
                      true,
                      false
                    );
                  })
              );
              this.DisableAirticketUpdate = false;
              this.spinner.hide();
            }
          }
        });
    } else {
      this.ShowPermissionMessageForm("01.12.01.43");
      this.spinner.hide();
    }
  }

  //#endregion for UpdateAirTicketDetails
  public loadPaymethod() {
    //alert(this.parentForm.controls.PAYMODE.value)
    debugger;

    if (this.airticketDetail.ResponseData.COMONLY != null) {
      if (this.airticketDetail.ResponseData.COMONLY == "W") {
        this.rdCheckedCmp = true;
        this.toggleShowCmp();
      } else if (this.airticketDetail.ResponseData.COMONLY == "C") {
        this.rdCheckedCust = true;
        this.toggleShowCust();
      } else {
        this.rdCheckedCr = true;
        this.toggleShow();
      }
    } else {
      this.rdCheckedCr = true;
      this.toggleShow();
    }
  }
  toggleShow() {
    debugger;
    this.isShowncm = true;
    this.isShownCmp = false;
    this.isShownCust = false;


  }
  public toggleShowCmp() {
    debugger;
    this.isShownCmp = true;
    this.isShown = false;
    this.isShownCust = false;




  }
  public toggleShowCust() {
    this.isShownCust = true;
    this.isShownCmp = false;
    this.isShown = false;




  }




  // BindTax() {
  //   if (this.airticketDetail.ResponseData.PRTYCODE != null && this.airticketDetail.ResponseData.PRTYCODE != undefined && this.airticketDetail.ResponseData.PRTYCODE != 0) {
  //     this.lookupApi.GetSupplierorCustomerCountry('SUPPLIERS', this.airticketDetail.ResponseData.PRTYCODE).subscribe(data => {
  //       var countryID;
  //       if (data != null && data != undefined) {
  //         countryID = data["ResponseData"].CONTID;
  //       }
  //       else {
  //         this.lookupApi.GetDefaultCountry().subscribe(data1 => {
  //           if (data1 != null && data1 != undefined) {
  //             countryID = data1["ResponseData"].UsrCode;
  //             if (this.custcountry == null || this.custcountry == undefined) {
  //               this.custcountry = countryID;
  //             }

  //           }
  //         });
  //       }
  //       if (countryID != undefined && countryID != null) {
  //         this.getInputTaxLookup(countryID);
  //         if (this.custcountry != undefined && this.custcountry != null)
  //           this.getOutTaxLookup(this.custcountry);
  //       }
  //     }
  //     );
  //   }
  //  }
  onCancelPO() {
    this.ShownCarddetails = false;
  }

  ShowCardDetails() {
    debugger;
    this.spinner.show();
    this.ShownCarddetails = true;
    this.subscription.add(this.objFolderPrice.GetItineraryDetailsForWexPayment(this.airticketDetail.ResponseData.FOLDERNO, this.airticketDetail.ResponseData.GUIDItinerary, this.airticketDetail.ResponseData.ITYPE, this.airticketDetail.ResponseData.SLNO).subscribe(
      (data) => {
        if (data.ResponseData != null && data.ResponseData.datampdel != null && data.ResponseData.datampdel.ResponseMessage != null && data.ResponseData.datampdel.ResponseMessage != '') {
          this.spinner.hide();
          this.ShownCarddetails = false;
          // this.CID=data.ResponseData.CID;
          // this.suppcode=data.ResponseData.SUPPPRTYCODE;
          // this.PDQ=data.ResponseData.PDQ;
          // this.CardNo=data.ResponseData.datampdel.CardNumber
          // this.CardSecurityCode=data.ResponseData.datampdel.CardSecurityCode;
          // this.MerchantLogUniqueId=data.ResponseData.datampdel.MerchantLogUniqueId;
          // if(data.ResponseData.datampdel.ExpirationDate!=null)
          // this.CardExpiryDate=this.commonservice.ConvertToLocaleDate(data.ResponseData.datampdel.ExpirationDate);

          // this.DateOfTravel= data.ResponseData.datampdel.DateOfTravel;
          this.commonservice.showWARNINGtoastrmsg(data.ResponseData.datampdel.ResponseMessage, 2000, true, false);
        } else if (data.ResponseData != null && data.ResponseData.datampdel != null && data.ResponseData.datampdel.ResponseMessage != null && data.ResponseData.datampdel.ResponseMessage == '') {
          this.spinner.hide();
          this.CID = data.ResponseData.CID;
          this.suppcode = data.ResponseData.SUPPPRTYCODE;
          this.PDQ = data.ResponseData.PDQ;
          this.CardNo = data.ResponseData.datampdel.CardNumber
          this.CardSecurityCode = data.ResponseData.datampdel.CardSecurityCode;
          this.MerchantLogUniqueId = data.ResponseData.datampdel.MerchantLogUniqueId;
          if (data.ResponseData.datampdel.ExpirationDate != null)
            this.CardExpiryDate = this.commonservice.ConvertToLocaleDate(data.ResponseData.datampdel.ExpirationDate);

          this.DateOfTravel = data.ResponseData.datampdel.DateOfTravel;
        }
        else {
          this.CID = data.ResponseData.CID;
        }
        this.spinner.hide();
        //this.r=data.ResponseData.

        //  this.subscription.add(this.objFolderPrice.GetWexPaymentSettingsDataByID(1).subscribe(
        //   (data) => {
        //    // this.CardNo=data.ResponseData.
        //   }
        // ))
      }
    ))

  }
  onCreateWEXPO(id: any) {
    this.spinner.show();
    if (this.SupplierRef == null || this.SupplierRef == '') {
      this.spinner.hide();
      this.commonservice.showWARNINGtoastrmsg("Messages.SupplierRefMandatory", 2000, true, false);
      return;
    }
    debugger;


    this.objFolderPrice.body = new Object;
    this.objFolderPrice.body.GUIDItinerary = this.airticketDetail.ResponseData.GUIDItinerary;
    this.objFolderPrice.body.TYPE = this.airticketDetail.ResponseData.ITYPE;
    this.objFolderPrice.body.SLNO = this.airticketDetail.ResponseData.SLNO;
    this.objFolderPrice.body.FOLDERNO = this.airticketDetail.ResponseData.FOLDERNO;
    //this.objFolderPrice.body.PDQNO=this.airticketDetail.ResponseData.PDQNO;
    this.objFolderPrice.body.Amount = this.airticketDetail.ResponseData.FC_Tot_Buy;
    this.objFolderPrice.body.MerchantLogUniqueId = this.MerchantLogUniqueId;
    this.objFolderPrice.body.SupplierRef = this.SupplierRef;
    this.objFolderPrice.body.supplierCode_bigint = this.suppcode;
    this.objFolderPrice.body.pTravelDateFrom_smalldatetime = this.commonservice.ConvertToLocaleDate(this.DateOfTravel);
    this.objFolderPrice.body.CID = this.CID;
    if (id == 1) {
      this.objFolderPrice.body.PostType = "Save";
    }
    this.DisableFC_Tot_Buy = true;
    this.objFolderPrice.body.CuurentDate = this.commonservice.ConvertToLocaleDate(new Date());
    this.subscription.add(this.objFolderPrice.AutomaticPOCreationAndSave().subscribe(
      (data) => {
        if (data.ResponseData != null && data.ResponseData != null && data.ResponseData.ResponseMessage != null && data.ResponseData.ResponseMessage != '' && data.ResponseData.MSGID == 4) {
          this.spinner.hide();
          this.commonservice.showWARNINGtoastrmsg(data.ResponseData.ResponseMessage, 5000, true, false);
        }
        else if (data.ResponseData != null && data.ResponseData != null && data.ResponseData.ResponseMessage != null && data.ResponseData.ResponseMessage != '' && (data.ResponseData.MSGID == 1 || data.ResponseData.MSGID == 2 || data.ResponseData.MSGID == 3)) {
          this.spinner.hide();
          if (data.ResponseData.MSGID == 1) {
            this.commonservice.showSUCCESStoastrmsg("Messages.saved_success", 5000, true, false);

          }
          else {
            this.commonservice.showSUCCESStoastrmsg(data.ResponseData.ResponseMessage, 5000, true, false);
            this.router.navigate(["PenAir/Folder/FolderSummary", this.orderNo]);

          }
        }

        this.spinner.hide();
        //this.r=data.ResponseData.


      }
    ))


  }
  EnableFC_Tot_Buy() {
    debugger;
    this.DisableFC_Tot_Buy = false;

  }
  onCopy() {
    debugger;
    this.spinner.show();
    this.objFolderPrice.CopyProductDetails(this.orderNo, this.airticketDetail.ResponseData.GUIDItinerary, "Ticket").subscribe(x => {
      debugger;
      this.spinner.hide();
      if (x.ResponseData.value == 1) {
        this.router.navigate([
          "/PenAir/Product/AirTicketDetailsEdit",
          this.orderNo,
          x.ResponseData.GUIDItinerary
        ]);
      }

    });
  }

  getInputTaxLookup(country: any) {
    this.subscription.add(this.lookupApi.GetLookupById('InputTaxMaster', country, "''").subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.Tax = data["ResponseData"];

          // alert(this.parentForm.controls.ITYPE.value);   
          var tax = this.airticketDetail.ResponseData.CostingTaxNo;
          if (tax != "") {

            this.TaxselectedItem = this.Tax.find(item => item.Code == tax);

          }
        }
      }
    ));
  }
  getOutTaxLookup(country: any) {
    this.subscription.add(this.lookupApi.GetLookupById('OutputTaxMaster', country, "''").subscribe(
      (data) => {
        if (data != null && data != undefined) {

          this.Tax = data["ResponseData"];

          // alert(this.parentForm.controls.ITYPE.value);   
          var tax = this.airticketDetail.ResponseData.SELLTAXNO;
          if (tax != "") {

            this.OutTaxselectedItem = this.Tax.find(item => item.Code == tax);

          }

        }
      }
    ));
  }
  BindTax() {
    if (this.airticketDetail.ResponseData.PRTYCODE != null && this.airticketDetail.ResponseData.PRTYCODE != undefined && this.airticketDetail.ResponseData.PRTYCODE != 0) {
      this.lookupApi.GetSupplierorCustomerCountry('SUPPLIERS', this.airticketDetail.ResponseData.PRTYCODE).subscribe(data => {
        var countryID;
        if (data != null && data != undefined) {
          countryID = data["ResponseData"].CONTID;
        }
        else {
          this.lookupApi.GetDefaultCountry().subscribe(data1 => {
            if (data1 != null && data1 != undefined) {
              countryID = data1["ResponseData"].UsrCode;
              if (this.custcountry == null || this.custcountry == undefined) {
                this.custcountry = countryID;
              }

            }
          });
        }
        if (countryID != undefined && countryID != null) {
          this.getInputTaxLookup(countryID);
          if (this.custcountry != undefined && this.custcountry != null)
            this.getOutTaxLookup(this.custcountry);
        }
      }
      );
    }
  }
}
