import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonserviceService } from '../../Services/commonservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicLayoutComponent } from '../../public-layout/public-layout.component';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { TranslateService } from '@ngx-translate/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { FileService } from '../../Services/file.service';
import { ShareDataService } from '../../Services/share-data.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { ToastrService } from 'ngx-toastr';
import { Reportschedulersetupservice } from '../../Services/reportschedulersetupservice';
import { LogviewformDTO } from '../../Models/reportscheduler';
import { Logviewformservice } from '../../Services/logviewformservice';

@Component({
  selector: 'app-reportschedulersetupform',
  templateUrl: './reportschedulersetupform.component.html',
  styleUrls: ['./reportschedulersetupform.component.scss']
})
export class ReportschedulersetupformComponent implements OnInit {
  @Output() FormStatus = new EventEmitter<string>();
  @Output() FormClose = new EventEmitter<string>();
  @Input() ReportSchedulerSetup;
  @Input() ModuleName;
  @Input() EmailSubject;
  @Input() RSSTAutoNo;
  public LogDate: any;
  public LogviewDTO: LogviewformDTO;
  public Months: Array<string> = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  private subscription: Subscription = new Subscription();
  public filetypeListsms: Array<string> = ["Text"];
  public recurrenceList: Array<string> = ["Daily", "Weekly", "Biweekly", "Monthly", "Quarterly", "Yearly", "10 Min",
    "30 Min", "1 Hour", "4 Hours", "8 Hours"];
  public filetypeList: Array<string> = ["Acrobat Format (PDF)", "Microsoft Excel (XLS)", "Microsoft Excel Data Only (XLS)", "Microsoft Word (DOC)"];

  constructor(public translate: TranslateService, private toastr: ToastrService, private logviewform: Logviewformservice,
     private route: ActivatedRoute, public common: CommonserviceService, private reportschedulerservice: Reportschedulersetupservice, 
     private apiShared: ShareDataService, private lookupApi: LookUpDetailsService, private formBuilder: FormBuilder, private router: Router, 
     private translateapi: TranslateService, private flashMessage: FlashMessagesService, private emailapi: FileService) { }
  public rdCheckedEmail = true;
  public rdCheckedSms = false;
  public Month: any;
  public toemail: any;
  ReportSchedulerSetupForm: FormGroup;
  //public RSST_RequestCriteria:any;
  //public AutoNo=this.route.snapshot.params['autono'];
  public checkTab = 0;
  public hidebuttons = true;
  public StartDate: any;
  public startDate: any;//337
  public nextRunDate: any;
  public StartTime: any;
  public Active: any;
  public showcomboBox = false;
  public hidecreated = false;
  public showemail = true;
  public SchedulerName: any;
  public enableCheck = true;
  public submitted = false;
  public showMsg: any;
  public kendoemail: any;
  public Notes: any;
  ccvalid: any = true;
  BOOKEBYList: Array<LookUpDetails["ResponseData"]> = [];
  public BOOKEBYListSelectedItem: LookUpDetails["ResponseData"];
  public CreatedUser: any;
  public CreatedUserName: any;
  public itemToRemove: any;
  public selectedTab = 0;
  public value = 2021;
  public MobileNo: any;
  public LogViewForm: any;
  public RSST_CrUID: any;
  public RSST_LupDateUID: any;
  public ItemToRemove: any;
  public isDisabled: boolean = true;
  public Datevalue: Date = new Date();
  public DisabledSaveButton: boolean = false;
  ngOnInit() {


    this.ReportSchedulerSetupForm = this.formBuilder.group({
      RSST_AutoNo: [0],
      RSST_Active: [1],
      RSST_SchdulerName: this.ModuleName,
      RSST_Recurrence: [''],
      RSST_StartDate: [''],
      RSST_StartTime: [''],
      RSST_Remarks: [''],
      RSST_SMTPUser: ['', Validators.required],
      // RSST_ToEmail: ['', [Validators.email]],
      // RSST_CCEmail: ['', [Validators.email]],
      RSST_ToEmail: [''],
      RSST_CCEmail: [''],
      RSST_EmailSubject: this.EmailSubject,
      RSST_MessageBody: [''],
      RSST_FileType: [''],
      RSST_MobileNo: ['', [Validators.maxLength(12), Validators.pattern('[0-9]+')]],
      RSST_ISDCode: [''],
      RSST_Notes: [''],
      RSST_SchedulerType: [''],
      RSST_ReportName: this.ModuleName,
      RSST_CrDate: [''],
      RSST_CrTime: [''],
      RSST_CrUID: [''],
      RSST_LupDate: [''],
      RSST_LupDateTime: [''],
      RSST_LupDateUID: [''],
      RSST_ExeArguments: [''],
      RSST_RequestCriteria: this.ReportSchedulerSetup,
      RSST_LastRunResult: [''],
      RSST_LastRunResultDate: [''],
      RSST_ModuleName: this.ModuleName,
      RSST_LastRunDate: ['01/Jan/1900'],
      RSST_LastRunTime: ['01/Jan/1900'],
      RSST_NextRunDate: [''],
      RSST_NextRunTime: ['01/Jan/1900'],
      RSST_Source: [''],
      CreatedUsername: '',
      LastUpdateusername: ''






    })
    this.RSST_CrUID = this.apiShared.uid;
    this.RSST_LupDateUID = this.apiShared.uid;
    this.enableCheck = true;
    this.LogDate = this.value;




    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {


        this.BOOKEBYList = data["ResponseData"]



      }
    ));




    if (this.RSSTAutoNo != null || this.RSSTAutoNo != undefined) {

      this.getReportscheduler(this.RSSTAutoNo);

      // this.showemail=true;
    }
    else {
      this.checkboxChange()
    }
    this.rdCheckedEmail == true;
    this.enableCheck = true;

  }

  public onTabSelected(e) {

  }
  ValidateISDCodelength() {
    if (this.ReportSchedulerSetupForm.controls.RSST_ISDCode.value != null) {
      var ISDCode = this.ReportSchedulerSetupForm.controls.RSST_ISDCode.value.toString();
      if (ISDCode != null && ISDCode.length > 4) {
        ISDCode = ISDCode.substring(0, 4);
        this.ReportSchedulerSetupForm.controls.RSST_ISDCode.setValue(ISDCode);
      }
    }
  }
  public onNextRunDateChange(Datevalue: Date): void {

    this.ReportSchedulerSetupForm.controls.RSST_NextRunDate.setValue(Datevalue)
  }
  getReportscheduler(AutoNo) {

    this.subscription.add(this.reportschedulerservice.getReportschedulerById(AutoNo)
      .subscribe(data => {

        if (data != null && data != undefined) {
          debugger;
          this.hidecreated = true;
          this.ReportSchedulerSetupForm.setValue({
            RSST_AutoNo: this.RSSTAutoNo,
            RSST_Active: data.ResponseData.RSST_Active,
            RSST_SchdulerName: data.ResponseData.RSST_SchdulerName,
            RSST_Recurrence: data.ResponseData.RSST_Recurrence,

            RSST_StartDate: data.ResponseData.RSST_StartDate != null ? (data.ResponseData.RSST_StartDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.RSST_StartDate)) : null,
            RSST_StartTime: data.ResponseData.RSST_StartTime != null ? (data.ResponseData.RSST_StartTime.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.RSST_StartTime)) : null,
            RSST_NextRunDate: data.ResponseData.RSST_NextRunDate != null ? (data.ResponseData.RSST_NextRunDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.RSST_NextRunDate)) : null,
            RSST_Remarks: data.ResponseData.RSST_Remarks,
            RSST_SMTPUser: data.ResponseData.RSST_SMTPUser,
            RSST_ToEmail: data.ResponseData.RSST_ToEmail,
            RSST_CCEmail: data.ResponseData.RSST_CCEmail,
            RSST_EmailSubject: data.ResponseData.RSST_EmailSubject,
            RSST_MessageBody: data.ResponseData.RSST_MessageBody,
            RSST_FileType: data.ResponseData.RSST_FileType,
            RSST_SchedulerType: data.ResponseData.RSST_SchedulerType,
            RSST_MobileNo: data.ResponseData.RSST_MobileNo,
            RSST_ModuleName: data.ResponseData.RSST_ModuleName,
            RSST_ReportName: data.ResponseData.RSST_ReportName,
            //  RSST_NextRunDate:data.ResponseData.RSST_NextRunDate,
            RSST_NextRunTime: data.ResponseData.RSST_NextRunTime,
            RSST_LastRunDate: data.ResponseData.RSST_LastRunDate,
            RSST_LastRunTime: data.ResponseData.RSST_LastRunTime,
            RSST_LupDateUID: data.ResponseData.RSST_LupDateUID,
            RSST_CrDate: data.ResponseData.RSST_CrDate,
            RSST_CrTime: data.ResponseData.RSST_CrTime,
            RSST_CrUID: data.ResponseData.RSST_CrUID,
            RSST_ISDCode: data.ResponseData.RSST_ISDCode,
            RSST_LastRunResult: data.ResponseData.RSST_LastRunResult,
            RSST_Notes: data.ResponseData.RSST_Notes,
            RSST_RequestCriteria: data.ResponseData.RSST_RequestCriteria,
            RSST_Source: data.ResponseData.RSST_Source,
            RSST_ExeArguments: data.ResponseData.RSST_ExeArguments,
            RSST_LupDate: data.ResponseData.RSST_LupDate,
            RSST_LupDateTime: data.ResponseData.RSST_LupDateTime,
            RSST_LastRunResultDate: data.ResponseData.RSST_LastRunResultDate,
            CreatedUsername: data.ResponseData.CreatedUsername,
            LastUpdateusername: data.ResponseData.LastUpdateusername


          })
          if (data.ResponseData.RSST_SchedulerType == "Email") {
            this.RadioEmailClick();

          }
          else if (data.ResponseData.RSST_SchedulerType == "SMS") {
            this.RadioSmsClick();


          }
          if (data.ResponseData.RSST_Active === 1) {
            this.enableCheck = true;
          }
          else {
            this.enableCheck = false;
          }
          if (data.ResponseData.RSST_SMTPUser.value != "") {
            this.BOOKEBYListSelectedItem = this.BOOKEBYList.find(item => item.Code == this.ReportSchedulerSetupForm.controls.RSST_SMTPUser.value);

            // this.ReportSchedulerSetupForm.controls.RSST_SMTPUser.setValue(this.BOOKEBYListSelectedItem.Name);

          }

        }
      }));

  }

  validateEmail(email) {
    const regularExpression =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }
  public OnToChange() {
    debugger;
    this.ccvalid = true;
    var str = this.ReportSchedulerSetupForm.value["RSST_ToEmail"];
    var splitted = str.split(",");
    var splitted1 = str.split(";");
    if (splitted.length > 1) {
      splitted.forEach((obj) => {
        if (obj != "") {
          this.ccvalid = this.validateEmail(obj);
          if (this.ccvalid == false) {
            // this.addressdetailsform.controls["CC"].setErrors({'incorrect': true});
            return;
          }
        }
      });
    } else if (splitted1.length > 1) {
      var str1 = this.ReportSchedulerSetupForm.value["RSST_ToEmail"];
      var splitted = str1.split(";");
      splitted.forEach((obj) => {
        if (obj != "") {
          this.ccvalid = this.validateEmail(obj);
          if (this.ccvalid == false) {
            return;

          }
        }
      });
    } else {
      var str1 = this.ReportSchedulerSetupForm.value["RSST_ToEmail"];
      if (str1 != "") this.ccvalid = this.validateEmail(str1);
      else this.ccvalid = true;
    }

    if (this.ccvalid == false) {
      this.ReportSchedulerSetupForm.controls["RSST_ToEmail"].setErrors({ incorrect: true });

      //email_invalid
    }
  }
  public OnCCChange() {
    debugger;
    this.ccvalid = true;
    var str = this.ReportSchedulerSetupForm.value["RSST_CCEmail"];
    var splitted = str.split(",");
    var splitted1 = str.split(";");
    if (splitted.length > 1) {
      splitted.forEach((obj) => {
        if (obj != "") {
          this.ccvalid = this.validateEmail(obj);
          if (this.ccvalid == false) {

            return;
          }
        }
      });
    } else if (splitted1.length > 1) {
      var str1 = this.ReportSchedulerSetupForm.value["RSST_CCEmail"];
      var splitted = str1.split(";");
      splitted.forEach((obj) => {
        if (obj != "") {
          this.ccvalid = this.validateEmail(obj);
          if (this.ccvalid == false) {
            return;

          }
        }
      });
    } else {
      var str1 = this.ReportSchedulerSetupForm.value["RSST_CCEmail"];
      if (str1 != "") this.ccvalid = this.validateEmail(str1);
      else this.ccvalid = true;
    }

    if (this.ccvalid == false) {
      this.ReportSchedulerSetupForm.controls["RSST_CCEmail"].setErrors({ incorrect: true });

      //email_invalid
    }
  }

  public onTabSelect(e) {
    this.selectedTab = e.index

    if (e.index == 2) {
      this.hidebuttons = false;
    }
    else {
      this.hidebuttons = true;
    }

  }


  RadioEmailClick() {

    this.selectedTab = 0;
    this.showcomboBox = false;
    this.checkTab = 0;
  }


  RadioSmsClick() {
    debugger;
    this.rdCheckedSms = true;
    this.selectedTab = 1;
    this.showcomboBox = true;
    this.checkTab = 1;

  }
  get f() { return this.ReportSchedulerSetupForm.controls; }


  public opened = false;
  public open() {
    this.opened = true;
  }


  public close(status) {
    this.opened = false;


  }
  public loadView() {
    //this.LogViewForm=this.Notes;
    this.logviewform.notes = this.Notes;

    this.open();

  }

  public onGenerate() {
    this.LogviewDTO = new LogviewformDTO;
    if (this.Month == null || this.Month == undefined) {
      this.Month = '0' + (new Date().getMonth() + 1).toString().slice(-2)
    }
    this.LogviewDTO.LogDate = this.LogDate;

    this.LogviewDTO.Month = this.Month;



    this.subscription.add(this.logviewform.ViewLog(this.LogviewDTO)
      .subscribe(res => {


        if (res["ResponseData"].Status == "Success") {
          this.Notes = res["ResponseData"].StatusDetails;

          this.loadView();

        }
        else if (res["ResponseData"].Status == "Fail") {
          this.showMsg = res["ResponseData"].StatusDetails;
          this.toastr.warning(this.showMsg, "",
            {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
        }
        else {
          this.showMsg = res["ResponseData"].StatusDetails;
          this.toastr.warning(this.showMsg, "",
            {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
        }
      }
      ));
  }



  public CreatedUserChange(value: any): void {
    if (value != null && value != undefined) {

      this.CreatedUserName = value.ActualName;
      this.ReportSchedulerSetupForm.controls.RSST_SMTPUser.setValue(value.Code);

    }
    else {
      this.CreatedUserName = null;

      this.ReportSchedulerSetupForm.controls.RSST_SMTPUser.setValue("");
    }
  }




  Cancel() {
    this.FormClose.emit("formClosed");

  }

  public onCancel() {
    this.FormClose.emit("formClosed");

  }
  checkboxChange() {
    this.enableCheck == true;

    if (this.enableCheck == true) {
      this.ReportSchedulerSetupForm.controls.RSST_Active.setValue(1);

    }
    else {
      this.ReportSchedulerSetupForm.controls.RSST_Active.setValue(0);
    }
  }


  public async onFormSubmit() {
    if (Boolean(await this.common.checkPermission1('01.11.32.03.16.01')) == true) {
      debugger;
      this.DisabledSaveButton = true;
      this.submitted = true;
      this.startDate = (this.ReportSchedulerSetupForm.controls.RSST_StartDate.value == null || this.ReportSchedulerSetupForm.controls.RSST_StartDate.value == "" ? "01-Jan-1900" : this.ReportSchedulerSetupForm.controls.RSST_StartDate.value);//Case id-337
      this.startDate = this.common.ConvertToLocaleDate(this.startDate);


      if (Boolean(this.common.ValidDateRange(this.startDate)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidStartDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidStartDate'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        this.DisabledSaveButton = false;
        return;
      }

      this.nextRunDate = (this.ReportSchedulerSetupForm.controls.RSST_NextRunDate.value == null || this.ReportSchedulerSetupForm.controls.RSST_NextRunDate.value == "" ? "01-Jan-1900" : this.ReportSchedulerSetupForm.controls.RSST_NextRunDate.value);//Case id-337
      this.nextRunDate = this.common.ConvertToLocaleDate(this.nextRunDate);


      if (Boolean(this.common.ValidDateRange(this.nextRunDate)) == false)//Case id-337
      {
        this.subscription.add(this.translateapi.get(['Messages.InvalidNextRunDate']).subscribe((translations) => {
          this.showMsg = translations['Messages.InvalidNextRunDate'];
          this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
        }));
        this.DisabledSaveButton = false;
        return;
      }




      if (this.startDate == "01-Jan-1900" || this.startDate == null || this.startDate == undefined) {
        // this.startDate= this.common.ConvertToLocaleDate(new Date());
        this.subscription.add(this.translateapi.get(['Messages.startDate_required']).subscribe((translations) => {

          this.showMsg = translations['Messages.startDate_required'];;
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })

        }));
        this.DisabledSaveButton = false;
        return;

      }
      this.ReportSchedulerSetupForm.controls.RSST_StartTime.setValue(this.ReportSchedulerSetupForm.controls.RSST_StartTime.value)

      if (this.ReportSchedulerSetupForm.controls.RSST_StartTime.value == "" || this.ReportSchedulerSetupForm.controls.RSST_StartTime.value == null || this.ReportSchedulerSetupForm.controls.RSST_StartTime.value == undefined) {
        this.subscription.add(this.translateapi.get(['Messages.starttime_required']).subscribe((translations) => {

          this.showMsg = translations['Messages.starttime_required'];;
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })

        }));
        this.DisabledSaveButton = false;
        return;
      }


      if (this.ReportSchedulerSetupForm.controls.RSST_Recurrence.value == "" || this.ReportSchedulerSetupForm.controls.RSST_Recurrence.value == null || this.ReportSchedulerSetupForm.controls.RSST_Recurrence.value == undefined) {

        this.subscription.add(this.translateapi.get(['Messages.reccurance_required']).subscribe((translations) => {

          this.showMsg = translations['Messages.reccurance_required'];;
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })

        }));
        this.DisabledSaveButton = false;
        return;

      }

      if (this.checkTab == 1) {
        this.ReportSchedulerSetupForm.controls.RSST_SchedulerType.setValue('SMS');

        debugger;

        if (this.ReportSchedulerSetupForm.controls.RSST_MobileNo.value == "" || this.ReportSchedulerSetupForm.controls.RSST_MobileNo.value == null || this.ReportSchedulerSetupForm.controls.RSST_MobileNo.value == undefined) {

          this.subscription.add(this.translateapi.get(['Messages.mobno_required']).subscribe((translations) => {

            this.showMsg = translations['Messages.mobno_required'];;
            this.toastr.error(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })

          }));
          this.DisabledSaveButton = false;
          return;

        }
        if (this.ReportSchedulerSetupForm.controls.RSST_FileType.value == "" || this.ReportSchedulerSetupForm.controls.RSST_FileType.value == undefined) {
          this.subscription.add(this.translateapi.get(['Messages.filetype_required']).subscribe((translations) => {

            this.showMsg = translations['Messages.filetype_required'];;
            this.toastr.error(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })

          }));
          this.DisabledSaveButton = false;
          return;
        }
      }




      if (this.ReportSchedulerSetupForm.invalid) {
        this.DisabledSaveButton = false;
        return;
      }
      if (this.RSSTAutoNo != null && this.RSSTAutoNo != "0") {
        debugger;
        this.Active = this.enableCheck;
        this.ReportSchedulerSetupForm.controls.RSST_StartTime.setValue(this.ReportSchedulerSetupForm.controls.RSST_StartTime.value)



        // this.startDate=this.common.ConvertToLocaleDate(this.startDate);

        if (this.ReportSchedulerSetupForm.controls.RSST_StartTime.value == null || this.ReportSchedulerSetupForm.controls.RSST_StartTime.value == undefined) {
          this.subscription.add(this.translateapi.get(['Messages.starttime_required']).subscribe((translations) => {

            this.showMsg = translations['Messages.starttime_required'];;
            this.toastr.error(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })

          }));
          this.DisabledSaveButton = false;
          return;
        }
        // this.ReportSchedulerSetupForm.value.RSST_LupDateUID=this.RSST_LupDateUID; 

        if (this.checkTab == 0) {
          this.rdCheckedEmail == true

          this.ReportSchedulerSetupForm.controls.RSST_SchedulerType.setValue('Email');

          if (this.selectedTab == 0) {
            if (this.ReportSchedulerSetupForm.controls.RSST_ToEmail.value == "" || this.ReportSchedulerSetupForm.controls.RSST_ToEmail.value == undefined) {
              this.subscription.add(this.translateapi.get(['Messages.email_required']).subscribe((translations) => {

                this.showMsg = translations['Messages.email_required'];;
                this.toastr.error(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })

              }));
              this.DisabledSaveButton = false;
              return;
            }
            if (this.ReportSchedulerSetupForm.controls.RSST_FileType.value == "" || this.ReportSchedulerSetupForm.controls.RSST_FileType.value == undefined) {
              this.subscription.add(this.translateapi.get(['Messages.filetype_required']).subscribe((translations) => {

                this.showMsg = translations['Messages.filetype_required'];;
                this.toastr.error(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })

              }));
              this.DisabledSaveButton = false;
              return;
            }




          }

        }
        // else if(this.checkTab==1)
        // {
        //   this.ReportSchedulerSetupForm.value.RSST_SchedulerType="SMS";
        //   debugger;
        // if (this.ReportSchedulerSetupForm.controls.RSST_MobileNo.value=="" ||this.ReportSchedulerSetupForm.controls.RSST_MobileNo.value==null ||this.ReportSchedulerSetupForm.controls.RSST_MobileNo.value==undefined)
        //   {

        //     this.subscription.add(this.translateapi.get(['Messages.mobno_required']).subscribe((translations ) => {

        //      this.showMsg= translations['Messages.mobno_required']  ;;
        //      this.toastr.error(this.showMsg,"",{
        //        timeOut:3000,
        //        disableTimeOut:false,
        //        closeButton:true
        //      })

        //     })); 
        //     return;

        //  }
        // }
        //   else{
        //     this.ReportSchedulerSetupForm.value.RSST_SchedulerType="Email";
        //   }


        this.ReportSchedulerSetupForm.value.RSST_StartDate = this.startDate;
        this.ReportSchedulerSetupForm.value.RSST_NextRunDate = this.nextRunDate;
        this.ReportSchedulerSetupForm.value.RSST_LupDateUID = this.apiShared.uid;

        this.reportschedulerservice.UpdateReportScheduler(this.ReportSchedulerSetupForm.value).subscribe(res => {
          if (res["ResponseData"].Status == "Success") {
            debugger;

            this.showMsg = res["ResponseData"].StatusDetails;
            this.FormStatus.emit("SavedSuccesfully");


            // this.router.navigate(['PenAir/Commonsetting/ReportSchedulerList']);
            this.toastr.success(this.showMsg, "",
              {

                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true

              })
            this.DisabledSaveButton = false;

          }
          else {
            this.showMsg = res["ResponseData"].StatusDetails;
            this.toastr.warning(this.showMsg, "",
              {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              })
            this.DisabledSaveButton = false;
          }

        });
      }
      else {


        //this.ReportSchedulerSetupForm.value.RSST_LastRunDate=this.StartDate;
        if (this.checkTab == 0) {
          this.rdCheckedEmail == true

          this.ReportSchedulerSetupForm.controls.RSST_SchedulerType.setValue('Email');

          if (this.selectedTab == 0) {
            if (this.ReportSchedulerSetupForm.controls.RSST_ToEmail.value == "" || this.ReportSchedulerSetupForm.controls.RSST_ToEmail.value == undefined) {
              this.subscription.add(this.translateapi.get(['Messages.email_required']).subscribe((translations) => {

                this.showMsg = translations['Messages.email_required'];;
                this.toastr.error(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })

              }));
              this.DisabledSaveButton = false;
              return;
            }
            if (this.ReportSchedulerSetupForm.controls.RSST_FileType.value == "" || this.ReportSchedulerSetupForm.controls.RSST_FileType.value == undefined) {
              this.subscription.add(this.translateapi.get(['Messages.filetype_required']).subscribe((translations) => {

                this.showMsg = translations['Messages.filetype_required'];;
                this.toastr.error(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })

              }));
              this.DisabledSaveButton = false;
              return;
            }
          }

        }


        if (this.enableCheck == true) {
          this.ReportSchedulerSetupForm.controls.RSST_Active.setValue(1);
        }
        this.ReportSchedulerSetupForm.value.RSST_StartDate = this.startDate;
        this.ReportSchedulerSetupForm.value.RSST_CrUID = this.apiShared.uid;
        this.ReportSchedulerSetupForm.value.RSST_NextRunDate = this.nextRunDate;
        // this.ReportSchedulerSetupForm.value.RSST_NextRunDate= this.startDate;
        this.ReportSchedulerSetupForm.value.RSST_LupDateUID = this.apiShared.uid;

        this.reportschedulerservice.AddReportScheduler(this.ReportSchedulerSetupForm.value).subscribe(res => {

          if (res["ResponseData"].Status == "Success") {
            debugger;
            // this.router.navigate(['PenAir/Commonsetting/ReportSchedulerList']);
            this.showMsg = res["ResponseData"].StatusDetails;
            this.FormStatus.emit("SavedSuccesfully");
            this.toastr.success(this.showMsg, "",
              {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              })
            this.DisabledSaveButton = false;

            //  this.opened = false;
          }
          else if (res["ResponseData"].Status == "Failed") {

            this.showMsg = res["ResponseData"].StatusDetails;
            this.toastr.warning(this.showMsg, "",
              {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              })
            this.DisabledSaveButton = false;
          }
          else {

            this.showMsg = res["ResponseData"].StatusDetails;
            this.toastr.warning(this.showMsg, "",
              {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              })
            this.DisabledSaveButton = false;
          }

        });
      }
    }
    else {
      this.ShowPermissionMessageForm('01.11.32.03.16.01');
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.11.32.03.16.01');
    }
  }

  public async onDelete() {
    if (Boolean(await this.common.checkPermission1('01.11.32.03.16.02')) == true) {

      this.ItemToRemove = true;
    }
    else {
      this.ItemToRemove = null;
      this.ShowPermissionMessageForm('01.11.32.03.16.02');
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.11.32.03.16.02');
    }
  }
  public confirmDelete(shouldRemove: boolean): void {
    if (shouldRemove) {
      debugger;


      this.subscription.add(this.reportschedulerservice.DeleteReportScheduler(this.RSSTAutoNo)
        .subscribe(res => {
          if (res != null && res != undefined) {
            if (res["ResponseData"].Status = "Success") {
              //   this.router.navigate(['PenAir/Commonsetting/ReportSchedulerList']);
              this.FormStatus.emit("DeletedSuccesfully");
              this.showMsg = res["ResponseData"].StatusDetails;
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
            } else {
              this.showMsg = res["ResponseData"].StatusDetails;
              this.toastr.warning(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
            }
          }
        }));





    }
    this.ItemToRemove = null;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339

  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }
}

