import { Component, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { Projectmasterservice } from '../Services/projectmasterservice';
import { Projectlist } from '../Models/projectlist';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicLayoutComponent } from '../public-layout/public-layout.component';
import { CommonserviceService } from '../Services/commonservice.service';
import { ShareDataService } from '../Services/share-data.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { EmailService } from '../Services/email.service';
import { saveAs } from '@progress/kendo-file-saver';
import { Workbook, ExcelExportData } from '@progress/kendo-angular-excel-export';
import { State, process, SortDescriptor } from '@progress/kendo-data-query';
import { FileService } from '../Services/file.service';
import { PageChangeEvent, PageSizeItem } from '@progress/kendo-angular-grid';
@Component({
  selector: 'app-projectmaster',
  templateUrl: './projectmaster.component.html',
  styleUrls: ['./projectmaster.component.scss']
})
export class ProjectmasterComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public projectmaster: Array<Projectlist["ResponseData"]> = [];
  public gridData: any;
  public opened: any = false;
  public productToRemove: any;
  public language = this.apiShared.LanguageSelected;
  public showMsg: any;
  showFilter = false;
  public windowTop: number = -100;
  public windowLeft: number = -225;
  public offset = 10;
  public left = this.windowLeft;
  public top = this.windowTop;
  public width = window.innerWidth - 40;
  public height = 800;
  public openProjectMasterReport: any = false;
  public reportParms: any;
  public equals: 'equals';
  public Componentname: string;
  public removeProductConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  fromId: string;
  bccId: string;
  userID: number;
  public pageSizes: (PageSizeItem | number)[] = [20, 50, 100, {
    text: 'All',
    value: 'all',
}];
  constructor(private Projectmasterservice: Projectmasterservice, public common: CommonserviceService, private apiShared: ShareDataService
    , private toastr: ToastrService, private layout: PublicLayoutComponent, private emailapi: FileService, public email: EmailService, private route: ActivatedRoute, private translateapi: TranslateService, private router: Router) {
    this.allData = this.allData.bind(this);
  }



  ngOnInit() {
    // this.layout.folderstatus='';
    // this.layout.headerPassenger='';
    this.Componentname = "ProjectMaster";
    //  this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");
    sessionStorage.setItem('Componentname', this.Componentname);
    this.loaddata();
    this.userID = +(this.apiShared.uid);
    this.subscription.add(this.emailapi.GetReportEmail(this.userID).subscribe(data => {
      if (data != null && data != undefined) {
        this.fromId = data.ResponseData.FROMEMAIL,
          this.bccId = data.ResponseData.DEFAULTBCCMAIL
      }
    }));


  }
  public allData(): ExcelExportData { //By Caseid-12617
    const result: ExcelExportData = {
      data: this.gridData
    };
    return result;
  }
  public sort: SortDescriptor[] = [ //12731
    {
      field: 'PID',
      dir: 'asc'
    }
  ];
  public loaddata() {

    this.subscription.add(this.Projectmasterservice.GetProjectMasterDetails()
      .subscribe(data => {
        if (data != null && data != undefined) {
        debugger;
          this.gridData = data["ResponseData"];
          this.common.masterData=this.gridData; //case id 13648
          this.gridData.forEach(item => {
            item.SDate = item.SDate=='1900-01-01T00:00:00'?null:new Date(item.SDate);
            item.EDate = item.EDate=='1900-01-01T00:00:00'?null:new Date(item.EDate);
          });
        }
        else {
          this.gridData = [];
          this.common.masterData=[]; //case id 13648
        }
      }));



  }

  public onExcelExport2(args: any): void {
    this.email.filename = 'ProjectMaster.xlsx';
    this.email.reportname = 'Project Master';
    // Prevent automatically saving the file. We will save it manually after we fetch and add the details
    args.preventDefault();
    // this.open();
    const observables = [];
    const workbook = args.workbook;
    const rows = workbook.sheets[0].rows;
    // Get the default header styles.
    // Aternatively set custom styles for the details
    // https://www.telerik.com/kendo-angular-ui/components/excelexport/api/WorkbookSheetRowCell/
    const headerOptions = rows[0].cells[0];
    // create a Workbook and save the generated data URL
    // https://www.telerik.com/kendo-angular-ui/components/excelexport/api/Workbook/
    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      // https://www.telerik.com/kendo-angular-ui/components/filesaver/
      saveAs(dataUrl, 'ProjectMaster.xlsx');
      this.email.fileurl = this.dataURItoBlob(dataUrl);
    });
  }
  public open() {
    this.opened = true;
  }
  public close(status : any) {
    if(status=="EmailSendSuccesfully" || status == 'Closed') //13552 email modifgication
    {
      this.opened = false;
    }

  }
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // // create a view into the buffer
    var ia = new Uint8Array(ab);

    // // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }
  onProjectDetailsCellClick(e) {

    if (e.column.field != null) {
      let clickedRowItem = e.dataItem;
      if (clickedRowItem.AutoNo !== null && clickedRowItem.AutoNo !== 0) {
        this.router.navigate(['/PenAir/Projects/ProjectDetails/', clickedRowItem.AutoNo]);
      }
    }


  }
  async addProjectHandler() {
    if (Boolean(await this.common.checkPermission1('01.11.32.02.03.04.01')) == true) {
      this.router.navigate(['PenAir/Projects/ProjectDetailsEdit', 0]);
    }
    else {
      this.ShowPermissionMessageForm('01.11.32.02.03.04.01');//339

    }

  }
  public confirmRemove(shouldRemove: boolean): void {
    this.removeProductConfirmationSubject.next(shouldRemove);

    if (shouldRemove) {
      let res = this.productToRemove;
      this.subscription.add(this.Projectmasterservice.DeleteProjectDetails(res.AutoNo).subscribe(res => {
        this.loaddata();
        this.subscription.add(this.translateapi.get(['Messages.deleted_success']).subscribe((translations) => {
          this.showMsg = translations['Messages.deleted_success'];
          this.toastr.success(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          });
        }));
      }, (err) => {
        console.log(err);
        this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {
          this.showMsg = translations['Messages.Failed'];
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          });
        }));

      }));
    }
    this.productToRemove = null;

  }
  public async removeHandler({ dataItem }) {
    if (Boolean(await this.common.checkPermission1('01.11.32.02.03.04.02')) == true) {
      this.productToRemove = dataItem;
      return this.removeProductConfirmationSubject;
    }
    else {
      this.productToRemove = null;
      this.ShowPermissionMessageForm('01.11.32.02.03.04.02');//339
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.11.32.02.03.04.02');
    }


  }


  onCancel() {
    this.router.navigate(['PenAir/Home']);
  }
  public onFilterClick()//Caseid 12643
  {
    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.common.masterData=[];  //case id 13648
  }
  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339

  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }

  public reportFile: string = "ProjectMaster.trdp";//12617
  public reportHeader = "Project Master";
  public reportSubject: string = "Project Master";
  public UserId = 1;
  public ProjectId: any;
  public ProjectName: any;
  public Sponsor: any;
  public Client: any;
  public StartDate: any;
  public EndDate: any;
  public filterChange(colName: any)//12617
  {
    debugger;
    this.ProjectId = "";
    this.ProjectName = "";
    this.Sponsor = "";
    this.Client = "";
    this.StartDate = "";
    this.EndDate = "";

    if (colName.filters[0] != undefined) {

      if (colName.filters[0].field == "PID")
        this.ProjectId = colName.filters[0].value;
      if (colName.filters[0].field == "PName")
        this.ProjectName = colName.filters[0].value;
      if (colName.filters[0].field == "Sponsor")
        this.Sponsor = colName.filters[0].value;
      if (colName.filters[0].field == "Client")
        this.Client = colName.filters[0].value;
      if (colName.filters[0].field == "SDate")
        this.StartDate = colName.filters[0].value;
      if (colName.filters[0].field == "EDate")
        this.EndDate = colName.filters[0].value;

    }
    if (colName.filters[1] != undefined) {

      if (colName.filters[1].field == "PID")
        this.ProjectId = colName.filters[1].value;
      if (colName.filters[1].field == "PName")
        this.ProjectName = colName.filters[1].value;
      if (colName.filters[1].field == "Sponsor")
        this.Sponsor = colName.filters[1].value;
      if (colName.filters[1].field == "Client")
        this.Client = colName.filters[1].value;
      if (colName.filters[1].field == "SDate")
        this.StartDate = colName.filters[1].value;
      if (colName.filters[1].field == "EDate")
        this.EndDate = colName.filters[1].value;
    }
    if (colName.filters[2] != undefined) {

      if (colName.filters[2].field == "PID")
        this.ProjectId = colName.filters[2].value;
      if (colName.filters[2].field == "PName")
        this.ProjectName = colName.filters[2].value;
      if (colName.filters[2].field == "Sponsor")
        this.Sponsor = colName.filters[2].value;
      if (colName.filters[2].field == "Client")
        this.Client = colName.filters[2].value;
      if (colName.filters[2].field == "SDate")
        this.StartDate = colName.filters[2].value;
      if (colName.filters[2].field == "EDate")
        this.EndDate = colName.filters[2].value;
    }
    if (colName.filters[3] != undefined) {

      if (colName.filters[3].field == "PID")
        this.ProjectId = colName.filters[3].value;
      if (colName.filters[3].field == "PName")
        this.ProjectName = colName.filters[3].value;
      if (colName.filters[3].field == "Sponsor")
        this.Sponsor = colName.filters[3].value;
      if (colName.filters[3].field == "Client")
        this.Client = colName.filters[3].value;
      if (colName.filters[3].field == "SDate")
        this.StartDate = colName.filters[3].value;
      if (colName.filters[3].field == "EDate")
        this.EndDate = colName.filters[3].value;
    }
    if (colName.filters[4] != undefined) {

      if (colName.filters[4].field == "PID")
        this.ProjectId = colName.filters[4].value;
      if (colName.filters[4].field == "PName")
        this.ProjectName = colName.filters[4].value;
      if (colName.filters[4].field == "Sponsor")
        this.Sponsor = colName.filters[4].value;
      if (colName.filters[4].field == "Client")
        this.Client = colName.filters[4].value;
      if (colName.filters[4].field == "SDate")
        this.StartDate = colName.filters[4].value;
      if (colName.filters[4].field == "EDate")
        this.EndDate = colName.filters[4].value;
    }
    if (colName.filters[5] != undefined) {

      if (colName.filters[5].field == "PID")
        this.ProjectId = colName.filters[5].value;
      if (colName.filters[5].field == "PName")
        this.ProjectName = colName.filters[5].value;
      if (colName.filters[5].field == "Sponsor")
        this.Sponsor = colName.filters[5].value;
      if (colName.filters[5].field == "Client")
        this.Client = colName.filters[5].value;
      if (colName.filters[5].field == "SDate")
        this.StartDate = colName.filters[5].value;
      if (colName.filters[5].field == "EDate")
        this.EndDate = colName.filters[5].value;
    }

  }
  public OpenProjectMasterReport()//12617
  {
    debugger;
    this.reportFile = "ProjectMaster.trdp";
    this.StartDate = (this.StartDate == null || this.StartDate == "" ? "01-Jan-1900" : this.StartDate);
    this.EndDate = (this.EndDate == null || this.EndDate == "" ? "01-Jan-1900" : this.EndDate);
    if (this.StartDate != null || this.StartDate != undefined || this.StartDate != "" || this.EndDate != null || this.EndDate != undefined || this.EndDate != "") {
      this.StartDate = this.common.ConvertToLocaleDate(new Date(this.StartDate));
      this.EndDate = this.common.ConvertToLocaleDate(new Date(this.EndDate));
    }

    this.reportParms = ({
      ProjectId: this.ProjectId,
      ProjectName: this.ProjectName,
      Sponsor: this.Sponsor,
      Client: this.Client,
      StartDate: this.StartDate,
      EndDate: this.EndDate,
      UserId: this.UserId,
      reportHeader: this.reportHeader
    }

    );

    this.openReport();
  }
  public onEnd() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const positionTop = windowHeight - this.height - this.offset;
    const positionLeft = windowWidth - this.offset;
    if (this.top < this.windowTop) {
      this.top = this.windowTop;
    }
    if (this.top > positionTop) {
      this.top = this.windowTop;
    }
    if (this.left < (this.windowLeft - 200)) {
      this.left = this.windowLeft;
    }
  }
  public openReport() {
    window.scrollTo(0, 0);
    this.openProjectMasterReport = true;
  }
  public closeProjectMasterReport() {
    this.openProjectMasterReport = false;
  }
}
