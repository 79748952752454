import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { IntlService } from '@progress/kendo-angular-intl';
import { CommonserviceService } from 'src/app/Services/commonservice.service';
import { ShareDataService } from 'src/app/Services/share-data.service';
const sampleCustomers:any = [
  {
    Steps: "A",
    Formula: "",
    Description: "Buy",
    Amount: 0,
    City: "Berlin",
  },
  {
    Steps: "B",
    Formula: "",
    Description: "PDQ Charge",
    Amount: 0,
    City: "México D.F.",
  },
  // {
  //   Steps: "C",
  //   Formula: "",
  //   Description: "TOMS VAT",
  //   Amount: 0,
  //   City: "México D.F.",
  // },
  {
    Steps: "C",
    Formula: "A+B",
    Description: "Total Expense",
    Amount: 0,
    City: "London",
  },
  {
    Steps: "D",
    Formula: "",
    Description: "Sales",
    Amount: 0,
    City: "Luleå",
  },
  {
    Steps: "E",
    Formula: "",
    Description: "Credit Card Charge",
    Amount: 0,
    City: "Mannheim",
  },
  {
    Steps: "F",
    Formula: "D+E",
    Description: "Total Sales",
    Amount: 0,
    City: "Strasbourg",
  },
  {
    Steps: "G",
    Formula: "",
    Description: "Agent Commission",
    Amount: 0,
    City: "Madrid",
  },
  {
    Steps: "H",
    Formula: "F-C-G",
    Description: "Net Profit",
    Amount: 0,
    City: "Marseille",
  }]
@Component({
  selector: 'app-commission-calculation',
  templateUrl: './commission-calculation.component.html',
  styleUrls: ['./commission-calculation.component.scss']
})
export class CommissionCalculationComponent implements OnInit,OnChanges {
  public gridData: any[] = sampleCustomers;
  @Input() folderSummary:any;
  @Input() commType:any;
  @Output() closeCommCalc: EventEmitter<string> = new EventEmitter();
  public fcCurrency='';
  homeCurrency:any;
  sell:number;
  folderexrate:any;
  TotCOMM:number=0.00;
  TotBUY :number=0.00;
  HcTotCOMM:number=0.00;
  HcTotBUY :number=0.00;
  HcSell :number=0.00;
  fcTotVat :number=0.00;
  totalReceivable:number; 
  HCtotalReceivable:number=0.00;
  balance:number;
  HCbalance:number=0.00;
  AgntTotCOMM:number=0.00;
  HCAgntTotCOMM:number=0.00;
  AGTCOM:number=0.00;
  FCAGTCOM:number=0.00;
  FC_CIDExRate:number;
  isShown=false;
  commDetail={
    type:'',
    currency:''
  };
  showCalc=false;
  showHC=false;
  showFC=false;
  constructor(public intl: IntlService,public common: CommonserviceService,private apiShared: ShareDataService) { }

  ngOnInit() {
    if(this.commType=='both'){
      this.showHC=true;
      this.showFC=true;
    }else if(this.commType=='FConly'){
      this.showFC=true;
    }else if(this.commType=='HConly'){
      this.showHC=true;
    }
    this.TotalCalculation();
  }
  TotalCalculation(){

    this.homeCurrency=this.apiShared.homeCurrency;
    this.folderexrate=this.folderSummary.FC_CIDExRate;
    this.sell=this.folderSummary.FCTotalBUY+this.folderSummary.FCTotalCOMM;
    this.fcTotVat=this.folderSummary.TotVAT/this.folderexrate;
    this.totalReceivable=(this.folderSummary.FCTotRECEIVABLE+this.fcTotVat+this.folderSummary.FCCREDITAMT)-(this.folderSummary.AGTCOMSUM+this.folderSummary.AGTCOMVAT);
    this.HCtotalReceivable=this.totalReceivable*this.folderexrate;
    this.balance=this.totalReceivable-this.folderSummary.FCALREADYPAID-this.folderSummary.FCOtherCredits;
    this.common.balance=this.balance;
    this.HCbalance=this.balance*this.folderexrate;
    this.TotBUY =this.folderSummary.FCTotalBUY;
    this.TotCOMM=this.folderSummary.FCTotalCOMM;
    if(this.folderSummary.CREDITAMT===0){
      this.HcTotBUY=this.folderSummary.TotalBUY+this.folderSummary.PDQCHARGE;
      this.HcTotCOMM=this.folderSummary.TotalCOMM-this.folderSummary.PDQCHARGE;
      this.AgntTotCOMM=this.folderSummary.FCTotalCOMM - ((this.folderSummary.PDQCHARGE/this.folderSummary.FC_CIDExRate)+this.folderSummary.FCAGTCOMSUM)	
      this.HCAgntTotCOMM=this.folderSummary.TotalCOMM - (this.folderSummary.PDQCHARGE+this.folderSummary.AGTCOMSUM);
    } else{
      this.HcTotBUY=this.folderSummary.TotalBUY;
      this.HcTotCOMM=this.folderSummary.TotalCOMM;
      this.AgntTotCOMM=this.folderSummary.FCTotalCOMM - this.folderSummary.FCAGTCOMSUM;
      this.HCAgntTotCOMM=this.folderSummary.TotalCOMM - this.folderSummary.AGTCOMSUM;
    }
    this.HcSell=this.folderSummary.TotalBUY+this.folderSummary.TotalCOMM;
    this.AGTCOM=this.folderSummary.AGTCOMSUM+this.folderSummary.AGTCOMVAT
    this.FCAGTCOM=this.folderSummary.FCAGTCOMSUM+this.folderSummary.FCAGTCOMVAT
    
  }
public rowCallback(context: RowClassArgs) {
  const isActive = context.dataItem.Steps==='C'||context.dataItem.Steps==='F'||context.dataItem.Steps==='H';
    return {
      active: isActive
    };
}
public showCalculation(type){
this.commDetail.type=type;
if(type=='HC'){
  this.commDetail.currency=String(this.apiShared.homeCurrency);
}else if(type=='FC'){
  this.commDetail.currency=String(this.apiShared.folderCurrency);
}
this.showCalc=true;
}
public closeCalc(){
  this.closeCommCalc.emit('true');
}
ngOnChanges(changes: SimpleChanges) {
  if(changes["folderSummary"].currentValue!==undefined)
  {
    this.TotalCalculation();
  }
  // console.log(this.dummymenu);
}
}
