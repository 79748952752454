import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvService } from 'src/env.service';
import { CommonserviceService } from './commonservice.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class GdsTrayService {
  apiUrl :string;
  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) { 
    this.apiUrl=env.baseUrl;
  }

  //caseid 12657 
  getGDSTray(): Observable<any> {
    const url = `${this.apiUrl}`+'GetGDSTrayList';
    return this.http.get<any>(url).pipe(
      catchError(this.commonservice.handleError<any>(`GetGDSTrayList`))
    );
  }
  //caseid 12657 
  uploadGDSTray(gdsUpload: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'GDSTrayUpload', gdsUpload, httpOptions).pipe();
  }
  //caseid 12657 
  resetGDSTray(): Observable<any> {
    const url = `${this.apiUrl}`+'GDSTrayReset';
    return this.http.get<any>(url).pipe(
      catchError(this.commonservice.handleError<any>(`resetGDSTray`))
    );
  }
  //caseid 12657 
  deleteGDSTray(gdsDelete: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'GDSTrayDelete', gdsDelete, httpOptions).pipe();
  }

}
