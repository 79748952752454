import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EnvService } from "src/env.service";
import { Observable } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { CommonserviceService } from "./commonservice.service";
import {
  PettyCashPaymentMasterDTO,
  PettyCashPaymentMasterGridDTO,
  PettyCashPaymentMasterListDTO
} from "../Models/petty-cash-payment-master-dto.model";
import {
  CashPaymentBalceDTO,
  CashPaymentDTO,
  CashPaymentMasterGridDTO
} from "../Models/cash-payment-dto.model";
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable({
  providedIn: "root"
})
export class CashPaymentService {
  apiUrl: string;
  public formData: CashPaymentDTO;
  public formDataget: CashPaymentBalceDTO;
  public PettyCashPaymentMaster: CashPaymentMasterGridDTO[];
  constructor(
    private http: HttpClient,
    private env: EnvService,
    public commonservice: CommonserviceService
  ) {
    this.apiUrl = env.baseUrl;
  }

  public GetCashpayemntOpeningBalance(): Observable<CashPaymentBalceDTO> {
    var body = {
      ...this.formDataget
    };
    return this.http
      .post<CashPaymentBalceDTO>(
        this.apiUrl + "GetCashpayemntOpeningBalance",
        body,
        httpOptions
      )
      .pipe(
        tap((railRes: any) => console.log(`PostCashPaymentDetails  w/ id=`)),
        catchError(
          this.commonservice.handleError<any>("PostCashPaymentDetails")
        )
      );
  }
  public GetCurrencyExchangeRateTolerance(): Observable<any> {
    var body = {
      ...this.formData,
      
    };
    return this.http
      .post<any>(
        this.apiUrl + "GetCurrencyExchangeRateTolerance",
        body,
        httpOptions
      )
      .pipe(
        tap((railRes: any) => console.log(`added Rail w/ id=`)),
        catchError(
          this.commonservice.handleError<any>("PostCashReceiptDetails")
        )
      );
  }
  public PostCashPaymentDetails(): Observable<CashPaymentDTO> {
    var body = {
      ...this.formData,
      gridmodelList: this.PettyCashPaymentMaster
    };
    return this.http
      .post<CashPaymentDTO>(
        this.apiUrl + "PostCashPaymentDetails",
        body,
        httpOptions
      )
      .pipe(
        tap((railRes: any) => console.log(`added Rail w/ id=`)),
        catchError(
          this.commonservice.handleError<any>("PostCashPaymentDetails")
        )
      );
  }
}
