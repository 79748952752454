import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription } from 'rxjs';
import { InvoiceComponent } from '../invoice/invoice.component';
@Component({
  selector: 'app-agent-commission',
  templateUrl: './agent-commission.component.html',
  styleUrls: ['./agent-commission.component.scss']
})

export class AgentCommissionComponent implements OnInit {
  @Input() AgentCommission;
  @Input() Invoice;
  @Output() FormClosed = new EventEmitter<string>();
  private subscription: Subscription = new Subscription();
  public AGTaxselectedItem: LookUpDetails["ResponseData"];
  Tax: Array<LookUpDetails["ResponseData"]> = [];
  public TaxselectedItem: LookUpDetails["ResponseData"];
  DisableFields=false;
  constructor(public lookupApi:LookUpDetailsService,public commonservice:CommonserviceService) { }

  ngOnInit() {
debugger;
    this.subscription.add(this.lookupApi.GetAllLookup('TAX').subscribe(
      (data) => {
        if(data["ResponseData"]!=null)
        {
         this.Tax= data["ResponseData"];  
         
         if(this.AgentCommission.AGTCOMVATNO!==null) 
         {
          this.TaxselectedItem =this.Tax.find(item => item.Code == this.AgentCommission.AGTCOMVATNO);
       
         }
        }
      }
      ));
     
      if(this.Invoice==true) 
      {
       
       this.DisableFields=true;
      } 
     
  }
  public close() {
    this.FormClosed.emit("close");
  }

  

}
