import { Component, OnInit, ChangeDetectorRef, Optional } from "@angular/core";
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { ShareDataService } from "../../Services/share-data.service";
import { FlashMessagesService } from "angular2-flash-messages";
import { TranslateService } from "@ngx-translate/core";
import { CommonserviceService } from "../../Services/commonservice.service";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { EmailService } from "../../Services/email.service";
import { FileService } from "../../Services/file.service";
import { FolderSummaryComponent } from "../../folder/folder-summary/folder-summary.component";
import { FolderReinvoice } from '../../Models/folder-reinvoice';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceService } from '../../Services/invoice.service';
import { PublicLayoutComponent } from "src/app/public-layout/public-layout.component";
import { PendoclockService } from '../../Services/pendoclock.service';
import { Pendoclock } from '../../Models/pendoclock';
import { FolderRefundreset } from '../../Models/folder-refundreset';
@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"]
})
export class DetailsComponent implements OnInit {
  public showMsg: any;
  submenuIndex: number = 0;
  private subscription: Subscription = new Subscription();
  public orderNo: any;
  Projno: any;
  private folderEditMode: any;
  private Productsaved: any = false;
  public folderStatus: any;
  public reportParms: any;
  public reportFile: string = "";

  public windowTop: number = -100;
  public windowLeft: number = -225;
  public offset = 10;
  public left = this.windowLeft;
  public top = this.windowTop;
  public width = window.innerWidth - 40;
  public height = 770;

  public disablelink = true;
  public disablecreditnote = true;
  public disableinvoice = false;
  public disableRefund = true;
  public disablelinkReinvoice = true;
  public InvoiceLabel = "Create Invoice";
  DeleteLabel = "Delete invoice";
  fromId: string;
  bccId: string;
  reportSubject: any = "Folder Master";
  public permissionid:any;
  public reinvoice: FolderReinvoice;
  public deleteinvoice: FolderReinvoice;
  public refundReset:FolderRefundreset;
  folderReadOnly: any;
  public confirmMsg: any;
  public ReceiptPayment:number=0;
  
  constructor(
    public emailapi: FileService,
    public common: CommonserviceService,
    private router: Router,
    private route: ActivatedRoute,
    public apiShared: ShareDataService,
    private translateapi: TranslateService,
    private flashMessage: FlashMessagesService,
    @Optional() private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private folder: FolderSummaryComponent,
    private spinner: NgxSpinnerService,
    private invoice: InvoiceService,
    private layout: PublicLayoutComponent,
    private apipenLock: PendoclockService,
  ) {}
  penDockLock: Pendoclock = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {
      LDocType: "",
      LDocNo: "",
      UID: 0,
      LDATE: "",
      LTIME: "",
      LPROJNO: "",
      LYearID: "",
      LAutoNo: "",
      Uname: "",
      Source: ""
    }
  };
  ngOnInit() {
    
    this.orderNo = this.apiShared.orderNo;
   
    if(this.orderNo=="")
    {
      this.orderNo=0;
      this.apiShared.Setapishareddata(0, 'orderNo');
    }
    this.Projno = this.apiShared.projNo;
    if(this.Projno=="")
    {
      this.Projno=0;
      this.apiShared.Setapishareddata(0, 'projNo');
    }
    
    this.Projno = this.apiShared.projNo;
    this.submenuIndex = +sessionStorage.getItem("submenuIndex");
    this.reportSubject =
      this.reportSubject + ":" + this.Projno + " Order:" + this.orderNo;
    this.subscription.add(
      this.emailapi.GetReportEmail(+this.apiShared.uid).subscribe(data => {
        if (data != null && data != undefined) {
          if (data.ResponseData != null && data.ResponseData != undefined) {
            (this.fromId = data.ResponseData.FROMEMAIL),
              (this.bccId = data.ResponseData.DEFAULTBCCMAIL);
          }
        }
      })
    );
    if(this.orderNo>0)
    {
      this.ReceiptPayment=1;
    }
    // if(this.orderNo!="0")
    // this.router.navigate(['/PenAir/Folder/FolderSummary',this.orderNo]);
  }
  async MenuClick(value: number, refreshed: boolean) {
    debugger;
    if (this.common.folderEditMode == "true") {
      this.apiShared.Setapishareddata(
        this.common.folderEditMode,
        "folderEditMode"
      );
    }
    this.folderEditMode = this.apiShared.folderEditMode;
    this.Productsaved = this.common.isfinalsaved;
    this.folderStatus = this.apiShared.FolderStatus;
    this.orderNo = this.apiShared.orderNo;
    if(this.orderNo>0)
    {
      this.ReceiptPayment=1;
     // this.apiShared.ReceiptPayment= 1;
    }
    this.disableinvoice = true;
    this.disablelink = true;
    this.disablecreditnote = true;
    this.disableRefund = true;
    this.disablelinkReinvoice = true;
   
    if (this.folderStatus == "Saved") {
      this.permissionid='01.12.01.09'
      this.subscription.add(
        this.translateapi
          .get(["Labels.create_invoice"])
          .subscribe(translations => {
            this.InvoiceLabel = translations["Labels.create_invoice"];
          })
      );
    
      this.disableinvoice = false;
    } else if (this.folderStatus == "Invoiced") {
      this.permissionid='01.12.01.09.03'
      this.InvoiceLabel = "Print Invoice";

      this.subscription.add(
        this.translateapi.get(["Labels.PrintEmail"]).subscribe(translations => {
          this.InvoiceLabel = translations["Labels.PrintEmail"];
        })
      );
      this.disablelink = false;
      this.disableinvoice = false;
      this.subscription.add(
        this.translateapi
          .get(["Labels.delete_invoice"])
          .subscribe(translations => {
            this.DeleteLabel = translations["Labels.delete_invoice"];
          })
      );
      //  this.DeleteLabel="delete_invoice";
      this.disablelinkReinvoice = false;
      // this.disablecreditnote=false;
      // this.disablecreditnote=true;
      // this.disableRefund=true;
      // this.disablelinkReinvoice=true;
    } else if (this.folderStatus == "Refund Request") {
      this.disablecreditnote = false;
      // if(this.orderNo>0 &&this.folder.CreditNoteAll==1)
      // {
      
      //   this.ReceiptPayment=1;
      //   this.apiShared.ReceiptPayment= 1;
      // }
     
      
      // this.disablecreditnote=true;
      // this.disableRefund=true;
      // this.disablelink=true;
      this.disablelinkReinvoice = true;
    } else if (
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      this.InvoiceLabel = "Print Credit note";
      // this.DeleteLabel="Delete credit note";
      //   this.disablelink=false;
      this.disableinvoice = false;
      this.disableRefund = false;

      // this.disablecreditnote=false;
      // this.disablecreditnote=true;
      // this.disableRefund=true;
      // this.disablelink=true;
      // this.disablelinkReinvoice=true;
    } else if (this.folderStatus == "Cancelled") {
      this.disableinvoice = true;
      this.disablelink = true;
      this.disablecreditnote = true;
      this.disableRefund = true;
      this.disablelinkReinvoice = true;
    } 
    // else {
    //   this.subscription.add(
    //     this.translateapi
    //       .get(["Labels.create_invoice"])
    //       .subscribe(translations => {
    //         this.InvoiceLabel = translations["Labels.create_invoice"];
    //       })
    //   );
    //   this.disablelink = true;
    // }
    if (this.folderStatus == "Refund Request") {
      this.disablecreditnote = false;
      // if(this.orderNo>0 &&this.folder.CreditNoteAll==1)
      // {
      //        this.ReceiptPayment=1;
      //        this.apiShared.setCredinotAll(this.ReceiptPayment.toString());
      // }
      // this.disablelink=true;
      // this.disableinvoice=true;
      // this.disablelinkReinvoice=true;
    }
    if (this.folderStatus == "Refund") {
      this.disableRefund = false;
      // this.disablelinkReinvoice=true;
     
    }
    this.closeMenu();

    if (this.folderEditMode == "false" && this.Productsaved == false) {
      if (value == 0) {
        sessionStorage.setItem("pagevalue", value.toString());
        if (!refreshed) {
          this.router.navigate([
            "/PenAir/Folder/FolderSummary/",
            this.orderNo
          ]);
        }
        this.setvalue(value);
      } else if (value == 1) {
       
        sessionStorage.setItem("pagevalue", value.toString());
        if (!refreshed)
          this.router.navigate([
            "/PenAir/Folder/PassengerDetails/",
            this.orderNo
          ]);
        this.setvalue(value);
      } else if (value == 2) {
       
        sessionStorage.setItem("pagevalue", value.toString());
        if (!refreshed)
          this.router.navigate([
            "/PenAir/Folder/Products/",
            this.orderNo
          ]);
        this.setvalue(value);
    } else  if(value==3) {
     
      sessionStorage.setItem('pagevalue', value.toString())
      if(!refreshed)
      if(Boolean(await this.common.checkPermission1('01.12.01.07'))==true )
       {
        this.router.navigate(['/PenAir/Folder/CustomerReceipts/',this.orderNo]);
        this.setvalue(value);
       }
       else
       {
       
        this.ShowPermissionMessageForm('01.12.01.07');
        
      }
    } else  if(value==4) {
     
      this.common.pagevalue=value;
      sessionStorage.setItem('pagevalue', value.toString())
      if(!refreshed)
      if(Boolean(await this.common.checkPermission1('01.12.01.08'))==true )
       {
        this.router.navigate(['/PenAir/Folder/Payment/',this.orderNo]);
        this.setvalue(value);
       }
       else
       {
       
        this.ShowPermissionMessageForm('01.12.01.08');
        
      }
    } else  if(value==5) {
     
      sessionStorage.setItem('pagevalue', value.toString())
      if(!refreshed)
        this.router.navigate(['/PenAir/Folder/FolderHistory/',this.orderNo]);
        this.setvalue(value);
      } else if (value == 6) {
        
       
        if(Boolean(await this.common.checkPermission1(this.permissionid))==true )
        {
        sessionStorage.setItem("pagevalue", value.toString());
        if (!this.disableinvoice) {
          if (!refreshed)
            this.router.navigate([
              "/PenAir/Folder/Invoice/",
              this.orderNo
            ]);
          this.setvalue(value);
        }
      }
      else
      {
        this.ShowPermissionMessageForm(this.permissionid);
      }
      } else if (value == 7) {
       
        sessionStorage.setItem("pagevalue", value.toString());
        if (!this.disablecreditnote) {
          if (!refreshed)
            this.router.navigate([
              "/PenAir/Folder/IssueCreditNote/",
              this.orderNo
            ]);
          this.setvalue(value);
        }
      } else if (value == 8) {
         
        if(Boolean(await this.common.checkPermission1('01.12.01.06.03'))==true )
        {
      
        if (!this.disablelink) {
          sessionStorage.setItem("pagevalue", value.toString());
          if (!refreshed)
          {
            this.onDeleteinvoice();
          }
            // this.router.navigate([
            //   "/PenAir/Folder/DeleteInvoice/",
            //   this.orderNo
            // ]);
          this.setvalue(value);
        }
      }
      else
      {
        this.ShowPermissionMessageForm('01.12.01.06.03');
      }
      } else if (value == 9) {
        if(Boolean(await this.common.checkPermission1('01.12.01.06.04'))==true )
        {
       
        if (!this.disablelink) {
          sessionStorage.setItem("pagevalue", value.toString());
          if (!refreshed)
          {
          this.onReinvoice();
          }
            // this.router.navigate([
            //   "/PenAir/Folder/Reinvoice/",
            //   this.orderNo
            // ]);
          this.setvalue(value);
        }
      }
      else{
        this.ShowPermissionMessageForm('01.12.01.06.04');
      }
      } else if (value == 10) {
        if(Boolean(await this.common.checkPermission1('01.12.01.06.05'))==true )
        {
            
        if (!this.disableRefund) {
//13157
          // sessionStorage.setItem("pagevalue", value.toString());
          // if (!refreshed)
          //   this.router.navigate([
          //     "/PenAir/Folder/RefundReset/",
          //     this.orderNo
          //   ],{skipLocationChange:true});
          // this.setvalue(value);
          this.onRefundReset();
//13157
        }
      }
      else{
        this.ShowPermissionMessageForm('01.12.01.06.05');
      }
      } else if (value == 11) {
        sessionStorage.setItem("pagevalue", value.toString());
        if (!refreshed)
          this.router.navigate([
            "/PenAir/Folder/FolderNotes/",
            this.orderNo
          ]);
        this.setvalue(value);
      } else if (value == 12) {
        sessionStorage.setItem("pagevalue", value.toString());
        if (!refreshed)
          this.router.navigate([
            "/PenAir/Folder/PNRSourceFileDetails/",
            this.orderNo
          ]);
        this.setvalue(value);
      } else if (value == 13) {
        value = 0;
        window.scrollTo(0, 0);
        sessionStorage.setItem("pagevalue", value.toString());
        // this.router.navigate(['/PenAir/Folder/TransactionSummary/',this.orderNo]);
        this.reportParms = {
          FolderNo: this.orderNo,
          projNo: this.Projno,
          rptType: "Folder",
          UserId: this.apiShared.uid
        };

        this.reportFile = "FolderTransactionSummaryReport.trdp";

        if (this.reportFile != "") this.open();
      }
    } else if (value === 2 && this.Productsaved == true) {
      sessionStorage.setItem("pagevalue", value.toString());
      if (!refreshed)
        this.router.navigate(["/PenAir/Folder/Products/", this.orderNo]);
      this.setvalue(value);
    } else if (value === 0 && this.Productsaved == true) {
      sessionStorage.setItem("pagevalue", value.toString());
      if (!refreshed)
        this.router.navigate([
          "/PenAir/Folder/FolderSummary/",
          this.orderNo
        ]);
      this.setvalue(value);
    } else {
      // //this.common.showFlash1('Messages.FolderOpen','bg-danger text-white',2000);
      // this.subscription.add(this.translateapi.get(['Messages.FolderOpen']).subscribe((translations ) => {
      //   this.showMsg= translations['Messages.FolderOpen'];
      //   this.common.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      // }));

      this.subscription.add(
        this.translateapi
          .get(["Messages.FolderOpen"])
          .subscribe(translations => {
            this.showMsg = translations["Messages.FolderOpen"];
            this.toastr.error(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            });
          })
      );
    }
    // if(this.orderNo>0 &&this.folderStatus == "Refund Request")
    // {
    //   this.apiShared.ReceiptPayment=this.folder.CreditNoteAll
    // }
    // else  if(this.orderNo>0 &&this.folderStatus != "Refund Request")
    // {
    //   this.ReceiptPayment=1;
    //   this.apiShared.ReceiptPayment= 1;
    // }
    // else
    // {
      
    //   this.apiShared.ReceiptPayment= 1;
    // }
    debugger;
  
   
  }
  public setvalue(value: number) {
    this.submenuIndex = value;
    this.common.submenuIndex = value;
    this.apiShared.Setapishareddata(value.toString(), "submenuIndex");
  }

  onActivate(componentReference) {
    //componentReference.anyFunction();
    //Below will subscribe to the searchItem emitter
    if (componentReference.menuIndex != undefined) {
      this.subscription.add(
        componentReference.menuIndex.subscribe((data: number) => {
          if (data == 0) {
            this.folderEditMode = "false";
            var menuindex = sessionStorage.getItem("pagevalue");

            if (menuindex != undefined) {
              this.MenuClick(+menuindex, this.common.browserRefresh);
            } else {
              this.MenuClick(0, this.common.browserRefresh);
            }

            this.cdref.detectChanges();
          }
          // Will receive the data from child here
        })
      );
    }
  }

  public opened = false;

  public open() {
    this.opened = true;
  }

  public close() {
    this.opened = false;
  }
  openMenu() {
    
    let toggleMenuButtonElements = document.getElementsByClassName(
      "__js_menu_button"
    );

    if (toggleMenuButtonElements.length === 0)
      console.log("__js_menu_button classes not found");

    for (let i = 0; i < toggleMenuButtonElements.length; i++) {
      let menuElement = document.getElementById("menu_element");

      if (menuElement === null) console.log("menu_element id not found");
      else menuElement.classList.toggle("open");
    }
  }
  openMoreClickMobile() {
    
    let toggleParentLiElements = document.getElementsByClassName("moreBtn");

    for (let i = 0; i < toggleParentLiElements.length; i++) {
      toggleParentLiElements[i].parentElement.classList.toggle("open");
    }
  }
  openAccountsMobile() {
    this.folderStatus = this.apiShared.FolderStatus;
    let toggleParentLiElements = document.getElementsByClassName(
      "accounts_mob"
    );
    for (let i = 0; i < toggleParentLiElements.length; i++) {
      toggleParentLiElements[i].parentElement.classList.toggle("open");
    }
  }
  async openAccountsclick() {
    this.folderStatus = this.apiShared.FolderStatus;
    
    if (Boolean(await this.common.checkPermission1("01.12.01.09")) == true) {
      if (this.folderStatus == "Saved") {
        this.permissionid='01.12.01.09'
        this.subscription.add(
          this.translateapi
            .get(["Labels.create_invoice"])
            .subscribe(translations => {
              this.InvoiceLabel = translations["Labels.create_invoice"];
            })
        );
      
        this.disableinvoice = false;
      } else if (this.folderStatus == "Invoiced") {
        this.permissionid='01.12.01.09.03'
        this.InvoiceLabel = "Print Invoice";
  
        this.subscription.add(
          this.translateapi.get(["Labels.PrintEmail"]).subscribe(translations => {
            this.InvoiceLabel = translations["Labels.PrintEmail"];
          })
        );
        this.disablelink = false;
        this.disableinvoice = false;
        this.subscription.add(
          this.translateapi
            .get(["Labels.delete_invoice"])
            .subscribe(translations => {
              this.DeleteLabel = translations["Labels.delete_invoice"];
            })
        );
        //  this.DeleteLabel="delete_invoice";
        this.disablelinkReinvoice = false;
       
      } else if (this.folderStatus == "Refund Request") {
        this.disablecreditnote = false;
       
        this.disablelinkReinvoice = true;
      } else if (
        this.folderStatus == "Refund" ||
        this.folderStatus == "Partial Refund"
      ) {
        this.InvoiceLabel = "Print Credit note";
        // this.DeleteLabel="Delete credit note";
        //   this.disablelink=false;
        this.disableinvoice = false;
        this.disableRefund = false;
  
       
      } else if (this.folderStatus == "Cancelled") {
        this.disableinvoice = true;
        this.disablelink = true;
        this.disablecreditnote = true;
        this.disableRefund = true;
        this.disablelinkReinvoice = true;
      } 
     
      if (this.folderStatus == "Refund Request") {
        this.disablecreditnote = false;
       
      }
      if (this.folderStatus == "Refund") {
        this.disableRefund = false;
        // this.disablelinkReinvoice=true;
       
      }
      this.closeMenu();
      let moreElement = document.getElementById("more_element");
      moreElement.classList.remove("open");

      let toggleParentLiElements = document.getElementsByClassName("accounts");
      for (let i = 0; i < toggleParentLiElements.length; i++) {
        toggleParentLiElements[i].parentElement.classList.toggle("open");
      }
    } else {
      this.ShowPermissionMessageForm("01.12.01.09"); //339
      //this.common.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.09');
    }
  }
  openMoreclick() {
    let accountElement = document.getElementById("account_element");
    accountElement.classList.remove("open");

    let toggleParentLiElements = document.getElementsByClassName("morelist");
    for (let i = 0; i < toggleParentLiElements.length; i++) {
      toggleParentLiElements[i].parentElement.classList.toggle("open");
    }
  }
  closeMenu() {
    let menuElement = document.getElementById("menu_element");
    menuElement.classList.remove("open");
    let accountElement = document.getElementById("account_element");
    accountElement.classList.remove("open");
    let accountMobElement = document.getElementById("account_mob_element");
    accountMobElement.classList.remove("open");
    let moreElement = document.getElementById("more_element");
    moreElement.classList.remove("open");
    let moreMobElement = document.getElementById("more_mob_element");
    moreMobElement.classList.remove("open");
  }

  ngOnDestroy() {
    
    this.subscription.unsubscribe();
  }

  public permissionmessageformopened: any; //Caseid-339
  public PermissionMessageId: any; //Caseid-339

  public PermissionFormClosing(
    status: any //339
  ) {
    if (status == "EmailSendSuccesfully") {
      //339
      this.permissionmessageformopened = false;
    }
  }

  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true; //Caseid-339
    this.PermissionMessageId = permissionid; //Caseid-339
  }
/*12873*/
  async onDeleteinvoice() {
    this.spinner.show();
    if (
      Boolean(await this.common.checkPermission1("01.12.01.06.03")) === true
    ) {
      this.deleteinvoice = new FolderReinvoice();
      //  this.invoiceDate=(this.folderInvoiceForm.controls.InvDate.value==null||this.folderInvoiceForm.controls.InvDate.value==""?"01-Jan-1900":this.folderInvoiceForm.controls.InvDate.value);//Case id-337
      this.deleteinvoice.pFolderNo = this.orderNo;
      this.deleteinvoice.language = this.apiShared.LanguageSelected;
      this.deleteinvoice.packaged = 0;

      this.deleteinvoice.invoicedate = this.common.ConvertToLocaleDate(
        "01/Jan/1900"
      );
      this.subscription.add(
        this.invoice.DeleteInvoice(this.deleteinvoice).subscribe(res => {
          debugger;
          if (res != null && res != undefined) {
            if (
              res.ResponseData.refundFolderNo != null &&
              res.ResponseData.refundFolderNo != 0
            ) {
              if (this.orderNo != 0 || this.orderNo != "")
                // if(this.lockStatus=="0") {
                this.deletePenLock("Folder", this.orderNo);

              // }
              this.getLockDetails(
                "Folder",
                res.ResponseData.refundFolderNo,
                this.apiShared.uid,
                this.apiShared.projNo
              );
              this.orderNo = res.ResponseData.refundFolderNo;
              // this.apiShared.orderNo=this.orderNo;
              this.apiShared.Setapishareddata(res.ResponseData.refundFolderNo, "orderNo");
              this.folderStatus = "Cancelled";
              this.layout.folderstatus = this.folderStatus;
              //this.apiShared.FolderStatus=this.folderStatus;
              this.apiShared.Setapishareddata(
                this.folderStatus,
                "FolderStatus"
              );
              this.router.navigate([
                "/PenAir/Folder/FolderSummary/",
                res.ResponseData.refundFolderNo
              ]);
              this.folder.GetFolderDetailsById( res.ResponseData.refundFolderNo);
           
              var invoiceno = sessionStorage.getItem("invoiceno");

              this.disablecreditnote = true;
              this.disablelink = true;
              this.disableinvoice = true;
              this.disablelinkReinvoice = true;
              if (res.ResponseData.message["messageid"] == 42)
                this.toastr.success(
                  res.ResponseData.message["messageid"] +
                    ":- " +
                    res.ResponseData.message["message"],
                  "",
                  {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  }
                );
              else
                this.toastr.warning(
                  res.ResponseData.message["messageid"] +
                    ":- " +
                    res.ResponseData.message["message"],
                  "",
                  {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  }
                );
              this.spinner.hide();
              // let currentUrl = this.router.url;
              // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              // this.router.onSameUrlNavigation = 'reload';
              // this.router.navigate([currentUrl]);
            } else {
              this.toastr.warning(
                res.ResponseData.message["messageid"] +
                  ":- " +
                  res.ResponseData.message["message"],
                "",
                {
                  timeOut: 5000,
                  disableTimeOut: false,
                  closeButton: true
                }
              );
              this.spinner.hide();
            }
          }
        })
      );
    } else {
      this.ShowPermissionMessageForm("01.12.01.06.03"); //339
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.06.03');
      this.spinner.hide();
    }
  }

  deletePenLock(docType, docno) {
    this.subscription.add(
      this.apipenLock.DeleteLock(docType, docno + "/").subscribe(
        res => {},
        err => {
          console.log(err);
        }
      )
    );
  }

  getLockDetails(docType, docNo, userId, projno) {
    this.subscription.add(
      this.apipenLock
        .getLock(docType, docNo, userId, projno)
        .subscribe(data => {
          if (data != null && data != undefined) {
            this.penDockLock = data;
            if (this.penDockLock.ResponseData != null) {
              if (this.penDockLock.ResponseData.Uname != "") {
                this.folderReadOnly = "1";
                // this.apiShared.folderReadOnly=this.folderReadOnly;
                this.apiShared.Setapishareddata(
                  this.folderReadOnly,
                  "folderReadOnly"
                );
                this.subscription.add(
                  this.translateapi
                    .get(["Labels.lock_msg"])
                    .subscribe(translations => {
                      this.confirmMsg = translations["Labels.lock_msg"].replace(
                        "<<1>>",
                        this.penDockLock.ResponseData.Uname
                      );
                    })
                );
              } else {
                this.folderReadOnly = "0";
                // this.apiShared.folderReadOnly=this.folderReadOnly;
                this.apiShared.Setapishareddata(
                  this.folderReadOnly,
                  "folderReadOnly"
                );
              }
            } else {
              this.folderReadOnly = "0";
              //this.apiShared.folderReadOnly=this.folderReadOnly;
              this.apiShared.Setapishareddata(
                this.folderReadOnly,
                "folderReadOnly"
              );
            }
          }
        })
    );
  }
async onRefundReset()
  {
    this.spinner.show();
    if(Boolean(await this.common.checkPermission1('01.12.01.06.05'))==true) {
      this.refundReset=new FolderRefundreset();
      this.refundReset.pFolderNo=this.orderNo;
      this.refundReset.language=this.apiShared.LanguageSelected;
      this.refundReset.packaged=0;
      this.refundReset.userId=this.apiShared.uid;
      this.subscription.add(this.invoice.RefundReset(this.refundReset).subscribe(res=>{
        if(res!=null&& res!=undefined){
        if(res.ResponseData.message.messageid===422) {
          this.toastr.success(res.ResponseData.message["messageid"]+':- '+res.ResponseData.message["message"],"",{
          timeOut:5000,
          disableTimeOut:false,
          closeButton:true
          });
          this.deletePenLock("Folder", this.orderNo);
          this.disablecreditnote=true;
          this.disablelink=true;
          this.disableinvoice=true;
          // this.refundreset=true;
          // var invoiceno= sessionStorage.getItem('invoiceno');
        
          this.spinner.hide();
          this.orderNo="";     
          
          this.apiShared.Setapishareddata(this.orderNo,'orderNo');
          this.router.navigateByUrl(localStorage.getItem('alerturl'),{skipLocationChange:true});
          localStorage.removeItem('alerturl');
          this.common.folderEditMode='false';
          this.common.folderEditMode=this.common.folderEditMode;
          this.apiShared.Setapishareddata(this.common.folderEditMode,'folderEditMode');
  
  
        } else {
          this.toastr.error(res.ResponseData.message["messageid"]+':- '+res.ResponseData.message["message"],"",{
            timeOut:5000,
            disableTimeOut:false,
            closeButton:true
            });
            this.spinner.hide();
        }
      }
       }));
    } else {
      this.ShowPermissionMessageForm('01.12.01.06.05');//339
      this.spinner.hide();
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.06.05');
    }
  }

  async onReinvoice() {
    this.spinner.show();
    if (
      Boolean(await this.common.checkPermission1("01.12.01.06.04")) === true
    ) {
      this.reinvoice = new FolderReinvoice();
      this.reinvoice.pFolderNo = this.orderNo;
      this.reinvoice.language = this.apiShared.LanguageSelected;
      this.reinvoice.invoicedate = this.common.ConvertToLocaleDate(
        "01/Jan/1900"
      );
      this.reinvoice.packaged = 0;
      this.subscription.add(
        this.invoice.FolderReinvoice(this.reinvoice).subscribe(res => {
          if (res != null && res != undefined) {
            if (
              res.ResponseData.orderno != null &&
              res.ResponseData.orderno != 0
            ) {
              if (this.orderNo != 0 || this.orderNo != "")
                // if(this.lockStatus=="0") {
                sessionStorage.setItem(
                  "orderNo",
                  res.ResponseData.orderno.toString()
                );
              this.deletePenLock("Folder", this.orderNo);
              // }
              this.getLockDetails(
                "Folder",
                res.ResponseData.orderno,
                this.apiShared.uid,
                this.apiShared.projNo
              );

              this.orderNo = res.ResponseData.orderno;

              //this.apiShared.orderNo=this.orderNo;
              this.apiShared.Setapishareddata(
                res.ResponseData.orderno,
                "orderNo"
              );
              this.spinner.show();
              //this.folder.GetFolderDetailsById(res.ResponseData.orderno);
              
              //sessionStorage.setItem("ReinvoiceFlag", "1");

              // this.router.navigate([
              //   "/PenAir/Folder/FolderHistory/",
              //   res.ResponseData.orderno
              // ]);
              // this.router.navigate([
              //   "PenAir/Folder/FolderSummaryReinvoiced",
              //   res.ResponseData.orderno
              // ]);
              this.router.navigate([
                "/PenAir/Folder/Reinvoice/",
                res.ResponseData.orderno
              ]);
              this.setvalue(0);
              // this.folder.GetFolderDetailsById(res.ResponseData.orderno);
              

              this.spinner.hide();
              this.toastr.success(
                res.ResponseData.message["messageid"] +
                  ":- " +
                  res.ResponseData.message["message"].replace(
                    "FolderOrder2Cash -",
                    ""
                  ) +
                  "New order no is:" +
                  this.orderNo,
                "",
                {
                  timeOut: 5000,
                  disableTimeOut: false,
                  closeButton: true
                }
              );

              // this.router.navigate(['/PenAir/Folder/Invoice/',this.orderNo]);
              this.subscription.add(
                this.translateapi
                  .get(["Labels.create_invoice"])
                  .subscribe(translations => {
                    this.InvoiceLabel = translations["Labels.create_invoice"];
                  })
              );
debugger;
              this.folderStatus = "Saved";
              this.layout.folderstatus = this.folderStatus;
              this.layout.headerPassenger =
                sessionStorage.getItem("PassengerNames") +
                +'- Folder No:'   +" Order No:" +
                this.Projno+"("+res.ResponseData.orderno+")";
              this.spinner.hide();
              //this.apiShared.FolderStatus=this.folderStatus;
              this.apiShared.Setapishareddata(
                this.folderStatus,
                "FolderStatus"
              );
            } else {
              this.setvalue(0);
              this.toastr.warning(
                res.ResponseData.message["messageid"] +
                  ":- " +
                  res.ResponseData.message["message"].replace(
                    "FolderOrder2Cash -",
                    ""
                  ),
                "",
                {
                  timeOut: 5000,
                  disableTimeOut: false,
                  closeButton: true
                }
              );
              this.spinner.hide();
            }
          }
        })
      );
    } else {
      this.setvalue(0);
      this.ShowPermissionMessageForm("01.12.01.06.04"); //339
      this.spinner.hide();
      // this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.06.04');
    }
  }
  /*12873*/
}
