import { Component, OnInit, ViewChild,Input } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CommonserviceService } from '../../Services/commonservice.service';
import { PublicLayoutComponent } from 'src/app/public-layout/public-layout.component';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { FileService } from '../../Services/file.service';
import { ShareDataService } from '../../Services/share-data.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { NgxSpinnerService } from "ngx-spinner";
import { JsonPipe } from '@angular/common';
import { ElementFinder } from 'protractor';
import { FlightBookingDetailsService } from '../../Services/flight-booking-details.service';
import { FlightBookingDetailsReportComponent } from '../../flight-booking-details-report/flight-booking-details-report.component';
import { FlightBookingDetailsDTO } from "../../Models/flight-booking-details";
import {
  ExportPDFParam
} from "../../Models/common-class.model";

@Component({
  selector: 'app-rpt-flight-booking-details',
  templateUrl: './rpt-flight-booking-details.component.html',
  styleUrls: ['./rpt-flight-booking-details.component.scss']
})
export class RptFlightBookingDetailsComponent implements OnInit {
  @ViewChild('autocompletesupplier') public autocompleteSupplier: AutoCompleteComponent;
  @ViewChild('autocompleteTo') public autocompleteTo: AutoCompleteComponent;
  private subscription: Subscription = new Subscription();

  constructor(public translate: TranslateService, public common: CommonserviceService, private apiShared: ShareDataService, private spinner: NgxSpinnerService, public flightBookingdetailsCmp: FlightBookingDetailsReportComponent, private route: ActivatedRoute,
    private layout: PublicLayoutComponent, private lookupApi: LookUpDetailsService, private formBuilder: FormBuilder,
    private router: Router, private translateapi: TranslateService, private flashMessage: FlashMessagesService, public FlightBookingService: FlightBookingDetailsService,
    private emailapi: FileService, private apishare: ShareDataService) { }
  public ExportPDFParamList: ExportPDFParam[];
  AirlineList: Array<LookUpDetails["ResponseData"]> = [];
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];
  destinationToList: Array<LookUpDetails["ResponseData"]> = [];
  public DestinationSelectedItem: LookUpDetails["ResponseData"];
  public destinationToSelectedItem: LookUpDetails["ResponseData"];
  public SupplierSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelected: String;
  public FlightBookingDetailsDTO: FlightBookingDetailsDTO;
  public ModuleName: String = "Passenger Booking Details Report";
  public DestinationFrom: String = "";
  public Destination = "";
  public Airline = "";
  public VairlineAIRID = "";
  @Input() drop: boolean = false;

  public type: string = "";
  public TableandField: any;
  public MCQuery: any;
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  public mcodeMultiSelectPopUp: boolean = false;
  MCMultipleSelectedItems: any = '';
  public mcodeInclude: boolean = true;
  public mcodeExclude: boolean = false;
  public MCMultipleSelect: boolean = false;

  public SupplierName: String = "";
  public Supplier: any = 0;
  public parentForm: any;

  opened = false;
  openSearch = false;
  public DestinationMultiSelectType: any = 0;
  public AirlineMultiSelectType: any = 0;
  public SupplierMultiSelectType: any = 0;
  AirLineSelectedItem: any;

  DestinationMultipleSelectedItems: any = '';
  SupplierMultipleSelectedItems: any = '';
  AirlineMultipleSelectedItems: any = '';
  SupplierCodeMultSelectedItems: any = '';
  public destinationSelected: String;
  public showMsg: any; public TravelDateFrom = null;
  public TravelDateTo = null;
  public CreateDateFrom = null;
  public CreateDateTo = null;
  public PassengerBookingRequest: any;
  public gridData: any = [];
  PassQuery: any;
  SupplierQuery: any;
  AirlineQuery: any;
  userID: number;
  Query: string;
  public PDCreateDateFrom: any = "";
  public PDCreateDateTo: any = "";
  public PDTravelDateFrom: any = "";
  public PDTravelDateTo: any = "";
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };


  MsInput: any = '';

  SupplierMultipleSelected = {
    SelectedItems: '',
    isIncluded: true
  }
  DestinationMultipleSelected = {
    SelectedItems: '',
    isIncluded: true
  }
  AirlineMultipleSelected = {
    SelectedItems: '',
    isIncluded: true
  }
  ngOnInit() {
    this.userID = +this.apiShared.uid;
    this.FlightBookingService.formDataHeader.RptHdrSel_UserId = this.userID;
    this.FlightBookingService.formDataHeader.RptHdrSel_ModuleName =
      "PassengerBookingDetailsReport";
    debugger;
    this.flightBookingdetailsCmp.LoadTemplate();

    this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
      (data) => {
        debugger;
        this.AirlineList = data["ResponseData"]
        if (
          this.FlightBookingService.formData != undefined &&
          this.FlightBookingService.formData.Airline != null && this.FlightBookingService.formData.Airline != ""
        ) {
          debugger;
          this.AirLineSelectedItem = this.AirlineList.find(item => item.UsrCode == this.FlightBookingService.formData.Airline);
          this.AirLineChange(this.AirLineSelectedItem);
        }
      }
    ));

    this.subscription.add(this.lookupApi.GetAllLookup('SUPPLIERS').subscribe(
      (data) => {

        this.SupplierList = data["ResponseData"]

      }
    ));
    debugger;
    if (this.FlightBookingService.formData != undefined && this.FlightBookingService.formData.ReportGenarated == true) {


      if (this.FlightBookingService.formData != undefined && this.FlightBookingService.formData != null) {
        if (this.FlightBookingService.formData.CreationFromdate != '01-Jan-1900') {
          this.CreateDateFrom = new Date(this.FlightBookingService.formData.CreationFromdate);
          this.CreateDateTo = new Date(this.FlightBookingService.formData.CreationTodate);
        }

        if (this.FlightBookingService.formData.TravelFromdate != '01-Jan-1900') {
          this.TravelDateFrom = new Date(this.FlightBookingService.formData.TravelFromdate);
          this.TravelDateTo = new Date(this.FlightBookingService.formData.TravelTodate);
        }
      }
      if (this.FlightBookingService.formData != undefined) {
        if (this.FlightBookingService.formData.Destination != undefined) {
          this.loadDestinationbyid();

        }
        if (this.FlightBookingService.formData.Supplier != undefined && this.FlightBookingService.formData.Supplier != null) {
          this.loadSupplierbyid();
        }

      }
      this.parentForm = "PassengerBookingDetails";
      this.userID = +(this.apishare.uid);
      sessionStorage.setItem('Componentname', "PassengerBookingDetailsRpt");
    }
  }

  loadDestinationbyid() {
    if (this.FlightBookingService.formData.Destination != undefined && this.FlightBookingService.formData.Destination != '' && this.FlightBookingService.formData.Destination != null) {

      this.subscription.add(this.lookupApi.SearchDestination(this.FlightBookingService.formData.Destination, "1").subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.destinationToList = data["ResponseData"];
            if (this.FlightBookingService.formData.Destination != "") {

              this.destinationToSelectedItem = this.destinationToList.find(item => item.Code == this.FlightBookingService.formData.Destination);

              if (this.destinationToSelectedItem != null) {
                this.destinationSelected = this.destinationToSelectedItem.Name;
              }
              this.DestinationValueChange(this.destinationSelected);
            }
          }

        }
      ));
    }
  }


  loadSupplierbyid() {
    debugger;
    if (this.FlightBookingService.formData.Supplier != undefined && this.FlightBookingService.formData.Supplier != '' && this.FlightBookingService.formData.Destination != null) {

      this.subscription.add(this.lookupApi.SearchSupplier(this.FlightBookingService.formData.Supplier).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.SupplierList = data["ResponseData"];
            if (this.FlightBookingService.formData.Supplier != "") {

              this.SupplierSelectedItem = this.SupplierList.find(item => item.Code == this.FlightBookingService.formData.Supplier);

              if (this.SupplierSelectedItem != null) {
                this.supplierSelected = this.SupplierSelectedItem.Name;
              }
              this.SupplierValueChange(this.supplierSelected);
            }
          }

        }
      ));
    }
  }

  //#region for Autocomplete
  handleFilterToDestination(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          this.destinationToList = data["ResponseData"];
        }
      ));

    } else {
      this.autocompleteTo.toggle(false);
    }
  }
  handleFiltersupplier(value) {
    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          this.SupplierList = data["ResponseData"];
        }
      ));
    } else {
      this.autocompleteSupplier.toggle(false);
    }
  }
  //#endregion Autocomplete
  public DestinationValueChange(value: any): void {
    debugger;
    this.MCMultipleSelectedItems = '';
    this.DestinationMultiSelectType = 0;
    this.destinationToSelectedItem = this.destinationToList.find(item => item.Name == value);

    if (this.destinationToSelectedItem != null) {
      this.DestinationFrom = this.destinationToSelectedItem.Name;
      this.Destination = this.destinationToSelectedItem.UsrCode;

    }
    else {
      this.DestinationFrom = "";
      this.Destination = "";
    }
  }


  //#region for LookUpChangeEvent
  public SupplierValueChange(value: any): void {
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.SupplierSelectedItem = this.SupplierList.find(item => item.Name == value);
    }


    if (this.SupplierSelectedItem != null) {
      debugger;
      this.supplierSelected = this.SupplierSelectedItem.Name;
      this.SupplierName = this.SupplierSelectedItem.Name;
      this.Supplier = (this.SupplierSelectedItem.Code);
    }
    else {
      this.SupplierName = "";
      this.Supplier = 0;
    }
  }
  public AirLineChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.Airline = value.Name;
      this.VairlineAIRID = value.UsrCode;
    }
    else {
      this.subscription.add(this.lookupApi.GetAllLookup('AIRWAYS').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.AirlineList = data["ResponseData"];
          }
        }
      ));
      this.Airline = "";
      this.VairlineAIRID = "";
    }

  }
  //#Endregion for LookUpChangeEvent
  //#region for MultiSelect LookUp binding
  public multiSelect(value) {
    debugger;

    if (value == "Supplier") {
      this.MsInput = this.SupplierMultipleSelected;
    } else if (value == "ARRIVALPT") {
      this.MsInput = this.DestinationMultipleSelected;

    } else if (value == "AIRWAYS") {
      this.MsInput = this.AirlineMultipleSelected;

    }
    if (value == "Supplier" || value == "ARRIVALPT") {
      this.drop = false;

    }
    else if (value == "AIRWAYS") {
      this.drop = true;

    }

    this.type = value;

    this.mcodeMultiSelectPopUp = true;
  }

  public closeMultiSelectPopUP(event: any) {

    this.mcodeMultiSelectPopUp = false;
  }
  public close() {

    this.opened = false;
    this.openSearch = false;
  }

  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }

  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.SupplierSelectedItem = value;
        // this.SupplierList = [];
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  public MultiCodeSelectedItems(event: any) {

    debugger;

    console.log(event)

    this.destinationSelected = "";
    if (event.type == "ARRIVALPT") {
      if (event.resultSelectedList != null && event.resultSelectedList != "") {
        this.DestinationMultipleSelected.SelectedItems = event.resultSelectedList;
        this.DestinationMultipleSelected.isIncluded = event.isIncluded;
      }
      else {
        this.DestinationMultipleSelected.SelectedItems = '';
      }

    }

    if (event.type == "Supplier") {
      if (event.resultSelectedList != null && event.resultSelectedList != '') {
        this.SupplierMultipleSelected.SelectedItems = event.resultSelectedList;
        this.SupplierMultipleSelected.isIncluded = event.isIncluded;
      } else {
        this.SupplierMultipleSelected.SelectedItems = '';
      }


    }



    if (event.type == "AIRWAYS") {
      if (event.resultSelectedList != null && event.resultSelectedList != '') {
        this.AirlineMultipleSelected.SelectedItems = event.resultSelectedList;
        this.AirlineMultipleSelected.isIncluded = event.isIncluded;
        this.AirLineSelectedItem = { Name: "{MULTISELECT}", UsrCode: "" };
      }
      else {
        this.AirlineMultipleSelected.SelectedItems = '';
      }
    }


    this.mcodeMultiSelectPopUp = false;
    if (event.isIncluded == true) {
      // this.mcodeInclude = true;
      // this.mcodeExclude = false;
      this.setMultiselectValue(event.type, 1);

    }
    else if (event.isExcluded == true) {
      // this.mcodeInclude = false;
      // this.mcodeExclude = true;

      this.setMultiselectValue(event.type, 2);
    }
    else {
      this.setMultiselectValue(event.type, 0);

    }

  }
  setMultiselectValue(type, value) {
    debugger;
    switch (type) {
      case 'ARRIVALPT':
        this.DestinationMultiSelectType = value;
        break;
      case 'Supplier':
        this.SupplierMultiSelectType = value;
        break;
      case 'AIRWAYS':
        this.AirlineMultiSelectType = value;
        break;


    }
  }
  //#Endregion for MultiSelect LookUp binding
  CheckDateRange(): Boolean {
    if (this.CreateDateFrom != null && this.CreateDateFrom != "") {
      var result = this.dateRangeValidation(this.CreateDateFrom, this.CreateDateTo);
      if (result == false) {
        return;
      }
    }
    if (this.TravelDateFrom != null && this.TravelDateFrom != "") {
      var result = this.dateRangeValidation(this.TravelDateFrom, this.TravelDateTo);
      if (result == false) {
        return;
      }
    }

    if ((this.CreateDateTo == null || this.CreateDateTo == "") && (this.CreateDateFrom != null && this.CreateDateFrom != "")) {
      this.CreateDateTo = this.CreateDateFrom;

    }
    if ((this.TravelDateTo == null || this.TravelDateTo == "") && (this.TravelDateFrom != null && this.TravelDateFrom != "")) {
      this.TravelDateTo = this.CreateDateFrom;

    }
  }
  //#region for date range validation
  dateRangeValidation(fromDate, toDate) {
    // var DateFrom = this.common.ConvertToLocaleDate(fromDate);
    // var DateTo = this.common.ConvertToLocaleDate(toDate);
    if (Boolean(this.common.ValidDateRange(fromDate)) == false) {
      this.common.showWARNINGtoastrmsg('Messages.InvalidFromDate', 3000, true, false);
      this.spinner.hide();
      return false;
    }
    if (Boolean(this.common.ValidDateRange(toDate)) == false) {
      this.common.showWARNINGtoastrmsg('Messages.InvalidToDate', 3000, true, false);
      this.spinner.hide();
      return false;
    }
    if (new Date(toDate) < new Date(fromDate)) {
      this.common.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 3000, true, false);
      this.spinner.hide();
      return false;
    }
  }
  //#End region for date range validation
  async onFormSubmit() {
    debugger;
    this.spinner.show();

    // this.CreateDateFrom = (this.CreateDateFrom == null || this.CreateDateFrom == "" ? new Date("01-Jan-1900") : this.CreateDateFrom);
    // this.CreateDateTo = (this.CreateDateTo == null || this.CreateDateTo == "" ? new Date("01-Jan-1900") : this.CreateDateTo);
    // this.TravelDateFrom = (this.TravelDateFrom == null || this.TravelDateFrom == "" ? new Date("01-Jan-1900") : this.TravelDateFrom);
    // this.TravelDateTo = (this.TravelDateTo == null || this.TravelDateTo == "" ? new Date("01-Jan-1900") : this.TravelDateTo);

    this.PDCreateDateFrom = this.CreateDateFrom == null || this.CreateDateFrom == "" ? this.common.ConvertToLocaleDate("01-Jan-1900") : this.common.ConvertToLocaleDate(this.CreateDateFrom);
    this.PDCreateDateTo = this.CreateDateTo == null || this.CreateDateTo == "" ? this.common.ConvertToLocaleDate("01-Jan-1900") : this.common.ConvertToLocaleDate(this.CreateDateTo);
    this.PDTravelDateFrom = this.TravelDateFrom == null || this.TravelDateFrom == "" ? this.common.ConvertToLocaleDate("01-Jan-1900") : this.common.ConvertToLocaleDate(this.TravelDateFrom);
    this.PDTravelDateTo = this.TravelDateTo == null || this.TravelDateTo == "" ? this.common.ConvertToLocaleDate("01-Jan-1900") : this.common.ConvertToLocaleDate(this.TravelDateTo);
    if ((this.PDCreateDateFrom == null || this.PDCreateDateFrom == "01-Jan-1900") && (this.PDTravelDateFrom == null || this.PDTravelDateFrom == "01-Jan-1900")) {
      this.common.showWARNINGtoastrmsg(
        "Messages.selectanyDate",
        2000,
        true,
        false
      );
      this.spinner.hide();
      return;
    }
    this.ExportPDFParamList = [];
    var filter = new ExportPDFParam();
    if (this.CreateDateFrom != null && this.CreateDateFrom != "") {
      var result = await this.dateRangeValidation(this.CreateDateFrom, this.CreateDateTo);
      if (result == false) {
        return;
      }
    }
    if (this.TravelDateFrom != null && this.TravelDateFrom != "") {
      var result = await this.dateRangeValidation(this.TravelDateFrom, this.TravelDateTo);
      if (result == false) {
        return;
      }
    }

    if ((this.CreateDateTo == null || this.CreateDateTo == "") && (this.CreateDateFrom != null && this.CreateDateFrom != "")) {
      this.CreateDateTo = this.CreateDateFrom;

    }
    if ((this.TravelDateTo == null || this.TravelDateTo == "") && (this.TravelDateFrom != null && this.TravelDateFrom != "")) {
      this.TravelDateTo = this.TravelDateFrom;

    }
    if (
      this.TravelDateFrom == new Date("01-Jan-1900") &&
      this.CreateDateFrom == new Date("01-Jan-1900")

    ) {
      this.common.showFlash1(
        "Messages.empty_fromdate",
        "bg-danger text-white",
        2000
      );
      return;
    }


    var filter = new ExportPDFParam();
    if ((this.CreateDateTo == undefined || this.CreateDateTo == null)) {

      this.CreateDateTo = null;
    }
    else {
      // this.CreateDateTo = this.common.ConvertToLocaleDate(this.CreateDateTo);
      // this.CreateDateFrom = this.common.ConvertToLocaleDate(this.CreateDateFrom);
      if (this.PDCreateDateFrom != "01-Jan-1900") {
        var filter = new ExportPDFParam();
        filter.Filter = this.common.GetTransilationMessage('Labels.creation_date');
        filter.FirstValue = this.PDCreateDateFrom + '-' + this.PDCreateDateTo;
        this.ExportPDFParamList.push(filter);
      }
    }
    if ((this.TravelDateTo == undefined || this.TravelDateTo == null)) {

      this.TravelDateTo = null;
    }
    else {
      // this.TravelDateTo = this.common.ConvertToLocaleDate(this.TravelDateTo);
      // this.TravelDateFrom = this.common.ConvertToLocaleDate(this.TravelDateFrom);
      if (this.PDTravelDateFrom != "01-Jan-1900") {
        var filter = new ExportPDFParam();
        filter.Filter = this.common.GetTransilationMessage('Labels.travel_date');
        filter.FirstValue = this.PDTravelDateFrom + '-' + this.PDTravelDateTo;
        this.ExportPDFParamList.push(filter);
      }

    }

    if ((this.Supplier == undefined || this.Supplier == '')) {
      this.Supplier = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.supplier');
      filter.FirstValue = this.Supplier;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.Destination == undefined || this.Destination == '')) {
      this.Destination = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.destination');
      filter.FirstValue = this.Destination;
      this.ExportPDFParamList.push(filter);
    }

    if ((this.Airline == undefined || this.Airline == '')) {
      this.Airline = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.airline');
      filter.FirstValue = this.Airline;
      this.ExportPDFParamList.push(filter);
    }

    //#region for MultiSelect LookUp Query to Procedure

    var DestinationCodes = '';
    this.TableandField = "FM.ARRIVALPT";
    this.type = "ARRIVALPT";

    this.PassQuery = "";

    this.DestinationFrom == "";

    if (this.DestinationMultipleSelected != null && this.DestinationMultipleSelected.SelectedItems != "") {

      this.DestinationMultipleSelectedItems = this.DestinationMultipleSelected.SelectedItems;
      this.DestinationMultipleSelectedItems.forEach(element1 => {
        debugger;
        if (DestinationCodes == "") {
          DestinationCodes = "'" + element1.Code + "'";

        } else {
          DestinationCodes = DestinationCodes + ',' + "'" + element1.Code + "'";

        }


      })



      if (this.DestinationMultipleSelected.isIncluded) {

        this.MCQuery = this.TableandField + " IN (" + DestinationCodes + ")";

        if (this.PassQuery != "") {
          this.PassQuery = this.PassQuery + " and " + this.MCQuery;
        }
        else {
          this.PassQuery = this.MCQuery;
        }

      } else {

        this.MCQuery = this.TableandField + " NOT IN (" + DestinationCodes + ")";
        if (this.PassQuery != "") {
          this.PassQuery = this.PassQuery + " and " + this.MCQuery;
        }
        else {
          this.PassQuery = this.MCQuery;
        }

      }

    }

    var SupplierCodes = '';
    this.TableandField = "AirTicketDetails.PRTYCODE";
    this.type = "Supplier";

    this.SupplierQuery = "";

    debugger;
    this.SupplierName == "";
    if (this.SupplierMultipleSelected != null && this.SupplierMultipleSelected.SelectedItems != "") {
      this.SupplierCodeMultSelectedItems = this.SupplierMultipleSelected.SelectedItems;
      if (this.SupplierCodeMultSelectedItems != null) {

        SupplierCodes = this.SupplierCodeMultSelectedItems;
        if (SupplierCodes != "") {
          if (this.SupplierMultipleSelected.isIncluded) {

            this.MCQuery = this.TableandField + " IN (" + SupplierCodes + ")";
            this.SupplierQuery = this.MCQuery;

          } else {

            this.MCQuery = this.TableandField + " NOT IN (" + SupplierCodes + ")";
            this.SupplierQuery = this.MCQuery;
          }
        }

        if (this.PassQuery != "" && this.SupplierQuery != "") {
          this.PassQuery = this.PassQuery + " and " + this.SupplierQuery;
        }
        else if (this.PassQuery == "" && this.SupplierQuery != "") {
          this.PassQuery = this.SupplierQuery;
        }

      }
    }
    var AirlineCodes = '';
    this.TableandField = "t1.AIRNAME";
    this.type = "AIRWAYS";

    this.AirlineQuery = "";

    debugger;

    if (this.AirlineMultipleSelected != null && this.AirlineMultipleSelected.SelectedItems != "") {
      this.AirlineMultipleSelectedItems = this.AirlineMultipleSelected.SelectedItems;
      this.AirlineMultipleSelectedItems.forEach(element1 => {
        debugger;
        if (AirlineCodes == "") {
          AirlineCodes = "'" + element1.UsrCode + "'";

        } else {
          AirlineCodes = AirlineCodes + ',' + "'" + element1.UsrCode + "'";

        }
      })
      if (this.AirlineMultipleSelected.isIncluded) {

        this.MCQuery = this.TableandField + " IN (" + AirlineCodes + ")";
        this.AirlineQuery = this.MCQuery;

      } else {

        this.MCQuery = this.TableandField + " NOT IN (" + AirlineCodes + ")";
        this.AirlineQuery = this.MCQuery;

      }
      if (this.PassQuery != "" && this.AirlineQuery != "") {
        this.PassQuery = this.PassQuery + " and " + this.AirlineQuery;
      }
      else if (this.PassQuery == "" && this.AirlineQuery != "") {
        this.PassQuery = this.AirlineQuery;
      }
    }
    //#End region for MultiSelect LookUp Query to Procedure
    this.FlightBookingDetailsDTO = new FlightBookingDetailsDTO();
    this.FlightBookingDetailsDTO.CreationFromdate = this.PDCreateDateFrom;
    this.FlightBookingDetailsDTO.CreationTodate = this.PDCreateDateTo;
    this.FlightBookingDetailsDTO.TravelFromdate = this.PDTravelDateFrom;
    this.FlightBookingDetailsDTO.TravelTodate = this.PDTravelDateTo;
    this.FlightBookingDetailsDTO.ModuleName = this.ModuleName;
    this.FlightBookingDetailsDTO.UserId = this.userID;
    if (this.Airline != "" && this.Airline != undefined && this.Airline != null) {
      this.FlightBookingDetailsDTO.Airline = this.VairlineAIRID;
      this.FlightBookingDetailsDTO.AirlineName = this.Airline;
    }
    else {
      this.FlightBookingDetailsDTO.Airline = "";
    }
    if (this.DestinationFrom != "" && this.DestinationFrom != undefined && this.DestinationFrom != null) {
      this.FlightBookingDetailsDTO.Destination = this.Destination;
      this.FlightBookingDetailsDTO.DestinationFrom = this.DestinationFrom;
    }
    else {
      this.FlightBookingDetailsDTO.Destination = "";
    }
    if (this.SupplierName != "" && this.SupplierName != undefined && this.SupplierName != null) {
      this.FlightBookingDetailsDTO.Supplier = this.Supplier;
      this.FlightBookingDetailsDTO.SupplierName = this.SupplierName;
    }
    else {
      this.FlightBookingDetailsDTO.Supplier = "";
    }

    this.FlightBookingDetailsDTO.passQry = this.PassQuery;
    this.FlightBookingDetailsDTO.ExportPDFParam = this.ExportPDFParamList;
    this.FlightBookingService.formData = this.FlightBookingDetailsDTO;
    this.PassengerBookingRequest = JSON.stringify(this.FlightBookingDetailsDTO);

    this.subscription.add(this.FlightBookingService.GetPassengerBookingDetailsReport().subscribe(res => {
      debugger;
      if (res["ResponseData"] != undefined) {

        this.spinner.hide();

        this.gridData = res["ResponseData"];

        if (this.gridData.length > 0) {

          this.FlightBookingDetailsDTO.ReportGenarated = true;
          this.FlightBookingService.griddata = this.gridData;
          this.router.navigate(["Reports/PassengerBookingDetails"]);
          this.spinner.hide();
        }
        else {
          this.common.showWARNINGtoastrmsg(
            "Messages.no_data_found",
            2000,
            true,
            false
          );
          this.spinner.hide();
        }
      }
      else {
        this.spinner.hide();
      }
    }
    ))
  }
  btnClearAll() {
    debugger;
    this.FlightBookingService.formData = undefined;
    this.FlightBookingService.formData = null;
    this.CreateDateFrom = null;
    this.CreateDateTo = null;
    this.TravelDateFrom = null;
    this.TravelDateTo = null;
    this.AirLineSelectedItem = null;
    this.destinationSelected = null;
    this.SupplierSelectedItem = null;
    this.DestinationMultipleSelectedItems = null;
    this.AirlineMultipleSelectedItems = null;
    this.SupplierMultipleSelectedItems = null;
    this.DestinationMultiSelectType = 0;
    this.AirlineMultiSelectType = 0;
    this.SupplierMultiSelectType = 0;
    this.supplierSelected = null;
    this.PassQuery = "";
    this.autocompleteSupplier.reset();
    this.autocompleteTo.reset();
    this.DestinationFrom = "";
    this.Destination = "";
    this.Airline = "";
    this.VairlineAIRID = "";
    this.Supplier = "";
    this.SupplierName = "";
  }
  onCancel() {
    var reporturl = localStorage.getItem('menuhighlight')

    if (reporturl != null) {
      localStorage.removeItem('menuhighlight');
      this.router.navigate(['/Login']);
    }
    else {
      this.router.navigate(['PenAir/Home']);
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
