import { Injectable } from '@angular/core';
import {  DelinkDto,DelinkReceiptGetDetailsDTO } from '../Models/delink-dto';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { EnvService } from 'src/env.service';
import { CommonserviceService } from './commonservice.service';
import { tap, catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
@Injectable({
  providedIn: 'root'
})
export class DelinkService {
  public formData:DelinkDto;
  public gridData:DelinkReceiptGetDetailsDTO[];
  apiUrl :string;
  
  

  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   }

   public  DelinkReceiptData(): Observable<DelinkReceiptGetDetailsDTO> {
    var body = {
          //...this.gridData,
          DelinkPostData: this.gridData
     
    };
    //alert(JSON.stringify(body))
    return this.http.post<DelinkReceiptGetDetailsDTO>(this.apiUrl+'PostDelinkReceiptDetails', body, httpOptions).pipe(
      tap((DelinkGrid: any) => console.log(`PostDelinkReceiptDetails`)),
      catchError(this.commonservice.handleError<any>('PostDelinkReceiptDetails'))
    );
  }

public  GetDelinkGridData(): Observable<DelinkReceiptGetDetailsDTO> {
  var body = {
        ...this.formData,
   
   
  };
  return this.http.post<DelinkReceiptGetDetailsDTO>(this.apiUrl+'GetDelinkReceiptDetails', body, httpOptions).pipe(
    tap((DelinkReceiptGetDetailsDTO: any) => console.log(`GetDelinkReceiptDetails`)),
    catchError(this.commonservice.handleError<any>('GetDelinkReceiptDetails'))
  );
}
}
