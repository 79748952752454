import { Component, OnInit, Input } from '@angular/core';
import { ShareDataService } from '../../Services/share-data.service';
import { Subscription } from 'rxjs';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { CommonserviceService } from '../../Services/commonservice.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { FileService } from '../../Services/file.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FolderpriceService } from '../../Services/folderprice.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-price-details-view',
  templateUrl: './price-details-view.component.html',
  styleUrls: ['./price-details-view.component.scss']
})
export class PriceDetailsViewComponent implements OnInit {
  public isWexEnabled: boolean = false;
  public createinvoiceanddeleteinvoice = true
  isShownWEX: boolean = false;
  public rdCheckedWex = false;
  DisableFC_Tot_Buy: boolean = true;
  ShownCarddetails: boolean = false;
  MerchantLogUniqueId: any = '';
  SupplierRef: any = "";
  CardNo: any = '';
  CID: any = '';
  CardSecurityCode: any = '';
  CardExpiryDate: any = '';
  suppcode: any = 0;
  PDQ: any = 0;
  DateOfTravel: any;
  hcCurrency: any;
  public pocreated: boolean = false;
  public labelchange = false;
  folderExrate: any;
  fldrCurrency: any;
  private buyExRate: number;
  folderStatus: any;
  isShown = false;
  Producttype: any = false;
  buycurrency: any;
  divValue: any;
  CostingVatType: any = 'No VAT'
  @Input() itineraryDetails;
  Tax: Array<LookUpDetails["ResponseData"]> = [];
  opentaxbaseForm: any = false;
  public TaxselectedItem: LookUpDetails["ResponseData"];
  private subscription: Subscription = new Subscription();
  constructor(private router: Router, private apiShared: ShareDataService, private lookupApi: LookUpDetailsService, public intl: IntlService,
    public common: CommonserviceService, private fileser: FileService, private spinner: NgxSpinnerService, public objFolderPrice: FolderpriceService) {
    debugger;
    this.subscription.add(this.lookupApi.GetAllLookup('ISWEX').subscribe(
      (data) => {

        debugger;
        if (data["ResponseData"] != null && data["ResponseData"].length > 0) {
          this.isWexEnabled = false;
        }
        else {
          this.isWexEnabled = true;
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('TAX').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.Tax = data["ResponseData"];

          // alert(this.parentForm.controls.ITYPE.value);   
          var tax = this.itineraryDetails.ResponseData.SELLTAXNO;
          if (tax != "") {

            this.TaxselectedItem = this.Tax.find(item => item.Code == tax);

          }
        }
      }
    ));

    if (this.itineraryDetails != undefined && this.itineraryDetails.ResponseData.ITYPE != "Hotel" && this.itineraryDetails.ResponseData.ITYPE != "Others"
      && this.itineraryDetails.ResponseData.ITYPE != "Ticket") {
      this.subscription.add(this.lookupApi.GetAllLookup('InputTaxMaster').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.Tax = data["ResponseData"];

            // alert(this.parentForm.controls.ITYPE.value);   
            var tax = this.itineraryDetails.ResponseData.SELLTAXNO;
            if (tax != "") {

              this.TaxselectedItem = this.Tax.find(item => item.Code == tax);

            }

          }

        }
      ));
    }

  }

  ngOnInit() {
    debugger;
    this.loadPaymethod()
    this.loadfiledetails();
    this.folderStatus = this.apiShared.FolderStatus;
    this.hcCurrency = this.apiShared.homeCurrency;
    if (this.itineraryDetails.ResponseData.FC_Tot_Buy == undefined) {
      this.itineraryDetails.ResponseData.FC_Tot_Buy = this.itineraryDetails.ResponseData.FCBuy;
    }
    if ((this.itineraryDetails.ResponseData.PONO != null && this.itineraryDetails.ResponseData.PONO > 0) && this.folderStatus == "Invoiced") {
      this.pocreated = true;
    }
    else {
      this.pocreated = false;
    }

    if (this.itineraryDetails.ResponseData.OthersType == "Default" || this.folderStatus == "Invoiced" || this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund")//By Caseid-12780
    {
      this.pocreated = true;
    }
    if ((this.itineraryDetails.ResponseData.ITYPE) == 'Hotel' || (this.itineraryDetails.ResponseData.ITYPE) == 'Cruise' || this.itineraryDetails.ResponseData.ITYPE == 'Car' || this.itineraryDetails.ResponseData.ITYPE == 'Tour' || this.itineraryDetails.ResponseData.ITYPE == 'Rail' || this.itineraryDetails.ResponseData.ITYPE == 'Insurance' || this.itineraryDetails.ResponseData.ITYPE == 'Transfers') {
      this.buycurrency = this.itineraryDetails.ResponseData.CURCODE;
    }
    if ((this.itineraryDetails.ResponseData.ITYPE) == 'Others') {
      this.buycurrency = this.itineraryDetails.ResponseData.CURID;
    }
    // if(this.itineraryDetails.ResponseData.ITYPE=="Hotel" ||this.itineraryDetails.ResponseData.ITYPE=="Others"||this.itineraryDetails.ResponseData.ITYPE=="Cruise"||this.itineraryDetails.ResponseData.ITYPE=="Car"||this.itineraryDetails.ResponseData.ITYPE=="Rail")
    // {
    this.Producttype = true;
    // }
    if (this.itineraryDetails.ResponseData.CostingVATType == 'NV') {
      this.divValue = 1;
      this.CostingVatType = 'No VAT'
    }
    else if (this.itineraryDetails.ResponseData.CostingVATType == 'VSF') {
      this.divValue = 2;
      this.CostingVatType = 'VAT on Service Fee'
    }
    else if (this.itineraryDetails.ResponseData.CostingVATType == 'VP') {
      this.divValue = 3;
      this.CostingVatType = 'VAT on Purchase'
    }
    else if (this.itineraryDetails.ResponseData.CostingVATType == 'VSC') {
      this.CostingVatType = 'VAT on Supp.Commission'
      this.divValue = 4;
    }
    else {
      this.divValue = 1;
      this.CostingVatType = 'No VAT'
    }


    this.getFolderValues();
    if (this.itineraryDetails.ResponseData.ITYPE == "Hotel" || this.itineraryDetails.ResponseData.ITYPE == "Others"
      || this.itineraryDetails.ResponseData.ITYPE == "Ticket")
      this.BindTax();
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.folderStatus == "Refund Request") {
      this.labelchange = true;
    }
    this.TaxsplitCalculation();
  }
  BindTax() {
    debugger;
    if (this.itineraryDetails.ResponseData.SUPPRTYCODE != null && this.itineraryDetails.ResponseData.SUPPRTYCODE != undefined && this.itineraryDetails.ResponseData.SUPPRTYCODE != 0) {
      this.lookupApi.GetSupplierorCustomerCountry('SUPPLIERS', this.itineraryDetails.ResponseData.SUPPRTYCODE).subscribe(data => {
        var countryID;
        if (data != null && data != undefined) {
          countryID = data["ResponseData"].CONTID;
        }
        else {
          this.lookupApi.GetDefaultCountry().subscribe(data1 => {
            if (data1 != null && data1 != undefined) {
              countryID = data1["ResponseData"].UsrCode;


            }
          });
        }
        if (countryID != undefined && countryID != null) {
          this.getInputTaxLookup(countryID);

        }
      }
      );
    }
  }
  getInputTaxLookup(country: any) {
    this.subscription.add(this.lookupApi.GetLookupById('InputTaxMaster', country, "''").subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.Tax = data["ResponseData"];

          // alert(this.parentForm.controls.ITYPE.value);   
          var tax = this.itineraryDetails.ResponseData.CostingTaxNo;
          if (tax != "") {

            this.TaxselectedItem = this.Tax.find(item => item.Code == tax);

          }
        }
      }
    ));
  }
  loadfiledetails() {
    this.subscription.add(
      this.fileser.GetCompanySettings("Ticket").subscribe(data => {
        if (data != null && data != undefined) {

          debugger;
          this.common.BSPTAXEnable = data.ResponseData.BSPTAXEnable;
        }
      }))
  }
  onOpenTaxbase() {
    this.opentaxbaseForm = true;
  }
  close() {
    this.opentaxbaseForm = false;
  }
  closeTaxBaseForm(): any { //13860 v1.0
    return {
      callParentMethod: (value: any) => {
        debugger;


      },
      closemodalpopup: () => {

        this.close()

      }
    }
  }
  TaxsplitCalculation() {
    this.folderExrate = this.apiShared.folderExchangeRate;
    // if((this.itineraryDetails.ResponseData.ITYPE ) =='Hotel'||(this.itineraryDetails.ResponseData.ITYPE ) =='Others')
    // {
    if (this.itineraryDetails.ResponseData.ITYPE == "Hotel") {
      this.buyExRate = this.itineraryDetails.ResponseData.EXRATE == "" || this.itineraryDetails.ResponseData.EXRATE == 0 ? 1 : this.itineraryDetails.ResponseData.EXRATE;


    }
    else {
      this.buyExRate = (this.itineraryDetails.ResponseData.CURExRate == "" || this.itineraryDetails.ResponseData.CURExRate == 0) ? 1 : this.itineraryDetails.ResponseData.CURExRate;
    }
    if (this.itineraryDetails.ResponseData.CostingVATType == 'NV' || this.itineraryDetails.ResponseData.CostingVATType == '' || this.itineraryDetails.ResponseData.CostingVATType == null || this.itineraryDetails.ResponseData.CostingVATType == undefined) {
      this.itineraryDetails.ResponseData.CostingCustomerSalesValue = (((this.itineraryDetails.ResponseData.FCBuy * this.buyExRate) / this.folderExrate));


      this.itineraryDetails.ResponseData.CostingCustomerServiceFee = this.itineraryDetails.ResponseData.FCSell - this.itineraryDetails.ResponseData.CostingCustomerSalesValue
    }
    if (this.itineraryDetails.ResponseData.CostingVATType == 'VSF') {
      this.itineraryDetails.ResponseData.CostingCustomerSalesValue = (((this.itineraryDetails.ResponseData.FCBuy * this.buyExRate) / this.folderExrate));


      this.itineraryDetails.ResponseData.CostingCustomerServiceFee = this.itineraryDetails.ResponseData.FCSell - this.itineraryDetails.ResponseData.CostingCustomerSalesValue
    }
    // }
  }

  getFolderValues() {
    // this.apiShared.folderCurrency.subscribe(fldrCurrency => this.fldrCurrency = fldrCurrency)
    // this.apiShared.folderExchangeRate.subscribe(folderExrate => this.folderExrate = folderExrate)
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    //this.folderExrate=new Number(this.folderExrate);


  }
  isShowncm: boolean = true; // hidden by default
  isShownCmp: boolean = false;
  isShownCust: boolean = false;

  public toggleShow() {
    debugger;
    this.isShowncm = true;
    this.isShownCmp = false;
    this.isShownCust = false;

  }
  public toggleShowCmp() {

    this.isShownCmp = true;
    this.isShowncm = false;
    this.isShownCust = false;
  }
  public toggleShowCust() {

    this.isShownCust = true;
    this.isShownCmp = false;
    this.isShowncm = false;

  }

  public rdCheckedCr = false;
  public rdCheckedCust = false;
  public rdCheckedCmp = false;
  public loadPaymethod() {
    if (this.itineraryDetails.ResponseData.ITYPE != "Ticket") {

      if (this.itineraryDetails.ResponseData.PAYMODE != null) {


        if (this.itineraryDetails.ResponseData.PAYMODE == "Company Card") {
          this.rdCheckedCmp = true;
          this.toggleShowCmp();
        }
        else if (this.itineraryDetails.ResponseData.PAYMODE == "Customer Card") {
          this.rdCheckedCust = true;
          this.toggleShowCust();
        }
        else {
          this.rdCheckedCr = true;
          this.toggleShow();
        }
      }
      else {
        this.rdCheckedCr = true;
        this.toggleShow();
      }
    }
    else {
      if (this.itineraryDetails.ResponseData.COMONLY != null) {

        if (this.itineraryDetails.ResponseData.COMONLY == "W") {
          this.rdCheckedCmp = true;
          this.toggleShowCmp();
        }
        else if (this.itineraryDetails.ResponseData.COMONLY == "C") {
          this.rdCheckedCust = true;
          this.toggleShowCust();
        }
        else {
          this.rdCheckedCr = true;
          this.toggleShow();
        }
      }
      else {
        this.rdCheckedCr = true;
        this.toggleShow();
      }

    }
  }



  toggleShowWexPay() {
    this.isShownCmp = false;
    this.isShown = false;
    this.isShownCust = false;
    this.isShownWEX = true;
  }
  onCancelPO() {
    this.ShownCarddetails = false;
  }
  ShowCardDetails() {
    debugger;
    this.spinner.show();
    this.ShownCarddetails = true;
    this.subscription.add(this.objFolderPrice.GetItineraryDetailsForWexPayment(this.itineraryDetails.ResponseData.FOLDERNO, this.itineraryDetails.ResponseData.GUIDItinerary, this.itineraryDetails.ResponseData.ITYPE, this.itineraryDetails.ResponseData.SLNO).subscribe(
      (data) => {
        if (data.ResponseData != null && data.ResponseData.datampdel != null && data.ResponseData.datampdel.ResponseMessage != null && data.ResponseData.datampdel.ResponseMessage != '') {
          this.spinner.hide();
          this.ShownCarddetails = false;
          // this.CID=data.ResponseData.CID;
          // this.suppcode=data.ResponseData.SUPPPRTYCODE;
          // this.PDQ=data.ResponseData.PDQ;

          // this.CardNo=data.ResponseData.datampdel.CardNumber
          // this.CardSecurityCode=data.ResponseData.datampdel.CardSecurityCode;
          // this.MerchantLogUniqueId=data.ResponseData.datampdel.MerchantLogUniqueId;
          // if(data.ResponseData.datampdel.ExpirationDate!=null)
          // this.CardExpiryDate=this.common.ConvertToLocaleDate(data.ResponseData.datampdel.ExpirationDate);
          // this.DateOfTravel= data.ResponseData.datampdel.DateOfTravel;
          this.common.showWARNINGtoastrmsg(data.ResponseData.datampdel.ResponseMessage, 2000, true, false);
        } else if (data.ResponseData != null && data.ResponseData.datampdel != null && data.ResponseData.datampdel.ResponseMessage != null && data.ResponseData.datampdel.ResponseMessage == '') {
          this.spinner.hide();
          this.CID = data.ResponseData.CID;
          this.suppcode = data.ResponseData.SUPPPRTYCODE;
          this.PDQ = data.ResponseData.PDQ;
          this.CardNo = data.ResponseData.datampdel.CardNumber
          this.CardSecurityCode = data.ResponseData.datampdel.CardSecurityCode;
          this.MerchantLogUniqueId = data.ResponseData.datampdel.MerchantLogUniqueId;
          if (data.ResponseData.datampdel.ExpirationDate != null)
            this.CardExpiryDate = this.common.ConvertToLocaleDate(data.ResponseData.datampdel.ExpirationDate);

          this.DateOfTravel = data.ResponseData.datampdel.DateOfTravel;
        }
        else {
          this.CID = data.ResponseData.CID;
        }
        this.spinner.hide();
        //this.r=data.ResponseData.

        //  this.subscription.add(this.objFolderPrice.GetWexPaymentSettingsDataByID(1).subscribe(
        //   (data) => {
        //    // this.CardNo=data.ResponseData.
        //   }
        // ))
      }
    ))

  }
  onCreatePO(id: any) {
    this.spinner.show();
    if (this.SupplierRef == null || this.SupplierRef == '') {
      this.spinner.hide();
      this.common.showWARNINGtoastrmsg("Messages.SupplierRefMandatory", 2000, true, false);
      return;
    }

    debugger;


    this.objFolderPrice.body = new Object;
    this.objFolderPrice.body.GUIDItinerary = this.itineraryDetails.ResponseData.GUIDItinerary;
    this.objFolderPrice.body.TYPE = this.itineraryDetails.ResponseData.ITYPE;
    this.objFolderPrice.body.SLNO = this.itineraryDetails.ResponseData.SLNO;
    this.objFolderPrice.body.FOLDERNO = this.itineraryDetails.ResponseData.FOLDERNO;
    //this.objFolderPrice.body.PDQNO=this.itineraryDetails.ResponseData.PDQNO;
    this.objFolderPrice.body.Amount = this.itineraryDetails.ResponseData.FC_Tot_Buy;
    this.objFolderPrice.body.MerchantLogUniqueId = this.MerchantLogUniqueId;
    this.objFolderPrice.body.SupplierRef = this.SupplierRef;
    this.objFolderPrice.body.supplierCode_bigint = this.suppcode;
    this.objFolderPrice.body.pTravelDateFrom_smalldatetime = this.common.ConvertToLocaleDate(this.DateOfTravel);
    this.objFolderPrice.body.CID = this.CID;
    if (id == 1) {
      this.objFolderPrice.body.PostType = "Save";
    }
    this.DisableFC_Tot_Buy = true;
    this.objFolderPrice.body.CuurentDate = this.common.ConvertToLocaleDate(new Date());
    this.subscription.add(this.objFolderPrice.AutomaticPOCreationAndSave().subscribe(
      (data) => {
        if (data.ResponseData != null && data.ResponseData != null && data.ResponseData.ResponseMessage != null && data.ResponseData.ResponseMessage != '' && data.ResponseData.MSGID == 4) {
          this.spinner.hide();
          this.common.showWARNINGtoastrmsg(data.ResponseData.ResponseMessage, 5000, true, false);
        }
        else if (data.ResponseData != null && data.ResponseData != null && data.ResponseData.ResponseMessage != null && data.ResponseData.ResponseMessage != '' && (data.ResponseData.MSGID == 1 || data.ResponseData.MSGID == 2 || data.ResponseData.MSGID == 3)) {
          this.spinner.hide();
          if (data.ResponseData.MSGID == 1) {
            this.common.showSUCCESStoastrmsg("Messages.saved_success", 5000, true, false);

          }
          else {
            this.common.showSUCCESStoastrmsg(data.ResponseData.ResponseMessage, 5000, true, false);
            var orderno = sessionStorage.getItem("orderNo");
            this.router.navigate(["PenAir/Folder/FolderSummary", orderno]);
            this.pocreated = true;
          }
        }

        this.spinner.hide();
        //this.r=data.ResponseData.


      }
    ))


  }
  EnableFC_Tot_Buy() {
    debugger;
    this.DisableFC_Tot_Buy = false;

  }
}
