export class Mealplanmaster {
    IsSuccess:boolean;
    Message:string;
    ResponseData:{
        MPM_Id :string;
        MPM_Description :string;
        MPM_AutoNo:number;
        StatusDetails:string;
        Status:string;
    }
}
    export class MealplanmasterRequestDTO {
        MPM_AutoNo:number;
        MPM_Id :string;
        MPM_Description :string;
        MPM_CrUID:number;
        MPM_CrDate:any;
        MPM_LupUID:number;
        MPM_LupDate:any;
        StatusDetails:string;
       
    }

