import { Component, OnInit } from '@angular/core';
import { OthersDetail } from '../../Models/others-detail';
import { ActivatedRoute, Router } from '@angular/router';
import { OthersService } from '../../Services/others.service';
import { ShareDataService } from '../../Services/share-data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { POCreationDetail } from '../../Models/pocreation-detail';
import { Pocreationresult } from '../../Models/pocreationresult';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PocreationService } from '../../Services/pocreation.service';
import { DeletePO } from '../../Models/delete-po';
import { Deletepo } from '../../Models/deletepo';
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription } from 'rxjs';
import { FolderpriceService } from '../../Services/folderprice.service';

@Component({

  selector: 'app-others-details',
  templateUrl: './others-details.component.html',
  styleUrls: ['./others-details.component.scss']
})


export class OthersDetailsComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public deletePO: DeletePO;
  public language = this.apiShared.LanguageSelected;
  public result: any;

  public DeleteInvoiceResponse: Deletepo;
  folderEditMode: any;
  folderStatus: any;
  lockStatus: any;

  loaded: boolean = false;
  public POCreation: POCreationDetail;
  public confirmMsg: any;
  public showMsg: any;
  public PONO: number;
  public pocreated = true;
  Pocreationresultobj: Pocreationresult;
  projNo: any;
  passName: any;
  showOrder = true;
  public formeditable = true;
  txtDisabled: true;
  public enableCheck = false;
  public enablePackage = false;
  public createinvoiceanddeleteinvoice = true;//By Caseid-12621
  public bookingDate: any;//By Caseid-12619
  public FolderQuoteCreatePO: any;
  public FolderQuoteDeletePO: any;
  public defaultchanrge = false;
  public FolderHideStatus = false;//12780
  othersDetail: OthersDetail = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {


      FOLDERNO: 0,
      CODE: 0,
      DESCRIPTION: "",
      UNIT: "",
      QTY: 0,
      RATE: 0,
      DISCOUNT: 0,
      AMOUNT: 0,
      VATCODE: 0,
      TOTAL: 0,
      SUPPCODE: 0,
      SUPPNAME: "",
      VATPER: 0,
      SLNO: 0,
      PROJNO: 0,
      ITYPE: "",
      GDS: "",
      PNR: "",
      PrintonInvoice: 0,
      OthersType: "",
      CURID: "",
      CURExRate: 0,
      AMTFC: 0,
      DefaultDepositPer: 0,
      DefaultDepositAmt: 0,
      STATUS: "",
      PAYMODE: "",
      CommOn: "",
      FCBuy: 0,
      FCCommPer: 0,
      FCCommAmt: 0,
      FCSell: 0,
      HCCommPer: 0,
      HCCommAmt: 0,
      HCSell: 0,
      ItnryNotes: "",
      LowFB: "",
      LowFare: "",
      HighFB: "",
      HighFare: 0,
      Fareoffered: 0,
      PotentialSaving: 0,
      ActualSaving: 0,
      PDNO: 0,
      PDTYPENO: 0,
      Forgone: 0,
      AddRemarks: "",
      PDAMOUNT: 0,
      BookingDate: this.FormatDate(new Date('01/01/1900').toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, '/')),
      PONO: 0,
      PONOYEARID: "",
      PAYMENTID: 0,
      PMTID: "",
      DEFDEPPER: 0,
      DEFDEP: 0,
      DepositDueDate: this.FormatDate(new Date('01/01/1900').toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, '/')),
      BalanceDueDate: this.FormatDate(new Date('01/01/1900').toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, '/')),
      BalanceDueAmount: 0,
      ProductDescription: "",
      GUIDItinerary: "",
      LOYALTYPOINTS: 0,
      LOYALTYPOINTSMEMBNO: "",
      TOMSVATTAXNO: 0,
      TOMSVATAMT: 0,
      PDUNIQUEREF: "",
      LOYALTYPOINTSAMT: 0,
      PDFCURID: "",
      PDEXRATE: 0,
      PDFCAMT: 0,
      SELLTAXNO: 0,
      SELLTAXRATE: 0,
      FC_SELLTAXAMT: 0,
      HC_SELLTAXAMT: 0,
      CONFIRMATIONNO: "",
      TaxExcludingSalesTax: 0,
      BaseFare: 0,
      PONOCCDeferredPosted: 0,
      BOOKEDBY: "",
      PMName: '',

      PDQDESC: '',
      CRDDESC: '',
      PMTDESC: '',
      PAYM: '',
      GSTHSTOnComm: 0,
      Markup: 0,
      CostofSale: 0,
      CostingSupplierGrossValue: 0,
      CostingCustomerSalesValue: 0,
      CostingCustomerServiceFee: 0,
      CostingCustomerNetSellPrice: 0,
      CostingVATType: '',
      CostingSupplierServiceFee: 0,
      CostingTaxNo: 0,
      CostingTaxRate: 0,
      CostingSupplierTaxAmount: 0,
      CostingSupplierNetBuyPrice: 0,
      CostingCustomerTaxAmount: 0,
      CostingSuppComm: 0,
      BSPPurchaseTax: 0,
      BSPPurchaseTaxRate: 0,
      SupplierDeposit: 0
    }

  };


  constructor(public commonservice: CommonserviceService, private route: ActivatedRoute, private router: Router, private api: OthersService, private apiShared: ShareDataService, private spinner: NgxSpinnerService
    , private apiPO: PocreationService, private translateapi: TranslateService, private toastr: ToastrService,  public objFolderPrice: FolderpriceService) { }

  private guid: string = this.route.snapshot.params['guidItinerary'];//'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/';
  private orderno = this.route.snapshot.params['orderNo']

  ngOnInit() {
    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderno !== orderno) {
        return this.router.navigate(['PenAir/Home']);
      }
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;

    this.folderEditMode = "false";
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.formEditableCheck();
    this.getOthersDetails(this.orderno, this.guid);
    var folderQuotes = sessionStorage.getItem('folderQuotes');//12663
    if (folderQuotes) {
      this.FolderQuoteCreatePO = true;
      this.FolderQuoteDeletePO = true;
    }
    else {
      this.FolderQuoteCreatePO = false;
      this.FolderQuoteDeletePO = false;
    }
  }



  getOthersDetails(id, guid) {
    debugger;
    this.spinner.show();
    this.subscription.add(this.api.getOthers(id, guid, true)
      .subscribe(data => {
        if (data != null && data != undefined) {
          this.othersDetail = data;
          this.PONO = this.othersDetail.ResponseData.PONO;

          if (this.PONO > 0) {

            this.pocreated = false;
          }
          else {
            this.pocreated = true;
          }
          if (this.othersDetail.ResponseData.PrintonInvoice == 1) {
            this.enableCheck = true;
          }
          else {
            this.enableCheck = false;
          }
          if (this.othersDetail.ResponseData.OthersType == "Package") {
            this.formEditableCheck();

            this.enablePackage = true;
          }
          else if (this.othersDetail.ResponseData.OthersType == "Default") {
            this.formeditable = false;
            this.defaultchanrge = true;
          }
          else {
            this.formEditableCheck();

            this.enablePackage = false;
          }

          this.loaded = true;
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
        }
      }));
  }
  onEdit() {

    this.router.navigate(['PenAir/Product/OthersDetailsEdit', this.orderno, this.guid + '/']);
  }
  onCancel() {
    this.commonservice.isfinalsaved = false;
    sessionStorage.setItem('pagevalue', '0');
    var folderQuotes = sessionStorage.getItem('folderQuotes');
    if (folderQuotes) {
      this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno]);
    } else {
      this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno]);
    }
  }

  public dattobechecked: any;
  public FormatDate(value: any): string {
    this.dattobechecked = this.commonservice.transformDate(new Date(value));
    if (this.dattobechecked == "1-Jan-1900")
      return "";
    else {
      return (this.dattobechecked);
    }
  }
  async onCreatePO() {
    this.spinner.show();
    if (Boolean(await this.commonservice.checkPermission1('01.12.01.26')) == true) {
      this.POCreation = new POCreationDetail();
      this.POCreation.folderNo = this.orderno;
      this.passName = this.apiShared.PassengerNames;
      this.projNo = this.apiShared.projNo;
      this.POCreation.projNo = this.projNo;
      this.POCreation.passName = this.passName;
      this.POCreation.postDate = new Date(this.othersDetail.ResponseData.BookingDate);
      this.POCreation.Status=this.folderStatus;//11614
      this.POCreation.POGUIDItinerary = this.othersDetail.ResponseData.GUIDItinerary;
      this.subscription.add(this.apiPO.CreatePO(this.POCreation).subscribe(res => {
        if (res != null && res != undefined) {

          this.Pocreationresultobj = res;
          if (this.Pocreationresultobj.ResponseData.success_bit == "True") {
            this.spinner.hide();
            if (this.Pocreationresultobj.ResponseData.invoiceno > 0) {
              this.subscription.add(this.translateapi.get(['Messages.POCreated']).subscribe((translations) => {

                this.showMsg = translations['Messages.POCreated'] + ':' + this.Pocreationresultobj.ResponseData.invoiceno;
                this.toastr.success(this.showMsg, "", {//By case id-12630
                  timeOut: 5000,//By case id-12630
                  disableTimeOut: false,//By case id-12630
                  closeButton: true
                })
                this.getOthersDetails(this.orderno, this.othersDetail.ResponseData.GUIDItinerary);
              }));
              this.pocreated = false;
            }
            if (this.Pocreationresultobj.ResponseData.message == "PO Already Created") {
              this.spinner.hide();
              this.showMsg = "";
              this.subscription.add(this.translateapi.get(['Messages.POAlreadyCreated']).subscribe((translations) => {

                this.showMsg = translations['Messages.POAlreadyCreated'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 0,
                  disableTimeOut: true,
                  closeButton: true
                })

              }));

            }
          }
          else {
            this.spinner.hide();
            this.showMsg = "";
            if (this.Pocreationresultobj.ResponseData.message == "PO Already Created") {
              this.showMsg = "";
              this.subscription.add(this.translateapi.get(['Messages.POAlreadyCreated']).subscribe((translations) => {

                this.showMsg = translations['Messages.POAlreadyCreated'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 0,
                  disableTimeOut: true,
                  closeButton: true
                })

              }));

            }
            else {
              this.spinner.hide();
              this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations) => {

                this.showMsg = translations['Messages.Failed'];
                this.toastr.error(this.showMsg, "", {
                  timeOut: 0,
                  disableTimeOut: true,
                  closeButton: true
                })

              }));
            }

          }
        }
        else {
          this.spinner.hide();
        }
      }
      ));
    }
    else {
      this.spinner.hide();
      this.ShowPermissionMessageForm('01.12.01.26');//339
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.26');
    }

  }

  async onDeletePO() {
    this.spinner.show();
    if (Boolean(await this.commonservice.checkPermission1('01.12.01.27')) == true) {
      this.subscription.add(this.api.getOthers(this.orderno, this.othersDetail.ResponseData.GUIDItinerary, true)
        .subscribe(data => {
          if (data != null && data != undefined) {
            this.othersDetail = data;
          }

        }));

      this.deletePO = new DeletePO();
      this.deletePO.Language = this.language;
      this.deletePO.FolderNo = this.orderno;
      this.deletePO.PONO = this.othersDetail.ResponseData.PONO;
      this.deletePO.POYearId = this.othersDetail.ResponseData.PONOYEARID;
      this.deletePO.Guid = this.othersDetail.ResponseData.GUIDItinerary;
      this.subscription.add(this.apiPO.DeletePO(this.deletePO).subscribe(res => {
        if (res != null && res != undefined) {

          this.DeleteInvoiceResponse = res;
          this.result = this.DeleteInvoiceResponse.ResponseData["message"];

          if (this.result.includes("PO Deleted:")) {
            this.spinner.hide();
            this.pocreated = true;
            this.toastr.success(this.result, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          }
          else {
            this.spinner.hide();
            this.toastr.error(this.result, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })

          }
        }
        else {
          this.spinner.hide();
        }
      }));
    }
    else {
      this.spinner.hide();
      this.ShowPermissionMessageForm('01.12.01.27');//339
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.27');
    }
  }
  formEditableCheck() {
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;
    }
    else {
      this.formeditable = false;
      this.createinvoiceanddeleteinvoice = false;
    }
    if (this.folderStatus == "Cancelled" || this.folderStatus == "Refund" || this.folderStatus == "Partial Refund")//By Caseid-12780
    {
      this.formeditable = false;
      this.FolderHideStatus = true;//12780
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  public permissionmessageformopened: any;//Caseid-339
  public PermissionMessageId: any;//Caseid-339

  public PermissionFormClosing(status: any)//339
  {
    if (status == "EmailSendSuccesfully")//339
    {
      this.permissionmessageformopened = false;
    }
  }

  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;//Caseid-339
    this.PermissionMessageId = permissionid;//Caseid-339
  }
  onCopy()
  {
    debugger;
this.spinner.show();
   this.objFolderPrice.CopyProductDetails(this.orderno,this.othersDetail.ResponseData.GUIDItinerary,"Others").subscribe(x=>{
    debugger;
    this.spinner.hide();
    if(x.ResponseData.value==1)
{
  this.router.navigate([
    "/PenAir/Product/OthersDetailsEdit",
    this.orderno,
    x.ResponseData.GUIDItinerary
  ]);
}

   });
  }
}

