import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { CommonserviceService } from "../Services/commonservice.service";
import { LookUpDetailsService } from '../Services/look-up-details.service';
import { ShareDataService } from '../Services/share-data.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LookUpDetails } from '../Services/look-up-details';
import { DomSanitizer } from "@angular/platform-browser";
import { State, process } from "@progress/kendo-data-query";
import { exportPDF, Group } from "@progress/kendo-drawing";
import { Workbook, ExcelExportData } from "@progress/kendo-angular-excel-export";
import { EmailService } from "../Services/email.service";
import { saveAs } from "@progress/kendo-file-saver";
import { IntlService } from "@progress/kendo-angular-intl";
import { FileService } from "../Services/file.service";
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { FlightBookingDetailsService } from '../Services/flight-booking-details.service';
import { FlightBookingDetailsDTO } from "../Models/flight-booking-details";
import { PageSizeItem } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-flight-booking-details-report',
  templateUrl: './flight-booking-details-report.component.html',
  styleUrls: ['./flight-booking-details-report.component.scss']
})
export class FlightBookingDetailsReportComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  @ViewChild("pdf", { read: false, static: false }) pdf;
  constructor(
    private lookupApi: LookUpDetailsService,
    public intl: IntlService,
    public common: CommonserviceService,
    public apiShared: ShareDataService,
    public email: EmailService,
    private sanitizer: DomSanitizer,
    public FlightBookingService: FlightBookingDetailsService,
    private translateapi: TranslateService,
    private toastr: ToastrService,
    private emailapi: FileService,
    private router: Router
  ) { this.allData = this.allData.bind(this); }
  opentemplate: any = false;
  TempList: Array<LookUpDetails["ResponseData"]> = [];
  public TemplateSelectedItem: LookUpDetails["ResponseData"];
  RptHdrSel_TemplateName: any = "";
  RptHdrSel_AutoNo: any = '';
  submitted: any = false;
  userID: any = 0;
  selectAll: any = false;
  showFilter = false;
  FolderNoChk: any = false;
  OrderNoChk: any = false;
  DateOfIssueChk: any = false;
  PaxNameChk: any = false;
  PassportChk: any = false;
  DateofBirthChk: any = false;
  PassportIssueDateChk: any = false;
  PNRChk: any = false;
  OutboundTravelDateChk: any = false;
  FltNoOutboundChk: any = false;
  OutboundOriginChk: any = false;
  OutboundDestinationChk: any = false;
  OutboundDepTimeChk: any = false;
  OutboundArrTimeChk: any = false;
  InboundTravelDateChk: any = false;
  FltNoInboundChk: any = false;
  InboundOriginChk: any = false;
  InboundDestinationChk: any = false;
  InboundDepTimeChk: any = false;
  InboundArrTimeChk: any = false;
  duplicatetemp: any = false;
  public pdftop: any;

  FOLDERNOHidden: any = false;
  OrderNoHidden: any = false;
  DateOfIssueHidden: any = false;
  PaxNameHidden: any = false;
  PassportHidden: any = false;
  DateofBirthHidden: any = false;
  PassportIssueDateHidden: any = false;
  PNRHidden: any = false;
  OutboundTravelDateHidden: any = false;
  FltNoOutboundSHidden: any = false;
  OutboundOriginHidden: any = false;
  OutboundDestinationHidden: any = false;
  OutboundDepTimeHidden: any = false;
  OutboundArrTimeHidden: any = false;
  InboundTravelDateHidden: any = false;
  FltNoInboundHidden: any = false;
  InboundOriginHidden: any = false;
  InboundDestinationHidden: any = false;
  InboundDepTimeHidden: any = false;
  InboundArrTimeHidden: any = false;
  public opened: any = false;

  public showMsg: any;

  actionsLayout: any;
  public column: any = "FolderNo,OrderNo,DateOfIssue,PaxName,Passport,DateofBirth,PassportIssueDate,PNR,OutboundTravelDate,FltNoOutbound,OutboundOrigin,OutboundDestination,OutboundDepTime,OutboundArrTime,InboundTravelDate,FltNoInbound,InboundOrigin,InboundDestination,InboundDepTime,InboundArrTime";
  public state: State = {
    skip: 0,
    take: 20,
    // group: [{ field: "GroupBy" }]
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public gridData: GridDataResult = process([], this.state);
  public pageSizes: (PageSizeItem | number)[] = [20, 50, 100, {
    text: 'All',
    value: 'all'
  }];
  equals = "equals";
  fromId: string;
  bccId: string;
  formData: FlightBookingDetailsDTO;
  ngOnInit() {
    this.email.Isgridpdf = false;
    this.userID = +this.apiShared.uid;
    this.formData = this.FlightBookingService.formData;
    this.subscription.add(
      this.emailapi.GetReportEmail(this.userID).subscribe(data => {
        (this.fromId = data.ResponseData.FROMEMAIL),
          (this.bccId = data.ResponseData.DEFAULTBCCMAIL);
      })
    );

    this.FlightBookingService.formDataHeader.RptHdrSel_UserId = this.userID;
    this.FlightBookingService.formDataHeader.RptHdrSel_ModuleName =
      "PassengerBookingDetailsReport";
    debugger;
    sessionStorage.setItem('Componentname', "PassengerBookingDetailsRpt");
    if (this.FlightBookingService.formDataHeader.TemplateSelectedItem != null && this.FlightBookingService.formDataHeader.TemplateSelectedItem != undefined) {
      //this.templateChange(this.FlightBookingService.formDataHeader.TemplateSelectedItem);
      this.LoadReportColumn();
    }

    debugger;
    if (this.FlightBookingService.griddata != undefined && this.FlightBookingService.griddata.length > 0) {
      for (var obj of this.FlightBookingService.griddata) {
        debugger;

        if (obj.DateOfIssue && obj.DateOfIssue != '') {

          obj.DateOfIssue = new Date(obj.DateOfIssue);
        }
        else {
          obj.DateOfIssue = null;

        }

        if (obj.DateofBirth && obj.DateofBirth != '') {
          obj.DateofBirth = new Date(obj.DateofBirth);
        }
        else {

          obj.DateofBirth = null;

        }
        if (obj.PassportIssueDate && obj.PassportIssueDate != '') {
          obj.PassportIssueDate = new Date(obj.PassportIssueDate);
        }
        else {
          obj.PassportIssueDate = null;

        }
        if (obj.OutboundTravelDate && obj.OutboundTravelDate != '') {
          obj.OutboundTravelDate = new Date(obj.OutboundTravelDate);
        }
        else {
          obj.OutboundTravelDate = null;

        }
        if (obj.InboundTravelDate && obj.InboundTravelDate != '') {
          obj.InboundTravelDate = new Date(obj.InboundTravelDate);
        }
        else {
          obj.InboundTravelDate = null;

        }

      }
    }
    this.gridData = process(this.FlightBookingService.griddata, this.state);
    if (this.FlightBookingService.formData.ExportPDFParam.length != undefined) {
      if (this.FlightBookingService.formData.ExportPDFParam.length > 0) {
        if (this.FlightBookingService.formData.ExportPDFParam.length <= 5) {
          this.pdftop = "3cm";
        }
        else if (this.FlightBookingService.formData.ExportPDFParam.length <= 13) {
          this.pdftop = "8cm";
        }
      }
    }
  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: this.FlightBookingService.griddata
    };
    return result;
  }

  public LoadTemplate() {
    debugger;

    this.subscription.add(
      this.FlightBookingService.GetTemplate().subscribe(data => {
        if (data != null && data != undefined) {
          debugger;
          this.TempList = data["ResponseData"];
          if (this.FlightBookingService.formDataHeader.RptHdrSel_AutoNo > 0) {
            this.TemplateSelectedItem = this.TempList.find(
              x =>
                x.UsrCode ==
                this.FlightBookingService.formDataHeader.RptHdrSel_AutoNo
            );

          }
          else {
            debugger;
            this.TemplateSelectedItem = this.TempList[0];

          }

        }
      })
    );
  }
  onSave() {
    this.submitted = true;
    this.userID = +this.apiShared.uid;
    if (this.FlightBookingService.formDataHeader.RptHdrSel_AutoNo == 0) {

      if (
        this.RptHdrSel_TemplateName == null ||
        this.RptHdrSel_TemplateName == undefined ||
        this.RptHdrSel_TemplateName == ""
      ) {
        return;
      } else {
        if (this.selectAll == true) {
          this.column = "FolderNo,OrderNo,DateOfIssue,PaxName,Passport,DateofBirth,PassportIssueDate,PNR,OutboundTravelDate,FltNoOutbound,OutboundOrigin,OutboundDestination,OutboundDepTime,OutboundArrTime,InboundTravelDate,FltNoInbound,InboundOrigin,InboundDestination,InboundDepTime,InboundArrTime";
        } else {
          this.column = "";

          if (this.FolderNoChk == true) {
            this.column = this.column + ",FolderNo";
          }
          if (this.OrderNoChk == true) {
            this.column = this.column + ",OrderNo";
          }
          if (this.DateOfIssueChk == true) {
            this.column = this.column + ",DateOfIssue";
          }
          if (this.PaxNameChk == true) {
            this.column = this.column + ",PaxName";
          }
          if (this.PassportChk == true) {
            this.column = this.column + ",Passport";
          }
          if (this.DateofBirthChk == true) {
            this.column = this.column + ",DateofBirth";
          }
          if (this.PassportIssueDateChk == true) {
            this.column = this.column + ",PassportIssueDate";
          }
          if (this.PNRChk == true) {
            this.column = this.column + ",PNR";
          }
          if (this.OutboundTravelDateChk == true) {
            this.column = this.column + ",OutboundTravelDate";
          }
          if (this.FltNoOutboundChk == true) {
            this.column = this.column + ",FltNoOutbound";
          }
          if (this.OutboundOriginChk == true) {
            this.column = this.column + ",OutboundOrigin";
          }
          if (this.OutboundDestinationChk == true) {
            this.column = this.column + ",OutboundDestination";
          }
          if (this.OutboundDepTimeChk == true) {
            this.column = this.column + ",OutboundDepTime";
          }
          if (this.OutboundArrTimeChk == true) {
            this.column = this.column + ",OutboundArrTime";
          }


          if (this.InboundTravelDateChk == true) {
            this.column = this.column + ",InboundTravelDate";
          }
          if (this.FltNoInboundChk == true) {
            this.column = this.column + ",FltNoInbound";
          }
          if (this.InboundOriginChk == true) {
            this.column = this.column + ",InboundOrigin";
          }
          if (this.InboundDestinationChk == true) {
            this.column = this.column + ",InboundDestination";
          }
          if (this.InboundDepTimeChk == true) {
            this.column = this.column + ",InboundDepTime";
          }
          if (this.InboundArrTimeChk == true) {
            this.column = this.column + ",InboundArrTime";
          }


          this.column = this.column.replace(/,\s*$/, "");
          this.column = this.column.replace(/^,/, "");
        }
        this.FlightBookingService.formDataHeader.RptHdrSel_UserId = this.userID;
        this.FlightBookingService.formDataHeader.RptHdrSel_ModuleName =
          "PassengerBookingDetailsReport";
        this.FlightBookingService.formDataHeader.RptHdrSel_ColumnHeader = this.column;
        this.FlightBookingService.formDataHeader.RptHdrSel_TemplateName = this.RptHdrSel_TemplateName;
        this.FlightBookingService.formDataHeader.RptHdrSel_CustCo = 0;
        this.FlightBookingService.formDataHeader.RptHdrSel_AutoNo = 0;
        this.FlightBookingService.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
          new Date()
        );
        this.subscription.add(
          this.FlightBookingService.saveOrUpdateReportHeader().subscribe(data => {
            debugger;
            if (data != undefined && data != null) {
              if (data["ResponseData"]["RptHdrSel_AutoNo"] > 0) {
                this.FlightBookingService.formDataHeader.RptHdrSel_AutoNo =
                  data["ResponseData"]["RptHdrSel_AutoNo"];
                this.LoadTemplate();
                this.LoadReportColumn();
                this.opentemplate = false;
                this.duplicatetemp = false;
              } else {
                this.duplicatetemp = true;
              }
            }
          })
        );
      }
    }

    else {
      if (this.RptHdrSel_TemplateName == null || this.RptHdrSel_TemplateName == undefined || this.RptHdrSel_TemplateName == "") {
        return
      }
      else if (this.selectAll == true) {
        this.column = "FolderNo,OrderNo,DateOfIssue,PaxName,Passport,DateofBirth,PassportIssueDate,PNR,OutboundTravelDate,FltNoOutbound,OutboundOrigin,OutboundDestination,OutboundDepTime,OutboundArrTime,InboundTravelDate,FltNoInbound,InboundOrigin,InboundDestination,InboundDepTime,InboundArrTime";
      }
      else {
        this.column = "";

        if (this.FolderNoChk == true) {
          this.column = this.column + ",FolderNo";
        }
        if (this.OrderNoChk == true) {
          this.column = this.column + ",OrderNo";
        }
        if (this.DateOfIssueChk == true) {
          this.column = this.column + ",DateOfIssue";
        }
        if (this.PaxNameChk == true) {
          this.column = this.column + ",PaxName";
        }
        if (this.PassportChk == true) {
          this.column = this.column + ",Passport";
        }
        if (this.DateofBirthChk == true) {
          this.column = this.column + ",DateofBirth";
        }
        if (this.PassportIssueDateChk == true) {
          this.column = this.column + ",PassportIssueDate";
        }
        if (this.PNRChk == true) {
          this.column = this.column + ",PNR";
        }
        if (this.OutboundTravelDateChk == true) {
          this.column = this.column + ",OutboundTravelDate";
        }
        if (this.FltNoOutboundChk == true) {
          this.column = this.column + ",FltNoOutbound";
        }
        if (this.OutboundOriginChk == true) {
          this.column = this.column + ",OutboundOrigin";
        }
        if (this.OutboundDestinationChk == true) {
          this.column = this.column + ",OutboundDestination";
        }
        if (this.OutboundDepTimeChk == true) {
          this.column = this.column + ",OutboundDepTime";
        }
        if (this.OutboundArrTimeChk == true) {
          this.column = this.column + ",OutboundArrTime";
        }


        if (this.InboundTravelDateChk == true) {
          this.column = this.column + ",InboundTravelDate";
        }
        if (this.FltNoInboundChk == true) {
          this.column = this.column + ",FltNoInbound";
        }
        if (this.InboundOriginChk == true) {
          this.column = this.column + ",InboundOrigin";
        }
        if (this.InboundDestinationChk == true) {
          this.column = this.column + ",InboundDestination";
        }
        if (this.InboundDepTimeChk == true) {
          this.column = this.column + ",InboundDepTime";
        }
        if (this.InboundArrTimeChk == true) {
          this.column = this.column + ",InboundArrTime";
        }
        this.column = this.column.replace(/,\s*$/, "");
        this.column = this.column.replace(/^,/, "");
      }
      this.FlightBookingService.formDataHeader.RptHdrSel_UserId = this.userID;
      this.FlightBookingService.formDataHeader.RptHdrSel_ModuleName =
        "PassengerBookingDetailsReport";
      this.FlightBookingService.formDataHeader.RptHdrSel_ColumnHeader = this.column;
      this.FlightBookingService.formDataHeader.RptHdrSel_TemplateName = this.RptHdrSel_TemplateName;
      this.FlightBookingService.formDataHeader.RptHdrSel_CustCo = 0;
      this.FlightBookingService.formDataHeader.RptHdrSel_AutoNo = this.RptHdrSel_AutoNo;
      this.FlightBookingService.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
        new Date()
      );
      this.subscription.add(
        this.FlightBookingService.saveOrUpdateReportHeader().subscribe(data => {
          debugger;
          if (data != undefined && data != null) {
            if (data["ResponseData"]["RptHdrSel_AutoNo"] > 0) {
              this.FlightBookingService.formDataHeader.RptHdrSel_AutoNo =
                data["ResponseData"]["RptHdrSel_AutoNo"];
              this.LoadTemplate();
              this.LoadReportColumn();
              this.opentemplate = false;
              this.duplicatetemp = false;
            } else {
              this.duplicatetemp = true;
            }
          }
        })
      );

    }

  }
  public dataStateChange(state: DataStateChangeEvent): void {
    debugger;
    this.state = state;
    this.gridData = process(this.FlightBookingService.griddata, this.state);

  }
  public LoadReportColumn() {

    this.FlightBookingService.formDataHeader.RptHdrSel_UserId = this.userID;
    this.FlightBookingService.formDataHeader.RptHdrSel_ModuleName =
      "PassengerBookingDetailsReport";

    this.subscription.add(
      this.FlightBookingService.GetGridColumn().subscribe(data => {
        debugger;
        if (
          data != undefined &&
          data != null &&
          data["ResponseData"] != undefined
        ) {
          this.TemplateSelectedItem = this.TempList.find(
            x => x.UsrCode == data["ResponseData"].RptHdrSel_AutoNo
          );

          if (data["ResponseData"].RptHdrSel_ColumnHeader != null) {
            var datas = data["ResponseData"].RptHdrSel_ColumnHeader.split(",");


            if (datas.includes("FolderNo")) {
              this.FOLDERNOHidden = false;
            } else {
              this.FOLDERNOHidden = true;
            }
            if (datas.includes("OrderNo")) {
              this.OrderNoHidden = false;
            } else {
              this.OrderNoHidden = true;
            }
            if (datas.includes("DateOfIssue")) {
              this.DateOfIssueHidden = false;
            } else {
              this.DateOfIssueHidden = true;
            }
            if (datas.includes("PaxName")) {
              this.PaxNameHidden = false;
            } else {
              this.PaxNameHidden = true;
            }
            if (datas.includes("Passport")) {
              this.PassportHidden = false;
            } else {
              this.PassportHidden = true;
            }

            if (datas.includes("DateofBirth")) {
              this.DateofBirthHidden = false;
            } else {
              this.DateofBirthHidden = true;
            }
            if (datas.includes("PassportIssueDate")) {
              this.PassportIssueDateHidden = false;
            } else {
              this.PassportIssueDateHidden = true;
            }
            if (datas.includes("PNR")) {
              this.PNRHidden = false;
            } else {
              this.PNRHidden = true;
            }
            if (datas.includes("OutboundTravelDate")) {
              this.OutboundTravelDateHidden = false;
            } else {
              this.OutboundTravelDateHidden = true;
            }

            if (datas.includes("FltNoOutbound")) {
              this.FltNoOutboundSHidden = false;
            } else {
              this.FltNoOutboundSHidden = true;
            }

            if (datas.includes("OutboundOrigin")) {
              this.OutboundOriginHidden = false;
            } else {
              this.OutboundOriginHidden = true;
            }



            if (datas.includes("OutboundDestination")) {
              this.OutboundDestinationHidden = false;
            } else {
              this.OutboundDestinationHidden = true;
            }


            if (datas.includes("OutboundDepTime")) {
              this.OutboundDepTimeHidden = false;
            } else {
              this.OutboundDepTimeHidden = true;
            }
            if (datas.includes("OutboundArrTime")) {
              this.OutboundArrTimeHidden = false;
            } else {
              this.OutboundArrTimeHidden = true;
            }

            if (datas.includes("InboundTravelDate")) {
              this.InboundTravelDateHidden = false;
            } else {
              this.InboundTravelDateHidden = true;
            }

            if (datas.includes("FltNoInbound")) {
              this.FltNoInboundHidden = false;
            } else {
              this.FltNoInboundHidden = true;
            }
            if (datas.includes("InboundOrigin")) {
              this.InboundOriginHidden = false;
            } else {
              this.InboundOriginHidden = true;
            }
            if (datas.includes("InboundDestination")) {
              this.InboundDestinationHidden = false;
            } else {
              this.InboundDestinationHidden = true;
            }
            if (datas.includes("InboundDepTime")) {
              this.InboundDepTimeHidden = false;
            } else {
              this.InboundDepTimeHidden = true;
            }
            if (datas.includes("InboundArrTime")) {
              this.InboundArrTimeHidden = false;
            } else {
              this.InboundArrTimeHidden = true;
            }


          }
        } else {
          this.enableAllColumn();
        }
      })
    );
  }
  public selectAllColumn() {
    if (this.selectAll == true) {
      this.FolderNoChk = true;
      this.OrderNoChk = true;
      this.DateOfIssueChk = true;
      this.PaxNameChk = true;
      this.PassportChk = true;
      this.DateofBirthChk = true;
      this.PassportIssueDateChk = true;
      this.PNRChk = true;
      this.OutboundTravelDateChk = true;
      this.FltNoOutboundChk = true;
      this.OutboundOriginChk = true;
      this.OutboundDestinationChk = true;
      this.OutboundDepTimeChk = true;
      this.OutboundArrTimeChk = true;
      this.InboundTravelDateChk = true;
      this.FltNoInboundChk = true;
      this.InboundOriginChk = true;
      this.InboundDestinationChk = true;
      this.InboundDepTimeChk = true;
      this.InboundArrTimeChk = true;

    } else {


      this.FolderNoChk = false;
      this.OrderNoChk = false;
      this.DateOfIssueChk = false;
      this.PaxNameChk = false;
      this.PassportChk = false;
      this.DateofBirthChk = false;
      this.PassportIssueDateChk = false;
      this.PNRChk = false;
      this.OutboundTravelDateChk = false;
      this.FltNoOutboundChk = false;
      this.OutboundOriginChk = false;
      this.OutboundDestinationChk = false;
      this.OutboundDepTimeChk = false;
      this.OutboundArrTimeChk = false;
      this.InboundTravelDateChk = false;
      this.FltNoInboundChk = false;
      this.InboundOriginChk = false;
      this.InboundDestinationChk = false;
      this.InboundDepTimeChk = false;
      this.InboundArrTimeChk = false;
    }
  }
  selectAlldisable() {

    if (
      (this.FolderNoChk == true) &&
      (this.OrderNoChk == true) &&
      (this.DateOfIssueChk == true) &&
      (this.PaxNameChk == true) &&
      (this.PassportChk == true) &&
      (this.DateofBirthChk == true) &&
      (this.PassportIssueDateChk == true) &&
      (this.PNRChk == true) &&
      (this.OutboundTravelDateChk == true) &&
      (this.FltNoOutboundChk == true) &&
      (this.OutboundOriginChk == true) &&
      (this.OutboundDestinationChk == true) &&
      (this.OutboundDepTimeChk == true) &&
      (this.OutboundArrTimeChk == true) &&
      (this.InboundTravelDateChk == true) &&
      (this.FltNoInboundChk == true) &&
      (this.InboundOriginChk == true) &&
      (this.InboundDestinationChk == true) &&
      (this.InboundDepTimeChk == true) &&
      (this.InboundArrTimeChk == true)) {
      this.selectAll = true;
    } else {
      this.selectAll = false;

    }

  }
  EditTemplate() {
    debugger;

    if (this.TemplateSelectedItem == null || this.TemplateSelectedItem == undefined) {
      this.subscription.add(this.translateapi.get(['Messages.template_required']).subscribe((translations) => {

        this.showMsg = translations['Messages.template_required'];;
        this.toastr.error(this.showMsg, "", {
          timeOut: 3000,
          disableTimeOut: false,
          closeButton: true
        })

      }));

      return;
    }
    this.GetVarientTemplateForEdit();

  }
  GetVarientTemplateForEdit() {

    this.FlightBookingService.GetVarientTemplateForEdit(this.TemplateSelectedItem.Code, this.FlightBookingService.formDataHeader.RptHdrSel_ModuleName).subscribe(data => {
      this.opentemplate = true;
      this.RptHdrSel_TemplateName = data["ResponseData"].RptHdrSel_TemplateName;
      this.RptHdrSel_AutoNo = data["ResponseData"].RptHdrSel_AutoNo;
      this.FlightBookingService.formDataHeader.RptHdrSel_AutoNo = this.RptHdrSel_AutoNo;
      if (data != null && data != undefined) {

        if (data["ResponseData"].RptHdrSel_ColumnHeader != null) {
          var datas = data["ResponseData"].RptHdrSel_ColumnHeader.split(',');
          "FolderNo,OrderNo,DateOfIssue,PaxName,Passport,DateofBirth,PassportIssueDate,PNR,OutboundTravelDate,FltNoOutbound,OutboundOrigin,OutboundDestination,OutboundDepTime,OutboundArrTime,InboundTravelDate,FltNoInbound,InboundOrigin,InboundDestination,InboundDepTime,InboundArrTime";
          if (datas.includes('FolderNo')) {
            this.FolderNoChk = true;
          }
          else {
            this.FolderNoChk = false;
          }
          if (datas.includes('OrderNo')) {
            this.OrderNoChk = true;
          }
          else {
            this.OrderNoChk = false;
          }
          if (datas.includes('DateOfIssue')) {
            this.DateOfIssueChk = true;
          }
          else {
            this.DateOfIssueChk = false;
          }
          if (datas.includes('PaxName')) {
            this.PaxNameChk = true;
          }
          else {
            this.PaxNameChk = false;
          }
          if (datas.includes('Passport')) {
            this.PassportChk = true;
          }
          else {
            this.PassportChk = false;
          }
          if (datas.includes('DateofBirth')) {
            this.DateofBirthChk = true;
          }
          else {
            this.DateofBirthChk = false;
          }
          if (datas.includes('PassportIssueDate')) {
            this.PassportIssueDateChk = true;
          }
          else {
            this.PassportIssueDateChk = false;
          }

          if (datas.includes('PNR')) {
            this.PNRChk = true;
          }
          else {
            this.PNRChk = false;
          }
          if (datas.includes('OutboundTravelDate')) {
            this.OutboundTravelDateChk = true;
          }
          else {
            this.OutboundTravelDateChk = false;
          }
          if (datas.includes('FltNoOutbound')) {
            this.FltNoOutboundChk = true;
          }
          else {
            this.FltNoOutboundChk = false;
          }
          if (datas.includes('OutboundOrigin')) {
            this.OutboundOriginChk = true;
          }
          else {
            this.OutboundOriginChk = false;
          }
          if (datas.includes('OutboundDestination')) {
            this.OutboundDestinationChk = true;
          }
          else {
            this.OutboundDestinationChk = false;
          }
          if (datas.includes('OutboundDepTime')) {
            this.OutboundDepTimeChk = true;
          }
          else {
            this.OutboundDepTimeChk = false;
          }
          if (datas.includes('OutboundArrTime')) {
            this.OutboundArrTimeChk = true;
          }
          else {
            this.OutboundArrTimeChk = false;
          }
          if (datas.includes('InboundTravelDate')) {
            this.InboundTravelDateChk = true;
          }
          else {
            this.InboundTravelDateChk = false;
          }
          if (datas.includes('FltNoInbound')) {
            this.FltNoInboundChk = true;
          }
          else {
            this.FltNoInboundChk = false;
          }
          if (datas.includes('InboundOrigin')) {
            this.InboundOriginChk = true;
          }
          else {
            this.InboundOriginChk = false;
          }
          if (datas.includes('InboundDestination')) {
            this.InboundDestinationChk = true;
          }
          else {
            this.InboundDestinationChk = false;
          }
          if (datas.includes('InboundDepTime')) {
            this.InboundDepTimeChk = true;
          }
          else {
            this.InboundDepTimeChk = false;
          }
          if (datas.includes('InboundArrTime')) {
            this.InboundArrTimeChk = true;
          }
          else {
            this.InboundArrTimeChk = false;
          }

        }

      }
    })
  }


  templateChange(value) {

    debugger;
    if (value != undefined) {
      this.FlightBookingService.formDataHeader.RptHdrSel_AutoNo = value.UsrCode;
      this.FlightBookingService.formDataHeader.TemplateSelectedItem = value.Code;
      this.LoadReportColumn();
    }
  }
  public enableAllColumn() {
    this.FOLDERNOHidden = false;
    this.OrderNoHidden = false;
    this.DateOfIssueHidden = false;
    this.PaxNameHidden = false;
    this.PassportHidden = false;
    this.DateofBirthHidden = false;
    this.PassportIssueDateHidden = false;
    this.PNRHidden = false;
    this.OutboundTravelDateHidden = false;
    this.FltNoOutboundSHidden = false;
    this.OutboundOriginHidden = false;
    this.OutboundDestinationHidden = false;
    this.OutboundDepTimeHidden = false;
    this.OutboundArrTimeHidden = false;
    this.InboundTravelDateHidden = false;
    this.FltNoInboundHidden = false;
    this.InboundOriginHidden = false;
    this.InboundDestinationHidden = false;
    this.InboundDepTimeHidden = false;
    this.InboundArrTimeHidden = false;
  }
  async export(pdfComponent: any) {
    let processedUri: any;
    let baseprocess = await pdfComponent
      .export()
      .then((group: Group) => exportPDF(group))
      .then(dataUri => {
        const base64 = dataUri.replace("data:application/pdf;base64,", "");
        processedUri = dataUri;
      });
    const fileObject = await this.dataURLtoFile(
      processedUri,
      "Passenger Booking Details Report.pdf"
    );
    return fileObject;
  }
  async dataURLtoFile(dataurl, filename) {
    let file: any;
    let dataURL = new Promise(resolve => {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let newFile = new File([u8arr], filename, { type: mime });
      file = newFile;
      resolve(newFile);
    });
    this.email.fileurl = file;
    this.email.filename = "Passenger Booking Details Report.pdf";
    this.email.reportname = "Passenger Booking Details Report";
    this.open();
    return file;
  }
  public open() {

    this.opened = true;
  }
  public close(status) {
    this.opened = false;
    this.email.fileurl = null;
    this.email.filename = null;
    this.email.reportname = null;
  }
  EmailPDF() {
    debugger;
    this.email.Isgridpdf = true;
    this.export(this.pdf);
  }
  excel() {

    this.email.filename = "Passenger Booking Details.xlsx";
    this.email.reportname = "Passenger Booking Details";

    this.open();
    //args.preventDefault();
  }
  public async onExcelExport2(args: any) {
    debugger;

    args.preventDefault();

    const observables = [];
    let workbook = args.workbook;

    let rows = workbook.sheets[0].rows;

    let headerOptions = rows[0].cells[0];
    var sheet = workbook.sheets[0];
    var reader = new FileReader();
    if ((this.FlightBookingService.formData.Supplier != undefined && this.FlightBookingService.formData.Supplier != null && this.FlightBookingService.formData.Supplier != "")) {
      this.common.generateExcelFilterheader(sheet, 'Supplier', this.FlightBookingService.formData.Supplier.toString(), '');

    }
    if ((this.FlightBookingService.formData.Destination != undefined && this.FlightBookingService.formData.Destination != null && this.FlightBookingService.formData.Destination != "")) {
      this.common.generateExcelFilterheader(sheet, 'Destination', this.FlightBookingService.formData.Destination.toString(), '');

    }

    if ((this.FlightBookingService.formData.TravelFromdate != undefined && this.FlightBookingService.formData.TravelFromdate != null && this.FlightBookingService.formData.TravelFromdate != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'Travel Date', this.common.ConvertToLocaleDate(this.FlightBookingService.formData.TravelFromdate), this.common.ConvertToLocaleDate(this.FlightBookingService.formData.TravelTodate));
    }
    if ((this.FlightBookingService.formData.CreationFromdate != undefined && this.FlightBookingService.formData.CreationFromdate != null && this.FlightBookingService.formData.CreationFromdate != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'Creation  Date', this.common.ConvertToLocaleDate(this.FlightBookingService.formData.CreationFromdate), this.common.ConvertToLocaleDate(this.FlightBookingService.formData.CreationTodate));
    }

    if ((this.email.reportname != undefined)) {
      this.common.generateExcelMainheader(sheet, this.email.reportname);
    }
    await this.common.SetCompanyheaderExcelheader(sheet)
    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      saveAs(dataUrl, "Passenger Booking  Report.xlsx");
      this.email.fileurl = this.dataURItoBlob(dataUrl);
    });
  }
  dataURItoBlob(dataURI) {

    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }
  public EmailFormClosing(
    status: any
  ) {
    if (status == "EmailSendSuccesfully" || status == 'Closed') {

      this.opened = false;
    }
  }
  public onTabSelect(e) {

  }
  Print() {
    //this.pdf.saveAs("Passenger Booking  Report.pdf");
  }
  async dataStateChangeColumnVisible(value: any) {
    debugger;
    this.column = "FolderNo,OrderNo,DateOfIssue,PaxName,Passport,DateofBirth,PassportIssueDate,PNR,OutboundTravelDate,FltNoOutbound,OutboundOrigin,OutboundDestination,OutboundDepTime,OutboundArrTime,InboundTravelDate,FltNoInbound,InboundOrigin,InboundDestination,InboundDepTime,InboundArrTime";
    if (value["columns"] != null && value["columns"].length > 0) {
      value["columns"].forEach(obj => {
        if (obj["hidden"] == true) {
          this.column = this.column.replace(obj["field"], "");
          this.column = this.column.replace(/,\s*$/, "");
          this.column = this.column.replace(/^,/, "");
        } else {
        }
      });
      if (this.TemplateSelectedItem != undefined) {
        this.FlightBookingService.formDataHeader.RptHdrSel_AutoNo = this.TemplateSelectedItem.UsrCode;
        this.FlightBookingService.formDataHeader.RptHdrSel_UserId = this.userID;
        this.FlightBookingService.formDataHeader.RptHdrSel_ModuleName =
          "PassengerBookingDetailsReport";
        this.FlightBookingService.formDataHeader.RptHdrSel_ColumnHeader = this.column;
        this.FlightBookingService.formDataHeader.RptHdrSel_TemplateName =
          "PassengerBookingDetailsReport";
        this.FlightBookingService.formDataHeader.RptHdrSel_CustCo = 0;
        this.FlightBookingService.formDataHeader.RptHdrSel_Lupdate = this.common.ConvertToLocaleDate(
          new Date()
        );
        this.subscription.add(
          this.FlightBookingService
            .saveOrUpdateReportHeader()
            .subscribe(data => { })
        );
      } else {

      }
    }
  }
  CreateTemplate() {
    debugger;
    this.FolderNoChk = false;
    this.OrderNoChk = false;
    this.DateOfIssueChk = false;
    this.PaxNameChk = false;
    this.PassportChk = false;
    this.DateofBirthChk = false;
    this.PassportIssueDateChk = false;
    this.PNRChk = false;
    this.OutboundTravelDateChk = false;
    this.FltNoOutboundChk = false;
    this.OutboundOriginChk = false;
    this.OutboundDestinationChk = false;
    this.OutboundDepTimeChk = false;
    this.OrderNoChk = false;
    this.OutboundArrTimeChk = false;
    this.InboundTravelDateChk = false;
    this.FltNoInboundChk = false;
    this.InboundOriginChk = false;
    this.InboundDestinationChk = false;
    this.InboundDepTimeChk = false;
    this.InboundArrTimeChk = false;
    this.selectAll = false;
    this.RptHdrSel_TemplateName = null;
    this.submitted = false;
    this.opentemplate = true;
    this.FlightBookingService.formDataHeader.RptHdrSel_AutoNo = 0;
  }
  onCancel() {
    var reporturl = sessionStorage.getItem("menuhighlight");
    this.FlightBookingService.griddata = "";
    if (reporturl != null) {
      this.router.navigate(["/Login"]);
    } else {
      this.router.navigate(["PenAir/PassengerBookingDetailsReport"]);
    }

  }
  closePassBookingReport() {
    this.opentemplate = false;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  onFilterClick() {
    debugger
    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }
}
