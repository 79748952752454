export class FolderSearchCriteriaDTO {
    enableSearch:boolean;
    PageNo :string;
    PageSize:string;
    SearcType:string;
    BookedBy :string;
    FoderStatus:number;
    PayStatus :string;
    Quotesonly:number;
     Foldersearch:boolean;
        Hotelsearch:boolean;
        Carsearch:boolean;
        Railsearch :boolean;
        TransfersSearch :boolean;
        TourSearch :boolean;
        CruiseSearch :boolean;
        InsuranceSearch :boolean;
        OthersSearch :boolean;
        FolderSearchDTO: FolderSearchDTO;  
        HotelSearchDTO:HotelSearchDTO;
        InsuranceSearchDTO:InsuranceSearchDTO;
        CarSearchDTO:CarSearchDTO;
        CruiseSearchDTO:CruiseSearchDTO;
        RailSearchDTO:RailSearchDTO;
        TransfersSearchDTO:TransfersSearchDTO;
        TourSearchDTO:TourSearchDTO;
        OthersSearchDTO:OthersSearchDTO;

}

export class FolderSearchDTO{
    PNR:string;
    AirlinePNR:string;
    InetRef:string;
    FolderNo:string;
    LeadPassenger :string;
    PassengerPhone:string;
    PassengerEmail:string;
    passngerPostCode:string;
    PaxName:string;
     InvoiceNo :string;
     InvAmount1:number;
    InvAmount2 :number;
    TicketNo:string;
    CustCode :number;
    CustName :string;
    CustomerPostcode :string;
    DepDatefrom :string;
    DepDateTo:string;
    YourRef:string;
    Firstname:string;
    Lastname :string;
     DepDatefromAF :Date;
     DepDateToAF:Date;
     OrderNo:string;
     CreationDatefromAF :Date;
     CreationDateToAF:Date;
     CreateDatefrom :string;
     CreateDateTo:string;
}
export class HotelSearchDTO{
    HotelName:string;
    Supplier :string;
    CheckInDatefrom :string;
    CheckInDateTo :string;
    CheckOutDatefrom :string;
    CheckOutDateTo :string;
    Status :string;
    RoomType:string;
    ConfirmationNo :string;
    VoucherNo:string;
    CheckInDatefromAF :Date;
    CheckInDateToAF:Date;
    CheckOutDatefromAF :Date;
    CheckOutDateToAF :Date;
}

export class InsuranceSearchDTO{
   Supplier:string;
    Type:string;
    DateFrom1 :string;
    DateFrom2 :string;
    DateTo1 :string;
    DateTo2 :string;
    Status :string;
    VoucherNo:string;
    DateFrom1AF :Date;
    DateFrom2AF :Date;
    DateTo1AF :Date;
    DateTo2AF :Date;
    CONFIRMATIONNO:string;
    PNUMBER:string;
    BookingDate:Date;
    BookingDateFrom:Date;
    BookingDateTo:Date;
    BookingDateFrom1 :string;
    BookingDateTo2 :string;
}
export class CarSearchDTO{
    Supplier:string;
    PickUp:string;
    DropOff:string;
    Status:string;
    VoucherNo:string;
    Type:string;
    hireFrom1 :string;
    hireFrom2 :string;
    hireTo1 :string;
    hireTo2 :string;
    hireFrom1AF :Date;
    hireFrom2AF :Date;
    hireTo1AF :Date;
    hireTo2AF :Date;
    
 }
 export class CruiseSearchDTO{
     Supplier:string;
     EmbtDateFrom :string;
     EmbtDateTo :string;
     DisembDateFrom :string;
     DisembDateTo :string;
     Status :string;
     VoucherNo:string;
      ConfirmationNo:string;
     Type:string;
     EmbtDateFromDt :Date;
     EmbtDateToDt :Date;
     DisembDateFromDt :Date;
     DisembDateToDt :Date;
 } 
 export class RailSearchDTO{
    Supplier :string;
       
    From :string;
    FromSelected :string;
    To :string;
    Status :string;
    VoucherNo :string;
    TicketDateFrom :Date;
    TicketDateTo :Date;
    DateFrom1 :string;
    DateFrom2 :string;
}
export class TransfersSearchDTO{
     Supplier :string;
     VechicleType :string;       
     From :string;
     To :string;
     Status :string;
     VoucherNo :string;
     DepDateFrom:string;
     DepDateTo:string;
     BookingDateFrom:string;
     BookingDateTo:string;
     DepDateFromAF :Date;
     DepDateToAF :Date;
     BookingDateFromAF :Date;
     BookingDateToAF :Date;
}
export class TourSearchDTO{
    Supplier:string;
    StartDateFrom :string;
    StartDateTo :string;
    EndDateFrom :string;
    EndDateTo :string;
    BookingDateFrom:string;
    BookingDateTo:string;
    Status :string;
    VoucherNo :string; 
    StartDateFromAF :Date;
    StartDateToAF :Date;
    EndDateFromAF :Date;
    EndDateToAF :Date;
    BookingDateFromAF:Date;
    BookingDateToAF:Date;
}
export class OthersSearchDTO{
     Product :string;
     Supplier :string;
     BookingDateFrom :string;
     BookingDateTo :string;
     BookingDateFromDt :Date;
     BookingDateToDt :Date;
     Status :string;
     ConfirmationNo :string;
     CheckOutDateTo:any;
}
export class InboxClosedParameterDTO{
      UserID :any;
      ACTIONID:any;
      LID:any;
          PageNo:string;
         PageSize :string
             Type :number
         IsDetailed :any;
}
