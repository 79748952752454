export class AddExpenseDTO {
    ProductCode: string;
      TCAmount: number;
      TaxCode:number;
      TaxAmount:number;
      Total:number;
    HCAmount:number;
      HCTaxAmount:number;

}
