export class Users {
    IsSuccess: boolean;
    Message: string;
    ResponseData:
        {
            UNAME: string;
            UPWD: string;
            Token: string;
            UID: string;
            Users_AdvPwdWarnDays: number;
            Users_LastPwdUpdatedDate: any;
            File_MaxPwdAge: number;
            ShowPwdExpryMsg: boolean;
            showPwdExpryWarnMsg: boolean;
            MultiUserAllowed: boolean;
            IsUserAlreadyLogin: boolean;
            PwdExpiryDate: Date;
            PwdExpiryInDays: number;
            ErrMsg: string;
            WebAccess: number;
            accessToken: string;
            secretToken: any;
        }
    // constructor(data) {
    //     this.ResponseData.UNAME=data.ResponseData.UNAME;

    // }
}
// export class CurrUser {
//     ResponseData:
//         {
//             UNAME: string;
//             Token: string;
//             UID: string;
//             IsUserAlreadyLogin: boolean;
//             MultiUserAllowed: boolean;
//             BAID: string;
//             BRID: string;
//             //UPWD: string;
//             // Users_AdvPwdWarnDays: number;
//             // Users_LastPwdUpdatedDate: any;
//             // File_MaxPwdAge: number;
//             // ShowPwdExpryMsg: boolean;
//             // showPwdExpryWarnMsg: boolean;
//             // MultiUserAllowed: boolean;
//             // PwdExpiryDate: Date;
//             // PwdExpiryInDays: number;
//             // ErrMsg: string;
//             // WebAccess: number;
//             // accessToken: string;
//             // secretToken: any;
//         }
//     constructor(data) {
//         this.ResponseData.UNAME = data.UNAME;
//         this.ResponseData.Token = data.Token;
//         this.ResponseData.UID = data.UID;
//         this.ResponseData.IsUserAlreadyLogin = data.IsUserAlreadyLogin;
//         this.ResponseData.MultiUserAllowed = data.MultiUserAllowed;
//         this.ResponseData.BAID = data.BAID;
//         this.ResponseData.BRID = data.BAID;
//     }
// }