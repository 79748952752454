export class POCreationDetail {

  id :number;
  folderNo :string;
  projNo :string;
  pType :string;
  postDate :any;
  slNo :number;
  itemNo :number;
  passName :string;
  buyTaxNo:number;
  buyAmount :number;
  tcBuyAmount :number;

  sellAmount :number;
  commAmount :number;
  commonly:string;
  payMode :string;

  supppartyCode :number;
  supplierName:string;

  PMCODE :number;
  Description :string;
  UserId :number;
  POGUIDItinerary:string;
  CurrentDate :any;
  Currency :string;
  ExRate :number;
  BatchNo :string;
  PaymentInclude :number;
  DEFDEPAmount_money :number;
  pTravelCommPostInclude :number;
  pCommissionAmount :number;
  CommissionTaxAmount :number;
  pCommTaxNo :number;
  pPurchaseTaxAmount :number;
  pPurchaseTaxCode :number;
  pSuppInvNo_varchar :string ;
  pParentProcess :string;
  Status:any;//11614
}


export class ProductUpdatePODTO
{
GUIDItinerary:any;  
LOYALTYPOINTS:any; 
LOYALTYPOINTSMEMBNO :any;  
LOYALTYPOINTSBAL :any;
TYPE :any;
MealPlan :any;
FOLDERNO :any;
RDESC :any;
ROOMRDESC :any;
PROJNO :any;
LOYALTYPOINTSAMT :any;

CostingCustomerNetSellPrice:any;
FCSell:any;
CostingCustomerServiceFee:any;
CostingCustomerTaxAmount:any;
CostofSale:any;

BaseFare:any;
SELLAMT:any;
SELLCOMM:any;
FC_SELLTAXAMT:any;
HC_SELLTAXAMT:any;
HCSellTaxAmt:any;
HCSell:any;
HCCommAmt:any;

fCSellTax:any;
SELLVAT:any;
 SELLTAXNO:any;
SELLTAXRATE:any;

 PAYABLE:any;
FC_CIDExRate :any;
}