import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PnrsourcefileService } from '../../Services/pnrsourcefile.service';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { Subject, Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
import { ShareDataService } from '../../Services/share-data.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EnvService } from 'src/env.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GetPNRSourceFileOptionDetails, PNRFileDTO, Pnrsourcefile } from '../../Models/pnrsourcefile';
import { SortDescriptor } from '@progress/kendo-data-query';
@Component({
  selector: 'app-pnr-sourcefile',
  templateUrl: './pnr-sourcefile.component.html',
  styleUrls: ['./pnr-sourcefile.component.scss']
})
export class PnrSourcefileComponent implements OnInit {
  private orderNo=this.route.snapshot.params['orderNo'];
  private subscription: Subscription = new Subscription();
  public gridPNRSourceFileData: Array<Pnrsourcefile> = [];//12695
  public gridData: Array<GetPNRSourceFileOptionDetails["ResponseData"]> = [];//12695
  public pnrFileOpened:any=false;
  public pdfPNRFileOpened:any=false;
  public textPNRFileOpened:any=false;
  systemUrl:string;
  PNRFileURL: SafeResourceUrl;
  public PNRFileleft = -220;
  public PNRFiletop = -100;
  public PNRFilewidth = window.innerWidth-40;
  public PNRFileheight = 950;
  public offset = 50;
  public  windowLeft= this.offset;
  public windowTop = this.offset;
  public showMessage:any;
  public headerFileName:any;
  public Detail=false;
  public folderPNRToRemove: any;
  public GDS:any;
  public PNR:any;
  public removeFolderPNRConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  constructor(public common:CommonserviceService,public sanitizer: DomSanitizer,
    private env: EnvService,private apiShared:ShareDataService,
    private PnrsourcefileService:PnrsourcefileService ,private route: ActivatedRoute,
    private router: Router,private translateapi: TranslateService,private toastr:ToastrService) {
    this.systemUrl=env.baseUrl
   }

  ngOnInit() {
    this.LoadPNRShowSourceFileData();//12695
  }
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 50;

  public onChange(event: any): void {
    this.expandMode = parseInt(event.target.value, 10);
}
public onPanelChange(event: any): void { 
  this.Detail=false;
}
onCancel()
{
  this.common.submenuIndex=0;
  sessionStorage.setItem('pagevalue','0')
  this.apiShared.Setapishareddata('0','submenuIndex')
  this.router.navigate(['PenAir/Folder/FolderSummary',this.orderNo]);
  
}
ngOnDestroy() {
  this.subscription.unsubscribe();
}

public LoadPNRShowSourceFileData(){//12695
  
  this.subscription.add(this.PnrsourcefileService.GetPNRSourceFileDetails(this.orderNo)
  .subscribe(data => {
    if(data!=null&& data!=undefined){
  this.gridData = data["ResponseData"]; 
    }
    else
    {
      this.gridData=[];
    }
  }));
  }

  public LoadPNRShowSourceFileDataBasedOnFolderNo(FOLDERNO:any){//12695
    this.PnrsourcefileService.formPNRSourceFileData=new Pnrsourcefile;
    this.PnrsourcefileService.formPNRSourceFileData.FOLDERNO=FOLDERNO;
    this.subscription.add(this.PnrsourcefileService.GetPNRSourceFileBasedOnFolderNo()
    .subscribe(data => {
      if(data!=null&& data!=undefined){
    this.gridPNRSourceFileData=data["ResponseData"]
      }
      else
      {
        this.gridPNRSourceFileData=[];
      }
    }));
    }
public ExpandGrid()//By Caseid-12695
{
  if(this.Detail==false)
  {
    this.Detail=true;
    this.LoadPNRShowSourceFileDataBasedOnFolderNo(this.orderNo);//12695
  }
  else{
    this.Detail=false;
  }
}


async onPNRSourceFileCellClick(e) {
  this.LoadPNRFile(e.INTERFACE,e.PNRFILENAME,e.GDS);//12695
}
public pnrFilePath:any;
public fileContent:any;
public LoadPNRFile(INTERFACE:any,fileName:any,GDS:any){//12695
  this.PnrsourcefileService.formData=new PNRFileDTO;
  this.PnrsourcefileService.formData.INTERFACE=INTERFACE;
  this.PnrsourcefileService.formData.PNRFILENAME=fileName;
  this.PnrsourcefileService.formData.GDS=GDS;
  this.subscription.add(this.PnrsourcefileService.LoadPNRFile()
  .subscribe(data => {
    if(data!=null&& data!=undefined){
      if(data["ResponseData"].status=="NotFound")
      {
        this.subscription.add(this.translateapi.get(['Messages.fileNotFound']).subscribe((translations ) => {
          this.showMessage= translations['Messages.fileNotFound']  ;;
          this.toastr.error(this.showMessage,"",{
            timeOut:5000,
            disableTimeOut:false,
            closeButton:true
          })
         }));
         this.router.navigate(['/PenAir/Folder/PNRSourceFileDetails/',this.orderNo]);
      }
      else
      {
        this.pnrFileOpened=true;
        this.pnrFilePath=this.systemUrl+data["ResponseData"].XMLFI_DESTINATIONPATH;
        if(data["ResponseData"].fileExtension=="pdf")
        {
          this.pnrFileOpened=true;
          this.pdfPNRFileOpened=true;
          this.headerFileName=fileName
          this.textPNRFileOpened=false;
          this.PNRFileURL= this.sanitizer.bypassSecurityTrustResourceUrl(this.pnrFilePath);
        }
        else if(data["ResponseData"].fileExtension=="xml")
        {
          this.pnrFileOpened=false;
          this.headerFileName=fileName;
          this.fileContent = data["ResponseData"].fileContent;
          this.pnrFilePath=this.systemUrl+data["ResponseData"].XMLFI_DESTINATIONPATH;
          window.open(this.pnrFilePath, "_blank");
        }
        else
        {
          this.pnrFileOpened=true;
          this.headerFileName=fileName
          this.pdfPNRFileOpened=false;
          this.textPNRFileOpened=true;
          this.fileContent = data["ResponseData"].fileContent;
        }
      }
    }
    else
    {
      this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations ) => {
        this.showMessage= translations['Messages.Failed'];
        this.common.showWARNINGtoastrmsg(this.showMessage, 2000, true, false);
      }));
    }
  }));
  }

  public dragPNRFile() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const positionTop = windowHeight - this.PNRFileheight - this.offset;
    const positionLeft = windowWidth - this.PNRFilewidth - this.offset;
  
    if (this.windowTop < this.offset) {
      this.windowTop = this.offset;
    }
  
    if (this.windowTop > positionTop) {
      this.windowTop = positionTop;
    }
  
    if (this.windowLeft < this.offset) {
      this.windowLeft = this.offset;
    }
  
    if (this.windowLeft > positionLeft) {
      this.windowLeft = positionLeft;
    }
  }

  public closePNRFile() {
    this.pnrFileOpened=false;
    this.router.navigate(['/PenAir/Folder/PNRSourceFileDetails/',this.orderNo]); 
  }
  public toggle(isOpened: boolean): void {
    this.pnrFileOpened = isOpened;
  }

  public LoadXMLData(filePathUrl:any){//12695
    this.subscription.add(this.PnrsourcefileService.LoadXMLData(filePathUrl)
    .subscribe(data => {
      if(data!=null&& data!=undefined){
    this.gridPNRSourceFileData=data["ResponseData"]
      }
      else
      {
        this.gridPNRSourceFileData=[];
      }
    }));
    }

    public sort: SortDescriptor[] = [
      {
          field: 'RECEIVEDDATE',
          dir: 'asc'
      }
    ];


    public DeleteFolderPNRDetails(e)
    {
      this.folderPNRToRemove=true;
      this.GDS=e.GDS;
      this.PNR=e.PNR;
      this.PnrsourcefileService.formPNRSourceFileData=new Pnrsourcefile;
      this.PnrsourcefileService.formPNRSourceFileData.FOLDERNO=this.orderNo;
      this.PnrsourcefileService.formPNRSourceFileData.GDS=this.GDS;
      this.PnrsourcefileService.formPNRSourceFileData.PNR=this.PNR;
      this.PnrsourcefileService.formPNRSourceFileData.PROJNO =this.apiShared.projNo;
      this.PnrsourcefileService.formPNRSourceFileData.UID =this.apiShared.uid;
    }

    public ConfirmationForDeletingFolderPNRDetails(shouldRemove: boolean): void { 
      this.removeFolderPNRConfirmationSubject.next(shouldRemove);
      if(shouldRemove)
      {
        this.subscription.add( this.PnrsourcefileService.DeleteFolderPNRDetails() 
        .subscribe(res => {
          if(res!=null&& res!=undefined){
          if(res["ResponseData"].Status=="Success")
          {
            this.LoadPNRShowSourceFileData();
            this.LoadPNRShowSourceFileDataBasedOnFolderNo(this.orderNo);
            this.showMessage=res["ResponseData"].StatusDetails;
            this.toastr.success(this.showMessage,"",{
              timeOut:3000,
              disableTimeOut:false,
              closeButton:true
            });
          } else {
            this.showMessage=res["ResponseData"].StatusDetails;
            this.toastr.warning(this.showMessage,"",{
              timeOut:3000,
              disableTimeOut:false,
              closeButton:true
            });
          }}
        }, (err) => {
            console.log(err);
            this.subscription.add(this.translateapi.get(['Messages.Failed']).subscribe((translations ) => {
              this.showMessage= translations['Messages.Failed'];
              this.common.showWARNINGtoastrmsg(this.showMessage, 2000, true, false);
            }));
        }));
      }
      this.folderPNRToRemove = null;
  }
}
