import { Component, OnInit, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageSizeItem } from '@progress/kendo-angular-grid';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LookUpDetails } from 'src/app/Services/look-up-details';
import { CommonserviceService } from 'src/app/Services/commonservice.service';
import { LookUpDetailsService } from 'src/app/Services/look-up-details.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageAnalysisReportDTOList, MessageAnalysisReport } from '../../Models/message-analysis-report.model';
import { MessageAnalysisReportService } from '../../Services/message-analysis-report.service';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";
import { EmailService } from "../../Services/email.service";
import { ExcelExportComponent } from "@progress/kendo-angular-excel-export";
import { Workbook, ExcelExportData } from '@progress/kendo-angular-excel-export';
import { exportPDF, Group } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { ExportPDFParam } from '../../Models/common-class.model';
import { ShareDataService } from '../../Services/share-data.service';
import { ModuleUsageService } from 'src/app/Services/module-usage.service';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
@Component({
  selector: 'app-message-analysis-report',
  templateUrl: './message-analysis-report.component.html',
  styleUrls: ['./message-analysis-report.component.scss']
})
export class MessageAnalysisReportComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public grids: QueryList<GridComponent>;
  isShowncase: any;
  Componentname: string;
  submitted = false;
  emailopened: any = false;
  showFilter = false;
  MessageAnalysisForm: FormGroup;
  public MessageAnalysisList: any;
  MessageAnalysisReportDTO: MessageAnalysisReport;
  ActionList: Array<LookUpDetails["ResponseData"]> = [];
  public ActionSelectedItem: LookUpDetails["ResponseData"];
  PenChannelUserList: Array<LookUpDetails["ResponseData"]> = [];
  public PenChannelUserSelectedItem: LookUpDetails["ResponseData"];
  public pageSizes: (PageSizeItem | number)[] = [20, 50, 100, {
    text: 'All',
    value: 'all',

  }];
  ispopop: any = false;
  public MessageAnalysisRequest: any;
  public ExportPDFParamList: ExportPDFParam[];
  public mySelection: number[] = [];
  public opened = false;
  public ActionDateFrom: any;
  public ActionDateTo: any;
  AlertStatusSelectedItem: any;
  AlertStatusValue: any;
  public User_Name = "";
  UserCode: any;
  public Action_Name = "";
  ActionCode: any;
  FolderNo: any;
  OrderNo: any;
  Reportdata: any;
  public pdftop: any;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  gridHeight: any;
  AlertStatusList: Array<string> = ["All", "Open with Alert", "Open", "Closed"];
  constructor(private formBuilder: FormBuilder, private lookupApi: LookUpDetailsService, private translateapi: TranslateService, private toastr: ToastrService, private router: Router,
    public common: CommonserviceService, private spinner: NgxSpinnerService, private route: ActivatedRoute, public email: EmailService,
    public MsgAnalysRPT: MessageAnalysisReportService, public apishared: ShareDataService, public ModuleUsageService: ModuleUsageService,
  ) { }

  ngOnInit(): void {
    debugger;

    this.Componentname = "Message Analysis Report";
    sessionStorage.setItem('Componentname', this.Componentname);

    this.AlertStatusSelectedItem = this.AlertStatusList.find
      (item => item == this.AlertStatusList[0]);
    this.AlertStatusValue = this.AlertStatusList[0];
    // this.ActionDateFrom = this.common.ConvertToLocaleDate(new Date());
    // this.ActionDateTo = this.common.ConvertToLocaleDate(new Date());
    // this.subscription.add(this.lookupApi.GetAllLookup('ACTION').subscribe(
    this.subscription.add(this.lookupApi.GetAllLookup('W_PreConditionAction').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.ActionList = data["ResponseData"];
          if (this.MessageAnalysisForm.controls.Action.value != "") {
            this.ActionSelectedItem = this.ActionList.find(item => item.Code == this.MessageAnalysisForm.controls.Action.value);
            this.actionValueChange(this.ActionSelectedItem);
          }
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.PenChannelUserList = data["ResponseData"];
          if (this.MessageAnalysisForm.controls.User.value != "") {
            this.PenChannelUserSelectedItem = this.PenChannelUserList.find(item => item.Code == this.MessageAnalysisForm.controls.User.value);
            this.PenChannelUserChange(this.PenChannelUserSelectedItem);
          }
        }
      }
    ));

    this.MessageAnalysisForm = this.formBuilder.group({
      ActionDateFrom: new Date(),
      ActionDateTo: new Date(),
      FolderNo: [''],
      OrderNo: [''],
      AlertStatus: [''],
      Action: [''],
      User: [''],
    })
  }
  public PenChannelUserChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.MessageAnalysisForm.controls.User.setValue(value.Code);
      this.User_Name = value.Name;
      this.UserCode = value.UsrCode
    }
    else {
      this.MessageAnalysisForm.controls.User.setValue("");
      this.User_Name = "";
      this.UserCode = "";
    }
  }

  public actionValueChange(value: any): void {
    debugger;
    if (value != null && value != undefined) {
      this.MessageAnalysisForm.controls.Action.setValue(value.Code);
      this.Action_Name = value.ActualName;
      this.ActionCode = value.UsrCode;
    }
    else {
      this.MessageAnalysisForm.controls.Action.setValue("");
      this.Action_Name = "";
      this.ActionCode = "";
    }
  }
  AlertStatusValueChange(event) {
    debugger;
    this.MessageAnalysisForm.controls.AlertStatus.setValue(this.AlertStatusList.find(
      item => item == this.MessageAnalysisForm.value.AlertStatus
    ));

  }
  onClear() {
    this.Reportdata = null;
    this.MessageAnalysisForm.reset();
    this.AlertStatusSelectedItem = this.AlertStatusList[0];
    this.ActionSelectedItem = null;
    this.PenChannelUserSelectedItem = null;
    this.submitted = false;
    this.FolderNo = null;
    this.OrderNo = null;
  }
  GetMessageAnalysisData() {
    debugger;
    // this.spinner.show();
    this.submitted = true;
    this.Reportdata = [];

    if ((this.MessageAnalysisForm.controls.ActionDateFrom.value == null) && (this.MessageAnalysisForm.controls.ActionDateTo.value == null)) {
      //if ((this.ActionDateFrom == null) && (this.ActionDateTo == null)) {
      this.common.showWARNINGtoastrmsg(
        "Messages.PleaseSelectAnyDate",
        2000,
        true,
        false
      );
      return false;
    }
    // if (((this.MessageAnalysisForm.controls.ActionDateFrom.value != "" || this.MessageAnalysisForm.controls.ActionDateFrom.value != null) && (this.MessageAnalysisForm.controls.ActionDateTo.value != "" || this.MessageAnalysisForm.controls.ActionDateTo.value != null))
    //   && (this.MessageAnalysisForm.controls.ActionDateFrom.value) > (this.MessageAnalysisForm.controls.ActionDateTo.value)) {
    if (((this.MessageAnalysisForm.controls.ActionDateFrom.value != "" || this.MessageAnalysisForm.controls.ActionDateFrom.value == null) && (this.MessageAnalysisForm.controls.ActionDateTo.value != "" && this.MessageAnalysisForm.controls.ActionDateTo.value != null)) &&
      (this.MessageAnalysisForm.controls.ActionDateFrom.value) > (this.MessageAnalysisForm.controls.ActionDateTo.value)) {
      this.common.showWARNINGtoastrmsg(
        "Messages.fromdate_exceeds",
        2000,
        true,
        false
      );
      return false;
    }

    if (this.MessageAnalysisForm.controls.ActionDateFrom.value == "" || this.MessageAnalysisForm.controls.ActionDateFrom.value == null || this.MessageAnalysisForm.controls.ActionDateFrom.value == undefined) {
      if (this.MessageAnalysisForm.controls.ActionDateTo.value != "" && this.MessageAnalysisForm.controls.ActionDateTo.value != null) {
        this.ActionDateFrom = this.common.ConvertToLocaleDate(this.MessageAnalysisForm.controls.ActionDateTo.value);
      }
      else {
        this.ActionDateFrom = this.common.ConvertToLocaleDate(new Date("01-Jan-1900"));
      }
    }
    else {
      this.ActionDateFrom = this.common.ConvertToLocaleDate(this.MessageAnalysisForm.controls.ActionDateFrom.value);
    }
    if (this.MessageAnalysisForm.controls.ActionDateTo.value == "" || this.MessageAnalysisForm.controls.ActionDateTo.value == null || this.MessageAnalysisForm.controls.ActionDateTo.value == undefined) {
      if (this.MessageAnalysisForm.controls.ActionDateFrom.value != "" && this.MessageAnalysisForm.controls.ActionDateFrom.value != null) {
        this.ActionDateTo = this.common.ConvertToLocaleDate(this.MessageAnalysisForm.controls.ActionDateFrom.value);
      }
      else {
        this.ActionDateTo = this.common.ConvertToLocaleDate(new Date("01-Jan-1900"));
      }
    }
    else {
      this.ActionDateTo = this.common.ConvertToLocaleDate(this.MessageAnalysisForm.controls.ActionDateTo.value);
    }
    this.ExportPDFParamList = [];
    debugger;
    var filter = new ExportPDFParam();
    if (this.ActionDateFrom != "01-Jan-1900") {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.DATE');
      filter.FirstValue = this.ActionDateFrom + ' - ' + this.ActionDateTo;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.FolderNo == undefined || this.FolderNo == '')) {
      this.FolderNo = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.folderNo');
      filter.FirstValue = this.FolderNo;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.OrderNo == undefined || this.OrderNo == '')) {
      this.OrderNo = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.orderNo');
      filter.FirstValue = this.OrderNo;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.ActionSelectedItem == undefined)) {
      this.ActionSelectedItem = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.Action');
      filter.FirstValue = this.ActionSelectedItem.ActualName;
      this.ExportPDFParamList.push(filter);
    }
    if ((this.PenChannelUserSelectedItem == undefined)) {
      this.PenChannelUserSelectedItem = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.user');
      filter.FirstValue = this.PenChannelUserSelectedItem.Name;
      this.ExportPDFParamList.push(filter);
    }

    if ((this.AlertStatusSelectedItem == undefined || this.AlertStatusSelectedItem == '')) {
      this.AlertStatusSelectedItem = null;
    }
    else {
      var filter = new ExportPDFParam();
      filter.Filter = this.common.GetTransilationMessage('Labels.TaskStatus');
      filter.FirstValue = this.AlertStatusSelectedItem;
      this.ExportPDFParamList.push(filter);
    }


    this.MessageAnalysisReportDTO = new MessageAnalysisReport;
    this.MessageAnalysisReportDTO.ActionDateFrom = this.ActionDateFrom;
    this.MessageAnalysisReportDTO.ActionDateTo = this.ActionDateTo;
    this.MessageAnalysisReportDTO.AlertStatus = this.AlertStatusSelectedItem;
    this.MessageAnalysisReportDTO.User = this.User_Name;
    this.MessageAnalysisReportDTO.usercode = this.UserCode;
    this.MessageAnalysisReportDTO.Action = this.Action_Name;
    this.MessageAnalysisReportDTO.ActionCode = this.ActionCode;
    this.MessageAnalysisReportDTO.FolderNo = this.MessageAnalysisForm.controls.FolderNo.value;
    this.MessageAnalysisReportDTO.OrderNo = this.MessageAnalysisForm.controls.OrderNo.value;
    this.mySelection = [];
    this.MessageAnalysisReportDTO.ExportPDFParam = this.ExportPDFParamList;
    // this.MessageAnalysisRequest = JSON.stringify(this.MessageAnalysisReportDTO);
    this.ModuleUsageService.ModuleUsageDTO.reportname = "Message Analysis Report";
    this.ModuleUsageService.ModuleUsageDTO.ReportCriteria = JSON.stringify(this.MessageAnalysisReportDTO);

    this.ModuleUsageService.getModuleUsageReport().subscribe(res => {
      debugger;
    })
    this.MessageAnalysisList = this.MessageAnalysisReportDTO;

    if (this.MessageAnalysisReportDTO.ExportPDFParam.length != undefined) {
      if (this.MessageAnalysisReportDTO.ExportPDFParam.length > 0) {
        if (this.MessageAnalysisReportDTO.ExportPDFParam.length <= 5) {
          this.pdftop = "3cm";
        }
        else if (this.MessageAnalysisReportDTO.ExportPDFParam.length <= 13) {
          this.pdftop = "8cm";
        }
      }
    }


    this.subscription.add(
      this.MsgAnalysRPT.GetMessageAnalysisReport(this.MessageAnalysisReportDTO)
        .subscribe((res) => {

          if (res != null && res != undefined) {
            debugger;
            this.spinner.hide();
            this.Reportdata = res["ResponseData"];
            // this.isShowncase = true;
            if (this.Reportdata.length > 0) {
              if (this.Reportdata.length > 12)
                this.gridHeight = window.innerHeight * .85;
              else
                this.gridHeight = 105 + (33 * this.Reportdata.length - 1);
              // for (var obj of this.Reportdata) {
              //   if (obj.ActionDateFrom != null) {
              //     obj.ActionDateFrom1 = obj.ActionDateFrom;
              //     obj.ActionDateFrom = new Date(obj.ActionDateFrom);
              //   }
              //   else {
              //     obj.ActionDateFrom = new Date('01-Jan-1900');
              //   }

              //   if (obj.ActionDateTo != null) {
              //     obj.ActionDateTo1 = obj.ActionDateTo;
              //     obj.ActionDateTo = new Date(obj.ActionDateTo);
              //   }
              //   else {
              //     debugger;
              //     obj.ActionDateTo = new Date('01-Jan-1900');
              //   }

              // }


            }
            else {
              this.gridHeight = 130;
            }


          } else {
            this.common.showWARNINGtoastrmsg('Messages.FailedPosting', 3000, true, false);
            this.spinner.hide();
          }
        }));
  }
  public async onExcelExport2(args: any) {
    debugger;
    this.email.filename = "Message Analysis Report.xlsx";
    this.email.reportname = "Message Analysis Report";

    args.preventDefault();

    const observables = [];
    let workbook = args.workbook;

    let rows = workbook.sheets[0].rows;

    let headerOptions = rows[0].cells[0];

    var reader = new FileReader();
    var sheet = workbook.sheets[0];


    if ((this.ActionDateFrom != undefined && this.ActionDateFrom != null && this.ActionDateFrom != "01-Jan-1900")) {
      this.common.generateExcelFilterheader(sheet, 'Action Date', (this.ActionDateFrom), (this.ActionDateTo));
    }


    if ((this.MessageAnalysisReportDTO.FolderNo != undefined && this.MessageAnalysisReportDTO.FolderNo != null && this.MessageAnalysisReportDTO.FolderNo != "")) {
      this.common.generateExcelFilterheader(sheet, 'Folder No', this.MessageAnalysisReportDTO.FolderNo.toString(), '');

    }
    if ((this.MessageAnalysisReportDTO.OrderNo != undefined && this.MessageAnalysisReportDTO.OrderNo != null && this.MessageAnalysisReportDTO.OrderNo != "")) {
      this.common.generateExcelFilterheader(sheet, 'Order No', this.MessageAnalysisReportDTO.OrderNo.toString(), '');

    }

    if ((this.AlertStatusSelectedItem != undefined && this.AlertStatusSelectedItem != null && this.AlertStatusSelectedItem != "")) {
      this.common.generateExcelFilterheader(sheet, 'Alert status', this.AlertStatusSelectedItem, '');

    }

    if (this.Action_Name != undefined && this.Action_Name != null && this.Action_Name != "") {

      this.common.generateExcelFilterheader(sheet, 'Action', this.Action_Name, '');
    }
    if (this.User_Name != undefined && this.User_Name != null && this.User_Name != "") {

      this.common.generateExcelFilterheader(sheet, 'User', this.User_Name, '');
    }


    if ((this.email.reportname != undefined)) {
      this.common.generateExcelMainheader(sheet, this.email.reportname);
    }
    await this.common.SetCompanyheaderExcelheader(sheet)
    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      saveAs(dataUrl, "Message Analysis Report.xlsx");
      if (this.ispopop == true) {

        this.ispopop = false;
        this.email.fileurl = this.dataURItoBlob(dataUrl);
        this.emailopened = true;
        this.spinner.hide("spinner-1");
      }
    });

  }
  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }
  public onExcelExport(component: ExcelExportComponent, value): void {
    debugger;
    const options = component.workbookOptions();
    const rows = options.sheets[0].rows;
    rows.forEach((row, index) => {
      if (row.type === "data") {
        if (row.cells[0].value == 1) {
          row.cells.forEach((cell) => {
            cell.background = "#aabbcc";

          });
          row.cells[0].value = null;
        }
        else {
          row.cells.forEach((cell) => {
            row.cells[0].value = null;
          });
        }
      }
      else {
        row.cells.forEach((cell) => {

          if (cell.value == "Isparent")
            cell.value = null;

        });

      }
    });
    let workbook = options;
    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      saveAs(dataUrl, "Message Analysis Report.xlsx");
      this.email.reportname = "Message Analysis Report";

      this.email.filename = "Message Analysis Report.xlsx";

      this.email.fileurl = this.common.dataURItoBlob(dataUrl);


    });
    if (value == 1) {
      this.opened = true;
      this.email.filename = "Message Analysis Report.xlsx";
    }
    else {
      this.email.filename = null;
      this.opened = false;
    }
  }
  public exportToPDF(grid: GridComponent): void {
    grid.saveAsPDF();
  }
  public exportGrids(value): void {
    debugger;
    const promises = this.grids.map((grid) => grid.drawPDF());
    Promise.all(promises)
      .then((groups) => {
        const rootGroup = new Group({
          pdf: {
            multiPage: true,
          },
        });
        groups.forEach((group) => {
          rootGroup.append(...group.children);
        });

        return exportPDF(rootGroup, { paperSize: "auto" });
      })
      .then((dataUri) => {
        saveAs(dataUri, "Message Analysis Report.pdf");
        this.email.reportname = "Message Analysis Report";

        this.email.filename = "Message Analysis Report.pdf";

        this.email.fileurl = this.common.dataURItoBlob(dataUri);
      });

  }
  public open() {
    window.scrollTo(0, 0);
    this.opened = true;
  }

  public close(value) {
    this.opened = false;
  }
  public Print() { }
  public Sendmail() {

    this.opened = true;

  }
  public EmailFormClosing(
    status: any
  ) {
    if (status == "EmailSendSuccesfully" || status == "Closed") {
      this.opened = false;
    }
  }
  public rowCallback(context: RowClassArgs) {

    if (context.dataItem.Isparent == 1) {
      return {
        passive: true,
        green: false,
      };
    } else {
      return {
        passive: false,
        green: true,
      };
    }
  }
  public onFilterClick() {
    this.showFilter = !this.showFilter;
    this.showFilter = this.showFilter ? true : false;
  }
  public permissionmessageformopened: any;
  public PermissionMessageId: any;

  public PermissionFormClosing(status: any) {
    if (status == "EmailSendSuccesfully") {
      this.permissionmessageformopened = false;
    }
  }

  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;
    this.PermissionMessageId = permissionid;
  }
  onCancel() {
    this.router.navigate(["PenAir/Home"], { skipLocationChange: true });
  }
}
