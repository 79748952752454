import { BrowserModule } from "@angular/platform-browser";
import {NgModule,LOCALE_ID,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA} from "@angular/core";
import "@progress/kendo-angular-intl/locales/bg/all";
import "@progress/kendo-angular-intl/locales/de/all";
import "@progress/kendo-angular-intl/locales/fr/all";
import "@progress/kendo-angular-intl/locales/zh/all";
import "@progress/kendo-angular-intl/locales/en/all";
import "@progress/kendo-angular-intl/locales/th/all";
import { ToastrModule } from "ngx-toastr";
import { IntlModule } from "@progress/kendo-angular-intl";
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_BASE_HREF, CommonModule, DatePipe } from "@angular/common";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { KendocontrolsComponent } from "./kendocontrols/kendocontrols.component";
import { AccordianMainMenuComponent } from "./SharedMenu/accordian-main-menu/accordian-main-menu.component";
// import { FoldersearchlistComponent } from "./search/foldersearchlist/foldersearchlist.component";
import { HomePageComponent } from "./home-page/home-page.component";
import { DialogComponent } from './dialog/dialog.component';
import { ProjectmasterComponent } from "./projectmaster/projectmaster.component";
import { HotelPropertyTypesComponent } from "./hotel-property-types/hotel-property-types.component";
import { MealplanmasterComponent } from "./mealplanmaster/mealplanmaster.component";
// import { HotelDetailsComponent } from "./product/hotel-details/hotel-details.component";
// import { InsuranceDetailsComponent } from "./insurance-details/insurance-details.component";
// import { TourDetailsComponent } from "./tour-details/tour-details.component";
// import { FCHCDetailsComponent } from "./fchcdetails/fchcdetails.component";
// import { HotelDetailsEditComponent } from "./product/hotel-details-edit/hotel-details-edit.component";
// import { CruiseDetailsComponent } from "./product/cruise-details/cruise-details.component";
// import { OthersDetailsComponent } from "./product/others-details/others-details.component";
// import { FchcdetailseditComponent } from "./product/fchcdetailsedit/fchcdetailsedit.component";
// import { TourDetailsEditComponent } from "./tour-details-edit/tour-details-edit.component";
// import { InsuranceDetailsEditComponent } from "./product/insurance-details-edit/insurance-details-edit.component";
// import { CruiseDetailsEditComponent } from "./cruise-details-edit/cruise-details-edit.component";
// import { OthersDetailsEditComponent } from "./others-details-edit/others-details-edit.component";
// import { CardetailsComponent } from "./cardetails/cardetails.component";
// import { RailDetailsComponent } from "./rail-details/rail-details.component";
// import { PaymentTermsEditComponent } from "./payment-terms-edit/payment-terms-edit.component";
// import { CorporateInfoDetailsEditComponent } from "./corporate-info-details-edit/corporate-info-details-edit.component";
// import { CarDetailsEditComponent } from "./car-details-edit/car-details-edit.component";
// import { FolderSummaryComponent } from "./folder/folder-summary/folder-summary.component";
// import { PaymentTermsComponent } from "./payment-terms/payment-terms.component";
// import { CorporateInfoDetailsComponent } from "./corporate-info-details/corporate-info-details.component";
// import { AirticketBspEditComponent } from "./airticket-bsp-edit/airticket-bsp-edit.component";
// import { AirticketDetailsComponent } from "./airticket-details/airticket-details.component";
// import { AirticketDetailsEditComponent } from "./folder/airticket-details-edit/airticket-details-edit.component";
// import { AirticketSalestaxdetailsEditComponent } from "./airticket-salestaxdetails-edit/airticket-salestaxdetails-edit.component";
// import { AirticketSalestaxdetailsComponent } from "./airticket-salestaxdetails/airticket-salestaxdetails.component";
// import { AirticketMembershipLoyalitypointsComponent } from "./airticket-membership-loyalitypoints/airticket-membership-loyalitypoints.component";
// import { AirticketMembershipLoyalitypointsEditComponent } from "./airticket-membership-loyalitypoints-edit/airticket-membership-loyalitypoints-edit.component";
// import { AirticketBspComponent } from "./airticket-bsp/airticket-bsp.component";
// import { PassengerDetailComponent } from "./sharedFolder/passenger-detail/passenger-detail.component";
// import { ProductsComponent } from "./product/products/products.component";
// import { CustomerspecificComponent } from "./folder/customerspecific/customerspecific.component";
// import { DeliveryaddressComponent } from "./folder/deliveryaddress/deliveryaddress.component";
// import { DeliveryaddresseditComponent } from "./folder/deliveryaddressedit/deliveryaddressedit.component";
// import { FolderSummaryEditComponent } from "./folder/folder-summary-edit/folder-summary-edit.component";
// import { ComfirmationWindowComponent } from "./login/comfirmation-window/comfirmation-window.component";
// import { ComfirmationWindowComponent } from "./SharedCommonModule/comfirmation-window/comfirmation-window.component";
// import { FolderhistoryComponent } from "./folder/folderhistory/folderhistory.component";
// import { PnrSourcefileComponent } from "./folder/pnr-sourcefile/pnr-sourcefile.component";
// import { FolderoptionsComponent } from "./folder/folderoptions/folderoptions.component";
// import { FolderNotesComponent } from "./folder/folder-notes/folder-notes.component";
// import { CustomerreceiptsComponent } from "./folder/customerreceipts/customerreceipts.component";
// import { PaymentstripComponent } from "./folder/paymentstrip/paymentstrip.component";
// import { DetailsComponent } from "./folder/details/details.component";
// import { RailDetailsEditComponent } from "./rail-details-edit/rail-details-edit.component";
// import { TransfersDetailsComponent } from "./product/transfers-details/transfers-details.component";
// import { TransfersDetailEditComponent } from "./product/transfers-detail-edit/transfers-detail-edit.component";
// import { PaymentComponent } from "./folder/payment/payment.component";
// import { CustomerpdqreceiptComponent } from "./customerpdqreceipt/customerpdqreceipt.component";
//import { JwtInterceptor } from './_helpers/jwt-interceptor';
// import { CustomerbankreceiptComponent } from "./folder/customerbankreceipt/customerbankreceipt.component";
// import { CustomerspecificEditComponent } from "./folder/customerspecific-edit/customerspecific-edit.component";
// import { BookingConfirmationComponent } from "./booking-confirmation/booking-confirmation.component";
// import { SupplierEditComponent } from "./product/supplier-edit/supplier-edit.component";
// import { InvoiceComponent } from "./folder/invoice/invoice.component";
// import { RevenueRecognitionPolicyComponent } from "./folder/revenue-recognition-policy/revenue-recognition-policy.component";
// import { RptAirticketReportWithPNRComponent } from "./rpt-airticket-report-with-pnr/rpt-airticket-report-with-pnr.component";
// import { UnlockdocumentComponent } from "./unlockdocument/unlockdocument.component";
// import { SearchParentComponent } from "./search/search-parent/search-parent.component";
// import { SearchbyHotelComponent } from "./search/searchby-hotel/searchby-hotel.component";
// import { SearchbyCarComponent } from "./search/searchby-car/searchby-car.component";
// import { SearchbyRailComponent } from "./search/searchby-rail/searchby-rail.component";
// import { SearchbyTransfersComponent } from "./search/searchby-transfers/searchby-transfers.component";
// import { SearchbyTourComponent } from "./search/searchby-tour/searchby-tour.component";
// import { SearchbyCruiseComponent } from "./search/searchby-cruise/searchby-cruise.component";
// import { SearchbyInsuranceComponent } from "./search/searchby-insurance/searchby-insurance.component";
// import { SearchbyOthersComponent } from "./search/searchby-others/searchby-others.component";
// import { SearchbyFolderComponent } from "./search/searchby-folder/searchby-folder.component";
// import { SearchdesignationComponent } from "./searchdesignation/searchdesignation.component";
// import { DropdownfilterComponent } from "./SharedCommonModule/dropdownfilter/dropdownfilter.component";
// import { ChangepasswordComponent } from "./changepassword/changepassword.component";
// import { RequestresetpasswordComponent } from "./requestresetpassword/requestresetpassword.component";
// import { LicenceRenewalComponent } from "./licence-renewal/licence-renewal.component";
// import { LicenseTermsComponent } from './license-terms/license-terms.component';
// import { GDSTrayComponent } from "./search/gdstray/gdstray.component";
// import { EmailComponent } from "./SharedCommonModule/email/email.component";
// import { TaxbaseComponent } from "./taxbase/taxbase.component";
// import { TaxbaseEditComponent } from "./taxbase-edit/taxbase-edit.component";
// import { PopupAnchorDirective } from "./SharedCommonModule/expense-add/PopupAnchorDirective";
// import { MembershipLoyalitypointsComponent } from "./membership-loyalitypoints/membership-loyalitypoints.component";
// import { MembershipLoyalitypointsEditComponent } from "./membership-loyalitypoints-edit/membership-loyalitypoints-edit.component";
// import { ProductEditComponent } from "./product-edit/product-edit.component";
// import { RptFolderActivityReportComponent } from "./rpt-folder-activity-report/rpt-folder-activity-report.component";
// import { RptFolderActivityProductComponent } from './rpt-folder-activity-product/rpt-folder-activity-product.component';
// import { MultiselectComponent } from './SharedCommonModule/multiselect/multiselect.component';
// import { Calendar2Component } from './SharedCommonModule/calendar2/calendar2.component';
// import { HotelPriceDetailsComponent } from './hotel-price-details/hotel-price-details.component';
// import { HotelSupplierPaymentComponent } from './hotel-supplier-payment/hotel-supplier-payment.component';
// import { PriceDetailsViewComponent } from './price-details-view/price-details-view.component';
// import { FolderaddExpenseComponent } from './folder/folderadd-expense/folderadd-expense.component';
// import { RptFlightBookingDetailsComponent } from './rpt-flight-booking-details/rpt-flight-booking-details.component';
// import { DelinkReceiptFromFolderComponent } from "./delink-receipt-from-folder/delink-receipt-from-folder.component";
// import { PenchannelListComponent } from "./penchannel-list/penchannel-list.component";
//import { KendogroupingComponent } from './kendogrouping/kendogrouping.component';
// import { SelectHotelActivityComponent } from "./HotelInfo/select-hotel-activity/select-hotel-activity.component";
//import { HotelActivityComponent } from './HotelInfo/hotel-activity/hotel-activity.component';
// import { HotelActivityViewComponent } from './HotelInfo/hotel-activity-view/hotel-activity-view.component';
// import { ReportschedulersetupformComponent } from "./SharedCommonModule/reportschedulersetupform/reportschedulersetupform.component";
// import { LogviewformComponent } from './logviewform/logviewform.component';
// import { LogformComponent } from "./folder/logform/logform.component";
//import { NominalMasterDtoComponent } from './Models/nominal-master-dto/nominal-master-dto.component';
//import { DelinkReceiptFromFolderComponent } from './delink-receipt-from-folder/delink-receipt-from-folder.component';
// import { DetailedProductGridComponent } from './folder/detailed-product-grid/detailed-product-grid.component';
// import { FchcdetaileditNewComponent } from './fchcdetailedit-new/fchcdetailedit-new.component';
// import { FchcdetailsNewComponent } from './fchcdetails-new/fchcdetails-new.component';
// import { FolderActivityDetailedComponent } from "./folder/folder-activity-detailed/folder-activity-detailed.component";
// import { RemindersComponent } from "./folder/reminders/reminders.component";
// import { CommissionCalculationComponent } from "./sharedFolder/commission-calculation/commission-calculation.component";
// import { CommissionCalculationStepComponent } from "./sharedFolder/commission-calculation-step/commission-calculation-step.component";
// import { AgentCommissionComponent } from "./folder/agent-commission/agent-commission.component";
// import { PurgeAlertsComponent } from "./SharedCommonModule/purge-alerts/purge-alerts.component";
// import { ExpenseAddComponent } from "./folder/expense-add/expense-add.component";
// import { AirlineDetailedReportComponent } from "./airline-detailed-report/airline-detailed-report.component";
// import { NotesToBePrintedEditComponent } from "./product/notes-to-be-printed/notes-to-be-printed-edit/notes-to-be-printed-edit.component";
// import { NotesToBePrintedViewComponent } from './notes-to-be-printed/notes-to-be-printed-view/notes-to-be-printed-view.component';
// import { LookupPopupComponent } from "./sharedSearch/lookup-popup/lookup-popup.component";
// import { PDQMotoCreditCardDetailsComponent } from "./folder/pdqmoto-credit-card-details/pdqmoto-credit-card-details.component";
// import { DefaultchargeLookupComponent } from "./folder/defaultcharge-lookup/defaultcharge-lookup.component";
// import { InstallmentPlanComponent } from "./folder/installment-plan/installment-plan.component";
// import { MessageAnalysisReportComponent } from './message-analysis-report/message-analysis-report.component';
// import { PassengerMasterAddComponent } from "./folder/passenger-master-add/passenger-master-add.component";
// import { EpayMailComponent } from "./folder/epay-mail/epay-mail.component";
// import { InternalNotesComponent } from "./folder/internal-notes/internal-notes.component";

import {TranslateModule,TranslateLoader,TranslateService} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {HttpClientModule,HttpClient,HTTP_INTERCEPTORS} from "@angular/common/http";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { AlertModule } from '@full-fledged/alerts';
// Modules
import { SharedLoginModule } from "./sharedLogin/sharedLogin.module";
import { SharedCommonModule } from "./SharedCommonModule/shared-common.module";
import { SharedMenuModule } from "./SharedMenu/sharedMenu.module";
import { FolderModule } from "./folder/Folder.module";
import { ReportModule } from "./report/report.module";
import { ProductModule } from "./product/product.module";
import { JwtInterceptor } from "./_helpers/jwt-interceptor";
import { ErrorInterceptor } from "./_helpers/error-interceptor";

// import { ToDoModule } from "./to-do/to-do.module";

// import {BankPayInSlipComponent} from './bank-pay-in-slip/bank-pay-in-slip.component'
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  // return new TranslateHttpLoader(http);
}

registerLocaleData(localeFr, "fr");
export class DynamicLocaleId extends String {
  locale: string;

  toString() {
    return this.locale;
  }
}

import {GridModule,ExcelModule,FilterCellComponent,PDFModule} from "@progress/kendo-angular-grid";
import { PopupModule } from "@progress/kendo-angular-popup";
import { WindowModule, DialogModule, DialogsModule } from "@progress/kendo-angular-dialog";
import { AlertComponent } from "./alert/alert.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { fakeBackendProvider } from "./_helpers/fake-backend-interceptor";
import { FlashMessagesModule } from "angular2-flash-messages";
import {TelerikReportingModule,TelerikReportViewerComponent} from "@progress/telerik-angular-report-viewer";

import { EditorModule } from "@progress/kendo-angular-editor";
import { L10nInterceptor } from "./_helpers/l10n.interceptor";
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from "angular2-moment"; // optional, provides moment-style pipes for date formatting
import { AuthGuard } from "./_guards/auth.guard";
import { RouterModule } from "@angular/router";
import { EnvServiceProvider } from "src/env-service-provider.service";

import { PublicLayoutComponent } from "./public-layout/public-layout.component";
// import { FolderSummaryComponent } from "./folder/folder-summary/folder-summary.component";


@NgModule({
  declarations: [
    // PublicLayoutComponent,
    // FolderSummaryComponent,
    AppComponent,
    KendocontrolsComponent,
    AlertComponent,
    // FoldersearchlistComponent,
    HomePageComponent,
    ProjectmasterComponent,
    HotelPropertyTypesComponent,
    MealplanmasterComponent,
    // LookupPopupComponent,

    // ProductPackageComponent,

    // RetailOrPackagefolderComponent,
    // HotelSupplierPaymentComponent,
    // PriceDetailsViewComponent,
    // HotelPriceDetailsComponent,
    // FolderaddExpenseComponent,
    // RptFlightBookingDetailsComponent,

   
    // DefaultchargeLookupComponent,
    // InstallmentPlanComponent,
     // PDQMotoCreditCardDetailsComponent,
     // PassengerMasterAddComponent,
   


    // MessageAnalysisReportComponent,
    // DetailedProductGridComponent,
        // FchcdetaileditNewComponent,
    // FchcdetailsNewComponent,
    // FolderActivityDetailedReportComponent,
    // FolderActivityDetailedComponent,

    // AirticketBspEditComponent,
     // AirticketDetailsComponent,
    // AccordianMainMenuComponent,
    // HotelDetailsComponent,
    // InsuranceDetailsComponent,
    // FCHCDetailsComponent,
    // PaymentTermsComponent,
    // HotelDetailsEditComponent,
    // CruiseDetailsComponent,
    // OthersDetailsComponent,
    // FchcdetailseditComponent,
    // CardetailsComponent,
    // RailDetailsComponent,
    // CorporateInfoDetailsComponent,
    // PaymentTermsEditComponent,
    // TourDetailsComponent,
    // CustomerreceiptsComponent,
    // TourDetailsEditComponent,
    // InsuranceDetailsEditComponent,
    // CruiseDetailsEditComponent,
    // CorporateInfoDetailsEditComponent,
    // CarDetailsEditComponent,
    // OthersDetailsEditComponent,
    // AirticketDetailsEditComponent,
    // AirticketSalestaxdetailsEditComponent,
    // AirticketSalestaxdetailsComponent,
    // AirticketMembershipLoyalitypointsComponent,
    // AirticketMembershipLoyalitypointsEditComponent,
    // AirticketBspComponent,
    // PassengerDetailComponent,
    // ProductsComponent,
    // CustomerspecificComponent,
    // DeliveryaddressComponent,
    // FolderhistoryComponent,
    // DeliveryaddresseditComponent,
    // PnrSourcefileComponent,
    // FolderSummaryComponent,
    // FolderoptionsComponent,
    // FolderNotesComponent,
    // FolderSummaryEditComponent,
    // FolderoptionsComponent,
    // PaymentstripComponent,
    // DetailsComponent,
    // CustomerreceiptsComponent,
    // RailDetailsEditComponent,
    // TransfersDetailsComponent,
    // TransfersDetailEditComponent,
    // PaymentComponent,
    // CustomerpdqreceiptComponent,
    // ComfirmationWindowComponent,
    // CustomerbankreceiptComponent,
    // CustomerspecificEditComponent,
    // BookingConfirmationComponent,
   // RptAirticketReportWithPNRComponent,
     // ReportformComponent,
     // UnlockdocumentComponent,
    // CustomerEditComponent,
    // InvoiceComponent,
    // SupplierEditComponent,
    // RevenueRecognitionPolicyComponent,
    // SearchParentComponent,
    // SearchbyHotelComponent,
    // SearchbyCarComponent,
    // SearchbyRailComponent,
    // SearchbyTransfersComponent,
    // SearchbyTourComponent,
    // SearchbyCruiseComponent,
    // SearchbyInsuranceComponent,
    // SearchbyOthersComponent,
    // SearchbyFolderComponent,
    // SearchdesignationComponent,
    // ChangepasswordComponent,
    // EmailComponent,
    // DropdownfilterComponent,
    // RequestresetpasswordComponent,
    // MembershipLoyalitypointsComponent,
    // MembershipLoyalitypointsEditComponent,
    // ProductEditComponent,
    // PopupAnchorDirective,
    // RptFolderActivityReportComponent,
    // RptFolderQueryComponent,
    // TaxbaseComponent,
    // TaxbaseEditComponent,
    // DelinkReceiptFromFolderComponent,
    // ReportschedulersetupformComponent,
    // PenchannelListComponent,
     // PermissionMessageFormComponent,
    // MultiselectComponent,
     // Calendar2Component,
    // RptFolderActivityProductComponent,,
        // NotesToBePrintedEditComponent,
    // NotesToBePrintedViewComponent,
    // AirlineDetailedReportComponent,
        // LicenseTermsComponent,
         // GDSTrayComponent,
    // LicenceRenewalComponent,
    // ExpenseAddComponent,
     // InternalNotesComponent,
    // EpayMailComponent,
    // AccountsMainMenuComponent,
    // SelectHotelActivityComponent,
    // LogformComponent,
    //HotelActivityComponent,
    //HotelActivityViewComponent,
    // RemindersComponent,
    // CommissionCalculationComponent,
    // CommissionCalculationStepComponent,
    // AgentCommissionComponent,
    // PurgeAlertsComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,      

    SharedLoginModule,
    SharedCommonModule,
    SharedMenuModule,
    FolderModule,
    ReportModule,
    ProductModule,
    // ToDoModule,
    PDFExportModule,
    AlertModule,
    PopupModule,
    NgxSpinnerModule,
    DialogModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TreeViewModule,
    CommonModule,
    InputsModule,
    DropDownsModule,
    DateInputsModule,
    ButtonsModule,
    HttpClientModule,
    GridModule,
    FormsModule,
    ReactiveFormsModule,

    EditorModule,
    DialogModule,

    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-center-center",
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    LayoutModule,
    PopupModule,
    WindowModule,
    DialogModule,
    GridModule,
    ExcelModule,
    // ComfirmationWindowComponent,
    FlashMessagesModule.forRoot(),
    // TelerikReportingModule,
    IntlModule,

    PDFModule,

    AlertModule.forRoot({
      maxMessages: 5,
      timeout: 5000,
      positionX: 'right',
      positionY: 'top',
    }),
  ],
  exports: [TranslateModule],

  providers: [
        // DetailsComponent,
        // HotelDetailsEditComponent,
    // CarDetailsEditComponent,
    // TourDetailsEditComponent,
    // AirticketDetailsEditComponent,
    // OthersDetailsEditComponent,
    // TransfersDetailEditComponent,
    // CruiseDetailsEditComponent,
    // InsuranceDetailsEditComponent,
    // RailDetailsEditComponent,
        // PenchannelListComponent,
      // FolderSummaryComponent,
          PublicLayoutComponent,

    // InvoiceComponent,

    // AccordianMainMenuComponent,

    // ProductsComponent,

    // FchcdetailseditComponent,
    ProjectmasterComponent,
    HotelPropertyTypesComponent,
    MealplanmasterComponent,
    DatePipe,

    AlertComponent,
    L10nInterceptor,
    TelerikReportViewerComponent,


    // provider used to create fake backend
    TranslateService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DialogComponent,
      multi: true
    },
    //{ provide: APP_BASE_HREF, useValue: window['_app_base'] || '/' },
    // provider used to create fake backend
    fakeBackendProvider,
    AuthGuard,
    EnvServiceProvider,


    //  FolderSummaryEditComponent,

    // SupplierEditComponent,
    // HotelPriceDetailsComponent
  ],

  entryComponents: [FilterCellComponent,
    // PublicLayoutComponent,

      // HotelPriceDetailsComponent,
 
      // SupplierEditComponent,
],
  bootstrap: [AppComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule { }
