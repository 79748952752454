export class Reportscheduler {
    IsSuccess:boolean;
    Message:string;
    ResponseData:{
        RSST_AutoNo:number;
        RSST_Active :number;
        RSST_SchdulerName :string;
        RSST_Recurrence:string;
        RSST_StartDate:string;
        RSST_StartTime:string;
        RSST_Remarks:string;
        RSST_SMTPUser:number;
        RSST_ToEmail:string;
        RSST_CCEmail:string;
        RSST_EmailSubject:string;
        RSST_MessageBody:string;
        RSST_FileType:string;
        RSST_MobileNo:string;
        RSST_ISDCode:string;
        RSST_Notes:string;
        StatusDetails:string;
        Status:string;
    }

}
export class Runscheduler{
    RSST_AutoNo:any;
    RSST_Active :any;
    RSST_SchdulerName :any;
    RSST_Recurrence:any;
    RSST_StartDate:any;
    RSST_StartTime:any;
    RSST_Remarks:any;
    RSST_SMTPUser:any;
    RSST_ToEmail:any;
    RSST_CCEmail:any;
    RSST_EmailSubject:any;
    RSST_MessageBody:any;
    RSST_FileType:any;
    RSST_MobileNo:any;
    RSST_ISDCode:any;
    RSST_Notes:any;
    RSST_SchedulerType:any;
    RSST_ReportName:any;
    RSST_CrDate:any;
    RSST_CrTime:any;
    RSST_CrUID:any;
    RSST_LupDate:any;
    RSST_LupDateTime:any;
    RSST_LupDateUID:any;
    RSST_ExeArguments:any;
    RSST_RequestCriteria:any;
    RSST_LastRunResult:any;
    RSST_LastRunResultDate:any;
    RSST_ModuleName:any;
    RSST_LastRunDate:any;
    RSST_LastRunTime:any;
    RSST_NextRunDate:any;
    RSST_NextRunTime:any;
    RSST_Source:any;
    CreatedUsername:any;
    LastUpdateusername:any;
}
export class ReportschedulerRequestDTO {
    
    IsSuccess:boolean;
    Message:string;
    ResponseData:{

    RSST_AutoNo:any;
    RSST_Active :any;
    RSST_SchdulerName :any;
    RSST_Recurrence:any;
    RSST_StartDate:any;
    RSST_StartTime:any;
    RSST_Remarks:any;
    RSST_SMTPUser:any;
    RSST_ToEmail:any;
    RSST_CCEmail:any;
    RSST_EmailSubject:any;
    RSST_MessageBody:any;
    RSST_FileType:any;
    RSST_MobileNo:any;
    RSST_ISDCode:any;
    RSST_Notes:any;
    RSST_SchedulerType:any;
    RSST_ReportName:any;
    RSST_CrDate:any;
    RSST_CrTime:any;
    RSST_CrUID:any;
    RSST_LupDate:any;
    RSST_LupDateTime:any;
    RSST_LupDateUID:any;
    RSST_ExeArguments:any;
    RSST_RequestCriteria:any;
    RSST_LastRunResult:any;
    RSST_LastRunResultDate:any;
    RSST_ModuleName:any;
    RSST_LastRunDate:any;
    RSST_LastRunTime:any;
    RSST_NextRunDate:any;
    RSST_NextRunTime:any;
    RSST_Source:any;
    CreatedUsername:any;
    LastUpdateusername:any;
    StatusDetails:any;
    RSST_ActiveInStr:any;
    }
}
export class LogviewformDTO {
    
        LogDate:any;
        Month:any;
    
}



