export class AirsegDetail {
    IsSuccess:boolean;
    Message:string;
    ResponseData:{
    
          FOLDERNO :number;
          ITEMNO :number;
          AIRNAME :string;
          AIRREALNAME :string;
          FLIGHT :string;
          CLASS :string;
          CLASSNAME :string;
          SEATS :string;
          STATUS :string;
          STATUSNAME :string;
         AIRDATE :string;
          DEPCITY :string;
          DEPCITYNAME :string;
          ARRCITY :string;
          ARRCITYNAME :string;
          OPENSEG :string;
          DEPTIME :number;
          ARRTIME :number;
          S :string;
          PNRNO :string;
          FAREBASIS :string;
          DEPTERM :string;
          CHKIN :string;
          BOOKEDVIA :string;
         ARRDATE :string;
          ARRTERM :string;
          FLTTIME :number;
          BAGGALLOW :string;
          MEALSINFO :string;
          SEATINFO :string;
          SLNO :number;
          PROJNO :number;
          GDS :string;
          Stops :number;
          ItnryNotes :string;
          PCC :string;
          VLOCATOR :string;
          SUPPCODE :number;
          OperatingAIRID :string;
          OperatingAIRNAME :string;
          OperatingFlightNo :string;
          TICKETORDER :number;
          Stopover :string;
          PNR:string;
         

}
}
