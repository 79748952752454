import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchParentComponent } from './search-parent/search-parent.component';
import { SearchbyCarComponent } from './searchby-car/searchby-car.component';
import { SearchbyCruiseComponent } from './searchby-cruise/searchby-cruise.component';
import { SearchbyFolderComponent } from './searchby-folder/searchby-folder.component';
import { SearchbyHotelComponent } from './searchby-hotel/searchby-hotel.component';
import { SearchbyInsuranceComponent } from './searchby-insurance/searchby-insurance.component';
import { SearchbyOthersComponent } from './searchby-others/searchby-others.component';
import { SearchbyRailComponent } from './searchby-rail/searchby-rail.component';
import { SearchbyTourComponent } from './searchby-tour/searchby-tour.component';
import { SearchbyTransfersComponent } from './searchby-transfers/searchby-transfers.component';
import { FoldersearchlistComponent } from './foldersearchlist/foldersearchlist.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WindowModule, DialogModule, DialogsModule } from "@progress/kendo-angular-dialog";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { NgxSpinnerModule } from "ngx-spinner";
import {TranslateModule} from "@ngx-translate/core";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { GridModule,ExcelModule} from "@progress/kendo-angular-grid";
import { SharedMenuModule } from '../SharedMenu/sharedMenu.module';
import { RouterModule } from '@angular/router';
import { SearchRoutingModule } from './search-routing.module';
import { SharedCommonModule } from '../SharedCommonModule/shared-common.module';
import { ProductModule } from '../product/product.module';
import { MenusModule } from "@progress/kendo-angular-menu";


@NgModule({
  declarations: [
    SearchParentComponent,
    SearchbyCarComponent,
    SearchbyCruiseComponent,
    SearchbyFolderComponent,
    SearchbyHotelComponent,
    SearchbyInsuranceComponent,
    SearchbyOthersComponent,
    SearchbyRailComponent,
    SearchbyTourComponent,
    SearchbyTransfersComponent,
    FoldersearchlistComponent
  ],
  imports: [
    CommonModule,
    SharedCommonModule,
    SharedMenuModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    WindowModule,
    DialogModule,
    DialogsModule,
    ButtonsModule,
    InputsModule,
    NgxSpinnerModule,
    TranslateModule,
    DropDownsModule,
    DateInputsModule,
    GridModule,
    ExcelModule,
    ProductModule,
    SearchRoutingModule,
    MenusModule

  ],
  exports:[FoldersearchlistComponent]
})
export class SearchModule { }
