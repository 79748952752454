import { Component, OnInit ,Input} from '@angular/core';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-airticket-membership-loyalitypoints',
  templateUrl: './airticket-membership-loyalitypoints.component.html',
  styleUrls: ['./airticket-membership-loyalitypoints.component.scss']
})
export class AirticketMembershipLoyalitypointsComponent implements OnInit {

  constructor() { }
  @Input() airticketmemershp;
  ngOnInit() {
  }
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;

  public onChange(event: any): void {
      this.expandMode = parseInt(event.target.value, 10);
  }
  
  public onPanelChange(event: any): void { console.log('stateChange: ', event); }


}
