export const config = {
  appVersion: "1.0.59"
};
//     ConfigURI: 'https://penserverdev2.pensupport.co.uk/PenAirWebAPIWthReports/',//'http://192.168.1.6/PenAirWebApiService/'
//     ViewTripURL:'https://penserverdev2.pensupport.co.uk/PenAIR/DotnetGc/ViewTrip/login.aspx?Surname='
// };
// 'http://localhost:29048/'
// 'https://penserverdev2.pensupport.co.uk/PenAirWebApiService/'

//'https://penserverdev2.pensupport.co.uk/PenAirWebAPIWthReports/'
