import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from 'src/env.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { CommonserviceService } from './commonservice.service';
import { ProductUpdatePODTO } from '../Models/pocreation-detail';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  apiUrl :string;

  constructor(private http: HttpClient,private env: EnvService,public commonservice:CommonserviceService) {
    this.apiUrl=env.baseUrl;
   }

   addProduct(ProductDetails: any): Observable<any> {
    return this.http.post<any>(this.apiUrl+'AddProduct', ProductDetails, httpOptions).pipe(
      tap((productRes: any) => console.log(`added Product w/ id=${productRes.ResponseData.PASS}`)),
      catchError(this.commonservice.handleError<any>('AddProduct'))
    );
   }


}
