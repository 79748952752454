import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { distinctUntilChanged, tap, map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from './login.service';
import { LookUpDetails } from './look-up-details';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EnvService } from 'src/env.service';
import { promise } from 'protractor';
import { Users } from 'src/Models/users';
import { Cellparam, ReportParameterDTO, voucherRpt } from '../Models/common-class.model';
import { BankPaymentDTO } from '../Models/bank-payment-dto.model';
import { BankreconsilationExcelIndexDTO } from '../Models/banreconcile-pen0013';
import * as moment from 'moment';
import { ExcelExportData, WorkbookSheetRowCell } from '@progress/kendo-angular-excel-export';
import { NgxSpinnerService } from 'ngx-spinner';

import { DatePipe } from '@angular/common';
import { anyChanged } from '@progress/kendo-angular-common';
import { ResponseData } from '../Models/response-data';
import { ShareDataService } from './share-data.service'
import { GoogleAuthService } from './google-auth.service';

@Injectable({
  providedIn: 'root'
})
export class CommonserviceService {
  x = new XMLHttpRequest();
  BSPTAXEnable: any = 0;
  isurlexist: any = true;
  PrtyCodeFolder: any = 0;

  public productexist:any=false;
  newfolder: boolean = false;
  public showMsg: any;
  balance: any = 0.00;
  public submitted: boolean = false;
  public package: boolean = false;
  public submenuIndex: number = 0;
  public isfinalsaved: any = false;
  private currentUser1: Users;
  canacess: boolean = false;
  reportParms: any;
  reportParmsc: any;
  reportParmsm: any;
  public directpermission: any = false;
  public pagevalue: any = -1;
  folderEditMode: any = 'false';
  public cdateformat: string = '';
  public permissions: Array<string> = [];
  Tax: Array<LookUpDetails["ResponseData"]> = [];
  InputTax: Array<LookUpDetails["ResponseData"]> = [];
  OutputTax: Array<LookUpDetails["ResponseData"]> = [];
  public buycurrency: any;
  public TaxselectedItem: LookUpDetails["ResponseData"];
  public InputTaxselectedItem: LookUpDetails["ResponseData"];
  public OutTaxselectedItem: LookUpDetails["ResponseData"];
  public disablecurrency: any = false;
  public browserRefresh: boolean = false;
  issuvatonsupcomm: any = false;
  Culture: any;
  DecimalCount: any = 2;
  public currencySubMenuIndex: number = 0;
  public formData: ReportParameterDTO;
  public excformData: BankPaymentDTO;
  public voucherparam: voucherRpt;
  url: string;
  pdfurl: string;
  isvalidocddate: any = true;
  isnodeurlenabled: any = false;
  datevalue: any;
  masterData: any;
  footerData: any;
  constructor(private env: EnvService, private googleauthservice: GoogleAuthService, private datePipe: DatePipe, private http: HttpClient, private spinner: NgxSpinnerService, private loginservice: LoginService, private toastr: ToastrService, private translateapi: TranslateService, public apiShared: ShareDataService, private flashMessage: FlashMessagesService) { this.url = env.baseUrl; this.pdfurl = env.baseUrl, this.allData = this.allData.bind(this); }

  private currentUserSubject = new BehaviorSubject<any>({});
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error + ' from ' + operation); // log to console instead//By Caseid-12753

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  public async checkPermission1(permission: string): Promise<boolean> {
    this.currentUser1 = JSON.parse(localStorage.getItem('currentUser'));
    var data = await this.http.get<boolean>(this.url + 'GetPermissionstatus/' + this.currentUser1.ResponseData.UID + '/' + permission + '/' + this.directpermission).toPromise();
    this.canacess = data["ResponseData"];
    return this.canacess;
  }
  public async ConvertDateTimeAppTimeZone(stattime: any, endTime: any): Promise<any> {
    debugger;
    var form = new Object;
    form["Startdatetime"] = stattime;
    form["Enddatetime"] = endTime;
    var body = {
      ...form,


    };

    var data = await this.http.post<any>(this.url + 'UTCToTimeZoneDateTime', body).toPromise();

    var time = data["ResponseData"];
    return time;
  }
  public getDataDiff(startDate, endDate) {
    var diff = endDate.getTime() - startDate.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    return diff;
  }
  public GetTransilationMessage(message: string): string {

    this.translateapi.get([message]).subscribe((translations) => {
      return this.showMsg = translations[message];

    });
    return this.showMsg;
  }
  generateExcelFilterheader(sheet: any, filter, value, value2) {

    sheet.frozenRows = 2;
    var myHeaders = [{
      value: filter,
      textAlign: "left",
      background: "#60b5ff",
      color: "#ffffff"
    }, {
      value: value,
      fontSize: 15,
      textAlign: "left",
      background: "#FFFFFF",
      color: "#000000"
    },
    {
      value: value2,
      fontSize: 15,
      textAlign: "left",
      background: "#FFFFFF",
      color: "#000000"
    }

    ];

    sheet.rows.splice(0, 0, { cells: myHeaders, type: "header", height: 30 })
  }
  getFormattedDateAndTime(): string {
    return this.datePipe.transform((new Date), 'dd/MMM/yyyy h:mm:ss').toString();
  }
  async SetCompanyheaderExcelheader(sheet: any) {
    debugger;
    var valuearray = [];

    let altIdx = 0;
    //var data1 = await this.http.get<any>(this.url + 'GetReportFooterWithLogo').toPromise();
    let cellcount = 0;
    sheet.frozenRows = 2;
    if (sheet.rows[0].cells.length > 0) {
      cellcount = sheet.rows[0].cells.length;
    }
    sheet.columns.forEach((col) => {
      var value = '';
      var cellparam = new Cellparam();
      cellparam.value = '';
      valuearray.push(cellparam);
    })
    // if (valuearray.length > 0) {
    //   valuearray[valuearray.length - 1 - cellcount].value = data1["ResponseData"]["footerdetails"];
    // }
    let currentDateTime = this.datePipe.transform((new Date), 'dd/MMM/yyyy h:mm:ss').toString();
    let rows = sheet.rows;

    const footerRow0 = {
      height: 30,
      background: "#60b5ff",
      color: "#ffffff",
      cells: [{ value: '', fontSize: 15, colSpan: 1, }, { value: '', fontSize: 15, colSpan: 1 }],
    };

    rows.push(footerRow0);
    const footerRow = {
      height: 30,
      background: "#60b5ff",
      color: "#ffffff",
      cells: [{ value: 'Powered By Penguin ', fontSize: 15, colSpan: 1 }, { value: null, fontSize: 0, colSpan: 2 }, { value: null, fontSize: 0, colSpan: 1 }, { value: currentDateTime, fontSize: 15, colSpan: 1 }, { value: '', fontSize: 0, colSpan: 2 }, { value: '', fontSize: 0, colSpan: 2 }, { value: "", fontSize: 0, colSpan: 2 }, { value: 'Report Taken By:' + this.apiShared.uname, fontSize: 0, colSpan: 1 }, { value: "" }],
    };

    rows.push(footerRow);
    const footeremptyRow = {
      height: 30,
      background: "#60b5ff",
      color: "#ffffff",
      cells: [],
    };
    rows.push(footeremptyRow);
    const footeraddressRow = {
      height: 30,
      background: "#60b5ff",
      color: "#ffffff",
      cells: [{ value: ' ', fontSize: 15, colSpan: 1 }, { value: null, fontSize: 0, colSpan: 2 }, { value: null, fontSize: 0, colSpan: 1 }, { value: "", fontSize: 15, colSpan: 1 }, { value: '', fontSize: 0, colSpan: 2 }, { value: null, fontSize: 0, colSpan: 2 }, { value: "", fontSize: 0, colSpan: 2 }, { value: '', fontSize: 0, colSpan: 1 }, { value: "" }],
    };
    rows.push(footeraddressRow);
    debugger;
    // var valuearray=[]; 

    //     let altIdx = 0;
    //     let rows =sheet.rows;
    //     let cellcount=0;
    //     sheet.frozenRows = 2;
    //     if( sheet.rows[0].cells.length>0)
    //     {
    //       cellcount= sheet.rows[0].cells.length;
    //     }
    //   sheet.columns.forEach((col)=>{
    //    var value='';
    //    var cellparam=new Cellparam();
    //    cellparam.value='';
    //     sheet.rows[0].cells.push(cellparam);
    //     valuearray.push(cellparam);
    //     })
    //     if(valuearray.length>0)
    //     {
    //       valuearray[valuearray.length-1-cellcount].value=data1["ResponseData"]["footerdetails"];
    //     }



  }
  generateExcelMainheader(sheet: any, value) {

    sheet.frozenRows = 2;
    var myHeaders = [{
      // value: '',
      // textAlign: "left",
      // background: "#60b5ff",
      // color: "#ffffff"
    }, {
      value: '',
      fontSize: 15,
      textAlign: "left",
      background: "#FFFFFF",
      color: "#000000"
    },
    {
      value: '',
      fontSize: 15,
      textAlign: "left",
      background: "#FFFFFF",
      color: "#000000"
    },
    {
      value: '',
      fontSize: 15,
      textAlign: "left",
      background: "#FFFFFF",
      color: "#000000"
    },
    {
      value: value,
      fontSize: 25,
      textAlign: "left",
      background: "#FFFFFF",
      color: "#000000"
    }
    ];

    sheet.rows.splice(0, 0, { cells: myHeaders, type: "header", height: 30 })
  }
  public async BAreaAuthorisation(BArea: string): Promise<boolean> {
    this.currentUser1 = JSON.parse(localStorage.getItem('currentUser'));
    var data = await this.http.get<boolean>(this.url + 'GetBAreaAuthorisation/' + this.currentUser1.ResponseData.UID + '/' + BArea).toPromise();
    this.canacess = data["ResponseData"];
    return this.canacess;
  }
  public async BranchAuthorisation(Branch: string): Promise<boolean> {
    this.currentUser1 = JSON.parse(localStorage.getItem('currentUser'));
    var data = await this.http.get<boolean>(this.url + 'BranchAuthorisation/' + this.currentUser1.ResponseData.UID + '/' + Branch).toPromise();
    this.canacess = data["ResponseData"];
    return this.canacess;
  }
  public async SuppGroupAuthorisation(Supplier: string): Promise<boolean> {
    this.currentUser1 = JSON.parse(localStorage.getItem('currentUser'));
    var data = await this.http.get<boolean>(this.url + 'SuppGroupAuthorisation/' + this.currentUser1.ResponseData.UID + '/' + Supplier).toPromise();
    this.canacess = data["ResponseData"];
    return this.canacess;
  }
  showFlash1(flshmessage: string, cssClass: string, timeout: number) {
    this.translateapi.get([flshmessage]).subscribe((translations) => {
      this.showMsg = translations[flshmessage];
      if (this.flashMessage != undefined && this.flashMessage.show != undefined)
        this.flashMessage.show(this.showMsg, { cssClass: cssClass, timeout: timeout });
    });
  }
  public checkPermission(value: string) {
    this.currentUser1 = JSON.parse(localStorage.getItem('currentUser'));
    let hasPermission = false;
    var permission = sessionStorage.getItem('permissiondetailslist');

    this.permissions = JSON.parse(permission);
    const permissionFound = this.permissions.find(x => x.toUpperCase() === value.toUpperCase());

    if (permissionFound != null) {
      return hasPermission = true;
    }
    return hasPermission;
  }
  // ConvertToFixed2(value: any) {
  //   if (value.toFixed != undefined && value != NaN) {
  //     var decimalpoint = this.DecimalCount.replace("n", '');
  //     decimalpoint = +decimalpoint;
  //  return   value = value.toFixed(decimalpoint)
  //   }
  // }
  ConvertToFixed2(value: any) {
    if (value.toFixed != undefined && value != NaN) {
      var decimalpoint = this.DecimalCount.replace("n", '');
      decimalpoint = +decimalpoint;
      value = value.toFixed(decimalpoint)
    }
    return +value;
  }

  //      GetPermission(userid:string )
  //  {

  //    this.http.get<Permissionlist>(this.url+'GetPermissionList/'+userid).subscribe(permissionlist => {
  //     sessionStorage.setItem('permissiondetailslist', JSON.stringify(permissionlist.ResponseData))
  //     return
  //   });
  //  }
  GetfolersmryReportPdf(): Observable<ReportParameterDTO> {
    var body = {
      ...this.formData,


    };

    return this.http.post<ReportParameterDTO>(this.url + 'GetfolersmryReportPdf', body);

  }
  public ConvertDateViewPageFormat(date: any): string {
    debugger;
    var dateresult;
    try {

      dateresult = this.datePipe.transform(date, this.cdateformat + " HH:mm:ss");
      return dateresult;
    } catch (e) {
      return date;
    }
  }
  public ConvertDateViewPageWitoutTimeFormat(date: any): string {
    var dateresult;
    try {

      dateresult = this.datePipe.transform(date, this.cdateformat);
      return dateresult;
    } catch (e) {
      return date;
    }
  }
  public ConvertTimeViewPageFormat(date: any): string {
    var dateresult;
    try {

      dateresult = this.datePipe.transform(date, " HH:mm:ss");
      return dateresult;
    } catch (e) {
      return date;
    }
  }



  GetReportBodyOrSubject(): Observable<ReportParameterDTO> {
    var body = {
      ...this.formData,
      reportParmsc: this.reportParms,
      reportParmsm: this.reportParms,
      reportParms: this.voucherparam

    };

    return this.http.post<ReportParameterDTO>(this.url + 'GetReportBodyOrSubject', body);



    //  var response =   await this.http.get<any>(this.url+'GetPermissionstatus/'+userid+'/'+permission , { headers } ).toPromise();

    return// response.json;
  }



  public showSUCCESStoastrmsg(message: string, timeOut: number, closeButton: boolean, disableTimeOut: boolean) {
    this.translateapi.get([message]).subscribe((translations) => {
      this.showMsg = translations[message];
      this.toastr.success(this.showMsg, "", {
        timeOut: timeOut,
        disableTimeOut: disableTimeOut,
        closeButton: closeButton
      })

    });
  }
  public showWARNINGtoastrmsg(message: string, timeOut: number, closeButton: boolean, disableTimeOut: boolean) {
    this.translateapi.get([message]).subscribe((translations) => {
      this.showMsg = translations[message];
      this.toastr.warning(this.showMsg, "", {
        timeOut: timeOut,
        disableTimeOut: disableTimeOut,
        closeButton: closeButton,
      })
    });
  }
  GetAllLookup(provider: string): Observable<LookUpDetails[]> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.get<LookUpDetails[]>(this.url + '/GetAllLokup/' + provider,
      { headers });
  }
  GetDefaultCurrencyAndDate(): Observable<LookUpDetails[]> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.get<LookUpDetails[]>(this.url + '/GetDefaultCurrencyAndDate/',
      { headers });
  }
  public showsINFOtoastrmsg(message: string, timeOut: number, closeButton: boolean, disableTimeOut: boolean) {

    this.translateapi.get([message]).subscribe((translations) => {
      this.showMsg = translations[message];
      this.toastr.info(this.showMsg, "", {
        timeOut: timeOut,
        disableTimeOut: disableTimeOut,
        closeButton: closeButton,
      })
    });
  }


  async dateRangeValidation(fromDate, toDate) {
    debugger;
    var DateFrom = this.ConvertToLocaleDate(fromDate);
    var DateTo = this.ConvertToLocaleDate(toDate);
    if (Boolean(this.ValidDateRange(fromDate)) == false) {
      this.showWARNINGtoastrmsg('Messages.InvalidFromDate', 3000, true, false);
      this.spinner.hide();
      return false;
    }
    if (Boolean(this.ValidDateRange(toDate)) == false) {
      this.showWARNINGtoastrmsg('Messages.InvalidToDate', 3000, true, false);
      this.spinner.hide();
      return false;
    }
    if (new Date(toDate) < new Date(fromDate)) {
      this.showWARNINGtoastrmsg('Messages.fromdate_exceeds', 3000, true, false);
      this.spinner.hide();
      return false;
    }
  }

  public ConvertToLocaleDate(date: any) {
    // if(date.toLocaleDateString!=undefined)
    // {
    //   return  date=date.toLocaleDateString('en-GB', {
    //     day: 'numeric', month: 'short', year: 'numeric'
    //   }).replace(/ /g, '-');
    // }
    // else
    // {
    //   return  date;
    // }
    if (date != '' && date != null && date.toLocaleDateString == undefined) {
      date = new Date(date);
    }
    if (date.toLocaleDateString != undefined) {
      date = date.toLocaleDateString('en-GB', {
        day: '2-digit', month: 'short', year: 'numeric'
      }).replace(/ /g, '-');
    }
    else {
      date;
    }
    if (date == '1-Jan-1900')
      date = date.replace('1-Jan-1900', '01-Jan-1900')
    date = date.replace('Sept', 'Sep')
    return date;

  }

  public ConvertToDDMMLocaleDate(date: any) {
    // if(date.toLocaleDateString!=undefined)
    // {
    //   return  date=date.toLocaleDateString('en-GB', {
    //     day: 'numeric', month: 'short', year: 'numeric'
    //   }).replace(/ /g, '-');
    // }
    // else
    // {
    //   return  date;
    // }
    if (date != '' && date != null && date.toLocaleDateString == undefined) {
      date = new Date(date);
    }
    if (date.toLocaleDateString != undefined) {
      date = date.toLocaleDateString('en-GB', {
        day: '2-digit', month: '2-digit', year: 'numeric'
      }).replace(/ /g, '-');
    }
    else {
      date;
    }
    if (date == '1-Jan-1900')
      date = date.replace('1-Jan-1900', '01-Jan-1900')
    date = date.replace('Sept', 'Sep')
    return date;

  }
  public transformDate(date) {


    if (date.toLocaleDateString != undefined) {
      date = date.toLocaleDateString('en-GB', {
        day: '2-digit', month: 'short', year: 'numeric'
      }).replace(/ /g, '-');
    }
    else {
      date;
    }
    if (date == '1-Jan-1900')
      date = date.replace('1-Jan-1900', '01-Jan-1900')
    date = date.replace('Sept', 'Sep')
    return date;

  }
  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }
  addMonths(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }
  public showPermissiontoastrmsg(message: string, timeOut: number, closeButton: boolean, disableTimeOut: boolean, permissionID: string) {

    this.translateapi.get([message]).subscribe((translations) => {
      this.showMsg = translations[message];
      this.toastr.info(this.showMsg + permissionID + ')', "", {
        timeOut: timeOut,
        disableTimeOut: disableTimeOut,
        closeButton: closeButton,
      })
    });
  }
  public showsERRORtoastrmsg(message: string, timeOut: number, closeButton: boolean, disableTimeOut: boolean) {
    this.translateapi.get([message]).subscribe((translations) => {
      this.showMsg = translations[message];
      this.toastr.error(this.showMsg, "", {
        timeOut: timeOut,
        disableTimeOut: disableTimeOut,
        closeButton: closeButton,
      })
    });
  }
  ValidDateRange(feildDate: any) {
    if (new Date(feildDate) < new Date('01-Jan-1900') || new Date(feildDate) > new Date('06-Jun-2079')) {
      return false;
    }
    else {
      return true;
    }
  }
  //   downloadPDF(): any {
  //     return this.http.get(this.url+'/GetDefaultCurrencyAndDate/', {  responseType: 'blob' as 'json' }).subscribe(res => {
  //         var file = new Blob([res], { type: 'application/pdf' });            
  //         var fileURL = URL.createObjectURL(file);
  //         window.open(fileURL);
  //     })
  // }
  downloadPDF(): Observable<any> {

    return this.http.get<any>(this.url + '/viewpdf1/',
      {});
    // return this.http.get(this.url+'/viewpdf/', { responseType: 'blob', observe: 'response' }).pipe(
    //   map((result:HttpResponse<Blob>) => {
    //     console.log(result);
    //    // saveAs(result, "Quotation.pdf");
    //     return result;

    //   }))};

  }
  downloadPDF1(): any {
    return this.http.post(this.url + '/viewpdf/', {
      responseType: 'blob',
      observe: 'response'
    })
      .pipe(
        map((res: any) => {
          var blob = new Blob([res.blob()], { type: 'application/pdf' });
          return blob;
        })
      );
  }

  public GetCurrencyExchangeRateTolerance(): Observable<any> {
    var body = {
      ...this.excformData,

    };
    return this.http
      .post<any>(
        this.url + "GetCurrencyExchangeRateTolerance",
        body,

      )
      .pipe(
        tap((railRes: any) => console.log(``)),
        catchError(
          this.handleError<any>("exchrtolerence")
        )
      );
  }

  GetExcelHeaderIndex(Code: number, Table): Observable<any> {

    const url = `${this.url}` + 'GetExcelHeaderIndex' + `/${Code}` + `/${Table}`;
    return this.http.get<BankreconsilationExcelIndexDTO[]>(url).pipe(
      tap(_ => console.log(`fetched table id=${Code}`)),

    );
  }

  convertRtfToPlaintext(rtf) {
    rtf = rtf.replace(/\\par[d]?/g, "");
    return rtf.replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "").trim();
  }

  public async checkDateValidation(Datestring) {
    var date = this.transformDate(new Date(Datestring));
    var isdate = this.isvaliddateChecking(Datestring);
    var datep = moment(isdate);
    var dst = datep.isValid();
    if (dst == false) {
      date = moment(new Date(date)).format("YYYY-MM-DD");
      isdate = this.isvaliddateChecking(date);
    }

    if (isdate !== false && isdate != undefined) {
      return true;
    } else {
      return false;
    }
  }
  isnumeric(maybeNumber) {
    maybeNumber = parseFloat(maybeNumber);
    maybeNumber = +maybeNumber;
    if (isNaN(maybeNumber) === true) {
      return false;
    } else {
      return true;
    }
  }
  isValidDate(str, format) {
    var d = moment(str, format);
    if (d == null || !d.isValid()) return false;
    if (str.toISOString != undefined)
      return (
        str.toISOString().indexOf(d.format(format)) >= 0 ||
        str.toISOString().indexOf(d.format(format)) >= 0 ||
        str.toISOString().indexOf(d.format(format)) >= 0 ||
        str.toISOString().indexOf(d.format(format)) >= 0
      );
  }
  isvaliddateChecking(value: any): any | null {


    this.isvalidocddate = false;
    if (
      this.cdateformat.toLowerCase() == "dd/mm/yyyy" ||
      this.cdateformat.toLowerCase() == "dd-mm-yyyy" ||
      this.cdateformat.toLowerCase() == "dd/mmm/yyyy" ||
      this.cdateformat.toLowerCase() == "dd-mmm-yyyy"
    ) {
      if (typeof value === "string" && value.includes("/")) {
        const str = value.split("/");
        if (
          this.cdateformat.toLowerCase() == "dd/mmm/yyyy" ||
          this.cdateformat.toLowerCase() == "dd/mm/yyyy"
        ) {
          const year = Number(str[2]);
          const month = Number(str[1]);
          const date = Number(str[0]);
          if (year.toString().length != 2) {

            if (isNaN(month) == false) {
              if (month <= 9) {
                var d = "0" + str[1];
                dm = moment(+d, "MM").format("MMM");
              } else {
                dm = moment(month, "MM").format("MMM");
              }
              const date = Number(str[0]);
              if (month > 12 || date > 31) {
                this.isvalidocddate = false;
              } else {
                this.datevalue = year + "/" + dm + "/" + date;
                this.isvalidocddate = this.datevalue;
              }
            } else {
              this.datevalue = year + "/" + str[1] + "/" + str[0];
              this.isvalidocddate = this.datevalue;
            }
          } else {

            var datem = this.transformDate(new Date(value));

            this.isvalidocddate = datem;
          }
        } else {
          const year = Number(str[2]);
          const month = Number(str[1]);
          var dm = "";
          if (isNaN(month) == false) {
            if (month <= 9) {
              var d = "0" + str[1];
              dm = moment(+d, "MM").format("MMM");
            } else {
              dm = moment(month, "MM").format("MMM");
            }
            const date = Number(str[0]);
            if (month > 12 || date > 31) {
              this.isvalidocddate = false;
            } else {
              this.datevalue = year + "/" + dm + "/" + date;
              this.isvalidocddate = this.datevalue;
            }
          } else {
            this.datevalue = year + "/" + str[1] + "/" + str[0];
            this.isvalidocddate = this.datevalue;
          }
        }
      } else if (typeof value === "string" && value.includes("-")) {
        const str = value.split("-");
        if (
          this.cdateformat.toLowerCase() == "dd-mmm-yyyy" ||
          this.cdateformat.toLowerCase() == "dd-mm-yyyy"
        ) {
          const year = Number(str[2]);
          const month = Number(str[1]);
          const date = Number(str[0]);
          if (year.toString().length != 2) {
            this.datevalue = year + "/" + month + "/" + date;
            this.isvalidocddate = this.datevalue;
          } else {
            var datem = this.transformDate(new Date(value));

            this.isvalidocddate = datem;
          }
        } else {
          const year = Number(str[2]);
          const month = Number(str[1]);
          var dm = "";
          if (isNaN(month) == false) {
            if (month <= 9) {
              var d = "0" + str[1];
              dm = moment(+d, "MM").format("MMM");
            } else {
              dm = moment(month, "MM").format("MMM");
            }
            const date = Number(str[0]);
            if (month > 12 || date > 31) {
              this.isvalidocddate = false;
            } else {
              this.datevalue = year + "/" + dm + "/" + date;
              this.isvalidocddate = this.datevalue;
            }
          } else {
            this.datevalue = year + "/" + str[1] + "/" + str[0];
            this.isvalidocddate = this.datevalue;
          }
        }
      } else {
        this.isvalidocddate = false;
      }
    } else if (
      this.cdateformat.toLowerCase() == "mm/dd/yyyy" ||
      this.cdateformat.toLowerCase() == "mm-dd-yyyy"
    ) {
      if (typeof value === "string" && value.includes("/")) {
        const str = value.split("/");

        const year = Number(str[2]);
        const month = Number(str[0]);
        const date = Number(str[1]);
        if (month > 12 || date > 31) {
          this.isvalidocddate = false;
        } else {
          // this.datevalue = year + "-" + month + "-" + date;
          // this.isvalidocddate = this.datevalue;

          if (year.toString().length != 2) {
            this.datevalue = year + "-" + month + "-" + date;
            this.isvalidocddate = this.datevalue;
          } else {
            var datem = this.transformDate(new Date(value));

            this.isvalidocddate = datem;
          }
        }
      } else if (typeof value === "string" && value.includes("-")) {
        const str = value.split("-");

        const year = Number(str[2]);
        const month = Number(str[0]);
        const date = Number(str[1]);
        if (month > 12 || date > 31) {
          this.isvalidocddate = false;
        } else {
          if (year.toString().length != 2) {
            this.datevalue = year + "-" + month + "-" + date;
            this.isvalidocddate = this.datevalue;
          } else {
            var datem = this.transformDate(new Date(value));
            this.isvalidocddate = datem;
          }

          // this.datevalue = year + "-" + month + "-" + date;
          // this.isvalidocddate = this.datevalue;
        }
      } else {
        this.isvalidocddate = false;
      }
    } else if (
      this.cdateformat.toLowerCase() == "yyyy/mm/dd" ||
      this.cdateformat.toLowerCase() == "yyyy-mm-dd"
    ) {
      if (typeof value === "string" && value.includes("/")) {
        const str = value.split("/");

        const year = Number(str[0]);
        const month = Number(str[1]);
        const date = Number(str[2]);
        if (month > 12 || date > 31) {
          this.isvalidocddate = false;
        } else {
          if (year.toString().length != 2) {
            this.datevalue = year + "-" + month + "-" + date;
            this.isvalidocddate = this.datevalue;
          } else {
            var datem = this.transformDate(new Date(value));

            this.isvalidocddate = datem;
          }
          // this.datevalue = year + "-" + month + "-" + date;
          // this.isvalidocddate = this.datevalue;
        }
      } else if (typeof value === "string" && value.includes("-")) {
        const str = value.split("-");

        const year = Number(str[0]);
        const month = Number(str[1]);
        const date = Number(str[2]);
        if (month > 12 || date > 31) {
          this.isvalidocddate = false;
        } else {
          // this.datevalue = year + "-" + month + "-" + date;
          // this.isvalidocddate = this.datevalue;

          if (year.toString().length != 2) {
            this.datevalue = year + "-" + month + "-" + date;
            this.isvalidocddate = this.datevalue;
          } else {
            var datem = this.transformDate(new Date(value));

            this.isvalidocddate = datem;
          }
        }
      } else {
        this.isvalidocddate = false;
      }
    }
    if (this.isvalidocddate == false) {
      var isdates = this.isValidDate(value, this.cdateformat.toLowerCase());
      this.isvalidocddate = isdates;
      if (this.isvalidocddate == false) {
        this.isvalidocddate = this.convertmomentdate(
          value,
          this.cdateformat.toLowerCase()
        );
        //.format("MM/dd/yyyy")
      }
    }
    return this.isvalidocddate;
  }
  convertmomentdate(value, format) {
    var lastresult = this.splitdate(value);
    var offset = moment().utcOffset();
    //value=this.convertUTCDateToLocalDate(new Date(value));
    var value2 = moment(lastresult).format("YYYY-MM-DD");

    value = value2;
    if (
      this.cdateformat.toLowerCase() == "dd/mm/yyyy" ||
      this.cdateformat.toLowerCase() == "dd-mm-yyyy" ||
      this.cdateformat.toLowerCase() == "dd/mmm/yyyy" ||
      this.cdateformat.toLowerCase() == "dd-mmm-yyyy"
    ) {
      if (typeof value === "string" && value.includes("/")) {
        const str = value.split("/");
        if (this.cdateformat.toLowerCase() == "dd/mmm/yyyy") {
          const year = Number(str[2]);
          const month = Number(str[1]);
          const date = Number(str[0]);

          this.datevalue = year + "-" + month + "-" + date;
          this.isvalidocddate = this.datevalue;
        } else {
          const year = Number(str[2]);
          const month = Number(str[1]);
          const date = Number(str[0]);
          if (month > 12 || date > 31) {
            this.isvalidocddate = false;
          } else {
            this.datevalue = year + "-" + month + "-" + date;
            this.isvalidocddate = this.datevalue;
          }
        }
      } else if (typeof value === "string" && value.includes("-")) {
        const str = value.split("-");
        if (this.cdateformat.toLowerCase() == "dd-mmm-yyyy") {
          const year = Number(str[2]);
          const month = Number(str[1]);
          const date = Number(str[0]);

          this.datevalue = year + "-" + month + "-" + date;
          this.isvalidocddate = this.datevalue;
        } else {
          const year = Number(str[2]);
          const month = Number(str[1]);
          const date = Number(str[0]);
          if (month > 12 || date > 31) {
            this.isvalidocddate = false;
          } else {
            this.datevalue = year + "-" + month + "-" + date;
            this.isvalidocddate = this.datevalue;
          }
        }
      } else {
        this.isvalidocddate = false;
      }
    } else if (
      this.cdateformat.toLowerCase() == "mm/dd/yyyy" ||
      this.cdateformat.toLowerCase() == "mm-dd-yyyy"
    ) {
      if (typeof value === "string" && value.includes("/")) {
        const str = value.split("/");

        const year = Number(str[2]);
        const month = Number(str[0]);
        const date = Number(str[1]);
        if (month > 12 || date > 31) {
          this.isvalidocddate = false;
        } else {
          this.datevalue = year + "-" + month + "-" + date;
          this.isvalidocddate = this.datevalue;
        }
      } else if (typeof value === "string" && value.includes("-")) {
        const str = value.split("-");

        const year = Number(str[2]);
        const month = Number(str[0]);
        const date = Number(str[1]);
        if (month > 12 || date > 31) {
          this.isvalidocddate = false;
        } else {
          this.datevalue = year + "-" + month + "-" + date;
          this.isvalidocddate = this.datevalue;
        }
      } else {
        this.isvalidocddate = false;
      }
    } else if (
      this.cdateformat.toLowerCase() == "yyyy/mm/dd" ||
      this.cdateformat.toLowerCase() == "yyyy-mm-dd"
    ) {
      if (typeof value === "string" && value.includes("/")) {
        const str = value.split("/");

        const year = Number(str[0]);
        const month = Number(str[1]);
        const date = Number(str[2]);
        if (month > 12 || date > 31) {
          this.isvalidocddate = false;
        } else {
          this.datevalue = year + "-" + month + "-" + date;
          this.isvalidocddate = this.datevalue;
        }
      } else if (typeof value === "string" && value.includes("-")) {
        const str = value.split("-");

        const year = Number(str[0]);
        const month = Number(str[1]);
        const date = Number(str[2]);
        if (month > 12 || date > 31) {
          this.isvalidocddate = false;
        } else {
          this.datevalue = year + "-" + month + "-" + date;
          this.isvalidocddate = this.datevalue;
        }
      } else {
        this.isvalidocddate = false;
      }
    }
    return this.isvalidocddate;
  }
  splitdate(date) {
    if (date.split == undefined) {
      return date;
    }
    var a = date.split(" ");
    var date = a[0];
    var time = a[1];
    return date;
  }
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // // create a view into the buffer
    var ia = new Uint8Array(ab);

    // // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });

    return blob;
  }
  public allData(data: any): ExcelExportData {
    debugger;
    const result: ExcelExportData = {
      data: this.masterData
    };
    return result;
  }
  public fileExists(url: string) {
    debugger;
    var xhr = new XMLHttpRequest();
    // url = 'assets//Excel_Download//test13.xlsx'
    xhr.open('HEAD', url, false);
    xhr.send();

    if (xhr.status == +"404") {
      //"File Not Found"
      return false;
    } else {

      return true;
    }
  }
  ConvertTo4digit(num: any, size: any) {
    debugger;

    let s = num + "";
    while (s.length < size) {
      s = "0" + s;
    }
    return s;


  }



  urlExists(url) {
    return fetch(url, { mode: "no-cors" })
      .then(res => true)
      .catch(err => false)
  }

  public ConvertToLocaleDatetime(datetime: any) {
    if (datetime !== '' && datetime !== null && datetime.toLocaleDateString === undefined) {
      datetime = new Date(datetime);
    }

    // Create a new Date object using the extracted string
    const twilioDate = new Date(datetime);

    const formattedDateTime = moment(twilioDate).format('DD/MM/YYYY h:mm:ss A');
    console.log(formattedDateTime);
    return formattedDateTime;
  }
  DateRangeDiff(fromDate: any, toDate: any, days: any) {

    var firstDate = moment(fromDate);
    var secondDate = moment(toDate);
    var diffInDays = Math.abs(firstDate.diff(secondDate, 'days'));
    if (diffInDays > days) {
      return false;
    }
    else {
      return true;
    }
  }
  GetTaxSplit(TaxType: any): Observable<any> {
    const url = `${this.url}` + 'GetTaxSplit' + `/${TaxType}`;
    return this.http.get<any>(url);
  }

}
