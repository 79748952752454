import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Alertdetails } from '../../Models/alertdetails';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertserviceService } from '../../Services/alertservice.service';
import { NgxSpinnerService } from "ngx-spinner";
import { observable, Observable, Subscription } from 'rxjs';
import { ShareDataService } from '../../Services/share-data.service';
import { PendoclockService } from '../../Services/pendoclock.service';
import { Pendoclock } from '../../Models/pendoclock';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { DataSourceRequestState, DataResult, CompositeFilterDescriptor, filterBy, FilterDescriptor, State, SortDescriptor, orderBy } from '@progress/kendo-data-query'
import { CommonserviceService } from '../../Services/commonservice.service';
import { ToastrService } from 'ngx-toastr';
import { PaginationServiceService } from '../../Services/pagination-service.service';
import { PublicLayoutComponent } from '../../public-layout/public-layout.component';
import { FolderSearchCriteriaDTO } from '../../Models/folder-search-criteria-dto';
import { ContextMenuComponent } from '@progress/kendo-angular-menu';
import { FolderRefundreset } from '../../Models/folder-refundreset';
import { InvoiceService } from '../../Services/invoice.service';
// import { AlertlistService, CategoriesService1 } from 'src/Services/alertlist.service';
// const flatten = filter => {
// const filters = filter.filters;
// if (filters) {
// return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
// }
// return [];
// };

@Component({
    selector: 'app-foldersearchlist',
    templateUrl: './foldersearchlist.component.html',
    styleUrls: ['./foldersearchlist.component.scss']
})
export class FoldersearchlistComponent implements OnInit {
    @ViewChild('gridmenu') public gridContextMenu: ContextMenuComponent;
    private subscription: Subscription = new Subscription();
    @Input() passengerNames: String;
    @Output() selectedPassenger: EventEmitter<string> = new EventEmitter();
    public rptFolderDetails = {
        orderNo: '',
        projNo: '',
        brName: '',
        atolattachement: false
    }
   @Input() package: any;
    public invoiceFormatItems: Array<string> = ["PackageItinerary7",
        "InvoicewithItinerary 02", "Car Voucher", "Accommodation Voucher"];
    public passenger: any = '';
    public showMsg: any;
    public ConfirmWindow: any;
    public confirmMsg: any;
    public gridData: GridDataResult;
    public alertms: string;
    public passengerName: any;
    public folderStatus: any;
    Messages_no_records: any = '';
    public refundReset: FolderRefundreset;
    lockStatus: any;
    public formeditable = true;
    folderSearchCriteriaDto: FolderSearchCriteriaDTO = localStorage.getItem('SrchTranferDTO') === null ? new FolderSearchCriteriaDTO() : JSON.parse(localStorage.getItem('SrchTranferDTO'));

    public filter: CompositeFilterDescriptor;
    orderNo: any;
    projNo: any;
    public folderList: any[];
    public stateUser: State = {
        skip: 0,
        //take: 10,
        // sort: [{
        // dir: "asc",
        // field: "userName"
        // }]
    };
    public sort: SortDescriptor[] = [
        {
            field: 'CRDATE',
            dir: 'desc'
        }
    ];
    totRows: number;
    public StatusList: any[];
    public ActionList: any[];
    private view: Observable<GridDataResult>;
    public pageSize: number = 10;
    private categoryFilter: any[] = [];
    public equals: 'equals';
    public contains: 'contain'
    public STATUSNAME: any = 'STATUSNAME'
    public folderEditMode: any
    public Componentname: string;
    //alertmsg:string;
    penDockLock: Pendoclock = {
        IsSuccess: true,
        Message: "succcess",
        ResponseData: {

            LDocType: "",
            LDocNo: "",
            UID: 0,
            LDATE: "",
            LTIME: "",
            LPROJNO: "",
            LYearID: "",
            LAutoNo: "",
            Uname: "",
            Source: ""

        }
    };
    loadBookingRpt = false;
    openRefund = false;
    Ptype = 'RefundRequest';
    //openBooking=false;
    public items: any[] = [
        { text: 'Email Booking Confirmation', id: 'EBC' },
        { text: 'Refund Request', id: 'RR' },
        {
            text: 'Copy to', items:
                [{ text: 'Sub Folder', id: 'SF' },
                { text: 'Sub Folder with Pax', id: 'SWP' },
                { text: 'Sub Folder without Pax', id: 'SWOP' },
                { text: 'New Folder', id: 'NF' }]
        },
        { text: 'Delete Refund', id: 'DR' },
        { text: 'Quick email', id: 'QE' }
    ];
    public itemsCopy: any[]
    private contextItem: any;
    public rowIndex: any;
    @Input() defaultReportSelected = '';
    selectedReport = '';
    constructor(public commonservice: CommonserviceService, private layout: PublicLayoutComponent, private toastr: ToastrService, public common: CommonserviceService, private AlertserviceService: AlertserviceService, private route: ActivatedRoute,
        private router: Router, private apiShared: ShareDataService, private paginationservice: PaginationServiceService,
        private spinner: NgxSpinnerService, private penLock: PendoclockService, private translateapi: TranslateService, private publiclayout: PublicLayoutComponent, private invoice: InvoiceService) {
        this.Messages_no_records = "";
        //this.service.query(this.state);
    }

    ngOnInit() {
        sessionStorage.setItem('PassengerNames', '');
        sessionStorage.setItem('FolderStatus', '');
        localStorage.removeItem('OrderType');
        //this.apiShared.Setapishareddata(this.folderStatus,'FolderStatus');
        this.layout.headerlanguage = sessionStorage.setItem('headerlanguage', null);
        this.layout.mainmenutransilate = sessionStorage.setItem('mainmenutransilate', null);
        this.layout.gettransilateheadertest();
        this.orderNo = "";
        this.orderNo = this.apiShared.orderNo;
        //this.publiclayout.headerText = "Inbox";
        //this.alertlist.query(this.state);
        // this.AlertserviceService.fetch(this.state).subscribe(r => this.products = r);
        this.selectedPassenger.emit('');
        this.layout.folderstatus = '';
        //this.spinner.show();

        if (this.folderSearchCriteriaDto.enableSearch == undefined) {
            this.folderSearchCriteriaDto.enableSearch = true;
        }
        this.getFolderList();
        /** spinner ends after 5 seconds */

        this.lockStatus = this.apiShared.folderReadOnly;
        if (this.lockStatus == "0" && this.orderNo != "") {
            this.deletePenLock("Folder", this.orderNo);
        }
        this.GetStatusList();
        this.GetActionList();
        this.common.folderEditMode = 'false'

        this.Componentname = "Foldersearchlist";
        //this.Componentname=(this.route.snapshot.routeConfig.component.name).replace("Component","");//12654
        sessionStorage.setItem('Componentname', this.Componentname);


        this.itemsCopy = [...this.items];//13157
    }
    public pageChangeUser(event: PageChangeEvent): void {

        this.stateUser.skip = event.skip;
        this.getFolderList();
    }
    public dataStateChange(state: DataStateChangeEvent): void {


    }
    getFolderList() {

        this.spinner.show();
        if (this.folderSearchCriteriaDto.enableSearch == true) {
            var pageno = (this.stateUser.skip / this.pageSize) + 1;
            this.paginationservice.GetSearchResult(this.pageSize, pageno).subscribe(res => {
                debugger;
                this.spinner.hide();
                //alert(JSON.stringify(res.ResponseData));
                if (res.ResponseData != null && res.ResponseData.length > 0) {
                    this.totRows = res.ResponseData[0] != undefined ? res.ResponseData[0].tot_rows : 0
                    this.folderList = res.ResponseData;
                    this.gridData = {
                        data: orderBy(res.ResponseData, this.sort),
                        total: res.ResponseData[0] != undefined ? res.ResponseData[0].tot_rows : 0
                    };
                    this.spinner.hide();
                }
                else {
                    this.gridData = {
                        data: [],
                        total: 0
                    };

                    this.subscription.add(this.translateapi.get(['Messages.no_records']).subscribe((translations) => {
                        this.Messages_no_records = translations['Messages.no_records'];
                        // alert(this.Messages_no_records)
                    }));
                    this.spinner.hide();
                }

            });
        }

    }
    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.gridData = {
            data: orderBy(this.folderList, this.sort),
            total: this.totRows
        };
    }
    public GetStatusList() {

        this.subscription.add(this.paginationservice.GetStatusList(1).subscribe(data => {
            this.StatusList = data['ResponseData'];
        }));
    }
    public GetActionList() {
        this.subscription.add(this.paginationservice.GetStatusList(2).subscribe(data => {
            this.ActionList = data['ResponseData'];
        }));
    }

    deletePenLock(docType, docno) {
        this.penLock.DeleteLock(docType, docno + "/").subscribe(res => {

        }, (err) => {
            console.log(err);

        }
        );
    }

    folderReadOnly: any;

    public onExcelExport(e: any): void {
        const rows = e.workbook.sheets[0].rows;

        // align multi header
        rows[0].cells[2].hAlign = 'center';

        // set alternating row color
        // let altIdx = 0;
        // rows.forEach((row) => {
        // if (row.type === 'data') {
        // if (altIdx % 2 !== 0) {
        // row.cells.forEach((cell) => {
        // cell.background = '#d3d3d3';
        // });
        // }
        // altIdx++;
        // }
        // });
    }


    onCellClick(e) {
        debugger;
        if (e.type === "contextmenu" || e.columnIndex == 0) {
            const originalEvent = e.originalEvent;
            originalEvent.preventDefault();
            this.contextItem = e.dataItem;
            debugger;
            // alert(JSON.stringify(this.contextItem))
            //this.orderNoSelected=e.dataItem.FOLDERNO;
            // this.refresh(e.dataItem.FOLDERNO);
            this.items = [...this.itemsCopy];
            if (e.dataItem.STATUS != 3 && e.dataItem.STATUS != 5) {
                this.items.splice(3, 1)
            }
            if (e.dataItem.STATUS == 4 && e.dataItem.INVOICENO == 0) {
                this.rowIndex = e.rowIndex;
                this.items.splice(1, 1, { text: 'Reset Folder', id: 'RT' })
            }

            this.orderNo = e.dataItem.FOLDERNO;
            this.gridContextMenu.show({
                left: originalEvent.pageX,
                top: originalEvent.pageY,
            });
        }
        else {

            this.spinner.show();
            let clickedRowItem = e.dataItem;
            //alert((new Date()).getFullYear());
            var year = new Date(clickedRowItem.CRDATE).getFullYear()
            if (year <= 2018) {
                this.subscription.add(this.translateapi.get(['Messages.Invalidproductguid1st']).subscribe((translations) => {
                    this.showMsg = translations['Messages.Invalidproductguid1st'];
                }));
                this.subscription.add(this.translateapi.get(['Messages.Invalidproductguid2nd']).subscribe((translations) => {
                    this.showMsg = this.showMsg + " " + 2018 + " ." + translations['Messages.Invalidproductguid2nd'];
                }));
                this.toastr.warning(this.showMsg, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                })
                this.spinner.hide();
            }
            else {
                //this.apiShared.orderNo = clickedRowItem.FOLDERNO;
                this.apiShared.Setapishareddata(clickedRowItem.FOLDERNO, 'orderNo');
                this.apiShared.Setapishareddata(clickedRowItem.PROJNO, 'projNo');
                this.passengerName = (clickedRowItem.Pass == null ? "" : clickedRowItem.Pass);
                let arry = this.passengerName.split(" ");
                if (arry.length > 0)
                    this.passengerName = arry[arry.length - 1].toString();
                //this.apiShared.PassengerName = this.passengerName;
                this.apiShared.Setapishareddata(this.passengerName, 'PassengerName');
                // this.apiShared.PassengerNames = clickedRowItem.Pass;
                this.apiShared.Setapishareddata(clickedRowItem.Pass, 'PassengerNames');
                //alert(JSON.stringify(this.apiShared.PassengerNames));
                if (clickedRowItem.Pass !== "" && clickedRowItem.Pass !== null) {
                    this.passenger = this.apiShared.PassengerNames + ' - ' + this.apiShared.orderNo;
                }
                else {
                    this.passenger = this.apiShared.orderNo;
                }
                this.folderStatus = clickedRowItem.STATUSNAME;
                //this.apiShared.FolderStatus = this.folderStatus;

                this.apiShared.Setapishareddata(this.folderStatus, 'FolderStatus');
                this.getLockDetails("Folder", clickedRowItem.FOLDERNO, this.apiShared.uid, clickedRowItem.PROJNO);
            }

            this.folderStatus = this.apiShared.FolderStatus;
            this.layout.folderstatus = this.folderStatus;
            if (clickedRowItem.STATUSNAME == "Cancelled") {
                this.formeditable = false;
                this.lockStatus = "1";
                this.apiShared.folderReadOnly = this.lockStatus;
            }

            this.folderEditMode = "false";
            this.common.folderEditMode = 'false'

            //this.apiShared.folderEditMode=this.folderEditMode;
            this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');

        }
    }
    onPopupClose(context) {
        this.contextItem = null;
    }
    public rowCallback = (context: RowClassArgs) => {
        if (this.contextItem) {
            const isActive = context.dataItem.FOLDERNO == this.contextItem.FOLDERNO;
            return {
                selected: isActive
            };
        }
        if (context.dataItem.STATUS == 3 || context.dataItem.STATUS == 5 || context.dataItem.STATUS == 6) {
            return {
                refund: true
            };
        }
    }
    public onSelect({ item }): void {
        //const index = this.gridData.indexOf(this.contextItem);
        debugger;
        switch (item.id) {
            case 'EBC':
                this.onBookingConfirmation(this.contextItem);
                break;
            case 'RR':
                this.onRefundRequest(this.contextItem);
                break;
            case 'NF'://Copy to New Folder-- copyType=1
                var newfolder = {
                    FOLDERNO: this.contextItem.FOLDERNO,
                    PROJNO: this.contextItem.PROJNO,
                    UID: this.apiShared.uid,
                    copyType: 1
                }
                this.CopyToFolder(newfolder);
                break;
            case 'SF'://Copy to subfolder-- copyType=2
                var subfolder = {
                    FOLDERNO: this.contextItem.FOLDERNO,
                    PROJNO: this.contextItem.PROJNO,
                    UID: this.apiShared.uid,
                    copyType: 2
                }
                this.CopyToFolder(subfolder);
                break;
            case 'SWP'://Copy to subfolder with pax-- copyType=3
                var subfolder = {
                    FOLDERNO: this.contextItem.FOLDERNO,
                    PROJNO: this.contextItem.PROJNO,
                    UID: this.apiShared.uid,
                    copyType: 3
                }
                this.CopyToFolder(subfolder);
                break;
            case 'SWOP'://Copy to subfolder without pax-- copyType=4
                var subfolder = {
                    FOLDERNO: this.contextItem.FOLDERNO,
                    PROJNO: this.contextItem.PROJNO,
                    UID: this.apiShared.uid,
                    copyType: 4
                }
                this.CopyToFolder(subfolder);
                break;
            case 'DR':
                this.onRefundReset();
                break;

            case 'RT':

                this.onCancelledFolderReset(this.contextItem.FOLDERNO, this.contextItem.PROJNO);
                break;


            default:
                break;
        }
    }

    async onBookingConfirmation(item) {
        if (Boolean(await this.common.checkPermission1('01.12.01.09.02')) == true) {
            this.rptFolderDetails = {
                orderNo: item.FOLDERNO,
                projNo: item.PROJNO,
                brName: '',
                atolattachement: false
            };
            this.rptFolderDetails.orderNo = item.FOLDERNO;
            this.rptFolderDetails.projNo = item.PROJNO
            this.loadBookingRpt = true;
        }
        else {
            this.showmsg('01.12.01.09.02');
        }
    }
    async onRefundRequest(item) {
        debugger;
        // sessionStorage.getItem('folderExchangeRate');
        // this.refunddata.FCID = sessionStorage.getItem('folderCurrency');
        this.projNo=item.PROJNO;
        if (item.STATUS == 3 || item.STATUS == 4 || item.STATUS == 5 || item.STATUS == 6) {
            this.subscription.add(this.translateapi.get(['Messages.refundNotAllowed']).subscribe((translations) => {
                this.showMsg = translations['Messages.refundNotAllowed'];
                this.toastr.warning(this.showMsg, "", {
                    timeOut: 3000,
                    disableTimeOut: false,
                    closeButton: true
                })
            }));
            return;
        } else {
            if (Boolean(await this.common.checkPermission1('01.12.01.06.02')) == true) {
                var result = await this.checkLockStatus("Folder", item.FOLDERNO, this.apiShared.uid, item.PROJNO);
                if (result != '') {
                    this.toastr.warning(result, "", {
                        timeOut: 3000,
                        disableTimeOut: false,
                        closeButton: true
                    })
                } else {
                    this.folderStatus = item.STATUSNAME;
                    this.apiShared.Setapishareddata(this.folderStatus, 'FolderStatus');
                    debugger
                    var folderexrate = item.FC_CIDExRate;
                    this.apiShared.Setapishareddata(folderexrate, 'folderExchangeRate');
                    var foldercurrency = item.FC_CID;
                    this.apiShared.Setapishareddata(foldercurrency, 'folderCurrency');
                    this.orderNo = item.FOLDERNO;
                    this.openRefund = true;
                }
            }
            else {
                this.showmsg('01.12.01.06.02');
            }
        }

    }
    public async CopyToFolder(folderDetails) { // caseid:12656
        if (this.contextItem.STATUS == 3 || this.contextItem.STATUS == 4 || this.contextItem.STATUS == 5 || this.contextItem.STATUS == 6) {
            this.subscription.add(this.translateapi.get(['Messages.folderCopyNotAllowed']).subscribe((translations) => {
                this.showMsg = translations['Messages.folderCopyNotAllowed'];
                this.toastr.warning(this.showMsg, "", {
                    timeOut: 3000,
                    disableTimeOut: false,
                    closeButton: true
                })
            }));
            return;
        }
        if (Boolean(await this.common.checkPermission1("01.12.01.11")) == true) {
            this.spinner.show();
            this.subscription.add(this.paginationservice.CopyToFolder(folderDetails)
                .subscribe(data => {
                    if (data != null && data.IsSuccess === true && data.Message === 'SUCCESS') {
                        this.orderNo = data.ResponseData.FOLDERNO;
                        this.projNo = data.ResponseData.PROJNO;
                        this.spinner.hide();
                        this.apiShared.Setapishareddata(0, 'folderReadOnly');
                        this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
                        this.apiShared.Setapishareddata(this.projNo, 'projNo');
                        this.passengerName = "";
                        this.folderStatus = "Saved";
                        this.apiShared.Setapishareddata(this.folderStatus, 'FolderStatus');
                        this.apiShared.Setapishareddata(this.passengerName, 'PassengerName');
                        this.layout.headerPassenger = this.orderNo;
                        this.getLockDetails("Folder", this.orderNo, this.apiShared.uid, this.projNo);
                        if (folderDetails.copyType == 1) {
                            this.subscription.add(this.translateapi.get(['Messages.newfolder_saved']).subscribe((translations) => {
                                this.showMsg = translations['Messages.newfolder_saved'];
                                this.toastr.success(this.showMsg, "", {
                                    timeOut: 3000,
                                    disableTimeOut: false,
                                    closeButton: true
                                })
                            }));
                        } else {
                            this.subscription.add(this.translateapi.get(['Messages.subfolder_saved']).subscribe((translations) => {
                                this.showMsg = translations['Messages.subfolder_saved'];
                                this.toastr.success(this.showMsg, "", {
                                    timeOut: 3000,
                                    disableTimeOut: false,
                                    closeButton: true
                                })
                            }));
                        }

                        this.router.navigate(['PenAir/Folder/FolderSummary', this.orderNo], { skipLocationChange: true });
                    } else {
                        this.spinner.hide();
                        this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                            this.showMsg = translations['Messages.some_error_in_save'];
                            this.toastr.warning(this.showMsg, "", {
                                timeOut: 3000,
                                disableTimeOut: false,
                                closeButton: true
                            })
                        }));
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                        this.showMsg = translations['Messages.some_error_in_save'];
                        this.toastr.warning(this.showMsg, "", {
                            timeOut: 3000,
                            disableTimeOut: false,
                            closeButton: true
                        })
                    }));
                }));;
        } else {
            this.showmsg('01.12.01.11');
            //this.common.showPermissiontoastrmsg("Messages.Permissiondenied", 5000, true, false,"01.12.01.11")
        }


    }
    public async onNew() {
        localStorage.setItem('alerturl', this.router.url)
        if (Boolean(await this.common.checkPermission1("01.12.01.11")) == true) {
            this.orderNo = 0;
            //this.apiShared.orderNo = this.orderNo;
            this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
            //this.router.navigate(['/PenAir/Folder/FolderSummaryEdit', "0"],{skipLocationChange:true});
            this.router.navigate(['/PenAir/RetailPackageFolder'], { skipLocationChange: true });
        }
        else {
            this.showmsg('01.12.01.11');
            //this.common.showPermissiontoastrmsg("Messages.Permissiondenied", 5000, true, false,"01.12.01.11")
        }

    }
    public async onNewpackage() {
        localStorage.setItem('alerturl', this.router.url)
        if (Boolean(await this.common.checkPermission1("01.12.01.11")) == true) {
            this.orderNo = 0;
            //this.apiShared.orderNo = this.orderNo;
            this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
            this.router.navigate(['/PenAir/Folder/PackageSummaryEdit', "0"], { skipLocationChange: true });

        }
        else {
            this.showmsg('01.12.01.11');
            //this.common.showPermissiontoastrmsg("Messages.Permissiondenied", 5000, true, false,"01.12.01.11")
        }

    }
    isSameUser = false;

    getLockDetails(docType, docNo, userId, projno) {
        localStorage.setItem('alerturl', this.router.url)
        this.subscription.add(this.penLock.getLock(docType, docNo, userId, projno)
            .subscribe(data => {
                if (data != null && data != undefined) {
                    this.penDockLock = data;
                    if (this.penDockLock.ResponseData != null) {
                        if (this.penDockLock.ResponseData.Uname != "") {
                            if (sessionStorage.getItem('uname')!=null && this.penDockLock.ResponseData.Uname.toLowerCase() == sessionStorage.getItem('uname').toLowerCase()) {                                this.spinner.hide();
                                this.isSameUser = true;
                                this.folderReadOnly = "1";
                                this.apiShared.Setapishareddata(this.folderReadOnly, 'folderReadOnly');
                                this.lockStatus = "1";
                                if(this.penDockLock.ResponseData.Source==null)
                                {
                                    this.isSameUser = false;
                                    this.subscription.add(this.translateapi.get(['Labels.lock_msg']).subscribe((translations) => {
                                        this.confirmMsg = translations['Labels.lock_msg'].replace("<<1>>", this.penDockLock.ResponseData.Uname)
                                        // this.apiShared.uid=userId;
                                    }));
                                }
                                else  if(this.penDockLock.ResponseData["Status"]=="0")
                                {
                                    
                                    this.subscription.add(this.translateapi.get(['Messages.sameUsrlock_msg']).subscribe((translations) => {
                                        this.confirmMsg = translations['Messages.sameUsrlock_msg'].replace("<<1>>", this.penDockLock.ResponseData.Uname)
                                        // this.apiShared.uid=userId;
                                    }));
                                }
                                else  if(this.penDockLock.ResponseData["Status"]=="1")
                                {
                                    
                                    this.subscription.add(this.translateapi.get(['Messages.sameUsrlock_msg']).subscribe((translations) => {
                                        this.confirmMsg = translations['Messages.sameUsrlock_msg'].replace("<<1>>", this.penDockLock.ResponseData.Uname)
                                        // this.apiShared.uid=userId;
                                    }));
                                }
                                this.ConfirmWindow = true;
                            } else {
                                debugger
                                this.spinner.hide();
                                this.isSameUser = false;
                                this.folderReadOnly = "1";
                                // this.apiShared.folderReadOnly = this.folderReadOnly;
                                this.apiShared.Setapishareddata(this.folderReadOnly, 'folderReadOnly');
                                this.lockStatus = "1";
                                this.subscription.add(this.translateapi.get(['Labels.lock_msg']).subscribe((translations) => {
                                    this.confirmMsg = translations['Labels.lock_msg'].replace("<<1>>", this.penDockLock.ResponseData.Uname)
                                    // this.apiShared.uid=userId;
                                }));

                                this.ConfirmWindow = true;
                            }

                        }
                        else {
                            debugger
                            this.spinner.hide();
                            this.folderReadOnly = "0";
                            // this.apiShared.folderReadOnly = this.folderReadOnly;
                            this.apiShared.Setapishareddata(this.folderReadOnly, 'folderReadOnly');
                            this.lockStatus = "0";
                            this.orderNo = this.apiShared.orderNo;
                            if (this.orderNo != "0") {
                                // localStorage.setItem('alerturl', 'PenAir/Search/List')

                                this.router.navigate(['/PenAir/Folder/FolderSummary', this.apiShared.orderNo], { skipLocationChange: true });
                            }
                            else
                                this.router.navigate(['PenAir/Folder/FolderSummaryEdit', "0"], { skipLocationChange: true });
                        }
                    }
                    else {
                        debugger
                        this.spinner.hide();
                        this.folderReadOnly = "0";
                        this.lockStatus = "0";
                        //this.apiShared.folderReadOnly = this.folderReadOnly;
                        this.apiShared.Setapishareddata(this.folderReadOnly, 'folderReadOnly');
                        this.orderNo = this.apiShared.orderNo;
                        this.selectedPassenger.emit(this.passenger);
                        if (this.orderNo != "0") {
                            localStorage.setItem('alerturl', 'PenAir/Search/List')
                            this.router.navigate(['/PenAir/Folder/FolderSummary', this.apiShared.orderNo], { skipLocationChange: true });
                        }

                        else
                            this.router.navigate(['PenAir/Folder/FolderSummaryEdit', "0"], { skipLocationChange: true });
                    }
                }
                else {
                    this.spinner.hide();
                }

            }));

    }

    async checkLockStatus(docType, docNo, userId, projno) {
        this.confirmMsg = '';
        localStorage.setItem('alerturl', this.router.url)
        var result = await this.penLock.getLockStatus(docType, docNo, userId, projno)
            .then(data => {
                if (data != null && data != undefined) {
                    //this.penDockLock = data;
                    if (data["ResponseData"] != null) {
                        if (data["ResponseData"].Uname != "") {
                            this.spinner.hide();
                            this.folderReadOnly = "1";
                            // this.apiShared.folderReadOnly = this.folderReadOnly;
                            this.apiShared.Setapishareddata(this.folderReadOnly, 'folderReadOnly');
                            this.lockStatus = "1";
                            this.subscription.add(this.translateapi.get(['Labels.lock_statusmsg']).subscribe((translations) => {
                                this.confirmMsg = translations['Labels.lock_statusmsg'].replace("<<1>>", data["ResponseData"].Uname)
                            }));

                            //this.ConfirmWindow = true;
                        }
                        else {
                            this.spinner.hide();
                            this.folderReadOnly = "0";
                            // this.apiShared.folderReadOnly = this.folderReadOnly;
                            this.apiShared.Setapishareddata(this.folderReadOnly, 'folderReadOnly');
                            this.lockStatus = "0";
                        }
                    } else {
                        this.spinner.hide();
                        this.folderReadOnly = "0";
                        this.lockStatus = "0";
                        //this.apiShared.folderReadOnly = this.folderReadOnly;
                        this.apiShared.Setapishareddata(this.folderReadOnly, 'folderReadOnly');
                    }
                }
                else {
                    this.spinner.hide();
                }

            });
        return this.confirmMsg;
    }



    onConfirm(action: string) {
        this.ConfirmWindow = false;

        if (action == "yes") {
            this.selectedPassenger.emit(this.passenger);
            if (this.isSameUser) {
                this.apiShared.Setapishareddata('0', 'folderReadOnly');
            }
            this.orderNo = this.apiShared.orderNo;
            if (this.orderNo != "0") {
                this.router.navigate(['/PenAir/Folder/FolderSummary', this.apiShared.orderNo], { skipLocationChange: true });
            }

            else
                this.router.navigate(['PenAir/Folder/FolderSummaryEdit', "0"], { skipLocationChange: true });
        }
    }

    public permissionmessageformopened: any;//Caseid-339
    public PermissionMessageId: any;//Caseid-339

    public PermissionFormClosing(status: any)//339
    {
        if (status == "EmailSendSuccesfully")//339
        {
            this.permissionmessageformopened = false;
        }
    }

    showmsg(permissionid: string) {
        this.permissionmessageformopened = true;//Caseid-339
        this.PermissionMessageId = permissionid;//Caseid-339
    }
    bookingRptClose(e) {
        this.loadBookingRpt = false;
    }
    refundClose(e) {
        this.deletePenLock("Folder", this.orderNo);
        this.openRefund = false;
    }
    ngOnDestroy() {
        // this.apiShared.Setapishareddata('', 'folderExchangeRate');
        // this.apiShared.Setapishareddata('', 'folderCurrency');
        this.subscription.unsubscribe();
    }

    async onRefundReset() {
        this.spinner.show();
        if (Boolean(await this.common.checkPermission1('01.12.01.06.05')) == true) {
            this.refundReset = new FolderRefundreset();
            this.refundReset.pFolderNo = this.orderNo;
            this.refundReset.language = this.apiShared.LanguageSelected;
            this.refundReset.packaged = 0;
            this.refundReset.userId = this.apiShared.uid;
            this.subscription.add(this.invoice.RefundReset(this.refundReset).subscribe(res => {
                if (res != null && res != undefined) {
                    if (res.ResponseData.message.messageid === 422) {
                        this.toastr.success(res.ResponseData.message["messageid"] + ':- ' + res.ResponseData.message["message"], "", {
                            timeOut: 5000,
                            disableTimeOut: false,
                            closeButton: true
                        });
                        this.deletePenLock("Folder", this.orderNo);

                        // this.refundreset=true;
                        // var invoiceno= sessionStorage.getItem('invoiceno');

                        this.spinner.hide();
                        this.orderNo = "";

                        this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
                        this.router.navigateByUrl(localStorage.getItem('alerturl'), { skipLocationChange: true });
                        localStorage.removeItem('alerturl');
                        this.common.folderEditMode = 'false';
                        this.common.folderEditMode = this.common.folderEditMode;
                        this.apiShared.Setapishareddata(this.common.folderEditMode, 'folderEditMode');


                    } else {
                        this.toastr.error(res.ResponseData.message["messageid"] + ':- ' + res.ResponseData.message["message"], "", {
                            timeOut: 5000,
                            disableTimeOut: false,
                            closeButton: true
                        });
                        this.spinner.hide();
                    }
                }
            }));
        } else {
            this.showmsg('01.12.01.06.05');//339
            this.spinner.hide();
            //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.06.05');
        }
    }

    async onCancelledFolderReset(FolderNo: any, ProjNo) {
        debugger;
        if (Boolean(await this.common.checkPermission1('ResetFolder')) == true) {
            debugger;

            this.spinner.show();
            this.subscription.add(this.invoice.CancelledFolderReset(FolderNo, ProjNo).subscribe(res => {
                debugger;
                this.spinner.hide();
                if (res != null && res != undefined) {
                    if (res["ResponseData"].Status == "Success") {
                        this.showMsg = res["ResponseData"].StatusDetails;
                        this.toastr.success(this.showMsg, "", {
                            timeOut: 5000,
                            disableTimeOut: false,
                            closeButton: true
                        })
                        this.gridData.data.splice(this.rowIndex, 1);
                    } else if (res["ResponseData"].Status == "Failed") {
                        this.showMsg = res["ResponseData"].StatusDetails;
                        this.common.showWARNINGtoastrmsg(
                            this.showMsg,
                            2000,
                            true,
                            false
                        );
                    } else {
                        this.showMsg = res["ResponseData"].StatusDetails;
                        this.common.showWARNINGtoastrmsg(
                            this.showMsg,
                            2000,
                            true,
                            false
                        );
                    }
                }
            }));
        } else {
            this.showmsg('ResetFolder');//339
            this.spinner.hide();
            //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.06.05');
        }

    }


}

