import { Component, OnInit } from "@angular/core";
import { CarDetail } from "../../Models/car-detail";
import { ActivatedRoute, Router } from "@angular/router";
import { CarService } from "../../Services/car.service";
import { ShareDataService } from "../../Services/share-data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { POCreationDetail } from "../../Models/pocreation-detail";
import { Pocreationresult } from "../../Models/pocreationresult";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { PocreationService } from "../../Services/pocreation.service";
import { FolderpriceService } from '../../Services/folderprice.service';
import { DeletePO } from "../../Models/delete-po";
import { Deletepo } from "../../Models/deletepo";
import { CommonserviceService } from "../../Services/commonservice.service";
import { Subscription } from "rxjs";
@Component({
  selector: "app-cardetails",
  templateUrl: "./cardetails.component.html",
  styleUrls: ["./cardetails.component.scss"]
})
export class CardetailsComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  lockStatus: any;
  public folderStatus: any;
  public folderstatus: any;
  projNo: any;
  passName: any;
  folderEditMode: any;
  fromtime: any;
  totime: any;
  loaded: true;
  public deletePO: DeletePO;
  public result: any;
  public language = this.apiShared.LanguageSelected;
  public DeleteInvoiceResponse: Deletepo;
  public confirmMsg: any;
  public showMsg: any;
  public PONO: number;
  public pocreated = true;
  showOrder = true; //By Case id-367
  public POCreation: POCreationDetail;
  Pocreationresultobj: Pocreationresult;
  public invoice = false; //By Caseid-12621
  public createPoInvoice = false; //By Caseid-12621
  public formeditable = true;
  public bookingDate: any; //By Caseid-12619
  public FolderHideStatus = false; //12780
  carDetail: CarDetail = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {
      FOLDERNO: 0,
      COMPANY: "",
      SUPPRTYCODE: 0,
      TYPE: "",
      PICKUP: "",
      DATE1: new Date("01/01/1900")
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric"
        })
        .replace(/ /g, "/"),
      DATE2: new Date("01/01/1900")
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric"
        })
        .replace(/ /g, "/"),
      DAYS: 0,
      RATE: 0,
      KM: 0,
      STATUS: "",
      PAYABLE: 0,
      VOUCHER: "",
      DROPOFF: "",
      PAYMODE: "",
      CURCODE: "",
      AMTLC: 0,
      AMTHC: 0,
      CommOn: "",
      SLNO: 0,
      PROJNO: 0,
      ITYPE: "",
      Provider: "",
      GDS: "",
      PNR: "",
      CURExRate: 0,
      FCBuy: 0,
      FCCommPer: 0,
      FCCommAmt: 0,
      FCSell: 0,
      HCCommPer: 0,
      HCCommAmt: 0,
      HCSell: 0,
      ItnryNotes: "",
      TIMEIN: 0,
      TIMEOUT: 0,
      LowFB: "",
      LowFare: 0,
      HighFB: "",
      HighFare: 0,
      Fareoffered: 0,
      PotentialSaving: 0,
      ActualSaving: 0,
      PDNO: 0,
      PDTYPENO: 0,
      Forgone: 0,
      AddRemarks: "",
      PDAMOUNT: 0,
      BookingDate: "",
      PONO: 0,
      PONOYEARID: "",
      PAYMENTID: "",
      PMTID: "",
      DEFDEPPER: 0,
      DEFDEP: 0,
      DepositDueDate: "",
      BalanceDueDate: "",
      BalanceDueAmount: 0,
      GUIDItinerary: "",
      LOYALTYPOINTS: 0,
      LOYALTYPOINTSMEMBNO: "",
      TOMSVATTAXNO: 0,
      TOMSVATAMT: 0,
      LOYALTYPOINTSAMT: 0,
      LOYALTYPOINTSBAL: 0,
      TOMSVATCID: "",
      PDUNIQUEREF: "",
      PDFCURID: "",
      PDFCAMT: 0,
      PDEXRATE: 0,
      SELLTAXNO: 0,
      SELLTAXRATE: 0,
      FC_SELLTAXAMT: 0,
      HC_SELLTAXAMT: 0,
      PONOCCDeferredPosted: 0,
      BOOKEDBY: "",
      CONFIRMATIONNO: "",
      TaxExcludingSalesTax: 0,
      BaseFare: 0,
      SupName: "",

      PDQDESC: "",
      CRDDESC: "",
      PMTDESC: "",
      PAYM: "",
      GSTHSTOnComm: 0,
      //  12698
      CostingSupplierGrossValue: 0,
      CostingSupplierServiceFee: 0,
      CostingTaxNo: 0,
      CostingTaxRate: 0,
      CostingSupplierTaxAmount: 0,
      CostingSupplierNetBuyPrice: 0,
      CostingCustomerSalesValue: 0,
      CostingCustomerServiceFee: 0,
      CostingCustomerTaxAmount: 0,
      CostingCustomerNetSellPrice: 0,
      CostingVATType: 0,
      CostingSuppComm: 0,

      SupplierDeposit: 0
      // 12698
    }
  };
  constructor(
    public commonservice: CommonserviceService,
    private route: ActivatedRoute,
    private router: Router,
    private api: CarService,
    private apiShared: ShareDataService,
    private spinner: NgxSpinnerService,
    private apiPO: PocreationService,
    private translateapi: TranslateService,
    private toastr: ToastrService,
    public objFolderPrice: FolderpriceService
  ) { }

  //@Input() guid: string;
  //@Input() orderno: number;
  private guid: string = this.route.snapshot.params["guidItinerary"]; //'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/';
  private orderno = this.route.snapshot.params["orderNo"];
  public FolderQuoteCreatePO: any;
  public FolderQuoteDeletePO: any;
  ngOnInit() {
    debugger;
    var data=this.objFolderPrice.package;
    var orderno = sessionStorage.getItem("orderNo");
    if (orderno != null)
      if (this.orderno !== orderno) {
        return this.router.navigate(["PenAir/Home"]);
      }
    window.scrollTo(0, 0);
    var d = this.apiShared.LanguageSelected;
    this.folderStatus = this.apiShared.FolderStatus;
    this.lockStatus = this.apiShared.folderReadOnly;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;
      this.invoice = false; //By Caseid-12621
    } else {
      this.formeditable = false;
      this.invoice = true; //By Caseid-12621
      this.createPoInvoice = true; //By Caseid-12621
    }
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;
      this.FolderHideStatus = true; //12780
    }

    // this.apiShared.folderEditMode=this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, "folderEditMode");
    this.getCarDetails(this.orderno, this.guid);
    //'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/
    var folderQuotes = sessionStorage.getItem('folderQuotes'); //12663
    if (folderQuotes) {
      this.FolderQuoteCreatePO = true;
      this.FolderQuoteDeletePO = true;
    }
    else {
      this.FolderQuoteCreatePO = false;
      this.FolderQuoteDeletePO = false;
    }
  }
  getCarDetails(id, guid) {
    this.spinner.show();

    this.subscription.add(
      this.api.getCar(id, guid, true).subscribe(data => {
        if (data != null && data != undefined) {
          this.carDetail = data;
          this.PONO = this.carDetail.ResponseData.PONO;

          if (this.PONO > 0) {
            this.pocreated = false;
            this.invoice = true; //By Caseid-12621
            if (this.folderStatus == "Invoiced") {
              //By Caseid-12621
              this.pocreated = true;
            }
          } else {
            this.pocreated = true;
          }
          if (data != null && data != undefined) {
            this.fromtime = this.pad(data.ResponseData.TIMEIN, 4);
            this.totime = this.pad(data.ResponseData.TIMEOUT, 4);
          }
          this.spinner.hide();
          console.log(this.carDetail);
          //this.isLoadingResults = false;
          this.loaded = true;
        } else {
          this.spinner.hide();
        }
      })
    );
  }
  onEdit() {
    this.router.navigate([
      "PenAir/Product/CarDetailsEdit",
      this.orderno,
      this.guid + "/"
    ]);
  }
  onCancel() {
    /// this.router.navigate(['/PenAir/Folder/Products',this.orderno],{skipLocationChange:true});
    this.commonservice.isfinalsaved = false;
    sessionStorage.setItem("pagevalue", "0");
    var folderQuotes = sessionStorage.getItem("folderQuotes");
    if (folderQuotes) {
      this.router.navigate(["/PenAir/CRM/Enquiry/View/", this.orderno]);
    } else {
      this.router.navigate(["PenAir/Folder/FolderSummary", this.orderno]);
    }
  }
  pad(num, size) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  async onCreatePO() {
    this.spinner.show();
    this.bookingDate =
      this.carDetail.ResponseData.BookingDate == null
        ? "01-Jan-1900"
        : this.carDetail.ResponseData.BookingDate; //By Caseid-12619
    if (
      Boolean(await this.commonservice.checkPermission1("01.12.01.26")) == true
    ) {
      if (this.bookingDate == "1900-01-01T00:00:00") {
        //By Case id-12619
        this.spinner.hide();
        this.subscription.add(
          this.translateapi
            .get(["Messages.InvalidBookingDate"])
            .subscribe(translations => {
              this.showMsg = translations["Messages.InvalidBookingDate"];
              this.toastr.error(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              });
            })
        );
        return;
      } //By Case id-12619
      else {
        this.POCreation = new POCreationDetail();
        this.POCreation.folderNo = this.orderno;
        this.passName = this.apiShared.PassengerNames;
        this.projNo = this.apiShared.projNo;
        this.POCreation.projNo = this.projNo;
        this.POCreation.passName = this.passName;
        this.POCreation.Status=this.folderStatus;//11614
        this.POCreation.postDate = new Date(
          this.carDetail.ResponseData.BookingDate
        );
        this.POCreation.POGUIDItinerary = this.carDetail.ResponseData.GUIDItinerary;

        this.subscription.add(
          this.apiPO.CreatePO(this.POCreation).subscribe(res => {
            if (res != null && res != undefined) {
              this.spinner.hide();
              this.Pocreationresultobj = res;
              if (this.Pocreationresultobj.ResponseData.success_bit == "True") {
                if (this.Pocreationresultobj.ResponseData.invoiceno > 0) {
                  this.subscription.add(
                    this.translateapi
                      .get(["Messages.POCreated"])
                      .subscribe(translations => {
                        this.showMsg =
                          translations["Messages.POCreated"] +
                          ":" +
                          this.Pocreationresultobj.ResponseData.invoiceno;
                        this.toastr.success(this.showMsg, "", {
                          //By case id-12630
                          timeOut: 5000, //By case id-12630
                          disableTimeOut: false, //By case id-12630
                          closeButton: true
                        });
                        this.getCarDetails(
                          this.orderno,
                          this.carDetail.ResponseData.GUIDItinerary
                        );
                      })
                  );
                  this.pocreated = false;
                }
                if (
                  this.Pocreationresultobj.ResponseData.message ==
                  "PO Already Created"
                ) {
                  this.spinner.hide();
                  this.showMsg = "";
                  this.subscription.add(
                    this.translateapi
                      .get(["Messages.POAlreadyCreated"])
                      .subscribe(translations => {
                        this.showMsg =
                          translations["Messages.POAlreadyCreated"];
                        this.toastr.warning(this.showMsg, "", {
                          timeOut: 0,
                          disableTimeOut: true,
                          closeButton: true
                        });
                      })
                  );
                }
              } else {
                this.spinner.hide();
                this.showMsg = "";
                this.subscription.add(
                  this.translateapi
                    .get(["Messages.Failed"])
                    .subscribe(translations => {
                      this.showMsg = this.Pocreationresultobj.ResponseData.message;
                      this.toastr.error(this.showMsg, "", {
                        timeOut: 0,
                        disableTimeOut: true,
                        closeButton: true
                      });
                    })
                );
              }
            } else {
              this.spinner.hide();
            }
          })
        );
      }
    } else {
      this.spinner.hide();
      this.ShowPermissionMessageForm("01.12.01.26"); //339
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.26');
    }
  }
  async onDeletePO() {
    this.spinner.show();
    if (
      Boolean(await this.commonservice.checkPermission1("01.12.01.27")) == true
    ) {
      //this.getAirticketDetails(this.orderNo,this.route.snapshot.params['guidItinerary']);
      this.subscription.add(
        this.api
          .getCar(this.orderno, this.carDetail.ResponseData.GUIDItinerary, true)
          .subscribe(data => {
            this.carDetail = data;
          })
      );
      //alert(JSON.stringify(this.carDetail));
      if (this.carDetail != null && this.carDetail != undefined) {
        this.deletePO = new DeletePO();
        this.deletePO.Language = this.language;
        this.deletePO.FolderNo = this.orderno;
        this.deletePO.PONO = this.carDetail.ResponseData.PONO;
        this.deletePO.POYearId = this.carDetail.ResponseData.PONOYEARID;
        this.deletePO.Guid = this.carDetail.ResponseData.GUIDItinerary;
      }
      this.subscription.add(
        this.apiPO.DeletePO(this.deletePO).subscribe(res => {
          if (res != null && res != undefined) {
            this.spinner.hide();
            this.DeleteInvoiceResponse = res;
            this.result = this.DeleteInvoiceResponse.ResponseData["message"];

            if (this.result.includes("PO Deleted:")) {
              this.pocreated = true;
              this.invoice = false; //By Caseid-12621
              this.toastr.success(this.result, "", {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              });
            } else {
              this.spinner.hide();
              this.toastr.error(this.result, "", {
                timeOut: 5000,
                disableTimeOut: false,
                closeButton: true
              });
            }
          } else {
            this.spinner.hide();
          }
        })
      );
    } else {
      this.spinner.hide();
      this.ShowPermissionMessageForm("01.12.01.27"); //339
      //this.commonservice.showPermissiontoastrmsg("Messages.Permissiondenied",5000,true,false,'01.12.01.27');
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public permissionmessageformopened: any; //Caseid-339
  public PermissionMessageId: any; //Caseid-339

  public PermissionFormClosing(
    status: any //339
  ) {
    if (status == "EmailSendSuccesfully") {
      //339
      this.permissionmessageformopened = false;
    }
  }

  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true; //Caseid-339
    this.PermissionMessageId = permissionid; //Caseid-339
  }
  onCopy()
  {
    debugger;
this.spinner.show();
   this.objFolderPrice.CopyProductDetails(this.orderno,this.carDetail.ResponseData.GUIDItinerary,"Car").subscribe(x=>{
    debugger;
    this.spinner.hide();
    if(x.ResponseData.value==1)
{
  this.router.navigate([
    "/PenAir/Product/CarDetailsEdit",
    this.orderno,
    x.ResponseData.GUIDItinerary
  ]);
}

   });
  }
}
