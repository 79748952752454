import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { CommonserviceService } from '../../Services/commonservice.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { Subscription } from 'rxjs';
import { ShareDataService } from "../../Services/share-data.service";

import { LookUpDetailsService } from '../../Services/look-up-details.service';
@Component({
  selector: 'app-corporate-info-details-edit',
  templateUrl: './corporate-info-details-edit.component.html',
  styleUrls: ['./corporate-info-details-edit.component.scss']
})
export class CorporateInfoDetailsEditComponent implements OnInit {

  @Input() parentForm: FormGroup
  @Input() public pocreated;
  ReasonCodeList: Array<LookUpDetails["ResponseData"]> = []; //By Case id-355
  public ReasonCodeSelectedItem: LookUpDetails["ResponseData"]; //By Case id-355
  private subscription: Subscription = new Subscription();  //By Case id-355
  lockStatus: any;
  folderStatus: any;
  formeditable: any;
  constructor(public apiShared: ShareDataService, public common: CommonserviceService, private lookupApi: LookUpDetailsService) { }
  ngOnInit() {

    //case id : 13777 
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    } else {
      this.formeditable = false;

    }
    // case id : 13777


    this.subscription.add(this.subscription.add(this.lookupApi.GetAllLookup('ReasonCode').subscribe(  //By Case id-355
      (data) => {
        if (data != null && data != undefined) {
          this.ReasonCodeList = data["ResponseData"];
          if (this.parentForm.controls.Reasoncode.value != "")//By Case id-355
          {
            this.ReasonCodeSelectedItem = this.ReasonCodeList.find(item => item.Code == this.parentForm.controls.Reasoncode.value);
          }
        }

      }
    )));
  }
  public expandMode: number = PanelBarExpandMode.Default;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  public height = 320;

  public onChange(event: any): void {
    this.expandMode = parseInt(event.target.value, 10);
  }
  get fp() { return this.parentForm.controls; }
  public onPanelChange(event: any): void { console.log('stateChange: ', event); }
  private hcBuy: number;
  private potentialsave: number;
  private actualsave: number;
  private fareOffered: number;
  CalculateFareOfferd() {
    if (this.parentForm.get('PAYABLE') != null) {
      this.hcBuy = this.parentForm.get('PAYABLE').value;
    }
    else if (this.parentForm.get('AMOUNT') != null) {
      this.hcBuy = this.parentForm.get('AMOUNT').value;
    }
    this.fareOffered = this.parentForm.get('Fareoffered').value;
    this.actualsave = this.fareOffered - this.hcBuy;
    this.potentialsave = this.fareOffered - (this.parentForm.get('HighFare').value);
    this.parentForm.controls.ActualSaving.setValue(parseFloat((this.actualsave).toFixed(2)));
    this.parentForm.controls.PotentialSaving.setValue(parseFloat((this.potentialsave).toFixed(2)));
  }

  public ReasonCodeChange(value: any): void   //By Case id-355
  {
    debugger;
    if (value != null && value != undefined) {
      this.parentForm.controls.Reasoncode.setValue(value.Code);
    }
    else {
      this.parentForm.controls.Reasoncode.setValue("");
    }
  }


}
