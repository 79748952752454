export class LookUpDetails {
    IsSuccess: boolean;
    Message: string;
    ResponseData: {
        Code: string;
        UsrCode: string;
        Name: string;
        SELFBILLING: string;
        ActualName: string;
        IATACode: string;
        IATACheckingOnFolder: string;
        LID: any;
        Status: number;
        CurID: any;
        PAYTERM: any;
        WHTax: any;
        WHTaxCode: any;
        Default: any;
        BALDUETO: any;
        CONTID: any;
        LOGO:any;
        value1:any;
    };
}
export class LookUpDetailReport {
    IsSuccess: boolean;
    Message: string;
    ResponseData: {
        Code: string;
        UsrCode: string;
        Name: string;
        SELFBILLING: string;
        ActualName: string;
        Month: string[];
    };
}
