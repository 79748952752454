import { Component, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { LookUpDetails } from '../../Services/look-up-details';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { FolderSearchCriteriaDTO, RailSearchDTO } from '../../Models/folder-search-criteria-dto';
import { SearhdtotransferService } from '../../Services/searhdtotransfer.service';
import { Subscription } from 'rxjs';
import { CommonserviceService } from '../../Services/commonservice.service';
@Component({
  selector: 'app-searchby-rail',
  templateUrl: './searchby-rail.component.html',
  styleUrls: ['./searchby-rail.component.scss']
})
export class SearchbyRailComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public railSearchForm: FormGroup;
  public submitted: boolean = false;
  opened = false;
  openSearch = false;
  control: number;
  lookuptype: string = '';
  lookupname: any = 'Supplier';
  SupplierList: Array<LookUpDetails['ResponseData']> = [];

  public supplierSelectedItem: LookUpDetails['ResponseData'];
  public supplierSelected: String;
  constructor(public common: CommonserviceService, private searhTransferDTO: SearhdtotransferService, private formBuilder: FormBuilder, private lookupApi: LookUpDetailsService) { }
  flsrchDto: FolderSearchCriteriaDTO;
  RailSearchDTO: RailSearchDTO;

  destinationList: Array<LookUpDetails["ResponseData"]> = [];
  destinationfromList: Array<LookUpDetails["ResponseData"]> = [];
  public destinationSelectedItem: LookUpDetails["ResponseData"];
  public destinationToSelectedItem: LookUpDetails["ResponseData"];
  public destinationSelected: String;
  public destinationToSelected: String;
  ngOnChanges(changes: SimpleChanges) {

    this.RailSearchDTO = this.flsrchDto.RailSearchDTO;
  }
  menuIndex = 3;
  ngOnInit() {

    // this.railSearchForm = this.formBuilder.group({
    //   Supplier :[""],
    //   From:[""],
    //   To:[""],
    //   Status :[""],
    //   Voucher:[""],
    // });
    this.subscription.add(this.searhTransferDTO.SrchTranferDTO.subscribe(flsrchDto => {
      this.flsrchDto = flsrchDto;

    }))
    if (this.flsrchDto.RailSearchDTO != null)
      this.RailSearchDTO = this.flsrchDto.RailSearchDTO;
    else
      this.RailSearchDTO = new RailSearchDTO();
    // this.loadSupplier();
    this.Destination();
    if(this.RailSearchDTO.Supplier != "" && this.RailSearchDTO.Supplier !=null){
      this.loadSupplierValue(this.RailSearchDTO.Supplier);
    }

  }
  public Destination() {
    debugger;
    if (this.RailSearchDTO.From != undefined && this.RailSearchDTO.From != "") {
      this.subscription.add(this.lookupApi.SearchDestination(this.RailSearchDTO.From, '1').subscribe(
        (data) => {

          if (data != null && data != undefined) {
            this.destinationfromList = data["ResponseData"];
            this.destinationSelected = this.destinationfromList[0].Name;
          }
        }
      ));
    }

    if (this.RailSearchDTO.To != undefined) {
      this.subscription.add(this.lookupApi.SearchDestination(this.RailSearchDTO.To, '1').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.destinationList = data["ResponseData"];
            this.destinationToSelected = this.destinationList[0].Name;
          }
        }
      ));
    }
  }

  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860
  public close(status) {

    this.opened = false;
    this.openSearch = false;
  }




  get f() { return this.railSearchForm.controls; }
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  @ViewChild('fromcity') public fromcity: AutoCompleteComponent;
  @ViewChild('tocity') public tocity: AutoCompleteComponent;
  handleFiltersupplier(value) {

    if (value.length >= 3) {
      this.subscription.add(this.lookupApi.GetLookupById("SUPPLIERS", value, value).subscribe(
        // this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  public SupplierValueChange(value: any): void { debugger;
    //  alert(value);
    if (this.SupplierList != null && this.SupplierList.length > 0) { //13860
      this.supplierSelectedItem = this.SupplierList.find(item => item.Name == value);

    }
    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860

      this.RailSearchDTO.Supplier = (this.supplierSelectedItem.Code);


    } else {
      this.supplierSelected = '';
      this.RailSearchDTO.Supplier = ('');
      this.supplierSelectedItem = null;

    }
    this.callChange();

  }

  loadSupplierValue(value:any) {  //14068   v1.0 
    debugger; 

      this.subscription.add(this.lookupApi.findSupplier(value).subscribe(
        (data) => {
          if (data["ResponseData"] != null) {
            this.SupplierList = data["ResponseData"];
            if (value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
              }

            }
          }

        }
      ));
    
  }



  ///-------------------------12687-------------------------------------------------------------------
  public DestinationValueChange(value: any): void {
    debugger;

    this.destinationSelectedItem = this.destinationfromList.find(item => item.Name == value);

    if (this.destinationSelectedItem != null) {
      this.RailSearchDTO.From = this.destinationSelectedItem.Code;
      // this.RailSearchDTO.FromSelected = this.destinationSelectedItem.name;

    }
    else {
      this.destinationSelected = "";
      this.RailSearchDTO.From = ('');
      this.destinationSelectedItem = null;
    }
    this.callChange();
  }


  public DestinationToValueChange(value: any): void {
    this.destinationToSelectedItem = this.destinationList.find(item => item.Name == value);
    if (this.destinationToSelectedItem != null) {
      this.RailSearchDTO.To = this.destinationToSelectedItem.Code;
    }
    else {
      this.destinationToSelected = "";
      this.RailSearchDTO.To = ('');
      this.destinationToSelectedItem = null;
    }
    this.callChange();
  }

  handlefromcity(value) {
    debugger;
    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          debugger;
          if (data != null && data != undefined) {
            this.destinationfromList = data["ResponseData"];
          }
        }
      ));
    } else {
      this.fromcity.toggle(false);
    }
  }
  handletocity(value) {
    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchDestination(value, '1').subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.destinationList = data["ResponseData"];
          }
        }
      ));
    } else {
      this.tocity.toggle(false);
    }
  }
  //-------------------------12687-------------------------------------------------------------------

  callChange() {

    if (this.RailSearchDTO.Supplier != '' || this.RailSearchDTO.From != '' || this.RailSearchDTO.To != ''
      || this.RailSearchDTO.VoucherNo != '' || this.RailSearchDTO.TicketDateFrom.toString() != '' || this.RailSearchDTO.TicketDateTo.toString() != '') {
      if (this.RailSearchDTO.TicketDateFrom != null) {
        if (this.RailSearchDTO.TicketDateFrom.toString() != '')
          this.RailSearchDTO.DateFrom1 = this.common.transformDate(new Date(this.RailSearchDTO.TicketDateFrom));
      } else {
        this.RailSearchDTO.DateFrom1 = null;

      }

      if (this.RailSearchDTO.TicketDateTo != null) {
        if (this.RailSearchDTO.TicketDateTo.toString() != '')
          this.RailSearchDTO.DateFrom2 = this.common.transformDate(new Date(this.RailSearchDTO.TicketDateTo));

      } else {
        this.RailSearchDTO.DateFrom2 = null;

      }
      if (this.RailSearchDTO.TicketDateFrom == null && this.RailSearchDTO.TicketDateTo != null) {
        this.RailSearchDTO.DateFrom1 = this.RailSearchDTO.DateFrom2;
      }
      this.flsrchDto.RailSearchDTO = this.RailSearchDTO;
    }


    else
      this.flsrchDto.RailSearchDTO = null;
  }

  Disable(value) {

    this.control = value;
    // this.enableform();

  }
  enable() {
    //  this.railSearchForm.enable();
  }




  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
