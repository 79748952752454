import { Component, OnInit,Input ,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-comfirmation-window',
  templateUrl: './comfirmation-window.component.html',
  styleUrls: ['./comfirmation-window.component.scss']
})
export class ComfirmationWindowComponent implements OnInit {
  @Output() confirmStatus = new EventEmitter<string>();
  @Input() confirmMsg;
  msg:string;
  public ConfirmMessage(status) {
   
    this.confirmStatus.emit(status);
  }

  constructor() { 
   
  }

  ngOnInit() {
  
//alert(JSON.stringify(this.confirmMsg))
   
  }

}
