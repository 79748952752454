import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Config, Menu, Menu1 } from "../SharedMenu/accordian-main-menu/types";
import { TranslateService, TranslateStore } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from "../Services/login.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ShareDataService } from "../Services/share-data.service";
import { PendoclockService } from "../Services/pendoclock.service";
import { CommonserviceService } from "../Services/commonservice.service";
import { TelerikReportViewerComponent } from "@progress/telerik-angular-report-viewer";
import { L10nInterceptor } from "../_helpers/l10n.interceptor";
import { ReportformComponent } from "../SharedCommonModule/reportform/reportform.component";
import { Observable, BehaviorSubject, Subscription, forkJoin } from "rxjs";
//google authenticator
import { GoogleAuthService } from '../Services/google-auth.service';
import { MatDialog } from '@angular/material/dialog';
import { TfaDialogComponent } from '../tfa-dialog/tfa-dialog.component';
import { AlertService } from '@full-fledged/alerts';
//import { Config } from '../../config';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { share, take, map } from "rxjs/operators";
import { Users } from "src/Models/users";
import { PenChannelService } from "../Services/pen-channel.service";
import { Pendoclock } from "../Models/pendoclock";
import { ToastrService } from "ngx-toastr";
import { FileService } from "../Services/file.service";
import { IntlService } from "@progress/kendo-angular-intl";
import { EnvService } from "src/env.service";
import { AccountMenu } from "../SharedMenu/accounts-main-menu/types";
import { TrustpaySettingsDTO } from "../Models/trustpay-settings-dto";
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Component({
  selector: "app-public-layout",
  templateUrl: "./public-layout.component.html",
  styleUrls: ["./public-layout.component.scss"],
})
export class PublicLayoutComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  private currentUser: Users;
  public version: string;
  username: any = new BehaviorSubject("");
  // @ViewChild("reportViewer", { static: true }) reportViewer: ReportformComponent;
  private observer: any;
  public loadingMsg: any = "Loading...";
  public PenAirWebVersion: any = "1.0.11";
  public PenAirWebApiVersion: any = "1.0.11";
  public currentYear = new Date().getFullYear();
  public client: any = {
    CHEAD: "",
    CWEB: "",
  };
  public data1: any;
  translations: any;
  public alert = "/Home";
  public airticketrpt = "";
  public prefix: any = "/assets/i18n/";
  public sufix: any = ".json";
  showfull = true;
  lockStatus: any;
  passengername: string = "";
  orderno: string = "";
  CurrencyMaster: string = "Currency Master";
  FolderReport: string = "Folder Reports";
  Commonsetting: string = "Common Settings";
  Adminstration: string = "Administration";
  Financesetting: string = "Finance Settings";
  Productsetting: string = "Product Settings";
  Travelsetting: string = "Travel Settings";
  StatutoryReport: string = "Statutory Reports";
  Airticketreports: string = "Air Ticket Reports";
  AirlineDetailedReport: string = "Airline Detailed Report";
  Folder: string = "Folder";
  Search: string = "Search";
  Alerts: string = "Alerts";
  Inbox: string = "Inbox";
  Closed: string = "Closed";
  Shared: string = "Shared";
  Configsettings: string = "Configuration Settings";
  Reports: string = "Reports";
  BankReconsilation = "Bank Reconsilation";
  AirTktrptPNR: string = "Air Ticket Report With PNR";
  QuebecOPCFICAVFeesReport = "Quebec OPC  FICAV Fees Report";
  JournalTransaction = "Journal Transaction";
  Canada: string = "Canada";
  Journal: string = "Journal";
  FolderO2C: string = "Folder O2C";
  SalesListing: string = "Sales Listing";
  Accounts: string = "Accounts";
  Financialstatements: string = "Financial Statements";
  TaxReports: string = "Tax Reports";
  General: string = "General";
  TaxCalculation: string = "TaxCalculation";//7485
  Bank: string = "Bank";
  CustomerReceiptsAndPaymentRpt: string = "Customer Receipt/Payment Report";
  CustomerStatement: string = "Customer Statement";
  CustomerStatementbylineitem: string = "Customer Statement by line item";
  BankStatement: string = "Bank Statement";
  FolderActivity: string = "Folder Activity Report";
  FolderQuery: string = "Folder Query Report";
  FolderProduct: string = "Foder Activity Product"
  TrialBalanceReport: string = "Trial Balance Report";
  TrialBalance: string = "Trial Balance";
  TrialBalancewithCostCenter: string = "Hyperion (HFM)-Data Export";
  VoucherLiabilityAsOnDateReport: string =
    "Voucher Liability as On Date Report";
  VoucherLiability: string = "Voucher Liability";
  Supplier: string = "Supplier";
  MasterDataList: string = "Master Data List";
  CustomerList: string = "Customer List"
  MarketingBookingData: string = "Marketing Booking Data"
  Customer: string = "Customer";
  ExpenseInvoice: string = "ExpenseInvoice"; //case id :13692
  Nominal: string = "Nominal";
  Chartofaccounts: string = "Chart Of Accounts";
  ProductGroupMaster: string = "Product Group Master";
  ConsolidatedInvoice: string = "Consolidated Invoice";
  ReportSchedulerList: string = "Report Scheduler List";
  EmailTemplatesGrid: string = "Email Templates Grid";
  CashDetails: string = "Cash";
  Hotel: string = "Hotel";
  PettyCash: string = "Petty Cash";
  PettyCashPayment: string = "Petty Cash Payment";
  CashPayment: string = "Cash Payment";
  CashReceipt: string = "Cash Receipt";
  AuditandQuries: string = "Audit And Queries";
  PettyCashReport: string = "Petty Cash Report";
  Payment: string = "Supplier Payment";
  BookAHotel: string = "Book A Hotel";
  HotelList: string = "Hotel List";
  FixedAssets: string = "Fixed Assets";
  ViewSupplier: string = "View Suppliers";
  ViewCustomer: string = "View Customer";
  VoucherMngt: string = "Voucher Management Report";
  GiftcardOrVoucher: string = "Gift Card/Voucher";
  Invoice = "Invoices";//13436

  ManualCreditNotes: string = "Credit Note";//13436
  Receipt: string = "Receipt";
  CustReceipt: string = "Customer Receipt";
  Utilities: string = "Utilities";
  PropertyTypes: string = "Property Types";
  MealPlanMaster: string = "Meal Plan Master";
  RoomTypesMaster: string = "Room Type Master";
  ProjectMaster: string = "Project Master";
  HotelPricingGroup: string = "Hotel Pricing Group";
  UnlockDocument: string = "Unlock Document";
  compiler: any;
  Help: string = "Help";
  Favorites: string = "Favorites";
  Guidlines: string = "Guidelines";
  BeginnerNotes: string = "Beginner Notes";
  Shortcuts: string = "Shortcuts";
  SuppierStatementrpt = "Supplier Statement Report";
  Reconcilation: string = "Bank Reconcilation";
  PandLrpt: string = "P & L Analysis Month Wise ";
  BankReconcilation: string = "Bank Reconcilation";
  UserSettings: string = "User Settings";
  AuditReport: string = "Folder Transaction Details";
  CustomerOutStandingSummary: string = "Customer OutStanding Summary"
  CustomerLedgerwithItinerary: string = "CustomerLedgerwithItinerary"
  Delink: string = "Delink Receipt  From Folder";
  PenChannel: string = "Pen Channel";
  CRM: string = "CRM";
  Enquiry: string = "Enquiry";
  PurgeAlerts: string = "Purge Alerts";
  translated: Array<{ display: string; target: any }> = [];
  search: string;
  Settings: string = "Settings";
  Assets: string = "Assets";
  resetSharedvalues: any = "";
  public headerText: string = "Details";
  public headervalue: string = "Details";
  folderstatus: string = "";
  headerPassenger: string;
  headerlanguage: any;
  public opened = false;
  headerrefresh: string;
  EmailInvoice: string = "Mass Email Invoice";
  AtolReportFiling: string = "Atol Report Filing";//12808
  public selectedValue: string = "---Select---";
  public mainmenu: string = "Details";
  public mainmenutransilate: any;
  langSelected: any;
  iconClass: string = "flag-icon flag-icon-us mr-1"; // 'fa fa-cog fa-fw';
  ViewNominal: string = "View Nominal";
  ViewCOA: string = "View Chart of Accounts";
  ViewProductGroupMaster: string = "Product Group Master";
  ViewConsolidatedInvoice: string = "Consolidated Invoice";
  PaymentGateway: string = "Payment Gateway Settings";
  PaymentGatewayList: string = "Payment Gateway List";
  PaymentGatewayListDetails: string = "Payment Gateway List Details";
  PettyCashMaster: string = "Petty Cash Master";
  PettyCashMasterList: string = "Petty Cash Master List";
  BankPayInSlip = "Bank Pay In Slip";
  CRMSettings = "CRM Settings";
  ThirdPartyCRMSettings = "Third Party CRM Settings";
  ScreenVariant = "Screen Variant";
  DealIdMaster = "Deal ID Master";
  OrderTypeMaster: string = "Order Type Master";
  ManualPDQReconciliation = "Manual PDQ Reconciliation";
  confirmMsg: any;
  PermissionMessage: string = "Permission Message"; //By Case id-339
  PermissionMessageForm: string = "Permission Message Form"; //By Case id-339
  ManualSales = "Manual Sales";
  ManualSalesList = "Manual Sales List";
  ManualSalesReturnList = "Manual Sales Return List";
  ManualSalesReturn = "Manual Sales Return";
  GeneralLedger = "GeneralLedger";
  BankPayment: "Bank Payment";
  BankReceipt: "Bank Receipt";//By Case id-13545
  ExportData = "Export Data";//By Case id-13173
  BSP = "BSP";//By Caseid 13174
  BSPManualReconciliation = "BSP Manual Reconciliation";//By Caseid 13174'
  AutoReceipt = "Auto-Receipt by Line Item";//By Caseid 13502
  supplierReconciliation = "Automatic Supplier Reconciliation";//By Caseid 13507
  FolderAddExpense = "Folder Add Expense From ExcelForm";//By Caseid 13385
  AutomaticCreditCardRecon = "Automatic Credit Card Reconciliation";//By Caseid 13564
  MarketingCode: string = "Marketing Code Master";//By Caseid 13588
  ProfitCentreList: string = "Profit Centre Master";//By Case id 13614
  PostCodeSearchSettings: string = "Post Code Search Settings";
  ActionMaster: string = "Action Master";//By Case id 13637  
  FraudScoreSettings: string = "Fraud Score Settings";//By caseid 13650
  Validations: string = "Validations";
  BSPDirectExpense = "BSP Direct Expense";//By Caseid 13371
  CountryMaster: string = "Country Master";  //By case id:13638
  CityMaster: string = "CityMaster"//By case id 13709
  DropdowmListMaster: string = "Dropdown List Master";//By caseid 13654
  MarketingCodeGroupMaster: string = "Marketing Code Group Master" //By case id 13791
  ProfitCentreGroupMaster: string = "Profit Centre Group Master" //By case id 13802
  CustomerGroupMaster: string = "Customer Group Master" //By case id 13861
  CustomerCreditGroup: string = "Customer Credit Group" //By case id 13921 
  GdsUsermappingList: string = "GDS User Mapping Set up"//By case id 13723
  CostCentreMaster: string = "Cost Centre Master" //By case id 13799
  GeneralLedgerReport: string = "GeneralLedgerReport" //By case id 13779
  FolderInstallmentReport: string = "Folder Installment Report";//By Case id:13429
  AuditQuery: string = "Audit Query";
  EmailSettingsList: string = "Email Settings ";//By caseid:13382
  CreateFolder: string = "Manual Folder" //By case id 13764
  packagefolder: string = "Package Folder" //By case id 13764
  GroupBooking: string = "Group Booking" //By case id 13855
  PassengerBookingDetails: string = "Passenger Booking Details" //By case id 13855
  SupplierGroupMaster: string = "Supplier Group Master" //By case id 13953
  StaffGroupMaster: string = "Staff Group Master" //By case id 13969
  BanktoBankTransfer: string = "Bank to Bank Transfer" //By case id 13935
  DefaultCharges: string = "Default Charges";//By caseid 13902: 
  AirwaysMaster: string = "Airways Master" //By case id 13879 
  TaxRateMaster: string = " Tax Rate Master" //By case id 13943
  MarketingRegion: string = "Marketing Region" //By case id 14009
  PaymentBlockMaster: string = "Payment Block Master" //By case id 13989
  CompanyCardPayment: string = "Company Card Payment" //By case id 7182 
  BankMaster: string = "Bank Master" //By case id 13968
  PaymentMethodMaster: string = "Payment Method Master" //By case id 13970
  CardMaster: string = "Card Master" //By case id 14056 
  GDSAutoFolderSettings: string = "GDS Auto Folder Settings" //By case id 13966
  PaymentTermsMaster: string = "Payment Terms Master" //By case id 14057 
  IATAMaster: string = "IATA Master" //By case id 14103
  ThirdPartyMapping: string = "Third Party Mapping"//By case id 14107
  AgentCommissionList: string = "Agent Commission List on Invoice";//By case id 13613
  thirdPartyExcelMapping: string = "Third Party Excel Mapping" //By case id 14005
  UAE: string = "UAE" //By case id 14139
  VAT201VATReturns: string = "VAT201-VAT Returns";//By case id 14139
  AgeingScheduleMaster: string = "Ageing Schedule Master" //By case id 14106
  Workflow: string = "Workflow"// By case id 14138
  AutomaticCompanyCardRecon = "Automatic Company Card Reconciliation";//By caseid 13706
  TaxMaster: string = " Tax Master" //By case id 14134
  CustomerAllocation: string = "Customer Allocation" //By case id 14143
  SupplierRemittanceAdvice: string = "Supplier Remittance Advice" //By case id 14084
  SuppierAllocationReport: string = "Suppier Allocation Report";//By Case id:14154
  DailyJournalAuditReport: string = "Daily Journal Audit Report" //By case id 14146
  ForeignCurrencyCustomerStatment: string = "Foreign Currency Customer Statment";//By Case id:14155
  DepositAsondateReport: string = "Deposit As on date Report "//by case id 14197
  Manualpurchase: string = "Manual Purchase" //By Case id 13619
  CannedMessage: string = "Canned Message" //By case id  13967
  CustomeOutstandingasondateReport: string = "Customer Outstanding Detailed As on date Report";//By Case id 14177
  SupplierAccounts: string = "Supplier"//By case id:14227
  SupplierOutstandingDetailedAsondateReport: string = "Supplier Outstanding Detailed Asondate Report"//By case id:14227
  ForeignCurrencySupplierStatement: string = "Foreign Currency Supplier Statement";//By Case id:14234
  SupplierOutStandingSummary: string = "Supplier OutStanding Summary"//By case id:14214
  DeferredRevenueAndExpenseReport: string = "Deferred Revenue And Expense Report"//by case id:14254
  RewardPointsReconciliation: string = "Reward Points Reconciliation"//by case id 14231
  EwaySettings: string = "EwaySettings"//By case id:14252
  DepositCollectedforFutureTravel: string = "Deposit Collected for Future Travel"//By case id  14255
  //FolderActivityDetailed: string = "Folder Activity Detailed Report"//14290
  ReplenishmentReport: string = "Replenishment Report"//By case id  14292
  CorporateReports: string = 'CorporateReports'//By case id  14292
  RewardPoint: string = 'Reward Points'//By case id  14292
  // Commission: string = "Commission"//By case id 14288
  MessageAnalysis: string = "Message Analysis Report";//By Case id:14213
  GiftCardMaster: string = "Gift Card Master"//By case id 14046
  GiftCardVoucherIssue: string = "Gift Card/Voucher Issue"; //By case id 14395 
  StoreFrontMaster: string = "Store Front Master";  //14277

  MessageSettings: string = "Message Settings" // By Case Id 14206

  ReasonCodeMaster: string = "Reason CodeMaster" //By case id  14055
  MainDocumentType: string = "MainDocumentType"; //case id :14276
  ManualCompanyCardRec: string = "Manual Company Card Reconcilation"; //case id :13996
  DirectRefundFolder: string = "Direct Refund" //By case id 14340 

  UserList: string = "User List"


  // CommissionbasedonProduct: string = "Commission based on Product"
  // CommissionbasedonReceipt: string = "Commission based on Receipt"

  SftpSettings: string = "SFTP Settings";//14318
  FolderActivityDetailed = "FolderActivityDetailed";
  Commission = "Commission";
  CommissionbasedonProduct = "CommissionbasedonProduct";
  CommissionbasedonReceipt = "CommissionbasedonReceipt";
  AutomaticPO = "Automatic PO";//14372
  GDSMaster: string = "GDS Master";//case id:14333;
  Busareawiseemailandphone: string = "Business Area Wise Email And Phone"; //case id: 14344;
  AutoChargingInstallment: string = "Auto Charging Installment" //By case id 14167
  SupplierAllocation: string = "Supplier Allocation"; //case id: 14423;
  EStatement: string = "E Statement"; //case id: 14428;
  AutomaticReminder: string = "Automatic Reminder"; //case id: 14428;
  BacsReconciliation: string = "BacsReconciliation" //By case id 14240
  AutomaticBalanceReminder: string = "AutomaticBalanceReminder" //By case id 14452
  Websites: string = "Websites"//By Case id 13992
  CustomerPortal: string = "Customer Portal"//By case id 13992
  // PurchaseListing: string = "Purchase Listing"; //By case id 14311
  PurchaseCommitmentReport: string = "Purchase  Commitment Report"; //By case id 14311
  UK: string = "UK"; //By case id 14459
  ATOL: string = "ATOL"//By case id 14459
  //PurchaseCommitmentReport: string = "Purchase Commitment Report"//By case id 14465
  ScheduleChangeCommunication: string = "Schedule Change Communication"//By case id 14476
  TicketingthroughGDS: string = "Ticketing through GDS"//By case id 14489
  SupplierLedgerreport: string = "Supplier Ledger Report" //By case id 14360
  SupplierOutstandingAsOnDateAgedReport: string = "Supplier Outstanding As On Date Aged Report"//By case id:14495
  CustomerLedgerReport: string = "Customer Ledger Report" //By case id 14350
  CustomerOutstandingAsOnDateAgedSummaryReport: string = "Customer Outstanding As On Date Aged Summary Report"//By case id:14494
  CurrencyRevaluation: string = "Currency Revaluation"//By case id:14527
  GLRevaluation: string = "GL Revaluation"//By case id:14527
  DebtorsBalanceRevaluation: string = "Debtors Balance Revaluation"//By case id:14527
  CreditorsBalanceRevaluation: string = "Creditors Balance Revaluation"//By case id:14527
  CustomerAgeingWithCreditLimit: string = "Customer Ageing With Credit Limit"//By case id:14524
  // FolderSalesReceiptReport: string = "Folder Sales Receipt Report"//by caseid:14606
  // DailyAudit: string = "Daily Audit"//by case id:14606
  // CreditCardReconciliationStatement: string = "Credit Card Reconciliation Statement"//By case id:14597
  ATOLReport
  projNo: string = "";
  public showMsg: any;
  penDockLock: Pendoclock = {
    IsSuccess: true,
    Message: "succcess",
    ResponseData: {
      LDocType: "",
      LDocNo: "",
      UID: 0,
      LDATE: "",
      LTIME: "",
      LPROJNO: "",
      LYearID: "",
      LAutoNo: "",
      Uname: "",
      Source: "",
    },
  };
  Invoices: string = "Invoices";
  Corporate: string = "Corporate";
  Languages: Array<any> = [
    {
      language: "English",
      icon: "flag-icon flag-icon-us flagIcon",
      click: (dataItem) => {
        this.translate.use("en");
        this.apiShared.LanguageSelected = dataItem.language;
        this.apiShared.setTranslateLang(dataItem.language);
        this.apiShared.Setapishareddata(dataItem.language, "language");
        this.langSelected = dataItem.language; //console.log(`${dataItem.actionName}ing last action.`);
        this.iconClass = dataItem.icon + ' mr-1';
        this.translateMenutext(this.langSelected);
      },
    },
    {
      language: "Chinese",
      icon: "flag-icon flag-icon-cn flagIcon",
      click: (dataItem) => {
        this.translate.use("zh");
        this.apiShared.LanguageSelected = dataItem.language;
        this.apiShared.setTranslateLang(dataItem.language);
        this.apiShared.Setapishareddata(dataItem.language, "language");
        this.langSelected = dataItem.language; //console.log(`${dataItem.actionName}ing last action.`);
        this.iconClass = dataItem.icon + ' mr-1';
        this.translateMenutext(this.langSelected);
        //this.getmenus(dataItem.language);
      },
    },
    {
      language: "French",
      icon: "flag-icon flag-icon-fr flagIcon",
      click: (dataItem) => {
        this.translate.use("fr");
        this.apiShared.LanguageSelected = dataItem.language;
        this.apiShared.setTranslateLang(dataItem.language);
        this.apiShared.Setapishareddata(dataItem.language, "language");
        this.langSelected = dataItem.language; //console.log(`${dataItem.actionName}ing last action.`);
        this.iconClass = dataItem.icon + ' mr-1';
        this.translateMenutext(this.langSelected);
      },
    },
    {
      language: "Thai",
      icon: "flag-icon flag-icon-th flagIcon",
      click: (dataItem) => {
        this.translate.use("th");
        this.apiShared.LanguageSelected = dataItem.language;
        this.apiShared.setTranslateLang(dataItem.language);
        this.apiShared.Setapishareddata(dataItem.language, "language");
        this.langSelected = dataItem.language; //console.log(`${dataItem.actionName}ing last action.`);
        this.iconClass = dataItem.icon + ' mr-1';
        this.translateMenutext(this.langSelected);
      },
    },

    {
      language: "Spanish",
      icon: "flag-icon flag-icon-es flagIcon",
      click: (dataItem) => {
        this.translate.use("es");
        this.apiShared.LanguageSelected = dataItem.language;
        this.apiShared.setTranslateLang(dataItem.language);
        this.apiShared.Setapishareddata(dataItem.language, "language");
        this.langSelected = dataItem.language; //console.log(`${dataItem.actionName}ing last action.`);
        this.iconClass = dataItem.icon + ' mr-1';
        this.translateMenutext(this.langSelected);
      },
    },

    // {
    //   language: "Japaneese",
    //   icon: "flag-icon flag-icon-jp flagIcon",
    //   click: (dataItem) => {
    //     this.translate.use("jp");
    //     this.apiShared.LanguageSelected = dataItem.language;
    //     this.apiShared.setTranslateLang(dataItem.language);
    //     this.apiShared.Setapishareddata(dataItem.language, "language");
    //     this.langSelected = dataItem.language; //console.log(`${dataItem.actionName}ing last action.`);
    //     this.iconClass = dataItem.icon+' mr-1';
    //     this.translateMenutext(this.langSelected);
    //   },
    // },
    // {
    //   language: "German",
    //   icon: "flag-icon flag-icon-de flagIcon",
    //   click: (dataItem) => {
    //     this.translate.use("de");
    //     this.apiShared.LanguageSelected = dataItem.language;
    //     this.apiShared.setTranslateLang(dataItem.language);
    //     this.apiShared.Setapishareddata(dataItem.language, "language");
    //     this.langSelected = dataItem.language; //console.log(`${dataItem.actionName}ing last action.`);
    //     this.iconClass = dataItem.icon+' mr-1';
    //     this.translateMenutext(this.langSelected);
    //   },
    // },
  ];
  public selectedLang: any;
  public menus: Menu[];
  public menus1: Menu1[];
  public notice: Observable<any>;
  public AccountsSideMenus: AccountMenu[]; //By case id-363
  AccountOptions: Config = { multi: false }; //By case id-363
  Profile: string = "Profile"; //By case id-363
  AccountSettings: string = "Account Settings"; //By case id-363
  SubscriptionDetails: string = "Subscription Details"; //By case id-363
  public hideaccountsmenucomponent = true; //By case id-363
  public hideaccordianmenucomponent = false; //By case id-363
  Home: string = "Home"; //By case id-363
  public PCLogout: any = {
    PCNO: sessionStorage.getItem("PCNO"),
    LogoutTime: "",
  };
  UrlService: any;
  UrlList: any;
  constructor(
    private intl: IntlService,
    private http: HttpClient,
    private trasilatesettings: TranslateStore,
    public common: CommonserviceService,
    private router: Router,
    private logoutService: LoginService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private apiShared: ShareDataService,
    private penchannelApi: PenChannelService,
    public apipenLock1: PendoclockService,
    public apipenLock: PendoclockService,
    private toastr: ToastrService,
    public file: FileService,

    private env: EnvService,
    private googleauthservice: GoogleAuthService,
    private dialog: MatDialog,
    private alertService: AlertService
    // @Inject(LOCAL_STORAGE) private storageService: StorageService
  ) {
    this.notice = new Observable((observer) => {
      this.observer = observer;
    });
  }
  public isTFAEnabled = false;
  ngOnInit() {
    this.getURL()
    this.getDateandCurrencyFormat();
    //alert(JSON.stringify(require('../../config').config.appVersion
    //));
    this.version = require("../../config").config.appVersion;
    this.GetFooterDetails();
    // this.interceptor.intercept(() => this.selectedLang);
    // if(this.common.checkPermission('01.11.03.13')==true )
    // {
    //   this.airticketrpt='/Reports/AirticketReportWithPNRReport';
    // }
    // else{

    //   this.airticketrpt=this.alert;
    // }

    /** spinner starts on init */
    //this.spinner.show();
    this.username = this.apiShared.uname;

    if (this.username === undefined || this.username === null) {
      this.router.navigate(["/Login"]);
    } else {
      this.username = " " + this.username;
    }

    this.passengername = sessionStorage.getItem("PassengerNames");
    this.orderno = sessionStorage.getItem("orderNo");
    if (
      this.passengername !== "" &&
      this.passengername !== null &&
      this.passengername !== "null"
    ) {
      this.headerPassenger = this.passengername + '- Folder No:' + this.apiShared.projNo + " Order No:" + this.orderno;
    } else {
      if (this.apiShared.projNo != null && +this.apiShared.projNo > 0)
        this.headerPassenger = 'Folder No:' + this.apiShared.projNo + " Order No:" + this.orderno;
    }
    this.folderstatus = sessionStorage.getItem("FolderStatus");
    this.langSelected = sessionStorage.getItem("LanguageSelected");
    if (this.langSelected == undefined || this.langSelected == null) {
      this.langSelected = "English";
    }

    this.translateMenutext(this.langSelected);
    this.headerrefresh = sessionStorage.getItem("headerText");
    if (this.headerrefresh != undefined) {
      if (this.headerrefresh === "null" || this.headerrefresh === null) {
        this.headerText = this.headerText;
      } else {
        this.headerText = this.headerrefresh;
      }
    }

    this.mainmenu = sessionStorage.getItem("mainmenu");

    if (this.mainmenu !== undefined) {
      if (this.mainmenu !== "null" && this.mainmenu !== null) {
        this.mainmenu = this.mainmenu;
        this.headervalue = this.mainmenu + "/" + this.headerText;
        // this.pageheader(this.headervalue);
      }
    }

    //this.getmenus(this.langSelected);
    if (this.langSelected == "English") {
      this.iconClass = "flag-icon flag-icon-us mr-1";
    }
    if (this.langSelected == "Chinese") {
      this.iconClass = "flag-icon flag-icon-cn mr-1";
    }
    if (this.langSelected == "French") {
      this.iconClass = "flag-icon flag-icon-fr mr-1";
    }
    if (this.langSelected == "Spanish") {
      this.iconClass = "flag-icon flag-icon-es mr-1";
    }
    if (this.langSelected == "Japaneese") {
      this.iconClass = "flag-icon flag-icon-jp mr-1";
    }
    if (this.langSelected == "German") {
      this.iconClass = "flag-icon flag-icon-de mr-1";
    }
    // this.reportViewer.refreshReport();
    // setTimeout(() => {
    /** spinner ends after 5 seconds */
    //this.spinner.hide();
    //}, 5000);

    var permission = sessionStorage.getItem("permissiondetailslist");
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let secretToken = localStorage.getItem("secretToken");
    this.isTFAEnabled = secretToken == 'null' ? false : true;
    if (permission === null && this.common.browserRefresh) {
      var ss = this.router.url;
      //this.logoutService.GetPermission(this.currentUser.ResponseData.UID);
      // this.router.navigate(['/PenAir/Home']);
      permission = sessionStorage.getItem("permissiondetailslist");
      if (
        sessionStorage.getItem("reporturl") != null &&
        this.common.browserRefresh
      ) {
        var reporturl = sessionStorage.getItem("reporturl");

        return this.router.navigateByUrl(reporturl);
      } else if (this.common.browserRefresh) {
        return this.router.navigateByUrl(this.router.url);
      } else {
        // 3

        this.router.navigateByUrl("/PenAir/Home");
      }
    }
    if (sessionStorage.getItem("reporturl") != null) {
      permission = sessionStorage.getItem("permissiondetailslist");
      var reporturl = sessionStorage.getItem("reporturl");

      return this.router.navigateByUrl(reporturl);
    } else if (history.length == 1) {
      //6

      //this.router.navigate(["/PenAir/Folder/Index"]);
      this.router.navigate(["/PenAir/Home"]);

    }
  }

  // signle open mode
  options: Config = { multi: false };

  async pageheader(header) {
    //Case id-365

    this.headervalue = header;
    if (this.headervalue == "Hotel/Book A Hotel") {
      if (Boolean(await this.common.checkPermission1("13.10.01")) == true) {
        window.open(this.env.HotelWebSiteURL, "_blank"); //Case id-365
      } else {
        this.ShowPermissionMessageForm("13.10.01");
      }
    }

    if (this.headervalue == "Assets/Fixed Assets") {
      if (Boolean(await this.common.checkPermission1("FixedAssets")) == true) {
        this.UrlService = this.UrlList.find(x => x.WS_ServiceType == 'FixedAssets')
        if (this.UrlService != null && this.UrlService.WS_ServiceURL != '') {
          window.open(this.UrlService.WS_ServiceURL + '?uname=' + this.username.trim());
        }
        else {
          this.showMsg = "Messages.urlNotFound";
          this.common.showWARNINGtoastrmsg(
            this.showMsg,
            2000,
            true,
            false
          );
        }


      }
      else {
        this.ShowPermissionMessageForm("FixedAssets");
      }
      //  } else {
      //   this.ShowPermissionMessageForm("13.10.01");
      //}
    }
    //13992
    if (this.headervalue == "Administration/Customer Portal") {


      if (Boolean(await this.common.checkPermission1("CustomerPortal")) == true) {
        this.UrlService = this.UrlList.find(x => x.WS_ServiceType == 'Customeronweb')
        if (this.UrlService != null && this.UrlService.WS_ServiceURL != '') {
          window.open(this.UrlService.WS_ServiceURL, "_blank");
        }
        else {
          this.showMsg = "Messages.urlNotFound";
          this.common.showWARNINGtoastrmsg(
            this.showMsg,
            2000,
            true,
            false
          );
        }


      }
      else {
        this.ShowPermissionMessageForm("CustomerPortal");
      }
      //  } else {
      //   this.ShowPermissionMessageForm("13.10.01");
      //}
    }
    //13992
    //  this.mainmenutransilate=sessionStorage.getItem('mainmenutransilate');
    // this.headerlanguage=new BehaviorSubject(sessionStorage.getItem('headerlanguage'));
    //  if(this.mainmenutransilate!=undefined){
    //   if(this.mainmenutransilate!=='null' && this.mainmenutransilate!==null) {
    //     this.subscription.add( this.translate.get([this.mainmenutransilate],).subscribe((translations ) => {
    //       this.mainmenu= translations[this.mainmenutransilate];
    //      }));
    //      if(this.headerText=='Inbox' ||this.headerText=='Closed'||this.headerText=='Shared Inbox')
    //      {
    //       this.subscription.add( this.translate.get(['Mainmenu.Alerts'],).subscribe((translations ) => {
    //         this.mainmenu= translations['Mainmenu.Alerts'];

    //        }));
    //      }
    //      if(this.headerText=='Inbox')
    //      {

    //         this.subscription.add( this.translate.get(['Mainmenu.Inbox'],).subscribe((translations ) => {
    //       this.headerText= translations['Mainmenu.Inbox'];
    //      }));
    //      }
    //      if(this.headerText=='Closed')
    //      {

    //         this.subscription.add( this.translate.get(['Mainmenu.Closed'],).subscribe((translations ) => {
    //       this.headerText= translations['Mainmenu.Closed'];
    //      }));
    //      }
    //      if(this.headerText=='Shared Inbox')
    //      {

    //         this.subscription.add( this.translate.get(['Mainmenu.SharedInbox'],).subscribe((translations ) => {
    //       this.headerText= translations['Mainmenu.SharedInbox'];
    //      }));
    //      }
    //     this.headervalue=this.mainmenu+'/'+this.headerText;
    //    }
    //  }
  }
  public permissionmessageformopened: any;
  public PermissionMessageId: any;

  public PermissionFormClosing(status: any) {
    if (status == "EmailSendSuccesfully") {
      this.permissionmessageformopened = false;
    }
  }


  getURL() {
    this.subscription.add(this.file.GetURL()
      .subscribe(data => {
        if (data != null) {
          this.UrlList = data.ResponseData;
        }
      }));
  }

  ShowPermissionMessageForm(permissionid: string) {
    this.permissionmessageformopened = true;
    this.PermissionMessageId = permissionid;
  }
  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
  }

  changeheader(hdtext) {

    //this.previousUrl
    // this.menus[2]
    sessionStorage.setItem("FolderStatus", "");
    sessionStorage.setItem("PassengerNames", "");
    sessionStorage.setItem("PassengerName", "");
    sessionStorage.setItem("orderNo", "");
    this.folderstatus = null;
    this.headerPassenger = null;
    this.headerText = hdtext;
    this.headerlanguage = hdtext;
    //this.common.isfinalsaved=true;
    if (
      this.common.isfinalsaved === true ||
      this.common.folderEditMode === "true"
    ) {
      //this.common.showFlash1('Messages.FolderOpen', 'bg-danger text-white', 2000);
      this.subscription.add(
        this.translate
          .get(["Messages.FolderOpen"])
          .subscribe((translations) => {
            this.showMsg = translations["Messages.FolderOpen"];
            this.toastr.error(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true,
            });
          })
      );
      return this.router.navigateByUrl(this.router.url);
    } else {
      //     var data = this.menus[2].submenu.find(x => x.name == hdtext)
      //     if (data === undefined) {
      //       var data = this.menus[3].submenu.find(x => x.name == hdtext)
      //     }
      //       if (data === undefined) {
      //         var data = this.menus[5].submenu.find(x => x.name == hdtext)
      //     }
      //     if (data === undefined) {
      //       var data = this.menus[6].submenu.find(x => x.name == hdtext)
      //   }
      //   if (data === undefined) {
      //     var data = this.menus[4].submenu.find(x => x.name == hdtext)
      // }

      this.headerText = hdtext;
      sessionStorage.setItem("headerText", this.headerText);

      this.getheadertext();
    }
  }
  showmsg(permissionid: string) {
    this.router.navigate(["PenAir/Folder/Index"]);
    this.headerText = "Alerts";
    this.common.showPermissiontoastrmsg(
      "Messages.Permissiondenied",
      5000,
      true,
      false,
      permissionid
    );
  }
  onActivate(componentReference) {
    //// console.log(componentReference)
    //componentReference.anyFunction();
    //Below will subscribe to the searchItem emitter
    if (componentReference.selectedPassenger != undefined)
      this.subscription.add(
        componentReference.selectedPassenger.subscribe((data: any) => {
          this.headerPassenger = data;

          // Will receive the data from child here
        })
      );
  }
  translateMenutext(language: any) {
    if (language == "") {
      language = this.langSelected;
    }
    // For menu label translation 
    forkJoin([this.translate.get(["Mainmenu.Alerts"]),//0
    this.translate.get(["Mainmenu.Search"]), //1
    this.translate.get(["Mainmenu.Inbox"]), //2
    this.translate.get(["Mainmenu.Closed"]), //3
    this.translate.get(["Mainmenu.Shared"]), //4
    this.translate.get(["Mainmenu.Reports"]), //5
    this.translate.get(["Mainmenu.AirTktrptPNR"]), //6
    this.translate.get(["Mainmenu.SalesListing"]), //7
    this.translate.get(["Mainmenu.CustomerReceiptsAndPaymentRpt"]), //8
    this.translate.get(["Mainmenu.CustomerStatement"]), //9

    this.translate.get(["Mainmenu.BankStatement"]), //10
    this.translate.get(["Mainmenu.TrialBalance"]), //11
    this.translate.get(["Mainmenu.TrialBalancewithCostCenter"]), //12
    this.translate.get(["Mainmenu.Supplier"]), //13
    this.translate.get(["Mainmenu.Customer"]), //14
    this.translate.get(["Mainmenu.EmailInvoice"]), //15
    this.translate.get(["Mainmenu.Receipt"]), //16
    this.translate.get(["Mainmenu.CustReceipt"]), //17
    this.translate.get(["Mainmenu.CashDetails"]), //18
    this.translate.get(["Mainmenu.Payment"]), //19

    this.translate.get(["Mainmenu.ViewSupplier"]), //20
    this.translate.get(["Mainmenu.ViewCustomer"]), //21
    this.translate.get(["Mainmenu.PettyCash"]), //22
    this.translate.get(["Mainmenu.Adminstration"]), //23
    this.translate.get(["Mainmenu.PettyCashPayment"]), //24
    this.translate.get(["Mainmenu.CashPayment"]), //25
    this.translate.get(["Mainmenu.Utilities"]), //26
    this.translate.get(["Mainmenu.UnlockDocument"]), //27
    this.translate.get(["Mainmenu.Reconcilation"]), //28
    this.translate.get(["Mainmenu.BankReconcilation"]), //29

    this.translate.get(["Mainmenu.Help"]), //30
    this.translate.get(["Mainmenu.Favorites"]), //31
    this.translate.get(["Mainmenu.Guidlines"]), //32
    this.translate.get(["Mainmenu.BeginnerNotes"]), //33
    this.translate.get(["Mainmenu.Configsettings"]), //34
    this.translate.get(["Mainmenu.FolderActivity"]), //35
    this.translate.get(["Mainmenu.FolderQuery"]), //36
    this.translate.get(["Mainmenu.SuppierStatementrpt"]), //37
    this.translate.get(["Mainmenu.PandLrpt"]), //38
    this.translate.get(["Mainmenu.Airticketreports"]), //39

    this.translate.get(["Mainmenu.Folder"]), //40
    this.translate.get(["Mainmenu.FolderReport"]), //41
    this.translate.get(["Mainmenu.UserSettings"]), //42
    this.translate.get(["Mainmenu.Shortcuts"]), //43
    this.translate.get(["Mainmenu.AuditReport"]), //44
    this.translate.get(["Mainmenu.Delink"]), //45
    this.translate.get(["Mainmenu.Settings"]), //46
    this.translate.get(["Mainmenu.PropertyTypes"]), //47
    this.translate.get(["Mainmenu.MealPlanMaster"]), //48
    this.translate.get(["Mainmenu.PenChannel"]), //49

    this.translate.get(["Mainmenu.Hotel"]), //50
    this.translate.get(["Mainmenu.HotelList"]), //51
    this.translate.get(["Mainmenu.BookAHotel"]), //52
    this.translate.get(["Mainmenu.ConsolidatedInvoice"]), //53
    this.translate.get(["Mainmenu.Nominal"]), //54
    this.translate.get(["Mainmenu.Chartofaccounts"]), //55
    this.translate.get(["Mainmenu.ViewNominal"]), //56
    this.translate.get(["Mainmenu.ViewCOA"]), //57
    this.translate.get(["Mainmenu.Travelsetting"]), //58
    this.translate.get(["Mainmenu.Commonsetting"]), //59

    this.translate.get(["Mainmenu.Productsetting"]), //60
    this.translate.get(["Mainmenu.Financesetting"]), //61
    this.translate.get(["Mainmenu.ProductGroupMaster"]), //62
    this.translate.get(["Mainmenu.ViewProductGroupMaster"]), //63
    this.translate.get(["Mainmenu.Invoices"]), //64
    this.translate.get(["Mainmenu.Corporate"]), //65
    this.translate.get(["Mainmenu.PenChannel"]), //66
    this.translate.get(["Mainmenu.Financialstatements"]), //67
    this.translate.get(["Mainmenu.Bank"]), //68
    this.translate.get(["Mainmenu.PaymentGateway"]), //69

    this.translate.get(["Mainmenu.PaymentGatewayList"]), //70
    this.translate.get(["Mainmenu.PettyCashMaster"]), //71
    this.translate.get(["Mainmenu.PettyCashMasterList"]), //72
    this.translate.get(["Mainmenu.BankPayInSlip"]), //73
    this.translate.get(["Mainmenu.Enquiry"]), //74
    this.translate.get(["Mainmenu.CRM"]), //75
    this.translate.get(["Mainmenu.PurgeAlerts"]), //76
    this.translate.get(["Mainmenu.RoomTypesMaster"]), //77
    this.translate.get(["Mainmenu.ProjectMaster"]), //78
    this.translate.get(["Mainmenu.HotelPricingGroup"]), //79

    this.translate.get(["Mainmenu.ReportSchedulerList"]), //80
    this.translate.get(["Mainmenu.Profile"]), //81
    this.translate.get(["Mainmenu.AccountSettings"]), //82
    this.translate.get(["Mainmenu.SubscriptionDetails"]), //83
    this.translate.get(["Mainmenu.Home"]), //84
    this.translate.get(["Mainmenu.CurrencyMaster"]), //85
    this.translate.get(["Mainmenu.AtolReportFiling"]), //86
    this.translate.get(["Mainmenu.Accounts"]), //87
    this.translate.get(["Mainmenu.AuditandQuries"]), //88
    this.translate.get(["Mainmenu.VoucherLiability"]), //89

    this.translate.get(["Mainmenu.CRMSettings"]), //90
    this.translate.get(["Mainmenu.ScreenVariant"]), //91
    this.translate.get(["Mainmenu.PettyCashReport"]), //92
    this.translate.get(["Mainmenu.CashReceipt"]), //93
    this.translate.get(["Mainmenu.ManualSales"]), //94
    this.translate.get(["Mainmenu.ManualPDQReconciliation"]), //95
    this.translate.get(["Mainmenu.CustomerOutStandingSummary"]), //96
    this.translate.get(["Mainmenu.AirlineDetailedReport"]),//97
    this.translate.get(["Mainmenu.GeneralLedger"]),//98
    this.translate.get(["Mainmenu.OrderTypeMaster"]), //99

    this.translate.get(["Mainmenu.BankPayment"]),//100
    this.translate.get(["Mainmenu.BSP"]),//101
    this.translate.get(["Mainmenu.BSPManualReconciliation"]),//102
    this.translate.get(["Mainmenu.VoucherMngt"]),//103
    this.translate.get(["Mainmenu.GiftcardOrVoucher"]),//104
    this.translate.get(["Mainmenu.ExportData"]),//105
    this.translate.get(["Mainmenu.AutoReceipt"]),//106
    this.translate.get(["Mainmenu.supplierReconciliation"]),//107
    this.translate.get(["Mainmenu.FolderProduct"]), //108
    this.translate.get(["Mainmenu.QuebecOPCFICAVFeesReport"]), //109

    this.translate.get(["Mainmenu.StatutoryReport"]), //110
    this.translate.get(["Mainmenu.Canada"]), //111
    this.translate.get(["Mainmenu.AutomaticCreditCardRecon"]),//112
    this.translate.get(["Mainmenu.CustomerStatementbylineitem"]),//113
    this.translate.get(["Mainmenu.MarketingCodeMaster"]),//114
    this.translate.get(["Mainmenu.ProfitCentreList"]),//115 
    this.translate.get(["Mainmenu.ActionMaster"]),//116
    this.translate.get(["Mainmenu.BankReceipt"]),//117
    this.translate.get(["Mainmenu.FraudScoreSettings"]),//118
    this.translate.get(["Mainmenu.Validations"]),//119

    this.translate.get(["Mainmenu.BSPDirectExpense"]), //120
    this.translate.get(["Mainmenu.CountryMaster"]),//121
    this.translate.get(["Mainmenu.CityMaster"]),//122
    this.translate.get(["Mainmenu.DropdowmListMaster"]),//123
    this.translate.get(["Mainmenu.MarketingCodeGroupMaster"]),//124
    this.translate.get(["Mainmenu.ProfitCentreGroupMaster"]),//125
    this.translate.get(["Mainmenu.GdsUsermappingList"]),//126
    this.translate.get(["Mainmenu.CostCentreMaster"]),//127
    this.translate.get(["Mainmenu.GeneralLedgerReport"]),//128
    this.translate.get(["Mainmenu.AuditQuery"]), //129

    this.translate.get(["Mainmenu.FolderInstallmentReport"]),//130
    this.translate.get(["Mainmenu.EmailSettingsList"]),//131
    this.translate.get(["Mainmenu.CustomerGroupMaster"]),//132
    this.translate.get(["Mainmenu.EmailTemplatesGrid"]),//133
    this.translate.get(["Mainmenu.CreateFolder"]),//134
    this.translate.get(["Mainmenu.CustomerCreditGroup"]),//135
    this.translate.get(["Mainmenu.FolderO2C"]),//136
    this.translate.get(["Mainmenu.FolderAddExpense"]),//137
    this.translate.get(["Mainmenu.GroupBooking"]),//138
    this.translate.get(["Mainmenu.PassengerBookingDetails"]),//139

    this.translate.get(["Mainmenu.SupplierGroupMaster"]),//140
    this.translate.get(["Mainmenu.StaffGroupMaster"]),//141
    this.translate.get(["Mainmenu.TaxReports"]),//142
    this.translate.get(["Mainmenu.General"]),//143
    this.translate.get(["Mainmenu.TaxCalculation"]),//144
    this.translate.get(["Mainmenu.JournalTransaction"]),//145
    this.translate.get(["Mainmenu.Journal"]),//146
    this.translate.get(["Mainmenu.BanktoBankTransfer"]),//147
    this.translate.get(["Mainmenu.DefaultCharges"]),//148
    this.translate.get(["Mainmenu.TaxRateMaster"]),//149

    this.translate.get(["Mainmenu.AirwaysMaster"]),//150
    this.translate.get(["Mainmenu.MarketingRegion"]),//151
    this.translate.get(["Mainmenu.PaymentBlockMaster"]),//152
    this.translate.get(["Mainmenu.CompanyCardPayment"]),//153
    this.translate.get(["Mainmenu.BankMaster"]),//154
    this.translate.get(["Mainmenu.PaymentMethodMaster"]),//155
    this.translate.get(["Mainmenu.ManualCreditNotes"]),//156
    this.translate.get(["Mainmenu.CardMaster"]),//157
    this.translate.get(["Mainmenu.GDSAutoFolderSettings"]),//158
    this.translate.get(["Mainmenu.PaymentTermsMaster"]),//159

    this.translate.get(["Mainmenu.IATAMaster"]),//160
    this.translate.get(["Mainmenu.ThirdPartyMapping"]),//161  
    this.translate.get(["Mainmenu.AgentCommissionList"]),//162
    this.translate.get(["Mainmenu.thirdPartyExcelMapping"]),//163
    this.translate.get(["Mainmenu.UAE"]),//164
    this.translate.get(["Mainmenu.AgeingScheduleMaster"]),//165
    this.translate.get(["Mainmenu.Workflow"]),//166
    this.translate.get(["Mainmenu.AutomaticCompanyCardRecon"]),//167
    this.translate.get(["Mainmenu.TaxMaster"]),//168
    this.translate.get(["Mainmenu.CustomerAllocation"]),//169    

    this.translate.get(["Mainmenu.SuppierAllocationReport"]),//170
    this.translate.get(["Mainmenu.SupplierRemittanceAdvice"]),//171
    this.translate.get(["Mainmenu.DailyJournalAuditReport"]),//172 
    this.translate.get(["Mainmenu.ForeignCurrencyCustomerStatment"]),//173
    this.translate.get(["Mainmenu.DepositAsondateReport"]),//174
    this.translate.get(["Mainmenu.ExpenseInvoice"]),//175
    this.translate.get(["Mainmenu.CannedMessage"]),//176
    this.translate.get(["Mainmenu.CustomerOutstandingReport"]),//177
    this.translate.get(["Mainmenu.SupplierOutstandingDetailedAsondateReport"]),//178
    this.translate.get(["Mainmenu.ForeignCurrencySupplierStatement"]),//179

    this.translate.get(["Mainmenu.SupplierOutStandingSummary"]),//180
    this.translate.get(["Mainmenu.RewardPointsReconciliation"]),//181
    this.translate.get(["Mainmenu.EwaySettings"]),//182
    this.translate.get(["Mainmenu.DepositCollectedforFutureTravel"]),//183
    this.translate.get(["Mainmenu.ReplenishmentReport"]),//184
    this.translate.get(["Mainmenu.RewardPoint"]),//185
    this.translate.get(["Mainmenu.MessageAnalysis"]),//186
    this.translate.get(["Mainmenu.GiftCardMaster"]),//187
    this.translate.get(["Mainmenu.DeferredRevenueAndExpenseReport"]),//188
    this.translate.get(["Mainmenu.MessageSettings"]),//189

    this.translate.get(["Mainmenu.ReasonCodeMaster"]),//190
    this.translate.get(["Mainmenu.MainDocumentType"]),//191
    this.translate.get(["Mainmenu.StoreFrontMaster"]),//192
    this.translate.get(["Mainmenu.DirectRefundFolder"]),//193
    this.translate.get(["Mainmenu.SftpSettings"]),//194



    this.translate.get(["Mainmenu.FolderActivityDetailed"]),//195
    this.translate.get(["Mainmenu.ManualCompanyCardRec"]),//196
    this.translate.get(["Mainmenu.Commission"]),//197
    this.translate.get(["Mainmenu.CommissionbasedonProduct"]),//198
    this.translate.get(["Mainmenu.CommissionbasedonReceipt"]),//199

    this.translate.get(["Mainmenu.PostCodeSearchSettings"]),//200
    this.translate.get(["Mainmenu.DealIdMaster"]),//201
    this.translate.get(["Mainmenu.AutomaticPO"]),//202
    this.translate.get(["Mainmenu.GDSMaster"]),//203
    this.translate.get(["Mainmenu.ThirdPartyCRMSettings"]),//204
    this.translate.get(["Mainmenu.GiftCardVoucherIssue"]),//205
    this.translate.get(["Mainmenu.Busareawiseemailandphone"]),//206
    this.translate.get(["Mainmenu.AutoChargingInstallment"]),//207
    this.translate.get(["Mainmenu.SupplierAllocation"]),//208
    this.translate.get(["Mainmenu.EStatement"]),//209
    this.translate.get(["Mainmenu.AutomaticReminder"]),//210
    this.translate.get(["Mainmenu.BacsReconciliation"]),//211
    this.translate.get(["Mainmenu.AutomaticBalanceReminder"]),//212
    this.translate.get(["Mainmenu.Websites"]),//213
    this.translate.get(["Mainmenu.CustomerPortal"]),//214
    // this.translate.get(["Mainmenu.PurchaseListing"]),//215
    this.translate.get(["Mainmenu.PurchaseCommitmentReport"]),//215

    this.translate.get(["Mainmenu.UK"]),//216
    this.translate.get(["Mainmenu.ATOL"]),//217
    this.translate.get(["Mainmenu.PurchaseCommitmentReport"]),//218
    this.translate.get(["Mainmenu.ScheduleChangeCommunication"]),//219
    this.translate.get(["Mainmenu.SupplierLedgerreport"]),//220
    this.translate.get(["Mainmenu.Assets"]),//221
    this.translate.get(["Mainmenu.FixedAssets"]),//222
    this.translate.get(["Mainmenu.TicketingthroughGDS"]),//223
    this.translate.get(["Mainmenu.CustomerLedgerwithItinerary"]),//224
    this.translate.get(["Mainmenu.SupplierOutstandingAsOnDateAgedReport"]),//225
    this.translate.get(["Mainmenu.CustomerLedgerReport"]),//226
    this.translate.get(["Mainmenu.CustomerOutstandingAsOnDateAgedSummaryReport"]),//227
    this.translate.get(["Mainmenu.MasterDataList"]),//228
    this.translate.get(["Mainmenu.CustomerList"]),//229
    this.translate.get(["Mainmenu.MarketingBookingData"]),//230
    this.translate.get(["Mainmenu.CurrencyRevaluation"]),//231
    this.translate.get(["Mainmenu.GLRevaluation"]),//232
    this.translate.get(["Mainmenu.DebtorsBalanceRevaluation"]),//233
    this.translate.get(["Mainmenu.CreditorsBalanceRevaluation"]),//234
    this.translate.get(["Mainmenu.CustomerAgeingWithCreditLimit"]),//235
    this.translate.get(["Mainmenu.UserList"])//236
      // this.translate.get(["Mainmenu.FolderSalesReceiptReport"]),//236
      // this.translate.get(["Mainmenu.DailyAudit"]),//237
      //this.translate.get(["Mainmenu.CreditCardReconciliationStatement"])//239





    ]).subscribe(results => {
      this.Alerts = results[0]["Mainmenu.Alerts"];
      this.Search = results[1]["Mainmenu.Search"];
      this.Inbox = results[2]["Mainmenu.Inbox"];
      this.Closed = results[3]["Mainmenu.Closed"];
      this.Shared = results[4]["Mainmenu.Shared"];
      this.Reports = results[5]["Mainmenu.Reports"];
      this.AirTktrptPNR = results[6]["Mainmenu.AirTktrptPNR"];
      this.SalesListing = results[7]["Mainmenu.SalesListing"];
      this.CustomerReceiptsAndPaymentRpt = results[8]["Mainmenu.CustomerReceiptsAndPaymentRpt"];
      this.CustomerStatement = results[9]["Mainmenu.CustomerStatement"];

      this.BankStatement = results[10]["Mainmenu.BankStatement"];
      this.TrialBalance = results[11]["Mainmenu.TrialBalance"];
      this.TrialBalancewithCostCenter = results[12]["Mainmenu.TrialBalancewithCostCenter"];
      this.Supplier = results[13]["Mainmenu.Supplier"];
      this.Customer = results[14]["Mainmenu.Customer"];
      this.EmailInvoice = results[15]["Mainmenu.EmailInvoice"];
      this.Receipt = results[16]["Mainmenu.Receipt"];
      this.CustReceipt = results[17]["Mainmenu.CustReceipt"];
      this.CashDetails = results[18]["Mainmenu.CashDetails"];
      this.Payment = results[19]["Mainmenu.Payment"];

      this.ViewSupplier = results[20]["Mainmenu.ViewSupplier"];
      this.ViewCustomer = results[21]["Mainmenu.ViewCustomer"];
      this.PettyCash = results[22]["Mainmenu.PettyCash"];
      this.Adminstration = results[23]["Mainmenu.Adminstration"];
      this.PettyCashPayment = results[24]["Mainmenu.PettyCashPayment"];
      this.CashPayment = results[25]["Mainmenu.CashPayment"];
      this.Utilities = results[26]["Mainmenu.Utilities"];
      this.UnlockDocument = results[27]["Mainmenu.UnlockDocument"];
      this.Reconcilation = results[28]["Mainmenu.Reconcilation"];
      this.BankReconcilation = results[29]["Mainmenu.BankReconcilation"];

      this.Help = results[30]["Mainmenu.Help"];
      this.Favorites = results[31]["Mainmenu.Favorites"];
      this.Guidlines = results[32]["Mainmenu.Guidlines"];
      this.BeginnerNotes = results[33]["Mainmenu.BeginnerNotes"];
      this.Configsettings = results[34]["Mainmenu.Configsettings"];
      this.FolderActivity = results[35]["Mainmenu.FolderActivity"];
      this.FolderQuery = results[36]["Mainmenu.FolderQuery"];
      this.SuppierStatementrpt = results[37]["Mainmenu.SuppierStatementrpt"];
      this.PandLrpt = results[38]["Mainmenu.PandLrpt"];
      this.Airticketreports = results[39]["Mainmenu.Airticketreports"];

      this.Folder = results[40]["Mainmenu.Folder"];
      this.FolderReport = results[41]["Mainmenu.FolderReport"];
      this.UserSettings = results[42]["Mainmenu.UserSettings"];
      this.Shortcuts = results[43]["Mainmenu.Shortcuts"];
      this.AuditReport = results[44]["Mainmenu.AuditReport"];
      this.Delink = results[45]["Mainmenu.Delink"];
      this.Settings = results[46]["Mainmenu.Settings"];
      this.PropertyTypes = results[47]["Mainmenu.PropertyTypes"];
      this.MealPlanMaster = results[48]["Mainmenu.MealPlanMaster"];
      this.PenChannel = results[49]["Mainmenu.PenChannel"];

      this.Hotel = results[50]["Mainmenu.Hotel"];
      this.HotelList = results[51]["Mainmenu.HotelList"];
      this.BookAHotel = results[52]["Mainmenu.BookAHotel"];
      this.ViewConsolidatedInvoice = results[53]["Mainmenu.ConsolidatedInvoice"];
      this.Nominal = results[54]["Mainmenu.Nominal"];
      this.Chartofaccounts = results[55]["Mainmenu.Chartofaccounts"];
      this.ViewNominal = results[56]["Mainmenu.ViewNominal"];
      this.ViewCOA = results[57]["Mainmenu.ViewCOA"];
      this.Travelsetting = results[58]["Mainmenu.Travelsetting"];
      this.Commonsetting = results[59]["Mainmenu.Commonsetting"];

      this.Productsetting = results[60]["Mainmenu.Productsetting"];
      this.Financesetting = results[61]["Mainmenu.Financesetting"];
      this.ProductGroupMaster = results[62]["Mainmenu.ProductGroupMaster"];
      this.ViewProductGroupMaster = results[63]["Mainmenu.ViewProductGroupMaster"];
      this.Invoices = results[64]["Mainmenu.Invoices"];
      this.Corporate = results[65]["Mainmenu.Corporate"];
      this.PenChannel = results[66]["Mainmenu.PenChannel"];
      this.Financialstatements = results[67]["Mainmenu.Financialstatements"];
      this.Bank = results[68]["Mainmenu.Bank"];
      this.PaymentGateway = results[69]["Mainmenu.PaymentGateway"];

      this.PaymentGatewayList = results[70]["Mainmenu.PaymentGatewayList"];
      this.PettyCashMaster = results[71]["Mainmenu.PettyCashMaster"];
      this.PettyCashMasterList = results[72]["Mainmenu.PettyCashMasterList"];
      this.BankPayInSlip = results[73]["Mainmenu.BankPayInSlip"];
      this.Enquiry = results[74]["Mainmenu.Enquiry"];
      this.CRM = results[75]["Mainmenu.CRM"];
      this.PurgeAlerts = results[76]["Mainmenu.PurgeAlerts"];
      this.RoomTypesMaster = results[77]["Mainmenu.RoomTypesMaster"];
      this.ProjectMaster = results[78]["Mainmenu.ProjectMaster"];
      this.HotelPricingGroup = results[79]["Mainmenu.HotelPricingGroup"];

      this.ReportSchedulerList = results[80]["Mainmenu.ReportSchedulerList"];
      this.Profile = results[81]["Mainmenu.Profile"];
      this.AccountSettings = results[82]["Mainmenu.AccountSettings"];
      this.SubscriptionDetails = results[83]["Mainmenu.SubscriptionDetails"];
      this.Home = results[84]["Mainmenu.Home"];
      this.CurrencyMaster = results[85]["Mainmenu.CurrencyMaster"];
      this.AtolReportFiling = results[86]["Mainmenu.AtolReportFiling"];
      this.Accounts = results[87]["Mainmenu.Accounts"];
      this.AuditandQuries = results[88]["Mainmenu.AuditandQuries"];
      this.VoucherLiability = results[89]["Mainmenu.VoucherLiability"];

      this.CRMSettings = results[90]["Mainmenu.CRMSettings"];
      this.ScreenVariant = results[91]["Mainmenu.ScreenVariant"];
      this.PettyCashReport = results[92]["Mainmenu.PettyCashReport"];
      this.CashReceipt = results[93]["Mainmenu.CashReceipt"];
      this.ManualSales = results[94]["Mainmenu.ManualSales"];
      this.ManualPDQReconciliation = results[95]["Mainmenu.ManualPDQReconciliation"]; //Caseid:13085
      this.CustomerOutStandingSummary = results[96]["Mainmenu.CustomerOutStandingSummary"];
      this.AirlineDetailedReport = results[97]["Mainmenu.AirlineDetailedReport"];
      this.GeneralLedger = results[98]["Mainmenu.GeneralLedger"];
      this.OrderTypeMaster = results[99]["Mainmenu.OrderTypeMaster"];

      this.BankPayment = results[100]["Mainmenu.BankPayment"];
      this.BSP = results[101]["Mainmenu.BSP"];
      this.BSPManualReconciliation = results[102]["Mainmenu.BSPManualReconciliation"];
      this.VoucherMngt = results[103]["Mainmenu.VoucherMngt"];
      this.GiftcardOrVoucher = results[104]["Mainmenu.GiftcardOrVoucher"];
      this.ExportData = results[105]["Mainmenu.ExportData"];
      this.AutoReceipt = results[106]["Mainmenu.AutoReceipt"];
      this.supplierReconciliation = results[107]["Mainmenu.supplierReconciliation"];
      this.FolderProduct = results[108]["Mainmenu.FolderProduct"];
      this.QuebecOPCFICAVFeesReport = results[109]["Mainmenu.QuebecOPCFICAVFeesReport"];

      this.StatutoryReport = results[110]["Mainmenu.StatutoryReport"];
      this.Canada = results[111]["Mainmenu.Canada"];
      this.AutomaticCreditCardRecon = results[112]["Mainmenu.AutomaticCreditCardRecon"];
      this.CustomerStatementbylineitem = results[113]["Mainmenu.CustomerStatementbylineitem"];
      this.MarketingCode = results[114]["Mainmenu.MarketingCodeMaster"];
      this.ProfitCentreList = results[115]["Mainmenu.ProfitCentreList"];
      this.ActionMaster = results[116]["Mainmenu.ActionMaster"];
      this.BankReceipt = results[117]["Mainmenu.BankReceipt"];
      this.FraudScoreSettings = results[118]["Mainmenu.FraudScoreSettings"];
      this.Validations = results[119]["Mainmenu.Validations"];

      this.BSPDirectExpense = results[120]["Mainmenu.BSPDirectExpense"];
      this.CountryMaster = results[121]["Mainmenu.CountryMaster"];
      this.CityMaster = results[122]["Mainmenu.CityMaster"];
      this.DropdowmListMaster = results[123]["Mainmenu.DropdowmListMaster"];
      this.MarketingCodeGroupMaster = results[124]["Mainmenu.MarketingCodeGroupMaster"];
      this.ProfitCentreGroupMaster = results[125]["Mainmenu.ProfitCentreGroupMaster"];
      this.GdsUsermappingList = results[126]["Mainmenu.GdsUsermappingList"];
      this.CostCentreMaster = results[127]["Mainmenu.CostCentreMaster"];
      this.GeneralLedgerReport = results[128]["Mainmenu.GeneralLedgerReport"];
      this.AuditQuery = results[129]["Mainmenu.AuditQuery"];

      this.FolderInstallmentReport = results[130]["Mainmenu.FolderInstallmentReport"];
      this.EmailSettingsList = results[131]["Mainmenu.EmailSettingsList"];
      this.CustomerGroupMaster = results[132]["Mainmenu.CustomerGroupMaster"];
      this.EmailTemplatesGrid = results[133]["Mainmenu.EmailTemplatesGrid"];
      this.CreateFolder = results[134]["Mainmenu.CreateFolder"];//134
      this.CustomerCreditGroup = results[135]["Mainmenu.CustomerCreditGroup"];
      this.FolderO2C = results[136]["Mainmenu.FolderO2C"];
      this.FolderAddExpense = results[137]["Mainmenu.FolderAddExpense"];
      this.GroupBooking = results[138]["Mainmenu.GroupBooking"];
      this.PassengerBookingDetails = results[139]["Mainmenu.PassengerBookingDetails"];

      this.SupplierGroupMaster = results[140]["Mainmenu.SupplierGroupMaster"];
      this.StaffGroupMaster = results[141]["Mainmenu.StaffGroupMaster"];
      this.TaxReports = results[142]["Mainmenu.TaxReports"];
      this.General = results[143]["Mainmenu.General"];
      this.TaxCalculation = results[144]["Mainmenu.TaxCalculation"];
      this.JournalTransaction = results[145]["Mainmenu.JournalTransaction"];
      this.Journal = results[146]["Mainmenu.Journal"];
      this.BanktoBankTransfer = results[147]["Mainmenu.BanktoBankTransfer"];
      this.DefaultCharges = results[148]["Mainmenu.DefaultCharges"];
      this.TaxRateMaster = results[149]["Mainmenu.TaxRateMaster"];

      this.AirwaysMaster = results[150]["Mainmenu.AirwaysMaster"];
      this.MarketingRegion = results[151]["Mainmenu.MarketingRegion"];
      this.PaymentBlockMaster = results[152]["Mainmenu.PaymentBlockMaster"];
      this.CompanyCardPayment = results[153]["Mainmenu.CompanyCardPayment"];
      this.BankMaster = results[154]["Mainmenu.BankMaster"];
      this.PaymentMethodMaster = results[155]["Mainmenu.PaymentMethodMaster"];
      this.ManualCreditNotes = results[156]["Mainmenu.ManualCreditNotes"];
      this.CardMaster = results[157]["Mainmenu.CardMaster"];
      this.GDSAutoFolderSettings = results[158]["Mainmenu.GDSAutoFolderSettings"];
      this.PaymentTermsMaster = results[159]["Mainmenu.PaymentTermsMaster"];

      this.IATAMaster = results[160]["Mainmenu.IATAMaster"];
      this.ThirdPartyMapping = results[161]["Mainmenu.ThirdPartyMapping"];
      this.AgentCommissionList = results[162]["Mainmenu.AgentCommissionList"];
      this.thirdPartyExcelMapping = results[163]["Mainmenu.thirdPartyExcelMapping"];
      this.UAE = results[164]["Mainmenu.UAE"];
      this.AgeingScheduleMaster = results[165]["Mainmenu.AgeingScheduleMaster"];
      this.Workflow = results[166]["Mainmenu.Workflow"];
      this.AutomaticCompanyCardRecon = results[167]["Mainmenu.AutomaticCompanyCardRecon"];
      this.TaxMaster = results[168]["Mainmenu.TaxMaster"];
      this.CustomerAllocation = results[169]["Mainmenu.CustomerAllocation"];

      this.SuppierAllocationReport = results[170]["Mainmenu.SuppierAllocationReport"];
      this.SupplierRemittanceAdvice = results[171]["Mainmenu.SupplierRemittanceAdvice"];
      this.DailyJournalAuditReport = results[172]["Mainmenu.DailyJournalAuditReport"];
      this.ForeignCurrencyCustomerStatment = results[173]["Mainmenu.ForeignCurrencyCustomerStatment"];
      this.DepositAsondateReport = results[174]["Mainmenu.DepositAsondateReport"];
      this.ExpenseInvoice = results[175]["Mainmenu.ExpenseInvoice"];
      this.CannedMessage = results[176]["Mainmenu.CannedMessage"];
      this.CustomeOutstandingasondateReport = results[177]["Mainmenu.CustomerOutstandingReport"];
      this.SupplierOutstandingDetailedAsondateReport = results[178]["Mainmenu.SupplierOutstandingDetailedAsondateReport"];
      this.ForeignCurrencySupplierStatement = results[179]["Mainmenu.ForeignCurrencySupplierStatement"];

      this.SupplierOutStandingSummary = results[180]["Mainmenu.SupplierOutStandingSummary"]
      this.RewardPointsReconciliation = results[181]["Mainmenu.RewardPointsReconciliation"];
      this.EwaySettings = results[182]["Mainmenu.EwaySettings"];
      this.DepositCollectedforFutureTravel = results[183]["Mainmenu.DepositCollectedforFutureTravel"];
      this.ReplenishmentReport = results[184]["Mainmenu.ReplenishmentReport"];//184
      this.RewardPoint = results[185]["Mainmenu.RewardPoint"];//185
      this.MessageAnalysis = results[186]["Mainmenu.MessageAnalysis"];
      this.GiftCardMaster = results[187]["Mainmenu.GiftCardMaster"];
      this.DeferredRevenueAndExpenseReport = results[188]["Mainmenu.DeferredRevenueAndExpenseReport"];
      this.MessageSettings = results[189]["Mainmenu.MessageSettings"];

      this.ReasonCodeMaster = results[190]["Mainmenu.ReasonCodeMaster"];
      this.MainDocumentType = results[191]["Mainmenu.MainDocumentType"];
      this.StoreFrontMaster = results[192]["Mainmenu.StoreFrontMaster"];
      this.DirectRefundFolder = results[193]["Mainmenu.DirectRefundFolder"]; //193
      this.SftpSettings = results[194]["Mainmenu.SftpSettings"];//194

      this.FolderActivityDetailed = results[195]["Mainmenu.FolderActivityDetailed"];
      this.ManualCompanyCardRec = results[196]["Mainmenu.ManualCompanyCardRec"]; //196
      this.Commission = results[197]["Mainmenu.Commission"];
      this.CommissionbasedonProduct = results[198]["Mainmenu.CommissionbasedonProduct"];
      this.CommissionbasedonReceipt = results[199]["Mainmenu.CommissionbasedonReceipt"];

      this.PostCodeSearchSettings = results[200]["Mainmenu.PostCodeSearchSettings"];//200
      this.DealIdMaster = results[201]["Mainmenu.DealIdMaster"];//201
      this.AutomaticPO = results[202]["Mainmenu.AutomaticPO"];//202
      this.GDSMaster = results[203]["Mainmenu.GDSMaster"];
      this.ThirdPartyCRMSettings = results[204]["Mainmenu.ThirdPartyCRMSettings"];
      this.GiftCardVoucherIssue = results[205]["Mainmenu.GiftCardVoucherIssue"];
      this.Busareawiseemailandphone = results[206]["Mainmenu.Busareawiseemailandphone"];
      this.AutoChargingInstallment = results[207]["Mainmenu.AutoChargingInstallment"];
      this.SupplierAllocation = results[208]["Mainmenu.SupplierAllocation"];
      this.EStatement = results[209]["Mainmenu.EStatement"];
      this.AutomaticReminder = results[210]["Mainmenu.AutomaticReminder"];
      this.BacsReconciliation = results[211]["Mainmenu.BacsReconciliation"];
      this.AutomaticBalanceReminder = results[212]["Mainmenu.AutomaticBalanceReminder"];
      this.Websites = results[213]["Mainmenu.Websites"];
      this.CustomerPortal = results[214]["Mainmenu.CustomerPortal"];
      // this.PurchaseListing = results[215]["Mainmenu.PurchaseListing"];
      this.PurchaseCommitmentReport = results[215]["Mainmenu.PurchaseCommitmentReport"];
      this.UK = results[216]["Mainmenu.UK"];
      this.ATOL = results[217]["Mainmenu.ATOL"];
      this.PurchaseCommitmentReport = results[218]["Mainmenu.PurchaseCommitmentReport"];
      this.ScheduleChangeCommunication = results[219]["Mainmenu.ScheduleChangeCommunication"];
      this.SupplierLedgerreport = results[220]["Mainmenu.SupplierLedgerreport"];
      this.Assets = results[221]["Mainmenu.Assets"];
      this.FixedAssets = results[222]["Mainmenu.FixedAssets"];
      this.TicketingthroughGDS = results[223]["Mainmenu.TicketingthroughGDS"];
      this.CustomerLedgerwithItinerary = results[224]["Mainmenu.CustomerLedgerwithItinerary"];
      this.SupplierOutstandingAsOnDateAgedReport = results[225]["Mainmenu.SupplierOutstandingAsOnDateAgedReport"];
      this.CustomerLedgerReport = results[226]["Mainmenu.CustomerLedgerReport"];
      this.CustomerOutstandingAsOnDateAgedSummaryReport = results[227]["Mainmenu.CustomerOutstandingAsOnDateAgedSummaryReport"];
      this.MasterDataList = results[228]["Mainmenu.MasterDataList"];
      this.CustomerList = results[229]["Mainmenu.CustomerList"];
      this.MarketingBookingData = results[230]["Mainmenu.MarketingBookingData"];
      this.CurrencyRevaluation = results[231]["Mainmenu.CurrencyRevaluation"];
      this.GLRevaluation = results[232]["Mainmenu.GLRevaluation"];
      this.DebtorsBalanceRevaluation = results[233]["Mainmenu.DebtorsBalanceRevaluation"];
      this.CreditorsBalanceRevaluation = results[234]["Mainmenu.CreditorsBalanceRevaluation"];
      this.CustomerAgeingWithCreditLimit = results[235]["Mainmenu.CustomerAgeingWithCreditLimit"];
      this.UserList = results[236]["Mainmenu.UserList"];
      // this.FolderSalesReceiptReport = results[236]["Mainmenu.FolderSalesReceiptReport"];
      // this.DailyAudit = results[237]["Mainmenu.DailyAudit"];
      //this.CreditCardReconciliationStatement = results[239]["Mainmenu.CreditCardReconciliationStatement"];




      this.getmenus(language);
      this.GetAccountMenu(language); //By Case id-363
      this.gettransilateheadertest();

    });
  }

  getmenus(language: any) {
    // if(language=='French')
    // {
    //   this.translate.use('fr');    ];

    // }
    // if(language=='Chinese')
    // {
    //   this.translate.use('zh');
    // }
    // this.apiShared.LanguageSelected=language;

    //this.apiShared.Setapishareddata(language,'language');

    this.menus = [
      // {
      //   menuid: "",
      //   name: this.Favorites,
      //   iconClass: "fa fa-star",
      //   active: false,
      //   id: "Favorites",
      //   submenuexists: true,
      //   routerLink: "",
      //   mainmenuid: "Mainmenu.Favorites",
      //   isFavorite: false,
      //   submenu: [],
      // },

      // {
      //   menuid: "01.23.01",
      //   name: this.CRM, //this.chnangeMenuLanguage('Mainmenu.Search',language),
      //   iconClass: "fas fa-tasks",
      //   active: true,
      //   id: "CRM",
      //   submenuexists: false,
      //   routerLink: "/PenAir/CRM/Search",
      //   mainmenuid: "Mainmenu.CRM",
      //   isFavorite: false,
      //   submenu: [
      //     // {menuid:'01.23.01', pid:'CRM',isFavorite: false, name: this.Enquiry, routerLink:'/PenAir/CRM/List',id:'Enquiry',submenuid:'Mainmenu.Enquiry', mainmenuid:'Mainmenu.CRM',submenu: []},
      //   ],
      // },
      {
        menuid: "",
        name: this.Search, //this.chnangeMenuLanguage('Mainmenu.Search',language),
        iconClass: "fa fa-search",
        active: true,
        id: "Search",
        submenuexists: false,
        routerLink: "/PenAir/Search/Folder",
        mainmenuid: "Mainmenu.Search",
        isFavorite: false,
        submenu: [
          // {menuid:'', pid:'Search',isFavorite: false, name: this.Search, routerLink:'/PenAir/Search/Folder',id:'Search',submenuid:'Mainmenu.Search', mainmenuid:'Mainmenu.Search',submenu: []},
        ],
      },

      // {
      //   menuid: "",
      //   name: this.Alerts, //this.chnangeMenuLanguage('Mainmenu.Alerts',language),
      //   iconClass: "fa fa-bell",
      //   active: false,
      //   id: "Alerts",
      //   submenuexists: true,
      //   routerLink: "/PenAir",
      //   mainmenuid: "Mainmenu.Alerts",
      //   isFavorite: false,
      //   submenu: [
      //     // {
      //     //   menuid: "",
      //     //   pid: "Alerts",
      //     //   isFavorite: false,
      //     //   name: this.Inbox,
      //     //   routerLink: "PenAir/Folder/Index",
      //     //   id: "Inbox",
      //     //   submenuexists: false,
      //     //   submenuid: "Mainmenu.Inbox",
      //     //   mainmenuid: "Mainmenu.Alerts",
      //     //   submenu: [],
      //     // },
      //   ],
      // },

      //14606 start
      // {
      //   menuid: "",
      //   name: this.DailyAudit,
      //   id: "Daily Audit",
      //   iconClass: "fas fa-pen-square",
      //   active: false,
      //   submenuexists: true,
      //   routerLink: "#",
      //   mainmenuid: "Daily Audit",
      //   isFavorite: false,
      //   submenu: [
      //     {
      //       menuid: "01.11.17",
      //       pid: "Daily Audit",
      //       isFavorite: false,
      //       name: this.FolderSalesReceiptReport,
      //       // routerLink: "/PenAir/FolderSalesReceiptReport/FolderSalesReceiptReport",
      //       routerLink: "PenAir/Reports/FolderSalesReceiptReport",
      //       id: "FolderSalesReceiptReport",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.FolderSalesReceiptReport",
      //       mainmenuid: "Mainmenu.DailyAudit",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "",
      //       pid: "DailyAudit",
      //       isFavorite: false,
      //       name: this.CreditCardReconciliationStatement,
      //       routerLink: "PenAir/Reports/CreditCardReconciliationStatement",
      //       id: "CreditCardReconciliationStatement",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.CreditCardReconciliationStatement",
      //       mainmenuid: "Mainmenu.DailyAudit",
      //       submenu: [],
      //     },

      //   ],
      // },
      //14606 End

      // {
      //   menuid: "",
      //   name: this.Folder,
      //   id: "Folder",
      //   iconClass: "fa fa-folder",
      //   active: false,
      //   submenuexists: true,
      //   routerLink: "/PenAir",
      //   mainmenuid: "Folder",
      //   isFavorite: false,
      //   submenu: [
      //     { menuid: '01.12.01.11', pid: 'Folder', isFavorite: false, name: this.CreateFolder, submenuexists: false, routerLink: 'PenAir/RetailPackageFolder', id: 'CreateFolder', submenuid: 'Mainmenu.CreateFolder', mainmenuid: 'Mainmenu.CreateFolder', submenu: [], },
      //     { menuid: '01.11.26.01', pid: 'Folder', isFavorite: false, name: this.DirectRefundFolder, submenuexists: false, routerLink: 'PenAir/Folder/FolderSummaryEdit/0', id: 'DirectRefundFolder', submenuid: 'Mainmenu.DirectRefundFolder', mainmenuid: 'Mainmenu.DirectRefundFolder', submenu: [], },
      //     {
      //       menuid: "01.11.17.07",
      //       pid: "Folder",
      //       isFavorite: false,
      //       name: this.EmailInvoice,
      //       routerLink: "PenAir/EmailInvoice/EmailInvoice",
      //       id: "EmailInvoice",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.EmailInvoice",
      //       mainmenuid: "Mainmenu.EmailInvoice",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Folder",
      //       isFavorite: false,
      //       name: this.FolderReport,
      //       routerLink: "#",
      //       id: "FolderReport",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.FolderReport",
      //       mainmenuid: "Mainmenu.Folder",
      //       submenu: [
      //         { menuid: '01.11.16.01', pid: 'FolderReport', isFavorite: false, name: this.FolderQuery, submenuexists: false, routerLink: 'PenAir/Reports/rptfolderquery', id: 'FolderQuery', submenuid: 'Mainmenu.FolderQuery', mainmenuid: 'Mainmenu.FolderReport', submenu: [], },
      //         {
      //           menuid: "01.11.02",
      //           pid: "FolderReport",
      //           isFavorite: false,
      //           name: this.FolderActivity,
      //           submenuexists: false,
      //           routerLink: "PenAir/Reports/rptfolderactivity",
      //           id: "FolderActivity",
      //           submenuid: "Mainmenu.FolderActivity",
      //           mainmenuid: "Mainmenu.FolderReport",
      //           submenu: [],
      //         },

      //         {
      //           menuid: "01.11.01.04",
      //           pid: "FolderReport",
      //           isFavorite: false,
      //           name: this.FolderActivityDetailed,
      //           submenuexists: false,
      //           routerLink: "PenAir/Reports/FolderActivityDetailedReport",
      //           id: "FolderActivityDetailed",
      //           submenuid: "Mainmenu.FolderActivityDetailed",
      //           mainmenuid: "Mainmenu.FolderReport",
      //           submenu: [],
      //         },

      //         {
      //           menuid: '01.11.01.01',
      //           pid: 'FolderReport',
      //           isFavorite: false,
      //           name: this.FolderProduct,
      //           submenuexists: false,
      //           routerLink: 'PenAir/Reports/rptfolderactivityproduct',
      //           id: 'FolderProduct',
      //           submenuid: 'Mainmenu.FolderProduct',
      //           mainmenuid: 'Mainmenu.FolderProduct',
      //           submenu: [],
      //         },


      //       ],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Folder",
      //       isFavorite: false,
      //       name: this.Airticketreports,
      //       routerLink: "#",
      //       id: "Airticketreports",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Airticketreports",
      //       mainmenuid: "Mainmenu.Folder",
      //       submenu: [

      //         // {
      //         //   menuid: "01.11.23.10.01",
      //         //   pid: "Airticketreports",
      //         //   isFavorite: false,
      //         //   name: this.TicketingthroughGDS,
      //         //   routerLink: "PenAir/Reports/TicketingthroughGDS",
      //         //   id: "TicketingthroughGDS",
      //         //   submenuexists: false,
      //         //   submenuid: "Mainmenu.TicketingthroughGDS",
      //         //   mainmenuid: "Mainmenu.Folder",
      //         //   submenu: [],
      //         // },
      //         {
      //           menuid: "01.11.03.13",
      //           pid: "Airticketreports",
      //           isFavorite: false,
      //           name: this.AirTktrptPNR,
      //           routerLink: "PenAir/Reports/AirticketReportWithPNRReport",
      //           id: "AirTktrptPNR",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.AirTktrptPNR",
      //           mainmenuid: "Mainmenu.Folder",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.03.14.01",
      //           pid: "Airticketreports",
      //           isFavorite: false,
      //           name: this.AirlineDetailedReport,
      //           routerLink: "PenAir/Reports/AirlineDetailedReport",
      //           id: "AirlineDetailedReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.AirlineDetailedReport",
      //           mainmenuid: "Mainmenu.Folder",
      //           submenu: [],
      //         },
      //       ],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Folder",
      //       isFavorite: false,
      //       name: this.Utilities,
      //       routerLink: "#",
      //       id: "Utilities",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Utilities",
      //       mainmenuid: "Mainmenu.Folder",
      //       submenu: [

      //         //  {menuid:'01.11.01.06.05',pid:'Utilities',isFavorite: false,  name: this.Delink, routerLink: 'PenAir/Utilities/Delink', id: 'Delink' ,submenuid:'Mainmenu.Delink', mainmenuid:'Mainmenu.Utilities'},

      //         {
      //           menuid: "01.11.26.05",
      //           pid: "Utilities",
      //           isFavorite: false,
      //           name: this.MessageAnalysis,
      //           routerLink: "PenAir/Utilities/MessageAnalysis",
      //           id: "MessageAnalysis",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.MessageAnalysis",
      //           mainmenuid: "Mainmenu.Utilities",
      //           submenu: [],
      //         },

      //         {
      //           menuid: "",
      //           pid: "Utilities",
      //           isFavorite: false,
      //           name: this.PurgeAlerts,
      //           routerLink: "PenAir/Utilities/PurgeAlerts",
      //           id: "PurgeAlerts",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.PurgeAlerts",
      //           mainmenuid: "Mainmenu.Utilities",
      //           submenu: [],
      //         },
      //       ],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Utilities",
      //       isFavorite: false,
      //       name: this.FolderO2C,
      //       routerLink: "#",
      //       id: "FolderO2C",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.FolderO2C",
      //       mainmenuid: "Mainmenu.Utilities",
      //       submenu: [
      //         {
      //           menuid: "FolderO2C",
      //           pid: "01.11.01.06.04.01",
      //           isFavorite: false,
      //           name: this.FolderAddExpense,
      //           routerLink: "PenAir/Utilities/FolderAddExpense",
      //           id: "FolderAddExpense",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.FolderAddExpense",
      //           mainmenuid: "Mainmenu.FolderO2C",
      //           submenu: [],
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   menuid: "",
      //   name: this.BSP,
      //   id: "BSP",
      //   iconClass: "fa fa-book",
      //   active: false,
      //   submenuexists: true,
      //   routerLink: "#",
      //   mainmenuid: "BSP",
      //   isFavorite: false,
      //   submenu: [
      //     {
      //       menuid: "01.13.02",
      //       pid: "BSP",
      //       isFavorite: false,
      //       name: this.BSPManualReconciliation,
      //       routerLink: "BSPManualReconciliation",
      //       id: "BSPManualReconciliation",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.BSPManualReconciliation",
      //       mainmenuid: "Mainmenu.BSP",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "01.11.23.09",
      //       pid: "BSP",
      //       isFavorite: false,
      //       name: this.BSPDirectExpense,
      //       routerLink: "PenAir/BSP/BSPDirectExpense",
      //       id: "BSPDirectExpense",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.BSPDirectExpense",
      //       mainmenuid: "Mainmenu.BSP",
      //       submenu: [],
      //     },

      //   ],
      // },
      // {
      //   menuid: "",
      //   name: this.Commission,
      //   id: "Commission",
      //   iconClass: "fa fa-receipt",
      //   active: false,
      //   submenuexists: true,
      //   routerLink: "/PenAir",
      //   mainmenuid: "Commission",
      //   isFavorite: false,
      //   submenu: [
      //     {
      //       menuid: "",
      //       pid: "Commission",
      //       isFavorite: false,
      //       name: this.CommissionbasedonProduct,
      //       routerLink: "#",
      //       id: "CommissionbasedonProduct",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.CommissionbasedonProduct",
      //       mainmenuid: "Mainmenu.Commission",
      //       submenu: [

      //       ],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Commission",
      //       isFavorite: false,
      //       name: this.CommissionbasedonReceipt,
      //       routerLink: "#",
      //       id: "CommissionbasedonReceipt",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.CommissionbasedonReceipt",
      //       mainmenuid: "Mainmenu.Commission",
      //       submenu: [

      //       ],
      //     },



      //   ],
      // },


      // {
      //   menuid: "",
      //   name: this.Customer,
      //   id: "Customer",
      //   iconClass: "fa fa-user",
      //   active: false,
      //   submenuexists: true,
      //   routerLink: "/PenAir",
      //   mainmenuid: "Customer",
      //   isFavorite: false,
      //   submenu: [
      //     {
      //       menuid: "01.12.01.07",
      //       pid: "Customer",
      //       isFavorite: false,
      //       name: this.CustReceipt,
      //       routerLink: "PenAir/Customer/OutsideReceipt",
      //       id: "CustReceipt",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.CustReceipt",
      //       mainmenuid: "Mainmenu.Customer",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "AutoChargingInstallments",
      //       pid: "Customer",
      //       isFavorite: false,
      //       name: this.AutoChargingInstallment,
      //       routerLink: "PenAir/Customer/AutoChargingInstallment",
      //       id: "AutoChargingInstallment",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.AutoChargingInstallment",
      //       mainmenuid: "Mainmenu.Customer",
      //       submenu: [],
      //     },
      //     // {
      //     //   menuid: "01.12.01.07",
      //     //   pid: "Customer",
      //     //   isFavorite: false,
      //     //   name: this.CustReceipt,
      //     //   routerLink: "PenAir/Customer/Receipt",
      //     //   id: "CustReceipt",
      //     //   submenuexists: false,
      //     //   submenuid: "Mainmenu.CustReceipt",
      //     //   mainmenuid: "Mainmenu.Customer",
      //     //   submenu: [],
      //     // },
      //     {
      //       menuid: "CustRcptlineitem",
      //       pid: "Customer",
      //       isFavorite: false,
      //       name: this.AutoReceipt,
      //       routerLink: "PenAir/Customer/AutoReceipt",
      //       id: "AutoReceipt",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.AutoReceipt",
      //       mainmenuid: "Mainmenu.Customer",
      //       submenu: [],
      //     },
      //     // {
      //     //   menuid: "",
      //     //   pid: "Customer",
      //     //   isFavorite: false,
      //     //   name: this.AutomaticReminder,
      //     //   routerLink: "#",
      //     //   id: "AutomaticReminder",
      //     //   submenuexists: true,
      //     //   submenuid: "Mainmenu.AutomaticReminder",
      //     //   mainmenuid: "Mainmenu.Customer",
      //     //   submenu: [
      //     // {
      //     //   menuid: "",
      //     //   pid: "AutomaticReminder",
      //     //   isFavorite: false,
      //     //   name: this.AutomaticBalanceReminder,
      //     //   routerLink: "PenAir/Customer/AutomaticBalanceReminder",
      //     //   id: "AutomaticBalanceReminder",
      //     //   submenuexists: false,
      //     //   submenuid: "Mainmenu.AutomaticBalanceReminder",
      //     //   mainmenuid: "Mainmenu.AutomaticReminder",
      //     //   submenu: [],
      //     // },
      //     //     {
      //     //       menuid: "",
      //     //       pid: "AutomaticReminder",
      //     //       isFavorite: false,
      //     //       name: this.EStatement,
      //     //       routerLink: "PenAir/Customer/EStatement",
      //     //       id: "EStatement",
      //     //       submenuexists: false,
      //     //       submenuid: "Mainmenu.EStatement",
      //     //       mainmenuid: "Mainmenu.AutomaticReminder",
      //     //       submenu: [],
      //     //     },

      //     //   ],
      //     // },

      //     {
      //       menuid: "01.11.03.13",
      //       pid: "Customer",
      //       isFavorite: false,
      //       name: this.ViewCustomer,
      //       routerLink: "PenAir/Customer/ViewCustomer",
      //       id: "ViewCustomer",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.ViewCustomer",
      //       mainmenuid: "Mainmenu.Customer",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "DataExport",
      //       pid: "Customer",
      //       isFavorite: false,
      //       name: this.ExportData,
      //       routerLink: "PenAir/Customer/ExportData",
      //       id: "ExportData",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.ExportData",
      //       mainmenuid: "Mainmenu.Customer",
      //       submenu: [],
      //     },

      //     // {
      //     //   menuid: "",
      //     //   pid: "Customer",
      //     //   isFavorite: false,
      //     //   name: this.ManualSales,
      //     //   routerLink: "PenAir/Customer/ManualSales/0/0/0",
      //     //   id: "ManualSales",
      //     //   submenuexists: true,
      //     //   submenuid: "Mainmenu.ManualSales",
      //     //   mainmenuid: "Mainmenu.Customer",

      //     //   submenu: [
      //     //     {
      //     //       menuid: "08.02",
      //     //       pid: "ManualSales",
      //     //       isFavorite: false,
      //     //       name: this.ManualSalesList,
      //     //       routerLink: "PenAir/Customer/ManualSalesList",
      //     //       id: "ManualSalesList",
      //     //       submenuexists: false,
      //     //       submenuid: "Mainmenu.ManualSalesList",
      //     //       mainmenuid: "Mainmenu.Customer",
      //     //       submenu: [],
      //     //     },
      //     //     {
      //     //       menuid: "08.02.03",
      //     //       pid: "Customer",
      //     //       isFavorite: false,
      //     //       name: this.ManualSales,
      //     //       routerLink: "PenAir/Customer/ManualSales/0/0/0/0",
      //     //       id: "ManualSales",
      //     //       submenuexists: false,
      //     //       submenuid: "Mainmenu.ManualSales",
      //     //       mainmenuid: "Mainmenu.Customer",

      //     //       submenu: [],
      //     //     }
      //     //     ,

      //     //     {
      //     //       menuid: "08.06.02",
      //     //       pid: "Reports",
      //     //       isFavorite: false,
      //     //       name: this.ManualSalesReturnList,
      //     //       routerLink: "PenAir/Customer/ManualSalesReturnList",
      //     //       id: "ManualSalesReturnList",
      //     //       submenuexists: false,
      //     //       submenuid: "Mainmenu.ManualSalesReturnList",
      //     //       mainmenuid: "Mainmenu.Customer",
      //     //       submenu: [],
      //     //     },
      //     //     {
      //     //       menuid: "08.06.02.03",
      //     //       pid: "Customer",
      //     //       isFavorite: false,
      //     //       name: this.ManualSalesReturn,
      //     //       routerLink: "PenAir/Customer/ManualSalesReturn/0/0/0/0",
      //     //       id: "ManualSales",
      //     //       submenuexists: false,
      //     //       submenuid: "Mainmenu.ManualSalesReturn",
      //     //       mainmenuid: "Mainmenu.Customer",

      //     //       submenu: [],
      //     //     }
      //     //   ]
      //     // },
      //     {
      //       menuid: "",
      //       pid: "Customer",
      //       isFavorite: false,
      //       name: this.MasterDataList,
      //       routerLink: "#",
      //       id: "MasterDataList",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.MasterDataList",
      //       mainmenuid: "Mainmenu.Customer",
      //       submenu: [
      //         {
      //           menuid: "01.11.05.07.04",
      //           pid: "MasterDataList",
      //           isFavorite: false,
      //           name: this.CustomerList,
      //           routerLink:
      //             "PenAir/CustomerList",
      //           id: "CustomerList",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerList",
      //           mainmenuid: "Mainmenu.MasterDataList",
      //           submenu: [],
      //         },
      //         // {
      //         //   menuid: "",
      //         //   pid: "",
      //         //   isFavorite: false,
      //         //   name: this.MarketingBookingData,
      //         //   routerLink:
      //         //     "",
      //         //   id: "MarketingBookingData",
      //         //   submenuexists: false,
      //         //   submenuid: "Mainmenu.MarketingBookingData",
      //         //   mainmenuid: "Mainmenu.MasterDataList",
      //         //   submenu: [],
      //         // },
      //       ],
      //     },




      //     {
      //       menuid: "",
      //       pid: "Customer",
      //       isFavorite: false,
      //       name: this.Corporate,
      //       routerLink: "#",
      //       id: "Corporate",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Corporate",
      //       mainmenuid: "Mainmenu.Customer",
      //       submenu: [
      //         {
      //           menuid: "01.11.05.25.01",
      //           pid: "Corporate",
      //           isFavorite: false,
      //           name: this.ViewConsolidatedInvoice,
      //           routerLink:
      //             "PenAir/ConsolidatedInvoice/ViewConsolidatedInvoice",
      //           id: "ConsolidatedInvoice",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ViewConsolidatedInvoice",
      //           mainmenuid: "Mainmenu.Corporate",
      //           submenu: [],
      //         },
      //       ],
      //     },



      //     {
      //       menuid: "",
      //       pid: "Customer",
      //       isFavorite: false,
      //       name: this.CRM,
      //       routerLink: "#",
      //       id: "CRM",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.CRM",
      //       mainmenuid: "Mainmenu.Customer",
      //       submenu: [
      //         // {
      //         //   menuid: "",
      //         //   pid: "CRM",
      //         //   isFavorite: false,
      //         //   name: this.ScheduleChangeCommunication,
      //         //   routerLink:
      //         //     "PenAir/Customer/ScheduleChangeCommunication",
      //         //   id: "ScheduleChangeCommunication",
      //         //   submenuexists: false,
      //         //   submenuid: "Mainmenu.ScheduleChangeCommunication",
      //         //   mainmenuid: "Mainmenu.CRM",
      //         //   submenu: [],
      //         // },
      //       ],
      //     },



      //     {
      //       menuid: "",
      //       pid: "Customer",
      //       isFavorite: false,
      //       name: this.Invoice,
      //       routerLink: "PenAir/Customer/ManualSales/0/0/0",
      //       id: "Invoices",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Invoices",
      //       mainmenuid: "Mainmenu.Customer",

      //       submenu: [
      //         {
      //           menuid: "SalesSearch",
      //           pid: "Invoices",
      //           isFavorite: false,
      //           name: this.ManualSales,
      //           routerLink: "PenAir/Customer/ManualSalesSearch",
      //           id: "Sales",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ManualSales",
      //           mainmenuid: "Mainmenu.Customer",
      //           submenu: [],
      //         },
      //         // {
      //         //   menuid: "08.02.03",
      //         //   pid: "Customer",
      //         //   isFavorite: false,
      //         //   name: this.ManualSales,
      //         //   routerLink: "PenAir/Customer/ManualSales/0/0/0/0",
      //         //   id: "ManualSales",
      //         //   submenuexists: false,
      //         //   submenuid: "Mainmenu.ManualSales",
      //         //   mainmenuid: "Mainmenu.Customer",

      //         //   submenu: [],
      //         // }
      //         // ,

      //         {
      //           menuid: "CreditNoteSearch",
      //           pid: "Invoices",
      //           isFavorite: false,
      //           name: this.ManualCreditNotes,
      //           routerLink: "PenAir/Customer/ManualSalesCreditnoteSearch",
      //           id: "CreditNotes",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ManualCreditNotes",
      //           mainmenuid: "Mainmenu.Customer",
      //           submenu: [],
      //         },
      //         // {
      //         //   menuid: "08.06.02.03",
      //         //   pid: "Customer",
      //         //   isFavorite: false,
      //         //   name: this.ManualSalesReturn,
      //         //   routerLink: "PenAir/Customer/ManualSalesReturn/0/0/0/0",
      //         //   id: "ManualSales",
      //         //   submenuexists: false,
      //         //   submenuid: "Mainmenu.ManualSalesReturn",
      //         //   mainmenuid: "Mainmenu.Customer",

      //         //   submenu: [],
      //         // }
      //       ]
      //     },

      //     {
      //       menuid: "",
      //       pid: "Customer",
      //       isFavorite: false,
      //       name: this.Reports,
      //       routerLink: "#",
      //       id: "Reports",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Reports",
      //       mainmenuid: "Mainmenu.Customer",
      //       submenu: [
      //         {
      //           menuid: "01.11.18.07",
      //           pid: "Reports",
      //           isFavorite: false,
      //           name: this.SalesListing,
      //           routerLink: "PenAir/Reports/CorporateInvoiceList",
      //           id: "SalesListing",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.SalesListing",
      //           mainmenuid: "Mainmenu.Customer",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.17.08",
      //           pid: "Reports",
      //           isFavorite: false,
      //           name: this.CustomerReceiptsAndPaymentRpt,
      //           routerLink: "PenAir/Reports/CustomerReceipt",
      //           id: "CustomerReceiptsAndPaymentRpt",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerReceiptsAndPaymentRpt",
      //           mainmenuid: "Mainmenu.Customer",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.05.01",
      //           pid: "Reports",
      //           isFavorite: false,
      //           name: this.CustomerStatement,
      //           routerLink: "PenAir/Reports/CustomerStatement",
      //           id: "CustomerStatement",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerStatement",
      //           mainmenuid: "Mainmenu.Customer",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "CustStmtlineItem",
      //           pid: "Reports",
      //           isFavorite: false,
      //           name: this.CustomerStatementbylineitem,
      //           routerLink: "PenAir/Reports/CustomerStatementbylineitem",
      //           id: "CustomerStatementbylineitem",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerStatementbylineitem",
      //           mainmenuid: "Mainmenu.Customer",
      //           submenu: [],
      //         },

      //         {
      //           menuid: "01.11.05.12",
      //           pid: "customer",
      //           isFavorite: false,
      //           name: this.CustomerAllocation,
      //           routerLink: "PenAir/Reports/CustomerAllocation",
      //           id: "CustomerAllocation",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerAllocation",
      //           mainmenuid: "Mainmenu.Customer",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "Folder_Installment_Report",
      //           pid: "Reports",
      //           isFavorite: false,
      //           name: this.FolderInstallmentReport,
      //           routerLink: "PenAir/Reports/FolderInstallmentReport",
      //           id: "FolderInstallmentReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.FolderInstallmentReport",
      //           mainmenuid: "Mainmenu.Customer",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.05.15",
      //           pid: "Reports",
      //           isFavorite: false,
      //           name: this.ForeignCurrencyCustomerStatment,
      //           routerLink: "PenAir/Reports/ForeignCurrencyCustomerStatment",
      //           id: "ForeignCurrencyCustomerStatment",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ForeignCurrencyCustomerStatment",
      //           mainmenuid: "Mainmenu.Customer",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.05.01",
      //           pid: "Reports",
      //           isFavorite: false,
      //           name: this.CustomerAgeingWithCreditLimit,
      //           routerLink: "PenAir/Reports/CustomerAgeingWithCreditLimit",
      //           id: "CustomerAgeingWithCreditLimit",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerAgeingWithCreditLimit",
      //           mainmenuid: "Mainmenu.Customer",
      //           submenu: [],
      //         },
      //       ],
      //     },

      //   ],
      // },
      // {
      //   menuid: "",
      //   name: this.Supplier,
      //   iconClass: "fa fa-handshake",
      //   active: false,
      //   id: "Supplier",
      //   submenuexists: true,
      //   routerLink: "/PenAir",
      //   mainmenuid: "Supplier",
      //   isFavorite: false,
      //   submenu: [

      //     // {
      //     //   menuid: "",
      //     //   pid: "Supplier",
      //     //   isFavorite: false,
      //     //   name: this.AutomaticPO,
      //     //   routerLink: "PenAir/Supplier/AutomaticPO",
      //     //   id: "AutomaticPO",
      //     //   submenuexists: false,
      //     //   submenuid: "Mainmenu.AutomaticPO",
      //     //   mainmenuid: "Mainmenu.Supplier",
      //     //   submenu: [],
      //     // },


      //     // {
      //     //   menuid: "03.06.02",
      //     //   pid: "Supplier",
      //     //   isFavorite: false,
      //     //   name: this.Payment,
      //     //   routerLink: "PenAir/Supplier/Payment",
      //     //   id: "Payment",
      //     //   submenuexists: false,
      //     //   submenuid: "Mainmenu.Payment",
      //     //   mainmenuid: "Mainmenu.Supplier",
      //     //   submenu: [],
      //     // },
      //     {
      //       menuid: "03.06.02",
      //       pid: "Supplier",
      //       isFavorite: false,
      //       name: this.Payment,
      //       routerLink: "PenAir/Supplier/Payment",
      //       id: "Payment",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.Payment",
      //       mainmenuid: "Mainmenu.Supplier",
      //       submenu: [],
      //     },

      //     // {
      //     //   menuid: "",
      //     //   pid: "Supplier",
      //     //   isFavorite: false,
      //     //   name: this.SupplierAllocation,
      //     //   routerLink: "PenAir/Reports/SupplierAllocation",
      //     //   id: "SupplierAllocation",
      //     //   submenuexists: false,
      //     //   submenuid: "Mainmenu.SupplierAllocation",
      //     //   mainmenuid: "Mainmenu.Supplier",
      //     //   submenu: [],
      //     // },
      //     {
      //       menuid: "01.11.06.09",
      //       pid: "Supplier",
      //       isFavorite: false,
      //       name: this.SuppierAllocationReport,
      //       routerLink: "PenAir/Reports/SuppierAllocationReport",
      //       id: "SuppierAllocationReport",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.SuppierAllocationReport",
      //       mainmenuid: "Mainmenu.Supplier",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "01.11.06.14",
      //       pid: "Supplier",
      //       isFavorite: false,
      //       name: this.ForeignCurrencySupplierStatement,
      //       routerLink: "PenAir/Reports/ForeignCurrencySupplierStatement",
      //       id: "ForeignCurrencySupplierStatement",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.ForeignCurrencySupplierStatement",
      //       mainmenuid: "Mainmenu.Supplier",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "01.11.06.01",
      //       pid: "Supplier",
      //       isFavorite: false,
      //       name: this.SuppierStatementrpt,
      //       routerLink: "PenAir/Reports/rptsupplierstatement",
      //       id: "SuppierStatementrpt",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.SuppierStatementrpt",
      //       mainmenuid: "Mainmenu.Customer",
      //       submenu: [],
      //     },

      //     {
      //       menuid: "03.02.01",
      //       pid: "Supplier",
      //       isFavorite: false,
      //       name: this.ViewSupplier,
      //       routerLink: "PenAir/Supplier/ViewSupplier",
      //       id: "ViewSupplier",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.ViewSupplier",
      //       mainmenuid: "Mainmenu.Supplier",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "AutoSuppReconcilebyPR",
      //       pid: "Supplier",
      //       isFavorite: false,
      //       name: this.supplierReconciliation,
      //       routerLink: "PenAir/Supplier/supplierReconciliation",
      //       id: "supplierReconciliation",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.supplierReconciliation",
      //       mainmenuid: "Mainmenu.Supplier",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "01.11.06.06",
      //       pid: "Supplier",
      //       isFavorite: false,
      //       name: this.SupplierRemittanceAdvice,
      //       routerLink: "PenAir/Supplier/SupplierRemittanceAdvice",
      //       id: "SupplierRemittanceAdvice",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.SupplierRemittanceAdvice",
      //       mainmenuid: "Mainmenu.Supplier",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Supplier",
      //       isFavorite: false,
      //       name: this.Invoice,
      //       routerLink: "#",
      //       id: "Invoice",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Invoice",
      //       mainmenuid: "Mainmenu.Supplier",
      //       submenu: [
      //         {
      //           menuid: "08.05",
      //           pid: "Invoice",
      //           isFavorite: false,
      //           name: this.ExpenseInvoice,
      //           routerLink: "PenAir/Supplier/ExpenseInvoiceSearch",
      //           id: "ExpenseInvoice",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ExpenseInvoice",
      //           mainmenuid: "Mainmenu.Invoice",
      //           submenu: [],
      //         }
      //       ],

      //     },
      //   ],
      // },
      // {
      //   menuid: "",
      //   name: this.GroupBooking,
      //   id: "GroupBooking",
      //   iconClass: "fa fa-desktop",
      //   active: false,
      //   submenuexists: true,
      //   routerLink: "#",
      //   mainmenuid: "GroupBooking",
      //   isFavorite: false,
      //   submenu: [
      //     {
      //       menuid: "PassengerBookingDetails",
      //       pid: "GroupBooking",
      //       isFavorite: false,
      //       name: this.PassengerBookingDetails,
      //       routerLink: "PenAir/PassengerBookingDetailsReport",
      //       id: "FlightBookingDetails",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.FlightBookingDetails",
      //       mainmenuid: "Mainmenu.GroupBooking",
      //       submenu: [

      //       ],
      //     },
      //   ],
      // },

      // {
      //   menuid: "",
      //   name: this.StatutoryReport,
      //   id: "StatutoryReport",
      //   iconClass: "fa fa-flag",
      //   active: false,
      //   submenuexists: true,
      //   routerLink: "/PenAir",
      //   mainmenuid: "StatutoryReport",
      //   isFavorite: false,
      //   submenu: [

      //     {
      //       menuid: "",
      //       pid: "StatutoryReport",
      //       isFavorite: false,
      //       name: this.Canada,
      //       routerLink: "#",
      //       id: "Canada",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Canada",
      //       mainmenuid: "Mainmenu.StatutoryReport",
      //       submenu: [
      //         {
      //           menuid: "Quebec_OPC_FICAV_Fees_Report",
      //           pid: "Canada",
      //           isFavorite: false,
      //           name: this.QuebecOPCFICAVFeesReport,
      //           routerLink: "PenAir/Reports/QuebecOPCFICAVFeesReport",
      //           id: "QuebecOPCFICAVFeesReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.QuebecOPCFICAVFeesReport",
      //           mainmenuid: "Mainmenu.Canada",
      //           submenu: [],
      //         },
      //       ],
      //     },
      //     // {
      //     //   menuid: "",
      //     //   pid: "StatutoryReport",
      //     //   isFavorite: false,
      //     //   name: this.UK,
      //     //   routerLink: "#",
      //     //   id: "uk",
      //     //   submenuexists: true,
      //     //   submenuid: "Mainmenu.uk",
      //     //   mainmenuid: "Mainmenu.StatutoryReport",
      //     //   submenu: [
      //     //     {
      //     //       menuid: "",
      //     //       pid: "uk",
      //     //       isFavorite: false,
      //     //       name: this.ATOL,
      //     //       routerLink: "#",
      //     //       id: "ATOL",
      //     //       submenuexists: true,
      //     //       submenuid: "Mainmenu.ATOL",
      //     //       mainmenuid: "Mainmenu.uk",
      //     //       submenu: [
      //     //         {
      //     //           menuid: "ATOL",
      //     //           pid: "",
      //     //           isFavorite: false,
      //     //           name: this.AtolReportFiling,
      //     //           routerLink: "PenAir/Reports/AtolReportFiling",
      //     //           id: "AtolReportFiling",
      //     //           submenuexists: false,
      //     //           submenuid: "Mainmenu.AtolReportFiling",
      //     //           mainmenuid: "Mainmenu.ATOL",
      //     //           submenu: [],
      //     //         },


      //     //       ]
      //     //     },
      //     //   ]
      //     // },

      //   ],
      // },

      // {
      //   menuid: "",
      //   name: this.GiftcardOrVoucher,
      //   iconClass: "fa fa-users",
      //   active: false,
      //   id: "GiftcardOrVoucher",
      //   submenuexists: true,
      //   routerLink: "/PenAir",
      //   mainmenuid: "Mainmenu.GiftcardOrVoucher",
      //   isFavorite: false,
      //   submenu: [

      //     {
      //       menuid: "01.11.32.01.21",
      //       pid: "GiftCardMaster",
      //       isFavorite: false,
      //       name: this.GiftCardMaster,
      //       routerLink: "PenAir/GiftCard/GiftCardMaster",
      //       id: "GiftCardMaster",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.GiftCardMaster",
      //       mainmenuid: "Mainmenu.GiftcardOrVoucher",
      //       submenu: [],
      //     },
      //     // {
      //     //   menuid: "08.12",
      //     //   pid: "GiftCardVoucherIssue",
      //     //   isFavorite: false,
      //     //   name: this.GiftCardVoucherIssue,
      //     //   routerLink: "PenAir/GiftCard/GiftCardVoucherIssue",
      //     //   id: "GiftCardVoucherIssue",
      //     //   submenuexists: false,
      //     //   submenuid: "Mainmenu.GiftCardVoucherIssue",
      //     //   mainmenuid: "Mainmenu.GiftcardOrVoucher",
      //     //   submenu: [],
      //     // },
      //     {
      //       menuid: "01.11.05.23.01",
      //       pid: "VoucherMngt",
      //       isFavorite: false,
      //       name: this.VoucherMngt,
      //       routerLink: "PenAir/GiftCard/Voucher",
      //       id: "GiftcardOrVoucher",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.GiftcardOrVoucher",
      //       mainmenuid: "Mainmenu.GiftcardOrVoucher",
      //       submenu: [],
      //     },
      //   ],
      // },
      // {
      //   menuid: "",
      //   name: this.RewardPoint,
      //   id: "RewardPoint",
      //   iconClass: "fa fa-check-circle",
      //   active: false,
      //   submenuexists: true,
      //   routerLink: "/PenAir",
      //   mainmenuid: "RewardPoint",
      //   isFavorite: false,
      //   submenu: [
      //     {
      //       menuid: "POINTSRECON",
      //       pid: "Bank",
      //       isFavorite: false,
      //       name: this.RewardPointsReconciliation,
      //       routerLink: "PenAir/Bank/RewardPointsReconciliation",
      //       id: "RewardPointsReconciliation",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.RewardPointsReconciliation",
      //       mainmenuid: "Mainmenu.Bank",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "ReplenishmentReport",
      //       pid: "ReplenishmentReport",
      //       isFavorite: false,
      //       name: this.ReplenishmentReport,
      //       routerLink: "PenAir/Reports/ReplenishmentReport",
      //       id: "ReplenishmentReport",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.ReplenishmentReport",
      //       mainmenuid: "Mainmenu.ReplenishmentReport",
      //       submenu: [],

      //     },
      //   ],
      // },
      // {
      //   menuid: "",
      //   name: this.Accounts,
      //   id: "Accounts",
      //   iconClass: "fa fa-university",
      //   active: false,
      //   submenuexists: true,
      //   routerLink: "/PenAir",
      //   mainmenuid: "Accounts",
      //   isFavorite: false,
      //   submenu: [
      //     {
      //       menuid: "",
      //       pid: "Accounts",
      //       isFavorite: false,
      //       name: this.Financialstatements,
      //       routerLink: "#",
      //       id: "Financialstatements",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Financialstatements",
      //       mainmenuid: "Mainmenu.Accounts",
      //       submenu: [
      //         // {
      //         //   menuid: "01.11.17.02",
      //         //   pid: "Financialstatements",
      //         //   isFavorite: false,
      //         //   name: this.PurchaseListing,
      //         //    routerLink: "PenAir/Reports/PurchaseListing",
      //         //   id: "PurchaseListing",
      //         //   submenuexists: false,
      //         //   submenuid: "Mainmenu.PurchaseListing",
      //         //   mainmenuid: "Mainmenu.Financialstatements",
      //         //   submenu: [],
      //         // },
      //         {
      //           menuid: "01.11.17.02",
      //           pid: "Financialstatements",
      //           isFavorite: false,
      //           name: this.PurchaseCommitmentReport,
      //           routerLink: "PenAir/Reports/PurchaseListingCommitmentReport",
      //           id: "PurchaseListingCommitmentReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.PurchaseListingCommitmentReport",
      //           mainmenuid: "Mainmenu.PurchaseListingCommitmentReport",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.30.01",
      //           pid: "Financialstatements",
      //           isFavorite: false,
      //           name: this.TrialBalance,
      //           routerLink: "PenAir/Reports/TrialBalance",
      //           id: "TrialBalanceReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.TrialBalance",
      //           mainmenuid: "Mainmenu.Financialstatements",
      //           submenu: [],
      //         },
      //         //  {menuid:'01.11.14.01',pid:'Financialstatements',isFavorite: false, name: this.PandLrpt, routerLink: 'PenAir/Reports/rptpandl',id:'PandLrpt',submenuid:'Mainmenu.PandLrpt', mainmenuid:'Mainmenu.Financialstatements'}

      //         {
      //           menuid: "01.11.14.01",
      //           pid: "Financialstatements",
      //           isFavorite: false,
      //           name: this.PandLrpt,
      //           routerLink: "PenAir/Reports/rptpandl",
      //           id: "PandLrpt",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.PandLrpt",
      //           mainmenuid: "Mainmenu.Financialstatements",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "13.11.01.01"
      //           ,
      //           pid: "Financialstatements",
      //           isFavorite: false,
      //           name: this.TrialBalancewithCostCenter,
      //           routerLink: "PenAir/Reports/TrialBalancewithCostCenter",
      //           id: "TrialBalancewithCostCenter",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.TrialBalancewithCostCenterReport",
      //           mainmenuid: "Mainmenu.Financialstatements",
      //           submenu: [],
      //         },],
      //     },

      //     {
      //       menuid: "",
      //       pid: "Accounts",
      //       isFavorite: false,
      //       name: this.UAE,
      //       routerLink: "#",
      //       id: "UAE",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.UAE",
      //       mainmenuid: "Mainmenu.Accounts",
      //       submenu: [
      //         {
      //           menuid: "VAT201VATReturns",
      //           pid: "UAE",
      //           isFavorite: false,
      //           name: this.VAT201VATReturns,
      //           routerLink: "PenAir/UAE/VAT201VATReturns",
      //           id: "VAT201VATReturns",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.VAT201VATReturns",
      //           mainmenuid: "Mainmenu.UAE",
      //           submenu: [

      //           ],
      //         },

      //       ],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Accounts",
      //       isFavorite: false,
      //       name: this.TaxReports,
      //       routerLink: "#",
      //       id: "TaxReports",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.TaxReports",
      //       mainmenuid: "Mainmenu.Accounts",
      //       submenu: [
      //         {
      //           menuid: "",
      //           pid: "TaxReports",
      //           isFavorite: false,
      //           name: this.General,
      //           routerLink: "#",
      //           id: "General",
      //           submenuexists: true,
      //           submenuid: "Mainmenu.General",
      //           mainmenuid: "Mainmenu.TaxReports",
      //           submenu: [
      //             {
      //               menuid: "01.11.30.05.01",
      //               pid: "General",
      //               isFavorite: false,
      //               name: this.TaxCalculation,
      //               routerLink: "PenAir/Reports/TaxCalculation",
      //               id: "TaxCalculation",
      //               submenuexists: false,
      //               submenuid: "Mainmenu.TaxCalculation",
      //               mainmenuid: "Mainmenu.General",
      //               submenu: [],
      //             },
      //           ],
      //         },

      //       ],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Accounts",
      //       isFavorite: false,
      //       name: this.Bank,
      //       routerLink: "#",
      //       id: "Bank",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Bank",
      //       mainmenuid: "Mainmenu.Accounts",
      //       submenu: [

      //         {
      //           menuid: "",
      //           pid: "Bank",
      //           isFavorite: false,
      //           name: this.BacsReconciliation,
      //           routerLink: "PenAir/Bank/BacsReconciliation",
      //           id: "BacsReconciliation",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.BacsReconciliation",
      //           mainmenuid: "Mainmenu.Bank",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "05.05.03",
      //           pid: "Bank",
      //           isFavorite: false,
      //           name: this.BanktoBankTransfer,
      //           routerLink: "PenAir/Reports/BanktoBankTransfer",
      //           id: "BanktoBankTransfer",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.BanktoBankTransfer",
      //           mainmenuid: "Mainmenu.Bank",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.30.04",
      //           pid: "Bank",
      //           isFavorite: false,
      //           name: this.BankStatement,
      //           routerLink: "PenAir/Reports/BankLedger",
      //           id: "BankStatement",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.BankStatement",
      //           mainmenuid: "Mainmenu.Bank",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "05.05.01.01",
      //           pid: "Bank",
      //           isFavorite: false,
      //           name: this.BankPayment,
      //           routerLink: "PenAir/Bank/BankPayment",
      //           id: "BankPayment",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.BankPayment",
      //           mainmenuid: "Mainmenu.Bank",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "CompanyCardPayment",
      //           pid: "Bank",
      //           isFavorite: false,
      //           name: this.CompanyCardPayment,
      //           routerLink: "PenAir/Bank/CompanycardPayment",
      //           id: "CompanycardPayment",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CompanycardPayment",
      //           mainmenuid: "Mainmenu.Bank",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "05.05.02.01",
      //           pid: "Bank",
      //           isFavorite: false,
      //           name: this.BankReceipt,
      //           routerLink: "PenAir/Bank/BankReceipt",
      //           id: "BankReceipt",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.BankReceipt",
      //           mainmenuid: "Mainmenu.Bank",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "05.14",
      //           pid: "Bank",
      //           isFavorite: false,
      //           name: this.BankReconcilation,
      //           routerLink: "Reconcilation/BankReconcilation",
      //           id: "BankReconcilation",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.BankReconcilation",
      //           mainmenuid: "Mainmenu.Bank",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "05.08.01",
      //           pid: "Bank",
      //           isFavorite: false,
      //           name: this.BankPayInSlip,
      //           routerLink: "PenAir/BankPayInSlip",
      //           id: "BankPayInSlip",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.BankPayInSlip",
      //           mainmenuid: "Mainmenu.Bank",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "05.07.03.01",
      //           pid: "Bank",
      //           isFavorite: false,
      //           name: this.ManualPDQReconciliation,
      //           routerLink: "ManualPDQReconciliation",
      //           id: "ManualPDQReconciliation",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ManualPDQReconciliation",
      //           mainmenuid: "Mainmenu.Bank",
      //           submenu: [],
      //         },

      //         {
      //           menuid: "05.11",
      //           pid: "Bank",
      //           isFavorite: false,
      //           name: this.AutomaticCreditCardRecon,
      //           routerLink: "PenAir/Bank/AutomaticCreditCardRecon",
      //           id: "AutomaticCreditCardRecon",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.AutomaticCreditCardRecon",
      //           mainmenuid: "Mainmenu.Bank",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "05.10.01.01",
      //           pid: "Bank",
      //           isFavorite: false,
      //           name: this.AutomaticCompanyCardRecon,
      //           routerLink: "PenAir/Bank/AutomaticCompanyCardRecon",
      //           id: "AutomaticCompanyCardRecon",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.AutomaticCompanyCardRecon",
      //           mainmenuid: "Mainmenu.Bank",
      //           submenu: [],
      //         },
      //         // {
      //         //   menuid: "05.10.01.01",
      //         //   pid: "Bank",
      //         //   isFavorite: false,
      //         //   name: this.ManualCompanyCardRec,
      //         //   routerLink: "PenAir/Bank/ManualCompanyCardRec",
      //         //   id: "ManualCompanyCardRec",
      //         //   submenuexists: false,
      //         //   submenuid: "Mainmenu.ManualCompanyCardRec",
      //         //   mainmenuid: "Mainmenu.Bank",
      //         //   submenu: [],
      //         // },
      //         {
      //           menuid: "05",
      //           pid: "Bank",
      //           isFavorite: false,
      //           name: this.BankMaster,
      //           routerLink: "PenAir/Bank/BankMaster",
      //           id: "BankMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.BankMaster",
      //           mainmenuid: "Mainmenu.Bank",
      //           submenu: [],
      //         },
      //       ],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Accounts",
      //       isFavorite: false,
      //       name: this.Journal,
      //       routerLink: "#",
      //       id: "Journal",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Journal",
      //       mainmenuid: "Mainmenu.Accounts",
      //       submenu: [
      //         {
      //           menuid: "01.11.17.06",
      //           pid: "Journal",
      //           isFavorite: false,
      //           name: this.DailyJournalAuditReport,
      //           routerLink: "PenAir/journal/DailyJournalAuditReport",
      //           id: "DailyJournalAuditReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.DailyJournalAuditReport",
      //           mainmenuid: "Mainmenu.Journal",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "04.05",
      //           pid: "Journal",
      //           isFavorite: false,
      //           name: this.JournalTransaction,
      //           routerLink: "PenAir/journal/JournalTransaction",
      //           // id: "Nominal",
      //           id: "JournalTransaction",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.JournalTransaction",
      //           mainmenuid: "Mainmenu.Journal",
      //           submenu: [],
      //         },
      //       ],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Accounts",
      //       isFavorite: false,
      //       name: this.PettyCash,
      //       routerLink: "#",
      //       id: "PettyCash",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.PettyCash",
      //       mainmenuid: "Mainmenu.Accounts",
      //       submenu: [
      //         {
      //           menuid: "06.01",
      //           pid: "PettyCash",
      //           isFavorite: false,
      //           name: this.PettyCashPayment,
      //           routerLink: "PenAir/CashDetails/PettyCash",
      //           id: "PettyCashPayment",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.PettyCashPayment",
      //           mainmenuid: "Mainmenu.PettyCash",
      //           submenu: [],
      //         },
      //         // {menuid:'06.01',pid:'CashDetails',isFavorite: false, name: this.CashPayment, routerLink: 'CashDetails/CashPayment' ,id:'CashPayment',submenuid:'Mainmenu.CashPayment', mainmenuid:'Mainmenu.PettyCash' },
      //         {
      //           menuid: "01.11.13",
      //           pid: "PettyCash",
      //           isFavorite: false,
      //           name: this.PettyCashReport,
      //           routerLink: "PenAir/Reports/rptpettycashreport",
      //           id: "PettyCashReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.PettyCashReport",
      //           mainmenuid: "Mainmenu.PettyCash",
      //           submenu: [],
      //         },
      //       ],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Accounts",
      //       isFavorite: false,
      //       name: this.CashDetails,
      //       routerLink: "#",
      //       id: "CashDetails",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.CashDetails",
      //       mainmenuid: "Mainmenu.Accounts",
      //       submenu: [
      //         {
      //           menuid: "06.03",
      //           pid: "CashDetails",
      //           isFavorite: false,
      //           name: this.CashPayment,
      //           routerLink: "PenAir/CashDetails/CashPayment",
      //           id: "CashPayment",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CashPayment",
      //           mainmenuid: "Mainmenu.PettyCash",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "06.02",
      //           pid: "CashDetails",
      //           isFavorite: false,
      //           name: this.CashReceipt,
      //           routerLink: "PenAir/CashDetails/CashReceipt",
      //           id: "CashReceipt",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CashReceipt",
      //           mainmenuid: "Mainmenu.PettyCash",
      //           submenu: [],
      //         },
      //       ],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Accounts",
      //       isFavorite: false,
      //       name: this.CurrencyRevaluation,
      //       routerLink: "#",
      //       id: "CurrencyRevaluation",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.CurrencyRevaluation",
      //       mainmenuid: "Mainmenu.Accounts",
      //       submenu: [
      //         // {
      //         //   menuid: "",
      //         //   pid: "CurrencyRevaluation",
      //         //   isFavorite: false,
      //         //   name: this.GLRevaluation,
      //         //   routerLink: "PenAir/CurrencyRevaluation/GLRevaluation",
      //         //   id: "GLRevaluation",
      //         //   submenuexists: false,
      //         //   submenuid: "Mainmenu.GLRevaluation",
      //         //   mainmenuid: "Mainmenu.CurrencyRevaluation",
      //         //   submenu: [],
      //         // },
      //         // {
      //         //   menuid: "",
      //         //   pid: "CurrencyRevaluation",
      //         //   isFavorite: false,
      //         //   name: this.DebtorsBalanceRevaluation,
      //         //   routerLink: "PenAir/CurrencyRevaluation/DebtorsBalanceRevaluation",
      //         //   id: "DebtorsBalanceRevaluation",
      //         //   submenuexists: false,
      //         //   submenuid: "Mainmenu.DebtorsBalanceRevaluation",
      //         //   mainmenuid: "Mainmenu.CurrencyRevaluation",
      //         //   submenu: [],
      //         // },
      //         // {
      //         //   menuid: "",
      //         //   pid: "CurrencyRevaluation",
      //         //   isFavorite: false,
      //         //   name: this.CreditorsBalanceRevaluation,
      //         //   routerLink: "PenAir/CurrencyRevaluation/CreditorsBalanceRevaluation",
      //         //   id: "CreditorsBalanceRevaluation",
      //         //   submenuexists: false,
      //         //   submenuid: "Mainmenu.CreditorsBalanceRevaluation",
      //         //   mainmenuid: "Mainmenu.CurrencyRevaluation",
      //         //   submenu: [],
      //         // },

      //       ],
      //     },

      //     {
      //       menuid: "",
      //       pid: "Accounts",
      //       isFavorite: false,
      //       name: this.GeneralLedger,
      //       routerLink: "#",
      //       id: "GeneralLedger",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.GeneralLedger",
      //       mainmenuid: "Mainmenu.Accounts",
      //       submenu: [
      //         {
      //           menuid: "01.11.30.03",
      //           pid: "Accounts",
      //           isFavorite: false,
      //           name: this.GeneralLedgerReport,
      //           routerLink: "PenAir/Reports/GeneralLedger",
      //           id: "GeneralLedgerReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.GeneralLedgerReport",
      //           mainmenuid: "Mainmenu.GeneralLedger",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "",
      //           pid: "Accounts",
      //           isFavorite: false,
      //           name: this.Chartofaccounts,
      //           routerLink: "#",
      //           id: "Chartofaccounts",
      //           submenuexists: true,
      //           submenuid: "Mainmenu.Chartofaccounts",
      //           mainmenuid: "Mainmenu.Accounts",
      //           submenu: [
      //             {
      //               menuid: "04.02.03",
      //               pid: "Chartofaccounts",
      //               isFavorite: false,
      //               name: this.ViewCOA,
      //               routerLink: "PenAir/Nominal/ViewNominal",
      //               // id: "Nominal",
      //               id: "ViewCOA",
      //               submenuexists: false,
      //               submenuid: "Mainmenu.ViewCOA",
      //               mainmenuid: "Mainmenu.Chartofaccounts",
      //               submenu: [],
      //             },
      //           ],
      //         },
      //         {
      //           menuid: "",
      //           pid: "Accounts",
      //           isFavorite: false,
      //           name: this.AuditQuery,
      //           routerLink: "#",
      //           id: "AuditQuery",
      //           submenuexists: true,
      //           submenuid: "Mainmenu.AuditQuery",
      //           mainmenuid: "Mainmenu.Accounts",
      //           submenu: [
      //             {
      //               menuid: "01.11.01.07",
      //               pid: "AuditQuery",
      //               isFavorite: false,
      //               name: this.AuditReport,
      //               routerLink: "/PenAir/Reports/AuditReport",
      //               id: "AuditReport",
      //               submenuexists: false,
      //               submenuid: "Mainmenu.AuditReport",
      //               mainmenuid: "Mainmenu.AuditQuery",
      //               submenu: [],
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Accounts",
      //       isFavorite: false,
      //       name: this.Customer,
      //       routerLink: "#",
      //       id: "CustomerAccount",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Customer",
      //       mainmenuid: "Mainmenu.Accounts",
      //       submenu: [
      //         {
      //           menuid: "CustomerLedgerwithItinerary",
      //           pid: "CustomerAccount",
      //           isFavorite: false,
      //           name: this.CustomerLedgerwithItinerary,
      //           routerLink: "/PenAir/Reports/CustomerLedgerwithItinerary",
      //           id: "CustomerLedgerwithItinerary",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerLedgerwithItinerary",
      //           mainmenuid: "Mainmenu.CustomerAccount",
      //           submenu: [],
      //         },
      //         //14350
      //         {
      //           menuid: "01.11.30.06.01",
      //           pid: "CustomerAccount",
      //           isFavorite: false,
      //           name: this.CustomerLedgerReport,
      //           routerLink: "/PenAir/Reports/CustomerLedgerReport",
      //           id: "CustomerLedgerReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerLedgerReport",
      //           mainmenuid: "Mainmenu.CustomerAccount",
      //           submenu: [],
      //         },
      //         //14350
      //         {
      //           menuid: "01.11.30.06.02",
      //           pid: "CustomerAccount",
      //           isFavorite: false,
      //           name: this.CustomerOutStandingSummary,
      //           routerLink: "/PenAir/Reports/CustomerOutstandingSummary",
      //           id: "CustomerOutStandingSummary",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerOutStandingSummary",
      //           mainmenuid: "Mainmenu.CustomerAccount",
      //           submenu: [],
      //         },
      //         //14177
      //         {
      //           menuid: "01.11.30.06.03",
      //           pid: "CustomerAccount",
      //           isFavorite: false,
      //           name: this.CustomeOutstandingasondateReport,
      //           routerLink: "/PenAir/Reports/CustomerOutstandingDetailedAsondateReport",
      //           id: "CustomerOutstandingDetailedAsondateReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerOutstandingDetailedAsondateReport",
      //           mainmenuid: "Mainmenu.CustomerAccount",
      //           submenu: [],
      //         },
      //         //14177
      //         //14494
      //         {
      //           menuid: "01.11.30.06.04",
      //           pid: "CustomerAccount",
      //           isFavorite: false,
      //           name: this.CustomerOutstandingAsOnDateAgedSummaryReport,
      //           routerLink: "/PenAir/Reports/CustomerOutstandingAsOnDateAgedSummaryReport",
      //           id: "CustomerOutstandingAsOnDateAgedSummaryReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerOutstandingAsOnDateAgedSummaryReport",
      //           mainmenuid: "Mainmenu.CustomerAccount",
      //           submenu: [],
      //         },
      //         //14254
      //         {
      //           menuid: "01.11.30.06.06",
      //           pid: "CustomerAccount",
      //           isFavorite: false,
      //           name: this.DeferredRevenueAndExpenseReport,
      //           routerLink: "/PenAir/Reports/DeferredRevenueAndExpenseReport",
      //           id: "DeferredRevenueAndExpenseReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.DeferredRevenueAndExpenseReport",
      //           mainmenuid: "Mainmenu.CustomerAccount",
      //           submenu: [],
      //         },
      //         //  14254

      //         //14197
      //         {
      //           menuid: "01.11.30.06.05",
      //           pid: "CustomerAccount",
      //           isFavorite: false,
      //           name: this.DepositAsondateReport,
      //           routerLink: "/PenAir/Reports/DepositAsondateReport",
      //           id: "DepositAsondateReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.DepositAsondateReport",
      //           mainmenuid: "Mainmenu.CustomerAccount",
      //           submenu: [],
      //         },
      //         //14197
      //         //14255
      //         {
      //           menuid: " 01.11.30.06.07",
      //           pid: "CustomerAccount",
      //           isFavorite: false,
      //           name: this.DepositCollectedforFutureTravel,
      //           routerLink: "/PenAir/Reports/DepositCollectedforFutureTravel",
      //           id: "DepositCollectedforFutureTravel",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.DepositCollectedforFutureTravel",
      //           mainmenuid: "Mainmenu.CustomerAccount",
      //           submenu: [],
      //         },
      //       ],
      //     },
      //     //14227

      //     {
      //       menuid: "",
      //       pid: "Accounts",
      //       isFavorite: false,
      //       name: this.SupplierAccounts,
      //       routerLink: "#",
      //       id: "SupplierAccounts",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.SupplierAccounts",
      //       mainmenuid: "Mainmenu.Accounts",
      //       submenu: [
      //         {
      //           menuid: "01.11.30.07.04",
      //           pid: "SupplierAccounts",
      //           isFavorite: false,
      //           name: this.SupplierOutstandingAsOnDateAgedReport,
      //           routerLink: "/PenAir/Reports/SupplierOutstandingAsOnDateAgedReport",
      //           id: "SupplierOutstandingAsOnDateAgedReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.SupplierOutstandingAsOnDateAgedReport",
      //           mainmenuid: "Mainmenu.SupplierAccounts",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.30.07.03",
      //           pid: "SupplierAccounts",
      //           isFavorite: false,
      //           name: this.SupplierOutstandingDetailedAsondateReport,
      //           routerLink: "/PenAir/Reports/SupplierOutstandingDetailedAsondateReport",
      //           id: "SupplierOutstandingDetailedAsondateReport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.SupplierOutstandingDetailedAsondateReport",
      //           mainmenuid: "Mainmenu.SupplierAccounts",
      //           submenu: [],
      //         },
      //         //14360
      //         {
      //           menuid: "01.11.30.07.01",
      //           pid: "CustomerAccount",
      //           isFavorite: false,
      //           name: this.SupplierLedgerreport,
      //           routerLink: "/PenAir/Reports/SupplierLedgerReport",
      //           id: "SupplierLedgerreport",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.SupplierLedgerreport",
      //           mainmenuid: "Mainmenu.SupplierAccounts",
      //           submenu: [],
      //         },
      //         //14214
      //         {
      //           menuid: "01.11.30.06.02",
      //           pid: "SupplierAccounts",
      //           isFavorite: false,
      //           name: this.SupplierOutStandingSummary,
      //           routerLink: "/PenAir/Reports/SupplierOutStandingSummary",
      //           id: "SupplierOutStandingSummary",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.SupplierOutStandingSummary",
      //           mainmenuid: "Mainmenu.SupplierAccounts",
      //           submenu: [],
      //         },
      //         //13992
      //         // {
      //         //   menuid: "01.11.17.02",
      //         //   pid: "SupplierAccounts",
      //         //   isFavorite: false,
      //         //   name: this.PurchaseCommitmentReport,
      //         //   routerLink: "/PenAir/Reports/PurchaseCommitmentReport",
      //         //   id: "PurchaseCommitmentReport",
      //         //   submenuexists: false,
      //         //   submenuid: "Mainmenu.PurchaseCommitmentReport",
      //         //   mainmenuid: "Mainmenu.SupplierAccounts",
      //         //   submenu: [],
      //         // },
      //       ],
      //     },

      //     //14227
      //     {
      //       menuid: "01.11.30.13",
      //       pid: "Accounts",
      //       isFavorite: false,
      //       name: this.VoucherLiability,
      //       routerLink: "PenAir/Reports/VoucherLiability",
      //       id: "VoucherLiability",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.VoucherLiability",
      //       mainmenuid: "Mainmenu.VoucherLiability",
      //       submenu: [],
      //     },

      //   ],
      // },
      // {
      //   menuid: "",
      //   name: this.Hotel,
      //   id: "Hotel",
      //   iconClass: "fa fa-building",
      //   active: false,
      //   submenuexists: true,
      //   routerLink: "/PenAir",
      //   mainmenuid: "Hotel",
      //   isFavorite: false,
      //   submenu: [
      //     {
      //       menuid: "13.10.01",
      //       pid: "Hotel",
      //       isFavorite: false,
      //       name: this.BookAHotel,
      //       routerLink: "",
      //       id: "BookAHotel",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.BookAHotel",
      //       mainmenuid: "Mainmenu.Hotel",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "01.11.32.02.03.03",
      //       pid: "Hotel",
      //       isFavorite: false,
      //       name: this.HotelList,
      //       routerLink: "PenAir/Hotel/List",
      //       id: "HotelList",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.HotelList",
      //       mainmenuid: "Mainmenu.Hotel",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Hotel",
      //       isFavorite: false,
      //       name: this.Settings,
      //       routerLink: "#",
      //       id: "HotelSettings",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Settings",
      //       mainmenuid: "Mainmenu.Hotel",
      //       submenu: [
      //         {
      //           menuid: "01.11.32.02.03.09",
      //           pid: "HotelSettings",
      //           isFavorite: false,
      //           name: this.HotelPricingGroup,
      //           routerLink: "PenAir/Settings/HotelPricingGroup",
      //           id: "HotelPricingGroup",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.HotelPricingGroup",
      //           mainmenuid: "Mainmenu.Hotel",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.02.03.05",
      //           pid: "HotelSettings",
      //           isFavorite: false,
      //           name: this.MealPlanMaster,
      //           routerLink: "PenAir/Settings/MealPlanMaster",
      //           id: "MealPlanMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.MealPlanMaster",
      //           mainmenuid: "Mainmenu.Hotel",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.02.03.04",
      //           pid: "HotelSettings",
      //           isFavorite: false,
      //           name: this.ProjectMaster,
      //           routerLink: "PenAir/Settings/ProjectMaster",
      //           id: "ProjectMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ProjectMaster",
      //           mainmenuid: "Mainmenu.Hotel",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.02.03.06",
      //           pid: "HotelSettings",
      //           isFavorite: false,
      //           name: this.PropertyTypes,
      //           routerLink: "PenAir/Settings/PropertyTypes",
      //           id: "PropertyType",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.hotelpropertytypes",
      //           mainmenuid: "Mainmenu.Hotel",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.02.03.02",
      //           pid: "HotelSettings",
      //           isFavorite: false,
      //           name: this.RoomTypesMaster,
      //           routerLink: "PenAir/Settings/RoomTypesMaster",
      //           id: "RoomTypesMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.RoomTypesMaster",
      //           mainmenuid: "Mainmenu.Hotel",
      //           submenu: [],
      //         },

      //       ],
      //     },
      //   ],
      // },
      // {
      //   menuid: "",
      //   name: this.Assets,
      //   id: "Assets",
      //   iconClass: "fa fa-home",
      //   active: false,
      //   submenuexists: true,
      //   routerLink: "/PenAir",
      //   mainmenuid: "Mainmenu.Assets",
      //   isFavorite: false,
      //   submenu: [
      //     {
      //       menuid: "",
      //       pid: "Assets",
      //       isFavorite: false,
      //       name: this.FixedAssets,
      //       routerLink: "",
      //       id: "FixedAssets",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.FixedAssets",
      //       mainmenuid: "Mainmenu.Assets",
      //       submenu: [],
      //     },
      //   ]
      // },
      // {
      //   menuid: "",
      //   name: this.Settings,
      //   id: "Settings",
      //   iconClass: "fa fa-wrench",
      //   active: false,
      //   submenuexists: true,
      //   routerLink: "/PenAir",
      //   mainmenuid: "Mainmenu.Settings",
      //   isFavorite: false,
      //   submenu: [
      //     {
      //       menuid: "",
      //       pid: "Settings",
      //       isFavorite: false,
      //       name: this.Commonsetting,
      //       routerLink: "#",
      //       id: "Commonsetting",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Commonsetting",
      //       mainmenuid: "Mainmenu.Settings",
      //       submenu: [
      //         //13637
      //         {
      //           menuid: "01.11.32.03.01.01",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.ActionMaster,
      //           routerLink: "PenAir/Settings/Commonsettings/ActionMaster",
      //           id: "ActionMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ActionMaster",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.03.12",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.Busareawiseemailandphone,
      //           routerLink: "PenAir/Settings/Commonsettings/Usersettings/Busareawiseemailandphone",
      //           id: "Busareawiseemailandphone",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.Busareawiseemailandphone",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.03.13",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.CannedMessage,
      //           routerLink: "PenAir/Settings/Commonsettings/CannedMessage",
      //           id: "CannedMessage",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CannedMessage",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         { //13638
      //           menuid: "01.22.01",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.CountryMaster,
      //           routerLink: "PenAir/Settings/Commonsetting/CountryMasterList",
      //           id: "CountryMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CountryMaster",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {//13654
      //           menuid: "01.11.32.03.15",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.DropdowmListMaster,
      //           routerLink: "PenAir/Settings/Commonsetting/DropdownListMaster",
      //           id: "DropdowmListMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.DropdowmListMaster",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "13.08.09",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.EmailSettingsList,
      //           routerLink: "PenAir/Commonsetting/EmailSettingsList",
      //           id: "EmailSettingsList",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.EmailSettingsList",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         //13791
      //         {
      //           menuid: "01.11.32.03.17",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.MarketingCodeGroupMaster,
      //           routerLink: "PenAir/Settings/Commonsettings/MarketingCodeGroupMaster",
      //           id: "MarketingCodeGroupMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.MarketingCodeGroupMaster",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         //13791
      //         //14009
      //         {
      //           menuid: "01.11.32.03.02",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.MarketingRegion,
      //           routerLink: "PenAir/Settings/Commonsettings/MarketingRegion",
      //           id: "MarketingRegion",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.MarketingRegion",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         //14009
      //         {
      //           menuid: "13.08.12",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.MarketingCode,
      //           routerLink: "PenAir/Settings/Commonsettings/MarketingCodeMaster",
      //           id: "MarketingCodeMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.MarketingCodeMaster",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.01.15",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.MainDocumentType,
      //           routerLink: "PenAir/Settings/Commonsettings/MainDocumentList",
      //           id: "ActionMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ActionMaster",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "13.06.01",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.MessageSettings,
      //           routerLink: "PenAir/Settings/Commonsettings/MessageSettings",
      //           id: "MessageSettings",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.MessageSettings",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: []
      //         },
      //         {
      //           menuid: "01.11.32.01.07",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.ProfitCentreGroupMaster,
      //           routerLink: "PenAir/Settings/Commonsettings/ProfitCentreGroupMaster",
      //           id: "ProfitCentreGroupMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ProfitCentreGroupMaster",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "13.06.02.01",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.ProfitCentreList,
      //           routerLink: "PenAir/Settings/Commonsettings/ProfitCentreMaster",
      //           id: "ProfitCenterList",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ProfitCentreList",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.03.04",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.PostCodeSearchSettings,
      //           routerLink: "PenAir/Settings/Commonsettings/PostCodeSearchSettings",
      //           id: "PostCodeSearchSettings",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.PostCodeSearchSettings",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.03.16",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.ReportSchedulerList,
      //           routerLink: "PenAir/Commonsetting/ReportSchedulerList",
      //           id: "ReportSchedulerList",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ReportSchedulerList",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.03.18.01",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.SftpSettings,
      //           routerLink: "PenAir/Settings/Commonsettings/SftpSettings",
      //           id: "SmsSetup",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.SftpSettings",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.06.17",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.thirdPartyExcelMapping,
      //           routerLink: "PenAir/Settings/Commonsetting/thirdPartyExcelMappingList",
      //           id: "thirdPartyExcelMapping",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.thirdPartyExcelMapping",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.03.08",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.Workflow,
      //           routerLink: "PenAir/Settings/Commonsettings/Workflow",
      //           id: "Workflow",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.Workflow",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.03.03",
      //           pid: "Commonsetting",
      //           isFavorite: false,
      //           name: this.EmailTemplatesGrid,
      //           routerLink: "PenAir/Commonsetting/EmailTemplatesGrid",
      //           id: "EmailTemplatesGrid",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.EmailTemplatesGrid",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },




      //       ],
      //     },


      //     {
      //       menuid: "",
      //       pid: "Settings",
      //       isFavorite: false,
      //       name: this.Financesetting,
      //       routerLink: "#",
      //       id: "Financesetting",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Financesetting",
      //       mainmenuid: "Mainmenu.Settings",
      //       submenu: [
      //         {
      //           menuid: "13.08.05",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.AgentCommissionList,
      //           routerLink:
      //             "PenAir/Financesetting/AgentCommissionList",
      //           id: "AgentCommissionList",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.AgentCommissionList",
      //           mainmenuid: "Mainmenu.AgentCommissionList",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.01.04.01",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.AgeingScheduleMaster,
      //           routerLink:
      //             "PenAir/Settings/Financesettings/AgeingScheduleMaster",
      //           id: "AgeingScheduleMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.AgeingScheduleMaster",
      //           mainmenuid: "Mainmenu.AgeingScheduleMaster",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.01.22",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.CardMaster,
      //           routerLink:
      //             "PenAir/Settings/Financesettings/CardMaster",
      //           id: "CardMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CardMaster",
      //           mainmenuid: "Mainmenu.CardMaster",
      //           submenu: [],
      //         },


      //         {
      //           menuid: "01.11.32.01.13.01",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.CostCentreMaster,
      //           routerLink:
      //             "PenAir/Settings/Financesettings/CostCentreMaster",
      //           id: "CostCentreMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CostCentreMaster",
      //           mainmenuid: "Mainmenu.CostCentreMaster",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.01.01.02",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.CustomerCreditGroup,
      //           routerLink:
      //             "PenAir/Settings/Financesettings/CustomerCreditGroup",
      //           id: "CustomerCreditGroup",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerCreditGroup",
      //           mainmenuid: "Mainmenu.CustomerCreditGroup",
      //           submenu: [],
      //         },
      //         //13861
      //         {
      //           menuid: "01.11.32.01.01.01.02",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.CustomerGroupMaster,
      //           routerLink:
      //             "PenAir/Settings/Financesettings/CustomerGroupMaster",
      //           id: "CustomerGroupMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerGroupMaster",
      //           mainmenuid: "Mainmenu.CustomerGroupMaster",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.01.20",
      //           pid: "Financesettings",
      //           isFavorite: false,
      //           name: this.DefaultCharges,
      //           routerLink: "PenAir/Settings/Financesettings/DefaultCharges",
      //           id: "DefaultCharges",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.DefaultCharges",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.01.08.01",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.CurrencyMaster,
      //           routerLink: "PenAir/Currency/CurrencyExcelUpload",
      //           id: "CurrencyMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CurrencyMaster",
      //           mainmenuid: "Mainmenu.CurrencyMaster",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "13.08.18.01",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.PaymentBlockMaster,
      //           routerLink:
      //             "PenAir/Settings/Financesettings/PaymentBlockMaster",
      //           id: "PaymentBlockMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.PaymentBlockMaster",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.01.06.01",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.PaymentMethodMaster,
      //           routerLink:
      //             "PenAir/Settings/Financesettings/PaymentMethodMaster",
      //           id: "PaymentMethodMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.PaymentMethodMaster",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.01.18",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.PaymentTermsMaster,
      //           routerLink:
      //             "PenAir/Settings/Financesettings/PaymentTermsMaster",
      //           id: "PaymentTermsMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.PaymentTermsMaster",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.02.09.11",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.PaymentGateway,
      //           routerLink:
      //             "PenAir/PaymentGatewayList/PaymentGatewayListDetails",
      //           id: "PaymentGatewayList",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.PaymentGatewayList",
      //           mainmenuid: "Mainmenu.PaymentGateway",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "01.11.32.01.08.01",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.PettyCashMaster,
      //           routerLink: "PenAir/PettyCashMaster/ViewPettyCashMaster",
      //           id: "PettyCashMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.PettyCashMasterList",
      //           mainmenuid: "Mainmenu.PettyCashMaster",
      //           submenu: [],
      //         },
      //         {
      //           menuid: "",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.Supplier,
      //           routerLink: "#",
      //           id: "Supplier",
      //           submenuexists: true,
      //           submenuid: "Mainmenu.Supplier",
      //           mainmenuid: "Mainmenu.Financesetting",
      //           submenu: [
      //             //13953
      //             {
      //               menuid: "01.11.32.01.05.01",
      //               pid: "Supplier",
      //               isFavorite: false,
      //               name: this.SupplierGroupMaster,
      //               routerLink: "PenAir/Settings/Financesettings/SupplierGroupMaster",
      //               id: "SupplierGroupMaster",
      //               submenuexists: false,
      //               submenuid: "Mainmenu.SupplierGroupMaster",
      //               mainmenuid: "Mainmenu.Supplier",
      //               submenu: []
      //             },
      //           ],
      //         },
      //         //13943
      //         {
      //           menuid: "01.11.32.01.16",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.TaxRateMaster,
      //           routerLink: "PenAir/Settings/Financesetting/TaxRateMaster",
      //           id: "TaxRateMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.PettyCashMasterList",
      //           mainmenuid: "Mainmenu.TaxRateMaster",
      //           submenu: [],
      //         },
      //         //14134
      //         {
      //           menuid: "TaxMaster",
      //           pid: "Financesetting",
      //           isFavorite: false,
      //           name: this.TaxMaster,
      //           routerLink: "PenAir/Settings/Financesetting/TaxMaster",
      //           id: "TaxMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.TaxMaster",
      //           mainmenuid: "Mainmenu.Financesetting",
      //           submenu: [],
      //         },

      //       ],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Settings",
      //       isFavorite: false,
      //       name: this.Productsetting,
      //       routerLink: "#",
      //       id: "Productsetting",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Productsetting",
      //       mainmenuid: "Mainmenu.Settings",
      //       submenu: [
      //         {
      //           menuid: "01.11.32.01.23.01",
      //           pid: "Productsetting",
      //           isFavorite: false,
      //           name: this.ViewProductGroupMaster,
      //           routerLink: "PenAir/ProductGroupMaster/ViewProductGroupMaster",
      //           id: "ProductGroupMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ViewProductGroupMaster",
      //           mainmenuid: "Mainmenu.Settings",
      //           submenu: [],
      //         },
      //       ],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Settings",
      //       isFavorite: false,
      //       name: this.Travelsetting,
      //       routerLink: "#",
      //       id: "travelsettings",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.Travelsetting",
      //       mainmenuid: "Mainmenu.Settings",
      //       submenu: [
      //         {
      //           menuid: "01.11.32.02.04.01",
      //           pid: "Travelsetting",
      //           isFavorite: false,
      //           name: this.AirwaysMaster,
      //           routerLink: "PenAir/travelsettings/AirwaysMaster",
      //           id: "AirwaysMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.AirwaysMaster",
      //           mainmenuid: "Mainmenu.Travelsetting",
      //           submenu: []
      //         },
      //         {//13709
      //           menuid: "01.11.32.02.02.01",
      //           pid: "Travelsetting",
      //           isFavorite: false,
      //           name: this.CityMaster,
      //           routerLink: "PenAir/travelsettings/CityMaster",
      //           id: "CityMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CityMaster",
      //           mainmenuid: "Mainmenu.Travelsetting",
      //           submenu: []
      //         },
      //         //13966
      //         {
      //           menuid: "01.11.32.02.07",
      //           pid: "Travelsetting",
      //           isFavorite: false,
      //           name: this.GDSAutoFolderSettings,
      //           routerLink: "PenAir/Settings/travelsettings/GDSAutoFolderSettings",
      //           id: "GDSAutoFolderSettings",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.GDSAutoFolderSettings",
      //           mainmenuid: "Mainmenu.Travelsetting",
      //           submenu: []
      //         },
      //         {
      //           menuid: "GDSMaster",
      //           pid: "Travelsetting",
      //           isFavorite: false,
      //           name: this.GDSMaster,
      //           routerLink: "PenAir/settings/Travelsettings/GDSMaster",
      //           id: "GDSMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.GDSMaster",
      //           mainmenuid: "Mainmenu.Travelsetting",
      //           submenu: []
      //         },
      //         {
      //           menuid: "GDSUsrMapView",
      //           pid: "Travelsetting",
      //           isFavorite: false,
      //           name: this.GdsUsermappingList,
      //           routerLink: "PenAir/travelsettings/GdsUsermappingList",
      //           id: "GdsUsermappingList",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.GdsUsermappingList",
      //           mainmenuid: "Mainmenu.Travelsetting",
      //           submenu: []
      //         },
      //         //13637
      //         //14103
      //         {
      //           menuid: "01.11.32.02.20",
      //           pid: "Travelsetting",
      //           isFavorite: false,
      //           name: this.IATAMaster,
      //           routerLink: "PenAir/Settings/travelsettings/IATAMaster",
      //           id: "IATAMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.IATAMaster",
      //           mainmenuid: "Mainmenu.Travelsetting",
      //           submenu: []
      //         },
      //         {
      //           menuid: "01.11.32.02.17",
      //           pid: "travelsettings",
      //           isFavorite: false,
      //           name: this.OrderTypeMaster,
      //           routerLink: "PenAir/travelsettings/OrderTypeMaster",
      //           id: "orderTypeMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.OrderTypeMaster",
      //           mainmenuid: "Mainmenu.Travelsetting",
      //           submenu: [],

      //         },

      //         {
      //           menuid: "ReasonCodeM",
      //           pid: "travelsettings",
      //           isFavorite: false,
      //           name: this.ReasonCodeMaster,
      //           routerLink: "PenAir/settings/Travelsettings/ReasonCodeMaster",
      //           id: "ReasonCodeMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ReasonCodeMaster",
      //           mainmenuid: "Mainmenu.Travelsetting",
      //           submenu: [],

      //         },
      //         {
      //           menuid: "",
      //           pid: "travelsettings",
      //           isFavorite: false,
      //           name: this.CRMSettings,
      //           routerLink: "#",
      //           id: "CRMSettings",
      //           submenuexists: true,
      //           submenuid: "Mainmenu.CRMSettings",
      //           mainmenuid: "Mainmenu.Travelsetting",
      //           submenu: [
      //             {
      //               menuid: "DealIDMaster",
      //               pid: "CRMSettings",
      //               isFavorite: false,
      //               name: this.DealIdMaster,
      //               routerLink: "PenAir/CRMSettings/DealIdMaster",
      //               id: "DealIdMaster",
      //               submenuexists: false,
      //               submenuid: "Mainmenu.DealIdMaster",
      //               mainmenuid: "Mainmenu.CRMSettings",
      //               submenu: []
      //             },
      //             {
      //               menuid: "01.11.32.02.22.01.01",
      //               pid: "CRMSettings",
      //               isFavorite: false,
      //               name: this.ScreenVariant,
      //               routerLink: "PenAir/CRMSettings/ScreenVariant",
      //               id: "ScreenVariant",
      //               submenuexists: false,
      //               submenuid: "Mainmenu.ScreenVariant",
      //               mainmenuid: "Mainmenu.CRMSettings",
      //               submenu: []
      //             },
      //             { //14317
      //               menuid: "01.11.32.03.09.01",
      //               pid: "Commonsetting",
      //               isFavorite: false,
      //               name: this.ThirdPartyCRMSettings,
      //               routerLink: "PenAir/Settings/Commonsetting/CRMSettings",
      //               id: "ThirdPartyCRMSettings",
      //               submenuexists: false,
      //               submenuid: "Mainmenu.ThirdPartyCRMSettings",
      //               mainmenuid: "Mainmenu.Settings",
      //               submenu: [],
      //             },
      //           ],
      //         },
      //         {
      //           menuid: "",
      //           pid: "travelsettings",
      //           isFavorite: false,
      //           name: this.Validations,
      //           routerLink: "#",
      //           id: "Validations",
      //           submenuexists: true,
      //           submenuid: "Mainmenu.Validations",
      //           mainmenuid: "Mainmenu.Travelsetting",
      //           submenu: [
      //             {
      //               menuid: "",
      //               pid: "Validations",
      //               isFavorite: false,
      //               name: this.FraudScoreSettings,
      //               routerLink: "PenAir/travelsettings/FraudScoreSettings",
      //               id: "FraudScoreSettings",
      //               submenuexists: false,
      //               submenuid: "Mainmenu.FraudScoreSettings",
      //               mainmenuid: "Mainmenu.Validations",
      //               submenu: []
      //             },
      //           ],
      //         },
      //         {
      //           //
      //           menuid: "01.11.32.02.06",
      //           pid: "travelsettings",
      //           isFavorite: false,
      //           name: this.StaffGroupMaster,
      //           routerLink: "PenAir/travelsettings/StaffGroupMaster",
      //           id: "StaffGroupMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.StaffGroupMaster",
      //           mainmenuid: "Mainmenu.Travelsetting",
      //           submenu: [],
      //         },
      //         //14277
      //         {
      //           menuid: "01.11.32.02.19",
      //           pid: "Travelsetting",
      //           isFavorite: false,
      //           name: this.StoreFrontMaster,
      //           routerLink: "PenAir/Travelsettings/StoreFrontMaster",
      //           id: "StoreFrontMaster",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.StoreFrontMaster",
      //           mainmenuid: "Mainmenu.Travelsetting",
      //           submenu: [],
      //         },
      //         //14107
      //         {
      //           menuid: "01.11.32.03.11",
      //           pid: "Travelsetting",
      //           isFavorite: false,
      //           name: this.ThirdPartyMapping,
      //           routerLink: "PenAir/settings/Travelsettings/ThirdPartyMapping",
      //           id: "ThirdPartyMapping",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.ThirdPartyMapping",
      //           mainmenuid: "Mainmenu.Travelsetting",
      //           submenu: []
      //         },

      //         //14107



      //       ],
      //     },
      //   ],
      // },
      // {
      //   menuid: "",
      //   name: this.Adminstration,
      //   iconClass: "fa fa-users",
      //   active: false,
      //   id: "Adminstration",
      //   submenuexists: true,
      //   routerLink: "/PenAir",
      //   mainmenuid: "Mainmenu.Adminstration",
      //   isFavorite: false,
      //   submenu: [

      //     {
      //       menuid: "01.11.25.01",
      //       pid: "Adminstration",
      //       isFavorite: false,
      //       name: this.UserList,
      //       routerLink: "PenAir/Adminstration/UserList",
      //       id: "UserList",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.UserList",
      //       mainmenuid: "Mainmenu.Adminstration",
      //       submenu: []
      //     },
      //     //13656
      //     {
      //       menuid: "",
      //       pid: "Adminstration",
      //       isFavorite: false,
      //       name: this.Utilities,
      //       routerLink: "#",
      //       id: "AdminUtilities",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.UnlockDocument",
      //       mainmenuid: "Mainmenu.Adminstration",
      //       submenu: [
      //         {
      //           menuid: "13.09.01",
      //           pid: "AdminUtilities",
      //           isFavorite: false,
      //           name: this.UnlockDocument,
      //           routerLink: "PenAir/Utilities/UnlockDocuments",
      //           id: "UnlockDocument",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.UnlockDocument",
      //           mainmenuid: "Mainmenu.Adminstration",
      //           submenu: [],
      //         },
      //       ],
      //     },
      //     //13656
      //     //  13992

      //     {
      //       menuid: "Websites",
      //       pid: "Adminstration",
      //       isFavorite: false,
      //       name: this.Websites,
      //       routerLink: "#",
      //       id: "AdminWebsites",
      //       submenuexists: true,
      //       submenuid: "Mainmenu.CustomerPortal",
      //       mainmenuid: "Mainmenu.Adminstration",
      //       submenu: [
      //         {
      //           menuid: "CustomerPortal",
      //           pid: "AdminWebsites",
      //           isFavorite: false,
      //           name: this.CustomerPortal,
      //           routerLink: "#",
      //           id: "CustomerPortal",
      //           submenuexists: false,
      //           submenuid: "Mainmenu.CustomerPortal",
      //           mainmenuid: "Mainmenu.Adminstration",
      //           submenu: [],
      //         },
      //       ],
      //     },
      //     //13992
      //     {
      //       menuid: "13.09.02.02",
      //       pid: "Adminstration",
      //       isFavorite: false,
      //       name: this.PenChannel,
      //       routerLink: "PenAir/Utilities/PenChannel",
      //       id: "PenChannel",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.PenChannel",
      //       mainmenuid: "Mainmenu.Adminstration",
      //       submenu: [],
      //     },
      //   ],
      // },
      // {
      //   menuid: "",
      //   name: this.Help,
      //   iconClass: "fa fa-question-circle",
      //   active: false,
      //   id: "Help",
      //   submenuexists: true,
      //   routerLink: "",
      //   mainmenuid: "Mainmenu.Help",
      //   isFavorite: false,
      //   submenu: [
      //     {
      //       menuid: "",
      //       pid: "Help",
      //       isFavorite: false,
      //       name: this.Guidlines,
      //       routerLink: "#",
      //       id: "Guidlines",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.Guidlines",
      //       mainmenuid: "Mainmenu.Help",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Help",
      //       isFavorite: false,
      //       name: this.BeginnerNotes,
      //       routerLink: "#",
      //       id: "BeginnerNotes",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.BeginnerNotes",
      //       mainmenuid: "Mainmenu.Help",
      //       submenu: [],
      //     },
      //     {
      //       menuid: "",
      //       pid: "Help",
      //       isFavorite: false,
      //       name: this.Shortcuts,
      //       routerLink: "#",
      //       id: "Shortcuts",
      //       submenuexists: false,
      //       submenuid: "Mainmenu.Shortcuts",
      //       mainmenuid: "Mainmenu.Help",
      //       submenu: [],
      //     },
      //   ],
      // },
    ];

    this.pageheader(this.headervalue);

    // if (data === undefined) {
    //   var data = this.menus[3].submenu.find(x => x.name == this.headerText)
    // }
  }
  public open() {
    this.opened = true;
  }
  gettransilateheadertest() {
    this.headerlanguage = sessionStorage.getItem("headerlanguage");
    // console.log("check",this.headerlanguage)
    if (this.headerlanguage == "Mainmenu.Inbox") {
      this.mainmenutransilate = "Mainmenu.Alerts";
    } else
      this.mainmenutransilate = sessionStorage.getItem("mainmenutransilate");
    if (
      this.headerlanguage !== undefined &&
      this.headerlanguage !== null &&
      this.headerlanguage !== "null"
    ) {
      this.subscription.add(
        this.translate.get([this.headerlanguage]).subscribe((translations) => {
          this.headerText = translations[this.headerlanguage];

          if (this.headerText == undefined) {
            if (
              this.headerlanguage != undefined &&
              this.headerlanguage._value != null
            ) {
              this.headerText = translations[this.headerlanguage._value];
              // this.translate.get([this.headerlanguage],).subscribe((translations ) => {
              //   this.headerText= translations[this.headerlanguage];

              //   });
            }
            //  this.subscription.add(this.translate.get([this.mainmenutransilate],).subscribe((translations ) => {
            //   this.mainmenu= translations[this.mainmenutransilate];
            //   sessionStorage.setItem('mainmenu', this.mainmenu)
            //   this.headervalue=this.mainmenu+'/'+this.headerText;
            //  }));
          }
        })
      );

      this.subscription.add(
        this.translate
          .get([this.mainmenutransilate])
          .subscribe((translations) => {
            this.mainmenu = translations[this.mainmenutransilate];
            sessionStorage.setItem("mainmenu", this.mainmenu);
            if (this.headerText.toString() != "undefined")
              this.headervalue = this.mainmenu + "/" + this.headerText;
            else this.headervalue = this.mainmenu;
          })
      );
      if (this.headerlanguage == "Mainmenu.Profile") {
        //By Case id-363
        this.headerPassenger = "";
        this.folderstatus = "";
        sessionStorage.setItem("headerPassenger", this.headerPassenger);
        sessionStorage.setItem("folderstatus", this.folderstatus);
        this.hideaccountsmenucomponent = false;
        this.hideaccordianmenucomponent = true;
      }
      if (this.headerlanguage == "Mainmenu.SubscriptionDetails") {
        //By Case id-363
        this.headerPassenger = "";
        this.folderstatus = "";
        sessionStorage.setItem("headerPassenger", this.headerPassenger);
        sessionStorage.setItem("folderstatus", this.folderstatus);
        this.hideaccountsmenucomponent = false;
        this.hideaccordianmenucomponent = true;
      }
    } else {
      if (sessionStorage.getItem("menuhighlight") != null) {
        var reporturl = sessionStorage.getItem("menuhighlight");
        var mainmenu = this.menus.find(
          (x) => x.mainmenuid == "Mainmenu.Reports"
        );
        var submenu = mainmenu.submenu.find((x) => x.routerLink == reporturl);

        this.subscription.add(
          this.translate.get(["Mainmenu.Reports"]).subscribe((translations) => {
            this.headerText = translations["Mainmenu.Reports"];
            this.subscription.add(
              this.translate
                .get([submenu.submenuid])
                .subscribe((translations) => {
                  this.headervalue = translations[submenu.submenuid];
                  this.headervalue = this.headerText + "/" + this.headervalue;
                })
            );
          })
        );
      } else {
        this.headerlanguage = "Mainmenu.Search";
        this.subscription.add(
          this.translate
            .get([this.headerlanguage])
            .subscribe((translations) => {
              this.headerText = translations[this.headerlanguage];
              this.headerlanguage = "Mainmenu.Search";
              sessionStorage.setItem("headerlanguage", this.headerlanguage);
              sessionStorage.setItem("headerText", this.headerText);
            })
        );
        this.mainmenutransilate = "Mainmenu.Alerts";
        if (
          this.mainmenutransilate !== undefined &&
          this.mainmenutransilate !== null &&
          this.mainmenutransilate !== "null"
        ) {
          this.subscription.add(
            this.translate
              .get([this.mainmenutransilate])
              .subscribe((translations) => {
                if (this.mainmenutransilate === "null") {
                  this.mainmenutransilate = "Mainmenu.Alerts";
                  this.mainmenu = translations[this.mainmenutransilate];
                  sessionStorage.setItem("mainmenu", this.mainmenu);
                } else {
                  this.mainmenu = translations[this.mainmenutransilate];
                  sessionStorage.setItem("mainmenu", this.mainmenu);
                }

                sessionStorage.setItem(
                  "mainmenutransilate",
                  this.mainmenutransilate
                );
                this.headervalue = this.mainmenu + "/" + this.headerText;
              })
          );
        }
      }
    }
  }

  getheadertext() {
    // var mainmenu = this.menus.find(x => x.name == this.headerText)
    // //var mainmenu = this.menus.find(x => x.name == this.headerText)
    // if (mainmenu === undefined) {
    //   var data1 = this.menus[0].submenu.find(x => x.name == this.headerText)
    // }
    // else {
    //   this.headerlanguage=mainmenu.mainmenuid;
    // }
    //   if (data1 === undefined) {
    //   var data1 = this.menus[1].submenu.find(x => x.name == this.headerText)
    // }
    //  if (data1 === undefined) {
    //   var data1 = this.menus[2].submenu.find(x => x.name ==this. headerText)
    // }
    //  if (data1 === undefined) {
    //   var data1 = this.menus[3].submenu.find(x => x.name == this.headerText)
    // }
    //  if (data1 === undefined) {
    //   var data1 = this.menus[4].submenu.find(x => x.name ==this. headerText)
    //   //alert(JSON.stringify(data1));
    //   if(data1===undefined)
    //   {
    //   var data2 = this.menus[4].submenu[2].submenu.find(x => x.name == this.headerText)
    //   //alert(JSON.stringify(data2));
    //   if(data2!=undefined)
    //   {
    //     var  data1 = this.menus[4].submenu.find(x => x.name ==this.menus[4].submenu[2].name);
    //   }
    //   }
    // }
    //  if (data1 === undefined) {
    //   var data1 = this.menus[5].submenu.find(x => x.name == this.headerText)
    // }
    // else{
    //   if(data1.submenuid==undefined)
    //   this.headerlanguage= data1.id;
    //   else
    //   this.headerlanguage= data1.submenuid;
    //   if(data1.mainmenuid==undefined)
    //   this.mainmenutransilate=data1.pid;
    //   else
    //   this.mainmenutransilate=data1.mainmenuid;
    // }
    // if (data1 === undefined) {
    //   var data1 = this.menus[7].submenu.find(x => x.name == this.headerText)
    // }
    // if (data1 === undefined) {
    //   var data1 = this.menus[8].submenu.find(x => x.name == this.headerText)
    // }
    // if (data1 === undefined) {
    //   var data1 = this.menus[9].submenu.find(x => x.name == this.headerText)
    // }
    // if (data1 === undefined) {
    //   var data1 = this.menus[10].submenu.find(x => x.name == this.headerText)
    // }
    // if (data1 === undefined) {
    //   var data1 = this.menus[11].submenu.find(x => x.name == this.headerText)
    // }
    // if (data1!= undefined) {
    //   this.headerlanguage= data1.submenuid;
    //   this.mainmenutransilate=data1.mainmenuid;
    // }
    //     if(data2!=undefined)
    //     this.headerlanguage=data2.submenuid;
    //     sessionStorage.setItem('mainmenutransilate', this.mainmenutransilate)
    //     sessionStorage.setItem('headerlanguage', this.headerlanguage)
  }
  onLogoutClick() {
    // alert(JSON.stringify(this.apiShared.uid))
    if (
      this.common.isfinalsaved === true ||
      this.common.folderEditMode === "true"
    ) {
      //this.common.showFlash1('Messages.FolderOpen', 'bg-danger text-white', 2000);
      this.subscription.add(
        this.translate
          .get(["Messages.FolderOpen"])
          .subscribe((translations) => {
            this.showMsg = translations["Messages.FolderOpen"];
            this.toastr.error(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true,
            });
          })
      );
      return this.router.navigateByUrl(this.router.url);
    } else {
      this.lockStatus = this.apiShared.folderReadOnly;
      this.logoutService.DeleteUserLoginInfo(this.apiShared.uid);
      if (this.lockStatus == "0") {
        this.deletePenLock("Folder", this.apiShared.orderNo);
      }
      if (sessionStorage.getItem("PCNO") !== null) {
        this.PCLogout = {
          PCNO: sessionStorage.getItem("PCNO"),
          LogoutTime: "",
        };
        this.penchannelApi.SaveLogoutTime(this.PCLogout).subscribe();
      }
      this.logoutService.GetLogout();

      sessionStorage.setItem("pagevalue", "0");
      this.apiShared.orderNo = this.resetSharedvalues;
      this.apiShared.BRID = this.resetSharedvalues;
      this.apiShared.CustomerCode = this.resetSharedvalues;
      this.apiShared.folderCurrency = this.resetSharedvalues;
      this.apiShared.folderExchangeRate = this.resetSharedvalues;
      this.apiShared.projNo = this.resetSharedvalues;
      this.apiShared.uid = this.resetSharedvalues;
      this.apiShared.PassengerName = this.resetSharedvalues;
      this.apiShared.PassengerNames = this.resetSharedvalues;
      this.apiShared.folderReadOnly = this.resetSharedvalues;
      this.apiShared.uname = this.resetSharedvalues;
      this.apiShared.folderEditMode = this.resetSharedvalues;
      this.apiShared.homeCurrency = this.resetSharedvalues;
      this.apiShared.hcExRate = this.resetSharedvalues;
      this.router.navigate(["/Login"]);
    }
  }
  onLogoutClick500Errror() {
    // alert(JSON.stringify(this.apiShared.uid))

    this.lockStatus = this.apiShared.folderReadOnly;
    this.logoutService.DeleteUserLoginInfo(this.apiShared.uid);
    if (this.lockStatus == "0") {
      this.deletePenLock("Folder", this.apiShared.orderNo);
    }
    if (sessionStorage.getItem("PCNO") !== null) {//case id 14121
      this.PCLogout = {
        PCNO: sessionStorage.getItem("PCNO"),
        LogoutTime: "",
      };
    }
    if (this.PCLogout.PCNO !== null) {
      this.penchannelApi.SaveLogoutTime(this.PCLogout).subscribe();
    }
    this.logoutService.GetLogout();
    sessionStorage.setItem("pagevalue", "0");
    this.apiShared.orderNo = this.resetSharedvalues;
    this.apiShared.BRID = this.resetSharedvalues;
    this.apiShared.CustomerCode = this.resetSharedvalues;
    this.apiShared.folderCurrency = this.resetSharedvalues;
    this.apiShared.folderExchangeRate = this.resetSharedvalues;
    this.apiShared.projNo = this.resetSharedvalues;
    this.apiShared.uid = this.resetSharedvalues;
    this.apiShared.PassengerName = this.resetSharedvalues;
    this.apiShared.PassengerNames = this.resetSharedvalues;
    this.apiShared.folderReadOnly = this.resetSharedvalues;
    this.apiShared.uname = this.resetSharedvalues;
    this.apiShared.folderEditMode = this.resetSharedvalues;
    this.apiShared.homeCurrency = this.resetSharedvalues;
    this.apiShared.hcExRate = this.resetSharedvalues;
    this.router.navigate(["/Login"]);
    window.location.reload();
    // ////// window.location.reload();//disable by deepa
  }
  getDateandCurrencyFormat() {
    // if(this.common.cdateformat=='' ||this.common.cdateformat==undefined ||this.common.cdateformat==null){

    this.subscription.add(
      this.common.GetDefaultCurrencyAndDate().subscribe((data) => {
        if (data != null && data != undefined) {
          if (
            data["ResponseData"] != null &&
            data["ResponseData"] != undefined
          ) {
            this.common.cdateformat = data["ResponseData"]["DateFormat"];
            if (
              this.common.cdateformat == null ||
              this.common.cdateformat == "" ||
              this.common.cdateformat == undefined
            ) {
              this.common.cdateformat = "dd/MMM/yyyy"; //Commented by case id-355
              // this.common.cdateformat='DD/MM/YYYY';//355
            }
            this.common.DecimalCount = data["ResponseData"]["DecimalCount"];
            if (
              this.common.DecimalCount == null ||
              this.common.DecimalCount == "" ||
              this.common.DecimalCount == undefined
            ) {
              this.common.DecimalCount = "n2";
            } else {
              this.common.DecimalCount = "n".concat(this.common.DecimalCount);
            }
            this.common.Culture = data["ResponseData"]["Culture"];

            if (
              this.common.Culture == null ||
              this.common.Culture == "" ||
              this.common.Culture == undefined
            ) {
              this.intl["localeId"] = "en-GB";
              this.common.Culture = "en-GB";
            } else {
              this.intl["localeId"] = data["ResponseData"]["Culture"];
            }
          } else {
            this.intl["localeId"] = "en-GB";
            this.common.Culture = "en-GB";
            this.common.DecimalCount = "n2";
            this.common.cdateformat = "dd/MMM/yyyy"; //Commented by case id-355
            //this.common.cdateformat='DD/MM/YYYY';//355
          }
        }
      })
    );
    //}
  }
  // onProfileClick(){
  //   localStorage.setItem('userprevious',this.router.url);
  //   this.headerText = this.UserSettings;
  //   this.headerlanguage=this.UserSettings;
  //   this.headervalue='';
  //   sessionStorage.setItem('headerText', this.headerText)
  //   this.router.navigate(['PenAir/Administration/UserDetails']);
  // }
  deletePenLock(docType, docno) {
    this.subscription.add(
      this.apipenLock.DeleteLock(docType, docno + "/").subscribe(
        (res) => {
          //alert('deleted Successfully!! :-)\n\n' );
        },
        (err) => {
          console.log(err);
          //  alert('Failed!! :-)\n\n' +err+'\n\n' );
        }
      )
    );
  }

  getLockDetails(docType, docNo, userId, projno) {
    this.subscription.add(
      this.apipenLock1
        .getLock(docType, docNo, userId, projno)
        .subscribe((data) => {
          this.penDockLock = data;
          if (this.penDockLock.ResponseData != null) {
            if (this.penDockLock.ResponseData.LAutoNo != null) {
              this.apiShared.Setapishareddata("1", "EmailInvoice");
              this.lockStatus = "1";
              this.subscription.add(
                this.translate
                  .get(["Messages.cannot_continue"])
                  .subscribe((translations) => {
                    this.confirmMsg = translations["Messages.cannot_continue"]
                      .replace("<<1>>", "Email Invoice")
                      .replace("<<2>>", this.penDockLock.ResponseData.Source)
                      .replace("<<3>>", this.penDockLock.ResponseData.Uname);
                    this.toastr.warning(this.confirmMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,

                      closeButton: true,
                    });
                  })
              );

              this.router.navigate(["PenAir/Folder/Index"]);
            } else {
              this.apiShared.Setapishareddata("0", "EmailInvoice");
              this.lockStatus = "0";

              this.router.navigate(["/EmailInvoice/EmailInvoice"]);
            }
          } else {
            this.spinner.hide();

            this.lockStatus = "0";

            this.apiShared.Setapishareddata("0", "EmailInvoice");

            this.router.navigate(["/EmailInvoice/EmailInvoice"]);
          }
        })
    );
    return this.lockStatus;
  }
  GetFooterDetails() {

    this.subscription.add(
      this.file.GetFooterDetails().subscribe((data) => {

        this.client = data.ResponseData;
        if (!this.client.CWEB.includes("http")) {

          this.client.CWEB = "http://" + this.client.CWEB;
        }
      })
    );
  }

  GetAccountMenu(language: any) {
    //By Case id-363

    this.AccountsSideMenus = [
      {
        name: this.Home,
        iconClass: "fas fa-home",
        active: true,
        id: "Home",
        submenuexists: false,
        mainRouterLink: "/PenAir",
        mainmenuid: "Mainmenu.Home",
        isFavorite: false,
        routerLink: "/PenAir/Home",
      },

      {
        name: this.Profile,
        iconClass: "far fa-user-circle",
        active: true,
        id: "Profile",
        submenuexists: false,
        mainRouterLink: "/PenAir",
        mainmenuid: "Mainmenu.Profile",
        isFavorite: false,
        routerLink: "/PenAir/Administration/UserDetails",
      },
      {
        name: this.SubscriptionDetails,
        id: "SubscriptionDetails",
        iconClass: "far fa-user",
        active: false,
        submenuexists: false,
        mainRouterLink: "/PenAir",
        mainmenuid: "Mainmenu.SubscriptionDetails",
        isFavorite: false,
        routerLink: "/PenAir/Account/MyAccounts",
      },
    ];
    this.pageheader(this.headervalue);
  }
  onMyAccountClick() {
    //By Case id-363

    this.subscription.add(
      this.translate.get(["Mainmenu.Profile"]).subscribe((translations) => {
        this.headerText = translations["Mainmenu.Profile"];
        this.subscription.add(
          this.translate
            .get(["Mainmenu.MyAccount"])
            .subscribe((translations) => {
              this.headervalue = translations["Mainmenu.MyAccount"];
              this.headervalue = this.headervalue + "/" + this.headerText;
              this.headerlanguage = "Mainmenu.Profile";
              this.mainmenutransilate = "Mainmenu.MyAccount";
              sessionStorage.setItem(
                "mainmenutransilate",
                this.mainmenutransilate
              );
              sessionStorage.setItem("headerlanguage", this.headerlanguage);
            })
        );
      })
    );
    this.headerPassenger = "";
    this.folderstatus = "";
    sessionStorage.setItem("headerPassenger", this.headerPassenger);
    sessionStorage.setItem("folderstatus", this.folderstatus);
    this.hideaccountsmenucomponent = false;
    this.hideaccordianmenucomponent = true;
    this.router.navigate(["PenAir/Administration/UserDetails"]);
  }
  public HideAcccountMenu(
    status: any ///By Case id-363
  ) {
    if (status == "HideAccountsMenu") {
      this.hideaccountsmenucomponent = true;
      this.hideaccordianmenucomponent = false;
    }
  }
  loadHomePage() {
    if (this.common.folderEditMode == "false") {
      this.router.navigate(["PenAir/Home"]);
    } else {
      this.subscription.add(
        this.translate
          .get(["Messages.FolderOpen"])
          .subscribe((translations) => {
            this.showMsg = translations["Messages.FolderOpen"];
            this.toastr.error(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true,
            });
          })
      );
    }
  }
  public url: any;
  onClickHelpFile() {
    //12654

    var component = sessionStorage.getItem("Componentname");
    this.subscription.add(
      this.file.GetHelpFile(component).subscribe((data) => {

        if (data.ResponseData != null || data.ResponseData != undefined) {
          if (data["ResponseData"].Status == "Success") {
            this.url = data["ResponseData"].StatusDetails;
            window.open(this.url, "_blank");
          } else if (data["ResponseData"].Status == "Failed") {
            this.showMsg = data["ResponseData"].StatusDetails;
            this.toastr.warning(this.showMsg, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true,
            });
          } else {
            this.showMsg = data["ResponseData"].StatusDetails;
            this.toastr.warning(this.showMsg, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true,
            });
          }
        }
      })
    );
    // alert(item);
  }
  onEnableTFAClick() {
    let accessToken = JSON.parse(localStorage.getItem("currentUser"));
    let access = accessToken.ResponseData.accessToken;
    this.googleauthservice.enableTFA(access).subscribe(
      (res: any) => {
        const dialogRef = this.dialog.open(TfaDialogComponent, {
          data: res.data.token,
        });

        dialogRef.afterClosed().subscribe((result) => {
          console.log(`Dialog result: ${result}`);
          let secretToken = localStorage.getItem("secretToken");

          if (secretToken !== 'null') {
            this.isTFAEnabled = secretToken ? true : false;
          }

          //this.storageService.get('loggedInUser').isTFAEnabled;
        });
      },
      (error) => {
        console.error(error);
        this.alertService.danger(error);
      }
    );
  }

  onDisableTFAClick() {
    const dialogRef = this.dialog.open(TfaDialogComponent, {
      data: { isDisable: true },
    });
    dialogRef.afterClosed().subscribe(() => {
      let secretToken = localStorage.getItem("secretToken");
      if (secretToken === 'null') {
        this.isTFAEnabled = secretToken ? false : true;
      }
    });
  }

  // changeheader(value:any)
  // {

  // }

  mobCollapse = false;
  menuToggler() {
    this.showfull = !this.showfull;
    this.mobCollapse = true;
  }

  fnMenuView(value) {
    window.scroll(0, 0);
    this.showfull = value;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
