import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from 'src/env.service';
import { CommonserviceService } from './commonservice.service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CRMSettingsDTO } from '../Models/crm-settings-dto';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CRMSettingsServiceService {
  apiUrl: string;

  constructor(private env: EnvService, private http: HttpClient, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }

  getCRMDetails(): Observable<CRMSettingsDTO[]> {

    const url = `${this.apiUrl}` + 'CRMDetails';
    return this.http.get<CRMSettingsDTO[]>(url).pipe(
      tap(_ => console.log(`Set All Post Code Details`)),
      catchError(this.commonservice.handleError<CRMSettingsDTO[]>(`Set All CRM Details`))
    );
  }

  SaveCRMDetails(CRMSettingsDTO): Observable<CRMSettingsDTO> {
    debugger
    const Url = `${this.apiUrl}` + 'CRMSettingsEdit';
    return this.http.post<any>(Url, CRMSettingsDTO, httpOptions).pipe(
      tap(_ => console.log(`Set All CRM Details`)),
      catchError(this.commonservice.handleError<CRMSettingsDTO>(`Set All CRM Details`))
    );
  }

}
