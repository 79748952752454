import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EnvService } from '../../env.service';
import { LoginService } from '../Services/login.service';
import { tap } from 'rxjs/operators';
import {Router, RouterStateSnapshot } from '@angular/router';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    currentUser:any;
    response:any;
    constructor( private router: Router,private authenticationService: LoginService,private env: EnvService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        this.currentUser = this.authenticationService.currentUserValue;
       // alert('intercept 2'+ JSON.stringify(this.currentUser));
        if(this.currentUser !=null)
        {
        this.response=this.currentUser["ResponseData"];
        const isLoggedIn = this.response && this.response.Token;
        const isApiUrl = request.url.startsWith(this.env.baseUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                // setHeaders: {
                    
                //     Authorization: `Bearer ${response.Token}`
                    
                // }

                setHeaders: {
                    'Access-Control-Allow-Headers':'X-Requested-With, Content-Type, Accept, Origin, Authorization,Access-Control-Allow-Headers',
                    'Access-Control-Allow-Methods':'GET, POST, PUT, DELETE, OPTIONS',
                    'Authorization': 'Bearer '+this.response.Token,
                    'Access-Control-Allow-Origin':'*',
                    user:localStorage.getItem('currentUser')
                }
              //  header.Add("Access-Control-Allow-Headers", "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With")
            });
            return next.handle(request).pipe(
                tap(
                    succ => {
                      
                     //  alert('dd');
            
                     },
                    err => {
                        
                        if (err == "Unknown Error" ||err==undefined){
                         //  alert('interceptor 1');
                            localStorage.removeItem('currentUser');
                            this.authenticationService.GetLogout();
                            this.router.navigate(['/Login']);
            
                        }
                    }
                ))

        }
    }

        return next.handle(request);
    }
}



