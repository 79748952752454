import { Component, OnInit, Input } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { AlertListDTO, Dropdownlist } from '../../Models/alertdetails';

@Component({
  selector: 'app-dropdownfilter',
  templateUrl: './dropdownfilter.component.html',
  styleUrls: ['./dropdownfilter.component.scss']
})
export class DropdownfilterComponent  extends  BaseFilterCellComponent  {

  public get selectedValue(): any {
    const filter = this.filterByField(this.valueField);
    if(this.defaultValue){
        this.applyFilter(
            this.updateFilter({ // add a filter for the field with the value
                field: this.valueField,
                operator: 'eq',
                value: this.defaultValue
        }));
        this.defaultValue=null;
    }
    return filter ? filter.value : null;
}

@Input() public filter: CompositeFilterDescriptor;
@Input() public data: any[];
@Input() public textField: string;
@Input() public valueField: string;
@Input() public defaultValue:string;

// public get defaultItem(): any {
//     return {
//         [this.textField]: 'Select item...',
//         [this.valueField]: null
//     };
// }
//public placeHolder: any = {    [this.textField]: 'Select item...', [this.valueField]: null };
constructor(filterService: FilterService) {
    super(filterService);
}

public onChange(value: any): void {
    this.defaultValue=null;
    this.applyFilter(
        (value === null|| value ==="All") ? // value of the default item
            this.removeFilter(this.valueField) : // remove the filter
            this.updateFilter({ // add a filter for the field with the value
                field: this.valueField,
                operator: 'eq',
                value: value
            })
    ); // update the root filter
}
}
