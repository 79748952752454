import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LookUpDetails } from '../../Services/look-up-details';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { ShareDataService } from '../../Services/share-data.service';

@Component({
  selector: 'app-retail-or-packagefolder',
  templateUrl: './retail-or-packagefolder.component.html',
  styleUrls: ['./retail-or-packagefolder.component.scss']
})
export class RetailOrPackagefolderComponent implements OnInit {
  showMsg: any;
  orderNo: any;
  confirmRetailOr
  Foldertype:any;PackageFolder:any;
orderTypeList: Array<LookUpDetails["ResponseData"]> = [];
public orderTypeSelectedItem: LookUpDetails["ResponseData"];
public orderTypeSelected: String;
private subscription: Subscription = new Subscription();
submit:any=false;
  constructor(public lookupApi: LookUpDetailsService,private apiShared: ShareDataService,private router: Router) { }

  ngOnInit(): void {
    this.lookupApi.OrderType=null;
    this.showMsg=null;
    this.orderTypeSelectedItem=null;
   // this.Foldertype="0";
    this.orderNo = "";
    this.orderNo = this.apiShared.orderNo;
    this.subscription.add(this.lookupApi.GetAllLookup('ORDERTYPEMASTER').subscribe(
      (data) => {
        debugger;
        if (data["ResponseData"] != null) {
          this.orderTypeList = data["ResponseData"]
          this.orderTypeList=this.orderTypeList.filter(x=>x.Name!="Package")
        this.orderTypeSelectedItem=this.orderTypeList.find(x=>x.Default==true||x.Default==1)
        }



      }
    ));
  }
  NextStep(status:any)
  {
    debugger;
    this.showMsg=null;
    if(status==true)
    {
     if(this.Foldertype=="0")
      {
  
        if(this.orderTypeSelectedItem==undefined ||this.orderTypeSelectedItem==null)
        {
          this.submit=true;
          this.showMsg="Select Order Type";

        }
        else
        {
         
          this.orderNo = 0;
          this.lookupApi.OrderType=this.orderTypeSelectedItem.UsrCode;
          this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
      this.router.navigate(['/PenAir/Folder/FolderSummaryEdit', "0"], { skipLocationChange: true });
          this.submit=false;
        }
      }
      else if(this.Foldertype=="1")
      {
        this.orderNo = 0;
        this.apiShared.Setapishareddata(this.orderNo, 'orderNo');
        this.router.navigate(['/PenAir/Folder/PackageSummaryEdit', "0"],{skipLocationChange:true});
      }
      else{
        
        this.showMsg="Select Folder Type";
      }
     
   
    
    }
    else
    {
      this.router.navigate(["PenAir/Home"], { skipLocationChange: true });
    }
   

  }
}
