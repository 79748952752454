
import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { ReportschedulerRequestDTO, Reportscheduler, Runscheduler } from '../Models/reportscheduler';
import { Observable, of } from 'rxjs';
import { HttpHeaders } from "@angular/common/http";
import { catchError, tap, map } from 'rxjs/operators';
import { EnvService } from '../../env.service';
import { CommonserviceService } from './commonservice.service';
import { ReportschedulersetupformComponent } from '../SharedCommonModule/reportschedulersetupform/reportschedulersetupform.component'
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})

export class Reportschedulersetupservice {

  apiUrl: string;
  RSSTAutoNo: any;
  public griddata: any;

  constructor(private http: HttpClient, private env: EnvService, public commonservice: CommonserviceService) {
    this.apiUrl = env.baseUrl;
  }
  AddReportScheduler(ReportschedulerRequestDTO: any): Observable<any> {
    var time = this.setTime(ReportschedulerRequestDTO.RSST_StartTime);
    ReportschedulerRequestDTO.RSST_StartTime = time;
    return this.http.post<any>(this.apiUrl + 'AddReportScheduler', ReportschedulerRequestDTO, httpOptions).pipe(

    );
  }

  UpdateReportScheduler(ReportschedulerRequestDTO: any): Observable<Reportscheduler> {
    var time = this.setTime(ReportschedulerRequestDTO.RSST_StartTime);
    ReportschedulerRequestDTO.RSST_StartTime = time;
    return this.http.post<Reportscheduler>(this.apiUrl + 'AddReportScheduler', ReportschedulerRequestDTO, httpOptions).pipe(

    );

  }




  setTime(time: any) {

    if (time === null || time === '')
      time = new Date("01-Jan-1900").toLocaleString();
    else {



      debugger;
      if (time.setDate == undefined)
        time = new Date(time);
      time.setDate(1);
      time.setMonth(0);
      time.setFullYear(1900);
      time.setSeconds(0);
      time = time.toLocaleString();
      return time;

    }
  }



  getReportschedulerById(AutoNo: any): Observable<ReportschedulerRequestDTO> {
    //const url = `${apiUrl}/${folderNo}/${SLNO}`;
    const url = `${this.apiUrl}` + 'getReportschedulerById' + `/${AutoNo}`;
    return this.http.get<ReportschedulerRequestDTO>(url).pipe(
      tap(_ => console.log(`fetched scheduler id=${AutoNo}`)),
      catchError(this.commonservice.handleError<ReportschedulerRequestDTO>(`getscheduler id=${AutoNo}`))
    );
  }




  GetSchedulerList(): Observable<ReportschedulerRequestDTO[]> {
    const url = `${this.apiUrl}` + 'GetSchedulerList';

    return this.http.get<ReportschedulerRequestDTO[]>(url).pipe(
      tap(_ => console.log(`fetched SchedulerList`)),
      catchError(this.commonservice.handleError<ReportschedulerRequestDTO[]>(`GetSchedulerList`))
    );
  }
  DeleteReportScheduler(AutoNo: any): Observable<any> {

    const url = `${this.apiUrl}` + 'DeleteReportScheduler' + `/${AutoNo}`;

    return this.http.post<any>(url, httpOptions).pipe(
      tap(_ => console.log(`delete reportscheduler`)),
      catchError(this.commonservice.handleError<any>('DeleteReportScheduler'))
    );
  }
  public async DeleteScheduler(AutoNo: any): Promise<Observable<any>> {

    //  const url = `${this.apiUrl}`+'DeleteScheduler'+`/${AutoNo}`;

    var result = await this.http.post<any>(this.apiUrl + 'DeleteScheduler' + `/${AutoNo}`, httpOptions).pipe(
      tap(_ => console.log(`delete reportscheduler`)),
      catchError(this.commonservice.handleError<any>('DeleteScheduler'))
    ).toPromise();
    return result;
  }

  public async RunScheduler(): Promise<Observable<Runscheduler>> {

    var result = await this.http.post<Runscheduler>(this.apiUrl + 'RunScheduler', this.griddata, httpOptions).pipe(
      tap((Runscheduler: any) => console.log(`Run Scheduler`)),
      catchError(this.commonservice.handleError<any>('Run Scheduler'))

    ).toPromise();
    return result;

  }




}
