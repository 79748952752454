import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpDetails } from '../../Services/look-up-details';
import { CarDetail } from '../../Models/car-detail';
import { CarService } from '../../Services/car.service';
import { Router, ActivatedRoute } from '@angular/router';
import { dateFieldName } from '@telerik/kendo-intl';
import { LookUpDetailsService } from '../../Services/look-up-details.service';
import { DropDownFilterSettings, AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { ShareDataService } from '../../Services/share-data.service';
import { CurrencyExchangerateService } from '../../Services/currency-exchangerate.service';
import { CurrencyExchangerate } from '../../Models/currency-exchangerate';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonserviceService } from '../../Services/commonservice.service';
import { Subscription, Subject } from 'rxjs';
import { SupplierMasterService } from '../../Services/supplier-master.service';
import { ToastrService } from 'ngx-toastr';
import { PocreationService } from '../../Services/pocreation.service';
import { ProductUpdatePODTO } from '../../Models/pocreation-detail';
import { FolderpriceService } from '../../Services/folderprice.service';

@Component({
  selector: 'app-car-details-edit',
  templateUrl: './car-details-edit.component.html',
  styleUrls: ['./car-details-edit.component.scss']
})
export class CarDetailsEditComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  @ViewChild('autocompletesupplier') public autocompletearrival: AutoCompleteComponent;
  @ViewChild("apppaymenttermsedit", { read: false, static: false }) public payterm: any;
  opened = false;
  lookuptype: string = '';
  lookupname: any = 'Supplier';

  currencyExrate: CurrencyExchangerate;
  ucode: string;
  public carEditForm: FormGroup;
  submitted = false;
  loaded = false;
  folderEditMode: any;
  folderExrate: any;
  fldrCurrency: any;
  private exRate: any;
  Uname: any = "";
  prdtUpdatePODTO: ProductUpdatePODTO;
  bookingDate: any //337
  hiredFromDate: any //337
  hiredToDate: any //337
  depositDueDate: any//337
  creditLimitMsg = false;
  creditLimiFlag = false;

  msg: any;
  //hotelDetailModal=HotelDetail;
  public statusList: Array<string> = ["OK", "Pending", "Cancelled", "Confirmed"];
  public paymethodList: Array<string> = ["Credit", "Company Card", "Customer Card", "Customer Pay Locally"];
  public CommonList: Array<string> = ["Purchase", "Gross", "Sales"];
  public CardList: Array<LookUpDetails> = [];
  constructor(private apiPO: PocreationService, public commonservice: CommonserviceService, private lookupApi: LookUpDetailsService, private router: Router,
    private route: ActivatedRoute, private formBuilder: FormBuilder, private api: CarService, private toastr: ToastrService,
    private apiExRate: CurrencyExchangerateService, private apiShared: ShareDataService, private supplier: SupplierMasterService
    , private flashMessage: FlashMessagesService, private translateapi: TranslateService, private spinner: NgxSpinnerService, public objFolderPrice: FolderpriceService) { }
  private guid: string = this.route.snapshot.params['guidItinerary'];//'Hotel.20190920.120000.{6b9246de-8094-44a4-a462-6bcde7a0bb83}/';
  private orderno = this.route.snapshot.params['orderNo']
  SupplierList: Array<LookUpDetails["ResponseData"]> = [];

  public supplierSelectedItem: LookUpDetails["ResponseData"];
  public supplierSelected: String;

  BOOKEBYList: Array<LookUpDetails["ResponseData"]> = [];
  public bookbyselectedItem: LookUpDetails["ResponseData"];
  public bookedbySelected: String;
  @ViewChild("appfchcdetailsedit", { read: false, static: false }) public fchcdetails: any;

  currencyList: Array<LookUpDetails["ResponseData"]> = [];
  public currencySelectedItem: LookUpDetails["ResponseData"];
  public currencySelected: String;
  openSearch = false;

  public projNo: any;
  public showMsg: any;
  savebutton = true;
  homeCurrency: any;
  hcExRate: any;
  lockStatus: any;
  folderStatus: any;
  formeditable: any;
  public PONO: number;
  public pocreated = true;
  disableTextbox = true;
  FC_Tot_BuyInitalvalue: any = 0;
  FCSell: any = 0;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  ngOnInit() {
debugger;
    //case id : 13777 
    this.lockStatus = this.apiShared.folderReadOnly;
    this.folderStatus = this.apiShared.FolderStatus;
    if (this.lockStatus == "0" && this.folderStatus != "Invoiced") {
      this.formeditable = true;

    } else {
      this.formeditable = false;

    }
    if (
      this.folderStatus == "Cancelled" ||
      this.folderStatus == "Refund" ||
      this.folderStatus == "Partial Refund"
    ) {
      //By Caseid-12780
      this.formeditable = false;

    }
    // case id : 13777
    localStorage.getItem('OrderType')
    var orderno = sessionStorage.getItem('orderNo');
    if (orderno != null)
      if (this.orderno !== orderno) {
        return this.router.navigate(['PenAir/Home'], { skipLocationChange: true });
      }
    this.homeCurrency = this.apiShared.homeCurrency;
    this.hcExRate = this.apiShared.hcExRate;
    this.folderEditMode = "true";
    this.apiShared.folderEditMode = this.folderEditMode;
    this.apiShared.Setapishareddata(this.folderEditMode, 'folderEditMode');
    this.Uname = this.apiShared.uname;
    this.getFolderDataValues();

    this.carEditForm = this.formBuilder.group({
      FOLDERNO: [this.orderno],
      COMPANY: [''],
      SUPPRTYCODE: ['', Validators.required],
      TYPE: [''],
      PICKUP: [''],
      DATE1: [new Date()],
      DATE2: [new Date()],
      DAYS: [0],
      RATE: [0],
      KM: [0],
      STATUS: ['OK'],
      PAYABLE: [0],
      VOUCHER: [''],
      DROPOFF: [''],
      PAYMODE: [''],
      CURCODE: [this.homeCurrency],
      AMTLC: [0],
      AMTHC: [0],
      CommOn: ['Purchase'],
      SLNO: [0],
      PROJNO: [this.projNo],
      ITYPE: ['Car'],
      Provider: [''],
      GDS: [''],
      PNR: [''],
      CURExRate: [this.hcExRate],
      FCBuy: [0],
      FCCommPer: [0],
      FCCommAmt: [0],
      FCSell: [0],
      HCCommPer: [0],
      HCCommAmt: [0],
      HCSell: [0],
      ItnryNotes: [''],
      TIMEIN: [0],
      TIMEOUT: [0],
      LowFB: [''],
      LowFare: [0],
      HighFB: [''],
      HighFare: [0],
      Fareoffered: [0],
      PotentialSaving: [0],
      ActualSaving: [0],
      PDNO: [0],
      PDTYPENO: [0],
      Forgone: [0],
      AddRemarks: [''],
      PDAMOUNT: [0],
      BookingDate: [new Date()],
      PONO: [0],
      PONOYEARID: [''],
      PAYMENTID: [0],
      PMTID: [0],
      DEFDEPPER: [0],
      DEFDEP: [0],
      DepositDueDate: [''],
      BalanceDueDate: [new Date()],
      BalanceDueAmount: [0],
      GUIDItinerary: [''],
      LOYALTYPOINTS: [0],
      LOYALTYPOINTSMEMBNO: [''],
      TOMSVATTAXNO: [0],
      TOMSVATAMT: [0],
      LOYALTYPOINTSAMT: [0],
      LOYALTYPOINTSBAL: [0],
      TOMSVATCID: [''],
      PDUNIQUEREF: [''],
      PDFCURID: [this.homeCurrency],
      PDFCAMT: [0],
      PDEXRATE: [this.hcExRate],
      SELLTAXNO: [0],
      SELLTAXRATE: [0],
      FC_SELLTAXAMT: [0],
      HC_SELLTAXAMT: [0],
      PONOCCDeferredPosted: [0],
      BOOKEDBY: this.Uname,
      CONFIRMATIONNO: [''],
      TaxExcludingSalesTax: [0],
      BaseFare: [0],
      SupName: [''],
      GSTHSTOnComm: [0],
      // 12698
      CostingSupplierGrossValue: 0,
      CostingSupplierServiceFee: 0,
      CostingTaxNo: 0,
      CostingTaxRate: 0,
      CostingSupplierTaxAmount: 0,
      CostingSupplierNetBuyPrice: 0,
      CostingCustomerSalesValue: 0,
      CostingCustomerServiceFee: 0,
      CostingCustomerTaxAmount: 0,
      CostingCustomerNetSellPrice: 0,
      CostingVATType: 0,
      Markup: [0],
      CostofSale: [0],
      CostingSuppComm: [0],
      SupplierDeposit: [0],
      CountryID: ['']
      // 12698
    }
    );

    if (this.guid != null)
      this.getCarDetails(this.orderno, this.guid);
    else {
      this.loaded = true;
      this.FormLoyalityPointsChange();
    }

    this.subscription.add(this.lookupApi.GetAllLookup('USERS').subscribe(
      (data) => {
        // console.log(JSON.stringify(data, null, 4));

        // console.log(JSON.stringify(data["ResponseData"], null, 4));
        if (data != null && data != undefined) {
          this.BOOKEBYList = data["ResponseData"]
          if (this.carEditForm.controls.BOOKEDBY.value != "") {
            this.bookbyselectedItem = this.BOOKEBYList.find(item => item.Name == this.carEditForm.controls.BOOKEDBY.value);

          }
        }
      }
    ));
    this.subscription.add(this.lookupApi.GetAllLookup('CURRENCY').subscribe(
      (data) => {
        // console.log(JSON.stringify(data, null, 4));

        // console.log(JSON.stringify(data["ResponseData"], null, 4));
        if (data != null && data != undefined) {
          this.currencyList = data["ResponseData"]
          //  alert("Currency="+this.carEditForm.controls.CURCODE.value);

          if (this.carEditForm.controls.CURCODE.value != "") {

            this.currencySelectedItem = this.currencyList.find(item => item.Code == this.carEditForm.controls.CURCODE.value);


          }
        }
      }
    ));

    this.onChanges();
  }
  get f() { return this.carEditForm.controls; }
  public loadSupplier() {
    this.subscription.add(this.lookupApi.GetAllLookup('SUPPLIERS').subscribe(
      (data) => {
        if (data != null && data != undefined) {
          this.SupplierList = data["ResponseData"];
          //// alert("dd"+this.carEditForm.controls.SUPPRTYCODE.value)     ;
          if (this.carEditForm.controls.SUPPRTYCODE.value != "") {

            this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.carEditForm.controls.SUPPRTYCODE.value);
            this.supplierSelected = this.supplierSelectedItem.Name;
          }
        }
      }
    ));
  }
  public open() {

    this.opened = true;
  }
  public close(status) {

    this.opened = false;
    this.openSearch = false;
  }

  public CurrencyExRatechange(): void {
    this.CalculateForm();
  }
  getCarDetails(id, guid) {
    this.spinner.show();
    this.subscription.add(this.api.getCar(id, guid, false)
      .subscribe(data => {
        if (data != null && data != undefined) {
          //this.hotelEditForm = this.formBuilder.group(data);          
          this.carEditForm.setValue({
            FOLDERNO: data.ResponseData.FOLDERNO,
            COMPANY: data.ResponseData.COMPANY,
            SUPPRTYCODE: data.ResponseData.SUPPRTYCODE,
            TYPE: data.ResponseData.TYPE,
            PICKUP: data.ResponseData.PICKUP,
            DATE1: data.ResponseData.DATE1 != null ? (data.ResponseData.DATE1.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.DATE1)) : null,
            //DATE1:new Date(data.ResponseData.DATE1),
            DATE2: data.ResponseData.DATE2 != null ? (data.ResponseData.DATE2.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.DATE2)) : null,
            // DATE2:new Date(data.ResponseData.DATE2),
            DAYS: data.ResponseData.DAYS,
            RATE: data.ResponseData.RATE,
            KM: data.ResponseData.KM,
            STATUS: data.ResponseData.STATUS,
            PAYABLE: data.ResponseData.PAYABLE,
            VOUCHER: data.ResponseData.VOUCHER,
            DROPOFF: data.ResponseData.DROPOFF,
            PAYMODE: data.ResponseData.PAYMODE,
            CURCODE: data.ResponseData.CURCODE,
            AMTLC: data.ResponseData.AMTLC,
            AMTHC: data.ResponseData.AMTHC,
            CommOn: data.ResponseData.CommOn,
            SLNO: data.ResponseData.SLNO,
            PROJNO: data.ResponseData.PROJNO,
            ITYPE: 'Car',//data.ResponseData.ITYPE,
            Provider: data.ResponseData.Provider,
            GDS: data.ResponseData.GDS,
            PNR: data.ResponseData.PNR,
            CURExRate: data.ResponseData.CURExRate,
            FCBuy: data.ResponseData.FCBuy,
            FCCommPer: data.ResponseData.FCCommPer,
            FCCommAmt: data.ResponseData.FCCommAmt,
            FCSell: data.ResponseData.FCSell,
            HCCommPer: data.ResponseData.HCCommPer,
            HCCommAmt: data.ResponseData.HCCommAmt,
            HCSell: data.ResponseData.HCSell,
            ItnryNotes: data.ResponseData.ItnryNotes,
            TIMEIN: data.ResponseData.TIMEIN,
            TIMEOUT: data.ResponseData.TIMEOUT,
            LowFB: data.ResponseData.LowFB,
            LowFare: data.ResponseData.LowFare,
            HighFB: data.ResponseData.HighFB,
            HighFare: data.ResponseData.HighFare,
            Fareoffered: data.ResponseData.Fareoffered,
            PotentialSaving: data.ResponseData.PotentialSaving,
            ActualSaving: data.ResponseData.ActualSaving,
            PDNO: data.ResponseData.PDNO,
            PDTYPENO: data.ResponseData.PDTYPENO,
            Forgone: data.ResponseData.Forgone,
            AddRemarks: data.ResponseData.AddRemarks,
            PDAMOUNT: data.ResponseData.PDAMOUNT,
            BookingDate: data.ResponseData.BookingDate != null ? (data.ResponseData.BookingDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.BookingDate)) : null,
            //  BookingDate:new Date(data.ResponseData.BookingDate),
            PONO: data.ResponseData.PONO,
            PONOYEARID: data.ResponseData.PONOYEARID,
            PAYMENTID: data.ResponseData.PAYMENTID,
            PMTID: data.ResponseData.PMTID,
            DEFDEPPER: data.ResponseData.DEFDEPPER,
            DEFDEP: data.ResponseData.DEFDEP,
            DepositDueDate: data.ResponseData.DepositDueDate != null ? (data.ResponseData.DepositDueDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.DepositDueDate)) : null,
            //DepositDueDate:new Date(data.ResponseData.DepositDueDate),
            BalanceDueDate: data.ResponseData.BalanceDueDate != null ? (data.ResponseData.BalanceDueDate.toString() == "1900-01-01T00:00:00" ? null : new Date(data.ResponseData.BalanceDueDate)) : null,
            //BalanceDueDate:new Date(data.ResponseData.BalanceDueDate),
            BalanceDueAmount: data.ResponseData.BalanceDueAmount,
            GUIDItinerary: data.ResponseData.GUIDItinerary,
            LOYALTYPOINTS: data.ResponseData.LOYALTYPOINTS,
            LOYALTYPOINTSMEMBNO: data.ResponseData.LOYALTYPOINTSMEMBNO,
            TOMSVATTAXNO: data.ResponseData.TOMSVATTAXNO,
            TOMSVATAMT: data.ResponseData.TOMSVATAMT,
            LOYALTYPOINTSAMT: data.ResponseData.LOYALTYPOINTSAMT,
            LOYALTYPOINTSBAL: data.ResponseData.LOYALTYPOINTSBAL,
            TOMSVATCID: data.ResponseData.TOMSVATCID,
            PDUNIQUEREF: data.ResponseData.PDUNIQUEREF,
            PDFCURID: data.ResponseData.PDFCURID,
            PDFCAMT: data.ResponseData.PDFCAMT,
            PDEXRATE: data.ResponseData.PDEXRATE,
            SELLTAXNO: data.ResponseData.SELLTAXNO,
            SELLTAXRATE: data.ResponseData.SELLTAXRATE,
            FC_SELLTAXAMT: data.ResponseData.FC_SELLTAXAMT,
            HC_SELLTAXAMT: data.ResponseData.HC_SELLTAXAMT,
            PONOCCDeferredPosted: data.ResponseData.PONOCCDeferredPosted,
            BOOKEDBY: data.ResponseData.BOOKEDBY,
            CONFIRMATIONNO: data.ResponseData.CONFIRMATIONNO,
            TaxExcludingSalesTax: data.ResponseData.TaxExcludingSalesTax,
            BaseFare: data.ResponseData.BaseFare,
            SupName: data.ResponseData.SupName,
            GSTHSTOnComm: 0,

            // 12698
            CostingSupplierGrossValue: data.ResponseData.CostingSupplierGrossValue,
            CostingSupplierServiceFee: data.ResponseData.CostingSupplierServiceFee,
            CostingTaxNo: data.ResponseData.CostingTaxNo,
            CostingTaxRate: data.ResponseData.CostingTaxRate,
            CostingSupplierTaxAmount: data.ResponseData.CostingSupplierTaxAmount,
            CostingSupplierNetBuyPrice: data.ResponseData.CostingSupplierNetBuyPrice,
            CostingCustomerSalesValue: data.ResponseData.CostingCustomerSalesValue,
            CostingCustomerServiceFee: data.ResponseData.CostingCustomerServiceFee,
            CostingCustomerTaxAmount: data.ResponseData.CostingCustomerTaxAmount,
            CostingCustomerNetSellPrice: data.ResponseData.CostingCustomerNetSellPrice,
            CostingVATType: data.ResponseData.CostingVATType,
            Markup: data.ResponseData.CostingCustomerServiceFee,
            CostofSale: data.ResponseData.CostingCustomerSalesValue,
            CostingSuppComm: data.ResponseData.CostingSuppComm,
            SupplierDeposit: data.ResponseData.SupplierDeposit,
            CountryID: ['']
            // 12698

          });
          this.FC_Tot_BuyInitalvalue = this.carEditForm.controls.FCBuy.value
          this.FCSell = this.carEditForm.controls.FCSell.value;
        }
        else {
          this.spinner.hide();
        }
        this.spinner.hide();
        //this.isLoadingResults = false;
        this.loaded = true;
        this.FormLoyalityPointsChange();
        this.loadsuppliermaster();


        this.PONO = this.carEditForm.controls.PONO.value;

        if (this.PONO > 0) {

          this.pocreated = false;
        }
        else {
          this.pocreated = true;
        }



      }));
  }
  loadsuppliermaster() {
    if (this.carEditForm.controls.SUPPRTYCODE.value != '' && this.carEditForm.controls.SUPPRTYCODE.value != null) {
      this.subscription.add(this.lookupApi.findSupplier(this.carEditForm.controls.SUPPRTYCODE.value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
            if (this.carEditForm.controls.SUPPRTYCODE.value != "") {

              this.supplierSelectedItem = this.SupplierList.find(item => item.Code == this.carEditForm.controls.SUPPRTYCODE.value);
              if (this.supplierSelectedItem != null) {
                this.supplierSelected = this.supplierSelectedItem.Name;
                this.carEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID);
              }

            }
          }
        }
      ));
    }
  }
  handleFiltersupplier(value) {

    if (value.length >= 3) {

      this.subscription.add(this.lookupApi.SearchSupplier(value).subscribe(
        (data) => {
          if (data != null && data != undefined) {
            this.SupplierList = data["ResponseData"];
          }
        }
      ));
      // this.destinationList = this.source.filter((s) => s.UsrCode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.autocompletearrival.toggle(false);
    }
  }
  onFormSubmit() {
    this.spinner.show();
    if (this.carEditForm.controls.PAYMODE.value == "Company Card")//By case id-355
    {
      if ((this.carEditForm.controls.PDAMOUNT.value == 0) || (this.carEditForm.controls.PDNO.value == "0") || (this.carEditForm.controls.PDTYPENO.value == "0")) {
        this.subscription.add(this.translateapi.get(['Messages.PleaseSelectCompanyCardWithPaymentOption']).subscribe((translations) => {

          this.showMsg = translations['Messages.PleaseSelectCompanyCardWithPaymentOption'];;
          this.toastr.error(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        this.spinner.hide();
        return;
      }
    }

    this.bookingDate = (this.carEditForm.controls.BookingDate.value == null || this.carEditForm.controls.BookingDate.value == "" ? "01-Jan-1900" : this.carEditForm.controls.BookingDate.value);//Case id-337
    this.hiredFromDate = (this.carEditForm.controls.DATE1.value == null || this.carEditForm.controls.DATE1.value == "" ? "01-Jan-1900" : this.carEditForm.controls.DATE1.value);//Case id-337
    this.hiredToDate = (this.carEditForm.controls.DATE2.value == null || this.carEditForm.controls.DATE2.value == "" ? "01-Jan-1900" : this.carEditForm.controls.DATE2.value);//Case id-337
    this.depositDueDate = (this.carEditForm.controls.DepositDueDate.value == null || this.carEditForm.controls.DepositDueDate.value == "" ? "01-Jan-1900" : this.carEditForm.controls.DepositDueDate.value);//Case id-337

    if (Boolean(this.commonservice.ValidDateRange(this.bookingDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidBookingDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidBookingDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.commonservice.ValidDateRange(this.hiredFromDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidHiredFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidHiredFromDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    if (Boolean(this.commonservice.ValidDateRange(this.hiredToDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidHiredToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidHiredToDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }

    if (Boolean(this.commonservice.ValidDateRange(this.depositDueDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidDepositDueDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidDepositDueDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      this.spinner.hide();
      return;
    }
    this.objFolderPrice
      .CreditLimitCheck(this.guid == null || undefined ? "0" : this.guid,
        0,
        this.orderno,
        this.carEditForm.get("HCSell").value
      )
      .subscribe(res => {
        if (res != undefined || res["ResponseData"] != null) {
          if (
            res["ResponseData"].value != "0" &&
            res["ResponseData"].Status != "0" &&
            res["ResponseData"].ErrorMessage != ""
          ) {
            debugger;
            if (res["ResponseData"].value == 1) {
              this.msg = res["ResponseData"].ErrorMessage;
              this.spinner.hide();
              this.creditLimiFlag = true;
              this.creditLimitMsg = true;

            } else if (res["ResponseData"].value == 2) {
              this.msg = res["ResponseData"].ErrorMessage;
              this.spinner.hide();
              this.creditLimiFlag = false;
              this.creditLimitMsg = true;
              // this.AirTicketSaveAndUpdate();
            } else {
              this.carDetailsSaveandUpdate();
            }
          } else {
            this.carDetailsSaveandUpdate();
          }
        }
      });
  }

  carDetailsSaveandUpdate() {
    this.carEditForm.controls.BaseFare.setValue(this.carEditForm.controls.FCSell.value);

    if (this.guid != null && this.PONO > 0) {
      this.prdtUpdatePODTO = new ProductUpdatePODTO();
      this.prdtUpdatePODTO.FOLDERNO = this.orderno;
      this.prdtUpdatePODTO.GUIDItinerary = this.carEditForm.get('GUIDItinerary').value;
      this.prdtUpdatePODTO.LOYALTYPOINTS = this.carEditForm.get('LOYALTYPOINTS').value;
      this.prdtUpdatePODTO.LOYALTYPOINTSAMT = this.carEditForm.get('LOYALTYPOINTSAMT').value
      this.prdtUpdatePODTO.LOYALTYPOINTSBAL = this.carEditForm.get('LOYALTYPOINTSBAL').value
      this.prdtUpdatePODTO.LOYALTYPOINTSMEMBNO = this.carEditForm.get('LOYALTYPOINTSMEMBNO').value;
      this.prdtUpdatePODTO.TYPE = "Car";
      this.prdtUpdatePODTO.BaseFare = this.carEditForm.get('BaseFare').value;
      this.prdtUpdatePODTO.SELLAMT = this.carEditForm.get('FCSell').value;
      this.prdtUpdatePODTO.SELLCOMM = this.carEditForm.get('HCCommAmt').value;

      this.prdtUpdatePODTO.HCCommAmt = this.carEditForm.get('HCCommAmt').value;

      this.prdtUpdatePODTO.SELLTAXNO = this.carEditForm.get('SELLTAXNO').value;
      this.prdtUpdatePODTO.SELLTAXRATE = this.carEditForm.get('SELLTAXRATE').value;
      this.prdtUpdatePODTO.PAYABLE = this.carEditForm.get('PAYABLE').value;
      this.prdtUpdatePODTO.FC_CIDExRate = this.carEditForm.get('CURExRate').value;
      this.prdtUpdatePODTO.FC_SELLTAXAMT = this.carEditForm.get('FC_SELLTAXAMT').value;
      this.prdtUpdatePODTO.CostingCustomerNetSellPrice = this.carEditForm.get('CostingCustomerNetSellPrice').value;
      this.prdtUpdatePODTO.CostingCustomerServiceFee = this.carEditForm.get('CostingCustomerServiceFee').value;
      this.prdtUpdatePODTO.CostingCustomerTaxAmount = this.carEditForm.get('CostingCustomerTaxAmount').value;

      //14150
      if (this.carEditForm.controls.CostingVATType.value == 'VSC') {
        this.prdtUpdatePODTO.PAYABLE.setValue(this.carEditForm.controls.CostingSupplierGrossValue.value - this.carEditForm.controls.CostingSuppComm.value);
        this.prdtUpdatePODTO.SELLCOMM.setValue(this.carEditForm.controls.Markup.value);
        //this.prdtUpdatePODTO.BalanceDueAmount.setValue(this.OthersEditForm.controls.CostingSupplierGrossValue.value - this.OthersEditForm.controls.CostingSuppComm.value);
      }
      //14150
      //this.apiPO.formData=
      this.apiPO.formData = this.prdtUpdatePODTO;
      this.subscription.add(this.apiPO.SaveProductAfterPO()
        .subscribe(res => {
          this.spinner.hide();
          if (res.IsSuccess === true && res.Message === 'Success') {
            this.spinner.hide();
            //  alert('SUCCESS3!! :-)\n\n' + JSON.stringify(this.carEditForm.value, null, 4));
            this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
              this.showMsg = translations['Messages.saved_success'];
              this.toastr.success(this.showMsg, "", {
                timeOut: 3000,
                disableTimeOut: false,
                closeButton: true
              })
            }));

            this.router.navigate(['/PenAir/Folder/CarDetails', this.orderno, this.guid], { skipLocationChange: true });
          } else {
            this.spinner.hide();
            this.toastr.warning(res.ResponseData.ErrorMessage, "", {
              timeOut: 5000,
              disableTimeOut: false,
              closeButton: true
            })
          }
        }, (err) => {
          this.spinner.hide();
          console.log(err);
          console.log(err);
          this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
            this.showMsg = translations['Messages.some_error_in_save'];
            this.toastr.warning(this.showMsg, "", {
              timeOut: 3000,
              disableTimeOut: false,
              closeButton: true
            })
          }));
        }
        ));

    }
    else {
      //14150
      if (this.carEditForm.controls.CostingVATType.value == 'VSC') {
        this.carEditForm.controls.PAYABLE.setValue(this.carEditForm.controls.CostingSupplierGrossValue.value - this.carEditForm.controls.CostingSuppComm.value);
        this.carEditForm.controls.HCCommAmt.setValue(this.carEditForm.controls.Markup.value);
        this.carEditForm.controls.BalanceDueAmount.setValue(this.carEditForm.controls.CostingSupplierGrossValue.value - this.carEditForm.controls.CostingSuppComm.value);
      }//14150
      if (this.carEditForm.value['CURExRate'] === '' || this.carEditForm.value['CURExRate'] === 0 || this.carEditForm.value['CURExRate'] === null) {
        this.subscription.add(this.translateapi.get(['Messages.ExchangeRateError']).subscribe((translations) => {
          this.showMsg = translations['Messages.ExchangeRateError'];
          this.toastr.warning(this.showMsg, "", {
            timeOut: 3000,
            disableTimeOut: false,
            closeButton: true
          })
        }));
        this.spinner.hide();
        return;
      }
      this.submitted = true;

      //console.log(this.hotelEditForm.get('FOLDERNO').value);
      // stop here if form is invalid
      if (this.carEditForm.invalid) {
        if (this.carEditForm.controls.SUPPRTYCODE.value == '' || this.carEditForm.controls.SUPPRTYCODE.value == undefined) {
          this.commonservice.submitted = true;
        }
        this.spinner.hide();
        return;
      }
      else {
        this.commonservice.submitted = false;
      }


      //this.hotelDetailModal= Object.assign({}, this.hotelEditForm.value);
      if (this.guid != null) {
        this.spinner.show();
        if (this.CompanyCardValidation() == true) {
          this.subscription.add(this.api.UpdateCarDetails(this.carEditForm.value)
            .subscribe(res => {
              this.spinner.hide();
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  this.spinner.hide();
                  //  alert('SUCCESS3!! :-)\n\n' + JSON.stringify(this.carEditForm.value, null, 4));
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));

                  this.router.navigate(['/PenAir/Folder/CarDetails', this.orderno, this.guid], { skipLocationChange: true });
                } else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
              else {
                this.spinner.hide();
              }
            }, (err) => {
              this.spinner.hide();
              console.log(err);
              console.log(err);
              this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                this.showMsg = translations['Messages.some_error_in_save'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
            }
            ));
        }
        else {
          this.spinner.hide();
        }
      }

      else {
        this.spinner.show();
        if (this.CompanyCardValidation() == true) {
          this.subscription.add(this.api.AddCarDetails(this.carEditForm.value)
            .subscribe(res => {
              if (res != null && res != undefined) {
                if (res.IsSuccess === true && res.Message === 'SUCCESS') {
                  this.spinner.hide();
                  this.subscription.add(this.translateapi.get(['Messages.saved_success']).subscribe((translations) => {
                    this.showMsg = translations['Messages.saved_success'];
                    this.toastr.success(this.showMsg, "", {
                      timeOut: 3000,
                      disableTimeOut: false,
                      closeButton: true
                    })
                  }));

                  //alert('SUCCESS223!! :-)\n\n' + JSON.stringify(this.carEditForm.value, null, 4));
                  // this.router.navigate(['/PenAir/Folder/Products',this.orderno],{skipLocationChange:true});
                  this.commonservice.isfinalsaved = false;
                  sessionStorage.setItem('pagevalue', '0');
                  var folderQuotes = sessionStorage.getItem('folderQuotes');
                  if (folderQuotes) {
                    this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno], { skipLocationChange: true });
                  }
                  else {
                    this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno], { skipLocationChange: true });
                  }

                } else {
                  this.spinner.hide();
                  this.toastr.warning(res.ResponseData.ErrorMessage, "", {
                    timeOut: 5000,
                    disableTimeOut: false,
                    closeButton: true
                  })
                }
              }
              else {
                this.spinner.hide();
              }
            }, (err) => {
              this.spinner.hide();
              console.log(err);
              console.log(err);
              this.subscription.add(this.translateapi.get(['Messages.some_error_in_save']).subscribe((translations) => {
                this.showMsg = translations['Messages.some_error_in_save'];
                this.toastr.warning(this.showMsg, "", {
                  timeOut: 3000,
                  disableTimeOut: false,
                  closeButton: true
                })
              }));
            }
            ));
        }
        else {
          this.spinner.hide();
        }
        //alert('SUCCESS2!! :-)\n\n' + JSON.stringify(this.hotelDetailModal, null, 4));
        // display form values on success
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.hotelEditForm.value, null, 4));
      }
    }
  }
  onCancel() {
    if (this.guid != "" && this.guid != null)
      this.router.navigate(['/PenAir/Folder/CarDetails', this.orderno, this.guid + '/'], { skipLocationChange: true });
    else {
      this.commonservice.isfinalsaved = false;
      sessionStorage.setItem('pagevalue', '0')
      var folderQuotes = sessionStorage.getItem('folderQuotes');
      if (folderQuotes) {
        this.router.navigate(['/PenAir/CRM/Enquiry/View/', this.orderno], { skipLocationChange: true });
      }
      else {
        this.router.navigate(['PenAir/Folder/FolderSummary', this.orderno], { skipLocationChange: true });
      }
    }
    //this.router.navigate(['/PenAir/Folder/Products',this.orderno],{skipLocationChange:true});
  }
  onReset() {
    this.submitted = false;
    this.carEditForm.reset();
  }
  private buyExRate: number;
  private fCBuy: number;
  private unit: number;
  private rate: number;

  CalculateForm() {
    this.buyExRate = (this.carEditForm.get('CURExRate').value == "" || this.carEditForm.get('CURExRate').value == 0) ? 1 : this.carEditForm.get('CURExRate').value;
    if ((this.carEditForm.get('AMTLC') != null) && this.carEditForm.get('AMTLC').value != 0 && this.carEditForm.get('AMTLC').value != "undefined") {
      this.fCBuy = this.carEditForm.controls.AMTLC.value;
      this.carEditForm.controls.FCBuy.setValue(parseFloat(this.fCBuy.toFixed(2)));
      this.carEditForm.controls.PAYABLE.setValue(parseFloat((this.fCBuy * this.buyExRate).toFixed(2)));
      this.carEditForm.controls.AMTHC.setValue(parseFloat((this.fCBuy * this.buyExRate).toFixed(2)));
    }
    else {
      this.carEditForm.controls.AMTHC.setValue(0);
      this.carEditForm.controls.FCBuy.setValue(0);
      this.carEditForm.controls.PAYABLE.setValue(parseFloat((this.carEditForm.get('FCBuy').value * this.buyExRate).toFixed(2)));
    }
    if (this.carEditForm.get('AMTLC').value == 0) {
      this.carEditForm.controls.AMTHC.setValue(0);
    }
    this.carEditForm.controls.HCSell.setValue(parseFloat((this.carEditForm.get('FCSell').value * this.exRate).toFixed(2)));
    this.carEditForm.controls.HCCommAmt.setValue(parseFloat((this.carEditForm.get('HCSell').value - this.carEditForm.get('PAYABLE').value).toFixed(2)));

  }
  private hcBuy: number;
  private amount: number;
  private balanceDueAmount: number;
  private defaultDepositPer: number;
  CalculateFormUnitsChange() {
    if (this.carEditForm.get('KM').value == 0 && this.carEditForm.get('RATE').value == 0) {
      this.carEditForm.controls.AMTLC.setValue(parseFloat((0).toFixed(2)));
    }
    else {
      this.unit = (this.carEditForm.get('KM').value == "" || this.carEditForm.get('KM').value == 0) ? 1 : this.carEditForm.get('KM').value;
      this.rate = (this.carEditForm.get('RATE').value == "" || this.carEditForm.get('RATE').value == 0) ? 1 : this.carEditForm.get('RATE').value;
      this.carEditForm.controls.AMTLC.setValue(parseFloat((this.unit * this.rate).toFixed(2)));
    }
    this.CalculateForm();
    // this.buyExRate=(this.carEditForm.get('CURExRate').value==""||this.carEditForm.get('CURExRate').value==0)?1:this.carEditForm.get('CURExRate').value;

    //Payment Terms

    this.amount = (this.carEditForm.get('DEFDEP').value == "" || this.carEditForm.get('DEFDEP').value == 0) ? 0 : this.carEditForm.get('DEFDEP').value;
    this.defaultDepositPer = (this.carEditForm.get('DEFDEPPER').value == "" || this.carEditForm.get('DEFDEPPER').value == 0) ? 0 : this.carEditForm.get('DEFDEPPER').value;

    if (this.defaultDepositPer != 0 && this.defaultDepositPer != 0.00)
      this.amount = (this.hcBuy * this.defaultDepositPer) / 100;

    this.balanceDueAmount = this.hcBuy - this.amount
    this.carEditForm.controls.DEFDEP.setValue(parseFloat(this.amount.toFixed(2)));
    this.carEditForm.controls.BalanceDueAmount.setValue(parseFloat(this.balanceDueAmount.toFixed(2)));
    //Corperate Info


    this.carEditForm.controls.ActualSaving.setValue(parseFloat((this.carEditForm.get('PAYABLE').value * -1).toFixed(2)));
  }

  getFolderDataValues() {

    // this.apiShared.folderCurrency.subscribe(fldrCurrency => this.fldrCurrency = fldrCurrency)
    // this.apiShared.folderExchangeRate.subscribe(folderExrate => this.folderExrate = folderExrate)
    // this.exRate=new Number(this.folderExrate);
    // this.apiShared.projNo.subscribe(projNo => this.projNo = projNo)
    this.fldrCurrency = this.apiShared.folderCurrency;
    this.folderExrate = this.apiShared.folderExchangeRate;
    this.exRate = new Number(this.folderExrate);
    this.projNo = this.apiShared.projNo;
  }
  onChanges(): void {

  }

  changefn() {


  }



  public SupplierValueChange(value: any): void {
    //  alert(value);
    debugger;
    if (this.SupplierList != null && this.SupplierList.length > 0) {
      this.supplierSelectedItem = this.SupplierList.find(
        item => item.Name == value
      );
    }

    if (this.supplierSelectedItem != null) {
      this.supplierSelected = this.supplierSelectedItem.Name; //13860
      this.SupplierDefaultvalue(this.supplierSelectedItem.Code);
      this.carEditForm.controls.SUPPRTYCODE.setValue(this.supplierSelectedItem.Code);
      this.carEditForm.controls.COMPANY.setValue(this.supplierSelectedItem.ActualName);
      this.carEditForm.controls.Provider.setValue(this.supplierSelectedItem.ActualName);
      this.apiShared.BALDUETO = this.supplierSelectedItem.BALDUETO;
      this.payterm.getBalanceDuedate();

      this.carEditForm.controls.CountryID.setValue(this.supplierSelectedItem.CONTID)
      if (this.supplierSelectedItem.CONTID != null && this.supplierSelectedItem.CONTID != undefined && this.supplierSelectedItem.CONTID != "")
        // this.fchcdetails.getTaxLookup(this.supplierSelectedItem.CONTID)
        this.TaxLookup(this.supplierSelectedItem.CONTID);
    }
    else {
      this.supplierSelected = "";
      this.carEditForm.controls.SUPPRTYCODE.setValue('');
      this.carEditForm.controls.COMPANY.setValue('');
      this.carEditForm.controls.Provider.setValue('');
    }
    // this.carEditForm.get("suppUserCodetext").setValue( this.supplierSelectedItem.Name);
  }

  TaxLookup(country) {
    this.fchcdetails.getInputTaxLookup(country)
  }
  public BookedByValueChange(value: any): void {
    console.log('selectionChange', value);
    if (value != null && value != undefined)

      this.carEditForm.controls.BOOKEDBY.setValue(value.UsrCode);
    else
      this.carEditForm.controls.BOOKEDBY.setValue("");
  }
  public CurrencyChange(value: any): void {
    console.log('selectionChange', value);
    if (value != null && value != undefined)
      this.carEditForm.controls.CURCODE.setValue(value.UsrCode);
    else
      this.carEditForm.controls.CURCODE.setValue("");
  }

  SupplierDefaultvalue(suppcode) {
    this.subscription.add(this.supplier.GetDefaultValue(suppcode)
      .subscribe(data => {
        if (data !== '' && data !== null && data !== undefined) {
          // this.carEditForm.controls.CURCODE.setValue(data.CURRENCYCODE);
          this.carEditForm.controls.PAYMENTID.setValue(data.PAYTERMID);
          this.carEditForm.controls.PMTID.setValue(data.PAYMTDID);
          this.resetChildForm();

          // if(this.carEditForm.controls.CURCODE.value!=""&&this.carEditForm.controls.CURCODE.value!=null)
          // {   
          //   this.currencySelectedItem =this.currencyList.find(item => item.Code ===this.carEditForm.controls.CURCODE.value );     
          //   this.getCurrencyExRate();
          // }
        }
      }));
  }
  resetChildForm() {
    this.resetFormSubject.next(true);
  }

  public getCurrencyExRate() {

    this.bookingDate = (this.carEditForm.controls.BookingDate.value == null || this.carEditForm.controls.BookingDate.value == "" ? "01-Jan-1900" : this.carEditForm.controls.BookingDate.value);//Case id-337

    if (Boolean(this.commonservice.ValidDateRange(this.bookingDate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidBookingDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidBookingDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }

    if (this.carEditForm.controls.CURCODE.value != '' && this.carEditForm.controls.CURCODE.value != undefined)
      this.getExchangeRate(this.carEditForm.controls.CURCODE.value, this.bookingDate)
    ///this.folderdata.changeData(this.carEditForm.controls.CURCODE.value, (this.carEditForm.controls.CURExRate.value))
  }


  getExchangeRate(currency, bookingDate) {
    this.savebutton = false;
    bookingDate = this.commonservice.ConvertToLocaleDate(bookingDate);
    // new Date(bookingDate).toLocaleDateString('en-GB', {
    //   day: 'numeric', month: 'short', year: 'numeric'
    // }).replace(/ /g, '-');
    bookingDate = bookingDate.replace('Sept', 'Sep')
    this.subscription.add(this.apiExRate.getExchangeRate(currency, bookingDate)
      .subscribe(data => {
        this.currencyExrate = data;
        this.carEditForm.controls.CURExRate.setValue(this.currencyExrate.ResponseData.EXRATE);
        this.savebutton = true;
        //this.CalculateForm();//12639
      }));
  }


  FormLoyalityPointsChange() {
    if (this.carEditForm.controls.LOYALTYPOINTSMEMBNO.value != "" && this.carEditForm.controls.LOYALTYPOINTSMEMBNO.value != null) {


      this.carEditForm.get('LOYALTYPOINTS').enable();
      this.carEditForm.get('LOYALTYPOINTSAMT').enable();
      // this.disableTextbox =  false;
    }
    else {
      this.carEditForm.controls.LOYALTYPOINTS.setValue(0);
      this.carEditForm.controls.LOYALTYPOINTSAMT.setValue(0);
      this.carEditForm.get('LOYALTYPOINTS').disable();
      this.carEditForm.get('LOYALTYPOINTSAMT').disable();

    }
  }



  diff: number;
  indate: Date;
  outdate: Date;
  days: number = 0;
  onDateoutblur() {
    this.indate = this.carEditForm.controls.DATE1.value
    this.outdate = this.carEditForm.controls.DATE2.value
    this.indate = (this.carEditForm.controls.DATE1.value == null || this.carEditForm.controls.DATE1.value == "" ? "01-Jan-1900" : this.carEditForm.controls.DATE1.value);//Case id-337
    this.outdate = (this.carEditForm.controls.DATE2.value == null || this.carEditForm.controls.DATE2.value == "" ? "01-Jan-1900" : this.carEditForm.controls.DATE2.value);//Case id-337


    if (Boolean(this.commonservice.ValidDateRange(this.indate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidHiredFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidHiredFromDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (Boolean(this.commonservice.ValidDateRange(this.outdate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidHiredToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidHiredToDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    this.diff = this.outdate.getTime() - this.indate.getTime();
    this.days = this.diff / (1000 * 60 * 60 * 24);
    this.carEditForm.controls.DAYS.setValue(this.days);
  }

  onNightsblur() {
    this.indate = this.carEditForm.controls.DATE1.value
    this.days = this.carEditForm.controls.DAYS.value

    this.indate = (this.carEditForm.controls.DATE1.value == null || this.carEditForm.controls.DATE1.value == "" ? "01-Jan-1900" : this.carEditForm.controls.DATE1.value);//Case id-337
    this.outdate = (this.carEditForm.controls.DATE2.value == null || this.carEditForm.controls.DATE2.value == "" ? "01-Jan-1900" : this.carEditForm.controls.DATE2.value);//Case id-337


    if (Boolean(this.commonservice.ValidDateRange(this.indate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidHiredFromDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidHiredFromDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    if (Boolean(this.commonservice.ValidDateRange(this.outdate)) == false)//Case id-337
    {
      this.subscription.add(this.translateapi.get(['Messages.InvalidHiredToDate']).subscribe((translations) => {
        this.showMsg = translations['Messages.InvalidHiredToDate'];
        this.commonservice.showWARNINGtoastrmsg(this.showMsg, 2000, true, false);
      }));
      return;
    }
    this.outdate = new Date((new Date(this.indate)).getTime() + (this.days * 60 * 60 * 24 * 1000));
    this.carEditForm.controls.DATE2.setValue(this.outdate);
  }



  CompanyCardValidation() {
    if (this.carEditForm.get('PAYMODE').value == "Company Card") {
      if (this.carEditForm.get('PDNO').value != "" && this.carEditForm.get('PDNO').value != null && this.carEditForm.get('PDFCURID').value != "" && this.carEditForm.get('PDFCURID').value != null
        && this.carEditForm.get('PDFCAMT').value != "" && this.carEditForm.get('PDFCAMT').value != null && this.carEditForm.get('PDFCAMT').value != 0 && this.carEditForm.get('PDTYPENO').value != null
        && this.carEditForm.get('PDTYPENO').value != "" && this.carEditForm.get('PDAMOUNT').value != null && this.carEditForm.get('PDAMOUNT').value != null && this.carEditForm.get('PDAMOUNT').value != 0

        && this.carEditForm.get('PDFCAMT').value != null && this.carEditForm.get('PDFCAMT').value != null && this.carEditForm.get('PDFCAMT').value != 0
      ) {
        return true;
      }
      else {
        this.commonservice.showFlash1('Messages.Companycard_validation', 'bg-danger text-white', 2000);
        return false;
      }
    }
    else {
      return true;
    }
  }
  isSticky: boolean = false;
  @HostListener('window:scroll')
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }
  //13860
  openBox(lookuptype) {
    this.openSearch = true;
    this.lookuptype = lookuptype;
    if (this.lookuptype == 'SUPPLIERS') {
      this.lookupname = 'Supplier';
    }
  }

  getParentMethod(): any {
    return {
      callParentMethod: (value: any) => {
        debugger;
        this.openSearch = false;
        this.supplierSelectedItem = value;
        this.SupplierList = [];
        this.loadSupplierValue(value.Code);
        this.SupplierValueChange(value.ActualName)
      },
      closemodalpopup: () => {

        this.openSearch = false;

      }
    }
  }
  //13860


  loadSupplierValue(value: any) {  //14068   v1.0 
    debugger;

    this.subscription.add(this.lookupApi.findSupplier(value).subscribe(
      (data) => {
        if (data["ResponseData"] != null) {
          this.SupplierList = data["ResponseData"];
          if (value != "") {

            this.supplierSelectedItem = this.SupplierList.find(item => item.Code == value);
            if (this.supplierSelectedItem != null) {
              this.supplierSelected = this.supplierSelectedItem.Name;
            }

          }
        }

      }
    ));

  }
  ngOnDestroy() {
    this.commonservice.submitted = false;
    this.subscription.unsubscribe();
  }
  ConfirmMessage() {
    if (this.creditLimiFlag == false) {
      this.creditLimitMsg = false;
      this.spinner.show();
      this.carDetailsSaveandUpdate();
    } else {
      this.creditLimitMsg = false;
    }
  }
}
